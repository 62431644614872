// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("../RR.js");
var Util$BsConsole = require("../util.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var component = RR$BsConsole.statelessComponent("Column-BsConsole");

function make(justifyContent, alignItems, alignContent, wrap, className, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var __x_000 = Css.display(/* flex */-1010954439);
              var __x_001 = /* :: */[
                Belt_Option.map(justifyContent, Css.justifyContent),
                /* :: */[
                  Belt_Option.map(alignItems, Css.alignItems),
                  /* :: */[
                    Belt_Option.map(alignContent, Css.alignContent),
                    /* :: */[
                      Belt_Option.map(wrap, Css.flexWrap),
                      /* :: */[
                        Css.flexDirection(/* column */-963948842),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ];
              var __x = /* :: */[
                __x_000,
                __x_001
              ];
              return ReactDOMRe.createElementVariadic("div", {
                          className: Css.style(Belt_List.keepMap(__x, Util$BsConsole.identity)) + (" " + Belt_Option.getWithDefault(className, ""))
                        }, children);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        var children = Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(reactProps.children), [], (function (c) {
                return [c];
              }));
        return make(undefined, undefined, undefined, undefined, undefined, children);
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
