'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = LinkRadial;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _d3Shape = require('d3-shape');

var _additionalProps = require('../../../util/additionalProps');

var _additionalProps2 = _interopRequireDefault(_additionalProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

LinkRadial.propTypes = {
  innerRef: _propTypes2.default.func
};

function LinkRadial(_ref) {
  var className = _ref.className,
      innerRef = _ref.innerRef,
      data = _ref.data,
      _ref$angle = _ref.angle,
      angle = _ref$angle === undefined ? function (d) {
    return d.x;
  } : _ref$angle,
      _ref$radius = _ref.radius,
      radius = _ref$radius === undefined ? function (d) {
    return d.y;
  } : _ref$radius,
      _ref$source = _ref.source,
      source = _ref$source === undefined ? function (d) {
    return d.source;
  } : _ref$source,
      _ref$target = _ref.target,
      target = _ref$target === undefined ? function (d) {
    return d.target;
  } : _ref$target,
      restProps = _objectWithoutProperties(_ref, ['className', 'innerRef', 'data', 'angle', 'radius', 'source', 'target']);

  var link = (0, _d3Shape.linkRadial)();
  link.angle(angle);
  link.radius(radius);
  link.source(source);
  link.target(target);

  return _react2.default.createElement('path', _extends({
    ref: innerRef,
    className: (0, _classnames2.default)('vx-link-radius', className),
    d: link(data)
  }, (0, _additionalProps2.default)(restProps, data)));
}