// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function fromString(s) {
  switch (s) {
    case "bytes" :
        return /* BytesType */5;
    case "callstack" :
        return /* CallstackType */2;
    case "classifiers" :
        return /* ClassifiersType */3;
    case "gigabytes" :
        return /* GigaBytesType */7;
    case "gps_timestamp" :
        return /* GpsTimestampType */1;
    case "ipv4" :
        return /* Ipv4Type */10;
    case "issue-assignee" :
        return /* AssigneeType */13;
    case "issue-state" :
        return /* IssueStateType */12;
    case "issue-ticket" :
        return /* TicketType */11;
    case "kilobytes" :
        return /* KiloBytesType */6;
    case "memory_address" :
        return /* MemoryAddressType */9;
    case "none" :
        return /* None_ */16;
    case "seconds" :
        return /* Seconds */15;
    case "semver" :
        return /* Semver */14;
    case "sha256" :
        return /* SHA256Type */8;
    case "tags" :
        return /* TagsType */4;
    case "unix_timestamp" :
        return /* UnixTimestampType */0;
    default:
      return ;
  }
}

function toString(f) {
  switch (f) {
    case /* UnixTimestampType */0 :
        return "unix_timestamp";
    case /* GpsTimestampType */1 :
        return "gps_timestamp";
    case /* CallstackType */2 :
        return "callstack";
    case /* ClassifiersType */3 :
        return "classifiers";
    case /* TagsType */4 :
        return "tags";
    case /* BytesType */5 :
        return "bytes";
    case /* KiloBytesType */6 :
        return "kilobytes";
    case /* GigaBytesType */7 :
        return "gigabytes";
    case /* SHA256Type */8 :
        return "sha256";
    case /* MemoryAddressType */9 :
        return "memory_address";
    case /* Ipv4Type */10 :
        return "ipv3";
    case /* TicketType */11 :
        return "issue-ticket";
    case /* IssueStateType */12 :
        return "issue-state";
    case /* AssigneeType */13 :
        return "issue-assignee";
    case /* Semver */14 :
        return "semver";
    case /* Seconds */15 :
        return "seconds";
    case /* None_ */16 :
        return "none";
    
  }
}

exports.fromString = fromString;
exports.toString = toString;
/* No side effect */
