// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var TeamsModel$BsConsole = require("./teams/TeamsModel.js");
var TeamsPanel$BsConsole = require("./teams/TeamsPanel.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Button = require("@material-ui/core/Button");
var TeamsDeleteDialog$BsConsole = require("./teams/TeamsDeleteDialog.js");
var TeamsCreateOrEditTeam$BsConsole = require("./teams/TeamsCreateOrEditTeam.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function SettingsTeamsPage(Props) {
  var config = Props.config;
  var token = Props.token;
  var match = React.useState((function () {
          return "";
        }));
  var setTeamName = match[1];
  var teamName = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setTeamDescription = match$1[1];
  var teamDescription = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setCurrentTeam = match$2[1];
  var currentTeam = match$2[0];
  var match$3 = TeamsModel$BsConsole.useTeamsPanelData(token);
  var refetchProjectMemberTeams = match$3[3];
  var refetchTeamMembers = match$3[2];
  var refetchTeams = match$3[1];
  var teamsPanelRemote = match$3[0];
  var match$4 = React.useState((function () {
          return refetchTeams;
        }));
  var setDeleteRefetch = match$4[1];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setCreateOrEditTeam = match$5[1];
  var match$6 = React.useState((function () {
          
        }));
  var setDeleteDialog = match$6[1];
  var match$7 = config.user.role;
  var isAdmin = match$7 === "admin" ? true : false;
  var numberOfTeams;
  numberOfTeams = typeof teamsPanelRemote === "number" || teamsPanelRemote.tag ? 0 : teamsPanelRemote[0][0].teams.length;
  var closeDialogForTeamCreateOrEdit = function (param) {
    Curry._1(setCreateOrEditTeam, (function (param) {
            return false;
          }));
    Curry._1(setCurrentTeam, (function (param) {
            
          }));
    Curry._1(setTeamName, (function (param) {
            return "";
          }));
    return Curry._1(setTeamDescription, (function (param) {
                  return "";
                }));
  };
  var tmp;
  var exit = 0;
  if (typeof teamsPanelRemote === "number") {
    exit = 1;
  } else if (teamsPanelRemote.tag) {
    tmp = null;
  } else {
    var match$8 = teamsPanelRemote[0][0];
    var projectMemberTeams = match$8.projectMemberTeams;
    var teamMembers = match$8.teamMembers;
    var users = match$8.users;
    var projects = match$8.projects;
    tmp = Belt_Array.map(match$8.teams, (function (team) {
            var teamMembers$1 = Belt_Array.keep(teamMembers, (function (teamMember) {
                    return teamMember.team === team.id;
                  }));
            var projectMemberTeams$1 = Belt_Array.keep(projectMemberTeams, (function (projectMemberTeam) {
                    return projectMemberTeam.team === team.id;
                  }));
            return React.createElement(TeamsPanel$BsConsole.make, {
                        config: config,
                        token: token,
                        team: team,
                        setCurrentTeam: setCurrentTeam,
                        editTeam: (function (param) {
                            return Curry._1(setCreateOrEditTeam, (function (param) {
                                          return true;
                                        }));
                          }),
                        setDeleteDialog: setDeleteDialog,
                        setDeleteRefetch: setDeleteRefetch,
                        refetchTeams: refetchTeams,
                        teamMembers: teamMembers$1,
                        refetchTeamMembers: refetchTeamMembers,
                        allUsers: users,
                        projectMemberTeams: projectMemberTeams$1,
                        refetchProjectMemberTeams: refetchProjectMemberTeams,
                        allProjects: projects
                      });
          }));
  }
  if (exit === 1) {
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          children: React.createElement(CircularProgress.default, {
                size: 150,
                color: "primary"
              })
        });
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading4 */3,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(3)),
                        /* :: */[
                          Css.fontSize(Css.px(18)),
                          /* [] */0
                        ]
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: isAdmin ? I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Teams") + (" (" + (String(numberOfTeams) + ")"))) : I18N$BsConsole.show(undefined, "Teams")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle1 */6,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(10)),
                        /* :: */[
                          Css.marginBottom(Css.px(10)),
                          /* :: */[
                            Css.fontSize(Css.px(12)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "Teams are groups of Backtrace users that reflect your organizations structure as it relates to access to specific Backtrace projects. Projects can have their access restricted such that only specific teams or users can view them. Administrators can create and edit teams.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: !isAdmin,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginTop(Css.px(10)),
                          /* :: */[
                            Css.marginBottom(Css.px(10)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setCreateOrEditTeam, (function (param) {
                                    return true;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create a team")
                }), tmp, React.createElement(TeamsCreateOrEditTeam$BsConsole.make, {
                  open: match$5[0],
                  currentTeam: currentTeam,
                  onClose: closeDialogForTeamCreateOrEdit,
                  saveChanges: (function (param) {
                      return TeamsUtils$BsConsole.handleCreateOrEditTeam(token, currentTeam, config, refetchTeams, teamName, teamDescription, closeDialogForTeamCreateOrEdit);
                    }),
                  teamName: teamName,
                  setTeamName: setTeamName,
                  teamDescription: teamDescription,
                  setTeamDescription: setTeamDescription
                }), React.createElement(TeamsDeleteDialog$BsConsole.make, {
                  token: token,
                  openDialog: match$6[0],
                  closeDialog: (function (param) {
                      Curry._1(setCurrentTeam, (function (param) {
                              
                            }));
                      return Curry._1(setDeleteDialog, (function (param) {
                                    
                                  }));
                    }),
                  refetch: match$4[0]
                }));
}

function make(config, token, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SettingsTeamsPage, {
              config: config,
              token: token
            }, children);
}

var Re = {
  make: make
};

var make$1 = SettingsTeamsPage;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
