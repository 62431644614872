// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var WfInt$BsConsole = require("./WfInt.js");
var WfBool$BsConsole = require("./WfBool.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfEnum$BsConsole = require("./WfEnum.js");
var WfFloat$BsConsole = require("./WfFloat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var WfString$BsConsole = require("./WfString.js");
var WfArrayRow$BsConsole = require("./WfArrayRow.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");
var Belt_MutableMapString = require("bs-platform/lib/js/belt_MutableMapString.js");
var BeautifulDnd$BsConsole = require("../bindings/BeautifulDnd.js");

function generateId(param) {
  return Pervasives.string_of_float(Math.random());
}

function updateArrayItemPosition(array, from, to_) {
  var otherItems = Belt_Array.concat(Belt_Array.slice(array, 0, from), Belt_Array.slice(array, from + 1 | 0, array.length));
  return Belt_Array.concat(Belt_Array.concat(Belt_Array.slice(otherItems, 0, to_), [Belt_Array.getExn(array, from)]), Belt_Array.slice(otherItems, to_, array.length));
}

var component = ReasonReact.reducerComponent("WfArray-BsConsole");

function make(btDefaults, token, fieldDoc, initialValue, onValueChange, schema, showAllErrors, remoteAction, makeWfArray, makeWfObject, param) {
  var setElementRef = function (nullableRef, param) {
    param.state.elementRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var setRemoteAction = function (param, param$1) {
    return Belt_MutableMapString.set(param$1.state.remoteActions, param[0], param[1]);
  };
  var sendNewValue = function (param) {
    var state = param.state;
    return Curry._1(onValueChange, Belt_Result.map(Belt_Result.flatMap(Belt_Result.map(Belt_Array.reduce(Belt_Array.keep(Belt_Array.map(state.arrayItems, (function (key) {
                                          return /* tuple */[
                                                  key,
                                                  Belt_MapString.getExn(state.childValues, key)
                                                ];
                                        })), (function (param) {
                                      return Caml_obj.caml_notequal(param[1], /* Ok */Block.__(0, [null]));
                                    })), /* Ok */Block.__(0, [[]]), (function (acc, param) {
                                  var v = param[1];
                                  if (acc.tag) {
                                    return /* Error */Block.__(1, [acc[0]]);
                                  }
                                  if (!v.tag) {
                                    return /* Ok */Block.__(0, [Belt_Array.concat(acc[0], [/* tuple */[
                                                      param[0],
                                                      v[0]
                                                    ]])]);
                                  }
                                  var match = v[0];
                                  return /* Error */Block.__(1, [/* tuple */[
                                              match[0],
                                              match[1]
                                            ]]);
                                })), (function (arr) {
                              return Belt_Array.map(arr, (function (param) {
                                            return param[1];
                                          }));
                            })), (function (arr) {
                          var err = WfCore$BsConsole.validateArray(schema, arr);
                          if (err !== undefined) {
                            return /* Error */Block.__(1, [/* tuple */[
                                        /* [] */0,
                                        err
                                      ]]);
                          } else {
                            return /* Ok */Block.__(0, [arr]);
                          }
                        })), (function (arr) {
                      return arr;
                    })));
  };
  var initialValues = Belt_Option.flatMap(initialValue, (function (initialValue) {
          try {
            return Json_decode.array(Util$BsConsole.identity, initialValue);
          }
          catch (exn){
            return ;
          }
        }));
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var state = param.state;
              Curry._1(remoteAction, (function (param) {
                      if (param.tag) {
                        var match = param[0];
                        if (match) {
                          var key = match[0];
                          if (key.tag) {
                            var action = Belt_MutableMapString.get(state.remoteActions, key[0]);
                            if (action !== undefined) {
                              return Curry._1(action, /* ScrollTo */Block.__(1, [match[1]]));
                            } else {
                              return ;
                            }
                          }
                          
                        }
                        var element = state.elementRef.contents;
                        if (element !== undefined) {
                          return WfCore$BsConsole.scrollToDomElement(Caml_option.valFromOption(element));
                        } else {
                          return ;
                        }
                      }
                      var match$1 = param[0];
                      if (!match$1) {
                        return ;
                      }
                      var key$1 = match$1[0];
                      if (!key$1.tag) {
                        return ;
                      }
                      var action$1 = Belt_MutableMapString.get(state.remoteActions, key$1[0]);
                      if (action$1 !== undefined) {
                        return Curry._1(action$1, /* Focus */Block.__(0, [match$1[1]]));
                      }
                      
                    }));
              var err = WfCore$BsConsole.validateArray(schema, []);
              return Curry._1(onValueChange, err !== undefined ? /* Error */Block.__(1, [/* tuple */[
                                /* [] */0,
                                err
                              ]]) : /* Ok */Block.__(0, [Json_encode.array(Util$BsConsole.identity, [])]));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var childValues = state.childValues;
              var arrayItems = state.arrayItems;
              var send = param.send;
              var handle = param.handle;
              var itemValues = Belt_Array.keep(Belt_Array.keepMap(arrayItems, (function (key) {
                          return Belt_Option.map(Belt_MapString.get(childValues, key), (function (prim) {
                                        return prim;
                                      }));
                        })), (function (v) {
                      return Caml_obj.caml_notequal(v, null);
                    }));
              var error = WfCore$BsConsole.validateArray(schema, itemValues);
              return ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.DragDropContext.make((function (e) {
                                return Curry._1(send, /* UpdatePosition */Block.__(2, [
                                              e.source.index,
                                              e.destination.index
                                            ]));
                              }), [React.createElement("div", {
                                    ref: Curry._1(handle, setElementRef)
                                  }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, WfCore$BsConsole.hasRequiredFields(/* Array */Block.__(5, [schema])), undefined, error, undefined, state.dirty, showAllErrors, [ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Droppable.make("droppable", (function (provided, param) {
                                                        return React.createElement("div", {
                                                                    ref: provided.innerRef
                                                                  }, Belt_Array.mapWithIndex(arrayItems, (function (i, key) {
                                                                          var onScalarValueChange = function (value) {
                                                                            var value$1;
                                                                            value$1 = value.tag ? /* Error */Block.__(1, [/* tuple */[
                                                                                    /* :: */[
                                                                                      /* Key */Block.__(1, [key]),
                                                                                      /* [] */0
                                                                                    ],
                                                                                    value[0]
                                                                                  ]]) : /* Ok */Block.__(0, [value[0]]);
                                                                            return Curry._1(send, /* StoreChildValue */Block.__(1, [
                                                                                          key,
                                                                                          value$1
                                                                                        ]));
                                                                          };
                                                                          var onCompoundValueChange = function (value) {
                                                                            var value$1;
                                                                            if (value.tag) {
                                                                              var match = value[0];
                                                                              value$1 = /* Error */Block.__(1, [/* tuple */[
                                                                                    /* :: */[
                                                                                      /* Key */Block.__(1, [key]),
                                                                                      match[0]
                                                                                    ],
                                                                                    match[1]
                                                                                  ]]);
                                                                            } else {
                                                                              value$1 = /* Ok */Block.__(0, [value[0]]);
                                                                            }
                                                                            return Curry._1(send, /* StoreChildValue */Block.__(1, [
                                                                                          key,
                                                                                          value$1
                                                                                        ]));
                                                                          };
                                                                          var remoteAction = function (remoteAction$1) {
                                                                            return Curry._2(handle, setRemoteAction, /* tuple */[
                                                                                        key,
                                                                                        remoteAction$1
                                                                                      ]);
                                                                          };
                                                                          var initialValue = Belt_Option.flatMap(initialValues, (function (__x) {
                                                                                  return Belt_Array.get(__x, i);
                                                                                }));
                                                                          return ReasonReact.element(key, undefined, BeautifulDnd$BsConsole.Draggable.make(key, i, undefined, (function (provided, param) {
                                                                                            var padding = "4px 17px";
                                                                                            var schema$1 = schema.childSchema;
                                                                                            var tmp;
                                                                                            var exit = 0;
                                                                                            switch (schema$1.tag | 0) {
                                                                                              case /* Int */0 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, WfInt$BsConsole.make(padding, undefined, initialValue, onScalarValueChange, schema$1[0], showAllErrors, remoteAction, token, []));
                                                                                                  break;
                                                                                              case /* Float */1 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, WfFloat$BsConsole.make(undefined, initialValue, padding, onScalarValueChange, schema$1[0], showAllErrors, remoteAction, []));
                                                                                                  break;
                                                                                              case /* Bool */2 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, WfBool$BsConsole.make(undefined, initialValue, onScalarValueChange, showAllErrors, schema$1[0], padding, remoteAction, []));
                                                                                                  break;
                                                                                              case /* Enum */3 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, WfEnum$BsConsole.make(undefined, initialValue, padding, onScalarValueChange, showAllErrors, schema$1[0], remoteAction, []));
                                                                                                  break;
                                                                                              case /* Array */5 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, Curry.app(makeWfArray, [
                                                                                                            btDefaults,
                                                                                                            token,
                                                                                                            undefined,
                                                                                                            initialValue,
                                                                                                            onCompoundValueChange,
                                                                                                            schema$1[0],
                                                                                                            showAllErrors,
                                                                                                            remoteAction,
                                                                                                            []
                                                                                                          ]));
                                                                                                  break;
                                                                                              case /* Object */6 :
                                                                                                  tmp = ReasonReact.element(undefined, undefined, Curry.app(makeWfObject, [
                                                                                                            btDefaults,
                                                                                                            token,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            initialValue,
                                                                                                            remoteAction,
                                                                                                            onCompoundValueChange,
                                                                                                            showAllErrors,
                                                                                                            schema$1[0],
                                                                                                            []
                                                                                                          ]));
                                                                                                  break;
                                                                                              case /* String */4 :
                                                                                              case /* CustomField */7 :
                                                                                                  exit = 1;
                                                                                                  break;
                                                                                              
                                                                                            }
                                                                                            if (exit === 1) {
                                                                                              tmp = ReasonReact.element(undefined, undefined, WfString$BsConsole.make(btDefaults, undefined, initialValue, padding, onScalarValueChange, showAllErrors, schema$1[0], remoteAction, []));
                                                                                            }
                                                                                            return React.cloneElement(React.createElement("div", {
                                                                                                            ref: provided.innerRef
                                                                                                          }, ReasonReact.element(undefined, undefined, WfArrayRow$BsConsole.make((function (param) {
                                                                                                                      return Curry._1(send, /* RemoveItem */Block.__(0, [key]));
                                                                                                                    }), provided.dragHandleProps, [tmp]))), provided.draggableProps);
                                                                                          })));
                                                                        })), provided.placeholder);
                                                      })))])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                            undefined,
                                            undefined,
                                            (function (param) {
                                                return Curry._1(send, /* AddNewItem */0);
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            /* Secondary */67972948,
                                            undefined,
                                            {
                                              marginTop: "-4px"
                                            },
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [
                                              ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                              I18N$BsConsole.show(undefined, "Add")
                                            ]
                                          ])))]));
            }),
          initialState: (function (param) {
              var arrayItems = [];
              var childValues = {
                contents: undefined
              };
              if (initialValues !== undefined) {
                Belt_Array.forEach(initialValues, (function (iv) {
                        var key = Pervasives.string_of_float(Math.random());
                        arrayItems.push(key);
                        childValues.contents = Belt_MapString.set(childValues.contents, key, /* Ok */Block.__(0, [iv]));
                        
                      }));
              }
              return {
                      arrayItems: arrayItems,
                      childValues: childValues.contents,
                      dirty: false,
                      remoteActions: Belt_MutableMapString.make(undefined),
                      elementRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                var key = Pervasives.string_of_float(Math.random());
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            arrayItems: Belt_Array.concat(state.arrayItems, [key]),
                            childValues: Belt_MapString.set(state.childValues, key, /* Ok */Block.__(0, [null])),
                            dirty: true,
                            remoteActions: state.remoteActions,
                            elementRef: state.elementRef
                          },
                          sendNewValue
                        ]);
              }
              switch (action.tag | 0) {
                case /* RemoveItem */0 :
                    var key$1 = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                arrayItems: Belt_Array.keep(state.arrayItems, (function (item) {
                                        return item !== key$1;
                                      })),
                                childValues: Belt_MapString.remove(state.childValues, key$1),
                                dirty: true,
                                remoteActions: state.remoteActions,
                                elementRef: state.elementRef
                              },
                              sendNewValue
                            ]);
                case /* StoreChildValue */1 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                arrayItems: state.arrayItems,
                                childValues: Belt_MapString.set(state.childValues, action[0], action[1]),
                                dirty: true,
                                remoteActions: state.remoteActions,
                                elementRef: state.elementRef
                              },
                              sendNewValue
                            ]);
                case /* UpdatePosition */2 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                arrayItems: updateArrayItemPosition(state.arrayItems, action[0], action[1]),
                                childValues: state.childValues,
                                dirty: state.dirty,
                                remoteActions: state.remoteActions,
                                elementRef: state.elementRef
                              },
                              sendNewValue
                            ]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.generateId = generateId;
exports.updateArrayItemPosition = updateArrayItemPosition;
exports.component = component;
exports.make = make;
/* component Not a pure module */
