// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var BtAutocomplete$BsConsole = require("../BtAutocomplete.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var Checkbox = require("@material-ui/core/Checkbox");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var Warning = require("@material-ui/icons/Warning");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var CardContent = require("@material-ui/core/CardContent");
var FormControl = require("@material-ui/core/FormControl");
var QueryPaginationTask$BsConsole = require("../QueryPaginationTask.js");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");
var ApiStabilityMonitoring$BsConsole = require("../ApiStabilityMonitoring.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");
var FetchStabilityMonitoring$BsConsole = require("./FetchStabilityMonitoring.js");

function getInputVal(e) {
  return e.target.value;
}

function isEmpty(value) {
  return value.trim() === "";
}

function StabilityMonitoringForms$FormDialog(Props) {
  var title = Props.title;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var onPrimarySave = Props.onPrimarySave;
  var primarySaveLabel = Props.primarySaveLabel;
  var primaryDisabledOpt = Props.primaryDisabled;
  var onDelete = Props.onDelete;
  var disabledOpt = Props.disabled;
  var error = Props.error;
  var children = Props.children;
  var primaryDisabled = primaryDisabledOpt !== undefined ? primaryDisabledOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp;
  var exit = 0;
  if (onSave !== undefined || onDelete !== undefined) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    var tmp$1 = {
      children: null
    };
    var tmp$2 = Belt_Option.isSome(onPrimarySave) ? /* spaceBetween */516682146 : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.justifyContent = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(CardContent.default, {
          children: React.createElement(Row2$BsConsole.make, tmp$1, React.createElement(Row2$BsConsole.make, {
                    children: null
                  }, onPrimarySave !== undefined && primarySaveLabel !== undefined ? React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          disabled: primaryDisabled || disabled,
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onPrimarySave, undefined);
                            }),
                          children: I18N$BsConsole.showSkip(primarySaveLabel)
                        }) : null, onSave !== undefined ? React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          disabled: disabled,
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onSave, undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Save")
                        }) : null), onDelete !== undefined ? React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: disabled,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginRight(Css.rem(0.5)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(onDelete, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Delete")
                    }) : null, React.createElement(Button.default, {
                    color: "primary",
                    onClick: (function (param) {
                        return Curry._1(onClose, undefined);
                      }),
                    children: I18N$BsConsole.show(undefined, "Cancel")
                  }))
        });
  }
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: Css.style(/* :: */[
                      Css.maxWidth(Css.px(650)),
                      /* :: */[
                        Css.minWidth(Css.px(450)),
                        /* [] */0
                      ]
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              maxWidth: "lg",
              disableBackdropClick: true,
              children: null
            }, React.createElement(CardContent.default, {
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading5 */4,
                          color: Colors$BsConsole.grey1,
                          children: I18N$BsConsole.showSkip(title)
                        }), React.createElement(Mui$BsConsole.IconButton.make, {
                          disableRipple: true,
                          ariaLabel: "Close",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.fontSize(Css.rem(1.5)),
                                  /* :: */[
                                    Css.important(Css.padding(Css.px(4))),
                                    /* [] */0
                                  ]
                                ]),
                            label: Css.style(/* :: */[
                                  Css.unsafe("fontSize", "inherit"),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            }),
                          children: React.createElement(Close.default, {
                                fontSize: "inherit"
                              })
                        })), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                      className: Css.style(/* :: */[
                            Css.margin2(Css.rem(1), /* zero */-789508312),
                            /* :: */[
                              Css.height(Css.px(4)),
                              /* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* [] */0
                              ]
                            ]
                          ])
                    })), error !== undefined ? React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.padding(Css.px(16)),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.redDark)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                              /* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redLight)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: I18N$BsConsole.dynamic(error)
                      })) : null, React.createElement(CardContent.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.overflow(/* auto */-1065951377),
                          /* [] */0
                        ])
                  },
                  children: children
                }), tmp);
}

var FormDialog = {
  make: StabilityMonitoringForms$FormDialog
};

function prepareSourceBody(state) {
  var baseBody_000 = /* tuple */[
    "name",
    state.name
  ];
  var baseBody_001 = /* :: */[
    /* tuple */[
      "coronerUrl",
      state.coronerUrl
    ],
    /* :: */[
      /* tuple */[
        "url",
        state.url
      ],
      /* :: */[
        /* tuple */[
          "submissionToken",
          state.submissionToken
        ],
        /* :: */[
          /* tuple */[
            "type",
            ApiStabilityMonitoring$BsConsole.Source.typeToString(state.type_)
          ],
          /* :: */[
            /* tuple */[
              "authenticationType",
              ApiStabilityMonitoring$BsConsole.Source.authenticationTypeToString(ApiStabilityMonitoring$BsConsole.Source.typeToAuthenticationType(state.type_))
            ],
            /* [] */0
          ]
        ]
      ]
    ]
  ];
  var baseBody = /* :: */[
    baseBody_000,
    baseBody_001
  ];
  var match = state.type_;
  if (match !== 0) {
    if (match >= 3) {
      return Json_encode.object_(Belt_List.concat(baseBody, /* :: */[
                      /* tuple */[
                        "awsCredentials",
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "accessKeyId",
                                state.accessKeyId
                              ],
                              /* :: */[
                                /* tuple */[
                                  "secretAccessKey",
                                  state.secretAccessKey
                                ],
                                /* [] */0
                              ]
                            ])
                      ],
                      /* :: */[
                        /* tuple */[
                          "redshiftConfiguration",
                          Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "region",
                                  state.region
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "clusterId",
                                    state.clusterId
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "dbUser",
                                      state.dbUser
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ])
                        ],
                        /* [] */0
                      ]
                    ]));
    } else {
      return Json_encode.object_(Belt_List.concat(baseBody, /* :: */[
                      /* tuple */[
                        "userPasswordCredentials",
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "username",
                                state.username
                              ],
                              /* :: */[
                                /* tuple */[
                                  "password",
                                  state.password
                                ],
                                /* [] */0
                              ]
                            ])
                      ],
                      /* [] */0
                    ]));
    }
  } else {
    return Json_encode.object_(Belt_List.concat(baseBody, /* :: */[
                    /* tuple */[
                      "authenticationType",
                      "none"
                    ],
                    /* [] */0
                  ]));
  }
}

function getInitialState(source, submissionToken) {
  if (source === undefined) {
    return {
            name: "",
            type_: /* Graphite */0,
            url: "",
            coronerUrl: "https://" + (String(window.location.hostname) + ""),
            username: "",
            password: "",
            accessKeyId: "",
            secretAccessKey: "",
            region: "",
            clusterId: "",
            dbUser: "",
            submissionToken: submissionToken
          };
  }
  var cred = source.userPasswordCredentials;
  var cred$1 = source.awsCredentials;
  var r = source.redshiftConfiguration;
  var r$1 = source.redshiftConfiguration;
  var r$2 = source.redshiftConfiguration;
  return {
          name: source.name,
          type_: source.type_,
          url: source.url,
          coronerUrl: source.coronerUrl,
          username: cred !== undefined ? cred.username : "",
          password: "",
          accessKeyId: cred$1 !== undefined ? cred$1.accessKeyId : "",
          secretAccessKey: "",
          region: r !== undefined ? r.region : "",
          clusterId: r$1 !== undefined ? r$1.clusterId : "",
          dbUser: r$2 !== undefined ? r$2.dbUser : "",
          submissionToken: source.submissionToken
        };
}

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetName */0 :
        return {
                name: action[0],
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetType */1 :
        return {
                name: state.name,
                type_: action[0],
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: "",
                password: "",
                accessKeyId: "",
                secretAccessKey: "",
                region: "",
                clusterId: "",
                dbUser: "",
                submissionToken: state.submissionToken
              };
    case /* SetUsername */2 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: action[0],
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetPassword */3 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: action[0],
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetAccessKeyID */4 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: action[0],
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetSecretAccessKey */5 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: action[0],
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetRegion */6 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: action[0],
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetClusterID */7 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: action[0],
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetDbUser */8 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: action[0],
                submissionToken: state.submissionToken
              };
    case /* SetUrl */9 :
        return {
                name: state.name,
                type_: state.type_,
                url: action[0],
                coronerUrl: state.coronerUrl,
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    case /* SetCoronerUrl */10 :
        return {
                name: state.name,
                type_: state.type_,
                url: state.url,
                coronerUrl: action[0],
                username: state.username,
                password: state.password,
                accessKeyId: state.accessKeyId,
                secretAccessKey: state.secretAccessKey,
                region: state.region,
                clusterId: state.clusterId,
                dbUser: state.dbUser,
                submissionToken: state.submissionToken
              };
    
  }
}

function disableButton(state) {
  var submissionToken = state.submissionToken;
  var coronerUrl = state.coronerUrl;
  var url = state.url;
  var type_ = state.type_;
  var name = state.name;
  if (type_ !== 0) {
    if (type_ >= 3) {
      if (name.trim() === "" || url.trim() === "" || coronerUrl.trim() === "" || submissionToken.trim() === "" || state.accessKeyId.trim() === "" || state.secretAccessKey.trim() === "" || state.region.trim() === "" || state.clusterId.trim() === "") {
        return true;
      } else {
        return state.dbUser.trim() === "";
      }
    } else if (name.trim() === "" || url.trim() === "" || coronerUrl.trim() === "" || submissionToken.trim() === "" || state.username.trim() === "") {
      return true;
    } else {
      return state.password.trim() === "";
    }
  } else if (name.trim() === "" || url.trim() === "" || coronerUrl.trim() === "") {
    return true;
  } else {
    return submissionToken.trim() === "";
  }
}

function StabilityMonitoringForms$Source(Props) {
  var source = Props.source;
  var projectName = Props.projectName;
  var token = Props.token;
  var onSave = Props.onSave;
  var onClose = Props.onClose;
  var submissionToken = Props.submissionToken;
  var serviceEndpoint = Props.serviceEndpoint;
  var match = React.useReducer(reducer, getInitialState(source, submissionToken));
  var state = match[0];
  var type_ = state.type_;
  var dispatch = match[1];
  var match$1 = ApiStabilityMonitoring$BsConsole.Source.typeToAuthenticationType(type_);
  var tmp;
  var exit = 0;
  switch (match$1) {
    case /* Aws */0 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                  title: I18N$BsConsole.get(undefined, "Source authentication")
                }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                  label: I18N$BsConsole.show(undefined, "Source authentication credentials")
                }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                  placeholder: I18N$BsConsole.get(undefined, "AWS access key ID"),
                  value: state.accessKeyId,
                  onChange: (function ($$event) {
                      var value = $$event.target.value;
                      return Curry._1(dispatch, /* SetAccessKeyID */Block.__(4, [value]));
                    })
                }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                  type: "password",
                  placeholder: I18N$BsConsole.get(undefined, "AWS secret access key"),
                  value: state.secretAccessKey,
                  onChange: (function ($$event) {
                      var value = $$event.target.value;
                      return Curry._1(dispatch, /* SetSecretAccessKey */Block.__(5, [value]));
                    })
                }));
        break;
    case /* Postgres */1 :
    case /* BasicAuth */3 :
        exit = 1;
        break;
    case /* Headers */2 :
    case /* None */4 :
        tmp = null;
        break;
    
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
              title: I18N$BsConsole.get(undefined, "Source authentication")
            }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
              label: I18N$BsConsole.showSkip("Source authentication credentials")
            }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
              placeholder: I18N$BsConsole.get(undefined, "Username"),
              value: state.username,
              onChange: (function ($$event) {
                  var value = $$event.target.value;
                  return Curry._1(dispatch, /* SetUsername */Block.__(2, [value]));
                })
            }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
              type: "password",
              placeholder: I18N$BsConsole.get(undefined, "Password"),
              value: state.password,
              onChange: (function ($$event) {
                  var value = $$event.target.value;
                  return Curry._1(dispatch, /* SetPassword */Block.__(3, [value]));
                })
            }));
  }
  return React.createElement(StabilityMonitoringForms$FormDialog, {
              title: source !== undefined ? I18N$BsConsole.get(undefined, "Modify metrics source") + (" - " + source.name) : I18N$BsConsole.get(undefined, "Add metrics source"),
              onClose: onClose,
              onSave: (function (param) {
                  if (source !== undefined) {
                    var onSuccess = function (_response) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully modified source"));
                    };
                    var onFailure = function (message) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed to modify source") + (": " + message)));
                    };
                    var arg = FetchStabilityMonitoring$BsConsole.makePutSourcesTask(serviceEndpoint, projectName, prepareSourceBody(state), source.id);
                    Curry._2((function (param) {
                              return (function (param$1, param$2) {
                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                });
                            })(token), undefined, (function (param) {
                            return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess, onFailure, param);
                          }));
                    Curry._1(onClose, undefined);
                    return Curry._1(onSave, undefined);
                  }
                  var onSuccess$1 = function (_response) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created source"));
                  };
                  var onFailure$1 = function (message) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed to create source") + (": " + message)));
                  };
                  var arg$1 = FetchStabilityMonitoring$BsConsole.makePostSourcesTask(serviceEndpoint, projectName, prepareSourceBody(state));
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                              });
                          })(token), undefined, (function (param) {
                          return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess$1, onFailure$1, param);
                        }));
                  Curry._1(onClose, undefined);
                  return Curry._1(onSave, undefined);
                }),
              disabled: disableButton(state),
              children: React.createElement(FormControl.default, {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: "Name",
                        title: "Name",
                        value: state.name,
                        label: I18N$BsConsole.show(undefined, "Name for identifying source in configuration UI."),
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(dispatch, /* SetName */Block.__(0, [value]));
                          })
                      }), React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: null
                      }, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                            label: I18N$BsConsole.show(undefined, "Source type")
                          }), React.createElement(Select.default, {
                            value: /* `String */[
                              -976970511,
                              ApiStabilityMonitoring$BsConsole.Source.typeToString(type_)
                            ],
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                if (typeof value === "number" || value[0] !== -976970511) {
                                  return ;
                                } else {
                                  return Curry._1(dispatch, /* SetType */Block.__(1, [ApiStabilityMonitoring$BsConsole.Source.stringToType(value[1])]));
                                }
                              }),
                            renderValue: (function (value) {
                                return React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            className: Css.style(/* :: */[
                                                  Css.padding2(/* zero */-789508312, Css.rem(1)),
                                                  /* :: */[
                                                    Css.textTransform(/* capitalize */-509867604),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            children: I18N$BsConsole.showSkip(value[1])
                                          });
                              }),
                            disableUnderline: true,
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.blackA09)),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* :: */[
                                        Css.marginLeft(Css.rem(0.5)),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            },
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  "graphite"
                                ],
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip("Graphite")
                                    })
                              }), React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  "influx"
                                ],
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip("Influx")
                                    })
                              }), React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  "postgres"
                                ],
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip("Postgres")
                                    })
                              }), React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  "redshift"
                                ],
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip("Redshift")
                                    })
                              }))), tmp, type_ >= 3 ? React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                              title: I18N$BsConsole.get(undefined, "Redshift configuration")
                            }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                              label: I18N$BsConsole.show(undefined, "AWS Redshift cluster configuration")
                            }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              placeholder: I18N$BsConsole.get(undefined, "Region"),
                              value: state.region,
                              onChange: (function ($$event) {
                                  var value = $$event.target.value;
                                  return Curry._1(dispatch, /* SetRegion */Block.__(6, [value]));
                                })
                            }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              placeholder: I18N$BsConsole.get(undefined, "Cluster ID"),
                              value: state.clusterId,
                              onChange: (function ($$event) {
                                  var value = $$event.target.value;
                                  return Curry._1(dispatch, /* SetClusterID */Block.__(7, [value]));
                                })
                            }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              placeholder: I18N$BsConsole.get(undefined, "DB user"),
                              value: state.dbUser,
                              onChange: (function ($$event) {
                                  var value = $$event.target.value;
                                  return Curry._1(dispatch, /* SetDbUser */Block.__(8, [value]));
                                })
                            })) : null, React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: I18N$BsConsole.get(undefined, "Source URL"),
                        title: I18N$BsConsole.get(undefined, "Source URL"),
                        value: state.url,
                        label: I18N$BsConsole.show(undefined, "URL address pointing to source entity."),
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(dispatch, /* SetUrl */Block.__(9, [value]));
                          })
                      }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: I18N$BsConsole.get(undefined, "Coronerd URL"),
                        title: I18N$BsConsole.get(undefined, "Coronerd URL"),
                        value: state.coronerUrl,
                        label: I18N$BsConsole.show(undefined, "URL address pointing to your Backtrace server."),
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(dispatch, /* SetCoronerUrl */Block.__(10, [value]));
                          })
                      }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: I18N$BsConsole.get(undefined, "Submission token"),
                        title: I18N$BsConsole.get(undefined, "Submission token"),
                        value: state.submissionToken,
                        label: I18N$BsConsole.show(undefined, "Backtrace submission token. Tokens can be found in the Error submission settings view."),
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(dispatch, /* SetCoronerUrl */Block.__(10, [value]));
                          })
                      }))
            });
}

var Source = {
  prepareSourceBody: prepareSourceBody,
  getInitialState: getInitialState,
  reducer: reducer,
  disableButton: disableButton,
  make: StabilityMonitoringForms$Source
};

function initialState(importer, metricGroups) {
  if (importer !== undefined) {
    return {
            name: importer.name,
            query: importer.query,
            delay: importer.delay,
            metric: importer.metric,
            metricGroup: Belt_Array.getBy(metricGroups, (function (metricGroup) {
                    return metricGroup.name === importer.metricGroup;
                  })),
            startAt: 7,
            testQueryRemote: /* NotAsked */0
          };
  } else {
    return {
            name: "",
            query: "",
            delay: 60,
            metric: "",
            metricGroup: undefined,
            startAt: 7,
            testQueryRemote: /* NotAsked */0
          };
  }
}

function makeImporterBody(state, sourceId) {
  var now = Date.now();
  var startAt = (now - state.startAt * 86400000) / 1000;
  var m = state.metricGroup;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "sourceId",
                sourceId
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  state.name.trim()
                ],
                /* :: */[
                  /* tuple */[
                    "query",
                    state.query.trim()
                  ],
                  /* :: */[
                    /* tuple */[
                      "delay",
                      state.delay
                    ],
                    /* :: */[
                      /* tuple */[
                        "metric",
                        state.metric.trim()
                      ],
                      /* :: */[
                        /* tuple */[
                          "metricGroup",
                          m !== undefined ? m.name : null
                        ],
                        /* :: */[
                          /* tuple */[
                            "startAt",
                            startAt | 0
                          ],
                          /* :: */[
                            /* tuple */[
                              "enabled",
                              true
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function disableButton$1(state) {
  if (state.name.trim() === "" || state.query.trim() === "" || state.metric.trim() === "") {
    return true;
  } else {
    return !Belt_Option.isSome(state.metricGroup);
  }
}

function reducer$1(state, action) {
  switch (action.tag | 0) {
    case /* SetName */0 :
        return {
                name: action[0],
                query: state.query,
                delay: state.delay,
                metric: state.metric,
                metricGroup: state.metricGroup,
                startAt: state.startAt,
                testQueryRemote: state.testQueryRemote
              };
    case /* SetQuery */1 :
        return {
                name: state.name,
                query: action[0],
                delay: state.delay,
                metric: state.metric,
                metricGroup: state.metricGroup,
                startAt: state.startAt,
                testQueryRemote: /* NotAsked */0
              };
    case /* SetDelay */2 :
        return {
                name: state.name,
                query: state.query,
                delay: action[0],
                metric: state.metric,
                metricGroup: state.metricGroup,
                startAt: state.startAt,
                testQueryRemote: state.testQueryRemote
              };
    case /* SetMetric */3 :
        return {
                name: state.name,
                query: state.query,
                delay: state.delay,
                metric: action[0],
                metricGroup: state.metricGroup,
                startAt: state.startAt,
                testQueryRemote: state.testQueryRemote
              };
    case /* SetMetricGroup */4 :
        return {
                name: state.name,
                query: state.query,
                delay: state.delay,
                metric: state.metric,
                metricGroup: action[0],
                startAt: state.startAt,
                testQueryRemote: state.testQueryRemote
              };
    case /* SetStartAt */5 :
        return {
                name: state.name,
                query: state.query,
                delay: state.delay,
                metric: state.metric,
                metricGroup: state.metricGroup,
                startAt: action[0],
                testQueryRemote: state.testQueryRemote
              };
    case /* SetTestQueryRemote */6 :
        return {
                name: state.name,
                query: state.query,
                delay: state.delay,
                metric: state.metric,
                metricGroup: state.metricGroup,
                startAt: state.startAt,
                testQueryRemote: action[0]
              };
    
  }
}

function StabilityMonitoringForms$Importer(Props) {
  var serviceEndpoint = Props.serviceEndpoint;
  var projectName = Props.projectName;
  var sourceId = Props.sourceId;
  var importer = Props.importer;
  var metricGroups = Props.metricGroups;
  var metrics = Props.metrics;
  var token = Props.token;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var match = React.useReducer(reducer$1, initialState(importer, metricGroups));
  var state = match[0];
  var testQueryRemote = state.testQueryRemote;
  var metricGroup = state.metricGroup;
  var query = state.query;
  var dispatch = match[1];
  var title = importer !== undefined ? I18N$BsConsole.get(undefined, "Modify importer") + (" - " + importer.name) : I18N$BsConsole.get(undefined, "Create importer");
  var tmp;
  tmp = typeof testQueryRemote === "number" ? (
      testQueryRemote === /* NotAsked */0 ? null : React.createElement(CircularProgress.default, {
              size: 16,
              color: "primary"
            })
    ) : (
      testQueryRemote.tag ? React.createElement(Row2$BsConsole.make, {
              alignItems: /* flexEnd */924268066,
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* :: */[
                          Css.fontSize(Css.rem(1.5)),
                          /* :: */[
                            Css.height(Css.rem(1.5)),
                            /* :: */[
                              Css.marginRight(Css.px(4)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(ErrorOutline.default, {
                      color: "inherit",
                      fontSize: "inherit"
                    })), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.dynamic(testQueryRemote[0])
                })) : React.createElement(Row2$BsConsole.make, {
              alignItems: /* flexEnd */924268066,
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.greenLight)),
                        /* :: */[
                          Css.marginRight(Css.px(4)),
                          /* :: */[
                            Css.fontSize(Css.rem(1.5)),
                            /* :: */[
                              Css.height(Css.rem(1.5)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(CheckCircle.default, {
                      color: "inherit",
                      fontSize: "inherit"
                    })), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.greenLight)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Success")
                }))
    );
  var tmp$1 = {
    title: title,
    onClose: onClose,
    onSave: (function (param) {
        var body = makeImporterBody(state, sourceId);
        var onSuccess = function (_response) {
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created importer"));
          return Curry._1(onSave, undefined);
        };
        var onFailure = function (message) {
          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed to create importer") + (": " + message)));
        };
        var task = importer !== undefined ? FetchStabilityMonitoring$BsConsole.makePutImportersTask(serviceEndpoint, projectName, body, importer.id) : FetchStabilityMonitoring$BsConsole.makePostImporterTask(serviceEndpoint, projectName, body);
        return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
      }),
    disabled: disableButton$1(state),
    children: React.createElement(FormControl.default, {
          className: Css.style(/* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]),
          children: null
        }, React.createElement(BtSettings$BsConsole.FormInput.make, {
              placeholder: I18N$BsConsole.get(undefined, "Name"),
              title: I18N$BsConsole.get(undefined, "Name"),
              value: state.name,
              label: I18N$BsConsole.show(undefined, "Name for identifying importer."),
              onChange: (function ($$event) {
                  var value = $$event.target.value;
                  return Curry._1(dispatch, /* SetName */Block.__(0, [value]));
                })
            }), React.createElement(BtSettings$BsConsole.FormInput.make, {
              placeholder: I18N$BsConsole.get(undefined, "Query"),
              title: I18N$BsConsole.get(undefined, "Query"),
              value: query,
              label: I18N$BsConsole.show(undefined, "Query to run against source."),
              onChange: (function ($$event) {
                  var value = $$event.target.value;
                  return Curry._1(dispatch, /* SetQuery */Block.__(1, [value]));
                }),
              rows: 3,
              rowsMax: 3,
              multiline: true
            }), React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(Button.default, {
                  variant: "outlined",
                  color: "primary",
                  disabled: query.trim() === "",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginRight(Css.rem(0.5)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      Curry._1(dispatch, /* SetTestQueryRemote */Block.__(6, [/* Loading */1]));
                      var onSuccess = function (_resp) {
                        return Curry._1(dispatch, /* SetTestQueryRemote */Block.__(6, [/* Success */Block.__(0, ["Success"])]));
                      };
                      var onFailure = function (msg) {
                        return Curry._1(dispatch, /* SetTestQueryRemote */Block.__(6, [/* Failure */Block.__(1, [msg])]));
                      };
                      var arg = FetchStabilityMonitoring$BsConsole.makeCheckSourcesTask(serviceEndpoint, projectName, sourceId, query);
                      return Curry._2((function (param) {
                                      return (function (param$1, param$2) {
                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                        });
                                    })(token), undefined, (function (param) {
                                    return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess, onFailure, param);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Test query")
                }), tmp), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
              title: I18N$BsConsole.get(undefined, "Collecting metrics data")
            }), React.createElement(Row2$BsConsole.make, {
              alignItems: /* baseline */287825029,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  children: I18N$BsConsole.show(undefined, "Start collecting data from")
                }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                  type: "number",
                  className: Css.style(/* :: */[
                        Css.important(Css.maxWidth(Css.px(100))),
                        /* :: */[
                          Css.margin2(Css.zero, Css.rem(0.5)),
                          /* :: */[
                            Css.selector("input", /* :: */[
                                  Css.textAlign(/* center */98248149),
                                  /* :: */[
                                    Css.fontSize(Css.px(13)),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  placeholder: "",
                  value: String(state.startAt),
                  onChange: (function ($$event) {
                      var value;
                      try {
                        value = Caml_format.caml_int_of_string($$event.target.value);
                      }
                      catch (exn){
                        value = 0;
                      }
                      return Curry._1(dispatch, /* SetStartAt */Block.__(5, [value]));
                    }),
                  inputProps: {
                    min: 0
                  }
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  children: I18N$BsConsole.show(undefined, "days ago")
                })), React.createElement(BtSettings$BsConsole.FormInput.make, {
              placeholder: "Delay",
              title: "Delay",
              value: String(state.delay),
              label: I18N$BsConsole.show(undefined, "Delay in seconds to run importer query."),
              onChange: (function ($$event) {
                  var value;
                  try {
                    value = Caml_format.caml_int_of_string($$event.target.value);
                  }
                  catch (exn){
                    value = 0;
                  }
                  return Curry._1(dispatch, /* SetDelay */Block.__(2, [value]));
                }),
              type: "number",
              className: Css.style(/* :: */[
                    Css.width(Css.px(200)),
                    /* [] */0
                  ])
            }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
              title: I18N$BsConsole.get(undefined, "Metric group")
            }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
              label: I18N$BsConsole.show(undefined, "Select metric group to associate importer data with.")
            }), React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (metricGroup) {
                  return metricGroup.name;
                }),
              considerations: metricGroups,
              onDelete: (function (param) {
                  return Curry._1(dispatch, /* SetMetricGroup */Block.__(4, [undefined]));
                }),
              onChange: (function (_raw, metricGroup) {
                  return Curry._1(dispatch, /* SetMetricGroup */Block.__(4, [metricGroup]));
                }),
              defaultValue: metricGroup !== undefined ? metricGroup.name : "",
              placeholder: I18N$BsConsole.get(undefined, "Metric group"),
              autoFocus: true,
              inputAriaLabel: I18N$BsConsole.get(undefined, "metric group"),
              renderListOption: (function (metricGroup, idx) {
                  return React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.showSkip(metricGroup.name),
                              key: metricGroup.name + ("__" + String(idx))
                            });
                }),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  var onFocus$prime = Curry.__1(onFocus);
                  var onChange$prime = Curry.__1(onChange);
                  return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              onFocus: onFocus$prime,
                              className: Css.style(/* :: */[
                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                    /* [] */0
                                  ]),
                              placeholder: param.placeholder,
                              value: param.value,
                              onChange: onChange$prime
                            });
                })
            }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
              title: I18N$BsConsole.get(undefined, "Metric"),
              className: Css.style(/* :: */[
                    Css.marginTop(Css.rem(1.0)),
                    /* [] */0
                  ])
            }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
              label: I18N$BsConsole.show(undefined, "Select metric value set to associate importer data with.")
            }), React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (metric) {
                  return metric.name;
                }),
              considerations: Belt_Array.keep(metrics, (function (metric) {
                      if (metricGroup !== undefined) {
                        return metricGroup.id === metric.metricGroup;
                      } else {
                        return false;
                      }
                    })),
              onDelete: (function (param) {
                  return Curry._1(dispatch, /* SetMetric */Block.__(3, [""]));
                }),
              onChange: (function (raw, _attribute) {
                  return Curry._1(dispatch, /* SetMetric */Block.__(3, [raw]));
                }),
              defaultValue: state.metric,
              placeholder: I18N$BsConsole.get(undefined, "Metric"),
              autoFocus: true,
              inputAriaLabel: I18N$BsConsole.get(undefined, "metric"),
              renderListOption: (function (metric, idx) {
                  return React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.showSkip(metric.name),
                              key: metric.name + ("__" + String(idx))
                            });
                }),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  var onFocus$prime = Curry.__1(onFocus);
                  var onChange$prime = Curry.__1(onChange);
                  return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              onFocus: onFocus$prime,
                              className: Css.style(/* :: */[
                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                    /* [] */0
                                  ]),
                              disabled: !Belt_Option.isSome(metricGroup),
                              placeholder: param.placeholder,
                              value: param.value,
                              onChange: onChange$prime
                            });
                })
            }))
  };
  var tmp$2 = metrics.length === 0 ? I18N$BsConsole.get(undefined, "No metrics exist. You must first create a metric value set in a metric group before creating an importer.") : undefined;
  if (tmp$2 !== undefined) {
    tmp$1.error = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(StabilityMonitoringForms$FormDialog, tmp$1);
}

var Importer = {
  initialState: initialState,
  makeImporterBody: makeImporterBody,
  disableButton: disableButton$1,
  reducer: reducer$1,
  make: StabilityMonitoringForms$Importer
};

function getInitialState$1(metricGroup) {
  if (metricGroup !== undefined) {
    return {
            name: metricGroup.name,
            description: Belt_Option.getWithDefault(metricGroup.description, "")
          };
  } else {
    return {
            name: "",
            description: ""
          };
  }
}

function reducer$2(state, action) {
  if (action.tag) {
    return {
            name: state.name,
            description: action[0]
          };
  } else {
    return {
            name: action[0],
            description: state.description
          };
  }
}

function makeTask(metricGroup, project, state) {
  if (metricGroup !== undefined) {
    return BpgTask$BsConsole.MetricGroup.updateFields(metricGroup, [/* tuple */[
                  "description",
                  state.description
                ]]);
  } else {
    return BpgTask$BsConsole.MetricGroup.create({
                id: 0,
                name: state.name,
                project: project.pid,
                description: state.description,
                unit: undefined,
                settings: undefined,
                metadata: undefined,
                __state: undefined,
                __create: undefined,
                __modify: undefined
              });
  }
}

function disableButton$2(state) {
  if (state.name.trim() === "") {
    return true;
  } else {
    return state.description.trim() === "";
  }
}

function StabilityMonitoringForms$MetricGroup(Props) {
  var metricGroup = Props.metricGroup;
  var project = Props.project;
  var token = Props.token;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var onOpenAttributeForm = Props.onOpenAttributeForm;
  var match = React.useReducer(reducer$2, getInitialState$1(metricGroup));
  var state = match[0];
  var name = state.name;
  var dispatch = match[1];
  var handleSave = function (param) {
    var arg = makeTask(metricGroup, project, state);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    var message = metricGroup !== undefined ? I18N$BsConsole.get(undefined, "Failed to edit metric group") : I18N$BsConsole.get(undefined, "Failed to create metric group");
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(message));
                  }
                  var message$1 = metricGroup !== undefined ? I18N$BsConsole.get(undefined, "Successfully modified metric group") : I18N$BsConsole.get(undefined, "Failed to modify metric group");
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Error") + (": " + message$1)));
                  return Curry._1(onSave, undefined);
                }));
  };
  return React.createElement(StabilityMonitoringForms$FormDialog, {
              title: metricGroup !== undefined ? I18N$BsConsole.get(undefined, "Edit") + (" - " + metricGroup.name) : I18N$BsConsole.get(undefined, "Create metric group"),
              onClose: onClose,
              onSave: (function (param) {
                  handleSave(undefined);
                  return Curry._1(onClose, undefined);
                }),
              onPrimarySave: (function (param) {
                  handleSave(undefined);
                  return Curry._1(onOpenAttributeForm, name);
                }),
              primarySaveLabel: I18N$BsConsole.get(undefined, "Save and link attributes"),
              disabled: disableButton$2(state),
              children: React.createElement(FormControl.default, {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey1,
                        children: null
                      }, React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* :: */[
                                    Css.marginRight(Css.px(2)),
                                    /* [] */0
                                  ]
                                ])
                          }, React.createElement(Info.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, "Tip: ")), I18N$BsConsole.show(undefined, "The metric group will group individual metrics together. Common examples of metric groups are 'SessionsStarted' or 'MinutesUsed'.")), metricGroup !== undefined ? null : React.createElement(BtSettings$BsConsole.FormInput.make, {
                          placeholder: I18N$BsConsole.get(undefined, "Name"),
                          title: I18N$BsConsole.get(undefined, "Name"),
                          value: name,
                          label: I18N$BsConsole.show(undefined, "Name of metric group"),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(dispatch, /* SetName */Block.__(0, [value]));
                            })
                        }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: I18N$BsConsole.get(undefined, "Description"),
                        title: I18N$BsConsole.get(undefined, "Description"),
                        value: state.description,
                        label: I18N$BsConsole.show(undefined, "Description of metric group"),
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(dispatch, /* SetDescription */Block.__(1, [value]));
                          })
                      }), React.createElement(Collapse.default, {
                        in: !disableButton$2(state),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(1)),
                                    /* [] */0
                                  ]),
                              color: Colors$BsConsole.grey1,
                              children: null
                            }, React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.accent)),
                                        /* :: */[
                                          Css.marginRight(Css.px(2)),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement(Info.default, {
                                      color: "inherit",
                                      fontSize: "inherit"
                                    })), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, "Tip: ")), I18N$BsConsole.show(undefined, "Link an attribute that you want to compute metrics by (for example, version). This attribute must exist in both Backtrace and your metric system. Later, you will define queries that use this attribute to gather metric data, and you'll be able to compute stability scores when you group by this metric in Backtrace's Explore when viewing as aggregate."))
                      }))
            });
}

var MetricGroup = {
  getInitialState: getInitialState$1,
  reducer: reducer$2,
  makeTask: makeTask,
  disableButton: disableButton$2,
  make: StabilityMonitoringForms$MetricGroup
};

function initialState$1(metricAttributes) {
  return {
          name: "",
          attributeValues: Belt_Array.map(metricAttributes, (function (metricAttribute) {
                  return /* tuple */[
                          metricAttribute.name,
                          ""
                        ];
                })),
          currentKey: "",
          currentValue: ""
        };
}

function reducer$3(state, action) {
  switch (action.tag | 0) {
    case /* SetName */0 :
        return {
                name: action[0],
                attributeValues: state.attributeValues,
                currentKey: state.currentKey,
                currentValue: state.currentValue
              };
    case /* OnAttributeValueChange */1 :
        var match = action[0];
        var value = match[1];
        var attributeName = match[0];
        var attributeValues = Belt_Array.map(state.attributeValues, (function (param) {
                var attributeName$prime = param[0];
                if (attributeName === attributeName$prime) {
                  return /* tuple */[
                          attributeName,
                          value
                        ];
                } else {
                  return /* tuple */[
                          attributeName$prime,
                          param[1]
                        ];
                }
              }));
        return {
                name: state.name,
                attributeValues: attributeValues,
                currentKey: state.currentKey,
                currentValue: state.currentValue
              };
    case /* AddMissingAttributeValues */2 :
        var attributeValues$1 = Belt_Array.reduce(action[0], state.attributeValues, (function (acc, curr) {
                return Belt_Array.concat(acc, [/* tuple */[
                              curr,
                              ""
                            ]]);
              }));
        return {
                name: state.name,
                attributeValues: attributeValues$1,
                currentKey: state.currentKey,
                currentValue: state.currentValue
              };
    
  }
}

function makeTask$1(state, metricGroup) {
  var attributeValues = JSON.stringify(Json_encode.object_(Belt_List.fromArray(Belt_Array.map(state.attributeValues, (function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    })))));
  return BpgTask$BsConsole.Metric.create({
              id: 0,
              metricGroup: metricGroup.id,
              name: state.name,
              attributeValues: attributeValues,
              metadata: undefined,
              __state: undefined,
              __create: undefined,
              __modify: undefined
            });
}

function disableButton$3(state, metricAttributes) {
  var allAttributesHaveValues = Belt_Option.isSome(Belt_Array.getBy(metricAttributes, (function (metricAttribute) {
              var _defaultValue = metricAttribute.defaultValue;
              if (_defaultValue !== undefined) {
                return true;
              } else {
                return Belt_Option.isSome(Belt_Array.getBy(state.attributeValues, (function (param) {
                                  if (metricAttribute.name === param[0]) {
                                    return param[1].trim() !== "";
                                  } else {
                                    return false;
                                  }
                                })));
              }
            })));
  if (state.name.trim() === "") {
    return true;
  } else {
    return !allAttributesHaveValues;
  }
}

function getOptions(paginationRemote, attributeName) {
  if (typeof paginationRemote === "number") {
    return [];
  }
  if (paginationRemote.tag) {
    return [];
  }
  var match = paginationRemote[0][0];
  var columns = match[0];
  var group = Belt_List.get(match[1], 0);
  if (group === undefined) {
    return [];
  }
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var distribution = Group$BsConsole.unwrapDistribution(getter(attributeName, /* Distribution */1));
  if (distribution !== undefined) {
    return Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
                  return /* tuple */[
                          FormatUtils$BsConsole.formatToString(param[0]),
                          param[1]
                        ];
                }));
  } else {
    return [];
  }
}

function StabilityMonitoringForms$MetricValue(Props) {
  var metricGroup = Props.metricGroup;
  var metricAttributes = Props.metricAttributes;
  var token = Props.token;
  var projectName = Props.projectName;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var match = React.useReducer(reducer$3, initialState$1(metricAttributes));
  var state = match[0];
  var attributeValues = state.attributeValues;
  var dispatch = match[1];
  var metricGroupName = metricGroup.name;
  var __x = Crdb$BsConsole.Aperture.$$default;
  var aperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
              /* Floating */0,
              /* Month */3
            ])), __x);
  var fold = Belt_Array.reduce(metricAttributes, Crdb$BsConsole.Fold.empty, (function (acc, curr) {
          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                          curr.name,
                          /* Distribution */Block.__(1, [50])
                        ]), acc);
        }));
  var query = /* Aggregate */Block.__(0, [
      aperture,
      undefined,
      fold,
      undefined,
      undefined,
      undefined
    ]);
  var match$1 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "filter-value-distribution");
  var paginationRemote = match$1[0];
  React.useEffect((function () {
          var missingAttributes = Belt_Array.keepMap(metricAttributes, (function (metricAttribute) {
                  if (Belt_Option.isSome(Belt_Array.getBy(attributeValues, (function (param) {
                                return param[0] === metricAttribute.name;
                              })))) {
                    return ;
                  } else {
                    return metricAttribute.name;
                  }
                }));
          if (missingAttributes.length !== 0) {
            Curry._1(dispatch, /* AddMissingAttributeValues */Block.__(2, [missingAttributes]));
          }
          
        }), [metricAttributes]);
  var match$2 = Belt_Array.getBy(metricAttributes, (function (metricAttribute) {
          return !Belt_Option.isSome(metricAttribute.defaultValue);
        }));
  return React.createElement(StabilityMonitoringForms$FormDialog, {
              title: I18N$BsConsole.get(undefined, "Create metric") + (" - " + metricGroupName),
              onClose: onClose,
              onSave: (function (param) {
                  var arg = makeTask$1(state, metricGroup);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (resp) {
                                if (resp.tag) {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to create metric"));
                                }
                                Curry._1(onClose, undefined);
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created metric"));
                                return Curry._1(onSave, undefined);
                              }));
                }),
              disabled: disableButton$3(state, metricAttributes),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: null
                }, React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.accent)),
                            /* :: */[
                              Css.marginRight(Css.px(2)),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(Info.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        })), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, "Tip: ")), I18N$BsConsole.show(undefined, "Create a metric to store each discrete piece of data you will compute error rates against. A common example of a metric is one named 'session-v1.0.0', which will store all of the sessions started in version '1.0.0' of the app. 'sessions-v1.0.1' could follow. Later, you will define an importer with a query to gather this metric data.")), React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Name"),
                      title: I18N$BsConsole.get(undefined, "Name"),
                      value: state.name,
                      label: I18N$BsConsole.show(undefined, "Name to identify metric"),
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(dispatch, /* SetName */Block.__(0, [value]));
                        })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Attribute values")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Key value pairs of metric attributes and their corresponding values (e.g. \"version\" : \"1.2.3\"). If attributes have been designated default values in the linking stage, then values may be omitted in this step.")
                    }), React.createElement("table", undefined, React.createElement("tbody", undefined, Belt_Array.map(metricAttributes, (function (metricAttribute) {
                                return React.createElement("tr", undefined, React.createElement("th", {
                                                className: Css.style(/* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.alignItems(/* center */98248149),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.height(Css.px(39)),
                                                          /* :: */[
                                                            Css.width(Css.pct(100)),
                                                            /* :: */[
                                                              Css.display(/* flex */-1010954439),
                                                              /* :: */[
                                                                Css.alignItems(/* center */98248149),
                                                                /* :: */[
                                                                  Css.padding2(/* zero */-789508312, Css.rem(0.5)),
                                                                  /* :: */[
                                                                    Css.backgroundColor(Css.hex(Colors$BsConsole.blackA09)),
                                                                    /* :: */[
                                                                      Css.borderRadius(Css.px(3)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ])
                                                  }, React.createElement(BtTypography$BsConsole.make, {
                                                        variant: /* Body1 */8,
                                                        children: I18N$BsConsole.showSkip(metricAttribute.name)
                                                      })), React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.padding(Css.px(4)),
                                                          /* [] */0
                                                        ])
                                                  }, I18N$BsConsole.showSkip(":"))), React.createElement("td", undefined, React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.display(/* flex */-1010954439),
                                                          /* [] */0
                                                        ])
                                                  }, React.createElement(BtAutocomplete$BsConsole.make, {
                                                        toString: (function (param) {
                                                            return param[0];
                                                          }),
                                                        freeSolo: true,
                                                        considerations: getOptions(paginationRemote, metricAttribute.name),
                                                        onChange: (function (raw, value) {
                                                            if (value !== undefined) {
                                                              return Curry._1(dispatch, /* OnAttributeValueChange */Block.__(1, [/* tuple */[
                                                                              metricAttribute.name,
                                                                              value[0]
                                                                            ]]));
                                                            } else {
                                                              return Curry._1(dispatch, /* OnAttributeValueChange */Block.__(1, [/* tuple */[
                                                                              metricAttribute.name,
                                                                              raw
                                                                            ]]));
                                                            }
                                                          }),
                                                        placeholder: I18N$BsConsole.get(undefined, "Value"),
                                                        renderListOption: (function (param, idx) {
                                                            return React.createElement(BtTypography$BsConsole.make, {
                                                                        variant: /* Body2 */9,
                                                                        children: I18N$BsConsole.showSkip(param[0]),
                                                                        key: metricGroup.name + ("__" + String(idx))
                                                                      });
                                                          }),
                                                        renderInput: (function (param) {
                                                            var onFocus = param.onFocus;
                                                            var onChange = param.onChange;
                                                            var onFocus$prime = Curry.__1(onFocus);
                                                            var onChange$prime = Curry.__1(onChange);
                                                            return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                                                        onFocus: onFocus$prime,
                                                                        className: Css.style(/* :: */[
                                                                              Css.marginRight(Css.rem(0.5)),
                                                                              /* :: */[
                                                                                Css.marginBottom(/* zero */-789508312),
                                                                                /* [] */0
                                                                              ]
                                                                            ]),
                                                                        placeholder: param.placeholder,
                                                                        value: param.value,
                                                                        onChange: onChange$prime
                                                                      });
                                                          })
                                                      }), React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.alignSelf(/* baseline */287825029),
                                                              /* :: */[
                                                                Css.opacity(Belt_Option.isSome(metricAttribute.defaultValue) ? 0 : 1),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      }, I18N$BsConsole.showSkip("*")))));
                              })))), match$2 !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.rem(0.5)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "* = linked attributes do not have default values and required")
                      }) : null));
}

var MetricValue = {
  initialState: initialState$1,
  reducer: reducer$3,
  makeTask: makeTask$1,
  disableButton: disableButton$3,
  getOptions: getOptions,
  make: StabilityMonitoringForms$MetricValue
};

function StabilityMonitoringForms$ItemChip(Props) {
  var name = Props.name;
  var onDelete = Props.onDelete;
  var title = Props.title;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.height(Css.px(28)),
                    /* :: */[
                      Css.padding2(Css.px(2), Css.px(4)),
                      /* :: */[
                        Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                        /* :: */[
                          Css.borderRadius(Css.px(3)),
                          /* :: */[
                            Css.margin4(/* zero */-789508312, Css.px(4), Css.px(4), /* zero */-789508312),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, title !== undefined ? React.createElement(Tooltip.default, {
                    children: React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              className: Css.style(/* :: */[
                                    Css.marginRight(Css.px(4)),
                                    /* [] */0
                                  ]),
                              color: Colors$BsConsole.grey3,
                              children: I18N$BsConsole.showSkip(name)
                            })),
                    title: title
                  }) : React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        className: Css.style(/* :: */[
                              Css.marginRight(Css.px(4)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey3,
                        children: I18N$BsConsole.showSkip(name)
                      })), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                  disableRipple: true,
                  ariaLabel: "Delete - " + name,
                  classes: {
                    root: Css.merge(/* :: */[
                          "action",
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.fontSize(Css.rem(1)),
                                  /* :: */[
                                    Css.important(Css.padding(Css.px(4))),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]),
                    label: Css.style(/* :: */[
                          Css.unsafe("fontSize", "inherit"),
                          /* [] */0
                        ])
                  },
                  onClick: onDelete,
                  title_tooltip: I18N$BsConsole.get(undefined, "Delete") + (" - " + name),
                  children: React.createElement(Delete.default, {
                        color: "inherit",
                        fontSize: "inherit"
                      })
                }));
}

var ItemChip = {
  make: StabilityMonitoringForms$ItemChip
};

function reducer$4(state, action) {
  switch (action.tag | 0) {
    case /* SetSelectedAttribute */0 :
        return {
                metricAttributes: state.metricAttributes,
                metricAttributesToDelete: state.metricAttributesToDelete,
                selectedAttribute: action[0],
                defaultValue: state.defaultValue,
                noDefaultValue: state.noDefaultValue,
                autocompleteKey: state.autocompleteKey
              };
    case /* SetDefaultValue */1 :
        return {
                metricAttributes: state.metricAttributes,
                metricAttributesToDelete: state.metricAttributesToDelete,
                selectedAttribute: state.selectedAttribute,
                defaultValue: action[0],
                noDefaultValue: state.noDefaultValue,
                autocompleteKey: state.autocompleteKey
              };
    case /* SetNoDefaultValue */2 :
        return {
                metricAttributes: state.metricAttributes,
                metricAttributesToDelete: state.metricAttributesToDelete,
                selectedAttribute: state.selectedAttribute,
                defaultValue: state.defaultValue,
                noDefaultValue: action[0],
                autocompleteKey: state.autocompleteKey
              };
    case /* AddAttribute */3 :
        var metricAttributes = Belt_Array.concat(state.metricAttributes, [action[0]]);
        return {
                metricAttributes: metricAttributes,
                metricAttributesToDelete: state.metricAttributesToDelete,
                selectedAttribute: undefined,
                defaultValue: undefined,
                noDefaultValue: false,
                autocompleteKey: state.autocompleteKey + 1 | 0
              };
    case /* AddAttributeToDelete */4 :
        var metricAttributesToDelete = Belt_Array.concat(state.metricAttributesToDelete, [action[0]]);
        return {
                metricAttributes: state.metricAttributes,
                metricAttributesToDelete: metricAttributesToDelete,
                selectedAttribute: state.selectedAttribute,
                defaultValue: state.defaultValue,
                noDefaultValue: state.noDefaultValue,
                autocompleteKey: state.autocompleteKey
              };
    case /* RemoveAttribute */5 :
        var attributeName = action[0];
        var metricAttributes$1 = Belt_Array.keep(state.metricAttributes, (function (attribute) {
                return attribute.name !== attributeName;
              }));
        return {
                metricAttributes: metricAttributes$1,
                metricAttributesToDelete: state.metricAttributesToDelete,
                selectedAttribute: state.selectedAttribute,
                defaultValue: state.defaultValue,
                noDefaultValue: state.noDefaultValue,
                autocompleteKey: state.autocompleteKey
              };
    
  }
}

var initialState_metricAttributes = [];

var initialState_metricAttributesToDelete = [];

var initialState$2 = {
  metricAttributes: initialState_metricAttributes,
  metricAttributesToDelete: initialState_metricAttributesToDelete,
  selectedAttribute: undefined,
  defaultValue: undefined,
  noDefaultValue: false,
  autocompleteKey: 0
};

function makeMetricAttribute(name, defaultValue, metricGroupId) {
  var defaultValue$1 = defaultValue !== undefined ? JSON.stringify(defaultValue) : undefined;
  return {
          id: 0,
          metricGroup: metricGroupId,
          name: name,
          defaultValue: defaultValue$1,
          metadata: undefined,
          __state: undefined,
          __create: undefined,
          __modify: undefined
        };
}

var MetricAttributeBulkTask = BpgTask$BsConsole.MakeBatchBpgTask1({
      table: BacktraceParserGenerator$BsConsole.MetricAttribute.table,
      to_json: BacktraceParserGenerator$BsConsole.MetricAttribute.to_json,
      of_json: BacktraceParserGenerator$BsConsole.MetricAttribute.of_json,
      Key: BacktraceParserGenerator$BsConsole.MetricAttribute.Key,
      to_key: BacktraceParserGenerator$BsConsole.MetricAttribute.to_key
    });

function StabilityMonitoringForms$MetricAttribute(Props) {
  var attributes = Props.attributes;
  var metricGroup = Props.metricGroup;
  var initialMetricAttributes = Props.initialMetricAttributes;
  var onOpenMetricForm = Props.onOpenMetricForm;
  var token = Props.token;
  var onClose = Props.onClose;
  var onCreate = Props.onCreate;
  var match = React.useReducer(reducer$4, initialState$2);
  var dispatch = match[1];
  var match$1 = match[0];
  var noDefaultValue = match$1.noDefaultValue;
  var defaultValue = match$1.defaultValue;
  var selectedAttribute = match$1.selectedAttribute;
  var metricAttributesToDelete = match$1.metricAttributesToDelete;
  var metricAttributes = match$1.metricAttributes;
  var metricGroupName = metricGroup.name;
  var selectedAttributes = Belt_Array.map(metricAttributes, (function (metricAttribute) {
          return metricAttribute.name;
        }));
  var title = initialMetricAttributes.length !== 0 ? I18N$BsConsole.get(undefined, "Edit linked attributes") + (" - " + metricGroupName) : I18N$BsConsole.get(undefined, "Link attributes") + (" - " + metricGroupName);
  var handleOnSave = function (param) {
    var deleteActions = Belt_Array.map(metricAttributesToDelete, (function (metricAttribute) {
            return Curry._1(MetricAttributeBulkTask.$$delete, metricAttribute);
          }));
    var createActions = Belt_Array.map(metricAttributes, (function (metricAttribute) {
            return Curry._1(MetricAttributeBulkTask.create, metricAttribute);
          }));
    return Curry._1(MetricAttributeBulkTask.makeTask, Belt_Array.concat(deleteActions, createActions));
  };
  var supportLink = Href$BsConsole.Support.make(undefined, /* ManageTheBacktraceMetricObjects */20);
  var tmp;
  if (noDefaultValue) {
    var supportLink$1 = Href$BsConsole.Support.make(undefined, /* ManageTheBacktraceMetricObjects */20);
    tmp = supportLink$1 !== undefined ? React.createElement(Row2$BsConsole.make, {
            className: Css.style(/* :: */[
                  Css.alignItems(/* center */98248149),
                  /* [] */0
                ]),
            children: null
          }, React.createElement(Warning.default, {
                className: Css.style(/* :: */[
                      Css.marginRight(Css.rem(0.5)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* [] */0
                      ]
                    ])
              }), React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: Css.style(/* :: */[
                      Css.marginTop(Css.rem(0.5)),
                      /* [] */0
                    ]),
                children: null
              }, I18N$BsConsole.show(undefined, "Event messages received for this metric group which are missing a linked attribute will be discarded if no default value is configured. See this documentation for more detail: "), React.createElement("a", {
                    className: Css.style(/* :: */[
                          Css.important(Css.textDecoration(/* underline */131142924)),
                          /* [] */0
                        ]),
                    href: supportLink$1,
                    rel: "noopener noreferrer",
                    target: "_blank"
                  }, I18N$BsConsole.show(undefined, "Manage the Backtrace Metric Objects")))) : null;
  } else {
    tmp = null;
  }
  return React.createElement(StabilityMonitoringForms$FormDialog, {
              title: title,
              onClose: onClose,
              onSave: (function (param) {
                  var arg = handleOnSave(undefined);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (resp) {
                                if (resp.tag) {
                                  var err = resp[0];
                                  var message = err[0] === BpgTask$BsConsole.BpgError ? err[2] : I18N$BsConsole.get(undefined, "Unknown BPG error");
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed linking attributes to metric group") + (": " + message)));
                                } else {
                                  var errors = Belt_Array.keep(resp[0], (function (param) {
                                          if (param.tag) {
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        }));
                                  if (errors.length !== 0) {
                                    Belt_Array.forEach(errors, (function (param) {
                                            if (!param.tag) {
                                              return ;
                                            }
                                            var match = param[0];
                                            if (match[0] === BpgTask$BsConsole.BpgError) {
                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Error") + (": " + match[2])));
                                            }
                                            
                                          }));
                                  } else {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated metric attribute"));
                                  }
                                }
                                Curry._1(onCreate, undefined);
                                return Curry._1(onClose, undefined);
                              }));
                }),
              onPrimarySave: (function (param) {
                  var arg = handleOnSave(undefined);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (resp) {
                                if (resp.tag) {
                                  var err = resp[0];
                                  var message = err[0] === BpgTask$BsConsole.BpgError ? err[2] : "Unknown BPG error";
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Error") + (": " + message)));
                                } else {
                                  var errors = Belt_Array.keep(resp[0], (function (param) {
                                          if (param.tag) {
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        }));
                                  if (errors.length !== 0) {
                                    Belt_Array.forEach(errors, (function (param) {
                                            if (!param.tag) {
                                              return ;
                                            }
                                            var match = param[0];
                                            if (match[0] === BpgTask$BsConsole.BpgError) {
                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Error") + (": " + match[2])));
                                            }
                                            
                                          }));
                                  } else {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated metric attribute"));
                                  }
                                }
                                Curry._1(onCreate, undefined);
                                return Curry._1(onOpenMetricForm, metricGroup);
                              }));
                }),
              primarySaveLabel: I18N$BsConsole.get(undefined, "Save and add metrics"),
              disabled: (metricAttributes.length - metricAttributesToDelete.length | 0) <= 0,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: null
                }, React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.accent)),
                            /* :: */[
                              Css.marginRight(Css.px(2)),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(Info.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        })), supportLink !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("strong", undefined, I18N$BsConsole.show(undefined, "Tip: ")), I18N$BsConsole.show(undefined, "Link an attribute that you want to compute metrics by (for example, version). This attribute must exist in both Backtrace and your metric system. Later, you will define queries that use this attribute to gather metric data, and you'll be able to compute stability scores when you group by this metric in Backtrace's Explore when viewing as aggregate. Please see the following documentation for more information: "), React.createElement("a", {
                            className: Css.style(/* :: */[
                                  Css.important(Css.textDecoration(/* underline */131142924)),
                                  /* [] */0
                                ]),
                            href: supportLink,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, I18N$BsConsole.show(undefined, "Manage the Backtrace Metric Objects"))) : null), React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Link attributes")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Attach one or more attributes that are present in both Backtrace error reporting and metrics reports.")
                    }), React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                            /* :: */[
                              Css.borderRadius(Css.px(2)),
                              /* :: */[
                                Css.marginBottom(Css.rem(1)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.padding(Css.rem(0.5)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("> div", /* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                children: null
                              }, React.createElement(BtAutocomplete$BsConsole.make, {
                                    toString: (function (attr) {
                                        return attr.name;
                                      }),
                                    considerations: Belt_Array.keep(Belt_Array.keep(attributes, Attribute_Helpers$BsConsole.filterFirstSeen), (function (attribute) {
                                            return !selectedAttributes.includes(attribute.name);
                                          })),
                                    onDelete: (function (param) {
                                        return Curry._1(dispatch, /* SetSelectedAttribute */Block.__(0, [undefined]));
                                      }),
                                    onChange: (function (raw, _attribute) {
                                        return Curry._1(dispatch, /* SetSelectedAttribute */Block.__(0, [raw]));
                                      }),
                                    defaultValue: Belt_Option.getWithDefault(selectedAttribute, ""),
                                    placeholder: I18N$BsConsole.get(undefined, "Attribute"),
                                    autoFocus: true,
                                    inputAriaLabel: I18N$BsConsole.get(undefined, "attribute"),
                                    renderListOption: (function (attr, idx) {
                                        return React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body2 */9,
                                                    children: I18N$BsConsole.showSkip(attr.name),
                                                    key: attr.name + ("__" + String(idx))
                                                  });
                                      }),
                                    renderInput: (function (param) {
                                        var onFocus = param.onFocus;
                                        var onChange = param.onChange;
                                        var onFocus$prime = Curry.__1(onFocus);
                                        var onChange$prime = Curry.__1(onChange);
                                        return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                                    onFocus: onFocus$prime,
                                                    className: Css.style(/* :: */[
                                                          Css.width(Css.pct(100)),
                                                          /* :: */[
                                                            Css.marginBottom(Css.rem(0.5)),
                                                            /* [] */0
                                                          ]
                                                        ]),
                                                    placeholder: param.placeholder,
                                                    value: param.value,
                                                    onChange: onChange$prime
                                                  });
                                      }),
                                    key: String(match$1.autocompleteKey)
                                  }), React.createElement(Row2$BsConsole.make, {
                                    className: Css.style(/* :: */[
                                          Css.alignItems(/* center */98248149),
                                          /* :: */[
                                            Css.justifyContent(/* spaceAround */-485895757),
                                            /* [] */0
                                          ]
                                        ]),
                                    children: null
                                  }, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                        className: Css.style(/* :: */[
                                              Css.marginBottom(/* zero */-789508312),
                                              /* :: */[
                                                Css.flex(/* `num */[
                                                      5496390,
                                                      2
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]),
                                        disabled: noDefaultValue === true,
                                        placeholder: I18N$BsConsole.get(undefined, "Default metric value"),
                                        value: Belt_Option.getWithDefault(defaultValue, ""),
                                        onChange: (function ($$event) {
                                            var v = $$event.target.value.trim();
                                            var value = v === "" ? undefined : v;
                                            return Curry._1(dispatch, /* SetDefaultValue */Block.__(1, [value]));
                                          })
                                      }), React.createElement(FormControlLabel.default, {
                                        label: I18N$BsConsole.get(undefined, "No default"),
                                        control: React.createElement(Checkbox.default, {
                                              checked: noDefaultValue,
                                              color: "primary",
                                              onChange: (function ($$event) {
                                                  var value = $$event.target.checked;
                                                  return Curry._1(dispatch, /* SetNoDefaultValue */Block.__(2, [value]));
                                                })
                                            }),
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(0.25)),
                                                  /* [] */0
                                                ]
                                              ])
                                        }
                                      })), tmp), React.createElement(Button.default, {
                                color: "primary",
                                disabled: !(Belt_Option.isSome(selectedAttribute) && (noDefaultValue === true || Belt_Option.isSome(defaultValue))),
                                onClick: (function (param) {
                                    var name = Belt_Option.getWithDefault(selectedAttribute, "");
                                    var metricAttribute = makeMetricAttribute(name, defaultValue, metricGroup.id);
                                    return Curry._1(dispatch, /* AddAttribute */Block.__(3, [metricAttribute]));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Add")
                              }))
                    }), React.createElement(Row2$BsConsole.make, {
                      flexWrap: /* wrap */-822134326,
                      children: null
                    }, Belt_Array.mapWithIndex(Belt_Array.keep(initialMetricAttributes, (function (attribute) {
                                return !Belt_Option.isSome(Belt_Array.getBy(metricAttributesToDelete, (function (attribute$prime) {
                                                  return attribute$prime.name === attribute.name;
                                                })));
                              })), (function (idx, attribute) {
                            return React.createElement(StabilityMonitoringForms$ItemChip, {
                                        name: attribute.name,
                                        onDelete: (function (param) {
                                            return Curry._1(dispatch, /* AddAttributeToDelete */Block.__(4, [attribute]));
                                          }),
                                        key: attribute.name + ("__" + String(idx))
                                      });
                          })), Belt_Array.mapWithIndex(metricAttributes, (function (idx, attribute) {
                            return React.createElement(StabilityMonitoringForms$ItemChip, {
                                        name: attribute.name,
                                        onDelete: (function (param) {
                                            return Curry._1(dispatch, /* RemoveAttribute */Block.__(5, [attribute.name]));
                                          }),
                                        key: attribute.name + ("__" + String(idx))
                                      });
                          })))));
}

var MetricAttribute = {
  reducer: reducer$4,
  initialState: initialState$2,
  makeMetricAttribute: makeMetricAttribute,
  MetricAttributeBulkTask: MetricAttributeBulkTask,
  make: StabilityMonitoringForms$MetricAttribute
};

function StabilityMonitoringForms$Delete(Props) {
  var onClose = Props.onClose;
  var onDelete = Props.onDelete;
  var entityName = Props.entityName;
  var warningLabel = Props.warningLabel;
  return React.createElement(StabilityMonitoringForms$FormDialog, {
              title: I18N$BsConsole.get(undefined, "Delete") + (" - " + entityName),
              onClose: onClose,
              onDelete: onDelete,
              children: null
            }, warningLabel !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    children: null
                  }, React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.redDark)),
                              /* [] */0
                            ])
                      }, I18N$BsConsole.show(undefined, "Warning:")), I18N$BsConsole.showSkip(" " + warningLabel)) : null, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.show(undefined, "Are you sure you would like to delete this item?")
                }));
}

var Delete$1 = {
  make: StabilityMonitoringForms$Delete
};

exports.getInputVal = getInputVal;
exports.isEmpty = isEmpty;
exports.FormDialog = FormDialog;
exports.Source = Source;
exports.Importer = Importer;
exports.MetricGroup = MetricGroup;
exports.MetricValue = MetricValue;
exports.ItemChip = ItemChip;
exports.MetricAttribute = MetricAttribute;
exports.Delete = Delete$1;
/* MetricAttributeBulkTask Not a pure module */
