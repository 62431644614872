// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var Text$BsConsole = require("./Text.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var Numeral$BsConsole = require("./Numeral.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BpgController$BsConsole = require("./BpgController.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var RetentionPolicy$BsConsole = require("./RetentionPolicy.js");
var SettingsContainer$BsConsole = require("./SettingsContainer.js");
var BillingLimitsFetch$BsConsole = require("./BillingLimitsFetch.js");
var RetentionPolicyTable$BsConsole = require("./RetentionPolicyTable.js");
var RetentionPolicyStatus$BsConsole = require("./RetentionPolicyStatus.js");

function secondsToDays(seconds) {
  return Numeral$BsConsole.format("0.0", Pervasives.string_of_float(Caml_format.caml_int_of_string(seconds) / 86400));
}

var days = Css.style(/* :: */[
      Css.maxWidth(Css.em(5)),
      /* :: */[
        Css.unsafe("margin", "0 5px !important"),
        /* [] */0
      ]
    ]);

var errorMessage = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.errorDark))),
      /* [] */0
    ]);

var component = RR$BsConsole.reducerComponent("SettingsRetentionPolicy-BsConsole__Dialog");

function make(errorOpt, policyOpt, ruleOpt, onClose, title, handleSubmit, _children) {
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var policy = policyOpt !== undefined ? Caml_option.valFromOption(policyOpt) : undefined;
  var rule = ruleOpt !== undefined ? Caml_option.valFromOption(ruleOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.showSkip(title)])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                          error !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, errorMessage, undefined, undefined, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                /* String_literal */Block.__(11, [
                                                                    "Error: ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* End_of_format */0
                                                                      ])
                                                                  ]),
                                                                "Error: %s"
                                                              ]), error)])) : null,
                                          ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, RetentionPolicy$BsConsole.Action.toString(self.state.actionType), (function (param, value) {
                                                      return Curry._1(self.send, /* SetAction */Block.__(0, [RetentionPolicy$BsConsole.Action.fromString(value)]));
                                                    }), "deletion-method", [
                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                              RetentionPolicy$BsConsole.Action.toString(/* DeleteAll */0),
                                                              Caml_option.some(Text$BsConsole.get(/* RP_Delete_All */11)),
                                                              Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Secondary */67972948,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))),
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              Belt_Option.isSome(rule) || (
                                                                policy !== undefined ? Belt_Option.isSome(Belt_List.getBy(Belt_List.fromArray(policy), (function (rule) {
                                                                              if (rule.action.type_ === /* Delete */0) {
                                                                                return !Belt_Option.isSome(rule.action.subsets);
                                                                              } else {
                                                                                return false;
                                                                              }
                                                                            }))) : false
                                                              ),
                                                              undefined,
                                                              []
                                                            ])),
                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                              RetentionPolicy$BsConsole.Action.toString(/* DeleteOnlyPhysical */1),
                                                              Caml_option.some(Text$BsConsole.get(/* RP_Delete_All_Physical */12)),
                                                              Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Secondary */67972948,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))),
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              Belt_Option.isSome(rule) || (
                                                                policy !== undefined ? Belt_Option.isSome(Belt_List.getBy(Belt_List.fromArray(policy), (function (rule) {
                                                                              if (rule.action.type_ === /* Delete */0) {
                                                                                return Belt_Option.isSome(rule.action.subsets);
                                                                              } else {
                                                                                return false;
                                                                              }
                                                                            }))) : false
                                                              ),
                                                              undefined,
                                                              []
                                                            ])),
                                                    CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.46.2") ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                RetentionPolicy$BsConsole.Action.toString(/* CompressOnlyPhysical */2),
                                                                Caml_option.some(Text$BsConsole.get(/* RP_Compress_All_Physical */13)),
                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              /* Secondary */67972948,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              []
                                                                            ]))),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                Belt_Option.isSome(rule) || (
                                                                  policy !== undefined ? Belt_Option.isSome(Belt_List.getBy(Belt_List.fromArray(policy), (function (rule) {
                                                                                return rule.action.type_ === /* Compress */1;
                                                                              }))) : false
                                                                ),
                                                                undefined,
                                                                []
                                                              ])) : null
                                                  ])),
                                          ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "after ")])),
                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                              days + " project-settings-textfield textfield-number",
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              (function ($$event) {
                                                                  var value = $$event.target.value;
                                                                  try {
                                                                    Caml_format.caml_float_of_string(value);
                                                                    Curry._1(self.send, /* SetError */Block.__(2, [undefined]));
                                                                  }
                                                                  catch (exn){
                                                                    Curry._1(self.send, /* SetError */Block.__(2, [I18N$BsConsole.get(undefined, "invalid number")]));
                                                                  }
                                                                  return Curry._1(self.send, /* SetDays */Block.__(1, [value]));
                                                                }),
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              Belt_Option.isSome(self.state.error),
                                                              undefined,
                                                              "number",
                                                              /* `String */[
                                                                -976970511,
                                                                self.state.days
                                                              ],
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              []
                                                            ])),
                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "days")]))
                                                  ]))
                                        ])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    Curry.__1(onClose),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Secondary */67972948,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                  ])),
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        var time = String(Caml_format.caml_float_of_string(self.state.days) * 86400 | 0);
                                                        var rule = RetentionPolicy$BsConsole.makeRule(time, self.state.actionType);
                                                        return Curry._1(handleSubmit, JSON.stringify(RetentionPolicy$BsConsole.toJson(policy !== undefined ? Belt_Array.concat(Belt_Array.keep(policy, (function (r) {
                                                                                        var s = r.action.subsets;
                                                                                        if (s !== undefined) {
                                                                                          if (Belt_Array.getExn(s, 0) === "physical") {
                                                                                            return self.state.actionType !== /* DeleteOnlyPhysical */1;
                                                                                          } else {
                                                                                            return false;
                                                                                          }
                                                                                        } else {
                                                                                          return self.state.actionType !== /* DeleteAll */0;
                                                                                        }
                                                                                      })), [rule]) : [rule])));
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    /* Secondary */67972948,
                                                    undefined,
                                                    undefined,
                                                    Belt_Option.isSome(self.state.error),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "submit")]
                                                  ]))
                                        ]))
                              ]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      actionType: /* DeleteAll */0,
                      days: "30.0",
                      error: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetAction */0 :
                    return /* Update */Block.__(0, [{
                                actionType: action[0],
                                days: state.days,
                                error: state.error
                              }]);
                case /* SetDays */1 :
                    return /* Update */Block.__(0, [{
                                actionType: state.actionType,
                                days: action[0],
                                error: state.error
                              }]);
                case /* SetError */2 :
                    return /* Update */Block.__(0, [{
                                actionType: state.actionType,
                                days: state.days,
                                error: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var CreateModifyDialog = {
  days: days,
  errorMessage: errorMessage,
  component: component,
  make: make
};

var errorMessage$1 = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.errorDark))),
      /* [] */0
    ]);

var component$1 = RR$BsConsole.statelessComponent("SettingsRetentionPolicy-BsConsole__DeleteDialog");

function make$1(error, onClose, handleSubmit, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete rule")])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                          error !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, errorMessage$1, undefined, undefined, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                /* String_literal */Block.__(11, [
                                                                    "Error: ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* End_of_format */0
                                                                      ])
                                                                  ]),
                                                                "Error: %s"
                                                              ]), error)])) : null,
                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Are you sure you would like to delete this rule?")]))
                                        ])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    Curry.__1(onClose),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Secondary */67972948,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                  ])),
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        return Curry._1(handleSubmit, undefined);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    /* Secondary */67972948,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Delete")]
                                                  ]))
                                        ]))
                              ]
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var DeleteDialog = {
  errorMessage: errorMessage$1,
  component: component$1,
  make: make$1
};

var email = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
      /* [] */0
    ]);

var component$2 = RR$BsConsole.statelessComponent("SettingsRetentionPolicy-BsConsole__SelfServiceDialog");

function make$2(onClose, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Attention!")])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [Text$BsConsole.get(/* SelfServiceRetentionPolicy */10)])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    Curry.__1(onClose),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    /* Secondary */67972948,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Close")]
                                                  ]))]))
                              ]
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var SelfServiceDialog = {
  email: email,
  component: component$2,
  make: make$2
};

var component$3 = RR$BsConsole.reducerComponent("SettingsRetentionPolicy-BsConsole__InstanceTable");

function make$3(isEnterprise, handleTask, token, onSuccess, rules, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var policy = Belt_List.get(rules, 0);
              var policy$prime = policy !== undefined ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined;
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (typeof match === "number") {
                  tmp = ReasonReact.element(undefined, undefined, make$2((function (param) {
                              return Curry._1(self.send, /* Dialog */[undefined]);
                            }), []));
                } else {
                  switch (match.tag | 0) {
                    case /* Create */0 :
                        var policy$1 = match[0];
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Create.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseCreate */36]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (_state, handleCreateSubmit, _toKey) {
                                    return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Modify.make, (function (param, param$1) {
                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                                      Curry._1(self.send, /* Dialog */[undefined]);
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseCreate */36]));
                                                      return Curry._1(onSuccess, undefined);
                                                    }), handleTask, token, (function (state, handleModifySubmit, _toKey) {
                                                      var msg = state.remote;
                                                      var tmp;
                                                      tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                                      return ReasonReact.element(undefined, undefined, make(Caml_option.some(tmp), Caml_option.some(policy$1), undefined, (function (param) {
                                                                        return Curry._1(self.send, /* Dialog */[undefined]);
                                                                      }), I18N$BsConsole.get(undefined, "Add new rule"), policy$1 !== undefined ? (function (rules) {
                                                                          return Curry._1(handleModifySubmit, /* tuple */[
                                                                                      {
                                                                                        id: 1,
                                                                                        rules: JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$1)),
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      (function (policy) {
                                                                                          return {
                                                                                                  id: policy.id,
                                                                                                  rules: rules,
                                                                                                  __state: policy.__state,
                                                                                                  __create: policy.__create,
                                                                                                  __modify: policy.__modify
                                                                                                };
                                                                                        }),
                                                                                      Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }) : (function (rules) {
                                                                          return Curry._1(handleCreateSubmit, /* tuple */[
                                                                                      {
                                                                                        id: 0,
                                                                                        rules: rules,
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      Util$BsConsole.identity,
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }), []));
                                                    })));
                                  })));
                        break;
                    case /* Modify */1 :
                        var rule = match[1];
                        var policy$2 = match[0];
                        var policy$prime$1;
                        try {
                          policy$prime$1 = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$2.rules));
                        }
                        catch (raw_msg){
                          var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                          if (msg[0] === Json_decode.DecodeError) {
                            console.log(msg[1]);
                            policy$prime$1 = [];
                          } else {
                            throw msg;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully modified"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseEdit */37]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                    var msg = state.remote;
                                    var tmp;
                                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                    return ReasonReact.element(undefined, undefined, make(Caml_option.some(tmp), Caml_option.some(policy$prime$1), Caml_option.some(rule), (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), I18N$BsConsole.get(undefined, "Modify rule"), (function (_rules) {
                                                      var rules = JSON.stringify(RetentionPolicy$BsConsole.toJson(Belt_Array.keep(policy$prime$1, (function (r) {
                                                                      return r.key !== rule.key;
                                                                    }))));
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$2,
                                                                  (function (policy) {
                                                                      return {
                                                                              id: policy.id,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    case /* Delete */2 :
                        var rule$1 = match[1];
                        var policy$3 = match[0];
                        var policy$prime$2;
                        try {
                          policy$prime$2 = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$3.rules));
                        }
                        catch (raw_msg$1){
                          var msg$1 = Caml_js_exceptions.internalToOCamlException(raw_msg$1);
                          if (msg$1[0] === Json_decode.DecodeError) {
                            console.log(msg$1[1]);
                            policy$prime$2 = [];
                          } else {
                            throw msg$1;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully deleted"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseDelete */38]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                    var rules = JSON.stringify(RetentionPolicy$BsConsole.toJson(Belt_Array.keep(policy$prime$2, (function (r) {
                                                    return r.key !== rule$1.key;
                                                  }))));
                                    var msg = state.remote;
                                    var tmp;
                                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                    return ReasonReact.element(undefined, undefined, make$1(tmp, (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), (function (param) {
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$3,
                                                                  (function (policy) {
                                                                      return {
                                                                              id: policy.id,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    
                  }
                }
              } else {
                tmp = null;
              }
              return React.createElement(React.Fragment, undefined, ReasonReact.element(policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__", undefined, RetentionPolicyStatus$BsConsole.Instance.make(token, (function (remote, _update) {
                                    return ReasonReact.element("instance" + (
                                                policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                              ), undefined, RetentionPolicyTable$BsConsole.Expansion.make("instance", Caml_option.some(policy !== undefined && policy.rules.trim() !== "" && policy.rules.trim() !== "[]" ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined), isEnterprise ? (function (param) {
                                                        return Curry._1(self.send, /* Dialog */[/* Create */Block.__(0, [policy$prime])]);
                                                      }) : (function (param) {
                                                        return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                      }), isEnterprise ? (function (rule) {
                                                        if (policy !== undefined) {
                                                          return Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                          policy,
                                                                          rule
                                                                        ])]);
                                                        }
                                                        
                                                      }) : (function (param) {
                                                        return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                      }), isEnterprise ? (function (rule) {
                                                        if (policy !== undefined) {
                                                          return Curry._1(self.send, /* Dialog */[/* Delete */Block.__(2, [
                                                                          policy,
                                                                          rule
                                                                        ])]);
                                                        }
                                                        
                                                      }) : (function (param) {
                                                        return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                      }), remote, []));
                                  }))), tmp);
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var InstanceTable = {
  component: component$3,
  make: make$3
};

var component$4 = RR$BsConsole.reducerComponent("SettingsRetentionPolicy-BsConsole__UniverseTable");

function make$4(isEnterprise, universes, handleTask, token, onSuccess, rules, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (typeof match === "number") {
                  tmp = ReasonReact.element(undefined, undefined, make$2((function (param) {
                              return Curry._1(self.send, /* Dialog */[undefined]);
                            }), []));
                } else {
                  switch (match.tag | 0) {
                    case /* Create */0 :
                        var policy = match[1];
                        var uid = match[0];
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Create.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseCreate */36]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (_state, handleCreateSubmit, _toKey) {
                                    return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Modify.make, (function (param, param$1) {
                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                                      Curry._1(self.send, /* Dialog */[undefined]);
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseCreate */36]));
                                                      return Curry._1(onSuccess, undefined);
                                                    }), handleTask, token, (function (state, handleModifySubmit, _toKey) {
                                                      var msg = state.remote;
                                                      var tmp;
                                                      tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                                      return ReasonReact.element(undefined, undefined, make(Caml_option.some(tmp), Caml_option.some(policy), undefined, (function (param) {
                                                                        return Curry._1(self.send, /* Dialog */[undefined]);
                                                                      }), I18N$BsConsole.get(undefined, "Add new rule"), policy !== undefined ? (function (rules) {
                                                                          return Curry._1(handleModifySubmit, /* tuple */[
                                                                                      {
                                                                                        universe: uid,
                                                                                        rules: JSON.stringify(RetentionPolicy$BsConsole.toJson(policy)),
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      (function (policy) {
                                                                                          return {
                                                                                                  universe: policy.universe,
                                                                                                  rules: rules,
                                                                                                  __state: policy.__state,
                                                                                                  __create: policy.__create,
                                                                                                  __modify: policy.__modify
                                                                                                };
                                                                                        }),
                                                                                      Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }) : (function (rules) {
                                                                          return Curry._1(handleCreateSubmit, /* tuple */[
                                                                                      {
                                                                                        universe: uid,
                                                                                        rules: rules,
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      Util$BsConsole.identity,
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }), []));
                                                    })));
                                  })));
                        break;
                    case /* Modify */1 :
                        var rule = match[1];
                        var policy$1 = match[0];
                        var policy$prime;
                        try {
                          policy$prime = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$1.rules));
                        }
                        catch (raw_msg){
                          var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                          if (msg[0] === Json_decode.DecodeError) {
                            console.log(msg[1]);
                            policy$prime = [];
                          } else {
                            throw msg;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully modified"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseEdit */37]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                    var msg = state.remote;
                                    var tmp;
                                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                    return ReasonReact.element(undefined, undefined, make(Caml_option.some(tmp), Caml_option.some(policy$prime), Caml_option.some(rule), (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), I18N$BsConsole.get(undefined, "Modify rule"), (function (_rules) {
                                                      var rules = JSON.stringify(RetentionPolicy$BsConsole.toJson(Belt_Array.keep(policy$prime, (function (r) {
                                                                      return r.key !== rule.key;
                                                                    }))));
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$1,
                                                                  (function (policy) {
                                                                      return {
                                                                              universe: policy.universe,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    case /* Delete */2 :
                        var rule$1 = match[1];
                        var policy$2 = match[0];
                        var policy$prime$1;
                        try {
                          policy$prime$1 = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$2.rules));
                        }
                        catch (raw_msg$1){
                          var msg$1 = Caml_js_exceptions.internalToOCamlException(raw_msg$1);
                          if (msg$1[0] === Json_decode.DecodeError) {
                            console.log(msg$1[1]);
                            policy$prime$1 = [];
                          } else {
                            throw msg$1;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully deleted"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_UniverseDelete */38]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                    var rules = JSON.stringify(RetentionPolicy$BsConsole.toJson(Belt_Array.keep(policy$prime$1, (function (r) {
                                                    return r.key !== rule$1.key;
                                                  }))));
                                    var msg = state.remote;
                                    var tmp;
                                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                    return ReasonReact.element(undefined, undefined, make$1(tmp, (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), (function (param) {
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$2,
                                                                  (function (policy) {
                                                                      return {
                                                                              universe: policy.universe,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    
                  }
                }
              } else {
                tmp = null;
              }
              return React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.map(universes, (function (universe) {
                                    var policy = Belt_List.getBy(rules, (function (rp) {
                                            return rp.universe === universe.id;
                                          }));
                                    var policy$prime = policy !== undefined ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined;
                                    return ReasonReact.element(universe.name + (
                                                policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                              ), undefined, RetentionPolicyStatus$BsConsole.Universe.make(universe.name, token, (function (remote, _update) {
                                                      return ReasonReact.element(universe.name + (
                                                                  policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                                                ), undefined, RetentionPolicyTable$BsConsole.Expansion.make(universe.name, Caml_option.some(policy !== undefined && policy.rules.trim() !== "" && policy.rules.trim() !== "[]" ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined), isEnterprise ? (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* Create */Block.__(0, [
                                                                                          universe.id,
                                                                                          policy$prime
                                                                                        ])]);
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), isEnterprise ? (function (rule) {
                                                                          if (policy !== undefined) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                                            policy,
                                                                                            rule
                                                                                          ])]);
                                                                          }
                                                                          
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), isEnterprise ? (function (rule) {
                                                                          if (policy !== undefined) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Delete */Block.__(2, [
                                                                                            policy,
                                                                                            rule
                                                                                          ])]);
                                                                          }
                                                                          
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), remote, []));
                                                    })));
                                  }))), tmp);
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var UniverseTable = {
  component: component$4,
  make: make$4
};

var component$5 = RR$BsConsole.reducerComponent("SettingsRetentionPolicy-BsConsole__ProjectsTable");

function make$5(isEnterprise, projects, universe, projectPolicies, handleTask, token, onSuccess, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (self) {
              var hasPolicy = Belt_List.sort(Belt_List.keep(projects, (function (project) {
                          var p = Belt_List.getBy(projectPolicies, (function (pr) {
                                  return project.pid === pr.project;
                                }));
                          if (p !== undefined && p.rules.trim() !== "") {
                            return p.rules.trim() !== "[]";
                          } else {
                            return false;
                          }
                        })), (function (a, b) {
                      if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                      } else {
                        return 0;
                      }
                    }));
              var noPolicy = Belt_List.sort(Belt_List.keep(projects, (function (project) {
                          var p = Belt_List.getBy(projectPolicies, (function (pr) {
                                  return project.pid === pr.project;
                                }));
                          if (p !== undefined && p.rules.trim() !== "") {
                            return p.rules.trim() === "[]";
                          } else {
                            return true;
                          }
                        })), (function (a, b) {
                      if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                      } else {
                        return 0;
                      }
                    }));
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (typeof match === "number") {
                  tmp = ReasonReact.element(undefined, undefined, make$2((function (param) {
                              return Curry._1(self.send, /* Dialog */[undefined]);
                            }), []));
                } else {
                  switch (match.tag | 0) {
                    case /* Create */0 :
                        var policy = match[1];
                        var pid = match[0];
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Create.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_ProjectCreate */39]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleCreateSubmit, _toKey) {
                                    return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Modify.make, (function (param, param$1) {
                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully created"));
                                                      Curry._1(self.send, /* Dialog */[undefined]);
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_ProjectCreate */39]));
                                                      return Curry._1(onSuccess, undefined);
                                                    }), handleTask, token, (function (state$prime, handleModifySubmit, _toKey) {
                                                      var match = state.remote;
                                                      var match$1 = state$prime.remote;
                                                      var tmp;
                                                      var exit = 0;
                                                      if (typeof match === "number" || match.tag !== /* Failure */1) {
                                                        exit = 1;
                                                      } else {
                                                        tmp = match[0];
                                                      }
                                                      if (exit === 1) {
                                                        tmp = typeof match$1 === "number" || match$1.tag !== /* Failure */1 ? undefined : match$1[0];
                                                      }
                                                      return ReasonReact.element(undefined, undefined, make(Caml_option.some(tmp), Caml_option.some(policy), undefined, (function (param) {
                                                                        return Curry._1(self.send, /* Dialog */[undefined]);
                                                                      }), I18N$BsConsole.get(undefined, "Add new rule"), policy !== undefined ? (function (rules) {
                                                                          return Curry._1(handleModifySubmit, /* tuple */[
                                                                                      {
                                                                                        project: pid,
                                                                                        rules: JSON.stringify(RetentionPolicy$BsConsole.toJson(policy)),
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      (function (policy) {
                                                                                          return {
                                                                                                  project: policy.project,
                                                                                                  rules: rules,
                                                                                                  __state: policy.__state,
                                                                                                  __create: policy.__create,
                                                                                                  __modify: policy.__modify
                                                                                                };
                                                                                        }),
                                                                                      Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }) : (function (rules) {
                                                                          return Curry._1(handleCreateSubmit, /* tuple */[
                                                                                      {
                                                                                        project: pid,
                                                                                        rules: rules,
                                                                                        __state: undefined,
                                                                                        __create: undefined,
                                                                                        __modify: undefined
                                                                                      },
                                                                                      Util$BsConsole.identity,
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        }),
                                                                                      (function (param) {
                                                                                          
                                                                                        })
                                                                                    ]);
                                                                        }), []));
                                                    })));
                                  })));
                        break;
                    case /* Modify */1 :
                        var rule = match[1];
                        var policy$1 = match[0];
                        var policy$prime;
                        try {
                          policy$prime = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$1.rules));
                        }
                        catch (raw_msg){
                          var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                          if (msg[0] === Json_decode.DecodeError) {
                            console.log(msg[1]);
                            policy$prime = [];
                          } else {
                            throw msg;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully modified"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_ProjectEdit */40]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (_state, handleSubmit, _toKey) {
                                    return ReasonReact.element(undefined, undefined, make(undefined, Caml_option.some(policy$prime), Caml_option.some(rule), (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), I18N$BsConsole.get(undefined, "Modify rule"), (function (rules) {
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$1,
                                                                  (function (policy) {
                                                                      return {
                                                                              project: policy.project,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    case /* Delete */2 :
                        var rule$1 = match[1];
                        var policy$2 = match[0];
                        var policy$prime$1;
                        try {
                          policy$prime$1 = RetentionPolicy$BsConsole.ofJson(JSON.parse(policy$2.rules));
                        }
                        catch (raw_msg$1){
                          var msg$1 = Caml_js_exceptions.internalToOCamlException(raw_msg$1);
                          if (msg$1[0] === Json_decode.DecodeError) {
                            console.log(msg$1[1]);
                            policy$prime$1 = [];
                          } else {
                            throw msg$1;
                          }
                        }
                        tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Modify.make, (function (param, param$1) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Rule successfully deleted"));
                                    Curry._1(self.send, /* Dialog */[undefined]);
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* RetentionPolicy_ProjectDelete */41]));
                                    return Curry._1(onSuccess, undefined);
                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                    var rules = JSON.stringify(RetentionPolicy$BsConsole.toJson(Belt_Array.keep(policy$prime$1, (function (r) {
                                                    return r.key !== rule$1.key;
                                                  }))));
                                    var msg = state.remote;
                                    var tmp;
                                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? undefined : msg[0];
                                    return ReasonReact.element(undefined, undefined, make$1(tmp, (function (param) {
                                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                                    }), (function (param) {
                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                  policy$2,
                                                                  (function (policy) {
                                                                      return {
                                                                              project: policy.project,
                                                                              rules: rules,
                                                                              __state: policy.__state,
                                                                              __create: policy.__create,
                                                                              __modify: policy.__modify
                                                                            };
                                                                    }),
                                                                  Form$BsConsole.Fields.set("rules", rules, Form$BsConsole.Fields.empty),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    }),
                                                                  (function (param) {
                                                                      
                                                                    })
                                                                ]);
                                                    }), []));
                                  })));
                        break;
                    
                  }
                }
              } else {
                tmp = null;
              }
              return React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.map(Belt_List.concat(hasPolicy, noPolicy), (function (project) {
                                    var policy = Belt_List.getBy(projectPolicies, (function (policy) {
                                            return project.pid === policy.project;
                                          }));
                                    return ReasonReact.element(project.name + (
                                                policy !== undefined ? policy.rules : "_"
                                              ), undefined, RetentionPolicyStatus$BsConsole.Project.make(universe.name, project.name, token, (function (remote, _update) {
                                                      return ReasonReact.element(project.name + (
                                                                  policy !== undefined ? policy.rules : "_"
                                                                ), undefined, RetentionPolicyTable$BsConsole.Expansion.make(project.name, Caml_option.some(policy !== undefined && policy.rules.trim() !== "" && policy.rules.trim() !== "[]" ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined), isEnterprise ? (function (param) {
                                                                          if (policy !== undefined) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Create */Block.__(0, [
                                                                                            project.pid,
                                                                                            RetentionPolicy$BsConsole.fromString(policy.rules)
                                                                                          ])]);
                                                                          } else {
                                                                            return Curry._1(self.send, /* Dialog */[/* Create */Block.__(0, [
                                                                                            project.pid,
                                                                                            undefined
                                                                                          ])]);
                                                                          }
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), isEnterprise ? (function (rule) {
                                                                          if (policy !== undefined) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                                            policy,
                                                                                            rule
                                                                                          ])]);
                                                                          }
                                                                          
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), isEnterprise ? (function (rule) {
                                                                          if (policy !== undefined) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Delete */Block.__(2, [
                                                                                            policy,
                                                                                            rule
                                                                                          ])]);
                                                                          }
                                                                          
                                                                        }) : (function (param) {
                                                                          return Curry._1(self.send, /* Dialog */[/* SelfService */0]);
                                                                        }), remote, []));
                                                    })));
                                  }))), tmp);
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$5.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var ProjectsTable = {
  component: component$5,
  make: make$5
};

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* [] */0
    ]);

var component$6 = RR$BsConsole.statelessComponent("SettingsRetentionPolicy-BsConsole");

function make$6(handleTask, token, shell, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (_self) {
              if (typeof shell === "number") {
                return null;
              }
              if (shell.tag) {
                return null;
              }
              var config = shell[0];
              return ReasonReact.element(undefined, undefined, BillingLimitsFetch$BsConsole.make(config.universe.name, handleTask, token, (function (limitsRemote, param) {
                                return ReasonReact.element(undefined, undefined, SettingsContainer$BsConsole.make([
                                                ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Retention policies")])),
                                                                    React.createElement("br", undefined),
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Administrators can configure retention policies that will allow data to be removed after a defined period of time. Instance level retention policies may be active. Please contact support for more information.")]))
                                                                  ]))])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Get.make, handleTask, token, undefined, (function (state, updater) {
                                                            var match = state.remote;
                                                            var exit = 0;
                                                            var exit$1 = 0;
                                                            if (typeof match === "number") {
                                                              if (match === /* NotAsked */0) {
                                                                exit$1 = 3;
                                                              }
                                                              
                                                            } else if (match.tag) {
                                                              exit$1 = 3;
                                                            } else if (typeof limitsRemote === "number") {
                                                              if (limitsRemote === /* NotAsked */0) {
                                                                exit = 2;
                                                              } else {
                                                                exit$1 = 3;
                                                              }
                                                            } else if (limitsRemote.tag) {
                                                              exit = 2;
                                                            } else {
                                                              if (typeof shell !== "number") {
                                                                if (shell.tag || shell[0].user.superuser !== 1) {
                                                                  return null;
                                                                } else {
                                                                  return React.createElement(React.Fragment, undefined, React.createElement("br", undefined), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Instance policy")])), ReasonReact.element(undefined, undefined, make$3(!Belt_Option.isSome(limitsRemote[0]), handleTask, token, (function (param) {
                                                                                        return Curry._1(updater, undefined);
                                                                                      }), match[0], [])));
                                                                }
                                                              }
                                                              if (shell === /* NotAsked */0) {
                                                                return null;
                                                              }
                                                              exit = 2;
                                                            }
                                                            if (exit$1 === 3 && !(typeof limitsRemote === "number" && limitsRemote !== 0)) {
                                                              exit = 2;
                                                            }
                                                            if (exit === 2) {
                                                              if (typeof shell !== "number") {
                                                                return null;
                                                              }
                                                              if (shell === 0) {
                                                                return null;
                                                              }
                                                              
                                                            }
                                                            return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                          }))),
                                                React.createElement("br", undefined),
                                                React.createElement("br", undefined),
                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Universe policy")])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Universe.Get.make, handleTask, token, undefined, (function (universeState, _updater) {
                                                            return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Get.make, handleTask, token, undefined, (function (universeRetentionState, updater) {
                                                                              var match = universeState.remote;
                                                                              var match$1 = universeRetentionState.remote;
                                                                              var exit = 0;
                                                                              var exit$1 = 0;
                                                                              if (typeof match === "number") {
                                                                                if (match === /* NotAsked */0) {
                                                                                  exit$1 = 3;
                                                                                }
                                                                                
                                                                              } else if (match.tag) {
                                                                                exit$1 = 3;
                                                                              } else if (typeof match$1 === "number") {
                                                                                if (match$1 === /* NotAsked */0) {
                                                                                  exit = 2;
                                                                                } else {
                                                                                  exit$1 = 3;
                                                                                }
                                                                              } else if (match$1.tag) {
                                                                                exit = 2;
                                                                              } else {
                                                                                if (typeof limitsRemote !== "number") {
                                                                                  if (limitsRemote.tag) {
                                                                                    return null;
                                                                                  } else {
                                                                                    return ReasonReact.element(undefined, undefined, make$4(!Belt_Option.isSome(limitsRemote[0]), match[0], handleTask, token, (function (param) {
                                                                                                      return Curry._1(updater, undefined);
                                                                                                    }), match$1[0], []));
                                                                                  }
                                                                                }
                                                                                if (limitsRemote === /* NotAsked */0) {
                                                                                  return null;
                                                                                }
                                                                                exit = 2;
                                                                              }
                                                                              if (exit$1 === 3 && !(typeof match$1 === "number" && match$1 !== 0)) {
                                                                                exit = 2;
                                                                              }
                                                                              if (exit === 2) {
                                                                                if (typeof limitsRemote !== "number") {
                                                                                  return null;
                                                                                }
                                                                                if (limitsRemote === 0) {
                                                                                  return null;
                                                                                }
                                                                                
                                                                              }
                                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                                            })));
                                                          }))),
                                                React.createElement("br", undefined),
                                                React.createElement("br", undefined),
                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Project policies")])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Project.Get.make, handleTask, token, undefined, (function (projectState, updater) {
                                                            return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Get.make, handleTask, token, undefined, (function (projectRetentionState, retentionUpdater) {
                                                                              var match = projectState.remote;
                                                                              var match$1 = projectRetentionState.remote;
                                                                              var exit = 0;
                                                                              var exit$1 = 0;
                                                                              if (typeof match === "number") {
                                                                                if (match === /* NotAsked */0) {
                                                                                  exit$1 = 3;
                                                                                }
                                                                                
                                                                              } else if (match.tag) {
                                                                                exit$1 = 3;
                                                                              } else if (typeof match$1 === "number") {
                                                                                if (match$1 === /* NotAsked */0) {
                                                                                  exit = 2;
                                                                                } else {
                                                                                  exit$1 = 3;
                                                                                }
                                                                              } else if (match$1.tag) {
                                                                                exit = 2;
                                                                              } else {
                                                                                if (typeof limitsRemote !== "number") {
                                                                                  if (limitsRemote.tag) {
                                                                                    return null;
                                                                                  } else {
                                                                                    return ReasonReact.element(undefined, undefined, make$5(!Belt_Option.isSome(limitsRemote[0]), match[0], config.universe, match$1[0], handleTask, token, (function (param) {
                                                                                                      Curry._1(updater, undefined);
                                                                                                      return Curry._1(retentionUpdater, undefined);
                                                                                                    }), []));
                                                                                  }
                                                                                }
                                                                                if (limitsRemote === /* NotAsked */0) {
                                                                                  return null;
                                                                                }
                                                                                exit = 2;
                                                                              }
                                                                              if (exit$1 === 3 && !(typeof match$1 === "number" && match$1 !== 0)) {
                                                                                exit = 2;
                                                                              }
                                                                              if (exit === 2) {
                                                                                if (typeof limitsRemote !== "number") {
                                                                                  return null;
                                                                                }
                                                                                if (limitsRemote === 0) {
                                                                                  return null;
                                                                                }
                                                                                
                                                                              }
                                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                                            })));
                                                          })))
                                              ]));
                              })));
            }),
          initialState: component$6.initialState,
          retainedProps: component$6.retainedProps,
          reducer: component$6.reducer,
          jsElementWrapped: component$6.jsElementWrapped
        };
}

exports.secondsToDays = secondsToDays;
exports.CreateModifyDialog = CreateModifyDialog;
exports.DeleteDialog = DeleteDialog;
exports.SelfServiceDialog = SelfServiceDialog;
exports.InstanceTable = InstanceTable;
exports.UniverseTable = UniverseTable;
exports.ProjectsTable = ProjectsTable;
exports.link = link;
exports.component = component$6;
exports.make = make$6;
/* days Not a pure module */
