// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Guides$BsConsole = require("../guides/Guides.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Guides_list$BsConsole = require("../guides/Guides_list.js");
var BpgController$BsConsole = require("../BpgController.js");
var DemoProjectUtil$BsConsole = require("../DemoProjectUtil.js");
var WindowVariables$BsConsole = require("../WindowVariables.js");
var DemoProjectPrompt$BsConsole = require("../DemoProjectPrompt.js");
var PS_Documentation_Guide$BsConsole = require("./PS_Documentation_Guide.js");
var PS_Documentation_TableOfContents$BsConsole = require("./PS_Documentation_TableOfContents.js");

function apiTokens(param) {
  return param.apiTokens;
}

function systemConfig(param) {
  return param.systemConfig;
}

function tokens(param) {
  return param.tokens;
}

var Config = {
  apiTokens: apiTokens,
  systemConfig: systemConfig,
  tokens: tokens
};

function make(doc) {
  return {
          guide: Belt_Option.flatMap(doc, (function (n) {
                  return Guides$BsConsole.getFirstByQsName(Guides_list$BsConsole.t, n);
                })),
          remoteToken: /* NotAsked */0,
          remoteApiToken: /* NotAsked */0
        };
}

function remoteToken(param) {
  return param.remoteToken;
}

function remoteApiToken(param) {
  return param.remoteApiToken;
}

var State = {
  make: make,
  remoteToken: remoteToken,
  remoteApiToken: remoteApiToken
};

function fromShellAndState(shell, state) {
  var match = state.remoteToken;
  var match$1 = state.remoteApiToken;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  if (typeof shell === "number") {
    if (shell !== /* NotAsked */0) {
      return /* Loading */0;
    }
    exit$3 = 5;
  } else if (shell.tag) {
    exit$3 = 5;
  } else if (typeof match === "number") {
    if (match === /* NotAsked */0) {
      exit$2 = 4;
    } else {
      exit$3 = 5;
    }
  } else if (match.tag) {
    exit$2 = 4;
  } else if (typeof match$1 === "number") {
    if (match$1 === /* NotAsked */0) {
      return /* Loading */0;
    }
    exit$2 = 4;
  } else if (!match$1.tag) {
    var config_apiTokens = match$1[0];
    var config_systemConfig = shell[0];
    var config_tokens = match[0];
    var config = {
      apiTokens: config_apiTokens,
      systemConfig: config_systemConfig,
      tokens: config_tokens
    };
    var guide = state.guide;
    if (guide !== undefined) {
      return /* ShowingGuide */Block.__(2, [
                config,
                guide
              ]);
    } else {
      return /* ShowingTableOfContents */Block.__(1, [config]);
    }
  }
  if (exit$3 === 5) {
    if (typeof match === "number") {
      if (match !== 0) {
        return /* Loading */0;
      }
      exit$2 = 4;
    } else {
      exit$2 = 4;
    }
  }
  if (exit$2 === 4) {
    if (typeof match$1 === "number") {
      if (match$1 !== 0) {
        return /* Loading */0;
      }
      exit$1 = 3;
    } else {
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof shell === "number") {
      exit = 2;
    } else {
      if (shell.tag) {
        return /* Failure */Block.__(0, [shell[0]]);
      }
      exit = 2;
    }
  }
  if (exit === 2 && typeof match !== "number" && match.tag) {
    return /* Failure */Block.__(0, [match[0]]);
  }
  if (typeof match$1 === "number" || !match$1.tag) {
    return /* Loading */0;
  } else {
    return /* Failure */Block.__(0, [match$1[0]]);
  }
}

var Status = {
  fromShellAndState: fromShellAndState
};

function reducer(action, state) {
  if (action.tag) {
    return /* Update */Block.__(0, [{
                guide: state.guide,
                remoteToken: state.remoteToken,
                remoteApiToken: action[0]
              }]);
  } else {
    return /* Update */Block.__(0, [{
                guide: state.guide,
                remoteToken: action[0],
                remoteApiToken: state.remoteApiToken
              }]);
  }
}

function goHome(handleChangeUrl, projectName) {
  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                projectName,
                {
                  doc: undefined
                }
              ]));
}

function loadGuideDoc(handleChangeUrl, projectName, docName) {
  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                projectName,
                {
                  doc: docName
                }
              ]));
}

var Action = {
  reducer: reducer,
  goHome: goHome,
  loadGuideDoc: loadGuideDoc
};

var container = Css.style(/* :: */[
      Css.selector(".integration-guide-markdown a", /* :: */[
            Css.color(Css.hex(Colors$BsConsole.accent)),
            /* :: */[
              Css.textDecoration(/* none */-922086728),
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.selector(".integration-guide-markdown code", /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey1)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var page = Css.style(/* :: */[
      Css.padding4(/* zero */-789508312, Css.rem(2.25), /* zero */-789508312, Css.rem(2.25)),
      /* :: */[
        Css.margin4(/* zero */-789508312, /* auto */-1065951377, Css.px(100), /* auto */-1065951377),
        /* :: */[
          Css.flex(/* `num */[
                5496390,
                5.0
              ]),
          /* :: */[
            Css.minWidth(Css.px(550)),
            /* :: */[
              Css.width(Css.pct(90.0)),
              /* :: */[
                Css.maxWidth(Css.px(1100)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var Style = {
  container: container,
  link: link,
  loadingContainer: loadingContainer,
  page: page,
  title: title
};

function pageContainer(maybeConfigOpt, maybeTokenOpt, hideOverviewBannerOpt, children) {
  var maybeConfig = maybeConfigOpt !== undefined ? Caml_option.valFromOption(maybeConfigOpt) : undefined;
  var maybeToken = maybeTokenOpt !== undefined ? Caml_option.valFromOption(maybeTokenOpt) : undefined;
  var hideOverviewBanner = hideOverviewBannerOpt !== undefined ? hideOverviewBannerOpt : false;
  var supportLink = Href$BsConsole.Support.make(undefined, /* CategoryIntegrationGuides */7);
  return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                  ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.merge(/* :: */[
                                container,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(1)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]), [
                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Integration guides")])),
                            supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                  React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Backtrace supports a wide array of environments, languages and platforms. See below for guides on various integration paths. For more details, please refer to the ")),
                                                  React.createElement("a", {
                                                        className: "link " + link,
                                                        href: supportLink,
                                                        target: "_blank"
                                                      }, I18N$BsConsole.show(undefined, "integration guide")),
                                                  React.createElement("span", undefined, I18N$BsConsole.showSkip("."))
                                                ]))])) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Backtrace supports a wide array of environments, languages and platforms. See below for guides on various integration paths."))]))]))
                          ])),
                  hideOverviewBanner || !(maybeConfig !== undefined && maybeToken !== undefined) ? null : ReasonReact.element(undefined, undefined, DemoProjectPrompt$BsConsole.Jsx2.make(maybeConfig, maybeToken, /* IntegrationGuidesOverview */0, undefined, undefined, undefined, undefined, [])),
                  ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, container, [children]))
                ]));
}

function failure(message) {
  return pageContainer(undefined, undefined, undefined, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(loadingContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [I18N$BsConsole.showSkip(message)])));
}

function guide(config, guide$1, handleChangeUrl, origin, project, receiveLayer, token) {
  return pageContainer(Caml_option.some(config.systemConfig), Caml_option.some(token), DemoProjectUtil$BsConsole.hasDemoProject(guide$1.name) || DemoProjectUtil$BsConsole.hasExampleProject(guide$1.name), ReasonReact.element(undefined, undefined, PS_Documentation_Guide$BsConsole.make(apiTokens(config), systemConfig(config), guide$1, (function (_e) {
                        return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                      project.name,
                                      {
                                        doc: undefined
                                      }
                                    ]));
                      }), origin, project, receiveLayer, tokens(config), token, [])));
}

function loading(param) {
  return pageContainer(undefined, undefined, undefined, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(loadingContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, undefined, undefined, undefined, []))])));
}

function tableOfContents(handleChangeUrl, projectName, sections, config, token) {
  var handleGuideClick = function (guide) {
    return loadGuideDoc(handleChangeUrl, projectName, guide.qsName);
  };
  return pageContainer(Caml_option.some(config.systemConfig), Caml_option.some(token), undefined, ReasonReact.element(undefined, undefined, PS_Documentation_TableOfContents$BsConsole.make(handleGuideClick, sections, [])));
}

var View = {
  pageContainer: pageContainer,
  failure: failure,
  guide: guide,
  loading: loading,
  tableOfContents: tableOfContents
};

function make$1(project, shell, token, handleTask, handleChangeUrl, doc, _children) {
  var init = ReasonReact.reducerComponent("PS_Documentation_v2-BsConsole");
  return {
          debugName: init.debugName,
          reactClassInternal: init.reactClassInternal,
          handedOffState: init.handedOffState,
          willReceiveProps: init.willReceiveProps,
          didMount: init.didMount,
          didUpdate: init.didUpdate,
          willUnmount: init.willUnmount,
          willUpdate: init.willUpdate,
          shouldUpdate: init.shouldUpdate,
          render: (function (self) {
              var renderTableOfContents = function (config) {
                var sections = PS_Documentation_TableOfContents$BsConsole.Section.staticArray(Guides_list$BsConsole.t);
                return tableOfContents(handleChangeUrl, project.name, sections, config, token);
              };
              var renderGuide = function (config, guide$1) {
                var origin = window.location.origin;
                return guide(config, guide$1, handleChangeUrl, origin, project, WindowVariables$BsConsole.maybeReceiveLayer, token);
              };
              var message = fromShellAndState(shell, self.state);
              var tmp;
              if (typeof message === "number") {
                tmp = loading(undefined);
              } else {
                switch (message.tag | 0) {
                  case /* Failure */0 :
                      tmp = failure(message[0]);
                      break;
                  case /* ShowingTableOfContents */1 :
                      tmp = renderTableOfContents(message[0]);
                      break;
                  case /* ShowingGuide */2 :
                      tmp = renderGuide(message[0], message[1]);
                      break;
                  
                }
              }
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Get.make, handleTask, token, undefined, (function (tokenState, _updater) {
                                    if (Caml_obj.caml_notequal(tokenState.remote, remoteToken(self.state))) {
                                      Curry._1(self.send, /* SetTokenRemote */Block.__(0, [tokenState.remote]));
                                    }
                                    return null;
                                  }))), ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ApiToken.Get.make, handleTask, token, undefined, (function (tokenState, _updater) {
                                    if (Caml_obj.caml_notequal(tokenState.remote, remoteApiToken(self.state))) {
                                      Curry._1(self.send, /* SetApiTokenRemote */Block.__(1, [tokenState.remote]));
                                    }
                                    return null;
                                  }))), tmp);
            }),
          initialState: (function (param) {
              return make(doc);
            }),
          retainedProps: init.retainedProps,
          reducer: reducer,
          jsElementWrapped: init.jsElementWrapped
        };
}

var BPG;

var RR;

var DocCard;

var GuideTemplate;

var TableOfContents;

var guideList = Guides_list$BsConsole.t;

exports.BPG = BPG;
exports.RR = RR;
exports.DocCard = DocCard;
exports.GuideTemplate = GuideTemplate;
exports.TableOfContents = TableOfContents;
exports.guideList = guideList;
exports.Config = Config;
exports.State = State;
exports.Status = Status;
exports.Action = Action;
exports.Style = Style;
exports.View = View;
exports.make = make$1;
/* container Not a pure module */
