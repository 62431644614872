// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function Spread(Props) {
  var props = Props.props;
  var children = Props.children;
  return React.cloneElement(children, props);
}

var component = ReasonReact.statelessComponent("Spread.Re");

function make(props, children) {
  return ReasonReactCompat.wrapReactForReasonReact(Spread, {
              props: props,
              children: children
            }, children);
}

var Re = {
  component: component,
  make: make
};

var make$1 = Spread;

exports.make = make$1;
exports.Re = Re;
/* component Not a pure module */
