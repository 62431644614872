// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Regex$BsConsole = require("./Regex.js");
var Table$BsConsole = require("./table.js");
var Colors$BsConsole = require("./Colors.js");
var Option$BsConsole = require("./option.js");
var Fragment$BsConsole = require("./Fragment.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");
var BpgController$BsConsole = require("./BpgController.js");

function listenerTypeToDisplayString(t) {
  switch (t) {
    case "console/socket" :
        return I18N$BsConsole.get(undefined, "management console");
    case "http/console" :
        return I18N$BsConsole.get(undefined, "web console");
    case "http/writer" :
        return I18N$BsConsole.get(undefined, "receive");
    default:
      return ;
  }
}

var containerGrid = Css.style(/* :: */[
      Css.marginLeft(/* auto */-1065951377),
      /* :: */[
        Css.marginTop(Css.rem(3)),
        /* :: */[
          Css.marginRight(/* auto */-1065951377),
          /* :: */[
            Css.maxWidth(Css.px(800)),
            /* [] */0
          ]
        ]
      ]
    ]);

var sectionLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA54)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.marginTop(Css.rem(1)),
      /* [] */0
    ]);

var Styles = {
  containerGrid: containerGrid,
  sectionLabel: sectionLabel,
  paper: paper
};

var fieldIsRequired = I18N$BsConsole.get(undefined, "Field is required");

var invalidDNSName = I18N$BsConsole.get(undefined, "Value must be valid DNS");

function mustBeLessThan(x) {
  return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Value must be less than ",
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* End_of_format */0
                        ])
                    ]),
                  "Value must be less than %d"
                ]), x);
}

var Messages = {
  fieldIsRequired: fieldIsRequired,
  invalidDNSName: invalidDNSName,
  mustBeLessThan: mustBeLessThan
};

function validateDnsName(state) {
  var dns = state.dns_name;
  if (dns === "" || Regex$BsConsole.isDnsName(dns)) {
    return ;
  } else {
    return invalidDNSName;
  }
}

function validateBacklog(state) {
  var _backlog = Option$BsConsole.ofString(state.backlog);
  if (_backlog === undefined) {
    return fieldIsRequired;
  }
  var __x = Option$BsConsole.tryWith((function (param) {
          return Caml_format.caml_int_of_string(state.backlog);
        }));
  return Belt_Option.mapWithDefault(__x, mustBeLessThan(2048), (function (value) {
                if (value <= 2048) {
                  return ;
                } else {
                  return mustBeLessThan(2048);
                }
              }));
}

function validateService(state) {
  var _service = Option$BsConsole.ofString(state.service);
  if (_service === undefined) {
    return fieldIsRequired;
  }
  var __x = Option$BsConsole.tryWith((function (param) {
          return Caml_format.caml_int_of_string(state.service);
        }));
  return Belt_Option.mapWithDefault(__x, mustBeLessThan(65535), (function (value) {
                if (value <= 65535) {
                  return ;
                } else {
                  return mustBeLessThan(65535);
                }
              }));
}

function validateConcurrency(state) {
  var _concurrency = Option$BsConsole.ofString(state.concurrency);
  if (_concurrency === undefined) {
    return fieldIsRequired;
  }
  var __x = Option$BsConsole.tryWith((function (param) {
          return Caml_format.caml_int_of_string(state.concurrency);
        }));
  return Belt_Option.mapWithDefault(__x, mustBeLessThan(100000), (function (value) {
                if (value <= 100000) {
                  return ;
                } else {
                  return mustBeLessThan(100000);
                }
              }));
}

var component = ReasonReact.statelessComponent("SettingsNetworkingListenersPage-BsConsole__DeleteListenerDialog");

function make(listener, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Listener.Delete.make, (function (param) {
                                            Curry._1(onClose, (new Event('close')));
                                            return Curry._1(onSuccess, undefined);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                            undefined,
                                                            true,
                                                            onClose,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete listener")])),
                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure?")]))])),
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  Curry.__1(onClose),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                ])),
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  (function (_event) {
                                                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                                                  listener,
                                                                                                  Util$BsConsole.identity
                                                                                                ]);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  state.remote === /* Loading */1,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Delete")]
                                                                                ]))
                                                                      ]))
                                                            ]
                                                          ]));
                                          })))]
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var DeleteListenerDialog = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("SettingsNetworkingListenersPage-BsConsole__ModifyDialog");

function make$1(title, param, listener, token, handleTask, onSuccess, onClose, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Listener.Modify.make, (function (_result, _updated) {
                                return Curry._1(onSuccess, UtilWindow$BsConsole.fakeSynthenticEvent);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.ListenerForm.make, (function (param) {
                                                  var values = param.values;
                                                  var match = values.dns_name;
                                                  var tmp = match === "" ? "__BACKTRACE_BPG__NULL__VALUE__" : values.dns_name;
                                                  var fields = Form$BsConsole.Fields.set("concurrency", values.concurrency, Form$BsConsole.Fields.set("ssl", values.ssl, Form$BsConsole.Fields.set("backlog", values.backlog, Form$BsConsole.Fields.set("service", values.service, Form$BsConsole.Fields.set("dns_name", tmp, Form$BsConsole.Fields.set("address", values.address, Form$BsConsole.Fields.set("protocol", values.protocol, Form$BsConsole.Fields.empty)))))));
                                                  return Curry._1(handleSubmit, /* tuple */[
                                                              listener,
                                                              Util$BsConsole.identity,
                                                              fields,
                                                              param.setError,
                                                              param.setSubmitting,
                                                              param.resetFormState
                                                            ]);
                                                }), undefined, undefined, {
                                                protocol: String(listener.protocol),
                                                type_: listener.type_,
                                                address: listener.address,
                                                dns_name: Belt_Option.getWithDefault(listener.dns_name, ""),
                                                service: Belt_Option.getWithDefault(listener.service, ""),
                                                backlog: String(listener.backlog),
                                                ssl: String(Belt_Option.getWithDefault(listener.ssl, 1)),
                                                concurrency: String(Belt_Option.getWithDefault(listener.concurrency, 16))
                                              }, /* :: */[
                                                /* tuple */[
                                                  /* Protocol */-636153224,
                                                  /* Required */0
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    /* Address */-363030124,
                                                    /* Required */0
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      /* Service */-813808619,
                                                      /* Custom */[validateService]
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        /* Backlog */-924961635,
                                                        /* Custom */[validateBacklog]
                                                      ],
                                                      /* :: */[
                                                        /* tuple */[
                                                          /* Ssl */4153260,
                                                          /* Required */0
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            /* Concurrency */-645910157,
                                                            /* Custom */[validateConcurrency]
                                                          ],
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ], undefined, (function (param) {
                                                  var getErrorForField = param.getErrorForField;
                                                  var handleSubmit = param.handleSubmit;
                                                  var handleChange = param.handleChange;
                                                  var form = param.form;
                                                  var msg = state.remote;
                                                  var tmp;
                                                  tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg[0])]))]));
                                                  var partial_arg = Curry._1(handleChange, /* DnsName */-236810124);
                                                  var partial_arg$1 = Curry._1(handleChange, /* Address */-363030124);
                                                  var partial_arg$2 = Curry._1(handleChange, /* Service */-813808619);
                                                  var partial_arg$3 = Curry._1(handleChange, /* Backlog */-924961635);
                                                  var partial_arg$4 = Curry._1(handleChange, /* Concurrency */-645910157);
                                                  return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                                  undefined,
                                                                  true,
                                                                  onClose,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.dynamic(title)])),
                                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                              tmp,
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* DnsName */-236810124)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* DnsName */-236810124), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.dns_name
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "DNS")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Address */-363030124)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Address */-363030124), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.address
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Address")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Service */-813808619)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Service */-813808619), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.service
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Port")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Use SSL")),
                                                                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                                      undefined,
                                                                                                      /* `Bool */[
                                                                                                        737456202,
                                                                                                        form.values.ssl === "1"
                                                                                                      ],
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      form.values.protocol,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      (function (_event, checked) {
                                                                                                          return Curry._2(handleChange, /* Ssl */4153260, checked ? "1" : "0");
                                                                                                        }),
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      []
                                                                                                    ]))),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Backlog */-924961635)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Backlog */-924961635), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.backlog
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Backlog")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$4, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Concurrency */-645910157)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Concurrency */-645910157), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.concurrency
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Concurrency")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ]))
                                                                            ])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        (function (_event) {
                                                                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        (function (_event) {
                                                                                            return Curry._1(handleSubmit, undefined);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        [I18N$BsConsole.show(undefined, "Modify")]
                                                                                      ]))
                                                                            ]))
                                                                  ]
                                                                ]));
                                                })));
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var ModifyListenerDialog = {
  component: component$1,
  make: make$1
};

function serviceValidator(state) {
  var _service = Option$BsConsole.ofString(state.service);
  if (_service === undefined) {
    return ;
  }
  var __x = Option$BsConsole.tryWith((function (param) {
          return Caml_format.caml_int_of_string(state.service);
        }));
  return Belt_Option.mapWithDefault(__x, mustBeLessThan(65535), (function (value) {
                if (value <= 65535) {
                  return ;
                } else {
                  return mustBeLessThan(65535);
                }
              }));
}

var component$2 = ReasonReact.statelessComponent("SettingsNetworkingListenersPage-BsConsole__ReceiveNewDialog");

function make$2(token, title, submitLabel, handleTask, onSuccess, onClose, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Listener.Create.make, (function (_result, _updated) {
                                return Curry._1(onSuccess, UtilWindow$BsConsole.fakeSynthenticEvent);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.ListenerForm.make, (function (param) {
                                                  var values = param.values;
                                                  return Curry._1(handleSubmit, /* tuple */[
                                                              {
                                                                id: 0,
                                                                groupname: "default",
                                                                protocol: Caml_format.caml_int_of_string(values.protocol),
                                                                type_: values.type_,
                                                                address: values.address,
                                                                dns_name: values.dns_name,
                                                                service: values.service,
                                                                backlog: Caml_format.caml_int_of_string(values.backlog),
                                                                ssl: Caml_format.caml_int_of_string(values.ssl),
                                                                concurrency: Caml_format.caml_int_of_string(values.concurrency),
                                                                undeletable: 0,
                                                                __state: undefined,
                                                                __create: undefined,
                                                                __modify: undefined
                                                              },
                                                              Util$BsConsole.identity,
                                                              param.setError,
                                                              param.setSubmitting,
                                                              param.resetFormState
                                                            ]);
                                                }), undefined, undefined, {
                                                protocol: "0",
                                                type_: "http/writer",
                                                address: "0.0.0.0",
                                                dns_name: "",
                                                service: "443",
                                                backlog: "128",
                                                ssl: "1",
                                                concurrency: "1024"
                                              }, /* :: */[
                                                /* tuple */[
                                                  /* Protocol */-636153224,
                                                  /* Required */0
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    /* Address */-363030124,
                                                    /* Required */0
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      /* DnsName */-236810124,
                                                      /* Custom */[validateDnsName]
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        /* Service */-813808619,
                                                        /* Custom */[validateService]
                                                      ],
                                                      /* :: */[
                                                        /* tuple */[
                                                          /* Backlog */-924961635,
                                                          /* Custom */[validateBacklog]
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            /* Ssl */4153260,
                                                            /* Required */0
                                                          ],
                                                          /* :: */[
                                                            /* tuple */[
                                                              /* Concurrency */-645910157,
                                                              /* Custom */[validateConcurrency]
                                                            ],
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ], undefined, (function (param) {
                                                  var getErrorForField = param.getErrorForField;
                                                  var handleSubmit = param.handleSubmit;
                                                  var handleChange = param.handleChange;
                                                  var form = param.form;
                                                  var msg = state.remote;
                                                  var tmp;
                                                  tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg[0])]))]));
                                                  var partial_arg = Curry._1(handleChange, /* DnsName */-236810124);
                                                  var partial_arg$1 = Curry._1(handleChange, /* Address */-363030124);
                                                  var partial_arg$2 = Curry._1(handleChange, /* Service */-813808619);
                                                  var partial_arg$3 = Curry._1(handleChange, /* Backlog */-924961635);
                                                  var partial_arg$4 = Curry._1(handleChange, /* Concurrency */-645910157);
                                                  return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                                  undefined,
                                                                  true,
                                                                  onClose,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.dynamic(title)])),
                                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                              tmp,
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* DnsName */-236810124)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* DnsName */-236810124), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.dns_name
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "DNS")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Address */-363030124)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Address */-363030124), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.address
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Address")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Service */-813808619)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Service */-813808619), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.service
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Port")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Use SSL")),
                                                                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                                      undefined,
                                                                                                      /* `Bool */[
                                                                                                        737456202,
                                                                                                        form.values.ssl === "1"
                                                                                                      ],
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      form.values.protocol,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      (function (_event, checked) {
                                                                                                          return Curry._2(handleChange, /* Ssl */4153260, checked ? "1" : "0");
                                                                                                        }),
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      []
                                                                                                    ]))),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Backlog */-924961635)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Backlog */-924961635), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.backlog
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Backlog")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        true,
                                                                                        (function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$4, param);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Belt_Option.isSome(Curry._1(getErrorForField, /* Concurrency */-645910157)),
                                                                                        Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Concurrency */-645910157), ""))),
                                                                                        undefined,
                                                                                        /* `String */[
                                                                                          -976970511,
                                                                                          form.values.concurrency
                                                                                        ],
                                                                                        undefined,
                                                                                        undefined,
                                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Backlog")),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        []
                                                                                      ]))
                                                                            ])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        (function (_event) {
                                                                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                        undefined,
                                                                                        undefined,
                                                                                        (function (_event) {
                                                                                            return Curry._1(handleSubmit, undefined);
                                                                                          }),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        [I18N$BsConsole.dynamic(submitLabel)]
                                                                                      ]))
                                                                            ]))
                                                                  ]
                                                                ]));
                                                })));
                              })));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var ReceiveNewDialog = {
  component: component$2,
  make: make$2
};

var component$3 = ReasonReact.reducerComponent("SettingsNetworkingListeners");

function make$3(token, handleChangeUrl, handleTask, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (typeof match === "number") {
                  switch (match) {
                    case /* NewReceive */0 :
                        tmp = ReasonReact.element(undefined, undefined, make$2(token, I18N$BsConsole.get(undefined, "Create new listener"), I18N$BsConsole.get(undefined, "Create"), handleTask, (function (_event) {
                                    Curry._1(self.send, /* BustCache */0);
                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                  }), (function (_event) {
                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                  }), []));
                        break;
                    case /* Web */1 :
                    case /* Socket */2 :
                        tmp = null;
                        break;
                    
                  }
                } else {
                  tmp = match.tag ? ReasonReact.element(undefined, undefined, make$1(match[1], I18N$BsConsole.get(undefined, "Modify"), match[0], token, handleTask, (function (_event) {
                                Curry._1(self.send, /* BustCache */0);
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), (function (_event) {
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), [])) : ReasonReact.element(undefined, undefined, make(match[0], token, handleTask, (function (_event) {
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), (function (_event, _listener) {
                                Curry._1(self.send, /* BustCache */0);
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), []));
                }
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1)),
                                            /* [] */0
                                          ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  undefined,
                                                  undefined,
                                                  (function (_event) {
                                                      return Curry._1(handleChangeUrl, /* SettingsNetworking */13);
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Outlined */-28821822,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [
                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardBackspace.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                    I18N$BsConsole.show(undefined, "Back to server settings")
                                                  ]
                                                ]))])),
                              ReasonReact.element(String(self.state.cacheKey), undefined, Curry._4(BpgController$BsConsole.Listener.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                          var listeners = state.remote;
                                          var tmp;
                                          var exit = 0;
                                          if (typeof listeners === "number" || listeners.tag) {
                                            exit = 1;
                                          } else {
                                            var __x = Belt_List.getBy(listeners[0], (function (listener) {
                                                    return listener.type_ === "http/console";
                                                  }));
                                            tmp = Belt_Option.mapWithDefault(__x, (function (_event) {
                                                    console.log("Invariant");
                                                    
                                                  }), (function (listener, _event) {
                                                    return Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                    listener,
                                                                    I18N$BsConsole.get(undefined, "Modify web interface listener")
                                                                  ])]);
                                                  }));
                                          }
                                          if (exit === 1) {
                                            tmp = (function (_event) {
                                                console.log("INVARIANT");
                                                
                                              });
                                          }
                                          var listeners$1 = state.remote;
                                          var tmp$1;
                                          var exit$1 = 0;
                                          if (typeof listeners$1 === "number" || listeners$1.tag) {
                                            exit$1 = 1;
                                          } else {
                                            var __x$1 = Belt_List.getBy(listeners$1[0], (function (listener) {
                                                    return listener.type_ === "console/socket";
                                                  }));
                                            tmp$1 = Belt_Option.mapWithDefault(__x$1, (function (_event) {
                                                    console.log("Invariant");
                                                    
                                                  }), (function (listener, _event) {
                                                    return Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                    listener,
                                                                    I18N$BsConsole.get(undefined, "Modify management console listener")
                                                                  ])]);
                                                  }));
                                          }
                                          if (exit$1 === 1) {
                                            tmp$1 = (function (_event) {
                                                console.log("INVARIANT");
                                                
                                              });
                                          }
                                          var listeners$2 = state.remote;
                                          var tmp$2;
                                          if (typeof listeners$2 === "number" || listeners$2.tag) {
                                            tmp$2 = null;
                                          } else {
                                            var listeners$prime = List.sort((function (a, b) {
                                                    return Caml_primitive.caml_string_compare(b.type_, a.type_);
                                                  }), Belt_List.keep(listeners$2[0], (function (t) {
                                                        return List.exists((function (t$prime) {
                                                                      return t$prime === t.type_;
                                                                    }), /* :: */[
                                                                    "http/writer",
                                                                    /* :: */[
                                                                      "http/console",
                                                                      /* :: */[
                                                                        "console/socket",
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]);
                                                      })));
                                            tmp$2 = ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(listeners$prime, (function (_aperture, _page, _rowsPerPage, _onChangePage, _onChangeRowsPerPage) {
                                                        return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, paper, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "type")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "dns")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "address")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "port")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "ssl")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                                    ]))])),
                                                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [$$Array.of_list(List.map((function (listener) {
                                                                                                        var dns = listener.dns_name;
                                                                                                        var tmp;
                                                                                                        var exit = 0;
                                                                                                        if (dns !== undefined && dns !== "") {
                                                                                                          tmp = I18N$BsConsole.dynamic(dns);
                                                                                                        } else {
                                                                                                          exit = 1;
                                                                                                        }
                                                                                                        if (exit === 1) {
                                                                                                          tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                                                                                    I18N$BsConsole.show(undefined, "none"),
                                                                                                                    ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "DNS name required to be set to the external DNS entry of your server, as it will be the URL that services will use to interact with your instance.")), undefined, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                                                                                    className: "smaller-icon"
                                                                                                                                  }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.PriorityHigh.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))
                                                                                                                  ]));
                                                                                                        }
                                                                                                        var match = Belt_Option.getWithDefault(listener.ssl, 0);
                                                                                                        return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(Belt_Option.getWithDefault(listenerTypeToDisplayString(listener.type_), "-"))])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [tmp])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(listener.address)])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(Belt_Option.getWithDefault(listener.service, "-"))])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [match !== 0 ? ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Https.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])) : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Http.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                                              return [
                                                                                                                                                      ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                  Curry._1(self.send, /* Dialog */[/* Delete */Block.__(0, [listener])]);
                                                                                                                                                                  return Curry._1(onClose, undefined);
                                                                                                                                                                }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")])),
                                                                                                                                                      ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                  Curry._1(self.send, /* Dialog */[/* Modify */Block.__(1, [
                                                                                                                                                                            listener,
                                                                                                                                                                            I18N$BsConsole.get(undefined, "Modify listener")
                                                                                                                                                                          ])]);
                                                                                                                                                                  return Curry._1(onClose, undefined);
                                                                                                                                                                }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Edit")]))
                                                                                                                                                    ];
                                                                                                                                            }), undefined, []))]))
                                                                                                                      ]));
                                                                                                      }), listeners$prime))]))
                                                                                ]))]));
                                                      })));
                                          }
                                          return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, paper, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, (function (_event) {
                                                                                          return Curry._1(self.send, /* Dialog */[/* NewReceive */0]);
                                                                                        }), undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Create a new listener to receive crashes")), Caml_option.some(I18N$BsConsole.show(undefined, "Any number of http/writer listeners can be configured to receive crashes")), [])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                                              ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, tmp, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Modify the web interface listener")), Caml_option.some(I18N$BsConsole.show(undefined, "The http/console listener serves the coronerd frontend")), [])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                                              ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, tmp$1, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Modify the management console socket")), Caml_option.some(I18N$BsConsole.show(undefined, "The console/socket exposes a terminal based management interface for server administrators ")), [])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                                      ]))
                                                                            ]))])),
                                                          tmp$2
                                                        ]));
                                        }))),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      cacheKey: 0,
                      dialog: undefined
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey,
                            dialog: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey + 1 | 0,
                            dialog: state.dialog
                          }]);
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

exports.listenerTypeToDisplayString = listenerTypeToDisplayString;
exports.Styles = Styles;
exports.Messages = Messages;
exports.validateDnsName = validateDnsName;
exports.validateBacklog = validateBacklog;
exports.validateService = validateService;
exports.validateConcurrency = validateConcurrency;
exports.DeleteListenerDialog = DeleteListenerDialog;
exports.ModifyListenerDialog = ModifyListenerDialog;
exports.serviceValidator = serviceValidator;
exports.ReceiveNewDialog = ReceiveNewDialog;
exports.component = component$3;
exports.make = make$3;
/* containerGrid Not a pure module */
