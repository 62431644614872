// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Bpg$BsConsole = require("./bpg.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var BpgView$BsConsole = require("./bpgView.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");

var paper = Css.style(/* :: */[
      Css.padding(Css.rem(2)),
      /* [] */0
    ]);

var select = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var Styles = {
  paper: paper,
  select: select
};

var $$let = Bpg$BsConsole.Make({
      table: Bpg$BsConsole.Project_.table,
      decode: Bpg$BsConsole.Project_.decode,
      toTupleList: Bpg$BsConsole.Project_.toTupleList
    });

var Table = BpgView$BsConsole.Make({
      toTupleList: $$let.toTupleList,
      decode: $$let.decode,
      decodeBPG: $$let.decodeBPG,
      encode: $$let.encode,
      encodePrimaryKey: $$let.encodePrimaryKey,
      get: $$let.get,
      create: $$let.create,
      toJSON: $$let.toJSON
    });

var component = ReasonReact.statelessComponent("Settings");

function make(shell, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var tmp;
              var exit = 0;
              if (typeof shell === "number" || shell.tag) {
                exit = 1;
              } else {
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, undefined, true, undefined, [
                          ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Select a project")])),
                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    /* `String */[
                                      -976970511,
                                      "coronerd"
                                    ],
                                    undefined,
                                    true,
                                    undefined,
                                    select,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [List.map((function (project) {
                                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                              -976970511,
                                                              project.name
                                                            ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(project.name)]))]));
                                            }), shell[0].projects)]
                                  ]))
                        ]));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          /* `String */[
                            -976970511,
                            "coronerd"
                          ],
                          undefined,
                          true,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          true,
                          undefined,
                          []
                        ]));
              }
              return React.createElement("div", {
                          style: {
                            display: "flex",
                            flexDirection: "column"
                          }
                        }, React.createElement("div", {
                              style: {
                                margin: "3em auto",
                                marginLeft: "calc(auto+300px)",
                                maxWidth: "680px",
                                minWidth: "550px",
                                flex: "5"
                              }
                            }, React.createElement("div", {
                                  style: {
                                    marginLeft: "-4px"
                                  },
                                  onClick: (function (_event) {
                                      return Curry._1(handleChangeUrl, /* SettingsProjects */8);
                                    })
                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardBackspace.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, paper, undefined, undefined, 0, [
                                      ReasonReact.element(undefined, undefined, Ui$BsConsole.AppBarSpacer.make([])),
                                      tmp,
                                      ReasonReact.element(undefined, undefined, Ui$BsConsole.AppBarSpacer.make([])),
                                      ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Included configuration objects")])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormGroup.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                    undefined,
                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "listeners")),
                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  "listeners",
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                    undefined,
                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "symbols")),
                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  "symbols",
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                    undefined,
                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "etc")),
                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  "etc",
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ]))
                                                        ]))
                                              ])),
                                      ReasonReact.element(undefined, undefined, Ui$BsConsole.AppBarSpacer.make([])),
                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                true,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                true,
                                                Caml_option.some(I18N$BsConsole.show(undefined, "new project name")),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                []
                                              ])),
                                      ReasonReact.element(undefined, undefined, Ui$BsConsole.AppBarSpacer.make([])),
                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Cancel")]
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Fork")]
                                                        ]))
                                              ]))
                                    ]))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.Table = Table;
exports.component = component;
exports.make = make;
/* paper Not a pure module */
