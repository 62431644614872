// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("PrContentWrap-BsConsole");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReactDOMRe.createElementVariadic("div", {
                          style: {
                            overflowY: "auto",
                            padding: String(24) + "px"
                          }
                        }, children);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

var padding = 24;

exports.RR = RR;
exports.component = component;
exports.padding = padding;
exports.make = make;
/* component Not a pure module */
