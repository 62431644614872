// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Crdb$BsConsole = require("../crdb.js");
var ExploreAggregateData$BsConsole = require("../ExploreAggregateData.js");

var fingerprintUniqueAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "fingerprint",
      /* Unique */4
    ]);

var errorTypeHeadAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "error.type",
      /* Head */1
    ]);

var errorTypeDistributionAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "error.type",
      /* Distribution */Block.__(1, [3])
    ]);

var callstackHeadAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "callstack",
      /* Head */1
    ]);

var unameSysnameDistributionAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "uname.sysname",
      /* Distribution */Block.__(1, [3])
    ]);

var deviceModelDistributionAggregate = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "device.model",
      /* Distribution */Block.__(1, [3])
    ]);

var fingerprintsOfLastWeekName = "Fingerprints over the past week";

function fingerprintsOfLastWeek(normBy, hasErrorType, hasDeviceModel) {
  var aperture = Crdb$BsConsole.Aperture.$$default;
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, callstackHeadAggregate, __x);
  var fold = ExploreAggregateData$BsConsole.createFold(Curry._2(Crdb$BsConsole.Fold.add, unameSysnameDistributionAggregate, __x$1), aperture);
  var fold$1 = hasErrorType ? Curry._2(Crdb$BsConsole.Fold.add, errorTypeHeadAggregate, fold) : fold;
  var fold$2 = hasDeviceModel ? Curry._2(Crdb$BsConsole.Fold.add, deviceModelDistributionAggregate, fold$1) : fold$1;
  return /* Dashboard */Block.__(0, [{
              username: "Backtrace",
              version: /* V1 */1,
              name: fingerprintsOfLastWeekName,
              mode: /* Aggregate */1,
              query: /* Aggregate */Block.__(0, [
                  Crdb$BsConsole.Aperture.$$default,
                  /* Identity */0,
                  fold$2,
                  Crdb$BsConsole.Sort.defaultAggregate,
                  undefined,
                  undefined
                ]),
              normBy: normBy,
              columnOrder: undefined,
              columnWidth: undefined,
              view: undefined
            }]);
}

var errorsByVersionName = "Errors by version";

function errorsByVersion(versionAttribute, normBy, hasErrorType, hasDeviceModel) {
  var aperture = Crdb$BsConsole.Aperture.$$default;
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, fingerprintUniqueAggregate, __x);
  var fold = ExploreAggregateData$BsConsole.createFold(Curry._2(Crdb$BsConsole.Fold.add, unameSysnameDistributionAggregate, __x$1), aperture);
  var fold$1 = hasErrorType ? Curry._2(Crdb$BsConsole.Fold.add, errorTypeDistributionAggregate, fold) : fold;
  var fold$2 = hasDeviceModel ? Curry._2(Crdb$BsConsole.Fold.add, deviceModelDistributionAggregate, fold$1) : fold$1;
  return /* Dashboard */Block.__(0, [{
              username: "Backtrace",
              version: /* V1 */1,
              name: errorsByVersionName,
              mode: /* Aggregate */1,
              query: /* Aggregate */Block.__(0, [
                  Crdb$BsConsole.Aperture.$$default,
                  /* Custom */[versionAttribute],
                  fold$2,
                  Crdb$BsConsole.Sort.defaultAggregate,
                  undefined,
                  undefined
                ]),
              normBy: normBy,
              columnOrder: undefined,
              columnWidth: undefined,
              view: undefined
            }]);
}

var errorByDeviceModelName = "Errors by device model";

function errorsByDeviceModel(normBy, hasErrorType) {
  var aperture = Crdb$BsConsole.Aperture.$$default;
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, unameSysnameDistributionAggregate, __x);
  var fold = ExploreAggregateData$BsConsole.createFold(Curry._2(Crdb$BsConsole.Fold.add, fingerprintUniqueAggregate, __x$1), aperture);
  var fold$1 = hasErrorType ? Curry._2(Crdb$BsConsole.Fold.add, errorTypeDistributionAggregate, fold) : fold;
  return /* Dashboard */Block.__(0, [{
              username: "Backtrace",
              version: /* V1 */1,
              name: errorByDeviceModelName,
              mode: /* Aggregate */1,
              query: /* Aggregate */Block.__(0, [
                  Crdb$BsConsole.Aperture.$$default,
                  /* Custom */["device.model"],
                  fold$1,
                  Crdb$BsConsole.Sort.defaultAggregate,
                  undefined,
                  undefined
                ]),
              normBy: normBy,
              columnOrder: undefined,
              columnWidth: undefined,
              view: undefined
            }]);
}

var errorsByTypeName = "Errors by type";

function errorsByType(normBy, hasDeviceModel) {
  var aperture = Crdb$BsConsole.Aperture.$$default;
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, fingerprintUniqueAggregate, __x);
  var fold = ExploreAggregateData$BsConsole.createFold(Curry._2(Crdb$BsConsole.Fold.add, unameSysnameDistributionAggregate, __x$1), aperture);
  var fold$1 = hasDeviceModel ? Curry._2(Crdb$BsConsole.Fold.add, deviceModelDistributionAggregate, fold) : fold;
  return /* Dashboard */Block.__(0, [{
              username: "Backtrace",
              version: /* V1 */1,
              name: errorsByTypeName,
              mode: /* Aggregate */1,
              query: /* Aggregate */Block.__(0, [
                  Crdb$BsConsole.Aperture.$$default,
                  /* Custom */["error.type"],
                  fold$1,
                  Crdb$BsConsole.Sort.defaultAggregate,
                  undefined,
                  undefined
                ]),
              normBy: normBy,
              columnOrder: undefined,
              columnWidth: undefined,
              view: undefined
            }]);
}

function getVersionAttribute(versionAttribute, attributes) {
  if (versionAttribute !== undefined) {
    return versionAttribute;
  } else {
    return Belt_Option.map(Belt_List.getBy(attributes, (function (attribute) {
                      var match = attribute.name;
                      var tmp;
                      switch (match) {
                        case "Version" :
                        case "application.version" :
                        case "version" :
                            tmp = true;
                            break;
                        default:
                          tmp = false;
                      }
                      if (tmp) {
                        return attribute.statistics.used;
                      } else {
                        return false;
                      }
                    })), (function (attribute) {
                  return attribute.name;
                }));
  }
}

function getV1Views(versionAttribute, normBy, attributes) {
  var attributeNames = Belt_List.toArray(Belt_List.map(Belt_List.keep(attributes, (function (attribute) {
                  return attribute.statistics.used;
                })), (function (attribute) {
              return attribute.name;
            })));
  var hasErrorType = attributeNames.includes("error.type");
  var hasDeviceModel = attributeNames.includes("device.model");
  var defaultQuery1_001 = fingerprintsOfLastWeek(normBy, hasErrorType, hasDeviceModel);
  var defaultQuery1 = /* tuple */[
    fingerprintsOfLastWeekName,
    defaultQuery1_001
  ];
  var defaultQuery2_001 = Belt_Option.map(getVersionAttribute(versionAttribute, attributes), (function (versionAttribute) {
          return errorsByVersion(versionAttribute, normBy, hasErrorType, hasDeviceModel);
        }));
  var defaultQuery2 = /* tuple */[
    errorsByVersionName,
    defaultQuery2_001
  ];
  var defaultQuery3 = hasDeviceModel ? /* tuple */[
      errorByDeviceModelName,
      errorsByDeviceModel(normBy, hasErrorType)
    ] : /* tuple */[
      errorByDeviceModelName,
      undefined
    ];
  var defaultQuery4 = hasErrorType ? /* tuple */[
      errorsByTypeName,
      errorsByType(normBy, hasDeviceModel)
    ] : /* tuple */[
      errorsByTypeName,
      undefined
    ];
  return /* :: */[
          defaultQuery1,
          /* :: */[
            defaultQuery2,
            /* :: */[
              defaultQuery3,
              /* :: */[
                defaultQuery4,
                /* [] */0
              ]
            ]
          ]
        ];
}

function getViews(versionAttribute, normBy, attributes) {
  var v1 = Belt_List.keepMap(Belt_List.map(getV1Views(versionAttribute, normBy, attributes), (function (param) {
              return param[1];
            })), (function (view) {
          return view;
        }));
  return Belt_List.concatMany([v1]);
}

function getNamesToAddAsBookmarks(versionAttribute, normBy, attributes, previousVersion) {
  var v1 = Belt_List.map(getV1Views(versionAttribute, normBy, attributes), (function (param) {
          return param[0];
        }));
  var viewNamesByVersion = /* :: */[
    v1,
    /* [] */0
  ];
  return Belt_List.toArray(Belt_List.flatten(Belt_Option.getWithDefault(Belt_List.take(Belt_List.reverse(viewNamesByVersion), 1 - previousVersion | 0), /* [] */0)));
}

var currentVersion = 1;

exports.currentVersion = currentVersion;
exports.fingerprintUniqueAggregate = fingerprintUniqueAggregate;
exports.errorTypeHeadAggregate = errorTypeHeadAggregate;
exports.errorTypeDistributionAggregate = errorTypeDistributionAggregate;
exports.callstackHeadAggregate = callstackHeadAggregate;
exports.unameSysnameDistributionAggregate = unameSysnameDistributionAggregate;
exports.deviceModelDistributionAggregate = deviceModelDistributionAggregate;
exports.fingerprintsOfLastWeekName = fingerprintsOfLastWeekName;
exports.fingerprintsOfLastWeek = fingerprintsOfLastWeek;
exports.errorsByVersionName = errorsByVersionName;
exports.errorsByVersion = errorsByVersion;
exports.errorByDeviceModelName = errorByDeviceModelName;
exports.errorsByDeviceModel = errorsByDeviceModel;
exports.errorsByTypeName = errorsByTypeName;
exports.errorsByType = errorsByType;
exports.getVersionAttribute = getVersionAttribute;
exports.getV1Views = getV1Views;
exports.getViews = getViews;
exports.getNamesToAddAsBookmarks = getNamesToAddAsBookmarks;
/* fingerprintUniqueAggregate Not a pure module */
