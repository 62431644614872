'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = labelTransform;

var _orientation = require('../constants/orientation');

var _orientation2 = _interopRequireDefault(_orientation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function labelTransform(_ref) {
  var labelOffset = _ref.labelOffset,
      labelProps = _ref.labelProps,
      orientation = _ref.orientation,
      range = _ref.range,
      tickLabelFontSize = _ref.tickLabelFontSize,
      tickLength = _ref.tickLength;

  var sign = orientation === _orientation2.default.left || orientation === _orientation2.default.top ? -1 : 1;

  var x = void 0,
      y = void 0,
      transform = null;
  if (orientation === _orientation2.default.top || orientation === _orientation2.default.bottom) {
    x = Math.max.apply(Math, _toConsumableArray(range)) / 2;
    y = sign * (tickLength + labelOffset + tickLabelFontSize + (orientation === _orientation2.default.bottom ? labelProps.fontSize : 0));
  } else {
    x = sign * (Math.max.apply(Math, _toConsumableArray(range)) / 2);
    y = -(tickLength + labelOffset);
    transform = 'rotate(' + sign * 90 + ')';
  }

  return { x: x, y: y, transform: transform };
}