// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");

function fuzz(searchValue, options, getName) {
  return Belt_Array.keepMap(Belt_Array.map(FuzzaldrinPlus.filter(Belt_Array.mapWithIndex(options, (function (i, e) {
                            return {
                                    q: Curry._1(getName, e),
                                    index: i
                                  };
                          })), searchValue, {
                      key: "q"
                    }), (function (r) {
                    return r.index;
                  })), (function (index) {
                return Belt_Array.get(options, index);
              }));
}

function getMatchParts(value, query) {
  var len = value.length;
  var matches = FuzzaldrinPlus.match(value, query);
  if (matches.length === 0) {
    return [{
              highlight: false,
              text: value
            }];
  }
  var lastExists = Belt_Array.getExn(matches, 0) === 0;
  var currBatch = [value[0]];
  var results = [];
  for(var i = 1; i < len; ++i){
    var exists = Belt_Option.isSome(Caml_option.undefined_to_opt(matches.find((function(i){
                return function (x) {
                  return x === i;
                }
                }(i)))));
    if (exists === lastExists) {
      var __x = currBatch;
      __x.push(value[i]);
    } else {
      results.push({
            highlight: lastExists,
            text: currBatch.join("")
          });
      currBatch = [value[i]];
    }
    lastExists = exists;
  }
  if (currBatch.length !== 0) {
    results.push({
          highlight: lastExists,
          text: currBatch.join("")
        });
  }
  return results;
}

exports.fuzz = fuzz;
exports.getMatchParts = getMatchParts;
/* fuzzaldrin-plus Not a pure module */
