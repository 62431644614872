// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Util$BsConsole = require("./util.js");
var Regex$BsConsole = require("./Regex.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function fromInt(dateInt) {
  return new Date(Caml_int64.to_float(Caml_int64.mul(dateInt, Caml_int64.mk(1000, 0))));
}

function toInt(date) {
  return Caml_int64.div(Caml_int64.of_float(date.valueOf()), Caml_int64.mk(1000, 0));
}

var $$Date$1 = {
  fromInt: fromInt,
  toInt: toInt
};

var fromString = Crdb$BsConsole.Granularity.stringToGranularity;

var toString = Crdb$BsConsole.Granularity.granularityToString;

var Granularity = {
  fromString: fromString,
  toString: toString
};

function fromString$1(expStr) {
  var len = expStr.length;
  var firstChar = expStr.charAt(0);
  var lastChar = expStr.charAt(len - 1 | 0);
  if (Regex$BsConsole.isDateInput(expStr) || Regex$BsConsole.isDateInputSeconds(expStr)) {
    return /* Date */Block.__(5, [new Date(expStr)]);
  }
  if (expStr === "true") {
    return /* Bool */Block.__(4, [true]);
  }
  if (expStr === "false") {
    return /* Bool */Block.__(4, [false]);
  }
  if (firstChar === "(" && lastChar === ")") {
    var func = function (param, param$1) {
      return param$1.slice(1, param);
    };
    var trimmed = Curry._2(func, -1, expStr);
    var delimiters = [];
    var openBrackets = 0;
    for(var i = 0 ,i_finish = trimmed.length; i <= i_finish; ++i){
      var $$char = trimmed.charAt(i);
      switch ($$char) {
        case "(" :
            openBrackets = openBrackets + 1 | 0;
            break;
        case ")" :
            openBrackets = openBrackets - 1 | 0;
            break;
        case "," :
            if (openBrackets === 0) {
              delimiters.push(i);
            }
            break;
        default:
          
      }
    }
    var lastPart = delimiters.length !== 0 ? trimmed.slice(1 + Belt_Array.getExn(delimiters, delimiters.length - 1 | 0) | 0) : trimmed;
    var parts = Belt_Array.concat(Belt_Array.mapWithIndex(delimiters, (function (i, strIdx) {
                if (i === 0) {
                  return trimmed.slice(0, strIdx);
                }
                var startIdx = Belt_Array.getExn(delimiters, i - 1 | 0);
                var param = startIdx + 1 | 0;
                return trimmed.slice(param, strIdx);
              })), [lastPart]);
    return /* List */Block.__(0, [Belt_List.map(Belt_List.fromArray(parts), fromString$1)]);
  }
  if (firstChar === "\"" && lastChar === "\"") {
    var func$1 = function (param, param$1) {
      return param$1.slice(1, param);
    };
    var trimmed$1 = Curry._2(func$1, -1, expStr);
    return /* Str */Block.__(1, [trimmed$1]);
  }
  var match = firstChar.match(/[0-9-.]/);
  if (match === null) {
    return /* Str */Block.__(1, [expStr]);
  }
  if ((
      match === null ? undefined : Caml_option.some(match)
    ).length !== 1) {
    return /* Str */Block.__(1, [expStr]);
  }
  try {
    return /* Int */Block.__(2, [Caml_format.caml_int64_of_string(expStr)]);
  }
  catch (exn){
    return /* Float */Block.__(3, [Caml_format.caml_float_of_string(expStr)]);
  }
}

var usedChars = [
  "(",
  "[",
  "-",
  ".",
  "\"",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0"
];

function toString$1(exp) {
  switch (exp.tag | 0) {
    case /* List */0 :
        return "(" + (Belt_List.toArray(Belt_List.map(exp[0], toString$1)).join(",") + ")");
    case /* Str */1 :
        var str = exp[0];
        var firstChar = str.charAt(0);
        if (usedChars.includes(firstChar) || str === "true" || str === "false") {
          return "\"" + (str + "\"");
        } else {
          return str;
        }
    case /* Int */2 :
        return Int64.to_string(exp[0]);
    case /* Float */3 :
        return "" + (String(exp[0]) + "");
    case /* Bool */4 :
        if (exp[0]) {
          return "true";
        } else {
          return "false";
        }
    case /* Date */5 :
        return DateFns.format("YYYY-MM-DDTHH:mm:ssZ", exp[0]);
    case /* ObjectID */6 :
        return ObjectID$BsConsole.toHexString(exp[0]);
    
  }
}

var Exp = {
  fromString: fromString$1,
  usedChars: usedChars,
  toString: toString$1
};

function encode(ta) {
  switch (ta.tag | 0) {
    case /* Relative */0 :
        var date = ta[0];
        if (date) {
          return /* List */Block.__(0, [/* :: */[
                      /* Str */Block.__(1, ["relative"]),
                      /* :: */[
                        /* Str */Block.__(1, ["fixed"]),
                        /* :: */[
                          /* Date */Block.__(5, [date[0]]),
                          /* :: */[
                            /* Str */Block.__(1, [Curry._1(toString, ta[1])]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]]);
        } else {
          return /* Str */Block.__(1, [Curry._1(toString, ta[1])]);
        }
    case /* Custom */1 :
        var match = ta[0];
        return /* List */Block.__(0, [/* :: */[
                    /* Date */Block.__(5, [match[0]]),
                    /* :: */[
                      /* Date */Block.__(5, [match[1]]),
                      /* [] */0
                    ]
                  ]]);
    case /* RelativeFirstSeen */2 :
        var nowDate = ta[0];
        if (nowDate) {
          return /* List */Block.__(0, [/* :: */[
                      /* Str */Block.__(1, ["relative-first-seen"]),
                      /* :: */[
                        /* Str */Block.__(1, ["fixed"]),
                        /* :: */[
                          /* Date */Block.__(5, [nowDate[0]]),
                          /* :: */[
                            /* Str */Block.__(1, [Curry._1(toString, ta[1])]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]]);
        } else {
          return /* List */Block.__(0, [/* :: */[
                      /* Str */Block.__(1, ["relative-first-seen"]),
                      /* :: */[
                        /* Str */Block.__(1, ["floating"]),
                        /* :: */[
                          /* Str */Block.__(1, [Curry._1(toString, ta[1])]),
                          /* [] */0
                        ]
                      ]
                    ]]);
        }
    case /* CustomFirstSeen */3 :
        var match$1 = ta[0];
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, ["custom-first-seen"]),
                    /* :: */[
                      /* Date */Block.__(5, [match$1[0]]),
                      /* :: */[
                        /* Date */Block.__(5, [match$1[1]]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    
  }
}

function decode(query) {
  switch (query.tag | 0) {
    case /* List */0 :
        var exps = query[0];
        if (exps) {
          var fromDate = exps[0];
          switch (fromDate.tag | 0) {
            case /* Str */1 :
                switch (fromDate[0]) {
                  case "custom" :
                      var match = exps[1];
                      if (match) {
                        var fromDate$1 = match[0];
                        if (fromDate$1.tag === /* Date */5) {
                          var match$1 = match[1];
                          if (match$1) {
                            var toDate = match$1[0];
                            if (toDate.tag === /* Date */5 && !match$1[1]) {
                              return /* Custom */Block.__(1, [/* tuple */[
                                          fromDate$1[0],
                                          toDate[0]
                                        ]]);
                            }
                            
                          }
                          
                        }
                        
                      }
                      break;
                  case "custom-first-seen" :
                      var match$2 = exps[1];
                      if (match$2) {
                        var fromDate$2 = match$2[0];
                        if (fromDate$2.tag === /* Date */5) {
                          var match$3 = match$2[1];
                          if (match$3) {
                            var toDate$1 = match$3[0];
                            if (toDate$1.tag === /* Date */5 && !match$3[1]) {
                              return /* CustomFirstSeen */Block.__(3, [/* tuple */[
                                          fromDate$2[0],
                                          toDate$1[0]
                                        ]]);
                            }
                            
                          }
                          
                        }
                        
                      }
                      break;
                  case "relative" :
                      var match$4 = exps[1];
                      if (match$4) {
                        var match$5 = match$4[0];
                        if (match$5.tag === /* Str */1 && match$5[0] === "fixed") {
                          var match$6 = match$4[1];
                          if (match$6) {
                            var nowDate = match$6[0];
                            if (nowDate.tag === /* Date */5) {
                              var match$7 = match$6[1];
                              if (match$7) {
                                var granularityStr = match$7[0];
                                if (granularityStr.tag === /* Str */1 && !match$7[1]) {
                                  return /* Relative */Block.__(0, [
                                            /* Fixed */[nowDate[0]],
                                            Curry._1(fromString, granularityStr[0])
                                          ]);
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      break;
                  case "relative-first-seen" :
                      var match$8 = exps[1];
                      if (match$8) {
                        var match$9 = match$8[0];
                        if (match$9.tag === /* Str */1) {
                          switch (match$9[0]) {
                            case "fixed" :
                                var match$10 = match$8[1];
                                if (match$10) {
                                  var nowDate$1 = match$10[0];
                                  if (nowDate$1.tag === /* Date */5) {
                                    var match$11 = match$10[1];
                                    if (match$11) {
                                      var granularityStr$1 = match$11[0];
                                      if (granularityStr$1.tag === /* Str */1 && !match$11[1]) {
                                        return /* RelativeFirstSeen */Block.__(2, [
                                                  /* Fixed */[nowDate$1[0]],
                                                  Curry._1(fromString, granularityStr$1[0])
                                                ]);
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                break;
                            case "floating" :
                                var match$12 = match$8[1];
                                if (match$12) {
                                  var granularityStr$2 = match$12[0];
                                  if (granularityStr$2.tag === /* Str */1 && !match$12[1]) {
                                    return /* RelativeFirstSeen */Block.__(2, [
                                              /* Floating */0,
                                              Curry._1(fromString, granularityStr$2[0])
                                            ]);
                                  }
                                  
                                }
                                break;
                            default:
                              
                          }
                        }
                        
                      }
                      break;
                  default:
                    
                }
                break;
            case /* Date */5 :
                var match$13 = exps[1];
                if (match$13) {
                  var toDate$2 = match$13[0];
                  if (toDate$2.tag === /* Date */5 && !match$13[1]) {
                    return /* Custom */Block.__(1, [/* tuple */[
                                fromDate[0],
                                toDate$2[0]
                              ]]);
                  }
                  
                }
                break;
            default:
              
          }
        }
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "UrlEncode.re",
                188,
                13
              ]
            ];
    case /* Str */1 :
        return /* Relative */Block.__(0, [
                  /* Floating */0,
                  Curry._1(fromString, query[0])
                ]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "UrlEncode.re",
              194,
              16
            ]
          ];
  }
}

var Time = {
  encode: encode,
  decode: decode
};

function encodeValue(v) {
  var variant = v[0];
  if (variant >= 3654863) {
    if (variant >= 737456202) {
      if (variant >= 758940238) {
        return /* Date */Block.__(5, [v[1]]);
      } else {
        return /* Str */Block.__(1, [v[1] ? "true" : "false"]);
      }
    } else if (variant >= 365180284) {
      return /* Float */Block.__(3, [v[1]]);
    } else {
      return /* Float */Block.__(3, [Caml_int64.to_float(v[1])]);
    }
  } else if (variant >= -589436806) {
    return /* ObjectID */Block.__(6, [v[1]]);
  } else {
    return /* Str */Block.__(1, [v[1]]);
  }
}

function valueToString(v) {
  var variant = v[0];
  if (variant >= 3654863) {
    if (variant >= 737456202) {
      if (variant >= 758940238) {
        return DateFns.format("YYYY-MM-DDTHH:mm:ssZ", v[1]);
      } else if (v[1]) {
        return "true";
      } else {
        return "false";
      }
    } else if (variant >= 365180284) {
      return v[1].toString();
    } else {
      return Caml_int64.to_float(v[1]).toString();
    }
  } else if (variant >= -589436806) {
    return ObjectID$BsConsole.toHexString(v[1]);
  } else {
    return v[1];
  }
}

function valueFromString(v) {
  if (Regex$BsConsole.isDateInput(v)) {
    return /* `Date */[
            758940238,
            new Date(v)
          ];
  } else if (v === "true" || v === "false") {
    return /* `Bool */[
            737456202,
            Pervasives.bool_of_string(v)
          ];
  } else {
    return /* `String */[
            -976970511,
            v
          ];
  }
}

function encode$1(filter) {
  var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
  var operation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
  return [
          attribute,
          Crdb$BsConsole.FilterOperation.toString(operation),
          valueToString(operation[0])
        ];
}

function decode2(filterJson) {
  var filter = Json_decode.array(Json_decode.string, filterJson);
  if (filter.length !== 3) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "UrlEncode.re",
            272,
            11
          ]
        ];
  }
  var attribute = filter[0];
  if (attribute === "_tx") {
    var operator = filter[1];
    var value = filter[2];
    var op = Crdb$BsConsole.FilterOperation.fromString(operator, /* `ObjectID */[
          -589436806,
          ObjectID$BsConsole.fromHexString(value)
        ]);
    return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                "_tx",
                op
              ]);
  }
  var operator$1 = filter[1];
  var value$1 = filter[2];
  var op$1 = Crdb$BsConsole.FilterOperation.fromString(operator$1, valueFromString(value$1));
  return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
              attribute,
              op$1
            ]);
}

function decodeValue(exp) {
  switch (exp.tag | 0) {
    case /* List */0 :
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "UrlEncode.re",
                285,
                11
              ]
            ];
    case /* Str */1 :
        return /* `String */[
                -976970511,
                exp[0]
              ];
    case /* Int */2 :
        return /* `Int */[
                3654863,
                exp[0]
              ];
    case /* Float */3 :
        return /* `Float */[
                365180284,
                exp[0]
              ];
    case /* Bool */4 :
        return /* `Bool */[
                737456202,
                exp[0]
              ];
    case /* Date */5 :
        return /* `Date */[
                758940238,
                exp[0]
              ];
    case /* ObjectID */6 :
        return /* `ObjectID */[
                -589436806,
                exp[0]
              ];
    
  }
}

function decode$1(query) {
  switch (query.tag | 0) {
    case /* List */0 :
        var match = query[0];
        if (match) {
          var attribute = match[0];
          if (attribute.tag === /* Str */1) {
            var match$1 = match[1];
            if (match$1) {
              var operation = match$1[0];
              if (operation.tag === /* Str */1) {
                var match$2 = match$1[1];
                if (match$2 && !match$2[1]) {
                  var value = decodeValue(match$2[0]);
                  var op;
                  switch (operation[0]) {
                    case "assigned-to" :
                        op = /* AssignedTo */Block.__(10, [value]);
                        break;
                    case "at-least" :
                        op = /* AtLeast */Block.__(8, [value]);
                        break;
                    case "at-most" :
                        op = /* AtMost */Block.__(9, [value]);
                        break;
                    case "contains" :
                        op = /* Contains */Block.__(2, [value]);
                        break;
                    case "equal" :
                        op = /* Equal */Block.__(0, [value]);
                        break;
                    case "greater-than" :
                        op = /* GreaterThan */Block.__(6, [value]);
                        break;
                    case "inverse-regex" :
                    case "inverse-regular-expression" :
                        op = /* InverseRegularExpression */Block.__(5, [value]);
                        break;
                    case "less-than" :
                        op = /* LessThan */Block.__(7, [value]);
                        break;
                    case "linked-to" :
                        op = /* LinkedTo */Block.__(12, [value]);
                        break;
                    case "not-assigned-to" :
                        op = /* NotAssignedTo */Block.__(11, [value]);
                        break;
                    case "not-contains" :
                        op = /* NotContains */Block.__(3, [value]);
                        break;
                    case "not-equal" :
                        op = /* NotEqual */Block.__(1, [value]);
                        break;
                    case "not-linked-to" :
                        op = /* NotLinkedTo */Block.__(13, [value]);
                        break;
                    case "regex" :
                    case "regular-expression" :
                        op = /* RegularExpression */Block.__(4, [value]);
                        break;
                    default:
                      throw [
                            Caml_builtin_exceptions.assert_failure,
                            /* tuple */[
                              "UrlEncode.re",
                              329,
                              15
                            ]
                          ];
                  }
                  return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                              attribute[0],
                              op
                            ]);
                }
                
              }
              
            }
            
          }
          
        }
        break;
    case /* Str */1 :
        var match_ = Belt_Option.map(Caml_option.null_to_opt(/^(.+?)(>=|<=|!=|<|>|=)(.+)$/.exec(query[0])), (function (prim) {
                return prim;
              }));
        if (match_ !== undefined) {
          var attr = Belt_Array.getExn(match_, 1);
          var opStr = Belt_Array.getExn(match_, 2);
          var value$1 = decodeValue(fromString$1(Belt_Array.getExn(match_, 3)));
          var operation$1;
          switch (opStr) {
            case "!=" :
                operation$1 = /* NotEqual */Block.__(1, [value$1]);
                break;
            case "<" :
                operation$1 = /* LessThan */Block.__(7, [value$1]);
                break;
            case "<=" :
                operation$1 = /* AtMost */Block.__(9, [value$1]);
                break;
            case "=" :
                operation$1 = /* Equal */Block.__(0, [value$1]);
                break;
            case ">" :
                operation$1 = /* GreaterThan */Block.__(6, [value$1]);
                break;
            case ">=" :
                operation$1 = /* AtLeast */Block.__(8, [value$1]);
                break;
            default:
              throw [
                    Caml_builtin_exceptions.assert_failure,
                    /* tuple */[
                      "UrlEncode.re",
                      304,
                      17
                    ]
                  ];
          }
          return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                      attr,
                      operation$1
                    ]);
        }
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "UrlEncode.re",
                307,
                13
              ]
            ];
    default:
      
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          332,
          11
        ]
      ];
}

var Filter = {
  encodeValue: encodeValue,
  valueToString: valueToString,
  valueFromString: valueFromString,
  encode: encode$1,
  decode2: decode2,
  decodeValue: decodeValue,
  decode: decode$1
};

function encode$2(filters) {
  var __x = Belt_Array.map(Curry._1(Crdb$BsConsole.Filters.toArray, filters), encode$1);
  return btoa(encodeURIComponent(JSON.stringify(Json_encode.array((function (param) {
                            return Json_encode.array((function (prim) {
                                          return prim;
                                        }), param);
                          }), __x))));
}

function decode$2(query) {
  if (!query.tag) {
    return Belt_List.reduce(Belt_List.reverse(Belt_List.map(query[0], decode$1)), Crdb$BsConsole.Filters.empty, (function (acc, filter) {
                  return Curry._2(Crdb$BsConsole.Filters.add, filter, acc);
                }));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          355,
          11
        ]
      ];
}

function decode2$1(filterString) {
  var __x = JSON.parse(decodeURIComponent(atob(filterString)));
  return Curry._1(Crdb$BsConsole.Filters.fromArray, Json_decode.array(decode2, __x));
}

var Filters = {
  encode: encode$2,
  decode: decode$2,
  decode2: decode2$1
};

function encodeValue$1(v) {
  return /* Str */Block.__(1, [v]);
}

function encode$3(having) {
  var attribute = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Having.getAttribute, having), "");
  var pipe = Curry._1(Crdb$BsConsole.Having.getPipe, having);
  var attributePipe = attribute + ("|" + pipe);
  var v = Curry._1(Crdb$BsConsole.Having.getOperation, having);
  switch (v.tag | 0) {
    case /* Equal */0 :
        return /* Str */Block.__(1, [attributePipe + ("=" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    case /* NotEqual */1 :
        return /* Str */Block.__(1, [attributePipe + ("!=" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    case /* GreaterThan */2 :
        return /* Str */Block.__(1, [attributePipe + (">" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    case /* LessThan */3 :
        return /* Str */Block.__(1, [attributePipe + ("<" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    case /* AtLeast */4 :
        return /* Str */Block.__(1, [attributePipe + (">=" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    case /* AtMost */5 :
        return /* Str */Block.__(1, [attributePipe + ("<=" + toString$1(/* Str */Block.__(1, [v[0]])))]);
    
  }
}

function decodeValue$1(exp) {
  if (exp.tag === /* Str */1) {
    return exp[0];
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          388,
          11
        ]
      ];
}

function decode$3(query) {
  switch (query.tag | 0) {
    case /* List */0 :
        var match = query[0];
        if (match) {
          var attribute = match[0];
          if (attribute.tag === /* Str */1) {
            var match$1 = match[1];
            if (match$1) {
              var operation = match$1[0];
              if (operation.tag === /* Str */1) {
                var match$2 = match$1[1];
                if (match$2 && !match$2[1]) {
                  var value = decodeValue$1(match$2[0]);
                  var op;
                  switch (operation[0]) {
                    case "at-least" :
                        op = /* AtLeast */Block.__(4, [value]);
                        break;
                    case "at-most" :
                        op = /* AtMost */Block.__(5, [value]);
                        break;
                    case "equal" :
                        op = /* Equal */Block.__(0, [value]);
                        break;
                    case "greater-than" :
                        op = /* GreaterThan */Block.__(2, [value]);
                        break;
                    case "less-than" :
                        op = /* LessThan */Block.__(3, [value]);
                        break;
                    case "not-equal" :
                        op = /* NotEqual */Block.__(1, [value]);
                        break;
                    default:
                      throw [
                            Caml_builtin_exceptions.assert_failure,
                            /* tuple */[
                              "UrlEncode.re",
                              422,
                              15
                            ]
                          ];
                  }
                  return Curry._1(Crdb$BsConsole.Having.create, /* tuple */[
                              attribute[0],
                              op
                            ]);
                }
                
              }
              
            }
            
          }
          
        }
        break;
    case /* Str */1 :
        var match_ = Belt_Option.map(Caml_option.null_to_opt(/^(.+?)(>=|<=|!=|<|>|=)(.+)$/.exec(query[0])), (function (prim) {
                return prim;
              }));
        if (match_ !== undefined) {
          var attr = Belt_Array.getExn(match_, 1);
          var opStr = Belt_Array.getExn(match_, 2);
          var value$1 = decodeValue$1(fromString$1(Belt_Array.getExn(match_, 3)));
          var operation$1;
          switch (opStr) {
            case "!=" :
                operation$1 = /* NotEqual */Block.__(1, [value$1]);
                break;
            case "<" :
                operation$1 = /* LessThan */Block.__(3, [value$1]);
                break;
            case "<=" :
                operation$1 = /* AtMost */Block.__(5, [value$1]);
                break;
            case "=" :
                operation$1 = /* Equal */Block.__(0, [value$1]);
                break;
            case ">" :
                operation$1 = /* GreaterThan */Block.__(2, [value$1]);
                break;
            case ">=" :
                operation$1 = /* AtLeast */Block.__(4, [value$1]);
                break;
            default:
              throw [
                    Caml_builtin_exceptions.assert_failure,
                    /* tuple */[
                      "UrlEncode.re",
                      407,
                      17
                    ]
                  ];
          }
          return Curry._1(Crdb$BsConsole.Having.create, /* tuple */[
                      attr,
                      operation$1
                    ]);
        }
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "UrlEncode.re",
                410,
                13
              ]
            ];
    default:
      
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          425,
          11
        ]
      ];
}

var Having = {
  encodeValue: encodeValue$1,
  encode: encode$3,
  decodeValue: decodeValue$1,
  decode: decode$3
};

function encode$4(havings) {
  return /* List */Block.__(0, [Belt_List.fromArray(Belt_Array.map(Curry._1(Crdb$BsConsole.Havings.toArray, havings), encode$3))]);
}

function decode$4(query) {
  if (!query.tag) {
    return Belt_List.reduce(Belt_List.reverse(Belt_List.map(query[0], decode$3)), Crdb$BsConsole.Havings.empty, (function (acc, having) {
                  return Curry._2(Crdb$BsConsole.Havings.add, having, acc);
                }));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          441,
          11
        ]
      ];
}

var Havings = {
  encode: encode$4,
  decode: decode$4
};

function encode$5(filters) {
  return /* List */Block.__(0, [Belt_List.map(filters, (function (f) {
                    return /* Str */Block.__(1, [f]);
                  }))]);
}

function decode$5(query) {
  if (!query.tag) {
    return Belt_List.map(query[0], (function (exp) {
                  if (exp.tag === /* Str */1) {
                    return exp[0];
                  }
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "UrlEncode.re",
                          454,
                          15
                        ]
                      ];
                }));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          462,
          16
        ]
      ];
}

var Select = {
  encode: encode$5,
  decode: decode$5
};

function encode$6(factor) {
  if (factor) {
    return /* Str */Block.__(1, [factor[0]]);
  } else {
    return /* Str */Block.__(1, ["identity"]);
  }
}

function decode$6(query) {
  if (query.tag === /* Str */1) {
    var factor = query[0];
    switch (factor) {
      case "ID" :
      case "Id" :
      case "fingerprint" :
      case "id" :
      case "identity" :
          return /* Identity */0;
      default:
        return /* Custom */[factor];
    }
  } else {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "UrlEncode.re",
            481,
            11
          ]
        ];
  }
}

var Factor = {
  encode: encode$6,
  decode: decode$6
};

function encodeOrder(ordering) {
  if (ordering) {
    return "desc";
  } else {
    return "asc";
  }
}

function encode$7(sort) {
  switch (sort.tag | 0) {
    case /* Group */0 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, ["group"]),
                    /* :: */[
                      /* Str */Block.__(1, [sort[0] ? "desc" : "asc"]),
                      /* [] */0
                    ]
                  ]]);
    case /* Count */1 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, ["errors"]),
                    /* :: */[
                      /* Str */Block.__(1, [sort[0] ? "desc" : "asc"]),
                      /* [] */0
                    ]
                  ]]);
    case /* Head */2 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["head"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Tail */3 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["tail"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Range */4 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["range"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] === /* Start */0 ? "min" : "max"]),
                        /* :: */[
                          /* Str */Block.__(1, [sort[2] ? "desc" : "asc"]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]]);
    case /* Select */5 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["select"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Unique */6 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["unique"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Min */7 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["min"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Max */8 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["max"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Mean */9 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["mean"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    case /* Sum */10 :
        return /* List */Block.__(0, [/* :: */[
                    /* Str */Block.__(1, [sort[0]]),
                    /* :: */[
                      /* Str */Block.__(1, ["sum"]),
                      /* :: */[
                        /* Str */Block.__(1, [sort[1] ? "desc" : "asc"]),
                        /* [] */0
                      ]
                    ]
                  ]]);
    
  }
}

function decodeOrder(str) {
  switch (str) {
    case "asc" :
    case "ascending" :
        return /* Ascending */0;
    case "desc" :
    case "descending" :
        return /* Descending */1;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "UrlEncode.re",
              519,
              11
            ]
          ];
  }
}

function decode$7(query) {
  if (!query.tag) {
    var match = query[0];
    if (match) {
      var attr = match[0];
      if (attr.tag === /* Str */1) {
        var attr$1 = attr[0];
        var exit = 0;
        switch (attr$1) {
          case "errors" :
              var match$1 = match[1];
              if (match$1) {
                var order = match$1[0];
                if (order.tag === /* Str */1) {
                  if (!match$1[1]) {
                    return /* Count */Block.__(1, [decodeOrder(order[0])]);
                  }
                  exit = 2;
                }
                
              }
              break;
          case "group" :
              var match$2 = match[1];
              if (match$2) {
                var order$1 = match$2[0];
                if (order$1.tag === /* Str */1) {
                  if (!match$2[1]) {
                    return /* Group */Block.__(0, [decodeOrder(order$1[0])]);
                  }
                  exit = 2;
                }
                
              }
              break;
          default:
            exit = 2;
        }
        if (exit === 2) {
          var match$3 = match[1];
          if (match$3) {
            var match$4 = match$3[0];
            if (match$4.tag === /* Str */1) {
              switch (match$4[0]) {
                case "head" :
                    var match$5 = match$3[1];
                    if (match$5) {
                      var order$2 = match$5[0];
                      if (order$2.tag === /* Str */1 && !match$5[1]) {
                        return /* Head */Block.__(2, [
                                  attr$1,
                                  decodeOrder(order$2[0])
                                ]);
                      }
                      
                    }
                    break;
                case "max" :
                    var match$6 = match$3[1];
                    if (match$6) {
                      var order$3 = match$6[0];
                      if (order$3.tag === /* Str */1 && !match$6[1]) {
                        return /* Max */Block.__(8, [
                                  attr$1,
                                  decodeOrder(order$3[0])
                                ]);
                      }
                      
                    }
                    break;
                case "mean" :
                    var match$7 = match$3[1];
                    if (match$7) {
                      var order$4 = match$7[0];
                      if (order$4.tag === /* Str */1 && !match$7[1]) {
                        return /* Mean */Block.__(9, [
                                  attr$1,
                                  decodeOrder(order$4[0])
                                ]);
                      }
                      
                    }
                    break;
                case "min" :
                    var match$8 = match$3[1];
                    if (match$8) {
                      var order$5 = match$8[0];
                      if (order$5.tag === /* Str */1 && !match$8[1]) {
                        return /* Min */Block.__(7, [
                                  attr$1,
                                  decodeOrder(order$5[0])
                                ]);
                      }
                      
                    }
                    break;
                case "range" :
                    var match$9 = match$3[1];
                    if (match$9) {
                      var range = match$9[0];
                      if (range.tag === /* Str */1) {
                        var match$10 = match$9[1];
                        if (match$10) {
                          var order$6 = match$10[0];
                          if (order$6.tag === /* Str */1 && !match$10[1]) {
                            var tmp;
                            switch (range[0]) {
                              case "finish" :
                              case "max" :
                                  tmp = /* Finish */1;
                                  break;
                              case "min" :
                              case "start" :
                                  tmp = /* Start */0;
                                  break;
                              default:
                                throw [
                                      Caml_builtin_exceptions.assert_failure,
                                      /* tuple */[
                                        "UrlEncode.re",
                                        534,
                                        15
                                      ]
                                    ];
                            }
                            return /* Range */Block.__(4, [
                                      attr$1,
                                      tmp,
                                      decodeOrder(order$6[0])
                                    ]);
                          }
                          
                        }
                        
                      }
                      
                    }
                    break;
                case "select" :
                    var match$11 = match$3[1];
                    if (match$11) {
                      var order$7 = match$11[0];
                      if (order$7.tag === /* Str */1 && !match$11[1]) {
                        return /* Select */Block.__(5, [
                                  attr$1,
                                  decodeOrder(order$7[0])
                                ]);
                      }
                      
                    }
                    break;
                case "sum" :
                    var match$12 = match$3[1];
                    if (match$12) {
                      var order$8 = match$12[0];
                      if (order$8.tag === /* Str */1 && !match$12[1]) {
                        return /* Sum */Block.__(10, [
                                  attr$1,
                                  decodeOrder(order$8[0])
                                ]);
                      }
                      
                    }
                    break;
                case "tail" :
                    var match$13 = match$3[1];
                    if (match$13) {
                      var order$9 = match$13[0];
                      if (order$9.tag === /* Str */1 && !match$13[1]) {
                        return /* Tail */Block.__(3, [
                                  attr$1,
                                  decodeOrder(order$9[0])
                                ]);
                      }
                      
                    }
                    break;
                case "unique" :
                    var match$14 = match$3[1];
                    if (match$14) {
                      var order$10 = match$14[0];
                      if (order$10.tag === /* Str */1 && !match$14[1]) {
                        return /* Unique */Block.__(6, [
                                  attr$1,
                                  decodeOrder(order$10[0])
                                ]);
                      }
                      
                    }
                    break;
                default:
                  
              }
            }
            
          }
          
        }
        
      }
      
    }
    
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          548,
          11
        ]
      ];
}

var Sort = {
  encodeOrder: encodeOrder,
  encode: encode$7,
  decodeOrder: decodeOrder,
  decode: decode$7
};

function encode$8(fold) {
  return /* List */Block.__(0, [Belt_List.map(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Fold.toArray, fold)), (function (agg) {
                    var operation = Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg);
                    var attr = Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg);
                    if (typeof operation === "number") {
                      return /* List */Block.__(0, [/* :: */[
                                  /* Str */Block.__(1, [attr]),
                                  /* :: */[
                                    /* Str */Block.__(1, [Crdb$BsConsole.Operation.toString(operation)]),
                                    /* [] */0
                                  ]
                                ]]);
                    }
                    if (operation.tag) {
                      return /* List */Block.__(0, [/* :: */[
                                  /* Str */Block.__(1, [attr]),
                                  /* :: */[
                                    /* Str */Block.__(1, ["distribution"]),
                                    Belt_Option.mapWithDefault(operation[0], /* [] */0, (function (bins) {
                                            return /* :: */[
                                                    /* Int */Block.__(2, [Caml_int64.of_int32(bins)]),
                                                    /* [] */0
                                                  ];
                                          }))
                                  ]
                                ]]);
                    }
                    var timeRange = operation[1];
                    var bins = operation[0];
                    return /* List */Block.__(0, [/* :: */[
                                /* Str */Block.__(1, [attr]),
                                /* :: */[
                                  /* Str */Block.__(1, ["bin"]),
                                  bins !== undefined ? (
                                      timeRange !== undefined ? /* :: */[
                                          /* Int */Block.__(2, [Caml_int64.of_int32(bins)]),
                                          /* :: */[
                                            /* List */Block.__(0, [/* :: */[
                                                  /* Int */Block.__(2, [timeRange[0]]),
                                                  /* :: */[
                                                    /* Int */Block.__(2, [timeRange[1]]),
                                                    /* [] */0
                                                  ]
                                                ]]),
                                            /* [] */0
                                          ]
                                        ] : /* :: */[
                                          /* Int */Block.__(2, [Caml_int64.of_int32(bins)]),
                                          /* [] */0
                                        ]
                                    ) : (
                                      timeRange !== undefined ? /* :: */[
                                          /* List */Block.__(0, [/* :: */[
                                                /* Int */Block.__(2, [timeRange[0]]),
                                                /* :: */[
                                                  /* Int */Block.__(2, [timeRange[1]]),
                                                  /* [] */0
                                                ]
                                              ]]),
                                          /* [] */0
                                        ] : /* [] */0
                                    )
                                ]
                              ]]);
                  }))]);
}

function decode$8(query) {
  if (!query.tag) {
    return Belt_List.reduce(Belt_List.reverse(query[0]), Crdb$BsConsole.Fold.empty, (function (acc, aggregationExp) {
                  var aggregation;
                  var exit = 0;
                  if (aggregationExp.tag) {
                    exit = 1;
                  } else {
                    var match = aggregationExp[0];
                    if (match) {
                      var attr = match[0];
                      if (attr.tag === /* Str */1) {
                        var rest = match[1];
                        var tmp;
                        var exit$1 = 0;
                        if (rest) {
                          var operation = rest[0];
                          if (operation.tag === /* Str */1) {
                            var operation$1 = operation[0];
                            switch (operation$1) {
                              case "bin" :
                                  var match$1 = rest[1];
                                  if (match$1) {
                                    var bins = match$1[0];
                                    switch (bins.tag | 0) {
                                      case /* List */0 :
                                          var match$2 = bins[0];
                                          if (match$2) {
                                            var fromDate = match$2[0];
                                            if (fromDate.tag === /* Int */2) {
                                              var match$3 = match$2[1];
                                              if (match$3) {
                                                var toDate = match$3[0];
                                                if (toDate.tag === /* Int */2 && !(match$3[1] || match$1[1])) {
                                                  tmp = /* Bin */Block.__(0, [
                                                      undefined,
                                                      /* tuple */[
                                                        fromDate[0],
                                                        toDate[0]
                                                      ]
                                                    ]);
                                                } else {
                                                  exit$1 = 2;
                                                }
                                              } else {
                                                exit$1 = 2;
                                              }
                                            } else {
                                              exit$1 = 2;
                                            }
                                          } else {
                                            exit$1 = 2;
                                          }
                                          break;
                                      case /* Int */2 :
                                          var match$4 = match$1[1];
                                          var bins$1 = bins[0];
                                          if (match$4) {
                                            var match$5 = match$4[0];
                                            if (match$5.tag) {
                                              exit$1 = 2;
                                            } else {
                                              var match$6 = match$5[0];
                                              if (match$6) {
                                                var fromDate$1 = match$6[0];
                                                if (fromDate$1.tag === /* Int */2) {
                                                  var match$7 = match$6[1];
                                                  if (match$7) {
                                                    var toDate$1 = match$7[0];
                                                    if (toDate$1.tag === /* Int */2 && !(match$7[1] || match$4[1])) {
                                                      tmp = /* Bin */Block.__(0, [
                                                          Caml_int64.to_int32(bins$1),
                                                          /* tuple */[
                                                            fromDate$1[0],
                                                            toDate$1[0]
                                                          ]
                                                        ]);
                                                    } else {
                                                      exit$1 = 2;
                                                    }
                                                  } else {
                                                    exit$1 = 2;
                                                  }
                                                } else {
                                                  exit$1 = 2;
                                                }
                                              } else {
                                                exit$1 = 2;
                                              }
                                            }
                                          } else {
                                            tmp = /* Bin */Block.__(0, [
                                                Caml_int64.to_int32(bins$1),
                                                undefined
                                              ]);
                                          }
                                          break;
                                      default:
                                        exit$1 = 2;
                                    }
                                  } else {
                                    tmp = /* Bin */Block.__(0, [
                                        undefined,
                                        undefined
                                      ]);
                                  }
                                  break;
                              case "distribution" :
                                  var match$8 = rest[1];
                                  if (match$8) {
                                    var bins$2 = match$8[0];
                                    if (bins$2.tag === /* Int */2 && !match$8[1]) {
                                      tmp = /* Distribution */Block.__(1, [Caml_int64.to_int32(bins$2[0])]);
                                    } else {
                                      exit$1 = 2;
                                    }
                                  } else {
                                    tmp = /* Distribution */Block.__(1, [undefined]);
                                  }
                                  break;
                              default:
                                if (rest[1]) {
                                  exit$1 = 2;
                                } else {
                                  switch (operation$1) {
                                    case "head" :
                                        tmp = /* Head */1;
                                        break;
                                    case "histogram" :
                                        tmp = /* Histogram */0;
                                        break;
                                    case "max" :
                                        tmp = /* Max */6;
                                        break;
                                    case "mean" :
                                        tmp = /* Mean */7;
                                        break;
                                    case "min" :
                                        tmp = /* Min */5;
                                        break;
                                    case "range" :
                                        tmp = /* Range */8;
                                        break;
                                    case "sum" :
                                        tmp = /* Sum */3;
                                        break;
                                    case "tail" :
                                        tmp = /* Tail */2;
                                        break;
                                    case "unique" :
                                        tmp = /* Unique */4;
                                        break;
                                    default:
                                      throw [
                                            Caml_builtin_exceptions.assert_failure,
                                            /* tuple */[
                                              "UrlEncode.re",
                                              620,
                                              27
                                            ]
                                          ];
                                  }
                                }
                            }
                          } else {
                            exit$1 = 2;
                          }
                        } else {
                          exit$1 = 2;
                        }
                        if (exit$1 === 2) {
                          throw [
                                Caml_builtin_exceptions.assert_failure,
                                /* tuple */[
                                  "UrlEncode.re",
                                  622,
                                  25
                                ]
                              ];
                        }
                        aggregation = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                              attr[0],
                              tmp
                            ]);
                      } else {
                        exit = 1;
                      }
                    } else {
                      exit = 1;
                    }
                  }
                  if (exit === 1) {
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "UrlEncode.re",
                            625,
                            21
                          ]
                        ];
                  }
                  return Curry._2(Crdb$BsConsole.Fold.add, aggregation, acc);
                }));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          635,
          17
        ]
      ];
}

var Fold = {
  encode: encode$8,
  decode: decode$8
};

function encode$9(stats) {
  return /* List */Block.__(0, [Belt_List.map(stats, (function (s) {
                    return /* Str */Block.__(1, [s]);
                  }))]);
}

function decode$9(exp) {
  if (!exp.tag) {
    return Belt_List.map(exp[0], (function (e) {
                  if (e.tag === /* Str */1) {
                    return e[0];
                  }
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "UrlEncode.re",
                          648,
                          15
                        ]
                      ];
                }));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "UrlEncode.re",
          651,
          11
        ]
      ];
}

var Stats = {
  encode: encode$9,
  decode: decode$9
};

function getByKey(stuff, key) {
  return Belt_Option.map(Belt_List.getBy(stuff, (function (param) {
                    return param[0] === key;
                  })), (function (param) {
                return param[1];
              }));
}

function decodeOpt(queryStrings) {
  var filters;
  try {
    var fingerprint = getByKey(queryStrings, "fingerprint");
    var error = Belt_Option.map(getByKey(queryStrings, "error"), ObjectID$BsConsole.fromHexString);
    var filtersBase = Belt_Option.map(getByKey(queryStrings, "filters"), (function (filters) {
            try {
              return decode2$1(filters);
            }
            catch (exn){
              return decode$2(fromString$1(filters));
            }
          }));
    var exit = 0;
    if (filtersBase !== undefined || fingerprint !== undefined || error !== undefined) {
      exit = 1;
    } else {
      filters = undefined;
    }
    if (exit === 1) {
      var finalFilters = Crdb$BsConsole.Filters.empty;
      if (filtersBase !== undefined) {
        finalFilters = Caml_option.valFromOption(filtersBase);
      }
      if (fingerprint !== undefined) {
        finalFilters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                  "fingerprint",
                  /* Equal */Block.__(0, [/* `String */[
                        -976970511,
                        fingerprint
                      ]])
                ]), finalFilters);
      }
      if (error !== undefined) {
        finalFilters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                  "_tx",
                  /* Equal */Block.__(0, [/* `ObjectID */[
                        -589436806,
                        error
                      ]])
                ]), finalFilters);
      }
      filters = Caml_option.some(finalFilters);
    }
    
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    Util$BsConsole.reportExn(err);
    filters = undefined;
  }
  var havings;
  try {
    havings = Belt_Option.map(Belt_Option.map(getByKey(queryStrings, "havings"), fromString$1), decode$4);
  }
  catch (raw_err$1){
    var err$1 = Caml_js_exceptions.internalToOCamlException(raw_err$1);
    Util$BsConsole.reportExn(err$1);
    havings = undefined;
  }
  var normBy = getByKey(queryStrings, "normBy");
  var match = getByKey(queryStrings, "highlightExporter");
  var highlightExporter;
  if (match !== undefined) {
    switch (match) {
      case "false" :
          highlightExporter = false;
          break;
      case "true" :
          highlightExporter = true;
          break;
      default:
        highlightExporter = false;
    }
  } else {
    highlightExporter = false;
  }
  var match$1 = getByKey(queryStrings, "similarity");
  var similarity;
  if (match$1 !== undefined) {
    switch (match$1) {
      case "false" :
          similarity = false;
          break;
      case "true" :
          similarity = true;
          break;
      default:
        similarity = false;
    }
  } else {
    similarity = false;
  }
  var tmp;
  try {
    tmp = Belt_Option.map(Belt_Option.map(getByKey(queryStrings, "time"), fromString$1), decode);
  }
  catch (raw_err$2){
    var err$2 = Caml_js_exceptions.internalToOCamlException(raw_err$2);
    Util$BsConsole.reportExn(err$2);
    tmp = undefined;
  }
  var tmp$1;
  try {
    tmp$1 = Belt_Option.map(Belt_Option.map(getByKey(queryStrings, "select"), fromString$1), decode$5);
  }
  catch (raw_err$3){
    var err$3 = Caml_js_exceptions.internalToOCamlException(raw_err$3);
    Util$BsConsole.reportExn(err$3);
    tmp$1 = undefined;
  }
  var tmp$2;
  try {
    tmp$2 = Belt_Option.map(Belt_Option.map(getByKey(queryStrings, "groupBy"), fromString$1), decode$6);
  }
  catch (raw_err$4){
    var err$4 = Caml_js_exceptions.internalToOCamlException(raw_err$4);
    Util$BsConsole.reportExn(err$4);
    tmp$2 = undefined;
  }
  var tmp$3;
  try {
    tmp$3 = Belt_Option.map(Belt_Option.map(getByKey(queryStrings, "sort"), fromString$1), decode$7);
  }
  catch (raw_err$5){
    var err$5 = Caml_js_exceptions.internalToOCamlException(raw_err$5);
    Util$BsConsole.reportExn(err$5);
    tmp$3 = undefined;
  }
  var tmp$4;
  try {
    tmp$4 = Belt_Option.map(getByKey(queryStrings, "aggregations"), (function (str) {
            return decode$8(fromString$1(str));
          }));
  }
  catch (raw_err$6){
    var err$6 = Caml_js_exceptions.internalToOCamlException(raw_err$6);
    Util$BsConsole.reportExn(err$6);
    tmp$4 = undefined;
  }
  var tmp$5;
  try {
    tmp$5 = Belt_Option.map(getByKey(queryStrings, "stats"), (function (str) {
            return decode$9(fromString$1(str));
          }));
  }
  catch (raw_err$7){
    var err$7 = Caml_js_exceptions.internalToOCamlException(raw_err$7);
    Util$BsConsole.reportExn(err$7);
    tmp$5 = undefined;
  }
  return {
          time: tmp,
          filters: filters,
          havings: havings,
          select: tmp$1,
          groupBy: tmp$2,
          sort: tmp$3,
          aggregations: tmp$4,
          stats: tmp$5,
          normBy: normBy,
          highlightExporter: highlightExporter,
          similarity: similarity
        };
}

function decode$10(queryStrings) {
  var optValues = decodeOpt(queryStrings);
  return {
          time: Belt_Option.getWithDefault(optValues.time, /* Relative */Block.__(0, [
                  /* Floating */0,
                  /* Week */2
                ])),
          filters: Belt_Option.getWithDefault(optValues.filters, Crdb$BsConsole.Filters.empty),
          havings: Belt_Option.getWithDefault(optValues.havings, Crdb$BsConsole.Havings.empty),
          select: Belt_Option.getWithDefault(optValues.select, /* [] */0),
          groupBy: Belt_Option.getWithDefault(optValues.groupBy, /* Identity */0),
          sort: Belt_Option.getWithDefault(optValues.sort, /* Count */Block.__(1, [/* Descending */1])),
          aggregations: Belt_Option.getWithDefault(optValues.aggregations, Crdb$BsConsole.Fold.empty),
          stats: Belt_Option.getWithDefault(optValues.stats, /* [] */0),
          normBy: optValues.normBy,
          highlightExporter: optValues.highlightExporter,
          similarity: optValues.similarity
        };
}

function encode$10(t) {
  var filtersArr = Curry._1(Crdb$BsConsole.Filters.toArray, t.filters);
  var fingerprintFilter = Belt_Array.get(Belt_Array.keepMap(filtersArr, (function (filter) {
              var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
              var operation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
              if (attribute !== "fingerprint") {
                return ;
              }
              if (operation.tag) {
                return ;
              }
              var match = operation[0];
              if (typeof match === "number" || match[0] !== -976970511) {
                return ;
              } else {
                return match[1];
              }
            })), 0);
  var errorFilter = Belt_Array.get(Belt_Array.keepMap(filtersArr, (function (filter) {
              var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
              var operation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
              if (attribute !== "_tx") {
                return ;
              }
              if (operation.tag) {
                return ;
              }
              var match = operation[0];
              if (typeof match === "number") {
                return ;
              }
              var variant = match[0];
              if (variant !== -976970511) {
                if (variant !== -589436806) {
                  return ;
                } else {
                  return ObjectID$BsConsole.toHexString(match[1]);
                }
              } else {
                return match[1];
              }
            })), 0);
  var remainingFilters = Belt_Array.keep(filtersArr, (function (filter) {
          var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var operation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
          switch (attribute) {
            case "_tx" :
                if (operation.tag) {
                  return true;
                } else {
                  return false;
                }
            case "fingerprint" :
                if (operation.tag) {
                  return true;
                } else {
                  return false;
                }
            default:
              return true;
          }
        }));
  var filters = Belt_Array.reduce(Belt_Array.reverse(remainingFilters), Crdb$BsConsole.Filters.empty, (function (filters, filter) {
          return Curry._2(Crdb$BsConsole.Filters.add, filter, filters);
        }));
  var havings = t.havings;
  return Belt_List.keepMap(/* :: */[
              Belt_Option.map(Caml_obj.caml_equal(t.time, /* Relative */Block.__(0, [
                          /* Floating */0,
                          /* Week */2
                        ])) ? undefined : t.time, (function (time) {
                      return /* tuple */[
                              "time",
                              toString$1(encode(time))
                            ];
                    })),
              /* :: */[
                Belt_Option.map(Curry._1(Crdb$BsConsole.Filters.toArray, filters).length === 0 ? undefined : Caml_option.some(filters), (function (filters) {
                        return /* tuple */[
                                "filters",
                                encode$2(filters)
                              ];
                      })),
                /* :: */[
                  Belt_Option.map(Curry._1(Crdb$BsConsole.Havings.toArray, havings).length === 0 ? undefined : Caml_option.some(havings), (function (havings) {
                          return /* tuple */[
                                  "havings",
                                  toString$1(encode$4(havings))
                                ];
                        })),
                  /* :: */[
                    Belt_List.length(t.select) === 0 ? undefined : /* tuple */[
                        "select",
                        toString$1(encode$5(t.select))
                      ],
                    /* :: */[
                      t.groupBy === /* Identity */0 ? undefined : /* tuple */[
                          "groupBy",
                          toString$1(encode$6(t.groupBy))
                        ],
                      /* :: */[
                        Caml_obj.caml_equal(t.sort, /* Count */Block.__(1, [/* Descending */1])) ? undefined : /* tuple */[
                            "sort",
                            toString$1(encode$7(t.sort))
                          ],
                        /* :: */[
                          Curry._1(Crdb$BsConsole.Fold.toArray, t.aggregations).length === 0 ? undefined : /* tuple */[
                              "aggregations",
                              toString$1(encode$8(t.aggregations))
                            ],
                          /* :: */[
                            Belt_List.length(t.stats) === 0 ? undefined : /* tuple */[
                                "stats",
                                toString$1(encode$9(t.stats))
                              ],
                            /* :: */[
                              Belt_Option.map(fingerprintFilter, (function (fingerprint) {
                                      return /* tuple */[
                                              "fingerprint",
                                              fingerprint
                                            ];
                                    })),
                              /* :: */[
                                Belt_Option.map(errorFilter, (function (error) {
                                        return /* tuple */[
                                                "error",
                                                error
                                              ];
                                      })),
                                /* :: */[
                                  Belt_Option.map(t.normBy, (function (normBy) {
                                          return /* tuple */[
                                                  "normBy",
                                                  normBy
                                                ];
                                        })),
                                  /* :: */[
                                    Belt_Option.map(t.similarity, (function (similarity) {
                                            return /* tuple */[
                                                    "similarity",
                                                    toString$1(/* Bool */Block.__(4, [similarity]))
                                                  ];
                                          })),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ], (function (a) {
                return Belt_Option.map(a, (function (param) {
                              return /* tuple */[
                                      param[0],
                                      encodeURIComponent(param[1])
                                    ];
                            }));
              }));
}

function toQueryString(fields, t) {
  var query = encode$10(t);
  var tmp;
  if (fields !== undefined) {
    var fullFields = Belt_List.toArray(fields).includes("filters") ? /* :: */[
        "fingerprint",
        /* :: */[
          "error",
          fields
        ]
      ] : fields;
    var fields$1 = Belt_List.toArray(fullFields);
    tmp = Belt_List.keep(query, (function (param) {
            return fields$1.includes(param[0]);
          }));
  } else {
    tmp = query;
  }
  return Task2$BsConsole.generateQueryString(tmp);
}

var Query = {
  getByKey: getByKey,
  decodeOpt: decodeOpt,
  decode: decode$10,
  encode: encode$10,
  toQueryString: toQueryString
};

exports.$$Date = $$Date$1;
exports.Granularity = Granularity;
exports.Exp = Exp;
exports.Time = Time;
exports.Filter = Filter;
exports.Filters = Filters;
exports.Having = Having;
exports.Havings = Havings;
exports.Select = Select;
exports.Factor = Factor;
exports.Sort = Sort;
exports.Fold = Fold;
exports.Stats = Stats;
exports.Query = Query;
/* DateFns Not a pure module */
