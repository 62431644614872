// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("../../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

function fromJson(json) {
  var partial_arg_000 = function (param) {
    return Json_decode.map(Caml_format.caml_int64_of_string, Json_decode.string, param);
  };
  var partial_arg_001 = /* :: */[
    (function (param) {
        return Json_decode.map(Caml_int64.of_float, Json_decode.$$float, param);
      }),
    /* :: */[
      (function (param) {
          return Json_decode.map(Caml_int64.of_int32, Json_decode.$$int, param);
        }),
      /* [] */0
    ]
  ];
  var partial_arg = /* :: */[
    partial_arg_000,
    partial_arg_001
  ];
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json),
          timestamp: Json_decode.field("timestamp", Json_decode.$$float, json),
          level: Json_decode.field("level", Json_decode.string, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          message: Json_decode.field("message", Json_decode.string, json),
          attributes: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("attributes", (function (param) {
                                    return Json_decode.dict(Util$BsConsole.identity, param);
                                  }), param);
                    }), json), { })
        };
}

function fromLogFile(str) {
  return Belt_Array.keepMap(str.split("\n"), (function (line) {
                var lineNoComma = line.endsWith(",") ? line.slice(0, line.length - 1 | 0) : line;
                try {
                  return fromJson(Json.parseOrRaise(lineNoComma));
                }
                catch (exn){
                  return ;
                }
              }));
}

var ParseError = Caml_exceptions.create("BugBreadcrumbsApi-BsConsole.ParseError");

function getBreadcrumb(token, path) {
  return new Promise((function (resolve, reject) {
                Task2$BsConsole.raw(token, /* Custom */Block.__(1, [path]), undefined, /* Get */0, undefined, (function (text) {
                        try {
                          return resolve(Json_decode.array(fromJson, Json.parseOrRaise(text)));
                        }
                        catch (raw_exn){
                          var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                          try {
                            return resolve(fromLogFile(text));
                          }
                          catch (exn$1){
                            return reject(exn);
                          }
                        }
                      }));
                
              }));
}

exports.fromJson = fromJson;
exports.fromLogFile = fromLogFile;
exports.ParseError = ParseError;
exports.getBreadcrumb = getBreadcrumb;
/* Util-BsConsole Not a pure module */
