// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var MetricsMemory$BsConsole = require("./MetricsMemory.js");

function rememberStuff(config, projectName, param) {
  if (projectName === undefined) {
    return MetricsMemory$BsConsole.forget("project-origin");
  }
  var projectOrigin;
  try {
    projectOrigin = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(Belt_List.getBy(config.projects, (function (project) {
                            return project.name === projectName;
                          })), (function (projectDetails) {
                        return projectDetails.owner;
                      })), (function (id) {
                    return Belt_Option.flatMap(config.users, (function (users) {
                                  return Belt_List.getBy(users, (function (user) {
                                                return user.uid === id;
                                              }));
                                }));
                  })), (function (user) {
                var match = user.email;
                switch (match) {
                  case "service-account@saucelabs.com" :
                      return "provisioning-sauce";
                  case "service@unity.com" :
                      return "provisioning-unity";
                  default:
                    return ;
                }
              })), "user");
  }
  catch (exn){
    projectOrigin = "unknown";
  }
  return MetricsMemory$BsConsole.memorize(/* tuple */[
              "project-origin",
              projectOrigin
            ]);
}

exports.rememberStuff = rememberStuff;
/* MetricsMemory-BsConsole Not a pure module */
