// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfMain$BsConsole = require("./WfMain.js");
var Fragment$BsConsole = require("../Fragment.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");

var component = ReasonReact.reducerComponent("WfForm-BsConsole");

function make(btDefaults, token, form, remoteAction, onValueChange, groupOptionalFieldsOpt, initialFormValue, prependContent, param) {
  var groupOptionalFields = groupOptionalFieldsOpt !== undefined ? groupOptionalFieldsOpt : false;
  var setRemoteAction = function (remoteAction, param) {
    param.state.remoteAction.contents = remoteAction;
    
  };
  var notifyValueChange = function (state) {
    var match = state.formValue.contents;
    var match$1 = state.optionalFormValue.contents;
    if (match !== undefined) {
      if (match.tag) {
        return Curry._1(onValueChange, /* Error */Block.__(1, [match[0]]));
      }
      var formValue = match[0];
      if (match$1 === undefined) {
        return Curry._1(onValueChange, /* Ok */Block.__(0, [formValue]));
      }
      if (!match$1.tag) {
        return Curry._1(onValueChange, /* Ok */Block.__(0, [Object.assign(Object.assign({ }, formValue), match$1[0])]));
      }
      
    }
    if (match$1 !== undefined && match$1.tag) {
      return Curry._1(onValueChange, /* Error */Block.__(1, [match$1[0]]));
    }
    
  };
  var setFormValue = function (formValue, param) {
    var state = param.state;
    state.formValue.contents = formValue;
    return notifyValueChange(state);
  };
  var setOptionalFormValue = function (formValue, param) {
    var state = param.state;
    state.optionalFormValue.contents = formValue;
    return notifyValueChange(state);
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var state = param.state;
              Curry._1(remoteAction, (function (path) {
                      if (!path) {
                        return Curry._1(send, /* ShowAllErrors */0);
                      }
                      var action = state.remoteAction.contents;
                      if (action !== undefined) {
                        Curry._1(send, /* ShowAllErrors */0);
                        return Curry._1(action, /* ScrollTo */Block.__(1, [path[0]]));
                      }
                      
                    }));
              setTimeout((function (param) {
                      var match = state.remoteAction.contents;
                      var match$1 = form.autoFocus;
                      if (match !== undefined && match$1 !== undefined) {
                        return Curry._1(match, /* Focus */Block.__(0, [match$1]));
                      }
                      
                    }), 100);
              
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var showOptionalFields = state.showOptionalFields;
              var showAllErrors = state.showAllErrors;
              var send = param.send;
              var handle = param.handle;
              var schema = form.schema;
              var tmp;
              if (groupOptionalFields) {
                var requiredFields = Belt_Array.keep(schema.fields, (function (field) {
                        return WfCore$BsConsole.hasRequiredFields(field.outputType.schema);
                      }));
                var optionalFields = Belt_Array.keep(schema.fields, (function (field) {
                        return !WfCore$BsConsole.hasRequiredFields(field.outputType.schema);
                      }));
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, WfMain$BsConsole.RootObject.make(btDefaults, token, true, undefined, initialFormValue, Curry._1(handle, setRemoteAction), Curry._1(handle, setFormValue), showAllErrors, WfCore$BsConsole.objectSchema(requiredFields, undefined), [])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.ButtonBase.Jsx2.make, undefined, {
                              width: "100%"
                            }, (function (param) {
                                return Curry._1(send, /* ToggleShowOptionalFields */1);
                              }), undefined, undefined, undefined, [React.createElement("div", {
                                    style: {
                                      display: "flex",
                                      height: "48px",
                                      padding: "0 17px",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }
                                  }, React.createElement("div", {
                                        style: {
                                          color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkBlue3),
                                          fontSize: "20px"
                                        }
                                      }, I18N$BsConsole.show(undefined, "Optional fields")), React.createElement("div", {
                                        style: {
                                          color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkBlue3),
                                          paddingTop: "4px",
                                          transition: "transform 0.3s ease-in-out",
                                          transform: "rotate(" + ((
                                              showOptionalFields ? "180deg" : " 0deg"
                                            ) + ")")
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardArrowDown.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))))])), ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Collapse.Jsx2.make, undefined, undefined, showOptionalFields, undefined, [ReasonReact.element(undefined, undefined, WfMain$BsConsole.RootObject.make(btDefaults, token, true, undefined, initialFormValue, Curry._1(handle, setRemoteAction), Curry._1(handle, setOptionalFormValue), showAllErrors, WfCore$BsConsole.objectSchema(optionalFields, undefined), []))])));
              } else {
                tmp = ReasonReact.element(undefined, undefined, WfMain$BsConsole.RootObject.make(btDefaults, token, true, undefined, initialFormValue, Curry._1(handle, setRemoteAction), Curry._1(handle, setFormValue), showAllErrors, schema, []));
              }
              return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                              React.createElement("div", {
                                    style: {
                                      marginBottom: "22px"
                                    }
                                  }, WfCore$BsConsole.hasRequiredFields(/* Object */Block.__(6, [schema])) ? React.createElement("div", {
                                          style: {
                                            marginTop: "22px",
                                            padding: "0 17px"
                                          }
                                        }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, {
                                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                                                }, [I18N$BsConsole.show(undefined, "* Required")]))) : null),
                              Belt_Option.mapWithDefault(prependContent, null, (function (cb) {
                                      return Curry._1(cb, showAllErrors);
                                    })),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      showAllErrors: false,
                      remoteAction: {
                        contents: undefined
                      },
                      formValue: {
                        contents: undefined
                      },
                      optionalFormValue: {
                        contents: undefined
                      },
                      showOptionalFields: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            showAllErrors: state.showAllErrors,
                            remoteAction: state.remoteAction,
                            formValue: state.formValue,
                            optionalFormValue: state.optionalFormValue,
                            showOptionalFields: !state.showOptionalFields
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            showAllErrors: true,
                            remoteAction: state.remoteAction,
                            formValue: state.formValue,
                            optionalFormValue: state.optionalFormValue,
                            showOptionalFields: state.showOptionalFields
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
