// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var SSA$BsConsole = require("./SSA.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SSAForms$BsConsole = require("./forms/SSAForms.js");
var SSAGroup$BsConsole = require("./SSAGroup.js");
var SSAStats$BsConsole = require("./SSAStats.js");
var SSATable$BsConsole = require("./SSATable.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SSAAction$BsConsole = require("./SSAAction.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var SSAMetrics$BsConsole = require("./SSAMetrics.js");
var SSASection$BsConsole = require("./SSASection.js");
var TeamsUtils$BsConsole = require("../../teams/TeamsUtils.js");
var AlertsUtils$BsConsole = require("../../alerts/AlertsUtils.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var TokenContext$BsConsole = require("../../context/TokenContext.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var SSACreateModal$BsConsole = require("./SSACreateModal.js");
var SSARenderElements$BsConsole = require("./SSARenderElements.js");
var ProjectNameContext$BsConsole = require("../../context/ProjectNameContext.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var ellipsisStyle = Css.style(/* :: */[
      Css.width(Css.px(305)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* [] */0
          ]
        ]
      ]
    ]);

var statsStyle = Css.style(/* :: */[
      Css.marginBottom(Css.px(24)),
      /* :: */[
        Css.fontStyle(/* italic */107228912),
        /* [] */0
      ]
    ]);

var initialActionsGroups_ignoreGroup = [];

var initialActionsGroups_setValueGroup = [];

var initialActionsGroups_indexOnlyGroup = [];

var initialActionsGroups_unionGroup = [];

var initialActionsGroups = {
  ignoreGroup: initialActionsGroups_ignoreGroup,
  setValueGroup: initialActionsGroups_setValueGroup,
  indexOnlyGroup: initialActionsGroups_indexOnlyGroup,
  unionGroup: initialActionsGroups_unionGroup
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetActionsAndStats */0 :
        return {
                actions: action[0],
                overallStats: action[1],
                lastModified: action[2],
                createNewTitle: state.createNewTitle,
                createNewForm: state.createNewForm,
                refetch: state.refetch,
                users: state.users,
                attributes: state.attributes,
                labelAttributes: state.labelAttributes
              };
    case /* SetCreateNew */1 :
        return {
                actions: state.actions,
                overallStats: state.overallStats,
                lastModified: state.lastModified,
                createNewTitle: action[0],
                createNewForm: action[1],
                refetch: state.refetch,
                users: state.users,
                attributes: state.attributes,
                labelAttributes: state.labelAttributes
              };
    case /* SetRefetch */2 :
        return {
                actions: state.actions,
                overallStats: state.overallStats,
                lastModified: state.lastModified,
                createNewTitle: state.createNewTitle,
                createNewForm: state.createNewForm,
                refetch: action[0],
                users: state.users,
                attributes: state.attributes,
                labelAttributes: state.labelAttributes
              };
    case /* SetAttributes */3 :
        return {
                actions: state.actions,
                overallStats: state.overallStats,
                lastModified: state.lastModified,
                createNewTitle: state.createNewTitle,
                createNewForm: state.createNewForm,
                refetch: state.refetch,
                users: state.users,
                attributes: action[0],
                labelAttributes: action[1]
              };
    case /* SetUsers */4 :
        return {
                actions: state.actions,
                overallStats: state.overallStats,
                lastModified: state.lastModified,
                createNewTitle: state.createNewTitle,
                createNewForm: state.createNewForm,
                refetch: state.refetch,
                users: action[0],
                attributes: state.attributes,
                labelAttributes: state.labelAttributes
              };
    
  }
}

var initialState_users = [];

var initialState_attributes = [];

var initialState_labelAttributes = [];

var initialState = {
  actions: initialActionsGroups,
  overallStats: undefined,
  lastModified: undefined,
  createNewTitle: undefined,
  createNewForm: undefined,
  refetch: 1,
  users: initialState_users,
  attributes: initialState_attributes,
  labelAttributes: initialState_labelAttributes
};

function PS_ServerSideActions$Main(Props) {
  var config = Props.config;
  var token = Props.token;
  var project = Props.project;
  Props.handleChangeUrl;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var refetch = match$1.refetch;
  var createNewForm = match$1.createNewForm;
  var lastModified = match$1.lastModified;
  var overallStats = match$1.overallStats;
  var actions = match$1.actions;
  var refetchActions = function (param) {
    return Curry._1(dispatch, /* SetRefetch */Block.__(2, [refetch + 1 | 0]));
  };
  var unionGroup = actions.unionGroup;
  var indexOnlyGroup = actions.indexOnlyGroup;
  var setValueGroup = actions.setValueGroup;
  var ignoreGroup = actions.ignoreGroup;
  var isAdmin = TeamsUtils$BsConsole.isAdmin(config, project.name);
  var deleteActionByIndex = function (index, action, param) {
    if (!isAdmin) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.adminOnlyText));
    }
    SSAMetrics$BsConsole.send(/* ActionDelete */2);
    var allActions = SSAGroup$BsConsole.removeAction(actions, index, action);
    if (allActions.length === 0) {
      var arg = BpgTask$BsConsole.ServerSideActions.deleteByKey({
            project: project.pid
          });
      return Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), true, (function (_results) {
                    refetchActions(undefined);
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.allRemovedText));
                  }));
    }
    var arg$1 = BpgTask$BsConsole.ServerSideActions.updateFieldsByKey({
          project: project.pid
        }, [/* tuple */[
            "configuration",
            SSAAction$BsConsole.toJsonString(allActions)
          ]]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                      });
                  })(token), true, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                  }
                  refetchActions(undefined);
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.deletedText));
                }));
  };
  var match$2 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.Users.fetchAll);
  var usersRemote = match$2[0];
  React.useEffect((function () {
          if (typeof usersRemote === "number") {
            usersRemote === /* NotAsked */0;
          } else if (usersRemote.tag) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(usersRemote[0]));
          } else {
            Curry._1(dispatch, /* SetUsers */Block.__(4, [usersRemote[0]]));
          }
          
        }), /* tuple */[
        token,
        project.pid,
        usersRemote
      ]);
  React.useEffect((function () {
          var arg = AlertsUtils$BsConsole.getAllAttributes(project.name, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), false, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                  }
                  var attrs = results[0];
                  var attributes = Belt_Array.map(Belt_List.toArray(attrs), (function (a) {
                          return a.name;
                        }));
                  var labelAttributes = Belt_Array.keepMap(Belt_List.toArray(attrs), (function (a) {
                          if (a.format === "labels") {
                            return a.name;
                          }
                          
                        }));
                  return Curry._1(dispatch, /* SetAttributes */Block.__(3, [
                                attributes,
                                labelAttributes
                              ]));
                }));
          
        }), ([]));
  var fetchActions = function (param) {
    var arg = BpgTask$BsConsole.ServerSideActions.fetchAll;
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), true, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                  }
                  var res = results[0];
                  var allActions = Belt_Array.reduce(res, [], (function (acc, curr) {
                          if (curr.project === project.pid) {
                            return Belt_Array.concat(acc, SSA$BsConsole.configurationFromJsonString(curr));
                          } else {
                            return acc;
                          }
                        }));
                  var match = SSAStats$BsConsole.getProjectStats(res, project);
                  var actionStats = match[1];
                  var allActionsWithStats = Belt_Array.mapWithIndex(allActions, (function (index, action) {
                          var statMaybe = Belt_Array.get(actionStats, index);
                          return SSAAction$BsConsole.addStat(statMaybe, action);
                        }));
                  var groupedActions = SSAGroup$BsConsole.groupActions(allActionsWithStats);
                  var lastModified = SSAUtils$BsConsole.getLastModified(res, project);
                  return Curry._1(dispatch, /* SetActionsAndStats */Block.__(0, [
                                groupedActions,
                                match[0],
                                lastModified
                              ]));
                }));
  };
  React.useEffect((function () {
          fetchActions(undefined);
          
        }), /* tuple */[
        token,
        project.name,
        refetch
      ]);
  var close = function (param) {
    return Curry._1(dispatch, /* SetCreateNew */Block.__(1, [
                  undefined,
                  undefined
                ]));
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.lineHeight(Css.rem(1.2)),
                    /* :: */[
                      Css.wordWrap(/* breakWord */1059921449),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(SSACreateModal$BsConsole.make, {
                  token: token,
                  project: project,
                  close: close,
                  formOpen: Belt_Option.isSome(createNewForm),
                  formTitle: match$1.createNewTitle,
                  formType: createNewForm !== undefined ? SSAForms$BsConsole.toModule(createNewForm) : SSAForms$BsConsole.indexOnlyModule,
                  actions: actions,
                  refetchActions: refetchActions,
                  allAttributes: match$1.attributes,
                  allLabelAttributes: match$1.labelAttributes,
                  allUsers: Belt_Array.map(match$1.users, (function (user) {
                          return user.username;
                        }))
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.lineHeight(Css.rem(1.2)),
                        /* :: */[
                          Css.wordWrap(/* breakWord */1059921449),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: featureDescription,
                      children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.settingsPageDescriptionText)
                    })), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  className: statsStyle,
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                              /* [] */0
                            ]),
                        children: overallStats !== undefined ? (
                            lastModified !== undefined ? React.createElement(Row2$BsConsole.make, {
                                    children: null
                                  }, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.marginRight(Css.px(4)),
                                              /* [] */0
                                            ])
                                      }, SSAUtils$BsConsole.lastModifiedString(lastModified), I18N$BsConsole.showSkip(",")), SSAUtils$BsConsole.overallStatsView(overallStats)) : SSAUtils$BsConsole.overallStatsView(overallStats)
                          ) : (
                            lastModified !== undefined ? SSAUtils$BsConsole.lastModifiedString(lastModified) : null
                          )
                      })
                }), Belt_Array.map(SSASection$BsConsole.options, (function (section) {
                    var tableComponent;
                    switch (section) {
                      case /* Ignore */0 :
                          tableComponent = React.createElement(SSATable$BsConsole.make, {
                                actions: ignoreGroup,
                                handleDelete: deleteActionByIndex
                              });
                          break;
                      case /* IndexOnly */1 :
                          tableComponent = React.createElement(SSATable$BsConsole.make, {
                                actions: indexOnlyGroup,
                                handleDelete: deleteActionByIndex
                              });
                          break;
                      case /* SetValue */2 :
                          tableComponent = React.createElement(SSATable$BsConsole.Ordered.make, {
                                actions: setValueGroup,
                                handleDelete: deleteActionByIndex,
                                handleUpdate: (function (updatedActions) {
                                    if (isAdmin) {
                                      SSAMetrics$BsConsole.send(/* ActionUpdate */1);
                                      var allActions = Belt_Array.concatMany([
                                            ignoreGroup,
                                            updatedActions,
                                            indexOnlyGroup,
                                            unionGroup
                                          ]);
                                      var arg = BpgTask$BsConsole.ServerSideActions.updateFieldsByKey({
                                            project: project.pid
                                          }, [/* tuple */[
                                              "configuration",
                                              SSAAction$BsConsole.toJsonString(allActions)
                                            ]]);
                                      return Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), true, (function (results) {
                                                    if (results.tag) {
                                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                                                    }
                                                    refetchActions(undefined);
                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.orderUpdatedText));
                                                  }));
                                    } else {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.adminOnlyText));
                                    }
                                  })
                              });
                          break;
                      case /* Union */3 :
                          tableComponent = React.createElement(SSATable$BsConsole.FourColumn.make, {
                                actions: unionGroup,
                                handleDelete: deleteActionByIndex
                              });
                          break;
                      
                    }
                    var m = SSASection$BsConsole.toModule(section);
                    return React.createElement(React.Fragment, {
                                children: React.createElement(SSARenderElements$BsConsole.Section.make, {
                                      titleText: m.titleText,
                                      descriptionText: m.descriptionText,
                                      tableComponent: tableComponent,
                                      createButtonText: SSAUtils$BsConsole.createText,
                                      createAction: (function (param) {
                                          if (isAdmin) {
                                            return Curry._1(dispatch, /* SetCreateNew */Block.__(1, [
                                                          m.createNewTitleText,
                                                          m.createNewFormType
                                                        ]));
                                          } else {
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.adminOnlyText));
                                          }
                                        })
                                    }),
                                key: m.titleText
                              });
                  })));
}

var Main = {
  make: PS_ServerSideActions$Main
};

function PS_ServerSideActions(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.submissionText)
                }), project !== undefined ? React.createElement(PS_ServerSideActions$Main, {
                    config: config,
                    token: token,
                    project: project,
                    handleChangeUrl: handleChangeUrl
                  }) : React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.projectNotFoundText)
                  }));
}

function make(config, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_ServerSideActions, {
              config: config,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var modalWidth = 300;

var make$1 = PS_ServerSideActions;

exports.pageMarginBottom = pageMarginBottom;
exports.featureDescription = featureDescription;
exports.ellipsisStyle = ellipsisStyle;
exports.statsStyle = statsStyle;
exports.modalWidth = modalWidth;
exports.initialActionsGroups = initialActionsGroups;
exports.reducer = reducer;
exports.initialState = initialState;
exports.Main = Main;
exports.make = make$1;
exports.Re = Re;
/* pageMarginBottom Not a pure module */
