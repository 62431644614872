// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var AggregationRenderStyles$BsConsole = require("../AggregationRenderStyles.js");
var Renderer_CallstackFrame$BsConsole = require("./Renderer_CallstackFrame.js");

var root = Css.style(/* :: */[
      Css.padding2(Css.px(4), Css.px(16)),
      /* :: */[
        Css.important(Css.fontFamily("Inconsolata")),
        /* :: */[
          Css.unsafe("lineHeight", "1 !important"),
          /* [] */0
        ]
      ]
    ]);

var dialogFrameText = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.important(Css.fontFamily("Inconsolata")),
        /* [] */0
      ]
    ]);

var frameText = Css.style(/* :: */[
      Css.textOverflow(/* ellipsis */166888785),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.important(Css.fontFamily("Inconsolata")),
            /* [] */0
          ]
        ]
      ]
    ]);

var callstackMore = Css.style(/* :: */[
      Css.lineHeight(Css.px(8)),
      /* :: */[
        Css.paddingLeft(Css.px(8)),
        /* [] */0
      ]
    ]);

var Style = {
  root: root,
  dialogFrameText: dialogFrameText,
  frameText: frameText,
  callstackMore: callstackMore
};

var component = ReasonReact.reducerComponent("Callstack");

function noopHandlerPreparingFrames(a) {
  return a;
}

function make(attribute, maxNumLinesOpt, onOpenDialog, frames, handlePreparingFramesOpt, handleAddFilters, handleChangeUrl, className, isDialogOpt, _children) {
  var maxNumLines = maxNumLinesOpt !== undefined ? Caml_option.valFromOption(maxNumLinesOpt) : undefined;
  var handlePreparingFrames = handlePreparingFramesOpt !== undefined ? handlePreparingFramesOpt : noopHandlerPreparingFrames;
  var isDialog = isDialogOpt !== undefined ? isDialogOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var preparedFrames = Curry._1(handlePreparingFrames, frames);
              var trimmedFrames = preparedFrames.length > 1 ? $$Array.sub(preparedFrames, 0, maxNumLines !== undefined ? (
                        maxNumLines <= preparedFrames.length ? maxNumLines : preparedFrames.length
                      ) : preparedFrames.length) : preparedFrames;
              if (trimmedFrames.length === 0) {
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.paddingLeft(Css.px(22)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey5)),
                                    /* [] */0
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "No faulting callstack"));
              } else {
                return React.createElement("span", {
                            onClick: onOpenDialog
                          }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(root + (" " + (className + ("  " + (
                                          self.state === undefined && !isDialog ? AggregationRenderStyles$BsConsole.Callstack.body : ""
                                        )))), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, /* Column */-81804554, undefined, undefined, [
                                    $$Array.mapi((function (i, frame) {
                                            return ReasonReact.element(String(i), undefined, Renderer_CallstackFrame$BsConsole.make(attribute, handleAddFilters, handleChangeUrl, frame, Belt_Option.getWithDefault(Belt_Array.get(frames, i), frame), []));
                                          }), trimmedFrames),
                                    maxNumLines !== undefined && frames.length > maxNumLines ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, (
                                                isDialog ? dialogFrameText : frameText
                                              ) + (" " + callstackMore), undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip("...")])) : null
                                  ])));
              }
            }),
          initialState: (function (param) {
              
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [undefined]);
              } else {
                return /* Update */Block.__(0, [/* FrameHovered */0]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Style = Style;
exports.component = component;
exports.noopHandlerPreparingFrames = noopHandlerPreparingFrames;
exports.make = make;
/* root Not a pure module */
