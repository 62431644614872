// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var AggregationType$BsConsole = require("./bs-crdb-response/src/AggregationType.js");

function makeQuery(attributes, query, normBy) {
  if (normBy === undefined) {
    return query;
  }
  var match = Belt_List.getBy(attributes, (function (attr) {
          if (attr.name === "application.session") {
            return attr.statistics.used;
          } else {
            return false;
          }
        }));
  if (match === undefined) {
    return query;
  }
  var __x = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty);
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "application.session",
            /* Unique */4
          ]), __x);
  return /* Aggregate */Block.__(0, [
            Curry._1(Crdb$BsConsole.Query.getAperture, query),
            Curry._1(Crdb$BsConsole.Query.getFactor, query),
            fold,
            Curry._1(Crdb$BsConsole.Query.getSort, query),
            undefined,
            Curry._1(Crdb$BsConsole.Query.getHavings, query)
          ]);
}

function applicationSessionInFold(fold) {
  if (fold !== undefined) {
    return Belt_Option.isSome(Curry._3(Crdb$BsConsole.Fold.getAttributeAggregateIndex, "application.session", "unique", Caml_option.valFromOption(fold)));
  } else {
    return false;
  }
}

function sanitizeAggColumns(aggColumns, fold) {
  var isApplicationSessionInFold = applicationSessionInFold(fold);
  if (isApplicationSessionInFold) {
    return aggColumns;
  } else {
    return Belt_Array.keep(aggColumns, (function (col) {
                  var match = col.name;
                  var match$1 = col.op;
                  if (match === "application.session" && match$1 !== undefined) {
                    return AggregationType$BsConsole.toString(match$1) !== "unique";
                  } else {
                    return true;
                  }
                }));
  }
}

function getInitialState(attributes, query, normBy) {
  return {
          retainedQuery: query,
          queryForRequest: makeQuery(attributes, query, normBy)
        };
}

function reducer(_state, action) {
  var query = action[0];
  return {
          retainedQuery: query,
          queryForRequest: makeQuery(action[1], query, action[2])
        };
}

function useQueryForAggTable(attributes, query, normBy) {
  var match = React.useReducer(reducer, undefined, (function (param) {
          return getInitialState(attributes, query, normBy);
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var retainedQuery = match$1.retainedQuery;
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Query.eq, query, retainedQuery)) {
            Curry._1(dispatch, /* UpdateQuery */[
                  query,
                  attributes,
                  normBy
                ]);
          }
          
        }), [query]);
  return match$1.queryForRequest;
}

var Normalize = {
  makeQuery: makeQuery,
  applicationSessionInFold: applicationSessionInFold,
  sanitizeAggColumns: sanitizeAggColumns,
  getInitialState: getInitialState,
  reducer: reducer,
  useQueryForAggTable: useQueryForAggTable
};

exports.Normalize = Normalize;
/* react Not a pure module */
