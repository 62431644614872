// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var I18N$BsConsole = require("./I18N.js");

function Rawable(Props) {
  var rawOpt = Props.raw;
  var data = Props.data;
  var children = Props.children;
  var raw = rawOpt !== undefined ? rawOpt : false;
  if (raw) {
    return React.createElement("pre", undefined, I18N$BsConsole.showSkip(JSON.stringify(data, null, 2)));
  } else {
    return children;
  }
}

var make = Rawable;

exports.make = make;
/* react Not a pure module */
