// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function getSelectedFileName(sourceCodeInfo, selectedFrameLibrary) {
  if (sourceCodeInfo !== undefined) {
    if (selectedFrameLibrary !== undefined) {
      var frame = Caml_option.valFromOption(selectedFrameLibrary);
      var match = sourceCodeInfo.path;
      if (match !== undefined) {
        return match;
      } else if (!(frame == null)) {
        return frame;
      } else {
        return ;
      }
    }
    var srcFunction = sourceCodeInfo.path;
    if (srcFunction !== undefined) {
      return srcFunction;
    } else {
      return ;
    }
  }
  if (selectedFrameLibrary === undefined) {
    return ;
  }
  var frame$1 = Caml_option.valFromOption(selectedFrameLibrary);
  if (!(frame$1 == null)) {
    return frame$1;
  }
  
}

function getDefectsByFrame(selectedFrame, defects, sourceCodeInfo) {
  var selectedFrameFuncName = selectedFrame.funcName;
  var selectedFrameLibrary = selectedFrame.library;
  var selectedFileName = getSelectedFileName(sourceCodeInfo, selectedFrameLibrary);
  return List.filter((function (defect) {
                  var match = defect.functionName;
                  var match$1 = defect.fileName;
                  if (match !== undefined) {
                    if (match$1 !== undefined) {
                      if (!(selectedFrameFuncName == null) && selectedFileName !== undefined) {
                        if (match === selectedFrameFuncName) {
                          return true;
                        } else {
                          return match$1 === selectedFileName;
                        }
                      } else {
                        return false;
                      }
                    } else if (selectedFrameFuncName == null) {
                      return false;
                    } else {
                      return match === selectedFrameFuncName;
                    }
                  } else if (match$1 !== undefined && selectedFileName !== undefined) {
                    return match$1 === selectedFileName;
                  } else {
                    return false;
                  }
                }))(defects);
}

exports.getSelectedFileName = getSelectedFileName;
exports.getDefectsByFrame = getDefectsByFrame;
/* No side effect */
