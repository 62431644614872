// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var BugTheme$BsConsole = require("./BugTheme.js");
var Services$BsConsole = require("../Services.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Styles = require("@material-ui/core/styles");
var SymbolServer_API$BsConsole = require("../project-settings/symbols/SymbolServers/SymbolServer_API.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function reloadRoute(projectName, aperture, error) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var __x$1 = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          var attr = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          return !(attr === "fingerprint" || attr === "callstack");
        }), __x);
  return /* ProjectDebugger2 */Block.__(19, [
            projectName,
            {
              aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                            "_tx",
                            /* Equal */Block.__(0, [/* `String */[
                                  -976970511,
                                  Util$BsConsole.hexToDec(error.errorId)
                                ]])
                          ]), __x$1), aperture),
              state: {
                errorId: error.errorId,
                threadIndex: 0,
                frameIndex: 0,
                variableIndex: undefined
              }
            }
          ]);
}

function showSuccess(res, projectName, aperture, error, handleChangeUrl) {
  var imported = res.symbolsConverted;
  var notImported = res.symbolsRequested - res.symbolsConverted | 0;
  var match = imported > 0;
  var match$1 = notImported > 0;
  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, Caml_option.some(React.createElement("div", {
                      className: Css.merge(/* :: */[
                            SnackPurveyor$BsConsole.Style.container,
                            /* :: */[
                              Css.style(/* :: */[
                                    Css.minWidth(Css.px(375)),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ])
                    }, match ? (
                        match$1 ? Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Successfully imported ",
                                        /* Int */Block.__(4, [
                                            /* Int_d */0,
                                            /* No_padding */0,
                                            /* No_precision */0,
                                            /* String_literal */Block.__(11, [
                                                " symbols. ",
                                                /* Int */Block.__(4, [
                                                    /* Int_d */0,
                                                    /* No_padding */0,
                                                    /* No_precision */0,
                                                    /* String_literal */Block.__(11, [
                                                        " symbols unable to be imported.",
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ]),
                                    "Successfully imported %d symbols. %d symbols unable to be imported."
                                  ]), imported, notImported) : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Successfully imported ",
                                        /* Int */Block.__(4, [
                                            /* Int_d */0,
                                            /* No_padding */0,
                                            /* No_precision */0,
                                            /* String_literal */Block.__(11, [
                                                " symbols.",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "Successfully imported %d symbols."
                                  ]), imported)
                      ) : Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                /* Int */Block.__(4, [
                                    /* Int_d */0,
                                    /* No_padding */0,
                                    /* No_precision */0,
                                    /* String_literal */Block.__(11, [
                                        " symbols were able to be imported out of total ",
                                        /* Int */Block.__(4, [
                                            /* Int_d */0,
                                            /* No_padding */0,
                                            /* No_precision */0,
                                            /* String_literal */Block.__(11, [
                                                " missing symbols requested.",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ]),
                                "%d symbols were able to be imported out of total %d missing symbols requested."
                              ]), imported, res.symbolsRequested), React.createElement(Styles.MuiThemeProvider, {
                          theme: BugTheme$BsConsole.blue,
                          children: React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.unsafe("boxShadow", "none"),
                                        /* :: */[
                                          Css.marginTop(Css.rem(1)),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                onClick: (function (_event) {
                                    SnackPurveyor$BsConsole.close(undefined);
                                    return Curry._1(handleChangeUrl, reloadRoute(projectName, aperture, error));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Refresh")
                              })
                        }))), I18N$BsConsole.show(undefined, "Success"));
}

function reprocessMissingSyms(endpt, token, universeName, projectName, aperture, error, setShowProgress, handleChangeUrl) {
  Curry._1(setShowProgress, (function (param) {
          return true;
        }));
  var arg = SymbolServer_API$BsConsole.reprocessMissingSyms(endpt, universeName, projectName, error.errorId, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (res) {
                Curry._1(setShowProgress, (function (param) {
                        return false;
                      }));
                if (!res.tag) {
                  return showSuccess(res[0], projectName, aperture, error, handleChangeUrl);
                }
                var e = res[0];
                if (e[0] === Task2$BsConsole.UncaughtPromiseError) {
                  var resp = e[1][1];
                  if (resp !== undefined) {
                    if (Caml_option.valFromOption(resp).status === 502) {
                      Curry._1(setShowProgress, (function (param) {
                              return false;
                            }));
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Progress status timed out. Missing symbols will continue to download in the background, please refresh this error at a later time to check on progress."));
                    } else {
                      return ;
                    }
                  }
                  
                }
                Curry._1(setShowProgress, (function (param) {
                        return false;
                      }));
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugReprocessMissingSymbols/reprocessMissingSyms", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `exn */[
                      5049499,
                      e
                    ]);
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Encountered an unknown error while completing the request to download missing symbols."));
              }));
}

function BugReprocessMissingSymbols$Body(Props) {
  var projectName = Props.projectName;
  var config = Props.config;
  var token = Props.token;
  var aperture = Props.aperture;
  var error = Props.error;
  var handleChangeUrl = Props.handleChangeUrl;
  var universeName = config.universe.name;
  var match = React.useState((function () {
          return false;
        }));
  var setShowProgress = match[1];
  if (match[0]) {
    return React.createElement("div", {
                className: SnackPurveyor$BsConsole.Style.container
              }, I18N$BsConsole.show(undefined, "Fetching symbols and reprocessing the submitted error object. This operation may take some time."), React.createElement(LinearProgress.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.margin2(Css.rem(0.5), /* zero */-789508312),
                              /* [] */0
                            ]
                          ])
                    }
                  }));
  } else {
    return React.createElement("div", {
                className: SnackPurveyor$BsConsole.Style.container
              }, I18N$BsConsole.show(undefined, "Missing symbols have been detected. Would you like to attempt to download symbols for all threads?"), React.createElement(Styles.MuiThemeProvider, {
                    theme: BugTheme$BsConsole.blue,
                    children: React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.unsafe("boxShadow", "none"),
                                  /* :: */[
                                    Css.marginTop(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (_event) {
                              var endpt = Services$BsConsole.getEndpoint(undefined)(config.services, "symbold");
                              if (endpt !== undefined) {
                                return reprocessMissingSyms(endpt, token, universeName, projectName, aperture, error, setShowProgress, handleChangeUrl);
                              } else {
                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Symbol service not available"));
                              }
                            }),
                          children: I18N$BsConsole.show(undefined, "Download and reprocess")
                        })
                  }));
  }
}

var Body = {
  reloadRoute: reloadRoute,
  showSuccess: showSuccess,
  reprocessMissingSyms: reprocessMissingSyms,
  make: BugReprocessMissingSymbols$Body
};

function displayPrompt(projectName, config, token, aperture, error, handleChangeUrl) {
  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, Caml_option.some(React.createElement(BugReprocessMissingSymbols$Body, {
                      projectName: projectName,
                      config: config,
                      token: token,
                      aperture: aperture,
                      error: error,
                      handleChangeUrl: handleChangeUrl
                    })), I18N$BsConsole.show(undefined, "Missing symbols"));
}

exports.Body = Body;
exports.displayPrompt = displayPrompt;
/* Css Not a pure module */
