// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");

function BugBttThreadSelect$ThreadGroupRow(Props) {
  var thread = Props.thread;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var typ = Props.typ;
  Props.setExpanded;
  var paneFocused = Props.paneFocused;
  var firstFrame = Belt_Array.getExn(thread.user, 0);
  var bg = Css.background(thread.tid === selectedThread.tid ? (
          paneFocused ? Css.hex(Colors$BsConsole.tealExtraLight) : Css.hex(Colors$BsConsole.blackA10)
        ) : Css.hex(Colors$BsConsole.white));
  var cn = Css.style(/* :: */[
        bg,
        /* :: */[
          Css.height(Css.px(20)),
          /* :: */[
            Css.padding2(Css.px(4), Css.px(3)),
            /* [] */0
          ]
        ]
      ]);
  var tmp;
  if (typ !== undefined) {
    if (typeof typ === "number") {
      tmp = typ !== 0 ? React.createElement("svg", {
              height: "20",
              width: "20",
              shapeRendering: "crispEdges"
            }, React.createElement("path", {
                  d: "M 9 0 L 9 10 L 18 10",
                  fill: "none",
                  stroke: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                  strokeWidth: "1"
                })) : React.createElement("svg", {
              height: "20",
              width: "20",
              shapeRendering: "crispEdges"
            }, React.createElement("path", {
                  d: "M 9 0 L 9 20",
                  fill: "none",
                  stroke: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                  strokeWidth: "1"
                }));
    } else {
      var setExpanded = typ[1];
      tmp = React.createElement(Mui$BsConsole.IconButton.make, {
            ariaLabel: "Expand",
            classes: {
              root: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.fontSize(Css.px(16)),
                          /* :: */[
                            Css.padding(Css.px(1)),
                            /* :: */[
                              Css.margin2(Css.px(1), Css.zero),
                              /* :: */[
                                Css.width(Css.px(18)),
                                /* :: */[
                                  Css.height(Css.px(18)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ])
            },
            onClick: (function (e) {
                e.stopPropagation();
                return Curry._1(setExpanded, undefined);
              }),
            children: React.createElement(ArrowDropDown.default, {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA87)),
                        /* :: */[
                          Css.transform(Css.rotate(Css.deg(typ[0] ? 0 : -90))),
                          /* :: */[
                            Css.transition(200, undefined, undefined, "transform"),
                            /* [] */0
                          ]
                        ]
                      ]),
                  fontSize: "inherit"
                })
          });
    }
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.unsafe("display", "contents"),
                    /* :: */[
                      Css.color(thread.faulted ? Css.hex(Colors$BsConsole.npLightRed) : Css.hex(Colors$BsConsole.blackA87)),
                      /* :: */[
                        Css.padding2(Css.px(4), Css.px(2)),
                        /* :: */[
                          Css.height(Css.px(20)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              onClick: (function (param) {
                  return Curry._1(setSelectedThread, thread);
                })
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        bg,
                        /* :: */[
                          Css.height(Css.px(20)),
                          /* :: */[
                            Css.width(Css.px(20)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, tmp), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* [] */0),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(thread.tid)), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(thread.name)), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(firstFrame.basename)), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(firstFrame.symbol)));
}

var ThreadGroupRow = {
  make: BugBttThreadSelect$ThreadGroupRow
};

function BugBttThreadSelect$ThreadGroup(Props) {
  var expandedThreads = Props.expandedThreads;
  var onToggleThread = Props.onToggleThread;
  var group = Props.group;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var paneFocused = Props.paneFocused;
  var thread = Belt_Array.getExn(group, 0);
  var expanded = Belt_MapString.getWithDefault(expandedThreads, thread.id, false);
  var tmp = {
    thread: thread,
    selectedThread: selectedThread,
    setSelectedThread: setSelectedThread,
    paneFocused: paneFocused
  };
  var tmp$1 = group.length === 1 ? undefined : /* Parent */[
      expanded,
      (function (param) {
          return Curry._1(onToggleThread, thread.id);
        })
    ];
  if (tmp$1 !== undefined) {
    tmp.typ = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BugBttThreadSelect$ThreadGroupRow, tmp), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.unsafe("display", "contents"),
                        /* :: */[
                          Css.height(Css.px(Caml_int32.imul(group.length - 1 | 0, 20))),
                          /* :: */[
                            Css.overflowY(Css.hidden),
                            /* [] */0
                          ]
                        ]
                      ])
                }, expanded ? Belt_Array.mapWithIndex(Belt_Array.sliceToEnd(group, 1), (function (idx, thread) {
                          return React.createElement(BugBttThreadSelect$ThreadGroupRow, {
                                      thread: thread,
                                      selectedThread: selectedThread,
                                      setSelectedThread: setSelectedThread,
                                      typ: idx === (group.length - 2 | 0) ? /* LastChild */1 : /* Child */0,
                                      paneFocused: paneFocused,
                                      key: String(idx)
                                    });
                        })) : null));
}

var ThreadGroup = {
  make: BugBttThreadSelect$ThreadGroup
};

function BugBttThreadSelect(Props) {
  var expandedThreads = Props.expandedThreads;
  var onToggleThread = Props.onToggleThread;
  var threads = Props.threads;
  var threadGroups = Props.threadGroups;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var w = Props.width;
  var h = Props.height;
  var paneFocused = Props.paneFocused;
  var onClick = Props.onClick;
  var visibleThreads = Belt_Array.reduce(threadGroups, [], (function (acc, threadGroup) {
          var firstThread = Belt_Array.getExn(threadGroup, 0);
          var expanded = Belt_MapString.getWithDefault(expandedThreads, firstThread.id, false);
          return Belt_Array.concat(acc, expanded ? threadGroup : [firstThread]);
        }));
  var scrollableDivRef = React.useRef(null);
  React.useLayoutEffect((function () {
          var selectedThread$1 = visibleThreads.find((function (thread) {
                  return Caml_obj.caml_equal(thread, selectedThread);
                }));
          var match = scrollableDivRef.current;
          if (!(match == null) && selectedThread$1 !== undefined) {
            var threadIndex = visibleThreads.indexOf(selectedThread$1);
            var currTop = match.scrollTop;
            if (currTop > (Caml_int32.imul(threadIndex, 20) - 16 | 0)) {
              match.scroll(0, Caml_int32.imul(threadIndex, 20) - 16 | 0);
            } else if (Caml_int32.imul(threadIndex, 20) > ((currTop + h | 0) - 76 | 0)) {
              match.scrollTo(0, (Caml_int32.imul(threadIndex, 20) - h | 0) + 76 | 0);
            }
            
          }
          
        }), [selectedThread]);
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.px(w)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* [] */0
                    ]
                  ]),
              onClick: onClick,
              children: null
            }, React.createElement(BugSectionHeader$BsConsole.make, {
                  title: "Threads",
                  count: threads.length
                }), React.createElement("div", {
                  ref: (function (param) {
                      scrollableDivRef.current = param;
                      
                    }),
                  className: Css.style(/* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.overflowY(Css.auto),
                            /* :: */[
                              Css.overflowX(Css.hidden),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.padding2(Css.px(4), Css.zero),
                            /* :: */[
                              Css.fontFamily("Inconsolata"),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* :: */[
                                    Css.display(Css.grid),
                                    /* :: */[
                                      Css.alignItems(Css.center),
                                      /* :: */[
                                        Css.gridTemplateColumns(/* :: */[
                                              Css.px(20),
                                              /* :: */[
                                                Css.auto,
                                                /* :: */[
                                                  Css.auto,
                                                  /* :: */[
                                                    Css.auto,
                                                    /* :: */[
                                                      Css.auto,
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* :: */[
                                          Css.unsafe("gridTemplateRows", "repeat(20px)"),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, Belt_Array.mapWithIndex(threadGroups, (function (idx, group) {
                            return React.createElement(BugBttThreadSelect$ThreadGroup, {
                                        expandedThreads: expandedThreads,
                                        onToggleThread: onToggleThread,
                                        group: group,
                                        selectedThread: selectedThread,
                                        setSelectedThread: setSelectedThread,
                                        paneFocused: paneFocused,
                                        key: String(idx)
                                      });
                          })))));
}

var make = BugBttThreadSelect;

exports.ThreadGroupRow = ThreadGroupRow;
exports.ThreadGroup = ThreadGroup;
exports.make = make;
/* Css Not a pure module */
