// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var Drilldown$BsConsole = require("../Drilldown.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var CallstackCopyButtonWithObjectFetch$BsConsole = require("../CallstackCopyButtonWithObjectFetch.js");

var tooltipPaper = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey0)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.selector("ul", /* :: */[
                  Css.padding2(Css.px(8), Css.px(12)),
                  /* :: */[
                    Css.maxHeight(Css.px(250)),
                    /* :: */[
                      Css.overflowY(/* auto */-1065951377),
                      /* :: */[
                        Css.overflowX(/* hidden */-862584982),
                        /* [] */0
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.important(Css.paddingRight(Css.zero)),
                /* :: */[
                  Css.flexWrap(/* nowrap */867913355),
                  /* :: */[
                    Css.fontFamily("Inconsolata"),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Css.height(Css.px(40)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var frame = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.textOverflow(/* ellipsis */166888785),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.unsafe("fontFamily", "inherit"),
            /* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* :: */[
                Css.padding(Css.px(4)),
                /* :: */[
                  Css.hover(/* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.borderRadius(Css.px(3)),
                    /* :: */[
                      Css.display(/* inlineBlock */-147785676),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipFrame = Css.style(/* :: */[
      Css.unsafe("fontFamily", "inherit"),
      /* :: */[
        Css.unsafe("fontSize", "inherit"),
        /* :: */[
          Css.padding(Css.px(4)),
          /* :: */[
            Css.hover(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                  /* [] */0
                ]),
            /* :: */[
              Css.borderRadius(Css.px(3)),
              /* :: */[
                Css.display(/* inlineBlock */-147785676),
                /* :: */[
                  Css.textAlign(/* left */-944764921),
                  /* :: */[
                    Css.whiteSpace(/* nowrap */867913355),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* :: */[
                        Css.textOverflow(/* ellipsis */166888785),
                        /* :: */[
                          Css.maxWidth(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var moreFramesButton = Css.style(/* :: */[
      Css.unsafe("fontSize", "inherit"),
      /* :: */[
        Css.padding(Css.px(4)),
        /* :: */[
          Css.borderRadius(Css.px(3)),
          /* :: */[
            Css.display(/* inlineBlock */-147785676),
            /* :: */[
              Css.textAlign(/* left */-944764921),
              /* :: */[
                Css.maxWidth(Css.pct(100)),
                /* :: */[
                  Css.margin(Css.px(12)),
                  /* :: */[
                    Css.marginTop(/* zero */-789508312),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var popoverPlusMore = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.marginBottom(Css.px(16)),
        /* :: */[
          Css.padding2(Css.px(3), Css.px(4)),
          /* :: */[
            Css.fontFamily("Inconsolata"),
            /* [] */0
          ]
        ]
      ]
    ]);

var arrowContainer = Css.style(/* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.height(Css.px(40)),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.paddingTop(Css.px(4)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.accent)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var button = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var copyButton = Css.style(/* :: */[
      Css.height(Css.px(40)),
      /* :: */[
        Css.width(Css.px(40)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* :: */[
            Css.selector("div", /* :: */[
                  Css.height(Css.px(18)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var callstackList = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.alignItems(/* flexStart */662439529),
              /* :: */[
                Css.fontFamily("Inconsolata"),
                /* :: */[
                  Css.padding(/* zero */-789508312),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var callstackListItem = Css.style(/* :: */[
      Css.unsafe("listStyle", "none"),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  tooltipPaper: tooltipPaper,
  container: container,
  frame: frame,
  tooltipFrame: tooltipFrame,
  moreFramesButton: moreFramesButton,
  popoverPlusMore: popoverPlusMore,
  arrowContainer: arrowContainer,
  button: button,
  copyButton: copyButton,
  callstackList: callstackList,
  callstackListItem: callstackListItem
};

var isWindows = navigator.platform.toLowerCase().includes("win");

function getFrameIndexString(index, total) {
  var digitCount = String(total).length;
  var indexString = String(index);
  var match = digitCount - indexString.length | 0;
  var leftPad;
  switch (match) {
    case 0 :
        leftPad = "";
        break;
    case 1 :
        leftPad = "0";
        break;
    case 2 :
        leftPad = "00";
        break;
    default:
      leftPad = "";
  }
  return "[" + (String(leftPad) + ("" + (String(indexString) + "]")));
}

function formatFramesForClipboard(frames) {
  return Belt_Array.mapWithIndex(frames, (function (index, frame) {
                  return getFrameIndexString(index, frames.length) + (" " + frame);
                })).join(isWindows ? "\r\n" : "\n");
}

function createFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* Contains */Block.__(2, [/* `String */[
                  -976970511,
                  value
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotContains */Block.__(3, [/* `String */[
                  -976970511,
                  value
                ]])
          ]
        ];
}

function Callstack$FrameList(Props) {
  var frames = Props.frames;
  var rawFrames = Props.rawFrames;
  var attribute = Props.attribute;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var processed = Caml_obj.caml_notequal(frames, rawFrames);
  return React.createElement("ul", {
              className: Css.merge(/* :: */[
                    callstackList,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, Belt_Array.mapWithIndex(frames, (function (idx, frame) {
                    var value = Belt_Array.get(rawFrames, idx);
                    if (value === undefined) {
                      return React.createElement("li", {
                                  key: frame + ("__" + String(idx)),
                                  className: callstackListItem
                                }, React.createElement("span", {
                                      className: tooltipFrame
                                    }, I18N$BsConsole.showSkip(frame)));
                    }
                    if (processed) {
                      var tmp = {
                        textValue: value,
                        filterOptions: createFilterOptions(attribute, value),
                        className: tooltipFrame,
                        children: I18N$BsConsole.showSkip(frame)
                      };
                      if (handleChangeUrl !== undefined) {
                        tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
                      }
                      if (handleAddFilters !== undefined) {
                        tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
                      }
                      return React.createElement(Tooltip.default, {
                                  classes: {
                                    tooltip: Css.style(/* :: */[
                                          Css.maxWidth(Css.vw(90)),
                                          /* [] */0
                                        ])
                                  },
                                  placement: "top-start",
                                  children: React.createElement("li", {
                                        className: callstackListItem
                                      }, React.createElement(Drilldown$BsConsole.make, tmp)),
                                  title: value,
                                  key: frame + ("__" + String(idx))
                                });
                    }
                    var tmp$1 = {
                      textValue: value,
                      filterOptions: createFilterOptions(attribute, value),
                      className: tooltipFrame,
                      children: I18N$BsConsole.showSkip(frame)
                    };
                    if (handleChangeUrl !== undefined) {
                      tmp$1.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
                    }
                    if (handleAddFilters !== undefined) {
                      tmp$1.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
                    }
                    return React.createElement("li", {
                                key: frame + ("__" + String(idx)),
                                className: callstackListItem
                              }, React.createElement(Drilldown$BsConsole.make, tmp$1));
                  })));
}

function make(frames, rawFrames, attribute, handleChangeUrl, handleAddFilters, className, children) {
  var tmp = {
    frames: frames,
    rawFrames: rawFrames,
    attribute: attribute
  };
  if (handleChangeUrl !== undefined) {
    tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
  }
  if (handleAddFilters !== undefined) {
    tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Callstack$FrameList, tmp, children);
}

var Re = {
  make: make
};

var FrameList = {
  make: Callstack$FrameList,
  Re: Re
};

function Callstack$CallstackPaper(Props) {
  var frames = Props.frames;
  var rawFrames = Props.rawFrames;
  var attribute = Props.attribute;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var config = Props.config;
  var token = Props.token;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var fingerprint = Props.fingerprint;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var showN = frames.length > 11 ? 10 : 11;
  var showFrames = expanded ? frames : Belt_Array.slice(frames, 0, showN);
  var tmp = {
    frames: showFrames,
    rawFrames: rawFrames,
    attribute: attribute
  };
  if (handleChangeUrl !== undefined) {
    tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
  }
  if (handleAddFilters !== undefined) {
    tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
  }
  var tmp$1;
  var exit = 0;
  if (config !== undefined && token !== undefined && projectName !== undefined && aperture !== undefined && fingerprint !== undefined) {
    tmp$1 = React.createElement(CallstackCopyButtonWithObjectFetch$BsConsole.make, {
          config: config,
          token: token,
          projectName: projectName,
          aperture: Caml_option.valFromOption(aperture),
          frames: frames,
          fingerprint: Caml_option.valFromOption(fingerprint),
          target: /* Button */0
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = React.createElement(Button.default, {
          color: "primary",
          classes: {
            root: Css.style(/* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ])
          },
          onClick: (function (param) {
              return Clipboard$BsConsole.copy(formatFramesForClipboard(rawFrames), (function (param) {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Callstack copied to clipboard."));
                          }), undefined);
            }),
          children: I18N$BsConsole.show(undefined, "Copy callstack")
        });
  }
  return React.createElement(Paper.default, {
              classes: {
                root: tooltipPaper
              },
              children: null
            }, React.createElement(Callstack$FrameList, tmp), (frames.length - showN | 0) > 0 && !expanded ? React.createElement(Button.default, {
                    disableRipple: true,
                    color: "primary",
                    classes: {
                      root: moreFramesButton
                    },
                    onClick: (function (param) {
                        return Curry._1(setExpanded, (function (param) {
                                      return true;
                                    }));
                      }),
                    children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* String_literal */Block.__(11, [
                                      " more frames",
                                      /* End_of_format */0
                                    ])
                                ]),
                              "%d more frames"
                            ]), frames.length - showN | 0)
                  }) : null, React.createElement(Divider.default, { }), tmp$1);
}

var CallstackPaper = {
  make: Callstack$CallstackPaper
};

function Callstack(Props) {
  var callstack = Props.callstack;
  Props.raw;
  var attribute = Props.attribute;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var config = Props.config;
  var token = Props.token;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var fingerprint = Props.fingerprint;
  var rawFrames = Belt_List.toArray(callstack);
  var frames = Morgue$BsConsole.Callstack.prep(rawFrames, {
        suffix: 2,
        dynamic: false
      });
  var match = Belt_Array.get(frames, 0);
  var match$1 = Belt_Array.get(rawFrames, 0);
  if (match === undefined) {
    return I18N$BsConsole.showSkip("\u2014");
  }
  if (match$1 === undefined) {
    return I18N$BsConsole.showSkip("\u2014");
  }
  var tmp = {
    frames: frames,
    rawFrames: rawFrames,
    attribute: attribute
  };
  if (handleChangeUrl !== undefined) {
    tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
  }
  if (handleAddFilters !== undefined) {
    tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
  }
  if (config !== undefined) {
    tmp.config = Caml_option.valFromOption(config);
  }
  if (token !== undefined) {
    tmp.token = Caml_option.valFromOption(token);
  }
  if (aperture !== undefined) {
    tmp.aperture = Caml_option.valFromOption(aperture);
  }
  if (projectName !== undefined) {
    tmp.projectName = Caml_option.valFromOption(projectName);
  }
  if (fingerprint !== undefined) {
    tmp.fingerprint = Caml_option.valFromOption(fingerprint);
  }
  return React.createElement(Tooltip.default, {
              classes: {
                popper: Css.style(/* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                tooltip: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.padding(Css.zero),
                        /* :: */[
                          Css.background(Css.none),
                          /* :: */[
                            Css.maxWidth(Css.vw(40)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              },
              interactive: true,
              placement: "right-end",
              enterDelay: 100,
              children: React.createElement("div", {
                    className: container
                  }, React.createElement(Drilldown$BsConsole.FromContext.make, {
                        textValue: match$1,
                        filterOptions: createFilterOptions(attribute, match$1),
                        className: frame,
                        children: I18N$BsConsole.showSkip(match)
                      }), React.createElement("div", {
                        className: Css.merge(/* :: */[
                              "frame-count",
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.marginLeft(Css.px(6)),
                                      /* :: */[
                                        Css.padding(Css.px(3)),
                                        /* :: */[
                                          Css.minWidth(Css.px(18)),
                                          /* :: */[
                                            Css.height(Css.px(18)),
                                            /* :: */[
                                              Css.alignSelf(/* center */98248149),
                                              /* :: */[
                                                Css.background(Css.hex(Colors$BsConsole.grey7)),
                                                /* :: */[
                                                  Css.fontSize(Css.px(10)),
                                                  /* :: */[
                                                    Css.display(/* flex */-1010954439),
                                                    /* :: */[
                                                      Css.alignItems(Css.center),
                                                      /* :: */[
                                                        Css.justifyContent(Css.center),
                                                        /* :: */[
                                                          Css.marginTop(Css.px(-2)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement("div", undefined, I18N$BsConsole.showSkip(String(frames.length))))),
              leaveDelay: 100,
              title: React.createElement(Callstack$CallstackPaper, tmp)
            });
}

function make$1(callstack, raw, attribute, handleChangeUrl, handleAddFilters, children) {
  return ReasonReactCompat.wrapReactForReasonReact(Callstack, {
              callstack: callstack,
              raw: raw,
              attribute: attribute,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters
            }, children);
}

var Re$1 = {
  make: make$1
};

var make$2 = Callstack;

exports.Style = Style;
exports.isWindows = isWindows;
exports.getFrameIndexString = getFrameIndexString;
exports.formatFramesForClipboard = formatFramesForClipboard;
exports.createFilterOptions = createFilterOptions;
exports.FrameList = FrameList;
exports.CallstackPaper = CallstackPaper;
exports.make = make$2;
exports.Re = Re$1;
/* tooltipPaper Not a pure module */
