// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("./util.js");
var IFrame$BsConsole = require("./IFrame.js");

function getOrgIDFromUniverse(universe) {
  return Belt_Array.get(universe.split("u-"), 1);
}

function getPathList(path) {
  switch (path) {
    case "" :
    case "/" :
        return /* [] */0;
    default:
      var raw = path.slice(1);
      var match = raw[raw.length - 1 | 0];
      var raw$1 = match === "/" ? raw.slice(0, -1) : raw;
      return Belt_List.fromArray(raw$1.split("/"));
  }
}

function getSearchJson(search) {
  var tmp;
  switch (search) {
    case "" :
    case "?" :
        tmp = "";
        break;
    default:
      tmp = decodeURIComponent(search.slice(1));
  }
  return Util$BsConsole.Qs.parse(tmp);
}

function getUDashOrgIDFromHostname(host) {
  return Belt_Option.flatMap(Belt_Array.get(host.split("."), 0), getOrgIDFromUniverse);
}

function getUDashOrgIDFromPath(path) {
  var match = getPathList(path);
  if (!match) {
    return ;
  }
  if (match[0] !== "dashboard") {
    return ;
  }
  var match$1 = match[1];
  if (match$1) {
    return Belt_Option.flatMap(match$1[0], getOrgIDFromUniverse);
  }
  
}

function getUDashOrgID(param) {
  var currentLocation = window.location;
  var host = currentLocation.host;
  var path = currentLocation.pathname;
  var fromPath = getUDashOrgIDFromPath(path);
  var fromUrl = getUDashOrgIDFromHostname(host);
  if (fromPath !== undefined) {
    return fromPath;
  } else if (fromUrl !== undefined) {
    return fromUrl;
  } else {
    return Js_exn.raiseError("Unable to parse universe from URL");
  }
}

function getProjectFromPath(path) {
  var match = getPathList(path);
  if (!match) {
    return ;
  }
  switch (match[0]) {
    case "dashboard" :
        var match$1 = match[1];
        if (!match$1) {
          return ;
        }
        var match$2 = match$1[1];
        if (!match$2) {
          return ;
        }
        if (match$2[0] !== "project") {
          return ;
        }
        var match$3 = match$2[1];
        if (match$3) {
          return match$3[0];
        } else {
          return ;
        }
    case "p" :
        var match$4 = match[1];
        if (match$4) {
          return match$4[0];
        } else {
          return ;
        }
    default:
      return ;
  }
}

function getProjectFromSearch(search) {
  var __x = getSearchJson(search);
  return Json_decode.optional((function (param) {
                return Json_decode.field("project", Json_decode.string, param);
              }), __x);
}

function getProject(param) {
  var currentLocation = window.location;
  var fromPath = getProjectFromPath(currentLocation.pathname);
  var fromSearch = getProjectFromSearch(currentLocation.search);
  if (fromPath !== undefined) {
    return fromPath;
  } else if (fromSearch !== undefined) {
    return fromSearch;
  } else {
    return ;
  }
}

function getReferrer(param) {
  var currentLocation = window.location;
  var p = currentLocation.pathname;
  var path;
  switch (p) {
    case "" :
    case "/" :
        path = "";
        break;
    default:
      path = p;
  }
  var s = currentLocation.search;
  var search;
  switch (s) {
    case "" :
    case "?" :
        search = "";
        break;
    default:
      search = s;
  }
  var str = path + search;
  var stringToEncode = str === "" ? undefined : str;
  return Belt_Option.map(Belt_Option.map(stringToEncode, (function (s) {
                    try {
                      return btoa(s);
                    }
                    catch (exn){
                      return btoa(escape(s));
                    }
                  })), (function (s) {
                return encodeURIComponent(s);
              }));
}

var unityDashboardHostName = "https://dashboard.unity3d.com";

var unityDashboardPathTemplate = "/organizations/{orgID}/projects/{project}/cloud-diagnostics/enterprise";

function getUnityDashboardUrl(param) {
  var orgID = getUDashOrgID(undefined);
  var project = getProject(undefined);
  var referrer = getReferrer(undefined);
  var urlWithTemplate = "https://dashboard.unity3d.com/organizations/{orgID}/projects/{project}/cloud-diagnostics/enterprise";
  var url = project !== undefined ? urlWithTemplate.replace("{orgID}", orgID).replace("{project}", project) : unityDashboardHostName;
  if (referrer !== undefined) {
    return url + ("?referrer=" + referrer);
  } else {
    return url;
  }
}

function isSupport(param) {
  var search = window.location.search;
  var __x = getSearchJson(search);
  var supportFromQSParams = Belt_Option.map(Json_decode.optional((function (param) {
              return Json_decode.field("support", Json_decode.string, param);
            }), __x), Pervasives.bool_of_string);
  var __x$1 = localStorage;
  var supportFromLocalStorage = Belt_Option.getWithDefault(Belt_Option.map(Caml_option.null_to_opt(__x$1.getItem("Backtrace-UDash-Support")), Pervasives.bool_of_string), false);
  if (supportFromQSParams === undefined) {
    return supportFromLocalStorage;
  }
  var __x$2 = localStorage;
  __x$2.setItem("Backtrace-UDash-Support", Pervasives.string_of_bool(supportFromQSParams));
  return supportFromQSParams;
}

function handleRedirect(param) {
  var consoleVariant = window._BACKTRACE_IDENTITY_VARIANT;
  var isSupport$1 = isSupport(undefined);
  if (consoleVariant == null) {
    return ;
  }
  if (consoleVariant !== "unity") {
    return ;
  }
  if (IFrame$BsConsole.isIFrame) {
    return ;
  }
  if (isSupport$1) {
    return ;
  }
  var nextUrl;
  try {
    nextUrl = getUnityDashboardUrl(undefined);
  }
  catch (exn){
    nextUrl = unityDashboardHostName;
  }
  var __x = window.location;
  __x.replace(nextUrl);
  
}

exports.getOrgIDFromUniverse = getOrgIDFromUniverse;
exports.getPathList = getPathList;
exports.getSearchJson = getSearchJson;
exports.getUDashOrgIDFromHostname = getUDashOrgIDFromHostname;
exports.getUDashOrgIDFromPath = getUDashOrgIDFromPath;
exports.getUDashOrgID = getUDashOrgID;
exports.getProjectFromPath = getProjectFromPath;
exports.getProjectFromSearch = getProjectFromSearch;
exports.getProject = getProject;
exports.getReferrer = getReferrer;
exports.unityDashboardHostName = unityDashboardHostName;
exports.unityDashboardPathTemplate = unityDashboardPathTemplate;
exports.getUnityDashboardUrl = getUnityDashboardUrl;
exports.isSupport = isSupport;
exports.handleRedirect = handleRedirect;
/* Util-BsConsole Not a pure module */
