// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function typeToString(tab) {
  if (tab !== undefined) {
    if (tab) {
      return "issue-based";
    } else {
      return "error-threshold";
    }
  }
  
}

function stringToType(tab) {
  if (tab === undefined) {
    return ;
  }
  switch (tab) {
    case "error-threshold" :
        return /* ErrorThresholdAlerts */0;
    case "issue-based" :
        return /* IssueBasedAlerts */1;
    default:
      return ;
  }
}

exports.typeToString = typeToString;
exports.stringToType = stringToType;
/* No side effect */
