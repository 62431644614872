// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");

var parentDiv = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.margin(Css.rem(1)),
          /* [] */0
        ]
      ]
    ]);

function paper(primaryMain) {
  return Css.style(/* :: */[
              Css.borderLeft(Css.px(8), /* solid */12956715, Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* :: */[
                Css.padding(Css.rem(0.4)),
                /* :: */[
                  Css.marginBottom(Css.px(20)),
                  /* [] */0
                ]
              ]
            ]);
}

function infoIcon(primaryMain) {
  return Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* :: */[
                Css.marginRight(Css.px(15)),
                /* [] */0
              ]
            ]);
}

var h4 = Css.style(/* :: */[
      Css.margin4(Css.px(0), Css.px(0), Css.px(8), Css.px(0)),
      /* :: */[
        Css.fontSize(Css.px(20)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* [] */0
        ]
      ]
    ]);

var p = Css.style(/* :: */[
      Css.margin(Css.px(0)),
      /* :: */[
        Css.fontSize(Css.px(16)),
        /* [] */0
      ]
    ]);

var Style = {
  parentDiv: parentDiv,
  paper: paper,
  infoIcon: infoIcon,
  h4: h4,
  p: p
};

function SuccessMessage(Props) {
  var classNameOpt = Props.className;
  var successHeaderOpt = Props.successHeader;
  var successMessage = Props.successMessage;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var successHeader = successHeaderOpt !== undefined ? Caml_option.valFromOption(successHeaderOpt) : I18N$BsConsole.show(undefined, "Success!");
  return React.createElement(Paper.default, {
              classes: {
                root: Css.merge(/* :: */[
                      paper(Colors$BsConsole.greenLight),
                      /* :: */[
                        className,
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement("div", {
                    className: parentDiv
                  }, React.createElement(Info.default, {
                        className: infoIcon(Colors$BsConsole.greenLight)
                      }), React.createElement("div", undefined, React.createElement("h4", {
                            className: h4
                          }, successHeader), React.createElement("p", {
                            className: p
                          }, successMessage)))
            });
}

var make = SuccessMessage;

exports.Style = Style;
exports.make = make;
/* parentDiv Not a pure module */
