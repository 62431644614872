// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Numeral$BsConsole = require("../Numeral.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var component = ReasonReact.statelessComponent("Renderer_Bytes-BsConsole");

function standardizeFormat(value) {
  var regex = new RegExp("(.+)\\.$");
  var result = regex.exec(value);
  var value$1 = result !== null ? Belt_Option.getWithDefault(Belt_Array.get(result, 1), "") : value;
  if (value$1.length > 4) {
    return Numeral$BsConsole.format("0,0.00b", value$1);
  } else {
    return Numeral$BsConsole.format("0b", value$1);
  }
}

function make(attribute, value, handleAddFilters, handleChangeUrl, classNameOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(className, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Flex_End */-403022699, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                                  /* tuple */[
                                                    attribute,
                                                    /* AtLeast */Block.__(8, [/* `String */[
                                                          -976970511,
                                                          value
                                                        ]])
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      attribute,
                                                      /* AtMost */Block.__(9, [/* `String */[
                                                            -976970511,
                                                            value
                                                          ]])
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ], undefined, value, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                                    var partial_arg = {
                                                      fontWeight: "normal",
                                                      textTransform: "none"
                                                    };
                                                    var arg = function (param, param$1, param$2) {
                                                      return ValueDrilldown$BsConsole.wrapWithButton(onClick, partial_arg, param, param$1, param$2);
                                                    };
                                                    return React.createElement("span", {
                                                                ref: refHandler,
                                                                onFocus: onFocus,
                                                                onClick: onClick
                                                              }, Curry._3(arg, undefined, undefined, I18N$BsConsole.showSkip(standardizeFormat(value))));
                                                  }), undefined, undefined, []))]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.standardizeFormat = standardizeFormat;
exports.make = make;
/* component Not a pure module */
