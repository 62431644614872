// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function Make(F) {
  var getId = function (param) {
    return /* tuple */[
            param.attribute,
            Curry._1(F.toString, param.operation)
          ];
  };
  var create = function (param) {
    return {
            attribute: param[0],
            operation: param[1]
          };
  };
  var toJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), F.toJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var fromJson = function (json) {
    return create(Json_decode.tuple2(Json_decode.string, F.fromJson, json));
  };
  var toTuple = function (param) {
    return /* tuple */[
            param.attribute,
            param.operation
          ];
  };
  var getAttribute = function (t$prime) {
    return t$prime.attribute;
  };
  var getOperation = function (t$prime) {
    return t$prime.operation;
  };
  return {
          getId: getId,
          create: create,
          toJson: toJson,
          fromJson: fromJson,
          toTuple: toTuple,
          operationToCrdb: F.toCrdb,
          getAttribute: getAttribute,
          getOperation: getOperation
        };
}

exports.Make = Make;
/* No side effect */
