// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Base$BsConsole = require("./bs-crdb-response/src/Base.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Route$BsConsole = require("./route.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Morgue$BsConsole = require("./bs-crdb-response/src/Morgue.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var UiStats$BsConsole = require("./UiStats.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Renderer$BsConsole = require("./renders/Renderer.js");
var Services$BsConsole = require("./Services.js");
var Callstack$BsConsole = require("./format-renderers/Callstack.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var ActivityFeed$BsConsole = require("./activity-feed/ActivityFeed.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var TriageTopBar$BsConsole = require("./debugger2/TriageTopBar.js");
var Configuration$BsConsole = require("./configuration.js");
var FetchDescribe$BsConsole = require("./FetchDescribe.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var FeatureService$BsConsole = require("./FeatureService.js");
var SimilarityUtils$BsConsole = require("./similarity/SimilarityUtils.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");
var DateRangeContent$BsConsole = require("./inbox/DateRangeContent.js");
var SimilarityDetail$BsConsole = require("./similarity/SimilarityDetail.js");
var FixedAttributeCard$BsConsole = require("./inbox/FixedAttributeCard.js");
var InboxUnmergeButton$BsConsole = require("./inbox/InboxUnmergeButton.js");
var AttributeDetailCard$BsConsole = require("./inbox/AttributeDetailCard.js");
var SimilarityCandidate$BsConsole = require("./similarity/SimilarityCandidate.js");
var SimilarityTabActions$BsConsole = require("./similarity/SimilarityTabActions.js");
var SimilarityDistanceParameter$BsConsole = require("./similarity/SimilarityDistanceParameter.js");
var CallstackCopyButtonWithObjectFetch$BsConsole = require("./CallstackCopyButtonWithObjectFetch.js");

var callstackPanel = Css.style(/* :: */[
      Css.overflow(Css.auto),
      /* [] */0
    ]);

var attributePanel = Css.style(/* :: */[
      Css.overflow(Css.auto),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var switchBackground = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.grey5))),
      /* [] */0
    ]);

var switchChecked = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.grey3))),
      /* [] */0
    ]);

var switchUnchecked = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.grey6))),
      /* [] */0
    ]);

var actionIconButton = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.grey3))),
      /* [] */0
    ]);

var tabsToolbar = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.minHeight(Css.px(48)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var indicator = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.green))),
      /* [] */0
    ]);

var Style = {
  callstackPanel: callstackPanel,
  attributePanel: attributePanel,
  switchBackground: switchBackground,
  switchChecked: switchChecked,
  switchUnchecked: switchUnchecked,
  actionIconButton: actionIconButton,
  tabsToolbar: tabsToolbar,
  indicator: indicator
};

function attributeUniq(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          attributeUniq(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(hd, v);
                    })))
        ];
}

function getUniqueFoldAttributes(fold) {
  return attributeUniq(Belt_List.fromArray(Belt_Array.map(Belt_Array.map(Curry._1(Crdb$BsConsole.Stats.valuesToArray, fold), Crdb$BsConsole.StatsAggregation.toTuple), (function (param) {
                        return param[0];
                      }))));
}

function removeAttributeFromFold(attributeName, fold) {
  return getUniqueFoldAttributes(Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                  attributeName,
                  "sum"
                ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                      attributeName,
                      "mean"
                    ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                          attributeName,
                          "sum"
                        ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                              attributeName,
                              "unique"
                            ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                                  attributeName,
                                  "bin"
                                ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                                      attributeName,
                                      "distribution"
                                    ], Curry._2(Crdb$BsConsole.Stats.remove, /* tuple */[
                                          attributeName,
                                          "range"
                                        ], fold))))))));
}

var component = ReasonReact.reducerComponentWithRetainedProps("TriageDetails-BsConsole");

function make(triageGroup, fetchIssues, projectName, token, config, columns, group, handleTask, stats, tab, handleChangeUrl, aperture, handleAddFilters, handleSetDetailsTab, _children) {
  var classifiersAndGuid = Belt_Array.keepMap(triageGroup.aggregations, (function (shape) {
          switch (shape.tag | 0) {
            case /* Unique */0 :
                if (shape[0] === "guid") {
                  return shape;
                } else {
                  return ;
                }
            case /* Head */3 :
                if (shape[0] === "classifiers") {
                  return shape;
                } else {
                  return ;
                }
            default:
              return ;
          }
        }));
  var addFilter = function (aperture, filters) {
    var filterMap = Belt_List.toArray(filters).reduce((function (filterMap, param) {
            return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                            param[0],
                            param[1]
                          ]), filterMap);
          }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
    return Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, aperture);
  };
  var getDebuggerRoute = function (fingerprint) {
    var init = Route$BsConsole.getDebuggerParams(undefined, undefined, projectName, undefined);
    return /* ProjectDebugger */Block.__(17, [
              projectName,
              {
                aperture: addFilter(aperture, WindowVariables$BsConsole.maybeAutoAddDeletedEqualZero ? /* :: */[
                        /* tuple */[
                          "fingerprint",
                          /* Equal */Block.__(0, [/* `String */[
                                -976970511,
                                fingerprint
                              ]])
                        ],
                        /* :: */[
                          /* tuple */[
                            "_deleted",
                            /* Equal */Block.__(0, [/* `Int */[
                                  3654863,
                                  Int64.zero
                                ]])
                          ],
                          /* [] */0
                        ]
                      ] : /* :: */[
                        /* tuple */[
                          "fingerprint",
                          /* Equal */Block.__(0, [/* `String */[
                                -976970511,
                                fingerprint
                              ]])
                        ],
                        /* [] */0
                      ]),
                sort: init.sort,
                s: init.s
              }
            ]);
  };
  var getInstancesRoute = function (fingerprint) {
    var init = Route$BsConsole.getInstancesParams(projectName, undefined, undefined);
    return /* ProjectInstances */Block.__(15, [
              projectName,
              {
                aperture: addFilter(aperture, /* :: */[
                      /* tuple */[
                        "fingerprint",
                        /* Equal */Block.__(0, [/* `String */[
                              -976970511,
                              fingerprint
                            ]])
                      ],
                      /* [] */0
                    ]),
                select: init.select,
                sort: init.sort
              }
            ]);
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              var similarityEndpointMaybe = Services$BsConsole.getEndpoint(undefined)(config.services, "similarity");
              if (similarityEndpointMaybe !== undefined) {
                var arg = SimilarityUtils$BsConsole.fetchServiceVersion(similarityEndpointMaybe);
                return Curry._2((function (param) {
                                return (function (param$1, param$2) {
                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                  });
                              })(token), undefined, (function (version) {
                              if (version.tag) {
                                Curry._1(self.send, /* SetSimilarityVersionValidity */Block.__(1, [
                                        false,
                                        false
                                      ]));
                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "Error retrieving similarity version: ",
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "Error retrieving similarity version: %s"
                                                  ]), Task2$BsConsole.renderError(undefined, version[0])));
                              }
                              var version$1 = version[0];
                              var validVersion = Semver.valid(version$1);
                              var isVersionValid = validVersion !== undefined && Semver.gte(validVersion, SimilarityUtils$BsConsole.minimumRequiredVersion) ? true : false;
                              var validVersion$1 = Semver.valid(version$1);
                              var supportsTickets = validVersion$1 !== undefined ? Semver.gte(validVersion$1, SimilarityUtils$BsConsole.includesCandidateTickets) : false;
                              Curry._1(self.send, /* SetSimilarityVersionValidity */Block.__(1, [
                                      isVersionValid,
                                      supportsTickets
                                    ]));
                              if (isVersionValid) {
                                return Curry._1(self.send, /* GetSimilarityCandidates */Block.__(3, [
                                              similarityEndpointMaybe,
                                              projectName,
                                              triageGroup.fingerprint,
                                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Limit */2),
                                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Threshold */0),
                                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Truncate */1),
                                              SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Intersection */1),
                                              SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Distance */0),
                                              false
                                            ]));
                              }
                              
                            }));
              }
              Curry._1(self.send, /* SetSimilarityVersionValidity */Block.__(1, [
                      false,
                      false
                    ]));
              
            }),
          didUpdate: (function (param) {
              var newSelf = param.newSelf;
              if (param.oldSelf.retainedProps.triageGroup.fingerprint === newSelf.retainedProps.triageGroup.fingerprint) {
                return ;
              }
              var similarityEndpointMaybe = Services$BsConsole.getEndpoint(undefined)(config.services, "similarity");
              var match = newSelf.state.isSimilarityServiceVersionValid;
              if (similarityEndpointMaybe !== undefined && match !== undefined && match) {
                return Curry._1(newSelf.send, /* GetSimilarityCandidates */Block.__(3, [
                              similarityEndpointMaybe,
                              projectName,
                              triageGroup.fingerprint,
                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Limit */2),
                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Threshold */0),
                              SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Truncate */1),
                              SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Intersection */1),
                              SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Distance */0),
                              false
                            ]));
              }
              
            }),
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var supportsTickets = match.supportsTickets;
              var isSimilarityServiceVersionValid = match.isSimilarityServiceVersionValid;
              var similarityLoading = match.similarityLoading;
              var selectedSimilarityCandidates = match.selectedSimilarityCandidates;
              var candidateDataMaybe = match.candidateDataMaybe;
              var useProcessedCallsack = match.useProcessedCallsack;
              var fingerprint = triageGroup.fingerprint;
              var similarityEndpointMaybe = Services$BsConsole.getEndpoint(undefined)(config.services, "similarity");
              var commentsServiceUrl = Services$BsConsole.getEndpoint(undefined)(config.services, "comments");
              var isMerged = TriageGroup$BsConsole.isGroup(triageGroup.fingerprint);
              var project = Configuration$BsConsole.getProject(projectName, config);
              if (project !== undefined) {
                return ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.make(handleTask, token, projectName, (function (describeRemote, param) {
                                  var getter = function (param, param$1) {
                                    return Group$BsConsole.getAggregation(columns, group, param, param$1);
                                  };
                                  var tmp;
                                  if (candidateDataMaybe !== undefined) {
                                    var count = Belt_List.length(candidateDataMaybe.candidates);
                                    tmp = count > 0 ? I18N$BsConsole.show(undefined, "Similarity (" + (String(count) + ")")) : I18N$BsConsole.show(undefined, "Similarity");
                                  } else {
                                    tmp = I18N$BsConsole.show(undefined, "Similarity");
                                  }
                                  var tmp$1;
                                  if (tab) {
                                    tmp$1 = similarityLoading ? React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.display(/* flex */-1010954439),
                                                  /* :: */[
                                                    Css.flexDirection(/* column */-963948842),
                                                    /* :: */[
                                                      Css.alignItems(/* center */98248149),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ])
                                          }, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, Css.style(/* :: */[
                                                        Css.paddingTop(Css.px(12)),
                                                        /* :: */[
                                                          Css.paddingBottom(Css.px(12)),
                                                          /* [] */0
                                                        ]
                                                      ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Computing similarity. This can take a while.")])), ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))) : (
                                        similarityEndpointMaybe !== undefined ? (
                                            isSimilarityServiceVersionValid !== undefined ? (
                                                isSimilarityServiceVersionValid ? ReasonReact.element(undefined, undefined, SimilarityDetail$BsConsole.Re.make(candidateDataMaybe, similarityEndpointMaybe, (function (selected) {
                                                              return Curry._1(send, /* SetSelectedSimilarityCandidates */Block.__(4, [selected]));
                                                            }), selectedSimilarityCandidates, triageGroup, token, projectName, handleChangeUrl, aperture, supportsTickets, config, [])) : ReasonReact.element(undefined, undefined, Curry._3(FeatureService$BsConsole.UnavailableVersion.Re.make, "similarity", SimilarityUtils$BsConsole.minimumRequiredVersion, []))
                                              ) : null
                                          ) : ReasonReact.element(undefined, undefined, Curry._2(FeatureService$BsConsole.Unavailable.Re.make, "similarity", []))
                                      );
                                  } else {
                                    var match = triageGroup.callstack;
                                    var tmp$2;
                                    if (match !== undefined) {
                                      var frames = Belt_List.toArray(match[0]);
                                      var morgueProcessedFrames = Morgue$BsConsole.Callstack.prep(frames, {
                                            suffix: 2,
                                            dynamic: true
                                          });
                                      var isWorthProcessingFrames = Caml_obj.caml_notequal(morgueProcessedFrames, frames);
                                      tmp$2 = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                                                    /* :: */[
                                                      Css.marginRight(Css.rem(1)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.rem(1)),
                                                        /* :: */[
                                                          Css.unsafe("maxWidth", "calc(100% - 316px)"),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]), undefined, undefined, 0, [
                                                ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [
                                                          ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, Css.style(/* :: */[
                                                                        Css.margin(Css.px(12)),
                                                                        /* [] */0
                                                                      ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Callstack")])),
                                                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                    ReasonReact.element(undefined, undefined, CallstackCopyButtonWithObjectFetch$BsConsole.Re.make(config, token, projectName, aperture, frames, undefined, undefined, [])),
                                                                    isWorthProcessingFrames ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, useProcessedCallsack ? I18N$BsConsole.show(undefined, "Show raw callstack frames") : I18N$BsConsole.show(undefined, "Show processed callstack frames"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, !isWorthProcessingFrames, undefined, /* `Bool */[
                                                                                          737456202,
                                                                                          useProcessedCallsack
                                                                                        ], (function (param, param$1) {
                                                                                            return Curry._1(send, /* SetNormalizedCallstack */Block.__(0, [!useProcessedCallsack]));
                                                                                          }), undefined, {
                                                                                          colorSecondary: switchUnchecked,
                                                                                          iconChecked: switchChecked,
                                                                                          bar: switchBackground
                                                                                        }, "", []))])) : null
                                                                  ]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry._7(Callstack$BsConsole.FrameList.Re.make, useProcessedCallsack ? morgueProcessedFrames : frames, frames, "callstack", handleChangeUrl, handleAddFilters, Css.style(/* :: */[
                                                              Css.fontSize(Css.px(13)),
                                                              /* :: */[
                                                                Css.minWidth(Css.px(300)),
                                                                /* :: */[
                                                                  Css.width(Css.px(300)),
                                                                  /* :: */[
                                                                    Css.minHeight(Css.px(67)),
                                                                    /* :: */[
                                                                      Css.resize(/* both */-1055160191),
                                                                      /* :: */[
                                                                        Css.overflowX(/* hidden */-862584982),
                                                                        /* :: */[
                                                                          Css.overflowY(/* auto */-1065951377),
                                                                          /* :: */[
                                                                            Css.paddingLeft(Css.px(8)),
                                                                            /* :: */[
                                                                              Css.paddingBottom(Css.px(8)),
                                                                              /* :: */[
                                                                                Css.maxWidth(Css.pct(100)),
                                                                                /* [] */0
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]), []))
                                              ]));
                                    } else {
                                      tmp$2 = null;
                                    }
                                    var match$1 = triageGroup.timestampRange;
                                    var tmp$3;
                                    if (candidateDataMaybe !== undefined) {
                                      var count$1 = Belt_List.length(candidateDataMaybe.candidates);
                                      if (count$1 > 0) {
                                        var __x = candidateDataMaybe.candidates;
                                        var totalErrorCount = Belt_List.reduce(__x, 0, (function (acc, curr) {
                                                return acc + curr.count | 0;
                                              }));
                                        var beginSentence = count$1 !== 1 ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "These ",
                                                        /* Int */Block.__(4, [
                                                            /* Int_d */0,
                                                            /* No_padding */0,
                                                            /* No_precision */0,
                                                            /* String_literal */Block.__(11, [
                                                                " fingerprints have ",
                                                                /* End_of_format */0
                                                              ])
                                                          ])
                                                      ]),
                                                    "These %d fingerprints have "
                                                  ]), count$1) : I18N$BsConsole.get(undefined, "This fingerprint has ");
                                        var endSentence = totalErrorCount !== 1 ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                    /* Int */Block.__(4, [
                                                        /* Int_d */0,
                                                        /* No_padding */0,
                                                        /* No_precision */0,
                                                        /* String_literal */Block.__(11, [
                                                            " errors.",
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "%d errors."
                                                  ]), totalErrorCount) : I18N$BsConsole.get(undefined, "1 error.");
                                        tmp$3 = ReasonReact.element(undefined, undefined, FixedAttributeCard$BsConsole.make(undefined, "Similarity", Caml_option.some(ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* spaceBetween */516682146, /* flexStart */662439529, undefined, undefined, undefined, [
                                                              React.createElement("div", undefined, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body2 */9, undefined, Css.style(/* :: */[
                                                                                Css.marginTop(Css.px(5)),
                                                                                /* :: */[
                                                                                  Css.display(/* block */888960333),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]), undefined, Colors$BsConsole.grey1, undefined, [count$1 !== 1 ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                        /* Int */Block.__(4, [
                                                                                            /* Int_d */0,
                                                                                            /* No_padding */0,
                                                                                            /* No_precision */0,
                                                                                            /* String_literal */Block.__(11, [
                                                                                                " potential candidates found for merging.",
                                                                                                /* End_of_format */0
                                                                                              ])
                                                                                          ]),
                                                                                        "%d potential candidates found for merging."
                                                                                      ]), count$1) : I18N$BsConsole.show(undefined, "1 potential candidate found for merging.")])), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body2 */9, undefined, Css.style(/* :: */[
                                                                                Css.marginTop(Css.px(5)),
                                                                                /* :: */[
                                                                                  Css.display(/* block */888960333),
                                                                                  /* :: */[
                                                                                    Css.marginBottom(Css.px(8)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]), undefined, Colors$BsConsole.grey4, undefined, [I18N$BsConsole.showSkip(beginSentence + endSentence)]))),
                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                        /* Small */311976103,
                                                                        undefined,
                                                                        (function (param) {
                                                                            return Curry._1(handleSetDetailsTab, /* SimilarityTab */1);
                                                                          }),
                                                                        undefined,
                                                                        undefined,
                                                                        /* Outlined */-28821822,
                                                                        /* Primary */-791844958,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [I18N$BsConsole.show(undefined, "View similar fingerprints")]
                                                                      ]))
                                                            ]))), []));
                                      } else {
                                        tmp$3 = null;
                                      }
                                    } else {
                                      tmp$3 = null;
                                    }
                                    tmp$1 = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexStart */662439529, undefined, undefined, undefined, [
                                                  tmp$2,
                                                  React.createElement("div", {
                                                        className: attributePanel
                                                      }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* wrap */-822134326, undefined, [
                                                                ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                              Css.minWidth(Css.px(300)),
                                                                              /* :: */[
                                                                                Css.width(Css.pct(33.33)),
                                                                                /* [] */0
                                                                              ]
                                                                            ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                                                                                        /* :: */[
                                                                                          Css.marginRight(Css.rem(1)),
                                                                                          /* :: */[
                                                                                            Css.marginBottom(Css.rem(1)),
                                                                                            /* :: */[
                                                                                              Css.minHeight(Css.px(110)),
                                                                                              /* :: */[
                                                                                                Css.height(Css.pct(100)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]), undefined, undefined, 0, [
                                                                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                                                                  Css.padding(Css.px(12)),
                                                                                                  /* :: */[
                                                                                                    Css.paddingBottom(/* zero */-789508312),
                                                                                                    /* [] */0
                                                                                                  ]
                                                                                                ]), [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Actions")]))])),
                                                                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                                                                  Css.padding(Css.px(4)),
                                                                                                  /* :: */[
                                                                                                    Css.paddingBottom(Css.px(12)),
                                                                                                    /* [] */0
                                                                                                  ]
                                                                                                ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(getDebuggerRoute(fingerprint), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                            undefined,
                                                                                                                            Css.style(/* :: */[
                                                                                                                                  Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                                                                  /* :: */[
                                                                                                                                    Css.textTransform(/* none */-922086728),
                                                                                                                                    /* :: */[
                                                                                                                                      Css.fontSize(Css.px(13)),
                                                                                                                                      /* [] */0
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]),
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, "smaller-icon", [
                                                                                                                                        ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.BugReport.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                                                                                        React.createElement("div", {
                                                                                                                                              className: Css.style(/* :: */[
                                                                                                                                                    Css.marginLeft(Css.em(0.5)),
                                                                                                                                                    /* [] */0
                                                                                                                                                  ])
                                                                                                                                            }, I18N$BsConsole.show(undefined, "View in debugger"))
                                                                                                                                      ]))]
                                                                                                                          ]))])),
                                                                                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(getInstancesRoute(fingerprint), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                            undefined,
                                                                                                                            Css.style(/* :: */[
                                                                                                                                  Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                                                                  /* :: */[
                                                                                                                                    Css.textTransform(/* none */-922086728),
                                                                                                                                    /* :: */[
                                                                                                                                      Css.fontSize(Css.px(13)),
                                                                                                                                      /* [] */0
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]),
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, "smaller-icon", [
                                                                                                                                        ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.List.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                                                                                        React.createElement("div", {
                                                                                                                                              className: Css.style(/* :: */[
                                                                                                                                                    Css.marginLeft(Css.em(0.5)),
                                                                                                                                                    /* [] */0
                                                                                                                                                  ])
                                                                                                                                            }, I18N$BsConsole.show(undefined, "View instances"))
                                                                                                                                      ]))]
                                                                                                                          ]))]))
                                                                                                      ]))]))
                                                                                  ]))])),
                                                                ReasonReact.element(undefined, undefined, FixedAttributeCard$BsConsole.make(undefined, I18N$BsConsole.get(undefined, "Date range"), Caml_option.some(match$1 !== undefined ? ReasonReact.element(undefined, undefined, DateRangeContent$BsConsole.make(match$1[0], match$1[1], [])) : null), [])),
                                                                Belt_Array.map(classifiersAndGuid, (function (param) {
                                                                        switch (param.tag | 0) {
                                                                          case /* Unique */0 :
                                                                              var attribute = param[0];
                                                                              if (attribute === "guid") {
                                                                                return ReasonReact.element(attribute, undefined, FixedAttributeCard$BsConsole.make(undefined, I18N$BsConsole.get(undefined, "Unique hosts"), Caml_option.some(React.createElement("span", {
                                                                                                        className: Css.style(/* :: */[
                                                                                                              Css.fontFamily("Inconsolata"),
                                                                                                              /* [] */0
                                                                                                            ])
                                                                                                      }, I18N$BsConsole.showSkip(Base$BsConsole.floatToString(Belt_Option.getWithDefault(param[1], 0))))), []));
                                                                              } else {
                                                                                return null;
                                                                              }
                                                                          case /* Head */3 :
                                                                              var attribute$1 = param[0];
                                                                              if (attribute$1 === "classifiers") {
                                                                                return ReasonReact.element(attribute$1, undefined, FixedAttributeCard$BsConsole.make(undefined, I18N$BsConsole.get(undefined, "Classifiers"), Caml_option.some(ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* wrap */-822134326, undefined, [ReasonReact.element(undefined, undefined, Renderer$BsConsole.make(param[1], attribute$1, handleAddFilters, handleChangeUrl, undefined, undefined, undefined, undefined, undefined, undefined, []))]))), []));
                                                                              } else {
                                                                                return null;
                                                                              }
                                                                          default:
                                                                            return null;
                                                                        }
                                                                      })),
                                                                tmp$3,
                                                                Belt_List.toArray(Belt_List.mapWithIndex(stats, (function (_i, attribute) {
                                                                            return ReasonReact.element(attribute, undefined, AttributeDetailCard$BsConsole.make(attribute, config, aperture, stats, projectName, handleChangeUrl, getter, handleAddFilters, undefined, /* Identity */0, token, group, handleTask, []));
                                                                          })))
                                                              ])))
                                                ])), TeamsUtils$BsConsole.isGuest(config) ? null : ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, BpgTask$BsConsole.Users.fetchAll, undefined, (function (usersRemote, param) {
                                                      if (typeof usersRemote === "number" || usersRemote.tag || commentsServiceUrl === undefined) {
                                                        return null;
                                                      } else {
                                                        return ReasonReact.element(undefined, undefined, ActivityFeed$BsConsole.make(commentsServiceUrl, fingerprint, project.pid, token, config.user, usersRemote[0][0], undefined, undefined, []));
                                                      }
                                                    }))));
                                  }
                                  return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, TriageTopBar$BsConsole.Re.make(config, (function (filter) {
                                                        var aperture$1 = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture);
                                                        return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                      projectName,
                                                                      Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture$1), undefined, stats, undefined, undefined, undefined, undefined, undefined),
                                                                      undefined
                                                                    ]));
                                                      }), triageGroup, fetchIssues, aperture, triageGroup.fingerprint, project, undefined, [])), React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.alignItems(/* stretch */-162316795),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }, React.createElement("div", {
                                                      className: Css.style(/* :: */[
                                                            Css.width(Css.pct(100)),
                                                            /* [] */0
                                                          ])
                                                    }, React.createElement("div", {
                                                          className: Css.style(/* :: */[
                                                                Css.paddingLeft(Css.rem(1)),
                                                                /* :: */[
                                                                  Css.paddingRight(Css.rem(1)),
                                                                  /* :: */[
                                                                    Css.width(Css.pct(100)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        }, React.createElement("div", {
                                                              className: tabsToolbar
                                                            }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, tab ? 1 : 0, {
                                                                      root: Css.style(/* :: */[
                                                                            Css.alignSelf(Css.flexEnd),
                                                                            /* [] */0
                                                                          ]),
                                                                      indicator: indicator
                                                                    }, (function (param, tab) {
                                                                        var tab$prime = tab !== 0 && tab === 1 ? /* SimilarityTab */1 : /* OverviewTab */0;
                                                                        return Curry._1(handleSetDetailsTab, tab$prime);
                                                                      }), undefined, undefined, undefined, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Overview")), undefined, [])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(React.createElement(React.Fragment, undefined, tmp)), undefined, []))
                                                                    ])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                                      isMerged ? ReasonReact.element(undefined, undefined, InboxUnmergeButton$BsConsole.make(projectName, token, /* :: */[
                                                                                  fingerprint,
                                                                                  /* [] */0
                                                                                ], (function (param) {
                                                                                    var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
                                                                                    var filters = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
                                                                                            return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== "fingerprint";
                                                                                          }), __x);
                                                                                    var aperture$1 = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
                                                                                    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                                                  projectName,
                                                                                                  Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture$1), undefined, stats, undefined, undefined, undefined, undefined, undefined),
                                                                                                  undefined
                                                                                                ]));
                                                                                  }), Css.style(/* :: */[
                                                                                      Css.marginRight(Css.rem(1)),
                                                                                      /* [] */0
                                                                                    ]), [])) : null,
                                                                      tab ? (
                                                                          similarityEndpointMaybe !== undefined && isSimilarityServiceVersionValid !== undefined && isSimilarityServiceVersionValid ? ReasonReact.element(undefined, undefined, SimilarityTabActions$BsConsole.Re.make((function (threshold, truncate, intersection, distance, limit) {
                                                                                        Curry._1(send, /* SetSelectedSimilarityCandidates */Block.__(4, [[]]));
                                                                                        return Curry._1(send, /* GetSimilarityCandidates */Block.__(3, [
                                                                                                      similarityEndpointMaybe,
                                                                                                      projectName,
                                                                                                      triageGroup.fingerprint,
                                                                                                      limit,
                                                                                                      threshold,
                                                                                                      truncate,
                                                                                                      intersection,
                                                                                                      distance,
                                                                                                      true
                                                                                                    ]));
                                                                                      }), [])) : null
                                                                        ) : ReasonReact.element(undefined, undefined, UiStats$BsConsole.make(stats, (function (stats) {
                                                                                    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                                                  projectName,
                                                                                                  Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, stats, undefined, undefined, undefined, undefined, undefined),
                                                                                                  undefined
                                                                                                ]));
                                                                                  }), describeRemote, token, config, []))
                                                                    ]))), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, Css.style(/* :: */[
                                                                      Css.marginBottom(Css.rem(1)),
                                                                      /* [] */0
                                                                    ]), undefined, [])), React.createElement("div", undefined, tmp$1)))));
                                })));
              } else {
                return null;
              }
            }),
          initialState: (function (param) {
              return {
                      useProcessedCallsack: true,
                      renderLimit: 500,
                      candidateDataMaybe: undefined,
                      selectedSimilarityCandidates: [],
                      loading: true,
                      similarityLoading: true,
                      isSimilarityServiceVersionValid: undefined,
                      supportsTickets: false
                    };
            }),
          retainedProps: {
            triageGroup: triageGroup
          },
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* RenderMore */0) {
                  return /* Update */Block.__(0, [{
                              useProcessedCallsack: state.useProcessedCallsack,
                              renderLimit: state.renderLimit + 250 | 0,
                              candidateDataMaybe: state.candidateDataMaybe,
                              selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                              loading: state.loading,
                              similarityLoading: state.similarityLoading,
                              isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                              supportsTickets: state.supportsTickets
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              useProcessedCallsack: state.useProcessedCallsack,
                              renderLimit: state.renderLimit,
                              candidateDataMaybe: state.candidateDataMaybe,
                              selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                              loading: false,
                              similarityLoading: false,
                              isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                              supportsTickets: state.supportsTickets
                            }]);
                }
              }
              switch (action.tag | 0) {
                case /* SetNormalizedCallstack */0 :
                    return /* Update */Block.__(0, [{
                                useProcessedCallsack: action[0],
                                renderLimit: state.renderLimit,
                                candidateDataMaybe: state.candidateDataMaybe,
                                selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                                loading: state.loading,
                                similarityLoading: state.similarityLoading,
                                isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                                supportsTickets: state.supportsTickets
                              }]);
                case /* SetSimilarityVersionValidity */1 :
                    var bool = action[0];
                    return /* Update */Block.__(0, [{
                                useProcessedCallsack: state.useProcessedCallsack,
                                renderLimit: state.renderLimit,
                                candidateDataMaybe: state.candidateDataMaybe,
                                selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                                loading: bool === false ? false : state.loading,
                                similarityLoading: bool === false ? false : state.similarityLoading,
                                isSimilarityServiceVersionValid: bool,
                                supportsTickets: action[1]
                              }]);
                case /* SetSimilarityCandidates */2 :
                    var match = action[0].results;
                    return /* Update */Block.__(0, [{
                                useProcessedCallsack: state.useProcessedCallsack,
                                renderLimit: state.renderLimit,
                                candidateDataMaybe: match && !match[1] ? match[0] : undefined,
                                selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                                loading: false,
                                similarityLoading: false,
                                isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                                supportsTickets: state.supportsTickets
                              }]);
                case /* GetSimilarityCandidates */3 :
                    var isUserInvokedComputation = action[8];
                    var distance = action[7];
                    var intersection = action[6];
                    var truncate = action[5];
                    var threshold = action[4];
                    var limit = action[3];
                    var fingerprint = action[2];
                    var project = action[1];
                    var endpoint = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                useProcessedCallsack: state.useProcessedCallsack,
                                renderLimit: state.renderLimit,
                                candidateDataMaybe: state.candidateDataMaybe,
                                selectedSimilarityCandidates: state.selectedSimilarityCandidates,
                                loading: state.loading,
                                similarityLoading: true,
                                isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                                supportsTickets: state.supportsTickets
                              },
                              (function (self) {
                                  var arg = SimilarityCandidate$BsConsole.fetchCandidates(endpoint, project, fingerprint, limit, threshold, truncate, intersection, distance);
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (err) {
                                                if (err.tag) {
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                      /* String_literal */Block.__(11, [
                                                                          "Error retrieving similarity results: ",
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ]),
                                                                      "Error retrieving similarity results: %s"
                                                                    ]), Task2$BsConsole.renderError(undefined, err[0])));
                                                }
                                                var match = err[0];
                                                if (isUserInvokedComputation) {
                                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Similarity_Compute */91]));
                                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Similarity results successfully computed"));
                                                }
                                                if (match[0] === 204) {
                                                  return ;
                                                } else {
                                                  return Curry._1(self.send, /* SetSimilarityCandidates */Block.__(2, [match[1]]));
                                                }
                                              }));
                                })
                            ]);
                case /* SetSelectedSimilarityCandidates */4 :
                    return /* Update */Block.__(0, [{
                                useProcessedCallsack: state.useProcessedCallsack,
                                renderLimit: state.renderLimit,
                                candidateDataMaybe: state.candidateDataMaybe,
                                selectedSimilarityCandidates: action[0],
                                loading: state.loading,
                                similarityLoading: state.similarityLoading,
                                isSimilarityServiceVersionValid: state.isSimilarityServiceVersionValid,
                                supportsTickets: state.supportsTickets
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (r) {
        return make(r.triageGroup, r.fetchIssues, r.projectName, r.token, r.config, r.columns, r.group, r.handleTask, r.stats, r.tab, r.handleChangeUrl, r.aperture, r.handleAddFilters, r.handleSetDetailsTab, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.Style = Style;
exports.attributeUniq = attributeUniq;
exports.getUniqueFoldAttributes = getUniqueFoldAttributes;
exports.removeAttributeFromFold = removeAttributeFromFold;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* callstackPanel Not a pure module */
