// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Icon$BsConsole = require("../../../bindings/Icon.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Column$BsConsole = require("../../../utilities/Column.js");
var Styles$BsConsole = require("../../../utilities/Styles.js");
var MuiIcons$BsConsole = require("../../../MuiIcons.js");
var Backtrace$BsConsole = require("../../../bindings/Backtrace.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");

var header = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey2)),
          /* [] */0
        ]
      ]
    ]);

var tooltipContainer = Css.style(/* :: */[
      Css.width(Css.px(13)),
      /* :: */[
        Css.height(Css.px(13)),
        /* :: */[
          Css.marginLeft(Css.px(-2)),
          /* :: */[
            Css.marginRight(Css.px(2)),
            /* :: */[
              Css.marginTop(Css.px(1)),
              /* :: */[
                Css.fontSize(Css.px(13)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Classes = {
  header: header,
  tooltipContainer: tooltipContainer
};

var headerStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey2)),
          /* [] */0
        ]
      ]
    ]);

var paginationPlaceholder = React.createElement("div", {
      className: Css.merge(/* :: */[
            headerStyle,
            /* :: */[
              Css.style(/* :: */[
                    Css.width(Css.px(14)),
                    /* :: */[
                      Css.height(Css.px(14)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ])
    });

var noLogsFound = React.createElement("div", {
      className: header
    }, I18N$BsConsole.show(undefined, "No logs found."));

var component = ReasonReact.reducerComponentWithRetainedProps("SymbolServers_Logs-BsConsole");

function make(serverId, token, endpoint, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              if (serverId === self.retainedProps) {
                return self.state;
              }
              var init = self.state;
              return {
                      page: 0,
                      filter: init.filter,
                      remoteFilter: init.remoteFilter
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var page = match.page;
              var showNext = function (send, page) {
                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                  }, I18N$BsConsole.show(undefined, "View next page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.width(Css.px(14)),
                                            /* :: */[
                                              Css.height(Css.px(14)),
                                              /* :: */[
                                                Css.cursor(/* pointer */-786317123),
                                                /* [] */0
                                              ]
                                            ]
                                          ]) + " smaller-icon",
                                      onClick: (function (param) {
                                          return Curry._1(send, /* UpdatePage */Block.__(0, [page + 1 | 0]));
                                        })
                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
              };
              var showPrevious = function (send, page) {
                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                  }, I18N$BsConsole.show(undefined, "View previous page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.cursor(/* pointer */-786317123),
                                            /* [] */0
                                          ]) + " smaller-icon",
                                      onClick: (function (param) {
                                          return Curry._1(send, /* UpdatePage */Block.__(0, [page - 1 | 0]));
                                        })
                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
              };
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.height(Css.pct(100)),
                                  /* [] */0
                                ]), [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, Css.style(/* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.justifyContent(/* flexStart */662439529),
                                              /* :: */[
                                                Css.marginBottom(Css.px(18)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]), [React.createElement("form", {
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* [] */0
                                                  ]),
                                              onSubmit: (function (e) {
                                                  e.preventDefault();
                                                  return Curry._1(send, /* SetRemoteFilterValue */0);
                                                })
                                            }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* nowrap */867913355, undefined, [
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                                undefined,
                                                                Css.style(/* :: */[
                                                                      Css.width(Css.pct(100)),
                                                                      /* :: */[
                                                                        Css.marginRight(Css.px(12)),
                                                                        /* :: */[
                                                                          Css.marginLeft(Css.px(0)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]),
                                                                /* `String */[
                                                                  -976970511,
                                                                  Belt_Option.getWithDefault(match.filter, "")
                                                                ],
                                                                (function ($$event) {
                                                                    var value = $$event.target.value;
                                                                    return Curry._1(send, /* SetFilterValue */Block.__(1, [value]));
                                                                  }),
                                                                I18N$BsConsole.get(undefined, "Filter by log text..."),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                []
                                                              ])),
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                /* Small */311976103,
                                                                Css.style(/* :: */[
                                                                      Css.width(Css.px(70)),
                                                                      /* [] */0
                                                                    ]),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                /* Outlined */-28821822,
                                                                /* Primary */-791844958,
                                                                "submit",
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Filter")]
                                                              ]))
                                                    ])))])),
                              ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, SymbolServer_API$BsConsole.getSymbolServerLogs(serverId, endpoint, match.remoteFilter, page, 50, undefined), undefined, (function (remote, _refetch) {
                                          if (typeof remote === "number") {
                                            if (remote === /* NotAsked */0) {
                                              return null;
                                            } else {
                                              return React.createElement("div", {
                                                          className: header
                                                        }, I18N$BsConsole.show(undefined, "Loading..."));
                                            }
                                          }
                                          if (remote.tag) {
                                            var err = remote[0];
                                            var errorMessage;
                                            if (err[0] === Task2$BsConsole.CoronerError || err[0] === Task2$BsConsole.Httpd) {
                                              errorMessage = err[1].message;
                                            } else if (err[0] === Task2$BsConsole.JsonDecodeError) {
                                              var message = err[1];
                                              errorMessage = message !== undefined ? message : "JsonDecodeError";
                                            } else {
                                              errorMessage = err[0] === Task2$BsConsole.UncaughtPromiseError ? Belt_Option.getWithDefault(JSON.stringify(err[1][0]), "UncaughtPromiseError") : (
                                                  err[0] === Task2$BsConsole.ErrorMsg ? err[1] : "UnknownError"
                                                );
                                            }
                                            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SymbolServers_Logs/Task2.Fetch/SymbolServer_API.getSymbolServerLogs", Backtrace$BsConsole.Attributes.make(undefined));
                                            Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                  288368849,
                                                  errorMessage
                                                ]);
                                            return React.createElement("div", {
                                                        className: header
                                                      }, I18N$BsConsole.show(undefined, "Failed to load logs."));
                                          }
                                          var match = remote[0][0];
                                          var logs = match[0];
                                          if (Belt_List.length(logs) <= 0) {
                                            return noLogsFound;
                                          }
                                          var total = match[1];
                                          return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, Css.style(/* :: */[
                                                                  Css.display(/* flex */-1010954439),
                                                                  /* :: */[
                                                                    Css.justifyContent(/* spaceBetween */516682146),
                                                                    /* :: */[
                                                                      Css.marginBottom(Css.px(18)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]), [])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                  Css.padding(Css.px(10)),
                                                                  /* :: */[
                                                                    Css.margin(Css.px(-10)),
                                                                    /* :: */[
                                                                      Css.background(Css.hex(Colors$BsConsole.npDarkGrey)),
                                                                      /* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey610)),
                                                                        /* :: */[
                                                                          Css.borderRadius(Css.px(2)),
                                                                          /* :: */[
                                                                            Css.height(Css.pct(100)),
                                                                            /* :: */[
                                                                              Css.overflowY(Css.scroll),
                                                                              /* [] */0
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]), [
                                                              Belt_Array.map(Belt_List.toArray(logs), (function (log) {
                                                                      var match = log.type_;
                                                                      var tmp;
                                                                      var exit = 0;
                                                                      if (match !== 0) {
                                                                        switch (match - 1 | 0) {
                                                                          case /* Info */0 :
                                                                              tmp = React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          tooltipContainer,
                                                                                          /* :: */[
                                                                                            Css.style(/* :: */[
                                                                                                  Css.color(Css.hex(Colors$BsConsole.orange)),
                                                                                                  /* [] */0
                                                                                                ]),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "warning", [])));
                                                                              break;
                                                                          case /* Warning */1 :
                                                                              tmp = React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          tooltipContainer,
                                                                                          /* :: */[
                                                                                            Css.style(/* :: */[
                                                                                                  Css.color(Css.hex(Colors$BsConsole.red)),
                                                                                                  /* [] */0
                                                                                                ]),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "error", [])));
                                                                              break;
                                                                          case /* Error */2 :
                                                                              exit = 1;
                                                                              break;
                                                                          
                                                                        }
                                                                      } else {
                                                                        exit = 1;
                                                                      }
                                                                      if (exit === 1) {
                                                                        tmp = React.createElement("div", {
                                                                              className: Css.merge(/* :: */[
                                                                                    tooltipContainer,
                                                                                    /* :: */[
                                                                                      Css.style(/* :: */[
                                                                                            Css.color(Css.hex(Colors$BsConsole.teal)),
                                                                                            /* [] */0
                                                                                          ]),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ])
                                                                            }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "info", [])));
                                                                      }
                                                                      return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                      tmp,
                                                                                      React.createElement("div", {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.fontSize(Css.px(14)),
                                                                                                  /* :: */[
                                                                                                    Css.fontFamily("Inconsolata"),
                                                                                                    /* :: */[
                                                                                                      Css.marginBottom(Css.px(5)),
                                                                                                      /* :: */[
                                                                                                        Css.textIndent(Css.em(1)),
                                                                                                        /* :: */[
                                                                                                          Css.marginLeft(Css.em(-1)),
                                                                                                          /* :: */[
                                                                                                            Css.wordWrap(Css.breakWord),
                                                                                                            /* :: */[
                                                                                                              Css.wordBreak(Css.breakAll),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ])
                                                                                          }, React.createElement("span", undefined, I18N$BsConsole.showSkip("[" + (log.creationDate + "]"))), React.createElement("span", {
                                                                                                className: Css.style(/* :: */[
                                                                                                      Css.paddingLeft(Css.px(7)),
                                                                                                      /* [] */0
                                                                                                    ])
                                                                                              }, I18N$BsConsole.showSkip(log.message)))
                                                                                    ]));
                                                                    })),
                                                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                            Css.display(/* flex */-1010954439),
                                                                            /* :: */[
                                                                              Css.justifyContent(/* spaceBetween */516682146),
                                                                              /* :: */[
                                                                                Css.width(Css.px(130)),
                                                                                /* :: */[
                                                                                  Css.marginTop(Css.px(15)),
                                                                                  /* :: */[
                                                                                    Css.marginRight(Css.px(115)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]), [
                                                                        page !== 0 ? showPrevious(send, page) : paginationPlaceholder,
                                                                        total === 0 ? null : React.createElement("div", undefined, I18N$BsConsole.showSkip(String(page + 1 | 0) + (" / " + String(total)))),
                                                                        total === 0 || (page + 1 | 0) === total ? paginationPlaceholder : showNext(send, page)
                                                                      ]))
                                                            ])));
                                        })))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      page: 0,
                      filter: undefined,
                      remoteFilter: undefined
                    };
            }),
          retainedProps: serverId,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            page: 0,
                            filter: state.filter,
                            remoteFilter: state.filter
                          }]);
              }
              if (!action.tag) {
                return /* Update */Block.__(0, [{
                            page: action[0],
                            filter: state.filter,
                            remoteFilter: state.remoteFilter
                          }]);
              }
              var value = action[0];
              if (value === "") {
                return /* Update */Block.__(0, [{
                            page: state.page,
                            filter: undefined,
                            remoteFilter: state.remoteFilter
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            page: state.page,
                            filter: value,
                            remoteFilter: state.remoteFilter
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var take = 50;

exports.Classes = Classes;
exports.headerStyle = headerStyle;
exports.paginationPlaceholder = paginationPlaceholder;
exports.noLogsFound = noLogsFound;
exports.take = take;
exports.component = component;
exports.make = make;
/* header Not a pure module */
