// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function Make(O) {
  var create = function (param) {
    return {
            attribute: param[0],
            operation: param[1]
          };
  };
  var toJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var toURLEncodedJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toURLEncodedJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var toPrivateJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toPrivateJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var fromJson = function (json) {
    return create(Json_decode.tuple2(Json_decode.string, O.fromJson, json));
  };
  var fromURLEncodedJson = function (json) {
    return create(Json_decode.tuple2(Json_decode.string, O.fromURLEncodedJson, json));
  };
  var getAttribute = function (t$prime) {
    var match = t$prime.attribute.split("|");
    var len = match.length;
    if (len >= 3) {
      return ;
    }
    switch (len) {
      case 0 :
      case 1 :
          return ;
      case 2 :
          var att = match[0];
          if (att.trim() === "") {
            return ;
          } else {
            return att;
          }
      
    }
  };
  var getPipe = function (t$prime) {
    var match = t$prime.attribute.split("|");
    var len = match.length;
    if (len >= 3) {
      return t$prime.attribute;
    }
    switch (len) {
      case 0 :
          return t$prime.attribute;
      case 1 :
          return match[0];
      case 2 :
          return match[1];
      
    }
  };
  var getOperation = function (t$prime) {
    return t$prime.operation;
  };
  return {
          create: create,
          toJson: toJson,
          toURLEncodedJson: toURLEncodedJson,
          toPrivateJson: toPrivateJson,
          fromJson: fromJson,
          fromURLEncodedJson: fromURLEncodedJson,
          getAttribute: getAttribute,
          getPipe: getPipe,
          getOperation: getOperation
        };
}

exports.Make = Make;
/* No side effect */
