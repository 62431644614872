// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("../../RR.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function Make(Cfg) {
  var className = function (cOpt, wsOpt, param) {
    var c = cOpt !== undefined ? cOpt : /* Black */0;
    var ws = wsOpt !== undefined ? wsOpt : /* normal */812216871;
    var color_;
    if (typeof c === "number") {
      switch (c) {
        case /* Black */0 :
            color_ = Css.hex(Colors$BsConsole.blackA87);
            break;
        case /* Grey */1 :
            color_ = Css.hex(Colors$BsConsole.blackA60);
            break;
        case /* White */2 :
            color_ = Css.hex(Colors$BsConsole.white);
            break;
        case /* LightWhite */3 :
            color_ = Css.hex(Colors$BsConsole.whiteA70);
            break;
        case /* Primary */4 :
            color_ = Css.hex(Colors$BsConsole.npDarkBlue);
            break;
        case /* Secondary */5 :
            color_ = Css.hex(Colors$BsConsole.npLightBlue);
            break;
        
      }
    } else {
      color_ = c[0];
    }
    return Css.style(/* :: */[
                Css.color(color_),
                /* :: */[
                  Css.fontSize(Css.px(Cfg.fontSize)),
                  /* :: */[
                    Css.fontWeight(Cfg.fontWeight),
                    /* :: */[
                      Css.whiteSpace(ws),
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  };
  var component = RR$BsConsole.statelessComponent("MakeText-BsConsole");
  var make = function (cn, value, color, whiteSpace, param) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (param) {
                return React.createElement("span", {
                            className: className(color, whiteSpace, undefined) + Belt_Option.mapWithDefault(cn, "", (function (cn) {
                                    return " " + cn;
                                  }))
                          }, I18N$BsConsole.showSkip(value));
              }),
            initialState: component.initialState,
            retainedProps: component.retainedProps,
            reducer: component.reducer,
            jsElementWrapped: component.jsElementWrapped
          };
  };
  var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
          return make(Caml_option.undefined_to_opt(reactProps.className), reactProps.value, Caml_option.undefined_to_opt(reactProps.color), Caml_option.undefined_to_opt(reactProps.whiteSpace), []);
        }));
  var Jsx3 = {
    make: make$1
  };
  return {
          className: className,
          component: component,
          make: make,
          Jsx3: Jsx3
        };
}

exports.Make = Make;
/* Css Not a pure module */
