// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Version$BsConsole = require("./Version.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");

var component = ReasonReact.statelessComponent("Footer-BsConsole");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var ver = window._BACKTRACE_CORONER_VERSION;
              var coronerVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;
              return React.createElement("div", {
                          style: {
                            bottom: "16px",
                            display: "flex",
                            fontSize: "16px",
                            position: "absolute",
                            width: "100%",
                            justifyContent: "center"
                          }
                        }, React.createElement("div", {
                              style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }
                            }, Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Backtrace - Console ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " - coronerd ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " - ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* Char_literal */Block.__(12, [
                                                                  /* " " */32,
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* String_literal */Block.__(11, [
                                                                          " Backtrace I/O, Inc.",
                                                                          /* End_of_format */0
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                      "Backtrace - Console %s - coronerd %s - %s %s Backtrace I/O, Inc."
                                    ]), Version$BsConsole.version, coronerVersion, "\u00A9", L10N_date_time_calendar$BsConsole.Format.numericYear(undefined, L10N_date_time$BsConsole.now(undefined)))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var consoleVersion = Version$BsConsole.version;

exports.consoleVersion = consoleVersion;
exports.component = component;
exports.make = make;
/* component Not a pure module */
