// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          endpoint: Json_decode.optional((function (param) {
                  return Json_decode.field("endpoint", Json_decode.string, param);
                }), json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                t.name
              ],
              /* :: */[
                /* tuple */[
                  "endpoint",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), t.endpoint)
                ],
                /* [] */0
              ]
            ]);
}

exports.fromJson = fromJson;
exports.toJson = toJson;
/* No side effect */
