// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Util$BsConsole = require("./util.js");
var ProjectFrontendSettings$BsConsole = require("./ProjectFrontendSettings.js");

function get(projectName, config) {
  return Belt_Option.flatMap(Belt_List.getBy(config.projectsSettings, (function (param) {
                    return param[0] === projectName;
                  })), (function (param) {
                return param[1].normBy;
              }));
}

function set(token, project, name) {
  ProjectFrontendSettings$BsConsole.reduce(token, project.pid, /* :: */[
        /* SetNormBy */Block.__(4, [name]),
        /* [] */0
      ], Util$BsConsole.identity);
  
}

exports.get = get;
exports.set = set;
/* Util-BsConsole Not a pure module */
