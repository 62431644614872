// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var ApiProject$BsConsole = require("./ApiProject.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");

var ParseError = Caml_exceptions.create("FetchProjectUsers-BsConsole.ParseError");

var UnknownError = Caml_exceptions.create("FetchProjectUsers-BsConsole.UnknownError");

function getErrorMessage(error) {
  if (error[0] === Task2$BsConsole.CoronerError) {
    return error[1].message;
  }
  if (error[0] !== ParseError) {
    return "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  var message = error[1].message;
  if (message !== undefined) {
    return "ParseError" + message;
  } else {
    return "ParseError: There was an error parsing data";
  }
}

function responseCb(onSuccess, onFailure, resp) {
  if (resp.tag) {
    return Curry._1(onFailure, getErrorMessage(resp[0]));
  } else {
    return Curry._1(onSuccess, resp[0]);
  }
}

function getTask(projectName) {
  return Task2$BsConsole.make(ApiProject$BsConsole.ListUsers.getTaskEndpoint(projectName), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var resp = ApiProject$BsConsole.ListUsers.decode(param.json);
                  return /* Ok */Block.__(0, [resp]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownError]);
                }
              }), undefined, undefined);
}

function use(token, projectName) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  React.useEffect((function () {
          var onSuccess = function (resp) {
            return Curry._1(setRemote, (function (param) {
                          return /* Success */Block.__(0, [resp]);
                        }));
          };
          var onFailure = function (msg) {
            return Curry._1(setRemote, (function (param) {
                          return /* Failure */Block.__(1, [msg]);
                        }));
          };
          if (typeof remote === "number") {
            if (remote !== 0) {
              if (CoronerdGate$BsConsole.projectUsersList) {
                var arg = getTask(projectName);
                Curry._2((function (param) {
                          return (function (param$1, param$2) {
                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                            });
                        })(token), undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              } else {
                var arg$1 = BpgTask$BsConsole.Users.fetchAll;
                Curry._2((function (param) {
                          return (function (param$1, param$2) {
                              return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                            });
                        })(token), undefined, (function (resp) {
                        if (resp.tag) {
                          var errorMessage = getErrorMessage(resp[0]);
                          return Curry._1(setRemote, (function (param) {
                                        return /* Failure */Block.__(1, [errorMessage]);
                                      }));
                        }
                        var projectUsers = Belt_Array.map(resp[0], (function (user) {
                                return {
                                        uid: user.uid,
                                        username: user.username,
                                        email: user.email
                                      };
                              }));
                        return Curry._1(setRemote, (function (param) {
                                      return /* Success */Block.__(0, [projectUsers]);
                                    }));
                      }));
              }
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  var update = function (param) {
    return Curry._1(setRemote, (function (param) {
                  return /* NotAsked */0;
                }));
  };
  return /* tuple */[
          remote,
          update
        ];
}

exports.ParseError = ParseError;
exports.UnknownError = UnknownError;
exports.getErrorMessage = getErrorMessage;
exports.responseCb = responseCb;
exports.getTask = getTask;
exports.use = use;
/* react Not a pure module */
