// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("../util.js");

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          payload: Json_decode.optional((function (param) {
                  return Json_decode.field("payload", Util$BsConsole.identity, param);
                }), json)
        };
}

exports.fromJson = fromJson;
/* Util-BsConsole Not a pure module */
