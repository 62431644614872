// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var Card = require("@material-ui/core/Card");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var Button = require("@material-ui/core/Button");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var Tooltip = require("@material-ui/core/Tooltip");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var SubTextFormatter$BsConsole = require("./SubTextFormatter.js");
var SubModifyPlanModal$BsConsole = require("./SubModifyPlanModal.js");
var CardContent = require("@material-ui/core/CardContent");

function SubCurrentPlanBanner(Props) {
  var config = Props.config;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var subscription = Props.subscription;
  var subscribedPlanInfo = Props.subscribedPlanInfo;
  var plans = Props.plans;
  var loadingLimits = Props.loadingLimits;
  var setLoadingLimits = Props.setLoadingLimits;
  var updateSubscriptions = Props.updateSubscriptions;
  var invoiceMe = Props.invoiceMe;
  var match = React.useState((function () {
          return false;
        }));
  var setOpenModal = match[1];
  var tmp;
  try {
    var userLimit = Belt_Option.getExn(ApiBillingLimits$BsConsole.getUserLimit(limits));
    var storageLimit = SubTextFormatter$BsConsole.toString(Belt_Option.getExn(ApiBillingLimits$BsConsole.getStorageLimitInBytes(limits)), /* Bytes */1);
    var limit = limits.submissions.limit;
    if (limit) {
      var errorLimit = SubTextFormatter$BsConsole.toString(limit[0], /* Millions */0);
      tmp = Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                /* String_literal */Block.__(11, [
                    "Receive up to ",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " errors and store up to ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " of data, with console access for ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* "." */46,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ]),
                "Receive up to %s errors and store up to %s of data, with console access for %s %s."
              ]), errorLimit, storageLimit, String(userLimit), userLimit === 1 ? "user" : "users");
    } else {
      tmp = Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                /* String_literal */Block.__(11, [
                    "Receive unlimited errors and store up to ",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " of data, with console access for ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* "." */46,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ]),
                "Receive unlimited errors and store up to %s of data, with console access for %s %s."
              ]), storageLimit, String(userLimit), userLimit === 1 ? "user" : "users");
    }
  }
  catch (exn){
    tmp = I18N$BsConsole.show(undefined, "Error retrieving limits for your universe.");
  }
  var tooltipTitle = SubUtil$BsConsole.getPlanModificationTooltip(config, subscription, invoiceMe, true);
  return React.createElement("div", undefined, React.createElement(Card.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(SubModifyPlanModal$BsConsole.make, {
                      openModal: match[0],
                      setOpenModal: setOpenModal,
                      updateSubscriptions: updateSubscriptions,
                      basePlans: plans,
                      subscription: subscription,
                      limits: limits,
                      refetchLimits: refetchLimits,
                      loading: loadingLimits,
                      setLoading: setLoadingLimits
                    }), React.createElement(CardContent.default, {
                      children: React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                alignItems: /* flexStart */662439529,
                                justifyContent: /* center */98248149,
                                children: null
                              }, React.createElement("div", {
                                    className: SubUtil$BsConsole.Style.titleText
                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "You are on the ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* Char_literal */Block.__(12, [
                                                        /* "." */46,
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ]),
                                            "You are on the %s."
                                          ]), subscribedPlanInfo.name)), React.createElement("div", {
                                    className: SubUtil$BsConsole.Style.bodyText
                                  }, tmp), React.createElement("div", {
                                    className: SubUtil$BsConsole.Style.bodyText
                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "The total cost of your Backtrace subscription is ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* String_literal */Block.__(11, [
                                                        ", excluding sales tax.",
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ]),
                                            "The total cost of your Backtrace subscription is %s, excluding sales tax."
                                          ]), SubPriceUtil$BsConsole.totalPriceString(subscription, plans)))), React.createElement(Tooltip.default, {
                                children: React.createElement("div", undefined, React.createElement(Button.default, {
                                          variant: "contained",
                                          color: "primary",
                                          disabled: !SubUtil$BsConsole.canModifyNemesisPlans(config) || !SubUtil$BsConsole.hasValidPaymentMethod(subscription, invoiceMe),
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.width(Css.pct(100)),
                                                  /* [] */0
                                                ])
                                          },
                                          onClick: (function (param) {
                                              NemesisMetrics$BsConsole.send(/* ViewPlansClick */0);
                                              return Curry._1(setOpenModal, (function (param) {
                                                            return true;
                                                          }));
                                            }),
                                          children: I18N$BsConsole.show(undefined, "Modify plan")
                                        })),
                                title: tooltipTitle,
                                onOpen: (function (param) {
                                    return NemesisMetrics$BsConsole.send(/* TooltipImpression */Block.__(2, [tooltipTitle]));
                                  })
                              }))
                    })));
}

var make = SubCurrentPlanBanner;

exports.make = make;
/* Css Not a pure module */
