// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function fromJson(json) {
  return Belt_Option.getWithDefault(Json_decode.field("resources", (function (param) {
                    return Json_decode.optional((function (param) {
                                  return Json_decode.array(Json_decode.string, param);
                                }), param);
                  }), json), []);
}

exports.fromJson = fromJson;
/* No side effect */
