// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var ReactDayPicker = require("react-day-picker");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var ClockSelect$BsConsole = require("./ClockSelect.js");
var L10N_date_time_clock$BsConsole = require("./L10N_date_time_clock.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");

((require('./static/ui.css')));

function make(className, numberOfMonths, onDayClick, disabledDays, initialMonth, from_, to_, children) {
  var from_$prime = Js_null_undefined.fromOption(from_);
  var to_$prime = Js_null_undefined.fromOption(to_);
  return ReasonReact.wrapJsForReason(ReactDayPicker.default, {
              className: Js_null_undefined.fromOption(className),
              numberOfMonths: Js_null_undefined.fromOption(numberOfMonths),
              onDayClick: Js_null_undefined.fromOption(onDayClick),
              disabledDays: Js_null_undefined.fromOption(disabledDays),
              selectedDays: /* tuple */[
                from_$prime,
                {
                  from: from_$prime,
                  to: to_$prime
                }
              ],
              modifiers: {
                start: from_$prime,
                end: to_$prime
              },
              initialMonth: Js_null_undefined.fromOption(initialMonth)
            }, children);
}

var ReactDatePicker = {
  make: make
};

var dateUtilsAddDayToRange__ = ((function(DateUtils) {
      return function(day, range) {
        var fromTo = DateUtils.addDayToRange(day, range);
        return [fromTo.from, fromTo.to];
      };
    }))(ReactDayPicker.DateUtils);

function addDayToRange(day, param) {
  var match = Curry._2(dateUtilsAddDayToRange__, day, {
        from: Js_null_undefined.fromOption(param[0]),
        to: Js_null_undefined.fromOption(param[1])
      });
  return /* tuple */[
          Caml_option.nullable_to_opt(match[0]),
          Caml_option.nullable_to_opt(match[1])
        ];
}

var DateSelectUtils = {
  dateUtilsAddDayToRange__: dateUtilsAddDayToRange__,
  addDayToRange: addDayToRange
};

function setHoursM(date, hours, minutes) {
  return new Date(date.setHours(hours, minutes));
}

var Time = {
  setHoursM: setHoursM
};

function mapRange(t, fn) {
  var match = t.from_;
  var match$1 = t.to_;
  if (match !== undefined && match$1 !== undefined) {
    return Caml_option.some(Curry._2(fn, Caml_option.valFromOption(match), Caml_option.valFromOption(match$1)));
  }
  
}

function updateRangeStartTime(t, hours, minutes) {
  return mapRange(t, (function (from_, to_) {
                if (Caml_obj.caml_notequal(from_, setHoursM(from_, hours, minutes))) {
                  return /* tuple */[
                          setHoursM(from_, hours, minutes),
                          to_
                        ];
                } else {
                  return /* tuple */[
                          from_,
                          to_
                        ];
                }
              }));
}

function updateRangeEndTime(t, hours, minutes) {
  return mapRange(t, (function (from_, to_) {
                if (Caml_obj.caml_notequal(to_, setHoursM(to_, hours, minutes))) {
                  return /* tuple */[
                          from_,
                          setHoursM(to_, hours, minutes)
                        ];
                } else {
                  return /* tuple */[
                          from_,
                          to_
                        ];
                }
              }));
}

var State = {
  mapRange: mapRange,
  updateRangeStartTime: updateRangeStartTime,
  updateRangeEndTime: updateRangeEndTime
};

function mergeTime(saveTheDate, saveTheTime) {
  if (saveTheTime === undefined) {
    if (saveTheDate !== undefined) {
      return Caml_option.some(Caml_option.valFromOption(saveTheDate));
    } else {
      return ;
    }
  }
  var f = Caml_option.valFromOption(saveTheTime);
  if (saveTheDate === undefined) {
    return Caml_option.some(f);
  }
  var __x = new Date(Caml_option.valFromOption(saveTheDate).setMinutes(f.getMinutes()));
  return Caml_option.some(new Date(__x.setHours(f.getHours())));
}

function alwaysBeLookingBackInTime(date) {
  return Belt_Option.map(date, (function (d) {
                if (d.valueOf() < Date.now()) {
                  return d;
                } else {
                  return new Date(Date.now());
                }
              }));
}

var timeSelectionContainer = Css.style(/* :: */[
      Css.width(/* auto */-1065951377),
      /* [] */0
    ]);

var timeDisplayContainer = Css.style(/* :: */[
      Css.margin(Css.px(8)),
      /* [] */0
    ]);

var Style = {
  timeSelectionContainer: timeSelectionContainer,
  timeDisplayContainer: timeDisplayContainer
};

var component = ReasonReact.reducerComponent("DateSelect-BsConsole");

function getHourMinute(maybeDate) {
  var arg = L10N_date_time_clock$BsConsole.Format.hourAndMinute24;
  return Belt_Option.getWithDefault(Belt_Option.map(maybeDate, (function (eta) {
                    return Curry._2(arg, undefined, eta);
                  })), "00:00");
}

function getMuiDate(strDate) {
  try {
    var match = strDate.split("-");
    if (match.length !== 3) {
      var __x = Backtrace$BsConsole.Attributes.addString("strDate", strDate, Backtrace$BsConsole.Attributes.addString("src_module", "DateSelect.DateChange.getMuiDate", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "Error parsing MUI date"
          ]);
      return new Date();
    }
    var yearStr = match[0];
    var monthStr = match[1];
    var dateStr = match[2];
    var year = Caml_format.caml_float_of_string(yearStr);
    var month = Caml_format.caml_float_of_string(monthStr) - 1.0;
    var date = Caml_format.caml_float_of_string(dateStr);
    return new Date(new Date().setFullYear(year, month, date));
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x$1 = Backtrace$BsConsole.Attributes.addString("strDate", strDate, Backtrace$BsConsole.Attributes.addString("src_module", "DateSelect.DateChange.getMuiDate", Backtrace$BsConsole.Attributes.make(undefined)));
    Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
          5049499,
          error
        ]);
    return new Date();
  }
}

function toMuiDateFormat(date) {
  return L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, date);
}

function getDateFromMuiWithTime(strDate, dateWithTime) {
  var muiDate = getMuiDate(strDate);
  return mergeTime(Caml_option.some(muiDate), dateWithTime);
}

function updateEndDate(self, newDate) {
  var match = self.state;
  return Curry._1(self.send, /* SetRange */Block.__(1, [
                match.from_,
                Caml_option.some(newDate)
              ]));
}

function updateStartDate(self, newDate) {
  var match = self.state;
  return Curry._1(self.send, /* SetRange */Block.__(1, [
                Caml_option.some(newDate),
                match.to_
              ]));
}

var DateChange = {
  getMuiDate: getMuiDate,
  toMuiDateFormat: toMuiDateFormat,
  getDateFromMuiWithTime: getDateFromMuiWithTime,
  updateEndDate: updateEndDate,
  updateStartDate: updateStartDate
};

function __send(self, param) {
  return Curry._1(self.send, /* SetRange */Block.__(1, [
                Caml_option.some(param[0]),
                Caml_option.some(param[1])
              ]));
}

function __forRange(performUpdate, self, maybe) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(maybe, performUpdate), (function (param) {
                    return __send(self, param);
                  })), undefined);
}

function forRangeStart(self, maybe) {
  return __forRange((function (param) {
                return updateRangeStartTime(self.state, param[0], param[1]);
              }), self, maybe);
}

function forRangeEnd(self, maybe) {
  return __forRange((function (param) {
                return updateRangeEndTime(self.state, param[0], param[1]);
              }), self, maybe);
}

var TimeChange = {
  __send: __send,
  __forRange: __forRange,
  forRangeStart: forRangeStart,
  forRangeEnd: forRangeEnd
};

function make$1(handleChange, range, closeParent, closeSelf, setErrorMessage, open_, maybeErrorMessageOpt, _children) {
  var maybeErrorMessage = maybeErrorMessageOpt !== undefined ? Caml_option.valFromOption(maybeErrorMessageOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Reset */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var date = Belt_Option.getWithDefault(self.state.from_, DateFns.subDays(7, new Date()));
              var date$1 = Belt_Option.getWithDefault(self.state.to_, new Date());
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              open_,
                              (function (_e) {
                                  return Curry._1(closeSelf, undefined);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [React.createElement("div", {
                                      onClick: (function (e) {
                                          if (open_) {
                                            e.stopPropagation();
                                            return ;
                                          }
                                          var match = self.state.from_;
                                          var match$1 = self.state.to_;
                                          if (match !== undefined && match$1 !== undefined && !Caml_obj.caml_equal(/* tuple */[
                                                  Caml_option.valFromOption(match),
                                                  Caml_option.valFromOption(match$1)
                                                ], range)) {
                                            return Curry._1(self.send, /* Reset */0);
                                          }
                                          
                                        })
                                    }, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Custom date range")])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceAround */-485895757, undefined, undefined, undefined, undefined, [
                                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(timeSelectionContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(timeDisplayContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            (function (e) {
                                                                                var targetVal = e.target.value;
                                                                                Belt_Option.map(getDateFromMuiWithTime(targetVal, self.state.from_), (function (date) {
                                                                                        return updateStartDate(self, date);
                                                                                      }));
                                                                                
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "date",
                                                                            /* `String */[
                                                                              -976970511,
                                                                              L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, date)
                                                                            ],
                                                                            undefined,
                                                                            undefined,
                                                                            Caml_option.some(I18N$BsConsole.show(undefined, "From")),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                                      Css.paddingTop(Css.px(16)),
                                                                      /* [] */0
                                                                    ]), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ClockSelect$BsConsole.make(undefined, getHourMinute(self.state.from_), (function (param) {
                                                                              return forRangeStart(self, param);
                                                                            }), []))]))
                                                      ])),
                                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(timeSelectionContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(timeDisplayContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            (function (e) {
                                                                                var targetVal = e.target.value;
                                                                                Belt_Option.map(getDateFromMuiWithTime(targetVal, self.state.to_), (function (date) {
                                                                                        return updateEndDate(self, date);
                                                                                      }));
                                                                                
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "date",
                                                                            /* `String */[
                                                                              -976970511,
                                                                              L10N_date_time_calendar$BsConsole.Format.numericDay(undefined, date$1)
                                                                            ],
                                                                            undefined,
                                                                            undefined,
                                                                            Caml_option.some(I18N$BsConsole.show(undefined, "To")),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                                      Css.paddingTop(Css.px(16)),
                                                                      /* [] */0
                                                                    ]), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ClockSelect$BsConsole.make(undefined, getHourMinute(self.state.to_), (function (param) {
                                                                              return forRangeEnd(self, param);
                                                                            }), []))]))
                                                      ]))
                                            ])), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row */4102650, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, make("Selectable", 2, (function (day) {
                                                                              var range = addDayToRange(day, /* tuple */[
                                                                                    self.state.from_,
                                                                                    self.state.to_
                                                                                  ]);
                                                                              var nextFrom = mergeTime(range[0], self.state.from_);
                                                                              var nextTo = mergeTime(range[1], self.state.to_);
                                                                              return Curry._1(self.send, /* SetRange */Block.__(1, [
                                                                                            nextFrom,
                                                                                            nextTo
                                                                                          ]));
                                                                            }), {
                                                                            after: DateFns.addMinutes(60, new Date())
                                                                          }, Caml_option.some(DateFns.addMonths(-1, new Date())), self.state.from_, self.state.to_, []))]))]))])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, self.state.selection === /* AllInRange */1 ? "all" : "first", (function (_event, value) {
                                                                    if (value === "all") {
                                                                      return Curry._1(self.send, /* SetSelection */Block.__(0, [/* AllInRange */1]));
                                                                    } else {
                                                                      return Curry._1(self.send, /* SetSelection */Block.__(0, [/* FirstSeenInRange */0]));
                                                                    }
                                                                  }), undefined, [
                                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                            "all",
                                                                            Caml_option.some(React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Include"), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, " all ")), I18N$BsConsole.show(undefined, "errors in range"))),
                                                                            Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          []
                                                                                        ]))),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ])),
                                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                            "first",
                                                                            Caml_option.some(React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Include only errors that were"), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, " first seen ")), I18N$BsConsole.show(undefined, "in range"))),
                                                                            Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          []
                                                                                        ]))),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))
                                                                ]))]))])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, /* Secondary */67972948, undefined, undefined, [maybeErrorMessage !== undefined ? I18N$BsConsole.showSkip(maybeErrorMessage) : null])),
                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (e) {
                                                            var match = self.state.from_;
                                                            var match$1 = self.state.to_;
                                                            if (match !== undefined && match$1 !== undefined) {
                                                              Curry._1(handleChange, /* tuple */[
                                                                    Caml_option.valFromOption(match),
                                                                    Caml_option.valFromOption(match$1),
                                                                    self.state.selection
                                                                  ]);
                                                            } else {
                                                              Curry._1(closeParent, undefined);
                                                            }
                                                            e.stopPropagation();
                                                            
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        maybeErrorMessage !== undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [I18N$BsConsole.show(undefined, "Apply")]
                                                      ]))
                                            ])))]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      from_: undefined,
                      to_: undefined,
                      selection: /* AllInRange */1
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            from_: Caml_option.some(range[0]),
                            to_: Caml_option.some(range[1]),
                            selection: state.selection
                          }]);
              }
              if (!action.tag) {
                return /* Update */Block.__(0, [{
                            from_: state.from_,
                            to_: state.to_,
                            selection: action[0]
                          }]);
              }
              var nextFrom = alwaysBeLookingBackInTime(action[0]);
              var nextTo = alwaysBeLookingBackInTime(action[1]);
              if (nextFrom === undefined) {
                return /* Update */Block.__(0, [state]);
              }
              if (nextTo === undefined) {
                return /* Update */Block.__(0, [state]);
              }
              var t = Caml_option.valFromOption(nextTo);
              if (Caml_option.valFromOption(nextFrom).getTime() < t.getTime() && t.getTime() <= Date.now()) {
                Curry._1(setErrorMessage, undefined);
                return /* Update */Block.__(0, [{
                            from_: nextFrom,
                            to_: nextTo,
                            selection: state.selection
                          }]);
              } else {
                Curry._1(setErrorMessage, "Invalid range");
                return /* Update */Block.__(0, [{
                            from_: nextFrom,
                            to_: nextTo,
                            selection: state.selection
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.ReactDatePicker = ReactDatePicker;
exports.DateSelectUtils = DateSelectUtils;
exports.Time = Time;
exports.State = State;
exports.mergeTime = mergeTime;
exports.alwaysBeLookingBackInTime = alwaysBeLookingBackInTime;
exports.Style = Style;
exports.component = component;
exports.getHourMinute = getHourMinute;
exports.DateChange = DateChange;
exports.TimeChange = TimeChange;
exports.make = make$1;
/*  Not a pure module */
