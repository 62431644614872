// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var UnityColors$BsConsole = require("./UnityColors.js");

var parent = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var imageDiv = Css.style(/* :: */[
      Css.backgroundImage(Css.url("https://res.cloudinary.com/backtrace/image/upload/v1655495588/UnityOnlyUfo.png")),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.backgroundRepeat(/* noRepeat */-695430532),
          /* :: */[
            Css.width(Css.px(400)),
            /* :: */[
              Css.height(Css.px(300)),
              /* :: */[
                Css.paddingTop(Css.px(200)),
                /* :: */[
                  Css.backgroundPosition(Css.pct(50), Css.px(0)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var header3 = Css.style(/* :: */[
      Css.color(Css.hex(UnityColors$BsConsole.grey500)),
      /* :: */[
        Css.fontSize(Css.rem(1.125)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                600
              ]),
          /* :: */[
            Css.lineHeight(Css.rem(1.5)),
            /* :: */[
              Css.margin(Css.px(0)),
              /* :: */[
                Css.letterSpacing(/* `rem */[
                      5691738,
                      -0.014
                    ]),
                /* :: */[
                  Css.fontFamily("Inter"),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var header4 = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              600
            ]),
        /* :: */[
          Css.lineHeight(Css.rem(1.5)),
          /* :: */[
            Css.margin4(Css.px(0), Css.px(0), Css.px(16), Css.px(0)),
            /* :: */[
              Css.letterSpacing(/* `rem */[
                    5691738,
                    -0.011
                  ]),
              /* :: */[
                Css.fontFamily("Inter"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var paragraph = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              600
            ]),
        /* :: */[
          Css.lineHeight(Css.rem(1.25)),
          /* :: */[
            Css.margin4(Css.px(0), Css.px(0), Css.px(16), Css.px(0)),
            /* :: */[
              Css.letterSpacing(/* `rem */[
                    5691738,
                    -0.006
                  ]),
              /* :: */[
                Css.fontFamily("Inter"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  parent: parent,
  imageDiv: imageDiv,
  header3: header3,
  header4: header4,
  paragraph: paragraph
};

function UnityErrorPage(Props) {
  var errorCode = Props.errorCode;
  var errorMessage = Props.errorMessage;
  var errorSupportText = Props.errorSupportText;
  return React.createElement("div", {
              className: parent
            }, React.createElement("div", {
                  className: imageDiv
                }, errorCode !== undefined ? React.createElement("h3", {
                        className: header3
                      }, Caml_option.valFromOption(errorCode)) : null, React.createElement("h4", {
                      className: header4
                    }, errorMessage), React.createElement("p", {
                      className: paragraph
                    }, errorSupportText)));
}

var component = ReasonReact.statelessComponent("UnityNotFound");

function make(errorCode, errorMessage, errorSupportText, children) {
  return ReasonReactCompat.wrapReactForReasonReact(UnityErrorPage, {
              errorCode: errorCode,
              errorMessage: errorMessage,
              errorSupportText: errorSupportText
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = UnityErrorPage;

exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* parent Not a pure module */
