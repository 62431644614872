// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SSAAction$BsConsole = require("./SSAAction.js");

function configurationFromJsonString(serverSideAction) {
  try {
    return Json_decode.at(/* :: */[
                  "actions",
                  /* [] */0
                ], (function (param) {
                    return Json_decode.array(SSAAction$BsConsole.fromJson, param);
                  }))(Belt_Option.getExn(Json.parse(serverSideAction.configuration)));
  }
  catch (exn){
    return Js_exn.raiseError(SSAUtils$BsConsole.invalidConfiguration);
  }
}

exports.configurationFromJsonString = configurationFromJsonString;
/* SSAUtils-BsConsole Not a pure module */
