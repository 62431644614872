// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

var $$Location = { };

function language(param) {
  return Caml_option.nullable_to_opt(window.navigator.language);
}

var $$Navigator = {
  language: language
};

exports.$$Location = $$Location;
exports.$$Navigator = $$Navigator;
/* No side effect */
