// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Nemesis$BsConsole = require("./Nemesis.js");
var BillingConstants$BsConsole = require("../BillingConstants.js");

function dataOfJson(json) {
  return {
          updated: Json_decode.field("updated", Json_decode.bool, json),
          reason: Json_decode.optional((function (param) {
                  return Json_decode.field("reason", Json_decode.string, param);
                }), json)
        };
}

function ofJson(json) {
  return {
          status: Json_decode.field("status", Json_decode.string, json),
          data: Json_decode.field("data", dataOfJson, json)
        };
}

function update(uids) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "uids",
          Json_encode.list((function (prim) {
                  return prim;
                }), uids)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/freeze")]), undefined, /* Post */Block.__(0, [json]), [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  var r = ofJson(param.json);
                  var match = r.status;
                  if (match === "OK") {
                    if (r.data.updated) {
                      return /* Ok */Block.__(0, [/* Updated */0]);
                    } else {
                      return /* Ok */Block.__(0, [/* NotUpdated */[Belt_Option.getWithDefault(r.data.reason, I18N$BsConsole.get(undefined, "unknown reason"))]]);
                    }
                  } else {
                    return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                  }
                }
                catch (exn){
                  return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                }
              }), undefined, undefined);
}

exports.dataOfJson = dataOfJson;
exports.ofJson = ofJson;
exports.update = update;
/* I18N-BsConsole Not a pure module */
