// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");

var component = ReasonReact.statelessComponent("Renderer_Tickets-BsConsole");

var tooltip = Css.style(/* :: */[
      Css.fontSize(Css.em(0.75)),
      /* :: */[
        Css.maxWidth(Css.vw(75)),
        /* [] */0
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.paddingTop(Css.px(4)),
        /* :: */[
          Css.paddingRight(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  tooltip: tooltip,
  icon: icon
};

function make(tickets, classNameOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(className, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [Belt_List.toArray(Belt_List.map(tickets, (function (ticket) {
                                          switch (ticket.tag | 0) {
                                            case /* Unlinked */0 :
                                                var match = ticket[0];
                                                var $$short = match.short;
                                                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, {
                                                                tooltip: tooltip
                                                              }, React.createElement("span", {
                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                  }, I18N$BsConsole.showSkip($$short)), undefined, undefined, 1000, 100, undefined, [React.createElement("div", {
                                                                      display: "inline-block"
                                                                    }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Nowrap */-397426005, /* Space_Between */959915471, undefined, /* Center */980392437, /* Center */980392437, [
                                                                              React.createElement("span", {
                                                                                    className: "smaller-icon " + icon
                                                                                  }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.LocalOffer.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                                                              href: match.url,
                                                                                              target: "_blank"
                                                                                            }, I18N$BsConsole.showSkip($$short))]))
                                                                            ])))]));
                                            case /* Linked */1 :
                                            case /* InteralAssigneeLink */2 :
                                                return null;
                                            
                                          }
                                        })))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var GlobalStyles;

exports.component = component;
exports.GlobalStyles = GlobalStyles;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
