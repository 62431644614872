// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var PrModal$BsConsole = require("./progressive-reveal/PrModal.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Charts__Bin$BsConsole = require("./charts/Charts__Bin.js");
var PrModalLayout$BsConsole = require("./progressive-reveal/PrModalLayout.js");

var component = RR$BsConsole.statelessComponent("BinModal-BsConsole");

function make(factorName, factorValue, onClose, attribute, data, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, PrModal$BsConsole.make(true, onClose, [ReasonReact.element(undefined, undefined, PrModalLayout$BsConsole.make(factorName, factorValue, attribute, "bin", onClose, (function (dialogWidth, dialogMaxHeight) {
                                          return ReasonReact.element(undefined, undefined, Charts__Bin$BsConsole.make(undefined, undefined, undefined, undefined, (((dialogMaxHeight - 48 | 0) - 48 | 0) - 48 | 0) - 48 | 0, dialogWidth - 48 | 0, undefined, true, false, false, undefined, undefined, undefined, undefined, undefined, undefined, data, []));
                                        })))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.factorName, reactProps.factorValue, reactProps.onClose, reactProps.attribute, reactProps.data, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
