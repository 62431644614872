// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Crdb$BsConsole = require("./crdb.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Configuration$BsConsole = require("./configuration.js");
var UniqueAggregations$BsConsole = require("./UniqueAggregations.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

function getlinkedAttributeFilters(linked_attributes, currentQueryAperture) {
  return Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, currentQueryAperture)), (function (filter) {
                        var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                        return linked_attributes.includes(filterAttribute);
                      })), (function (filter) {
                    return /* tuple */[
                            Curry._1(Crdb$BsConsole.Filter.getAttribute, filter),
                            Curry._1(Crdb$BsConsole.Filter.getOperation, filter)
                          ];
                  })));
}

function getUniqueRange(currentQuery) {
  return UniqueAggregations$BsConsole.apertureToDataPointRange(Curry._1(Crdb$BsConsole.Query.getAperture, currentQuery));
}

function getUniqueDataPointFilters(uniqueRange) {
  var end_ = uniqueRange[1];
  var duration = end_ - uniqueRange[0];
  return /* :: */[
          /* tuple */[
            "_duration",
            /* Equal */Block.__(0, [/* `Int */[
                  3654863,
                  Caml_int64.of_float(duration)
                ]])
          ],
          /* :: */[
            /* tuple */[
              "_end_timestamp",
              /* Equal */Block.__(0, [/* `Int */[
                    3654863,
                    Caml_int64.of_float(end_)
                  ]])
            ],
            /* [] */0
          ]
        ];
}

function linearizeScore(count, aperture, uniqueRange) {
  var match = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture));
  var apertureWidth = match[1].valueOf() / 1000 - match[0].valueOf() / 1000;
  var end_ = Caml_primitive.caml_float_min(uniqueRange[1], Date.now() / 1000);
  var uniqueWidth = end_ - uniqueRange[0];
  return Caml_int64.of_float(Math.floor(Caml_int64.to_float(count) * (apertureWidth / uniqueWidth)));
}

function makeUniqueAggQuery(currentQuery, uniqueAggregations, factor, uniqueRange) {
  var match = Belt_Array.get(uniqueAggregations.configuration.unique, 0);
  var match$1 = match !== undefined ? /* tuple */[
      match.unique_attribute,
      match.linked_attributes
    ] : /* tuple */[
      "guid",
      []
    ];
  var linked_attributes = match$1[1];
  var currentQueryAperture = Curry._1(Crdb$BsConsole.Query.getAperture, currentQuery);
  var aperture = Crdb$BsConsole.addFilter(Crdb$BsConsole.Aperture.$$default, Belt_List.concatMany([
            /* :: */[
              /* tuple */[
                "_name",
                /* Equal */Block.__(0, [/* `String */[
                      -976970511,
                      match$1[0]
                    ]])
              ],
              /* [] */0
            ],
            getUniqueDataPointFilters(uniqueRange),
            getlinkedAttributeFilters(linked_attributes, currentQueryAperture)
          ]));
  var factor$1 = factor && linked_attributes.includes(factor[0]) ? factor : undefined;
  return /* Aggregate */Block.__(0, [
            aperture,
            factor$1,
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "_count",
                      /* Sum */3
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function hasUniqueColumn(uniqueAggregations, query) {
  try {
    var unique = Belt_Array.getExn(uniqueAggregations.configuration.unique, 0);
    var __x = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty);
    var uniqeAggIndex = Belt_Option.getWithDefault(Curry._3(Crdb$BsConsole.Fold.getAttributeAggregateIndex, unique.unique_attribute, "unique", __x), -1);
    return uniqeAggIndex > -1;
  }
  catch (exn){
    return false;
  }
}

function getCountFromRows(rows) {
  return Belt_List.reduce(rows, 0, (function (acc, currentRow) {
                var rowCount = Belt_List.reduce(currentRow, 0, (function (acc$prime, param) {
                        var value = param[1];
                        if (param[0] !== "_count") {
                          return acc$prime;
                        }
                        if (value === undefined) {
                          return acc$prime;
                        }
                        if (value.tag) {
                          return acc$prime;
                        }
                        var match = value[0];
                        if (typeof match === "number" || match[0] !== 365180284) {
                          return acc$prime;
                        } else {
                          return acc$prime + match[1];
                        }
                      }));
                return acc + rowCount;
              }));
}

function useCount(query, projectName, config, token, factor) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setUniqueCountRemote = match[1];
  var uniqueCountQueryRemote = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setUniqueAggregation = match$1[1];
  var uniqueAggregation = match$1[0];
  var match$2 = React.useState((function () {
          return query;
        }));
  var setQuery = match$2[1];
  var query$prime = match$2[0];
  var match$3 = React.useState((function () {
          return UniqueAggregations$BsConsole.apertureToDataPointRange(Curry._1(Crdb$BsConsole.Query.getAperture, query));
        }));
  var setUniqueRange = match$3[1];
  var uniqueRange = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setUniqueCountData = match$4[1];
  var uniqueAggregationsRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.UniqueAggregations.fetchAll, undefined);
  var project = Belt_Option.getExn(Configuration$BsConsole.getProject(projectName, config));
  React.useEffect((function () {
          if (typeof uniqueAggregationsRemote !== "number" && !uniqueAggregationsRemote.tag) {
            var uniqueAggregation = Belt_Array.getBy(uniqueAggregationsRemote[0][0], (function (u) {
                    return u.project === project.pid;
                  }));
            if (uniqueAggregation !== undefined) {
              Curry._1(setUniqueAggregation, (function (param) {
                      return uniqueAggregation;
                    }));
            }
            
          }
          
        }), [uniqueAggregationsRemote]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            return Curry._1(setUniqueCountRemote, (function (param) {
                          return /* Success */Block.__(0, [resp]);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setUniqueCountRemote, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          if (typeof uniqueCountQueryRemote === "number") {
            if (uniqueCountQueryRemote === /* NotAsked */0) {
              if (uniqueAggregation !== undefined && hasUniqueColumn(uniqueAggregation, query$prime)) {
                Curry._1(setUniqueCountRemote, (function (param) {
                        return /* Loading */1;
                      }));
              }
              
            } else if (uniqueAggregation !== undefined && uniqueRange !== undefined) {
              var query$1 = makeUniqueAggQuery(query, uniqueAggregation, factor, uniqueRange);
              var arg = QueryPaginationTask$BsConsole.UniqueAggregationsAggregate.makeTask(query$1, project.name, undefined, 0);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return QueryPaginationTask$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          } else if (!uniqueCountQueryRemote.tag && uniqueAggregation !== undefined && uniqueRange !== undefined) {
            var match = uniqueCountQueryRemote[0];
            var rows = match[1];
            var columns = match[0];
            var match$1 = Belt_Array.get(uniqueAggregation.configuration.unique, 0);
            var match$2 = match$1 !== undefined ? /* tuple */[
                match$1.unique_attribute,
                match$1.linked_attributes
              ] : /* tuple */[
                "guid",
                []
              ];
            var unique_attribute = match$2[0];
            var currentAperture = Curry._1(Crdb$BsConsole.Query.getAperture, query$prime);
            var count;
            var exit = 0;
            if (factor) {
              var attribute = factor[0];
              if (match$2[1].includes(attribute)) {
                var scores = Belt_List.toArray(Belt_List.keepMap(Belt_List.map(rows, (function (group) {
                                var groupName = group.factor.value;
                                var getter = function (param, param$1) {
                                  return Group$BsConsole.getAggregation(columns, group, param, param$1);
                                };
                                var match = Group$BsConsole.unwrapSum(getter("_count", /* Sum */7));
                                if (match === undefined) {
                                  return ;
                                }
                                if (match.tag) {
                                  return ;
                                }
                                var match$1 = match[0];
                                if (typeof match$1 === "number") {
                                  return ;
                                }
                                if (match$1[0] !== 365180284) {
                                  return ;
                                }
                                var count = linearizeScore(Caml_int64.of_float(match$1[1]), currentAperture, uniqueRange);
                                return {
                                        attribute: attribute,
                                        groupValue: groupName,
                                        count: count
                                      };
                              })), (function (s) {
                            return s;
                          })));
                count = /* Grouped */Block.__(1, [scores]);
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              var group = Belt_List.get(rows, 0);
              if (group !== undefined) {
                var getter = function (param, param$1) {
                  return Group$BsConsole.getAggregation(columns, group, param, param$1);
                };
                var match$3 = Group$BsConsole.unwrapSum(getter("_count", /* Sum */7));
                if (match$3 !== undefined && !match$3.tag) {
                  var match$4 = match$3[0];
                  if (typeof match$4 === "number" || match$4[0] !== 365180284) {
                    count = undefined;
                  } else {
                    var count$1 = linearizeScore(Caml_int64.of_float(match$4[1]), currentAperture, uniqueRange);
                    count = /* Total */Block.__(0, [count$1]);
                  }
                } else {
                  count = undefined;
                }
              } else {
                count = undefined;
              }
            }
            var uniqueCount = Belt_Option.flatMap(count, (function (count$prime) {
                    return /* tuple */[
                            unique_attribute,
                            count$prime
                          ];
                  }));
            Curry._1(setUniqueCountData, (function (param) {
                    return uniqueCount;
                  }));
          }
          
        }), /* tuple */[
        uniqueCountQueryRemote,
        uniqueAggregation,
        query$prime
      ]);
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Query.eq, query, query$prime)) {
            var newUniqueRange = UniqueAggregations$BsConsole.apertureToDataPointRange(Curry._1(Crdb$BsConsole.Query.getAperture, query));
            Curry._1(setUniqueRange, (function (param) {
                    return newUniqueRange;
                  }));
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setUniqueCountRemote, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [query]);
  return match$4[0];
}

exports.getlinkedAttributeFilters = getlinkedAttributeFilters;
exports.getUniqueRange = getUniqueRange;
exports.getUniqueDataPointFilters = getUniqueDataPointFilters;
exports.linearizeScore = linearizeScore;
exports.makeUniqueAggQuery = makeUniqueAggQuery;
exports.hasUniqueColumn = hasUniqueColumn;
exports.getCountFromRows = getCountFromRows;
exports.useCount = useCount;
/* react Not a pure module */
