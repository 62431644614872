// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var I18N$BsConsole = require("./I18N.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Nemesis$BsConsole = require("./subscriptions/Nemesis.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Fragment$BsConsole = require("./Fragment.js");
var StripeCheckout$BsConsole = require("./react-stripe-checkout/StripeCheckout.js");
var BillingConstants$BsConsole = require("./BillingConstants.js");
var ApiPaymentsCreate$BsConsole = require("./ApiPaymentsCreate.js");

function updateNemesisPaymentMethod(stripe, coronerdToken) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "source",
          stripe.id
        ],
        /* :: */[
          /* tuple */[
            "token",
            coronerdToken
          ],
          /* [] */0
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/payments")]), undefined, /* Post */Block.__(0, [json]), [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  return /* Ok */Block.__(0, [ApiPaymentsCreate$BsConsole.ofJson(param.json)]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                }
              }), undefined, undefined);
}

function promise(url, stripeTokenObject, coronerdToken) {
  return (function(url, stripeTokenObject, coronerdToken) {
    let promise = new Promise((resolve, reject) => {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open(
        'POST',
        url+ `/payments?token=${coronerdToken}`
      );
      xmlhttp.setRequestHeader('Content-Type', 'application/json');
      xmlhttp.addEventListener('load', function() {
        resolve(JSON.parse(this.responseText));
      });
      xmlhttp.addEventListener('error', reject);
      xmlhttp.addEventListener('abort', reject);
      xmlhttp.send(
        JSON.stringify({
          source: stripeTokenObject.id,
          token: coronerdToken,
        })
      );
    })

    return promise;
})(url, stripeTokenObject, coronerdToken);
}

var error = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.error)),
      /* :: */[
        Css.marginLeft(Css.px(16)),
        /* [] */0
      ]
    ]);

var component = RR$BsConsole.reducerComponent("PaymentView");

function make(token, onResolve, nemesisOpt, children) {
  var nemesis = nemesisOpt !== undefined ? nemesisOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var response = self.state.remote;
              var tmp;
              tmp = typeof response === "number" || response.tag || response[0] ? null : React.createElement("span", {
                      className: error
                    }, I18N$BsConsole.show(undefined, "Error: please contact support"));
              return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                              ReasonReact.element(undefined, undefined, StripeCheckout$BsConsole.make(undefined, undefined, 0, (function (t) {
                                          return Curry._1(self.send, /* HandleReceiveToken */Block.__(1, [t]));
                                        }), BillingConstants$BsConsole.stripePublishable, true, true, I18N$BsConsole.get(undefined, "Submit"), [children])),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action.tag) {
                var t = action[0];
                return /* SideEffects */Block.__(1, [(function (self) {
                              if (nemesis) {
                                var arg = updateNemesisPaymentMethod(t, token);
                                return Curry._2((function (param) {
                                                return (function (param$1, param$2) {
                                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                  });
                                              })(token), undefined, (function (resp) {
                                              if (resp.tag) {
                                                return Curry._1(self.send, /* HandleResolve */Block.__(0, [/* Err */0]));
                                              }
                                              var response = resp[0];
                                              if (response !== undefined) {
                                                return Curry._1(self.send, /* HandleResolve */Block.__(0, [response]));
                                              } else {
                                                return Curry._1(self.send, /* HandleResolve */Block.__(0, [/* Err */0]));
                                              }
                                            }));
                              }
                              promise(BillingConstants$BsConsole.serverUrl, t, token).then((function (response) {
                                        var response$1 = ApiPaymentsCreate$BsConsole.ofJson(response);
                                        if (response$1 !== undefined) {
                                          Curry._1(self.send, /* HandleResolve */Block.__(0, [response$1]));
                                        } else {
                                          Curry._1(self.send, /* HandleResolve */Block.__(0, [/* Err */0]));
                                        }
                                        return Promise.resolve(undefined);
                                      })).catch((function (_error) {
                                      Curry._1(self.send, /* HandleResolve */Block.__(0, [/* Err */0]));
                                      return Promise.resolve(undefined);
                                    }));
                              
                            })]);
              }
              var response = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          remote: /* Success */Block.__(0, [response])
                        },
                        (function (_self) {
                            return Curry._1(onResolve, response);
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.token, reactProps.onResolve, Caml_option.undefined_to_opt(reactProps.nemesis), Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(reactProps.children), [], (function (c) {
                          return [c];
                        })));
      }));

var Jsx3 = {
  make: make$1
};

exports.updateNemesisPaymentMethod = updateNemesisPaymentMethod;
exports.promise = promise;
exports.error = error;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* error Not a pure module */
