// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var AppHeader$BsConsole = require("./AppHeader.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

var component = ReasonReact.statelessComponent("Page-BsConsole");

var mainBackground = IdentityVariant$BsConsole.current >= 2 && IFrame$BsConsole.isIFrame ? /* transparent */582626130 : Css.hex(Colors$BsConsole.grey75);

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.backgroundColor(mainBackground),
          /* :: */[
            Css.minHeight(Css.vh(100)),
            /* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.position(/* relative */903134412),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  mainBackground: mainBackground,
  container: container
};

function make(token, handleChangeUrl, route, shell, handleBack, handleTask, handleLogout, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          className: container
                        }, React.createElement("div", {
                              style: {
                                display: "flex",
                                flex: "1",
                                flexDirection: "column"
                              }
                            }, ReasonReact.element(undefined, undefined, AppHeader$BsConsole.make(route, shell, handleBack, handleChangeUrl, handleLogout, token, handleTask, [])), children));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
