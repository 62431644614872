// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var DescribeTask$BsConsole = require("../DescribeTask.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var SavedQueryDefaults$BsConsole = require("./SavedQueryDefaults.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var SavedQueriesProjectSettings$BsConsole = require("./SavedQueriesProjectSettings.js");

var savedQueryDataContext = React.createContext(undefined);

function makeProps(project, queriesBpg, userDefault, projectDefault, projectBookmarkedViews, userBookmarkedViews, queries, refetchUserSettings, refetchQueries, refetchProjectSettings, refetchQueriesBpg, children, param) {
  var data = {
    project: project,
    queriesBpg: queriesBpg,
    userDefault: userDefault,
    projectDefault: projectDefault,
    projectBookmarkedViews: projectBookmarkedViews,
    userBookmarkedViews: userBookmarkedViews,
    queries: queries,
    refetchUserSettings: refetchUserSettings,
    refetchQueries: refetchQueries,
    refetchProjectSettings: refetchProjectSettings,
    refetchQueriesBpg: refetchQueriesBpg
  };
  return {
          value: data,
          children: children
        };
}

var make = savedQueryDataContext.Provider;

var DataProvider = {
  makeProps: makeProps,
  make: make
};

function SavedQueriesContext$Provider(Props) {
  var projectName = Props.projectName;
  var token = Props.token;
  var config = Props.config;
  var children = Props.children;
  var projects = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Project.fetchAll, undefined);
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var attributes = React.useMemo((function () {
          if (typeof describeRemote === "number" || describeRemote.tag) {
            return /* [] */0;
          } else {
            return describeRemote[0];
          }
        }), [describeRemote]);
  var project;
  project = typeof projects === "number" || projects.tag ? undefined : Belt_Array.getBy(projects[0][0], (function (p) {
            return p.name === projectName;
          }));
  var pid = Belt_Option.map(project, (function (project) {
          return project.pid;
        }));
  var match$1 = Task2$BsConsole.useRefetchable(true, BpgTask$BsConsole.Query.fetchAll, undefined);
  var queriesBpg = match$1[0];
  var queriesBpg$1 = React.useMemo((function () {
          if (typeof queriesBpg === "number" || queriesBpg.tag || project === undefined) {
            return ;
          } else {
            return Belt_List.fromArray(Belt_Array.keep(queriesBpg[0][0], (function (q) {
                              return q.project === project.pid;
                            })));
          }
        }), /* tuple */[
        queriesBpg,
        project
      ]);
  var match$2 = SavedQueriesProjectSettings$BsConsole.use(token, pid, config, attributes, projectName);
  var userSettings = match$2[2];
  var projectSettings = match$2[0];
  var defaultSavedQueries = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.map(projectSettings, (function (settings) {
                            return SavedQueryDefaults$BsConsole.getViews(settings.versionAttribute, settings.normBy, attributes);
                          })), /* [] */0);
        }), /* tuple */[
        projectSettings,
        attributes
      ]);
  var projectDefault = React.useMemo((function () {
          return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(projectSettings, (function (settings) {
                                    return settings.defaultView;
                                  })), (function (defaultView) {
                                return defaultView.savedQueryJsonString;
                              })), Json.parse), SavedQueriesModel$BsConsole.fromJsonV1DecoderSafe);
        }), [projectSettings]);
  var userDefault = React.useMemo((function () {
          return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(userSettings, (function (settings) {
                                    return UserFrontendSettings$BsConsole.getDefaultView(projectName, settings);
                                  })), (function (defaultView) {
                                return defaultView.savedQueryJsonString;
                              })), Json.parse), SavedQueriesModel$BsConsole.fromJsonV1DecoderSafe);
        }), [userSettings]);
  var projectBookmarkedViews = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.map(projectSettings, (function (settings) {
                            return settings.bookmarkedViews;
                          })), []);
        }), [projectSettings]);
  var userBookmarkedViews = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.map(userSettings, (function (settings) {
                            return UserFrontendSettings$BsConsole.getBookmarks(projectName, settings);
                          })), []);
        }), [userSettings]);
  var match$3 = SavedQueriesModel$BsConsole.useRefetchableSavedQueries(token, projectName);
  var queries = Belt_Option.map(Belt_Option.map(match$3[0], (function (queries) {
              return Belt_List.keep(queries, (function (q) {
                            if (q.tag) {
                              return false;
                            } else {
                              return true;
                            }
                          }));
            })), (function (queries$prime) {
          return Belt_List.concat(queries$prime, defaultSavedQueries);
        }));
  return React.createElement(make, makeProps(project, queriesBpg$1, userDefault, projectDefault, projectBookmarkedViews, userBookmarkedViews, queries, match$2[3], match$3[1], match$2[1], match$1[1], children, undefined));
}

function make$1(projectName, token, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesContext$Provider, {
              projectName: projectName,
              token: token,
              config: config,
              children: children
            }, children);
}

var Jsx2 = {
  make: make$1
};

var Provider = {
  make: SavedQueriesContext$Provider,
  Jsx2: Jsx2
};

var MissingProvider = Caml_exceptions.create("SavedQueriesContext-BsConsole.MissingProvider");

function useData(param) {
  var data = React.useContext(savedQueryDataContext);
  if (data !== undefined) {
    return data;
  }
  throw MissingProvider;
}

exports.savedQueryDataContext = savedQueryDataContext;
exports.DataProvider = DataProvider;
exports.Provider = Provider;
exports.MissingProvider = MissingProvider;
exports.useData = useData;
/* savedQueryDataContext Not a pure module */
