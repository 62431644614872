// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var WfForm$BsConsole = require("./WfForm.js");
var WfParser$BsConsole = require("./WfParser.js");

var component = ReasonReact.reducerComponent("WfPlayground-BsConsole");

function make(btDefaults, token, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var remoteAction = state.remoteAction;
              var form = state.form;
              var initialFormValue = state.initialFormValue;
              var formJson = state.formJson;
              var send = param.send;
              var tmp;
              if (form !== undefined) {
                if (form.tag) {
                  tmp = React.createElement("div", {
                        style: {
                          width: "640px"
                        }
                      }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                      style: {
                                        padding: "34px"
                                      }
                                    }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Error")])), React.createElement("div", {
                                          style: {
                                            marginTop: "8px"
                                          }
                                        }, I18N$BsConsole.showSkip(WfParser$BsConsole.getUserFriendlyError(form[0]))))])));
                } else {
                  var initialFormValueJson = Json.parse(initialFormValue);
                  var json = state.submittedFormValue;
                  tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                  style: {
                                    padding: "18px",
                                    width: "900px"
                                  }
                                }, ReasonReact.element(formJson + initialFormValue, undefined, WfForm$BsConsole.make(btDefaults, token, form[0], (function (ra) {
                                            remoteAction.contents = ra;
                                            
                                          }), (function (fv) {
                                            return Curry._1(send, /* SetFormValue */Block.__(2, [fv]));
                                          }), undefined, initialFormValueJson, undefined, [])), React.createElement("div", {
                                      style: {
                                        display: "flex",
                                        paddingTop: "16px",
                                        width: "100%",
                                        justifyContent: "flex-end"
                                      }
                                    }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                              undefined,
                                              undefined,
                                              (function (param) {
                                                  return Curry._1(send, /* SubmitForm */0);
                                                }),
                                              undefined,
                                              undefined,
                                              /* Contained */-515484397,
                                              /* Primary */-791844958,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [I18N$BsConsole.show(undefined, "Submit")]
                                            ]))), json !== undefined ? React.createElement("div", {
                                        style: {
                                          paddingTop: "16px"
                                        }
                                      }, I18N$BsConsole.showSkip(JSON.stringify(Caml_option.valFromOption(json), null, 2))) : null)]));
                }
              } else {
                tmp = null;
              }
              return React.createElement("div", {
                          style: {
                            display: "flex",
                            minHeight: "100vh",
                            minWidth: "100vw",
                            padding: "64px 0",
                            alignItems: "center",
                            flexDirection: "column"
                          }
                        }, React.createElement("div", {
                              style: {
                                display: "flex",
                                width: "900px",
                                justifyContent: "space-between"
                              }
                            }, React.createElement("div", {
                                  style: {
                                    width: "480px"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                style: {
                                                  padding: "34px"
                                                }
                                              }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Your form json")]))), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                        (function (e) {
                                                            return Curry._1(send, /* UpdateFormJson */Block.__(0, [e.target.value]));
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        /* `String */[
                                                          -976970511,
                                                          formJson
                                                        ],
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                        /* `Int */[
                                                          3654863,
                                                          12
                                                        ],
                                                        []
                                                      ])))]))), React.createElement("div", {
                                  style: {
                                    width: "400px"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                style: {
                                                  padding: "34px"
                                                }
                                              }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Your initial form value")]))), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                        (function (e) {
                                                            return Curry._1(send, /* UpdateInitialFormValue */Block.__(1, [e.target.value]));
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        /* `String */[
                                                          -976970511,
                                                          initialFormValue
                                                        ],
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                        /* `Int */[
                                                          3654863,
                                                          12
                                                        ],
                                                        []
                                                      ])))])))), React.createElement("div", {
                              style: {
                                marginTop: "32px"
                              }
                            }, tmp));
            }),
          initialState: (function (param) {
              return {
                      formJson: "",
                      initialFormValue: "",
                      form: undefined,
                      remoteAction: {
                        contents: undefined
                      },
                      formValue: undefined,
                      submittedFormValue: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                var match = state.formValue;
                var match$1 = state.remoteAction.contents;
                if (match !== undefined) {
                  if (!match.tag) {
                    return /* Update */Block.__(0, [{
                                formJson: state.formJson,
                                initialFormValue: state.initialFormValue,
                                form: state.form,
                                remoteAction: state.remoteAction,
                                formValue: state.formValue,
                                submittedFormValue: Caml_option.some(match[0])
                              }]);
                  }
                  if (match$1 !== undefined) {
                    var path = match[0][0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                formJson: state.formJson,
                                initialFormValue: state.initialFormValue,
                                form: state.form,
                                remoteAction: state.remoteAction,
                                formValue: state.formValue,
                                submittedFormValue: undefined
                              },
                              (function (param) {
                                  return Curry._1(match$1, /* ScrollToError */[path]);
                                })
                            ]);
                  }
                  
                }
                return /* Update */Block.__(0, [{
                            formJson: state.formJson,
                            initialFormValue: state.initialFormValue,
                            form: state.form,
                            remoteAction: state.remoteAction,
                            formValue: state.formValue,
                            submittedFormValue: undefined
                          }]);
              }
              switch (action.tag | 0) {
                case /* UpdateFormJson */0 :
                    var formJson = action[0];
                    var form;
                    if (formJson === "") {
                      form = undefined;
                    } else {
                      try {
                        form = WfParser$BsConsole.parseFormSafe(JSON.parse(formJson));
                      }
                      catch (exn){
                        form = /* Error */Block.__(1, [/* UnknownJsonParseException */3]);
                      }
                    }
                    return /* Update */Block.__(0, [{
                                formJson: formJson,
                                initialFormValue: state.initialFormValue,
                                form: form,
                                remoteAction: state.remoteAction,
                                formValue: state.formValue,
                                submittedFormValue: undefined
                              }]);
                case /* UpdateInitialFormValue */1 :
                    return /* Update */Block.__(0, [{
                                formJson: state.formJson,
                                initialFormValue: action[0],
                                form: state.form,
                                remoteAction: state.remoteAction,
                                formValue: state.formValue,
                                submittedFormValue: state.submittedFormValue
                              }]);
                case /* SetFormValue */2 :
                    return /* Update */Block.__(0, [{
                                formJson: state.formJson,
                                initialFormValue: state.initialFormValue,
                                form: state.form,
                                remoteAction: state.remoteAction,
                                formValue: action[0],
                                submittedFormValue: state.submittedFormValue
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
