// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var WfActions$BsConsole = require("./WfActions.js");
var WfActionForm$BsConsole = require("./WfActionForm.js");
var LoadingOverlay$BsConsole = require("../../LoadingOverlay.js");

var component = RR$BsConsole.reducerComponent("WfActionsModal-BsConsole");

function make(btDefaults, token, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              WfActions$BsConsole.sub.contents = (function (action) {
                  return Curry._1(send, /* SetAction */Block.__(0, [action]));
                });
              
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var actionState = match.actionState;
              var action = match.action;
              var tmp;
              if (action !== undefined) {
                var onSuccess = action.onSuccess;
                var tmp$1;
                tmp$1 = typeof actionState === "number" || actionState.tag !== /* Failure */1 ? undefined : Caml_option.some(actionState[0]);
                tmp = ReasonReact.element(undefined, undefined, WfActionForm$BsConsole.make(btDefaults, token, tmp$1, (function (formJson) {
                            Curry._1(send, /* SetActionState */Block.__(1, [/* Loading */1]));
                            var __x = Curry._1(onSuccess, Caml_option.some(formJson));
                            __x.then((function (resp) {
                                    if (resp !== undefined) {
                                      Curry._1(send, /* SetActionState */Block.__(1, [/* Failure */Block.__(1, [Caml_option.valFromOption(resp)])]));
                                    } else {
                                      Curry._1(send, /* SetActionState */Block.__(1, [/* NotAsked */0]));
                                      Curry._1(send, /* ClearAction */0);
                                    }
                                    return Promise.resolve(undefined);
                                  }));
                            
                          }), action.form, action.initialFormValue, []));
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              Belt_Option.isSome(action),
                              (function (param) {
                                  if (action !== undefined) {
                                    Curry._1(action.onSuccess, undefined);
                                  }
                                  return Curry._1(send, /* ClearAction */0);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                React.createElement("div", {
                                      style: {
                                        overflowY: "auto",
                                        padding: "24px",
                                        width: "600px"
                                      }
                                    }, tmp),
                                typeof actionState === "number" && actionState !== 0 ? ReasonReact.element(undefined, undefined, LoadingOverlay$BsConsole.make([])) : null
                              ]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      action: undefined,
                      actionState: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            action: undefined,
                            actionState: /* NotAsked */0
                          }]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            action: state.action,
                            actionState: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            action: action[0],
                            actionState: /* NotAsked */0
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
