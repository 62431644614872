// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");

var tooltipClasses = {
  popper: Css.style(/* :: */[
        Css.opacity(1),
        /* [] */0
      ]),
  tooltip: Css.style(/* :: */[
        Css.padding(Css.zero),
        /* :: */[
          Css.background(Css.none),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey2)),
            /* [] */0
          ]
        ]
      ])
};

var paperClasses = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ])
};

var tooltipContentContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.alignItems(/* flexStart */662439529),
          /* :: */[
            Css.selector("> *:not(:last-of-type)", /* :: */[
                  Css.marginBottom(Css.px(4)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.flexGrow(1),
            /* :: */[
              Css.height(Css.px(40)),
              /* :: */[
                Css.lineHeight(Css.px(40)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipPaper = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey0)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.selector("p", /* :: */[
                  Css.padding2(Css.px(8), Css.px(12)),
                  /* :: */[
                    Css.lineHeight(Css.px(20)),
                    /* :: */[
                      Css.margin(Css.px(0)),
                      /* :: */[
                        Css.maxHeight(Css.px(250)),
                        /* :: */[
                          Css.overflowY(/* scroll */-949692403),
                          /* :: */[
                            Css.overflowX(/* hidden */-862584982),
                            /* :: */[
                              Css.wordWrap(/* breakWord */1059921449),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  tooltipClasses: tooltipClasses,
  paperClasses: paperClasses,
  tooltipContentContainer: tooltipContentContainer,
  container: container,
  tooltipPaper: tooltipPaper
};

function FormatPopover$WithCopyButton(Props) {
  var value = Props.value;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Tooltip.default, {
              classes: tooltipClasses,
              interactive: true,
              placement: "right",
              enterDelay: 100,
              children: React.createElement("div", {
                    className: Css.merge(/* :: */[
                          container,
                          /* :: */[
                            className,
                            /* [] */0
                          ]
                        ])
                  }, children),
              leaveDelay: 100,
              title: React.createElement(Paper.default, {
                    classes: {
                      root: tooltipPaper
                    },
                    children: null
                  }, React.createElement("p", undefined, I18N$BsConsole.showSkip(value)), React.createElement(Divider.default, { }), React.createElement(Button.default, {
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ])
                        },
                        onClick: (function (param) {
                            return Clipboard$BsConsole.copy(value, (function (param) {
                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Copied to clipboard."));
                                        }), undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, "Copy to clipboard")
                      }))
            });
}

var WithCopyButton = {
  make: FormatPopover$WithCopyButton
};

function FormatPopover(Props) {
  var content = Props.content;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Tooltip.default, {
              classes: tooltipClasses,
              interactive: true,
              placement: "left",
              enterDelay: 100,
              children: React.createElement("div", {
                    className: Css.merge(/* :: */[
                          container,
                          /* :: */[
                            className,
                            /* [] */0
                          ]
                        ])
                  }, children),
              leaveDelay: 100,
              title: React.createElement(Paper.default, {
                    classes: paperClasses,
                    children: React.createElement("div", {
                          className: tooltipContentContainer
                        }, content)
                  })
            });
}

var make = FormatPopover;

exports.Styles = Styles;
exports.WithCopyButton = WithCopyButton;
exports.make = make;
/* tooltipClasses Not a pure module */
