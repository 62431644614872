// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var InboxMultiTicket$BsConsole = require("../../inbox/InboxMultiTicket.js");

function BugIssueTicket(Props) {
  var anchorOrigin = Props.anchorOrigin;
  var handleWfAssociateTicket = Props.handleWfAssociateTicket;
  var workflows = Props.workflows;
  var projectName = Props.projectName;
  var target = Props.target;
  var handleAddTicket = Props.handleAddTicket;
  var tickets = Props.tickets;
  var handleRemoveTicket = Props.handleRemoveTicket;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var token = Props.token;
  var fetchIssues = Props.fetchIssues;
  var selectedFingerprints = Props.selectedFingerprints;
  return ReasonReact.element(undefined, undefined, InboxMultiTicket$BsConsole.make(anchorOrigin, undefined, handleWfAssociateTicket, workflows, projectName, target, fetchIssues, handleAddTicket, tickets, handleRemoveTicket, handleChangeUrl, config, token, selectedFingerprints, undefined, []));
}

var make = BugIssueTicket;

exports.make = make;
/* ReasonReact Not a pure module */
