// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Input = require("@material-ui/core/Input");
var Paper = require("@material-ui/core/Paper");
var Popper = require("@material-ui/core/Popper");
var Check = require("@material-ui/icons/Check");
var Search = require("@material-ui/icons/Search");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var ListItemText = require("@material-ui/core/ListItemText");
var ClickAwayListener = require("@material-ui/core/ClickAwayListener");

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* [] */0
        ]
      ]
    ]);

var menuItemClasses = {
  root: Css.style(/* :: */[
        Css.paddingLeft(Css.px(45)),
        /* :: */[
          Css.paddingRight(Css.px(45)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.hover(/* :: */[
                      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.focus(/* :: */[
                        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ])
};

var noProjectsFound = {
  root: Css.style(/* :: */[
        Css.padding2(Css.px(11), Css.px(45)),
        /* [] */0
      ])
};

var currentListItemText = {
  root: Css.style(/* :: */[
        Css.padding(Css.px(0)),
        /* [] */0
      ]),
  primary: Css.style(/* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* [] */0
        ]
      ])
};

var highlightedMenuItem = {
  root: Css.style(/* :: */[
        Css.paddingLeft(Css.px(45)),
        /* :: */[
          Css.paddingRight(Css.px(45)),
          /* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.opacity(1),
              /* :: */[
                Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                /* [] */0
              ]
            ]
          ]
        ]
      ])
};

var currentMenuItemClasses = {
  root: Css.style(/* :: */[
        Css.paddingRight(Css.px(45)),
        /* :: */[
          Css.paddingLeft(Css.px(0)),
          /* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.opacity(1),
              /* :: */[
                Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                /* [] */0
              ]
            ]
          ]
        ]
      ])
};

var Style = {
  iconContainer: iconContainer,
  menuItemPadding: 45,
  menuItemClasses: menuItemClasses,
  noProjectsFound: noProjectsFound,
  currentListItemText: currentListItemText,
  highlightedMenuItem: highlightedMenuItem,
  currentMenuItemClasses: currentMenuItemClasses
};

function BtSearchSelect(Props) {
  var anchorEl = Props.anchorEl;
  var open_ = Props.open_;
  var selectedValue = Props.selectedValue;
  var considerations = Props.considerations;
  var placeholder = Props.placeholder;
  var onSelectionChange = Props.onSelectionChange;
  var setMenuOpen = Props.setMenuOpen;
  var match = React.useState((function () {
          return "";
        }));
  var setInputValue = match[1];
  var inputValue = match[0];
  var match$1 = React.useState((function () {
          return considerations;
        }));
  var setFilteredConsiderations = match$1[1];
  var filteredConsiderations = match$1[0];
  var match$2 = React.useState((function () {
          return 0;
        }));
  var setHighlightedRow = match$2[1];
  var highlightedRow = match$2[0];
  var handleKeyDown = React.useCallback((function ($$event) {
          var keyName = $$event.key;
          switch (keyName) {
            case "" :
                return ;
            case "ArrowDown" :
                $$event.stopPropagation();
                return Curry._1(setHighlightedRow, (function (prevIdx) {
                              if (prevIdx >= Belt_List.length(filteredConsiderations)) {
                                return Belt_List.length(filteredConsiderations) - 1 | 0;
                              } else {
                                return prevIdx + 1 | 0;
                              }
                            }));
            case "ArrowUp" :
                $$event.stopPropagation();
                return Curry._1(setHighlightedRow, (function (prevIdx) {
                              if (prevIdx <= 0) {
                                return 0;
                              } else {
                                return prevIdx - 1 | 0;
                              }
                            }));
            case "Enter" :
                $$event.stopPropagation();
                Belt_Option.map(Belt_List.get(filteredConsiderations, highlightedRow), (function (proj) {
                        Curry._1(onSelectionChange, proj);
                        Curry._1(setInputValue, (function (param) {
                                return "";
                              }));
                        return Curry._1(setMenuOpen, false);
                      }));
                return ;
            case "Escape" :
                Curry._1(setInputValue, (function (param) {
                        return "";
                      }));
                return Curry._1(setMenuOpen, false);
            default:
              return ;
          }
        }), /* tuple */[
        setMenuOpen,
        filteredConsiderations,
        highlightedRow
      ]);
  React.useEffect((function () {
          var nextConsiderations = inputValue.trim() !== "" ? Belt_List.fromArray(Fuzz$BsConsole.fuzz(inputValue, Belt_List.toArray(considerations), (function (listItem) {
                        return listItem;
                      }))) : considerations;
          Curry._1(setFilteredConsiderations, (function (param) {
                  return nextConsiderations;
                }));
          Curry._1(setHighlightedRow, (function (param) {
                  return 0;
                }));
          
        }), /* tuple */[
        inputValue,
        considerations
      ]);
  return React.createElement(Popper.default, {
              anchorEl: anchorEl,
              open: open_,
              placement: "bottom-start",
              children: React.createElement(ClickAwayListener.default, {
                    onClickAway: (function (param) {
                        Curry._1(setInputValue, (function (param) {
                                return "";
                              }));
                        return Curry._1(setMenuOpen, false);
                      }),
                    children: React.createElement(Paper.default, {
                          className: Css.style(/* :: */[
                                Css.maxHeight(Css.px(400)),
                                /* :: */[
                                  Css.maxWidth(Css.px(400)),
                                  /* :: */[
                                    Css.minWidth(Css.px(40)),
                                    /* :: */[
                                      Css.overflow(/* auto */-1065951377),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          children: React.createElement("div", {
                                onKeyDown: handleKeyDown
                              }, React.createElement(Row2$BsConsole.make, {
                                    alignItems: /* center */98248149,
                                    className: Css.style(/* :: */[
                                          Css.position(/* sticky */188263721),
                                          /* :: */[
                                            Css.top(Css.px(0)),
                                            /* :: */[
                                              Css.important(Css.backgroundColor(Css.white)),
                                              /* :: */[
                                                Css.zIndex(100),
                                                /* :: */[
                                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                                  /* :: */[
                                                    Css.padding4(Css.px(11), Css.px(0), Css.px(11), Css.px(0)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]),
                                    children: null
                                  }, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.width(Css.px(40)),
                                              /* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* :: */[
                                                    Css.justifyContent(/* center */98248149),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, React.createElement(Search.default, {
                                            className: Css.style(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.searchIcon)),
                                                  /* :: */[
                                                    Css.fontSize(Css.px(24)),
                                                    /* [] */0
                                                  ]
                                                ])
                                          })), React.createElement(Input.default, {
                                        inputRef: (function (input) {
                                            if (input == null) {
                                              return ;
                                            }
                                            var domElement = ReactDom.findDOMNode(input);
                                            setTimeout((function (param) {
                                                    return domElement.focus();
                                                  }), 100);
                                            
                                          }),
                                        className: Css.style(/* :: */[
                                              Css.selector("input", /* :: */[
                                                    Css.padding4(Css.px(6), Css.px(8), Css.px(7), Css.px(0)),
                                                    /* [] */0
                                                  ]),
                                              /* [] */0
                                            ]),
                                        value: inputValue,
                                        placeholder: placeholder,
                                        onChange: (function ($$event) {
                                            var value = Belt_Option.getWithDefault(Caml_option.nullable_to_opt($$event.target.value), "");
                                            return Curry._1(setInputValue, (function (param) {
                                                          return value;
                                                        }));
                                          }),
                                        disableUnderline: true
                                      })), Belt_List.length(filteredConsiderations) > 0 ? React.createElement(MenuList.default, {
                                      children: Belt_List.toArray(Belt_List.mapWithIndex(filteredConsiderations, (function (idx, listItem) {
                                                  if (listItem === selectedValue) {
                                                    return React.createElement(MenuItem.default, {
                                                                disabled: true,
                                                                classes: currentMenuItemClasses,
                                                                value: selectedValue,
                                                                children: React.createElement(Row2$BsConsole.make, {
                                                                      alignItems: /* center */98248149,
                                                                      className: iconContainer,
                                                                      children: null
                                                                    }, React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.width(Css.px(40)),
                                                                                /* :: */[
                                                                                  Css.display(/* flex */-1010954439),
                                                                                  /* :: */[
                                                                                    Css.alignItems(/* center */98248149),
                                                                                    /* :: */[
                                                                                      Css.justifyContent(/* center */98248149),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ])
                                                                        }, React.createElement(Check.default, {
                                                                              className: Css.style(/* :: */[
                                                                                    Css.color(Css.hex(Colors$BsConsole.accent)),
                                                                                    /* :: */[
                                                                                      Css.fontSize(Css.px(24)),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ])
                                                                            })), React.createElement(ListItemText.default, {
                                                                          classes: currentListItemText,
                                                                          primary: I18N$BsConsole.showSkip(selectedValue)
                                                                        }))
                                                              });
                                                  } else {
                                                    return React.createElement(MenuItem.default, {
                                                                disableRipple: true,
                                                                classes: idx === highlightedRow ? highlightedMenuItem : menuItemClasses,
                                                                onClick: (function ($$event) {
                                                                    Curry._1(onSelectionChange, listItem);
                                                                    Curry._1(setInputValue, (function (param) {
                                                                            return "";
                                                                          }));
                                                                    return Curry._1(setMenuOpen, false);
                                                                  }),
                                                                value: listItem,
                                                                children: React.createElement(ListItemText.default, {
                                                                      classes: currentListItemText,
                                                                      primary: I18N$BsConsole.showSkip(listItem)
                                                                    }),
                                                                key: listItem + ("-" + String(idx))
                                                              });
                                                  }
                                                })))
                                    }) : React.createElement(ListItemText.default, {
                                      classes: noProjectsFound,
                                      children: I18N$BsConsole.show(undefined, "No projects found")
                                    }))
                        })
                  })
            });
}

var make = BtSearchSelect;

exports.Style = Style;
exports.make = make;
/* iconContainer Not a pure module */
