// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");

function factorFromJson(json) {
  var match = Json_decode.array(Json_decode.string, json);
  if (match.length !== 1) {
    return /* Custom */[""];
  }
  var str = match[0];
  switch (str) {
    case "NONE" :
        return /* Custom */["NONE"];
    case "fingerprint" :
    case "identity" :
        return /* Identity */0;
    default:
      return /* Custom */[str];
  }
}

function parseFold(t$prime) {
  var __x = Js_dict.entries(Json_decode.at(/* :: */[
              "fold",
              /* [] */0
            ], (function (param) {
                return Json_decode.dict((function (json) {
                              var __x = Json_decode.list((function (json) {
                                      try {
                                        return Crdb$BsConsole.Operation.fromJson(json);
                                      }
                                      catch (exn){
                                        return ;
                                      }
                                    }), json);
                              var __x$1 = Belt_List.keepMap(__x, Util$BsConsole.identity);
                              return Belt_List.map(__x$1, (function (o) {
                                            if (typeof o === "number" && o === 0) {
                                              return /* Distribution */Block.__(1, [3]);
                                            } else {
                                              return o;
                                            }
                                          }));
                            }), param);
              }))(t$prime));
  return Belt_Array.reduce(__x, Crdb$BsConsole.Fold.empty, (function (acc, param) {
                var attribute = param[0];
                return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                              return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                              attribute,
                                              operation
                                            ]), acc);
                            }));
              }));
}

function filtersFromJson(json) {
  var __x = Js_dict.entries(Belt_List.headExn(Json_decode.at(/* :: */[
                  "filter",
                  /* [] */0
                ], (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.dict((function (json) {
                                                var __x = Json_decode.list((function (json) {
                                                        try {
                                                          return Crdb$BsConsole.FilterOperation.fromJson(json);
                                                        }
                                                        catch (_err){
                                                          return ;
                                                        }
                                                      }), json);
                                                var __x$1 = Belt_List.keep(__x, Belt_Option.isSome);
                                                return Belt_List.map(__x$1, Belt_Option.getExn);
                                              }), param);
                                }), param);
                  }))(json)));
  return Belt_Array.reduce(__x, Crdb$BsConsole.Filters.empty, (function (acc, param) {
                var attribute = param[0];
                return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              attribute,
                                              operation
                                            ]), acc);
                            }));
              }));
}

function havingFromJson(json) {
  return {
          params: Json_decode.field("params", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          op: Json_decode.field("op", Json_decode.string, json),
          property: Json_decode.optional((function (param) {
                  return Json_decode.field("property", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

function getHavingsFromRaw(raw, fold) {
  var attributeString = Belt_Array.get(Belt_Option.getWithDefault(raw.property, []), 0);
  if (attributeString === undefined) {
    return ;
  }
  var strSplit = attributeString.split(";");
  if (strSplit.length !== 3) {
    return ;
  }
  var name = strSplit[0];
  var index = strSplit[1];
  var v = Belt_Array.get(raw.params, 0);
  var v$1 = v !== undefined ? v : "";
  var match = raw.op;
  var operationMaybe;
  switch (match) {
    case "!=" :
        operationMaybe = /* NotEqual */Block.__(1, [v$1]);
        break;
    case "<" :
        operationMaybe = /* LessThan */Block.__(3, [v$1]);
        break;
    case "<=" :
        operationMaybe = /* AtMost */Block.__(5, [v$1]);
        break;
    case "==" :
        operationMaybe = /* Equal */Block.__(0, [v$1]);
        break;
    case ">" :
        operationMaybe = /* GreaterThan */Block.__(2, [v$1]);
        break;
    case ">=" :
        operationMaybe = /* AtLeast */Block.__(4, [v$1]);
        break;
    default:
      operationMaybe = undefined;
  }
  var attribute = Js_dict.get(fold, name);
  if (attribute === undefined) {
    return ;
  }
  if (operationMaybe === undefined) {
    return ;
  }
  var att = Belt_List.get(attribute, Caml_format.caml_int_of_string(index));
  if (att === undefined) {
    return ;
  }
  var agg = Caml_option.valFromOption(att);
  if (agg === undefined) {
    return ;
  }
  var aggStr = Crdb$BsConsole.Operation.toString(agg);
  var havingAttr = attributeString + ("|" + aggStr);
  return Caml_option.some(Curry._1(Crdb$BsConsole.Having.create, /* tuple */[
                  havingAttr,
                  operationMaybe
                ]));
}

function havingsRawFromJson(json) {
  return Json_decode.at(/* :: */[
                "having",
                /* [] */0
              ], (function (param) {
                  return Json_decode.array((function (j) {
                                var rawFold = Json_decode.at(/* :: */[
                                        "fold",
                                        /* [] */0
                                      ], (function (param) {
                                          return Json_decode.dict((function (json) {
                                                        return Json_decode.list((function (json) {
                                                                      try {
                                                                        return Crdb$BsConsole.Operation.fromJson(json);
                                                                      }
                                                                      catch (exn){
                                                                        return ;
                                                                      }
                                                                    }), json);
                                                      }), param);
                                        }))(json);
                                var raw = havingFromJson(j);
                                return getHavingsFromRaw(raw, rawFold);
                              }), param);
                }))(json);
}

function queryFromJson(json) {
  return {
          factor: Json_decode.field("group", factorFromJson, json),
          filter: Route$BsConsole.withDefaultOnException(Crdb$BsConsole.Filters.empty, filtersFromJson, json),
          fold: Route$BsConsole.withDefaultOnException(Crdb$BsConsole.Fold.empty, parseFold, json),
          havingsArr: Route$BsConsole.withDefaultOnException([], havingsRawFromJson, json)
        };
}

function parseQuery(alertFullDetails) {
  if (alertFullDetails === undefined) {
    return ;
  }
  try {
    var q = Json.parse(alertFullDetails.query);
    if (q !== undefined) {
      return queryFromJson(Caml_option.valFromOption(q));
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

exports.factorFromJson = factorFromJson;
exports.parseFold = parseFold;
exports.filtersFromJson = filtersFromJson;
exports.havingFromJson = havingFromJson;
exports.getHavingsFromRaw = getHavingsFromRaw;
exports.havingsRawFromJson = havingsRawFromJson;
exports.queryFromJson = queryFromJson;
exports.parseQuery = parseQuery;
/* Crdb-BsConsole Not a pure module */
