// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Util$BsConsole = require("../util.js");
var ApiWf$BsConsole = require("./workflows-api/ApiWf.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ObjectID$BsConsole = require("../ObjectID.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");

function getTxFromQueryString(search) {
  var decode = function (json) {
    return {
            tx: Json_decode.optional((function (param) {
                    return Json_decode.field("_tx", Json_decode.string, param);
                  }), json)
          };
  };
  var json = Util$BsConsole.Qs.parse(search);
  var t = decode(json);
  var tx = t.tx;
  if (tx !== undefined) {
    return ObjectID$BsConsole.fromHexString(tx);
  }
  
}

function getFingerprintOfTx(token, projectName, tx, cbSuccess, cbFailure) {
  var aperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                "_tx",
                /* Equal */Block.__(0, [/* `ObjectID */[
                      -589436806,
                      tx
                    ]])
              ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                  /* Floating */0,
                  /* All */6
                ])), Crdb$BsConsole.Aperture.$$default));
  var query_001 = Curry._2(Crdb$BsConsole.Select.add, "fingerprint", Crdb$BsConsole.Select.empty);
  var query = /* Select */Block.__(1, [
      aperture,
      query_001,
      undefined,
      undefined
    ]);
  var queryJson = Curry._1(Crdb$BsConsole.Query.toCrdb_, query);
  var onSuccess = Curry.__1(cbSuccess);
  var onFailure = function (param) {
    return Curry._1(cbFailure, undefined);
  };
  var decode = function (json) {
    try {
      return Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "values",
                      /* [] */0
                    ]
                  ], (function (json2) {
                      var l = Json_decode.list((function (param) {
                              return Json_decode.tuple2(Json_decode.string, (function (param) {
                                            return Json_decode.tuple2(Json_decode.string, Json_decode.$$int, param);
                                          }), param);
                            }), json2);
                      var fingerprints = List.map((function (param) {
                              return param[1][0];
                            }), l);
                      var fingerprint = Belt_List.get(fingerprints, 0);
                      if (fingerprint !== undefined) {
                        return /* DecodeSuccess */Block.__(0, [fingerprint]);
                      }
                      var errorMessage = "There was no matching fingerprint in the response";
                      var __x = Backtrace$BsConsole.Attributes.addString("tx", ObjectID$BsConsole.toHexString(tx), Backtrace$BsConsole.Attributes.addString("src_module", "WfLinkUtil/getFingerprintOfTx/decode", Backtrace$BsConsole.Attributes.make(undefined)));
                      Backtrace$BsConsole.Client.send(__x, /* `string */[
                            288368849,
                            errorMessage
                          ]);
                      return /* DecodeFailure */Block.__(1, [new Error(errorMessage)]);
                    }))(json);
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      var error = Uncaught$BsConsole.castToJsExn(err);
      var __x = Backtrace$BsConsole.Attributes.addString("tx", ObjectID$BsConsole.toHexString(tx), Backtrace$BsConsole.Attributes.addString("src_module", "WfLinkUtil/getFingerprintOfTx/decode", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            error
          ]);
      return /* DecodeFailure */Block.__(1, [error]);
    }
  };
  var task = Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [queryJson]), undefined, (function (param) {
          var json = param.json;
          try {
            if (param.status >= 400) {
              var response = ApiWf$BsConsole.$$Error.fromJson(json);
              var __x = Backtrace$BsConsole.Attributes.addString("tx", ObjectID$BsConsole.toHexString(tx), Backtrace$BsConsole.Attributes.addString("src_module", "WfLinkUtil/getFingerprintOfTx/task", Backtrace$BsConsole.Attributes.make(undefined)));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    response.error
                  ]);
              return /* Error */Block.__(1, [[
                          ApiWf$BsConsole.ServiceError,
                          response.error
                        ]]);
            }
            var response$1 = decode(json);
            if (response$1.tag) {
              return /* Error */Block.__(1, [[
                          ApiWf$BsConsole.ParseError,
                          response$1[0]
                        ]]);
            } else {
              return /* Ok */Block.__(0, [response$1[0]]);
            }
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            var error = Uncaught$BsConsole.castToJsExn(err);
            var __x$1 = Backtrace$BsConsole.Attributes.addString("tx", ObjectID$BsConsole.toHexString(tx), Backtrace$BsConsole.Attributes.addString("src_module", "WfLinkUtil/getFingerprintOfTx/task", Backtrace$BsConsole.Attributes.make(undefined)));
            Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                  5049499,
                  error
                ]);
            return /* Error */Block.__(1, [[
                        ApiWf$BsConsole.ParseError,
                        error
                      ]]);
          }
        }), undefined, undefined);
  return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
              }));
}

exports.getTxFromQueryString = getTxFromQueryString;
exports.getFingerprintOfTx = getFingerprintOfTx;
/* Crdb-BsConsole Not a pure module */
