// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var WfCore$BsConsole = require("./WfCore.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

var ParseError = Caml_exceptions.create("WfParser-BsConsole.ParseError");

function parseIntSchema(path, uiSchema, required, json) {
  var defaultValue = Json_decode.optional((function (param) {
          return Json_decode.field("default", Json_decode.$$int, param);
        }), json);
  var min = Json_decode.optional((function (param) {
          return Json_decode.field("min", Json_decode.$$int, param);
        }), json);
  var max = Json_decode.optional((function (param) {
          return Json_decode.field("max", Json_decode.$$int, param);
        }), json);
  var placeholder = Belt_Option.flatMap(uiSchema, (function (param) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("ui:placeholder", Json_decode.string, param);
                      }), param);
        }));
  var uiWidget = Belt_Option.map(Belt_Option.flatMap(uiSchema, (function (param) {
              return Json_decode.optional((function (param) {
                            return Json_decode.field("ui:widget", Json_decode.string, param);
                          }), param);
            })), (function (widget) {
          if (widget === "backtrace-user") {
            return /* BacktraceUser */0;
          }
          throw [
                ParseError,
                /* InvalidIntUiWidget */Block.__(10, [
                    widget,
                    path
                  ])
              ];
        }));
  return WfCore$BsConsole.Int.make(required, defaultValue, min, max, placeholder, uiWidget, undefined);
}

function parseFloatSchema(param, uiSchema, required, json) {
  var defaultValue = Json_decode.optional((function (param) {
          return Json_decode.field("default", Json_decode.$$float, param);
        }), json);
  var min = Json_decode.optional((function (param) {
          return Json_decode.field("min", Json_decode.$$float, param);
        }), json);
  var max = Json_decode.optional((function (param) {
          return Json_decode.field("max", Json_decode.$$float, param);
        }), json);
  var placeholder = Belt_Option.flatMap(uiSchema, (function (param) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("ui:placeholder", Json_decode.string, param);
                      }), param);
        }));
  return WfCore$BsConsole.Float.make(required, defaultValue, min, max, placeholder, undefined);
}

function parseBoolSchema(path, uiSchema, required, json) {
  var defaultValue = Json_decode.optional((function (param) {
          return Json_decode.field("default", Json_decode.bool, param);
        }), json);
  var optArr = Json_decode.optional((function (param) {
          return Json_decode.field("enumNames", (function (param) {
                        return Json_decode.array(Json_decode.string, param);
                      }), param);
        }), json);
  var labels = Belt_Option.map(optArr, (function (arr) {
          if (arr.length !== 2) {
            throw [
                  ParseError,
                  /* BoolMustContainTwoEnumNames */Block.__(9, [path])
                ];
          }
          return /* tuple */[
                  Belt_Array.getExn(arr, 0),
                  Belt_Array.getExn(arr, 1)
                ];
        }));
  var uiWidget = Belt_Option.map(Belt_Option.flatMap(uiSchema, (function (param) {
              return Json_decode.optional((function (param) {
                            return Json_decode.field("ui:widget", Json_decode.string, param);
                          }), param);
            })), (function (widget) {
          switch (widget) {
            case "checkbox" :
                return /* Checkbox */111644259;
            case "radio" :
                return /* Radio */-146439973;
            case "select" :
                return /* Select */516394780;
            default:
              throw [
                    ParseError,
                    /* InvalidBoolUiWidget */Block.__(11, [
                        widget,
                        path
                      ])
                  ];
          }
        }));
  var placeholder = Belt_Option.flatMap(uiSchema, (function (param) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("ui:placeholder", Json_decode.string, param);
                      }), param);
        }));
  return WfCore$BsConsole.Bool.make(required, defaultValue, labels, uiWidget, placeholder, undefined);
}

function parseStringSchema(path, uiSchema, required, json) {
  var defaultValue = Json_decode.optional((function (param) {
          return Json_decode.field("default", Json_decode.string, param);
        }), json);
  var minLength = Json_decode.optional((function (param) {
          return Json_decode.field("minLength", Json_decode.$$int, param);
        }), json);
  var maxLength = Json_decode.optional((function (param) {
          return Json_decode.field("maxLength", Json_decode.$$int, param);
        }), json);
  var placeholder = Belt_Option.flatMap(uiSchema, (function (param) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("ui:placeholder", Json_decode.string, param);
                      }), param);
        }));
  var regex = Belt_Option.map(Json_decode.optional((function (param) {
              return Json_decode.field("regex", Json_decode.string, param);
            }), json), (function (prim) {
          return new RegExp(prim);
        }));
  var uiWidget = Belt_Option.map(Belt_Option.flatMap(uiSchema, (function (param) {
              return Json_decode.optional((function (param) {
                            return Json_decode.field("ui:widget", Json_decode.string, param);
                          }), param);
            })), (function (widget) {
          switch (widget) {
            case "color" :
                return /* Color */-578166461;
            case "password" :
                return /* Password */578936635;
            case "textarea" :
                return /* Textarea */843607770;
            default:
              throw [
                    ParseError,
                    /* InvalidStringUiWidget */Block.__(12, [
                        widget,
                        path
                      ])
                  ];
          }
        }));
  var inputType = Belt_Option.map(Json_decode.optional((function (param) {
              return Json_decode.field("format", Json_decode.string, param);
            }), json), (function (inputType) {
          switch (inputType) {
            case "data-url" :
                return /* DataUrl */3;
            case "date" :
                return /* Date */4;
            case "date-time" :
                return /* DateTime */5;
            case "email" :
                return /* Email */1;
            case "password" :
                return /* Password */0;
            case "uri" :
                return /* Uri */2;
            default:
              throw [
                    ParseError,
                    /* InvalidInputType */Block.__(6, [
                        inputType,
                        path
                      ])
                  ];
          }
        }));
  var uiPopulatedType = Belt_Option.map(Json_decode.optional((function (param) {
              return Json_decode.field("uiPopulatedField", Json_decode.string, param);
            }), json), (function (other) {
          switch (other) {
            case "bt-query-url" :
                return /* QueryUrl */0;
            case "current-project-name" :
                return /* CurrentProjectName */2;
            case "current-project-token" :
                return /* CurrentProjectToken */3;
            case "sca-service-url" :
                return /* ScaServiceUrl */1;
            default:
              throw [
                    ParseError,
                    /* InvalidUiPopulatedType */Block.__(7, [
                        other,
                        path
                      ])
                  ];
          }
        }));
  return WfCore$BsConsole.$$String.make(required, defaultValue, minLength, maxLength, regex, inputType, uiPopulatedType, uiWidget, placeholder, undefined);
}

function parseEnumItems(path, json) {
  var enumValues = Json_decode.field("enum", (function (param) {
          return Json_decode.array(Util$BsConsole.identity, param);
        }), json);
  var enumLabels;
  try {
    enumLabels = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                return Json_decode.field("enumNames", (function (param) {
                              return Json_decode.array(Json_decode.string, param);
                            }), param);
              }), json), Belt_Array.map(enumValues, Json_decode.string));
  }
  catch (exn){
    throw [
          ParseError,
          /* EnumLabelsMissingAndValuesNotString */Block.__(5, [path])
        ];
  }
  if (enumValues.length !== enumLabels.length) {
    throw [
          ParseError,
          /* EnumValueLabelCountMismatch */Block.__(4, [path])
        ];
  }
  return Belt_Array.map(Belt_Array.zip(enumLabels, enumValues), (function (param) {
                return WfCore$BsConsole.Enum.makeItem(param[0], param[1], undefined);
              }));
}

function parseEnumSchema(path, uiSchema, required, json) {
  var defaultValue = Json_decode.optional((function (param) {
          return Json_decode.field("default", Util$BsConsole.identity, param);
        }), json);
  var placeholder = Belt_Option.flatMap(uiSchema, (function (param) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("ui:placeholder", Json_decode.string, param);
                      }), param);
        }));
  return WfCore$BsConsole.Enum.make(parseEnumItems(path, json), required, defaultValue, placeholder, undefined);
}

function parseArraySchema(path, uiSchema, onUiAutofocus, json) {
  var unique = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("unique", Json_decode.bool, param);
            }), json), false);
  var orderable = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("orderable", Json_decode.bool, param);
            }), json), true);
  var minLength = Json_decode.optional((function (param) {
          return Json_decode.field("minItems", Json_decode.$$int, param);
        }), json);
  var maxLength = Json_decode.optional((function (param) {
          return Json_decode.field("maxItems", Json_decode.$$int, param);
        }), json);
  var items;
  try {
    items = Json_decode.field("items", Util$BsConsole.identity, json);
  }
  catch (exn){
    throw [
          ParseError,
          /* ArrayMissingItemsField */Block.__(8, [path])
        ];
  }
  var hasEnum = Belt_Option.isSome(Json_decode.optional((function (param) {
              return Json_decode.field("enum", Util$BsConsole.identity, param);
            }), items));
  var schemaType;
  if (hasEnum) {
    schemaType = "enum";
  } else {
    try {
      schemaType = Json_decode.field("type", Json_decode.string, items);
    }
    catch (exn$1){
      throw [
            ParseError,
            /* MissingTypeField */Block.__(2, [/* :: */[
                  "items",
                  path
                ]])
          ];
    }
  }
  var childrenUiSchema = Belt_Option.flatMap(uiSchema, (function (schema) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("items", Util$BsConsole.identity, param);
                      }), schema);
        }));
  var childSchema = parseSchema(/* :: */[
        "items",
        path
      ], childrenUiSchema, false, onUiAutofocus, schemaType, items);
  return WfCore$BsConsole.array(childSchema, unique, minLength, maxLength, orderable, undefined);
}

function parseObjectSchema(path, uiSchema, onUiAutofocus, json) {
  var requiredFields = Json_decode.optional((function (param) {
          return Json_decode.field("required", (function (param) {
                        return Json_decode.array(Json_decode.string, param);
                      }), param);
        }), json);
  var fieldsDict;
  try {
    fieldsDict = Json_decode.field("properties", (function (param) {
            return Json_decode.dict(Util$BsConsole.identity, param);
          }), json);
  }
  catch (exn){
    throw [
          ParseError,
          /* MissingPropertiesInObjectField */Block.__(0, [path])
        ];
  }
  var uiSchema$1 = Belt_Option.map(uiSchema, (function (uiSchema) {
          try {
            return Json_decode.dict(Util$BsConsole.identity, uiSchema);
          }
          catch (exn){
            throw [
                  ParseError,
                  /* InvalidUiSchema */Block.__(13, [path])
                ];
          }
        }));
  var order = Belt_Option.map(Belt_Option.flatMap(uiSchema$1, (function (__x) {
              return Js_dict.get(__x, "ui:order");
            })), (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }));
  var getIndex = function (arr, item) {
    var index = arr.indexOf(item);
    if (index === -1) {
      return ;
    } else {
      return index;
    }
  };
  var restIndex = Belt_Option.flatMap(order, (function (__x) {
          return getIndex(__x, "*");
        }));
  var fields = Belt_Array.map(Belt_SortArray.stableSortBy(Js_dict.entries(fieldsDict), (function (param, param$1) {
              if (order === undefined) {
                return 0;
              }
              var i1 = getIndex(order, param[0]);
              var i2 = getIndex(order, param$1[0]);
              if (i1 !== undefined) {
                if (i2 !== undefined) {
                  return i1 - i2 | 0;
                } else if (restIndex !== undefined) {
                  return i1 - restIndex | 0;
                } else {
                  return 0;
                }
              } else if (i2 !== undefined && restIndex !== undefined) {
                return restIndex - i2 | 0;
              } else {
                return 0;
              }
            })), (function (param) {
          var key = param[0];
          var required = Belt_Option.map(requiredFields, (function (reqArray) {
                  return reqArray.includes(key);
                }));
          var uiSchema$2 = Belt_Option.flatMap(uiSchema$1, (function (uiSchema) {
                  return Js_dict.get(uiSchema, key);
                }));
          var outputType = parseField(/* :: */[
                key,
                /* :: */[
                  "properties",
                  path
                ]
              ], uiSchema$2, required, onUiAutofocus, param[1]);
          return {
                  name: key,
                  outputType: outputType
                };
        }));
  return WfCore$BsConsole.objectSchema(fields, undefined);
}

function parseSchema(path, uiSchema, required, onUiAutofocus, schemaType, json) {
  switch (schemaType) {
    case "array" :
        return /* Array */Block.__(5, [parseArraySchema(path, uiSchema, onUiAutofocus, json)]);
    case "boolean" :
        return /* Bool */Block.__(2, [parseBoolSchema(path, uiSchema, required, json)]);
    case "enum" :
        return /* Enum */Block.__(3, [parseEnumSchema(path, uiSchema, required, json)]);
    case "integer" :
        return /* Int */Block.__(0, [parseIntSchema(path, uiSchema, required, json)]);
    case "number" :
        return /* Float */Block.__(1, [parseFloatSchema(path, uiSchema, required, json)]);
    case "object" :
        return /* Object */Block.__(6, [parseObjectSchema(path, uiSchema, onUiAutofocus, json)]);
    case "string" :
        return /* String */Block.__(4, [parseStringSchema(path, uiSchema, required, json)]);
    default:
      throw [
            ParseError,
            /* UnsupportedFieldType */Block.__(1, [path])
          ];
  }
}

function parseField(path, uiSchema, requiredOpt, onUiAutofocus, json) {
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var hasEnum = Belt_Option.isSome(Json_decode.optional((function (param) {
              return Json_decode.field("enum", Util$BsConsole.identity, param);
            }), json));
  var schemaType;
  if (hasEnum) {
    schemaType = "enum";
  } else {
    try {
      schemaType = Json_decode.field("type", Json_decode.string, json);
    }
    catch (exn){
      throw [
            ParseError,
            /* MissingTypeField */Block.__(2, [/* :: */[
                  "items",
                  path
                ]])
          ];
    }
  }
  var title;
  try {
    title = Json_decode.field("title", Json_decode.string, json);
  }
  catch (exn$1){
    throw [
          ParseError,
          /* MissingTitleField */Block.__(3, [path])
        ];
  }
  var parsedSchema = parseSchema(path, uiSchema, required, onUiAutofocus, schemaType, json);
  var description = Json_decode.optional((function (param) {
          return Json_decode.field("description", Json_decode.string, param);
        }), json);
  return WfCore$BsConsole.field(title, description, parsedSchema, undefined);
}

function parseForm(json) {
  var title = Json_decode.optional((function (param) {
          return Json_decode.field("title", Json_decode.string, param);
        }), json);
  var description;
  try {
    description = Json_decode.optional((function (param) {
            return Json_decode.field("description", Json_decode.string, param);
          }), json);
  }
  catch (exn){
    throw [
          ParseError,
          /* MissingFormDescription */2
        ];
  }
  var uiSchema = Json_decode.optional((function (param) {
          return Json_decode.field("uiSchema", Util$BsConsole.identity, param);
        }), json);
  var autoFocus = {
    contents: undefined
  };
  var onUiAutofocus = function (path) {
    var match = autoFocus.contents;
    if (match !== undefined) {
      return ;
    } else {
      autoFocus.contents = Belt_List.map(Belt_List.reverse(path), (function (key) {
              return /* Key */Block.__(1, [key]);
            }));
      return ;
    }
  };
  var schema = parseObjectSchema(/* [] */0, uiSchema, onUiAutofocus, json);
  return {
          title: title,
          description: description,
          schema: schema,
          autoFocus: autoFocus.contents
        };
}

function parseFormSafe(json) {
  try {
    return /* Ok */Block.__(0, [parseForm(json)]);
  }
  catch (raw_parseError){
    var parseError = Caml_js_exceptions.internalToOCamlException(raw_parseError);
    if (parseError[0] === ParseError) {
      return /* Error */Block.__(1, [parseError[1]]);
    } else {
      return /* Error */Block.__(1, [/* UnknownJsonParseException */3]);
    }
  }
}

function printPath(path) {
  if (path) {
    return Belt_List.toArray(Belt_List.reverse(path)).join(".");
  } else {
    return "the root of json";
  }
}

function getUserFriendlyError(parseError) {
  if (typeof parseError === "number") {
    switch (parseError) {
      case /* MissingFormField */0 :
          return I18N$BsConsole.get(undefined, "Json is missing \"form\" field");
      case /* MissingFormTitle */1 :
          return I18N$BsConsole.get(undefined, "Form is missing \"title\" field");
      case /* MissingFormDescription */2 :
          return I18N$BsConsole.get(undefined, "Form is missing \"description\" field");
      case /* UnknownJsonParseException */3 :
          return I18N$BsConsole.get(undefined, "Unknown exception occured while parsing the json");
      case /* InvalidJson */4 :
          return I18N$BsConsole.get(undefined, "Invalid json");
      
    }
  } else {
    switch (parseError.tag | 0) {
      case /* MissingPropertiesInObjectField */0 :
          return I18N$BsConsole.get(undefined, "Missing \"properties\" field in ") + printPath(parseError[0]);
      case /* UnsupportedFieldType */1 :
          return I18N$BsConsole.get(undefined, "Unsupported \"type\" in ") + printPath(parseError[0]);
      case /* MissingTypeField */2 :
          return I18N$BsConsole.get(undefined, "Missing \"type\" field in ") + printPath(parseError[0]);
      case /* MissingTitleField */3 :
          return I18N$BsConsole.get(undefined, "Missing \"title\" field in ") + printPath(parseError[0]);
      case /* EnumValueLabelCountMismatch */4 :
          return I18N$BsConsole.get(undefined, "\"enum\" and \"enumNames\" fields do not have the same lengths in ") + printPath(parseError[0]);
      case /* EnumLabelsMissingAndValuesNotString */5 :
          return I18N$BsConsole.get(undefined, "\"enum\" values are not valid string and \"enumLabels\" are not present in ") + printPath(parseError[0]);
      case /* InvalidInputType */6 :
          return "\"" + (parseError[0] + (I18N$BsConsole.get(undefined, "\" is not a supported string format in ") + printPath(parseError[1])));
      case /* InvalidUiPopulatedType */7 :
          return "\"" + (parseError[0] + (I18N$BsConsole.get(undefined, "\" is not a supported string uiPopulatedType in ") + printPath(parseError[1])));
      case /* ArrayMissingItemsField */8 :
          return I18N$BsConsole.get(undefined, "Missing \"items\" field in ") + printPath(parseError[0]);
      case /* BoolMustContainTwoEnumNames */9 :
          return I18N$BsConsole.get(undefined, "Boolean field must contain exactly two \"enumName\" values in ") + printPath(parseError[0]);
      case /* InvalidIntUiWidget */10 :
          return "\"" + (parseError[0] + (I18N$BsConsole.get(undefined, "\" is not a supported \"ui:widget\" for integer field in ") + printPath(parseError[1])));
      case /* InvalidBoolUiWidget */11 :
          return "\"" + (parseError[0] + (I18N$BsConsole.get(undefined, "\" is not a supported \"ui:widget\" for boolean field in ") + printPath(parseError[1])));
      case /* InvalidStringUiWidget */12 :
          return "\"" + (parseError[0] + (I18N$BsConsole.get(undefined, "\" is not a supported \"ui:widget\" for string field in ") + printPath(parseError[1])));
      case /* InvalidUiSchema */13 :
          return I18N$BsConsole.get(undefined, "Invalid ui schema in ") + printPath(parseError[0]);
      
    }
  }
}

exports.ParseError = ParseError;
exports.parseIntSchema = parseIntSchema;
exports.parseFloatSchema = parseFloatSchema;
exports.parseBoolSchema = parseBoolSchema;
exports.parseStringSchema = parseStringSchema;
exports.parseEnumItems = parseEnumItems;
exports.parseEnumSchema = parseEnumSchema;
exports.parseArraySchema = parseArraySchema;
exports.parseObjectSchema = parseObjectSchema;
exports.parseSchema = parseSchema;
exports.parseField = parseField;
exports.parseForm = parseForm;
exports.parseFormSafe = parseFormSafe;
exports.printPath = printPath;
exports.getUserFriendlyError = getUserFriendlyError;
/* I18N-BsConsole Not a pure module */
