// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Email$BsConsole = require("../Email.js");
var Colors$BsConsole = require("../Colors.js");
var Guides$BsConsole = require("../guides/Guides.js");
var Markdown$BsConsole = require("../Markdown.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var I18N_config$BsConsole = require("../I18N_config.js");
var ApiDemoProject$BsConsole = require("../ApiDemoProject.js");
var DemoProjectUtil$BsConsole = require("../DemoProjectUtil.js");
var SdkExampleBanner$BsConsole = require("../sdk-examples/SdkExampleBanner.js");
var DemoProjectPrompt$BsConsole = require("../DemoProjectPrompt.js");
var PS_Documentation_GuideTemplate$BsConsole = require("./PS_Documentation_GuideTemplate.js");

var closeMarkdown = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(24)),
        /* :: */[
          Css.right(Css.px(24)),
          /* [] */0
        ]
      ]
    ]);

var errorContactUs = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var errorIcon = Css.style(/* :: */[
      Css.selector("svg", /* :: */[
            Css.fontSize(Css.px(48)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.red)),
              /* [] */0
            ]
          ]),
      /* [] */0
    ]);

var loading = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var markdownCard = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var Style = {
  closeMarkdown: closeMarkdown,
  errorContactUs: errorContactUs,
  errorIcon: errorIcon,
  loading: loading,
  markdownCard: markdownCard
};

function loading$1(param) {
  return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(loading, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, undefined, undefined, undefined, []))]));
}

function failure(message) {
  return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(loading, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [I18N$BsConsole.showSkip(message)]));
}

function errorContactUs$1(text) {
  return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                  React.createElement("span", {
                        className: errorIcon
                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.$$Error.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                  React.createElement("div", {
                        className: errorContactUs
                      }, React.createElement("p", undefined, I18N$BsConsole.showSkip(text), React.createElement("a", {
                                href: "mailto:" + Email$BsConsole.support
                              }, I18N$BsConsole.showSkip(Email$BsConsole.support)), I18N$BsConsole.show(undefined, " to configure your instance.")))
                ]));
}

function guide(template, onGoHomeClick, markdown, config) {
  var match = template.guide.name;
  var tmp = match === "JavaScript" ? ReasonReact.element(undefined, undefined, SdkExampleBanner$BsConsole.Jsx2.make(template, config)([])) : null;
  return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, "integration-guide-markdown " + markdownCard, [
                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                            undefined,
                            closeMarkdown,
                            onGoHomeClick,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [
                              ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                              I18N$BsConsole.show(undefined, "Go back")
                            ]
                          ])),
                  tmp,
                  ReasonReact.element(undefined, undefined, Util$BsConsole.RenderIf.make((function (param) {
                              return !template.isEnabled;
                            }), (function (param) {
                              return errorContactUs$1(I18N$BsConsole.get(undefined, "This integration is not enabled for your instance."));
                            }), undefined, [])),
                  ReasonReact.element(undefined, undefined, Util$BsConsole.RenderIf.make((function (param) {
                              return !template.isVersionCompatible;
                            }), (function (param) {
                              return errorContactUs$1(I18N$BsConsole.get(undefined, "You must upgrade your instance to use this integration."));
                            }), undefined, [])),
                  ReasonReact.element(undefined, undefined, Markdown$BsConsole.make(PS_Documentation_GuideTemplate$BsConsole.renderMarkdown(template, markdown), []))
                ]));
}

var View = {
  loading: loading$1,
  failure: failure,
  errorContactUs: errorContactUs$1,
  guide: guide
};

var component = ReasonReact.reducerComponent("PS_Documentation_Guide-BsConsole");

function make(apiTokens, config, guide$1, onGoHomeClick, origin, project, receiveLayer, tokens, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var template = function (param) {
                return PS_Documentation_GuideTemplate$BsConsole.make(config, origin, project, receiveLayer, tokens, apiTokens, guide$1);
              };
              var startLoading = function (param) {
                var languageCode = I18N_config$BsConsole.GetUserLanguage.get(config);
                var __x = Guides$BsConsole.loadMarkdown(languageCode, guide$1);
                var __x$1 = __x.then((function (markdown) {
                        return Promise.resolve(Curry._1(self.send, /* MarkdownLoadSuccess */Block.__(1, [markdown])));
                      }));
                __x$1.catch((function (e) {
                        return Promise.resolve(Curry._1(self.send, /* MarkdownLoadFailed */Block.__(0, [e])));
                      }));
                return loading$1(undefined);
              };
              var message = self.state.markdown;
              var tmp;
              tmp = typeof message === "number" ? (
                  message === /* NotAsked */0 ? startLoading(undefined) : loading$1(undefined)
                ) : (
                  message.tag ? failure(message[0]) : guide(template(undefined), onGoHomeClick, message[0], config)
                );
              return React.createElement(React.Fragment, undefined, DemoProjectUtil$BsConsole.hasDemoProject(guide$1.name) ? ReasonReact.element(undefined, undefined, DemoProjectPrompt$BsConsole.Jsx2.make(config, token, /* IntegrationGuide */1, undefined, undefined, ApiDemoProject$BsConsole.Platform.guideNameToType(guide$1.name), Css.style(/* :: */[
                                        Css.marginLeft(Css.px(15)),
                                        /* :: */[
                                          Css.marginRight(Css.px(15)),
                                          /* :: */[
                                            Css.marginTop(Css.px(15)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]), [])) : null, tmp);
            }),
          initialState: (function (param) {
              return {
                      markdown: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            markdown: /* Success */Block.__(0, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            markdown: /* Failure */Block.__(1, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var BPG;

var GuideTemplate;

exports.BPG = BPG;
exports.GuideTemplate = GuideTemplate;
exports.Style = Style;
exports.View = View;
exports.component = component;
exports.make = make;
/* closeMarkdown Not a pure module */
