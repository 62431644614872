// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var ApiStabilityMonitoring$BsConsole = require("../ApiStabilityMonitoring.js");

var ParseError = Caml_exceptions.create("FetchStabilityMonitoring-BsConsole.ParseError");

var CheckImportError = Caml_exceptions.create("FetchStabilityMonitoring-BsConsole.CheckImportError");

var UnknownSvcError = Caml_exceptions.create("FetchStabilityMonitoring-BsConsole.UnknownSvcError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === CheckImportError) {
    tmp = error[1].join("\n");
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === Task2$BsConsole.ErrorMsg ? "Error: " + error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function serviceDeleteResponseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  if (error[0] === Task2$BsConsole.UncaughtPromiseError) {
    return Curry._1(onSuccess, undefined);
  }
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === CheckImportError) {
    tmp = error[1].join("\n");
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === Task2$BsConsole.ErrorMsg ? "Error: " + error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function makeGetSourcesTask(serviceEndpoint, projectName) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + "/sources")))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = Json_decode.field("sources", (function (param) {
                          return Json_decode.array(ApiStabilityMonitoring$BsConsole.Source.fromJson, param);
                        }), param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeDeleteSourcesTask(serviceEndpoint, projectName, id) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/sources/" + (String(id) + "")))))]), undefined, /* Delete */1, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return /* Ok */Block.__(0, [200]);
              }), undefined, undefined);
}

function makeCheckSourcesTask(serviceEndpoint, projectName, id, query) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/sources/" + (String(id) + ("/check?query=" + (String(query) + "")))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                try {
                  var success = Json_decode.field("success", Json_decode.bool, json);
                  var errors = Json_decode.field("errors", (function (param) {
                          return Json_decode.array(Json_decode.string, param);
                        }), json);
                  var warnings = Json_decode.field("warnings", (function (param) {
                          return Json_decode.array(Json_decode.string, param);
                        }), json);
                  if (success) {
                    return /* Ok */Block.__(0, [warnings]);
                  } else {
                    return /* Error */Block.__(1, [[
                                CheckImportError,
                                errors,
                                warnings
                              ]]);
                  }
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makePostSourcesTask(serviceEndpoint, projectName, body) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + "/sources")))]), undefined, /* Post */Block.__(0, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = ApiStabilityMonitoring$BsConsole.$$Response.fromJson(param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makePostImporterTask(serviceEndpoint, projectName, body) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + "/importers")))]), undefined, /* Post */Block.__(0, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = ApiStabilityMonitoring$BsConsole.$$Response.fromJson(param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makePutSourcesTask(serviceEndpoint, projectName, body, id) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/sources/" + (String(id) + "")))))]), undefined, /* Put */Block.__(1, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = ApiStabilityMonitoring$BsConsole.$$Response.fromJson(param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeDeleteImportersTask(serviceEndpoint, projectName, id) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/importers/" + (String(id) + "")))))]), undefined, /* Delete */1, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function makePutImportersTask(serviceEndpoint, projectName, body, id) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/importers/" + (String(id) + "")))))]), undefined, /* Put */Block.__(1, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = ApiStabilityMonitoring$BsConsole.$$Response.fromJson(param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeGetImportersTask(serviceEndpoint, projectName) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + "/importers")))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = Json_decode.field("importers", (function (param) {
                          return Json_decode.array(ApiStabilityMonitoring$BsConsole.Importer.fromJson, param);
                        }), param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeGetLogsTask(serviceEndpoint, projectName, limitOpt, offsetOpt, sourceId, importerId) {
  var limit = limitOpt !== undefined ? limitOpt : 250;
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  var qs = sourceId !== undefined ? (
      importerId !== undefined ? "" : "&sourceId=" + (String(Caml_option.valFromOption(sourceId)) + "")
    ) : (
      importerId !== undefined ? "&importerId=" + (String(Caml_option.valFromOption(importerId)) + "") : ""
    );
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/projects/" + (String(projectName) + ("/logs?limit=" + (String(limit) + ("&offset=" + (String(offset) + ("" + (String(qs) + "")))))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = Json_decode.field("messages", (function (param) {
                          return Json_decode.array(ApiStabilityMonitoring$BsConsole.Log.fromJson, param);
                        }), param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function useLogs(serviceEndpoint, projectName, token, sourceId, importerId) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp]);
                }));
  };
  var onFailure = function (msg) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [msg]);
                }));
  };
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              var arg = makeGetLogsTask(serviceEndpoint, projectName, 250, Caml_int32.imul(page, 250), sourceId, importerId);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  React.useEffect((function () {
          if (typeof remote !== "number" && !remote.tag) {
            Curry._1(setRemote, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [page]);
  var setLogPage = function (page) {
    if (page >= 0) {
      return Curry._1(setPage, (function (param) {
                    return page;
                  }));
    }
    
  };
  var refresh = function (param) {
    Curry._1(setPage, (function (param) {
            return 0;
          }));
    return Curry._1(setRemote, (function (param) {
                  return /* NotAsked */0;
                }));
  };
  return /* tuple */[
          remote,
          refresh,
          page,
          setLogPage
        ];
}

function getSources(token, projectName, serviceEndpoint) {
  var task = makeGetSourcesTask(serviceEndpoint, projectName);
  return BpgTask$BsConsole.use(token, task);
}

function deleteSource(token, projectName, serviceEndpoint, id) {
  var task = makeDeleteSourcesTask(serviceEndpoint, projectName, id);
  return BpgTask$BsConsole.use(token, task);
}

function getImporters(token, projectName, serviceEndpoint) {
  var task = makeGetImportersTask(serviceEndpoint, projectName);
  return BpgTask$BsConsole.use(token, task);
}

exports.ParseError = ParseError;
exports.CheckImportError = CheckImportError;
exports.UnknownSvcError = UnknownSvcError;
exports.responseCb = responseCb;
exports.serviceDeleteResponseCb = serviceDeleteResponseCb;
exports.makeGetSourcesTask = makeGetSourcesTask;
exports.makeDeleteSourcesTask = makeDeleteSourcesTask;
exports.makeCheckSourcesTask = makeCheckSourcesTask;
exports.makePostSourcesTask = makePostSourcesTask;
exports.makePostImporterTask = makePostImporterTask;
exports.makePutSourcesTask = makePutSourcesTask;
exports.makeDeleteImportersTask = makeDeleteImportersTask;
exports.makePutImportersTask = makePutImportersTask;
exports.makeGetImportersTask = makeGetImportersTask;
exports.makeGetLogsTask = makeGetLogsTask;
exports.useLogs = useLogs;
exports.getSources = getSources;
exports.deleteSource = deleteSource;
exports.getImporters = getImporters;
/* react Not a pure module */
