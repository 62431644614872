// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Colors$BsConsole = require("./Colors.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function tableStyle(stickyHeader, showGrid, dense) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.tableLayout(/* fixed */10615156),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.borderCollapse(stickyHeader ? /* separate */119283555 : /* collapse */-996847251),
                        /* :: */[
                          Css.display(/* table */182695950),
                          /* :: */[
                            Css.borderSpacing(/* zero */-789508312),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey2)),
                              /* :: */[
                                Css.unsafe("backgroundColor", "inherit"),
                                /* :: */[
                                  Css.selector("thead.BtTableHead, tbody.BtTableBody, tr.BtTableRow", /* :: */[
                                        Css.borderSpacing(/* zero */-789508312),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    Css.selector("tr.BtTableRow:last-of-type > td", /* :: */[
                                          Css.unsafe("borderBottom", "none"),
                                          /* :: */[
                                            Css.paddingBottom(Css.px(1)),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                stickyHeader ? Css.style(/* :: */[
                        Css.selector("thead.BtTableHead, tbody.BtTableBody, tr.BtTableRow", /* :: */[
                              Css.borderCollapse(/* separate */119283555),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("th.BtTableHeaderCell", /* :: */[
                                Css.position(/* sticky */188263721),
                                /* :: */[
                                  Css.top(/* zero */-789508312),
                                  /* :: */[
                                    Css.zIndex(2),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]) : Css.style(/* :: */[
                        Css.selector("thead.BtTableHead, tbody.BtTableBody, tr.BtTableRow", /* :: */[
                              Css.borderCollapse(/* collapse */-996847251),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]),
                /* :: */[
                  showGrid ? Css.style(/* :: */[
                          Css.selector("th.BtTableHeaderCell:not(:last-child), td.BtTableDataCell:not(:last-child)", /* :: */[
                                Css.boxShadow(Css.px(-1), undefined, undefined, undefined, true, Css.hex(Colors$BsConsole.grey6)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]) : "",
                  /* :: */[
                    dense ? "" : Css.style(/* :: */[
                            Css.selector("th.BtTableHeaderCell, td.BtTableDataCell", /* :: */[
                                  Css.height(Css.px(48)),
                                  /* :: */[
                                    Css.padding2(/* zero */-789508312, Css.px(16)),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]),
                    /* :: */[
                      "BtTable",
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var loadingContainerStyle = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var make = React.forwardRef((function (Props, theRef) {
        var stickyHeaderOpt = Props.stickyHeader;
        var showGridOpt = Props.showGrid;
        var denseOpt = Props.dense;
        var classNameOpt = Props.className;
        var loadingOpt = Props.loading;
        var children = Props.children;
        var stickyHeader = stickyHeaderOpt !== undefined ? stickyHeaderOpt : false;
        var showGrid = showGridOpt !== undefined ? showGridOpt : false;
        var dense = denseOpt !== undefined ? denseOpt : false;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var loading = loadingOpt !== undefined ? loadingOpt : false;
        return React.createElement(React.Fragment, undefined, React.createElement("table", {
                        ref: theRef,
                        className: Css.merge(/* :: */[
                              tableStyle(stickyHeader, showGrid, dense),
                              /* :: */[
                                className,
                                /* [] */0
                              ]
                            ])
                      }, children), loading ? React.createElement("div", {
                          className: loadingContainerStyle
                        }, React.createElement(CircularProgress.default, {
                              size: 100,
                              color: "inherit"
                            })) : null);
      }));

exports.tableStyle = tableStyle;
exports.loadingContainerStyle = loadingContainerStyle;
exports.make = make;
/* loadingContainerStyle Not a pure module */
