// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var statelessComponent = ReasonReact.statelessComponent;

var statelessComponentWithRetainedProps = ReasonReact.statelessComponentWithRetainedProps;

var reducerComponent = ReasonReact.reducerComponent;

var reducerComponentWithRetainedProps = ReasonReact.reducerComponentWithRetainedProps;

var element = ReasonReact.element;

var wrapReasonForJs = ReasonReact.wrapReasonForJs;

var createDomElement = ReasonReact.createDomElement;

var wrapJsForReason = ReasonReact.wrapJsForReason;

var Router = ReasonReact.Router;

exports.statelessComponent = statelessComponent;
exports.statelessComponentWithRetainedProps = statelessComponentWithRetainedProps;
exports.reducerComponent = reducerComponent;
exports.reducerComponentWithRetainedProps = reducerComponentWithRetainedProps;
exports.element = element;
exports.wrapReasonForJs = wrapReasonForJs;
exports.createDomElement = createDomElement;
exports.wrapJsForReason = wrapJsForReason;
exports.Router = Router;
/* ReasonReact Not a pure module */
