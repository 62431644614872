// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../I18N.js");

function fromJson(json) {
  return {
          brand: Json_decode.field("brand", Json_decode.string, json),
          expiryMonth: Json_decode.field("expiry_month", Json_decode.$$int, json),
          expiryYear: Json_decode.field("expiry_year", Json_decode.$$int, json),
          last4: Json_decode.field("last4", Json_decode.string, json)
        };
}

function toString(t) {
  var match = t.brand;
  var cardType;
  switch (match) {
    case "american_express" :
        cardType = "American Express";
        break;
    case "diners_club" :
        cardType = "Diner's Club";
        break;
    case "discover" :
        cardType = "Discover";
        break;
    case "jcb" :
        cardType = "JCB";
        break;
    case "mastercard" :
        cardType = "Mastercard";
        break;
    case "other" :
        cardType = "Other";
        break;
    case "visa" :
        cardType = "Visa";
        break;
    default:
      cardType = "Other";
  }
  return Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* String_literal */Block.__(11, [
                          " ending in ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  " (expires ",
                                  /* Int */Block.__(4, [
                                      /* Int_d */0,
                                      /* No_padding */0,
                                      /* No_precision */0,
                                      /* Char_literal */Block.__(12, [
                                          /* "/" */47,
                                          /* Int */Block.__(4, [
                                              /* Int_d */0,
                                              /* No_padding */0,
                                              /* No_precision */0,
                                              /* Char_literal */Block.__(12, [
                                                  /* ")" */41,
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%s ending in %s (expires %d/%d)"
                ]), cardType, t.last4, t.expiryMonth, t.expiryYear);
}

exports.fromJson = fromJson;
exports.toString = toString;
/* I18N-BsConsole Not a pure module */
