// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");

var ConnectorError = Caml_exceptions.create("DataSourcesApi-BsConsole.ConnectorError");

function decode(json) {
  return Json_decode.array((function (json) {
                return /* tuple */[
                        Json_decode.field("fromAttribute", Json_decode.string, json),
                        Json_decode.field("toAttribute", Json_decode.string, json)
                      ];
              }), json);
}

var AttributeMapping = {
  decode: decode
};

function decode$1(json) {
  return {
          connectionId: Json_decode.field("connectionId", Json_decode.$$int, json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          lastDownloadDate: Json_decode.optional((function (json) {
                  return Json_decode.field("lastDownloadDate", Json_decode.date, json);
                }), json),
          lastDownloadFailure: Json_decode.optional((function (json) {
                  return Json_decode.field("lastDownloadFailure", Json_decode.date, json);
                }), json),
          totalNumberOfDownloadedBytes: Json_decode.field("totalNumberOfDownloadedBytes", Json_decode.$$float, json),
          totalNumberOfDownloadedFiles: Json_decode.field("totalNumberOfDownloadedFiles", Json_decode.$$float, json),
          totalNumberOfFailures: Json_decode.field("totalNumberOfFailures", Json_decode.$$float, json)
        };
}

var Statistics = {
  decode: decode$1
};

function decode$2(json) {
  return {
          connectionId: Json_decode.field("connectionId", Json_decode.$$int, json),
          sendNotifications: Json_decode.field("sendConnectionDisabledNotification", Json_decode.bool, json),
          notificationRecipients: Json_decode.field("connectionDisabledNotificationReceivers", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var ConnectionNotifications = {
  decode: decode$2
};

function toVariant(param) {
  if (param > 2 || param < 0) {
    return /* Full */0;
  } else {
    return param;
  }
}

function fromVariant(param) {
  return param;
}

function toString(param) {
  switch (param) {
    case /* Full */0 :
        return "full";
    case /* Ignore */1 :
        return "ignore";
    case /* Dynamic */2 :
        return "dynamic";
    
  }
}

function fromString(param) {
  switch (param) {
    case "dynamic" :
        return /* Dynamic */2;
    case "full" :
        return /* Full */0;
    case "ignore" :
        return /* Ignore */1;
    default:
      return /* Full */0;
  }
}

var AttachmentStrategy = {
  toVariant: toVariant,
  fromVariant: fromVariant,
  toString: toString,
  fromString: fromString
};

function decode$3(json) {
  var param = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("secret", Json_decode.$$int, param);
            }), json), 0);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("attributeMapping", decode, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          bucketName: Json_decode.field("bucketName", Json_decode.string, json),
          projectId: Json_decode.field("projectId", Json_decode.string, json),
          coronerdAddress: Json_decode.field("coronerdAddress", Json_decode.string, json),
          user: Json_decode.field("user", Json_decode.string, json),
          secretExists: param !== 0 ? true : false,
          creationDate: new Date(Json_decode.field("creationDate", Json_decode.string, json)),
          attributeMapping: Belt_Option.getWithDefault(__x, []),
          statistics: Json_decode.field("statistics", decode$1, json),
          attachmentStrategy: toVariant(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("attachmentStrategy", Json_decode.$$int, param);
                        }), json), 0)),
          disabled: Json_decode.field("disabled", Json_decode.bool, json),
          connectionNotifications: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionNotifications", decode$2, param);
                }), json)
        };
}

var XBox = {
  decode: decode$3
};

function decode$4(json) {
  var param = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("secret", Json_decode.$$int, param);
            }), json), 0);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("attributeMapping", decode, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          serverUrl: Json_decode.field("serverUrl", Json_decode.string, json),
          projectId: Json_decode.field("projectId", Json_decode.string, json),
          coronerdAddress: Json_decode.field("coronerdAddress", Json_decode.string, json),
          secretExists: param !== 0 ? true : false,
          creationDate: new Date(Json_decode.field("creationDate", Json_decode.string, json)),
          attributeMapping: Belt_Option.getWithDefault(__x, []),
          statistics: Json_decode.field("statistics", decode$1, json),
          attachmentStrategy: toVariant(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("attachmentStrategy", Json_decode.$$int, param);
                        }), json), 0)),
          disabled: Json_decode.field("disabled", Json_decode.bool, json),
          connectionNotifications: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionNotifications", decode$2, param);
                }), json)
        };
}

var NintendoSwitch = {
  decode: decode$4
};

function decode$5(json) {
  var param = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("secret", Json_decode.$$int, param);
            }), json), 0);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("attributeMapping", decode, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          coronerdAddress: Json_decode.field("coronerdAddress", Json_decode.string, json),
          secretExists: param !== 0 ? true : false,
          serverUrl: Json_decode.field("serverUrl", Json_decode.string, json),
          filter: Json_decode.optional((function (param) {
                  return Json_decode.field("filter", Json_decode.string, param);
                }), json),
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.string, param);
                }), json),
          creationDate: new Date(Json_decode.field("creationDate", Json_decode.string, json)),
          attributeMapping: Belt_Option.getWithDefault(__x, []),
          statistics: Json_decode.field("statistics", decode$1, json),
          attachmentStrategy: toVariant(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("attachmentStrategy", Json_decode.$$int, param);
                        }), json), 0)),
          disabled: Json_decode.field("disabled", Json_decode.bool, json),
          ignoreSslCert: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("ignoreSslCert", Json_decode.bool, param);
                    }), json), false),
          connectionNotifications: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionNotifications", decode$2, param);
                }), json)
        };
}

var Playstation4 = {
  decode: decode$5
};

function decode$6(json) {
  var param = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("secret", Json_decode.$$int, param);
            }), json), 0);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("attributeMapping", decode, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          coronerdAddress: Json_decode.field("coronerdAddress", Json_decode.string, json),
          user: Json_decode.optional((function (param) {
                  return Json_decode.field("user", Json_decode.string, param);
                }), json),
          secretExists: param !== 0 ? true : false,
          serverUrl: Json_decode.field("serverUrl", Json_decode.string, json),
          filter: Json_decode.optional((function (param) {
                  return Json_decode.field("filter", Json_decode.string, param);
                }), json),
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.string, param);
                }), json),
          creationDate: new Date(Json_decode.field("creationDate", Json_decode.string, json)),
          attributeMapping: Belt_Option.getWithDefault(__x, []),
          statistics: Json_decode.field("statistics", decode$1, json),
          attachmentStrategy: toVariant(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("attachmentStrategy", Json_decode.$$int, param);
                        }), json), 0)),
          disabled: Json_decode.field("disabled", Json_decode.bool, json),
          ignoreSslCert: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("ignoreSslCert", Json_decode.bool, param);
                    }), json), false),
          connectionNotifications: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionNotifications", decode$2, param);
                }), json)
        };
}

var Playstation5 = {
  decode: decode$6
};

function decode$7(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("attributeMapping", decode, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          projectId: Json_decode.field("projectId", Json_decode.string, json),
          coronerdAddress: Json_decode.field("coronerdAddress", Json_decode.string, json),
          creationDate: new Date(Json_decode.field("creationDate", Json_decode.string, json)),
          attributeMapping: Belt_Option.getWithDefault(__x, []),
          statistics: Json_decode.field("statistics", decode$1, json),
          disabled: Json_decode.field("disabled", Json_decode.bool, json),
          connectionNotifications: Json_decode.optional((function (param) {
                  return Json_decode.field("connectionNotifications", decode$2, param);
                }), json)
        };
}

var Unity = {
  decode: decode$7
};

function decodeDataSource(json) {
  var match = Json_decode.field("type", Json_decode.$$int, json);
  switch (match) {
    case 1 :
        return /* Playstation4 */Block.__(0, [decode$5(json)]);
    case 2 :
        return /* NintendoSwitch */Block.__(1, [decode$4(json)]);
    case 3 :
        return /* XBox */Block.__(2, [decode$3(json)]);
    case 4 :
        return /* Playstation5 */Block.__(3, [decode$6(json)]);
    case 5 :
        return /* Unity */Block.__(4, [decode$7(json)]);
    default:
      return Js_exn.raiseError("Invalid connector type");
  }
}

function getStatus(param) {
  switch (param.tag | 0) {
    case /* Playstation4 */0 :
        var match = param[0];
        if (match.disabled) {
          return "Disabled";
        } else if (match.statistics.totalNumberOfDownloadedFiles > 0.0) {
          return "Active";
        } else {
          return "Active - awaiting first download";
        }
    case /* NintendoSwitch */1 :
        var match$1 = param[0];
        if (match$1.disabled) {
          return "Disabled";
        } else if (match$1.statistics.totalNumberOfDownloadedFiles > 0.0) {
          return "Active";
        } else {
          return "Active - awaiting first download";
        }
    case /* XBox */2 :
        var match$2 = param[0];
        if (match$2.disabled) {
          return "Disabled";
        } else if (match$2.statistics.totalNumberOfDownloadedFiles > 0.0) {
          return "Active";
        } else {
          return "Active - awaiting first download";
        }
    case /* Playstation5 */3 :
        var match$3 = param[0];
        if (match$3.disabled) {
          return "Disabled";
        } else if (match$3.statistics.totalNumberOfDownloadedFiles > 0.0) {
          return "Active";
        } else {
          return "Active - awaiting first download";
        }
    case /* Unity */4 :
        var match$4 = param[0];
        if (match$4.disabled) {
          return "Disabled";
        } else if (match$4.statistics.totalNumberOfDownloadedFiles > 0.0) {
          return "Active";
        } else {
          return "Active - awaiting first download";
        }
    
  }
}

function getId(param) {
  switch (param.tag | 0) {
    case /* Playstation4 */0 :
    case /* NintendoSwitch */1 :
    case /* XBox */2 :
    case /* Playstation5 */3 :
    case /* Unity */4 :
        return param[0].id;
    
  }
}

function getName(param) {
  switch (param.tag | 0) {
    case /* Playstation4 */0 :
        var match = param[0];
        return Belt_Option.getWithDefault(match.name, "ps4_connection_" + String(match.id));
    case /* NintendoSwitch */1 :
        var match$1 = param[0];
        return Belt_Option.getWithDefault(match$1.name, "switch_connection_" + String(match$1.id));
    case /* XBox */2 :
        var match$2 = param[0];
        return Belt_Option.getWithDefault(match$2.name, "xbox_connection_" + match$2.bucketName);
    case /* Playstation5 */3 :
        var match$3 = param[0];
        return Belt_Option.getWithDefault(match$3.name, "ps5_connection_" + String(match$3.id));
    case /* Unity */4 :
        var match$4 = param[0];
        return Belt_Option.getWithDefault(match$4.name, "unity_connection_" + String(match$4.id));
    
  }
}

function getStatistics(param) {
  switch (param.tag | 0) {
    case /* NintendoSwitch */1 :
        return param[0].statistics;
    case /* Playstation4 */0 :
    case /* XBox */2 :
        return param[0].statistics;
    case /* Playstation5 */3 :
        return param[0].statistics;
    case /* Unity */4 :
        return param[0].statistics;
    
  }
}

function getAttributeMapping(param) {
  switch (param.tag | 0) {
    case /* NintendoSwitch */1 :
        return param[0].attributeMapping;
    case /* Playstation4 */0 :
    case /* XBox */2 :
        return param[0].attributeMapping;
    case /* Playstation5 */3 :
        return param[0].attributeMapping;
    case /* Unity */4 :
        return param[0].attributeMapping;
    
  }
}

var playstation4LogoInfo_002 = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* [] */0
    ]);

var playstation4LogoInfo = /* tuple */[
  "https://res.cloudinary.com/backtrace/image/upload/v1584115252/ps4logo.png",
  "sony playstation 4 logo",
  playstation4LogoInfo_002
];

var nintendoSwitchLogoInfo_002 = Css.style(/* :: */[
      Css.height(Css.px(36)),
      /* [] */0
    ]);

var nintendoSwitchLogoInfo = /* tuple */[
  "https://res.cloudinary.com/backtrace/image/upload/v1605044343/switch-logo.svg",
  "nintendo switch logo",
  nintendoSwitchLogoInfo_002
];

var xBoxLogoInfo_002 = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* [] */0
    ]);

var xBoxLogoInfo = /* tuple */[
  "https://res.cloudinary.com/backtrace/image/upload/v1608044925/xbox-logo-green-text.png",
  "xbox logo",
  xBoxLogoInfo_002
];

var playstation5LogoInfo_002 = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* [] */0
    ]);

var playstation5LogoInfo = /* tuple */[
  "https://res.cloudinary.com/backtrace/image/upload/v1608064193/ps5-logo.png",
  "sony playstation 5 logo",
  playstation5LogoInfo_002
];

var unityLogoInfo_002 = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* [] */0
    ]);

var unityLogoInfo = /* tuple */[
  "https://res.cloudinary.com/backtrace/image/upload/v1651074650/U_Logo_Black_RGB.svg",
  "unity logo",
  unityLogoInfo_002
];

function getLogoInfo(param) {
  switch (param.tag | 0) {
    case /* Playstation4 */0 :
        return playstation4LogoInfo;
    case /* NintendoSwitch */1 :
        return nintendoSwitchLogoInfo;
    case /* XBox */2 :
        return xBoxLogoInfo;
    case /* Playstation5 */3 :
        return playstation5LogoInfo;
    case /* Unity */4 :
        return unityLogoInfo;
    
  }
}

function addAttributeMappingsInForm(attributeMapping, form) {
  Belt_Array.forEachWithIndex(attributeMapping, (function (idx, param) {
          var name = "attributeMapping[" + (String(idx) + "][fromAttribute]");
          form.append(name, param[0]);
          var name$1 = "attributeMapping[" + (String(idx) + "][toAttribute]");
          form.append(name$1, param[1]);
          
        }));
  return form;
}

function addNotificationRecipientsInForm(notificationRecipients, sendNotifications, areNotificationsAvailable, form) {
  if (areNotificationsAvailable && sendNotifications) {
    if (notificationRecipients.length === 0) {
      form.append("connectionDisabledNotificationReceivers[0]", "");
    } else {
      Belt_Array.forEachWithIndex(notificationRecipients, (function (idx, v) {
              var name = "connectionDisabledNotificationReceivers[" + (String(idx) + "]");
              form.append(name, v);
              
            }));
    }
  }
  return form;
}

function prepareFormBody(formFields) {
  var form = new FormData();
  Belt_Array.forEach(formFields, (function (param) {
          var value = param[1];
          var name = param[0];
          form.append(name, value[1]);
          
        }));
  return form;
}

function getServiceVersion(serviceEndpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + "/healthcheck/"]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var serviceVersion = Json_decode.field("serviceVersion", Json_decode.string, param.json);
                  return /* Ok */Block.__(0, [serviceVersion]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  if (err[0] === Js_exn.$$Error) {
                    return /* Error */Block.__(1, [[
                                ConnectorError,
                                Belt_Option.getWithDefault(err[1].message, "Unknown error")
                              ]]);
                  }
                  throw err;
                }
              }), undefined, undefined);
}

function get(serviceEndpoint, projectName) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + projectName)]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var dataSources = Json_decode.array(decodeDataSource, param.json);
                  var version = param.headers.get("service-version");
                  return /* Ok */Block.__(0, [/* tuple */[
                              dataSources,
                              version === null ? undefined : Caml_option.some(version)
                            ]]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  if (err[0] === Js_exn.$$Error) {
                    return /* Error */Block.__(1, [[
                                ConnectorError,
                                Belt_Option.getWithDefault(err[1].message, "Unknown error")
                              ]]);
                  }
                  throw err;
                }
              }), undefined, undefined);
}

function post(projectName, serviceEndpoint, form) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + projectName)]), undefined, /* PostForm */Block.__(3, [form]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                var err = Json_decode.optional((function (param) {
                        return Json_decode.field("err", Json_decode.string, param);
                      }), json);
                if (err !== undefined) {
                  return /* Error */Block.__(1, [[
                              ConnectorError,
                              err
                            ]]);
                } else {
                  return /* Ok */Block.__(0, [json]);
                }
              }), undefined, undefined);
}

function toggleDisabled(id, serviceEndpoint, disabled) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + (String(id) + ("/toggle/" + (
                        disabled ? "1" : "0"
                      ))))]), undefined, /* PutForm */Block.__(4, [{ }]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                var err = Json_decode.optional((function (param) {
                        return Json_decode.field("err", Json_decode.string, param);
                      }), json);
                if (err !== undefined) {
                  return /* Error */Block.__(1, [[
                              ConnectorError,
                              err
                            ]]);
                } else {
                  return /* Ok */Block.__(0, [json]);
                }
              }), undefined, undefined);
}

function put(id, serviceEndpoint, form) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + String(id))]), undefined, /* PutForm */Block.__(4, [form]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                var err = Json_decode.optional((function (param) {
                        return Json_decode.field("err", Json_decode.string, param);
                      }), json);
                if (err !== undefined) {
                  return /* Error */Block.__(1, [[
                              ConnectorError,
                              err
                            ]]);
                } else {
                  return /* Ok */Block.__(0, [json]);
                }
              }), undefined, undefined);
}

function $$delete(serviceEndpoint, id) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + String(id))]), undefined, /* Delete */1, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                var err = Json_decode.optional((function (param) {
                        return Json_decode.field("err", Json_decode.string, param);
                      }), json);
                if (err !== undefined) {
                  return /* Error */Block.__(1, [[
                              ConnectorError,
                              err
                            ]]);
                } else {
                  return /* Ok */Block.__(0, [json]);
                }
              }), undefined, undefined);
}

function fetchRange(serviceEndpoint, id, from_, to_) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/connection/" + (String(id) + "/fetch"))]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "from",
                        Json_encode.date(from_)
                      ],
                      /* :: */[
                        /* tuple */[
                          "to",
                          Json_encode.date(to_)
                        ],
                        /* [] */0
                      ]
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return /* Ok */Block.__(0, [param.json]);
              }), undefined, undefined);
}

function getLogs(serviceEndpoint, id, filterOpt, takeOpt, pageOpt, param) {
  var filter = filterOpt !== undefined ? Caml_option.valFromOption(filterOpt) : undefined;
  var take = takeOpt !== undefined ? takeOpt : 20;
  var page = pageOpt !== undefined ? pageOpt : 0;
  var fullEndpoint = filter !== undefined ? serviceEndpoint + ("/connection/" + (String(id) + ("/text?take=" + (String(take) + ("&page=" + (String(page) + ("&text=" + filter))))))) : serviceEndpoint + ("/connection/" + (String(id) + ("/logs?take=" + (String(take) + ("&page=" + String(page))))));
  return Task2$BsConsole.make(/* Custom */Block.__(1, [fullEndpoint]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var logsOfJson = function (json) {
                  return Json_decode.list((function (json) {
                                var logType = Json_decode.field("type", Json_decode.$$int, json);
                                var tmp;
                                if (logType > 3 || logType < 0) {
                                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "DataSourcesApi/getLogs/decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
                                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                                        288368849,
                                        "log field type: " + (String(logType) + " not accounted for in decoder. Defaulted to type: Unknown")
                                      ]);
                                  tmp = /* Unknown */4;
                                } else {
                                  tmp = logType;
                                }
                                return {
                                        creationDate: Json_decode.field("creationDate", Json_decode.string, json),
                                        id: Json_decode.field("id", Json_decode.$$int, json),
                                        type_: tmp,
                                        message: Json_decode.field("message", Json_decode.string, json),
                                        connectionId: Json_decode.field("connectionId", Json_decode.$$int, json)
                                      };
                              }), json);
                };
                var json = param.json;
                var maybeResultArray = Js_json.decodeArray(json);
                if (maybeResultArray === undefined) {
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.JsonDecodeError,
                              "Missing response array."
                            ]]);
                }
                var j = Belt_Array.get(maybeResultArray, 0);
                if (j === undefined) {
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.JsonDecodeError,
                              "Missing results array."
                            ]]);
                }
                var v = Belt_Array.get(maybeResultArray, 1);
                var remainder = v !== undefined && Caml_int32.mod_(Json_decode.$$int(Caml_option.valFromOption(v)), take) !== 0 ? 1 : 0;
                var v$1 = Belt_Array.get(maybeResultArray, 1);
                var total = v$1 !== undefined ? Caml_int32.div(Json_decode.$$int(Caml_option.valFromOption(v$1)), take) : 0;
                return /* Ok */Block.__(0, [/* tuple */[
                            Curry._1(logsOfJson, Caml_option.valFromOption(j)),
                            total + remainder | 0
                          ]]);
              }), undefined, undefined);
}

exports.ConnectorError = ConnectorError;
exports.AttributeMapping = AttributeMapping;
exports.Statistics = Statistics;
exports.ConnectionNotifications = ConnectionNotifications;
exports.AttachmentStrategy = AttachmentStrategy;
exports.XBox = XBox;
exports.NintendoSwitch = NintendoSwitch;
exports.Playstation4 = Playstation4;
exports.Playstation5 = Playstation5;
exports.Unity = Unity;
exports.decodeDataSource = decodeDataSource;
exports.getStatus = getStatus;
exports.getId = getId;
exports.getName = getName;
exports.getStatistics = getStatistics;
exports.getAttributeMapping = getAttributeMapping;
exports.playstation4LogoInfo = playstation4LogoInfo;
exports.nintendoSwitchLogoInfo = nintendoSwitchLogoInfo;
exports.xBoxLogoInfo = xBoxLogoInfo;
exports.playstation5LogoInfo = playstation5LogoInfo;
exports.unityLogoInfo = unityLogoInfo;
exports.getLogoInfo = getLogoInfo;
exports.addAttributeMappingsInForm = addAttributeMappingsInForm;
exports.addNotificationRecipientsInForm = addNotificationRecipientsInForm;
exports.prepareFormBody = prepareFormBody;
exports.getServiceVersion = getServiceVersion;
exports.get = get;
exports.post = post;
exports.toggleDisabled = toggleDisabled;
exports.put = put;
exports.$$delete = $$delete;
exports.fetchRange = fetchRange;
exports.getLogs = getLogs;
/* playstation4LogoInfo Not a pure module */
