// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Icon$BsConsole = require("../bindings/Icon.js");

var component = RR$BsConsole.statelessComponent("BtCheckbox-BsConsole");

function make(theme, sizeOpt, onChange, checked, indeterminate, disabled, param) {
  var size = sizeOpt !== undefined ? sizeOpt : /* medium */-20425611;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var iconSize = size !== -20425611 ? (
                  size >= -9768761 ? 18 : 24
                ) : 20;
              var iStyle = Css.style(/* :: */[
                    Css.fontSize(Css.px(iconSize)),
                    /* :: */[
                      Css.height(Css.px(iconSize)),
                      /* :: */[
                        Css.width(Css.px(iconSize)),
                        /* [] */0
                      ]
                    ]
                  ]);
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                              undefined,
                              Belt_Option.map(checked, (function (c) {
                                      return /* `Bool */[
                                              737456202,
                                              c
                                            ];
                                    })),
                              undefined,
                              /* Primary */-791844958,
                              disabled,
                              undefined,
                              undefined,
                              undefined,
                              onChange,
                              indeterminate,
                              Caml_option.some(React.createElement("i", {
                                        className: iStyle
                                      }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, undefined, /* inherit_ */-601204732, "check_box", [])))),
                              Caml_option.some(React.createElement("i", {
                                        className: iStyle
                                      }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, undefined, /* inherit_ */-601204732, "indeterminate_check_box", [])))),
                              Caml_option.some(React.createElement("i", {
                                        className: iStyle
                                      }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, undefined, /* inherit_ */-601204732, "check_box_outline_blank", [])))),
                              Caml_option.some(size !== -20425611 ? (
                                      size >= -9768761 ? ({
                                            height: "24px",
                                            padding: "4px",
                                            width: "24px"
                                          }) : ({ })
                                    ) : ({
                                        height: "36px",
                                        padding: "8px",
                                        width: "36px"
                                      })),
                              []
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
