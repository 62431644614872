// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BinChart$BsConsole = require("./BinChart.js");
var Interpolate$BsConsole = require("./Interpolate.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsValue$BsConsole = require("./MetricsValue.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var ResizeObserver$BsConsole = require("./ResizeObserver.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function makeQuery(aperture) {
  var range = Crdb$BsConsole.ensureValidTimeRange(Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture)));
  var __x = Crdb$BsConsole.Fold.empty;
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "timestamp",
            /* Bin */Block.__(0, [
                64,
                range
              ])
          ]), __x);
  return /* Aggregate */Block.__(0, [
            aperture,
            /* Custom */["*"],
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function convertBinDataToFloats(binData) {
  return Belt_List.reduce(binData, [], (function (acc, param) {
                var count = param[2];
                var end_ = param[1];
                var start = param[0];
                if (start === undefined) {
                  return acc;
                }
                switch (start.tag | 0) {
                  case /* Raw */0 :
                      var match = start[0];
                      if (typeof match === "number") {
                        return acc;
                      }
                      var variant = match[0];
                      if (variant !== 365180284) {
                        if (variant !== 758940238) {
                          return acc;
                        }
                        if (end_ === undefined) {
                          return acc;
                        }
                        if (end_.tag) {
                          return acc;
                        }
                        var match$1 = end_[0];
                        if (typeof match$1 === "number" || match$1[0] !== 758940238) {
                          return acc;
                        } else {
                          return Belt_Array.concat(acc, [/* tuple */[
                                        Math.floor(match[1].valueOf() / 1000),
                                        Math.floor(match$1[1].valueOf() / 1000),
                                        count
                                      ]]);
                        }
                      }
                      if (end_ === undefined) {
                        return acc;
                      }
                      if (end_.tag) {
                        return acc;
                      }
                      var match$2 = end_[0];
                      if (typeof match$2 === "number" || match$2[0] !== 365180284) {
                        return acc;
                      } else {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      match[1],
                                      match$2[1],
                                      count
                                    ]]);
                      }
                  case /* UnixTimestamp */2 :
                      if (end_ !== undefined && end_.tag === /* UnixTimestamp */2) {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      Math.floor(start[0].valueOf() / 1000),
                                      Math.floor(end_[0].valueOf() / 1000),
                                      count
                                    ]]);
                      } else {
                        return acc;
                      }
                  case /* GpsTimestamp */3 :
                      if (end_ !== undefined && end_.tag === /* GpsTimestamp */3) {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      Math.floor(start[0].valueOf() / 1000),
                                      Math.floor(end_[0].valueOf() / 1000),
                                      count
                                    ]]);
                      } else {
                        return acc;
                      }
                  default:
                    return acc;
                }
              }));
}

function extractBinDataFromRows(rows, range, bins) {
  return Belt_Option.flatMap(Belt_List.getBy(rows, (function (row) {
                    return row.factor.value === "*";
                  })), (function (row) {
                return Belt_Option.flatMap(Belt_List.getBy(row.aggregations, (function (shape) {
                                  if (shape.tag === /* Bin */2) {
                                    return shape[0] === "timestamp";
                                  } else {
                                    return false;
                                  }
                                })), (function (aggregation) {
                              if (aggregation.tag !== /* Bin */2) {
                                return ;
                              }
                              var binData = aggregation[1];
                              if (binData === undefined) {
                                return ;
                              }
                              var __x = convertBinDataToFloats(binData);
                              return Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins, range[0], range[1]), __x);
                            }));
              }));
}

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.height(Css.px(106)),
          /* [] */0
        ]
      ]
    ]);

var countAndRangeContainer = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(16)),
        /* :: */[
          Css.left(Css.px(16)),
          /* [] */0
        ]
      ]
    ]);

var countLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* :: */[
        Css.fontFamily("Inconsolata, Roboto"),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey0)),
          /* [] */0
        ]
      ]
    ]);

var count = Css.style(/* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            600
          ]),
      /* [] */0
    ]);

var range = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(12)),
        /* :: */[
          Css.marginTop(Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var binChartContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.position(/* relative */903134412),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  container: container,
  countAndRangeContainer: countAndRangeContainer,
  countLabel: countLabel,
  count: count,
  range: range,
  binChartContainer: binChartContainer
};

function ErrorsOverTimeBinChart$DataChart(Props) {
  var binData = Props.binData;
  var range = Props.range;
  var onRangeChange = Props.onRangeChange;
  var chartContainerRef = React.useRef(null);
  var chartContainerBoundingRect = ResizeObserver$BsConsole.use(chartContainerRef);
  return React.createElement("div", {
              ref: chartContainerRef,
              className: binChartContainer
            }, React.createElement(BinChart$BsConsole.make, {
                  height: chartContainerBoundingRect.height,
                  width: chartContainerBoundingRect.width,
                  data: binData,
                  getBinRange: (function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    }),
                  getBinValue: (function (param) {
                      return param[2];
                    }),
                  range: range,
                  bins: 64,
                  renderTooltipData: (function (param) {
                      return React.createElement(Col2$BsConsole.make, {
                                  children: null
                                }, React.createElement(Row2$BsConsole.make, {
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.px(8)),
                                            /* [] */0
                                          ]),
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body2 */9,
                                            children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              " - ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ])
                                                        ]),
                                                      "%s - %s"
                                                    ]), L10N_date_time$BsConsole.Format.textAbbreviatedMonthDay(undefined, new Date(param[0] * 1000)), L10N_date_time$BsConsole.Format.textAbbreviatedMonthDay(undefined, new Date(param[1] * 1000)))
                                          })
                                    }), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.fontFamily("Inconsolata"),
                                            /* :: */[
                                              Css.marginLeft(/* auto */-1065951377),
                                              /* :: */[
                                                Css.fontSize(Css.px(16)),
                                                /* [] */0
                                              ]
                                            ]
                                          ])
                                    }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " errors",
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "%s errors"
                                            ]), Numeral$BsConsole.format("0,0", String(param[2])))));
                    }),
                  margins: {
                    top: 59,
                    bottom: 2,
                    left: 6,
                    right: 6
                  },
                  binPadding: 0.075,
                  onChartBrushSelection: (function (selectionData) {
                      var values = Belt_Option.flatMap((selectionData == null) ? undefined : Caml_option.some(selectionData), (function (data) {
                              return Caml_option.nullable_to_opt(data.xValues);
                            }));
                      var start = Belt_Option.flatMap(values, (function (v) {
                              return Belt_Array.get(v, 0);
                            }));
                      var end_ = Belt_Option.flatMap(Belt_Option.flatMap(values, (function (v) {
                                  return Belt_Array.get(v, v.length - 1 | 0);
                                })), (function (startValue) {
                              return Belt_Option.map(Belt_Array.getBy(binData, (function (param) {
                                                return param[0] === startValue;
                                              })), (function (param) {
                                            return param[1];
                                          }));
                            }));
                      if (start === undefined) {
                        return ;
                      }
                      if (end_ === undefined) {
                        return ;
                      }
                      var rangeStart = new Date(start * 1000);
                      var rangeEnd = new Date(end_ * 1000);
                      return Curry._1(onRangeChange, /* tuple */[
                                  rangeStart,
                                  rangeEnd
                                ]);
                    })
                }));
}

var DataChart = {
  make: ErrorsOverTimeBinChart$DataChart
};

function ErrorsOverTimeBinChart(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var onRangeChange = Props.onRangeChange;
  var classNameOpt = Props.className;
  var normByOpt = Props.normBy;
  var metricsAttributes = Props.metricsAttributes;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var normBy = normByOpt !== undefined ? Caml_option.valFromOption(normByOpt) : undefined;
  var query = makeQuery(aperture);
  var match = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "errors-over-time-bin-chart");
  var queryDataRemote = match[0];
  var stabilityScore = MetricsValue$BsConsole.use(aperture, normBy, token, projectName, metricsAttributes, /* Custom */["*"]);
  var tmp;
  var exit = 0;
  if (typeof queryDataRemote === "number") {
    exit = 1;
  } else if (queryDataRemote.tag) {
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          children: React.createElement(BtTypography$BsConsole.make, {
                variant: /* Caption */11,
                children: I18N$BsConsole.showSkip(queryDataRemote[0])
              })
        });
  } else {
    var match$1 = queryDataRemote[0];
    var _next = match$1[1];
    var match$2 = match$1[0];
    var queryForRequest = match$2[3];
    var rows = match$2[1];
    var exit$1 = 0;
    if (typeof _next === "number" && _next !== 0) {
      exit = 1;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      var match$3 = Crdb$BsConsole.ensureValidTimeRange(Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, queryForRequest))));
      var end_ = match$3[1];
      var start = match$3[0];
      var range_000 = Caml_int64.to_float(start);
      var range_001 = Caml_int64.to_float(end_);
      var range$1 = /* tuple */[
        range_000,
        range_001
      ];
      var $$default = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, 64, Caml_int64.to_float(start), Caml_int64.to_float(end_)), []);
      var binData;
      try {
        binData = Belt_Option.getWithDefault(extractBinDataFromRows(rows, range$1, 64), $$default);
      }
      catch (_err){
        binData = $$default;
      }
      var count$1 = Belt_Option.getWithDefault(Belt_Option.map(Belt_List.get(rows, 0), (function (group) {
                  return group.count;
                })), 0);
      var countString = Numeral$BsConsole.format("0,0", String(count$1));
      var match$4 = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, queryForRequest)));
      var tmp$1;
      tmp$1 = stabilityScore !== undefined && !(stabilityScore.tag || normBy === undefined) ? React.createElement(React.Fragment, undefined, React.createElement("span", undefined, I18N$BsConsole.showSkip(" / ")), React.createElement("span", {
                  className: count
                }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Int64.to_string(stabilityScore[0])))), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ]),
                      " %s"
                    ]), normBy)) : null;
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Col2$BsConsole.make, {
                className: countAndRangeContainer,
                children: null
              }, React.createElement("div", {
                    className: countLabel
                  }, React.createElement("span", {
                        className: count
                      }, I18N$BsConsole.showSkip(countString)), I18N$BsConsole.show(undefined, " errors"), tmp$1), React.createElement("div", {
                    className: range
                  }, I18N$BsConsole.dynamic(Util$BsConsole.formatDateRange(undefined, undefined, match$4[0], match$4[1], undefined)))), React.createElement(ErrorsOverTimeBinChart$DataChart, {
                binData: binData,
                range: range$1,
                onRangeChange: onRangeChange
              }));
    }
    
  }
  if (exit === 1) {
    tmp = React.createElement(Row2$BsConsole.make, {
          alignItems: /* center */98248149,
          justifyContent: /* center */98248149,
          className: Css.style(/* :: */[
                Css.height(Css.pct(100)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]
              ]),
          children: React.createElement(LinearProgress.default, {
                color: "primary",
                classes: {
                  root: Css.style(/* :: */[
                        Css.width(Css.px(200)),
                        /* [] */0
                      ])
                }
              })
        });
  }
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    container,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, tmp);
}

function make(token, projectName, aperture, onRangeChange, className, metricsAttributes, normBy, _children) {
  var tmp = {
    token: token,
    projectName: projectName,
    aperture: aperture,
    onRangeChange: onRangeChange,
    metricsAttributes: metricsAttributes
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (normBy !== undefined) {
    tmp.normBy = Caml_option.valFromOption(normBy);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ErrorsOverTimeBinChart, tmp, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = ErrorsOverTimeBinChart;

exports.makeQuery = makeQuery;
exports.convertBinDataToFloats = convertBinDataToFloats;
exports.extractBinDataFromRows = extractBinDataFromRows;
exports.Style = Style;
exports.DataChart = DataChart;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
