// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Styles = require("@material-ui/core/styles");
var Reprocessing_Tasks$BsConsole = require("./Reprocessing_Tasks.js");
var QueryPaginationTask$BsConsole = require("../QueryPaginationTask.js");
var ReprocessingProgressBar$BsConsole = require("./ReprocessingProgressBar.js");

var minCoronerdVersion = "1.43.0";

var theme = Styles.createMuiTheme({
      palette: {
        primary: {
          main: Colors$BsConsole.octothorpe(Colors$BsConsole.accent),
          light: Colors$BsConsole.octothorpe(Colors$BsConsole.accentLight),
          dark: Colors$BsConsole.octothorpe(Colors$BsConsole.accentDark),
          contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        },
        secondary: {
          main: Colors$BsConsole.octothorpe(Colors$BsConsole.accent),
          light: Colors$BsConsole.octothorpe(Colors$BsConsole.accentLight),
          dark: Colors$BsConsole.octothorpe(Colors$BsConsole.accentDark),
          contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        }
      }
    });

function useResultCountEffect(token, query, project) {
  var match = React.useState((function () {
          
        }));
  var setResultCount = match[1];
  var match$1 = QueryPaginationTask$BsConsole.CrdbSelect.use(token, project, query, "reprocess_query");
  var paginationRemote = match$1[0];
  React.useEffect((function () {
          if (typeof paginationRemote !== "number" && !paginationRemote.tag) {
            var numResults = Belt_Option.getWithDefault(paginationRemote[0][0][2].instances, 0);
            Curry._1(setResultCount, (function (param) {
                    return numResults;
                  }));
          }
          
        }), [paginationRemote]);
  return match[0];
}

function ReprocessQuery(Props) {
  var onCloseCb = Props.onCloseCb;
  var enableReloadBtnOpt = Props.enableReloadBtn;
  var token = Props.token;
  var project = Props.project;
  var universe = Props.universe;
  var query = Props.query;
  var gateCrdbVersionOpt = Props.gateCrdbVersion;
  var metricSourceOpt = Props.metricSource;
  var children = Props.children;
  var enableReloadBtn = enableReloadBtnOpt !== undefined ? enableReloadBtnOpt : false;
  var gateCrdbVersion = gateCrdbVersionOpt !== undefined ? gateCrdbVersionOpt : true;
  var metricSource = metricSourceOpt !== undefined ? Caml_option.valFromOption(metricSourceOpt) : undefined;
  var onCloseCb$1 = function (param) {
    if (onCloseCb !== undefined) {
      return Curry._1(onCloseCb, undefined);
    }
    
  };
  var match = React.useState((function () {
          return false;
        }));
  var setDialogOpen = match[1];
  var confirmAndReprocess = function (param) {
    if (gateCrdbVersion === true) {
      return CoronerdGate$BsConsole.snack(minCoronerdVersion, undefined, (function (param) {
                    return Curry._1(setDialogOpen, (function (param) {
                                  return true;
                                }));
                  }), undefined);
    } else {
      return Curry._1(setDialogOpen, (function (param) {
                    return true;
                  }));
    }
  };
  var resultCount = useResultCountEffect(token, query, project);
  var dialogContent = function (param) {
    if (resultCount !== undefined) {
      return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, I18N$BsConsole.showSkip(String(resultCount) + (" " + (I18N$BsConsole.get(undefined, "objects selected") + ".")))), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Are you sure you want to perform this reprocess operation?")), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Note: Some objects may not be reprocessed depending on your retention policy criteria.")]))));
    } else {
      return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
    }
  };
  var displayProgress = function (param) {
    var btn = enableReloadBtn ? ({
          label: I18N$BsConsole.get(undefined, "Refresh view"),
          onClick: (function (param) {
              window.location.reload(false);
              
            })
        }) : undefined;
    return SnackPurveyor$BsConsole.enqueue(btn, undefined, -1, Caml_option.some(ReasonReact.element(new Date().toISOString(), undefined, ReprocessingProgressBar$BsConsole.Re.make(token, "/" + (String(universe) + ("/" + (String(project) + ""))), []))), I18N$BsConsole.show(undefined, "Reprocessing status"));
  };
  return React.createElement(React.Fragment, undefined, Curry._1(children, /* tuple */[
                  confirmAndReprocess,
                  displayProgress
                ]), ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(theme, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                Css.style(/* :: */[
                                      Css.zIndex(1401),
                                      /* [] */0
                                    ]),
                                match[0],
                                (function (_e) {
                                    
                                  }),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [
                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [dialogContent(undefined)]))])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                      undefined,
                                                      undefined,
                                                      (function (_event) {
                                                          Curry._1(setDialogOpen, (function (param) {
                                                                  return false;
                                                                }));
                                                          var arg = Reprocessing_Tasks$BsConsole.reload(token, project, universe, CoronerdGate$BsConsole.isEnoughCoronerdVersion(minCoronerdVersion) ? query : undefined, undefined);
                                                          Curry._2((function (param) {
                                                                    return (function (param$1, param$2) {
                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                      });
                                                                  })(token), undefined, (function (resp) {
                                                                  if (resp.tag) {
                                                                    var err = resp[0];
                                                                    if (err[0] === Task2$BsConsole.CoronerError) {
                                                                      var match = err[1];
                                                                      if (Caml_obj.caml_equal(match.code, /* `Int */[
                                                                              3654863,
                                                                              65537
                                                                            ])) {
                                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Another reprocessing request is already in progress. Please try again later."));
                                                                      } else {
                                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Reload request failed: ") + match.message));
                                                                      }
                                                                    }
                                                                    console.log(JSON.stringify(err, null, 0));
                                                                    return ;
                                                                  }
                                                                  if (metricSource !== undefined) {
                                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [metricSource]));
                                                                  }
                                                                  return displayProgress(undefined);
                                                                }));
                                                          return onCloseCb$1(undefined);
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      /* Raised */-387916264,
                                                      /* Secondary */67972948,
                                                      undefined,
                                                      undefined,
                                                      false,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [I18N$BsConsole.get(undefined, "Yes")]
                                                    ])),
                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                      undefined,
                                                      undefined,
                                                      (function (_event) {
                                                          Curry._1(setDialogOpen, (function (param) {
                                                                  return false;
                                                                }));
                                                          return onCloseCb$1(undefined);
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      /* Secondary */67972948,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [I18N$BsConsole.get(undefined, "Cancel")]
                                                    ]))
                                          ]))
                                ]
                              ]))])));
}

function make(onCloseCb, enableReloadBtnOpt, token, project, universe, query, gateCrdbVersionOpt, metricSourceOpt, children) {
  var enableReloadBtn = enableReloadBtnOpt !== undefined ? enableReloadBtnOpt : false;
  var gateCrdbVersion = gateCrdbVersionOpt !== undefined ? gateCrdbVersionOpt : true;
  var metricSource = metricSourceOpt !== undefined ? Caml_option.valFromOption(metricSourceOpt) : undefined;
  var tmp = {
    enableReloadBtn: enableReloadBtn,
    token: token,
    project: project,
    universe: universe,
    query: query,
    gateCrdbVersion: gateCrdbVersion,
    metricSource: metricSource,
    children: children
  };
  if (onCloseCb !== undefined) {
    tmp.onCloseCb = Caml_option.valFromOption(onCloseCb);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ReprocessQuery, tmp, children);
}

var Re = {
  make: make
};

var make$1 = ReprocessQuery;

exports.minCoronerdVersion = minCoronerdVersion;
exports.theme = theme;
exports.useResultCountEffect = useResultCountEffect;
exports.make = make$1;
exports.Re = Re;
/* theme Not a pure module */
