// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Union$BsConsole = require("./Union.js");
var Ignore$BsConsole = require("./Ignore.js");
var SetValue$BsConsole = require("./SetValue.js");
var IndexOnly$BsConsole = require("./IndexOnly.js");

function toModule(param) {
  switch (param) {
    case /* IndexOnly */0 :
        return IndexOnly$BsConsole.Module;
    case /* Ignore */1 :
        return Ignore$BsConsole.Module;
    case /* SetValue */2 :
        return SetValue$BsConsole.Module;
    case /* Union */3 :
        return Union$BsConsole.Module;
    
  }
}

var indexOnlyModule = IndexOnly$BsConsole.Module;

var ignoreModule = Ignore$BsConsole.Module;

var setValueModule = SetValue$BsConsole.Module;

var unionModule = Union$BsConsole.Module;

exports.indexOnlyModule = indexOnlyModule;
exports.ignoreModule = ignoreModule;
exports.setValueModule = setValueModule;
exports.unionModule = unionModule;
exports.toModule = toModule;
/* Union-BsConsole Not a pure module */
