// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function update(user, token) {
  var nextMetadata = {
    lastSeen: Caml_option.some(new Date())
  };
  var arg = BpgTask$BsConsole.Users.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Users.to_key(user), [/* tuple */[
          "metadata",
          JSON.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Users.Metadata.to_json, nextMetadata))
        ]]);
  Curry._2((function (param) {
            return (function (param$1, param$2) {
                return Task2$BsConsole.handle(param, arg, param$1, param$2);
              });
          })(token), undefined, (function (_res) {
          
        }));
  
}

exports.update = update;
/* Task2-BsConsole Not a pure module */
