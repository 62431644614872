// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../Task2.js");
var Nemesis$BsConsole = require("./Nemesis.js");
var SubCardInfo$BsConsole = require("./SubCardInfo.js");
var BillingConstants$BsConsole = require("../BillingConstants.js");

function subscribedAddonsFromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          quantity: Json_decode.optional((function (param) {
                  return Json_decode.field("quantity", Json_decode.$$int, param);
                }), json)
        };
}

function subscribedAddonToJson(addon) {
  var q = addon.quantity;
  if (q !== undefined) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "id",
                  addon.id
                ],
                /* :: */[
                  /* tuple */[
                    "quantity",
                    q
                  ],
                  /* [] */0
                ]
              ]);
  } else {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "id",
                  addon.id
                ],
                /* [] */0
              ]);
  }
}

function subscriptionFromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          planId: Json_decode.field("planId", Json_decode.string, json),
          planQuantity: Json_decode.field("planQuantity", Json_decode.$$int, json),
          addons: Json_decode.field("addons", (function (param) {
                  return Json_decode.array(subscribedAddonsFromJson, param);
                }), json),
          cardOnFile: Json_decode.field("cardOnFile", Json_decode.bool, json),
          cardInfo: Json_decode.optional((function (param) {
                  return Json_decode.field("cardInfo", SubCardInfo$BsConsole.fromJson, param);
                }), json)
        };
}

function subscriptionsFromJson(json) {
  return Json_decode.at(/* :: */[
                "results",
                /* :: */[
                  "subscriptions",
                  /* [] */0
                ]
              ], (function (param) {
                  return Json_decode.array(subscriptionFromJson, param);
                }))(json);
}

function $$fetch(param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/subscriptions")]), undefined, /* Get */0, [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  return /* Ok */Block.__(0, [subscriptionsFromJson(param.json)]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                }
              }), undefined, undefined);
}

function update(planId, planQuantity, addons) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "plan",
          Json_encode.object_(/* :: */[
                /* tuple */[
                  "id",
                  planId
                ],
                /* :: */[
                  /* tuple */[
                    "quantity",
                    planQuantity
                  ],
                  /* [] */0
                ]
              ])
        ],
        /* :: */[
          /* tuple */[
            "addons",
            Json_encode.array(subscribedAddonToJson, addons)
          ],
          /* [] */0
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/subscriptions")]), undefined, /* Post */Block.__(0, [json]), [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  return /* Ok */Block.__(0, [subscriptionsFromJson(param.json)]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                }
              }), undefined, undefined);
}

exports.subscribedAddonsFromJson = subscribedAddonsFromJson;
exports.subscribedAddonToJson = subscribedAddonToJson;
exports.subscriptionFromJson = subscriptionFromJson;
exports.subscriptionsFromJson = subscriptionsFromJson;
exports.$$fetch = $$fetch;
exports.update = update;
/* Task2-BsConsole Not a pure module */
