// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Base$BsConsole = require("../bs-crdb-response/src/Base.js");
var I18N$BsConsole = require("../I18N.js");
var Fingerprint$BsConsole = require("./Fingerprint.js");
var BaseRenderer$BsConsole = require("./BaseRenderer.js");

function getAtLeastAndAtMostFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* AtLeast */Block.__(8, [value])
          ],
          /* tuple */[
            attribute,
            /* AtMost */Block.__(9, [value])
          ]
        ];
}

function getEqualsFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [value])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [value])
          ]
        ];
}

function Raw(Props) {
  var value = Props.value;
  var attribute = Props.attribute;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var alignment = value[0] !== 365180284 ? /* Left */847852583 : /* Right */-57574468;
  var variant = value[0];
  var filterOptions = variant >= 737456202 ? (
      variant >= 758940238 ? getAtLeastAndAtMostFilterOptions(attribute, /* `Date */[
              758940238,
              value[1]
            ]) : getEqualsFilterOptions(attribute, /* `Bool */[
              737456202,
              value[1]
            ])
    ) : (
      variant >= 365180284 ? getAtLeastAndAtMostFilterOptions(attribute, /* `Float */[
              365180284,
              value[1]
            ]) : getEqualsFilterOptions(attribute, /* `String */[
              -976970511,
              value[1]
            ])
    );
  if (attribute === "fingerprint" && typeof value !== "number" && value[0] === -976970511) {
    return React.createElement(Fingerprint$BsConsole.make, {
                value: Base$BsConsole.toString(value),
                attribute: attribute,
                className: className
              });
  }
  return React.createElement(BaseRenderer$BsConsole.make, {
              className: className,
              alignment: alignment,
              textValue: Base$BsConsole.toString(value),
              filterOptions: filterOptions,
              children: I18N$BsConsole.showSkip(Base$BsConsole.toString(value))
            });
}

var make = Raw;

exports.getAtLeastAndAtMostFilterOptions = getAtLeastAndAtMostFilterOptions;
exports.getEqualsFilterOptions = getEqualsFilterOptions;
exports.make = make;
/* react Not a pure module */
