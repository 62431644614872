// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var Calendar = { };

var Clock = { };

var defaults = {
  calendar: {
    numeric: {
      day: "YYYY-MM-DD",
      month: "YYYY-MM",
      monthDay: "MM-DD"
    },
    textFormal: {
      day: "EEE, Do MMMM, YYYY",
      month: "MMMM YYYY",
      monthDay: "MMMM Do"
    },
    textStandard: {
      day: "Do MMMM YYYY",
      month: "MMMM YYYY",
      monthDay: "MMMM Do"
    },
    textAbbreviated: {
      day: "Do MMM YYYY",
      month: "MMM YYYY",
      monthDay: "MMM Do"
    },
    year: "YYYY"
  },
  clock: {
    twelve: {
      minute: "hh:mm a",
      second: "hh:mm:ss a",
      millis: "hh:mm:ss.SSS a"
    },
    twentyFour: {
      minute: "HH:mm",
      second: "HH:mm:ss",
      millis: "HH:mm:ss.SSS"
    }
  }
};

exports.Calendar = Calendar;
exports.Clock = Clock;
exports.defaults = defaults;
/* No side effect */
