// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = ReasonReact.reducerComponent("WfBool-BsConsole");

function make(fieldDoc, initialValue, onValueChange, showAllErrors, schema, padding, remoteAction, param) {
  var validate = function (value) {
    var err = WfCore$BsConsole.Bool.validate(schema, value);
    if (err !== undefined) {
      return /* Error */Block.__(1, [err]);
    } else {
      return /* Ok */Block.__(0, [value]);
    }
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (param) {
                          return Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), param);
                        })));
              return Curry._1(remoteAction, (function (param) {
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var validatedValue = state.validatedValue;
              var send = param.send;
              var error;
              error = validatedValue.tag ? validatedValue[0] : undefined;
              return React.createElement("label", {
                          style: {
                            cursor: "pointer"
                          }
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, schema.required, padding, error, true, state.dirty, showAllErrors, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                            undefined,
                                            /* `Bool */[
                                              737456202,
                                              Belt_Option.getWithDefault(state.value, false)
                                            ],
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (param, newValue) {
                                                return Curry._1(send, /* Select */[newValue]);
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            {
                                              padding: "8px"
                                            },
                                            []
                                          ]))])));
            }),
          initialState: (function (param) {
              var initialValue$1;
              try {
                initialValue$1 = Belt_Option.map(initialValue, Json_decode.bool);
              }
              catch (exn){
                initialValue$1 = undefined;
              }
              var match = schema.defaultValue;
              var value = initialValue$1 !== undefined ? initialValue$1 : (
                  match !== undefined ? match : undefined
                );
              return {
                      value: value,
                      validatedValue: validate(value),
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      selectRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var value = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          value: value,
                          validatedValue: validate(value),
                          dirty: true,
                          elementRef: state.elementRef,
                          selectRef: state.selectRef
                        },
                        (function (param) {
                            return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (param) {
                                              return Json_encode.nullable((function (prim) {
                                                            return prim;
                                                          }), param);
                                            })));
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
