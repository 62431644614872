// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Shell$BsConsole = require("../shell.js");
var Task2$BsConsole = require("../Task2.js");
var WfRoot$BsConsole = require("./WfRoot.js");
var WfError$BsConsole = require("./WfError.js");
var WfTasks$BsConsole = require("./WfTasks.js");
var Services$BsConsole = require("../Services.js");
var WfWatcher$BsConsole = require("./WfWatcher.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var WatcherNewTask$BsConsole = require("../WatcherNewTask.js");

var component = RR$BsConsole.statelessComponent("ProjectWorkflow-BsConsole");

var workflowNotEnabled = React.createElement("div", {
      style: {
        display: "flex",
        padding: "32px 32px 0",
        width: "100%",
        alignItems: "center",
        flexDirection: "column"
      }
    }, React.createElement("div", {
          style: {
            maxWidth: "1000px",
            paddingTop: "24px",
            position: "relative",
            width: "100%"
          }
        }, ReasonReact.element(undefined, undefined, WfRoot$BsConsole.WorkflowItemWrap.make(true, [React.createElement("div", {
                        style: {
                          padding: "18px"
                        }
                      }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations are not enabled for this instance of coronerd.")])))]))));

function make(submissionToken, user, projectName, route, handleTask, handleChangeUrl, token, project, config, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              if (submissionToken === undefined) {
                return React.createElement("div", {
                            style: {
                              margin: "1em auto",
                              maxWidth: "1100px",
                              minWidth: "550px",
                              width: "90%",
                              flex: "5"
                            }
                          }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, {
                                    padding: "24px"
                                  }, undefined, undefined, [
                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                  Css.marginBottom(Css.rem(1.0)),
                                                  /* [] */0
                                                ]), /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations")])),
                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "You must create a submission token before configuring a workflow integration.")])),
                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                              I18N$BsConsole.show(undefined, "Create one here: "),
                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (_event) {
                                                            return Curry._1(handleChangeUrl, /* ProjectSettingsSubmissionTokens */Block.__(31, [projectName]));
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        /* Raised */-387916264,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [I18N$BsConsole.show(undefined, "Submission Tokens")]
                                                      ]))
                                            ]))
                                  ])));
              }
              var serviceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "hooks");
              var btDefaults = Json_encode.object_(/* :: */[
                    /* tuple */[
                      "bt-submission-endpoint",
                      Shell$BsConsole.getSubmissionEndpoint(submissionToken, config, /* JSON */0)
                    ],
                    /* :: */[
                      /* tuple */[
                        "bt-query-endpoint",
                        Shell$BsConsole.getQueryEndpoint(config)
                      ],
                      /* :: */[
                        /* tuple */[
                          "sca-service-endpoint",
                          Shell$BsConsole.getServiceEndpoint(config, "sca", "")
                        ],
                        /* :: */[
                          /* tuple */[
                            "current-project-name",
                            project.name
                          ],
                          /* :: */[
                            /* tuple */[
                              "current-project-token",
                              submissionToken.id
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
              var maybeTask = WfTasks$BsConsole.describeWorkflow(config);
              if (maybeTask !== undefined) {
                return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Caml_option.valFromOption(maybeTask), undefined, (function (integrationsRemote, _refetchIntegrations) {
                                  var maybeInnerTask = WatcherNewTask$BsConsole.fetchAllWatchers(config, projectName);
                                  if (maybeInnerTask !== undefined) {
                                    return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Caml_option.valFromOption(maybeInnerTask), undefined, (function (watchersRemote, refetchWatchers) {
                                                      if (typeof integrationsRemote === "number") {
                                                        return null;
                                                      }
                                                      if (!integrationsRemote.tag) {
                                                        if (typeof watchersRemote === "number") {
                                                          watchersRemote === /* NotAsked */0;
                                                        } else {
                                                          if (watchersRemote.tag) {
                                                            return ReasonReact.element(undefined, undefined, WfError$BsConsole.Jsx2.make("Could not load available watchers:", watchersRemote[0])([]));
                                                          }
                                                          var match = watchersRemote[0];
                                                          var watchers = Belt_Array.map(Belt_Array.keep(match[0], (function (watch) {
                                                                      return project.pid === watch.project;
                                                                    })), (function (param) {
                                                                  return WfWatcher$BsConsole.fromBpg(project, param);
                                                                }));
                                                          return ReasonReact.element(undefined, undefined, WfRoot$BsConsole.make(btDefaults, user, route, handleTask, (function (route) {
                                                                            return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                                                          projectName,
                                                                                          route,
                                                                                          undefined
                                                                                        ]));
                                                                          }), handleChangeUrl, token, config.universe.name, project, watchers, integrationsRemote[0][0], match[1], refetchWatchers, serviceEndpoint, config, []));
                                                        }
                                                      }
                                                      if (typeof watchersRemote === "number") {
                                                        return null;
                                                      } else {
                                                        return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, {
                                                                                  padding: "24px"
                                                                                }, undefined, undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                                                Css.marginBottom(Css.rem(1.0)),
                                                                                                /* [] */0
                                                                                              ]), /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations")])),
                                                                                  ReasonReact.element(undefined, undefined, WfError$BsConsole.Jsx2.make("Could not load available integrations:", integrationsRemote[0])([]))
                                                                                ]))]));
                                                      }
                                                    })));
                                  } else {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
                                    return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, {
                                                              padding: "24px"
                                                            }, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                            Css.marginBottom(Css.rem(1.0)),
                                                                            /* [] */0
                                                                          ]), /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations")])),
                                                              ReasonReact.element(undefined, undefined, WfError$BsConsole.Jsx2.make("Could not load available integrations:", [
                                                                          Task2$BsConsole.ErrorMsg,
                                                                          "Workflow service unavailable"
                                                                        ])([]))
                                                            ]))]));
                                  }
                                })));
              } else {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
                return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, {
                                          padding: "24px"
                                        }, undefined, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                        Css.marginBottom(Css.rem(1.0)),
                                                        /* [] */0
                                                      ]), /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations")])),
                                          ReasonReact.element(undefined, undefined, WfError$BsConsole.Jsx2.make("Could not load available integrations:", [
                                                      Task2$BsConsole.ErrorMsg,
                                                      "Workflow service unavailable"
                                                    ])([]))
                                        ]))]));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.workflowNotEnabled = workflowNotEnabled;
exports.make = make;
/* component Not a pure module */
