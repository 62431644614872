// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var support = "support@backtrace.io";

var sales = "sales@backtrace.io";

exports.support = support;
exports.sales = sales;
/* No side effect */
