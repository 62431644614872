// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactFlipToolkit = require("react-flip-toolkit");

function make(flipKey, children) {
  return ReasonReact.wrapJsForReason(ReactFlipToolkit.Flipper, {
              flipKey: flipKey
            }, children);
}

var Js = { };

exports.make = make;
exports.Js = Js;
/* ReasonReact Not a pure module */
