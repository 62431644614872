// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_math = require("bs-platform/lib/js/js_math.js");
var D3Array = require("d3-array");
var D3Brush = require("d3-brush");
var D3Scale = require("d3-scale");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var D3Selection = require("d3-selection");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var Is_equal = require("date-fns/is_equal");
var Selection$BsConsole = require("../bs-vx/src/Selection/Selection.js");
var Interpolate$BsConsole = require("../Interpolate.js");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var BsVx_WithTooltip$BsConsole = require("../bs-vx/src/Tooltip/BsVx_WithTooltip.js");
var AxisJs = require("@vx/axis/build/axis/Axis.js");
var RowsJs = require("@vx/grid/build/grids/Rows.js");
var BarJs = require("@vx/shape/build/shapes/Bar.js");
var ColumnsJs = require("@vx/grid/build/grids/Columns.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

var TooltipController = BsVx_WithTooltip$BsConsole.Make({ });

function maxInt(arr) {
  return Belt_Array.reduce(arr, 0, (function (acc, v) {
                if (v > acc) {
                  return v;
                } else {
                  return acc;
                }
              }));
}

function xScaleInt(xMax, param) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        0,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function xScaleFloat(xMin, xMax, param) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        xMin,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function xScaleDate(xMax, param) {
  var __x = D3Scale.scaleTime();
  var __x$1 = __x.rangeRound([
        0,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function yScale(yMax, data) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        yMax,
        0
      ]);
  return __x$1.domain([
              0,
              maxInt(data)
            ]);
}

function drawBrush(width, height, xScale, onChange, element, param) {
  D3Selection.select(element).attr("class", "brush").call(D3Brush.brushX().extent([
              [
                0,
                0
              ],
              [
                width,
                height
              ]
            ]).on("end", (function (param) {
              var selection = Selection$BsConsole.$$Event.selection(D3Selection.event);
              if (selection === undefined) {
                return ;
              }
              var arr = Belt_Array.map(selection, (function (param) {
                      return xScale.invert(param);
                    }));
              return Curry._1(onChange, /* tuple */[
                          Belt_Array.getExn(arr, 0),
                          Belt_Array.getExn(arr, 1)
                        ]);
            })));
  
}

function ChartsBin$DateChart(Props) {
  Props.arrow;
  var variantOpt = Props.variant;
  var barFill = Props.barFill;
  var barHoverFill = Props.barHoverFill;
  var numYAxisTicks = Props.numYAxisTicks;
  var hideXAxisOpt = Props.hideXAxis;
  var hideYAxisOpt = Props.hideYAxis;
  var hideGridColumnsOpt = Props.hideGridColumns;
  var hideGridRowsOpt = Props.hideGridRows;
  var data = Props.data;
  Props.marginTop;
  Props.marginLeft;
  var yMax = Props.yMax;
  var xMax = Props.xMax;
  var xScale = Props.xScale;
  var yScale = Props.yScale;
  var height = Props.height;
  var width = Props.width;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : false;
  var match = React.useState((function () {
          
        }));
  var setTooltip = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setCoords = match$1[1];
  var match$2 = React.useState((function () {
          
        }));
  var info = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setGroupRef = match$3[1];
  var themeVariant = React.useContext(BtThemeContext$BsConsole.context);
  var match$4;
  if (barFill !== undefined) {
    match$4 = barHoverFill !== undefined ? /* tuple */[
        barFill,
        barHoverFill
      ] : /* tuple */[
        barFill,
        barFill
      ];
  } else if (themeVariant !== undefined) {
    var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
    match$4 = /* tuple */[
      palette.primary.main,
      palette.primary.dark
    ];
  } else {
    match$4 = /* tuple */[
      Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
      Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark)
    ];
  }
  var barHoverFillColor = match$4[1];
  var barFillColor = match$4[0];
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(/* relative */903134412),
                    /* [] */0
                  ])
            }, React.createElement("svg", {
                  className: variant ? Css.style(/* :: */[
                          Css.cursor(/* crosshair */180897442),
                          /* [] */0
                        ]) : "",
                  height: String(height),
                  width: String(width),
                  onMouseLeave: (function (_e) {
                      return Curry._1(setTooltip, (function (param) {
                                    
                                  }));
                    }),
                  onMouseMove: (function (e) {
                      var x = e.clientX;
                      var y = e.clientY;
                      return Curry._1(setCoords, (function (param) {
                                    return /* tuple */[
                                            x,
                                            y
                                          ];
                                  }));
                    })
                }, React.createElement("g", {
                      ref: (function (r) {
                          var r$1 = (r == null) ? undefined : Caml_option.some(r);
                          return Curry._1(setGroupRef, (function (param) {
                                        return r$1;
                                      }));
                        }),
                      className: Css.style(/* :: */[
                            Css.unsafe("transform", "translate(" + (String(Css.marginLeft) + ("," + (String(Css.marginTop) + ")")))),
                            /* [] */0
                          ])
                    }, React.createElement("rect", {
                          height: String(yMax),
                          width: String(xMax),
                          fill: "transparent"
                        }), hideGridRows ? null : React.createElement(RowsJs.default, {
                            top: 0,
                            scale: yScale,
                            width: xMax
                          }), hideGridColumns ? null : React.createElement(ColumnsJs.default, {
                            scale: xScale,
                            height: yMax
                          }), hideXAxis ? null : React.createElement(AxisJs.default, {
                            hideAxisLine: true,
                            hideZero: true,
                            numTicks: 2,
                            scale: xScale,
                            tickLabelProps: (function (_tickValue, _index) {
                                return {
                                        dx: "-20px",
                                        dy: "0.25em",
                                        fontFamily: "inconsolata;monospace",
                                        fontSize: 10
                                      };
                              }),
                            top: yMax
                          }), hideYAxis ? null : React.createElement(AxisJs.default, {
                            hideAxisLine: true,
                            numTicks: numYAxisTicks,
                            orientation: "left",
                            scale: yScale,
                            tickLabelProps: (function (_tickValue, _index) {
                                return {
                                        dx: "-10px",
                                        dy: "2px",
                                        fontFamily: "monospace",
                                        fontSize: 10
                                      };
                              })
                          }), Belt_Array.mapWithIndex(data, (function (i, param) {
                            var y = param[2];
                            var x2 = param[1];
                            var x1 = param[0];
                            var isHovered = info !== undefined ? Is_equal(x1, info[0]) && Is_equal(x2, info[1]) : false;
                            var isHovered$1 = info !== undefined ? Is_equal(x1, info[0]) && Is_equal(x2, info[1]) : false;
                            return React.createElement(React.Fragment, {
                                        children: null,
                                        key: String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__fragment"))))
                                      }, React.createElement(BarJs.default, {
                                            x: Curry._1(xScale, x1),
                                            y: Curry._1(yScale, y) - 1 | 0,
                                            width: Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0),
                                            height: (yMax - Curry._1(yScale, y) | 0) + 1 | 0,
                                            fill: isHovered ? barHoverFillColor : barFillColor,
                                            key: String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__bar"))))
                                          }), React.createElement(BarJs.default, {
                                            x: Curry._1(xScale, x1),
                                            y: 0,
                                            width: Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0),
                                            height: yMax,
                                            fill: isHovered$1 ? barFillColor : "transparent",
                                            fillOpacity: 0.2,
                                            key: String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__background"))))
                                          }));
                          })))));
}

var DateChart = {
  drawBrush: drawBrush,
  make: ChartsBin$DateChart
};

var make = React.memo((function (Props) {
        var barFill = Props.barFill;
        var barHoverFill = Props.barHoverFill;
        var numYAxisTicks = Props.numYAxisTicks;
        var hideXAxisOpt = Props.hideXAxis;
        var hideYAxisOpt = Props.hideYAxis;
        var hideGridColumnsOpt = Props.hideGridColumns;
        var hideGridRowsOpt = Props.hideGridRows;
        var data = Props.data;
        var marginTop = Props.marginTop;
        var marginLeft = Props.marginLeft;
        var marginRight = Props.marginRight;
        var marginBottom = Props.marginBottom;
        var height = Props.height;
        var width = Props.width;
        var barWidthOpt = Props.barWidth;
        var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
        var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
        var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : false;
        var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : false;
        var barWidth = barWidthOpt !== undefined ? barWidthOpt : 12;
        var match = React.useState((function () {
                
              }));
        var tooltip = match[0];
        var yAxisWidth = hideYAxis ? 0 : 25;
        var xMax = ((width - marginLeft | 0) - marginRight | 0) - (
          hideXAxis ? 0 : 8
        ) | 0;
        var yMax = ((height - marginTop | 0) - marginBottom | 0) - (
          hideYAxis ? 0 : 8
        ) | 0;
        var xScale = xScaleFloat(0, xMax, Interpolate$BsConsole.Histogram.extent(data));
        var yScale$1 = yScale(yMax, $$Array.map((function (param) {
                    return param[2];
                  }), data));
        var svgWidth = width - ((
            hideXAxis ? 0 : 10
          ) + yAxisWidth | 0) | 0;
        var xTickSpacing = xMax / 5 | 0;
        var xBarSpacingModifier = (xTickSpacing / 2 | 0) - (barWidth / 2 | 0) | 0;
        var xTickLabelSpacing = xBarSpacingModifier + 4 | 0;
        var themeVariant = React.useContext(BtThemeContext$BsConsole.context);
        var match$1;
        if (barFill !== undefined) {
          match$1 = barHoverFill !== undefined ? /* tuple */[
              barFill,
              barHoverFill
            ] : /* tuple */[
              barFill,
              barFill
            ];
        } else if (themeVariant !== undefined) {
          var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
          match$1 = /* tuple */[
            palette.primary.main,
            palette.primary.dark
          ];
        } else {
          match$1 = /* tuple */[
            Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
            Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark)
          ];
        }
        var barHoverFillColor = match$1[1];
        var barFillColor = match$1[0];
        return React.createElement(React.Fragment, undefined, React.createElement("svg", {
                        height: String(height),
                        width: String(svgWidth)
                      }, React.createElement("g", {
                            className: Css.style(/* :: */[
                                  Css.unsafe("transform", "translate(" + (String(Css.marginLeft) + ("," + (String(Css.marginTop) + ")")))),
                                  /* [] */0
                                ])
                          }, React.createElement("rect", {
                                height: String(yMax),
                                width: String(xMax),
                                fill: "transparent"
                              }), hideGridRows ? null : React.createElement(RowsJs.default, {
                                  top: 0,
                                  scale: yScale$1,
                                  width: xMax,
                                  stroke: "#" + Colors$BsConsole.grey5
                                }), hideGridColumns ? null : React.createElement(ColumnsJs.default, {
                                  scale: xScale,
                                  height: yMax,
                                  stroke: "#" + Colors$BsConsole.grey5
                                }), React.createElement("g", undefined, hideXAxis ? null : React.createElement(AxisJs.default, {
                                      hideAxisLine: false,
                                      hideZero: true,
                                      orientation: "bottom",
                                      scale: xScale,
                                      tickFormat: (function (value, param) {
                                          var v = Numeral$BsConsole.format("0.0a", value);
                                          var v2 = v.includes(".0") ? Numeral$BsConsole.format("0a", value) : v;
                                          if (v2.includes("4")) {
                                            return "4+";
                                          } else {
                                            return " " + v2;
                                          }
                                        }),
                                      tickLabelProps: (function (_tickValue, _index) {
                                          return {
                                                  dx: String(xTickLabelSpacing) + "px",
                                                  dy: "0px",
                                                  textAnchor: "center",
                                                  fontFamily: "Inconsolata",
                                                  fontSize: 10,
                                                  fill: "#" + Colors$BsConsole.grey4
                                                };
                                        }),
                                      tickLength: 1,
                                      tickStroke: "#" + Colors$BsConsole.grey4,
                                      tickValues: [
                                        "0",
                                        "1",
                                        "2",
                                        "3",
                                        "4"
                                      ],
                                      top: yMax
                                    })), React.createElement("g", {
                                style: {
                                  transform: "translate(15px, 0px)"
                                }
                              }, hideYAxis ? null : React.createElement(AxisJs.default, {
                                      hideAxisLine: false,
                                      hideZero: true,
                                      numTicks: numYAxisTicks,
                                      orientation: "left",
                                      scale: yScale$1,
                                      tickFormat: (function (value, param) {
                                          var v = Numeral$BsConsole.format("0.0a", value);
                                          if (v.includes(".5")) {
                                            return "";
                                          } else {
                                            return Numeral$BsConsole.format("0a", v);
                                          }
                                        }),
                                      tickLabelProps: (function (_tickValue, _index) {
                                          return {
                                                  dx: "-5px",
                                                  dy: "8px",
                                                  textAnchor: "start",
                                                  fontFamily: "Inconsolata;monospace",
                                                  fontSize: 10,
                                                  fill: "#" + Colors$BsConsole.grey4
                                                };
                                        }),
                                      tickLength: 1
                                    })), Belt_Array.mapWithIndex(data, (function (i, param) {
                                  var y = param[2];
                                  var x1 = param[0];
                                  var isHovered = tooltip !== undefined;
                                  return React.createElement("svg", undefined, React.createElement("title", undefined, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* Int */Block.__(4, [
                                                              /* Int_d */0,
                                                              /* No_padding */0,
                                                              /* No_precision */0,
                                                              /* Char_literal */Block.__(12, [
                                                                  /* " " */32,
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* String_literal */Block.__(11, [
                                                                          " with a distance of ",
                                                                          /* Int */Block.__(4, [
                                                                              /* Int_d */0,
                                                                              /* No_padding */0,
                                                                              /* No_precision */0,
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ])
                                                                    ])
                                                                ])
                                                            ]),
                                                          "%d %s with a distance of %d"
                                                        ]), y, y === 1 ? "candidate" : "candidates", x1 | 0)), React.createElement(BarJs.default, {
                                                  x: Curry._1(xScale, x1) + xBarSpacingModifier | 0,
                                                  y: Curry._1(yScale$1, y),
                                                  width: barWidth,
                                                  height: yMax - Curry._1(yScale$1, y) | 0,
                                                  fill: isHovered ? barHoverFillColor : barFillColor,
                                                  key: String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(param[1]) + "__bar"))))
                                                }));
                                })))));
      }));

var SimilarityDistanceChart = {
  maxLabelLength: 50,
  make: make
};

function ChartsBin$Chart(Props) {
  var barFill = Props.barFill;
  var barHoverFill = Props.barHoverFill;
  var numYAxisTicks = Props.numYAxisTicks;
  var hideXAxisOpt = Props.hideXAxis;
  var hideYAxisOpt = Props.hideYAxis;
  var hideGridColumnsOpt = Props.hideGridColumns;
  var hideGridRowsOpt = Props.hideGridRows;
  var data = Props.data;
  Props.marginTop;
  Props.marginLeft;
  var yMax = Props.yMax;
  var xMax = Props.xMax;
  var height = Props.height;
  var width = Props.width;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : false;
  var match = React.useState((function () {
          
        }));
  var setTooltip = match[1];
  var tooltip = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setCoords = match$1[1];
  var match$2 = React.useState((function () {
          
        }));
  var info = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setGroupRef = match$3[1];
  var yAxisWidth = hideYAxis ? 0 : 25;
  var xScale = xScaleFloat(yAxisWidth, xMax, Interpolate$BsConsole.Histogram.extent(data));
  var yScale$1 = yScale(yMax, $$Array.map((function (param) {
              return param[2];
            }), data));
  var svgWidth = width - ((
      hideXAxis ? 0 : 10
    ) + yAxisWidth | 0) | 0;
  var themeVariant = React.useContext(BtThemeContext$BsConsole.context);
  var match$4;
  if (barFill !== undefined) {
    match$4 = barHoverFill !== undefined ? /* tuple */[
        barFill,
        barHoverFill
      ] : /* tuple */[
        barFill,
        barFill
      ];
  } else if (themeVariant !== undefined) {
    var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
    match$4 = /* tuple */[
      palette.primary.main,
      palette.primary.dark
    ];
  } else {
    match$4 = /* tuple */[
      Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
      Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark)
    ];
  }
  var barHoverFillColor = match$4[1];
  var barFillColor = match$4[0];
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(/* relative */903134412),
                    /* :: */[
                      Css.width(Css.px(width)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("svg", {
                  height: String(height),
                  width: String(svgWidth),
                  onMouseLeave: (function (_e) {
                      return Curry._1(setTooltip, (function (param) {
                                    
                                  }));
                    }),
                  onMouseMove: (function (e) {
                      var x = e.clientX;
                      var y = e.clientY;
                      return Curry._1(setCoords, (function (param) {
                                    return /* tuple */[
                                            x,
                                            y
                                          ];
                                  }));
                    })
                }, React.createElement("g", {
                      ref: (function (r) {
                          var r$1 = (r == null) ? undefined : Caml_option.some(r);
                          return Curry._1(setGroupRef, (function (param) {
                                        return r$1;
                                      }));
                        }),
                      className: Css.style(/* :: */[
                            Css.unsafe("transform", "translate(" + (String(Css.marginLeft) + ("," + (String(Css.marginTop) + ")")))),
                            /* [] */0
                          ])
                    }, React.createElement("rect", {
                          height: String(yMax),
                          width: String(xMax),
                          fill: "transparent"
                        }), hideGridRows ? null : React.createElement(RowsJs.default, {
                            top: 0,
                            scale: yScale$1,
                            width: xMax,
                            stroke: "#" + Colors$BsConsole.grey5
                          }), hideGridColumns ? null : React.createElement(ColumnsJs.default, {
                            scale: xScale,
                            height: yMax,
                            stroke: "#" + Colors$BsConsole.grey5
                          }), React.createElement("g", undefined, hideXAxis ? null : React.createElement(AxisJs.default, {
                                hideAxisLine: true,
                                hideZero: true,
                                numTicks: (width / 100 | 0) + 1 | 0,
                                orientation: "bottom",
                                scale: xScale,
                                tickFormat: (function (value, param) {
                                    var v = Numeral$BsConsole.format("0.0a", value);
                                    if (v.includes(".0")) {
                                      return Numeral$BsConsole.format("0a", value);
                                    } else {
                                      return v;
                                    }
                                  }),
                                tickLabelProps: (function (_tickValue, _index) {
                                    return {
                                            dy: "0",
                                            textAnchor: "start",
                                            fontFamily: "Inconsolata",
                                            fontSize: 10,
                                            fill: "#" + Colors$BsConsole.grey4
                                          };
                                  }),
                                tickLength: 4,
                                tickStroke: "#" + Colors$BsConsole.grey4,
                                top: yMax
                              })), React.createElement("g", {
                          style: {
                            transform: "translate(49px, 0px)"
                          }
                        }, hideYAxis ? null : React.createElement(AxisJs.default, {
                                hideAxisLine: true,
                                numTicks: numYAxisTicks,
                                orientation: "left",
                                scale: yScale$1,
                                tickLabelProps: (function (_tickValue, _index) {
                                    return {
                                            dx: "-2px",
                                            dy: "0.3em",
                                            textAnchor: "end",
                                            fontFamily: "Inconsolata;monospace",
                                            fontSize: 10
                                          };
                                  })
                              })), Belt_Array.mapWithIndex(data, (function (i, param) {
                            var y = param[2];
                            var x2 = param[1];
                            var x1 = param[0];
                            var isHovered = tooltip !== undefined ? x1 === tooltip[1] && x2 === tooltip[2] : false;
                            var isHovered$1 = info !== undefined ? x1 === info[0] && x2 === info[1] : false;
                            return React.createElement(React.Fragment, {
                                        children: null,
                                        key: String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__fragment"))))
                                      }, React.createElement(BarJs.default, {
                                            x: Curry._1(xScale, x1) + yAxisWidth | 0,
                                            y: Curry._1(yScale$1, y) - 1 | 0,
                                            width: Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0),
                                            height: (yMax - Curry._1(yScale$1, y) | 0) + 1 | 0,
                                            fill: isHovered ? barHoverFillColor : barFillColor,
                                            key: String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__bar"))))
                                          }), React.createElement(BarJs.default, {
                                            x: Curry._1(xScale, x1) + yAxisWidth | 0,
                                            y: 0,
                                            width: Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0),
                                            height: yMax,
                                            fill: isHovered$1 ? barHoverFillColor : "transparent",
                                            fillOpacity: 0.2,
                                            key: String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__background"))))
                                          }));
                          })))));
}

var Chart = {
  maxLabelLength: 50,
  make: ChartsBin$Chart
};

function toConsumable(bin) {
  return Belt_List.reduce(bin, undefined, (function (acc, param) {
                var count = param[2];
                var mf$prime$prime = param[1];
                var mf$prime = param[0];
                if (acc !== undefined) {
                  if (typeof acc === "number") {
                    return /* Unsupported */0;
                  }
                  switch (acc.tag | 0) {
                    case /* BinInt */0 :
                        if (mf$prime !== undefined && mf$prime.tag === /* Bytes */5 && mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                          return /* BinInt */Block.__(0, [/* :: */[
                                      /* tuple */[
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime[0])),
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime$prime[0])),
                                        count
                                      ],
                                      acc[0]
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    case /* BinFloat */1 :
                        if (mf$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match = mf$prime[0];
                        if (typeof match === "number") {
                          return /* Unsupported */0;
                        }
                        if (match[0] !== 365180284) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match$1 = mf$prime$prime[0];
                        if (typeof match$1 === "number" || match$1[0] !== 365180284) {
                          return /* Unsupported */0;
                        } else {
                          return /* BinFloat */Block.__(1, [/* :: */[
                                      /* tuple */[
                                        match[1],
                                        match$1[1],
                                        count
                                      ],
                                      acc[0]
                                    ]]);
                        }
                    case /* BinDate */2 :
                        var f$prime;
                        var f$prime$prime;
                        if (mf$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        switch (mf$prime.tag | 0) {
                          case /* Raw */0 :
                              var match$2 = mf$prime[0];
                              if (typeof match$2 === "number") {
                                return /* Unsupported */0;
                              }
                              if (match$2[0] !== 758940238) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime === undefined) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime.tag) {
                                return /* Unsupported */0;
                              }
                              var match$3 = mf$prime$prime[0];
                              if (typeof match$3 === "number") {
                                return /* Unsupported */0;
                              }
                              if (match$3[0] !== 758940238) {
                                return /* Unsupported */0;
                              }
                              f$prime = match$2[1];
                              f$prime$prime = match$3[1];
                              break;
                          case /* UnixTimestamp */2 :
                              if (mf$prime$prime === undefined) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime.tag !== /* UnixTimestamp */2) {
                                return /* Unsupported */0;
                              }
                              f$prime = mf$prime[0];
                              f$prime$prime = mf$prime$prime[0];
                              break;
                          default:
                            return /* Unsupported */0;
                        }
                        return /* BinDate */Block.__(2, [/* :: */[
                                    /* tuple */[
                                      f$prime,
                                      f$prime$prime,
                                      count
                                    ],
                                    acc[0]
                                  ]]);
                    
                  }
                } else {
                  if (mf$prime === undefined) {
                    return /* Unsupported */0;
                  }
                  switch (mf$prime.tag | 0) {
                    case /* Raw */0 :
                        var match$4 = mf$prime[0];
                        if (typeof match$4 === "number") {
                          return /* Unsupported */0;
                        }
                        var variant = match$4[0];
                        if (variant !== 365180284) {
                          if (variant !== 758940238) {
                            return /* Unsupported */0;
                          }
                          if (mf$prime$prime === undefined) {
                            return /* Unsupported */0;
                          }
                          if (mf$prime$prime.tag) {
                            return /* Unsupported */0;
                          }
                          var match$5 = mf$prime$prime[0];
                          if (typeof match$5 === "number" || match$5[0] !== 758940238) {
                            return /* Unsupported */0;
                          } else {
                            return /* BinDate */Block.__(2, [/* :: */[
                                        /* tuple */[
                                          match$4[1],
                                          match$5[1],
                                          count
                                        ],
                                        /* [] */0
                                      ]]);
                          }
                        }
                        if (mf$prime$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match$6 = mf$prime$prime[0];
                        if (typeof match$6 === "number" || match$6[0] !== 365180284) {
                          return /* Unsupported */0;
                        } else {
                          return /* BinFloat */Block.__(1, [/* :: */[
                                      /* tuple */[
                                        match$4[1],
                                        match$6[1],
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        }
                    case /* UnixTimestamp */2 :
                        if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* UnixTimestamp */2) {
                          return /* BinDate */Block.__(2, [/* :: */[
                                      /* tuple */[
                                        mf$prime[0],
                                        mf$prime$prime[0],
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    case /* Bytes */5 :
                        if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                          return /* BinInt */Block.__(0, [/* :: */[
                                      /* tuple */[
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime[0])),
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime$prime[0])),
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    default:
                      return /* Unsupported */0;
                  }
                }
              }));
}

function binToConsumable(bin) {
  return Belt_List.reduce(bin, /* [] */0, (function (acc, param) {
                if (acc === undefined) {
                  return ;
                }
                var count = param[2];
                var mf$prime$prime = param[1];
                var mf$prime = param[0];
                if (mf$prime === undefined) {
                  return ;
                }
                switch (mf$prime.tag | 0) {
                  case /* Raw */0 :
                      var match = mf$prime[0];
                      var variant = match[0];
                      if (variant >= 737456202) {
                        if (variant >= 758940238) {
                          if (mf$prime$prime === undefined) {
                            return ;
                          }
                          if (mf$prime$prime.tag) {
                            return ;
                          }
                          var match$1 = mf$prime$prime[0];
                          if (typeof match$1 === "number" || match$1[0] !== 758940238) {
                            return ;
                          } else {
                            return /* :: */[
                                    /* tuple */[
                                      match[1].valueOf() | 0,
                                      match$1[1].valueOf() | 0,
                                      count
                                    ],
                                    acc
                                  ];
                          }
                        }
                        if (mf$prime$prime === undefined) {
                          return ;
                        }
                        if (mf$prime$prime.tag) {
                          return ;
                        }
                        return ;
                      }
                      if (variant >= 365180284) {
                        if (mf$prime$prime === undefined) {
                          return ;
                        }
                        if (mf$prime$prime.tag) {
                          return ;
                        }
                        var match$2 = mf$prime$prime[0];
                        if (typeof match$2 === "number" || match$2[0] !== 365180284) {
                          return ;
                        } else {
                          return /* :: */[
                                  /* tuple */[
                                    match[1] | 0,
                                    match$2[1] | 0,
                                    count
                                  ],
                                  acc
                                ];
                        }
                      }
                      if (mf$prime$prime === undefined) {
                        return ;
                      }
                      if (mf$prime$prime.tag) {
                        return ;
                      }
                      return ;
                  case /* UnixTimestamp */2 :
                      if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* UnixTimestamp */2) {
                        return /* :: */[
                                /* tuple */[
                                  mf$prime[0].valueOf() | 0,
                                  mf$prime$prime[0].valueOf() | 0,
                                  count
                                ],
                                acc
                              ];
                      } else {
                        return ;
                      }
                  case /* Classifiers */4 :
                      return ;
                  case /* Bytes */5 :
                      if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                        return /* :: */[
                                /* tuple */[
                                  Caml_format.caml_int_of_string(mf$prime[0]),
                                  Caml_format.caml_int_of_string(mf$prime$prime[0]),
                                  count
                                ],
                                acc
                              ];
                      } else {
                        return ;
                      }
                  default:
                    return ;
                }
              }));
}

function ChartsBin(Props) {
  var variantOpt = Props.variant;
  var binsOpt = Props.bins;
  var numYAxisTicksOpt = Props.numYAxisTicks;
  var range = Props.range;
  var heightOpt = Props.height;
  var widthOpt = Props.width;
  var hideXAxisOpt = Props.hideXAxis;
  var hideYAxisOpt = Props.hideYAxis;
  var hideGridRowsOpt = Props.hideGridRows;
  var hideGridColumnsOpt = Props.hideGridColumns;
  var marginLeftOpt = Props.marginLeft;
  var marginTopOpt = Props.marginTop;
  var marginRightOpt = Props.marginRight;
  var marginBottomOpt = Props.marginBottom;
  var barFill = Props.barFill;
  var barHoverFill = Props.barHoverFill;
  var bin = Props.bin;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var bins = binsOpt !== undefined ? binsOpt : 32;
  var numYAxisTicks = numYAxisTicksOpt !== undefined ? numYAxisTicksOpt : 6;
  var height = heightOpt !== undefined ? heightOpt : 100;
  var width = widthOpt !== undefined ? widthOpt : 600;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : true;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : true;
  var marginLeft = marginLeftOpt !== undefined ? marginLeftOpt : 0;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : 20;
  var marginRight = marginRightOpt !== undefined ? marginRightOpt : 20;
  var marginBottom = marginBottomOpt !== undefined ? marginBottomOpt : 30;
  var xMax = function (width) {
    return ((width - marginLeft | 0) - marginRight | 0) - (
            hideXAxis ? 0 : 8
          ) | 0;
  };
  var yMax = function (height) {
    return (height - marginTop | 0) - marginBottom | 0;
  };
  var data = toConsumable(bin);
  if (data === undefined) {
    return I18N$BsConsole.show(undefined, "No data");
  }
  if (typeof data === "number") {
    return I18N$BsConsole.show(undefined, "Unsupported");
  }
  switch (data.tag | 0) {
    case /* BinInt */0 :
        var data$prime = Belt_List.map(data[0], (function (param) {
                return /* tuple */[
                        param[0],
                        param[1],
                        param[2]
                      ];
              }));
        var extent = D3Array.extent(Belt_List.reduce(data$prime, [], (function (acc, param) {
                    return Belt_Array.concat(acc, [
                                param[0],
                                param[1]
                              ]);
                  })));
        var bins$1 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, extent[0], extent[1]);
        var arr = Interpolate$BsConsole.Histogram.merge(bins$1, Belt_List.toArray(Belt_List.reverse(data$prime)));
        var height$1 = (height + marginTop | 0) + marginBottom | 0;
        var xMax$1 = xMax(width);
        var yMax$1 = yMax(height$1);
        var tmp = {
          numYAxisTicks: numYAxisTicks,
          hideXAxis: hideXAxis,
          hideYAxis: hideYAxis,
          hideGridColumns: hideGridColumns,
          hideGridRows: hideGridRows,
          data: arr,
          marginTop: marginTop,
          marginLeft: marginLeft,
          yMax: yMax$1,
          xMax: xMax$1,
          height: height$1,
          width: width
        };
        if (barFill !== undefined) {
          tmp.barFill = Caml_option.valFromOption(barFill);
        }
        if (barHoverFill !== undefined) {
          tmp.barHoverFill = Caml_option.valFromOption(barHoverFill);
        }
        return React.createElement("div", undefined, React.createElement(ChartsBin$Chart, tmp));
    case /* BinFloat */1 :
        var data$prime$1 = data[0];
        var extent$1 = D3Array.extent(Belt_List.reduce(data$prime$1, [], (function (acc, param) {
                    return Belt_Array.concat(acc, [
                                param[0],
                                param[1]
                              ]);
                  })));
        var bins$2 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, extent$1[0], extent$1[1]);
        var arr$1 = Interpolate$BsConsole.Histogram.merge(bins$2, Belt_List.toArray(Belt_List.reverse(data$prime$1)));
        var height$2 = (height + marginTop | 0) + marginBottom | 0;
        var xMax$2 = xMax(width);
        var yMax$2 = yMax(height$2);
        var tmp$1 = {
          numYAxisTicks: numYAxisTicks,
          hideXAxis: hideXAxis,
          hideYAxis: hideYAxis,
          hideGridColumns: hideGridColumns,
          hideGridRows: hideGridRows,
          data: arr$1,
          marginTop: marginTop,
          marginLeft: marginLeft,
          yMax: yMax$2,
          xMax: xMax$2,
          height: height$2,
          width: width
        };
        if (barFill !== undefined) {
          tmp$1.barFill = Caml_option.valFromOption(barFill);
        }
        if (barHoverFill !== undefined) {
          tmp$1.barHoverFill = Caml_option.valFromOption(barHoverFill);
        }
        return React.createElement("div", undefined, React.createElement(ChartsBin$Chart, tmp$1));
    case /* BinDate */2 :
        var data$prime$2 = data[0];
        var extent$2 = D3Array.extent(Belt_List.reduce(data$prime$2, [], (function (acc, param) {
                    return Belt_Array.concat(acc, [
                                param[0],
                                param[1]
                              ]);
                  })));
        var extent$prime = Belt_Option.getWithDefault(range, extent$2);
        var bins$3 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, Math.floor(extent$prime[0].valueOf() / 1000), Math.floor(extent$prime[1].valueOf() / 1000));
        var arr$2;
        try {
          arr$2 = Belt_Array.map(Interpolate$BsConsole.Histogram.merge(bins$3, Belt_List.toArray(Belt_List.reverse(Belt_List.map(data$prime$2, (function (param) {
                                  return /* tuple */[
                                          Math.floor(param[0].valueOf() / 1000),
                                          Math.floor(param[1].valueOf() / 1000),
                                          param[2]
                                        ];
                                }))))), (function (param) {
                  return /* tuple */[
                          new Date(param[0] * 1000),
                          new Date(param[1] * 1000),
                          param[2]
                        ];
                }));
        }
        catch (exn){
          arr$2 = undefined;
        }
        if (arr$2 === undefined) {
          return React.createElement("div", {
                      style: {
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        justifyContent: "center"
                      }
                    }, React.createElement(CircularProgress.default, { }));
        }
        var height$3 = (height + marginTop | 0) + marginBottom | 0;
        var xMax$3 = xMax(width);
        var yMax$3 = yMax(height$3);
        var sx = xScaleDate(xMax$3, Interpolate$BsConsole.Histogram.extentDate(arr$2));
        var sy = yScale(yMax$3, $$Array.map((function (param) {
                    return param[2];
                  }), arr$2));
        var tmp$2 = {
          variant: variant,
          numYAxisTicks: numYAxisTicks,
          hideXAxis: hideXAxis,
          hideYAxis: hideYAxis,
          hideGridColumns: hideGridColumns,
          hideGridRows: hideGridRows,
          data: arr$2,
          marginTop: marginTop,
          marginLeft: marginLeft,
          yMax: yMax$3,
          xMax: xMax$3,
          xScale: sx,
          yScale: sy,
          height: height$3,
          width: width
        };
        if (barFill !== undefined) {
          tmp$2.barFill = Caml_option.valFromOption(barFill);
        }
        if (barHoverFill !== undefined) {
          tmp$2.barHoverFill = Caml_option.valFromOption(barHoverFill);
        }
        return React.createElement(ChartsBin$DateChart, tmp$2);
    
  }
}

var make$1 = ChartsBin;

exports.TooltipController = TooltipController;
exports.maxInt = maxInt;
exports.xScaleInt = xScaleInt;
exports.xScaleFloat = xScaleFloat;
exports.xScaleDate = xScaleDate;
exports.yScale = yScale;
exports.DateChart = DateChart;
exports.SimilarityDistanceChart = SimilarityDistanceChart;
exports.Chart = Chart;
exports.toConsumable = toConsumable;
exports.binToConsumable = binToConsumable;
exports.make = make$1;
/* TooltipController Not a pure module */
