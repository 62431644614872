// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var TeamsUtils$BsConsole = require("./TeamsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Button = require("@material-ui/core/Button");

var textRemove = I18N$BsConsole.show(undefined, "Remove");

var textDeleteTeamMember = I18N$BsConsole.show(undefined, "Removing members from a team will result in inability for those members to access projects restricted to the team. Removing members from a team will fail if the member is an owner of any objects within those projects.");

var textDeleteTeam = I18N$BsConsole.show(undefined, "Deleting teams may eliminate member's ability to access projects unless they are an organization administrator. Deletion will fail if team members own any objects within those projects.");

var textRemoveTeamFromProject = I18N$BsConsole.show(undefined, "Removing a team will eliminate their ability to access the project unless they are an organization administrator. If a member is an owner of any object, ownership must be transferred before the member is removed.");

var textRemoveUserFromProject = I18N$BsConsole.show(undefined, "Removing a user will eliminate their ability to access the project unless they are an organization administrator. If a member is an owner of any object, ownership must be transferred before the member is removed.");

function toTitleString(param) {
  switch (param.tag | 0) {
    case /* Team */0 :
        return I18N$BsConsole.get(undefined, "Delete team");
    case /* TeamMember */1 :
        return I18N$BsConsole.get(undefined, "Remove member from team");
    case /* ProjectMemberTeam */2 :
        return I18N$BsConsole.get(undefined, "Remove team access from project");
    case /* ProjectMemberUser */3 :
        return I18N$BsConsole.get(undefined, "Remove user access from project");
    
  }
}

function toActionString(param) {
  if (param.tag) {
    return textRemove;
  } else {
    return I18N$BsConsole.show(undefined, "Delete");
  }
}

function toDeleteDescriptionString(param) {
  switch (param.tag | 0) {
    case /* Team */0 :
        return textDeleteTeam;
    case /* TeamMember */1 :
        return textDeleteTeamMember;
    case /* ProjectMemberTeam */2 :
        return textRemoveTeamFromProject;
    case /* ProjectMemberUser */3 :
        return textRemoveUserFromProject;
    
  }
}

var DeleteType = {
  toTitleString: toTitleString,
  toActionString: toActionString,
  toDeleteDescriptionString: toDeleteDescriptionString
};

function TeamsDeleteDialog(Props) {
  var token = Props.token;
  var openDialog = Props.openDialog;
  var closeDialog = Props.closeDialog;
  var refetch = Props.refetch;
  var tmp;
  if (openDialog !== undefined) {
    switch (openDialog.tag | 0) {
      case /* Team */0 :
          tmp = React.createElement(BtSettings$BsConsole.FormInput.make, {
                placeholder: "",
                value: openDialog[2].name,
                label: I18N$BsConsole.show(undefined, "Team"),
                onChange: (function (param) {
                    
                  }),
                disabled: true,
                className: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ])
              });
          break;
      case /* TeamMember */1 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[2].name,
                    label: I18N$BsConsole.show(undefined, "Team"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[3].username,
                    label: I18N$BsConsole.show(undefined, "User"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }));
          break;
      case /* ProjectMemberTeam */2 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[2].name,
                    label: I18N$BsConsole.show(undefined, "Team"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[3].name,
                    label: I18N$BsConsole.show(undefined, "Project"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }));
          break;
      case /* ProjectMemberUser */3 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[2].username,
                    label: I18N$BsConsole.show(undefined, "User"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: "",
                    value: openDialog[3].name,
                    label: I18N$BsConsole.show(undefined, "Project"),
                    onChange: (function (param) {
                        
                      }),
                    disabled: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }));
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: openDialog !== undefined,
              onClose: closeDialog,
              title: openDialog !== undefined ? toTitleString(openDialog) : "",
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* flexEnd */924268066,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(5)),
                              /* :: */[
                                Css.marginBottom(Css.px(15)),
                                /* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: openDialog !== undefined ? toDeleteDescriptionString(openDialog) : null
                      }), tmp, React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: false,
                              onClick: (function (param) {
                                  if (openDialog !== undefined) {
                                    switch (openDialog.tag | 0) {
                                      case /* Team */0 :
                                          var t = openDialog[2];
                                          var universe = openDialog[1];
                                          var id = openDialog[0];
                                          var arg = BpgTask$BsConsole.Team.deleteByKey({
                                                id: id,
                                                universe: universe
                                              });
                                          Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), true, (function (results) {
                                                  TeamsUtils$BsConsole.handleResults(results, $$String.lowercase(toTitleString(/* Team */Block.__(0, [
                                                                  id,
                                                                  universe,
                                                                  t
                                                                ]))), refetch);
                                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_DeleteTeam */62]));
                                                }));
                                          break;
                                      case /* TeamMember */1 :
                                          var u = openDialog[3];
                                          var t$1 = openDialog[2];
                                          var user = openDialog[1];
                                          var team = openDialog[0];
                                          var arg$1 = BpgTask$BsConsole.TeamMember.deleteByKey({
                                                team: team,
                                                user: user
                                              });
                                          Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                                      });
                                                  })(token), true, (function (results) {
                                                  TeamsUtils$BsConsole.handleResults(results, $$String.lowercase(toTitleString(/* TeamMember */Block.__(1, [
                                                                  team,
                                                                  user,
                                                                  t$1,
                                                                  u
                                                                ]))), refetch);
                                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_RemoveTeamMember */64]));
                                                }));
                                          break;
                                      case /* ProjectMemberTeam */2 :
                                          var proj = openDialog[3];
                                          var t$2 = openDialog[2];
                                          var team$1 = openDialog[1];
                                          var project = openDialog[0];
                                          var arg$2 = BpgTask$BsConsole.ProjectMemberTeam.deleteByKey({
                                                project: project,
                                                team: team$1
                                              });
                                          Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg$2, param$1, param$2);
                                                      });
                                                  })(token), true, (function (results) {
                                                  TeamsUtils$BsConsole.handleResults(results, $$String.lowercase(toTitleString(/* ProjectMemberTeam */Block.__(2, [
                                                                  project,
                                                                  team$1,
                                                                  t$2,
                                                                  proj
                                                                ]))), refetch);
                                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_DeleteProjectMemberTeam */66]));
                                                }));
                                          break;
                                      case /* ProjectMemberUser */3 :
                                          var proj$1 = openDialog[3];
                                          var u$1 = openDialog[2];
                                          var user$1 = openDialog[1];
                                          var project$1 = openDialog[0];
                                          var arg$3 = BpgTask$BsConsole.ProjectMemberUser.deleteByKey({
                                                project: project$1,
                                                user: user$1
                                              });
                                          Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg$3, param$1, param$2);
                                                      });
                                                  })(token), true, (function (results) {
                                                  TeamsUtils$BsConsole.handleResults(results, $$String.lowercase(toTitleString(/* ProjectMemberUser */Block.__(3, [
                                                                  project$1,
                                                                  user$1,
                                                                  u$1,
                                                                  proj$1
                                                                ]))), refetch);
                                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_DeleteProjectMemberUser */68]));
                                                }));
                                          break;
                                      
                                    }
                                  }
                                  return Curry._1(closeDialog, undefined);
                                }),
                              children: openDialog !== undefined ? toActionString(openDialog) : null
                            })
                      }))
            });
}

var make = TeamsDeleteDialog;

exports.textRemove = textRemove;
exports.textDeleteTeamMember = textDeleteTeamMember;
exports.textDeleteTeam = textDeleteTeam;
exports.textRemoveTeamFromProject = textRemoveTeamFromProject;
exports.textRemoveUserFromProject = textRemoveUserFromProject;
exports.DeleteType = DeleteType;
exports.make = make;
/* textRemove Not a pure module */
