// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Reprocessing_Tasks$BsConsole = require("./Reprocessing_Tasks.js");

var container = Css.style(/* :: */[
      Css.padding(/* `percent */[
            -119887163,
            5
          ]),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.justifyContent(/* flexStart */662439529),
          /* :: */[
            Css.alignItems(/* flexStart */662439529),
            /* :: */[
              Css.flexWrap(/* wrap */-822134326),
              /* :: */[
                Css.flexDirection(/* column */-963948842),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  container: container
};

function ReprocessingProgressBar(Props) {
  var token = Props.token;
  var projectPath = Props.projectPath;
  var match = React.useState((function () {
          
        }));
  var setJob = match[1];
  var job = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setCompletedJob = match$1[1];
  var completedJob = match$1[0];
  React.useEffect((function () {
          var retryCount = {
            contents: 3
          };
          var update_progress = function (param) {
            var cb = function (result) {
              if (result.tag) {
                console.log(result[0]);
                return ;
              }
              var control = result[0];
              var job = Js_dict.get(control.reloads, projectPath);
              if (job !== undefined) {
                Curry._1(setJob, (function (param) {
                        return job;
                      }));
              } else {
                Curry._1(setJob, (function (param) {
                        
                      }));
                retryCount.contents = retryCount.contents - 1 | 0;
              }
              if (!CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.46.15")) {
                return ;
              }
              var job$1 = Belt_List.getBy(control.completed_reloads, (function (param) {
                      return Caml_obj.caml_equal(param.path, projectPath);
                    }));
              if (job$1 !== undefined) {
                return Curry._1(setCompletedJob, (function (param) {
                              return job$1;
                            }));
              } else {
                return Curry._1(setCompletedJob, (function (param) {
                              
                            }));
              }
            };
            if (retryCount.contents > 0) {
              return Task2$BsConsole.handle(token, Reprocessing_Tasks$BsConsole.control(token, undefined), undefined, cb);
            }
            
          };
          update_progress(undefined);
          var intervalId = setInterval(update_progress, 1000);
          return (function (param) {
                    clearInterval(intervalId);
                    
                  });
        }), ([]));
  var tmp;
  if (job !== undefined) {
    var progressPct = job.cursor / job.n_objects * 100;
    var progressPctFake = progressPct === 100 ? 99 : progressPct;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Css.merge(/* :: */[
                    container,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.padding3(/* `percent */[
                                  -119887163,
                                  5
                                ], /* `percent */[
                                  -119887163,
                                  5
                                ], /* `percent */[
                                  -119887163,
                                  0
                                ]),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(/* `percent */[
                              -119887163,
                              15
                            ]),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(String(progressPctFake | 0)), I18N$BsConsole.showSkip("%")), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(/* `percent */[
                              -119887163,
                              85
                            ]),
                        /* [] */0
                      ])
                }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                          365180284,
                          progressPct
                        ], undefined, /* Determinate */373815968, [])))), React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding3(/* `percent */[
                          -119887163,
                          0
                        ], /* `percent */[
                          -119887163,
                          5
                        ], /* `percent */[
                          -119887163,
                          5
                        ]),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.justifyContent(/* flexEnd */924268066),
                        /* [] */0
                      ]
                    ]
                  ])
            }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [progressPct === 100 ? I18N$BsConsole.show(undefined, "Finishing...") : React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Reprocessed (errors / total): "), I18N$BsConsole.showSkip(String(job.cursor)), I18N$BsConsole.showSkip(" / "), I18N$BsConsole.showSkip(String(job.n_objects)))]))));
  } else {
    tmp = completedJob !== undefined ? (
        completedJob.n_objects === completedJob.cursor ? React.createElement("div", {
                className: container
              }, I18N$BsConsole.showSkip(projectPath + (String(completedJob.cursor) + I18N$BsConsole.get(undefined, " objects reprocessed")))) : React.createElement("div", {
                className: container
              }, React.createElement("div", undefined, I18N$BsConsole.showSkip(projectPath)), React.createElement("div", undefined, I18N$BsConsole.showSkip(String(completedJob.cursor) + I18N$BsConsole.get(undefined, " objects reprocessed"))), React.createElement("div", undefined, I18N$BsConsole.showSkip(String(completedJob.n_objects - completedJob.cursor | 0) + I18N$BsConsole.get(undefined, " skipped due to retention policies"))))
      ) : I18N$BsConsole.showSkip(projectPath + I18N$BsConsole.get(undefined, " reprocessed"));
  }
  return React.createElement("div", undefined, tmp);
}

function make(token, projectPath, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ReprocessingProgressBar, {
              token: token,
              projectPath: projectPath
            }, _children);
}

var Re = {
  make: make
};

var make$1 = ReprocessingProgressBar;

exports.Style = Style;
exports.make = make$1;
exports.Re = Re;
/* container Not a pure module */
