// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Validator = require("validator");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Ui$BsConsole = require("../ui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var BtList$BsConsole = require("../components/BtList.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var BtListItem$BsConsole = require("../components/BtListItem.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");

var component = RR$BsConsole.reducerComponent("PrSummart_DistinctIdButton");

function make(textToCopy, onFilterTo, onFilterAgainst, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHander, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHander,
                                            className: Css.style(/* :: */[
                                                  Css.width(Css.pct(100)),
                                                  /* :: */[
                                                    Css.height(Css.pct(100)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, Curry._1(children, (function (param) {
                                                  
                                                })));
                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                return ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [
                                                ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (e) {
                                                            e.stopPropagation();
                                                            Clipboard$BsConsole.copy(textToCopy, undefined, undefined);
                                                            Curry._1(onClose, undefined);
                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Callstack copied to clipboard"));
                                                          }), [I18N$BsConsole.show(undefined, "Copy")])),
                                                Validator.isURL(textToCopy, {
                                                      require_protocol: true
                                                    }) ? ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (param) {
                                                              Util$BsConsole.openUrl(textToCopy);
                                                              return Curry._1(onClose, undefined);
                                                            }), [I18N$BsConsole.show(undefined, "Visit url")])) : null,
                                                ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (e) {
                                                            e.stopPropagation();
                                                            Curry._1(onClose, undefined);
                                                            return Curry._1(onFilterTo, undefined);
                                                          }), [I18N$BsConsole.show(undefined, "Filter to")])),
                                                ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (e) {
                                                            e.stopPropagation();
                                                            Curry._1(onClose, undefined);
                                                            return Curry._1(onFilterAgainst, undefined);
                                                          }), [I18N$BsConsole.show(undefined, "Filter against")]))
                                              ]));
                              }), undefined, []));
            }),
          initialState: (function (param) {
              return {
                      anchorEl: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            anchorEl: Caml_option.some(action[0])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            anchorEl: undefined
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
