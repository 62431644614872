// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var WfParser$BsConsole = require("../../workflow/WfParser.js");

var resource = "metrics_adapters";

function arrayOfJsonObjectsFromJson(json) {
  var arr = Js_json.decodeArray(json);
  if (arr !== undefined) {
    return arr;
  } else {
    return [];
  }
}

function configurationFromJson(json) {
  return {
          configuration: Json_decode.field("configuration", arrayOfJsonObjectsFromJson, json)
        };
}

function schemasFromJson(json) {
  return {
          schemas: Json_decode.field("schemas", configurationFromJson, json)
        };
}

function schemaFromJson(json) {
  return {
          descriptor: Json_decode.field("descriptor", schemasFromJson, json)
        };
}

function configurationFromJson$1(nested) {
  var match = Belt_List.fromArray(nested.descriptor.schemas.configuration);
  if (match) {
    return WfParser$BsConsole.parseFormSafe(match[0]);
  }
  
}

function adapterFromJson(json) {
  return Belt_Array.map(Js_dict.entries(Belt_Option.getExn(Js_json.decodeObject(json))), (function (param) {
                var parsed = schemaFromJson(param[1]);
                return /* tuple */[
                        param[0],
                        configurationFromJson$1(parsed)
                      ];
              }));
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                try {
                  return /* Ok */Block.__(0, [adapterFromJson(respBag.json)]);
                }
                catch (_exn){
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.JsonDecodeError,
                              "error parsing adapter"
                            ]]);
                }
              }), undefined, undefined);
}

exports.resource = resource;
exports.arrayOfJsonObjectsFromJson = arrayOfJsonObjectsFromJson;
exports.schemasFromJson = schemasFromJson;
exports.schemaFromJson = schemaFromJson;
exports.configurationFromJson = configurationFromJson$1;
exports.adapterFromJson = adapterFromJson;
exports.get = get;
/* Task2-BsConsole Not a pure module */
