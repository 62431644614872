'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _d3Scale = require('d3-scale');

exports.default = function (_ref) {
  var range = _ref.range,
      domain = _ref.domain,
      unknown = _ref.unknown;

  var scale = (0, _d3Scale.scaleOrdinal)();

  if (range) scale.range(range);
  if (domain) scale.domain(domain);
  if (unknown) scale.unknown(unknown);

  return scale;
};