// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var DistributionMenu$BsConsole = require("../charts/DistributionMenu.js");
var ControllableHoverBg$BsConsole = require("../ControllableHoverBg.js");
var DistributionExpandableRow$BsConsole = require("../DistributionExpandableRow.js");

var background = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* [] */0
    ]);

var cell = Css.style(/* :: */[
      Css.padding2(Css.px(5), Css.px(8)),
      /* [] */0
    ]);

var row1Cell = Css.style(/* :: */[
      Css.width(Css.pct(68)),
      /* [] */0
    ]);

var row2Cell = Css.style(/* :: */[
      Css.width(Css.pct(7)),
      /* [] */0
    ]);

var row3Cell = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* [] */0
    ]);

var borderBottom = Css.style(/* :: */[
      Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
      /* [] */0
    ]);

var boldText = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* [] */0
    ]);

var numericCell = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* :: */[
        Css.fontFamily("inconsolata"),
        /* [] */0
      ]
    ]);

var percentageCell = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.flexWrap(/* nowrap */867913355),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var percentageText = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* :: */[
          Css.marginRight(Css.pct(5)),
          /* [] */0
        ]
      ]
    ]);

var barContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.flexWrap(/* nowrap */867913355),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.width(Css.pct(70)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function bar(w, isHovered) {
  return Css.style(/* :: */[
              Css.width(Css.pct(w)),
              /* :: */[
                Css.height(Css.px(30)),
                /* :: */[
                  isHovered ? Css.backgroundColor(Css.hex(Colors$BsConsole.blue)) : Css.backgroundColor(Css.hex(Colors$BsConsole.grey3)),
                  /* [] */0
                ]
              ]
            ]);
}

var ellipsis = Css.style(/* :: */[
      Css.unsafe("display", "-webkit-box"),
      /* :: */[
        Css.unsafe("-webkit-line-clamp", "5"),
        /* :: */[
          Css.unsafe("-webkit-box-orient", "vertical"),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  background: background,
  cell: cell,
  row1Cell: row1Cell,
  row2Cell: row2Cell,
  row3Cell: row3Cell,
  borderBottom: borderBottom,
  boldText: boldText,
  numericCell: numericCell,
  percentageCell: percentageCell,
  percentageText: percentageText,
  barContainer: barContainer,
  bar: bar,
  ellipsis: ellipsis
};

var component = RR$BsConsole.statelessComponentWithRetainedProps("PrDistribution_TableRow");

function make(onMouseOver, onMouseLeave, label, value, valuesTotal, onFilterTo, onFilterAgainst, isHovered, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: (function (param) {
              var oldSelf = param.oldSelf;
              if (label !== oldSelf.retainedProps.label || value !== oldSelf.retainedProps.value) {
                return true;
              } else {
                return isHovered !== oldSelf.retainedProps.isHovered;
              }
            }),
          render: (function (param) {
              var percentWidth = value === 0 || valuesTotal === 0 ? 0 : value / valuesTotal * 100;
              return React.createElement("tr", {
                          className: Css.style(/* :: */[
                                Css.height(/* auto */-1065951377),
                                /* [] */0
                              ]),
                          onMouseLeave: onMouseLeave,
                          onMouseOver: onMouseOver
                        }, React.createElement("td", {
                              className: Css.merge(/* :: */[
                                    cell,
                                    /* :: */[
                                      row1Cell,
                                      /* [] */0
                                    ]
                                  ]),
                              title: label
                            }, ReasonReact.element(undefined, undefined, ControllableHoverBg$BsConsole.make(undefined, undefined, undefined, (function (param) {
                                        return ReasonReact.element(undefined, undefined, DistributionMenu$BsConsole.make(label, onFilterTo, onFilterAgainst, (function (openMenu) {
                                                          return ReasonReact.element(undefined, undefined, DistributionExpandableRow$BsConsole.Jsx2.make(label, isHovered, openMenu, []));
                                                        })));
                                      })))), React.createElement("td", {
                              className: Css.merge(/* :: */[
                                    cell,
                                    /* :: */[
                                      row2Cell,
                                      /* :: */[
                                        numericCell,
                                        /* :: */[
                                          isHovered ? boldText : "",
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(value)))), React.createElement("td", {
                              className: Css.merge(/* :: */[
                                    cell,
                                    /* :: */[
                                      row3Cell,
                                      /* :: */[
                                        numericCell,
                                        /* :: */[
                                          isHovered ? boldText : "",
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            }, React.createElement("div", {
                                  className: percentageCell
                                }, React.createElement("span", {
                                      className: percentageText
                                    }, I18N$BsConsole.showSkip(Util$BsConsole.toFormattedPct(value, valuesTotal))), React.createElement("div", {
                                      className: barContainer
                                    }, React.createElement("span", {
                                          className: bar(percentWidth, isHovered)
                                        })))));
            }),
          initialState: component.initialState,
          retainedProps: {
            label: label,
            value: value,
            isHovered: isHovered
          },
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var TableRow = {
  component: component,
  make: make
};

var tailLabel = "Other";

var component$1 = RR$BsConsole.reducerComponent("PrDistribution-BsConsole");

function make$1(onClose, attribute, distribution, handleAddFilters, handleChangeUrl, param) {
  var updateChartData = function (state, distribution) {
    var chartData = Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
            return /* tuple */[
                    FormatUtils$BsConsole.formatToString(param[0]),
                    param[1]
                  ];
          }));
    var valuesTotal = Belt_Array.reduce(Belt_Array.map(chartData, (function (prim) {
                return prim[1];
              })), 0, (function (prim, prim$1) {
            return prim + prim$1 | 0;
          })) + Belt_Option.getWithDefault(distribution.tail, 0) | 0;
    return {
            hoveredRow: Belt_Option.flatMap(state, (function (state) {
                    return state.hoveredRow;
                  })),
            chartData: chartData,
            valuesTotal: valuesTotal,
            retainedDistribution: distribution
          };
  };
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: (function (param) {
              var state = param.state;
              if (distribution !== state.retainedDistribution) {
                return updateChartData(state, distribution);
              } else {
                return state;
              }
            }),
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var valuesTotal = state.valuesTotal;
              var hoveredRow = state.hoveredRow;
              var send = param.send;
              var tail = distribution.tail;
              var tmp;
              if (tail !== undefined) {
                var percentWidth = tail === 0 || valuesTotal === 0 ? 0 : tail / valuesTotal * 100;
                tmp = React.createElement("tr", {
                      onMouseLeave: (function (param) {
                          return Curry._1(send, /* RowLeft */0);
                        }),
                      onMouseOver: (function (param) {
                          return Curry._1(send, /* RowHovered */[tailLabel]);
                        })
                    }, React.createElement("td", {
                          className: Css.merge(/* :: */[
                                cell,
                                /* :: */[
                                  Caml_obj.caml_equal(hoveredRow, tailLabel) ? boldText : "",
                                  /* [] */0
                                ]
                              ])
                        }, React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.padding(Css.px(8)),
                                    /* [] */0
                                  ])
                            }, I18N$BsConsole.showSkip(tailLabel))), React.createElement("td", {
                          className: Css.merge(/* :: */[
                                cell,
                                /* :: */[
                                  numericCell,
                                  /* :: */[
                                    Caml_obj.caml_equal(hoveredRow, tailLabel) ? boldText : "",
                                    /* [] */0
                                  ]
                                ]
                              ])
                        }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(tail)))), React.createElement("td", {
                          className: Css.merge(/* :: */[
                                cell,
                                /* :: */[
                                  row3Cell,
                                  /* :: */[
                                    numericCell,
                                    /* :: */[
                                      Caml_obj.caml_equal(hoveredRow, tailLabel) ? boldText : "",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        }, React.createElement("div", {
                              className: percentageCell
                            }, React.createElement("span", {
                                  className: percentageText
                                }, I18N$BsConsole.showSkip(Util$BsConsole.toFormattedPct(tail, valuesTotal))), React.createElement("div", {
                                  className: barContainer
                                }, React.createElement("span", {
                                      className: bar(percentWidth, Caml_obj.caml_equal(hoveredRow, tailLabel))
                                    })))));
              } else {
                tmp = null;
              }
              return React.createElement("div", undefined, React.createElement("div", {
                              style: {
                                display: "flex",
                                paddingBottom: "16px",
                                justifyContent: "flex-end"
                              }
                            }, React.createElement("div", {
                                  className: Css.merge(/* :: */[
                                        background,
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.padding(Css.rem(0.5)),
                                                /* :: */[
                                                  Css.display(/* flex */-1010954439),
                                                  /* :: */[
                                                    Css.marginRight(Css.rem(1)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Unique values")), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginLeft(Css.rem(0.5)),
                                            /* :: */[
                                              Css.fontFamily("inconsolata"),
                                              /* [] */0
                                            ]
                                          ])
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(distribution.keys))))), React.createElement("div", {
                                  className: Css.merge(/* :: */[
                                        background,
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.padding(Css.rem(0.5)),
                                                /* :: */[
                                                  Css.display(/* flex */-1010954439),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Total count")), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginLeft(Css.rem(0.5)),
                                            /* :: */[
                                              Css.fontFamily("inconsolata"),
                                              /* [] */0
                                            ]
                                          ])
                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(valuesTotal)))))), React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    background,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement("table", {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.tableLayout(/* fixed */10615156),
                                          /* :: */[
                                            Css.borderSpacing(Css.px(0)),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, React.createElement("thead", {
                                      className: borderBottom
                                    }, React.createElement("tr", {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                /* :: */[
                                                  Css.fontWeight(/* normal */812216871),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, React.createElement("th", {
                                              className: Css.merge(/* :: */[
                                                    borderBottom,
                                                    /* :: */[
                                                      cell,
                                                      /* :: */[
                                                        row1Cell,
                                                        /* :: */[
                                                          Css.style(/* :: */[
                                                                Css.fontWeight(/* normal */812216871),
                                                                /* :: */[
                                                                  Css.textAlign(/* left */-944764921),
                                                                  /* :: */[
                                                                    Css.paddingLeft(Css.px(16)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.show(undefined, "distinct_id")), React.createElement("th", {
                                              className: Css.merge(/* :: */[
                                                    borderBottom,
                                                    /* :: */[
                                                      cell,
                                                      /* :: */[
                                                        row2Cell,
                                                        /* :: */[
                                                          Css.style(/* :: */[
                                                                Css.fontWeight(/* normal */812216871),
                                                                /* :: */[
                                                                  Css.textAlign(/* right */-379319332),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.show(undefined, "count")), React.createElement("th", {
                                              className: Css.merge(/* :: */[
                                                    borderBottom,
                                                    /* :: */[
                                                      cell,
                                                      /* :: */[
                                                        row3Cell,
                                                        /* :: */[
                                                          Css.style(/* :: */[
                                                                Css.fontWeight(/* normal */812216871),
                                                                /* :: */[
                                                                  Css.textAlign(/* left */-944764921),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.show(undefined, "percentage")))), React.createElement("tbody", undefined, Belt_Array.map(state.chartData, (function (param) {
                                            var label = param[0];
                                            return ReasonReact.element(label, undefined, make((function (param) {
                                                              return Curry._1(send, /* RowHovered */[label]);
                                                            }), (function (param) {
                                                              return Curry._1(send, /* RowLeft */0);
                                                            }), label, param[1], valuesTotal, (function (param) {
                                                              Curry._1(onClose, undefined);
                                                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                              /* tuple */[
                                                                                attribute,
                                                                                /* Equal */Block.__(0, [/* `String */[
                                                                                      -976970511,
                                                                                      label
                                                                                    ]])
                                                                              ],
                                                                              /* [] */0
                                                                            ]));
                                                            }), (function (param) {
                                                              Curry._1(onClose, undefined);
                                                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                              /* tuple */[
                                                                                attribute,
                                                                                /* NotEqual */Block.__(1, [/* `String */[
                                                                                      -976970511,
                                                                                      label
                                                                                    ]])
                                                                              ],
                                                                              /* [] */0
                                                                            ]));
                                                            }), Caml_obj.caml_equal(hoveredRow, label), []));
                                          })), tmp))));
            }),
          initialState: (function (param) {
              return updateChartData(undefined, distribution);
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            hoveredRow: action[0],
                            chartData: state.chartData,
                            valuesTotal: state.valuesTotal,
                            retainedDistribution: state.retainedDistribution
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            hoveredRow: undefined,
                            chartData: state.chartData,
                            valuesTotal: state.valuesTotal,
                            retainedDistribution: state.retainedDistribution
                          }]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.TableRow = TableRow;
exports.tailLabel = tailLabel;
exports.component = component$1;
exports.make = make$1;
/* background Not a pure module */
