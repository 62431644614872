// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BugLink$BsConsole = require("./BugLink.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var Paper = require("@material-ui/core/Paper");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Dialog = require("@material-ui/core/Dialog");
var Close = require("@material-ui/icons/Close");
var Collapse = require("@material-ui/core/Collapse");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var HelpOutline = require("@material-ui/icons/HelpOutline");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

var idx = {
  contents: 0
};

function fromSymbolData(symbolData) {
  if (symbolData.downloadInProgress) {
    return /* DownloadInProgress */0;
  } else if (symbolData.blacklist.length !== 0) {
    return /* SymbolInBlacklist */3;
  } else if (symbolData.skiplist.length !== 0) {
    return /* SymbolInSkiplist */4;
  } else if (symbolData.logs.length !== 0) {
    return /* ErrorsInLogs */2;
  } else if (symbolData.symbolsUploadedManually) {
    return /* NoSymbolsForDebugId */1;
  } else if (symbolData.whitelist.length === 0) {
    return /* AddSymbol */5;
  } else {
    return /* UnknownError */6;
  }
}

var SymbolInformation = {
  test: false,
  idx: idx,
  fromSymbolData: fromSymbolData
};

var th = Css.style(/* :: */[
      Css.fontWeight(Css.medium),
      /* :: */[
        Css.padding(Css.px(9)),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.textAlign(/* left */-944764921),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey4)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var td = Css.style(/* :: */[
      Css.padding(Css.px(9)),
      /* :: */[
        Css.fontSize(Css.px(12)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey2)),
          /* :: */[
            Css.wordBreak(Css.breakAll),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  th: th,
  td: td
};

function BugMissingSymbols$SymbolRow(Props) {
  var config = Props.config;
  Props.idx;
  var missingSymbol = Props.missingSymbol;
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var task = React.useMemo((function () {
          return BugTasks$BsConsole.getSymbolData(config.universe.name, projectName, missingSymbol.debug_file, missingSymbol.debug_identifier, undefined);
        }), [missingSymbol]);
  var remote = Task2$BsConsole.use(undefined, task, undefined);
  var tmp;
  var exit = 0;
  if (typeof remote === "number") {
    exit = 1;
  } else if (remote.tag) {
    tmp = React.createElement("td", {
          className: td,
          colSpan: 2
        }, I18N$BsConsole.show(undefined, "Failed fetching symbol information"));
  } else {
    var match$1 = remote[0];
    var match$2 = match$1[1];
    var symbolData = match$1[0];
    if (typeof match$2 === "number" && match$2 === 0) {
      var symbolInformation = fromSymbolData(symbolData);
      var reason;
      switch (symbolInformation) {
        case /* DownloadInProgress */0 :
            reason = I18N$BsConsole.show(undefined, "Download in progress");
            break;
        case /* NoSymbolsForDebugId */1 :
            reason = I18N$BsConsole.show(undefined, "Symbol not uploaded for this debug ID");
            break;
        case /* ErrorsInLogs */2 :
            reason = I18N$BsConsole.show(undefined, "Failed downloading symbols");
            break;
        case /* SymbolInBlacklist */3 :
            reason = I18N$BsConsole.show(undefined, "Symbol is in blocklist");
            break;
        case /* SymbolInSkiplist */4 :
            reason = I18N$BsConsole.show(undefined, "Symbol is in skiplist");
            break;
        case /* AddSymbol */5 :
            reason = I18N$BsConsole.show(undefined, "Symbols are not uploaded");
            break;
        case /* UnknownError */6 :
            reason = I18N$BsConsole.show(undefined, "Unknown error");
            break;
        
      }
      var action;
      switch (symbolInformation) {
        case /* DownloadInProgress */0 :
            action = I18N$BsConsole.show(undefined, "Check back in few minutes");
            break;
        case /* NoSymbolsForDebugId */1 :
            action = React.createElement(BugLink$BsConsole.make, {
                  route: /* ProjectSettingsSymbols */Block.__(39, [projectName]),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.style(/* :: */[
                        Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
                        /* :: */[
                          Css.important(Css.textDecoration(Css.underline)),
                          /* :: */[
                            Css.cursor(/* pointer */-786317123),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Upload the symbols for this debug ID")
                });
            break;
        case /* ErrorsInLogs */2 :
            action = React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Resolve the symbol server issues")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Logs:")), Belt_Array.map(symbolData.logs, (function (log) {
                        return React.createElement("div", undefined, I18N$BsConsole.dynamic(log.message));
                      })));
            break;
        case /* SymbolInBlacklist */3 :
            action = React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Remove symbol from blocklist in"), React.createElement(BugLink$BsConsole.make, {
                      route: /* ProjectSettingsSymbolServers */Block.__(38, [projectName]),
                      handleChangeUrl: handleChangeUrl,
                      className: Css.style(/* :: */[
                            Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
                            /* :: */[
                              Css.important(Css.textDecoration(Css.underline)),
                              /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "symbol server configuration")
                    }));
            break;
        case /* SymbolInSkiplist */4 :
            action = React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Remove symbol from skiplist in "), React.createElement(BugLink$BsConsole.make, {
                      route: /* ProjectSettingsSymbolServers */Block.__(38, [projectName]),
                      handleChangeUrl: handleChangeUrl,
                      className: Css.style(/* :: */[
                            Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
                            /* :: */[
                              Css.important(Css.textDecoration(Css.underline)),
                              /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "symbol server configuration")
                    }));
            break;
        case /* AddSymbol */5 :
            action = React.createElement(BugLink$BsConsole.make, {
                  route: /* ProjectSettingsSymbols */Block.__(39, [projectName]),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.style(/* :: */[
                        Css.important(Css.color(Css.hex(Colors$BsConsole.blue))),
                        /* :: */[
                          Css.important(Css.textDecoration(Css.underline)),
                          /* :: */[
                            Css.cursor(/* pointer */-786317123),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Upload the missing symbols")
                });
            break;
        case /* UnknownError */6 :
            action = I18N$BsConsole.show(undefined, "Contact support for help resolving the issue");
            break;
        
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement("td", {
                className: td
              }, reason), React.createElement("td", {
                className: td
              }, action));
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = React.createElement("td", {
          className: td,
          colSpan: 2
        }, I18N$BsConsole.show(undefined, "Loading..."));
  }
  return React.createElement("tr", {
              className: Css.style(/* :: */[
                    Css.borderTop(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                    /* [] */0
                  ])
            }, React.createElement("td", {
                  className: td
                }, I18N$BsConsole.showSkip(missingSymbol.debug_file)), React.createElement("td", {
                  className: td
                }, I18N$BsConsole.showSkip(missingSymbol.debug_identifier)), tmp);
}

var SymbolRow = {
  make: BugMissingSymbols$SymbolRow
};

function BugMissingSymbols$SymbolsTable(Props) {
  var config = Props.config;
  var missingSymbols = Props.missingSymbols;
  return React.createElement("table", {
              className: Css.style(/* :: */[
                    Css.tableLayout(/* fixed */10615156),
                    /* :: */[
                      Css.borderSpacing(Css.zero),
                      /* :: */[
                        Css.borderCollapse(/* collapse */-996847251),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("thead", undefined, React.createElement("tr", {
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(2), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerSecondary)),
                            /* [] */0
                          ])
                    }, React.createElement("th", {
                          className: Css.merge(/* :: */[
                                th,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.width(Css.px(160)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.show(undefined, "File name")), React.createElement("th", {
                          className: Css.merge(/* :: */[
                                th,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.width(Css.px(140)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.show(undefined, "Debug ID")), React.createElement("th", {
                          className: Css.merge(/* :: */[
                                th,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.width(Css.px(230)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.show(undefined, "Reason")), React.createElement("th", {
                          className: Css.merge(/* :: */[
                                th,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.width(Css.px(170)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.show(undefined, "Action")))), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(missingSymbols, (function (idx, missingSymbol) {
                        return React.createElement(BugMissingSymbols$SymbolRow, {
                                    config: config,
                                    idx: idx,
                                    missingSymbol: missingSymbol
                                  });
                      }))));
}

var SymbolsTable = {
  make: BugMissingSymbols$SymbolsTable
};

function BugMissingSymbols(Props) {
  var config = Props.config;
  var open_ = Props.open_;
  var onClose = Props.onClose;
  var debugId = Props.debugId;
  var missingSymbols = Props.missingSymbols;
  var match = React.useState((function () {
          return false;
        }));
  var setOthersExpanded = match[1];
  var othersExpanded = match[0];
  var supportLink = Href$BsConsole.Support.make(undefined, /* WhatAreSymbols */24);
  var tmp;
  if (debugId !== undefined) {
    var symbol = Belt_Option.getExn(Caml_option.undefined_to_opt(missingSymbols.find((function (symbol) {
                    return symbol.debug_identifier === debugId;
                  }))));
    var restSymbols = Belt_Array.keep(missingSymbols, (function (symbol) {
            return symbol.debug_identifier !== debugId;
          }));
    var match$1 = restSymbols.length;
    tmp = React.createElement(Col2$BsConsole.make, {
          className: Css.style(/* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]),
          children: null
        }, React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontWeight(Css.medium),
                    /* :: */[
                      Css.fontSize(Css.px(13)),
                      /* :: */[
                        Css.paddingLeft(Css.px(9)),
                        /* :: */[
                          Css.paddingTop(Css.px(9)),
                          /* :: */[
                            Css.paddingBottom(Css.px(3)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, "Selected function")), React.createElement(BugMissingSymbols$SymbolsTable, {
              config: config,
              missingSymbols: [symbol]
            }), match$1 !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.fontWeight(Css.medium),
                          /* :: */[
                            Css.paddingTop(Css.px(9)),
                            /* :: */[
                              Css.cursor(/* pointer */-786317123),
                              /* :: */[
                                Css.paddingBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    onClick: (function (param) {
                        return Curry._1(setOthersExpanded, (function (prim) {
                                      return !prim;
                                    }));
                      }),
                    children: null
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.fontWeight(Css.medium),
                              /* :: */[
                                Css.fontSize(Css.px(13)),
                                /* :: */[
                                  Css.paddingLeft(Css.px(9)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "Others")), React.createElement(Mui$BsConsole.IconButton.make, {
                        ariaLabel: othersExpanded ? "Collapse" : "Expand",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.transform(Css.rotate(Css.deg(othersExpanded ? 0 : -180))),
                                /* :: */[
                                  Css.transition(250, undefined, undefined, "transform"),
                                  /* :: */[
                                    Css.fontSize(Css.px(18)),
                                    /* :: */[
                                      Css.padding(Css.px(2)),
                                      /* :: */[
                                        Css.width(Css.px(22)),
                                        /* :: */[
                                          Css.height(Css.px(22)),
                                          /* :: */[
                                            Css.marginLeft(Css.px(2)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            
                          }),
                        children: React.createElement(KeyboardArrowDown.default, {
                              fontSize: "inherit"
                            })
                      })), React.createElement(Collapse.default, {
                    in: othersExpanded,
                    children: React.createElement(BugMissingSymbols$SymbolsTable, {
                          config: config,
                          missingSymbols: restSymbols
                        }),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  })) : null);
  } else {
    tmp = React.createElement(BugMissingSymbols$SymbolsTable, {
          config: config,
          missingSymbols: missingSymbols
        });
  }
  return React.createElement(Dialog.default, {
              PaperComponent: "div",
              open: open_,
              classes: {
                paper: Css.style(/* :: */[
                      Css.width(Css.px(800)),
                      /* :: */[
                        Css.maxWidth(Css.px(800)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* :: */[
                            Css.pointerEvents(Css.none),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              },
              onClose: onClose,
              children: React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.pointerEvents(/* auto */-1065951377),
                            /* [] */0
                          ])
                    },
                    elevation: 24,
                    children: React.createElement(Col2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.padding(Css.px(16)),
                                /* [] */0
                              ]),
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: Css.style(/* :: */[
                                    Css.paddingLeft(Css.px(9)),
                                    /* :: */[
                                      Css.paddingBottom(Css.px(9)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              children: null
                            }, React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.fontSize(Css.px(16)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Missing symbols")), supportLink !== undefined ? React.createElement("a", {
                                    href: supportLink,
                                    target: "_blank"
                                  }, React.createElement(Mui$BsConsole.IconButton.make, {
                                        ariaLabel: "Link to article: What are symbols?",
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.fontSize(Css.px(18)),
                                                /* :: */[
                                                  Css.padding(Css.px(4)),
                                                  /* :: */[
                                                    Css.width(Css.px(26)),
                                                    /* :: */[
                                                      Css.height(Css.px(26)),
                                                      /* :: */[
                                                        Css.marginLeft(Css.px(4)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        },
                                        onClick: (function (param) {
                                            
                                          }),
                                        children: React.createElement(HelpOutline.default, {
                                              fontSize: "inherit"
                                            })
                                      })) : null, React.createElement(Mui$BsConsole.IconButton.make, {
                                  ariaLabel: "Close",
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.marginLeft(Css.auto),
                                          /* :: */[
                                            Css.fontSize(Css.px(18)),
                                            /* :: */[
                                              Css.padding(Css.px(4)),
                                              /* :: */[
                                                Css.width(Css.px(26)),
                                                /* :: */[
                                                  Css.height(Css.px(26)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ])
                                  },
                                  onClick: (function (param) {
                                      return Curry._1(onClose, undefined);
                                    }),
                                  children: React.createElement(Close.default, {
                                        fontSize: "inherit"
                                      })
                                })), tmp)
                  })
            });
}

var make = BugMissingSymbols;

exports.SymbolInformation = SymbolInformation;
exports.Styles = Styles;
exports.SymbolRow = SymbolRow;
exports.SymbolsTable = SymbolsTable;
exports.make = make;
/* th Not a pure module */
