// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");

var MergeFailure = Caml_exceptions.create("InboxMergerError-BsConsole.MergeFailure");

var DecodingFailure = Caml_exceptions.create("InboxMergerError-BsConsole.DecodingFailure");

function mergeErrors(token, onSuccess, onFailure, fingerprints, projectName, param) {
  var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/issue_control/merge_fingerprints?project=" + projectName]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "fingerprints",
                  fingerprints
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var json = param.json;
          var response = Json_decode.optional((function (param) {
                  return Json_decode.field("response", Json_decode.string, param);
                }), json);
          var group = Json_decode.optional((function (param) {
                  return Json_decode.field("fingerprint", Json_decode.string, param);
                }), json);
          if (response !== undefined && response === "ok" && group !== undefined) {
            return /* Ok */Block.__(0, [group]);
          } else {
            return /* Error */Block.__(1, [DecodingFailure]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (group) {
                if (group.tag) {
                  return Curry._1(onFailure, group[0]);
                } else {
                  return Curry._1(onSuccess, group[0]);
                }
              }));
}

function deprecatedMergeErrors(token, onSuccess, onFailure, fingerprints, projectName, param) {
  var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + projectName]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "actions",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "fingerprint",
                          Json_encode.array(Util$BsConsole.identity, [Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "merge"
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "arguments",
                                          Json_encode.list((function (prim) {
                                                  return prim;
                                                }), fingerprints)
                                        ],
                                        /* [] */0
                                      ]
                                    ])])
                        ],
                        /* [] */0
                      ])
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var json = param.json;
          var result = Json_decode.optional(Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "result",
                      /* [] */0
                    ]
                  ], Json_decode.string), json);
          var group = Json_decode.optional(Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "group",
                      /* [] */0
                    ]
                  ], Json_decode.string), json);
          if (result !== undefined && result === "success" && group !== undefined) {
            return /* Ok */Block.__(0, [group]);
          } else {
            return /* Error */Block.__(1, [DecodingFailure]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (group) {
                if (group.tag) {
                  return Curry._1(onFailure, group[0]);
                } else {
                  return Curry._1(onSuccess, group[0]);
                }
              }));
}

function workflowMergeErrors(serviceEndpoint, token, onSuccess, onFailure, fingerprints, sourceFingerprint, projectName, param) {
  var url = serviceEndpoint + ("/issue/merge?project=" + projectName);
  var arg = Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "actions",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "fingerprint",
                          Json_encode.array(Util$BsConsole.identity, [Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "merge"
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "source",
                                          sourceFingerprint
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "arguments",
                                            Json_encode.list((function (prim) {
                                                    return prim;
                                                  }), fingerprints)
                                          ],
                                          /* [] */0
                                        ]
                                      ]
                                    ])])
                        ],
                        /* [] */0
                      ])
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var json = param.json;
          var result = Json_decode.optional((function (param) {
                  return Json_decode.field("success", Json_decode.bool, param);
                }), json);
          var group = Json_decode.optional((function (param) {
                  return Json_decode.field("body", Json_decode.string, param);
                }), json);
          if (result !== undefined && result && group !== undefined) {
            return /* Ok */Block.__(0, [group]);
          } else {
            return /* Error */Block.__(1, [DecodingFailure]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (group) {
                if (group.tag) {
                  return Curry._1(onFailure, group[0]);
                } else {
                  return Curry._1(onSuccess, group[0]);
                }
              }));
}

function unmergeErrors(token, onSuccess, onFailure, fingerprints, projectName, param) {
  var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/issue_control/unmerge_fingerprints?project=" + projectName]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "fingerprints",
                  fingerprints
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var response = Json_decode.optional((function (param) {
                  return Json_decode.field("response", Json_decode.string, param);
                }), param.json);
          if (response === "ok") {
            return /* Ok */Block.__(0, [undefined]);
          } else {
            return /* Error */Block.__(1, [DecodingFailure]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (group) {
                if (group.tag) {
                  return Curry._1(onFailure, group[0]);
                } else {
                  return Curry._1(onSuccess, group[0]);
                }
              }));
}

function deprecatedUnmergeErrors(token, onSuccess, onFailure, fingerprints, projectName, param) {
  var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + projectName]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "actions",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "fingerprint",
                          Json_encode.array(Util$BsConsole.identity, [Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "unmerge"
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "arguments",
                                          Json_encode.list((function (prim) {
                                                  return prim;
                                                }), fingerprints)
                                        ],
                                        /* [] */0
                                      ]
                                    ])])
                        ],
                        /* [] */0
                      ])
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var result = Json_decode.optional(Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "result",
                      /* [] */0
                    ]
                  ], Json_decode.string), param.json);
          if (result === "success") {
            return /* Ok */Block.__(0, [undefined]);
          } else {
            return /* Error */Block.__(1, [DecodingFailure]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (err) {
                if (err.tag) {
                  return Curry._1(onFailure, err[0]);
                } else {
                  return Curry._1(onSuccess, undefined);
                }
              }));
}

exports.MergeFailure = MergeFailure;
exports.DecodingFailure = DecodingFailure;
exports.mergeErrors = mergeErrors;
exports.deprecatedMergeErrors = deprecatedMergeErrors;
exports.workflowMergeErrors = workflowMergeErrors;
exports.unmergeErrors = unmergeErrors;
exports.deprecatedUnmergeErrors = deprecatedUnmergeErrors;
/* Util-BsConsole Not a pure module */
