// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var WfCore$BsConsole = require("./WfCore.js");
var Fragment$BsConsole = require("../Fragment.js");
var WfRequiredFieldIndicator$BsConsole = require("./WfRequiredFieldIndicator.js");

var component = ReasonReact.statelessComponent("WfFormCell-BsConsole");

function make(fieldDoc, requiredOpt, paddingOpt, error, boolLayoutOpt, dirty, showAllErrors, children) {
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var padding = paddingOpt !== undefined ? paddingOpt : "12px 17px";
  var boolLayout = boolLayoutOpt !== undefined ? boolLayoutOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var showErrorBackground = dirty || showAllErrors ? error !== undefined : false;
              var docComponent;
              if (fieldDoc !== undefined) {
                var tmp = {
                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.black),
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "135%"
                };
                var tmp$1 = boolLayout ? "9px" : undefined;
                if (tmp$1 !== undefined) {
                  tmp.paddingTop = Caml_option.valFromOption(tmp$1);
                }
                var description = fieldDoc.description;
                docComponent = React.createElement("div", undefined, React.createElement("div", {
                          style: tmp
                        }, I18N$BsConsole.showSkip(fieldDoc.title), required ? ReasonReact.element(undefined, undefined, WfRequiredFieldIndicator$BsConsole.make([])) : null), description !== undefined ? React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(description)]))) : null);
              } else {
                docComponent = null;
              }
              var tmp$2 = {
                background: showErrorBackground ? Colors$BsConsole.octothorpe(Colors$BsConsole.npLightPink) : "none",
                display: "flex",
                marginTop: "2px",
                padding: padding,
                width: "100%",
                flexDirection: boolLayout ? "row-reverse" : "column",
                justifyContent: boolLayout ? "flex-end" : "flex-start"
              };
              var tmp$3 = boolLayout ? "7px" : undefined;
              if (tmp$3 !== undefined) {
                tmp$2.paddingLeft = Caml_option.valFromOption(tmp$3);
              }
              var tmp$4 = boolLayout ? "flex-start" : undefined;
              if (tmp$4 !== undefined) {
                tmp$2.alignItems = Caml_option.valFromOption(tmp$4);
              }
              var tmp$5;
              var exit = 0;
              var error$1;
              if (dirty) {
                if (error !== undefined) {
                  error$1 = error;
                  exit = 1;
                } else {
                  tmp$5 = null;
                }
              } else if (showAllErrors && error !== undefined) {
                error$1 = error;
                exit = 1;
              } else {
                tmp$5 = null;
              }
              if (exit === 1) {
                tmp$5 = React.createElement("div", {
                      style: {
                        color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed),
                        fontSize: "16px"
                      }
                    }, I18N$BsConsole.showSkip(WfCore$BsConsole.errorToString(error$1)));
              }
              return React.createElement("div", {
                          style: tmp$2
                        }, docComponent, ReasonReact.element(undefined, undefined, Fragment$BsConsole.make(children)), tmp$5);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
