// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function Make(FL, TA) {
  var default_000 = FL.empty;
  var default_001 = TA.$$default;
  var $$default = /* tuple */[
    default_000,
    default_001
  ];
  var toURLEncodedJson = function (param) {
    return [
            Curry._1(TA.toJson, param[1]),
            Curry._1(FL.toURLEncodedJson, param[0])
          ];
  };
  var toJson = function (param) {
    return [
            Curry._1(TA.toJson, param[1]),
            Curry._1(FL.toJson, param[0])
          ];
  };
  var toPrivateJsonTuple2 = function (param) {
    return /* :: */[
            /* tuple */[
              "filter",
              [
                Curry._1(TA.toJson, param[1]),
                Curry._1(FL.toPrivateJson, param[0])
              ]
            ],
            /* [] */0
          ];
  };
  var fromJson = function (json) {
    var match;
    try {
      match = Json_decode.tuple2(TA.fromJson, FL.fromJson, json);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg[0] === Json_decode.DecodeError) {
        throw [
              Json_decode.DecodeError,
              msg[1]
            ];
      }
      throw msg;
    }
    return /* tuple */[
            match[1],
            match[0]
          ];
  };
  var fromURLEncodedJson = function (json) {
    var match;
    try {
      match = Json_decode.tuple2(TA.fromJson, FL.fromURLEncodedJson, json);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg[0] === Json_decode.DecodeError) {
        throw [
              Json_decode.DecodeError,
              msg[1]
            ];
      }
      throw msg;
    }
    return /* tuple */[
            match[1],
            match[0]
          ];
  };
  var getTimeAperture = function (param) {
    return param[1];
  };
  var setTimeAperture = function (timeAperture, param) {
    return /* tuple */[
            param[0],
            timeAperture
          ];
  };
  var getFilters = function (param) {
    return param[0];
  };
  var setFilters = function (filterMap, param) {
    return /* tuple */[
            filterMap,
            param[1]
          ];
  };
  var sortRange = function (t$prime, t$prime$prime) {
    var match = Caml_primitive.caml_float_compare(t$prime, t$prime$prime);
    if (match > 0) {
      if (match >= 2) {
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "Aperture.re",
                82,
                11
              ]
            ];
      }
      return /* tuple */[
              t$prime$prime,
              t$prime
            ];
    }
    if (match >= -1) {
      return /* tuple */[
              t$prime,
              t$prime$prime
            ];
    }
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Aperture.re",
            82,
            11
          ]
        ];
  };
  var addTimeApertureRange = function (ta, fl) {
    var match = Curry._1(TA.getAttribute, ta);
    if (match === "fingerprint;first_seen") {
      return Curry._3(FL.addRange, "timestamp", sortRange(Math.floor(Curry._1(TA.getStart, ta).valueOf() / 1000.0), Math.floor(Curry._1(TA.getFinish, ta).valueOf() / 1000.0)), Curry._3(FL.addRange, "fingerprint;first_seen", sortRange(Math.floor(Curry._1(TA.getStart, ta).valueOf() / 1000.0), Math.floor(Curry._1(TA.getFinish, ta).valueOf() / 1000.0)), fl));
    } else {
      return Curry._3(FL.addRange, Curry._1(TA.getAttribute, ta), sortRange(Math.floor(Curry._1(TA.getStart, ta).valueOf() / 1000.0), Math.floor(Curry._1(TA.getFinish, ta).valueOf() / 1000.0)), fl);
    }
  };
  var toCrdbTuple2 = function (param) {
    var filter = Curry._1(FL.toCrdb, addTimeApertureRange(param[1], param[0]));
    return Belt_List.keep(/* :: */[
                /* tuple */[
                  "filter",
                  [filter]
                ],
                /* [] */0
              ], (function (param) {
                  return Caml_obj.caml_notequal(param[1], [Json_encode.object_(/* [] */0)]);
                }));
  };
  var toCrdbTimelessTuple2 = function (param) {
    var filter = Curry._1(FL.toCrdb, param[0]);
    return Belt_List.keep(/* :: */[
                /* tuple */[
                  "filter",
                  [filter]
                ],
                /* [] */0
              ], (function (param) {
                  return Caml_obj.caml_notequal(param[1], [Json_encode.object_(/* [] */0)]);
                }));
  };
  var toCrdbJson = function (param) {
    return [Curry._1(FL.toCrdb, addTimeApertureRange(param[1], param[0]))];
  };
  var toCrdbTimeless = function (param) {
    return Curry._1(FL.toCrdb, param[0]);
  };
  var eq = function (param, param$1) {
    if (Curry._2(FL.eq, param[0], param$1[0])) {
      return Curry._2(TA.eq, param[1], param$1[1]);
    } else {
      return false;
    }
  };
  return {
          $$default: $$default,
          toJson: toJson,
          toURLEncodedJson: toURLEncodedJson,
          toPrivateJsonTuple2: toPrivateJsonTuple2,
          fromJson: fromJson,
          fromURLEncodedJson: fromURLEncodedJson,
          setTimeAperture: setTimeAperture,
          getTimeAperture: getTimeAperture,
          setFilters: setFilters,
          getFilters: getFilters,
          toCrdbTuple2: toCrdbTuple2,
          toCrdbTimelessTuple2: toCrdbTimelessTuple2,
          toCrdbJson: toCrdbJson,
          toCrdbTimeless: toCrdbTimeless,
          eq: eq
        };
}

exports.Make = Make;
/* No side effect */
