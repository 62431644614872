// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Service$BsConsole = require("./Service.js");

function fromJson(json) {
  return Json_decode.list(Service$BsConsole.fromJson, json);
}

function _getEndpoint(projectNameOpt, overridesOpt, services, name) {
  var projectName = projectNameOpt !== undefined ? projectNameOpt : "*";
  var overrides = overridesOpt !== undefined ? Caml_option.valFromOption(overridesOpt) : undefined;
  var match = Belt_Option.flatMap(Belt_Option.flatMap(overrides, (function (overrides) {
              return Belt_List.getBy(overrides, (function (param) {
                            if (projectName === "*" || param[0] === projectName) {
                              return param[1].name === name;
                            } else {
                              return false;
                            }
                          }));
            })), (function (param) {
          return param[1].endpoint;
        }));
  var match$1 = Belt_Option.flatMap(Belt_Option.flatMap(services, (function (services) {
              return Belt_List.getBy(services, (function (service) {
                            return service.name === name;
                          }));
            })), (function (s) {
          return s.endpoint;
        }));
  if (match !== undefined) {
    return match;
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    return ;
  }
}

var arg = Caml_option.some(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(window._BACKTRACE_SERVICE_OVERRIDES), Json.parse), (function (overrides) {
            try {
              return Json_decode.list((function (param) {
                            return Json_decode.tuple2(Json_decode.string, Service$BsConsole.fromJson, param);
                          }), overrides);
            }
            catch (exn){
              console.log("_BACKTRACE_SERVICE_OVERRIDES Decode Error");
              return ;
            }
          })));

function getEndpoint(param) {
  return (function (param$1, param$2) {
      return _getEndpoint(param, arg, param$1, param$2);
    });
}

exports.fromJson = fromJson;
exports._getEndpoint = _getEndpoint;
exports.getEndpoint = getEndpoint;
/* arg Not a pure module */
