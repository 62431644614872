// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var HtmlElementRe = require("bs-webapi/lib/js/src/dom/html/HtmlElementRe.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var Button = require("@material-ui/core/Button");
var Tooltip = require("@material-ui/core/Tooltip");
var Checkbox = require("@material-ui/core/Checkbox");
var ArrowDropUp = require("@material-ui/icons/ArrowDropUp");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");
var CenterFocusStrong = require("@material-ui/icons/CenterFocusStrong");

((require('../../../../src/static/syntaxHighlighter.css')));

function BugFetchedSource(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var attributes = Props.attributes;
  var endpoint = Props.endpoint;
  var directory = Props.directory;
  var fileName = Props.fileName;
  var funcName = Props.funcName;
  var objName = Props.objName;
  var faultingLine = Props.faultingLine;
  var match = React.useState((function () {
          
        }));
  var setFetchedSourceCode = match[1];
  var fetchedSourceCode = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowTimestamps = match$1[1];
  var showTimestamps = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setShowCommits = match$2[1];
  var showCommits = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setShowBlame = match$3[1];
  var showBlame = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setGetBlame = match$4[1];
  var match$5 = React.useState((function () {
          return 0;
        }));
  var setRefetchKey = match$5[1];
  var match$6 = React.useState((function () {
          return false;
        }));
  var setIsLoading = match$6[1];
  var match$7 = React.useState((function () {
          
        }));
  var setFullscreenMsg = match$7[1];
  var fullscreenMsg = match$7[0];
  var scrollTarget = React.useRef(/* Fault */2);
  var surroundingLines = React.useRef(256);
  var setSurroundingLines = function (r) {
    surroundingLines.current = Curry._1(r, surroundingLines.current);
    
  };
  React.useEffect((function () {
          Curry._1(setRefetchKey, (function (rk) {
                  return rk + 1 | 0;
                }));
          
        }), [match$4[0]]);
  React.useEffect((function () {
          Curry._1(setGetBlame, (function (param) {
                  if (showTimestamps || showCommits) {
                    return true;
                  } else {
                    return showBlame;
                  }
                }));
          
        }), /* tuple */[
        showTimestamps,
        showCommits,
        showBlame
      ]);
  React.useEffect((function () {
          scrollTarget.current = /* Fault */2;
          setSurroundingLines((function (param) {
                  return 256;
                }));
          
        }), /* tuple */[
        Belt_Option.mapWithDefault(directory, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(fileName, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(funcName, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(objName, "", Util$BsConsole.identity)
      ]);
  React.useEffect((function () {
          Curry._1(setIsLoading, (function (param) {
                  return true;
                }));
          Curry._1(setFetchedSourceCode, (function (param) {
                  
                }));
          Curry._1(setFullscreenMsg, (function (param) {
                  
                }));
          Task2$BsConsole.handle(token, BugTasks$BsConsole.FetchedSourceCode.$$fetch(projectName, universeName, attributes, endpoint, directory, fileName, funcName, objName, faultingLine, surroundingLines.current, showTimestamps || showCommits || showBlame, undefined), undefined, (function (resp) {
                  if (resp.tag) {
                    var err = resp[0];
                    Curry._1(setIsLoading, (function (param) {
                            return false;
                          }));
                    if (err[0] !== BugTasks$BsConsole.FetchedSourceCode.FileNotFound) {
                      return Curry._1(setFullscreenMsg, (function (param) {
                                    return "An unknown error occurred";
                                  }));
                    }
                    var errmsg = err[1];
                    return Curry._1(setFullscreenMsg, (function (param) {
                                  return Belt_Option.mapWithDefault(errmsg, "Not enough information to locate source code", Util$BsConsole.identity);
                                }));
                  }
                  var resp$1 = resp[0];
                  Curry._1(setFetchedSourceCode, (function (param) {
                          return resp$1;
                        }));
                  return Curry._1(setIsLoading, (function (param) {
                                return false;
                              }));
                }));
          
        }), /* tuple */[
        Belt_Option.mapWithDefault(directory, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(fileName, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(funcName, "", Util$BsConsole.identity),
        Belt_Option.mapWithDefault(objName, "", Util$BsConsole.identity),
        match$5[0]
      ]);
  var highlightStyle = Css.merge(/* :: */[
        "highlighted-line",
        /* :: */[
          Css.style(/* :: */[
                Css.background(Css.hex(Colors$BsConsole.orangeExtraLight)),
                /* :: */[
                  Css.display(/* inlineBlock */-147785676),
                  /* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]);
  var startLine = Belt_Option.mapWithDefault(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
              return fsc.startLine;
            })), 1, Util$BsConsole.identity);
  var totalLines = Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
          return fsc.totalLines;
        }));
  var isFaultingLine = function (i) {
    return Belt_Option.mapWithDefault(Belt_Option.map(faultingLine, (function (fl) {
                      return ((i + startLine | 0) - 1 | 0) === fl;
                    })), false, Util$BsConsole.identity);
  };
  var fileContents = Belt_Option.mapWithDefault(Belt_Option.map(fetchedSourceCode, (function (fsc) {
              return fsc.fileContents;
            })), [], (function (fc) {
          return fc.split("\n");
        }));
  var lastLine = (fileContents.length + startLine | 0) - 1 | 0;
  var fileContentsEl = Belt_Array.mapWithIndex(fileContents, (function (i, line) {
          return React.createElement("span", {
                      className: Css.merge(/* :: */[
                            Css.style(/* :: */[
                                  Css.display(/* block */888960333),
                                  /* [] */0
                                ]),
                            /* :: */[
                              isFaultingLine(i + 1 | 0) ? highlightStyle : "",
                              /* :: */[
                                isFaultingLine(i + 1 | 0) ? "faulting-line" : "",
                                /* [] */0
                              ]
                            ]
                          ])
                    }, I18N$BsConsole.showSkip(line + " "));
        }));
  var fileContentsHighlighted = Belt_Option.map(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
              return fsc.fileContentsHighlighted;
            })), (function (fch) {
          return Belt_Array.mapWithIndex(fch.split("\n"), (function (i, line) {
                          if (isFaultingLine(i + 1 | 0)) {
                            return "<span class='" + (highlightStyle + (" faulting-line'>" + (line + "</span>")));
                          } else {
                            return line;
                          }
                        })).join("\n");
        }));
  var sourceRepo = Belt_Option.mapWithDefault(Belt_Option.map(fetchedSourceCode, (function (fsc) {
              return fsc.sourceRepo;
            })), "", Util$BsConsole.identity);
  var sourceCommitDateFull = Belt_Option.mapWithDefault(Belt_Option.map(fetchedSourceCode, (function (fsc) {
              return fsc.sourceCommitDate;
            })), "", Util$BsConsole.identity);
  var sourceCommitDate = Belt_Option.mapWithDefault(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
              return Belt_Array.get(fsc.sourceCommitDate.split("T"), 0);
            })), "", Util$BsConsole.identity);
  var sourceCommit = Belt_Option.mapWithDefault(Belt_Option.map(fetchedSourceCode, (function (fsc) {
              return fsc.sourceCommit;
            })), "", Util$BsConsole.identity);
  var sourceFilepath = Belt_Option.mapWithDefault(Belt_Option.map(fetchedSourceCode, (function (fsc) {
              return fsc.sourceFilepath;
            })), "", Util$BsConsole.identity);
  var scrollRef = React.useRef(undefined);
  var columnStyle = function (show) {
    return Css.style(/* :: */[
                Css.margin(/* zero */-789508312),
                /* :: */[
                  Css.display(show ? /* unset */653193961 : /* none */-922086728),
                  /* [] */0
                ]
              ]);
  };
  var checkboxLabelStyle = Css.style(/* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.userSelect(Css.none),
          /* :: */[
            Css.marginRight(Css.rem(1.5)),
            /* [] */0
          ]
        ]
      ]);
  var columnSpan = function (i, line) {
    return React.createElement("span", {
                className: Css.merge(/* :: */[
                      Css.style(/* :: */[
                            Css.display(/* block */888960333),
                            /* :: */[
                              Css.paddingRight(Css.rem(1)),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        isFaultingLine(i + 1 | 0) ? highlightStyle : "",
                        /* [] */0
                      ]
                    ])
              }, I18N$BsConsole.showSkip(line));
  };
  var infoColumnStyle = Css.style(/* :: */[
        Css.paddingRight(Css.rem(1)),
        /* :: */[
          Css.minWidth(/* zero */-789508312),
          /* [] */0
        ]
      ]);
  var infoColumnHeaderStyle = Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.blackA54)),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* :: */[
            Css.fontSize(Css.rem(0.75)),
            /* [] */0
          ]
        ]
      ]);
  var ellipsisText = Css.style(/* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.textAlign(/* left */-944764921),
            /* :: */[
              Css.overflow(/* hidden */-862584982),
              /* :: */[
                Css.whiteSpace(/* nowrap */867913355),
                /* :: */[
                  Css.display(/* unset */653193961),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var scrollToFault = function (param) {
    var scrollDiv = scrollRef.current;
    if (scrollDiv === undefined) {
      return ;
    }
    var scrollDiv$1 = Caml_option.valFromOption(scrollDiv);
    var highlightedLine = Belt_Option.flatMap(Caml_option.nullable_to_opt(scrollDiv$1.querySelector(".faulting-line")), HtmlElementRe.ofElement);
    if (highlightedLine === undefined) {
      return ;
    }
    var highlightedLine$1 = Caml_option.valFromOption(highlightedLine);
    scrollDiv$1.scrollTop = highlightedLine$1.offsetTop - (highlightedLine$1.offsetHeight << 3) | 0;
    
  };
  var scrollToBottom = function (param) {
    var scrollDiv = scrollRef.current;
    if (scrollDiv !== undefined) {
      Caml_option.valFromOption(scrollDiv).scrollTop = 10000000;
      return ;
    }
    
  };
  React.useLayoutEffect((function () {
          var match = scrollTarget.current;
          switch (match) {
            case /* Top */0 :
                break;
            case /* Bottom */1 :
                scrollToBottom(undefined);
                break;
            case /* Fault */2 :
                scrollToFault(undefined);
                break;
            
          }
          
        }));
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.position(/* relative */903134412),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.margin(/* zero */-789508312),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.justifyContent(/* spaceBetween */516682146),
                            /* :: */[
                              Css.paddingRight(Css.pct(1)),
                              /* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey65)),
                                /* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.minHeight(Css.rem(3)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(Row2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.margin(/* zero */-789508312),
                              /* :: */[
                                Css.padding(/* zero */-789508312),
                                /* :: */[
                                  Css.paddingLeft(Css.rem(0.5)),
                                  /* :: */[
                                    Css.flexWrap(/* nowrap */867913355),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* :: */[
                                        Css.justifyContent(/* flexStart */662439529),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(Col2$BsConsole.make, {
                          className: infoColumnStyle,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              className: infoColumnHeaderStyle,
                              children: I18N$BsConsole.show(undefined, "Repository")
                            }), React.createElement(Row2$BsConsole.make, {
                              className: ellipsisText,
                              children: React.createElement(Tooltip.default, {
                                    interactive: true,
                                    children: React.createElement("span", undefined, I18N$BsConsole.showSkip(sourceRepo)),
                                    title: sourceRepo
                                  })
                            })), React.createElement(Col2$BsConsole.make, {
                          className: infoColumnStyle,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              className: infoColumnHeaderStyle,
                              children: I18N$BsConsole.show(undefined, "Date")
                            }), React.createElement(Row2$BsConsole.make, {
                              className: ellipsisText,
                              children: React.createElement(Tooltip.default, {
                                    interactive: true,
                                    children: React.createElement("span", undefined, I18N$BsConsole.showSkip(sourceCommitDate)),
                                    title: sourceCommitDateFull
                                  })
                            })), React.createElement(Col2$BsConsole.make, {
                          className: infoColumnStyle,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              className: infoColumnHeaderStyle,
                              children: I18N$BsConsole.show(undefined, "Revision")
                            }), React.createElement("div", {
                              className: ellipsisText
                            }, React.createElement(Tooltip.default, {
                                  interactive: true,
                                  children: React.createElement("span", undefined, I18N$BsConsole.showSkip(sourceCommit)),
                                  title: sourceCommit
                                }))), React.createElement(Col2$BsConsole.make, {
                          className: infoColumnStyle,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              className: infoColumnHeaderStyle,
                              children: I18N$BsConsole.show(undefined, "Filepath")
                            }), React.createElement("div", {
                              className: ellipsisText
                            }, React.createElement(Tooltip.default, {
                                  interactive: true,
                                  children: React.createElement("span", undefined, I18N$BsConsole.showSkip(sourceFilepath)),
                                  title: sourceFilepath
                                })))), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      className: Css.style(/* :: */[
                            Css.userSelect(Css.none),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Tooltip.default, {
                          children: React.createElement(Button.default, {
                                variant: "text",
                                size: "small",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.75)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return scrollToFault(undefined);
                                  }),
                                children: React.createElement(CenterFocusStrong.default, { })
                              }),
                          title: "Jump to highlighted line"
                        }), React.createElement(FormControlLabel.default, {
                          label: "Time",
                          control: React.createElement(Checkbox.default, {
                                checked: showTimestamps,
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey4)),
                                        /* :: */[
                                          Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                          /* :: */[
                                            Css.paddingRight(Css.rem(0.5)),
                                            /* :: */[
                                              Css.selector("svg", /* :: */[
                                                    Css.width(Css.rem(0.9)),
                                                    /* :: */[
                                                      Css.height(Css.rem(0.9)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ])
                                },
                                onChange: (function (e) {
                                    var value = e.target.checked;
                                    scrollTarget.current = /* Fault */2;
                                    return Curry._1(setShowTimestamps, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disableRipple: true
                              }),
                          classes: {
                            root: checkboxLabelStyle
                          }
                        }), React.createElement(FormControlLabel.default, {
                          label: "Blame",
                          control: React.createElement(Checkbox.default, {
                                checked: showBlame,
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey4)),
                                        /* :: */[
                                          Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                          /* :: */[
                                            Css.paddingRight(Css.rem(0.5)),
                                            /* :: */[
                                              Css.selector("svg", /* :: */[
                                                    Css.width(Css.rem(0.9)),
                                                    /* :: */[
                                                      Css.height(Css.rem(0.9)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ])
                                },
                                onChange: (function (e) {
                                    var value = e.target.checked;
                                    scrollTarget.current = /* Fault */2;
                                    return Curry._1(setShowBlame, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disableRipple: true
                              }),
                          classes: {
                            root: checkboxLabelStyle
                          }
                        }), React.createElement(FormControlLabel.default, {
                          label: "Commit",
                          control: React.createElement(Checkbox.default, {
                                checked: showCommits,
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey4)),
                                        /* :: */[
                                          Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                          /* :: */[
                                            Css.paddingRight(Css.rem(0.5)),
                                            /* :: */[
                                              Css.selector("svg", /* :: */[
                                                    Css.width(Css.rem(0.9)),
                                                    /* :: */[
                                                      Css.height(Css.rem(0.9)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ])
                                },
                                onChange: (function (e) {
                                    var value = e.target.checked;
                                    scrollTarget.current = /* Fault */2;
                                    return Curry._1(setShowCommits, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disableRipple: true
                              }),
                          classes: {
                            root: checkboxLabelStyle
                          }
                        }))), match$6[0] ? React.createElement(CircularProgress.default, {
                    disableShrink: true,
                    size: 48,
                    classes: {
                      root: Css.style(/* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.top(/* `calc */[
                                    -1044768619,
                                    /* tuple */[
                                      /* sub */5745024,
                                      Css.pct(50),
                                      Css.px(24)
                                    ]
                                  ]),
                              /* :: */[
                                Css.right(/* `calc */[
                                      -1044768619,
                                      /* tuple */[
                                        /* sub */5745024,
                                        Css.pct(50),
                                        Css.px(24)
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ])
                    }
                  }) : null, fetchedSourceCode !== undefined ? React.createElement("div", {
                    ref: (function (r) {
                        var r$1 = (r == null) ? undefined : Caml_option.some(r);
                        scrollRef.current = r$1;
                        
                      }),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.height(Css.pct(100)),
                            /* :: */[
                              Css.padding2(Css.px(10), Css.px(10)),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.overflow(/* auto */-1065951377),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, startLine > 1 ? React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          justifyContent: /* center */98248149,
                          children: React.createElement(Button.default, {
                                variant: "text",
                                size: "small",
                                color: "primary",
                                onClick: (function (_event) {
                                    scrollTarget.current = /* Top */0;
                                    setSurroundingLines((function (sl) {
                                            return sl + 100 | 0;
                                          }));
                                    return Curry._1(setRefetchKey, (function (rk) {
                                                  return rk + 1 | 0;
                                                }));
                                  }),
                                children: null
                              }, React.createElement(ArrowDropUp.default, {
                                    className: Css.style(/* :: */[
                                          Css.userSelect(Css.none),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.green)),
                                            /* :: */[
                                              Css.marginRight(Css.rem(0.5)),
                                              /* [] */0
                                            ]
                                          ]
                                        ])
                                  }), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Show ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " lines",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "Show %s lines"
                                      ]), String(Math.min(100, startLine - 1 | 0))))
                        }) : null, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.flexDirection(/* row */5693978),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement("pre", {
                            className: columnStyle(showCommits)
                          }, React.createElement("code", undefined, Belt_Array.mapWithIndex(Belt_Option.mapWithDefault(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
                                              return fsc.commits;
                                            })), [], (function (b) {
                                          return b.split("\n");
                                        })), columnSpan))), React.createElement("pre", {
                            className: columnStyle(showTimestamps)
                          }, React.createElement("code", undefined, Belt_Array.mapWithIndex(Belt_Option.mapWithDefault(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
                                              return fsc.timeStamps;
                                            })), [], (function (b) {
                                          return b.split("\n");
                                        })), columnSpan))), React.createElement("pre", {
                            className: columnStyle(showBlame)
                          }, React.createElement("code", undefined, Belt_Array.mapWithIndex(Belt_Option.mapWithDefault(Belt_Option.flatMap(fetchedSourceCode, (function (fsc) {
                                              return fsc.blame;
                                            })), [], (function (b) {
                                          return b.split("\n");
                                        })), columnSpan))), React.createElement("pre", {
                            className: Css.style(/* :: */[
                                  Css.margin(/* zero */-789508312),
                                  /* [] */0
                                ])
                          }, React.createElement("code", {
                                className: Css.style(/* :: */[
                                      Css.$$float(/* left */-944764921),
                                      /* [] */0
                                    ])
                              }, Belt_Array.mapWithIndex(fileContents, (function (lineNo, _txt) {
                                      return columnSpan(lineNo, String(lineNo + startLine | 0));
                                    }))), fileContentsHighlighted !== undefined ? React.createElement("code", {
                                  dangerouslySetInnerHTML: {
                                    __html: fileContentsHighlighted
                                  }
                                }) : React.createElement("code", undefined, fileContentsEl))), totalLines !== undefined && totalLines > lastLine ? React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          justifyContent: /* center */98248149,
                          children: React.createElement(Button.default, {
                                variant: "text",
                                size: "small",
                                color: "primary",
                                onClick: (function (_event) {
                                    scrollTarget.current = /* Bottom */1;
                                    setSurroundingLines((function (sl) {
                                            return sl + 100 | 0;
                                          }));
                                    return Curry._1(setRefetchKey, (function (rk) {
                                                  return rk + 1 | 0;
                                                }));
                                  }),
                                children: null
                              }, React.createElement(ArrowDropDown.default, {
                                    className: Css.style(/* :: */[
                                          Css.userSelect(Css.none),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.green)),
                                            /* :: */[
                                              Css.marginRight(Css.rem(0.5)),
                                              /* [] */0
                                            ]
                                          ]
                                        ])
                                  }), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Show ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " lines",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "Show %s lines"
                                      ]), String(Math.min(100, lastLine - 1 | 0))))
                        }) : null) : (
                fullscreenMsg !== undefined ? React.createElement(Col2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* center */98248149,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* :: */[
                                  Css.padding2(Css.px(10), Css.px(10)),
                                  /* :: */[
                                    Css.fontSize(Css.rem(1.5)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        children: I18N$BsConsole.dynamic(fullscreenMsg)
                      }) : null
              ));
}

var make = React.memo(BugFetchedSource);

exports.make = make;
/*  Not a pure module */
