// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Theme$BsConsole = require("../Theme.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var component = RR$BsConsole.statelessComponent("Renderer_CallstackFrame-BsConsole");

function make(attribute, handleAddFilters, handleChangeUrl, frame, raw, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                        /* tuple */[
                                          attribute,
                                          /* Contains */Block.__(2, [/* `String */[
                                                -976970511,
                                                raw
                                              ]])
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            attribute,
                                            /* NotContains */Block.__(3, [/* `String */[
                                                  -976970511,
                                                  raw
                                                ]])
                                          ],
                                          /* [] */0
                                        ]
                                      ], undefined, raw, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                          var func = function (param, param$1, param$2, param$3) {
                                            return ValueDrilldown$BsConsole.wrapWithButton(onClick, param, param$1, param$2, param$3);
                                          };
                                          var arg = Css.style(/* :: */[
                                                Css.textTransform(Css.none),
                                                /* :: */[
                                                  Css.fontFamily("Inconsolata"),
                                                  /* :: */[
                                                    Css.padding2(Css.px(0), Css.px(8)),
                                                    /* :: */[
                                                      Css.whiteSpace(Css.nowrap),
                                                      /* :: */[
                                                        Css.minHeight(Css.zero),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]);
                                          return React.createElement("div", {
                                                      ref: refHandler,
                                                      style: {
                                                        display: "inline-block"
                                                      },
                                                      onFocus: onFocus
                                                    }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, {
                                                              tooltip: Css.style(/* :: */[
                                                                    Css.wordWrap(Css.breakWord),
                                                                    /* [] */0
                                                                  ])
                                                            }, React.createElement("span", {
                                                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                                                }, I18N$BsConsole.showSkip(frame)), undefined, /* Top */4202101, 1000, 100, Caml_option.some(Theme$BsConsole.main), [func(undefined, undefined, arg, I18N$BsConsole.showSkip(frame))])));
                                        }), undefined, undefined, []))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
