// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Service$BsConsole = require("./Service.js");
var DefaultView$BsConsole = require("./saved-queries/DefaultView.js");

function ofJson(json) {
  return {
          hasSeenFirstTimeModal: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("has_seen_first_time_modal", Json_decode.bool, param);
                    }), json), false)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "has_seen_first_time_modal",
                t.hasSeenFirstTimeModal
              ],
              /* [] */0
            ]);
}

var UnityCDA = {
  ofJson: ofJson,
  toJson: toJson
};

var $$default = {
  bookmarksVersion: 0
};

function ofJson$1(json) {
  return {
          bookmarksVersion: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("bookmarksVersion", Json_decode.$$int, param);
                    }), json), 0)
        };
}

function toJson$1(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "bookmarksVersion",
                t.bookmarksVersion
              ],
              /* [] */0
            ]);
}

var FirstTimeExperience = {
  $$default: $$default,
  ofJson: ofJson$1,
  toJson: toJson$1
};

var empty_bookmarkedViews = [];

var empty = {
  defaultView: undefined,
  bookmarkedViews: empty_bookmarkedViews,
  serviceOverrides: undefined,
  versionAttribute: undefined,
  normBy: undefined,
  unityCDA: undefined,
  firstTimeExperience: $$default
};

function ofJson$2(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("bookmarkedViews", (function (param) {
                        return Json_decode.array(Json_decode.string, param);
                      }), param);
        }), json);
  var __x$1 = Json_decode.optional((function (param) {
          return Json_decode.field("firstTimeExperience", ofJson$1, param);
        }), json);
  return {
          defaultView: Json_decode.optional((function (param) {
                  return Json_decode.field("defaultView", DefaultView$BsConsole.ofJson, param);
                }), json),
          bookmarkedViews: Belt_Option.getWithDefault(__x, []),
          serviceOverrides: Json_decode.optional((function (param) {
                  return Json_decode.field("serviceOverrides", (function (param) {
                                return Json_decode.list(Service$BsConsole.fromJson, param);
                              }), param);
                }), json),
          versionAttribute: Json_decode.optional((function (param) {
                  return Json_decode.field("versionAttribute", Json_decode.string, param);
                }), json),
          normBy: Json_decode.optional((function (param) {
                  return Json_decode.field("normBy", Json_decode.string, param);
                }), json),
          unityCDA: Json_decode.optional((function (param) {
                  return Json_decode.field("unity_CDA", ofJson, param);
                }), json),
          firstTimeExperience: Belt_Option.getWithDefault(__x$1, $$default)
        };
}

function toJson$2(t) {
  var defaultView = t.defaultView;
  var props_000 = /* tuple */[
    "defaultView",
    defaultView !== undefined ? DefaultView$BsConsole.toJson(defaultView) : null
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "bookmarkedViews",
      t.bookmarkedViews
    ],
    /* :: */[
      /* tuple */[
        "firstTimeExperience",
        toJson$1(t.firstTimeExperience)
      ],
      /* [] */0
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var serviceOverrides = t.serviceOverrides;
  var props$1 = serviceOverrides !== undefined ? /* :: */[
      /* tuple */[
        "serviceOverrides",
        Json_encode.list(Service$BsConsole.toJson, serviceOverrides)
      ],
      props
    ] : props;
  var versionAttribute = t.versionAttribute;
  var props$2 = versionAttribute !== undefined ? /* :: */[
      /* tuple */[
        "versionAttribute",
        versionAttribute
      ],
      props$1
    ] : props$1;
  var normBy = t.normBy;
  var props$3 = normBy !== undefined ? /* :: */[
      /* tuple */[
        "normBy",
        normBy
      ],
      props$2
    ] : props$2;
  var u = t.unityCDA;
  return Json_encode.object_(u !== undefined ? /* :: */[
                /* tuple */[
                  "unity_CDA",
                  toJson(u)
                ],
                /* [] */0
              ] : props$3);
}

function toBPG(settings, pid) {
  return {
          pid: pid,
          json: JSON.stringify(toJson$2(settings)),
          __state: undefined,
          __create: undefined,
          __modify: undefined
        };
}

function getUnparsedSettingsFromBpg(token, pid, cb) {
  var arg = BpgTask$BsConsole.ProjectFrontendSettings.fetchAll;
  Curry._2((function (param) {
            return (function (param$1, param$2) {
                return Task2$BsConsole.handle(param, arg, param$1, param$2);
              });
          })(token), true, (function (allUserSettings) {
          if (allUserSettings.tag) {
            Curry._1(cb, /* Error */Block.__(1, [allUserSettings[0]]));
          } else {
            var myUserSettingsBpg = Belt_Array.getBy(allUserSettings[0], (function (s) {
                    return s.pid === pid;
                  }));
            Curry._1(cb, /* Ok */Block.__(0, [myUserSettingsBpg]));
          }
          
        }));
  
}

function getParsedSettingsFromBpg(token, pid, cb) {
  return getUnparsedSettingsFromBpg(token, pid, (function (projectSettingsBpgResult) {
                return Belt_Result.map(projectSettingsBpgResult, (function (projectSettingsBpg) {
                              if (projectSettingsBpg === undefined) {
                                return Curry._1(cb, undefined);
                              }
                              var parsedSettings = Json.parse(projectSettingsBpg.json);
                              return Curry._1(cb, Belt_Option.map(parsedSettings, ofJson$2));
                            }));
              }));
}

function deleteProjectSettingsForProject(token, pid, successCb, errorCb) {
  return getUnparsedSettingsFromBpg(token, pid, (function (projectSettings) {
                if (projectSettings.tag) {
                  return Curry._1(errorCb, undefined);
                }
                var projectSettings$1 = projectSettings[0];
                if (projectSettings$1 !== undefined) {
                  return Task2$BsConsole.handle(token, BpgTask$BsConsole.ProjectFrontendSettings.$$delete(projectSettings$1), undefined, (function (_resp) {
                                return Curry._1(successCb, undefined);
                              }));
                } else {
                  return Curry._1(successCb, undefined);
                }
              }));
}

function updateOrCreate(token, pid, newSettingsFn, cb) {
  return getParsedSettingsFromBpg(token, pid, (function (frontendSettings) {
                var newSettings = Curry._1(newSettingsFn, frontendSettings);
                if (frontendSettings !== undefined) {
                  var arg = BpgTask$BsConsole.ProjectFrontendSettings.updateFieldsByKey({
                        pid: pid
                      }, [/* tuple */[
                          "json",
                          toJson$2(newSettings)
                        ]]);
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                              });
                          })(token), undefined, (function (_res) {
                          return Curry._1(cb, undefined);
                        }));
                  return ;
                }
                var arg$1 = BpgTask$BsConsole.ProjectFrontendSettings.create(toBPG(newSettings, pid));
                return Curry._2((function (param) {
                                return (function (param$1, param$2) {
                                    return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                  });
                              })(token), true, (function (_res) {
                              return Curry._1(cb, undefined);
                            }));
              }));
}

function getNextSettings(actions, settings) {
  return Belt_List.reduce(actions, settings, (function (accumulator, action) {
                switch (action.tag | 0) {
                  case /* AddBookmarksWithDefaults */0 :
                      return {
                              defaultView: accumulator.defaultView,
                              bookmarkedViews: Belt_Array.concat(accumulator.bookmarkedViews, action[0]),
                              serviceOverrides: accumulator.serviceOverrides,
                              versionAttribute: accumulator.versionAttribute,
                              normBy: accumulator.normBy,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: {
                                bookmarksVersion: action[1]
                              }
                            };
                  case /* AddBookmarkedView */1 :
                      var bookmarkedViews = Belt_Array.concat(accumulator.bookmarkedViews, [action[0]]);
                      return {
                              defaultView: accumulator.defaultView,
                              bookmarkedViews: bookmarkedViews,
                              serviceOverrides: accumulator.serviceOverrides,
                              versionAttribute: accumulator.versionAttribute,
                              normBy: accumulator.normBy,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience
                            };
                  case /* RemoveBookmarkedView */2 :
                      var view = action[0];
                      var bookmarkedViews$1 = Belt_Array.keep(accumulator.bookmarkedViews, (function (v) {
                              return v !== view;
                            }));
                      return {
                              defaultView: accumulator.defaultView,
                              bookmarkedViews: bookmarkedViews$1,
                              serviceOverrides: accumulator.serviceOverrides,
                              versionAttribute: accumulator.versionAttribute,
                              normBy: accumulator.normBy,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience
                            };
                  case /* SetVersionAttribute */3 :
                      return {
                              defaultView: accumulator.defaultView,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              serviceOverrides: accumulator.serviceOverrides,
                              versionAttribute: action[0],
                              normBy: accumulator.normBy,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience
                            };
                  case /* SetNormBy */4 :
                      return {
                              defaultView: accumulator.defaultView,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              serviceOverrides: accumulator.serviceOverrides,
                              versionAttribute: accumulator.versionAttribute,
                              normBy: action[0],
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience
                            };
                  
                }
              }));
}

function reduce(token, pid, actions, cb) {
  return updateOrCreate(token, pid, (function (frontendSettings) {
                var settings = frontendSettings !== undefined ? frontendSettings : empty;
                return getNextSettings(actions, settings);
              }), cb);
}

exports.UnityCDA = UnityCDA;
exports.FirstTimeExperience = FirstTimeExperience;
exports.empty = empty;
exports.ofJson = ofJson$2;
exports.toJson = toJson$2;
exports.toBPG = toBPG;
exports.getUnparsedSettingsFromBpg = getUnparsedSettingsFromBpg;
exports.getParsedSettingsFromBpg = getParsedSettingsFromBpg;
exports.deleteProjectSettingsForProject = deleteProjectSettingsForProject;
exports.updateOrCreate = updateOrCreate;
exports.getNextSettings = getNextSettings;
exports.reduce = reduce;
/* Task2-BsConsole Not a pure module */
