// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Colors$BsConsole = require("../Colors.js");
var Styles = require("@material-ui/core/styles");

var core = {
  overrides: {
    MuiTabs: {
      root: {
        minHeight: "32px"
      }
    },
    MuiTab: {
      labelContainer: {
        "@media (minWidth: 960px)": {
          padding: "6px 12px"
        },
        padding: "6px 12px"
      },
      textColorPrimary: {
        color: "#" + Colors$BsConsole.grey4
      },
      root: {
        "@media (minWidth: 960px)": {
          minWidth: "110px",
          minHeight: "32px",
          fontSize: "12px",
          paddingTop: "4px"
        },
        minWidth: "110px",
        minHeight: "32px",
        fontSize: "12px",
        paddingTop: "4px",
        "&$disabled": {
          color: "#" + (Colors$BsConsole.grey5 + " !important")
        }
      }
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "9px",
        paddingRight: "9px",
        fontSize: "0.8125em",
        lineHeight: "21px",
        height: "21px"
      },
      gutters: {
        paddingLeft: "9px",
        paddingRight: "9px"
      }
    },
    MuiListItem: {
      root: {
        paddingTop: "3px",
        paddingBottom: "3px"
      },
      button: {
        "&:hover": {
          backgroundColor: "#" + Colors$BsConsole.blue,
          color: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        },
        "&:focus": {
          backgroundColor: "#" + Colors$BsConsole.blue,
          color: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        },
        transition: "none"
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: "6px",
        fontSize: "22px"
      }
    },
    MuiList: {
      padding: {
        paddingTop: "3px",
        paddingBottom: "3px"
      }
    },
    MuiIconButton: {
      root: {
        padding: "6px"
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "11px",
        padding: "6px 10px",
        maxWidth: "500px"
      },
      tooltipPlacementBottom: {
        "@media (minWidth: 600px)": {
          margin: "4px 0"
        }
      },
      tooltipPlacementTop: {
        "@media (minWidth: 600px)": {
          margin: "4px 0"
        }
      }
    }
  }
};

var green = Styles.createMuiTheme(Object.assign(core, {
          palette: {
            primary: {
              main: Colors$BsConsole.octothorpe(Colors$BsConsole.green),
              light: Colors$BsConsole.octothorpe(Colors$BsConsole.greenLight),
              dark: Colors$BsConsole.octothorpe(Colors$BsConsole.greenDark),
              contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            },
            secondary: {
              main: Colors$BsConsole.octothorpe(Colors$BsConsole.green),
              light: Colors$BsConsole.octothorpe(Colors$BsConsole.greenLight),
              dark: Colors$BsConsole.octothorpe(Colors$BsConsole.greenDark),
              contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            }
          }
        }));

var blue = Styles.createMuiTheme(Object.assign(core, {
          palette: {
            primary: {
              main: Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
              light: Colors$BsConsole.octothorpe(Colors$BsConsole.blueLight),
              dark: Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark),
              contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            },
            secondary: {
              main: Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
              light: Colors$BsConsole.octothorpe(Colors$BsConsole.blueLight),
              dark: Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark),
              contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            }
          }
        }));

exports.core = core;
exports.green = green;
exports.blue = blue;
/* core Not a pure module */
