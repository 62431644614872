// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");

var resource = "notifications";

function fromJson(json) {
  return {
          uuid: Json_decode.field("uuid", Json_decode.string, json),
          alertId: Json_decode.field("alert_id", Json_decode.string, json),
          alertEvaluationId: Json_decode.field("alert_evaluation_id", Json_decode.string, json),
          state: Json_decode.field("state", Json_decode.string, json),
          createdAt: Json_decode.field("created_at", Json_decode.$$float, json),
          warningTriggerCount: Json_decode.field("warning_trigger_count", Json_decode.$$int, json),
          criticalTriggerCount: Json_decode.field("critical_trigger_count", Json_decode.$$int, json),
          warningGroupCount: Json_decode.field("warning_group_count", Json_decode.$$int, json),
          criticalGroupCount: Json_decode.field("critical_group_count", Json_decode.$$int, json)
        };
}

function notificationResponseFromJson(json) {
  return {
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.array(fromJson, param);
                }), json)
        };
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [notificationResponseFromJson(respBag.json)]);
              }), undefined, undefined);
}

function getByAlertId(path, universeName, projectName, alertId, limitOpt, param) {
  var limit = limitOpt !== undefined ? limitOpt : 50;
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + ("&alert_id=" + (String(alertId) + ("&limit=" + (String(limit) + "")))))))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [notificationResponseFromJson(respBag.json)]);
              }), undefined, undefined);
}

exports.resource = resource;
exports.fromJson = fromJson;
exports.notificationResponseFromJson = notificationResponseFromJson;
exports.get = get;
exports.getByAlertId = getByAlertId;
/* Task2-BsConsole Not a pure module */
