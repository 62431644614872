// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Emotion = require("emotion");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Css_Colors = require("./Css_Colors.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

function mergeStyles(stylenames) {
  return Emotion.cx($$Array.of_list(stylenames));
}

function ruleToJs(rule) {
  var variant = rule[0];
  if (variant < -659583595) {
    if (variant >= -737064851) {
      return /* tuple */[
              "textShadow",
              rule[1]
            ];
    } else {
      return /* tuple */[
              "animation",
              rule[1]
            ];
    }
  }
  if (variant >= 488687584) {
    if (variant < 829240095) {
      return /* tuple */[
              "boxShadow",
              rule[1]
            ];
    }
    var match = rule[1];
    return /* tuple */[
            match[0],
            Js_dict.fromList(List.map(ruleToJs, match[1]))
          ];
  }
  if (variant < -434952966) {
    return /* tuple */[
            "transition",
            rule[1]
          ];
  }
  var match$1 = rule[1];
  var value = match$1[1];
  var name = match$1[0];
  if (name === "content") {
    return /* tuple */[
            name,
            value === "" ? "\"\"" : value
          ];
  } else {
    return /* tuple */[
            name,
            value
          ];
  }
}

function makeJson(rules) {
  return Js_dict.fromList(List.map(ruleToJs, rules));
}

function make(rules) {
  return Emotion.css(Js_dict.fromList(List.map(ruleToJs, rules)));
}

function join(separator, strings) {
  var _acc = "";
  var _strings = strings;
  while(true) {
    var strings$1 = _strings;
    var acc = _acc;
    if (!strings$1) {
      return acc;
    }
    var xs = strings$1[1];
    var x = strings$1[0];
    if (!xs) {
      return acc + x;
    }
    _strings = xs;
    _acc = acc + (x + separator);
    continue ;
  };
}

function string_of_length(param) {
  if (typeof param === "number") {
    return "0";
  }
  var variant = param[0];
  if (variant >= 22632) {
    if (variant >= 25096) {
      if (variant >= 26433) {
        if (variant >= 5691738) {
          return param[1].toString() + "rem";
        } else {
          return param[1].toString() + "vw";
        }
      } else if (variant >= 26418) {
        return param[1].toString() + "vh";
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 24416) {
      if (variant >= 25092) {
        return param[1].toString() + "pt";
      } else {
        return param[1].toString() + "mm";
      }
    } else if (variant >= 22643) {
      return param[1].toString() + "ex";
    } else {
      return param[1].toString() + "em";
    }
  }
  if (variant >= -833470756) {
    if (variant >= 22181) {
      if (variant >= 22186) {
        return param[1].toString() + "cm";
      } else {
        return param[1].toString() + "ch";
      }
    } else if (variant >= -119887163) {
      return param[1].toString() + "%";
    } else {
      return param[1].toString() + "vmin";
    }
  }
  if (variant === -1040484748) {
    return param[1].toString() + "px";
  }
  if (variant >= -833472530) {
    return param[1].toString() + "vmax";
  }
  var match = param[1];
  if (match[0] >= 5745024) {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

function string_of_angle(param) {
  var variant = param[0];
  if (variant >= 4995526) {
    if (variant >= 5690837) {
      return param[1].toString() + "rad";
    } else {
      return param[1].toString() + "deg";
    }
  } else if (variant >= -855250051) {
    return param[1].toString() + "turn";
  } else {
    return param[1].toString() + "grad";
  }
}

function string_of_percent(param) {
  return param[1].toString() + "%";
}

function string_of_hsl(h, s, l) {
  return "hsl(" + (string_of_angle(h) + (", " + (string_of_percent(s) + (", " + (string_of_percent(l) + ")")))));
}

function string_of_alpha(param) {
  if (param[0] >= 5496390) {
    return param[1].toString();
  } else {
    return param[1].toString() + "%";
  }
}

function string_of_hsla(h, s, l, a) {
  return "hsla(" + (string_of_angle(h) + (", " + (string_of_percent(s) + (", " + (string_of_percent(l) + (", " + (string_of_alpha(a) + ")")))))));
}

function string_of_color(param) {
  if (typeof param === "number") {
    if (param >= 582626130) {
      return "transparent";
    } else {
      return "currentColor";
    }
  }
  var variant = param[0];
  if (variant !== -878128972) {
    if (variant >= 5197569) {
      if (variant >= 5692173) {
        var match = param[1];
        var r = match[0];
        var g = match[1];
        var b = match[2];
        return "rgb(" + (r.toString() + (", " + (g.toString() + (", " + (b.toString() + ")")))));
      }
      var match$1 = param[1];
      return string_of_hsl(match$1[0], match$1[1], match$1[2]);
    }
    if (variant >= 5194459) {
      return "#" + param[1];
    }
    var match$2 = param[1];
    return string_of_hsla(match$2[0], match$2[1], match$2[2], match$2[3]);
  }
  var match$3 = param[1];
  var r$1 = match$3[0];
  var g$1 = match$3[1];
  var b$1 = match$3[2];
  var a = match$3[3];
  return "rgba(" + (r$1.toString() + (", " + (g$1.toString() + (", " + (b$1.toString() + (", " + (a.toString() + ")")))))));
}

function string_of_stops(stops) {
  return join(", ", List.map((function (param) {
                    return join(" ", /* :: */[
                                string_of_color(param[1]),
                                /* :: */[
                                  string_of_length(param[0]),
                                  /* [] */0
                                ]
                              ]);
                  }), stops));
}

function string_of_linearGradient(angle, stops) {
  return "linear-gradient(" + (string_of_angle(angle) + (", " + (string_of_stops(stops) + ")")));
}

function string_of_repeatingLinearGradient(angle, stops) {
  return "repeating-linear-gradient(" + (string_of_angle(angle) + (", " + (string_of_stops(stops) + ")")));
}

function string_of_overflow(param) {
  if (param >= -862584982) {
    if (param >= 589592690) {
      return "visible";
    } else {
      return "hidden";
    }
  } else if (param >= -949692403) {
    return "scroll";
  } else {
    return "auto";
  }
}

function string_of_visibility(param) {
  if (param >= 589592690) {
    return "visible";
  } else {
    return "hidden";
  }
}

function string_of_background(bg) {
  if (typeof bg === "number") {
    if (bg !== 292050538) {
      if (bg >= 582626130) {
        return "transparent";
      } else {
        return "none";
      }
    } else {
      return "currentColor";
    }
  }
  var variant = bg[0];
  if (variant >= 5197569) {
    if (variant !== 5692173) {
      if (variant >= 119548911) {
        if (variant < 616379637) {
          return "radial-gradient(" + (string_of_stops(bg[1]) + ")");
        }
        var match = bg[1];
        return "linear-gradient(" + (string_of_angle(match[0]) + (", " + (string_of_stops(match[1]) + ")")));
      }
      if (variant >= 5843823) {
        return "url(" + (bg[1] + ")");
      }
      var match$1 = bg[1];
      return string_of_hsl(match$1[0], match$1[1], match$1[2]);
    }
    var match$2 = bg[1];
    return "rgb(" + (join(", ", /* :: */[
                  match$2[0].toString(),
                  /* :: */[
                    match$2[1].toString(),
                    /* :: */[
                      match$2[2].toString(),
                      /* [] */0
                    ]
                  ]
                ]) + ")");
  }
  if (variant !== -878128972) {
    if (variant >= -160280644) {
      if (variant >= 5194459) {
        return "#" + bg[1];
      }
      var match$3 = bg[1];
      return "repeating-linear-gradient(" + (string_of_angle(match$3[0]) + (", " + (string_of_stops(match$3[1]) + ")")));
    }
    if (variant >= -657111370) {
      return "repeating-radial-gradient(" + (string_of_stops(bg[1]) + ")");
    }
    var match$4 = bg[1];
    return string_of_hsla(match$4[0], match$4[1], match$4[2], match$4[3]);
  }
  var match$5 = bg[1];
  return "rgba(" + (join(", ", /* :: */[
                match$5[0].toString(),
                /* :: */[
                  match$5[1].toString(),
                  /* :: */[
                    match$5[2].toString(),
                    /* :: */[
                      match$5[3].toString(),
                      /* [] */0
                    ]
                  ]
                ]
              ]) + ")");
}

function string_of_cursor(x) {
  if (x >= 82328943) {
    if (x >= 477181167) {
      if (x >= 624364317) {
        if (x !== 626862894) {
          if (x >= 939907157) {
            if (x >= 951366027) {
              return "ne-resize";
            } else {
              return "not-allowed";
            }
          } else if (x >= 676920916) {
            return "col-resize";
          } else {
            return "nw-resize";
          }
        } else {
          return "context-menu";
        }
      } else if (x >= 577832966) {
        if (x >= 621258809) {
          return "e-resize";
        } else {
          return "ew-resize";
        }
      } else if (x >= 563171728) {
        return "alias";
      } else {
        return "nesw-resize";
      }
    } else if (x >= 302348560) {
      if (x !== 365450254) {
        if (x >= 457757954) {
          if (x >= 465819841) {
            return "default";
          } else {
            return "n-resize";
          }
        } else if (x >= 381626435) {
          return "vertical-text";
        } else {
          return "no-drop";
        }
      } else {
        return "all-scroll";
      }
    } else if (x >= 180897442) {
      if (x >= 294257099) {
        return "w-resize";
      } else {
        return "crosshair";
      }
    } else if (x >= 103479213) {
      return "progress";
    } else {
      return "nwse-resize";
    }
  } else if (x >= -840286290) {
    if (x >= -459627717) {
      if (x !== -348903737) {
        if (x >= -19620980) {
          if (x >= -18796519) {
            return "ns-resize";
          } else {
            return "grabbing";
          }
        } else if (x >= -88732200) {
          return "zoom-in";
        } else {
          return "zoom-out";
        }
      } else {
        return "s-resize";
      }
    } else if (x >= -786317123) {
      if (x >= -693048282) {
        return "se-resize";
      } else {
        return "pointer";
      }
    } else if (x >= -822977931) {
      return "wait";
    } else {
      return "row-resize";
    }
  } else if (x >= -999567412) {
    if (x !== -989121855) {
      if (x >= -922086728) {
        if (x >= -856044371) {
          return "text";
        } else {
          return "none";
        }
      } else if (x >= -933174511) {
        return "move";
      } else {
        return "grab";
      }
    } else {
      return "help";
    }
  } else if (x >= -1044071499) {
    if (x >= -1020049992) {
      return "sw-resize";
    } else {
      return "copy";
    }
  } else if (x >= -1044569694) {
    return "cell";
  } else {
    return "auto";
  }
}

function string_of_fontWeight(x) {
  if (typeof x === "number") {
    if (x >= -81801163) {
      if (x >= 434326211) {
        if (x >= 812216871) {
          if (x >= 888264127) {
            return "900";
          } else {
            return "400";
          }
        } else if (x >= 653193961) {
          return "unset";
        } else {
          return "lighter";
        }
      } else if (x !== -21160922) {
        if (x >= -20425611) {
          return "500";
        } else {
          return "800";
        }
      } else {
        return "200";
      }
    } else if (x >= -812709613) {
      if (x >= -534575822) {
        if (x >= -184774442) {
          return "300";
        } else {
          return "bolder";
        }
      } else if (x >= -601204732) {
        return "inherit";
      } else {
        return "600";
      }
    } else if (x !== -878767996) {
      if (x >= -855898535) {
        return "100";
      } else {
        return "700";
      }
    } else {
      return "initial";
    }
  } else {
    return x[1].toString();
  }
}

function string_of_fontStyle(param) {
  if (param >= 107228912) {
    if (param >= 734792881) {
      if (param >= 812216871) {
        return "normal";
      } else {
        return "oblique";
      }
    } else if (param >= 653193961) {
      return "unset";
    } else {
      return "italic";
    }
  } else if (param >= -601204732) {
    return "inherit";
  } else {
    return "initial";
  }
}

function string_of_flex(param) {
  if (typeof param === "number") {
    if (param !== -922086728) {
      if (param >= -878767996) {
        return "initial";
      } else {
        return "auto";
      }
    } else {
      return "none";
    }
  } else {
    return param[1].toString();
  }
}

function string_of_flexBasis(param) {
  if (typeof param === "number") {
    if (param >= -550577721) {
      if (param >= 60557045) {
        if (param >= 427265337) {
          return "content";
        } else {
          return "max-content";
        }
      } else if (param >= -195805336) {
        return "fit-content";
      } else {
        return "min-content";
      }
    } else if (param !== -1011102077) {
      if (param >= -789508312) {
        return "0";
      } else {
        return "auto";
      }
    } else {
      return "fill";
    }
  }
  var variant = param[0];
  if (variant >= 22632) {
    if (variant >= 25096) {
      if (variant >= 26433) {
        if (variant >= 5691738) {
          return param[1].toString() + "rem";
        } else {
          return param[1].toString() + "vw";
        }
      } else if (variant >= 26418) {
        return param[1].toString() + "vh";
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 24416) {
      if (variant >= 25092) {
        return param[1].toString() + "pt";
      } else {
        return param[1].toString() + "mm";
      }
    } else if (variant >= 22643) {
      return param[1].toString() + "ex";
    } else {
      return param[1].toString() + "em";
    }
  }
  if (variant >= -833470756) {
    if (variant >= 22181) {
      if (variant >= 22186) {
        return param[1].toString() + "cm";
      } else {
        return param[1].toString() + "ch";
      }
    } else if (variant >= -119887163) {
      return param[1].toString() + "%";
    } else {
      return param[1].toString() + "vmin";
    }
  }
  if (variant === -1040484748) {
    return param[1].toString() + "px";
  }
  if (variant >= -833472530) {
    return param[1].toString() + "vmax";
  }
  var match = param[1];
  if (match[0] >= 5745024) {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

var cache = Emotion.cache;

function $$global(selector, rules) {
  Emotion.injectGlobal(Js_dict.fromList(/* :: */[
            /* tuple */[
              selector,
              Js_dict.fromList(List.map(ruleToJs, rules))
            ],
            /* [] */0
          ]));
  
}

function insertRule(raw) {
  Emotion.injectGlobal(raw);
  
}

function keyframes(frames) {
  var addStop = function (dict, param) {
    dict[param[0].toString() + "%"] = Js_dict.fromList(List.map(ruleToJs, param[1]));
    return dict;
  };
  return Emotion.keyframes(List.fold_left(addStop, { }, frames));
}

function d(property, value) {
  return /* `declaration */[
          -434952966,
          /* tuple */[
            property,
            value
          ]
        ];
}

function important(v) {
  if (typeof v === "number") {
    return v;
  }
  if (v[0] !== -434952966) {
    return v;
  }
  var match = v[1];
  return /* `declaration */[
          -434952966,
          /* tuple */[
            match[0],
            match[1] + " !important"
          ]
        ];
}

function label(label$1) {
  return /* `declaration */[
          -434952966,
          /* tuple */[
            "label",
            label$1
          ]
        ];
}

function deg(x) {
  return /* `deg */[
          4995526,
          x
        ];
}

function rad(x) {
  return /* `rad */[
          5690837,
          x
        ];
}

function grad(x) {
  return /* `grad */[
          -999567410,
          x
        ];
}

function turn(x) {
  return /* `turn */[
          -855250051,
          x
        ];
}

function pct(x) {
  return /* `percent */[
          -119887163,
          x
        ];
}

function rgb(r, g, b) {
  return /* `rgb */[
          5692173,
          /* tuple */[
            r,
            g,
            b
          ]
        ];
}

function rgba(r, g, b, a) {
  return /* `rgba */[
          -878128972,
          /* tuple */[
            r,
            g,
            b,
            a
          ]
        ];
}

function hsl(h, s, l) {
  return /* `hsl */[
          5197569,
          /* tuple */[
            h,
            /* `percent */[
              -119887163,
              s
            ],
            /* `percent */[
              -119887163,
              l
            ]
          ]
        ];
}

function hsla(h, s, l, a) {
  return /* `hsla */[
          -988425664,
          /* tuple */[
            h,
            /* `percent */[
              -119887163,
              s
            ],
            /* `percent */[
              -119887163,
              l
            ],
            a
          ]
        ];
}

function hex(x) {
  return /* `hex */[
          5194459,
          x
        ];
}

function linearGradient(angle, stops) {
  return /* `linearGradient */[
          616379637,
          /* tuple */[
            angle,
            stops
          ]
        ];
}

function repeatingLinearGradient(angle, stops) {
  return /* `repeatingLinearGradient */[
          -160280644,
          /* tuple */[
            angle,
            stops
          ]
        ];
}

function radialGradient(stops) {
  return /* `radialGradient */[
          119548911,
          stops
        ];
}

function repeatingRadialGradient(stops) {
  return /* `repeatingRadialGradient */[
          -657111370,
          stops
        ];
}

function string_of_length_cascading(param) {
  if (typeof param === "number") {
    if (param >= -601204732) {
      if (param >= 653193961) {
        return "unset";
      } else {
        return "inherit";
      }
    } else if (param >= -789508312) {
      return "0";
    } else {
      return "initial";
    }
  }
  var variant = param[0];
  if (variant >= 22632) {
    if (variant >= 25096) {
      if (variant >= 26433) {
        if (variant >= 5691738) {
          return param[1].toString() + "rem";
        } else {
          return param[1].toString() + "vw";
        }
      } else if (variant >= 26418) {
        return param[1].toString() + "vh";
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 24416) {
      if (variant >= 25092) {
        return param[1].toString() + "pt";
      } else {
        return param[1].toString() + "mm";
      }
    } else if (variant >= 22643) {
      return param[1].toString() + "ex";
    } else {
      return param[1].toString() + "em";
    }
  }
  if (variant >= -833470756) {
    if (variant >= 22181) {
      if (variant >= 22186) {
        return param[1].toString() + "cm";
      } else {
        return param[1].toString() + "ch";
      }
    } else if (variant >= -119887163) {
      return param[1].toString() + "%";
    } else {
      return param[1].toString() + "vmin";
    }
  }
  if (variant === -1040484748) {
    return param[1].toString() + "px";
  }
  if (variant >= -833472530) {
    return param[1].toString() + "vmax";
  }
  var match = param[1];
  if (match[0] >= 5745024) {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

function ch(x) {
  return /* `ch */[
          22181,
          x
        ];
}

function cm(x) {
  return /* `cm */[
          22186,
          x
        ];
}

function em(x) {
  return /* `em */[
          22632,
          x
        ];
}

function ex(x) {
  return /* `ex */[
          22643,
          x
        ];
}

function fr(x) {
  return /* `fr */[
          22860,
          x
        ];
}

function mm(x) {
  return /* `mm */[
          24416,
          x
        ];
}

function pt(x) {
  return /* `pt */[
          25092,
          x
        ];
}

function px(x) {
  return /* `px */[
          25096,
          x
        ];
}

function pxFloat(x) {
  return /* `pxFloat */[
          -1040484748,
          x
        ];
}

function rem(x) {
  return /* `rem */[
          5691738,
          x
        ];
}

function vh(x) {
  return /* `vh */[
          26418,
          x
        ];
}

function vmax(x) {
  return /* `vmax */[
          -833472530,
          x
        ];
}

function vmin(x) {
  return /* `vmin */[
          -833470756,
          x
        ];
}

function vw(x) {
  return /* `vw */[
          26433,
          x
        ];
}

function $neg(a, b) {
  return /* `calc */[
          -1044768619,
          /* tuple */[
            /* sub */5745024,
            a,
            b
          ]
        ];
}

function $plus(a, b) {
  return /* `calc */[
          -1044768619,
          /* tuple */[
            /* add */4846113,
            a,
            b
          ]
        ];
}

var Calc = {
  $neg: $neg,
  $plus: $plus
};

function size(x, y) {
  return /* `size */[
          -866934591,
          /* tuple */[
            x,
            y
          ]
        ];
}

function resize(x) {
  return d("resize", x >= -367674250 ? (
                x !== 365165796 ? (
                    x >= 653193961 ? (
                        x >= 888960333 ? "block" : "unset"
                      ) : (
                        x >= 423610969 ? "inline" : "vertical"
                      )
                  ) : "horizontal"
              ) : (
                x >= -878767996 ? (
                    x >= -601204732 ? "inherit" : "initial"
                  ) : (
                    x >= -922086728 ? "none" : "both"
                  )
              ));
}

function count(x) {
  return /* `count */[
          -899463985,
          x
        ];
}

function cubicBesier(a, b, c, d) {
  return /* `cubicBezier */[
          -554785527,
          /* tuple */[
            a,
            b,
            c,
            d
          ]
        ];
}

function localUrl(x) {
  return /* `localUrl */[
          -538079548,
          x
        ];
}

function rotate(a) {
  return /* `rotate */[
          -887077285,
          a
        ];
}

function rotate3d(x, y, z, a) {
  return /* `rotate3d */[
          142802924,
          /* tuple */[
            x,
            y,
            z,
            a
          ]
        ];
}

function rotateX(a) {
  return /* `rotateX */[
          -249738851,
          a
        ];
}

function rotateY(a) {
  return /* `rotateY */[
          -249738850,
          a
        ];
}

function rotateZ(a) {
  return /* `rotateZ */[
          -249738849,
          a
        ];
}

function scale(x, y) {
  return /* `scale */[
          -120664438,
          /* tuple */[
            x,
            y
          ]
        ];
}

function scale3d(x, y, z) {
  return /* `scale3d */[
          -452513317,
          /* tuple */[
            x,
            y,
            z
          ]
        ];
}

function scaleX(x) {
  return /* `scaleX */[
          1009117838,
          x
        ];
}

function scaleY(x) {
  return /* `scaleY */[
          1009117839,
          x
        ];
}

function scaleZ(x) {
  return /* `scaleZ */[
          1009117840,
          x
        ];
}

function skew(x, y) {
  return /* `skew */[
          -866839798,
          /* tuple */[
            x,
            y
          ]
        ];
}

function skewX(a) {
  return /* `skewX */[
          -31746546,
          a
        ];
}

function skewY(a) {
  return /* `skewY */[
          -31746545,
          a
        ];
}

function steps(i, dir) {
  return /* `steps */[
          68058023,
          /* tuple */[
            i,
            dir
          ]
        ];
}

function translate(x, y) {
  return /* `translate */[
          -914368690,
          /* tuple */[
            x,
            y
          ]
        ];
}

function translate3d(x, y, z) {
  return /* `translate3d */[
          178189215,
          /* tuple */[
            x,
            y,
            z
          ]
        ];
}

function translateX(x) {
  return /* `translateX */[
          106728778,
          x
        ];
}

function translateY(y) {
  return /* `translateY */[
          106728779,
          y
        ];
}

function translateZ(z) {
  return /* `translateZ */[
          106728780,
          z
        ];
}

function url(x) {
  return /* `url */[
          5843823,
          x
        ];
}

function display(x) {
  return d("display", x >= 64712127 ? (
                x >= 653193961 ? (
                    x >= 793912528 ? (
                        x >= 888960333 ? (
                            x >= 1054826616 ? "table-caption" : "block"
                          ) : (
                            x >= 850209563 ? "table-column-group" : "table-cell"
                          )
                      ) : (
                        x >= 790889754 ? "contents" : "unset"
                      )
                  ) : (
                    x >= 182695950 ? (
                        x >= 606419204 ? (
                            x >= 632591505 ? "list-item" : "table-header-group"
                          ) : (
                            x >= 423610969 ? "inline" : "table"
                          )
                      ) : (
                        x >= 97122692 ? "table-column" : "inline-grid"
                      )
                  )
              ) : (
                x >= -843129172 ? (
                    x >= -601204732 ? (
                        x >= -147785676 ? (
                            x >= 53323314 ? "inline-flex" : "inline-block"
                          ) : (
                            x >= -245903344 ? "run-in" : "inherit"
                          )
                      ) : (
                        x >= -836725517 ? "table-row-group" : "table-row"
                      )
                  ) : (
                    x >= -999565626 ? (
                        x >= -878767996 ? (
                            x >= -854050059 ? "inline-table" : "initial"
                          ) : (
                            x >= -922086728 ? "none" : "grid"
                          )
                      ) : (
                        x >= -1010954439 ? "flex" : "table-footer-group"
                      )
                  )
              ));
}

function position(x) {
  return d("position", x >= 100392110 ? (
                x >= 653193961 ? (
                    x >= 903134412 ? "relative" : "unset"
                  ) : (
                    x >= 188263721 ? "sticky" : "static"
                  )
              ) : (
                x >= -601204732 ? (
                    x >= 10615156 ? "fixed" : "inherit"
                  ) : (
                    x >= -878767996 ? "initial" : "absolute"
                  )
              ));
}

function top(x) {
  return d("top", string_of_length_cascading(x));
}

function bottom(x) {
  return d("bottom", string_of_length_cascading(x));
}

function left(x) {
  return d("left", string_of_length_cascading(x));
}

function right(x) {
  return d("right", string_of_length_cascading(x));
}

function flex(x) {
  return d("flex", string_of_flex(x));
}

function flex3(grow, shrink, basis) {
  return d("flex", grow.toString() + (" " + (shrink.toString() + (" " + string_of_flexBasis(basis)))));
}

function flexGrow(x) {
  return d("flexGrow", x.toString());
}

function flexShrink(x) {
  return d("flexShrink", x.toString());
}

function flexBasis(x) {
  return d("flexBasis", string_of_flexBasis(x));
}

function flexDirection(x) {
  return d("flexDirection", x >= 135477484 ? (
                x >= 378951208 ? "row-reverse" : "column-reverse"
              ) : (
                x >= 5693978 ? "row" : "column"
              ));
}

function flexWrap(x) {
  return d("flexWrap", x !== -463121288 ? (
                x >= 867913355 ? "nowrap" : "wrap"
              ) : "wrap-reverse");
}

function order(x) {
  return d("order", x.toString());
}

function string_of_margin(param) {
  if (typeof param === "number") {
    if (param >= -789508312) {
      return "0";
    } else {
      return "auto";
    }
  }
  var variant = param[0];
  if (variant >= 22632) {
    if (variant >= 25096) {
      if (variant >= 26433) {
        if (variant >= 5691738) {
          return param[1].toString() + "rem";
        } else {
          return param[1].toString() + "vw";
        }
      } else if (variant >= 26418) {
        return param[1].toString() + "vh";
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 24416) {
      if (variant >= 25092) {
        return param[1].toString() + "pt";
      } else {
        return param[1].toString() + "mm";
      }
    } else if (variant >= 22643) {
      return param[1].toString() + "ex";
    } else {
      return param[1].toString() + "em";
    }
  }
  if (variant >= -833470756) {
    if (variant >= 22181) {
      if (variant >= 22186) {
        return param[1].toString() + "cm";
      } else {
        return param[1].toString() + "ch";
      }
    } else if (variant >= -119887163) {
      return param[1].toString() + "%";
    } else {
      return param[1].toString() + "vmin";
    }
  }
  if (variant === -1040484748) {
    return param[1].toString() + "px";
  }
  if (variant >= -833472530) {
    return param[1].toString() + "vmax";
  }
  var match = param[1];
  if (match[0] >= 5745024) {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

function margin(x) {
  return d("margin", string_of_margin(x));
}

function margin2(v, h) {
  return d("margin", string_of_margin(v) + (" " + string_of_margin(h)));
}

function margin3(top, h, bottom) {
  return d("margin", string_of_margin(top) + (" " + (string_of_margin(h) + (" " + string_of_margin(bottom)))));
}

function margin4(top, right, bottom, left) {
  return d("margin", string_of_margin(top) + (" " + (string_of_margin(right) + (" " + (string_of_margin(bottom) + (" " + string_of_margin(left)))))));
}

function marginLeft(x) {
  return d("marginLeft", string_of_margin(x));
}

function marginRight(x) {
  return d("marginRight", string_of_margin(x));
}

function marginTop(x) {
  return d("marginTop", string_of_margin(x));
}

function marginBottom(x) {
  return d("marginBottom", string_of_margin(x));
}

function padding(x) {
  return d("padding", string_of_length(x));
}

function padding2(v, h) {
  return d("padding", string_of_length(v) + (" " + string_of_length(h)));
}

function padding3(top, h, bottom) {
  return d("padding", string_of_length(top) + (" " + (string_of_length(h) + (" " + string_of_length(bottom)))));
}

function padding4(top, right, bottom, left) {
  return d("padding", string_of_length(top) + (" " + (string_of_length(right) + (" " + (string_of_length(bottom) + (" " + string_of_length(left)))))));
}

function paddingLeft(x) {
  return d("paddingLeft", string_of_length(x));
}

function paddingRight(x) {
  return d("paddingRight", string_of_length(x));
}

function paddingTop(x) {
  return d("paddingTop", string_of_length(x));
}

function paddingBottom(x) {
  return d("paddingBottom", string_of_length(x));
}

function string_of_minmax(param) {
  if (typeof param === "number") {
    if (param >= -550577721) {
      if (param >= 60557045) {
        return "max-content";
      } else {
        return "min-content";
      }
    } else if (param >= -789508312) {
      return "0";
    } else {
      return "auto";
    }
  }
  var variant = param[0];
  if (variant >= 22643) {
    if (variant >= 25096) {
      if (variant >= 26433) {
        if (variant >= 5691738) {
          return param[1].toString() + "rem";
        } else {
          return param[1].toString() + "vw";
        }
      } else if (variant >= 26418) {
        return param[1].toString() + "vh";
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 24416) {
      if (variant >= 25092) {
        return param[1].toString() + "pt";
      } else {
        return param[1].toString() + "mm";
      }
    } else if (variant >= 22860) {
      return param[1].toString() + "fr";
    } else {
      return param[1].toString() + "ex";
    }
  }
  if (variant >= -119887163) {
    if (variant >= 22186) {
      if (variant >= 22632) {
        return param[1].toString() + "em";
      } else {
        return param[1].toString() + "cm";
      }
    } else if (variant >= 22181) {
      return param[1].toString() + "ch";
    } else {
      return param[1].toString() + "%";
    }
  }
  if (variant >= -833472530) {
    if (variant >= -833470756) {
      return param[1].toString() + "vmin";
    } else {
      return param[1].toString() + "vmax";
    }
  }
  if (variant >= -1040484748) {
    return param[1].toString() + "px";
  }
  var match = param[1];
  if (match[0] >= 5745024) {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

function string_of_dimension(param) {
  if (typeof param === "number") {
    if (param !== -922086728) {
      if (param >= -550577721) {
        if (param >= 60557045) {
          return "max-content";
        } else {
          return "min-content";
        }
      } else if (param >= -789508312) {
        return "0";
      } else {
        return "auto";
      }
    } else {
      return "none";
    }
  }
  var variant = param[0];
  if (variant >= 22632) {
    if (variant >= 25092) {
      if (variant !== 25096) {
        if (variant >= 26433) {
          if (variant >= 5691738) {
            return param[1].toString() + "rem";
          } else {
            return param[1].toString() + "vw";
          }
        } else if (variant >= 26418) {
          return param[1].toString() + "vh";
        } else {
          return param[1].toString() + "pt";
        }
      } else {
        return param[1].toString() + "px";
      }
    } else if (variant >= 22860) {
      if (variant >= 24416) {
        return param[1].toString() + "mm";
      } else {
        return param[1].toString() + "fr";
      }
    } else if (variant >= 22643) {
      return param[1].toString() + "ex";
    } else {
      return param[1].toString() + "em";
    }
  }
  if (variant >= -754859950) {
    if (variant >= 22181) {
      if (variant >= 22186) {
        return param[1].toString() + "cm";
      } else {
        return param[1].toString() + "ch";
      }
    }
    if (variant >= -119887163) {
      return param[1].toString() + "%";
    }
    var match = param[1];
    return "minmax(" + (string_of_minmax(match[0]) + ("," + (string_of_minmax(match[1]) + ")")));
  }
  if (variant >= -833472530) {
    if (variant >= -833470756) {
      return param[1].toString() + "vmin";
    } else {
      return param[1].toString() + "vmax";
    }
  }
  if (variant >= -1040484748) {
    return param[1].toString() + "px";
  }
  var match$1 = param[1];
  if (match$1[0] >= 5745024) {
    return "calc(" + (string_of_length(match$1[1]) + (" - " + (string_of_length(match$1[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match$1[1]) + (" + " + (string_of_length(match$1[2]) + ")")));
  }
}

function width(x) {
  return d("width", string_of_dimension(x));
}

function height(x) {
  return d("height", string_of_dimension(x));
}

function minWidth(x) {
  return d("minWidth", string_of_dimension(x));
}

function maxWidth(x) {
  return d("maxWidth", string_of_dimension(x));
}

function minHeight(x) {
  return d("minHeight", string_of_dimension(x));
}

function maxHeight(x) {
  return d("maxHeight", string_of_dimension(x));
}

function gridAutoDirectionToJs(param) {
  if (param >= -227605271) {
    if (param >= 5693978) {
      if (param >= 653193961) {
        return "unset";
      } else {
        return "row";
      }
    } else if (param >= -15525083) {
      return "row dense";
    } else {
      return "column dense";
    }
  } else if (param !== -878767996) {
    if (param >= -601204732) {
      return "inherit";
    } else {
      return "column";
    }
  } else {
    return "initial";
  }
}

function gridAutoFlow(direction) {
  return d("gridAutoFlow", gridAutoDirectionToJs(direction));
}

function repeatValueToJs(param) {
  if (typeof param === "number") {
    if (param >= 652328338) {
      return "auto-fill";
    } else {
      return "auto-fit";
    }
  } else {
    return param[1].toString();
  }
}

function gridLengthToJs(param) {
  if (typeof param === "number") {
    if (param >= -550577721) {
      if (param >= 60557045) {
        return "max-content";
      } else {
        return "min-content";
      }
    } else if (param >= -789508312) {
      return "0";
    } else {
      return "auto";
    }
  }
  var variant = param[0];
  if (variant >= 22643) {
    if (variant < 25096) {
      if (variant >= 24416) {
        if (variant >= 25092) {
          return param[1].toString() + "pt";
        } else {
          return param[1].toString() + "mm";
        }
      } else if (variant >= 22860) {
        return param[1].toString() + "fr";
      } else {
        return param[1].toString() + "ex";
      }
    }
    if (variant === 26418) {
      return param[1].toString() + "vh";
    }
    if (variant < 5691738) {
      if (variant >= 26433) {
        return param[1].toString() + "vw";
      } else {
        return param[1].toString() + "px";
      }
    }
    if (variant < 108828507) {
      return param[1].toString() + "rem";
    }
    var match = param[1];
    return "repeat(" + (repeatValueToJs(match[0]) + (", " + (string_of_dimension(match[1]) + ")")));
  }
  if (variant >= -754859950) {
    if (variant === -119887163) {
      return param[1].toString() + "%";
    }
    if (variant >= 22186) {
      if (variant >= 22632) {
        return param[1].toString() + "em";
      } else {
        return param[1].toString() + "cm";
      }
    }
    if (variant >= 22181) {
      return param[1].toString() + "ch";
    }
    var match$1 = param[1];
    return "minmax(" + (string_of_minmax(match$1[0]) + ("," + (string_of_minmax(match$1[1]) + ")")));
  }
  if (variant >= -833472530) {
    if (variant >= -833470756) {
      return param[1].toString() + "vmin";
    } else {
      return param[1].toString() + "vmax";
    }
  }
  if (variant >= -1040484748) {
    return param[1].toString() + "px";
  }
  var match$2 = param[1];
  if (match$2[0] >= 5745024) {
    return "calc(" + (string_of_length(match$2[1]) + (" - " + (string_of_length(match$2[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match$2[1]) + (" + " + (string_of_length(match$2[2]) + ")")));
  }
}

function string_of_dimensions(dimensions) {
  return $$String.concat(" ", List.map(gridLengthToJs, dimensions));
}

function gridTemplateColumns(dimensions) {
  return d("gridTemplateColumns", string_of_dimensions(dimensions));
}

function gridTemplateRows(dimensions) {
  return d("gridTemplateRows", string_of_dimensions(dimensions));
}

function gridAutoColumns(dimensions) {
  return d("gridAutoColumns", string_of_dimension(dimensions));
}

function gridAutoRows(dimensions) {
  return d("gridAutoRows", string_of_dimension(dimensions));
}

function gridColumn(start, end$prime) {
  return d("gridColumn", start.toString() + (" / " + end$prime.toString()));
}

function gridRow(start, end$prime) {
  return d("gridRow", start.toString() + (" / " + end$prime.toString()));
}

function gridColumnStart(n) {
  return d("gridColumnStart", n.toString());
}

function gridColumnEnd(n) {
  return d("gridColumnEnd", n.toString());
}

function gridRowStart(n) {
  return d("gridRowStart", n.toString());
}

function gridRowEnd(n) {
  return d("gridRowEnd", n.toString());
}

function gridColumnGap(n) {
  return d("gridColumnGap", string_of_length(n));
}

function gridRowGap(n) {
  return d("gridRowGap", string_of_length(n));
}

function gridGap(n) {
  return d("gridGap", string_of_length(n));
}

function string_of_align(param) {
  if (param >= 98248149) {
    if (param >= 662439529) {
      if (param >= 924268066) {
        return "flex-end";
      } else {
        return "flex-start";
      }
    } else if (param >= 287825029) {
      return "baseline";
    } else {
      return "center";
    }
  } else if (param >= -162316795) {
    return "stretch";
  } else {
    return "auto";
  }
}

function alignItems(x) {
  return d("alignItems", string_of_align(x));
}

function alignSelf(x) {
  return d("alignSelf", string_of_align(x));
}

function string_of_justify(param) {
  if (param >= 98248149) {
    if (param >= 662439529) {
      if (param >= 924268066) {
        return "flex-end";
      } else {
        return "flex-start";
      }
    } else if (param >= 516682146) {
      return "space-between";
    } else {
      return "center";
    }
  } else if (param !== -485895757) {
    if (param >= -162316795) {
      return "stretch";
    } else {
      return "space-evenly";
    }
  } else {
    return "space-around";
  }
}

function justifyContent(x) {
  return d("justifyContent", string_of_justify(x));
}

function justifySelf(x) {
  return d("justifySelf", string_of_justify(x));
}

function alignContent(x) {
  return d("alignContent", string_of_justify(x));
}

function boxSizing(x) {
  return d("boxSizing", x !== -878767996 ? (
                x >= 9307263 ? (
                    x >= 653193961 ? "unset" : "border-box"
                  ) : (
                    x >= -601204732 ? "inherit" : "content-box"
                  )
              ) : "initial");
}

function $$float(x) {
  return d("float", x !== -922086728 ? (
                x >= -379319332 ? "right" : "left"
              ) : "none");
}

function clear(x) {
  return d("clear", x !== -944764921 ? (
                x >= -379319332 ? "right" : "both"
              ) : "left");
}

function overflow(x) {
  return d("overflow", string_of_overflow(x));
}

function overflowX(x) {
  return d("overflowX", string_of_overflow(x));
}

function overflowY(x) {
  return d("overflowY", string_of_overflow(x));
}

function zIndex(x) {
  return d("zIndex", x.toString());
}

function contentRule(x) {
  return d("content", "\"" + (String(x) + "\""));
}

function columnCount(x) {
  return d("columnCount", typeof x === "number" ? (
                x >= -601204732 ? (
                    x >= 653193961 ? "unset" : "inherit"
                  ) : (
                    x >= -878767996 ? "initial" : "auto"
                  )
              ) : x[1].toString());
}

function direction(x) {
  return d("direction", x !== -601204732 ? (
                x >= 5695082 ? (
                    x >= 653193961 ? "unset" : "rtl"
                  ) : (
                    x >= 5396714 ? "ltr" : "initial"
                  )
              ) : "inherit");
}

function string_of_filter(param) {
  if (typeof param === "number") {
    if (param >= -601204732) {
      if (param >= 653193961) {
        return "unset";
      } else {
        return "inherit";
      }
    } else if (param >= -878767996) {
      return "initial";
    } else {
      return "none";
    }
  }
  var variant = param[0];
  if (variant >= -97740042) {
    if (variant >= 5843823) {
      if (variant >= 934407426) {
        if (variant >= 1036870963) {
          return "hue-rotate(" + (string_of_angle(param[1]) + ")");
        } else {
          return "contrast(" + (param[1].toString() + "%)");
        }
      } else if (variant >= 534308630) {
        return "invert(" + (param[1].toString() + "%)");
      } else {
        return "url(" + (param[1] + ")");
      }
    } else if (variant >= -29929489) {
      return "saturate(" + (param[1].toString() + "%)");
    } else {
      return "sepia(" + (param[1].toString() + "%)");
    }
  }
  if (variant !== -594525841) {
    if (variant >= -345678389) {
      if (variant >= -214464569) {
        return "grayscale(" + (param[1].toString() + "%)");
      } else {
        return "opacity(" + (param[1].toString() + "%)");
      }
    } else if (variant >= -445542959) {
      return "brightness(" + (param[1].toString() + "%)");
    } else {
      return "blur(" + (string_of_length(param[1]) + ")");
    }
  }
  var match = param[1];
  return "drop-shadow(" + (string_of_length(match[0]) + (" " + (string_of_length(match[1]) + (" " + (string_of_length(match[2]) + (" " + (string_of_color(match[3]) + ")")))))));
}

function filter(x) {
  return d("filter", join(" ", List.map(string_of_filter, x)));
}

function backfaceVisibility(x) {
  return d("backfaceVisibility", string_of_visibility(x));
}

function visibility(x) {
  return d("visibility", string_of_visibility(x));
}

function boxShadow(xOpt, yOpt, blurOpt, spreadOpt, insetOpt, color) {
  var x = xOpt !== undefined ? xOpt : /* zero */-789508312;
  var y = yOpt !== undefined ? yOpt : /* zero */-789508312;
  var blur = blurOpt !== undefined ? blurOpt : /* zero */-789508312;
  var spread = spreadOpt !== undefined ? spreadOpt : /* zero */-789508312;
  var inset = insetOpt !== undefined ? insetOpt : false;
  return /* `shadow */[
          488687584,
          string_of_length(x) + (" " + (string_of_length(y) + (" " + (string_of_length(blur) + (" " + (string_of_length(spread) + (" " + (string_of_color(color) + (" " + (
                              inset ? "inset" : ""
                            ))))))))))
        ];
}

function string_of_shadow(param) {
  return param[1];
}

function boxShadows(shadows) {
  return d("boxShadow", join(", ", List.map(string_of_shadow, shadows)));
}

function string_of_borderstyle(param) {
  if (param >= 568403505) {
    if (param >= 841979626) {
      return "dotted";
    } else {
      return "dashed";
    }
  } else if (param >= 12956715) {
    return "solid";
  } else {
    return "none";
  }
}

function border(px, style, color) {
  return d("border", string_of_length(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color)))));
}

function borderWidth(x) {
  return d("borderWidth", string_of_length(x));
}

function borderStyle(x) {
  return d("borderStyle", string_of_borderstyle(x));
}

function borderColor(x) {
  return d("borderColor", string_of_color(x));
}

function borderLeft(px, style, color) {
  return d("borderLeft", string_of_length(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color)))));
}

function borderLeftWidth(x) {
  return d("borderLeftWidth", string_of_length(x));
}

function borderLeftStyle(x) {
  return d("borderLeftStyle", string_of_borderstyle(x));
}

function borderLeftColor(x) {
  return d("borderLeftColor", string_of_color(x));
}

function borderRight(px, style, color) {
  return d("borderRight", string_of_length(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color)))));
}

function borderRightWidth(x) {
  return d("borderRightWidth", string_of_length(x));
}

function borderRightColor(x) {
  return d("borderRightColor", string_of_color(x));
}

function borderRightStyle(x) {
  return d("borderRightStyle", string_of_borderstyle(x));
}

function borderTop(px, style, color) {
  return d("borderTop", string_of_length(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color)))));
}

function borderTopWidth(x) {
  return d("borderTopWidth", string_of_length(x));
}

function borderTopStyle(x) {
  return d("borderTopStyle", string_of_borderstyle(x));
}

function borderTopColor(x) {
  return d("borderTopColor", string_of_color(x));
}

function borderBottom(px, style, color) {
  return d("borderBottom", string_of_length(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color)))));
}

function borderBottomWidth(x) {
  return d("borderBottomWidth", string_of_length(x));
}

function borderBottomStyle(x) {
  return d("borderBottomStyle", string_of_borderstyle(x));
}

function borderBottomColor(x) {
  return d("borderBottomColor", string_of_color(x));
}

function borderRadius(i) {
  return d("borderRadius", string_of_length(i));
}

function borderTopLeftRadius(i) {
  return d("borderTopLeftRadius", string_of_length(i));
}

function borderTopRightRadius(i) {
  return d("borderTopRightRadius", string_of_length(i));
}

function borderBottomLeftRadius(i) {
  return d("borderBottomLeftRadius", string_of_length(i));
}

function borderBottomRightRadius(i) {
  return d("borderBottomRightRadius", string_of_length(i));
}

function tableLayout(x) {
  return d("tableLayout", x >= 10615156 ? "fixed" : "auto");
}

function borderCollapse(x) {
  return d("borderCollapse", x >= 119283555 ? "separate" : "collapse");
}

function borderSpacing(i) {
  return d("borderSpacing", string_of_length(i));
}

function background(x) {
  return d("background", string_of_background(x));
}

function backgrounds(bg) {
  return d("background", join(", ", List.map(string_of_background, bg)));
}

function backgroundColor(x) {
  return d("backgroundColor", string_of_color(x));
}

function backgroundImage(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = "none";
  } else {
    var variant = x[0];
    if (variant !== -160280644) {
      if (variant >= 119548911) {
        if (variant >= 616379637) {
          var match = x[1];
          tmp = string_of_linearGradient(match[0], match[1]);
        } else {
          tmp = "radial-gradient(" + (string_of_stops(x[1]) + ")");
        }
      } else {
        tmp = variant >= 5843823 ? "url(" + (x[1] + ")") : "repeating-radial-gradient(" + (string_of_stops(x[1]) + ")");
      }
    } else {
      var match$1 = x[1];
      tmp = string_of_repeatingLinearGradient(match$1[0], match$1[1]);
    }
  }
  return d("backgroundImage", tmp);
}

function backgroundAttachment(x) {
  return d("backgroundAttachment", x !== -118437525 ? (
                x >= 10615156 ? "fixed" : "scroll"
              ) : "local");
}

function backgroundClip(x) {
  return d("backgroundClip", x !== 9307263 ? (
                x >= 972575930 ? "padding-box" : "content-box"
              ) : "border-box");
}

function backgroundOrigin(x) {
  return d("backgroundOrigin", x !== 9307263 ? (
                x >= 972575930 ? "padding-box" : "content-box"
              ) : "border-box");
}

function backgroundPosition(x, y) {
  return d("backgroundPosition", string_of_length(x) + (" " + string_of_length(y)));
}

function backgroundRepeat(x) {
  return d("backgroundRepeat", x >= 646437021 ? (
                x >= 646437022 ? "repeat-y" : "repeat-x"
              ) : (
                x >= 108828507 ? "repeat" : "no-repeat"
              ));
}

function backgroundSize(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x !== -899416265 ? (
        x >= 427065300 ? "contain" : "auto"
      ) : "cover";
  } else {
    var match = x[1];
    tmp = string_of_length(match[0]) + (" " + string_of_length(match[1]));
  }
  return d("backgroundSize", tmp);
}

function cursor(x) {
  return d("cursor", string_of_cursor(x));
}

function clipPath(x) {
  return d("clipPath", "url(" + (x[1] + ")"));
}

function string_of_listStyleType(param) {
  if (param >= -484197732) {
    if (param >= 700345660) {
      if (param >= 787279419) {
        if (param >= 826920258) {
          return "upper-latin";
        } else {
          return "upper-roman";
        }
      } else if (param >= 739986499) {
        return "lower-latin";
      } else {
        return "lower-roman";
      }
    } else if (param >= -422333295) {
      return "decimal";
    } else {
      return "upper-alpha";
    }
  } else if (param >= -703761904) {
    if (param >= -655228771) {
      if (param >= -571131491) {
        return "lower-alpha";
      } else {
        return "square";
      }
    } else if (param >= -699686657) {
      return "lower-greek";
    } else {
      return "circle";
    }
  } else if (param >= -922086728) {
    return "none";
  } else {
    return "disc";
  }
}

function string_of_listStylePosition(param) {
  if (param >= 501235708) {
    return "inside";
  } else {
    return "outside";
  }
}

function string_of_listStyleImage(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    return "url(" + (param[1] + ")");
  }
}

function listStyle(style, pos, img) {
  return d("listStyle", string_of_listStyleType(style) + (" " + (string_of_listStylePosition(pos) + (" " + string_of_listStyleImage(img)))));
}

function listStyleType(x) {
  return d("listStyleType", string_of_listStyleType(x));
}

function listStylePosition(x) {
  return d("listStylePosition", string_of_listStylePosition(x));
}

function listStyleImage(x) {
  return d("listStyleImage", string_of_listStyleImage(x));
}

function opacity(x) {
  return d("opacity", x.toString());
}

function string_of_outlineStyle(param) {
  if (param >= 472095738) {
    if (param !== 568403505) {
      if (param >= 852175633) {
        if (param >= 1042283741) {
          return "inset";
        } else {
          return "double";
        }
      } else if (param >= 841979626) {
        return "dotted";
      } else {
        return "grove";
      }
    } else {
      return "dashed";
    }
  } else if (param !== -862584982) {
    if (param >= 12956715) {
      if (param >= 209930196) {
        return "outset";
      } else {
        return "solid";
      }
    } else if (param >= -379468757) {
      return "ridge";
    } else {
      return "none";
    }
  } else {
    return "hidden";
  }
}

function outline(size, style, color) {
  return d("outline", string_of_length(size) + (" " + (string_of_outlineStyle(style) + (" " + string_of_color(color)))));
}

function outlineStyle(x) {
  return d("outlineStyle", string_of_outlineStyle(x));
}

function outlineWidth(x) {
  return d("outlineWidth", string_of_length(x));
}

function outlineColor(x) {
  return d("outlineColor", string_of_color(x));
}

function outlineOffset(x) {
  return d("outlineOffset", string_of_length(x));
}

function color(x) {
  return d("color", string_of_color(x));
}

function fontFamily(x) {
  return d("fontFamily", x);
}

function fontSize(x) {
  return d("fontSize", string_of_length_cascading(x));
}

function fontVariant(x) {
  return d("fontVariant", x !== -601204732 ? (
                x >= 653193961 ? (
                    x >= 812216871 ? "normal" : "unset"
                  ) : (
                    x >= -386684792 ? "small-caps" : "initial"
                  )
              ) : "inherit");
}

function fontStyle(x) {
  return d("fontStyle", string_of_fontStyle(x));
}

function fontWeight(x) {
  return d("fontWeight", string_of_fontWeight(x));
}

function fontFace(fontFamily, src, fontStyle, fontWeight, param) {
  var fontStyle$1 = Js_option.map(string_of_fontStyle, fontStyle);
  var src$1 = $$String.concat(", ", List.map((function (param) {
              if (param[0] >= 5843823) {
                return "url(\"" + (String(param[1]) + "\")");
              } else {
                return "local(\"" + (String(param[1]) + "\")");
              }
            }), src));
  var fontStyle$2 = Belt_Option.mapWithDefault(fontStyle$1, "", (function (s) {
          return "font-style: " + s;
        }));
  var fontWeight$1 = Belt_Option.mapWithDefault(fontWeight, "", (function (w) {
          return "font-weight: " + string_of_fontWeight(w);
        }));
  var asString = "@font-face {\n    font-family: " + (String(fontFamily) + (";\n    src: " + (String(src$1) + (";\n    " + (String(fontStyle$2) + (";\n    " + (String(fontWeight$1) + ";\n}")))))));
  Emotion.injectGlobal(asString);
  return fontFamily;
}

function lineHeight(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= -789508312 ? (
        x >= 653193961 ? (
            x >= 812216871 ? "normal" : "unset"
          ) : (
            x >= -601204732 ? "inherit" : "0"
          )
      ) : (
        x >= -878767996 ? "initial" : "auto"
      );
  } else {
    var variant = x[0];
    if (variant >= 22643) {
      tmp = variant >= 26418 ? (
          variant >= 4845682 ? (
              variant >= 5691738 ? x[1].toString() + "rem" : x[1].toString()
            ) : (
              variant >= 26433 ? x[1].toString() + "vw" : x[1].toString() + "vh"
            )
        ) : (
          variant >= 25092 ? (
              variant >= 25096 ? x[1].toString() + "px" : x[1].toString() + "pt"
            ) : (
              variant >= 24416 ? x[1].toString() + "mm" : x[1].toString() + "ex"
            )
        );
    } else if (variant >= -119887163) {
      tmp = variant >= 22186 ? (
          variant >= 22632 ? x[1].toString() + "em" : x[1].toString() + "cm"
        ) : (
          variant >= 22181 ? x[1].toString() + "ch" : x[1].toString() + "%"
        );
    } else if (variant >= -833472530) {
      tmp = variant >= -833470756 ? x[1].toString() + "vmin" : x[1].toString() + "vmax";
    } else if (variant >= -1040484748) {
      tmp = x[1].toString() + "px";
    } else {
      var match = x[1];
      tmp = match[0] >= 5745024 ? "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")"))) : "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
    }
  }
  return d("lineHeight", tmp);
}

function letterSpacing(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= -789508312 ? (
        x >= 653193961 ? (
            x >= 812216871 ? "normal" : "unset"
          ) : (
            x >= -601204732 ? "inherit" : "0"
          )
      ) : (
        x >= -878767996 ? "initial" : "auto"
      );
  } else {
    var variant = x[0];
    if (variant >= 22632) {
      tmp = variant >= 25096 ? (
          variant >= 26433 ? (
              variant >= 5691738 ? x[1].toString() + "rem" : x[1].toString() + "vw"
            ) : (
              variant >= 26418 ? x[1].toString() + "vh" : x[1].toString() + "px"
            )
        ) : (
          variant >= 24416 ? (
              variant >= 25092 ? x[1].toString() + "pt" : x[1].toString() + "mm"
            ) : (
              variant >= 22643 ? x[1].toString() + "ex" : x[1].toString() + "em"
            )
        );
    } else if (variant >= -833470756) {
      tmp = variant >= 22181 ? (
          variant >= 22186 ? x[1].toString() + "cm" : x[1].toString() + "ch"
        ) : (
          variant >= -119887163 ? x[1].toString() + "%" : x[1].toString() + "vmin"
        );
    } else if (variant !== -1040484748) {
      if (variant >= -833472530) {
        tmp = x[1].toString() + "vmax";
      } else {
        var match = x[1];
        tmp = match[0] >= 5745024 ? "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")"))) : "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
      }
    } else {
      tmp = x[1].toString() + "px";
    }
  }
  return d("letterSpacing", tmp);
}

function textAlign(x) {
  return d("textAlign", x >= -379319332 ? (
                x >= 98248149 ? (
                    x >= 653193961 ? "unset" : "center"
                  ) : (
                    x >= 62250832 ? "justify" : "right"
                  )
              ) : (
                x !== -878767996 ? (
                    x >= -601204732 ? "inherit" : "left"
                  ) : "initial"
              ));
}

function textDecoration(x) {
  return d("textDecoration", x >= -601204732 ? (
                x >= 412155569 ? (
                    x >= 653193961 ? "unset" : "line-through"
                  ) : (
                    x >= 131142924 ? "underline" : "inherit"
                  )
              ) : (
                x !== -922086728 ? (
                    x >= -878767996 ? "initial" : "overline"
                  ) : "none"
              ));
}

function textDecorationColor(x) {
  return d("textDecorationColor", string_of_color(x));
}

function textDecorationStyle(x) {
  return d("textDecorationStyle", x >= 568403505 ? (
                x >= 841979626 ? (
                    x >= 852175633 ? "double" : "dotted"
                  ) : (
                    x >= 653193961 ? "unset" : "dashed"
                  )
              ) : (
                x >= -601204732 ? (
                    x >= 12956715 ? "solid" : "inherit"
                  ) : (
                    x >= -822975027 ? "wavy" : "initial"
                  )
              ));
}

function textIndent(x) {
  return d("textIndent", string_of_length(x));
}

function textOverflow(x) {
  return d("textOverflow", typeof x === "number" ? (
                x !== -878767996 ? (
                    x >= 166888785 ? (
                        x >= 653193961 ? "unset" : "ellipsis"
                      ) : (
                        x >= -601204732 ? "inherit" : "clip"
                      )
                  ) : "initial"
              ) : x[1]);
}

function textShadow(xOpt, yOpt, blurOpt, color) {
  var x = xOpt !== undefined ? xOpt : /* zero */-789508312;
  var y = yOpt !== undefined ? yOpt : /* zero */-789508312;
  var blur = blurOpt !== undefined ? blurOpt : /* zero */-789508312;
  return /* `textShadow */[
          -737064851,
          string_of_length(x) + (" " + (string_of_length(y) + (" " + (string_of_length(blur) + (" " + string_of_color(color))))))
        ];
}

function string_of_textShadow(param) {
  return param[1];
}

function textShadows(textShadows$1) {
  return d("textShadow", join(", ", List.map(string_of_textShadow, textShadows$1)));
}

function textTransform(x) {
  return d("textTransform", x >= -509867604 ? (
                x >= -415330030 ? (
                    x >= 653193961 ? "unset" : "uppercase"
                  ) : (
                    x >= -425349839 ? "lowercase" : "capitalize"
                  )
              ) : (
                x !== -878767996 ? (
                    x >= -601204732 ? "inherit" : "none"
                  ) : "initial"
              ));
}

function userSelect(x) {
  return d("userSelect", x >= -856044371 ? (
                x >= 4847905 ? (
                    x >= 653193961 ? "unset" : "all"
                  ) : (
                    x >= -601204732 ? "inherit" : "text"
                  )
              ) : (
                x !== -922086728 ? (
                    x >= -878767996 ? "initial" : "auto"
                  ) : "none"
              ));
}

function verticalAlign(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= 5745024 ? (
        x >= 287825029 ? (
            x >= 516406248 ? (
                x >= 653193961 ? "unset" : "text-top"
              ) : (
                x >= 476669816 ? "text-bottom" : "baseline"
              )
          ) : (
            x !== 5793429 ? (
                x >= 79692155 ? "super" : "sub"
              ) : "top"
          )
      ) : (
        x >= -866200747 ? (
            x >= -601204732 ? (
                x >= -445061397 ? "bottom" : "inherit"
              ) : (
                x >= -789508312 ? "0" : "middle"
              )
          ) : (
            x >= -878767996 ? "initial" : "auto"
          )
      );
  } else {
    var variant = x[0];
    if (variant >= 22632) {
      tmp = variant >= 25096 ? (
          variant >= 26433 ? (
              variant >= 5691738 ? x[1].toString() + "rem" : x[1].toString() + "vw"
            ) : (
              variant >= 26418 ? x[1].toString() + "vh" : x[1].toString() + "px"
            )
        ) : (
          variant >= 24416 ? (
              variant >= 25092 ? x[1].toString() + "pt" : x[1].toString() + "mm"
            ) : (
              variant >= 22643 ? x[1].toString() + "ex" : x[1].toString() + "em"
            )
        );
    } else if (variant >= -833470756) {
      tmp = variant >= 22181 ? (
          variant >= 22186 ? x[1].toString() + "cm" : x[1].toString() + "ch"
        ) : (
          variant >= -119887163 ? x[1].toString() + "%" : x[1].toString() + "vmin"
        );
    } else if (variant !== -1040484748) {
      if (variant >= -833472530) {
        tmp = x[1].toString() + "vmax";
      } else {
        var match = x[1];
        tmp = match[0] >= 5745024 ? "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")"))) : "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
      }
    } else {
      tmp = x[1].toString() + "px";
    }
  }
  return d("verticalAlign", tmp);
}

function whiteSpace(x) {
  return d("whiteSpace", x >= 653193961 ? (
                x >= 812216871 ? (
                    x >= 867913355 ? "nowrap" : "normal"
                  ) : (
                    x >= 660870029 ? "pre-wrap" : "unset"
                  )
              ) : (
                x >= 5595171 ? (
                    x >= 538440119 ? "pre-line" : "pre"
                  ) : (
                    x >= -601204732 ? "inherit" : "initial"
                  )
              ));
}

function wordBreak(x) {
  return d("wordBreak", x >= -323760734 ? (
                x >= 812216871 ? (
                    x >= 892748188 ? "keep-all" : "normal"
                  ) : (
                    x >= 653193961 ? "unset" : "break-all"
                  )
              ) : (
                x >= -601204732 ? "inherit" : "initial"
              ));
}

function wordSpacing(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= -789508312 ? (
        x >= 653193961 ? (
            x >= 812216871 ? "normal" : "unset"
          ) : (
            x >= -601204732 ? "inherit" : "0"
          )
      ) : (
        x >= -878767996 ? "initial" : "auto"
      );
  } else {
    var variant = x[0];
    if (variant >= 22632) {
      tmp = variant >= 25096 ? (
          variant >= 26433 ? (
              variant >= 5691738 ? x[1].toString() + "rem" : x[1].toString() + "vw"
            ) : (
              variant >= 26418 ? x[1].toString() + "vh" : x[1].toString() + "px"
            )
        ) : (
          variant >= 24416 ? (
              variant >= 25092 ? x[1].toString() + "pt" : x[1].toString() + "mm"
            ) : (
              variant >= 22643 ? x[1].toString() + "ex" : x[1].toString() + "em"
            )
        );
    } else if (variant >= -833470756) {
      tmp = variant >= 22181 ? (
          variant >= 22186 ? x[1].toString() + "cm" : x[1].toString() + "ch"
        ) : (
          variant >= -119887163 ? x[1].toString() + "%" : x[1].toString() + "vmin"
        );
    } else if (variant !== -1040484748) {
      if (variant >= -833472530) {
        tmp = x[1].toString() + "vmax";
      } else {
        var match = x[1];
        tmp = match[0] >= 5745024 ? "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")"))) : "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
      }
    } else {
      tmp = x[1].toString() + "px";
    }
  }
  return d("wordSpacing", tmp);
}

function wordWrap(x) {
  return d("wordWrap", x !== -601204732 ? (
                x >= 812216871 ? (
                    x >= 1059921449 ? "break-word" : "normal"
                  ) : (
                    x >= 653193961 ? "unset" : "initial"
                  )
              ) : "inherit");
}

function string_of_pointerEvents(param) {
  if (param >= -922086728) {
    return "none";
  } else {
    return "auto";
  }
}

function pointerEvents(x) {
  return d("pointerEvents", string_of_pointerEvents(x));
}

function string_of_transform(param) {
  var variant = param[0];
  if (variant >= -120664438) {
    if (variant >= 106728778) {
      if (variant >= 178189215) {
        if (variant >= 1009117838) {
          switch (variant - 1009117838 | 0) {
            case 0 :
                return "scaleX(" + (param[1].toString() + ")");
            case 1 :
                return "scaleY(" + (param[1].toString() + ")");
            case 2 :
                return "scaleZ(" + (param[1].toString() + ")");
            
          }
        } else {
          var match = param[1];
          var x = match[0];
          var y = match[1];
          var z = match[2];
          return "translate3d(" + (string_of_length(x) + (", " + (string_of_length(y) + (", " + (string_of_length(z) + ")")))));
        }
      } else {
        if (variant >= 142802924) {
          var match$1 = param[1];
          return "rotate3d(" + (match$1[0].toString() + (", " + (match$1[1].toString() + (", " + (match$1[2].toString() + (", " + (string_of_angle(match$1[3]) + ")")))))));
        }
        switch (variant - 106728778 | 0) {
          case 0 :
              return "translateX(" + (string_of_length(param[1]) + ")");
          case 1 :
              return "translateY(" + (string_of_length(param[1]) + ")");
          case 2 :
              return "translateZ(" + (string_of_length(param[1]) + ")");
          
        }
      }
    } else {
      if (variant === -31746546) {
        return "skewX(" + (string_of_angle(param[1]) + ")");
      }
      if (variant >= -31746545) {
        return "skewY(" + (string_of_angle(param[1]) + ")");
      }
      var match$2 = param[1];
      var x$1 = match$2[0];
      var y$1 = match$2[1];
      return "scale(" + (x$1.toString() + (", " + (y$1.toString() + ")")));
    }
  } else if (variant >= -887077285) {
    if (variant >= -452513317) {
      if (variant >= -249738851) {
        switch (variant + 249738851 | 0) {
          case 0 :
              return "rotateX(" + (string_of_angle(param[1]) + ")");
          case 1 :
              return "rotateY(" + (string_of_angle(param[1]) + ")");
          case 2 :
              return "rotateZ(" + (string_of_angle(param[1]) + ")");
          
        }
      } else {
        var match$3 = param[1];
        return "scale3d(" + (match$3[0].toString() + (", " + (match$3[1].toString() + (", " + (match$3[2].toString() + ")")))));
      }
    } else {
      if (variant < -866839798) {
        return "rotate(" + (string_of_angle(param[1]) + ")");
      }
      var match$4 = param[1];
      return "skew(" + (string_of_angle(match$4[0]) + (", " + (string_of_angle(match$4[1]) + ")")));
    }
  } else {
    if (variant < -914368690) {
      return "perspective(" + (param[1].toString() + ")");
    }
    var match$5 = param[1];
    return "translate(" + (string_of_length(match$5[0]) + (", " + (string_of_length(match$5[1]) + ")")));
  }
}

function transform(x) {
  return d("transform", string_of_transform(x));
}

function transforms(xs) {
  return d("transform", join(" ", List.map(string_of_transform, xs)));
}

function transformOrigin(x, y) {
  return d("transformOrigin", string_of_length(x) + (" " + string_of_length(y)));
}

function transformOrigin3d(x, y, z) {
  return d("transformOrigin", string_of_length(x) + (" " + (string_of_length(y) + (" " + (string_of_length(z) + " ")))));
}

function transformStyle(x) {
  return d("transformStyle", x >= 589702045 ? "preserve-3d" : "flat");
}

function perspective(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= -789508312 ? "0" : "none";
  } else {
    var variant = x[0];
    if (variant >= 22632) {
      tmp = variant >= 25096 ? (
          variant >= 26433 ? (
              variant >= 5691738 ? x[1].toString() + "rem" : x[1].toString() + "vw"
            ) : (
              variant >= 26418 ? x[1].toString() + "vh" : x[1].toString() + "px"
            )
        ) : (
          variant >= 24416 ? (
              variant >= 25092 ? x[1].toString() + "pt" : x[1].toString() + "mm"
            ) : (
              variant >= 22643 ? x[1].toString() + "ex" : x[1].toString() + "em"
            )
        );
    } else if (variant >= -833470756) {
      tmp = variant >= 22181 ? (
          variant >= 22186 ? x[1].toString() + "cm" : x[1].toString() + "ch"
        ) : (
          variant >= -119887163 ? x[1].toString() + "%" : x[1].toString() + "vmin"
        );
    } else if (variant !== -1040484748) {
      if (variant >= -833472530) {
        tmp = x[1].toString() + "vmax";
      } else {
        var match = x[1];
        tmp = match[0] >= 5745024 ? "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")"))) : "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
      }
    } else {
      tmp = x[1].toString() + "px";
    }
  }
  return d("perspective", tmp);
}

function string_of_timingFunction(param) {
  if (typeof param === "number") {
    if (param >= -193525386) {
      if (param >= 138027891) {
        return "ease-out";
      } else if (param >= 20933615) {
        return "step-end";
      } else {
        return "step-start";
      }
    } else if (param !== -960651557) {
      if (param >= -325037595) {
        return "linear";
      } else {
        return "ease";
      }
    } else {
      return "ease-in-out";
    }
  }
  if (param[0] >= 68058023) {
    var match = param[1];
    var i = match[0];
    if (match[1] >= 67859554) {
      return "steps(" + (i.toString() + ", start)");
    } else {
      return "steps(" + (i.toString() + ", end)");
    }
  }
  var match$1 = param[1];
  return "cubic-bezier(" + (match$1[0].toString() + (", " + (match$1[1].toString() + (", " + (match$1[2].toString() + (", " + (match$1[3].toString() + ")")))))));
}

function transition(durationOpt, delayOpt, timingFunctionOpt, property) {
  var duration = durationOpt !== undefined ? durationOpt : 0;
  var delay = delayOpt !== undefined ? delayOpt : 0;
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : /* ease */-1022587922;
  return /* `transition */[
          -659583595,
          duration.toString() + "ms " + (string_of_timingFunction(timingFunction) + (" " + (delay.toString() + "ms " + property)))
        ];
}

function transitions(xs) {
  return d("transition", join(", ", List.map((function (param) {
                        return param[1];
                      }), xs)));
}

function transitionDelay(i) {
  return d("transitionDelay", i.toString() + "ms");
}

function transitionDuration(i) {
  return d("transitionDuration", i.toString() + "ms");
}

function transitionTimingFunction(x) {
  return d("transitionTimingFunction", string_of_timingFunction(x));
}

function transitionProperty(x) {
  return d("transitionProperty", x);
}

function perspectiveOrigin(x, y) {
  return d("perspectiveOrigin", string_of_length(x) + (" " + string_of_length(y)));
}

function string_of_animationDirection(param) {
  if (param >= 812216871) {
    if (param >= 892586298) {
      return "alternate";
    } else {
      return "normal";
    }
  } else if (param >= 545428232) {
    return "alternate-reverse";
  } else {
    return "reverse";
  }
}

function string_of_animationFillMode(param) {
  if (param >= 245861168) {
    if (param >= 733653774) {
      return "forwards";
    } else {
      return "backwards";
    }
  } else if (param >= -922086728) {
    return "none";
  } else {
    return "both";
  }
}

function string_of_animationIterationCount(param) {
  if (typeof param === "number") {
    return "infinite";
  } else {
    return param[1].toString();
  }
}

function string_of_animationPlayState(param) {
  if (param >= -276545362) {
    return "paused";
  } else {
    return "running";
  }
}

function animation(durationOpt, delayOpt, directionOpt, timingFunctionOpt, fillModeOpt, playStateOpt, iterationCountOpt, name) {
  var duration = durationOpt !== undefined ? durationOpt : 0;
  var delay = delayOpt !== undefined ? delayOpt : 0;
  var direction = directionOpt !== undefined ? directionOpt : /* normal */812216871;
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : /* ease */-1022587922;
  var fillMode = fillModeOpt !== undefined ? fillModeOpt : /* none */-922086728;
  var playState = playStateOpt !== undefined ? playStateOpt : /* running */-345412097;
  var iterationCount = iterationCountOpt !== undefined ? iterationCountOpt : /* `count */[
      -899463985,
      1
    ];
  return /* `animation */[
          -885723388,
          name + (" " + (duration.toString() + "ms " + (string_of_timingFunction(timingFunction) + (" " + (delay.toString() + "ms " + (string_of_animationIterationCount(iterationCount) + (" " + (string_of_animationDirection(direction) + (" " + (string_of_animationFillMode(fillMode) + (" " + string_of_animationPlayState(playState))))))))))))
        ];
}

function string_of_animation(param) {
  return param[1];
}

function animations(xs) {
  return d("animation", join(", ", List.map(string_of_animation, xs)));
}

function animationDelay(x) {
  return d("animationDelay", x.toString() + "ms");
}

function animationDirection(x) {
  return d("animationDirection", string_of_animationDirection(x));
}

function animationDuration(x) {
  return d("animationDuration", x.toString() + "ms");
}

function animationFillMode(x) {
  return d("animationFillMode", string_of_animationFillMode(x));
}

function animationIterationCount(x) {
  return d("animationIterationCount", string_of_animationIterationCount(x));
}

function animationName(x) {
  return d("animationName", x);
}

function animationPlayState(x) {
  return d("animationPlayState", string_of_animationPlayState(x));
}

function animationTimingFunction(x) {
  return d("animationTimingFunction", string_of_timingFunction(x));
}

function selector(selector$1, rules) {
  return /* `selector */[
          829240095,
          /* tuple */[
            selector$1,
            rules
          ]
        ];
}

function active(param) {
  return selector(":active", param);
}

function after(param) {
  return selector("::after", param);
}

function before(param) {
  return selector("::before", param);
}

function checked(param) {
  return selector(":checked", param);
}

function children(param) {
  return selector(" > *", param);
}

function directSibling(param) {
  return selector(" + ", param);
}

function disabled(param) {
  return selector(":disabled", param);
}

function firstChild(param) {
  return selector(":first-child", param);
}

function firstOfType(param) {
  return selector(":first-of-type", param);
}

function focus(param) {
  return selector(":focus", param);
}

function hover(param) {
  return selector(":hover", param);
}

function lastChild(param) {
  return selector(":last-child", param);
}

function lastOfType(param) {
  return selector(":last-of-type", param);
}

function link(param) {
  return selector(":link", param);
}

function readOnly(param) {
  return selector(":read-only", param);
}

function required(param) {
  return selector(":required", param);
}

function visited(param) {
  return selector(":visited", param);
}

function enabled(param) {
  return selector(":enabled", param);
}

function noContent(param) {
  return selector(":empty", param);
}

function $$default(param) {
  return selector(":default", param);
}

function anyLink(param) {
  return selector(":any-link", param);
}

function onlyChild(param) {
  return selector(":only-child", param);
}

function onlyOfType(param) {
  return selector(":only-of-type", param);
}

function optional(param) {
  return selector(":optional", param);
}

function invalid(param) {
  return selector(":invalid", param);
}

function outOfRange(param) {
  return selector(":out-of-range", param);
}

function siblings(param) {
  return selector(" ~ ", param);
}

function target(param) {
  return selector(":target", param);
}

function firstLine(param) {
  return selector("::first-line", param);
}

function firstLetter(param) {
  return selector("::first-letter", param);
}

function selection(param) {
  return selector("::selection", param);
}

function placeholder(param) {
  return selector("::placeholder", param);
}

function media(query, rules) {
  return /* `selector */[
          829240095,
          /* tuple */[
            "@media " + query,
            rules
          ]
        ];
}

function fill(color) {
  return d("fill", string_of_color(color));
}

function fillOpacity(opacity) {
  return d("fillOpacity", opacity.toString());
}

function fillRule(x) {
  return d("fillRule", x >= 110563029 ? "evenodd" : "nonzero");
}

function stroke(color) {
  return d("stroke", string_of_color(color));
}

function strokeWidth(length) {
  return d("strokeWidth", string_of_length(length));
}

function strokeOpacity(opacity) {
  return d("strokeOpacity", opacity.toString());
}

function strokeMiterlimit(x) {
  return d("strokeMiterlimit", x.toString());
}

function strokeLinecap(x) {
  return d("strokeLinecap", x !== -655228771 ? (
                x >= -312084402 ? "round" : "butt"
              ) : "square");
}

function strokeLinejoin(x) {
  return d("strokeLinejoin", x !== 141361157 ? (
                x >= 811681914 ? "bevel" : "round"
              ) : "miter");
}

function stopColor(c) {
  return d("stopColor", string_of_color(c));
}

function stopOpacity(o) {
  return d("stopOpacity", o.toString());
}

var empty = /* [] */0;

var merge = mergeStyles;

var style = make;

var toJson = makeJson;

var initial = /* initial */-878767996;

var inherit_ = /* inherit_ */-601204732;

var unset = /* unset */653193961;

var rtl = /* rtl */5695082;

var ltr = /* ltr */5396714;

var transparent = Css_Colors.transparent;

var currentColor = /* currentColor */292050538;

var aliceblue = Css_Colors.aliceblue;

var antiquewhite = Css_Colors.antiquewhite;

var aqua = Css_Colors.aqua;

var aquamarine = Css_Colors.aquamarine;

var azure = Css_Colors.azure;

var beige = Css_Colors.beige;

var bisque = Css_Colors.bisque;

var black = Css_Colors.black;

var blanchedalmond = Css_Colors.blanchedalmond;

var blue = Css_Colors.blue;

var blueviolet = Css_Colors.blueviolet;

var brown = Css_Colors.brown;

var burlywood = Css_Colors.burlywood;

var cadetblue = Css_Colors.cadetblue;

var chartreuse = Css_Colors.chartreuse;

var chocolate = Css_Colors.chocolate;

var coral = Css_Colors.coral;

var cornflowerblue = Css_Colors.cornflowerblue;

var cornsilk = Css_Colors.cornsilk;

var crimson = Css_Colors.crimson;

var cyan = Css_Colors.cyan;

var darkblue = Css_Colors.darkblue;

var darkcyan = Css_Colors.darkcyan;

var darkgoldenrod = Css_Colors.darkgoldenrod;

var darkgray = Css_Colors.darkgray;

var darkgrey = Css_Colors.darkgrey;

var darkgreen = Css_Colors.darkgreen;

var darkkhaki = Css_Colors.darkkhaki;

var darkmagenta = Css_Colors.darkmagenta;

var darkolivegreen = Css_Colors.darkolivegreen;

var darkorange = Css_Colors.darkorange;

var darkorchid = Css_Colors.darkorchid;

var darkred = Css_Colors.darkred;

var darksalmon = Css_Colors.darksalmon;

var darkseagreen = Css_Colors.darkseagreen;

var darkslateblue = Css_Colors.darkslateblue;

var darkslategray = Css_Colors.darkslategray;

var darkslategrey = Css_Colors.darkslategrey;

var darkturquoise = Css_Colors.darkturquoise;

var darkviolet = Css_Colors.darkviolet;

var deeppink = Css_Colors.deeppink;

var deepskyblue = Css_Colors.deepskyblue;

var dimgray = Css_Colors.dimgray;

var dimgrey = Css_Colors.dimgrey;

var dodgerblue = Css_Colors.dodgerblue;

var firebrick = Css_Colors.firebrick;

var floralwhite = Css_Colors.floralwhite;

var forestgreen = Css_Colors.forestgreen;

var fuchsia = Css_Colors.fuchsia;

var gainsboro = Css_Colors.gainsboro;

var ghostwhite = Css_Colors.ghostwhite;

var gold = Css_Colors.gold;

var goldenrod = Css_Colors.goldenrod;

var gray = Css_Colors.gray;

var grey = Css_Colors.grey;

var green = Css_Colors.green;

var greenyellow = Css_Colors.greenyellow;

var honeydew = Css_Colors.honeydew;

var hotpink = Css_Colors.hotpink;

var indianred = Css_Colors.indianred;

var indigo = Css_Colors.indigo;

var ivory = Css_Colors.ivory;

var khaki = Css_Colors.khaki;

var lavender = Css_Colors.lavender;

var lavenderblush = Css_Colors.lavenderblush;

var lawngreen = Css_Colors.lawngreen;

var lemonchiffon = Css_Colors.lemonchiffon;

var lightblue = Css_Colors.lightblue;

var lightcoral = Css_Colors.lightcoral;

var lightcyan = Css_Colors.lightcyan;

var lightgoldenrodyellow = Css_Colors.lightgoldenrodyellow;

var lightgray = Css_Colors.lightgray;

var lightgrey = Css_Colors.lightgrey;

var lightgreen = Css_Colors.lightgreen;

var lightpink = Css_Colors.lightpink;

var lightsalmon = Css_Colors.lightsalmon;

var lightseagreen = Css_Colors.lightseagreen;

var lightskyblue = Css_Colors.lightskyblue;

var lightslategray = Css_Colors.lightslategray;

var lightslategrey = Css_Colors.lightslategrey;

var lightsteelblue = Css_Colors.lightsteelblue;

var lightyellow = Css_Colors.lightyellow;

var lime = Css_Colors.lime;

var limegreen = Css_Colors.limegreen;

var linen = Css_Colors.linen;

var magenta = Css_Colors.magenta;

var maroon = Css_Colors.maroon;

var mediumaquamarine = Css_Colors.mediumaquamarine;

var mediumblue = Css_Colors.mediumblue;

var mediumorchid = Css_Colors.mediumorchid;

var mediumpurple = Css_Colors.mediumpurple;

var mediumseagreen = Css_Colors.mediumseagreen;

var mediumslateblue = Css_Colors.mediumslateblue;

var mediumspringgreen = Css_Colors.mediumspringgreen;

var mediumturquoise = Css_Colors.mediumturquoise;

var mediumvioletred = Css_Colors.mediumvioletred;

var midnightblue = Css_Colors.midnightblue;

var mintcream = Css_Colors.mintcream;

var mistyrose = Css_Colors.mistyrose;

var moccasin = Css_Colors.moccasin;

var navajowhite = Css_Colors.navajowhite;

var navy = Css_Colors.navy;

var oldlace = Css_Colors.oldlace;

var olive = Css_Colors.olive;

var olivedrab = Css_Colors.olivedrab;

var orange = Css_Colors.orange;

var orangered = Css_Colors.orangered;

var orchid = Css_Colors.orchid;

var palegoldenrod = Css_Colors.palegoldenrod;

var palegreen = Css_Colors.palegreen;

var paleturquoise = Css_Colors.paleturquoise;

var palevioletred = Css_Colors.palevioletred;

var papayawhip = Css_Colors.papayawhip;

var peachpuff = Css_Colors.peachpuff;

var peru = Css_Colors.peru;

var pink = Css_Colors.pink;

var plum = Css_Colors.plum;

var powderblue = Css_Colors.powderblue;

var purple = Css_Colors.purple;

var rebeccapurple = Css_Colors.rebeccapurple;

var red = Css_Colors.red;

var rosybrown = Css_Colors.rosybrown;

var royalblue = Css_Colors.royalblue;

var saddlebrown = Css_Colors.saddlebrown;

var salmon = Css_Colors.salmon;

var sandybrown = Css_Colors.sandybrown;

var seagreen = Css_Colors.seagreen;

var seashell = Css_Colors.seashell;

var sienna = Css_Colors.sienna;

var silver = Css_Colors.silver;

var skyblue = Css_Colors.skyblue;

var slateblue = Css_Colors.slateblue;

var slategray = Css_Colors.slategray;

var slategrey = Css_Colors.slategrey;

var snow = Css_Colors.snow;

var springgreen = Css_Colors.springgreen;

var steelblue = Css_Colors.steelblue;

var tan = Css_Colors.tan;

var teal = Css_Colors.teal;

var thistle = Css_Colors.thistle;

var tomato = Css_Colors.tomato;

var turquoise = Css_Colors.turquoise;

var violet = Css_Colors.violet;

var wheat = Css_Colors.wheat;

var white = Css_Colors.white;

var whitesmoke = Css_Colors.whitesmoke;

var yellow = Css_Colors.yellow;

var yellowgreen = Css_Colors.yellowgreen;

var zero = /* zero */-789508312;

var horizontal = /* horizontal */365165796;

var vertical = /* vertical */-367674250;

var solid = /* solid */12956715;

var dotted = /* dotted */841979626;

var dashed = /* dashed */568403505;

var none = /* none */-922086728;

var auto = /* auto */-1065951377;

var hidden = /* hidden */-862584982;

var visible = /* visible */589592690;

var local = /* local */-118437525;

var scroll = /* scroll */-949692403;

var paddingBox = /* paddingBox */972575930;

var borderBox = /* borderBox */9307263;

var contentBox = /* contentBox */-1008848302;

var noRepeat = /* noRepeat */-695430532;

var repeat = /* repeat */108828507;

var minmax = /* minmax */-754859950;

var repeatX = /* repeatX */646437021;

var repeatY = /* repeatY */646437022;

var contain = /* contain */427065300;

var cover = /* cover */-899416265;

var row = /* row */5693978;

var rowReverse = /* rowReverse */378951208;

var column = /* column */-963948842;

var columnReverse = /* columnReverse */135477484;

var wrap = /* wrap */-822134326;

var nowrap = /* nowrap */867913355;

var wrapReverse = /* wrapReverse */-463121288;

var inline = /* inline */423610969;

var block = /* block */888960333;

var contents = /* contents */790889754;

var flexBox = /* flex */-1010954439;

var grid = /* grid */-999565626;

var inlineBlock = /* inlineBlock */-147785676;

var inlineFlex = /* inlineFlex */53323314;

var inlineGrid = /* inlineGrid */64712127;

var inlineTable = /* inlineTable */-854050059;

var listItem = /* listItem */632591505;

var runIn = /* runIn */-245903344;

var table = /* table */182695950;

var tableCaption = /* tableCaption */1054826616;

var tableColumnGroup = /* tableColumnGroup */850209563;

var tableHeaderGroup = /* tableHeaderGroup */606419204;

var tableFooterGroup = /* tableFooterGroup */-1045696202;

var tableRowGroup = /* tableRowGroup */-836725517;

var tableCell = /* tableCell */793912528;

var tableColumn = /* tableColumn */97122692;

var tableRow = /* tableRow */-843129172;

var absolute = /* absolute */-1013592457;

var relative = /* relative */903134412;

var $$static = /* static */100392110;

var fixed = /* fixed */10615156;

var sticky = /* sticky */188263721;

var flexStart = /* flexStart */662439529;

var flexEnd = /* flexEnd */924268066;

var center = /* center */98248149;

var stretch = /* stretch */-162316795;

var spaceBetween = /* spaceBetween */516682146;

var spaceAround = /* spaceAround */-485895757;

var spaceEvenly = /* spaceEvenly */-1016061555;

var baseline = /* baseline */287825029;

var forwards = /* forwards */733653774;

var backwards = /* backwards */245861168;

var both = /* both */-1055160191;

var infinite = /* infinite */-630647084;

var paused = /* paused */-276545362;

var running = /* running */-345412097;

var inside = /* inside */501235708;

var outside = /* outside */-430011099;

var italic = /* italic */107228912;

var oblique = /* oblique */734792881;

var underline = /* underline */131142924;

var overline = /* overline */-986651832;

var lineThrough = /* lineThrough */412155569;

var clip = /* clip */-1044222256;

var ellipsis = /* ellipsis */166888785;

var wavy = /* wavy */-822975027;

var $$double = /* double */852175633;

var uppercase = /* uppercase */-415330030;

var lowercase = /* lowercase */-425349839;

var capitalize = /* capitalize */-509867604;

var sub = /* sub */5745024;

var $$super = /* super */79692155;

var textTop = /* textTop */516406248;

var textBottom = /* textBottom */476669816;

var middle = /* middle */-866200747;

var normal = /* normal */812216871;

var breakAll = /* breakAll */-323760734;

var keepAll = /* keepAll */892748188;

var breakWord = /* breakWord */1059921449;

var reverse = /* reverse */452737314;

var alternate = /* alternate */892586298;

var alternateReverse = /* alternateReverse */545428232;

var fill$1 = /* fill */-1011102077;

var content = /* content */427265337;

var maxContent = /* maxContent */60557045;

var minContent = /* minContent */-550577721;

var fitContent = /* fitContent */-195805336;

var all = /* all */4847905;

var text = /* text */-856044371;

var linear = /* linear */-325037595;

var ease = /* ease */-1022587922;

var easeIn = /* easeIn */138027891;

var easeOut = /* easeOut */715748672;

var easeInOut = /* easeInOut */-960651557;

var stepStart = /* stepStart */-193525386;

var stepEnd = /* stepEnd */20933615;

var round = /* round */-312084402;

var miter = /* miter */141361157;

var bevel = /* bevel */811681914;

var butt = /* butt */-1054861805;

var square = /* square */-655228771;

var unsafe = d;

var thin = /* thin */-855898535;

var extraLight = /* extraLight */-21160922;

var light = /* light */-184774442;

var medium = /* medium */-20425611;

var semiBold = /* semiBold */-812709613;

var bold = /* bold */-1055161979;

var extraBold = /* extraBold */-81801163;

var lighter = /* lighter */434326211;

var bolder = /* bolder */-534575822;

var SVG = {
  fill: fill,
  fillRule: fillRule,
  fillOpacity: fillOpacity,
  stroke: stroke,
  strokeLinecap: strokeLinecap,
  strokeLinejoin: strokeLinejoin,
  strokeMiterlimit: strokeMiterlimit,
  strokeWidth: strokeWidth,
  strokeOpacity: strokeOpacity,
  stopColor: stopColor,
  stopOpacity: stopOpacity
};

exports.empty = empty;
exports.merge = merge;
exports.style = style;
exports.toJson = toJson;
exports.cache = cache;
exports.$$global = $$global;
exports.insertRule = insertRule;
exports.important = important;
exports.label = label;
exports.initial = initial;
exports.inherit_ = inherit_;
exports.unset = unset;
exports.rtl = rtl;
exports.ltr = ltr;
exports.deg = deg;
exports.rad = rad;
exports.grad = grad;
exports.turn = turn;
exports.rgb = rgb;
exports.rgba = rgba;
exports.hsl = hsl;
exports.hsla = hsla;
exports.hex = hex;
exports.transparent = transparent;
exports.currentColor = currentColor;
exports.linearGradient = linearGradient;
exports.repeatingLinearGradient = repeatingLinearGradient;
exports.radialGradient = radialGradient;
exports.repeatingRadialGradient = repeatingRadialGradient;
exports.aliceblue = aliceblue;
exports.antiquewhite = antiquewhite;
exports.aqua = aqua;
exports.aquamarine = aquamarine;
exports.azure = azure;
exports.beige = beige;
exports.bisque = bisque;
exports.black = black;
exports.blanchedalmond = blanchedalmond;
exports.blue = blue;
exports.blueviolet = blueviolet;
exports.brown = brown;
exports.burlywood = burlywood;
exports.cadetblue = cadetblue;
exports.chartreuse = chartreuse;
exports.chocolate = chocolate;
exports.coral = coral;
exports.cornflowerblue = cornflowerblue;
exports.cornsilk = cornsilk;
exports.crimson = crimson;
exports.cyan = cyan;
exports.darkblue = darkblue;
exports.darkcyan = darkcyan;
exports.darkgoldenrod = darkgoldenrod;
exports.darkgray = darkgray;
exports.darkgrey = darkgrey;
exports.darkgreen = darkgreen;
exports.darkkhaki = darkkhaki;
exports.darkmagenta = darkmagenta;
exports.darkolivegreen = darkolivegreen;
exports.darkorange = darkorange;
exports.darkorchid = darkorchid;
exports.darkred = darkred;
exports.darksalmon = darksalmon;
exports.darkseagreen = darkseagreen;
exports.darkslateblue = darkslateblue;
exports.darkslategray = darkslategray;
exports.darkslategrey = darkslategrey;
exports.darkturquoise = darkturquoise;
exports.darkviolet = darkviolet;
exports.deeppink = deeppink;
exports.deepskyblue = deepskyblue;
exports.dimgray = dimgray;
exports.dimgrey = dimgrey;
exports.dodgerblue = dodgerblue;
exports.firebrick = firebrick;
exports.floralwhite = floralwhite;
exports.forestgreen = forestgreen;
exports.fuchsia = fuchsia;
exports.gainsboro = gainsboro;
exports.ghostwhite = ghostwhite;
exports.gold = gold;
exports.goldenrod = goldenrod;
exports.gray = gray;
exports.grey = grey;
exports.green = green;
exports.greenyellow = greenyellow;
exports.honeydew = honeydew;
exports.hotpink = hotpink;
exports.indianred = indianred;
exports.indigo = indigo;
exports.ivory = ivory;
exports.khaki = khaki;
exports.lavender = lavender;
exports.lavenderblush = lavenderblush;
exports.lawngreen = lawngreen;
exports.lemonchiffon = lemonchiffon;
exports.lightblue = lightblue;
exports.lightcoral = lightcoral;
exports.lightcyan = lightcyan;
exports.lightgoldenrodyellow = lightgoldenrodyellow;
exports.lightgray = lightgray;
exports.lightgrey = lightgrey;
exports.lightgreen = lightgreen;
exports.lightpink = lightpink;
exports.lightsalmon = lightsalmon;
exports.lightseagreen = lightseagreen;
exports.lightskyblue = lightskyblue;
exports.lightslategray = lightslategray;
exports.lightslategrey = lightslategrey;
exports.lightsteelblue = lightsteelblue;
exports.lightyellow = lightyellow;
exports.lime = lime;
exports.limegreen = limegreen;
exports.linen = linen;
exports.magenta = magenta;
exports.maroon = maroon;
exports.mediumaquamarine = mediumaquamarine;
exports.mediumblue = mediumblue;
exports.mediumorchid = mediumorchid;
exports.mediumpurple = mediumpurple;
exports.mediumseagreen = mediumseagreen;
exports.mediumslateblue = mediumslateblue;
exports.mediumspringgreen = mediumspringgreen;
exports.mediumturquoise = mediumturquoise;
exports.mediumvioletred = mediumvioletred;
exports.midnightblue = midnightblue;
exports.mintcream = mintcream;
exports.mistyrose = mistyrose;
exports.moccasin = moccasin;
exports.navajowhite = navajowhite;
exports.navy = navy;
exports.oldlace = oldlace;
exports.olive = olive;
exports.olivedrab = olivedrab;
exports.orange = orange;
exports.orangered = orangered;
exports.orchid = orchid;
exports.palegoldenrod = palegoldenrod;
exports.palegreen = palegreen;
exports.paleturquoise = paleturquoise;
exports.palevioletred = palevioletred;
exports.papayawhip = papayawhip;
exports.peachpuff = peachpuff;
exports.peru = peru;
exports.pink = pink;
exports.plum = plum;
exports.powderblue = powderblue;
exports.purple = purple;
exports.rebeccapurple = rebeccapurple;
exports.red = red;
exports.rosybrown = rosybrown;
exports.royalblue = royalblue;
exports.saddlebrown = saddlebrown;
exports.salmon = salmon;
exports.sandybrown = sandybrown;
exports.seagreen = seagreen;
exports.seashell = seashell;
exports.sienna = sienna;
exports.silver = silver;
exports.skyblue = skyblue;
exports.slateblue = slateblue;
exports.slategray = slategray;
exports.slategrey = slategrey;
exports.snow = snow;
exports.springgreen = springgreen;
exports.steelblue = steelblue;
exports.tan = tan;
exports.teal = teal;
exports.thistle = thistle;
exports.tomato = tomato;
exports.turquoise = turquoise;
exports.violet = violet;
exports.wheat = wheat;
exports.white = white;
exports.whitesmoke = whitesmoke;
exports.yellow = yellow;
exports.yellowgreen = yellowgreen;
exports.ch = ch;
exports.cm = cm;
exports.em = em;
exports.ex = ex;
exports.fr = fr;
exports.mm = mm;
exports.pct = pct;
exports.pt = pt;
exports.px = px;
exports.pxFloat = pxFloat;
exports.rem = rem;
exports.vh = vh;
exports.vmax = vmax;
exports.vmin = vmin;
exports.vw = vw;
exports.zero = zero;
exports.Calc = Calc;
exports.size = size;
exports.resize = resize;
exports.horizontal = horizontal;
exports.vertical = vertical;
exports.solid = solid;
exports.dotted = dotted;
exports.dashed = dashed;
exports.localUrl = localUrl;
exports.url = url;
exports.none = none;
exports.auto = auto;
exports.hidden = hidden;
exports.visible = visible;
exports.local = local;
exports.scroll = scroll;
exports.paddingBox = paddingBox;
exports.borderBox = borderBox;
exports.contentBox = contentBox;
exports.noRepeat = noRepeat;
exports.repeat = repeat;
exports.minmax = minmax;
exports.repeatX = repeatX;
exports.repeatY = repeatY;
exports.contain = contain;
exports.cover = cover;
exports.row = row;
exports.rowReverse = rowReverse;
exports.column = column;
exports.columnReverse = columnReverse;
exports.wrap = wrap;
exports.nowrap = nowrap;
exports.wrapReverse = wrapReverse;
exports.inline = inline;
exports.block = block;
exports.contents = contents;
exports.flexBox = flexBox;
exports.grid = grid;
exports.inlineBlock = inlineBlock;
exports.inlineFlex = inlineFlex;
exports.inlineGrid = inlineGrid;
exports.inlineTable = inlineTable;
exports.listItem = listItem;
exports.runIn = runIn;
exports.table = table;
exports.tableCaption = tableCaption;
exports.tableColumnGroup = tableColumnGroup;
exports.tableHeaderGroup = tableHeaderGroup;
exports.tableFooterGroup = tableFooterGroup;
exports.tableRowGroup = tableRowGroup;
exports.tableCell = tableCell;
exports.tableColumn = tableColumn;
exports.tableRow = tableRow;
exports.absolute = absolute;
exports.relative = relative;
exports.$$static = $$static;
exports.fixed = fixed;
exports.sticky = sticky;
exports.flexStart = flexStart;
exports.flexEnd = flexEnd;
exports.center = center;
exports.stretch = stretch;
exports.spaceBetween = spaceBetween;
exports.spaceAround = spaceAround;
exports.spaceEvenly = spaceEvenly;
exports.baseline = baseline;
exports.forwards = forwards;
exports.backwards = backwards;
exports.both = both;
exports.infinite = infinite;
exports.count = count;
exports.paused = paused;
exports.running = running;
exports.inside = inside;
exports.outside = outside;
exports.translate = translate;
exports.translate3d = translate3d;
exports.translateX = translateX;
exports.translateY = translateY;
exports.translateZ = translateZ;
exports.scale = scale;
exports.scale3d = scale3d;
exports.scaleX = scaleX;
exports.scaleY = scaleY;
exports.scaleZ = scaleZ;
exports.rotate = rotate;
exports.rotate3d = rotate3d;
exports.rotateX = rotateX;
exports.rotateY = rotateY;
exports.rotateZ = rotateZ;
exports.skew = skew;
exports.skewX = skewX;
exports.skewY = skewY;
exports.italic = italic;
exports.oblique = oblique;
exports.underline = underline;
exports.overline = overline;
exports.lineThrough = lineThrough;
exports.clip = clip;
exports.ellipsis = ellipsis;
exports.wavy = wavy;
exports.$$double = $$double;
exports.uppercase = uppercase;
exports.lowercase = lowercase;
exports.capitalize = capitalize;
exports.sub = sub;
exports.$$super = $$super;
exports.textTop = textTop;
exports.textBottom = textBottom;
exports.middle = middle;
exports.normal = normal;
exports.breakAll = breakAll;
exports.keepAll = keepAll;
exports.breakWord = breakWord;
exports.reverse = reverse;
exports.alternate = alternate;
exports.alternateReverse = alternateReverse;
exports.fill = fill$1;
exports.content = content;
exports.maxContent = maxContent;
exports.minContent = minContent;
exports.fitContent = fitContent;
exports.all = all;
exports.text = text;
exports.linear = linear;
exports.ease = ease;
exports.easeIn = easeIn;
exports.easeOut = easeOut;
exports.easeInOut = easeInOut;
exports.stepStart = stepStart;
exports.stepEnd = stepEnd;
exports.steps = steps;
exports.cubicBesier = cubicBesier;
exports.round = round;
exports.miter = miter;
exports.bevel = bevel;
exports.butt = butt;
exports.square = square;
exports.unsafe = unsafe;
exports.display = display;
exports.position = position;
exports.top = top;
exports.bottom = bottom;
exports.left = left;
exports.right = right;
exports.flex = flex;
exports.flex3 = flex3;
exports.flexGrow = flexGrow;
exports.flexShrink = flexShrink;
exports.flexBasis = flexBasis;
exports.flexDirection = flexDirection;
exports.flexWrap = flexWrap;
exports.order = order;
exports.gridTemplateColumns = gridTemplateColumns;
exports.gridTemplateRows = gridTemplateRows;
exports.gridAutoColumns = gridAutoColumns;
exports.gridAutoRows = gridAutoRows;
exports.gridAutoFlow = gridAutoFlow;
exports.gridColumn = gridColumn;
exports.gridRow = gridRow;
exports.gridColumnStart = gridColumnStart;
exports.gridColumnEnd = gridColumnEnd;
exports.gridRowStart = gridRowStart;
exports.gridRowEnd = gridRowEnd;
exports.gridColumnGap = gridColumnGap;
exports.gridRowGap = gridRowGap;
exports.gridGap = gridGap;
exports.width = width;
exports.height = height;
exports.minWidth = minWidth;
exports.maxWidth = maxWidth;
exports.minHeight = minHeight;
exports.maxHeight = maxHeight;
exports.margin = margin;
exports.margin2 = margin2;
exports.margin3 = margin3;
exports.margin4 = margin4;
exports.marginLeft = marginLeft;
exports.marginRight = marginRight;
exports.marginTop = marginTop;
exports.marginBottom = marginBottom;
exports.padding = padding;
exports.padding2 = padding2;
exports.padding3 = padding3;
exports.padding4 = padding4;
exports.paddingLeft = paddingLeft;
exports.paddingRight = paddingRight;
exports.paddingTop = paddingTop;
exports.paddingBottom = paddingBottom;
exports.alignContent = alignContent;
exports.alignItems = alignItems;
exports.alignSelf = alignSelf;
exports.justifySelf = justifySelf;
exports.justifyContent = justifyContent;
exports.boxSizing = boxSizing;
exports.$$float = $$float;
exports.clear = clear;
exports.overflow = overflow;
exports.overflowX = overflowX;
exports.overflowY = overflowY;
exports.zIndex = zIndex;
exports.contentRule = contentRule;
exports.columnCount = columnCount;
exports.direction = direction;
exports.backfaceVisibility = backfaceVisibility;
exports.visibility = visibility;
exports.border = border;
exports.borderWidth = borderWidth;
exports.borderStyle = borderStyle;
exports.borderColor = borderColor;
exports.borderTop = borderTop;
exports.borderTopWidth = borderTopWidth;
exports.borderTopStyle = borderTopStyle;
exports.borderTopColor = borderTopColor;
exports.borderBottom = borderBottom;
exports.borderBottomWidth = borderBottomWidth;
exports.borderBottomStyle = borderBottomStyle;
exports.borderBottomColor = borderBottomColor;
exports.borderLeft = borderLeft;
exports.borderLeftWidth = borderLeftWidth;
exports.borderLeftStyle = borderLeftStyle;
exports.borderLeftColor = borderLeftColor;
exports.borderRight = borderRight;
exports.borderRightWidth = borderRightWidth;
exports.borderRightStyle = borderRightStyle;
exports.borderRightColor = borderRightColor;
exports.borderRadius = borderRadius;
exports.borderTopLeftRadius = borderTopLeftRadius;
exports.borderTopRightRadius = borderTopRightRadius;
exports.borderBottomLeftRadius = borderBottomLeftRadius;
exports.borderBottomRightRadius = borderBottomRightRadius;
exports.tableLayout = tableLayout;
exports.borderCollapse = borderCollapse;
exports.borderSpacing = borderSpacing;
exports.boxShadow = boxShadow;
exports.boxShadows = boxShadows;
exports.background = background;
exports.backgrounds = backgrounds;
exports.backgroundColor = backgroundColor;
exports.backgroundImage = backgroundImage;
exports.backgroundAttachment = backgroundAttachment;
exports.backgroundClip = backgroundClip;
exports.backgroundOrigin = backgroundOrigin;
exports.backgroundPosition = backgroundPosition;
exports.backgroundRepeat = backgroundRepeat;
exports.backgroundSize = backgroundSize;
exports.cursor = cursor;
exports.clipPath = clipPath;
exports.listStyle = listStyle;
exports.listStyleType = listStyleType;
exports.listStylePosition = listStylePosition;
exports.listStyleImage = listStyleImage;
exports.opacity = opacity;
exports.outline = outline;
exports.outlineStyle = outlineStyle;
exports.outlineWidth = outlineWidth;
exports.outlineColor = outlineColor;
exports.outlineOffset = outlineOffset;
exports.pointerEvents = pointerEvents;
exports.filter = filter;
exports.thin = thin;
exports.extraLight = extraLight;
exports.light = light;
exports.medium = medium;
exports.semiBold = semiBold;
exports.bold = bold;
exports.extraBold = extraBold;
exports.lighter = lighter;
exports.bolder = bolder;
exports.color = color;
exports.fontFamily = fontFamily;
exports.fontFace = fontFace;
exports.fontSize = fontSize;
exports.fontVariant = fontVariant;
exports.fontStyle = fontStyle;
exports.fontWeight = fontWeight;
exports.letterSpacing = letterSpacing;
exports.lineHeight = lineHeight;
exports.textAlign = textAlign;
exports.textDecoration = textDecoration;
exports.textDecorationColor = textDecorationColor;
exports.textDecorationStyle = textDecorationStyle;
exports.textIndent = textIndent;
exports.textOverflow = textOverflow;
exports.textShadow = textShadow;
exports.textShadows = textShadows;
exports.textTransform = textTransform;
exports.userSelect = userSelect;
exports.verticalAlign = verticalAlign;
exports.whiteSpace = whiteSpace;
exports.wordBreak = wordBreak;
exports.wordSpacing = wordSpacing;
exports.wordWrap = wordWrap;
exports.transform = transform;
exports.transforms = transforms;
exports.transformOrigin = transformOrigin;
exports.transformOrigin3d = transformOrigin3d;
exports.transformStyle = transformStyle;
exports.perspective = perspective;
exports.perspectiveOrigin = perspectiveOrigin;
exports.transition = transition;
exports.transitions = transitions;
exports.transitionDelay = transitionDelay;
exports.transitionDuration = transitionDuration;
exports.transitionTimingFunction = transitionTimingFunction;
exports.transitionProperty = transitionProperty;
exports.keyframes = keyframes;
exports.animation = animation;
exports.animations = animations;
exports.animationDelay = animationDelay;
exports.animationDirection = animationDirection;
exports.animationDuration = animationDuration;
exports.animationFillMode = animationFillMode;
exports.animationIterationCount = animationIterationCount;
exports.animationName = animationName;
exports.animationPlayState = animationPlayState;
exports.animationTimingFunction = animationTimingFunction;
exports.selector = selector;
exports.active = active;
exports.after = after;
exports.before = before;
exports.checked = checked;
exports.children = children;
exports.directSibling = directSibling;
exports.disabled = disabled;
exports.firstChild = firstChild;
exports.firstOfType = firstOfType;
exports.focus = focus;
exports.hover = hover;
exports.lastChild = lastChild;
exports.lastOfType = lastOfType;
exports.link = link;
exports.readOnly = readOnly;
exports.required = required;
exports.visited = visited;
exports.enabled = enabled;
exports.noContent = noContent;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.anyLink = anyLink;
exports.onlyChild = onlyChild;
exports.onlyOfType = onlyOfType;
exports.optional = optional;
exports.invalid = invalid;
exports.outOfRange = outOfRange;
exports.siblings = siblings;
exports.target = target;
exports.firstLine = firstLine;
exports.firstLetter = firstLetter;
exports.selection = selection;
exports.placeholder = placeholder;
exports.media = media;
exports.SVG = SVG;
/* cache Not a pure module */
