// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function $$Image(Props) {
  var className = Props.className;
  var style = Props.style;
  var src = Props.src;
  var alt = Props.alt;
  var onClickOpt = Props.onClick;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var tmp = {
    alt: I18N$BsConsole.get(undefined, alt),
    src: src,
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement("img", tmp);
}

var component = ReasonReact.statelessComponent("Image");

function make(className, style, src, alt, onClickOpt) {
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var tmp = {
    src: src,
    alt: alt,
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var partial_arg = tmp;
  return (function (param) {
      return ReasonReactCompat.wrapReactForReasonReact($$Image, partial_arg, param);
    });
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = $$Image;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
