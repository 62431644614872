// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Bin$BsConsole = require("./aggregation-renderers/Bin.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Raw$BsConsole = require("./format-renderers/Raw.js");
var Base$BsConsole = require("./bs-crdb-response/src/Base.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Shape$BsConsole = require("./bs-crdb-response/src/Shape.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var AddFold$BsConsole = require("./AddFold.js");
var Numeral$BsConsole = require("./Numeral.js");
var Truncate$BsConsole = require("./Truncate.js");
var SortOrder$BsConsole = require("./bs-crdb-query/src/SortOrder.js");
var EmptyValue$BsConsole = require("./format-renderers/EmptyValue.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var Fingerprint$BsConsole = require("./format-renderers/Fingerprint.js");
var SummedEvents$BsConsole = require("./SummedEvents.js");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var FormatPopover$BsConsole = require("./format-renderers/FormatPopover.js");
var QbMorePopover$BsConsole = require("./query-builder/QbMorePopover.js");
var Button = require("@material-ui/core/Button");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var Tooltip = require("@material-ui/core/Tooltip");
var AggregationType$BsConsole = require("./bs-crdb-response/src/AggregationType.js");
var MoreVert = require("@material-ui/icons/MoreVert");
var ButtonBase = require("@material-ui/core/ButtonBase");
var AddCircle = require("@material-ui/icons/AddCircle");
var ActivitySortHeader$BsConsole = require("./aggregation-renderers/ActivitySortHeader.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var CondensedDistribution$BsConsole = require("./charts/CondensedDistribution.js");
var CountNormalizationCell$BsConsole = require("./CountNormalizationCell.js");

var factorColumn = "__factor";

var countColumn = "__count";

var activityColumn = "__activity";

function orderingToDirection(param) {
  if (param) {
    return /* Desc */759138929;
  } else {
    return /* Asc */3258129;
  }
}

function directionToOrdering(param) {
  if (param >= 759138929) {
    return /* Descending */1;
  } else {
    return /* Ascending */0;
  }
}

function rangeToColumnName(range, attribute) {
  if (range) {
    return "" + (String(attribute) + "__range--max");
  } else {
    return "" + (String(attribute) + "__range--min");
  }
}

function isRangeColumn(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return false;
  }
  var match$1 = match[1];
  if (match$1 === "range") {
    return true;
  } else {
    return false;
  }
}

function createFold(fold, aperture) {
  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  "timestamp",
                  /* Range */8
                ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "timestamp",
                      /* Bin */Block.__(0, [
                          32,
                          Crdb$BsConsole.ensureValidTimeRange(Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture)))
                        ])
                    ]), fold));
}

function getAggregationFromColumnName(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return ;
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "bin" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Bin */Block.__(0, [
                            32,
                            undefined
                          ])
                      ]));
    case "distribution" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Distribution */Block.__(1, [3])
                      ]));
    case "head" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Head */1
                      ]));
    case "max" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Max */6
                      ]));
    case "mean" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Mean */7
                      ]));
    case "min" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Min */5
                      ]));
    case "range" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Range */8
                      ]));
    case "sum" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Sum */3
                      ]));
    case "tail" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Tail */2
                      ]));
    case "unique" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Unique */4
                      ]));
    default:
      return ;
  }
}

function getSortingStateFromTableSort(sortingState) {
  var ordering = directionToOrdering(sortingState.direction);
  var columnName = sortingState.columnName;
  if (columnName === factorColumn) {
    return /* Group */Block.__(0, [ordering]);
  }
  if (columnName === countColumn) {
    return /* Count */Block.__(1, [ordering]);
  }
  var match = columnName.split("__");
  if (match.length !== 2) {
    return /* Count */Block.__(1, [/* Descending */1]);
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "head" :
        return /* Head */Block.__(2, [
                  attribute,
                  ordering
                ]);
    case "max" :
        return /* Max */Block.__(8, [
                  attribute,
                  ordering
                ]);
    case "mean" :
        return /* Mean */Block.__(9, [
                  attribute,
                  ordering
                ]);
    case "min" :
        return /* Min */Block.__(7, [
                  attribute,
                  ordering
                ]);
    case "range--max" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Finish */1,
                  ordering
                ]);
    case "range--min" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Start */0,
                  ordering
                ]);
    case "sum" :
        return /* Sum */Block.__(10, [
                  attribute,
                  ordering
                ]);
    case "tail" :
        return /* Tail */Block.__(3, [
                  attribute,
                  ordering
                ]);
    case "unique" :
        return /* Unique */Block.__(6, [
                  attribute,
                  ordering
                ]);
    default:
      return /* Count */Block.__(1, [/* Descending */1]);
  }
}

function getTableSortingState(sort) {
  switch (sort.tag | 0) {
    case /* Group */0 :
        return {
                columnName: factorColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Count */1 :
        return {
                columnName: countColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Head */2 :
        return {
                columnName: "" + (String(sort[0]) + "__head"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Tail */3 :
        return {
                columnName: "" + (String(sort[0]) + "__tail"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Range */4 :
        return {
                columnName: rangeToColumnName(sort[1], sort[0]),
                direction: sort[2] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Select */5 :
        return ;
    case /* Unique */6 :
        return {
                columnName: "" + (String(sort[0]) + "__unique"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Min */7 :
        return {
                columnName: "" + (String(sort[0]) + "__min"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Max */8 :
        return {
                columnName: "" + (String(sort[0]) + "__max"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Mean */9 :
        return {
                columnName: "" + (String(sort[0]) + "__mean"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Sum */10 :
        return {
                columnName: "" + (String(sort[0]) + "__sum"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    
  }
}

function onSortChange(cb, sortingState) {
  return Curry._1(cb, getSortingStateFromTableSort(sortingState));
}

function getColumnAlignment(column) {
  var op = column.op;
  if (op === undefined) {
    return /* Left */847852583;
  }
  switch (op) {
    case /* Distribution */1 :
    case /* Bin */2 :
        return /* Left */847852583;
    case /* Unique */0 :
    case /* Mean */6 :
    case /* Sum */7 :
        return /* Right */-57574468;
    case /* Head */3 :
    case /* Tail */4 :
    case /* Range */5 :
    case /* Min */8 :
    case /* Max */9 :
        return BtTableAdapter$BsConsole.getAlignmentByType_(column.type_);
    
  }
}

function getTableColumns(columns, factor, normBy, fold) {
  var factorString = factor ? factor[0] : "fingerprint";
  var defaultColumns = [
    BtTableColumn$BsConsole.make("Group - " + (String(factorString) + ""), factorColumn, (function (row) {
            var groupName = row.factor.value;
            return /* `String */[
                    -976970511,
                    groupName
                  ];
          }), undefined, undefined, undefined, 100, 400, 200, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(normBy !== undefined ? "Errors by " + (String(normBy) + "") : I18N$BsConsole.get(undefined, "Errors"), countColumn, (function (row) {
            var count = row.count;
            return /* `Int */[
                    3654863,
                    count
                  ];
          }), undefined, undefined, undefined, Belt_Option.isSome(normBy) ? 150 : 100, undefined, Belt_Option.isSome(normBy) ? 160 : 120, undefined, undefined, /* Right */-57574468, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Activity"), activityColumn, (function (row) {
            var data = Belt_List.getBy(row.aggregations, (function (shape) {
                    if (shape.tag === /* Bin */2 && shape[0] === "timestamp") {
                      return true;
                    } else {
                      return false;
                    }
                  }));
            return /* `CrdbAggregate */[
                    -901480206,
                    data
                  ];
          }), undefined, true, undefined, 204, 204, 204, undefined, undefined, undefined, undefined)
  ];
  var timestampRange = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg) {
          if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === "timestamp") {
            return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === "range";
          } else {
            return false;
          }
        }));
  var aggColumns = Belt_Array.map(Belt_Array.keep(columns, (function (column) {
              var op$prime = column.op;
              var op = op$prime !== undefined ? AggregationType$BsConsole.toString(op$prime) : "";
              var match = column.name === "timestamp" && op === "bin";
              var match$1 = column.name === "timestamp" && op === "range";
              if (match) {
                return false;
              } else if (match$1) {
                return timestampRange !== undefined;
              } else {
                return true;
              }
            })), (function (column) {
          var op$prime = column.op;
          var op = op$prime !== undefined ? AggregationType$BsConsole.toString(op$prime) : "";
          var match = column.op;
          var match$1 = column.format;
          var match$2;
          var exit = 0;
          if (match !== undefined) {
            switch (match) {
              case /* Distribution */1 :
                  match$2 = /* tuple */[
                    215,
                    215
                  ];
                  break;
              case /* Bin */2 :
                  match$2 = /* tuple */[
                    232,
                    232
                  ];
                  break;
              case /* Range */5 :
                  match$2 = /* tuple */[
                    160,
                    160
                  ];
                  break;
              case /* Unique */0 :
              case /* Head */3 :
              case /* Tail */4 :
              case /* Mean */6 :
              case /* Sum */7 :
              case /* Min */8 :
              case /* Max */9 :
                  exit = 1;
                  break;
              
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            match$2 = match$1 === 2 ? /* tuple */[
                215,
                215
              ] : /* tuple */[
                undefined,
                160
              ];
          }
          var match$3 = column.op;
          var disableSort = match$3 !== undefined ? match$3 === 2 || match$3 === 1 : false;
          return BtTableColumn$BsConsole.make(column.name + (" - " + op), column.name + ("__" + op), (function (row) {
                        var data = Belt_List.getBy(row.aggregations, (function (shape) {
                                if (Shape$BsConsole.getName(shape) === column.name) {
                                  return Shape$BsConsole.getAggString(shape) === op;
                                } else {
                                  return false;
                                }
                              }));
                        return /* `CrdbAggregate */[
                                -901480206,
                                data
                              ];
                      }), disableSort, undefined, undefined, match$2[0], undefined, match$2[1], undefined, undefined, getColumnAlignment(column), undefined);
        }));
  return Belt_Array.concat(defaultColumns, aggColumns);
}

function renderHeaderCell(param, column, sortingState, onSortChange, onColumnDelete, handleSetSort) {
  var onColumnDelete$prime = function (columnName) {
    return Curry._1(onColumnDelete, getAggregationFromColumnName(columnName));
  };
  var col = column.name;
  var disableDelete = col === countColumn || col === factorColumn ? true : col === activityColumn;
  var match = column.align;
  var align = match >= 847852583 ? /* left */-944764921 : /* right */-379319332;
  if (onSortChange === undefined) {
    return React.createElement(BtTableAdapter$BsConsole.Header.make, {
                children: I18N$BsConsole.showSkip(column.title)
              });
  }
  var col$1 = column.name;
  if (col$1 !== activityColumn) {
    if (isRangeColumn(col$1)) {
      return React.createElement(BtTableAdapter$BsConsole.EnhancedRangeHeader.make, {
                  sortingState: sortingState,
                  onSortChange: onSortChange,
                  columnName: column.name,
                  align: align,
                  disableDelete: disableDelete,
                  onColumnDelete: onColumnDelete$prime,
                  children: I18N$BsConsole.showSkip(column.title)
                });
    } else {
      return React.createElement(BtTableAdapter$BsConsole.EnhancedHeader.make, {
                  sortingState: sortingState,
                  onSortChange: onSortChange,
                  disableSort: column.disableSort,
                  columnName: column.name,
                  align: align,
                  disableDelete: disableDelete,
                  onColumnDelete: onColumnDelete$prime,
                  children: I18N$BsConsole.showSkip(column.title)
                });
    }
  }
  var sort = sortingState !== undefined ? getSortingStateFromTableSort(sortingState) : Crdb$BsConsole.Sort.defaultAggregate;
  return React.createElement(ActivitySortHeader$BsConsole.make, {
              handleSetSort: handleSetSort,
              sort: sort
            });
}

function renderStubHeader(query, attributes, onChange, param) {
  return React.createElement(AddFold$BsConsole.make, {
              query: query,
              onChange: onChange,
              attributes: attributes,
              children: React.createElement(Button.default, {
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginLeft(Css.px(16)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (_event) {
                        
                      }),
                    children: React.createElement(Row2$BsConsole.make, {
                          children: null
                        }, React.createElement(AddCircle.default, { }), React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* :: */[
                                      Css.whiteSpace(/* nowrap */867913355),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "Add aggregation")))
                  })
            });
}

function renderExploreAggDataCell(row, column, value, key, factor, token, projectName, handleChangeUrl, handleAddFilters, aperture, havings, universe, totalCount, stabilityScore, normBy, sort, onAggregationExpandChange, config, uniqueCount, param) {
  var col = column.name;
  if (col === factorColumn) {
    return React.createElement(BtTableCell$BsConsole.make, {
                children: React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, factor ? React.createElement(Raw$BsConsole.make, {
                            value: /* `String */[
                              -976970511,
                              row.factor.value
                            ],
                            attribute: factor[0],
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.unsafe("maxWidth", "calc(100% - 28px)"),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    "group-title",
                                    /* [] */0
                                  ]
                                ])
                          }) : React.createElement(Fingerprint$BsConsole.make, {
                            value: row.factor.value,
                            attribute: "fingerprint",
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.unsafe("maxWidth", "calc(100% - 28px)"),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    "group-title",
                                    /* [] */0
                                  ]
                                ])
                          }), React.createElement(QbMorePopover$BsConsole.Jsx3.make, {
                          group: row,
                          aperture: aperture,
                          config: config,
                          havings: havings,
                          handleChangeUrl: handleChangeUrl,
                          token: token,
                          name: projectName,
                          universe: universe,
                          sort: sort,
                          handleAddFilters: handleAddFilters,
                          normBy: normBy,
                          children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                ariaLabel: "Group options",
                                classes: {
                                  root: Css.merge(/* :: */[
                                        Css.style(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.accent)),
                                              /* :: */[
                                                Css.fontSize(Css.rem(1)),
                                                /* :: */[
                                                  Css.important(Css.padding(Css.px(4))),
                                                  /* :: */[
                                                    Css.display(/* none */-922086728),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* :: */[
                                          "group-more",
                                          /* [] */0
                                        ]
                                      ]),
                                  label: Css.style(/* :: */[
                                        Css.unsafe("fontSize", "inherit"),
                                        /* [] */0
                                      ])
                                },
                                title_tooltip: I18N$BsConsole.get(undefined, "Group options"),
                                children: React.createElement(MoreVert.default, {
                                      color: "inherit",
                                      fontSize: "inherit"
                                    })
                              })
                        })),
                key: key + ("-factor__" + row.factor.value)
              });
  }
  if (col === countColumn) {
    var errorPercentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(row.count / totalCount * 100)) + "%";
    var tmp;
    var exit = 0;
    if (stabilityScore !== undefined && normBy !== undefined) {
      var applicationSessionCount = Belt_Option.flatMap(Belt_List.getBy(row.aggregations, (function (agg) {
                  if (agg.tag || agg[0] !== "application.session") {
                    return false;
                  } else {
                    return agg[1] !== undefined;
                  }
                })), (function (agg) {
              if (agg.tag) {
                return ;
              }
              if (agg[0] !== "application.session") {
                return ;
              }
              var count = agg[1];
              if (count !== undefined && count > 0) {
                return count | 0;
              }
              
            }));
      var score;
      if (stabilityScore.tag) {
        var match = Belt_Array.getBy(stabilityScore[0], (function (param) {
                return param[0] === row.factor.value;
              }));
        score = match !== undefined ? match[1] : undefined;
      } else {
        score = stabilityScore[0];
      }
      var match$1 = applicationSessionCount !== undefined ? /* tuple */[
          "unique application.session",
          applicationSessionCount
        ] : /* tuple */[
          "errors",
          row.count
        ];
      var numeratorCount = match$1[1];
      var numerator = match$1[0];
      var numeratorCountString = Numeral$BsConsole.format("0,0", String(numeratorCount));
      var groupAttribute = row.factor.attribute;
      var groupValue = row.factor.value;
      var metricsScoreTitle;
      if (score !== undefined) {
        if (stabilityScore.tag) {
          var scoreTotalString = Numeral$BsConsole.format("0,0", Int64.to_string(score));
          metricsScoreTitle = Curry._6(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* Char_literal */Block.__(12, [
                            /* " " */32,
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " / ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " in ",
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* Char_literal */Block.__(12, [
                                                            /* " " */32,
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* End_of_format */0
                                                              ])
                                                          ])
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "%s %s / %s %s in %s %s"
                  ]), numeratorCountString, numerator, scoreTotalString, normBy, groupAttribute, groupValue);
        } else {
          var scoreTotalString$1 = Numeral$BsConsole.format("0,0", Int64.to_string(score));
          metricsScoreTitle = Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* Char_literal */Block.__(12, [
                            /* " " */32,
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " / ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "%s %s / %s %s"
                  ]), numeratorCountString, numerator, scoreTotalString$1, normBy);
        }
      } else {
        metricsScoreTitle = React.createElement("div", undefined, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No metrics have been captured for this group. Visit the project's 'Stability monitoring' settings to ensure the proper metrics and importers are configured.")), React.createElement(Link$BsConsole.Jsx3.make, {
                  route: /* ProjectSettingsStabilityMonitoring */Block.__(50, [projectName]),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.style(/* :: */[
                        Css.important(Css.textDecoration(/* underline */131142924)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Stability monitoring settings")
                }));
      }
      tmp = React.createElement(CountNormalizationCell$BsConsole.make, {
            count: row.count,
            normalizedPercentageString: score !== undefined ? "(" + (Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(Caml_primitive.caml_float_min(numeratorCount / Caml_int64.to_float(score) * 100, 100))) + "%)") : I18N$BsConsole.get(undefined, "(N/A)"),
            countTitle: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              " of total errors in set",
                              /* End_of_format */0
                            ])
                        ]),
                      "%s of total errors in set"
                    ]), errorPercentage),
            normalizedTitle: metricsScoreTitle,
            normalizedTooltipInteractive: !Belt_Option.isSome(score)
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(Tooltip.default, {
            children: React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.textAlign(/* right */-379319332),
                          /* :: */[
                            Css.fontFamily("inconsolata"),
                            /* :: */[
                              Css.padding2(/* zero */-789508312, Css.px(4)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(Truncate$BsConsole.make, {
                      children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(row.count)))
                    })),
            title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              " of total errors in set",
                              /* End_of_format */0
                            ])
                        ]),
                      "%s of total errors in set"
                    ]), errorPercentage)
          });
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp,
                key: key + ("-" + (String(row.count) + column.name))
              });
  }
  if (col === activityColumn) {
    var timestampRange = Belt_List.getBy(row.aggregations, (function (shape) {
            if (shape.tag === /* Range */8 && shape[0] === "timestamp") {
              return true;
            } else {
              return false;
            }
          }));
    var tmp$1;
    var exit$1 = 0;
    if (typeof value === "number" || value[0] !== -901480206) {
      exit$1 = 1;
    } else {
      var match$2 = value[1];
      if (match$2 !== undefined && match$2.tag === /* Bin */2 && match$2[0] === "timestamp") {
        var data = match$2[1];
        if (data !== undefined) {
          var binRange = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture));
          var tmp$2;
          var exit$2 = 0;
          var start;
          var finish;
          if (timestampRange !== undefined && timestampRange.tag === /* Range */8 && timestampRange[0] === "timestamp") {
            var match$3 = timestampRange[1];
            if (match$3 !== undefined) {
              var match$4 = match$3[0];
              if (match$4 !== undefined) {
                switch (match$4.tag | 0) {
                  case /* UnixTimestamp */2 :
                      var match$5 = match$3[1];
                      if (match$5 !== undefined && match$5.tag === /* UnixTimestamp */2) {
                        start = match$4[0];
                        finish = match$5[0];
                        exit$2 = 2;
                      } else {
                        tmp$2 = null;
                      }
                      break;
                  case /* GpsTimestamp */3 :
                      var match$6 = match$3[1];
                      if (match$6 !== undefined && match$6.tag === /* GpsTimestamp */3) {
                        start = match$4[0];
                        finish = match$6[0];
                        exit$2 = 2;
                      } else {
                        tmp$2 = null;
                      }
                      break;
                  default:
                    tmp$2 = null;
                }
              } else {
                tmp$2 = null;
              }
            } else {
              tmp$2 = null;
            }
          } else {
            tmp$2 = null;
          }
          if (exit$2 === 2) {
            tmp$2 = React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.px(10)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey2)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Tooltip.default, {
                      children: React.createElement("div", undefined, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, start))),
                      title: I18N$BsConsole.get(undefined, "First seen in set")
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.margin2(/* zero */-789508312, Css.em(0.5)),
                            /* [] */0
                          ])
                    }, I18N$BsConsole.showSkip("-")), React.createElement(Tooltip.default, {
                      children: React.createElement("div", undefined, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, finish))),
                      title: I18N$BsConsole.get(undefined, "Last seen in set")
                    }));
          }
          tmp$1 = React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.margin2(/* zero */-789508312, Css.px(4)),
                      /* [] */0
                    ])
              }, React.createElement(Bin$BsConsole.make, {
                    data: data,
                    width: 164,
                    height: 26,
                    barFill: "#" + Colors$BsConsole.mutedChartViz,
                    range: binRange
                  }), tmp$2);
        } else {
          exit$1 = 1;
        }
      } else {
        exit$1 = 1;
      }
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(EmptyValue$BsConsole.make, { });
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp$1,
                key: String(row.count) + (column.name + "__activity")
              });
  }
  if (typeof value !== "number" && value[0] === -901480206) {
    var shape = value[1];
    if (shape !== undefined) {
      var tmp$3;
      switch (shape.tag | 0) {
        case /* Unique */0 :
            var value$1 = shape[1];
            var attribute = shape[0];
            var exit$3 = 0;
            if (uniqueCount !== undefined && attribute === uniqueCount[0]) {
              var unique = uniqueCount[1];
              var count;
              count = unique.tag ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(unique[0], (function (group) {
                                return group.groupValue === row.factor.value;
                              })), (function (group) {
                            return Caml_int64.to_int32(group.count);
                          })), 1) : Caml_int64.to_float(unique[0]);
              var value$2 = Belt_Option.getWithDefault(value$1, 0);
              var ratio = value$2 / count;
              var percentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(Caml_primitive.caml_float_min(ratio * 100, 100))) + ("%" + (
                  ratio > 1 ? "*" : ""
                ));
              var normalizedTitle = count > 0 && value$2 > 0 ? (
                  ratio > 1 ? React.createElement(Col2$BsConsole.make, {
                          children: null
                        }, React.createElement("div", undefined, Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " of unique ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " events (",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              " / ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* Char_literal */Block.__(12, [
                                                                      /* ")" */41,
                                                                      /* End_of_format */0
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                      "%s of unique %s events (%s / %s)"
                                    ]), percentage, attribute, Base$BsConsole.floatToString(value$2), Base$BsConsole.floatToString(count))), React.createElement("div", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "* unique events for ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " may be incomplete for current time range",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "* unique events for %s may be incomplete for current time range"
                                    ]), attribute))) : Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      " of unique ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " events (",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " / ",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* Char_literal */Block.__(12, [
                                                              /* ")" */41,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ]),
                              "%s of unique %s events (%s / %s)"
                            ]), percentage, attribute, Base$BsConsole.floatToString(value$2), Base$BsConsole.floatToString(count))
                ) : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "Insufficient unique event data for ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      " for the current timeframe",
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "Insufficient unique event data for %s for the current timeframe"
                        ]), attribute);
              tmp$3 = React.createElement(CountNormalizationCell$BsConsole.make, {
                    count: value$2 | 0,
                    normalizedPercentageString: count > 0 ? "(" + (percentage + ")") : I18N$BsConsole.get(undefined, "(N/A)"),
                    normalizedTitle: normalizedTitle
                  });
            } else {
              exit$3 = 2;
            }
            if (exit$3 === 2) {
              var value$prime = value$1 !== undefined ? /* Raw */Block.__(0, [/* `Float */[
                      365180284,
                      value$1
                    ]]) : undefined;
              tmp$3 = React.createElement(FormatRenderer$BsConsole.make, {
                    value: value$prime,
                    attribute: attribute,
                    handleChangeUrl: handleChangeUrl,
                    handleAddFilters: handleAddFilters
                  });
            }
            break;
        case /* Bin */2 :
            var bin = shape[1];
            if (bin !== undefined) {
              var attribute$1 = shape[0];
              tmp$3 = React.createElement(Tooltip.default, {
                    placement: "top",
                    children: React.createElement(ButtonBase.default, {
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.padding(Css.px(4)),
                                  /* :: */[
                                    Css.hover(/* :: */[
                                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA10)),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onAggregationExpandChange, /* Bin */Block.__(1, [
                                            factor,
                                            row.factor.value,
                                            attribute$1,
                                            bin
                                          ]));
                            }),
                          disableRipple: true,
                          children: React.createElement(Bin$BsConsole.make, {
                                data: bin,
                                width: 192,
                                height: 28
                              })
                        }),
                    title: I18N$BsConsole.get(undefined, "Click to expand chart")
                  });
            } else {
              tmp$3 = React.createElement(EmptyValue$BsConsole.make, { });
            }
            break;
        case /* Range */8 :
            var range = shape[1];
            var attribute$2 = shape[0];
            tmp$3 = range !== undefined ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.fontFamily("inconsolata"),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(FormatRenderer$BsConsole.make, {
                        value: range[0],
                        attribute: attribute$2,
                        handleChangeUrl: handleChangeUrl,
                        handleAddFilters: handleAddFilters,
                        className: Css.style(/* :: */[
                              Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                              /* [] */0
                            ])
                      }), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.textAlign(/* center */98248149),
                              /* :: */[
                                Css.width(Css.rem(2)),
                                /* :: */[
                                  Css.padding2(/* zero */-789508312, Css.rem(0.5)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.showSkip("-")), React.createElement(FormatRenderer$BsConsole.make, {
                        value: range[1],
                        attribute: attribute$2,
                        handleChangeUrl: handleChangeUrl,
                        handleAddFilters: handleAddFilters,
                        className: Css.style(/* :: */[
                              Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                              /* [] */0
                            ])
                      })) : React.createElement(EmptyValue$BsConsole.make, { });
            break;
        case /* Distribution */9 :
            var value$3 = shape[1];
            if (value$3 !== undefined) {
              var attribute$3 = shape[0];
              tmp$3 = React.createElement(CondensedDistribution$BsConsole.Proto.make, {
                    attribute: attribute$3,
                    distribution: value$3,
                    groupCount: row.count,
                    onExpansion: (function (param) {
                        return Curry._1(onAggregationExpandChange, /* Distribution */Block.__(0, [
                                      factor,
                                      row.factor.value,
                                      attribute$3
                                    ]));
                      })
                  });
            } else {
              tmp$3 = React.createElement(EmptyValue$BsConsole.make, { });
            }
            break;
        default:
          var value$4 = shape[1];
          var attribute$4 = shape[0];
          var exit$4 = 0;
          if (value$4 !== undefined) {
            var exit$5 = 0;
            switch (value$4.tag | 0) {
              case /* Raw */0 :
              case /* None_ */1 :
                  exit$5 = 3;
                  break;
              default:
                exit$4 = 2;
            }
            if (exit$5 === 3) {
              var match$7 = value$4[0];
              if (typeof match$7 === "number" || match$7[0] !== -976970511 || attribute$4 === "fingerprint") {
                exit$4 = 2;
              } else {
                tmp$3 = React.createElement(FormatPopover$BsConsole.WithCopyButton.make, {
                      value: match$7[1],
                      children: React.createElement(FormatRenderer$BsConsole.make, {
                            value: value$4,
                            attribute: attribute$4,
                            handleChangeUrl: handleChangeUrl,
                            handleAddFilters: handleAddFilters,
                            config: config,
                            token: token,
                            aperture: aperture,
                            projectName: projectName,
                            fingerprint: row.factor.value
                          })
                    });
              }
            }
            
          } else {
            exit$4 = 2;
          }
          if (exit$4 === 2) {
            tmp$3 = React.createElement(FormatRenderer$BsConsole.make, {
                  value: value$4,
                  attribute: attribute$4,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilters: handleAddFilters,
                  config: config,
                  token: token,
                  aperture: aperture,
                  projectName: projectName,
                  fingerprint: row.factor.value
                });
          }
          
      }
      return React.createElement(BtTableCell$BsConsole.make, {
                  children: tmp$3,
                  key: row.factor.value + column.name
                });
    }
    
  }
  return React.createElement(BtTableCell$BsConsole.make, {
              children: React.createElement(EmptyValue$BsConsole.make, { }),
              key: row.factor.value + column.name
            });
}

function getTableContainerClassName(rows) {
  var match = rows.length >= 4;
  var containerHeight = match ? (
      IFrame$BsConsole.isIFrame ? Css.style(/* :: */[
              Css.unsafe("maxHeight", "unset !important"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ]) : Css.style(/* :: */[
              Css.unsafe("maxHeight", "calc(100vh - 396px)"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ])
    ) : "";
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.position(Css.relative),
                    /* :: */[
                      Css.selector("th:hover .delete-column", /* :: */[
                            Css.display(/* block */888960333),
                            /* :: */[
                              Css.marginLeft(Css.px(4)),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        Css.selector("th:hover .column-title-deletable", /* :: */[
                              Css.unsafe("width", "calc(100% - 28px)"),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("tr.BtTableRow:hover .group-more", /* :: */[
                                Css.display(/* block */888960333),
                                /* :: */[
                                  Css.marginLeft(Css.px(4)),
                                  /* [] */0
                                ]
                              ]),
                          /* :: */[
                            Css.selector("tr.BtTableRow:hover .group-title", /* :: */[
                                  Css.unsafe("width", "calc(100% - 28px)"),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.selector("tr.BtTableRow:hover .frame-count", /* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.white)),
                                      /* [] */0
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                containerHeight,
                /* [] */0
              ]
            ]);
}

function getRowId(row) {
  return row.factor.attribute + ("-" + (row.factor.value + ("__" + String(row.count))));
}

function useTableData(token, projectName, query, normBy, sort, factor, fold, attributes, uniqueCount) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setTableRemote = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setSortingState = match$1[1];
  var queryForRequest = SummedEvents$BsConsole.Normalize.useQueryForAggTable(attributes, query, normBy);
  var match$2 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, queryForRequest, "explore");
  var setPage = match$2[2];
  var page = match$2[1];
  var paginationRemote = match$2[0];
  React.useEffect((function () {
          if (typeof paginationRemote === "number") {
            if (paginationRemote === /* NotAsked */0) {
              Curry._1(setTableRemote, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setTableRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (paginationRemote.tag) {
            var msg = paginationRemote[0];
            Curry._1(setTableRemote, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else {
            var match = paginationRemote[0];
            var next = match[1];
            var match$1 = match[0];
            var meta = match$1[2];
            var rows = match$1[1];
            var columns = getTableColumns(SummedEvents$BsConsole.Normalize.sanitizeAggColumns(Belt_List.toArray(match$1[0]), Caml_option.some(fold)), factor, normBy, fold);
            var columnOrder = BtTableSessionData$BsConsole.ColumnOrder.get(projectName, /* ExploreAgg */1);
            var columnWidths = BtTableSessionData$BsConsole.ColumnWidth.get(projectName, /* ExploreAgg */1);
            var containerClassName = getTableContainerClassName(Belt_List.toArray(rows));
            Curry._1(setTableRemote, (function (param) {
                    return /* Success */Block.__(0, [{
                                rows: Belt_List.toArray(rows),
                                columns: columns,
                                columnOrder: columnOrder,
                                page: page,
                                setPage: (function (page) {
                                    return Curry._1(setPage, (function (param) {
                                                  return page;
                                                }));
                                  }),
                                meta: meta,
                                loading: typeof next === "number" ? next !== 0 : false,
                                columnWidths: columnWidths,
                                containerClassName: containerClassName
                              }]);
                  }));
          }
          
        }), /* tuple */[
        paginationRemote,
        normBy,
        queryForRequest,
        uniqueCount
      ]);
  React.useEffect((function () {
          var sortingState = getTableSortingState(sort);
          Curry._1(setSortingState, (function (param) {
                  return sortingState;
                }));
          
        }), /* tuple */[
        Curry._1(Crdb$BsConsole.Sort.getAttribute, sort),
        Curry._1(Crdb$BsConsole.Sort.getAggString, sort),
        SortOrder$BsConsole.Ordering.toString(Curry._1(Crdb$BsConsole.Sort.getOrdering, sort))
      ]);
  return /* tuple */[
          match[0],
          match$1[0]
        ];
}

exports.factorColumn = factorColumn;
exports.countColumn = countColumn;
exports.activityColumn = activityColumn;
exports.orderingToDirection = orderingToDirection;
exports.directionToOrdering = directionToOrdering;
exports.rangeToColumnName = rangeToColumnName;
exports.isRangeColumn = isRangeColumn;
exports.createFold = createFold;
exports.getAggregationFromColumnName = getAggregationFromColumnName;
exports.getSortingStateFromTableSort = getSortingStateFromTableSort;
exports.getTableSortingState = getTableSortingState;
exports.onSortChange = onSortChange;
exports.getColumnAlignment = getColumnAlignment;
exports.getTableColumns = getTableColumns;
exports.renderHeaderCell = renderHeaderCell;
exports.renderStubHeader = renderStubHeader;
exports.renderExploreAggDataCell = renderExploreAggDataCell;
exports.getTableContainerClassName = getTableContainerClassName;
exports.getRowId = getRowId;
exports.useTableData = useTableData;
/* Css Not a pure module */
