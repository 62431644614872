// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Text$BsConsole = require("../Text.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var IFrame$BsConsole = require("../IFrame.js");
var BtPaper$BsConsole = require("../BtPaper.js");
var Current$BsConsole = require("../saved-queries/Current.js");
var ViewNav$BsConsole = require("../ViewNav.js");
var BinModal$BsConsole = require("../BinModal.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Services$BsConsole = require("../Services.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var AlertsUtils$BsConsole = require("../alerts/AlertsUtils.js");
var ViewLoading$BsConsole = require("../ViewLoading.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var FactorSelect$BsConsole = require("../factorSelect.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var MetricsValue$BsConsole = require("../MetricsValue.js");
var Paper = require("@material-ui/core/Paper");
var Save = require("@material-ui/icons/Save");
var ExporterUtils$BsConsole = require("../metrics-exporter/ExporterUtils.js");
var Button = require("@material-ui/core/Button");
var BacktraceHooks$BsConsole = require("../BacktraceHooks.js");
var BtEnhancedTable$BsConsole = require("../BtEnhancedTable.js");
var ExploreExportAs$BsConsole = require("./ExploreExportAs.js");
var HideFeatureGate$BsConsole = require("../HideFeatureGate.js");
var DrilldownContext$BsConsole = require("../DrilldownContext.js");
var DistributionModal$BsConsole = require("../DistributionModal.js");
var ExploreModeSelect$BsConsole = require("../ExploreModeSelect.js");
var NormalizeBySelect$BsConsole = require("../NormalizeBySelect.js");
var SavedQueriesModel$BsConsole = require("../saved-queries/SavedQueriesModel.js");
var BtTableSessionData$BsConsole = require("../BtTableSessionData.js");
var ExploreAggregateData$BsConsole = require("../ExploreAggregateData.js");
var SavedQueriesContainer$BsConsole = require("../saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("../ErrorsOverTimeBinChart.js");
var UniqueAggregationsTask$BsConsole = require("../UniqueAggregationsTask.js");
var DistributionValueSelect$BsConsole = require("../DistributionValueSelect.js");
var SavedQueryBookmarkSelect$BsConsole = require("../saved-queries/SavedQueryBookmarkSelect.js");

function makeQuery(aperture, factor, fold, sort, havings) {
  return /* Aggregate */Block.__(0, [
            aperture,
            Caml_option.some(factor),
            ExploreAggregateData$BsConsole.createFold(fold, aperture),
            Caml_option.some(sort),
            undefined,
            havings
          ]);
}

function getFoldTuples(fold) {
  return Belt_Array.map(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg) {
                return /* tuple */[
                        Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg),
                        Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg))
                      ];
              }));
}

function ProjectQueryBuilder(Props) {
  var config = Props.config;
  var attributes = Props.attributes;
  var normBy = Props.normBy;
  var sort = Props.sort;
  var fold = Props.fold;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var factor = Props.factor;
  var projectName = Props.projectName;
  var universe = Props.universe;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var metricsAttributes = Props.metricsAttributes;
  var route = Props.route;
  var highlightExporter = Props.highlightExporter;
  var match = React.useState((function () {
          
        }));
  var setModal = match[1];
  var modal = match[0];
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = makeQuery(apertureForQuery, factor, fold, sort, Caml_option.some(havings));
  var stabilityScore = MetricsValue$BsConsole.use(aperture, normBy, token, projectName, metricsAttributes, factor);
  var uniqueCount = UniqueAggregationsTask$BsConsole.useCount(query, projectName, config, token, factor);
  var match$1 = ExploreAggregateData$BsConsole.useTableData(token, projectName, query, normBy, sort, factor, fold, attributes, uniqueCount);
  var tableDataRemote = match$1[0];
  BacktraceHooks$BsConsole.useSortRecover(sort, query, (function (param) {
          return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                        projectName,
                        Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), Crdb$BsConsole.Sort.defaultAggregate, normBy, undefined, undefined)
                      ]));
        }));
  React.useEffect((function () {
          BtTableSessionData$BsConsole.Query.set(projectName, query, /* ExploreAgg */1);
          
        }), /* tuple */[
        query,
        projectName
      ]);
  var setFold = function (f) {
    return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                  projectName,
                  Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, undefined, Caml_option.some(f), sort, normBy, undefined, undefined)
                ]));
  };
  var setSort = function (s) {
    return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                  projectName,
                  Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), s, normBy, undefined, undefined)
                ]));
  };
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                  projectName,
                  Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture$prime), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), sort, normBy, undefined, undefined)
                ]));
  };
  var mode = SavedQueriesModel$BsConsole.modeOfStringExn("aggregate");
  var queryForSavedQueryContainer = makeQuery(aperture, factor, fold, sort, Caml_option.some(havings));
  var match$2 = Services$BsConsole.getEndpoint(undefined)(config.services, ExporterUtils$BsConsole.servicename);
  var match$3 = Services$BsConsole.getEndpoint(undefined)(config.services, AlertsUtils$BsConsole.servicename);
  var tmp;
  var exit = 0;
  if (match$2 !== undefined || match$3 !== undefined) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = TeamsUtils$BsConsole.isAtLeastMemberRole(config, projectName) ? React.createElement(HideFeatureGate$BsConsole.make, {
            shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* ExploreExportAs */11),
            children: React.createElement(ExploreExportAs$BsConsole.make, {
                  config: config,
                  token: token,
                  exporterEndpoint: match$2,
                  alertsEndpoint: match$3,
                  universeName: config.universe.name,
                  projectName: projectName,
                  factor: factor,
                  fold: fold,
                  aperture: aperture,
                  sort: sort,
                  havings: havings,
                  highlightExporter: highlightExporter,
                  handleChangeUrl: handleChangeUrl
                })
          }) : null;
  }
  var tmp$1;
  if (typeof tableDataRemote === "number") {
    tmp$1 = tableDataRemote === /* NotAsked */0 ? null : React.createElement(ViewLoading$BsConsole.Jsx3.make, {
            label: I18N$BsConsole.get(undefined, "Fetching aggregation data")
          });
  } else if (tableDataRemote.tag) {
    tmp$1 = React.createElement(Col2$BsConsole.make, {
          alignItems: /* center */98248149,
          justifyContent: /* spaceAround */-485895757,
          className: Css.style(/* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.minHeight(Css.px(400)),
                  /* :: */[
                    Css.paddingBottom(Css.px(8)),
                    /* :: */[
                      Css.fontSize(Css.px(20)),
                      /* [] */0
                    ]
                  ]
                ]
              ]),
          children: I18N$BsConsole.dynamic(tableDataRemote[0])
        });
  } else {
    var match$4 = tableDataRemote[0];
    var meta = match$4.meta;
    var rows = match$4.rows;
    var match$5 = rows.length;
    if (match$5 !== 0) {
      var arg = Belt_Option.getWithDefault(meta.instances, 1);
      var arg$1 = function (modal) {
        return Curry._1(setModal, (function (param) {
                      return modal;
                    }));
      };
      var arg$2 = function (columnAggregation) {
        if (columnAggregation === undefined) {
          return ;
        }
        var agg = Caml_option.valFromOption(columnAggregation);
        var aggToDelete = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg$prime) {
                if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg$prime)) {
                  return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg$prime));
                } else {
                  return false;
                }
              }));
        if (aggToDelete !== undefined) {
          return setFold(Curry._2(Crdb$BsConsole.Fold.remove, Caml_option.valFromOption(aggToDelete), fold));
        }
        
      };
      var tmp$2 = {
        columns: match$4.columns,
        rows: rows,
        getRowId: ExploreAggregateData$BsConsole.getRowId,
        renderDataCell: (function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19) {
              return ExploreAggregateData$BsConsole.renderExploreAggDataCell(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19);
            };
            return (function (param) {
                var func$1 = Curry._1(func, param);
                return (function (param) {
                    var func$2 = Curry._1(func$1, param);
                    return (function (param) {
                        return Curry.app(func$2, [
                                    param,
                                    factor,
                                    token,
                                    projectName,
                                    handleChangeUrl,
                                    handleAddFilters,
                                    apertureForQuery,
                                    havings,
                                    universe,
                                    arg,
                                    stabilityScore,
                                    normBy,
                                    sort,
                                    arg$1,
                                    config,
                                    uniqueCount,
                                    undefined
                                  ]);
                      });
                  });
              });
          }),
        renderHeaderCell: (function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5) {
              return ExploreAggregateData$BsConsole.renderHeaderCell(param, param$1, param$2, param$3, param$4, param$5);
            };
            return (function (param) {
                var func$1 = Curry._1(func, param);
                return (function (param) {
                    var func$2 = Curry._1(func$1, param);
                    return (function (param) {
                        return Curry._3(func$2, param, arg$2, setSort);
                      });
                  });
              });
          }),
        renderStubHeader: (function (param) {
            return ExploreAggregateData$BsConsole.renderStubHeader(query, attributes, (function (agg) {
                          return setFold(Curry._2(Crdb$BsConsole.Fold.add, agg, fold));
                        }), param);
          }),
        stubMinWidth: 175,
        onSortChange: (function (param) {
            return ExploreAggregateData$BsConsole.onSortChange(setSort, param);
          }),
        onColumnOrderChange: (function (columnOrder) {
            return BtTableSessionData$BsConsole.ColumnOrder.set(projectName, columnOrder, /* ExploreAgg */1);
          }),
        resizeableColumns: true,
        draggableColumns: true,
        page: match$4.page,
        onChangePage: match$4.setPage,
        totalRows: Belt_Option.getWithDefault(meta.groups, 0),
        loading: match$4.loading,
        containerClassName: match$4.containerClassName,
        onColumnWidthChange: (function (columnWidths) {
            return BtTableSessionData$BsConsole.ColumnWidth.set(projectName, columnWidths, /* ExploreAgg */1);
          })
      };
      var tmp$3 = match$1[1];
      if (tmp$3 !== undefined) {
        tmp$2.sortingState = Caml_option.valFromOption(tmp$3);
      }
      var tmp$4 = IFrame$BsConsole.isIFrame ? [ExploreAggregateData$BsConsole.factorColumn] : [
          ExploreAggregateData$BsConsole.factorColumn,
          ExploreAggregateData$BsConsole.countColumn
        ];
      if (tmp$4 !== undefined) {
        tmp$2.fixedLeftColumns = Caml_option.valFromOption(tmp$4);
      }
      if (match$4.columnOrder !== undefined) {
        tmp$2.columnOrder = Caml_option.valFromOption(match$4.columnOrder);
      }
      if (match$4.columnWidths !== undefined) {
        tmp$2.columnWidths = Caml_option.valFromOption(match$4.columnWidths);
      }
      tmp$1 = React.createElement(DrilldownContext$BsConsole.Provider.make, {
            handleChangeUrl: handleChangeUrl,
            handleAddFilters: handleAddFilters,
            children: React.createElement(BtEnhancedTable$BsConsole.make, tmp$2)
          });
    } else {
      tmp$1 = React.createElement(Col2$BsConsole.make, {
            alignItems: /* center */98248149,
            justifyContent: /* center */98248149,
            className: Css.style(/* :: */[
                  Css.minHeight(Css.px(175)),
                  /* :: */[
                    Css.padding(Css.rem(2)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* [] */0
                    ]
                  ]
                ]),
            children: Caml_int64.gt(meta.tx, Caml_int64.one) ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading1 */0,
                        children: Text$BsConsole.get(/* Explore_Empty_Title */6)
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        children: Text$BsConsole.get(/* Explore_Empty_Description */7)
                      })) : React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading1 */0,
                        children: Text$BsConsole.get(/* Welcome */8)
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        children: Text$BsConsole.get(/* No_Crashes_Uploaded */9)
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.rem(1)),
                              /* [] */0
                            ]),
                        onClick: (function (_event) {
                            return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                          projectName,
                                          {
                                            doc: undefined
                                          }
                                        ]));
                          }),
                        children: I18N$BsConsole.show(undefined, "Get started")
                      }))
          });
    }
  }
  var tmp$5;
  if (modal !== undefined) {
    if (modal.tag) {
      var factor$1 = modal[0];
      var factorName = factor$1 ? factor$1[0] : "fingerprint";
      tmp$5 = React.createElement(BinModal$BsConsole.Jsx3.make, {
            onClose: (function (param) {
                return Curry._1(setModal, (function (param) {
                              
                            }));
              }),
            attribute: modal[2],
            factorName: factorName,
            factorValue: modal[1],
            data: modal[3]
          });
    } else {
      var factor$2 = modal[0];
      var factorName$1;
      if (factor$2) {
        var name = factor$2[0];
        factorName$1 = name === "NONE" ? "" : name;
      } else {
        factorName$1 = "fingerprint";
      }
      tmp$5 = React.createElement(DistributionModal$BsConsole.Jsx3.make, {
            onClose: (function (param) {
                return Curry._1(setModal, (function (param) {
                              
                            }));
              }),
            token: token,
            aperture: aperture,
            attribute: modal[2],
            handleTask: handleTask,
            projectName: projectName,
            handleAddFilters: handleAddFilters,
            handleChangeUrl: handleChangeUrl,
            factorName: factorName$1,
            factorValue: modal[1]
          });
    }
  } else {
    tmp$5 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(ViewNav$BsConsole.make, {
                      config: config,
                      aperture: aperture,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName,
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                            /* [] */0
                          ])
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                            /* :: */[
                              Css.padding2(Css.px(4), Css.px(16)),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          justifyContent: /* spaceBetween */516682146,
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]),
                          children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                                name: projectName,
                                token: token,
                                handleTask: handleTask,
                                handleChangeUrl: handleChangeUrl,
                                config: config,
                                query: queryForSavedQueryContainer,
                                mode: mode,
                                normBy: normBy,
                                renderTrigger: (function (param) {
                                    var queries = param.queries;
                                    var handleOpen = param.handleOpen;
                                    var disabled = Belt_Option.getWithDefault(Belt_Option.map(queries, (function (savedQueries) {
                                                var mode = SavedQueriesModel$BsConsole.modeOfStringExn("aggregate");
                                                var query = makeQuery(aperture, factor, fold, sort, Caml_option.some(havings));
                                                var match = Current$BsConsole.get(mode, query, savedQueries);
                                                if (match) {
                                                  return true;
                                                } else {
                                                  return false;
                                                }
                                              })), false);
                                    var tmp = {
                                      projectBookmarkedViews: param.projectBookmarkedViews,
                                      userBookmarkedViews: param.userBookmarkedViews,
                                      projectDefault: param.projectDefault,
                                      userDefault: param.userDefault,
                                      queries: queries,
                                      handleOpen: (function (param) {
                                          return Curry._2(handleOpen, undefined, undefined);
                                        }),
                                      handleChangeUrl: handleChangeUrl,
                                      currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("aggregate"),
                                      projectName: projectName
                                    };
                                    if (queryForSavedQueryContainer !== undefined) {
                                      tmp.query = Caml_option.valFromOption(queryForSavedQueryContainer);
                                    }
                                    return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, tmp), React.createElement(Button.default, {
                                                    color: "primary",
                                                    className: Css.style(/* :: */[
                                                          Css.marginRight(Css.px(8)),
                                                          /* :: */[
                                                            Css.display(/* flex */-1010954439),
                                                            /* :: */[
                                                              Css.alignItems(/* center */98248149),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]),
                                                    disabled: disabled,
                                                    onClick: (function (param) {
                                                        MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ViewManagerSaveView */120]));
                                                        return Curry._2(handleOpen, undefined, /* Save */0);
                                                      }),
                                                    children: null
                                                  }, React.createElement(Save.default, {
                                                        className: Css.style(/* :: */[
                                                              Css.marginRight(Css.px(8)),
                                                              /* [] */0
                                                            ]),
                                                        color: "inherit"
                                                      }), I18N$BsConsole.show(undefined, "Save view")));
                                  })
                              })
                        }), tmp)), React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          "explore-table-paper",
                          /* :: */[
                            "aggs__container",
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.padding4(Css.px(16), Css.px(16), /* zero */-789508312, Css.px(16)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                                attribute: "error.type",
                                label: "Error type",
                                aperture: aperture,
                                token: token,
                                projectName: projectName,
                                handleSetAperture: handleSetAperture,
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(8)),
                                      /* [] */0
                                    ]),
                                icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                                      color: "inherit",
                                      fontSize: "inherit"
                                    }),
                                view: "list"
                              }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                                attribute: "uname.sysname",
                                label: "Platform",
                                aperture: aperture,
                                token: token,
                                projectName: projectName,
                                handleSetAperture: handleSetAperture,
                                icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                                      color: "inherit",
                                      fontSize: "inherit"
                                    }),
                                view: "list"
                              }) : null), React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          className: "walkthrough--explore--groupings-right",
                          children: null
                        }, React.createElement(FactorSelect$BsConsole.Jsx3.make, {
                              onFactorUpdate: (function (factor) {
                                  var nextFactor = factor === "fingerprint" ? /* Identity */0 : /* Custom */[factor];
                                  return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                                                projectName,
                                                Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, nextFactor, Caml_option.some(fold), sort, undefined, undefined, undefined)
                                              ]));
                                }),
                              currentFactor: factor ? factor[0] : "fingerprint",
                              attributes: attributes,
                              className: Css.style(/* :: */[
                                    Css.marginRight(Css.rem(0.5)),
                                    /* [] */0
                                  ])
                            }), React.createElement(NormalizeBySelect$BsConsole.make, {
                              aperture: aperture,
                              token: token,
                              projectName: projectName,
                              view: "explore-aggregate",
                              normBy: normBy,
                              handleSetMetricGroup: (function (normBy) {
                                  return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                                                projectName,
                                                Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), sort, normBy, undefined, undefined)
                                              ]));
                                }),
                              metricsAttributes: metricsAttributes,
                              config: config,
                              className: Css.style(/* :: */[
                                    Css.marginRight(Css.px(8)),
                                    /* [] */0
                                  ])
                            }), React.createElement(ExploreModeSelect$BsConsole.Jsx3.make, {
                              projectName: projectName,
                              aperture: aperture,
                              currentView: /* Aggregate */-834597217,
                              handleChangeUrl: handleChangeUrl
                            }))), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                      token: token,
                      projectName: projectName,
                      aperture: apertureForQuery,
                      onRangeChange: (function (range) {
                          return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                                        projectName,
                                        Route$BsConsole.getQueryBuilderParams(Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture)), Caml_option.some(havings), projectName, undefined, Caml_option.some(fold), sort, normBy, undefined, undefined)
                                      ]));
                        }),
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                            /* [] */0
                          ]),
                      normBy: normBy,
                      metricsAttributes: metricsAttributes
                    }), tmp$1), tmp$5);
}

var make = ProjectQueryBuilder;

exports.makeQuery = makeQuery;
exports.getFoldTuples = getFoldTuples;
exports.make = make;
/* Css Not a pure module */
