// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("../bs-crdb-response/src/Parse.js");
var Colors$BsConsole = require("../Colors.js");
var FetchCrdb$BsConsole = require("../FetchCrdb.js");
var Charts__Bin$BsConsole = require("../charts/Charts__Bin.js");
var FetchDescribe$BsConsole = require("../FetchDescribe.js");
var PrDistribution$BsConsole = require("../progressive-reveal/PrDistribution.js");

var component = ReasonReact.statelessComponent("DictionaryAttributes");

function make(handleTask, token, name, query, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, FetchCrdb$BsConsole.make(handleTask, token, name, query, (function (remote, _query$prime, param) {
                                return Curry._1(children, remote);
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Fetch = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("ExpandedAggregation");

function make$1(maybePreferedAggregation, onClose, factor, handleChangeUrl, handleAddFilters, aperture, attribute, handleTask, token, name, dialogWidth, dialogMaxHeight, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.make(handleTask, token, name, (function (remote, param) {
                                          if (typeof remote === "number") {
                                            if (remote === /* NotAsked */0) {
                                              return I18N$BsConsole.show(undefined, "notasked");
                                            } else {
                                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
                                            }
                                          }
                                          if (remote.tag) {
                                            return I18N$BsConsole.show(undefined, "failure");
                                          }
                                          var attribute$1 = Belt_List.getBy(remote[0][0], (function (param) {
                                                  return param.name === attribute;
                                                }));
                                          if (attribute$1 === undefined) {
                                            return I18N$BsConsole.show(undefined, "failure");
                                          }
                                          var match = Belt_List.getBy(attribute$1.aggregate, (function (a) {
                                                  return a === "bin";
                                                }));
                                          var exit = 0;
                                          if (!(maybePreferedAggregation !== undefined && !(typeof maybePreferedAggregation === "number" || !(maybePreferedAggregation.tag === /* Distribution */1 && maybePreferedAggregation[0] === undefined)))) {
                                            exit = 2;
                                          }
                                          if (exit === 2 && match !== undefined) {
                                            return ReasonReact.element(undefined, undefined, make(handleTask, token, name, /* Aggregate */Block.__(0, [
                                                              aperture,
                                                              undefined,
                                                              Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                        attribute$1.name,
                                                                        /* Bin */Block.__(0, [
                                                                            32,
                                                                            undefined
                                                                          ])
                                                                      ]), Crdb$BsConsole.Fold.empty),
                                                              undefined,
                                                              undefined,
                                                              undefined
                                                            ]), (function (remote) {
                                                              if (typeof remote === "number") {
                                                                if (remote === /* NotAsked */0) {
                                                                  return I18N$BsConsole.show(undefined, "not asked");
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
                                                                }
                                                              }
                                                              if (remote.tag) {
                                                                return I18N$BsConsole.show(undefined, "failure");
                                                              }
                                                              var json = remote[0];
                                                              var columns = Parse$BsConsole.parseColumns(json);
                                                              var maybeGroup = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
                                                                        "fingerprint",
                                                                        undefined
                                                                      ], columns, json));
                                                              if (maybeGroup === undefined) {
                                                                return I18N$BsConsole.show(undefined, "INVARIANT");
                                                              }
                                                              var getter = function (param, param$1) {
                                                                return Group$BsConsole.getAggregation(columns, maybeGroup, param, param$1);
                                                              };
                                                              var maybeBin = Group$BsConsole.unwrapBin(getter(attribute$1.name, /* Bin */2));
                                                              if (maybeBin !== undefined) {
                                                                return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey9)),
                                                                                    /* :: */[
                                                                                      Css.selector("& .vx-axis-tick line", /* :: */[
                                                                                            Css.display(/* none */-922086728),
                                                                                            /* [] */0
                                                                                          ]),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Charts__Bin$BsConsole.make(undefined, undefined, undefined, undefined, (((dialogMaxHeight - 48 | 0) - 48 | 0) - 48 | 0) - 48 | 0, dialogWidth - 48 | 0, undefined, undefined, false, undefined, 50, undefined, 50, undefined, undefined, undefined, maybeBin, []))]));
                                                              } else {
                                                                return I18N$BsConsole.show(undefined, "INVARIANT@");
                                                              }
                                                            })));
                                          }
                                          return ReasonReact.element(undefined, undefined, make(handleTask, token, name, /* Aggregate */Block.__(0, [
                                                            aperture,
                                                            undefined,
                                                            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                      attribute$1.name,
                                                                      /* Distribution */Block.__(1, [50])
                                                                    ]), Crdb$BsConsole.Fold.empty),
                                                            undefined,
                                                            undefined,
                                                            undefined
                                                          ]), (function (remote) {
                                                            if (typeof remote === "number") {
                                                              if (remote === /* NotAsked */0) {
                                                                return I18N$BsConsole.show(undefined, "not asked");
                                                              } else {
                                                                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
                                                              }
                                                            }
                                                            if (remote.tag) {
                                                              return I18N$BsConsole.show(undefined, "failure");
                                                            }
                                                            var json = remote[0];
                                                            var columns = Parse$BsConsole.parseColumns(json);
                                                            var maybeGroup = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
                                                                      Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor),
                                                                      undefined
                                                                    ], columns, json));
                                                            if (maybeGroup === undefined) {
                                                              return I18N$BsConsole.show(undefined, "INVARIANT");
                                                            }
                                                            var getter = function (param, param$1) {
                                                              return Group$BsConsole.getAggregation(columns, maybeGroup, param, param$1);
                                                            };
                                                            var maybeDistribution = Group$BsConsole.unwrapDistribution(getter(attribute$1.name, /* Distribution */1));
                                                            if (maybeDistribution !== undefined) {
                                                              return ReasonReact.element(undefined, undefined, PrDistribution$BsConsole.make(onClose, attribute$1.name, maybeDistribution, handleAddFilters, handleChangeUrl, []));
                                                            } else {
                                                              return I18N$BsConsole.show(undefined, "INVARIANT");
                                                            }
                                                          })));
                                        })))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.Fetch = Fetch;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
