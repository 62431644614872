// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function SettingsBilling$BillingUsage(Props) {
  Props.limitsRemote;
  return null;
}

function make(limitsRemote, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SettingsBilling$BillingUsage, {
              limitsRemote: limitsRemote
            }, _children);
}

var Re = {
  make: make
};

var BillingUsage = {
  make: SettingsBilling$BillingUsage,
  Re: Re
};

function SettingsBilling(Props) {
  Props.handleChangeUrl;
  Props.config;
  Props.token;
  Props.handleTask;
  return null;
}

function make$1(handleTask, token, config, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SettingsBilling, {
              handleTask: handleTask,
              token: token,
              config: config,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Re$1 = {
  make: make$1
};

var make$2 = SettingsBilling;

exports.BillingUsage = BillingUsage;
exports.make = make$2;
exports.Re = Re$1;
/* ReasonReactCompat Not a pure module */
