// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");

var button = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* :: */[
        Css.unsafe("padding", "0 !important"),
        /* :: */[
          Css.unsafe("minHeight", "unset !important"),
          /* :: */[
            Css.unsafe("borderRadius", "16px !important"),
            /* :: */[
              Css.unsafe("textTransform", "unset !important"),
              /* :: */[
                Css.focus(/* :: */[
                      Css.unsafe("background", Colors$BsConsole.octothorpe(Colors$BsConsole.whiteA30) + " !important"),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var chipRoot = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* :: */[
        Css.unsafe("background", "transparent !important"),
        /* :: */[
          Css.unsafe("border", "1px solid currentColor !important"),
          /* :: */[
            Css.unsafe("cursor", "pointer !important"),
            /* :: */[
              Css.unsafe("height", "unset !important"),
              /* :: */[
                Css.hover(/* :: */[
                      Css.important(Css.background(Css.hex(Colors$BsConsole.blueBackground))),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.focus(/* :: */[
                        Css.important(Css.background(Css.hex(Colors$BsConsole.blueBackground))),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.unsafe("marginLeft", "0 !important"),
      /* :: */[
        Css.unsafe("color", "currentColor !important"),
        /* [] */0
      ]
    ]);

var labelRoot = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* [] */0
      ]
    ]);

var component = ReasonReact.statelessComponent("Chip-BsConsole__Add");

function make(label, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.showSkip(label)), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        undefined,
                                        button,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                    undefined,
                                                    {
                                                      icon: icon,
                                                      root: chipRoot
                                                    },
                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                                  root: labelRoot
                                                                }, undefined, [I18N$BsConsole.showSkip(label)]))),
                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    []
                                                  ]))]
                                      ]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Add = {
  button: button,
  chipRoot: chipRoot,
  icon: icon,
  labelRoot: labelRoot,
  component: component,
  make: make
};

var labelRoot$1 = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* :: */[
          Css.maxWidth(Css.px(150)),
          /* :: */[
            Css.overflow(Css.hidden),
            /* [] */0
          ]
        ]
      ]
    ]);

var filterValue = Css.style(/* :: */[
      Css.overflow(Css.hidden),
      /* :: */[
        Css.whiteSpace(Css.nowrap),
        /* :: */[
          Css.unsafe("textOverflow", "ellipsis"),
          /* [] */0
        ]
      ]
    ]);

var component$1 = ReasonReact.statelessComponent("Chip-BsConsole__Filter");

function make$1(attribute, operator, value, onDelete, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(attribute + ("_" + (operator + ("__" + (value + "___Tooltip")))), undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Edit filter")), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element(attribute + ("_" + (operator + ("__" + value))), undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                        undefined,
                                        undefined,
                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                      root: labelRoot$1
                                                    }, undefined, [
                                                      Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                /* String */Block.__(2, [
                                                                    /* No_padding */0,
                                                                    /* Char_literal */Block.__(12, [
                                                                        /* " " */32,
                                                                        /* String */Block.__(2, [
                                                                            /* No_padding */0,
                                                                            /* End_of_format */0
                                                                          ])
                                                                      ])
                                                                  ]),
                                                                "%s %s"
                                                              ]), attribute, operator),
                                                      React.createElement("span", {
                                                            className: filterValue
                                                          }, I18N$BsConsole.showSkip(" " + value))
                                                    ]))),
                                        undefined,
                                        undefined,
                                        onDelete,
                                        undefined,
                                        /* Secondary */67972948,
                                        []
                                      ]))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Filter = {
  labelRoot: labelRoot$1,
  filterValue: filterValue,
  component: component$1,
  make: make$1
};

var labelRoot$2 = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* :: */[
          Css.maxWidth(Css.px(150)),
          /* :: */[
            Css.overflow(Css.hidden),
            /* [] */0
          ]
        ]
      ]
    ]);

var filterValue$1 = Css.style(/* :: */[
      Css.overflow(Css.hidden),
      /* :: */[
        Css.whiteSpace(Css.nowrap),
        /* :: */[
          Css.unsafe("textOverflow", "ellipsis"),
          /* [] */0
        ]
      ]
    ]);

var component$2 = ReasonReact.statelessComponent("Chip-BsConsole__TriageFilter");

function make$2(attribute, operator, value, onDelete, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(attribute + ("_" + (operator + ("__" + (value + "___Tooltip")))), undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Edit filter")), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element(attribute + ("_" + (operator + ("__" + value))), undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                        undefined,
                                        undefined,
                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                      root: labelRoot$2
                                                    }, undefined, [
                                                      Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                                /* String_literal */Block.__(11, [
                                                                    "Triage ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* Char_literal */Block.__(12, [
                                                                            /* " " */32,
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ])
                                                                      ])
                                                                  ]),
                                                                "Triage %s %s"
                                                              ]), attribute, operator),
                                                      React.createElement("span", {
                                                            className: filterValue$1
                                                          }, I18N$BsConsole.showSkip(" " + value))
                                                    ]))),
                                        undefined,
                                        undefined,
                                        onDelete,
                                        undefined,
                                        /* Primary */-791844958,
                                        []
                                      ]))]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var TriageFilter = {
  labelRoot: labelRoot$2,
  filterValue: filterValue$1,
  component: component$2,
  make: make$2
};

var labelRoot$3 = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* [] */0
      ]
    ]);

var component$3 = ReasonReact.statelessComponent("Chip-BsConsole__Filter");

function make$3(attribute, aggregation, onDelete, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(attribute + ("_" + (aggregation + "___Tooltip")), undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Edit aggregation")), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element(attribute + ("_" + aggregation), undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                        undefined,
                                        undefined,
                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                      root: labelRoot$3
                                                    }, undefined, [Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                /* String */Block.__(2, [
                                                                    /* No_padding */0,
                                                                    /* Char_literal */Block.__(12, [
                                                                        /* " " */32,
                                                                        /* String */Block.__(2, [
                                                                            /* No_padding */0,
                                                                            /* End_of_format */0
                                                                          ])
                                                                      ])
                                                                  ]),
                                                                "%s %s"
                                                              ]), attribute, aggregation)]))),
                                        undefined,
                                        undefined,
                                        onDelete,
                                        undefined,
                                        /* Primary */-791844958,
                                        []
                                      ]))]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var Aggregate = {
  labelRoot: labelRoot$3,
  component: component$3,
  make: make$3
};

var labelRoot$4 = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* :: */[
          Css.overflow(Css.hidden),
          /* [] */0
        ]
      ]
    ]);

var typography = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* :: */[
          Css.unsafe("lineHeight", "24px !important"),
          /* :: */[
            Css.overflow(Css.hidden),
            /* [] */0
          ]
        ]
      ]
    ]);

var component$4 = ReasonReact.statelessComponent("Chip-BsConsole__Select__Default");

function make$4(attribute, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(attribute + "___Tooltip", undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Edit attribute column")), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element("__" + attribute, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                        undefined,
                                        undefined,
                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                      root: labelRoot$4
                                                    }, undefined, [I18N$BsConsole.showSkip(attribute)]))),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        /* Primary */-791844958,
                                        []
                                      ]))]));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var Default = {
  typography: typography,
  component: component$4,
  make: make$4
};

var component$5 = ReasonReact.statelessComponent("Chip-BsConsole__Select");

function make$5(attribute, onDelete, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(attribute + "___Tooltip", undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "Edit attribute column")), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element("__" + attribute, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                        undefined,
                                        undefined,
                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                      root: labelRoot$4
                                                    }, undefined, [I18N$BsConsole.showSkip(attribute)]))),
                                        undefined,
                                        undefined,
                                        onDelete,
                                        undefined,
                                        /* Primary */-791844958,
                                        []
                                      ]))]));
            }),
          initialState: component$5.initialState,
          retainedProps: component$5.retainedProps,
          reducer: component$5.reducer,
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var Select = {
  labelRoot: labelRoot$4,
  Default: Default,
  component: component$5,
  make: make$5
};

var labelRoot$5 = Css.style(/* :: */[
      Css.unsafe("color", "inherit !important"),
      /* :: */[
        Css.unsafe("fontSize", "12px !important"),
        /* :: */[
          Css.maxWidth(Css.px(150)),
          /* :: */[
            Css.overflow(Css.hidden),
            /* [] */0
          ]
        ]
      ]
    ]);

var flexStyle = Css.style(/* :: */[
      Css.unsafe("display", "flex"),
      /* :: */[
        Css.unsafe("flexDirection", "column"),
        /* [] */0
      ]
    ]);

var component$6 = RR$BsConsole.statelessComponent("Chip-BsConsole__NMore");

function make$6(n, chips, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            style: {
                                              color: "currentColor",
                                              display: "flex",
                                              marginRight: "16px",
                                              alignItems: "center"
                                            },
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                        className: Styles$BsConsole.$$Text.tooltipTitle
                                                      }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                /* String_literal */Block.__(11, [
                                                                    "view ",
                                                                    /* Int */Block.__(4, [
                                                                        /* Int_d */0,
                                                                        /* No_padding */0,
                                                                        /* No_precision */0,
                                                                        /* String_literal */Block.__(11, [
                                                                            " more chips",
                                                                            /* End_of_format */0
                                                                          ])
                                                                      ])
                                                                  ]),
                                                                "view %d more chips"
                                                              ]), n)), undefined, undefined, 1500, undefined, undefined, [ReasonReact.element(String(n) + "more", undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                              undefined,
                                                              undefined,
                                                              Caml_option.some(ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, {
                                                                            root: labelRoot$5
                                                                          }, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                      /* String_literal */Block.__(11, [
                                                                                          "+ ",
                                                                                          /* Int */Block.__(4, [
                                                                                              /* Int_d */0,
                                                                                              /* No_padding */0,
                                                                                              /* No_precision */0,
                                                                                              /* String_literal */Block.__(11, [
                                                                                                  " more",
                                                                                                  /* End_of_format */0
                                                                                                ])
                                                                                            ])
                                                                                        ]),
                                                                                      "+ %d more"
                                                                                    ]), n)]))),
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              /* Primary */-791844958,
                                                              []
                                                            ]))])));
                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                return React.createElement("div", {
                                            className: flexStyle
                                          }, chips);
                              }), undefined, []));
            }),
          initialState: component$6.initialState,
          retainedProps: component$6.retainedProps,
          reducer: component$6.reducer,
          jsElementWrapped: component$6.jsElementWrapped
        };
}

var NMore = {
  labelRoot: labelRoot$5,
  flexStyle: flexStyle,
  component: component$6,
  make: make$6
};

exports.Add = Add;
exports.Filter = Filter;
exports.TriageFilter = TriageFilter;
exports.Aggregate = Aggregate;
exports.Select = Select;
exports.NMore = NMore;
/* button Not a pure module */
