'use strict';

var NodeRe = require("../nodes/NodeRe.js");
var ElementRe = require("../nodes/ElementRe.js");
var DomTypesRe = require("../DomTypesRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var EventTargetRe = require("../events/EventTargetRe.js");

function Impl(T) {
  var ofElement = (function (element) {
      // BEWARE: Assumes "contentEditable" uniquely identifies an HTMLELement
      return element.contentEditable !== undefined ?  element : null;
    });
  var ofElement$1 = function (self) {
    return Caml_option.null_to_opt(ofElement(self));
  };
  var contentEditable = function (self) {
    return DomTypesRe.decodeContentEditable(self.contentEditable);
  };
  var setContentEditable = function (self, value) {
    self.contentEditable = DomTypesRe.encodeContentEditable(value);
    
  };
  var dir = function (self) {
    return DomTypesRe.decodeDir(self.dir);
  };
  var setDir = function (self, value) {
    self.dir = DomTypesRe.encodeDir(value);
    
  };
  return {
          ofElement: ofElement$1,
          contentEditable: contentEditable,
          setContentEditable: setContentEditable,
          dir: dir,
          setDir: setDir
        };
}

var include = NodeRe.Impl({ });

EventTargetRe.Impl({ });

var include$1 = ElementRe.Impl({ });

var ofElement = (function (element) {
      // BEWARE: Assumes "contentEditable" uniquely identifies an HTMLELement
      return element.contentEditable !== undefined ?  element : null;
    });

function ofElement$1(self) {
  return Caml_option.null_to_opt(ofElement(self));
}

function contentEditable(self) {
  return DomTypesRe.decodeContentEditable(self.contentEditable);
}

function setContentEditable(self, value) {
  self.contentEditable = DomTypesRe.encodeContentEditable(value);
  
}

function dir(self) {
  return DomTypesRe.decodeDir(self.dir);
}

function setDir(self, value) {
  self.dir = DomTypesRe.encodeDir(value);
  
}

var nodeType = include.nodeType;

var asHtmlElement = include$1.asHtmlElement;

var ofNode = include$1.ofNode;

var insertAdjacentElement = include$1.insertAdjacentElement;

var insertAdjacentHTML = include$1.insertAdjacentHTML;

var insertAdjacentText = include$1.insertAdjacentText;

exports.Impl = Impl;
exports.nodeType = nodeType;
exports.asHtmlElement = asHtmlElement;
exports.ofNode = ofNode;
exports.insertAdjacentElement = insertAdjacentElement;
exports.insertAdjacentHTML = insertAdjacentHTML;
exports.insertAdjacentText = insertAdjacentText;
exports.ofElement = ofElement$1;
exports.contentEditable = contentEditable;
exports.setContentEditable = setContentEditable;
exports.dir = dir;
exports.setDir = setDir;
/* include Not a pure module */
