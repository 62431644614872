// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Make(Cfg) {
  var Flex2$Make = function (Props) {
    var flexGrowOpt = Props.flexGrow;
    var flexShrinkOpt = Props.flexShrink;
    var alignItemsOpt = Props.alignItems;
    var justifyContentOpt = Props.justifyContent;
    var alignContentOpt = Props.alignContent;
    var flexWrapOpt = Props.flexWrap;
    var className = Props.className;
    var onClick = Props.onClick;
    var children = Props.children;
    var flexGrow = flexGrowOpt !== undefined ? flexGrowOpt : 0;
    var flexShrink = flexShrinkOpt !== undefined ? flexShrinkOpt : 1;
    var alignItems = alignItemsOpt !== undefined ? alignItemsOpt : /* flexStart */662439529;
    var justifyContent = justifyContentOpt !== undefined ? justifyContentOpt : /* flexStart */662439529;
    var alignContent = alignContentOpt !== undefined ? alignContentOpt : /* flexStart */662439529;
    var flexWrap = flexWrapOpt !== undefined ? flexWrapOpt : /* nowrap */867913355;
    var flexClass = Css.style(/* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(Cfg.flexDirection),
            /* :: */[
              Css.flexGrow(flexGrow),
              /* :: */[
                Css.flexShrink(flexShrink),
                /* :: */[
                  Css.alignItems(alignItems),
                  /* :: */[
                    Css.justifyContent(justifyContent),
                    /* :: */[
                      Css.alignContent(alignContent),
                      /* :: */[
                        Css.flexWrap(flexWrap),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]);
    var tmp = {
      className: className !== undefined ? className + (" " + flexClass) : flexClass
    };
    if (onClick !== undefined) {
      tmp.onClick = Caml_option.valFromOption(onClick);
    }
    return React.createElement("div", tmp, children);
  };
  return {
          make: Flex2$Make
        };
}

exports.Make = Make;
/* Css Not a pure module */
