// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Option$BsConsole = require("./option.js");

function decodeExn(json) {
  return {
          token: Json_decode.field("token", Json_decode.string, json)
        };
}

function decode(json) {
  return Option$BsConsole.tryWith((function (param) {
                return decodeExn(json);
              }));
}

exports.decodeExn = decodeExn;
exports.decode = decode;
/* No side effect */
