// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeDeltaList(json) {
  return Belt_List.flatten(Belt_List.map(Json_decode.list((function (param) {
                        return Json_decode.list(Json_decode.$$float, param);
                      }), json), (function (idAndCount) {
                    var id = Belt_List.getExn(idAndCount, 0);
                    var count = Belt_Option.getWithDefault(Belt_List.get(idAndCount, 1), 0) + 1;
                    return Belt_List.mapWithIndex(Belt_List.make(count | 0, id), (function (i, id) {
                                  return Caml_int64.of_float(id + i);
                                }));
                  })));
}

function toHexString(t) {
  return Curry._1(Printf.sprintf(/* Format */[
                  /* Int64 */Block.__(7, [
                      /* Int_x */6,
                      /* No_padding */0,
                      /* No_precision */0,
                      /* End_of_format */0
                    ]),
                  "%Lx"
                ]), t);
}

function toDecString(t) {
  return Curry._1(Printf.sprintf(/* Format */[
                  /* Int64 */Block.__(7, [
                      /* Int_d */0,
                      /* No_padding */0,
                      /* No_precision */0,
                      /* End_of_format */0
                    ]),
                  "%Ld"
                ]), t);
}

var fromDecimalString = Caml_format.caml_int64_of_string;

function fromHexString(str) {
  return Caml_format.caml_int64_of_string("0x" + str);
}

var fromInteger = Caml_int64.of_int32;

exports.decodeDeltaList = decodeDeltaList;
exports.toHexString = toHexString;
exports.toDecString = toDecString;
exports.fromDecimalString = fromDecimalString;
exports.fromHexString = fromHexString;
exports.fromInteger = fromInteger;
/* No side effect */
