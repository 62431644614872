// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Utility$BsConsole = require("./Utility.js");

function fromString(str) {
  switch (str) {
    case "ascending" :
        return /* Ascending */0;
    case "descending" :
        return /* Descending */1;
    default:
      return ;
  }
}

function toString(t$prime) {
  if (t$prime) {
    return "descending";
  } else {
    return "ascending";
  }
}

function toggle(t$prime) {
  if (t$prime) {
    return /* Ascending */0;
  } else {
    return /* Descending */1;
  }
}

var Ordering = {
  fromString: fromString,
  toString: toString,
  toggle: toggle
};

var ascending = "ascending";

var Constants = {
  ascending: ascending,
  descending: "descending",
  rangeStart: ";0",
  rangeFinish: ";1",
  head: ";0",
  tail: ";0"
};

function toggle$1(t$prime) {
  if (t$prime) {
    return /* Start */0;
  } else {
    return /* Finish */1;
  }
}

function fromString$1(str) {
  switch (str) {
    case "finish" :
        return /* Finish */1;
    case "start" :
        return /* Start */0;
    default:
      return ;
  }
}

function toString$1(t$prime) {
  if (t$prime) {
    return "finish";
  } else {
    return "start";
  }
}

function toCrdb(t$prime) {
  if (t$prime) {
    return ";1";
  } else {
    return ";0";
  }
}

var $$Range = {
  toggle: toggle$1,
  fromString: fromString$1,
  toString: toString$1,
  toCrdb: toCrdb
};

var Aggregation = {
  $$Range: $$Range
};

function encodeTuple2(param) {
  return Json_encode.tuple2((function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), param);
}

function encodeTuple3(param) {
  return Json_encode.tuple3((function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), param);
}

function encodeTuple4(param) {
  return Json_encode.tuple4((function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), param);
}

function Make(F, FF) {
  var toJson = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
          return Json_encode.tuple2((function (prim) {
                        return prim;
                      }), (function (prim) {
                        return prim;
                      }), /* tuple */[
                      "group",
                      t$prime[0] ? "descending" : "ascending"
                    ]);
      case /* Count */1 :
          return Json_encode.tuple2((function (prim) {
                        return prim;
                      }), (function (prim) {
                        return prim;
                      }), /* tuple */[
                      "count",
                      t$prime[0] ? "descending" : "ascending"
                    ]);
      case /* Head */2 :
          return encodeTuple3(/* tuple */[
                      "head",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Tail */3 :
          return encodeTuple3(/* tuple */[
                      "tail",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Range */4 :
          return encodeTuple4(/* tuple */[
                      "range",
                      t$prime[0],
                      t$prime[1] ? "finish" : "start",
                      t$prime[2] ? "descending" : "ascending"
                    ]);
      case /* Select */5 :
          return encodeTuple3(/* tuple */[
                      "select",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Unique */6 :
          return encodeTuple3(/* tuple */[
                      "unique",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Min */7 :
          return encodeTuple3(/* tuple */[
                      "min",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Max */8 :
          return encodeTuple3(/* tuple */[
                      "max",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Mean */9 :
          return encodeTuple3(/* tuple */[
                      "mean",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      case /* Sum */10 :
          return encodeTuple3(/* tuple */[
                      "sum",
                      t$prime[0],
                      t$prime[1] ? "descending" : "ascending"
                    ]);
      
    }
  };
  var fromJson = function (json) {
    var partial_arg_000 = function (json) {
      var match = Json_decode.tuple2(Json_decode.string, Json_decode.string, json);
      if (match[0] === "group") {
        return /* Group */Block.__(0, [Utility$BsConsole.optDefault(/* Descending */1, fromString(match[1]))]);
      }
      
    };
    var partial_arg_001 = /* :: */[
      (function (json) {
          var match = Json_decode.tuple2(Json_decode.string, Json_decode.string, json);
          if (match[0] === "count") {
            return /* Count */Block.__(1, [Utility$BsConsole.optDefault(/* Descending */1, fromString(match[1]))]);
          }
          
        }),
      /* :: */[
        (function (json) {
            var match = Json_decode.tuple3(Json_decode.string, Json_decode.string, Json_decode.string, json);
            var c = match[2];
            var b = match[1];
            switch (match[0]) {
              case "head" :
                  return /* Head */Block.__(2, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              case "mean" :
                  return /* Mean */Block.__(9, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              case "select" :
                  return /* Select */Block.__(5, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              case "sum" :
                  return /* Sum */Block.__(10, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              case "tail" :
                  return /* Tail */Block.__(3, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              case "unique" :
                  return /* Unique */Block.__(6, [
                            b,
                            Utility$BsConsole.optDefault(/* Descending */1, fromString(c))
                          ]);
              default:
                return ;
            }
          }),
        /* :: */[
          (function (json) {
              var match = Json_decode.tuple4(Json_decode.string, Json_decode.string, Json_decode.string, Json_decode.string, json);
              if (match[0] === "range") {
                return /* Range */Block.__(4, [
                          match[1],
                          Utility$BsConsole.optDefault(/* Start */0, fromString$1(match[2])),
                          Utility$BsConsole.optDefault(/* Descending */1, fromString(match[3]))
                        ]);
              }
              
            }),
          /* [] */0
        ]
      ]
    ];
    var partial_arg = /* :: */[
      partial_arg_000,
      partial_arg_001
    ];
    return Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json);
  };
  var $$default = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          ";count"
        ],
        /* :: */[
          /* tuple */[
            "ordering",
            ascending
          ],
          /* [] */0
        ]
      ]);
  var toCrdb = function (t$prime, f$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "name",
                        ";group"
                      ],
                      /* :: */[
                        /* tuple */[
                          "ordering",
                          t$prime[0] ? "descending" : "ascending"
                        ],
                        /* [] */0
                      ]
                    ]);
      case /* Count */1 :
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "name",
                        ";count"
                      ],
                      /* :: */[
                        /* tuple */[
                          "ordering",
                          t$prime[0] ? "descending" : "ascending"
                        ],
                        /* [] */0
                      ]
                    ]);
      case /* Head */2 :
          var attribute = t$prime[0];
          var index = Curry._3(FF.getAttributeAggregateIndex, attribute, "head", f$prime);
          if (index !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute + (";" + String(index))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Tail */3 :
          var attribute$1 = t$prime[0];
          var index$1 = Curry._3(FF.getAttributeAggregateIndex, attribute$1, "tail", f$prime);
          if (index$1 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$1 + (";" + String(index$1))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Range */4 :
          var attribute$2 = t$prime[0];
          var index$2 = Curry._3(FF.getAttributeAggregateIndex, attribute$2, "range", f$prime);
          if (index$2 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$2 + (";" + (String(index$2) + (
                                t$prime[1] ? ";1" : ";0"
                              )))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[2] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Select */5 :
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "name",
                        t$prime[0]
                      ],
                      /* :: */[
                        /* tuple */[
                          "ordering",
                          t$prime[1] ? "descending" : "ascending"
                        ],
                        /* [] */0
                      ]
                    ]);
      case /* Unique */6 :
          var attribute$3 = t$prime[0];
          var index$3 = Curry._3(FF.getAttributeAggregateIndex, attribute$3, "unique", f$prime);
          if (index$3 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$3 + (";" + String(index$3))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Min */7 :
          var attribute$4 = t$prime[0];
          var index$4 = Curry._3(FF.getAttributeAggregateIndex, attribute$4, "min", f$prime);
          if (index$4 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$4 + (";" + String(index$4))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Max */8 :
          var attribute$5 = t$prime[0];
          var index$5 = Curry._3(FF.getAttributeAggregateIndex, attribute$5, "max", f$prime);
          if (index$5 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$5 + (";" + String(index$5))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Mean */9 :
          var attribute$6 = t$prime[0];
          var index$6 = Curry._3(FF.getAttributeAggregateIndex, attribute$6, "mean", f$prime);
          if (index$6 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$6 + (";" + String(index$6))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      case /* Sum */10 :
          var attribute$7 = t$prime[0];
          var index$7 = Curry._3(FF.getAttributeAggregateIndex, attribute$7, "sum", f$prime);
          if (index$7 !== undefined) {
            return Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          attribute$7 + (";" + String(index$7))
                        ],
                        /* :: */[
                          /* tuple */[
                            "ordering",
                            t$prime[1] ? "descending" : "ascending"
                          ],
                          /* [] */0
                        ]
                      ]);
          } else {
            return $$default;
          }
      
    }
  };
  var eq = Caml_obj.caml_equal;
  var setOrdering = function (o$prime, t$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
          return /* Group */Block.__(0, [o$prime]);
      case /* Count */1 :
          return /* Count */Block.__(1, [o$prime]);
      case /* Head */2 :
          return /* Head */Block.__(2, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Tail */3 :
          return /* Tail */Block.__(3, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Range */4 :
          return /* Range */Block.__(4, [
                    t$prime[0],
                    t$prime[1],
                    o$prime
                  ]);
      case /* Select */5 :
          return /* Select */Block.__(5, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Unique */6 :
          return /* Unique */Block.__(6, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Min */7 :
          return /* Min */Block.__(7, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Max */8 :
          return /* Max */Block.__(8, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Mean */9 :
          return /* Mean */Block.__(9, [
                    t$prime[0],
                    o$prime
                  ]);
      case /* Sum */10 :
          return /* Sum */Block.__(10, [
                    t$prime[0],
                    o$prime
                  ]);
      
    }
  };
  var getOrdering = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
      case /* Count */1 :
          return t$prime[0];
      case /* Range */4 :
          return t$prime[2];
      default:
        return t$prime[1];
    }
  };
  var toggleOrdering = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
          return /* Group */Block.__(0, [t$prime[0] ? /* Ascending */0 : /* Descending */1]);
      case /* Count */1 :
          return /* Count */Block.__(1, [t$prime[0] ? /* Ascending */0 : /* Descending */1]);
      case /* Head */2 :
          return /* Head */Block.__(2, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Tail */3 :
          return /* Tail */Block.__(3, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Range */4 :
          return /* Range */Block.__(4, [
                    t$prime[0],
                    t$prime[1],
                    t$prime[2] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Select */5 :
          return /* Select */Block.__(5, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Unique */6 :
          return /* Unique */Block.__(6, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Min */7 :
          return /* Min */Block.__(7, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Max */8 :
          return /* Max */Block.__(8, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Mean */9 :
          return /* Mean */Block.__(9, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      case /* Sum */10 :
          return /* Sum */Block.__(10, [
                    t$prime[0],
                    t$prime[1] ? /* Ascending */0 : /* Descending */1
                  ]);
      
    }
  };
  var getAttribute = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Group */0 :
          return "group";
      case /* Count */1 :
          return "count";
      default:
        return t$prime[0];
    }
  };
  var getAggString = function (param) {
    switch (param.tag | 0) {
      case /* Group */0 :
      case /* Count */1 :
          return "none";
      case /* Head */2 :
          return "head";
      case /* Tail */3 :
          return "tail";
      case /* Range */4 :
          return "range";
      case /* Select */5 :
          return "select";
      case /* Unique */6 :
          return "unique";
      case /* Min */7 :
          return "min";
      case /* Max */8 :
          return "max";
      case /* Mean */9 :
          return "mean";
      case /* Sum */10 :
          return "sum";
      
    }
  };
  var getRangeParam = function (t$prime) {
    if (t$prime.tag === /* Range */4) {
      return t$prime[1];
    } else {
      return /* Finish */1;
    }
  };
  return {
          toCrdb: toCrdb,
          toJson: toJson,
          fromJson: fromJson,
          eq: eq,
          defaultSelect: F.defaultSelect,
          defaultAggregate: F.defaultAggregate,
          getOrdering: getOrdering,
          setOrdering: setOrdering,
          toggleOrdering: toggleOrdering,
          getRangeParam: getRangeParam,
          getAttribute: getAttribute,
          getAggString: getAggString
        };
}

exports.Ordering = Ordering;
exports.Constants = Constants;
exports.Aggregation = Aggregation;
exports.encodeTuple2 = encodeTuple2;
exports.encodeTuple3 = encodeTuple3;
exports.encodeTuple4 = encodeTuple4;
exports.Make = Make;
/* No side effect */
