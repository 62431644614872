// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var ExporterUtils$BsConsole = require("./ExporterUtils.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var ExporterSetApi$BsConsole = require("./api/ExporterSetApi.js");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");
var InputLabel = require("@material-ui/core/InputLabel");

function ExporterCreateMetricsModal(Props) {
  var _open = Props.open;
  var onClose = Props.onClose;
  var handleSave = Props.handleSave;
  var sets = Props.sets;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var token = Props.token;
  var stores = Props.stores;
  var modalHeight = 445;
  var match = React.useState((function () {
          return "";
        }));
  var setMetricName = match[1];
  var metricName = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setSet = match$1[1];
  var set = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setCreateNewSet = match$2[1];
  var match$3 = React.useState((function () {
          return "";
        }));
  var setNewSetName = match$3[1];
  var newSetName = match$3[0];
  var match$4 = React.useState((function () {
          return [];
        }));
  var setSelectedStores = match$4[1];
  var selectedStores = match$4[0];
  var close = function (param) {
    Curry._1(onClose, undefined);
    Curry._1(setMetricName, (function (param) {
            return "";
          }));
    Curry._1(setNewSetName, (function (param) {
            return "";
          }));
    Curry._1(setSet, (function (param) {
            
          }));
    return Curry._1(setCreateNewSet, (function (param) {
                  return false;
                }));
  };
  var creatingNewSet = match$2[0] || sets.length === 0;
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: close,
              modalWidth: 300,
              modalHeight: modalHeight,
              title: I18N$BsConsole.get(undefined, "Export current query as a metric"),
              subtitle: I18N$BsConsole.get(undefined, "Capture the current aggregation query as a metric and attach it to a metric set to export it."),
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.height(Css.px(modalHeight - 120 | 0)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", undefined, React.createElement("div", undefined, React.createElement(InputLabel.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.show(undefined, "Metric name")
                                    })
                              }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                                placeholder: "",
                                value: metricName,
                                onChange: (function (e) {
                                    var value = BtSettings$BsConsole.getInputValue(e);
                                    return Curry._1(setMetricName, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disabled: false,
                                className: Css.style(/* :: */[
                                      Css.width(Css.px(300)),
                                      /* :: */[
                                        Css.marginTop(Css.px(8)),
                                        /* :: */[
                                          Css.marginLeft(/* auto */-1065951377),
                                          /* :: */[
                                            Css.marginRight(/* auto */-1065951377),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              })), creatingNewSet ? React.createElement(React.Fragment, undefined, React.createElement(InputLabel.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.marginBottom(Css.rem(0.5)),
                                          /* [] */0
                                        ])
                                  },
                                  children: React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        children: I18N$BsConsole.show(undefined, "Name a new metrics set")
                                      })
                                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                                  placeholder: "",
                                  value: newSetName,
                                  onChange: (function (e) {
                                      var value = BtSettings$BsConsole.getInputValue(e);
                                      return Curry._1(setNewSetName, (function (param) {
                                                    return value;
                                                  }));
                                    }),
                                  disabled: false,
                                  className: Css.style(/* :: */[
                                        Css.width(Css.px(300)),
                                        /* :: */[
                                          Css.marginTop(Css.px(8)),
                                          /* :: */[
                                            Css.marginLeft(/* auto */-1065951377),
                                            /* :: */[
                                              Css.marginRight(/* auto */-1065951377),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ])
                                }), sets.length !== 0 ? React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginBottom(Css.px(8)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(Button.default, {
                                        variant: "text",
                                        size: "small",
                                        color: "primary",
                                        onClick: (function (param) {
                                            Curry._1(setCreateNewSet, (function (param) {
                                                    return false;
                                                  }));
                                            return Curry._1(setSelectedStores, (function (param) {
                                                          return [];
                                                        }));
                                          }),
                                        children: React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Caption */11,
                                              children: I18N$BsConsole.show(undefined, "Choose from existing sets")
                                            })
                                      })) : null, Belt_List.length(stores) !== 0 ? React.createElement(BtTypeaheadInput$BsConsole.Multi.make, {
                                    selectedItems: selectedStores,
                                    items: Belt_List.toArray(stores),
                                    toString: ExporterUtils$BsConsole.getStoreName,
                                    toId: (function (store) {
                                        return store.id;
                                      }),
                                    placeholder: I18N$BsConsole.get(undefined, "Find a store"),
                                    noItemsMessage: I18N$BsConsole.get(undefined, "No stores"),
                                    inputLabel: I18N$BsConsole.get(undefined, "Attach metrics set to existing store(s) (optional)"),
                                    itemFilter: (function (param) {
                                        return true;
                                      }),
                                    pixelWidth: 300,
                                    handleAllItemsAfterChange: (function (items) {
                                        return Curry._1(setSelectedStores, (function (param) {
                                                      return items;
                                                    }));
                                      }),
                                    hideButtons: true
                                  }) : null) : React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                              selectedItem: set,
                              toString: (function (item) {
                                  return item.name;
                                }),
                              inputLabel: I18N$BsConsole.get(undefined, "Attach to an existing metrics set"),
                              placeholder: I18N$BsConsole.get(undefined, "Select a set"),
                              noItemsMessage: I18N$BsConsole.get(undefined, "No existing sets"),
                              createNewItemMessage: I18N$BsConsole.get(undefined, "Don't see your set? Create a new one."),
                              items: sets,
                              itemFilter: (function (param) {
                                  return true;
                                }),
                              pixelWidth: 300,
                              handleItemSelection: (function (item) {
                                  return Curry._1(setSet, (function (param) {
                                                return item;
                                              }));
                                }),
                              handleCreateNewItem: (function (param) {
                                  return Curry._1(setCreateNewSet, (function (param) {
                                                return true;
                                              }));
                                })
                            })), React.createElement(Row2$BsConsole.make, {
                        className: creatingNewSet && Belt_List.length(stores) !== 0 ? Css.style(/* :: */[
                                Css.marginBottom(Css.px(15)),
                                /* [] */0
                              ]) : "",
                        children: null
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: metricName === "" || !ExporterUtils$BsConsole.isValidName(metricName) || set === undefined && newSetName === "" && !ExporterUtils$BsConsole.isValidName(newSetName),
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginRight(Css.px(8)),
                                    /* [] */0
                                  ])
                            },
                            onClick: (function (param) {
                                if (creatingNewSet) {
                                  var arg = ExporterSetApi$BsConsole.create(endpoint, universeName, projectName, newSetName, undefined);
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (resp) {
                                                if (resp.tag) {
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                                                }
                                                Curry._3(handleSave, metricName, resp[0].id, selectedStores);
                                                return close(undefined);
                                              }));
                                }
                                if (set !== undefined) {
                                  Curry._3(handleSave, metricName, set.id, selectedStores);
                                } else {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error creating metric: no set id"));
                                }
                                return close(undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Save")
                          }), React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: close,
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var make = ExporterCreateMetricsModal;

exports.make = make;
/* Css Not a pure module */
