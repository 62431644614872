// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Styles$BsConsole = require("../../../utilities/Styles.js");
var MuiIcons$BsConsole = require("../../../MuiIcons.js");
var SnackPurveyor$BsConsole = require("../../../SnackPurveyor.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_Metrics$BsConsole = require("./SymbolServers_Metrics.js");

var headerStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey2)),
          /* [] */0
        ]
      ]
    ]);

var noSymbolsFound = React.createElement("div", {
      className: headerStyle
    }, I18N$BsConsole.show(undefined, "No symbols are currently in the skiplist."));

var paginationPlaceholder = React.createElement("div", {
      className: Css.merge(/* :: */[
            headerStyle,
            /* :: */[
              Css.style(/* :: */[
                    Css.width(Css.px(14)),
                    /* :: */[
                      Css.height(Css.px(14)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ])
    });

var trashPlaceholder = React.createElement("div", {
      className: Css.style(/* :: */[
            Css.width(Css.px(18)),
            /* :: */[
              Css.height(Css.px(18)),
              /* :: */[
                Css.marginLeft(Css.px(7)),
                /* :: */[
                  Css.marginRight(Css.px(7)),
                  /* [] */0
                ]
              ]
            ]
          ])
    });

var component = ReasonReact.reducerComponentWithRetainedProps("SymbolServers_Skiplist-BsConsole");

function showNext(send, page) {
  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                      className: Styles$BsConsole.$$Text.tooltipTitle
                    }, I18N$BsConsole.show(undefined, "View next page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(14)),
                              /* :: */[
                                Css.height(Css.px(14)),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* [] */0
                                ]
                              ]
                            ]) + " smaller-icon",
                        onClick: (function (param) {
                            return Curry._1(send, /* UpdatePage */Block.__(0, [page + 1 | 0]));
                          })
                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
}

function showPrevious(send, page) {
  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                      className: Styles$BsConsole.$$Text.tooltipTitle
                    }, I18N$BsConsole.show(undefined, "View previous page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.cursor(/* pointer */-786317123),
                              /* [] */0
                            ]) + " smaller-icon",
                        onClick: (function (param) {
                            return Curry._1(send, /* UpdatePage */Block.__(0, [page - 1 | 0]));
                          })
                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
}

function make(serverId, token, endpoint, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              if (serverId === self.retainedProps) {
                return self.state;
              }
              var init = self.state;
              return {
                      page: 0,
                      symbolsToUpload: init.symbolsToUpload,
                      hovered: init.hovered,
                      filter: init.filter,
                      remoteFilter: init.remoteFilter
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var hovered = match.hovered;
              var page = match.page;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, Css.style(/* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.justifyContent(/* flexStart */662439529),
                                        /* :: */[
                                          Css.marginBottom(Css.px(18)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]), [React.createElement("form", {
                                        className: Css.style(/* :: */[
                                              Css.width(Css.pct(100)),
                                              /* [] */0
                                            ]),
                                        onSubmit: (function (e) {
                                            e.preventDefault();
                                            return Curry._1(send, /* SetRemoteFilterValue */2);
                                          })
                                      }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* nowrap */867913355, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                          undefined,
                                                          Css.style(/* :: */[
                                                                Css.width(Css.pct(100.0)),
                                                                /* :: */[
                                                                  Css.marginRight(Css.px(12)),
                                                                  /* :: */[
                                                                    Css.marginLeft(Css.px(32)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]),
                                                          /* `String */[
                                                            -976970511,
                                                            Belt_Option.getWithDefault(match.filter, "")
                                                          ],
                                                          (function ($$event) {
                                                              var value = $$event.target.value;
                                                              return Curry._1(send, /* SetFilterValue */Block.__(3, [value]));
                                                            }),
                                                          I18N$BsConsole.get(undefined, "Filter skip list by identifier or name..."),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          []
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          /* Small */311976103,
                                                          Css.style(/* :: */[
                                                                Css.width(Css.px(70)),
                                                                /* [] */0
                                                              ]),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          /* Outlined */-28821822,
                                                          /* Primary */-791844958,
                                                          "submit",
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Filter")]
                                                        ]))
                                              ])))])), ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, SymbolServer_API$BsConsole.getSymbolServerSkiplist(serverId, endpoint, match.remoteFilter, page, 25, undefined), undefined, (function (remote, refetch) {
                                    if (typeof remote === "number") {
                                      if (remote === /* NotAsked */0) {
                                        return null;
                                      } else {
                                        return React.createElement("div", {
                                                    className: headerStyle
                                                  }, I18N$BsConsole.show(undefined, "Loading..."));
                                      }
                                    }
                                    if (remote.tag) {
                                      return React.createElement("div", {
                                                  className: headerStyle
                                                }, I18N$BsConsole.show(undefined, "Failed to load symbols."));
                                    }
                                    var match = remote[0][0];
                                    var total = match[1];
                                    var result = match[0];
                                    var tmp;
                                    if (Belt_List.length(result) === 0) {
                                      tmp = noSymbolsFound;
                                    } else {
                                      var __x = Belt_List.toArray(result);
                                      tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.justifyContent(/* flexStart */662439529),
                                                          /* :: */[
                                                            Css.marginBottom(Css.px(3)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                              /* Small */311976103,
                                                              Css.style(/* :: */[
                                                                    Css.width(Css.px(100)),
                                                                    /* :: */[
                                                                      Css.marginLeft(Css.px(30)),
                                                                      /* :: */[
                                                                        Css.marginBottom(Css.px(18)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]),
                                                              (function (param) {
                                                                  new Promise((function (resolve, param) {
                                                                          var arg = SymbolServer_API$BsConsole.deleteAllSkiplist(endpoint, serverId, undefined);
                                                                          return Curry._2((function (param) {
                                                                                          return (function (param$1, param$2) {
                                                                                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                            });
                                                                                        })(token), true, (function (resp) {
                                                                                        if (resp.tag) {
                                                                                          SymbolServers_Metrics$BsConsole.send(/* SkiplistDeleteFailed */17);
                                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to clear skiplist."));
                                                                                          return resolve(Caml_option.some(resp[0]));
                                                                                        }
                                                                                        SymbolServers_Metrics$BsConsole.send(/* SkiplistDeleteSuccess */16);
                                                                                        Curry._1(refetch, undefined);
                                                                                        return resolve(undefined);
                                                                                      }));
                                                                        }));
                                                                  
                                                                }),
                                                              undefined,
                                                              undefined,
                                                              /* Outlined */-28821822,
                                                              /* Primary */-791844958,
                                                              "submit",
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [I18N$BsConsole.show(undefined, "Clear skip list")]
                                                            ]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                    React.createElement("div", {
                                                          className: Css.merge(/* :: */[
                                                                Css.style(/* :: */[
                                                                      Css.marginRight(Css.px(166)),
                                                                      /* :: */[
                                                                        Css.marginLeft(Css.px(32)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  headerStyle,
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }, I18N$BsConsole.show(undefined, "Date")),
                                                    React.createElement("div", {
                                                          className: Css.merge(/* :: */[
                                                                headerStyle,
                                                                /* :: */[
                                                                  Css.style(/* :: */[
                                                                        Css.marginRight(Css.px(59)),
                                                                        /* [] */0
                                                                      ]),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }, I18N$BsConsole.show(undefined, "Symbol name")),
                                                    React.createElement("div", {
                                                          className: headerStyle
                                                        }, I18N$BsConsole.show(undefined, "Debug identifier"))
                                                  ])), Belt_Array.map(__x, (function (item) {
                                                  var id = item.debugId;
                                                  return React.createElement("span", {
                                                              onMouseEnter: (function (param) {
                                                                  return Curry._1(send, /* MouseEnter */Block.__(2, [item.id]));
                                                                }),
                                                              onMouseLeave: (function (param) {
                                                                  return Curry._1(send, /* MouseLeave */1);
                                                                })
                                                            }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, hovered !== undefined && hovered === item.id ? Css.style(/* :: */[
                                                                            Css.backgroundColor(/* `hex */[
                                                                                  5194459,
                                                                                  Colors$BsConsole.grey8
                                                                                ]),
                                                                            /* [] */0
                                                                          ]) : "", [
                                                                      hovered !== undefined && hovered === item.id ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                      className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                    }, I18N$BsConsole.show(undefined, "Remove symbol from server skip list")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                        onClick: (function (param) {
                                                                                            new Promise((function (resolve, param) {
                                                                                                    var arg = SymbolServer_API$BsConsole.deleteSymbolSkiplist(endpoint, item.id, undefined);
                                                                                                    return Curry._2((function (param) {
                                                                                                                    return (function (param$1, param$2) {
                                                                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                                      });
                                                                                                                  })(token), true, (function (resp) {
                                                                                                                  if (resp.tag) {
                                                                                                                    SymbolServers_Metrics$BsConsole.send(/* SkiplistDeleteFailed */17);
                                                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete symbol."));
                                                                                                                    return resolve(Caml_option.some(resp[0]));
                                                                                                                  }
                                                                                                                  SymbolServers_Metrics$BsConsole.send(/* SkiplistDeleteSuccess */16);
                                                                                                                  Curry._1(refetch, undefined);
                                                                                                                  return resolve(undefined);
                                                                                                                }));
                                                                                                  }));
                                                                                            
                                                                                          })
                                                                                      }, React.createElement("span", {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.color(Css.hex(Colors$BsConsole.grey5)),
                                                                                                  /* :: */[
                                                                                                    Css.marginLeft(Css.px(7)),
                                                                                                    /* :: */[
                                                                                                      Css.marginRight(Css.px(7)),
                                                                                                      /* :: */[
                                                                                                        Css.cursor(/* pointer */-786317123),
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]) + " smaller-icon"
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))))])) : trashPlaceholder,
                                                                      React.createElement("pre", {
                                                                            className: Css.style(/* :: */[
                                                                                  Css.marginRight(Css.px(25)),
                                                                                  /* :: */[
                                                                                    Css.fontSize(Css.px(14)),
                                                                                    /* :: */[
                                                                                      Css.lineHeight(Css.px(14)),
                                                                                      /* :: */[
                                                                                        Css.fontFamily("Inconsolata"),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ])
                                                                          }, I18N$BsConsole.showSkip(item.creationDate)),
                                                                      React.createElement("pre", {
                                                                            className: Css.style(/* :: */[
                                                                                  Css.width(Css.px(150)),
                                                                                  /* :: */[
                                                                                    Css.whiteSpace(/* preWrap */660870029),
                                                                                    /* :: */[
                                                                                      Css.wordWrap(/* breakWord */1059921449),
                                                                                      /* :: */[
                                                                                        Css.fontSize(Css.px(14)),
                                                                                        /* :: */[
                                                                                          Css.lineHeight(Css.px(14)),
                                                                                          /* :: */[
                                                                                            Css.fontFamily("Inconsolata"),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ])
                                                                          }, I18N$BsConsole.showSkip(item.symbolName)),
                                                                      React.createElement("pre", {
                                                                            className: Css.style(/* :: */[
                                                                                  Css.width(Css.px(250)),
                                                                                  /* :: */[
                                                                                    Css.whiteSpace(/* preWrap */660870029),
                                                                                    /* :: */[
                                                                                      Css.wordWrap(/* breakWord */1059921449),
                                                                                      /* :: */[
                                                                                        Css.fontSize(Css.px(14)),
                                                                                        /* :: */[
                                                                                          Css.lineHeight(Css.px(14)),
                                                                                          /* :: */[
                                                                                            Css.fontFamily("Inconsolata"),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ])
                                                                          }, id !== undefined ? I18N$BsConsole.showSkip(id) : null)
                                                                    ])));
                                                })), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.justifyContent(/* spaceBetween */516682146),
                                                          /* :: */[
                                                            Css.width(Css.px(130)),
                                                            /* :: */[
                                                              Css.marginTop(Css.px(15)),
                                                              /* :: */[
                                                                Css.marginRight(Css.px(115)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]), [
                                                    page !== 0 ? showPrevious(send, page) : paginationPlaceholder,
                                                    total === 0 ? null : React.createElement("div", undefined, I18N$BsConsole.showSkip(String(page + 1 | 0) + (" / " + String(total)))),
                                                    total === 0 || (page + 1 | 0) === total ? paginationPlaceholder : showNext(send, page)
                                                  ])));
                                    }
                                    return React.createElement("div", undefined, tmp);
                                  }))));
            }),
          initialState: (function (param) {
              return {
                      page: 0,
                      symbolsToUpload: "",
                      hovered: undefined,
                      filter: undefined,
                      remoteFilter: undefined
                    };
            }),
          retainedProps: serverId,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* ClearSymbolsToUpload */0 :
                      return /* Update */Block.__(0, [{
                                  page: state.page,
                                  symbolsToUpload: "",
                                  hovered: state.hovered,
                                  filter: state.filter,
                                  remoteFilter: state.remoteFilter
                                }]);
                  case /* MouseLeave */1 :
                      return /* Update */Block.__(0, [{
                                  page: state.page,
                                  symbolsToUpload: state.symbolsToUpload,
                                  hovered: undefined,
                                  filter: state.filter,
                                  remoteFilter: state.remoteFilter
                                }]);
                  case /* SetRemoteFilterValue */2 :
                      return /* Update */Block.__(0, [{
                                  page: 0,
                                  symbolsToUpload: state.symbolsToUpload,
                                  hovered: state.hovered,
                                  filter: state.filter,
                                  remoteFilter: state.filter
                                }]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* UpdatePage */0 :
                      return /* Update */Block.__(0, [{
                                  page: action[0],
                                  symbolsToUpload: state.symbolsToUpload,
                                  hovered: state.hovered,
                                  filter: state.filter,
                                  remoteFilter: state.remoteFilter
                                }]);
                  case /* UpdateSymbolsToUpload */1 :
                      return /* Update */Block.__(0, [{
                                  page: state.page,
                                  symbolsToUpload: action[0],
                                  hovered: state.hovered,
                                  filter: state.filter,
                                  remoteFilter: state.remoteFilter
                                }]);
                  case /* MouseEnter */2 :
                      return /* Update */Block.__(0, [{
                                  page: state.page,
                                  symbolsToUpload: state.symbolsToUpload,
                                  hovered: action[0],
                                  filter: state.filter,
                                  remoteFilter: state.remoteFilter
                                }]);
                  case /* SetFilterValue */3 :
                      var value = action[0];
                      if (value === "") {
                        return /* Update */Block.__(0, [{
                                    page: state.page,
                                    symbolsToUpload: state.symbolsToUpload,
                                    hovered: state.hovered,
                                    filter: undefined,
                                    remoteFilter: state.remoteFilter
                                  }]);
                      } else {
                        return /* Update */Block.__(0, [{
                                    page: state.page,
                                    symbolsToUpload: state.symbolsToUpload,
                                    hovered: state.hovered,
                                    filter: value,
                                    remoteFilter: state.remoteFilter
                                  }]);
                      }
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var take = 25;

exports.take = take;
exports.headerStyle = headerStyle;
exports.noSymbolsFound = noSymbolsFound;
exports.paginationPlaceholder = paginationPlaceholder;
exports.trashPlaceholder = trashPlaceholder;
exports.component = component;
exports.showNext = showNext;
exports.showPrevious = showPrevious;
exports.make = make;
/* headerStyle Not a pure module */
