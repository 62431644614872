// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task$BsConsole = require("./task.js");

function fromJson(json) {
  return {
          assets_js: Json_decode.field("assets_js", Json_decode.string, json),
          assets_css: Json_decode.field("assets_css", Json_decode.string, json)
        };
}

var Resources = {
  fromJson: fromJson
};

function decode(json) {
  return Json_decode.optional((function (json) {
                return {
                        resources: Json_decode.optional(Json_decode.at(/* :: */[
                                  "session",
                                  /* :: */[
                                    "resources",
                                    /* [] */0
                                  ]
                                ], fromJson), json)
                      };
              }), json);
}

function getResourcePath(api, resourceType) {
  var sanitizeApiRe = new RegExp("[^a-zA-Z0-9-]", "g");
  var api$1 = api.replace(sanitizeApiRe, "");
  return "https://cdn-js.global.ssl.fastly.net/" + (api$1 + ("/_assets/" + (
              resourceType ? "main.css" : "main.js"
            )));
}

function overrideTask(handleTask, api, cb) {
  var jsAsset = getResourcePath(api, /* JS */0);
  var cssAsset = getResourcePath(api, /* CSS */1);
  return Curry._2(handleTask, cb, Task$BsConsole.makeTask(/* SetOverride */Block.__(20, [
                    jsAsset,
                    cssAsset
                  ])));
}

function overrideLegacyTask(handleTask, cb) {
  return overrideTask(handleTask, "legacy", cb);
}

exports.Resources = Resources;
exports.decode = decode;
exports.getResourcePath = getResourcePath;
exports.overrideTask = overrideTask;
exports.overrideLegacyTask = overrideLegacyTask;
/* No side effect */
