// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Form$BsConsole = require("../Form.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BpgController$BsConsole = require("../BpgController.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var BtTableSessionData$BsConsole = require("../BtTableSessionData.js");

function SavedQueriesReplace(Props) {
  var projectName = Props.projectName;
  var pid = Props.pid;
  var savedView = Props.savedView;
  var bpg = Props.bpg;
  var current = Props.current;
  var normBy = Props.normBy;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var refetchQueries = Props.refetchQueries;
  var mode = Props.mode;
  var config = Props.config;
  var navToLoad = Props.navToLoad;
  var navToNone = Props.navToNone;
  var navToDetails = Props.navToDetails;
  var projectDefault = Props.projectDefault;
  var refetchProjectDefault = Props.refetchProjectDefault;
  var userDefault = Props.userDefault;
  var refetchUserDefault = Props.refetchUserDefault;
  var newSavedView = /* Dashboard */Block.__(0, [{
        username: SavedQueriesModel$BsConsole.getUsername(savedView),
        version: /* V1 */1,
        name: SavedQueriesModel$BsConsole.getName(savedView),
        mode: Belt_Option.getExn(mode),
        query: current,
        normBy: normBy,
        columnOrder: BtTableSessionData$BsConsole.ColumnOrder.getWithoutView(projectName),
        columnWidth: BtTableSessionData$BsConsole.ColumnWidth.getWithoutView(projectName),
        view: BtTableSessionData$BsConsole.pathNameToView(window.location.pathname)
      }]);
  var savedViewName = SavedQueriesModel$BsConsole.getName(savedView);
  var userDefaultName = Belt_Option.map(userDefault, SavedQueriesModel$BsConsole.getName);
  var projectDefaultName = Belt_Option.map(projectDefault, SavedQueriesModel$BsConsole.getName);
  var isProjectDefault = projectDefaultName !== undefined ? projectDefaultName === savedViewName : false;
  var isUserDefault = userDefaultName !== undefined ? userDefaultName === savedViewName : false;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.height(/* `vh */[
                          26418,
                          100
                        ]),
                    /* :: */[
                      Css.padding(/* `rem */[
                            5691738,
                            3
                          ]),
                      /* [] */0
                    ]
                  ])
            }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                      Css.style(/* :: */[
                            Css.height(Css.px(48)),
                            /* :: */[
                              Css.position(/* absolute */-1013592457),
                              /* :: */[
                                Css.top(/* `rem */[
                                      5691738,
                                      1
                                    ]),
                                /* :: */[
                                  Css.right(/* `rem */[
                                        5691738,
                                        1
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      undefined,
                      (function (param) {
                          return Curry._1(navToLoad, undefined);
                        }),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      "Close",
                      [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                    ])), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                              Css.margin(/* zero */-789508312),
                              /* [] */0
                            ]), undefined, undefined, undefined, [
                          I18N$BsConsole.show(undefined, "Are you sure you want to replace "),
                          React.createElement("span", {
                                className: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.accent)),
                                      /* [] */0
                                    ])
                              }, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                        /* Char_literal */Block.__(12, [
                                            /* "\"" */34,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* "\"" */34,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "\"%s\""
                                      ]), SavedQueriesModel$BsConsole.getName(savedView))),
                          I18N$BsConsole.show(undefined, " with the current view?")
                        ])), isUserDefault ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.marginTop(/* `rem */[
                                        5691738,
                                        2
                                      ]),
                                  /* [] */0
                                ]
                              ]), undefined, undefined, undefined, [
                            I18N$BsConsole.show(undefined, "This view is your "),
                            React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.accent)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "User default")),
                            I18N$BsConsole.showSkip(".")
                          ])) : null, isProjectDefault ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.marginTop(/* `rem */[
                                        5691738,
                                        2
                                      ]),
                                  /* [] */0
                                ]
                              ]), undefined, undefined, undefined, [
                            I18N$BsConsole.show(undefined, "This view is your "),
                            React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.accent)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Project default")),
                            I18N$BsConsole.showSkip(".")
                          ])) : null, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginTop(/* `rem */[
                                  5691738,
                                  8
                                ]),
                            /* [] */0
                          ])
                    }, ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Query.Modify.make, (function (_result, _query) {
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String_literal */Block.__(11, [
                                                  "Successfully replaced ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "Successfully replaced %s"
                                            ]), SavedQueriesModel$BsConsole.getName(savedView)));
                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_Replace */10]));
                                Curry._1(navToNone, undefined);
                                if (isProjectDefault) {
                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_ReplaceDefault */11]));
                                  SavedQueriesModel$BsConsole.makeProjectDefault(token, newSavedView, pid, projectName, refetchProjectDefault);
                                }
                                if (isUserDefault) {
                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_ReplaceDefault */11]));
                                  SavedQueriesModel$BsConsole.makeUserDefault(token, config, projectName, newSavedView, config.user.uid, refetchUserDefault);
                                }
                                return Curry._1(refetchQueries, undefined);
                              }), handleTask, token, (function (_state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                Css.merge(/* :: */[
                                                      NewStyles$BsConsole.containedButton,
                                                      /* [] */0
                                                    ]),
                                                (function (param) {
                                                    return Curry._1(handleSubmit, /* tuple */[
                                                                bpg,
                                                                Util$BsConsole.identity,
                                                                Form$BsConsole.Fields.set("payload", JSON.stringify(SavedQueriesModel$BsConsole.toJson(newSavedView)), Form$BsConsole.Fields.empty),
                                                                (function (param) {
                                                                    
                                                                  }),
                                                                (function (param) {
                                                                    
                                                                  }),
                                                                (function (param) {
                                                                    
                                                                  })
                                                              ]);
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Yes, replace it")]
                                              ]));
                              }))), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                              undefined,
                              Css.merge(/* :: */[
                                    NewStyles$BsConsole.textButton,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.marginLeft(/* `rem */[
                                                  5691738,
                                                  1
                                                ]),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ]),
                              (function (_e) {
                                  return Curry._1(navToDetails, undefined);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [I18N$BsConsole.show(undefined, "Nevermind")]
                            ])))));
}

function make(projectName, pid, savedView, bpg, current, token, handleTask, refetchQueries, mode, config, navToLoad, navToNone, navToDetails, projectDefault, refetchProjectDefault, userDefault, refetchUserDefault, normBy, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesReplace, {
              projectName: projectName,
              pid: pid,
              savedView: savedView,
              bpg: bpg,
              current: current,
              normBy: normBy,
              token: token,
              handleTask: handleTask,
              refetchQueries: refetchQueries,
              mode: mode,
              config: config,
              navToLoad: navToLoad,
              navToNone: navToNone,
              navToDetails: navToDetails,
              projectDefault: projectDefault,
              refetchProjectDefault: refetchProjectDefault,
              userDefault: userDefault,
              refetchUserDefault: refetchUserDefault
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueriesReplace;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
