// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var Reopen$BsConsole = require("../reopen/Reopen.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReopenGate$BsConsole = require("../reopen/ReopenGate.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Inbox = require("@material-ui/icons/Inbox");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var ButtonBase = require("@material-ui/core/ButtonBase");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

var listClasses = {
  root: Css.style(/* :: */[
        Css.padding2(Css.px(3), Css.px(0)),
        /* [] */0
      ])
};

var listItemClasses = {
  root: Css.style(/* :: */[
        Css.padding2(Css.px(1), Css.px(12)),
        /* :: */[
          Css.height(Css.px(24)),
          /* :: */[
            Css.margin2(Css.px(2), Css.px(0)),
            /* :: */[
              Css.fontSize(Css.px(12)),
              /* :: */[
                Css.textAlign(/* left */-944764921),
                /* :: */[
                  Css.flexShrink(0),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ])
};

var button = {
  root: Css.style(/* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.unsafe("fontSize", "inherit"),
          /* :: */[
            Css.unsafe("fontFamily", "inherit"),
            /* :: */[
              Css.padding(Css.px(4)),
              /* :: */[
                Css.borderRadius(Css.px(3)),
                /* :: */[
                  Css.hover(/* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.userSelect(/* text */-856044371),
                    /* :: */[
                      Css.justifyContent(/* spaceBetween */516682146),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ])
};

function getStatusStringAndTooltip(state, invariant) {
  if (state !== undefined) {
    switch (state) {
      case /* Open */0 :
          var statusString = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Open */0));
          return /* tuple */[
                  statusString,
                  undefined
                ];
      case /* InProgress */1 :
          var statusString$1 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* InProgress */1));
          return /* tuple */[
                  statusString$1,
                  undefined
                ];
      case /* Resolved */2 :
          if (invariant !== undefined) {
            var statusString$2 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Resolved */2) + "*");
            return /* tuple */[
                    statusString$2,
                    Caml_option.some(Caml_option.valFromOption(invariant))
                  ];
          }
          var statusString$3 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Resolved */2));
          return /* tuple */[
                  statusString$3,
                  undefined
                ];
      case /* Muted */3 :
          if (invariant !== undefined) {
            var statusString$4 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Muted */3) + "*");
            return /* tuple */[
                    statusString$4,
                    Caml_option.some(Caml_option.valFromOption(invariant))
                  ];
          }
          var statusString$5 = I18N$BsConsole.showSkip(Issues$BsConsole.State.toStringAsAdjectiveCapitalized(/* Muted */3));
          return /* tuple */[
                  statusString$5,
                  undefined
                ];
      
    }
  } else {
    var statusString$6 = I18N$BsConsole.showEmpty(undefined);
    return /* tuple */[
            statusString$6,
            undefined
          ];
  }
}

function TriageMultiState$TriageCell(Props) {
  var setAnchorEl = Props.setAnchorEl;
  var state = Props.state;
  var invariant = Props.invariant;
  var match = getStatusStringAndTooltip(state, invariant);
  var toolTipInvariant = match[1];
  var title = toolTipInvariant !== undefined ? React.createElement(Col2$BsConsole.make, {
          children: null
        }, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body2 */9,
              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Will reopen if ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ])
                          ]),
                        "Will reopen if %s"
                      ]), Issues$BsConsole.Invariant.toDescriptiveString(toolTipInvariant))
            }), I18N$BsConsole.show(undefined, "Update group status")) : I18N$BsConsole.show(undefined, "Update group status");
  return React.createElement(Tooltip.default, {
              placement: "top",
              children: React.createElement(ButtonBase.default, {
                    classes: button,
                    onClick: (function ($$event) {
                        var target = $$event.currentTarget;
                        return Curry._1(setAnchorEl, (function (param) {
                                      return Caml_option.some(target);
                                    }));
                      }),
                    children: null
                  }, React.createElement("div", undefined, match[0]), React.createElement("div", {
                        className: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.fontSize(Css.rem(1)),
                                    /* :: */[
                                      Css.display(/* inlineFlex */53323314),
                                      /* [] */0
                                    ]
                                  ]),
                              /* :: */[
                                "triage-action",
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(KeyboardArrowDown.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          }))),
              title: title
            });
}

var TriageCell = {
  make: TriageMultiState$TriageCell
};

function TriageMultiState$TriageMultiIcon(Props) {
  var setAnchorEl = Props.setAnchorEl;
  Props.state;
  Props.invariant;
  return React.createElement(Tooltip.default, {
              placement: "top",
              children: React.createElement("div", {
                    className: Css.merge(/* :: */[
                          Css.style(/* :: */[
                                Css.marginRight(Css.rem(1)),
                                /* [] */0
                              ]),
                          /* :: */[
                            "smaller-icon",
                            /* [] */0
                          ]
                        ]),
                    role: "button",
                    onClick: (function ($$event) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      return Caml_option.some($$event.currentTarget);
                                    }));
                      })
                  }, React.createElement(Mui$BsConsole.IconButton.make, {
                        ariaLabel: "Set status for groups",
                        color: "secondary",
                        children: React.createElement(Inbox.default, { })
                      })),
              title: I18N$BsConsole.get(undefined, "Set status for groups")
            });
}

var TriageMultiIcon = {
  make: TriageMultiState$TriageMultiIcon
};

function TriageMultiState(Props) {
  var config = Props.config;
  var handleSetState = Props.handleSetState;
  var aperture = Props.aperture;
  var targetType = Props.targetType;
  var state = Props.state;
  var invariant = Props.invariant;
  var projectName = Props.projectName;
  var sort = Props.sort;
  var match = React.useState((function () {
          
        }));
  var setClickedState = match[1];
  var clickedState = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  var tmp;
  var exit = 0;
  if (ReopenGate$BsConsole.allow && clickedState !== undefined) {
    tmp = React.createElement(Reopen$BsConsole.make, {
          config: config,
          nextState: clickedState,
          handleSetState: handleSetState,
          projectName: Belt_Option.getWithDefault(projectName, ""),
          onClose: (function (param) {
              Curry._1(setClickedState, (function (param) {
                      
                    }));
              Curry._1(setAnchorEl, (function (param) {
                      
                    }));
              
            }),
          aperture: aperture,
          sort: sort
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1;
    if (CoronerdGate$BsConsole.isStateMachineDisabled) {
      if (state !== undefined) {
        switch (state) {
          case /* Open */0 :
              tmp$1 = [
                /* InProgress */1,
                /* Muted */3,
                /* Resolved */2
              ];
              break;
          case /* InProgress */1 :
              tmp$1 = [
                /* Open */0,
                /* Muted */3,
                /* Resolved */2
              ];
              break;
          case /* Resolved */2 :
              tmp$1 = [
                /* Open */0,
                /* InProgress */1,
                /* Muted */3
              ];
              break;
          case /* Muted */3 :
              tmp$1 = [
                /* Open */0,
                /* InProgress */1,
                /* Resolved */2
              ];
              break;
          
        }
      } else {
        tmp$1 = [
          /* Open */0,
          /* InProgress */1,
          /* Muted */3,
          /* Resolved */2
        ];
      }
    } else {
      tmp$1 = state !== undefined ? (
          state !== 2 ? (
              state >= 3 ? [
                  /* Open */0,
                  /* Resolved */2
                ] : [
                  /* Muted */3,
                  /* Resolved */2
                ]
            ) : [
              /* Open */0,
              /* Muted */3
            ]
        ) : [
          /* Open */0,
          /* Muted */3,
          /* Resolved */2
        ];
    }
    tmp = React.createElement(MenuList.default, {
          classes: listClasses,
          children: Belt_Array.map(tmp$1, (function (s) {
                  if (s >= 2) {
                    if (ReopenGate$BsConsole.allow) {
                      return React.createElement(MenuItem.default, {
                                  classes: listItemClasses,
                                  onClick: (function (_e) {
                                      if (s !== 2) {
                                        if (s >= 3) {
                                          return Curry._1(setClickedState, (function (param) {
                                                        return /* Muted */3;
                                                      }));
                                        } else {
                                          Curry._2(handleSetState, s, /* NoInvariant */0);
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        
                                                      }));
                                        }
                                      } else {
                                        return Curry._1(setClickedState, (function (param) {
                                                      return /* Resolved */2;
                                                    }));
                                      }
                                    }),
                                  children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " until...",
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "%s until..."
                                          ]), Issues$BsConsole.State.toStringCapitalized(s))
                                });
                    } else {
                      return React.createElement(MenuItem.default, {
                                  classes: listItemClasses,
                                  onClick: (function (param) {
                                      Curry._2(handleSetState, s, /* NoInvariant */0);
                                      return Curry._1(setAnchorEl, (function (param) {
                                                    
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, Issues$BsConsole.State.toStringCapitalized(s))
                                });
                    }
                  } else {
                    return React.createElement(MenuItem.default, {
                                classes: listItemClasses,
                                onClick: (function (_event) {
                                    Curry._2(handleSetState, s, /* NoInvariant */0);
                                    return Curry._1(setAnchorEl, (function (param) {
                                                  
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, Issues$BsConsole.State.toStringCapitalized(s))
                              });
                  }
                }))
        });
  }
  return React.createElement(React.Fragment, undefined, targetType !== -1038879658 ? (
                targetType !== -120082594 ? null : React.createElement(TriageMultiState$TriageMultiIcon, {
                        setAnchorEl: setAnchorEl,
                        state: state,
                        invariant: invariant
                      })
              ) : React.createElement(TriageMultiState$TriageCell, {
                    setAnchorEl: setAnchorEl,
                    state: state,
                    invariant: invariant
                  }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  onClose: (function (param) {
                      Curry._1(setClickedState, (function (param) {
                              
                            }));
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    }),
                  children: tmp
                }));
}

function make(config, handleSetState, aperture, targetTypeOpt, state, invariant, projectName, sort, children) {
  var targetType = targetTypeOpt !== undefined ? targetTypeOpt : /* TriageCell */-1038879658;
  var tmp = {
    config: config,
    handleSetState: handleSetState,
    aperture: aperture,
    targetType: targetType,
    invariant: invariant
  };
  if (state !== undefined) {
    tmp.state = Caml_option.valFromOption(state);
  }
  if (projectName !== undefined) {
    tmp.projectName = Caml_option.valFromOption(projectName);
  }
  if (sort !== undefined) {
    tmp.sort = Caml_option.valFromOption(sort);
  }
  return ReasonReactCompat.wrapReactForReasonReact(TriageMultiState, tmp, children);
}

var Re = {
  make: make
};

var make$1 = TriageMultiState;

exports.listClasses = listClasses;
exports.listItemClasses = listItemClasses;
exports.button = button;
exports.getStatusStringAndTooltip = getStatusStringAndTooltip;
exports.TriageCell = TriageCell;
exports.TriageMultiIcon = TriageMultiIcon;
exports.make = make$1;
exports.Re = Re;
/* listClasses Not a pure module */
