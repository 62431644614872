// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function toString(t) {
  switch (t) {
    case /* English */0 :
        return "English";
    case /* Japanese */1 :
        return "日本語";
    case /* Korean */2 :
        return "한국어";
    case /* OldEnglish */3 :
        return "Ye Olde English";
    case /* Polish */4 :
        return "Polskie";
    
  }
}

function toISO639(t) {
  switch (t) {
    case /* English */0 :
        return "en";
    case /* Japanese */1 :
        return "ja";
    case /* Korean */2 :
        return "ko";
    case /* OldEnglish */3 :
        return "ang";
    case /* Polish */4 :
        return "pl";
    
  }
}

function fromISO639(code) {
  switch (code) {
    case "ang" :
        return /* OldEnglish */3;
    case "en" :
        return /* English */0;
    case "ja" :
        return /* Japanese */1;
    case "ko" :
        return /* Korean */2;
    case "pl" :
        return /* Polish */4;
    default:
      return ;
  }
}

function fromBCP47(code) {
  return Belt_Option.flatMap(Caml_option.undefined_to_opt(code.split("_").shift()), fromISO639);
}

function map(fn) {
  return Belt_List.map(/* :: */[
              /* English */0,
              /* :: */[
                /* Japanese */1,
                /* [] */0
              ]
            ], fn);
}

exports.toString = toString;
exports.toISO639 = toISO639;
exports.fromISO639 = fromISO639;
exports.fromBCP47 = fromBCP47;
exports.map = map;
/* No side effect */
