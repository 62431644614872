// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var AlertsRouter$BsConsole = require("./AlertsRouter.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var AlertsListView$BsConsole = require("../../alerts/AlertsListView.js");
var AlertsDetailView$BsConsole = require("../../alerts/AlertsDetailView.js");
var AlertsAlertConfigApi$BsConsole = require("../../alerts/api/AlertsAlertConfigApi.js");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.height(Css.px(4)),
        /* :: */[
          Css.margin2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription,
  indicator: indicator
};

function ErrorThresholdAlerts(Props) {
  var config = Props.config;
  var token = Props.token;
  var project = Props.project;
  var endpoint = Props.endpoint;
  var handleChangeUrl = Props.handleChangeUrl;
  var id = Props.id;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setAlertConfigs = match[1];
  var alertConfigs = match[0];
  var universeName = config.universe.name;
  var projectName = project.name;
  var fetchAlertConfigs = function (param) {
    var arg = AlertsAlertConfigApi$BsConsole.get(endpoint, config.universe.name, project.name, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setAlertConfigs, (function (param) {
                                return result.values;
                              }));
                }));
  };
  React.useEffect((function () {
          fetchAlertConfigs(undefined);
          
        }), /* tuple */[
        token,
        endpoint,
        universeName,
        projectName
      ]);
  if (id === undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body2 */9,
                    className: featureDescription,
                    children: I18N$BsConsole.show(undefined, "Set up alerts to trigger on error ingestion criteria.")
                  }), React.createElement(AlertsListView$BsConsole.make, {
                    config: config,
                    token: token,
                    endpoint: endpoint,
                    universeName: universeName,
                    projectName: projectName,
                    alertConfigs: alertConfigs,
                    setAlertConfigs: setAlertConfigs,
                    fetchAlertConfigs: fetchAlertConfigs,
                    handleChangeUrl: handleChangeUrl
                  }));
  }
  var specifiedAlert = Belt_List.getBy(alertConfigs, (function (alertConfig) {
          return alertConfig.id === id;
        }));
  var alertDetails = specifiedAlert !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              children: I18N$BsConsole.showSkip(specifiedAlert.name)
            }), React.createElement(AlertsDetailView$BsConsole.make, {
              config: config,
              handleChangeUrl: handleChangeUrl,
              alertConfigs: alertConfigs,
              fetchAlertConfigs: fetchAlertConfigs,
              alert: specifiedAlert,
              token: token,
              endpoint: endpoint,
              universeName: universeName,
              projectName: projectName
            })) : React.createElement(BtTypography$BsConsole.make, {
          variant: /* Body2 */9,
          className: featureDescription,
          children: I18N$BsConsole.show(undefined, "No alert found.")
        });
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  variant: "text",
                  size: "small",
                  color: "primary",
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                    projectName,
                                    {
                                      tab: AlertsRouter$BsConsole.typeToString(/* ErrorThresholdAlerts */0),
                                      id: undefined
                                    }
                                  ]));
                    }),
                  children: null
                }, React.createElement(KeyboardArrowLeft.default, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ])
                    }), I18N$BsConsole.show(undefined, "All alerts")), alertDetails);
}

var make = ErrorThresholdAlerts;

exports.Styles = Styles;
exports.make = make;
/* pageMarginBottom Not a pure module */
