// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Validator = require("validator");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var ApiWf$BsConsole = require("../workflows-api/ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var WfRouter$BsConsole = require("../WfRouter.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Radio = require("@material-ui/core/Radio");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Launch = require("@material-ui/icons/Launch");
var WorkflowVersion$BsConsole = require("../WorkflowVersion.js");
var MenuItem = require("@material-ui/core/MenuItem");
var ApiWfConnections$BsConsole = require("../workflows-api/ApiWfConnections.js");
var TextField = require("@material-ui/core/TextField");
var ApiWfIntegrations$BsConsole = require("../workflows-api/ApiWfIntegrations.js");
var RadioGroup = require("@material-ui/core/RadioGroup");
var Wf2AdvancedOptions$BsConsole = require("../Wf2AdvancedOptions.js");
var Assignment = require("@material-ui/icons/Assignment");
var Visibility = require("@material-ui/icons/Visibility");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var FormHelperText = require("@material-ui/core/FormHelperText");
var VisibilityOff = require("@material-ui/icons/VisibilityOff");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.height(Css.px(4)),
        /* :: */[
          Css.margin2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.flex(/* `num */[
                  5496390,
                  1
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var inputTitle = Css.style(/* :: */[
      Css.marginBottom(Css.px(10)),
      /* [] */0
    ]);

var topMargin = Css.style(/* :: */[
      Css.marginTop(Css.px(30)),
      /* [] */0
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(30)),
      /* [] */0
    ]);

var rightLeftMargin = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* :: */[
        Css.marginRight(Css.px(10)),
        /* [] */0
      ]
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.marginBottom(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var flexColumn = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var section = Css.merge(/* :: */[
      flexRow,
      /* :: */[
        bottomMargin,
        /* :: */[
          Css.style(/* :: */[
                Css.flexDirection(/* row */5693978),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var sectionRow = Css.merge(/* :: */[
      flexRow,
      /* :: */[
        Css.style(/* :: */[
              Css.flexDirection(/* row */5693978),
              /* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var sectionColumn = Css.merge(/* :: */[
      flexColumn,
      /* :: */[
        Css.style(/* :: */[
              Css.flex(/* `num */[
                    5496390,
                    1
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(80)),
          /* [] */0
        ]
      ]
    ]);

var continueButton = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var tooltipTitle = Css.style(/* :: */[
      Css.paddingTop(Css.px(1)),
      /* :: */[
        Css.paddingBottom(Css.px(1)),
        /* :: */[
          Css.textShadow(undefined, undefined, undefined, Css.hex(Colors$BsConsole.blackA45)),
          /* :: */[
            Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
            /* :: */[
              Css.fontWeight(Css.normal),
              /* :: */[
                Css.fontSize(Css.rem(0.75)),
                /* :: */[
                  Css.lineHeight(Css.em(1.35)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  indicator: indicator,
  inputItem: inputItem,
  inputTitle: inputTitle,
  topMargin: topMargin,
  bottomMargin: bottomMargin,
  rightLeftMargin: rightLeftMargin,
  flexRow: flexRow,
  flexColumn: flexColumn,
  section: section,
  sectionRow: sectionRow,
  sectionColumn: sectionColumn,
  buttonContainer: buttonContainer,
  continueButton: continueButton,
  tooltipTitle: tooltipTitle
};

function JiraServerConnection$Required(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: className,
              children: null
            }, children, React.createElement("span", {
                  style: {
                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                  }
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: JiraServerConnection$Required
};

function JiraServerConnection$Password(Props) {
  var show = Props.show;
  var handleToogle = Props.handleToogle;
  var children = Props.children;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.position(/* relative */903134412),
                      /* [] */0
                    ]
                  ])
            }, children, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: show ? "Hide password" : "Show password",
                  size: "small",
                  className: Css.style(/* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.right(/* zero */-789508312),
                          /* :: */[
                            Css.maxHeight(Css.px(39)),
                            /* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(handleToogle, undefined);
                    }),
                  children: show ? React.createElement(Visibility.default, {
                          fontSize: "small"
                        }) : React.createElement(VisibilityOff.default, {
                          fontSize: "small"
                        })
                }));
}

var Password = {
  make: JiraServerConnection$Password
};

function JiraServerConnection$Section(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    section,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var Section = {
  make: JiraServerConnection$Section
};

function JiraServerConnection$SectionRow(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionRow,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionRow = {
  make: JiraServerConnection$SectionRow
};

function JiraServerConnection$SectionColumn(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionColumn,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionColumn = {
  make: JiraServerConnection$SectionColumn
};

function JiraServerConnection$OAuth1Options(Props) {
  var isBacktraceSetUpComplete = Props.isBacktraceSetUpComplete;
  var isJiraSetUpComplete = Props.isJiraSetUpComplete;
  var privateRsaKey_selection = Props.privateRsaKey_selection;
  var publicRsaKey_response = Props.publicRsaKey_response;
  var shouldProvideRsaKeys_selection = Props.shouldProvideRsaKeys_selection;
  var projectName = Props.projectName;
  var showValidationErrors = Props.showValidationErrors;
  var handleShouldProvideRsaKeysChange = Props.handleShouldProvideRsaKeysChange;
  var handlePrivateKeyChange = Props.handlePrivateKeyChange;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleJiraSetUpCompleted = Props.handleJiraSetUpCompleted;
  var consumerKey_selection = Props.consumerKey_selection;
  var handleSetConsumerKey = Props.handleSetConsumerKey;
  var route = Props.route;
  var docLinkOAuth1 = "https://docs.saucelabs.com/error-reporting/workflow-integrations/issue-tracking/jira/#oauth-10";
  var match = React.useState((function () {
          return false;
        }));
  var setShowAdvancedOptions = match[1];
  if (isBacktraceSetUpComplete) {
    return React.createElement(React.Fragment, undefined, React.createElement(JiraServerConnection$SectionColumn, {
                    children: React.createElement("span", {
                          className: Css.merge(/* :: */[
                                bottomMargin,
                                /* :: */[
                                  topMargin,
                                  /* [] */0
                                ]
                              ])
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Heading6 */5,
                              children: null
                            }, I18N$BsConsole.show(undefined, "2. Finish set up in Jira"), isJiraSetUpComplete ? React.createElement(CheckCircle.default, {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(10)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.green)),
                                            /* [] */0
                                          ]
                                        ]),
                                    fontSize: "inherit"
                                  }) : null), isJiraSetUpComplete ? null : React.createElement(FormHelperText.default, {
                                children: null
                              }, I18N$BsConsole.show(undefined, "To create a new application link in Jira "), React.createElement("a", {
                                    href: docLinkOAuth1,
                                    rel: "noopener noreferrer",
                                    target: "_blank"
                                  }, I18N$BsConsole.show(undefined, "view our Jira Server documentation."))))
                  }), isJiraSetUpComplete ? null : React.createElement(React.Fragment, undefined, publicRsaKey_response !== undefined ? React.createElement(JiraServerConnection$SectionColumn, {
                            children: null
                          }, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.position(/* relative */903134412),
                                      /* [] */0
                                    ])
                              }, React.createElement(JiraServerConnection$Required, {
                                    children: I18N$BsConsole.show(undefined, "Copy Your Public RSA key")
                                  }), React.createElement(FormHelperText.default, {
                                    className: Css.style(/* :: */[
                                          Css.marginBottom(Css.px(10)),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "You will need this key to finish your set up in Jira.")
                                  }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                    ariaLabel: "Copy public RSA key to clipboard",
                                    className: Css.style(/* :: */[
                                          Css.position(/* absolute */-1013592457),
                                          /* :: */[
                                            Css.bottom(/* zero */-789508312),
                                            /* :: */[
                                              Css.right(/* zero */-789508312),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    onClick: (function (param) {
                                        var __x = window.navigator.clipboard.writeText(publicRsaKey_response);
                                        var __x$1 = __x.then((function (param) {
                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, 4000, undefined, I18N$BsConsole.show(undefined, "Public RSA key copied to clipboard"));
                                                return Promise.resolve(undefined);
                                              }));
                                        __x$1.catch((function (param) {
                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to copy callstack to clipboard"));
                                                return Promise.resolve(undefined);
                                              }));
                                        
                                      }),
                                    placement_tooltip: "top",
                                    title_tooltip: React.createElement("span", {
                                          className: tooltipTitle
                                        }, I18N$BsConsole.show(undefined, "Copy public RSA key to clipboard")),
                                    children: React.createElement(Assignment.default, {
                                          color: "inherit",
                                          fontSize: "inherit"
                                        })
                                  })), React.createElement(TextField.default, {
                                multiline: true,
                                disabled: true,
                                placeholder: "",
                                value: publicRsaKey_response,
                                color: "primary",
                                variant: "outlined",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.selector("textarea", /* :: */[
                                                Css.maxHeight(Css.rem(15)),
                                                /* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                onChange: (function (param) {
                                    
                                  })
                              })) : null, React.createElement(JiraServerConnection$Section, {
                          children: null,
                          classNames: buttonContainer
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  if (typeof route === "number" || route.tag) {
                                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                  projectName,
                                                  WfRouter$BsConsole.root,
                                                  undefined
                                                ]));
                                  } else {
                                    return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                                                    error: undefined
                                                  }]));
                                  }
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              className: continueButton,
                              disabled: false,
                              onClick: (function (param) {
                                  return Curry._1(handleJiraSetUpCompleted, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Continue")
                            }))));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(JiraServerConnection$Section, {
                    children: null
                  }, React.createElement(JiraServerConnection$SectionColumn, {
                        children: null,
                        classNames: Css.style(/* :: */[
                              Css.minWidth(Css.pct(50)),
                              /* [] */0
                            ])
                      }, React.createElement(JiraServerConnection$Required, {
                            children: I18N$BsConsole.show(undefined, "Generate Private RSA key")
                          }), React.createElement(FormHelperText.default, {
                            children: I18N$BsConsole.show(undefined, "Allow Backtrace to generate key or provide your own")
                          }), React.createElement(RadioGroup.default, {
                            value: shouldProvideRsaKeys_selection,
                            onChange: (function (e) {
                                var tmp;
                                try {
                                  tmp = Pervasives.bool_of_string(e.target.value);
                                }
                                catch (exn){
                                  tmp = false;
                                }
                                return Curry._1(handleShouldProvideRsaKeysChange, tmp);
                              }),
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.flexDirection(/* row */5693978),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: null
                          }, React.createElement(FormControlLabel.default, {
                                value: true,
                                label: I18N$BsConsole.get(undefined, "Yes"),
                                control: React.createElement(Radio.default, {
                                      color: "primary"
                                    })
                              }), React.createElement(FormControlLabel.default, {
                                value: false,
                                label: I18N$BsConsole.get(undefined, "No"),
                                control: React.createElement(Radio.default, {
                                      color: "primary"
                                    })
                              }))), shouldProvideRsaKeys_selection ? null : React.createElement(JiraServerConnection$SectionColumn, {
                          children: null,
                          classNames: Css.style(/* :: */[
                                Css.marginLeft(Css.px(15)),
                                /* [] */0
                              ])
                        }, React.createElement(JiraServerConnection$Required, {
                              children: I18N$BsConsole.show(undefined, "Private RSA key")
                            }), React.createElement(FormHelperText.default, {
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.px(10)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, I18N$BsConsole.show(undefined, "To generate your own RSA keys "), React.createElement("a", {
                                  href: docLinkOAuth1,
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }, I18N$BsConsole.show(undefined, "view documentation."))), React.createElement(TextField.default, {
                              multiline: true,
                              placeholder: "",
                              value: Belt_Option.getWithDefault(privateRsaKey_selection, ""),
                              color: "primary",
                              variant: "outlined",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.selector("textarea", /* :: */[
                                              Css.minHeight(Css.rem(5)),
                                              /* :: */[
                                                Css.maxHeight(Css.rem(10)),
                                                /* [] */0
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              onChange: (function ($$event) {
                                  return Curry._1(handlePrivateKeyChange, Belt_Option.getWithDefault($$event.target.value, ""));
                                })
                            }), showValidationErrors && Belt_Option.isNone(privateRsaKey_selection) ? React.createElement(FormHelperText.default, {
                                error: true,
                                children: I18N$BsConsole.show(undefined, "Private RSA key is required")
                              }) : null)), React.createElement(Wf2AdvancedOptions$BsConsole.make, {
                    isOpen: match[0],
                    handleToggle: (function (param) {
                        return Curry._1(setShowAdvancedOptions, (function (prevState) {
                                      return !prevState;
                                    }));
                      }),
                    children: React.createElement(JiraServerConnection$SectionColumn, {
                          children: null,
                          classNames: Css.style(/* :: */[
                                Css.marginRight(Css.px(15)),
                                /* [] */0
                              ])
                        }, React.createElement(JiraServerConnection$Required, {
                              className: inputTitle,
                              children: I18N$BsConsole.show(undefined, "Consumer Key")
                            }), React.createElement(TextField.default, {
                              autoFocus: false,
                              disabled: false,
                              placeholder: "",
                              value: consumerKey_selection,
                              color: "primary",
                              variant: "outlined",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.selector("input", /* :: */[
                                            Css.important(Css.padding(Css.px(10))),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ])
                              },
                              onChange: (function ($$event) {
                                  return Curry._1(handleSetConsumerKey, Belt_Option.getWithDefault($$event.target.value, ""));
                                })
                            }), showValidationErrors && consumerKey_selection.trim() === "" ? React.createElement(FormHelperText.default, {
                                error: true,
                                children: I18N$BsConsole.show(undefined, "Consumer key is required. You can use the default of \"BacktraceIO\"")
                              }) : null)
                  }));
  }
}

var OAuth1Options = {
  make: JiraServerConnection$OAuth1Options
};

function JiraServerConnection$OAuth2Options(Props) {
  var clientId_selection = Props.clientId_selection;
  var clientSecret_selection = Props.clientSecret_selection;
  var show_clientSecret_selection = Props.show_clientSecret_selection;
  var isBacktraceSetUpComplete = Props.isBacktraceSetUpComplete;
  var showValidationErrors = Props.showValidationErrors;
  var handleClientIdChange = Props.handleClientIdChange;
  var handleClientSecretChange = Props.handleClientSecretChange;
  var handleShowClientSecretToogle = Props.handleShowClientSecretToogle;
  return React.createElement(React.Fragment, undefined, React.createElement(JiraServerConnection$SectionColumn, {
                  children: React.createElement("span", {
                        className: Css.merge(/* :: */[
                              bottomMargin,
                              /* :: */[
                                topMargin,
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading6 */5,
                            children: null
                          }, I18N$BsConsole.show(undefined, "2. Finish set up in Jira"), isBacktraceSetUpComplete ? React.createElement(CheckCircle.default, {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.px(10)),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.green)),
                                          /* [] */0
                                        ]
                                      ]),
                                  fontSize: "inherit"
                                }) : null), isBacktraceSetUpComplete ? null : React.createElement(FormHelperText.default, {
                              children: null
                            }, I18N$BsConsole.show(undefined, "To configure client ID and secret, please "), React.createElement("a", {
                                  href: "https://docs.saucelabs.com/error-reporting/workflow-integrations/issue-tracking/jira/#oauth-20",
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }, I18N$BsConsole.show(undefined, "view our Jira Server documentation.")))),
                  classNames: bottomMargin
                }), isBacktraceSetUpComplete ? null : React.createElement(JiraServerConnection$Section, {
                    children: null
                  }, React.createElement(JiraServerConnection$SectionColumn, {
                        children: null,
                        classNames: Css.style(/* :: */[
                              Css.marginRight(Css.px(15)),
                              /* [] */0
                            ])
                      }, React.createElement(JiraServerConnection$Required, {
                            className: inputTitle,
                            children: I18N$BsConsole.show(undefined, "Client ID")
                          }), React.createElement(TextField.default, {
                            autoFocus: false,
                            disabled: false,
                            autoComplete: "new-password",
                            placeholder: "",
                            value: Belt_Option.getWithDefault(clientId_selection, ""),
                            color: "primary",
                            variant: "outlined",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.important(Css.padding(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ])
                            },
                            onChange: (function ($$event) {
                                return Curry._1(handleClientIdChange, Belt_Option.getWithDefault($$event.target.value, ""));
                              })
                          }), showValidationErrors && Belt_Option.isNone(clientId_selection) ? React.createElement(FormHelperText.default, {
                              error: true,
                              children: I18N$BsConsole.show(undefined, "Client ID is required")
                            }) : null), React.createElement(JiraServerConnection$SectionColumn, {
                        children: null,
                        classNames: Css.style(/* :: */[
                              Css.marginLeft(Css.px(15)),
                              /* [] */0
                            ])
                      }, React.createElement(JiraServerConnection$Required, {
                            className: inputTitle,
                            children: I18N$BsConsole.show(undefined, "Client secret")
                          }), React.createElement(JiraServerConnection$Password, {
                            show: show_clientSecret_selection,
                            handleToogle: handleShowClientSecretToogle,
                            children: React.createElement(TextField.default, {
                                  autoFocus: false,
                                  disabled: false,
                                  autoComplete: "new-password",
                                  placeholder: "",
                                  value: Belt_Option.getWithDefault(clientSecret_selection, ""),
                                  color: "primary",
                                  variant: "outlined",
                                  type: show_clientSecret_selection ? "text" : "password",
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.selector("input", /* :: */[
                                                  Css.important(Css.padding(Css.px(10))),
                                                  /* :: */[
                                                    Css.important(Css.paddingRight(Css.px(45))),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  onChange: (function ($$event) {
                                      return Curry._1(handleClientSecretChange, Belt_Option.getWithDefault($$event.target.value, ""));
                                    })
                                })
                          }), showValidationErrors && Belt_Option.isNone(clientSecret_selection) ? React.createElement(FormHelperText.default, {
                              error: true,
                              children: I18N$BsConsole.show(undefined, "Client secret is required")
                            }) : null)));
}

var OAuth2Options = {
  make: JiraServerConnection$OAuth2Options
};

function JiraServerConnection$BasicAuthOptions(Props) {
  var userName_selection = Props.userName_selection;
  var password_selection = Props.password_selection;
  var show_password_selection = Props.show_password_selection;
  var showValidationErrors = Props.showValidationErrors;
  var handleUserNameChange = Props.handleUserNameChange;
  var handlePasswordChange = Props.handlePasswordChange;
  var handleShowPasswordToogle = Props.handleShowPasswordToogle;
  return React.createElement(JiraServerConnection$Section, {
              children: null
            }, React.createElement(JiraServerConnection$SectionColumn, {
                  children: null,
                  classNames: Css.style(/* :: */[
                        Css.marginRight(Css.px(15)),
                        /* [] */0
                      ])
                }, React.createElement(JiraServerConnection$Required, {
                      className: inputTitle,
                      children: I18N$BsConsole.show(undefined, "Username")
                    }), React.createElement(TextField.default, {
                      autoFocus: false,
                      disabled: false,
                      autoComplete: "new-password",
                      placeholder: "",
                      value: Belt_Option.getWithDefault(userName_selection, ""),
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.selector("input", /* :: */[
                                    Css.important(Css.padding(Css.px(10))),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ])
                      },
                      onChange: (function ($$event) {
                          return Curry._1(handleUserNameChange, Belt_Option.getWithDefault($$event.target.value, ""));
                        })
                    }), showValidationErrors && Belt_Option.isNone(userName_selection) ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Username is required")
                      }) : null), React.createElement(JiraServerConnection$SectionColumn, {
                  children: null,
                  classNames: Css.style(/* :: */[
                        Css.marginLeft(Css.px(15)),
                        /* [] */0
                      ])
                }, React.createElement(JiraServerConnection$Required, {
                      className: inputTitle,
                      children: I18N$BsConsole.show(undefined, "Password")
                    }), React.createElement(JiraServerConnection$Password, {
                      show: show_password_selection,
                      handleToogle: handleShowPasswordToogle,
                      children: React.createElement(TextField.default, {
                            autoFocus: false,
                            disabled: false,
                            autoComplete: "new-password",
                            placeholder: "",
                            value: Belt_Option.getWithDefault(password_selection, ""),
                            color: "primary",
                            variant: "outlined",
                            type: show_password_selection ? "text" : "password",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.selector("input", /* :: */[
                                            Css.important(Css.padding(Css.px(10))),
                                            /* :: */[
                                              Css.important(Css.paddingRight(Css.px(45))),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            onChange: (function ($$event) {
                                return Curry._1(handlePasswordChange, Belt_Option.getWithDefault($$event.target.value, ""));
                              })
                          })
                    }), showValidationErrors && Belt_Option.isNone(userName_selection) ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Password is required")
                      }) : React.createElement(FormHelperText.default, {
                        children: I18N$BsConsole.show(undefined, "You may be able to use an api key")
                      })));
}

var BasicAuthOptions = {
  make: JiraServerConnection$BasicAuthOptions
};

function JiraServerConnection$SetUpConnection(Props) {
  var isBacktraceSetUpComplete = Props.isBacktraceSetUpComplete;
  var isJiraSetUpComplete = Props.isJiraSetUpComplete;
  var connectionName_selection = Props.connectionName_selection;
  var jiraServerUrl_selection = Props.jiraServerUrl_selection;
  var jiraServerUrl_error = Props.jiraServerUrl_error;
  var authType_selection = Props.authType_selection;
  var authTypes_available = Props.authTypes_available;
  var consumerKey_selection = Props.consumerKey_selection;
  var userName_selection = Props.userName_selection;
  var password_selection = Props.password_selection;
  var show_password_selection = Props.show_password_selection;
  var privateRsaKey_selection = Props.privateRsaKey_selection;
  var publicRsaKey_response = Props.publicRsaKey_response;
  var shouldProvideRsaKeys_selection = Props.shouldProvideRsaKeys_selection;
  var clientId_selection = Props.clientId_selection;
  var clientSecret_selection = Props.clientSecret_selection;
  var show_clientSecret_selection = Props.show_clientSecret_selection;
  var projectName = Props.projectName;
  var showValidationErrors = Props.showValidationErrors;
  var handleSetConnectionName = Props.handleSetConnectionName;
  var handleSetJiraServerUrlError = Props.handleSetJiraServerUrlError;
  var handleSetAuthType = Props.handleSetAuthType;
  var handleSetJiraServerUrl = Props.handleSetJiraServerUrl;
  var handleUserNameChange = Props.handleUserNameChange;
  var handlePasswordChange = Props.handlePasswordChange;
  var handleShowPasswordToogle = Props.handleShowPasswordToogle;
  var handleShouldProvideRsaKeysChange = Props.handleShouldProvideRsaKeysChange;
  var handlePrivateKeyChange = Props.handlePrivateKeyChange;
  var handleClientIdChange = Props.handleClientIdChange;
  var handleClientSecretChange = Props.handleClientSecretChange;
  var handleShowClientSecretToogle = Props.handleShowClientSecretToogle;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleSave = Props.handleSave;
  var handleJiraSetUpCompleted = Props.handleJiraSetUpCompleted;
  var handleSetConsumerKey = Props.handleSetConsumerKey;
  var route = Props.route;
  var tmp;
  switch (authType_selection) {
    case /* Basic */0 :
        tmp = React.createElement(JiraServerConnection$BasicAuthOptions, {
              userName_selection: userName_selection,
              password_selection: password_selection,
              show_password_selection: show_password_selection,
              showValidationErrors: showValidationErrors,
              handleUserNameChange: handleUserNameChange,
              handlePasswordChange: handlePasswordChange,
              handleShowPasswordToogle: handleShowPasswordToogle
            });
        break;
    case /* OAuth1 */1 :
        tmp = React.createElement(JiraServerConnection$OAuth1Options, {
              isBacktraceSetUpComplete: isBacktraceSetUpComplete,
              isJiraSetUpComplete: isJiraSetUpComplete,
              privateRsaKey_selection: privateRsaKey_selection,
              publicRsaKey_response: publicRsaKey_response,
              shouldProvideRsaKeys_selection: shouldProvideRsaKeys_selection,
              projectName: projectName,
              showValidationErrors: showValidationErrors,
              handleShouldProvideRsaKeysChange: handleShouldProvideRsaKeysChange,
              handlePrivateKeyChange: handlePrivateKeyChange,
              handleChangeUrl: handleChangeUrl,
              handleJiraSetUpCompleted: handleJiraSetUpCompleted,
              consumerKey_selection: consumerKey_selection,
              handleSetConsumerKey: handleSetConsumerKey,
              route: route
            });
        break;
    case /* OAuth2 */2 :
        tmp = React.createElement(JiraServerConnection$OAuth2Options, {
              clientId_selection: clientId_selection,
              clientSecret_selection: clientSecret_selection,
              show_clientSecret_selection: show_clientSecret_selection,
              isBacktraceSetUpComplete: isBacktraceSetUpComplete,
              showValidationErrors: showValidationErrors,
              handleClientIdChange: handleClientIdChange,
              handleClientSecretChange: handleClientSecretChange,
              handleShowClientSecretToogle: handleShowClientSecretToogle
            });
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.merge(/* :: */[
                        bottomMargin,
                        /* :: */[
                          topMargin,
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, I18N$BsConsole.show(undefined, "1. Set up connection"), isBacktraceSetUpComplete ? React.createElement(CheckCircle.default, {
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.px(10)),
                              /* :: */[
                                Css.color(Css.hex(Colors$BsConsole.green)),
                                /* [] */0
                              ]
                            ]),
                        fontSize: "inherit"
                      }) : null), isBacktraceSetUpComplete ? null : React.createElement(React.Fragment, undefined, React.createElement(JiraServerConnection$Section, {
                        children: null
                      }, React.createElement(JiraServerConnection$SectionColumn, {
                            children: null,
                            classNames: Css.style(/* :: */[
                                  Css.marginRight(Css.px(15)),
                                  /* [] */0
                                ])
                          }, React.createElement(JiraServerConnection$Required, {
                                className: inputTitle,
                                children: I18N$BsConsole.show(undefined, "Connection name")
                              }), React.createElement(TextField.default, {
                                autoFocus: false,
                                disabled: false,
                                placeholder: "",
                                value: connectionName_selection,
                                color: "primary",
                                variant: "outlined",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.selector("input", /* :: */[
                                              Css.important(Css.padding(Css.px(10))),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ])
                                },
                                onChange: (function ($$event) {
                                    return Curry._1(handleSetConnectionName, Belt_Option.getWithDefault($$event.target.value, ""));
                                  }),
                                onBlur: (function (param) {
                                    if (Validator.isURL(jiraServerUrl_selection)) {
                                      return Curry._1(handleSetJiraServerUrlError, false);
                                    } else {
                                      return Curry._1(handleSetJiraServerUrlError, true);
                                    }
                                  })
                              }), showValidationErrors && connectionName_selection.trim() === "" ? React.createElement(FormHelperText.default, {
                                  error: true,
                                  children: I18N$BsConsole.show(undefined, "Connection name is required")
                                }) : null), React.createElement(JiraServerConnection$SectionColumn, {
                            children: null,
                            classNames: Css.style(/* :: */[
                                  Css.marginLeft(Css.px(15)),
                                  /* [] */0
                                ])
                          }, React.createElement(JiraServerConnection$Required, {
                                className: inputTitle,
                                children: I18N$BsConsole.show(undefined, "Auth type")
                              }), React.createElement(Select.default, {
                                value: authType_selection,
                                onChange: (function ($$event, param) {
                                    var value = $$event.target.value;
                                    if (!(value == null)) {
                                      return Curry._1(handleSetAuthType, value);
                                    }
                                    
                                  }),
                                input: React.createElement(OutlinedInput.default, {
                                      classes: {
                                        root: Css.style(/* :: */[
                                              Css.width(/* auto */-1065951377),
                                              /* [] */0
                                            ])
                                      },
                                      labelWidth: 0
                                    }),
                                classes: {
                                  select: Css.merge(/* :: */[
                                        inputItem,
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.unsafe("width", "calc(100% - 45px)"),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]),
                                  selectMenu: Css.style(/* :: */[
                                        Css.paddingRight(Css.px(30)),
                                        /* [] */0
                                      ])
                                },
                                autoWidth: true,
                                children: authTypes_available.length !== 0 ? Belt_Array.map(authTypes_available, (function (authType) {
                                          return React.createElement(MenuItem.default, {
                                                      value: authType,
                                                      children: I18N$BsConsole.show(undefined, Curry._1(ApiWfConnections$BsConsole.JiraServerConnection.AuthType.typeToString, authType)),
                                                      key: Curry._1(ApiWfConnections$BsConsole.JiraServerConnection.AuthType.typeToString, authType)
                                                    });
                                        })) : [React.createElement(MenuItem.default, {
                                          children: I18N$BsConsole.show(undefined, "No options available")
                                        })]
                              }))), React.createElement(JiraServerConnection$Section, {
                        children: React.createElement(JiraServerConnection$SectionColumn, {
                              children: null
                            }, React.createElement(JiraServerConnection$Required, {
                                  className: inputTitle,
                                  children: I18N$BsConsole.show(undefined, "Jira Server url")
                                }), React.createElement(TextField.default, {
                                  autoFocus: false,
                                  disabled: false,
                                  placeholder: "",
                                  value: jiraServerUrl_selection,
                                  color: "primary",
                                  variant: "outlined",
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.selector("input", /* :: */[
                                                Css.important(Css.padding(Css.px(10))),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ])
                                  },
                                  onChange: (function ($$event) {
                                      var url = Belt_Option.getWithDefault($$event.target.value, "");
                                      if (Validator.isURL(url) && jiraServerUrl_error) {
                                        return Curry._2(handleSetJiraServerUrl, url, false);
                                      } else {
                                        return Curry._2(handleSetJiraServerUrl, url, jiraServerUrl_error);
                                      }
                                    }),
                                  onBlur: (function (param) {
                                      if (Validator.isURL(jiraServerUrl_selection)) {
                                        return Curry._1(handleSetJiraServerUrlError, false);
                                      } else {
                                        return Curry._1(handleSetJiraServerUrlError, true);
                                      }
                                    })
                                }), jiraServerUrl_error ? React.createElement(FormHelperText.default, {
                                    error: true,
                                    children: I18N$BsConsole.show(undefined, "Must be a valid url")
                                  }) : (
                                showValidationErrors && jiraServerUrl_selection.trim() === "" ? React.createElement(FormHelperText.default, {
                                        error: true,
                                        children: I18N$BsConsole.show(undefined, "Jira Server url is required")
                                      }) : null
                              ))
                      })), tmp, isBacktraceSetUpComplete ? null : React.createElement(JiraServerConnection$Section, {
                    children: null,
                    classNames: buttonContainer
                  }, React.createElement(Button.default, {
                        color: "primary",
                        onClick: (function (param) {
                            if (typeof route === "number" || route.tag) {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                            projectName,
                                            WfRouter$BsConsole.root,
                                            undefined
                                          ]));
                            } else {
                              return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                                              error: undefined
                                            }]));
                            }
                          }),
                        children: I18N$BsConsole.show(undefined, "Cancel")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        className: continueButton,
                        disabled: false,
                        onClick: (function (param) {
                            return Curry._1(handleSave, undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, "Continue")
                      })));
}

var SetUpConnection = {
  make: JiraServerConnection$SetUpConnection
};

function JiraServerConnection$AuthorizationConnection(Props) {
  var isBacktraceSetUpComplete = Props.isBacktraceSetUpComplete;
  var isJiraSetUpComplete = Props.isJiraSetUpComplete;
  var connectionId_selection = Props.connectionId_selection;
  var instanceUrl_selection = Props.instanceUrl_selection;
  var config = Props.config;
  var projectName = Props.projectName;
  var authType_selection = Props.authType_selection;
  var handleChangeUrl = Props.handleChangeUrl;
  var route = Props.route;
  var authorizeUrl;
  if (connectionId_selection !== undefined && instanceUrl_selection !== undefined) {
    var baseUrl = WorkflowVersion$BsConsole.current(config);
    if (typeof baseUrl === "number") {
      authorizeUrl = "";
    } else {
      var redirectUrl;
      var exit = 0;
      if (typeof route === "number" || route.tag) {
        exit = 1;
      } else {
        redirectUrl = encodeURIComponent(window.location.origin + "/settings/workflow-connections");
      }
      if (exit === 1) {
        redirectUrl = encodeURIComponent(window.location.origin + ("/p/" + (projectName + ("/settings/integrations?pluginId=jira-server&connectionId=" + String(connectionId_selection)))));
      }
      var tmp;
      switch (authType_selection) {
        case /* Basic */0 :
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "JiraServerConnection.re",
                    789,
                    23
                  ]
                ];
        case /* OAuth1 */1 :
            tmp = "1.0";
            break;
        case /* OAuth2 */2 :
            tmp = "2.0";
            break;
        
      }
      authorizeUrl = baseUrl[0] + ("/plugins/jira-server/external/oauth/" + (tmp + ("/connect?connectionId=" + (String(connectionId_selection) + ("&backtraceInstanceUrl=" + (instanceUrl_selection + ("&redirectUrl=" + redirectUrl)))))));
    }
  } else {
    authorizeUrl = "";
  }
  if (isBacktraceSetUpComplete && isJiraSetUpComplete) {
    return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.merge(/* :: */[
                          topMargin,
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.show(undefined, "3. Authorize Backtrace in Jira")
                  }), React.createElement(JiraServerConnection$Section, {
                    children: null,
                    classNames: buttonContainer
                  }, React.createElement(Button.default, {
                        color: "primary",
                        onClick: (function (param) {
                            if (typeof route === "number" || route.tag) {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                            projectName,
                                            WfRouter$BsConsole.root,
                                            undefined
                                          ]));
                            } else {
                              return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                                              error: undefined
                                            }]));
                            }
                          }),
                        children: I18N$BsConsole.show(undefined, "Cancel")
                      }), React.createElement("a", {
                        href: authorizeUrl,
                        rel: "noopener noreferrer"
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            className: continueButton,
                            disabled: false,
                            onClick: (function (param) {
                                return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_Created */Block.__(7, ["jiraServer"])]));
                              }),
                            children: null
                          }, I18N$BsConsole.show(undefined, "Authorize"), React.createElement(Launch.default, {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.em(0.5)),
                                      /* [] */0
                                    ]),
                                color: "inherit",
                                fontSize: "inherit"
                              })))));
  } else {
    return null;
  }
}

var AuthorizationConnection = {
  make: JiraServerConnection$AuthorizationConnection
};

var initialState_authTypes_available = [
  /* OAuth1 */1,
  /* OAuth2 */2,
  /* Basic */0
];

var initialState = {
  connectionId_selection: undefined,
  instanceUrl_selection: undefined,
  connectionName_selection: "",
  jiraServerUrl_selection: "",
  jiraServerUrl_error: false,
  authType_selection: /* OAuth2 */2,
  consumerKey_selection: "BacktraceIO",
  userName_selection: undefined,
  password_selection: undefined,
  show_password_selection: false,
  clientId_selection: undefined,
  clientSecret_selection: undefined,
  show_clientSecret_selection: false,
  shouldProvideRsaKeys_selection: true,
  privateRsaKey_selection: undefined,
  publicRsaKey_response: undefined,
  authTypes_available: initialState_authTypes_available,
  isBacktraceSetUpComplete: false,
  isJiraSetUpComplete: false,
  showValidationErrors: false,
  errorMessage: ""
};

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ToogleShowPassword_Selection */0 :
          var newrecord = Caml_obj.caml_obj_dup(state);
          newrecord.show_password_selection = !state.show_password_selection;
          return newrecord;
      case /* ToogleShowClientSecret_Selection */1 :
          var newrecord$1 = Caml_obj.caml_obj_dup(state);
          newrecord$1.show_clientSecret_selection = !state.show_clientSecret_selection;
          return newrecord$1;
      case /* JiraSetUpCompleted */2 :
          var newrecord$2 = Caml_obj.caml_obj_dup(state);
          newrecord$2.isJiraSetUpComplete = true;
          return newrecord$2;
      
    }
  } else {
    switch (action.tag | 0) {
      case /* SetConnectionName_Selection */0 :
          var newrecord$3 = Caml_obj.caml_obj_dup(state);
          newrecord$3.connectionName_selection = action[0];
          return newrecord$3;
      case /* SetJiraServerUrl_Selection */1 :
          var newrecord$4 = Caml_obj.caml_obj_dup(state);
          newrecord$4.jiraServerUrl_error = action[1];
          newrecord$4.jiraServerUrl_selection = action[0];
          return newrecord$4;
      case /* SetJiraServerUrl_error */2 :
          var newrecord$5 = Caml_obj.caml_obj_dup(state);
          newrecord$5.jiraServerUrl_error = action[0];
          return newrecord$5;
      case /* SetAuthType_Selection */3 :
          var newrecord$6 = Caml_obj.caml_obj_dup(state);
          newrecord$6.privateRsaKey_selection = undefined;
          newrecord$6.shouldProvideRsaKeys_selection = true;
          newrecord$6.clientSecret_selection = undefined;
          newrecord$6.clientId_selection = undefined;
          newrecord$6.password_selection = undefined;
          newrecord$6.userName_selection = undefined;
          newrecord$6.authType_selection = action[0];
          return newrecord$6;
      case /* SetConsumerKey_Selection */4 :
          var newrecord$7 = Caml_obj.caml_obj_dup(state);
          newrecord$7.consumerKey_selection = action[0];
          return newrecord$7;
      case /* SetUserName_Selection */5 :
          var newrecord$8 = Caml_obj.caml_obj_dup(state);
          newrecord$8.userName_selection = action[0];
          return newrecord$8;
      case /* SetPassword_Selection */6 :
          var newrecord$9 = Caml_obj.caml_obj_dup(state);
          newrecord$9.password_selection = action[0];
          return newrecord$9;
      case /* SetClientId_Selection */7 :
          var newrecord$10 = Caml_obj.caml_obj_dup(state);
          newrecord$10.clientId_selection = action[0];
          return newrecord$10;
      case /* SetClientSecret_Selection */8 :
          var newrecord$11 = Caml_obj.caml_obj_dup(state);
          newrecord$11.clientSecret_selection = action[0];
          return newrecord$11;
      case /* SetShouldProvideRsaKeys_Selection */9 :
          var newrecord$12 = Caml_obj.caml_obj_dup(state);
          newrecord$12.shouldProvideRsaKeys_selection = action[0];
          return newrecord$12;
      case /* SetPrivateRsaKey_Selection */10 :
          var newrecord$13 = Caml_obj.caml_obj_dup(state);
          newrecord$13.privateRsaKey_selection = action[0];
          return newrecord$13;
      case /* SetConnectionIdAndInstanceURL */11 :
          var newrecord$14 = Caml_obj.caml_obj_dup(state);
          newrecord$14.isJiraSetUpComplete = action[3];
          newrecord$14.isBacktraceSetUpComplete = action[2];
          newrecord$14.instanceUrl_selection = action[1];
          newrecord$14.connectionId_selection = action[0];
          return newrecord$14;
      case /* SetConnectionIdAndInstanceURLANDPublicKey */12 :
          var newrecord$15 = Caml_obj.caml_obj_dup(state);
          newrecord$15.isJiraSetUpComplete = action[4];
          newrecord$15.isBacktraceSetUpComplete = action[3];
          newrecord$15.publicRsaKey_response = action[2];
          newrecord$15.instanceUrl_selection = action[1];
          newrecord$15.connectionId_selection = action[0];
          return newrecord$15;
      case /* SetErrorMessage */13 :
          var newrecord$16 = Caml_obj.caml_obj_dup(state);
          newrecord$16.errorMessage = action[0];
          return newrecord$16;
      case /* ShowValidationErrors */14 :
          var newrecord$17 = Caml_obj.caml_obj_dup(state);
          newrecord$17.showValidationErrors = action[0];
          return newrecord$17;
      case /* AutoPopulateState */15 :
          var autopopulate = action[0];
          var newrecord$18 = Caml_obj.caml_obj_dup(state);
          newrecord$18.isJiraSetUpComplete = autopopulate.isJiraSetUpComplete;
          newrecord$18.isBacktraceSetUpComplete = autopopulate.isBacktraceSetUpComplete;
          newrecord$18.publicRsaKey_response = autopopulate.publicRsaKey_response;
          newrecord$18.clientId_selection = autopopulate.clientId_selection;
          newrecord$18.userName_selection = autopopulate.userName_selection;
          newrecord$18.consumerKey_selection = autopopulate.consumerKey_selection;
          newrecord$18.authType_selection = autopopulate.authType_selection;
          newrecord$18.jiraServerUrl_selection = autopopulate.jiraServerUrl_selection;
          newrecord$18.connectionName_selection = autopopulate.connectionName_selection;
          newrecord$18.instanceUrl_selection = autopopulate.instanceUrl_selection;
          newrecord$18.connectionId_selection = autopopulate.connectionId_selection;
          return newrecord$18;
      
    }
  }
}

function JiraServerConnection(Props) {
  var route = Props.route;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleSetErrorMessage = Props.handleSetErrorMessage;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var errorMessage = match$1.errorMessage;
  var isJiraSetUpComplete = match$1.isJiraSetUpComplete;
  var isBacktraceSetUpComplete = match$1.isBacktraceSetUpComplete;
  var privateRsaKey_selection = match$1.privateRsaKey_selection;
  var shouldProvideRsaKeys_selection = match$1.shouldProvideRsaKeys_selection;
  var clientSecret_selection = match$1.clientSecret_selection;
  var clientId_selection = match$1.clientId_selection;
  var password_selection = match$1.password_selection;
  var userName_selection = match$1.userName_selection;
  var consumerKey_selection = match$1.consumerKey_selection;
  var authType_selection = match$1.authType_selection;
  var jiraServerUrl_error = match$1.jiraServerUrl_error;
  var jiraServerUrl_selection = match$1.jiraServerUrl_selection;
  var connectionName_selection = match$1.connectionName_selection;
  var connectionId_selection = match$1.connectionId_selection;
  React.useEffect((function () {
          if (typeof route === "number") {
            route === /* Default */0;
          } else if (route.tag) {
            var connectionId = route[0];
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* SetConnectionIdAndInstanceURL */Block.__(11, [
                            connectionId,
                            param.body.instanceUrl,
                            true,
                            true
                          ]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(13, [err]));
            };
            var maybeTask = ApiWfConnections$BsConsole.QueryConnection.queryConnectionTask(config, projectName, "jira-server", connectionId);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          } else {
            var onSuccess$1 = function (param) {
              var body = param.body;
              var autoPopulateState_connectionId_selection = body.id;
              var autoPopulateState_instanceUrl_selection = body.instanceUrl;
              var autoPopulateState_connectionName_selection = body.name;
              var autoPopulateState_jiraServerUrl_selection = Belt_Option.getWithDefault(body.options.baseUrl, "");
              var autoPopulateState_authType_selection = Curry._1(ApiWfConnections$BsConsole.JiraServerConnection.AuthType.apiStringToType, body.options.authorizationOptions.type_);
              var autoPopulateState_consumerKey_selection = Belt_Option.getWithDefault(body.options.authorizationOptions.consumerKey, "BacktraceIO");
              var autoPopulateState_userName_selection = body.options.authorizationOptions.username;
              var autoPopulateState_clientId_selection = body.options.authorizationOptions.clientKey;
              var autoPopulateState_publicRsaKey_response = body.options.authorizationOptions.publicKey;
              var autoPopulateState = {
                connectionId_selection: autoPopulateState_connectionId_selection,
                instanceUrl_selection: autoPopulateState_instanceUrl_selection,
                connectionName_selection: autoPopulateState_connectionName_selection,
                jiraServerUrl_selection: autoPopulateState_jiraServerUrl_selection,
                authType_selection: autoPopulateState_authType_selection,
                consumerKey_selection: autoPopulateState_consumerKey_selection,
                userName_selection: autoPopulateState_userName_selection,
                clientId_selection: autoPopulateState_clientId_selection,
                publicRsaKey_response: autoPopulateState_publicRsaKey_response,
                isBacktraceSetUpComplete: false,
                isJiraSetUpComplete: false
              };
              return Curry._1(dispatch, /* AutoPopulateState */Block.__(15, [autoPopulateState]));
            };
            var onFailure$1 = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(13, [err]));
            };
            var maybeTask$1 = ApiWfConnections$BsConsole.QueryConnection.queryConnectionTask(config, projectName, "jira-server", route[0]);
            if (maybeTask$1 !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess$1, onFailure$1, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), [route]);
  var handleSave = function (param) {
    var isFormValid;
    if (connectionName_selection.trim() === "" || jiraServerUrl_selection.trim() === "" || jiraServerUrl_error) {
      isFormValid = false;
    } else {
      switch (authType_selection) {
        case /* Basic */0 :
            isFormValid = userName_selection !== undefined ? password_selection !== undefined : false;
            break;
        case /* OAuth1 */1 :
            var match = consumerKey_selection.trim() !== "";
            isFormValid = match ? (
                shouldProvideRsaKeys_selection ? true : (
                    privateRsaKey_selection !== undefined ? privateRsaKey_selection.trim() !== "" : false
                  )
              ) : false;
            break;
        case /* OAuth2 */2 :
            isFormValid = clientId_selection !== undefined ? clientSecret_selection !== undefined : false;
            break;
        
      }
    }
    if (!isFormValid) {
      return Curry._1(dispatch, /* ShowValidationErrors */Block.__(14, [true]));
    }
    if (typeof route !== "number" && !route.tag) {
      if (connectionId_selection === undefined) {
        return Curry._1(handleSetErrorMessage, "Connection Id is missing");
      }
      var onSuccess = function (param) {
        var body = param.body;
        switch (authType_selection) {
          case /* Basic */0 :
              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_Edited */Block.__(8, ["jiraServer"])]));
              return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                              error: undefined
                            }]));
          case /* OAuth1 */1 :
              Curry._1(handleSetErrorMessage, "");
              return Curry._1(dispatch, /* SetConnectionIdAndInstanceURLANDPublicKey */Block.__(12, [
                            body.id,
                            body.instanceUrl,
                            body.options.authorizationOptions.publicKey,
                            true,
                            false
                          ]));
          case /* OAuth2 */2 :
              Curry._1(handleSetErrorMessage, "");
              return Curry._1(dispatch, /* SetConnectionIdAndInstanceURL */Block.__(11, [
                            body.id,
                            body.instanceUrl,
                            true,
                            true
                          ]));
          
        }
      };
      var onFailure = Curry.__1(handleSetErrorMessage);
      var maybeTask = ApiWfConnections$BsConsole.JiraServerConnection.editConnectionTask(config, "jira-server", String(connectionId_selection), connectionName_selection, jiraServerUrl_selection, authType_selection, privateRsaKey_selection, userName_selection, password_selection, shouldProvideRsaKeys_selection, clientId_selection, clientSecret_selection, projectName, consumerKey_selection);
      if (maybeTask !== undefined) {
        return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
      } else {
        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
      }
    }
    var onSuccess$1 = function (param) {
      var body = param.body;
      switch (authType_selection) {
        case /* Basic */0 :
            var param_pluginId = Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, /* JiraServer */1);
            var param_connectionId = body.id;
            var param_connectionCreated = true;
            var param$1 = {
              pluginId: param_pluginId,
              connectionId: param_connectionId,
              connectionCreated: param_connectionCreated,
              integrationId: undefined,
              referrer: undefined,
              error: undefined
            };
            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_Created */Block.__(7, ["jiraServer"])]));
            return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                          projectName,
                          WfRouter$BsConsole.root,
                          param$1
                        ]));
        case /* OAuth1 */1 :
            Curry._1(handleSetErrorMessage, "");
            return Curry._1(dispatch, /* SetConnectionIdAndInstanceURLANDPublicKey */Block.__(12, [
                          body.id,
                          body.instanceUrl,
                          body.options.authorizationOptions.publicKey,
                          true,
                          false
                        ]));
        case /* OAuth2 */2 :
            Curry._1(handleSetErrorMessage, "");
            return Curry._1(dispatch, /* SetConnectionIdAndInstanceURL */Block.__(11, [
                          body.id,
                          body.instanceUrl,
                          true,
                          true
                        ]));
        
      }
    };
    var onFailure$1 = Curry.__1(handleSetErrorMessage);
    var maybeTask$1 = ApiWfConnections$BsConsole.JiraServerConnection.createConnectionTask(config, "jira-server", connectionName_selection, jiraServerUrl_selection, authType_selection, privateRsaKey_selection, userName_selection, password_selection, shouldProvideRsaKeys_selection, clientId_selection, clientSecret_selection, projectName, consumerKey_selection);
    if (maybeTask$1 !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess$1, onFailure$1, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginRight(Css.px(50)),
                    /* [] */0
                  ])
            }, errorMessage.length > 0 ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, errorMessage)
                  }) : null, I18N$BsConsole.show(undefined, "To configure a Jira Server integration please "), React.createElement("a", {
                  className: Css.style(/* :: */[
                        Css.cursor(/* pointer */-786317123),
                        /* [] */0
                      ]),
                  href: "https://docs.saucelabs.com/error-reporting/workflow-integrations/issue-tracking/jira/#jira-server",
                  rel: "noopener noreferrer",
                  target: "_blank"
                }, I18N$BsConsole.show(undefined, "view documentation.")), React.createElement(JiraServerConnection$SetUpConnection, {
                  isBacktraceSetUpComplete: isBacktraceSetUpComplete,
                  isJiraSetUpComplete: isJiraSetUpComplete,
                  connectionName_selection: connectionName_selection,
                  jiraServerUrl_selection: jiraServerUrl_selection,
                  jiraServerUrl_error: jiraServerUrl_error,
                  authType_selection: authType_selection,
                  authTypes_available: match$1.authTypes_available,
                  consumerKey_selection: consumerKey_selection,
                  userName_selection: userName_selection,
                  password_selection: password_selection,
                  show_password_selection: match$1.show_password_selection,
                  privateRsaKey_selection: privateRsaKey_selection,
                  publicRsaKey_response: match$1.publicRsaKey_response,
                  shouldProvideRsaKeys_selection: shouldProvideRsaKeys_selection,
                  clientId_selection: clientId_selection,
                  clientSecret_selection: clientSecret_selection,
                  show_clientSecret_selection: match$1.show_clientSecret_selection,
                  projectName: projectName,
                  showValidationErrors: match$1.showValidationErrors,
                  handleSetConnectionName: (function (connectionName) {
                      return Curry._1(dispatch, /* SetConnectionName_Selection */Block.__(0, [connectionName]));
                    }),
                  handleSetJiraServerUrlError: (function (isError) {
                      return Curry._1(dispatch, /* SetJiraServerUrl_error */Block.__(2, [isError]));
                    }),
                  handleSetAuthType: (function (authType) {
                      return Curry._1(dispatch, /* SetAuthType_Selection */Block.__(3, [authType]));
                    }),
                  handleSetJiraServerUrl: (function (url, isError) {
                      return Curry._1(dispatch, /* SetJiraServerUrl_Selection */Block.__(1, [
                                    url,
                                    isError
                                  ]));
                    }),
                  handleUserNameChange: (function (username) {
                      return Curry._1(dispatch, /* SetUserName_Selection */Block.__(5, [username]));
                    }),
                  handlePasswordChange: (function (password) {
                      return Curry._1(dispatch, /* SetPassword_Selection */Block.__(6, [password]));
                    }),
                  handleShowPasswordToogle: (function (param) {
                      return Curry._1(dispatch, /* ToogleShowPassword_Selection */0);
                    }),
                  handleShouldProvideRsaKeysChange: (function (shouldGenerateKeys) {
                      return Curry._1(dispatch, /* SetShouldProvideRsaKeys_Selection */Block.__(9, [shouldGenerateKeys]));
                    }),
                  handlePrivateKeyChange: (function (privateKey) {
                      return Curry._1(dispatch, /* SetPrivateRsaKey_Selection */Block.__(10, [privateKey]));
                    }),
                  handleClientIdChange: (function (clientId) {
                      return Curry._1(dispatch, /* SetClientId_Selection */Block.__(7, [clientId]));
                    }),
                  handleClientSecretChange: (function (clientSecret) {
                      return Curry._1(dispatch, /* SetClientSecret_Selection */Block.__(8, [clientSecret]));
                    }),
                  handleShowClientSecretToogle: (function (param) {
                      return Curry._1(dispatch, /* ToogleShowClientSecret_Selection */1);
                    }),
                  handleChangeUrl: handleChangeUrl,
                  handleSave: handleSave,
                  handleJiraSetUpCompleted: (function (param) {
                      return Curry._1(dispatch, /* JiraSetUpCompleted */2);
                    }),
                  handleSetConsumerKey: (function (consumerKey) {
                      return Curry._1(dispatch, /* SetConsumerKey_Selection */Block.__(4, [consumerKey]));
                    }),
                  route: route
                }), authType_selection !== 0 ? React.createElement(JiraServerConnection$AuthorizationConnection, {
                    isBacktraceSetUpComplete: isBacktraceSetUpComplete,
                    isJiraSetUpComplete: isJiraSetUpComplete,
                    connectionId_selection: connectionId_selection,
                    instanceUrl_selection: match$1.instanceUrl_selection,
                    config: config,
                    projectName: projectName,
                    authType_selection: authType_selection,
                    handleChangeUrl: handleChangeUrl,
                    route: route
                  }) : null);
}

var make = JiraServerConnection;

exports.Styles = Styles;
exports.Required = Required;
exports.Password = Password;
exports.Section = Section;
exports.SectionRow = SectionRow;
exports.SectionColumn = SectionColumn;
exports.OAuth1Options = OAuth1Options;
exports.OAuth2Options = OAuth2Options;
exports.BasicAuthOptions = BasicAuthOptions;
exports.SetUpConnection = SetUpConnection;
exports.AuthorizationConnection = AuthorizationConnection;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* indicator Not a pure module */
