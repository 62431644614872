// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BpgController$BsConsole = require("../BpgController.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var RetentionPolicy$BsConsole = require("../RetentionPolicy.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var BtClickableInput$BsConsole = require("../BtClickableInput.js");
var BillingLimitsFetch$BsConsole = require("../BillingLimitsFetch.js");
var RetentionPolicyTable$BsConsole = require("../RetentionPolicyTable.js");
var RetentionPolicyStatus$BsConsole = require("../RetentionPolicyStatus.js");

function isValidDays(days, highest) {
  if (days !== undefined) {
    if (highest !== undefined) {
      if (days > 0.0) {
        return days <= highest;
      } else {
        return false;
      }
    } else {
      return days > 0.0;
    }
  } else {
    return highest === undefined;
  }
}

function exceedsSoftLimit(state, limit) {
  if (state !== undefined && limit !== undefined) {
    return Caml_obj.caml_greaterequal(Caml_option.valFromOption(state), Caml_option.valFromOption(limit));
  } else {
    return false;
  }
}

var component = RR$BsConsole.statelessComponent("PS_RetentionPolicy-BsConsole__InstanceTable");

function make(token, rules, user, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var policy = Belt_List.get(rules, 0);
              var policy$prime = policy !== undefined ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined;
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__", undefined, RetentionPolicyStatus$BsConsole.Instance.make(token, (function (remote, _update) {
                                          return ReasonReact.element("instance" + (
                                                      policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                                    ), undefined, RetentionPolicyTable$BsConsole.make("instance", Caml_option.some(policy !== undefined && policy.rules.trim() !== "" && policy.rules.trim() !== "[]" ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined), undefined, undefined, undefined, remote, user, []));
                                        })))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var InstanceTable = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("PS_RetentionPolicy-BsConsole__FormRow");

function make$1(text, state, highest, actionType, suggestions, placeholderOpt, isEditing, exceedsSoftLimitMessage, setDays, $$default, original, _children) {
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              var tmp;
              if (isEditing) {
                var match = exceedsSoftLimitMessage !== undefined ? /* tuple */[
                    Caml_option.some(I18N$BsConsole.showSkip(exceedsSoftLimitMessage)),
                    true
                  ] : (
                    highest !== undefined && !isValidDays(state, highest) ? /* tuple */[
                        Caml_option.some(Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Must be greater than 0 and maximum of ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " days (based on account)",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "Must be greater than 0 and maximum of %s days (based on account)"
                                    ]), Numeral$BsConsole.format("0.00", Pervasives.string_of_float(highest)))),
                        true
                      ] : /* tuple */[
                        undefined,
                        false
                      ]
                  );
                var helperElement = match[0];
                tmp = React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(/* row */5693978),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* :: */[
                                  Css.justifyContent(/* center */98248149),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }, Belt_Option.isSome(helperElement) ? ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, Css.style(/* :: */[
                                    Css.width(Css.em(21)),
                                    /* :: */[
                                      Css.height(Css.em(2)),
                                      /* :: */[
                                        Css.textAlign(/* right */-379319332),
                                        /* :: */[
                                          Css.marginRight(Css.px(8)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]), undefined, match[1], [helperElement])) : null, ReasonReact.element(undefined, undefined, BtClickableInput$BsConsole.Jsx2.make(suggestions, placeholder, state, (function ($$event) {
                                var value = $$event.target.value;
                                var floatOption = Belt_Float.fromString(value);
                                if (floatOption !== undefined) {
                                  return Curry._2(setDays, actionType, floatOption);
                                } else {
                                  return Curry._2(setDays, actionType, undefined);
                                }
                              }), (function (suggestedValue) {
                                return Curry._2(setDays, actionType, Belt_Float.fromString(suggestedValue));
                              }), [])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                  Css.fontSize(Css.rem(1)),
                                  /* :: */[
                                    Css.marginLeft(Css.px(5)),
                                    /* :: */[
                                      Css.fontWeight(/* `num */[
                                            5496390,
                                            400
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]), /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, " days")])));
              } else {
                var message = original !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " days",
                                    /* End_of_format */0
                                  ])
                              ]),
                            "%s days"
                          ]), String(original)) : I18N$BsConsole.show(undefined, "Never");
                tmp = React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, message);
              }
              return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.skip(text)])),
                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, Css.style(/* :: */[
                                            Css.height(Css.rem(5)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.display(/* flex */-1010954439),
                                                    /* :: */[
                                                      Css.flexDirection(/* column */-963948842),
                                                      /* :: */[
                                                        Css.alignItems(/* flexEnd */924268066),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ])
                                            }, tmp)]))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var FormRow = {
  component: component$1,
  make: make$1
};

var component$2 = RR$BsConsole.statelessComponent("PS_RetentionPolicy-BsConsole__UniverseTable");

function make$2(universes, user, token, rules, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              var table = Belt_List.toArray(Belt_List.map(universes, (function (universe) {
                          var policy = Belt_List.getBy(rules, (function (rp) {
                                  return rp.universe === universe.id;
                                }));
                          var policy$prime = policy !== undefined ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined;
                          return ReasonReact.element(universe.name + (
                                      policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                    ), undefined, RetentionPolicyStatus$BsConsole.Universe.make(universe.name, token, (function (remote, _update) {
                                            return ReasonReact.element(universe.name + (
                                                        policy$prime !== undefined ? JSON.stringify(RetentionPolicy$BsConsole.toJson(policy$prime)) : "__"
                                                      ), undefined, RetentionPolicyTable$BsConsole.make(universe.name, Caml_option.some(policy !== undefined && policy.rules.trim() !== "" && policy.rules.trim() !== "[]" ? RetentionPolicy$BsConsole.fromString(policy.rules) : undefined), undefined, undefined, undefined, remote, user, []));
                                          })));
                        })));
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [table]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var UniverseTable = {
  component: component$2,
  make: make$2
};

var component$3 = RR$BsConsole.reducerComponent("PS_RetentionPolicy-BsConsole__ProjectsTable");

function make$3(project, universe, user, projectPolicies, handleTask, token, onSuccess, limit, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var policy = Belt_List.getBy(projectPolicies, (function (policy) {
                      return project.pid === policy.project;
                    }));
              var match = RetentionPolicy$BsConsole.getCriteriaDays(policy);
              var deleteAll = match[2];
              var deleteOnlyPhysical = match[1];
              var compressOnlyPhysical = match[0];
              var highestCompress = Belt_Option.flatMap(limit, ApiBillingLimits$BsConsole.getRetentionCompress);
              var highestDeleteOnlyPhysical = Belt_Option.flatMap(limit, ApiBillingLimits$BsConsole.getRetentionDeleteOnlyPhysical);
              var highestDeleteAll = Belt_Option.flatMap(limit, ApiBillingLimits$BsConsole.getRetentionDeleteAll);
              var intSuggestions = [
                7,
                14,
                30,
                60,
                90,
                180,
                365
              ];
              var strSuggestions = Belt_Array.map(intSuggestions, (function (num) {
                      return String(num);
                    }));
              var getSuggestions = function (highest) {
                if (highest === undefined) {
                  return strSuggestions;
                }
                var highestInt = highest | 0;
                return Belt_Array.map(Belt_Array.keep(intSuggestions, (function (num) {
                                  return num <= highestInt;
                                })), (function (num) {
                              return String(num);
                            }));
              };
              var highestCompressSuggestions = getSuggestions(highestCompress);
              var highestDeleteOnlyPhysicalSuggestions = getSuggestions(highestDeleteOnlyPhysical);
              var highestDeleteAllSuggestions = getSuggestions(highestDeleteAll);
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, make$1(I18N$BsConsole.get(undefined, "Compress physical objects (removes reprocessing availability)"), self.state.compressOnlyPhysical, highestCompress, /* CompressOnlyPhysical */2, highestCompressSuggestions, undefined, self.state.isEditing, exceedsSoftLimit(self.state.compressOnlyPhysical, self.state.deleteOnlyPhysical) ? I18N$BsConsole.get(undefined, "Must not exceed the Delete All Physical Objects value") : undefined, (function (t, d) {
                                                    return Curry._1(self.send, /* SetDays */Block.__(0, [
                                                                  t,
                                                                  d
                                                                ]));
                                                  }), 7.0, compressOnlyPhysical, [])),
                                        ReasonReact.element(undefined, undefined, make$1(I18N$BsConsole.get(undefined, "Delete all physical objects (removes debugger view availability)"), self.state.deleteOnlyPhysical, highestDeleteOnlyPhysical, /* DeleteOnlyPhysical */1, highestDeleteOnlyPhysicalSuggestions, undefined, self.state.isEditing, exceedsSoftLimit(self.state.deleteOnlyPhysical, self.state.deleteAll) ? I18N$BsConsole.get(undefined, "Must not exceed the Delete Physical Objects and Metadata value") : undefined, (function (t, d) {
                                                    return Curry._1(self.send, /* SetDays */Block.__(0, [
                                                                  t,
                                                                  d
                                                                ]));
                                                  }), 30.0, deleteOnlyPhysical, [])),
                                        ReasonReact.element(undefined, undefined, make$1(I18N$BsConsole.get(undefined, "Delete physical objects AND metadata (removes all records of error data)"), self.state.deleteAll, highestDeleteAll, /* DeleteAll */0, highestDeleteAllSuggestions, undefined, self.state.isEditing, undefined, (function (t, d) {
                                                    return Curry._1(self.send, /* SetDays */Block.__(0, [
                                                                  t,
                                                                  d
                                                                ]));
                                                  }), 90.0, deleteAll, [])),
                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [null])),
                                                  self.state.isEditing ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, Css.style(/* :: */[
                                                                  Css.display(/* flex */-1010954439),
                                                                  /* :: */[
                                                                    Css.flexDirection(/* row */5693978),
                                                                    /* :: */[
                                                                      Css.justifyContent(/* flexEnd */924268066),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]), undefined, undefined, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                        undefined,
                                                                        Css.style(/* :: */[
                                                                              Css.margin4(Css.rem(1), Css.px(0), Css.rem(1), Css.rem(1)),
                                                                              /* [] */0
                                                                            ]),
                                                                        (function (param) {
                                                                            return Curry._1(self.send, /* Cancel */Block.__(2, [
                                                                                          compressOnlyPhysical,
                                                                                          deleteOnlyPhysical,
                                                                                          deleteAll
                                                                                        ]));
                                                                          }),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        /* Primary */-791844958,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [I18N$BsConsole.show(undefined, "Cancel")]
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                        /* Small */311976103,
                                                                        Css.style(/* :: */[
                                                                              Css.margin4(Css.rem(1), Css.px(0), Css.rem(1), Css.rem(1)),
                                                                              /* [] */0
                                                                            ]),
                                                                        (function (param) {
                                                                            var rules = RetentionPolicy$BsConsole.toJson(RetentionPolicy$BsConsole.assembleRules(self.state.compressOnlyPhysical, self.state.deleteOnlyPhysical, self.state.deleteAll, undefined));
                                                                            RetentionPolicy$BsConsole.updateProjectPolicy(policy, rules, token, (function (param) {
                                                                                    Curry._1(onSuccess, undefined);
                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated project retention policy"));
                                                                                    return Curry._1(self.send, /* ToggleEditing */Block.__(1, [false]));
                                                                                  }), (function (param) {
                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                                                                    return Curry._1(self.send, /* ToggleEditing */Block.__(1, [false]));
                                                                                  }), project.pid, undefined);
                                                                            
                                                                          }),
                                                                        undefined,
                                                                        undefined,
                                                                        /* Raised */-387916264,
                                                                        /* Primary */-791844958,
                                                                        undefined,
                                                                        undefined,
                                                                        !isValidDays(self.state.compressOnlyPhysical, highestCompress) || !isValidDays(self.state.deleteOnlyPhysical, highestDeleteOnlyPhysical) || !isValidDays(self.state.deleteAll, highestDeleteAll) || exceedsSoftLimit(self.state.compressOnlyPhysical, self.state.deleteOnlyPhysical) || exceedsSoftLimit(self.state.deleteOnlyPhysical, self.state.deleteAll),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [I18N$BsConsole.show(undefined, "Save")]
                                                                      ]))
                                                            ])) : ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, Css.style(/* :: */[
                                                                  Css.display(/* flex */-1010954439),
                                                                  /* :: */[
                                                                    Css.justifyContent(/* flexEnd */924268066),
                                                                    /* [] */0
                                                                  ]
                                                                ]), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                        /* Small */311976103,
                                                                        Css.style(/* :: */[
                                                                              Css.margin4(Css.rem(1), Css.px(0), Css.rem(1), Css.rem(1)),
                                                                              /* [] */0
                                                                            ]),
                                                                        (function (param) {
                                                                            return Curry._1(self.send, /* ToggleEditing */Block.__(1, [true]));
                                                                          }),
                                                                        undefined,
                                                                        undefined,
                                                                        /* Raised */-387916264,
                                                                        /* Primary */-791844958,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [I18N$BsConsole.show(undefined, "Edit")]
                                                                      ]))]))
                                                ]))
                                      ]))]));
            }),
          initialState: (function (param) {
              var policy = Belt_List.getBy(projectPolicies, (function (policy) {
                      return project.pid === policy.project;
                    }));
              var match = RetentionPolicy$BsConsole.getCriteriaDays(policy);
              return {
                      dialog: undefined,
                      deleteAll: match[2],
                      deleteOnlyPhysical: match[1],
                      compressOnlyPhysical: match[0],
                      isEditing: false
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetDays */0 :
                    var days = action[1];
                    switch (action[0]) {
                      case /* DeleteAll */0 :
                          return /* Update */Block.__(0, [{
                                      dialog: state.dialog,
                                      deleteAll: days,
                                      deleteOnlyPhysical: state.deleteOnlyPhysical,
                                      compressOnlyPhysical: state.compressOnlyPhysical,
                                      isEditing: state.isEditing
                                    }]);
                      case /* DeleteOnlyPhysical */1 :
                          return /* Update */Block.__(0, [{
                                      dialog: state.dialog,
                                      deleteAll: state.deleteAll,
                                      deleteOnlyPhysical: days,
                                      compressOnlyPhysical: state.compressOnlyPhysical,
                                      isEditing: state.isEditing
                                    }]);
                      case /* CompressOnlyPhysical */2 :
                          return /* Update */Block.__(0, [{
                                      dialog: state.dialog,
                                      deleteAll: state.deleteAll,
                                      deleteOnlyPhysical: state.deleteOnlyPhysical,
                                      compressOnlyPhysical: days,
                                      isEditing: state.isEditing
                                    }]);
                      
                    }
                case /* ToggleEditing */1 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                deleteAll: state.deleteAll,
                                deleteOnlyPhysical: state.deleteOnlyPhysical,
                                compressOnlyPhysical: state.compressOnlyPhysical,
                                isEditing: action[0]
                              }]);
                case /* Cancel */2 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                deleteAll: action[2],
                                deleteOnlyPhysical: action[1],
                                compressOnlyPhysical: action[0],
                                isEditing: false
                              }]);
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var ProjectsTable = {
  component: component$3,
  make: make$3
};

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var component$4 = RR$BsConsole.statelessComponent("PS_RetentionPolicy-BsConsole");

function make$4(handleTask, token, shell, name, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              if (typeof shell === "number") {
                return null;
              }
              if (shell.tag) {
                return null;
              }
              var config = shell[0];
              return ReasonReact.element(undefined, undefined, BillingLimitsFetch$BsConsole.make(config.universe.name, handleTask, token, (function (limitsRemote, param) {
                                return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                                              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                                              /* :: */[
                                                                Css.borderRadius(Css.px(4)),
                                                                /* [] */0
                                                              ]
                                                            ]), undefined, undefined, 0, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Retention policies")])),
                                                                    React.createElement("br", undefined),
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Administrators can configure retention policies that will allow data to be removed after a defined period of time. Instance level retention policies may be active. Please contact support for more information.")]))
                                                                  ]))])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                            var instanceConfig = state.remote;
                                                            if (typeof instanceConfig === "number") {
                                                              if (instanceConfig === /* NotAsked */0) {
                                                                return null;
                                                              } else {
                                                                return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                              }
                                                            } else if (instanceConfig.tag || config.user.superuser !== 1) {
                                                              return null;
                                                            } else {
                                                              return React.createElement(React.Fragment, undefined, React.createElement("br", undefined), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Instance policy")])), ReasonReact.element(undefined, undefined, make(token, instanceConfig[0], config.user, [])));
                                                            }
                                                          }))),
                                                React.createElement("br", undefined),
                                                React.createElement("br", undefined),
                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Universe policy")])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Universe.Get.make, handleTask, token, undefined, (function (universeState, _updater) {
                                                            return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.UniverseRetention.Get.make, handleTask, token, undefined, (function (universeRetentionState, _updater) {
                                                                              var match = universeState.remote;
                                                                              var match$1 = universeRetentionState.remote;
                                                                              var exit = 0;
                                                                              if (typeof match === "number") {
                                                                                if (match === /* NotAsked */0) {
                                                                                  exit = 2;
                                                                                }
                                                                                
                                                                              } else if (match.tag) {
                                                                                exit = 2;
                                                                              } else {
                                                                                if (typeof match$1 !== "number") {
                                                                                  if (match$1.tag) {
                                                                                    return null;
                                                                                  } else {
                                                                                    return ReasonReact.element(undefined, undefined, make$2(match[0], config.user, token, match$1[0], []));
                                                                                  }
                                                                                }
                                                                                if (match$1 === /* NotAsked */0) {
                                                                                  return null;
                                                                                }
                                                                                exit = 2;
                                                                              }
                                                                              if (exit === 2) {
                                                                                if (typeof match$1 !== "number") {
                                                                                  return null;
                                                                                }
                                                                                if (match$1 === 0) {
                                                                                  return null;
                                                                                }
                                                                                
                                                                              }
                                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                                            })));
                                                          }))),
                                                React.createElement("br", undefined),
                                                React.createElement("br", undefined),
                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Project policy")])),
                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ProjectRetention.Get.make, handleTask, token, undefined, (function (projectRetentionState, updater) {
                                                            var match = projectRetentionState.remote;
                                                            var match$1 = Belt_List.getBy(config.projects, (function (project) {
                                                                    return project.name === name;
                                                                  }));
                                                            var exit = 0;
                                                            if (typeof match === "number") {
                                                              if (match === /* NotAsked */0) {
                                                                exit = 2;
                                                              }
                                                              
                                                            } else if (match.tag) {
                                                              exit = 2;
                                                            } else {
                                                              if (typeof limitsRemote !== "number") {
                                                                if (limitsRemote.tag || match$1 === undefined) {
                                                                  return null;
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, make$3(match$1, config.universe, config.user, match[0], handleTask, token, (function (param) {
                                                                                    return Curry._1(updater, undefined);
                                                                                  }), limitsRemote[0], []));
                                                                }
                                                              }
                                                              if (limitsRemote === /* NotAsked */0) {
                                                                return null;
                                                              }
                                                              exit = 2;
                                                            }
                                                            if (exit === 2) {
                                                              if (typeof limitsRemote !== "number") {
                                                                return null;
                                                              }
                                                              if (limitsRemote === 0) {
                                                                return null;
                                                              }
                                                              
                                                            }
                                                            return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "secondary", undefined, undefined, []))]));
                                                          })))
                                              ]));
                              })));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

exports.isValidDays = isValidDays;
exports.exceedsSoftLimit = exceedsSoftLimit;
exports.InstanceTable = InstanceTable;
exports.FormRow = FormRow;
exports.UniverseTable = UniverseTable;
exports.ProjectsTable = ProjectsTable;
exports.link = link;
exports.component = component$4;
exports.make = make$4;
/* component Not a pure module */
