// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");

var component = ReasonReact.statelessComponent("DateRangeContent-BsConsole");

var daysAgoFont = Css.style(/* :: */[
      Css.fontWeight(Css.medium),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey1)),
        /* :: */[
          Css.marginBottom(Css.px(3)),
          /* [] */0
        ]
      ]
    ]);

var subtitle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.rem(0.8125)),
        /* :: */[
          Css.fontWeight(Css.normal),
          /* :: */[
            Css.lineHeight(Css.em(1.375)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey2)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var dateFont = Css.style(/* :: */[
      Css.fontWeight(Css.light),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey3)),
        /* [] */0
      ]
    ]);

function make(fromDate, toDate, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.lineHeight(Css.em(1.375)),
                                  /* :: */[
                                    Css.fontFamily("Roboto"),
                                    /* :: */[
                                      Css.fontSize(Css.rem(0.75)),
                                      /* [] */0
                                    ]
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                            Css.marginRight(Css.px(35)),
                                            /* [] */0
                                          ]), [
                                        React.createElement("div", {
                                              className: Css.merge(/* :: */[
                                                    Css.style(/* :: */[
                                                          Css.display(/* flex */-1010954439),
                                                          /* :: */[
                                                            Css.alignItems(/* center */98248149),
                                                            /* [] */0
                                                          ]
                                                        ]),
                                                    /* :: */[
                                                      subtitle,
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "First seen")), React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.px(3)),
                                                        /* :: */[
                                                          Css.height(Css.px(12)),
                                                          /* [] */0
                                                        ]
                                                      ]) + " extra-smaller-icon"
                                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AccessTime.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))),
                                        React.createElement("div", {
                                              className: daysAgoFont
                                            }, I18N$BsConsole.showSkip(FormatUtils$BsConsole.formatDateToAgoString(fromDate))),
                                        React.createElement("div", {
                                              className: dateFont
                                            }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, fromDate)))
                                      ])),
                              ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                        React.createElement("div", {
                                              className: subtitle
                                            }, I18N$BsConsole.show(undefined, "Last seen")),
                                        React.createElement("div", {
                                              className: daysAgoFont
                                            }, I18N$BsConsole.showSkip(FormatUtils$BsConsole.formatDateToAgoString(toDate))),
                                        React.createElement("div", {
                                              className: dateFont
                                            }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, toDate)))
                                      ]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.daysAgoFont = daysAgoFont;
exports.subtitle = subtitle;
exports.dateFont = dateFont;
exports.make = make;
/* component Not a pure module */
