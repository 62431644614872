// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");

var callToActionContainer = Css.style(/* :: */[
      Css.marginBottom(Css.em(1)),
      /* :: */[
        Css.padding(Css.em(1)),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.error)),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.error)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var callToActionButtonSearch = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.error)),
      /* :: */[
        Css.marginRight(Css.em(1)),
        /* [] */0
      ]
    ]);

var callToActionButtonUpload = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.error)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.white)),
        /* [] */0
      ]
    ]);

var Styles = {
  callToActionContainer: callToActionContainer,
  callToActionButtonSearch: callToActionButtonSearch,
  callToActionButtonUpload: callToActionButtonUpload
};

var component = RR$BsConsole.statelessComponent("MissingSymbolsCallToAction-BsConsole__Banner");

function make(handleTask, token, handleChangeUrl, name, missingSymbolsOpt, _children) {
  var missingSymbols = missingSymbolsOpt !== undefined ? Caml_option.valFromOption(missingSymbolsOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              if (missingSymbols !== undefined) {
                if (missingSymbols.count > 0) {
                  return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, callToActionContainer, undefined, undefined, undefined, [
                                  React.createElement("div", {
                                        style: {
                                          display: "flex"
                                        }
                                      }, React.createElement("span", {
                                            style: {
                                              marginRight: "1em"
                                            }
                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Warning: the current project contains missing symbols.")]))),
                                  React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                callToActionButtonSearch,
                                                (function (_event) {
                                                    return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [name]));
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Flat */781662169,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Search missing symbols")]
                                              ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                callToActionButtonUpload,
                                                (function (_event) {
                                                    return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUpload */Block.__(37, [name]));
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Upload symbols")]
                                              ])))
                                ]));
                } else {
                  return null;
                }
              } else {
                return ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Summary.make(handleTask, token, name, (function (remote, _update) {
                                  if (typeof remote === "number" || remote.tag || remote[0].missingSymbols.count <= 0) {
                                    return null;
                                  } else {
                                    return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, callToActionContainer, undefined, undefined, undefined, [
                                                    React.createElement("div", {
                                                          style: {
                                                            display: "flex"
                                                          }
                                                        }, React.createElement("span", {
                                                              style: {
                                                                marginRight: "1em"
                                                              }
                                                            }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Warning: the current project contains missing symbols.")]))),
                                                    React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  callToActionButtonSearch,
                                                                  (function (_event) {
                                                                      return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [name]));
                                                                    }),
                                                                  undefined,
                                                                  undefined,
                                                                  /* Flat */781662169,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [I18N$BsConsole.show(undefined, "Search missing symbols")]
                                                                ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  callToActionButtonUpload,
                                                                  (function (_event) {
                                                                      return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUpload */Block.__(37, [name]));
                                                                    }),
                                                                  undefined,
                                                                  undefined,
                                                                  /* Raised */-387916264,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [I18N$BsConsole.show(undefined, "Upload symbols")]
                                                                ])))
                                                  ]));
                                  }
                                })));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Banner = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("MissingSymbolsCallToAction-BsConsole__Banner");

function make$1(handleChangeUrl, name, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, callToActionContainer, undefined, undefined, undefined, [
                              React.createElement("div", {
                                    style: {
                                      display: "flex"
                                    }
                                  }, React.createElement("span", {
                                        style: {
                                          marginRight: "1em"
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Warning: this instance is missing symbols.")]))),
                              React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                            undefined,
                                            callToActionButtonSearch,
                                            (function (_event) {
                                                return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [name]));
                                              }),
                                            undefined,
                                            undefined,
                                            /* Flat */781662169,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [I18N$BsConsole.show(undefined, "Search missing symbols")]
                                          ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                            undefined,
                                            callToActionButtonUpload,
                                            (function (_event) {
                                                return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUpload */Block.__(37, [name]));
                                              }),
                                            undefined,
                                            undefined,
                                            /* Raised */-387916264,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [I18N$BsConsole.show(undefined, "Upload symbols")]
                                          ])))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var DebuggerBanner = {
  component: component$1,
  make: make$1
};

exports.Styles = Styles;
exports.Banner = Banner;
exports.DebuggerBanner = DebuggerBanner;
/* callToActionContainer Not a pure module */
