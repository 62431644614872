// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var BtList$BsConsole = require("./components/BtList.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Clipboard$BsConsole = require("./bindings/Clipboard.js");
var BtListItem$BsConsole = require("./components/BtListItem.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

function wrapWithButton(onClick, style, labelClassName, classNameOpt, component) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                  /* Small */311976103,
                  className,
                  onClick,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(Belt_Option.getWithDefault(style, {
                            minWidth: "initial",
                            textTransform: "none"
                          })),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true,
                  undefined,
                  {
                    label: Belt_Option.getWithDefault(labelClassName, "")
                  },
                  [component]
                ]));
}

var component = ReasonReact.statelessComponent("ValueDrilldown-BsConsole");

function make(handleAddFilters, handleChangeUrl, filterOptions, linkOptions, value, onClose, param, target, classNameOpt, onDrillDownOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onDrillDown = onDrillDownOpt !== undefined ? onDrillDownOpt : (function (param) {
        
      });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make(target, className, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Left */847852583
                            }, undefined, undefined, onClose, undefined, (function (onClose) {
                                return [ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [
                                                  ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                Css.hover(/* :: */[
                                                                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                                                      /* [] */0
                                                                    ]),
                                                                /* [] */0
                                                              ]), (function ($$event) {
                                                              $$event.stopPropagation();
                                                              Clipboard$BsConsole.copy(value, undefined, undefined);
                                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* String_literal */Block.__(11, [
                                                                                    " copied to clipboard",
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            "%s copied to clipboard"
                                                                          ]), value));
                                                              return Curry._1(onClose, undefined);
                                                            }), [I18N$BsConsole.show(undefined, "Copy")])),
                                                  Belt_List.toArray(Belt_List.map(Belt_Option.getWithDefault(linkOptions, /* [] */0), (function (param) {
                                                              var onClick = param[1];
                                                              return ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                                  Css.hover(/* :: */[
                                                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  /* [] */0
                                                                                ]), (function ($$event) {
                                                                                $$event.stopPropagation();
                                                                                Curry._1(onClose, undefined);
                                                                                Curry._1(onDrillDown, undefined);
                                                                                return Curry._1(onClick, undefined);
                                                                              }), [param[0]]));
                                                            }))),
                                                  Belt_List.toArray(List.map((function (param) {
                                                              var operation = param[1];
                                                              var tmp;
                                                              switch (operation.tag | 0) {
                                                                case /* NotEqual */1 :
                                                                case /* NotContains */3 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Filter against");
                                                                    break;
                                                                case /* Equal */0 :
                                                                case /* Contains */2 :
                                                                case /* RegularExpression */4 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Filter to");
                                                                    break;
                                                                case /* GreaterThan */6 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Greater than");
                                                                    break;
                                                                case /* LessThan */7 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Less than");
                                                                    break;
                                                                case /* AtLeast */8 :
                                                                    tmp = I18N$BsConsole.show(undefined, "At least");
                                                                    break;
                                                                case /* AtMost */9 :
                                                                    tmp = I18N$BsConsole.show(undefined, "At most");
                                                                    break;
                                                                case /* AssignedTo */10 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Assigned to");
                                                                    break;
                                                                case /* NotAssignedTo */11 :
                                                                    tmp = I18N$BsConsole.show(undefined, "Not assigned to");
                                                                    break;
                                                                default:
                                                                  tmp = I18N$BsConsole.show(undefined, "Invariant");
                                                              }
                                                              return ReasonReact.element(undefined, undefined, Link$BsConsole.make(Curry._1(handleAddFilters, /* :: */[
                                                                                  /* tuple */[
                                                                                    param[0],
                                                                                    operation
                                                                                  ],
                                                                                  /* [] */0
                                                                                ]), (function ($$event) {
                                                                                $$event.stopPropagation();
                                                                                Curry._1(onClose, undefined);
                                                                                return Curry._1(onDrillDown, undefined);
                                                                              }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                                            Css.hover(/* :: */[
                                                                                                  Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                                                                                  /* [] */0
                                                                                                ]),
                                                                                            /* [] */0
                                                                                          ]), undefined, [tmp]))]));
                                                            }), filterOptions))
                                                ]))];
                              }), undefined, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.handleAddFilters, reactProps.handleChangeUrl, reactProps.filterOptions, Caml_option.undefined_to_opt(reactProps.linkOptions), reactProps.value, Caml_option.undefined_to_opt(reactProps.onClose), Caml_option.undefined_to_opt(reactProps.displayValue), reactProps.target, Caml_option.undefined_to_opt(reactProps.className), Caml_option.undefined_to_opt(reactProps.onDrillDown), []);
      }));

var Jsx3 = {
  make: make$1
};

exports.wrapWithButton = wrapWithButton;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
