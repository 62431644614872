// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SettingsTheme$BsConsole = require("../project-settings/SettingsTheme.js");
var Button = require("@material-ui/core/Button");
var Styles = require("@material-ui/core/styles");
var BannerPurveyor$BsConsole = require("../utilities/BannerPurveyor.js");

function PlayBanners(Props) {
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.margin(Css.px(25)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "BannerPurveyor")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Component for queuing up top-of-page banners that persist until user dismisses")
                }), React.createElement(Styles.MuiThemeProvider, {
                  theme: SettingsTheme$BsConsole.theme,
                  children: null
                }, React.createElement(BannerPurveyor$BsConsole.make, { }), React.createElement(Row2$BsConsole.make, {
                      children: null
                    }, React.createElement(Button.default, {
                          onClick: (function (param) {
                              var number = Date.now();
                              var cta_action = function (param) {
                                console.log("do something!");
                                
                              };
                              var cta = {
                                title: "Learn more",
                                action: cta_action
                              };
                              return BannerPurveyor$BsConsole.enqueue({
                                          bannerItem: {
                                            message: "This is the text that will be shown in the banner " + (String(number) + ""),
                                            cta: (number | 0) % 2 === 0 ? cta : undefined
                                          },
                                          cache: undefined,
                                          el: undefined
                                        });
                            }),
                          children: I18N$BsConsole.show(undefined, "Enqueue a banner")
                        }), React.createElement(Button.default, {
                          onClick: (function (param) {
                              return BannerPurveyor$BsConsole.enqueue({
                                          bannerItem: {
                                            message: I18N$BsConsole.get(undefined, "You won't see me again until at least a minute goes by. Even if you refresh!"),
                                            cta: undefined
                                          },
                                          cache: {
                                            id: "playground_expires",
                                            minutes: 1
                                          },
                                          el: undefined
                                        });
                            }),
                          children: I18N$BsConsole.show(undefined, "Enqueue banner with 1 minute expire time")
                        }))));
}

var make = PlayBanners;

exports.make = make;
/* Css Not a pure module */
