// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var ApiList$BsConsole = require("../apiList.js");
var ReactSyntaxHighlighter = require("react-syntax-highlighter");
var Hljs = require("react-syntax-highlighter/styles/hljs");

var customStyle = {
  fontSize: "12px",
  height: "100%",
  margin: "0",
  overflow: "auto"
};

var Attachments = { };

function getSourceCodeAndStartLine(text, faultingLineNum, originalStartNum) {
  var lines = text.split("\n");
  var lineCount = lines.length;
  if (lineCount <= 500) {
    return /* tuple */[
            I18N$BsConsole.showSkip(text),
            originalStartNum
          ];
  }
  var startIndex = Belt_Option.getWithDefault(faultingLineNum, 1) - 1 | 0;
  var arrStart = (startIndex - 250 | 0) >= 0 ? startIndex - 250 | 0 : 0;
  var arrEnd = (startIndex + 250 | 0) >= (lineCount - 1 | 0) ? lineCount - 1 | 0 : startIndex + 250 | 0;
  var str = "";
  for(var i = arrStart; i <= arrEnd; ++i){
    str = i === arrStart ? str + Belt_Option.getWithDefault(Belt_Array.get(lines, i), "unknown") : str + ("\n" + Belt_Option.getWithDefault(Belt_Array.get(lines, i), "unknown"));
  }
  var startLineNum = arrStart + 1 | 0;
  var sourceCode = I18N$BsConsole.showSkip(str);
  return /* tuple */[
          sourceCode,
          startLineNum
        ];
}

var make = React.memo((function (Props) {
        var attachments = Props.attachments;
        var source = Props.source;
        var faultingLine = Props.faultingLine;
        var projectName = Props.projectName;
        var errorId = Props.errorId;
        var scrolled = React.useRef(false);
        var preRef = React.useRef(undefined);
        React.useEffect((function () {
                scrolled.current = false;
                
              }), /* tuple */[
              source,
              faultingLine
            ]);
        switch (source.tag | 0) {
          case /* SourceCode */0 :
              var source$1 = source[0];
              var text = source$1.text;
              var originalStartNum = source$1.startLine;
              var match = getSourceCodeAndStartLine(text, faultingLine, originalStartNum);
              var startLineNum = match[1];
              return React.createElement(ReactSyntaxHighlighter.default, {
                          style: Hljs.zenburn,
                          customStyle: customStyle,
                          showLineNumbers: true,
                          startingLineNumber: startLineNum,
                          lineProps: (function (i) {
                              if (Caml_obj.caml_equal(String((i - 1 | 0) + startLineNum | 0), Belt_Option.map(faultingLine, (function (prim) {
                                            return String(prim);
                                          })))) {
                                return {
                                        style: {
                                          background: Colors$BsConsole.octothorpe(Colors$BsConsole.greyPink),
                                          display: "block",
                                          width: "100%"
                                        }
                                      };
                              } else {
                                return { };
                              }
                            }),
                          wrapLines: true,
                          PreTag: (function (props) {
                              return React.createElement("pre", {
                                          ref: (function (r) {
                                              var r$1 = (r == null) ? undefined : Caml_option.some(r);
                                              preRef.current = r$1;
                                              if (!scrolled.current && !(r == null) && faultingLine !== undefined) {
                                                scrolled.current = true;
                                                r.scrollTop = Caml_int32.imul(faultingLine - startLineNum | 0, 14) - 100 | 0;
                                                return ;
                                              }
                                              
                                            }),
                                          className: "walkthrough--debugger--source-code",
                                          style: props.style
                                        }, props.children);
                            }),
                          children: match[0]
                        });
          case /* LogFile */1 :
              return React.createElement(ReactSyntaxHighlighter.default, {
                          style: Hljs.zenburn,
                          customStyle: customStyle,
                          children: I18N$BsConsole.showSkip(source[0].text)
                        });
          case /* HtmlAttachment */2 :
              var attachment = source[0];
              if (typeof attachments === "number") {
                return null;
              }
              if (attachments.tag) {
                return null;
              }
              var maybeFile = attachments[0][0].find((function (file) {
                      return file.name === attachment;
                    }));
              if (maybeFile === undefined) {
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.height(Css.pct(100)),
                                    /* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.justifyContent(/* center */98248149),
                                        /* :: */[
                                          Css.alignItems(/* center */98248149),
                                          /* :: */[
                                            Css.border(Css.zero, Css.solid, Css.hex(Colors$BsConsole.black)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "Error loading attachment."));
              }
              var path = ApiList$BsConsole.getPath(errorId, undefined, projectName, maybeFile);
              return React.createElement("iframe", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.height(Css.pct(100)),
                                  /* :: */[
                                    Css.border(Css.zero, Css.solid, Css.hex(Colors$BsConsole.black)),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          sandbox: "allow-scripts",
                          src: path
                        });
          
        }
      }));

exports.customStyle = customStyle;
exports.Attachments = Attachments;
exports.getSourceCodeAndStartLine = getSourceCodeAndStartLine;
exports.make = make;
/* make Not a pure module */
