// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Format$BsConsole = require("./bs-crdb-response/src/Format.js");
var Numeral$BsConsole = require("./Numeral.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BtOutlineSelect$BsConsole = require("./BtOutlineSelect.js");
var MenuItem = require("@material-ui/core/MenuItem");
var CircularProgress = require("@material-ui/core/CircularProgress");

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.selector(".value", /* :: */[
            Css.marginRight(Css.rem(1)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".count", /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey4)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemClasses = {
  root: Css.style(/* :: */[
        Css.hover(/* :: */[
              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
              /* [] */0
            ]),
        /* :: */[
          Css.paddingLeft(Css.px(32)),
          /* :: */[
            Css.paddingRight(Css.px(32)),
            /* :: */[
              Css.position(/* relative */903134412),
              /* [] */0
            ]
          ]
        ]
      ]),
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
        /* :: */[
          Css.before(/* :: */[
                Css.fontFamily("'Material Icons'"),
                /* :: */[
                  Css.unsafe("content", "'check'"),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.position(/* absolute */-1013592457),
                      /* :: */[
                        Css.left(Css.px(8)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ])
};

var contentRow = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var Style = {
  label: label,
  select: select,
  iconContainer: iconContainer,
  menuItem: menuItem,
  menuItemClasses: menuItemClasses,
  contentRow: contentRow,
  loading: loading
};

function BtFilterSelect$ValueWithIcon(Props) {
  var icon = Props.icon;
  var children = Props.children;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: iconContainer,
                  children: icon
                }), children);
}

var ValueWithIcon = {
  make: BtFilterSelect$ValueWithIcon
};

function removeAttributeFilters(aperture, attribute) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== attribute;
        }), __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function addFilter(aperture, filter) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, filter, __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function getValueFromAperture(aperture, attribute) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Curry._2(Crdb$BsConsole.Filters.getBy, (function (filter) {
                        var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                        var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                        switch (filterOperation.tag | 0) {
                          case /* Equal */0 :
                          case /* Contains */2 :
                              break;
                          default:
                            return false;
                        }
                        var match = filterOperation[0];
                        if (typeof match === "number" || match[0] !== -976970511) {
                          return false;
                        } else {
                          return attribute === filterAttribute;
                        }
                      }), __x), (function (filter) {
                    var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                    var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                    switch (filterOperation.tag | 0) {
                      case /* Equal */0 :
                      case /* Contains */2 :
                          break;
                      default:
                        return ;
                    }
                    var match = filterOperation[0];
                    if (typeof match === "number" || match[0] !== -976970511 || attribute !== filterAttribute) {
                      return ;
                    } else {
                      return match[1];
                    }
                  })), "All");
}

function getDistributionDataFromRow(row, attribute) {
  return Belt_List.head(Belt_List.keepMap(row.aggregations, (function (shape) {
                    if (shape.tag !== /* Distribution */9) {
                      return ;
                    }
                    var distData = shape[1];
                    if (distData !== undefined && shape[0] === attribute) {
                      return distData;
                    }
                    
                  })));
}

function parseDistributionData(distData) {
  return Belt_List.toArray(Belt_List.keepMap(distData.vals, (function (param) {
                    var count = param[1];
                    var match = Belt_Option.map(param[0], (function (format$prime) {
                            return /* tuple */[
                                    Format$BsConsole.toString(format$prime),
                                    count
                                  ];
                          }));
                    if (match === undefined) {
                      return ;
                    }
                    var name = match[0];
                    if (name !== "*") {
                      return /* tuple */[
                              name,
                              match[1]
                            ];
                    }
                    
                  })));
}

function defaultRenderOption(option) {
  return I18N$BsConsole.show(undefined, option);
}

function BtFilterSelect(Props) {
  var attribute = Props.attribute;
  var label = Props.label;
  var name = Props.name;
  var options = Props.options;
  var renderOptionOpt = Props.renderOption;
  var renderValueOpt = Props.renderValue;
  var aperture = Props.aperture;
  var handleSetAperture = Props.handleSetAperture;
  var loading$1 = Props.loading;
  var createFilter = Props.createFilter;
  var icon = Props.icon;
  var className = Props.className;
  var view = Props.view;
  var renderOption = renderOptionOpt !== undefined ? renderOptionOpt : defaultRenderOption;
  var renderValue = renderValueOpt !== undefined ? renderValueOpt : defaultRenderOption;
  var value = React.useMemo((function () {
          return getValueFromAperture(aperture, attribute);
        }), /* tuple */[
        aperture,
        attribute
      ]);
  var handleOnChange = function ($$event, _jsEvent) {
    var value = $$event.target.value;
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FilterSelect */Block.__(5, [
                view,
                attribute
              ])]));
    if (value === "All") {
      return Curry._1(handleSetAperture, removeAttributeFilters(aperture, attribute));
    } else {
      return Curry._1(handleSetAperture, addFilter(removeAttributeFilters(aperture, attribute), Curry._1(createFilter, value)));
    }
  };
  var tmp = {
    value: value,
    name: name,
    renderValue: (function (value) {
        return Belt_Option.getWithDefault(Belt_Option.map(icon, (function (icon$prime) {
                          return React.createElement(BtFilterSelect$ValueWithIcon, {
                                      icon: icon$prime,
                                      children: Curry._1(renderValue, value)
                                    });
                        })), I18N$BsConsole.show(undefined, value));
      }),
    onChange: handleOnChange,
    label: label,
    children: null
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(BtOutlineSelect$BsConsole.make, tmp, Belt_Array.map(options, (function (param) {
                    var value = param[0];
                    return React.createElement(MenuItem.default, {
                                disableRipple: true,
                                classes: menuItemClasses,
                                value: value,
                                className: menuItem,
                                children: React.createElement(Row2$BsConsole.make, {
                                      justifyContent: /* spaceBetween */516682146,
                                      className: contentRow,
                                      children: null
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          className: "value",
                                          children: Curry._1(renderOption, value)
                                        }), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          className: "count",
                                          children: I18N$BsConsole.show(undefined, Numeral$BsConsole.format("0,0", String(param[1])))
                                        })),
                                key: value
                              });
                  })), loading$1 ? React.createElement(MenuItem.default, {
                    disabled: true,
                    children: React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement("span", {
                              className: loading
                            }, I18N$BsConsole.show(undefined, "Loading")), React.createElement(CircularProgress.default, {
                              size: 16,
                              color: "primary"
                            }))
                  }) : null);
}

var make = BtFilterSelect;

exports.Style = Style;
exports.ValueWithIcon = ValueWithIcon;
exports.removeAttributeFilters = removeAttributeFilters;
exports.addFilter = addFilter;
exports.getValueFromAperture = getValueFromAperture;
exports.getDistributionDataFromRow = getDistributionDataFromRow;
exports.parseDistributionData = parseDistributionData;
exports.defaultRenderOption = defaultRenderOption;
exports.make = make;
/* label Not a pure module */
