// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Fragment$BsConsole = require("../Fragment.js");
var SlateReact$BsConsole = require("../bindings/SlateReact.js");
var SlatePlugins$BsConsole = require("./SlatePlugins.js");
var MentionAutocomplete$BsConsole = require("./MentionAutocomplete.js");

var component = RR$BsConsole.reducerComponent("CommentContent-BsConsole");

function make(className, editorRef, editable, allUsers, activeUser, value, onChange, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              return Curry._1(send, /* SetEditPlugins */Block.__(0, [[SlatePlugins$BsConsole.mentions((function (search, cb) {
                                      return Curry._1(send, /* SearchMentions */Block.__(1, [/* tuple */[
                                                      search,
                                                      cb
                                                    ]]));
                                    }), (function (param) {
                                      return Curry._1(send, /* HideMentionSearch */0);
                                    }), (function (param) {
                                      return Curry._1(send, /* SelectSuggestion */1);
                                    }), (function (param) {
                                      return Curry._1(send, /* MatchSuggestion */2);
                                    }), (function (param) {
                                      return Curry._1(send, /* NextSuggestion */3);
                                    }), (function (param) {
                                      return Curry._1(send, /* PreviousSuggestion */4);
                                    }), undefined)]]));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var send = param.send;
              var editorClass = Css.style(/* :: */[
                    Css.height(Css.pct(100)),
                    /* :: */[
                      Css.fontSize(Css.px(14)),
                      /* [] */0
                    ]
                  ]);
              return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                              ReasonReact.element(undefined, editorRef, SlateReact$BsConsole.Editor.make(undefined, className !== undefined ? Css.merge(/* :: */[
                                              editorClass,
                                              /* :: */[
                                                className,
                                                /* [] */0
                                              ]
                                            ]) : editorClass, editable ? "Add comment" : "", !editable, value, (function (change) {
                                          return Curry._1(onChange, change.value);
                                        }), undefined, editable ? state.editPlugins : state.readOnlyPlugins, [])),
                              ReasonReact.element(undefined, undefined, MentionAutocomplete$BsConsole.make(Belt_Option.isSome(state.mentionSearch), state.autocompleteUsers, state.selectedIndex, (function (user) {
                                          return Curry._1(send, /* MentionUserSelected */Block.__(2, [user]));
                                        }), []))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      editPlugins: [],
                      readOnlyPlugins: [SlatePlugins$BsConsole.mentionsView(activeUser, undefined)],
                      autocompleteUsers: [],
                      mentionSearch: undefined,
                      selectedIndex: 0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* HideMentionSearch */0 :
                      return /* Update */Block.__(0, [{
                                  editPlugins: state.editPlugins,
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: state.autocompleteUsers,
                                  mentionSearch: undefined,
                                  selectedIndex: state.selectedIndex
                                }]);
                  case /* SelectSuggestion */1 :
                      var match = state.mentionSearch;
                      if (match === undefined) {
                        return /* Update */Block.__(0, [{
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  }]);
                      }
                      var cb = match[1];
                      var user = Belt_Array.get(state.autocompleteUsers, state.selectedIndex);
                      if (user !== undefined) {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  {
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  },
                                  (function (param) {
                                      return Curry._1(cb, user);
                                    })
                                ]);
                      } else {
                        return /* Update */Block.__(0, [{
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  }]);
                      }
                  case /* MatchSuggestion */2 :
                      var match$1 = state.mentionSearch;
                      if (match$1 === undefined) {
                        return /* Update */Block.__(0, [{
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  }]);
                      }
                      var cb$1 = match$1[1];
                      var search = match$1[0];
                      var user$1 = state.autocompleteUsers.find((function (user) {
                              return user.username === search;
                            }));
                      if (user$1 !== undefined) {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  {
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  },
                                  (function (param) {
                                      return Curry._1(cb$1, user$1);
                                    })
                                ]);
                      } else {
                        return /* Update */Block.__(0, [{
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  }]);
                      }
                  case /* NextSuggestion */3 :
                      var newIndex = state.selectedIndex + 1 | 0;
                      return /* Update */Block.__(0, [{
                                  editPlugins: state.editPlugins,
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: state.autocompleteUsers,
                                  mentionSearch: state.mentionSearch,
                                  selectedIndex: newIndex >= state.autocompleteUsers.length ? newIndex - state.autocompleteUsers.length | 0 : newIndex
                                }]);
                  case /* PreviousSuggestion */4 :
                      var newIndex$1 = state.selectedIndex - 1 | 0;
                      return /* Update */Block.__(0, [{
                                  editPlugins: state.editPlugins,
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: state.autocompleteUsers,
                                  mentionSearch: state.mentionSearch,
                                  selectedIndex: newIndex$1 < 0 ? state.autocompleteUsers.length - 1 | 0 : newIndex$1
                                }]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* SetEditPlugins */0 :
                      return /* Update */Block.__(0, [{
                                  editPlugins: action[0],
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: state.autocompleteUsers,
                                  mentionSearch: state.mentionSearch,
                                  selectedIndex: state.selectedIndex
                                }]);
                  case /* SearchMentions */1 :
                      var match$2 = action[0];
                      var search$1 = match$2[0];
                      return /* Update */Block.__(0, [{
                                  editPlugins: state.editPlugins,
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: Belt_Array.keep(allUsers, (function (user) {
                                          return user.username.startsWith(search$1);
                                        })),
                                  mentionSearch: /* tuple */[
                                    search$1,
                                    match$2[1]
                                  ],
                                  selectedIndex: 0
                                }]);
                  case /* MentionUserSelected */2 :
                      var user$2 = action[0];
                      var match$3 = state.mentionSearch;
                      if (match$3 === undefined) {
                        return /* Update */Block.__(0, [{
                                    editPlugins: state.editPlugins,
                                    readOnlyPlugins: state.readOnlyPlugins,
                                    autocompleteUsers: state.autocompleteUsers,
                                    mentionSearch: undefined,
                                    selectedIndex: state.selectedIndex
                                  }]);
                      }
                      var cb$2 = match$3[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  editPlugins: state.editPlugins,
                                  readOnlyPlugins: state.readOnlyPlugins,
                                  autocompleteUsers: state.autocompleteUsers,
                                  mentionSearch: undefined,
                                  selectedIndex: state.selectedIndex
                                },
                                (function (param) {
                                    return Curry._1(cb$2, user$2);
                                  })
                              ]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
