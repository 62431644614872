// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Button = require("@material-ui/core/Button");
var ListItemText = require("@material-ui/core/ListItemText");

function SubGoToInviteNewUser(Props) {
  var handleChangeUrl = Props.handleChangeUrl;
  var disabled = Props.disabled;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(ListItemText.default, {
                  secondary: I18N$BsConsole.show(undefined, "They will be invited with default settings"),
                  children: I18N$BsConsole.show(undefined, "Invite a new user")
                }), React.createElement("div", undefined, React.createElement(Button.default, {
                      variant: "raised",
                      color: "primary",
                      disabled: disabled,
                      onClick: (function (_event) {
                          return Curry._1(handleChangeUrl, /* SettingsInvitesNew */12);
                        }),
                      children: I18N$BsConsole.show(undefined, "Send an invitation")
                    })));
}

function make(handleChangeUrl, disabledOpt, children) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return ReasonReactCompat.wrapReactForReasonReact(SubGoToInviteNewUser, {
              handleChangeUrl: handleChangeUrl,
              disabled: disabled
            }, children);
}

var Re = {
  make: make
};

var make$1 = SubGoToInviteNewUser;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
