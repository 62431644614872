// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("./util.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");

var storageConsumedKey = "_Backtrace-Storage-Consumed-Override";

var storageLimitKey = "_Backtrace-Storage-Limit-Override";

var errorsSubmittedKey = "_Backtrace-Errors-Submitted-Override";

var errorsLimitKey = "_Backtrace-Errors-Limit-Override";

var userCountKey = "_Backtrace-User-Count-Override";

var userLimitKey = "_Backtrace-User-Limit-Override";

var limitsJsonKey = "_Backtrace-Limits-Json-Override";

function toString(t) {
  switch (t) {
    case /* StorageConsumed */0 :
        return storageConsumedKey;
    case /* StorageLimit */1 :
        return storageLimitKey;
    case /* ErrorsReceived */2 :
        return errorsSubmittedKey;
    case /* ErrorsLimit */3 :
        return errorsLimitKey;
    case /* UserLimit */4 :
        return userLimitKey;
    case /* UserCount */5 :
        return userCountKey;
    case /* LimitsJson */6 :
        return limitsJsonKey;
    
  }
}

var Key = {
  storageConsumedKey: storageConsumedKey,
  storageLimitKey: storageLimitKey,
  errorsSubmittedKey: errorsSubmittedKey,
  errorsLimitKey: errorsLimitKey,
  userCountKey: userCountKey,
  userLimitKey: userLimitKey,
  limitsJsonKey: limitsJsonKey,
  toString: toString
};

function get(overrideKey, decoder) {
  return Belt_Option.map(Caml_option.nullable_to_opt(window.sessionStorage.getItem(toString(overrideKey))), decoder);
}

function decoder(overrideDecoder, overrideKey, decoder$1, json) {
  var override = get(overrideKey, overrideDecoder);
  if (override !== undefined) {
    return Caml_option.valFromOption(override);
  } else {
    return Curry._1(decoder$1, json);
  }
}

var TempOverride = {
  Key: Key,
  get: get,
  decoder: decoder
};

function ofJson(json) {
  return {
          endTimestamp: Json_decode.optional((function (param) {
                  return Json_decode.field("endTimestamp", Json_decode.$$float, param);
                }), json),
          frozenUids: Json_decode.optional((function (param) {
                  return Json_decode.field("frozenUids", (function (param) {
                                return Json_decode.list(Json_decode.$$int, param);
                              }), param);
                }), json)
        };
}

var NemesisTrial = {
  ofJson: ofJson
};

function ofJson$1(json) {
  var match = Json_decode.optional((function (param) {
          return Json_decode.field("requireContact", Json_decode.bool, param);
        }), json);
  if (match !== undefined && match) {
    return /* Legacy */Block.__(0, [/* RequireContact */4]);
  }
  var match$1 = Json_decode.optional((function (param) {
          return Json_decode.field("flow", Json_decode.string, param);
        }), json);
  if (match$1 === undefined) {
    return /* Legacy */Block.__(0, [/* SelfService */0]);
  }
  switch (match$1) {
    case "1.0.0" :
        return /* Legacy */Block.__(0, [/* SelfService */0]);
    case "2.0.0" :
        if (Json_decode.field("onTrialPlan", Json_decode.bool, json)) {
          return /* Legacy */Block.__(0, [/* Trial */2]);
        } else if (Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("isPartner", Json_decode.bool, param);
                    }), json), false)) {
          return /* Legacy */Block.__(0, [/* Partner */3]);
        } else {
          return /* Legacy */Block.__(0, [/* Limited */1]);
        }
    case "3.0.0" :
        var trial = Json_decode.optional((function (param) {
                return Json_decode.field("nemesisTrial", ofJson, param);
              }), json);
        if (trial === undefined) {
          return /* Subscription */Block.__(1, [/* Base */0]);
        }
        var endTimestamp = trial.endTimestamp;
        if (endTimestamp !== undefined) {
          if (endTimestamp > Date.now()) {
            return /* Subscription */Block.__(1, [/* ActiveTrial */Block.__(0, [endTimestamp])]);
          } else {
            return /* Subscription */Block.__(1, [/* ExpiredTrial */Block.__(1, [endTimestamp])]);
          }
        } else {
          return /* Subscription */Block.__(1, [/* Base */0]);
        }
    default:
      return /* Legacy */Block.__(0, [/* SelfService */0]);
  }
}

var FeatureGate = {
  ofJson: ofJson$1
};

var $$default = {
  autoRenew: false
};

function ofJson$2(json) {
  return {
          autoRenew: Pervasives.bool_of_string(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("autoRenew", Json_decode.string, param);
                        }), json), "false"))
        };
}

var External = {
  $$default: $$default,
  ofJson: ofJson$2
};

function ofJson$3(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json)
        };
}

var Feature = {
  ofJson: ofJson$3
};

function ofJson$4(json) {
  return Json_decode.list(ofJson$3, json);
}

var Features = {
  ofJson: ofJson$4
};

function isPermitted(features, flag) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_List.getBy(features, (function (param) {
                        return param.name === "retention";
                      })), (function (param) {
                    return param.value === "custom";
                  })), false);
}

var Flag = {
  isPermitted: isPermitted
};

function ofJson$5(json) {
  var variant = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("variant", Json_decode.string, param);
            }), json), "");
  var match = variant.toLowerCase();
  var tmp;
  switch (match) {
    case "unity" :
    case "unity-store" :
        tmp = /* UnityStore */1;
        break;
    default:
      tmp = /* BacktraceSelfService */0;
  }
  return {
          planID: Json_decode.optional((function (param) {
                  return Json_decode.field("planID", Json_decode.string, param);
                }), json),
          period: Json_decode.optional((function (param) {
                  return Json_decode.field("period", Json_decode.string, param);
                }), json),
          manualOverride: Json_decode.optional((function (param) {
                  return Json_decode.field("manualOverride", Json_decode.bool, param);
                }), json),
          expirationDetails: Json_decode.optional((function (param) {
                  return Json_decode.field("expirationDetails", Json_decode.string, param);
                }), json),
          customerID: Json_decode.optional((function (param) {
                  return Json_decode.field("customerID", Json_decode.string, param);
                }), json),
          trialEnd: Json_decode.map((function (f) {
                  return new Date(Belt_Option.getWithDefault(f, 0) * 1000);
                }), (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("trialEnd", Json_decode.$$float, param);
                              }), param);
                }), json),
          expirationTime: Json_decode.map((function (f) {
                  return new Date(Belt_Option.getWithDefault(f, 0) * 1000);
                }), (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("expirationTime", Json_decode.$$float, param);
                              }), param);
                }), json),
          gate: Belt_Option.getWithDefault(Json_decode.optional(ofJson$1, json), /* Legacy */Block.__(0, [/* SelfService */0])),
          hasSceduledChange: Json_decode.optional((function (param) {
                  return Json_decode.field("hasSceduledChange", Json_decode.bool, param);
                }), json),
          email: Json_decode.optional((function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          onTrialPlan: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("onTrialPlan", Json_decode.bool, param);
                    }), json), false),
          isPartner: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("isPartner", Json_decode.bool, param);
                    }), json), false),
          sales: Json_decode.optional((function (param) {
                  return Json_decode.field("sales", Json_decode.string, param);
                }), json),
          users: Json_decode.optional((function (param) {
                  return Json_decode.field("users", Json_decode.$$int, param);
                }), json),
          partnerManaged: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("partnerManaged", Json_decode.bool, param);
                    }), json), false),
          variant: tmp,
          external_: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("external", ofJson$2, param);
                    }), json), $$default),
          features: Json_decode.optional((function (param) {
                  return Json_decode.field("features", (function (param) {
                                return Json_decode.list(ofJson$3, param);
                              }), param);
                }), json),
          invoiceMe: Json_decode.optional((function (param) {
                  return Json_decode.field("invoiceMe", Json_decode.bool, param);
                }), json),
          nemesisTrial: Json_decode.optional((function (param) {
                  return Json_decode.field("nemesisTrial", ofJson, param);
                }), json)
        };
}

var Metadata = {
  ofJson: ofJson$5
};

function ofFloat(num) {
  if (num === 0.0) {
    return /* Unlimited */0;
  } else {
    return /* Limit */[num];
  }
}

function ofString(str) {
  return ofFloat(Caml_format.caml_float_of_string(str));
}

function ofJson$6(json) {
  return ofFloat(Json_decode.$$float(json));
}

var Limit = {
  ofFloat: ofFloat,
  ofString: ofString,
  ofJson: ofJson$6
};

function ofJson$7(json) {
  var counterDecoder = function (param) {
    return decoder(Caml_format.caml_float_of_string, /* StorageConsumed */0, Json_decode.$$float, param);
  };
  var limitDecoder = function (param) {
    return decoder(ofString, /* StorageLimit */1, ofJson$6, param);
  };
  return {
          limit: Json_decode.field("limit", limitDecoder, json),
          counter: Json_decode.field("counter", counterDecoder, json),
          rejected: Json_decode.field("rejected", Json_decode.$$float, json)
        };
}

var $$Storage = {
  Limit: Limit,
  ofJson: ofJson$7
};

function ofInt(num) {
  if (num === 0) {
    return /* Unlimited */0;
  } else {
    return /* Limit */[num];
  }
}

function ofString$1(str) {
  return ofInt(Caml_format.caml_int_of_string(str));
}

function ofJson$8(json) {
  return ofInt(Json_decode.$$int(json));
}

var Limit$1 = {
  ofInt: ofInt,
  ofString: ofString$1,
  ofJson: ofJson$8
};

function ofJson$9(json) {
  var counterDecoder = function (param) {
    return decoder(Caml_format.caml_int_of_string, /* ErrorsReceived */2, Json_decode.$$int, param);
  };
  var limitDecoder = function (param) {
    return decoder(ofString$1, /* ErrorsLimit */3, ofJson$8, param);
  };
  return {
          limit: Json_decode.field("limit", limitDecoder, json),
          counter: Json_decode.field("counter", counterDecoder, json),
          rejected: Json_decode.field("rejected", Json_decode.$$int, json),
          limits: Json_decode.field("limits", (function (param) {
                  return Json_decode.array(Json_decode.$$int, param);
                }), json)
        };
}

var Submissions = {
  Limit: Limit$1,
  ofJson: ofJson$9
};

function _ofJson(json) {
  var partial_arg_000 = function (str) {
    return ofJson$5(JSON.parse(Json_decode.string(str)));
  };
  var partial_arg_001 = /* :: */[
    ofJson$5,
    /* [] */0
  ];
  var partial_arg = /* :: */[
    partial_arg_000,
    partial_arg_001
  ];
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          metadata: Json_decode.field("metadata", (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json),
          storage: Json_decode.optional((function (param) {
                  return Json_decode.field("storage", ofJson$7, param);
                }), json),
          submissions: Json_decode.field("submissions", ofJson$9, json)
        };
}

function ofJson$10(universeName, json) {
  var overrideJson = Belt_Option.flatMap(get(/* LimitsJson */6, Json.parse), Util$BsConsole.identity);
  if (overrideJson === undefined) {
    return Json_decode.optional((function (param) {
                  return Json_decode.field(universeName, _ofJson, param);
                }), json);
  }
  var __x = Caml_option.valFromOption(overrideJson);
  return Json_decode.optional((function (param) {
                return Json_decode.field(universeName, _ofJson, param);
              }), __x);
}

function getCurrentUserCount(param) {
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var allCurrentUsers;
  allCurrentUsers = typeof allUsersRemote === "number" ? [] : (
      allUsersRemote.tag ? [] : Belt_Array.keep(allUsersRemote[0][0], (function (user) {
                if (user.deleted === 0) {
                  return !user.email.endsWith("@backtrace.io");
                } else {
                  return false;
                }
              }))
    );
  var userCount = allCurrentUsers.length;
  var override = get(/* UserCount */5, Caml_format.caml_int_of_string);
  if (override !== undefined) {
    return override;
  } else {
    return userCount;
  }
}

function getUserLimit(limits) {
  var userLimit = Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
              return param.name === "users";
            })), (function (param) {
          return Caml_format.caml_int_of_string(param.value);
        }));
  var userLimit$prime = Caml_obj.caml_equal(userLimit, 0) ? undefined : userLimit;
  var override = get(/* UserLimit */4, Caml_format.caml_int_of_string);
  if (override !== undefined) {
    return override;
  } else {
    return userLimit$prime;
  }
}

function moreUsersPermitted(currentUserCount, userLimitMaybe) {
  if (userLimitMaybe !== undefined) {
    return Caml_obj.caml_lessthan(currentUserCount, Caml_option.valFromOption(userLimitMaybe));
  } else {
    return true;
  }
}

function getStorageLimit(limits) {
  return Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
                    return param.name === "storage";
                  })), (function (param) {
                return Caml_format.caml_int_of_string(param.value);
              }));
}

function getStorageLimitInBytes(limits) {
  var match = limits.storage;
  if (match !== undefined) {
    var l = match.limit;
    if (l) {
      return l[0];
    }
    
  }
  return Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
                    return param.name === "storage";
                  })), (function (param) {
                return Caml_format.caml_float_of_string(param.value) * 1000000000;
              }));
}

function getStorageRejected(limits) {
  return Belt_Option.mapWithDefault(limits.storage, 0, (function (s) {
                return s.rejected;
              }));
}

function getRetentionDeleteOnlyPhysical(limits) {
  return Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
                    return param.name === "retention-physical";
                  })), (function (param) {
                return Caml_format.caml_float_of_string(param.value);
              }));
}

function getRetentionDeleteAll(limits) {
  return Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
                    return param.name === "retention-all";
                  })), (function (param) {
                return Caml_format.caml_float_of_string(param.value);
              }));
}

function getRetentionCompress(limits) {
  return Belt_Option.map(Belt_List.getBy(Belt_Option.getWithDefault(limits.metadata.features, /* [] */0), (function (param) {
                    return param.name === "retention-compress";
                  })), (function (param) {
                return Caml_format.caml_float_of_string(param.value);
              }));
}

var bytesInGB = 1000000000;

exports.TempOverride = TempOverride;
exports.NemesisTrial = NemesisTrial;
exports.FeatureGate = FeatureGate;
exports.External = External;
exports.Feature = Feature;
exports.Features = Features;
exports.Flag = Flag;
exports.Metadata = Metadata;
exports.$$Storage = $$Storage;
exports.Submissions = Submissions;
exports._ofJson = _ofJson;
exports.ofJson = ofJson$10;
exports.getCurrentUserCount = getCurrentUserCount;
exports.getUserLimit = getUserLimit;
exports.moreUsersPermitted = moreUsersPermitted;
exports.bytesInGB = bytesInGB;
exports.getStorageLimit = getStorageLimit;
exports.getStorageLimitInBytes = getStorageLimitInBytes;
exports.getStorageRejected = getStorageRejected;
exports.getRetentionDeleteOnlyPhysical = getRetentionDeleteOnlyPhysical;
exports.getRetentionDeleteAll = getRetentionDeleteAll;
exports.getRetentionCompress = getRetentionCompress;
/* Util-BsConsole Not a pure module */
