// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_SetString = require("bs-platform/lib/js/belt_SetString.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Text$BsConsole = require("./Text.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var BtTheme$BsConsole = require("./BtTheme.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ViewNav$BsConsole = require("./ViewNav.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Services$BsConsole = require("./Services.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var UnitySdkCTA$BsConsole = require("./UnitySdkCTA.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("./configuration.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Styles = require("@material-ui/core/styles");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var BtFilterSelect$BsConsole = require("./BtFilterSelect.js");
var BtOutlineSelect$BsConsole = require("./BtOutlineSelect.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var DashboardWidgets$BsConsole = require("./DashboardWidgets.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var CardContent = require("@material-ui/core/CardContent");
var DashboardWidgetGrid$BsConsole = require("./DashboardWidgetGrid.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var DialogActions = require("@material-ui/core/DialogActions");
var DistributionValueSelect$BsConsole = require("./DistributionValueSelect.js");
var ProjectFrontendSettings$BsConsole = require("./ProjectFrontendSettings.js");
var KeyboardBackspace = require("@material-ui/icons/KeyboardBackspace");

var paper = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flexDirection(/* column */-963948842),
              /* [] */0
            ]
          ]),
      /* :: */[
        BtPaper$BsConsole.mainViewMargins,
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var cta = Css.style(/* :: */[
      Css.margin4(Css.px(15), Css.px(15), Css.px(0), Css.px(15)),
      /* [] */0
    ]);

var groupValue = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.marginRight(Css.px(4)),
        /* [] */0
      ]
    ]);

var invalidGroupValue = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* :: */[
        Css.marginRight(Css.px(4)),
        /* [] */0
      ]
    ]);

var groupSpacer = Css.style(/* :: */[
      Css.marginRight(Css.px(4)),
      /* [] */0
    ]);

var groupSelect = Css.style(/* :: */[
      Css.minWidth(Css.px(200)),
      /* [] */0
    ]);

var groupMenuItemClasses = {
  root: Css.style(/* :: */[
        Css.hover(/* :: */[
              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
              /* [] */0
            ]),
        /* [] */0
      ]),
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
        /* [] */0
      ])
};

var compareMenuItemClasses = BtFilterSelect$BsConsole.Style.menuItemClasses;

var invalidGroupMenuItemClasses = {
  root: Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.redDark)),
        /* [] */0
      ]),
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground))),
        /* [] */0
      ])
};

var invalidCheckboxClasses = {
  root: Css.style(/* :: */[
        Css.unsafe("color", "inherit"),
        /* [] */0
      ])
};

var menuItemGroupName = Css.style(/* :: */[
      Css.marginRight(Css.rem(2)),
      /* [] */0
    ]);

var groupCount = Css.style(/* :: */[
      Css.opacity(0.9),
      /* [] */0
    ]);

var groupSelectErrorMessageContainer = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.padding2(Css.px(11), Css.px(16)),
          /* [] */0
        ]
      ]
    ]);

var groupSelectErrorMessage = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.whiteSpace(/* normal */812216871),
        /* :: */[
          Css.maxWidth(Css.px(300)),
          /* [] */0
        ]
      ]
    ]);

var groupSelectButtonContainer = Css.style(/* :: */[
      Css.padding2(Css.px(11), Css.px(16)),
      /* [] */0
    ]);

var Style = {
  paper: paper,
  iconContainer: iconContainer,
  cta: cta,
  groupValue: groupValue,
  invalidGroupValue: invalidGroupValue,
  groupSpacer: groupSpacer,
  groupSelect: groupSelect,
  groupMenuItemClasses: groupMenuItemClasses,
  compareMenuItemClasses: compareMenuItemClasses,
  invalidGroupMenuItemClasses: invalidGroupMenuItemClasses,
  invalidCheckboxClasses: invalidCheckboxClasses,
  menuItemGroupName: menuItemGroupName,
  groupCount: groupCount,
  groupSelectErrorMessageContainer: groupSelectErrorMessageContainer,
  groupSelectErrorMessage: groupSelectErrorMessage,
  groupSelectButtonContainer: groupSelectButtonContainer
};

function getVersionAttribute(projectFrontendSettings, attributes) {
  var projectSettingsVersionAttribute = Belt_Option.flatMap(projectFrontendSettings, (function (settings) {
          return settings.versionAttribute;
        }));
  var versionAttributes = Belt_List.keep(attributes, (function (attribute) {
          var match = attribute.format;
          var match$1 = attribute.name;
          var isAVersionAttribute;
          switch (match) {
            case "none" :
                isAVersionAttribute = match$1.toLowerCase().includes("version") && attribute.statistics.used;
                break;
            case "semver" :
                isAVersionAttribute = true;
                break;
            default:
              isAVersionAttribute = false;
          }
          if (attribute.statistics.used) {
            return isAVersionAttribute;
          } else {
            return false;
          }
        }));
  var applicationVersion = Belt_List.getBy(versionAttributes, (function (attr) {
          return attr.name === "application.version";
        }));
  var version = Belt_List.getBy(versionAttributes, (function (attr) {
          return attr.name === "version";
        }));
  if (projectSettingsVersionAttribute !== undefined) {
    return projectSettingsVersionAttribute;
  } else if (version !== undefined) {
    return version.name;
  } else if (applicationVersion !== undefined) {
    return applicationVersion.name;
  } else {
    return ;
  }
}

function CompareReleases$VersionSelect(Props) {
  var projectName = Props.projectName;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleReplaceUrl = Props.handleReplaceUrl;
  var aperture = Props.aperture;
  var attributes = Props.attributes;
  var currentProject = Props.currentProject;
  var projectFrontendSettings = Props.projectFrontendSettings;
  React.useEffect((function () {
          var versionAttribute = getVersionAttribute(projectFrontendSettings, attributes);
          if (versionAttribute !== undefined) {
            Curry._1(handleReplaceUrl, /* ProjectCompareReleases */Block.__(9, [
                    projectName,
                    versionAttribute,
                    {
                      aperture: aperture,
                      groups: undefined
                    }
                  ]));
          }
          
        }), [projectFrontendSettings]);
  return React.createElement(DashboardWidgets$BsConsole.ChooseVersion.make, {
              attributes: attributes,
              onVersionAttributeChange: (function (versionAttribute) {
                  return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                                projectName,
                                versionAttribute,
                                {
                                  aperture: aperture,
                                  groups: undefined
                                }
                              ]));
                }),
              token: token,
              project: currentProject
            });
}

var VersionSelect = {
  make: CompareReleases$VersionSelect
};

function getVersionAttributes(attributes) {
  return Belt_List.keep(attributes, (function (attribute) {
                var match = attribute.format;
                var match$1 = attribute.name;
                switch (match) {
                  case "none" :
                      if (match$1.includes("version")) {
                        return true;
                      } else {
                        return false;
                      }
                  case "semver" :
                      return true;
                  default:
                    return false;
                }
              }));
}

function CompareReleases$CompareAttributeSelect(Props) {
  var token = Props.token;
  var config = Props.config;
  var attributes = Props.attributes;
  var currentProject = Props.currentProject;
  var compareAttribute = Props.compareAttribute;
  var setCompareAttribute = Props.setCompareAttribute;
  var projectFrontendSettings = Props.projectFrontendSettings;
  var match = React.useState((function () {
          return compareAttribute;
        }));
  var setValue = match[1];
  var value = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDisplayDialog = match$1[1];
  var versionAttributes = React.useMemo((function () {
          return getVersionAttributes(attributes);
        }), [attributes]);
  var handleOnChange = function ($$event, _jsEvent) {
    var value$prime = $$event.target.value;
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* CompareAttributeUpdate */116]));
    Curry._1(setValue, (function (param) {
            return value$prime;
          }));
    var isValueCurrentDefault = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(projectFrontendSettings, (function (settings) {
                    return settings.versionAttribute;
                  })), (function (attribute) {
                return attribute === value$prime;
              })), false);
    if (Caml_obj.caml_equal(config.user.role, "admin") && !isValueCurrentDefault) {
      return Curry._1(setDisplayDialog, (function (param) {
                    return true;
                  }));
    } else {
      return Curry._1(setCompareAttribute, value$prime);
    }
  };
  var handleOnClose = function (_event) {
    return Curry._1(setDisplayDialog, (function (param) {
                  return false;
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(BtOutlineSelect$BsConsole.make, {
                  value: value,
                  name: "compare-attribute",
                  renderValue: (function (value) {
                      return React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  children: null
                                }, React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      className: iconContainer,
                                      children: React.createElement(MuiIcons$BsConsole.Cube.make, {
                                            color: "inherit",
                                            fontSize: "inherit"
                                          })
                                    }), I18N$BsConsole.show(undefined, value));
                    }),
                  onChange: handleOnChange,
                  label: "Compare releases by",
                  className: Css.style(/* :: */[
                        Css.minWidth(Css.px(140)),
                        /* :: */[
                          Css.marginRight(Css.rem(1)),
                          /* [] */0
                        ]
                      ]),
                  children: Belt_List.toArray(Belt_List.map(versionAttributes, (function (attribute) {
                              return React.createElement(MenuItem.default, {
                                          disableRipple: true,
                                          classes: compareMenuItemClasses,
                                          value: attribute.name,
                                          children: I18N$BsConsole.showSkip(attribute.name)
                                        });
                            })))
                }), match$1[0] && Caml_obj.caml_equal(config.user.role, "admin") ? React.createElement(Dialog.default, {
                    open: true,
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.width(Css.pct(25)),
                            /* :: */[
                              Css.unsafe("maxWidth", "unset"),
                              /* [] */0
                            ]
                          ]),
                      container: Css.style(/* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* [] */0
                          ]),
                      root: Css.style(/* :: */[
                            Css.userSelect(/* none */-922086728),
                            /* [] */0
                          ])
                    },
                    onClose: handleOnClose,
                    children: React.createElement(CardContent.default, {
                          children: null
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Heading6 */5,
                              color: Colors$BsConsole.grey1,
                              children: I18N$BsConsole.show(undefined, "Set default version attribute")
                            }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(1.0)),
                                    /* :: */[
                                      Css.marginTop(Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ]),
                              color: Colors$BsConsole.grey1,
                              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Before we take you to your next view, would you like to set \"",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    "\" as your project's default version attribute?",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "Before we take you to your next view, would you like to set \"%s\" as your project's default version attribute?"
                                      ]), value)
                            }), React.createElement(DialogActions.default, {
                              children: null
                            }, React.createElement(Button.default, {
                                  color: "primary",
                                  onClick: (function (_event) {
                                      Curry._1(setDisplayDialog, (function (param) {
                                              return false;
                                            }));
                                      return Curry._1(setCompareAttribute, value);
                                    }),
                                  children: I18N$BsConsole.show(undefined, "No")
                                }), React.createElement(Button.default, {
                                  variant: "contained",
                                  color: "primary",
                                  onClick: (function (param) {
                                      return ProjectFrontendSettings$BsConsole.updateOrCreate(token, currentProject.pid, (function (settings) {
                                                    if (settings !== undefined) {
                                                      return {
                                                              defaultView: settings.defaultView,
                                                              bookmarkedViews: settings.bookmarkedViews,
                                                              serviceOverrides: settings.serviceOverrides,
                                                              versionAttribute: value,
                                                              normBy: settings.normBy,
                                                              unityCDA: settings.unityCDA,
                                                              firstTimeExperience: settings.firstTimeExperience
                                                            };
                                                    } else {
                                                      return {
                                                              defaultView: ProjectFrontendSettings$BsConsole.empty.defaultView,
                                                              bookmarkedViews: ProjectFrontendSettings$BsConsole.empty.bookmarkedViews,
                                                              serviceOverrides: ProjectFrontendSettings$BsConsole.empty.serviceOverrides,
                                                              versionAttribute: value,
                                                              normBy: ProjectFrontendSettings$BsConsole.empty.normBy,
                                                              unityCDA: ProjectFrontendSettings$BsConsole.empty.unityCDA,
                                                              firstTimeExperience: ProjectFrontendSettings$BsConsole.empty.firstTimeExperience
                                                            };
                                                    }
                                                  }), (function (param) {
                                                    Curry._1(setCompareAttribute, value);
                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Version attribute saved"));
                                                    return Curry._1(setDisplayDialog, (function (param) {
                                                                  return false;
                                                                }));
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Yes")
                                })))
                  }) : null);
}

var CompareAttributeSelect = {
  getVersionAttributes: getVersionAttributes,
  make: CompareReleases$CompareAttributeSelect
};

function arraysAreDifferent(listA, listB) {
  var a = Belt_SetString.fromArray(listA);
  var b = Belt_SetString.fromArray(listB);
  return Belt_SetString.cmp(a, b) !== 0;
}

function truncateGroupName(groupName) {
  var nameLength = groupName.length;
  if (nameLength <= 20) {
    return groupName;
  }
  var start = groupName.slice(0, 8);
  var end_ = groupName.slice(nameLength - 5 | 0);
  return start + ("..." + end_);
}

var groupNotAvailableText = "At least one selected group is not found in the current data set. Remove group from choices or widen filter set to accomodate all chosen groups.";

var needAtLeastOneGroupText = "Select at least one group";

var groupLimitExceededText = "Choose up to 4 groups only";

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* OnOpen */0) {
      return {
              _open: true,
              selected: state.selected,
              warning: state.warning
            };
    } else {
      return {
              _open: false,
              selected: [],
              warning: undefined
            };
    }
  }
  if (!action.tag) {
    return {
            _open: false,
            selected: action[0],
            warning: undefined
          };
  }
  var available = action[1];
  var selected = action[0];
  if (Belt_Array.reduce(selected, false, (function (acc, curr) {
            return acc ? acc : !available.includes(curr);
          }))) {
    return {
            _open: state._open,
            selected: selected,
            warning: groupNotAvailableText
          };
  } else if (selected.length === 0) {
    return {
            _open: state._open,
            selected: state.selected,
            warning: needAtLeastOneGroupText
          };
  } else if (selected.length > 4) {
    return {
            _open: state._open,
            selected: state.selected,
            warning: groupLimitExceededText
          };
  } else {
    return {
            _open: state._open,
            selected: selected,
            warning: undefined
          };
  }
}

var initialState_selected = [];

var initialState = {
  _open: false,
  selected: initialState_selected,
  warning: undefined
};

function CompareReleases$GroupSelect(Props) {
  var availableGroups = Props.availableGroups;
  var groups = Props.groups;
  var setSelectedGroups = Props.setSelectedGroups;
  var availableStringArray = React.useMemo((function () {
          return Belt_Array.map(availableGroups, (function (group) {
                        return group.factor.value;
                      }));
        }), [availableGroups]);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var warning = match$1.warning;
  var selected = match$1.selected;
  var _open = match$1._open;
  var latestSelectRequestedRef = React.useRef(undefined);
  React.useEffect((function () {
          if (_open) {
            var latestSelectRequested = latestSelectRequestedRef.current;
            var match = arraysAreDifferent(groups, selected);
            if (match && !(latestSelectRequested !== undefined && arraysAreDifferent(latestSelectRequested, groups))) {
              Curry._1(setSelectedGroups, selected);
              latestSelectRequestedRef.current = selected;
            }
            
          }
          return (function (param) {
                    latestSelectRequestedRef.current = undefined;
                    
                  });
        }), /* tuple */[
        groups,
        selected
      ]);
  React.useEffect((function () {
          if (_open) {
            Curry._1(dispatch, /* SetSelected */Block.__(1, [
                    groups,
                    availableStringArray
                  ]));
          }
          
        }), [_open]);
  var handleOnChange = function ($$event, _jsEvent) {
    var value = $$event.target.value;
    var nextSelected = Belt_Array.keepMap(value, (function (group) {
            if (group === undefined) {
              return ;
            } else {
              return Caml_option.some(group);
            }
          }));
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* CompareGroupSelect */117]));
    return Curry._1(dispatch, /* SetSelected */Block.__(1, [
                  nextSelected,
                  availableStringArray
                ]));
  };
  var handleOnOpen = function (_event) {
    return Curry._1(dispatch, /* OnOpen */0);
  };
  var handleOnClose = function (_event) {
    return Curry._1(dispatch, /* OnClose */1);
  };
  return React.createElement(BtOutlineSelect$BsConsole.make, {
              value: groups,
              name: "compare-groups",
              renderValue: (function (selected) {
                  var available = Belt_Array.map(availableGroups, (function (group) {
                          return group.factor.value;
                        }));
                  var valid = Belt_Array.keep(selected, (function (group) {
                          return available.includes(group);
                        }));
                  var invalid = Belt_Array.keep(selected, (function (group) {
                          return !available.includes(group);
                        }));
                  return React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              children: null
                            }, React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  className: iconContainer,
                                  children: React.createElement(MuiIcons$BsConsole.Code.make, {
                                        color: "inherit",
                                        fontSize: "inherit"
                                      })
                                }), selected.length === 0 ? I18N$BsConsole.showSkip("-") : null, Belt_Array.mapWithIndex(valid, (function (idx, group) {
                                    return React.createElement(React.Fragment, {
                                                children: null,
                                                key: group + ("__" + String(idx))
                                              }, React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body1 */8,
                                                    className: groupValue,
                                                    title: group,
                                                    children: I18N$BsConsole.show(undefined, truncateGroupName(group))
                                                  }), idx < (valid.length - 1 | 0) ? React.createElement("span", {
                                                      className: groupSpacer
                                                    }, I18N$BsConsole.showSkip("/")) : null);
                                  })), valid.length !== 0 && invalid.length !== 0 ? React.createElement("span", {
                                    className: groupSpacer
                                  }, I18N$BsConsole.showSkip("/")) : null, Belt_Array.mapWithIndex(invalid, (function (idx, group) {
                                    return React.createElement(React.Fragment, {
                                                children: null,
                                                key: group + ("__" + String(idx))
                                              }, React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body1 */8,
                                                    className: invalidGroupValue,
                                                    title: group,
                                                    children: I18N$BsConsole.show(undefined, truncateGroupName(group))
                                                  }), idx < (invalid.length - 1 | 0) ? React.createElement("span", {
                                                      className: groupSpacer
                                                    }, I18N$BsConsole.showSkip("/")) : null);
                                  })));
                }),
              onChange: handleOnChange,
              label: "Comparing releases",
              className: groupSelect,
              multiple: true,
              open: _open,
              onClose: handleOnClose,
              onOpen: handleOnOpen,
              displayEmpty: true,
              menuProps: {
                key: selected.join("")
              },
              children: null
            }, Belt_Array.map(availableGroups, (function (group) {
                    var groupName = group.factor.value;
                    return React.createElement(MenuItem.default, {
                                disableRipple: true,
                                classes: groupMenuItemClasses,
                                value: groupName,
                                children: null,
                                key: groupName + ("__" + String(group.count))
                              }, React.createElement(Checkbox.default, {
                                    checked: selected.includes(groupName),
                                    color: "primary"
                                  }), React.createElement(Row2$BsConsole.make, {
                                    justifyContent: /* spaceBetween */516682146,
                                    alignContent: /* center */98248149,
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ]),
                                    children: null
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: menuItemGroupName,
                                        children: I18N$BsConsole.showSkip(groupName)
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        className: groupCount,
                                        children: I18N$BsConsole.show(undefined, Numeral$BsConsole.format("0,0", String(group.count)))
                                      })));
                  })), Belt_Array.map(Belt_Array.keep(selected, (function (group) {
                        return !availableStringArray.includes(group);
                      })), (function (groupName) {
                    return React.createElement(MenuItem.default, {
                                disableRipple: true,
                                classes: invalidGroupMenuItemClasses,
                                value: groupName,
                                children: null,
                                key: groupName
                              }, React.createElement(Checkbox.default, {
                                    checked: selected.includes(groupName),
                                    color: "inherit",
                                    classes: invalidCheckboxClasses
                                  }), React.createElement(Row2$BsConsole.make, {
                                    justifyContent: /* spaceBetween */516682146,
                                    alignContent: /* center */98248149,
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ]),
                                    children: null
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: menuItemGroupName,
                                        children: I18N$BsConsole.showSkip(groupName)
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        className: groupCount,
                                        children: I18N$BsConsole.show(undefined, "Not found in set")
                                      })));
                  })), warning !== undefined ? React.createElement("div", {
                    key: warning,
                    className: groupSelectErrorMessageContainer
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: groupSelectErrorMessage,
                        children: I18N$BsConsole.show(undefined, warning)
                      })) : null);
}

var GroupSelect = {
  arraysAreDifferent: arraysAreDifferent,
  truncateGroupName: truncateGroupName,
  groupNotAvailableText: groupNotAvailableText,
  needAtLeastOneGroupText: needAtLeastOneGroupText,
  groupLimitExceededText: groupLimitExceededText,
  reducer: reducer,
  initialState: initialState,
  make: CompareReleases$GroupSelect
};

function addFilter(aperture, filter) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, filter, __x), aperture);
}

function makeSimpleQuery(aperture, compareAttribute) {
  var aperture$1 = addFilter(aperture, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            compareAttribute,
            /* RegularExpression */Block.__(4, [/* `String */[
                  -976970511,
                  ".*"
                ]])
          ]));
  return /* Aggregate */Block.__(0, [
            aperture$1,
            /* Custom */[compareAttribute],
            Crdb$BsConsole.Fold.empty,
            /* Group */Block.__(0, [/* Descending */1]),
            undefined,
            undefined
          ]);
}

function reducer$1(_state, action) {
  var groups = action[3];
  var compareAttribute = action[2];
  var regexString = "(^" + (Belt_Array.map(groups, (function (groupName) {
              return groupName.split(".").join("\\.").split("-").join("\\-");
            })).join("|") + "$)");
  var groupFilter = Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
        compareAttribute,
        /* RegularExpression */Block.__(4, [/* `String */[
              -976970511,
              regexString
            ]])
      ]);
  var txFilter = Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
        "_tx",
        /* AtMost */Block.__(9, [/* `ObjectID */[
              -589436806,
              action[1]
            ]])
      ]);
  var apertureForQuery = Caml_option.some(addFilter(addFilter(action[0], groupFilter), txFilter));
  var compareOverride = /* :: */[
    /* tuple */[
      "compare",
      Json_encode.object_(/* :: */[
            /* tuple */[
              "attribute",
              compareAttribute
            ],
            /* :: */[
              /* tuple */[
                "groups",
                Json_encode.array((function (prim) {
                        return prim;
                      }), groups)
              ],
              /* [] */0
            ]
          ])
    ],
    /* [] */0
  ];
  return {
          apertureForQuery: apertureForQuery,
          compareOverride: compareOverride
        };
}

function getApertureForQueries(aperture, compareAttribute, groups, tx) {
  var match = React.useReducer(reducer$1, {
        apertureForQuery: undefined,
        compareOverride: undefined
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var retainedAperture = BacktraceHooks$BsConsole.getRetainedAperture(aperture);
  React.useEffect((function () {
          if (groups !== undefined && groups.length !== 0 && tx !== undefined) {
            Curry._1(dispatch, /* SetApertureAndOverrides */[
                  aperture,
                  tx,
                  compareAttribute,
                  groups
                ]);
          }
          
        }), /* tuple */[
        groups,
        tx,
        retainedAperture
      ]);
  return /* tuple */[
          match$1.apertureForQuery,
          match$1.compareOverride
        ];
}

function getReleaseGroupRows(token, projectName, compareAttribute, aperture) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var match$1 = React.useState((function () {
          return makeSimpleQuery(aperture, compareAttribute);
        }));
  var setQuery = match$1[1];
  var query = match$1[0];
  React.useEffect((function () {
          var nextQuery = makeSimpleQuery(aperture, compareAttribute);
          Curry._1(setQuery, (function (param) {
                  return nextQuery;
                }));
          Curry._1(setRemote, (function (param) {
                  return /* Loading */1;
                }));
          
        }), [compareAttribute]);
  React.useEffect((function () {
          var apertureFromQuery = Curry._1(Crdb$BsConsole.Query.getAperture, query);
          if (!Curry._2(Crdb$BsConsole.Aperture.eq, aperture, apertureFromQuery)) {
            var nextQuery = makeSimpleQuery(aperture, compareAttribute);
            Curry._1(setQuery, (function (param) {
                    return nextQuery;
                  }));
            Curry._1(setRemote, (function (param) {
                    return /* Loading */1;
                  }));
          }
          
        }), [aperture]);
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              var onSuccess = function (resp) {
                return Curry._1(setRemote, (function (param) {
                              return /* Success */Block.__(0, [resp]);
                            }));
              };
              var onFailure = function (errorMessage) {
                return Curry._1(setRemote, (function (param) {
                              return /* Failure */Block.__(1, [errorMessage]);
                            }));
              };
              var arg = QueryPaginationTask$BsConsole.CrdbAggregate.makeTask(query, projectName, undefined, 0);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return QueryPaginationTask$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

function CompareReleases$View(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var groups = Props.groups;
  var config = Props.config;
  var serviceEndpoint = Props.serviceEndpoint;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleReplaceUrl = Props.handleReplaceUrl;
  var handleAddFilters = Props.handleAddFilters;
  var compareAttribute = Props.compareAttribute;
  var currentProject = Props.currentProject;
  var attributes = Props.attributes;
  var projectFrontendSettings = Props.projectFrontendSettings;
  var match = React.useState((function () {
          return false;
        }));
  var setShowUnitySDKCTA = match[1];
  var showUnitySDKCTA = match[0];
  React.useEffect((function () {
          Curry._1(setShowUnitySDKCTA, (function (param) {
                  return false;
                }));
          
        }), [projectName]);
  var checkForBacktraceUnitySDKErrors = function (error) {
    if (error === undefined) {
      return ;
    }
    switch (error) {
      case "No metric group found" :
      case "No unique_aggregation object found" :
          break;
      default:
        return ;
    }
    if (showUnitySDKCTA === false && IdentityVariant$BsConsole.current === /* Unity */2) {
      return Curry._1(setShowUnitySDKCTA, (function (param) {
                    return true;
                  }));
    }
    
  };
  var frozenAperture = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var releaseGroupsRemote = getReleaseGroupRows(token, projectName, compareAttribute, frozenAperture);
  var tx;
  tx = typeof releaseGroupsRemote === "number" || releaseGroupsRemote.tag ? undefined : releaseGroupsRemote[0][2].tx;
  React.useEffect((function () {
          var exit = 0;
          var rows;
          if (typeof releaseGroupsRemote !== "number" && !releaseGroupsRemote.tag) {
            var rows$1 = releaseGroupsRemote[0][1];
            if (!(groups !== undefined && groups.length !== 0)) {
              rows = rows$1;
              exit = 1;
            }
            
          }
          if (exit === 1) {
            var groups$1 = Belt_Array.slice(Belt_List.toArray(Belt_List.keepMap(rows, (function (row) {
                            var group = row.factor.value;
                            if (group === "*") {
                              return ;
                            } else {
                              return group;
                            }
                          }))), 0, 4);
            Curry._1(handleReplaceUrl, /* ProjectCompareReleases */Block.__(9, [
                    projectName,
                    compareAttribute,
                    {
                      aperture: aperture,
                      groups: groups$1
                    }
                  ]));
          }
          
        }), [releaseGroupsRemote]);
  var match$1 = getApertureForQueries(frozenAperture, compareAttribute, groups, tx);
  var compareOverride = match$1[1];
  var apertureForQueries = match$1[0];
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                  projectName,
                  compareAttribute,
                  {
                    aperture: aperture$prime,
                    groups: undefined
                  }
                ]));
  };
  if (typeof releaseGroupsRemote === "number") {
    return null;
  }
  if (releaseGroupsRemote.tag) {
    return null;
  }
  var match$2 = releaseGroupsRemote[0];
  var rows = match$2[1];
  if (Belt_List.length(rows) === 0) {
    return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.rem(1)),
                          /* :: */[
                            Css.marginLeft(Css.rem(1)),
                            /* [] */0
                          ]
                        ]),
                    children: React.createElement(CompareReleases$CompareAttributeSelect, {
                          token: token,
                          config: config,
                          attributes: attributes,
                          currentProject: currentProject,
                          compareAttribute: compareAttribute,
                          setCompareAttribute: (function (attribute) {
                              return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                                            projectName,
                                            attribute,
                                            {
                                              aperture: aperture,
                                              groups: undefined
                                            }
                                          ]));
                            }),
                          projectFrontendSettings: projectFrontendSettings
                        })
                  }), React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey2)),
                          /* :: */[
                            Css.padding(Css.rem(2)),
                            /* [] */0
                          ]
                        ]),
                    children: React.createElement(Col2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(33)),
                                /* :: */[
                                  Css.minWidth(Css.px(450)),
                                  /* [] */0
                                ]
                              ]),
                          children: Caml_int64.gt(match$2[2].tx, Caml_int64.one) ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Heading3 */2,
                                      children: Text$BsConsole.get(/* CompareReleases_Empty_Title */0)
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: Text$BsConsole.get(/* CompareReleases_Empty_Description */1)
                                    })) : React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Heading3 */2,
                                      children: Text$BsConsole.get(/* Welcome */8)
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: Text$BsConsole.get(/* No_Crashes_Uploaded */9)
                                    }), React.createElement(Row2$BsConsole.make, {
                                      className: Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* :: */[
                                              Css.marginTop(Css.rem(0.5)),
                                              /* [] */0
                                            ]
                                          ]),
                                      children: React.createElement(Link$BsConsole.Jsx3.make, {
                                            route: /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                projectName,
                                                {
                                                  doc: undefined
                                                }
                                              ]),
                                            handleChangeUrl: handleChangeUrl,
                                            children: React.createElement(Button.default, {
                                                  variant: "raised",
                                                  color: "primary",
                                                  onClick: (function (param) {
                                                      
                                                    }),
                                                  children: I18N$BsConsole.show(undefined, "Get started")
                                                })
                                          })
                                    }))
                        })
                  }));
  } else if (apertureForQueries !== undefined && compareOverride !== undefined) {
    return React.createElement(React.Fragment, undefined, showUnitySDKCTA ? React.createElement(UnitySdkCTA$BsConsole.make, {
                      className: cta
                    }) : null, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.rem(1)),
                          /* :: */[
                            Css.marginLeft(Css.rem(1)),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                          attribute: "error.type",
                          label: "Error type",
                          aperture: aperture,
                          token: token,
                          projectName: currentProject.name,
                          handleSetAperture: handleSetAperture,
                          className: Css.style(/* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]),
                          icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                                color: "inherit",
                                fontSize: "inherit"
                              }),
                          view: "compare_releases"
                        }) : null, React.createElement(CompareReleases$CompareAttributeSelect, {
                        token: token,
                        config: config,
                        attributes: attributes,
                        currentProject: currentProject,
                        compareAttribute: compareAttribute,
                        setCompareAttribute: (function (attribute) {
                            return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                                          projectName,
                                          attribute,
                                          {
                                            aperture: aperture,
                                            groups: undefined
                                          }
                                        ]));
                          }),
                        projectFrontendSettings: projectFrontendSettings
                      }), React.createElement(CompareReleases$GroupSelect, {
                        availableGroups: Belt_List.toArray(rows),
                        groups: Belt_Option.getWithDefault(groups, []),
                        setSelectedGroups: (function (groups) {
                            return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                                          projectName,
                                          compareAttribute,
                                          {
                                            aperture: aperture,
                                            groups: groups
                                          }
                                        ]));
                          })
                      })), React.createElement(DashboardWidgetGrid$BsConsole.make, {
                    aperture: Caml_option.valFromOption(apertureForQueries),
                    token: token,
                    projectName: projectName,
                    config: config,
                    serviceEndpoint: serviceEndpoint,
                    handleChangeUrl: handleChangeUrl,
                    handleAddFilters: handleAddFilters,
                    attributes: /* [] */0,
                    updateProjectFrontendSettingsRemote: (function (param) {
                        
                      }),
                    dashboard: "releases",
                    versionAttribute: compareAttribute,
                    overrides: compareOverride,
                    bubbleUpError: checkForBacktraceUnitySDKErrors
                  }));
  } else {
    return null;
  }
}

var View = {
  make: CompareReleases$View
};

function CompareReleases(Props) {
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var groups = Props.groups;
  var token = Props.token;
  var config = Props.config;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleReplaceUrl = Props.handleReplaceUrl;
  var handleAddFilters = Props.handleAddFilters;
  var compareAttribute = Props.compareAttribute;
  var serviceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "overview");
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var match$1 = BacktraceHooks$BsConsole.getProjectFrontendSettings(token, config, projectName);
  var projectFrontendSettings = match$1[0];
  var currentProject = Configuration$BsConsole.getProject(projectName, config);
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  if (serviceEndpoint !== undefined && compareAttribute !== undefined) {
    if (typeof describeRemote === "number") {
      exit$2 = 3;
    } else if (describeRemote.tag || !(currentProject !== undefined && typeof projectFrontendSettings !== "number")) {
      exit$1 = 2;
    } else if (projectFrontendSettings.tag) {
      exit = 1;
    } else {
      tmp = React.createElement(CompareReleases$View, {
            aperture: aperture,
            token: token,
            projectName: projectName,
            groups: groups,
            config: config,
            serviceEndpoint: serviceEndpoint,
            handleChangeUrl: handleChangeUrl,
            handleReplaceUrl: handleReplaceUrl,
            handleAddFilters: handleAddFilters,
            compareAttribute: compareAttribute,
            currentProject: currentProject,
            attributes: describeRemote[0],
            projectFrontendSettings: projectFrontendSettings[0]
          });
    }
  } else {
    exit$3 = 4;
  }
  if (exit$3 === 4) {
    if (compareAttribute !== undefined || typeof describeRemote === "number") {
      exit$2 = 3;
    } else if (describeRemote.tag || !(currentProject !== undefined && typeof projectFrontendSettings !== "number")) {
      exit$1 = 2;
    } else if (projectFrontendSettings.tag) {
      exit = 1;
    } else {
      tmp = React.createElement(CompareReleases$VersionSelect, {
            projectName: projectName,
            token: token,
            handleChangeUrl: handleChangeUrl,
            handleReplaceUrl: handleReplaceUrl,
            aperture: aperture,
            attributes: describeRemote[0],
            currentProject: currentProject,
            projectFrontendSettings: projectFrontendSettings[0]
          });
    }
  }
  if (exit$2 === 3) {
    if (typeof describeRemote === "number") {
      tmp = React.createElement(ViewLoading$BsConsole.Jsx3.make, {
            label: "Fetching releases data"
          });
    } else {
      exit$1 = 2;
    }
  }
  if (exit$1 === 2) {
    if (typeof projectFrontendSettings === "number") {
      tmp = React.createElement(ViewLoading$BsConsole.Jsx3.make, {
            label: "Fetching releases data"
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(2)),
                /* [] */0
              ]),
          children: React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                children: I18N$BsConsole.show(undefined, "Overview service is currently not available on this instance. Please contact Backtrace support for further assistance.")
              })
        });
  }
  return React.createElement(Styles.MuiThemeProvider, {
              theme: BtTheme$BsConsole.theme,
              children: React.createElement(DrilldownContext$BsConsole.Provider.make, {
                    handleChangeUrl: handleChangeUrl,
                    handleAddFilters: handleAddFilters,
                    children: null
                  }, Gate$BsConsole.isAlpha(undefined) ? React.createElement(Row2$BsConsole.make, {
                          children: React.createElement(Button.default, {
                                color: "primary",
                                className: Css.style(/* :: */[
                                      Css.marginTop(Css.px(16)),
                                      /* :: */[
                                        Css.marginLeft(Css.px(16)),
                                        /* [] */0
                                      ]
                                    ]),
                                onClick: (function (param) {
                                    return Curry._1(handleChangeUrl, /* ProjectOverview */Block.__(8, [
                                                  projectName,
                                                  {
                                                    aperture: aperture
                                                  }
                                                ]));
                                  }),
                                children: null
                              }, React.createElement(KeyboardBackspace.default, {
                                    className: Css.style(/* :: */[
                                          Css.marginRight(Css.px(5)),
                                          /* [] */0
                                        ])
                                  }), I18N$BsConsole.show(undefined, "Back to overview"))
                        }) : null, React.createElement(Paper.default, {
                        classes: {
                          root: paper
                        },
                        children: null
                      }, React.createElement(ViewNav$BsConsole.make, {
                            config: config,
                            aperture: aperture,
                            route: route,
                            handleChangeUrl: handleChangeUrl,
                            projectName: projectName
                          }), tmp))
            });
}

function make(aperture, projectName, groups, token, config, route, handleChangeUrl, handleReplaceUrl, handleAddFilters, compareAttribute, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(CompareReleases, {
              aperture: aperture,
              projectName: projectName,
              groups: groups,
              token: token,
              config: config,
              route: route,
              handleChangeUrl: handleChangeUrl,
              handleReplaceUrl: handleReplaceUrl,
              handleAddFilters: handleAddFilters,
              compareAttribute: compareAttribute
            }, _children);
}

var Re = {
  make: make
};

var groupLimit = 4;

var make$1 = CompareReleases;

exports.groupLimit = groupLimit;
exports.Style = Style;
exports.getVersionAttribute = getVersionAttribute;
exports.VersionSelect = VersionSelect;
exports.CompareAttributeSelect = CompareAttributeSelect;
exports.GroupSelect = GroupSelect;
exports.addFilter = addFilter;
exports.makeSimpleQuery = makeSimpleQuery;
exports.reducer = reducer$1;
exports.getApertureForQueries = getApertureForQueries;
exports.getReleaseGroupRows = getReleaseGroupRows;
exports.View = View;
exports.make = make$1;
exports.Re = Re;
/* paper Not a pure module */
