'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = Axis;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _shape = require('@vx/shape');

var _point = require('@vx/point');

var _group = require('@vx/group');

var _text = require('@vx/text');

var _center = require('../utils/center');

var _center2 = _interopRequireDefault(_center);

var _identity = require('../utils/identity');

var _identity2 = _interopRequireDefault(_identity);

var _labelTransform = require('../utils/labelTransform');

var _labelTransform2 = _interopRequireDefault(_labelTransform);

var _orientation = require('../constants/orientation');

var _orientation2 = _interopRequireDefault(_orientation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  axisClassName: _propTypes2.default.string,
  axisLineClassName: _propTypes2.default.string,
  hideAxisLine: _propTypes2.default.bool,
  hideTicks: _propTypes2.default.bool,
  hideZero: _propTypes2.default.bool,
  label: _propTypes2.default.string,
  labelClassName: _propTypes2.default.string,
  labelOffset: _propTypes2.default.number,
  labelProps: _propTypes2.default.object,
  left: _propTypes2.default.number,
  numTicks: _propTypes2.default.number,
  orientation: _propTypes2.default.oneOf([_orientation2.default.top, _orientation2.default.right, _orientation2.default.bottom, _orientation2.default.left]),
  rangePadding: _propTypes2.default.number,
  scale: _propTypes2.default.func.isRequired,
  stroke: _propTypes2.default.string,
  strokeWidth: _propTypes2.default.number,
  strokeDasharray: _propTypes2.default.string,
  tickClassName: _propTypes2.default.string,
  tickFormat: _propTypes2.default.func,
  tickLabelProps: _propTypes2.default.func,
  tickLength: _propTypes2.default.number,
  tickStroke: _propTypes2.default.string,
  tickTransform: _propTypes2.default.string,
  tickValues: _propTypes2.default.array,
  tickComponent: _propTypes2.default.func,
  top: _propTypes2.default.number,
  children: _propTypes2.default.func
};

function Axis(_ref) {
  var children = _ref.children,
      axisClassName = _ref.axisClassName,
      axisLineClassName = _ref.axisLineClassName,
      _ref$hideAxisLine = _ref.hideAxisLine,
      hideAxisLine = _ref$hideAxisLine === undefined ? false : _ref$hideAxisLine,
      _ref$hideTicks = _ref.hideTicks,
      hideTicks = _ref$hideTicks === undefined ? false : _ref$hideTicks,
      _ref$hideZero = _ref.hideZero,
      hideZero = _ref$hideZero === undefined ? false : _ref$hideZero,
      _ref$label = _ref.label,
      label = _ref$label === undefined ? '' : _ref$label,
      labelClassName = _ref.labelClassName,
      _ref$labelOffset = _ref.labelOffset,
      labelOffset = _ref$labelOffset === undefined ? 14 : _ref$labelOffset,
      _ref$labelProps = _ref.labelProps,
      labelProps = _ref$labelProps === undefined ? {
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fontSize: 10,
    fill: 'black'
  } : _ref$labelProps,
      _ref$left = _ref.left,
      left = _ref$left === undefined ? 0 : _ref$left,
      _ref$numTicks = _ref.numTicks,
      numTicks = _ref$numTicks === undefined ? 10 : _ref$numTicks,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === undefined ? _orientation2.default.bottom : _ref$orientation,
      _ref$rangePadding = _ref.rangePadding,
      rangePadding = _ref$rangePadding === undefined ? 0 : _ref$rangePadding,
      scale = _ref.scale,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === undefined ? 'black' : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === undefined ? 1 : _ref$strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      tickClassName = _ref.tickClassName,
      tickFormat = _ref.tickFormat,
      _ref$tickLabelProps = _ref.tickLabelProps,
      tickLabelProps = _ref$tickLabelProps === undefined ? function (tickValue, index) {
    return {
      textAnchor: 'middle',
      fontFamily: 'Arial',
      fontSize: 10,
      fill: 'black'
    };
  } : _ref$tickLabelProps,
      _ref$tickLength = _ref.tickLength,
      tickLength = _ref$tickLength === undefined ? 8 : _ref$tickLength,
      _ref$tickStroke = _ref.tickStroke,
      tickStroke = _ref$tickStroke === undefined ? 'black' : _ref$tickStroke,
      tickTransform = _ref.tickTransform,
      tickValues = _ref.tickValues,
      tickComponent = _ref.tickComponent,
      _ref$top = _ref.top,
      top = _ref$top === undefined ? 0 : _ref$top;

  var values = scale.ticks ? scale.ticks(numTicks) : scale.domain();
  if (tickValues) values = tickValues;
  var format = scale.tickFormat ? scale.tickFormat() : _identity2.default;
  if (tickFormat) format = tickFormat;

  var range = scale.range();
  var range0 = range[0] + 0.5 - rangePadding;
  var range1 = range[range.length - 1] + 0.5 + rangePadding;

  var horizontal = orientation !== _orientation2.default.left && orientation !== _orientation2.default.right;
  var isLeft = orientation === _orientation2.default.left;
  var isTop = orientation === _orientation2.default.top;
  var tickSign = isLeft || isTop ? -1 : 1;

  var position = (scale.bandwidth ? _center2.default : _identity2.default)(scale.copy());

  var axisFromPoint = new _point.Point({
    x: horizontal ? range0 : 0,
    y: horizontal ? 0 : range0
  });
  var axisToPoint = new _point.Point({
    x: horizontal ? range1 : 0,
    y: horizontal ? 0 : range1
  });

  var tickLabelFontSize = 10; // track the max tick label size to compute label offset

  if (!!children) {
    return _react2.default.createElement(
      _group.Group,
      { className: (0, _classnames2.default)('vx-axis', axisClassName), top: top, left: left },
      children({
        axisFromPoint: axisFromPoint,
        axisToPoint: axisToPoint,
        horizontal: horizontal,
        tickSign: tickSign,
        numTicks: numTicks,
        label: label,
        rangePadding: rangePadding,
        tickLength: tickLength,
        tickFormat: format,
        tickPosition: position,
        ticks: values.map(function (value, index) {
          var from = new _point.Point({
            x: horizontal ? position(value) : 0,
            y: horizontal ? 0 : position(value)
          });
          var to = new _point.Point({
            x: horizontal ? position(value) : tickSign * tickLength,
            y: horizontal ? tickLength * tickSign : position(value)
          });
          return {
            value: value,
            index: index,
            from: from,
            to: to,
            formattedValue: format(value, index)
          };
        })
      })
    );
  }

  return _react2.default.createElement(
    _group.Group,
    { className: (0, _classnames2.default)('vx-axis', axisClassName), top: top, left: left },
    values.map(function (val, index) {
      if (hideZero && val === 0) return null;

      var tickFromPoint = new _point.Point({
        x: horizontal ? position(val) : 0,
        y: horizontal ? 0 : position(val)
      });
      var tickToPoint = new _point.Point({
        x: horizontal ? position(val) : tickSign * tickLength,
        y: horizontal ? tickLength * tickSign : position(val)
      });

      var tickLabelPropsObj = tickLabelProps(val, index);
      tickLabelFontSize = Math.max(tickLabelFontSize, tickLabelPropsObj.fontSize || 0);

      return _react2.default.createElement(
        _group.Group,
        {
          key: 'vx-tick-' + val + '-' + index,
          className: (0, _classnames2.default)('vx-axis-tick', tickClassName),
          transform: tickTransform
        },
        !hideTicks && _react2.default.createElement(_shape.Line, { from: tickFromPoint, to: tickToPoint, stroke: tickStroke }),
        tickComponent ? tickComponent(_extends({
          x: tickToPoint.x,
          y: tickToPoint.y + (horizontal && !isTop ? tickLabelFontSize : 0),
          formattedValue: format(val, index)
        }, tickLabelPropsObj)) : _react2.default.createElement(
          _text.Text,
          _extends({
            x: tickToPoint.x,
            y: tickToPoint.y + (horizontal && !isTop ? tickLabelFontSize : 0)
          }, tickLabelPropsObj),
          format(val, index)
        )
      );
    }),
    !hideAxisLine && _react2.default.createElement(_shape.Line, {
      className: (0, _classnames2.default)('vx-axis-line', axisLineClassName),
      from: axisFromPoint,
      to: axisToPoint,
      stroke: stroke,
      strokeWidth: strokeWidth,
      strokeDasharray: strokeDasharray
    }),
    label && _react2.default.createElement(
      _text.Text,
      _extends({
        className: (0, _classnames2.default)('vx-axis-label', labelClassName)
      }, (0, _labelTransform2.default)({
        labelOffset: labelOffset,
        labelProps: labelProps,
        orientation: orientation,
        range: range,
        tickLabelFontSize: tickLabelFontSize,
        tickLength: tickLength
      }), labelProps),
      label
    )
  );
}

Axis.propTypes = propTypes;