// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var UnityColors$BsConsole = require("./UnityColors.js");
var Styles = require("@material-ui/core/styles");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

var defaultDivider = Colors$BsConsole.octothorpe(Colors$BsConsole.blackA12);

var defaultBackground = {
  paper: Colors$BsConsole.octothorpe(Colors$BsConsole.white),
  default: Colors$BsConsole.octothorpe(Colors$BsConsole.grey75)
};

var defaultAction = {
  active: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA54),
  hover: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA04),
  hoverOpacity: 0.04,
  selected: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA08),
  selectedOpacity: 0.08,
  disabled: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA26),
  disabledOpacity: 0.38,
  disabledBackground: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA12),
  focus: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA12),
  focusOpacity: 0.12,
  activatedOpacity: 0.12
};

var defaultPrimary = {
  dark: Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark),
  light: Colors$BsConsole.octothorpe(Colors$BsConsole.blueLight),
  main: Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
  contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
};

var defaultSecondary = {
  light: Colors$BsConsole.octothorpe(Colors$BsConsole.green),
  main: Colors$BsConsole.octothorpe(Colors$BsConsole.greenDark),
  dark: Colors$BsConsole.octothorpe(Colors$BsConsole.greenExtraDark),
  contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
};

var defaultError = {
  main: Colors$BsConsole.octothorpe(Colors$BsConsole.deleteRed),
  light: Colors$BsConsole.octothorpe(Colors$BsConsole.redLight),
  dark: Colors$BsConsole.octothorpe(Colors$BsConsole.deleteRedDark),
  contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.black)
};

var defaultGrey = {
  grey50: Colors$BsConsole.octothorpe(Colors$BsConsole.grey0),
  grey100: Colors$BsConsole.octothorpe(Colors$BsConsole.grey1),
  grey200: Colors$BsConsole.octothorpe(Colors$BsConsole.grey2),
  grey300: Colors$BsConsole.octothorpe(Colors$BsConsole.grey3),
  grey400: Colors$BsConsole.octothorpe(Colors$BsConsole.grey4),
  grey500: Colors$BsConsole.octothorpe(Colors$BsConsole.grey5),
  grey600: Colors$BsConsole.octothorpe(Colors$BsConsole.grey6),
  grey700: Colors$BsConsole.octothorpe(Colors$BsConsole.grey7),
  grey800: Colors$BsConsole.octothorpe(Colors$BsConsole.grey8),
  grey900: Colors$BsConsole.octothorpe(Colors$BsConsole.grey9)
};

var defaultDataViz = {
  dataVizPalette: Colors$BsConsole.qualitativeVizPalette,
  dataVizRest: Colors$BsConsole.qualitativeVizRest
};

var defaultCommon = {
  black: Colors$BsConsole.octothorpe(Colors$BsConsole.black),
  white: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
};

function makeTheme(primaryOpt, secondaryOpt, errorOpt, dataVisualizationOpt, greyOpt, commonOpt, backgroundOpt, param) {
  var primary = primaryOpt !== undefined ? Caml_option.valFromOption(primaryOpt) : defaultPrimary;
  var secondary = secondaryOpt !== undefined ? Caml_option.valFromOption(secondaryOpt) : defaultSecondary;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : defaultError;
  var dataVisualization = dataVisualizationOpt !== undefined ? Caml_option.valFromOption(dataVisualizationOpt) : defaultDataViz;
  var grey = greyOpt !== undefined ? Caml_option.valFromOption(greyOpt) : defaultGrey;
  var common = commonOpt !== undefined ? Caml_option.valFromOption(commonOpt) : defaultCommon;
  var background = backgroundOpt !== undefined ? Caml_option.valFromOption(backgroundOpt) : defaultBackground;
  return {
          palette: {
            primary: primary,
            secondary: secondary,
            error: error,
            grey: grey,
            common: common,
            background: background
          },
          dataVisualization: dataVisualization
        };
}

var defaultTheme = makeTheme(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var appThemes = {
  backtrace: defaultTheme,
  unity: makeTheme({
        main: Colors$BsConsole.octothorpe(UnityColors$BsConsole.primaryMain),
        light: Colors$BsConsole.octothorpe(UnityColors$BsConsole.primaryLight),
        dark: Colors$BsConsole.octothorpe(UnityColors$BsConsole.primaryDark),
        contrastText: Colors$BsConsole.octothorpe(UnityColors$BsConsole.primaryContrastText)
      }, undefined, {
        main: Colors$BsConsole.octothorpe(UnityColors$BsConsole.errorMain),
        light: Colors$BsConsole.octothorpe(UnityColors$BsConsole.errorLight),
        dark: Colors$BsConsole.octothorpe(UnityColors$BsConsole.errorDark),
        contrastText: Colors$BsConsole.octothorpe(UnityColors$BsConsole.errorContrastText)
      }, {
        dataVizPalette: UnityColors$BsConsole.dataVizPalette,
        dataVizRest: UnityColors$BsConsole.dataVizRest
      }, {
        grey50: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey50),
        grey100: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey100),
        grey200: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey200),
        grey300: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey300),
        grey400: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey400),
        grey500: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey500),
        grey600: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey600),
        grey700: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey700),
        grey800: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey800),
        grey900: Colors$BsConsole.octothorpe(UnityColors$BsConsole.grey900)
      }, undefined, {
        paper: Colors$BsConsole.octothorpe(UnityColors$BsConsole.white),
        default: Colors$BsConsole.octothorpe(UnityColors$BsConsole.backgroundDefault)
      }, undefined)
};

var context = React.createContext(undefined);

function getCompanyThemeObj(company) {
  return Caml_option.nullable_to_opt(company >= 2 ? appThemes.unity : appThemes.backtrace);
}

function getDataVisualization(company) {
  var companyTheme = getCompanyThemeObj(company);
  return Caml_option.nullable_to_opt(companyTheme !== undefined ? Caml_option.valFromOption(companyTheme).dataVisualization : null);
}

function getDataVizPalette(company) {
  var dataVisualization = getDataVisualization(company);
  return Caml_option.nullable_to_opt(dataVisualization !== undefined ? Caml_option.valFromOption(dataVisualization).dataVizPalette : null);
}

function getDataVizRest(company) {
  var dataVisualization = getDataVisualization(company);
  return Caml_option.nullable_to_opt(dataVisualization !== undefined ? Caml_option.valFromOption(dataVisualization).dataVizRest : null);
}

function getDefaultDataVizColor(company) {
  return Belt_Array.get(Belt_Option.getWithDefault(getDataVizPalette(company), []), 0);
}

function getPalette(company) {
  return Belt_Option.getWithDefault(getCompanyThemeObj(company), defaultTheme).palette;
}

function useThemePalette(param) {
  return getPalette(Belt_Option.getWithDefault(React.useContext(context), /* Backtrace */0));
}

function getHex(color) {
  return Css.hex(Colors$BsConsole.deoctothorpe(color));
}

function BtThemeContext$Consumer(Props) {
  var children = Props.children;
  return Curry._1(children, React.useContext(context));
}

var component = RR$BsConsole.statelessComponent("BtThemeContext-BsConsole");

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(BtThemeContext$Consumer, {
              children: children
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var Consumer = {
  make: BtThemeContext$Consumer,
  Jsx2: Jsx2
};

function getConsolePaletteTheme(param) {
  return IdentityVariant$BsConsole.current;
}

var provider = context.Provider;

function BtThemeContext$Provider(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return IdentityVariant$BsConsole.current;
        }));
  return React.createElement(provider, {
              value: match[0],
              children: children
            });
}

var component$1 = RR$BsConsole.statelessComponent("BtThemeContext-BsConsole");

function make$1(children) {
  return ReasonReactCompat.wrapReactForReasonReact(BtThemeContext$Provider, {
              children: children
            }, children);
}

var Jsx2$1 = {
  component: component$1,
  make: make$1
};

var Provider = {
  getConsolePaletteTheme: getConsolePaletteTheme,
  provider: provider,
  make: BtThemeContext$Provider,
  Jsx2: Jsx2$1
};

function BtThemeContext$MuiProvider(Props) {
  var children = Props.children;
  var palette = getPalette(Belt_Option.getWithDefault(React.useContext(context), /* Backtrace */0));
  var dontUseOnlyForMuiTheme = Styles.createMuiTheme({
        palette: palette,
        overrides: {
          MuiTooltip: {
            tooltip: {
              color: Colors$BsConsole.octothorpe(Colors$BsConsole.white),
              backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.grey35)
            }
          }
        }
      });
  return ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(dontUseOnlyForMuiTheme, [children]));
}

var component$2 = RR$BsConsole.statelessComponent("BtThemeContext-BsConsole");

function make$2(children) {
  return ReasonReactCompat.wrapReactForReasonReact(BtThemeContext$MuiProvider, {
              children: children
            }, children);
}

var Jsx2$2 = {
  component: component$2,
  make: make$2
};

var MuiProvider = {
  make: BtThemeContext$MuiProvider,
  Jsx2: Jsx2$2
};

exports.defaultDivider = defaultDivider;
exports.defaultBackground = defaultBackground;
exports.defaultAction = defaultAction;
exports.defaultPrimary = defaultPrimary;
exports.defaultSecondary = defaultSecondary;
exports.defaultError = defaultError;
exports.defaultGrey = defaultGrey;
exports.defaultDataViz = defaultDataViz;
exports.defaultCommon = defaultCommon;
exports.makeTheme = makeTheme;
exports.defaultTheme = defaultTheme;
exports.appThemes = appThemes;
exports.context = context;
exports.getCompanyThemeObj = getCompanyThemeObj;
exports.getDataVisualization = getDataVisualization;
exports.getDataVizPalette = getDataVizPalette;
exports.getDataVizRest = getDataVizRest;
exports.getDefaultDataVizColor = getDefaultDataVizColor;
exports.getPalette = getPalette;
exports.useThemePalette = useThemePalette;
exports.getHex = getHex;
exports.Consumer = Consumer;
exports.Provider = Provider;
exports.MuiProvider = MuiProvider;
/* defaultDivider Not a pure module */
