// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var BugTree$BsConsole = require("./BugTree.js");
var BugTreeNode$BsConsole = require("./BugTreeNode.js");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");

function BugVariableDetails$ValueRow(Props) {
  var label = Props.label;
  var value = Props.value;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding2(Css.px(4), Css.px(8)),
                    /* [] */0
                  ])
            }, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.fontWeight(Css.medium),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(label + ":")), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.paddingLeft(Css.px(4)),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(value)));
}

var ValueRow = {
  make: BugVariableDetails$ValueRow
};

function BugVariableDetails(Props) {
  var variable = Props.variable;
  var variableAnnotations = Props.variableAnnotations;
  var jsonAnnotation = Belt_Option.flatMap(variableAnnotations, (function (annotations) {
          return Caml_option.undefined_to_opt(annotations.find((function (ann) {
                            return ann.type === "JSON";
                          })));
        }));
  var jsonPPAnnotation = Belt_Option.flatMap(variableAnnotations, (function (annotations) {
          return Caml_option.undefined_to_opt(annotations.find((function (ann) {
                            return ann.type === "JSONPP";
                          })));
        }));
  var tmp;
  if (variable !== undefined) {
    var value = variable.value;
    var name = variable.name;
    var symbol = variable.symbol;
    var error = variable.error;
    var tmp$1;
    if (jsonAnnotation !== undefined) {
      var json = JSON.parse(jsonAnnotation.text);
      var tree = BugTreeNode$BsConsole.fromJson("Allocation information", undefined, undefined, json);
      tmp$1 = React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.paddingTop(Css.px(8)),
                  /* [] */0
                ])
          }, React.createElement(BugTree$BsConsole.make, {
                defaultExpanded: true,
                node: tree
              }));
    } else {
      tmp$1 = null;
    }
    var tmp$2;
    if (jsonPPAnnotation !== undefined) {
      var json$1 = JSON.parse(jsonPPAnnotation.text);
      var tree$1 = BugTreeNode$BsConsole.fromJson("Alternate representation", undefined, undefined, json$1);
      tmp$2 = React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.paddingTop(Css.px(8)),
                  /* [] */0
                ])
          }, React.createElement(BugTree$BsConsole.make, {
                defaultExpanded: true,
                node: tree$1
              }));
    } else {
      tmp$2 = null;
    }
    tmp = React.createElement(Col2$BsConsole.make, {
          className: Css.style(/* :: */[
                Css.padding2(Css.px(6), Css.zero),
                /* :: */[
                  Css.fontSize(Css.px(12)),
                  /* [] */0
                ]
              ]),
          children: null
        }, React.createElement(BugVariableDetails$ValueRow, {
              label: I18N$BsConsole.get(undefined, "Address"),
              value: variable.address
            }), React.createElement(BugVariableDetails$ValueRow, {
              label: I18N$BsConsole.get(undefined, "Type"),
              value: variable.type
            }), value !== undefined ? React.createElement(BugVariableDetails$ValueRow, {
                label: I18N$BsConsole.get(undefined, "Value"),
                value: value
              }) : null, name !== undefined ? React.createElement(BugVariableDetails$ValueRow, {
                label: I18N$BsConsole.get(undefined, "Name"),
                value: name
              }) : null, symbol !== undefined ? React.createElement(BugVariableDetails$ValueRow, {
                label: I18N$BsConsole.get(undefined, "Symbol"),
                value: symbol
              }) : null, error !== undefined ? React.createElement(BugVariableDetails$ValueRow, {
                label: I18N$BsConsole.get(undefined, "Error"),
                value: error
              }) : null, tmp$1, tmp$2);
  } else {
    tmp = null;
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BugSectionHeader$BsConsole.make, {
                  title: I18N$BsConsole.get(undefined, "Variable details")
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* :: */[
                            Css.overflowY(Css.auto),
                            /* [] */0
                          ]
                        ]
                      ])
                }, tmp));
}

var make = BugVariableDetails;

exports.ValueRow = ValueRow;
exports.make = make;
/* Css Not a pure module */
