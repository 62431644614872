// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Fragment$BsConsole = require("../../Fragment.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var SymbolTable$BsConsole = require("./SymbolTable.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.em(1)),
        /* [] */0
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var actionButtom = Css.style(/* :: */[
      Css.marginBottom(Css.em(0.5)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.padding2(Css.em(3), /* zero */-789508312),
          /* [] */0
        ]
      ]
    ]);

var callToActionButtonUpload = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.error)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.white)),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.marginTop(Css.px(24)),
      /* [] */0
    ]);

var Styles = {
  titleContainer: titleContainer,
  title: title,
  actionButtom: actionButtom,
  loadingContainer: loadingContainer,
  callToActionButtonUpload: callToActionButtonUpload,
  buttonContainer: buttonContainer
};

var component = RR$BsConsole.statelessComponent("PS_MissingSymbols-BsConsole");

function make(handleTask, token, handleChangeUrl, name, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Missing.make(handleTask, token, name, (function (remote, _update) {
                                          if (typeof remote === "number") {
                                            if (remote === /* NotAsked */0) {
                                              return null;
                                            } else {
                                              return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Missing symbols")])),
                                                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This page lists symbols that are required by crashes that have been submitted into the system. The columns below are First Appearance (the submission date of the first crash to require the symbol), Filename (a guess as to the name of the missing debug file) and Debug Identifier (the GUID of the missing debug file). Remember to upload the corresponding executable files for 64-bit Windows applications.")]))]))
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, []))]))
                                                            ]));
                                            }
                                          }
                                          if (remote.tag) {
                                            return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                            ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Missing symbols")])),
                                                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This page lists symbols that are required by crashes that have been submitted into the system. The columns below are First Appearance (the submission date of the first crash to require the symbol), Filename (a guess as to the name of the missing debug file) and Debug Identifier (the GUID of the missing debug file). Remember to upload the corresponding executable files for 64-bit Windows applications.")]))]))
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [I18N$BsConsole.showSkip(remote[0])]))]))
                                                          ]));
                                          }
                                          var symbols = remote[0];
                                          return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Missing symbols")])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This page lists symbols that are required by crashes that have been submitted into the system. The columns below are First Appearance (the submission date of the first crash to require the symbol), Filename (a guess as to the name of the missing debug file) and Debug Identifier (the GUID of the missing debug file). Remember to upload the corresponding executable files for 64-bit Windows applications.")])),
                                                                              Belt_List.length(symbols) > 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(buttonContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                    undefined,
                                                                                                    callToActionButtonUpload,
                                                                                                    (function (_event) {
                                                                                                        return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUpload */Block.__(37, [name]));
                                                                                                      }),
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    /* Raised */-387916264,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    [I18N$BsConsole.show(undefined, "Upload symbols")]
                                                                                                  ]))])) : null
                                                                            ]))
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(SymbolTable$BsConsole.Missing.Re.make, symbols, []))]))
                                                        ]));
                                        })))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* titleContainer Not a pure module */
