// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");

function getObjectJson(project, token, objectId, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/get?token=" + (token + ("&project=" + (project + ("&object=" + (objectId + "&resource=json.gz")))))]), undefined, /* Get */0, undefined, (function (json) {
                return /* Ok */Block.__(0, [json]);
              }), undefined, undefined);
}

function fromJson(json) {
  return {
          declaration: Json_decode.field("declaration", Json_decode.$$int, json),
          function_: Json_decode.field("function", Json_decode.string, json),
          line: Json_decode.field("line", Json_decode.$$int, json),
          object_: Json_decode.field("object", Json_decode.string, json),
          source: Json_decode.optional((function (param) {
                  return Json_decode.field("source", Json_decode.string, param);
                }), json),
          state: Json_decode.field("state", Json_decode.$$int, json)
        };
}

var TestedCallstack = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          callstack: Json_decode.field("callstack", (function (param) {
                  return Json_decode.array(fromJson, param);
                }), json)
        };
}

var TestedLog = {
  fromJson: fromJson$1
};

function testCallstack(universe, project, token, json, rulesetName, language, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/callstack?token=" + (token + ("&action=evaluate&project=" + (project + ("&universe=" + (universe + ("&name=" + (rulesetName + ("&fulljson=true" + Belt_Option.getWithDefault(Belt_Option.map(language, (function (l) {
                                            return "&language=" + l;
                                          })), "")))))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (param) {
                return /* Ok */Block.__(0, [param.json]);
              }), undefined, undefined);
}

exports.getObjectJson = getObjectJson;
exports.TestedCallstack = TestedCallstack;
exports.TestedLog = TestedLog;
exports.testCallstack = testCallstack;
/* Task2-BsConsole Not a pure module */
