// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../Task2.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");

function disqualifiedFromJson(json) {
  return {
          byThreshold: Json_decode.field("byThreshold", Json_decode.$$int, json),
          byIntersection: Json_decode.field("byIntersection", Json_decode.$$int, json),
          byDistance: Json_decode.field("byDistance", Json_decode.$$int, json)
        };
}

function metaFromJson(json) {
  return {
          disqualified: Json_decode.field("disqualified", disqualifiedFromJson, json),
          qualified: Json_decode.field("qualified", Json_decode.$$int, json)
        };
}

function candidateFromJson(json) {
  var parseTickets = function (jsonStr) {
    var json;
    try {
      json = Belt_Option.map(jsonStr, (function (prim) {
              return JSON.parse(prim);
            }));
    }
    catch (exn){
      json = undefined;
    }
    return Belt_Option.map(json, (function (j) {
                  return Json_decode.list(Issues$BsConsole.Ticket.ofJson, j);
                }));
  };
  return {
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          first_seen: Json_decode.field("first_seen", Json_decode.$$int, json),
          callstack: Json_decode.field("callstack", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          classifiers: Json_decode.field("classifiers", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          dates: Json_decode.field("dates", (function (param) {
                  return Json_decode.list(Json_decode.$$int, param);
                }), json),
          count: Json_decode.field("count", Json_decode.$$int, json),
          mainCallstackDiff: Json_decode.field("mainCallstackDiff", (function (param) {
                  return Json_decode.list((function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          candidateCallstackDiff: Json_decode.field("candidateCallstackDiff", (function (param) {
                  return Json_decode.list((function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          distance: Json_decode.field("distance", Json_decode.$$int, json),
          state: Json_decode.field("state", Json_decode.string, json),
          tickets: parseTickets(Json_decode.optional((function (param) {
                      return Json_decode.field("tickets", Json_decode.string, param);
                    }), json))
        };
}

function candidateRequestFromJson(json) {
  return {
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          callstack: Json_decode.field("callstack", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          meta: Json_decode.field("meta", metaFromJson, json),
          candidates: Json_decode.field("candidates", (function (param) {
                  return Json_decode.list(candidateFromJson, param);
                }), json)
        };
}

function candidacyFromJson(json) {
  return {
          status: Json_decode.field("status", Json_decode.string, json),
          results: Json_decode.field("results", (function (param) {
                  return Json_decode.list(candidateRequestFromJson, param);
                }), json)
        };
}

function candidacyToJson(threshold, truncate, intersection, distance, _t$prime) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                "distance"
              ],
              /* :: */[
                /* tuple */[
                  "threshold",
                  threshold
                ],
                /* :: */[
                  /* tuple */[
                    "truncate",
                    truncate
                  ],
                  /* :: */[
                    /* tuple */[
                      "intersection",
                      intersection ? intersection[0] : "dynamic"
                    ],
                    /* :: */[
                      /* tuple */[
                        "distance",
                        distance ? distance[0] : "dynamic"
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function datesToDateString(dates) {
  return Belt_List.toArray(Belt_List.map(dates, (function (date) {
                      var dateAsFloat = date * 1000;
                      return FormatUtils$BsConsole.formatDateToAgoString(new Date(dateAsFloat));
                    }))).join(" - ");
}

function fetchCandidates(path, project, fingerprint, limit, threshold, truncate, intersection, distance) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "project",
          project
        ],
        /* :: */[
          /* tuple */[
            "fingerprint",
            fingerprint
          ],
          /* :: */[
            /* tuple */[
              "limit",
              limit
            ],
            /* :: */[
              /* tuple */[
                "candidacy",
                Json_encode.array((function (param) {
                        return candidacyToJson(threshold, truncate, intersection, distance, param);
                      }), ["one"])
              ],
              /* [] */0
            ]
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + "/candidates")]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [/* tuple */[
                            respBag.status,
                            candidacyFromJson(respBag.json)
                          ]]);
              }), undefined, undefined);
}

exports.disqualifiedFromJson = disqualifiedFromJson;
exports.metaFromJson = metaFromJson;
exports.candidateFromJson = candidateFromJson;
exports.candidateRequestFromJson = candidateRequestFromJson;
exports.candidacyFromJson = candidacyFromJson;
exports.candidacyToJson = candidacyToJson;
exports.datesToDateString = datesToDateString;
exports.fetchCandidates = fetchCandidates;
/* Task2-BsConsole Not a pure module */
