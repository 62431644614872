// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/lib/js/src/Fetch.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var RR$BsConsole = require("./RR.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Belt_HashMapString = require("bs-platform/lib/js/belt_HashMapString.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var TokenContext$BsConsole = require("./context/TokenContext.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

function toBsFetch(param) {
  if (typeof param === "number") {
    switch (param) {
      case /* Get */0 :
          return /* Get */0;
      case /* Delete */1 :
          return /* Delete */4;
      case /* Head */2 :
          return /* Head */1;
      
    }
  } else {
    switch (param.tag | 0) {
      case /* DeleteBody */2 :
          return /* Delete */4;
      case /* Post */0 :
      case /* PostForm */3 :
          return /* Post */2;
      case /* Put */1 :
      case /* PutForm */4 :
          return /* Put */3;
      
    }
  }
}

function getBody(json) {
  if (typeof json === "number") {
    switch (json) {
      case /* Get */0 :
      case /* Delete */1 :
      case /* Head */2 :
          return ;
      
    }
  } else {
    switch (json.tag | 0) {
      case /* PostForm */3 :
      case /* PutForm */4 :
          return Caml_option.some(json[0]);
      default:
        return Caml_option.some(JSON.stringify(json[0]));
    }
  }
}

function toString(path) {
  if (path.tag) {
    return path[0];
  } else {
    return "/api" + path[0];
  }
}

var Path = {
  toString: toString
};

var TaskError = Caml_exceptions.create("Task2-BsConsole.TaskError");

var UnknownError = Caml_exceptions.create("Task2-BsConsole.UnknownError");

var UncaughtPromiseError = Caml_exceptions.create("Task2-BsConsole.UncaughtPromiseError");

var JsonDecodeError = Caml_exceptions.create("Task2-BsConsole.JsonDecodeError");

var ErrorMsg = Caml_exceptions.create("Task2-BsConsole.ErrorMsg");

function make(path, mode, meth, headersOpt, decodeResp, cacheKey, param) {
  var headers = headersOpt !== undefined ? headersOpt : [];
  return {
          cacheKey: cacheKey,
          path: path,
          mode: mode,
          meth: meth,
          headers: headers,
          decodeResp: decodeResp,
          decodeErr: (function (param) {
              return UnknownError;
            })
        };
}

function tasksEqual(t1, t2) {
  if (Caml_obj.caml_equal(t1.path, t2.path) && Caml_obj.caml_equal(t1.meth, t2.meth)) {
    return Caml_obj.caml_equal(t1.headers, t2.headers);
  } else {
    return false;
  }
}

var middleware = {
  contents: /* [] */0
};

function registerMiddleware(mw) {
  middleware.contents = /* :: */[
    mw,
    middleware.contents
  ];
  
}

var Httpd = Caml_exceptions.create("Task2-BsConsole.Httpd");

function decodeHttpdError(resp) {
  if (resp.tag) {
    return /* Error */Block.__(1, [resp[0]]);
  }
  var responseBag = resp[0];
  var error = Error$BsConsole.HTTPD.decode(responseBag.json);
  if (error !== undefined) {
    return /* Error */Block.__(1, [[
                Httpd,
                error
              ]]);
  } else {
    return /* Ok */Block.__(0, [responseBag]);
  }
}

registerMiddleware(decodeHttpdError);

var CoronerError = Caml_exceptions.create("Task2-BsConsole.CoronerError");

function decodeStandardError(resp) {
  if (resp.tag) {
    return /* Error */Block.__(1, [resp[0]]);
  }
  var responseBag = resp[0];
  try {
    var error = Json_decode.field("error", Util$BsConsole.identity, responseBag.json);
    var partial_arg_000 = function (json) {
      return /* `Int */[
              3654863,
              Json_decode.$$int(json)
            ];
    };
    var partial_arg_001 = /* :: */[
      (function (json) {
          return /* `String */[
                  -976970511,
                  Json_decode.string(json)
                ];
        }),
      /* [] */0
    ];
    var partial_arg = /* :: */[
      partial_arg_000,
      partial_arg_001
    ];
    var code = Json_decode.field("code", (function (param) {
            return Json_decode.oneOf(partial_arg, param);
          }), error);
    var message = Json_decode.field("message", Json_decode.string, error);
    return /* Error */Block.__(1, [[
                CoronerError,
                {
                  code: code,
                  message: message
                }
              ]]);
  }
  catch (exn){
    return /* Ok */Block.__(0, [responseBag]);
  }
}

registerMiddleware(decodeStandardError);

var invalidTokenHandler = {
  contents: undefined
};

function onInvalidToken(cb) {
  invalidTokenHandler.contents = cb;
  
}

function invalidTokenMiddleware(resp) {
  var cb = invalidTokenHandler.contents;
  if (cb === undefined) {
    return resp;
  }
  if (resp.tag) {
    return /* Error */Block.__(1, [resp[0]]);
  }
  var responseBag = resp[0];
  var match = Error$BsConsole.HTTPD.decode(responseBag.json);
  if (match === undefined) {
    return /* Ok */Block.__(0, [responseBag]);
  }
  var match$1 = match.code;
  if (match$1 !== undefined && typeof match$1 === "number" && match$1 === 5) {
    Curry._1(cb, undefined);
    return ;
  } else {
    return /* Ok */Block.__(0, [responseBag]);
  }
}

registerMiddleware(invalidTokenMiddleware);

function generateQueryString(params) {
  var queryString = {
    contents: ""
  };
  Belt_List.forEachWithIndex(params, (function (i, param) {
          queryString.contents = queryString.contents + ((
              i === 0 ? "?" : "&"
            ) + (param[0] + ("=" + param[1])));
          
        }));
  return queryString.contents;
}

function makeHeaders(token, meth, headers, param) {
  var tmp;
  if (typeof meth === "number") {
    tmp = [/* tuple */[
        "Content-Type",
        "application/json"
      ]];
  } else {
    switch (meth.tag | 0) {
      case /* PostForm */3 :
      case /* PutForm */4 :
          tmp = [];
          break;
      default:
        tmp = [/* tuple */[
            "Content-Type",
            "application/json"
          ]];
    }
  }
  var match = Belt_Array.getBy(headers, (function (param) {
          return param[0] === "X-Coroner-Location";
        }));
  return Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(tmp, Belt_Option.mapWithDefault(token, [], (function (token) {
                            return [/* tuple */[
                                      "X-Coroner-Token",
                                      token
                                    ]];
                          }))), match !== undefined ? [] : [/* tuple */[
                      "X-Coroner-Location",
                      location.origin
                    ]]), headers);
}

function makeRequest(token, meth, headers, mode, param) {
  var headers$1 = makeHeaders(token, meth, headers, undefined);
  var method__ = toBsFetch(meth);
  var body = getBody(meth);
  return Fetch.RequestInit.make(method__, Caml_option.some(headers$1), body, undefined, undefined, mode, /* SameOrigin */1, undefined, undefined, undefined, undefined)(undefined);
}

var cache = Belt_HashMapString.make(100);

function invalidateCache(key) {
  return Belt_HashMapString.remove(cache, key);
}

function baseHandleJson(token, skipCacheOpt, task, cb) {
  var skipCache = skipCacheOpt !== undefined ? skipCacheOpt : false;
  var url = toString(task.path);
  var cacheResp = Belt_Option.flatMap(task.cacheKey, (function (cacheKey) {
          return Belt_HashMapString.get(cache, cacheKey);
        }));
  if (!skipCache && cacheResp !== undefined) {
    return Curry._1(cb, /* Ok */Block.__(0, [cacheResp]));
  }
  var init = makeRequest(token, task.meth, task.headers, task.mode, undefined);
  var resp$prime = {
    contents: undefined
  };
  var partial_arg = task.meth;
  fetch(url, init).then((function (resp) {
                resp$prime.contents = Caml_option.some(resp);
                return Promise.resolve(resp);
              })).then((function (param) {
              var json = typeof partial_arg === "number" && partial_arg >= 2 ? Promise.resolve(null) : param.json();
              return Promise.all(/* tuple */[
                          json,
                          Promise.resolve(param)
                        ]);
            })).then((function (param) {
            var json = param[0];
            var resp = param[1];
            var responseBag_headers = resp.headers;
            var responseBag_ok = resp.ok;
            var responseBag_redirected = resp.redirected;
            var responseBag_status = resp.status;
            var responseBag_statusText = resp.statusText;
            var responseBag_type_ = resp._type;
            var responseBag_url = resp.url;
            var responseBag = {
              json: json,
              headers: responseBag_headers,
              ok: responseBag_ok,
              redirected: responseBag_redirected,
              status: responseBag_status,
              statusText: responseBag_statusText,
              type_: responseBag_type_,
              url: responseBag_url
            };
            var rez = Belt_List.reduce(middleware.contents, /* Ok */Block.__(0, [responseBag]), (function (acc, mw) {
                    return Belt_Option.flatMap(acc, Curry.__1(mw));
                  }));
            if (rez !== undefined) {
              var rez$1;
              try {
                rez$1 = Belt_Result.flatMap(rez, task.decodeResp);
              }
              catch (raw_msg){
                var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                rez$1 = msg[0] === Json_decode.DecodeError || msg[0] === Json_decode.DecodeError ? /* Error */Block.__(1, [[
                        JsonDecodeError,
                        msg[1]
                      ]]) : /* Error */Block.__(1, [UnknownError]);
              }
              var match = task.cacheKey;
              if (match !== undefined && !rez$1.tag) {
                Belt_HashMapString.set(cache, match, rez$1[0]);
              }
              Curry._1(cb, rez$1);
            }
            return Promise.resolve(undefined);
          })).catch((function (e) {
          Curry._1(cb, /* Error */Block.__(1, [[
                    UncaughtPromiseError,
                    /* tuple */[
                      e,
                      resp$prime.contents
                    ]
                  ]]));
          return Promise.resolve(undefined);
        }));
  
}

function raw(token, path, mode, meth, headersOpt, cb) {
  var headers = headersOpt !== undefined ? headersOpt : [];
  var url = toString(path);
  var init = makeRequest(token, meth, headers, mode, undefined);
  return fetch(url, init).then((function (resp) {
                  return Promise.all(/* tuple */[
                              resp.text(),
                              Promise.resolve(resp)
                            ]);
                })).then((function (param) {
                Curry._1(cb, param[0]);
                return Promise.resolve(undefined);
              }));
}

function handleUnauthorized(task, skipCache, cb) {
  return baseHandleJson(undefined, skipCache, task, cb);
}

function handle(token, task, skipCache, cb) {
  return baseHandleJson(token, skipCache, task, cb);
}

var component = RR$BsConsole.reducerComponent("Fetch");

function make$1(token, task, skipCache, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (param) {
              var state = param.state;
              if (tasksEqual(task, state.retainedTask)) {
                return state;
              } else {
                Curry._1(param.send, /* HandleTask */Block.__(1, [task]));
                return {
                        remote: /* Loading */1,
                        retainedTask: task
                      };
              }
            }),
          didMount: (function (param) {
              return Curry._1(param.send, /* HandleTask */Block.__(1, [task]));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              return Curry._2(children, param.state.remote, (function (param) {
                            return Curry._1(send, /* HandleTask */Block.__(1, [task]));
                          }));
            }),
          initialState: (function (param) {
              return {
                      remote: /* Loading */1,
                      retainedTask: task
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (!action.tag) {
                return /* Update */Block.__(0, [{
                            remote: action[0],
                            retainedTask: state.retainedTask
                          }]);
              }
              var task = action[0];
              return /* SideEffects */Block.__(1, [(function (param) {
                            var send = param.send;
                            var match = state.remote;
                            if (typeof match === "number" || match.tag) {
                              Curry._1(send, /* SetRemote */Block.__(0, [/* Loading */1]));
                            } else {
                              Curry._1(send, /* SetRemote */Block.__(0, [/* Success */Block.__(0, [/* tuple */[
                                            match[0][0],
                                            /* Loading */1
                                          ]])]));
                            }
                            return handle(token, task, skipCache, (function (cb) {
                                          if (!cb.tag) {
                                            return Curry._1(send, /* SetRemote */Block.__(0, [/* Success */Block.__(0, [/* tuple */[
                                                                cb[0],
                                                                /* NotAsked */0
                                                              ]])]));
                                          }
                                          var err = cb[0];
                                          var match = state.remote;
                                          if (typeof match === "number" || match.tag) {
                                            return Curry._1(send, /* SetRemote */Block.__(0, [/* Failure */Block.__(1, [err])]));
                                          } else {
                                            return Curry._1(send, /* SetRemote */Block.__(0, [/* Success */Block.__(0, [/* tuple */[
                                                                match[0][0],
                                                                /* Failure */Block.__(1, [err])
                                                              ]])]));
                                          }
                                        }));
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

function use(skipCache, task, param) {
  var token = React.useContext(TokenContext$BsConsole.ctx);
  var match = React.useState((function () {
          return /* Loading */1;
        }));
  var setValue = match[1];
  React.useEffect((function () {
          var cancelled = {
            contents: false
          };
          Curry._1(setValue, (function (value) {
                  if (typeof value === "number" || value.tag) {
                    return /* Loading */1;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                value[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          baseHandleJson(token, skipCache, task, (function (resp) {
                  if (cancelled.contents) {
                    return ;
                  }
                  if (resp.tag) {
                    var err = resp[0];
                    return Curry._1(setValue, (function (value) {
                                  if (typeof value === "number" || value.tag) {
                                    return /* Failure */Block.__(1, [err]);
                                  } else {
                                    return /* Success */Block.__(0, [/* tuple */[
                                                value[0][0],
                                                /* Failure */Block.__(1, [err])
                                              ]]);
                                  }
                                }));
                  }
                  var resp$1 = resp[0];
                  return Curry._1(setValue, (function (param) {
                                return /* Success */Block.__(0, [/* tuple */[
                                            resp$1,
                                            /* NotAsked */0
                                          ]]);
                              }));
                }));
          return (function (param) {
                    cancelled.contents = true;
                    
                  });
        }), /* tuple */[
        task,
        token
      ]);
  return match[0];
}

function useRefetchable(skipCache, task, param) {
  var token = React.useContext(TokenContext$BsConsole.ctx);
  var match = React.useState((function () {
          return 0;
        }));
  var setKey = match[1];
  var match$1 = React.useState((function () {
          return /* Loading */1;
        }));
  var setValue = match$1[1];
  React.useEffect((function () {
          var cancelled = {
            contents: false
          };
          Curry._1(setValue, (function (value) {
                  if (typeof value === "number" || value.tag) {
                    return /* Loading */1;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                value[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          baseHandleJson(token, skipCache, task, (function (resp) {
                  if (cancelled.contents) {
                    return ;
                  }
                  if (resp.tag) {
                    var err = resp[0];
                    return Curry._1(setValue, (function (value) {
                                  if (typeof value === "number" || value.tag) {
                                    return /* Failure */Block.__(1, [err]);
                                  } else {
                                    return /* Success */Block.__(0, [/* tuple */[
                                                value[0][0],
                                                /* Failure */Block.__(1, [err])
                                              ]]);
                                  }
                                }));
                  }
                  var resp$1 = resp[0];
                  return Curry._1(setValue, (function (param) {
                                return /* Success */Block.__(0, [/* tuple */[
                                            resp$1,
                                            /* NotAsked */0
                                          ]]);
                              }));
                }));
          return (function (param) {
                    cancelled.contents = true;
                    
                  });
        }), /* tuple */[
        task,
        token,
        match[0]
      ]);
  return /* tuple */[
          match$1[0],
          (function (param) {
              return Curry._1(setKey, (function (key) {
                            return key + 1 | 0;
                          }));
            })
        ];
}

function baseErrorRenderer(error) {
  if (error[0] !== CoronerError) {
    if (error[0] === JsonDecodeError) {
      return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Failed decoding json ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ]),
                      "Failed decoding json %s"
                    ]), Belt_Option.mapWithDefault(error[1], "", (function (err) {
                        return " (" + (err + ")");
                      })));
    } else if (error[0] === ErrorMsg) {
      return I18N$BsConsole.get(undefined, error[1]);
    } else {
      return ;
    }
  }
  var match = error[1];
  var code = match.code;
  var codeStr = code[0] >= 3654863 ? String(code[1]) : code[1];
  return I18N$BsConsole.get(undefined, "" + (String(match.message) + (" (code: " + (String(codeStr) + ")"))));
}

var errorRenderers = [baseErrorRenderer];

function registerErrorRenderer(renderer) {
  errorRenderers.push(renderer);
  
}

function renderError(defaultOpt, error) {
  var $$default = defaultOpt !== undefined ? defaultOpt : I18N$BsConsole.get(undefined, "Unknown error has occurred");
  return Belt_Option.getWithDefault(Belt_Array.reduce(errorRenderers, undefined, (function (message, renderer) {
                    if (message !== undefined) {
                      return message;
                    } else {
                      return Curry._1(renderer, error);
                    }
                  })), $$default);
}

function shouldntHappen(error) {
  var msg = "[ERROR] " + renderError(undefined, error);
  console.log(error);
  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, 8000, undefined, I18N$BsConsole.showSkip(msg));
}

var Meth = { };

var Fetch$1 = {
  make: make$1
};

exports.Meth = Meth;
exports.Path = Path;
exports.UnknownError = UnknownError;
exports.UncaughtPromiseError = UncaughtPromiseError;
exports.JsonDecodeError = JsonDecodeError;
exports.ErrorMsg = ErrorMsg;
exports.TaskError = TaskError;
exports.make = make;
exports.invalidateCache = invalidateCache;
exports.onInvalidToken = onInvalidToken;
exports.Httpd = Httpd;
exports.CoronerError = CoronerError;
exports.generateQueryString = generateQueryString;
exports.handleUnauthorized = handleUnauthorized;
exports.handle = handle;
exports.raw = raw;
exports.Fetch = Fetch$1;
exports.registerErrorRenderer = registerErrorRenderer;
exports.renderError = renderError;
exports.shouldntHappen = shouldntHappen;
exports.use = use;
exports.useRefetchable = useRefetchable;
/*  Not a pure module */
