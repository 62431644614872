// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Current$BsConsole = require("./Current.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var FormControl = require("@material-ui/core/FormControl");
var ListItemText = require("@material-ui/core/ListItemText");
var ViewCompact = require("@material-ui/icons/ViewCompact");

var label = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* [] */0
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var chip = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(8)),
      /* :: */[
        Css.borderRadius(Css.px(20)),
        /* :: */[
          Css.fontSize(Css.px(10)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  400
                ]),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey3)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var modeChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.grey675)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var projectDefaultChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.orange, 0.5))),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var myProjectDefaultChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.green, 0.3))),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemRoot = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
            /* [] */0
          ]),
      /* :: */[
        Css.paddingLeft(Css.px(32)),
        /* :: */[
          Css.paddingRight(Css.px(32)),
          /* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.unsafe("height", "unset"),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var openDrawerItem = Css.merge(/* :: */[
      menuItemRoot,
      /* :: */[
        Css.style(/* :: */[
              Css.boxShadow(undefined, Css.px(1), undefined, undefined, true, Css.hex(Colors$BsConsole.grey675)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var savedQueryName = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* [] */0
        ]
      ]
    ]);

var menuItemClasses = {
  root: menuItemRoot,
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
        /* :: */[
          Css.opacity(1),
          /* :: */[
            Css.before(/* :: */[
                  Css.fontFamily("'Material Icons'"),
                  /* :: */[
                    Css.unsafe("content", "'check'"),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.accent)),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.left(Css.px(8)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ])
};

var Style = {
  label: label,
  select: select,
  loading: loading,
  chip: chip,
  modeChip: modeChip,
  projectDefaultChip: projectDefaultChip,
  myProjectDefaultChip: myProjectDefaultChip,
  menuItemRoot: menuItemRoot,
  openDrawerItem: openDrawerItem,
  savedQueryName: savedQueryName,
  menuItemClasses: menuItemClasses
};

function SavedQueryBookmarkSelect$SavedQueryMenuItem(Props) {
  var savedQuery = Props.savedQuery;
  var userDefault = Props.userDefault;
  var projectDefault = Props.projectDefault;
  var name = SavedQueriesModel$BsConsole.getName(savedQuery);
  var author = SavedQueriesModel$BsConsole.getUsername(savedQuery);
  var tmp;
  var exit = 0;
  if (userDefault !== undefined && SavedQueriesModel$BsConsole.getName(userDefault) === name) {
    tmp = React.createElement("div", {
          className: Css.merge(/* :: */[
                myProjectDefaultChip,
                /* :: */[
                  Css.style(/* :: */[
                        Css.marginLeft(Css.px(4)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ])
        }, I18N$BsConsole.show(undefined, "My project default"));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = projectDefault !== undefined && SavedQueriesModel$BsConsole.getName(projectDefault) === name ? React.createElement("div", {
            className: Css.merge(/* :: */[
                  projectDefaultChip,
                  /* :: */[
                    Css.style(/* :: */[
                          Css.marginLeft(Css.px(4)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ])
          }, I18N$BsConsole.show(undefined, "Project default")) : null;
  }
  var primary = React.createElement(Row2$BsConsole.make, {
        alignItems: /* center */98248149,
        justifyContent: /* spaceBetween */516682146,
        flexWrap: /* nowrap */867913355,
        className: Css.style(/* :: */[
              Css.width(Css.pct(100)),
              /* [] */0
            ]),
        children: null
      }, React.createElement("div", {
            className: savedQueryName
          }, I18N$BsConsole.showSkip(name)), React.createElement(Row2$BsConsole.make, {
            alignItems: /* center */98248149,
            children: null
          }, React.createElement("div", {
                className: Css.merge(/* :: */[
                      modeChip,
                      /* :: */[
                        Css.style(/* :: */[
                              Css.marginLeft(Css.px(8)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ])
              }, I18N$BsConsole.show(undefined, SavedQueriesModel$BsConsole.modeToFriendlyString(SavedQueriesModel$BsConsole.getMode(savedQuery)))), tmp));
  var secondary = Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
            /* String_literal */Block.__(11, [
                "By ",
                /* String */Block.__(2, [
                    /* No_padding */0,
                    /* End_of_format */0
                  ])
              ]),
            "By %s"
          ]), author);
  return React.createElement(ListItemText.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.padding(/* zero */-789508312),
                      /* [] */0
                    ])
              },
              primary: primary,
              secondary: secondary
            });
}

var SavedQueryMenuItem = {
  make: SavedQueryBookmarkSelect$SavedQueryMenuItem
};

function SavedQueryBookmarkSelect(Props) {
  var projectBookmarkedViews = Props.projectBookmarkedViews;
  var userBookmarkedViews = Props.userBookmarkedViews;
  var projectDefault = Props.projectDefault;
  var userDefault = Props.userDefault;
  var queries = Props.queries;
  var handleOpen = Props.handleOpen;
  var handleChangeUrl = Props.handleChangeUrl;
  var currentMode = Props.currentMode;
  var projectName = Props.projectName;
  var query = Props.query;
  var bookmarkedSavedQueries = React.useMemo((function () {
          return Belt_List.map(Belt_List.keep(Belt_Option.getWithDefault(queries, /* [] */0), (function (sq) {
                            if (userBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(sq))) {
                              return true;
                            } else {
                              return projectBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(sq));
                            }
                          })), (function (sq) {
                        return sq;
                      }));
        }), /* tuple */[
        projectBookmarkedViews,
        queries
      ]);
  var currentSavedView = React.useMemo((function () {
          if (query === undefined) {
            return ;
          }
          if (queries === undefined) {
            return ;
          }
          var sq = Current$BsConsole.get(currentMode, query, queries);
          if (sq) {
            return sq[0];
          }
          
        }), /* tuple */[
        query,
        queries
      ]);
  var value = Belt_Option.getWithDefault(Belt_Option.map(currentSavedView, SavedQueriesModel$BsConsole.getName), "Choose bookmarked view");
  var options = React.useMemo((function () {
          return Belt_Array.keep(Belt_Array.map(Js_dict.entries(Belt_List.reduce(Belt_List.keepMap(Belt_List.concatMany([
                                          /* :: */[
                                            userDefault,
                                            /* [] */0
                                          ],
                                          /* :: */[
                                            projectDefault,
                                            /* [] */0
                                          ],
                                          bookmarkedSavedQueries
                                        ]), (function (sq) {
                                        return sq;
                                      })), { }, (function (acc, current) {
                                    acc[SavedQueriesModel$BsConsole.getName(current)] = current;
                                    return acc;
                                  }))), (function (param) {
                            return param[1];
                          })), (function (sq) {
                        return SavedQueriesModel$BsConsole.getName(sq) !== value;
                      }));
        }), /* tuple */[
        userDefault,
        projectDefault,
        bookmarkedSavedQueries,
        value
      ]);
  var handleOnChange = function ($$event, _jsEvent) {
    var value = $$event.target.value;
    if (value === "drawer") {
      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* BookmarkedViewSelectOpenManager */119]));
      return Curry._1(handleOpen, undefined);
    }
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* BookmarkedViewSelectViewApplied */118]));
    Belt_Option.map(Belt_Array.getBy(options, (function (sq) {
                return SavedQueriesModel$BsConsole.getName(sq) === value;
              })), (function (savedQuery) {
            return Curry._1(handleChangeUrl, SavedQueriesModel$BsConsole.getRouteForSavedQuery(savedQuery, projectName));
          }));
    
  };
  return React.createElement(FormControl.default, {
              color: "primary",
              children: null
            }, React.createElement("label", {
                  className: label,
                  htmlFor: "bookmark-select"
                }, I18N$BsConsole.show(undefined, "View")), React.createElement(Select.default, {
                  value: value,
                  onChange: handleOnChange,
                  renderValue: (function (value$prime) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  className: Css.style(/* :: */[
                                        Css.fontWeight(/* `num */[
                                              5496390,
                                              500
                                            ]),
                                        /* [] */0
                                      ]),
                                  children: I18N$BsConsole.show(undefined, value$prime)
                                });
                    }),
                  disableUnderline: true,
                  name: "bookmark-select",
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    transitionDuration: 0
                  },
                  children: null
                }, currentSavedView !== undefined ? React.createElement(MenuItem.default, {
                        disabled: true,
                        classes: menuItemClasses,
                        value: SavedQueriesModel$BsConsole.getName(currentSavedView),
                        children: React.createElement(SavedQueryBookmarkSelect$SavedQueryMenuItem, {
                              savedQuery: currentSavedView,
                              userDefault: userDefault,
                              projectDefault: projectDefault
                            })
                      }) : null, Belt_Array.map(options, (function (savedQuery) {
                        var name = SavedQueriesModel$BsConsole.getName(savedQuery);
                        return React.createElement(MenuItem.default, {
                                    classes: menuItemClasses,
                                    value: name,
                                    children: React.createElement(SavedQueryBookmarkSelect$SavedQueryMenuItem, {
                                          savedQuery: savedQuery,
                                          userDefault: userDefault,
                                          projectDefault: projectDefault
                                        }),
                                    key: name
                                  });
                      })), React.createElement(MenuItem.default, {
                      value: "drawer",
                      className: openDrawerItem,
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            className: "value",
                            children: React.createElement(Row2$BsConsole.make, {
                                  children: null
                                }, React.createElement(ViewCompact.default, {
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.px(10)),
                                            /* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                              /* [] */0
                                            ]
                                          ])
                                    }), I18N$BsConsole.show(undefined, "Open saved views"))
                          })
                    })));
}

var make = SavedQueryBookmarkSelect;

exports.Style = Style;
exports.SavedQueryMenuItem = SavedQueryMenuItem;
exports.make = make;
/* label Not a pure module */
