// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../I18N.js");

function toString(param) {
  switch (param) {
    case /* GreaterThanOrEqual */0 :
        return I18N$BsConsole.get(undefined, "Greater than or equal to");
    case /* LessThanOrEqual */1 :
        return I18N$BsConsole.get(undefined, "Less than or equal to");
    case /* NoThreshold */2 :
        return I18N$BsConsole.get(undefined, "No threshold");
    
  }
}

function toStringAlternative(param) {
  switch (param) {
    case /* GreaterThanOrEqual */0 :
        return I18N$BsConsole.get(undefined, "or more");
    case /* LessThanOrEqual */1 :
        return I18N$BsConsole.get(undefined, "or less");
    case /* NoThreshold */2 :
        return I18N$BsConsole.get(undefined, "no threshold");
    
  }
}

function toValue(param) {
  switch (param) {
    case /* GreaterThanOrEqual */0 :
        return "ge";
    case /* LessThanOrEqual */1 :
        return "le";
    case /* NoThreshold */2 :
        return "nothreshold";
    
  }
}

function fromValue(param) {
  switch (param) {
    case "ge" :
        return /* GreaterThanOrEqual */0;
    case "le" :
        return /* LessThanOrEqual */1;
    case "nothreshold" :
        return /* NoThreshold */2;
    default:
      return /* NoThreshold */2;
  }
}

var operatorOptions = /* :: */[
  /* GreaterThanOrEqual */0,
  /* :: */[
    /* LessThanOrEqual */1,
    /* :: */[
      /* NoThreshold */2,
      /* [] */0
    ]
  ]
];

exports.toString = toString;
exports.toStringAlternative = toStringAlternative;
exports.toValue = toValue;
exports.fromValue = fromValue;
exports.operatorOptions = operatorOptions;
/* I18N-BsConsole Not a pure module */
