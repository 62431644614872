// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../../../bs-metrics/MetricsEvent.js");

var metricsNamespace = "symbolservers/";

function toEvent(action) {
  switch (action) {
    case /* ServerCreated */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_created",
                    payload: undefined
                  }]);
    case /* ServerCreationFailed */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_creation_failed",
                    payload: undefined
                  }]);
    case /* ServerUpdated */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_updated",
                    payload: undefined
                  }]);
    case /* ServerUpdateFailed */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_update_failed",
                    payload: undefined
                  }]);
    case /* ServerDeleted */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_deleted",
                    payload: undefined
                  }]);
    case /* ServerDeleteFailed */5 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_delete_failed",
                    payload: undefined
                  }]);
    case /* ServerPriorityUpdate */6 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_priority_updated",
                    payload: undefined
                  }]);
    case /* ServerPriorityUpdateFailed */7 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_priority_update_failed",
                    payload: undefined
                  }]);
    case /* WhitelistUpdateSuccess */8 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_whitelist_update",
                    payload: undefined
                  }]);
    case /* WhitelistUpdateFailed */9 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_whitelist_update_failed",
                    payload: undefined
                  }]);
    case /* WhitelistDeleteSuccess */10 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_whitelist_delete",
                    payload: undefined
                  }]);
    case /* WhitelistDeleteFailed */11 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_whitelist_delete_failed",
                    payload: undefined
                  }]);
    case /* BlacklistUpdateSuccess */12 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_blacklist_update",
                    payload: undefined
                  }]);
    case /* BlacklistUpdateFailed */13 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_blacklist_update_failed",
                    payload: undefined
                  }]);
    case /* BlacklistDeleteSuccess */14 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_blacklist_delete",
                    payload: undefined
                  }]);
    case /* BlacklistDeleteFailed */15 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_blacklist_delete_failed",
                    payload: undefined
                  }]);
    case /* SkiplistDeleteSuccess */16 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_skiplist_delete",
                    payload: undefined
                  }]);
    case /* SkiplistDeleteFailed */17 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_skiplist_delete_failed",
                    payload: undefined
                  }]);
    case /* SkiplistDeleteAllSuccess */18 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_skiplist_delete_all",
                    payload: undefined
                  }]);
    case /* SkiplistDeleteAllFailed */19 :
        return /* GenericEvent */Block.__(2, [{
                    name: "symbolservers/server_skiplist_delete_failed_all",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
