// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var ApiWf$BsConsole = require("./workflows-api/ApiWf.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var Belt_MutableMapString = require("bs-platform/lib/js/belt_MutableMapString.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var ApiWfIntegrations$BsConsole = require("./workflows-api/ApiWfIntegrations.js");
var Typography = require("@material-ui/core/Typography");
var CircularProgress = require("@material-ui/core/CircularProgress");

var wfLogsCache = Belt_MutableMapString.make(undefined);

function getWfLogsFromCache(key) {
  return Belt_MutableMapString.get(wfLogsCache, key);
}

function storeWfLogsInCache(key, logs) {
  return Belt_MutableMapString.set(wfLogsCache, key, logs);
}

var logsSection = Css.style(/* :: */[
      Css.margin(Css.px(16)),
      /* [] */0
    ]);

var logsTitle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.lineHeight(Css.px(24)),
        /* [] */0
      ]
    ]);

var logsContainer = Css.style(/* :: */[
      Css.padding(Css.px(15)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.blackBackground)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.white)),
          /* :: */[
            Css.maxHeight(Css.px(235)),
            /* :: */[
              Css.overflowY(/* scroll */-949692403),
              /* :: */[
                Css.unsafe("fontFamily", "Hack, monospace"),
                /* :: */[
                  Css.borderRadius(Css.px(4)),
                  /* :: */[
                    Css.fontWeight(/* `num */[
                          5496390,
                          400
                        ]),
                    /* :: */[
                      Css.fontSize(Css.px(12)),
                      /* :: */[
                        Css.lineHeight(Css.px(20)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var log = Css.style(/* :: */[
      Css.margin2(Css.px(10), Css.px(0)),
      /* [] */0
    ]);

var logTimestamp = Css.style(/* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            700
          ]),
      /* [] */0
    ]);

var Style = {
  logsSection: logsSection,
  logsTitle: logsTitle,
  logsContainer: logsContainer,
  log: log,
  logTimestamp: logTimestamp
};

function WfIntegrationLogs$Loading(Props) {
  return React.createElement("div", {
              className: logsSection
            }, React.createElement(Typography.default, {
                  variant: "body2",
                  children: I18N$BsConsole.show(undefined, "Latest Logs")
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.justifyContent(/* center */98248149),
                            /* :: */[
                              Css.marginBottom(Css.px(25)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(CircularProgress.default, {
                      size: 30,
                      color: "primary"
                    })));
}

var Loading = {
  make: WfIntegrationLogs$Loading
};

function WfIntegrationLogs(Props) {
  var expanded = Props.expanded;
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var maybeIntegrationId = Props.maybeIntegrationId;
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setIntegrationLogsRemote = match[1];
  var integrationLogsRemote = match[0];
  React.useEffect((function () {
          if (expanded) {
            if (maybeIntegrationId !== undefined) {
              var cachedLogs = Belt_MutableMapString.get(wfLogsCache, projectName + String(maybeIntegrationId));
              if (cachedLogs !== undefined) {
                Curry._1(setIntegrationLogsRemote, (function (param) {
                        return /* Success */Block.__(0, [cachedLogs]);
                      }));
              } else {
                var onSuccess = function (param) {
                  var body = param.body;
                  Belt_MutableMapString.set(wfLogsCache, projectName + String(maybeIntegrationId), body.entries);
                  return Curry._1(setIntegrationLogsRemote, (function (param) {
                                return /* Success */Block.__(0, [body.entries]);
                              }));
                };
                var onFailure = function (err) {
                  return Curry._1(setIntegrationLogsRemote, (function (param) {
                                return /* Failure */Block.__(1, [err]);
                              }));
                };
                var maybeTask = ApiWfIntegrations$BsConsole.QueryIntegrationLogs.queryIntegrationLogsTask(projectName, maybeIntegrationId, config);
                if (maybeTask !== undefined) {
                  Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                          return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                        }));
                } else {
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                }
              }
            } else {
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WfIntegrationLogs", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    "No integration id"
                  ]);
              Curry._1(setIntegrationLogsRemote, (function (param) {
                      return /* Failure */Block.__(1, ["Integration Id not found"]);
                    }));
            }
          }
          
        }), /* tuple */[
        maybeIntegrationId,
        expanded
      ]);
  if (typeof integrationLogsRemote === "number") {
    if (integrationLogsRemote === /* NotAsked */0) {
      return React.createElement(WfIntegrationLogs$Loading, { });
    } else {
      return React.createElement(WfIntegrationLogs$Loading, { });
    }
  }
  if (integrationLogsRemote.tag) {
    return React.createElement("div", {
                className: logsSection
              }, React.createElement(Typography.default, {
                    variant: "body2",
                    children: I18N$BsConsole.show(undefined, "Latest Logs")
                  }), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Error: ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ])
                          ]),
                        "Error: %s"
                      ]), integrationLogsRemote[0]));
  }
  var integrationLogs = integrationLogsRemote[0];
  if (integrationLogs.length !== 0) {
    return React.createElement("div", {
                className: logsSection
              }, React.createElement(Typography.default, {
                    variant: "body2",
                    children: I18N$BsConsole.show(undefined, "Latest Logs")
                  }), React.createElement("div", {
                    className: logsContainer
                  }, Belt_Array.map(integrationLogs, (function (log$1) {
                          return React.createElement("div", {
                                      className: log
                                    }, React.createElement("strong", {
                                          className: logTimestamp
                                        }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          ": ",
                                                          /* End_of_format */0
                                                        ])
                                                    ]),
                                                  "%s: "
                                                ]), log$1.timestamp)), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ]),
                                              "%s"
                                            ]), log$1.message));
                        }))));
  } else {
    return React.createElement("div", {
                className: logsSection
              }, React.createElement(Typography.default, {
                    variant: "body2",
                    children: I18N$BsConsole.show(undefined, "Latest Logs")
                  }), I18N$BsConsole.show(undefined, "No logs available"));
  }
}

var component = ReasonReact.statelessComponent("WfIntegrationLogs");

function make(expanded, token, config, maybeIntegrationId, projectName) {
  var partial_arg = {
    expanded: expanded,
    token: token,
    config: config,
    projectName: projectName,
    maybeIntegrationId: maybeIntegrationId
  };
  return (function (param) {
      return ReasonReactCompat.wrapReactForReasonReact(WfIntegrationLogs, partial_arg, param);
    });
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = WfIntegrationLogs;

exports.wfLogsCache = wfLogsCache;
exports.getWfLogsFromCache = getWfLogsFromCache;
exports.storeWfLogsInCache = storeWfLogsInCache;
exports.Style = Style;
exports.Loading = Loading;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* wfLogsCache Not a pure module */
