// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function ofJson(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.map((function (param) {
                              if (param[0] === "OK") {
                                return /* Ok */[{
                                          messages: param[1]
                                        }];
                              } else {
                                return /* Err */0;
                              }
                            }), (function (json) {
                              return /* tuple */[
                                      Json_decode.field("status", Json_decode.string, json),
                                      Json_decode.at(/* :: */[
                                              "data",
                                              /* :: */[
                                                "messages",
                                                /* [] */0
                                              ]
                                            ], (function (param) {
                                                return Json_decode.array(Json_decode.string, param);
                                              }))(json)
                                    ];
                            }), param);
              }), json);
}

exports.ofJson = ofJson;
/* No side effect */
