// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = RR$BsConsole.reducerComponent("WfNameField-BsConsole");

function make(projectName, allWatchers, editedWatcherName, showAllErrors, value, onValueChange, disabledOpt, elementRef, param) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var tmp;
              tmp = value.tag ? value[0][1] : undefined;
              var tmp$1 = {
                style: {
                  padding: "8px 0"
                }
              };
              if (elementRef !== undefined) {
                tmp$1.ref = Caml_option.valFromOption(elementRef);
              }
              var tmp$2;
              tmp$2 = value.tag ? value[0][0] : value[0];
              return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make({
                              title: "Name integration",
                              description: "This will be used to identify the integration throughout the app"
                            }, true, undefined, tmp, undefined, param.state.dirty, showAllErrors, [React.createElement("div", tmp$1, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            true,
                                            (function (e) {
                                                Curry._1(send, /* SetDirty */0);
                                                var newName = e.target.value;
                                                if (newName === "") {
                                                  return Curry._1(onValueChange, /* Error */Block.__(1, [/* tuple */[
                                                                  newName,
                                                                  /* Required */0
                                                                ]]));
                                                }
                                                var newWatcherName = projectName + ("-" + newName);
                                                var watcherNameExists = Belt_Array.some(allWatchers, (function (w) {
                                                        if (w.watcherName === newWatcherName) {
                                                          return Belt_Option.mapWithDefault(editedWatcherName, true, (function (editedWatcherName) {
                                                                        return newWatcherName !== editedWatcherName;
                                                                      }));
                                                        } else {
                                                          return false;
                                                        }
                                                      }));
                                                if (watcherNameExists) {
                                                  return Curry._1(onValueChange, /* Error */Block.__(1, [/* tuple */[
                                                                  newName,
                                                                  /* NameIsNotUnique */7
                                                                ]]));
                                                } else {
                                                  return Curry._1(onValueChange, /* Ok */Block.__(0, [newName]));
                                                }
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            /* `String */[
                                              -976970511,
                                              tmp$2
                                            ],
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            disabled,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            []
                                          ])))]));
            }),
          initialState: (function (param) {
              return {
                      dirty: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          dirty: true
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
