// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BtTable$BsConsole = require("../BtTable.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Edit = require("@material-ui/icons/Edit");
var ExporterUtils$BsConsole = require("./ExporterUtils.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var ExporterSetApi$BsConsole = require("./api/ExporterSetApi.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var Warning = require("@material-ui/icons/Warning");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var ExporterExportApi$BsConsole = require("./api/ExporterExportApi.js");
var ExporterMetricApi$BsConsole = require("./api/ExporterMetricApi.js");
var ExporterMetricRow$BsConsole = require("./ExporterMetricRow.js");
var OpenInNew = require("@material-ui/icons/OpenInNew");
var ExporterDeleteModal$BsConsole = require("./ExporterDeleteModal.js");
var ExporterEditSetModal$BsConsole = require("./ExporterEditSetModal.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

function ExporterSets(Props) {
  var token = Props.token;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var stores = Props.stores;
  var $$exports = Props.exports;
  var fetchExports = Props.fetchExports;
  var metrics = Props.metrics;
  var fetchMetrics = Props.fetchMetrics;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setSets = match[1];
  var sets = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setEditSet = match$1[1];
  var editSet = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setSetDeleteModal = match$2[1];
  var setDeleteModal = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setMetricDeleteModal = match$3[1];
  var metricDeleteModal = match$3[0];
  React.useEffect((function () {
          var arg = ExporterSetApi$BsConsole.get(endpoint, universeName, projectName, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setSets, (function (param) {
                                return result;
                              }));
                }));
          
        }), /* tuple */[
        token,
        endpoint,
        universeName,
        projectName
      ]);
  var handleSetEdit = function (newName, storesToAdd, storesToRemove) {
    if (editSet === undefined) {
      return ;
    }
    if (editSet.name !== newName) {
      var arg = ExporterSetApi$BsConsole.update(endpoint, universeName, projectName, newName, editSet.id, undefined);
      Curry._2((function (param) {
                return (function (param$1, param$2) {
                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                  });
              })(token), undefined, (function (resp) {
              if (resp.tag) {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
              }
              var updated = Belt_List.map(sets, (function (set) {
                      if (set.id === editSet.id) {
                        return {
                                name: newName,
                                id: editSet.id
                              };
                      } else {
                        return set;
                      }
                    }));
              return Curry._1(setSets, (function (param) {
                            return updated;
                          }));
            }));
    }
    var toRemovePromises = Belt_List.toArray(Belt_List.flatten(Belt_List.fromArray(Belt_Array.map(storesToRemove, (function (storeToRemove) {
                        var exportsToDelete = Belt_List.filter($$exports, (function ($$export) {
                                if ($$export.storeId === storeToRemove.id) {
                                  return $$export.setId === editSet.id;
                                } else {
                                  return false;
                                }
                              }));
                        return Belt_List.map(exportsToDelete, (function (exportToDelete) {
                                      return new Promise((function (resolve, param) {
                                                    var arg = ExporterExportApi$BsConsole.$$delete(endpoint, universeName, projectName, exportToDelete.id, undefined);
                                                    return Curry._2((function (param) {
                                                                    return (function (param$1, param$2) {
                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                      });
                                                                  })(token), undefined, (function (resp) {
                                                                  if (resp.tag) {
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                                                                  }
                                                                  return resolve(resp);
                                                                }));
                                                  }));
                                    }));
                      })))));
    var toAddPromises = Belt_Array.map(storesToAdd, (function (storeToAdd) {
            return new Promise((function (resolve, param) {
                          var arg = ExporterExportApi$BsConsole.create(endpoint, universeName, projectName, storeToAdd.id, editSet.id, undefined);
                          return Curry._2((function (param) {
                                          return (function (param$1, param$2) {
                                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                            });
                                        })(token), undefined, (function (resp) {
                                        if (!resp.tag) {
                                          return ;
                                        }
                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                                        return resolve(resp);
                                      }));
                        }));
          }));
    var __x = Promise.all(toRemovePromises);
    var __x$1 = __x.then((function (_result) {
            Curry._1(fetchExports, undefined);
            return Promise.resolve(undefined);
          }));
    __x$1.catch((function (_exn) {
            Curry._1(fetchExports, undefined);
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error removing exports."));
            return Promise.resolve(undefined);
          }));
    var __x$2 = Promise.all(toAddPromises);
    var __x$3 = __x$2.then((function (_result) {
            Curry._1(fetchExports, undefined);
            return Promise.resolve(undefined);
          }));
    __x$3.catch((function (_exn) {
            Curry._1(fetchExports, undefined);
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error adding exports."));
            return Promise.resolve(undefined);
          }));
    
  };
  var handleSetDelete = function (param) {
    if (setDeleteModal === undefined) {
      return ;
    }
    var arg = ExporterSetApi$BsConsole.$$delete(endpoint, universeName, projectName, setDeleteModal.id, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var updated = Belt_List.keep(sets, (function (item) {
                          return item.id !== setDeleteModal.id;
                        }));
                  return Curry._1(setSets, (function (param) {
                                return updated;
                              }));
                }));
  };
  var handleMetricDelete = function (param) {
    if (metricDeleteModal === undefined) {
      return ;
    }
    var arg = ExporterMetricApi$BsConsole.$$delete(endpoint, universeName, projectName, metricDeleteModal.id, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  } else {
                    return Curry._1(fetchMetrics, undefined);
                  }
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(ExporterEditSetModal$BsConsole.make, {
                  open: Belt_Option.isSome(editSet),
                  onClose: (function (param) {
                      return Curry._1(setEditSet, (function (param) {
                                    
                                  }));
                    }),
                  handleSave: handleSetEdit,
                  existingSet: editSet,
                  existingLinkedExports: editSet !== undefined ? Belt_List.keep($$exports, (function ($$export) {
                            return $$export.setId === editSet.id;
                          })) : /* [] */0,
                  stores: stores,
                  exports: $$exports,
                  handleChangeUrl: handleChangeUrl,
                  projectName: projectName
                }), React.createElement(ExporterDeleteModal$BsConsole.make, {
                  open: setDeleteModal !== undefined,
                  onClose: (function (param) {
                      return Curry._1(setSetDeleteModal, (function (param) {
                                    
                                  }));
                    }),
                  handleDelete: handleSetDelete,
                  title: I18N$BsConsole.get(undefined, "Delete metrics set"),
                  itemName: I18N$BsConsole.get(undefined, "metrics set")
                }), React.createElement(ExporterDeleteModal$BsConsole.make, {
                  open: metricDeleteModal !== undefined,
                  onClose: (function (param) {
                      return Curry._1(setMetricDeleteModal, (function (param) {
                                    
                                  }));
                    }),
                  handleDelete: handleMetricDelete,
                  title: I18N$BsConsole.get(undefined, "Delete metric from this set"),
                  itemName: I18N$BsConsole.get(undefined, "metric")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined));
                    }),
                  children: null
                }, React.createElement(OpenInNew.default, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ])
                    }), I18N$BsConsole.show(undefined, "Create a metrics set in explore")), Belt_List.length(sets) === 0 ? null : React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            pageMarginBottom,
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ])
                    },
                    children: React.createElement(BtTableContainer$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.borderRadius(Css.px(4)),
                                /* [] */0
                              ]),
                          children: React.createElement(BtTable$BsConsole.make, {
                                children: null
                              }, React.createElement(BtTableHead$BsConsole.make, {
                                    children: React.createElement(BtTableRow$BsConsole.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.px(48)),
                                                    /* [] */0
                                                  ])
                                            }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                              children: I18N$BsConsole.show(undefined, "Metrics set")
                                            }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                              children: I18N$BsConsole.show(undefined, "Connected metric stores")
                                            }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                              children: I18N$BsConsole.show(undefined, "Last transmitted")
                                            }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.px(96)),
                                                    /* [] */0
                                                  ])
                                            }))
                                  }), React.createElement(BtTableBody$BsConsole.make, {
                                    children: Belt_List.toArray(Belt_List.map(sets, (function (curr) {
                                                var linkedExports = Belt_List.keep($$exports, (function ($$export) {
                                                        return $$export.setId === curr.id;
                                                      }));
                                                var allLinkedStores = Belt_List.flatten(Belt_List.mapWithIndex(linkedExports, (function (_exportIndex, $$export) {
                                                            return Belt_List.keep(stores, (function (store) {
                                                                          return store.id === $$export.storeId;
                                                                        }));
                                                          })));
                                                var allLinkedStoreNames = Belt_List.toArray(Belt_List.mapWithIndex(allLinkedStores, (function (_storeIndex, store) {
                                                            return ExporterUtils$BsConsole.getStoreName(store);
                                                          })));
                                                var linkedMetrics = Belt_List.keep(metrics, (function (metric) {
                                                        return metric.setId === curr.id;
                                                      }));
                                                var lastTransmittedTimestamp = Belt_List.reduce(allLinkedStores, 0, (function (acc, store) {
                                                        if (store.lastTs > acc) {
                                                          return store.lastTs;
                                                        } else {
                                                          return acc;
                                                        }
                                                      })) * 1000;
                                                return React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                                            expandedContent: Belt_List.length(linkedMetrics) === 0 ? React.createElement("div", {
                                                                    className: Css.style(/* :: */[
                                                                          Css.marginLeft(Css.px(47)),
                                                                          /* :: */[
                                                                            Css.marginBottom(Css.px(15)),
                                                                            /* [] */0
                                                                          ]
                                                                        ])
                                                                  }, React.createElement(BtTypography$BsConsole.make, {
                                                                        variant: /* Caption */11,
                                                                        className: Css.style(/* :: */[
                                                                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                              /* [] */0
                                                                            ]),
                                                                        children: I18N$BsConsole.show(undefined, "There are no metrics attached to this set.")
                                                                      })) : React.createElement("div", {
                                                                    className: Css.style(/* :: */[
                                                                          Css.width(Css.pct(60)),
                                                                          /* :: */[
                                                                            Css.marginLeft(Css.px(29)),
                                                                            /* :: */[
                                                                              Css.marginBottom(Css.px(15)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]
                                                                        ])
                                                                  }, React.createElement(BtTableContainer$BsConsole.make, {
                                                                        children: React.createElement(BtTable$BsConsole.make, {
                                                                              showGrid: false,
                                                                              children: null
                                                                            }, React.createElement(BtTableHead$BsConsole.make, {
                                                                                  children: React.createElement(BtTableRow$BsConsole.make, {
                                                                                        children: null
                                                                                      }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.width(Css.px(150)),
                                                                                                  /* [] */0
                                                                                                ]),
                                                                                            children: I18N$BsConsole.show(undefined, "Metric name")
                                                                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                                                                            children: React.createElement(Row2$BsConsole.make, {
                                                                                                  alignItems: /* center */98248149,
                                                                                                  justifyContent: /* spaceBetween */516682146,
                                                                                                  children: null
                                                                                                }, I18N$BsConsole.show(undefined, "Query"), React.createElement("div", {
                                                                                                      className: Css.style(/* :: */[
                                                                                                            Css.marginBottom(Css.px(8)),
                                                                                                            /* [] */0
                                                                                                          ])
                                                                                                    }, React.createElement(Button.default, {
                                                                                                          variant: "text",
                                                                                                          size: "small",
                                                                                                          color: "primary",
                                                                                                          onClick: (function (param) {
                                                                                                              return Curry._1(handleChangeUrl, Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined));
                                                                                                            }),
                                                                                                          children: null
                                                                                                        }, React.createElement(OpenInNew.default, {
                                                                                                              className: Css.style(/* :: */[
                                                                                                                    Css.marginRight(Css.px(8)),
                                                                                                                    /* [] */0
                                                                                                                  ])
                                                                                                            }), I18N$BsConsole.show(undefined, "Add metrics in explore"))))
                                                                                          }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.width(Css.px(96)),
                                                                                                  /* [] */0
                                                                                                ])
                                                                                          }))
                                                                                }), React.createElement(BtTableBody$BsConsole.make, {
                                                                                  children: Belt_List.toArray(Belt_List.map(linkedMetrics, (function (m) {
                                                                                              return React.createElement(ExporterMetricRow$BsConsole.make, {
                                                                                                          m: m,
                                                                                                          setMetricDeleteModal: setMetricDeleteModal,
                                                                                                          projectName: projectName
                                                                                                        });
                                                                                            })))
                                                                                }))
                                                                      })),
                                                            children: null
                                                          }, React.createElement(BtTableCell$BsConsole.make, {
                                                                children: I18N$BsConsole.showSkip(curr.name)
                                                              }), allLinkedStoreNames.length === 0 ? React.createElement(BtTableCell$BsConsole.make, {
                                                                  children: React.createElement(Tooltip.default, {
                                                                        classes: {
                                                                          root: Css.style(/* :: */[
                                                                                Css.width(Css.px(150)),
                                                                                /* [] */0
                                                                              ])
                                                                        },
                                                                        children: React.createElement("div", {
                                                                              className: Css.style(/* :: */[
                                                                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                                                    /* [] */0
                                                                                  ])
                                                                            }, React.createElement(Warning.default, { })),
                                                                        title: Belt_List.length(stores) === 0 ? I18N$BsConsole.get(undefined, "To start transmitting data, create a store below and then edit this metrics set to attach it to the store.") : I18N$BsConsole.get(undefined, "To start transmitting data, click the edit icon to connect a store to this set.")
                                                                      })
                                                                }) : React.createElement(Tooltip.default, {
                                                                  children: React.createElement(BtTableCell$BsConsole.make, {
                                                                        children: I18N$BsConsole.showSkip(allLinkedStoreNames.join(", "))
                                                                      }),
                                                                  title: allLinkedStoreNames.join(", ")
                                                                }), React.createElement(BtTableCell$BsConsole.make, {
                                                                children: lastTransmittedTimestamp === 0 ? I18N$BsConsole.showSkip("--") : I18N$BsConsole.showSkip(new Date(lastTransmittedTimestamp).toLocaleString())
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.important(Css.padding(/* zero */-789508312)),
                                                                      /* [] */0
                                                                    ]),
                                                                children: null
                                                              }, React.createElement(Mui$BsConsole.IconButton.make, {
                                                                    ariaLabel: "Edit",
                                                                    onClick: (function (param) {
                                                                        return Curry._1(setEditSet, (function (param) {
                                                                                      return curr;
                                                                                    }));
                                                                      }),
                                                                    children: React.createElement(Edit.default, { })
                                                                  }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                                    ariaLabel: "Delete",
                                                                    onClick: (function (param) {
                                                                        return Curry._1(setSetDeleteModal, (function (param) {
                                                                                      return curr;
                                                                                    }));
                                                                      }),
                                                                    children: React.createElement(Delete.default, { })
                                                                  })));
                                              })))
                                  }))
                        })
                  }));
}

var make = ExporterSets;

exports.pageMarginBottom = pageMarginBottom;
exports.make = make;
/* pageMarginBottom Not a pure module */
