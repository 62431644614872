// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Option$BsConsole = require("./option.js");

function to_json(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key = {
  to_json: to_json,
  of_json: of_json
};

function to_key(param) {
  return {
          id: param.id
        };
}

function to_json$1(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* :: */[
                      /* tuple */[
                        "deleted",
                        o.deleted
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Universe = {
  table: "universe",
  Key: Key,
  to_key: to_key,
  to_json: to_json$1,
  of_json: of_json$1
};

function getEmpty(param) {
  return {
          lastSeen: undefined
        };
}

function to_json$2(t) {
  var lastVisit = Belt_Option.map(t.lastSeen, (function (lastVisit) {
          return /* tuple */[
                  "lastSeen",
                  lastVisit.valueOf()
                ];
        }));
  return Json_encode.object_(Belt_List.keepMap(/* :: */[
                  lastVisit,
                  /* [] */0
                ], (function (v) {
                    return v;
                  })));
}

function of_json$2(json) {
  return {
          lastSeen: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("lastSeen", Json_decode.$$float, param);
                    }), json), (function (d) {
                  return new Date(d);
                }))
        };
}

var Metadata = {
  getEmpty: getEmpty,
  to_json: to_json$2,
  of_json: of_json$2
};

function to_json$3(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "username",
                    o.username
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$3(json) {
  return {
          username: Json_decode.field("username", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json)
        };
}

var Key$1 = {
  to_json: to_json$3,
  of_json: of_json$3
};

function to_key$1(param) {
  return {
          username: param.username,
          universe: param.universe
        };
}

function to_json$4(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "uid",
                    o.uid
                  ],
                  /* :: */[
                    /* tuple */[
                      "email",
                      o.email
                    ],
                    /* :: */[
                      /* tuple */[
                        "username",
                        o.username
                      ],
                      /* :: */[
                        /* tuple */[
                          "method",
                          o.method_
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.password, (function (v) {
                                  return /* tuple */[
                                          "password",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.metadata, (function (v) {
                                    return /* tuple */[
                                            "metadata",
                                            JSON.stringify(to_json$2(v))
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "active",
                                o.active
                              ],
                              /* :: */[
                                /* tuple */[
                                  "superuser",
                                  o.superuser
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "universe",
                                    o.universe
                                  ],
                                  /* :: */[
                                    Option$BsConsole.bind(o.role, (function (v) {
                                            return /* tuple */[
                                                    "role",
                                                    v
                                                  ];
                                          })),
                                    /* :: */[
                                      /* tuple */[
                                        "deleted",
                                        o.deleted
                                      ],
                                      /* :: */[
                                        Option$BsConsole.bind(o.__state, (function (v) {
                                                return /* tuple */[
                                                        "__state",
                                                        v
                                                      ];
                                              })),
                                        /* :: */[
                                          Option$BsConsole.bind(o.__create, (function (v) {
                                                  return /* tuple */[
                                                          "__create",
                                                          v
                                                        ];
                                                })),
                                          /* :: */[
                                            Option$BsConsole.bind(o.__modify, (function (v) {
                                                    return /* tuple */[
                                                            "__modify",
                                                            v
                                                          ];
                                                  })),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$4(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("metadata", Json_decode.string, param);
        }), json);
  return {
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json),
          username: Json_decode.field("username", Json_decode.string, json),
          method_: Json_decode.field("method", Json_decode.string, json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json),
          metadata: Belt_Option.flatMap(__x, (function (metadata) {
                  try {
                    return of_json$2(JSON.parse(metadata));
                  }
                  catch (exn){
                    return ;
                  }
                })),
          active: Json_decode.field("active", Json_decode.$$int, json),
          superuser: Json_decode.field("superuser", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          role: Json_decode.optional((function (param) {
                  return Json_decode.field("role", Json_decode.string, param);
                }), json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Users = {
  table: "users",
  Metadata: Metadata,
  Key: Key$1,
  to_key: to_key$1,
  to_json: to_json$4,
  of_json: of_json$4
};

function to_json$5(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$5(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Key$2 = {
  to_json: to_json$5,
  of_json: of_json$5
};

function to_key$2(param) {
  return {
          universe: param.universe,
          name: param.name
        };
}

function to_json$6(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "pid",
                    o.pid
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* :: */[
                      /* tuple */[
                        "name",
                        o.name
                      ],
                      /* :: */[
                        /* tuple */[
                          "owner",
                          o.owner
                        ],
                        /* :: */[
                          /* tuple */[
                            "deleted",
                            o.deleted
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$6(json) {
  return {
          pid: Json_decode.field("pid", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Project = {
  table: "project",
  Key: Key$2,
  to_key: to_key$2,
  to_json: to_json$6,
  of_json: of_json$6
};

function to_json$7(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$7(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json)
        };
}

var Key$3 = {
  to_json: to_json$7,
  of_json: of_json$7
};

function to_key$3(param) {
  return {
          id: param.id
        };
}

function to_json$8(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      /* tuple */[
                        "capabilities",
                        o.capabilities
                      ],
                      /* :: */[
                        /* tuple */[
                          "owner",
                          o.owner
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.metadata, (function (v) {
                                  return /* tuple */[
                                          "metadata",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$8(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          capabilities: Json_decode.field("capabilities", Json_decode.string, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ApiToken = {
  table: "api_token",
  Key: Key$3,
  to_key: to_key$3,
  to_json: to_json$8,
  of_json: of_json$8
};

function to_json$9(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$9(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Key$4 = {
  to_json: to_json$9,
  of_json: of_json$9
};

function to_key$4(param) {
  return {
          project: param.project,
          name: param.name
        };
}

function to_json$10(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.format, (function (v) {
                              return /* tuple */[
                                      "format",
                                      v
                                    ];
                            })),
                      /* :: */[
                        /* tuple */[
                          "type",
                          o.type_
                        ],
                        /* :: */[
                          /* tuple */[
                            "description",
                            o.description
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$10(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          format: Json_decode.optional((function (param) {
                  return Json_decode.field("format", Json_decode.string, param);
                }), json),
          type_: Json_decode.field("type", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Attribute = {
  table: "attribute",
  Key: Key$4,
  to_key: to_key$4,
  to_json: to_json$10,
  of_json: of_json$10
};

function to_json$11(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$11(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Key$5 = {
  to_json: to_json$11,
  of_json: of_json$11
};

function to_key$5(param) {
  return {
          project: param.project,
          name: param.name
        };
}

function to_json$12(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* :: */[
                      /* tuple */[
                        "id",
                        o.id
                      ],
                      /* :: */[
                        /* tuple */[
                          "owner",
                          o.owner
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.languages, (function (v) {
                                  return /* tuple */[
                                          "languages",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.platforms, (function (v) {
                                    return /* tuple */[
                                            "platforms",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "enabled",
                                o.enabled
                              ],
                              /* :: */[
                                /* tuple */[
                                  "rules",
                                  o.rules
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "priority",
                                    o.priority
                                  ],
                                  /* :: */[
                                    Option$BsConsole.bind(o.__state, (function (v) {
                                            return /* tuple */[
                                                    "__state",
                                                    v
                                                  ];
                                          })),
                                    /* :: */[
                                      Option$BsConsole.bind(o.__create, (function (v) {
                                              return /* tuple */[
                                                      "__create",
                                                      v
                                                    ];
                                            })),
                                      /* :: */[
                                        Option$BsConsole.bind(o.__modify, (function (v) {
                                                return /* tuple */[
                                                        "__modify",
                                                        v
                                                      ];
                                              })),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$12(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          languages: Json_decode.optional((function (param) {
                  return Json_decode.field("languages", Json_decode.string, param);
                }), json),
          platforms: Json_decode.optional((function (param) {
                  return Json_decode.field("platforms", Json_decode.string, param);
                }), json),
          enabled: Json_decode.field("enabled", Json_decode.$$int, json),
          rules: Json_decode.field("rules", Json_decode.string, json),
          priority: Json_decode.field("priority", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Deduplication = {
  table: "deduplication",
  Key: Key$5,
  to_key: to_key$5,
  to_json: to_json$12,
  of_json: of_json$12
};

function to_json$13(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$13(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$6 = {
  to_json: to_json$13,
  of_json: of_json$13
};

function to_key$6(param) {
  return {
          id: param.id
        };
}

function to_json$14(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "rules",
                      o.rules
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$14(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          rules: Json_decode.field("rules", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var InstanceRetention = {
  table: "instance_retention",
  Key: Key$6,
  to_key: to_key$6,
  to_json: to_json$14,
  of_json: of_json$14
};

function to_json$15(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* [] */0
                ]));
}

function of_json$15(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json)
        };
}

var Key$7 = {
  to_json: to_json$15,
  of_json: of_json$15
};

function to_key$7(param) {
  return {
          universe: param.universe
        };
}

function to_json$16(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* :: */[
                    /* tuple */[
                      "definition",
                      o.definition
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.metadata, (function (v) {
                              return /* tuple */[
                                      "metadata",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$16(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          definition: Json_decode.field("definition", Json_decode.string, json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Limits = {
  table: "limits",
  Key: Key$7,
  to_key: to_key$7,
  to_json: to_json$16,
  of_json: of_json$16
};

function to_json$17(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$17(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$8 = {
  to_json: to_json$17,
  of_json: of_json$17
};

function to_key$8(param) {
  return {
          id: param.id
        };
}

function to_json$18(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "groupname",
                      o.groupname
                    ],
                    /* :: */[
                      /* tuple */[
                        "protocol",
                        o.protocol
                      ],
                      /* :: */[
                        /* tuple */[
                          "type",
                          o.type_
                        ],
                        /* :: */[
                          /* tuple */[
                            "address",
                            o.address
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.dns_name, (function (v) {
                                    return /* tuple */[
                                            "dns_name",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.service, (function (v) {
                                      return /* tuple */[
                                              "service",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                /* tuple */[
                                  "backlog",
                                  o.backlog
                                ],
                                /* :: */[
                                  Option$BsConsole.bind(o.ssl, (function (v) {
                                          return /* tuple */[
                                                  "ssl",
                                                  v
                                                ];
                                        })),
                                  /* :: */[
                                    Option$BsConsole.bind(o.concurrency, (function (v) {
                                            return /* tuple */[
                                                    "concurrency",
                                                    v
                                                  ];
                                          })),
                                    /* :: */[
                                      /* tuple */[
                                        "undeletable",
                                        o.undeletable
                                      ],
                                      /* :: */[
                                        Option$BsConsole.bind(o.__state, (function (v) {
                                                return /* tuple */[
                                                        "__state",
                                                        v
                                                      ];
                                              })),
                                        /* :: */[
                                          Option$BsConsole.bind(o.__create, (function (v) {
                                                  return /* tuple */[
                                                          "__create",
                                                          v
                                                        ];
                                                })),
                                          /* :: */[
                                            Option$BsConsole.bind(o.__modify, (function (v) {
                                                    return /* tuple */[
                                                            "__modify",
                                                            v
                                                          ];
                                                  })),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$18(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          groupname: Json_decode.field("groupname", Json_decode.string, json),
          protocol: Json_decode.field("protocol", Json_decode.$$int, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          address: Json_decode.field("address", Json_decode.string, json),
          dns_name: Json_decode.optional((function (param) {
                  return Json_decode.field("dns_name", Json_decode.string, param);
                }), json),
          service: Json_decode.optional((function (param) {
                  return Json_decode.field("service", Json_decode.string, param);
                }), json),
          backlog: Json_decode.field("backlog", Json_decode.$$int, json),
          ssl: Json_decode.optional((function (param) {
                  return Json_decode.field("ssl", Json_decode.$$int, param);
                }), json),
          concurrency: Json_decode.optional((function (param) {
                  return Json_decode.field("concurrency", Json_decode.$$int, param);
                }), json),
          undeletable: Json_decode.field("undeletable", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Listener = {
  table: "listener",
  Key: Key$8,
  to_key: to_key$8,
  to_json: to_json$18,
  of_json: of_json$18
};

function to_json$19(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "pid",
                    o.pid
                  ],
                  /* [] */0
                ]));
}

function of_json$19(json) {
  return {
          pid: Json_decode.field("pid", Json_decode.$$int, json)
        };
}

var Key$9 = {
  to_json: to_json$19,
  of_json: of_json$19
};

function to_key$9(param) {
  return {
          pid: param.pid
        };
}

function to_json$20(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "pid",
                    o.pid
                  ],
                  /* :: */[
                    /* tuple */[
                      "json",
                      o.json
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$20(json) {
  return {
          pid: Json_decode.field("pid", Json_decode.$$int, json),
          json: Json_decode.field("json", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectFrontendSettings = {
  table: "project_frontend_settings",
  Key: Key$9,
  to_key: to_key$9,
  to_json: to_json$20,
  of_json: of_json$20
};

function to_json$21(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$21(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json)
        };
}

var Key$10 = {
  to_json: to_json$21,
  of_json: of_json$21
};

function to_key$10(param) {
  return {
          id: param.id,
          universe: param.universe
        };
}

function to_json$22(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* :: */[
                      /* tuple */[
                        "name",
                        o.name
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.description, (function (v) {
                                return /* tuple */[
                                        "description",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__state, (function (v) {
                                  return /* tuple */[
                                          "__state",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__create, (function (v) {
                                    return /* tuple */[
                                            "__create",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__modify, (function (v) {
                                      return /* tuple */[
                                              "__modify",
                                              v
                                            ];
                                    })),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$22(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Team = {
  table: "team",
  Key: Key$10,
  to_key: to_key$10,
  to_json: to_json$22,
  of_json: of_json$22
};

function to_json$23(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "team",
                      o.team
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$23(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          team: Json_decode.field("team", Json_decode.$$int, json)
        };
}

var Key$11 = {
  to_json: to_json$23,
  of_json: of_json$23
};

function to_key$11(param) {
  return {
          project: param.project,
          team: param.team
        };
}

function to_json$24(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "team",
                      o.team
                    ],
                    /* :: */[
                      /* tuple */[
                        "role",
                        o.role
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$24(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          team: Json_decode.field("team", Json_decode.$$int, json),
          role: Json_decode.field("role", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectMemberTeam = {
  table: "project_member_team",
  Key: Key$11,
  to_key: to_key$11,
  to_json: to_json$24,
  of_json: of_json$24
};

function to_json$25(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "user",
                      o.user
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$25(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          user: Json_decode.field("user", Json_decode.$$int, json)
        };
}

var Key$12 = {
  to_json: to_json$25,
  of_json: of_json$25
};

function to_key$12(param) {
  return {
          project: param.project,
          user: param.user
        };
}

function to_json$26(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "user",
                      o.user
                    ],
                    /* :: */[
                      /* tuple */[
                        "role",
                        o.role
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$26(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          user: Json_decode.field("user", Json_decode.$$int, json),
          role: Json_decode.field("role", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectMemberUser = {
  table: "project_member_user",
  Key: Key$12,
  to_key: to_key$12,
  to_json: to_json$26,
  of_json: of_json$26
};

function to_json$27(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* [] */0
                ]));
}

function of_json$27(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$13 = {
  to_json: to_json$27,
  of_json: of_json$27
};

function to_key$13(param) {
  return {
          project: param.project
        };
}

function to_json$28(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "rules",
                      o.rules
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$28(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          rules: Json_decode.field("rules", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectRetention = {
  table: "project_retention",
  Key: Key$13,
  to_key: to_key$13,
  to_json: to_json$28,
  of_json: of_json$28
};

function to_json$29(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$29(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$14 = {
  to_json: to_json$29,
  of_json: of_json$29
};

function to_key$14(param) {
  return {
          name: param.name,
          project: param.project
        };
}

function to_json$30(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.owner, (function (v) {
                              return /* tuple */[
                                      "owner",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.metadata, (function (v) {
                                return /* tuple */[
                                        "metadata",
                                        v
                                      ];
                              })),
                        /* :: */[
                          /* tuple */[
                            "queries",
                            o.queries
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.payload, (function (v) {
                                    return /* tuple */[
                                            "payload",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__state, (function (v) {
                                      return /* tuple */[
                                              "__state",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__create, (function (v) {
                                        return /* tuple */[
                                                "__create",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__modify, (function (v) {
                                          return /* tuple */[
                                                  "__modify",
                                                  v
                                                ];
                                        })),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$30(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          owner: Json_decode.optional((function (param) {
                  return Json_decode.field("owner", Json_decode.$$int, param);
                }), json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          queries: Json_decode.field("queries", Json_decode.string, json),
          payload: Json_decode.optional((function (param) {
                  return Json_decode.field("payload", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Query = {
  table: "query",
  Key: Key$14,
  to_key: to_key$14,
  to_json: to_json$30,
  of_json: of_json$30
};

function to_json$31(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$31(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$15 = {
  to_json: to_json$31,
  of_json: of_json$31
};

function to_key$15(param) {
  return {
          id: param.id
        };
}

function to_json$32(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "mail_from",
                      o.mail_from
                    ],
                    /* :: */[
                      /* tuple */[
                        "server",
                        o.server
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.cainfo, (function (v) {
                                return /* tuple */[
                                        "cainfo",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.username, (function (v) {
                                  return /* tuple */[
                                          "username",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.password, (function (v) {
                                    return /* tuple */[
                                            "password",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__state, (function (v) {
                                      return /* tuple */[
                                              "__state",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__create, (function (v) {
                                        return /* tuple */[
                                                "__create",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__modify, (function (v) {
                                          return /* tuple */[
                                                  "__modify",
                                                  v
                                                ];
                                        })),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$32(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          mail_from: Json_decode.field("mail_from", Json_decode.string, json),
          server: Json_decode.field("server", Json_decode.string, json),
          cainfo: Json_decode.optional((function (param) {
                  return Json_decode.field("cainfo", Json_decode.string, param);
                }), json),
          username: Json_decode.optional((function (param) {
                  return Json_decode.field("username", Json_decode.string, param);
                }), json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Smtp = {
  table: "smtp",
  Key: Key$15,
  to_key: to_key$15,
  to_json: to_json$32,
  of_json: of_json$32
};

function to_json$33(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$33(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$16 = {
  to_json: to_json$33,
  of_json: of_json$33
};

function to_key$16(param) {
  return {
          id: param.id
        };
}

function to_json$34(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "owner",
                      o.owner
                    ],
                    /* :: */[
                      /* tuple */[
                        "project",
                        o.project
                      ],
                      /* :: */[
                        /* tuple */[
                          "include_users",
                          o.include_users
                        ],
                        /* :: */[
                          /* tuple */[
                            "title",
                            o.title
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.rcpt, (function (v) {
                                    return /* tuple */[
                                            "rcpt",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "day",
                                o.day
                              ],
                              /* :: */[
                                /* tuple */[
                                  "period",
                                  o.period
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "timezone",
                                    o.timezone
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "hour",
                                      o.hour
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "widgets",
                                        o.widgets
                                      ],
                                      /* :: */[
                                        Option$BsConsole.bind(o.metadata, (function (v) {
                                                return /* tuple */[
                                                        "metadata",
                                                        v
                                                      ];
                                              })),
                                        /* :: */[
                                          Option$BsConsole.bind(o.__state, (function (v) {
                                                  return /* tuple */[
                                                          "__state",
                                                          v
                                                        ];
                                                })),
                                          /* :: */[
                                            Option$BsConsole.bind(o.__create, (function (v) {
                                                    return /* tuple */[
                                                            "__create",
                                                            v
                                                          ];
                                                  })),
                                            /* :: */[
                                              Option$BsConsole.bind(o.__modify, (function (v) {
                                                      return /* tuple */[
                                                              "__modify",
                                                              v
                                                            ];
                                                    })),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$34(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          include_users: Json_decode.field("include_users", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          rcpt: Json_decode.optional((function (param) {
                  return Json_decode.field("rcpt", Json_decode.string, param);
                }), json),
          day: Json_decode.field("day", Json_decode.$$int, json),
          period: Json_decode.field("period", Json_decode.string, json),
          timezone: Json_decode.field("timezone", Json_decode.string, json),
          hour: Json_decode.field("hour", Json_decode.$$int, json),
          widgets: Json_decode.field("widgets", Json_decode.string, json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Report = {
  table: "report",
  Key: Key$16,
  to_key: to_key$16,
  to_json: to_json$34,
  of_json: of_json$34
};

function to_json$35(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "uid",
                    o.uid
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* :: */[
                      /* tuple */[
                        "name",
                        o.name
                      ],
                      /* [] */0
                    ]
                  ]
                ]));
}

function of_json$35(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Key$17 = {
  to_json: to_json$35,
  of_json: of_json$35
};

function to_key$17(param) {
  return {
          uid: param.uid,
          universe: param.universe,
          name: param.name
        };
}

function to_json$36(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "uid",
                    o.uid
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* :: */[
                      /* tuple */[
                        "name",
                        o.name
                      ],
                      /* :: */[
                        /* tuple */[
                          "value",
                          o.value
                        ],
                        /* :: */[
                          /* tuple */[
                            "owner",
                            o.owner
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$36(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ResourceOverride = {
  table: "resource_override",
  Key: Key$17,
  to_key: to_key$17,
  to_json: to_json$36,
  of_json: of_json$36
};

function to_json$37(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$37(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$18 = {
  to_json: to_json$37,
  of_json: of_json$37
};

function to_key$18(param) {
  return {
          id: param.id
        };
}

function to_json$38(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* :: */[
                      /* tuple */[
                        "project",
                        o.project
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.regexp, (function (v) {
                                return /* tuple */[
                                        "regexp",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.action, (function (v) {
                                  return /* tuple */[
                                          "action",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.builtin, (function (v) {
                                    return /* tuple */[
                                            "builtin",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "format",
                                o.format
                              ],
                              /* :: */[
                                /* tuple */[
                                  "target",
                                  o.target
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "enable",
                                    o.enable
                                  ],
                                  /* :: */[
                                    Option$BsConsole.bind(o.__state, (function (v) {
                                            return /* tuple */[
                                                    "__state",
                                                    v
                                                  ];
                                          })),
                                    /* :: */[
                                      Option$BsConsole.bind(o.__create, (function (v) {
                                              return /* tuple */[
                                                      "__create",
                                                      v
                                                    ];
                                            })),
                                      /* :: */[
                                        Option$BsConsole.bind(o.__modify, (function (v) {
                                                return /* tuple */[
                                                        "__modify",
                                                        v
                                                      ];
                                              })),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$38(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          regexp: Json_decode.optional((function (param) {
                  return Json_decode.field("regexp", Json_decode.string, param);
                }), json),
          action: Json_decode.optional((function (param) {
                  return Json_decode.field("action", Json_decode.string, param);
                }), json),
          builtin: Json_decode.optional((function (param) {
                  return Json_decode.field("builtin", Json_decode.string, param);
                }), json),
          format: Json_decode.field("format", Json_decode.string, json),
          target: Json_decode.field("target", Json_decode.string, json),
          enable: Json_decode.field("enable", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Scrubber = {
  table: "scrubber",
  Key: Key$18,
  to_key: to_key$18,
  to_json: to_json$38,
  of_json: of_json$38
};

function to_json$39(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* [] */0
                ]));
}

function of_json$39(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$19 = {
  to_json: to_json$39,
  of_json: of_json$39
};

function to_key$19(param) {
  return {
          project: param.project
        };
}

function to_json$40(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "configuration",
                      o.configuration
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.enabled, (function (v) {
                              return /* tuple */[
                                      "enabled",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$40(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          configuration: Json_decode.field("configuration", Json_decode.string, json),
          enabled: Json_decode.optional((function (param) {
                  return Json_decode.field("enabled", Json_decode.$$int, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", (function (x) {
                                return x;
                              }), param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ServerSideActions = {
  table: "server_side_actions",
  Key: Key$19,
  to_key: to_key$19,
  to_json: to_json$40,
  of_json: of_json$40
};

function to_json$41(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* :: */[
                    /* tuple */[
                      "email",
                      o.email
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$41(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

var Key$20 = {
  to_json: to_json$41,
  of_json: of_json$41
};

function to_key$20(param) {
  return {
          universe: param.universe,
          email: param.email
        };
}

function to_json$42(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* :: */[
                    /* tuple */[
                      "email",
                      o.email
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.username, (function (v) {
                              return /* tuple */[
                                      "username",
                                      v
                                    ];
                            })),
                      /* :: */[
                        /* tuple */[
                          "method",
                          o.method_
                        ],
                        /* :: */[
                          /* tuple */[
                            "token",
                            ""
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.metadata, (function (v) {
                                    return /* tuple */[
                                            "metadata",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "role",
                                o.role
                              ],
                              /* :: */[
                                Option$BsConsole.bind(o.__state, (function (v) {
                                        return /* tuple */[
                                                "__state",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__create, (function (v) {
                                          return /* tuple */[
                                                  "__create",
                                                  v
                                                ];
                                        })),
                                  /* :: */[
                                    Option$BsConsole.bind(o.__modify, (function (v) {
                                            return /* tuple */[
                                                    "__modify",
                                                    v
                                                  ];
                                          })),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$42(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json),
          username: Json_decode.optional((function (param) {
                  return Json_decode.field("username", Json_decode.string, param);
                }), json),
          method_: Json_decode.field("method", Json_decode.string, json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          role: Json_decode.field("role", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var SignupPending = {
  table: "signup_pending",
  Key: Key$20,
  to_key: to_key$20,
  to_json: to_json$42,
  of_json: of_json$42
};

function to_json$43(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "domain",
                    o.domain
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$43(json) {
  return {
          domain: Json_decode.field("domain", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json)
        };
}

var Key$21 = {
  to_json: to_json$43,
  of_json: of_json$43
};

function to_key$21(param) {
  return {
          domain: param.domain,
          universe: param.universe
        };
}

function to_json$44(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "domain",
                    o.domain
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe",
                      o.universe
                    ],
                    /* :: */[
                      /* tuple */[
                        "role",
                        o.role
                      ],
                      /* :: */[
                        /* tuple */[
                          "method",
                          o.method_
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.__state, (function (v) {
                                  return /* tuple */[
                                          "__state",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__create, (function (v) {
                                    return /* tuple */[
                                            "__create",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__modify, (function (v) {
                                      return /* tuple */[
                                              "__modify",
                                              v
                                            ];
                                    })),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$44(json) {
  return {
          domain: Json_decode.field("domain", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          role: Json_decode.field("role", Json_decode.string, json),
          method_: Json_decode.field("method", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var SignupWhitelist = {
  table: "signup_whitelist",
  Key: Key$21,
  to_key: to_key$21,
  to_json: to_json$44,
  of_json: of_json$44
};

function to_json$45(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$45(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Key$22 = {
  to_json: to_json$45,
  of_json: of_json$45
};

function to_key$22(param) {
  return {
          id: param.id
        };
}

function to_json$46(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      o.name
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.key, (function (v) {
                              return /* tuple */[
                                      "key",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.cert, (function (v) {
                                return /* tuple */[
                                        "cert",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.chain, (function (v) {
                                  return /* tuple */[
                                          "chain",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.password, (function (v) {
                                    return /* tuple */[
                                            "password",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "method",
                                o.method_
                              ],
                              /* :: */[
                                Option$BsConsole.bind(o.__state, (function (v) {
                                        return /* tuple */[
                                                "__state",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__create, (function (v) {
                                          return /* tuple */[
                                                  "__create",
                                                  v
                                                ];
                                        })),
                                  /* :: */[
                                    Option$BsConsole.bind(o.__modify, (function (v) {
                                            return /* tuple */[
                                                    "__modify",
                                                    v
                                                  ];
                                          })),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$46(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          key: Json_decode.optional((function (param) {
                  return Json_decode.field("key", Json_decode.string, param);
                }), json),
          cert: Json_decode.optional((function (param) {
                  return Json_decode.field("cert", Json_decode.string, param);
                }), json),
          chain: Json_decode.optional((function (param) {
                  return Json_decode.field("chain", Json_decode.string, param);
                }), json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json),
          method_: Json_decode.field("method", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Ssl = {
  table: "ssl",
  Key: Key$22,
  to_key: to_key$22,
  to_json: to_json$46,
  of_json: of_json$46
};

function to_json$47(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "url",
                      o.url
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$47(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

var Key$23 = {
  to_json: to_json$47,
  of_json: of_json$47
};

function to_key$23(param) {
  return {
          project: param.project,
          url: param.url
        };
}

function to_json$48(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "owner",
                      o.owner
                    ],
                    /* :: */[
                      /* tuple */[
                        "url",
                        o.url
                      ],
                      /* :: */[
                        /* tuple */[
                          "title",
                          o.title
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.settings, (function (v) {
                                  return /* tuple */[
                                          "settings",
                                          v
                                        ];
                                })),
                          /* :: */[
                            /* tuple */[
                              "whitelist",
                              o.whitelist
                            ],
                            /* :: */[
                              Option$BsConsole.bind(o.__state, (function (v) {
                                      return /* tuple */[
                                              "__state",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__create, (function (v) {
                                        return /* tuple */[
                                                "__create",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__modify, (function (v) {
                                          return /* tuple */[
                                                  "__modify",
                                                  v
                                                ];
                                        })),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$48(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          url: Json_decode.field("url", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          settings: Json_decode.optional((function (param) {
                  return Json_decode.field("settings", Json_decode.string, param);
                }), json),
          whitelist: Json_decode.field("whitelist", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Symsrv = {
  table: "symsrv",
  Key: Key$23,
  to_key: to_key$23,
  to_json: to_json$48,
  of_json: of_json$48
};

function to_json$49(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "team",
                    o.team
                  ],
                  /* :: */[
                    /* tuple */[
                      "user",
                      o.user
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$49(json) {
  return {
          team: Json_decode.field("team", Json_decode.$$int, json),
          user: Json_decode.field("user", Json_decode.$$int, json)
        };
}

var Key$24 = {
  to_json: to_json$49,
  of_json: of_json$49
};

function to_key$24(param) {
  return {
          team: param.team,
          user: param.user
        };
}

function to_json$50(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "team",
                    o.team
                  ],
                  /* :: */[
                    /* tuple */[
                      "user",
                      o.user
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$50(json) {
  return {
          team: Json_decode.field("team", Json_decode.$$int, json),
          user: Json_decode.field("user", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var TeamMember = {
  table: "team_member",
  Key: Key$24,
  to_key: to_key$24,
  to_json: to_json$50,
  of_json: of_json$50
};

function to_json$51(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* [] */0
                ]));
}

function of_json$51(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json)
        };
}

var Key$25 = {
  to_json: to_json$51,
  of_json: of_json$51
};

function to_key$25(param) {
  return {
          id: param.id
        };
}

function to_json$52(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "id",
                    o.id
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      /* tuple */[
                        "owner",
                        o.owner
                      ],
                      /* :: */[
                        /* tuple */[
                          "expires",
                          o.expires
                        ],
                        /* :: */[
                          /* tuple */[
                            "deleted",
                            o.deleted
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$52(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          expires: Json_decode.field("expires", Json_decode.$$int, json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Token = {
  table: "token",
  Key: Key$25,
  to_key: to_key$25,
  to_json: to_json$52,
  of_json: of_json$52
};

function to_json$53(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* [] */0
                ]));
}

function of_json$53(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json)
        };
}

var Key$26 = {
  to_json: to_json$53,
  of_json: of_json$53
};

function to_key$26(param) {
  return {
          universe: param.universe
        };
}

function to_json$54(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "universe",
                    o.universe
                  ],
                  /* :: */[
                    /* tuple */[
                      "rules",
                      o.rules
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$54(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          rules: Json_decode.field("rules", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var UniverseRetention = {
  table: "universe_retention",
  Key: Key$26,
  to_key: to_key$26,
  to_json: to_json$54,
  of_json: of_json$54
};

function to_json$55(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "owner",
                    o.owner
                  ],
                  /* [] */0
                ]));
}

function of_json$55(json) {
  return {
          owner: Json_decode.field("owner", Json_decode.$$int, json)
        };
}

var Key$27 = {
  to_json: to_json$55,
  of_json: of_json$55
};

function to_key$27(param) {
  return {
          owner: param.owner
        };
}

function to_json$56(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "owner",
                    o.owner
                  ],
                  /* :: */[
                    /* tuple */[
                      "json",
                      o.json
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$56(json) {
  return {
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          json: Json_decode.field("json", Json_decode.string, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var UserFrontendSettings = {
  table: "user_frontend_settings",
  Key: Key$27,
  to_key: to_key$27,
  to_json: to_json$56,
  of_json: of_json$56
};

function to_json$57(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      /* tuple */[
                        "owner",
                        o.owner
                      ],
                      /* [] */0
                    ]
                  ]
                ]));
}

function of_json$57(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json)
        };
}

var Key$28 = {
  to_json: to_json$57,
  of_json: of_json$57
};

function to_key$28(param) {
  return {
          name: param.name,
          project: param.project,
          owner: param.owner
        };
}

function to_json$58(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      /* tuple */[
                        "owner",
                        o.owner
                      ],
                      /* :: */[
                        /* tuple */[
                          "event_type",
                          o.event_type
                        ],
                        /* :: */[
                          /* tuple */[
                            "action",
                            o.action
                          ],
                          /* :: */[
                            Option$BsConsole.bind(o.action_args, (function (v) {
                                    return /* tuple */[
                                            "action_args",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              /* tuple */[
                                "action_type",
                                o.action_type
                              ],
                              /* :: */[
                                /* tuple */[
                                  "period",
                                  o.period
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "sum",
                                    o.sum
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "settings",
                                      o.settings
                                    ],
                                    /* :: */[
                                      Option$BsConsole.bind(o.__state, (function (v) {
                                              return /* tuple */[
                                                      "__state",
                                                      v
                                                    ];
                                            })),
                                      /* :: */[
                                        Option$BsConsole.bind(o.__create, (function (v) {
                                                return /* tuple */[
                                                        "__create",
                                                        v
                                                      ];
                                              })),
                                        /* :: */[
                                          Option$BsConsole.bind(o.__modify, (function (v) {
                                                  return /* tuple */[
                                                          "__modify",
                                                          v
                                                        ];
                                                })),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$58(json) {
  return {
          integration_id: Json_decode.optional((function (param) {
                  return Json_decode.field("integration_id", Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          owner: Json_decode.field("owner", Json_decode.$$int, json),
          event_type: Json_decode.field("event_type", Json_decode.string, json),
          action: Json_decode.field("action", Json_decode.string, json),
          action_args: Json_decode.optional((function (param) {
                  return Json_decode.field("action_args", Json_decode.string, param);
                }), json),
          action_type: Json_decode.field("action_type", Json_decode.string, json),
          period: Json_decode.field("period", Json_decode.$$int, json),
          sum: Json_decode.field("sum", Json_decode.$$int, json),
          settings: Json_decode.field("settings", Json_decode.string, json),
          state: Json_decode.optional((function (param) {
                  return Json_decode.field("state", Json_decode.string, param);
                }), json),
          type_: Json_decode.optional((function (param) {
                  return Json_decode.field("type", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", (function (x) {
                                return x;
                              }), param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var WatcherNew = {
  table: "watcher_new",
  Key: Key$28,
  to_key: to_key$28,
  to_json: to_json$58,
  of_json: of_json$58
};

function to_json$59(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$59(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$29 = {
  to_json: to_json$59,
  of_json: of_json$59
};

function to_key$29(param) {
  return {
          name: param.name,
          project: param.project
        };
}

function to_json$60(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      o.project
                    ],
                    /* :: */[
                      /* tuple */[
                        "id",
                        o.id
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.description, (function (v) {
                                return /* tuple */[
                                        "description",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.unit, (function (v) {
                                  return /* tuple */[
                                          "unit",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.settings, (function (v) {
                                    return /* tuple */[
                                            "settings",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.metadata, (function (v) {
                                      return /* tuple */[
                                              "metadata",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__state, (function (v) {
                                        return /* tuple */[
                                                "__state",
                                                v
                                              ];
                                      })),
                                /* :: */[
                                  Option$BsConsole.bind(o.__create, (function (v) {
                                          return /* tuple */[
                                                  "__create",
                                                  v
                                                ];
                                        })),
                                  /* :: */[
                                    Option$BsConsole.bind(o.__modify, (function (v) {
                                            return /* tuple */[
                                                    "__modify",
                                                    v
                                                  ];
                                          })),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$60(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.$$int, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          unit: Json_decode.optional((function (param) {
                  return Json_decode.field("unit", Json_decode.string, param);
                }), json),
          settings: Json_decode.optional((function (param) {
                  return Json_decode.field("settings", Json_decode.string, param);
                }), json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", (function (x) {
                                return x;
                              }), param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var MetricGroup = {
  table: "metric_group",
  Key: Key$29,
  to_key: to_key$29,
  to_json: to_json$60,
  of_json: of_json$60
};

function to_json$61(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "metric_group",
                      o.metricGroup
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$61(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          metricGroup: Json_decode.field("metric_group", Json_decode.$$int, json)
        };
}

var Key$30 = {
  to_json: to_json$61,
  of_json: of_json$61
};

function to_key$30(param) {
  return {
          name: param.name,
          metricGroup: param.metricGroup
        };
}

function to_json$62(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "metric_group",
                      o.metricGroup
                    ],
                    /* :: */[
                      /* tuple */[
                        "id",
                        o.id
                      ],
                      /* :: */[
                        /* tuple */[
                          "attribute_values",
                          o.attributeValues
                        ],
                        /* :: */[
                          Option$BsConsole.bind(o.metadata, (function (v) {
                                  return /* tuple */[
                                          "metadata",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$62(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          metricGroup: Json_decode.field("metric_group", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          attributeValues: Json_decode.field("attribute_values", Json_decode.string, json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", (function (x) {
                                return x;
                              }), param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var Metric = {
  table: "metric",
  Key: Key$30,
  to_key: to_key$30,
  to_json: to_json$62,
  of_json: of_json$62
};

function to_json$63(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "metric_group",
                      o.metricGroup
                    ],
                    /* [] */0
                  ]
                ]));
}

function of_json$63(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          metricGroup: Json_decode.field("metric_group", Json_decode.$$int, json)
        };
}

var Key$31 = {
  to_json: to_json$63,
  of_json: of_json$63
};

function to_key$31(param) {
  return {
          name: param.name,
          metricGroup: param.metricGroup
        };
}

function to_json$64(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "name",
                    o.name
                  ],
                  /* :: */[
                    /* tuple */[
                      "metric_group",
                      o.metricGroup
                    ],
                    /* :: */[
                      /* tuple */[
                        "id",
                        o.id
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.defaultValue, (function (v) {
                                return /* tuple */[
                                        "default_value",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.metadata, (function (v) {
                                  return /* tuple */[
                                          "metadata",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__state, (function (v) {
                                    return /* tuple */[
                                            "__state",
                                            v
                                          ];
                                  })),
                            /* :: */[
                              Option$BsConsole.bind(o.__create, (function (v) {
                                      return /* tuple */[
                                              "__create",
                                              v
                                            ];
                                    })),
                              /* :: */[
                                Option$BsConsole.bind(o.__modify, (function (v) {
                                        return /* tuple */[
                                                "__modify",
                                                v
                                              ];
                                      })),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$64(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          metricGroup: Json_decode.field("metric_group", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          defaultValue: Json_decode.optional((function (param) {
                  return Json_decode.field("default_value", Json_decode.string, param);
                }), json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", Json_decode.string, param);
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", (function (x) {
                                return x;
                              }), param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var MetricAttribute = {
  table: "metric_attribute",
  Key: Key$31,
  to_key: to_key$31,
  to_json: to_json$64,
  of_json: of_json$64
};

function to_json$65(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* [] */0
                ]));
}

function of_json$65(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$32 = {
  to_json: to_json$65,
  of_json: of_json$65
};

function to_key$32(param) {
  return {
          project: param.project
        };
}

function to_json$66(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "disabled",
                      o.disabled
                    ],
                    /* :: */[
                      /* tuple */[
                        "configuration",
                        o.configuration
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$66(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          configuration: Json_decode.field("configuration", (function (a) {
                  return JSON.parse(Json_decode.string(a));
                }), json),
          disabled: Json_decode.field("disabled", Json_decode.$$int, json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectSampling = {
  table: "project_sampling",
  Key: Key$32,
  to_key: to_key$32,
  to_json: to_json$66,
  of_json: of_json$66
};

function of_json$67(json) {
  return {
          unique: Json_decode.field("unique", (function (param) {
                  return Json_decode.array((function (json) {
                                return {
                                        name: Json_decode.optional((function (param) {
                                                return Json_decode.field("name", Json_decode.string, param);
                                              }), json),
                                        unique_attribute: Caml_array.caml_array_get(Json_decode.field("unique_attributes", (function (param) {
                                                    return Json_decode.array(Json_decode.string, param);
                                                  }), json), 0),
                                        linked_attributes: Json_decode.field("linked_attributes", (function (param) {
                                                return Json_decode.array(Json_decode.string, param);
                                              }), json)
                                      };
                              }), param);
                }), json)
        };
}

function to_json$67(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "unique",
                Belt_Array.map(t.unique, (function (u) {
                        var __x = u.name;
                        return Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "name",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), __x)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "unique_attributes",
                                        [u.unique_attribute]
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "linked_attributes",
                                          u.linked_attributes
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ]);
                      }))
              ],
              /* [] */0
            ]);
}

var Configuration = {
  of_json: of_json$67,
  to_json: to_json$67
};

function to_json$68(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* [] */0
                ]));
}

function of_json$68(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json)
        };
}

var Key$33 = {
  to_json: to_json$68,
  of_json: of_json$68
};

function to_key$33(param) {
  return {
          project: param.project
        };
}

function to_json$69(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "project",
                    o.project
                  ],
                  /* :: */[
                    /* tuple */[
                      "enabled",
                      o.enabled
                    ],
                    /* :: */[
                      /* tuple */[
                        "configuration",
                        to_json$67(o.configuration)
                      ],
                      /* :: */[
                        Option$BsConsole.bind(o.__state, (function (v) {
                                return /* tuple */[
                                        "__state",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__create, (function (v) {
                                  return /* tuple */[
                                          "__create",
                                          v
                                        ];
                                })),
                          /* :: */[
                            Option$BsConsole.bind(o.__modify, (function (v) {
                                    return /* tuple */[
                                            "__modify",
                                            v
                                          ];
                                  })),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$69(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          enabled: Json_decode.field("enabled", Json_decode.$$int, json),
          configuration: Json_decode.field("configuration", (function (a) {
                  return of_json$67(JSON.parse(Json_decode.string(a)));
                }), json),
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var UniqueAggregations = {
  table: "unique_aggregations",
  Configuration: Configuration,
  Key: Key$33,
  to_key: to_key$33,
  to_json: to_json$69,
  of_json: of_json$69
};

function ofJson(json) {
  return {
          includeModules: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("include_modules", Json_decode.bool, param);
                    }), json), true),
          includeSources: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("include_sources", Json_decode.bool, param);
                    }), json), true),
          includeMetadata: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("include_metadata", Json_decode.bool, param);
                    }), json), true)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "include_modules",
                t.includeModules
              ],
              /* :: */[
                /* tuple */[
                  "include_sources",
                  t.includeSources
                ],
                /* :: */[
                  /* tuple */[
                    "include_metadata",
                    t.includeMetadata
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var empty = {
  includeModules: true,
  includeSources: true,
  includeMetadata: true
};

var Configuration$1 = {
  ofJson: ofJson,
  toJson: toJson,
  empty: empty
};

function to_json$70(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "pid",
                    o.pid
                  ],
                  /* [] */0
                ]));
}

function of_json$70(json) {
  return {
          pid: Json_decode.field("pid", Json_decode.$$int, json)
        };
}

var Key$34 = {
  to_json: to_json$70,
  of_json: of_json$70
};

function to_key$34(param) {
  return {
          pid: param.pid
        };
}

function to_json$71(o) {
  return Json_encode.object_(Option$BsConsole.compact(/* :: */[
                  /* tuple */[
                    "pid",
                    o.pid
                  ],
                  /* :: */[
                    /* tuple */[
                      "configuration",
                      JSON.stringify(toJson(o.configuration))
                    ],
                    /* :: */[
                      Option$BsConsole.bind(o.__state, (function (v) {
                              return /* tuple */[
                                      "__state",
                                      v
                                    ];
                            })),
                      /* :: */[
                        Option$BsConsole.bind(o.__create, (function (v) {
                                return /* tuple */[
                                        "__create",
                                        v
                                      ];
                              })),
                        /* :: */[
                          Option$BsConsole.bind(o.__modify, (function (v) {
                                  return /* tuple */[
                                          "__modify",
                                          v
                                        ];
                                })),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]));
}

function of_json$71(json) {
  var tmp;
  try {
    tmp = Json_decode.field("configuration", (function (a) {
            return ofJson(JSON.parse(Json_decode.string(a)));
          }), json);
  }
  catch (exn){
    tmp = empty;
  }
  return {
          pid: Json_decode.field("pid", Json_decode.$$int, json),
          configuration: tmp,
          __state: Json_decode.optional((function (param) {
                  return Json_decode.field("__state", Json_decode.string, param);
                }), json),
          __create: Json_decode.optional((function (param) {
                  return Json_decode.field("__create", Json_decode.$$int, param);
                }), json),
          __modify: Json_decode.optional((function (param) {
                  return Json_decode.field("__modify", Json_decode.$$int, param);
                }), json)
        };
}

var ProjectDeduplicationSettings = {
  table: "project_deduplication_settings",
  Configuration: Configuration$1,
  Key: Key$34,
  to_key: to_key$34,
  to_json: to_json$71,
  of_json: of_json$71
};

exports.Universe = Universe;
exports.Users = Users;
exports.Project = Project;
exports.ApiToken = ApiToken;
exports.Attribute = Attribute;
exports.Deduplication = Deduplication;
exports.InstanceRetention = InstanceRetention;
exports.Limits = Limits;
exports.Listener = Listener;
exports.ProjectFrontendSettings = ProjectFrontendSettings;
exports.Team = Team;
exports.ProjectMemberTeam = ProjectMemberTeam;
exports.ProjectMemberUser = ProjectMemberUser;
exports.ProjectRetention = ProjectRetention;
exports.Query = Query;
exports.Smtp = Smtp;
exports.Report = Report;
exports.ResourceOverride = ResourceOverride;
exports.Scrubber = Scrubber;
exports.ServerSideActions = ServerSideActions;
exports.SignupPending = SignupPending;
exports.SignupWhitelist = SignupWhitelist;
exports.Ssl = Ssl;
exports.Symsrv = Symsrv;
exports.TeamMember = TeamMember;
exports.Token = Token;
exports.UniverseRetention = UniverseRetention;
exports.UserFrontendSettings = UserFrontendSettings;
exports.WatcherNew = WatcherNew;
exports.MetricGroup = MetricGroup;
exports.Metric = Metric;
exports.MetricAttribute = MetricAttribute;
exports.ProjectSampling = ProjectSampling;
exports.UniqueAggregations = UniqueAggregations;
exports.ProjectDeduplicationSettings = ProjectDeduplicationSettings;
/* No side effect */
