// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/lib/js/src/Fetch.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Request$BsConsole = require("./Request.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function makeInit(headers, method__, body) {
  var partial_arg = Caml_option.some(headers);
  var partial_arg$1 = method__;
  var defaultInit = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
    return Fetch.RequestInit.make(partial_arg$1, partial_arg, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
  };
  if (body !== undefined) {
    return Curry.app(defaultInit, [
                Caml_option.some(Caml_option.valFromOption(body)),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              ]);
  } else {
    return Curry.app(defaultInit, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              ]);
  }
}

function getFormatForFile(request) {
  switch (request.tag | 0) {
    case /* FileAttachment */0 :
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "Upload.re",
                30,
                25
              ]
            ];
    case /* SymbolFile */1 :
        return /* Minidump */0;
    case /* TraceFile */2 :
        var maybeResults = new RegExp("\\.(dmp|btt|mdmp)$").exec(request[4].name);
        if (maybeResults === null) {
          return /* Minidump */0;
        }
        var result = Caml_array.caml_array_get(maybeResults, 1);
        if ((result == null) || result !== "btt") {
          return /* Minidump */0;
        } else {
          return /* Btt */1;
        }
    
  }
}

function makeRequestUrl(request) {
  switch (request.tag | 0) {
    case /* FileAttachment */0 :
        var match = request[0];
        return location.origin + (":" + (String(match.port) + ("/api/post?token=" + (match.token + ("&project=" + (match.project + ("&universe=" + (match.universe + ("&object=" + (match.objectID + ("&attachment_name=" + (match.file.name + "&_mod_sync=true"))))))))))));
    case /* SymbolFile */1 :
        return location.origin + (":" + (String(request[1]) + ("/api/post?token=" + (request[0] + ("&project=" + (request[2] + ("&universe=" + (request[3] + ("&tag=" + (request[4] + "&format=symbols&_mod_sync=true&_mod_frontend=true"))))))))));
    case /* TraceFile */2 :
        var match$1 = getFormatForFile(request);
        return location.origin + (":" + (String(request[1]) + ("/api/post?token=" + (request[0] + ("&project=" + (request[2] + ("&universe=" + (request[3] + ("&format=" + ((
                                    match$1 ? "btt" : "minidump"
                                  ) + "&_mod_sync=true"))))))))));
    
  }
}

function makeHeaders(token) {
  var content_type = /* tuple */[
    "content-type",
    "multipart/form-data;"
  ];
  if (token !== undefined) {
    return [
            content_type,
            /* tuple */[
              "X-Coroner-Token",
              token
            ]
          ];
  } else {
    return [content_type];
  }
}

function methodForRequest(_request) {
  return /* Post */2;
}

function headersForRequest(request) {
  switch (request.tag | 0) {
    case /* FileAttachment */0 :
        return makeHeaders(request[0].token);
    case /* SymbolFile */1 :
    case /* TraceFile */2 :
        return makeHeaders(request[0]);
    
  }
}

function getFormOfFile(request) {
  switch (request.tag | 0) {
    case /* FileAttachment */0 :
        var form = new FormData();
        form.append("attachment_name", request[0].file);
        return form;
    case /* SymbolFile */1 :
        var form$1 = new FormData();
        form$1.append("upload_file", request[5]);
        return form$1;
    case /* TraceFile */2 :
        var form$2 = new FormData();
        form$2.append("upload_file", request[4]);
        return form$2;
    
  }
}

function handleHttpResponse(httpRequest, handleResponse) {
  var match = Request$BsConsole.XML_HttpRequest.getReadyState(httpRequest);
  if (match < 4) {
    return ;
  }
  var responseString = Request$BsConsole.XML_HttpRequest.getResponse(httpRequest);
  var tmp;
  try {
    tmp = Json_decode.oneOf(/* :: */[
          (function (json) {
              return {
                      status: /* Failure */0,
                      message: Json_decode.field("error", (function (json) {
                              return Json_decode.field("message", Json_decode.string, json);
                            }), json),
                      object_: undefined,
                      rxid: undefined
                    };
            }),
          /* :: */[
            (function (json) {
                var tmp;
                try {
                  tmp = Json_decode.field("object", Json_decode.string, json);
                }
                catch (exn){
                  tmp = undefined;
                }
                var tmp$1;
                try {
                  tmp$1 = Json_decode.field("_rxid", Json_decode.string, json);
                }
                catch (exn$1){
                  tmp$1 = undefined;
                }
                return {
                        status: /* Success */1,
                        message: Json_decode.field("response", Json_decode.string, json),
                        object_: tmp,
                        rxid: tmp$1
                      };
              }),
            /* [] */0
          ]
        ], JSON.parse(responseString));
  }
  catch (exn){
    tmp = {
      status: /* Failure */0,
      message: "Unknown error",
      object_: undefined,
      rxid: undefined
    };
  }
  return Curry._1(handleResponse, tmp);
}

function makeRequest(request, handleResponse) {
  var form = getFormOfFile(request);
  var httpRequest = new XMLHttpRequest();
  httpRequest.open("Post", makeRequestUrl(request), true);
  Request$BsConsole.XML_HttpRequest.setOnReadyStateChange((function (param) {
          return handleHttpResponse(httpRequest, handleResponse);
        }), httpRequest);
  Request$BsConsole.XML_HttpRequest.setOnError((function (param) {
          return handleHttpResponse(httpRequest, handleResponse);
        }), httpRequest);
  return /* tuple */[
          httpRequest,
          form
        ];
}

exports.makeInit = makeInit;
exports.getFormatForFile = getFormatForFile;
exports.makeRequestUrl = makeRequestUrl;
exports.makeHeaders = makeHeaders;
exports.methodForRequest = methodForRequest;
exports.headersForRequest = headersForRequest;
exports.getFormOfFile = getFormOfFile;
exports.handleHttpResponse = handleHttpResponse;
exports.makeRequest = makeRequest;
/* No side effect */
