// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var InputChip$BsConsole = require("../InputChip.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var AlertsAlertConfigApi$BsConsole = require("./api/AlertsAlertConfigApi.js");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");
var AlertsCreateAlertModal$BsConsole = require("./AlertsCreateAlertModal.js");
var AlertsMuteUntilOptions$BsConsole = require("./AlertsMuteUntilOptions.js");
var AlertsComparisonOperator$BsConsole = require("./AlertsComparisonOperator.js");

var chipStyle = Css.merge(/* :: */[
      AlertsCreateAlertModal$BsConsole.chipLabel,
      /* :: */[
        Css.style(/* :: */[
              Css.maxWidth(Css.px(400)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function toMuteUntilOptionString(t, nowUnix) {
  var future = (nowUnix + AlertsMuteUntilOptions$BsConsole.toSeconds(t)) * 1000;
  var date = new Date(future).toLocaleString();
  return AlertsMuteUntilOptions$BsConsole.toString(t) + (" (" + (date + ")"));
}

function AlertsDetailViewConfig(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  Props.alertConfigs;
  var parsedQuery = Props.parsedQuery;
  Props.alert;
  var alertFullDetails = Props.alertFullDetails;
  var thresholds = Props.thresholds;
  var targets = Props.targets;
  var endpoint = Props.endpoint;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var token = Props.token;
  var refetchAlert = Props.refetchAlert;
  var match = React.useState((function () {
          return false;
        }));
  var setEditModal = match[1];
  var editModal = match[0];
  var match$1 = React.useState((function () {
          return /* OneHour */0;
        }));
  var setSelectedMuteUntilOption = match$1[1];
  var selectedMuteUntilOption = match$1[0];
  var nowUnix = Date.now() / 1000;
  var updateAlertConfig = function (timestamp, enabled, param) {
    if (alertFullDetails === undefined) {
      return ;
    }
    var arg = AlertsAlertConfigApi$BsConsole.update(endpoint, universeName, projectName, alertFullDetails.id, alertFullDetails.name, alertFullDetails.triggers, alertFullDetails.query, alertFullDetails.queryPeriod, timestamp !== undefined ? timestamp : alertFullDetails.muteUntil, alertFullDetails.minNotificationInterval, enabled !== undefined ? enabled : alertFullDetails.enabled, alertFullDetails.targets, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  } else {
                    return Curry._1(refetchAlert, undefined);
                  }
                }));
  };
  if (parsedQuery === undefined) {
    return null;
  }
  if (alertFullDetails === undefined) {
    return null;
  }
  var __x = parsedQuery.fold;
  var errorCountIndex = Curry._3(Crdb$BsConsole.Fold.getAttributeAggregateIndex, AlertsUtils$BsConsole.errorCountAttribute, AlertsUtils$BsConsole.errorCountOperation, __x);
  var factor$prime = parsedQuery.factor;
  var groupBy = factor$prime ? factor$prime[0] : "fingerprint";
  var filters = Curry._1(Crdb$BsConsole.Filters.toArray, parsedQuery.filter);
  var __x$1 = Crdb$BsConsole.Aperture.$$default;
  var aperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, parsedQuery.filter, __x$1);
  var havings = AlertsUtils$BsConsole.getHavingsFromHavingsArr(parsedQuery.havingsArr);
  var newFold = AlertsUtils$BsConsole.foldWithoutErrorCountAgg(parsedQuery.fold);
  var minutes = alertFullDetails.queryPeriod / 60 | 0;
  return React.createElement(React.Fragment, undefined, React.createElement(AlertsCreateAlertModal$BsConsole.make, {
                  config: config,
                  open: editModal,
                  onClose: (function (param) {
                      return Curry._1(setEditModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  init: /* Alert */Block.__(0, [
                      alertFullDetails,
                      parsedQuery,
                      targets
                    ]),
                  handleSave: (function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
                      return AlertsAlertConfigApi$BsConsole.saveAlert(/* SettingsDetailView */2, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7);
                    }),
                  afterSave: refetchAlert,
                  endpoint: endpoint,
                  universeName: universeName,
                  projectName: projectName,
                  token: token,
                  handleChangeUrl: handleChangeUrl,
                  existingAlerts: /* [] */0
                }), React.createElement(Paper.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.padding(Css.px(24)),
                            /* :: */[
                              Css.marginBottom(Css.px(24)),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      children: React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]),
                            children: null
                          }, React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                children: null
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.blue)),
                                          /* :: */[
                                            Css.marginRight(Css.px(8)),
                                            /* [] */0
                                          ]
                                        ])
                                  }, alertFullDetails.enabled ? React.createElement(CheckCircle.default, {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.green)),
                                                /* [] */0
                                              ])
                                        }) : React.createElement(ErrorOutline.default, {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                /* [] */0
                                              ])
                                        })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.height(Css.px(24)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Button */10,
                                        color: Colors$BsConsole.grey0,
                                        children: I18N$BsConsole.showSkip(alertFullDetails.enabled ? I18N$BsConsole.get(undefined, "Alert is active") : I18N$BsConsole.get(undefined, "Alert is inactive"))
                                      })), React.createElement(Button.default, {
                                    variant: "text",
                                    color: "primary",
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginLeft(Css.px(10)),
                                            /* :: */[
                                              Css.marginTop(Css.px(-3)),
                                              /* [] */0
                                            ]
                                          ])
                                    },
                                    onClick: (function (param) {
                                        return updateAlertConfig(undefined, !alertFullDetails.enabled, undefined);
                                      }),
                                    children: I18N$BsConsole.showSkip(alertFullDetails.enabled ? I18N$BsConsole.get(undefined, "Disable") : I18N$BsConsole.get(undefined, "Enable"))
                                  })), React.createElement("div", undefined, React.createElement("div", undefined, React.createElement(Button.default, {
                                        variant: "contained",
                                        color: "primary",
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.marginLeft(Css.px(10)),
                                                /* :: */[
                                                  Css.marginTop(Css.px(-3)),
                                                  /* [] */0
                                                ]
                                              ])
                                        },
                                        onClick: (function (param) {
                                            return Curry._1(setEditModal, (function (param) {
                                                          return !editModal;
                                                        }));
                                          }),
                                        children: I18N$BsConsole.show(undefined, "Edit alert")
                                      }), React.createElement(Button.default, {
                                        variant: "contained",
                                        color: "primary",
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.marginLeft(Css.px(10)),
                                                /* :: */[
                                                  Css.marginTop(Css.px(-3)),
                                                  /* [] */0
                                                ]
                                              ])
                                        },
                                        onClick: (function (param) {
                                            return Util$BsConsole.openUrl(Route$BsConsole.pathForRoute(Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                                              /* Floating */0,
                                                                              /* Week */2
                                                                            ])), aperture)), Caml_option.some(havings), parsedQuery.factor, Caml_option.some(newFold), undefined, undefined, undefined, undefined)));
                                          }),
                                        children: I18N$BsConsole.show(undefined, "View in explore")
                                      }))))
                    }), React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.px(18)),
                            /* :: */[
                              Css.marginBottom(Css.px(18)),
                              /* [] */0
                            ]
                          ])
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      flexWrap: /* wrap */-822134326,
                      children: null
                    }, React.createElement("div", {
                          className: chipStyle
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Button */10,
                              className: Css.style(/* :: */[
                                    Css.marginTop(Css.px(5)),
                                    /* :: */[
                                      Css.marginRight(Css.px(5)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(5)),
                                        /* :: */[
                                          Css.height(Css.px(12)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]),
                              color: Colors$BsConsole.grey0,
                              children: minutes === 1 ? I18N$BsConsole.show(undefined, "Over the last minute") : I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Over the last") + (" " + (String(minutes) + (" " + I18N$BsConsole.get(undefined, "minutes")))))
                            })), React.createElement("div", {
                          className: chipStyle
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Button */10,
                              className: Css.style(/* :: */[
                                    Css.marginTop(Css.px(5)),
                                    /* :: */[
                                      Css.marginRight(Css.px(5)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(5)),
                                        /* :: */[
                                          Css.height(Css.px(12)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]),
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "for any") + (" " + ((
                                        groupBy === "NONE" ? I18N$BsConsole.get(undefined, "error") : groupBy
                                      ) + " ")))
                            })), filters.length !== 0 || Curry._1(Crdb$BsConsole.Havings.toArray, havings).length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Button */10,
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(5)),
                                      /* :: */[
                                        Css.marginRight(Css.px(5)),
                                        /* :: */[
                                          Css.height(Css.px(12)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                color: Colors$BsConsole.grey0,
                                children: I18N$BsConsole.show(undefined, "with errors filtered to")
                              }), Belt_Array.map(filters, (function (filter) {
                                  var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                                  var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                                  var filterValue = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(filterOperation)));
                                  var filterString = filterAttribute + (" " + (Crdb$BsConsole.FilterOperation.toString(filterOperation) + (" " + filterValue)));
                                  return React.createElement(React.Fragment, {
                                              children: React.createElement(Tooltip.default, {
                                                    children: React.createElement("div", {
                                                          key: filterAttribute,
                                                          className: Css.merge(/* :: */[
                                                                AlertsCreateAlertModal$BsConsole.chipLabel,
                                                                /* :: */[
                                                                  Css.style(/* :: */[
                                                                        Css.maxWidth(Css.px(250)),
                                                                        /* [] */0
                                                                      ]),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }, React.createElement(BtTypography$BsConsole.make, {
                                                              variant: /* Button */10,
                                                              className: Css.style(/* :: */[
                                                                    Css.marginTop(Css.px(5)),
                                                                    /* :: */[
                                                                      Css.marginRight(Css.px(5)),
                                                                      /* :: */[
                                                                        Css.marginBottom(Css.px(5)),
                                                                        /* :: */[
                                                                          Css.height(Css.px(12)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]),
                                                              color: Colors$BsConsole.grey0,
                                                              children: I18N$BsConsole.showSkip(filterString)
                                                            })),
                                                    title: filterString.length > 48 ? filterString : ""
                                                  }),
                                              key: filterString
                                            });
                                })), Belt_Array.map(Curry._1(Crdb$BsConsole.Havings.toArray, havings), (function (having) {
                                  var match = InputChip$BsConsole.Filter.getHavingInfo(having);
                                  var attribute = match[0];
                                  if (attribute === undefined) {
                                    return null;
                                  }
                                  var att = Belt_Option.getWithDefault(Belt_Array.get(attribute.split(";"), 0), "no attr");
                                  var havingString = att + (" | " + (match[1] + (" " + (Util$BsConsole.filterOpMap(match[2]) + (" " + match[3])))));
                                  return React.createElement(Tooltip.default, {
                                              children: React.createElement("div", {
                                                    key: havingString,
                                                    className: Css.merge(/* :: */[
                                                          AlertsCreateAlertModal$BsConsole.chipLabel,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.maxWidth(Css.px(250)),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, React.createElement(BtTypography$BsConsole.make, {
                                                        variant: /* Button */10,
                                                        className: Css.style(/* :: */[
                                                              Css.marginTop(Css.px(5)),
                                                              /* :: */[
                                                                Css.marginRight(Css.px(5)),
                                                                /* :: */[
                                                                  Css.marginBottom(Css.px(5)),
                                                                  /* :: */[
                                                                    Css.height(Css.px(12)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]),
                                                        color: Colors$BsConsole.grey0,
                                                        children: I18N$BsConsole.showSkip(havingString)
                                                      })),
                                              title: havingString.length > 48 ? havingString : ""
                                            });
                                }))) : null), React.createElement(Col2$BsConsole.make, {
                      children: React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.map(thresholds, (function (condition) {
                                      return React.createElement(React.Fragment, {
                                                  children: React.createElement(Row2$BsConsole.make, {
                                                        alignItems: /* center */98248149,
                                                        flexWrap: /* wrap */-822134326,
                                                        className: Css.style(/* :: */[
                                                              Css.marginTop(Css.px(5)),
                                                              /* [] */0
                                                            ]),
                                                        children: null
                                                      }, React.createElement(BtTypography$BsConsole.make, {
                                                            variant: /* Button */10,
                                                            className: Css.style(/* :: */[
                                                                  Css.marginTop(Css.px(5)),
                                                                  /* :: */[
                                                                    Css.marginRight(Css.px(5)),
                                                                    /* :: */[
                                                                      Css.marginBottom(Css.px(5)),
                                                                      /* :: */[
                                                                        Css.height(Css.px(12)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]),
                                                            color: Colors$BsConsole.grey0,
                                                            children: condition >= 141150399 ? I18N$BsConsole.show(undefined, "Send critical alert if") : I18N$BsConsole.show(undefined, "Send warning alert if")
                                                          }), Belt_List.toArray(Belt_List.mapWithIndex(alertFullDetails.triggers, (function (index, trigger) {
                                                                  var value = condition >= 141150399 ? trigger.criticalThreshold | 0 : trigger.warningThreshold | 0;
                                                                  var uniqueString = I18N$BsConsole.get(undefined, "unique") + (" " + (trigger.aggregation.column + (" " + I18N$BsConsole.get(undefined, "see a crash"))));
                                                                  return React.createElement(React.Fragment, {
                                                                              children: null,
                                                                              key: trigger.aggregation.column + String(trigger.aggregation.index)
                                                                            }, React.createElement("div", {
                                                                                  className: chipStyle
                                                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                                                      variant: /* Button */10,
                                                                                      className: Css.style(/* :: */[
                                                                                            Css.marginTop(Css.px(5)),
                                                                                            /* :: */[
                                                                                              Css.marginRight(Css.px(5)),
                                                                                              /* :: */[
                                                                                                Css.marginBottom(Css.px(5)),
                                                                                                /* :: */[
                                                                                                  Css.height(Css.px(12)),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]),
                                                                                      color: Colors$BsConsole.grey0,
                                                                                      children: I18N$BsConsole.showSkip(String(value) + (" " + (AlertsComparisonOperator$BsConsole.toStringAlternative(AlertsComparisonOperator$BsConsole.fromValue(trigger.comparisonOperator)) + (" " + (
                                                                                                  errorCountIndex !== undefined && trigger.aggregation.column === AlertsUtils$BsConsole.errorCountAttribute && trigger.aggregation.index === errorCountIndex ? (
                                                                                                      value > 1 ? I18N$BsConsole.get(undefined, "new errors") : I18N$BsConsole.get(undefined, "new error")
                                                                                                    ) + (" " + (
                                                                                                        value > 1 ? I18N$BsConsole.get(undefined, "occur") : I18N$BsConsole.get(undefined, "occurs")
                                                                                                      )) : uniqueString
                                                                                                )))))
                                                                                    })), index === (Belt_List.length(alertFullDetails.triggers) - 1 | 0) ? null : React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.marginRight(Css.px(5)),
                                                                                          /* [] */0
                                                                                        ])
                                                                                  }, React.createElement(BtTypography$BsConsole.make, {
                                                                                        variant: /* Button */10,
                                                                                        className: Css.style(/* :: */[
                                                                                              Css.marginTop(Css.px(5)),
                                                                                              /* :: */[
                                                                                                Css.marginRight(Css.px(5)),
                                                                                                /* :: */[
                                                                                                  Css.marginBottom(Css.px(5)),
                                                                                                  /* :: */[
                                                                                                    Css.height(Css.px(12)),
                                                                                                    /* [] */0
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]),
                                                                                        color: Colors$BsConsole.grey0,
                                                                                        children: I18N$BsConsole.show(undefined, "or")
                                                                                      })));
                                                                })))),
                                                  key: condition
                                                });
                                    }))), React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                className: Css.style(/* :: */[
                                      Css.marginTop(Css.px(12)),
                                      /* :: */[
                                        Css.marginRight(Css.px(5)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(5)),
                                          /* :: */[
                                            Css.height(Css.px(12)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: null
                              }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Button */10,
                                        color: Colors$BsConsole.grey0,
                                        children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Integrations that will fire when alert is triggered") + ":")
                                      })), targets.length !== 0 ? Belt_Array.map(targets, (function (target) {
                                        return React.createElement("div", {
                                                    key: target.id,
                                                    className: Css.style(/* :: */[
                                                          Css.marginLeft(Css.px(8)),
                                                          /* [] */0
                                                        ])
                                                  }, React.createElement(BtTypography$BsConsole.make, {
                                                        variant: /* Button */10,
                                                        color: Colors$BsConsole.grey0,
                                                        children: I18N$BsConsole.showSkip(target.name)
                                                      }));
                                      })) : React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginLeft(Css.px(8)),
                                            /* [] */0
                                          ])
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Button */10,
                                          color: Colors$BsConsole.grey0,
                                          children: I18N$BsConsole.show(undefined, "None")
                                        }))), React.createElement("div", undefined, alertFullDetails.minNotificationInterval > 60 ? React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Button */10,
                                      color: Colors$BsConsole.grey0,
                                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                /* String_literal */Block.__(11, [
                                                    "Notifications spaced by ",
                                                    /* Int */Block.__(4, [
                                                        /* Int_d */0,
                                                        /* No_padding */0,
                                                        /* No_precision */0,
                                                        /* String_literal */Block.__(11, [
                                                            " minutes",
                                                            /* End_of_format */0
                                                          ])
                                                      ])
                                                  ]),
                                                "Notifications spaced by %d minutes"
                                              ]), (alertFullDetails.minNotificationInterval | 0) / 60 | 0)
                                    }) : React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Button */10,
                                      color: Colors$BsConsole.grey0,
                                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                /* String_literal */Block.__(11, [
                                                    "Notifications spaced by ",
                                                    /* Int */Block.__(4, [
                                                        /* Int_d */0,
                                                        /* No_padding */0,
                                                        /* No_precision */0,
                                                        /* String_literal */Block.__(11, [
                                                            " seconds",
                                                            /* End_of_format */0
                                                          ])
                                                      ])
                                                  ]),
                                                "Notifications spaced by %d seconds"
                                              ]), alertFullDetails.minNotificationInterval | 0)
                                    })))
                    }), React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.px(18)),
                            /* :: */[
                              Css.marginBottom(Css.px(18)),
                              /* [] */0
                            ]
                          ])
                    }), React.createElement(Col2$BsConsole.make, {
                      children: null
                    }, alertFullDetails.muteUntil > nowUnix ? React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            children: null
                          }, React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                className: Css.style(/* :: */[
                                      Css.width(Css.px(375)),
                                      /* [] */0
                                    ]),
                                children: null
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.blue)),
                                          /* :: */[
                                            Css.marginRight(Css.px(8)),
                                            /* [] */0
                                          ]
                                        ])
                                  }, React.createElement(Info.default, { })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.height(Css.px(24)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Button */10,
                                        color: Colors$BsConsole.grey0,
                                        children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Alert is muted until") + (" " + new Date(alertFullDetails.muteUntil * 1000).toLocaleString()))
                                      }))), React.createElement(Button.default, {
                                variant: "outlined",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginTop(Css.px(10)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(10)),
                                          /* :: */[
                                            Css.marginLeft(Css.px(10)),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                },
                                onClick: (function (param) {
                                    return updateAlertConfig(0, undefined, undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Unmute")
                              })) : null, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: Css.style(/* :: */[
                                    Css.width(Css.px(375)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.px(8)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Mute until")), React.createElement(Select.default, {
                                  id: "select-timeframe",
                                  value: selectedMuteUntilOption,
                                  disabled: false,
                                  onChange: (function ($$event, param) {
                                      var value = $$event.target.value;
                                      return Curry._1(setSelectedMuteUntilOption, (function (param) {
                                                    return value;
                                                  }));
                                    }),
                                  renderValue: (function (v) {
                                      return React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.paddingLeft(Css.px(10)),
                                                        /* [] */0
                                                      ])
                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                      variant: /* Caption */11,
                                                      children: I18N$BsConsole.showSkip(toMuteUntilOptionString(v, nowUnix))
                                                    }));
                                    }),
                                  disableUnderline: true,
                                  classes: {
                                    root: AlertsUtils$BsConsole.selectStyle
                                  },
                                  children: Belt_Array.map(AlertsMuteUntilOptions$BsConsole.options, (function (v) {
                                          return React.createElement(MenuItem.default, {
                                                      value: v,
                                                      children: React.createElement(BtTypography$BsConsole.make, {
                                                            variant: /* Caption */11,
                                                            children: I18N$BsConsole.showSkip(toMuteUntilOptionString(v, nowUnix))
                                                          }),
                                                      key: AlertsMuteUntilOptions$BsConsole.toString(v)
                                                    });
                                        }))
                                })), React.createElement(Button.default, {
                              variant: "outlined",
                              color: "primary",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.px(10)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(10)),
                                        /* :: */[
                                          Css.marginLeft(Css.px(10)),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              },
                              onClick: (function (param) {
                                  var timestamp = nowUnix + AlertsMuteUntilOptions$BsConsole.toSeconds(selectedMuteUntilOption);
                                  return updateAlertConfig(timestamp, undefined, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Mute")
                            })))));
}

var make = AlertsDetailViewConfig;

exports.chipStyle = chipStyle;
exports.toMuteUntilOptionString = toMuteUntilOptionString;
exports.make = make;
/* chipStyle Not a pure module */
