// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Col2$BsConsole = require("./components/Col2.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Image$BsConsole = require("./components/Image.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("./Services.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var ApiDemoProject$BsConsole = require("./ApiDemoProject.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var Tooltip = require("@material-ui/core/Tooltip");
var GenerateDemoProject$BsConsole = require("./GenerateDemoProject.js");
var BacktraceErrorDialog$BsConsole = require("./BacktraceErrorDialog.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function callToActionContainer(primaryMain) {
  return Css.style(/* :: */[
              Css.padding(Css.em(1)),
              /* :: */[
                Css.paddingRight(Css.px(55)),
                /* :: */[
                  Css.border(Css.px(1), /* solid */12956715, primaryMain),
                  /* :: */[
                    Css.color(primaryMain),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.justifyContent(/* spaceBetween */516682146),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.marginBottom(Css.px(16)),
                            /* :: */[
                              Css.position(/* relative */903134412),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function leftBlueBar(primaryMain) {
  return Css.style(/* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.left(Css.px(0)),
                /* :: */[
                  Css.height(Css.pct(100)),
                  /* :: */[
                    Css.width(Css.px(7)),
                    /* :: */[
                      Css.backgroundColor(primaryMain),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var bannerTextAndIconContainer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* [] */0
    ]);

var bannerInfoIcon = Css.style(/* :: */[
      Css.marginRight(Css.em(1)),
      /* [] */0
    ]);

var bannerTextContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

function callToActionButtonPrimary(primaryMain) {
  return Css.style(/* :: */[
              Css.height(Css.px(40)),
              /* :: */[
                Css.width(/* auto */-1065951377),
                /* :: */[
                  Css.backgroundColor(primaryMain),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.white)),
                    /* :: */[
                      Css.marginLeft(Css.px(7)),
                      /* :: */[
                        Css.marginRight(Css.px(7)),
                        /* :: */[
                          Css.overflowY(/* hidden */-862584982),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var buttonWithIcon = Css.style(/* :: */[
      Css.paddingLeft(Css.px(5)),
      /* :: */[
        Css.paddingRight(Css.px(20)),
        /* [] */0
      ]
    ]);

var buttonWithOutIcon = Css.style(/* :: */[
      Css.paddingLeft(Css.px(20)),
      /* :: */[
        Css.paddingRight(Css.px(20)),
        /* [] */0
      ]
    ]);

var bannerButtonsContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.minWidth(Css.px(400)),
          /* [] */0
        ]
      ]
    ]);

var closeBanner = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(5)),
        /* :: */[
          Css.right(Css.px(5)),
          /* :: */[
            Css.width(Css.px(35)),
            /* :: */[
              Css.height(Css.px(35)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bannerTextMain = Css.style(/* :: */[
      Css.fontSize(Css.em(1.1)),
      /* :: */[
        Css.lineHeight(Css.em(1.5)),
        /* :: */[
          Css.color(Css.rgba(0, 0, 0, 0.87)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  500
                ]),
            /* :: */[
              Css.marginBottom(Css.px(5)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bannerTextSecondary = Css.style(/* :: */[
      Css.fontSize(Css.em(0.9)),
      /* :: */[
        Css.lineHeight(Css.em(1.5)),
        /* :: */[
          Css.color(Css.rgba(0, 0, 0, 0.87)),
          /* [] */0
        ]
      ]
    ]);

var flexContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var errorContainer = Css.merge(/* :: */[
      flexContainer,
      /* :: */[
        Css.style(/* :: */[
              Css.width(Css.vw(100)),
              /* :: */[
                Css.height(Css.vh(100)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var errorPaper = Css.merge(/* :: */[
      flexContainer,
      /* :: */[
        Css.style(/* :: */[
              Css.padding(Css.px(100)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  callToActionContainer: callToActionContainer,
  leftBlueBar: leftBlueBar,
  bannerTextAndIconContainer: bannerTextAndIconContainer,
  bannerInfoIcon: bannerInfoIcon,
  bannerTextContainer: bannerTextContainer,
  callToActionButtonPrimary: callToActionButtonPrimary,
  buttonWithIcon: buttonWithIcon,
  buttonWithOutIcon: buttonWithOutIcon,
  bannerButtonsContainer: bannerButtonsContainer,
  closeBanner: closeBanner,
  bannerTextMain: bannerTextMain,
  bannerTextSecondary: bannerTextSecondary,
  flexContainer: flexContainer,
  errorContainer: errorContainer,
  errorPaper: errorPaper
};

function getBannerInfo(bannerVariant, handleChangeUrl, projectName, maybePlatform) {
  var defaultTitle = "Explore Backtrace with a demo project";
  var defaultDescription = "Try our web, gaming, or mobile native demos to learn how Backtrace can help you uncover crash and error data, or start from scratch by following our ";
  if (bannerVariant !== 1) {
    if (bannerVariant !== 0) {
      if (handleChangeUrl !== undefined && projectName !== undefined) {
        return {
                title: defaultTitle,
                description: defaultDescription,
                linkTitle: "Integration Guides",
                handleLinkClick: (function (param) {
                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                  projectName,
                                  {
                                    doc: undefined
                                  }
                                ]));
                  })
              };
      }
      
    } else if (handleChangeUrl === undefined) {
      projectName !== undefined;
    }
    
  } else if (handleChangeUrl === undefined && projectName === undefined && maybePlatform !== undefined) {
    return {
            title: "Explore Backtrace with a " + (ApiDemoProject$BsConsole.Platform.typeToLabel(maybePlatform) + " demo project"),
            description: "Want to see how Backtrace handles " + (ApiDemoProject$BsConsole.Platform.typeToLabel(maybePlatform) + " errors and crashes?"),
            linkTitle: undefined,
            handleLinkClick: undefined
          };
  }
  return {
          title: defaultTitle,
          description: "Try our web, gaming, or mobile native demos to learn how Backtrace can help you uncover crash and error data, or start from scratch by following our Integration Guides.",
          linkTitle: undefined,
          handleLinkClick: undefined
        };
}

var BannerVariant = {
  getBannerInfo: getBannerInfo
};

function DemoProjectPrompt$LoadingState(Props) {
  var config = Props.config;
  var token = Props.token;
  var platform = Props.platform;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setDemoGenerationFailed = match[1];
  React.useEffect((function () {
          if (platform !== /* Default */4) {
            ApiDemoProject$BsConsole.DemoGenerator.generateDemoProject(config, token, platform, (function (proj) {
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* GenerateDemoProjectUsingLink */Block.__(4, [ApiDemoProject$BsConsole.Platform.typeToString(platform)])]));
                    var __x = window;
                    __x.location = "/p/" + proj;
                    
                  }), (function (param) {
                    return Curry._1(setDemoGenerationFailed, (function (param) {
                                  return true;
                                }));
                  }));
          } else {
            Curry._1(setDemoGenerationFailed, (function (param) {
                    return true;
                  }));
          }
          
        }), ([]));
  if (match[0]) {
    return React.createElement(BacktraceErrorDialog$BsConsole.make, {
                title: "Something went wrong",
                description: "Encountered an unknown error while generating demo project. Please try again later.",
                buttonText: "Back to homepage",
                onClick: (function (param) {
                    return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                    referrer: undefined,
                                    token: undefined,
                                    redirect: undefined,
                                    method: undefined
                                  }]));
                  })
              });
  } else {
    return React.createElement(Col2$BsConsole.make, {
                alignItems: /* center */98248149,
                justifyContent: /* center */98248149,
                className: Css.style(/* :: */[
                      Css.height(Css.vh(100)),
                      /* [] */0
                    ]),
                children: null
              }, React.createElement(Row2$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.show(undefined, "Generating project, please wait...")
                  }), React.createElement(CircularProgress.default, { }));
  }
}

function make(config, token, platform, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(DemoProjectPrompt$LoadingState, {
              config: config,
              token: token,
              platform: platform,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var LoadingState = {
  make: DemoProjectPrompt$LoadingState,
  Jsx2: Jsx2
};

function DemoProjectPrompt$DemoProjectBanner$LoadingDemoProjectBanner(Props) {
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.height(Css.pct(100)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(CircularProgress.default, {
                  className: Css.style(/* :: */[
                        Css.marginRight(Css.px(25)),
                        /* [] */0
                      ])
                }), I18N$BsConsole.show(undefined, "Generating project, please wait..."));
}

var LoadingDemoProjectBanner = {
  make: DemoProjectPrompt$DemoProjectBanner$LoadingDemoProjectBanner
};

function DemoProjectPrompt$DemoProjectBanner(Props) {
  var config = Props.config;
  var token = Props.token;
  var styles = Props.styles;
  var bannerVariant = Props.bannerVariant;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var setShowBanner = Props.setShowBanner;
  var maybePlatformOpt = Props.maybePlatform;
  var maybePlatform = maybePlatformOpt !== undefined ? Caml_option.valFromOption(maybePlatformOpt) : undefined;
  var match = React.useState((function () {
          return false;
        }));
  var setLoadingDemoData = match[1];
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Css.hex(Colors$BsConsole.deoctothorpe(palette.primary.main));
  var bannerInfo = getBannerInfo(bannerVariant, handleChangeUrl, projectName, maybePlatform);
  var tmp;
  if (match[0]) {
    tmp = React.createElement(DemoProjectPrompt$DemoProjectBanner$LoadingDemoProjectBanner, { });
  } else {
    var match$1 = bannerInfo.linkTitle;
    var match$2 = bannerInfo.handleLinkClick;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
              ariaLabel: "Close banner",
              className: closeBanner,
              onClick: (function (param) {
                  return Curry._1(setShowBanner, (function (param) {
                                return false;
                              }));
                }),
              children: React.createElement(Close.default, {
                    fontSize: "small"
                  })
            }), React.createElement("span", {
              className: leftBlueBar(primaryMain)
            }), React.createElement(Row2$BsConsole.make, {
              className: bannerTextAndIconContainer,
              children: null
            }, React.createElement("span", {
                  className: bannerInfoIcon
                }, React.createElement(Info.default, { })), React.createElement("div", {
                  className: bannerTextContainer
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Subtitle1 */6,
                      className: bannerTextMain,
                      children: I18N$BsConsole.show(undefined, bannerInfo.title)
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Subtitle1 */6,
                      className: bannerTextSecondary,
                      children: null
                    }, I18N$BsConsole.show(undefined, bannerInfo.description), match$1 !== undefined && match$2 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                                className: Css.style(/* :: */[
                                      Css.cursor(/* pointer */-786317123),
                                      /* [] */0
                                    ]),
                                onClick: (function (param) {
                                    return Curry._1(match$2, undefined);
                                  })
                              }, I18N$BsConsole.show(undefined, match$1)), React.createElement("span", undefined, I18N$BsConsole.show(undefined, "."))) : null))), React.createElement("div", {
              className: bannerButtonsContainer
            }, maybePlatform !== undefined ? React.createElement(Button.default, {
                    variant: "raised",
                    className: Css.merge(/* :: */[
                          callToActionButtonPrimary(primaryMain),
                          /* :: */[
                            buttonWithOutIcon,
                            /* [] */0
                          ]
                        ]),
                    onClick: (function (_event) {
                        return GenerateDemoProject$BsConsole.handleGenerateDemoProject(maybePlatform, setLoadingDemoData, config, token, /* Banner */1);
                      }),
                    children: I18N$BsConsole.show(undefined, "Generate sample project")
                  }) : React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                        placement: "bottom",
                        children: React.createElement(Button.default, {
                              variant: "raised",
                              className: Css.merge(/* :: */[
                                    callToActionButtonPrimary(primaryMain),
                                    /* :: */[
                                      buttonWithIcon,
                                      /* [] */0
                                    ]
                                  ]),
                              onClick: (function (_event) {
                                  return GenerateDemoProject$BsConsole.handleGenerateDemoProject(/* React */2, setLoadingDemoData, config, token, /* Banner */1);
                                }),
                              children: null
                            }, React.createElement(Image$BsConsole.make, {
                                  className: Css.style(/* :: */[
                                        Css.height(Css.px(20)),
                                        /* :: */[
                                          Css.paddingLeft(Css.px(12)),
                                          /* :: */[
                                            Css.paddingRight(Css.px(10)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  src: "https://res.cloudinary.com/backtrace/image/upload/e_colorize,co_white/v1694802390/react.png",
                                  alt: "React Logo"
                                }), I18N$BsConsole.show(undefined, "Web")),
                        title: "Generate a sample React project"
                      }), React.createElement(Tooltip.default, {
                        placement: "bottom",
                        children: React.createElement(Button.default, {
                              variant: "raised",
                              className: Css.merge(/* :: */[
                                    callToActionButtonPrimary(primaryMain),
                                    /* :: */[
                                      buttonWithIcon,
                                      /* [] */0
                                    ]
                                  ]),
                              onClick: (function (_event) {
                                  return GenerateDemoProject$BsConsole.handleGenerateDemoProject(/* Unity */0, setLoadingDemoData, config, token, /* Banner */1);
                                }),
                              children: null
                            }, React.createElement(Image$BsConsole.make, {
                                  className: Css.style(/* :: */[
                                        Css.height(Css.px(45)),
                                        /* [] */0
                                      ]),
                                  src: "https://res.cloudinary.com/backtrace/image/upload/e_colorize,co_white/v1651233860/U_Logo_Black_RGB-small.png",
                                  alt: "Unity Logo"
                                }), I18N$BsConsole.show(undefined, "Gaming")),
                        title: "Generate a sample Unity project"
                      }), React.createElement(Tooltip.default, {
                        placement: "bottom",
                        children: React.createElement(Button.default, {
                              variant: "raised",
                              className: Css.merge(/* :: */[
                                    callToActionButtonPrimary(primaryMain),
                                    /* :: */[
                                      buttonWithIcon,
                                      /* [] */0
                                    ]
                                  ]),
                              onClick: (function (_event) {
                                  return GenerateDemoProject$BsConsole.handleGenerateDemoProject(/* ReactNative */3, setLoadingDemoData, config, token, /* Banner */1);
                                }),
                              children: null
                            }, React.createElement(Image$BsConsole.make, {
                                  className: Css.style(/* :: */[
                                        Css.height(Css.px(20)),
                                        /* :: */[
                                          Css.paddingLeft(Css.px(12)),
                                          /* :: */[
                                            Css.paddingRight(Css.px(10)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  src: "https://res.cloudinary.com/backtrace/image/upload/e_colorize,co_white/v1694802390/react.png",
                                  alt: "React Native Logo"
                                }), I18N$BsConsole.show(undefined, "Mobile")),
                        title: "Generate a sample React Native project"
                      }))));
  }
  return React.createElement(Paper.default, {
              className: Css.merge(/* :: */[
                    callToActionContainer(primaryMain),
                    /* :: */[
                      styles,
                      /* [] */0
                    ]
                  ]),
              children: tmp
            });
}

var DemoProjectBanner = {
  LoadingDemoProjectBanner: LoadingDemoProjectBanner,
  make: DemoProjectPrompt$DemoProjectBanner
};

function DemoProjectPrompt(Props) {
  var config = Props.config;
  var token = Props.token;
  var bannerVariant = Props.bannerVariant;
  var maybePlatform = Props.maybePlatform;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var stylesOpt = Props.styles;
  var styles = stylesOpt !== undefined ? stylesOpt : "";
  var isOverviewServiceAvailable = Belt_Option.isSome(Services$BsConsole.getEndpoint(undefined)(config.services, "overview"));
  var match = React.useState((function () {
          return true;
        }));
  if (isOverviewServiceAvailable && match[0]) {
    return React.createElement(DemoProjectPrompt$DemoProjectBanner, {
                config: config,
                token: token,
                styles: styles,
                bannerVariant: bannerVariant,
                projectName: projectName,
                handleChangeUrl: handleChangeUrl,
                setShowBanner: match[1],
                maybePlatform: maybePlatform
              });
  } else {
    return null;
  }
}

function make$1(config, token, bannerVariant, projectName, handleChangeUrl, maybePlatform, styles, _children) {
  var tmp = {
    config: config,
    token: token,
    bannerVariant: bannerVariant
  };
  if (maybePlatform !== undefined) {
    tmp.maybePlatform = Caml_option.valFromOption(maybePlatform);
  }
  if (handleChangeUrl !== undefined) {
    tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
  }
  if (projectName !== undefined) {
    tmp.projectName = Caml_option.valFromOption(projectName);
  }
  if (styles !== undefined) {
    tmp.styles = Caml_option.valFromOption(styles);
  }
  return ReasonReactCompat.wrapReactForReasonReact(DemoProjectPrompt, tmp, _children);
}

var Jsx2$1 = {
  make: make$1
};

var make$2 = DemoProjectPrompt;

exports.Styles = Styles;
exports.BannerVariant = BannerVariant;
exports.LoadingState = LoadingState;
exports.DemoProjectBanner = DemoProjectBanner;
exports.make = make$2;
exports.Jsx2 = Jsx2$1;
/* bannerTextAndIconContainer Not a pure module */
