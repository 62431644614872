// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../Colors.js");
var Fragment$BsConsole = require("../Fragment.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var BtIconButton$BsConsole = require("../components/BtIconButton.js");

var component = ReasonReact.reducerComponent("WfArrayRow-BsConsole");

function make(onDelete, dragHandleProps, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              return React.createElement("div", {
                          style: {
                            display: "flex",
                            padding: "4px 0",
                            alignItems: "center"
                          },
                          onMouseLeave: (function (param) {
                              return Curry._1(send, /* MouseLeave */1);
                            }),
                          onMouseOver: (function (param) {
                              return Curry._1(send, /* MouseEnter */0);
                            })
                        }, React.cloneElement(React.createElement("div", {
                                  style: {
                                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npLightBlue),
                                    display: "inline-block",
                                    paddingTop: "4px",
                                    paddingRight: "4px"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.DragHandle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), dragHandleProps), React.createElement("div", {
                              style: {
                                flex: "1"
                              }
                            }, ReasonReact.element(undefined, undefined, Fragment$BsConsole.make(children))), ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(undefined, undefined, undefined, undefined, {
                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.npLightBlue),
                                  visibility: param.state.mouseOver ? "visible" : "hidden"
                                }, /* Primary */-791844958, (function (param) {
                                    return Curry._1(onDelete, undefined);
                                  }), "Delete", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])));
            }),
          initialState: (function (param) {
              return {
                      mouseOver: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            mouseOver: false
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            mouseOver: true
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
