// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Icon$BsConsole = require("../bindings/Icon.js");
var Image$BsConsole = require("../components/Image.js");
var BtList$BsConsole = require("../components/BtList.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var WfUtils$BsConsole = require("../workflow/WfUtils.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var WfRouter$BsConsole = require("../workflow/WfRouter.js");
var BtListItem$BsConsole = require("../components/BtListItem.js");
var ApiWfTickets$BsConsole = require("../workflow/workflows-api/ApiWfTickets.js");
var BtIconButton$BsConsole = require("../components/BtIconButton.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WorkflowVersion$BsConsole = require("../workflow/WorkflowVersion.js");
var InboxUnlinkedTicket$BsConsole = require("./InboxUnlinkedTicket.js");

var menuList = Css.style(/* :: */[
      Css.padding2(Css.px(8), /* zero */-789508312),
      /* :: */[
        Css.focus(/* :: */[
              Css.unsafe("outline", "none"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var menuItemText = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.marginLeft(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var menuItemBase = Css.style(/* :: */[
      Css.unsafe("height", "unset"),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.lineHeight(/* `abs */[
                4845682,
                1.0
              ]),
          /* :: */[
            Css.hover(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.white)),
                    /* [] */0
                  ]
                ]),
            /* :: */[
              Css.focus(/* :: */[
                    Css.unsafe("outline", "none"),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var menuItemLessPadding = Css.merge(/* :: */[
      menuItemBase,
      /* :: */[
        Css.style(/* :: */[
              Css.padding2(Css.px(8), Css.px(16)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItem = Css.merge(/* :: */[
      menuItemBase,
      /* :: */[
        Css.style(/* :: */[
              Css.padding2(Css.px(8), Css.px(40)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuSectionHeader = Css.style(/* :: */[
      Css.padding2(Css.px(8), Css.px(16)),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* [] */0
        ]
      ]
    ]);

var integrationHeader = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.padding2(Css.px(8), Css.px(16)),
        /* :: */[
          Css.fontSize(Css.px(11)),
          /* :: */[
            Css.lineHeight(Css.px(16)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    500
                  ]),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.width(Css.px(16)),
      /* :: */[
        Css.height(Css.px(16)),
        /* :: */[
          Css.marginRight(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var menuDivider = Css.style(/* :: */[
      Css.margin2(Css.px(5), Css.px(0)),
      /* [] */0
    ]);

var menuEditIcon = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* [] */0
    ]);

var deleteMuiItem = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA60)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.justifyContent(/* spaceBetween */516682146),
            /* :: */[
              Css.padding2(Css.px(8), Css.px(16)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  menuList: menuList,
  menuItemContainer: menuItemContainer,
  menuItemText: menuItemText,
  menuItemBase: menuItemBase,
  menuItemLessPadding: menuItemLessPadding,
  menuItem: menuItem,
  menuSectionHeader: menuSectionHeader,
  integrationHeader: integrationHeader,
  icon: icon,
  menuDivider: menuDivider,
  menuEditIcon: menuEditIcon,
  deleteMuiItem: deleteMuiItem
};

var component = ReasonReact.reducerComponent("InboxMultiTicket-BsConsole");

function initialState(param) {
  return {
          urlValue: undefined,
          shortValue: undefined,
          unlinkedDialogOpen: false,
          loading: false,
          maybeLinkedIntegrationId: undefined
        };
}

function make(anchorOrigin, transformOrigin, handleWfAssociateTicket, workflows, projectName, target, fetchIssues, handleAddTicket, tickets, handleRemoveTicket, handleChangeUrl, config, token, selectedFingerprints, onCloseOpt, _children) {
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var ticketCreateWorkflows = WfUtils$BsConsole.filterBySupportedAction(workflows, "ticket:create");
              var hasWorkflows = ticketCreateWorkflows.length !== 0;
              var currentIntegrationType = {
                contents: ""
              };
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make(Curry.__3(target), undefined, anchorOrigin, transformOrigin, undefined, (function (param) {
                                return Curry._1(onClose, undefined);
                              }), undefined, (function (onClose) {
                                var tmp;
                                if (tickets !== undefined && tickets) {
                                  var __x = Belt_List.toArray(tickets);
                                  tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, menuSectionHeader, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Manage existing tickets")])), ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [Belt_Array.mapWithIndex(__x, (function (idx, ticket) {
                                                        var match;
                                                        var exit = 0;
                                                        switch (ticket.tag | 0) {
                                                          case /* Unlinked */0 :
                                                          case /* Linked */1 :
                                                              exit = 1;
                                                              break;
                                                          case /* InteralAssigneeLink */2 :
                                                              throw [
                                                                    Caml_builtin_exceptions.assert_failure,
                                                                    /* tuple */[
                                                                      "InboxMultiTicket.re",
                                                                      163,
                                                                      34
                                                                    ]
                                                                  ];
                                                          
                                                        }
                                                        if (exit === 1) {
                                                          var match$1 = ticket[0];
                                                          match = /* tuple */[
                                                            match$1.short,
                                                            match$1.url
                                                          ];
                                                        }
                                                        return ReasonReact.element(String(idx), undefined, BtListItem$BsConsole.make(deleteMuiItem, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* small */-9768761, "receipt", [])),
                                                                                  React.createElement("div", {
                                                                                        className: Css.style(/* :: */[
                                                                                              Css.color(Css.hex(Colors$BsConsole.blackA87)),
                                                                                              /* :: */[
                                                                                                Css.paddingLeft(Css.px(4)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ])
                                                                                      }, React.createElement("a", {
                                                                                            href: match[1],
                                                                                            target: "_blank"
                                                                                          }, I18N$BsConsole.showSkip(match[0])))
                                                                                ])),
                                                                        React.createElement("div", undefined, ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(true, Css.style(/* :: */[
                                                                                          Css.marginRight(Css.px(-8)),
                                                                                          /* [] */0
                                                                                        ]), undefined, undefined, undefined, undefined, (function (param) {
                                                                                        if (handleRemoveTicket !== undefined) {
                                                                                          return Curry._2(handleRemoveTicket, ticket, (function (param) {
                                                                                                        return Curry._1(onClose, undefined);
                                                                                                      }));
                                                                                        }
                                                                                        
                                                                                      }), "Delete ticket link", [ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* small */-9768761, "clear", []))])))
                                                                      ]));
                                                      }))])), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, menuDivider, undefined, [])));
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            onClick: (function (e) {
                                                e.stopPropagation();
                                                
                                              })
                                          }, tmp, ReasonReact.element(undefined, undefined, BtList$BsConsole.make(menuList, [
                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, menuSectionHeader, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Create a ticket")])),
                                                    ReasonReact.element(undefined, undefined, InboxUnlinkedTicket$BsConsole.make(Belt_Option.getWithDefault(self.state.urlValue, ""), Belt_Option.getWithDefault(self.state.shortValue, ""), self.state.unlinkedDialogOpen, (function (v) {
                                                                return Curry._1(self.send, /* SetInputUrlValue */Block.__(0, [v]));
                                                              }), projectName, (function (_v) {
                                                                var match = self.state.urlValue;
                                                                var match$1 = self.state.shortValue;
                                                                if (match === undefined) {
                                                                  return ;
                                                                }
                                                                if (match$1 === undefined) {
                                                                  return ;
                                                                }
                                                                var match$2 = WorkflowVersion$BsConsole.current(config);
                                                                if (typeof match$2 === "number") {
                                                                  var ticket = Issues$BsConsole.Ticket.makeUnlinked(match$1, match);
                                                                  return Curry._2(handleAddTicket, ticket, (function (param) {
                                                                                return Curry._1(self.send, /* Close */Block.__(3, [onClose]));
                                                                              }));
                                                                } else {
                                                                  return ApiWfTickets$BsConsole.setIssueTickets(config, token, fetchIssues, selectedFingerprints, projectName, match, match$1, self.state.maybeLinkedIntegrationId, (function (param) {
                                                                                return Curry._1(self.send, /* Close */Block.__(3, [onClose]));
                                                                              }));
                                                                }
                                                              }), (function (v) {
                                                                return Curry._1(self.send, /* SetInputShortValue */Block.__(1, [v]));
                                                              }), (function (param) {
                                                                return Curry._1(self.send, /* CloseDialog */2);
                                                              }), workflows, self.state.maybeLinkedIntegrationId, (function (v) {
                                                                return Curry._1(self.send, /* SetInputLinkedIntegrationIdValue */Block.__(2, [v]));
                                                              }), [])),
                                                    Belt_Array.mapWithIndex(ticketCreateWorkflows, (function (idx, workflow) {
                                                            var watcher = workflow.watcher;
                                                            return React.createElement("div", undefined, currentIntegrationType.contents !== watcher.type_ ? (currentIntegrationType.contents = watcher.type_, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, integrationHeader, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [
                                                                                    ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(icon, undefined, Belt_Option.getWithDefault(workflow.integration.description.icon, ""), watcher.type_ + " Icon", undefined)([])),
                                                                                    I18N$BsConsole.show(undefined, watcher.type_.replace("_legacy", "").toUpperCase())
                                                                                  ]))) : null, ReasonReact.element(watcher.watcherName + ("__" + String(idx)), undefined, BtListItem$BsConsole.make(menuItem, (function (param) {
                                                                                  return Curry._2(handleWfAssociateTicket, workflow, (function (param) {
                                                                                                return Curry._1(self.send, /* Close */Block.__(3, [onClose]));
                                                                                              }));
                                                                                }), [I18N$BsConsole.show(undefined, watcher.userFriendlyName)])));
                                                          })),
                                                    hasWorkflows ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, integrationHeader, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "OTHER")])) : null,
                                                    ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(hasWorkflows ? menuItem : menuItemLessPadding, (function (param) {
                                                                return Curry._1(self.send, /* OpenDialog */1);
                                                              }), [I18N$BsConsole.show(undefined, "Add link")])),
                                                    hasWorkflows ? null : ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(menuItemLessPadding, (function (param) {
                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                                                projectName,
                                                                                WfRouter$BsConsole.root,
                                                                                undefined
                                                                              ]));
                                                                }), [
                                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, menuDivider, undefined, [])),
                                                                React.createElement("div", {
                                                                      className: menuItemContainer
                                                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, menuItemText, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Add a workflow integration")])))
                                                              ]))
                                                  ])));
                              }), undefined, []));
            }),
          initialState: initialState,
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* ResetState */0 :
                      return /* Update */Block.__(0, [{
                                  urlValue: undefined,
                                  shortValue: undefined,
                                  unlinkedDialogOpen: false,
                                  loading: false,
                                  maybeLinkedIntegrationId: undefined
                                }]);
                  case /* OpenDialog */1 :
                      return /* Update */Block.__(0, [{
                                  urlValue: state.urlValue,
                                  shortValue: state.shortValue,
                                  unlinkedDialogOpen: true,
                                  loading: state.loading,
                                  maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                }]);
                  case /* CloseDialog */2 :
                      return /* Update */Block.__(0, [{
                                  urlValue: state.urlValue,
                                  shortValue: state.shortValue,
                                  unlinkedDialogOpen: false,
                                  loading: state.loading,
                                  maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                }]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* SetInputUrlValue */0 :
                      var urlValue = action[0];
                      var regex = new RegExp("\\/([^\\/?]+)\\/?$");
                      var result = regex.exec(urlValue.replace(new RegExp("\\?.+"), ""));
                      if (result !== null) {
                        return /* Update */Block.__(0, [{
                                    urlValue: urlValue,
                                    shortValue: Belt_Option.flatMap(Belt_Array.get(result, 1), (function (prim) {
                                            if (prim == null) {
                                              return ;
                                            } else {
                                              return Caml_option.some(prim);
                                            }
                                          })),
                                    unlinkedDialogOpen: state.unlinkedDialogOpen,
                                    loading: state.loading,
                                    maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                  }]);
                      } else {
                        return /* Update */Block.__(0, [{
                                    urlValue: urlValue,
                                    shortValue: state.shortValue,
                                    unlinkedDialogOpen: state.unlinkedDialogOpen,
                                    loading: state.loading,
                                    maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                  }]);
                      }
                  case /* SetInputShortValue */1 :
                      return /* Update */Block.__(0, [{
                                  urlValue: state.urlValue,
                                  shortValue: action[0],
                                  unlinkedDialogOpen: state.unlinkedDialogOpen,
                                  loading: state.loading,
                                  maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                }]);
                  case /* SetInputLinkedIntegrationIdValue */2 :
                      return /* Update */Block.__(0, [{
                                  urlValue: state.urlValue,
                                  shortValue: state.shortValue,
                                  unlinkedDialogOpen: state.unlinkedDialogOpen,
                                  loading: state.loading,
                                  maybeLinkedIntegrationId: action[0]
                                }]);
                  case /* Close */3 :
                      var onClose = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  urlValue: state.urlValue,
                                  shortValue: state.shortValue,
                                  unlinkedDialogOpen: state.unlinkedDialogOpen,
                                  loading: true,
                                  maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                                },
                                (function (self) {
                                    setTimeout((function (param) {
                                            Curry._1(onClose, undefined);
                                            setTimeout((function (param) {
                                                    return Curry._1(self.send, /* ResetState */0);
                                                  }), 0);
                                            
                                          }), 0);
                                    
                                  })
                              ]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Array_;

exports.Array_ = Array_;
exports.Styles = Styles;
exports.component = component;
exports.initialState = initialState;
exports.make = make;
/* menuList Not a pure module */
