// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var ApiSymbolsSummary$BsConsole = require("./ApiSymbolsSummary.js");

var ParseError = Caml_exceptions.create("SymbolsFetch-BsConsole.ParseError");

function makeTask(endpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "summary"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          Json_encode.object_(/* [] */0)
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [ApiSymbolsSummary$BsConsole.decode(param.json)]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SymbolsFetch/makeTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  return Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : "Something went wrong.");
}

function use(token, projectName) {
  var match = React.useState((function () {
          return 0;
        }));
  var setKey = match[1];
  var bumpKey = function (param) {
    return Curry._1(setKey, (function (k) {
                  return k + 1 | 0;
                }));
  };
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match$1[1];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp]);
                }));
  };
  var onFailure = function (errorMessage) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [errorMessage]);
                }));
  };
  React.useEffect((function () {
          Curry._1(setRemote, (function (param) {
                  return /* Loading */1;
                }));
          var arg = makeTask("/api/symfile?project=" + projectName);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  return responseCb(onSuccess, onFailure, param);
                }));
          
        }), /* tuple */[
        match[0],
        projectName,
        token
      ]);
  return /* tuple */[
          match$1[0],
          bumpKey
        ];
}

exports.ParseError = ParseError;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.use = use;
/* react Not a pure module */
