// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeCreate(json) {
  return /* Create */Block.__(1, [{
              password: Json_decode.field("password", Json_decode.string, json),
              username: Json_decode.field("username", Json_decode.string, json)
            }]);
}

function decodeReset(json) {
  return /* Reset */Block.__(2, [{
              universe_id: Json_decode.field("universe_id", Json_decode.string, json),
              token: Json_decode.field("token", Json_decode.string, json),
              username: Json_decode.field("username", Json_decode.string, json)
            }]);
}

function decodeTokenized(json) {
  return /* Tokenized */Block.__(3, [{
              token: Json_decode.field("token", Json_decode.string, json)
            }]);
}

function decodeSAML(json) {
  var params__method = Json_decode.field("method", Json_decode.string, json);
  var params_token = Json_decode.field("token", Json_decode.string, json);
  return /* SAML */Block.__(4, [
            params__method,
            params_token
          ]);
}

function decode(json) {
  var partial_arg_001 = /* :: */[
    decodeReset,
    /* :: */[
      decodeSAML,
      /* :: */[
        decodeTokenized,
        /* [] */0
      ]
    ]
  ];
  var partial_arg = /* :: */[
    decodeCreate,
    partial_arg_001
  ];
  return Json_decode.withDefault(/* Normal */0, (function (param) {
                return Json_decode.oneOf(partial_arg, param);
              }), json);
}

exports.decodeCreate = decodeCreate;
exports.decodeReset = decodeReset;
exports.decodeTokenized = decodeTokenized;
exports.decodeSAML = decodeSAML;
exports.decode = decode;
/* No side effect */
