// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");

var root = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* :: */[
        Css.margin2(Css.em(1), /* zero */-789508312),
        /* [] */0
      ]
    ]);

var Styles = {
  root: root
};

var component = ReasonReact.statelessComponent("SettingsDialog-BsConsole");

function make(onChange, value, error, helperText, label, fullWidth, autoFocusOpt, disabledOpt, idOpt, _children) {
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var id = idOpt !== undefined ? idOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                              "settings-textfield " + root,
                              undefined,
                              undefined,
                              fullWidth,
                              onChange,
                              undefined,
                              undefined,
                              undefined,
                              error,
                              Caml_option.some(helperText),
                              undefined,
                              value,
                              undefined,
                              autoFocus,
                              Caml_option.some(label),
                              undefined,
                              undefined,
                              disabled,
                              undefined,
                              id,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              []
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var TextField = {
  Styles: Styles,
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("SettingsDialog-BsConsole");

function make$1(onChange, value, error, helperText, label, fullWidth, autoFocusOpt, idOpt, content, disabledOpt, _children) {
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var id = idOpt !== undefined ? idOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              if (disabled) {
                return React.createElement("div", {
                            style: {
                              display: "inline-block",
                              position: "relative",
                              width: fullWidth ? "100%" : "inherit"
                            }
                          }, React.createElement("div", {
                                style: {
                                  bottom: "14px",
                                  position: "absolute",
                                  right: "5px",
                                  zIndex: "1000"
                                }
                              }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ArrowDropDown.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, make(onChange, value, error, helperText, label, fullWidth, autoFocus, disabled, id, [])));
              } else {
                return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                  return React.createElement("div", {
                                              ref: refHandler,
                                              style: {
                                                display: "inline-block",
                                                position: "relative",
                                                width: fullWidth ? "100%" : "inherit"
                                              },
                                              onFocus: onFocus,
                                              onClick: onClick
                                            }, React.createElement("div", {
                                                  style: {
                                                    bottom: "14px",
                                                    position: "absolute",
                                                    right: "5px",
                                                    zIndex: "1000"
                                                  }
                                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ArrowDropDown.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, make(onChange, value, error, helperText, label, fullWidth, autoFocus, undefined, id, [])));
                                }), undefined, undefined, undefined, undefined, undefined, undefined, content, true, []));
              }
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var TypeaheadTextField = {
  component: component$1,
  make: make$1
};

var root$1 = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var dialogTitle = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.padding2(Css.em(0.5), Css.px(24)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* spaceBetween */516682146),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.white)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var content = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var close = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

var Styles$1 = {
  root: root$1,
  dialogTitle: dialogTitle,
  content: content,
  title: title,
  close: close
};

var component$2 = ReasonReact.statelessComponent("SettingsDialog-BsConsole");

function make$2(onClose, title$1, classNameOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              {
                                paper: className
                              },
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                React.createElement("div", {
                                      className: dialogTitle
                                    }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(title$1)])), React.createElement("div", {
                                          className: close,
                                          onClick: (function (_event) {
                                              return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                            })
                                        }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))),
                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, content, undefined, children))
                              ]
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

exports.TextField = TextField;
exports.TypeaheadTextField = TypeaheadTextField;
exports.Styles = Styles$1;
exports.component = component$2;
exports.make = make$2;
/* root Not a pure module */
