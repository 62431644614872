// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");

function capabilitiesToString(caps) {
  return (
          caps.errorPost ? "error:post " : ""
        ) + ((
            caps.symbolPost ? "symbol:post " : ""
          ) + ((
              caps.objectGet ? "object:get " : ""
            ) + ((
                caps.queryPost ? "query:post " : ""
              ) + (
                caps.syncPost ? "sync:post " : ""
              ))));
}

function stringToCapabilities(caps) {
  return Belt_Array.reduce(caps.split(" "), {
              errorPost: false,
              symbolPost: false,
              objectGet: false,
              queryPost: false,
              syncPost: false
            }, (function (acc, cap) {
                switch (cap) {
                  case "error:post" :
                      return {
                              errorPost: true,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "object:get" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: true,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "query:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: true,
                              syncPost: acc.syncPost
                            };
                  case "symbol:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: true,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "sync:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: true
                            };
                  default:
                    return acc;
                }
              }));
}

exports.capabilitiesToString = capabilitiesToString;
exports.stringToCapabilities = stringToCapabilities;
/* No side effect */
