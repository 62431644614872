// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Colors$BsConsole = require("./Colors.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");

var searchInputContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.unsafe("boxShadow", "unset"),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.blackA20)),
          /* [] */0
        ]
      ]
    ]);

var searchIcon = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(12)),
        /* :: */[
          Css.left(Css.px(6)),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey3)),
            /* [] */0
          ]
        ]
      ]
    ]);

var clearInput = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey3)),
            /* [] */0
          ]
        ]
      ]
    ]);

var searchInput = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.unsafe("border", "none"),
        /* :: */[
          Css.paddingTop(/* zero */-789508312),
          /* :: */[
            Css.paddingRight(Css.px(48)),
            /* :: */[
              Css.paddingBottom(/* zero */-789508312),
              /* :: */[
                Css.paddingLeft(Css.px(36)),
                /* :: */[
                  Css.borderRadius(Css.px(3)),
                  /* :: */[
                    Css.fontSize(Css.px(16)),
                    /* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.height(Css.px(48)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  searchInputContainer: searchInputContainer,
  searchIcon: searchIcon,
  clearInput: clearInput,
  searchInput: searchInput
};

var component = ReasonReact.statelessComponent("SearchBar-BsConsole");

function make(value, handleSearchChange, placeholderOpt, widthOpt, _children) {
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Search...";
  var width = widthOpt !== undefined ? widthOpt : "initial";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          style: {
                            width: width
                          }
                        }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, searchInputContainer, undefined, undefined, undefined, [
                                  React.createElement("div", {
                                        className: searchIcon
                                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Search.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                  $$String.trim(value) === "" ? null : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                              clearInput,
                                              undefined,
                                              (function (_e) {
                                                  return Curry._1(handleSearchChange, "");
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              "Clear search",
                                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                            ])),
                                  React.createElement("input", {
                                        className: "saved-query-search-input " + searchInput,
                                        placeholder: placeholder,
                                        value: value,
                                        onChange: (function ($$event) {
                                            return Curry._1(handleSearchChange, $$event.target.value);
                                          })
                                      })
                                ])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* searchInputContainer Not a pure module */
