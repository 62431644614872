// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");

function toCapacityType(percent) {
  if (percent > 100) {
    return /* Over */2;
  } else if (percent === 100) {
    return /* Full */1;
  } else {
    return /* Partial */0;
  }
}

function toBarPercent(percent) {
  var capacity = toCapacityType(percent);
  if (capacity !== 0) {
    return 100;
  } else {
    return percent;
  }
}

function toColor(percent) {
  var capacity = toCapacityType(percent);
  switch (capacity) {
    case /* Partial */0 :
        return Colors$BsConsole.greenDark;
    case /* Full */1 :
        return Colors$BsConsole.greenOlive;
    case /* Over */2 :
        return Colors$BsConsole.redDark;
    
  }
}

function toMessage(percent) {
  var capacity = toCapacityType(percent);
  if (capacity >= 2) {
    return I18N$BsConsole.get(undefined, "Overcapacity");
  } else {
    return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                    /* Float */Block.__(8, [
                        /* Float_f */0,
                        /* No_padding */0,
                        /* Lit_precision */[2],
                        /* Char_literal */Block.__(12, [
                            /* "%" */37,
                            /* String_literal */Block.__(11, [
                                " used",
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "%.2f%% used"
                  ]), percent);
  }
}

var Capacity = {
  toCapacityType: toCapacityType,
  toBarPercent: toBarPercent,
  toColor: toColor,
  toMessage: toMessage
};

function PercentageBar(Props) {
  var numerator = Props.numerator;
  var denominator = Props.denominator;
  var percent = numerator / denominator * 100;
  var barColor = toColor(percent);
  var barMessage = toMessage(percent);
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.marginBottom(Css.rem(0.5)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontFamily("Roboto"),
                        /* :: */[
                          Css.fontSize(Css.px(14)),
                          /* :: */[
                            Css.fontStyle(/* normal */812216871),
                            /* :: */[
                              Css.fontWeight(/* normal */812216871),
                              /* :: */[
                                Css.lineHeight(Css.px(16)),
                                /* :: */[
                                  Css.letterSpacing(Css.px(0)),
                                  /* :: */[
                                    Css.textAlign(/* left */-944764921),
                                    /* :: */[
                                      Css.color(Css.hex(barColor)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(5)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.showSkip(barMessage)), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
                                /* :: */[
                                  Css.height(Css.px(10)),
                                  /* :: */[
                                    Css.borderRadius(Css.px(2)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(toBarPercent(percent))),
                                  /* :: */[
                                    Css.backgroundColor(Css.hex(barColor)),
                                    /* :: */[
                                      Css.height(Css.px(10)),
                                      /* :: */[
                                        Css.borderRadius(Css.px(2)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }))
                }));
}

var make = PercentageBar;

exports.Capacity = Capacity;
exports.make = make;
/* Css Not a pure module */
