// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReleaseChannel$BsConsole = require("./ReleaseChannel.js");

var includeInternalUsers = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_INCLUDE_INTERNAL_USERS), false, (function (x) {
        return x;
      }));

var maybeReceiveLayer = window._BACKTRACE_RECEIVE_LAYER;

var match = window._BACKTRACE_AUTO_DELETED_EQUAL_ZERO;

var maybeAutoAddDeletedEqualZero = !(match == null) && !match ? false : true;

var backtraceEarlyAccess = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_EARLY_ACCESS), false, (function (x) {
        return x;
      }));

var _BACKTRACE_RELEASE_CHANNEL = ReleaseChannel$BsConsole.ofString(Caml_option.nullable_to_opt(window._BACKTRACE_RELEASE_CHANNEL));

var __x = window._BACKTRACE_USE_ALPHA;

var _BACKTRACE_USE_ALPHA = Belt_Option.getWithDefault(Belt_Option.map((__x == null) ? undefined : Caml_option.some(__x), (function (v) {
            return v === "true";
          })), false);

var __x$1 = window._BACKTRACE_USE_BETA;

var _BACKTRACE_USE_BETA = Belt_Option.getWithDefault(Belt_Option.map((__x$1 == null) ? undefined : Caml_option.some(__x$1), (function (v) {
            return v === "true";
          })), false);

function decodeBacktraceUseI18N(json) {
  var match = Js_json.decodeString(json);
  var match$1 = Js_json.decodeBoolean(json);
  if (match === "true") {
    return true;
  }
  if (match$1 !== undefined && match$1) {
    return true;
  } else {
    return false;
  }
}

var _BACKTRACE_USE_I18N = Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(window._BACKTRACE_USE_I18N), decodeBacktraceUseI18N), false);

var _BACKTRACE_USER_MANAGEMENT = window._BACKTRACE_USER_MANAGEMENT;

var loginIndividualizedText = window._BACKTRACE_LOGIN_INDIVIDUALIZED_TEXT;

var promptMissingSymbols = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_PROMPT_MISSING_SYMBOLS), false);

var consoleVersion = "3.0.0-alpha";

var maybeReceiveLayer$1 = (maybeReceiveLayer == null) ? undefined : Caml_option.some(maybeReceiveLayer);

var _BACKTRACE_USER_MANAGEMENT$1 = (_BACKTRACE_USER_MANAGEMENT == null) ? undefined : Caml_option.some(_BACKTRACE_USER_MANAGEMENT);

var loginIndividualizedText$1 = (loginIndividualizedText == null) ? undefined : Caml_option.some(loginIndividualizedText);

exports.includeInternalUsers = includeInternalUsers;
exports.consoleVersion = consoleVersion;
exports.maybeReceiveLayer = maybeReceiveLayer$1;
exports.maybeAutoAddDeletedEqualZero = maybeAutoAddDeletedEqualZero;
exports.backtraceEarlyAccess = backtraceEarlyAccess;
exports._BACKTRACE_RELEASE_CHANNEL = _BACKTRACE_RELEASE_CHANNEL;
exports._BACKTRACE_USE_ALPHA = _BACKTRACE_USE_ALPHA;
exports._BACKTRACE_USE_BETA = _BACKTRACE_USE_BETA;
exports.decodeBacktraceUseI18N = decodeBacktraceUseI18N;
exports._BACKTRACE_USE_I18N = _BACKTRACE_USE_I18N;
exports._BACKTRACE_USER_MANAGEMENT = _BACKTRACE_USER_MANAGEMENT$1;
exports.loginIndividualizedText = loginIndividualizedText$1;
exports.promptMissingSymbols = promptMissingSymbols;
/* includeInternalUsers Not a pure module */
