// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");
var AccentCard$BsConsole = require("../../utilities/AccentCard.js");
var BeautifulDnd$BsConsole = require("../../bindings/BeautifulDnd.js");
var Dedupe_RuleCard$BsConsole = require("./Dedupe_RuleCard.js");

function updateArrayItemPosition(array, from, to_) {
  var otherItems = Belt_Array.concat(Belt_Array.slice(array, 0, from), Belt_Array.slice(array, from + 1 | 0, array.length));
  return Belt_Array.concat(Belt_Array.concat(Belt_Array.slice(otherItems, 0, to_), [Belt_Array.getExn(array, from)]), Belt_Array.slice(otherItems, to_, array.length));
}

var component = RR$BsConsole.reducerComponent("Dedupe_RuleList-BsConsole");

function make(ruleListOpt, handleOrderUpdate, handleAddRule, handleCardUpdate, handleRuleDeletion, staticOpt, _children) {
  var ruleList = ruleListOpt !== undefined ? ruleListOpt : /* [] */0;
  var $$static = staticOpt !== undefined ? staticOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var hovered = param.state.hovered;
              return React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ])
                        }, ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.DragDropContext.make((function (e) {
                                    if (hovered) {
                                      return Curry._1(send, /* MoveAction */[
                                                  e.source.index,
                                                  e.destination.index
                                                ]);
                                    }
                                    
                                  }), [ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Droppable.make("droppable-rule-list", (function (provided, param) {
                                              var __x = Belt_List.toArray(ruleList);
                                              return React.createElement("div", {
                                                          ref: provided.innerRef
                                                        }, Belt_Array.mapWithIndex(__x, (function (index, rule) {
                                                                return React.createElement("span", {
                                                                            onMouseEnter: (function (param) {
                                                                                return Curry._1(send, /* MouseEnter */0);
                                                                              }),
                                                                            onMouseLeave: (function (param) {
                                                                                return Curry._1(send, /* MouseLeave */1);
                                                                              })
                                                                          }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                    React.createElement("div", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.marginRight(Css.px(5)),
                                                                                                /* [] */0
                                                                                              ])
                                                                                        }, I18N$BsConsole.showSkip(String(index + 1 | 0))),
                                                                                    React.createElement("div", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.flexGrow(1),
                                                                                                /* :: */[
                                                                                                  Css.marginRight(Css.px(50)),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ])
                                                                                        }, ReasonReact.element(rule.dataKey, undefined, BeautifulDnd$BsConsole.Draggable.make(rule.dataKey, index, !hovered || $$static, (function (provided, snapshot) {
                                                                                                    return React.cloneElement(React.createElement("div", {
                                                                                                                    ref: provided.innerRef
                                                                                                                  }, ReasonReact.element(undefined, undefined, Dedupe_RuleCard$BsConsole.make(rule.dataKey, handleCardUpdate, rule.functionValue, rule.objectValue, rule.sourceValue, rule.actionValue, rule.replacementValue, rule.attributeValue, I18N$BsConsole.get(undefined, "Delete rule from ruleset"), handleRuleDeletion, provided.dragHandleProps, hovered, snapshot.isDragging, $$static, []))), provided.draggableProps);
                                                                                                  }))))
                                                                                  ])));
                                                              })), provided.placeholder);
                                            })))])), $$static ? null : ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Add a rule"), Caml_option.some(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), (function (param) {
                                      return Curry._1(handleAddRule, undefined);
                                    }), undefined, 150, [])));
            }),
          initialState: (function (param) {
              return {
                      hovered: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              hovered: false
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              hovered: true
                            }]);
                }
              }
              var to_ = action[1];
              var from = action[0];
              return /* SideEffects */Block.__(1, [(function (param) {
                            return Curry._1(handleOrderUpdate, Belt_List.fromArray(updateArrayItemPosition(Belt_List.toArray(ruleList), from, to_)));
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.updateArrayItemPosition = updateArrayItemPosition;
exports.component = component;
exports.make = make;
/* component Not a pure module */
