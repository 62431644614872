// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var WfTasks$BsConsole = require("./WfTasks.js");
var WfRouter$BsConsole = require("./WfRouter.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var WfWatchersList$BsConsole = require("./WfWatchersList.js");
var SlackConnection$BsConsole = require("./slack/SlackConnection.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");
var JiraPluginDetection$BsConsole = require("../JiraPluginDetection.js");
var WfWatcherEditStepper$BsConsole = require("./WfWatcherEditStepper.js");
var WfWatcherCreateStepper$BsConsole = require("./WfWatcherCreateStepper.js");
var ResetScrollOnRouteUpdate$BsConsole = require("../ResetScrollOnRouteUpdate.js");

var ParseError = Caml_exceptions.create("WfRoot-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("WfRoot-BsConsole.ServiceError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === ServiceError ? error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

var component = RR$BsConsole.statelessComponent("WfRoot-BsConsole");

function make(isActive, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Fade.make(isActive, true, true, undefined, [React.createElement("div", {
                                    style: {
                                      marginBottom: "24px",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: isActive ? "1" : "0"
                                    }
                                  }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, children)))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var WorkflowItemWrap = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("WfRoot-BsConsole");

function make$1(btDefaults, user, route, handleTask, onRouteChange, handleChangeUrl, token, universeName, project, watchers, availableIntegrations, watcherRefetchState, onRefetchWatchers, serviceEndpoint, config, param) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              if (typeof route === "number") {
                switch (route) {
                  case /* JiraCloud */0 :
                      return ReasonReact.element(undefined, undefined, JiraPluginDetection$BsConsole.Jsx2.make(token, handleChangeUrl, project, config, /* Default */0, /* JiraCloud */0, []));
                  case /* Slack */1 :
                      return ReasonReact.element(undefined, undefined, SlackConnection$BsConsole.Jsx2.make(token, config, handleChangeUrl, project, []));
                  case /* WatchersList */2 :
                      if (serviceEndpoint !== undefined) {
                        var match = WorkflowVersion$BsConsole.current(config);
                        if (typeof match === "number" && match !== 0) {
                          return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, WfTasks$BsConsole.getWatcherStatuses(serviceEndpoint, undefined), undefined, (function (remote, param) {
                                            if (typeof remote !== "number" && !remote.tag) {
                                              var statuses = Caml_option.some(remote[0][0]);
                                              return ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(/* Workflow */Block.__(54, [{
                                                                  nonce: ""
                                                                }]), [ReasonReact.element(undefined, undefined, WfWatchersList$BsConsole.make(statuses, (function (param) {
                                                                          return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]));
                                                                        }), watcherRefetchState === /* Loading */1, availableIntegrations, (function (watcher) {
                                                                          return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                                        watcher.userFriendlyName,
                                                                                        /* ConfigureConnection */1
                                                                                      ]));
                                                                        }), (function (param) {
                                                                          return Curry._1(onRefetchWatchers, undefined);
                                                                        }), watchers, token, project, config, handleChangeUrl, []))]));
                                            }
                                            return ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(/* Workflow */Block.__(54, [{
                                                                nonce: ""
                                                              }]), [ReasonReact.element(undefined, undefined, WfWatchersList$BsConsole.make(undefined, (function (param) {
                                                                        return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]));
                                                                      }), watcherRefetchState === /* Loading */1, availableIntegrations, (function (watcher) {
                                                                        return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                                      watcher.userFriendlyName,
                                                                                      /* ConfigureConnection */1
                                                                                    ]));
                                                                      }), (function (param) {
                                                                        return Curry._1(onRefetchWatchers, undefined);
                                                                      }), watchers, token, project, config, handleChangeUrl, []))]));
                                          })));
                        }
                        return ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(/* Workflow */Block.__(54, [{
                                            nonce: ""
                                          }]), [ReasonReact.element(undefined, undefined, WfWatchersList$BsConsole.make(undefined, (function (param) {
                                                    return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]));
                                                  }), watcherRefetchState === /* Loading */1, availableIntegrations, (function (watcher) {
                                                    var type_ = watcher.type_;
                                                    if (type_ === undefined) {
                                                      return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                    watcher.userFriendlyName,
                                                                    /* ConfigureConnection */1
                                                                  ]));
                                                    }
                                                    switch (type_) {
                                                      case "jira" :
                                                      case "jira-server" :
                                                      case "slack" :
                                                          break;
                                                      default:
                                                        return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                      watcher.userFriendlyName,
                                                                      /* ConfigureConnection */1
                                                                    ]));
                                                    }
                                                    var param_pluginId = type_;
                                                    var param_integrationId = watcher.integrationId;
                                                    var param = {
                                                      pluginId: param_pluginId,
                                                      connectionId: undefined,
                                                      connectionCreated: undefined,
                                                      integrationId: param_integrationId,
                                                      referrer: undefined,
                                                      error: undefined
                                                    };
                                                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                                  project.name,
                                                                  WfRouter$BsConsole.root,
                                                                  param
                                                                ]));
                                                  }), (function (param) {
                                                    return Curry._1(onRefetchWatchers, undefined);
                                                  }), watchers, token, project, config, handleChangeUrl, []))]));
                      }
                      var match$1 = WorkflowVersion$BsConsole.current(config);
                      if (typeof match$1 === "number" && match$1 !== 0) {
                        return ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(/* Workflow */Block.__(54, [{
                                            nonce: ""
                                          }]), [ReasonReact.element(undefined, undefined, WfWatchersList$BsConsole.make(undefined, (function (param) {
                                                    return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]));
                                                  }), watcherRefetchState === /* Loading */1, availableIntegrations, (function (watcher) {
                                                    return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                  watcher.userFriendlyName,
                                                                  /* ConfigureConnection */1
                                                                ]));
                                                  }), (function (param) {
                                                    return Curry._1(onRefetchWatchers, undefined);
                                                  }), watchers, token, project, config, handleChangeUrl, []))]));
                      }
                      return ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(/* Workflow */Block.__(54, [{
                                          nonce: ""
                                        }]), [ReasonReact.element(undefined, undefined, WfWatchersList$BsConsole.make(undefined, (function (param) {
                                                  return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]));
                                                }), watcherRefetchState === /* Loading */1, availableIntegrations, (function (watcher) {
                                                  var type_ = watcher.type_;
                                                  if (type_ === undefined) {
                                                    return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                  watcher.userFriendlyName,
                                                                  /* ConfigureConnection */1
                                                                ]));
                                                  }
                                                  switch (type_) {
                                                    case "jira" :
                                                    case "jira-server" :
                                                    case "slack" :
                                                        break;
                                                    default:
                                                      return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                                    watcher.userFriendlyName,
                                                                    /* ConfigureConnection */1
                                                                  ]));
                                                  }
                                                  var param_pluginId = type_;
                                                  var param_integrationId = watcher.integrationId;
                                                  var param = {
                                                    pluginId: param_pluginId,
                                                    connectionId: undefined,
                                                    connectionCreated: undefined,
                                                    integrationId: param_integrationId,
                                                    referrer: undefined,
                                                    error: undefined
                                                  };
                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                                project.name,
                                                                WfRouter$BsConsole.root,
                                                                param
                                                              ]));
                                                }), (function (param) {
                                                  return Curry._1(onRefetchWatchers, undefined);
                                                }), watchers, token, project, config, handleChangeUrl, []))]));
                      break;
                  
                }
              } else {
                switch (route.tag | 0) {
                  case /* JiraServer */0 :
                      return ReasonReact.element(undefined, undefined, JiraPluginDetection$BsConsole.Jsx2.make(token, handleChangeUrl, project, config, route[0], /* JiraServer */1, []));
                  case /* WatcherCreate */1 :
                      return ReasonReact.element(undefined, undefined, WfWatcherCreateStepper$BsConsole.make(btDefaults, route[0], (function (step) {
                                        return Curry._1(onRouteChange, /* WatcherCreate */Block.__(1, [step]));
                                      }), Curry.__1(onRouteChange), token, watchers, availableIntegrations, handleTask, project, (function (param) {
                                        Curry._1(onRefetchWatchers, undefined);
                                        return Curry._1(onRouteChange, /* WatchersList */2);
                                      }), (function (param) {
                                        return Curry._1(onRouteChange, /* WatchersList */2);
                                      }), user, serviceEndpoint, universeName, config, []));
                  case /* WatcherEdit */2 :
                      var watcherName = route[0];
                      var watcher = watchers.find((function (watcher) {
                              return watcher.userFriendlyName === watcherName;
                            }));
                      var watcher$1 = watcher === undefined ? undefined : Caml_option.some(watcher);
                      if (watcher === undefined) {
                        return null;
                      }
                      var integration = availableIntegrations.find((function (integration) {
                              var watcherType = watcher$1.type_;
                              if (watcherType !== undefined) {
                                return integration.description.type_ === watcherType;
                              } else {
                                return integration.description.path === watcher$1.path;
                              }
                            }));
                      var match$2 = param.state.wfIntegrationFull;
                      if (integration !== undefined) {
                        if (match$2 !== undefined) {
                          if (integration.description.type_ !== match$2.description.type_) {
                            Curry._1(send, /* SetWfIntegrationFull */[undefined]);
                            return null;
                          }
                          
                        } else {
                          var maybeTask = WfTasks$BsConsole.describeIntegration(integration.description.type_, config);
                          if (maybeTask !== undefined) {
                            var onSuccess = function (integration$prime) {
                              return Curry._1(send, /* SetWfIntegrationFull */[integration$prime]);
                            };
                            var onFailure = function (param) {
                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching integration"));
                            };
                            Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                    return responseCb(onSuccess, onFailure, param);
                                  }));
                            return null;
                          }
                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                          return null;
                        }
                      } else if (match$2 === undefined) {
                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Integration not found"));
                        return null;
                      }
                      return ReasonReact.element(undefined, undefined, WfWatcherEditStepper$BsConsole.make(btDefaults, route[1], watchers, (function (newStep) {
                                        return Curry._1(onRouteChange, /* WatcherEdit */Block.__(2, [
                                                      watcherName,
                                                      newStep
                                                    ]));
                                      }), token, handleTask, project, watcher$1, (function (param) {
                                        Curry._1(onRefetchWatchers, undefined);
                                        return Curry._1(onRouteChange, /* WatchersList */2);
                                      }), (function (param) {
                                        return Curry._1(onRouteChange, /* WatchersList */2);
                                      }), match$2, serviceEndpoint, universeName, config, []));
                      break;
                  
                }
              }
            }),
          initialState: (function (param) {
              return {
                      wfIntegrationFull: undefined
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, param) {
              return /* Update */Block.__(0, [{
                          wfIntegrationFull: action[0]
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var RR;

exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.responseCb = responseCb;
exports.WorkflowItemWrap = WorkflowItemWrap;
exports.RR = RR;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
