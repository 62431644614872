// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ButtonBase = require("@material-ui/core/ButtonBase");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var buttonBase = {
  root: Css.style(/* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* spaceBetween */516682146),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.padding(Css.px(8)),
                /* :: */[
                  Css.unsafe("width", "calc(100% - 50px)"),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ])
};

var hovered = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* [] */0
    ]);

var iconButton = {
  root: Css.style(/* :: */[
        Css.height(Css.px(50)),
        /* :: */[
          Css.width(Css.px(50)),
          /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]
        ]
      ])
};

var label = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.fontFamily("Roboto"),
        /* :: */[
          Css.textAlign(/* left */-944764921),
          /* :: */[
            Css.unsafe("display", "-webkit-box"),
            /* :: */[
              Css.unsafe("-webkit-box-orient", "vertical"),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.maxWidth(Css.pct(100)),
                  /* :: */[
                    Css.wordWrap(/* breakWord */1059921449),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var ellipsis = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.unsafe("-webkit-line-clamp", "3"),
        /* [] */0
      ]
    ]);

var Styles = {
  container: container,
  buttonBase: buttonBase,
  hovered: hovered,
  iconButton: iconButton,
  label: label,
  ellipsis: ellipsis
};

function DistributionExpandableRow(Props) {
  var label$1 = Props.label;
  var isHovered = Props.isHovered;
  var openMenu = Props.openMenu;
  var match = React.useState((function () {
          return false;
        }));
  var setIsOverflowing = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setIsExpanded = match$1[1];
  var isExanded = match$1[0];
  return React.createElement("div", {
              className: container
            }, React.createElement(ButtonBase.default, {
                  classes: buttonBase,
                  onClick: (function (e) {
                      return Curry._1(openMenu, e.target);
                    }),
                  children: React.createElement("span", {
                        ref: (function (maybeEl) {
                            if (maybeEl == null) {
                              return ;
                            }
                            var isOverflowing = maybeEl.scrollHeight > maybeEl.clientHeight;
                            return Curry._1(setIsOverflowing, (function (param) {
                                          return isOverflowing;
                                        }));
                          }),
                        className: Css.merge(/* :: */[
                              label,
                              /* :: */[
                                isHovered ? hovered : "",
                                /* :: */[
                                  isExanded ? "" : ellipsis,
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.showSkip(label$1))
                }), match[0] ? (
                isExanded ? null : React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "Expand content",
                        classes: iconButton,
                        onClick: (function (e) {
                            e.stopPropagation();
                            return Curry._1(setIsExpanded, (function (param) {
                                          return true;
                                        }));
                          }),
                        placement_tooltip: "top",
                        title_tooltip: I18N$BsConsole.get(undefined, "Expand content"),
                        children: React.createElement(KeyboardArrowDown.default, { })
                      })
              ) : (
                isExanded ? React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "Collapse content",
                        classes: iconButton,
                        onClick: (function (e) {
                            e.stopPropagation();
                            return Curry._1(setIsExpanded, (function (param) {
                                          return false;
                                        }));
                          }),
                        placement_tooltip: "top",
                        title_tooltip: I18N$BsConsole.get(undefined, "Collapse content"),
                        children: React.createElement(KeyboardArrowUp.default, { })
                      }) : null
              ));
}

var component = ReasonReact.statelessComponent("DistributionExpandedableRow");

function make(label, isHovered, openMenu, children) {
  return ReasonReactCompat.wrapReactForReasonReact(DistributionExpandableRow, {
              label: label,
              isHovered: isHovered,
              openMenu: openMenu
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = DistributionExpandableRow;

exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
