// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Colors$BsConsole = require("../../Colors.js");
var BugBreadcrumbsUtils$BsConsole = require("./BugBreadcrumbsUtils.js");

var rowCompactStyle = Css.style(/* :: */[
      Css.height(Css.pct(100)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var rowExpandedStyle = Css.style(/* :: */[
      Css.marginTop(Css.px(25)),
      /* [] */0
    ]);

function rowStyle(isExpanded) {
  if (isExpanded) {
    return Css.merge(/* :: */[
                rowCompactStyle,
                /* :: */[
                  rowExpandedStyle,
                  /* [] */0
                ]
              ]);
  } else {
    return rowCompactStyle;
  }
}

function BugBreadcrumbsTextViews$Type_(Props) {
  var text = Props.text;
  var textColor = Props.textColor;
  var isExpandedOpt = Props.isExpanded;
  var isExpanded = isExpandedOpt !== undefined ? isExpandedOpt : false;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: isExpanded ? /* flexStart */662439529 : /* center */98248149,
              className: rowStyle(isExpanded),
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.backgroundColor(/* transparent */582626130),
                          /* :: */[
                            Css.padding(Css.px(3)),
                            /* :: */[
                              Css.color(Css.hex(textColor)),
                              /* :: */[
                                Css.border(Css.px(1), Css.solid, Css.hex(textColor)),
                                /* :: */[
                                  Css.borderRadius(Css.px(3)),
                                  /* :: */[
                                    Css.textAlign(/* center */98248149),
                                    /* :: */[
                                      Css.fontFamily("Roboto"),
                                      /* :: */[
                                        Css.fontWeight(/* normal */812216871),
                                        /* :: */[
                                          Css.fontSize(Css.px(13)),
                                          /* :: */[
                                            Css.lineHeight(Css.px(15)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, I18N$BsConsole.showSkip(text))
            });
}

var Type_ = {
  make: BugBreadcrumbsTextViews$Type_
};

function BugBreadcrumbsTextViews$Level(Props) {
  var text = Props.text;
  var adornment = Props.adornment;
  var isExpandedOpt = Props.isExpanded;
  var isExpanded = isExpandedOpt !== undefined ? isExpandedOpt : false;
  var textElement = React.createElement("div", {
        className: Css.style(/* :: */[
              Css.backgroundColor(/* transparent */582626130),
              /* :: */[
                Css.padding(Css.px(3)),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey0)),
                  /* :: */[
                    Css.textAlign(/* left */-944764921),
                    /* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(13)),
                          /* :: */[
                            Css.lineHeight(Css.px(15)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ])
      }, I18N$BsConsole.showSkip(text));
  if (adornment !== undefined) {
    return React.createElement(Row2$BsConsole.make, {
                alignItems: isExpanded ? /* flexStart */662439529 : /* center */98248149,
                className: Css.merge(/* :: */[
                      rowStyle(isExpanded),
                      /* :: */[
                        Css.style(/* :: */[
                              Css.marginLeft(Css.px(-12)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]),
                children: null
              }, Caml_option.valFromOption(adornment), textElement);
  } else {
    return React.createElement(Row2$BsConsole.make, {
                alignItems: isExpanded ? /* flexStart */662439529 : /* center */98248149,
                className: rowStyle(isExpanded),
                children: textElement
              });
  }
}

var Level = {
  make: BugBreadcrumbsTextViews$Level
};

function BugBreadcrumbsTextViews$Description(Props) {
  var message = Props.message;
  return React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
              minWidth: /* Px */Block.__(0, [200]),
              text: message
            });
}

var Description = {
  make: BugBreadcrumbsTextViews$Description
};

function BugBreadcrumbsTextViews$Timestamp(Props) {
  var timestamp = Props.timestamp;
  var isExpandedOpt = Props.isExpanded;
  var isExpanded = isExpandedOpt !== undefined ? isExpandedOpt : false;
  var str = BugBreadcrumbsUtils$BsConsole.dateTimeMsString(timestamp);
  return React.createElement(Row2$BsConsole.make, {
              alignItems: isExpanded ? /* flexStart */662439529 : /* center */98248149,
              className: rowStyle(isExpanded),
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.textAlign(/* center */98248149),
                          /* :: */[
                            Css.fontFamily("Roboto"),
                            /* :: */[
                              Css.fontWeight(/* normal */812216871),
                              /* :: */[
                                Css.fontSize(Css.px(13)),
                                /* :: */[
                                  Css.lineHeight(Css.px(15)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, I18N$BsConsole.showSkip(str))
            });
}

var Timestamp = {
  make: BugBreadcrumbsTextViews$Timestamp
};

exports.rowCompactStyle = rowCompactStyle;
exports.rowExpandedStyle = rowExpandedStyle;
exports.rowStyle = rowStyle;
exports.Type_ = Type_;
exports.Level = Level;
exports.Description = Description;
exports.Timestamp = Timestamp;
/* rowCompactStyle Not a pure module */
