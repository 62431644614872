// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Lazy = require("bs-platform/lib/js/lazy.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var __BACKTRACE_DEFAULT_CDN_URL = "https://cdn-bt.global.ssl.fastly.net";

var __REGEX_FASTLY_CDN_URL = new RegExp("https://.*fastly.net/.*/main.js");

function appScriptNotFound(passThrough) {
  var __x = Backtrace$BsConsole.Attributes.make(undefined);
  var __x$1 = Backtrace$BsConsole.Attributes.addString("cdn.lookup", "Fastly.getBaseUrl", __x);
  Backtrace$BsConsole.Client.send(Backtrace$BsConsole.Attributes.addString("src_module", "Fastly/Internal.Report.appScriptNotFound", __x$1), /* `string */[
        288368849,
        "Console Application: Fastly::APP_SCRIPT::NOT_FOUND"
      ]);
  return passThrough;
}

function appScriptSrcParseFailure(error, passThrough) {
  var __x = Backtrace$BsConsole.Attributes.make(undefined);
  var __x$1 = Backtrace$BsConsole.Attributes.addString("cdn.lookup", "Fastly.getBaseUrl", __x);
  var __x$2 = Backtrace$BsConsole.Attributes.addString("cdn.lookup.parse.fail", "Fastly.getBaseUrl", __x$1);
  Backtrace$BsConsole.Client.send(Backtrace$BsConsole.Attributes.addString("src_module", "Fastly/Internal.Report.appScriptSrcParseFailure", __x$2), /* `string */[
        288368849,
        error
      ]);
  return passThrough;
}

var Report = {
  appScriptNotFound: appScriptNotFound,
  appScriptSrcParseFailure: appScriptSrcParseFailure
};

function ifAppScriptNotFoundThenSendReport(maybe) {
  if (maybe !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(maybe));
  } else {
    return appScriptNotFound(undefined);
  }
}

function isAppScriptCDNSrc(src) {
  return __REGEX_FASTLY_CDN_URL.test(src);
}

function parseSourceIntoBaseURL(src) {
  try {
    return new URL(src).origin;
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error[0] === Js_exn.$$Error) {
      return appScriptSrcParseFailure(Belt_Option.getWithDefault(error[1].message, "Unknown Parsing Error"), undefined);
    }
    throw error;
  }
}

function getBaseUrl(param) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(ifAppScriptNotFoundThenSendReport(Caml_option.undefined_to_opt(Belt_Array.keepMap(Array.prototype.slice.call(document.getElementsByTagName("script")), (function (param) {
                                  return Caml_option.nullable_to_opt(param.getAttribute("src"));
                                })).find(isAppScriptCDNSrc))), parseSourceIntoBaseURL), __BACKTRACE_DEFAULT_CDN_URL);
}

var getBaseUrlLazy = Lazy.from_fun(getBaseUrl);

var Internal = {
  Report: Report,
  ifAppScriptNotFoundThenSendReport: ifAppScriptNotFoundThenSendReport,
  isAppScriptCDNSrc: isAppScriptCDNSrc,
  parseSourceIntoBaseURL: parseSourceIntoBaseURL,
  getBaseUrl: getBaseUrl,
  getBaseUrlLazy: getBaseUrlLazy
};

function getBaseUrl$1(param) {
  return CamlinternalLazy.force(getBaseUrlLazy);
}

function getResourceUrl(namespace, filePath) {
  return [
            CamlinternalLazy.force(getBaseUrlLazy),
            namespace,
            filePath
          ].join("/");
}

function getAssetUrl(namespace, filePath) {
  return getResourceUrl(namespace, filePath.join("/"));
}

exports.__BACKTRACE_DEFAULT_CDN_URL = __BACKTRACE_DEFAULT_CDN_URL;
exports.__REGEX_FASTLY_CDN_URL = __REGEX_FASTLY_CDN_URL;
exports.Internal = Internal;
exports.getBaseUrl = getBaseUrl$1;
exports.getResourceUrl = getResourceUrl;
exports.getAssetUrl = getAssetUrl;
/* __REGEX_FASTLY_CDN_URL Not a pure module */
