// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Parse$BsConsole = require("./Parse.js");
var Format$BsConsole = require("./Format.js");
var Morgue$BsConsole = require("./Morgue.js");
var ObjectID$BsConsole = require("../../ObjectID.js");
var ResponseUtil$BsConsole = require("./ResponseUtil.js");
var MetadataTimes$BsConsole = require("../../MetadataTimes.js");

function columnsDescDecoder(json) {
  var match = Parse$BsConsole.getColumnNameFormatType(json);
  return {
          name: match[0],
          format: match[1],
          type_: match[2]
        };
}

function parseColumnsDesc(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("columns_desc", (function (json) {
                              return Json_decode.list(columnsDescDecoder, json);
                            }), json);
              }), json);
}

var columns = parseColumnsDesc;

function responseWithMorgue(json, columns) {
  var response$prime = Json_decode.field("response", ResponseUtil$BsConsole.identity, json);
  var unpacked = Morgue$BsConsole.unpack(response$prime);
  var groups = Json_decode.field("*", ResponseUtil$BsConsole.identity, unpacked);
  return Json_decode.list((function (group) {
                return List.append(/* :: */[
                            /* tuple */[
                              "id",
                              Json_decode.field("id", (function (eta) {
                                      var param;
                                      return Format$BsConsole.parse2PointOh(undefined, undefined, param, eta);
                                    }), group)
                            ],
                            /* [] */0
                          ], List.map((function (column) {
                                  var partial_arg = column.name;
                                  var partial_arg$1 = column.format;
                                  var partial_arg$2 = column.type_;
                                  var maybeValue = Json_decode.field(column.name, (function (param) {
                                          return Format$BsConsole.parse2PointOh(partial_arg$2, partial_arg$1, partial_arg, param);
                                        }), group);
                                  return /* tuple */[
                                          column.name,
                                          maybeValue
                                        ];
                                }), columns));
              }), groups);
}

function metadata(json) {
  return {
          hasNextPage: Json_decode.withDefault(false, (function (json) {
                  var rows = Json_decode.at(/* :: */[
                          "_",
                          /* :: */[
                            "runtime",
                            /* :: */[
                              "filter",
                              /* :: */[
                                "rows",
                                /* [] */0
                              ]
                            ]
                          ]
                        ], Json_decode.$$int)(json);
                  var limit = Json_decode.at(/* :: */[
                          "response",
                          /* :: */[
                            "pagination",
                            /* :: */[
                              "limit",
                              /* [] */0
                            ]
                          ]
                        ], Json_decode.$$int)(json);
                  var offset = Json_decode.at(/* :: */[
                          "response",
                          /* :: */[
                            "pagination",
                            /* :: */[
                              "offset",
                              /* [] */0
                            ]
                          ]
                        ], Json_decode.$$int)(json);
                  return rows > (limit + offset | 0);
                }), json),
          instances: Json_decode.withDefault(undefined, Json_decode.at(/* :: */[
                    "_",
                    /* :: */[
                      "runtime",
                      /* :: */[
                        "filter",
                        /* :: */[
                          "rows",
                          /* [] */0
                        ]
                      ]
                    ]
                  ], (function (param) {
                      return Json_decode.optional(Json_decode.$$int, param);
                    })), json),
          pagination: Json_decode.withDefault(undefined, (function (json) {
                  return Json_decode.optional((function (json) {
                                return {
                                        limit: Json_decode.at(/* :: */[
                                                "response",
                                                /* :: */[
                                                  "pagination",
                                                  /* :: */[
                                                    "limit",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ], Json_decode.$$int)(json),
                                        offset: Json_decode.at(/* :: */[
                                                "response",
                                                /* :: */[
                                                  "pagination",
                                                  /* :: */[
                                                    "offset",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ], Json_decode.$$int)(json)
                                      };
                              }), json);
                }), json),
          tx: ObjectID$BsConsole.fromDecimalString(Json_decode.withDefault("1", Json_decode.at(/* :: */[
                        "_",
                        /* :: */[
                          "tx",
                          /* [] */0
                        ]
                      ], (function (json) {
                          return Json_decode.string(JSON.stringify(json));
                        })), json)),
          times: Json_decode.withDefault(undefined, Json_decode.at(/* :: */[
                    "_",
                    /* :: */[
                      "runtime",
                      /* [] */0
                    ]
                  ], MetadataTimes$BsConsole.decode), json)
        };
}

exports.columnsDescDecoder = columnsDescDecoder;
exports.parseColumnsDesc = parseColumnsDesc;
exports.columns = columns;
exports.responseWithMorgue = responseWithMorgue;
exports.metadata = metadata;
/* Parse-BsConsole Not a pure module */
