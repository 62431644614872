// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BtCard$BsConsole = require("../utilities/BtCard.js");
var Colors$BsConsole = require("../Colors.js");
var WfParser$BsConsole = require("../workflow/WfParser.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Tooltip = require("@material-ui/core/Tooltip");
var ExporterStoreApi$BsConsole = require("./api/ExporterStoreApi.js");
var ExporterAdapterApi$BsConsole = require("./api/ExporterAdapterApi.js");
var ExporterDeleteModal$BsConsole = require("./ExporterDeleteModal.js");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");
var ExporterCreateOrUpdateStoreModal$BsConsole = require("./ExporterCreateOrUpdateStoreModal.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

function getSchemeForAdapter(adapterName, adapters) {
  return Belt_Array.getBy(adapters, (function (param) {
                return Caml_obj.caml_equal(param[0], adapterName);
              }));
}

function ExporterStores$DisplayStoreError(Props) {
  var store = Props.store;
  var err = store.meta.error;
  if (err === undefined) {
    return null;
  }
  var errs = err.message.split(":");
  var match = errs.length;
  if (match === 0) {
    return null;
  }
  if (match === 1) {
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.px(8)),
                      /* [] */0
                    ])
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Button */10,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.redDark)),
                          /* :: */[
                            Css.marginRight(Css.px(5)),
                            /* [] */0
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "Error:")
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.redDark)),
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.dynamic(Caml_array.caml_array_get(errs, 0).charAt(0).toUpperCase() + Caml_array.caml_array_get(errs, 0).substring(1))
                  }));
  }
  var func = function (param, param$1) {
    return param$1.slice(1, param);
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.px(8)),
                    /* [] */0
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* :: */[
                          Css.marginRight(Css.px(5)),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.dynamic(Caml_array.caml_array_get(errs, 0).charAt(0).toUpperCase() + Caml_array.caml_array_get(errs, 0).substring(1) + ":")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.dynamic(Curry._2(func, errs.length, errs).join(":"))
                }));
}

var DisplayStoreError = {
  make: ExporterStores$DisplayStoreError
};

function ExporterStores$ViewConfiguration(Props) {
  var store = Props.store;
  var tmp;
  try {
    tmp = I18N$BsConsole.dynamic(JSON.stringify(JSON.parse(store.configuration), null, 2));
  }
  catch (exn){
    tmp = I18N$BsConsole.show(undefined, "Error parsing configuration");
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ExporterStores$DisplayStoreError, {
                  store: store
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* :: */[
                          Css.marginRight(Css.px(5)),
                          /* :: */[
                            Css.display(Css.block),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Configuration:")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* :: */[
                          Css.wordBreak(/* breakAll */-323760734),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement("pre", {
                        className: Css.style(/* :: */[
                              Css.whiteSpace(/* preWrap */660870029),
                              /* [] */0
                            ])
                      }, tmp)
                }));
}

var ViewConfiguration = {
  make: ExporterStores$ViewConfiguration
};

function ExporterStores$ViewResource(Props) {
  var store = Props.store;
  var config = Props.config;
  return React.createElement(React.Fragment, undefined, React.createElement(ExporterStores$DisplayStoreError, {
                  store: store
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* :: */[
                          Css.marginRight(Css.px(5)),
                          /* :: */[
                            Css.display(Css.block),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Resource:")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* :: */[
                          Css.wordBreak(/* breakAll */-323760734),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.showSkip(Belt_Option.getWithDefault(ExporterStoreApi$BsConsole.getFullResourceUrl(store.meta.resource, config, ""), I18N$BsConsole.get(undefined, "Unknown resource")))
                }));
}

var ViewResource = {
  make: ExporterStores$ViewResource
};

function ExporterStores(Props) {
  var token = Props.token;
  var config = Props.config;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var stores = Props.stores;
  var setStores = Props.setStores;
  var $$exports = Props.exports;
  var match = React.useState((function () {
          return [];
        }));
  var setAdapters = match[1];
  var adapters = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setcreateOrUpdateModal = match$1[1];
  var createOrUpdateModal = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setStoreDeleteModal = match$2[1];
  var storeDeleteModal = match$2[0];
  var connected = Belt_List.map(stores, (function (store) {
          return Belt_List.has($$exports, store.id, (function (a, b) {
                        return a.storeId === b;
                      }));
        }));
  React.useEffect((function () {
          var arg = ExporterAdapterApi$BsConsole.get(endpoint, universeName, projectName, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setAdapters, (function (param) {
                                return result;
                              }));
                }));
          
        }), /* tuple */[
        token,
        endpoint,
        universeName,
        projectName
      ]);
  var handleSave = function (adapterMaybe, configuration, period) {
    if (createOrUpdateModal !== undefined && createOrUpdateModal && adapterMaybe !== undefined) {
      var existingStore = createOrUpdateModal[0][0];
      var arg = ExporterStoreApi$BsConsole.update(endpoint, universeName, projectName, configuration === "" ? "{}" : configuration, existingStore.id, Caml_option.some(period), undefined);
      return Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                    }
                    var updated = Belt_List.map(stores, (function (store) {
                            if (store.id === existingStore.id) {
                              return {
                                      id: existingStore.id,
                                      adapter: existingStore.adapter,
                                      configuration: configuration,
                                      lastTs: existingStore.lastTs,
                                      period: period,
                                      meta: {
                                        error: existingStore.meta.error,
                                        direction: existingStore.meta.direction,
                                        resource: existingStore.meta.resource
                                      }
                                    };
                            } else {
                              return store;
                            }
                          }));
                    return Curry._1(setStores, (function (param) {
                                  return updated;
                                }));
                  }));
    }
    if (adapterMaybe === undefined) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Cannot create store; no adapter specified."));
    }
    var arg$1 = ExporterStoreApi$BsConsole.create(endpoint, universeName, projectName, adapterMaybe[0], configuration, Caml_option.some(period), undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var updated = Belt_List.concat(stores, /* :: */[
                        resp[0],
                        /* [] */0
                      ]);
                  return Curry._1(setStores, (function (param) {
                                return updated;
                              }));
                }));
  };
  var handleDelete = function (param) {
    if (storeDeleteModal === undefined) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Cannot delete store; no store specified."));
    }
    var arg = ExporterStoreApi$BsConsole.$$delete(endpoint, universeName, projectName, storeDeleteModal.id, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var updated = Belt_List.keep(stores, (function (store) {
                          return store.id !== storeDeleteModal.id;
                        }));
                  return Curry._1(setStores, (function (param) {
                                return updated;
                              }));
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(ExporterCreateOrUpdateStoreModal$BsConsole.make, {
                  token: token,
                  open: Belt_Option.isSome(createOrUpdateModal),
                  onClose: (function (param) {
                      return Curry._1(setcreateOrUpdateModal, (function (param) {
                                    
                                  }));
                    }),
                  handleSave: handleSave,
                  adapters: adapters,
                  editStore: createOrUpdateModal !== undefined && createOrUpdateModal ? createOrUpdateModal[0] : undefined
                }), React.createElement(ExporterDeleteModal$BsConsole.make, {
                  open: storeDeleteModal !== undefined,
                  onClose: (function (param) {
                      return Curry._1(setStoreDeleteModal, (function (param) {
                                    
                                  }));
                    }),
                  handleDelete: handleDelete,
                  title: I18N$BsConsole.get(undefined, "Delete metrics store"),
                  itemName: I18N$BsConsole.get(undefined, "metrics store")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setcreateOrUpdateModal, (function (param) {
                                    return /* Add */0;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create a metrics store")
                }), React.createElement(Row2$BsConsole.make, {
                  flexWrap: /* wrap */-822134326,
                  children: Belt_List.toArray(Belt_List.mapWithIndex(stores, (function (index, store) {
                              var adapterMatch = getSchemeForAdapter(store.adapter, adapters);
                              if (adapterMatch === undefined) {
                                return null;
                              }
                              var adapterConfigurationScheme = adapterMatch[1];
                              var name = adapterMatch[0];
                              if (adapterConfigurationScheme !== undefined && adapterConfigurationScheme.tag) {
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "A configured adapter might be missing due to error.") + (" " + WfParser$BsConsole.getUserFriendlyError(adapterConfigurationScheme[0]))));
                                return null;
                              }
                              var match = store.configuration;
                              var tmp = match === "{}" ? /* tuple */[
                                  I18N$BsConsole.get(undefined, "View details"),
                                  I18N$BsConsole.get(undefined, "Hide details"),
                                  React.createElement(ExporterStores$ViewResource, {
                                        store: store,
                                        config: config
                                      })
                                ] : /* tuple */[
                                  I18N$BsConsole.get(undefined, "View details"),
                                  I18N$BsConsole.get(undefined, "Hide details"),
                                  React.createElement(ExporterStores$ViewConfiguration, {
                                        store: store
                                      })
                                ];
                              return React.createElement(React.Fragment, {
                                          children: React.createElement(BtCard$BsConsole.make, {
                                                cardInnerHeight: undefined,
                                                title: store.adapter.charAt(0).toUpperCase() + store.adapter.substring(1),
                                                content: Caml_option.some(React.createElement(Col2$BsConsole.make, {
                                                          children: null
                                                        }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Button */10,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(5)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  children: I18N$BsConsole.show(undefined, "Id:")
                                                                }), React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Caption */11,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* [] */0
                                                                      ]),
                                                                  children: I18N$BsConsole.showSkip(store.id)
                                                                })), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Button */10,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(5)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  children: I18N$BsConsole.show(undefined, "Last transmitted:")
                                                                }), React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Caption */11,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* [] */0
                                                                      ]),
                                                                  children: I18N$BsConsole.showSkip(store.lastTs === 0 ? "--" : new Date(store.lastTs * 1000).toLocaleString())
                                                                })), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Button */10,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(5)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  children: I18N$BsConsole.show(undefined, "Period:")
                                                                }), React.createElement(BtTypography$BsConsole.make, {
                                                                  variant: /* Caption */11,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* [] */0
                                                                      ]),
                                                                  children: I18N$BsConsole.dynamic(String(store.period | 0) + I18N$BsConsole.get(undefined, "seconds"))
                                                                })))),
                                                status: Belt_Option.getWithDefault(Belt_List.get(connected, index), false) ? Caml_option.some(React.createElement(BtCard$BsConsole.Status.make, {
                                                            error: store.meta.error !== undefined
                                                          })) : Caml_option.some(React.createElement(Tooltip.default, {
                                                            children: React.createElement(ErrorOutline.default, {
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                                        /* [] */0
                                                                      ])
                                                                }),
                                                            title: I18N$BsConsole.get(undefined, "Warning: this store is not connected to any metric sets.")
                                                          })),
                                                more: /* :: */[
                                                  /* tuple */[
                                                    I18N$BsConsole.get(undefined, "Edit"),
                                                    (function (param) {
                                                        return Curry._1(setcreateOrUpdateModal, (function (param) {
                                                                      return /* Edit */[/* tuple */[
                                                                                store,
                                                                                /* tuple */[
                                                                                  name,
                                                                                  adapterConfigurationScheme
                                                                                ]
                                                                              ]];
                                                                    }));
                                                      })
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      I18N$BsConsole.get(undefined, "Delete"),
                                                      (function (param) {
                                                          return Curry._1(setStoreDeleteModal, (function (param) {
                                                                        return store;
                                                                      }));
                                                        })
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ],
                                                expand: tmp
                                              }),
                                          key: store.id
                                        });
                            })))
                }));
}

var make = ExporterStores;

exports.pageMarginBottom = pageMarginBottom;
exports.getSchemeForAdapter = getSchemeForAdapter;
exports.DisplayStoreError = DisplayStoreError;
exports.ViewConfiguration = ViewConfiguration;
exports.ViewResource = ViewResource;
exports.make = make;
/* pageMarginBottom Not a pure module */
