// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Shell$BsConsole = require("../../shell.js");
var Task2$BsConsole = require("../../Task2.js");
var ExporterMetrics$BsConsole = require("../ExporterMetrics.js");

function fromJson(json) {
  return {
          href: Json_decode.field("href", Json_decode.string, json)
        };
}

var $$URL = {
  fromJson: fromJson
};

var resource = "metrics_stores";

function metaErrorFromJson(json) {
  return {
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

function getFullResourceUrl(resource, config, defaultEndpoint) {
  if (resource === undefined) {
    return ;
  }
  var endpoint = Shell$BsConsole.getServiceEndpoint(config, "metrics-exporter", defaultEndpoint);
  var fullUrl = fromJson(new URL(resource, endpoint));
  return fullUrl.href;
}

function metaFromJson(json) {
  return {
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", metaErrorFromJson, param);
                }), json),
          direction: Json_decode.optional((function (param) {
                  return Json_decode.field("direction", Json_decode.string, param);
                }), json),
          resource: Json_decode.optional((function (param) {
                  return Json_decode.field("resource", Json_decode.string, param);
                }), json)
        };
}

function storeFromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          adapter: Json_decode.field("adapter", Json_decode.string, json),
          configuration: Json_decode.field("configuration", Json_decode.string, json),
          lastTs: Json_decode.field("last_ts", Json_decode.$$float, json),
          period: Json_decode.field("period", Json_decode.$$float, json),
          meta: Json_decode.field("_metadata", metaFromJson, json)
        };
}

function create(path, universeName, projectName, adapter, configuration, periodOpt, param) {
  var period = periodOpt !== undefined ? Caml_option.valFromOption(periodOpt) : undefined;
  ExporterMetrics$BsConsole.send(/* StoreCreate */3);
  var periodValue = period !== undefined ? period : 60;
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "adapter",
          adapter
        ],
        /* :: */[
          /* tuple */[
            "configuration",
            configuration === "" ? "{}" : configuration
          ],
          /* :: */[
            /* tuple */[
              "period",
              periodValue
            ],
            /* [] */0
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [storeFromJson(respBag.json)]);
              }), undefined, undefined);
}

function update(path, universeName, projectName, configuration, id, periodOpt, param) {
  var period = periodOpt !== undefined ? Caml_option.valFromOption(periodOpt) : undefined;
  var periodValue = period !== undefined ? period : 60;
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "configuration",
          configuration
        ],
        /* :: */[
          /* tuple */[
            "period",
            periodValue
          ],
          /* [] */0
        ]
      ]);
  ExporterMetrics$BsConsole.send(/* StoreUpdate */4);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Put */Block.__(1, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [respBag.json]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [Json_decode.list(storeFromJson, respBag.json)]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  ExporterMetrics$BsConsole.send(/* StoreDelete */5);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

exports.$$URL = $$URL;
exports.resource = resource;
exports.metaErrorFromJson = metaErrorFromJson;
exports.getFullResourceUrl = getFullResourceUrl;
exports.metaFromJson = metaFromJson;
exports.storeFromJson = storeFromJson;
exports.create = create;
exports.update = update;
exports.get = get;
exports.$$delete = $$delete;
/* Shell-BsConsole Not a pure module */
