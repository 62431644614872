// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Task$BsConsole = require("./task.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var ApiDescribe$BsConsole = require("./apiDescribe.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ApiDescribeDisabled$BsConsole = require("./apiDescribeDisabled.js");

function getFilteredAndSortedActiveAttributes(attributes) {
  var filteredAttributes = Belt_List.keep(attributes, (function (attr) {
          if (attr.custom) {
            return true;
          } else {
            return attr.statistics.used;
          }
        }));
  var sortedCustom = List.sort((function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }), List.filter((function (attr) {
                return attr.custom;
              }))(filteredAttributes));
  var sortedbuiltIns = List.sort((function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }), List.filter((function (attr) {
                return !attr.custom;
              }))(filteredAttributes));
  return Pervasives.$at(sortedCustom, sortedbuiltIns);
}

var component = ReasonReact.reducerComponent("FetchDescribe-BsConsole__Disabled");

function make(handleTask, token, project, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* Request */0 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    return Curry._2(handleTask, (function (j) {
                                                  var json = TaskCallback$BsConsole.getJson(j);
                                                  var disabled = ApiDescribeDisabled$BsConsole.Disabled.decode(json);
                                                  var active = ApiDescribeDisabled$BsConsole.Active.decode(json);
                                                  return Curry._1(self.send, /* Success */[{
                                                                active: active,
                                                                disabled: disabled
                                                              }]);
                                                }), Task$BsConsole.makeTask(/* DescribeWithDisabled */Block.__(4, [
                                                      token,
                                                      project
                                                    ])));
                                  })
                              ]);
                  case /* Update */1 :
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    return Curry._2(handleTask, (function (json) {
                                                  var disabled = ApiDescribeDisabled$BsConsole.Disabled.decode(TaskCallback$BsConsole.getJson(json));
                                                  var active = ApiDescribeDisabled$BsConsole.Active.decode(TaskCallback$BsConsole.getJson(json));
                                                  return Curry._1(self.send, /* Success */[{
                                                                active: active,
                                                                disabled: disabled
                                                              }]);
                                                }), Task$BsConsole.makeTask(/* DescribeWithDisabled */Block.__(4, [
                                                      token,
                                                      project
                                                    ])));
                                  })]);
                  case /* Failure */2 :
                      return /* NoUpdate */0;
                  
                }
              } else {
                var attributes = action[0];
                var active = getFilteredAndSortedActiveAttributes(attributes.active);
                var disabled = List.sort((function (a, b) {
                        return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
                      }), attributes.disabled);
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [{
                                  active: active,
                                  disabled: disabled
                                }])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Disabled = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponentWithRetainedProps("FactorSelect");

function make$1(handleTask, token, name, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: (function (self) {
              if (self.retainedProps !== name) {
                Curry._1(self.send, /* Request */Block.__(0, [name]));
              }
              return self.state;
            }),
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */Block.__(0, [name]));
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */0);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: name,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Update */0) {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                return Curry._2(handleTask, (function (json) {
                                              try {
                                                return Curry._1(self.send, /* Success */Block.__(1, [ApiDescribe$BsConsole.decodeExn(TaskCallback$BsConsole.getJson(json))]));
                                              }
                                              catch (raw_err){
                                                var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                                var error = Uncaught$BsConsole.castToJsExn(err);
                                                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FetchDescribe/Update", Backtrace$BsConsole.Attributes.make(undefined));
                                                Backtrace$BsConsole.Client.send(__x, /* `exn */[
                                                      5049499,
                                                      error
                                                    ]);
                                                return Curry._1(self.send, /* Failure */1);
                                              }
                                            }), Task$BsConsole.makeTask(/* Describe */Block.__(3, [
                                                  token,
                                                  name
                                                ])));
                              })]);
                } else {
                  return /* NoUpdate */0;
                }
              }
              if (action.tag) {
                var attributes = action[0];
                var filteredResults = getFilteredAndSortedActiveAttributes(attributes);
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [/* tuple */[
                                  filteredResults,
                                  attributes
                                ]])
                          }]);
              }
              var name$1 = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          remote: /* Loading */1
                        },
                        (function (self) {
                            return Curry._2(handleTask, (function (json) {
                                          try {
                                            return Curry._1(self.send, /* Success */Block.__(1, [ApiDescribe$BsConsole.decodeExn(TaskCallback$BsConsole.getJson(json))]));
                                          }
                                          catch (raw_err){
                                            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                            var error = Uncaught$BsConsole.castToJsExn(err);
                                            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FetchDescribe/Request", Backtrace$BsConsole.Attributes.make(undefined));
                                            Backtrace$BsConsole.Client.send(__x, /* `exn */[
                                                  5049499,
                                                  error
                                                ]);
                                            return Curry._1(self.send, /* Failure */1);
                                          }
                                        }), Task$BsConsole.makeTask(/* Describe */Block.__(3, [
                                              token,
                                              name$1
                                            ])));
                          })
                      ]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.getFilteredAndSortedActiveAttributes = getFilteredAndSortedActiveAttributes;
exports.Disabled = Disabled;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
