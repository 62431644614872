// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BpgController$BsConsole = require("../../BpgController.js");
var PlainTextView$BsConsole = require("../PlainTextView.js");
var Dedupe_InfoView$BsConsole = require("./Dedupe_InfoView.js");
var BillingLimitsFetch$BsConsole = require("../../BillingLimitsFetch.js");

var dedupeTitle = I18N$BsConsole.get(undefined, "Deduplication configuration");

var component = RR$BsConsole.reducerComponent("PS_DedupeConfig-BsConsole");

function make(shell, project, handleTask, token, handleChangeUrl, objectId, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var editView = match.editView;
              var addView = match.addView;
              var tmp;
              if (typeof shell === "number") {
                tmp = shell === /* NotAsked */0 ? null : ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(dedupeTitle, /* Loader */1, []));
              } else if (shell.tag) {
                tmp = ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(dedupeTitle, /* Error */0, []));
              } else {
                var config = shell[0];
                var universe = config.universe;
                tmp = ReasonReact.element(undefined, undefined, BillingLimitsFetch$BsConsole.make(config.universe.name, handleTask, token, (function (limitsRemote, param) {
                            return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.InstanceRetention.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                  var match = state.remote;
                                                  var exit = 0;
                                                  var exit$1 = 0;
                                                  if (typeof match === "number") {
                                                    if (match === /* NotAsked */0) {
                                                      exit$1 = 3;
                                                    }
                                                    
                                                  } else if (match.tag) {
                                                    exit$1 = 3;
                                                  } else if (typeof limitsRemote === "number") {
                                                    if (limitsRemote === /* NotAsked */0) {
                                                      exit = 2;
                                                    } else {
                                                      exit$1 = 3;
                                                    }
                                                  } else if (limitsRemote.tag) {
                                                    exit = 2;
                                                  } else if (typeof shell === "number") {
                                                    if (shell === /* NotAsked */0) {
                                                      return null;
                                                    }
                                                    exit = 2;
                                                  } else {
                                                    if (shell.tag) {
                                                      return null;
                                                    }
                                                    var config = shell[0];
                                                    return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, BpgTask$BsConsole.Deduplication.fetchAll, undefined, (function (remote, refetch) {
                                                                      if (typeof remote === "number") {
                                                                        if (remote === /* NotAsked */0) {
                                                                          return null;
                                                                        } else {
                                                                          return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(dedupeTitle, /* Loader */1, []));
                                                                        }
                                                                      }
                                                                      if (remote.tag) {
                                                                        return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(dedupeTitle, /* Error */0, []));
                                                                      }
                                                                      var __x = Belt_List.fromArray(remote[0][0]);
                                                                      var resultsForProject = Belt_List.filter(__x, (function (r) {
                                                                              return r.project === project.pid;
                                                                            }));
                                                                      return ReasonReact.element(undefined, undefined, Dedupe_InfoView$BsConsole.make(resultsForProject, (function (param) {
                                                                                        return Curry._1(send, /* ShowAddView */0);
                                                                                      }), (function (param) {
                                                                                        return Curry._1(send, /* HideAddView */1);
                                                                                      }), (function (ruleObject) {
                                                                                        return Curry._1(send, /* ShowEditView */[ruleObject]);
                                                                                      }), (function (param) {
                                                                                        return Curry._1(send, /* HideEditView */2);
                                                                                      }), addView, editView, universe.name, project, token, config.user.uid, refetch, objectId, handleChangeUrl, []));
                                                                    })));
                                                  }
                                                  if (exit$1 === 3 && !(typeof limitsRemote === "number" && limitsRemote !== 0)) {
                                                    exit = 2;
                                                  }
                                                  if (exit === 2) {
                                                    if (typeof shell !== "number") {
                                                      return null;
                                                    }
                                                    if (shell === 0) {
                                                      return null;
                                                    }
                                                    
                                                  }
                                                  return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(dedupeTitle, /* Loader */1, []));
                                                }))));
                          })));
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [tmp]));
            }),
          initialState: (function (param) {
              return {
                      addView: false,
                      editView: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            addView: state.addView,
                            editView: action[0]
                          }]);
              }
              switch (action) {
                case /* ShowAddView */0 :
                    return /* Update */Block.__(0, [{
                                addView: true,
                                editView: undefined
                              }]);
                case /* HideAddView */1 :
                    return /* Update */Block.__(0, [{
                                addView: false,
                                editView: state.editView
                              }]);
                case /* HideEditView */2 :
                    return /* Update */Block.__(0, [{
                                addView: state.addView,
                                editView: undefined
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.dedupeTitle = dedupeTitle;
exports.component = component;
exports.make = make;
/* dedupeTitle Not a pure module */
