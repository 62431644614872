// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_SetString = require("bs-platform/lib/js/belt_SetString.js");
var ListUtils$BsConsole = require("../../ListUtils.js");

function add(v$prime, t$prime) {
  return ListUtils$BsConsole.uniq(Belt_List.concat(t$prime, /* :: */[
                  v$prime,
                  /* [] */0
                ]));
}

function union(t$prime, t$prime$prime) {
  return Belt_SetString.toList(Belt_SetString.union(Belt_SetString.fromArray($$Array.of_list(t$prime)), Belt_SetString.fromArray($$Array.of_list(t$prime$prime))));
}

function remove(v$prime, t$prime) {
  return Belt_List.keep(t$prime, (function (v) {
                return Caml_obj.caml_notequal(v, v$prime);
              }));
}

function get(v$prime, t$prime) {
  return Belt_List.getBy(t$prime, (function (v) {
                return Caml_obj.caml_equal(v, v$prime);
              }));
}

function toJson(t$prime) {
  return Json_encode.array((function (prim) {
                return prim;
              }), Belt_List.toArray(t$prime));
}

function fromJson(json) {
  return Belt_List.fromArray(Json_decode.array(Json_decode.string, json));
}

function eq(t$prime, t$prime$prime) {
  return Belt_SetString.eq(Belt_SetString.fromArray($$Array.of_list(t$prime)), Belt_SetString.fromArray($$Array.of_list(t$prime$prime)));
}

var empty = /* [] */0;

var size = Belt_List.size;

var toArray = Belt_List.toArray;

var fromArray = Belt_List.fromArray;

var toCrdb = toJson;

exports.empty = empty;
exports.add = add;
exports.union = union;
exports.remove = remove;
exports.get = get;
exports.size = size;
exports.toArray = toArray;
exports.fromArray = fromArray;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.toCrdb = toCrdb;
exports.eq = eq;
/* No side effect */
