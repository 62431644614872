// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function getFormattedNumber(num) {
  return new Intl.NumberFormat().format(num);
}

exports.getFormattedNumber = getFormattedNumber;
/* No side effect */
