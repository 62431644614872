// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");

function _get(key) {
  switch (key) {
    case /* CompareReleases_Empty_Title */0 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "No releases found.")]);
    case /* CompareReleases_Empty_Description */1 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Consider expanding the time frame, updating your filters, or changing your release attribute.")]);
    case /* Triage_Empty_Title */2 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "No issue data.")]);
    case /* Instances_Empty_Title */4 :
    case /* Explore_Empty_Title */6 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "No error data.")]);
    case /* Triage_Empty_Description */3 :
    case /* Instances_Empty_Description */5 :
    case /* Explore_Empty_Description */7 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Consider expanding the time frame or updating your filters.")]);
    case /* Welcome */8 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Welcome!")]);
    case /* No_Crashes_Uploaded */9 :
        return /* String */Block.__(0, ["It looks like this project has not had any crashes uploaded yet. If you need help getting set up, please take a moment to look at our integration documentation."]);
    case /* SelfServiceRetentionPolicy */10 :
        return /* Component */Block.__(1, [React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This instance of Backtrace is configured on the Self Service tier.")])), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "On the Self Service tier, metadata for crashes and exceptions are retained for 365 days. This allows you to query for and understand impact of issues that have occured in the 365 days.")])), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Dump files and other attachments associated to the crashes and exceptions are retained for 90 days. This allows you to understand context and use the debugger to address issues that have occured in the last 90 days.")])), React.createElement("br", undefined), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [React.createElement("span", undefined, I18N$BsConsole.show(undefined, "If you would like to configure your own retention policies, reach out to our account team about upgrading your instance to the Enterprise tier."))])))]);
    case /* RP_Delete_All */11 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Delete all error data")]);
    case /* RP_Delete_All_Physical */12 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Delete all dumps and debugger view data")]);
    case /* RP_Compress_All_Physical */13 :
        return /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Compress all dumps (removes ability to reprocess)")]);
    
  }
}

function unwrap(message) {
  if (message.tag) {
    return message[0];
  } else {
    return I18N$BsConsole.showSkip(message[0]);
  }
}

function get(key) {
  return unwrap(_get(key));
}

exports._get = _get;
exports.unwrap = unwrap;
exports.get = get;
/* react Not a pure module */
