// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../Task2.js");

function versionResponseFromJson(json) {
  return Json_decode.field("_", (function (param) {
                return Json_decode.field("version", Json_decode.string, param);
              }), json);
}

function fetchServiceVersion(path) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + "/version")]), undefined, /* Get */0, undefined, (function (respBag) {
                var version = versionResponseFromJson(respBag.json);
                return /* Ok */Block.__(0, [version]);
              }), undefined, undefined);
}

var minimumRequiredVersion = "1.2.0";

var includesCandidateTickets = "1.3.6";

exports.minimumRequiredVersion = minimumRequiredVersion;
exports.includesCandidateTickets = includesCandidateTickets;
exports.versionResponseFromJson = versionResponseFromJson;
exports.fetchServiceVersion = fetchServiceVersion;
/* Task2-BsConsole Not a pure module */
