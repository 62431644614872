// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var aliceblue = /* `hex */[
  5194459,
  "F0F8FF"
];

var antiquewhite = /* `hex */[
  5194459,
  "FAEBD7"
];

var aqua = /* `hex */[
  5194459,
  "00FFFF"
];

var aquamarine = /* `hex */[
  5194459,
  "7FFFD4"
];

var azure = /* `hex */[
  5194459,
  "F0FFFF"
];

var beige = /* `hex */[
  5194459,
  "F5F5DC"
];

var bisque = /* `hex */[
  5194459,
  "FFE4C4"
];

var black = /* `hex */[
  5194459,
  "000000"
];

var blanchedalmond = /* `hex */[
  5194459,
  "FFEBCD"
];

var blue = /* `hex */[
  5194459,
  "0000FF"
];

var blueviolet = /* `hex */[
  5194459,
  "8A2BE2"
];

var brown = /* `hex */[
  5194459,
  "A52A2A"
];

var burlywood = /* `hex */[
  5194459,
  "DEB887"
];

var cadetblue = /* `hex */[
  5194459,
  "5F9EA0"
];

var chartreuse = /* `hex */[
  5194459,
  "7FFF00"
];

var chocolate = /* `hex */[
  5194459,
  "D2691E"
];

var coral = /* `hex */[
  5194459,
  "FF7F50"
];

var cornflowerblue = /* `hex */[
  5194459,
  "6495ED"
];

var cornsilk = /* `hex */[
  5194459,
  "FFF8DC"
];

var crimson = /* `hex */[
  5194459,
  "DC143C"
];

var cyan = /* `hex */[
  5194459,
  "00FFFF"
];

var darkblue = /* `hex */[
  5194459,
  "00008B"
];

var darkcyan = /* `hex */[
  5194459,
  "008B8B"
];

var darkgoldenrod = /* `hex */[
  5194459,
  "B8860B"
];

var darkgray = /* `hex */[
  5194459,
  "A9A9A9"
];

var darkgreen = /* `hex */[
  5194459,
  "006400"
];

var darkgrey = /* `hex */[
  5194459,
  "A9A9A9"
];

var darkkhaki = /* `hex */[
  5194459,
  "BDB76B"
];

var darkmagenta = /* `hex */[
  5194459,
  "8B008B"
];

var darkolivegreen = /* `hex */[
  5194459,
  "556B2F"
];

var darkorange = /* `hex */[
  5194459,
  "FF8C00"
];

var darkorchid = /* `hex */[
  5194459,
  "9932CC"
];

var darkred = /* `hex */[
  5194459,
  "8B0000"
];

var darksalmon = /* `hex */[
  5194459,
  "E9967A"
];

var darkseagreen = /* `hex */[
  5194459,
  "8FBC8F"
];

var darkslateblue = /* `hex */[
  5194459,
  "483D8B"
];

var darkslategray = /* `hex */[
  5194459,
  "2F4F4F"
];

var darkslategrey = /* `hex */[
  5194459,
  "2F4F4F"
];

var darkturquoise = /* `hex */[
  5194459,
  "00CED1"
];

var darkviolet = /* `hex */[
  5194459,
  "9400D3"
];

var deeppink = /* `hex */[
  5194459,
  "FF1493"
];

var deepskyblue = /* `hex */[
  5194459,
  "00BFFF"
];

var dimgray = /* `hex */[
  5194459,
  "696969"
];

var dimgrey = /* `hex */[
  5194459,
  "696969"
];

var dodgerblue = /* `hex */[
  5194459,
  "1E90FF"
];

var firebrick = /* `hex */[
  5194459,
  "B22222"
];

var floralwhite = /* `hex */[
  5194459,
  "FFFAF0"
];

var forestgreen = /* `hex */[
  5194459,
  "228B22"
];

var fuchsia = /* `hex */[
  5194459,
  "FF00FF"
];

var gainsboro = /* `hex */[
  5194459,
  "DCDCDC"
];

var ghostwhite = /* `hex */[
  5194459,
  "F8F8FF"
];

var gold = /* `hex */[
  5194459,
  "FFD700"
];

var goldenrod = /* `hex */[
  5194459,
  "DAA520"
];

var gray = /* `hex */[
  5194459,
  "808080"
];

var green = /* `hex */[
  5194459,
  "008000"
];

var greenyellow = /* `hex */[
  5194459,
  "ADFF2F"
];

var grey = /* `hex */[
  5194459,
  "808080"
];

var honeydew = /* `hex */[
  5194459,
  "F0FFF0"
];

var hotpink = /* `hex */[
  5194459,
  "FF69B4"
];

var indianred = /* `hex */[
  5194459,
  "CD5C5C"
];

var indigo = /* `hex */[
  5194459,
  "4B0082"
];

var ivory = /* `hex */[
  5194459,
  "FFFFF0"
];

var khaki = /* `hex */[
  5194459,
  "F0E68C"
];

var lavender = /* `hex */[
  5194459,
  "E6E6FA"
];

var lavenderblush = /* `hex */[
  5194459,
  "FFF0F5"
];

var lawngreen = /* `hex */[
  5194459,
  "7CFC00"
];

var lemonchiffon = /* `hex */[
  5194459,
  "FFFACD"
];

var lightblue = /* `hex */[
  5194459,
  "ADD8E6"
];

var lightcoral = /* `hex */[
  5194459,
  "F08080"
];

var lightcyan = /* `hex */[
  5194459,
  "E0FFFF"
];

var lightgoldenrodyellow = /* `hex */[
  5194459,
  "FAFAD2"
];

var lightgray = /* `hex */[
  5194459,
  "D3D3D3"
];

var lightgreen = /* `hex */[
  5194459,
  "90EE90"
];

var lightgrey = /* `hex */[
  5194459,
  "D3D3D3"
];

var lightpink = /* `hex */[
  5194459,
  "FFB6C1"
];

var lightsalmon = /* `hex */[
  5194459,
  "FFA07A"
];

var lightseagreen = /* `hex */[
  5194459,
  "20B2AA"
];

var lightskyblue = /* `hex */[
  5194459,
  "87CEFA"
];

var lightslategray = /* `hex */[
  5194459,
  "778899"
];

var lightslategrey = /* `hex */[
  5194459,
  "778899"
];

var lightsteelblue = /* `hex */[
  5194459,
  "B0C4DE"
];

var lightyellow = /* `hex */[
  5194459,
  "FFFFE0"
];

var lime = /* `hex */[
  5194459,
  "00FF00"
];

var limegreen = /* `hex */[
  5194459,
  "32CD32"
];

var linen = /* `hex */[
  5194459,
  "FAF0E6"
];

var magenta = /* `hex */[
  5194459,
  "FF00FF"
];

var maroon = /* `hex */[
  5194459,
  "800000"
];

var mediumaquamarine = /* `hex */[
  5194459,
  "66CDAA"
];

var mediumblue = /* `hex */[
  5194459,
  "0000CD"
];

var mediumorchid = /* `hex */[
  5194459,
  "BA55D3"
];

var mediumpurple = /* `hex */[
  5194459,
  "9370DB"
];

var mediumseagreen = /* `hex */[
  5194459,
  "3CB371"
];

var mediumslateblue = /* `hex */[
  5194459,
  "7B68EE"
];

var mediumspringgreen = /* `hex */[
  5194459,
  "00FA9A"
];

var mediumturquoise = /* `hex */[
  5194459,
  "48D1CC"
];

var mediumvioletred = /* `hex */[
  5194459,
  "C71585"
];

var midnightblue = /* `hex */[
  5194459,
  "191970"
];

var mintcream = /* `hex */[
  5194459,
  "F5FFFA"
];

var mistyrose = /* `hex */[
  5194459,
  "FFE4E1"
];

var moccasin = /* `hex */[
  5194459,
  "FFE4B5"
];

var navajowhite = /* `hex */[
  5194459,
  "FFDEAD"
];

var navy = /* `hex */[
  5194459,
  "000080"
];

var oldlace = /* `hex */[
  5194459,
  "FDF5E6"
];

var olive = /* `hex */[
  5194459,
  "808000"
];

var olivedrab = /* `hex */[
  5194459,
  "6B8E23"
];

var orange = /* `hex */[
  5194459,
  "FFA500"
];

var orangered = /* `hex */[
  5194459,
  "FF4500"
];

var orchid = /* `hex */[
  5194459,
  "DA70D6"
];

var palegoldenrod = /* `hex */[
  5194459,
  "EEE8AA"
];

var palegreen = /* `hex */[
  5194459,
  "98FB98"
];

var paleturquoise = /* `hex */[
  5194459,
  "AFEEEE"
];

var palevioletred = /* `hex */[
  5194459,
  "DB7093"
];

var papayawhip = /* `hex */[
  5194459,
  "FFEFD5"
];

var peachpuff = /* `hex */[
  5194459,
  "FFDAB9"
];

var peru = /* `hex */[
  5194459,
  "CD853F"
];

var pink = /* `hex */[
  5194459,
  "FFC0CB"
];

var plum = /* `hex */[
  5194459,
  "DDA0DD"
];

var powderblue = /* `hex */[
  5194459,
  "B0E0E6"
];

var purple = /* `hex */[
  5194459,
  "800080"
];

var rebeccapurple = /* `hex */[
  5194459,
  "663399"
];

var red = /* `hex */[
  5194459,
  "FF0000"
];

var rosybrown = /* `hex */[
  5194459,
  "BC8F8F"
];

var royalblue = /* `hex */[
  5194459,
  "4169E1"
];

var saddlebrown = /* `hex */[
  5194459,
  "8B4513"
];

var salmon = /* `hex */[
  5194459,
  "FA8072"
];

var sandybrown = /* `hex */[
  5194459,
  "F4A460"
];

var seagreen = /* `hex */[
  5194459,
  "2E8B57"
];

var seashell = /* `hex */[
  5194459,
  "FFF5EE"
];

var sienna = /* `hex */[
  5194459,
  "A0522D"
];

var silver = /* `hex */[
  5194459,
  "C0C0C0"
];

var skyblue = /* `hex */[
  5194459,
  "87CEEB"
];

var slateblue = /* `hex */[
  5194459,
  "6A5ACD"
];

var slategray = /* `hex */[
  5194459,
  "708090"
];

var slategrey = /* `hex */[
  5194459,
  "708090"
];

var snow = /* `hex */[
  5194459,
  "FFFAFA"
];

var springgreen = /* `hex */[
  5194459,
  "00FF7F"
];

var steelblue = /* `hex */[
  5194459,
  "4682B4"
];

var tan = /* `hex */[
  5194459,
  "D2B48C"
];

var teal = /* `hex */[
  5194459,
  "008080"
];

var thistle = /* `hex */[
  5194459,
  "D8BFD8"
];

var tomato = /* `hex */[
  5194459,
  "FF6347"
];

var transparent = /* transparent */582626130;

var turquoise = /* `hex */[
  5194459,
  "40E0D0"
];

var violet = /* `hex */[
  5194459,
  "EE82EE"
];

var wheat = /* `hex */[
  5194459,
  "F5DEB3"
];

var white = /* `hex */[
  5194459,
  "FFFFFF"
];

var whitesmoke = /* `hex */[
  5194459,
  "F5F5F5"
];

var yellow = /* `hex */[
  5194459,
  "FFFF00"
];

var yellowgreen = /* `hex */[
  5194459,
  "9ACD3"
];

exports.aliceblue = aliceblue;
exports.antiquewhite = antiquewhite;
exports.aqua = aqua;
exports.aquamarine = aquamarine;
exports.azure = azure;
exports.beige = beige;
exports.bisque = bisque;
exports.black = black;
exports.blanchedalmond = blanchedalmond;
exports.blue = blue;
exports.blueviolet = blueviolet;
exports.brown = brown;
exports.burlywood = burlywood;
exports.cadetblue = cadetblue;
exports.chartreuse = chartreuse;
exports.chocolate = chocolate;
exports.coral = coral;
exports.cornflowerblue = cornflowerblue;
exports.cornsilk = cornsilk;
exports.crimson = crimson;
exports.cyan = cyan;
exports.darkblue = darkblue;
exports.darkcyan = darkcyan;
exports.darkgoldenrod = darkgoldenrod;
exports.darkgray = darkgray;
exports.darkgreen = darkgreen;
exports.darkgrey = darkgrey;
exports.darkkhaki = darkkhaki;
exports.darkmagenta = darkmagenta;
exports.darkolivegreen = darkolivegreen;
exports.darkorange = darkorange;
exports.darkorchid = darkorchid;
exports.darkred = darkred;
exports.darksalmon = darksalmon;
exports.darkseagreen = darkseagreen;
exports.darkslateblue = darkslateblue;
exports.darkslategray = darkslategray;
exports.darkslategrey = darkslategrey;
exports.darkturquoise = darkturquoise;
exports.darkviolet = darkviolet;
exports.deeppink = deeppink;
exports.deepskyblue = deepskyblue;
exports.dimgray = dimgray;
exports.dimgrey = dimgrey;
exports.dodgerblue = dodgerblue;
exports.firebrick = firebrick;
exports.floralwhite = floralwhite;
exports.forestgreen = forestgreen;
exports.fuchsia = fuchsia;
exports.gainsboro = gainsboro;
exports.ghostwhite = ghostwhite;
exports.gold = gold;
exports.goldenrod = goldenrod;
exports.gray = gray;
exports.green = green;
exports.greenyellow = greenyellow;
exports.grey = grey;
exports.honeydew = honeydew;
exports.hotpink = hotpink;
exports.indianred = indianred;
exports.indigo = indigo;
exports.ivory = ivory;
exports.khaki = khaki;
exports.lavender = lavender;
exports.lavenderblush = lavenderblush;
exports.lawngreen = lawngreen;
exports.lemonchiffon = lemonchiffon;
exports.lightblue = lightblue;
exports.lightcoral = lightcoral;
exports.lightcyan = lightcyan;
exports.lightgoldenrodyellow = lightgoldenrodyellow;
exports.lightgray = lightgray;
exports.lightgreen = lightgreen;
exports.lightgrey = lightgrey;
exports.lightpink = lightpink;
exports.lightsalmon = lightsalmon;
exports.lightseagreen = lightseagreen;
exports.lightskyblue = lightskyblue;
exports.lightslategray = lightslategray;
exports.lightslategrey = lightslategrey;
exports.lightsteelblue = lightsteelblue;
exports.lightyellow = lightyellow;
exports.lime = lime;
exports.limegreen = limegreen;
exports.linen = linen;
exports.magenta = magenta;
exports.maroon = maroon;
exports.mediumaquamarine = mediumaquamarine;
exports.mediumblue = mediumblue;
exports.mediumorchid = mediumorchid;
exports.mediumpurple = mediumpurple;
exports.mediumseagreen = mediumseagreen;
exports.mediumslateblue = mediumslateblue;
exports.mediumspringgreen = mediumspringgreen;
exports.mediumturquoise = mediumturquoise;
exports.mediumvioletred = mediumvioletred;
exports.midnightblue = midnightblue;
exports.mintcream = mintcream;
exports.mistyrose = mistyrose;
exports.moccasin = moccasin;
exports.navajowhite = navajowhite;
exports.navy = navy;
exports.oldlace = oldlace;
exports.olive = olive;
exports.olivedrab = olivedrab;
exports.orange = orange;
exports.orangered = orangered;
exports.orchid = orchid;
exports.palegoldenrod = palegoldenrod;
exports.palegreen = palegreen;
exports.paleturquoise = paleturquoise;
exports.palevioletred = palevioletred;
exports.papayawhip = papayawhip;
exports.peachpuff = peachpuff;
exports.peru = peru;
exports.pink = pink;
exports.plum = plum;
exports.powderblue = powderblue;
exports.purple = purple;
exports.rebeccapurple = rebeccapurple;
exports.red = red;
exports.rosybrown = rosybrown;
exports.royalblue = royalblue;
exports.saddlebrown = saddlebrown;
exports.salmon = salmon;
exports.sandybrown = sandybrown;
exports.seagreen = seagreen;
exports.seashell = seashell;
exports.sienna = sienna;
exports.silver = silver;
exports.skyblue = skyblue;
exports.slateblue = slateblue;
exports.slategray = slategray;
exports.slategrey = slategrey;
exports.snow = snow;
exports.springgreen = springgreen;
exports.steelblue = steelblue;
exports.tan = tan;
exports.teal = teal;
exports.thistle = thistle;
exports.tomato = tomato;
exports.transparent = transparent;
exports.turquoise = turquoise;
exports.violet = violet;
exports.wheat = wheat;
exports.white = white;
exports.whitesmoke = whitesmoke;
exports.yellow = yellow;
exports.yellowgreen = yellowgreen;
/* No side effect */
