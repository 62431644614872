// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var BtTable$BsConsole = require("../../BtTable.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTableRow$BsConsole = require("../../BtTableRow.js");
var BtTableBody$BsConsole = require("../../BtTableBody.js");
var BtTableCell$BsConsole = require("../../BtTableCell.js");
var BtTableHead$BsConsole = require("../../BtTableHead.js");
var Icon = require("@material-ui/core/Icon");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var ApiTokensEdit$BsConsole = require("./ApiTokensEdit.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Switch = require("@material-ui/core/Switch");
var Clear = require("@material-ui/icons/Clear");
var BtAutocomplete$BsConsole = require("../../BtAutocomplete.js");
var ApiTokensCreate$BsConsole = require("./ApiTokensCreate.js");
var MenuItem = require("@material-ui/core/MenuItem");
var BtTableContainer$BsConsole = require("../../BtTableContainer.js");
var ApiTokensMetadata$BsConsole = require("./ApiTokensMetadata.js");
var CardContent = require("@material-ui/core/CardContent");
var DialogTitle = require("@material-ui/core/DialogTitle");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

function ApiTokens$TransferOwnershipDialog(Props) {
  var token = Props.token;
  var apiToken = Props.apiToken;
  var users = Props.users;
  var onClose = Props.onClose;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var match = React.useState((function () {
          
        }));
  var setNewOwner = match[1];
  var newOwner = match[0];
  var onSave = React.useCallback((function (_event) {
          if (newOwner === undefined) {
            return ;
          }
          var arg = BpgTask$BsConsole.ApiToken.updateFieldsByKey(BacktraceParserGenerator$BsConsole.ApiToken.to_key(apiToken), [/* tuple */[
                  "owner",
                  newOwner.uid
                ]]);
          return Curry._2((function (param) {
                          return (function (param$1, param$2) {
                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                            });
                        })(token), undefined, (function (param) {
                        return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                      }));
        }), /* tuple */[
        newOwner,
        apiToken
      ]);
  var currentOwner = Belt_Option.flatMap(users, (function (u) {
          return Belt_Array.getBy(u, (function (user) {
                        return user.uid === apiToken.owner;
                      }));
        }));
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: Css.style(/* :: */[
                      Css.minWidth(Css.px(400)),
                      /* :: */[
                        Css.maxWidth(Css.px(800)),
                        /* [] */0
                      ]
                    ]),
                container: Css.style(/* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]),
                root: Css.style(/* :: */[
                      Css.userSelect(/* none */-922086728),
                      /* [] */0
                    ])
              },
              onClose: onClose,
              disableBackdropClick: true,
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: I18N$BsConsole.show(undefined, "Transfer ownership")
                }), React.createElement(DialogContent.default, {
                  children: users !== undefined && currentOwner !== undefined ? React.createElement(Col2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(0.5)),
                                    /* [] */0
                                  ]),
                              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Transfer ownership from ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " to",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "Transfer ownership from %s to"
                                      ]), currentOwner.username)
                            }), React.createElement(BtAutocomplete$BsConsole.make, {
                              toString: (function (user) {
                                  return user.username;
                                }),
                              considerations: users,
                              onDelete: (function (param) {
                                  return Curry._1(setNewOwner, (function (param) {
                                                
                                              }));
                                }),
                              onChange: (function (_raw, user) {
                                  return Curry._1(setNewOwner, (function (param) {
                                                return user;
                                              }));
                                }),
                              defaultValue: Belt_Option.getWithDefault(Belt_Option.map(newOwner, (function (u) {
                                          return u.username;
                                        })), ""),
                              placeholder: I18N$BsConsole.get(undefined, "New owner"),
                              autoFocus: true,
                              inputAriaLabel: I18N$BsConsole.get(undefined, "new owner"),
                              renderListOption: (function (user, idx) {
                                  return React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Body2 */9,
                                              children: I18N$BsConsole.showSkip(user.username + (" - " + user.email)),
                                              key: user.username + ("__" + String(idx))
                                            });
                                }),
                              renderInput: (function (param) {
                                  var onFocus = param.onFocus;
                                  var onChange = param.onChange;
                                  var onFocus$prime = Curry.__1(onFocus);
                                  var onChange$prime = Curry.__1(onChange);
                                  return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                              onFocus: onFocus$prime,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              placeholder: param.placeholder,
                                              value: param.value,
                                              onChange: onChange$prime
                                            });
                                })
                            })) : null
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Cancel")
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: Belt_Option.isNone(newOwner),
                      onClick: onSave,
                      children: I18N$BsConsole.show(undefined, "Save")
                    })));
}

var TransferOwnershipDialog = {
  make: ApiTokens$TransferOwnershipDialog
};

function capabilitiesToString(caps) {
  return (
          caps.errorPost ? "error:post " : ""
        ) + ((
            caps.symbolPost ? "symbol:post " : ""
          ) + ((
              caps.objectGet ? "object:get " : ""
            ) + ((
                caps.queryPost ? "query:post " : ""
              ) + (
                caps.syncPost ? "sync:post " : ""
              ))));
}

function stringToCapabilities(caps) {
  return Belt_Array.reduce(caps.split(" "), {
              errorPost: false,
              symbolPost: false,
              objectGet: false,
              queryPost: false,
              syncPost: false
            }, (function (acc, cap) {
                switch (cap) {
                  case "error:post" :
                      return {
                              errorPost: true,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "object:get" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: true,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "query:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: true,
                              syncPost: acc.syncPost
                            };
                  case "symbol:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: true,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: acc.syncPost
                            };
                  case "sync:post" :
                      return {
                              errorPost: acc.errorPost,
                              symbolPost: acc.symbolPost,
                              objectGet: acc.objectGet,
                              queryPost: acc.queryPost,
                              syncPost: true
                            };
                  default:
                    return acc;
                }
              }));
}

function ApiTokens(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          
        }));
  var setTokens = match[1];
  var tokens = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setUsers = match$1[1];
  var users = match$1[0];
  var match$2 = React.useState((function () {
          return 0;
        }));
  var setRefetchTokens = match$2[1];
  var match$3 = React.useState((function () {
          return /* Closed */1;
        }));
  var setDisplayDialog = match$3[1];
  var displayDialog = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setAnchorEl = match$4[1];
  var anchorEl = match$4[0];
  var currentProject = Belt_List.getBy(config.projects, (function (proj) {
          return proj.name === projectName;
        }));
  React.useEffect((function () {
          Task2$BsConsole.handle(token, BpgTask$BsConsole.ApiToken.fetchAll, undefined, (function (tokens) {
                  if (tokens.tag) {
                    return ;
                  }
                  var tokens$1 = tokens[0];
                  var tokens$2 = Belt_Option.map(Belt_List.getBy(config.projects, (function (proj) {
                              return proj.name === projectName;
                            })), (function (proj) {
                          return Belt_List.fromArray(Belt_Array.keep(tokens$1, (function (token) {
                                            return token.project === proj.pid;
                                          })));
                        }));
                  return Curry._1(setTokens, (function (param) {
                                return tokens$2;
                              }));
                }));
          
        }), [match$2[0]]);
  React.useEffect((function () {
          Task2$BsConsole.handle(token, BpgTask$BsConsole.Users.fetchAll, undefined, (function (users) {
                  if (users.tag) {
                    return ;
                  }
                  var users$1 = users[0];
                  return Curry._1(setUsers, (function (param) {
                                return users$1;
                              }));
                }));
          
        }), ([]));
  var tmp;
  if (anchorEl !== undefined) {
    var token$1 = anchorEl[1];
    tmp = React.createElement(Menu.default, {
          disableAutoFocusItem: true,
          anchorEl: anchorEl[0],
          open: true,
          onClose: (function (param) {
              return Curry._1(setAnchorEl, (function (param) {
                            
                          }));
            }),
          children: null,
          key: token$1.id + "__menu"
        }, React.createElement(MenuItem.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.paddingTop(Css.em(0.25)),
                      /* :: */[
                        Css.paddingBottom(Css.em(0.25)),
                        /* [] */0
                      ]
                    ])
              },
              onClick: (function (param) {
                  Curry._1(setDisplayDialog, (function (param) {
                          return /* EditForm */Block.__(0, [token$1]);
                        }));
                  return Curry._1(setAnchorEl, (function (param) {
                                
                              }));
                }),
              children: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    children: I18N$BsConsole.show(undefined, "Edit")
                  })
            }), React.createElement(MenuItem.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.paddingTop(Css.em(0.25)),
                      /* :: */[
                        Css.paddingBottom(Css.em(0.25)),
                        /* [] */0
                      ]
                    ])
              },
              onClick: (function (param) {
                  var meta = Belt_Option.flatMap(token$1.metadata, ApiTokensMetadata$BsConsole.fromString);
                  var description = Belt_Option.mapWithDefault(meta, "", (function (m) {
                          return m.description;
                        }));
                  Curry._1(setDisplayDialog, (function (param) {
                          return /* DeleteDialog */Block.__(1, [
                                    token$1,
                                    description
                                  ]);
                        }));
                  return Curry._1(setAnchorEl, (function (param) {
                                
                              }));
                }),
              children: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    children: I18N$BsConsole.show(undefined, "Delete")
                  })
            }), React.createElement(MenuItem.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.paddingTop(Css.em(0.25)),
                      /* :: */[
                        Css.paddingBottom(Css.em(0.25)),
                        /* [] */0
                      ]
                    ])
              },
              onClick: (function (param) {
                  Curry._1(setDisplayDialog, (function (param) {
                          return /* TransferOwnership */Block.__(2, [token$1]);
                        }));
                  return Curry._1(setAnchorEl, (function (param) {
                                
                              }));
                }),
              children: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    children: I18N$BsConsole.show(undefined, "Transfer ownership")
                  })
            }));
  } else {
    tmp = null;
  }
  var tmp$1;
  if (typeof displayDialog === "number") {
    tmp$1 = displayDialog === /* CreateForm */0 ? React.createElement(Dialog.default, {
            open: true,
            classes: {
              paper: Css.style(/* :: */[
                    Css.width(Css.pct(50)),
                    /* :: */[
                      Css.unsafe("maxWidth", "unset"),
                      /* [] */0
                    ]
                  ]),
              container: Css.style(/* :: */[
                    Css.alignItems(/* flexStart */662439529),
                    /* [] */0
                  ]),
              root: Css.style(/* :: */[
                    Css.userSelect(/* none */-922086728),
                    /* [] */0
                  ])
            },
            onClose: (function (param) {
                return Curry._1(setDisplayDialog, (function (param) {
                              return /* Closed */1;
                            }));
              }),
            children: React.createElement(ApiTokensCreate$BsConsole.make, {
                  token: token,
                  config: config,
                  close: (function (param) {
                      Curry._1(setRefetchTokens, (function (refetch) {
                              return refetch + 1 | 0;
                            }));
                      return Curry._1(setDisplayDialog, (function (param) {
                                    return /* Closed */1;
                                  }));
                    }),
                  currentProject: currentProject
                })
          }) : null;
  } else {
    switch (displayDialog.tag | 0) {
      case /* EditForm */0 :
          tmp$1 = React.createElement(Dialog.default, {
                open: true,
                classes: {
                  paper: Css.style(/* :: */[
                        Css.width(Css.pct(50)),
                        /* :: */[
                          Css.unsafe("maxWidth", "unset"),
                          /* [] */0
                        ]
                      ]),
                  container: Css.style(/* :: */[
                        Css.alignItems(/* flexStart */662439529),
                        /* [] */0
                      ]),
                  root: Css.style(/* :: */[
                        Css.userSelect(/* none */-922086728),
                        /* [] */0
                      ])
                },
                onClose: (function (param) {
                    return Curry._1(setDisplayDialog, (function (param) {
                                  return /* Closed */1;
                                }));
                  }),
                children: React.createElement(ApiTokensEdit$BsConsole.make, {
                      apiToken: displayDialog[0],
                      token: token,
                      close: (function (param) {
                          Curry._1(setRefetchTokens, (function (refetch) {
                                  return refetch + 1 | 0;
                                }));
                          return Curry._1(setDisplayDialog, (function (param) {
                                        return /* Closed */1;
                                      }));
                        })
                    })
              });
          break;
      case /* DeleteDialog */1 :
          var description = displayDialog[1];
          var apiToken = displayDialog[0];
          tmp$1 = React.createElement(Dialog.default, {
                open: true,
                classes: {
                  paper: Css.style(/* :: */[
                        Css.minWidth(Css.px(400)),
                        /* :: */[
                          Css.maxWidth(Css.px(800)),
                          /* [] */0
                        ]
                      ]),
                  container: Css.style(/* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]),
                  root: Css.style(/* :: */[
                        Css.userSelect(/* none */-922086728),
                        /* [] */0
                      ])
                },
                onClose: (function (_e) {
                    return Curry._1(setDisplayDialog, (function (param) {
                                  return /* Closed */1;
                                }));
                  }),
                children: React.createElement(CardContent.default, {
                      children: null
                    }, React.createElement(Mui$BsConsole.IconButton.make, {
                          ariaLabel: "Close",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.height(Css.px(48)),
                                  /* :: */[
                                    Css.position(/* absolute */-1013592457),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                      /* :: */[
                                        Css.top(/* zero */-789508312),
                                        /* :: */[
                                          Css.right(/* zero */-789508312),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ])
                          },
                          color: "primary",
                          onClick: (function (_event) {
                              return Curry._1(setDisplayDialog, (function (param) {
                                            return /* Closed */1;
                                          }));
                            }),
                          children: React.createElement(Clear.default, { })
                        }), React.createElement(Row2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* :: */[
                                  Css.marginBottom(Css.rem(1.5)),
                                  /* [] */0
                                ]
                              ]),
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                children: null
                              }, React.createElement(Row2$BsConsole.make, {
                                    className: Css.style(/* :: */[
                                          Css.marginBottom(Css.rem(0.5)),
                                          /* [] */0
                                        ]),
                                    children: description.length > 0 ? I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Are you sure you want to delete the following token") + (": \"" + (description + "\"?"))) : I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Are you sure you want to delete the following token") + "?")
                                  }), I18N$BsConsole.showSkip(apiToken.id))
                        }), React.createElement(Row2$BsConsole.make, {
                          justifyContent: /* flexEnd */924268066,
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginRight(Css.rem(0.5)),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (_event) {
                                  return Curry._1(setDisplayDialog, (function (param) {
                                                return /* Closed */1;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "raised",
                              color: "primary",
                              disabled: false,
                              onClick: (function (_event) {
                                  return Task2$BsConsole.handle(token, BpgTask$BsConsole.ApiToken.$$delete(apiToken), undefined, (function (param) {
                                                Curry._1(setDisplayDialog, (function (param) {
                                                        return /* Closed */1;
                                                      }));
                                                return Curry._1(setRefetchTokens, (function (refetch) {
                                                              return refetch + 1 | 0;
                                                            }));
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Yes")
                            })))
              });
          break;
      case /* TransferOwnership */2 :
          tmp$1 = React.createElement(ApiTokens$TransferOwnershipDialog, {
                token: token,
                apiToken: displayDialog[0],
                users: users,
                onClose: (function (param) {
                    return Curry._1(setDisplayDialog, (function (param) {
                                  return /* Closed */1;
                                }));
                  }),
                onSuccess: (function (param) {
                    Curry._1(setDisplayDialog, (function (param) {
                            return /* Closed */1;
                          }));
                    return Curry._1(setRefetchTokens, (function (i) {
                                  return i + 1 | 0;
                                }));
                  }),
                onFailure: (function (errorMessage) {
                    Curry._1(setDisplayDialog, (function (param) {
                            return /* Closed */1;
                          }));
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, errorMessage));
                  })
              });
          break;
      
    }
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              className: Css.style(/* :: */[
                    Css.overflowX(/* auto */-1065951377),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "API tokens")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "API tokens are used to authenticate Backtrace API usage. Capabilities are used to grant tokens finer-grained permissions to the API.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginTop(Css.rem(0.5)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1.5)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setDisplayDialog, (function (param) {
                                    return /* CreateForm */0;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "New")
                }), React.createElement(Paper.default, {
                  className: Css.style(/* :: */[
                        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                        /* :: */[
                          Css.borderRadius(Css.px(4)),
                          /* [] */0
                        ]
                      ]),
                  elevation: 0,
                  children: React.createElement(BtTableContainer$BsConsole.make, {
                        children: React.createElement(BtTable$BsConsole.make, {
                              children: null
                            }, React.createElement(BtTableHead$BsConsole.make, {
                                  children: React.createElement(BtTableRow$BsConsole.make, {
                                        children: null
                                      }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                            className: Css.style(/* :: */[
                                                  Css.textAlign(/* left */-944764921),
                                                  /* :: */[
                                                    Css.padding(Css.rem(1)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            children: I18N$BsConsole.show(undefined, "Token")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            className: Css.style(/* :: */[
                                                  Css.textAlign(/* left */-944764921),
                                                  /* :: */[
                                                    Css.padding(Css.rem(1)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            children: I18N$BsConsole.show(undefined, "Owner")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            className: Css.style(/* :: */[
                                                  Css.textAlign(/* left */-944764921),
                                                  /* :: */[
                                                    Css.padding(Css.rem(1)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            children: I18N$BsConsole.show(undefined, "Description")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            className: Css.style(/* :: */[
                                                  Css.textAlign(/* left */-944764921),
                                                  /* :: */[
                                                    Css.padding(Css.rem(1)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            children: I18N$BsConsole.show(undefined, "Capabilities")
                                          }), config.universe.name === "backtrace" || config.universe.name === "yolo" ? React.createElement(BtTableCell$BsConsole.Header.make, {
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.px(100)),
                                                    /* [] */0
                                                  ]),
                                              children: I18N$BsConsole.show(undefined, "Enabled")
                                            }) : null, React.createElement(BtTableCell$BsConsole.Header.make, {
                                            className: Css.style(/* :: */[
                                                  Css.width(Css.px(76)),
                                                  /* [] */0
                                                ]),
                                            children: null
                                          }))
                                }), React.createElement(BtTableBody$BsConsole.make, {
                                  children: tokens !== undefined ? Belt_List.toArray(Belt_List.map(tokens, (function (token$2) {
                                                var meta = Belt_Option.flatMap(token$2.metadata, ApiTokensMetadata$BsConsole.fromString);
                                                var description = Belt_Option.mapWithDefault(meta, "", (function (m) {
                                                        return m.description;
                                                      }));
                                                var disabled = Belt_Option.mapWithDefault(meta, false, (function (m) {
                                                        return m.disabled;
                                                      }));
                                                var caps = token$2.capabilities.trim().split(" ").join(", ");
                                                var tmp;
                                                if (users !== undefined) {
                                                  var owner = Belt_Array.getBy(users, (function (user) {
                                                          return user.uid === token$2.owner;
                                                        }));
                                                  tmp = I18N$BsConsole.show(undefined, Belt_Option.mapWithDefault(owner, "", (function (user) {
                                                              return user.username;
                                                            })));
                                                } else {
                                                  tmp = I18N$BsConsole.showSkip("");
                                                }
                                                return React.createElement(BtTableRow$BsConsole.make, {
                                                            children: null,
                                                            key: token$2.id
                                                          }, React.createElement(BtTableCell$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.userSelect(/* text */-856044371),
                                                                      /* [] */0
                                                                    ]),
                                                                children: I18N$BsConsole.dynamic(token$2.id)
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.userSelect(/* text */-856044371),
                                                                      /* [] */0
                                                                    ]),
                                                                children: tmp
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                children: I18N$BsConsole.showSkip(description)
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                children: I18N$BsConsole.showSkip(caps)
                                                              }), config.universe.name === "backtrace" || config.universe.name === "yolo" ? React.createElement(BtTableCell$BsConsole.make, {
                                                                  children: React.createElement(Switch.default, {
                                                                        color: "primary",
                                                                        onChange: (function (_form, checked) {
                                                                            var disabled = !checked;
                                                                            var m = meta !== undefined ? ({
                                                                                  description: meta.description,
                                                                                  buckets: meta.buckets,
                                                                                  disabled: disabled
                                                                                }) : ({
                                                                                  description: ApiTokensMetadata$BsConsole.empty.description,
                                                                                  buckets: ApiTokensMetadata$BsConsole.empty.buckets,
                                                                                  disabled: disabled
                                                                                });
                                                                            var props = [/* tuple */[
                                                                                "metadata",
                                                                                ApiTokensMetadata$BsConsole.toJson(m)
                                                                              ]];
                                                                            var arg = BpgTask$BsConsole.ApiToken.updateFields(token$2, props);
                                                                            return Curry._2((function (param) {
                                                                                            return (function (param$1, param$2) {
                                                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                              });
                                                                                          })(token), true, (function (_res) {
                                                                                          return Curry._1(setRefetchTokens, (function (k) {
                                                                                                        return k + 1 | 0;
                                                                                                      }));
                                                                                        }));
                                                                          }),
                                                                        checked: !disabled
                                                                      })
                                                                }) : null, React.createElement(BtTableCell$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.textAlign(/* right */-379319332),
                                                                      /* [] */0
                                                                    ]),
                                                                children: React.createElement(Mui$BsConsole.IconButton.make, {
                                                                      ariaLabel: "Token settings",
                                                                      onClick: (function ($$event) {
                                                                          var el = $$event.currentTarget;
                                                                          if (!(el == null)) {
                                                                            return Curry._1(setAnchorEl, (function (param) {
                                                                                          return /* tuple */[
                                                                                                  el,
                                                                                                  token$2
                                                                                                ];
                                                                                        }));
                                                                          }
                                                                          
                                                                        }),
                                                                      children: React.createElement(Icon.default, {
                                                                            fontSize: "small",
                                                                            children: I18N$BsConsole.showSkip("more_vert")
                                                                          })
                                                                    })
                                                              }));
                                              }))) : null
                                }))
                      })
                }), tmp, tmp$1);
}

function make(token, config, projectName, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ApiTokens, {
              token: token,
              config: config,
              projectName: projectName
            }, _children);
}

var Re = {
  make: make
};

var make$1 = ApiTokens;

exports.TransferOwnershipDialog = TransferOwnershipDialog;
exports.capabilitiesToString = capabilitiesToString;
exports.stringToCapabilities = stringToCapabilities;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
