// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Task2$BsConsole = require("../Task2.js");
var WfCore$BsConsole = require("./WfCore.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Typeahead$BsConsole = require("../typeahead/Typeahead.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

function getCount(param) {
  
}

function toString(t) {
  return t.username;
}

var UserTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: "",
      fuzz: undefined
    });

var component = RR$BsConsole.reducerComponent("WfBacktraceUser-BsConsole_Inner");

function make(schema, users, padding, fieldDoc, initialValue, onValueChange, showAllErrors, remoteAction, param) {
  var validate = function (value) {
    var parsedValue;
    if (value === "") {
      parsedValue = /* Ok */Block.__(0, [undefined]);
    } else {
      var user = users.find((function (user) {
              return user.username === value;
            }));
      parsedValue = user !== undefined ? /* Ok */Block.__(0, [(
              user === undefined ? undefined : Caml_option.some(user)
            ).uid]) : /* Error */Block.__(1, [/* UserNotFound */1]);
    }
    return Belt_Result.flatMap(parsedValue, (function (optUid) {
                  var err = WfCore$BsConsole.Int.validate(schema, optUid);
                  if (err !== undefined) {
                    return /* Error */Block.__(1, [err]);
                  } else {
                    return /* Ok */Block.__(0, [optUid]);
                  }
                }));
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (optJson) {
                          if (optJson !== undefined) {
                            return optJson;
                          } else {
                            return null;
                          }
                        })));
              return Curry._1(remoteAction, (function (param) {
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var validatedValue = state.validatedValue;
              var value = state.value;
              var send = param.send;
              var error;
              error = validatedValue.tag ? validatedValue[0] : undefined;
              return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, schema.required, padding, error, undefined, state.dirty, showAllErrors, [state.focused ? ReasonReact.element(undefined, undefined, Curry.app(UserTypeahead.make, [
                                          undefined,
                                          (function (param) {
                                              setTimeout((function (param) {
                                                      return Curry._1(send, /* SetFocused */Block.__(2, [false]));
                                                    }), 100);
                                              
                                            }),
                                          Belt_List.fromArray(users),
                                          (function (user) {
                                              return Curry._1(send, /* Select */Block.__(0, [user]));
                                            }),
                                          undefined,
                                          undefined,
                                          value,
                                          undefined,
                                          (function (v) {
                                              return Curry._1(send, /* Edit */Block.__(1, [v]));
                                            }),
                                          []
                                        ])) : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    schema.placeholder,
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        return Curry._1(send, /* SetFocused */Block.__(2, [true]));
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* `String */[
                                                      -976970511,
                                                      value
                                                    ],
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    []
                                                  ]))]))]));
            }),
          initialState: (function (param) {
              var match = schema.defaultValue;
              var value = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(initialValue !== undefined ? Json_decode.$$int(Caml_option.valFromOption(initialValue)) : (
                              match !== undefined ? match : undefined
                            ), (function (userId) {
                              return Caml_option.undefined_to_opt(users.find((function (user) {
                                                return user.uid === userId;
                                              })));
                            })), (function (user) {
                          return user.username;
                        })), "");
              return {
                      value: value,
                      validatedValue: validate(value),
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      selectRef: {
                        contents: undefined
                      },
                      focused: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* Select */0 :
                    var user = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                value: user.username,
                                validatedValue: validate(user.username),
                                dirty: true,
                                elementRef: state.elementRef,
                                selectRef: state.selectRef,
                                focused: false
                              },
                              (function (param) {
                                  return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (optUid) {
                                                    if (optUid !== undefined) {
                                                      return optUid;
                                                    } else {
                                                      return null;
                                                    }
                                                  })));
                                })
                            ]);
                case /* Edit */1 :
                    var value = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                value: value,
                                validatedValue: validate(value),
                                dirty: true,
                                elementRef: state.elementRef,
                                selectRef: state.selectRef,
                                focused: state.focused
                              },
                              (function (param) {
                                  return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (optUid) {
                                                    if (optUid !== undefined) {
                                                      return optUid;
                                                    } else {
                                                      return null;
                                                    }
                                                  })));
                                })
                            ]);
                case /* SetFocused */2 :
                    return /* Update */Block.__(0, [{
                                value: state.value,
                                validatedValue: validate(state.value),
                                dirty: state.dirty,
                                elementRef: state.elementRef,
                                selectRef: state.selectRef,
                                focused: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Inner = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("WfBacktraceUser-BsConsole");

function make$1(token, schema, fieldDoc, padding, initialValue, onValueChange, showAllErrors, remoteAction, param) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, BpgTask$BsConsole.Users.fetchAll, undefined, (function (remote, param) {
                                if (typeof remote === "number" || remote.tag) {
                                  return null;
                                } else {
                                  return ReasonReact.element(undefined, undefined, make(schema, remote[0][0], padding, fieldDoc, initialValue, onValueChange, showAllErrors, remoteAction, []));
                                }
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.UserTypeahead = UserTypeahead;
exports.Inner = Inner;
exports.component = component$1;
exports.make = make$1;
/* UserTypeahead Not a pure module */
