// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Util$BsConsole = require("../util.js");
var Belt_MutableSet = require("bs-platform/lib/js/belt_MutableSet.js");
var Version$BsConsole = require("../Version.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var MetricsNav$BsConsole = require("./MetricsNav.js");
var MetricsUri$BsConsole = require("./MetricsUri.js");
var MetricsMemory$BsConsole = require("./MetricsMemory.js");
var MetricsRequest$BsConsole = require("./MetricsRequest.js");
var MetricsService$BsConsole = require("./MetricsService.js");

var endpoint = "/track";

var initialized = {
  contents: false
};

function setInitialized(param) {
  initialized.contents = true;
  
}

function toString(action) {
  if (typeof action === "number") {
    switch (action) {
      case /* Reprocess_Object_List */0 :
          return "reprocess/object_list";
      case /* Reprocess_Object_Debug */1 :
          return "reprocess/object_debug";
      case /* Reprocess_WithoutQuery_FromSettings */2 :
          return "reprocess/without_query_from_settings";
      case /* Reprocess_WithQuery_FromSettings */3 :
          return "reprocess/with_query_from_settings";
      case /* Reprocess_WithQuery_FromTriage */4 :
          return "reprocess/with_query_from_triage";
      case /* Reprocess_WithQuery_FromExplore */5 :
          return "reprocess/with_query_from_explore";
      case /* DeleteByQuery_Physical */6 :
          return "delete_by_query/physical";
      case /* DeleteByQuery_PhysicalAndMetadata */7 :
          return "delete_by_query/physical_and_metadata";
      case /* Changelog_Click */8 :
          return "changelog/click";
      case /* SavedQueries_Apply */9 :
          return "saved_queries/apply";
      case /* SavedQueries_Replace */10 :
          return "saved_queries/replace";
      case /* SavedQueries_ReplaceDefault */11 :
          return "saved_queries/replace_default";
      case /* SavedQueries_MakeProjectDefault */12 :
          return "saved_queries/make_project_default";
      case /* SavedQueries_MakeUserDefault */13 :
          return "saved_queries/make_user_default";
      case /* SavedQueries_AddProjectBookmark */14 :
          return "saved_queries/add_project_bookmark";
      case /* SavedQueries_RemoveProjectBookmark */15 :
          return "saved_queries/remove_project_bookmark";
      case /* SavedQueries_AddUserBookmark */16 :
          return "saved_queries/add_user_bookmark";
      case /* SavedQueries_RemoveUserBookmark */17 :
          return "saved_queries/remove_user_bookmark";
      case /* Share_CreateTinyUrlSuccess */18 :
          return "share/create_tiny_url_sucess";
      case /* Share_CreateTinyUrlFailure */19 :
          return "share/create_tiny_url_failure";
      case /* Share_UseTinyUrlSuccess */20 :
          return "share/use_tiny_url_success";
      case /* Share_UseTinyUrlFailure */21 :
          return "share/use_tiny_url_failure";
      case /* QueryCountExceedsMaxCount */22 :
          return "query_count_exceeds_max_count";
      case /* FlameGraph_FilterToFrame */23 :
          return "flamegraph/filter_to_frame";
      case /* FlameGraph_FilterAgainstFrame */24 :
          return "flamegraph/filter_against_frame";
      case /* FlameGraph_CopyCallstack */25 :
          return "flamegraph/copy_callstack";
      case /* FlameGraph_FrameClick */26 :
          return "flamegraph/frame_click";
      case /* ExploreGroup_ViewUniqueBugs */27 :
          return "explore_group/view_unique_bugs";
      case /* ExploreGroup_Triage */28 :
          return "explore_group/triage";
      case /* ExploreGroup_List */29 :
          return "explore_group/list";
      case /* ExploreGroup_FilterOnGroup */30 :
          return "explore_group/filter_on_group";
      case /* ExploreGroup_Debugger */31 :
          return "explore_group/debugger";
      case /* ExploreGroup_FlameGraph */32 :
          return "explore_group/flamegraph";
      case /* UserLogin_Success */33 :
          return "user_login/success";
      case /* UserLogin_Failure */34 :
          return "user_login/failure";
      case /* Inbox_MessageBarExpandAll */35 :
          return "Inbox/message_bar_expand_all";
      case /* RetentionPolicy_UniverseCreate */36 :
          return "retention_policy/universe_rule_create";
      case /* RetentionPolicy_UniverseEdit */37 :
          return "retention_policy/universe_rule_edit";
      case /* RetentionPolicy_UniverseDelete */38 :
          return "retention_policy/universe_rule_delete";
      case /* RetentionPolicy_ProjectCreate */39 :
          return "retention_policy/project_rule_create";
      case /* RetentionPolicy_ProjectEdit */40 :
          return "retention_policy/project_rule_edit";
      case /* RetentionPolicy_ProjectDelete */41 :
          return "retention_policy/project_rule_delete";
      case /* Sampling_Enable */42 :
          return "sampling/enable";
      case /* Sampling_Disable */43 :
          return "sampling/disable";
      case /* Sampling_SaveConfiguration */44 :
          return "sampling/save_configuration";
      case /* Triage_AddColumn */45 :
          return "triage/add_column";
      case /* Triage_AddTags */46 :
          return "triage/add_tags";
      case /* Triage_RemoveTags */47 :
          return "triage/remove_tags";
      case /* Triage_AddRemoveTags */48 :
          return "triage/add_remove_tags";
      case /* Triage_SetState */49 :
          return "triage/set_issue_state";
      case /* Triage_SetInvariant */50 :
          return "triage/set_issue_invariant";
      case /* Triage_SetBtAssignee */51 :
          return "triage/set_bt_assignee";
      case /* Triage_SetUnlinkedTicket */52 :
          return "triage/set_unlinked_ticket";
      case /* Triage_AddUnlinkedTicket */53 :
          return "triage/add_unlinked_ticket";
      case /* Triage_SetLinkedAssignees */54 :
          return "triage/set_linked_assignees";
      case /* Triage_RemoveTicket */55 :
          return "triage/remove_unlinked_ticket";
      case /* DefaultUI_Legacy */56 :
          return "default_ui/legacy";
      case /* DefaultUI_Console2 */57 :
          return "default_ui/console2";
      case /* Notifications_ViewOpen */58 :
          return "notifications/view_open";
      case /* Notifications_ProgressItemClick */59 :
          return "notifications/progress_item_click";
      case /* Teams_CreateTeam */60 :
          return "teams/create_team";
      case /* Teams_EditTeam */61 :
          return "teams/edit_team";
      case /* Teams_DeleteTeam */62 :
          return "teams/delete_team";
      case /* Teams_AddTeamMember */63 :
          return "teams/add_team_member";
      case /* Teams_RemoveTeamMember */64 :
          return "teams/delete_team_member";
      case /* Teams_CreateProjectMemberTeam */65 :
          return "teams/create_project_member_team";
      case /* Teams_DeleteProjectMemberTeam */66 :
          return "teams/delete_project_member_team";
      case /* Teams_CreateProjectMemberUser */67 :
          return "teams/create_project_member_user";
      case /* Teams_DeleteProjectMemberUser */68 :
          return "teams/delete_project_member_user";
      case /* DataSource_Playstation4_Create */69 :
          return "data_source/playstation4/create";
      case /* DataSource_Playstation4_Modify */70 :
          return "data_source/playstation4/modify";
      case /* DataSource_Playstation5_Create */71 :
          return "data_source/playstation5/create";
      case /* DataSource_Playstation5_Modify */72 :
          return "data_source/playstation5/modify";
      case /* DataSource_NintendoSwitch_Create */73 :
          return "data_source/nintendo_switch/create";
      case /* DataSource_NintendoSwitch_Modify */74 :
          return "data_source/nintendo_switch/modify";
      case /* DataSource_XBox_Create */75 :
          return "data_source/xbox/create";
      case /* DataSource_XBox_Modify */76 :
          return "data_source/xbox/modify";
      case /* SourceCode_TabClick */77 :
          return "sourcecode/tab_click";
      case /* SourceCode_RepoCreate */78 :
          return "sourcecode/repo_create";
      case /* SourceCode_RepoEdit */79 :
          return "sourcecode/repo_edit";
      case /* SourceCode_RepoEditAuthInfo */80 :
          return "sourcecode/repo_edit_auth_info";
      case /* SourceCode_RepoTest */81 :
          return "sourcecode/repo_test";
      case /* SourceCode_RepoDelete */82 :
          return "sourcecode/repo_delete";
      case /* SourceCode_RuleCreate */83 :
          return "sourcecode/rule_create";
      case /* SourceCode_RuleChangePosition */84 :
          return "sourcecode/rule_change_position";
      case /* SourceCode_RuleDelete */85 :
          return "sourcecode/rule_delete";
      case /* SourceCode_CloneSubmodules */86 :
          return "sourcecode/clone_submodules";
      case /* SourceCode_PerforceServerCreate */87 :
          return "sourcecode/perforce_server_create";
      case /* SourceCode_PerforceServerEdit */88 :
          return "sourcecode/perforce_server_edit";
      case /* SourceCode_PerforceServerDelete */89 :
          return "sourcecode/perforce_server_delete";
      case /* Similarity_DetailTabClick */90 :
          return "similarity/detail_tab_click";
      case /* Similarity_Compute */91 :
          return "similarity/compute";
      case /* Similarity_MergeGroups */92 :
          return "similarity/merge_groups";
      case /* Scrubbers_Create_Builtin */93 :
          return "scrubbers/create_builtin";
      case /* Scrubbers_Toggle_Builtin */94 :
          return "scrubbers/toggle_builtin";
      case /* Scrubbers_Create_Custom */95 :
          return "scrubbers/create_custom";
      case /* Scrubbers_Delete_Custom */96 :
          return "scrubbers/delete_custom";
      case /* Scrubbers_Toggle_Custom */97 :
          return "scrubbers/toggle_custom";
      case /* Overview_Analyze */98 :
          return "overview/analyze";
      case /* Overview_Documentation */99 :
          return "overview/documentation";
      case /* Debugger_FrameRowClick */100 :
          return "debugger/frame_row_click";
      case /* Debugger_FrameRowDefectClick */101 :
          return "debugger/frame_row_defect_click";
      case /* Debugger_CorrelatedDefectClick */102 :
          return "debugger/correlated_defect_click";
      case /* Debugger_AddCustomAttributeClick */103 :
          return "debugger/add_custom_attribute_click";
      case /* Debugger_Load */104 :
          return "debugger/load";
      case /* Debugger_Attributes_Search */105 :
          return "debugger/attributes_search";
      case /* Debugger_RenderVariables */106 :
          return "debugger/render_variables";
      case /* Debugger_ShowFormattedDataClick */107 :
          return "debugger/show_formatted_data_click";
      case /* Debugger_PreviewAttachmentClick */108 :
          return "debugger/preview_attachment_click";
      case /* Debugger_DownloadAttachmentClick */109 :
          return "debugger/download_attachment_click";
      case /* Debugger_SplitViewClick */110 :
          return "debugger/split_view_click";
      case /* Debugger_ToggleVariables */111 :
          return "debugger/toggle_variables";
      case /* Debugger_HightlightBar_AttributeAdded */112 :
          return "debugger/highlightbar_attribute_added";
      case /* UnityCDAFirstTimeModalDocumentationClick */113 :
          return "unity_CDA/first_time_modal_documentation_click";
      case /* UnityCDAFirstTimeModalSeen */114 :
          return "unity_CDA/first_time_modal_seen";
      case /* SSOLoginClick */115 :
          return "sso/login_click";
      case /* CompareAttributeUpdate */116 :
          return "compare_releases/attribute_update";
      case /* CompareGroupSelect */117 :
          return "compare_release/group_select";
      case /* BookmarkedViewSelectViewApplied */118 :
          return "bookmarked_view_select/view_applied";
      case /* BookmarkedViewSelectOpenManager */119 :
          return "bookmarked_view_select/open_manager";
      case /* ViewManagerSaveView */120 :
          return "view_manager/save_view";
      case /* SessionReplay_Play */121 :
          return "session_replay_play";
      case /* IssueBasedAlert_CreateLinkClick */122 :
          return "issue_based_alert/create_link_click";
      case /* IssueBasedAlert_Created */123 :
          return "issue_based_alert/created";
      case /* IssueBasedAlert_Edited */124 :
          return "issue_based_alert/edited";
      case /* IssueBasedAlert_Deleted */125 :
          return "issue_based_alert/deleted";
      case /* FilterQueryBuilderOpen */126 :
          return "filter_query_builder/open";
      case /* FilterQueryBuilderApply */127 :
          return "filter_query_builder/apply";
      case /* FilterQueryBuilderCancel */128 :
          return "filter_query_builder/cancel";
      case /* FilterQueryBuilderTabSelect */129 :
          return "filter_query_builder/tab_select";
      case /* TeamsWelcomeSeen */130 :
          return "teams/welcome_seen";
      case /* UniverseDisabled */131 :
          return "universe_disabled";
      case /* UserFrontendSettingsGuestBelowCoronerdVersion */132 :
          return "user_frontend_settings/guest_below_coronerd_version";
      case /* WorkflowLinkFingerprintMatch */133 :
          return "workflow_link_fingerprint_match";
      case /* WorkflowLinkFingerprintMismatch */134 :
          return "workflow_link_fingerprint_mismatch";
      case /* DownloadedWebSdkExample */135 :
          return "downloaded_web_sdk_example";
      
    }
  } else {
    switch (action.tag | 0) {
      case /* Raw */0 :
          return action[0];
      case /* CreatedFirstProject */1 :
          return "created_first_project/via_" + action[0];
      case /* GenerateDemoProject */2 :
          return "generate_demo_project/" + action[0];
      case /* GenerateDemoProjectViaProjectCreation */3 :
          return "generate_demo_project_via_project_creation/" + action[0];
      case /* GenerateDemoProjectUsingLink */4 :
          return "generate_demo_project_via_link/" + action[0];
      case /* FilterSelect */5 :
          return "filter_select/" + (action[0] + ("/" + action[1]));
      case /* Connection_InstallLinkClick */6 :
          return "connection/" + (action[0] + "/install_link_click");
      case /* Connection_Created */7 :
          return "connection/" + (action[0] + "/created");
      case /* Connection_Edited */8 :
          return "connection/" + (action[0] + "/edited");
      case /* Connection_Transfered */9 :
          return "connection/" + (action[0] + "/transfered");
      case /* Connection_Deleted */10 :
          return "connection/" + (action[0] + "/deleted");
      case /* Integration_Chosen */11 :
          return "integration/" + (action[0] + "/chosen");
      case /* Integration_Created */12 :
          return "integration/" + (action[0] + "/created");
      case /* Integration_Edited */13 :
          return "integration/" + (action[0] + "/edited");
      case /* Integration_Deleted */14 :
          return "integration/" + (action[0] + "/deleted");
      case /* Integration_Transfered */15 :
          return "integration/" + (action[0] + "/transfered");
      case /* ProjectSettingsDeduplicationIgnoreModulesUpdate */16 :
          return "project_settings/deduplication_update/include_modules/" + Pervasives.string_of_bool(action[0]);
      case /* ProjectSettingsDeduplicationIgnoreSourcesUpdate */17 :
          return "project_settings/deduplication_update/include_sources/" + Pervasives.string_of_bool(action[0]);
      case /* ProjectSettingsDeduplicationIgnoreMetadataUpdate */18 :
          return "project_settings/deduplication_update/include_metadata/" + Pervasives.string_of_bool(action[0]);
      
    }
  }
}

var Action = {
  toString: toString
};

function toString$1($$event) {
  if (typeof $$event === "number") {
    return "ping";
  }
  switch ($$event.tag | 0) {
    case /* Action */0 :
    case /* ActionWithAttrs */1 :
        return "action/" + toString($$event[0]);
    case /* GenericEvent */2 :
        return $$event[0].name;
    case /* Nav */3 :
        return "nav/" + MetricsNav$BsConsole.path($$event[0]);
    case /* Query */4 :
        return "query/" + $$event[0];
    case /* QueryJson */5 :
        var reportName = $$event[0];
        if (reportName !== undefined) {
          return "query/" + reportName;
        } else {
          return "query/";
        }
    case /* QueryJsonWithAttrs */6 :
        var reportName$1 = $$event[0];
        if (reportName$1 !== undefined) {
          return "query/" + reportName$1;
        } else {
          return "query/";
        }
    
  }
}

function toProperties($$event) {
  if (typeof $$event === "number") {
    return ;
  }
  switch ($$event.tag | 0) {
    case /* Action */0 :
        return ;
    case /* ActionWithAttrs */1 :
        return Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, $$event[1])));
    case /* GenericEvent */2 :
        return Belt_Option.map($$event[0].payload, (function (payload) {
                      return Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, payload)));
                    }));
    case /* Nav */3 :
        var lastRoute = $$event[1];
        var route = $$event[0];
        var exit = 0;
        var name;
        if (typeof route === "number") {
          switch (route) {
            case /* Signup */0 :
            case /* Recover */1 :
            case /* CreateFirstProject */2 :
            case /* Shell */3 :
            case /* Settings */4 :
            case /* SettingsMe */5 :
            case /* SettingsUsers */6 :
            case /* SettingsTeams */7 :
            case /* SettingsProjects */8 :
            case /* SettingsProjectsNew */9 :
            case /* SettingsProjectsFork */10 :
            case /* SettingsInvites */11 :
            case /* SettingsInvitesNew */12 :
            case /* SettingsNetworking */13 :
            case /* SettingsRetentionPolicy */14 :
            case /* SettingsNetworkingSSL */15 :
            case /* SettingsNetworkingListeners */16 :
            case /* SettingsWhitelist */17 :
            case /* SettingsBilling */18 :
            case /* SettingsBillingPlans */19 :
            case /* SettingsSubscriptions */20 :
            case /* SettingsSamlConfig */21 :
            case /* WfPlayground */22 :
            case /* UiPlayground */23 :
                exit = 2;
                break;
            
          }
        } else {
          switch (route.tag | 0) {
            case /* UDashEntry */2 :
                name = route[0].project;
                exit = 1;
                break;
            case /* Reset */0 :
            case /* CreateAccount */1 :
            case /* Login */3 :
            case /* Share */4 :
            case /* Activate */5 :
            case /* SettingsWfConnectionManagement */6 :
            case /* RedirectLegacyDebugger */51 :
            case /* RedirectLegacyDetailView */52 :
            case /* RedirectLegacyQueryBuilder */53 :
            case /* Workflow */54 :
            case /* SamlError */55 :
            case /* NotFound */56 :
            case /* Error */57 :
            case /* GenerateDemoProject */58 :
                exit = 2;
                break;
            default:
              name = route[0];
              exit = 1;
          }
        }
        switch (exit) {
          case 1 :
              MetricsMemory$BsConsole.memorize(/* tuple */[
                    "project",
                    name
                  ]);
              MetricsMemory$BsConsole.memorize(/* tuple */[
                    "toName",
                    MetricsNav$BsConsole.path(route)
                  ]);
              MetricsMemory$BsConsole.memorize(/* tuple */[
                    "fromName",
                    Belt_Option.mapWithDefault(lastRoute, "-", MetricsNav$BsConsole.path)
                  ]);
              return /* :: */[
                      /* tuple */[
                        "project",
                        name
                      ],
                      /* :: */[
                        /* tuple */[
                          "toName",
                          MetricsNav$BsConsole.path(route)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fromName",
                            Belt_Option.mapWithDefault(lastRoute, "-", MetricsNav$BsConsole.path)
                          ],
                          /* [] */0
                        ]
                      ]
                    ];
          case 2 :
              MetricsMemory$BsConsole.forget("project");
              MetricsMemory$BsConsole.memorize(/* tuple */[
                    "toName",
                    MetricsNav$BsConsole.path(route)
                  ]);
              MetricsMemory$BsConsole.memorize(/* tuple */[
                    "fromName",
                    Belt_Option.mapWithDefault(lastRoute, "-", MetricsNav$BsConsole.path)
                  ]);
              return /* :: */[
                      /* tuple */[
                        "toName",
                        MetricsNav$BsConsole.path(route)
                      ],
                      /* :: */[
                        /* tuple */[
                          "fromName",
                          Belt_Option.mapWithDefault(lastRoute, "-", MetricsNav$BsConsole.path)
                        ],
                        /* [] */0
                      ]
                    ];
          
        }
        break;
    case /* Query */4 :
        var metaDataTimes = $$event[2];
        var query = $$event[1];
        if (metaDataTimes !== undefined) {
          return /* :: */[
                  /* tuple */[
                    "query-json",
                    Curry._1(Crdb$BsConsole.Query.toPrivateJson, query)
                  ],
                  /* :: */[
                    /* tuple */[
                      "query-times-filter",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), metaDataTimes.filter)
                    ],
                    /* :: */[
                      /* tuple */[
                        "query-times-total",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), metaDataTimes.total)
                      ],
                      /* :: */[
                        /* tuple */[
                          "query-times-group-by",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), metaDataTimes.group_by)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ];
        } else {
          return /* :: */[
                  /* tuple */[
                    "query-json",
                    Curry._1(Crdb$BsConsole.Query.toPrivateJson, query)
                  ],
                  /* [] */0
                ];
        }
    case /* QueryJson */5 :
        var metaDataTimes$1 = $$event[2];
        var queryJson = $$event[1];
        if (metaDataTimes$1 !== undefined) {
          return /* :: */[
                  /* tuple */[
                    "query-json",
                    queryJson
                  ],
                  /* :: */[
                    /* tuple */[
                      "query-times-filter",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), metaDataTimes$1.filter)
                    ],
                    /* :: */[
                      /* tuple */[
                        "query-times-total",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), metaDataTimes$1.total)
                      ],
                      /* :: */[
                        /* tuple */[
                          "query-times-group-by",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), metaDataTimes$1.group_by)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ];
        } else {
          return /* :: */[
                  /* tuple */[
                    "query-json",
                    queryJson
                  ],
                  /* [] */0
                ];
        }
    case /* QueryJsonWithAttrs */6 :
        var metaDataTimes$2 = $$event[2];
        var queryJson$1 = $$event[1];
        var timeProps = metaDataTimes$2 !== undefined ? /* :: */[
            /* tuple */[
              "query-json",
              queryJson$1
            ],
            /* :: */[
              /* tuple */[
                "query-times-filter",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), metaDataTimes$2.filter)
              ],
              /* :: */[
                /* tuple */[
                  "query-times-total",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), metaDataTimes$2.total)
                ],
                /* :: */[
                  /* tuple */[
                    "query-times-group-by",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), metaDataTimes$2.group_by)
                  ],
                  /* [] */0
                ]
              ]
            ]
          ] : /* :: */[
            /* tuple */[
              "query-json",
              queryJson$1
            ],
            /* [] */0
          ];
        var includedAttrs = Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, $$event[3])));
        return Belt_List.concat(timeProps, includedAttrs);
    
  }
}

function toJson(extraProperties, now, eventName) {
  var propertyTuples = Belt_List.concat(/* :: */[
        /* tuple */[
          "__now",
          now
        ],
        /* :: */[
          /* tuple */[
            "console_major",
            "3"
          ],
          /* :: */[
            /* tuple */[
              "consoleVersion",
              Version$BsConsole.version
            ],
            /* :: */[
              /* tuple */[
                "eventName",
                eventName
              ],
              /* :: */[
                /* tuple */[
                  "universe",
                  Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("universe.name"), "unknown")
                ],
                /* :: */[
                  /* tuple */[
                    "coronerdVersion",
                    Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("coronerdVersion"), "unknown")
                  ],
                  /* :: */[
                    /* tuple */[
                      "email",
                      Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("user.email"), "unknown")
                    ],
                    /* :: */[
                      /* tuple */[
                        "username",
                        Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("user.username"), "unknown")
                      ],
                      /* :: */[
                        /* tuple */[
                          "role",
                          Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("user.role"), "unknown")
                        ],
                        /* :: */[
                          /* tuple */[
                            "react.version",
                            Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("react.version"), "unknown")
                          ],
                          /* :: */[
                            /* tuple */[
                              "browser.name",
                              Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("browser.name"), "unknown")
                            ],
                            /* :: */[
                              /* tuple */[
                                "browser.version",
                                Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("browser.version"), "unknown")
                              ],
                              /* :: */[
                                /* tuple */[
                                  "browser.platform",
                                  Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("browser.platform"), "unknown")
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "browser.vendor",
                                    Belt_Option.getWithDefault(Backtrace$BsConsole.Client.getAttribute("browser.vendor"), "unknown")
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "max-error-count",
                                      Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_MAX_ERROR_COUNT), 0)
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ], Belt_Option.getWithDefault(extraProperties, /* [] */0));
  var allProperties = Belt_List.concat(propertyTuples, Belt_List.fromArray(Belt_MutableSet.toArray(MetricsMemory$BsConsole.memorySet)));
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "event",
                eventName
              ],
              /* :: */[
                /* tuple */[
                  "properties",
                  Json_encode.object_(allProperties)
                ],
                /* [] */0
              ]
            ]);
}

function toUrlEncode(param) {
  var $$event = param.event;
  var eventName = toString$1($$event);
  var extraProperties = toProperties($$event);
  return new Buffer(JSON.stringify(toJson(extraProperties, param.now, eventName))).toString("base64");
}

function toBreadcrumbData(queuedEvent) {
  var eventName = toString$1(queuedEvent.event);
  var extraProperties = toProperties(queuedEvent.event);
  var json = toJson(extraProperties, queuedEvent.now, eventName);
  var data = Backtrace$BsConsole.Attributes.make(undefined);
  Backtrace$BsConsole.Attributes.addJson("data", json, data);
  return data;
}

function dispatchEvent(queuedEvent) {
  Backtrace$BsConsole.Client.leaveBreadcrumb(undefined, Caml_option.some(toBreadcrumbData(queuedEvent)), toString$1(queuedEvent.event));
  var data64 = toUrlEncode(queuedEvent);
  var uri64 = MetricsUri$BsConsole.toUrlEncode(MetricsService$BsConsole.mixpanel.url + endpoint);
  return MetricsRequest$BsConsole.get(MetricsUri$BsConsole.getBaseUri(undefined) + ("?uri=" + (uri64 + ("&data=" + data64))));
}

var queue = {
  contents: /* [] */0
};

function queueEvent($$event) {
  queue.contents = /* :: */[
    {
      now: Date.now(),
      event: $$event
    },
    queue.contents
  ];
  
}

function dispatchQueue(param) {
  Belt_List.forEach(Belt_List.reverse(queue.contents), dispatchEvent);
  queue.contents = /* [] */0;
  
}

function send($$event) {
  queueEvent($$event);
  if (initialized.contents) {
    return dispatchQueue(undefined);
  }
  
}

function forceSend($$event) {
  return dispatchEvent({
              now: Date.now(),
              event: $$event
            });
}

var consoleVersion = Version$BsConsole.version;

exports.endpoint = endpoint;
exports.initialized = initialized;
exports.setInitialized = setInitialized;
exports.Action = Action;
exports.toString = toString$1;
exports.toProperties = toProperties;
exports.consoleVersion = consoleVersion;
exports.toJson = toJson;
exports.toUrlEncode = toUrlEncode;
exports.toBreadcrumbData = toBreadcrumbData;
exports.dispatchEvent = dispatchEvent;
exports.queue = queue;
exports.queueEvent = queueEvent;
exports.dispatchQueue = dispatchQueue;
exports.send = send;
exports.forceSend = forceSend;
/* Crdb-BsConsole Not a pure module */
