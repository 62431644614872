// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Task$BsConsole = require("./task.js");
var Task2$BsConsole = require("./Task2.js");
var ApiSymbols$BsConsole = require("./ApiSymbols.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ApiMissingSymbols$BsConsole = require("./ApiMissingSymbols.js");
var ApiSymbolArchives$BsConsole = require("./ApiSymbolArchives.js");
var ApiSymbolsSummary$BsConsole = require("./ApiSymbolsSummary.js");
var ApiSymbolArchiveTags$BsConsole = require("./ApiSymbolArchiveTags.js");

var component = ReasonReact.reducerComponent("FetchSymbols-BsConsole");

function make(handleTask, token, name, archiveIdsOpt, children) {
  var archiveIds = archiveIdsOpt !== undefined ? Caml_option.valFromOption(archiveIdsOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              switch (action) {
                case /* Request */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolArchives$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolArchives */Block.__(15, [
                                                    token,
                                                    name,
                                                    archiveIds
                                                  ])));
                                })
                            ]);
                case /* Update */1 :
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolArchives$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolArchives */Block.__(15, [
                                                    token,
                                                    name,
                                                    archiveIds
                                                  ])));
                                })]);
                case /* Failure */2 :
                    return /* NoUpdate */0;
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Archives = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("FetchSymbols-BsConsole");

function make$1(handleTask, token, name, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              switch (action) {
                case /* Request */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiMissingSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* MissingSymbols */Block.__(16, [
                                                    token,
                                                    name
                                                  ])));
                                })
                            ]);
                case /* Update */1 :
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiMissingSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* MissingSymbols */Block.__(16, [
                                                    token,
                                                    name
                                                  ])));
                                })]);
                case /* Failure */2 :
                    return /* NoUpdate */0;
                
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Missing = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.reducerComponent("FetchSymbols-BsConsole");

function make$2(handleTask, token, name, children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              switch (action) {
                case /* Request */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolsSummary$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolsSummary */Block.__(18, [
                                                    token,
                                                    name
                                                  ])));
                                })
                            ]);
                case /* Update */1 :
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolsSummary$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolsSummary */Block.__(18, [
                                                    token,
                                                    name
                                                  ])));
                                })]);
                case /* Failure */2 :
                    return /* NoUpdate */0;
                
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var Summary = {
  component: component$2,
  make: make$2
};

var component$3 = ReasonReact.reducerComponent("FetchSymbols-BsConsole");

function make$3(handleTask, token, name, children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              switch (action) {
                case /* Request */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolArchiveTags$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolArchiveTags */Block.__(14, [
                                                    token,
                                                    name
                                                  ])));
                                })
                            ]);
                case /* Update */1 :
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbolArchiveTags$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* SymbolArchiveTags */Block.__(14, [
                                                    token,
                                                    name
                                                  ])));
                                })]);
                case /* Failure */2 :
                    return /* NoUpdate */0;
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var Tags = {
  component: component$3,
  make: make$3
};

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  return Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : "Error: Something went wrong. This error has been reported to Backtrace.");
}

function makeForm(archiveId, tag, param) {
  var archiveIdArray = Belt_Option.flatMap(archiveId, (function (id) {
          return /* tuple */[
                  "archive_ids",
                  [id]
                ];
        }));
  var tagArray = Belt_Option.flatMap(tag, (function (tag) {
          return /* tuple */[
                  "tags",
                  [tag]
                ];
        }));
  return Json_encode.object_(Belt_List.keepMap(/* :: */[
                  archiveIdArray,
                  /* :: */[
                    tagArray,
                    /* [] */0
                  ]
                ], (function (x) {
                    return x;
                  })));
}

function makeTask(projectName, archiveId, tag) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/symfile?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "symbols"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          makeForm(archiveId, tag, undefined)
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                return /* Ok */Block.__(0, [ApiSymbols$BsConsole.decode(param.json)]);
              }), undefined, undefined);
}

function useTask(token, projectName, archiveId, tag) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp]);
                }));
  };
  var onFailure = function (msg) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [msg]);
                }));
  };
  var task = makeTask(projectName, archiveId, tag);
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

var component$4 = ReasonReact.reducerComponent("FetchSymbols-BsConsole");

function make$4(handleTask, token, name, tag, archiveId, children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              switch (action) {
                case /* Request */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  if (archiveId !== undefined && tag !== undefined) {
                                    return Curry._2(handleTask, (function (json) {
                                                  return Curry._1(self.send, /* Success */[ApiSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                                }), Task$BsConsole.makeTask(/* Symbols */Block.__(17, [
                                                      token,
                                                      name,
                                                      archiveId,
                                                      tag
                                                    ])));
                                  }
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* Symbols */Block.__(17, [
                                                    token,
                                                    name,
                                                    undefined,
                                                    undefined
                                                  ])));
                                })
                            ]);
                case /* Update */1 :
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  if (archiveId !== undefined && tag !== undefined) {
                                    return Curry._2(handleTask, (function (json) {
                                                  return Curry._1(self.send, /* Success */[ApiSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                                }), Task$BsConsole.makeTask(/* Symbols */Block.__(17, [
                                                      token,
                                                      name,
                                                      archiveId,
                                                      tag
                                                    ])));
                                  }
                                  return Curry._2(handleTask, (function (json) {
                                                return Curry._1(self.send, /* Success */[ApiSymbols$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                              }), Task$BsConsole.makeTask(/* Symbols */Block.__(17, [
                                                    token,
                                                    name,
                                                    undefined,
                                                    undefined
                                                  ])));
                                })]);
                case /* Failure */2 :
                    return /* NoUpdate */0;
                
              }
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

exports.Archives = Archives;
exports.Missing = Missing;
exports.Summary = Summary;
exports.Tags = Tags;
exports.responseCb = responseCb;
exports.makeForm = makeForm;
exports.makeTask = makeTask;
exports.useTask = useTask;
exports.component = component$4;
exports.make = make$4;
/* component Not a pure module */
