// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Reprocessing_Types$BsConsole = require("./Reprocessing_Types.js");

var DecodeError = Caml_exceptions.create("Reprocessing_Tasks-BsConsole.DecodeError");

function control(token, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "token",
                        token
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "status"
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [Reprocessing_Types$BsConsole.Control.ofJson(param.json)]);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  return /* Error */Block.__(1, [[
                              DecodeError,
                              e
                            ]]);
                }
              }), undefined, undefined);
}

function reload(token, project, universe, query, param) {
  var props_000 = /* tuple */[
    "token",
    token
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "action",
      "reload"
    ],
    /* :: */[
      /* tuple */[
        "project",
        project
      ],
      /* :: */[
        /* tuple */[
          "universe",
          universe
        ],
        /* [] */0
      ]
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var props$1 = query !== undefined ? /* :: */[
      /* tuple */[
        "query",
        Crdb$BsConsole.QueryManager.toCrdb(query)
      ],
      props
    ] : props;
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(props$1)]), undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [Reprocessing_Types$BsConsole.ReloadResp.ofJson(param.json)]);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  return /* Error */Block.__(1, [[
                              DecodeError,
                              e
                            ]]);
                }
              }), undefined, undefined);
}

function reloadObjects(token, project, universe, objects) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/control"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "token",
                        token
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "reload"
                        ],
                        /* :: */[
                          /* tuple */[
                            "project",
                            project
                          ],
                          /* :: */[
                            /* tuple */[
                              "universe",
                              universe
                            ],
                            /* :: */[
                              /* tuple */[
                                "objects",
                                objects
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])]), undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [Reprocessing_Types$BsConsole.ReloadResp.ofJson(param.json)]);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  return /* Error */Block.__(1, [[
                              DecodeError,
                              e
                            ]]);
                }
              }), undefined, undefined);
}

exports.DecodeError = DecodeError;
exports.control = control;
exports.reload = reload;
exports.reloadObjects = reloadObjects;
/* Crdb-BsConsole Not a pure module */
