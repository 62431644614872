// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var PrTitleRow$BsConsole = require("./PrTitleRow.js");
var WindowSize$BsConsole = require("../WindowSize.js");
var PrContentWrap$BsConsole = require("./PrContentWrap.js");

var component = RR$BsConsole.statelessComponent("PrModalLayout-BsConsole");

function make(factorName, factorValue, aggregationParameter, aggregationName, onClose, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, WindowSize$BsConsole.make((function (width, height) {
                                var horizontalPadding = width > 1300 ? 150 : 75;
                                var dialogWidth = Caml_primitive.caml_int_min(width - (horizontalPadding << 1) | 0, 1300);
                                var dialogMaxHeight = height - 200 | 0;
                                return React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.width(Css.px(dialogWidth)),
                                                  /* :: */[
                                                    Css.maxHeight(Css.px(dialogMaxHeight)),
                                                    /* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.flexDirection(/* column */-963948842),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ])
                                          }, ReasonReact.element(undefined, undefined, PrTitleRow$BsConsole.make(factorName, factorValue, aggregationName, aggregationParameter, onClose, [])), ReasonReact.element(undefined, undefined, PrContentWrap$BsConsole.make([Curry._2(children, dialogWidth, dialogMaxHeight)])));
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
