// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

function toPathname(t) {
  switch (IdentityVariant$BsConsole.current) {
    case /* Backtrace */0 :
        switch (t) {
          case /* ArticleDataRetention */0 :
              return "support.backtrace.io/hc/en-us/articles/360040515731-Data-Retention";
          case /* Base */1 :
              return "docs.saucelabs.com/error-reporting/getting-started/";
          case /* ArticlePhabricatorIntegration */2 :
              return "docs.saucelabs.com/error-reporting/workflow-integrations/issue-tracking/phabricator/";
          case /* ArticlePricingTierFeatures */3 :
              return "support.backtrace.io/hc/en-us/articles/360040105152-What-features-are-included-in-the-different-tiers-";
          case /* ArticleScheduledReports */4 :
              return "docs.saucelabs.com/error-reporting/project-setup/scheduled-reports/";
          case /* ArticleStorageSampling */5 :
              return "support.backtrace.io/hc/en-us/articles/360047271572-Storage-Sampling";
          case /* ArticleSubmissionBuckets */6 :
              return "support.backtrace.io/hc/en-us/articles/360040104772-Submission-Buckets";
          case /* CategoryIntegrationGuides */7 :
              return "support.backtrace.io/hc/en-us/categories/360002096212-Integration-Guides";
          case /* GuideAccountManagement */8 :
              return "docs.saucelabs.com/error-reporting/org-settings/user-mgmnt/";
          case /* GuideIntegrationBreakpad */9 :
              return "support.backtrace.io/hc/en-us/articles/360040106132-Breakpad-Integration-Guide";
          case /* GuideIntegrationCoresnap */10 :
              return "support.backtrace.io/hc/en-us/articles/360040106052-Coresnap-Integration-Guide";
          case /* GuideIntegrationCrashpad */11 :
              return "support.backtrace.io/hc/en-us/articles/360040516131-Crashpad-Integration-Guide";
          case /* GuideIntegrationCryEngine */12 :
              return "support.backtrace.io/hc/en-us/articles/360058815691-CryEngine-Integration-Guide";
          case /* GuideIntegrationElectron */13 :
              return "support.backtrace.io/hc/en-us/articles/360040517231-Electron-Integration-Guide";
          case /* GuideIntegrationHttpMinidump */14 :
              return "support.backtrace.io/hc/en-us/articles/360040106092-HTTP-Submission-of-Minidump";
          case /* GuideIntegrationIOS */15 :
              return "docs.saucelabs.com/error-reporting/platform-integrations/ios/setup/";
          case /* GuideIntegrationUnity */16 :
              return "docs.saucelabs.com/error-reporting/platform-integrations/unity/setup/";
          case /* GuideIntegrationUnreal */17 :
              return "docs.saucelabs.com/error-reporting/platform-integrations/unreal/setup/";
          case /* GuideIntegrationVisualStudio */18 :
              return "support.backtrace.io/hc/en-us/articles/360040515951-Visual-Studio-Extension-Guide-C-and-Crashpad-";
          case /* GuideFlamegraphs */19 :
              return "docs.saucelabs.com/error-reporting/web-console/flame-graphs/";
          case /* ManageTheBacktraceMetricObjects */20 :
              return "docs.saucelabs.com/error-reporting/project-setup/metrics-stability-scores/#manage-the-backtrace-metric-objects";
          case /* SettingsAttributes */21 :
              return "docs.saucelabs.com/error-reporting/project-setup/attributes/";
          case /* SettingsSymbolification */22 :
              return "docs.saucelabs.com/error-reporting/project-setup/symbolication/";
          case /* StabilityMetricWidgets */23 :
              return "docs.saucelabs.com/error-reporting/web-console/overview/#stability-metrics-widgets";
          case /* WhatAreSymbols */24 :
              return "support.backtrace.io/hc/en-us/articles/360040105192-What-are-symbols-";
          case /* WhatIsASubmissionToken */25 :
              return "support.backtrace.io/hc/en-us/articles/360040105172-What-is-a-submission-token-";
          case /* WhyAreAggregateFiltersRemovedInCertainViews */26 :
              return "support.backtrace.io/hc/en-us/articles/360046612991";
          
        }
    case /* SauceLabs */1 :
        return ;
    case /* Unity */2 :
        switch (t) {
          case /* Base */1 :
              return "docs.unity.com/cloud-diagnostics-advanced/";
          case /* ManageTheBacktraceMetricObjects */20 :
              return "docs.unity.com/cloud-diagnostics-advanced/StabilityMonitoring.html";
          case /* SettingsAttributes */21 :
              return "docs.unity.com/cloud-diagnostics-advanced/Attributes.html";
          case /* StabilityMetricWidgets */23 :
              return "docs.unity.com/cloud-diagnostics-advanced/Overview.html#Stability_metrics_widgets";
          case /* SettingsSymbolification */22 :
          case /* WhatAreSymbols */24 :
              return "docs.unity.com/cloud-diagnostics-advanced/DebugSymbols.html";
          case /* ArticleDataRetention */0 :
          case /* ArticlePhabricatorIntegration */2 :
          case /* ArticlePricingTierFeatures */3 :
          case /* ArticleScheduledReports */4 :
          case /* ArticleStorageSampling */5 :
          case /* ArticleSubmissionBuckets */6 :
          case /* CategoryIntegrationGuides */7 :
          case /* GuideAccountManagement */8 :
          case /* GuideIntegrationBreakpad */9 :
          case /* GuideIntegrationCoresnap */10 :
          case /* GuideIntegrationCrashpad */11 :
          case /* GuideIntegrationCryEngine */12 :
          case /* GuideIntegrationElectron */13 :
          case /* GuideIntegrationHttpMinidump */14 :
          case /* GuideIntegrationIOS */15 :
          case /* GuideIntegrationUnity */16 :
          case /* GuideIntegrationUnreal */17 :
          case /* GuideIntegrationVisualStudio */18 :
          case /* GuideFlamegraphs */19 :
          case /* WhatIsASubmissionToken */25 :
          case /* WhyAreAggregateFiltersRemovedInCertainViews */26 :
              return ;
          
        }
    
  }
}

function normalizePathString(path) {
  if ("/".startsWith(path)) {
    return path.substr(1);
  } else {
    return path;
  }
}

function make(noSchemaOpt, page) {
  var noSchema = noSchemaOpt !== undefined ? noSchemaOpt : false;
  var path = toPathname(page);
  if (path !== undefined) {
    var noSchemaOpt$1 = noSchema;
    var noSchema$1 = noSchemaOpt$1 !== undefined ? noSchemaOpt$1 : false;
    var path$1 = normalizePathString(path);
    if (noSchema$1) {
      return "//" + (String(path$1) + "");
    } else {
      return "" + (String("https") + ("://" + (String(path$1) + "")));
    }
  }
  
}

var jsMapperConstantArray = [/* tuple */[
    -642242812,
    "https://docs.unity.com/cloud-diagnostics-advanced/Setup.html"
  ]];

function outsideLinksToJs(param) {
  return Js_mapperRt.binarySearch(1, param, jsMapperConstantArray);
}

var Support_Doc = { };

var Support = {
  Doc: Support_Doc,
  make: make
};

var changelog = "https://changelog.saucelabs.com/en?category=error%20reporting";

exports.Support = Support;
exports.changelog = changelog;
exports.outsideLinksToJs = outsideLinksToJs;
/* IdentityVariant-BsConsole Not a pure module */
