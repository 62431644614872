// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var ApiResourceList$BsConsole = require("./ApiResourceList.js");

function buildURL(projectName, resource, token, errorId) {
  var tmp = resource === "raw" ? "&resource=raw" : "&resource=" + (resource + ".gz");
  return "/api/get?token=" + (token + ("&project=" + (projectName + ("&object=" + (errorId + tmp)))));
}

var ParseError = Caml_exceptions.create("ResourceFork-BsConsole.ParseError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

var defaultResourceList = [
  "json",
  "txt"
];

function use(token, projectName, errorId) {
  var match = React.useState((function () {
          return [];
        }));
  var setResourceList = match[1];
  React.useEffect((function () {
          var onSuccess = function (resourceList) {
            return Curry._1(setResourceList, (function (param) {
                          return resourceList;
                        }));
          };
          var onFailure = function (param) {
            return Curry._1(setResourceList, (function (param) {
                          return defaultResourceList;
                        }));
          };
          var arg = Task2$BsConsole.make(/* Api */Block.__(0, [Curry._2(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "/list?view=resources&object=",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        "&project=",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ]),
                            "/list?view=resources&object=%s&project=%s"
                          ]), errorId, projectName)]), undefined, /* Get */0, undefined, (function (param) {
                  var resourceList = ApiResourceList$BsConsole.fromJson(param.json);
                  return /* Ok */Block.__(0, [resourceList]);
                }), undefined, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  return responseCb(onSuccess, onFailure, param);
                }));
          
        }), [errorId]);
  return Belt_Array.map(Belt_Array.concat(match[0], ["raw"]), (function (name) {
                return {
                        name: name,
                        url: buildURL(projectName, name, token, errorId)
                      };
              }));
}

exports.buildURL = buildURL;
exports.ParseError = ParseError;
exports.responseCb = responseCb;
exports.defaultResourceList = defaultResourceList;
exports.use = use;
/* react Not a pure module */
