// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Nemesis$BsConsole = require("./Nemesis.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var BillingConstants$BsConsole = require("../BillingConstants.js");

var UnexpectedValueError = Caml_exceptions.create("NemesisPlanSelection-BsConsole.UnexpectedValueError");

var ErrorMessage = Caml_exceptions.create("NemesisPlanSelection-BsConsole.ErrorMessage");

function fromString(str) {
  switch (str) {
    case "addon" :
        return /* Addon */0;
    case "plan" :
        return /* Plan */1;
    default:
      throw [
            UnexpectedValueError,
            "planType"
          ];
  }
}

var PlanType = {
  fromString: fromString
};

function fromString$1(str) {
  switch (str) {
    case "flat_fee" :
        return /* FlatFee */0;
    case "per_unit" :
        return /* PerUnit */1;
    case "stairstep" :
        return /* Stairstep */4;
    case "tiered" :
        return /* Tiered */2;
    case "volume" :
        return /* Volume */3;
    default:
      throw [
            UnexpectedValueError,
            "pricing_model"
          ];
  }
}

var PricingModel = {
  fromString: fromString$1
};

function fromString$2(str) {
  switch (str) {
    case "day" :
        return /* Day */0;
    case "month" :
        return /* Month */2;
    case "not_applicable" :
        return /* NotApplicable */4;
    case "week" :
        return /* Week */1;
    case "year" :
        return /* Year */3;
    default:
      throw [
            UnexpectedValueError,
            "period_unit"
          ];
  }
}

function toPeriod1String(periodUnit) {
  switch (periodUnit) {
    case /* Day */0 :
        return I18N$BsConsole.get(undefined, "daily");
    case /* Week */1 :
        return I18N$BsConsole.get(undefined, "weekly");
    case /* Month */2 :
        return I18N$BsConsole.get(undefined, "monthly");
    case /* Year */3 :
        return I18N$BsConsole.get(undefined, "annually");
    case /* NotApplicable */4 :
        return I18N$BsConsole.empty(undefined);
    
  }
}

var PeriodUnit = {
  fromString: fromString$2,
  toPeriod1String: toPeriod1String
};

function tierFromJson(json) {
  return {
          startingUnit: Json_decode.field("starting_unit", Json_decode.$$int, json),
          endingUnit: Json_decode.field("ending_unit", Json_decode.$$int, json),
          price: Json_decode.field("price", Json_decode.$$int, json)
        };
}

function priceFromJson(json) {
  return {
          pricingModel: Json_decode.field("pricing_model", (function (json) {
                  return fromString$1(Json_decode.string(json));
                }), json),
          price: Json_decode.optional((function (param) {
                  return Json_decode.field("price", Json_decode.$$int, param);
                }), json),
          tiers: Json_decode.optional((function (param) {
                  return Json_decode.field("tiers", (function (param) {
                                return Json_decode.array(tierFromJson, param);
                              }), param);
                }), json),
          period: Json_decode.field("period", Json_decode.$$int, json),
          periodUnit: Json_decode.field("period_unit", (function (json) {
                  return fromString$2(Json_decode.string(json));
                }), json)
        };
}

function incrementingFeatureFromJson(json) {
  var partial_arg_001 = /* :: */[
    (function (json) {
        return Caml_format.caml_int_of_string(Json_decode.string(json));
      }),
    /* [] */0
  ];
  var partial_arg = /* :: */[
    Json_decode.$$int,
    partial_arg_001
  ];
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          label: Json_decode.optional((function (param) {
                  return Json_decode.field("label", Json_decode.string, param);
                }), json),
          planType: Json_decode.field("planType", (function (json) {
                  return fromString(Json_decode.string(json));
                }), json),
          incrementAmount: Json_decode.field("incrementAmount", (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json),
          price: Json_decode.optional((function (param) {
                  return Json_decode.field("price", priceFromJson, param);
                }), json)
        };
}

function baseFeatureFromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json)
        };
}

function planSelectionFromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          quantity: Json_decode.field("quantity", Json_decode.bool, json),
          incrementingFeatures: Json_decode.field("incrementingFeatures", (function (param) {
                  return Json_decode.array(incrementingFeatureFromJson, param);
                }), json),
          baseFeatures: Json_decode.field("baseFeatures", (function (param) {
                  return Json_decode.array(baseFeatureFromJson, param);
                }), json),
          price: Json_decode.field("price", priceFromJson, json)
        };
}

function planSelectionChoicesFromJson(json) {
  return Json_decode.at(/* :: */[
                "results",
                /* :: */[
                  "basePlans",
                  /* [] */0
                ]
              ], (function (param) {
                  return Json_decode.array(planSelectionFromJson, param);
                }))(json);
}

function $$fetch(param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/plan-selection")]), undefined, /* Get */0, [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  return /* Ok */Block.__(0, [planSelectionChoicesFromJson(param.json)]);
                }
                catch (raw_fieldName){
                  var fieldName = Caml_js_exceptions.internalToOCamlException(raw_fieldName);
                  if (fieldName[0] !== UnexpectedValueError) {
                    return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                  }
                  var str = Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                            /* String_literal */Block.__(11, [
                                "Unexpected value for field ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "Unexpected value for field %s"
                          ]), fieldName[1]);
                  return /* Error */Block.__(1, [[
                              ErrorMessage,
                              str
                            ]]);
                }
              }), undefined, undefined);
}

exports.UnexpectedValueError = UnexpectedValueError;
exports.ErrorMessage = ErrorMessage;
exports.PlanType = PlanType;
exports.PricingModel = PricingModel;
exports.PeriodUnit = PeriodUnit;
exports.tierFromJson = tierFromJson;
exports.priceFromJson = priceFromJson;
exports.incrementingFeatureFromJson = incrementingFeatureFromJson;
exports.baseFeatureFromJson = baseFeatureFromJson;
exports.planSelectionFromJson = planSelectionFromJson;
exports.planSelectionChoicesFromJson = planSelectionChoicesFromJson;
exports.$$fetch = $$fetch;
/* I18N-BsConsole Not a pure module */
