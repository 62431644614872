// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ListUtils$BsConsole = require("../../ListUtils.js");

function Make(F, S, C) {
  var add = function (a$prime, t$prime) {
    return ListUtils$BsConsole.uniq(Belt_List.add(t$prime, a$prime));
  };
  var remove = function (a$prime, t$prime) {
    return Belt_List.keep(t$prime, (function (a$prime$prime) {
                  return Caml_obj.caml_notequal(a$prime$prime, a$prime);
                }));
  };
  var keep = function (predicate, t$prime) {
    return Belt_List.keep(t$prime, predicate);
  };
  var getBy = function (predicate, t$prime) {
    return Belt_List.getBy(t$prime, predicate);
  };
  var addRange = function (attribute, param, t) {
    var match = Curry._1(F.createRange, /* tuple */[
          attribute,
          /* tuple */[
            param[0],
            param[1]
          ]
        ]);
    var t$prime = ListUtils$BsConsole.uniq(Belt_List.add(t, match[0]));
    return ListUtils$BsConsole.uniq(Belt_List.add(t$prime, match[1]));
  };
  var eq = function (t$prime, t$prime$prime) {
    return Belt_Set.eq(Belt_Set.fromArray(Belt_List.toArray(t$prime), C), Belt_Set.fromArray(Belt_List.toArray(t$prime$prime), C));
  };
  return {
          empty: /* [] */0,
          add: add,
          remove: remove,
          keep: keep,
          getBy: getBy,
          size: Belt_List.size,
          toArray: Belt_List.toArray,
          fromArray: Belt_List.fromArray,
          toCrdb: S.toCrdb,
          toJson: S.toJson,
          toPrivateJson: S.toPrivateJson,
          fromJson: S.fromJson,
          toURLEncodedJson: S.toURLEncodedJson,
          fromURLEncodedJson: S.fromURLEncodedJson,
          addRange: addRange,
          eq: eq
        };
}

exports.Make = Make;
/* No side effect */
