// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Email$BsConsole = require("../Email.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var SubCardInfo$BsConsole = require("./SubCardInfo.js");
var Card = require("@material-ui/core/Card");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Switch = require("@material-ui/core/Switch");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var Tooltip = require("@material-ui/core/Tooltip");
var NemesisInvoiceMe$BsConsole = require("./NemesisInvoiceMe.js");
var InputLabel = require("@material-ui/core/InputLabel");
var CardContent = require("@material-ui/core/CardContent");
var SettingsBillingPaymentView$BsConsole = require("../SettingsBillingPaymentView.js");

function SubPaymentBanner$ChangeCreditCardInfoButton(Props) {
  var subscription = Props.subscription;
  var config = Props.config;
  return React.createElement(Button.default, {
              variant: "contained",
              disabled: !SubUtil$BsConsole.canModifyNemesisPlans(config),
              onClick: (function (param) {
                  
                }),
              children: subscription.cardOnFile ? I18N$BsConsole.show(undefined, "Replace card on file") : I18N$BsConsole.show(undefined, "Enter payment information")
            });
}

var ChangeCreditCardInfoButton = {
  make: SubPaymentBanner$ChangeCreditCardInfoButton
};

function SubPaymentBanner$InvoiceMe(Props) {
  var config = Props.config;
  var invoiceMe = Props.invoiceMe;
  var setInvoiceMe = Props.setInvoiceMe;
  var updateInvoiceMe = Props.updateInvoiceMe;
  if (invoiceMe) {
    return React.createElement(Col2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.marginTop(Css.px(12)),
                        /* [] */0
                      ]
                    ]),
                children: null
              }, React.createElement("div", {
                    className: SubUtil$BsConsole.Style.titleText
                  }, I18N$BsConsole.show(undefined, "Alternative payment methods")), React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: Css.merge(/* :: */[
                              SubUtil$BsConsole.Style.bodyText,
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.width(Css.px(760)),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "Enable the \"Invoice me\" feature if you'd prefer to use an alternative payment method. When enabled, our team will be notified and get in contact with you regarding payment options.")), React.createElement(Row2$BsConsole.make, {
                        alignItems: /* baseline */287825029,
                        children: null
                      }, React.createElement(InputLabel.default, {
                            htmlFor: "enable-invoice-me",
                            children: React.createElement("div", {
                                  className: SubUtil$BsConsole.Style.titleText
                                }, I18N$BsConsole.show(undefined, "Invoice me"))
                          }), React.createElement(Tooltip.default, {
                            children: React.createElement("div", undefined, React.createElement(Switch.default, {
                                      id: "enable-invoice-me",
                                      color: "primary",
                                      disabled: !SubUtil$BsConsole.canModifyNemesisPlans(config),
                                      onChange: (function (_form, _bool) {
                                          return Curry._1(setInvoiceMe, (function (prev) {
                                                        NemesisMetrics$BsConsole.send(/* UpdateInvoiceMe */4);
                                                        Curry._1(updateInvoiceMe, !prev);
                                                        return !prev;
                                                      }));
                                        }),
                                      checked: invoiceMe
                                    })),
                            title: SubUtil$BsConsole.canModifyNemesisPlans(config) ? I18N$BsConsole.empty(undefined) : SubUtil$BsConsole.onlyAdminsText
                          }))));
  } else {
    return null;
  }
}

var InvoiceMe = {
  make: SubPaymentBanner$InvoiceMe
};

function SubPaymentBanner(Props) {
  var config = Props.config;
  var subscription = Props.subscription;
  var token = Props.token;
  var refetchSubscription = Props.refetchSubscription;
  Props.limits;
  var refetchLimits = Props.refetchLimits;
  var invoiceMe = Props.invoiceMe;
  var setInvoiceMe = Props.setInvoiceMe;
  var updateInvoiceMe = function (flagValue) {
    var arg = NemesisInvoiceMe$BsConsole.update(flagValue);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    Curry._1(setInvoiceMe, (function (prev) {
                            return !prev;
                          }));
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SubUtil$BsConsole.unknownErrorMessage));
                  }
                  var toast = flagValue ? I18N$BsConsole.get(undefined, "Invoicing enabled. Our team will be in touch via email!") : I18N$BsConsole.get(undefined, "Credit card billing enabled. Please enter payment information.");
                  return Curry._1(refetchSubscription, toast);
                }));
  };
  var cardInfo = subscription.cardInfo;
  return React.createElement("div", undefined, React.createElement(Card.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]
                        ])
                  },
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Col2$BsConsole.make, {
                              children: null
                            }, React.createElement("div", undefined, React.createElement("div", {
                                      className: SubUtil$BsConsole.Style.titleText
                                    }, I18N$BsConsole.show(undefined, "Credit card")), React.createElement("div", {
                                      className: SubUtil$BsConsole.Style.bodyText
                                    }, I18N$BsConsole.show(undefined, "Use a credit card to pay for your Backtrace subscription. We use Stripe to ensure the security of your payment information.")), React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* baseline */287825029,
                                      justifyContent: /* flexStart */662439529,
                                      className: Css.style(/* :: */[
                                            Css.marginTop(Css.px(10)),
                                            /* [] */0
                                          ]),
                                      children: null
                                    }, cardInfo !== undefined ? React.createElement("div", {
                                            className: Css.merge(/* :: */[
                                                  SubUtil$BsConsole.Style.bodyText,
                                                  /* :: */[
                                                    Css.style(/* :: */[
                                                          Css.marginRight(Css.px(25)),
                                                          /* [] */0
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ])
                                          }, I18N$BsConsole.showSkip(SubCardInfo$BsConsole.toString(cardInfo))) : null, React.createElement(Tooltip.default, {
                                          children: React.createElement("div", undefined, SubUtil$BsConsole.canModifyNemesisPlans(config) ? React.createElement(SettingsBillingPaymentView$BsConsole.Jsx3.make, {
                                                      token: token,
                                                      onResolve: (function (response) {
                                                          if (response) {
                                                            NemesisMetrics$BsConsole.send(/* UpdateCreditCard */3);
                                                            Curry._1(refetchSubscription, I18N$BsConsole.get(undefined, "Credit card updated."));
                                                            return Curry._1(refetchLimits, undefined);
                                                          } else {
                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                /* String_literal */Block.__(11, [
                                                                                    "Something went wrong. Please contact ",
                                                                                    /* String */Block.__(2, [
                                                                                        /* No_padding */0,
                                                                                        /* End_of_format */0
                                                                                      ])
                                                                                  ]),
                                                                                "Something went wrong. Please contact %s"
                                                                              ]), Email$BsConsole.sales));
                                                          }
                                                        }),
                                                      nemesis: true,
                                                      children: React.createElement(SubPaymentBanner$ChangeCreditCardInfoButton, {
                                                            subscription: subscription,
                                                            config: config
                                                          })
                                                    }) : React.createElement(SubPaymentBanner$ChangeCreditCardInfoButton, {
                                                      subscription: subscription,
                                                      config: config
                                                    })),
                                          title: SubUtil$BsConsole.canModifyNemesisPlans(config) ? I18N$BsConsole.empty(undefined) : SubUtil$BsConsole.onlyAdminsText
                                        }))), React.createElement("br", undefined), React.createElement(SubPaymentBanner$InvoiceMe, {
                                  config: config,
                                  invoiceMe: invoiceMe,
                                  setInvoiceMe: setInvoiceMe,
                                  updateInvoiceMe: updateInvoiceMe
                                }))
                      })
                }));
}

var noCardColor = Colors$BsConsole.orangeExtraDark;

var yesCardColor = Colors$BsConsole.greenOlive;

var make = SubPaymentBanner;

exports.noCardColor = noCardColor;
exports.yesCardColor = yesCardColor;
exports.ChangeCreditCardInfoButton = ChangeCreditCardInfoButton;
exports.InvoiceMe = InvoiceMe;
exports.make = make;
/* Css Not a pure module */
