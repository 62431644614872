// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var Util$BsConsole = require("./util.js");
var Typeahead$BsConsole = require("./typeahead/Typeahead.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var FilterValue$BsConsole = require("./filterValue.js");
var InboxDescribe$BsConsole = require("./inbox/InboxDescribe.js");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");

function getCount(param) {
  
}

function toString(t$prime) {
  return t$prime;
}

var fuzz = Util$BsConsole.Fuzz.filterOperation;

var OperationTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: "[ operation ]",
      fuzz: fuzz
    });

function setRef(tokenType, theRef, param) {
  var state = param.state;
  switch (tokenType) {
    case /* Attribute */0 :
        state.attributeInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
        return ;
    case /* Operation */1 :
        state.operationInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
        return ;
    case /* Value */2 :
        state.valueInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
        return ;
    
  }
}

var component = ReasonReact.reducerComponent("Filters-BsConsole");

var menu = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

var Style = {
  menu: menu
};

function make(onChange, onClose, name, token, handleTask, attributes, aperture, attributeOpt, filterOpt, valueOpt, _children) {
  var attribute = attributeOpt !== undefined ? Caml_option.valFromOption(attributeOpt) : undefined;
  var filter = filterOpt !== undefined ? Caml_option.valFromOption(filterOpt) : undefined;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var goTo = function (inputRef) {
                var r = inputRef.contents;
                if (r === undefined) {
                  return ;
                }
                var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                domElement.querySelector("input").focus();
                domElement.querySelector("input").select();
                
              };
              var attr = self.state.attribute;
              var match = self.state.filter;
              var match$1 = self.state.attribute;
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, {
                              root: menu
                            }, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, Curry._1(self.handle, (function (param, param$1) {
                                          return setRef(/* Attribute */0, param, param$1);
                                        })), Curry.app(AttributeTypeahead$BsConsole.make, [
                                        undefined,
                                        undefined,
                                        Belt_List.keep(attributes, Attribute_Helpers$BsConsole.filterFirstSeen),
                                        (function (att) {
                                            Curry._1(self.send, /* SetAttribute */Block.__(1, [att]));
                                            setTimeout((function (param) {
                                                    return goTo(self.state.operationInputRef);
                                                  }), 200);
                                            
                                          }),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        attribute !== undefined ? InboxUtils$BsConsole.getFriendlyName(attribute.name) : "",
                                        undefined,
                                        (function (param) {
                                            var match = self.state.attribute;
                                            if (match !== undefined) {
                                              return Curry._1(self.send, /* SetAttribute */Block.__(1, [undefined]));
                                            }
                                            
                                          }),
                                        []
                                      ])),
                              attr !== undefined ? ReasonReact.element(attr.name, Curry._1(self.handle, (function (param, param$1) {
                                            return setRef(/* Operation */1, param, param$1);
                                          })), Curry.app(OperationTypeahead.make, [
                                          undefined,
                                          undefined,
                                          InboxUtils$BsConsole.isNestedIssues(attr.name) ? InboxDescribe$BsConsole.filter(attr) : attr.filter,
                                          (function (opp) {
                                              Curry._1(self.send, /* SetFilter */Block.__(2, [opp]));
                                              setTimeout((function (param) {
                                                      return goTo(self.state.valueInputRef);
                                                    }), 200);
                                              
                                            }),
                                          undefined,
                                          (function (param) {
                                              Curry._1(self.send, /* SetAttribute */Block.__(1, [undefined]));
                                              setTimeout((function (param) {
                                                      return goTo(self.state.attributeInputRef);
                                                    }), 200);
                                              
                                            }),
                                          filter !== undefined ? filter : "",
                                          undefined,
                                          undefined,
                                          []
                                        ])) : null,
                              match !== undefined && match$1 !== undefined ? ReasonReact.element(match$1.name + ("__" + match), Curry._1(self.handle, (function (param, param$1) {
                                            return setRef(/* Value */2, param, param$1);
                                          })), FilterValue$BsConsole.make(match$1, match, (function (param) {
                                            return Curry._1(self.send, /* SetValue */Block.__(0, [param[0]]));
                                          }), name, token, handleTask, aperture, (function (param) {
                                            Curry._1(self.send, /* SetFilter */Block.__(2, [undefined]));
                                            setTimeout((function (param) {
                                                    return goTo(self.state.operationInputRef);
                                                  }), 200);
                                            
                                          }), value !== undefined ? value : "", [])) : null
                            ]));
            }),
          initialState: (function (param) {
              return {
                      attribute: attribute,
                      filter: filter,
                      value: value,
                      attributeInputRef: {
                        contents: undefined
                      },
                      operationInputRef: {
                        contents: undefined
                      },
                      valueInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetValue */0 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                attribute: state.attribute,
                                filter: state.filter,
                                value: action[0],
                                attributeInputRef: state.attributeInputRef,
                                operationInputRef: state.operationInputRef,
                                valueInputRef: state.valueInputRef
                              },
                              (function (self) {
                                  var filterMap = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
                                  var match = self.state.attribute;
                                  var match$1 = self.state.filter;
                                  var match$2 = self.state.value;
                                  var f = match !== undefined && match$1 !== undefined && match$2 !== undefined ? Caml_option.some(Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                  match.name,
                                                  Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                                        match$1,
                                                        match$2
                                                      ])
                                                ]), filterMap)) : undefined;
                                  if (f !== undefined) {
                                    Curry._1(onChange, Caml_option.valFromOption(f));
                                  }
                                  return Curry._1(onClose, undefined);
                                })
                            ]);
                case /* SetAttribute */1 :
                    return /* Update */Block.__(0, [{
                                attribute: action[0],
                                filter: undefined,
                                value: state.value,
                                attributeInputRef: state.attributeInputRef,
                                operationInputRef: state.operationInputRef,
                                valueInputRef: state.valueInputRef
                              }]);
                case /* SetFilter */2 :
                    return /* Update */Block.__(0, [{
                                attribute: state.attribute,
                                filter: action[0],
                                value: state.value,
                                attributeInputRef: state.attributeInputRef,
                                operationInputRef: state.operationInputRef,
                                valueInputRef: state.valueInputRef
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.OperationTypeahead = OperationTypeahead;
exports.setRef = setRef;
exports.component = component;
exports.Style = Style;
exports.make = make;
/* OperationTypeahead Not a pure module */
