// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SSAAction$BsConsole = require("./SSAAction.js");

function SSAStatDisplay$Overall(Props) {
  var action = Props.action;
  var stats = SSAAction$BsConsole.getStat(action);
  var tmp;
  if (stats !== undefined) {
    var matchedStr = String(stats.matched);
    var mismatchedStr = String(stats.mismatched);
    var condEvalFailedStr = String(stats.conditionEvaluationFailed);
    var stats$1 = [
      /* tuple */[
        SSAUtils$BsConsole.matchedText,
        matchedStr
      ],
      /* tuple */[
        SSAUtils$BsConsole.mismatchedText,
        mismatchedStr
      ],
      /* tuple */[
        SSAUtils$BsConsole.condEvalFailText,
        condEvalFailedStr
      ]
    ];
    tmp = Belt_Array.map(stats$1, (function (param) {
            var str = param[0];
            return React.createElement(Row2$BsConsole.make, {
                        children: null,
                        key: str
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginRight(Css.px(4)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.showSkip(str + ":")), React.createElement("div", undefined, I18N$BsConsole.showSkip(param[1])));
          }));
  } else {
    tmp = I18N$BsConsole.showSkip(SSAUtils$BsConsole.noStatsForActionText);
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(12)),
                    /* :: */[
                      Css.marginLeft(Css.px(48)),
                      /* :: */[
                        Css.marginBottom(Css.px(24)),
                        /* :: */[
                          Css.fontStyle(/* italic */107228912),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: tmp
            });
}

var Overall = {
  make: SSAStatDisplay$Overall
};

function SSAStatDisplay(Props) {
  var action = Props.action;
  var stats = SSAAction$BsConsole.getStat(action);
  var tmp;
  if (stats !== undefined) {
    var matchedStr = String(stats.matched);
    var mismatchedStr = String(stats.mismatched);
    var condEvalFailedStr = String(stats.conditionEvaluationFailed);
    var stats$1 = [
      /* tuple */[
        SSAUtils$BsConsole.matchedText,
        matchedStr
      ],
      /* tuple */[
        SSAUtils$BsConsole.mismatchedText,
        mismatchedStr
      ],
      /* tuple */[
        SSAUtils$BsConsole.condEvalFailText,
        condEvalFailedStr
      ]
    ];
    tmp = Belt_Array.map(stats$1, (function (param) {
            var str = param[0];
            return React.createElement(Row2$BsConsole.make, {
                        children: null,
                        key: str
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginRight(Css.px(4)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.showSkip(str + ":")), React.createElement("div", undefined, I18N$BsConsole.showSkip(param[1])));
          }));
  } else {
    tmp = I18N$BsConsole.showSkip(SSAUtils$BsConsole.noStatsForActionText);
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(12)),
                    /* :: */[
                      Css.marginLeft(Css.px(48)),
                      /* :: */[
                        Css.marginBottom(Css.px(24)),
                        /* :: */[
                          Css.fontStyle(/* italic */107228912),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: tmp
            });
}

var make = SSAStatDisplay;

exports.Overall = Overall;
exports.make = make;
/* Css Not a pure module */
