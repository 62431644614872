// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Image$BsConsole = require("./components/Image.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SauceColors$BsConsole = require("./SauceColors.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

var parent = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(Css.vh(100)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.flexDirection(/* column */-963948842),
                /* :: */[
                  Css.paddingBottom(Css.pct(20)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var topElement = Css.style(/* :: */[
      Css.width(Css.px(475)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var notFoundImg = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var bottomElement = Css.style(/* :: */[
      Css.width(Css.px(475)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.margin4(Css.px(-15), Css.px(0), Css.px(0), Css.px(25)),
            /* [] */0
          ]
        ]
      ]
    ]);

var genericBottomElement = Css.style(/* :: */[
      Css.width(Css.px(475)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.margin4(Css.px(-15), Css.px(0), Css.px(0), Css.px(25)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var genericHeader = Css.style(/* :: */[
      Css.fontSize(Css.px(36)),
      /* :: */[
        Css.color(Css.hex(SauceColors$BsConsole.green)),
        /* :: */[
          Css.margin4(Css.px(15), Css.px(0), Css.px(0), Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var genericMessage = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.marginTop(Css.px(12)),
        /* :: */[
          Css.textAlign(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var bottomLeft = Css.style(/* :: */[
      Css.width(Css.pct(35)),
      /* [] */0
    ]);

var bottomRight = Css.style(/* :: */[
      Css.width(Css.pct(65)),
      /* :: */[
        Css.margin4(Css.px(0), Css.px(10), Css.px(0), Css.px(20)),
        /* [] */0
      ]
    ]);

var h1 = Css.style(/* :: */[
      Css.fontSize(Css.px(96)),
      /* :: */[
        Css.color(Css.hex(SauceColors$BsConsole.green)),
        /* :: */[
          Css.margin(Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var pageNotFound = Css.style(/* :: */[
      Css.fontSize(Css.px(30)),
      /* :: */[
        Css.color(Css.hex(SauceColors$BsConsole.green)),
        /* :: */[
          Css.margin4(Css.px(15), Css.px(0), Css.px(0), Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var p = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.marginTop(Css.px(12)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var Styles = {
  parent: parent,
  topElement: topElement,
  notFoundImg: notFoundImg,
  bottomElement: bottomElement,
  genericBottomElement: genericBottomElement,
  genericHeader: genericHeader,
  genericMessage: genericMessage,
  bottomLeft: bottomLeft,
  bottomRight: bottomRight,
  h1: h1,
  pageNotFound: pageNotFound,
  p: p,
  logo: logo
};

function BacktraceErrorPage(Props) {
  var errorCode = Props.errorCode;
  var errorMessage = Props.errorMessage;
  var errorSupportTextCallback = Props.errorSupportTextCallback;
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: parent
                }, React.createElement("div", {
                      className: topElement
                    }, ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([])), ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(notFoundImg, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1655476304/Error404.svg", "Error 404", undefined)([]))), errorCode !== undefined ? React.createElement("div", {
                        className: bottomElement
                      }, React.createElement("div", {
                            className: bottomLeft
                          }, React.createElement("h1", {
                                className: h1
                              }, Caml_option.valFromOption(errorCode))), React.createElement("div", {
                            className: bottomRight
                          }, React.createElement("h3", {
                                className: pageNotFound
                              }, errorMessage), React.createElement("p", {
                                className: p
                              }, Curry._1(errorSupportTextCallback, palette.primary.main)))) : React.createElement("div", {
                        className: genericBottomElement
                      }, React.createElement("h3", {
                            className: genericHeader
                          }, errorMessage), React.createElement("p", {
                            className: genericMessage
                          }, Curry._1(errorSupportTextCallback, palette.primary.main)))));
}

var component = ReasonReact.statelessComponent("BacktraceNotFound");

function make(errorCode, errorMessage, errorSupportTextCallback, children) {
  return ReasonReactCompat.wrapReactForReasonReact(BacktraceErrorPage, {
              errorCode: errorCode,
              errorMessage: errorMessage,
              errorSupportTextCallback: errorSupportTextCallback
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var RD;

var make$1 = BacktraceErrorPage;

exports.RD = RD;
exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* parent Not a pure module */
