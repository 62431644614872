// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Bpg = require("backtrace-morgue/dist/lib/bpg");

function signUp(email, username, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/signup"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "email",
                                  email
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "username",
                                    username
                                  ],
                                  /* [] */0
                                ]
                              ])
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                return /* Ok */Block.__(0, [undefined]);
              }), undefined, undefined);
}

function activate(token, password, param) {
  var form = Json_encode.object_(Belt_List.concat(/* :: */[
            /* tuple */[
              "token",
              token
            ],
            /* [] */0
          ], password !== undefined ? /* :: */[
              /* tuple */[
                "password",
                password
              ],
              /* [] */0
            ] : /* [] */0));
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/signup"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "verify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          form
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("status", Json_decode.string, param.json)]);
              }), undefined, undefined);
}

function invite(universeId, email, username, method__, role, param) {
  return BpgTask$BsConsole.SignupPending.create({
              universe: universeId,
              email: email,
              username: username,
              method_: method__,
              metadata: undefined,
              role: role,
              __state: undefined,
              __create: undefined,
              __modify: undefined
            });
}

function changePassword(user, password, param) {
  return BpgTask$BsConsole.Users.updateFields(user, [/* tuple */[
                "password",
                Bpg.blobText(password)
              ]]);
}

function resetPassword(universeId, username, password, param) {
  return BpgTask$BsConsole.Users.updateFieldsByKey({
              username: username,
              universe: universeId
            }, [/* tuple */[
                "password",
                password
              ]]);
}

function changeEmail(user, email, param) {
  return BpgTask$BsConsole.Users.updateFields(user, [/* tuple */[
                "email",
                email
              ]]);
}

function resendInvite(username, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/signup"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "resend"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "username",
                                  username
                                ],
                                /* [] */0
                              ])
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, (function (param) {
                return /* Ok */Block.__(0, [param.json]);
              }), undefined, undefined);
}

exports.signUp = signUp;
exports.activate = activate;
exports.invite = invite;
exports.changePassword = changePassword;
exports.resetPassword = resetPassword;
exports.changeEmail = changeEmail;
exports.resendInvite = resendInvite;
/* Task2-BsConsole Not a pure module */
