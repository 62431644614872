// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("./Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var FormatUtils$BsConsole = require("./FormatUtils.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var CrdbPaginationTask$BsConsole = require("./CrdbPaginationTask.js");

var versionRegex = Belt_List.map(/* :: */[
      "version",
      /* :: */[
        "release",
        /* :: */[
          "build",
          /* [] */0
        ]
      ]
    ], (function (r) {
        return new RegExp(r, "i");
      }));

var versionFormats = /* :: */[
  "semver",
  /* [] */0
];

function hasFormat(attribute, formats) {
  return Belt_Option.isSome(Belt_List.getBy(formats, (function (v) {
                    return attribute.format === v;
                  })));
}

function hasRegexMatch(attribute, regexs) {
  if (Belt_Option.isSome(Belt_List.getBy(regexs, (function (v) {
                return v.test(attribute.name);
              })))) {
    return hasFormat(attribute, /* :: */[
                "none",
                versionFormats
              ]);
  } else {
    return false;
  }
}

function getGreatestAttributeValues(crdbSuccess) {
  var rows = crdbSuccess[1];
  var columns = crdbSuccess[0];
  var stringVersionSorter = function (a, b) {
    var match = Semver.valid(a);
    var match$1 = Semver.valid(b);
    if (match !== undefined && match$1 !== undefined && match !== match$1) {
      if (Semver.gte(match, match$1)) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  };
  var result = Belt_List.map(columns, (function (column) {
          return /* tuple */[
                  column.name,
                  Belt_List.get(Belt_List.sort(Belt_List.filter(Belt_List.map(rows, (function (group) {
                                      var getter = function (param, param$1) {
                                        return Group$BsConsole.getAggregation(columns, group, param, param$1);
                                      };
                                      var distribution = Group$BsConsole.unwrapDistribution(getter(column.name, /* Distribution */1));
                                      if (distribution === undefined) {
                                        return ;
                                      }
                                      var maxVersion = Belt_List.get(Belt_List.sort(Belt_List.filter(Belt_List.map(distribution.vals, (function (param) {
                                                          return /* tuple */[
                                                                  FormatUtils$BsConsole.formatToString(param[0]),
                                                                  param[1]
                                                                ];
                                                        })), (function (param) {
                                                      var name = param[0];
                                                      if (name !== "*") {
                                                        return Belt_Option.isSome(Semver.valid(name));
                                                      } else {
                                                        return false;
                                                      }
                                                    })), (function (param, param$1) {
                                                  return stringVersionSorter(param[0], param$1[0]);
                                                })), 0);
                                      if (maxVersion !== undefined) {
                                        return maxVersion[0];
                                      }
                                      
                                    })), Belt_Option.isSome), (function (a, b) {
                              if (a !== undefined && b !== undefined) {
                                return stringVersionSorter(a, b);
                              } else {
                                return -1;
                              }
                            })), 0)
                ];
        }));
  return Belt_List.map(result, (function (param) {
                var version = param[1];
                var unwrappedVersion = version !== undefined ? Belt_Option.getWithDefault(Caml_option.valFromOption(version), "") : "";
                return /* tuple */[
                        param[0],
                        unwrappedVersion
                      ];
              }));
}

function getVersionAttributes(attributes) {
  return Belt_List.filter(attributes, (function (attribute) {
                if (hasRegexMatch(attribute, versionRegex)) {
                  return true;
                } else {
                  return hasFormat(attribute, versionFormats);
                }
              }));
}

function makeTask(attributes, aperture, sort, projectName) {
  var fold = Belt_List.reduce(attributes, Crdb$BsConsole.Fold.empty, (function (agg, cur) {
          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                          cur.name,
                          /* Distribution */Block.__(1, [50])
                        ]), agg);
        }));
  var query_001 = /* Identity */0;
  var query = /* Aggregate */Block.__(0, [
      aperture,
      query_001,
      fold,
      sort,
      undefined,
      undefined
    ]);
  return CrdbPaginationTask$BsConsole.makeTask(query, projectName, (function (resp) {
                return CrdbPaginationTask$BsConsole.Aggregate.decodeResp(/* Identity */0, resp);
              }), 0, undefined);
}

function use(projectName, aperture, token, sortOpt, param) {
  var sort = sortOpt !== undefined ? Caml_option.valFromOption(sortOpt) : undefined;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setAttributesRemotes = match[1];
  var attributesRemotes = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setRequestedAttributes = match$1[1];
  var requestedAttributes = match$1[0];
  var match$2 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setHasBuiltAttributesRemotes = match$3[1];
  var setAttributeRemote = function (attribute, remote) {
    return Curry._1(setAttributesRemotes, (function (attributesRemotes) {
                  return Belt_List.map(attributesRemotes, (function (param) {
                                var att = param[0];
                                if (att.name === attribute) {
                                  return /* tuple */[
                                          att,
                                          remote
                                        ];
                                } else {
                                  return /* tuple */[
                                          att,
                                          param[1]
                                        ];
                                }
                              }));
                }));
  };
  var fetchAttribute = function (name) {
    return Curry._1(setRequestedAttributes, (function (s) {
                  return /* :: */[
                          name,
                          s
                        ];
                }));
  };
  var fetchAllRequested = function (param) {
    return Curry._1(setAttributesRemotes, (function (attributesRemotes) {
                  return Belt_List.map(attributesRemotes, (function (param) {
                                var ret = param[1];
                                var att = param[0];
                                if (Belt_Option.isSome(Belt_List.getBy(requestedAttributes, (function (n) {
                                              return n === att.name;
                                            }))) && typeof ret === "number" && ret === 0) {
                                  return /* tuple */[
                                          att,
                                          /* Loading */1
                                        ];
                                } else {
                                  return /* tuple */[
                                          att,
                                          ret
                                        ];
                                }
                              }));
                }));
  };
  React.useEffect((function () {
          fetchAllRequested(undefined);
          
        }), [requestedAttributes]);
  React.useEffect((function () {
          if (typeof describeRemote !== "number" && !describeRemote.tag) {
            var attributes = describeRemote[0];
            Curry._1(setAttributesRemotes, (function (param) {
                    var nextAttributesRemotes = Belt_List.map(getVersionAttributes(attributes), (function (attribute) {
                            return /* tuple */[
                                    attribute,
                                    /* NotAsked */0
                                  ];
                          }));
                    Curry._1(setHasBuiltAttributesRemotes, (function (param) {
                            return true;
                          }));
                    return nextAttributesRemotes;
                  }));
            fetchAllRequested(undefined);
          }
          
        }), [describeRemote]);
  React.useEffect((function () {
          var nextAttributes = Belt_List.filter(attributesRemotes, (function (param) {
                  return param[1] === /* Loading */1;
                }));
          if (Belt_List.length(nextAttributes) > 0) {
            var aggTask = makeTask(Belt_List.map(nextAttributes, (function (param) {
                        return param[0];
                      })), aperture, sort, projectName);
            Task2$BsConsole.handle(token, aggTask, undefined, (function (param) {
                    return CrdbPaginationTask$BsConsole.responseCb((function (resp) {
                                  var attributeGreatestVersions = getGreatestAttributeValues(resp);
                                  return Belt_List.forEach(attributeGreatestVersions, (function (param) {
                                                var name = param[0];
                                                if (Belt_Option.isSome(Belt_List.getBy(nextAttributes, (function (param) {
                                                              return param[0].name === name;
                                                            })))) {
                                                  return setAttributeRemote(name, /* Success */Block.__(0, [param[1]]));
                                                }
                                                
                                              }));
                                }), (function (err) {
                                  return Belt_List.forEach(nextAttributes, (function (param) {
                                                return setAttributeRemote(param[0].name, /* Failure */Block.__(1, [err]));
                                              }));
                                }), param);
                  }));
          }
          
        }), [attributesRemotes]);
  var tmp;
  var exit = 0;
  if (match$3[0]) {
    if (typeof describeRemote === "number" || describeRemote.tag) {
      exit = 1;
    } else {
      tmp = /* Success */Block.__(0, [attributesRemotes]);
    }
  } else if (typeof describeRemote === "number" || describeRemote.tag) {
    exit = 1;
  } else {
    tmp = /* Loading */1;
  }
  if (exit === 1) {
    tmp = typeof describeRemote === "number" ? (
        describeRemote !== 0 ? /* Loading */1 : /* NotAsked */0
      ) : /* Failure */Block.__(1, [describeRemote[0]]);
  }
  return /* tuple */[
          tmp,
          fetchAttribute
        ];
}

function GetGreatestVersion(Props) {
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var token = Props.token;
  var sortOpt = Props.sort;
  var children = Props.children;
  var sort = sortOpt !== undefined ? Caml_option.valFromOption(sortOpt) : undefined;
  var partial_arg = Caml_option.some(sort);
  return Curry._1(children, (function (param) {
                return use(projectName, aperture, token, partial_arg, param);
              }));
}

function make(projectName, aperture, token, sortOpt, children) {
  var sort = sortOpt !== undefined ? Caml_option.valFromOption(sortOpt) : undefined;
  return ReasonReactCompat.wrapReactForReasonReact(GetGreatestVersion, {
              projectName: projectName,
              aperture: aperture,
              token: token,
              sort: sort,
              children: Curry.__1(children)
            }, children);
}

var Re = {
  make: make
};

var make$1 = GetGreatestVersion;

exports.versionRegex = versionRegex;
exports.versionFormats = versionFormats;
exports.hasFormat = hasFormat;
exports.hasRegexMatch = hasRegexMatch;
exports.getGreatestAttributeValues = getGreatestAttributeValues;
exports.getVersionAttributes = getVersionAttributes;
exports.makeTask = makeTask;
exports.use = use;
exports.make = make$1;
exports.Re = Re;
/* versionRegex Not a pure module */
