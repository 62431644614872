// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

function make(children) {
  return ReasonReact.wrapJsForReason(React.Fragment, { }, children);
}

var Jsx3 = { };

exports.make = make;
exports.Jsx3 = Jsx3;
/* react Not a pure module */
