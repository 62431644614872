// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function fromJson(json) {
  return /* Ok */Block.__(0, [{
              url: Json_decode.field("url", Json_decode.string, json)
            }]);
}

exports.fromJson = fromJson;
/* No side effect */
