// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Printexc = require("bs-platform/lib/js/printexc.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var UnityErrorPage$BsConsole = require("./UnityErrorPage.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var BacktraceErrorPage$BsConsole = require("./BacktraceErrorPage.js");

function getOrThrow(optional, message) {
  if (optional !== undefined) {
    return Caml_option.valFromOption(optional);
  } else {
    return Js_exn.raiseError(message);
  }
}

function castToJsExn(exn) {
  var err = Caml_js_exceptions.caml_as_js_exn(exn);
  if (err !== undefined) {
    return Caml_option.valFromOption(err);
  } else {
    return new Error(Printexc.to_string(exn));
  }
}

function jsExnToString(exn) {
  var orMissing = function (exn, fieldName) {
    return Belt_Option.getWithDefault(exn, "MISSING " + (String(fieldName) + ""));
  };
  var stack = orMissing(exn.stack, "STACK");
  var msg = orMissing(exn.message, "MESSAGE");
  var name = orMissing(exn.name, "NAME");
  return "\n    :: EXCEPTION OCCURRED ::\n    name: " + (String(name) + ("\n    message:\n    " + (String(msg) + ("\n\n    stack:\n    " + (String(stack) + "\n  ")))));
}

function report(error, componentStack) {
  var frame = JSON.stringify(Js_dict.fromList(/* :: */[
            /* tuple */[
              "frame",
              componentStack.split("in").slice(1)
            ],
            /* [] */0
          ]));
  Backtrace$BsConsole.Client.memorize("react.componentStack", componentStack);
  Backtrace$BsConsole.Client.memorize("componentStack", frame);
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Uncaught/report", Backtrace$BsConsole.Attributes.make(undefined));
  Backtrace$BsConsole.Client.send(__x, /* `exn */[
        5049499,
        error
      ]);
  
}

function Uncaught(Props) {
  var errorSupportTextCallback = function (primaryMain) {
    var homeLink = Css.style(/* :: */[
          Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* [] */0
          ]
        ]);
    return React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Something has gone wrong! This error has been reported to Backtrace and we are working on it. Take me "), React.createElement("span", {
                    className: homeLink,
                    onClick: (function (param) {
                        return ReasonReactRouter.push("/");
                      })
                  }, I18N$BsConsole.show(undefined, "home")), I18N$BsConsole.show(undefined, "."));
  };
  if (IdentityVariant$BsConsole.current >= 2) {
    return React.createElement(UnityErrorPage$BsConsole.make, {
                errorMessage: I18N$BsConsole.show(undefined, "Unexpected error"),
                errorSupportText: I18N$BsConsole.show(undefined, "Something has gone wrong! This error has been reported and we are working on it.")
              });
  } else {
    return React.createElement(BacktraceErrorPage$BsConsole.make, {
                errorMessage: I18N$BsConsole.show(undefined, "Unexpected Error"),
                errorSupportTextCallback: errorSupportTextCallback
              });
  }
}

var component = ReasonReact.statelessComponent("Uncaught");

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(Uncaught, { }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var RD;

var RR;

var make$1 = Uncaught;

exports.RD = RD;
exports.RR = RR;
exports.getOrThrow = getOrThrow;
exports.castToJsExn = castToJsExn;
exports.jsExnToString = jsExnToString;
exports.report = report;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
