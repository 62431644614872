// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(40)),
      /* [] */0
    ]);

var Styles = {
  bottomMargin: bottomMargin
};

function Wf2AdvancedOptions(Props) {
  var isOpen = Props.isOpen;
  var handleToggle = Props.handleToggle;
  var stylesOpt = Props.styles;
  var children = Props.children;
  var styles = stylesOpt !== undefined ? stylesOpt : "";
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Colors$BsConsole.deoctothorpe(palette.primary.main);
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.color(Css.hex(primaryMain)),
                              /* [] */0
                            ]),
                        /* :: */[
                          bottomMargin,
                          /* :: */[
                            styles,
                            /* [] */0
                          ]
                        ]
                      ]),
                  onClick: (function (_e) {
                      return Curry._1(handleToggle, undefined);
                    }),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(primaryMain)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    500
                                  ]),
                              /* :: */[
                                Css.fontSize(Css.rem(0.875)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Advanced options")
                    }), isOpen ? React.createElement(KeyboardArrowUp.default, { }) : React.createElement(KeyboardArrowDown.default, { })), isOpen ? children : null);
}

var make = Wf2AdvancedOptions;

exports.Styles = Styles;
exports.make = make;
/* bottomMargin Not a pure module */
