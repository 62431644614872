// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

function crdb(query, projectName, decodeResp, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [Crdb$BsConsole.QueryManager.toCrdb(query)]), undefined, decodeResp, undefined, undefined);
}

var ParseError = Caml_exceptions.create("Tasks-BsConsole.ParseError");

var DeleteError = Caml_exceptions.create("Tasks-BsConsole.DeleteError");

function deleteObject(projectName, tx, subsetOpt, param) {
  var subset = subsetOpt !== undefined ? Caml_option.valFromOption(subsetOpt) : undefined;
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/delete"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "project",
                        projectName
                      ],
                      /* :: */[
                        /* tuple */[
                          "objects",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), tx)
                        ],
                        /* :: */[
                          subset !== undefined ? (
                              subset ? /* tuple */[
                                  "subsets",
                                  Json_encode.array((function (prim) {
                                          return prim;
                                        }), ["crdb"])
                                ] : /* tuple */[
                                  "subsets",
                                  Json_encode.array((function (prim) {
                                          return prim;
                                        }), ["physical"])
                                ]
                            ) : /* tuple */[
                              "subset",
                              Json_encode.array((function (prim) {
                                      return prim;
                                    }), ["*"])
                            ],
                          /* [] */0
                        ]
                      ]
                    ])]), undefined, (function (param) {
                var json = param.json;
                try {
                  var msg = Json_decode.field("response", Json_decode.string, json);
                  if (msg === "ok") {
                    return /* Ok */Block.__(0, [json]);
                  } else {
                    return /* Error */Block.__(1, [[
                                DeleteError,
                                msg
                              ]]);
                  }
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Tasks/deleteObject", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

exports.crdb = crdb;
exports.ParseError = ParseError;
exports.DeleteError = DeleteError;
exports.deleteObject = deleteObject;
/* Crdb-BsConsole Not a pure module */
