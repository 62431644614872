// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var ReactBeautifulDnd = require("react-beautiful-dnd");
var BtSettings$BsConsole = require("../BtSettings.js");
var InboxUtils$BsConsole = require("../inbox/InboxUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Close = require("@material-ui/icons/Close");
var BtAutocomplete$BsConsole = require("../BtAutocomplete.js");
var TextField = require("@material-ui/core/TextField");
var AttributeTypeahead$BsConsole = require("../typeahead/AttributeTypeahead.js");
var FormHelperText = require("@material-ui/core/FormHelperText");

function Wf2MainBodyConent$CloseButton(Props) {
  var handleClick = Props.handleClick;
  var classNameOpt = Props.className;
  var ariaLabel = Props.ariaLabel;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Mui$BsConsole.IconButton.make, {
              ariaLabel: ariaLabel,
              size: "small",
              className: Css.merge(/* :: */[
                    className,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.red)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              onClick: (function (_e) {
                  return Curry._1(handleClick, undefined);
                }),
              children: React.createElement(Close.default, {
                    fontSize: "small"
                  })
            });
}

var CloseButton = {
  make: Wf2MainBodyConent$CloseButton
};

function Wf2MainBodyConent$AttributeAutocomplete(Props) {
  var attributes = Props.attributes;
  var selectedAttributes = Props.selectedAttributes;
  var handleAddSelectedAttribute = Props.handleAddSelectedAttribute;
  return React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (attribute$prime) {
                  return attribute$prime.name;
                }),
              considerations: Belt_Array.keep(Belt_List.toArray(attributes), (function (attribute) {
                      return !Belt_Array.some(selectedAttributes, (function (selected) {
                                    return selected === attribute.name;
                                  }));
                    })),
              onChange: (function (_raw, attribute$prime) {
                  return Curry._1(handleAddSelectedAttribute, attribute$prime);
                }),
              defaultValue: "",
              placeholder: I18N$BsConsole.get(undefined, "Add attribute"),
              autoFocus: false,
              inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
              renderListOption: (function (attr, idx) {
                  return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                              name: attr.name,
                              description: attr.description,
                              custom: attr.custom,
                              triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                              used: attr.statistics.used,
                              key: attr.name + ("__" + String(idx))
                            });
                }),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  var onFocus$prime = Curry.__1(onFocus);
                  var onChange$prime = Curry.__1(onChange);
                  return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                              onFocus: onFocus$prime,
                              className: Css.style(/* :: */[
                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                    /* :: */[
                                      Css.unsafe("width", "calc(100% - 28px - 49px)"),
                                      /* :: */[
                                        Css.marginLeft(Css.px(28)),
                                        /* :: */[
                                          Css.marginRight(Css.px(49)),
                                          /* :: */[
                                            Css.boxSizing(/* borderBox */9307263),
                                            /* :: */[
                                              Css.selector("input", /* :: */[
                                                    Css.important(Css.padding(Css.px(10))),
                                                    /* [] */0
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              placeholder: param.placeholder,
                              value: param.value,
                              onChange: onChange$prime,
                              variant: "outlined"
                            });
                }),
              clearOnChange: true
            });
}

var AttributeAutocomplete = {
  make: Wf2MainBodyConent$AttributeAutocomplete
};

function Wf2MainBodyConent$MainBodyContentRow(Props) {
  var attributes = Props.attributes;
  var initialValue = Props.initialValue;
  var dragHandleProps = Props.dragHandleProps;
  var handleMouseEnter = Props.handleMouseEnter;
  var handleDeleteSelectedAttribute = Props.handleDeleteSelectedAttribute;
  var isAttributeValid = initialValue === "" ? true : Belt_List.some(attributes, (function (attribute) {
            return attribute.name === initialValue;
          }));
  return React.cloneElement(React.createElement("div", undefined, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.marginBottom(Css.px(10)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.flex3(0, 1, /* zero */-789508312),
                                /* :: */[
                                  Css.fontSize(Css.rem(1.5)),
                                  /* :: */[
                                    Css.marginRight(Css.px(15)),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          onMouseEnter: (function (param) {
                              return Curry._1(handleMouseEnter, undefined);
                            })
                        }, I18N$BsConsole.showSkip("=")), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.flex3(15, 1, /* zero */-789508312),
                                /* [] */0
                              ])
                        }, React.createElement(TextField.default, {
                              disabled: true,
                              placeholder: "",
                              value: initialValue,
                              variant: "outlined",
                              className: Css.style(/* :: */[
                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                    /* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.selector("input", /* :: */[
                                              Css.important(Css.padding(Css.px(10))),
                                              /* :: */[
                                                Css.important(Css.color(Css.hex(Colors$BsConsole.black))),
                                                /* [] */0
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            })), React.createElement(Wf2MainBodyConent$CloseButton, {
                          handleClick: (function (param) {
                              return Curry._1(handleDeleteSelectedAttribute, undefined);
                            }),
                          className: Css.style(/* :: */[
                                Css.flex3(0, 1, /* zero */-789508312),
                                /* :: */[
                                  Css.fontSize(Css.rem(1.5)),
                                  /* :: */[
                                    Css.marginLeft(Css.px(5)),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          ariaLabel: "delete main body content row"
                        })), isAttributeValid ? null : React.createElement(FormHelperText.default, {
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.px(28)),
                              /* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* [] */0
                              ]
                            ]),
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Invalid attribute: " + (initialValue + ". Delete attribute to continue."))
                      })), dragHandleProps);
}

var MainBodyContentRow = {
  make: Wf2MainBodyConent$MainBodyContentRow
};

function Wf2MainBodyConent(Props) {
  var attributes = Props.attributes;
  var handleAddSelectedAttribute = Props.handleAddSelectedAttribute;
  var handleDeleteSelectedAttribute = Props.handleDeleteSelectedAttribute;
  var classNameOpt = Props.className;
  var selectedAttributes = Props.selectedAttributes;
  var handleUpdateAttributeOrder = Props.handleUpdateAttributeOrder;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return false;
        }));
  var setIsHovered = match[1];
  var isHovered = match[0];
  return React.createElement("div", {
              className: className
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(0)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Main body content")
                }), React.createElement(FormHelperText.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Choose what attributes get appended to the main body text. If omitted, all will be appended.")
                }), React.createElement(ReactBeautifulDnd.DragDropContext, {
                  onDragEnd: (function (e) {
                      if (isHovered) {
                        var from = e.source.index;
                        var to_ = e.destination.index;
                        var otherItems = Belt_Array.concat(Belt_Array.slice(selectedAttributes, 0, from), Belt_Array.slice(selectedAttributes, from + 1 | 0, selectedAttributes.length));
                        return Curry._1(handleUpdateAttributeOrder, Belt_Array.concat(Belt_Array.concat(Belt_Array.slice(otherItems, 0, to_), [Belt_Array.getExn(selectedAttributes, from)]), Belt_Array.slice(otherItems, to_, selectedAttributes.length)));
                      }
                      
                    }),
                  children: React.createElement(ReactBeautifulDnd.Droppable, {
                        droppableId: "droppable-attribute-list",
                        children: (function (provided, param) {
                            return React.createElement("div", {
                                        ref: provided.innerRef
                                      }, Belt_Array.mapWithIndex(selectedAttributes, (function (index, attribute) {
                                              return React.createElement(ReactBeautifulDnd.Draggable, {
                                                          draggableId: String(index),
                                                          index: index,
                                                          isDragDisabled: !isHovered,
                                                          children: (function (provided, param) {
                                                              return React.cloneElement(React.createElement("div", {
                                                                              ref: provided.innerRef
                                                                            }, React.createElement(Wf2MainBodyConent$MainBodyContentRow, {
                                                                                  attributes: attributes,
                                                                                  initialValue: attribute,
                                                                                  dragHandleProps: provided.dragHandleProps,
                                                                                  handleMouseEnter: (function (param) {
                                                                                      return Curry._1(setIsHovered, (function (param) {
                                                                                                    return true;
                                                                                                  }));
                                                                                    }),
                                                                                  handleDeleteSelectedAttribute: (function (param) {
                                                                                      return Curry._1(handleDeleteSelectedAttribute, index);
                                                                                    }),
                                                                                  key: attribute + String(index)
                                                                                })), provided.draggableProps);
                                                            }),
                                                          key: String(index)
                                                        });
                                            })), provided.placeholder);
                          })
                      })
                }), React.createElement(Wf2MainBodyConent$AttributeAutocomplete, {
                  attributes: attributes,
                  selectedAttributes: selectedAttributes,
                  handleAddSelectedAttribute: Curry.__1(handleAddSelectedAttribute)
                }));
}

var make = Wf2MainBodyConent;

exports.CloseButton = CloseButton;
exports.AttributeAutocomplete = AttributeAutocomplete;
exports.MainBodyContentRow = MainBodyContentRow;
exports.make = make;
/* Css Not a pure module */
