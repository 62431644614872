// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");

var monospaceFont = Css.fontFamily("Inconsolata");

var Color = {
  dividerPrimary: Colors$BsConsole.grey5,
  dividerSecondary: Colors$BsConsole.grey6,
  dividerTertiary: Colors$BsConsole.grey65
};

exports.monospaceFont = monospaceFont;
exports.Color = Color;
/* monospaceFont Not a pure module */
