// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");

var preStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.fontFamily("Inconsolata"),
        /* :: */[
          Css.padding2(Css.px(4), Css.zero),
          /* :: */[
            Css.marginLeft(Css.px(50)),
            /* [] */0
          ]
        ]
      ]
    ]);

function BugBreadcrumbsRowDetails(Props) {
  var attributes = Props.attributes;
  var attributesFull = Util$BsConsole.formattedStringify(attributes);
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* flexStart */662439529,
              className: preStyle,
              children: React.createElement("pre", {
                    className: Css.style(/* :: */[
                          Css.whiteSpace(/* preWrap */660870029),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.showSkip(attributesFull))
            });
}

var make = BugBreadcrumbsRowDetails;

exports.preStyle = preStyle;
exports.make = make;
/* preStyle Not a pure module */
