// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("./Colors.js");

var frame = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* :: */[
        Css.hover(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var body = Css.style(/* :: */[
      Css.borderRadius(Css.px(4)),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* :: */[
          Css.background(Css.hex(Colors$BsConsole.blackA00)),
          /* :: */[
            Css.hover(/* :: */[
                  Css.background(Css.hex(Colors$BsConsole.blackA08)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Callstack = {
  frame: frame,
  body: body
};

exports.Callstack = Callstack;
/* frame Not a pure module */
