// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var BtSelect$BsConsole = require("./BtSelect.js");
var Select = require("@material-ui/core/Select");
var InputLabel = require("@material-ui/core/InputLabel");

function BtSelectJsx3(Props) {
  var onChange = Props.onChange;
  var value = Props.value;
  var classNameOpt = Props.className;
  var label = Props.label;
  var renderValue = Props.renderValue;
  var open_ = Props.open_;
  var onClose = Props.onClose;
  var onOpen = Props.onOpen;
  var rootClassNameOpt = Props.rootClassName;
  var variantOpt = Props.variant;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var rootClassName = rootClassNameOpt !== undefined ? rootClassNameOpt : "";
  var variant = variantOpt !== undefined ? variantOpt : /* Filled */969114050;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    value: value,
    onChange: onChange,
    disableUnderline: true,
    classes: {
      select: BtSelect$BsConsole.Style.select,
      icon: BtSelect$BsConsole.Style.icon,
      root: Css.merge(/* :: */[
            BtSelect$BsConsole.Style.root(variant),
            /* :: */[
              rootClassName,
              /* :: */[
                "root",
                /* [] */0
              ]
            ]
          ]),
      selectMenu: Css.style(/* :: */[
            Css.maxWidth(Css.px(200)),
            /* [] */0
          ])
    },
    variant: "filled",
    autoWidth: true,
    children: children
  };
  if (open_ !== undefined) {
    tmp.open = Caml_option.valFromOption(open_);
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  if (onOpen !== undefined) {
    tmp.onOpen = Caml_option.valFromOption(onOpen);
  }
  if (renderValue !== undefined) {
    tmp.renderValue = Caml_option.valFromOption(renderValue);
  }
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.position(/* relative */903134412),
                          /* :: */[
                            Css.height(Css.px(40)),
                            /* [] */0
                          ]
                        ]),
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, React.createElement(InputLabel.default, {
                  className: BtSelect$BsConsole.Style.label,
                  children: I18N$BsConsole.showSkip(label)
                }), React.createElement(Select.default, tmp));
}

var make = BtSelectJsx3;

exports.make = make;
/* Css Not a pure module */
