// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var TextField = require("@material-ui/core/TextField");

function getInputValue(e) {
  return e.target.value;
}

function BtSettings$FormSectionTitle(Props) {
  var title = Props.title;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Subtitle2 */7,
              className: className,
              children: I18N$BsConsole.showSkip(title)
            });
}

var FormSectionTitle = {
  make: BtSettings$FormSectionTitle
};

function BtSettings$FormSectionLabel(Props) {
  var label = Props.label;
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Caption */11,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(0.5)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey4)),
                      /* [] */0
                    ]
                  ]),
              children: label
            });
}

var FormSectionLabel = {
  make: BtSettings$FormSectionLabel
};

function BtSettings$FormSectionTextField(Props) {
  var multiline = Props.multiline;
  var rows = Props.rows;
  var rowsMax = Props.rowsMax;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var _type = Props.type;
  var helperText = Props.helperText;
  var error = Props.error;
  var classNameOpt = Props.className;
  var disabledOpt = Props.disabled;
  var placeholder = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var inputProps = Props.inputProps;
  var inputRef = Props.inputRef;
  var autoFocusOpt = Props.autoFocus;
  var variantOpt = Props.variant;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var variant = variantOpt !== undefined ? variantOpt : "filled";
  var tmp = {
    autoFocus: autoFocus,
    disabled: disabled,
    placeholder: placeholder,
    value: value,
    color: "primary",
    variant: variant,
    classes: {
      root: Css.merge(/* :: */[
            Css.style(/* :: */[
                  Css.minWidth(Css.px(200)),
                  /* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* :: */[
                      Css.selector("input", /* :: */[
                            Css.important(Css.paddingTop(Css.px(10))),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("div[class*='multiline']", /* :: */[
                              Css.important(Css.paddingTop(Css.px(10))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]),
            /* :: */[
              className,
              /* [] */0
            ]
          ])
    },
    onChange: onChange
  };
  if (rows !== undefined) {
    tmp.rows = Caml_option.valFromOption(rows);
  }
  if (rowsMax !== undefined) {
    tmp.rowsMax = Caml_option.valFromOption(rowsMax);
  }
  if (multiline !== undefined) {
    tmp.multiline = Caml_option.valFromOption(multiline);
  }
  if (helperText !== undefined) {
    tmp.helperText = Caml_option.valFromOption(helperText);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  if (_type !== undefined) {
    tmp.type = Caml_option.valFromOption(_type);
  }
  if (inputRef !== undefined) {
    tmp.inputRef = Caml_option.valFromOption(inputRef);
  }
  if (inputProps !== undefined) {
    tmp.inputProps = Caml_option.valFromOption(inputProps);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  return React.createElement(TextField.default, tmp);
}

var FormSectionTextField = {
  make: BtSettings$FormSectionTextField
};

function BtSettings$FormInput(Props) {
  var placeholder = Props.placeholder;
  var title = Props.title;
  var value = Props.value;
  var label = Props.label;
  var onChange = Props.onChange;
  var error = Props.error;
  var helperText = Props.helperText;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var _type = Props.type;
  var rows = Props.rows;
  var rowsMax = Props.rowsMax;
  var multiline = Props.multiline;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: className,
    disabled: disabled,
    placeholder: placeholder,
    value: value,
    onChange: onChange
  };
  if (multiline !== undefined) {
    tmp.multiline = Caml_option.valFromOption(multiline);
  }
  if (rows !== undefined) {
    tmp.rows = Caml_option.valFromOption(rows);
  }
  if (rowsMax !== undefined) {
    tmp.rowsMax = Caml_option.valFromOption(rowsMax);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (_type !== undefined) {
    tmp.type = Caml_option.valFromOption(_type);
  }
  if (helperText !== undefined) {
    tmp.helperText = Caml_option.valFromOption(helperText);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  return React.createElement(React.Fragment, undefined, title !== undefined ? React.createElement(BtSettings$FormSectionTitle, {
                    title: title
                  }) : null, label !== undefined ? React.createElement(BtSettings$FormSectionLabel, {
                    label: Caml_option.valFromOption(label)
                  }) : null, React.createElement(BtSettings$FormSectionTextField, tmp));
}

var FormInput = {
  make: BtSettings$FormInput
};

function BtSettings$Container(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.marginLeft(Css.auto),
                          /* :: */[
                            Css.marginRight(Css.auto),
                            /* :: */[
                              Css.marginBottom(Css.px(75)),
                              /* :: */[
                                Css.minWidth(Css.px(550)),
                                /* :: */[
                                  Css.maxWidth(Css.px(1600)),
                                  /* :: */[
                                    Css.unsafe("width", "calc(100% - 266px)"),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

function make(className, children) {
  var tmp = {
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BtSettings$Container, tmp, children);
}

var Jsx2 = {
  make: make
};

var Container = {
  make: BtSettings$Container,
  Jsx2: Jsx2
};

function BtSettings$InlineHighlight(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: Css.style(/* :: */[
                    Css.fontFamily("Inconsolata"),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.accentDark)),
                        /* :: */[
                          Css.padding2(Css.px(3), Css.em(0.5)),
                          /* :: */[
                            Css.whiteSpace(/* nowrap */867913355),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var InlineHighlight = {
  make: BtSettings$InlineHighlight
};

function BtSettings$FancyDivider(Props) {
  var dividerColorOpt = Props.dividerColor;
  var className = Props.className;
  var dividerColor = dividerColorOpt !== undefined ? dividerColorOpt : Colors$BsConsole.greenOlive;
  return React.createElement("hr", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.width(Css.px(42)),
                          /* :: */[
                            Css.height(Css.px(1)),
                            /* :: */[
                              Css.border(Css.px(1), Css.solid, Css.hex(dividerColor)),
                              /* :: */[
                                Css.backgroundColor(Css.hex(dividerColor)),
                                /* :: */[
                                  Css.margin(Css.px(0)),
                                  /* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(8)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      Belt_Option.mapWithDefault(className, "", Util$BsConsole.identity),
                      /* [] */0
                    ]
                  ])
            });
}

var FancyDivider = {
  make: BtSettings$FancyDivider
};

function BtSettings$RangeSlider(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var min = Props.min;
  var max = Props.max;
  var step = Props.step;
  var className = Props.className;
  var tmp = {
    max: String(max),
    min: String(min),
    step: step,
    type: "range",
    value: value,
    onChange: (function ($$event) {
        return Curry._1(onChange, $$event.target.value);
      })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("input", tmp);
}

var RangeSlider = {
  make: BtSettings$RangeSlider
};

var baseStyle = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

function BtSettings$Paper(Props) {
  var classNameOpt = Props.className;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  return React.createElement(Paper.default, {
              className: Css.merge(/* :: */[
                    baseStyle,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              elevation: 0,
              children: children
            });
}

function make$1(className, children) {
  var tmp = {
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BtSettings$Paper, tmp, children);
}

var Jsx2$1 = {
  make: make$1
};

var Paper$1 = {
  baseStyle: baseStyle,
  make: BtSettings$Paper,
  Jsx2: Jsx2$1
};

exports.getInputValue = getInputValue;
exports.FormSectionTitle = FormSectionTitle;
exports.FormSectionLabel = FormSectionLabel;
exports.FormSectionTextField = FormSectionTextField;
exports.FormInput = FormInput;
exports.Container = Container;
exports.InlineHighlight = InlineHighlight;
exports.FancyDivider = FancyDivider;
exports.RangeSlider = RangeSlider;
exports.Paper = Paper$1;
/* baseStyle Not a pure module */
