// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Bpg$BsConsole = require("./bpg.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Image$BsConsole = require("./components/Image.js");
var Task2$BsConsole = require("./Task2.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var AuthTasks$BsConsole = require("./auth/AuthTasks.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");
var I18N_config$BsConsole = require("./I18N_config.js");
var SystemUsage$BsConsole = require("./SystemUsage.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BpgController$BsConsole = require("./BpgController.js");
var Settings_i18n$BsConsole = require("./Settings_i18n.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");
var CopyToClipboard$BsConsole = require("./CopyToClipboard.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

var PasswordChangeFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.Activate.validators
    });

var ChangeEmailFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.ChangeEmail.validators
    });

var component = ReasonReact.statelessComponent("SettingsMePage-BsConsole__SectionHeader");

function make(label, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(0.5)),
                                  /* [] */0
                                ]), undefined, undefined, undefined, [I18N$BsConsole.showSkip(label)]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var SectionHeader = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("SettingsMePage-BsConsole__EditAuthMethodDialog");

function make$1(onUpdateCurrentUser, token, handleTask, user, onClose, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Modify.make, (function (_result, user) {
                                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* String_literal */Block.__(11, [
                                                              "Successfully updated ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ]),
                                                          "Successfully updated %s"
                                                        ]), user.username));
                                            Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                            return Curry._1(onUpdateCurrentUser, user);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditAuthMethodForm.make, (function (param) {
                                                              var values = param.values;
                                                              return Curry._1(handleSubmit, /* tuple */[
                                                                          user,
                                                                          (function (user) {
                                                                              return {
                                                                                      uid: user.uid,
                                                                                      email: user.email,
                                                                                      username: user.username,
                                                                                      method_: values.method_,
                                                                                      password: user.password,
                                                                                      metadata: user.metadata,
                                                                                      active: user.active,
                                                                                      superuser: user.superuser,
                                                                                      universe: user.universe,
                                                                                      role: user.role,
                                                                                      deleted: user.deleted,
                                                                                      __state: user.__state,
                                                                                      __create: user.__create,
                                                                                      __modify: user.__modify
                                                                                    };
                                                                            }),
                                                                          Form$BsConsole.Fields.set("method", values.method_, Form$BsConsole.Fields.empty),
                                                                          param.setError,
                                                                          param.setSubmitting,
                                                                          param.resetFormState
                                                                        ]);
                                                            }), undefined, undefined, {
                                                            method_: user.method_
                                                          }, /* :: */[
                                                            /* tuple */[
                                                              /* Method */1039100673,
                                                              /* Required */0
                                                            ],
                                                            /* [] */0
                                                          ], undefined, (function (param) {
                                                              var handleSubmit = param.handleSubmit;
                                                              var handleChange = param.handleChange;
                                                              var form = param.form;
                                                              var _msg = state.remote;
                                                              var tmp;
                                                              tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? false : true;
                                                              var msg = state.remote;
                                                              var tmp$1;
                                                              tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg[0])]));
                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit auth method")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit authentication method")])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, tmp, undefined, undefined, [
                                                                                                  ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.method_, (function (_event, value) {
                                                                                                              return Curry._2(handleChange, /* Method */1039100673, value);
                                                                                                            }), undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                      "password",
                                                                                                                      Caml_option.some(I18N$BsConsole.show(undefined, "password")),
                                                                                                                      Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    []
                                                                                                                                  ]))),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      []
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                      "saml",
                                                                                                                      Caml_option.some(I18N$BsConsole.show(undefined, "saml")),
                                                                                                                      Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    undefined,
                                                                                                                                    []
                                                                                                                                  ]))),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      []
                                                                                                                    ]))
                                                                                                          ])),
                                                                                                  tmp$1
                                                                                                ]))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Curry.__1(onClose),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      console.log(form.values);
                                                                                                      console.log(form.errors);
                                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  state.remote === /* Loading */1,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Save")]
                                                                                                ]))
                                                                                      ]))
                                                                            ]));
                                                            })));
                                          })))]
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var EditAuthMethodDialog = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("SettingsMePage-BsConsole__EditPasswordDialog");

function make$2(token, user, onClose, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._3(PasswordChangeFormContainer.make, {
                                          password: "",
                                          passwordConfirmation: ""
                                        }, (function (state, param) {
                                            var notifyOnFailure = param.notifyOnFailure;
                                            var notifyOnSuccess = param.notifyOnSuccess;
                                            var arg = AuthTasks$BsConsole.changePassword(user, state.password, undefined);
                                            return Curry._2((function (param) {
                                                            return (function (param$1, param$2) {
                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                              });
                                                          })(token), undefined, (function (user) {
                                                          if (user.tag) {
                                                            return Curry._2(notifyOnFailure, /* [] */0, undefined);
                                                          }
                                                          Curry._1(notifyOnSuccess, undefined);
                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                        /* String_literal */Block.__(11, [
                                                                            "Successfully updated ",
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ]),
                                                                        "Successfully updated %s"
                                                                      ]), user[0].username));
                                                          return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                        }));
                                          }), (function (form) {
                                            var partial_arg = form.submit;
                                            var match = Curry._1(form.result, /* Password */0);
                                            var tmp;
                                            tmp = match !== undefined && match.tag ? true : false;
                                            var match$1 = Curry._1(form.result, /* Password */0);
                                            var tmp$1;
                                            tmp$1 = match$1 !== undefined && match$1.tag ? match$1[0] : "";
                                            var match$2 = Curry._1(form.result, /* PasswordConfirmation */1);
                                            var tmp$2;
                                            tmp$2 = match$2 !== undefined && match$2.tag ? true : false;
                                            var match$3 = Curry._1(form.result, /* PasswordConfirmation */1);
                                            var tmp$3;
                                            tmp$3 = match$3 !== undefined && match$3.tag ? match$3[0] : "";
                                            return React.createElement("form", {
                                                        onSubmit: (function (param) {
                                                            return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                          })
                                                      }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit password")])),
                                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    true,
                                                                                    (function ($$event) {
                                                                                        return Curry._2(form.change, /* Password */0, Curry._2(AuthForms$BsConsole.Activate.Password.update, form.state, $$event.target.value));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    tmp,
                                                                                    Caml_option.some(I18N$BsConsole.dynamic(tmp$1)),
                                                                                    "password",
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.state.password
                                                                                    ],
                                                                                    undefined,
                                                                                    undefined,
                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "Password")),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ])),
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    true,
                                                                                    (function ($$event) {
                                                                                        return Curry._2(form.change, /* PasswordConfirmation */1, Curry._2(AuthForms$BsConsole.Activate.PasswordConfirmation.update, form.state, $$event.target.value));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    tmp$2,
                                                                                    Caml_option.some(I18N$BsConsole.dynamic(tmp$3)),
                                                                                    "password",
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.state.passwordConfirmation
                                                                                    ],
                                                                                    undefined,
                                                                                    undefined,
                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "Confirm password")),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))
                                                                        ])),
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    (function (_event) {
                                                                                        return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                  ])),
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    "submit",
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Save")]
                                                                                  ]))
                                                                        ]))
                                                              ])));
                                          })))]
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var EditPasswordDialog = {
  component: component$2,
  make: make$2
};

var component$3 = ReasonReact.statelessComponent("SettingsMePage-BsConsole__EditEmailDialog");

function make$3(onUpdateCurrentUser, token, user, onClose, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._3(ChangeEmailFormContainer.make, {
                                          email: user.email
                                        }, (function (state, param) {
                                            var notifyOnFailure = param.notifyOnFailure;
                                            var notifyOnSuccess = param.notifyOnSuccess;
                                            var arg = AuthTasks$BsConsole.changeEmail(user, state.email, undefined);
                                            return Curry._2((function (param) {
                                                            return (function (param$1, param$2) {
                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                              });
                                                          })(token), undefined, (function (user) {
                                                          if (user.tag) {
                                                            var match = user[0];
                                                            if (match[0] === Task2$BsConsole.CoronerError) {
                                                              return Curry._2(notifyOnFailure, /* [] */0, match[1].message);
                                                            } else {
                                                              return Curry._2(notifyOnFailure, /* [] */0, I18N$BsConsole.get(undefined, "Unknown error has occured"));
                                                            }
                                                          }
                                                          var user$1 = user[0];
                                                          Curry._1(onUpdateCurrentUser, user$1);
                                                          Curry._1(notifyOnSuccess, undefined);
                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                        /* String_literal */Block.__(11, [
                                                                            "Successfully updated ",
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ]),
                                                                        "Successfully updated %s"
                                                                      ]), user$1.username));
                                                          return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                        }));
                                          }), (function (form) {
                                            var partial_arg = form.submit;
                                            var match = form.status;
                                            var tmp;
                                            if (typeof match === "number") {
                                              tmp = null;
                                            } else {
                                              var msg = match[1];
                                              tmp = msg !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, /* Nowrap */-397426005, undefined, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, {
                                                                              fontSize: "16px"
                                                                            }, undefined, [I18N$BsConsole.dynamic(msg)]))]))])) : null;
                                            }
                                            var match$1 = Curry._1(form.result, /* Email */0);
                                            var tmp$1;
                                            tmp$1 = match$1 !== undefined && match$1.tag ? true : false;
                                            var match$2 = Curry._1(form.result, /* Email */0);
                                            var tmp$2;
                                            tmp$2 = match$2 !== undefined && match$2.tag ? match$2[0] : "";
                                            return React.createElement("form", {
                                                        onSubmit: (function (param) {
                                                            return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                          })
                                                      }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit email")])),
                                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                          tmp,
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    I18N$BsConsole.get(undefined, "Email"),
                                                                                    true,
                                                                                    (function ($$event) {
                                                                                        return Curry._2(form.change, /* Email */0, Curry._2(AuthForms$BsConsole.ChangeEmail.Email.update, form.state, $$event.target.value));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    tmp$1,
                                                                                    Caml_option.some(I18N$BsConsole.dynamic(tmp$2)),
                                                                                    undefined,
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.state.email
                                                                                    ],
                                                                                    undefined,
                                                                                    true,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))
                                                                        ])),
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    (function (_event) {
                                                                                        return Curry._1(onClose, (new Event('close')));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                  ])),
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    "submit",
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Save")]
                                                                                  ]))
                                                                        ]))
                                                              ])));
                                          })))]
                            ]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var EditEmailDialog = {
  component: component$3,
  make: make$3
};

var component$4 = ReasonReact.statelessComponent("SettingListItem");

function make$4(label, value, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, Css.style(/* :: */[
                                  Css.justifyContent(/* spaceBetween */516682146),
                                  /* [] */0
                                ]), undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(value), Caml_option.some(label), [])),
                              ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                            ]));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var SettingListItem = {
  component: component$4,
  make: make$4
};

var listItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* [] */0
    ]);

var clickableListItem = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

var morgueLogin = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var morgueLoginInput = Css.style(/* :: */[
      Css.unsafe("border", "unset"),
      /* :: */[
        Css.width(Css.rem(15.5)),
        /* [] */0
      ]
    ]);

var visibilityButtom = Css.style(/* :: */[
      Css.marginRight(Css.rem(1.5)),
      /* [] */0
    ]);

var Styles = {
  listItem: listItem,
  clickableListItem: clickableListItem,
  morgueLogin: morgueLogin,
  morgueLoginInput: morgueLoginInput,
  visibilityButtom: visibilityButtom
};

var component$5 = ReasonReact.reducerComponent("UserSettings");

function make$5(onUpdateCurrentUser, config, handleChangeUrl, token, handleTask, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (self) {
              var isSauceLabsVariant = IdentityVariant$BsConsole.current === /* SauceLabs */1 && config.user.email !== "security@backtrace.io";
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                switch (match) {
                  case /* AuthMethod */0 :
                      tmp = ReasonReact.element(undefined, undefined, make$1(onUpdateCurrentUser, token, handleTask, config.user, (function (param) {
                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                }), []));
                      break;
                  case /* Locale */1 :
                      tmp = ReasonReact.element(undefined, undefined, Settings_i18n$BsConsole.ChooseLanguageDialog.make(token, config, (function (param) {
                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                }), []));
                      break;
                  case /* Password */2 :
                      tmp = ReasonReact.element(undefined, undefined, make$2(token, config.user, (function (param) {
                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                }), []));
                      break;
                  case /* Email */3 :
                      tmp = ReasonReact.element(undefined, undefined, make$3(onUpdateCurrentUser, token, config.user, (function (param) {
                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                }), []));
                      break;
                  
                }
              } else {
                tmp = null;
              }
              var tmp$1;
              if (isSauceLabsVariant) {
                tmp$1 = null;
              } else {
                var match$1 = Curry._1(Bpg$BsConsole.Helpers.User.getMethod, config.user);
                var match$2 = Curry._1(Bpg$BsConsole.Helpers.User.getMethod, config.user);
                tmp$1 = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem + (" " + clickableListItem), undefined, (function (_event) {
                                return Curry._1(self.send, /* Dialog */[/* AuthMethod */0]);
                              }), undefined, [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Authentication method")), Caml_option.some(match$1 !== undefined ? (
                                              match$1 ? I18N$BsConsole.show(undefined, "password") : I18N$BsConsole.show(undefined, "saml")
                                            ) : (console.log("INVARIANT"), null)), [])),
                              match$2 !== undefined ? (
                                  match$2 ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                              undefined,
                                              undefined,
                                              (function ($$event) {
                                                  $$event.stopPropagation();
                                                  return Curry._1(self.send, /* Dialog */[/* Password */2]);
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              /* Primary */-791844958,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              true,
                                              undefined,
                                              undefined,
                                              [I18N$BsConsole.show(undefined, "Change password")]
                                            ])) : null
                                ) : (console.log("INVARIANT"), null),
                              React.createElement("span", undefined, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))
                            ])), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])));
              }
              var __x = config.user.role;
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, make(I18N$BsConsole.get(undefined, "Account"), [])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1.0)),
                                            /* [] */0
                                          ]), [
                                        tmp,
                                        ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [
                                                  isSauceLabsVariant ? null : React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Username")), Caml_option.some(I18N$BsConsole.dynamic(config.user.username)), []))])), ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, []))),
                                                  ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem + (" " + (
                                                              isSauceLabsVariant ? "" : clickableListItem
                                                            )), undefined, (function (_event) {
                                                              if (!isSauceLabsVariant) {
                                                                return Curry._1(self.send, /* Dialog */[/* Email */3]);
                                                              }
                                                              
                                                            }), undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Email")), Caml_option.some(I18N$BsConsole.dynamic(config.user.email)), [])),
                                                            isSauceLabsVariant ? null : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                          ]))
                                                ]))
                                      ])),
                              Util$BsConsole.renderIf((function (param) {
                                      return I18N_config$BsConsole.isEnabled(undefined);
                                    }), (function (param) {
                                      return ReasonReact.element(undefined, undefined, make(I18N$BsConsole.get(undefined, "Locale"), []));
                                    }), undefined, undefined),
                              Util$BsConsole.renderIf((function (param) {
                                      return I18N_config$BsConsole.isEnabled(undefined);
                                    }), (function (param) {
                                      return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                                          Css.marginBottom(Css.rem(1.0)),
                                                          /* [] */0
                                                        ]), [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem + (" " + clickableListItem), undefined, (function (_event) {
                                                                            return Curry._1(self.send, /* Dialog */[/* Locale */1]);
                                                                          }), undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Language")), Caml_option.some(I18N$BsConsole.showSkip(Belt_Option.getWithDefault(Belt_Option.map(I18N_config$BsConsole.GetUserLanguage.getNoDefault(config), I18N$BsConsole.LanguageCode.toString), I18N$BsConsole.get(undefined, "User Browser Language")))), [])),
                                                                          ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                        ]))]))]));
                                    }), undefined, undefined),
                              ReasonReact.element(undefined, undefined, make(I18N$BsConsole.get(undefined, "Authentication"), [])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1.0)),
                                            /* [] */0
                                          ]), [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [
                                                  tmp$1,
                                                  ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Role")), Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(__x, I18N$BsConsole.get(undefined, "role not set")))), []))])),
                                                  isSauceLabsVariant ? null : React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])), ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem + (" " + clickableListItem), undefined, undefined, undefined, [
                                                                  ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Command line login")), Caml_option.some(React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, CopyToClipboard$BsConsole.make("morgue login --token=" + (token + (" " + location.origin)), (function (param) {
                                                                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Morgue login command copied to clipboard"));
                                                                                            }), [React.createElement("div", {
                                                                                                  className: morgueLogin
                                                                                                }, React.createElement("pre", undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "morgue login") + (" " + (location.origin + (" " + I18N$BsConsole.skip("--token=")))))), self.state.tokenHidden ? React.createElement("input", {
                                                                                                        className: morgueLoginInput,
                                                                                                        type: "password",
                                                                                                        value: token,
                                                                                                        onChange: (function (param) {
                                                                                                            
                                                                                                          })
                                                                                                      }) : React.createElement("input", {
                                                                                                        className: morgueLoginInput,
                                                                                                        type: "text",
                                                                                                        value: token,
                                                                                                        onChange: (function (param) {
                                                                                                            
                                                                                                          })
                                                                                                      }))])))), [])),
                                                                  self.state.tokenHidden ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                              visibilityButtom,
                                                                              undefined,
                                                                              (function (param) {
                                                                                  return Curry._1(self.send, /* RevealToken */0);
                                                                                }),
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              "Reveal token",
                                                                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.VisibilityOff.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                            ])) : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                              visibilityButtom,
                                                                              undefined,
                                                                              (function (param) {
                                                                                  return Curry._1(self.send, /* HideToken */1);
                                                                                }),
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              "Hide token",
                                                                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Visibility.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                            ])),
                                                                  ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Click for installation instructions (https://github.com/backtrace-labs/backtrace-morgue)"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [React.createElement("a", {
                                                                                            href: "https://github.com/backtrace-labs/backtrace-morgue",
                                                                                            target: "_blank"
                                                                                          }, ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(undefined, {
                                                                                                      width: "40px"
                                                                                                    }, "https://cdn-js.global.ssl.fastly.net/static/github.svg", "GitHub Logo", undefined)([])))]))]))
                                                                ])))
                                                ]))])),
                              isSauceLabsVariant ? null : React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, make(I18N$BsConsole.get(undefined, "Storage usage"), [])), ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                                  Css.marginBottom(Css.rem(1.0)),
                                                  /* [] */0
                                                ]), [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, SystemUsage$BsConsole.Re.make(config, token, handleChangeUrl, []))]))]))])))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      tokenHidden: true
                    };
            }),
          retainedProps: component$5.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              tokenHidden: true
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              tokenHidden: false
                            }]);
                }
              } else {
                return /* Update */Block.__(0, [{
                            dialog: action[0],
                            tokenHidden: state.tokenHidden
                          }]);
              }
            }),
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var UserSettings = {
  Styles: Styles,
  component: component$5,
  make: make$5
};

var component$6 = ReasonReact.statelessComponent("SettingsMePage-BsConsole");

function make$6(onUpdateCurrentUser, token, handleTask, config, handleChangeUrl, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, make$5(onUpdateCurrentUser, config, handleChangeUrl, token, handleTask, []));
            }),
          initialState: component$6.initialState,
          retainedProps: component$6.retainedProps,
          reducer: component$6.reducer,
          jsElementWrapped: component$6.jsElementWrapped
        };
}

exports.PasswordChangeFormContainer = PasswordChangeFormContainer;
exports.ChangeEmailFormContainer = ChangeEmailFormContainer;
exports.SectionHeader = SectionHeader;
exports.EditAuthMethodDialog = EditAuthMethodDialog;
exports.EditPasswordDialog = EditPasswordDialog;
exports.EditEmailDialog = EditEmailDialog;
exports.SettingListItem = SettingListItem;
exports.UserSettings = UserSettings;
exports.component = component$6;
exports.make = make$6;
/* PasswordChangeFormContainer Not a pure module */
