// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../I18N.js");

function toString(param) {
  switch (param) {
    case /* SevenDays */0 :
        return I18N$BsConsole.get(undefined, "7 days");
    case /* FourteenDays */1 :
        return I18N$BsConsole.get(undefined, "14 days");
    case /* ThirtyDays */2 :
        return I18N$BsConsole.get(undefined, "30 days");
    case /* NinetyDays */3 :
        return I18N$BsConsole.get(undefined, "90 days");
    
  }
}

function toNumber(param) {
  switch (param) {
    case /* SevenDays */0 :
        return 7;
    case /* FourteenDays */1 :
        return 14;
    case /* ThirtyDays */2 :
        return 30;
    case /* NinetyDays */3 :
        return 90;
    
  }
}

function toTimelineChartNodeSize(param) {
  switch (param) {
    case /* SevenDays */0 :
        return 25;
    case /* FourteenDays */1 :
        return 20;
    case /* ThirtyDays */2 :
        return 10;
    case /* NinetyDays */3 :
        return 8;
    
  }
}

function toTimelineChartMouseOffset(param) {
  if (param !== 1) {
    if (param !== 0) {
      return 4;
    } else {
      return 13;
    }
  } else {
    return 10;
  }
}

var secondsPerDay = 24 * 60 * 60;

function toSeconds(param) {
  switch (param) {
    case /* SevenDays */0 :
        return 7 * secondsPerDay;
    case /* FourteenDays */1 :
        return 14 * secondsPerDay;
    case /* ThirtyDays */2 :
        return 30 * secondsPerDay;
    case /* NinetyDays */3 :
        return 90 * secondsPerDay;
    
  }
}

var options = [
  /* SevenDays */0,
  /* FourteenDays */1,
  /* ThirtyDays */2,
  /* NinetyDays */3
];

exports.toString = toString;
exports.toNumber = toNumber;
exports.toTimelineChartNodeSize = toTimelineChartNodeSize;
exports.toTimelineChartMouseOffset = toTimelineChartMouseOffset;
exports.secondsPerDay = secondsPerDay;
exports.toSeconds = toSeconds;
exports.options = options;
/* I18N-BsConsole Not a pure module */
