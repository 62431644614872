// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WfFrequencyInput$BsConsole = require("./WfFrequencyInput.js");
var WfThresholdInput$BsConsole = require("./WfThresholdInput.js");

function defaultConfig(category) {
  return {
          eventType: category.type_ === /* IssueTracker */2 ? /* Group */1 : /* Trace */0,
          frequency: /* Empty */0,
          threshold: /* Empty */0
        };
}

function toBehavior(t) {
  return Belt_Option.map(t, (function (cfg) {
                var frequency = cfg.frequency;
                var tmp;
                if (typeof frequency === "number") {
                  tmp = 60;
                } else {
                  if (frequency.tag) {
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "WfBehaviorForm.re",
                            34,
                            24
                          ]
                        ];
                  }
                  tmp = frequency[0];
                }
                var threshold = cfg.threshold;
                var tmp$1;
                if (typeof threshold === "number") {
                  tmp$1 = 0;
                } else {
                  if (threshold.tag) {
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "WfBehaviorForm.re",
                            40,
                            24
                          ]
                        ];
                  }
                  tmp$1 = threshold[0];
                }
                return {
                        eventType: cfg.eventType,
                        frequency: tmp,
                        threshold: tmp$1
                      };
              }));
}

function fromWatcher(watcher) {
  return {
          config: Belt_Option.map(watcher.behavior, (function (behavior) {
                  return {
                          eventType: behavior.eventType,
                          frequency: /* Valid */Block.__(0, [behavior.frequency]),
                          threshold: /* Valid */Block.__(0, [behavior.threshold])
                        };
                })),
          dataSynchronization: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("manual_sync", Json_decode.bool, param);
                    }), watcher.settings), true),
          dataSynchronization2Way: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("two_way_sync", Json_decode.bool, param);
                    }), watcher.settings), false)
        };
}

var inlineInput = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(4)),
      /* :: */[
        Css.width(Css.px(150)),
        /* [] */0
      ]
    ]);

var inlineInputText = Css.style(/* :: */[
      Css.paddingTop(Css.px(7)),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* [] */0
      ]
    ]);

var Styles = {
  inlineInput: inlineInput,
  inlineInputText: inlineInputText
};

var component = RR$BsConsole.reducerComponent("WfBehaviorForm-BsConsole");

function make(universeName, watcherName, integration, value, onValueChange, serviceEndpoint, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              var match = integration.description.category.type_;
              var verbPresent = match !== 2 ? "send" : "create";
              var match$1 = integration.description.category.type_;
              var verbPast = match$1 !== 2 ? "sent" : "created";
              var match$2 = integration.description.category.type_;
              var noun;
              switch (match$2) {
                case /* MessagingPlatform */0 :
                    noun = "message";
                    break;
                case /* Monitoring */1 :
                    noun = "alert";
                    break;
                case /* IssueTracker */2 :
                    noun = "ticket";
                    break;
                case /* Other */3 :
                    noun = "event";
                    break;
                
              }
              var match$3 = integration.description.category.type_;
              var article;
              switch (match$3) {
                case /* MessagingPlatform */0 :
                case /* IssueTracker */2 :
                    article = "a";
                    break;
                case /* Monitoring */1 :
                case /* Other */3 :
                    article = "an";
                    break;
                
              }
              var match$4 = integration.description.category.type_;
              var nounPlural;
              switch (match$4) {
                case /* MessagingPlatform */0 :
                    nounPlural = "messages";
                    break;
                case /* Monitoring */1 :
                    nounPlural = "alerts";
                    break;
                case /* IssueTracker */2 :
                    nounPlural = "tickets";
                    break;
                case /* Other */3 :
                    nounPlural = "events";
                    break;
                
              }
              var title = integration.description.title;
              var match$5 = integration.description.category.type_;
              var tmp;
              if (match$5 !== 2) {
                tmp = null;
              } else {
                var tmp$1;
                if (Belt_Option.isSome(serviceEndpoint) && integration.description.type_ === "jira") {
                  tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                            style: {
                              fontSize: "16px",
                              fontWeight: "500"
                            }
                          }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Data synchronization from ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "Data synchronization from %s"
                                  ]), title)), React.createElement("div", {
                            style: {
                              fontSize: "14px",
                              paddingBottom: "8px"
                            }
                          }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "When updating a ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                " task, also modifies the linked issue.",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "When updating a %s task, also modifies the linked issue."
                                  ]), title)), React.createElement("div", {
                            style: {
                              display: "flex",
                              height: "54px"
                            }
                          }, React.createElement("label", {
                                style: {
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center"
                                }
                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                        undefined,
                                        /* `Bool */[
                                          737456202,
                                          value.dataSynchronization2Way
                                        ],
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (param, param$1) {
                                            return Curry._1(onValueChange, {
                                                        config: value.config,
                                                        dataSynchronization: value.dataSynchronization,
                                                        dataSynchronization2Way: true
                                                      });
                                          }),
                                        {
                                          padding: "4px"
                                        },
                                        []
                                      ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Yes"))), React.createElement("label", {
                                style: {
                                  cursor: "pointer",
                                  display: "flex",
                                  paddingLeft: "8px",
                                  alignItems: "center"
                                }
                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                        undefined,
                                        /* `Bool */[
                                          737456202,
                                          !value.dataSynchronization2Way
                                        ],
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (param, param$1) {
                                            return Curry._1(onValueChange, {
                                                        config: value.config,
                                                        dataSynchronization: value.dataSynchronization,
                                                        dataSynchronization2Way: false
                                                      });
                                          }),
                                        {
                                          padding: "4px"
                                        },
                                        []
                                      ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No")))));
                } else if (Belt_Option.isSome(serviceEndpoint) && integration.description.type_ === "phabricator") {
                  var serviceEndpoint$1 = Belt_Option.getExn(serviceEndpoint);
                  var origin = window.location.origin;
                  var baseUrl = serviceEndpoint$1.startsWith("/") ? origin + serviceEndpoint$1 : serviceEndpoint$1;
                  var supportLink = Href$BsConsole.Support.make(undefined, /* ArticlePhabricatorIntegration */2);
                  tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                            style: {
                              fontSize: "16px",
                              fontWeight: "500"
                            }
                          }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Data synchronization from ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                " to Backtrace",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "Data synchronization from %s to Backtrace"
                                  ]), title)), React.createElement("div", {
                            style: {
                              fontSize: "14px",
                              paddingBottom: "8px"
                            }
                          }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "To enable synchronization from Phabricator to Backtrace you need to set up a Herald rule that calls a webhook at "), React.createElement("span", {
                                    className: Css.style(/* :: */[
                                          Css.whiteSpace(/* nowrap */867913355),
                                          /* :: */[
                                            Css.background(Css.hex(Colors$BsConsole.grey75)),
                                            /* :: */[
                                              Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey625)),
                                              /* :: */[
                                                Css.fontFamily("Inconsolata"),
                                                /* :: */[
                                                  Css.padding(Css.px(2)),
                                                  /* :: */[
                                                    Css.marginTop(Css.px(2)),
                                                    /* :: */[
                                                      Css.display(Css.inlineBlock),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(baseUrl + ("/" + (universeName + ("/" + watcherName)))))), supportLink !== undefined ? React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.paddingTop(Css.px(8)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "For more details, see the "), React.createElement("a", {
                                      className: Css.style(/* :: */[
                                            Css.important(Css.color(Css.hex(Colors$BsConsole.npDarkBlue2))),
                                            /* [] */0
                                          ]),
                                      href: supportLink,
                                      target: "_blank"
                                    }, I18N$BsConsole.show(undefined, "Phabricator integration guide"))) : null));
                } else {
                  tmp$1 = null;
                }
                tmp = React.createElement("div", undefined, React.createElement(React.Fragment, undefined, React.createElement("div", {
                              style: {
                                fontSize: "16px",
                                fontWeight: "500"
                              }
                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Data synchronization to ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "Data synchronization to %s"
                                    ]), title)), React.createElement("div", {
                              style: {
                                fontSize: "14px",
                                paddingBottom: "8px"
                              }
                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "When updating a report, also modifies the linked ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " task.",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "When updating a report, also modifies the linked %s task."
                                    ]), title)), React.createElement("div", {
                              style: {
                                display: "flex",
                                height: "54px"
                              }
                            }, React.createElement("label", {
                                  style: {
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                          undefined,
                                          /* `Bool */[
                                            737456202,
                                            value.dataSynchronization
                                          ],
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (param, param$1) {
                                              return Curry._1(onValueChange, {
                                                          config: value.config,
                                                          dataSynchronization: true,
                                                          dataSynchronization2Way: value.dataSynchronization2Way
                                                        });
                                            }),
                                          {
                                            padding: "4px"
                                          },
                                          []
                                        ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Yes"))), React.createElement("label", {
                                  style: {
                                    cursor: "pointer",
                                    display: "flex",
                                    paddingLeft: "8px",
                                    alignItems: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                          undefined,
                                          /* `Bool */[
                                            737456202,
                                            !value.dataSynchronization
                                          ],
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (param, param$1) {
                                              return Curry._1(onValueChange, {
                                                          config: value.config,
                                                          dataSynchronization: false,
                                                          dataSynchronization2Way: value.dataSynchronization2Way
                                                        });
                                            }),
                                          {
                                            padding: "4px"
                                          },
                                          []
                                        ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No"))))), tmp$1);
              }
              var config = value.config;
              var tmp$2;
              if (config !== undefined) {
                var match$6 = config.eventType;
                tmp$2 = React.createElement("div", {
                      style: {
                        marginTop: "8px"
                      }
                    }, React.createElement("div", {
                          style: {
                            fontWeight: "500",
                            paddingTop: "0 8px"
                          }
                        }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Select when ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* " " */32,
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " should be ",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* Char_literal */Block.__(12, [
                                                              /* "." */46,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),
                                  "Select when %s %s should be %s."
                                ]), article, noun, verbPast)), ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, undefined, undefined, /* `String */[
                              -976970511,
                              "fieldset"
                            ], [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, match$6 ? "1" : "0", (function (param, v) {
                                          var tmp;
                                          switch (v) {
                                            case "0" :
                                                tmp = /* Trace */0;
                                                break;
                                            case "1" :
                                                tmp = /* Group */1;
                                                break;
                                            default:
                                              throw [
                                                    Caml_builtin_exceptions.assert_failure,
                                                    /* tuple */[
                                                      "WfBehaviorForm.re",
                                                      347,
                                                      32
                                                    ]
                                                  ];
                                          }
                                          return Curry._1(onValueChange, {
                                                      config: {
                                                        eventType: tmp,
                                                        frequency: config.frequency,
                                                        threshold: config.threshold
                                                      },
                                                      dataSynchronization: value.dataSynchronization,
                                                      dataSynchronization2Way: value.dataSynchronization2Way
                                                    });
                                        }), undefined, [
                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                  "0",
                                                  Caml_option.some(I18N$BsConsole.show(undefined, "When an error is received by the system.")),
                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                []
                                                              ]))),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  []
                                                ])),
                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                  "1",
                                                  Caml_option.some(I18N$BsConsole.show(undefined, "When a new error group is created by the system.")),
                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                []
                                                              ]))),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  {
                                                    marginTop: "-6px"
                                                  },
                                                  []
                                                ]))
                                      ]))])), React.createElement("div", {
                          style: {
                            marginTop: "16px"
                          }
                        }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.ButtonBase.Jsx2.make, undefined, undefined, (function (param) {
                                    return Curry._1(send, /* ToggleAdvancedSettings */0);
                                  }), undefined, undefined, undefined, [React.createElement("div", {
                                        style: {
                                          display: "flex",
                                          alignItems: "center"
                                        }
                                      }, React.createElement("div", {
                                            style: {
                                              marginLeft: "-5px",
                                              paddingRight: "4px"
                                            }
                                          }, React.createElement("div", {
                                                style: {
                                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA54),
                                                  transition: "transform 0.3s ease-in-out",
                                                  transform: "rotate(" + ((
                                                      state.advancedSettingsExpanded ? "90deg" : " 0deg"
                                                    ) + ")")
                                                }
                                              }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardArrowRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, {
                                                fontWeight: "500",
                                                paddingLeft: "10px"
                                              }, [I18N$BsConsole.show(undefined, "Advanced settings")])))]))), ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Collapse.Jsx2.make, undefined, undefined, state.advancedSettingsExpanded, undefined, [React.createElement("div", {
                                    style: {
                                      paddingLeft: "32px"
                                    }
                                  }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Customize when you will receive automated messages")]))), React.createElement("div", {
                                        style: {
                                          paddingTop: "24px"
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, {
                                                fontWeight: "500"
                                              }, [I18N$BsConsole.show(undefined, "Frequency")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This is the minimum amount of time the system will wait before firing the next event.")]))), React.createElement("div", {
                                        style: {
                                          display: "flex",
                                          paddingTop: "8px"
                                        }
                                      }, React.createElement("span", {
                                            className: inlineInputText
                                          }, I18N$BsConsole.show(undefined, "Once every")), React.createElement("div", {
                                            className: inlineInput
                                          }, ReasonReact.element(undefined, undefined, WfFrequencyInput$BsConsole.make(config.frequency, (function (frequency) {
                                                      return Curry._1(onValueChange, {
                                                                  config: {
                                                                    eventType: config.eventType,
                                                                    frequency: frequency,
                                                                    threshold: config.threshold
                                                                  },
                                                                  dataSynchronization: value.dataSynchronization,
                                                                  dataSynchronization2Way: value.dataSynchronization2Way
                                                                });
                                                    }), []))), React.createElement("span", {
                                            className: inlineInputText
                                          }, I18N$BsConsole.show(undefined, "seconds"))), React.createElement("div", {
                                        style: {
                                          fontWeight: "500",
                                          paddingTop: "24px"
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, {
                                                fontWeight: "500"
                                              }, [I18N$BsConsole.show(undefined, "Threshold")]))), React.createElement("div", {
                                        style: { }
                                      }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Action is taken when this number of events has been aggregated, regardless of frequency settings.")]))), React.createElement("div", {
                                        style: {
                                          display: "flex",
                                          paddingTop: "8px"
                                        }
                                      }, React.createElement("div", {
                                            className: inlineInput
                                          }, ReasonReact.element(undefined, undefined, WfThresholdInput$BsConsole.make(config.threshold, (function (threshold) {
                                                      return Curry._1(onValueChange, {
                                                                  config: {
                                                                    eventType: config.eventType,
                                                                    frequency: config.frequency,
                                                                    threshold: threshold
                                                                  },
                                                                  dataSynchronization: value.dataSynchronization,
                                                                  dataSynchronization2Way: value.dataSynchronization2Way
                                                                });
                                                    }), []))), React.createElement("span", {
                                            className: inlineInputText
                                          }, I18N$BsConsole.show(undefined, "errors"))))])));
              } else {
                tmp$2 = null;
              }
              return React.createElement("div", {
                          style: {
                            padding: "17px",
                            paddingTop: "51px"
                          }
                        }, tmp, React.createElement("div", {
                              style: {
                                fontSize: "16px",
                                fontWeight: "500",
                                paddingTop: "28px"
                              }
                            }, I18N$BsConsole.show(undefined, "Automated actions")), React.createElement("div", {
                              style: {
                                padding: "8px 0"
                              }
                            }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "With this integration, you can manually ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* Char_literal */Block.__(12, [
                                                  /* " " */32,
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " in ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* Char_literal */Block.__(12, [
                                                                  /* "." */46,
                                                                  /* End_of_format */0
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                      "With this integration, you can manually %s %s in %s."
                                    ]), verbPresent, nounPlural, title)), React.createElement("div", {
                              style: {
                                paddingBottom: "8px"
                              }
                            }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Would you like to automatically ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* Char_literal */Block.__(12, [
                                                  /* " " */32,
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " for error reports? You are able to configure automated behavior below.",
                                                          /* End_of_format */0
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                      "Would you like to automatically %s %s for error reports? You are able to configure automated behavior below."
                                    ]), verbPresent, nounPlural)), React.createElement("div", {
                              style: {
                                display: "flex",
                                height: "54px"
                              }
                            }, React.createElement("label", {
                                  style: {
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                          undefined,
                                          /* `Bool */[
                                            737456202,
                                            Belt_Option.isSome(value.config)
                                          ],
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (param, param$1) {
                                              return Curry._1(onValueChange, {
                                                          config: defaultConfig(integration.description.category),
                                                          dataSynchronization: value.dataSynchronization,
                                                          dataSynchronization2Way: value.dataSynchronization2Way
                                                        });
                                            }),
                                          {
                                            padding: "4px"
                                          },
                                          []
                                        ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Yes"))), React.createElement("label", {
                                  style: {
                                    cursor: "pointer",
                                    display: "flex",
                                    paddingLeft: "8px",
                                    alignItems: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                          undefined,
                                          /* `Bool */[
                                            737456202,
                                            Belt_Option.isNone(value.config)
                                          ],
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (param, param$1) {
                                              return Curry._1(onValueChange, {
                                                          config: undefined,
                                                          dataSynchronization: value.dataSynchronization,
                                                          dataSynchronization2Way: value.dataSynchronization2Way
                                                        });
                                            }),
                                          {
                                            padding: "4px"
                                          },
                                          []
                                        ])), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No")))), tmp$2);
            }),
          initialState: (function (param) {
              return {
                      advancedSettingsExpanded: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          advancedSettingsExpanded: !state.advancedSettingsExpanded
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var defaultPeriod = 60;

var defaultSum = 0;

exports.defaultConfig = defaultConfig;
exports.defaultPeriod = defaultPeriod;
exports.defaultSum = defaultSum;
exports.toBehavior = toBehavior;
exports.fromWatcher = fromWatcher;
exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* inlineInput Not a pure module */
