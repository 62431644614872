// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("./Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("./Column.js");
var Styles$BsConsole = require("./Styles.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");

var component = RR$BsConsole.reducerComponent("OrderedCard-BsConsole");

var cardStyle = Css.style(/* :: */[
      Css.borderRadius(Css.px(0)),
      /* :: */[
        Css.minWidth(Css.px(400)),
        /* :: */[
          Css.minHeight(Css.px(100)),
          /* :: */[
            Css.height(Css.px(100)),
            /* :: */[
              Css.marginBottom(Css.px(15)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function hoverStyle(hovered, isDragging, view, disabled) {
  if (view) {
    if (disabled) {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                    /* :: */[
                      Css.opacity(0.5),
                      /* [] */0
                    ]
                  ]
                ]);
    } else if (isDragging || hovered) {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                  /* [] */0
                ]);
    } else {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                  /* [] */0
                ]);
    }
  } else {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
                /* [] */0
              ]);
  }
}

function hoverStyleFooter(hovered, isDragging, view, disabled) {
  if (view) {
    if (isDragging || hovered) {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey5)),
                  /* [] */0
                ]);
    } else if (disabled) {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey5)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                    /* [] */0
                  ]
                ]);
    } else {
      return Css.style(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                  /* [] */0
                ]);
    }
  } else {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.accentLight)),
                /* [] */0
              ]);
  }
}

function disabledStyle(disabled) {
  if (disabled) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey4)),
                  /* [] */0
                ]
              ]);
  } else {
    return "";
  }
}

function disabledStyleFooter(disabled) {
  if (disabled) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey5)),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey4)),
                  /* [] */0
                ]
              ]);
  } else {
    return "";
  }
}

function disabledStyleIcons(disabled) {
  if (disabled) {
    return Css.style(/* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey4)),
                /* [] */0
              ]);
  } else {
    return "";
  }
}

function make(titleOpt, subtitleOpt, infoLeftOpt, infoRightOpt, handleTitleUpdate, onEditEnabled, onEditDisabled, editTitleTextOpt, $staropt$star, $staropt$star$1, $staropt$star$2, deleteAction, onCardClick, moreActions, dragHandleProps, $staropt$star$3, $staropt$star$4, view, $staropt$star$5, $staropt$star$6, _children) {
  var title = titleOpt !== undefined ? titleOpt : "";
  var subtitle = subtitleOpt !== undefined ? subtitleOpt : "";
  var infoLeft = infoLeftOpt !== undefined ? infoLeftOpt : /* String */Block.__(0, [""]);
  var infoRight = infoRightOpt !== undefined ? infoRightOpt : /* String */Block.__(0, [""]);
  var editTitleText = editTitleTextOpt !== undefined ? editTitleTextOpt : I18N$BsConsole.get(undefined, "Edit");
  if ($staropt$star === undefined) {
    I18N$BsConsole.get(undefined, "Save changes");
  }
  if ($staropt$star$1 === undefined) {
    I18N$BsConsole.get(undefined, "Discard changes");
  }
  var deleteCardText = $staropt$star$2 !== undefined ? $staropt$star$2 : I18N$BsConsole.get(undefined, "Delete");
  if ($staropt$star$3 === undefined) {
    false;
  }
  var isDragging = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var cardDisabled = $staropt$star$5 !== undefined ? $staropt$star$5 : false;
  var showIconsAlways = $staropt$star$6 !== undefined ? $staropt$star$6 : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var hovered = match.hovered;
              var isTitleEditable = handleTitleUpdate !== undefined;
              var editIcon = isTitleEditable ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                              className: Styles$BsConsole.$$Text.tooltipTitle
                            }, I18N$BsConsole.showSkip(editTitleText)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                className: "smaller-icon " + Css.style(/* :: */[
                                      Css.cursor(/* pointer */-786317123),
                                      /* :: */[
                                        Css.marginLeft(Css.px(2)),
                                        /* [] */0
                                      ]
                                    ]),
                                onClick: (function (param) {
                                    return Curry._1(send, /* EditEnabled */0);
                                  })
                              }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Edit.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])) : null;
              var deleteIcon = deleteAction !== undefined ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                              className: Styles$BsConsole.$$Text.tooltipTitle
                            }, I18N$BsConsole.showSkip(deleteCardText)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                className: "smaller-icon " + Css.style(/* :: */[
                                      Css.cursor(/* pointer */-786317123),
                                      /* :: */[
                                        Css.marginLeft(Css.px(2)),
                                        /* [] */0
                                      ]
                                    ]),
                                onClick: deleteAction
                              }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])) : null;
              var disabledIcon = cardDisabled ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                              className: Styles$BsConsole.$$Text.tooltipTitle
                            }, I18N$BsConsole.show(undefined, "This ruleset is currently disabled. Click the icon to the right to enable.")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                className: "smaller-icon " + Css.style(/* :: */[
                                      Css.cursor(/* pointer */-786317123),
                                      /* :: */[
                                        Css.marginLeft(Css.px(2)),
                                        /* [] */0
                                      ]
                                    ])
                              }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.VpnKey.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])) : null;
              var moreIcon;
              if (moreActions) {
                moreIcon = ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (close) {
                            return Belt_List.toArray(Belt_List.map(moreActions, (function (item) {
                                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, Css.style(/* :: */[
                                                                  Css.width(Css.px(100)),
                                                                  /* :: */[
                                                                    Css.padding2(Css.px(4), Css.px(12)),
                                                                    /* [] */0
                                                                  ]
                                                                ]), (function (param) {
                                                                Curry._1(item.executable, undefined);
                                                                return Curry._1(close, undefined);
                                                              }), undefined, false, undefined, [I18N$BsConsole.showSkip(item.label)]));
                                            })));
                          }), undefined, []));
              } else {
                moreIcon = null;
              }
              var tmp;
              tmp = infoLeft.tag ? infoLeft[0] : I18N$BsConsole.showSkip(infoLeft[0]);
              var tmp$1;
              tmp$1 = infoRight.tag ? infoRight[0] : I18N$BsConsole.showSkip(infoRight[0]);
              return React.cloneElement(React.createElement("div", undefined, React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.display(/* flex */-1010954439),
                                        /* :: */[
                                          Css.alignItems(/* center */98248149),
                                          /* [] */0
                                        ]
                                      ]),
                                  onClick: (function (_event) {
                                      if (onCardClick !== undefined) {
                                        return Curry._1(onCardClick, undefined);
                                      }
                                      
                                    }),
                                  onMouseLeave: (function (param) {
                                      return Curry._1(send, /* MouseLeave */3);
                                    })
                                }, React.createElement("div", {
                                      className: Css.merge(/* :: */[
                                            cardStyle,
                                            /* :: */[
                                              hoverStyle(hovered, isDragging, view, cardDisabled),
                                              /* [] */0
                                            ]
                                          ])
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.flexDirection(/* column */-963948842),
                                                  /* :: */[
                                                    Css.justifyContent(/* spaceBetween */516682146),
                                                    /* :: */[
                                                      Css.height(Css.pct(100)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          onMouseEnter: (function (param) {
                                              return Curry._1(send, /* MouseEnter */2);
                                            })
                                        }, React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.margin(Css.px(10)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(18)),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.justifyContent(/* spaceBetween */516682146),
                                                          /* :: */[
                                                            Css.alignItems(/* flexEnd */924268066),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                              Css.display(/* flex */-1010954439),
                                                              /* :: */[
                                                                Css.flexGrow(4),
                                                                /* [] */0
                                                              ]
                                                            ]), [
                                                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.merge(/* :: */[
                                                                        Styles$BsConsole.$$Text.subtitle,
                                                                        /* :: */[
                                                                          Css.style(/* :: */[
                                                                                Css.marginBottom(Css.px(5)),
                                                                                /* :: */[
                                                                                  Css.height(Css.px(20)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]), [React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.display(/* flex */-1010954439),
                                                                                /* :: */[
                                                                                  Css.justifyContent(/* spaceBetween */516682146),
                                                                                  /* :: */[
                                                                                    Css.width(Css.pct(100)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ])
                                                                        }, React.createElement("aside", undefined, I18N$BsConsole.showSkip(subtitle)), showIconsAlways || (hovered || isDragging) && !match.editEnabled ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                    editIcon,
                                                                                    deleteIcon,
                                                                                    disabledIcon,
                                                                                    moreIcon
                                                                                  ])) : null)])),
                                                          React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.width(Css.px(300)),
                                                                      /* :: */[
                                                                        Css.overflowX(/* hidden */-862584982),
                                                                        /* :: */[
                                                                          Css.whiteSpace(/* nowrap */867913355),
                                                                          /* :: */[
                                                                            Css.textOverflow(/* ellipsis */166888785),
                                                                            /* [] */0
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ])
                                                              }, I18N$BsConsole.showSkip(title))
                                                        ])))), React.createElement("div", {
                                              className: Css.merge(/* :: */[
                                                    Styles$BsConsole.$$Text.subtitle,
                                                    /* :: */[
                                                      hoverStyleFooter(hovered, isDragging, view, cardDisabled),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.marginLeft(Css.px(10)),
                                                          /* :: */[
                                                            Css.marginRight(Css.px(10)),
                                                            /* :: */[
                                                              Css.marginTop(Css.px(5)),
                                                              /* :: */[
                                                                Css.marginBottom(Css.px(5)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.whiteSpace(/* nowrap */867913355),
                                                                  /* :: */[
                                                                    Css.overflow(/* hidden */-862584982),
                                                                    /* :: */[
                                                                      Css.textOverflow(/* ellipsis */166888785),
                                                                      /* :: */[
                                                                        Css.width(Css.px(180)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ])
                                                          }, tmp),
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.whiteSpace(/* nowrap */867913355),
                                                                  /* :: */[
                                                                    Css.overflow(/* hidden */-862584982),
                                                                    /* :: */[
                                                                      Css.textOverflow(/* ellipsis */166888785),
                                                                      /* :: */[
                                                                        Css.width(Css.px(180)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ])
                                                          }, tmp$1),
                                                      React.createElement("div", undefined, I18N$BsConsole.showSkip(" "))
                                                    ]))))))), dragHandleProps);
            }),
          initialState: (function (param) {
              return {
                      hovered: false,
                      editEnabled: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action) {
                case /* EditEnabled */0 :
                    if (onEditEnabled !== undefined) {
                      Curry._1(onEditEnabled, undefined);
                    }
                    return /* Update */Block.__(0, [{
                                hovered: state.hovered,
                                editEnabled: true
                              }]);
                case /* EditDisabled */1 :
                    if (onEditDisabled !== undefined) {
                      Curry._1(onEditDisabled, undefined);
                    }
                    return /* Update */Block.__(0, [{
                                hovered: state.hovered,
                                editEnabled: false
                              }]);
                case /* MouseEnter */2 :
                    return /* Update */Block.__(0, [{
                                hovered: true,
                                editEnabled: state.editEnabled
                              }]);
                case /* MouseLeave */3 :
                    return /* Update */Block.__(0, [{
                                hovered: false,
                                editEnabled: state.editEnabled
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.cardStyle = cardStyle;
exports.hoverStyle = hoverStyle;
exports.hoverStyleFooter = hoverStyleFooter;
exports.disabledStyle = disabledStyle;
exports.disabledStyleFooter = disabledStyleFooter;
exports.disabledStyleIcons = disabledStyleIcons;
exports.make = make;
/* component Not a pure module */
