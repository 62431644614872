// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var I18N$BsConsole = require("../I18N.js");
var NemesisPlanSelection$BsConsole = require("./NemesisPlanSelection.js");

function getPlanInfo(planId, plans) {
  return Belt_Array.getBy(plans, (function (p) {
                return p.id === planId;
              }));
}

function convert(n, t) {
  return n / 100;
}

var Currency = {
  convert: convert
};

function getPlanPrice(plan, planQuantity) {
  var match = plan.price.pricingModel;
  var planCost;
  if (match !== 1) {
    if (match !== 0) {
      var tiersArray = plan.price.tiers;
      if (tiersArray !== undefined) {
        var tierForThisSubscriptionQuantity = Belt_Array.getBy(tiersArray, (function (tier) {
                if (planQuantity >= tier.startingUnit) {
                  return planQuantity < tier.endingUnit;
                } else {
                  return false;
                }
              }));
        planCost = tierForThisSubscriptionQuantity !== undefined ? Caml_int32.imul(tierForThisSubscriptionQuantity.price, planQuantity) / 100 : undefined;
      } else {
        planCost = undefined;
      }
    } else {
      var p = plan.price.price;
      planCost = p !== undefined ? p / 100 : undefined;
    }
  } else {
    var p$1 = plan.price.price;
    planCost = p$1 !== undefined ? Caml_int32.imul(p$1, planQuantity) / 100 : undefined;
  }
  return /* tuple */[
          planCost,
          /* tuple */[
            plan.price.period,
            plan.price.periodUnit
          ]
        ];
}

function getFeaturePrice(featurePrice, featureQuantity) {
  var match = featurePrice.pricingModel;
  var featureCost;
  if (match !== 1) {
    if (match !== 0) {
      var tiersArray = featurePrice.tiers;
      if (tiersArray !== undefined) {
        var tierForThisSubscriptionQuantity = Belt_Array.getBy(tiersArray, (function (tier) {
                if (featureQuantity >= tier.startingUnit) {
                  return featureQuantity < tier.endingUnit;
                } else {
                  return false;
                }
              }));
        featureCost = tierForThisSubscriptionQuantity !== undefined ? Caml_int32.imul(tierForThisSubscriptionQuantity.price, featureQuantity) / 100 : undefined;
      } else {
        featureCost = undefined;
      }
    } else {
      var p = featurePrice.price;
      featureCost = p !== undefined ? p / 100 : undefined;
    }
  } else {
    var p$1 = featurePrice.price;
    featureCost = p$1 !== undefined ? Caml_int32.imul(p$1, featureQuantity) / 100 : undefined;
  }
  return /* tuple */[
          featureCost,
          /* tuple */[
            featurePrice.period,
            featurePrice.periodUnit
          ]
        ];
}

var mismatchedPrice = I18N$BsConsole.get(undefined, "Operating on prices with mismatched periods");

function addPrices(a, b) {
  var bPeriodMaybe = b[1];
  var bPriceMaybe = b[0];
  var aPeriodMaybe = a[1];
  var aPriceMaybe = a[0];
  if (aPeriodMaybe === undefined) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (bPeriodMaybe === undefined) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (!(aPeriodMaybe[0] === bPeriodMaybe[0] && aPeriodMaybe[1] === bPeriodMaybe[1])) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (aPriceMaybe === undefined) {
    if (bPriceMaybe !== undefined) {
      return /* tuple */[
              bPriceMaybe,
              bPeriodMaybe
            ];
    } else {
      return /* tuple */[
              undefined,
              aPeriodMaybe
            ];
    }
  }
  if (bPriceMaybe === undefined) {
    return /* tuple */[
            aPriceMaybe,
            aPeriodMaybe
          ];
  }
  var newPrice = aPriceMaybe + bPriceMaybe;
  return /* tuple */[
          newPrice,
          aPeriodMaybe
        ];
}

function subtractPrices(a, b) {
  var bPeriodMaybe = b[1];
  var bPriceMaybe = b[0];
  var aPeriodMaybe = a[1];
  var aPriceMaybe = a[0];
  if (aPeriodMaybe === undefined) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (bPeriodMaybe === undefined) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (!(aPeriodMaybe[0] === bPeriodMaybe[0] && aPeriodMaybe[1] === bPeriodMaybe[1])) {
    return Js_exn.raiseError(mismatchedPrice);
  }
  if (aPriceMaybe === undefined) {
    if (bPriceMaybe !== undefined) {
      return /* tuple */[
              bPriceMaybe,
              bPeriodMaybe
            ];
    } else {
      return /* tuple */[
              undefined,
              aPeriodMaybe
            ];
    }
  }
  if (bPriceMaybe === undefined) {
    return /* tuple */[
            aPriceMaybe,
            aPeriodMaybe
          ];
  }
  var newPrice = aPriceMaybe - bPriceMaybe;
  return /* tuple */[
          newPrice,
          aPeriodMaybe
        ];
}

function getTotalPlanPrice(plan, planQuantity) {
  var match = plan.price.pricingModel;
  var planCost;
  if (match !== 1) {
    if (match !== 0) {
      var tiersArray = plan.price.tiers;
      if (tiersArray !== undefined) {
        var tierForThisSubscriptionQuantity = Belt_Array.getBy(tiersArray, (function (tier) {
                if (planQuantity >= tier.startingUnit) {
                  return planQuantity < tier.endingUnit;
                } else {
                  return false;
                }
              }));
        planCost = tierForThisSubscriptionQuantity !== undefined ? Caml_int32.imul(tierForThisSubscriptionQuantity.price, planQuantity) / 100 : undefined;
      } else {
        planCost = undefined;
      }
    } else {
      var p = plan.price.price;
      planCost = p !== undefined ? p / 100 : undefined;
    }
  } else {
    var p$1 = plan.price.price;
    planCost = p$1 !== undefined ? Caml_int32.imul(p$1, planQuantity) / 100 : undefined;
  }
  var planPeriod_000 = plan.price.period;
  var planPeriod_001 = plan.price.periodUnit;
  var planPeriod = /* tuple */[
    planPeriod_000,
    planPeriod_001
  ];
  return /* tuple */[
          planCost,
          planPeriod
        ];
}

function priceDifferenceOfIncrementingFeature(currentPrice, plan, feature) {
  var match = feature.planType;
  if (match) {
    return getTotalPlanPrice(plan, 1);
  }
  var p = feature.price;
  if (p !== undefined) {
    return getFeaturePrice(p, 1);
  }
  var currPeriodMaybe = currentPrice[1];
  var currPrice = currentPrice[0];
  if (currPeriodMaybe !== undefined) {
    return /* tuple */[
            currPrice,
            currPeriodMaybe
          ];
  } else {
    return /* tuple */[
            currPrice,
            /* tuple */[
              1,
              /* NotApplicable */4
            ]
          ];
  }
}

function getTotalPlanPriceFromSubscription(subscription, plans) {
  var planQuantity = subscription.planQuantity;
  var subscribedPlanInfoMaybe = Belt_Array.getBy(plans, (function (p) {
          return p.id === subscription.planId;
        }));
  var planCost;
  if (subscribedPlanInfoMaybe !== undefined) {
    var match = subscribedPlanInfoMaybe.price.pricingModel;
    if (match !== 1) {
      if (match !== 0) {
        var tiersArray = subscribedPlanInfoMaybe.price.tiers;
        if (tiersArray !== undefined) {
          var tierForThisSubscriptionQuantity = Belt_Array.getBy(tiersArray, (function (tier) {
                  if (planQuantity >= tier.startingUnit) {
                    return planQuantity < tier.endingUnit;
                  } else {
                    return false;
                  }
                }));
          planCost = tierForThisSubscriptionQuantity !== undefined ? Caml_int32.imul(tierForThisSubscriptionQuantity.price, planQuantity) / 100 : undefined;
        } else {
          planCost = undefined;
        }
      } else {
        var p = subscribedPlanInfoMaybe.price.price;
        planCost = p !== undefined ? p / 100 : undefined;
      }
    } else {
      var p$1 = subscribedPlanInfoMaybe.price.price;
      planCost = p$1 !== undefined ? Caml_int32.imul(p$1, planQuantity) / 100 : undefined;
    }
  } else {
    planCost = undefined;
  }
  var planPeriod = subscribedPlanInfoMaybe !== undefined ? /* tuple */[
      subscribedPlanInfoMaybe.price.period,
      subscribedPlanInfoMaybe.price.periodUnit
    ] : undefined;
  return /* tuple */[
          planCost,
          planPeriod
        ];
}

function getPriceByPricingModel(acc, p, q) {
  var match = p.pricingModel;
  if (match !== 1) {
    if (match !== 0) {
      var tiersArray = p.tiers;
      if (tiersArray === undefined) {
        return acc;
      }
      var tierForThisSubscriptionQuantity = Belt_Array.getBy(tiersArray, (function (tier) {
              if (q >= tier.startingUnit) {
                return q < tier.endingUnit;
              } else {
                return false;
              }
            }));
      if (tierForThisSubscriptionQuantity !== undefined) {
        return acc + Caml_int32.imul(tierForThisSubscriptionQuantity.price, q);
      } else {
        return acc;
      }
    }
    var p$1 = p.price;
    if (p$1 !== undefined) {
      return acc + p$1;
    } else {
      return acc;
    }
  }
  var p$2 = p.price;
  if (p$2 !== undefined) {
    return acc + Caml_int32.imul(q, p$2);
  } else {
    return acc;
  }
}

function getTotalAddonsPrice(subscription, plans) {
  var subscribedPlanInfoMaybe = Belt_Array.getBy(plans, (function (p) {
          return p.id === subscription.planId;
        }));
  if (subscribedPlanInfoMaybe !== undefined) {
    return Belt_Array.reduce(subscribedPlanInfoMaybe.incrementingFeatures, 0.0, (function (acc, curr) {
                  var match = curr.planType;
                  if (match) {
                    return acc;
                  }
                  var addon = Belt_Array.getBy(subscription.addons, (function (addon) {
                          return addon.id === curr.id;
                        }));
                  if (addon === undefined) {
                    return acc;
                  }
                  var match$1 = addon.quantity;
                  var match$2 = curr.price;
                  if (match$1 !== undefined) {
                    if (match$2 !== undefined) {
                      return getPriceByPricingModel(acc, match$2, match$1);
                    } else {
                      return acc;
                    }
                  } else if (match$2 !== undefined) {
                    return getPriceByPricingModel(acc, match$2, 1);
                  } else {
                    return acc;
                  }
                })) / 100;
  } else {
    return 0.0;
  }
}

function validPlans(subscription, plans) {
  var subscribedPlanInfoMaybe = Belt_Array.getBy(plans, (function (p) {
          return p.id === subscription.planId;
        }));
  if (subscribedPlanInfoMaybe !== undefined) {
    return Belt_Array.reduce(subscription.addons, true, (function (_acc, curr) {
                  return Belt_Option.isSome(Belt_Array.getBy(subscribedPlanInfoMaybe.incrementingFeatures, (function (feature) {
                                    return feature.id === curr.id;
                                  })));
                }));
  } else {
    return false;
  }
}

function priceToString(price) {
  var priceMaybe = price[0];
  var periodString = NemesisPlanSelection$BsConsole.PeriodUnit.toPeriod1String(price[1][1]);
  if (priceMaybe !== undefined) {
    return Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                    /* Char_literal */Block.__(12, [
                        /* "$" */36,
                        /* Float */Block.__(8, [
                            /* Float_f */0,
                            /* No_padding */0,
                            /* Lit_precision */[0],
                            /* Char_literal */Block.__(12, [
                                /* " " */32,
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ])
                          ])
                      ]),
                    "$%.0f %s"
                  ]), priceMaybe, periodString);
  } else {
    return I18N$BsConsole.empty(undefined);
  }
}

function totalPriceOfPlanPlusAddons(subscription, plans) {
  if (!validPlans(subscription, plans)) {
    return /* tuple */[
            undefined,
            undefined
          ];
  }
  var match = getTotalPlanPriceFromSubscription(subscription, plans);
  var planPeriodMaybe = match[1];
  var planPriceMaybe = match[0];
  if (planPriceMaybe === undefined) {
    return /* tuple */[
            undefined,
            planPeriodMaybe
          ];
  }
  var addonsCost = getTotalAddonsPrice(subscription, plans);
  var total = planPriceMaybe + addonsCost;
  return /* tuple */[
          total,
          planPeriodMaybe
        ];
}

function totalPriceString(subscription, plans) {
  if (!validPlans(subscription, plans)) {
    return I18N$BsConsole.get(undefined, "unknown");
  }
  var match = getTotalPlanPriceFromSubscription(subscription, plans);
  var planPeriodMaybe = match[1];
  var planPriceMaybe = match[0];
  var periodString = planPeriodMaybe !== undefined ? NemesisPlanSelection$BsConsole.PeriodUnit.toPeriod1String(planPeriodMaybe[1]) : I18N$BsConsole.get(undefined, "unknown");
  if (planPriceMaybe === undefined) {
    return I18N$BsConsole.get(undefined, "unknown");
  }
  var addonsCost = getTotalAddonsPrice(subscription, plans);
  var total = planPriceMaybe + addonsCost;
  return Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                  /* Char_literal */Block.__(12, [
                      /* "$" */36,
                      /* Float */Block.__(8, [
                          /* Float_f */0,
                          /* No_padding */0,
                          /* Lit_precision */[0],
                          /* Char_literal */Block.__(12, [
                              /* " " */32,
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ]),
                  "$%.0f %s"
                ]), total, periodString);
}

function numberOfPlansToAdd(desiredValue, incrementAmount, currentPlanQuantity, currentPlanBaseValue) {
  var haveNow = Caml_int32.imul(currentPlanQuantity - 1 | 0, incrementAmount) + currentPlanBaseValue | 0;
  var delta = desiredValue - haveNow | 0;
  var modulo = Caml_int32.mod_(delta, incrementAmount);
  if (delta <= 0) {
    return 0;
  } else if (modulo === 0) {
    return delta;
  } else {
    return Caml_int32.div(delta, incrementAmount);
  }
}

function numberOfAddonsToAdd(desiredValue, incrementAmount, currentValue) {
  var delta = desiredValue - currentValue | 0;
  if (delta <= 0) {
    return 0;
  } else {
    return Caml_int32.div(delta, incrementAmount);
  }
}

function getNextPlanQuantity(nextPlan, features) {
  if (nextPlan.quantity) {
    return Belt_List.reduce(features, 1, (function (acc, currentLimitsFeature) {
                  var featureThatIncrementsWithPlanQuantity = Belt_Array.get(Belt_Array.keep(nextPlan.incrementingFeatures, (function (incrementingFeature) {
                              if (incrementingFeature.planType === /* Plan */1) {
                                return incrementingFeature.name === currentLimitsFeature.name;
                              } else {
                                return false;
                              }
                            })), 0);
                  var nextPlanFeatureBaseValue = Belt_Array.get(Belt_Array.keep(nextPlan.baseFeatures, (function (feature) {
                              return feature.name === currentLimitsFeature.name;
                            })), 0);
                  if (featureThatIncrementsWithPlanQuantity === undefined) {
                    return acc;
                  }
                  if (nextPlanFeatureBaseValue === undefined) {
                    return acc;
                  }
                  try {
                    var desiredValue = Caml_format.caml_int_of_string(currentLimitsFeature.value);
                    var baseValue = Caml_format.caml_int_of_string(nextPlanFeatureBaseValue.value);
                    var numberToAdd = numberOfPlansToAdd(desiredValue, featureThatIncrementsWithPlanQuantity.incrementAmount, acc, baseValue);
                    return acc + numberToAdd | 0;
                  }
                  catch (exn){
                    return acc;
                  }
                }));
  } else {
    return 1;
  }
}

function getCurrentFeatureValue(plan, planQuantity, featureName) {
  var base = Belt_Array.getBy(plan.baseFeatures, (function (baseFeature) {
          return baseFeature.name === featureName;
        }));
  var incrementing = Belt_Array.getBy(plan.incrementingFeatures, (function (incrementingFeature) {
          return incrementingFeature.name === featureName;
        }));
  if (base === undefined) {
    if (incrementing !== undefined) {
      return 0 + Caml_int32.imul(incrementing.incrementAmount, planQuantity) | 0;
    } else {
      return 0;
    }
  }
  if (incrementing !== undefined) {
    try {
      var match = incrementing.planType;
      if (match) {
        return Caml_format.caml_int_of_string(base.value) + Caml_int32.imul(incrementing.incrementAmount, planQuantity) | 0;
      } else {
        return Caml_format.caml_int_of_string(base.value);
      }
    }
    catch (exn){
      return 0;
    }
  } else {
    try {
      return Caml_format.caml_int_of_string(base.value);
    }
    catch (exn$1){
      return 0;
    }
  }
}

function getNextPlanAddons(nextPlan, nextPlanQuantity, features) {
  var featuresThatIncrementByAddon = Belt_Array.keep(nextPlan.incrementingFeatures, (function (incrementingFeature) {
          return incrementingFeature.planType === /* Addon */0;
        }));
  return Belt_List.reduce(features, [], (function (acc, currentLimitsFeature) {
                var featureThatIncrementsByAddon = Belt_Array.getBy(featuresThatIncrementByAddon, (function (f) {
                        return f.name === currentLimitsFeature.name;
                      }));
                if (featureThatIncrementsByAddon === undefined) {
                  return acc;
                }
                try {
                  var desiredValue = Caml_format.caml_int_of_string(currentLimitsFeature.value);
                  var currentValue = getCurrentFeatureValue(nextPlan, nextPlanQuantity, featureThatIncrementsByAddon.name);
                  var numberToAdd = numberOfAddonsToAdd(desiredValue, featureThatIncrementsByAddon.incrementAmount, currentValue);
                  if (numberToAdd <= 0) {
                    return acc;
                  }
                  var newAddon_id = featureThatIncrementsByAddon.id;
                  var newAddon_quantity = numberToAdd;
                  var newAddon = {
                    id: newAddon_id,
                    quantity: newAddon_quantity
                  };
                  return Belt_Array.concat(acc, [newAddon]);
                }
                catch (exn){
                  return acc;
                }
              }));
}

function nextPlanQuantityAndAddons(limits, nextPlan) {
  var features = limits.metadata.features;
  if (features === undefined) {
    return /* tuple */[
            1,
            []
          ];
  }
  var planQuantity = getNextPlanQuantity(nextPlan, features);
  var addonsArray = getNextPlanAddons(nextPlan, planQuantity, features);
  return /* tuple */[
          planQuantity,
          addonsArray
        ];
}

exports.getPlanInfo = getPlanInfo;
exports.Currency = Currency;
exports.getPlanPrice = getPlanPrice;
exports.getFeaturePrice = getFeaturePrice;
exports.mismatchedPrice = mismatchedPrice;
exports.addPrices = addPrices;
exports.subtractPrices = subtractPrices;
exports.getTotalPlanPrice = getTotalPlanPrice;
exports.priceDifferenceOfIncrementingFeature = priceDifferenceOfIncrementingFeature;
exports.getTotalPlanPriceFromSubscription = getTotalPlanPriceFromSubscription;
exports.getPriceByPricingModel = getPriceByPricingModel;
exports.getTotalAddonsPrice = getTotalAddonsPrice;
exports.validPlans = validPlans;
exports.priceToString = priceToString;
exports.totalPriceOfPlanPlusAddons = totalPriceOfPlanPlusAddons;
exports.totalPriceString = totalPriceString;
exports.numberOfPlansToAdd = numberOfPlansToAdd;
exports.numberOfAddonsToAdd = numberOfAddonsToAdd;
exports.getNextPlanQuantity = getNextPlanQuantity;
exports.getCurrentFeatureValue = getCurrentFeatureValue;
exports.getNextPlanAddons = getNextPlanAddons;
exports.nextPlanQuantityAndAddons = nextPlanQuantityAndAddons;
/* mismatchedPrice Not a pure module */
