// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("./util.js");

function ofInt(i) {
  if (i !== 1) {
    if (i !== 2) {
      return ;
    } else {
      return /* Invalid */1;
    }
  } else {
    return /* Failed */0;
  }
}

var $$Error = {
  ofInt: ofInt
};

function Make(E) {
  var decode = function (json) {
    var result_code = Json_decode.field("code", Json_decode.$$int, json);
    var result_text = Json_decode.field("text", Json_decode.string, json);
    var result_result = Json_decode.field("result", (function (param) {
            return Json_decode.list(E.of_json, param);
          }), json);
    var i = result_code;
    if (i !== 0) {
      return /* Failure */Block.__(1, [
                result_text,
                ofInt(i)
              ]);
    } else {
      return /* Success */Block.__(0, [
                result_text,
                result_result
              ]);
    }
  };
  return {
          defaultError: /* Failure */Block.__(1, [
              "Something went wrong.",
              undefined
            ]),
          decode: decode
        };
}

function MakeCreate(E) {
  var decode = function (json) {
    var result_code = Json_decode.field("code", Json_decode.$$int, json);
    var result_text = Json_decode.field("text", Json_decode.string, json);
    var result_result = Json_decode.optional((function (param) {
            return Json_decode.field("result", E.of_json, param);
          }), json);
    var i = result_code;
    if (i !== 0) {
      return /* Failure */Block.__(1, [
                result_text,
                ofInt(i)
              ]);
    } else {
      return /* Success */Block.__(0, [
                result_text,
                result_result
              ]);
    }
  };
  return {
          defaultError: /* Failure */Block.__(1, [
              "Something went wrong.",
              undefined
            ]),
          decode: decode
        };
}

function head(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list(Util$BsConsole.identity, param);
                  }), json));
}

exports.$$Error = $$Error;
exports.Make = Make;
exports.MakeCreate = MakeCreate;
exports.head = head;
/* Util-BsConsole Not a pure module */
