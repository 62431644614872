// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var WfIntegrationIcon$BsConsole = require("./WfIntegrationIcon.js");

var component = RR$BsConsole.reducerComponent("WfIntegrationSelectItem-BsConsole");

function make(onApplicationSelected, integration, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              return React.createElement("div", {
                          style: {
                            padding: "8px"
                          }
                        }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                      className: Styles$BsConsole.$$Text.tooltipTitle
                                    }, I18N$BsConsole.showSkip(integration.description.description)), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.ButtonBase.Jsx2.make, undefined, {
                                            height: "100%",
                                            width: "100%"
                                          }, (function (param) {
                                              return Curry._1(onApplicationSelected, undefined);
                                            }), undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, {
                                                      height: "100%",
                                                      width: "100%"
                                                    }, undefined, param.state.hovered ? 4 : 2, [React.createElement("div", {
                                                            style: {
                                                              display: "flex",
                                                              height: "100%",
                                                              minHeight: "150px",
                                                              padding: "4px",
                                                              width: "100%",
                                                              flexDirection: "column"
                                                            },
                                                            onMouseEnter: (function (param) {
                                                                return Curry._1(send, /* SetHovered */[true]);
                                                              }),
                                                            onMouseLeave: (function (param) {
                                                                return Curry._1(send, /* SetHovered */[false]);
                                                              })
                                                          }, React.createElement("div", {
                                                                style: {
                                                                  padding: "12px",
                                                                  alignSelf: "center"
                                                                }
                                                              }, ReasonReact.element(undefined, undefined, WfIntegrationIcon$BsConsole.make(integration.description.icon, integration.description.title + " Icon", 52, []))), React.createElement("div", {
                                                                style: {
                                                                  borderTop: "1px solid " + Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                                                                  marginTop: "6px",
                                                                  padding: "6px 8px 0"
                                                                }
                                                              }, React.createElement("div", {
                                                                    style: {
                                                                      textAlign: "left"
                                                                    }
                                                                  }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(integration.description.title)]))), React.createElement("div", {
                                                                    style: {
                                                                      textAlign: "left"
                                                                    }
                                                                  }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(integration.description.category.name)])))))]))]))])));
            }),
          initialState: (function (param) {
              return {
                      hovered: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          hovered: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
