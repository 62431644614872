// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Tune = require("@material-ui/icons/Tune");
var Button = require("@material-ui/core/Button");
var Switch = require("@material-ui/core/Switch");
var Tooltip = require("@material-ui/core/Tooltip");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var SimilarityDistanceParameter$BsConsole = require("./SimilarityDistanceParameter.js");

function SimilarityTabActions$ParameterRow(Props) {
  var title = Props.title;
  var subtitle = Props.subtitle;
  var value = Props.value;
  var setValue = Props.setValue;
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Subtitle2 */7,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(5)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.showSkip(title)
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Overline */12,
                      className: Css.style(/* :: */[
                            Css.width(Css.px(215)),
                            /* :: */[
                              Css.marginRight(Css.px(25)),
                              /* [] */0
                            ]
                          ]),
                      color: Colors$BsConsole.grey4,
                      children: I18N$BsConsole.showSkip(subtitle)
                    })), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.marginRight(Css.px(5)),
                        /* [] */0
                      ]),
                  children: React.createElement(TextField.default, {
                        autoFocus: true,
                        disabled: false,
                        placeholder: "",
                        value: String(value),
                        color: "primary",
                        type: "number",
                        inputProps: {
                          min: 0
                        },
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            if (value > 0 && value !== "") {
                              return Curry._1(setValue, (function (param) {
                                            return Caml_format.caml_int_of_string(value);
                                          }));
                            } else {
                              return Curry._1(setValue, (function (param) {
                                            return 0;
                                          }));
                            }
                          })
                      })
                }));
}

var ParameterRow = {
  make: SimilarityTabActions$ParameterRow
};

function SimilarityTabActions$DynamicParameterRow(Props) {
  var paramType = Props.paramType;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var value = Props.value;
  var setValue = Props.setValue;
  var isDynamic = value ? false : true;
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]),
              children: React.createElement(Col2$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.px(10)),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle2 */7,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.px(5)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.showSkip(title)
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Overline */12,
                        className: Css.style(/* :: */[
                              Css.width(Css.px(215)),
                              /* :: */[
                                Css.marginRight(Css.px(25)),
                                /* [] */0
                              ]
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: I18N$BsConsole.showSkip(subtitle)
                      }), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ])
                      }, React.createElement(Tooltip.default, {
                            children: React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ])
                                }, React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      justifyContent: /* spaceBetween */516682146,
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.px(5)),
                                            /* [] */0
                                          ]),
                                      children: null
                                    }, React.createElement("div", undefined, React.createElement(InputLabel.default, {
                                              htmlFor: "dynamic-toggle-" + (String(title) + ""),
                                              children: React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Caption */11,
                                                    children: I18N$BsConsole.show(undefined, "Dynamic")
                                                  })
                                            }), React.createElement(Switch.default, {
                                              id: "dynamic-toggle-" + (String(title) + ""),
                                              color: "primary",
                                              onChange: (function (param, param$1) {
                                                  if (!isDynamic) {
                                                    return Curry._1(setValue, (function (param) {
                                                                  return /* Dynamic */0;
                                                                }));
                                                  }
                                                  var newValue = SimilarityDistanceParameter$BsConsole.Dynamic.toDefaultValue(paramType);
                                                  return Curry._1(setValue, (function (param) {
                                                                return newValue;
                                                              }));
                                                }),
                                              checked: isDynamic
                                            })), React.createElement("div", undefined, value ? React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.width(Css.px(55)),
                                                      /* [] */0
                                                    ])
                                              }, React.createElement(TextField.default, {
                                                    autoFocus: true,
                                                    placeholder: "",
                                                    value: String(value[0]),
                                                    color: "primary",
                                                    type: "number",
                                                    inputProps: {
                                                      min: 0
                                                    },
                                                    onChange: (function ($$event) {
                                                        var newValue = $$event.target.value;
                                                        if (newValue !== "" && newValue > 0) {
                                                          return Curry._1(setValue, (function (param) {
                                                                        return /* Int */[Caml_format.caml_int_of_string(newValue)];
                                                                      }));
                                                        } else {
                                                          return Curry._1(setValue, (function (param) {
                                                                        return /* Int */[0];
                                                                      }));
                                                        }
                                                      })
                                                  })) : null))),
                            title: I18N$BsConsole.get(undefined, "When enabled, value is automatically computed.")
                          })))
            });
}

var DynamicParameterRow = {
  make: SimilarityTabActions$DynamicParameterRow
};

function SimilarityTabActions(Props) {
  var computeCandidates = Props.computeCandidates;
  var match = React.useState((function () {
          return false;
        }));
  var setModal = match[1];
  var match$1 = React.useState((function () {
          return SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Threshold */0);
        }));
  var threshold = match$1[0];
  var match$2 = React.useState((function () {
          return SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Truncate */1);
        }));
  var truncate = match$2[0];
  var match$3 = React.useState((function () {
          return SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Limit */2);
        }));
  var limit = match$3[0];
  var match$4 = React.useState((function () {
          return SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Intersection */1);
        }));
  var intersection = match$4[0];
  var match$5 = React.useState((function () {
          return SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Distance */0);
        }));
  var distance = match$5[0];
  var paramValues_001 = /* :: */[
    truncate,
    /* :: */[
      limit,
      /* [] */0
    ]
  ];
  var paramValues = /* :: */[
    threshold,
    paramValues_001
  ];
  var paramSetters_000 = match$1[1];
  var paramSetters_001 = /* :: */[
    match$2[1],
    /* :: */[
      match$3[1],
      /* [] */0
    ]
  ];
  var paramSetters = /* :: */[
    paramSetters_000,
    paramSetters_001
  ];
  var dynamicParamValues_001 = /* :: */[
    distance,
    /* [] */0
  ];
  var dynamicParamValues = /* :: */[
    intersection,
    dynamicParamValues_001
  ];
  var dynamicParamSetters_000 = match$4[1];
  var dynamicParamSetters_001 = /* :: */[
    match$5[1],
    /* [] */0
  ];
  var dynamicParamSetters = /* :: */[
    dynamicParamSetters_000,
    dynamicParamSetters_001
  ];
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement(Button.default, {
                        variant: "outlined",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setModal, (function (param) {
                                          return true;
                                        }));
                          }),
                        children: null
                      }, React.createElement(Tune.default, {
                            className: Css.style(/* :: */[
                                  Css.marginRight(Css.px(5)),
                                  /* [] */0
                                ])
                          }), I18N$BsConsole.show(undefined, "Tune thresholds"))
                }), React.createElement(BtModal$BsConsole.Default.make, {
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  modalWidth: 300,
                  title: I18N$BsConsole.get(undefined, "Threshold settings"),
                  subtitle: "",
                  children: React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapWithIndex(/* :: */[
                                /* Threshold */0,
                                /* :: */[
                                  /* Truncate */1,
                                  /* :: */[
                                    /* Limit */2,
                                    /* [] */0
                                  ]
                                ]
                              ], (function (i, p) {
                                  return React.createElement(SimilarityTabActions$ParameterRow, {
                                              title: SimilarityDistanceParameter$BsConsole.Hardcoded.toTitleString(p),
                                              subtitle: SimilarityDistanceParameter$BsConsole.Hardcoded.toSubtitleString(p),
                                              value: Belt_List.getExn(paramValues, i),
                                              setValue: Belt_List.getExn(paramSetters, i)
                                            });
                                }))), Belt_List.toArray(Belt_List.mapWithIndex(/* :: */[
                                /* Intersection */1,
                                /* :: */[
                                  /* Distance */0,
                                  /* [] */0
                                ]
                              ], (function (i, p) {
                                  return React.createElement(SimilarityTabActions$DynamicParameterRow, {
                                              paramType: p,
                                              title: SimilarityDistanceParameter$BsConsole.Dynamic.toTitleString(p),
                                              subtitle: SimilarityDistanceParameter$BsConsole.Dynamic.toSubtitleString(p),
                                              value: Belt_List.getExn(dynamicParamValues, i),
                                              setValue: Belt_List.getExn(dynamicParamSetters, i)
                                            });
                                }))), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexStart */662439529,
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* [] */0
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.px(10)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    Curry._5(computeCandidates, threshold, truncate, intersection, distance, limit);
                                    return Curry._1(setModal, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Compute")
                              }), React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                onClick: (function (param) {
                                    return Curry._1(setModal, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              })))
                }));
}

function make(computeCandidates, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SimilarityTabActions, {
              computeCandidates: computeCandidates
            }, children);
}

var Re = {
  make: make
};

var make$1 = SimilarityTabActions;

exports.ParameterRow = ParameterRow;
exports.DynamicParameterRow = DynamicParameterRow;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
