// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeMissingSymbol(json) {
  return {
          timestamp: Json_decode.field("timestamp", Json_decode.$$int, json),
          debugId: Json_decode.field("debug_id", Json_decode.string, json),
          debugFile: Json_decode.field("debug_file", Json_decode.string, json)
        };
}

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("missing_symbols", (function (param) {
                              return Json_decode.list(decodeMissingSymbol, param);
                            }), json);
              }), json);
}

exports.decodeMissingSymbol = decodeMissingSymbol;
exports.decode = decode;
/* No side effect */
