// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var DocumentRe = require("bs-webapi/lib/js/src/dom/nodes/DocumentRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var IFrame$BsConsole = require("../IFrame.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtPaper$BsConsole = require("../BtPaper.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ViewNav$BsConsole = require("../ViewNav.js");
var BugShell$BsConsole = require("./BugShell.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var BugTheme$BsConsole = require("./BugTheme.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BugLoader$BsConsole = require("./BugLoader.js");
var BugFailure$BsConsole = require("./BugFailure.js");
var TriageUtils$BsConsole = require("../TriageUtils.js");
var TriageTopBar$BsConsole = require("./TriageTopBar.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("../configuration.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Styles = require("@material-ui/core/styles");
var FetchIssuesData$BsConsole = require("../FetchIssuesData.js");
var AttributesContext$BsConsole = require("../context/AttributesContext.js");

function useWindowSize(param) {
  var $$window$1 = window;
  var $$document$1 = document;
  var getWidth = function (param) {
    var innerWidth = $$window$1.innerWidth;
    var doc = DocumentRe.asHtmlDocument($$document$1);
    if (doc === undefined) {
      return innerWidth;
    }
    var body = Caml_option.valFromOption(doc).body;
    if (body == null) {
      return innerWidth;
    } else {
      return body.clientWidth;
    }
  };
  var match = React.useState((function () {
          return /* tuple */[
                  getWidth(undefined),
                  $$window$1.innerHeight
                ];
        }));
  var setDimensions = match[1];
  React.useEffect((function () {
          var cb = function (param) {
            return Curry._1(setDimensions, (function (param) {
                          return /* tuple */[
                                  getWidth(undefined),
                                  $$window$1.innerHeight
                                ];
                        }));
          };
          if (!IFrame$BsConsole.isIFrame) {
            $$window$1.addEventListener("resize", cb);
          }
          cb(undefined);
          if (!IFrame$BsConsole.isIFrame) {
            return (function (param) {
                      $$window$1.removeEventListener("resize", cb);
                      
                    });
          }
          
        }), ([]));
  return match[0];
}

var arrowRoot = Css.style(/* :: */[
      Css.unsafe("color", "inherit"),
      /* [] */0
    ]);

var arrowDisabled = Css.style(/* :: */[
      Css.opacity(0.55),
      /* [] */0
    ]);

var paper = Css.merge(/* :: */[
      BtPaper$BsConsole.mainViewMargins,
      /* :: */[
        Css.style(/* :: */[
              Css.marginBottom(Css.px(150)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Styles$1 = {
  arrowRoot: arrowRoot,
  arrowDisabled: arrowDisabled,
  paper: paper
};

function BugRoot$Default(Props) {
  var projectName = Props.projectName;
  var config = Props.config;
  var aperture = Props.aperture;
  var initialSelection = Props.initialSelection;
  var route = Props.route;
  var view = Props.view;
  var token = Props.token;
  var error = Props.error;
  var errors = Props.errors;
  var errorIndex = Props.errorIndex;
  var setErrorIndex = Props.setErrorIndex;
  var project = Configuration$BsConsole.getProject(projectName, config);
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var fingerprint = error.fingerprint;
  var errorId = error.errorId;
  var match$1 = useWindowSize(undefined);
  var containerWidth = match$1[0] - 32 | 0;
  var containerHeight = Caml_primitive.caml_int_max(((match$1[1] - 32 | 0) - 112 | 0) - 26 | 0, 790);
  var onAddFilter = React.useCallback((function (filter) {
          return Curry._1(handleChangeUrl, /* ProjectDebugger2 */Block.__(19, [
                        projectName,
                        {
                          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture),
                          state: initialSelection
                        }
                      ]));
        }), /* tuple */[
        handleChangeUrl,
        initialSelection,
        aperture
      ]);
  var attributes = React.useContext(AttributesContext$BsConsole.ctx).active;
  var query = TriageUtils$BsConsole.makeQuery(/* [] */0, Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    "fingerprint",
                    /* Equal */Block.__(0, [/* `String */[
                          -976970511,
                          fingerprint
                        ]])
                  ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture), Crdb$BsConsole.Havings.empty, view, undefined, attributes, Crdb$BsConsole.Fold.empty);
  var match$2 = FetchIssuesData$BsConsole.use(projectName, token, query);
  var triageGroupRemote = match$2[0];
  var triageGroup;
  if (typeof triageGroupRemote === "number") {
    triageGroup = undefined;
  } else if (triageGroupRemote.tag) {
    Backtrace$BsConsole.Client.reportWithSourceModule("debugger2/BugRoot", new Error(triageGroupRemote[0]));
    triageGroup = undefined;
  } else {
    triageGroup = Belt_Array.get(triageGroupRemote[0].triageGroups, 0);
  }
  React.useEffect((function () {
          var errorId = Belt_Option.map(initialSelection, (function (is) {
                  return is.errorId;
                }));
          if (errorId !== undefined) {
            var index = errors.findIndex((function (ei) {
                    return ei.errorId === errorId;
                  }));
            if (index !== -1) {
              Curry._1(setErrorIndex, (function (param) {
                      return index;
                    }));
            }
            
          }
          
        }), [errors]);
  var tmp;
  if (errors.length !== 0) {
    var err = Belt_Array.get(errors, errorIndex % 20);
    tmp = err !== undefined ? React.createElement(BugShell$BsConsole.make, {
            config: config,
            aperture: aperture,
            width: containerWidth,
            height: containerHeight,
            errorInfo: err,
            initialSelection: initialSelection,
            view: view,
            token: token
          }) : null;
  } else {
    tmp = React.createElement(BugFailure$BsConsole.make, {
          children: null
        }, React.createElement("div", {
              className: Css.style(/* [] */0)
            }, I18N$BsConsole.show(undefined, "No errors found within the selected timeframe and filters.")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Check your filters or try expanding your timeframe.")));
  }
  return React.createElement(Paper.default, {
              className: paper,
              children: null
            }, React.createElement(ViewNav$BsConsole.make, {
                  config: config,
                  aperture: aperture,
                  route: route,
                  handleChangeUrl: handleChangeUrl,
                  projectName: projectName
                }), triageGroup !== undefined ? (
                project !== undefined ? React.createElement(TriageTopBar$BsConsole.make, {
                        config: config,
                        onAddFilter: onAddFilter,
                        triageGroup: triageGroup,
                        fetchIssues: match$2[1],
                        aperture: aperture,
                        fingerprint: fingerprint,
                        project: project,
                        errorData: /* tuple */[
                          errorIndex,
                          setErrorIndex,
                          errorId
                        ]
                      }) : React.createElement(TriageTopBar$BsConsole.EmptyState.make, { })
              ) : React.createElement(TriageTopBar$BsConsole.EmptyState.make, { }), React.createElement(Styles.MuiThemeProvider, {
                  theme: BugTheme$BsConsole.green,
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.height(Css.px(containerHeight)),
                              /* :: */[
                                Css.paddingTop(Css.zero),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey2)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, tmp)
                }));
}

var Default = {
  make: BugRoot$Default
};

function BugRoot(Props) {
  var projectName = Props.projectName;
  var config = Props.config;
  var aperture = Props.aperture;
  var initialSelection = Props.initialSelection;
  var route = Props.route;
  var view = Props.view;
  var token = Props.token;
  var match = React.useState((function () {
          return 0;
        }));
  var errorIndex = match[0];
  var errors = BugTasks$BsConsole.useErrors(aperture, errorIndex / 20 | 0);
  var errorMessage = function (message) {
    return React.createElement(Col2$BsConsole.make, {
                alignItems: /* center */98248149,
                className: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.paddingTop(Css.px(80)),
                          /* [] */0
                        ]
                      ]
                    ]),
                children: React.createElement(Col2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      children: React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.paddingBottom(Css.px(8)),
                                  /* :: */[
                                    Css.fontSize(Css.px(20)),
                                    /* :: */[
                                      Css.color(/* `rgb */[
                                            5692173,
                                            /* tuple */[
                                              0,
                                              0,
                                              0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.dynamic(message))
                    })
              });
  };
  var match$1 = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match$1.handleChangeUrl;
  var match$2 = useWindowSize(undefined);
  var containerHeight = Caml_primitive.caml_int_max(((match$2[1] - 32 | 0) - 112 | 0) - 26 | 0, 790);
  if (typeof errors === "number") {
    if (errors === /* NotAsked */0) {
      return null;
    }
    
  } else {
    if (errors.tag) {
      var e = errors[0];
      if (e[0] === Task2$BsConsole.CoronerError) {
        return errorMessage(e[1].message);
      } else {
        return errorMessage("Unknown error");
      }
    }
    var match$3 = errors[0];
    var match$4 = match$3[1];
    if (typeof match$4 !== "number") {
      return null;
    }
    if (match$4 === 0) {
      var errors$1 = match$3[0][0];
      var error = Belt_Array.get(errors$1, errorIndex % 20);
      if (error !== undefined) {
        return React.createElement(BugRoot$Default, {
                    projectName: projectName,
                    config: config,
                    aperture: aperture,
                    initialSelection: initialSelection,
                    route: route,
                    view: view,
                    token: token,
                    error: error,
                    errors: errors$1,
                    errorIndex: errorIndex,
                    setErrorIndex: match[1]
                  });
      } else {
        return React.createElement(Paper.default, {
                    className: paper,
                    children: null
                  }, React.createElement(ViewNav$BsConsole.make, {
                        config: config,
                        aperture: aperture,
                        route: route,
                        handleChangeUrl: handleChangeUrl,
                        projectName: projectName
                      }), React.createElement(Styles.MuiThemeProvider, {
                        theme: BugTheme$BsConsole.green,
                        children: React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.height(Css.px(containerHeight)),
                                    /* :: */[
                                      Css.paddingTop(Css.zero),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            }, React.createElement(BugFailure$BsConsole.make, {
                                  children: null
                                }, React.createElement("div", {
                                      className: Css.style(/* [] */0)
                                    }, I18N$BsConsole.show(undefined, "No errors found within the selected timeframe and filters.")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Check your filters or try expanding your timeframe."))))
                      }));
      }
    }
    
  }
  return React.createElement(Paper.default, {
              className: paper,
              children: null
            }, React.createElement(ViewNav$BsConsole.make, {
                  config: config,
                  aperture: aperture,
                  route: route,
                  handleChangeUrl: handleChangeUrl,
                  projectName: projectName
                }), React.createElement(TriageTopBar$BsConsole.EmptyState.make, { }), React.createElement(Styles.MuiThemeProvider, {
                  theme: BugTheme$BsConsole.green,
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.height(Css.px(containerHeight)),
                              /* :: */[
                                Css.paddingTop(Css.zero),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey2)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, React.createElement(BugLoader$BsConsole.make, {
                            children: I18N$BsConsole.show(undefined, "Fetching errors matching filters and selected timeframe")
                          }))
                }));
}

function make(projectName, config, aperture, initialSelection, route, view, token, param) {
  return ReasonReactCompat.wrapReactForReasonReact(BugRoot, {
              projectName: projectName,
              config: config,
              aperture: aperture,
              initialSelection: initialSelection,
              route: route,
              view: view,
              token: token
            }, []);
}

var Re = {
  make: make
};

var make$1 = BugRoot;

exports.useWindowSize = useWindowSize;
exports.Styles = Styles$1;
exports.Default = Default;
exports.make = make$1;
exports.Re = Re;
/* arrowRoot Not a pure module */
