// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Validator = require("validator");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("../util.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function errorToString(error) {
  if (typeof error === "number") {
    switch (error) {
      case /* Required */0 :
          return "This is a required field";
      case /* UserNotFound */1 :
          return "User with such username does not exist";
      case /* ArrayNotUnique */2 :
          return "Values must be unique";
      case /* InvalidInteger */3 :
          return "Must be a valid integer";
      case /* InvalidFloat */4 :
          return "Must be a valid number";
      case /* InvalidUrl */5 :
          return "Must be a valid url";
      case /* StringRegexMatch */6 :
          return "Does not match the pattern";
      case /* NameIsNotUnique */7 :
          return "Name already exists";
      case /* UnknownError */8 :
          return "Unknown error";
      
    }
  } else {
    switch (error.tag | 0) {
      case /* StringMinLength */0 :
          var len = error[0];
          var characterWord = len === 1 ? "character" : "characters";
          return "Must be at least " + (String(len) + (" " + (String(characterWord) + " long")));
      case /* StringMaxLength */1 :
          var len$1 = error[0];
          var characterWord$1 = len$1 === 1 ? "character" : "characters";
          return "Must be at most " + (String(len$1) + (" " + (String(characterWord$1) + " long")));
      case /* StringInputType */2 :
          var match = error[0];
          if (match >= 3) {
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "WfCore.re",
                    58,
                    26
                  ]
                ];
          }
          switch (match) {
            case /* Password */0 :
                throw [
                      Caml_builtin_exceptions.assert_failure,
                      /* tuple */[
                        "WfCore.re",
                        58,
                        26
                      ]
                    ];
            case /* Email */1 :
                return "Must be a valid email";
            case /* Uri */2 :
                return "Must be a valid uri";
            
          }
      case /* ArrayMinLength */3 :
          var len$2 = error[0];
          var entryWord = len$2 === 1 ? "entry" : "entries";
          return "Must have at least " + (String(len$2) + (" " + (String(entryWord) + "")));
      case /* ArrayMaxLength */4 :
          var len$3 = error[0];
          var entryWord$1 = len$3 === 1 ? "entry" : "entries";
          return "Must have at most " + (String(len$3) + (" " + (String(entryWord$1) + "")));
      
    }
  }
}

function make(required, defaultValue, min, max, placeholder, uiWidget, param) {
  return {
          required: required,
          defaultValue: defaultValue,
          min: min,
          max: max,
          placeholder: placeholder,
          uiWidget: uiWidget
        };
}

function validate(schema, value) {
  if (!schema.required || Belt_Option.isSome(value)) {
    return ;
  } else {
    return /* Required */0;
  }
}

var Int = {
  make: make,
  validate: validate
};

function make$1(required, defaultValue, min, max, placeholder, param) {
  return {
          required: required,
          defaultValue: defaultValue,
          min: min,
          max: max,
          placeholder: placeholder
        };
}

function validate$1(schema, value) {
  if (!schema.required || Belt_Option.isSome(value)) {
    return ;
  } else {
    return /* Required */0;
  }
}

var Float = {
  make: make$1,
  validate: validate$1
};

function make$2(required, defaultValue, labels, uiWidget, placeholder, param) {
  return {
          required: required,
          labels: labels,
          uiWidget: uiWidget,
          defaultValue: defaultValue,
          placeholder: placeholder
        };
}

function validate$2(schema, value) {
  if (!schema.required || Belt_Option.isSome(value)) {
    return ;
  } else {
    return /* Required */0;
  }
}

var Bool = {
  make: make$2,
  validate: validate$2
};

function makeItem(label, value, param) {
  return {
          label: label,
          value: value
        };
}

function make$3(items, required, defaultValue, placeholder, param) {
  return {
          items: items,
          required: required,
          defaultValue: defaultValue,
          placeholder: placeholder
        };
}

function validate$3(schema, value) {
  if (!schema.required || Belt_Option.isSome(value)) {
    return ;
  } else {
    return /* Required */0;
  }
}

var Enum = {
  makeItem: makeItem,
  make: make$3,
  validate: validate$3
};

function make$4(required, defaultValue, minLength, maxLength, regex, inputType, uiPopulatedType, uiWidget, placeholder, param) {
  return {
          defaultValue: defaultValue,
          required: required,
          minLength: minLength,
          maxLength: maxLength,
          regex: regex,
          inputType: inputType,
          uiPopulatedType: uiPopulatedType,
          uiWidget: uiWidget,
          placeholder: placeholder
        };
}

function validate$4(schema, value) {
  if (value === undefined) {
    if (schema.required) {
      return /* Required */0;
    } else {
      return ;
    }
  }
  var err = Belt_Result.flatMap(Belt_Result.flatMap(Belt_Result.flatMap(Belt_Result.flatMap(/* Ok */Block.__(0, [value]), (function (v) {
                      var minLength = schema.minLength;
                      if (minLength !== undefined && v.length < minLength) {
                        return /* Error */Block.__(1, [/* StringMinLength */Block.__(0, [minLength])]);
                      } else {
                        return /* Ok */Block.__(0, [v]);
                      }
                    })), (function (v) {
                  var maxLength = schema.maxLength;
                  if (maxLength !== undefined && v.length > maxLength) {
                    return /* Error */Block.__(1, [/* StringMaxLength */Block.__(1, [maxLength])]);
                  } else {
                    return /* Ok */Block.__(0, [v]);
                  }
                })), (function (v) {
              var match = schema.inputType;
              if (match !== undefined && !(match !== 2 || Validator.isURL(v))) {
                return /* Error */Block.__(1, [/* StringInputType */Block.__(2, [/* Uri */2])]);
              } else {
                return /* Ok */Block.__(0, [v]);
              }
            })), (function (v) {
          var regex = schema.regex;
          if (regex !== undefined && !Caml_option.valFromOption(regex).test(v)) {
            return /* Error */Block.__(1, [/* StringRegexMatch */6]);
          } else {
            return /* Ok */Block.__(0, [v]);
          }
        }));
  if (err.tag) {
    return err[0];
  }
  
}

var $$String$1 = {
  make: make$4,
  validate: validate$4
};

function uniqueArray(arr) {
  var cmp = Caml_obj.caml_compare;
  var Comparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var requestedSet = Belt_Set.fromArray(arr, Comparator);
  return Belt_Set.size(requestedSet) === arr.length;
}

function validateArray(arraySchema, value) {
  var err = Belt_Result.flatMap(Belt_Result.flatMap(Belt_Result.flatMap(/* Ok */Block.__(0, [value]), (function (v) {
                  var minLength = arraySchema.minLength;
                  if (minLength !== undefined && v.length < minLength) {
                    return /* Error */Block.__(1, [/* ArrayMinLength */Block.__(3, [minLength])]);
                  } else {
                    return /* Ok */Block.__(0, [v]);
                  }
                })), (function (v) {
              var maxLength = arraySchema.maxLength;
              if (maxLength !== undefined && v.length > maxLength) {
                return /* Error */Block.__(1, [/* ArrayMaxLength */Block.__(4, [maxLength])]);
              } else {
                return /* Ok */Block.__(0, [v]);
              }
            })), (function (v) {
          if (arraySchema.unique && !uniqueArray(v)) {
            return /* Error */Block.__(1, [/* ArrayNotUnique */2]);
          } else {
            return /* Ok */Block.__(0, [v]);
          }
        }));
  if (err.tag) {
    return err[0];
  }
  
}

function validateFieldRecursively(schema, value) {
  try {
    switch (schema.tag | 0) {
      case /* Int */0 :
          return Belt_Option.mapWithDefault(validate(schema[0], Belt_Option.map(value, Json_decode.$$int)), [], (function (err) {
                        return [err];
                      }));
      case /* Float */1 :
          return Belt_Option.mapWithDefault(validate$1(schema[0], Belt_Option.map(value, Json_decode.$$float)), [], (function (err) {
                        return [err];
                      }));
      case /* Bool */2 :
          return Belt_Option.mapWithDefault(validate$2(schema[0], Belt_Option.map(value, Json_decode.bool)), [], (function (err) {
                        return [err];
                      }));
      case /* Enum */3 :
          return Belt_Option.mapWithDefault(validate$3(schema[0], value), [], (function (err) {
                        return [err];
                      }));
      case /* String */4 :
          return Belt_Option.mapWithDefault(validate$4(schema[0], Belt_Option.map(value, Json_decode.string)), [], (function (err) {
                        return [err];
                      }));
      case /* Array */5 :
          var __x = Belt_Option.getExn(value);
          return validateArrayRecursively(schema[0], Json_decode.array(Util$BsConsole.identity, __x));
      case /* Object */6 :
          var __x$1 = Belt_Option.getExn(value);
          return validateObjectRecursively(schema[0], Json_decode.dict(Util$BsConsole.identity, __x$1));
      case /* CustomField */7 :
          return Belt_Option.mapWithDefault(validate$4(schema[0], Belt_Option.map(value, Json_decode.string)), [], (function (err) {
                        return [err];
                      }));
      
    }
  }
  catch (exn){
    return [/* UnknownError */8];
  }
}

function validateArrayRecursively(arraySchema, value) {
  var arrayErrs = validateArray(arraySchema, value);
  var childErrs = Belt_Array.concatMany(Belt_Array.map(value, (function (v) {
              return validateFieldRecursively(arraySchema.childSchema, Caml_option.some(v));
            })));
  return Belt_Array.concat(Belt_Option.mapWithDefault(arrayErrs, [], (function (err) {
                    return [err];
                  })), childErrs);
}

function validateObjectRecursively(objectSchema, value) {
  return Belt_Array.concatMany(Belt_Array.map(objectSchema.fields, (function (field) {
                    var formField = field.outputType;
                    return validateFieldRecursively(formField.schema, Js_dict.get(value, field.name));
                  })));
}

function field(title, description, schema, param) {
  return {
          doc: {
            title: title,
            description: description
          },
          schema: schema
        };
}

function array(childSchema, unique, minLength, maxLength, orderable, param) {
  return {
          unique: unique,
          childSchema: childSchema,
          orderable: orderable,
          minLength: minLength,
          maxLength: maxLength
        };
}

function objectField(name, outputType, param) {
  return {
          name: name,
          outputType: outputType
        };
}

function objectSchema(fields, param) {
  return {
          fields: fields
        };
}

function hasRequiredFields(_schema) {
  while(true) {
    var schema = _schema;
    switch (schema.tag | 0) {
      case /* Int */0 :
      case /* Float */1 :
      case /* Bool */2 :
          return schema[0].required;
      case /* Array */5 :
          var arraySchema = schema[0];
          if (Belt_Option.mapWithDefault(arraySchema.minLength, false, (function (minLength) {
                    return minLength > 0;
                  }))) {
            return true;
          }
          _schema = arraySchema.childSchema;
          continue ;
      case /* Object */6 :
          return Belt_Array.some(schema[0].fields, (function (field) {
                        var formField = field.outputType;
                        return hasRequiredFields(formField.schema);
                      }));
      default:
        return schema[0].required;
    }
  };
}

function pluckKeys(keys, json) {
  return Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, json)), (function (param) {
                    var key = param[0];
                    return Belt_Array.some(keys, (function (k) {
                                  return k === key;
                                }));
                  })));
}

function pluckSchemaKeys(schema, json) {
  return pluckKeys(Belt_Array.map(schema.fields, (function (field) {
                    return field.name;
                  })), json);
}

function scrollToDomElement(element) {
  var el = document.querySelector("#workflow-scroll-container");
  if (el == null) {
    var top = element.getBoundingClientRect().top + scrollY - window.innerHeight / 4;
    scroll({
          top: top,
          behavior: "smooth"
        });
    return ;
  }
  var scrollY$1 = el.scrollTop;
  var top$1 = element.getBoundingClientRect().top + scrollY$1 - window.innerHeight / 4;
  return el.scroll({
              top: top$1,
              behavior: "smooth"
            });
}

exports.errorToString = errorToString;
exports.Int = Int;
exports.Float = Float;
exports.Bool = Bool;
exports.Enum = Enum;
exports.$$String = $$String$1;
exports.uniqueArray = uniqueArray;
exports.validateArray = validateArray;
exports.validateFieldRecursively = validateFieldRecursively;
exports.validateArrayRecursively = validateArrayRecursively;
exports.validateObjectRecursively = validateObjectRecursively;
exports.field = field;
exports.array = array;
exports.objectField = objectField;
exports.objectSchema = objectSchema;
exports.hasRequiredFields = hasRequiredFields;
exports.pluckKeys = pluckKeys;
exports.pluckSchemaKeys = pluckSchemaKeys;
exports.scrollToDomElement = scrollToDomElement;
/* validator Not a pure module */
