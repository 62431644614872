// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");

var metricsNamespace = "breadcrumbs/";

function toEvent(action) {
  switch (action) {
    case /* TabClick */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "breadcrumbs/tab_click",
                    payload: undefined
                  }]);
    case /* FilterType */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "breadcrumbs/filter_type",
                    payload: undefined
                  }]);
    case /* FilterLevel */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "breadcrumbs/filter_level",
                    payload: undefined
                  }]);
    case /* ExpandAttributes */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "breadcrumbs/expand_attributes",
                    payload: undefined
                  }]);
    case /* Search */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: "breadcrumbs/search",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
