// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var RR$BsConsole = require("./RR.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Tooltip = require("@material-ui/core/Tooltip");

var customStyle = Css.style(/* :: */[
      Css.color(Css.hex("123624")),
      /* :: */[
        Css.background(Css.hex("FFCE48")),
        /* [] */0
      ]
    ]);

var triageStyle = Css.style(/* :: */[
      Css.color(Css.hex("123624")),
      /* :: */[
        Css.background(Css.hex("3EDC91")),
        /* [] */0
      ]
    ]);

var defaultStyle = Css.style(/* :: */[
      Css.color(Css.hex("1B1B1B")),
      /* :: */[
        Css.background(Css.hex("547581")),
        /* [] */0
      ]
    ]);

var unusedStyle = Css.style(/* :: */[
      Css.color(Css.hex("123624")),
      /* :: */[
        Css.background(Css.hex("B6BECC")),
        /* [] */0
      ]
    ]);

var recentStyle = Css.style(/* :: */[
      Css.color(Css.hex("123624")),
      /* :: */[
        Css.background(Css.hex("B6BECC")),
        /* [] */0
      ]
    ]);

var pill = Css.style(/* :: */[
      Css.fontSize(Css.px(10)),
      /* :: */[
        Css.borderRadius(Css.px(2)),
        /* :: */[
          Css.padding2(Css.px(2), Css.px(3)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  500
                ]),
            /* :: */[
              Css.marginLeft(Css.px(4)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function FilterPills$Base(Props) {
  var text = Props.text;
  var tooltip = Props.tooltip;
  var style = Props.style;
  return React.createElement(Tooltip.default, {
              placement: "top",
              enterDelay: 500,
              children: React.createElement("span", {
                    className: Css.merge(/* :: */[
                          pill,
                          /* :: */[
                            style,
                            /* [] */0
                          ]
                        ])
                  }, I18N$BsConsole.show(undefined, text)),
              title: I18N$BsConsole.get(undefined, tooltip)
            });
}

var Base = {
  customStyle: customStyle,
  triageStyle: triageStyle,
  defaultStyle: defaultStyle,
  unusedStyle: unusedStyle,
  recentStyle: recentStyle,
  pill: pill,
  make: FilterPills$Base
};

function FilterPills$Recent(Props) {
  return React.createElement(FilterPills$Base, {
              text: "Recent",
              tooltip: "Recently used for filtering in this project",
              style: recentStyle
            });
}

var component = RR$BsConsole.statelessComponent("FilterPills-BsConsole");

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterPills$Recent, { }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var Recent = {
  make: FilterPills$Recent,
  Jsx2: Jsx2
};

function FilterPills$Unused(Props) {
  return React.createElement(FilterPills$Base, {
              text: "Unused",
              tooltip: "This attribute has not received data for any errors or crashes",
              style: unusedStyle
            });
}

var component$1 = RR$BsConsole.statelessComponent("FilterPills-BsConsole");

function make$1(children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterPills$Unused, { }, children);
}

var Jsx2$1 = {
  component: component$1,
  make: make$1
};

var Unused = {
  make: FilterPills$Unused,
  Jsx2: Jsx2$1
};

function FilterPills$Custom(Props) {
  return React.createElement(FilterPills$Base, {
              text: "Custom",
              tooltip: "An attribute that was added specifically for this project",
              style: customStyle
            });
}

var component$2 = RR$BsConsole.statelessComponent("FilterPills-BsConsole");

function make$2(children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterPills$Custom, { }, children);
}

var Jsx2$2 = {
  component: component$2,
  make: make$2
};

var Custom = {
  make: FilterPills$Custom,
  Jsx2: Jsx2$2
};

function FilterPills$Triage(Props) {
  return React.createElement(FilterPills$Base, {
              text: "Triage",
              tooltip: "An attribute used for managing a group of errors or crashes",
              style: triageStyle
            });
}

var component$3 = RR$BsConsole.statelessComponent("FilterPills-BsConsole");

function make$3(children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterPills$Triage, { }, children);
}

var Jsx2$3 = {
  component: component$3,
  make: make$3
};

var Triage = {
  make: FilterPills$Triage,
  Jsx2: Jsx2$3
};

function FilterPills$Default(Props) {
  return React.createElement(FilterPills$Base, {
              text: "Default",
              tooltip: "A standard attribute included with all Backtrace projects",
              style: defaultStyle
            });
}

var component$4 = RR$BsConsole.statelessComponent("FilterPills-BsConsole");

function make$4(children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterPills$Default, { }, children);
}

var Jsx2$4 = {
  component: component$4,
  make: make$4
};

var Default = {
  make: FilterPills$Default,
  Jsx2: Jsx2$4
};

exports.Base = Base;
exports.Recent = Recent;
exports.Unused = Unused;
exports.Custom = Custom;
exports.Triage = Triage;
exports.Default = Default;
/* customStyle Not a pure module */
