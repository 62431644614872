// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfForm$BsConsole = require("./WfForm.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var WfTasks$BsConsole = require("./WfTasks.js");
var WfLayout$BsConsole = require("./WfLayout.js");
var WfMetrics$BsConsole = require("./WfMetrics.js");
var WfWatcher$BsConsole = require("./WfWatcher.js");
var WfNameField$BsConsole = require("./WfNameField.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var WatcherNewTask$BsConsole = require("../WatcherNewTask.js");
var WfBehaviorForm$BsConsole = require("./WfBehaviorForm.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");
var WfWatcherStepper$BsConsole = require("./WfWatcherStepper.js");
var WfIntegrationFull$BsConsole = require("./WfIntegrationFull.js");
var WfIntegrationIcon$BsConsole = require("./WfIntegrationIcon.js");
var WfAdvancedSettings$BsConsole = require("./WfAdvancedSettings.js");
var WfCustomizePayload$BsConsole = require("./WfCustomizePayload.js");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

function makeUpdateTask(updatedWatcher, updatedFields, config, projectName) {
  var match = WorkflowVersion$BsConsole.current(config);
  if (typeof match === "number" && match !== 0) {
    return Caml_option.some(BpgTask$BsConsole.WatcherNew.updateFields(updatedWatcher, updatedFields));
  } else {
    return WatcherNewTask$BsConsole.update(updatedWatcher, updatedFields, config, projectName);
  }
}

function isStepSupported(step, version, integration) {
  switch (step) {
    case /* SelectIntegration */0 :
        return false;
    case /* ConfigureConnection */1 :
    case /* CustomizePayload */2 :
        return true;
    case /* SetupBehavior */3 :
        if (typeof version === "number") {
          return version !== 0;
        } else {
          return false;
        }
    case /* AdvancedSettings */4 :
        var match = integration.description.advanced_settings;
        if (typeof version === "number" && version !== 0) {
          return true;
        } else {
          return match !== undefined;
        }
    
  }
}

function getSupportedSteps(version, integration) {
  return Belt_List.reduce(/* :: */[
              /* ConfigureConnection */1,
              /* :: */[
                /* CustomizePayload */2,
                /* :: */[
                  /* SetupBehavior */3,
                  /* :: */[
                    /* AdvancedSettings */4,
                    /* [] */0
                  ]
                ]
              ]
            ], WfWatcherStepper$BsConsole.Steps.empty(undefined), (function (steps, step) {
                if (isStepSupported(step, version, integration)) {
                  return Pervasives.$at(steps, /* :: */[
                              step,
                              /* [] */0
                            ]);
                } else {
                  return steps;
                }
              }));
}

var component = RR$BsConsole.reducerComponent("WfWatcherEditStepper-BsConsole");

function make(btDefaults, step, allWatchers, onStepChange, token, handleTask, project, watcher, onEditingCompleted, onCancelEditing, integration, serviceEndpoint, universeName, config, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var steps = state.steps;
              var send = param.send;
              var tmp;
              switch (step) {
                case /* SelectIntegration */0 :
                    tmp = null;
                    break;
                case /* ConfigureConnection */1 :
                    var json = state.connection;
                    var tmp$1;
                    tmp$1 = json.tag ? undefined : Caml_option.some(json[0]);
                    tmp = ReasonReact.element(undefined, undefined, WfForm$BsConsole.make(btDefaults, token, integration.forms.configureConnection, (function (action) {
                                state.highlightConnectionErrors.contents = action;
                                
                              }), (function (fv) {
                                return Curry._1(send, /* UpdateConnection */Block.__(1, [fv]));
                              }), undefined, tmp$1, (function (showAllErrors) {
                                return ReasonReact.element(undefined, undefined, WfNameField$BsConsole.make(project.name, allWatchers, watcher.watcherName, showAllErrors, /* Ok */Block.__(0, [watcher.userFriendlyName]), (function (prim) {
                                                  
                                                }), true, undefined, []));
                              }), []));
                    break;
                case /* CustomizePayload */2 :
                    tmp = ReasonReact.element(undefined, undefined, WfCustomizePayload$BsConsole.make(state.payload, (function (customizePayload) {
                                return Curry._1(send, /* UpdatePayload */Block.__(2, [customizePayload]));
                              }), project.name, handleTask, token, []));
                    break;
                case /* SetupBehavior */3 :
                    var match = WorkflowVersion$BsConsole.current(config);
                    tmp = typeof match === "number" && match !== 0 ? ReasonReact.element(undefined, undefined, WfBehaviorForm$BsConsole.make(universeName, watcher.watcherName, integration, state.behavior, (function (behavior) {
                                  return Curry._1(send, /* UpdateBehavior */Block.__(4, [behavior]));
                                }), serviceEndpoint, [])) : null;
                    break;
                case /* AdvancedSettings */4 :
                    tmp = ReasonReact.element(undefined, undefined, WfAdvancedSettings$BsConsole.make(state.advancedSettings, (function (advancedSettings) {
                                return Curry._1(send, /* UpdateAdvancedSettings */Block.__(3, [advancedSettings]));
                              }), token, handleTask, project.name, integration.description.advanced_settings, []));
                    break;
                
              }
              return ReasonReact.element(undefined, undefined, WfLayout$BsConsole.make((function (param) {
                                return Curry._1(onCancelEditing, undefined);
                              }), "90deg", ReasonReact.element(undefined, undefined, Curry._5(WfWatcherStepper$BsConsole.UpperNavigation.Jsx2.make, steps, step, (function (param, step) {
                                        return Curry._1(send, /* GoToStep */Block.__(0, [step]));
                                      }), undefined, [])), [React.createElement("div", {
                                    style: {
                                      padding: "24px"
                                    }
                                  }, React.createElement("div", {
                                        style: {
                                          minHeight: "100px",
                                          position: "relative"
                                        }
                                      }, React.createElement("div", {
                                            style: {
                                              position: "absolute",
                                              right: "12px",
                                              top: "8px"
                                            }
                                          }, ReasonReact.element(undefined, undefined, WfIntegrationIcon$BsConsole.make(integration.description.icon, integration.description.title, 56, []))), tmp), ReasonReact.element(undefined, undefined, Curry._6(WfWatcherStepper$BsConsole.BottomNavigation.Jsx2.make, steps, step, (function (param, step) {
                                              return Curry._1(send, /* GoToStep */Block.__(0, [step]));
                                            }), (function (param, step) {
                                              return Curry._1(send, /* GoToStep */Block.__(0, [step]));
                                            }), (function (param) {
                                              return Curry._1(send, /* Submit */2);
                                            }), [])))]));
            }),
          initialState: (function (param) {
              return {
                      initialFormValue: watcher.settings,
                      maxStep: /* ConfigureConnection */1,
                      steps: getSupportedSteps(WorkflowVersion$BsConsole.current(config), integration),
                      connection: /* Ok */Block.__(0, [WfCore$BsConsole.pluckSchemaKeys(integration.forms.configureConnection.schema, watcher.settings)]),
                      payload: /* Ok */Block.__(0, [WfCore$BsConsole.pluckKeys([
                                "include_hostname",
                                "display_settings"
                              ], watcher.settings)]),
                      behavior: WfBehaviorForm$BsConsole.fromWatcher(watcher),
                      advancedSettings: /* Ok */Block.__(0, [WfCore$BsConsole.pluckKeys(["_actions"], watcher.settings)]),
                      highlightConnectionErrors: {
                        contents: undefined
                      },
                      highlightPayloadErrors: {
                        contents: undefined
                      },
                      highlightAdvancedSettingsErrors: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* Cancel */0 :
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    return Curry._1(onCancelEditing, undefined);
                                  })]);
                  case /* TestConnection */1 :
                      return /* NoUpdate */0;
                  case /* Submit */2 :
                      var match = WorkflowVersion$BsConsole.current(config);
                      if (typeof match === "number") {
                        if (match !== 0) {
                          var match$1 = state.connection;
                          var match$2 = state.payload;
                          var match$3 = state.advancedSettings;
                          if (!match$1.tag) {
                            var f1 = match$1[0];
                            if (!match$2.tag) {
                              var f2 = match$2[0];
                              if (!match$3.tag) {
                                var f3 = match$3[0];
                                return /* SideEffects */Block.__(1, [(function (param) {
                                              var formValue = Object.assign(Object.assign(Object.assign(Object.assign({ }, f1), f2), f3), {
                                                    manual_sync: state.behavior.dataSynchronization,
                                                    two_way_sync: state.behavior.dataSynchronization2Way
                                                  });
                                              var updatedWatcher = WfWatcher$BsConsole.toBpg(WfWatcher$BsConsole.make(undefined, watcher.userFriendlyName, project, watcher.watcherName, WfBehaviorForm$BsConsole.toBehavior(state.behavior.config), formValue, project.owner, integration.description.path));
                                              var __x = BacktraceParserGenerator$BsConsole.WatcherNew.to_json(updatedWatcher);
                                              var updatedFields = Belt_Array.keep(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, __x)), (function (param) {
                                                      var key = param[0];
                                                      if (key !== "name" && key !== "project" && key !== "owner" && key !== "__state" && key !== "__create") {
                                                        return key !== "__modify";
                                                      } else {
                                                        return false;
                                                      }
                                                    }));
                                              var match = integration.description.type_;
                                              if (match === "sca") {
                                                var scaEndpointMaybe = Json_decode.optional((function (param) {
                                                        return Json_decode.field("sca-service-endpoint", Json_decode.string, param);
                                                      }), btDefaults);
                                                if (scaEndpointMaybe !== undefined) {
                                                  var arg = WfTasks$BsConsole.ScaBackfill.put(scaEndpointMaybe, universeName, f1.crashProject, f1.crashProjectToken, f1.defectProject, f1.defectProjectToken, f1.queryUrl, f1.ignoreFiles, f1.ignoreFunctions, f1.limitFiles, f1.limitFunctions, f1.classifierMatch, f1.matchColumn);
                                                  Curry._2((function (param) {
                                                            return (function (param$1, param$2) {
                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                              });
                                                          })(token), undefined, (function (resp) {
                                                          if (resp.tag) {
                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to initiate defect correlation backfill."));
                                                          } else {
                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(resp[0].message));
                                                          }
                                                        }));
                                                }
                                                
                                              }
                                              var maybeTask = makeUpdateTask(updatedWatcher, updatedFields, config, project.name);
                                              if (maybeTask !== undefined) {
                                                return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (error) {
                                                              if (error.tag) {
                                                                return Task2$BsConsole.shouldntHappen(error[0]);
                                                              } else {
                                                                return Curry._1(onEditingCompleted, undefined);
                                                              }
                                                            }));
                                              } else {
                                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                              }
                                            })]);
                              }
                              
                            }
                            
                          }
                          if (!match$3.tag) {
                            return /* NoUpdate */0;
                          }
                          var path = match$3[0][0];
                          return /* SideEffects */Block.__(1, [(function (param) {
                                        var cb = state.highlightAdvancedSettingsErrors.contents;
                                        if (cb !== undefined) {
                                          return Curry._1(cb, /* ScrollToError */[path]);
                                        }
                                        
                                      })]);
                        }
                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
                        return /* NoUpdate */0;
                      }
                      var match$4 = state.connection;
                      var match$5 = state.payload;
                      var match$6 = state.advancedSettings;
                      if (match$4.tag) {
                        return /* NoUpdate */0;
                      }
                      var f1$1 = match$4[0];
                      if (match$5.tag) {
                        return /* NoUpdate */0;
                      }
                      var f2$1 = match$5[0];
                      if (match$6.tag) {
                        return /* NoUpdate */0;
                      }
                      var f3$1 = match$6[0];
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    var formValue = Object.assign(Object.assign(Object.assign({ }, f1$1), f2$1), f3$1);
                                    var updatedWatcher = WfWatcher$BsConsole.toBpg(WfWatcher$BsConsole.make(undefined, watcher.userFriendlyName, project, watcher.watcherName, undefined, formValue, project.owner, integration.description.path));
                                    var __x = BacktraceParserGenerator$BsConsole.WatcherNew.to_json(updatedWatcher);
                                    var updatedFields = Belt_Array.keep(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, __x)), (function (param) {
                                            var key = param[0];
                                            if (key !== "name" && key !== "project" && key !== "owner" && key !== "__state" && key !== "__create") {
                                              return key !== "__modify";
                                            } else {
                                              return false;
                                            }
                                          }));
                                    var match = integration.description.type_;
                                    if (match === "sca") {
                                      var scaEndpointMaybe = Json_decode.optional((function (param) {
                                              return Json_decode.field("sca-service-endpoint", Json_decode.string, param);
                                            }), btDefaults);
                                      if (scaEndpointMaybe !== undefined) {
                                        var arg = WfTasks$BsConsole.ScaBackfill.put(scaEndpointMaybe, universeName, f1$1.crashProject, f1$1.crashProjectToken, f1$1.defectProject, f1$1.defectProjectToken, f1$1.queryUrl, f1$1.ignoreFiles, f1$1.ignoreFunctions, f1$1.limitFiles, f1$1.limitFunctions, f1$1.classifierMatch, f1$1.matchColumn);
                                        Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (resp) {
                                                if (resp.tag) {
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to initiate defect correlation backfill."));
                                                } else {
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(resp[0].message));
                                                }
                                              }));
                                      }
                                      
                                    }
                                    var maybeTask = makeUpdateTask(updatedWatcher, updatedFields, config, project.name);
                                    if (maybeTask !== undefined) {
                                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (error) {
                                                    if (error.tag) {
                                                      return Task2$BsConsole.shouldntHappen(error[0]);
                                                    } else {
                                                      return Curry._1(onEditingCompleted, undefined);
                                                    }
                                                  }));
                                    } else {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                    }
                                  })]);
                      break;
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* GoToStep */0 :
                      var nextStep = action[0];
                      var shouldValidate = WfWatcherStepper$BsConsole.Steps.compare(state.steps, nextStep, step) > 0;
                      if (!shouldValidate) {
                        return /* SideEffects */Block.__(1, [(function (param) {
                                      return Curry._1(onStepChange, nextStep);
                                    })]);
                      }
                      switch (step) {
                        case /* ConfigureConnection */1 :
                            var match$7 = state.connection;
                            if (!match$7.tag) {
                              return /* SideEffects */Block.__(1, [(function (param) {
                                            return Curry._1(onStepChange, nextStep);
                                          })]);
                            }
                            var path$1 = match$7[0][0];
                            return /* SideEffects */Block.__(1, [(function (param) {
                                          var cb = state.highlightConnectionErrors.contents;
                                          if (cb !== undefined) {
                                            WfMetrics$BsConsole.send(/* WorkflowConfigureFormStepInvalid */Block.__(5, [
                                                    WfIntegrationFull$BsConsole.toSimple(integration),
                                                    1
                                                  ]));
                                            return Curry._1(cb, /* ScrollToError */[path$1]);
                                          }
                                          
                                        })]);
                        case /* CustomizePayload */2 :
                            var match$8 = state.payload;
                            if (!match$8.tag) {
                              return /* SideEffects */Block.__(1, [(function (param) {
                                            return Curry._1(onStepChange, nextStep);
                                          })]);
                            }
                            var path$2 = match$8[0][0];
                            return /* SideEffects */Block.__(1, [(function (param) {
                                          var cb = state.highlightPayloadErrors.contents;
                                          if (cb !== undefined) {
                                            return Curry._1(cb, /* ScrollToError */[path$2]);
                                          }
                                          
                                        })]);
                        case /* SetupBehavior */3 :
                            var match$9 = state.behavior.config;
                            var exit = 0;
                            if (match$9 !== undefined) {
                              var exit$1 = 0;
                              var tmp = match$9.frequency;
                              if (typeof tmp === "number" || !tmp.tag) {
                                exit$1 = 4;
                              } else {
                                exit = 3;
                              }
                              if (exit$1 === 4) {
                                var tmp$1 = match$9.threshold;
                                exit = typeof tmp$1 === "number" || !tmp$1.tag ? 2 : 3;
                              }
                              
                            } else {
                              exit = 2;
                            }
                            switch (exit) {
                              case 2 :
                                  return /* SideEffects */Block.__(1, [(function (param) {
                                                return Curry._1(onStepChange, nextStep);
                                              })]);
                              case 3 :
                                  return /* SideEffects */Block.__(1, [(function (param) {
                                                window.alert("Please enter valid integers for frequency and threshold or leave the fields empty (default values will be applied in that case)");
                                                
                                              })]);
                              
                            }
                            break;
                        case /* SelectIntegration */0 :
                        case /* AdvancedSettings */4 :
                            break;
                        
                      }
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    return Curry._1(onStepChange, nextStep);
                                  })]);
                      break;
                  case /* UpdateConnection */1 :
                      return /* Update */Block.__(0, [{
                                  initialFormValue: state.initialFormValue,
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  connection: action[0],
                                  payload: state.payload,
                                  behavior: state.behavior,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors
                                }]);
                  case /* UpdatePayload */2 :
                      return /* Update */Block.__(0, [{
                                  initialFormValue: state.initialFormValue,
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  connection: state.connection,
                                  payload: action[0],
                                  behavior: state.behavior,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors
                                }]);
                  case /* UpdateAdvancedSettings */3 :
                      return /* Update */Block.__(0, [{
                                  initialFormValue: state.initialFormValue,
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  connection: state.connection,
                                  payload: state.payload,
                                  behavior: state.behavior,
                                  advancedSettings: action[0],
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors
                                }]);
                  case /* UpdateBehavior */4 :
                      return /* Update */Block.__(0, [{
                                  initialFormValue: state.initialFormValue,
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  connection: state.connection,
                                  payload: state.payload,
                                  behavior: action[0],
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors
                                }]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.makeUpdateTask = makeUpdateTask;
exports.isStepSupported = isStepSupported;
exports.getSupportedSteps = getSupportedSteps;
exports.component = component;
exports.make = make;
/* component Not a pure module */
