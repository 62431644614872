// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var BillingConstants$BsConsole = require("../BillingConstants.js");

var ParseError = Caml_exceptions.create("Nemesis-BsConsole.ParseError");

var UnknownSvcError = Caml_exceptions.create("Nemesis-BsConsole.UnknownSvcError");

function versionResponseFromJson(json) {
  return Json_decode.field("_", (function (param) {
                return Json_decode.field("version", Json_decode.string, param);
              }), json);
}

function fetchServiceVersion(param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/version")]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var versionString = Json_decode.at(/* :: */[
                          "_",
                          /* :: */[
                            "version",
                            /* [] */0
                          ]
                        ], Json_decode.string)(param.json);
                  var v = Semver.valid(versionString);
                  if (v !== undefined) {
                    return /* Ok */Block.__(0, [v]);
                  } else {
                    return /* Error */Block.__(1, [UnknownSvcError]);
                  }
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

var minimumRequiredVersion = "0.3.2";

exports.ParseError = ParseError;
exports.UnknownSvcError = UnknownSvcError;
exports.minimumRequiredVersion = minimumRequiredVersion;
exports.versionResponseFromJson = versionResponseFromJson;
exports.fetchServiceVersion = fetchServiceVersion;
/* Semver Not a pure module */
