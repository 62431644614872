// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../../I18N.js");

var errorFetching = I18N$BsConsole.get(undefined, "Error fetching session replay data.");

var noReplayCheckDocs = I18N$BsConsole.get(undefined, "No session replay found for this error. Check your integration documentation to see if session replay is supported.");

var noReplayEventsFound = I18N$BsConsole.get(undefined, "No session replay events found for this error.");

exports.errorFetching = errorFetching;
exports.noReplayCheckDocs = noReplayCheckDocs;
exports.noReplayEventsFound = noReplayEventsFound;
/* errorFetching Not a pure module */
