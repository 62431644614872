// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

var ParseError = Caml_exceptions.create("ApiWf-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("ApiWf-BsConsole.ServiceError");

var bpgType = "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table;

var cacheKey = "bpg-" + bpgType;

function fromJson(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var $$Error = {
  fromJson: fromJson
};

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === ServiceError ? error[1] : "Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.bpgType = bpgType;
exports.cacheKey = cacheKey;
exports.$$Error = $$Error;
exports.responseCb = responseCb;
/* No side effect */
