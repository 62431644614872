// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../../../Colors.js");
var AxisJs = require("@vx/axis/build/axis/Axis.js");

var TickLabelProps = { };

var defaultLabelProps = {
  fontFamily: "Arial",
  fontSize: "8",
  fill: Colors$BsConsole.octothorpe(Colors$BsConsole.black),
  textAnchor: "middle"
};

var jsMapperConstantArray = [
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    4202101,
    "top"
  ],
  /* tuple */[
    437082891,
    "bottom"
  ],
  /* tuple */[
    847852583,
    "left"
  ]
];

function orientationToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray);
}

function orientationFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray, param);
}

function make(axisClassName, axisLineClassName, hideAxisLineOpt, hideTicksOpt, hideZeroOpt, labelOpt, labelClassName, labelOffsetOpt, labelProps, leftOpt, numTicksOpt, orientationOpt, rangePaddingOpt, scale, strokeOpt, strokeWidthOpt, strokeDasharray, tickClassName, tickFormat, tickLabelProps, tickLengthOpt, tickStrokeOpt, tickTransform, tickValues, topOpt, children) {
  var hideAxisLine = hideAxisLineOpt !== undefined ? hideAxisLineOpt : false;
  var hideTicks = hideTicksOpt !== undefined ? hideTicksOpt : false;
  var hideZero = hideZeroOpt !== undefined ? hideZeroOpt : false;
  var label = labelOpt !== undefined ? labelOpt : "";
  var labelOffset = labelOffsetOpt !== undefined ? labelOffsetOpt : 14;
  var left = leftOpt !== undefined ? leftOpt : 0;
  var numTicks = numTicksOpt !== undefined ? numTicksOpt : 10;
  var orientation = orientationOpt !== undefined ? orientationOpt : /* Bottom */437082891;
  var rangePadding = rangePaddingOpt !== undefined ? rangePaddingOpt : 0;
  var stroke = strokeOpt !== undefined ? strokeOpt : Colors$BsConsole.octothorpe(Colors$BsConsole.black);
  var strokeWidth = strokeWidthOpt !== undefined ? strokeWidthOpt : 0.5;
  var tickLength = tickLengthOpt !== undefined ? tickLengthOpt : 8;
  var tickStroke = tickStrokeOpt !== undefined ? tickStrokeOpt : Colors$BsConsole.octothorpe(Colors$BsConsole.black);
  var top = topOpt !== undefined ? topOpt : 0;
  var tmp = {
    hideAxisLine: hideAxisLine,
    hideTicks: hideTicks,
    hideZero: hideZero,
    label: label,
    labelOffset: labelOffset,
    left: left,
    numTicks: numTicks,
    orientation: orientationToJs(orientation),
    rangePadding: rangePadding,
    scale: scale,
    stroke: stroke,
    strokeWidth: strokeWidth,
    tickLength: tickLength,
    tickStroke: tickStroke,
    top: top
  };
  if (axisClassName !== undefined) {
    tmp.axisClassName = Caml_option.valFromOption(axisClassName);
  }
  if (axisLineClassName !== undefined) {
    tmp.axisLineClassName = Caml_option.valFromOption(axisLineClassName);
  }
  if (labelClassName !== undefined) {
    tmp.labelClassName = Caml_option.valFromOption(labelClassName);
  }
  if (labelProps !== undefined) {
    tmp.labelProps = Caml_option.valFromOption(labelProps);
  }
  if (strokeDasharray !== undefined) {
    tmp.strokeDasharray = Caml_option.valFromOption(strokeDasharray);
  }
  if (tickClassName !== undefined) {
    tmp.tickClassName = Caml_option.valFromOption(tickClassName);
  }
  if (tickFormat !== undefined) {
    tmp.tickFormat = Caml_option.valFromOption(tickFormat);
  }
  if (tickLabelProps !== undefined) {
    tmp.tickLabelProps = Caml_option.valFromOption(tickLabelProps);
  }
  if (tickTransform !== undefined) {
    tmp.tickTransform = Caml_option.valFromOption(tickTransform);
  }
  if (tickValues !== undefined) {
    tmp.tickValues = Caml_option.valFromOption(tickValues);
  }
  return ReasonReact.wrapJsForReason(AxisJs.default, tmp, children);
}

exports.TickLabelProps = TickLabelProps;
exports.defaultLabelProps = defaultLabelProps;
exports.orientationToJs = orientationToJs;
exports.orientationFromJs = orientationFromJs;
exports.make = make;
/* defaultLabelProps Not a pure module */
