// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Task$BsConsole = require("./task.js");
var Error$BsConsole = require("./error.js");
var ApiSignup$BsConsole = require("./apiSignup.js");
var ApiWhitelist$BsConsole = require("./ApiWhitelist.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");

var component = ReasonReact.reducerComponent("SignUp-BsConsole__FetchWhitelist");

function make(handleTask, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return Curry._1(children, self.state.remote);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* NoUpdate */0;
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                return Curry._2(handleTask, (function (json) {
                                              return Curry._1(self.send, /* Success */[ApiWhitelist$BsConsole.decode(TaskCallback$BsConsole.getJson(json))]);
                                            }), Task$BsConsole.makeTask(/* WhiteList */0));
                              })
                          ]);
                }
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var FetchWhitelist = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("SignUp-BsConsole__CreateNewUser");

function make$1(handleTask, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (email, username) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              email,
                              username
                            ]));
              };
              return Curry._2(children, self.state.remote, handleSubmit);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var username = action[1];
                    var email = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                var response = ApiSignup$BsConsole.decode(TaskCallback$BsConsole.getJson(json));
                                                if (response !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [response]));
                                                }
                                                var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                                if (error !== undefined) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [error.message]));
                                                } else {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, ["There was an error"]));
                                                }
                                              }), Task$BsConsole.makeTask(/* SignUp */Block.__(11, [
                                                    email,
                                                    username
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [action[0]])
                              }]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var CreateNewUser = {
  component: component$1,
  make: make$1
};

exports.FetchWhitelist = FetchWhitelist;
exports.CreateNewUser = CreateNewUser;
/* component Not a pure module */
