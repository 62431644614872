// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BugLink$BsConsole = require("../debugger2/BugLink.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var TeamsModel$BsConsole = require("../teams/TeamsModel.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Radio = require("@material-ui/core/Radio");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Select = require("@material-ui/core/Select");
var IdentityVariant$BsConsole = require("../IdentityVariant.js");
var MenuItem = require("@material-ui/core/MenuItem");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");
var TeamsAddTeamUsers$BsConsole = require("../teams/TeamsAddTeamUsers.js");
var TeamsDeleteDialog$BsConsole = require("../teams/TeamsDeleteDialog.js");
var InputLabel = require("@material-ui/core/InputLabel");
var RadioGroup = require("@material-ui/core/RadioGroup");
var FormControl = require("@material-ui/core/FormControl");
var TeamsCreateTeamError$BsConsole = require("../teams/TeamsCreateTeamError.js");
var TeamsCreateOrEditTeam$BsConsole = require("../teams/TeamsCreateOrEditTeam.js");
var TeamsProjectMemberTable$BsConsole = require("../teams/TeamsProjectMemberTable.js");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

function toString(param) {
  if (param) {
    return "user";
  } else {
    return "team";
  }
}

function fromString(param) {
  switch (param) {
    case "team" :
        return /* Team */0;
    case "user" :
        return /* User */1;
    default:
      return /* Team */0;
  }
}

var Mapping = {
  toString: toString,
  fromString: fromString
};

var selectStyle = Css.style(/* :: */[
      Css.minWidth(Css.px(250)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
        /* :: */[
          Css.paddingLeft(Css.px(10)),
          /* :: */[
            Css.borderRadius(Css.px(3)),
            /* :: */[
              Css.height(Css.px(35)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function roleString(v) {
  switch (v) {
    case "admin" :
        return I18N$BsConsole.show(undefined, "admin: can manage project");
    case "guest" :
        return I18N$BsConsole.show(undefined, "guest: read-only access");
    case "member" :
        return I18N$BsConsole.show(undefined, "member: can interact within project");
    default:
      return I18N$BsConsole.show(undefined, "");
  }
}

function PS_AccessControl(Props) {
  var config = Props.config;
  Props.name;
  Props.handleTask;
  var token = Props.token;
  var project = Props.project;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setCreateMappingView = match[1];
  var match$1 = React.useState((function () {
          return /* Team */0;
        }));
  var setMapping = match$1[1];
  var mapping = match$1[0];
  var match$2 = TeamsModel$BsConsole.useRefetchableProjectMemberTeamsForProject(token, project);
  var refetchProjectMemberTeamsForProject = match$2[1];
  var projectMemberTeamsForProject = match$2[0];
  var match$3 = TeamsModel$BsConsole.useRefetchableProjectMemberUsersForProject(token, project);
  var refetchProjectMemberUsersForProject = match$3[1];
  var projectMemberUsersForProject = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setDeleteDialog = match$4[1];
  var match$5 = React.useState((function () {
          return refetchProjectMemberTeamsForProject;
        }));
  var match$6 = React.useState((function () {
          
        }));
  var setCurrentTeam = match$6[1];
  var currentTeam = match$6[0];
  var match$7 = React.useState((function () {
          return "";
        }));
  var setTeamName = match$7[1];
  var teamName = match$7[0];
  var match$8 = React.useState((function () {
          return "";
        }));
  var setTeamDescription = match$8[1];
  var teamDescription = match$8[0];
  var match$9 = TeamsModel$BsConsole.useRefetchableTeams(token);
  var refetchTeams = match$9[1];
  var teams = match$9[0];
  var match$10 = React.useState((function () {
          return false;
        }));
  var setOpenDialog = match$10[1];
  var match$11 = React.useState((function () {
          return /* CreateTeam */0;
        }));
  var setView = match$11[1];
  var cancelCreateTeam = function (param) {
    Curry._1(setOpenDialog, (function (param) {
            return false;
          }));
    Curry._1(setTeamName, (function (param) {
            return "";
          }));
    return Curry._1(setTeamDescription, (function (param) {
                  return "";
                }));
  };
  var radioRoot = Css.style(/* :: */[
        Css.padding(Css.px(3)),
        /* :: */[
          Css.paddingLeft(Css.px(12)),
          /* :: */[
            Css.paddingRight(Css.px(12)),
            /* [] */0
          ]
        ]
      ]);
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var allUsers;
  if (typeof allUsersRemote === "number") {
    allUsers = [];
  } else if (allUsersRemote.tag) {
    allUsers = [];
  } else {
    var __x = Belt_List.fromArray(Util$BsConsole.filterBacktraceInternalUsers(allUsersRemote[0][0], config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2));
    allUsers = Belt_List.toArray(Belt_List.sort(__x, (function (u1, u2) {
                return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
              })));
  }
  var allTeams;
  if (teams !== undefined) {
    var __x$1 = Belt_List.fromArray(teams);
    allTeams = Belt_List.toArray(Belt_List.sort(__x$1, (function (t1, t2) {
                return $$String.compare(t1.name.toLowerCase(), t2.name.toLowerCase());
              })));
  } else {
    allTeams = [];
  }
  var match$12 = React.useState((function () {
          
        }));
  var setSelectedTeam = match$12[1];
  var selectedTeam = match$12[0];
  var match$13 = React.useState((function () {
          
        }));
  var setSelectedUser = match$13[1];
  var selectedUser = match$13[0];
  var match$14 = React.useState((function () {
          
        }));
  var setSelectedRole = match$14[1];
  var selectedRole = match$14[0];
  var allRoles = [
    "guest",
    "member",
    "admin"
  ];
  var userToString = function (u) {
    return u.username;
  };
  var teamToString = function (t) {
    return t.name;
  };
  var closeMappingDialog = function (param) {
    Curry._1(setSelectedTeam, (function (param) {
            
          }));
    Curry._1(setSelectedUser, (function (param) {
            
          }));
    Curry._1(setSelectedRole, (function (param) {
            
          }));
    Curry._1(setCreateMappingView, (function (param) {
            return false;
          }));
    return Curry._1(setMapping, (function (param) {
                  return /* Team */0;
                }));
  };
  var createProjectMemberMapping = function (param) {
    if (mapping) {
      var arg = BpgTask$BsConsole.ProjectMemberUser.create({
            project: project.pid,
            user: selectedUser !== undefined ? selectedUser.uid : 0,
            role: selectedRole !== undefined ? selectedRole : "",
            __state: undefined,
            __create: undefined,
            __modify: undefined
          });
      Curry._2((function (param) {
                return (function (param$1, param$2) {
                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                  });
              })(token), true, (function (results) {
              TeamsUtils$BsConsole.handleResults(results, I18N$BsConsole.get(undefined, "create project member user"), refetchProjectMemberUsersForProject);
              return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_CreateProjectMemberUser */67]));
            }));
    } else {
      var arg$1 = BpgTask$BsConsole.ProjectMemberTeam.create({
            project: project.pid,
            team: selectedTeam !== undefined ? selectedTeam.id : 0,
            role: selectedRole !== undefined ? selectedRole : "",
            __state: undefined,
            __create: undefined,
            __modify: undefined
          });
      Curry._2((function (param) {
                return (function (param$1, param$2) {
                    return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                  });
              })(token), true, (function (results) {
              TeamsUtils$BsConsole.handleResults(results, I18N$BsConsole.get(undefined, "create project member team"), refetchProjectMemberTeamsForProject);
              return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_CreateProjectMemberTeam */65]));
            }));
    }
    return closeMappingDialog(undefined);
  };
  if (projectMemberUsersForProject !== undefined && projectMemberTeamsForProject !== undefined && !(typeof allUsersRemote === "number" || allUsersRemote.tag || teams === undefined)) {
    return React.createElement(BtSettings$BsConsole.Container.make, {
                children: React.createElement(Paper.default, {
                      className: Css.style(/* :: */[
                            Css.padding(Css.rem(1.0)),
                            /* :: */[
                              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                              /* :: */[
                                Css.borderRadius(Css.px(4)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      elevation: 0,
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading4 */3,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* :: */[
                                  Css.fontSize(Css.px(18)),
                                  /* [] */0
                                ]
                              ]),
                          color: Colors$BsConsole.grey1,
                          children: I18N$BsConsole.show(undefined, "Access control")
                        }), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.px(15)),
                                /* [] */0
                              ])
                        }, React.createElement("p", undefined, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Overline */12,
                                  children: I18N$BsConsole.show(undefined, "This project is accessible by all users unless you limit access. Once you limit access to a specific team or user, access to the project will be restricted to only those users and organization administrators. You can specify the level of access that a user or team has in the project with the role field. Guests have read-only access, members can interact freely within the project, and admins can manage users and the project.")
                                })), React.createElement("p", undefined, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Overline */12,
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.px(5)),
                                        /* [] */0
                                      ]),
                                  children: I18N$BsConsole.show(undefined, "Organization administrators can view and configure teams for all projects in organization settings.")
                                }), React.createElement(BugLink$BsConsole.make, {
                                  route: /* SettingsTeams */7,
                                  handleChangeUrl: handleChangeUrl,
                                  children: React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Overline */12,
                                        color: Colors$BsConsole.blue,
                                        children: I18N$BsConsole.show(undefined, "Go to organization settings.")
                                      })
                                }))), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginTop(Css.px(10)),
                                  /* :: */[
                                    Css.marginBottom(Css.px(10)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(setCreateMappingView, (function (param) {
                                            return true;
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "Add a new team or user")
                        }), projectMemberTeamsForProject.length !== 0 || projectMemberUsersForProject.length !== 0 ? React.createElement(TeamsProjectMemberTable$BsConsole.make, {
                            token: token,
                            project: project,
                            memberTeams: projectMemberTeamsForProject,
                            memberUsers: projectMemberUsersForProject,
                            setDeleteDialog: setDeleteDialog,
                            setDeleteRefetch: match$5[1],
                            refetchProjectMemberTeams: refetchProjectMemberTeamsForProject,
                            refetchProjectMemberUsers: refetchProjectMemberUsersForProject,
                            universeId: config.universe.id,
                            allUsers: allUsers,
                            handleChangeUrl: handleChangeUrl
                          }) : React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            className: Css.style(/* :: */[
                                  Css.marginTop(Css.px(10)),
                                  /* :: */[
                                    Css.marginBottom(Css.px(10)),
                                    /* :: */[
                                      Css.fontSize(Css.px(12)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            color: Colors$BsConsole.grey4,
                            children: I18N$BsConsole.show(undefined, "There are currently no specified teams or users. All members of your tenant have access to the project.")
                          }), React.createElement(BtModal$BsConsole.Default.make, {
                          open: match[0],
                          onClose: closeMappingDialog,
                          title: I18N$BsConsole.get(undefined, "Add new team or user to project"),
                          subtitle: I18N$BsConsole.get(undefined, "Select a team or user to add and assign the level of access."),
                          divider: true,
                          children: React.createElement(Col2$BsConsole.make, {
                                justifyContent: /* flexEnd */924268066,
                                children: null
                              }, React.createElement(InputLabel.default, {
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginTop(Css.px(8)),
                                            /* :: */[
                                              Css.marginBottom(Css.px(3)),
                                              /* [] */0
                                            ]
                                          ])
                                    },
                                    htmlFor: "select-type",
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          children: I18N$BsConsole.show(undefined, "Type")
                                        })
                                  }), React.createElement(RadioGroup.default, {
                                    ariaLabel: "attachment strategy",
                                    value: mapping ? "user" : "team",
                                    onChange: (function (e) {
                                        var value = fromString(e.target.value);
                                        return Curry._1(setMapping, (function (param) {
                                                      return value;
                                                    }));
                                      }),
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* :: */[
                                              Css.display(/* flex */-1010954439),
                                              /* :: */[
                                                Css.flexDirection(/* row */5693978),
                                                /* [] */0
                                              ]
                                            ]
                                          ])
                                    },
                                    children: null,
                                    key: "select-type"
                                  }, React.createElement(FormControlLabel.default, {
                                        value: "team",
                                        label: I18N$BsConsole.get(undefined, "Team"),
                                        control: React.createElement(Radio.default, {
                                              color: "primary",
                                              classes: {
                                                root: radioRoot
                                              }
                                            })
                                      }), React.createElement(FormControlLabel.default, {
                                        value: "user",
                                        label: I18N$BsConsole.get(undefined, "User"),
                                        control: React.createElement(Radio.default, {
                                              color: "primary",
                                              classes: {
                                                root: radioRoot
                                              }
                                            })
                                      })), mapping ? React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                      selectedItem: selectedUser,
                                      toString: userToString,
                                      inputLabel: I18N$BsConsole.get(undefined, "User"),
                                      placeholder: I18N$BsConsole.get(undefined, "Search for a user"),
                                      noItemsMessage: I18N$BsConsole.get(undefined, "No users found"),
                                      items: allUsers,
                                      itemFilter: (function (param) {
                                          return true;
                                        }),
                                      pixelWidth: 250,
                                      setPositionFixed: true,
                                      handleItemSelection: (function (a) {
                                          return Curry._1(setSelectedUser, (function (param) {
                                                        return a;
                                                      }));
                                        })
                                    }) : React.createElement(React.Fragment, undefined, React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                          selectedItem: selectedTeam,
                                          toString: teamToString,
                                          inputLabel: I18N$BsConsole.get(undefined, "Team"),
                                          placeholder: I18N$BsConsole.get(undefined, "Search for a team"),
                                          noItemsMessage: I18N$BsConsole.get(undefined, "No teams found"),
                                          createNewItemMessage: I18N$BsConsole.get(undefined, "Create a new team"),
                                          items: allTeams,
                                          itemFilter: (function (param) {
                                              return true;
                                            }),
                                          pixelWidth: 250,
                                          setPositionFixed: true,
                                          handleItemSelection: (function (t) {
                                              return Curry._1(setSelectedTeam, (function (param) {
                                                            return t;
                                                          }));
                                            }),
                                          handleCreateNewItem: (function (param) {
                                              Curry._1(setView, (function (param) {
                                                      return /* CreateTeam */0;
                                                    }));
                                              return Curry._1(setOpenDialog, (function (param) {
                                                            return true;
                                                          }));
                                            })
                                        }), React.createElement(Dialog.default, {
                                          open: match$10[0],
                                          onClose: (function (param) {
                                              return Curry._1(setOpenDialog, (function (param) {
                                                            return false;
                                                          }));
                                            }),
                                          children: React.createElement("div", {
                                                onClick: (function (e) {
                                                    e.stopPropagation();
                                                    
                                                  })
                                              }, match$11[0] ? (
                                                  currentTeam !== undefined ? React.createElement(TeamsAddTeamUsers$BsConsole.make, {
                                                          config: config,
                                                          token: token,
                                                          open: true,
                                                          currentTeam: currentTeam,
                                                          onClose: cancelCreateTeam,
                                                          modalWidth: 250,
                                                          cancelButtonText: I18N$BsConsole.get(undefined, "Skip"),
                                                          allUsers: allUsers
                                                        }) : React.createElement(TeamsCreateTeamError$BsConsole.make, {
                                                          open: true,
                                                          onClose: cancelCreateTeam,
                                                          modalWidth: 250,
                                                          handleChangeUrl: handleChangeUrl
                                                        })
                                                ) : React.createElement(TeamsCreateOrEditTeam$BsConsole.make, {
                                                      open: true,
                                                      currentTeam: undefined,
                                                      onClose: cancelCreateTeam,
                                                      saveChanges: (function (param) {
                                                          return TeamsUtils$BsConsole.handleCreateTeam(token, config, teamName, teamDescription, (function (results) {
                                                                        if (results.tag) {
                                                                          return Curry._1(setCurrentTeam, (function (param) {
                                                                                        
                                                                                      }));
                                                                        }
                                                                        var res = results[0];
                                                                        Curry._1(setCurrentTeam, (function (param) {
                                                                                return res;
                                                                              }));
                                                                        Curry._1(setView, (function (param) {
                                                                                return /* AddUsers */1;
                                                                              }));
                                                                        return Curry._1(refetchTeams, undefined);
                                                                      }));
                                                        }),
                                                      teamName: teamName,
                                                      setTeamName: setTeamName,
                                                      teamDescription: teamDescription,
                                                      setTeamDescription: setTeamDescription,
                                                      callToActionString: I18N$BsConsole.get(undefined, "Create team")
                                                    }))
                                        })), React.createElement(InputLabel.default, {
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginTop(Css.px(8)),
                                            /* :: */[
                                              Css.marginBottom(Css.px(3)),
                                              /* [] */0
                                            ]
                                          ])
                                    },
                                    htmlFor: "select-role",
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          children: I18N$BsConsole.show(undefined, "Role")
                                        })
                                  }), React.createElement(FormControl.default, {
                                    disabled: mapping ? allUsers.length === 0 || Belt_Option.isNone(selectedUser) : allTeams.length === 0 || Belt_Option.isNone(selectedTeam),
                                    children: React.createElement(Select.default, {
                                          id: "select-role",
                                          value: selectedRole !== undefined ? selectedRole : "",
                                          onChange: (function ($$event, param) {
                                              var value = $$event.target.value;
                                              return Curry._1(setSelectedRole, (function (param) {
                                                            return value;
                                                          }));
                                            }),
                                          renderValue: (function (v) {
                                              return React.createElement(BtTypography$BsConsole.make, {
                                                          variant: /* Caption */11,
                                                          children: roleString(v)
                                                        });
                                            }),
                                          disableUnderline: true,
                                          classes: {
                                            root: selectStyle
                                          },
                                          children: $$Array.map((function (v) {
                                                  return React.createElement(MenuItem.default, {
                                                              value: v,
                                                              children: React.createElement(BtTypography$BsConsole.make, {
                                                                    variant: /* Caption */11,
                                                                    children: roleString(v)
                                                                  })
                                                            });
                                                }), allRoles)
                                        })
                                  }), React.createElement(Row2$BsConsole.make, {
                                    justifyContent: /* flexEnd */924268066,
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.marginTop(Css.rem(1)),
                                            /* [] */0
                                          ]
                                        ]),
                                    children: React.createElement(Button.default, {
                                          variant: "contained",
                                          color: "primary",
                                          disabled: Belt_Option.isNone(selectedRole) || (
                                            mapping ? Belt_Option.isNone(selectedUser) : Belt_Option.isNone(selectedTeam)
                                          ),
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.marginTop(Css.px(8)),
                                                  /* :: */[
                                                    Css.marginBottom(Css.px(3)),
                                                    /* [] */0
                                                  ]
                                                ])
                                          },
                                          onClick: createProjectMemberMapping,
                                          children: I18N$BsConsole.show(undefined, "Add to project")
                                        })
                                  }))
                        }), React.createElement(TeamsDeleteDialog$BsConsole.make, {
                          token: token,
                          openDialog: match$4[0],
                          closeDialog: (function (param) {
                              return Curry._1(setDeleteDialog, (function (param) {
                                            
                                          }));
                            }),
                          refetch: match$5[0]
                        }))
              });
  } else {
    return null;
  }
}

function make(config, name, handleTask, token, project, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_AccessControl, {
              config: config,
              name: name,
              handleTask: handleTask,
              token: token,
              project: project,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var modalWidth = 250;

var make$1 = PS_AccessControl;

exports.Mapping = Mapping;
exports.modalWidth = modalWidth;
exports.selectStyle = selectStyle;
exports.roleString = roleString;
exports.make = make$1;
exports.Re = Re;
/* selectStyle Not a pure module */
