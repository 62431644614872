// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Form$BsConsole = require("../Form.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("../util.js");
var Table$BsConsole = require("../table.js");
var Colors$BsConsole = require("../Colors.js");
var Fragment$BsConsole = require("../Fragment.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var Typeahead$BsConsole = require("../typeahead/Typeahead.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var UtilWindow$BsConsole = require("../UtilWindow.js");
var BpgController$BsConsole = require("../BpgController.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SettingsDialog$BsConsole = require("../SettingsDialog.js");
var SubmissionUrls$BsConsole = require("./SubmissionUrls.js");

function toString(t$prime) {
  return t$prime.username;
}

function getCount(param) {
  
}

var placeholder = I18N$BsConsole.get(undefined, "[ modify owner ]");

var UserTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: placeholder,
      fuzz: undefined
    });

var component = RR$BsConsole.statelessComponent("PS_ErrorSubmissionTokens-BsConsole__DeleteTokenDialog");

function make(token_, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Delete.make, (function (param, param$1) {
                                Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                return Curry._1(onSuccess, undefined);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var msg = state.remote;
                                var tmp;
                                tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])]))]));
                                var _msg = state.remote;
                                var tmp$1;
                                tmp$1 = typeof _msg === "number" ? (
                                    _msg === /* NotAsked */0 ? false : true
                                  ) : (
                                    _msg.tag ? true : false
                                  );
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Delete token"), undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                          tmp,
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure you would like to delete this token?")]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    Curry.__1(onClose),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (_event) {
                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                    token_,
                                                                                    Util$BsConsole.identity
                                                                                  ]);
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    tmp$1,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Delete")]
                                                                  ]))
                                                        ]))
                                              ]));
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var DeleteTokenDialog = {
  component: component,
  make: make
};

var smellyWidth = Css.style(/* :: */[
      Css.width(Css.px(500)),
      /* [] */0
    ]);

var loadingIcon = Css.style(/* :: */[
      Css.margin2(Css.em(1), /* zero */-789508312),
      /* [] */0
    ]);

var errorRoot = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var error = Css.style(/* :: */[
      Css.margin(Css.px(8)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* [] */0
      ]
    ]);

var Styles = {
  smellyWidth: smellyWidth,
  loadingIcon: loadingIcon,
  errorRoot: errorRoot,
  error: error
};

var component$1 = RR$BsConsole.statelessComponent("PS_ErrorSubmissionTokens-BsConsole__EditTokenDialog");

function make$1(token_, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Modify.make, (function (param, token) {
                                Curry._1(onClose, undefined);
                                return Curry._1(onSuccess, token);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make((function (param) {
                                                  return Curry._1(onClose, undefined);
                                                }), I18N$BsConsole.get(undefined, "Transfer ownership"), undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (state_, _updater) {
                                                            var users = state_.remote;
                                                            if (typeof users === "number") {
                                                              if (users === /* NotAsked */0) {
                                                                return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(smellyWidth, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, loadingIcon, []))])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              (function (_event) {
                                                                                                  
                                                                                                }),
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              true,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                            ])),
                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              (function (_event) {
                                                                                                  
                                                                                                }),
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              true,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              [I18N$BsConsole.show(undefined, "transfer")]
                                                                                            ]))
                                                                                  ])));
                                                              } else {
                                                                return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(smellyWidth, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, loadingIcon, []))])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              (function (_event) {
                                                                                                  
                                                                                                }),
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              true,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                            ])),
                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              (function (_event) {
                                                                                                  
                                                                                                }),
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              true,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              [I18N$BsConsole.show(undefined, "transfer")]
                                                                                            ]))
                                                                                  ])));
                                                              }
                                                            }
                                                            if (users.tag) {
                                                              return null;
                                                            }
                                                            var users$1 = users[0];
                                                            return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(smellyWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditProjectForm.make, (function (param) {
                                                                                        var values = param.values;
                                                                                        var next = Belt_List.getBy(users$1, (function (user) {
                                                                                                return user.uid === Caml_format.caml_int_of_string(values.owner);
                                                                                              }));
                                                                                        if (next !== undefined) {
                                                                                          return Curry._1(handleSubmit, /* tuple */[
                                                                                                      token_,
                                                                                                      (function (token_) {
                                                                                                          return {
                                                                                                                  id: token_.id,
                                                                                                                  project: token_.project,
                                                                                                                  owner: next.uid,
                                                                                                                  expires: token_.expires,
                                                                                                                  deleted: token_.deleted,
                                                                                                                  __state: token_.__state,
                                                                                                                  __create: token_.__create,
                                                                                                                  __modify: token_.__modify
                                                                                                                };
                                                                                                        }),
                                                                                                      Form$BsConsole.Fields.set("owner", values.owner, Form$BsConsole.Fields.empty),
                                                                                                      param.setError,
                                                                                                      param.setSubmitting,
                                                                                                      param.resetFormState
                                                                                                    ]);
                                                                                        } else {
                                                                                          console.log("INVARIANT");
                                                                                          return ;
                                                                                        }
                                                                                      }), undefined, undefined, {
                                                                                      owner: ""
                                                                                    }, /* :: */[
                                                                                      /* tuple */[
                                                                                        /* Owner */-878442477,
                                                                                        /* Required */0
                                                                                      ],
                                                                                      /* [] */0
                                                                                    ], undefined, (function (param) {
                                                                                        var getErrorForField = param.getErrorForField;
                                                                                        var handleSubmit = param.handleSubmit;
                                                                                        var handleChange = param.handleChange;
                                                                                        var form = param.form;
                                                                                        var userName = Belt_List.getBy(users$1, (function (user) {
                                                                                                return String(user.uid) === form.values.owner;
                                                                                              }));
                                                                                        var partial_arg = Curry._1(handleChange, /* Owner */-878442477);
                                                                                        var _msg = state.remote;
                                                                                        var tmp;
                                                                                        tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477)) : true;
                                                                                        var msg = state.remote;
                                                                                        var tmp$1;
                                                                                        tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? I18N$BsConsole.showSkip(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Owner */-878442477), "")) : I18N$BsConsole.showSkip(msg[0]);
                                                                                        var match = state.remote;
                                                                                        var match$1 = Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477));
                                                                                        var tmp$2;
                                                                                        var exit = 0;
                                                                                        if (typeof match === "number" || match.tag !== /* Failure */1) {
                                                                                          exit = 1;
                                                                                        } else {
                                                                                          tmp$2 = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                                    root: errorRoot
                                                                                                  }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                                              error: error
                                                                                                            }, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match[0])]))]));
                                                                                        }
                                                                                        if (exit === 1) {
                                                                                          tmp$2 = match$1 ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                                      root: errorRoot
                                                                                                    }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                                                error: error
                                                                                                              }, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Make sure a user is selected by clicking an option")]))])) : null;
                                                                                        }
                                                                                        return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                                                        return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                                      }), /* `String */[
                                                                                                                      -976970511,
                                                                                                                      userName !== undefined ? userName.username : ""
                                                                                                                    ], tmp, tmp$1, I18N$BsConsole.show(undefined, "transfer ownership"), true, false, undefined, (function (_onClose) {
                                                                                                                        return ReasonReact.element(undefined, undefined, Curry.app(UserTypeahead.make, [
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        users$1,
                                                                                                                                        (function (user) {
                                                                                                                                            return Curry._2(handleChange, /* Owner */-878442477, String(user.uid));
                                                                                                                                          }),
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        []
                                                                                                                                      ]));
                                                                                                                      }), undefined, []))])), tmp$2, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (_event) {
                                                                                                                          return Curry._1(onClose, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (_event) {
                                                                                                                          return Curry._1(handleSubmit, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      state.remote === /* Loading */1 || form.values.owner === "",
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      [I18N$BsConsole.show(undefined, "transfer")]
                                                                                                                    ]))
                                                                                                          ])));
                                                                                      })))]));
                                                          })))]));
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var ModifyTokenDialog = {
  Styles: Styles,
  component: component$1,
  make: make$1
};

var tableContainer = Css.style(/* :: */[
      Css.marginTop(Css.em(1)),
      /* [] */0
    ]);

var tableHeaderCell = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* [] */0
      ]
    ]);

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.em(1)),
        /* :: */[
          Css.paddingRight(Css.px(28)),
          /* [] */0
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var addButton = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(-28)),
        /* :: */[
          Css.unsafe("top", "calc(50% - 28px)"),
          /* [] */0
        ]
      ]
    ]);

var Styles$1 = {
  tableContainer: tableContainer,
  tableHeaderCell: tableHeaderCell,
  titleContainer: titleContainer,
  title: title,
  addButton: addButton
};

var component$2 = RR$BsConsole.reducerComponent("ProjectSettings");

function make$2(handleChangeUrl, handleTask, token, project, shell, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Get.make, handleTask, token, undefined, (function (state, updater) {
                                          var results = state.remote;
                                          if (typeof results !== "number" && !results.tag) {
                                            var tokens = Belt_List.keep(results[0], (function (token) {
                                                    return token.project === project.pid;
                                                  }));
                                            var onSuccess = function (param, param$1) {
                                              if (Belt_List.length(tokens) > 0) {
                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created token!"));
                                                return Curry._1(updater, undefined);
                                              } else {
                                                Curry._1(self.send, /* Dialog */[/* DocsDialog */0]);
                                                return Curry._1(updater, undefined);
                                              }
                                            };
                                            var supportLink = Href$BsConsole.Support.make(undefined, /* WhatIsASubmissionToken */25);
                                            var match = self.state.dialog;
                                            var tmp;
                                            tmp = match !== undefined ? (
                                                typeof match === "number" ? ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make((function (_event) {
                                                              return Curry._1(self.send, /* Dialog */[undefined]);
                                                            }), I18N$BsConsole.get(undefined, "Successfully created first token"), undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "You have successfully created your first token, to continue setting up your Backtrace environment continue to our integration documentation.")]))])),
                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                undefined,
                                                                                undefined,
                                                                                (function (_event) {
                                                                                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                                                                  project.name,
                                                                                                  {
                                                                                                    doc: undefined
                                                                                                  }
                                                                                                ]));
                                                                                  }),
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                [I18N$BsConsole.show(undefined, "lets go")]
                                                                              ]))]))
                                                          ])) : (
                                                    match.tag ? ReasonReact.element(undefined, undefined, make(match[0], token, handleTask, (function (param) {
                                                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                                                }), (function (param) {
                                                                  Curry._1(updater, undefined);
                                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted token!"));
                                                                }), [])) : ReasonReact.element(undefined, undefined, make$1(match[0], token, handleTask, (function (param) {
                                                                  return Curry._1(self.send, /* Dialog */[undefined]);
                                                                }), (function (param) {
                                                                  Curry._1(updater, undefined);
                                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully edited token!"));
                                                                }), []))
                                                  )
                                              ) : null;
                                            return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Submission tokens")])),
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Submission tokens are persistent tokens used to authenticate incoming error reports. You can create a token and integrate it into your application to submit an error. You can revoke a token if you no longer want to receive error reports for applications using that token. Click on the plus button to your right to begin.")])),
                                                                          React.createElement("br", undefined),
                                                                          supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                                      I18N$BsConsole.show(undefined, "If you would like to learn more about submission tokens, you can do so "),
                                                                                      React.createElement("a", {
                                                                                            href: supportLink,
                                                                                            target: "_blank"
                                                                                          }, I18N$BsConsole.show(undefined, "here")),
                                                                                      I18N$BsConsole.showSkip(".")
                                                                                    ])) : null
                                                                        ])),
                                                                ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Create.make, onSuccess, handleTask, token, (function (_state, handlseSubmit, _toKey) {
                                                                            if (typeof shell === "number") {
                                                                              return null;
                                                                            }
                                                                            if (shell.tag) {
                                                                              return null;
                                                                            }
                                                                            var configuration = shell[0];
                                                                            return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                            undefined,
                                                                                            addButton,
                                                                                            (function (param) {
                                                                                                return Curry._1(handlseSubmit, /* tuple */[
                                                                                                            {
                                                                                                              id: "",
                                                                                                              project: project.pid,
                                                                                                              owner: configuration.user.uid,
                                                                                                              expires: 0,
                                                                                                              deleted: 0,
                                                                                                              __state: undefined,
                                                                                                              __create: undefined,
                                                                                                              __modify: undefined
                                                                                                            },
                                                                                                            Util$BsConsole.identity,
                                                                                                            (function (param) {
                                                                                                                
                                                                                                              }),
                                                                                                            (function (param) {
                                                                                                                
                                                                                                              }),
                                                                                                            (function (param) {
                                                                                                                
                                                                                                              })
                                                                                                          ]);
                                                                                              }),
                                                                                            undefined,
                                                                                            undefined,
                                                                                            /* Fab */3502759,
                                                                                            /* Primary */-791844958,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                          ]));
                                                                          })))
                                                              ])), ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (state$prime, _updater) {
                                                                  var msg = state$prime.remote;
                                                                  var tmp;
                                                                  var exit = 0;
                                                                  if (typeof msg === "number") {
                                                                    exit = 1;
                                                                  } else if (msg.tag) {
                                                                    tmp = I18N$BsConsole.showSkip(msg[0]);
                                                                  } else {
                                                                    var users = msg[0];
                                                                    tmp = ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(tokens, (function (aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage) {
                                                                                return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Token")])),
                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Owner")])),
                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Enabled")])),
                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                                                  ]))])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [List.length(tokens) > 0 ? Curry._1(aperture, $$Array.of_list(List.map((function (token$prime) {
                                                                                                                            var __x = Belt_List.getBy(users, (function (user) {
                                                                                                                                    return user.uid === token$prime.owner;
                                                                                                                                  }));
                                                                                                                            return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(token$prime.id)])),
                                                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(__x, "-", (function (user) {
                                                                                                                                                                  return user.username;
                                                                                                                                                                })))])),
                                                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Modify.make, (function (_result, _updatedToken) {
                                                                                                                                                                  Curry._1(updater, undefined);
                                                                                                                                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully edited token!"));
                                                                                                                                                                }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                                                                                                                                                  var msg = state.remote;
                                                                                                                                                                  var tmp;
                                                                                                                                                                  if (typeof msg === "number" || msg.tag !== /* Failure */1) {
                                                                                                                                                                    tmp = null;
                                                                                                                                                                  } else {
                                                                                                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                                                                                                  /* String_literal */Block.__(11, [
                                                                                                                                                                                      "Could not update token setting: ",
                                                                                                                                                                                      /* String */Block.__(2, [
                                                                                                                                                                                          /* No_padding */0,
                                                                                                                                                                                          /* End_of_format */0
                                                                                                                                                                                        ])
                                                                                                                                                                                    ]),
                                                                                                                                                                                  "Could not update token setting: %s"
                                                                                                                                                                                ]), msg[0]));
                                                                                                                                                                    tmp = null;
                                                                                                                                                                  }
                                                                                                                                                                  return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, undefined, undefined, /* `Bool */[
                                                                                                                                                                                      737456202,
                                                                                                                                                                                      token$prime.deleted === 0
                                                                                                                                                                                    ], (function (param, param$1) {
                                                                                                                                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                                                                                                                                    token$prime,
                                                                                                                                                                                                    Util$BsConsole.identity,
                                                                                                                                                                                                    Form$BsConsole.Fields.set("deleted", token$prime.deleted === 0 ? "1" : "0", Form$BsConsole.Fields.empty),
                                                                                                                                                                                                    (function (param) {
                                                                                                                                                                                                        
                                                                                                                                                                                                      }),
                                                                                                                                                                                                    (function (param) {
                                                                                                                                                                                                        
                                                                                                                                                                                                      }),
                                                                                                                                                                                                    (function (param) {
                                                                                                                                                                                                        
                                                                                                                                                                                                      })
                                                                                                                                                                                                  ]);
                                                                                                                                                                                      }), undefined, undefined, undefined, [])), tmp);
                                                                                                                                                                })))])),
                                                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, "none", "right", undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                                                                  var copyServerAddressItem;
                                                                                                                                                                  if (typeof shell === "number" || shell.tag) {
                                                                                                                                                                    copyServerAddressItem = null;
                                                                                                                                                                  } else {
                                                                                                                                                                    var serverAddress = SubmissionUrls$BsConsole.createSubmissionUrlFromConfig(shell[0], token$prime.id, "json")(undefined, undefined, undefined);
                                                                                                                                                                    copyServerAddressItem = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                                Clipboard$BsConsole.copy(I18N$BsConsole.skip(serverAddress), (function (param) {
                                                                                                                                                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                                                                                                                            /* String */Block.__(2, [
                                                                                                                                                                                                                /* No_padding */0,
                                                                                                                                                                                                                /* String_literal */Block.__(11, [
                                                                                                                                                                                                                    " copied to clipboard.",
                                                                                                                                                                                                                    /* End_of_format */0
                                                                                                                                                                                                                  ])
                                                                                                                                                                                                              ]),
                                                                                                                                                                                                            "%s copied to clipboard."
                                                                                                                                                                                                          ]), serverAddress));
                                                                                                                                                                                      }), undefined);
                                                                                                                                                                                return Curry._1(onClose, undefined);
                                                                                                                                                                              }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Copy server address")]));
                                                                                                                                                                  }
                                                                                                                                                                  return [
                                                                                                                                                                          copyServerAddressItem,
                                                                                                                                                                          ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                                      Curry._1(self.send, /* Dialog */[/* EditToken */Block.__(0, [token$prime])]);
                                                                                                                                                                                      return Curry._1(onClose, undefined);
                                                                                                                                                                                    }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Transfer ownership")])),
                                                                                                                                                                          ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Delete.make, (function (param, param$1) {
                                                                                                                                                                                      Curry._1(updater, undefined);
                                                                                                                                                                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted token!"));
                                                                                                                                                                                    }), handleTask, token, (function (_state, _handleSubmit, _toKey) {
                                                                                                                                                                                      return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                                                        Curry._1(self.send, /* Dialog */[/* DeleteToken */Block.__(1, [token$prime])]);
                                                                                                                                                                                                        return Curry._1(onClose, undefined);
                                                                                                                                                                                                      }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]));
                                                                                                                                                                                    })))
                                                                                                                                                                        ];
                                                                                                                                                                }), undefined, []))]))
                                                                                                                                          ]));
                                                                                                                          }), tokens))) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "There are currently no submission tokens for this project")]))]))])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    onChangePage,
                                                                                                                    undefined,
                                                                                                                    onChangeRowsPerPage,
                                                                                                                    /* `Int */[
                                                                                                                      3654863,
                                                                                                                      page
                                                                                                                    ],
                                                                                                                    undefined,
                                                                                                                    /* `Int */[
                                                                                                                      3654863,
                                                                                                                      List.length(tokens)
                                                                                                                    ],
                                                                                                                    /* `Int */[
                                                                                                                      3654863,
                                                                                                                      rowsPerPage
                                                                                                                    ],
                                                                                                                    /* `Int */[
                                                                                                                      3654863,
                                                                                                                      6
                                                                                                                    ],
                                                                                                                    undefined,
                                                                                                                    undefined,
                                                                                                                    []
                                                                                                                  ]))]))
                                                                                              ]));
                                                                              })));
                                                                  }
                                                                  if (exit === 1) {
                                                                    tmp = React.createElement("div", {
                                                                          style: {
                                                                            display: "flex",
                                                                            margin: "1em 0",
                                                                            alignContent: "center",
                                                                            justifyContent: "center"
                                                                          }
                                                                        }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, [])));
                                                                  }
                                                                  return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, tableContainer, [tmp]));
                                                                }))), tmp);
                                          }
                                          var supportLink$1 = Href$BsConsole.Support.make(undefined, /* WhatIsASubmissionToken */25);
                                          return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Submission tokens")])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Submission tokens are persistent tokens used to authenticate incoming error reports. You can create a token and integrate it into your application to submit an error. You can revoke a token if you no longer want to receive error reports for applications using that token. Click on the plus button to your right to begin.")])),
                                                                              React.createElement("br", undefined),
                                                                              supportLink$1 !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                                          I18N$BsConsole.show(undefined, "If you would like to learn more about submission tokens, you can do so "),
                                                                                          React.createElement("a", {
                                                                                                href: supportLink$1,
                                                                                                target: "_blank"
                                                                                              }, I18N$BsConsole.show(undefined, "here")),
                                                                                          I18N$BsConsole.showSkip(".")
                                                                                        ])) : null
                                                                            ]))
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, tableContainer, [React.createElement("div", {
                                                                          style: {
                                                                            display: "flex",
                                                                            margin: "1em 0",
                                                                            alignContent: "center",
                                                                            justifyContent: "center"
                                                                          }
                                                                        }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, [])))]))
                                                        ]));
                                        })))]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

exports.UserTypeahead = UserTypeahead;
exports.DeleteTokenDialog = DeleteTokenDialog;
exports.ModifyTokenDialog = ModifyTokenDialog;
exports.Styles = Styles$1;
exports.component = component$2;
exports.make = make$2;
/* UserTypeahead Not a pure module */
