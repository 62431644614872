// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var DocumentRe = require("bs-webapi/lib/js/src/dom/nodes/DocumentRe.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var IFrame$BsConsole = require("./IFrame.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");

function onMessageListener($$event) {
  if (!IFrame$BsConsole.isIFrame) {
    return ;
  }
  var eventData = $$event.data;
  var messageData = Belt_Option.flatMap((eventData == null) ? undefined : Caml_option.some(eventData), IFrame$BsConsole.Message.fromJs);
  if (messageData !== undefined && messageData.type_ === "backtrace-popstate") {
    return ReasonReactRouter.push(atob(messageData.payload));
  }
  
}

function handleOnMessage(param) {
  React.useEffect((function () {
          var __x = window;
          __x.addEventListener("message", onMessageListener);
          return (function (param) {
                    var __x = window;
                    __x.removeEventListener("message", onMessageListener);
                    
                  });
        }), []);
  
}

function IFramePostMessageEvents$MessageHandler(Props) {
  handleOnMessage(undefined);
  return null;
}

var MessageHandler = {
  onMessageListener: onMessageListener,
  handleOnMessage: handleOnMessage,
  make: IFramePostMessageEvents$MessageHandler
};

var bodyEl = Belt_Option.flatMap(DocumentRe.asHtmlDocument(document), (function (prim) {
        return Caml_option.nullable_to_opt(prim.body);
      }));

function handleOnResize(param) {
  var match = React.useState((function () {
          
        }));
  var setCurrentHeight = match[1];
  var currentHeight = match[0];
  var resizeHandler = React.useCallback((function (entries) {
          Belt_Option.map(Belt_Array.get(entries, 0), (function (entry) {
                  var height = entry.contentRect.height;
                  if (height > 0) {
                    return Curry._1(setCurrentHeight, (function (_prev) {
                                  return height;
                                }));
                  }
                  
                }));
          
        }), []);
  var resizeObserver = React.useMemo((function () {
          return new ResizeObserver(resizeHandler);
        }), [resizeHandler]);
  React.useEffect((function () {
          if (!IFrame$BsConsole.isIFrame) {
            return ;
          }
          if (bodyEl === undefined) {
            return ;
          }
          var body = Caml_option.valFromOption(bodyEl);
          resizeObserver.observe(body);
          return (function (param) {
                    resizeObserver.unobserve(body);
                    
                  });
        }), [resizeObserver]);
  React.useEffect((function () {
          if (currentHeight !== undefined && currentHeight > 0) {
            var heightStr = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(currentHeight));
            IFrame$BsConsole.postMessage({
                  type_: "backtrace-body-height",
                  payload: heightStr + "px"
                });
          }
          
        }), [currentHeight]);
  
}

function IFramePostMessageEvents$ResizeHandler(Props) {
  handleOnResize(undefined);
  return null;
}

var ResizeHandler = {
  bodyEl: bodyEl,
  handleOnResize: handleOnResize,
  make: IFramePostMessageEvents$ResizeHandler
};

function handleOnLoadMessage(param) {
  React.useEffect((function () {
          IFrame$BsConsole.postMessage({
                type_: "backtrace-app-loaded",
                payload: "true"
              });
          
        }), []);
  
}

function IFramePostMessageEvents$OnLoadHandler(Props) {
  handleOnLoadMessage(undefined);
  return null;
}

var OnLoadHandler = {
  handleOnLoadMessage: handleOnLoadMessage,
  make: IFramePostMessageEvents$OnLoadHandler
};

function IFramePostMessageEvents(Props) {
  Props.children;
  if (IFrame$BsConsole.isIFrame) {
    return React.createElement(React.Fragment, undefined, React.createElement(IFramePostMessageEvents$MessageHandler, { }), React.createElement(IFramePostMessageEvents$ResizeHandler, { }), React.createElement(IFramePostMessageEvents$OnLoadHandler, { }));
  } else {
    return null;
  }
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(IFramePostMessageEvents, {
              children: children
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = IFramePostMessageEvents;

exports.MessageHandler = MessageHandler;
exports.ResizeHandler = ResizeHandler;
exports.OnLoadHandler = OnLoadHandler;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* bodyEl Not a pure module */
