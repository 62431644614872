// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Crdb = require("backtrace-morgue/dist/lib/crdb");
var Callstack = require("backtrace-morgue/lib/callstack");

function unpack(json$prime) {
  return new Crdb.Response(json$prime).unpack();
}

function prep(frames, options) {
  return new Callstack(frames).render(options);
}

var Callstack$1 = {
  prep: prep
};

exports.unpack = unpack;
exports.Callstack = Callstack$1;
/* backtrace-morgue/dist/lib/crdb Not a pure module */
