// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N_real$BsConsole = require("./I18N_real.js");
var I18N_language_code$BsConsole = require("./I18N_language_code.js");
var I18N_language_file$BsConsole = require("./I18N_language_file.js");

var LanguageCode = {
  fromISO639: I18N_language_code$BsConsole.fromISO639,
  fromBCP47: I18N_language_code$BsConsole.fromBCP47,
  map: I18N_language_code$BsConsole.map,
  toISO639: I18N_language_code$BsConsole.toISO639,
  toString: I18N_language_code$BsConsole.toString
};

var $$File = {
  load: I18N_language_file$BsConsole.load
};

var Option_showOrEmpty = I18N_real$BsConsole.$$Option.showOrEmpty;

var Option_dynamicOrEmpty = I18N_real$BsConsole.$$Option.dynamicOrEmpty;

var $$Option = {
  showOrEmpty: Option_showOrEmpty,
  dynamicOrEmpty: Option_dynamicOrEmpty
};

var dynamic = I18N_real$BsConsole.dynamic;

var dynamicGet = I18N_real$BsConsole.dynamicGet;

var empty = I18N_real$BsConsole.empty;

var get = I18N_real$BsConsole.get;

var show = I18N_real$BsConsole.show;

var showEmpty = I18N_real$BsConsole.showEmpty;

var showSkip = I18N_real$BsConsole.showSkip;

var skip = I18N_real$BsConsole.skip;

var skipf = I18N_real$BsConsole.skipf;

var getf = I18N_real$BsConsole.getf;

var showf = I18N_real$BsConsole.showf;

var showSkipf = I18N_real$BsConsole.showSkipf;

exports.LanguageCode = LanguageCode;
exports.$$File = $$File;
exports.$$Option = $$Option;
exports.dynamic = dynamic;
exports.dynamicGet = dynamicGet;
exports.empty = empty;
exports.get = get;
exports.show = show;
exports.showEmpty = showEmpty;
exports.showSkip = showSkip;
exports.skip = skip;
exports.skipf = skipf;
exports.getf = getf;
exports.showf = showf;
exports.showSkipf = showSkipf;
/* I18N_real-BsConsole Not a pure module */
