// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

var mainBackground = IdentityVariant$BsConsole.current >= 2 && IFrame$BsConsole.isIFrame ? /* transparent */582626130 : Css.hex(Colors$BsConsole.grey75);

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.backgroundColor(mainBackground),
          /* :: */[
            Css.minHeight(Css.vh(100)),
            /* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.position(/* relative */903134412),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  container: container
};

function ViewContainer(Props) {
  var children = Props.children;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    container,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

function make(className, children) {
  var tmp = {
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ViewContainer, tmp, children);
}

var Re = {
  make: make
};

var make$1 = ViewContainer;

exports.mainBackground = mainBackground;
exports.Style = Style;
exports.make = make$1;
exports.Re = Re;
/* mainBackground Not a pure module */
