// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var WfForm$BsConsole = require("./WfForm.js");

function WfFormJsx3(Props) {
  var btDefaults = Props.btDefaults;
  var token = Props.token;
  var form = Props.form;
  Props.groupOptionalFields;
  var initialFormValue = Props.initialFormValue;
  var onValueChange = Props.onValueChange;
  return ReasonReact.element(undefined, undefined, WfForm$BsConsole.make(btDefaults, token, form, (function (_action) {
                    return false;
                  }), onValueChange, undefined, Caml_option.some(initialFormValue), (function (_showAllErrors) {
                    return null;
                  }), []));
}

var make = React.memo(WfFormJsx3);

exports.make = make;
/* make Not a pure module */
