// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var InboxUtils$BsConsole = require("./InboxUtils.js");

var options = /* :: */[
  /* Open */1,
  /* :: */[
    /* InProgress */2,
    /* :: */[
      /* Resolved */3,
      /* :: */[
        /* Muted */4,
        /* :: */[
          /* All */0,
          /* :: */[
            /* Custom */5,
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

function toString(v) {
  switch (v) {
    case /* All */0 :
        return "all";
    case /* Open */1 :
        return "open";
    case /* InProgress */2 :
        return "in-progress";
    case /* Resolved */3 :
        return "resolved";
    case /* Muted */4 :
        return "muted";
    case /* Custom */5 :
        return "custom";
    
  }
}

function ofState(v) {
  return v + 1 | 0;
}

function toState(v) {
  switch (v) {
    case /* InProgress */2 :
        return /* InProgress */2;
    case /* Resolved */3 :
        return /* Resolved */3;
    case /* Muted */4 :
        return /* Muted */4;
    case /* All */0 :
    case /* Open */1 :
    case /* Custom */5 :
        return /* Open */1;
    
  }
}

function ofString(str) {
  switch (str) {
    case "all" :
        return /* All */0;
    case "in-progress" :
        return /* InProgress */2;
    case "muted" :
        return /* Muted */4;
    case "open" :
        return /* Open */1;
    case "resolved" :
        return /* Resolved */3;
    default:
      return /* Custom */5;
  }
}

function isDetailView(aperture) {
  return Belt_List.length(Belt_List.keep(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
                    var attributeStr = Curry._1(Crdb$BsConsole.Filter.getAttribute, f);
                    var operationStr = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, f));
                    return Caml_obj.caml_equal(/* tuple */[
                                attributeStr,
                                operationStr
                              ], /* tuple */[
                                "fingerprint",
                                "equal"
                              ]);
                  }))) > 0;
}

function isInboxWorkflow(filter) {
  return InboxUtils$BsConsole.isNestedIssues(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter));
}

function getInboxWorkflowAttributeName(name) {
  var regex = new RegExp("fingerprint;issues;(.+)");
  var result = regex.exec(name);
  if (result !== null) {
    return Belt_Option.flatMap(Belt_Array.get(result, 1), (function (prim) {
                  if (prim == null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }));
  }
  
}

function getWorkflowAttribute(filter) {
  return getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter));
}

function getDetails(aperture, handleSetFilters, fingerprint) {
  return Curry._1(handleSetFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                      "fingerprint",
                      Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                            "equal",
                            fingerprint
                          ])
                    ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)));
}

function clearIssueNestedFilter(aperture, column) {
  return Belt_List.reduce(Belt_List.keep(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
                    var attribute = "fingerprint;issues;" + column;
                    return Curry._1(Crdb$BsConsole.Filter.getAttribute, f) === attribute;
                  })), aperture, (function (acc, f) {
                return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.remove, f, Curry._1(Crdb$BsConsole.Aperture.getFilters, acc)), acc);
              }));
}

function clearAllNestedFilters(aperture) {
  return Belt_List.reduce(Belt_List.map(Belt_List.keep(Belt_List.map(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
                            return getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, f));
                          })), Belt_Option.isSome), Belt_Option.getExn), aperture, clearIssueNestedFilter);
}

function clearAllNestedFilterExcept(aperture, except) {
  return Belt_List.reduce(Belt_List.map(Belt_List.keep(Belt_List.map(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
                            return getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, f));
                          })), Belt_Option.isSome), Belt_Option.getExn), aperture, (function (acc, f) {
                if (Belt_List.has(except, f, (function (prim, prim$1) {
                          return prim === prim$1;
                        }))) {
                  return acc;
                } else {
                  return clearIssueNestedFilter(acc, f);
                }
              }));
}

function deNestedSelectorAttributes(aperture) {
  return Belt_List.reduce(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), aperture, (function (acc, f) {
                var att = getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, f));
                if (att !== undefined) {
                  return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.remove, f, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                          att,
                                          Curry._1(Crdb$BsConsole.Filter.getOperation, f)
                                        ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, acc))), acc);
                } else {
                  return acc;
                }
              }));
}

function clearAllNonNestedFilters(aperture) {
  return Belt_List.reduce(Belt_List.keep(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
                    return Belt_Option.isNone(getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, f)));
                  })), aperture, (function (acc, f) {
                return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.remove, f, Curry._1(Crdb$BsConsole.Aperture.getFilters, acc)), acc);
              }));
}

function hasNestedSelectorFilter(aperture, included) {
  return Belt_List.has(Belt_List.map(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, clearAllNonNestedFilters(aperture)))), (function (f) {
                    return getInboxWorkflowAttributeName(Curry._1(Crdb$BsConsole.Filter.getAttribute, f));
                  })), included, Caml_obj.caml_equal);
}

function addWorkflowStateFilter(aperture, w) {
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                      Issues$BsConsole.State.columnName,
                      /* Equal */Block.__(0, [/* `String */[
                            -976970511,
                            toString(w)
                          ]])
                    ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture);
}

function setWorkflowFilterToAperture(aperture, nextWorkflowSet) {
  if (nextWorkflowSet !== 0) {
    if (nextWorkflowSet >= 5) {
      return aperture;
    } else {
      return addWorkflowStateFilter(clearIssueNestedFilter(aperture, "state"), nextWorkflowSet);
    }
  } else {
    return clearIssueNestedFilter(aperture, "state");
  }
}

function prepareInboxAllTimeQuery(aperture) {
  var allTimeAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
              /* Floating */0,
              /* All */6
            ])), clearAllNonNestedFilters(clearAllNestedFilterExcept(aperture, /* :: */[
                "assignee",
                /* :: */[
                  "state",
                  /* [] */0
                ]
              ])));
  return /* Aggregate */Block.__(0, [
            allTimeAperture,
            undefined,
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "fingerprint",
                      /* Unique */4
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function fromAperture(aperture) {
  var stateFilters = Belt_List.keep(Belt_List.fromArray(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture))), (function (f) {
          var match = Curry._1(Crdb$BsConsole.Filter.getAttribute, f);
          if (match === "fingerprint;issues;state") {
            return true;
          } else {
            return false;
          }
        }));
  var match = Belt_List.length(stateFilters);
  if (match === 0) {
    return /* All */0;
  }
  if (match !== 1) {
    return /* Custom */5;
  }
  var operation = Curry._1(Crdb$BsConsole.Filter.getOperation, Belt_List.getExn(stateFilters, 0));
  if (operation.tag) {
    return /* Custom */5;
  }
  var v = operation[0];
  if (typeof v === "number" || v[0] !== -976970511) {
    return /* Custom */5;
  } else {
    return ofString(v[1]);
  }
}

function getTabIndexFromAperture(aperture) {
  var iwState = fromAperture(aperture);
  return Belt_List.toArray(options).indexOf(iwState);
}

var getActiveTab = fromAperture;

var Folders = {
  options: options,
  toString: toString,
  ofState: ofState,
  toState: toState,
  ofString: ofString,
  isDetailView: isDetailView,
  isInboxWorkflow: isInboxWorkflow,
  getInboxWorkflowAttributeName: getInboxWorkflowAttributeName,
  getWorkflowAttribute: getWorkflowAttribute,
  getDetails: getDetails,
  clearIssueNestedFilter: clearIssueNestedFilter,
  clearAllNestedFilters: clearAllNestedFilters,
  clearAllNestedFilterExcept: clearAllNestedFilterExcept,
  deNestedSelectorAttributes: deNestedSelectorAttributes,
  clearAllNonNestedFilters: clearAllNonNestedFilters,
  hasNestedSelectorFilter: hasNestedSelectorFilter,
  addWorkflowStateFilter: addWorkflowStateFilter,
  setWorkflowFilterToAperture: setWorkflowFilterToAperture,
  prepareInboxAllTimeQuery: prepareInboxAllTimeQuery,
  fromAperture: fromAperture,
  getTabIndexFromAperture: getTabIndexFromAperture,
  getActiveTab: getActiveTab
};

exports.Folders = Folders;
/* Crdb-BsConsole Not a pure module */
