// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Charts__Bin$BsConsole = require("../charts/Charts__Bin.js");

function Bin(Props) {
  var data = Props.data;
  var binsOpt = Props.bins;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var hideYAxisOpt = Props.hideYAxis;
  var hideXAxisOpt = Props.hideXAxis;
  var marginTopOpt = Props.marginTop;
  var marginRightOpt = Props.marginRight;
  var marginLeftOpt = Props.marginLeft;
  var marginBottomOpt = Props.marginBottom;
  var barFill = Props.barFill;
  var barHoverFill = Props.barHoverFill;
  var classNameOpt = Props.className;
  var range = Props.range;
  var bins = binsOpt !== undefined ? binsOpt : 32;
  var width = widthOpt !== undefined ? widthOpt : 128;
  var height = heightOpt !== undefined ? heightOpt : 25;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : true;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : true;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : 0;
  var marginRight = marginRightOpt !== undefined ? marginRightOpt : 0;
  var marginLeft = marginLeftOpt !== undefined ? marginLeftOpt : 0;
  var marginBottom = marginBottomOpt !== undefined ? marginBottomOpt : 0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: className
            }, ReasonReact.element(undefined, undefined, Charts__Bin$BsConsole.make(undefined, bins, undefined, range, height, width, hideXAxis, hideYAxis, undefined, undefined, marginLeft, marginTop, marginRight, marginBottom, barFill, barHoverFill, data, [])));
}

var make = React.memo(Bin);

exports.make = make;
/* make Not a pure module */
