'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ordinal = require('../ordinal');

var _ordinal2 = _interopRequireDefault(_ordinal);

var _d3Scale = require('d3-scale');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _ordinal2.default)(_d3Scale.schemeCategory20);