// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var TeamsUtils$BsConsole = require("./TeamsUtils.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");

function fetchTeams(token, cb) {
  var arg = BpgTask$BsConsole.Team.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (results) {
                if (results.tag) {
                  return TeamsUtils$BsConsole.handleRefetchableResults(results, "fetch teams", cb);
                } else {
                  return Curry._1(cb, results[0]);
                }
              }));
}

function useRefetchableTeams(token) {
  var match = React.useState((function () {
          
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchTeams(token, (function (newValue) {
                  return Curry._1(setValue, (function (param) {
                                return newValue;
                              }));
                }));
          
        }), /* tuple */[
        match$1[0],
        token
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function fetchTeamMembers(token, team, cb) {
  var arg = BpgTask$BsConsole.TeamMember.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (results) {
                if (results.tag) {
                  return TeamsUtils$BsConsole.handleRefetchableResults(results, "fetch team members", cb);
                }
                var resultsForTeam = Belt_Array.keep(results[0], (function (result) {
                        return result.team === team.id;
                      }));
                if (resultsForTeam.length === 0) {
                  return Curry._1(cb, undefined);
                } else {
                  return Curry._1(cb, resultsForTeam);
                }
              }));
}

function useRefetchableTeamMembers(token, team) {
  var match = React.useState((function () {
          
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchTeamMembers(token, team, (function (newValue) {
                  return Curry._1(setValue, (function (param) {
                                return newValue;
                              }));
                }));
          
        }), /* tuple */[
        match$1[0],
        token,
        team.id
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function fetchProjectMemberTeamsForProject(token, project, cb) {
  var arg = BpgTask$BsConsole.ProjectMemberTeam.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (results) {
                if (results.tag) {
                  return TeamsUtils$BsConsole.handleRefetchableResults(results, "fetch project member teams", cb);
                }
                var resultsForProject = Belt_Array.keep(results[0], (function (result) {
                        return result.project === project.pid;
                      }));
                return Curry._1(cb, resultsForProject);
              }));
}

function useRefetchableProjectMemberTeamsForProject(token, project) {
  var match = React.useState((function () {
          
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchProjectMemberTeamsForProject(token, project, (function (newValue) {
                  return Curry._1(setValue, (function (param) {
                                return newValue;
                              }));
                }));
          
        }), /* tuple */[
        match$1[0],
        token,
        project.pid
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function fetchProjectMemberUsersForProject(token, project, cb) {
  var arg = BpgTask$BsConsole.ProjectMemberUser.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (results) {
                if (results.tag) {
                  return TeamsUtils$BsConsole.handleRefetchableResults(results, "fetch project member teams", cb);
                }
                var resultsForProject = Belt_Array.keep(results[0], (function (result) {
                        return result.project === project.pid;
                      }));
                return Curry._1(cb, resultsForProject);
              }));
}

function useRefetchableProjectMemberUsersForProject(token, project) {
  var match = React.useState((function () {
          
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchProjectMemberUsersForProject(token, project, (function (newValue) {
                  return Curry._1(setValue, (function (param) {
                                return newValue;
                              }));
                }));
          
        }), /* tuple */[
        match$1[0],
        token,
        project.pid
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function useTeamsPanelData(token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setDataRemote = match[1];
  var dataRemote = match[0];
  var match$1 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.Team.fetchAll);
  var teamsRemote = match$1[0];
  var match$2 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.TeamMember.fetchAll);
  var teamMembersRemote = match$2[0];
  var match$3 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.ProjectMemberTeam.fetchAll);
  var projectMemberTeamRemote = match$3[0];
  var match$4 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.Users.fetchAll);
  var usersRemote = match$4[0];
  var match$5 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.Project.fetchAll);
  var projectsRemote = match$5[0];
  React.useEffect((function () {
          var exit = 0;
          var msg;
          var exit$1 = 0;
          var exit$2 = 0;
          var exit$3 = 0;
          var exit$4 = 0;
          var exit$5 = 0;
          var exit$6 = 0;
          var exit$7 = 0;
          var exit$8 = 0;
          if (typeof teamsRemote === "number") {
            exit$8 = 10;
          } else if (teamsRemote.tag) {
            msg = teamsRemote[0];
            exit = 1;
          } else {
            var teams = teamsRemote[0];
            if (typeof teamMembersRemote === "number") {
              exit$7 = 9;
            } else if (teamMembersRemote.tag) {
              exit$8 = 10;
            } else {
              var teamMembers = teamMembersRemote[0];
              if (typeof usersRemote === "number") {
                exit$6 = 8;
              } else if (usersRemote.tag) {
                exit$7 = 9;
              } else {
                var users = usersRemote[0];
                if (typeof projectMemberTeamRemote === "number") {
                  exit$5 = 7;
                } else if (projectMemberTeamRemote.tag) {
                  exit$6 = 8;
                } else {
                  var projectMemberTeams = projectMemberTeamRemote[0];
                  if (typeof projectsRemote === "number") {
                    if (projectsRemote !== /* NotAsked */0) {
                      exit$5 = 7;
                    }
                    
                  } else if (projectsRemote.tag) {
                    exit$5 = 7;
                  } else {
                    var projects = projectsRemote[0];
                    Curry._1(setDataRemote, (function (param) {
                            return /* Success */Block.__(0, [/* tuple */[
                                        {
                                          projects: projects,
                                          users: users,
                                          teams: teams,
                                          teamMembers: teamMembers,
                                          projectMemberTeams: projectMemberTeams
                                        },
                                        /* NotAsked */0
                                      ]]);
                          }));
                  }
                }
              }
            }
          }
          if (exit$8 === 10) {
            if (typeof teamMembersRemote === "number" || teamMembersRemote.tag !== /* Failure */1) {
              exit$7 = 9;
            } else {
              msg = teamMembersRemote[0];
              exit = 1;
            }
          }
          if (exit$7 === 9) {
            if (typeof usersRemote === "number" || usersRemote.tag !== /* Failure */1) {
              exit$6 = 8;
            } else {
              msg = usersRemote[0];
              exit = 1;
            }
          }
          if (exit$6 === 8) {
            if (typeof projectMemberTeamRemote === "number" || projectMemberTeamRemote.tag !== /* Failure */1) {
              exit$5 = 7;
            } else {
              msg = projectMemberTeamRemote[0];
              exit = 1;
            }
          }
          if (exit$5 === 7) {
            if (typeof projectsRemote === "number") {
              if (projectsRemote === /* NotAsked */0) {
                exit$4 = 6;
              } else {
                exit = 2;
              }
            } else if (projectsRemote.tag) {
              msg = projectsRemote[0];
              exit = 1;
            } else {
              exit$4 = 6;
            }
          }
          if (exit$4 === 6) {
            if (typeof projectMemberTeamRemote === "number" && projectMemberTeamRemote !== 0) {
              exit = 2;
            } else {
              exit$3 = 5;
            }
          }
          if (exit$3 === 5) {
            if (typeof usersRemote === "number" && usersRemote !== 0) {
              exit = 2;
            } else {
              exit$2 = 4;
            }
          }
          if (exit$2 === 4) {
            if (typeof teamMembersRemote === "number" && teamMembersRemote !== 0) {
              exit = 2;
            } else {
              exit$1 = 3;
            }
          }
          if (exit$1 === 3 && typeof teamsRemote === "number" && teamsRemote !== 0) {
            exit = 2;
          }
          switch (exit) {
            case 1 :
                Curry._1(setDataRemote, (function (currentRemote) {
                        if (typeof currentRemote === "number" || currentRemote.tag) {
                          return /* Failure */Block.__(1, [msg]);
                        } else {
                          return /* Success */Block.__(0, [/* tuple */[
                                      currentRemote[0][0],
                                      /* Failure */Block.__(1, [msg])
                                    ]]);
                        }
                      }));
                break;
            case 2 :
                Curry._1(setDataRemote, (function (remote) {
                        if (typeof remote === "number" || remote.tag) {
                          return /* Loading */1;
                        } else {
                          return /* Success */Block.__(0, [/* tuple */[
                                      remote[0][0],
                                      /* Loading */1
                                    ]]);
                        }
                      }));
                break;
            
          }
          
        }), /* tuple */[
        teamsRemote,
        teamMembersRemote,
        usersRemote,
        projectMemberTeamRemote,
        projectsRemote
      ]);
  React.useEffect((function () {
          var exit = 0;
          var msg;
          if (typeof dataRemote === "number") {
            dataRemote === /* NotAsked */0;
          } else if (dataRemote.tag) {
            msg = dataRemote[0];
            exit = 1;
          } else {
            var msg$1 = dataRemote[0][1];
            if (typeof msg$1 !== "number" && msg$1.tag === /* Failure */1) {
              msg = msg$1[0];
              exit = 1;
            }
            
          }
          if (exit === 1) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "Failure to load Teams data: ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "Failure to load Teams data: %s"
                        ]), msg));
          }
          
        }), [dataRemote]);
  return /* tuple */[
          dataRemote,
          match$1[1],
          match$2[1],
          match$3[1]
        ];
}

exports.fetchTeams = fetchTeams;
exports.useRefetchableTeams = useRefetchableTeams;
exports.fetchTeamMembers = fetchTeamMembers;
exports.useRefetchableTeamMembers = useRefetchableTeamMembers;
exports.fetchProjectMemberTeamsForProject = fetchProjectMemberTeamsForProject;
exports.useRefetchableProjectMemberTeamsForProject = useRefetchableProjectMemberTeamsForProject;
exports.fetchProjectMemberUsersForProject = fetchProjectMemberUsersForProject;
exports.useRefetchableProjectMemberUsersForProject = useRefetchableProjectMemberUsersForProject;
exports.useTeamsPanelData = useTeamsPanelData;
/* react Not a pure module */
