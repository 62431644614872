// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var ApiOverview$BsConsole = require("./ApiOverview.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var Configuration$BsConsole = require("./configuration.js");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var ReactGridLayout$BsConsole = require("./ReactGridLayout.js");
var DashboardWidgets$BsConsole = require("./DashboardWidgets.js");
var DashboardWidgetData$BsConsole = require("./DashboardWidgetData.js");
var FetchProjectOverview$BsConsole = require("./FetchProjectOverview.js");

function getTemplateReplaceList(queryItem, versionAttribute) {
  var match = DashboardWidgetData$BsConsole.hasVersionAttribute(queryItem);
  var replaceList = match && versionAttribute !== undefined ? /* :: */[
      /* tuple */[
        DashboardWidgetData$BsConsole._VERSION_ATTRIBUTE_TOKEN,
        versionAttribute
      ],
      /* [] */0
    ] : /* [] */0;
  var groupFromQueryItemConfigs = DashboardWidgetData$BsConsole.getCurrentGroup(queryItem, versionAttribute);
  if (groupFromQueryItemConfigs !== undefined) {
    return /* :: */[
            /* tuple */[
              DashboardWidgetData$BsConsole._GROUP_ATTRIBUTE_TOKEN,
              groupFromQueryItemConfigs
            ],
            replaceList
          ];
  } else {
    return replaceList;
  }
}

function getValueForReplace(str, vars) {
  return Belt_List.reduce(vars, undefined, (function (acc, param) {
                var token = param[0];
                if (Belt_Option.isSome(acc)) {
                  return acc;
                } else if (str.includes(token)) {
                  return /* tuple */[
                          token,
                          param[1]
                        ];
                } else {
                  return ;
                }
              }));
}

var StackDepthExceeded = Caml_exceptions.create("DashboardWidgetGrid-BsConsole.StringTemplating.StackDepthExceeded");

function replaceAll(_str, vars, _stackDepthOpt, _param) {
  while(true) {
    var stackDepthOpt = _stackDepthOpt;
    var str = _str;
    var stackDepth = stackDepthOpt !== undefined ? stackDepthOpt : 0;
    if (stackDepth > 1000) {
      throw StackDepthExceeded;
    }
    var match = getValueForReplace(str, vars);
    if (match === undefined) {
      return str;
    }
    _param = undefined;
    _stackDepthOpt = stackDepth + 1 | 0;
    _str = str.replace(new RegExp(match[0], "g"), match[1]);
    continue ;
  };
}

function getQueryItem(queryItem, versionAttribute) {
  var replaceList = getTemplateReplaceList(queryItem, versionAttribute);
  var queryJson;
  try {
    queryJson = Caml_option.some(JSON.parse(replaceAll(JSON.stringify(queryItem.queryJson), replaceList, undefined, undefined)));
  }
  catch (_error){
    queryJson = undefined;
  }
  var name = replaceAll(queryItem.name, replaceList, undefined, undefined);
  var queryItemConfigs = Belt_Option.map(queryItem.queryItemConfigs, (function (configs) {
          return Belt_List.map(configs, (function (c) {
                        return {
                                id: c.id,
                                key: c.key,
                                value: replaceAll(c.value, replaceList, undefined, undefined),
                                universeName: c.universeName,
                                projectName: c.projectName
                              };
                      }));
        }));
  var queryItem_id = queryItem.id;
  var queryItem_name = queryItem.name;
  var queryItem_query = queryItem.query;
  var queryItem_queryJson = queryItem.queryJson;
  var queryItem_variant = queryItem.variant;
  var queryItem_layoutsJs = queryItem.layoutsJs;
  if (queryJson !== undefined) {
    return {
            id: queryItem_id,
            name: name,
            query: ApiOverview$BsConsole.QueryItem.toCrdb(Caml_option.valFromOption(queryJson)),
            queryJson: queryItem_queryJson,
            queryItemConfigs: queryItemConfigs,
            variant: queryItem_variant,
            layoutsJs: queryItem_layoutsJs
          };
  } else {
    return {
            id: queryItem_id,
            name: name,
            query: queryItem_query,
            queryJson: queryItem_queryJson,
            queryItemConfigs: queryItemConfigs,
            variant: queryItem_variant,
            layoutsJs: queryItem_layoutsJs
          };
  }
}

var StringTemplating = {
  getValueForReplace: getValueForReplace,
  StackDepthExceeded: StackDepthExceeded,
  maximumStackDepth: 1000,
  replaceAll: replaceAll,
  getQueryItem: getQueryItem
};

function DashboardWidgetGrid$DashboardItem(Props) {
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var serviceVersion = Props.serviceVersion;
  var token = Props.token;
  var universe = Props.universe;
  var queryItem = Props.queryItem;
  var aperture = Props.aperture;
  var apertureForQuery = Props.apertureForQuery;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var attributes = Props.attributes;
  var config = Props.config;
  var versionAttribute = Props.versionAttribute;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var updateProjectFrontendSettingsRemote = Props.updateProjectFrontendSettingsRemote;
  var dashboard = Props.dashboard;
  var refetchDashboard = Props.refetchDashboard;
  var overrides = Props.overrides;
  var bubbleUpError = Props.bubbleUpError;
  var needsVersionAttribute = DashboardWidgetData$BsConsole.hasVersionAttribute(queryItem);
  var project = Belt_Option.getExn(Configuration$BsConsole.getProject(projectName, config));
  var queryItem$1 = getQueryItem(queryItem, versionAttribute);
  if (needsVersionAttribute && versionAttribute === undefined && onVersionAttributeChange !== undefined) {
    return React.createElement(DashboardWidgets$BsConsole.ChooseVersion.make, {
                attributes: attributes,
                onVersionAttributeChange: (function (attribute) {
                    Curry._1(onVersionAttributeChange, attribute);
                    return Curry._1(updateProjectFrontendSettingsRemote, undefined);
                  }),
                token: token,
                project: project
              });
  }
  var tmp = {
    projectName: projectName,
    serviceEndpoint: serviceEndpoint,
    serviceVersion: serviceVersion,
    token: token,
    universe: universe,
    queryItem: queryItem$1,
    aperture: aperture,
    apertureForQuery: apertureForQuery,
    handleChangeUrl: handleChangeUrl,
    handleAddFilters: handleAddFilters,
    versionAttribute: versionAttribute,
    dashboard: dashboard,
    config: config,
    attributes: attributes,
    refetchDashboard: refetchDashboard,
    bubbleUpError: bubbleUpError
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  if (overrides !== undefined) {
    tmp.overrides = Caml_option.valFromOption(overrides);
  }
  return React.createElement(DashboardWidgets$BsConsole.make, tmp);
}

var DashboardItem = {
  make: DashboardWidgetGrid$DashboardItem
};

function makeCssFriendly(name) {
  return name.replace(new RegExp("[^a-zA-Z0-9]", "g"), "").toLowerCase();
}

function DashboardWidgetGrid(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var config = Props.config;
  var serviceEndpoint = Props.serviceEndpoint;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var attributes = Props.attributes;
  var updateProjectFrontendSettingsRemote = Props.updateProjectFrontendSettingsRemote;
  var dashboard = Props.dashboard;
  var versionAttribute = Props.versionAttribute;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var overrides = Props.overrides;
  var bubbleUpError = Props.bubbleUpError;
  var apertureForQuery = BacktraceHooks$BsConsole.getApertureForMultipleQueries(aperture, token, projectName);
  var match = FetchProjectOverview$BsConsole.useLayoutList(token, projectName, config.universe.name, serviceEndpoint, dashboard);
  var refetchLayoutList = match[1];
  var layoutListRemote = match[0];
  if (typeof layoutListRemote === "number") {
    layoutListRemote === /* NotAsked */0;
  } else if (layoutListRemote.tag) {
    if (apertureForQuery !== undefined) {
      return I18N$BsConsole.dynamic(layoutListRemote[0]);
    }
    
  } else {
    if (apertureForQuery !== undefined) {
      var apertureForQuery$1 = Caml_option.valFromOption(apertureForQuery);
      var queryList = layoutListRemote[0];
      return React.createElement(ReactGridLayout$BsConsole.ResponsiveWithSizeDetection.make, {
                  layouts: queryList.layoutsJs,
                  cols: queryList.cols,
                  rowHeight: queryList.rowHeight,
                  breakpoints: queryList.breakpoints,
                  children: Belt_Array.map(queryList.queries, (function (queryItem) {
                          var needsVersionAttribute = DashboardWidgetData$BsConsole.hasVersionAttribute(queryItem);
                          var tmp = {
                            projectName: projectName,
                            serviceEndpoint: serviceEndpoint,
                            serviceVersion: queryList.version,
                            token: token,
                            universe: config.universe.name,
                            queryItem: queryItem,
                            aperture: aperture,
                            apertureForQuery: apertureForQuery$1,
                            handleChangeUrl: handleChangeUrl,
                            handleAddFilters: handleAddFilters,
                            attributes: attributes,
                            config: config,
                            versionAttribute: needsVersionAttribute ? versionAttribute : undefined,
                            updateProjectFrontendSettingsRemote: updateProjectFrontendSettingsRemote,
                            dashboard: dashboard,
                            refetchDashboard: refetchLayoutList,
                            bubbleUpError: bubbleUpError,
                            key: String(queryItem.id) + "__item"
                          };
                          if (onVersionAttributeChange !== undefined) {
                            tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
                          }
                          if (overrides !== undefined) {
                            tmp.overrides = Caml_option.valFromOption(overrides);
                          }
                          return React.createElement("div", {
                                      key: String(queryItem.id),
                                      className: Css.merge(/* :: */[
                                            "walkthrough--overview--widget_" + makeCssFriendly(queryItem.name),
                                            /* :: */[
                                              Css.style(/* :: */[
                                                    Css.overflow(/* hidden */-862584982),
                                                    /* [] */0
                                                  ]),
                                              /* [] */0
                                            ]
                                          ])
                                    }, React.createElement(DashboardWidgetGrid$DashboardItem, tmp));
                        }))
                });
    }
    if (apertureForQuery !== undefined) {
      return I18N$BsConsole.dynamic(layoutListRemote[0]);
    }
    
  }
  return React.createElement(ViewLoading$BsConsole.Jsx3.make, {
              label: "Fetching overview data"
            });
}

var make = DashboardWidgetGrid;

exports.getTemplateReplaceList = getTemplateReplaceList;
exports.StringTemplating = StringTemplating;
exports.DashboardItem = DashboardItem;
exports.makeCssFriendly = makeCssFriendly;
exports.make = make;
/* Css Not a pure module */
