// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");

var truncate = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  truncate: truncate
};

function Truncate(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("span", {
              className: Css.merge(/* :: */[
                    truncate,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = Truncate;

exports.Style = Style;
exports.make = make;
/* truncate Not a pure module */
