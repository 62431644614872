// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var Current$BsConsole = require("./Current.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var SearchBar$BsConsole = require("../SearchBar.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SavedQueryItem$BsConsole = require("./SavedQueryItem.js");
var SavedQueriesSave$BsConsole = require("./SavedQueriesSave.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var SavedQueriesDelete$BsConsole = require("./SavedQueriesDelete.js");
var SavedQueriesDetail$BsConsole = require("./SavedQueriesDetail.js");
var SavedQueriesReplace$BsConsole = require("./SavedQueriesReplace.js");

var listItem = Css.style(/* :: */[
      Css.padding2(/* `rem */[
            5691738,
            2 / 3
          ], /* `rem */[
            5691738,
            1
          ]),
      /* :: */[
        Css.margin4(/* zero */-789508312, Css.px(16), Css.px(8), Css.px(16)),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
          /* :: */[
            Css.borderRadius(Css.px(4)),
            /* [] */0
          ]
        ]
      ]
    ]);

var padRight = Css.style(/* :: */[
      Css.paddingRight(/* `rem */[
            5691738,
            1
          ]),
      /* [] */0
    ]);

var savedQueryItemTitle = Css.style(/* :: */[
      Css.textOverflow(/* ellipsis */166888785),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  listItem: listItem,
  padRight: padRight,
  savedQueryItemTitle: savedQueryItemTitle
};

function fuzzySaveQuerySearch(search, l) {
  if (search.length <= 0) {
    return l;
  }
  var __x = $$Array.mapi((function (i, e) {
          return {
                  q: SavedQueriesModel$BsConsole.getName(e) + (" " + SavedQueriesModel$BsConsole.getUsername(e)),
                  index: i
                };
        }), $$Array.of_list(l));
  var results = FuzzaldrinPlus.filter(__x, search, {
        key: "q"
      });
  if (results.length !== 0) {
    return $$Array.to_list($$Array.map((function (index) {
                      return List.nth(l, index);
                    }), $$Array.map((function (r) {
                          return r.index;
                        }), results)));
  } else {
    return /* [] */0;
  }
}

function modeToString(mode$prime) {
  switch (mode$prime) {
    case /* Inbox */0 :
        return "inbox";
    case /* Aggregate */1 :
        return "aggregate";
    case /* Instance */2 :
        return "instance";
    
  }
}

function modeOfStringExn(str$prime) {
  switch (str$prime) {
    case "aggregate" :
        return /* Aggregate */1;
    case "inbox" :
        return /* Inbox */0;
    case "instance" :
        return /* Instance */2;
    default:
      throw Caml_builtin_exceptions.not_found;
  }
}

function prepareForSort(savedQuery, projectDefault, userDefault, projectBookmarkedViews, userBookmarkedViews) {
  var isUserBookmarked = userBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedQuery));
  var isProjectBookmarked = projectBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedQuery));
  var isUserDefault = Belt_Option.getWithDefault(Belt_Option.map(userDefault, (function (sq) {
              return SavedQueriesModel$BsConsole.equal(sq, savedQuery);
            })), false);
  var isProjectDefault = Belt_Option.getWithDefault(Belt_Option.map(projectDefault, (function (sq) {
              return SavedQueriesModel$BsConsole.equal(sq, savedQuery);
            })), false);
  var prefix = isUserBookmarked ? "AAAA_" : (
      isProjectBookmarked ? "AAA_" : (
          isUserDefault ? "AA_" : (
              isProjectDefault ? "A_" : "_"
            )
        )
    );
  return prefix + SavedQueriesModel$BsConsole.getName(savedQuery);
}

var initialState = {
  search: "",
  savedQueryMenuViewTab: 1,
  dialog: /* Load */1,
  savedDashboardCacheKey: 0
};

var component = ReasonReact.reducerComponent("SavedQueries-BsConsole");

function make(name, token, handleTask, handleChangeUrl, config, query, mode, queriesBpg, queries, refetchQueries, project, projectDefault, refetchProjectSettings, userDefault, refetchUserSettings, normBy, projectBookmarkedViews, userBookmarkedViews, open_, onClose, dialogOnOpen, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              if (dialogOnOpen === undefined) {
                return self.state;
              }
              if (!Caml_obj.caml_notequal(dialogOnOpen, self.state.dialog)) {
                return self.state;
              }
              var init = self.state;
              return {
                      search: init.search,
                      savedQueryMenuViewTab: init.savedQueryMenuViewTab,
                      dialog: dialogOnOpen,
                      savedDashboardCacheKey: init.savedDashboardCacheKey
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var onDetailsClick = function (savedQuery) {
                return Curry._1(self.send, /* Dialog */Block.__(1, [/* Details */Block.__(2, [savedQuery])]));
              };
              var onSelectSavedQuery = function (savedQuery) {
                Curry._1(handleChangeUrl, SavedQueriesModel$BsConsole.getRouteForSavedQuery(savedQuery, name));
                return Curry._1(self.send, /* OnClose */0);
              };
              var view = function (refetchQueries$1, project, queries, queriesBpg$1, config) {
                var navToLoad = function (param) {
                  return Curry._1(self.send, /* Dialog */Block.__(1, [/* Load */1]));
                };
                var navToReplace = function (savedView, query, query$prime) {
                  return Curry._1(self.send, /* Dialog */Block.__(1, [/* Replace */Block.__(1, [
                                    savedView,
                                    query,
                                    query$prime
                                  ])]));
                };
                var navToDelete = function (savedView, query$prime) {
                  return Curry._1(self.send, /* Dialog */Block.__(1, [/* Delete */Block.__(0, [
                                    savedView,
                                    query$prime
                                  ])]));
                };
                var savedView = self.state.dialog;
                if (typeof savedView === "number") {
                  if (savedView === /* Save */0) {
                    return ReasonReact.element(undefined, undefined, SavedQueriesSave$BsConsole.Re.make(project, config, query, mode, refetchQueries$1, refetchProjectSettings, refetchUserSettings, navToLoad, token, handleTask, normBy, []));
                  } else {
                    var tmp;
                    if (query !== undefined && mode !== undefined) {
                      var saved = Current$BsConsole.get(mode, query, queries);
                      if (saved) {
                        var saved$1 = saved[0];
                        tmp = ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                      Css.marginTop(/* `rem */[
                                            5691738,
                                            1.0
                                          ]),
                                      /* :: */[
                                        Css.marginBottom(/* `rem */[
                                              5691738,
                                              1.0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]), [
                                  ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.whiteSpace(/* nowrap */867913355),
                                                  /* [] */0
                                                ]
                                              ]), undefined, Colors$BsConsole.grey4, undefined, [I18N$BsConsole.show(undefined, "Current view:")])),
                                  ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.whiteSpace(/* nowrap */867913355),
                                                  /* :: */[
                                                    Css.color(/* `hex */[
                                                          5194459,
                                                          Colors$BsConsole.accent
                                                        ]),
                                                    /* :: */[
                                                      Css.overflow(/* hidden */-862584982),
                                                      /* :: */[
                                                        Css.marginLeft(/* `rem */[
                                                              5691738,
                                                              1
                                                            ]),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]), undefined, undefined, undefined, [React.createElement("span", {
                                                  className: Css.merge(/* :: */[
                                                        "link",
                                                        /* :: */[
                                                          Css.style(/* :: */[
                                                                Css.cursor(/* pointer */-786317123),
                                                                /* :: */[
                                                                  Css.whiteSpace(/* nowrap */867913355),
                                                                  /* :: */[
                                                                    Css.textOverflow(/* ellipsis */166888785),
                                                                    /* :: */[
                                                                      Css.overflow(/* hidden */-862584982),
                                                                      /* :: */[
                                                                        Css.display(/* inlineBlock */-147785676),
                                                                        /* :: */[
                                                                          Css.width(/* `percent */[
                                                                                -119887163,
                                                                                100
                                                                              ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  onClick: (function (_event) {
                                                      return onDetailsClick(saved$1);
                                                    })
                                                }, I18N$BsConsole.showSkip(SavedQueriesModel$BsConsole.getName(saved$1)))]))
                                ]));
                      } else {
                        tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                  /* Medium */861718677,
                                  Css.merge(/* :: */[
                                        NewStyles$BsConsole.containedButton,
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.paddingLeft(/* `rem */[
                                                      5691738,
                                                      1
                                                    ]),
                                                /* :: */[
                                                  Css.paddingRight(/* `rem */[
                                                        5691738,
                                                        1
                                                      ]),
                                                  /* :: */[
                                                    Css.marginTop(/* `rem */[
                                                          5691738,
                                                          1.0
                                                        ]),
                                                    /* :: */[
                                                      Css.marginBottom(/* `rem */[
                                                            5691738,
                                                            1.5
                                                          ]),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]),
                                  (function (e) {
                                      e.stopPropagation();
                                      return Curry._1(self.send, /* Dialog */Block.__(1, [/* Save */0]));
                                    }),
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                              React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.paddingRight(Css.px(4)),
                                                          /* :: */[
                                                            Css.marginLeft(Css.px(-4)),
                                                            /* :: */[
                                                              Css.height(Css.px(20)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ])
                                                  }),
                                              React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Save current view"))
                                            ]))]
                                ]));
                      }
                    } else {
                      tmp = null;
                    }
                    var match = self.state.savedQueryMenuViewTab;
                    var considerations = match !== 0 ? queries : List.filter((function (sd) {
                                var sdUsername = SavedQueriesModel$BsConsole.getUsername(sd);
                                var currentUsername = config.user.username;
                                return sdUsername === currentUsername;
                              }))(queries);
                    var results = fuzzySaveQuerySearch($$String.trim(self.state.search), considerations);
                    var userDefault$1 = Belt_Option.flatMap(userDefault, (function (ud) {
                            return Belt_List.getBy(results, (function (res) {
                                          return SavedQueriesModel$BsConsole.getName(res) === SavedQueriesModel$BsConsole.getName(ud);
                                        }));
                          }));
                    var projectDefault$1 = Belt_Option.flatMap(projectDefault, (function (pd) {
                            return Belt_List.getBy(results, (function (res) {
                                          return SavedQueriesModel$BsConsole.getName(res) === SavedQueriesModel$BsConsole.getName(pd);
                                        }));
                          }));
                    var match$1 = Belt_List.partition(Belt_List.keep(results, (function (savedQuery) {
                                if (Belt_Option.getWithDefault(Belt_Option.map(projectDefault$1, (function (sq) {
                                              return !SavedQueriesModel$BsConsole.equal(sq, savedQuery);
                                            })), true)) {
                                  return Belt_Option.getWithDefault(Belt_Option.map(userDefault$1, (function (sq) {
                                                    return !SavedQueriesModel$BsConsole.equal(sq, savedQuery);
                                                  })), true);
                                } else {
                                  return false;
                                }
                              })), (function (a) {
                            return SavedQueriesModel$BsConsole.getUsername(a) === config.user.username;
                          }));
                    var results$1 = Belt_List.concatMany([
                          Belt_Option.getWithDefault(Belt_Option.map(userDefault$1, (function (sq) {
                                      return /* :: */[
                                              sq,
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          Belt_Option.getWithDefault(Belt_Option.map(projectDefault$1, (function (sq) {
                                      return /* :: */[
                                              sq,
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          match$1[0],
                          match$1[1]
                        ]);
                    return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                        Css.height(/* `vh */[
                                              26418,
                                              100
                                            ]),
                                        /* [] */0
                                      ]), [
                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                              Css.style(/* :: */[
                                                    Css.position(/* absolute */-1013592457),
                                                    /* :: */[
                                                      Css.top(/* `rem */[
                                                            5691738,
                                                            1
                                                          ]),
                                                      /* :: */[
                                                        Css.right(/* `rem */[
                                                              5691738,
                                                              1
                                                            ]),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]),
                                              undefined,
                                              (function (param) {
                                                  return Curry._1(self.send, /* OnClose */0);
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              "Close",
                                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                            ])),
                                    React.createElement("div", {
                                          className: Css.merge(/* :: */[
                                                NewStyles$BsConsole.savedViewsTopFold,
                                                /* :: */[
                                                  Css.style(/* :: */[
                                                        Css.paddingBottom(/* `rem */[
                                                              5691738,
                                                              0.25
                                                            ]),
                                                        /* [] */0
                                                      ]),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* [] */0), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading4 */3, undefined, Css.style(/* :: */[
                                                                          Css.margin(/* zero */-789508312),
                                                                          /* [] */0
                                                                        ]), undefined, undefined, undefined, [React.createElement("div", {
                                                                            className: Css.style(/* :: */[
                                                                                  Css.display(/* flex */-1010954439),
                                                                                  /* :: */[
                                                                                    Css.flexDirection(/* row */5693978),
                                                                                    /* :: */[
                                                                                      Css.alignItems(/* center */98248149),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]
                                                                                ])
                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ViewCompact.Jsx2.make, Css.style(/* :: */[
                                                                                        Css.marginRight(Css.px(10)),
                                                                                        /* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                                                          /* :: */[
                                                                                            Css.fontSize(Css.rem(2.5)),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]), undefined, undefined, undefined, undefined, [])), I18N$BsConsole.show(undefined, "Saved views"))]))]))])), React.createElement(React.Fragment, undefined, tmp), ReasonReact.element(undefined, undefined, SearchBar$BsConsole.make(self.state.search, (function (value) {
                                                    return Curry._1(self.send, /* HandleSearchChange */Block.__(2, [value]));
                                                  }), I18N$BsConsole.get(undefined, "Search"), "100%", []))),
                                    ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, self.state.savedQueryMenuViewTab, {
                                              indicator: Css.style(/* :: */[
                                                    Css.backgroundColor(/* `hex */[
                                                          5194459,
                                                          Colors$BsConsole.accent
                                                        ]),
                                                    /* [] */0
                                                  ]),
                                              root: Css.style(/* :: */[
                                                    Css.color(/* `hex */[
                                                          5194459,
                                                          Colors$BsConsole.accent
                                                        ]),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(1)),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, (function (_event, value) {
                                                return Curry._1(self.send, /* SetSavedQueryDashboardTab */Block.__(0, [value]));
                                              }), undefined, undefined, true, true, [
                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, Css.style(/* :: */[
                                                            Css.textTransform(/* unset */653193961),
                                                            /* [] */0
                                                          ]), undefined, Caml_option.some(I18N$BsConsole.show(undefined, "My views")), undefined, [])),
                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, Css.style(/* :: */[
                                                            Css.textTransform(/* unset */653193961),
                                                            /* [] */0
                                                          ]), undefined, Caml_option.some(I18N$BsConsole.show(undefined, "All")), undefined, []))
                                            ])),
                                    React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.padding(/* zero */-789508312),
                                                /* :: */[
                                                  Css.overflowY(/* auto */-1065951377),
                                                  /* :: */[
                                                    Css.padding2(Css.px(8), /* zero */-789508312),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ])
                                        }, Belt_List.length(results$1) === 0 ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, listItem, [React.createElement("span", {
                                                          className: savedQueryItemTitle
                                                        }, List.length(considerations) > 0 ? I18N$BsConsole.show(undefined, "No match") : I18N$BsConsole.show(undefined, "No saved views"))])) : Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(results$1, (function (a, b) {
                                                          return $$String.compare(prepareForSort(a, projectDefault$1, userDefault$1, projectBookmarkedViews, userBookmarkedViews), prepareForSort(b, projectDefault$1, userDefault$1, projectBookmarkedViews, userBookmarkedViews));
                                                        })), (function (idx, savedQuery) {
                                                      var isProjectDefault = Belt_Option.map(projectDefault$1, (function (sq) {
                                                              return SavedQueriesModel$BsConsole.equal(sq, savedQuery);
                                                            }));
                                                      var isUserDefault = Belt_Option.map(userDefault$1, (function (sq) {
                                                              return SavedQueriesModel$BsConsole.equal(sq, savedQuery);
                                                            }));
                                                      var isProjectBookmarked = projectBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedQuery));
                                                      var isUserBookmarked = userBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedQuery));
                                                      var queryBPGItem = Belt_Option.flatMap(queriesBpg, (function (bpgItems) {
                                                              return Belt_List.getBy(bpgItems, (function (query) {
                                                                            return query.name === "%dashboard% " + SavedQueriesModel$BsConsole.getName(savedQuery);
                                                                          }));
                                                            }));
                                                      return ReasonReact.element(SavedQueriesModel$BsConsole.getName(savedQuery) + ("__" + String(idx)), undefined, SavedQueryItem$BsConsole.Re.make(savedQuery, isUserDefault, isProjectDefault, undefined, isProjectBookmarked, isUserBookmarked, handleChangeUrl, name, (function (param) {
                                                                        return Curry._1(self.send, /* OnClose */0);
                                                                      }), onDetailsClick, (function (savedView) {
                                                                        if (queryBPGItem !== undefined) {
                                                                          return Curry._1(self.send, /* Dialog */Block.__(1, [/* Delete */Block.__(0, [
                                                                                            savedView,
                                                                                            queryBPGItem
                                                                                          ])]));
                                                                        }
                                                                        var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueries/SavedQueryItem.Re/handleOnDeleteClicked", Backtrace$BsConsole.Attributes.make(undefined));
                                                                        Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                              288368849,
                                                                              "Error deleting saved view"
                                                                            ]);
                                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: Unable to delete view"));
                                                                      }), (function (savedView) {
                                                                        if (queryBPGItem !== undefined && query !== undefined) {
                                                                          return Curry._1(self.send, /* Dialog */Block.__(1, [/* Replace */Block.__(1, [
                                                                                            savedView,
                                                                                            queryBPGItem,
                                                                                            query
                                                                                          ])]));
                                                                        }
                                                                        var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueries/SavedQueryItem.Re/handleOnReplaceClicked", Backtrace$BsConsole.Attributes.make(undefined));
                                                                        Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                              288368849,
                                                                              "Error replacing saved view"
                                                                            ]);
                                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: Unable to replace view"));
                                                                      }), refetchQueries, refetchProjectSettings, refetchUserSettings, config, token, []));
                                                    }))))
                                  ]));
                  }
                }
                switch (savedView.tag | 0) {
                  case /* Delete */0 :
                      var savedView$1 = savedView[0];
                      var navToDetails = function (param) {
                        return Curry._1(self.send, /* Dialog */Block.__(1, [/* Details */Block.__(2, [savedView$1])]));
                      };
                      return ReasonReact.element(undefined, undefined, SavedQueriesDelete$BsConsole.Re.make(savedView$1, savedView[1], refetchQueries$1, handleTask, token, navToLoad, navToDetails, config, project.pid, projectDefault, refetchProjectSettings, userDefault, refetchUserSettings, name, []));
                  case /* Replace */1 :
                      var savedView$2 = savedView[0];
                      return ReasonReact.element(undefined, undefined, SavedQueriesReplace$BsConsole.Re.make(name, project.pid, savedView$2, savedView[1], savedView[2], token, handleTask, refetchQueries$1, mode, config, navToLoad, (function (param) {
                                        return Curry._1(self.send, /* OnClose */0);
                                      }), (function (param) {
                                        return Curry._1(self.send, /* Dialog */Block.__(1, [/* Details */Block.__(2, [savedView$2])]));
                                      }), projectDefault, refetchProjectSettings, userDefault, refetchUserSettings, normBy, []));
                  case /* Details */2 :
                      var savedView$3 = savedView[0];
                      var isProjectBookmarked = projectBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedView$3));
                      var isUserBookmarked = userBookmarkedViews.includes(SavedQueriesModel$BsConsole.getName(savedView$3));
                      return ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(NewStyles$BsConsole.theme, [ReasonReact.element(undefined, undefined, SavedQueriesDetail$BsConsole.Re.make(savedView$3, project.pid, queries, navToLoad, (function (param) {
                                                  return Curry._1(self.send, /* OnClose */0);
                                                }), query, mode, navToReplace, navToDelete, onSelectSavedQuery, queriesBpg$1, token, config, name, projectDefault, refetchProjectSettings, userDefault, refetchUserSettings, isProjectBookmarked, isUserBookmarked, []))]));
                  
                }
              };
              var tmp;
              var exit = 0;
              if (queries !== undefined && queriesBpg !== undefined && project !== undefined) {
                tmp = view(refetchQueries, project, queries, queriesBpg, config);
              } else {
                exit = 1;
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                              Css.height(Css.vh(100)),
                              /* :: */[
                                Css.color(/* `hex */[
                                      5194459,
                                      Colors$BsConsole.accent
                                    ]),
                                /* [] */0
                              ]
                            ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []))]));
              }
              return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Drawer.Jsx2.make, {
                              paper: Css.style(/* :: */[
                                    Css.width(/* `px */[
                                          25096,
                                          450
                                        ]),
                                    /* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.flexDirection(/* column */-963948842),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            }, undefined, undefined, open_, /* Right */-57574468, (function (param) {
                                return Curry._1(self.send, /* OnClose */0);
                              }), [tmp]));
            }),
          initialState: (function (param) {
              return initialState;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          initialState,
                          (function (_self) {
                              return Curry._1(onClose, undefined);
                            })
                        ]);
              }
              switch (action.tag | 0) {
                case /* SetSavedQueryDashboardTab */0 :
                    return /* Update */Block.__(0, [{
                                search: state.search,
                                savedQueryMenuViewTab: action[0],
                                dialog: state.dialog,
                                savedDashboardCacheKey: state.savedDashboardCacheKey
                              }]);
                case /* Dialog */1 :
                    return /* Update */Block.__(0, [{
                                search: state.search,
                                savedQueryMenuViewTab: state.savedQueryMenuViewTab,
                                dialog: action[0],
                                savedDashboardCacheKey: state.savedDashboardCacheKey
                              }]);
                case /* HandleSearchChange */2 :
                    return /* Update */Block.__(0, [{
                                search: action[0],
                                savedQueryMenuViewTab: state.savedQueryMenuViewTab,
                                dialog: state.dialog,
                                savedDashboardCacheKey: state.savedDashboardCacheKey
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (r) {
        return make(r.name, r.token, r.handleTask, r.handleChangeUrl, r.config, r.query, r.mode, r.queriesBpg, r.queries, r.refetchQueries, r.project, r.projectDefault, r.refetchProjectSettings, r.userDefault, r.refetchUserSettings, r.normBy, r.projectBookmarkedViews, r.userBookmarkedViews, r.open_, r.onClose, Caml_option.undefined_to_opt(r.dialogOnOpen), []);
      }));

var Jsx3 = {
  make: make$1
};

var Drawer = {
  initialState: initialState,
  component: component,
  make: make,
  Jsx3: Jsx3
};

exports.Styles = Styles;
exports.fuzzySaveQuerySearch = fuzzySaveQuerySearch;
exports.modeToString = modeToString;
exports.modeOfStringExn = modeOfStringExn;
exports.prepareForSort = prepareForSort;
exports.Drawer = Drawer;
/* listItem Not a pure module */
