// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Regex$BsConsole = require("../Regex.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var ApiDescribe$BsConsole = require("../apiDescribe.js");
var BtTypography$BsConsole = require("../BtTypography.js");

var ParseError = Caml_exceptions.create("AlertsUtils-BsConsole.ParseError");

var errorCountAggregation = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "_tx",
      /* Count */9
    ]);

var errorCountAttribute = "_tx";

var errorCountOperation = "count";

var errorCount = I18N$BsConsole.get(undefined, "_tx - count");

var userFriendlyErrorCount = I18N$BsConsole.get(undefined, "error - count");

var userFriendlyErrorCountAlt = I18N$BsConsole.get(undefined, "errors");

function foldWithoutErrorCountAgg(fold) {
  var aggregation = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg) {
          if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === errorCountAttribute) {
            return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === errorCountOperation;
          } else {
            return false;
          }
        }));
  if (aggregation !== undefined) {
    return Curry._2(Crdb$BsConsole.Fold.remove, Caml_option.valFromOption(aggregation), fold);
  } else {
    return fold;
  }
}

var selectStyle = Css.style(/* :: */[
      Css.minWidth(Css.px(200)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
        /* :: */[
          Css.marginLeft(Css.px(3)),
          /* [] */0
        ]
      ]
    ]);

var isValidName = Regex$BsConsole.isAlertName;

function AlertsUtils$AlertWarning(Props) {
  var message = Props.message;
  if (message !== undefined) {
    return React.createElement(Row2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.px(8)),
                      /* [] */0
                    ]),
                children: React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      color: Colors$BsConsole.redDark,
                      children: I18N$BsConsole.showSkip(message)
                    })
              });
  } else {
    return null;
  }
}

var AlertWarning = {
  make: AlertsUtils$AlertWarning
};

function getOperation(fold, agg, param) {
  var attributeFold = Belt_Array.keep(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (a) {
          return Curry._1(Crdb$BsConsole.Aggregation.getAttribute, a) === agg.column;
        }));
  var theFold = Belt_Array.get(attributeFold, agg.index);
  if (theFold !== undefined) {
    return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, Caml_option.valFromOption(theFold)));
  }
  
}

function newFoldWithAttOpRemoved(fold, attrToRemove, opToRemove, param) {
  var aggs = Curry._1(Crdb$BsConsole.Fold.toArray, fold);
  return Curry._1(Crdb$BsConsole.Fold.fromArray, Belt_Array.keep(aggs, (function (agg) {
                    var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                    return !(match[0] === attrToRemove && match[1] === opToRemove);
                  })));
}

function newFoldWithAttOpAdded(fold, attrToAdd, opToAdd, param) {
  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  attrToAdd,
                  opToAdd
                ]), fold);
}

function getAllAttributes(projectName, param) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "action",
          "describe"
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?action=describe&project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                try {
                  var attributes = ApiDescribe$BsConsole.decodeExn(respBag.json);
                  return /* Ok */Block.__(0, [attributes]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "AlertsUtils/getAllAttributes", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function getInvalidTriggerConditions(triggerConditions) {
  return Belt_List.keepMap(Belt_List.keepMap(triggerConditions, (function (t) {
                    return t;
                  })), (function (t) {
                var match = t.comparisonOperator;
                switch (match) {
                  case /* GreaterThanOrEqual */0 :
                      if (t.criticalThreshold < t.warningThreshold) {
                        return t;
                      } else {
                        return ;
                      }
                  case /* LessThanOrEqual */1 :
                      if (t.warningThreshold < t.criticalThreshold) {
                        return t;
                      } else {
                        return ;
                      }
                  case /* NoThreshold */2 :
                      return ;
                  
                }
              }));
}

function getValidTriggerConditions(triggerConditions) {
  return Belt_List.keepMap(Belt_List.keepMap(triggerConditions, (function (t) {
                    return t;
                  })), (function (t) {
                var match = t.comparisonOperator;
                switch (match) {
                  case /* GreaterThanOrEqual */0 :
                      if (t.criticalThreshold >= t.warningThreshold) {
                        return t;
                      } else {
                        return ;
                      }
                  case /* LessThanOrEqual */1 :
                      if (t.warningThreshold >= t.criticalThreshold) {
                        return t;
                      } else {
                        return ;
                      }
                  case /* NoThreshold */2 :
                      return ;
                  
                }
              }));
}

function getHavingsFromHavingsArr(havingsArr) {
  return Belt_Array.reduce(havingsArr, Crdb$BsConsole.Havings.empty, (function (acc, having) {
                if (having !== undefined) {
                  return Curry._2(Crdb$BsConsole.Havings.add, Caml_option.valFromOption(having), acc);
                } else {
                  return acc;
                }
              }));
}

function AlertsUtils$FrontEllipsis(Props) {
  var text = Props.text;
  var widthValue = Props.widthValue;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.textAlign(/* left */-944764921),
                    /* :: */[
                      Css.textOverflow(/* ellipsis */166888785),
                      /* :: */[
                        Css.maxWidth(widthValue),
                        /* :: */[
                          Css.whiteSpace(/* nowrap */867913355),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* :: */[
                              Css.direction(/* rtl */5695082),
                              /* :: */[
                                Css.textAlign(/* left */-944764921),
                                /* :: */[
                                  Css.marginRight(Css.px(5)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.showSkip(text));
}

var FrontEllipsis = {
  make: AlertsUtils$FrontEllipsis
};

var servicename = "alerts";

exports.servicename = servicename;
exports.ParseError = ParseError;
exports.errorCountAggregation = errorCountAggregation;
exports.errorCountAttribute = errorCountAttribute;
exports.errorCountOperation = errorCountOperation;
exports.errorCount = errorCount;
exports.userFriendlyErrorCount = userFriendlyErrorCount;
exports.userFriendlyErrorCountAlt = userFriendlyErrorCountAlt;
exports.foldWithoutErrorCountAgg = foldWithoutErrorCountAgg;
exports.selectStyle = selectStyle;
exports.isValidName = isValidName;
exports.AlertWarning = AlertWarning;
exports.getOperation = getOperation;
exports.newFoldWithAttOpRemoved = newFoldWithAttOpRemoved;
exports.newFoldWithAttOpAdded = newFoldWithAttOpAdded;
exports.getAllAttributes = getAllAttributes;
exports.getInvalidTriggerConditions = getInvalidTriggerConditions;
exports.getValidTriggerConditions = getValidTriggerConditions;
exports.getHavingsFromHavingsArr = getHavingsFromHavingsArr;
exports.FrontEllipsis = FrontEllipsis;
/* errorCountAggregation Not a pure module */
