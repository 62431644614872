// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = ReasonReact.reducerComponent("WfEnumArray-BsConsole");

function make(fieldDoc, initialValue, onValueChange, showAllErrors, remoteAction, param, enumSchema, param$1) {
  var setElementRef = function (nullableRef, param) {
    param.state.elementRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              Curry._1(onValueChange, /* Ok */Block.__(0, [param.state.checkedItems]));
              return Curry._1(remoteAction, (function (param) {
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var checkedItems = state.checkedItems;
              var send = param.send;
              return React.createElement("div", {
                          ref: Curry._1(param.handle, setElementRef)
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, undefined, undefined, undefined, undefined, state.dirty, showAllErrors, [React.createElement("div", undefined, Belt_Array.map(enumSchema.items, (function (item) {
                                              return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                  undefined,
                                                                  Caml_option.some(I18N$BsConsole.showSkip(item.label)),
                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                undefined,
                                                                                /* `Bool */[
                                                                                  737456202,
                                                                                  checkedItems.includes(item.value)
                                                                                ],
                                                                                undefined,
                                                                                /* Primary */-791844958,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                (function (param, checked) {
                                                                                    if (checked) {
                                                                                      return Curry._1(send, /* ItemChecked */Block.__(0, [item.value]));
                                                                                    } else {
                                                                                      return Curry._1(send, /* ItemUnchecked */Block.__(1, [item.value]));
                                                                                    }
                                                                                  }),
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                []
                                                                              ]))),
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  []
                                                                ])));
                                            })))])));
            }),
          initialState: (function (param) {
              return {
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      checkedItems: Belt_Option.getWithDefault(Belt_Option.map(initialValue, (function (param) {
                                  return Json_decode.array(Util$BsConsole.identity, param);
                                })), [])
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                var item = action[0];
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            dirty: state.dirty,
                            elementRef: state.elementRef,
                            checkedItems: Belt_Array.keep(state.checkedItems, (function (ci) {
                                    return Caml_obj.caml_notequal(ci, item);
                                  }))
                          },
                          (function (param) {
                              return Curry._1(onValueChange, /* Ok */Block.__(0, [param.state.checkedItems]));
                            })
                        ]);
              }
              var item$1 = action[0];
              if (state.checkedItems.includes(item$1)) {
                return /* NoUpdate */0;
              } else {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            dirty: state.dirty,
                            elementRef: state.elementRef,
                            checkedItems: Belt_Array.concat(state.checkedItems, [item$1])
                          },
                          (function (param) {
                              return Curry._1(onValueChange, /* Ok */Block.__(0, [param.state.checkedItems]));
                            })
                        ]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
