// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");

function toString(param) {
  if (param) {
    return "greater or equal";
  } else {
    return "greater than";
  }
}

function ofString(op) {
  switch (op) {
    case "greater or equal" :
        return /* GreaterOrEqual */1;
    case "greater than" :
        return /* GreaterThan */0;
    default:
      return ;
  }
}

var InvariantOperator = {
  toString: toString,
  ofString: ofString
};

function ReopenVersion(Props) {
  Props.config;
  Props.token;
  var version = Props.version;
  var setVersion = Props.setVersion;
  var field = Props.field;
  var setField = Props.setField;
  var operator = Props.operator;
  var setOperator = Props.setOperator;
  var attributesRemotes = Props.attributesRemotes;
  var greatestAttributeRemote = Belt_List.getBy(attributesRemotes, (function (param) {
          return Caml_obj.caml_equal(param[0].name, field);
        }));
  var tmp;
  if (greatestAttributeRemote !== undefined) {
    var match = greatestAttributeRemote[1];
    tmp = typeof match === "number" && match !== 0 ? I18N$BsConsole.get(undefined, "...loading") : I18N$BsConsole.get(undefined, "...");
  } else {
    tmp = I18N$BsConsole.get(undefined, "...");
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Select.default, {
                      value: /* `String */[
                        -976970511,
                        Belt_Option.getWithDefault(field, "")
                      ],
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          var v = value[1];
                          Belt_List.forEach(attributesRemotes, (function (param) {
                                  var remoteValue = param[1];
                                  var match = Caml_obj.caml_equal(param[0], v);
                                  if (!match) {
                                    return ;
                                  }
                                  if (typeof remoteValue === "number") {
                                    return ;
                                  }
                                  if (remoteValue.tag) {
                                    return ;
                                  }
                                  var v$1 = remoteValue[0];
                                  return Curry._1(setVersion, (function (param) {
                                                return v$1;
                                              }));
                                }));
                          Curry._1(setVersion, (function (param) {
                                  
                                }));
                          return Curry._1(setField, (function (param) {
                                        return Caml_option.some(v);
                                      }));
                        }),
                      renderValue: (function (value) {
                          return React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip(value[1])
                                    });
                        }),
                      disableUnderline: true,
                      children: Belt_Array.map(Belt_List.toArray(attributesRemotes), (function (param) {
                              var name = param[0].name;
                              return React.createElement(MenuItem.default, {
                                          value: /* `String */[
                                            -976970511,
                                            name
                                          ],
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Caption */11,
                                                children: I18N$BsConsole.showSkip(name)
                                              })
                                        });
                            }))
                    }), React.createElement(Select.default, {
                      value: operator,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          console.log(value);
                          return Curry._1(setOperator, value);
                        }),
                      renderValue: (function (value) {
                          return React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip(value ? "greater or equal" : "greater than")
                                    });
                        }),
                      disableUnderline: true,
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: /* GreaterThan */0,
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Caption */11,
                                children: I18N$BsConsole.showSkip("greater than")
                              })
                        }), CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.61.28") ? React.createElement(MenuItem.default, {
                            value: /* GreaterOrEqual */1,
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.showSkip("greater or equal")
                                })
                          }) : null)), React.createElement("div", undefined, React.createElement(TextField.default, {
                      helperText: "",
                      disabled: Belt_Option.isNone(field),
                      error: false,
                      placeholder: tmp,
                      value: Belt_Option.getWithDefault(version, ""),
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setVersion, (function (param) {
                                        return value;
                                      }));
                        })
                    })));
}

var make = ReopenVersion;

exports.InvariantOperator = InvariantOperator;
exports.make = make;
/* react Not a pure module */
