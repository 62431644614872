'use strict';

var NodeRe = require("./NodeRe.js");
var DomTypesRe = require("../DomTypesRe.js");
var SlotableRe = require("./SlotableRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ChildNodeRe = require("./ChildNodeRe.js");
var ParentNodeRe = require("./ParentNodeRe.js");
var EventTargetRe = require("../events/EventTargetRe.js");
var NonDocumentTypeChildNodeRe = require("./NonDocumentTypeChildNodeRe.js");

function ofNode(node) {
  if (NodeRe.nodeType(node) === /* Element */0) {
    return Caml_option.some(node);
  }
  
}

function Impl(T) {
  var asHtmlElement = (function (element) {
      // BEWARE: Assumes "contentEditable" uniquely identifies an HTMLELement
      return element.contentEditable !== undefined ?  element : null;
    });
  var asHtmlElement$1 = function (self) {
    return Caml_option.null_to_opt(asHtmlElement(self));
  };
  var insertAdjacentElement = function (position, element, self) {
    self.insertAdjacentElement(DomTypesRe.encodeInsertPosition(position), element);
    
  };
  var insertAdjacentHTML = function (position, text, self) {
    self.insertAdjacentHTML(DomTypesRe.encodeInsertPosition(position), text);
    
  };
  var insertAdjacentText = function (position, text, self) {
    self.insertAdjacentText(DomTypesRe.encodeInsertPosition(position), text);
    
  };
  return {
          asHtmlElement: asHtmlElement$1,
          ofNode: ofNode,
          insertAdjacentElement: insertAdjacentElement,
          insertAdjacentHTML: insertAdjacentHTML,
          insertAdjacentText: insertAdjacentText
        };
}

var include = NodeRe.Impl({ });

EventTargetRe.Impl({ });

ParentNodeRe.Impl({ });

NonDocumentTypeChildNodeRe.Impl({ });

ChildNodeRe.Impl({ });

SlotableRe.Impl({ });

var asHtmlElement = (function (element) {
      // BEWARE: Assumes "contentEditable" uniquely identifies an HTMLELement
      return element.contentEditable !== undefined ?  element : null;
    });

function asHtmlElement$1(self) {
  return Caml_option.null_to_opt(asHtmlElement(self));
}

function insertAdjacentElement(position, element, self) {
  self.insertAdjacentElement(DomTypesRe.encodeInsertPosition(position), element);
  
}

function insertAdjacentHTML(position, text, self) {
  self.insertAdjacentHTML(DomTypesRe.encodeInsertPosition(position), text);
  
}

function insertAdjacentText(position, text, self) {
  self.insertAdjacentText(DomTypesRe.encodeInsertPosition(position), text);
  
}

var nodeType = include.nodeType;

exports.Impl = Impl;
exports.nodeType = nodeType;
exports.asHtmlElement = asHtmlElement$1;
exports.ofNode = ofNode;
exports.insertAdjacentElement = insertAdjacentElement;
exports.insertAdjacentHTML = insertAdjacentHTML;
exports.insertAdjacentText = insertAdjacentText;
/* include Not a pure module */
