// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_MutableSet = require("bs-platform/lib/js/belt_MutableSet.js");

var AuthInfo = { };

function cmp(param, param$1) {
  return Caml_obj.caml_compare(param[0], param$1[0]);
}

var Cmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var memorySet = Belt_MutableSet.make(Cmp);

function forget(key) {
  var shouldBeRemoved = Belt_MutableSet.toArray(Belt_MutableSet.keep(memorySet, (function (param) {
              return param[0] === key;
            })));
  return Belt_MutableSet.removeMany(memorySet, shouldBeRemoved);
}

function memorize(param) {
  var key = param[0];
  forget(key);
  return Belt_MutableSet.add(memorySet, /* tuple */[
              key,
              param[1]
            ]);
}

function memorizeMany(many) {
  return Belt_List.forEach(many, memorize);
}

function empty(param) {
  return Belt_Array.forEach(Belt_MutableSet.toArray(memorySet), (function (param) {
                return forget(param[0]);
              }));
}

exports.AuthInfo = AuthInfo;
exports.Cmp = Cmp;
exports.memorySet = memorySet;
exports.forget = forget;
exports.memorize = memorize;
exports.memorizeMany = memorizeMany;
exports.empty = empty;
/* Cmp Not a pure module */
