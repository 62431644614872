// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var InputChip$BsConsole = require("../InputChip.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var AlertsTrigger$BsConsole = require("./AlertsTrigger.js");
var InboxWorkflow$BsConsole = require("../inbox/InboxWorkflow.js");
var Select = require("@material-ui/core/Select");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var OutlinedInput = require("@material-ui/core/OutlinedInput");

var chipLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.black)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
        /* :: */[
          Css.fontSize(/* `px */[
                25096,
                12
              ]),
          /* :: */[
            Css.whiteSpace(/* nowrap */867913355),
            /* :: */[
              Css.overflow(/* hidden */-862584982),
              /* :: */[
                Css.textOverflow(/* ellipsis */166888785),
                /* :: */[
                  Css.borderRadius(Css.px(3)),
                  /* :: */[
                    Css.maxWidth(Css.px(100)),
                    /* :: */[
                      Css.height(Css.px(24)),
                      /* :: */[
                        Css.marginRight(Css.px(3)),
                        /* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.padding(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function optionalTooltip(title) {
  if (title.length > 16) {
    return title;
  } else {
    return "";
  }
}

function getFactorString(f) {
  if (!f) {
    return "fingerprint";
  }
  var factor$prime = f[0];
  var name = InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(factor$prime);
  if (name !== undefined) {
    return name;
  } else {
    return factor$prime;
  }
}

function AlertsQueryDisplay(Props) {
  var factor = Props.factor;
  var setFactor = Props.setFactor;
  var factorOptions = Props.factorOptions;
  var filters = Props.filters;
  var fold = Props.fold;
  var havings = Props.havings;
  var aggs = Curry._1(Crdb$BsConsole.Fold.toArray, fold);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.borderBottom(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey65)),
                      /* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey0,
                  children: I18N$BsConsole.show(undefined, "Query")
                }), React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(10)),
                        /* :: */[
                          Css.marginBottom(Css.px(10)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(5)),
                                /* [] */0
                              ])
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Button */10,
                              color: Colors$BsConsole.grey4,
                              children: I18N$BsConsole.show(undefined, "Group by")
                            })), React.createElement(Select.default, {
                          value: factor,
                          onChange: (function (e, param) {
                              var value = e.target.value;
                              return Curry._1(setFactor, (function (param) {
                                            return value;
                                          }));
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: AlertsTrigger$BsConsole.dropdownStyle
                              }),
                          renderValue: (function (v) {
                              return I18N$BsConsole.showSkip(getFactorString(v));
                            }),
                          className: Css.style(/* :: */[
                                Css.backgroundColor(/* transparent */582626130),
                                /* :: */[
                                  Css.paddingLeft(Css.rem(0.75)),
                                  /* :: */[
                                    Css.height(Css.rem(2)),
                                    /* :: */[
                                      Css.width(Css.ch(17)),
                                      /* :: */[
                                        Css.marginRight(Css.px(8)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          children: Belt_List.toArray(Belt_List.map(factorOptions, (function (option) {
                                      return React.createElement(MenuItem.default, {
                                                  value: option,
                                                  children: I18N$BsConsole.showSkip(getFactorString(option))
                                                });
                                    })))
                        })), filters.length === 0 && Curry._1(Crdb$BsConsole.Havings.toArray, havings).length === 0 ? null : React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.marginRight(Css.px(25)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(5)),
                                  /* [] */0
                                ])
                          }, React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Button */10,
                                color: Colors$BsConsole.grey4,
                                children: I18N$BsConsole.show(undefined, "Filters")
                              })), Belt_Array.map(filters, (function (filter) {
                              var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                              var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                              var filterValue = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(filterOperation)));
                              var filterString = filterAttribute + (" " + (Util$BsConsole.filterOpMap(Crdb$BsConsole.FilterOperation.toString(filterOperation)) + (" " + filterValue)));
                              return React.createElement(Tooltip.default, {
                                          children: React.createElement("div", {
                                                key: filterAttribute,
                                                className: Css.merge(/* :: */[
                                                      chipLabel,
                                                      /* :: */[
                                                        Css.style(/* :: */[
                                                              Css.maxWidth(Css.px(250)),
                                                              /* [] */0
                                                            ]),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip(filterString)),
                                          title: optionalTooltip(filterString)
                                        });
                            })), Belt_Array.map(Curry._1(Crdb$BsConsole.Havings.toArray, havings), (function (having) {
                              var match = InputChip$BsConsole.Filter.getHavingInfo(having);
                              var attribute = match[0];
                              if (attribute === undefined) {
                                return null;
                              }
                              var att = Belt_Option.getWithDefault(Belt_Array.get(attribute.split(";"), 0), "no attr");
                              var havingString = att + (" | " + (match[1] + (" " + (Util$BsConsole.filterOpMap(match[2]) + (" " + match[3])))));
                              return React.createElement(Tooltip.default, {
                                          children: React.createElement("div", {
                                                key: havingString,
                                                className: Css.merge(/* :: */[
                                                      chipLabel,
                                                      /* :: */[
                                                        Css.style(/* :: */[
                                                              Css.maxWidth(Css.px(250)),
                                                              /* [] */0
                                                            ]),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip(havingString)),
                                          title: optionalTooltip(havingString)
                                        });
                            }))), aggs.length === 0 ? null : React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(5)),
                                  /* [] */0
                                ])
                          }, React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Button */10,
                                color: Colors$BsConsole.grey4,
                                children: I18N$BsConsole.show(undefined, "Aggregation")
                              })), React.createElement(Row2$BsConsole.make, {
                            flexWrap: /* wrap */-822134326,
                            children: Belt_Array.map(aggs, (function (agg) {
                                    var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                                    var attr = match[0] + (" - " + match[1]);
                                    return React.createElement(Tooltip.default, {
                                                children: React.createElement("div", {
                                                      key: attr,
                                                      className: chipLabel
                                                    }, I18N$BsConsole.showSkip(attr)),
                                                title: optionalTooltip(attr)
                                              });
                                  }))
                          }))));
}

var chipLength = 16;

var make = AlertsQueryDisplay;

exports.chipLabel = chipLabel;
exports.chipLength = chipLength;
exports.optionalTooltip = optionalTooltip;
exports.getFactorString = getFactorString;
exports.make = make;
/* chipLabel Not a pure module */
