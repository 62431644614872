// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Comment$BsConsole = require("./Comment.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var AfMetrics$BsConsole = require("./AfMetrics.js");
var CommentInput$BsConsole = require("./CommentInput.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var FetchActivityFeed$BsConsole = require("../FetchActivityFeed.js");

var container = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.maxWidth(Css.px(900)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(Css.column),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container
};

function defaultRenderComment(param) {
  return ReasonReact.element(String(param.idx), undefined, Comment$BsConsole.make(param.edited, param.comment, param.commenter, param.activeUser, param.allUsers, param.onEditComment, param.onEditingSaved, param.onEditingCancelled, param.onDeleteComment, []));
}

var component = RR$BsConsole.reducerComponent("ActivityFeed-BsConsole");

function make(serviceUrl, fingerprint, projectId, token, user, users, containerClassNameOpt, renderCommentOpt, _children) {
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : container;
  var renderComment = renderCommentOpt !== undefined ? renderCommentOpt : defaultRenderComment;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* FetchComments */3);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var pendingDeletion = state.pendingDeletion;
              var commentsRemote = state.commentsRemote;
              var editedCommentId = state.editedCommentId;
              var send = param.send;
              var tmp;
              var exit = 0;
              if (typeof commentsRemote === "number") {
                exit = 1;
              } else {
                tmp = commentsRemote.tag ? React.createElement("div", undefined, I18N$BsConsole.show(undefined, commentsRemote[0])) : React.createElement("div", {
                        className: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                "ActivityFeed_CommentList",
                                /* [] */0
                              ]
                            ])
                      }, Belt_Array.map(commentsRemote[0][0], (function (comment) {
                              var commenter = users.find((function (u) {
                                      return u.uid === comment.userId;
                                    }));
                              if (commenter !== undefined) {
                                return Curry._1(renderComment, {
                                            edited: Caml_obj.caml_equal(editedCommentId, comment.id),
                                            idx: comment.id,
                                            activeUser: user,
                                            allUsers: users,
                                            comment: comment,
                                            commenter: commenter === undefined ? undefined : Caml_option.some(commenter),
                                            onEditComment: (function (comment) {
                                                return Curry._1(send, /* EditComment */Block.__(1, [comment.id]));
                                              }),
                                            onEditingSaved: (function (commentId, newContents) {
                                                return Curry._1(send, /* ModifyComment */Block.__(5, [
                                                              commentId,
                                                              newContents
                                                            ]));
                                              }),
                                            onEditingCancelled: (function (param) {
                                                return Curry._1(send, /* CancelEditing */1);
                                              }),
                                            onDeleteComment: (function (param) {
                                                return Curry._1(send, /* SetPendingDeletion */Block.__(6, [comment.id]));
                                              })
                                          });
                              } else {
                                return null;
                              }
                            })));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "primary", undefined, undefined, []));
              }
              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: containerClassName
                            }, ReasonReact.element(undefined, undefined, CommentInput$BsConsole.make(state.commentInputValue, (function (newValue) {
                                        return Curry._1(send, /* SetCommentInputValue */Block.__(0, [newValue]));
                                      }), users, user, (function (contents, param) {
                                        return Curry._1(send, /* PostComment */Block.__(4, [contents]));
                                      }), [])), tmp), pendingDeletion !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                    undefined,
                                    true,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [
                                      ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure you want to delete your comment?")]))])),
                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          undefined,
                                                          (function (param) {
                                                              return Curry._1(send, /* SetPendingDeletion */Block.__(6, [undefined]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          /* Primary */-791844958,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Cancel")]
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          undefined,
                                                          (function (_event) {
                                                              return Curry._1(send, /* DeleteComment */Block.__(7, [pendingDeletion]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          /* Primary */-791844958,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Delete")]
                                                        ]))
                                              ]))
                                    ]
                                  ])) : null);
            }),
          initialState: (function (param) {
              return {
                      editedCommentId: undefined,
                      commentInputValue: CommentInput$BsConsole.initialValue,
                      commentsRemote: /* NotAsked */0,
                      pendingDeletion: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* ResetCommentInput */0 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: undefined,
                                  commentInputValue: CommentInput$BsConsole.initialValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* CancelEditing */1 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: undefined,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* ResetCommentInputAndRefetch */2 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  editedCommentId: undefined,
                                  commentInputValue: CommentInput$BsConsole.initialValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                },
                                (function (self) {
                                    return Curry._1(self.send, /* FetchComments */3);
                                  })
                              ]);
                  case /* FetchComments */3 :
                      var match = state.commentsRemote;
                      var commentsRemote;
                      commentsRemote = typeof match === "number" || match.tag ? /* Loading */1 : /* Success */Block.__(0, [/* tuple */[
                              match[0][0],
                              /* Loading */1
                            ]]);
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                },
                                (function (self) {
                                    var onSuccess = function (comments) {
                                      return Curry._1(self.send, /* FetchCommentsSuccess */Block.__(2, [comments]));
                                    };
                                    var onFailure = function (msg) {
                                      return Curry._1(self.send, /* FetchCommentsFailure */Block.__(3, [msg]));
                                    };
                                    var arg = FetchActivityFeed$BsConsole.getCommentsTask(serviceUrl, projectId, fingerprint);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return FetchActivityFeed$BsConsole.responseCb(onSuccess, onFailure, param);
                                                }));
                                  })
                              ]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* SetCommentInputValue */0 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: action[0],
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* EditComment */1 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: action[0],
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* FetchCommentsSuccess */2 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: /* Success */Block.__(0, [/* tuple */[
                                        action[0],
                                        /* NotAsked */0
                                      ]]),
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* FetchCommentsFailure */3 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: /* Failure */Block.__(1, [action[0]]),
                                  pendingDeletion: state.pendingDeletion
                                }]);
                  case /* PostComment */4 :
                      var contents = action[0];
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    var onSuccess = function (_comment) {
                                      AfMetrics$BsConsole.send(/* CommentCreated */[contents]);
                                      return Curry._1(self.send, /* ResetCommentInputAndRefetch */2);
                                    };
                                    var onFailure = function (msg) {
                                      return Curry._1(self.send, /* FetchCommentsFailure */Block.__(3, [msg]));
                                    };
                                    var arg = FetchActivityFeed$BsConsole.postCommentTask(serviceUrl, projectId, fingerprint, JSON.stringify(contents.toJSON()));
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return FetchActivityFeed$BsConsole.responseCb(onSuccess, onFailure, param);
                                                }));
                                  })]);
                  case /* ModifyComment */5 :
                      var contents$1 = action[1];
                      var commentId = action[0];
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    var onSuccess = function (_comment) {
                                      AfMetrics$BsConsole.send(/* CommentEdited */0);
                                      return Curry._1(self.send, /* ResetCommentInputAndRefetch */2);
                                    };
                                    var onFailure = function (msg) {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* String_literal */Block.__(11, [
                                                              "Failed to modify comment ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ]),
                                                          "Failed to modify comment %s"
                                                        ]), msg));
                                    };
                                    var arg = FetchActivityFeed$BsConsole.modifyCommentTask(serviceUrl, projectId, fingerprint, commentId, JSON.stringify(contents$1.toJSON()));
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return FetchActivityFeed$BsConsole.responseCb(onSuccess, onFailure, param);
                                                }));
                                  })]);
                  case /* SetPendingDeletion */6 :
                      return /* Update */Block.__(0, [{
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: action[0]
                                }]);
                  case /* DeleteComment */7 :
                      var commentId$1 = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  editedCommentId: state.editedCommentId,
                                  commentInputValue: state.commentInputValue,
                                  commentsRemote: state.commentsRemote,
                                  pendingDeletion: undefined
                                },
                                (function (self) {
                                    var onSuccess = function (param) {
                                      AfMetrics$BsConsole.send(/* CommentDeleted */1);
                                      return Curry._1(self.send, /* FetchComments */3);
                                    };
                                    var onFailure = function (msg) {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* String_literal */Block.__(11, [
                                                              "Failed to delete comment ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ]),
                                                          "Failed to delete comment %s"
                                                        ]), msg));
                                    };
                                    FetchActivityFeed$BsConsole.deleteCommentTask(serviceUrl, token, projectId, fingerprint, commentId$1, onSuccess, onFailure);
                                    
                                  })
                              ]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.serviceUrl, reactProps.fingerprint, reactProps.projectId, reactProps.token, reactProps.user, reactProps.users, Caml_option.undefined_to_opt(reactProps.containerClassName), Caml_option.undefined_to_opt(reactProps.renderComment), []);
      }));

var Jsx3 = {
  make: make$1
};

exports.Styles = Styles;
exports.defaultRenderComment = defaultRenderComment;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* container Not a pure module */
