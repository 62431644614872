// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("../../I18N.js");
var ApiWf$BsConsole = require("./ApiWf.js");
var Image$BsConsole = require("../../components/Image.js");
var Task2$BsConsole = require("../../Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Select = require("@material-ui/core/Select");
var WorkflowVersion$BsConsole = require("../WorkflowVersion.js");
var MenuItem = require("@material-ui/core/MenuItem");

function fromJson(json) {
  return {
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          short: Json_decode.field("short", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          integrationId: Json_decode.optional((function (param) {
                  return Json_decode.field("integrationId", Json_decode.$$int, param);
                }), json)
        };
}

var Ticket = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          seq: Json_decode.field("seq", Json_decode.$$int, json),
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          state: Json_decode.field("state", Json_decode.string, json),
          invariant_reopen_count: Json_decode.field("invariant_reopen_count", Json_decode.$$int, json),
          invariant_reopen_last_time: Json_decode.field("invariant_reopen_last_time", Json_decode.$$int, json),
          tags: Json_decode.field("tags", Json_decode.string, json),
          ticket: Json_decode.field("ticket", Json_decode.string, json),
          blame_last_modified: Json_decode.field("blame_last_modified", Json_decode.$$int, json)
        };
}

var $$Response = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          body: Json_decode.field("body", fromJson$1, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success = {
  fromJson: fromJson$2
};

function createTicketTask(projectName, fingerprint, $$short, url, maybeLinkedIntegrationId, config) {
  var payload;
  var exit = 0;
  if (maybeLinkedIntegrationId !== undefined && maybeLinkedIntegrationId !== -1) {
    payload = Json_encode.object_(/* :: */[
          /* tuple */[
            "fingerprint",
            fingerprint
          ],
          /* :: */[
            /* tuple */[
              "project",
              projectName
            ],
            /* :: */[
              /* tuple */[
                "short",
                $$short
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  url
                ],
                /* :: */[
                  /* tuple */[
                    "integrationId",
                    maybeLinkedIntegrationId
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    payload = Json_encode.object_(/* :: */[
          /* tuple */[
            "fingerprint",
            fingerprint
          ],
          /* :: */[
            /* tuple */[
              "project",
              projectName
            ],
            /* :: */[
              /* tuple */[
                "short",
                $$short
              ],
              /* :: */[
                /* tuple */[
                  "url",
                  url
                ],
                /* :: */[
                  /* tuple */[
                    "integrationId",
                    null
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]);
  }
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url$1 = baseUrl[0] + "/issue/tickets";
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url$1]), undefined, /* Post */Block.__(0, [payload]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$2(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/QueryIntegration.queryIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var CreateTicket = {
  $$Response: $$Response,
  Success: Success,
  createTicketTask: createTicketTask
};

function setIssueTickets(config, token, fetchIssues, selectedFingerprints, projectName, url, $$short, maybeLinkedIntegrationId, cb) {
  var promises = Belt_Array.map(selectedFingerprints, (function (fingerprint) {
          return new Promise((function (resolve, param) {
                        var onSuccess = function (param) {
                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Triage_AddUnlinkedTicket */53]));
                          return resolve(/* Success */0);
                        };
                        var onFailure = function (err) {
                          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfTicket/addTicketsLink", Backtrace$BsConsole.Attributes.make(undefined));
                          Backtrace$BsConsole.Client.send(__x, /* `string */[
                                288368849,
                                "\"Failed to add ticket to fingerprint " + (String(fingerprint) + (": " + (String(err) + "\"")))
                              ]);
                          return resolve(/* Failure */1);
                        };
                        var maybeTask = createTicketTask(projectName, fingerprint, $$short, url, maybeLinkedIntegrationId, config);
                        if (maybeTask !== undefined) {
                          return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                        return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                                      }));
                        } else {
                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
                        }
                      }));
        }));
  var onSuccessAll = function (successesCount) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Successfully updated ",
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              " issue(s).",
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "Successfully updated %d issue(s)."
                ]), successesCount));
    Curry._1(fetchIssues, selectedFingerprints);
    return Curry._1(cb, undefined);
  };
  var onPartialSuccess = function (failuresCount) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Failured to update ",
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              " of ",
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* String_literal */Block.__(11, [
                                      "  issue(s).",
                                      /* End_of_format */0
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "Failured to update %d of %d  issue(s)."
                ]), failuresCount, selectedFingerprints.length));
    Curry._1(fetchIssues, selectedFingerprints);
    return Curry._1(cb, undefined);
  };
  var onFailureAll = function (failuresCount) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Failured to update ",
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              " issue(s).",
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "Failured to update %d issue(s)."
                ]), failuresCount));
    return Curry._1(cb, undefined);
  };
  var __x = Promise.all(promises);
  var __x$1 = __x.then((function (results) {
          var successesCount = Belt_Array.keep(results, (function (result) {
                  return result === /* Success */0;
                })).length;
          var failuresCount = Belt_Array.keep(results, (function (result) {
                  return result === /* Failure */1;
                })).length;
          var match = successesCount > 0;
          var match$1 = failuresCount > 0;
          if (match) {
            if (match$1) {
              onPartialSuccess(failuresCount);
            } else {
              onSuccessAll(successesCount);
            }
          } else if (match$1) {
            onFailureAll(failuresCount);
          } else {
            onFailureAll(selectedFingerprints.length);
          }
          return Promise.resolve(undefined);
        }));
  __x$1.catch((function (exn) {
          if (exn[0] === Task2$BsConsole.TaskError) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip("Failure. Issue(s) not updated: " + I18N$BsConsole.get(undefined, Task2$BsConsole.renderError(undefined, exn[1]))));
          }
          return Promise.resolve(undefined);
        }));
  
}

var menuItemIcon = Css.style(/* :: */[
      Css.width(Css.px(20)),
      /* :: */[
        Css.marginRight(Css.px(10)),
        /* [] */0
      ]
    ]);

var fullWidth = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var Styles = {
  menuItemIcon: menuItemIcon,
  fullWidth: fullWidth
};

function ApiWfTickets$SelectLinkedIntegration(Props) {
  var maybeLinkedIntegrationId = Props.maybeLinkedIntegrationId;
  var onLinkedIntegrationIdChange = Props.onLinkedIntegrationIdChange;
  var supportedWorkflows = Props.supportedWorkflows;
  return React.createElement(Select.default, {
              value: maybeLinkedIntegrationId,
              onChange: (function ($$event, param) {
                  var value = $$event.target.value;
                  if (!(value == null)) {
                    return Curry._1(onLinkedIntegrationIdChange, value);
                  }
                  
                }),
              className: fullWidth,
              autoWidth: true,
              children: Belt_Array.concat(Belt_Array.map(Belt_SortArray.stableSortBy(supportedWorkflows, (function (a1, a2) {
                              return $$String.compare(a1.watcher.type_.toLowerCase(), a2.watcher.type_.toLowerCase());
                            })), (function (param) {
                          var watcher = param.watcher;
                          var integration = param.integration;
                          var integrationId = watcher.integrationId;
                          if (integrationId !== undefined) {
                            return React.createElement(MenuItem.default, {
                                        value: integrationId,
                                        children: null,
                                        key: String(integrationId)
                                      }, React.createElement(Image$BsConsole.make, {
                                            className: menuItemIcon,
                                            src: Belt_Option.getWithDefault(integration.description.icon, ""),
                                            alt: integration.description.title + " Icon"
                                          }), I18N$BsConsole.show(undefined, watcher.watcherName));
                          } else {
                            return null;
                          }
                        })), [React.createElement(MenuItem.default, {
                          onClick: (function (_event) {
                              
                            }),
                          value: -1,
                          children: I18N$BsConsole.show(undefined, "None")
                        })])
            });
}

var component = ReasonReact.statelessComponent("SelectLinkedIntegration");

function make(maybeLinkedIntegrationId, onLinkedIntegrationIdChange, supportedWorkflows, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ApiWfTickets$SelectLinkedIntegration, {
              maybeLinkedIntegrationId: maybeLinkedIntegrationId,
              onLinkedIntegrationIdChange: onLinkedIntegrationIdChange,
              supportedWorkflows: supportedWorkflows
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var SelectLinkedIntegration = {
  Styles: Styles,
  make: ApiWfTickets$SelectLinkedIntegration,
  Jsx2: Jsx2
};

exports.Ticket = Ticket;
exports.CreateTicket = CreateTicket;
exports.setIssueTickets = setIssueTickets;
exports.SelectLinkedIntegration = SelectLinkedIntegration;
/* menuItemIcon Not a pure module */
