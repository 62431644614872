// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function timeStringParser(timeString) {
  return Caml_option.null_to_opt(new RegExp("(\\d{2}):(\\d{2})").exec(timeString));
}

function matchIntegrationParams(urlSearchParams) {
  return Caml_option.null_to_opt(urlSearchParams.match(new RegExp("_t=([\\w\\d+\\\\]+)")));
}

var forValidEmail = new RegExp("^[-!#$%&'*+\\/0-9=?A-Z^_a-z{|}~](\\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\\.?[a-zA-Z0-9])*\\.[a-zA-Z](-?[a-zA-Z0-9])+$", "i");

var containsSymbolsOrNumbers = new RegExp("[0-9]+|[!|\"|#|$|%|&|'|\\(|\\)|*|\\+|,|.|\\/|:|;|<|>|=|?|@|\\[|\\]|\\|\\^|_|\\-|`|\\{|\\}|\\||~|\\s]+", "i");

function sourceCodePathValid(path) {
  if (new RegExp("\\/\\/.*\\/.*").test(path)) {
    return !new RegExp("\\.\\.\\.").test(path);
  } else {
    return false;
  }
}

function containsSpaces(str) {
  return new RegExp("\\s").test(str);
}

function isPossiblySSHURL(url) {
  if (Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("@").exec(url)))) {
    return Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("ssh:\\/\\/").exec(url)));
  } else {
    return false;
  }
}

function isDateInput(str) {
  return new RegExp("^(\\d{4})-(\\d{2})-(\\d{2})T(\\d{2})\\:(\\d{2})[\\-\\+](\\d{2})\\:(\\d{2})$").test(str);
}

function isDateInputSeconds(str) {
  return new RegExp("^(\\d{4})-(\\d{2})-(\\d{2})T(\\d{2})\\:(\\d{2})\\:(\\d{2})[\\-\\+](\\d{2})\\:(\\d{2})$").test(str);
}

function isName(name) {
  return new RegExp("[\\w\\-\\_\\s]+").test(name);
}

function isDnsName(name) {
  return new RegExp("^[a-zA-Z0-9\\.]*[a-zA-Z0-9]$").test(name);
}

function isExporterName(name) {
  return new RegExp("[\\w\\-\\_]+").test(name);
}

function isProjectName(name) {
  return new RegExp("^[A-Z0-9\\-]+$", "i").test(name);
}

function isAlertName(name) {
  return new RegExp("^[A-Z0-9\\s\\-]+$", "i").test(name);
}

function isOnlyAscii(str) {
  return new RegExp("^[\x01-\x7f]+$", "i").test(str);
}

function isOnlyDigits(str) {
  return new RegExp("^[\\d]+$", "i").test(str);
}

var matchURLQueryStringParams = new RegExp("\\?.+");

var matchURLLastRoute = new RegExp("\\/([^\\/?]+)\\/?$");

function isOfPreviewableExtension(str) {
  return Belt_List.has(/* :: */[
              ".+\\.jpg",
              /* :: */[
                ".+\\.gif",
                /* :: */[
                  ".+\\.png",
                  /* :: */[
                    ".+\\.bmp",
                    /* :: */[
                      ".+\\.mp4",
                      /* :: */[
                        ".+\\.wav",
                        /* :: */[
                          ".+\\.json",
                          /* :: */[
                            ".+\\.mp3",
                            /* :: */[
                              ".+\\.txt",
                              /* :: */[
                                ".+\\.log",
                                /* :: */[
                                  ".+\\.ini",
                                  /* :: */[
                                    ".+\\.pdf",
                                    /* :: */[
                                      ".+\\.xml",
                                      /* :: */[
                                        ".+\\.runtime-xml",
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ], str, (function (s, a) {
                return Belt_Option.isSome(Caml_option.null_to_opt(new RegExp(s, "i").exec(a)));
              }));
}

exports.timeStringParser = timeStringParser;
exports.matchIntegrationParams = matchIntegrationParams;
exports.forValidEmail = forValidEmail;
exports.containsSymbolsOrNumbers = containsSymbolsOrNumbers;
exports.sourceCodePathValid = sourceCodePathValid;
exports.containsSpaces = containsSpaces;
exports.isPossiblySSHURL = isPossiblySSHURL;
exports.isDateInput = isDateInput;
exports.isDateInputSeconds = isDateInputSeconds;
exports.isName = isName;
exports.isDnsName = isDnsName;
exports.isExporterName = isExporterName;
exports.isProjectName = isProjectName;
exports.isAlertName = isAlertName;
exports.isOnlyAscii = isOnlyAscii;
exports.isOnlyDigits = isOnlyDigits;
exports.matchURLQueryStringParams = matchURLQueryStringParams;
exports.matchURLLastRoute = matchURLLastRoute;
exports.isOfPreviewableExtension = isOfPreviewableExtension;
/* forValidEmail Not a pure module */
