// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Card = require("@material-ui/core/Card");
var PercentageBar$BsConsole = require("./PercentageBar.js");
var Warning = require("@material-ui/icons/Warning");
var SubTextFormatter$BsConsole = require("../subscriptions/SubTextFormatter.js");
var CardContent = require("@material-ui/core/CardContent");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");

function DataCard$Message(Props) {
  var message = Props.message;
  var messageColorOpt = Props.messageColor;
  var iconOpt = Props.icon;
  var messageColor = messageColorOpt !== undefined ? messageColorOpt : Colors$BsConsole.redDark;
  var icon = iconOpt !== undefined ? Caml_option.valFromOption(iconOpt) : React.createElement(ErrorOutline.default, { });
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.color(Css.hex(messageColor)),
                    /* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.lineHeight(Css.px(14)),
                            /* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(16)),
                        /* :: */[
                          Css.width(Css.px(18)),
                          /* :: */[
                            Css.marginRight(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement("span", {
                      className: "smaller-icon"
                    }, icon)), I18N$BsConsole.showSkip(message));
}

var Message = {
  make: DataCard$Message
};

var title = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* :: */[
            Css.fontSize(Css.px(16)),
            /* :: */[
              Css.lineHeight(Css.px(19)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.black)),
                /* :: */[
                  Css.marginBottom(Css.px(15)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var dataText = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* normal */812216871),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.lineHeight(Css.px(16)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.black)),
                /* :: */[
                  Css.marginBottom(Css.px(15)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  title: title,
  dataText: dataText
};

var dataSectionHeight = 84;

function DataCard$UnlimitedCard(Props) {
  var cardWidthOpt = Props.cardWidth;
  var cardMarginRightOpt = Props.cardMarginRight;
  Props.cardMarginBottom;
  var title$1 = Props.title;
  var numerator = Props.numerator;
  var unit = Props.unit;
  var verb = Props.verb;
  var formatter = Props.formatter;
  var failure = Props.failure;
  var extra = Props.extra;
  var extension = Props.extension;
  var cardWidth = cardWidthOpt !== undefined ? cardWidthOpt : 325;
  var cardMarginRight = cardMarginRightOpt !== undefined ? cardMarginRightOpt : 25;
  var n = SubTextFormatter$BsConsole.toString(numerator, formatter);
  var sentence;
  var exit = 0;
  var v;
  if (unit !== undefined) {
    if (verb !== undefined) {
      sentence = Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                /* String */Block.__(2, [
                    /* No_padding */0,
                    /* Char_literal */Block.__(12, [
                        /* " " */32,
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* Char_literal */Block.__(12, [
                                /* " " */32,
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ])
                          ])
                      ])
                  ]),
                "%s %s %s"
              ]), n, unit, verb);
    } else {
      v = unit;
      exit = 1;
    }
  } else if (verb !== undefined) {
    v = verb;
    exit = 1;
  } else {
    sentence = Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* End_of_format */0
                ]),
              "%s"
            ]), n);
  }
  if (exit === 1) {
    sentence = Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* Char_literal */Block.__(12, [
                      /* " " */32,
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ])
                ]),
              "%s %s"
            ]), n, v);
  }
  var tmp;
  if (failure !== undefined) {
    tmp = failure.tag ? React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(dataSectionHeight)),
                  /* [] */0
                ])
          }, React.createElement(DataCard$Message, {
                message: failure[0],
                messageColor: Colors$BsConsole.orangeDark,
                icon: React.createElement(Warning.default, { })
              })) : React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(dataSectionHeight)),
                  /* [] */0
                ])
          }, React.createElement(DataCard$Message, {
                message: failure[0],
                messageColor: Colors$BsConsole.redDark,
                icon: React.createElement(ErrorOutline.default, { })
              }));
  } else {
    var tmp$1;
    tmp$1 = extra !== undefined ? (
        extra.tag ? React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.lineHeight(Css.px(14)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blue)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])
              }, React.createElement(Link$BsConsole.Jsx3.make, {
                    route: extra[2],
                    handleChangeUrl: extra[0],
                    children: I18N$BsConsole.showSkip(extra[1])
                  })) : React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.lineHeight(Css.px(14)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])
              }, I18N$BsConsole.showSkip(extra[0]))
      ) : React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(14)),
                  /* [] */0
                ])
          });
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Css.merge(/* :: */[
                    dataText,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.paddingBottom(Css.rem(2.46)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ])
            }, sentence), React.createElement(Row2$BsConsole.make, {
              justifyContent: /* flexEnd */924268066,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: tmp$1
            }));
  }
  return React.createElement(Card.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.width(Css.px(cardWidth)),
                        /* :: */[
                          Css.marginRight(Css.px(cardMarginRight)),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              children: React.createElement(CardContent.default, {
                    children: React.createElement(Col2$BsConsole.make, {
                          alignItems: /* flexStart */662439529,
                          justifyContent: /* center */98248149,
                          children: null
                        }, React.createElement("div", {
                              className: title
                            }, I18N$BsConsole.show(undefined, title$1)), tmp, extension !== undefined ? Caml_option.valFromOption(extension) : null)
                  })
            });
}

var UnlimitedCard = {
  make: DataCard$UnlimitedCard
};

function DataCard(Props) {
  var cardWidthOpt = Props.cardWidth;
  var cardMarginRightOpt = Props.cardMarginRight;
  Props.cardMarginBottom;
  var title$1 = Props.title;
  var numerator = Props.numerator;
  var denominator = Props.denominator;
  var unit = Props.unit;
  var verb = Props.verb;
  var formatter = Props.formatter;
  var failure = Props.failure;
  var extra = Props.extra;
  var extension = Props.extension;
  var cardWidth = cardWidthOpt !== undefined ? cardWidthOpt : 325;
  var cardMarginRight = cardMarginRightOpt !== undefined ? cardMarginRightOpt : 25;
  var n = SubTextFormatter$BsConsole.toString(numerator, formatter);
  var d = SubTextFormatter$BsConsole.toString(denominator, formatter);
  var sentence;
  var exit = 0;
  var v;
  if (unit !== undefined) {
    if (verb !== undefined) {
      sentence = Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                /* String */Block.__(2, [
                    /* No_padding */0,
                    /* String_literal */Block.__(11, [
                        " out of ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* Char_literal */Block.__(12, [
                                /* " " */32,
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* Char_literal */Block.__(12, [
                                        /* " " */32,
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ]),
                "%s out of %s %s %s"
              ]), n, d, unit, verb);
    } else {
      v = unit;
      exit = 1;
    }
  } else if (verb !== undefined) {
    v = verb;
    exit = 1;
  } else {
    sentence = Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* String_literal */Block.__(11, [
                      " out of ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ])
                ]),
              "%s out of %s"
            ]), n, d);
  }
  if (exit === 1) {
    sentence = Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* String_literal */Block.__(11, [
                      " out of ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* Char_literal */Block.__(12, [
                              /* " " */32,
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ])
                ]),
              "%s out of %s %s"
            ]), n, d, v);
  }
  var tmp;
  if (failure !== undefined) {
    tmp = failure.tag ? React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(dataSectionHeight)),
                  /* [] */0
                ])
          }, React.createElement(DataCard$Message, {
                message: failure[0],
                messageColor: Colors$BsConsole.orangeDark,
                icon: React.createElement(Warning.default, { })
              })) : React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(dataSectionHeight)),
                  /* [] */0
                ])
          }, React.createElement(DataCard$Message, {
                message: failure[0],
                messageColor: Colors$BsConsole.redDark,
                icon: React.createElement(ErrorOutline.default, { })
              }));
  } else {
    var tmp$1;
    tmp$1 = extra !== undefined ? (
        extra.tag ? React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.lineHeight(Css.px(14)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blue)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])
              }, React.createElement(Link$BsConsole.Jsx3.make, {
                    route: extra[2],
                    handleChangeUrl: extra[0],
                    children: I18N$BsConsole.showSkip(extra[1])
                  })) : React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontFamily("Roboto"),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(12)),
                          /* :: */[
                            Css.lineHeight(Css.px(14)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])
              }, I18N$BsConsole.showSkip(extra[0]))
      ) : React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.height(Css.px(14)),
                  /* [] */0
                ])
          });
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: dataText
            }, sentence), React.createElement(PercentageBar$BsConsole.make, {
              numerator: numerator,
              denominator: denominator
            }), React.createElement(Row2$BsConsole.make, {
              justifyContent: /* flexEnd */924268066,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: tmp$1
            }));
  }
  return React.createElement(Card.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.width(Css.px(cardWidth)),
                        /* :: */[
                          Css.marginRight(Css.px(cardMarginRight)),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              children: React.createElement(CardContent.default, {
                    children: React.createElement(Col2$BsConsole.make, {
                          alignItems: /* flexStart */662439529,
                          justifyContent: /* center */98248149,
                          children: null
                        }, React.createElement("div", {
                              className: title
                            }, I18N$BsConsole.show(undefined, title$1)), tmp, extension !== undefined ? Caml_option.valFromOption(extension) : null)
                  })
            });
}

var extraSectionHeight = 14;

var sentenceHeight = 31;

var percentageBarHeight = 39;

var make = DataCard;

exports.Message = Message;
exports.Style = Style;
exports.extraSectionHeight = extraSectionHeight;
exports.sentenceHeight = sentenceHeight;
exports.percentageBarHeight = percentageBarHeight;
exports.dataSectionHeight = dataSectionHeight;
exports.UnlimitedCard = UnlimitedCard;
exports.make = make;
/* title Not a pure module */
