// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Crdb$BsConsole = require("../crdb.js");
var Fuzz$BsConsole = require("../bindings/Fuzz.js");
var I18N$BsConsole = require("../I18N.js");
var Icon$BsConsole = require("../bindings/Icon.js");
var Util$BsConsole = require("../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("../error.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("../bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("../Task2.js");
var BtList$BsConsole = require("../components/BtList.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BtCheckbox$BsConsole = require("../components/BtCheckbox.js");
var BtListItem$BsConsole = require("../components/BtListItem.js");

var ParseError = Caml_exceptions.create("InboxMultiTags-BsConsole.ParseError");

var CoronerdError = Caml_exceptions.create("InboxMultiTags-BsConsole.CoronerdError");

var allTimeAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
            /* Floating */0,
            /* Month */3
          ])), Crdb$BsConsole.Aperture.$$default);

var allTagsQuery_002 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
          "fingerprint;issues;tags",
          /* Distribution */Block.__(1, [512])
        ]), Crdb$BsConsole.Fold.empty);

var allTagsQuery = /* Aggregate */Block.__(0, [
    allTimeAperture,
    undefined,
    allTagsQuery_002,
    undefined,
    undefined,
    undefined
  ]);

function decodeTags(json) {
  var columns = Parse$BsConsole.parseColumns(json);
  var group = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
            "*",
            undefined
          ], columns, json));
  return Util$BsConsole.getUniqueValues(Belt_Array.keep(Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(group, (function (group) {
                                return Group$BsConsole.unwrapDistribution(Group$BsConsole.getAggregation(columns, group, "fingerprint;issues;tags", /* Distribution */1));
                              })), (function (result) {
                            return Belt_Array.concatMany(Belt_Array.keepMap(Belt_Array.keepMap(Belt_List.toArray(result.vals), (function (param) {
                                                  return param[0];
                                                })), (function (tags) {
                                              if (tags.tag === /* IssueTags */15) {
                                                return Belt_List.toArray(tags[0]);
                                              }
                                              
                                            })));
                          })), []), (function (tag) {
                    if (tag !== "*") {
                      return tag !== "";
                    } else {
                      return false;
                    }
                  })));
}

var component = ReasonReact.reducerComponent("InboxMultiTags-BsConsole");

function getTagStates(allTags, tagGroups, selectedGroups) {
  var selectedTags = Belt_List.toArray(Belt_List.flatten(tagGroups));
  return Belt_Array.reduce(allTags, undefined, (function (acc, tag) {
                var occurences = Belt_Array.keep(selectedTags, (function (t) {
                        return t === tag;
                      })).length;
                return Belt_MapString.set(acc, tag, occurences === 0 ? /* Bool */[false] : (
                              occurences === selectedGroups ? /* Bool */[true] : /* Indeterminate */0
                            ));
              }));
}

function changes(tagStates, allTags, tagGroups, selectedGroups) {
  var selectedTags = Belt_List.toArray(Belt_List.flatten(tagGroups));
  var originalTagStates = Belt_Array.reduce(allTags, undefined, (function (acc, tag) {
          var occurences = Belt_Array.keep(selectedTags, (function (t) {
                  return t === tag;
                })).length;
          return Belt_MapString.set(acc, tag, occurences === 0 ? /* Bool */[false] : (
                        occurences === selectedGroups ? /* Bool */[true] : /* Indeterminate */0
                      ));
        }));
  var changes$1 = Belt_Array.keepMap(Belt_MapString.toArray(tagStates), (function (param) {
          var state = param[1];
          var tag = param[0];
          var originalState = Belt_MapString.getWithDefault(originalTagStates, tag, /* Bool */[false]);
          if (Caml_obj.caml_equal(state, originalState)) {
            return ;
          } else {
            return /* tuple */[
                    tag,
                    originalState,
                    state
                  ];
          }
        }));
  var additions = Belt_Array.keepMap(changes$1, (function (param) {
          if (Caml_obj.caml_equal(param[2], /* Bool */[true])) {
            return param[0];
          }
          
        }));
  var removals = Belt_Array.keepMap(changes$1, (function (param) {
          if (Caml_obj.caml_equal(param[2], /* Bool */[false])) {
            return param[0];
          }
          
        }));
  return /* tuple */[
          additions,
          removals
        ];
}

function make(anchorOrigin, transformOrigin, allTags, target, tagGroups, selectedGroups, handleAddRemoveTags, onCloseOpt, param) {
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (param) {
              var state = param.state;
              if (tagGroups !== state.retainedTagGroups || allTags !== state.retainedAllTags) {
                return {
                        retainedTagGroups: tagGroups,
                        retainedAllTags: allTags,
                        tagStates: getTagStates(allTags, tagGroups, selectedGroups),
                        searchString: state.searchString
                      };
              } else {
                return {
                        retainedTagGroups: tagGroups,
                        retainedAllTags: allTags,
                        tagStates: state.tagStates,
                        searchString: state.searchString
                      };
              }
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              var searchString = match.searchString;
              var tagStates = match.tagStates;
              var allTags = Belt_MapString.keysToArray(tagStates);
              var filteredAllTags = searchString === "" ? allTags : Fuzz$BsConsole.fuzz(searchString, allTags, Util$BsConsole.identity);
              var filteredSortedAllTags = Belt_SortArray.stableSortBy(Belt_SortArray.stableSortBy(filteredAllTags, (function (t1, t2) {
                          return $$String.compare(t1.toLowerCase(), t2.toLowerCase());
                        })), (function (t1, t2) {
                      var match = Belt_MapString.getWithDefault(tagStates, t1, /* Bool */[true]);
                      var match$1 = Belt_MapString.getWithDefault(tagStates, t2, /* Bool */[true]);
                      if (match) {
                        if (match[0]) {
                          if (match$1 && !match$1[0]) {
                            return -1;
                          } else {
                            return 0;
                          }
                        } else if (match$1 && !match$1[0]) {
                          return 0;
                        } else {
                          return 1;
                        }
                      } else if (match$1 && !match$1[0]) {
                        return -1;
                      } else {
                        return 0;
                      }
                    }));
              var hasError = searchString.includes(" ");
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make(Curry.__3(target), undefined, anchorOrigin, transformOrigin, undefined, (function (param) {
                                Curry._1(self.send, /* ResetTagStates */1);
                                return Curry._1(onClose, undefined);
                              }), undefined, (function (onClose) {
                                var match = changes(tagStates, allTags, tagGroups, selectedGroups);
                                return React.createElement("div", {
                                            onClick: (function (e) {
                                                e.stopPropagation();
                                                
                                              })
                                          }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                            Css.padding2(Css.px(0), Css.px(12)),
                                                                            /* [] */0
                                                                          ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                  Css.style(/* :: */[
                                                                                        Css.marginTop(Css.px(4)),
                                                                                        /* :: */[
                                                                                          Css.width(Css.px(190)),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]),
                                                                                  undefined,
                                                                                  I18N$BsConsole.get(undefined, "Tag as"),
                                                                                  undefined,
                                                                                  (function (e) {
                                                                                      return Curry._1(self.send, /* SetSearchString */Block.__(1, [e.target.value]));
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  hasError,
                                                                                  Caml_option.some(hasError ? I18N$BsConsole.show(undefined, "space characters are not allowed") : I18N$BsConsole.showEmpty(undefined)),
                                                                                  undefined,
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    searchString
                                                                                  ],
                                                                                  undefined,
                                                                                  true,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.fontSize(Css.px(12)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    endAdornment: React.createElement("span", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.color(Css.hex(Colors$BsConsole.blackA60)),
                                                                                                /* [] */0
                                                                                              ])
                                                                                        }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* small */-9768761, "search", [])))
                                                                                  },
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  {
                                                                                    onKeyDown: (function (e) {
                                                                                        if (e.which === 13 && !hasError) {
                                                                                          Curry._1(self.send, /* AddTag */Block.__(2, [searchString]));
                                                                                          return e.preventDefault();
                                                                                        }
                                                                                        
                                                                                      })
                                                                                  },
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))]))])),
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(Css.style(/* :: */[
                                                                  Css.maxHeight(Css.px(168)),
                                                                  /* :: */[
                                                                    Css.overflowY(Css.auto),
                                                                    /* :: */[
                                                                      Css.marginTop(Css.px(8)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]), [
                                                              allTags.includes(searchString) || searchString === "" ? null : ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                              Css.paddingLeft(Css.px(20)),
                                                                              /* :: */[
                                                                                Css.flexGrow(1),
                                                                                /* :: */[
                                                                                  Css.justifyContent(/* spaceBetween */516682146),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]
                                                                            ]), undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                    undefined,
                                                                                    Caml_option.some(I18N$BsConsole.showSkip("\"" + (String(searchString) + "\""))),
                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, BtCheckbox$BsConsole.make(undefined, /* small */-9768761, (function (param, param$1) {
                                                                                                    return Curry._1(self.send, /* AddTag */Block.__(2, [searchString]));
                                                                                                  }), false, undefined, false, []))),
                                                                                    {
                                                                                      label: Css.style(/* :: */[
                                                                                            Css.fontSize(Css.px(12)),
                                                                                            /* :: */[
                                                                                              Css.paddingLeft(Css.px(2)),
                                                                                              /* :: */[
                                                                                                Css.paddingTop(Css.px(2)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ])
                                                                                    },
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ])),
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    /* Small */311976103,
                                                                                    undefined,
                                                                                    (function (param) {
                                                                                        return Curry._1(self.send, /* AddTag */Block.__(2, [searchString]));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    /* Primary */-791844958,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    hasError,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Add new tag")]
                                                                                  ]))
                                                                        ])),
                                                              Belt_Array.map(filteredSortedAllTags, (function (tag) {
                                                                      var state = Belt_MapString.getWithDefault(tagStates, tag, /* Bool */[true]);
                                                                      var match = changes(tagStates, allTags, tagGroups, selectedGroups);
                                                                      var isTagUnsaved = Belt_Array.keep(match[0], (function (addition) {
                                                                              return addition === tag;
                                                                            })).length !== 0 || Belt_Array.keep(match[1], (function (removal) {
                                                                              return removal === tag;
                                                                            })).length !== 0;
                                                                      return ReasonReact.element(tag, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                                          Css.paddingLeft(Css.px(20)),
                                                                                          /* :: */[
                                                                                            Css.flexGrow(1),
                                                                                            /* :: */[
                                                                                              Css.display(/* flex */-1010954439),
                                                                                              /* :: */[
                                                                                                Css.justifyContent(/* spaceBetween */516682146),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]), undefined, [
                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                undefined,
                                                                                                Caml_option.some(I18N$BsConsole.showSkip(tag)),
                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, BtCheckbox$BsConsole.make(undefined, /* small */-9768761, (function (param, param$1) {
                                                                                                                return Curry._1(self.send, /* ToggleTag */Block.__(0, [tag]));
                                                                                                              }), state ? state[0] : true, state ? false : true, undefined, []))),
                                                                                                {
                                                                                                  label: Css.style(/* :: */[
                                                                                                        Css.fontSize(Css.px(12)),
                                                                                                        /* :: */[
                                                                                                          Css.paddingLeft(Css.px(2)),
                                                                                                          /* :: */[
                                                                                                            Css.paddingTop(Css.px(2)),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]
                                                                                                      ])
                                                                                                },
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                []
                                                                                              ])),
                                                                                      isTagUnsaved ? React.createElement("span", {
                                                                                              className: Css.style(/* :: */[
                                                                                                    Css.color(Css.hex(Colors$BsConsole.deleteRed)),
                                                                                                    /* [] */0
                                                                                                  ])
                                                                                            }, I18N$BsConsole.showSkip("unsaved")) : null
                                                                                    ]));
                                                                    }))
                                                            ])),
                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* flexStart */662439529, undefined, undefined, undefined, Css.style(/* :: */[
                                                                            Css.width(Css.pct(100)),
                                                                            /* :: */[
                                                                              Css.padding2(Css.px(5), Css.px(10)),
                                                                              /* :: */[
                                                                                Css.selector("button:not(:first-of-type)", /* :: */[
                                                                                      Css.marginLeft(Css.px(5)),
                                                                                      /* [] */0
                                                                                    ]),
                                                                                /* [] */0
                                                                              ]
                                                                            ]
                                                                          ]), [
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  /* Small */311976103,
                                                                                  Css.style(/* :: */[
                                                                                        Css.marginRight(Css.px(4)),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  (function (param) {
                                                                                      Curry._1(self.send, /* Apply */0);
                                                                                      return Curry._1(onClose, undefined);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  /* Contained */-515484397,
                                                                                  /* Primary */-791844958,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  (match[0].length + match[1].length | 0) === 0,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Save")]
                                                                                ])),
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  /* Small */311976103,
                                                                                  Css.style(/* :: */[
                                                                                        Css.marginRight(Css.px(4)),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  (function (param) {
                                                                                      return Curry._1(onClose, undefined);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  /* Primary */-791844958,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                ]))
                                                                      ]))]))
                                                  ])));
                              }), undefined, []));
            }),
          initialState: (function (param) {
              return {
                      retainedTagGroups: tagGroups,
                      retainedAllTags: allTags,
                      tagStates: getTagStates(allTags, tagGroups, selectedGroups),
                      searchString: ""
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== /* Apply */0) {
                  return /* Update */Block.__(0, [{
                              retainedTagGroups: state.retainedTagGroups,
                              retainedAllTags: state.retainedAllTags,
                              tagStates: getTagStates(allTags, tagGroups, selectedGroups),
                              searchString: state.searchString
                            }]);
                }
                var match = changes(state.tagStates, allTags, tagGroups, selectedGroups);
                var removals = match[1];
                var additions = match[0];
                var updatedState_retainedTagGroups = state.retainedTagGroups;
                var updatedState_retainedAllTags = state.retainedAllTags;
                var updatedState_tagStates = state.tagStates;
                var updatedState = {
                  retainedTagGroups: updatedState_retainedTagGroups,
                  retainedAllTags: updatedState_retainedAllTags,
                  tagStates: updatedState_tagStates,
                  searchString: ""
                };
                return /* UpdateWithSideEffects */Block.__(2, [
                          updatedState,
                          (function (param) {
                              return Curry._2(handleAddRemoveTags, Belt_List.fromArray(additions), Belt_List.fromArray(removals));
                            })
                        ]);
              } else {
                switch (action.tag | 0) {
                  case /* ToggleTag */0 :
                      var tag = action[0];
                      var currselection = Belt_MapString.getExn(state.tagStates, tag);
                      var newSelection = currselection && currselection[0] ? /* Bool */[false] : /* Bool */[true];
                      return /* Update */Block.__(0, [{
                                  retainedTagGroups: state.retainedTagGroups,
                                  retainedAllTags: state.retainedAllTags,
                                  tagStates: Belt_MapString.set(state.tagStates, tag, newSelection),
                                  searchString: state.searchString
                                }]);
                  case /* SetSearchString */1 :
                      return /* Update */Block.__(0, [{
                                  retainedTagGroups: state.retainedTagGroups,
                                  retainedAllTags: state.retainedAllTags,
                                  tagStates: state.tagStates,
                                  searchString: action[0]
                                }]);
                  case /* AddTag */2 :
                      return /* Update */Block.__(0, [{
                                  retainedTagGroups: state.retainedTagGroups,
                                  retainedAllTags: state.retainedAllTags,
                                  tagStates: Belt_MapString.set(state.tagStates, action[0], /* Bool */[true]),
                                  searchString: ""
                                }]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

function allTagsTask(projectName) {
  var fold = Curry._1(Crdb$BsConsole.Fold.toCrdb, Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                "tags",
                /* Distribution */Block.__(1, [512])
              ]), Crdb$BsConsole.Fold.empty));
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "filter",
          [Json_encode.object_(/* :: */[
                  /* tuple */[
                    "tags",
                    [[
                        "regular-expression",
                        ".*"
                      ]]
                  ],
                  /* [] */0
                ])]
        ],
        /* :: */[
          /* tuple */[
            "fold",
            fold
          ],
          /* :: */[
            /* tuple */[
              "group",
              ["*"]
            ],
            /* :: */[
              /* tuple */[
                "table",
                "issues"
              ],
              /* [] */0
            ]
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [body]), undefined, (function (response) {
                try {
                  var columns = Parse$BsConsole.parseColumns(response.json);
                  var group = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
                            "*",
                            undefined
                          ], columns, response.json));
                  var result = Util$BsConsole.getUniqueValues(Belt_Array.keep(Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(group, (function (group) {
                                          return Group$BsConsole.unwrapDistribution(Group$BsConsole.getAggregation(columns, group, "tags", /* Distribution */1));
                                        })), (function (result) {
                                      var arr = Belt_Array.keepMap(Belt_Array.keepMap(Belt_List.toArray(result.vals), (function (param) {
                                                  return param[0];
                                                })), (function (tags) {
                                              if (tags.tag === /* IssueTags */15) {
                                                return Belt_List.toArray(tags[0]);
                                              }
                                              
                                            }));
                                      return Belt_Array.concatMany((console.log(arr), arr));
                                    })), []), (function (tag) {
                              if (tag !== "*") {
                                return tag !== "";
                              } else {
                                return false;
                              }
                            })));
                  return /* Ok */Block.__(0, [result]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Error$BsConsole.HTTPD.decode(response.json);
                  if (error !== undefined) {
                    return /* Error */Block.__(1, [[
                                CoronerdError,
                                error
                              ]]);
                  }
                  var error$1 = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "InboxMultiTags/allTagsTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error$1
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error$1
                            ]]);
                }
              }), undefined, undefined);
}

function useAllTags(projectName, token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var key = match$1[0];
  React.useEffect((function () {
          if (key > 0) {
            Curry._1(setRemote, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [key]);
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              var arg = allTagsTask(projectName);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (resp) {
                      if (resp.tag) {
                        var error = resp[0];
                        var errorMessage;
                        if (error[0] === CoronerdError) {
                          errorMessage = error[1].message;
                        } else if (error[0] === ParseError) {
                          var message = error[1].message;
                          errorMessage = message !== undefined ? message : "Something went wrong. This error has been reported to Backtrace.";
                        } else {
                          errorMessage = "Something went wrong. This error has been reported to Backtrace.";
                        }
                        return Curry._1(setRemote, (function (param) {
                                      return /* Failure */Block.__(1, [errorMessage]);
                                    }));
                      }
                      var resp$1 = resp[0];
                      return Curry._1(setRemote, (function (param) {
                                    return /* Success */Block.__(0, [resp$1]);
                                  }));
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  var refetchTags = function (param) {
    return Curry._1(setKey, (function (k) {
                  return k + 1 | 0;
                }));
  };
  return /* tuple */[
          remote,
          refetchTags
        ];
}

exports.ParseError = ParseError;
exports.CoronerdError = CoronerdError;
exports.allTagsQuery = allTagsQuery;
exports.decodeTags = decodeTags;
exports.component = component;
exports.getTagStates = getTagStates;
exports.changes = changes;
exports.make = make;
exports.allTagsTask = allTagsTask;
exports.useAllTags = useAllTags;
/* allTimeAperture Not a pure module */
