// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");

var metricsNamespace = "server-side-actions/";

function toEvent(action) {
  switch (action) {
    case /* ActionCreate */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "server-side-actions/action_create",
                    payload: undefined
                  }]);
    case /* ActionUpdate */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "server-side-actions/action_update",
                    payload: undefined
                  }]);
    case /* ActionDelete */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "server-side-actions/action_delete",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
