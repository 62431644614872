// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Image$BsConsole = require("./components/Image.js");
var Task2$BsConsole = require("./Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function ofJson(json) {
  return {
          projectName: Json_decode.field("projectName", Json_decode.string, json),
          token: Json_decode.field("token", Json_decode.string, json),
          timelessAperture: Json_decode.field("timelessAperture", Json_decode.string, json),
          timeGranularity: Json_decode.field("timeGranularity", Json_decode.string, json),
          count: Json_decode.field("count", Json_decode.$$int, json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "projectName",
                t.projectName
              ],
              /* :: */[
                /* tuple */[
                  "token",
                  t.token
                ],
                /* :: */[
                  /* tuple */[
                    "timelessAperture",
                    t.timelessAperture
                  ],
                  /* :: */[
                    /* tuple */[
                      "timeGranularity",
                      t.timeGranularity
                    ],
                    /* :: */[
                      /* tuple */[
                        "count",
                        t.count
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var QueryCountCache = {
  ofJson: ofJson,
  toJson: toJson
};

function useErrorCount(aperture, token, projectName) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match[1];
  var storageKey = "bs-query-count";
  var getApertureString = function (ap) {
    try {
      return JSON.stringify(Curry._1(Crdb$BsConsole.Aperture.toCrdbJson, ap));
    }
    catch (exn){
      return "unknown";
    }
  };
  var getTimeGranularityFromAperture = function (ap) {
    var match = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, ap);
    switch (match.tag | 0) {
      case /* Relative */0 :
      case /* RelativeFirstSeen */2 :
          return match[1];
      case /* Custom */1 :
      case /* CustomFirstSeen */3 :
          return ;
      
    }
  };
  var setSessionStorageErrorCount = function (count) {
    try {
      var timelessAperture = Curry._1(Crdb$BsConsole.Aperture.toCrdbTimeless, aperture);
      var timeGranularityOption = getTimeGranularityFromAperture(aperture);
      if (timeGranularityOption !== undefined) {
        var cacheObj_timelessAperture = JSON.stringify(timelessAperture);
        var cacheObj_timeGranularity = Crdb$BsConsole.Granularity.granularityToString(timeGranularityOption);
        var cacheObj = {
          projectName: projectName,
          token: token,
          timelessAperture: cacheObj_timelessAperture,
          timeGranularity: cacheObj_timeGranularity,
          count: count
        };
        var cacheObjStr = JSON.stringify(toJson(cacheObj));
        window.sessionStorage.setItem(storageKey, cacheObjStr);
        return ;
      }
      window.sessionStorage.removeItem(storageKey);
      return ;
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      var __x = Backtrace$BsConsole.Attributes.make(undefined);
      return Backtrace$BsConsole.Client.send(__x, /* `exn */[
                  5049499,
                  Uncaught$BsConsole.castToJsExn(e)
                ]);
    }
  };
  var onSuccess = function (resp) {
    var meta = resp[2];
    var responseTime = Belt_Option.getWithDefault(Belt_Option.flatMap(meta.times, (function (t) {
                return t.total;
              })), -1);
    var count = Belt_Option.getWithDefault(meta.instances, -1);
    MetricsEvent$BsConsole.send(/* GenericEvent */Block.__(2, [{
              name: "query/page_load_error_count",
              payload: Caml_option.some(Json_encode.object_(/* :: */[
                        /* tuple */[
                          "response-time",
                          responseTime
                        ],
                        /* :: */[
                          /* tuple */[
                            "project",
                            projectName
                          ],
                          /* :: */[
                            /* tuple */[
                              "aperture",
                              getApertureString(aperture)
                            ],
                            /* :: */[
                              /* tuple */[
                                "count",
                                count
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]))
            }]));
    setSessionStorageErrorCount(count);
    return Curry._1(setValue, (function (param) {
                  return /* Success */Block.__(0, [/* tuple */[
                              count,
                              /* NotAsked */0
                            ]]);
                }));
  };
  var onFailure = function (errorMessage) {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "RenderIfBelowErrorCount/useErrorCount", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          errorMessage
        ]);
    return Curry._1(setValue, (function (param) {
                  return /* Failure */Block.__(1, [errorMessage]);
                }));
  };
  var makeErrorCountQuery = function (param) {
    var query_001 = Curry._2(Crdb$BsConsole.Select.add, "_tx", Crdb$BsConsole.Select.empty);
    var query_003 = Caml_option.some(Crdb$BsConsole.Paginate.initial);
    var query = /* Select */Block.__(1, [
        aperture,
        query_001,
        undefined,
        query_003
      ]);
    var queryJson = Curry._1(Crdb$BsConsole.Query.toCrdb_, query);
    var task = QueryPaginationTask$BsConsole.makeBaseTask(queryJson, projectName, (function (param) {
            return QueryPaginationTask$BsConsole.CrdbSelect.decodeResp(query, param);
          }));
    return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                  return QueryPaginationTask$BsConsole.responseCb(onSuccess, onFailure, param);
                }));
  };
  var getSessionStorageErrorCount = function (param) {
    var jsonRef = "";
    try {
      var queryCountCache = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window.sessionStorage.getItem(storageKey)), "");
      jsonRef = queryCountCache;
      if (queryCountCache === "") {
        return ;
      }
      var cacheObj = ofJson(JSON.parse(queryCountCache));
      var currentTimeGranularityOption = getTimeGranularityFromAperture(aperture);
      if (currentTimeGranularityOption === undefined) {
        return ;
      }
      var currentTimelessAperture = JSON.stringify(Curry._1(Crdb$BsConsole.Aperture.toCrdbTimeless, aperture));
      var match = cacheObj.timelessAperture === currentTimelessAperture;
      var match$1 = cacheObj.timeGranularity === Crdb$BsConsole.Granularity.granularityToString(currentTimeGranularityOption);
      var match$2 = cacheObj.projectName === projectName;
      var match$3 = cacheObj.token === token;
      if (match && match$1 && match$2 && match$3) {
        return cacheObj.count;
      } else {
        return ;
      }
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      var __x = Backtrace$BsConsole.Attributes.addString("json-to-parse", jsonRef, Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            Uncaught$BsConsole.castToJsExn(e)
          ]);
      return ;
    }
  };
  React.useEffect((function () {
          Curry._1(setValue, (function (param) {
                  return /* Loading */1;
                }));
          var cachedCount = getSessionStorageErrorCount(undefined);
          if (cachedCount !== undefined) {
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                cachedCount,
                                /* NotAsked */0
                              ]]);
                  }));
          } else {
            makeErrorCountQuery(undefined);
          }
          
        }), /* tuple */[
        aperture,
        token,
        projectName
      ]);
  return match[0];
}

function RenderIfBelowErrorCount$ErrorCountChecker(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var renderOnSuccess = Props.renderOnSuccess;
  var maxErrorCount = Props.maxErrorCount;
  var countRemote = useErrorCount(aperture, token, projectName);
  var countExceedsMax = function (count, maxErrorCount) {
    if (count >= maxErrorCount) {
      MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
              /* QueryCountExceedsMaxCount */22,
              Json_encode.object_(/* :: */[
                    /* tuple */[
                      "errorCount",
                      count
                    ],
                    /* :: */[
                      /* tuple */[
                        "maxErrorCount",
                        maxErrorCount
                      ],
                      /* [] */0
                    ]
                  ])
            ]));
      return true;
    } else {
      return false;
    }
  };
  if (typeof countRemote === "number") {
    countRemote === /* NotAsked */0;
  } else {
    if (countRemote.tag) {
      return React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Failure" + countRemote[0]));
    }
    var match = countRemote[0];
    var match$1 = match[1];
    var count = match[0];
    var exit = 0;
    if (!(typeof match$1 === "number" && match$1 !== 0)) {
      exit = 2;
    }
    if (exit === 2) {
      if (countExceedsMax(count, maxErrorCount)) {
        return React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.height(Css.vh(100)),
                          /* :: */[
                            Css.width(Css.vw(100)),
                            /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(Paper.default, {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(500)),
                              /* :: */[
                                Css.height(Css.px(100)),
                                /* :: */[
                                  Css.padding(Css.px(25)),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.justifyContent(/* center */98248149),
                                      /* :: */[
                                        Css.alignItems(/* center */98248149),
                                        /* :: */[
                                          Css.marginTop(Css.px(15)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        children: null
                      }, React.createElement(Image$BsConsole.make, {
                            className: Css.style(/* :: */[
                                  Css.height(Css.px(50)),
                                  /* :: */[
                                    Css.marginRight(Css.px(15)),
                                    /* [] */0
                                  ]
                                ]),
                            src: "https://res.cloudinary.com/backtrace/image/upload/v1677707433/SL_symbol_color_2x.png",
                            alt: "Sauce Labs Logo"
                          }), React.createElement("div", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "We found more than ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                " errors, please narrow your search by choosing a shorter timeframe.",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "We found more than %s errors, please narrow your search by choosing a shorter timeframe."
                                  ]), String(count)))));
      } else {
        return Curry._1(renderOnSuccess, undefined);
      }
    }
    
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.height(Css.vh(100)),
                    /* :: */[
                      Css.width(Css.vw(100)),
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(LinearProgress.default, {
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.width(Css.px(200)),
                            /* [] */0
                          ])
                    }
                  })
            });
}

var ErrorCountChecker = {
  make: RenderIfBelowErrorCount$ErrorCountChecker
};

function RenderIfBelowErrorCount(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var renderOnSuccess = Props.renderOnSuccess;
  var maxErrorCountOption = window._BACKTRACE_MAX_ERROR_COUNT;
  if (maxErrorCountOption == null) {
    return Curry._1(renderOnSuccess, undefined);
  } else {
    return React.createElement(RenderIfBelowErrorCount$ErrorCountChecker, {
                aperture: aperture,
                token: token,
                projectName: projectName,
                renderOnSuccess: renderOnSuccess,
                maxErrorCount: maxErrorCountOption
              });
  }
}

function make(token, projectName, aperture, renderOnSuccess, children) {
  return ReasonReactCompat.wrapReactForReasonReact(RenderIfBelowErrorCount, {
              aperture: aperture,
              token: token,
              projectName: projectName,
              renderOnSuccess: renderOnSuccess
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = RenderIfBelowErrorCount;

exports.QueryCountCache = QueryCountCache;
exports.useErrorCount = useErrorCount;
exports.ErrorCountChecker = ErrorCountChecker;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
