// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Int64 = require("bs-platform/lib/js/int64.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Numeral$BsConsole = require("../../Numeral.js");

function floatToString(f) {
  var valueParts = f.toString().split(".");
  var wholeNumber = Numeral$BsConsole.format("0,0", Belt_Option.getWithDefault(Belt_Array.get(valueParts, 0), "0"));
  var precisionPoints = Belt_Array.get(valueParts, 1);
  return Belt_Option.getWithDefault(Belt_Option.map(precisionPoints, (function (decimals) {
                    return wholeNumber + ("." + decimals);
                  })), wholeNumber);
}

function toString(param) {
  var variant = param[0];
  if (variant >= 737456202) {
    if (variant >= 758940238) {
      return Numeral$BsConsole.format("0,0", Int64.to_string(Caml_int64.of_float(param[1].getDate())));
    } else {
      return Pervasives.string_of_bool(param[1]);
    }
  } else if (variant >= 365180284) {
    return floatToString(param[1]);
  } else {
    return param[1];
  }
}

function rawToString(param) {
  var variant = param[0];
  if (variant >= 737456202) {
    if (variant >= 758940238) {
      return Int64.to_string(Caml_int64.of_float(param[1].getDate()));
    } else {
      return Pervasives.string_of_bool(param[1]);
    }
  } else if (variant >= 365180284) {
    return floatToString(param[1]);
  } else {
    return param[1];
  }
}

exports.floatToString = floatToString;
exports.toString = toString;
exports.rawToString = rawToString;
/* Numeral-BsConsole Not a pure module */
