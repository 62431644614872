'use strict';

var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

var ParseError = Caml_exceptions.create("Json.ParseError");

function parse(s) {
  try {
    return Caml_option.some(JSON.parse(s));
  }
  catch (exn){
    return ;
  }
}

function parseOrRaise(s) {
  try {
    return JSON.parse(s);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Js_exn.$$Error) {
      var m = e[1].message;
      var message = m !== undefined ? m : "Unknown error";
      throw [
            ParseError,
            message
          ];
    }
    throw e;
  }
}

var Decode;

var Encode;

function stringify(prim) {
  return JSON.stringify(prim);
}

exports.Decode = Decode;
exports.Encode = Encode;
exports.ParseError = ParseError;
exports.parse = parse;
exports.parseOrRaise = parseOrRaise;
exports.stringify = stringify;
/* No side effect */
