// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Lazy = require("bs-platform/lib/js/lazy.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Util_option$BsConsole = require("./Util_option.js");
var L10N_date_time_config_cache$BsConsole = require("./L10N_date_time_config_cache.js");

function formats(param) {
  return param.formats;
}

function clockScheme(param) {
  return param.clockScheme;
}

var include = L10N_date_time_config_cache$BsConsole.Make({ });

var getExn = include.getExn;

var clockScheme$1 = Lazy.from_fun((function (param) {
        return Curry._1(getExn, undefined).clockScheme;
      }));

var formats$1 = Lazy.from_fun((function (param) {
        return Curry._1(getExn, undefined).formats;
      }));

var Lazy$1 = {
  clockScheme: clockScheme$1,
  formats: formats$1
};

function clockScheme$2(maybe) {
  return Util_option$BsConsole.Lazy.getWithDefault(maybe, clockScheme$1);
}

function formats$2(maybe) {
  return Util_option$BsConsole.Lazy.getWithDefault(maybe, formats$1);
}

var Cache_store = include.store;

var Cache_set = include.set;

var Cache_get = include.get;

var Cache_getOr = include.getOr;

var Cache_getOrLazy = include.getOrLazy;

var Cache = {
  store: Cache_store,
  set: Cache_set,
  get: Cache_get,
  getOr: Cache_getOr,
  getOrLazy: Cache_getOrLazy,
  getExn: getExn,
  Lazy: Lazy$1,
  clockScheme: clockScheme$2,
  formats: formats$2
};

exports.formats = formats;
exports.clockScheme = clockScheme;
exports.Cache = Cache;
/* include Not a pure module */
