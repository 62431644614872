// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var WfForm$BsConsole = require("../WfForm.js");

var component = RR$BsConsole.reducerComponent("WfActionForm-BsConsole");

function make(btDefaults, token, error, onFormSubmitted, form, initialFormValue, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var remoteAction = param.state.remoteAction;
              var send = param.send;
              return React.createElement("div", undefined, error !== undefined ? React.createElement("div", {
                                style: {
                                  border: "2px solid " + Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed),
                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed),
                                  margin: "8px",
                                  padding: "8px"
                                }
                              }, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, Caml_option.valFromOption(error)))) : null, ReasonReact.element(undefined, undefined, WfForm$BsConsole.make(btDefaults, token, form, (function (ra) {
                                    remoteAction.contents = ra;
                                    
                                  }), (function (v) {
                                    return Curry._1(send, /* UpdateFormValue */[v]);
                                  }), undefined, initialFormValue, undefined, [])), React.createElement("div", {
                              style: {
                                display: "flex",
                                paddingTop: "24px",
                                width: "100%",
                                justifyContent: "flex-end"
                              }
                            }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                      undefined,
                                      undefined,
                                      (function (param) {
                                          return Curry._1(send, /* SubmitForm */0);
                                        }),
                                      undefined,
                                      undefined,
                                      /* Contained */-515484397,
                                      /* Primary */-791844958,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [I18N$BsConsole.show(undefined, "Submit")]
                                    ]))));
            }),
          initialState: (function (param) {
              return {
                      formValue: undefined,
                      remoteAction: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            formValue: action[0],
                            remoteAction: state.remoteAction
                          }]);
              } else {
                return /* SideEffects */Block.__(1, [(function (param) {
                              var match = state.formValue;
                              var match$1 = state.remoteAction.contents;
                              if (match !== undefined) {
                                if (match.tag) {
                                  if (match$1 !== undefined) {
                                    return Curry._1(match$1, /* HighlightErrors */0);
                                  } else {
                                    return ;
                                  }
                                } else {
                                  return Curry._1(onFormSubmitted, match[0]);
                                }
                              }
                              
                            })]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
