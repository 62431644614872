// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Numeral$BsConsole = require("./Numeral.js");
var Truncate$BsConsole = require("./Truncate.js");
var Tooltip = require("@material-ui/core/Tooltip");

var row = Css.style(/* :: */[
      Css.fontFamily("inconsolata"),
      /* [] */0
    ]);

var count = Css.style(/* :: */[
      Css.width(Css.pct(50)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var normalizedPercentage = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.color(Css.hex(Colors$BsConsole.accent)),
            /* [] */0
          ]),
      /* :: */[
        count,
        /* [] */0
      ]
    ]);

var Style = {
  row: row,
  count: count,
  normalizedPercentage: normalizedPercentage
};

function CountNormalizationCell(Props) {
  var count$1 = Props.count;
  var normalizedPercentageString = Props.normalizedPercentageString;
  var countTitle = Props.countTitle;
  var normalizedTitle = Props.normalizedTitle;
  var normalizedTooltipInteractiveOpt = Props.normalizedTooltipInteractive;
  var normalizedTooltipInteractive = normalizedTooltipInteractiveOpt !== undefined ? normalizedTooltipInteractiveOpt : false;
  return React.createElement(Row2$BsConsole.make, {
              className: row,
              children: null
            }, countTitle !== undefined ? React.createElement(Tooltip.default, {
                    children: React.createElement("div", {
                          className: count
                        }, React.createElement(Truncate$BsConsole.make, {
                              children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(count$1)))
                            })),
                    title: countTitle
                  }) : React.createElement("div", {
                    className: count
                  }, React.createElement(Truncate$BsConsole.make, {
                        children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(count$1)))
                      })), normalizedTitle !== undefined ? React.createElement(Tooltip.default, {
                    interactive: normalizedTooltipInteractive,
                    children: React.createElement("div", {
                          className: normalizedPercentage
                        }, React.createElement(Truncate$BsConsole.make, {
                              children: I18N$BsConsole.showSkip(normalizedPercentageString)
                            })),
                    title: normalizedTitle
                  }) : React.createElement("div", {
                    className: normalizedPercentage
                  }, React.createElement(Truncate$BsConsole.make, {
                        children: I18N$BsConsole.showSkip(normalizedPercentageString)
                      })));
}

var make = CountNormalizationCell;

exports.Style = Style;
exports.make = make;
/* row Not a pure module */
