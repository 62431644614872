// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Chip$BsConsole = require("./Chip.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Route$BsConsole = require("./route.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");

function setRef(tokenType, theRef, param) {
  if (tokenType) {
    return ;
  } else {
    param.state.attributeInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  }
}

var component = ReasonReact.reducerComponent("SelectInputs");

var menu = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

function make(attributes, onClose, onChange, attributeOpt, _children) {
  var attribute = attributeOpt !== undefined ? Caml_option.valFromOption(attributeOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, {
                              root: menu
                            }, undefined, undefined, undefined, 0, [ReasonReact.element(undefined, Curry._1(self.handle, (function (param, param$1) {
                                          return setRef(/* Attribute */0, param, param$1);
                                        })), Curry.app(AttributeTypeahead$BsConsole.make, [
                                        undefined,
                                        undefined,
                                        Belt_List.keep(attributes, Attribute_Helpers$BsConsole.filterFirstSeen),
                                        (function (att) {
                                            return Curry._1(self.send, /* SetAttribute */[att]);
                                          }),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        attribute !== undefined ? attribute.name : "",
                                        undefined,
                                        undefined,
                                        []
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      attribute: attribute,
                      attributeInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              var attribute = action[0];
              return /* SideEffects */Block.__(1, [(function (_self) {
                            Curry._1(onChange, attribute);
                            return Curry._1(onClose, undefined);
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Inputs = {
  setRef: setRef,
  component: component,
  menu: menu,
  make: make
};

var toolbar = Css.style(/* :: */[
      Css.flex(/* `num */[
            5496390,
            1
          ]),
      /* :: */[
        Css.flexWrap(/* nowrap */867913355),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* [] */0
        ]
      ]
    ]);

var clearButton = Css.style(/* :: */[
      Css.margin2(/* zero */-789508312, Css.px(16)),
      /* [] */0
    ]);

var clearButtonLabel = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* [] */0
    ]);

var Styles = {
  toolbar: toolbar,
  clearButton: clearButton,
  clearButtonLabel: clearButtonLabel
};

function setRef$1(theRef, param) {
  param.state.addButton.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component$1 = ReasonReact.reducerComponent("Select");

function make$1(select, onChange, remote, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var focus = function (inputRef) {
                var r = inputRef.contents;
                if (r === undefined) {
                  return ;
                }
                var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                domElement.focus();
                
              };
              if (typeof remote === "number") {
                remote === /* NotAsked */0;
              } else {
                if (remote.tag) {
                  return null;
                }
                var attributes = remote[0];
                return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, toolbar, true, [
                                ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                            return React.createElement("div", {
                                                        ref: refHandler,
                                                        style: {
                                                          display: "flex",
                                                          margin: "0 16px",
                                                          alignItems: "center"
                                                        },
                                                        onFocus: onFocus,
                                                        onClick: onClick
                                                      }, ReasonReact.element(undefined, Curry._1(self.handle, setRef$1), Chip$BsConsole.Add.make(I18N$BsConsole.get(undefined, "Add attribute column"), [])));
                                          }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                            return ReasonReact.element(undefined, undefined, make(attributes, (function (param) {
                                                              Curry._1(onClose, undefined);
                                                              setTimeout((function (param) {
                                                                      return focus(self.state.addButton);
                                                                    }), 300);
                                                              
                                                            }), (function (a) {
                                                              Curry._1(onChange, Curry._2(Crdb$BsConsole.Select.add, a.name, select));
                                                              setTimeout((function (param) {
                                                                      return focus(self.state.addButton);
                                                                    }), 300);
                                                              
                                                            }), undefined, []));
                                          }), undefined, [])),
                                React.createElement("div", {
                                      style: {
                                        display: "flex",
                                        overflow: "auto",
                                        alignItems: "center",
                                        alignSelf: "stretch",
                                        flexGrow: "1",
                                        flexWrap: "nowrap"
                                      }
                                    }, $$Array.map((function (attribute) {
                                            if (Route$BsConsole.ProjectInstancesParams.mustSelectFields.includes(attribute)) {
                                              if (attribute === "_tx") {
                                                return null;
                                              } else {
                                                return ReasonReact.element(undefined, undefined, Curry._2(Chip$BsConsole.Select.Default.make, attribute, []));
                                              }
                                            } else {
                                              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                                                return React.createElement("span", {
                                                                            ref: refHandler,
                                                                            onFocus: onFocus,
                                                                            onClick: onClick
                                                                          }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Select.make(attribute, (function (param) {
                                                                                      return Curry._1(onChange, Curry._2(Crdb$BsConsole.Select.remove, attribute, select));
                                                                                    }), [])));
                                                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                                                var tmp;
                                                                var exit = 0;
                                                                var attr;
                                                                try {
                                                                  attr = List.find((function (attr) {
                                                                          return attr.name === attribute;
                                                                        }), attributes);
                                                                  exit = 1;
                                                                }
                                                                catch (exn){
                                                                  if (exn === Caml_builtin_exceptions.not_found) {
                                                                    tmp = undefined;
                                                                  } else {
                                                                    throw exn;
                                                                  }
                                                                }
                                                                if (exit === 1) {
                                                                  tmp = attr;
                                                                }
                                                                return ReasonReact.element(undefined, undefined, make(attributes, (function (param) {
                                                                                  Curry._1(onClose, undefined);
                                                                                  setTimeout((function (param) {
                                                                                          return focus(self.state.addButton);
                                                                                        }), 300);
                                                                                  
                                                                                }), (function (a) {
                                                                                  Curry._1(onChange, Curry._2(Crdb$BsConsole.Select.add, a.name, Curry._2(Crdb$BsConsole.Select.remove, attribute, select)));
                                                                                  setTimeout((function (param) {
                                                                                          return focus(self.state.addButton);
                                                                                        }), 300);
                                                                                  
                                                                                }), Caml_option.some(tmp), []));
                                                              }), undefined, []));
                                            }
                                          }), Curry._1(Crdb$BsConsole.Select.toArray, select))),
                                Curry._1(Crdb$BsConsole.Select.toArray, select).length > 3 ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                            /* Small */311976103,
                                            undefined,
                                            (function (_event) {
                                                return Curry._1(onChange, /* :: */[
                                                            "callstack",
                                                            /* :: */[
                                                              "timestamp",
                                                              /* :: */[
                                                                "fingerprint",
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]);
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            {
                                              root: clearButton,
                                              label: clearButtonLabel
                                            },
                                            [I18N$BsConsole.show(undefined, "Clear")]
                                          ])) : null
                              ]));
              }
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, toolbar, true, [React.createElement("div", {
                                    style: {
                                      display: "flex",
                                      margin: "0 16px",
                                      alignItems: "center"
                                    }
                                  }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Add.make(I18N$BsConsole.get(undefined, "Add attribute"), [])))]));
            }),
          initialState: (function (param) {
              return {
                      addButton: {
                        contents: undefined
                      },
                      isOpen: false
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          addButton: state.addButton,
                          isOpen: !state.isOpen
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Default = {
  Styles: Styles,
  setRef: setRef$1,
  component: component$1,
  make: make$1
};

exports.Inputs = Inputs;
exports.Default = Default;
exports.Styles = Styles;
exports.setRef = setRef$1;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
