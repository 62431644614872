// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var RoutePath$BsConsole = require("./RoutePath.js");

function isModified($$event) {
  if ($$event.metaKey || $$event.altKey || $$event.shiftKey) {
    return true;
  } else {
    return $$event.ctrlKey;
  }
}

var component = RR$BsConsole.statelessComponent("Link-BsConsole");

function make(route, onClick, handleChangeUrl, disabledOpt, styleOpt, classNameOpt, children) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({
        color: "inherit",
        textDecoration: "none"
      });
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var handleClick = function ($$event) {
    Belt_Option.map(onClick, (function (onClick) {
            return Curry._1(onClick, $$event);
          }));
    var defaultPrevented = $$event.defaultPrevented;
    var isLeftClick = $$event.button === 0;
    var isModified$1 = isModified($$event);
    if (!defaultPrevented && isLeftClick && !isModified$1) {
      $$event.preventDefault();
      return Curry._1(handleChangeUrl, route);
    }
    
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              if (disabled) {
                return ReactDOMRe.createElementVariadic("span", {
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.selector("li", /* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.grey5)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    className,
                                    /* [] */0
                                  ]
                                ])
                          }, children);
              } else {
                return ReasonReact.element(undefined, undefined, RoutePath$BsConsole.make(route, (function (_route, path) {
                                  return ReactDOMRe.createElementVariadic("a", {
                                              className: className,
                                              style: style,
                                              href: path,
                                              onClick: handleClick
                                            }, children);
                                })));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

function Link$Jsx3(Props) {
  var route = Props.route;
  var disabledOpt = Props.disabled;
  var onClick = Props.onClick;
  var handleChangeUrl = Props.handleChangeUrl;
  var classNameOpt = Props.className;
  var styleOpt = Props.style;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({
        color: "inherit",
        textDecoration: "none"
      });
  var handleClick = function ($$event) {
    Belt_Option.map(onClick, (function (onClick) {
            return Curry._1(onClick, $$event);
          }));
    var defaultPrevented = $$event.defaultPrevented;
    var isLeftClick = $$event.button === 0;
    var isModified$1 = isModified($$event);
    if (!defaultPrevented && isLeftClick && !isModified$1) {
      $$event.preventDefault();
      return Curry._1(handleChangeUrl, route);
    }
    
  };
  if (disabled) {
    return React.createElement("span", {
                className: Css.merge(/* :: */[
                      Css.style(/* :: */[
                            Css.selector("li", /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey5)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]),
                      /* :: */[
                        className,
                        /* [] */0
                      ]
                    ])
              }, children);
  } else {
    return React.createElement("a", {
                className: className,
                style: style,
                href: Route$BsConsole.pathForRoute(route),
                onClick: handleClick
              }, children);
  }
}

var Jsx3 = {
  make: Link$Jsx3
};

exports.isModified = isModified;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
