// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var Drilldown$BsConsole = require("./Drilldown.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var Add = require("@material-ui/icons/Add");
var Edit = require("@material-ui/icons/Edit");
var TriageActionPopover$BsConsole = require("./TriageActionPopover.js");

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var assigneeButton = Css.style(/* :: */[
      Css.position(/* unset */653193961),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.selector(":first-of-type", /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.whiteSpace(/* nowrap */867913355),
                  /* :: */[
                    Css.maxWidth(Css.pct(100)),
                    /* [] */0
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var assigneesContainer = Css.style(/* :: */[
      Css.unsafe("maxWidth", "calc(100% - 28px)"),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* [] */0
          ]
        ]
      ]
    ]);

var assignee = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.fontSize(Css.px(13)),
            /* :: */[
              Css.marginRight(Css.px(4)),
              /* :: */[
                Css.padding2(Css.px(3), Css.px(4)),
                /* :: */[
                  Css.borderRadius(Css.px(2)),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Css.display(/* inlineBlock */-147785676),
                        /* :: */[
                          Css.verticalAlign(/* middle */-866200747),
                          /* :: */[
                            Css.selector(":first-of-type", /* :: */[
                                  Css.maxWidth(Css.pct(100)),
                                  /* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.overflow(/* hidden */-862584982),
                                      /* :: */[
                                        Css.textOverflow(/* ellipsis */166888785),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "assignee__item",
        /* [] */0
      ]
    ]);

var assigneesRow = Css.style(/* :: */[
      Css.selector(":last-child", /* :: */[
            Css.marginLeft(Css.px(4)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var Styles = {
  iconButton: iconButton,
  assigneeButton: assigneeButton,
  assigneesContainer: assigneesContainer,
  assignee: assignee,
  assigneesRow: assigneesRow
};

function createFilterOptions(user) {
  return [
          /* tuple */[
            "fingerprint;issues;assignee",
            /* AssignedTo */Block.__(10, [/* `String */[
                  -976970511,
                  user
                ]])
          ],
          /* tuple */[
            "fingerprint;issues;assignee",
            /* NotAssignedTo */Block.__(11, [/* `String */[
                  -976970511,
                  user
                ]])
          ]
        ];
}

function TriageAssignees(Props) {
  var assignees = Props.assignees;
  var tickets = Props.tickets;
  var handleSetIssue = Props.handleSetIssue;
  var projectUsers = Props.projectUsers;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var handleSetAssignee = function (projectUser) {
    return TriageGroup$BsConsole.QuickActions.assignTickets(handleSetIssue, Belt_List.keepMap(Belt_Option.getWithDefault(tickets, /* [] */0), (function (ticket) {
                      switch (ticket.tag | 0) {
                        case /* Linked */1 :
                            var match = ticket[0];
                            return /* tuple */[
                                    /* tuple */[
                                      match.short,
                                      match.watcher
                                    ],
                                    true
                                  ];
                        case /* Unlinked */0 :
                        case /* InteralAssigneeLink */2 :
                            return ;
                        
                      }
                    })), projectUser, true, undefined);
  };
  var tmp;
  if (anchorEl !== undefined) {
    var assigneeUsername = Belt_List.get(Issues$BsConsole.Assignees.getAssigneeUsernames(Belt_Option.getWithDefault(assignees, /* [] */0)), 0);
    var assignedProjectUser = Belt_Array.getBy(projectUsers, (function (user) {
            return user.username === Belt_Option.getWithDefault(assigneeUsername, "");
          }));
    tmp = React.createElement(TriageActionPopover$BsConsole.Assignees.make, {
          anchorEl: Caml_option.valFromOption(anchorEl),
          projectUsers: projectUsers,
          assignedProjectUser: assignedProjectUser,
          onClose: (function (param) {
              return Curry._1(setAnchorEl, (function (param) {
                            
                          }));
            }),
          handleSetAssignee: handleSetAssignee
        });
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, assignees !== undefined ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: assigneesRow,
                    children: null
                  }, React.createElement("div", {
                        className: assigneesContainer
                      }, Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.reduce(Belt_List.map(assignees, (function (assignee) {
                                          if (assignee.tag) {
                                            return Belt_Option.getWithDefault(assignee[0].username, "");
                                          } else {
                                            return assignee[0].username;
                                          }
                                        })), /* [] */0, (function (acc, curr) {
                                      if (Belt_List.has(acc, curr, (function (a, b) {
                                                return a === b;
                                              })) || curr === "") {
                                        return acc;
                                      } else {
                                        return Belt_List.concat(acc, /* :: */[
                                                    curr,
                                                    /* [] */0
                                                  ]);
                                      }
                                    })), (function (idx, user) {
                                  return React.createElement(Drilldown$BsConsole.FromContext.make, {
                                              textValue: user,
                                              filterOptions: createFilterOptions(user),
                                              className: assigneeButton,
                                              children: React.createElement("div", {
                                                    key: user + ("__" + String(idx)),
                                                    className: assignee
                                                  }, I18N$BsConsole.showSkip(user)),
                                              key: user + ("__" + String(idx))
                                            });
                                })))), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "Edit assignees",
                        classes: iconButton,
                        onClick: (function ($$event) {
                            var el = $$event.currentTarget;
                            return Curry._1(setAnchorEl, (function (param) {
                                          return Caml_option.some(el);
                                        }));
                          }),
                        placement_tooltip: "top",
                        title_tooltip: I18N$BsConsole.get(undefined, "Edit assignees"),
                        children: React.createElement(Edit.default, {
                              color: "inherit",
                              fontSize: "inherit"
                            })
                      })) : React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                    ariaLabel: "Add assignee",
                    classes: iconButton,
                    onClick: (function ($$event) {
                        var el = $$event.currentTarget;
                        return Curry._1(setAnchorEl, (function (param) {
                                      return Caml_option.some(el);
                                    }));
                      }),
                    placement_tooltip: "top",
                    title_tooltip: I18N$BsConsole.get(undefined, "Add assignee"),
                    children: React.createElement(Add.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        })
                  }), tmp);
}

var make = TriageAssignees;

exports.Styles = Styles;
exports.createFilterOptions = createFilterOptions;
exports.make = make;
/* iconButton Not a pure module */
