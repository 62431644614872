// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("./Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var ApiWfConnections$BsConsole = require("./workflow/workflows-api/ApiWfConnections.js");

function WorkflowRedirect(Props) {
  var nonce = Props.nonce;
  var handleChangeUrl = Props.handleChangeUrl;
  var projects = Props.projects;
  var token = Props.token;
  var config = Props.config;
  var lastProj = Props.lastProj;
  var project;
  if (lastProj !== undefined) {
    project = lastProj.name;
  } else {
    var projectOption = Belt_Array.get(Belt_List.toArray(projects), 0);
    project = projectOption !== undefined ? projectOption.name : "";
  }
  React.useEffect((function () {
          var onSuccess = function (param) {
            var body = param.body;
            var connectionId = body.data.id;
            var pluginId = body.pluginId;
            var param_pluginId = pluginId;
            var param_connectionCreated = true;
            var param$1 = {
              pluginId: param_pluginId,
              connectionId: connectionId,
              connectionCreated: param_connectionCreated,
              integrationId: undefined,
              referrer: undefined,
              error: undefined
            };
            return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                          project,
                          WfRouter$BsConsole.root,
                          param$1
                        ]));
          };
          var onFailure = function (err) {
            return Curry._1(handleChangeUrl, /* Error */Block.__(57, [err]));
          };
          var maybeTask = ApiWfConnections$BsConsole.NonceCallback.nonceCallbackTask(nonce, config, project);
          if (maybeTask !== undefined) {
            Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
          } else {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
          }
          
        }), [nonce]);
  return null;
}

var component = ReasonReact.statelessComponent("WorkflowRedirect");

function make(nonce, handleChangeUrl, projects, token, lastProj, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(WorkflowRedirect, {
              nonce: nonce,
              handleChangeUrl: handleChangeUrl,
              projects: projects,
              token: token,
              config: config,
              lastProj: lastProj
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = WorkflowRedirect;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
