// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Image$BsConsole = require("../components/Image.js");

var component = RR$BsConsole.statelessComponent("WfIntegrationIcon-BsConsole");

function make(icon, alt, sizeOpt, param) {
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return React.createElement("div", {
                          style: {
                            height: "" + (String(size) + "px"),
                            width: "" + (String(size) + "px")
                          }
                        }, icon !== undefined ? ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(undefined, {
                                      height: "" + (String(size) + "px"),
                                      width: "" + (String(size) + "px")
                                    }, icon, alt, undefined)([])) : null);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
