// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Task2$BsConsole = require("./Task2.js");
var Tasks$BsConsole = require("./Tasks.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var ExporterDeleteModal$BsConsole = require("./metrics-exporter/ExporterDeleteModal.js");

function DeleteObjects(Props) {
  var variant = Props.variant;
  var token = Props.token;
  var onClose = Props.onClose;
  var onSuccess = Props.onSuccess;
  var projectName = Props.projectName;
  var tmp;
  tmp = variant.tag ? I18N$BsConsole.get(undefined, "original object and debug view data") : I18N$BsConsole.get(undefined, "original object and all associated data");
  return React.createElement(ExporterDeleteModal$BsConsole.make, {
              open: true,
              onClose: onClose,
              handleDelete: (function (param) {
                  var tmp;
                  tmp = variant.tag ? /* Physical */0 : undefined;
                  var arg = Tasks$BsConsole.deleteObject(projectName, [variant[0]], Caml_option.some(tmp), undefined);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (resp) {
                                if (resp.tag) {
                                  console.log(resp[0]);
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete object."));
                                  return Curry._1(onClose, undefined);
                                }
                                Curry._1(onClose, undefined);
                                return Curry._1(onSuccess, undefined);
                              }));
                }),
              title: "Delete object?",
              itemName: tmp
            });
}

var make = DeleteObjects;

exports.make = make;
/* react Not a pure module */
