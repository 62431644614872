// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var Renderer_CallstackFrames$BsConsole = require("./Renderer_CallstackFrames.js");
var Renderer_CallstackExpansion$BsConsole = require("./Renderer_CallstackExpansion.js");

var component = ReasonReact.reducerComponent("Renderer_Callstack-BsConsole");

function callstackStr(frame, charCount) {
  var hingeNumber = charCount / 2 | 0;
  if (frame.length <= charCount) {
    return frame;
  }
  var left = $$String.sub(frame, 0, hingeNumber);
  var right = $$String.sub(frame, frame.length - hingeNumber | 0, hingeNumber);
  return left + (" ... " + right);
}

function make(attribute, maxNumLinesOpt, useProcessedCallsackOpt, dynamicOpt, suffixOpt, frames, maxCharactersOpt, handleAddFilters, handleChangeUrl, raw, classNameOpt, config, token, aperture, projectName, _children) {
  var maxNumLines = maxNumLinesOpt !== undefined ? Caml_option.valFromOption(maxNumLinesOpt) : undefined;
  var useProcessedCallsack = useProcessedCallsackOpt !== undefined ? useProcessedCallsackOpt : true;
  var dynamic = dynamicOpt !== undefined ? dynamicOpt : false;
  var suffix = suffixOpt !== undefined ? suffixOpt : 0;
  var maxCharacters = maxCharactersOpt !== undefined ? Caml_option.valFromOption(maxCharactersOpt) : undefined;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return React.createElement("div", {
                          style: {
                            display: "flex",
                            minHeight: "65px",
                            alignItems: "center"
                          }
                        }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Renderer_CallstackFrames$BsConsole.make(attribute, Caml_option.some(maxNumLines), (function ($$event) {
                                        $$event.stopPropagation();
                                        return Curry._1(self.send, /* OpenDialog */0);
                                      }), frames, (function (frames) {
                                        if (!useProcessedCallsack) {
                                          return frames;
                                        }
                                        var morguePreparedFrames = Morgue$BsConsole.Callstack.prep(frames, {
                                              suffix: suffix,
                                              dynamic: dynamic
                                            });
                                        if (maxCharacters !== undefined) {
                                          return $$Array.map((function (f) {
                                                        return callstackStr(f, maxCharacters);
                                                      }), morguePreparedFrames);
                                        } else {
                                          return morguePreparedFrames;
                                        }
                                      }), handleAddFilters, handleChangeUrl, className, undefined, [])), ReasonReact.element(undefined, undefined, Renderer_CallstackExpansion$BsConsole.make(attribute, (function (param) {
                                        return Curry._1(self.send, /* CloseDialog */1);
                                      }), self.state.dialogOpen, raw, frames, handleAddFilters, handleChangeUrl, config, token, aperture, projectName, []))));
            }),
          initialState: (function (param) {
              return {
                      dialogOpen: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            dialogOpen: false
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            dialogOpen: true
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var CallstackFrames;

var CallstackExpansion;

exports.CallstackFrames = CallstackFrames;
exports.CallstackExpansion = CallstackExpansion;
exports.component = component;
exports.callstackStr = callstackStr;
exports.make = make;
/* component Not a pure module */
