// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Map = require("bs-platform/lib/js/belt_Map.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");

function Make(V, C) {
  var empty = Belt_Map.make(C);
  var add = function (value, map) {
    return Belt_Map.set(map, Curry._1(V.getId, value), value);
  };
  var remove = function (id, map) {
    return Belt_Map.remove(map, id);
  };
  var get = function (id, map) {
    return Belt_Map.get(map, id);
  };
  var toArray = Belt_Map.toArray;
  var fromArray = function (array) {
    return Belt_Map.fromArray(array, C);
  };
  var toJson = function (map) {
    var __x = Belt_Map.toArray(map);
    return Belt_Array.map(__x, (function (param) {
                  return Curry._1(V.toJson, param[1]);
                }));
  };
  var fromJson = function (json) {
    var __x = Json_decode.array(V.fromJson, json);
    return Belt_Map.fromArray(Belt_Array.map(__x, (function (v) {
                      return /* tuple */[
                              Curry._1(V.getId, v),
                              v
                            ];
                    })), C);
  };
  var toCrdb = function (t$prime) {
    return Json_encode.object_(List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              Json_encode.list((function (f) {
                                      return Curry._1(V.operationToCrdb, Curry._1(V.toTuple, f)[1]);
                                    }), List.sort((function (a, b) {
                                          return Caml_obj.caml_compare(Curry._1(V.toTuple, b)[1], Curry._1(V.toTuple, a)[1]);
                                        }), param[1]))
                            ];
                    }), Belt_MapString.toList(Belt_Map.reduce(t$prime, undefined, (function (acc, param, v) {
                              var a = param[0];
                              var l = Belt_MapString.get(acc, a);
                              if (l !== undefined) {
                                return Belt_MapString.set(acc, a, List.append(/* :: */[
                                                v,
                                                /* [] */0
                                              ], l));
                              } else {
                                return Belt_MapString.set(acc, a, /* :: */[
                                            v,
                                            /* [] */0
                                          ]);
                              }
                            })))));
  };
  var getAttributeAggregateIndex = function (attribute$prime, aggregate$prime, t$prime) {
    return Belt_Map.reduce(t$prime, /* tuple */[
                  -1,
                  undefined
                ], (function (acc, _key, value) {
                    var match = Curry._1(V.getId, value);
                    if (acc[1] !== undefined) {
                      return acc;
                    }
                    var i = acc[0];
                    var match$1 = match[0] === attribute$prime;
                    var match$2 = match[1] === aggregate$prime;
                    if (match$1) {
                      if (match$2) {
                        return /* tuple */[
                                i + 1 | 0,
                                i + 1 | 0
                              ];
                      } else {
                        return /* tuple */[
                                i + 1 | 0,
                                undefined
                              ];
                      }
                    } else {
                      return acc;
                    }
                  }))[1];
  };
  var eq = function (t$prime, t$prime$prime) {
    return Belt_Map.eq(t$prime, t$prime$prime, Caml_obj.caml_equal);
  };
  return {
          empty: empty,
          add: add,
          remove: remove,
          get: get,
          size: Belt_Map.size,
          toArray: toArray,
          valuesToArray: Belt_Map.valuesToArray,
          fromArray: fromArray,
          toJson: toJson,
          fromJson: fromJson,
          toCrdb: toCrdb,
          eq: eq,
          getAttributeAggregateIndex: getAttributeAggregateIndex
        };
}

exports.Make = Make;
/* No side effect */
