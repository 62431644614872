// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Semver = require("semver");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function clean(a) {
  return Caml_option.nullable_to_opt(Semver.clean(a));
}

function gt(a, b) {
  return Semver.gt(a, b);
}

function gte(a, b) {
  return Semver.gte(a, b);
}

function lt(a, b) {
  return Semver.lt(a, b);
}

function lte(a, b) {
  return Semver.lte(a, b);
}

function eq(a, b) {
  return Semver.eq(a, b);
}

function neq(a, b) {
  return Semver.neq(a, b);
}

function comparatorToString(comparator) {
  switch (comparator) {
    case /* LooseEqual */0 :
        return "==";
    case /* Equal */2 :
        return "===";
    case /* Empty */3 :
        return "";
    case /* LooseNotEqual */1 :
    case /* NotEqual */4 :
        return "!==";
    case /* Gt */5 :
        return ">";
    case /* Gte */6 :
        return ">=";
    case /* Lt */7 :
        return "<";
    case /* Lte */8 :
        return "<=";
    
  }
}

function cmp(a, c, b) {
  return Semver.cmp(a, comparatorToString(c), b);
}

function compare(a, b) {
  return Semver.compare(a, b);
}

function rcompare(a, b) {
  return Semver.rcompare(a, b);
}

function diffStringToDiff(input) {
  var opt = (input == null) ? undefined : Caml_option.some(input);
  if (input == null) {
    return ;
  }
  switch (opt) {
    case "major" :
        return /* Major */0;
    case "minor" :
        return /* Minor */1;
    case "patch" :
        return /* Patch */2;
    case "premajor" :
        return /* Premajor */3;
    case "preminor" :
        return /* Preminor */4;
    case "prepatch" :
        return /* Prepatch */5;
    default:
      throw Caml_builtin_exceptions.not_found;
  }
}

function diffToDiffString(input) {
  switch (input) {
    case /* Major */0 :
        return "major";
    case /* Minor */1 :
        return "minor";
    case /* Patch */2 :
        return "patch";
    case /* Premajor */3 :
        return "premajor";
    case /* Preminor */4 :
        return "preminor";
    case /* Prepatch */5 :
        return "prepatch";
    
  }
}

function diff(a, b) {
  return diffStringToDiff(Semver.diff(a, b));
}

function valid(v) {
  return Caml_option.nullable_to_opt(Semver.valid(v));
}

function inc(v, release) {
  return Caml_option.nullable_to_opt(Semver.inc(v, diffToDiffString(release)));
}

function patch(v) {
  return Semver.patch(v);
}

function minor(v) {
  return Semver.minor(v);
}

function major(v) {
  return Semver.major(v);
}

function satisfies(a, b) {
  return Semver.satisfies(a, b);
}

exports.clean = clean;
exports.gt = gt;
exports.gte = gte;
exports.lt = lt;
exports.lte = lte;
exports.eq = eq;
exports.neq = neq;
exports.comparatorToString = comparatorToString;
exports.cmp = cmp;
exports.compare = compare;
exports.rcompare = rcompare;
exports.diffStringToDiff = diffStringToDiff;
exports.diffToDiffString = diffToDiffString;
exports.diff = diff;
exports.valid = valid;
exports.inc = inc;
exports.patch = patch;
exports.minor = minor;
exports.major = major;
exports.satisfies = satisfies;
/* semver Not a pure module */
