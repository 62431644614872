// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");

var ParseError = Caml_exceptions.create("PhoneHomeTask-BsConsole.ParseError");

function decode(json) {
  return {
          isBlocked: Json_decode.field("isBlocked", Json_decode.bool, json)
        };
}

var $$Response = {
  decode: decode
};

function makeTask(universeName) {
  var endpoint = "https://harmonia.backtrace.io/api/harmonia/universe/" + (universeName + "/status");
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint]), undefined, /* Get */0, undefined, (function (param) {
                try {
                  var response = decode(param.json);
                  if (response.isBlocked) {
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UniverseDisabled */131]));
                  }
                  return /* Ok */Block.__(0, [response]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "PhoneHomeTask/makeTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  return Curry._1(onFailure, error[0] === Task2$BsConsole.ErrorMsg ? error[1] : "Something went wrong.");
}

function use(universeName) {
  var match = React.useState((function () {
          return 0;
        }));
  var setKey = match[1];
  var bumpKey = function (param) {
    return Curry._1(setKey, (function (k) {
                  return k + 1 | 0;
                }));
  };
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match$1[1];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp.isBlocked]);
                }));
  };
  var onFailure = function (errorMessage) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [errorMessage]);
                }));
  };
  React.useEffect((function () {
          Curry._1(setRemote, (function (param) {
                  return /* Loading */1;
                }));
          var arg = makeTask(universeName);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })("ringringring"), undefined, (function (param) {
                  return responseCb(onSuccess, onFailure, param);
                }));
          
        }), /* tuple */[
        match[0],
        universeName
      ]);
  return /* tuple */[
          match$1[0],
          bumpKey
        ];
}

exports.ParseError = ParseError;
exports.$$Response = $$Response;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.use = use;
/* react Not a pure module */
