// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function fromJson(json) {
  var param = Json_decode.field("type", Json_decode.string, json);
  var tmp;
  switch (param) {
    case "issue" :
        tmp = /* IssueTracker */2;
        break;
    case "messaging" :
        tmp = /* MessagingPlatform */0;
        break;
    case "monitoring" :
        tmp = /* Monitoring */1;
        break;
    case "other" :
        tmp = /* Other */3;
        break;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "WfIntegration.re",
              25,
              19
            ]
          ];
  }
  return {
          type_: tmp,
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Category = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          category: Json_decode.field("category", fromJson, json),
          description: Json_decode.oneOf(/* :: */[
                (function (param) {
                    return Json_decode.field("descriptor", Json_decode.string, param);
                  }),
                /* :: */[
                  (function (param) {
                      return Json_decode.field("description", Json_decode.string, param);
                    }),
                  /* [] */0
                ]
              ], json),
          icon: Json_decode.optional((function (param) {
                  return Json_decode.field("icon", Json_decode.string, param);
                }), json),
          path: Json_decode.field("path", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          alert: Json_decode.optional((function (param) {
                  return Json_decode.field("alert", Json_decode.bool, param);
                }), json),
          advanced_settings: Json_decode.optional((function (param) {
                  return Json_decode.field("advanced_settings", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

var Description = {
  Category: Category,
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          description: Json_decode.field("description", fromJson$1, json),
          supportedActions: Json_decode.optional((function (param) {
                  return Json_decode.field("actions", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

exports.Description = Description;
exports.fromJson = fromJson$2;
/* No side effect */
