// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(rc) {
  switch (rc) {
    case /* Master */0 :
        return "master2";
    case /* Latest */1 :
        return "latest2";
    case /* Api7 */2 :
        return "7";
    
  }
}

function ofString(rcS) {
  if (rcS === undefined) {
    return /* Api7 */2;
  }
  switch (rcS) {
    case "latest" :
        return /* Latest */1;
    case "master" :
        return /* Master */0;
    default:
      return /* Api7 */2;
  }
}

exports.toString = toString;
exports.ofString = ofString;
/* No side effect */
