// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Table = require("@material-ui/core/Table");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var TableRow = require("@material-ui/core/TableRow");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var LinearProgress = require("@material-ui/core/LinearProgress");
var TeamsProjectMemberTeamRow$BsConsole = require("./TeamsProjectMemberTeamRow.js");
var TeamsProjectMemberUserRow$BsConsole = require("./TeamsProjectMemberUserRow.js");

function TeamsProjectMemberTable$ProjectAccessTableHeader(Props) {
  return React.createElement(TableHead.default, {
              children: React.createElement(TableRow.default, {
                    children: null
                  }, React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.px(48)),
                                    /* [] */0
                                  ]),
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Name")
                            })
                      }), React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Type")
                            })
                      }), React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Members")
                            })
                      }), React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Role")
                            })
                      }), React.createElement(TableCell.default, {
                        children: null
                      }))
            });
}

var ProjectAccessTableHeader = {
  make: TeamsProjectMemberTable$ProjectAccessTableHeader
};

function TeamsProjectMemberTable(Props) {
  var token = Props.token;
  var project = Props.project;
  var memberTeams = Props.memberTeams;
  var memberUsers = Props.memberUsers;
  var setDeleteDialog = Props.setDeleteDialog;
  var setDeleteRefetch = Props.setDeleteRefetch;
  var refetchProjectMemberTeams = Props.refetchProjectMemberTeams;
  var refetchProjectMemberUsers = Props.refetchProjectMemberUsers;
  var universeId = Props.universeId;
  var allUsers = Props.allUsers;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          
        }));
  var setTeamData = match[1];
  var teamData = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setTeamDataFetchError = match$1[1];
  var teamPromises = Belt_Array.map(memberTeams, (function (memberTeam) {
          return new Promise((function (resolve, param) {
                        var arg = BpgTask$BsConsole.Team.fetchOne({
                              id: memberTeam.team,
                              universe: universeId
                            });
                        return Curry._2((function (param) {
                                        return (function (param$1, param$2) {
                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                          });
                                      })(token), undefined, (function (resp) {
                                      if (resp.tag) {
                                        return resolve(undefined);
                                      } else {
                                        return resolve(resp[0]);
                                      }
                                    }));
                      }));
        }));
  React.useEffect((function () {
          var __x = Promise.all(teamPromises);
          var __x$1 = __x.then((function (result) {
                  Curry._1(setTeamData, (function (param) {
                          return result;
                        }));
                  return Promise.resolve(undefined);
                }));
          __x$1.catch((function (_exn) {
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching team data."));
                  Curry._1(setTeamDataFetchError, (function (param) {
                          return true;
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [memberTeams]);
  if (teamData !== undefined) {
    return React.createElement(Table.default, {
                children: null
              }, React.createElement(TeamsProjectMemberTable$ProjectAccessTableHeader, { }), React.createElement(TableBody.default, {
                    children: null
                  }, match$1[0] ? null : Belt_Array.mapWithIndex(memberTeams, (function (index, member) {
                            var thisTeamsData = Belt_Array.get(teamData, index);
                            if (thisTeamsData === undefined) {
                              return null;
                            }
                            var td = Caml_option.valFromOption(thisTeamsData);
                            if (td !== undefined) {
                              return React.createElement(React.Fragment, {
                                          children: React.createElement(TeamsProjectMemberTeamRow$BsConsole.make, {
                                                token: token,
                                                team: td,
                                                projectMember: member,
                                                project: project,
                                                allUsers: allUsers,
                                                setDeleteDialog: (function (d) {
                                                    Curry._1(setDeleteRefetch, (function (param) {
                                                            return refetchProjectMemberTeams;
                                                          }));
                                                    return Curry._1(setDeleteDialog, d);
                                                  }),
                                                handleChangeUrl: handleChangeUrl
                                              }),
                                          key: String(td.id)
                                        });
                            } else {
                              return React.createElement(TeamsProjectMemberTeamRow$BsConsole.MissingTeamData.make, {
                                          projectMember: member
                                        });
                            }
                          })), Belt_Array.map(memberUsers, (function (member) {
                          return React.createElement(TeamsProjectMemberUserRow$BsConsole.make, {
                                      token: token,
                                      projectMember: member,
                                      allUsers: allUsers,
                                      project: project,
                                      setDeleteDialog: (function (d) {
                                          Curry._1(setDeleteRefetch, (function (param) {
                                                  return refetchProjectMemberUsers;
                                                }));
                                          return Curry._1(setDeleteDialog, d);
                                        })
                                    });
                        }))));
  } else {
    return React.createElement(LinearProgress.default, { });
  }
}

var make = TeamsProjectMemberTable;

exports.ProjectAccessTableHeader = ProjectAccessTableHeader;
exports.make = make;
/* Css Not a pure module */
