// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Href$BsConsole = require("../../Href.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Numeral$BsConsole = require("../../Numeral.js");
var Fragment$BsConsole = require("../../Fragment.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");
var MissingSymbolsCallToAction$BsConsole = require("./MissingSymbolsCallToAction.js");

function updateToDateString($$int) {
  return L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, new Date($$int * 1000));
}

var count = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.margin2(Css.rem(1), /* zero */-789508312),
        /* [] */0
      ]
    ]);

var dateStats = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var summarySection = Css.style(/* :: */[
      Css.marginRight(Css.rem(1)),
      /* :: */[
        Css.marginTop(Css.rem(1)),
        /* [] */0
      ]
    ]);

var buttonStyle = Css.style(/* :: */[
      Css.marginTop(Css.rem(0.5)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.blackA87)),
        /* [] */0
      ]
    ]);

var Styles = {
  count: count,
  dateStats: dateStats,
  summarySection: summarySection,
  buttonStyle: buttonStyle
};

var component = RR$BsConsole.statelessComponent("PS_Symbols-BsConsole__StatsCard");

function make(handleSearchClick, title, count$1, firstUpdate, lastUpdate, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, summarySection, [
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(title)])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, count, /* Title */594052472, /* Center */980392437, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(count$1)))])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "first update")])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, dateStats, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(count$1 !== 0 ? updateToDateString(firstUpdate) : "-")])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "most recent update")])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, dateStats, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(count$1 !== 0 ? updateToDateString(lastUpdate) : "-")]))
                                      ])),
                              count$1 > 0 ? ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    buttonStyle,
                                                    handleSearchClick,
                                                    undefined,
                                                    undefined,
                                                    /* Outlined */-28821822,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    true,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Search")]
                                                  ]))])) : null
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var StatsCard = {
  Styles: Styles,
  component: component,
  make: make
};

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var Styles$1 = {
  titleContainer: titleContainer,
  title: title
};

var component$1 = RR$BsConsole.statelessComponent("PS_Symbols-BsConsole");

function make$1(handleTask, token, handleChangeUrl, name, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Summary.make(handleTask, token, name, (function (remote, _update) {
                                          if (typeof remote === "number") {
                                            remote === /* NotAsked */0;
                                          } else {
                                            if (remote.tag) {
                                              return I18N$BsConsole.showSkip(remote[0]);
                                            }
                                            var summary = remote[0];
                                            var supportLink = Href$BsConsole.Support.make(undefined, /* SettingsSymbolification */22);
                                            return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                            ReasonReact.element(undefined, undefined, MissingSymbolsCallToAction$BsConsole.Banner.make(handleTask, token, handleChangeUrl, name, Caml_option.some(summary.missingSymbols), [])),
                                                            ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Symbols")])),
                                                                      ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "The Backtrace symbol management interface allows you to manage symbol files in order to symbolicate minidump files. This allows rendering and unwinding of minidump objects for improved deduplication and viewing in the web debugger. To learn more about symbolification, please refer to the documentation.")])),
                                                                                supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                                                Css.marginTop(Css.rem(0.5)),
                                                                                                /* [] */0
                                                                                              ]), /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                                                                  href: supportLink,
                                                                                                  target: "_blank"
                                                                                                }, I18N$BsConsole.show(undefined, "Symbolification documentation"))])) : null
                                                                              ]))
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                      ReasonReact.element(undefined, undefined, make((function (param) {
                                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [name]));
                                                                                }), I18N$BsConsole.get(undefined, "Uploaded archives"), summary.archives.count, summary.archives.firstUpdatedTime, summary.archives.lastUpdatedTime, [])),
                                                                      ReasonReact.element(undefined, undefined, make((function (param) {
                                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolSearch */Block.__(42, [name]));
                                                                                }), I18N$BsConsole.get(undefined, "Uploaded symbols"), summary.symbols.count, summary.symbols.firstUpdatedTime, summary.symbols.lastUpdatedTime, [])),
                                                                      ReasonReact.element(undefined, undefined, make((function (param) {
                                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [name]));
                                                                                }), I18N$BsConsole.get(undefined, "Missing symbols"), summary.missingSymbols.count, summary.missingSymbols.firstCrashTime, summary.missingSymbols.lastCrashTime, []))
                                                                    ]))
                                                          ]));
                                          }
                                          var supportLink$1 = Href$BsConsole.Support.make(undefined, /* SettingsSymbolification */22);
                                          return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                          ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Symbols")])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "The Backtrace symbol management interface allows you to manage symbol files in order to symbolicate minidump files. This allows rendering and unwinding of minidump objects for improved deduplication and viewing in the web debugger. To learn more about symbolification, please refer to the documentation.")])),
                                                                              supportLink$1 !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                                              Css.marginTop(Css.rem(0.5)),
                                                                                              /* [] */0
                                                                                            ]), /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                                                                href: supportLink$1,
                                                                                                target: "_blank"
                                                                                              }, I18N$BsConsole.show(undefined, "Symbolification documentation"))])) : null
                                                                            ]))
                                                                  ])),
                                                          React.createElement("div", {
                                                                style: {
                                                                  display: "flex",
                                                                  height: "200px",
                                                                  alignItems: "center",
                                                                  justifyContent: "center"
                                                                }
                                                              }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))
                                                        ]));
                                        })))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.updateToDateString = updateToDateString;
exports.StatsCard = StatsCard;
exports.Styles = Styles$1;
exports.component = component$1;
exports.make = make$1;
/* count Not a pure module */
