// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function fromJson(prim) {
  return prim;
}

exports.fromJson = fromJson;
/* No side effect */
