// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../../util.js");
var ApiWf$BsConsole = require("../../workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");

function getScheduledReportsEndpoint(config) {
  var services = config.services;
  var shouldBlockLegacyReportService = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.62.0");
  if (services === undefined) {
    if (shouldBlockLegacyReportService) {
      return /* ReportsServiceUnavailable */0;
    } else {
      return /* LegacyReportsService */1;
    }
  }
  var scheduledReportsService = Belt_List.getBy(services, (function (service) {
          return service.name === "scheduled-reports";
        }));
  if (scheduledReportsService === undefined) {
    if (shouldBlockLegacyReportService) {
      return /* ReportsServiceUnavailable */0;
    } else {
      return /* LegacyReportsService */1;
    }
  }
  var endpoint = scheduledReportsService.endpoint;
  if (endpoint !== undefined) {
    return /* LatestReportsService */[endpoint];
  } else {
    return /* ReportsServiceUnavailable */0;
  }
}

function typeToString(param) {
  if (param) {
    return "monthly";
  } else {
    return "daily";
  }
}

function stringToType(str) {
  switch (str) {
    case "daily" :
        return /* Daily */0;
    case "monthly" :
        return /* Monthly */1;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReports/Frequency/stringToType", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "frequency.type: \"" + (str + "\" is not an available option and was defaulted to Daily.")
          ]);
      return /* Daily */0;
  }
}

function decode(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          time: Json_decode.field("time", Json_decode.string, json),
          weekdays: Json_decode.optional((function (param) {
                  return Json_decode.field("weekdays", Json_decode.$$int, param);
                }), json),
          months: Json_decode.optional((function (param) {
                  return Json_decode.field("months", Json_decode.$$int, param);
                }), json)
        };
}

var Frequency = {
  typeToString: typeToString,
  stringToType: stringToType,
  decode: decode
};

function decode$1(json) {
  var list = Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, json)));
  return Belt_List.map(list, (function (tup) {
                var k = tup[0];
                var __x = tup[1];
                var v = Json_decode.array((function (__x) {
                        return Json_decode.array(Json_decode.string, __x);
                      }), __x);
                return /* tuple */[
                        k,
                        v
                      ];
              }));
}

var Filter = {
  decode: decode$1
};

function decode$2(json) {
  var list = Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, json)));
  return Belt_List.map(list, (function (tup) {
                var k = tup[0];
                var __x = tup[1];
                var v = Json_decode.array((function (__x) {
                        return Json_decode.array(Json_decode.string, __x);
                      }), __x);
                return /* tuple */[
                        k,
                        v
                      ];
              }));
}

var Attributes = {
  decode: decode$2
};

function decode$3(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          address: Json_decode.field("address", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          ownerId: Json_decode.field("ownerId", Json_decode.$$int, json),
          lastChangedById: Json_decode.field("lastChangedById", Json_decode.$$int, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json),
          frequency: Json_decode.field("frequency", decode, json),
          timezone: Json_decode.field("timezone", Json_decode.string, json),
          days: Json_decode.field("days", Json_decode.$$int, json),
          recipients: Json_decode.field("recipients", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          filter: Json_decode.field("filter", decode$1, json),
          attributes: Json_decode.field("attributes", decode$2, json),
          created: Json_decode.field("created", Json_decode.string, json),
          updated: Json_decode.field("updated", Json_decode.string, json),
          lastSent: Json_decode.optional((function (param) {
                  return Json_decode.field("lastSent", Json_decode.string, param);
                }), json),
          lastError: Json_decode.optional((function (param) {
                  return Json_decode.field("lastError", Json_decode.string, param);
                }), json)
        };
}

var ScheduledReports = {
  Frequency: Frequency,
  Filter: Filter,
  Attributes: Attributes,
  decode: decode$3
};

function decode$4(json) {
  return {
          statusCode: Json_decode.field("statusCode", Json_decode.$$int, json),
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

var $$Error = {
  decode: decode$4
};

function queryScheduledReportTask(projectName, reportsId, config) {
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + ("/reports/" + reportsId)));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiScheduledReports/QueryScheduledReport.queryScheduledReportTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryScheduledReport = {
  queryScheduledReportTask: queryScheduledReportTask
};

function queryScheduledReportsTask(projectName, config) {
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + "/reports"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = Json_decode.array(decode$3, json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiScheduledReports/QueryScheduledReports.queryScheduledReportsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryScheduledReports = {
  queryScheduledReportsTask: queryScheduledReportsTask
};

function createScheduledReportTask(name, enabled, frequency, timezone, days, recipients, filters, attributes, config, projectName) {
  var match = stringToType(frequency.type_);
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "enabled",
            enabled
          ],
          /* :: */[
            /* tuple */[
              "frequency",
              Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      frequency.type_
                    ],
                    /* :: */[
                      /* tuple */[
                        "time",
                        frequency.time
                      ],
                      /* :: */[
                        match ? /* tuple */[
                            "months",
                            Belt_Option.getWithDefault(frequency.months, -1)
                          ] : /* tuple */[
                            "weekdays",
                            Belt_Option.getWithDefault(frequency.weekdays, -1)
                          ],
                        /* [] */0
                      ]
                    ]
                  ])
            ],
            /* :: */[
              /* tuple */[
                "timezone",
                timezone
              ],
              /* :: */[
                /* tuple */[
                  "days",
                  days
                ],
                /* :: */[
                  /* tuple */[
                    "filter",
                    filters !== undefined ? Json_encode.object_(Belt_List.map(filters, (function (filter) {
                                  var __x = Belt_Array.map(filter[1], (function (item) {
                                          return item;
                                        }));
                                  return /* tuple */[
                                          filter[0],
                                          Json_encode.array(Util$BsConsole.identity, __x)
                                        ];
                                }))) : Json_encode.object_(/* [] */0)
                  ],
                  /* :: */[
                    /* tuple */[
                      "recipients",
                      recipients
                    ],
                    /* :: */[
                      /* tuple */[
                        "attributes",
                        attributes !== undefined ? Json_encode.object_(Belt_List.map(attributes, (function (attribute) {
                                      var __x = Belt_Array.map(attribute[1], (function (item) {
                                              return item;
                                            }));
                                      return /* tuple */[
                                              attribute[0],
                                              Json_encode.array(Util$BsConsole.identity, __x)
                                            ];
                                    }))) : Json_encode.object_(/* [] */0)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + "/reports"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiScheduledReports/CreateScheduledReport.createScheduledReportTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var CreateScheduledReport = {
  createScheduledReportTask: createScheduledReportTask
};

function editScheduledReportTask(name, enabled, frequency, timezone, days, recipients, filters, attributes, config, projectName, reportId) {
  var match = stringToType(frequency.type_);
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "enabled",
            enabled
          ],
          /* :: */[
            /* tuple */[
              "frequency",
              Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      frequency.type_
                    ],
                    /* :: */[
                      /* tuple */[
                        "time",
                        frequency.time
                      ],
                      /* :: */[
                        match ? /* tuple */[
                            "months",
                            Belt_Option.getWithDefault(frequency.months, -1)
                          ] : /* tuple */[
                            "weekdays",
                            Belt_Option.getWithDefault(frequency.weekdays, -1)
                          ],
                        /* [] */0
                      ]
                    ]
                  ])
            ],
            /* :: */[
              /* tuple */[
                "timezone",
                timezone
              ],
              /* :: */[
                /* tuple */[
                  "days",
                  days
                ],
                /* :: */[
                  /* tuple */[
                    "filter",
                    filters !== undefined ? Json_encode.object_(Belt_List.map(filters, (function (filter) {
                                  var __x = Belt_Array.map(filter[1], (function (item) {
                                          return item;
                                        }));
                                  return /* tuple */[
                                          filter[0],
                                          Json_encode.array(Util$BsConsole.identity, __x)
                                        ];
                                }))) : Json_encode.object_(/* [] */0)
                  ],
                  /* :: */[
                    /* tuple */[
                      "recipients",
                      recipients
                    ],
                    /* :: */[
                      /* tuple */[
                        "attributes",
                        attributes !== undefined ? Json_encode.object_(Belt_List.map(attributes, (function (attribute) {
                                      var __x = Belt_Array.map(attribute[1], (function (item) {
                                              return item;
                                            }));
                                      return /* tuple */[
                                              attribute[0],
                                              Json_encode.array(Util$BsConsole.identity, __x)
                                            ];
                                    }))) : Json_encode.object_(/* [] */0)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + ("/reports/" + String(reportId))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfScheduledReports/EditScheduledReport.editScheduledReportsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var EditScheduledReport = {
  editScheduledReportTask: editScheduledReportTask
};

function toogleEnabledScheduledReportTask(enabled, config, projectName, reportId) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "enabled",
          enabled
        ],
        /* [] */0
      ]);
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + ("/reports/" + String(reportId))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfScheduledReports/ToogleEnabledScheduledReport.toogleEnabledScheduledReportsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var ToogleEnabledScheduledReport = {
  toogleEnabledScheduledReportTask: toogleEnabledScheduledReportTask
};

function deleteScheduledReportTask(config, projectName, reportId) {
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + ("/reports/" + String(reportId))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Delete */1, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiScheduledReport/DeleteScheduledReport.deleteScheduledReportTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var DeleteScheduledReport = {
  deleteScheduledReportTask: deleteScheduledReportTask
};

function previewScheduledReportTask(config, projectName, reportId, recipients) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "recipients",
          recipients
        ],
        /* [] */0
      ]);
  var baseUrl = getScheduledReportsEndpoint(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/projects/" + (projectName + ("/reports/" + (String(reportId) + "/send"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = decode$4(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.message
                                  ]]);
                      }
                      var response$1 = decode$3(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiScheduledReport/PreviewScheduledReport.previewScheduledReportTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var PreviewScheduledReport = {
  previewScheduledReportTask: previewScheduledReportTask
};

exports.getScheduledReportsEndpoint = getScheduledReportsEndpoint;
exports.ScheduledReports = ScheduledReports;
exports.$$Error = $$Error;
exports.QueryScheduledReport = QueryScheduledReport;
exports.QueryScheduledReports = QueryScheduledReports;
exports.CreateScheduledReport = CreateScheduledReport;
exports.EditScheduledReport = EditScheduledReport;
exports.ToogleEnabledScheduledReport = ToogleEnabledScheduledReport;
exports.DeleteScheduledReport = DeleteScheduledReport;
exports.PreviewScheduledReport = PreviewScheduledReport;
/* Util-BsConsole Not a pure module */
