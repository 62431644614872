// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Format$BsConsole = require("./Format.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ObjectID$BsConsole = require("../../ObjectID.js");
var Attributes$BsConsole = require("./Attributes.js");
var FormatType$BsConsole = require("./FormatType.js");
var ResponseUtil$BsConsole = require("./ResponseUtil.js");
var MetadataTimes$BsConsole = require("../../MetadataTimes.js");
var AggregationType$BsConsole = require("./AggregationType.js");

function _parseList(param, list$prime) {
  var factorsDesc = param[2];
  var format = param[1];
  var attribute = param[0];
  return List.map((function (param) {
                return {
                        factor: {
                          desc: factorsDesc,
                          attribute: attribute,
                          format: format,
                          value: param[0]
                        },
                        aggregations: param[1],
                        count: param[2]
                      };
              }), list$prime);
}

function getFormatFromJson(json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  var tmp;
  switch (name) {
    case "callstack" :
        tmp = "callstack";
        break;
    case "classifiers" :
        tmp = "classifiers";
        break;
    case "fingerprint;issues;assignee" :
        tmp = "issue-assignee";
        break;
    case "fingerprint;issues;ticket" :
        tmp = "issue-ticket";
        break;
    case "fingerprint;issues;tags" :
    case "tags" :
        tmp = "tags";
        break;
    default:
      tmp = Json_decode.field("format", Json_decode.string, json);
  }
  return FormatType$BsConsole.fromString(tmp);
}

function getTypeFromJson(json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  var tmp;
  switch (name) {
    case "fingerprint;issues;assignee" :
    case "fingerprint;issues;ticket" :
        tmp = "";
        break;
    case "classifiers" :
    case "fingerprint;issues;state" :
    case "fingerprint;issues;tags" :
    case "tags" :
        tmp = "dictionary";
        break;
    default:
      tmp = Json_decode.field("type", Json_decode.string, json);
  }
  return Attributes$BsConsole.type_FromJs(tmp);
}

function getColumnNameFormatType(json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  var format = getFormatFromJson(json);
  var type_ = getTypeFromJson(json);
  return /* tuple */[
          name,
          format,
          type_
        ];
}

function columnsDescDecoder(json) {
  var match = getColumnNameFormatType(json);
  var op = AggregationType$BsConsole.fromString(Json_decode.field("op", Json_decode.string, json));
  return {
          name: match[0],
          format: match[1],
          type_: match[2],
          op: op
        };
}

function factorsDescDecoder(json) {
  var match = getColumnNameFormatType(json);
  return {
          name: match[0],
          format: match[1],
          type_: match[2]
        };
}

function parseFactorsDesc(json, attribute) {
  var maybeFactorsDesc = Belt_List.get(Belt_Option.getWithDefault(Json_decode.field("response", (function (json) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("factors_desc", (function (json) {
                                              return Json_decode.list(factorsDescDecoder, json);
                                            }), param);
                              }), json);
                }), json), /* [] */0), 0);
  if (maybeFactorsDesc !== undefined) {
    return maybeFactorsDesc;
  } else {
    return {
            name: attribute,
            format: undefined,
            type_: undefined
          };
  }
}

function legacyParseColumns(json) {
  return List.map((function (param) {
                var regex = new RegExp("(.*)\\((.*)\\)");
                var result = regex.exec(param[0]);
                if (result !== null) {
                  var maybeAgg = AggregationType$BsConsole.fromString(Caml_array.caml_array_get(result, 1));
                  var maybeFormat = FormatType$BsConsole.fromString(param[1]);
                  var attribute = Caml_array.caml_array_get(result, 2);
                  return {
                          name: attribute,
                          format: maybeFormat,
                          type_: undefined,
                          op: maybeAgg
                        };
                }
                throw [
                      Json_decode.DecodeError,
                      "failed to parse aggregation column"
                    ];
              }), Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "columns",
                      /* [] */0
                    ]
                  ], (function (json) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
                                  }), json);
                    }))(json));
}

function parseSeq(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field("seq", Json_decode.$$int, param);
                            }), json);
              }), json);
}

function parseColumnsDesc(json) {
  var legacyColumns = legacyParseColumns(json);
  var columnsDesc = Json_decode.field("response", (function (json) {
          return Json_decode.optional((function (param) {
                        return Json_decode.field("columns_desc", (function (json) {
                                      return Json_decode.list(columnsDescDecoder, json);
                                    }), param);
                      }), json);
        }), json);
  try {
    return Belt_List.map(legacyColumns, (function (legCol) {
                  var maybeMatch = Belt_List.getBy(Belt_Option.getExn(columnsDesc), (function (cold) {
                          return Caml_obj.caml_equal(/* tuple */[
                                      cold.op,
                                      cold.name
                                    ], /* tuple */[
                                      legCol.op,
                                      legCol.name
                                    ]);
                        }));
                  if (maybeMatch !== undefined) {
                    return maybeMatch;
                  } else {
                    return {
                            name: legCol.name,
                            format: legCol.format,
                            type_: undefined,
                            op: legCol.op
                          };
                  }
                }));
  }
  catch (exn){
    return columnsDesc;
  }
}

function parseColumns(json) {
  var desc = parseColumnsDesc(json);
  if (desc !== undefined) {
    return desc;
  } else {
    return legacyParseColumns(json);
  }
}

function parseIssuesColumns(json) {
  var desc = parseColumnsDesc(json);
  if (desc !== undefined) {
    return desc;
  } else {
    return legacyParseColumns(json);
  }
}

function rangeDecoder(type_, format, attribute, json) {
  try {
    return Json_decode.tuple2((function (json) {
                  return Format$BsConsole.parse2PointOh(type_, format, attribute, json);
                }), (function (json) {
                  return Format$BsConsole.parse2PointOh(type_, format, attribute, json);
                }), json);
  }
  catch (exn){
    return ;
  }
}

function distributionDecoder(type_, format, attribute, maybeJson) {
  if (maybeJson === undefined) {
    return ;
  }
  var json = Caml_option.valFromOption(maybeJson);
  return {
          keys: Json_decode.field("keys", Json_decode.$$int, json),
          tail: Json_decode.optional((function (param) {
                  return Json_decode.field("tail", Json_decode.$$int, param);
                }), json),
          vals: Json_decode.field("vals", (function (json) {
                  return Json_decode.list((function (param) {
                                return Json_decode.tuple2((function (param) {
                                              return Format$BsConsole.parse2PointOh(type_, format, attribute, param);
                                            }), Json_decode.$$int, param);
                              }), json);
                }), json)
        };
}

function binDecoder(type_, format, attribute, json) {
  try {
    var partial_arg = attribute;
    var partial_arg$1 = function (param) {
      return Format$BsConsole.parse2PointOh(type_, format, partial_arg, param);
    };
    var partial_arg$2 = attribute;
    var partial_arg$3 = function (param) {
      return Format$BsConsole.parse2PointOh(type_, format, partial_arg$2, param);
    };
    return Json_decode.list((function (param) {
                  return Json_decode.tuple3(partial_arg$3, partial_arg$1, Json_decode.$$int, param);
                }), json);
  }
  catch (raw__msg){
    var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
    if (_msg[0] === Json_decode.DecodeError) {
      return ;
    }
    throw _msg;
  }
}

function getDecoder(aggregate, type_, format, attribute) {
  switch (aggregate) {
    case /* Unique */0 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Unique */Block.__(0, [
                        attribute,
                        Json_decode.$$float(Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Unique */Block.__(0, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Distribution */1 :
        return (function (json) {
            return /* Distribution */Block.__(9, [
                      attribute,
                      distributionDecoder(type_, format, attribute, ResponseUtil$BsConsole.unwrap(json))
                    ]);
          });
    case /* Bin */2 :
        return (function (json) {
            return /* Bin */Block.__(2, [
                      attribute,
                      binDecoder(type_, format, attribute, json)
                    ]);
          });
    case /* Head */3 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Head */Block.__(3, [
                        attribute,
                        Format$BsConsole.parse2PointOh(type_, format, attribute, Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Head */Block.__(3, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Tail */4 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Tail */Block.__(1, [
                        attribute,
                        Format$BsConsole.parse2PointOh(type_, format, attribute, Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Tail */Block.__(1, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Range */5 :
        return (function (json) {
            return /* Range */Block.__(8, [
                      attribute,
                      rangeDecoder(type_, format, attribute, json)
                    ]);
          });
    case /* Mean */6 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Mean */Block.__(5, [
                        attribute,
                        /* Raw */Block.__(0, [/* `Float */[
                              365180284,
                              Json_decode.$$float(Caml_option.valFromOption(unwrapped))
                            ]])
                      ]);
            } else {
              return /* Mean */Block.__(5, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Sum */7 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Sum */Block.__(4, [
                        attribute,
                        Format$BsConsole.parse2PointOh(type_, format, attribute, Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Sum */Block.__(4, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Min */8 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Min */Block.__(6, [
                        attribute,
                        Format$BsConsole.parse2PointOh(type_, format, attribute, Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Min */Block.__(6, [
                        attribute,
                        undefined
                      ]);
            }
          });
    case /* Max */9 :
        return (function (json) {
            var unwrapped = ResponseUtil$BsConsole.unwrap(json);
            if (unwrapped !== undefined) {
              return /* Max */Block.__(7, [
                        attribute,
                        Format$BsConsole.parse2PointOh(type_, format, attribute, Caml_option.valFromOption(unwrapped))
                      ]);
            } else {
              return /* Max */Block.__(7, [
                        attribute,
                        undefined
                      ]);
            }
          });
    
  }
}

function decodeExn(param, columns, json) {
  var attribute = param[0];
  var factorsDesc = parseFactorsDesc(json, attribute);
  return _parseList(/* tuple */[
              attribute,
              param[1],
              factorsDesc
            ], Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "values",
                      /* [] */0
                    ]
                  ], (function (json) {
                      var l$prime = Json_decode.list((function (param) {
                              return Json_decode.tuple3(Json_decode.string, (function (param) {
                                            return Json_decode.list((function (j) {
                                                          return j;
                                                        }), param);
                                          }), Json_decode.$$int, param);
                            }), json);
                      return List.map((function (param) {
                                    return /* tuple */[
                                            param[0],
                                            List.map2((function (json, column) {
                                                    var decoder = getDecoder(Belt_Option.getExn(column.op), column.type_, column.format, column.name);
                                                    return Curry._1(decoder, json);
                                                  }), param[1], columns),
                                            param[2]
                                          ];
                                  }), l$prime);
                    }))(json));
}

function metadata(json) {
  var partial_arg_000 = Json_decode.at(/* :: */[
        "_",
        /* :: */[
          "runtime",
          /* :: */[
            "having",
            /* :: */[
              "groups",
              /* [] */0
            ]
          ]
        ]
      ], Json_decode.$$int);
  var partial_arg_001 = /* :: */[
    Json_decode.at(/* :: */[
          "_",
          /* :: */[
            "runtime",
            /* :: */[
              "group_by",
              /* :: */[
                "groups",
                /* [] */0
              ]
            ]
          ]
        ], Json_decode.$$int),
    /* [] */0
  ];
  var partial_arg = /* :: */[
    partial_arg_000,
    partial_arg_001
  ];
  var partial_arg$1 = function (param) {
    return Json_decode.oneOf(partial_arg, param);
  };
  return {
          hasNextPage: Json_decode.withDefault(false, (function (json) {
                  var groups = Json_decode.oneOf(/* :: */[
                        Json_decode.at(/* :: */[
                              "_",
                              /* :: */[
                                "runtime",
                                /* :: */[
                                  "having",
                                  /* :: */[
                                    "groups",
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ], Json_decode.$$int),
                        /* :: */[
                          Json_decode.at(/* :: */[
                                "_",
                                /* :: */[
                                  "runtime",
                                  /* :: */[
                                    "group_by",
                                    /* :: */[
                                      "groups",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ], Json_decode.$$int),
                          /* [] */0
                        ]
                      ], json);
                  var limit = Json_decode.at(/* :: */[
                          "response",
                          /* :: */[
                            "pagination",
                            /* :: */[
                              "limit",
                              /* [] */0
                            ]
                          ]
                        ], Json_decode.$$int)(json);
                  var offset = Json_decode.at(/* :: */[
                          "response",
                          /* :: */[
                            "pagination",
                            /* :: */[
                              "offset",
                              /* [] */0
                            ]
                          ]
                        ], Json_decode.$$int)(json);
                  return groups > (limit + offset | 0);
                }), json),
          groups: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.optional(partial_arg$1, param);
                }), json),
          instances: Json_decode.withDefault(undefined, Json_decode.at(/* :: */[
                    "_",
                    /* :: */[
                      "runtime",
                      /* :: */[
                        "filter",
                        /* :: */[
                          "rows",
                          /* [] */0
                        ]
                      ]
                    ]
                  ], (function (param) {
                      return Json_decode.optional(Json_decode.$$int, param);
                    })), json),
          pagination: Json_decode.withDefault(undefined, (function (json) {
                  return Json_decode.optional((function (json) {
                                return {
                                        limit: Json_decode.at(/* :: */[
                                                "response",
                                                /* :: */[
                                                  "pagination",
                                                  /* :: */[
                                                    "limit",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ], Json_decode.$$int)(json),
                                        offset: Json_decode.at(/* :: */[
                                                "response",
                                                /* :: */[
                                                  "pagination",
                                                  /* :: */[
                                                    "offset",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ], Json_decode.$$int)(json)
                                      };
                              }), json);
                }), json),
          tx: ObjectID$BsConsole.fromDecimalString(Json_decode.withDefault("1", Json_decode.at(/* :: */[
                        "_",
                        /* :: */[
                          "tx",
                          /* [] */0
                        ]
                      ], (function (json) {
                          return Json_decode.string(JSON.stringify(json));
                        })), json)),
          seq: Json_decode.withDefault(undefined, Json_decode.at(/* :: */[
                    "response",
                    /* :: */[
                      "seq",
                      /* [] */0
                    ]
                  ], (function (param) {
                      return Json_decode.optional(Json_decode.$$int, param);
                    })), json),
          times: Json_decode.withDefault(undefined, Json_decode.at(/* :: */[
                    "_",
                    /* :: */[
                      "runtime",
                      /* [] */0
                    ]
                  ], MetadataTimes$BsConsole.decode), json)
        };
}

var Aggregate = {
  metadata: metadata
};

function factorToFormat(factor) {
  var format = factor.desc.format;
  var type_ = factor.desc.type_;
  var valueJson = factor.value;
  return Format$BsConsole.parse2PointOh(type_, format, undefined, valueJson);
}

exports._parseList = _parseList;
exports.getFormatFromJson = getFormatFromJson;
exports.getTypeFromJson = getTypeFromJson;
exports.getColumnNameFormatType = getColumnNameFormatType;
exports.columnsDescDecoder = columnsDescDecoder;
exports.factorsDescDecoder = factorsDescDecoder;
exports.parseFactorsDesc = parseFactorsDesc;
exports.legacyParseColumns = legacyParseColumns;
exports.parseSeq = parseSeq;
exports.parseColumnsDesc = parseColumnsDesc;
exports.parseColumns = parseColumns;
exports.parseIssuesColumns = parseIssuesColumns;
exports.rangeDecoder = rangeDecoder;
exports.distributionDecoder = distributionDecoder;
exports.binDecoder = binDecoder;
exports.getDecoder = getDecoder;
exports.decodeExn = decodeExn;
exports.Aggregate = Aggregate;
exports.factorToFormat = factorToFormat;
/* Format-BsConsole Not a pure module */
