// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Axis = require("@visx/axis");
var Grid = require("@visx/grid");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Brush = require("@visx/brush");
var Group = require("@visx/group");
var Scale = require("@visx/scale");
var Shape = require("@visx/shape");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Pattern = require("@visx/pattern");
var Tooltip = require("@visx/tooltip");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("./I18N.js");
var Caml_splice_call = require("bs-platform/lib/js/caml_splice_call.js");
var Colors$BsConsole = require("./Colors.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

function getXScale(domain, xMax, padding) {
  return Scale.scaleBand().range([
                  0,
                  xMax
                ]).domain(domain).padding(padding);
}

function getYScale(yMax, getBinValue, data) {
  var values = Belt_Array.map(data, getBinValue);
  return Scale.scaleLinear().range([
                0,
                yMax - 1
              ]).domain([
              Caml_splice_call.spliceApply(Math.min, [values]),
              Caml_primitive.caml_float_max(Caml_splice_call.spliceApply(Math.max, [values]), 1)
            ]);
}

function getStrokeColor(colorRange, restColor, index) {
  var color = Belt_Option.getWithDefault(Belt_Array.get(colorRange, index), restColor);
  return "#" + color;
}

function getDefaultMargins(leftAxisLabel, bottomAxisLabel) {
  var left = Belt_Option.isSome(leftAxisLabel) ? 60 : 8;
  var bottom = Belt_Option.isSome(bottomAxisLabel) ? 50 : 8;
  return {
          top: 8,
          right: 8,
          bottom: bottom,
          left: left
        };
}

var defaultTickFormat = Pervasives.string_of_float;

function getGroupClassName(groupedBinData) {
  var bins = Belt_Array.get(groupedBinData, 0);
  if (bins !== undefined) {
    return Css.style(/* :: */[
                Css.selector(".bin-boundary:nth-of-type(n) + .data-point:nth-of-type(" + (String(bins.length) + "n)"), /* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                /* [] */0
              ]);
  } else {
    return "";
  }
}

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var boundingRect = Css.style(/* :: */[
      Css.selector("rect:hover + .data-points", /* :: */[
            Css.opacity(1.0),
            /* [] */0
          ]),
      /* [] */0
    ]);

var dataPointsGroup = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.opacity(0),
            /* [] */0
          ]),
      /* :: */[
        "data-points",
        /* [] */0
      ]
    ]);

var dataPoint = Css.style(/* :: */[
      Css.pointerEvents(/* none */-922086728),
      /* [] */0
    ]);

var bar = Css.style(/* :: */[
      Css.pointerEvents(/* none */-922086728),
      /* [] */0
    ]);

var backingBin = Css.style(/* :: */[
      Css.opacity(0.0),
      /* [] */0
    ]);

var activeBar = Css.style(/* :: */[
      Css.opacity(0.3),
      /* [] */0
    ]);

var tooltipRange = Css.style(/* :: */[
      Css.marginBottom(Css.rem(0.5)),
      /* [] */0
    ]);

var tooltipData = Css.style(/* :: */[
      Css.selector(":not(:last-of-type)", /* :: */[
            Css.marginBottom(Css.px(6)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var tooltipDataName = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.maxWidth(Css.px(250)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipDataCount = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* [] */0
    ]);

var noDataContainer = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  getGroupClassName: getGroupClassName,
  container: container,
  boundingRect: boundingRect,
  dataPointsGroup: dataPointsGroup,
  dataPoint: dataPoint,
  bar: bar,
  backingBin: backingBin,
  activeBar: activeBar,
  tooltipRange: tooltipRange,
  tooltipData: tooltipData,
  tooltipDataName: tooltipDataName,
  tooltipDataCount: tooltipDataCount,
  noDataContainer: noDataContainer
};

var tooltipStyles = Object.assign(Tooltip.defaultStyles, {
      backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.grey35),
      opacity: "0.9",
      color: Colors$BsConsole.octothorpe(Colors$BsConsole.white),
      zIndex: 10000
    });

function BinChart$DefaultNoDataLabel(Props) {
  return React.createElement("div", {
              className: noDataContainer
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  children: I18N$BsConsole.show(undefined, "no data")
                }));
}

var DefaultNoDataLabel = {
  make: BinChart$DefaultNoDataLabel
};

function BinChart(Props) {
  var height = Props.height;
  var width = Props.width;
  var data = Props.data;
  var getBinRange = Props.getBinRange;
  var getBinValue = Props.getBinValue;
  var tickFormatOpt = Props.tickFormat;
  Props.range;
  Props.bins;
  var showGridRowsOpt = Props.showGridRows;
  var showGridColumnsOpt = Props.showGridColumns;
  var renderTooltipData = Props.renderTooltipData;
  var leftAxisLabel = Props.leftAxisLabel;
  var bottomAxisLabel = Props.bottomAxisLabel;
  var barFill = Props.barFill;
  var noDataLabelOpt = Props.noDataLabel;
  var margins = Props.margins;
  var binPaddingOpt = Props.binPadding;
  var onChartBrushSelection = Props.onChartBrushSelection;
  var tickFormat = tickFormatOpt !== undefined ? tickFormatOpt : defaultTickFormat;
  var showGridRows = showGridRowsOpt !== undefined ? showGridRowsOpt : false;
  var showGridColumns = showGridColumnsOpt !== undefined ? showGridColumnsOpt : false;
  var noDataLabel = noDataLabelOpt !== undefined ? noDataLabelOpt : (function (param) {
        return React.createElement(BinChart$DefaultNoDataLabel, { });
      });
  var binPadding = binPaddingOpt !== undefined ? binPaddingOpt : 0.1;
  var match = React.useState((function () {
          
        }));
  var setTooltipData = match[1];
  var tooltipData = match[0];
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  var primaryColor = palette.primary.main;
  var defaultMargins = getDefaultMargins(leftAxisLabel, bottomAxisLabel);
  var chartMargins = Belt_Option.getWithDefault(margins, defaultMargins);
  var xMax = React.useMemo((function () {
          return (width - chartMargins.left | 0) - chartMargins.right | 0;
        }), [width]);
  var yMax = React.useMemo((function () {
          return (height - chartMargins.top | 0) - chartMargins.bottom | 0;
        }), [height]);
  var xScale = React.useMemo((function () {
          return getXScale(Belt_Array.map(data, (function (bin) {
                            return Curry._1(getBinRange, bin)[0];
                          })), xMax, binPadding);
        }), /* tuple */[
        data,
        xMax
      ]);
  var yScale = React.useMemo((function () {
          return getYScale(yMax, getBinValue, data);
        }), /* tuple */[
        yMax,
        data
      ]);
  var getX = function (bin) {
    return Curry._1(getBinRange, bin)[0];
  };
  var handleMouseMove = React.useCallback((function ($$event) {
          var el = $$event.currentTarget;
          var boundingRect = el.getBoundingClientRect();
          var left = $$event.clientX - boundingRect.left | 0;
          var top = $$event.clientY - boundingRect.top | 0;
          var x = left - chartMargins.left | 0;
          if (!(x >= 0 && x <= xMax)) {
            return Curry._1(setTooltipData, (function (param) {
                          
                        }));
          }
          var bin = Belt_Array.getBy(data, (function (bin) {
                  var match = Curry._1(getBinRange, bin);
                  var width = xScale.bandwidth();
                  var binStart = Curry._1(xScale, match[0]);
                  var binEnd = binStart + width;
                  if (x >= binStart) {
                    return x <= binEnd;
                  } else {
                    return false;
                  }
                }));
          if (bin === undefined) {
            return ;
          }
          var data$1 = Caml_option.valFromOption(bin);
          return Curry._1(setTooltipData, (function (param) {
                        return /* tuple */[
                                left,
                                top,
                                data$1
                              ];
                      }));
        }), /* tuple */[
        data,
        xMax
      ]);
  var handleMouseLeave = function (_event) {
    return Curry._1(setTooltipData, (function (param) {
                  
                }));
  };
  var tmp = {
    className: container
  };
  var tmp$1 = Belt_Option.isSome(renderTooltipData) ? handleMouseLeave : undefined;
  if (tmp$1 !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.isSome(renderTooltipData) ? handleMouseMove : undefined;
  if (tmp$2 !== undefined) {
    tmp.onMouseMove = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", tmp, React.createElement("svg", {
                  height: String(height),
                  width: String(width)
                }, React.createElement("rect", {
                      height: String(0 > height ? 0 : height),
                      width: String(width),
                      fill: "transparent"
                    }), React.createElement(Group.Group, {
                      top: chartMargins.top,
                      left: chartMargins.left,
                      children: null
                    }, showGridRows ? React.createElement(Grid.GridRows, {
                            scale: yScale,
                            numTicks: 4,
                            width: xMax,
                            height: yMax,
                            stroke: "#" + Colors$BsConsole.grey65
                          }) : null, showGridColumns ? React.createElement(Grid.GridColumns, {
                            scale: xScale,
                            numTicks: 4,
                            width: xMax,
                            height: yMax,
                            stroke: "#" + Colors$BsConsole.grey65
                          }) : null, leftAxisLabel !== undefined ? React.createElement(Axis.AxisLeft, {
                            scale: yScale,
                            numTicks: 4,
                            hideAxisLine: true,
                            hideTicks: true,
                            label: leftAxisLabel
                          }) : null, bottomAxisLabel !== undefined ? React.createElement(Axis.AxisBottom, {
                            scale: xScale,
                            numTicks: 4,
                            hideAxisLine: true,
                            hideTicks: true,
                            label: bottomAxisLabel,
                            tickFormat: tickFormat,
                            top: yMax
                          }) : null, Belt_Array.mapWithIndex(data, (function (idx, bin) {
                            var height = Caml_primitive.caml_float_max(0, Curry._1(yScale, Curry._1(getBinValue, bin)) + 1);
                            var width = xScale.bandwidth();
                            var x = Curry._1(xScale, getX(bin));
                            var y = yMax - height;
                            var isActive = Belt_Option.getWithDefault(Belt_Option.map(tooltipData, (function (param) {
                                        var xPos = param[0] - chartMargins.left | 0;
                                        var binEnd = x + width;
                                        if (xPos >= x) {
                                          return xPos <= binEnd;
                                        } else {
                                          return false;
                                        }
                                      })), false);
                            return React.createElement(React.Fragment, {
                                        children: null,
                                        key: "Bar__-" + (String(idx) + ("__" + (Pervasives.string_of_float(Curry._1(getBinValue, bin)) + (
                                                isActive ? "_active" : ""
                                              ))))
                                      }, React.createElement(Shape.Bar, {
                                            x: x,
                                            y: y,
                                            width: width,
                                            height: height,
                                            className: bar,
                                            fill: Belt_Option.getWithDefault(barFill, primaryColor)
                                          }), React.createElement(Shape.Bar, {
                                            x: x,
                                            y: 0,
                                            width: width,
                                            height: yMax > 0 ? yMax : 0,
                                            className: Css.merge(/* :: */[
                                                  backingBin,
                                                  /* :: */[
                                                    isActive ? activeBar : "",
                                                    /* [] */0
                                                  ]
                                                ]),
                                            fill: palette.grey.grey300
                                          }));
                          }))), onChartBrushSelection !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Pattern.PatternLines, {
                            id: "lines",
                            height: 8,
                            width: 8,
                            stroke: palette.grey.grey400,
                            orientation: ["diagonal"]
                          }), React.createElement(Brush.Brush, {
                            handleSize: 8,
                            height: height - chartMargins.bottom | 0,
                            width: (width - chartMargins.left | 0) - chartMargins.right | 0,
                            xScale: xScale,
                            yScale: yScale,
                            onBrushEnd: onChartBrushSelection,
                            resetOnEnd: true,
                            selectedBoxStyle: {
                              fill: "url(#lines)",
                              fillOpacity: 1,
                              stroke: palette.grey.grey500,
                              strokeWidth: 2
                            },
                            resizeTriggerAreas: [
                              "left",
                              "right"
                            ]
                          })) : null), tooltipData !== undefined && renderTooltipData !== undefined ? React.createElement(Tooltip.TooltipWithBounds, {
                    top: tooltipData[1],
                    left: tooltipData[0],
                    style: tooltipStyles,
                    children: Curry._1(renderTooltipData, tooltipData[2]),
                    key: Pervasives.string_of_float(Math.random())
                  }) : null, data.length !== 0 ? null : Curry._1(noDataLabel, undefined));
}

var make = BinChart;

exports.getXScale = getXScale;
exports.getYScale = getYScale;
exports.getStrokeColor = getStrokeColor;
exports.getDefaultMargins = getDefaultMargins;
exports.defaultTickFormat = defaultTickFormat;
exports.Style = Style;
exports.tooltipStyles = tooltipStyles;
exports.DefaultNoDataLabel = DefaultNoDataLabel;
exports.make = make;
/* container Not a pure module */
