// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var DataCard$BsConsole = require("../utilities/DataCard.js");
var SubUserLimit$BsConsole = require("./SubUserLimit.js");
var SubStorageLimit$BsConsole = require("./SubStorageLimit.js");

var cardWrapper = Css.style(/* :: */[
      Css.height(Css.pct(100)),
      /* :: */[
        Css.marginBottom(Css.rem(1.0)),
        /* [] */0
      ]
    ]);

function SubUsageDisplay(Props) {
  var limits = Props.limits;
  var config = Props.config;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var daysFloatUntilNewMonth = SubUtil$BsConsole.getDaysUntilNextMonth(Date.now());
  var daysUntilNewMonth = daysFloatUntilNewMonth | 0;
  var limit = limits.submissions.limit;
  var errorsDataCard = limit ? React.createElement(DataCard$BsConsole.make, {
          title: I18N$BsConsole.get(undefined, "Monthly errors received"),
          numerator: limits.submissions.counter,
          denominator: limit[0],
          unit: I18N$BsConsole.get(undefined, "errors"),
          verb: I18N$BsConsole.get(undefined, "received"),
          formatter: /* Millions */0,
          failure: undefined,
          extra: /* Text */Block.__(0, [Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Resets in ",
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ])
                          ]),
                        "Resets in %d %s"
                      ]), daysUntilNewMonth, daysUntilNewMonth === 1 ? "day" : "days")])
        }) : React.createElement(DataCard$BsConsole.UnlimitedCard.make, {
          title: I18N$BsConsole.get(undefined, "Monthly errors received"),
          numerator: limits.submissions.counter,
          unit: I18N$BsConsole.get(undefined, "errors"),
          verb: I18N$BsConsole.get(undefined, "received"),
          formatter: /* Millions */0,
          failure: undefined,
          extra: /* Text */Block.__(0, [Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Resets in ",
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ])
                          ]),
                        "Resets in %d %s"
                      ]), daysUntilNewMonth, daysUntilNewMonth === 1 ? "day" : "days")])
        });
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* stretch */-162316795,
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(10)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(Row2$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.height(Css.pct(100)),
                            /* :: */[
                              Css.important(Css.flexWrap(/* wrap */-822134326)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: cardWrapper
                      }, errorsDataCard), React.createElement("div", {
                        className: cardWrapper
                      }, React.createElement(SubStorageLimit$BsConsole.make, {
                            config: config,
                            token: token,
                            limits: limits
                          })), React.createElement("div", {
                        className: cardWrapper
                      }, React.createElement(SubUserLimit$BsConsole.make, {
                            config: config,
                            token: token,
                            limits: limits,
                            handleChangeUrl: handleChangeUrl
                          })))
            });
}

var make = SubUsageDisplay;

exports.cardWrapper = cardWrapper;
exports.make = make;
/* cardWrapper Not a pure module */
