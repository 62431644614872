// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");

function isEmptyArr(arr) {
  return arr.length === 0;
}

function isNotEmptyArr(arr) {
  return arr.length !== 0;
}

function isNotEmptyStr(s) {
  return s.trim() !== "";
}

function getConditionValue(conditionsMaybe) {
  if (conditionsMaybe !== undefined) {
    return Belt_Array.get(conditionsMaybe, 0);
  }
  
}

var unsupportedType = I18N$BsConsole.get(undefined, "unsupported type");

var unsupportedSource = I18N$BsConsole.get(undefined, "unsupported source");

var unsupportedCondition = I18N$BsConsole.get(undefined, "unsupported condition");

var invalidConfiguration = I18N$BsConsole.get(undefined, "invalid configuration");

var noSource = I18N$BsConsole.get(undefined, "no source");

var orderError = I18N$BsConsole.get(undefined, "Error modifying order of actions.");

var adminOnlyText = I18N$BsConsole.get(undefined, "Only admins can update submission actions.");

var allRemovedText = I18N$BsConsole.get(undefined, "All actions removed.");

var deletedText = I18N$BsConsole.get(undefined, "Deleted action.");

var orderUpdatedText = I18N$BsConsole.get(undefined, "Order updated.");

var submissionText = I18N$BsConsole.get(undefined, "Submission actions");

var projectNotFoundText = I18N$BsConsole.get(undefined, "Project not found");

var sourceText = I18N$BsConsole.get(undefined, "Source");

var conditionText = I18N$BsConsole.get(undefined, "Condition");

var probabilityText = I18N$BsConsole.get(undefined, "Probability");

var orderText = I18N$BsConsole.get(undefined, "Order");

var attributeText = I18N$BsConsole.get(undefined, "Attribute");

var valueText = I18N$BsConsole.get(undefined, "Value");

var moduleText = I18N$BsConsole.get(undefined, "Module");

var callstackText = I18N$BsConsole.get(undefined, "Callstack");

var regexText = I18N$BsConsole.get(undefined, "Regular expression");

var stringCompText = I18N$BsConsole.get(undefined, "String comparison");

var changeOrderText = I18N$BsConsole.get(undefined, "Update order");

var ssaCreatedText = I18N$BsConsole.get(undefined, "Server-side action created.");

var selectAttributeText = I18N$BsConsole.get(undefined, "Select attribute");

var noAttributesText = I18N$BsConsole.get(undefined, "No attributes");

var noUsersText = I18N$BsConsole.get(undefined, "You don't have any users");

var enterRegExpText = I18N$BsConsole.get(undefined, "Enter regular expression");

var enterAttrText = I18N$BsConsole.get(undefined, "Enter attribute value");

var setAssigneeText = I18N$BsConsole.get(undefined, "Set assignee");

var assigneeText = I18N$BsConsole.get(undefined, "Assignee");

var defineAttrToSetText = I18N$BsConsole.get(undefined, "Define the attribute value to set.");

var defineAttrToUnionText = I18N$BsConsole.get(undefined, "Define the attribute value to union.");

var enterValueText = I18N$BsConsole.get(undefined, "Enter value");

var addAnotherText = I18N$BsConsole.get(undefined, "Add another value");

var createText = I18N$BsConsole.get(undefined, "Create");

var cancelText = I18N$BsConsole.get(undefined, "Cancel");

var removeText = I18N$BsConsole.get(undefined, "Remove");

var deleteText = I18N$BsConsole.get(undefined, "Delete");

var selectSourceText = I18N$BsConsole.get(undefined, "Select source type");

var selectConditionText = I18N$BsConsole.get(undefined, "Select condition type");

var inverseText = I18N$BsConsole.get(undefined, "inverse");

var inverseText2 = I18N$BsConsole.get(undefined, "Inverse");

var ignoreTitleText = I18N$BsConsole.get(undefined, "Reject submission");

var ignoreDescriptionText = I18N$BsConsole.get(undefined, "Do not process the object. If conditions are matched, no other actions will be performed on that dataset.");

var indexOnlyTitleText = I18N$BsConsole.get(undefined, "Drop objects and index only");

var indexOnlyDescriptionText = I18N$BsConsole.get(undefined, "Process the object, but delete it physically. This is similar to sampling, but with a different deletion reason. Other actions may be applied to the data, but objects will not be stored.");

var setValuesTitleText = I18N$BsConsole.get(undefined, "Set values");

var setValueTitleText = I18N$BsConsole.get(undefined, "Set value");

var setValuesDescriptionText = I18N$BsConsole.get(undefined, "Update an attribute value on the object. These actions will be performed in order. When multiple conditions match, the last action will be applied. Setting an assignee will only apply on fingerprint creation.");

var unionValuesTitleText = I18N$BsConsole.get(undefined, "Union values");

var unionValueTitleText = I18N$BsConsole.get(undefined, "Union value");

var unionValuesDescriptionText = I18N$BsConsole.get(undefined, "Update label-formatted attributes (such as classifiers and tags) on the object. When multiple conditions match, all tags will be applied.");

var settingsPageDescriptionText = I18N$BsConsole.get(undefined, "Backtrace can be configured here to perform automated actions based on the results of analyzing incoming objects such as errors and dumps. Provide a condition and an action; the action will be performed when Backtrace ingests an object that meets the condition.");

var usageText = I18N$BsConsole.get(undefined, "Statistics");

var matchedText = I18N$BsConsole.get(undefined, "Matched");

var mismatchedText = I18N$BsConsole.get(undefined, "Mismatched");

var condEvalFailText = I18N$BsConsole.get(undefined, "Condition evaluation failed");

var noStatsForActionText = I18N$BsConsole.get(undefined, "No statistics for this action");

var lastModifiedText = I18N$BsConsole.get(undefined, "Last modified");

var executionFailedText = I18N$BsConsole.get(undefined, "Execution failed");

function lastModifiedString(timestamp) {
  return Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "%s %s"
                ]), lastModifiedText, L10N_date_time$BsConsole.Format.textDay(undefined, new Date(timestamp * 1000)));
}

function overallStatsView(stats) {
  var executionFailed = stats.executionFailed;
  if (executionFailed !== undefined) {
    return React.createElement(Row2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.fontStyle(/* italic */107228912),
                      /* [] */0
                    ]),
                children: null
              }, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.marginRight(Css.px(4)),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.showSkip(usageText)), React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.marginRight(Css.px(4)),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.showSkip("-")), React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.marginRight(Css.px(4)),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.showSkip(executionFailedText + ":")), React.createElement("div", undefined, I18N$BsConsole.showSkip(String(executionFailed))));
  } else {
    return null;
  }
}

function getLastModified(all, project) {
  return Belt_Array.reduce(all, undefined, (function (acc, curr) {
                if (curr.project !== project.pid) {
                  return acc;
                }
                var match = curr.__modify;
                if (match !== undefined) {
                  if (acc !== undefined && match <= acc) {
                    return acc;
                  } else {
                    return match;
                  }
                } else if (acc !== undefined) {
                  return acc;
                } else {
                  return ;
                }
              }));
}

var Ellipse;

exports.isEmptyArr = isEmptyArr;
exports.isNotEmptyArr = isNotEmptyArr;
exports.isNotEmptyStr = isNotEmptyStr;
exports.getConditionValue = getConditionValue;
exports.Ellipse = Ellipse;
exports.unsupportedType = unsupportedType;
exports.unsupportedSource = unsupportedSource;
exports.unsupportedCondition = unsupportedCondition;
exports.invalidConfiguration = invalidConfiguration;
exports.noSource = noSource;
exports.orderError = orderError;
exports.adminOnlyText = adminOnlyText;
exports.allRemovedText = allRemovedText;
exports.deletedText = deletedText;
exports.orderUpdatedText = orderUpdatedText;
exports.submissionText = submissionText;
exports.projectNotFoundText = projectNotFoundText;
exports.sourceText = sourceText;
exports.conditionText = conditionText;
exports.probabilityText = probabilityText;
exports.orderText = orderText;
exports.attributeText = attributeText;
exports.valueText = valueText;
exports.moduleText = moduleText;
exports.callstackText = callstackText;
exports.regexText = regexText;
exports.stringCompText = stringCompText;
exports.changeOrderText = changeOrderText;
exports.ssaCreatedText = ssaCreatedText;
exports.selectAttributeText = selectAttributeText;
exports.noAttributesText = noAttributesText;
exports.noUsersText = noUsersText;
exports.enterRegExpText = enterRegExpText;
exports.enterAttrText = enterAttrText;
exports.setAssigneeText = setAssigneeText;
exports.assigneeText = assigneeText;
exports.defineAttrToSetText = defineAttrToSetText;
exports.defineAttrToUnionText = defineAttrToUnionText;
exports.enterValueText = enterValueText;
exports.addAnotherText = addAnotherText;
exports.createText = createText;
exports.cancelText = cancelText;
exports.removeText = removeText;
exports.deleteText = deleteText;
exports.selectSourceText = selectSourceText;
exports.selectConditionText = selectConditionText;
exports.inverseText = inverseText;
exports.inverseText2 = inverseText2;
exports.ignoreTitleText = ignoreTitleText;
exports.ignoreDescriptionText = ignoreDescriptionText;
exports.indexOnlyTitleText = indexOnlyTitleText;
exports.indexOnlyDescriptionText = indexOnlyDescriptionText;
exports.setValuesTitleText = setValuesTitleText;
exports.setValueTitleText = setValueTitleText;
exports.setValuesDescriptionText = setValuesDescriptionText;
exports.unionValuesTitleText = unionValuesTitleText;
exports.unionValueTitleText = unionValueTitleText;
exports.unionValuesDescriptionText = unionValuesDescriptionText;
exports.settingsPageDescriptionText = settingsPageDescriptionText;
exports.usageText = usageText;
exports.matchedText = matchedText;
exports.mismatchedText = mismatchedText;
exports.condEvalFailText = condEvalFailText;
exports.noStatsForActionText = noStatsForActionText;
exports.lastModifiedText = lastModifiedText;
exports.executionFailedText = executionFailedText;
exports.lastModifiedString = lastModifiedString;
exports.overallStatsView = overallStatsView;
exports.getLastModified = getLastModified;
/* unsupportedType Not a pure module */
