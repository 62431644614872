// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var ReactMarkdown = require("react-markdown");

function make(source, children) {
  return ReasonReact.wrapJsForReason(ReactMarkdown, {
              source: source,
              escapeHtml: false
            }, children);
}

var ReactMarkdown$1 = {
  make: make
};

var component = RR$BsConsole.statelessComponent("Markdown-BsConsole");

function make$1(source, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (_self) {
              return ((function () {
        const links = document.links;
        for (let i = 0, linksLength = links.length; i < linksLength; i++) {
          if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
          }
        }
      })());
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, make(source, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.ReactMarkdown = ReactMarkdown$1;
exports.component = component;
exports.make = make$1;
/* component Not a pure module */
