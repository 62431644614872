// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Guides$BsConsole = require("../guides/Guides.js");
var PS_Documentation_DocCard$BsConsole = require("./PS_Documentation_DocCard.js");

var grid = Css.style(/* :: */[
      Css.marginTop(Css.rem(1.0)),
      /* [] */0
    ]);

var Style = {
  grid: grid
};

function render(handleGuideClick, t) {
  var subHeading = t.subHeading;
  return React.createElement("span", {
              key: "toc-" + t.headline
            }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(t.headline)])), subHeading !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(subHeading)])) : null, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(grid, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [Belt_Array.map(t.guides, (function (guide) {
                              return ReasonReact.element(guide.name, undefined, PS_Documentation_DocCard$BsConsole.make(guide.name, guide.iconUrl, (function (_e) {
                                                return Curry._1(handleGuideClick, guide);
                                              }), []));
                            }))])));
}

function staticArray(guideList) {
  return [
          {
            guides: Guides$BsConsole.byFormat(guideList, /* Framework */0),
            headline: I18N$BsConsole.get(undefined, "Native platforms"),
            subHeading: I18N$BsConsole.get(undefined, "If you are integrating crash reporting for the first time, we recommend Crashpad. If you are developing a server or embedded application for UNIX, we recommend the coredump route or an advanced integration.")
          },
          {
            guides: Guides$BsConsole.byFormat(guideList, /* Language */1),
            headline: I18N$BsConsole.get(undefined, "High-level integrations"),
            subHeading: undefined
          },
          {
            guides: Guides$BsConsole.byFormat(guideList, /* DataSources */5),
            headline: I18N$BsConsole.get(undefined, "Game console portals"),
            subHeading: undefined
          },
          {
            guides: Guides$BsConsole.byFormat(guideList, /* Symbolication */4),
            headline: I18N$BsConsole.get(undefined, "Symbolication"),
            subHeading: undefined
          },
          {
            guides: Guides$BsConsole.byFormat(guideList, /* Testing */2),
            headline: I18N$BsConsole.get(undefined, "Testing"),
            subHeading: undefined
          }
        ];
}

var Section = {
  render: render,
  staticArray: staticArray
};

var component = ReasonReact.statelessComponent("PS_Documentation_TableOfContents");

function make(handleGuideClick, sections, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [Belt_Array.map(sections, (function (param) {
                                      return render(handleGuideClick, param);
                                    }))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var DocCard;

exports.DocCard = DocCard;
exports.Style = Style;
exports.Section = Section;
exports.component = component;
exports.make = make;
/* grid Not a pure module */
