// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var ApiWf$BsConsole = require("./workflows-api/ApiWf.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var WfLayout$BsConsole = require("./WfLayout.js");
var ApiAlerts$BsConsole = require("../project-settings/alerts/ApiAlerts.js");
var WfWatcher$BsConsole = require("./WfWatcher.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var LoadingOverlay$BsConsole = require("../LoadingOverlay.js");
var WatcherNewTask$BsConsole = require("../WatcherNewTask.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");
var WfWatchersListWatcher$BsConsole = require("./WfWatchersListWatcher.js");
var AutomatedActionsMovedBanner$BsConsole = require("./AutomatedActionsMovedBanner.js");

var header = Css.style(/* :: */[
      Css.fontWeight(Css.medium),
      /* :: */[
        Css.textAlign(/* left */-944764921),
        /* [] */0
      ]
    ]);

var headerCell = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* [] */0
    ]);

var Styles = {
  header: header,
  headerCell: headerCell
};

var component = RR$BsConsole.reducerComponent("WfWatchersList-BsConsole");

function make(twoWaySyncStatuses, onCreateIntegration, refetchingWatchers, availableIntegrations, onEditIntegration, onIntegrationDeleted, watchers, token, project, config, handleChangeUrl, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var match = WorkflowVersion$BsConsole.current(config);
              if (typeof match === "number") {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
              }
              var onSuccess = function (results) {
                return Curry._1(send, /* SetAlerts */Block.__(2, [results.body]));
              };
              var onFailure = function (param) {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching alerts."));
              };
              var maybeTask = ApiAlerts$BsConsole.QueryAlerts.queryAlertsTask(config, project.name);
              if (maybeTask !== undefined) {
                return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                              return ApiAlerts$BsConsole.responseCb(onSuccess, onFailure, param);
                            }));
              } else {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
              }
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var alerts = state.alerts;
              var expandedRow = state.expandedRow;
              var send = param.send;
              var match = WorkflowVersion$BsConsole.current(config);
              var tmp;
              if (watchers.length !== 0) {
                var match$1 = WorkflowVersion$BsConsole.current(config);
                tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                          style: {
                            borderSpacing: "0",
                            fontSize: "14px",
                            width: "100%"
                          }
                        }, React.createElement("div", {
                              className: header,
                              style: {
                                backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.grey740),
                                display: "flex",
                                alignItems: "center"
                              }
                            }, React.createElement("div", {
                                  className: [
                                      WfWatchersListWatcher$BsConsole.Styles.col1,
                                      headerCell
                                    ].join(" ")
                                }, I18N$BsConsole.show(undefined, "Type")), React.createElement("div", {
                                  className: [
                                      WfWatchersListWatcher$BsConsole.Styles.col2,
                                      headerCell
                                    ].join(" ")
                                }, I18N$BsConsole.show(undefined, "Name")), React.createElement("div", {
                                  className: [
                                      WfWatchersListWatcher$BsConsole.Styles.col3,
                                      headerCell
                                    ].join(" ")
                                }, typeof match$1 === "number" && match$1 !== 0 ? I18N$BsConsole.show(undefined, "Trigger") : I18N$BsConsole.show(undefined, "Issue Based Alerts")), React.createElement("div", {
                                  className: [
                                      WfWatchersListWatcher$BsConsole.Styles.col4,
                                      headerCell
                                    ].join(" ")
                                }, I18N$BsConsole.show(undefined, "Status")), React.createElement("div", {
                                  className: [
                                      WfWatchersListWatcher$BsConsole.Styles.col5,
                                      headerCell
                                    ].join(" ")
                                })), React.createElement("div", {
                              style: {
                                position: "relative"
                              }
                            }, Belt_Array.mapWithIndex(watchers, (function (i, watcher) {
                                    var integration = availableIntegrations.find((function (integration) {
                                            var watcherType = watcher.type_;
                                            if (watcherType !== undefined) {
                                              return integration.description.type_ === watcherType;
                                            } else {
                                              return integration.description.path === watcher.path;
                                            }
                                          }));
                                    return ReasonReact.element(String(i), undefined, WfWatchersListWatcher$BsConsole.make(Belt_Option.flatMap(twoWaySyncStatuses, (function (__x) {
                                                          return Js_dict.get(__x, watcher.watcherName);
                                                        })), token, onEditIntegration, (function (watcher) {
                                                      return Curry._1(send, /* AskDeleteionConfirmation */Block.__(1, [watcher]));
                                                    }), Caml_obj.caml_equal(expandedRow, i), (function (param) {
                                                      return Curry._1(send, /* SetExpandedRow */Block.__(0, [i]));
                                                    }), (function (param) {
                                                      return Curry._1(send, /* SetExpandedRow */Block.__(0, [undefined]));
                                                    }), i === 0, integration === undefined ? undefined : Caml_option.some(integration), watcher, project, config, alerts, handleChangeUrl, []));
                                  })), refetchingWatchers ? ReasonReact.element(undefined, undefined, LoadingOverlay$BsConsole.make([])) : null)), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              Belt_Option.isSome(state.showDeleteConfirmDialog),
                              (function (param) {
                                  return Curry._1(send, /* CancelDeletion */0);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure?")])),
                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure you want to delete the integration?")]))])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        return Curry._1(send, /* CancelDeletion */0);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "No")]
                                                  ])),
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        return Curry._1(send, /* ConfirmDeletion */1);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Yes")]
                                                  ]))
                                        ]))
                              ]
                            ])));
              } else {
                tmp = React.createElement("div", {
                      style: {
                        display: "flex",
                        padding: "18px",
                        alignItems: "center"
                      }
                    }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "No workflows integrations have been set up yet. You can create one by clicking on the plus sign above.")])));
              }
              return ReasonReact.element(undefined, undefined, WfLayout$BsConsole.make(onCreateIntegration, "45deg", React.createElement("div", {
                                  style: {
                                    padding: "16px"
                                  }
                                }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations")]))), React.createElement("div", {
                                      style: {
                                        paddingTop: "8px"
                                      }
                                    }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Workflow integrations allow Backtrace to integrate into third-party services such as real-time chat (such as HipChat and Slack), issue trackers (such as JIRA and GitHub) and monitoring (such as Circonus and Datadog).")])))), [React.createElement("div", {
                                    style: {
                                      width: "100%"
                                    }
                                  }, typeof match === "number" ? null : ReasonReact.element(undefined, undefined, AutomatedActionsMovedBanner$BsConsole.Jsx2.make(project.name, handleChangeUrl, [])), tmp)]));
            }),
          initialState: (function (param) {
              return {
                      expandedRow: undefined,
                      showDeleteConfirmDialog: undefined,
                      alerts: []
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* CancelDeletion */0) {
                  return /* Update */Block.__(0, [{
                              expandedRow: state.expandedRow,
                              showDeleteConfirmDialog: undefined,
                              alerts: state.alerts
                            }]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (param) {
                                var send = param.send;
                                var watcher = param.state.showDeleteConfirmDialog;
                                if (watcher === undefined) {
                                  return ;
                                }
                                var match = WorkflowVersion$BsConsole.current(config);
                                if (typeof match === "number") {
                                  if (match === 0) {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Workflow service unavailable"));
                                  }
                                  var arg = BpgTask$BsConsole.WatcherNew.$$delete(WfWatcher$BsConsole.toBpg(watcher));
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (param) {
                                                if (param.tag) {
                                                  return ;
                                                }
                                                Curry._1(onIntegrationDeleted, undefined);
                                                return Curry._1(send, /* CancelDeletion */0);
                                              }));
                                }
                                var match$1 = watcher.integrationId;
                                var match$2 = watcher.type_;
                                if (match$1 === undefined) {
                                  if (match$2 !== undefined) {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error deleting integration. Integration Id missing. Please try again."));
                                  } else {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error deleting integration. Integration and Plugin Ids are missing. Please try again."));
                                  }
                                }
                                if (match$2 === undefined) {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error deleting integration. Plugin Id missing. Please try again."));
                                }
                                var maybeTask = WatcherNewTask$BsConsole.$$delete(project.name, match$2, match$1, config);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiWf$BsConsole.responseCb((function (param) {
                                                              switch (match$2) {
                                                                case "jira" :
                                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Deleted */Block.__(14, ["jiraCloud"])]));
                                                                    break;
                                                                case "jira-server" :
                                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Deleted */Block.__(14, ["jiraServer"])]));
                                                                    break;
                                                                default:
                                                                  
                                                              }
                                                              Curry._1(onIntegrationDeleted, undefined);
                                                              return Curry._1(send, /* CancelDeletion */0);
                                                            }), (function (param) {
                                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error deleting integration. Please try again."));
                                                            }), param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* SetExpandedRow */0 :
                    return /* Update */Block.__(0, [{
                                expandedRow: action[0],
                                showDeleteConfirmDialog: state.showDeleteConfirmDialog,
                                alerts: state.alerts
                              }]);
                case /* AskDeleteionConfirmation */1 :
                    return /* Update */Block.__(0, [{
                                expandedRow: state.expandedRow,
                                showDeleteConfirmDialog: action[0],
                                alerts: state.alerts
                              }]);
                case /* SetAlerts */2 :
                    return /* Update */Block.__(0, [{
                                expandedRow: state.expandedRow,
                                showDeleteConfirmDialog: state.showDeleteConfirmDialog,
                                alerts: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* header Not a pure module */
