// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var Numeral$BsConsole = require("./Numeral.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var AddSelect$BsConsole = require("./AddSelect.js");
var Clipboard$BsConsole = require("./bindings/Clipboard.js");
var SortOrder$BsConsole = require("./bs-crdb-query/src/SortOrder.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var BtTableSort$BsConsole = require("./BtTableSort.js");
var Sync = require("@material-ui/icons/Sync");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var DeleteObjects$BsConsole = require("./DeleteObjects.js");
var FormatPopover$BsConsole = require("./format-renderers/FormatPopover.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var FileAttachment$BsConsole = require("./FileAttachment.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var Delete = require("@material-ui/icons/Delete");
var AddCircle = require("@material-ui/icons/AddCircle");
var BugReport = require("@material-ui/icons/BugReport");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var Reprocessing_Tasks$BsConsole = require("./reprocessing/Reprocessing_Tasks.js");
var Assignment = require("@material-ui/icons/Assignment");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var DeleteForever = require("@material-ui/icons/DeleteForever");

function getSelect(select) {
  var addIfMissing = function (list, item) {
    if (Belt_List.has(list, item, Caml_obj.caml_equal)) {
      return list;
    } else {
      return Belt_List.add(list, item);
    }
  };
  return addIfMissing(addIfMissing(addIfMissing(select, "_tx"), "_deleted"), "_compressed");
}

function makeQuery(aperture, select, sort) {
  var select$1 = getSelect(select);
  return /* Select */Block.__(1, [
            aperture,
            select$1,
            Caml_option.some(sort),
            undefined
          ]);
}

function getTableSortingState(sort) {
  if (sort.tag !== /* Select */5) {
    return ;
  }
  var direction = sort[1] ? /* Desc */759138929 : /* Asc */3258129;
  return {
          columnName: sort[0],
          direction: direction
        };
}

function onSortChange(cb, sortingState) {
  var match = sortingState.direction;
  var direction = match >= 759138929 ? /* Descending */1 : /* Ascending */0;
  return Curry._1(cb, /* Select */Block.__(5, [
                sortingState.columnName,
                direction
              ]));
}

function getTableColumns(columns) {
  return Belt_List.toArray(Belt_List.map(columns, (function (col) {
                    var match = col.name;
                    if (match === "_tx") {
                      return BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "object ID"), col.name, (function (row) {
                                    var match = Belt_List.getBy(row, (function (param) {
                                            return param[0] === col.name;
                                          }));
                                    if (match !== undefined) {
                                      return /* `CrdbSelect */[
                                              615627497,
                                              /* tuple */[
                                                match[0],
                                                match[1]
                                              ]
                                            ];
                                    } else {
                                      return /* None */870530776;
                                    }
                                  }), true, true, undefined, 170, 170, undefined, Css.style(/* :: */[
                                      Css.important(Css.textAlign(/* left */-944764921)),
                                      /* [] */0
                                    ]), undefined, undefined, undefined);
                    } else {
                      return BtTableColumn$BsConsole.make(col.name, col.name, (function (row) {
                                    var match = Belt_List.getBy(row, (function (param) {
                                            return param[0] === col.name;
                                          }));
                                    if (match !== undefined) {
                                      return /* `CrdbSelect */[
                                              615627497,
                                              /* tuple */[
                                                match[0],
                                                match[1]
                                              ]
                                            ];
                                    } else {
                                      return /* None */870530776;
                                    }
                                  }), undefined, undefined, undefined, 100, undefined, 200, Css.style(/* :: */[
                                      Css.selector(":hover .delete-column", /* :: */[
                                            Css.display(/* block */888960333),
                                            /* :: */[
                                              Css.marginLeft(Css.px(4)),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* :: */[
                                        Css.selector(":hover .column-title", /* :: */[
                                              Css.unsafe("width", "calc(100% - 28px)"),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]), undefined, undefined, undefined);
                    }
                  })));
}

function getRowId(row) {
  var match = Belt_List.getBy(row, (function (param) {
          return param[0] === "_tx";
        }));
  if (match === undefined) {
    return "_";
  }
  var match$1 = match[1];
  if (match$1 === undefined) {
    return "_";
  }
  switch (match$1.tag | 0) {
    case /* Raw */0 :
        var match$2 = match$1[0];
        if (typeof match$2 === "number" || match$2[0] !== 365180284) {
          return "_";
        } else {
          return ObjectID$BsConsole.toHexString(Caml_int64.of_float(match$2[1]));
        }
    case /* ObjectID */17 :
        return ObjectID$BsConsole.toHexString(match$1[0]);
    default:
      return "_";
  }
}

function ExploreListData$InstanceActions(Props) {
  var row = Props.row;
  var id = Props.id;
  var getObjectRoute = Props.getObjectRoute;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var token = Props.token;
  var allowReprocessing = Props.allowReprocessing;
  var universe = Props.universe;
  var onRefresh = Props.onRefresh;
  var match = React.useState((function () {
          var match = Belt_List.getBy(row, (function (param) {
                  return param[0] === "_deleted";
                }));
          if (match === undefined) {
            return true;
          }
          var value = match[1];
          if (value === undefined) {
            return true;
          }
          switch (value.tag | 0) {
            case /* Raw */0 :
                var match$1 = value[0];
                if (typeof match$1 === "number" || match$1[0] !== 737456202) {
                  return true;
                } else {
                  return match$1[1];
                }
            case /* Bitmap */11 :
                return value[0];
            default:
              return true;
          }
        }));
  var setDeleted = match[1];
  var deleted = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setRemoved = match$1[1];
  var removed = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setModal = match$2[1];
  var modal = match$2[0];
  var match$3 = Belt_List.getBy(row, (function (param) {
          return param[0] === "_compressed";
        }));
  var compressed;
  if (match$3 !== undefined) {
    var value = match$3[1];
    if (value !== undefined) {
      switch (value.tag | 0) {
        case /* Raw */0 :
            var match$4 = value[0];
            compressed = typeof match$4 === "number" || match$4[0] !== 737456202 ? false : match$4[1];
            break;
        case /* Bitmap */11 :
            compressed = value[0];
            break;
        default:
          compressed = false;
      }
    } else {
      compressed = false;
    }
  } else {
    compressed = false;
  }
  var route = Curry._1(getObjectRoute, id);
  var tmp;
  if (allowReprocessing) {
    var title = deleted || removed ? "" : (
        compressed ? "This object is compressed per retention policy and cannot be reprocessed" : "Reprocess crash"
      );
    tmp = React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
          ariaLabel: deleted ? "This object was deleted and cannot be reprocessed" : (
              removed ? "This object was removed and cannot be reprocessed" : (
                  compressed ? "This object is compressed per retention policy and cannot be reprocessed" : "Reprocess crash"
                )
            ),
          disabled: deleted || removed || compressed,
          classes: {
            root: Css.style(/* :: */[
                  Css.color(Css.hex(Colors$BsConsole.accent)),
                  /* :: */[
                    Css.fontSize(Css.rem(1)),
                    /* :: */[
                      Css.important(Css.padding(Css.px(4))),
                      /* [] */0
                    ]
                  ]
                ]),
            label: Css.style(/* :: */[
                  Css.unsafe("fontSize", "inherit"),
                  /* [] */0
                ])
          },
          onClick: (function (param) {
              var arg = Reprocessing_Tasks$BsConsole.reloadObjects(token, projectName, universe, [ObjectID$BsConsole.toHexString(id)]);
              return Curry._2((function (param) {
                              return (function (param$1, param$2) {
                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                });
                            })(token), undefined, (function (resp) {
                            if (resp.tag) {
                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to reprocess object"));
                            } else {
                              return SnackPurveyor$BsConsole.enqueue({
                                          label: I18N$BsConsole.get(undefined, "Reload page"),
                                          onClick: onRefresh
                                        }, undefined, 4000, undefined, I18N$BsConsole.show(undefined, "Reprocessing job scheduled"));
                            }
                          }));
            }),
          title_tooltip: I18N$BsConsole.get(undefined, title),
          children: React.createElement(Sync.default, {
                color: "inherit",
                fontSize: "inherit"
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement(BtTableCell$BsConsole.make, {
              children: null,
              key: "objectID__" + Int64.to_string(id)
            }, React.createElement("span", {
                  className: Css.merge(/* :: */[
                        removed ? Css.style(/* :: */[
                                Css.textDecoration(/* lineThrough */412155569),
                                /* [] */0
                              ]) : "",
                        /* :: */[
                          "object-id",
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(ObjectID$BsConsole.toHexString(id))), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.display(/* none */-922086728),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* :: */[
                                  Css.justifyContent(/* center */98248149),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          "object-action-items",
                          /* [] */0
                        ]
                      ])
                }, deleted || removed ? React.createElement(Mui$BsConsole.IconButton.make, {
                        ariaLabel: "View in debugger",
                        disabled: deleted || removed,
                        classes: {
                          root: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.accent)),
                                /* :: */[
                                  Css.fontSize(Css.rem(1)),
                                  /* :: */[
                                    Css.important(Css.padding(Css.px(4))),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          label: Css.style(/* :: */[
                                Css.unsafe("fontSize", "inherit"),
                                /* [] */0
                              ])
                        },
                        children: React.createElement(BugReport.default, {
                              color: "inherit",
                              fontSize: "inherit"
                            })
                      }) : React.createElement(Link$BsConsole.Jsx3.make, {
                        route: route,
                        handleChangeUrl: handleChangeUrl,
                        children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                              ariaLabel: "View in debugger",
                              disabled: deleted || removed,
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.accent)),
                                      /* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* :: */[
                                          Css.important(Css.padding(Css.px(4))),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                label: Css.style(/* :: */[
                                      Css.unsafe("fontSize", "inherit"),
                                      /* [] */0
                                    ])
                              },
                              title_tooltip: I18N$BsConsole.get(undefined, "View in debugger"),
                              children: React.createElement(BugReport.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            })
                      }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                      ariaLabel: "Copy ID in clipboard",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.accent)),
                              /* :: */[
                                Css.fontSize(Css.rem(1)),
                                /* :: */[
                                  Css.important(Css.padding(Css.px(4))),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        label: Css.style(/* :: */[
                              Css.unsafe("fontSize", "inherit"),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Clipboard$BsConsole.copy(ObjectID$BsConsole.toHexString(id), (function (param) {
                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Object ID copied to clipboard."));
                                      }), undefined);
                        }),
                      title_tooltip: I18N$BsConsole.get(undefined, "Copy ID in clipboard"),
                      children: React.createElement(Assignment.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })
                    }), React.createElement(FileAttachment$BsConsole.IconButton.make, {
                      id: ObjectID$BsConsole.toHexString(id),
                      projectName: projectName,
                      token: token,
                      disabled: deleted || removed,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.accent)),
                            /* :: */[
                              Css.fontSize(Css.rem(1)),
                              /* :: */[
                                Css.important(Css.padding(Css.px(4))),
                                /* [] */0
                              ]
                            ]
                          ])
                    }), tmp, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                      ariaLabel: "Delete original object (removes debug view data)",
                      disabled: deleted || removed,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.accent)),
                              /* :: */[
                                Css.fontSize(Css.rem(1)),
                                /* :: */[
                                  Css.important(Css.padding(Css.px(4))),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        label: Css.style(/* :: */[
                              Css.unsafe("fontSize", "inherit"),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setModal, (function (param) {
                                        return /* Delete */Block.__(1, [ObjectID$BsConsole.toHexString(id)]);
                                      }));
                        }),
                      title_tooltip: I18N$BsConsole.get(undefined, "Delete original object (removes debug view data)"),
                      children: React.createElement(Delete.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })
                    }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                      ariaLabel: "Delete original object and all associated data",
                      disabled: removed,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.accent)),
                              /* :: */[
                                Css.fontSize(Css.rem(1)),
                                /* :: */[
                                  Css.important(Css.padding(Css.px(4))),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        label: Css.style(/* :: */[
                              Css.unsafe("fontSize", "inherit"),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setModal, (function (param) {
                                        return /* DeleteForever */Block.__(0, [ObjectID$BsConsole.toHexString(id)]);
                                      }));
                        }),
                      title_tooltip: I18N$BsConsole.get(undefined, "Delete original object and all associated data"),
                      children: React.createElement(DeleteForever.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })
                    }), modal !== undefined ? React.createElement(DeleteObjects$BsConsole.make, {
                        variant: modal,
                        token: token,
                        onClose: (function (param) {
                            return Curry._1(setModal, (function (param) {
                                          
                                        }));
                          }),
                        onSuccess: (function (param) {
                            if (modal !== undefined) {
                              if (modal.tag) {
                                Curry._1(setDeleted, (function (param) {
                                        return true;
                                      }));
                              } else {
                                Curry._1(setRemoved, (function (param) {
                                        return true;
                                      }));
                              }
                            }
                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success deleting object(s)."));
                            return Curry._1(setModal, (function (param) {
                                          
                                        }));
                          }),
                        projectName: projectName
                      }) : null));
}

var InstanceActions = {
  make: ExploreListData$InstanceActions
};

function renderInstanceDataCell(projectName, universe, token, getObjectRoute, handleChangeUrl, handleAddFilters, onRefresh, allowReprocessing, row, param, value, key) {
  var exit = 0;
  if (typeof value === "number" || value[0] !== 615627497) {
    exit = 1;
  } else {
    var match = value[1];
    if (match[0] === "_tx") {
      var match$1 = match[1];
      if (match$1 !== undefined) {
        switch (match$1.tag | 0) {
          case /* Raw */0 :
              var match$2 = match$1[0];
              if (typeof match$2 === "number") {
                exit = 1;
              } else {
                if (match$2[0] === 365180284) {
                  return React.createElement(ExploreListData$InstanceActions, {
                              row: row,
                              id: Caml_int64.of_float(match$2[1]),
                              getObjectRoute: getObjectRoute,
                              handleChangeUrl: handleChangeUrl,
                              projectName: projectName,
                              token: token,
                              allowReprocessing: allowReprocessing,
                              universe: universe,
                              onRefresh: onRefresh
                            });
                }
                exit = 1;
              }
              break;
          case /* ObjectID */17 :
              return React.createElement(ExploreListData$InstanceActions, {
                          row: row,
                          id: match$1[0],
                          getObjectRoute: getObjectRoute,
                          handleChangeUrl: handleChangeUrl,
                          projectName: projectName,
                          token: token,
                          allowReprocessing: allowReprocessing,
                          universe: universe,
                          onRefresh: onRefresh
                        });
          default:
            exit = 1;
        }
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    var tmp;
    if (typeof value === "number") {
      tmp = null;
    } else {
      var variant = value[0];
      if (variant >= 365180284) {
        if (variant !== 615627497) {
          tmp = variant !== 737456202 ? (
              variant >= 365180285 ? null : I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.0", Pervasives.string_of_float(value[1])))
            ) : I18N$BsConsole.showSkip(Pervasives.string_of_bool(value[1]));
        } else {
          var match$3 = value[1];
          var value$1 = match$3[1];
          var attribute = match$3[0];
          var exit$1 = 0;
          if (value$1 !== undefined) {
            var exit$2 = 0;
            switch (value$1.tag | 0) {
              case /* Raw */0 :
              case /* None_ */1 :
                  exit$2 = 3;
                  break;
              default:
                exit$1 = 2;
            }
            if (exit$2 === 3) {
              var match$4 = value$1[0];
              if (typeof match$4 === "number" || match$4[0] !== -976970511 || attribute === "fingerprint") {
                exit$1 = 2;
              } else {
                tmp = React.createElement(FormatPopover$BsConsole.WithCopyButton.make, {
                      value: match$4[1],
                      children: React.createElement(FormatRenderer$BsConsole.make, {
                            value: value$1,
                            attribute: attribute,
                            handleChangeUrl: handleChangeUrl,
                            handleAddFilters: handleAddFilters
                          })
                    });
              }
            }
            
          } else {
            exit$1 = 2;
          }
          if (exit$1 === 2) {
            tmp = React.createElement(FormatRenderer$BsConsole.make, {
                  value: value$1,
                  attribute: attribute,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilters: handleAddFilters
                });
          }
          
        }
      } else {
        tmp = variant !== -976970511 ? (
            variant !== 3654863 ? null : I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.0", String(value[1])))
          ) : I18N$BsConsole.showSkip(value[1]);
      }
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp,
                key: key
              });
  }
  
}

function renderStubHeader(onChange, select, describeRemote, param) {
  return React.createElement(AddSelect$BsConsole.Jsx3.make, {
              select: select,
              onChange: onChange,
              describeRemote: describeRemote,
              children: React.createElement(Button.default, {
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginLeft(Css.px(16)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (_event) {
                        
                      }),
                    children: React.createElement(Row2$BsConsole.make, {
                          children: null
                        }, React.createElement(AddCircle.default, { }), React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* :: */[
                                      Css.whiteSpace(/* nowrap */867913355),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "Add attribute")))
                  })
            });
}

function renderHeaderCell(align, column, sortingState, onSortChange, onColumnDelete) {
  var match = column.disableSort;
  if (onSortChange !== undefined && !match) {
    var sortingState$1 = sortingState !== undefined && sortingState.columnName === column.name ? sortingState : undefined;
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* :: */[
                          Css.height(Css.px(24)),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              }, React.createElement("div", {
                    className: Css.merge(/* :: */[
                          Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]),
                          /* :: */[
                            "column-title",
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(BtTableSort$BsConsole.Label.make, {
                        sortingState: sortingState$1,
                        columnName: column.name,
                        onSortChange: onSortChange,
                        align: align,
                        children: I18N$BsConsole.showSkip(column.title)
                      })), column.name === "timestamp" ? null : React.createElement("div", {
                      className: Css.merge(/* :: */[
                            Css.style(/* :: */[
                                  Css.display(/* none */-922086728),
                                  /* [] */0
                                ]),
                            /* :: */[
                              "delete-column",
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                          ariaLabel: "Delete column",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* :: */[
                                    Css.fontSize(Css.rem(1)),
                                    /* :: */[
                                      Css.important(Css.padding(Css.px(4))),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            label: Css.style(/* :: */[
                                  Css.unsafe("fontSize", "inherit"),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onColumnDelete, column.name);
                            }),
                          title_tooltip: I18N$BsConsole.get(undefined, "Delete column"),
                          children: React.createElement(Delete.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })
                        })));
  }
  return React.createElement("span", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.whiteSpace(/* nowrap */867913355),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* :: */[
                              Css.textOverflow(/* ellipsis */166888785),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      "column-title",
                      /* [] */0
                    ]
                  ])
            }, I18N$BsConsole.showSkip(column.title));
}

function getTableContainerClassName(rows) {
  var match = rows.length >= 4;
  var containerHeight = match ? (
      IFrame$BsConsole.isIFrame ? Css.style(/* :: */[
              Css.unsafe("maxHeight", "unset !important"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ]) : Css.style(/* :: */[
              Css.unsafe("maxHeight", "calc(100vh - 396px)"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ])
    ) : "";
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.position(Css.relative),
                    /* :: */[
                      Css.selector("tr.BtTableRow:hover > td > .object-id", /* :: */[
                            Css.display(/* none */-922086728),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("tr.BtTableRow:hover > td > .object-action-items", /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("tr.BtTableRow:hover .frame-count", /* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.white)),
                                  /* [] */0
                                ]
                              ]),
                          /* :: */[
                            Css.selector("tr.BtTableRow > td", /* :: */[
                                  Css.fontFamily("Inconsolata"),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                "explore-table-paper",
                /* :: */[
                  containerHeight,
                  /* [] */0
                ]
              ]
            ]);
}

function useTableData(token, projectName, query, select, sort) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setTableRemote = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setSortingState = match$1[1];
  var match$2 = QueryPaginationTask$BsConsole.CrdbSelect.use(token, projectName, query, "instance");
  var setPage = match$2[2];
  var page = match$2[1];
  var paginationRemote = match$2[0];
  React.useEffect((function () {
          if (typeof paginationRemote === "number") {
            if (paginationRemote === /* NotAsked */0) {
              Curry._1(setTableRemote, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setTableRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (paginationRemote.tag) {
            var msg = paginationRemote[0];
            Curry._1(setTableRemote, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else {
            var match = paginationRemote[0];
            var next = match[1];
            var match$1 = match[0];
            var meta = match$1[2];
            var rows = match$1[1];
            var columns = getTableColumns(match$1[0]);
            var columns$1 = Belt_Array.keep(columns, (function (col) {
                    if (col.name !== "_deleted" && col.name !== "_compressed") {
                      return true;
                    } else {
                      return Belt_List.has(select, col.name, (function (prim, prim$1) {
                                    return prim === prim$1;
                                  }));
                    }
                  }));
            var columnOrder = BtTableSessionData$BsConsole.ColumnOrder.get(projectName, /* ExploreList */2);
            var columnWidths = BtTableSessionData$BsConsole.ColumnWidth.get(projectName, /* ExploreList */2);
            var containerClassName = getTableContainerClassName(Belt_List.toArray(rows));
            Curry._1(setTableRemote, (function (param) {
                    return /* Success */Block.__(0, [{
                                rows: Belt_List.toArray(rows),
                                columns: columns$1,
                                columnOrder: columnOrder,
                                page: page,
                                setPage: (function (page) {
                                    return Curry._1(setPage, (function (param) {
                                                  return page;
                                                }));
                                  }),
                                meta: meta,
                                loading: typeof next === "number" ? next !== 0 : false,
                                columnWidths: columnWidths,
                                containerClassName: containerClassName
                              }]);
                  }));
          }
          
        }), /* tuple */[
        select,
        paginationRemote
      ]);
  React.useEffect((function () {
          var sortingState = getTableSortingState(sort);
          Curry._1(setSortingState, (function (param) {
                  return sortingState;
                }));
          
        }), /* tuple */[
        Curry._1(Crdb$BsConsole.Sort.getAttribute, sort),
        Curry._1(Crdb$BsConsole.Sort.getAggString, sort),
        SortOrder$BsConsole.Ordering.toString(Curry._1(Crdb$BsConsole.Sort.getOrdering, sort))
      ]);
  return /* tuple */[
          match[0],
          match$1[0]
        ];
}

exports.getSelect = getSelect;
exports.makeQuery = makeQuery;
exports.getTableSortingState = getTableSortingState;
exports.onSortChange = onSortChange;
exports.getTableColumns = getTableColumns;
exports.getRowId = getRowId;
exports.InstanceActions = InstanceActions;
exports.renderInstanceDataCell = renderInstanceDataCell;
exports.renderStubHeader = renderStubHeader;
exports.renderHeaderCell = renderHeaderCell;
exports.getTableContainerClassName = getTableContainerClassName;
exports.useTableData = useTableData;
/* Css Not a pure module */
