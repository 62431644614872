// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var DrilldownMenu$BsConsole = require("./DrilldownMenu.js");

var drilldownDispatchContext = React.createContext(undefined);

function makeProps(dispatch, children, param) {
  return {
          value: dispatch,
          children: children
        };
}

var make = drilldownDispatchContext.Provider;

var DrilldownDispatchProvider = {
  makeProps: makeProps,
  make: make
};

var initialState_filterOptions = [];

var initialState_outsideLinkOptions = [];

function initialState_onClose(param) {
  
}

var initialState = {
  anchorEl: undefined,
  textValue: undefined,
  filterOptions: initialState_filterOptions,
  outsideLinkOptions: initialState_outsideLinkOptions,
  onClose: initialState_onClose
};

function reducer(_state, action) {
  if (!action) {
    return initialState;
  }
  var match = action[0];
  return {
          anchorEl: match[0],
          textValue: match[1],
          filterOptions: match[2],
          outsideLinkOptions: match[3],
          onClose: match[4]
        };
}

function DrilldownContext$Provider(Props) {
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var onClose = match$1.onClose;
  return React.createElement(React.Fragment, undefined, React.createElement(make, makeProps(dispatch, children, undefined)), React.createElement(DrilldownMenu$BsConsole.make, {
                  textValue: match$1.textValue,
                  anchorEl: match$1.anchorEl,
                  onClose: (function (param) {
                      Curry._1(dispatch, /* ClearState */0);
                      return Curry._1(onClose, undefined);
                    }),
                  filterOptions: match$1.filterOptions,
                  outsideLinkOptions: match$1.outsideLinkOptions,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilters: handleAddFilters
                }));
}

var Provider = {
  make: DrilldownContext$Provider
};

function make$1(handleChangeUrl, handleAddFilters, children) {
  return ReasonReactCompat.wrapReactForReasonReact(DrilldownContext$Provider, {
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              children: children
            }, children);
}

var ProviderRe = {
  make: make$1
};

function useDrilldownDispatch(param) {
  return React.useContext(drilldownDispatchContext);
}

exports.drilldownDispatchContext = drilldownDispatchContext;
exports.DrilldownDispatchProvider = DrilldownDispatchProvider;
exports.initialState = initialState;
exports.reducer = reducer;
exports.Provider = Provider;
exports.ProviderRe = ProviderRe;
exports.useDrilldownDispatch = useDrilldownDispatch;
/* drilldownDispatchContext Not a pure module */
