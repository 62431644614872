// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");

var title = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.rem(0.8125)),
        /* :: */[
          Css.fontWeight(Css.medium),
          /* :: */[
            Css.lineHeight(Css.em(1.375)),
            /* [] */0
          ]
        ]
      ]
    ]);

var subtitle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.rem(0.8125)),
        /* :: */[
          Css.fontWeight(Css.normal),
          /* :: */[
            Css.lineHeight(Css.em(1.375)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey3)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bodyWithAccentColor = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.rem(0.8125)),
        /* :: */[
          Css.fontWeight(Css.normal),
          /* :: */[
            Css.lineHeight(Css.em(1.375)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey2)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var data = Css.style(/* :: */[
      Css.fontWeight(Css.normal),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey1)),
        /* :: */[
          Css.fontSize(Css.rem(0.90)),
          /* [] */0
        ]
      ]
    ]);

var tooltipTitle = Css.style(/* :: */[
      Css.paddingTop(Css.px(1)),
      /* :: */[
        Css.paddingBottom(Css.px(1)),
        /* :: */[
          Css.textShadow(undefined, undefined, undefined, Css.hex(Colors$BsConsole.blackA45)),
          /* :: */[
            Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
            /* :: */[
              Css.fontWeight(Css.normal),
              /* :: */[
                Css.fontSize(Css.rem(0.75)),
                /* :: */[
                  Css.lineHeight(Css.em(1.35)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipTitleSize = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.75)),
      /* :: */[
        Css.lineHeight(Css.em(1.35)),
        /* [] */0
      ]
    ]);

var noWrap = Css.style(/* :: */[
      Css.whiteSpace(Css.nowrap),
      /* [] */0
    ]);

var $$Text = {
  title: title,
  subtitle: subtitle,
  bodyWithAccentColor: bodyWithAccentColor,
  data: data,
  tooltipTitle: tooltipTitle,
  tooltipTitleSize: tooltipTitleSize,
  noWrap: noWrap
};

Css.$$global("body", /* :: */[
      Css.backgroundColor(/* transparent */582626130),
      /* [] */0
    ]);

exports.$$Text = $$Text;
/* title Not a pure module */
