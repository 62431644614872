// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");

function fromString(param) {
  switch (param) {
    case "1 day" :
        return /* Days */Block.__(2, [1]);
    case "1 month" :
        return /* Days */Block.__(2, [30]);
    case "1 week" :
        return /* Days */Block.__(2, [7]);
    case "2 hours" :
        return /* Hours */Block.__(1, [2]);
    case "30 minutes" :
        return /* Minutes */Block.__(0, [30]);
    default:
      return /* Unknown */0;
  }
}

function toString(v) {
  if (typeof v === "number") {
    return "unknown time";
  }
  switch (v.tag | 0) {
    case /* Minutes */0 :
        return String(v[0]) + " minutes";
    case /* Hours */1 :
        return String(v[0]) + " hours";
    case /* Days */2 :
        var v$1 = v[0];
        if (v$1 !== 1) {
          if (v$1 !== 7) {
            if (v$1 !== 30) {
              return String(v$1) + " days";
            } else {
              return "1 month";
            }
          } else {
            return "1 week";
          }
        } else {
          return "1 day";
        }
    
  }
}

function currentTimePlusMs(ms) {
  var now = new Date();
  var nowMs = now.getTime();
  return nowMs + Caml_int64.to_float(ms);
}

function toDate(v) {
  if (typeof v === "number") {
    return new Date().getTime();
  }
  switch (v.tag | 0) {
    case /* Minutes */0 :
        return currentTimePlusMs(Caml_int64.of_int32(Caml_int32.imul(Caml_int32.imul(v[0], 60), 1000)));
    case /* Hours */1 :
        return currentTimePlusMs(Caml_int64.of_int32(Caml_int32.imul(Caml_int32.imul(Caml_int32.imul(v[0], 60), 60), 1000)));
    case /* Days */2 :
        var v$1 = v[0];
        if (v$1 !== 7) {
          if (v$1 !== 30) {
            return currentTimePlusMs(Caml_int64.mul(Caml_int64.of_int32(Caml_int32.imul(v$1, 24)), Caml_int64.mk(3600000, 0)));
          } else {
            return currentTimePlusMs(Caml_int64.mul(Caml_int64.mk(720, 0), Caml_int64.mk(3600000, 0)));
          }
        } else {
          return currentTimePlusMs(Caml_int64.mk(604800000, 0));
        }
    
  }
}

function dateToFriendlyString(v) {
  if (typeof v === "number") {
    var date = new Date(new Date().getTime());
    return date.toLocaleDateString() + (" " + date.toLocaleTimeString());
  }
  switch (v.tag | 0) {
    case /* Minutes */0 :
        var date$1 = new Date(toDate(/* Minutes */Block.__(0, [v[0]])));
        return date$1.toLocaleDateString() + (" " + date$1.toLocaleTimeString());
    case /* Hours */1 :
        var date$2 = new Date(toDate(/* Hours */Block.__(1, [v[0]])));
        return date$2.toLocaleDateString() + (" " + date$2.toLocaleTimeString());
    case /* Days */2 :
        return new Date(toDate(/* Days */Block.__(2, [v[0]]))).toDateString();
    
  }
}

function dateToInvariantSubmissionFormat(ms) {
  return String(Math.floor(ms / 1000.0) | 0);
}

var TimePresets_default = /* Days */Block.__(2, [7]);

var TimePresets = {
  fromString: fromString,
  toString: toString,
  currentTimePlusMs: currentTimePlusMs,
  toDate: toDate,
  dateToFriendlyString: dateToFriendlyString,
  dateToInvariantSubmissionFormat: dateToInvariantSubmissionFormat,
  $$default: TimePresets_default
};

var timeOptions = [
  /* Minutes */Block.__(0, [30]),
  /* Hours */Block.__(1, [2]),
  /* Days */Block.__(2, [1]),
  /* Days */Block.__(2, [7]),
  /* Days */Block.__(2, [30])
];

function ReopenTime(Props) {
  var timePreset = Props.timePreset;
  var setTimePreset = Props.setTimePreset;
  return React.createElement(React.Fragment, undefined, React.createElement(Select.default, {
                  value: /* `String */[
                    -976970511,
                    toString(timePreset)
                  ],
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      var nextTimePreset = fromString(value[1]);
                      return Curry._1(setTimePreset, (function (param) {
                                    return nextTimePreset;
                                  }));
                    }),
                  renderValue: (function (value) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.showSkip(value[1])
                                });
                    }),
                  disableUnderline: true,
                  children: Belt_Array.map(timeOptions, (function (time) {
                          var presetString = toString(time);
                          return React.createElement(MenuItem.default, {
                                      value: /* `String */[
                                        -976970511,
                                        presetString
                                      ],
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(presetString)
                                          })
                                    });
                        }))
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(10)),
                        /* [] */0
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey1)),
                            /* :: */[
                              Css.margin(Css.px(0)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.showSkip(dateToFriendlyString(timePreset))
                    })));
}

var make = ReopenTime;

exports.TimePresets = TimePresets;
exports.timeOptions = timeOptions;
exports.make = make;
/* Css Not a pure module */
