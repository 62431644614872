// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var I18N$BsConsole = require("../I18N.js");

var frequency = [
  {
    label: I18N$BsConsole.get(undefined, "Weekly"),
    value: "week"
  },
  {
    label: I18N$BsConsole.get(undefined, "Daily"),
    value: "day"
  }
];

var hours = [
  {
    label: "01",
    value: "1"
  },
  {
    label: "02",
    value: "2"
  },
  {
    label: "03",
    value: "3"
  },
  {
    label: "04",
    value: "4"
  },
  {
    label: "05",
    value: "5"
  },
  {
    label: "06",
    value: "6"
  },
  {
    label: "07",
    value: "7"
  },
  {
    label: "08",
    value: "8"
  },
  {
    label: "09",
    value: "9"
  },
  {
    label: "10",
    value: "10"
  },
  {
    label: "11",
    value: "11"
  },
  {
    label: "12",
    value: "12"
  }
];

var amOrPm = [
  {
    label: "AM",
    value: "am"
  },
  {
    label: "PM",
    value: "pm"
  }
];

var days = [
  {
    label: I18N$BsConsole.get(undefined, "Sunday"),
    value: "0"
  },
  {
    label: I18N$BsConsole.get(undefined, "Monday"),
    value: "1"
  },
  {
    label: I18N$BsConsole.get(undefined, "Tuesday"),
    value: "2"
  },
  {
    label: I18N$BsConsole.get(undefined, "Wednesday"),
    value: "3"
  },
  {
    label: I18N$BsConsole.get(undefined, "Thursday"),
    value: "4"
  },
  {
    label: I18N$BsConsole.get(undefined, "Friday"),
    value: "5"
  },
  {
    label: I18N$BsConsole.get(undefined, "Saturday"),
    value: "6"
  }
];

var months = [
  {
    label: I18N$BsConsole.get(undefined, "Jan"),
    value: "0"
  },
  {
    label: I18N$BsConsole.get(undefined, "Feb"),
    value: "1"
  },
  {
    label: I18N$BsConsole.get(undefined, "Mar"),
    value: "2"
  },
  {
    label: I18N$BsConsole.get(undefined, "Apr"),
    value: "3"
  },
  {
    label: I18N$BsConsole.get(undefined, "May"),
    value: "4"
  },
  {
    label: I18N$BsConsole.get(undefined, "Jun"),
    value: "5"
  },
  {
    label: I18N$BsConsole.get(undefined, "Jul"),
    value: "6"
  },
  {
    label: I18N$BsConsole.get(undefined, "Aug"),
    value: "7"
  },
  {
    label: I18N$BsConsole.get(undefined, "Sep"),
    value: "8"
  },
  {
    label: I18N$BsConsole.get(undefined, "Oct"),
    value: "9"
  },
  {
    label: I18N$BsConsole.get(undefined, "Nov"),
    value: "10"
  },
  {
    label: I18N$BsConsole.get(undefined, "Dec"),
    value: "11"
  }
];

var timezones_000 = {
  offset: "-11.00",
  label: I18N$BsConsole.get("timezone", "Pacific/Niue"),
  value: "Pacific/Niue"
};

var timezones_001 = /* :: */[
  {
    offset: "-11.00",
    label: I18N$BsConsole.get("timezone", "Pacific/Pago_Pago"),
    value: "Pacific/Pago_Pago"
  },
  /* :: */[
    {
      offset: "-10.00",
      label: I18N$BsConsole.get("timezone", "Pacific/Honolulu"),
      value: "Pacific/Honolulu"
    },
    /* :: */[
      {
        offset: "-10.00",
        label: I18N$BsConsole.get("timezone", "Pacific/Rarotonga"),
        value: "Pacific/Rarotonga"
      },
      /* :: */[
        {
          offset: "-10.00",
          label: I18N$BsConsole.get("timezone", "Pacific/Tahiti"),
          value: "Pacific/Tahiti"
        },
        /* :: */[
          {
            offset: "-9.50",
            label: I18N$BsConsole.get("timezone", "Pacific/Marquesas"),
            value: "Pacific/Marquesas"
          },
          /* :: */[
            {
              offset: "-9.00",
              label: I18N$BsConsole.get("timezone", "America/Anchorage"),
              value: "America/Anchorage"
            },
            /* :: */[
              {
                offset: "-9.00",
                label: I18N$BsConsole.get("timezone", "Pacific/Gambier"),
                value: "Pacific/Gambier"
              },
              /* :: */[
                {
                  offset: "-08.00",
                  label: I18N$BsConsole.get("timezone", "America/Los_Angeles"),
                  value: "America/Los_Angeles"
                },
                /* :: */[
                  {
                    offset: "-08.00",
                    label: I18N$BsConsole.get("timezone", "America/Tijuana"),
                    value: "America/Tijuana"
                  },
                  /* :: */[
                    {
                      offset: "-08.00",
                      label: I18N$BsConsole.get("timezone", "America/Vancouver"),
                      value: "America/Vancouver"
                    },
                    /* :: */[
                      {
                        offset: "-08.00",
                        label: I18N$BsConsole.get("timezone", "America/Whitehorse"),
                        value: "America/Whitehorse"
                      },
                      /* :: */[
                        {
                          offset: "-08.00",
                          label: I18N$BsConsole.get("timezone", "Pacific/Pitcairn"),
                          value: "Pacific/Pitcairn"
                        },
                        /* :: */[
                          {
                            offset: "-7.00",
                            label: I18N$BsConsole.get("timezone", "America/Dawson_Creek"),
                            value: "America/Dawson_Creek"
                          },
                          /* :: */[
                            {
                              offset: "-7.00",
                              label: I18N$BsConsole.get("timezone", "America/Denver"),
                              value: "America/Denver"
                            },
                            /* :: */[
                              {
                                offset: "-7.00",
                                label: I18N$BsConsole.get("timezone", "America/Edmonton"),
                                value: "America/Edmonton"
                              },
                              /* :: */[
                                {
                                  offset: "-7.00",
                                  label: I18N$BsConsole.get("timezone", "America/Hermosillo"),
                                  value: "America/Hermosillo"
                                },
                                /* :: */[
                                  {
                                    offset: "-7.00",
                                    label: I18N$BsConsole.get("timezone", "America/Mazatlan"),
                                    value: "America/Mazatlan"
                                  },
                                  /* :: */[
                                    {
                                      offset: "-7.00",
                                      label: I18N$BsConsole.get("timezone", "America/Phoenix"),
                                      value: "America/Phoenix"
                                    },
                                    /* :: */[
                                      {
                                        offset: "-7.00",
                                        label: I18N$BsConsole.get("timezone", "America/Yellowknife"),
                                        value: "America/Yellowknife"
                                      },
                                      /* :: */[
                                        {
                                          offset: "-6.00",
                                          label: I18N$BsConsole.get("timezone", "America/Belize"),
                                          value: "America/Belize"
                                        },
                                        /* :: */[
                                          {
                                            offset: "-6.00",
                                            label: I18N$BsConsole.get("timezone", "America/Chicago"),
                                            value: "America/Chicago"
                                          },
                                          /* :: */[
                                            {
                                              offset: "-6.00",
                                              label: I18N$BsConsole.get("timezone", "America/Costa_Rica"),
                                              value: "America/Costa_Rica"
                                            },
                                            /* :: */[
                                              {
                                                offset: "-6.00",
                                                label: I18N$BsConsole.get("timezone", "America/El_Salvador"),
                                                value: "America/El_Salvador"
                                              },
                                              /* :: */[
                                                {
                                                  offset: "-6.00",
                                                  label: I18N$BsConsole.get("timezone", "America/Guatemala"),
                                                  value: "America/Guatemala"
                                                },
                                                /* :: */[
                                                  {
                                                    offset: "-6.00",
                                                    label: I18N$BsConsole.get("timezone", "America/Managua"),
                                                    value: "America/Managua"
                                                  },
                                                  /* :: */[
                                                    {
                                                      offset: "-6.00",
                                                      label: I18N$BsConsole.get("timezone", "America/Mexico_City"),
                                                      value: "America/Mexico_City"
                                                    },
                                                    /* :: */[
                                                      {
                                                        offset: "-6.00",
                                                        label: I18N$BsConsole.get("timezone", "America/Regina"),
                                                        value: "America/Regina"
                                                      },
                                                      /* :: */[
                                                        {
                                                          offset: "-6.00",
                                                          label: I18N$BsConsole.get("timezone", "America/Tegucigalpa"),
                                                          value: "America/Tegucigalpa"
                                                        },
                                                        /* :: */[
                                                          {
                                                            offset: "-6.00",
                                                            label: I18N$BsConsole.get("timezone", "America/Winnipeg"),
                                                            value: "America/Winnipeg"
                                                          },
                                                          /* :: */[
                                                            {
                                                              offset: "-6.00",
                                                              label: I18N$BsConsole.get("timezone", "Pacific/Galapagos"),
                                                              value: "Pacific/Galapagos"
                                                            },
                                                            /* :: */[
                                                              {
                                                                offset: "-5.00",
                                                                label: I18N$BsConsole.get("timezone", "America/Bogota"),
                                                                value: "America/Bogota"
                                                              },
                                                              /* :: */[
                                                                {
                                                                  offset: "-5.00",
                                                                  label: I18N$BsConsole.get("timezone", "America/Cancun"),
                                                                  value: "America/Cancun"
                                                                },
                                                                /* :: */[
                                                                  {
                                                                    offset: "-5.00",
                                                                    label: I18N$BsConsole.get("timezone", "America/Cayman"),
                                                                    value: "America/Cayman"
                                                                  },
                                                                  /* :: */[
                                                                    {
                                                                      offset: "-5.00",
                                                                      label: I18N$BsConsole.get("timezone", "America/Guayaquil"),
                                                                      value: "America/Guayaquil"
                                                                    },
                                                                    /* :: */[
                                                                      {
                                                                        offset: "-5.00",
                                                                        label: I18N$BsConsole.get("timezone", "America/Havana"),
                                                                        value: "America/Havana"
                                                                      },
                                                                      /* :: */[
                                                                        {
                                                                          offset: "-5.00",
                                                                          label: I18N$BsConsole.get("timezone", "America/Iqaluit"),
                                                                          value: "America/Iqaluit"
                                                                        },
                                                                        /* :: */[
                                                                          {
                                                                            offset: "-5.00",
                                                                            label: I18N$BsConsole.get("timezone", "America/Jamaica"),
                                                                            value: "America/Jamaica"
                                                                          },
                                                                          /* :: */[
                                                                            {
                                                                              offset: "-5.00",
                                                                              label: I18N$BsConsole.get("timezone", "America/Lima"),
                                                                              value: "America/Lima"
                                                                            },
                                                                            /* :: */[
                                                                              {
                                                                                offset: "-5.00",
                                                                                label: I18N$BsConsole.get("timezone", "America/Nassau"),
                                                                                value: "America/Nassau"
                                                                              },
                                                                              /* :: */[
                                                                                {
                                                                                  offset: "-5.00",
                                                                                  label: I18N$BsConsole.get("timezone", "America/New_York"),
                                                                                  value: "America/New_York"
                                                                                },
                                                                                /* :: */[
                                                                                  {
                                                                                    offset: "-5.00",
                                                                                    label: I18N$BsConsole.get("timezone", "America/Panama"),
                                                                                    value: "America/Panama"
                                                                                  },
                                                                                  /* :: */[
                                                                                    {
                                                                                      offset: "-5.00",
                                                                                      label: I18N$BsConsole.get("timezone", "America/Port-au-Prince"),
                                                                                      value: "America/Port-au-Prince"
                                                                                    },
                                                                                    /* :: */[
                                                                                      {
                                                                                        offset: "-5.00",
                                                                                        label: I18N$BsConsole.get("timezone", "America/Rio_Branco"),
                                                                                        value: "America/Rio_Branco"
                                                                                      },
                                                                                      /* :: */[
                                                                                        {
                                                                                          offset: "-5.00",
                                                                                          label: I18N$BsConsole.get("timezone", "America/Toronto"),
                                                                                          value: "America/Toronto"
                                                                                        },
                                                                                        /* :: */[
                                                                                          {
                                                                                            offset: "-5.00",
                                                                                            label: I18N$BsConsole.get("timezone", "Pacific/Easter"),
                                                                                            value: "Pacific/Easter"
                                                                                          },
                                                                                          /* :: */[
                                                                                            {
                                                                                              offset: "-4.50",
                                                                                              label: I18N$BsConsole.get("timezone", "America/Caracas"),
                                                                                              value: "America/Caracas"
                                                                                            },
                                                                                            /* :: */[
                                                                                              {
                                                                                                offset: "-3.00",
                                                                                                label: I18N$BsConsole.get("timezone", "America/Asuncion"),
                                                                                                value: "America/Asuncion"
                                                                                              },
                                                                                              /* :: */[
                                                                                                {
                                                                                                  offset: "-4.00",
                                                                                                  label: I18N$BsConsole.get("timezone", "America/Barbados"),
                                                                                                  value: "America/Barbados"
                                                                                                },
                                                                                                /* :: */[
                                                                                                  {
                                                                                                    offset: "-4.00",
                                                                                                    label: I18N$BsConsole.get("timezone", "America/Boa_Vista"),
                                                                                                    value: "America/Boa_Vista"
                                                                                                  },
                                                                                                  /* :: */[
                                                                                                    {
                                                                                                      offset: "-3.00",
                                                                                                      label: I18N$BsConsole.get("timezone", "America/Campo_Grande"),
                                                                                                      value: "America/Campo_Grande"
                                                                                                    },
                                                                                                    /* :: */[
                                                                                                      {
                                                                                                        offset: "-3.00",
                                                                                                        label: I18N$BsConsole.get("timezone", "America/Cuiaba"),
                                                                                                        value: "America/Cuiaba"
                                                                                                      },
                                                                                                      /* :: */[
                                                                                                        {
                                                                                                          offset: "-4.00",
                                                                                                          label: I18N$BsConsole.get("timezone", "America/Curacao"),
                                                                                                          value: "America/Curacao"
                                                                                                        },
                                                                                                        /* :: */[
                                                                                                          {
                                                                                                            offset: "-4.00",
                                                                                                            label: I18N$BsConsole.get("timezone", "America/Grand_Turk"),
                                                                                                            value: "America/Grand_Turk"
                                                                                                          },
                                                                                                          /* :: */[
                                                                                                            {
                                                                                                              offset: "-4.00",
                                                                                                              label: I18N$BsConsole.get("timezone", "America/Guyana"),
                                                                                                              value: "America/Guyana"
                                                                                                            },
                                                                                                            /* :: */[
                                                                                                              {
                                                                                                                offset: "-4.00",
                                                                                                                label: I18N$BsConsole.get("timezone", "America/Halifax"),
                                                                                                                value: "America/Halifax"
                                                                                                              },
                                                                                                              /* :: */[
                                                                                                                {
                                                                                                                  offset: "-4.00",
                                                                                                                  label: I18N$BsConsole.get("timezone", "America/La_Paz"),
                                                                                                                  value: "America/La_Paz"
                                                                                                                },
                                                                                                                /* :: */[
                                                                                                                  {
                                                                                                                    offset: "-4.00",
                                                                                                                    label: I18N$BsConsole.get("timezone", "America/Manaus"),
                                                                                                                    value: "America/Manaus"
                                                                                                                  },
                                                                                                                  /* :: */[
                                                                                                                    {
                                                                                                                      offset: "-4.00",
                                                                                                                      label: I18N$BsConsole.get("timezone", "America/Martinique"),
                                                                                                                      value: "America/Martinique"
                                                                                                                    },
                                                                                                                    /* :: */[
                                                                                                                      {
                                                                                                                        offset: "-4.00",
                                                                                                                        label: I18N$BsConsole.get("timezone", "America/Port_of_Spain"),
                                                                                                                        value: "America/Port_of_Spain"
                                                                                                                      },
                                                                                                                      /* :: */[
                                                                                                                        {
                                                                                                                          offset: "-4.00",
                                                                                                                          label: I18N$BsConsole.get("timezone", "America/Porto_Velho"),
                                                                                                                          value: "America/Porto_Velho"
                                                                                                                        },
                                                                                                                        /* :: */[
                                                                                                                          {
                                                                                                                            offset: "-4.00",
                                                                                                                            label: I18N$BsConsole.get("timezone", "America/Puerto_Rico"),
                                                                                                                            value: "America/Puerto_Rico"
                                                                                                                          },
                                                                                                                          /* :: */[
                                                                                                                            {
                                                                                                                              offset: "-4.00",
                                                                                                                              label: I18N$BsConsole.get("timezone", "America/Santo_Domingo"),
                                                                                                                              value: "America/Santo_Domingo"
                                                                                                                            },
                                                                                                                            /* :: */[
                                                                                                                              {
                                                                                                                                offset: "-4.00",
                                                                                                                                label: I18N$BsConsole.get("timezone", "America/Thule"),
                                                                                                                                value: "America/Thule"
                                                                                                                              },
                                                                                                                              /* :: */[
                                                                                                                                {
                                                                                                                                  offset: "-4.00",
                                                                                                                                  label: I18N$BsConsole.get("timezone", "Atlantic/Bermuda"),
                                                                                                                                  value: "Atlantic/Bermuda"
                                                                                                                                },
                                                                                                                                /* :: */[
                                                                                                                                  {
                                                                                                                                    offset: "-3.50",
                                                                                                                                    label: I18N$BsConsole.get("timezone", "America/St_Johns"),
                                                                                                                                    value: "America/St_Johns"
                                                                                                                                  },
                                                                                                                                  /* :: */[
                                                                                                                                    {
                                                                                                                                      offset: "-3.00",
                                                                                                                                      label: I18N$BsConsole.get("timezone", "America/Araguaina"),
                                                                                                                                      value: "America/Araguaina"
                                                                                                                                    },
                                                                                                                                    /* :: */[
                                                                                                                                      {
                                                                                                                                        offset: "-3.00",
                                                                                                                                        label: I18N$BsConsole.get("timezone", "America/Argentina/Buenos_Aires"),
                                                                                                                                        value: "America/Argentina/Buenos_Aires"
                                                                                                                                      },
                                                                                                                                      /* :: */[
                                                                                                                                        {
                                                                                                                                          offset: "-3.00",
                                                                                                                                          label: I18N$BsConsole.get("timezone", "America/Bahia"),
                                                                                                                                          value: "America/Bahia"
                                                                                                                                        },
                                                                                                                                        /* :: */[
                                                                                                                                          {
                                                                                                                                            offset: "-3.00",
                                                                                                                                            label: I18N$BsConsole.get("timezone", "America/Belem"),
                                                                                                                                            value: "America/Belem"
                                                                                                                                          },
                                                                                                                                          /* :: */[
                                                                                                                                            {
                                                                                                                                              offset: "-3.00",
                                                                                                                                              label: I18N$BsConsole.get("timezone", "America/Cayenne"),
                                                                                                                                              value: "America/Cayenne"
                                                                                                                                            },
                                                                                                                                            /* :: */[
                                                                                                                                              {
                                                                                                                                                offset: "-3.00",
                                                                                                                                                label: I18N$BsConsole.get("timezone", "America/Fortaleza"),
                                                                                                                                                value: "America/Fortaleza"
                                                                                                                                              },
                                                                                                                                              /* :: */[
                                                                                                                                                {
                                                                                                                                                  offset: "-3.00",
                                                                                                                                                  label: I18N$BsConsole.get("timezone", "America/Godthab"),
                                                                                                                                                  value: "America/Godthab"
                                                                                                                                                },
                                                                                                                                                /* :: */[
                                                                                                                                                  {
                                                                                                                                                    offset: "-3.00",
                                                                                                                                                    label: I18N$BsConsole.get("timezone", "America/Maceio"),
                                                                                                                                                    value: "America/Maceio"
                                                                                                                                                  },
                                                                                                                                                  /* :: */[
                                                                                                                                                    {
                                                                                                                                                      offset: "-3.00",
                                                                                                                                                      label: I18N$BsConsole.get("timezone", "America/Miquelon"),
                                                                                                                                                      value: "America/Miquelon"
                                                                                                                                                    },
                                                                                                                                                    /* :: */[
                                                                                                                                                      {
                                                                                                                                                        offset: "-3.00",
                                                                                                                                                        label: I18N$BsConsole.get("timezone", "America/Montevideo"),
                                                                                                                                                        value: "America/Montevideo"
                                                                                                                                                      },
                                                                                                                                                      /* :: */[
                                                                                                                                                        {
                                                                                                                                                          offset: "-3.00",
                                                                                                                                                          label: I18N$BsConsole.get("timezone", "America/Paramaribo"),
                                                                                                                                                          value: "America/Paramaribo"
                                                                                                                                                        },
                                                                                                                                                        /* :: */[
                                                                                                                                                          {
                                                                                                                                                            offset: "-3.00",
                                                                                                                                                            label: I18N$BsConsole.get("timezone", "America/Recife"),
                                                                                                                                                            value: "America/Recife"
                                                                                                                                                          },
                                                                                                                                                          /* :: */[
                                                                                                                                                            {
                                                                                                                                                              offset: "-3.00",
                                                                                                                                                              label: I18N$BsConsole.get("timezone", "America/Santiago"),
                                                                                                                                                              value: "America/Santiago"
                                                                                                                                                            },
                                                                                                                                                            /* :: */[
                                                                                                                                                              {
                                                                                                                                                                offset: "-2.00",
                                                                                                                                                                label: I18N$BsConsole.get("timezone", "America/Sao_Paulo"),
                                                                                                                                                                value: "America/Sao_Paulo"
                                                                                                                                                              },
                                                                                                                                                              /* :: */[
                                                                                                                                                                {
                                                                                                                                                                  offset: "-3.00",
                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Antarctica/Palmer"),
                                                                                                                                                                  value: "Antarctica/Palmer"
                                                                                                                                                                },
                                                                                                                                                                /* :: */[
                                                                                                                                                                  {
                                                                                                                                                                    offset: "-3.00",
                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Antarctica/Rothera"),
                                                                                                                                                                    value: "Antarctica/Rothera"
                                                                                                                                                                  },
                                                                                                                                                                  /* :: */[
                                                                                                                                                                    {
                                                                                                                                                                      offset: "-3.00",
                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Atlantic/Stanley"),
                                                                                                                                                                      value: "Atlantic/Stanley"
                                                                                                                                                                    },
                                                                                                                                                                    /* :: */[
                                                                                                                                                                      {
                                                                                                                                                                        offset: "-2.00",
                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "America/Noronha"),
                                                                                                                                                                        value: "America/Noronha"
                                                                                                                                                                      },
                                                                                                                                                                      /* :: */[
                                                                                                                                                                        {
                                                                                                                                                                          offset: "-2.00",
                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Atlantic/South_Georgia"),
                                                                                                                                                                          value: "Atlantic/South_Georgia"
                                                                                                                                                                        },
                                                                                                                                                                        /* :: */[
                                                                                                                                                                          {
                                                                                                                                                                            offset: "-1.00",
                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "America/Scoresbysund"),
                                                                                                                                                                            value: "America/Scoresbysund"
                                                                                                                                                                          },
                                                                                                                                                                          /* :: */[
                                                                                                                                                                            {
                                                                                                                                                                              offset: "-1.00",
                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Atlantic/Azores"),
                                                                                                                                                                              value: "Atlantic/Azores"
                                                                                                                                                                            },
                                                                                                                                                                            /* :: */[
                                                                                                                                                                              {
                                                                                                                                                                                offset: "-1.00",
                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Atlantic/Cape_Verde"),
                                                                                                                                                                                value: "Atlantic/Cape_Verde"
                                                                                                                                                                              },
                                                                                                                                                                              /* :: */[
                                                                                                                                                                                {
                                                                                                                                                                                  offset: "+0.0",
                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Africa/Abidjan"),
                                                                                                                                                                                  value: "Africa/Abidjan"
                                                                                                                                                                                },
                                                                                                                                                                                /* :: */[
                                                                                                                                                                                  {
                                                                                                                                                                                    offset: "+0.0",
                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Africa/Accra"),
                                                                                                                                                                                    value: "Africa/Accra"
                                                                                                                                                                                  },
                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                    {
                                                                                                                                                                                      offset: "+0.0",
                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Africa/Bissau"),
                                                                                                                                                                                      value: "Africa/Bissau"
                                                                                                                                                                                    },
                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                      {
                                                                                                                                                                                        offset: "+0.0",
                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Africa/Casablanca"),
                                                                                                                                                                                        value: "Africa/Casablanca"
                                                                                                                                                                                      },
                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                        {
                                                                                                                                                                                          offset: "+0.0",
                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Africa/El_Aaiun"),
                                                                                                                                                                                          value: "Africa/El_Aaiun"
                                                                                                                                                                                        },
                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                          {
                                                                                                                                                                                            offset: "+0.0",
                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Africa/Monrovia"),
                                                                                                                                                                                            value: "Africa/Monrovia"
                                                                                                                                                                                          },
                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                            {
                                                                                                                                                                                              offset: "+0.0",
                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "America/Danmarkshavn"),
                                                                                                                                                                                              value: "America/Danmarkshavn"
                                                                                                                                                                                            },
                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                              {
                                                                                                                                                                                                offset: "+0.0",
                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Atlantic/Canary"),
                                                                                                                                                                                                value: "Atlantic/Canary"
                                                                                                                                                                                              },
                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                {
                                                                                                                                                                                                  offset: "+0.0",
                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Atlantic/Faroe"),
                                                                                                                                                                                                  value: "Atlantic/Faroe"
                                                                                                                                                                                                },
                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                  {
                                                                                                                                                                                                    offset: "+0.0",
                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Atlantic/Reykjavik"),
                                                                                                                                                                                                    value: "Atlantic/Reykjavik"
                                                                                                                                                                                                  },
                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                    {
                                                                                                                                                                                                      offset: "+0.0",
                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Etc/GMT"),
                                                                                                                                                                                                      value: "Etc/GMT"
                                                                                                                                                                                                    },
                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                      {
                                                                                                                                                                                                        offset: "+0.0",
                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Dublin"),
                                                                                                                                                                                                        value: "Europe/Dublin"
                                                                                                                                                                                                      },
                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                        {
                                                                                                                                                                                                          offset: "+0.0",
                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Lisbon"),
                                                                                                                                                                                                          value: "Europe/Lisbon"
                                                                                                                                                                                                        },
                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                          {
                                                                                                                                                                                                            offset: "+0.0",
                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/London"),
                                                                                                                                                                                                            value: "Europe/London"
                                                                                                                                                                                                          },
                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                            {
                                                                                                                                                                                                              offset: "+1.00",
                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Africa/Algiers"),
                                                                                                                                                                                                              value: "Africa/Algiers"
                                                                                                                                                                                                            },
                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                              {
                                                                                                                                                                                                                offset: "+1.00",
                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Africa/Ceuta"),
                                                                                                                                                                                                                value: "Africa/Ceuta"
                                                                                                                                                                                                              },
                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                {
                                                                                                                                                                                                                  offset: "+1.00",
                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Africa/Lagos"),
                                                                                                                                                                                                                  value: "Africa/Lagos"
                                                                                                                                                                                                                },
                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                  {
                                                                                                                                                                                                                    offset: "+1.00",
                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Africa/Ndjamena"),
                                                                                                                                                                                                                    value: "Africa/Ndjamena"
                                                                                                                                                                                                                  },
                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                      offset: "+1.00",
                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Africa/Tunis"),
                                                                                                                                                                                                                      value: "Africa/Tunis"
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                      {
                                                                                                                                                                                                                        offset: "+2.00",
                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Africa/Windhoek"),
                                                                                                                                                                                                                        value: "Africa/Windhoek"
                                                                                                                                                                                                                      },
                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                          offset: "+1.00",
                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Amsterdam"),
                                                                                                                                                                                                                          value: "Europe/Amsterdam"
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                          {
                                                                                                                                                                                                                            offset: "+1.00",
                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Andorra"),
                                                                                                                                                                                                                            value: "Europe/Andorra"
                                                                                                                                                                                                                          },
                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                              offset: "+1.00",
                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Europe/Belgrade"),
                                                                                                                                                                                                                              value: "Europe/Belgrade"
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                offset: "+1.00",
                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Europe/Berlin"),
                                                                                                                                                                                                                                value: "Europe/Berlin"
                                                                                                                                                                                                                              },
                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                  offset: "+1.00",
                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Europe/Brussels"),
                                                                                                                                                                                                                                  value: "Europe/Brussels"
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                    offset: "+1.00",
                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Europe/Budapest"),
                                                                                                                                                                                                                                    value: "Europe/Budapest"
                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                      offset: "+1.00",
                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Europe/Copenhagen"),
                                                                                                                                                                                                                                      value: "Europe/Copenhagen"
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                        offset: "+1.00",
                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Gibraltar"),
                                                                                                                                                                                                                                        value: "Europe/Gibraltar"
                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                          offset: "+1.00",
                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Luxembourg"),
                                                                                                                                                                                                                                          value: "Europe/Luxembourg"
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                            offset: "+1.00",
                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Madrid"),
                                                                                                                                                                                                                                            value: "Europe/Madrid"
                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                              offset: "+1.00",
                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Europe/Malta"),
                                                                                                                                                                                                                                              value: "Europe/Malta"
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                offset: "+1.00",
                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Europe/Monaco"),
                                                                                                                                                                                                                                                value: "Europe/Monaco"
                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                  offset: "+1.00",
                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Europe/Oslo"),
                                                                                                                                                                                                                                                  value: "Europe/Oslo"
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                    offset: "+1.00",
                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Europe/Paris"),
                                                                                                                                                                                                                                                    value: "Europe/Paris"
                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                      offset: "+1.00",
                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Europe/Prague"),
                                                                                                                                                                                                                                                      value: "Europe/Prague"
                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                        offset: "+1.00",
                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Rome"),
                                                                                                                                                                                                                                                        value: "Europe/Rome"
                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                          offset: "+1.00",
                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Stockholm"),
                                                                                                                                                                                                                                                          value: "Europe/Stockholm"
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                            offset: "+1.00",
                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Tirane"),
                                                                                                                                                                                                                                                            value: "Europe/Tirane"
                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                              offset: "+1.00",
                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Europe/Vienna"),
                                                                                                                                                                                                                                                              value: "Europe/Vienna"
                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                offset: "+1.00",
                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Europe/Warsaw"),
                                                                                                                                                                                                                                                                value: "Europe/Warsaw"
                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                  offset: "+1.00",
                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Europe/Zurich"),
                                                                                                                                                                                                                                                                  value: "Europe/Zurich"
                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                    offset: "+2.00",
                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Africa/Cairo"),
                                                                                                                                                                                                                                                                    value: "Africa/Cairo"
                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                      offset: "+2.00",
                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Africa/Johannesburg"),
                                                                                                                                                                                                                                                                      value: "Africa/Johannesburg"
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                        offset: "+2.00",
                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Africa/Maputo"),
                                                                                                                                                                                                                                                                        value: "Africa/Maputo"
                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                          offset: "+2.00",
                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Africa/Tripoli"),
                                                                                                                                                                                                                                                                          value: "Africa/Tripoli"
                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                            offset: "+2.00",
                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Asia/Amman"),
                                                                                                                                                                                                                                                                            value: "Asia/Amman"
                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                              offset: "+2.00",
                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Beirut"),
                                                                                                                                                                                                                                                                              value: "Asia/Beirut"
                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                offset: "+2.00",
                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Damascus"),
                                                                                                                                                                                                                                                                                value: "Asia/Damascus"
                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                  offset: "+2.00",
                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Gaza"),
                                                                                                                                                                                                                                                                                  value: "Asia/Gaza"
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                    offset: "+2.00",
                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Jerusalem"),
                                                                                                                                                                                                                                                                                    value: "Asia/Jerusalem"
                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                      offset: "+2.00",
                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Nicosia"),
                                                                                                                                                                                                                                                                                      value: "Asia/Nicosia"
                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                        offset: "+2.00",
                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Athens"),
                                                                                                                                                                                                                                                                                        value: "Europe/Athens"
                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                          offset: "+2.00",
                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Bucharest"),
                                                                                                                                                                                                                                                                                          value: "Europe/Bucharest"
                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                            offset: "+2.00",
                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Chisinau"),
                                                                                                                                                                                                                                                                                            value: "Europe/Chisinau"
                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                              offset: "+2.00",
                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Europe/Helsinki"),
                                                                                                                                                                                                                                                                                              value: "Europe/Helsinki"
                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                offset: "+2.00",
                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Europe/Istanbul"),
                                                                                                                                                                                                                                                                                                value: "Europe/Istanbul"
                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                  offset: "+2.00",
                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Europe/Kaliningrad"),
                                                                                                                                                                                                                                                                                                  value: "Europe/Kaliningrad"
                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                    offset: "+2.00",
                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Europe/Kiev"),
                                                                                                                                                                                                                                                                                                    value: "Europe/Kiev"
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                      offset: "+2.00",
                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Europe/Riga"),
                                                                                                                                                                                                                                                                                                      value: "Europe/Riga"
                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                        offset: "+2.00",
                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Sofia"),
                                                                                                                                                                                                                                                                                                        value: "Europe/Sofia"
                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                          offset: "+2.00",
                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Tallinn"),
                                                                                                                                                                                                                                                                                                          value: "Europe/Tallinn"
                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                            offset: "+2.00",
                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Vilnius"),
                                                                                                                                                                                                                                                                                                            value: "Europe/Vilnius"
                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                              offset: "+3.00",
                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Africa/Khartoum"),
                                                                                                                                                                                                                                                                                                              value: "Africa/Khartoum"
                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                offset: "+3.00",
                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Africa/Nairobi"),
                                                                                                                                                                                                                                                                                                                value: "Africa/Nairobi"
                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                  offset: "+3.00",
                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Antarctica/Syowa"),
                                                                                                                                                                                                                                                                                                                  value: "Antarctica/Syowa"
                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                    offset: "+3.00",
                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Baghdad"),
                                                                                                                                                                                                                                                                                                                    value: "Asia/Baghdad"
                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                      offset: "+3.00",
                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Qatar"),
                                                                                                                                                                                                                                                                                                                      value: "Asia/Qatar"
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                        offset: "+3.00",
                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Riyadh"),
                                                                                                                                                                                                                                                                                                                        value: "Asia/Riyadh"
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                          offset: "+3.00",
                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Europe/Minsk"),
                                                                                                                                                                                                                                                                                                                          value: "Europe/Minsk"
                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                            offset: "+3.00",
                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Europe/Moscow"),
                                                                                                                                                                                                                                                                                                                            value: "Europe/Moscow"
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                              offset: "+3.50",
                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Tehran"),
                                                                                                                                                                                                                                                                                                                              value: "Asia/Tehran"
                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Baku"),
                                                                                                                                                                                                                                                                                                                                value: "Asia/Baku"
                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                  offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Dubai"),
                                                                                                                                                                                                                                                                                                                                  value: "Asia/Dubai"
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                    offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Tbilisi"),
                                                                                                                                                                                                                                                                                                                                    value: "Asia/Tbilisi"
                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                      offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Yerevan"),
                                                                                                                                                                                                                                                                                                                                      value: "Asia/Yerevan"
                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                        offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Europe/Samara"),
                                                                                                                                                                                                                                                                                                                                        value: "Europe/Samara"
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                          offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Indian/Mahe"),
                                                                                                                                                                                                                                                                                                                                          value: "Indian/Mahe"
                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                            offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Indian/Mauritius"),
                                                                                                                                                                                                                                                                                                                                            value: "Indian/Mauritius"
                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                              offset: "+4.00",
                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Indian/Reunion"),
                                                                                                                                                                                                                                                                                                                                              value: "Indian/Reunion"
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                offset: "+4.50",
                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Kabul"),
                                                                                                                                                                                                                                                                                                                                                value: "Asia/Kabul"
                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                  offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Antarctica/Mawson"),
                                                                                                                                                                                                                                                                                                                                                  value: "Antarctica/Mawson"
                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                    offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Aqtau"),
                                                                                                                                                                                                                                                                                                                                                    value: "Asia/Aqtau"
                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                      offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Aqtobe"),
                                                                                                                                                                                                                                                                                                                                                      value: "Asia/Aqtobe"
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                        offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Ashgabat"),
                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Ashgabat"
                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                          offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Asia/Dushanbe"),
                                                                                                                                                                                                                                                                                                                                                          value: "Asia/Dushanbe"
                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                            offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Asia/Karachi"),
                                                                                                                                                                                                                                                                                                                                                            value: "Asia/Karachi"
                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                              offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Tashkent"),
                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Tashkent"
                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Yekaterinburg"),
                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Yekaterinburg"
                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                  offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Indian/Kerguelen"),
                                                                                                                                                                                                                                                                                                                                                                  value: "Indian/Kerguelen"
                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                    offset: "+5.00",
                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Indian/Maldives"),
                                                                                                                                                                                                                                                                                                                                                                    value: "Indian/Maldives"
                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                      offset: "+5.50",
                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Calcutta"),
                                                                                                                                                                                                                                                                                                                                                                      value: "Asia/Calcutta"
                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                        offset: "+5.50",
                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Colombo"),
                                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Colombo"
                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                          offset: "+5.75",
                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Asia/Katmandu"),
                                                                                                                                                                                                                                                                                                                                                                          value: "Asia/Katmandu"
                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                            offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Antarctica/Vostok"),
                                                                                                                                                                                                                                                                                                                                                                            value: "Antarctica/Vostok"
                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                              offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Almaty"),
                                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Almaty"
                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Bishkek"),
                                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Bishkek"
                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                  offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Dhaka"),
                                                                                                                                                                                                                                                                                                                                                                                  value: "Asia/Dhaka"
                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                    offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Omsk"),
                                                                                                                                                                                                                                                                                                                                                                                    value: "Asia/Omsk"
                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                      offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Thimphu"),
                                                                                                                                                                                                                                                                                                                                                                                      value: "Asia/Thimphu"
                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                        offset: "+6.00",
                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Indian/Chagos"),
                                                                                                                                                                                                                                                                                                                                                                                        value: "Indian/Chagos"
                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                          offset: "+6.50",
                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Asia/Rangoon"),
                                                                                                                                                                                                                                                                                                                                                                                          value: "Asia/Rangoon"
                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                            offset: "+6.50",
                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Indian/Cocos"),
                                                                                                                                                                                                                                                                                                                                                                                            value: "Indian/Cocos"
                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                              offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Antarctica/Davis"),
                                                                                                                                                                                                                                                                                                                                                                                              value: "Antarctica/Davis"
                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Bangkok"),
                                                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Bangkok"
                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Hovd"),
                                                                                                                                                                                                                                                                                                                                                                                                  value: "Asia/Hovd"
                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Jakarta"),
                                                                                                                                                                                                                                                                                                                                                                                                    value: "Asia/Jakarta"
                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Krasnoyarsk"),
                                                                                                                                                                                                                                                                                                                                                                                                      value: "Asia/Krasnoyarsk"
                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Saigon"),
                                                                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Saigon"
                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+7.00",
                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Indian/Christmas"),
                                                                                                                                                                                                                                                                                                                                                                                                          value: "Indian/Christmas"
                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Antarctica/Casey"),
                                                                                                                                                                                                                                                                                                                                                                                                            value: "Antarctica/Casey"
                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Brunei"),
                                                                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Brunei"
                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Choibalsan"),
                                                                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Choibalsan"
                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Hong_Kong"),
                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Asia/Hong_Kong"
                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Irkutsk"),
                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Asia/Irkutsk"
                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Asia/Kuala_Lumpur"),
                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Asia/Kuala_Lumpur"
                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Macau"),
                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Macau"
                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Asia/Makassar"),
                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Asia/Makassar"
                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Asia/Manila"),
                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Asia/Manila"
                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Shanghai"),
                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Shanghai"
                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Singapore"),
                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Singapore"
                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Taipei"),
                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Asia/Taipei"
                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Asia/Ulaanbaatar"),
                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Asia/Ulaanbaatar"
                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+8.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Australia/Perth"),
                                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Australia/Perth"
                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+8.50",
                                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Pyongyang"),
                                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Pyongyang"
                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Asia/Dili"),
                                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Asia/Dili"
                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Asia/Jayapura"),
                                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Asia/Jayapura"
                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Seoul"),
                                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Seoul"
                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Asia/Tokyo"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Asia/Tokyo"
                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Asia/Yakutsk"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Asia/Yakutsk"
                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+9.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Pacific/Palau"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Pacific/Palau"
                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+10.50",
                                                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Australia/Adelaide"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Australia/Adelaide"
                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+9.50",
                                                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Australia/Darwin"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Australia/Darwin"
                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Antarctica/DumontDUrville"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Antarctica/DumontDUrville"
                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Asia/Magadan"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Asia/Magadan"
                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Asia/Vladivostok"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Asia/Vladivostok"
                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Australia/Brisbane"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Australia/Brisbane"
                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Australia/Hobart"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Australia/Hobart"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Australia/Sydney"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Australia/Sydney"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Pacific/Chuuk"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Pacific/Chuuk"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Pacific/Guam"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Pacific/Guam"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+10.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Pacific/Port_Moresby"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Pacific/Port_Moresby"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Pacific/Efate"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Pacific/Efate"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Pacific/Guadalcanal"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Pacific/Guadalcanal"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Pacific/Kosrae"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Pacific/Kosrae"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Pacific/Norfolk"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Pacific/Norfolk"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Pacific/Noumea"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Pacific/Noumea"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+11.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Pacific/Pohnpei"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Pacific/Pohnpei"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Asia/Kamchatka"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Asia/Kamchatka"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+13.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Pacific/Auckland"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Pacific/Auckland"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+13.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Pacific/Fiji"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Pacific/Fiji"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Pacific/Funafuti"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Pacific/Funafuti"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Pacific/Kwajalein"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Pacific/Kwajalein"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Pacific/Majuro"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Pacific/Majuro"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Pacific/Nauru"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Pacific/Nauru"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      label: I18N$BsConsole.get("timezone", "Pacific/Tarawa"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value: "Pacific/Tarawa"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        label: I18N$BsConsole.get("timezone", "Pacific/Wake"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        value: "Pacific/Wake"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          offset: "+12.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          label: I18N$BsConsole.get("timezone", "Pacific/Wallis"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value: "Pacific/Wallis"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            offset: "+14.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            label: I18N$BsConsole.get("timezone", "Pacific/Apia"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            value: "Pacific/Apia"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              offset: "+13.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              label: I18N$BsConsole.get("timezone", "Pacific/Enderbury"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value: "Pacific/Enderbury"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                offset: "+13.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                label: I18N$BsConsole.get("timezone", "Pacific/Fakaofo"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                value: "Pacific/Fakaofo"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  offset: "+13.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  label: I18N$BsConsole.get("timezone", "Pacific/Tongatapu"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value: "Pacific/Tongatapu"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    offset: "+14.00",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    label: I18N$BsConsole.get("timezone", "Pacific/Kiritimati"),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    value: "Pacific/Kiritimati"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* [] */0
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                ]
                                                                                                                                                                                                              ]
                                                                                                                                                                                                            ]
                                                                                                                                                                                                          ]
                                                                                                                                                                                                        ]
                                                                                                                                                                                                      ]
                                                                                                                                                                                                    ]
                                                                                                                                                                                                  ]
                                                                                                                                                                                                ]
                                                                                                                                                                                              ]
                                                                                                                                                                                            ]
                                                                                                                                                                                          ]
                                                                                                                                                                                        ]
                                                                                                                                                                                      ]
                                                                                                                                                                                    ]
                                                                                                                                                                                  ]
                                                                                                                                                                                ]
                                                                                                                                                                              ]
                                                                                                                                                                            ]
                                                                                                                                                                          ]
                                                                                                                                                                        ]
                                                                                                                                                                      ]
                                                                                                                                                                    ]
                                                                                                                                                                  ]
                                                                                                                                                                ]
                                                                                                                                                              ]
                                                                                                                                                            ]
                                                                                                                                                          ]
                                                                                                                                                        ]
                                                                                                                                                      ]
                                                                                                                                                    ]
                                                                                                                                                  ]
                                                                                                                                                ]
                                                                                                                                              ]
                                                                                                                                            ]
                                                                                                                                          ]
                                                                                                                                        ]
                                                                                                                                      ]
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]
                                                                                                                              ]
                                                                                                                            ]
                                                                                                                          ]
                                                                                                                        ]
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var timezones = /* :: */[
  timezones_000,
  timezones_001
];

function getTimezoneOffset(timezone) {
  var tz = Belt_List.getBy(timezones, (function (timezone$prime) {
          return timezone === timezone$prime.label;
        }));
  if (tz === undefined) {
    return 0;
  }
  try {
    return Caml_format.caml_float_of_string(tz.offset) | 0;
  }
  catch (exn){
    return 0;
  }
}

exports.frequency = frequency;
exports.hours = hours;
exports.amOrPm = amOrPm;
exports.days = days;
exports.months = months;
exports.timezones = timezones;
exports.getTimezoneOffset = getTimezoneOffset;
/* frequency Not a pure module */
