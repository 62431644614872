// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var ExporterMetrics$BsConsole = require("../ExporterMetrics.js");

var resource = "metrics_sets";

function setFromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json)
        };
}

function create(path, universeName, projectName, name, param) {
  ExporterMetrics$BsConsole.send(/* SetCreate */0);
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [setFromJson(respBag.json)]);
              }), undefined, undefined);
}

function update(path, universeName, projectName, name, id, param) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* [] */0
      ]);
  ExporterMetrics$BsConsole.send(/* SetUpdate */1);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Put */Block.__(1, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [respBag.json]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [Json_decode.list(setFromJson, respBag.json)]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  ExporterMetrics$BsConsole.send(/* SetDelete */2);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

exports.resource = resource;
exports.setFromJson = setFromJson;
exports.create = create;
exports.update = update;
exports.get = get;
exports.$$delete = $$delete;
/* Task2-BsConsole Not a pure module */
