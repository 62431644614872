// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Chip$BsConsole = require("./Chip.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var Filters$BsConsole = require("./filters.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var ApiDescribe$BsConsole = require("./apiDescribe.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");

var clearButton = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
      /* :: */[
        Css.marginLeft(Css.px(16)),
        /* [] */0
      ]
    ]);

var clearButtonLabel = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* [] */0
    ]);

var toolbar = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.flexWrap(/* nowrap */867913355),
        /* :: */[
          Css.overflowX(/* auto */-1065951377),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  clearButton: clearButton,
  clearButtonLabel: clearButtonLabel,
  toolbar: toolbar
};

function nMoreChips(chips) {
  if (chips.length <= 3) {
    return chips;
  }
  var n = chips.length - 2 | 0;
  var original = Belt_Array.slice(chips, 0, 2);
  var moreChips = Belt_Array.slice(chips, 2, n);
  var nMore = [ReasonReact.element(undefined, undefined, Chip$BsConsole.NMore.make(n, moreChips, []))];
  return Belt_Array.concat(original, nMore);
}

function setRef(theRef, param) {
  param.state.addButton.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component = ReasonReact.reducerComponent("ProjectInbox");

function make(onChange, name, handleTask, aperture, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var getChip = function (filter) {
                var onDelete = function (param) {
                  return Curry._1(onChange, Curry._2(Crdb$BsConsole.Filters.remove, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)));
                };
                var attr = InboxWorkflow$BsConsole.Folders.getWorkflowAttribute(filter);
                var attribute = attr !== undefined ? attr : Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
                var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
                var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
                if (InboxUtils$BsConsole.isNestedIssues(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter))) {
                  return ReasonReact.element(attribute + ("_" + (operator + ("__" + (value$1 + "___Chip")))), undefined, Chip$BsConsole.TriageFilter.make(attribute, operator, value$1, onDelete, []));
                } else {
                  return ReasonReact.element(attribute + ("_" + (operator + ("__" + (value$1 + "___Chip")))), undefined, Chip$BsConsole.Filter.make(attribute, operator, value$1, onDelete, []));
                }
              };
              var attributes = self.state.remote;
              var tmp;
              var exit = 0;
              if (typeof attributes === "number" || attributes.tag) {
                exit = 1;
              } else {
                var attributes$1 = attributes[0];
                var filterArray = Belt_Array.reverse(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)));
                tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(toolbar, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                          ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                      return React.createElement("div", {
                                                  ref: refHandler,
                                                  style: {
                                                    color: "currentColor",
                                                    display: "flex",
                                                    margin: "0 16px",
                                                    alignItems: "center"
                                                  },
                                                  onFocus: onFocus,
                                                  onClick: onClick
                                                }, ReasonReact.element(undefined, Curry._1(self.handle, setRef), Chip$BsConsole.Add.make(I18N$BsConsole.get(undefined, "Add filter"), [])));
                                    }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                      return ReasonReact.element(undefined, undefined, Filters$BsConsole.make(onChange, (function (param) {
                                                        Curry._1(onClose, undefined);
                                                        setTimeout((function (param) {
                                                                var inputRef = self.state.addButton;
                                                                var r = inputRef.contents;
                                                                if (r === undefined) {
                                                                  return ;
                                                                }
                                                                var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                                                                domElement.focus();
                                                                
                                                              }), 300);
                                                        
                                                      }), name, token, handleTask, attributes$1, aperture, undefined, undefined, undefined, []));
                                    }), undefined, [])),
                          React.createElement("div", {
                                style: {
                                  display: "flex",
                                  overflow: "auto",
                                  alignItems: "center",
                                  alignSelf: "stretch",
                                  flexGrow: "1",
                                  flexWrap: "nowrap"
                                }
                              }, nMoreChips(Belt_Array.mapWithIndex(Belt_Array.concat(Belt_Array.keep(filterArray, (function (f) {
                                                  return InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
                                                })), Belt_Array.keep(filterArray, (function (f) {
                                                  return !InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
                                                }))), (function (i, filter) {
                                          return ReasonReact.element(String(i), undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                                            return React.createElement("span", {
                                                                        ref: refHandler,
                                                                        onFocus: onFocus,
                                                                        onClick: onClick
                                                                      }, getChip(filter));
                                                          }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                                            return ReasonReact.element(undefined, undefined, Filters$BsConsole.make(onChange, onClose, name, token, handleTask, attributes$1, Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.remove, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture), Caml_option.some(Belt_List.getBy(attributes$1, (function (attribute) {
                                                                                      return attribute.name === Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                                                                                    }))), Caml_option.some(Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))), Caml_option.some(Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))))), []));
                                                          }), undefined, []));
                                        })))),
                          Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)).length !== 0 ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                      /* Small */311976103,
                                      undefined,
                                      (function (_event) {
                                          return Curry._1(onChange, Crdb$BsConsole.Filters.empty);
                                        }),
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      {
                                        root: clearButton,
                                        label: clearButtonLabel
                                      },
                                      [I18N$BsConsole.show(undefined, "Clear")]
                                    ])) : null
                        ]));
              }
              if (exit === 1) {
                var filterArray$1 = Belt_Array.reverse(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)));
                tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(toolbar, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                          ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (_refHandler, _onFocus, _onClick) {
                                      return React.createElement("div", {
                                                  style: {
                                                    color: "currentColor",
                                                    display: "flex",
                                                    marginRight: "16px",
                                                    alignItems: "center"
                                                  }
                                                }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Add.make(I18N$BsConsole.get(undefined, "Add filter"), [])));
                                    }), undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                      return I18N$BsConsole.show(undefined, "loading");
                                    }), undefined, [])),
                          React.createElement("div", {
                                style: {
                                  display: "flex",
                                  overflow: "auto",
                                  alignItems: "center",
                                  alignSelf: "stretch",
                                  flexGrow: "1",
                                  flexWrap: "nowrap"
                                }
                              }, nMoreChips(Belt_Array.map(Belt_Array.concat(Belt_Array.keep(filterArray$1, (function (f) {
                                                  return InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
                                                })), Belt_Array.keep(filterArray$1, (function (f) {
                                                  return !InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
                                                }))), getChip)))
                        ]));
              }
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, toolbar, true, [tmp]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0,
                      addButton: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* NoUpdate */0;
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1,
                              addButton: state.addButton
                            },
                            (function (self) {
                                return Curry._2(handleTask, (function (json) {
                                              return Curry._1(self.send, /* Success */[ApiDescribe$BsConsole.decodeExn(TaskCallback$BsConsole.getJson(json))]);
                                            }), Task$BsConsole.makeTask(/* Describe */Block.__(3, [
                                                  token,
                                                  name
                                                ])));
                              })
                          ]);
                }
              }
              var filteredAttributes = List.filter((function (attr) {
                        if (attr.custom) {
                          return true;
                        } else {
                          return attr.statistics.used;
                        }
                      }))(action[0]);
              var sortedCustom = List.sort((function (a, b) {
                      return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
                    }), List.filter((function (attr) {
                            return attr.custom;
                          }))(filteredAttributes));
              var sortedbuiltIns = List.sort((function (a, b) {
                      return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
                    }), List.filter((function (attr) {
                            return !attr.custom;
                          }))(filteredAttributes));
              var results = Pervasives.$at(sortedCustom, sortedbuiltIns);
              return /* Update */Block.__(0, [{
                          remote: /* Success */Block.__(0, [results]),
                          addButton: state.addButton
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Style = Style;
exports.nMoreChips = nMoreChips;
exports.setRef = setRef;
exports.component = component;
exports.make = make;
/* clearButton Not a pure module */
