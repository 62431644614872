// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeFileList(json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: name,
          size: Json_decode.field("size", Json_decode.$$float, json),
          content_type: Json_decode.field("content_type", Json_decode.string, json),
          inline: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("inline", Json_decode.bool, param);
                }), json)
        };
}

function decodeExn(json) {
  return Json_decode.field("attachments", (function (param) {
                return Json_decode.list(decodeFileList, param);
              }), json);
}

function getPath(errorId, token, projectName, file) {
  return "/api/get?object=" + (errorId + ((
              token !== undefined ? "&token=" + token : ""
            ) + ("&project=" + (projectName + ("&attachment_id=" + (file.id + ("&attachment_inline=" + Pervasives.string_of_bool(file.inline))))))));
}

exports.decodeFileList = decodeFileList;
exports.decodeExn = decodeExn;
exports.getPath = getPath;
/* No side effect */
