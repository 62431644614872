// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var FingerprintId$BsConsole = require("./FingerprintId.js");
var Tooltip = require("@material-ui/core/Tooltip");
var WindowVariables$BsConsole = require("./WindowVariables.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var MoreVert = require("@material-ui/icons/MoreVert");
var BugReport = require("@material-ui/icons/BugReport");

function createStringFilterOptions(fingerprint) {
  return [
          /* tuple */[
            "fingerprint",
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  fingerprint
                ]])
          ],
          /* tuple */[
            "fingerprint",
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  fingerprint
                ]])
          ]
        ];
}

var fingerprint = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.flexGrow(1),
            /* :: */[
              Css.unsafe("color", "inherit"),
              /* :: */[
                Css.fontFamily("Inconsolata"),
                /* :: */[
                  Css.padding(Css.px(4)),
                  /* :: */[
                    Css.selector(":visited", /* :: */[
                          Css.unsafe("color", "inherit"),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "triage-fingerprint-link",
        /* [] */0
      ]
    ]);

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var row = Css.style(/* :: */[
      Css.selector("> *:not(:first-child)", /* :: */[
            Css.marginLeft(Css.px(4)),
            /* :: */[
              Css.justifySelf(/* flexEnd */924268066),
              /* [] */0
            ]
          ]),
      /* [] */0
    ]);

var Styles = {
  fingerprint: fingerprint,
  iconButton: iconButton,
  row: row
};

function addFilter(aperture, filters) {
  var filterMap = Belt_List.toArray(filters).reduce((function (filterMap, param) {
          return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                          param[0],
                          param[1]
                        ]), filterMap);
        }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, aperture);
}

function getDebuggerRoute(projectName, fingerprint, aperture) {
  var filtersToAdd = WindowVariables$BsConsole.maybeAutoAddDeletedEqualZero ? /* :: */[
      /* tuple */[
        "fingerprint",
        /* Equal */Block.__(0, [/* `String */[
              -976970511,
              fingerprint
            ]])
      ],
      /* :: */[
        /* tuple */[
          "_deleted",
          /* Equal */Block.__(0, [/* `Int */[
                3654863,
                Int64.zero
              ]])
        ],
        /* [] */0
      ]
    ] : /* :: */[
      /* tuple */[
        "fingerprint",
        /* Equal */Block.__(0, [/* `String */[
              -976970511,
              fingerprint
            ]])
      ],
      /* [] */0
    ];
  return /* ProjectDebugger2 */Block.__(19, [
            projectName,
            {
              aperture: Crdb$BsConsole.addFilter(aperture, filtersToAdd),
              state: undefined
            }
          ]);
}

function TriageFingerprint(Props) {
  var fingerprint$1 = Props.fingerprint;
  var handleChangeUrl = Props.handleChangeUrl;
  var getDetailsRoute = Props.getDetailsRoute;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var drilldownDispatch = DrilldownContext$BsConsole.useDrilldownDispatch(undefined);
  var detailsRoute = Curry._1(getDetailsRoute, fingerprint$1.hash);
  var debuggerRoute = getDebuggerRoute(projectName, fingerprint$1.hash, aperture);
  var match = fingerprint$1.id;
  var match$1 = Gate$BsConsole.isAlpha(undefined);
  var match$2 = location.host;
  var tmp;
  var exit = 0;
  if (match !== undefined && match$1) {
    switch (match$2) {
      case "backtrace.sp.backtrace.io" :
      case "yolo.sp.backtrace.io" :
          tmp = I18N$BsConsole.showSkip(FingerprintId$BsConsole.addProjectPrefix(Issues$BsConsole.Id.toString(match), projectName));
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = I18N$BsConsole.showSkip(TriageGroup$BsConsole.formatFingerprint(fingerprint$1.hash).slice(0, 7));
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: row,
              children: null
            }, React.createElement(Link$BsConsole.Jsx3.make, {
                  route: detailsRoute,
                  handleChangeUrl: handleChangeUrl,
                  className: fingerprint,
                  style: { },
                  children: React.createElement(Tooltip.default, {
                        placement: "top",
                        children: React.createElement("span", undefined, tmp),
                        title: I18N$BsConsole.get(undefined, "View fingerprint group details")
                      })
                }), React.createElement(Link$BsConsole.Jsx3.make, {
                  route: debuggerRoute,
                  handleChangeUrl: handleChangeUrl,
                  children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "View in debugger",
                        classes: iconButton,
                        placement_tooltip: "top",
                        title_tooltip: I18N$BsConsole.get(undefined, "View in debugger"),
                        children: React.createElement(BugReport.default, {
                              color: "inherit",
                              fontSize: "inherit"
                            })
                      })
                }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                  ariaLabel: "More group options",
                  classes: iconButton,
                  onClick: (function ($$event) {
                      var el = $$event.currentTarget;
                      if (!(el == null)) {
                        if (drilldownDispatch !== undefined) {
                          return Curry._1(drilldownDispatch, /* SetDrilldown */[/* tuple */[
                                        Caml_option.some(el),
                                        fingerprint$1.hash,
                                        createStringFilterOptions(fingerprint$1.hash),
                                        [],
                                        (function (param) {
                                            
                                          })
                                      ]]);
                        } else {
                          console.log("DrilldownContext's provider is needed as a parent for this component to function properly.");
                          return ;
                        }
                      }
                      
                    }),
                  placement_tooltip: "top",
                  title_tooltip: I18N$BsConsole.get(undefined, "More group options"),
                  children: React.createElement(MoreVert.default, {
                        color: "inherit",
                        fontSize: "inherit"
                      })
                }));
}

var make = TriageFingerprint;

exports.createStringFilterOptions = createStringFilterOptions;
exports.Styles = Styles;
exports.addFilter = addFilter;
exports.getDebuggerRoute = getDebuggerRoute;
exports.make = make;
/* fingerprint Not a pure module */
