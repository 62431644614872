// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeActions(json) {
  return Json_decode.array((function (json$prime) {
                var action = Json_decode.field("action", Json_decode.string, json$prime);
                var type_ = Json_decode.field("type", Json_decode.string, json$prime);
                var key = Json_decode.optional((function (param) {
                        return Json_decode.field("key", (function (json$prime$prime) {
                                      return json$prime$prime;
                                    }), param);
                      }), json$prime);
                return /* tuple */[
                        action,
                        type_,
                        key
                      ];
              }), json);
}

function toJsDict(json) {
  var actions = Json_decode.field("actions", decodeActions, json);
  var results = Json_decode.field("results", (function (param) {
          return Json_decode.array((function (json$prime) {
                        return json$prime;
                      }), param);
        }), json);
  return Belt_Array.reduceWithIndex(actions, { }, (function (resp, param, idx) {
                var result = Belt_Option.flatMap(Belt_Array.get(results, idx), (function (json$prime) {
                        return Json_decode.optional((function (param) {
                                      return Json_decode.field("result", (function (json$prime$prime) {
                                                    return json$prime$prime;
                                                  }), param);
                                    }), json$prime);
                      }));
                resp[param[1]] = {
                  action: param[0],
                  result: result
                };
                return resp;
              }));
}

function getBPGObjectFromFromDict(responseDict, entity, decoder) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(responseDict, entity), (function (param) {
                    return Belt_Option.map(param.result, (function (json$prime) {
                                  return Json_decode.array(decoder, json$prime);
                                }));
                  })), []);
}

var $$Response = {
  decodeActions: decodeActions,
  toJsDict: toJsDict,
  getBPGObjectFromFromDict: getBPGObjectFromFromDict
};

exports.$$Response = $$Response;
/* No side effect */
