// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");

var DecodeError = Caml_exceptions.create("Deleting_Tasks-BsConsole.DecodeError");

var DeleteErrors = { };

function ofJson(json) {
  return {
          response: Json_decode.field("response", Json_decode.string, json)
        };
}

var DeleteResp = {
  ofJson: ofJson
};

function $$delete(token, project, universe, subsets, query, param) {
  var props_000 = /* tuple */[
    "token",
    token
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "project",
      project
    ],
    /* :: */[
      /* tuple */[
        "universe",
        universe
      ],
      /* :: */[
        /* tuple */[
          "query",
          Crdb$BsConsole.QueryManager.toCrdb(query)
        ],
        /* [] */0
      ]
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var props$1 = subsets ? /* :: */[
      /* tuple */[
        "subsets",
        [
          "physical",
          "crdb"
        ]
      ],
      props
    ] : /* :: */[
      /* tuple */[
        "subsets",
        ["physical"]
      ],
      props
    ];
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/delete"]), undefined, /* Post */Block.__(0, [Json_encode.object_(props$1)]), undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [ofJson(param.json)]);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  var error = Uncaught$BsConsole.castToJsExn(e);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Deleting_Tasks/delete", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              DecodeError,
                              e
                            ]]);
                }
              }), undefined, undefined);
}

exports.DecodeError = DecodeError;
exports.DeleteErrors = DeleteErrors;
exports.DeleteResp = DeleteResp;
exports.$$delete = $$delete;
/* Crdb-BsConsole Not a pure module */
