// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");

var span = Css.style(/* :: */[
      Css.padding(Css.px(4)),
      /* [] */0
    ]);

var Styles = {
  span: span
};

function EmptyValue(Props) {
  return React.createElement("span", {
              className: span
            }, I18N$BsConsole.showSkip("\u2014"));
}

var make = EmptyValue;

exports.Styles = Styles;
exports.make = make;
/* span Not a pure module */
