// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var InboxMetrics$BsConsole = require("./InboxMetrics.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var InboxMergerError$BsConsole = require("./InboxMergerError.js");

var component = RR$BsConsole.statelessComponent("InboxUnmergeButton-BsConsole");

function make(name, token, fingerprints, onSuccess, classNameOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            onFocus: onFocus,
                                            onClick: onClick,
                                            display: "inline-block"
                                          }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    Css.merge(/* :: */[
                                                          Css.style(/* :: */[
                                                                Css.marginLeft(Css.rem(1)),
                                                                /* :: */[
                                                                  Css.textTransform(/* none */-922086728),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          /* :: */[
                                                            className,
                                                            /* [] */0
                                                          ]
                                                        ]),
                                                    onClick,
                                                    undefined,
                                                    undefined,
                                                    /* Outlined */-28821822,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    onFocus,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, "smaller-icon", [
                                                                ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.CallSplit.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                React.createElement("div", {
                                                                      className: Css.style(/* :: */[
                                                                            Css.marginLeft(Css.em(0.5)),
                                                                            /* [] */0
                                                                          ])
                                                                    }, I18N$BsConsole.show(undefined, "Unmerge error"))
                                                              ]))]
                                                  ])));
                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                return React.createElement("div", {
                                            onClick: (function (e) {
                                                e.stopPropagation();
                                                
                                              })
                                          }, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Are you sure you would like to unmerge this error?")]))])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                              undefined,
                                                              undefined,
                                                              (function (param) {
                                                                  var onSuccess$1 = function (param) {
                                                                    InboxMetrics$BsConsole.send(/* UnmergeSuccess */2);
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully unmerged error."));
                                                                    return Curry._1(onSuccess, undefined);
                                                                  };
                                                                  var onFailure = function (param) {
                                                                    InboxMetrics$BsConsole.send(/* UnmergeFailure */3);
                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to unmerge error."));
                                                                  };
                                                                  if (CoronerdGate$BsConsole.mergeUnmergeEndpoint) {
                                                                    return InboxMergerError$BsConsole.unmergeErrors(token, onSuccess$1, onFailure, Belt_List.toArray(fingerprints), name, undefined);
                                                                  } else {
                                                                    return InboxMergerError$BsConsole.deprecatedUnmergeErrors(token, onSuccess$1, onFailure, fingerprints, name, undefined);
                                                                  }
                                                                }),
                                                              undefined,
                                                              undefined,
                                                              /* Outlined */-28821822,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [I18N$BsConsole.show(undefined, "Unmerge")]
                                                            ]))])));
                              }), undefined, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
