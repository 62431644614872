// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Task$BsConsole = require("./task.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ApiBillingLimits$BsConsole = require("./ApiBillingLimits.js");

var ParseError = Caml_exceptions.create("BillingLimitsFetch-BsConsole.ParseError");

function createTask(universeName) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/limits"]), undefined, /* Get */0, undefined, (function (param) {
                var limits = ApiBillingLimits$BsConsole.ofJson(universeName, param.json);
                return /* Ok */Block.__(0, [limits]);
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function use(universeName, token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var onSuccess = function (limits) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [limits]);
                }));
  };
  var onFailure = function (msg) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [msg]);
                }));
  };
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              var arg = createTask(universeName);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  var update = function (param) {
    return Curry._1(setRemote, (function (param) {
                  return /* NotAsked */0;
                }));
  };
  return /* tuple */[
          remote,
          update
        ];
}

var component = ReasonReact.reducerComponent("BillingLimitsFetch-BsConsole");

function make(universeName, handleTask, token, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Request */0);
              };
              return Curry._2(children, self.state.remote, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              return Curry._2(handleTask, (function (j) {
                                            try {
                                              var json = TaskCallback$BsConsole.getJson(j);
                                              var limits = ApiBillingLimits$BsConsole.ofJson(universeName, json);
                                              return Curry._1(self.send, /* Success */Block.__(0, [limits]));
                                            }
                                            catch (raw_msg){
                                              var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                                              if (msg[0] === Json_decode.DecodeError) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [msg[1]]));
                                              } else {
                                                return Curry._1(self.send, /* Failure */Block.__(1, ["Failed to fetch"]));
                                              }
                                            }
                                          }), Task$BsConsole.makeTask(/* BillingLimits */Block.__(21, [token])));
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.ParseError = ParseError;
exports.createTask = createTask;
exports.responseCb = responseCb;
exports.use = use;
exports.component = component;
exports.make = make;
/* component Not a pure module */
