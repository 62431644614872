// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Union$BsConsole = require("./forms/Union.js");
var Colors$BsConsole = require("../../Colors.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var BtModal$BsConsole = require("../../utilities/BtModal.js");
var SSAGroup$BsConsole = require("./SSAGroup.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SetValue$BsConsole = require("./forms/SetValue.js");
var SSAAction$BsConsole = require("./SSAAction.js");
var SSAMetrics$BsConsole = require("./SSAMetrics.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Switch = require("@material-ui/core/Switch");
var Close = require("@material-ui/icons/Close");
var SSAFormElements$BsConsole = require("./SSAFormElements.js");
var BtTypeaheadInput$BsConsole = require("../../utilities/BtTypeaheadInput.js");
var SSAStringComparison$BsConsole = require("./SSAStringComparison.js");

var selectStyle = Css.style(/* :: */[
      Css.minWidth(Css.px(250)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
        /* :: */[
          Css.paddingLeft(Css.px(10)),
          /* :: */[
            Css.borderRadius(Css.px(3)),
            /* :: */[
              Css.height(Css.px(35)),
              /* :: */[
                Css.marginBottom(Css.px(8)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var default_unionValues = [undefined];

var default_probability = {
  formStr: "100",
  value: 1
};

var $$default = {
  source: undefined,
  conditions: undefined,
  setAttributeKey: undefined,
  setAttributeValue: undefined,
  unionKey: undefined,
  unionValues: default_unionValues,
  probability: default_probability
};

function SSACreateModal(Props) {
  var token = Props.token;
  var project = Props.project;
  var close = Props.close;
  var formOpen = Props.formOpen;
  var formTitle = Props.formTitle;
  var formType = Props.formType;
  var actions = Props.actions;
  var refetchActions = Props.refetchActions;
  var allAttributes = Props.allAttributes;
  var allLabelAttributes = Props.allLabelAttributes;
  var allUsers = Props.allUsers;
  var match = React.useState((function () {
          return $$default;
        }));
  var setInput = match[1];
  var input = match[0];
  var handleClose = function (param) {
    Curry._1(setInput, (function (param) {
            return $$default;
          }));
    return Curry._1(close, undefined);
  };
  React.useEffect((function () {
          Curry._1(setInput, (function (param) {
                  return $$default;
                }));
          
        }), [formType]);
  var createAction = function (param) {
    var newAction = Curry._1(formType.toAction, input);
    var allActions = SSAGroup$BsConsole.addNewAction(actions, Belt_Option.getExn(newAction));
    if (SSAGroup$BsConsole.hasNoActions(actions)) {
      var arg = BpgTask$BsConsole.ServerSideActions.create({
            project: project.pid,
            configuration: SSAAction$BsConsole.toJsonString(allActions),
            enabled: 1,
            __state: undefined,
            __create: undefined,
            __modify: undefined
          });
      return Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), true, (function (results) {
                    if (results.tag) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                    }
                    Curry._1(refetchActions, undefined);
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.ssaCreatedText));
                  }));
    }
    var arg$1 = BpgTask$BsConsole.ServerSideActions.updateFieldsByKey({
          project: project.pid
        }, [/* tuple */[
            "configuration",
            SSAAction$BsConsole.toJsonString(allActions)
          ]]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                      });
                  })(token), true, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, results[0])));
                  }
                  Curry._1(refetchActions, undefined);
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SSAUtils$BsConsole.ssaCreatedText));
                }));
  };
  if (formTitle !== undefined) {
    return React.createElement(BtModal$BsConsole.Default.make, {
                open: formOpen,
                onClose: handleClose,
                modalWidth: 500,
                title: formTitle,
                subtitle: "",
                divider: true,
                className: Css.style(/* :: */[
                      Css.selector("> div > div", /* :: */[
                            Css.important(Css.overflowY(/* visible */589592690)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]),
                children: React.createElement(Col2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.height(Css.pct(100)),
                            /* [] */0
                          ]),
                      children: null
                    }, Belt_Array.mapWithIndex(formType.formItems, (function (index, formItem) {
                            var tmp;
                            if (typeof formItem === "number") {
                              switch (formItem) {
                                case /* SetAttribute */0 :
                                    var tmp$1;
                                    if (Belt_Option.getWithDefault(input.setAttributeKey, "") === SetValue$BsConsole.userKey) {
                                      tmp$1 = React.createElement(Col2$BsConsole.make, {
                                            alignItems: /* flexStart */662439529,
                                            className: Css.style(/* :: */[
                                                  Css.width(Css.pct(100)),
                                                  /* [] */0
                                                ]),
                                            children: React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                                  selectedItem: input.setAttributeValue,
                                                  toString: Util$BsConsole.identity,
                                                  inputLabel: "",
                                                  placeholder: SSAUtils$BsConsole.setAssigneeText,
                                                  noItemsMessage: SSAUtils$BsConsole.noUsersText,
                                                  items: allUsers,
                                                  itemFilter: (function (param) {
                                                      return true;
                                                    }),
                                                  pixelWidth: 408,
                                                  handleItemSelection: (function (item) {
                                                      var updated_source = input.source;
                                                      var updated_conditions = input.conditions;
                                                      var updated_setAttributeKey = input.setAttributeKey;
                                                      var updated_setAttributeValue = item;
                                                      var updated_unionKey = input.unionKey;
                                                      var updated_unionValues = input.unionValues;
                                                      var updated_probability = input.probability;
                                                      var updated = {
                                                        source: updated_source,
                                                        conditions: updated_conditions,
                                                        setAttributeKey: updated_setAttributeKey,
                                                        setAttributeValue: updated_setAttributeValue,
                                                        unionKey: updated_unionKey,
                                                        unionValues: updated_unionValues,
                                                        probability: updated_probability
                                                      };
                                                      return Curry._1(setInput, (function (param) {
                                                                    return updated;
                                                                  }));
                                                    })
                                                })
                                          });
                                    } else {
                                      var value = input.setAttributeValue;
                                      tmp$1 = React.createElement(SSAFormElements$BsConsole.TextInput.make, {
                                            label: SSAUtils$BsConsole.valueText,
                                            placeholder: SSAUtils$BsConsole.enterAttrText,
                                            value: value !== undefined ? value : "",
                                            onChange: (function ($$event) {
                                                var value = $$event.target.value;
                                                var updated_source = input.source;
                                                var updated_conditions = input.conditions;
                                                var updated_setAttributeKey = input.setAttributeKey;
                                                var updated_setAttributeValue = value;
                                                var updated_unionKey = input.unionKey;
                                                var updated_unionValues = input.unionValues;
                                                var updated_probability = input.probability;
                                                var updated = {
                                                  source: updated_source,
                                                  conditions: updated_conditions,
                                                  setAttributeKey: updated_setAttributeKey,
                                                  setAttributeValue: updated_setAttributeValue,
                                                  unionKey: updated_unionKey,
                                                  unionValues: updated_unionValues,
                                                  probability: updated_probability
                                                };
                                                return Curry._1(setInput, (function (param) {
                                                              return updated;
                                                            }));
                                              }),
                                            className: Css.style(/* :: */[
                                                  Css.marginBottom(Css.px(24)),
                                                  /* [] */0
                                                ])
                                          });
                                    }
                                    tmp = React.createElement(Col2$BsConsole.make, {
                                          alignItems: /* flexStart */662439529,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Body2 */9,
                                              className: SSAFormElements$BsConsole.helpTextStyle,
                                              children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.defineAttrToSetText)
                                            }), React.createElement(Col2$BsConsole.make, {
                                              alignItems: /* flexStart */662439529,
                                              className: Css.style(/* :: */[
                                                    Css.marginBottom(Css.px(12)),
                                                    /* [] */0
                                                  ]),
                                              children: null
                                            }, React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Subtitle2 */7,
                                                  className: SSAFormElements$BsConsole.labelStyle,
                                                  children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.attributeText)
                                                }), React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                                  selectedItem: input.setAttributeKey,
                                                  toString: Util$BsConsole.identity,
                                                  inputLabel: "",
                                                  placeholder: SSAUtils$BsConsole.selectAttributeText,
                                                  noItemsMessage: SSAUtils$BsConsole.noAttributesText,
                                                  items: Belt_Array.concat([SetValue$BsConsole.userKey], allAttributes),
                                                  itemFilter: (function (param) {
                                                      return true;
                                                    }),
                                                  pixelWidth: 204,
                                                  handleItemSelection: (function (item) {
                                                      var updated_source = input.source;
                                                      var updated_conditions = input.conditions;
                                                      var updated_setAttributeKey = item;
                                                      var updated_setAttributeValue = input.setAttributeValue;
                                                      var updated_unionKey = input.unionKey;
                                                      var updated_unionValues = input.unionValues;
                                                      var updated_probability = input.probability;
                                                      var updated = {
                                                        source: updated_source,
                                                        conditions: updated_conditions,
                                                        setAttributeKey: updated_setAttributeKey,
                                                        setAttributeValue: updated_setAttributeValue,
                                                        unionKey: updated_unionKey,
                                                        unionValues: updated_unionValues,
                                                        probability: updated_probability
                                                      };
                                                      return Curry._1(setInput, (function (param) {
                                                                    return updated;
                                                                  }));
                                                    })
                                                })), tmp$1);
                                    break;
                                case /* SetUnionAttribute */1 :
                                    var match = input.source;
                                    tmp = React.createElement(Col2$BsConsole.make, {
                                          alignItems: /* flexStart */662439529,
                                          children: null
                                        }, React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Body2 */9,
                                              className: SSAFormElements$BsConsole.helpTextStyle,
                                              children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.defineAttrToUnionText)
                                            }), React.createElement(Col2$BsConsole.make, {
                                              alignItems: /* flexStart */662439529,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              children: null
                                            }, React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Subtitle2 */7,
                                                  className: SSAFormElements$BsConsole.labelStyle,
                                                  children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.attributeText)
                                                }), React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                                  selectedItem: match !== undefined && typeof match !== "number" ? match[0] : undefined,
                                                  toString: Util$BsConsole.identity,
                                                  placeholder: SSAUtils$BsConsole.selectAttributeText,
                                                  noItemsMessage: SSAUtils$BsConsole.noAttributesText,
                                                  items: Belt_Array.concat(allLabelAttributes, [Union$BsConsole.tagsAttribute]),
                                                  itemFilter: (function (param) {
                                                      return true;
                                                    }),
                                                  pixelWidth: 192,
                                                  handleItemSelection: (function (item) {
                                                      var updated_source = input.source;
                                                      var updated_conditions = input.conditions;
                                                      var updated_setAttributeKey = input.setAttributeKey;
                                                      var updated_setAttributeValue = input.setAttributeValue;
                                                      var updated_unionKey = item;
                                                      var updated_unionValues = input.unionValues;
                                                      var updated_probability = input.probability;
                                                      var updated = {
                                                        source: updated_source,
                                                        conditions: updated_conditions,
                                                        setAttributeKey: updated_setAttributeKey,
                                                        setAttributeValue: updated_setAttributeValue,
                                                        unionKey: updated_unionKey,
                                                        unionValues: updated_unionValues,
                                                        probability: updated_probability
                                                      };
                                                      return Curry._1(setInput, (function (param) {
                                                                    return updated;
                                                                  }));
                                                    })
                                                })), React.createElement(Col2$BsConsole.make, {
                                              children: Belt_Array.mapWithIndex(input.unionValues, (function (index, unionValue) {
                                                      return React.createElement(Row2$BsConsole.make, {
                                                                  justifyContent: /* flexStart */662439529,
                                                                  children: null
                                                                }, React.createElement(SSAFormElements$BsConsole.TextInput.make, {
                                                                      label: "",
                                                                      placeholder: SSAUtils$BsConsole.enterValueText,
                                                                      value: unionValue !== undefined ? unionValue : "",
                                                                      onChange: (function ($$event) {
                                                                          var value = $$event.target.value;
                                                                          var updatedUnionValues = Belt_Array.mapWithIndex(input.unionValues, (function (i, v) {
                                                                                  if (i === index) {
                                                                                    return value;
                                                                                  } else {
                                                                                    return v;
                                                                                  }
                                                                                }));
                                                                          var updated_source = input.source;
                                                                          var updated_conditions = input.conditions;
                                                                          var updated_setAttributeKey = input.setAttributeKey;
                                                                          var updated_setAttributeValue = input.setAttributeValue;
                                                                          var updated_unionKey = input.unionKey;
                                                                          var updated_probability = input.probability;
                                                                          var updated = {
                                                                            source: updated_source,
                                                                            conditions: updated_conditions,
                                                                            setAttributeKey: updated_setAttributeKey,
                                                                            setAttributeValue: updated_setAttributeValue,
                                                                            unionKey: updated_unionKey,
                                                                            unionValues: updatedUnionValues,
                                                                            probability: updated_probability
                                                                          };
                                                                          return Curry._1(setInput, (function (param) {
                                                                                        return updated;
                                                                                      }));
                                                                        })
                                                                    }), index === 0 ? null : React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                                        ariaLabel: SSAUtils$BsConsole.removeText,
                                                                        onClick: (function (param) {
                                                                            var updatedUnionValues = Belt_Array.keepWithIndex(input.unionValues, (function (param, i) {
                                                                                    return i !== index;
                                                                                  }));
                                                                            var updated_source = input.source;
                                                                            var updated_conditions = input.conditions;
                                                                            var updated_setAttributeKey = input.setAttributeKey;
                                                                            var updated_setAttributeValue = input.setAttributeValue;
                                                                            var updated_unionKey = input.unionKey;
                                                                            var updated_probability = input.probability;
                                                                            var updated = {
                                                                              source: updated_source,
                                                                              conditions: updated_conditions,
                                                                              setAttributeKey: updated_setAttributeKey,
                                                                              setAttributeValue: updated_setAttributeValue,
                                                                              unionKey: updated_unionKey,
                                                                              unionValues: updatedUnionValues,
                                                                              probability: updated_probability
                                                                            };
                                                                            return Curry._1(setInput, (function (param) {
                                                                                          return updated;
                                                                                        }));
                                                                          }),
                                                                        title_tooltip: SSAUtils$BsConsole.removeText,
                                                                        children: React.createElement(Close.default, { })
                                                                      }));
                                                    }))
                                            }), React.createElement(Button.default, {
                                              variant: "text",
                                              color: "primary",
                                              onClick: (function (param) {
                                                  var updatedUnionValues = Belt_Array.concat(input.unionValues, [undefined]);
                                                  var updated_source = input.source;
                                                  var updated_conditions = input.conditions;
                                                  var updated_setAttributeKey = input.setAttributeKey;
                                                  var updated_setAttributeValue = input.setAttributeValue;
                                                  var updated_unionKey = input.unionKey;
                                                  var updated_probability = input.probability;
                                                  var updated = {
                                                    source: updated_source,
                                                    conditions: updated_conditions,
                                                    setAttributeKey: updated_setAttributeKey,
                                                    setAttributeValue: updated_setAttributeValue,
                                                    unionKey: updated_unionKey,
                                                    unionValues: updatedUnionValues,
                                                    probability: updated_probability
                                                  };
                                                  return Curry._1(setInput, (function (param) {
                                                                return updated;
                                                              }));
                                                }),
                                              children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.addAnotherText)
                                            }));
                                    break;
                                case /* SetExecutionProbability */2 :
                                    tmp = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.57.25") ? React.createElement(SSAFormElements$BsConsole.RangeSlider.make, {
                                            value: input.probability.formStr,
                                            onChange: (function (param) {
                                                var value = param[0];
                                                var updated_source = input.source;
                                                var updated_conditions = input.conditions;
                                                var updated_setAttributeKey = input.setAttributeKey;
                                                var updated_setAttributeValue = input.setAttributeValue;
                                                var updated_unionKey = input.unionKey;
                                                var updated_unionValues = input.unionValues;
                                                var updated_probability = {
                                                  formStr: value,
                                                  value: param[1] ? Caml_format.caml_float_of_string(value) / 100 : undefined
                                                };
                                                var updated = {
                                                  source: updated_source,
                                                  conditions: updated_conditions,
                                                  setAttributeKey: updated_setAttributeKey,
                                                  setAttributeValue: updated_setAttributeValue,
                                                  unionKey: updated_unionKey,
                                                  unionValues: updated_unionValues,
                                                  probability: updated_probability
                                                };
                                                return Curry._1(setInput, (function (param) {
                                                              return updated;
                                                            }));
                                              }),
                                            min: 0,
                                            max: 100,
                                            step: 1
                                          }) : null;
                                    break;
                                
                              }
                            } else if (formItem.tag) {
                              var conditions = input.conditions;
                              var tmp$2;
                              if (conditions !== undefined) {
                                var match$1 = Belt_Array.get(conditions, 0);
                                if (match$1 !== undefined) {
                                  if (match$1.tag) {
                                    var match$2 = SSAUtils$BsConsole.getConditionValue(input.conditions);
                                    var tmp$3;
                                    tmp$3 = match$2 !== undefined && match$2.tag ? match$2[0] : SSAStringComparison$BsConsole.$$default;
                                    tmp$2 = React.createElement(Row2$BsConsole.make, {
                                          alignItems: /* flexStart */662439529,
                                          justifyContent: /* flexStart */662439529,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement(SSAFormElements$BsConsole.DropdownSelect.make, {
                                              label: "",
                                              options: SSAStringComparison$BsConsole.options,
                                              optionToString: SSAStringComparison$BsConsole.toLabel,
                                              selectedOption: tmp$3,
                                              handleChange: (function ($$event, param) {
                                                  var value = $$event.target.value;
                                                  var conditions = input.conditions;
                                                  var updated;
                                                  if (conditions !== undefined) {
                                                    var match = Belt_Array.get(conditions, 0);
                                                    updated = match !== undefined ? (
                                                        match.tag ? [/* StringComparison */Block.__(1, [
                                                                value,
                                                                match[1]
                                                              ])] : []
                                                      ) : [/* StringComparison */Block.__(1, [
                                                            SSAStringComparison$BsConsole.$$default,
                                                            value
                                                          ])];
                                                  } else {
                                                    updated = [/* StringComparison */Block.__(1, [
                                                          SSAStringComparison$BsConsole.$$default,
                                                          value
                                                        ])];
                                                  }
                                                  return Curry._1(setInput, (function (param) {
                                                                return {
                                                                        source: input.source,
                                                                        conditions: updated,
                                                                        setAttributeKey: input.setAttributeKey,
                                                                        setAttributeValue: input.setAttributeValue,
                                                                        unionKey: input.unionKey,
                                                                        unionValues: input.unionValues,
                                                                        probability: input.probability
                                                                      };
                                                              }));
                                                }),
                                              className: Css.style(/* :: */[
                                                    Css.marginBottom(Css.px(5)),
                                                    /* :: */[
                                                      Css.marginRight(Css.px(12)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              formWidth: 192
                                            }), React.createElement(SSAFormElements$BsConsole.TextInput.make, {
                                              label: "",
                                              placeholder: SSAUtils$BsConsole.enterValueText,
                                              value: match$1[1],
                                              onChange: (function ($$event) {
                                                  var value = $$event.target.value;
                                                  var conditions = input.conditions;
                                                  var updated;
                                                  if (conditions !== undefined) {
                                                    var match = Belt_Array.get(conditions, 0);
                                                    updated = match !== undefined ? (
                                                        match.tag ? [/* StringComparison */Block.__(1, [
                                                                match[0],
                                                                value
                                                              ])] : []
                                                      ) : [/* StringComparison */Block.__(1, [
                                                            SSAStringComparison$BsConsole.$$default,
                                                            value
                                                          ])];
                                                  } else {
                                                    updated = [/* StringComparison */Block.__(1, [
                                                          SSAStringComparison$BsConsole.$$default,
                                                          value
                                                        ])];
                                                  }
                                                  return Curry._1(setInput, (function (param) {
                                                                return {
                                                                        source: input.source,
                                                                        conditions: updated,
                                                                        setAttributeKey: input.setAttributeKey,
                                                                        setAttributeValue: input.setAttributeValue,
                                                                        unionKey: input.unionKey,
                                                                        unionValues: input.unionValues,
                                                                        probability: input.probability
                                                                      };
                                                              }));
                                                }),
                                              className: Css.style(/* :: */[
                                                    Css.marginBottom(Css.px(24)),
                                                    /* :: */[
                                                      Css.marginRight(Css.px(18)),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }));
                                  } else {
                                    tmp$2 = React.createElement(Row2$BsConsole.make, {
                                          alignItems: /* flexStart */662439529,
                                          justifyContent: /* flexStart */662439529,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement(SSAFormElements$BsConsole.TextInput.make, {
                                              label: "",
                                              placeholder: SSAUtils$BsConsole.enterRegExpText,
                                              value: match$1[0],
                                              onChange: (function ($$event) {
                                                  var value = $$event.target.value;
                                                  var conditions = input.conditions;
                                                  var updated;
                                                  if (conditions !== undefined) {
                                                    var match = Belt_Array.get(conditions, 0);
                                                    updated = match !== undefined ? (
                                                        match.tag ? [] : [/* RegEx */Block.__(0, [
                                                                value,
                                                                match[1]
                                                              ])]
                                                      ) : [/* RegEx */Block.__(0, [
                                                            value,
                                                            false
                                                          ])];
                                                  } else {
                                                    updated = [/* RegEx */Block.__(0, [
                                                          value,
                                                          false
                                                        ])];
                                                  }
                                                  return Curry._1(setInput, (function (param) {
                                                                return {
                                                                        source: input.source,
                                                                        conditions: updated,
                                                                        setAttributeKey: input.setAttributeKey,
                                                                        setAttributeValue: input.setAttributeValue,
                                                                        unionKey: input.unionKey,
                                                                        unionValues: input.unionValues,
                                                                        probability: input.probability
                                                                      };
                                                              }));
                                                }),
                                              className: Css.style(/* :: */[
                                                    Css.marginBottom(Css.px(24)),
                                                    /* :: */[
                                                      Css.marginRight(Css.px(18)),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }), React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              justifyContent: /* center */98248149,
                                              children: null
                                            }, React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginTop(Css.px(5)),
                                                        /* [] */0
                                                      ])
                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                      variant: /* Subtitle2 */7,
                                                      className: SSAFormElements$BsConsole.labelStyle,
                                                      children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.inverseText2)
                                                    })), React.createElement("div", undefined, React.createElement(Switch.default, {
                                                      id: "regex-inverse",
                                                      color: "primary",
                                                      onChange: (function (param, param$1) {
                                                          var conditions = input.conditions;
                                                          var updated;
                                                          if (conditions !== undefined) {
                                                            var match = Belt_Array.get(conditions, 0);
                                                            updated = match !== undefined ? (
                                                                match.tag ? [] : [/* RegEx */Block.__(0, [
                                                                        match[0],
                                                                        !match[1]
                                                                      ])]
                                                              ) : [/* RegEx */Block.__(0, [
                                                                    "",
                                                                    true
                                                                  ])];
                                                          } else {
                                                            updated = [/* RegEx */Block.__(0, [
                                                                  "",
                                                                  true
                                                                ])];
                                                          }
                                                          return Curry._1(setInput, (function (param) {
                                                                        return {
                                                                                source: input.source,
                                                                                conditions: updated,
                                                                                setAttributeKey: input.setAttributeKey,
                                                                                setAttributeValue: input.setAttributeValue,
                                                                                unionKey: input.unionKey,
                                                                                unionValues: input.unionValues,
                                                                                probability: input.probability
                                                                              };
                                                                      }));
                                                        }),
                                                      checked: match$1[1]
                                                    }))));
                                  }
                                } else {
                                  tmp$2 = null;
                                }
                              } else {
                                tmp$2 = null;
                              }
                              tmp = React.createElement(React.Fragment, undefined, React.createElement(SSAFormElements$BsConsole.DropdownSelect.make, {
                                        label: formItem[0],
                                        options: [
                                          /* RegEx */Block.__(0, [
                                              "",
                                              false
                                            ]),
                                          /* StringComparison */Block.__(1, [
                                              SSAStringComparison$BsConsole.$$default,
                                              ""
                                            ])
                                        ],
                                        optionToString: SSAAction$BsConsole.conditionToLabel,
                                        selectedOption: SSAUtils$BsConsole.getConditionValue(input.conditions),
                                        handleChange: (function ($$event, param) {
                                            var value = $$event.target.value;
                                            var updated_source = input.source;
                                            var updated_conditions = [value];
                                            var updated_setAttributeKey = input.setAttributeKey;
                                            var updated_setAttributeValue = input.setAttributeValue;
                                            var updated_unionKey = input.unionKey;
                                            var updated_unionValues = input.unionValues;
                                            var updated_probability = input.probability;
                                            var updated = {
                                              source: updated_source,
                                              conditions: updated_conditions,
                                              setAttributeKey: updated_setAttributeKey,
                                              setAttributeValue: updated_setAttributeValue,
                                              unionKey: updated_unionKey,
                                              unionValues: updated_unionValues,
                                              probability: updated_probability
                                            };
                                            return Curry._1(setInput, (function (param) {
                                                          return updated;
                                                        }));
                                          }),
                                        className: Css.style(/* :: */[
                                              Css.marginBottom(Css.px(5)),
                                              /* [] */0
                                            ]),
                                        formWidth: 408,
                                        placeholder: SSAUtils$BsConsole.selectConditionText
                                      }), tmp$2);
                            } else {
                              var match$3 = formItem[0];
                              var optionHelpText = match$3[1];
                              var match$4 = input.source;
                              var match$5 = input.source;
                              var match$6 = input.source;
                              var tmp$4;
                              if (match$6 !== undefined && typeof match$6 !== "number") {
                                var match$7 = input.source;
                                tmp$4 = React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                                      selectedItem: match$7 !== undefined && typeof match$7 !== "number" ? match$7[0] : undefined,
                                      toString: Util$BsConsole.identity,
                                      placeholder: SSAUtils$BsConsole.selectAttributeText,
                                      noItemsMessage: SSAUtils$BsConsole.noAttributesText,
                                      items: allAttributes,
                                      itemFilter: (function (param) {
                                          return true;
                                        }),
                                      pixelWidth: 192,
                                      handleItemSelection: (function (item) {
                                          var updated_source = /* Attribute */[item];
                                          var updated_conditions = input.conditions;
                                          var updated_setAttributeKey = input.setAttributeKey;
                                          var updated_setAttributeValue = input.setAttributeValue;
                                          var updated_unionKey = input.unionKey;
                                          var updated_unionValues = input.unionValues;
                                          var updated_probability = input.probability;
                                          var updated = {
                                            source: updated_source,
                                            conditions: updated_conditions,
                                            setAttributeKey: updated_setAttributeKey,
                                            setAttributeValue: updated_setAttributeValue,
                                            unionKey: updated_unionKey,
                                            unionValues: updated_unionValues,
                                            probability: updated_probability
                                          };
                                          return Curry._1(setInput, (function (param) {
                                                        return updated;
                                                      }));
                                        })
                                    });
                              } else {
                                tmp$4 = null;
                              }
                              tmp = React.createElement(React.Fragment, undefined, optionHelpText !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body2 */9,
                                          className: SSAFormElements$BsConsole.helpTextStyle,
                                          children: I18N$BsConsole.showSkip(optionHelpText)
                                        }) : null, React.createElement(Row2$BsConsole.make, {
                                        alignItems: /* center */98248149,
                                        justifyContent: /* center */98248149,
                                        children: null
                                      }, React.createElement(SSAFormElements$BsConsole.DropdownSelect.make, {
                                            label: match$3[0],
                                            options: [
                                              /* Attribute */[""],
                                              /* Module */0,
                                              /* Callstack */1
                                            ],
                                            optionToString: SSAAction$BsConsole.sourceToLabel,
                                            selectedOption: input.source,
                                            handleChange: (function ($$event, param) {
                                                var value = $$event.target.value;
                                                var updated_conditions = input.conditions;
                                                var updated_setAttributeKey = input.setAttributeKey;
                                                var updated_setAttributeValue = input.setAttributeValue;
                                                var updated_unionKey = input.unionKey;
                                                var updated_unionValues = input.unionValues;
                                                var updated_probability = input.probability;
                                                var updated = {
                                                  source: value,
                                                  conditions: updated_conditions,
                                                  setAttributeKey: updated_setAttributeKey,
                                                  setAttributeValue: updated_setAttributeValue,
                                                  unionKey: updated_unionKey,
                                                  unionValues: updated_unionValues,
                                                  probability: updated_probability
                                                };
                                                return Curry._1(setInput, (function (param) {
                                                              return updated;
                                                            }));
                                              }),
                                            className: match$4 !== undefined && typeof match$4 !== "number" ? Css.style(/* :: */[
                                                    Css.marginRight(Css.px(12)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(5)),
                                                      /* [] */0
                                                    ]
                                                  ]) : Css.style(/* :: */[
                                                    Css.marginBottom(Css.px(5)),
                                                    /* [] */0
                                                  ]),
                                            formWidth: match$5 !== undefined && typeof match$5 !== "number" ? 200 : 408,
                                            placeholder: SSAUtils$BsConsole.selectSourceText
                                          }), tmp$4));
                            }
                            return React.createElement(React.Fragment, {
                                        children: tmp,
                                        key: index
                                      });
                          })), React.createElement(Row2$BsConsole.make, {
                          justifyContent: /* spaceBetween */516682146,
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.marginTop(Css.px(24)),
                                  /* [] */0
                                ]
                              ]),
                          children: null
                        }, React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: !Curry._1(formType.isComplete, input),
                              onClick: (function (param) {
                                  SSAMetrics$BsConsole.send(/* ActionCreate */0);
                                  createAction(undefined);
                                  Curry._1(setInput, (function (param) {
                                          return $$default;
                                        }));
                                  return Curry._1(close, undefined);
                                }),
                              children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.createText)
                            }), React.createElement(Button.default, {
                              variant: "text",
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(close, undefined);
                                }),
                              children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.cancelText)
                            })))
              });
  } else {
    return null;
  }
}

var pixelWidth = 408;

var middleMargin = 12;

var make = SSACreateModal;

exports.selectStyle = selectStyle;
exports.pixelWidth = pixelWidth;
exports.middleMargin = middleMargin;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.make = make;
/* selectStyle Not a pure module */
