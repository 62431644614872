// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var BaseRenderer$BsConsole = require("./BaseRenderer.js");

function createStringFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  value
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  value
                ]])
          ]
        ];
}

function Ipv4(Props) {
  var value = Props.value;
  var attribute = Props.attribute;
  return React.createElement(BaseRenderer$BsConsole.make, {
              alignment: /* Right */-57574468,
              textValue: Util$BsConsole.formatIpv4(value),
              filterOptions: createStringFilterOptions(attribute, value),
              children: I18N$BsConsole.showSkip(Util$BsConsole.formatIpv4(value))
            });
}

var make = Ipv4;

exports.createStringFilterOptions = createStringFilterOptions;
exports.make = make;
/* react Not a pure module */
