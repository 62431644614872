// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var ApiSaml$BsConsole = require("./ApiSaml.js");
var BpgTask$BsConsole = require("./BpgTask.js");

var ParseError = Caml_exceptions.create("FetchSaml-BsConsole.ParseError");

var UnknownSvcError = Caml_exceptions.create("FetchSaml-BsConsole.UnknownSvcError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === Task2$BsConsole.ErrorMsg ? "Error: " + error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function createPost(serviceEndpoint, host, token, payload) {
  var url = serviceEndpoint + ("/?token=" + (token + ("&host=" + host)));
  return Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [payload]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  return ApiSaml$BsConsole.$$Response.decode(param.json);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function modifyTenantTask(serviceEndpoint, host, token, tenant) {
  var payload = Json_encode.object_(/* :: */[
        /* tuple */[
          "action",
          "modify"
        ],
        /* :: */[
          /* tuple */[
            "form",
            ApiSaml$BsConsole.Tenant.encode(tenant)
          ],
          /* [] */0
        ]
      ]);
  return createPost(serviceEndpoint, host, token, payload);
}

function createTenantTask(serviceEndpoint, host, token, tenant) {
  var payload = Json_encode.object_(/* :: */[
        /* tuple */[
          "action",
          "create"
        ],
        /* :: */[
          /* tuple */[
            "form",
            ApiSaml$BsConsole.Tenant.encode(tenant)
          ],
          /* [] */0
        ]
      ]);
  return createPost(serviceEndpoint, host, token, payload);
}

function configTask(serviceEndpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + "/config"]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  return ApiSaml$BsConsole.$$Response.decode(param.json);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function getTenantConfig(serviceEndpoint, host) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/?host=" + host)]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var response = ApiSaml$BsConsole.decode(param.json);
                  return /* Ok */Block.__(0, [response]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function useConfig(serviceEndpoint, token) {
  var task = configTask(serviceEndpoint);
  return BpgTask$BsConsole.use(token, task);
}

function useGetTenant(serviceEndpoint, token, host) {
  var task = getTenantConfig(serviceEndpoint, host);
  return BpgTask$BsConsole.use(token, task);
}

exports.ParseError = ParseError;
exports.UnknownSvcError = UnknownSvcError;
exports.responseCb = responseCb;
exports.createPost = createPost;
exports.modifyTenantTask = modifyTenantTask;
exports.createTenantTask = createTenantTask;
exports.configTask = configTask;
exports.getTenantConfig = getTenantConfig;
exports.useConfig = useConfig;
exports.useGetTenant = useGetTenant;
/* Task2-BsConsole Not a pure module */
