// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function ofJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          cursor: Json_decode.field("cursor", Json_decode.$$int, json),
          n_objects: Json_decode.field("n_objects", Json_decode.$$int, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          path: Json_decode.optional((function (param) {
                  return Json_decode.field("path", Json_decode.string, param);
                }), json)
        };
}

var Reload = {
  ofJson: ofJson
};

function ofJson$1(json) {
  return {
          reloads: Json_decode.field("reloads", (function (param) {
                  return Json_decode.dict(ofJson, param);
                }), json),
          completed_reloads: Json_decode.field("completed_reloads", (function (param) {
                  return Json_decode.list(ofJson, param);
                }), json)
        };
}

var Control = {
  ofJson: ofJson$1
};

function ofJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          result: Json_decode.field("result", Json_decode.string, json)
        };
}

var ReloadResp = {
  ofJson: ofJson$2
};

exports.Reload = Reload;
exports.Control = Control;
exports.ReloadResp = ReloadResp;
/* No side effect */
