// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var MetricsEvent$BsConsole = require("./MetricsEvent.js");

function toEvent(action) {
  switch (action.tag | 0) {
    case /* CtaClick */0 :
        var match = action[0];
        return /* GenericEvent */Block.__(2, [{
                    name: match.nameSpace + "cta_click",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "message",
                                match.details
                              ],
                              /* [] */0
                            ]))
                  }]);
    case /* DismissClick */1 :
        var match$1 = action[0];
        return /* GenericEvent */Block.__(2, [{
                    name: match$1.nameSpace + "dismiss_click",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "message",
                                match$1.details
                              ],
                              /* [] */0
                            ]))
                  }]);
    case /* Impression */2 :
        var match$2 = action[0];
        return /* GenericEvent */Block.__(2, [{
                    name: match$2.nameSpace + "impression",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "message",
                                match$2.details
                              ],
                              /* [] */0
                            ]))
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

var Send = {
  toEvent: toEvent,
  send: send
};

exports.Send = Send;
/* MetricsEvent-BsConsole Not a pure module */
