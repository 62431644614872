// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Col2$BsConsole = require("./components/Col2.js");
var Gate$BsConsole = require("./Gate.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var Launch = require("@material-ui/icons/Launch");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");

var container = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
        /* :: */[
          Css.borderRadius(Css.px(4)),
          /* :: */[
            Css.width(Css.px(250)),
            /* :: */[
              Css.padding(Css.rem(1)),
              /* :: */[
                Css.marginRight(Css.rem(1)),
                /* :: */[
                  Css.alignSelf(/* flexStart */662439529),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var section = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* :: */[
        Css.selector("&:last-of-type", /* :: */[
              Css.marginBottom(/* zero */-789508312),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var sectionHeader = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  500
                ]),
            /* :: */[
              Css.padding2(Css.px(4), /* zero */-789508312),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey2)),
                /* [] */0
              ]
            ]
          ]),
      /* :: */[
        "section-header",
        /* [] */0
      ]
    ]);

var activeRoute = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.padding2(Css.px(4), /* zero */-789508312),
        /* [] */0
      ]
    ]);

var navItem = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey3)),
            /* [] */0
          ]),
      /* :: */[
        "nav-item",
        /* [] */0
      ]
    ]);

var navItemLink = Css.style(/* :: */[
      Css.padding2(Css.px(4), /* zero */-789508312),
      /* :: */[
        Css.hover(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.accent)),
              /* :: */[
                Css.selector(".nav-item", /* :: */[
                      Css.unsafe("color", "inherit"),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  container: container,
  section: section,
  sectionHeader: sectionHeader,
  activeRoute: activeRoute,
  navItem: navItem,
  navItemLink: navItemLink
};

function ProjectSettingsNavigation$Container(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: container
            }, children);
}

var Container = {
  make: ProjectSettingsNavigation$Container
};

function ProjectSettingsNavigation$Section(Props) {
  var children = Props.children;
  return React.createElement(Col2$BsConsole.make, {
              className: section,
              children: children
            });
}

var Section = {
  make: ProjectSettingsNavigation$Section
};

function isRouteActive(route, currentRoute) {
  return Route$BsConsole.toString(route) === Route$BsConsole.toString(currentRoute);
}

function ProjectSettingsNavigation$SectionHeader(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Subtitle1 */6,
              className: Css.merge(/* :: */[
                    sectionHeader,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: children
            });
}

var SectionHeader = {
  make: ProjectSettingsNavigation$SectionHeader
};

function ProjectSettingsNavigation$NavItem(Props) {
  var route = Props.route;
  var currentRoute = Props.currentRoute;
  var handleChangeUrl = Props.handleChangeUrl;
  var children = Props.children;
  if (isRouteActive(route, currentRoute)) {
    return React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: activeRoute,
                children: children
              });
  } else {
    return React.createElement(Link$BsConsole.Jsx3.make, {
                route: route,
                handleChangeUrl: handleChangeUrl,
                className: navItemLink,
                style: { },
                children: React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: navItem,
                      children: children
                    })
              });
  }
}

var NavItem = {
  make: ProjectSettingsNavigation$NavItem
};

function ProjectSettingsNavigation$OutsideLink(Props) {
  var href = Props.href;
  var children = Props.children;
  if (href !== undefined) {
    return React.createElement("a", {
                className: navItemLink,
                href: href,
                rel: "noopener noreferrer",
                target: "_blank"
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.merge(/* :: */[
                          navItem,
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.display(/* inlineFlex */53323314),
                                  /* :: */[
                                    Css.alignItems(/* center */98248149),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, children, React.createElement(Launch.default, {
                        color: "inherit",
                        fontSize: "inherit"
                      })));
  } else {
    return null;
  }
}

var OutsideLink = {
  make: ProjectSettingsNavigation$OutsideLink
};

function ProjectSettingsNavigation(Props) {
  var route = Props.route;
  var config = Props.config;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement(ProjectSettingsNavigation$Container, {
              children: null
            }, React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettings */Block.__(22, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Overview")
                    }), React.createElement(ProjectSettingsNavigation$OutsideLink, {
                      href: Href$BsConsole.Support.make(undefined, /* Base */1),
                      children: I18N$BsConsole.show(undefined, "Documentation")
                    }), React.createElement(ProjectSettingsNavigation$OutsideLink, {
                      href: Href$BsConsole.changelog,
                      children: I18N$BsConsole.show(undefined, "Changelog")
                    })), React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$SectionHeader, {
                      children: I18N$BsConsole.show(undefined, "Error Submission")
                    }), React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsIntegratedDocs */8),
                      children: React.createElement(ProjectSettingsNavigation$NavItem, {
                            route: /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                projectName,
                                {
                                  doc: undefined
                                }
                              ]),
                            currentRoute: route,
                            handleChangeUrl: handleChangeUrl,
                            children: I18N$BsConsole.show(undefined, "Integration guides")
                          })
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSubmissionTokens */Block.__(31, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Submission tokens")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSubmissionDataSources */Block.__(33, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Data Sources")
                    })), React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$SectionHeader, {
                      children: I18N$BsConsole.show(undefined, "Error processing")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsAttributes */Block.__(34, [
                          projectName,
                          Route$BsConsole.ProjectSettingsAttributesParams.empty
                        ]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Attributes")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsDedupeConfig */Block.__(49, [
                          projectName,
                          undefined
                        ]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Deduplication")
                    }), CoronerdGate$BsConsole.serverSideActions ? React.createElement(ProjectSettingsNavigation$NavItem, {
                        route: /* ProjectSettingsServerSideActions */Block.__(26, [projectName]),
                        currentRoute: route,
                        handleChangeUrl: handleChangeUrl,
                        children: I18N$BsConsole.show(undefined, "Submission actions")
                      }) : null, React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsReprocessing */Block.__(45, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Object reprocessing")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsScrubbers */Block.__(25, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Privacy control")
                    })), React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$SectionHeader, {
                      children: I18N$BsConsole.show(undefined, "Symbols")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbols */Block.__(39, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Symbols overview")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbolAccessTokens */Block.__(40, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Access tokens")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsMissingSymbols */Block.__(41, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Missing symbols")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbolSearch */Block.__(42, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Search")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbolsUpload */Block.__(37, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Upload an archive")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Uploaded archives")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSymbolServers */Block.__(38, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Symbol servers")
                    })), React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$SectionHeader, {
                      children: I18N$BsConsole.show(undefined, "Workflow")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsIntegrations */Block.__(35, [
                          projectName,
                          WfRouter$BsConsole.root,
                          undefined
                        ]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Integrations")
                    }), React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsAlerts */Block.__(4, [config.user])),
                      children: React.createElement(ProjectSettingsNavigation$NavItem, {
                            route: /* ProjectSettingsAlerts */Block.__(27, [
                                projectName,
                                {
                                  tab: undefined,
                                  id: undefined
                                }
                              ]),
                            currentRoute: route,
                            handleChangeUrl: handleChangeUrl,
                            children: I18N$BsConsole.show(undefined, "Alerts")
                          })
                    }), React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsScheduledReports */9),
                      children: React.createElement(ProjectSettingsNavigation$NavItem, {
                            route: /* ProjectSettingsScheduledReports */Block.__(43, [projectName]),
                            currentRoute: route,
                            handleChangeUrl: handleChangeUrl,
                            children: I18N$BsConsole.show(undefined, "Scheduled reports")
                          })
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsStabilityMonitoring */Block.__(50, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Stability monitoring")
                    }), React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsMetricsExporter */Block.__(13, [config.user])),
                      children: React.createElement(ProjectSettingsNavigation$NavItem, {
                            route: /* ProjectSettingsMetricsExporter */Block.__(24, [projectName]),
                            currentRoute: route,
                            handleChangeUrl: handleChangeUrl,
                            children: I18N$BsConsole.show(undefined, "Metrics exporter")
                          })
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsSourceCode */Block.__(47, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Source code")
                    })), React.createElement(ProjectSettingsNavigation$Section, {
                  children: null
                }, React.createElement(ProjectSettingsNavigation$SectionHeader, {
                      children: I18N$BsConsole.show(undefined, "Project")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsApiTokens */Block.__(48, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "API tokens")
                    }), React.createElement(ProjectSettingsNavigation$NavItem, {
                      route: /* ProjectSettingsRetentionPolicy */Block.__(46, [projectName]),
                      currentRoute: route,
                      handleChangeUrl: handleChangeUrl,
                      children: I18N$BsConsole.show(undefined, "Storage retention")
                    }), CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.51.1") ? React.createElement(ProjectSettingsNavigation$NavItem, {
                        route: /* ProjectSettingStorageSampling */Block.__(32, [projectName]),
                        currentRoute: route,
                        handleChangeUrl: handleChangeUrl,
                        children: I18N$BsConsole.show(undefined, "Storage sampling")
                      }) : null, React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsAccessControl */Block.__(1, [config.user])),
                      children: React.createElement(ProjectSettingsNavigation$NavItem, {
                            route: /* ProjectSettingsAccessControl */Block.__(44, [projectName]),
                            currentRoute: route,
                            handleChangeUrl: handleChangeUrl,
                            children: I18N$BsConsole.show(undefined, "Access control")
                          })
                    })));
}

function make(route, config, projectName, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ProjectSettingsNavigation, {
              route: route,
              config: config,
              projectName: projectName,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = ProjectSettingsNavigation;

exports.Style = Style;
exports.Container = Container;
exports.Section = Section;
exports.isRouteActive = isRouteActive;
exports.SectionHeader = SectionHeader;
exports.NavItem = NavItem;
exports.OutsideLink = OutsideLink;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
