// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Body1$BsConsole = require("../components/text/Body1.js");
var Renderer_Date$BsConsole = require("./Renderer_Date.js");
var Renderer_Ipv4$BsConsole = require("./Renderer_Ipv4.js");
var Renderer_Tags$BsConsole = require("./Renderer_Tags.js");
var Renderer_Bytes$BsConsole = require("./Renderer_Bytes.js");
var Renderer_Number$BsConsole = require("./Renderer_Number.js");
var Renderer_String$BsConsole = require("./Renderer_String.js");
var Renderer_Tickets$BsConsole = require("./Renderer_Tickets.js");
var Renderer_Callstack$BsConsole = require("./Renderer_Callstack.js");
var Renderer_Classifiers$BsConsole = require("./Renderer_Classifiers.js");
var Renderer_Fingerprint$BsConsole = require("./Renderer_Fingerprint.js");
var Renderer_MemoryAddress$BsConsole = require("./Renderer_MemoryAddress.js");
var Renderer_WorkflowState$BsConsole = require("./Renderer_WorkflowState.js");

var component = ReasonReact.statelessComponent("Renderer-BsConsole");

function wrapWithButton(component) {
  return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    textTransform: "none"
                  },
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true,
                  undefined,
                  undefined,
                  [component]
                ]));
}

function make(maybeValue, attribute, handleAddFilters, handleChangeUrl, classNameOpt, forDetailView, config, token, aperture, projectName, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              if (maybeValue === undefined) {
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.paddingLeft(Css.px(8)),
                                  /* [] */0
                                ])
                          }, ReasonReact.element(undefined, undefined, Curry._5(Body1$BsConsole.make, undefined, "\u2014", /* Grey */1, /* nowrap */867913355, [])));
              }
              switch (maybeValue.tag | 0) {
                case /* Raw */0 :
                    var value = maybeValue[0];
                    var variant = value[0];
                    if (variant >= 737456202) {
                      if (variant >= 758940238) {
                        return ReasonReact.element(undefined, undefined, Renderer_Number$BsConsole.make(attribute, /* `Float */[
                                        365180284,
                                        value[1].getDate()
                                      ], handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                      } else {
                        return ReasonReact.element(undefined, undefined, Renderer_String$BsConsole.make(attribute, Pervasives.string_of_bool(value[1]), handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                      }
                    }
                    if (variant >= 365180284) {
                      return ReasonReact.element(undefined, undefined, Renderer_Number$BsConsole.make(attribute, value, handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                    }
                    var s = value[1];
                    switch (attribute) {
                      case "classifiers" :
                          return ReasonReact.element(undefined, undefined, Renderer_Classifiers$BsConsole.make(s.split(" "), handleAddFilters, handleChangeUrl, []));
                      case "fingerprint" :
                          return ReasonReact.element(undefined, undefined, Renderer_Fingerprint$BsConsole.make(attribute, s, handleAddFilters, handleChangeUrl, className, []));
                      default:
                        return ReasonReact.element(undefined, undefined, Renderer_String$BsConsole.make(attribute, s, handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                    }
                case /* UnixTimestamp */2 :
                case /* GpsTimestamp */3 :
                    break;
                case /* Classifiers */4 :
                    return ReasonReact.element(undefined, undefined, Renderer_Classifiers$BsConsole.make(Belt_List.toArray(Belt_List.sort(maybeValue[0], (function (a, b) {
                                              if (b.length > a.length) {
                                                return 1;
                                              } else {
                                                return -1;
                                              }
                                            }))), handleAddFilters, handleChangeUrl, []));
                case /* Bytes */5 :
                    return ReasonReact.element(undefined, undefined, Renderer_Bytes$BsConsole.make(attribute, maybeValue[0], handleAddFilters, handleChangeUrl, className, []));
                case /* MemoryAddress */8 :
                    return ReasonReact.element(undefined, undefined, Renderer_MemoryAddress$BsConsole.make(attribute, maybeValue[0], handleAddFilters, handleChangeUrl, className, []));
                case /* Callstack */9 :
                    var match = maybeValue[0];
                    return ReasonReact.element(undefined, undefined, Renderer_Callstack$BsConsole.make(attribute, 3, undefined, true, 2, Belt_List.toArray(match[0]), undefined, handleAddFilters, handleChangeUrl, match[1], undefined, config, token, aperture, projectName, []));
                case /* UUID */10 :
                    return ReasonReact.element(undefined, undefined, Renderer_String$BsConsole.make(attribute, maybeValue[0], handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                case /* Bitmap */11 :
                    return ReasonReact.element(undefined, undefined, Renderer_String$BsConsole.make(attribute, Pervasives.string_of_bool(maybeValue[0]), handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
                case /* Ipv4 */12 :
                    return ReasonReact.element(undefined, undefined, Renderer_Ipv4$BsConsole.make(attribute, maybeValue[0], handleAddFilters, handleChangeUrl, className, []));
                case /* IssueTickets */13 :
                    return ReasonReact.element(undefined, undefined, Renderer_Tickets$BsConsole.make(maybeValue[0], className, []));
                case /* IssueState */14 :
                    return ReasonReact.element(undefined, undefined, Renderer_WorkflowState$BsConsole.make(maybeValue[0], handleAddFilters, handleChangeUrl, undefined, []));
                case /* IssueTags */15 :
                    return ReasonReact.element(undefined, undefined, Renderer_Tags$BsConsole.make(undefined, /* Tags */0, Belt_List.toArray(maybeValue[0]), undefined, handleAddFilters, handleChangeUrl, undefined, className, []));
                default:
                  return React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.paddingLeft(Css.px(8)),
                                    /* [] */0
                                  ])
                            }, ReasonReact.element(undefined, undefined, Curry._5(Body1$BsConsole.make, undefined, "\u2014", /* Grey */1, /* nowrap */867913355, [])));
              }
              return ReasonReact.element(undefined, undefined, Renderer_Date$BsConsole.make(attribute, maybeValue[0], handleAddFilters, handleChangeUrl, className, Belt_Option.getWithDefault(forDetailView, false), []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Classifiers;

var Tags;

var Callstack;

var $$String;

var Fingerprint;

var $$Date;

var $$Number;

var Tickets;

var MemoryAddress;

var Bytes;

var Ipv4;

var WorkflowState;

exports.Classifiers = Classifiers;
exports.Tags = Tags;
exports.Callstack = Callstack;
exports.$$String = $$String;
exports.Fingerprint = Fingerprint;
exports.$$Date = $$Date;
exports.$$Number = $$Number;
exports.Tickets = Tickets;
exports.MemoryAddress = MemoryAddress;
exports.Bytes = Bytes;
exports.Ipv4 = Ipv4;
exports.WorkflowState = WorkflowState;
exports.component = component;
exports.wrapWithButton = wrapWithButton;
exports.make = make;
/* component Not a pure module */
