// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var component = ReasonReact.statelessComponent("SettingsContainer-BsConsole");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReactDOMRe.createElementVariadic("div", {
                          style: {
                            margin: "0 auto 100px auto",
                            maxWidth: "1100px",
                            minWidth: "550px",
                            padding: "2.25rem 2.25rem 0 2.25rem",
                            width: "90%",
                            flex: "5"
                          }
                        }, children);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(reactProps.children), [], (function (c) {
                          return [c];
                        })));
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
