// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var $$String = require("bs-platform/lib/js/string.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Route$BsConsole = require("./route.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BetaTag$BsConsole = require("./BetaTag.js");
var BtModal$BsConsole = require("./utilities/BtModal.js");
var BtTable$BsConsole = require("./BtTable.js");
var Numeral$BsConsole = require("./Numeral.js");
var ListUtils$BsConsole = require("./ListUtils.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var BtTableRow$BsConsole = require("./BtTableRow.js");
var EmptyValue$BsConsole = require("./format-renderers/EmptyValue.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var TriageData$BsConsole = require("./TriageData.js");
var BtTableBody$BsConsole = require("./BtTableBody.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var BtTableHead$BsConsole = require("./BtTableHead.js");
var LinearGauge$BsConsole = require("./LinearGauge.js");
var Chip = require("@material-ui/core/Chip");
var BinLineChart$BsConsole = require("./BinLineChart.js");
var BtSelectJsx3$BsConsole = require("./BtSelectJsx3.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Edit = require("@material-ui/icons/Edit");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Clear = require("@material-ui/icons/Clear");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Launch = require("@material-ui/icons/Launch");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var Warning = require("@material-ui/icons/Warning");
var BtTableContainer$BsConsole = require("./BtTableContainer.js");
var InputLabel = require("@material-ui/core/InputLabel");
var CardContent = require("@material-ui/core/CardContent");
var DashboardWidgetData$BsConsole = require("./DashboardWidgetData.js");
var FetchProjectOverview$BsConsole = require("./FetchProjectOverview.js");
var CondensedDistribution$BsConsole = require("./charts/CondensedDistribution.js");
var ContainerWithDimensions$BsConsole = require("./ContainerWithDimensions.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");
var ProjectFrontendSettings$BsConsole = require("./ProjectFrontendSettings.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var CondensedDistributionChart$BsConsole = require("./CondensedDistributionChart.js");

var container = Css.style(/* :: */[
      Css.maxWidth(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.selector(":hover .edit-version", /* :: */[
                Css.display(/* block */888960333),
                /* [] */0
              ]),
          /* :: */[
            Css.position(/* relative */903134412),
            /* [] */0
          ]
        ]
      ]
    ]);

var walkthroughContainers = Css.style(/* :: */[
      Css.maxWidth(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* [] */0
      ]
    ]);

var containerBorder = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var dataContainer = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.maxHeight(Css.px(500)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.unsafe("height", "calc(100% - 27px)"),
            /* [] */0
          ]
        ]
      ]
    ]);

var tableContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
            /* :: */[
              Css.maxHeight(Css.pct(100)),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.boxShadow(undefined, Css.px(1), undefined, undefined, undefined, Css.hex(Colors$BsConsole.grey6)),
                  /* [] */0
                ]
              ]
            ]
          ]),
      /* [] */0
    ]);

var loadingContainerStyle = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var titleRow = Css.style(/* :: */[
      Css.height(Css.px(27)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              500
            ]),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* [] */0
        ]
      ]
    ]);

var summaryTile = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.height(Css.rem(9.375)),
            /* :: */[
              Css.unsafe("width", "calc(25% - 12px)"),
              /* :: */[
                Css.padding(Css.rem(1)),
                /* :: */[
                  Css.alignItems(/* flexStart */662439529),
                  /* [] */0
                ]
              ]
            ]
          ]),
      /* :: */[
        containerBorder,
        /* [] */0
      ]
    ]);

var summaryCountRow = Css.style(/* :: */[
      Css.important(Css.flexGrow(1)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var summaryCount = Css.style(/* :: */[
      Css.fontFamily("inconsolata"),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              500
            ]),
        /* :: */[
          Css.marginBottom(Css.rem(0.5)),
          /* :: */[
            Css.fontSize(Css.rem(3.25)),
            /* [] */0
          ]
        ]
      ]
    ]);

var iconButtonClasses = {
  root: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.accent)),
              /* :: */[
                Css.fontSize(Css.rem(1)),
                /* :: */[
                  Css.important(Css.padding(Css.px(4))),
                  /* :: */[
                    Css.display(/* none */-922086728),
                    /* :: */[
                      Css.marginLeft(Css.rem(0.5)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]),
        /* :: */[
          "edit-version",
          /* [] */0
        ]
      ]),
  label: Css.style(/* :: */[
        Css.unsafe("fontSize", "inherit"),
        /* [] */0
      ])
};

var tableIconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "table-action",
          /* [] */0
        ]
      ])
};

var compareTable = Css.merge(/* :: */[
      containerBorder,
      /* :: */[
        Css.style(/* :: */[
              Css.selector(".BtTableBody > .BtTableRow .table-action", /* :: */[
                    Css.unsafe("color", "inherit"),
                    /* :: */[
                      Css.opacity(0.5),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.selector(".BtTableBody > .BtTableRow:hover .table-action", /* :: */[
                      Css.opacity(1.0),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.accent)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var groupedLineChartHeaderRow = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(8)),
      /* :: */[
        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
          /* :: */[
            Css.height(Css.px(48)),
            /* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.maxWidth(Css.pct(100)),
                /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* :: */[
                    Css.whiteSpace(/* nowrap */867913355),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var headerGroupRow = Css.style(/* :: */[
      Css.important(Css.flexGrow(1)),
      /* :: */[
        Css.maxWidth(Css.pct(75)),
        /* [] */0
      ]
    ]);

function headerGroup(groups) {
  var widthPercentage = String(Js_math.floor(100 / groups.length));
  return Css.style(/* :: */[
              Css.unsafe("width", "calc(" + (widthPercentage + "% - 8px)")),
              /* :: */[
                Css.marginRight(Css.px(8)),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var truncate = Css.style(/* :: */[
      Css.display(/* inlineBlock */-147785676),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* [] */0
          ]
        ]
      ]
    ]);

var headerGroupTitle = Css.merge(/* :: */[
      truncate,
      /* [] */0
    ]);

var headerSelect = Css.style(/* :: */[
      Css.selector(".select", /* :: */[
            Css.minWidth(Css.px(120)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".root", /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
              /* :: */[
                Css.borderColor(Css.hex(Colors$BsConsole.grey6)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var headerSelectShort = Css.merge(/* :: */[
      headerSelect,
      /* :: */[
        Css.style(/* :: */[
              Css.selector(".select", /* :: */[
                    Css.paddingTop(Css.rem(0.25)),
                    /* :: */[
                      Css.paddingBottom(Css.rem(0.25)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]),
        /* :: */[
          Css.style(/* :: */[
                Css.unsafe("height", "unset"),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var groupedBinLinChartContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.unsafe("height", "calc(100% - 48px)"),
        /* :: */[
          Css.position(/* relative */903134412),
          /* [] */0
        ]
      ]
    ]);

var distributionChartContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.position(/* relative */903134412),
        /* :: */[
          Css.height(Css.px(30)),
          /* [] */0
        ]
      ]
    ]);

var colorSwatch = Css.style(/* :: */[
      Css.height(Css.px(10)),
      /* :: */[
        Css.width(Css.px(10)),
        /* :: */[
          Css.minWidth(Css.px(10)),
          /* :: */[
            Css.borderRadius(Css.pct(50)),
            /* :: */[
              Css.marginRight(Css.rem(0.5)),
              /* :: */[
                Css.marginLeft(Css.rem(0.5)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var distributionGroups = Css.merge(/* :: */[
      "dist_group",
      /* :: */[
        Css.style(/* :: */[
              Css.marginRight(Css.rem(0.5)),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* :: */[
                    Css.selector("button", /* :: */[
                          Css.important(Css.maxHeight(Css.px(20))),
                          /* :: */[
                            Css.padding(/* zero */-789508312),
                            /* :: */[
                              Css.fontSize(Css.rem(0.8125)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var warningBtn = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.top(Css.rem(1.0)),
          /* :: */[
            Css.right(Css.rem(1.0)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  container: container,
  walkthroughContainers: walkthroughContainers,
  containerBorder: containerBorder,
  dataContainer: dataContainer,
  tableContainer: tableContainer,
  loadingContainerStyle: loadingContainerStyle,
  titleRow: titleRow,
  title: title,
  summaryTile: summaryTile,
  summaryCountRow: summaryCountRow,
  summaryCount: summaryCount,
  iconButtonClasses: iconButtonClasses,
  tableIconButton: tableIconButton,
  compareTable: compareTable,
  groupedLineChartHeaderRow: groupedLineChartHeaderRow,
  headerGroupRow: headerGroupRow,
  headerGroup: headerGroup,
  truncate: truncate,
  headerGroupTitle: headerGroupTitle,
  headerSelect: headerSelect,
  headerSelectShort: headerSelectShort,
  groupedBinLinChartContainer: groupedBinLinChartContainer,
  distributionChartContainer: distributionChartContainer,
  colorSwatch: colorSwatch,
  distributionGroups: distributionGroups,
  warningBtn: warningBtn
};

function DashboardWidgets$Container(Props) {
  var errorOpt = Props.error;
  var betaOpt = Props.beta;
  var children = Props.children;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var beta = betaOpt !== undefined ? betaOpt : false;
  var tmp;
  tmp = error !== undefined ? (
      error.tag ? React.createElement(Tooltip.default, {
              interactive: true,
              placement: "top",
              children: React.createElement(Warning.default, {
                    className: warningBtn
                  }),
              title: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* [] */0
                        ]),
                    children: error[0]
                  })
            }) : React.createElement(Tooltip.default, {
              interactive: true,
              placement: "top",
              children: React.createElement(Warning.default, {
                    className: warningBtn
                  }),
              title: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* [] */0
                        ]),
                    children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                              /* String_literal */Block.__(11, [
                                  "Error: ",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* End_of_format */0
                                    ])
                                ]),
                              "Error: %s"
                            ]), error[0])
                  })
            })
    ) : null;
  return React.createElement("div", {
              className: container
            }, beta ? React.createElement(BetaTag$BsConsole.Jsx3.make, {
                    className: Css.style(/* :: */[
                          Css.position(/* absolute */-1013592457),
                          /* :: */[
                            Css.bottom(Css.rem(0.25)),
                            /* :: */[
                              Css.left(Css.rem(0.25)),
                              /* [] */0
                            ]
                          ]
                        ])
                  }) : null, tmp, children);
}

var Container = {
  make: DashboardWidgets$Container
};

function DashboardWidgets$DataContainer(Props) {
  var children = Props.children;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    dataContainer,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var DataContainer = {
  make: DashboardWidgets$DataContainer
};

function filterFingerprintIssues(str) {
  return str.replace(new RegExp("fingerprint;issues;", "g"), "");
}

function DashboardWidgets$Title(Props) {
  var queryItem = Props.queryItem;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var match = DashboardWidgetData$BsConsole.hasVersionAttribute(queryItem);
  if (match && onVersionAttributeChange !== undefined) {
    return React.createElement(Row2$BsConsole.make, {
                alignItems: /* center */98248149,
                children: null
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: title,
                    children: I18N$BsConsole.dynamic(filterFingerprintIssues(queryItem.name))
                  }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                    ariaLabel: "Edit version attribute",
                    classes: iconButtonClasses,
                    onClick: (function (param) {
                        return Curry._1(onVersionAttributeChange, undefined);
                      }),
                    placement_tooltip: "top",
                    title_tooltip: I18N$BsConsole.get(undefined, "Edit version attribute"),
                    children: React.createElement(Edit.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        })
                  }));
  }
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: title,
              children: I18N$BsConsole.dynamic(filterFingerprintIssues(queryItem.name))
            });
}

var Title = {
  make: DashboardWidgets$Title
};

function DashboardWidgets$TitleRow(Props) {
  var children = Props.children;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* spaceBetween */516682146,
              className: titleRow,
              children: children
            });
}

var TitleRow = {
  make: DashboardWidgets$TitleRow
};

function DashboardWidgets$AnalyzeLink(Props) {
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var label = Props.label;
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body2 */9,
              className: Css.style(/* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* [] */0
                  ]),
              children: React.createElement(Link$BsConsole.Jsx3.make, {
                    route: route,
                    onClick: (function (param) {
                        return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Overview_Analyze */98]));
                      }),
                    handleChangeUrl: handleChangeUrl,
                    className: Css.merge(/* :: */[
                          "walkthrough--overview--widget_analyze",
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.hover(/* :: */[
                                        Css.important(Css.textDecoration(/* underline */131142924)),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, I18N$BsConsole.showSkip(label), React.createElement(Launch.default, {
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.em(0.5)),
                              /* [] */0
                            ]),
                        color: "inherit",
                        fontSize: "inherit"
                      }))
            });
}

var AnalyzeLink = {
  make: DashboardWidgets$AnalyzeLink
};

function DashboardWidgets$AggregateTable(Props) {
  var rows = Props.rows;
  var columns = Props.columns;
  var metadata = Props.metadata;
  var loading = Props.loading;
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var queryItem = Props.queryItem;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var sort = Curry._1(Crdb$BsConsole.Query.getSort, queryItem.query);
  var fold = Curry._1(Crdb$BsConsole.Query.getFold, queryItem.query);
  var factor = Curry._1(Crdb$BsConsole.Query.getFactor, queryItem.query);
  var route_001 = Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), undefined, projectName, factor, fold, sort, undefined, undefined, undefined);
  var route = /* ProjectQueryBuilder */Block.__(13, [
      projectName,
      route_001
    ]);
  var tmp = {
    queryItem: queryItem
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  var func = DashboardWidgetData$BsConsole.AggregateTable.renderDataCell;
  var arg = Belt_Option.getWithDefault(metadata.instances, 0);
  var tmp$1 = {
    columns: columns,
    rows: rows,
    getRowId: BtTableAdapter$BsConsole.CrdbAggregate.getRowId,
    showGrid: true,
    renderDataCell: (function (param) {
        var func$1 = Curry._1(func, param);
        return (function (param) {
            var func$2 = Curry._1(func$1, param);
            return (function (param) {
                var func$3 = Curry._1(func$2, param);
                return (function (param) {
                    return Curry._4(func$3, param, arg, handleChangeUrl, handleAddFilters);
                  });
              });
          });
      }),
    renderHeaderCell: DashboardWidgetData$BsConsole.AggregateTable.renderHeaderCell,
    onSortChange: onSortChange,
    resizeableColumns: true,
    draggableColumns: true,
    fixedLeftColumns: [BtTableAdapter$BsConsole.CrdbAggregate.factorColumn],
    loading: loading,
    containerClassName: tableContainer
  };
  if (sortingState !== undefined) {
    tmp$1.sortingState = Caml_option.valFromOption(sortingState);
  }
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: null
                }, React.createElement(DashboardWidgets$Title, tmp), React.createElement(DashboardWidgets$AnalyzeLink, {
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      label: I18N$BsConsole.get(undefined, "Analyze in Explore")
                    })), React.createElement(DashboardWidgets$DataContainer, {
                  children: React.createElement(BtEnhancedTable$BsConsole.make, tmp$1),
                  className: containerBorder
                }));
}

var AggregateTable = {
  make: DashboardWidgets$AggregateTable
};

function DashboardWidgets$TriageTable(Props) {
  var rows = Props.rows;
  var columns = Props.columns;
  var metadata = Props.metadata;
  var loading = Props.loading;
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var containerClassName = Props.containerClassName;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var aperture = Props.aperture;
  var apertureForQuery = Props.apertureForQuery;
  var projectName = Props.projectName;
  var queryItem = Props.queryItem;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var route_001 = Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var route = /* ProjectTriage */Block.__(11, [
      projectName,
      route_001,
      undefined
    ]);
  var tmp = {
    queryItem: queryItem
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  var func = DashboardWidgetData$BsConsole.TriageTable.renderDataCell;
  var arg = Belt_Option.getWithDefault(metadata.instances, 0);
  var tmp$1 = {
    columns: columns,
    rows: rows,
    getRowId: TriageData$BsConsole.getRowId,
    showGrid: true,
    renderDataCell: (function (param) {
        var func$1 = Curry._1(func, param);
        return (function (param) {
            var func$2 = Curry._1(func$1, param);
            return (function (param) {
                var func$3 = Curry._1(func$2, param);
                return (function (param) {
                    return Curry._7(func$3, param, arg, aperture, apertureForQuery, handleChangeUrl, handleAddFilters, projectName);
                  });
              });
          });
      }),
    renderHeaderCell: DashboardWidgetData$BsConsole.TriageTable.renderHeaderCell,
    onSortChange: onSortChange,
    resizeableColumns: true,
    draggableColumns: true,
    fixedLeftColumns: [
      BtTableAdapter$BsConsole.CrdbAggregate.statusColumn,
      BtTableAdapter$BsConsole.CrdbAggregate.factorColumn
    ],
    loading: loading,
    containerClassName: Css.merge(/* :: */[
          tableContainer,
          /* :: */[
            containerClassName,
            /* [] */0
          ]
        ])
  };
  if (sortingState !== undefined) {
    tmp$1.sortingState = Caml_option.valFromOption(sortingState);
  }
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: null
                }, React.createElement(DashboardWidgets$Title, tmp), React.createElement(DashboardWidgets$AnalyzeLink, {
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      label: I18N$BsConsole.get(undefined, "Analyze in Triage")
                    })), React.createElement(DashboardWidgets$DataContainer, {
                  children: React.createElement(BtEnhancedTable$BsConsole.make, tmp$1),
                  className: containerBorder
                }));
}

var TriageTable = {
  make: DashboardWidgets$TriageTable
};

function getStrokeColor(index, colorRange, restColor) {
  return "#" + Belt_Option.getWithDefault(Belt_Array.get(colorRange, index), restColor);
}

function colorSwatch$1(index, colorRange, restColor) {
  return Css.merge(/* :: */[
              colorSwatch,
              /* :: */[
                Css.style(/* :: */[
                      Css.unsafe("backgroundColor", getStrokeColor(index, colorRange, restColor)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]);
}

function tooltipDataColorSwatch(index, colorRange, restColor) {
  return Css.style(/* :: */[
              Css.height(Css.rem(0.5)),
              /* :: */[
                Css.width(Css.rem(0.5)),
                /* :: */[
                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.white)),
                  /* :: */[
                    Css.borderRadius(Css.pct(50)),
                    /* :: */[
                      Css.unsafe("backgroundColor", getStrokeColor(index, colorRange, restColor)),
                      /* :: */[
                        Css.marginRight(Css.rem(0.5)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var tooltipData = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.selector(":not(:last-of-type)", /* :: */[
              Css.marginBottom(Css.px(6)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var tooltipDataName = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.maxWidth(Css.px(250)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipDataCount = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* [] */0
    ]);

function DashboardWidgets$GroupedBinChart(Props) {
  var data = Props.data;
  var bins = Props.bins;
  var loading = Props.loading;
  var queryItem = Props.queryItem;
  var versionAttribute = Props.versionAttribute;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var range = Props.range;
  var colorRange = Props.colorRange;
  var handleUpdateGroup = Props.handleUpdateGroup;
  var currentGroup = Props.currentGroup;
  var match = React.useState((function () {
          return Belt_Array.slice(data, 0, 4);
        }));
  var setDataForChart = match[1];
  var dataForChart = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setIgnoreList = match$1[1];
  var ignoreList = match$1[0];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match$2;
  if (colorRange !== undefined) {
    match$2 = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match$2 = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match$2 = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match$2[1];
  var colors = match$2[0];
  React.useEffect((function () {
          var nextDataForChart = Belt_Array.slice(data, 0, 4);
          Curry._1(setDataForChart, (function (param) {
                  return nextDataForChart;
                }));
          
        }), [data]);
  var match$3 = React.useMemo((function () {
          var chartData = Belt_Array.keep(dataForChart, (function (param) {
                  return !ignoreList.includes(param[0]);
                }));
          var chartColors = Belt_Array.keepMap(Belt_Array.reduceWithIndex(dataForChart, [], (function (acc, param, idx) {
                      if (ignoreList.includes(param[0])) {
                        return acc;
                      } else {
                        return Belt_Array.concat(acc, [Belt_Array.get(colors, idx)]);
                      }
                    })), (function (x) {
                  return x;
                }));
          return /* tuple */[
                  chartData,
                  chartColors
                ];
        }), /* tuple */[
        dataForChart,
        ignoreList
      ]);
  var colorRange$1 = match$3[1];
  var chartData = match$3[0];
  var tmp = {
    queryItem: queryItem
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  var factor = DashboardWidgetData$BsConsole.getFactor(queryItem.query, versionAttribute);
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: React.createElement(DashboardWidgets$Title, tmp)
                }), React.createElement(DashboardWidgets$DataContainer, {
                  children: null,
                  className: Css.merge(/* :: */[
                        containerBorder,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      flexWrap: /* nowrap */867913355,
                      className: groupedLineChartHeaderRow,
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          className: headerGroupRow,
                          children: Belt_Array.mapWithIndex(Belt_Array.slice(data, 0, 4), (function (idx, param) {
                                  var group = param[0];
                                  var checked = !ignoreList.includes(group);
                                  var tmp = currentGroup === "*" ? I18N$BsConsole.showSkip(group) : React.createElement(FormatRenderer$BsConsole.make, {
                                          value: /* Raw */Block.__(0, [/* `String */[
                                                -976970511,
                                                group
                                              ]]),
                                          attribute: currentGroup
                                        });
                                  return React.createElement("div", {
                                              key: group,
                                              className: headerGroup(data)
                                            }, React.createElement(Checkbox.default, {
                                                  checked: checked,
                                                  color: "inherit",
                                                  classes: {
                                                    root: Css.style(/* :: */[
                                                          Css.unsafe("color", getStrokeColor(idx, colors, fallbackColor)),
                                                          /* :: */[
                                                            Css.padding(/* zero */-789508312),
                                                            /* :: */[
                                                              Css.selector("svg", /* :: */[
                                                                    Css.fontSize(Css.px(16)),
                                                                    /* [] */0
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ])
                                                  },
                                                  onChange: (function (_event) {
                                                      return Curry._1(setIgnoreList, (function (currentIgnoreList) {
                                                                    if (checked) {
                                                                      return Belt_Array.concat(currentIgnoreList, [group]);
                                                                    } else {
                                                                      return Belt_Array.keep(currentIgnoreList, (function (group$prime) {
                                                                                    return group$prime !== group;
                                                                                  }));
                                                                    }
                                                                  }));
                                                    })
                                                }), React.createElement(Tooltip.default, {
                                                  placement: "top",
                                                  children: React.createElement("span", {
                                                        className: headerGroupTitle
                                                      }, tmp),
                                                  title: I18N$BsConsole.skip(group)
                                                }));
                                }))
                        }), factor !== undefined ? React.createElement(BtSelectJsx3$BsConsole.make, {
                            onChange: (function ($$event, param) {
                                var match = $$event.target.value;
                                if (typeof match === "number") {
                                  return ;
                                }
                                if (match[0] !== -976970511) {
                                  return ;
                                }
                                var f = match[1];
                                if (f === "*") {
                                  return Curry._1(handleUpdateGroup, "*");
                                } else if (f === DashboardWidgetData$BsConsole.factorToString(factor)) {
                                  return Curry._1(handleUpdateGroup, undefined);
                                } else {
                                  return ;
                                }
                              }),
                            value: currentGroup,
                            className: headerSelect,
                            label: "",
                            renderValue: (function (selected) {
                                switch (selected) {
                                  case "*" :
                                  case "NONE" :
                                  case "None" :
                                      return I18N$BsConsole.show(undefined, "Total");
                                  default:
                                    return Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "By ",
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "By %s"
                                                  ]), selected);
                                }
                              }),
                            variant: /* Outlined */-28821822,
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  "*"
                                ],
                                children: I18N$BsConsole.show(undefined, "Total")
                              }), React.createElement(MenuItem.default, {
                                value: /* `String */[
                                  -976970511,
                                  DashboardWidgetData$BsConsole.factorToString(factor)
                                ],
                                children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "By ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "By %s"
                                        ]), DashboardWidgetData$BsConsole.factorToString(factor))
                              })) : null), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                      className: groupedBinLinChartContainer,
                      children: (function (width, height) {
                          return React.createElement(React.Fragment, undefined, React.createElement(BinLineChart$BsConsole.make, {
                                          height: height,
                                          width: width,
                                          data: chartData,
                                          getBinRange: (function (param) {
                                              return /* tuple */[
                                                      param[0],
                                                      param[1]
                                                    ];
                                            }),
                                          getBinValue: (function (param) {
                                              return param[2];
                                            }),
                                          tickFormat: (function (d) {
                                              return L10N_date_time_calendar$BsConsole.Format.numericMonthDay(undefined, new Date(d * 1000));
                                            }),
                                          range: range,
                                          bins: bins,
                                          colorRange: colorRange$1,
                                          showGridRows: true,
                                          renderTooltipData: (function (data) {
                                              var range = Belt_Option.flatMap(Belt_Array.get(data, 0), (function (param) {
                                                      var match = param[1];
                                                      return /* tuple */[
                                                              match[0],
                                                              match[1]
                                                            ];
                                                    }));
                                              return React.createElement(Col2$BsConsole.make, {
                                                          children: null
                                                        }, range !== undefined ? React.createElement(Row2$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.marginBottom(Css.px(8)),
                                                                      /* [] */0
                                                                    ]),
                                                                children: React.createElement(BtTypography$BsConsole.make, {
                                                                      variant: /* Body2 */9,
                                                                      children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* String_literal */Block.__(11, [
                                                                                        " - ",
                                                                                        /* String */Block.__(2, [
                                                                                            /* No_padding */0,
                                                                                            /* End_of_format */0
                                                                                          ])
                                                                                      ])
                                                                                  ]),
                                                                                "%s - %s"
                                                                              ]), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(range[0] * 1000)), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(range[1] * 1000)))
                                                                    })
                                                              }) : null, Belt_Array.mapWithIndex(data, (function (idx, param) {
                                                                var groupName = param[0];
                                                                return React.createElement(Row2$BsConsole.make, {
                                                                            alignItems: /* center */98248149,
                                                                            justifyContent: /* spaceBetween */516682146,
                                                                            className: tooltipData,
                                                                            children: null,
                                                                            key: groupName + ("__" + String(idx))
                                                                          }, React.createElement(Row2$BsConsole.make, {
                                                                                alignItems: /* center */98248149,
                                                                                children: null
                                                                              }, React.createElement("div", {
                                                                                    className: tooltipDataColorSwatch(idx, colors, fallbackColor)
                                                                                  }), React.createElement(BtTypography$BsConsole.make, {
                                                                                    variant: /* Body1 */8,
                                                                                    className: tooltipDataName,
                                                                                    children: I18N$BsConsole.showSkip(groupName)
                                                                                  })), React.createElement(BtTypography$BsConsole.make, {
                                                                                variant: /* Body1 */8,
                                                                                className: tooltipDataCount,
                                                                                children: I18N$BsConsole.showSkip(String(param[1][2]))
                                                                              }));
                                                              })));
                                            }),
                                          leftAxisLabel: I18N$BsConsole.get(undefined, "Errors"),
                                          bottomAxisLabel: I18N$BsConsole.get(undefined, "Time")
                                        }), loading ? React.createElement("div", {
                                            className: loadingContainerStyle
                                          }, React.createElement(CircularProgress.default, {
                                                size: 100,
                                                color: "inherit"
                                              })) : null);
                        })
                    })));
}

var GroupedBinChart = {
  groupLimit: 4,
  getStrokeColor: getStrokeColor,
  colorSwatch: colorSwatch$1,
  tooltipDataColorSwatch: tooltipDataColorSwatch,
  tooltipData: tooltipData,
  tooltipDataName: tooltipDataName,
  tooltipDataCount: tooltipDataCount,
  make: DashboardWidgets$GroupedBinChart
};

function DashboardWidgets$MetricsErrorIcon(Props) {
  var errorMessage = Props.errorMessage;
  var supportLink = Href$BsConsole.Support.make(undefined, /* StabilityMetricWidgets */23);
  if (supportLink !== undefined) {
    return React.createElement(Tooltip.default, {
                interactive: true,
                placement: "top",
                children: React.createElement(Warning.default, {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.redDark)),
                            /* [] */0
                          ])
                    }),
                title: React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.lineHeight(Css.em(1.375)),
                            /* [] */0
                          ]),
                      children: React.createElement("div", {
                            onClick: (function (param) {
                                return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Overview_Documentation */99]));
                              })
                          }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Error: ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                ". This likely means you need to configure your SDK to send metric data. To configure this, please see the following ",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "Error: %s. This likely means you need to configure your SDK to send metric data. To configure this, please see the following "
                                  ]), errorMessage), React.createElement("a", {
                                className: Css.style(/* :: */[
                                      Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
                                      /* :: */[
                                        Css.important(Css.textDecoration(/* underline */131142924)),
                                        /* [] */0
                                      ]
                                    ]),
                                href: supportLink,
                                rel: "noopener noreferrer",
                                target: "_blank"
                              }, I18N$BsConsole.show(undefined, "Stability Metric Widgets documentation")), I18N$BsConsole.showSkip("."))
                    })
              });
  } else {
    return null;
  }
}

var MetricsErrorIcon = {
  make: DashboardWidgets$MetricsErrorIcon
};

function getStrokeColor$1(index, colorRange, restColor) {
  return "#" + Belt_Option.getWithDefault(Belt_Array.get(colorRange, index), restColor);
}

function colorSwatch$2(index, colorRange, restColor) {
  return Css.merge(/* :: */[
              colorSwatch,
              /* :: */[
                Css.style(/* :: */[
                      Css.unsafe("backgroundColor", getStrokeColor$1(index, colorRange, restColor)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]);
}

function tooltipDataColorSwatch$1(index, colorRange, restColor) {
  return Css.style(/* :: */[
              Css.height(Css.rem(0.5)),
              /* :: */[
                Css.width(Css.rem(0.5)),
                /* :: */[
                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.white)),
                  /* :: */[
                    Css.borderRadius(Css.pct(50)),
                    /* :: */[
                      Css.unsafe("backgroundColor", getStrokeColor$1(index, colorRange, restColor)),
                      /* :: */[
                        Css.marginRight(Css.rem(0.5)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var tooltipData$1 = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.selector(":not(:last-of-type)", /* :: */[
              Css.marginBottom(Css.px(6)),
              /* :: */[
                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.white, 0.25))),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var tooltipDataName$1 = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.maxWidth(Css.px(250)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var noDataContainer = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function inverseGetBinValue(bin) {
  if (bin.numerator === 0 && bin.denominator === 0) {
    return 100;
  } else {
    return Caml_primitive.caml_float_max(bin.denominator - bin.numerator, 0) / (
            bin.denominator > 1 ? bin.denominator : 1
          ) * 100;
  }
}

function defaultGetBinValue(bin) {
  if (bin.numerator === 0 && bin.denominator === 0) {
    return 0;
  } else {
    return bin.numerator / (
            bin.denominator > 1 ? bin.denominator : 1
          ) * 100;
  }
}

function DashboardWidgets$RatioBinChart$TooltipData(Props) {
  var data = Props.data;
  var getBinValue = Props.getBinValue;
  var numeratorGroup = Props.numeratorGroup;
  var colorRange = Props.colorRange;
  var denominatorGroup = Props.denominatorGroup;
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match;
  if (colorRange !== undefined) {
    match = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match[1];
  var colors = match[0];
  var len = data.length;
  if (len !== 1) {
    if (len === 0) {
      return null;
    }
    var binRange = Belt_Option.flatMap(Belt_Array.get(data, 0), (function (param) {
            return param[1].range;
          }));
    return React.createElement(Col2$BsConsole.make, {
                children: null
              }, binRange !== undefined ? React.createElement(Row2$BsConsole.make, {
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body2 */9,
                            children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " - ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "%s - %s"
                                    ]), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(binRange[0] * 1000)), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(binRange[1] * 1000)))
                          })
                    }) : null, React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.marginBottom(Css.px(4)),
                            /* [] */0
                          ]
                        ]),
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Caption */11,
                          children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* String_literal */Block.__(11, [
                                            " / ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "%s / %s"
                                  ]), numeratorGroup, denominatorGroup)
                        })
                  }), Belt_Array.mapWithIndex(data, (function (idx, param) {
                      var binData = param[1];
                      var group = param[0];
                      var percentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(Curry._1(getBinValue, binData))) + "%";
                      return React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  justifyContent: /* spaceBetween */516682146,
                                  className: tooltipData$1,
                                  children: null,
                                  key: group + ("__" + String(idx))
                                }, React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      children: null
                                    }, React.createElement("div", {
                                          className: tooltipDataColorSwatch$1(idx, colors, fallbackColor)
                                        }), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          className: tooltipDataName$1,
                                          children: I18N$BsConsole.showSkip(group)
                                        })), React.createElement(Col2$BsConsole.make, {
                                      children: null
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          className: Css.style(/* :: */[
                                                Css.alignSelf(/* flexEnd */924268066),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(percentage)
                                        }), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          className: Css.style(/* :: */[
                                                Css.alignSelf(/* flexEnd */924268066),
                                                /* [] */0
                                              ]),
                                          children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* String_literal */Block.__(11, [
                                                            " / ",
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* End_of_format */0
                                                              ])
                                                          ])
                                                      ]),
                                                    "%s / %s"
                                                  ]), Numeral$BsConsole.format("0,0", String(binData.numerator)), Numeral$BsConsole.format("0,0", String(binData.denominator)))
                                        })));
                    })));
  }
  var match$1 = data[0];
  var bin = match$1[1];
  var match$2 = bin.range;
  var percentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(Curry._1(getBinValue, bin))) + "%";
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        children: Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " - ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "%s - %s"
                                ]), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(match$2[0] * 1000)), L10N_date_time$BsConsole.Format.numericMonthDay(undefined, new Date(match$2[1] * 1000)))
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.margin2(Css.px(8), /* zero */-789508312),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: tooltipDataColorSwatch$1(0, colors, fallbackColor)
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading5 */4,
                      children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ]),
                                "%s"
                              ]), percentage)
                    })), React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        children: Curry._4(I18N$BsConsole.showSkipf(/* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* Char_literal */Block.__(12, [
                                          /* " " */32,
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " / ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* Char_literal */Block.__(12, [
                                                          /* " " */32,
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),
                                  "%s %s / %s %s"
                                ]), Numeral$BsConsole.format("0,0", String(bin.numerator)), numeratorGroup, Numeral$BsConsole.format("0,0", String(bin.denominator)), denominatorGroup)
                      })
                }));
}

var TooltipData = {
  make: DashboardWidgets$RatioBinChart$TooltipData
};

function DashboardWidgets$RatioBinChart(Props) {
  var bins = Props.bins;
  var title$1 = Props.title;
  Props.queryItem;
  var colorRange = Props.colorRange;
  var range = Props.range;
  var data = Props.data;
  var numeratorGroup = Props.numeratorGroup;
  var denominatorGroup = Props.denominatorGroup;
  var inverseOpt = Props.inverse;
  var errorMessage = Props.errorMessage;
  var inverse = inverseOpt !== undefined ? inverseOpt : false;
  var match = React.useState((function () {
          return [];
        }));
  var setIgnoreList = match[1];
  var ignoreList = match[0];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match$1;
  if (colorRange !== undefined) {
    match$1 = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match$1 = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match$1 = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match$1[1];
  var colors = match$1[0];
  var getBinRange = React.useMemo((function () {
          return (function (param) {
              return param.range;
            });
        }), [data]);
  var getBinValue = React.useMemo((function () {
          return (function (param) {
              if (inverse) {
                return inverseGetBinValue(param);
              } else {
                return defaultGetBinValue(param);
              }
            });
        }), /* tuple */[
        data,
        inverse
      ]);
  var match$2 = React.useMemo((function () {
          var chartData = Belt_Array.keep(data, (function (param) {
                  return !ignoreList.includes(param[0]);
                }));
          var chartColors = Belt_Array.keepMap(Belt_Array.reduceWithIndex(data, [], (function (acc, param, idx) {
                      if (ignoreList.includes(param[0])) {
                        return acc;
                      } else {
                        return Belt_Array.concat(acc, [Belt_Array.get(colors, idx)]);
                      }
                    })), (function (x) {
                  return x;
                }));
          return /* tuple */[
                  chartData,
                  chartColors
                ];
        }), /* tuple */[
        data,
        ignoreList
      ]);
  var colorRange$1 = match$2[1];
  var chartData = match$2[0];
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: title,
                        children: I18N$BsConsole.dynamic(title$1)
                      })
                }), React.createElement(DashboardWidgets$DataContainer, {
                  children: null,
                  className: Css.merge(/* :: */[
                        containerBorder,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      flexWrap: /* nowrap */867913355,
                      className: groupedLineChartHeaderRow,
                      children: null
                    }, Belt_Array.mapWithIndex(data, (function (idx, param) {
                            var groupName = param[0];
                            var checked = !ignoreList.includes(groupName);
                            return React.createElement(Row2$BsConsole.make, {
                                        alignItems: /* center */98248149,
                                        className: headerGroupRow,
                                        children: null,
                                        key: groupName + ("_" + String(idx))
                                      }, React.createElement(Checkbox.default, {
                                            checked: checked,
                                            color: "inherit",
                                            classes: {
                                              root: Css.style(/* :: */[
                                                    Css.unsafe("color", getStrokeColor$1(idx, colors, fallbackColor)),
                                                    /* :: */[
                                                      Css.padding(/* zero */-789508312),
                                                      /* :: */[
                                                        Css.marginRight(Css.px(4)),
                                                        /* :: */[
                                                          Css.selector("svg", /* :: */[
                                                                Css.fontSize(Css.px(16)),
                                                                /* [] */0
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            },
                                            onChange: (function (_event) {
                                                return Curry._1(setIgnoreList, (function (currentIgnoreList) {
                                                              if (checked) {
                                                                return Belt_Array.concat(currentIgnoreList, [groupName]);
                                                              } else {
                                                                return Belt_Array.keep(currentIgnoreList, (function (group$prime) {
                                                                              return group$prime !== groupName;
                                                                            }));
                                                              }
                                                            }));
                                              })
                                          }), React.createElement(Tooltip.default, {
                                            placement: "top",
                                            children: React.createElement("span", {
                                                  className: headerGroupTitle
                                                }, I18N$BsConsole.dynamic(groupName)),
                                            title: I18N$BsConsole.skip(groupName)
                                          }));
                          })), errorMessage !== undefined ? React.createElement(DashboardWidgets$MetricsErrorIcon, {
                            errorMessage: errorMessage
                          }) : null), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                      className: groupedBinLinChartContainer,
                      children: (function (width, height) {
                          var tmp = {
                            height: height,
                            width: width,
                            data: chartData,
                            getBinRange: getBinRange,
                            getBinValue: getBinValue,
                            tickFormat: (function (d) {
                                return L10N_date_time_calendar$BsConsole.Format.numericMonthDay(undefined, new Date(d * 1000));
                              }),
                            range: range,
                            bins: bins,
                            colorRange: colorRange$1,
                            showGridRows: true,
                            renderTooltipData: (function (data) {
                                return React.createElement(DashboardWidgets$RatioBinChart$TooltipData, {
                                            data: data,
                                            getBinValue: getBinValue,
                                            numeratorGroup: numeratorGroup,
                                            denominatorGroup: denominatorGroup
                                          });
                              }),
                            leftAxisLabel: I18N$BsConsole.get(undefined, denominatorGroup + " (%)"),
                            bottomAxisLabel: I18N$BsConsole.get(undefined, "Time")
                          };
                          var tmp$1 = Belt_Option.flatMap(errorMessage, (function (msg) {
                                  return (function (param) {
                                            return React.createElement(Row2$BsConsole.make, {
                                                        alignItems: /* center */98248149,
                                                        justifyContent: /* center */98248149,
                                                        className: noDataContainer,
                                                        children: null
                                                      }, React.createElement(DashboardWidgets$MetricsErrorIcon, {
                                                            errorMessage: msg
                                                          }), React.createElement(BtTypography$BsConsole.make, {
                                                            variant: /* Body1 */8,
                                                            className: Css.style(/* :: */[
                                                                  Css.marginLeft(Css.rem(0.5)),
                                                                  /* [] */0
                                                                ]),
                                                            children: I18N$BsConsole.show(undefined, "Error")
                                                          }));
                                          });
                                }));
                          if (tmp$1 !== undefined) {
                            tmp.noDataLabel = Caml_option.valFromOption(tmp$1);
                          }
                          return React.createElement(React.Fragment, undefined, React.createElement(BinLineChart$BsConsole.make, tmp));
                        })
                    })));
}

var RatioBinChart = {
  getStrokeColor: getStrokeColor$1,
  colorSwatch: colorSwatch$2,
  tooltipDataColorSwatch: tooltipDataColorSwatch$1,
  tooltipData: tooltipData$1,
  tooltipDataName: tooltipDataName$1,
  noDataContainer: noDataContainer,
  inverseGetBinValue: inverseGetBinValue,
  defaultGetBinValue: defaultGetBinValue,
  TooltipData: TooltipData,
  make: DashboardWidgets$RatioBinChart
};

function DashboardWidgets$SummaryTile(Props) {
  var title$1 = Props.title;
  var count = Props.count;
  var className = Props.className;
  var className$1 = Belt_Option.getWithDefault(Belt_Option.map(className, (function (c) {
              return Css.merge(/* :: */[
                          summaryTile,
                          /* :: */[
                            c,
                            /* [] */0
                          ]
                        ]);
            })), summaryTile);
  return React.createElement(Col2$BsConsole.make, {
              className: className$1,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading2 */1,
                  className: summaryCount,
                  children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(count)))
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: title,
                  children: I18N$BsConsole.showSkip(title$1)
                }));
}

var SummaryTile = {
  make: DashboardWidgets$SummaryTile
};

function DashboardWidgets$RatioTile$TextRow(Props) {
  var denominatorCount = Props.denominatorCount;
  var numeratorCount = Props.numeratorCount;
  var denominatorGroupName = Props.denominatorGroupName;
  var numeratorGroupName = Props.numeratorGroupName;
  var title$1 = Props.title;
  var numeratorToolTip = Numeral$BsConsole.format("0,0", String(numeratorCount));
  var denominatorToolTip = Numeral$BsConsole.format("0,0", String(denominatorCount));
  var numeratorDisplay = Numeral$BsConsole.format("0.[0]a", String(numeratorCount));
  var denominatorDisplay = Numeral$BsConsole.format("0.[0]a", String(denominatorCount));
  return React.createElement(Row2$BsConsole.make, {
              justifyContent: /* spaceBetween */516682146,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.rem(1.68)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.merge(/* :: */[
                        title,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.fontSize(Css.rem(0.9)),
                                /* :: */[
                                  Css.marginBottom(Css.rem(0.5)),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.showSkip(title$1)
                }), React.createElement(Tooltip.default, {
                  placement: "top",
                  children: React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.qualitativeViz1)),
                              /* [] */0
                            ])
                      }, Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        " / ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ]),
                                "%s / %s"
                              ]), numeratorDisplay, denominatorDisplay)),
                  title: Curry._4(I18N$BsConsole.skipf(/* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* String_literal */Block.__(11, [
                                            " / ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* " " */32,
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ]),
                            "%s %s / %s %s"
                          ]), numeratorToolTip, numeratorGroupName, denominatorToolTip, denominatorGroupName)
                }));
}

var TextRow = {
  make: DashboardWidgets$RatioTile$TextRow
};

function DashboardWidgets$RatioTile(Props) {
  var denominatorCount = Props.denominatorCount;
  var numeratorCount = Props.numeratorCount;
  var denominatorGroupName = Props.denominatorGroupName;
  var numeratorGroupName = Props.numeratorGroupName;
  var title = Props.title;
  var error = Props.error;
  var className = Props.className;
  var className$1 = Belt_Option.getWithDefault(Belt_Option.map(className, (function (c) {
              return Css.merge(/* :: */[
                          summaryTile,
                          /* :: */[
                            c,
                            /* [] */0
                          ]
                        ]);
            })), summaryTile);
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* flexStart */662439529,
              className: className$1,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading2 */1,
                  className: Css.merge(/* :: */[
                        summaryCount,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.fontSize(Css.rem(3.25)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.[000]%", Pervasives.string_of_float(numeratorCount / denominatorCount)))
                }), React.createElement(DashboardWidgets$RatioTile$TextRow, {
                  denominatorCount: denominatorCount,
                  numeratorCount: numeratorCount,
                  denominatorGroupName: denominatorGroupName,
                  numeratorGroupName: numeratorGroupName,
                  title: title
                }), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                  className: distributionChartContainer,
                  children: (function (width, param) {
                      if (error !== undefined) {
                        return React.createElement(LinearGauge$BsConsole.make, {
                                    width: width,
                                    denominatorCount: 1,
                                    numeratorCount: numeratorCount,
                                    numeratorGroupName: numeratorGroupName
                                  });
                      } else {
                        return React.createElement(LinearGauge$BsConsole.make, {
                                    width: width,
                                    denominatorCount: denominatorCount,
                                    numeratorCount: numeratorCount,
                                    numeratorGroupName: numeratorGroupName
                                  });
                      }
                    })
                }));
}

var RatioTile = {
  TextRow: TextRow,
  make: DashboardWidgets$RatioTile
};

function DashboardWidgets$SummaryRow(Props) {
  var totalInstances = Props.totalInstances;
  var totalFingerprints = Props.totalFingerprints;
  var open_ = Props.open_;
  var in_progress = Props.in_progress;
  return React.createElement(DashboardWidgets$Container, {
              children: React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.height(Css.pct(100)),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, React.createElement(DashboardWidgets$SummaryTile, {
                        title: I18N$BsConsole.get(undefined, "Total errors"),
                        count: totalInstances
                      }), React.createElement(DashboardWidgets$SummaryTile, {
                        title: I18N$BsConsole.get(undefined, "Unique errors"),
                        count: totalFingerprints
                      }), React.createElement(DashboardWidgets$SummaryTile, {
                        title: I18N$BsConsole.get(undefined, "Open fingerprints"),
                        count: open_
                      }), React.createElement(DashboardWidgets$SummaryTile, {
                        title: I18N$BsConsole.get(undefined, "In progress fingerprints"),
                        count: in_progress
                      }))
            });
}

var SummaryRow = {
  make: DashboardWidgets$SummaryRow
};

function DashboardWidgets$SummaryV2Row(Props) {
  var summary = Props.summary;
  var numWidgets = Belt_List.length(summary);
  return React.createElement(Row2$BsConsole.make, {
              justifyContent: /* spaceBetween */516682146,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: Belt_List.toArray(Belt_List.map(summary, (function (param) {
                          var title = param[0];
                          var percentage = Numeral$BsConsole.format("0.0", Pervasives.string_of_float(100 / numWidgets));
                          var paddingWidth = Caml_int32.div(((numWidgets - 1 | 0) << 4), numWidgets);
                          return React.createElement(DashboardWidgets$SummaryTile, {
                                      title: I18N$BsConsole.get(undefined, title),
                                      count: param[1],
                                      className: Css.style(/* :: */[
                                            Css.unsafe("width", "calc(" + (percentage + ("% - " + (String(paddingWidth) + "px)")))),
                                            /* [] */0
                                          ]),
                                      key: title
                                    });
                        })))
            });
}

var SummaryV2Row = {
  make: DashboardWidgets$SummaryV2Row
};

function DashboardWidgets$PointRatio(Props) {
  var denominatorCount = Props.denominatorCount;
  var numeratorCount = Props.numeratorCount;
  var denominatorGroupName = Props.denominatorGroupName;
  var numeratorGroupName = Props.numeratorGroupName;
  var title = Props.title;
  var inverse = Props.inverse;
  var error = Props.error;
  var numerator = inverse ? Caml_primitive.caml_int_max(denominatorCount - numeratorCount | 0, 0) : numeratorCount;
  return React.createElement(DashboardWidgets$Container, {
              error: error,
              children: React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    children: React.createElement(DashboardWidgets$RatioTile, {
                          denominatorCount: denominatorCount,
                          numeratorCount: numerator,
                          denominatorGroupName: denominatorGroupName,
                          numeratorGroupName: numeratorGroupName,
                          title: title,
                          error: error,
                          className: Css.style(/* :: */[
                                Css.unsafe("width", "100%"),
                                /* [] */0
                              ])
                        })
                  })
            });
}

var PointRatio = {
  make: DashboardWidgets$PointRatio
};

function getVersionAttributes(attributes) {
  return Belt_List.keep(attributes, (function (attribute) {
                var match = attribute.format;
                var match$1 = attribute.name;
                switch (match) {
                  case "none" :
                      if (match$1.includes("version")) {
                        return true;
                      } else {
                        return false;
                      }
                  case "semver" :
                      return true;
                  default:
                    return false;
                }
              }));
}

function getDefaultVersionAttribute(attributes) {
  var preferred = Belt_List.getBy(attributes, (function (attr) {
          var match = attr.name;
          switch (match) {
            case "application.version" :
            case "version" :
                return true;
            default:
              return false;
          }
        }));
  if (preferred !== undefined) {
    return preferred;
  } else {
    return Belt_List.get(attributes, 0);
  }
}

function DashboardWidgets$ChooseVersionSelect(Props) {
  var attributes = Props.attributes;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var token = Props.token;
  var project = Props.project;
  var children = Props.children;
  var versionAttributes = getVersionAttributes(attributes);
  var match = React.useState((function () {
          return Belt_Option.map(getDefaultVersionAttribute(versionAttributes), (function (attr) {
                        return attr.name;
                      }));
        }));
  var setValue = match[1];
  var value = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDisplayDialog = match$1[1];
  return React.createElement(React.Fragment, undefined, Curry._1(children, (function (param) {
                    return Curry._1(setDisplayDialog, (function (param) {
                                  return true;
                                }));
                  })), match$1[0] ? React.createElement(Dialog.default, {
                    open: true,
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.width(Css.pct(25)),
                            /* :: */[
                              Css.unsafe("maxWidth", "unset"),
                              /* [] */0
                            ]
                          ]),
                      container: Css.style(/* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* [] */0
                          ]),
                      root: Css.style(/* :: */[
                            Css.userSelect(/* none */-922086728),
                            /* [] */0
                          ])
                    },
                    onClose: (function (param) {
                        return Curry._1(setDisplayDialog, (function (param) {
                                      return false;
                                    }));
                      }),
                    children: React.createElement(CardContent.default, {
                          children: null
                        }, React.createElement(Mui$BsConsole.IconButton.make, {
                              ariaLabel: "Close dialog",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.height(Css.px(48)),
                                      /* :: */[
                                        Css.position(/* absolute */-1013592457),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                          /* :: */[
                                            Css.top(/* zero */-789508312),
                                            /* :: */[
                                              Css.right(/* zero */-789508312),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ])
                              },
                              color: "primary",
                              onClick: (function (_event) {
                                  return Curry._1(setDisplayDialog, (function (param) {
                                                return false;
                                              }));
                                }),
                              children: React.createElement(Clear.default, { })
                            }), React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Heading6 */5,
                              color: Colors$BsConsole.grey1,
                              children: I18N$BsConsole.show(undefined, "Version attribute")
                            }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(1.0)),
                                    /* :: */[
                                      Css.marginTop(Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ]),
                              color: Colors$BsConsole.grey1,
                              children: I18N$BsConsole.show(undefined, "Please select the project's default version attribute")
                            }), React.createElement(Col2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(1)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement(BtSelectJsx3$BsConsole.make, {
                                  onChange: (function ($$event, param) {
                                      var value = $$event.target.value;
                                      return Curry._1(setValue, (function (param) {
                                                    return value;
                                                  }));
                                    }),
                                  value: value !== undefined ? value : "",
                                  className: Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(1)),
                                        /* :: */[
                                          Css.selector(".select", /* :: */[
                                                Css.minWidth(Css.px(120)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]),
                                  label: I18N$BsConsole.get(undefined, "Select version attribute"),
                                  renderValue: I18N$BsConsole.showSkip,
                                  children: Belt_List.toArray(Belt_List.map(versionAttributes, (function (attribute) {
                                              return React.createElement(MenuItem.default, {
                                                          value: attribute.name,
                                                          children: I18N$BsConsole.showSkip(attribute.name)
                                                        });
                                            })))
                                }), React.createElement(Button.default, {
                                  variant: "contained",
                                  color: "primary",
                                  disabled: Belt_Option.isNone(value),
                                  onClick: (function (param) {
                                      return ProjectFrontendSettings$BsConsole.updateOrCreate(token, project.pid, (function (settings) {
                                                    if (settings !== undefined) {
                                                      return {
                                                              defaultView: settings.defaultView,
                                                              bookmarkedViews: settings.bookmarkedViews,
                                                              serviceOverrides: settings.serviceOverrides,
                                                              versionAttribute: value,
                                                              normBy: settings.normBy,
                                                              unityCDA: settings.unityCDA,
                                                              firstTimeExperience: settings.firstTimeExperience
                                                            };
                                                    } else {
                                                      return {
                                                              defaultView: ProjectFrontendSettings$BsConsole.empty.defaultView,
                                                              bookmarkedViews: ProjectFrontendSettings$BsConsole.empty.bookmarkedViews,
                                                              serviceOverrides: ProjectFrontendSettings$BsConsole.empty.serviceOverrides,
                                                              versionAttribute: value,
                                                              normBy: ProjectFrontendSettings$BsConsole.empty.normBy,
                                                              unityCDA: ProjectFrontendSettings$BsConsole.empty.unityCDA,
                                                              firstTimeExperience: ProjectFrontendSettings$BsConsole.empty.firstTimeExperience
                                                            };
                                                    }
                                                  }), (function (param) {
                                                    Curry._1(onVersionAttributeChange, value);
                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Version attribute added."));
                                                    return Curry._1(setDisplayDialog, (function (param) {
                                                                  return false;
                                                                }));
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Set version attribute")
                                })))
                  }) : null);
}

var ChooseVersionSelect = {
  getVersionAttributes: getVersionAttributes,
  getDefaultVersionAttribute: getDefaultVersionAttribute,
  make: DashboardWidgets$ChooseVersionSelect
};

function DashboardWidgets$ChooseVersion(Props) {
  var attributes = Props.attributes;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var token = Props.token;
  var project = Props.project;
  return React.createElement(DashboardWidgets$Container, {
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.maxWidth(Css.pct(100)),
                          /* :: */[
                            Css.marginTop(Css.px(27)),
                            /* :: */[
                              Css.unsafe("height", "calc(100% - 27px)"),
                              /* :: */[
                                Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                /* :: */[
                                  Css.borderRadius(Css.px(4)),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.justifyContent(/* center */98248149),
                                      /* :: */[
                                        Css.alignItems(/* center */98248149),
                                        /* :: */[
                                          Css.padding(Css.rem(2)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(Col2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* :: */[
                                    Css.marginBottom(Css.rem(1)),
                                    /* :: */[
                                      Css.textAlign(/* center */98248149),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            children: I18N$BsConsole.show(undefined, "This widget requires an attribute linked to application version. Please select which attribute you would like to use.")
                          }), React.createElement(DashboardWidgets$ChooseVersionSelect, {
                            attributes: attributes,
                            onVersionAttributeChange: onVersionAttributeChange,
                            token: token,
                            project: project,
                            children: (function (onOpenVersionDialog) {
                                return React.createElement(Button.default, {
                                            variant: "contained",
                                            color: "primary",
                                            onClick: (function (param) {
                                                return Curry._1(onOpenVersionDialog, undefined);
                                              }),
                                            children: I18N$BsConsole.show(undefined, "Select version attribute")
                                          });
                              })
                          })))
            });
}

var ChooseVersion = {
  make: DashboardWidgets$ChooseVersion
};

function DashboardWidgets$AttributesSelect(Props) {
  var currentValue = Props.currentValue;
  var attributes = Props.attributes;
  var onChange = Props.onChange;
  var defaultValue = Props.defaultValue;
  var match = React.useState((function () {
          return currentValue;
        }));
  var setCustomGroup = match[1];
  var customGroup = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(InputLabel.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginTop(Css.rem(0.5)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* [] */0
                          ]
                        ])
                  },
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        children: I18N$BsConsole.show(undefined, "Group data by")
                      })
                }), React.createElement(BtAutocomplete$BsConsole.make, {
                  toString: (function (attr) {
                      return attr.name;
                    }),
                  considerations: Belt_List.toArray(Belt_List.sort(attributes, (function (a, b) {
                              if (defaultValue !== undefined) {
                                if (a.name === defaultValue) {
                                  return -1;
                                } else if (b.name === defaultValue) {
                                  return 1;
                                } else {
                                  return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
                                }
                              } else {
                                return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
                              }
                            }))),
                  onChange: (function (_raw, attribute) {
                      return Belt_Option.map(attribute, (function (attr) {
                                    Curry._1(onChange, attr.name);
                                    return Curry._1(setCustomGroup, (function (param) {
                                                  return attr.name;
                                                }));
                                  }));
                    }),
                  placeholder: I18N$BsConsole.get(undefined, "Select an attribute"),
                  autoFocus: true,
                  inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                  renderListOption: (function (attr, idx) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  children: defaultValue !== undefined && defaultValue === attr.name ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " (default)",
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "%s (default)"
                                            ]), filterFingerprintIssues(attr.name)) : I18N$BsConsole.dynamic(filterFingerprintIssues(attr.name)),
                                  key: attr.name + ("__" + String(idx))
                                });
                    }),
                  renderInput: (function (param) {
                      var onFocus = param.onFocus;
                      var onChange = param.onChange;
                      var onFocus$prime = Curry.__1(onFocus);
                      var onChange$prime = Curry.__1(onChange);
                      return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                  onFocus: onFocus$prime,
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.marginBottom(/* zero */-789508312),
                                          /* [] */0
                                        ]
                                      ]),
                                  placeholder: param.placeholder,
                                  value: filterFingerprintIssues(param.value),
                                  onChange: onChange$prime
                                });
                    }),
                  clearOnChange: true
                }), customGroup !== undefined ? React.createElement(Chip.default, {
                    label: React.createElement("span", undefined, I18N$BsConsole.showSkip(filterFingerprintIssues(customGroup))),
                    variant: "outlined",
                    size: "small",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginTop(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }
                  }) : null);
}

var AttributesSelect = {
  make: DashboardWidgets$AttributesSelect
};

function DashboardWidgets$EditQueryItemConfig$EditGroupBy(Props) {
  var currentValue = Props.currentValue;
  var defaultValue = Props.defaultValue;
  var attributes = Props.attributes;
  var onChange = Props.onChange;
  var attributes$1 = Belt_List.filter(attributes, (function (attribute) {
          if (attribute.statistics.used && ListUtils$BsConsole.contains(attribute.aggregate, "histogram")) {
            return !ListUtils$BsConsole.contains(attribute.aggregate, "bin");
          } else {
            return false;
          }
        }));
  return React.createElement(DashboardWidgets$AttributesSelect, {
              currentValue: currentValue,
              attributes: attributes$1,
              onChange: onChange,
              defaultValue: defaultValue
            });
}

var EditGroupBy = {
  make: DashboardWidgets$EditQueryItemConfig$EditGroupBy
};

var minServiceVersion = "0.8.5";

function DashboardWidgets$EditQueryItemConfig(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var serviceEndpoint = Props.serviceEndpoint;
  var serviceVersion = Props.serviceVersion;
  var queryItem = Props.queryItem;
  var token = Props.token;
  var onChange = Props.onChange;
  var editableConfigs = Props.editableConfigs;
  var match = React.useState((function () {
          return false;
        }));
  var setEdit = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setUpdatedGroupBy = match$1[1];
  var updatedGroupBy = match$1[0];
  var match$2 = Semver.gte(serviceVersion, minServiceVersion);
  var match$3 = TeamsUtils$BsConsole.isAdmin(config, projectName);
  if (!match$2) {
    return null;
  }
  if (!match$3) {
    return null;
  }
  if (!match[0]) {
    return React.createElement(Button.default, {
                variant: "text",
                color: "primary",
                onClick: (function (param) {
                    return Curry._1(setEdit, (function (param) {
                                  return true;
                                }));
                  }),
                children: I18N$BsConsole.show(undefined, "Edit")
              });
  }
  var match$4 = editableConfigs.groupBy;
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: true,
              onClose: (function (param) {
                  Curry._1(setEdit, (function (param) {
                          return false;
                        }));
                  return Curry._1(setUpdatedGroupBy, (function (param) {
                                
                              }));
                }),
              title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Customize \"",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* "\"" */34,
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "Customize \"%s\""
                      ]), filterFingerprintIssues(queryItem.name)),
              subtitle: I18N$BsConsole.get(undefined, "The following options are available for customizing this widget:"),
              divider: true,
              fullWidth: true,
              dialogClasses: {
                paper: Css.style(/* :: */[
                      Css.width(Css.px(500)),
                      /* :: */[
                        Css.unsafe("maxWidth", "unset"),
                        /* [] */0
                      ]
                    ]),
                container: Css.style(/* :: */[
                      Css.alignItems(/* flexStart */662439529),
                      /* [] */0
                    ]),
                root: Css.style(/* :: */[
                      Css.userSelect(/* none */-922086728),
                      /* [] */0
                    ])
              },
              disableBackdropClick: true,
              children: null
            }, match$4 !== undefined ? React.createElement(DashboardWidgets$EditQueryItemConfig$EditGroupBy, {
                    currentValue: match$4.currentValue,
                    defaultValue: match$4.defaultValue,
                    attributes: match$4.attributes,
                    onChange: (function (newGrp) {
                        return Curry._1(setUpdatedGroupBy, (function (param) {
                                      return newGrp;
                                    }));
                      })
                  }) : null, React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* flexEnd */924268066,
                  children: null
                }, React.createElement(Button.default, {
                      variant: "text",
                      color: "primary",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginRight(Css.rem(0.5)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (_event) {
                          Curry._1(setEdit, (function (param) {
                                  return false;
                                }));
                          return Curry._1(setUpdatedGroupBy, (function (param) {
                                        
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Cancel")
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: updatedGroupBy === undefined,
                      onClick: (function (_event) {
                          if (updatedGroupBy !== undefined) {
                            var arg = FetchProjectOverview$BsConsole.makeQueryConfigPostTask(serviceEndpoint, queryItem.id, "group", updatedGroupBy, projectName, universeName);
                            return Curry._2((function (param) {
                                            return (function (param$1, param$2) {
                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                              });
                                          })(token), undefined, (function (param) {
                                          return FetchProjectOverview$BsConsole.responseCb((function (param) {
                                                        Curry._1(onChange, undefined);
                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Saved custom \"group by\""));
                                                      }), (function (errMsg) {
                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                            /* String_literal */Block.__(11, [
                                                                                "Error: ",
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            "Error: %s"
                                                                          ]), errMsg));
                                                      }), param);
                                        }));
                          }
                          
                        }),
                      children: I18N$BsConsole.show(undefined, "Save")
                    })));
}

var EditQueryItemConfig = {
  EditGroupBy: EditGroupBy,
  minServiceVersion: minServiceVersion,
  make: DashboardWidgets$EditQueryItemConfig
};

function getData(groups) {
  var sortedData = Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(groups), (function (a, b) {
              var match = a.group;
              var match$1 = b.group;
              if (match === "__rest") {
                return 1;
              } else if (match$1 === "__rest") {
                return -1;
              } else {
                return b.count - a.count | 0;
              }
            })));
  var chartData = Belt_Array.map(sortedData, (function (group) {
          return /* tuple */[
                  group.group,
                  group.count
                ];
        }));
  return /* tuple */[
          sortedData,
          chartData
        ];
}

function getColor(index, groupName, colorMap, colorRange, restColor) {
  var mappedColor = Belt_Array.getBy(colorMap, (function (param) {
          return param[0] === groupName;
        }));
  var indexedColor = Belt_Array.get(colorRange, index);
  if (mappedColor !== undefined) {
    return mappedColor[1];
  } else if (groupName === "__rest" || indexedColor === undefined) {
    return restColor;
  } else {
    return indexedColor;
  }
}

function getColorSwatchClassName(c) {
  return Css.merge(/* :: */[
              colorSwatch,
              /* :: */[
                Css.style(/* :: */[
                      Css.backgroundColor(Css.hex(c)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]);
}

function getGroupRowClassName(hoveredGroup, sortedData) {
  var idx = Belt_Option.flatMap(hoveredGroup, (function (group) {
          return Belt_Array.getIndexBy(sortedData, (function (group$prime) {
                        return group.group === group$prime.group;
                      }));
        }));
  if (idx !== undefined) {
    return Css.style(/* :: */[
                Css.unsafe("height", "calc(100% - 70px)"),
                /* :: */[
                  Css.selector(".dist_group", /* :: */[
                        Css.marginRight(Css.rem(0.5)),
                        /* :: */[
                          Css.transition(50, undefined, undefined, "opacity"),
                          /* [] */0
                        ]
                      ]),
                  /* :: */[
                    Css.selector(".dist_group:not(:nth-of-type(" + (String(idx + 1 | 0) + "))"), /* :: */[
                          Css.opacity(0.4),
                          /* :: */[
                            Css.marginRight(Css.rem(0.5)),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.unsafe("height", "calc(100% - 70px)"),
                /* :: */[
                  Css.selector(".dist_group", /* :: */[
                        Css.marginRight(Css.rem(0.5)),
                        /* :: */[
                          Css.transition(50, undefined, undefined, "opacity"),
                          /* [] */0
                        ]
                      ]),
                  /* [] */0
                ]
              ]);
  }
}

function getRoute(queryItem, aperture, projectName, route) {
  if (route !== undefined) {
    return route;
  }
  var sort = Curry._1(Crdb$BsConsole.Query.getSort, queryItem.query);
  var fold = Curry._1(Crdb$BsConsole.Query.getFold, queryItem.query);
  var factor = Curry._1(Crdb$BsConsole.Query.getFactor, queryItem.query);
  return /* ProjectQueryBuilder */Block.__(13, [
            projectName,
            Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), undefined, projectName, factor, fold, sort, undefined, undefined, undefined)
          ]);
}

function DashboardWidgets$Distribution(Props) {
  var queryItem = Props.queryItem;
  var groups = Props.groups;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var colorMapOpt = Props.colorMap;
  var colorRange = Props.colorRange;
  var handleAddFilters = Props.handleAddFilters;
  var handleChangeUrl = Props.handleChangeUrl;
  var aperture = Props.aperture;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var token = Props.token;
  var route = Props.route;
  var analyzeRouteLabelOpt = Props.analyzeRouteLabel;
  var serviceEndpoint = Props.serviceEndpoint;
  var serviceVersion = Props.serviceVersion;
  var versionAttribute = Props.versionAttribute;
  var refetchDashboard = Props.refetchDashboard;
  var config = Props.config;
  var attributes = Props.attributes;
  var colorMap = colorMapOpt !== undefined ? colorMapOpt : [];
  var analyzeRouteLabel = analyzeRouteLabelOpt !== undefined ? analyzeRouteLabelOpt : I18N$BsConsole.get(undefined, "Analyze in Explore");
  var match = React.useState((function () {
          return getData(groups);
        }));
  var setData = match[1];
  var match$1 = match[0];
  var chartData = match$1[1];
  var sortedData = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setHoveredGroup = match$2[1];
  var hoveredGroup = match$2[0];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match$3;
  if (colorRange !== undefined) {
    match$3 = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match$3 = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match$3 = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match$3[1];
  var colors = match$3[0];
  var route$1;
  if (route !== undefined) {
    route$1 = route;
  } else {
    var sort = Curry._1(Crdb$BsConsole.Query.getSort, queryItem.query);
    var fold = Curry._1(Crdb$BsConsole.Query.getFold, queryItem.query);
    var factor = Belt_Option.map(DashboardWidgetData$BsConsole.getCurrentGroup(queryItem, versionAttribute), (function (grpStr) {
            return /* Custom */[grpStr];
          }));
    route$1 = /* ProjectQueryBuilder */Block.__(13, [
        projectName,
        Route$BsConsole.getQueryBuilderParams(Caml_option.some(aperture), undefined, projectName, factor, fold, sort, undefined, undefined, undefined)
      ]);
  }
  React.useEffect((function () {
          var newData = getData(groups);
          Curry._1(setData, (function (param) {
                  return newData;
                }));
          
        }), [groups]);
  var route$2 = getRoute(queryItem, aperture, projectName, route$1);
  var defaultGroup = DashboardWidgetData$BsConsole.getDefaultGroup(queryItem, versionAttribute);
  var currentGroup = Belt_Option.mapWithDefault(DashboardWidgetData$BsConsole.getCurrentGroup(queryItem, versionAttribute), "", Util$BsConsole.identity);
  var editableConfigs = {
    groupBy: {
      currentValue: currentGroup,
      defaultValue: defaultGroup,
      attributes: attributes
    }
  };
  var tmp = {
    queryItem: queryItem
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  var match$4 = Belt_Array.get(sortedData, 0);
  var tmp$1;
  var exit = 0;
  var group;
  if (hoveredGroup !== undefined) {
    group = hoveredGroup;
    exit = 1;
  } else if (match$4 !== undefined) {
    group = match$4;
    exit = 1;
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    tmp$1 = React.createElement(BtTypography$BsConsole.make, {
          variant: /* Body1 */8,
          className: title,
          children: Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " errors (",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " fingerprints ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " devices)",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "%s errors (%s fingerprints %s %s devices)"
                  ]), Numeral$BsConsole.format("0,0", String(group.count)), Numeral$BsConsole.format("0,0", Belt_Option.getWithDefault(group.unique_fingerprint, 1).toString()), "\u2014", Numeral$BsConsole.format("0,0", Belt_Option.getWithDefault(group.unique_guid, 1).toString()))
        });
  }
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: null
                }, React.createElement(DashboardWidgets$Title, tmp), React.createElement(DashboardWidgets$AnalyzeLink, {
                      route: route$2,
                      handleChangeUrl: handleChangeUrl,
                      label: analyzeRouteLabel
                    })), React.createElement(DashboardWidgets$DataContainer, {
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceAround */-485895757,
                        className: Css.style(/* :: */[
                              Css.height(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.height(Css.px(40)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                children: tmp$1
                              }), React.createElement(Col2$BsConsole.make, {
                                children: React.createElement(DashboardWidgets$EditQueryItemConfig, {
                                      config: config,
                                      projectName: projectName,
                                      universeName: universeName,
                                      serviceEndpoint: serviceEndpoint,
                                      serviceVersion: serviceVersion,
                                      queryItem: queryItem,
                                      token: token,
                                      onChange: refetchDashboard,
                                      editableConfigs: editableConfigs
                                    })
                              })), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                            className: distributionChartContainer,
                            children: (function (width, param) {
                                var tmp = {
                                  width: width,
                                  data: chartData,
                                  onDataHover: (function (group) {
                                      var nextGroup = Belt_Option.flatMap(group, (function (param) {
                                              var name = param[0];
                                              return Belt_Array.getBy(groups, (function (group$prime) {
                                                            return group$prime.group === name;
                                                          }));
                                            }));
                                      return Curry._1(setHoveredGroup, (function (param) {
                                                    return nextGroup;
                                                  }));
                                    }),
                                  colorMap: colorMap
                                };
                                var tmp$1 = Belt_Option.map(hoveredGroup, (function (g) {
                                        return g.group;
                                      }));
                                if (tmp$1 !== undefined) {
                                  tmp.emphasizedGroup = Caml_option.valFromOption(tmp$1);
                                }
                                return React.createElement(CondensedDistributionChart$BsConsole.make, tmp);
                              })
                          }), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            alignContent: /* spaceAround */-485895757,
                            flexWrap: /* wrap */-822134326,
                            className: getGroupRowClassName(hoveredGroup, sortedData),
                            children: Belt_Array.mapWithIndex(sortedData, (function (index, group) {
                                    var match = group.group;
                                    var tmp = match === "__rest" ? React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body2 */9,
                                            children: I18N$BsConsole.show(undefined, "Rest of groups")
                                          }) : React.createElement(FormatRenderer$BsConsole.make, {
                                            value: group.group_format_value,
                                            attribute: group.group_format_attribute,
                                            handleChangeUrl: handleChangeUrl,
                                            handleAddFilters: handleAddFilters
                                          });
                                    return React.createElement("div", {
                                                key: group.group + ("__" + String(index)),
                                                className: distributionGroups,
                                                onMouseEnter: (function (param) {
                                                    return Curry._1(setHoveredGroup, (function (param) {
                                                                  return group;
                                                                }));
                                                  }),
                                                onMouseLeave: (function (param) {
                                                    return Curry._1(setHoveredGroup, (function (param) {
                                                                  
                                                                }));
                                                  })
                                              }, React.createElement("div", {
                                                    className: getColorSwatchClassName(getColor(index, group.group, colorMap, colors, fallbackColor))
                                                  }), tmp);
                                  }))
                          })),
                  className: Css.merge(/* :: */[
                        containerBorder,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.padding2(/* zero */-789508312, Css.rem(1)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ])
                }));
}

var Distribution = {
  getData: getData,
  getColor: getColor,
  getColorSwatchClassName: getColorSwatchClassName,
  getGroupRowClassName: getGroupRowClassName,
  getRoute: getRoute,
  make: DashboardWidgets$Distribution
};

var colorMap = [
  /* tuple */[
    "open",
    Colors$BsConsole.qualitativeViz1
  ],
  /* tuple */[
    "in-progress",
    Colors$BsConsole.qualitativeViz2
  ],
  /* tuple */[
    "muted",
    Colors$BsConsole.qualitativeViz3
  ],
  /* tuple */[
    "resolved",
    Colors$BsConsole.qualitativeViz4
  ]
];

function DashboardWidgets$TriageDistribution(Props) {
  var queryItem = Props.queryItem;
  var groups = Props.groups;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var handleAddFilters = Props.handleAddFilters;
  var handleChangeUrl = Props.handleChangeUrl;
  var aperture = Props.aperture;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var token = Props.token;
  var serviceEndpoint = Props.serviceEndpoint;
  var serviceVersion = Props.serviceVersion;
  var versionAttribute = Props.versionAttribute;
  var refetchDashboard = Props.refetchDashboard;
  var config = Props.config;
  var attributes = Props.attributes;
  var route_001 = Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var route = /* ProjectTriage */Block.__(11, [
      projectName,
      route_001,
      undefined
    ]);
  var tmp = {
    queryItem: queryItem,
    groups: groups,
    colorMap: colorMap,
    handleAddFilters: handleAddFilters,
    handleChangeUrl: handleChangeUrl,
    aperture: aperture,
    universeName: universeName,
    projectName: projectName,
    token: token,
    route: route,
    analyzeRouteLabel: I18N$BsConsole.get(undefined, "Analyze in Triage"),
    serviceEndpoint: serviceEndpoint,
    serviceVersion: serviceVersion,
    versionAttribute: versionAttribute,
    refetchDashboard: refetchDashboard,
    config: config,
    attributes: attributes
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  return React.createElement(DashboardWidgets$Distribution, tmp);
}

var TriageDistribution = {
  colorMap: colorMap,
  make: DashboardWidgets$TriageDistribution
};

function getStrokeColor$2(index, colorRange, restColor) {
  return "#" + Belt_Option.getWithDefault(Belt_Array.get(colorRange, index), restColor);
}

function colorSwatch$3(index, colorRange, restColor) {
  return Css.merge(/* :: */[
              colorSwatch,
              /* :: */[
                Css.style(/* :: */[
                      Css.marginLeft(/* zero */-789508312),
                      /* :: */[
                        Css.unsafe("backgroundColor", getStrokeColor$2(index, colorRange, restColor)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]);
}

function getGroupRoute(aperture, attribute, group, projectName) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var __x$1 = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          var match = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var match$1 = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
          switch (match$1.tag | 0) {
            case /* RegularExpression */4 :
                return match !== attribute;
            case /* AtMost */9 :
                return match !== "_tx";
            default:
              return true;
          }
        }), __x);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  group
                ]])
          ]), __x$1);
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture);
  var __x$2 = Crdb$BsConsole.Aperture.$$default;
  var __x$3 = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, __x$2);
  var aperture$1 = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, __x$3);
  return /* ProjectTriage */Block.__(11, [
            projectName,
            Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture$1), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            undefined
          ]);
}

function DashboardWidgets$CompareGroups(Props) {
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var colorRange = Props.colorRange;
  var groups = Props.groups;
  var queryItem = Props.queryItem;
  var groupAttribute = Props.groupAttribute;
  var handleChangeUrl = Props.handleChangeUrl;
  Props.handleAddFilters;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match;
  if (colorRange !== undefined) {
    match = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match[1];
  var colors = match[0];
  var tmp = {
    queryItem: queryItem
  };
  if (onVersionAttributeChange !== undefined) {
    tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
  }
  var metricGroup = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.get(groups, 0), (function (group) {
              return group.summedEvents;
            })), (function (summedEvents) {
          return summedEvents.metricGroup;
        }));
  return React.createElement(DashboardWidgets$Container, {
              children: null
            }, React.createElement(DashboardWidgets$TitleRow, {
                  children: React.createElement(DashboardWidgets$Title, tmp)
                }), React.createElement(DashboardWidgets$DataContainer, {
                  children: React.createElement(BtTableContainer$BsConsole.make, {
                        className: compareTable,
                        children: React.createElement(BtTable$BsConsole.make, {
                              stickyHeader: true,
                              showGrid: false,
                              children: null
                            }, React.createElement(BtTableHead$BsConsole.make, {
                                  className: Css.style(/* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.grey3)),
                                          /* [] */0
                                        ]
                                      ]),
                                  children: React.createElement(BtTableRow$BsConsole.make, {
                                        children: null
                                      }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                            children: I18N$BsConsole.show(undefined, "Release")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: I18N$BsConsole.show(undefined, "User adoption")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: metricGroup !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                        /* String_literal */Block.__(11, [
                                                            "Error-free ",
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* End_of_format */0
                                                              ])
                                                          ]),
                                                        "Error-free %s"
                                                      ]), metricGroup) : I18N$BsConsole.show(undefined, "Error-free sessions")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: I18N$BsConsole.show(undefined, "Error-free users")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: I18N$BsConsole.show(undefined, "New errors")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: I18N$BsConsole.show(undefined, "Unique errors")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            align: /* right */-379319332,
                                            children: I18N$BsConsole.show(undefined, "Total errors")
                                          }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                            children: I18N$BsConsole.show(undefined, "Triage status")
                                          }))
                                }), React.createElement(BtTableBody$BsConsole.make, {
                                  children: null
                                }, Belt_Array.mapWithIndex(groups, (function (index, group) {
                                        var match = group.uniqueEvents;
                                        var tmp;
                                        if (match !== undefined) {
                                          var events = match.events;
                                          var total = match.total;
                                          var percentage = Caml_primitive.caml_float_max(0, (
                                                events > 1 ? events : 1
                                              ) / total);
                                          var numeratorGroupName = I18N$BsConsole.get(undefined, "unique events");
                                          var title = Curry._3(I18N$BsConsole.getf(undefined, /* Format */[
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* Char_literal */Block.__(12, [
                                                            /* " " */32,
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* String_literal */Block.__(11, [
                                                                    " / ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* String_literal */Block.__(11, [
                                                                            " total events",
                                                                            /* End_of_format */0
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ])
                                                          ])
                                                      ]),
                                                    "%s %s / %s total events"
                                                  ]), Numeral$BsConsole.format("0,0", String(events)), numeratorGroupName, Numeral$BsConsole.format("0,0", String(total)));
                                          tmp = React.createElement(Tooltip.default, {
                                                placement: "top",
                                                children: React.createElement("div", {
                                                      className: Css.style(/* :: */[
                                                            Css.display(/* flex */-1010954439),
                                                            /* :: */[
                                                              Css.flexDirection(/* column */-963948842),
                                                              /* :: */[
                                                                Css.alignItems(/* flexEnd */924268066),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ])
                                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.[000]%", Pervasives.string_of_float(percentage))), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                                                          className: Css.style(/* :: */[
                                                                Css.width(Css.pct(100)),
                                                                /* :: */[
                                                                  Css.height(Css.px(10)),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          children: (function (width, height) {
                                                              return React.createElement(LinearGauge$BsConsole.make, {
                                                                          width: width,
                                                                          barHeight: height,
                                                                          denominatorCount: total,
                                                                          numeratorCount: events > 0 ? events : 0,
                                                                          numeratorGroupName: numeratorGroupName
                                                                        });
                                                            })
                                                        })),
                                                title: title
                                              });
                                        } else {
                                          tmp = React.createElement(EmptyValue$BsConsole.make, { });
                                        }
                                        var match$1 = group.summedEvents;
                                        var tmp$1;
                                        if (match$1 !== undefined) {
                                          var events$1 = match$1.events;
                                          var numeratorCount = Belt_Option.getWithDefault(group.uniqueApplicationSessions, group.count);
                                          var match$2 = group.uniqueApplicationSessions;
                                          var numeratorGroupName$1 = match$2 !== undefined ? "unique(application.session)" : "errors";
                                          var percentage$1 = Caml_primitive.caml_float_max(0, 1 - (
                                                numeratorCount > 1 ? numeratorCount : 1
                                              ) / events$1);
                                          var title$1 = Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* Char_literal */Block.__(12, [
                                                            /* " " */32,
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* String_literal */Block.__(11, [
                                                                    " / ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* Char_literal */Block.__(12, [
                                                                            /* " " */32,
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ])
                                                          ])
                                                      ]),
                                                    "%s %s / %s %s"
                                                  ]), Numeral$BsConsole.format("0,0", String(numeratorCount)), numeratorGroupName$1, Numeral$BsConsole.format("0,0", String(events$1)), match$1.metricGroup);
                                          tmp$1 = React.createElement(Tooltip.default, {
                                                placement: "top",
                                                children: React.createElement("div", {
                                                      className: Css.style(/* :: */[
                                                            Css.display(/* flex */-1010954439),
                                                            /* :: */[
                                                              Css.flexDirection(/* column */-963948842),
                                                              /* :: */[
                                                                Css.alignItems(/* flexEnd */924268066),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ])
                                                    }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.[000]%", Pervasives.string_of_float(percentage$1))), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                                                          className: Css.style(/* :: */[
                                                                Css.width(Css.pct(100)),
                                                                /* :: */[
                                                                  Css.height(Css.px(10)),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          children: (function (width, height) {
                                                              return React.createElement(LinearGauge$BsConsole.make, {
                                                                          width: width,
                                                                          barHeight: height,
                                                                          denominatorCount: events$1,
                                                                          numeratorCount: Caml_primitive.caml_int_max(events$1 - numeratorCount | 0, 0),
                                                                          numeratorGroupName: numeratorGroupName$1
                                                                        });
                                                            })
                                                        })),
                                                title: title$1
                                              });
                                        } else {
                                          tmp$1 = React.createElement(EmptyValue$BsConsole.make, { });
                                        }
                                        var match$3 = group.uniqueEvents;
                                        var match$4 = group.uniqueEventErrors;
                                        var tmp$2;
                                        if (match$3 !== undefined) {
                                          if (match$4 !== undefined) {
                                            var events$2 = match$3.events;
                                            var percentage$2 = Caml_primitive.caml_float_max(0, 1 - (
                                                  match$4 > 1 ? match$4 : 1
                                                ) / events$2);
                                            var title$2 = Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              " unique error events / ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* String_literal */Block.__(11, [
                                                                      " unique events",
                                                                      /* End_of_format */0
                                                                    ])
                                                                ])
                                                            ])
                                                        ]),
                                                      "%s unique error events / %s unique events"
                                                    ]), Numeral$BsConsole.format("0,0", String(match$4)), Numeral$BsConsole.format("0,0", String(events$2)));
                                            tmp$2 = React.createElement(Tooltip.default, {
                                                  placement: "top",
                                                  children: React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.display(/* flex */-1010954439),
                                                              /* :: */[
                                                                Css.flexDirection(/* column */-963948842),
                                                                /* :: */[
                                                                  Css.alignItems(/* flexEnd */924268066),
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ])
                                                      }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.[000]%", Pervasives.string_of_float(percentage$2))), React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
                                                            className: Css.style(/* :: */[
                                                                  Css.width(Css.pct(100)),
                                                                  /* :: */[
                                                                    Css.height(Css.px(10)),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            children: (function (width, height) {
                                                                return React.createElement(LinearGauge$BsConsole.make, {
                                                                            width: width,
                                                                            barHeight: height,
                                                                            denominatorCount: events$2,
                                                                            numeratorCount: Caml_primitive.caml_int_max(events$2 - match$4 | 0, 0),
                                                                            numeratorGroupName: "unique events"
                                                                          });
                                                              })
                                                          })),
                                                  title: title$2
                                                });
                                          } else {
                                            tmp$2 = React.createElement(EmptyValue$BsConsole.make, { });
                                          }
                                        } else {
                                          tmp$2 = React.createElement(EmptyValue$BsConsole.make, { });
                                        }
                                        var distribution = group.triageStatusDist;
                                        return React.createElement(BtTableRow$BsConsole.make, {
                                                    children: null,
                                                    key: group.group
                                                  }, React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        children: React.createElement(Row2$BsConsole.make, {
                                                              alignItems: /* center */98248149,
                                                              justifyContent: /* spaceBetween */516682146,
                                                              children: null
                                                            }, React.createElement(Row2$BsConsole.make, {
                                                                  alignItems: /* center */98248149,
                                                                  children: null
                                                                }, React.createElement("div", {
                                                                      className: colorSwatch$3(index, colors, fallbackColor)
                                                                    }), I18N$BsConsole.showSkip(group.group)), React.createElement(Link$BsConsole.Jsx3.make, {
                                                                  route: getGroupRoute(aperture, groupAttribute, group.group, projectName),
                                                                  onClick: (function (param) {
                                                                      return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Overview_Analyze */98]));
                                                                    }),
                                                                  handleChangeUrl: handleChangeUrl,
                                                                  className: Css.merge(/* :: */[
                                                                        "walkthrough--releases--widget_analyze",
                                                                        /* :: */[
                                                                          Css.style(/* :: */[
                                                                                Css.display(/* flex */-1010954439),
                                                                                /* :: */[
                                                                                  Css.alignItems(/* center */98248149),
                                                                                  /* :: */[
                                                                                    Css.important(Css.color(Css.hex(Colors$BsConsole.grey6))),
                                                                                    /* :: */[
                                                                                      Css.marginLeft(Css.em(0.5)),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                                        ariaLabel: "View group in triage",
                                                                        classes: tableIconButton,
                                                                        placement_tooltip: "top",
                                                                        title_tooltip: I18N$BsConsole.get(undefined, "View group in triage"),
                                                                        children: React.createElement(Launch.default, {
                                                                              color: "inherit",
                                                                              fontSize: "inherit"
                                                                            })
                                                                      })
                                                                }))
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: tmp
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: tmp$1
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: tmp$2
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(group.newFingerprints)))
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(group.uniqueFingerprints)))
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        align: /* right */-379319332,
                                                        children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(group.count)))
                                                      }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                        children: distribution !== undefined ? React.createElement(CondensedDistribution$BsConsole.Proto.make, {
                                                                attribute: groupAttribute,
                                                                distribution: distribution,
                                                                groupCount: group.count,
                                                                onExpansion: (function (param) {
                                                                    
                                                                  })
                                                              }) : React.createElement(EmptyValue$BsConsole.make, { })
                                                      }));
                                      })), groups.length === 0 ? I18N$BsConsole.show(undefined, "No data") : null))
                      })
                }));
}

var CompareGroups = {
  getStrokeColor: getStrokeColor$2,
  colorSwatch: colorSwatch$3,
  getGroupRoute: getGroupRoute,
  make: DashboardWidgets$CompareGroups
};

function DashboardWidgets(Props) {
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var serviceVersion = Props.serviceVersion;
  var token = Props.token;
  var universe = Props.universe;
  var queryItem = Props.queryItem;
  var aperture = Props.aperture;
  var apertureForQuery = Props.apertureForQuery;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var versionAttribute = Props.versionAttribute;
  var onVersionAttributeChange = Props.onVersionAttributeChange;
  var dashboard = Props.dashboard;
  var config = Props.config;
  var attributes = Props.attributes;
  var refetchDashboard = Props.refetchDashboard;
  var overrides = Props.overrides;
  var bubbleUpError = Props.bubbleUpError;
  var dataRemote = DashboardWidgetData$BsConsole.useTableData(serviceEndpoint, token, projectName, universe, queryItem, apertureForQuery, versionAttribute, dashboard, overrides, undefined);
  React.useEffect((function () {
          var match = queryItem.variant;
          if (typeof dataRemote === "number") {
            dataRemote === /* NotAsked */0;
          } else if (dataRemote.tag) {
            if (match >= 12) {
              Curry._1(bubbleUpError, dataRemote[0]);
            }
            
          } else {
            var match$1 = dataRemote[0];
            if (match$1.tag === /* PointRatio */7) {
              Curry._1(bubbleUpError, match$1[0].error);
            }
            
          }
          
        }), /* tuple */[
        dataRemote,
        queryItem.variant
      ]);
  var match = queryItem.variant;
  if (typeof dataRemote === "number") {
    return null;
  }
  if (dataRemote.tag) {
    if (match < 10) {
      return null;
    }
    var range = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, apertureForQuery));
    var range_000 = Math.floor(range[0].valueOf() / 1000);
    var range_001 = Math.floor(range[1].valueOf() / 1000);
    var range$1 = /* tuple */[
      range_000,
      range_001
    ];
    var match$1 = queryItem.variant;
    var denominatorGroup = match$1 !== 11 ? "sessions" : "users";
    return React.createElement(DashboardWidgets$RatioBinChart, {
                bins: 32,
                title: queryItem.name,
                queryItem: queryItem,
                range: range$1,
                data: [],
                numeratorGroup: "",
                denominatorGroup: denominatorGroup,
                errorMessage: dataRemote[0]
              });
  }
  var summary = dataRemote[0];
  switch (summary.tag | 0) {
    case /* AggregateTable */0 :
        var match$2 = summary[0];
        var tmp = {
          rows: match$2.rows,
          columns: match$2.columns,
          metadata: match$2.metadata,
          loading: match$2.loading,
          sortingState: match$2.sortingState,
          onSortChange: match$2.setSortingState,
          queryItem: queryItem,
          projectName: projectName,
          aperture: aperture,
          handleChangeUrl: handleChangeUrl,
          handleAddFilters: handleAddFilters
        };
        if (onVersionAttributeChange !== undefined) {
          tmp.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$AggregateTable, tmp);
    case /* GroupedBinChart */1 :
        var match$3 = summary[0];
        var tmp$1 = {
          data: match$3.data,
          bins: match$3.bins,
          loading: match$3.loading,
          queryItem: queryItem,
          versionAttribute: versionAttribute,
          range: match$3.range,
          handleUpdateGroup: match$3.handleUpdateGroup,
          currentGroup: match$3.currentGroup
        };
        if (onVersionAttributeChange !== undefined) {
          tmp$1.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$GroupedBinChart, tmp$1);
    case /* TriageTable */2 :
        var match$4 = summary[0];
        var tmp$2 = {
          rows: match$4.rows,
          columns: match$4.columns,
          metadata: match$4.metadata,
          loading: match$4.loading,
          sortingState: match$4.sortingState,
          onSortChange: match$4.setSortingState,
          containerClassName: match$4.containerClassName,
          handleChangeUrl: handleChangeUrl,
          handleAddFilters: handleAddFilters,
          aperture: aperture,
          apertureForQuery: apertureForQuery,
          projectName: projectName,
          queryItem: queryItem
        };
        if (onVersionAttributeChange !== undefined) {
          tmp$2.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$TriageTable, tmp$2);
    case /* SummaryRow */3 :
        var match$5 = summary[0];
        return React.createElement(DashboardWidgets$SummaryRow, {
                    totalInstances: match$5.totalInstances,
                    totalFingerprints: match$5.totalFingerprints,
                    open_: match$5.open_,
                    in_progress: match$5.in_progress
                  });
    case /* SummaryV2Row */4 :
        return React.createElement(DashboardWidgets$SummaryV2Row, {
                    summary: summary[0]
                  });
    case /* Distribution */5 :
        var tmp$3 = {
          queryItem: queryItem,
          groups: summary[0],
          handleAddFilters: handleAddFilters,
          handleChangeUrl: handleChangeUrl,
          aperture: aperture,
          universeName: universe,
          projectName: projectName,
          token: token,
          serviceEndpoint: serviceEndpoint,
          serviceVersion: serviceVersion,
          versionAttribute: versionAttribute,
          refetchDashboard: refetchDashboard,
          config: config,
          attributes: attributes
        };
        if (onVersionAttributeChange !== undefined) {
          tmp$3.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$Distribution, tmp$3);
    case /* TriageDistribution */6 :
        var tmp$4 = {
          queryItem: queryItem,
          groups: summary[0],
          handleAddFilters: handleAddFilters,
          handleChangeUrl: handleChangeUrl,
          aperture: aperture,
          universeName: universe,
          projectName: projectName,
          token: token,
          serviceEndpoint: serviceEndpoint,
          serviceVersion: serviceVersion,
          versionAttribute: versionAttribute,
          refetchDashboard: refetchDashboard,
          config: config,
          attributes: attributes
        };
        if (onVersionAttributeChange !== undefined) {
          tmp$4.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$TriageDistribution, tmp$4);
    case /* PointRatio */7 :
        var match$6 = summary[0];
        var error = match$6.error;
        return React.createElement(DashboardWidgets$PointRatio, {
                    denominatorCount: match$6.denominatorCount,
                    numeratorCount: match$6.numeratorCount,
                    denominatorGroupName: match$6.denominatorGroupName,
                    numeratorGroupName: match$6.numeratorGroupName,
                    title: Belt_Option.isSome(error) ? queryItem.name : match$6.title,
                    inverse: match$6.inverse,
                    error: Belt_Option.map(error, (function (msg) {
                            var supportLink = Href$BsConsole.Support.make(undefined, /* StabilityMetricWidgets */23);
                            return /* Dom */Block.__(1, [supportLink !== undefined ? React.createElement("div", {
                                              onClick: (function (param) {
                                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Overview_Documentation */99]));
                                                })
                                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                      /* String_literal */Block.__(11, [
                                                          "Error: ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* String_literal */Block.__(11, [
                                                                  ". This likely means you need to configure your SDK to send metric data. To configure this, please see the following ",
                                                                  /* End_of_format */0
                                                                ])
                                                            ])
                                                        ]),
                                                      "Error: %s. This likely means you need to configure your SDK to send metric data. To configure this, please see the following "
                                                    ]), msg), React.createElement("a", {
                                                  className: Css.style(/* :: */[
                                                        Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
                                                        /* :: */[
                                                          Css.important(Css.textDecoration(/* underline */131142924)),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  href: supportLink,
                                                  rel: "noopener noreferrer",
                                                  target: "_blank"
                                                }, I18N$BsConsole.show(undefined, "Stability Metric Widgets documentation")), I18N$BsConsole.showSkip(".")) : null]);
                          }))
                  });
    case /* CompareGroups */8 :
        if (versionAttribute === undefined) {
          return null;
        }
        var tmp$5 = {
          aperture: aperture,
          projectName: projectName,
          groups: summary[0],
          queryItem: queryItem,
          groupAttribute: versionAttribute,
          handleChangeUrl: handleChangeUrl,
          handleAddFilters: handleAddFilters
        };
        if (onVersionAttributeChange !== undefined) {
          tmp$5.onVersionAttributeChange = Caml_option.valFromOption(onVersionAttributeChange);
        }
        return React.createElement(DashboardWidgets$CompareGroups, tmp$5);
    case /* GroupedRatioBins */9 :
        var match$7 = summary[0];
        return React.createElement(DashboardWidgets$RatioBinChart, {
                    bins: match$7.bins,
                    title: match$7.title,
                    queryItem: queryItem,
                    range: match$7.range,
                    data: match$7.data,
                    numeratorGroup: match$7.numeratorGroup,
                    denominatorGroup: match$7.denominatorGroup,
                    inverse: match$7.inverse
                  });
    
  }
}

var make = DashboardWidgets;

exports.Style = Style;
exports.Container = Container;
exports.DataContainer = DataContainer;
exports.filterFingerprintIssues = filterFingerprintIssues;
exports.Title = Title;
exports.TitleRow = TitleRow;
exports.AnalyzeLink = AnalyzeLink;
exports.AggregateTable = AggregateTable;
exports.TriageTable = TriageTable;
exports.GroupedBinChart = GroupedBinChart;
exports.MetricsErrorIcon = MetricsErrorIcon;
exports.RatioBinChart = RatioBinChart;
exports.SummaryTile = SummaryTile;
exports.RatioTile = RatioTile;
exports.SummaryRow = SummaryRow;
exports.SummaryV2Row = SummaryV2Row;
exports.PointRatio = PointRatio;
exports.ChooseVersionSelect = ChooseVersionSelect;
exports.ChooseVersion = ChooseVersion;
exports.AttributesSelect = AttributesSelect;
exports.EditQueryItemConfig = EditQueryItemConfig;
exports.Distribution = Distribution;
exports.TriageDistribution = TriageDistribution;
exports.CompareGroups = CompareGroups;
exports.make = make;
/* container Not a pure module */
