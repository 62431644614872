// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var Colors$BsConsole = require("./Colors.js");
var BtFilterSelect$BsConsole = require("./BtFilterSelect.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* :: */[
          Css.marginLeft(Css.rem(0.25)),
          /* [] */0
        ]
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* :: */[
        Css.selector(".value", /* :: */[
              Css.marginRight(Css.rem(1)),
              /* [] */0
            ]),
        /* :: */[
          Css.selector(".count", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey4)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var Style = {
  label: label,
  select: select,
  iconContainer: iconContainer,
  menuItem: menuItem,
  loading: loading
};

function getQueryFold(attribute) {
  var __x = Crdb$BsConsole.Fold.empty;
  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  attribute,
                  /* Distribution */Block.__(1, [10])
                ]), __x);
}

function removeAttributeFilters(aperture, attribute) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== attribute;
        }), __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function addFilter(aperture, filter) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, filter, __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function makeDistributionQuery(aperture, attribute) {
  var fold = getQueryFold(attribute);
  var queryAperture = removeAttributeFilters(aperture, attribute);
  return /* Aggregate */Block.__(0, [
            queryAperture,
            undefined,
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function hasAttribute(attributes, attribute) {
  return Belt_Option.isSome(Belt_List.getBy(attributes, (function (attr) {
                    if (attr.name === attribute) {
                      return attr.statistics.used;
                    } else {
                      return false;
                    }
                  })));
}

function DistributionValueSelect(Props) {
  var attribute = Props.attribute;
  var label = Props.label;
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var handleSetAperture = Props.handleSetAperture;
  var className = Props.className;
  var icon = Props.icon;
  var view = Props.view;
  var query = React.useMemo((function () {
          return makeDistributionQuery(aperture, attribute);
        }), /* tuple */[
        aperture,
        attribute
      ]);
  var match = React.useState((function () {
          return [/* tuple */[
                    "All",
                    0
                  ]];
        }));
  var setOptions = match[1];
  var match$1 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, attribute + "-type-select");
  var dataRemote = match$1[0];
  React.useEffect((function () {
          if (typeof dataRemote !== "number" && !dataRemote.tag) {
            var rows = dataRemote[0][0][1];
            var totalCount = Belt_Option.getWithDefault(Belt_Option.map(Belt_List.get(rows, 0), (function (row) {
                        return row.count;
                      })), 0);
            var errorTypes = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_List.get(rows, 0), (function (param) {
                            return BtFilterSelect$BsConsole.getDistributionDataFromRow(param, attribute);
                          })), BtFilterSelect$BsConsole.parseDistributionData), []);
            Curry._1(setOptions, (function (param) {
                    return Belt_Array.concat([/* tuple */[
                                  "All",
                                  totalCount
                                ]], errorTypes);
                  }));
          }
          
        }), [dataRemote]);
  var tmp = {
    attribute: attribute,
    label: label,
    name: attribute + "-select",
    options: match[0],
    aperture: aperture,
    handleSetAperture: handleSetAperture,
    loading: dataRemote === /* Loading */1,
    createFilter: (function (value) {
        return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    attribute,
                    /* Equal */Block.__(0, [/* `String */[
                          -976970511,
                          value
                        ]])
                  ]);
      }),
    view: view
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(BtFilterSelect$BsConsole.make, tmp);
}

var make = DistributionValueSelect;

exports.Style = Style;
exports.getQueryFold = getQueryFold;
exports.removeAttributeFilters = removeAttributeFilters;
exports.addFilter = addFilter;
exports.makeDistributionQuery = makeDistributionQuery;
exports.hasAttribute = hasAttribute;
exports.make = make;
/* label Not a pure module */
