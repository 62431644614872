// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DocumentRe = require("bs-webapi/lib/js/src/dom/nodes/DocumentRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Colors$BsConsole = require("./Colors.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");

function getWidth(element) {
  var widthString = element.width;
  if (widthString == null) {
    return ;
  }
  try {
    return Caml_format.caml_int_of_string(widthString.replace("px", ""));
  }
  catch (exn){
    return ;
  }
}

function toggleClass(element, className) {
  var classList = element.classList;
  if (classList.contains(className)) {
    classList.remove(className);
    return ;
  } else {
    classList.add(className);
    return ;
  }
}

function addClass(element, className) {
  var classList = element.classList;
  if (classList.contains(className)) {
    return ;
  } else {
    classList.add(className);
    return ;
  }
}

function removeClass(element, className) {
  var classList = element.classList;
  if (classList.contains(className)) {
    classList.remove(className);
    return ;
  }
  
}

var ColumnWidth = { };

var resizerStyle = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.top(/* zero */-789508312),
                /* :: */[
                  Css.bottom(/* zero */-789508312),
                  /* :: */[
                    Css.width(Css.px(4)),
                    /* :: */[
                      Css.cursor(/* colResize */676920916),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "BtTableColumnWidthResize",
        /* [] */0
      ]
    ]);

var activeStyle = Css.style(/* :: */[
      Css.unsafe("boxShadow", "inset -2px 0 0 #" + (Colors$BsConsole.accent + " !important")),
      /* [] */0
    ]);

var cursorActiveStyle = Css.style(/* :: */[
      Css.selector("*", /* :: */[
            Css.important(Css.cursor(/* colResize */676920916)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector("th.BtTableHeaderCell button", /* :: */[
              Css.important(Css.pointerEvents(/* none */-922086728)),
              /* [] */0
            ]),
        /* :: */[
          Css.selector("th.BtTableHeaderCell .BtTableSortLabel", /* :: */[
                Css.important(Css.pointerEvents(/* none */-922086728)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var cursorActiveStyleMin = Css.style(/* :: */[
      Css.selector("*", /* :: */[
            Css.important(Css.cursor(/* eResize */621258809)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var cursorActiveStyleMax = Css.style(/* :: */[
      Css.selector("*", /* :: */[
            Css.important(Css.cursor(/* wResize */294257099)),
            /* [] */0
          ]),
      /* [] */0
    ]);

function BtEnhancedTableHeaderCell$Resize(Props) {
  var columnWidth = Props.columnWidth;
  var onWidthChange = Props.onWidthChange;
  var minWidth = Props.minWidth;
  var maxWidth = Props.maxWidth;
  var onResizeChange = Props.onResizeChange;
  var resizeRef = React.useRef(null);
  var currentClientWidth = React.useRef(0);
  var pageX = React.useRef(0);
  var handleMouseMove = React.useCallback((function ($$event) {
          var diff = $$event.pageX - pageX.current | 0;
          var current = currentClientWidth.current;
          var newWidth = Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(current + diff | 0, minWidth), maxWidth);
          var match = (current + diff | 0) <= minWidth;
          var match$1 = (current + diff | 0) >= maxWidth;
          if (match) {
            addClass(document.documentElement, cursorActiveStyleMin);
          } else if (match$1) {
            addClass(document.documentElement, cursorActiveStyleMax);
          } else {
            removeClass(document.documentElement, cursorActiveStyleMin);
            removeClass(document.documentElement, cursorActiveStyleMax);
          }
          return Curry._1(onWidthChange, newWidth);
        }), ([]));
  var handleMouseUp = React.useCallback((function ($$event) {
          var match = $$event.button;
          if (match !== 0) {
            return ;
          }
          currentClientWidth.current = 0;
          pageX.current = 0;
          Curry._1(onResizeChange, false);
          removeClass(document.documentElement, cursorActiveStyle);
          removeClass(document.documentElement, cursorActiveStyleMin);
          removeClass(document.documentElement, cursorActiveStyleMax);
          var resize = resizeRef.current;
          if (!(resize == null)) {
            removeClass(resize, activeStyle);
          }
          window.removeEventListener("mousemove", handleMouseMove);
          
        }), ([]));
  return React.createElement("div", {
              ref: resizeRef,
              className: resizerStyle,
              onMouseDown: (function ($$event) {
                  var match = $$event.button;
                  if (columnWidth === undefined) {
                    return ;
                  }
                  if (match !== 0) {
                    return ;
                  }
                  $$event.preventDefault();
                  currentClientWidth.current = columnWidth.width;
                  pageX.current = $$event.pageX;
                  Curry._1(onResizeChange, true);
                  addClass(document.documentElement, cursorActiveStyle);
                  var resize = resizeRef.current;
                  if (!(resize == null)) {
                    addClass(resize, activeStyle);
                  }
                  window.addEventListener("mousemove", handleMouseMove);
                  window.addEventListener("mouseup", handleMouseUp, {
                        capture: false,
                        once: true,
                        passive: false
                      });
                  
                })
            });
}

var Resize = {
  resizerStyle: resizerStyle,
  activeStyle: activeStyle,
  cursorActiveStyle: cursorActiveStyle,
  cursorActiveStyleMin: cursorActiveStyleMin,
  cursorActiveStyleMax: cursorActiveStyleMax,
  make: BtEnhancedTableHeaderCell$Resize
};

var headerStyle = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.selector(".BtTableColumnWidthResize", /* :: */[
                  Css.opacity(1.0),
                  /* [] */0
                ]),
            /* [] */0
          ]),
      /* [] */0
    ]);

var resizeHeader = Css.style(/* :: */[
      Css.selector(".BtTableColumnWidthResize:hover", /* :: */[
            Css.unsafe("boxShadow", "inset -2px 0 0 #" + (Colors$BsConsole.accent + " !important")),
            /* [] */0
          ]),
      /* [] */0
    ]);

var cursorActiveStyle$1 = Css.style(/* :: */[
      Css.selector(".BtTableColumnWidthResize", /* :: */[
            Css.opacity(1.0),
            /* [] */0
          ]),
      /* :: */[
        Css.cursor(/* colResize */676920916),
        /* [] */0
      ]
    ]);

function BtEnhancedTableHeaderCell(Props) {
  var column = Props.column;
  var classNameOpt = Props.className;
  var resizeableOpt = Props.resizeable;
  var draggableOpt = Props.draggable;
  var columnWidth = Props.columnWidth;
  var onWidthChange = Props.onWidthChange;
  var minWidthOpt = Props.minWidth;
  var maxWidthOpt = Props.maxWidth;
  var initialWidthOpt = Props.initialWidth;
  Props.disableSort;
  var disableDropOpt = Props.disableDrop;
  var onDraggingChange = Props.onDraggingChange;
  var onResizeChange = Props.onResizeChange;
  var onDragDrop = Props.onDragDrop;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var resizeable = resizeableOpt !== undefined ? resizeableOpt : false;
  var draggable = draggableOpt !== undefined ? draggableOpt : false;
  var minWidth = minWidthOpt !== undefined ? minWidthOpt : 64;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : 1500;
  var initialWidth = initialWidthOpt !== undefined ? Caml_option.valFromOption(initialWidthOpt) : undefined;
  var disableDrop = disableDropOpt !== undefined ? disableDropOpt : false;
  var headerRef = React.useRef(null);
  React.useLayoutEffect((function () {
          var match = headerRef.current;
          if (columnWidth === undefined && !(match == null)) {
            var match$1 = getWidth(match);
            if (match$1 === undefined) {
              var clientWidth = Belt_Option.getWithDefault(initialWidth, match.clientWidth);
              Curry._1(onWidthChange, Caml_primitive.caml_int_min(clientWidth > minWidth ? clientWidth : minWidth, maxWidth));
            }
            
          }
          
        }), []);
  var handleDragStart = function ($$event) {
    Curry._1(onDraggingChange, column.name);
    var htmlDocument = DocumentRe.asHtmlDocument(document);
    if (htmlDocument !== undefined) {
      var body = Caml_option.valFromOption(htmlDocument).body;
      if (!(body == null)) {
        var dragImage = body.querySelector("#column-drag-image");
        var dragImage$1;
        if (dragImage == null) {
          var el = document.createElement("div");
          el.id = "column-drag-image";
          body.appendChild(el);
          dragImage$1 = el;
        } else {
          dragImage$1 = dragImage;
        }
        dragImage$1.innerText = column.name;
        $$event.dataTransfer.setDragImage(dragImage$1, 20, 20);
      }
      
    }
    $$event.dataTransfer.setData("text/plain", column.name);
    $$event.dataTransfer["effectAllowed"] = "move";
    
  };
  var handleDragEnter = function (_event) {
    var el = headerRef.current;
    if (!(el == null)) {
      return addClass(el, "active-drop");
    }
    
  };
  var handleDragLeave = function (_event) {
    var el = headerRef.current;
    if (!(el == null)) {
      return removeClass(el, "active-drop");
    }
    
  };
  var handleDragOver = function ($$event) {
    $$event.preventDefault();
    
  };
  var handleDragEnd = function ($$event) {
    $$event.preventDefault();
    Curry._1(onDraggingChange, undefined);
    return $$event.dataTransfer.setData("text/plain", null);
  };
  var handleDrop = function ($$event) {
    $$event.preventDefault();
    Curry._1(onDragDrop, column.name);
    return $$event.dataTransfer.setData("text/plain", null);
  };
  var tmp = {
    className: Css.merge(/* :: */[
          headerStyle,
          /* :: */[
            className,
            /* :: */[
              draggable ? "drop-container" : "",
              /* :: */[
                resizeable ? resizeHeader : "",
                /* [] */0
              ]
            ]
          ]
        ]),
    draggable: draggable,
    children: null,
    ref: headerRef
  };
  var tmp$1 = draggable ? handleDragStart : undefined;
  if (tmp$1 !== undefined) {
    tmp.onDragStart = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = draggable ? handleDragEnd : undefined;
  if (tmp$2 !== undefined) {
    tmp.onDragEnd = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = !disableDrop ? handleDragEnter : undefined;
  if (tmp$3 !== undefined) {
    tmp.onDragEnter = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = !disableDrop ? handleDragLeave : undefined;
  if (tmp$4 !== undefined) {
    tmp.onDragLeave = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = !disableDrop ? handleDragOver : undefined;
  if (tmp$5 !== undefined) {
    tmp.onDragOver = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = !disableDrop ? handleDrop : undefined;
  if (tmp$6 !== undefined) {
    tmp.onDrop = Caml_option.valFromOption(tmp$6);
  }
  return React.createElement(BtTableCell$BsConsole.Header.make, tmp, children, resizeable ? React.createElement(BtEnhancedTableHeaderCell$Resize, {
                    columnWidth: columnWidth,
                    onWidthChange: onWidthChange,
                    minWidth: minWidth,
                    maxWidth: maxWidth,
                    onResizeChange: onResizeChange
                  }) : null);
}

var make = BtEnhancedTableHeaderCell;

exports.getWidth = getWidth;
exports.toggleClass = toggleClass;
exports.addClass = addClass;
exports.removeClass = removeClass;
exports.ColumnWidth = ColumnWidth;
exports.Resize = Resize;
exports.headerStyle = headerStyle;
exports.resizeHeader = resizeHeader;
exports.cursorActiveStyle = cursorActiveStyle$1;
exports.make = make;
/* resizerStyle Not a pure module */
