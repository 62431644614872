// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Switch = require("@material-ui/core/Switch");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var InputLabel = require("@material-ui/core/InputLabel");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var ellipsisStyle = Css.style(/* :: */[
      Css.width(Css.px(305)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* [] */0
          ]
        ]
      ]
    ]);

var lastModified = I18N$BsConsole.get(undefined, "Last modified");

function fromString(param) {
  switch (param) {
    case "ccn" :
        return /* CreditCard */0;
    case "env" :
        return /* EnvironmentVariable */3;
    case "key" :
        return /* EncryptionKey */2;
    case "ssn" :
        return /* SocialSecurity */1;
    default:
      return /* NotBuiltin */4;
  }
}

function toString(param) {
  switch (param) {
    case /* CreditCard */0 :
        return "ccn";
    case /* SocialSecurity */1 :
        return "ssn";
    case /* EncryptionKey */2 :
        return "key";
    case /* EnvironmentVariable */3 :
        return "env";
    case /* NotBuiltin */4 :
        return "";
    
  }
}

function toTitle(param) {
  switch (param) {
    case /* CreditCard */0 :
        return I18N$BsConsole.get(undefined, "Credit cards");
    case /* SocialSecurity */1 :
        return I18N$BsConsole.get(undefined, "Social security numbers");
    case /* EncryptionKey */2 :
        return I18N$BsConsole.get(undefined, "Encryption keys");
    case /* EnvironmentVariable */3 :
        return I18N$BsConsole.get(undefined, "Environment variables and module paths");
    case /* NotBuiltin */4 :
        return "";
    
  }
}

var Builtin = {
  fromString: fromString,
  toString: toString,
  toTitle: toTitle
};

var builtinList = /* :: */[
  /* CreditCard */0,
  /* :: */[
    /* SocialSecurity */1,
    /* :: */[
      /* EncryptionKey */2,
      /* :: */[
        /* EnvironmentVariable */3,
        /* [] */0
      ]
    ]
  ]
];

function getAnyBuiltinsEnabled(existingBuiltins) {
  return Belt_Array.some(existingBuiltins, (function (scrubber) {
                return scrubber.enable !== 0;
              }));
}

function lastModifiedString(scrubber) {
  return Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "%s %s"
                ]), lastModified, L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, new Date(Belt_Option.getWithDefault(Belt_Option.map(scrubber.__modify, (function (x) {
                                return x * 1000;
                              })), 0))));
}

function PS_Scrubbers$Main(Props) {
  var config = Props.config;
  var token = Props.token;
  var project = Props.project;
  Props.handleChangeUrl;
  var match = React.useState((function () {
          return [];
        }));
  var setBuiltins = match[1];
  var builtins = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setRegexps = match$1[1];
  var regexps = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setName = match$2[1];
  var name = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setRegexp = match$3[1];
  var regexp = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setCreateNew = match$4[1];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setAnyBuiltinsEnabled = match$5[1];
  var anyBuiltinsEnabled = match$5[0];
  var match$6 = React.useState((function () {
          return 1;
        }));
  var setRefetchScrubbers = match$6[1];
  var match$7 = React.useState((function () {
          return false;
        }));
  var setViewConfig = match$7[1];
  var viewConfig = match$7[0];
  var fetchScrubbers = function (param) {
    var arg = BpgTask$BsConsole.Scrubber.fetchAll;
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), true, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching scrubbers."));
                  }
                  var resultsForThisProject = Belt_Array.keep(results[0], (function (scrubber) {
                          return scrubber.project === project.pid;
                        }));
                  var existingBuiltins = Belt_Array.keep(resultsForThisProject, (function (scrubber) {
                          return Belt_Option.isSome(scrubber.builtin);
                        }));
                  var existingRegexps = Belt_Array.keep(resultsForThisProject, (function (scrubber) {
                          return Belt_Option.isSome(scrubber.regexp);
                        }));
                  var anyBuiltinsEnabled = getAnyBuiltinsEnabled(existingBuiltins);
                  Curry._1(setBuiltins, (function (param) {
                          return existingBuiltins;
                        }));
                  Curry._1(setRegexps, (function (param) {
                          return existingRegexps;
                        }));
                  return Curry._1(setAnyBuiltinsEnabled, (function (param) {
                                return anyBuiltinsEnabled;
                              }));
                }));
  };
  var createScrubber = function (name, regexp, builtin, param) {
    var arg = BpgTask$BsConsole.Scrubber.create({
          id: 0,
          name: name,
          project: project.pid,
          regexp: regexp,
          action: undefined,
          builtin: builtin,
          format: "all",
          target: "all",
          enable: 1,
          __state: undefined,
          __create: undefined,
          __modify: undefined
        });
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), true, (function (results) {
                  if (results.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, results[0])));
                  }
                  var result = results[0];
                  if (builtin !== undefined) {
                    var updated = Belt_Array.concat(builtins, [result]);
                    Curry._1(setBuiltins, (function (param) {
                            return updated;
                          }));
                    var anyBuiltinsEnabled = getAnyBuiltinsEnabled(updated);
                    Curry._1(setAnyBuiltinsEnabled, (function (param) {
                            return anyBuiltinsEnabled;
                          }));
                  }
                  if (regexp === undefined) {
                    return ;
                  }
                  var updated$1 = Belt_Array.concat(regexps, [result]);
                  return Curry._1(setRegexps, (function (param) {
                                return updated$1;
                              }));
                }));
  };
  var toggleScrubber = function (scrubber) {
    var newEnableValue = scrubber.enable === 0 ? 1 : 0;
    var arg = BpgTask$BsConsole.Scrubber.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Scrubber.to_key(scrubber), [/* tuple */[
            "enable",
            newEnableValue
          ]]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var _b = scrubber.builtin;
                  if (_b !== undefined) {
                    var updated = Belt_Array.map(builtins, (function (builtin) {
                            if (builtin.id === scrubber.id) {
                              return {
                                      id: scrubber.id,
                                      name: scrubber.name,
                                      project: scrubber.project,
                                      regexp: scrubber.regexp,
                                      action: scrubber.action,
                                      builtin: scrubber.builtin,
                                      format: scrubber.format,
                                      target: scrubber.target,
                                      enable: newEnableValue,
                                      __state: scrubber.__state,
                                      __create: scrubber.__create,
                                      __modify: Date.now() / 1000 | 0
                                    };
                            } else {
                              return builtin;
                            }
                          }));
                    Curry._1(setBuiltins, (function (param) {
                            return updated;
                          }));
                    var anyBuiltinsEnabled = getAnyBuiltinsEnabled(updated);
                    return Curry._1(setAnyBuiltinsEnabled, (function (param) {
                                  return anyBuiltinsEnabled;
                                }));
                  }
                  var updated$1 = Belt_Array.map(regexps, (function (regexp) {
                          if (regexp.id === scrubber.id) {
                            return {
                                    id: scrubber.id,
                                    name: scrubber.name,
                                    project: scrubber.project,
                                    regexp: scrubber.regexp,
                                    action: scrubber.action,
                                    builtin: scrubber.builtin,
                                    format: scrubber.format,
                                    target: scrubber.target,
                                    enable: newEnableValue,
                                    __state: scrubber.__state,
                                    __create: scrubber.__create,
                                    __modify: Date.now() / 1000 | 0
                                  };
                          } else {
                            return regexp;
                          }
                        }));
                  return Curry._1(setRegexps, (function (param) {
                                return updated$1;
                              }));
                }));
  };
  React.useEffect((function () {
          fetchScrubbers(undefined);
          
        }), /* tuple */[
        token,
        config.universe.name,
        project.name,
        match$6[0]
      ]);
  var close = function (param) {
    Curry._1(setRegexp, (function (param) {
            
          }));
    Curry._1(setName, (function (param) {
            
          }));
    return Curry._1(setCreateNew, (function (param) {
                  return false;
                }));
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.lineHeight(Css.rem(1.2)),
                    /* :: */[
                      Css.wordWrap(/* breakWord */1059921449),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtModal$BsConsole.Default.make, {
                  open: match$4[0],
                  onClose: close,
                  modalWidth: 300,
                  title: I18N$BsConsole.get(undefined, "Create a scrubber"),
                  subtitle: "",
                  divider: true,
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        children: null
                      }, React.createElement("div", undefined, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                                label: I18N$BsConsole.show(undefined, "Name")
                              }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                placeholder: I18N$BsConsole.get(undefined, "IP Addresses"),
                                value: name !== undefined ? name : "",
                                onChange: (function ($$event) {
                                    var value = $$event.target.value;
                                    return Curry._1(setName, (function (param) {
                                                  return value;
                                                }));
                                  })
                              })), React.createElement("div", undefined, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                                label: I18N$BsConsole.show(undefined, "Regular expression")
                              }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                placeholder: "[0-9]+\\.[0-9]+\\.[0-9]+\\.[0-9]+",
                                value: regexp !== undefined ? regexp : "",
                                onChange: (function ($$event) {
                                    var value = $$event.target.value;
                                    return Curry._1(setRegexp, (function (param) {
                                                  return value;
                                                }));
                                  })
                              })), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]),
                            children: null
                          }, React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                disabled: !Belt_Option.isSome(regexp) && !Belt_Option.isSome(name),
                                onClick: (function (param) {
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Scrubbers_Create_Custom */95]));
                                    createScrubber(Belt_Option.getWithDefault(name, ""), Belt_Option.getWithDefault(regexp, ""), undefined, undefined);
                                    return close(undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Save")
                              }), React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                onClick: close,
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              })))
                }), React.createElement("p", {
                  className: Css.style(/* :: */[
                        Css.lineHeight(Css.rem(1.2)),
                        /* :: */[
                          Css.wordWrap(/* breakWord */1059921449),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: featureDescription,
                      children: I18N$BsConsole.show(undefined, "Enable scrubbers to remove sensitive data from minidump files submitted to Backtrace before they are committed to disk. All data regions of the minidump file are scrubbed, including memory, map, registers, and variable regions. The offending information is masked and the over-all structure of the file is unmodified. Scrubbing includes file system paths. Enabled scrubbers take effect on future minidump submissions and cannot be applied to data already on disk.")
                    })), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(24)),
                        /* [] */0
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: featureDescription,
                      children: I18N$BsConsole.show(undefined, "Please note that on-premise instances currently require a coronerd.conf setting to enable the overall use of these scrubbers. Without this config file setting, the scrubbers here will not take effect despite being enabled. Backtrace-hosted instances have this setting enabled automatically.")
                    }), React.createElement(Button.default, {
                      variant: "text",
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(setViewConfig, (function (param) {
                                        return !viewConfig;
                                      }));
                        }),
                      children: viewConfig ? I18N$BsConsole.show(undefined, "Hide config") : I18N$BsConsole.show(undefined, "View config")
                    }), viewConfig ? React.createElement(Paper.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.px(200)),
                                /* :: */[
                                  Css.padding(Css.px(24)),
                                  /* :: */[
                                    Css.marginTop(Css.px(24)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey2)),
                                    /* :: */[
                                      Css.wordBreak(/* breakAll */-323760734),
                                      /* [] */0
                                    ]
                                  ]),
                              children: React.createElement("pre", undefined, I18N$BsConsole.showSkip(JSON.stringify(JSON.parse("{\"data_scrub\":{\"enable\":true}}"), null, 2)))
                            })
                      }) : null), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Built-in data scrubbers")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Our default set of scrubbers provide removal of common forms of sensitive data.")
                }), React.createElement(BtSettings$BsConsole.Paper.make, {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(480)),
                        /* :: */[
                          Css.padding(Css.px(25)),
                          /* :: */[
                            Css.paddingTop(Css.px(10)),
                            /* :: */[
                              Css.paddingBottom(Css.px(10)),
                              /* :: */[
                                pageMarginBottom,
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(48)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(InputLabel.default, {
                          htmlFor: "enable-all-builtins-toggle",
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Heading6 */5,
                                className: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey0)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Enable scrubbing")
                              })
                        }), React.createElement(Switch.default, {
                          id: "enable-all-builtins-toggle",
                          color: "primary",
                          onChange: (function (_form, _bool) {
                              if (anyBuiltinsEnabled) {
                                var promises = Belt_Array.keepMap(builtins, (function (scrubber) {
                                        if (scrubber.enable !== 0) {
                                          return Caml_option.some(new Promise((function (resolve, param) {
                                                            var arg = BpgTask$BsConsole.Scrubber.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Scrubber.to_key(scrubber), [/* tuple */[
                                                                    "enable",
                                                                    0
                                                                  ]]);
                                                            return Curry._2((function (param) {
                                                                            return (function (param$1, param$2) {
                                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                              });
                                                                          })(token), undefined, (function (resp) {
                                                                          if (resp.tag) {
                                                                            return resolve(undefined);
                                                                          } else {
                                                                            return resolve(scrubber);
                                                                          }
                                                                        }));
                                                          })));
                                        }
                                        
                                      }));
                                var __x = Promise.all(promises);
                                var __x$1 = __x.then((function (_results) {
                                        Curry._1(setRefetchScrubbers, (function (prev) {
                                                return prev + 1 | 0;
                                              }));
                                        return Promise.resolve(undefined);
                                      }));
                                __x$1.catch((function (_exn) {
                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error updating scrubbers."));
                                        return Promise.resolve(undefined);
                                      }));
                                return ;
                              }
                              var createPromises = Belt_List.toArray(Belt_List.map(Belt_List.keep(builtinList, (function (builtin) {
                                              var existingBuiltin = Belt_Array.getBy(builtins, (function (existingBuiltin) {
                                                      return fromString(Belt_Option.getWithDefault(existingBuiltin.builtin, "")) === builtin;
                                                    }));
                                              return existingBuiltin === undefined;
                                            })), (function (builtin) {
                                          return new Promise((function (resolve, param) {
                                                        var arg = BpgTask$BsConsole.Scrubber.create({
                                                              id: 0,
                                                              name: toString(builtin),
                                                              project: project.pid,
                                                              regexp: undefined,
                                                              action: undefined,
                                                              builtin: toString(builtin),
                                                              format: "all",
                                                              target: "all",
                                                              enable: 1,
                                                              __state: undefined,
                                                              __create: undefined,
                                                              __modify: undefined
                                                            });
                                                        return Curry._2((function (param) {
                                                                        return (function (param$1, param$2) {
                                                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                          });
                                                                      })(token), undefined, (function (resp) {
                                                                      if (resp.tag) {
                                                                        return resolve(undefined);
                                                                      } else {
                                                                        return resolve(builtin);
                                                                      }
                                                                    }));
                                                      }));
                                        })));
                              var updatePromises = Belt_Array.map(Belt_Array.keep(builtins, (function (builtin) {
                                          return builtin.enable === 0;
                                        })), (function (scrubber) {
                                      return new Promise((function (resolve, param) {
                                                    var arg = BpgTask$BsConsole.Scrubber.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Scrubber.to_key(scrubber), [/* tuple */[
                                                            "enable",
                                                            1
                                                          ]]);
                                                    return Curry._2((function (param) {
                                                                    return (function (param$1, param$2) {
                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                      });
                                                                  })(token), undefined, (function (resp) {
                                                                  if (resp.tag) {
                                                                    return resolve(undefined);
                                                                  } else {
                                                                    return resolve(fromString(Belt_Option.getWithDefault(scrubber.builtin, "")));
                                                                  }
                                                                }));
                                                  }));
                                    }));
                              var __x$2 = Promise.all(Belt_Array.concat(createPromises, updatePromises));
                              __x$2.then((function (_results) {
                                      Curry._1(setRefetchScrubbers, (function (prev) {
                                              return prev + 1 | 0;
                                            }));
                                      return Promise.resolve(undefined);
                                    }));
                              
                            }),
                          checked: anyBuiltinsEnabled
                        })), React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.px(24)),
                            /* :: */[
                              Css.marginRight(Css.px(48)),
                              /* [] */0
                            ]
                          ])
                    }, Belt_List.toArray(Belt_List.map(builtinList, (function (builtin) {
                                var existingBuiltin = Belt_Array.getBy(builtins, (function (existingBuiltin) {
                                        return fromString(Belt_Option.getWithDefault(existingBuiltin.builtin, "")) === builtin;
                                      }));
                                return React.createElement(React.Fragment, {
                                            children: React.createElement(Row2$BsConsole.make, {
                                                  alignItems: /* center */98248149,
                                                  justifyContent: /* spaceBetween */516682146,
                                                  children: null,
                                                  key: toTitle(builtin)
                                                }, React.createElement(Col2$BsConsole.make, {
                                                      children: null
                                                    }, React.createElement("div", undefined, React.createElement(InputLabel.default, {
                                                              htmlFor: "builtin" + (toTitle(builtin) + "-enable-toggle"),
                                                              children: React.createElement(BtTypography$BsConsole.make, {
                                                                    variant: /* Body1 */8,
                                                                    className: Css.style(/* :: */[
                                                                          Css.color(Css.hex(Colors$BsConsole.grey1)),
                                                                          /* [] */0
                                                                        ]),
                                                                    children: I18N$BsConsole.showSkip(toTitle(builtin))
                                                                  })
                                                            })), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                                              variant: /* Caption */11,
                                                              className: Css.style(/* :: */[
                                                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                    /* [] */0
                                                                  ]),
                                                              children: existingBuiltin !== undefined ? lastModifiedString(existingBuiltin) : I18N$BsConsole.show(undefined, "Never enabled")
                                                            }))), React.createElement("div", undefined, React.createElement(Switch.default, {
                                                          id: "builtin" + (toTitle(builtin) + "-enable-toggle"),
                                                          color: "primary",
                                                          onChange: (function (_form, _bool) {
                                                              if (existingBuiltin !== undefined) {
                                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Scrubbers_Toggle_Builtin */94]));
                                                                return toggleScrubber(existingBuiltin);
                                                              } else {
                                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Scrubbers_Create_Builtin */93]));
                                                                return createScrubber(toString(builtin), undefined, toString(builtin), undefined);
                                                              }
                                                            }),
                                                          checked: existingBuiltin !== undefined ? existingBuiltin.enable !== 0 : false
                                                        }))),
                                            key: toTitle(builtin)
                                          });
                              }))))), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Custom data scrubbers")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Custom scrubbers remove POSIX regular expression matches from minidump files.")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setCreateNew, (function (param) {
                                    return true;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create a custom scrubber")
                }), regexps.length === 0 ? null : React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.width(Css.px(480)),
                            /* :: */[
                              Css.padding(Css.px(25)),
                              /* :: */[
                                Css.paddingTop(Css.px(10)),
                                /* :: */[
                                  Css.paddingBottom(Css.px(10)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    },
                    children: Belt_Array.map(regexps, (function (scrubber) {
                            var regex = Belt_Option.getWithDefault(scrubber.regexp, "");
                            return React.createElement(React.Fragment, {
                                        children: React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              justifyContent: /* spaceBetween */516682146,
                                              children: null
                                            }, React.createElement(Col2$BsConsole.make, {
                                                  className: Css.style(/* :: */[
                                                        Css.lineHeight(Css.rem(1.2)),
                                                        /* [] */0
                                                      ]),
                                                  children: React.createElement(Tooltip.default, {
                                                        children: React.createElement(InputLabel.default, {
                                                              classes: {
                                                                root: Css.style(/* :: */[
                                                                      Css.marginTop(Css.px(8)),
                                                                      /* :: */[
                                                                        Css.marginBottom(Css.px(3)),
                                                                        /* [] */0
                                                                      ]
                                                                    ])
                                                              },
                                                              htmlFor: scrubber.name + "-enable-toggle",
                                                              children: null
                                                            }, React.createElement("div", {
                                                                  className: ellipsisStyle
                                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                                      variant: /* Body1 */8,
                                                                      className: Css.style(/* :: */[
                                                                            Css.color(Css.hex(Colors$BsConsole.grey1)),
                                                                            /* [] */0
                                                                          ]),
                                                                      children: I18N$BsConsole.showSkip(scrubber.name)
                                                                    })), React.createElement("div", {
                                                                  className: ellipsisStyle
                                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                                      variant: /* Caption */11,
                                                                      className: Css.style(/* :: */[
                                                                            Css.color(Css.hex(Colors$BsConsole.grey1)),
                                                                            /* :: */[
                                                                              Css.display(/* block */888960333),
                                                                              /* :: */[
                                                                                Css.marginTop(Css.px(8)),
                                                                                /* :: */[
                                                                                  Css.marginBottom(Css.px(8)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]),
                                                                      children: I18N$BsConsole.showSkip(regex)
                                                                    })), React.createElement("div", {
                                                                  className: ellipsisStyle
                                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                                      variant: /* Caption */11,
                                                                      className: Css.style(/* :: */[
                                                                            Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                            /* [] */0
                                                                          ]),
                                                                      children: lastModifiedString(scrubber)
                                                                    }))),
                                                        title: regex.length > 58 ? regex : ""
                                                      })
                                                }), React.createElement("div", undefined, React.createElement(Switch.default, {
                                                      id: scrubber.name + "-enable-toggle",
                                                      color: "primary",
                                                      onChange: (function (_form, _bool) {
                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Scrubbers_Toggle_Custom */97]));
                                                          return toggleScrubber(scrubber);
                                                        }),
                                                      checked: scrubber.enable !== 0
                                                    }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                      ariaLabel: "Delete scrubber",
                                                      onClick: (function (param) {
                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Scrubbers_Delete_Custom */96]));
                                                          var arg = BpgTask$BsConsole.Scrubber.$$delete(scrubber);
                                                          return Curry._2((function (param) {
                                                                          return (function (param$1, param$2) {
                                                                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                            });
                                                                        })(token), true, (function (results) {
                                                                        if (results.tag) {
                                                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, results[0])));
                                                                        }
                                                                        var updated = Belt_Array.keep(regexps, (function (regexp) {
                                                                                return regexp.id !== scrubber.id;
                                                                              }));
                                                                        return Curry._1(setRegexps, (function (param) {
                                                                                      return updated;
                                                                                    }));
                                                                      }));
                                                        }),
                                                      title_tooltip: I18N$BsConsole.get(undefined, "Delete scrubber"),
                                                      children: React.createElement(Delete.default, { })
                                                    }))),
                                        key: scrubber.id
                                      });
                          }))
                  }));
}

var Main = {
  make: PS_Scrubbers$Main
};

function PS_Scrubbers(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        pageMarginBottom,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Privacy control")
                }), project !== undefined ? React.createElement(PS_Scrubbers$Main, {
                    config: config,
                    token: token,
                    project: project,
                    handleChangeUrl: handleChangeUrl
                  }) : React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.show(undefined, "Project not found")
                  }));
}

function make(config, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_Scrubbers, {
              config: config,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_Scrubbers;

exports.pageMarginBottom = pageMarginBottom;
exports.featureDescription = featureDescription;
exports.ellipsisStyle = ellipsisStyle;
exports.lastModified = lastModified;
exports.Builtin = Builtin;
exports.builtinList = builtinList;
exports.getAnyBuiltinsEnabled = getAnyBuiltinsEnabled;
exports.lastModifiedString = lastModifiedString;
exports.Main = Main;
exports.make = make$1;
exports.Re = Re;
/* pageMarginBottom Not a pure module */
