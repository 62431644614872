// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function BugVerticalDivider(Props) {
  var c = Props.color;
  var width = Props.width;
  var onWidthChange = Props.onWidthChange;
  var match = React.useState((function () {
          
        }));
  var setDragBase = match[1];
  var dragBase = match[0];
  var nextDispatchRef = React.useRef(undefined);
  React.useEffect((function () {
          setInterval((function (param) {
                  var w = nextDispatchRef.current;
                  if (w !== undefined) {
                    Curry._1(onWidthChange, w);
                    nextDispatchRef.current = undefined;
                    return ;
                  }
                  
                }), 10);
          
        }), ([]));
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(/* relative */903134412),
                    /* :: */[
                      Css.width(Css.px(2)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.background(Css.hex(c)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              draggable: true,
              onDrag: (function (e) {
                  setTimeout((function (param) {
                          var pageX = Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("backtrace-drag-x")), "0"));
                          if (pageX !== 0 && dragBase !== undefined) {
                            nextDispatchRef.current = dragBase[0] + (pageX - dragBase[1] | 0) | 0;
                            return ;
                          }
                          
                        }), 0);
                  
                }),
              onDragEnd: (function (param) {
                  return Curry._1(setDragBase, (function (param) {
                                
                              }));
                }),
              onDragStart: (function (e) {
                  e.dataTransfer.setDragImage(document.createElement("div"), 0, 0);
                  var pageX = e.pageX;
                  return Curry._1(setDragBase, (function (param) {
                                return /* tuple */[
                                        width,
                                        pageX
                                      ];
                              }));
                })
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.left(Css.px(-5)),
                          /* :: */[
                            Css.top(Css.zero),
                            /* :: */[
                              Css.bottom(Css.zero),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* :: */[
                                  Css.width(Css.px(12)),
                                  /* :: */[
                                    Css.cursor(/* colResize */676920916),
                                    /* :: */[
                                      Css.zIndex(1),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }));
}

var make = BugVerticalDivider;

exports.make = make;
/* Css Not a pure module */
