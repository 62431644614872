// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugHighlightBar$BsConsole = require("./BugHighlightBar.js");
var HideFeatureGate$BsConsole = require("../HideFeatureGate.js");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var AttributesContext$BsConsole = require("../context/AttributesContext.js");
var BugHeaderErrorDates$BsConsole = require("./BugHeaderErrorDates.js");
var BugHeaderClassifiers$BsConsole = require("./BugHeaderClassifiers.js");
var BugHeaderErrorMessage$BsConsole = require("./BugHeaderErrorMessage.js");
var BugHeaderActionButtons$BsConsole = require("./BugHeaderActionButtons.js");

var container = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.padding2(Css.px(10), Css.px(16)),
        /* :: */[
          Css.borderBottom(Css.px(2), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerSecondary)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flexDirection(/* row */5693978),
              /* :: */[
                Css.boxSizing(/* borderBox */9307263),
                /* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.Debugger.white1)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var leftContainer = Css.style(/* :: */[
      Css.width(Css.pct(40)),
      /* [] */0
    ]);

var rightContainer = Css.style(/* :: */[
      Css.width(Css.pct(60)),
      /* [] */0
    ]);

var bottomRow = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* [] */0
    ]);

var Styles = {
  container: container,
  leftContainer: leftContainer,
  rightContainer: rightContainer,
  bottomRow: bottomRow
};

function BugHeader(Props) {
  var config = Props.config;
  var onAddFilter = Props.onAddFilter;
  var errorId = Props.errorId;
  var fingerprint = Props.fingerprint;
  var classifiers = Props.classifiers;
  var time = Props.time;
  var recieved = Props.recieved;
  var compressed = Props.compressed;
  var variableAnnotations = Props.variableAnnotations;
  var project = Props.project;
  var downloadMissingSymsBtnOpt = Props.downloadMissingSymsBtn;
  var onJumpToFaultingFrame = Props.onJumpToFaultingFrame;
  var faulting = Props.faulting;
  var onSelectVariableId = Props.onSelectVariableId;
  var attachments = Props.attachments;
  var universeName = Props.universeName;
  var onAttachmentUploaded = Props.onAttachmentUploaded;
  var missingSymbols = Props.missingSymbols;
  var maybeErrorMessage = Props.maybeErrorMessage;
  var projectName = Props.projectName;
  var attrs = Props.attributes;
  var handleChangeUrl = Props.handleChangeUrl;
  var token = Props.token;
  var downloadMissingSymsBtn = downloadMissingSymsBtnOpt !== undefined ? Caml_option.valFromOption(downloadMissingSymsBtnOpt) : null;
  var describedAttributes = React.useContext(AttributesContext$BsConsole.ctx);
  var attributes = Belt_Array.keep(Belt_SortArray.stableSortBy(Js_dict.entries(attrs), (function (param, param$1) {
              return $$String.compare(param[0].toLowerCase(), param$1[0].toLowerCase());
            })), (function (param) {
          return Attribute_Helpers$BsConsole.RelevantAttributes.isRelevantAttribute(describedAttributes, /* tuple */[
                      param[0],
                      param[1]
                    ]);
        }));
  var tmp;
  if (maybeErrorMessage !== undefined) {
    var attributeName = "error.message";
    var attributeValue = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keep(attributes, (function (attr) {
                      return attr[0] === attributeName;
                    })), 0), /* tuple */[
            attributeName,
            null
          ])[1];
    var maybeProjectAttribute = Belt_List.getBy(describedAttributes.active, (function (pa) {
            return pa.name === attributeName;
          }));
    tmp = React.createElement(BugHeaderErrorMessage$BsConsole.make, {
          projectName: projectName,
          attributeName: attributeName,
          attributeValue: attributeValue,
          maybeProjectAttribute: maybeProjectAttribute,
          onAddFilter: onAddFilter,
          handleChangeUrl: handleChangeUrl
        });
  } else {
    tmp = null;
  }
  var tmp$1 = {
    variableAnnotations: variableAnnotations,
    onSelectVariableId: onSelectVariableId,
    downloadMissingSymsBtn: downloadMissingSymsBtn,
    faulting: faulting,
    onJumpToFaultingFrame: onJumpToFaultingFrame,
    config: config,
    fingerprint: fingerprint,
    token: token,
    project: project,
    errorId: errorId,
    attachments: attachments,
    onAttachmentUploaded: onAttachmentUploaded,
    compressed: compressed,
    universeName: universeName
  };
  if (missingSymbols !== undefined) {
    tmp$1.missingSymbols = Caml_option.valFromOption(missingSymbols);
  }
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: leftContainer
                }, tmp, React.createElement(BugHeaderErrorDates$BsConsole.make, {
                      time: time,
                      recieved: recieved
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* flexStart */662439529,
                      className: bottomRow,
                      children: null
                    }, React.createElement(BugHeaderClassifiers$BsConsole.make, {
                          config: config,
                          classifiers: classifiers,
                          onAddFilter: onAddFilter
                        }), React.createElement(BugHeaderActionButtons$BsConsole.make, tmp$1))), React.createElement("div", {
                  className: rightContainer
                }, React.createElement(HideFeatureGate$BsConsole.make, {
                      shouldHide: BugHighlightBar$BsConsole.shouldHideHighlightBar(config),
                      children: React.createElement(BugHighlightBar$BsConsole.make, {
                            projectName: projectName,
                            config: config,
                            onAddFilter: onAddFilter,
                            attributes: attrs,
                            handleChangeUrl: handleChangeUrl,
                            token: token,
                            errorId: errorId
                          })
                    })));
}

var make = BugHeader;

exports.Styles = Styles;
exports.make = make;
/* container Not a pure module */
