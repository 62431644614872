// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Scale = require("@visx/scale");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  container: container
};

function LinearGauge(Props) {
  var width = Props.width;
  var barHeightOpt = Props.barHeight;
  var denominatorCount = Props.denominatorCount;
  var numeratorCount = Props.numeratorCount;
  var numeratorGroupName = Props.numeratorGroupName;
  var barFill = Props.barFill;
  var backgroundBarFill = Props.backgroundBarFill;
  var barHeight = barHeightOpt !== undefined ? barHeightOpt : 13;
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var barFillColor = barFill !== undefined ? barFill : (
      themeContext !== undefined ? Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDefaultDataVizColor(themeContext), Colors$BsConsole.qualitativeViz1) : Colors$BsConsole.qualitativeViz1
    );
  var xScale = Scale.scaleLinear().range([
          width,
          0
        ]).domain([
        denominatorCount > 1 ? denominatorCount : 1,
        0
      ]);
  return React.createElement("div", {
              className: container
            }, React.createElement("svg", {
                  height: String(barHeight),
                  width: String(width)
                }, React.createElement("rect", {
                      height: String(barHeight),
                      width: String(width),
                      fill: backgroundBarFill !== undefined ? "#" + backgroundBarFill : "#" + Colors$BsConsole.addAlpha(barFillColor, 0.3)
                    }), React.createElement("rect", {
                      key: numeratorGroupName + ("__" + String(numeratorCount)),
                      className: Css.merge(/* :: */[
                            "rect_" + numeratorGroupName,
                            /* :: */[
                              Css.style(/* :: */[
                                    Css.transition(50, undefined, undefined, "fill"),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      height: String(barHeight),
                      width: Pervasives.string_of_float(Curry._1(xScale, numeratorCount)),
                      fill: "#" + barFillColor,
                      x: "0",
                      y: "0"
                    }, React.createElement("title", undefined, I18N$BsConsole.showSkip(numeratorGroupName + (" - " + String(numeratorCount)))))));
}

var make = LinearGauge;

exports.Style = Style;
exports.make = make;
/* container Not a pure module */
