// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BpgController$BsConsole = require("./BpgController.js");

var containerGrid = Css.style(/* :: */[
      Css.maxWidth(Css.px(900)),
      /* :: */[
        Css.marginLeft(/* auto */-1065951377),
        /* :: */[
          Css.marginRight(/* auto */-1065951377),
          /* :: */[
            Css.marginTop(Css.rem(1)),
            /* [] */0
          ]
        ]
      ]
    ]);

var grid = Css.style(/* :: */[
      Css.marginTop(Css.rem(1)),
      /* [] */0
    ]);

var Styles = {
  containerGrid: containerGrid,
  grid: grid
};

var component = ReasonReact.reducerComponent("SettingsNetworkingSSL");

function make(handleChangeUrl, handleTask, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(containerGrid, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V8 */7, undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                              style: {
                                                marginLeft: "-4px"
                                              },
                                              onClick: (function (_event) {
                                                  return Curry._1(handleChangeUrl, /* SettingsNetworking */13);
                                                })
                                            }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardBackspace.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])),
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V8 */7, undefined, undefined, undefined, undefined, undefined, [])),
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(grid, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V8 */7, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Ssl.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                    var msg = state.remote;
                                                    if (typeof msg === "number") {
                                                      return I18N$BsConsole.show(undefined, "...loading");
                                                    }
                                                    if (msg.tag) {
                                                      return I18N$BsConsole.dynamic(msg[0]);
                                                    }
                                                    var _ssl = Belt_List.head(msg[0]);
                                                    if (_ssl !== undefined) {
                                                      return I18N$BsConsole.show(undefined, "..success");
                                                    } else {
                                                      return I18N$BsConsole.show(undefined, "unspecified error");
                                                    }
                                                  })))]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* containerGrid Not a pure module */
