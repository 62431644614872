// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function getSuccess(t$prime) {
  if (typeof t$prime === "number" || t$prime.tag) {
    return ;
  } else {
    return Caml_option.some(t$prime[0]);
  }
}

exports.getSuccess = getSuccess;
/* No side effect */
