// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

function Make(T) {
  var component = ReasonReact.reducerComponent("WithTooltip");
  var make = function (children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                return React.createElement("div", {
                            style: {
                              height: "inherit",
                              position: "relative",
                              width: "inherit"
                            }
                          }, Curry._2(children, self.state, {
                                onUpdate: (function (v) {
                                    return Curry._1(self.send, /* Update */Block.__(1, [v]));
                                  }),
                                onShow: (function (t) {
                                    return Curry._1(self.send, /* Show */Block.__(0, [t]));
                                  }),
                                onHide: (function (param) {
                                    return Curry._1(self.send, /* Hide */0);
                                  })
                              }));
              }),
            initialState: (function (param) {
                return {
                        visibility: /* Closed */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                if (typeof action === "number") {
                  return /* Update */Block.__(0, [{
                              visibility: /* Closed */0
                            }]);
                } else if (action.tag) {
                  return /* Update */Block.__(0, [{
                              visibility: action[0]
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              visibility: /* Open */[action[0]]
                            }]);
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          component: component,
          make: make
        };
}

exports.Make = Make;
/* react Not a pure module */
