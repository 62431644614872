// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Column$BsConsole = require("../../utilities/Column.js");
var Dedupe_FormView$BsConsole = require("./Dedupe_FormView.js");
var Dedupe_RuleList$BsConsole = require("./Dedupe_RuleList.js");

var rowStyle = Css.style(/* :: */[
      Css.marginTop(Css.px(20)),
      /* :: */[
        Css.marginBottom(Css.px(20)),
        /* [] */0
      ]
    ]);

var component = RR$BsConsole.statelessComponent("Dedupe_StaticRuleView-BsConsole");

function make(name, platforms, languages, rules, isEnabled, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var ruleList = Dedupe_FormView$BsConsole.transformRulesToCardData(Dedupe_FormView$BsConsole.parseRuleObjectsFromString(rules));
              var langArr = Dedupe_FormView$BsConsole.LanguageOption.apiStrToUiArr(languages);
              var __x = Belt_Array.keep(langArr, (function (str) {
                      if (str !== "") {
                        return str !== " ";
                      } else {
                        return false;
                      }
                    }));
              var platArr = Dedupe_FormView$BsConsole.PlatformOption.apiStrToUiArr(platforms);
              var __x$1 = Belt_Array.keep(platArr, (function (str) {
                      if (str !== "") {
                        return str !== " ";
                      } else {
                        return false;
                      }
                    }));
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.minHeight(Css.px(250)),
                                        /* :: */[
                                          Css.borderRadius(Css.px(0)),
                                          /* :: */[
                                            Css.minWidth(Css.px(500)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]), undefined, undefined, 0, [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.paddingLeft(Css.px(40)),
                                                          /* :: */[
                                                            Css.paddingRight(Css.px(20)),
                                                            /* :: */[
                                                              Css.paddingTop(Css.px(18)),
                                                              /* :: */[
                                                                Css.paddingBottom(Css.px(15)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.width(Css.px(200)),
                                                                  /* [] */0
                                                                ])
                                                          }, React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.fontSize(Css.px(13)),
                                                                      /* :: */[
                                                                        Css.fontFamily("Roboto"),
                                                                        /* :: */[
                                                                          Css.fontWeight(/* medium */-20425611),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ])
                                                              }, I18N$BsConsole.dynamic(name + (" " + I18N$BsConsole.get(undefined, "Rules")))))])),
                                            React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey7)),
                                                        /* :: */[
                                                          Css.paddingTop(Css.px(1)),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }),
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey7)),
                                                          /* [] */0
                                                        ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                    Css.margin(Css.px(20)),
                                                                    /* :: */[
                                                                      Css.marginLeft(Css.px(20)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]), [React.createElement("div", {
                                                                      className: Css.style(/* :: */[
                                                                            Css.padding(Css.px(5)),
                                                                            /* [] */0
                                                                          ])
                                                                    }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                          /* :: */[
                                                                                            Css.marginBottom(Css.px(20)),
                                                                                            /* :: */[
                                                                                              Css.marginRight(Css.px(140)),
                                                                                              /* :: */[
                                                                                                Css.fontSize(Css.em(0.95)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.show(undefined, "Languages")),
                                                                              React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.marginBottom(Css.px(20)),
                                                                                          /* :: */[
                                                                                            Css.fontSize(Css.em(0.95)),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.showSkip(Belt_Array.reduce(__x, "", (function (acc, curr) {
                                                                                              if (acc === "") {
                                                                                                return curr;
                                                                                              } else {
                                                                                                return acc + (", " + curr);
                                                                                              }
                                                                                            }))))
                                                                            ])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                          /* :: */[
                                                                                            Css.marginBottom(Css.px(20)),
                                                                                            /* :: */[
                                                                                              Css.marginRight(Css.px(85)),
                                                                                              /* :: */[
                                                                                                Css.fontSize(Css.em(0.95)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.show(undefined, "Operating systems")),
                                                                              React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.marginBottom(Css.px(20)),
                                                                                          /* :: */[
                                                                                            Css.fontSize(Css.em(0.95)),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.showSkip(Belt_Array.reduce(__x$1, "", (function (acc, curr) {
                                                                                              if (acc === "") {
                                                                                                return curr;
                                                                                              } else {
                                                                                                return acc + (", " + curr);
                                                                                              }
                                                                                            }))))
                                                                            ])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.marginBottom(Css.px(10)),
                                                                                          /* :: */[
                                                                                            Css.marginRight(Css.px(10)),
                                                                                            /* :: */[
                                                                                              Css.marginTop(Css.px(10)),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ]
                                                                                        ])
                                                                                  }, React.createElement("label", {
                                                                                        className: Css.style(/* :: */[
                                                                                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                              /* :: */[
                                                                                                Css.fontSize(Css.em(0.95)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]),
                                                                                        htmlFor: "ruleset-enabled"
                                                                                      }, I18N$BsConsole.show(undefined, "Ruleset enabled for all errors"))),
                                                                              React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                            undefined,
                                                                                            undefined,
                                                                                            Caml_option.some(ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, undefined, undefined, /* `Bool */[
                                                                                                          737456202,
                                                                                                          isEnabled
                                                                                                        ], (function (param, param$1) {
                                                                                                            
                                                                                                          }), undefined, undefined, undefined, []))),
                                                                                            undefined,
                                                                                            Css.style(/* :: */[
                                                                                                  Css.important(Css.marginBottom(Css.px(15))),
                                                                                                  /* :: */[
                                                                                                    Css.height(Css.px(35)),
                                                                                                    /* :: */[
                                                                                                      Css.padding(Css.px(10)),
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ]
                                                                                                ]),
                                                                                            "ruleset-enabled",
                                                                                            undefined,
                                                                                            undefined,
                                                                                            []
                                                                                          ])))
                                                                            ])))]))])),
                                            React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginTop(Css.px(20)),
                                                        /* :: */[
                                                          Css.marginLeft(Css.px(20)),
                                                          /* :: */[
                                                            Css.marginBottom(Css.px(40)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }, React.createElement("div", {
                                                      className: Css.style(/* :: */[
                                                            Css.fontSize(Css.px(13)),
                                                            /* :: */[
                                                              Css.fontFamily("Roboto"),
                                                              /* :: */[
                                                                Css.fontWeight(/* medium */-20425611),
                                                                /* :: */[
                                                                  Css.marginBottom(Css.px(10)),
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ])
                                                    }, I18N$BsConsole.show(undefined, "Rules")), ReasonReact.element(undefined, undefined, Dedupe_RuleList$BsConsole.make(ruleList, (function (_newList) {
                                                            
                                                          }), (function (param) {
                                                            
                                                          }), (function (_k, _f, _o, _a, _r, _att, _s) {
                                                            
                                                          }), (function (_ruleKey) {
                                                            
                                                          }), true, [])))
                                          ]))])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var mininumHeight = 250;

exports.mininumHeight = mininumHeight;
exports.rowStyle = rowStyle;
exports.component = component;
exports.make = make;
/* rowStyle Not a pure module */
