// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function encode(l) {
  return Json_encode.object_(Belt_List.map(l, (function (param) {
                    return /* tuple */[
                            param[0],
                            Curry._1(param[2], param[1])
                          ];
                  })));
}

function Make(Aperture, TimeAperture$prime, Fold, Factor, Select, SortOrder, Paginate, Havings) {
  var fromJson = function (json$prime) {
    var selectDecoder = function (json) {
      return /* Select */Block.__(1, [
                Json_decode.field("filter", (function (json) {
                        return Curry._1(Aperture.fromJson, json);
                      }), json),
                Json_decode.field("select", Select.fromJson, json),
                Json_decode.withDefault(undefined, (function (param) {
                        return Json_decode.field("order", SortOrder.fromJson, param);
                      }), json),
                undefined
              ]);
    };
    var aggregateDecoder = function (json) {
      return /* Aggregate */Block.__(0, [
                Json_decode.field("filter", Aperture.fromJson, json),
                Json_decode.optional((function (param) {
                        return Json_decode.field("group", Factor.fromJson, param);
                      }), json),
                Json_decode.field("fold", Fold.fromJson, json),
                Json_decode.withDefault(undefined, (function (param) {
                        return Json_decode.field("order", SortOrder.fromJson, param);
                      }), json),
                undefined,
                Json_decode.optional((function (param) {
                        return Json_decode.field("having", Havings.fromJson, param);
                      }), json)
              ]);
    };
    return Json_decode.oneOf(/* :: */[
                selectDecoder,
                /* :: */[
                  aggregateDecoder,
                  /* [] */0
                ]
              ], json$prime);
  };
  var toJson = function (t$prime) {
    if (t$prime.tag) {
      var sort = t$prime[2];
      var select = t$prime[1];
      var aperture = t$prime[0];
      if (sort !== undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "select",
                            Curry._1(Select.toJson, select)
                          ],
                          /* :: */[
                            /* tuple */[
                              "order",
                              Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort))
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          /* :: */[
                            /* tuple */[
                              "filter",
                              Curry._1(Aperture.toJson, aperture)
                            ],
                            /* [] */0
                          ],
                          /* [] */0
                        ]
                      ]));
      } else {
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "filter",
                      Curry._1(Aperture.toJson, aperture)
                    ],
                    /* :: */[
                      /* tuple */[
                        "select",
                        Curry._1(Select.toJson, select)
                      ],
                      /* [] */0
                    ]
                  ]);
      }
    }
    var factor = t$prime[1];
    var aperture$1 = t$prime[0];
    if (factor !== undefined) {
      var sort$1 = t$prime[3];
      var fold = t$prime[2];
      var factor$1 = Caml_option.valFromOption(factor);
      if (sort$1 !== undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            Curry._1(Factor.toJson, factor$1)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toJson, fold)
                            ],
                            /* :: */[
                              /* tuple */[
                                "order",
                                Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort$1))
                              ],
                              /* [] */0
                            ]
                          ]
                        ],
                        /* :: */[
                          /* :: */[
                            /* tuple */[
                              "filter",
                              Curry._1(Aperture.toJson, aperture$1)
                            ],
                            /* [] */0
                          ],
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._1(Havings.toJson, havings)
                                                ],
                                                /* [] */0
                                              ];
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      } else {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            Curry._1(Factor.toJson, factor$1)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toJson, fold)
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          /* :: */[
                            /* tuple */[
                              "filter",
                              Curry._1(Aperture.toJson, aperture$1)
                            ],
                            /* [] */0
                          ],
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._1(Havings.toJson, havings)
                                                ],
                                                /* [] */0
                                              ];
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      }
    }
    var sort$2 = t$prime[3];
    var fold$1 = t$prime[2];
    if (sort$2 !== undefined) {
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toJson, fold$1)
                        ],
                        /* :: */[
                          /* tuple */[
                            "order",
                            Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort$2))
                          ],
                          /* [] */0
                        ]
                      ],
                      /* :: */[
                        /* :: */[
                          /* tuple */[
                            "filter",
                            Curry._1(Aperture.toJson, aperture$1)
                          ],
                          /* [] */0
                        ],
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      return /* :: */[
                                              /* tuple */[
                                                "having",
                                                Curry._1(Havings.toJson, havings)
                                              ],
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    } else {
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toJson, fold$1)
                        ],
                        /* [] */0
                      ],
                      /* :: */[
                        /* :: */[
                          /* tuple */[
                            "filter",
                            Curry._1(Aperture.toJson, aperture$1)
                          ],
                          /* [] */0
                        ],
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      return /* :: */[
                                              /* tuple */[
                                                "having",
                                                Curry._1(Havings.toJson, havings)
                                              ],
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    }
  };
  var toPrivateJson = function (t$prime) {
    if (t$prime.tag) {
      var sort = t$prime[2];
      var aperture = t$prime[0];
      if (sort !== undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "select",
                            Curry._1(Select.toJson, t$prime[1])
                          ],
                          /* :: */[
                            /* tuple */[
                              "order",
                              Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort))
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          Curry._1(Aperture.toPrivateJsonTuple2, aperture),
                          /* [] */0
                        ]
                      ]));
      } else {
        return Json_encode.object_(List.concat(/* :: */[
                        /* [] */0,
                        /* :: */[
                          Curry._1(Aperture.toPrivateJsonTuple2, aperture),
                          /* [] */0
                        ]
                      ]));
      }
    }
    var factor = t$prime[1];
    var aperture$1 = t$prime[0];
    if (factor !== undefined) {
      var sort$1 = t$prime[3];
      var fold = t$prime[2];
      var factor$1 = Caml_option.valFromOption(factor);
      if (sort$1 !== undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            Curry._1(Factor.toJson, factor$1)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toJson, fold)
                            ],
                            /* :: */[
                              /* tuple */[
                                "order",
                                Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort$1))
                              ],
                              /* [] */0
                            ]
                          ]
                        ],
                        /* :: */[
                          Curry._1(Aperture.toPrivateJsonTuple2, aperture$1),
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._1(Havings.toJson, havings)
                                                ],
                                                /* [] */0
                                              ];
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      } else {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            Curry._1(Factor.toJson, factor$1)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toJson, fold)
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          Curry._1(Aperture.toPrivateJsonTuple2, aperture$1),
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._1(Havings.toJson, havings)
                                                ],
                                                /* [] */0
                                              ];
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      }
    }
    var sort$2 = t$prime[3];
    var fold$1 = t$prime[2];
    if (sort$2 !== undefined) {
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toJson, fold$1)
                        ],
                        /* :: */[
                          /* tuple */[
                            "order",
                            Curry._1(SortOrder.toJson, Caml_option.valFromOption(sort$2))
                          ],
                          /* [] */0
                        ]
                      ],
                      /* :: */[
                        Curry._1(Aperture.toPrivateJsonTuple2, aperture$1),
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      return /* :: */[
                                              /* tuple */[
                                                "having",
                                                Curry._1(Havings.toJson, havings)
                                              ],
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    } else {
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toJson, fold$1)
                        ],
                        /* [] */0
                      ],
                      /* :: */[
                        Curry._1(Aperture.toPrivateJsonTuple2, aperture$1),
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      return /* :: */[
                                              /* tuple */[
                                                "having",
                                                Curry._1(Havings.toJson, havings)
                                              ],
                                              /* [] */0
                                            ];
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    }
  };
  var toCrdbFn = function (apertureToCrdbFn, t$prime) {
    if (t$prime.tag) {
      var sort = t$prime[2];
      var select = t$prime[1];
      var aperture = t$prime[0];
      if (sort !== undefined) {
        var pagination = t$prime[3];
        var sort$1 = Caml_option.valFromOption(sort);
        if (pagination === undefined) {
          return Json_encode.object_(List.concat(/* :: */[
                          /* :: */[
                            /* tuple */[
                              "select",
                              Curry._1(Select.toCrdb, select)
                            ],
                            /* :: */[
                              /* tuple */[
                                "order",
                                [Curry._2(SortOrder.toCrdb, sort$1, Fold.empty)]
                              ],
                              /* [] */0
                            ]
                          ],
                          /* :: */[
                            Curry._1(apertureToCrdbFn, aperture),
                            /* [] */0
                          ]
                        ]));
        }
        var pagination$1 = Caml_option.valFromOption(pagination);
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "select",
                            Curry._1(Select.toCrdb, select)
                          ],
                          /* :: */[
                            /* tuple */[
                              "order",
                              [Curry._2(SortOrder.toCrdb, sort$1, Fold.empty)]
                            ],
                            /* :: */[
                              /* tuple */[
                                "offset",
                                Curry._1(Paginate.getOffset, pagination$1)
                              ],
                              /* :: */[
                                /* tuple */[
                                  "limit",
                                  Curry._1(Paginate.getLimit, pagination$1)
                                ],
                                /* [] */0
                              ]
                            ]
                          ]
                        ],
                        /* :: */[
                          Curry._1(apertureToCrdbFn, aperture),
                          /* [] */0
                        ]
                      ]));
      }
      var pagination$2 = t$prime[3];
      if (pagination$2 === undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "select",
                            Curry._1(Select.toCrdb, select)
                          ],
                          /* [] */0
                        ],
                        /* :: */[
                          Curry._1(apertureToCrdbFn, aperture),
                          /* [] */0
                        ]
                      ]));
      }
      var pagination$3 = Caml_option.valFromOption(pagination$2);
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "select",
                          Curry._1(Select.toCrdb, select)
                        ],
                        /* :: */[
                          /* tuple */[
                            "offset",
                            Curry._1(Paginate.getOffset, pagination$3)
                          ],
                          /* :: */[
                            /* tuple */[
                              "limit",
                              Curry._1(Paginate.getLimit, pagination$3)
                            ],
                            /* [] */0
                          ]
                        ]
                      ],
                      /* :: */[
                        Curry._1(apertureToCrdbFn, aperture),
                        /* [] */0
                      ]
                    ]));
    }
    var factor = t$prime[1];
    var aperture$1 = t$prime[0];
    if (factor !== undefined) {
      var sort$2 = t$prime[3];
      var fold = t$prime[2];
      var factor$1 = Caml_option.valFromOption(factor);
      if (sort$2 !== undefined) {
        var pagination$4 = t$prime[4];
        var sort$3 = Caml_option.valFromOption(sort$2);
        if (pagination$4 === undefined) {
          return Json_encode.object_(List.concat(/* :: */[
                          /* :: */[
                            /* tuple */[
                              "group",
                              [Curry._1(Factor.toCrdb, factor$1)]
                            ],
                            /* :: */[
                              /* tuple */[
                                "fold",
                                Curry._1(Fold.toCrdb, fold)
                              ],
                              /* :: */[
                                /* tuple */[
                                  "order",
                                  [Curry._2(SortOrder.toCrdb, sort$3, fold)]
                                ],
                                /* [] */0
                              ]
                            ]
                          ],
                          /* :: */[
                            Curry._1(apertureToCrdbFn, aperture$1),
                            /* :: */[
                              Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                          if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                            return /* [] */0;
                                          } else {
                                            return /* :: */[
                                                    /* tuple */[
                                                      "having",
                                                      Curry._2(Havings.toCrdb, havings, fold)
                                                    ],
                                                    /* [] */0
                                                  ];
                                          }
                                        })), /* [] */0),
                              /* [] */0
                            ]
                          ]
                        ]));
        }
        var pagination$5 = Caml_option.valFromOption(pagination$4);
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            [Curry._1(Factor.toCrdb, factor$1)]
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toCrdb, fold)
                            ],
                            /* :: */[
                              /* tuple */[
                                "order",
                                [Curry._2(SortOrder.toCrdb, sort$3, fold)]
                              ],
                              /* :: */[
                                /* tuple */[
                                  "offset",
                                  Curry._1(Paginate.getOffset, pagination$5)
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "limit",
                                    Curry._1(Paginate.getLimit, pagination$5)
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ],
                        /* :: */[
                          Curry._1(apertureToCrdbFn, aperture$1),
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                          return /* [] */0;
                                        } else {
                                          return /* :: */[
                                                  /* tuple */[
                                                    "having",
                                                    Curry._2(Havings.toCrdb, havings, fold)
                                                  ],
                                                  /* [] */0
                                                ];
                                        }
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      }
      var pagination$6 = t$prime[4];
      if (pagination$6 === undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "group",
                            [Curry._1(Factor.toCrdb, factor$1)]
                          ],
                          /* :: */[
                            /* tuple */[
                              "fold",
                              Curry._1(Fold.toCrdb, fold)
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          Curry._1(apertureToCrdbFn, aperture$1),
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                          return /* [] */0;
                                        } else {
                                          return /* :: */[
                                                  /* tuple */[
                                                    "having",
                                                    Curry._2(Havings.toCrdb, havings, fold)
                                                  ],
                                                  /* [] */0
                                                ];
                                        }
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      }
      var pagination$7 = Caml_option.valFromOption(pagination$6);
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "group",
                          [Curry._1(Factor.toCrdb, factor$1)]
                        ],
                        /* :: */[
                          /* tuple */[
                            "fold",
                            Curry._1(Fold.toCrdb, fold)
                          ],
                          /* :: */[
                            /* tuple */[
                              "offset",
                              Curry._1(Paginate.getOffset, pagination$7)
                            ],
                            /* :: */[
                              /* tuple */[
                                "limit",
                                Curry._1(Paginate.getLimit, pagination$7)
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ],
                      /* :: */[
                        Curry._1(apertureToCrdbFn, aperture$1),
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                        return /* [] */0;
                                      } else {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._2(Havings.toCrdb, havings, fold)
                                                ],
                                                /* [] */0
                                              ];
                                      }
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    }
    var sort$4 = t$prime[3];
    var fold$1 = t$prime[2];
    if (sort$4 !== undefined) {
      var pagination$8 = t$prime[4];
      var sort$5 = Caml_option.valFromOption(sort$4);
      if (pagination$8 === undefined) {
        return Json_encode.object_(List.concat(/* :: */[
                        /* :: */[
                          /* tuple */[
                            "fold",
                            Curry._1(Fold.toCrdb, fold$1)
                          ],
                          /* :: */[
                            /* tuple */[
                              "order",
                              [Curry._2(SortOrder.toCrdb, sort$5, fold$1)]
                            ],
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          Curry._1(apertureToCrdbFn, aperture$1),
                          /* :: */[
                            Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                        if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                          return /* [] */0;
                                        } else {
                                          return /* :: */[
                                                  /* tuple */[
                                                    "having",
                                                    Curry._2(Havings.toCrdb, havings, fold$1)
                                                  ],
                                                  /* [] */0
                                                ];
                                        }
                                      })), /* [] */0),
                            /* [] */0
                          ]
                        ]
                      ]));
      }
      var pagination$9 = Caml_option.valFromOption(pagination$8);
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toCrdb, fold$1)
                        ],
                        /* :: */[
                          /* tuple */[
                            "order",
                            [Curry._2(SortOrder.toCrdb, sort$5, fold$1)]
                          ],
                          /* :: */[
                            /* tuple */[
                              "offset",
                              Curry._1(Paginate.getOffset, pagination$9)
                            ],
                            /* :: */[
                              /* tuple */[
                                "limit",
                                Curry._1(Paginate.getLimit, pagination$9)
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ],
                      /* :: */[
                        Curry._1(apertureToCrdbFn, aperture$1),
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                        return /* [] */0;
                                      } else {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._2(Havings.toCrdb, havings, fold$1)
                                                ],
                                                /* [] */0
                                              ];
                                      }
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    }
    var pagination$10 = t$prime[4];
    if (pagination$10 === undefined) {
      return Json_encode.object_(List.concat(/* :: */[
                      /* :: */[
                        /* tuple */[
                          "fold",
                          Curry._1(Fold.toCrdb, fold$1)
                        ],
                        /* [] */0
                      ],
                      /* :: */[
                        Curry._1(apertureToCrdbFn, aperture$1),
                        /* :: */[
                          Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                      if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                        return /* [] */0;
                                      } else {
                                        return /* :: */[
                                                /* tuple */[
                                                  "having",
                                                  Curry._2(Havings.toCrdb, havings, fold$1)
                                                ],
                                                /* [] */0
                                              ];
                                      }
                                    })), /* [] */0),
                          /* [] */0
                        ]
                      ]
                    ]));
    }
    var pagination$11 = Caml_option.valFromOption(pagination$10);
    return Json_encode.object_(List.concat(/* :: */[
                    /* :: */[
                      /* tuple */[
                        "fold",
                        Curry._1(Fold.toCrdb, fold$1)
                      ],
                      /* :: */[
                        /* tuple */[
                          "offset",
                          Curry._1(Paginate.getOffset, pagination$11)
                        ],
                        /* :: */[
                          /* tuple */[
                            "limit",
                            Curry._1(Paginate.getLimit, pagination$11)
                          ],
                          /* [] */0
                        ]
                      ]
                    ],
                    /* :: */[
                      Curry._1(apertureToCrdbFn, aperture$1),
                      /* :: */[
                        Belt_Option.getWithDefault(Belt_Option.map(t$prime[5], (function (havings) {
                                    if (Curry._2(Havings.eq, Havings.empty, havings)) {
                                      return /* [] */0;
                                    } else {
                                      return /* :: */[
                                              /* tuple */[
                                                "having",
                                                Curry._2(Havings.toCrdb, havings, fold$1)
                                              ],
                                              /* [] */0
                                            ];
                                    }
                                  })), /* [] */0),
                        /* [] */0
                      ]
                    ]
                  ]));
  };
  var toCrdb_ = function (t$prime) {
    return toCrdbFn(Aperture.toCrdbTuple2, t$prime);
  };
  var eq = function (t$prime, t$prime$prime) {
    if (t$prime.tag) {
      var sort$prime = t$prime[2];
      var select$prime = t$prime[1];
      var aperture$prime = t$prime[0];
      if (sort$prime === undefined) {
        if (t$prime$prime.tag && !(t$prime$prime[2] !== undefined || !Curry._2(Aperture.eq, aperture$prime, t$prime$prime[0]))) {
          return Curry._2(Select.eq, select$prime, t$prime$prime[1]);
        } else {
          return false;
        }
      }
      if (!t$prime$prime.tag) {
        return false;
      }
      var sort$prime$prime = t$prime$prime[2];
      if (sort$prime$prime !== undefined && Curry._2(Aperture.eq, aperture$prime, t$prime$prime[0]) && Curry._2(Select.eq, select$prime, t$prime$prime[1])) {
        return Curry._2(SortOrder.eq, Caml_option.valFromOption(sort$prime), Caml_option.valFromOption(sort$prime$prime));
      } else {
        return false;
      }
    }
    var factor$prime = t$prime[1];
    var aperture$prime$1 = t$prime[0];
    if (factor$prime !== undefined) {
      var sort$prime$1 = t$prime[3];
      var fold$prime = t$prime[2];
      var factor$prime$1 = Caml_option.valFromOption(factor$prime);
      if (sort$prime$1 !== undefined) {
        if (t$prime$prime.tag) {
          return false;
        }
        var factor$prime$prime = t$prime$prime[1];
        if (factor$prime$prime === undefined) {
          return false;
        }
        var sort$prime$prime$1 = t$prime$prime[3];
        if (sort$prime$prime$1 !== undefined && Curry._2(Aperture.eq, aperture$prime$1, t$prime$prime[0]) && Curry._2(Factor.eq, factor$prime$1, Caml_option.valFromOption(factor$prime$prime)) && Curry._2(Fold.eq, fold$prime, t$prime$prime[2]) && Curry._2(SortOrder.eq, Caml_option.valFromOption(sort$prime$1), Caml_option.valFromOption(sort$prime$prime$1))) {
          return Curry._2(Havings.eq$prime, t$prime[5], t$prime$prime[5]);
        } else {
          return false;
        }
      }
      if (t$prime$prime.tag) {
        return false;
      }
      var factor$prime$prime$1 = t$prime$prime[1];
      if (factor$prime$prime$1 !== undefined && !(t$prime$prime[3] !== undefined || !(Curry._2(Aperture.eq, aperture$prime$1, t$prime$prime[0]) && Curry._2(Factor.eq, factor$prime$1, Caml_option.valFromOption(factor$prime$prime$1)) && Curry._2(Fold.eq, fold$prime, t$prime$prime[2])))) {
        return Curry._2(Havings.eq$prime, t$prime[5], t$prime$prime[5]);
      } else {
        return false;
      }
    }
    var sort$prime$2 = t$prime[3];
    var fold$prime$1 = t$prime[2];
    if (sort$prime$2 === undefined) {
      if (t$prime$prime.tag || t$prime$prime[1] !== undefined || t$prime$prime[3] !== undefined || !(Curry._2(Aperture.eq, aperture$prime$1, t$prime$prime[0]) && Curry._2(Fold.eq, fold$prime$1, t$prime$prime[2]))) {
        return false;
      } else {
        return Curry._2(Havings.eq$prime, t$prime[5], t$prime$prime[5]);
      }
    }
    if (t$prime$prime.tag) {
      return false;
    }
    if (t$prime$prime[1] !== undefined) {
      return false;
    }
    var sort$prime$prime$2 = t$prime$prime[3];
    if (sort$prime$prime$2 !== undefined && Curry._2(Aperture.eq, aperture$prime$1, t$prime$prime[0]) && Curry._2(Fold.eq, fold$prime$1, t$prime$prime[2]) && Curry._2(SortOrder.eq, Caml_option.valFromOption(sort$prime$2), Caml_option.valFromOption(sort$prime$prime$2))) {
      return Curry._2(Havings.eq$prime, t$prime[5], t$prime$prime[5]);
    } else {
      return false;
    }
  };
  var setPagination = function (pagination, query) {
    if (query.tag) {
      return /* Select */Block.__(1, [
                query[0],
                query[1],
                query[2],
                Caml_option.some(pagination)
              ]);
    } else {
      return /* Aggregate */Block.__(0, [
                query[0],
                query[1],
                query[2],
                query[3],
                Caml_option.some(pagination),
                query[5]
              ]);
    }
  };
  var setAperture = function (aperture, query) {
    if (query.tag) {
      return /* Select */Block.__(1, [
                aperture,
                query[1],
                query[2],
                query[3]
              ]);
    } else {
      return /* Aggregate */Block.__(0, [
                aperture,
                query[1],
                query[2],
                query[3],
                query[4],
                query[5]
              ]);
    }
  };
  var setSort = function (sort, query) {
    if (query.tag) {
      return /* Select */Block.__(1, [
                query[0],
                query[1],
                sort,
                query[3]
              ]);
    } else {
      return /* Aggregate */Block.__(0, [
                query[0],
                query[1],
                query[2],
                sort,
                query[4],
                query[5]
              ]);
    }
  };
  var getAperture = function (query) {
    return query[0];
  };
  var getFactor = function (query) {
    if (query.tag) {
      return ;
    } else {
      return query[1];
    }
  };
  var getSort = function (query) {
    if (query.tag) {
      return query[2];
    } else {
      return query[3];
    }
  };
  var getFold = function (query) {
    if (query.tag) {
      return ;
    } else {
      return Caml_option.some(query[2]);
    }
  };
  var freeze = function (t$prime) {
    if (t$prime.tag) {
      var a = t$prime[0];
      return /* Select */Block.__(1, [
                Curry._2(Aperture.setTimeAperture, Curry._1(TimeAperture$prime.freeze, Curry._1(Aperture.getTimeAperture, a)), a),
                t$prime[1],
                t$prime[2],
                t$prime[3]
              ]);
    }
    var a$1 = t$prime[0];
    return /* Aggregate */Block.__(0, [
              Curry._2(Aperture.setTimeAperture, Curry._1(TimeAperture$prime.freeze, Curry._1(Aperture.getTimeAperture, a$1)), a$1),
              t$prime[1],
              t$prime[2],
              t$prime[3],
              t$prime[4],
              t$prime[5]
            ]);
  };
  var getSelect = function (t$prime) {
    if (t$prime.tag) {
      return Caml_option.some(t$prime[1]);
    }
    
  };
  var getHavings = function (t$prime) {
    if (t$prime.tag) {
      return ;
    } else {
      return t$prime[5];
    }
  };
  var toCrdbTimeless = function (t$prime) {
    return toCrdbFn(Aperture.toCrdbTimelessTuple2, t$prime);
  };
  return {
          toJson: toJson,
          toPrivateJson: toPrivateJson,
          fromJson: fromJson,
          toCrdb_: toCrdb_,
          toCrdbTimeless: toCrdbTimeless,
          eq: eq,
          setPagination: setPagination,
          setAperture: setAperture,
          setSort: setSort,
          freeze: freeze,
          getAperture: getAperture,
          getFactor: getFactor,
          getSort: getSort,
          getFold: getFold,
          getSelect: getSelect,
          getHavings: getHavings
        };
}

exports.encode = encode;
exports.Make = Make;
/* No side effect */
