// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var ReactFlipToolkit = require("react-flip-toolkit");

function make(flipId, inverseFlipId, onAppear, onExit, translate, scale, opacity, children) {
  return ReasonReact.wrapJsForReason(ReactFlipToolkit.Flipped, {
              flipId: Js_null_undefined.fromOption(flipId),
              inverseFlipId: Js_null_undefined.fromOption(inverseFlipId),
              onAppear: Js_null_undefined.fromOption(onAppear),
              onExit: Js_null_undefined.fromOption(onExit),
              translate: Js_null_undefined.fromOption(translate),
              scale: Js_null_undefined.fromOption(scale),
              opacity: Js_null_undefined.fromOption(opacity)
            }, children);
}

var Js = { };

exports.make = make;
exports.Js = Js;
/* ReasonReact Not a pure module */
