// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Base$BsConsole = require("./Base.js");
var Intl$BsConsole = require("../../bindings/Intl.js");
var Util$BsConsole = require("../../util.js");
var Issues$BsConsole = require("./Issues.js");
var ObjectID$BsConsole = require("../../ObjectID.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");

function stringDecoder(json) {
  return /* `String */[
          -976970511,
          Json_decode.string(json)
        ];
}

function floatDecoder(json) {
  try {
    return /* `Float */[
            365180284,
            Json_decode.$$float(json)
          ];
  }
  catch (exn){
    return /* `Float */[
            365180284,
            Number(Json_decode.string(json))
          ];
  }
}

function boolDecoder(json) {
  return /* `Bool */[
          737456202,
          Json_decode.bool(json)
        ];
}

function baseFromJson(json) {
  return Json_decode.oneOf(/* :: */[
              stringDecoder,
              /* :: */[
                floatDecoder,
                /* :: */[
                  boolDecoder,
                  /* [] */0
                ]
              ]
            ], json);
}

function rawFromJson(json) {
  try {
    return /* Raw */Block.__(0, [baseFromJson(json)]);
  }
  catch (exn){
    return ;
  }
}

function noneFromJson(json) {
  try {
    return /* None_ */Block.__(1, [baseFromJson(json)]);
  }
  catch (exn){
    return ;
  }
}

function decodeParseJsonObjectOrDecodeError(json) {
  try {
    return Json.parseOrRaise(Json_decode.string(json));
  }
  catch (exn){
    throw [
          Json_decode.DecodeError,
          "json string parse failure"
        ];
  }
}

function parseIssueStateFromJson(json) {
  try {
    var state = Issues$BsConsole.State.ofString(Json_decode.string(json));
    if (state !== undefined) {
      return /* IssueState */Block.__(14, [state]);
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

function parseTicketsFromJson(json) {
  var fromV0 = function (json) {
    return /* :: */[
            /* Unlinked */Block.__(0, [{
                  short: Json_decode.field("id", Json_decode.string, json),
                  url: Json_decode.field("url", Json_decode.string, json),
                  assignees: undefined
                }]),
            /* [] */0
          ];
  };
  try {
    var tickets = Json_decode.oneOf(/* :: */[
          (function (json) {
              return Json_decode.list((function (json) {
                            return Issues$BsConsole.Ticket.ofJson(json);
                          }), json);
            }),
          /* :: */[
            (function (json) {
                return Json_decode.list((function (json) {
                              return Issues$BsConsole.Ticket.ofJson(json);
                            }), decodeParseJsonObjectOrDecodeError(json));
              }),
            /* :: */[
              fromV0,
              /* :: */[
                (function (json) {
                    return fromV0(decodeParseJsonObjectOrDecodeError(json));
                  }),
                /* [] */0
              ]
            ]
          ]
        ], json);
    return /* IssueTickets */Block.__(13, [tickets]);
  }
  catch (exn){
    return ;
  }
}

function parseCallstackFromJson(json) {
  try {
    var frames = List.map((function (frame) {
            return frame.trim();
          }), Json_decode.oneOf(/* :: */[
              (function (param) {
                  return Json_decode.field("frame", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }),
              /* :: */[
                (function (json) {
                    return Json_decode.field("frame", (function (param) {
                                  return Json_decode.list(Json_decode.string, param);
                                }), Json.parseOrRaise(Json_decode.string(json)));
                  }),
                /* [] */0
              ]
            ], json));
    var raw = Json_decode.string(((function(str) { return str; }))(json));
    return /* Callstack */Block.__(9, [/* tuple */[
                frames,
                raw
              ]]);
  }
  catch (exn){
    return ;
  }
}

var spaceRegex = /\s+/;

function decodeClassifiersAndTags(json) {
  return Belt_List.fromArray(Belt_Array.keepMap(Json_decode.string(json).split(spaceRegex), (function (c) {
                    if (c === "") {
                      return ;
                    } else {
                      return c;
                    }
                  })));
}

function parseClassifiersFromJson(json) {
  var classifiersList;
  try {
    classifiersList = decodeClassifiersAndTags(json);
  }
  catch (exn){
    classifiersList = /* [] */0;
  }
  if (classifiersList && !(classifiersList[0] === "*" && !classifiersList[1])) {
    return /* Classifiers */Block.__(4, [classifiersList]);
  }
  
}

function parseTagsFromJson(json) {
  try {
    return /* IssueTags */Block.__(15, [decodeClassifiersAndTags(json)]);
  }
  catch (exn){
    return ;
  }
}

function parseMemoryAddressFromJson(json) {
  try {
    var mem = Json_decode.oneOf(/* :: */[
          Json_decode.string,
          /* :: */[
            (function (json) {
                return Json_decode.string(JSON.stringify(json));
              }),
            /* [] */0
          ]
        ], json);
    return /* MemoryAddress */Block.__(8, [mem]);
  }
  catch (exn){
    return ;
  }
}

function parseUnixTimestampFromJson(json) {
  try {
    return /* UnixTimestamp */Block.__(2, [new Date(Caml_format.caml_float_of_string(Json_decode.oneOf(/* :: */[
                            Json_decode.string,
                            /* :: */[
                              (function (json) {
                                  return Json_decode.string(JSON.stringify(json));
                                }),
                              /* [] */0
                            ]
                          ], json)) * 1000)]);
  }
  catch (exn){
    return ;
  }
}

function parseGpsTimestampFromJson(json) {
  try {
    return /* GpsTimestamp */Block.__(3, [new Date(Caml_format.caml_float_of_string(Json_decode.oneOf(/* :: */[
                            Json_decode.string,
                            /* :: */[
                              (function (json) {
                                  return Json_decode.string(JSON.stringify(json));
                                }),
                              /* [] */0
                            ]
                          ], json)) * 1000)]);
  }
  catch (exn){
    return ;
  }
}

function parseUuidFromJson(json) {
  try {
    return /* UUID */Block.__(10, [Json_decode.string(json)]);
  }
  catch (exn){
    return ;
  }
}

function parseBitmapFromJson(json) {
  try {
    return /* Bitmap */Block.__(11, [Json_decode.oneOf(/* :: */[
                    (function (param) {
                        return Json_decode.map((function (param) {
                                      switch (param) {
                                        case "0" :
                                            return false;
                                        case "1" :
                                            return true;
                                        default:
                                          throw [
                                                Caml_builtin_exceptions.assert_failure,
                                                /* tuple */[
                                                  "Format.re",
                                                  217,
                                                  24
                                                ]
                                              ];
                                      }
                                    }), Json_decode.string, param);
                      }),
                    /* :: */[
                      (function (param) {
                          return Json_decode.map((function (param) {
                                        if (param === 0) {
                                          return false;
                                        }
                                        if (param === 1) {
                                          return true;
                                        }
                                        throw [
                                              Caml_builtin_exceptions.assert_failure,
                                              /* tuple */[
                                                "Format.re",
                                                224,
                                                24
                                              ]
                                            ];
                                      }), Json_decode.$$int, param);
                        }),
                      /* [] */0
                    ]
                  ], json)]);
  }
  catch (exn){
    return ;
  }
}

function parseObjectIDFromJson(json) {
  try {
    return /* ObjectID */Block.__(17, [Caml_format.caml_int64_of_string(Json_decode.oneOf(/* :: */[
                        Json_decode.string,
                        /* :: */[
                          (function (json) {
                              return Json_decode.string(JSON.stringify(json));
                            }),
                          /* [] */0
                        ]
                      ], json))]);
  }
  catch (exn){
    return ;
  }
}

function parseIpv4FromJson(json) {
  try {
    return /* Ipv4 */Block.__(12, [Json_decode.oneOf(/* :: */[
                    Json_decode.string,
                    /* :: */[
                      (function (json) {
                          return Json_decode.string(JSON.stringify(json));
                        }),
                      /* [] */0
                    ]
                  ], json)]);
  }
  catch (exn){
    return ;
  }
}

function parseBytesFromJson(json) {
  var mem;
  try {
    mem = Json_decode.oneOf(/* :: */[
          stringDecoder,
          /* :: */[
            floatDecoder,
            /* [] */0
          ]
        ], json);
  }
  catch (exn){
    mem = undefined;
  }
  if (mem === undefined) {
    return ;
  }
  if (typeof mem === "number") {
    return ;
  }
  var variant = mem[0];
  if (variant !== -976970511) {
    if (variant !== 3654863) {
      if (variant !== 365180284) {
        return ;
      } else {
        return /* Bytes */Block.__(5, [mem[1].toString()]);
      }
    } else {
      return /* Bytes */Block.__(5, [Int64.to_string(mem[1])]);
    }
  } else {
    return /* Bytes */Block.__(5, [mem[1]]);
  }
}

function parseKiloBytesFromJson(json) {
  var mem;
  try {
    mem = Json_decode.oneOf(/* :: */[
          stringDecoder,
          /* :: */[
            floatDecoder,
            /* [] */0
          ]
        ], json);
  }
  catch (exn){
    mem = undefined;
  }
  if (mem === undefined) {
    return ;
  }
  if (typeof mem === "number") {
    return ;
  }
  var variant = mem[0];
  if (variant !== -976970511) {
    if (variant !== 3654863) {
      if (variant !== 365180284) {
        return ;
      } else {
        return /* KiloBytes */Block.__(6, [mem[1].toString()]);
      }
    } else {
      return /* KiloBytes */Block.__(6, [Int64.to_string(mem[1])]);
    }
  } else {
    return /* KiloBytes */Block.__(6, [mem[1]]);
  }
}

function parseGigaBytesFromJson(json) {
  var mem;
  try {
    mem = Json_decode.oneOf(/* :: */[
          stringDecoder,
          /* :: */[
            floatDecoder,
            /* [] */0
          ]
        ], json);
  }
  catch (exn){
    mem = undefined;
  }
  if (mem === undefined) {
    return ;
  }
  if (typeof mem === "number") {
    return ;
  }
  var variant = mem[0];
  if (variant !== -976970511) {
    if (variant !== 3654863) {
      if (variant !== 365180284) {
        return ;
      } else {
        return /* GigaBytes */Block.__(7, [mem[1].toString()]);
      }
    } else {
      return /* GigaBytes */Block.__(7, [Int64.to_string(mem[1])]);
    }
  } else {
    return /* GigaBytes */Block.__(7, [mem[1]]);
  }
}

function parseSha256FromJson(json) {
  try {
    return /* Raw */Block.__(0, [/* `String */[
                -976970511,
                Json_decode.string(json)
              ]]);
  }
  catch (exn){
    return ;
  }
}

function parseByFormat(format, json) {
  switch (format) {
    case /* UnixTimestampType */0 :
        return parseUnixTimestampFromJson(json);
    case /* GpsTimestampType */1 :
        return parseGpsTimestampFromJson(json);
    case /* CallstackType */2 :
        return parseCallstackFromJson(json);
    case /* ClassifiersType */3 :
        return parseClassifiersFromJson(json);
    case /* TagsType */4 :
        return parseTagsFromJson(json);
    case /* BytesType */5 :
        return parseBytesFromJson(json);
    case /* KiloBytesType */6 :
        return parseKiloBytesFromJson(json);
    case /* GigaBytesType */7 :
        return parseGigaBytesFromJson(json);
    case /* SHA256Type */8 :
        return parseSha256FromJson(json);
    case /* MemoryAddressType */9 :
        return parseMemoryAddressFromJson(json);
    case /* Ipv4Type */10 :
        return parseIpv4FromJson(json);
    case /* TicketType */11 :
        return parseTicketsFromJson(json);
    case /* IssueStateType */12 :
        return parseIssueStateFromJson(json);
    case /* AssigneeType */13 :
    case /* Semver */14 :
    case /* Seconds */15 :
        return rawFromJson(json);
    case /* None_ */16 :
        return noneFromJson(json);
    
  }
}

function parse2PointOh(type_, format, attribute, json) {
  var exit = 0;
  if (type_ === undefined) {
    if (format !== undefined) {
      return parseByFormat(format, json);
    } else {
      return rawFromJson(json);
    }
  }
  if (type_ >= 813535941) {
    if (type_ >= 946856507) {
      if (type_ < 1023055798) {
        return parseUuidFromJson(json);
      }
      exit = 1;
    } else {
      exit = type_ >= 946114815 ? 2 : 1;
    }
  } else if (type_ >= 530205405) {
    if (type_ >= 631276559) {
      return parseBitmapFromJson(json);
    }
    exit = 2;
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        if (format !== undefined) {
          return parseByFormat(format, json);
        } else {
          return rawFromJson(json);
        }
    case 2 :
        var exit$1 = 0;
        if (attribute !== undefined) {
          switch (attribute) {
            case "_tx" :
            case "id" :
                return parseObjectIDFromJson(json);
            default:
              exit$1 = 3;
          }
        } else {
          exit$1 = 3;
        }
        if (exit$1 === 3) {
          if (format !== undefined) {
            return parseByFormat(format, json);
          }
          try {
            return /* Raw */Block.__(0, [floatDecoder(json)]);
          }
          catch (exn){
            return ;
          }
        }
        break;
    
  }
}

function getFormattedBytesWithUnit(value, bytesUnit) {
  try {
    return Intl$BsConsole.getFormattedNumber(Caml_format.caml_float_of_string(value)) + bytesUnit;
  }
  catch (exn){
    return value;
  }
}

function toString(f) {
  switch (f.tag | 0) {
    case /* Raw */0 :
        return Base$BsConsole.toString(f[0]);
    case /* None_ */1 :
        return Base$BsConsole.rawToString(f[0]);
    case /* UnixTimestamp */2 :
    case /* GpsTimestamp */3 :
        return L10N_date_time$BsConsole.Format.numericDay(undefined, f[0]);
    case /* Classifiers */4 :
        return Belt_List.reduce(f[0], "", (function (acc, curr) {
                      return curr + (" " + acc);
                    }));
    case /* Bytes */5 :
        return getFormattedBytesWithUnit(f[0], "B");
    case /* KiloBytes */6 :
        return getFormattedBytesWithUnit(f[0], "KB");
    case /* GigaBytes */7 :
        return getFormattedBytesWithUnit(f[0], "GB");
    case /* MemoryAddress */8 :
        return "0x" + Util$BsConsole.decToHex(f[0]);
    case /* Callstack */9 :
        return f[0][1];
    case /* UUID */10 :
        return f[0];
    case /* Bitmap */11 :
        return Pervasives.string_of_bool(f[0]);
    case /* Ipv4 */12 :
        return Util$BsConsole.formatIpv4(f[0]);
    case /* IssueTickets */13 :
        return Belt_List.reduce(f[0], "", (function (acc, curr) {
                      return Issues$BsConsole.Ticket.getShort(curr) + (" " + acc);
                    }));
    case /* IssueState */14 :
        return Issues$BsConsole.State.toString(f[0]);
    case /* IssueTags */15 :
        return Belt_List.reduce(f[0], "", (function (acc, curr) {
                      return curr + (" " + acc);
                    }));
    case /* IssueAssignees */16 :
        return Belt_List.toArray(Issues$BsConsole.Assignees.getAssigneeUsernames(f[0])).join(", ");
    case /* ObjectID */17 :
        return ObjectID$BsConsole.toHexString(f[0]);
    
  }
}

exports.stringDecoder = stringDecoder;
exports.floatDecoder = floatDecoder;
exports.boolDecoder = boolDecoder;
exports.baseFromJson = baseFromJson;
exports.rawFromJson = rawFromJson;
exports.noneFromJson = noneFromJson;
exports.decodeParseJsonObjectOrDecodeError = decodeParseJsonObjectOrDecodeError;
exports.parseIssueStateFromJson = parseIssueStateFromJson;
exports.parseTicketsFromJson = parseTicketsFromJson;
exports.parseCallstackFromJson = parseCallstackFromJson;
exports.spaceRegex = spaceRegex;
exports.decodeClassifiersAndTags = decodeClassifiersAndTags;
exports.parseClassifiersFromJson = parseClassifiersFromJson;
exports.parseTagsFromJson = parseTagsFromJson;
exports.parseMemoryAddressFromJson = parseMemoryAddressFromJson;
exports.parseUnixTimestampFromJson = parseUnixTimestampFromJson;
exports.parseGpsTimestampFromJson = parseGpsTimestampFromJson;
exports.parseUuidFromJson = parseUuidFromJson;
exports.parseBitmapFromJson = parseBitmapFromJson;
exports.parseObjectIDFromJson = parseObjectIDFromJson;
exports.parseIpv4FromJson = parseIpv4FromJson;
exports.parseBytesFromJson = parseBytesFromJson;
exports.parseKiloBytesFromJson = parseKiloBytesFromJson;
exports.parseGigaBytesFromJson = parseGigaBytesFromJson;
exports.parseSha256FromJson = parseSha256FromJson;
exports.parseByFormat = parseByFormat;
exports.parse2PointOh = parse2PointOh;
exports.getFormattedBytesWithUnit = getFormattedBytesWithUnit;
exports.toString = toString;
/* Base-BsConsole Not a pure module */
