// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Colors$BsConsole = require("../Colors.js");
var Drilldown$BsConsole = require("../Drilldown.js");

function valueDrilldownStyle(alignment) {
  return Css.style(/* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.justifyContent(alignment >= 847852583 ? /* flexStart */662439529 : /* flexEnd */924268066),
                /* :: */[
                  Css.unsafe("fontSize", "inherit"),
                  /* :: */[
                    Css.unsafe("fontFamily", "inherit"),
                    /* :: */[
                      Css.padding(Css.px(4)),
                      /* :: */[
                        Css.borderRadius(Css.px(3)),
                        /* :: */[
                          Css.hover(/* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.userSelect(/* text */-856044371),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function BaseRenderer(Props) {
  var classNameOpt = Props.className;
  var alignmentOpt = Props.alignment;
  var textValue = Props.textValue;
  var filterOptionsOpt = Props.filterOptions;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var alignment = alignmentOpt !== undefined ? alignmentOpt : /* Left */847852583;
  var filterOptions = filterOptionsOpt !== undefined ? filterOptionsOpt : [];
  return React.createElement(Drilldown$BsConsole.FromContext.make, {
              textValue: textValue,
              filterOptions: filterOptions,
              className: Css.merge(/* :: */[
                    valueDrilldownStyle(alignment),
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: React.createElement("span", {
                    className: Css.style(/* :: */[
                          Css.whiteSpace(/* nowrap */867913355),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* :: */[
                              Css.textOverflow(/* ellipsis */166888785),
                              /* [] */0
                            ]
                          ]
                        ])
                  }, children)
            });
}

var make = BaseRenderer;

exports.valueDrilldownStyle = valueDrilldownStyle;
exports.make = make;
/* Css Not a pure module */
