// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Raw$BsConsole = require("./Raw.js");
var I18N$BsConsole = require("../I18N.js");
var Ipv4$BsConsole = require("./Ipv4.js");
var Util$BsConsole = require("../util.js");
var Bytes$BsConsole = require("./Bytes.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var NoFormat$BsConsole = require("./NoFormat.js");
var ObjectID$BsConsole = require("../ObjectID.js");
var Callstack$BsConsole = require("./Callstack.js");
var IssueTags$BsConsole = require("./IssueTags.js");
var EmptyValue$BsConsole = require("./EmptyValue.js");
var IssueState$BsConsole = require("./IssueState.js");
var Classifiers$BsConsole = require("./Classifiers.js");
var BaseRenderer$BsConsole = require("./BaseRenderer.js");
var IssueTickets$BsConsole = require("./IssueTickets.js");
var IssueAssignees$BsConsole = require("./IssueAssignees.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");

function getColumnAlignment(format) {
  switch (format.tag | 0) {
    case /* Raw */0 :
        var value = format[0];
        if (typeof value === "number" || value[0] !== 365180284) {
          return /* left */-944764921;
        } else {
          return /* right */-379319332;
        }
    case /* Bytes */5 :
    case /* MemoryAddress */8 :
        return /* right */-379319332;
    default:
      return /* left */-944764921;
  }
}

function FormatRenderer(Props) {
  var value = Props.value;
  var attribute = Props.attribute;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var classNameOpt = Props.className;
  var config = Props.config;
  var token = Props.token;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var fingerprint = Props.fingerprint;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  if (value === undefined) {
    return React.createElement(EmptyValue$BsConsole.make, { });
  }
  switch (value.tag | 0) {
    case /* Raw */0 :
        return React.createElement(Raw$BsConsole.make, {
                    value: value[0],
                    attribute: attribute,
                    className: className
                  });
    case /* None_ */1 :
        return React.createElement(NoFormat$BsConsole.make, {
                    value: value[0],
                    attribute: attribute,
                    className: className
                  });
    case /* UnixTimestamp */2 :
    case /* GpsTimestamp */3 :
        break;
    case /* Classifiers */4 :
        var tmp = {
          classifiers: Belt_List.toArray(Belt_List.sort(value[0], (function (a, b) {
                      if (b.length > a.length) {
                        return 1;
                      } else {
                        return -1;
                      }
                    })))
        };
        if (handleChangeUrl !== undefined) {
          tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        return React.createElement(Classifiers$BsConsole.make, tmp);
    case /* Bytes */5 :
        return React.createElement(Bytes$BsConsole.make, {
                    value: value[0],
                    attribute: attribute
                  });
    case /* KiloBytes */6 :
        return React.createElement(Bytes$BsConsole.make, {
                    value: value[0],
                    bytesUnit: "KB",
                    attribute: attribute
                  });
    case /* GigaBytes */7 :
        return React.createElement(Bytes$BsConsole.make, {
                    value: value[0],
                    bytesUnit: "GB",
                    attribute: attribute
                  });
    case /* MemoryAddress */8 :
        var value$1 = value[0];
        var filterOptions = [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  value$1
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  value$1
                ]])
          ]
        ];
        return React.createElement(BaseRenderer$BsConsole.make, {
                    className: className,
                    textValue: "0x" + Util$BsConsole.decToHex(value$1),
                    filterOptions: filterOptions,
                    children: I18N$BsConsole.showSkip("0x" + Util$BsConsole.decToHex(value$1))
                  });
    case /* Callstack */9 :
        var match = value[0];
        var callstack = match[0];
        if (!callstack) {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
        var tmp$1 = {
          callstack: callstack,
          raw: match[1],
          attribute: attribute,
          fingerprint: fingerprint
        };
        if (handleChangeUrl !== undefined) {
          tmp$1.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp$1.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        if (config !== undefined) {
          tmp$1.config = Caml_option.valFromOption(config);
        }
        if (token !== undefined) {
          tmp$1.token = Caml_option.valFromOption(token);
        }
        if (aperture !== undefined) {
          tmp$1.aperture = Caml_option.valFromOption(aperture);
        }
        if (projectName !== undefined) {
          tmp$1.projectName = Caml_option.valFromOption(projectName);
        }
        return React.createElement(Callstack$BsConsole.make, tmp$1);
    case /* UUID */10 :
        var value$2 = value[0];
        var filterOptions$1 = [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  value$2
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  value$2
                ]])
          ]
        ];
        return React.createElement(BaseRenderer$BsConsole.make, {
                    className: className,
                    textValue: value$2,
                    filterOptions: filterOptions$1,
                    children: I18N$BsConsole.showSkip(value$2)
                  });
    case /* Bitmap */11 :
        var value$3 = value[0];
        var filterOptions$2 = [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `Bool */[
                  737456202,
                  value$3
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `Bool */[
                  737456202,
                  value$3
                ]])
          ]
        ];
        return React.createElement(BaseRenderer$BsConsole.make, {
                    className: className,
                    textValue: Pervasives.string_of_bool(value$3),
                    filterOptions: filterOptions$2,
                    children: I18N$BsConsole.showSkip(Pervasives.string_of_bool(value$3))
                  });
    case /* Ipv4 */12 :
        return React.createElement(Ipv4$BsConsole.make, {
                    value: value[0],
                    attribute: attribute
                  });
    case /* IssueTickets */13 :
        var tickets = value[0];
        var unlinkedTickets = Belt_Option.getWithDefault(Issues$BsConsole.Tickets.getUnlinked(tickets), /* [] */0);
        var linkedTickets = Belt_Option.getWithDefault(Issues$BsConsole.Tickets.getLinked(tickets), /* [] */0);
        var tickets$1 = Belt_List.toArray(Belt_List.keepMap(Belt_List.keep(Belt_List.map(Belt_List.concat(unlinkedTickets, linkedTickets), (function (ticket) {
                            switch (ticket.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  break;
                              case /* InteralAssigneeLink */2 :
                                  return ;
                              
                            }
                            var t = ticket[0];
                            return /* tuple */[
                                    t.short,
                                    t.url
                                  ];
                          })), Belt_Option.isSome), Util$BsConsole.identity));
        if (tickets$1.length !== 0) {
          return React.createElement(IssueTickets$BsConsole.make, {
                      tickets: tickets$1
                    });
        } else {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
    case /* IssueState */14 :
        return React.createElement(IssueState$BsConsole.make, {
                    state: value[0]
                  });
    case /* IssueTags */15 :
        var tags = Belt_List.toArray(Belt_List.sort(Belt_List.keep(value[0], (function (tag) {
                        return tag.trim() !== "";
                      })), (function (a, b) {
                    if (b.length > a.length) {
                      return 1;
                    } else {
                      return -1;
                    }
                  })));
        if (tags.length === 0) {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
        var tmp$2 = {
          tags: tags
        };
        if (handleChangeUrl !== undefined) {
          tmp$2.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp$2.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        return React.createElement(IssueTags$BsConsole.make, tmp$2);
    case /* IssueAssignees */16 :
        return React.createElement(IssueAssignees$BsConsole.make, {
                    assignees: value[0]
                  });
    case /* ObjectID */17 :
        var value$4 = value[0];
        var filterOptions$3 = [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  ObjectID$BsConsole.toDecString(value$4)
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  ObjectID$BsConsole.toDecString(value$4)
                ]])
          ]
        ];
        return React.createElement(BaseRenderer$BsConsole.make, {
                    className: className,
                    textValue: ObjectID$BsConsole.toHexString(value$4),
                    filterOptions: filterOptions$3,
                    children: I18N$BsConsole.showSkip(ObjectID$BsConsole.toHexString(value$4))
                  });
    
  }
  var value$5 = value[0];
  var filterOptions$4 = attribute !== "timestamp" ? [
      /* tuple */[
        attribute,
        /* AtLeast */Block.__(8, [/* `Date */[
              758940238,
              value$5
            ]])
      ],
      /* tuple */[
        attribute,
        /* AtMost */Block.__(9, [/* `Date */[
              758940238,
              value$5
            ]])
      ]
    ] : [];
  return React.createElement(BaseRenderer$BsConsole.make, {
              className: className,
              textValue: L10N_date_time$BsConsole.Format.numericDay(undefined, value$5),
              filterOptions: filterOptions$4,
              children: I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDay(undefined, value$5))
            });
}

var make = React.memo(FormatRenderer);

exports.getColumnAlignment = getColumnAlignment;
exports.make = make;
/* make Not a pure module */
