// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function initialize(param) {
  window.AppcuesSettings = {
    enableURLDetection: true
  };
  var scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.setAttribute("async", "true");
  scriptTag.setAttribute("src", "//fast.appcues.com/94547.js");
  Belt_Option.map(Belt_Array.get(Array.prototype.slice.call(document.getElementsByTagName("script")), 0), (function (topScript) {
          return Belt_Option.map(Caml_option.nullable_to_opt(topScript.parentNode), (function (head) {
                        return head.insertBefore(scriptTag, topScript);
                      }));
        }));
  
}

function identify(name, universe, project) {
  var project$1 = Belt_Option.getWithDefault(project, "");
  return Curry._3((function identify(name, universe, project) {
        setTimeout(() => {
          try {
            window.Appcues.identify(name, {
              universe: universe,
              project: project
            });
          } catch (err) {
          }
        }, 100)
      }), name, universe, project$1);
}

function track(name) {
  return Curry._1((function track(event) {
        setTimeout(() => {
          try {
            window.Appcues.track(event);
          } catch (err) {
          }
        }, 100)
      }), name);
}

exports.initialize = initialize;
exports.identify = identify;
exports.track = track;
/* No side effect */
