// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Task2$BsConsole = require("./Task2.js");
var WorkflowVersion$BsConsole = require("./workflow/WorkflowVersion.js");

function getUrl(workflowCall, config) {
  var wfVersion = WorkflowVersion$BsConsole.current(config);
  if (typeof workflowCall === "number") {
    if (workflowCall === /* Bpg */0) {
      if (typeof wfVersion === "number") {
        if (wfVersion !== 0) {
          return /* Api */Block.__(0, ["/bpg"]);
        } else {
          return ;
        }
      } else {
        return /* Custom */Block.__(1, [wfVersion[0] + "/legacy/api/bpg"]);
      }
    } else if (typeof wfVersion === "number") {
      if (wfVersion !== 0) {
        return /* Api */Block.__(0, ["/workflow/describe"]);
      } else {
        return ;
      }
    } else {
      return /* Custom */Block.__(1, [wfVersion[0] + "/legacy/describe"]);
    }
  }
  switch (workflowCall.tag | 0) {
    case /* DescribeWatcher */0 :
        var watcherName = workflowCall[0];
        if (typeof wfVersion === "number") {
          if (wfVersion !== 0) {
            return /* Api */Block.__(0, ["/workflow/describe" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "watcher",
                              watcherName
                            ],
                            /* [] */0
                          ])]);
          } else {
            return ;
          }
        } else {
          return /* Custom */Block.__(1, [wfVersion[0] + ("/legacy/describe" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "watcher",
                              watcherName
                            ],
                            /* [] */0
                          ]))]);
        }
    case /* DescribeIntegration */1 :
        var integrationName = workflowCall[0];
        if (typeof wfVersion === "number") {
          if (wfVersion !== 0) {
            return /* Api */Block.__(0, ["/workflow/describe" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "type",
                              integrationName
                            ],
                            /* [] */0
                          ])]);
          } else {
            return ;
          }
        } else {
          return /* Custom */Block.__(1, [wfVersion[0] + ("/legacy/describe" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "type",
                              integrationName
                            ],
                            /* [] */0
                          ]))]);
        }
    case /* Workflow */2 :
        var watcherName$1 = workflowCall[1];
        var projectName = workflowCall[0];
        if (typeof wfVersion === "number") {
          if (wfVersion !== 0) {
            return /* Api */Block.__(0, ["/workflow" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "project",
                              projectName
                            ],
                            /* :: */[
                              /* tuple */[
                                "watcher",
                                watcherName$1
                              ],
                              /* [] */0
                            ]
                          ])]);
          } else {
            return ;
          }
        } else {
          return /* Custom */Block.__(1, [wfVersion[0] + ("/legacy/action" + Task2$BsConsole.generateQueryString(/* :: */[
                            /* tuple */[
                              "project",
                              projectName
                            ],
                            /* :: */[
                              /* tuple */[
                                "watcher",
                                watcherName$1
                              ],
                              /* [] */0
                            ]
                          ]))]);
        }
    
  }
}

function getTicketCreate(integration) {
  var __x = Js_dict.entries(integration.actions);
  var match = Belt_Option.getExn(Caml_option.undefined_to_opt(__x.find((function (param) {
                  return param[0] === "ticket:create";
                }))));
  var request = match[1].request;
  var schema = request.schema;
  var fields = schema.fields;
  var customFieldsArray = Belt_Option.getWithDefault(Belt_Option.map(integration.customFields, (function (fields) {
              return [fields];
            })), []);
  return {
          title: request.title,
          description: request.description,
          schema: {
            fields: customFieldsArray.length !== 0 ? Belt_Array.concat(fields, customFieldsArray) : fields
          },
          autoFocus: request.autoFocus
        };
}

exports.getUrl = getUrl;
exports.getTicketCreate = getTicketCreate;
/* Task2-BsConsole Not a pure module */
