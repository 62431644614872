// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ProjectTriageList$BsConsole = require("./ProjectTriageList.js");
var ProjectTriageDetails$BsConsole = require("./ProjectTriageDetails.js");

function ProjectTriage(Props) {
  var fold = Props.fold;
  var view = Props.view;
  var attributes = Props.attributes;
  var havings = Props.havings;
  var aperture = Props.aperture;
  var config = Props.config;
  var sort = Props.sort;
  var stats = Props.stats;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var projectName = Props.projectName;
  Props.universe;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var route = Props.route;
  var normBy = Props.normBy;
  var similarity = Props.similarity;
  var metricsAttributesOpt = Props.metricsAttributes;
  var stabilityScoreOpt = Props.stabilityScore;
  var maybeWfLinkTx = Props.maybeWfLinkTx;
  var metricsAttributes = metricsAttributesOpt !== undefined ? metricsAttributesOpt : /* [] */0;
  var stabilityScore = stabilityScoreOpt !== undefined ? Caml_option.valFromOption(stabilityScoreOpt) : undefined;
  if (view) {
    return React.createElement(ProjectTriageDetails$BsConsole.make, {
                fold: fold,
                attributes: attributes,
                havings: havings,
                aperture: aperture,
                config: config,
                sort: sort,
                stats: stats,
                token: token,
                handleTask: handleTask,
                projectName: projectName,
                universe: config.universe.name,
                handleChangeUrl: handleChangeUrl,
                handleAddFilters: handleAddFilters,
                route: route,
                normBy: normBy,
                similarity: similarity,
                metricsAttributes: metricsAttributes,
                maybeWfLinkTx: maybeWfLinkTx
              });
  } else {
    return React.createElement(ProjectTriageList$BsConsole.make, {
                fold: fold,
                attributes: attributes,
                havings: havings,
                aperture: aperture,
                config: config,
                sort: sort,
                stats: stats,
                token: token,
                handleTask: handleTask,
                projectName: projectName,
                universe: config.universe.name,
                handleChangeUrl: handleChangeUrl,
                handleAddFilters: handleAddFilters,
                route: route,
                normBy: normBy,
                metricsAttributes: metricsAttributes,
                stabilityScore: stabilityScore
              });
  }
}

var make = ProjectTriage;

exports.make = make;
/* react Not a pure module */
