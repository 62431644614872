// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Backtrace$BsConsole = require("./bindings/Backtrace.js");

function language_load_failure(languageCode, error) {
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "I18N_report.language_load_failure", Backtrace$BsConsole.Attributes.addString("i18n.language", languageCode, Backtrace$BsConsole.Attributes.addString("i18n.load.failure", "I18N_Language.File.load", Backtrace$BsConsole.Attributes.make(undefined))));
  return Backtrace$BsConsole.Client.send(__x, /* `string */[
              288368849,
              error
            ]);
}

exports.language_load_failure = language_load_failure;
/* Backtrace-BsConsole Not a pure module */
