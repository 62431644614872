// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function handleDomFormChange(handleChange, $$event) {
  return Curry._1(handleChange, $$event.target.value);
}

function handleDomFormSubmit(handleSubmit, $$event) {
  $$event.preventDefault();
  return Curry._1(handleSubmit, undefined);
}

exports.handleDomFormChange = handleDomFormChange;
exports.handleDomFormSubmit = handleDomFormSubmit;
/* No side effect */
