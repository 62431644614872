// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var Task2$BsConsole = require("../Task2.js");
var SimilarityCandidate$BsConsole = require("./SimilarityCandidate.js");

function summaryFromJson(json) {
  return {
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          state: Json_decode.optional((function (param) {
                  return Json_decode.field("state", Json_decode.string, param);
                }), json),
          candidateInstances: Json_decode.field("candidateInstances", Json_decode.$$int, json),
          candidates: Json_decode.field("candidates", Json_decode.$$int, json),
          dates: Json_decode.field("dates", (function (param) {
                  return Json_decode.list(Json_decode.$$int, param);
                }), json),
          groupedByDistance: Json_decode.field("groupedByDistance", (function (param) {
                  return Json_decode.list(Json_decode.$$int, param);
                }), json)
        };
}

function summaryResponseFromJson(json) {
  return {
          status: Json_decode.field("status", Json_decode.string, json),
          results: Json_decode.field("results", (function (param) {
                  return Json_decode.list(summaryFromJson, param);
                }), json)
        };
}

function fetchSummary(path, project, aperture, limit, threshold, truncate, intersection, distance) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "project",
          project
        ],
        /* :: */[
          /* tuple */[
            "limit",
            limit
          ],
          /* :: */[
            /* tuple */[
              "filter",
              Curry._1(Crdb$BsConsole.Aperture.toCrdbJson, aperture)
            ],
            /* :: */[
              /* tuple */[
                "candidacy",
                SimilarityCandidate$BsConsole.candidacyToJson(threshold, truncate, intersection, distance, undefined)
              ],
              /* [] */0
            ]
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + "/summary")]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                var result = summaryResponseFromJson(respBag.json);
                return /* Ok */Block.__(0, [result]);
              }), undefined, undefined);
}

exports.summaryFromJson = summaryFromJson;
exports.summaryResponseFromJson = summaryResponseFromJson;
exports.fetchSummary = fetchSummary;
/* Crdb-BsConsole Not a pure module */
