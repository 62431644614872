// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");

var L10N_date_time_settings_missing = Caml_exceptions.create("L10N_date_time_config_cache-BsConsole.L10N_date_time_settings_missing");

function Make(TARGET) {
  var store = {
    contents: undefined
  };
  var set = function (t) {
    store.contents = Caml_option.some(t);
    return t;
  };
  var get = function (param) {
    return store.contents;
  };
  var getOr = function (otherwise) {
    var settings = store.contents;
    if (settings !== undefined) {
      return Caml_option.valFromOption(settings);
    } else {
      return set(otherwise);
    }
  };
  var getOrLazy = function (otherwise) {
    var settings = store.contents;
    if (settings !== undefined) {
      return Caml_option.valFromOption(settings);
    } else {
      return set(CamlinternalLazy.force(otherwise));
    }
  };
  var getExn = function (param) {
    var settings = store.contents;
    if (settings !== undefined) {
      return Caml_option.valFromOption(settings);
    }
    throw L10N_date_time_settings_missing;
  };
  return {
          store: store,
          set: set,
          get: get,
          getOr: getOr,
          getOrLazy: getOrLazy,
          getExn: getExn
        };
}

exports.L10N_date_time_settings_missing = L10N_date_time_settings_missing;
exports.Make = Make;
/* No side effect */
