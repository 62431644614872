// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Image$BsConsole = require("./components/Image.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Radio = require("@material-ui/core/Radio");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var SuccessMessage$BsConsole = require("./SuccessMessage.js");
var WarningMessage$BsConsole = require("./WarningMessage.js");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var ApiWfIntegrations$BsConsole = require("./workflow/workflows-api/ApiWfIntegrations.js");
var JiraStatusMapping$BsConsole = require("./workflow/jira/JiraStatusMapping.js");
var Wf2MainBodyConent$BsConsole = require("./workflow/Wf2MainBodyConent.js");
var RadioGroup = require("@material-ui/core/RadioGroup");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");
var Wf2AdvancedOptions$BsConsole = require("./workflow/Wf2AdvancedOptions.js");
var JiraAddNewRowButton$BsConsole = require("./workflow/jira/JiraAddNewRowButton.js");
var JiraCloseIconButton$BsConsole = require("./workflow/jira/JiraCloseIconButton.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var FormHelperText = require("@material-ui/core/FormHelperText");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");
var JiraAttributeAutocomplete$BsConsole = require("./JiraAttributeAutocomplete.js");

var alignRowWidths = Css.style(/* :: */[
      Css.marginRight(Css.px(49)),
      /* :: */[
        Css.unsafe("width", "calc(100% - 49px)"),
        /* [] */0
      ]
    ]);

var loadingContainerStyle = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* :: */[
                            Css.height(Css.vh(75)),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var loadingInputStyle = Css.style(/* :: */[
      Css.marginTop(Css.px(5)),
      /* :: */[
        Css.marginRight(Css.px(15)),
        /* [] */0
      ]
    ]);

var li = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA54)),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.marginBottom(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var parentCreate = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var titleText = Css.style(/* :: */[
      Css.fontSize(Css.px(24)),
      /* :: */[
        Css.margin(Css.px(0)),
        /* [] */0
      ]
    ]);

var titleLogo = Css.style(/* :: */[
      Css.width(Css.px(24)),
      /* :: */[
        Css.margin4(Css.px(0), Css.px(16), Css.px(0), Css.px(0)),
        /* [] */0
      ]
    ]);

var integrationNameLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(5)),
        /* [] */0
      ]
    ]);

var verticalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var horizontalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.flex(/* `num */[
                5496390,
                1
              ]),
          /* :: */[
            Css.marginBottom(Css.px(10)),
            /* [] */0
          ]
        ]
      ]
    ]);

var containerRightMargin = Css.style(/* :: */[
      Css.marginRight(Css.px(49)),
      /* [] */0
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.minWidth(Css.px(250)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var input = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var flexInput = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.flex(/* `num */[
              5496390,
              1
            ]),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(50)),
          /* :: */[
            Css.marginBottom(Css.px(25)),
            /* [] */0
          ]
        ]
      ]
    ]);

var saveButton = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var formHelperText = Css.style(/* :: */[
      Css.margin4(Css.px(8), Css.px(0), Css.px(0), Css.px(0)),
      /* [] */0
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(40)),
      /* [] */0
    ]);

var advancedRules = Css.style(/* :: */[
      Css.background(/* none */-922086728),
      /* :: */[
        Css.border(Css.px(0), /* solid */12956715, /* transparent */582626130),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* :: */[
            Css.paddingTop(Css.px(5)),
            /* :: */[
              Css.paddingBottom(Css.px(5)),
              /* :: */[
                Css.marginTop(Css.px(13)),
                /* :: */[
                  Css.marginLeft(Css.px(20)),
                  /* :: */[
                    Css.borderRadius(Css.px(4)),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.greyA60)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  alignRowWidths: alignRowWidths,
  loadingContainerStyle: loadingContainerStyle,
  loadingInputStyle: loadingInputStyle,
  li: li,
  parentCreate: parentCreate,
  title: title,
  titleText: titleText,
  titleLogo: titleLogo,
  integrationNameLabel: integrationNameLabel,
  verticalContainer: verticalContainer,
  horizontalContainer: horizontalContainer,
  flexRow: flexRow,
  containerRightMargin: containerRightMargin,
  inputItem: inputItem,
  input: input,
  flexInput: flexInput,
  buttonContainer: buttonContainer,
  saveButton: saveButton,
  formHelperText: formHelperText,
  bottomMargin: bottomMargin,
  advancedRules: advancedRules
};

function JiraConnection$Required(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: className,
              children: null
            }, children, React.createElement("span", {
                  style: {
                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                  }
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: JiraConnection$Required
};

var availableMacros = /* :: */[
  "{{project}}",
  /* :: */[
    "{{group_prefix}}",
    /* :: */[
      "{{classifiers}}",
      /* :: */[
        "{{frames}}",
        /* :: */[
          "{{application}}",
          /* [] */0
        ]
      ]
    ]
  ]
];

function JiraConnection$SubjectLineRow(Props) {
  var attributes = Props.attributes;
  var subjectLineVal = Props.subjectLineVal;
  var handleSubjectLineChange = Props.handleSubjectLineChange;
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Colors$BsConsole.deoctothorpe(palette.primary.main);
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setInvalidValues = match$1[1];
  var invalidValues = match$1[0];
  var handleFocus = function (param) {
    if (anchorEl !== undefined) {
      return Caml_option.valFromOption(anchorEl).focus();
    }
    
  };
  React.useEffect((function () {
          var invalidValues = Belt_Array.keep(subjectLineVal.trim().split(" "), (function (value) {
                  if (value.startsWith("$")) {
                    return !Belt_List.some(attributes, (function (attr) {
                                  return attr.name === value.substr(1);
                                }));
                  } else if (value.startsWith("{{") && value.endsWith("}}")) {
                    return !Belt_List.some(availableMacros, (function (macro) {
                                  return macro === value;
                                }));
                  } else {
                    return false;
                  }
                }));
          Curry._1(setInvalidValues, (function (param) {
                  return invalidValues;
                }));
          
        }), [subjectLineVal]);
  var tmp;
  if (invalidValues.length !== 0) {
    var invalidValuesString = Belt_Array.reduceWithIndex(invalidValues, "", (function (acc, attr, index) {
            return acc + ((
                      index !== 0 ? ", " : " "
                    ) + attr);
          }));
    tmp = React.createElement(FormHelperText.default, {
          error: true,
          children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "Invalid values: ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* String_literal */Block.__(11, [
                                ".  Delete invalid values to continue.",
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "Invalid values: %s.  Delete invalid values to continue."
                  ]), invalidValuesString)
        });
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(JiraConnection$Required, {
                  className: integrationNameLabel,
                  children: I18N$BsConsole.show(undefined, "Subject line")
                }), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        alignRowWidths,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.position(/* relative */903134412),
                                /* :: */[
                                  Css.marginRight(Css.px(49)),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(JiraAttributeAutocomplete$BsConsole.make, {
                      toString: (function (attribute$prime) {
                          return "$" + attribute$prime.name;
                        }),
                      considerations: Belt_List.toArray(attributes),
                      onChange: (function (newValue) {
                          Curry._1(handleSubjectLineChange, newValue);
                          return handleFocus(undefined);
                        }),
                      defaultValue: subjectLineVal,
                      inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                      renderListOption: (function (attr, idx) {
                          return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                                      name: attr.name,
                                      description: attr.description,
                                      custom: attr.custom,
                                      triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                                      used: attr.statistics.used,
                                      key: attr.name + ("__" + String(idx))
                                    });
                        }),
                      renderInput: (function (param) {
                          var handleAddAttribute = param.handleAddAttribute;
                          var onFocus = param.onFocus;
                          var onChange = param.onChange;
                          var onFocus$prime = Curry.__1(onFocus);
                          var onChange$prime = Curry.__1(onChange);
                          return React.createElement("div", undefined, React.createElement(TextField.default, {
                                          placeholder: param.placeholder,
                                          value: subjectLineVal,
                                          variant: "outlined",
                                          className: Css.style(/* :: */[
                                                Css.important(Css.marginBottom(/* zero */-789508312)),
                                                /* :: */[
                                                  Css.width(Css.pct(100)),
                                                  /* :: */[
                                                    Css.selector("input", /* :: */[
                                                          Css.important(Css.padding(Css.px(10))),
                                                          /* :: */[
                                                            Css.important(Css.paddingRight(Css.px(25))),
                                                            /* [] */0
                                                          ]
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          inputRef: (function (input) {
                                              if (input == null) {
                                                return ;
                                              }
                                              var domElement = ReactDom.findDOMNode(input);
                                              return Curry._1(setAnchorEl, (function (param) {
                                                            return Caml_option.some(domElement);
                                                          }));
                                            }),
                                          onChange: onChange$prime,
                                          onFocus: onFocus$prime
                                        }), React.createElement(Tooltip.default, {
                                          children: React.createElement(Button.default, {
                                                className: Css.style(/* :: */[
                                                      Css.color(Css.hex(primaryMain)),
                                                      /* :: */[
                                                        Css.height(Css.px(35)),
                                                        /* :: */[
                                                          Css.width(Css.px(20)),
                                                          /* :: */[
                                                            Css.padding(Css.px(0)),
                                                            /* :: */[
                                                              Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                                              /* :: */[
                                                                Css.position(/* absolute */-1013592457),
                                                                /* :: */[
                                                                  Css.top(Css.px(2)),
                                                                  /* :: */[
                                                                    Css.right(Css.px(2)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]),
                                                classes: {
                                                  root: Css.style(/* :: */[
                                                        Css.minWidth(Css.px(20)),
                                                        /* :: */[
                                                          Css.maxWidth(Css.px(20)),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                },
                                                onClick: (function (param) {
                                                    Curry._1(handleAddAttribute, "$");
                                                    return handleFocus(undefined);
                                                  }),
                                                children: React.createElement(AddCircleOutline.default, {
                                                      fontSize: "inherit"
                                                    })
                                              }),
                                          title: "Add dynamic attribute value"
                                        }));
                        })
                    })), tmp, React.createElement(FormHelperText.default, {
                  children: I18N$BsConsole.show(undefined, "Generates the subject/summary line for the Jira issue, including support for the following macros and attributes:")
                }), React.createElement("ul", {
                  className: Css.style(/* :: */[
                        Css.paddingLeft(Css.px(20)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* :: */[
                            Css.fontSize(Css.rem(0.6)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "{{project}} = Name of project")), React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "{{group_prefix}} = First 6 characters of group fingerprint")), React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "{{classifiers}} = List of classifiers")), React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "{{frames}} = List of frames in call stack")), React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "{{application}} = Name of application")), React.createElement("li", {
                      className: li
                    }, I18N$BsConsole.show(undefined, "$<attribute name> = Add an attribute, like error.message, to the subject line"))));
}

var SubjectLineRow = {
  availableMacros: availableMacros,
  make: JiraConnection$SubjectLineRow
};

function JiraConnection$JiraTemplate(Props) {
  var classNameOpt = Props.className;
  var attributes = Props.attributes;
  var subjectLineVal = Props.subjectLineVal;
  var handleSubjectLineChange = Props.handleSubjectLineChange;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: className
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(25)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Jira template")
                }), React.createElement(JiraConnection$SubjectLineRow, {
                  attributes: attributes,
                  subjectLineVal: subjectLineVal,
                  handleSubjectLineChange: handleSubjectLineChange
                }));
}

var JiraTemplate = {
  make: JiraConnection$JiraTemplate
};

function JiraConnection$IssueType(Props) {
  var jiraIssueTypes = Props.jiraIssueTypes;
  var jiraIssueTypeSelection = Props.jiraIssueTypeSelection;
  var handleIssueTypeChange = Props.handleIssueTypeChange;
  var match = React.useState((function () {
          return false;
        }));
  var setDependenciesIncomplete = match[1];
  var dependenciesIncomplete = match[0];
  React.useEffect((function () {
          if (dependenciesIncomplete === true && jiraIssueTypes.length !== 0) {
            Curry._1(setDependenciesIncomplete, (function (param) {
                    return false;
                  }));
          }
          
        }), /* tuple */[
        dependenciesIncomplete,
        jiraIssueTypes
      ]);
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    containerRightMargin,
                    /* :: */[
                      verticalContainer,
                      /* [] */0
                    ]
                  ]),
              onClick: (function (param) {
                  return Curry._1(setDependenciesIncomplete, (function (param) {
                                return jiraIssueTypes.length === 0;
                              }));
                })
            }, React.createElement("div", {
                  className: bottomMargin
                }, React.createElement(JiraConnection$Required, {
                      className: integrationNameLabel,
                      children: I18N$BsConsole.show(undefined, "Issue Type")
                    }), React.createElement(Select.default, {
                      value: jiraIssueTypeSelection,
                      disabled: jiraIssueTypes.length === 0,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._1(handleIssueTypeChange, value);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: jiraIssueTypes.length !== 0 ? Belt_Array.map(jiraIssueTypes, (function (issueType) {
                                return React.createElement(MenuItem.default, {
                                            value: issueType,
                                            children: I18N$BsConsole.showSkip(issueType.name),
                                            key: issueType.id
                                          });
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), dependenciesIncomplete ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "You must select a project name before you can select this field")
                      }) : React.createElement(FormHelperText.default, {
                        children: I18N$BsConsole.show(undefined, "Jira issue type")
                      })));
}

var IssueType = {
  make: JiraConnection$IssueType
};

function JiraConnection$MainBodyTextField(Props) {
  var jiraContentKeys = Props.jiraContentKeys;
  var jiraContentKeySelection = Props.jiraContentKeySelection;
  var jiraIssueTypeSelection = Props.jiraIssueTypeSelection;
  var handleContentKeyChange = Props.handleContentKeyChange;
  var match = React.useState((function () {
          return false;
        }));
  var setDependenciesIncomplete = match[1];
  var dependenciesIncomplete = match[0];
  React.useEffect((function () {
          if (dependenciesIncomplete === true && jiraContentKeys.length !== 0 && Belt_Option.isSome(jiraIssueTypeSelection)) {
            Curry._1(setDependenciesIncomplete, (function (param) {
                    return false;
                  }));
          }
          
        }), /* tuple */[
        dependenciesIncomplete,
        jiraContentKeys,
        jiraIssueTypeSelection
      ]);
  return React.createElement("div", {
              className: verticalContainer,
              onClick: (function (param) {
                  return Curry._1(setDependenciesIncomplete, (function (param) {
                                return !(jiraContentKeys.length !== 0 && Belt_Option.isSome(jiraIssueTypeSelection));
                              }));
                })
            }, React.createElement("div", {
                  className: bottomMargin
                }, React.createElement(JiraConnection$Required, {
                      className: integrationNameLabel,
                      children: I18N$BsConsole.show(undefined, "Main body text field")
                    }), React.createElement(Select.default, {
                      value: jiraContentKeySelection,
                      disabled: jiraContentKeys.length <= 0 || Belt_Option.isNone(jiraIssueTypeSelection),
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._1(handleContentKeyChange, value);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: jiraContentKeys.length !== 0 ? Belt_Array.map(jiraContentKeys, (function (contentKey) {
                                return React.createElement(MenuItem.default, {
                                            value: contentKey,
                                            children: I18N$BsConsole.showSkip(contentKey.name),
                                            key: contentKey.id
                                          });
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), dependenciesIncomplete ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "You must select an issue type before you can select this field")
                      }) : React.createElement(FormHelperText.default, {
                        children: I18N$BsConsole.show(undefined, "The field where Backtrace will put descriptive error information")
                      })));
}

var MainBodyTextField = {
  make: JiraConnection$MainBodyTextField
};

function JiraConnection$CustomFieldRow(Props) {
  var attributes = Props.attributes;
  var customFieldsAvailable = Props.customFieldsAvailable;
  var index = Props.index;
  var fieldName = Props.fieldName;
  var fieldValue = Props.fieldValue;
  var handleFieldNameChange = Props.handleFieldNameChange;
  var handleFieldValueChange = Props.handleFieldValueChange;
  var handleFieldDelete = Props.handleFieldDelete;
  var validationError = Props.validationError;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Colors$BsConsole.deoctothorpe(palette.primary.main);
  var handleFocus = function (param) {
    if (anchorEl !== undefined) {
      return Caml_option.valFromOption(anchorEl).focus();
    }
    
  };
  var isFieldNameValid = fieldName === "" || Belt_Array.some(customFieldsAvailable, (function (field) {
          return field.id === fieldName;
        }));
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDependenciesIncomplete = match$1[1];
  var dependenciesIncomplete = match$1[0];
  var match$2 = React.useState((function () {
          return [];
        }));
  var setInvalidAttributes = match$2[1];
  var invalidAttributes = match$2[0];
  React.useEffect((function () {
          if (dependenciesIncomplete === true && customFieldsAvailable.length !== 0) {
            Curry._1(setDependenciesIncomplete, (function (param) {
                    return false;
                  }));
          }
          
        }), /* tuple */[
        dependenciesIncomplete,
        customFieldsAvailable
      ]);
  React.useEffect((function () {
          var invalidAttrs = Belt_Array.keep(fieldValue.trim().split(" "), (function (attribute) {
                  if (attribute.startsWith("$")) {
                    return !Belt_List.some(attributes, (function (attr) {
                                  return attr.name === attribute.substr(1);
                                }));
                  } else {
                    return false;
                  }
                }));
          Curry._1(setInvalidAttributes, (function (param) {
                  return invalidAttrs;
                }));
          
        }), [fieldValue]);
  var match$3 = validationError && fieldName.trim() === "" && fieldValue.trim() !== "";
  var match$4 = invalidAttributes.length !== 0;
  var tmp;
  if (isFieldNameValid) {
    if (match$4) {
      var invalidAttributesString = Belt_Array.reduceWithIndex(invalidAttributes, "", (function (acc, attr, index) {
              return acc + ((
                        index !== 0 ? ", " : " "
                      ) + attr);
            }));
      tmp = React.createElement(FormHelperText.default, {
            error: true,
            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Invalid attribute(s): ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  ".  Delete attribute(s) to continue.",
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "Invalid attribute(s): %s.  Delete attribute(s) to continue."
                    ]), invalidAttributesString)
          });
    } else {
      tmp = React.createElement(FormHelperText.default, {
            children: I18N$BsConsole.show(undefined, "Value to populate into custom field")
          });
    }
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: horizontalContainer
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        containerRightMargin,
                        /* :: */[
                          verticalContainer,
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      if (isFieldNameValid) {
                        return Curry._1(setDependenciesIncomplete, (function (param) {
                                      return customFieldsAvailable.length === 0;
                                    }));
                      }
                      
                    })
                }, React.createElement("p", {
                      className: integrationNameLabel
                    }, I18N$BsConsole.show(undefined, "Field name")), React.createElement(Select.default, {
                      value: fieldName,
                      disabled: !isFieldNameValid || customFieldsAvailable.length === 0,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._2(handleFieldNameChange, value, index);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: isFieldNameValid ? (
                          customFieldsAvailable.length !== 0 ? Belt_Array.map(customFieldsAvailable, (function (customFieldValue) {
                                    return React.createElement(MenuItem.default, {
                                                value: customFieldValue.id,
                                                children: I18N$BsConsole.showSkip(customFieldValue.name),
                                                key: customFieldValue.id
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        ) : React.createElement(MenuItem.default, {
                              value: fieldName,
                              children: I18N$BsConsole.showSkip(fieldName),
                              key: fieldName
                            })
                    }), isFieldNameValid ? (
                    match$3 ? React.createElement(FormHelperText.default, {
                            error: true,
                            children: I18N$BsConsole.show(undefined, "Field Name is required when its value is set.")
                          }) : (
                        dependenciesIncomplete ? React.createElement(FormHelperText.default, {
                                error: true,
                                children: I18N$BsConsole.show(undefined, "You must select an issue type before you can select this field")
                              }) : React.createElement(FormHelperText.default, {
                                children: I18N$BsConsole.show(undefined, "Name of the Jira custom field")
                              })
                      )
                  ) : React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Field name is invalid, delete custom field row to continue.")
                      })), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        verticalContainer,
                        /* [] */0
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(/* column */-963948842),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement("p", {
                          className: integrationNameLabel
                        }, I18N$BsConsole.show(undefined, "Values")), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.position(/* relative */903134412),
                                /* [] */0
                              ])
                        }, isFieldNameValid ? React.createElement(JiraAttributeAutocomplete$BsConsole.make, {
                                toString: (function (attribute$prime) {
                                    return "$" + attribute$prime.name;
                                  }),
                                considerations: Belt_List.toArray(attributes),
                                onChange: (function (newFieldValue) {
                                    Curry._2(handleFieldValueChange, newFieldValue, index);
                                    return handleFocus(undefined);
                                  }),
                                defaultValue: fieldValue,
                                inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                                renderListOption: (function (attr, idx) {
                                    return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                                                name: attr.name,
                                                description: attr.description,
                                                custom: attr.custom,
                                                triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                                                used: attr.statistics.used,
                                                key: attr.name + ("__" + String(idx))
                                              });
                                  }),
                                renderInput: (function (param) {
                                    var handleAddAttribute = param.handleAddAttribute;
                                    var onFocus = param.onFocus;
                                    var onChange = param.onChange;
                                    var onFocus$prime = Curry.__1(onFocus);
                                    var onChange$prime = Curry.__1(onChange);
                                    return React.createElement("div", undefined, React.createElement(TextField.default, {
                                                    placeholder: param.placeholder,
                                                    value: fieldValue,
                                                    variant: "outlined",
                                                    className: Css.style(/* :: */[
                                                          Css.important(Css.marginBottom(/* zero */-789508312)),
                                                          /* :: */[
                                                            Css.width(Css.pct(100)),
                                                            /* :: */[
                                                              Css.selector("input", /* :: */[
                                                                    Css.important(Css.padding(Css.px(10))),
                                                                    /* :: */[
                                                                      Css.important(Css.paddingRight(Css.px(25))),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]),
                                                    inputRef: (function (input) {
                                                        if (input == null) {
                                                          return ;
                                                        }
                                                        var domElement = ReactDom.findDOMNode(input);
                                                        return Curry._1(setAnchorEl, (function (param) {
                                                                      return Caml_option.some(domElement);
                                                                    }));
                                                      }),
                                                    onChange: onChange$prime,
                                                    onFocus: onFocus$prime
                                                  }), React.createElement(Tooltip.default, {
                                                    children: React.createElement(Button.default, {
                                                          className: Css.style(/* :: */[
                                                                Css.color(Css.hex(primaryMain)),
                                                                /* :: */[
                                                                  Css.height(Css.px(35)),
                                                                  /* :: */[
                                                                    Css.width(Css.px(20)),
                                                                    /* :: */[
                                                                      Css.padding(Css.px(0)),
                                                                      /* :: */[
                                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                                                        /* :: */[
                                                                          Css.position(/* absolute */-1013592457),
                                                                          /* :: */[
                                                                            Css.top(Css.px(2)),
                                                                            /* :: */[
                                                                              Css.right(Css.px(2)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]),
                                                          classes: {
                                                            root: Css.style(/* :: */[
                                                                  Css.minWidth(Css.px(20)),
                                                                  /* :: */[
                                                                    Css.maxWidth(Css.px(20)),
                                                                    /* [] */0
                                                                  ]
                                                                ])
                                                          },
                                                          onClick: (function (param) {
                                                              Curry._1(handleAddAttribute, "$");
                                                              return handleFocus(undefined);
                                                            }),
                                                          children: React.createElement(AddCircleOutline.default, {
                                                                fontSize: "inherit"
                                                              })
                                                        }),
                                                    title: "Add dynamic attribute value"
                                                  }));
                                  })
                              }) : React.createElement(TextField.default, {
                                disabled: true,
                                placeholder: "",
                                value: fieldValue,
                                variant: "outlined",
                                className: Css.style(/* :: */[
                                      Css.important(Css.marginBottom(/* zero */-789508312)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.selector("input", /* :: */[
                                                Css.important(Css.padding(Css.px(10))),
                                                /* :: */[
                                                  Css.important(Css.paddingRight(Css.px(25))),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              })), tmp)), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        buttonContainer,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.width(Css.px(48)),
                                /* :: */[
                                  Css.height(Css.px(48)),
                                  /* :: */[
                                    Css.marginTop(Css.px(35)),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(JiraCloseIconButton$BsConsole.make, {
                      handleClick: (function (param) {
                          return Curry._1(handleFieldDelete, index);
                        }),
                      className: Css.style(/* :: */[
                            Css.flex3(0, 1, /* zero */-789508312),
                            /* :: */[
                              Css.fontSize(Css.rem(1.5)),
                              /* :: */[
                                Css.marginLeft(Css.px(5)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      ariaLabel: "delete custom field row"
                    })));
}

var CustomFieldRow = {
  make: JiraConnection$CustomFieldRow
};

function JiraConnection$CustomFields(Props) {
  var attributes = Props.attributes;
  var customFieldsAvailable = Props.customFieldsAvailable;
  var customFieldSelections = Props.customFieldSelections;
  var customFieldValidationErrors = Props.customFieldValidationErrors;
  var handleAddNewCustomFieldRow = Props.handleAddNewCustomFieldRow;
  var handleFieldNameChange = Props.handleFieldNameChange;
  var handleFieldValueChange = Props.handleFieldValueChange;
  var handleFieldDelete = Props.handleFieldDelete;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            containerRightMargin,
                            /* :: */[
                              verticalContainer,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(0)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Custom fields")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Optional list of fields to populate in the Jira issue. For array fields, separate values with commas.")
                        }))), Belt_List.toArray(Belt_List.mapWithIndex(customFieldSelections, (function (index, field) {
                        return React.createElement(JiraConnection$CustomFieldRow, {
                                    attributes: attributes,
                                    customFieldsAvailable: customFieldsAvailable,
                                    index: index,
                                    fieldName: field[0],
                                    fieldValue: field[1],
                                    handleFieldNameChange: handleFieldNameChange,
                                    handleFieldValueChange: handleFieldValueChange,
                                    handleFieldDelete: handleFieldDelete,
                                    validationError: Belt_Array.some(customFieldValidationErrors, (function (errorIndex) {
                                            return errorIndex === index;
                                          })),
                                    key: field[0] + String(index)
                                  });
                      }))), React.createElement(JiraAddNewRowButton$BsConsole.make, {
                  item: "field",
                  handleClick: (function (param) {
                      return Curry._1(handleAddNewCustomFieldRow, undefined);
                    })
                }));
}

var CustomFields = {
  make: JiraConnection$CustomFields
};

function JiraConnection$ResolvedUnitlVersionRow(Props) {
  var attributes = Props.attributes;
  var versionFieldsAvailable = Props.versionFieldsAvailable;
  var versionFieldSelection = Props.versionFieldSelection;
  var btVersionAttribute = Props.btVersionAttribute;
  var handleJiraVersionChange = Props.handleJiraVersionChange;
  var handleBtVersionAttributeChange = Props.handleBtVersionAttributeChange;
  var resolveUntilValidationError = Props.resolveUntilValidationError;
  var match = React.useState((function () {
          return false;
        }));
  var setDependenciesIncomplete = match[1];
  var dependenciesIncomplete = match[0];
  React.useEffect((function () {
          if (dependenciesIncomplete === true && versionFieldsAvailable.length !== 0) {
            Curry._1(setDependenciesIncomplete, (function (param) {
                    return false;
                  }));
          }
          
        }), /* tuple */[
        dependenciesIncomplete,
        versionFieldsAvailable
      ]);
  var versionAttributes = Belt_List.toArray(Belt_List.keep(attributes, (function (attr) {
              return attr.format === "semver";
            })));
  return React.createElement("div", {
              className: horizontalContainer
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        containerRightMargin,
                        /* :: */[
                          verticalContainer,
                          /* [] */0
                        ]
                      ])
                }, React.createElement("p", {
                      className: integrationNameLabel
                    }, I18N$BsConsole.show(undefined, "Backtrace version attribute")), React.createElement(Select.default, {
                      value: btVersionAttribute,
                      disabled: false,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._1(handleBtVersionAttributeChange, value);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: versionAttributes.length !== 0 ? Belt_Array.map(versionAttributes, (function (attr) {
                                return React.createElement(MenuItem.default, {
                                            value: attr.name,
                                            children: I18N$BsConsole.showSkip(attr.name),
                                            key: attr.name
                                          });
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), resolveUntilValidationError && Belt_Option.isNone(btVersionAttribute) ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Backtrace version attribute is required when Jira version field is set.")
                      }) : null), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        containerRightMargin,
                        /* :: */[
                          verticalContainer,
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(setDependenciesIncomplete, (function (param) {
                                    return versionFieldsAvailable.length === 0;
                                  }));
                    })
                }, React.createElement("p", {
                      className: integrationNameLabel
                    }, I18N$BsConsole.show(undefined, "Jira version field")), React.createElement(Select.default, {
                      value: versionFieldSelection,
                      disabled: versionFieldsAvailable.length === 0,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._1(handleJiraVersionChange, value);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: versionFieldsAvailable.length !== 0 ? Belt_Array.map(versionFieldsAvailable, (function (field) {
                                return React.createElement(MenuItem.default, {
                                            value: field.id,
                                            children: I18N$BsConsole.showSkip(field.name),
                                            key: field.id
                                          });
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), dependenciesIncomplete ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "You must select an issue type before you can select this field")
                      }) : null, resolveUntilValidationError && Belt_Option.isNone(versionFieldSelection) ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "Version field is required when Backtrace version attribute is set.")
                      }) : null));
}

var ResolvedUnitlVersionRow = {
  make: JiraConnection$ResolvedUnitlVersionRow
};

var AdvancedRule = { };

function JiraConnection$AdvancedRuleRow(Props) {
  var rule = Props.rule;
  var index = Props.index;
  var handleDeleteAdvancedRule = Props.handleDeleteAdvancedRule;
  return React.createElement("div", {
              className: flexRow
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.paddingTop(Css.px(10)),
                          /* :: */[
                            Css.marginRight(Css.px(10)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "If")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: true,
                  placeholder: "",
                  value: rule.attribute,
                  color: "primary",
                  variant: "outlined",
                  className: Css.style(/* :: */[
                        Css.selector("input", /* :: */[
                              Css.important(Css.color(Css.hex(Colors$BsConsole.black))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]),
                  classes: {
                    root: Css.style(/* :: */[
                          Css.selector("input", /* :: */[
                                Css.important(Css.padding(Css.px(10))),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.minWidth(Css.px(250)),
                            /* [] */0
                          ]
                        ])
                  }
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.paddingTop(Css.px(10)),
                          /* :: */[
                            Css.marginLeft(Css.px(10)),
                            /* :: */[
                              Css.marginRight(Css.px(10)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "matches")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: true,
                  placeholder: "",
                  value: rule.attributeRegex,
                  color: "primary",
                  variant: "outlined",
                  className: Css.style(/* :: */[
                        Css.selector("input", /* :: */[
                              Css.important(Css.color(Css.hex(Colors$BsConsole.black))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]),
                  classes: {
                    root: flexInput
                  }
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.paddingTop(Css.px(10)),
                          /* :: */[
                            Css.marginLeft(Css.px(10)),
                            /* :: */[
                              Css.marginRight(Css.px(10)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "then use")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: true,
                  placeholder: I18N$BsConsole.get(undefined, "Regex"),
                  value: rule.jiraRegex,
                  color: "primary",
                  variant: "outlined",
                  className: Css.style(/* :: */[
                        Css.selector("input", /* :: */[
                              Css.important(Css.color(Css.hex(Colors$BsConsole.black))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]),
                  classes: {
                    root: flexInput
                  }
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.paddingTop(Css.px(10)),
                          /* :: */[
                            Css.marginLeft(Css.px(10)),
                            /* :: */[
                              Css.marginRight(Css.px(10)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "as Jira fix version.")
                }), React.createElement(JiraCloseIconButton$BsConsole.make, {
                  handleClick: (function (param) {
                      return Curry._1(handleDeleteAdvancedRule, index);
                    }),
                  className: Css.style(/* :: */[
                        Css.flex3(0, 1, /* zero */-789508312),
                        /* :: */[
                          Css.fontSize(Css.rem(1.5)),
                          /* :: */[
                            Css.marginLeft(Css.px(19)),
                            /* :: */[
                              Css.marginRight(Css.px(16)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  ariaLabel: "delete advanced rule row"
                }));
}

var AdvancedRuleRow = {
  make: JiraConnection$AdvancedRuleRow
};

function JiraConnection$AddChipButton(Props) {
  var isValid = Props.isValid;
  var onSuccess = Props.onSuccess;
  var onError = Props.onError;
  Props.className;
  return React.createElement(Tooltip.default, {
              placement: "bottom",
              children: React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    className: Css.style(/* :: */[
                          Css.height(Css.px(25)),
                          /* :: */[
                            Css.marginTop(Css.px(7)),
                            /* :: */[
                              Css.marginLeft(Css.px(15)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    onClick: (function (param) {
                        if (isValid) {
                          return Curry._1(onSuccess, undefined);
                        } else {
                          return Curry._1(onError, undefined);
                        }
                      }),
                    children: I18N$BsConsole.show(undefined, "Add")
                  }),
              title: "Add advanced rule"
            });
}

var AddChipButton = {
  make: JiraConnection$AddChipButton
};

function JiraConnection$AttributeAutocomplete(Props) {
  var rule = Props.rule;
  var attributes = Props.attributes;
  var displayErrors = Props.displayErrors;
  var handleChange = Props.handleChange;
  var handleUnsavedRuleChanges = Props.handleUnsavedRuleChanges;
  var isEmptyRow = Props.isEmptyRow;
  var match = React.useState((function () {
          return 0;
        }));
  var setRowKey = match[1];
  React.useEffect((function () {
          Curry._1(setRowKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [rule]);
  return React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (attribute$prime) {
                  return attribute$prime.name;
                }),
              considerations: Belt_List.toArray(attributes),
              onChange: (function (_raw, attribute$prime) {
                  if (Belt_Option.isSome(attribute$prime)) {
                    Curry._1(handleUnsavedRuleChanges, true);
                  } else if (isEmptyRow) {
                    Curry._1(handleUnsavedRuleChanges, false);
                  }
                  return Curry._1(handleChange, attribute$prime !== undefined ? attribute$prime.name : "");
                }),
              defaultValue: rule.attribute,
              placeholder: I18N$BsConsole.get(undefined, "Predicate Attribute"),
              autoFocus: false,
              inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
              renderListOption: (function (attr, idx) {
                  return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                              name: attr.name,
                              description: attr.description,
                              custom: attr.custom,
                              triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                              used: attr.statistics.used,
                              key: attr.name + ("__" + String(idx))
                            });
                }),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  var onFocus$prime = Curry.__1(onFocus);
                  var onChange$prime = function ($$event) {
                    var newValue = $$event.target.value;
                    if (newValue !== "") {
                      Curry._1(handleUnsavedRuleChanges, true);
                    } else if (isEmptyRow) {
                      Curry._1(handleUnsavedRuleChanges, false);
                    }
                    return Curry._1(onChange, $$event);
                  };
                  return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                  onFocus: onFocus$prime,
                                  className: Css.style(/* :: */[
                                        Css.important(Css.marginBottom(/* zero */-789508312)),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.selector("input", /* :: */[
                                                  Css.important(Css.padding(Css.px(10))),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  placeholder: param.placeholder,
                                  value: param.value,
                                  onChange: onChange$prime,
                                  variant: "outlined"
                                }), displayErrors && rule.attribute === "" ? React.createElement(FormHelperText.default, {
                                    error: true,
                                    children: I18N$BsConsole.show(undefined, "Predicate Attribute is required")
                                  }) : null);
                }),
              key: "attribute-" + String(match[0])
            });
}

var AttributeAutocomplete = {
  make: JiraConnection$AttributeAutocomplete
};

function JiraConnection$AdvancedRuleInput(Props) {
  var attributes = Props.attributes;
  var foundUnsavedRuleChanges = Props.foundUnsavedRuleChanges;
  var handleAddAdvancedRule = Props.handleAddAdvancedRule;
  var handleUnsavedRuleChanges = Props.handleUnsavedRuleChanges;
  var initialRule = {
    attribute: "",
    attributeRegex: "",
    jiraRegex: ""
  };
  var match = React.useState((function () {
          return initialRule;
        }));
  var setRule = match[1];
  var rule = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDisplayErrors = match$1[1];
  var displayErrors = match$1[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: flexRow
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.alignSelf(/* flexStart */662439529),
                            /* :: */[
                              Css.paddingTop(Css.px(10)),
                              /* :: */[
                                Css.marginRight(Css.px(10)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "If")
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  1
                                ]),
                            /* :: */[
                              Css.minWidth(Css.px(250)),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(JiraConnection$AttributeAutocomplete, {
                          rule: rule,
                          attributes: attributes,
                          displayErrors: displayErrors,
                          handleChange: (function (attribute) {
                              return Curry._1(setRule, (function (prev) {
                                            return {
                                                    attribute: attribute,
                                                    attributeRegex: prev.attributeRegex,
                                                    jiraRegex: prev.jiraRegex
                                                  };
                                          }));
                            }),
                          handleUnsavedRuleChanges: handleUnsavedRuleChanges,
                          isEmptyRow: rule.attributeRegex === "" && rule.jiraRegex === ""
                        })), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.alignSelf(/* flexStart */662439529),
                            /* :: */[
                              Css.paddingTop(Css.px(10)),
                              /* :: */[
                                Css.marginLeft(Css.px(10)),
                                /* :: */[
                                  Css.marginRight(Css.px(10)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "matches")
                    }), React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  1
                                ]),
                            /* [] */0
                          ])
                    }, React.createElement(TextField.default, {
                          autoFocus: false,
                          disabled: false,
                          placeholder: I18N$BsConsole.get(undefined, "Predicate Attribute Regex"),
                          value: rule.attributeRegex,
                          color: "primary",
                          variant: "outlined",
                          classes: {
                            root: input
                          },
                          onChange: (function ($$event) {
                              var attributeRegex = Belt_Option.getWithDefault($$event.target.value, "");
                              if (attributeRegex !== "") {
                                Curry._1(handleUnsavedRuleChanges, true);
                              } else if (rule.jiraRegex === "" && rule.attribute === "") {
                                Curry._1(handleUnsavedRuleChanges, false);
                              }
                              return Curry._1(setRule, (function (prev) {
                                            return {
                                                    attribute: prev.attribute,
                                                    attributeRegex: attributeRegex,
                                                    jiraRegex: prev.jiraRegex
                                                  };
                                          }));
                            })
                        }), displayErrors && rule.attributeRegex === "" ? React.createElement(FormHelperText.default, {
                            error: true,
                            children: I18N$BsConsole.show(undefined, "Predicate Attribute Regex is required")
                          }) : null), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.alignSelf(/* flexStart */662439529),
                            /* :: */[
                              Css.paddingTop(Css.px(10)),
                              /* :: */[
                                Css.marginLeft(Css.px(10)),
                                /* :: */[
                                  Css.marginRight(Css.px(10)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "then use")
                    }), React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  1
                                ]),
                            /* [] */0
                          ])
                    }, React.createElement(TextField.default, {
                          autoFocus: false,
                          disabled: false,
                          placeholder: I18N$BsConsole.get(undefined, "Jira Version Regex"),
                          value: rule.jiraRegex,
                          color: "primary",
                          variant: "outlined",
                          classes: {
                            root: input
                          },
                          onChange: (function ($$event) {
                              var jiraRegex = Belt_Option.getWithDefault($$event.target.value, "");
                              if (jiraRegex !== "") {
                                Curry._1(handleUnsavedRuleChanges, true);
                              } else if (rule.attributeRegex === "" && rule.attribute === "") {
                                Curry._1(handleUnsavedRuleChanges, false);
                              }
                              return Curry._1(setRule, (function (prev) {
                                            return {
                                                    attribute: prev.attribute,
                                                    attributeRegex: prev.attributeRegex,
                                                    jiraRegex: jiraRegex
                                                  };
                                          }));
                            })
                        }), displayErrors && rule.jiraRegex === "" ? React.createElement(FormHelperText.default, {
                            error: true,
                            children: I18N$BsConsole.show(undefined, "Jira Version Regex is required")
                          }) : null), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.alignSelf(/* flexStart */662439529),
                            /* :: */[
                              Css.paddingTop(Css.px(10)),
                              /* :: */[
                                Css.marginLeft(Css.px(10)),
                                /* :: */[
                                  Css.marginRight(Css.px(10)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "as Jira fix version.")
                    }), React.createElement(JiraConnection$AddChipButton, {
                      isValid: !(rule.attribute === "" || rule.attributeRegex.trim() === "" || rule.jiraRegex.trim() === ""),
                      onSuccess: (function (param) {
                          Curry._1(handleUnsavedRuleChanges, false);
                          Curry._1(handleAddAdvancedRule, rule);
                          Curry._1(setRule, (function (param) {
                                  return initialRule;
                                }));
                          return Curry._1(setDisplayErrors, (function (param) {
                                        return false;
                                      }));
                        }),
                      onError: (function (param) {
                          return Curry._1(setDisplayErrors, (function (param) {
                                        return true;
                                      }));
                        }),
                      className: Css.style(/* :: */[
                            Css.alignSelf(/* flexStart */662439529),
                            /* [] */0
                          ])
                    })), foundUnsavedRuleChanges ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Must click Add to save rule or clear out all rule inputs to continue")
                  }) : null);
}

var AdvancedRuleInput = {
  make: JiraConnection$AdvancedRuleInput
};

function JiraConnection$ResolveUnitlRegexRow(Props) {
  var versionRegEx = Props.versionRegEx;
  var handleversionRegExChange = Props.handleversionRegExChange;
  var attributes = Props.attributes;
  var advancedRules$1 = Props.advancedRules;
  var handleAddAdvancedRule = Props.handleAddAdvancedRule;
  var handleDeleteAdvancedRule = Props.handleDeleteAdvancedRule;
  var useAdvancedRules = Props.useAdvancedRules;
  var toogleAdvancedRules = Props.toogleAdvancedRules;
  var handleUnsavedRuleChanges = Props.handleUnsavedRuleChanges;
  var foundUnsavedRuleChanges = Props.foundUnsavedRuleChanges;
  if (useAdvancedRules) {
    return React.createElement("div", {
                className: horizontalContainer
              }, React.createElement("div", {
                    className: Css.merge(/* :: */[
                          containerRightMargin,
                          /* :: */[
                            verticalContainer,
                            /* [] */0
                          ]
                        ])
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: horizontalContainer
                          }, React.createElement("p", {
                                className: integrationNameLabel
                              }, I18N$BsConsole.show(undefined, "Advanced Rules Builder")), React.createElement("button", {
                                className: advancedRules,
                                onClick: (function (param) {
                                    return Curry._1(toogleAdvancedRules, undefined);
                                  })
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    className: Css.style(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.blue)),
                                          /* :: */[
                                            Css.fontWeight(/* `num */[
                                                  5496390,
                                                  500
                                                ]),
                                            /* :: */[
                                              Css.fontSize(Css.rem(0.875)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "Default rule configurations")
                                  }))), React.createElement(FormHelperText.default, {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(20)),
                                  /* :: */[
                                    Css.marginTop(Css.px(3)),
                                    /* [] */0
                                  ]
                                ]),
                            children: null
                          }, I18N$BsConsole.show(undefined, "Used to extract version number based on attribute values. (For example, if you apply different Jira versions for different branches, a rule could be "), React.createElement("span", {
                                className: Css.style(/* :: */[
                                      Css.fontStyle(/* italic */107228912),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "\"if 'Branch Name' matches 'Dev' then use 'Dev (.*)' as Jira fix version\")"))), React.createElement("div", undefined, Belt_Array.mapWithIndex(advancedRules$1, (function (index, rule) {
                                  return React.createElement(JiraConnection$AdvancedRuleRow, {
                                              rule: rule,
                                              index: index,
                                              handleDeleteAdvancedRule: handleDeleteAdvancedRule,
                                              key: "advanced-rule-" + String(index)
                                            });
                                })), React.createElement(JiraConnection$AdvancedRuleInput, {
                                attributes: attributes,
                                foundUnsavedRuleChanges: foundUnsavedRuleChanges,
                                handleAddAdvancedRule: handleAddAdvancedRule,
                                handleUnsavedRuleChanges: handleUnsavedRuleChanges
                              })))));
  } else {
    return React.createElement("div", {
                className: horizontalContainer
              }, React.createElement("div", {
                    className: Css.merge(/* :: */[
                          containerRightMargin,
                          /* :: */[
                            verticalContainer,
                            /* [] */0
                          ]
                        ])
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: horizontalContainer
                          }, React.createElement("p", {
                                className: integrationNameLabel
                              }, I18N$BsConsole.show(undefined, "Regex to extract version")), React.createElement("button", {
                                className: advancedRules,
                                onClick: (function (param) {
                                    return Curry._1(toogleAdvancedRules, undefined);
                                  })
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    className: Css.style(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.blue)),
                                          /* :: */[
                                            Css.fontWeight(/* `num */[
                                                  5496390,
                                                  500
                                                ]),
                                            /* :: */[
                                              Css.fontSize(Css.rem(0.875)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "Advanced rule configurations")
                                  }))), React.createElement(TextField.default, {
                            autoFocus: false,
                            helperText: I18N$BsConsole.get(undefined, "Used to extract version number. For fix version of 'project-1.2.3', use 'project-(.*)' such that version will be 1.2.3."),
                            disabled: false,
                            placeholder: "",
                            value: versionRegEx,
                            color: "primary",
                            variant: "outlined",
                            classes: {
                              root: input
                            },
                            FormHelperTextProps: {
                              classes: {
                                contained: formHelperText
                              }
                            },
                            onChange: (function ($$event) {
                                return Curry._1(handleversionRegExChange, Belt_Option.getWithDefault($$event.target.value, ""));
                              })
                          }))));
  }
}

var ResolveUnitlRegexRow = {
  make: JiraConnection$ResolveUnitlRegexRow
};

function JiraConnection$ResolvedUntilBehavior(Props) {
  var attributes = Props.attributes;
  var versionFieldsAvailable = Props.versionFieldsAvailable;
  var versionFieldSelection = Props.versionFieldSelection;
  var versionRegEx = Props.versionRegEx;
  var resolveUntilValidationError = Props.resolveUntilValidationError;
  var btVersionAttribute = Props.btVersionAttribute;
  var handleBtVersionAttributeChange = Props.handleBtVersionAttributeChange;
  var handleJiraVersionChange = Props.handleJiraVersionChange;
  var handleversionRegExChange = Props.handleversionRegExChange;
  var advancedRules = Props.advancedRules;
  var handleAddAdvancedRule = Props.handleAddAdvancedRule;
  var handleDeleteAdvancedRule = Props.handleDeleteAdvancedRule;
  var useAdvancedRules = Props.useAdvancedRules;
  var toogleAdvancedRules = Props.toogleAdvancedRules;
  var handleUnsavedRuleChanges = Props.handleUnsavedRuleChanges;
  var foundUnsavedRuleChanges = Props.foundUnsavedRuleChanges;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            containerRightMargin,
                            /* :: */[
                              verticalContainer,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(0)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Resolved until behavior")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Upon syncing Jira to Backtrace, Backtrace can reopen Jira issues if the error reappears in a newer version.")
                        }))), React.createElement(JiraConnection$ResolvedUnitlVersionRow, {
                  attributes: attributes,
                  versionFieldsAvailable: versionFieldsAvailable,
                  versionFieldSelection: versionFieldSelection,
                  btVersionAttribute: btVersionAttribute,
                  handleJiraVersionChange: handleJiraVersionChange,
                  handleBtVersionAttributeChange: handleBtVersionAttributeChange,
                  resolveUntilValidationError: resolveUntilValidationError
                }), React.createElement(JiraConnection$ResolveUnitlRegexRow, {
                  versionRegEx: versionRegEx,
                  handleversionRegExChange: handleversionRegExChange,
                  attributes: attributes,
                  advancedRules: advancedRules,
                  handleAddAdvancedRule: handleAddAdvancedRule,
                  handleDeleteAdvancedRule: handleDeleteAdvancedRule,
                  useAdvancedRules: useAdvancedRules,
                  toogleAdvancedRules: toogleAdvancedRules,
                  handleUnsavedRuleChanges: handleUnsavedRuleChanges,
                  foundUnsavedRuleChanges: foundUnsavedRuleChanges
                }));
}

var ResolvedUntilBehavior = {
  make: JiraConnection$ResolvedUntilBehavior
};

function JiraConnection$ComponentRow(Props) {
  var index = Props.index;
  var selectedComponentId = Props.selectedComponentId;
  var jiraComponentSelections = Props.jiraComponentSelections;
  var jiraComponentsAvailable = Props.jiraComponentsAvailable;
  var jiraProjectSelection = Props.jiraProjectSelection;
  var handleComponentChange = Props.handleComponentChange;
  var handleDeleteComponentRow = Props.handleDeleteComponentRow;
  var match = React.useState((function () {
          
        }));
  var setError = match[1];
  var error = match[0];
  React.useEffect((function () {
          if (Belt_Option.isSome(error)) {
            Curry._1(setError, (function (param) {
                    
                  }));
          }
          
        }), /* tuple */[
        jiraProjectSelection,
        jiraComponentsAvailable
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: flexRow,
                  onClick: (function (param) {
                      return Curry._1(setError, (function (param) {
                                    var match = Belt_Option.isSome(jiraProjectSelection);
                                    var match$1 = jiraComponentsAvailable.length !== 0;
                                    if (match) {
                                      if (match$1) {
                                        return ;
                                      } else {
                                        return "There are no Jira components associated with this Jira project.";
                                      }
                                    } else {
                                      return "You must select a project name before you can select this field";
                                    }
                                  }));
                    })
                }, React.createElement(Select.default, {
                      value: selectedComponentId,
                      disabled: jiraComponentsAvailable.length === 0,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._2(handleComponentChange, value, index);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: inputItem
                      },
                      autoWidth: true,
                      children: jiraComponentsAvailable.length !== 0 ? Belt_Array.map(jiraComponentsAvailable, (function (component) {
                                return React.createElement(MenuItem.default, {
                                            disabled: Belt_Array.some(jiraComponentSelections, (function (componentIdFromSelection) {
                                                    return componentIdFromSelection === component.id;
                                                  })),
                                            value: component.id,
                                            children: I18N$BsConsole.showSkip(component.name),
                                            key: component.name
                                          });
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), React.createElement(JiraCloseIconButton$BsConsole.make, {
                      handleClick: (function (param) {
                          return Curry._1(handleDeleteComponentRow, index);
                        }),
                      ariaLabel: "delete component row"
                    })), error !== undefined ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, error)
                  }) : null);
}

var ComponentRow = {
  make: JiraConnection$ComponentRow
};

function JiraConnection$Components(Props) {
  var jiraComponentSelections = Props.jiraComponentSelections;
  var jiraComponentsAvailable = Props.jiraComponentsAvailable;
  var handleComponentChange = Props.handleComponentChange;
  var handleAddNewComponentRow = Props.handleAddNewComponentRow;
  var handleDeleteComponentRow = Props.handleDeleteComponentRow;
  var jiraProjectSelection = Props.jiraProjectSelection;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            containerRightMargin,
                            /* :: */[
                              verticalContainer,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(0)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Components")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Attach available Jira components to tickets created in Backtrace")
                        }))), Belt_Array.mapWithIndex(jiraComponentSelections, (function (index, selectedComponentId) {
                    return React.createElement(JiraConnection$ComponentRow, {
                                index: index,
                                selectedComponentId: selectedComponentId,
                                jiraComponentSelections: jiraComponentSelections,
                                jiraComponentsAvailable: jiraComponentsAvailable,
                                jiraProjectSelection: jiraProjectSelection,
                                handleComponentChange: handleComponentChange,
                                handleDeleteComponentRow: handleDeleteComponentRow,
                                key: selectedComponentId
                              });
                  })), React.createElement(JiraAddNewRowButton$BsConsole.make, {
                  item: "component",
                  handleClick: (function (param) {
                      return Curry._1(handleAddNewComponentRow, undefined);
                    })
                }));
}

var Components = {
  make: JiraConnection$Components
};

function JiraConnection$DataSyncRadioGroup(Props) {
  var title = Props.title;
  var secondaryText = Props.secondaryText;
  var shouldSync = Props.shouldSync;
  var handleChange = Props.handleChange;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: className
            }, React.createElement(JiraConnection$Required, {
                  className: integrationNameLabel,
                  children: I18N$BsConsole.show(undefined, title)
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, secondaryText)
                }), React.createElement(RadioGroup.default, {
                  value: shouldSync,
                  onChange: (function (e) {
                      var tmp;
                      try {
                        tmp = Pervasives.bool_of_string(e.target.value);
                      }
                      catch (exn){
                        tmp = false;
                      }
                      return Curry._1(handleChange, tmp);
                    }),
                  classes: {
                    root: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* row */5693978),
                            /* [] */0
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(FormControlLabel.default, {
                      value: true,
                      label: I18N$BsConsole.get(undefined, "Enabled"),
                      control: React.createElement(Radio.default, {
                            color: "primary"
                          })
                    }), React.createElement(FormControlLabel.default, {
                      value: false,
                      label: I18N$BsConsole.get(undefined, "Disabled"),
                      control: React.createElement(Radio.default, {
                            color: "primary"
                          })
                    })));
}

var DataSyncRadioGroup = {
  make: JiraConnection$DataSyncRadioGroup
};

var docLinkDataSync = "https://docs.saucelabs.com/error-reporting/workflow-integrations/issue-tracking/jira/#data-synchronization";

function JiraConnection$JiraToBtSyncRadioGroup(Props) {
  var title = Props.title;
  var secondaryText = Props.secondaryText;
  var shouldSync = Props.shouldSync;
  var handleChange = Props.handleChange;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: className
            }, React.createElement(JiraConnection$Required, {
                  className: integrationNameLabel,
                  children: I18N$BsConsole.show(undefined, title)
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, secondaryText)
                }), React.createElement(RadioGroup.default, {
                  value: Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraToBtSyncOption.toString, shouldSync),
                  onChange: (function (e) {
                      return Curry._1(handleChange, Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.map(Caml_option.nullable_to_opt(e.target.value), ApiWfIntegrations$BsConsole.Jira.JiraToBtSyncOption.fromString), (function (x) {
                                            return x;
                                          })), /* Disabled */2));
                    }),
                  classes: {
                    root: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* row */5693978),
                            /* [] */0
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(FormControlLabel.default, {
                      value: Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraToBtSyncOption.toString, /* Enabled */0),
                      label: I18N$BsConsole.get(undefined, "Automatic setup"),
                      control: React.createElement(Radio.default, {
                            color: "primary"
                          })
                    }), React.createElement(FormControlLabel.default, {
                      value: Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraToBtSyncOption.toString, /* Manual */1),
                      label: I18N$BsConsole.get(undefined, "Manual setup"),
                      control: React.createElement(Radio.default, {
                            color: "primary"
                          })
                    }), React.createElement(FormControlLabel.default, {
                      value: Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraToBtSyncOption.toString, /* Disabled */2),
                      label: I18N$BsConsole.get(undefined, "Disabled"),
                      control: React.createElement(Radio.default, {
                            color: "primary"
                          })
                    })), shouldSync === /* Manual */1 ? React.createElement(FormHelperText.default, {
                    children: null
                  }, I18N$BsConsole.show(undefined, "You must create the webhook manually in Jira. See "), React.createElement("a", {
                        href: docLinkDataSync,
                        rel: "noopener noreferrer",
                        target: "_blank"
                      }, I18N$BsConsole.show(undefined, "our Jira Server documentation."))) : null);
}

var JiraToBtSyncRadioGroup = {
  docLinkDataSync: docLinkDataSync,
  make: JiraConnection$JiraToBtSyncRadioGroup
};

function boolToSyncIssuesOnAdd(param) {
  if (param) {
    return /* JiraOverridesBacktrace */0;
  } else {
    return /* BacktraceOverridesJira */1;
  }
}

function syncIssuesOnAddToBool(param) {
  if (param) {
    return false;
  } else {
    return true;
  }
}

function JiraConnection$SyncIssues(Props) {
  var syncIssuesOnAdd = Props.syncIssuesOnAdd;
  var handleSyncIssuesOnAddChange = Props.handleSyncIssuesOnAddChange;
  var syncFromBtToJira = Props.syncFromBtToJira;
  var syncFromJiraToBt = Props.syncFromJiraToBt;
  var match = React.useState((function () {
          return true;
        }));
  var setIsVisible = match[1];
  React.useEffect((function () {
          if (syncFromBtToJira) {
            if (syncFromJiraToBt >= 2) {
              Curry._1(handleSyncIssuesOnAddChange, /* BacktraceOverridesJira */1);
              Curry._1(setIsVisible, (function (param) {
                      return true;
                    }));
            } else {
              Curry._1(setIsVisible, (function (param) {
                      return true;
                    }));
            }
          } else if (syncFromJiraToBt >= 2) {
            Curry._1(handleSyncIssuesOnAddChange, /* BacktraceOverridesJira */1);
            Curry._1(setIsVisible, (function (param) {
                    return false;
                  }));
          } else {
            Curry._1(handleSyncIssuesOnAddChange, /* JiraOverridesBacktrace */0);
            Curry._1(setIsVisible, (function (param) {
                    return true;
                  }));
          }
          
        }), /* tuple */[
        syncFromBtToJira,
        syncFromJiraToBt
      ]);
  if (match[0]) {
    return React.createElement("div", {
                className: horizontalContainer
              }, React.createElement("div", {
                    className: Css.merge(/* :: */[
                          containerRightMargin,
                          /* :: */[
                            verticalContainer,
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(JiraConnection$Required, {
                        className: integrationNameLabel,
                        children: I18N$BsConsole.show(undefined, "Assignee and Status Synchronization on Linking")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Use Assignee and Status from either Backtrace or Jira if there is a mismatch")
                      }), React.createElement(RadioGroup.default, {
                        value: syncIssuesOnAdd,
                        onChange: (function (e) {
                            var tmp;
                            try {
                              var param = Pervasives.bool_of_string(e.target.value);
                              tmp = param ? /* JiraOverridesBacktrace */0 : /* BacktraceOverridesJira */1;
                            }
                            catch (exn){
                              tmp = /* JiraOverridesBacktrace */0;
                            }
                            return Curry._1(handleSyncIssuesOnAddChange, tmp);
                          }),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.display(/* flex */-1010954439),
                                /* :: */[
                                  Css.flexDirection(/* row */5693978),
                                  /* [] */0
                                ]
                              ])
                        },
                        children: null
                      }, React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.display(/* block */888960333),
                                  /* :: */[
                                    Css.marginRight(Css.px(25)),
                                    /* [] */0
                                  ]
                                ])
                          }, React.createElement(FormControlLabel.default, {
                                value: true,
                                label: I18N$BsConsole.get(undefined, "Jira overrides Backtrace"),
                                disabled: syncFromJiraToBt === /* Disabled */2,
                                control: React.createElement(Radio.default, {
                                      checked: syncIssuesOnAdd ? false : true,
                                      color: "primary"
                                    })
                              }), React.createElement(FormHelperText.default, {
                                className: Css.style(/* :: */[
                                      Css.transform(Css.translate(Css.px(33), Css.px(-19))),
                                      /* :: */[
                                        Css.width(Css.pct(85)),
                                        /* :: */[
                                          Css.lineHeight(Css.px(15)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                children: syncFromJiraToBt === /* Disabled */2 ? I18N$BsConsole.show(undefined, "To select turn on \"Data synchronization from Jira to Backtrace\"") : I18N$BsConsole.show(undefined, "(recommended)")
                              })), React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.display(/* block */888960333),
                                  /* [] */0
                                ])
                          }, React.createElement(FormControlLabel.default, {
                                value: false,
                                label: I18N$BsConsole.get(undefined, "Backtrace overrides Jira"),
                                disabled: !syncFromBtToJira,
                                control: React.createElement(Radio.default, {
                                      checked: !(
                                        syncIssuesOnAdd ? false : true
                                      ),
                                      color: "primary"
                                    })
                              }), syncFromBtToJira ? null : React.createElement(FormHelperText.default, {
                                  className: Css.style(/* :: */[
                                        Css.transform(Css.translate(Css.px(33), Css.px(-19))),
                                        /* :: */[
                                          Css.width(Css.pct(85)),
                                          /* :: */[
                                            Css.lineHeight(Css.px(15)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  children: I18N$BsConsole.show(undefined, "To select turn on \"Data synchronization from Backtrace to Jira\"")
                                })))));
  } else {
    return null;
  }
}

var SyncIssues = {
  make: JiraConnection$SyncIssues
};

var initialState_jiraProjects = [];

var initialState_jiraIssueTypes = [];

var initialState_jiraContentKeys = [];

var initialState_selectedAttributes = [];

var initialState_customFieldsAvailable = [];

var initialState_customFieldValidationErrors = [];

var initialState_versionFieldsAvailable = [];

var initialState_jiraComponentSelections = [""];

var initialState_jiraComponentsAvailable = [];

var initialState_advancedRules = [];

var initialState_jiraStatusesAvailable = [];

var initialState = {
  awaitEditAutoPopulation: true,
  integrationId: undefined,
  integrationName: "",
  connectionId: undefined,
  connectionCreated: false,
  jiraProjectSelection: undefined,
  jiraProjects: initialState_jiraProjects,
  jiraProjectsFetched: false,
  jiraIssueTypeSelection: undefined,
  jiraIssueTypes: initialState_jiraIssueTypes,
  jiraContentKeySelection: undefined,
  jiraContentKeys: initialState_jiraContentKeys,
  errorMessage: "",
  syncFromBtToJira: true,
  syncFromJiraToBt: /* Enabled */0,
  syncIssuesOnAdd: /* JiraOverridesBacktrace */0,
  advancedOptionsOpen: false,
  subjectLineVal: "BACKTRACE - {{project}} - {{application}} - {{group_prefix}} : {{classifiers}} / {{frames}}",
  selectedAttributes: initialState_selectedAttributes,
  customFieldSelections: /* [] */0,
  customFieldsAvailable: initialState_customFieldsAvailable,
  customFieldValidationErrors: initialState_customFieldValidationErrors,
  btVersionAttribute: undefined,
  versionFieldSelection: undefined,
  versionFieldsAvailable: initialState_versionFieldsAvailable,
  versionRegEx: "(.*)",
  resolveUntilValidationError: false,
  jiraComponentSelections: initialState_jiraComponentSelections,
  jiraComponentsAvailable: initialState_jiraComponentsAvailable,
  advancedRules: initialState_advancedRules,
  useAdvancedRules: false,
  foundUnsavedRuleChanges: false,
  jiraStatusMappingSelections: undefined,
  jiraStatusesAvailable: initialState_jiraStatusesAvailable,
  isStatusMappingValid: true
};

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* ToggleAdvancedOptionsOpen */0) {
      var newrecord = Caml_obj.caml_obj_dup(state);
      newrecord.advancedOptionsOpen = !state.advancedOptionsOpen;
      return newrecord;
    }
    var newrecord$1 = Caml_obj.caml_obj_dup(state);
    newrecord$1.useAdvancedRules = !state.useAdvancedRules;
    return newrecord$1;
  } else {
    switch (action.tag | 0) {
      case /* SetIntegrationName */0 :
          var newrecord$2 = Caml_obj.caml_obj_dup(state);
          newrecord$2.integrationName = action[0];
          return newrecord$2;
      case /* SetConnectionId */1 :
          var newrecord$3 = Caml_obj.caml_obj_dup(state);
          newrecord$3.connectionCreated = action[1];
          newrecord$3.connectionId = action[0];
          return newrecord$3;
      case /* SetJiraProjectSelection */2 :
          var newrecord$4 = Caml_obj.caml_obj_dup(state);
          newrecord$4.jiraProjectSelection = action[0];
          return newrecord$4;
      case /* QueryJiraProjectsSuccess */3 :
          var newrecord$5 = Caml_obj.caml_obj_dup(state);
          newrecord$5.jiraProjectsFetched = true;
          newrecord$5.jiraProjects = action[0];
          return newrecord$5;
      case /* QueryJiraProjectsFailure */4 :
          var newrecord$6 = Caml_obj.caml_obj_dup(state);
          newrecord$6.errorMessage = action[0];
          newrecord$6.jiraProjectsFetched = true;
          return newrecord$6;
      case /* SetJiraIssueTypeSelection */5 :
          var newrecord$7 = Caml_obj.caml_obj_dup(state);
          newrecord$7.jiraIssueTypeSelection = action[0];
          return newrecord$7;
      case /* QueryJiraIssueTypesSuccess */6 :
          var newrecord$8 = Caml_obj.caml_obj_dup(state);
          newrecord$8.jiraIssueTypes = action[0];
          newrecord$8.jiraIssueTypeSelection = action[1];
          newrecord$8.jiraProjectSelection = action[2];
          return newrecord$8;
      case /* SetJiraContentKeySelection */7 :
          var newrecord$9 = Caml_obj.caml_obj_dup(state);
          newrecord$9.jiraContentKeySelection = action[0];
          return newrecord$9;
      case /* SetCustomFieldsSelection */8 :
          var newrecord$10 = Caml_obj.caml_obj_dup(state);
          newrecord$10.customFieldSelections = action[0];
          return newrecord$10;
      case /* SetCustomFieldsAvailable */9 :
          var newrecord$11 = Caml_obj.caml_obj_dup(state);
          newrecord$11.customFieldsAvailable = action[0];
          return newrecord$11;
      case /* SetVersionFieldsAvailable */10 :
          var newrecord$12 = Caml_obj.caml_obj_dup(state);
          newrecord$12.versionFieldsAvailable = action[0];
          return newrecord$12;
      case /* SetVersionFieldsAvailableAndVersionFieldSelections */11 :
          var newrecord$13 = Caml_obj.caml_obj_dup(state);
          newrecord$13.versionFieldsAvailable = action[0];
          newrecord$13.versionFieldSelection = action[1];
          return newrecord$13;
      case /* SetCustomFieldValidationErrors */12 :
          var newrecord$14 = Caml_obj.caml_obj_dup(state);
          newrecord$14.customFieldValidationErrors = action[0];
          return newrecord$14;
      case /* QueryJiraContentKeysSuccess */13 :
          var newrecord$15 = Caml_obj.caml_obj_dup(state);
          newrecord$15.jiraContentKeys = action[0];
          newrecord$15.jiraContentKeySelection = action[1];
          return newrecord$15;
      case /* SetErrorMessage */14 :
          var newrecord$16 = Caml_obj.caml_obj_dup(state);
          newrecord$16.errorMessage = action[0];
          newrecord$16.awaitEditAutoPopulation = false;
          return newrecord$16;
      case /* SetSyncFromBtToJira */15 :
          var newrecord$17 = Caml_obj.caml_obj_dup(state);
          newrecord$17.syncFromBtToJira = action[0];
          return newrecord$17;
      case /* SetSyncFromJiraToBt */16 :
          var newrecord$18 = Caml_obj.caml_obj_dup(state);
          newrecord$18.syncFromJiraToBt = action[0];
          return newrecord$18;
      case /* SetSyncIssuesOnAdd */17 :
          var newrecord$19 = Caml_obj.caml_obj_dup(state);
          newrecord$19.syncIssuesOnAdd = action[0];
          return newrecord$19;
      case /* SetSubjectLineVal */18 :
          var newrecord$20 = Caml_obj.caml_obj_dup(state);
          newrecord$20.subjectLineVal = action[0];
          return newrecord$20;
      case /* SetSelectedAttributes */19 :
          var newrecord$21 = Caml_obj.caml_obj_dup(state);
          newrecord$21.selectedAttributes = action[0];
          return newrecord$21;
      case /* AutoPopulateState */20 :
          var autoPopulateState = action[0];
          var newrecord$22 = Caml_obj.caml_obj_dup(state);
          newrecord$22.jiraStatusMappingSelections = autoPopulateState.jiraStatusMappingSelections;
          newrecord$22.useAdvancedRules = autoPopulateState.useAdvancedRules;
          newrecord$22.advancedRules = autoPopulateState.advancedRules;
          newrecord$22.jiraComponentSelections = autoPopulateState.jiraComponentSelections;
          newrecord$22.versionRegEx = autoPopulateState.versionRegEx;
          newrecord$22.versionFieldSelection = autoPopulateState.versionFieldSelection;
          newrecord$22.btVersionAttribute = autoPopulateState.btVersionAttribute;
          newrecord$22.customFieldSelections = autoPopulateState.customFieldSelections;
          newrecord$22.selectedAttributes = autoPopulateState.selectedAttributes;
          newrecord$22.subjectLineVal = autoPopulateState.subjectLineVal;
          newrecord$22.advancedOptionsOpen = autoPopulateState.advancedOptionsOpen;
          newrecord$22.syncIssuesOnAdd = autoPopulateState.syncIssuesOnAdd;
          newrecord$22.syncFromJiraToBt = autoPopulateState.syncFromJiraToBt;
          newrecord$22.syncFromBtToJira = autoPopulateState.syncFromBtToJira;
          newrecord$22.jiraContentKeySelection = action[2];
          newrecord$22.jiraIssueTypeSelection = action[1];
          newrecord$22.jiraProjectSelection = autoPopulateState.jiraProjectSelection;
          newrecord$22.connectionId = autoPopulateState.connectionId;
          newrecord$22.integrationName = autoPopulateState.integrationName;
          newrecord$22.integrationId = autoPopulateState.integrationId;
          newrecord$22.awaitEditAutoPopulation = false;
          return newrecord$22;
      case /* SetBtVersionAttribute */21 :
          var newrecord$23 = Caml_obj.caml_obj_dup(state);
          newrecord$23.btVersionAttribute = action[0];
          return newrecord$23;
      case /* SetVersionFieldSelection */22 :
          var newrecord$24 = Caml_obj.caml_obj_dup(state);
          newrecord$24.versionFieldSelection = action[0];
          return newrecord$24;
      case /* SetVersionRegEx */23 :
          var newrecord$25 = Caml_obj.caml_obj_dup(state);
          newrecord$25.versionRegEx = action[0];
          return newrecord$25;
      case /* SetResolveUnitlValidationError */24 :
          var newrecord$26 = Caml_obj.caml_obj_dup(state);
          newrecord$26.resolveUntilValidationError = action[0];
          return newrecord$26;
      case /* SetJiraComponentSelections */25 :
          var newrecord$27 = Caml_obj.caml_obj_dup(state);
          newrecord$27.jiraComponentSelections = action[0];
          return newrecord$27;
      case /* SetJiraComponentsAvailable */26 :
          var newrecord$28 = Caml_obj.caml_obj_dup(state);
          newrecord$28.jiraComponentsAvailable = action[0];
          return newrecord$28;
      case /* SetAdvancedRules */27 :
          var newrecord$29 = Caml_obj.caml_obj_dup(state);
          newrecord$29.advancedRules = action[0];
          return newrecord$29;
      case /* SetUnsavedRuleChanges */28 :
          var newrecord$30 = Caml_obj.caml_obj_dup(state);
          newrecord$30.foundUnsavedRuleChanges = action[0];
          return newrecord$30;
      case /* SetJiraStatusMappingSelections */29 :
          var newrecord$31 = Caml_obj.caml_obj_dup(state);
          newrecord$31.jiraStatusMappingSelections = action[0];
          return newrecord$31;
      case /* SetJiraStatusesAvailable */30 :
          var newrecord$32 = Caml_obj.caml_obj_dup(state);
          newrecord$32.jiraStatusesAvailable = action[0];
          newrecord$32.jiraStatusMappingSelections = action[1];
          return newrecord$32;
      case /* SetIsStatusMappingValid */31 :
          var newrecord$33 = Caml_obj.caml_obj_dup(state);
          newrecord$33.isStatusMappingValid = action[0];
          return newrecord$33;
      
    }
  }
}

function use(formState, jiraType, universe, token, handleChangeUrl, projectName, config) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var jiraStatusesAvailable = match$1.jiraStatusesAvailable;
  var jiraStatusMappingSelections = match$1.jiraStatusMappingSelections;
  var foundUnsavedRuleChanges = match$1.foundUnsavedRuleChanges;
  var useAdvancedRules = match$1.useAdvancedRules;
  var advancedRules = match$1.advancedRules;
  var jiraComponentSelections = match$1.jiraComponentSelections;
  var versionRegEx = match$1.versionRegEx;
  var versionFieldSelection = match$1.versionFieldSelection;
  var btVersionAttribute = match$1.btVersionAttribute;
  var customFieldValidationErrors = match$1.customFieldValidationErrors;
  var customFieldsAvailable = match$1.customFieldsAvailable;
  var customFieldSelections = match$1.customFieldSelections;
  var selectedAttributes = match$1.selectedAttributes;
  var subjectLineVal = match$1.subjectLineVal;
  var syncIssuesOnAdd = match$1.syncIssuesOnAdd;
  var syncFromJiraToBt = match$1.syncFromJiraToBt;
  var syncFromBtToJira = match$1.syncFromBtToJira;
  var jiraContentKeySelection = match$1.jiraContentKeySelection;
  var jiraIssueTypeSelection = match$1.jiraIssueTypeSelection;
  var jiraProjectSelection = match$1.jiraProjectSelection;
  var connectionId = match$1.connectionId;
  var integrationName = match$1.integrationName;
  var integrationId = match$1.integrationId;
  var awaitEditAutoPopulation = match$1.awaitEditAutoPopulation;
  var match$2 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match$2[0];
  var attributes;
  attributes = typeof describeRemote === "number" || describeRemote.tag ? /* [] */0 : Belt_List.sort(describeRemote[0], (function (attr1, attr2) {
            return $$String.compare(attr1.name.toLowerCase(), attr2.name.toLowerCase());
          }));
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1) {
            if (jiraIssueTypeSelection !== undefined && connectionId !== undefined && jiraProjectSelection !== undefined) {
              var onSuccess = function (param) {
                var body = param.body;
                var match = Belt_Array.getIndexBy(body, (function (key) {
                        return key.name === "Description";
                      }));
                var defaultContentKey;
                if (jiraContentKeySelection !== undefined) {
                  var index = Belt_Array.getIndexBy(body, (function (key) {
                          return key.name === jiraContentKeySelection.name;
                        }));
                  defaultContentKey = index !== undefined ? Caml_array.caml_array_get(body, index) : Caml_array.caml_array_get(body, 0);
                } else {
                  defaultContentKey = match !== undefined ? Caml_array.caml_array_get(body, match) : Caml_array.caml_array_get(body, 0);
                }
                return Curry._1(dispatch, /* QueryJiraContentKeysSuccess */Block.__(13, [
                              body,
                              defaultContentKey
                            ]));
              };
              var onFailure = function (err) {
                return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
              };
              var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryContentKeys.queryContentKeyTask, connectionId, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), jiraIssueTypeSelection.id, jiraProjectSelection, config);
              if (maybeTask !== undefined) {
                Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                        return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                      }));
              } else {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
              }
            } else {
              Curry._1(dispatch, /* SetJiraContentKeySelection */Block.__(7, [undefined]));
            }
          }
          
        }), /* tuple */[
        jiraIssueTypeSelection,
        awaitEditAutoPopulation
      ]);
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1 && connectionId !== undefined && jiraProjectSelection !== undefined) {
            var onSuccess = function (param) {
              var body = param.body;
              var match = Belt_Array.getIndexBy(body, (function (issue) {
                      return issue.name === "Bug";
                    }));
              var defaultIssueType;
              if (jiraIssueTypeSelection !== undefined) {
                var index = Belt_Array.getIndexBy(body, (function (issue) {
                        return issue.name === jiraIssueTypeSelection.name;
                      }));
                defaultIssueType = index !== undefined ? Caml_array.caml_array_get(body, index) : Caml_array.caml_array_get(body, 0);
              } else {
                defaultIssueType = match !== undefined ? Caml_array.caml_array_get(body, match) : Caml_array.caml_array_get(body, 0);
              }
              return Curry._1(dispatch, /* QueryJiraIssueTypesSuccess */Block.__(6, [
                            body,
                            defaultIssueType,
                            jiraProjectSelection
                          ]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
            };
            var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryIssueTypes.queryIssuesTask, connectionId, jiraProjectSelection, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        jiraProjectSelection,
        awaitEditAutoPopulation
      ]);
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1 && connectionId !== undefined && jiraProjectSelection !== undefined) {
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* SetJiraComponentsAvailable */Block.__(26, [param.body]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
            };
            var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryComponents.queryComponentTask, connectionId, jiraProjectSelection, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        jiraProjectSelection,
        awaitEditAutoPopulation
      ]);
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1 && connectionId !== undefined && jiraProjectSelection !== undefined && jiraIssueTypeSelection !== undefined) {
            var getJiraStatuses = function (defaultProjectStatusIds) {
              var onSuccess = function (param) {
                var body = param.body;
                var keepAvailableStatuses = function (selectedMapping) {
                  if (selectedMapping === undefined) {
                    return ;
                  }
                  var statusArr = Belt_Array.keep(selectedMapping, (function (selectedStatus) {
                          return Belt_Array.some(body, (function (availableStatus) {
                                        return availableStatus.id === selectedStatus;
                                      }));
                        }));
                  if (statusArr.length !== 0) {
                    return statusArr;
                  }
                  
                };
                var removeNoneExistentStatuses = function (selectedMappings) {
                  return {
                          open_: keepAvailableStatuses(selectedMappings.open_),
                          inProgress: keepAvailableStatuses(selectedMappings.inProgress),
                          resolved: keepAvailableStatuses(selectedMappings.resolved),
                          muted: keepAvailableStatuses(selectedMappings.muted),
                          reopened: keepAvailableStatuses(selectedMappings.reopened)
                        };
                };
                var updatedJiraStatusMapping;
                if (jiraStatusMappingSelections !== undefined) {
                  var statuses = removeNoneExistentStatuses(jiraStatusMappingSelections);
                  updatedJiraStatusMapping = Belt_Option.isSome(statuses.open_) || Belt_Option.isSome(statuses.inProgress) || Belt_Option.isSome(statuses.resolved) || Belt_Option.isSome(statuses.muted) || Belt_Option.isSome(statuses.reopened) ? statuses : defaultProjectStatusIds;
                } else {
                  updatedJiraStatusMapping = defaultProjectStatusIds;
                }
                return Curry._1(dispatch, /* SetJiraStatusesAvailable */Block.__(30, [
                              body,
                              updatedJiraStatusMapping
                            ]));
              };
              var onFailure = function (err) {
                return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
              };
              var maybeTask = Curry._6(ApiWfIntegrations$BsConsole.Jira.QueryStatuses.queryStatusesTask, connectionId, jiraIssueTypeSelection.id, jiraProjectSelection, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
              if (maybeTask !== undefined) {
                return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                              return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                            }));
              } else {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
              }
            };
            var onSuccess = function (param) {
              return getJiraStatuses(param.body.projectStatusIds);
            };
            var onFailure = function (param) {
              return getJiraStatuses(undefined);
            };
            var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryIntegrationDefaults.queryIntegrationDefaultsTask, connectionId, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), jiraProjectSelection);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        jiraIssueTypeSelection,
        awaitEditAutoPopulation
      ]);
  var autoPopulateForm = function (integrationId) {
    var onFailure = function (err) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
    };
    var onSuccess = function (param) {
      var body = param.body;
      var match = body.connection;
      if (match === undefined) {
        return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["No connectionId found."]));
      }
      var match$1 = body.options;
      if (match$1 === undefined) {
        return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Response body options are missing values."]));
      }
      var resolvedUntilOptions = match$1.resolvedUntilOptions;
      var customFieldValues = match$1.customFieldValues;
      var resolvedUntilOptionsIndexZero = resolvedUntilOptions !== undefined ? Belt_Array.get(resolvedUntilOptions, 0) : undefined;
      var synchronizeIssuesOnAdd = body.synchronizeIssuesOnAdd;
      var tmp;
      if (resolvedUntilOptions !== undefined) {
        var arr = {
          contents: []
        };
        Belt_Array.forEach(resolvedUntilOptions, (function (resolvedUntilOption) {
                var conditions$prime = resolvedUntilOption.conditions;
                if (conditions$prime !== undefined) {
                  return Belt_Array.forEach(conditions$prime, (function (condition) {
                                var rule_attribute = condition.attribute;
                                var rule_attributeRegex = condition.regex;
                                var rule_jiraRegex = resolvedUntilOption.regex;
                                var rule = {
                                  attribute: rule_attribute,
                                  attributeRegex: rule_attributeRegex,
                                  jiraRegex: rule_jiraRegex
                                };
                                var x = arr.contents;
                                arr.contents = Belt_Array.concat(x, [rule]);
                                
                              }));
                }
                
              }));
        tmp = arr.contents;
      } else {
        tmp = [];
      }
      var autoPopulateState_connectionId = match.connectionId;
      var autoPopulateState_integrationId = integrationId;
      var autoPopulateState_integrationName = body.watcherName;
      var autoPopulateState_jiraProjectSelection = match$1.projectId;
      var autoPopulateState_syncFromBtToJira = Belt_Option.getWithDefault(body.synchronizeIssues, true);
      var autoPopulateState_syncFromJiraToBt = Belt_Option.getWithDefault(match$1.backtraceSync, /* Enabled */0);
      var autoPopulateState_syncIssuesOnAdd = synchronizeIssuesOnAdd !== undefined && !synchronizeIssuesOnAdd ? /* BacktraceOverridesJira */1 : /* JiraOverridesBacktrace */0;
      var autoPopulateState_subjectLineVal = Belt_Option.getWithDefault(match$1.summaryTemplate, subjectLineVal);
      var autoPopulateState_selectedAttributes = Belt_Option.getWithDefault(Belt_Option.map(match$1.displaySettings, (function (settings) {
                  return settings.attributeList;
                })), []);
      var autoPopulateState_customFieldSelections = customFieldValues !== undefined ? customFieldValues : /* [] */0;
      var autoPopulateState_btVersionAttribute = resolvedUntilOptionsIndexZero !== undefined ? resolvedUntilOptionsIndexZero.attribute : undefined;
      var autoPopulateState_versionFieldSelection = resolvedUntilOptionsIndexZero !== undefined ? resolvedUntilOptionsIndexZero.jiraFieldKey : undefined;
      var autoPopulateState_versionRegEx = resolvedUntilOptionsIndexZero !== undefined ? resolvedUntilOptionsIndexZero.regex : "(.*)";
      var autoPopulateState_jiraComponentSelections = Belt_Option.getWithDefault(match$1.customComponentIds, []);
      var autoPopulateState_useAdvancedRules = resolvedUntilOptions !== undefined ? Belt_Array.some(resolvedUntilOptions, (function (resolvedUntilOption) {
                return Belt_Option.isSome(resolvedUntilOption.conditions);
              })) : false;
      var autoPopulateState_jiraStatusMappingSelections = match$1.projectStatusIds;
      var autoPopulateState = {
        connectionId: autoPopulateState_connectionId,
        integrationId: autoPopulateState_integrationId,
        integrationName: autoPopulateState_integrationName,
        jiraProjectSelection: autoPopulateState_jiraProjectSelection,
        syncFromBtToJira: autoPopulateState_syncFromBtToJira,
        syncFromJiraToBt: autoPopulateState_syncFromJiraToBt,
        syncIssuesOnAdd: autoPopulateState_syncIssuesOnAdd,
        advancedOptionsOpen: true,
        subjectLineVal: autoPopulateState_subjectLineVal,
        selectedAttributes: autoPopulateState_selectedAttributes,
        customFieldSelections: autoPopulateState_customFieldSelections,
        btVersionAttribute: autoPopulateState_btVersionAttribute,
        versionFieldSelection: autoPopulateState_versionFieldSelection,
        versionRegEx: autoPopulateState_versionRegEx,
        jiraComponentSelections: autoPopulateState_jiraComponentSelections,
        advancedRules: tmp,
        useAdvancedRules: autoPopulateState_useAdvancedRules,
        jiraStatusMappingSelections: autoPopulateState_jiraStatusMappingSelections
      };
      var issueTypeId = match$1.issueTypeId;
      var contentKeyId = match$1.contentKey;
      var match$2 = autoPopulateState_connectionId;
      var match$3 = autoPopulateState_jiraProjectSelection;
      if (match$2 !== undefined && match$3 !== undefined && issueTypeId !== undefined) {
        var onSuccess$1 = function (param) {
          var issueType = Belt_Array.getBy(param.body, (function (issue) {
                  return issue.id === issueTypeId;
                }));
          if (issueType !== undefined) {
            var match = autoPopulateState_connectionId;
            var match$1 = autoPopulateState_jiraProjectSelection;
            if (match !== undefined && match$1 !== undefined && contentKeyId !== undefined) {
              var onSuccess$2 = function (param) {
                var contentKey = Belt_Array.getBy(param.body, (function (key) {
                        return key.id === contentKeyId;
                      }));
                if (contentKey !== undefined) {
                  return Curry._1(dispatch, /* AutoPopulateState */Block.__(20, [
                                autoPopulateState,
                                issueType,
                                contentKey
                              ]));
                } else {
                  return Curry._1(dispatch, /* AutoPopulateState */Block.__(20, [
                                autoPopulateState,
                                issueType,
                                undefined
                              ]));
                }
              };
              var onFailure = function (err) {
                return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
              };
              var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryContentKeys.queryContentKeyTask, match, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), issueType.id, match$1, config);
              if (maybeTask !== undefined) {
                return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                              return ApiWf$BsConsole.responseCb(onSuccess$2, onFailure, param);
                            }));
              } else {
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
              }
            }
            return Curry._1(dispatch, /* AutoPopulateState */Block.__(20, [
                          autoPopulateState,
                          undefined,
                          undefined
                        ]));
          } else {
            return Curry._1(dispatch, /* AutoPopulateState */Block.__(20, [
                          autoPopulateState,
                          undefined,
                          undefined
                        ]));
          }
        };
        var onFailure = function (err) {
          return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
        };
        var maybeTask = Curry._5(ApiWfIntegrations$BsConsole.Jira.QueryIssueTypes.queryIssuesTask, match$2, match$3, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
        if (maybeTask !== undefined) {
          return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                        return ApiWf$BsConsole.responseCb(onSuccess$1, onFailure, param);
                      }));
        } else {
          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
        }
      }
      var errorMessage = (
        Belt_Option.isNone(autoPopulateState_connectionId) ? "Connection Id is missing. " : ""
      ) + ((
          Belt_Option.isNone(autoPopulateState_jiraProjectSelection) ? "Jira Project Id is missing. " : ""
        ) + (
          Belt_Option.isNone(issueTypeId) ? "Issue Type Id is missing." : ""
        ));
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [errorMessage]));
    };
    var maybeTask = ApiWfIntegrations$BsConsole.QueryIntegration.queryIntegrationTask(projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), integrationId, config);
    if (maybeTask !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  React.useEffect((function () {
          if (formState.tag) {
            autoPopulateForm(formState[0]);
          } else {
            Curry._1(dispatch, /* SetConnectionId */Block.__(1, [
                    formState[0],
                    formState[1]
                  ]));
          }
          
        }), ([]));
  React.useEffect((function () {
          if (connectionId !== undefined) {
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* QueryJiraProjectsSuccess */Block.__(3, [param.body]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* QueryJiraProjectsFailure */Block.__(4, [err]));
            };
            var maybeTask = Curry._4(ApiWfIntegrations$BsConsole.Jira.QueryProjects.queryProjectsTask, connectionId, config, projectName, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        connectionId,
        universe
      ]);
  var removeNoneExistentVersionFields = function (versionFieldSelection, versionFieldsAvailable) {
    if (versionFieldSelection !== undefined && Belt_Array.some(versionFieldsAvailable, (function (field) {
              return field.id === versionFieldSelection;
            }))) {
      return versionFieldSelection;
    }
    
  };
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1 && connectionId !== undefined && jiraProjectSelection !== undefined && jiraIssueTypeSelection !== undefined) {
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* SetCustomFieldsAvailable */Block.__(9, [param.body]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
            };
            var maybeTask = Curry._6(ApiWfIntegrations$BsConsole.Jira.QueryFieldValues.queryCustomFieldValuesTask, connectionId, jiraProjectSelection, jiraIssueTypeSelection.id, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), universe, config);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        jiraIssueTypeSelection,
        awaitEditAutoPopulation
      ]);
  React.useEffect((function () {
          var exit = 0;
          if (!(formState.tag && awaitEditAutoPopulation === true)) {
            exit = 1;
          }
          if (exit === 1 && connectionId !== undefined && jiraProjectSelection !== undefined && jiraIssueTypeSelection !== undefined) {
            var onSuccess = function (param) {
              var body = param.body;
              var updatedJiraVersionFieldsSelection = removeNoneExistentVersionFields(versionFieldSelection, body);
              return Curry._1(dispatch, /* SetVersionFieldsAvailableAndVersionFieldSelections */Block.__(11, [
                            body,
                            updatedJiraVersionFieldsSelection
                          ]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
            };
            var maybeTask = Curry._6(ApiWfIntegrations$BsConsole.Jira.QueryFieldValues.queryFieldValuesTask, connectionId, jiraProjectSelection, jiraIssueTypeSelection.id, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), universe, config);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), /* tuple */[
        jiraIssueTypeSelection,
        awaitEditAutoPopulation
      ]);
  var isSubjectLineValid = function (param) {
    var isValid = true;
    if (subjectLineVal.trim() === "") {
      isValid = false;
    } else {
      var hasInvalidMacrosOrAttributes = Belt_Array.keep(subjectLineVal.trim().split(" "), (function (value) {
              if (value.startsWith("$")) {
                return !Belt_List.some(attributes, (function (attr) {
                              return attr.name === value.substr(1);
                            }));
              } else if (value.startsWith("{{") && value.endsWith("}}")) {
                return !Belt_List.some(availableMacros, (function (macro) {
                              return macro === value;
                            }));
              } else {
                return false;
              }
            })).length !== 0;
      isValid = !hasInvalidMacrosOrAttributes;
    }
    return isValid;
  };
  var areFieldsValid = function (param) {
    var isValid = {
      contents: true
    };
    if (Belt_List.length(customFieldSelections) > 0) {
      Belt_List.forEachWithIndex(customFieldSelections, (function (index, field) {
              var match = field[0] === "" && field[1] !== "";
              var match$1 = field[0] !== "";
              if (match) {
                Curry._1(dispatch, /* SetCustomFieldValidationErrors */Block.__(12, [Belt_Array.concat(customFieldValidationErrors, [index])]));
                isValid.contents = false;
                return ;
              } else if (match$1 && Belt_Array.some(customFieldsAvailable, (function (availableField) {
                        return availableField.id === field[0];
                      })) === false) {
                isValid.contents = false;
                return ;
              } else {
                return ;
              }
            }));
    }
    if (btVersionAttribute !== undefined) {
      if (versionFieldSelection !== undefined) {
        
      } else {
        Curry._1(dispatch, /* SetResolveUnitlValidationError */Block.__(24, [true]));
        isValid.contents = false;
      }
    } else if (versionFieldSelection !== undefined) {
      Curry._1(dispatch, /* SetResolveUnitlValidationError */Block.__(24, [true]));
      isValid.contents = false;
    }
    return isValid.contents;
  };
  var areAttributesValid = function (param) {
    var isValid = {
      contents: true
    };
    Belt_List.forEach(customFieldSelections, (function (customFieldRow) {
            return Belt_Array.forEach(customFieldRow[1].trim().split(" "), (function (attribute) {
                          if (attribute.startsWith("$") && !Belt_List.some(attributes, (function (attr) {
                                    return attr.name === attribute.substr(1);
                                  }))) {
                            isValid.contents = false;
                            return ;
                          }
                          
                        }));
          }));
    Belt_Array.forEach(selectedAttributes, (function (attribute) {
            if (!Belt_List.some(attributes, (function (attr) {
                      return attr.name === attribute;
                    }))) {
              isValid.contents = false;
              return ;
            }
            
          }));
    return isValid.contents;
  };
  var areStatusMappingsValid = function (param) {
    var isValid;
    var exit = 0;
    if (syncFromBtToJira || syncFromJiraToBt < 2) {
      exit = 1;
    } else {
      isValid = true;
    }
    if (exit === 1) {
      if (jiraStatusMappingSelections !== undefined) {
        var open_ = Belt_Option.getWithDefault(jiraStatusMappingSelections.open_, []);
        var inProgress = Belt_Option.getWithDefault(jiraStatusMappingSelections.inProgress, []);
        var resolved = Belt_Option.getWithDefault(jiraStatusMappingSelections.resolved, []);
        var muted = Belt_Option.getWithDefault(jiraStatusMappingSelections.muted, []);
        var allStatuses = Util$BsConsole.getUniqueValues(Belt_Array.concatMany([
                  open_,
                  inProgress,
                  resolved,
                  muted
                ]));
        isValid = Belt_Array.keep(jiraStatusesAvailable, (function (availableStatus) {
                return !Belt_Array.some(allStatuses, (function (selectedStatus) {
                              return selectedStatus === availableStatus.id;
                            }));
              })).length === 0;
      } else {
        isValid = false;
      }
    }
    Curry._1(dispatch, /* SetIsStatusMappingValid */Block.__(31, [isValid]));
    return isValid;
  };
  var handleCancel = function (param) {
    if (formState.tag && !formState[1]) {
      return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                      error: undefined
                    }]));
    } else {
      return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                    projectName,
                    WfRouter$BsConsole.root,
                    undefined
                  ]));
    }
  };
  var handleSave = function (param) {
    var onSuccess = function (param) {
      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Created */Block.__(12, [jiraType ? "jiraServer" : "jiraCloud"])]));
      if (formState.tag && !formState[1]) {
        return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                        error: undefined
                      }]));
      } else {
        return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                      projectName,
                      WfRouter$BsConsole.root,
                      undefined
                    ]));
      }
    };
    var onFailure = function (err) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, [err]));
    };
    var customFieldsSelectionWithoutEmptyFields = Belt_List.keep(customFieldSelections, (function (field) {
            return field[0] !== "";
          }));
    var resolvedUntilOptions = useAdvancedRules ? Belt_Array.map(advancedRules, (function (rule) {
              return {
                      attribute: Belt_Option.getWithDefault(btVersionAttribute, ""),
                      jiraFieldKey: Belt_Option.getWithDefault(versionFieldSelection, ""),
                      regex: rule.jiraRegex,
                      conditions: [{
                          attribute: rule.attribute,
                          regex: rule.attributeRegex
                        }]
                    };
            })) : (
        btVersionAttribute !== undefined ? (
            versionFieldSelection !== undefined ? [{
                  attribute: btVersionAttribute,
                  jiraFieldKey: versionFieldSelection,
                  regex: versionRegEx,
                  conditions: undefined
                }] : []
          ) : []
      );
    var customComponentIds = Belt_Array.keep(jiraComponentSelections, (function (component) {
            return component !== "";
          }));
    if (!(areFieldsValid(undefined) && areAttributesValid(undefined) && areStatusMappingsValid(undefined) && !foundUnsavedRuleChanges && isSubjectLineValid(undefined))) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Invalid field(s), please try again."]));
    }
    if (connectionId === undefined) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Something went wrong. Please try again."]));
    }
    if (jiraProjectSelection === undefined) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Something went wrong. Please try again."]));
    }
    if (jiraIssueTypeSelection === undefined) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Something went wrong. Please try again."]));
    }
    if (jiraContentKeySelection === undefined) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Something went wrong. Please try again."]));
    }
    if (formState.tag) {
      if (integrationId === undefined) {
        return Curry._1(dispatch, /* SetErrorMessage */Block.__(14, ["Integration Id is missing."]));
      }
      var maybeTask = ApiWfIntegrations$BsConsole.EditIntegration.editJiraIntegrationTask(connectionId, jiraIssueTypeSelection.id, integrationId, projectName, jiraProjectSelection, jiraContentKeySelection.id, syncFromBtToJira, syncFromJiraToBt, syncIssuesOnAdd ? false : true, subjectLineVal.trim(), true, selectedAttributes, config, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), customFieldsSelectionWithoutEmptyFields, resolvedUntilOptions, customComponentIds, syncFromJiraToBt >= 2 && !syncFromBtToJira ? undefined : Caml_option.some(Curry._1(ApiWfIntegrations$BsConsole.Jira.Statuses.encodeJson, jiraStatusMappingSelections)));
      if (maybeTask !== undefined) {
        return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
      } else {
        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
      }
    }
    var maybeTask$1 = ApiWfIntegrations$BsConsole.CreateIntegration.createJiraIntegrationTask(connectionId, jiraIssueTypeSelection.id, integrationName, projectName, jiraProjectSelection, jiraContentKeySelection.id, syncFromBtToJira, syncFromJiraToBt, syncIssuesOnAdd ? false : true, subjectLineVal.trim(), true, selectedAttributes, config, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType), customFieldsSelectionWithoutEmptyFields, resolvedUntilOptions, customComponentIds, syncFromJiraToBt >= 2 && !syncFromBtToJira ? undefined : Caml_option.some(Curry._1(ApiWfIntegrations$BsConsole.Jira.Statuses.encodeJson, jiraStatusMappingSelections)));
    if (maybeTask$1 !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  return /* tuple */[
          {
            awaitEditAutoPopulation: awaitEditAutoPopulation,
            integrationId: integrationId,
            integrationName: integrationName,
            connectionId: connectionId,
            connectionCreated: match$1.connectionCreated,
            jiraProjectSelection: jiraProjectSelection,
            jiraProjects: match$1.jiraProjects,
            jiraProjectsFetched: match$1.jiraProjectsFetched,
            jiraIssueTypeSelection: jiraIssueTypeSelection,
            jiraIssueTypes: match$1.jiraIssueTypes,
            jiraContentKeySelection: jiraContentKeySelection,
            jiraContentKeys: match$1.jiraContentKeys,
            errorMessage: match$1.errorMessage,
            syncFromBtToJira: syncFromBtToJira,
            syncFromJiraToBt: syncFromJiraToBt,
            syncIssuesOnAdd: syncIssuesOnAdd,
            advancedOptionsOpen: match$1.advancedOptionsOpen,
            subjectLineVal: subjectLineVal,
            selectedAttributes: selectedAttributes,
            customFieldSelections: customFieldSelections,
            customFieldsAvailable: customFieldsAvailable,
            customFieldValidationErrors: customFieldValidationErrors,
            btVersionAttribute: btVersionAttribute,
            versionFieldSelection: versionFieldSelection,
            versionFieldsAvailable: match$1.versionFieldsAvailable,
            versionRegEx: versionRegEx,
            resolveUntilValidationError: match$1.resolveUntilValidationError,
            jiraComponentSelections: jiraComponentSelections,
            jiraComponentsAvailable: match$1.jiraComponentsAvailable,
            advancedRules: advancedRules,
            useAdvancedRules: useAdvancedRules,
            foundUnsavedRuleChanges: foundUnsavedRuleChanges,
            jiraStatusMappingSelections: jiraStatusMappingSelections,
            jiraStatusesAvailable: jiraStatusesAvailable,
            isStatusMappingValid: match$1.isStatusMappingValid
          },
          dispatch,
          handleSave,
          handleCancel,
          attributes
        ];
}

function JiraConnection(Props) {
  var formState = Props.formState;
  var universe = Props.universe;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var config = Props.config;
  var jiraType = Props.jiraType;
  var match = use(formState, jiraType, universe, token, handleChangeUrl, projectName, config);
  var attributes = match[4];
  var dispatch = match[1];
  var match$1 = match[0];
  var jiraStatusMappingSelections = match$1.jiraStatusMappingSelections;
  var advancedRules = match$1.advancedRules;
  var jiraComponentSelections = match$1.jiraComponentSelections;
  var customFieldSelections = match$1.customFieldSelections;
  var selectedAttributes = match$1.selectedAttributes;
  var syncFromJiraToBt = match$1.syncFromJiraToBt;
  var syncFromBtToJira = match$1.syncFromBtToJira;
  var errorMessage = match$1.errorMessage;
  var jiraContentKeySelection = match$1.jiraContentKeySelection;
  var jiraIssueTypeSelection = match$1.jiraIssueTypeSelection;
  var jiraProjectsFetched = match$1.jiraProjectsFetched;
  var jiraProjects = match$1.jiraProjects;
  var jiraProjectSelection = match$1.jiraProjectSelection;
  var integrationName = match$1.integrationName;
  React.useEffect((function () {
          setTimeout((function (param) {
                  window.scrollTo(0, 0);
                  
                }), 200);
          
        }), [errorMessage]);
  var tmp;
  var exit = 0;
  if (formState.tag && match$1.awaitEditAutoPopulation) {
    tmp = React.createElement("div", {
          className: loadingContainerStyle
        }, React.createElement(CircularProgress.default, {
              size: 100,
              color: "inherit"
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1;
    tmp$1 = formState.tag ? true : false;
    tmp = React.createElement(React.Fragment, undefined, errorMessage.length > 0 ? React.createElement(WarningMessage$BsConsole.make, {
                errorMessage: I18N$BsConsole.show(undefined, errorMessage)
              }) : null, match$1.connectionCreated ? React.createElement(SuccessMessage$BsConsole.make, {
                successMessage: I18N$BsConsole.show(undefined, "Your connection was created successfully.")
              }) : null, React.createElement(Paper.default, {
              className: parentCreate,
              children: null
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        title,
                        /* :: */[
                          bottomMargin,
                          /* [] */0
                        ]
                      ])
                }, React.createElement(Image$BsConsole.make, {
                      className: titleLogo,
                      src: "https://res.cloudinary.com/backtrace/image/upload/v165544361956/workflows_jira.svg",
                      alt: "Jira Logo"
                    }), React.createElement("p", {
                      className: titleText
                    }, jiraType ? I18N$BsConsole.show(undefined, "Jira Server Integration") : I18N$BsConsole.show(undefined, "Jira Cloud Integration"))), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        horizontalContainer,
                        /* :: */[
                          bottomMargin,
                          /* :: */[
                            alignRowWidths,
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            containerRightMargin,
                            /* :: */[
                              verticalContainer,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement("div", {
                          className: bottomMargin
                        }, React.createElement(JiraConnection$Required, {
                              className: integrationNameLabel,
                              children: I18N$BsConsole.show(undefined, "Integration Name")
                            }), React.createElement(TextField.default, {
                              autoFocus: false,
                              helperText: I18N$BsConsole.get(undefined, "To identify the integration throughout the app"),
                              disabled: tmp$1,
                              placeholder: "",
                              value: integrationName,
                              color: "primary",
                              variant: "outlined",
                              classes: {
                                root: input
                              },
                              FormHelperTextProps: {
                                classes: {
                                  contained: formHelperText
                                }
                              },
                              onChange: (function ($$event) {
                                  var value = Belt_Option.getWithDefault($$event.target.value, "");
                                  return Curry._1(dispatch, /* SetIntegrationName */Block.__(0, [value]));
                                })
                            })), React.createElement(JiraConnection$DataSyncRadioGroup, {
                          title: "Data synchronization from Backtrace to Jira",
                          secondaryText: "When updating a Backtrace issue, also modifies the linked Jira task",
                          shouldSync: syncFromBtToJira,
                          handleChange: (function (selectedVal) {
                              return Curry._1(dispatch, /* SetSyncFromBtToJira */Block.__(15, [selectedVal]));
                            })
                        })), React.createElement("div", {
                      className: verticalContainer
                    }, React.createElement("div", {
                          className: bottomMargin
                        }, React.createElement(JiraConnection$Required, {
                              className: integrationNameLabel,
                              children: I18N$BsConsole.show(undefined, "Project Name")
                            }), React.createElement(Select.default, {
                              value: jiraProjectSelection,
                              onChange: (function ($$event, param) {
                                  var value = $$event.target.value;
                                  if (!(value == null)) {
                                    return Curry._1(dispatch, /* SetJiraProjectSelection */Block.__(2, [value]));
                                  }
                                  
                                }),
                              input: React.createElement(OutlinedInput.default, {
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* [] */0
                                          ])
                                    },
                                    labelWidth: 0
                                  }),
                              classes: {
                                select: inputItem
                              },
                              autoWidth: true,
                              children: jiraProjectsFetched && jiraProjects.length !== 0 ? Belt_Array.map(jiraProjects, (function (project) {
                                        return React.createElement(MenuItem.default, {
                                                    value: project.id,
                                                    children: I18N$BsConsole.showSkip(project.name),
                                                    key: project.id
                                                  });
                                      })) : (
                                  jiraProjectsFetched && jiraProjects.length === 0 ? [React.createElement(MenuItem.default, {
                                            children: I18N$BsConsole.show(undefined, "No options available")
                                          })] : [React.createElement(MenuItem.default, {
                                            children: null
                                          }, React.createElement("div", {
                                                className: loadingInputStyle
                                              }, React.createElement(CircularProgress.default, {
                                                    size: 20,
                                                    color: "inherit"
                                                  })), I18N$BsConsole.show(undefined, "Loading..."))]
                                )
                            }), React.createElement(FormHelperText.default, {
                              children: I18N$BsConsole.show(undefined, "Where Backtrace will create issues")
                            })), React.createElement(JiraConnection$JiraToBtSyncRadioGroup, {
                          title: "Data synchronization from Jira to Backtrace",
                          secondaryText: "When updating a Jira task, also modifies the linked Backtrace issue",
                          shouldSync: syncFromJiraToBt,
                          handleChange: (function (selectedVal) {
                              return Curry._1(dispatch, /* SetSyncFromJiraToBt */Block.__(16, [selectedVal]));
                            })
                        }))), React.createElement(JiraConnection$SyncIssues, {
                  syncIssuesOnAdd: match$1.syncIssuesOnAdd,
                  handleSyncIssuesOnAddChange: (function (syncValue) {
                      return Curry._1(dispatch, /* SetSyncIssuesOnAdd */Block.__(17, [syncValue]));
                    }),
                  syncFromBtToJira: syncFromBtToJira,
                  syncFromJiraToBt: syncFromJiraToBt
                }), React.createElement(Wf2AdvancedOptions$BsConsole.make, {
                  isOpen: match$1.advancedOptionsOpen,
                  handleToggle: (function (param) {
                      return Curry._1(dispatch, /* ToggleAdvancedOptionsOpen */0);
                    }),
                  children: null
                }, React.createElement(JiraConnection$JiraTemplate, {
                      className: bottomMargin,
                      attributes: attributes,
                      subjectLineVal: match$1.subjectLineVal,
                      handleSubjectLineChange: (function (newVal) {
                          return Curry._1(dispatch, /* SetSubjectLineVal */Block.__(18, [newVal]));
                        })
                    }), React.createElement("div", {
                      className: Css.merge(/* :: */[
                            horizontalContainer,
                            /* :: */[
                              alignRowWidths,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(JiraConnection$IssueType, {
                          jiraIssueTypes: match$1.jiraIssueTypes,
                          jiraIssueTypeSelection: jiraIssueTypeSelection,
                          handleIssueTypeChange: (function (value) {
                              return Curry._1(dispatch, /* SetJiraIssueTypeSelection */Block.__(5, [value]));
                            })
                        }), React.createElement(JiraConnection$MainBodyTextField, {
                          jiraContentKeys: match$1.jiraContentKeys,
                          jiraContentKeySelection: jiraContentKeySelection,
                          jiraIssueTypeSelection: jiraIssueTypeSelection,
                          handleContentKeyChange: (function (value) {
                              return Curry._1(dispatch, /* SetJiraContentKeySelection */Block.__(7, [value]));
                            })
                        })), React.createElement(Wf2MainBodyConent$BsConsole.make, {
                      attributes: attributes,
                      handleAddSelectedAttribute: (function (attribute) {
                          if (attribute !== undefined) {
                            return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(19, [Belt_Array.concat(selectedAttributes, [attribute.name])]));
                          }
                          
                        }),
                      handleDeleteSelectedAttribute: (function (index) {
                          var length = selectedAttributes.length;
                          if (length <= 1) {
                            return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(19, [[]]));
                          }
                          var firstHalf = Belt_Array.slice(selectedAttributes, 0, index);
                          var secondHalf = Belt_Array.sliceToEnd(selectedAttributes, index + 1 | 0);
                          var removedAttributeArray = Belt_Array.concat(firstHalf, secondHalf);
                          return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(19, [removedAttributeArray]));
                        }),
                      selectedAttributes: selectedAttributes,
                      handleUpdateAttributeOrder: (function (updatedSelectionOrder) {
                          return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(19, [updatedSelectionOrder]));
                        })
                    }), React.createElement(JiraConnection$CustomFields, {
                      attributes: attributes,
                      customFieldsAvailable: match$1.customFieldsAvailable,
                      customFieldSelections: customFieldSelections,
                      customFieldValidationErrors: match$1.customFieldValidationErrors,
                      handleAddNewCustomFieldRow: (function (param) {
                          var addRowToSelection = Belt_List.concat(customFieldSelections, /* :: */[
                                /* tuple */[
                                  "",
                                  ""
                                ],
                                /* [] */0
                              ]);
                          return Curry._1(dispatch, /* SetCustomFieldsSelection */Block.__(8, [addRowToSelection]));
                        }),
                      handleFieldNameChange: (function (fieldName, index) {
                          var customFieldsSelectionArray = Belt_List.toArray(customFieldSelections);
                          var currentFieldValue = Belt_Array.get(customFieldsSelectionArray, index);
                          var currentFieldValue$1 = currentFieldValue !== undefined ? currentFieldValue[1] : "";
                          Belt_Array.set(customFieldsSelectionArray, index, /* tuple */[
                                fieldName,
                                currentFieldValue$1
                              ]);
                          return Curry._1(dispatch, /* SetCustomFieldsSelection */Block.__(8, [Belt_List.fromArray(customFieldsSelectionArray)]));
                        }),
                      handleFieldValueChange: (function (fieldValue, index) {
                          var customFieldsSelectionArray = Belt_List.toArray(customFieldSelections);
                          var currentFieldName = Belt_Array.get(customFieldsSelectionArray, index);
                          var currentFieldName$1 = currentFieldName !== undefined ? currentFieldName[0] : "";
                          Belt_Array.set(customFieldsSelectionArray, index, /* tuple */[
                                currentFieldName$1,
                                fieldValue
                              ]);
                          return Curry._1(dispatch, /* SetCustomFieldsSelection */Block.__(8, [Belt_List.fromArray(customFieldsSelectionArray)]));
                        }),
                      handleFieldDelete: (function (deleteIndex) {
                          var newCustomFieldsList = Belt_List.keepWithIndex(customFieldSelections, (function (param, index) {
                                  return index !== deleteIndex;
                                }));
                          return Curry._1(dispatch, /* SetCustomFieldsSelection */Block.__(8, [newCustomFieldsList]));
                        })
                    }), React.createElement(JiraConnection$ResolvedUntilBehavior, {
                      attributes: attributes,
                      versionFieldsAvailable: match$1.versionFieldsAvailable,
                      versionFieldSelection: match$1.versionFieldSelection,
                      versionRegEx: match$1.versionRegEx,
                      resolveUntilValidationError: match$1.resolveUntilValidationError,
                      btVersionAttribute: match$1.btVersionAttribute,
                      handleBtVersionAttributeChange: (function (version$prime) {
                          return Curry._1(dispatch, /* SetBtVersionAttribute */Block.__(21, [version$prime]));
                        }),
                      handleJiraVersionChange: (function (version$prime) {
                          return Curry._1(dispatch, /* SetVersionFieldSelection */Block.__(22, [version$prime]));
                        }),
                      handleversionRegExChange: (function (versionRegEx) {
                          return Curry._1(dispatch, /* SetVersionRegEx */Block.__(23, [versionRegEx]));
                        }),
                      advancedRules: advancedRules,
                      handleAddAdvancedRule: (function (rule) {
                          var updatedRules = Belt_Array.concat(advancedRules, [rule]);
                          return Curry._1(dispatch, /* SetAdvancedRules */Block.__(27, [updatedRules]));
                        }),
                      handleDeleteAdvancedRule: (function (index) {
                          var updatedRules = Belt_Array.keepWithIndex(advancedRules, (function (param, idx) {
                                  return idx !== index;
                                }));
                          return Curry._1(dispatch, /* SetAdvancedRules */Block.__(27, [updatedRules]));
                        }),
                      useAdvancedRules: match$1.useAdvancedRules,
                      toogleAdvancedRules: (function (param) {
                          return Curry._1(dispatch, /* ToogleAdvancedRules */1);
                        }),
                      handleUnsavedRuleChanges: (function (wasSaved) {
                          return Curry._1(dispatch, /* SetUnsavedRuleChanges */Block.__(28, [wasSaved]));
                        }),
                      foundUnsavedRuleChanges: match$1.foundUnsavedRuleChanges
                    }), React.createElement(JiraConnection$Components, {
                      jiraComponentSelections: jiraComponentSelections,
                      jiraComponentsAvailable: match$1.jiraComponentsAvailable,
                      handleComponentChange: (function (newComponent, index) {
                          var updatedComponents = Belt_Array.mapWithIndex(jiraComponentSelections, (function (idx, component) {
                                  if (index === idx) {
                                    return newComponent;
                                  } else {
                                    return component;
                                  }
                                }));
                          return Curry._1(dispatch, /* SetJiraComponentSelections */Block.__(25, [updatedComponents]));
                        }),
                      handleAddNewComponentRow: (function (param) {
                          var updatedComponents = Belt_Array.concat(jiraComponentSelections, [""]);
                          return Curry._1(dispatch, /* SetJiraComponentSelections */Block.__(25, [updatedComponents]));
                        }),
                      handleDeleteComponentRow: (function (index) {
                          var updatedComponents = Belt_Array.keepWithIndex(jiraComponentSelections, (function (param, idx) {
                                  return index !== idx;
                                }));
                          return Curry._1(dispatch, /* SetJiraComponentSelections */Block.__(25, [updatedComponents]));
                        }),
                      jiraProjectSelection: jiraProjectSelection
                    }), syncFromBtToJira || syncFromJiraToBt !== /* Disabled */2 ? React.createElement(JiraStatusMapping$BsConsole.make, {
                        isStatusMappingValid: match$1.isStatusMappingValid,
                        jiraStatusesAvailable: match$1.jiraStatusesAvailable,
                        jiraStatusMappingSelections: jiraStatusMappingSelections,
                        handleBtStatusMappingChange: (function (btStatus, jiraStatus) {
                            var updatedJiraStatusMapping;
                            switch (btStatus) {
                              case /* Open */0 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingOpen = jiraStatusMappingSelections.open_;
                                    updatedJiraStatusMapping = existingOpen !== undefined ? ({
                                          open_: Belt_Array.concat([jiraStatus], Belt_Array.keep(existingOpen, (function (existingStatus) {
                                                      return existingStatus !== jiraStatus;
                                                    }))),
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: [jiraStatus],
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: [jiraStatus],
                                      inProgress: undefined,
                                      resolved: undefined,
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Resolved */1 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingResolved = jiraStatusMappingSelections.resolved;
                                    updatedJiraStatusMapping = existingResolved !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: Belt_Array.concat([jiraStatus], Belt_Array.keep(existingResolved, (function (existingStatus) {
                                                      return existingStatus !== jiraStatus;
                                                    }))),
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: [jiraStatus],
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: undefined,
                                      resolved: [jiraStatus],
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* InProgress */2 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingInProgress = jiraStatusMappingSelections.inProgress;
                                    updatedJiraStatusMapping = existingInProgress !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: Belt_Array.concat([jiraStatus], Belt_Array.keep(existingInProgress, (function (existingStatus) {
                                                      return existingStatus !== jiraStatus;
                                                    }))),
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: [jiraStatus],
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: [jiraStatus],
                                      resolved: undefined,
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Muted */3 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingMuted = jiraStatusMappingSelections.muted;
                                    updatedJiraStatusMapping = existingMuted !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: Belt_Array.concat([jiraStatus], Belt_Array.keep(existingMuted, (function (existingStatus) {
                                                      return existingStatus !== jiraStatus;
                                                    }))),
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: [jiraStatus],
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: undefined,
                                      resolved: undefined,
                                      muted: [jiraStatus],
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Reopened */4 :
                                  updatedJiraStatusMapping = jiraStatusMappingSelections !== undefined ? ({
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: [jiraStatus]
                                      }) : ({
                                        open_: undefined,
                                        inProgress: undefined,
                                        resolved: undefined,
                                        muted: undefined,
                                        reopened: [jiraStatus]
                                      });
                                  break;
                              
                            }
                            return Curry._1(dispatch, /* SetJiraStatusMappingSelections */Block.__(29, [updatedJiraStatusMapping]));
                          }),
                        handleJiraStatusMappingAdd: (function (btStatus, jiraStatus) {
                            var updatedJiraStatusMapping;
                            switch (btStatus) {
                              case /* Open */0 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingOpen = jiraStatusMappingSelections.open_;
                                    updatedJiraStatusMapping = existingOpen !== undefined ? ({
                                          open_: Belt_Array.concat(existingOpen, [jiraStatus]),
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: [jiraStatus],
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: [jiraStatus],
                                      inProgress: undefined,
                                      resolved: undefined,
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Resolved */1 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingResolved = jiraStatusMappingSelections.resolved;
                                    updatedJiraStatusMapping = existingResolved !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: Belt_Array.concat(existingResolved, [jiraStatus]),
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: [jiraStatus],
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: undefined,
                                      resolved: [jiraStatus],
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* InProgress */2 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingInProgress = jiraStatusMappingSelections.inProgress;
                                    updatedJiraStatusMapping = existingInProgress !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: Belt_Array.concat(existingInProgress, [jiraStatus]),
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: [jiraStatus],
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: [jiraStatus],
                                      resolved: undefined,
                                      muted: undefined,
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Muted */3 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingMuted = jiraStatusMappingSelections.muted;
                                    updatedJiraStatusMapping = existingMuted !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: Belt_Array.concat(existingMuted, [jiraStatus]),
                                          reopened: jiraStatusMappingSelections.reopened
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: [jiraStatus],
                                          reopened: jiraStatusMappingSelections.reopened
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: undefined,
                                      resolved: undefined,
                                      muted: [jiraStatus],
                                      reopened: undefined
                                    };
                                  }
                                  break;
                              case /* Reopened */4 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingReopened = jiraStatusMappingSelections.reopened;
                                    updatedJiraStatusMapping = existingReopened !== undefined ? ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: Belt_Array.concat(existingReopened, [jiraStatus])
                                        }) : ({
                                          open_: jiraStatusMappingSelections.open_,
                                          inProgress: jiraStatusMappingSelections.inProgress,
                                          resolved: jiraStatusMappingSelections.resolved,
                                          muted: jiraStatusMappingSelections.muted,
                                          reopened: [jiraStatus]
                                        });
                                  } else {
                                    updatedJiraStatusMapping = {
                                      open_: undefined,
                                      inProgress: undefined,
                                      resolved: undefined,
                                      muted: undefined,
                                      reopened: [jiraStatus]
                                    };
                                  }
                                  break;
                              
                            }
                            return Curry._1(dispatch, /* SetJiraStatusMappingSelections */Block.__(29, [updatedJiraStatusMapping]));
                          }),
                        handleJiraStatusMappingDelete: (function (btStatus, jiraStatus) {
                            var updatedJiraStatusMapping;
                            switch (btStatus) {
                              case /* Open */0 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingOpen = jiraStatusMappingSelections.open_;
                                    if (existingOpen !== undefined) {
                                      var updatedOpenArr = Belt_Array.keep(existingOpen, (function (existingStatus) {
                                              return existingStatus !== jiraStatus;
                                            }));
                                      updatedJiraStatusMapping = {
                                        open_: updatedOpenArr.length !== 0 ? updatedOpenArr : undefined,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    } else {
                                      updatedJiraStatusMapping = {
                                        open_: undefined,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    }
                                  } else {
                                    updatedJiraStatusMapping = undefined;
                                  }
                                  break;
                              case /* Resolved */1 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingResolved = jiraStatusMappingSelections.resolved;
                                    if (existingResolved !== undefined) {
                                      var updatedResolvedArr = Belt_Array.keep(existingResolved, (function (existingStatus) {
                                              return existingStatus !== jiraStatus;
                                            }));
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: updatedResolvedArr.length !== 0 ? updatedResolvedArr : undefined,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    } else {
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: undefined,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    }
                                  } else {
                                    updatedJiraStatusMapping = undefined;
                                  }
                                  break;
                              case /* InProgress */2 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingInProgress = jiraStatusMappingSelections.inProgress;
                                    if (existingInProgress !== undefined) {
                                      var updatedInProgressArr = Belt_Array.keep(existingInProgress, (function (existingStatus) {
                                              return existingStatus !== jiraStatus;
                                            }));
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: updatedInProgressArr.length !== 0 ? updatedInProgressArr : undefined,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    } else {
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: undefined,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    }
                                  } else {
                                    updatedJiraStatusMapping = undefined;
                                  }
                                  break;
                              case /* Muted */3 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingMuted = jiraStatusMappingSelections.muted;
                                    if (existingMuted !== undefined) {
                                      var updatedMutedArr = Belt_Array.keep(existingMuted, (function (existingStatus) {
                                              return existingStatus !== jiraStatus;
                                            }));
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: updatedMutedArr.length !== 0 ? updatedMutedArr : undefined,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    } else {
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: undefined,
                                        reopened: jiraStatusMappingSelections.reopened
                                      };
                                    }
                                  } else {
                                    updatedJiraStatusMapping = undefined;
                                  }
                                  break;
                              case /* Reopened */4 :
                                  if (jiraStatusMappingSelections !== undefined) {
                                    var existingResolved$1 = jiraStatusMappingSelections.reopened;
                                    if (existingResolved$1 !== undefined) {
                                      var updatedReopenedArr = Belt_Array.keep(existingResolved$1, (function (existingStatus) {
                                              return existingStatus !== jiraStatus;
                                            }));
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: updatedReopenedArr.length !== 0 ? updatedReopenedArr : undefined
                                      };
                                    } else {
                                      updatedJiraStatusMapping = {
                                        open_: jiraStatusMappingSelections.open_,
                                        inProgress: jiraStatusMappingSelections.inProgress,
                                        resolved: jiraStatusMappingSelections.resolved,
                                        muted: jiraStatusMappingSelections.muted,
                                        reopened: undefined
                                      };
                                    }
                                  } else {
                                    updatedJiraStatusMapping = undefined;
                                  }
                                  break;
                              
                            }
                            return Curry._1(dispatch, /* SetJiraStatusMappingSelections */Block.__(29, [updatedJiraStatusMapping]));
                          })
                      }) : null), React.createElement("div", {
                  className: buttonContainer
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: match[3],
                      children: I18N$BsConsole.show(undefined, "Cancel")
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      className: saveButton,
                      disabled: Belt_Option.isNone(jiraProjectSelection) || integrationName.length <= 0 || Belt_Option.isNone(jiraIssueTypeSelection) || Belt_Option.isNone(jiraContentKeySelection),
                      onClick: match[2],
                      children: I18N$BsConsole.show(undefined, "Save")
                    }))));
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.position(/* relative */903134412),
                          /* [] */0
                        ])
                  }, tmp)
            });
}

var component = ReasonReact.statelessComponent("JiraConnection");

function make(formState, universe, token, handleChangeUrl, projectName, config, jiraType, children) {
  return ReasonReactCompat.wrapReactForReasonReact(JiraConnection, {
              formState: formState,
              universe: universe,
              token: token,
              handleChangeUrl: handleChangeUrl,
              projectName: projectName,
              config: config,
              jiraType: jiraType
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = JiraConnection;

exports.Styles = Styles;
exports.Required = Required;
exports.SubjectLineRow = SubjectLineRow;
exports.JiraTemplate = JiraTemplate;
exports.IssueType = IssueType;
exports.MainBodyTextField = MainBodyTextField;
exports.CustomFieldRow = CustomFieldRow;
exports.CustomFields = CustomFields;
exports.ResolvedUnitlVersionRow = ResolvedUnitlVersionRow;
exports.AdvancedRule = AdvancedRule;
exports.AdvancedRuleRow = AdvancedRuleRow;
exports.AddChipButton = AddChipButton;
exports.AttributeAutocomplete = AttributeAutocomplete;
exports.AdvancedRuleInput = AdvancedRuleInput;
exports.ResolveUnitlRegexRow = ResolveUnitlRegexRow;
exports.ResolvedUntilBehavior = ResolvedUntilBehavior;
exports.ComponentRow = ComponentRow;
exports.Components = Components;
exports.DataSyncRadioGroup = DataSyncRadioGroup;
exports.JiraToBtSyncRadioGroup = JiraToBtSyncRadioGroup;
exports.boolToSyncIssuesOnAdd = boolToSyncIssuesOnAdd;
exports.syncIssuesOnAddToBool = syncIssuesOnAddToBool;
exports.SyncIssues = SyncIssues;
exports.initialState = initialState;
exports.reducer = reducer;
exports.use = use;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* alignRowWidths Not a pure module */
