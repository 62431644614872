// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Drilldown$BsConsole = require("../Drilldown.js");

var classifierButton = Css.style(/* :: */[
      Css.position(/* unset */653193961),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.selector(":first-of-type", /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.whiteSpace(/* nowrap */867913355),
                  /* :: */[
                    Css.maxWidth(Css.pct(100)),
                    /* [] */0
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var classifier = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.fontFamily("Inconsolata"),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
              /* :: */[
                Css.fontSize(Css.px(13)),
                /* :: */[
                  Css.marginRight(Css.px(4)),
                  /* :: */[
                    Css.padding2(Css.px(3), Css.px(4)),
                    /* :: */[
                      Css.borderRadius(Css.px(2)),
                      /* :: */[
                        Css.hover(/* :: */[
                              Css.filter(/* :: */[
                                    /* `brightness */[
                                      -445542959,
                                      90
                                    ],
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* :: */[
                            Css.display(/* inlineBlock */-147785676),
                            /* :: */[
                              Css.verticalAlign(/* middle */-866200747),
                              /* :: */[
                                Css.selector(":first-of-type", /* :: */[
                                      Css.maxWidth(Css.pct(100)),
                                      /* :: */[
                                        Css.whiteSpace(/* nowrap */867913355),
                                        /* :: */[
                                          Css.overflow(/* hidden */-862584982),
                                          /* :: */[
                                            Css.textOverflow(/* ellipsis */166888785),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "classifier__item",
        /* [] */0
      ]
    ]);

var Style = {
  classifierButton: classifierButton,
  classifier: classifier
};

function createFilterOptions(classifier) {
  return [
          /* tuple */[
            "classifiers",
            /* Contains */Block.__(2, [/* `String */[
                  -976970511,
                  classifier
                ]])
          ],
          /* tuple */[
            "classifiers",
            /* NotContains */Block.__(3, [/* `String */[
                  -976970511,
                  classifier
                ]])
          ]
        ];
}

function Classifiers(Props) {
  var classifiers = Props.classifiers;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  return Belt_Array.mapWithIndex(classifiers, (function (idx, classifier$1) {
                var tmp = {
                  textValue: classifier$1,
                  filterOptions: createFilterOptions(classifier$1),
                  className: classifierButton,
                  children: React.createElement("div", {
                        key: classifier$1 + ("__" + String(idx)),
                        className: classifier
                      }, I18N$BsConsole.showSkip(classifier$1)),
                  key: classifier$1 + ("__" + String(idx))
                };
                if (handleChangeUrl !== undefined) {
                  tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
                }
                if (handleAddFilters !== undefined) {
                  tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
                }
                return React.createElement(Drilldown$BsConsole.make, tmp);
              }));
}

var make = Classifiers;

exports.Style = Style;
exports.createFilterOptions = createFilterOptions;
exports.make = make;
/* classifierButton Not a pure module */
