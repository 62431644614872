// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Gate$BsConsole = require("../Gate.js");
var MetricsUri$BsConsole = require("./MetricsUri.js");
var MetricsEvent$BsConsole = require("./MetricsEvent.js");
var MetricsMemory$BsConsole = require("./MetricsMemory.js");

function initialize(thingsToRemember, serviceFromConfig) {
  if (!Gate$BsConsole.metricsDisabled(undefined)) {
    MetricsMemory$BsConsole.empty(undefined);
    MetricsMemory$BsConsole.memorizeMany(thingsToRemember);
    MetricsEvent$BsConsole.setInitialized(undefined);
    MetricsUri$BsConsole.setBaseUri(serviceFromConfig);
    return MetricsEvent$BsConsole.dispatchQueue(undefined);
  }
  
}

var Memory;

var $$Buffer;

var $$Event;

var Nav;

var $$Request;

var Uri;

exports.Memory = Memory;
exports.$$Buffer = $$Buffer;
exports.$$Event = $$Event;
exports.Nav = Nav;
exports.$$Request = $$Request;
exports.Uri = Uri;
exports.initialize = initialize;
/* Gate-BsConsole Not a pure module */
