// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var BaseRenderer$BsConsole = require("./BaseRenderer.js");

function createFilterOptions(state) {
  return [
          /* tuple */[
            Issues$BsConsole.State.columnName,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  state
                ]])
          ],
          /* tuple */[
            Issues$BsConsole.State.columnName,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  state
                ]])
          ]
        ];
}

function IssueState(Props) {
  var state = Props.state;
  var value = Issues$BsConsole.State.toStringCapitalized(state);
  return React.createElement(BaseRenderer$BsConsole.make, {
              textValue: value,
              filterOptions: createFilterOptions(Issues$BsConsole.State.toString(state)),
              children: I18N$BsConsole.showSkip(value)
            });
}

var make = IssueState;

exports.createFilterOptions = createFilterOptions;
exports.make = make;
/* react Not a pure module */
