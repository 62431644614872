// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Format$BsConsole = require("../bs-crdb-response/src/Format.js");
var BaseRenderer$BsConsole = require("./BaseRenderer.js");

function createNumberFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* AtLeast */Block.__(8, [/* `String */[
                  -976970511,
                  value
                ]])
          ],
          /* tuple */[
            attribute,
            /* AtMost */Block.__(9, [/* `String */[
                  -976970511,
                  value
                ]])
          ]
        ];
}

function Bytes(Props) {
  var value = Props.value;
  var bytesUnitOpt = Props.bytesUnit;
  var attribute = Props.attribute;
  var bytesUnit = bytesUnitOpt !== undefined ? bytesUnitOpt : "B";
  var bytesWithUnit = Format$BsConsole.getFormattedBytesWithUnit(value, bytesUnit);
  return React.createElement(BaseRenderer$BsConsole.make, {
              alignment: /* Right */-57574468,
              textValue: value,
              filterOptions: createNumberFilterOptions(attribute, value),
              children: I18N$BsConsole.showSkip(bytesWithUnit)
            });
}

var make = Bytes;

exports.createNumberFilterOptions = createNumberFilterOptions;
exports.make = make;
/* react Not a pure module */
