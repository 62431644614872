// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Icon$BsConsole = require("./bindings/Icon.js");
var Colors$BsConsole = require("./Colors.js");
var Belt_MutableQueue = require("bs-platform/lib/js/belt_MutableQueue.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtIconButton$BsConsole = require("./components/BtIconButton.js");

var enqueueSnackEventName = "enqueue-snack";

var queue = Belt_MutableQueue.make(undefined);

var dispatchEvent = (function(eventName) {
  var event = new Event(eventName);
  window.dispatchEvent(event);
});

function enqueue(button, buttons, delayOpt, paperBody, el) {
  var delay = delayOpt !== undefined ? delayOpt : 4000;
  var delay$1 = delay !== -1 ? delay : undefined;
  Belt_MutableQueue.add(queue, {
        el: el,
        paperBody: paperBody,
        button: button,
        buttons: buttons,
        delay: delay$1
      });
  return dispatchEvent(enqueueSnackEventName);
}

function close(param) {
  return dispatchEvent(enqueueSnackEventName);
}

var component = ReasonReact.reducerComponent("SnackPurveyor-BsConsole");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              var processSnackQueue = function (param) {
                return Curry._1(self.send, /* ProcessQueue */0);
              };
              window.addEventListener(enqueueSnackEventName, processSnackQueue);
              Curry._1(self.onUnmount, (function (param) {
                      window.removeEventListener(enqueueSnackEventName, processSnackQueue);
                      
                    }));
              return Curry._1(self.send, /* ProcessQueue */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var getPaperBodyStyle = function (param) {
                var match = self.state.paper;
                if (match) {
                  return Css.style(/* :: */[
                              Css.display(/* none */-922086728),
                              /* [] */0
                            ]);
                } else {
                  return "";
                }
              };
              var renderExpandButton = function (paper, paperBodyState) {
                if (paper !== undefined) {
                  if (paperBodyState) {
                    return ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(true, Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.white)),
                                        /* [] */0
                                      ]), undefined, undefined, undefined, undefined, (function (param) {
                                      return Curry._1(self.send, /* SetPaper */[/* PaperExpanded */0]);
                                    }), "Expand", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ExpandMore.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]));
                  } else {
                    return ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(true, Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.white)),
                                        /* [] */0
                                      ]), undefined, undefined, undefined, undefined, (function (param) {
                                      return Curry._1(self.send, /* SetPaper */[/* PaperCollapsed */1]);
                                    }), "Collapse", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ExpandLess.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]));
                  }
                } else {
                  return null;
                }
              };
              var t = self.state.toast;
              if (t === undefined) {
                return null;
              }
              var delay = t.delay;
              var match = t.button;
              var match$1 = t.buttons;
              var paperBody = t.paperBody;
              return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Snackbar.Jsx2.make, undefined, undefined, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Left */847852583
                            }, self.state.open_, (function (_event, reason) {
                                if (reason === "clickaway") {
                                  return ;
                                } else {
                                  return Curry._1(self.send, /* ProcessQueue */0);
                                }
                              }), (function (param) {
                                return Curry._1(self.send, /* ProcessQueue */0);
                              }), delay !== undefined ? /* `Int */[
                                3654863,
                                delay
                              ] : undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                Css.background(Css.hex(Colors$BsConsole.grey10)),
                                                /* :: */[
                                                  Css.minWidth(Css.px(288)),
                                                  /* :: */[
                                                    Css.maxWidth(Css.px(568)),
                                                    /* :: */[
                                                      Css.borderRadius(Css.px(4)),
                                                      /* :: */[
                                                        Css.padding2(Css.px(6), Css.px(16)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]), [
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.flexWrap(Css.wrap),
                                                          /* [] */0
                                                        ]), [
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.padding2(Css.px(8), Css.px(0)),
                                                                  /* :: */[
                                                                    Css.color(Css.hex(Colors$BsConsole.white)),
                                                                    /* :: */[
                                                                      Css.fontSize(Css.px(14)),
                                                                      /* :: */[
                                                                        Css.margin(Css.auto),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ])
                                                          }, t.el),
                                                      ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                    Css.marginLeft(Css.auto),
                                                                    /* [] */0
                                                                  ]), [match !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            match.onClick,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Primary */-791844958,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [I18N$BsConsole.showSkip(match.label)]
                                                                          ])) : (
                                                                    match$1 !== undefined ? Belt_List.toArray(Belt_List.map(match$1, (function (button) {
                                                                                  return React.createElement("span", {
                                                                                              key: button.label
                                                                                            }, I18N$BsConsole.showSkip(" "), ReasonReact.element(button.label, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      button.onClick,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      /* Primary */-791844958,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      [I18N$BsConsole.showSkip(button.label)]
                                                                                                    ])));
                                                                                }))) : null
                                                                  )]))
                                                    ])),
                                            React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginRight(Css.px(-8)),
                                                        /* :: */[
                                                          Css.marginLeft(Css.auto),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }, renderExpandButton(t.paperBody, self.state.paper), ReasonReact.element(undefined, undefined, BtIconButton$BsConsole.make(true, Css.style(/* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.white)),
                                                              /* [] */0
                                                            ]), undefined, undefined, undefined, undefined, (function (param) {
                                                            return Curry._1(self.send, /* ProcessQueue */0);
                                                          }), "Clear", [ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, undefined, /* small */-9768761, "clear", []))])))
                                          ])), paperBody !== undefined ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, getPaperBodyStyle(undefined), undefined, undefined, undefined, [Caml_option.valFromOption(paperBody)])) : null)]));
            }),
          initialState: (function (param) {
              return {
                      open_: false,
                      toast: undefined,
                      paper: /* PaperExpanded */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            open_: state.open_,
                            toast: state.toast,
                            paper: action[0]
                          }]);
              }
              if (state.open_) {
                return /* Update */Block.__(0, [{
                            open_: false,
                            toast: state.toast,
                            paper: state.paper
                          }]);
              }
              var t = Belt_MutableQueue.pop(queue);
              if (t !== undefined) {
                return /* Update */Block.__(0, [{
                            open_: true,
                            toast: t,
                            paper: /* PaperExpanded */0
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            open_: false,
                            toast: undefined,
                            paper: state.paper
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var container = Css.style(/* :: */[
      Css.padding(/* `percent */[
            -119887163,
            5
          ]),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.justifyContent(/* flexStart */662439529),
          /* :: */[
            Css.alignItems(/* flexStart */662439529),
            /* :: */[
              Css.flexWrap(/* wrap */-822134326),
              /* :: */[
                Css.flexDirection(/* column */-963948842),
                /* :: */[
                  Css.maxWidth(Css.px(568)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  container: container
};

exports.enqueueSnackEventName = enqueueSnackEventName;
exports.queue = queue;
exports.dispatchEvent = dispatchEvent;
exports.enqueue = enqueue;
exports.close = close;
exports.component = component;
exports.make = make;
exports.Style = Style;
/* queue Not a pure module */
