// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var RR$BsConsole = require("../RR.js");
var Colors$BsConsole = require("../Colors.js");

function getMentionsCount(node) {
  console.log("Getting", node);
  if (node.type === "userMention") {
    return 1;
  }
  var nodes = node.nodes;
  if (!(nodes == null)) {
    return Belt_Array.reduce(Belt_Array.map(nodes, getMentionsCount), 0, (function (prim, prim$1) {
                  return prim + prim$1 | 0;
                }));
  } else {
    return 0;
  }
}

var mentionRegex = new RegExp("@(\\S*)$");

function getInput(value) {
  var startText = value.startText;
  if (startText == null) {
    return ;
  }
  var startOffset = value.selection.start.offset;
  var textBefore = startText.text.slice(0, startOffset);
  var result = mentionRegex.exec(textBefore);
  if (result !== null) {
    return Belt_Array.getExn(result, 1);
  }
  
}

function hasValidAncestors(value) {
  return !value.document.getClosest(value.selection.start.key, (function (node) {
                return node.type !== "paragraph";
              }));
}

function insertMention(editor, selectedUser, param) {
  var value = editor.value;
  var inputValue = getInput(value);
  if (inputValue === undefined) {
    return ;
  }
  editor.deleteBackward(inputValue.length + 1 | 0);
  var selectedRange = editor.value.selection;
  return editor.insertText(" ").insertInlineAtRange(selectedRange, {
                data: {
                  user: selectedUser
                },
                nodes: [{
                    object: "text",
                    leaves: [{
                        text: "@" + selectedUser.username
                      }]
                  }],
                type: "userMention"
              }).focus();
}

function mentionsView(activeUser, param) {
  return {
          renderNode: (function (props, _editor, next) {
              if (props.node.type !== "userMention") {
                return next();
              }
              var u = props.node.data.get("user");
              return RR$BsConsole.createDomElement("span", Object.assign({
                              className: "mention",
                              style: {
                                padding: "2px 3px",
                                backgroundColor: u.uid === activeUser.uid ? Colors$BsConsole.octothorpe(Colors$BsConsole.greenLight) : Colors$BsConsole.octothorpe(Colors$BsConsole.blueLight)
                              }
                            }, props.attributes), props.node.text);
            })
        };
}

function mentions(onShowSuggestions, param, onSelectSuggestion, onMatchSuggestion, onNextSuggestion, onPrevSuggestion, param$1) {
  var searching = {
    contents: false
  };
  return {
          onKeyDown: (function (e, _editor, next) {
              var keyCode = e.which;
              if (keyCode < 14) {
                if (keyCode !== 9) {
                  if (keyCode >= 13 && searching.contents) {
                    Curry._1(onSelectSuggestion, undefined);
                    searching.contents = false;
                    e.preventDefault();
                    return e.stopPropagation();
                  } else {
                    return next();
                  }
                } else {
                  if (searching.contents) {
                    Curry._1(onSelectSuggestion, undefined);
                    searching.contents = false;
                    e.preventDefault();
                  }
                  return next();
                }
              }
              if (keyCode >= 41) {
                return next();
              }
              if (keyCode < 32) {
                return next();
              }
              switch (keyCode - 32 | 0) {
                case 0 :
                    if (searching.contents) {
                      Curry._1(onMatchSuggestion, undefined);
                      searching.contents = false;
                    }
                    return next();
                case 6 :
                    if (searching.contents) {
                      Curry._1(onPrevSuggestion, undefined);
                      e.preventDefault();
                    }
                    return next();
                case 1 :
                case 2 :
                case 3 :
                case 4 :
                case 5 :
                case 7 :
                    return next();
                case 8 :
                    if (searching.contents) {
                      Curry._1(onNextSuggestion, undefined);
                      e.preventDefault();
                    }
                    return next();
                
              }
            }),
          renderMark: (function (props, _editor, next) {
              if (props.mark.type === "mentionContext") {
                return RR$BsConsole.createDomElement("span", Object.assign({
                                className: "mention-context"
                              }, props.attributes), props.children);
              } else {
                return next();
              }
            }),
          renderNode: (function (props, _editor, next) {
              if (props.node.type === "userMention") {
                return RR$BsConsole.createDomElement("span", Object.assign({
                                className: "mention",
                                style: {
                                  padding: "2px 3px",
                                  backgroundColor: "lightblue"
                                }
                              }, props.attributes), props.node.text);
              } else {
                return next();
              }
            }),
          onChange: (function (editor, _next) {
              var inputValue = getInput(editor.value);
              var hasValidAncestors$1 = hasValidAncestors(editor.value);
              var decorations = editor.value.decorations.filter((function (value) {
                      return value.mark.type !== "mentionContext";
                    }));
              if (inputValue === undefined) {
                return ;
              }
              if (!hasValidAncestors$1) {
                return ;
              }
              setTimeout((function (param) {
                      return Curry._2(onShowSuggestions, inputValue, (function (selectedUser) {
                                    return insertMention(editor, selectedUser, undefined);
                                  }));
                    }), 0);
              searching.contents = true;
              var selection = editor.value.selection;
              var updatedDecorations = decorations.push({
                    anchor: {
                      key: selection.start.key,
                      offset: (selection.start.offset - inputValue.length | 0) - 1 | 0
                    },
                    focus: {
                      key: selection.start.key,
                      offset: selection.start.offset
                    },
                    mark: {
                      type: "mentionContext"
                    }
                  });
              return editor.setDecorations(updatedDecorations, undefined);
            }),
          schema: {
            marks: {
              mentionContext: { }
            },
            inlines: {
              userMention: {
                isVoid: true
              }
            }
          }
        };
}

exports.getMentionsCount = getMentionsCount;
exports.mentionRegex = mentionRegex;
exports.getInput = getInput;
exports.hasValidAncestors = hasValidAncestors;
exports.insertMention = insertMention;
exports.mentionsView = mentionsView;
exports.mentions = mentions;
/* mentionRegex Not a pure module */
