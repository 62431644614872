// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Column$BsConsole = require("../../utilities/Column.js");
var Styles$BsConsole = require("../../utilities/Styles.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");

var defaultAction = "i";

var regexReplacementPlaceholder = I18N$BsConsole.get(undefined, "s#regex#replacement#");

var regexPlaceholder = I18N$BsConsole.get(undefined, "regular expression");

var badRegexReplacementMessage = I18N$BsConsole.get(undefined, "Replacement should have format ") + I18N$BsConsole.get(undefined, regexReplacementPlaceholder);

function isRegexFormat(value) {
  var split = value.split("#");
  return split.length === 4;
}

function getNewActions(a, index, value) {
  var __x = a.split("");
  return Belt_Array.mapWithIndex(__x, (function (i, a) {
                  if (i === index) {
                    return value;
                  } else {
                    return a;
                  }
                })).join("");
}

function getNewActionsByRemoving(a, index) {
  var __x = Belt_List.fromArray(Belt_Array.mapWithIndex(a, (function (i, a) {
              if (index === i) {
                return "REMOVE";
              } else {
                return a;
              }
            })));
  return Belt_List.toArray(Belt_List.filter(__x, (function (a) {
                      return a !== "REMOVE";
                    }))).join("");
}

var actionOptions_000 = /* tuple */[
  "i",
  I18N$BsConsole.get(undefined, "[i] Ignore current frame, go to next.")
];

var actionOptions_001 = /* :: */[
  /* tuple */[
    "I",
    I18N$BsConsole.get(undefined, "[I] Ignore frame unless it is a faulting frame.")
  ],
  /* :: */[
    /* tuple */[
      "F",
      I18N$BsConsole.get(undefined, "[F] Skip all frames in the current object file until a faulting frame is reached.")
    ],
    /* :: */[
      /* tuple */[
        "o",
        I18N$BsConsole.get(undefined, "[o] Skip all consecutive frames in the current object file.")
      ],
      /* :: */[
        /* tuple */[
          "t",
          I18N$BsConsole.get(undefined, "[t] Terminate the callstack state machine after the current frame.")
        ],
        /* :: */[
          /* tuple */[
            "s",
            I18N$BsConsole.get(undefined, "[s] Reset the signature and pretty-printed callstack.")
          ],
          /* :: */[
            /* tuple */[
              "q",
              I18N$BsConsole.get(undefined, "[q] Push current frame to signature callstack and move to next frame.")
            ],
            /* :: */[
              /* tuple */[
                "r",
                I18N$BsConsole.get(undefined, "[r] Rename the current function. Requires replacement expression.")
              ],
              /* :: */[
                /* tuple */[
                  "a",
                  I18N$BsConsole.get(undefined, "[a] Add attribute values to the signature callstack string that is used to generate a fingerprint. Requires attribute field.")
                ],
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var actionOptions = /* :: */[
  actionOptions_000,
  actionOptions_001
];

var component = RR$BsConsole.reducerComponent("Dedupe_RuleCard-BsConsole");

var cardStyle = Css.style(/* :: */[
      Css.borderRadius(Css.px(0)),
      /* :: */[
        Css.borderColor(/* `hex */[
              5194459,
              Colors$BsConsole.grey5
            ]),
        /* :: */[
          Css.borderWidth(Css.px(1)),
          /* :: */[
            Css.borderStyle(/* solid */12956715),
            /* :: */[
              Css.padding(Css.px(10)),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.marginBottom(Css.px(15)),
                  /* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.blackA00)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function hoverStyle(hovered, isDragging) {
  if (hovered || isDragging) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                /* [] */0
              ]);
  } else {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.blackA00)),
                /* [] */0
              ]);
  }
}

var blockStyle = Css.style(/* :: */[
      Css.backgroundColor(/* `hex */[
            5194459,
            Colors$BsConsole.grey7
          ]),
      /* :: */[
        Css.color(/* `hex */[
              5194459,
              Colors$BsConsole.grey2
            ]),
        /* :: */[
          Css.padding(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var boxedStyle = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, /* `hex */[
            5194459,
            Colors$BsConsole.grey5
          ]),
      /* :: */[
        Css.lineHeight(Css.px(27)),
        /* :: */[
          Css.width(Css.px(83)),
          /* :: */[
            Css.textAlign(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var ruleSpacingStyle = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var ruleSectionStatic = Css.style(/* :: */[
      Css.backgroundColor(/* `hex */[
            5194459,
            Colors$BsConsole.grey7
          ]),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.padding(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var ruleRowStyleStatic = Css.style(/* :: */[
      Css.marginBottom(Css.px(8)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.height(Css.px(40)),
          /* [] */0
        ]
      ]
    ]);

var ruleRowStyle = Css.style(/* :: */[
      Css.marginBottom(Css.px(8)),
      /* :: */[
        Css.backgroundColor(/* `hex */[
              5194459,
              Colors$BsConsole.grey7
            ]),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.lineHeight(Css.px(24)),
        /* :: */[
          Css.borderStyle(/* none */-922086728),
          /* :: */[
            Css.backgroundColor(/* `hex */[
                  5194459,
                  Colors$BsConsole.grey7
                ]),
            /* :: */[
              Css.borderBottom(Css.px(1), /* solid */12956715, /* `hex */[
                    5194459,
                    Colors$BsConsole.grey5
                  ]),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.marginRight(Css.px(5)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var cardMarginStyle = Css.style(/* :: */[
      Css.margin(Css.px(5)),
      /* [] */0
    ]);

var addPredicatePlusButton = Css.style(/* :: */[
      Css.borderRadius(Css.px(5)),
      /* :: */[
        Css.width(Css.px(100)),
        /* :: */[
          Css.height(Css.px(40)),
          /* :: */[
            Css.margin(Css.px(5)),
            /* :: */[
              Css.marginLeft(Css.px(20)),
              /* :: */[
                Css.border(Css.px(0), Css.solid, Css.transparent),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey2)),
                  /* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Css.fontSize(Css.px(14)),
                        /* :: */[
                          Css.fontWeight(/* medium */-20425611),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function getValuesFromPredicates(predicates) {
  var match = Belt_Array.reduce(predicates, /* tuple */[
        undefined,
        undefined,
        undefined
      ], (function (acc, param) {
          var sourceValue = acc[2];
          var objectValue = acc[1];
          var functionValue = acc[0];
          var value = param[1];
          var nextValue = value === "" ? undefined : value;
          switch (param[0]) {
            case /* FunctionPredicate */0 :
                return /* tuple */[
                        nextValue,
                        objectValue,
                        sourceValue
                      ];
            case /* ObjectPredicate */1 :
                return /* tuple */[
                        functionValue,
                        nextValue,
                        sourceValue
                      ];
            case /* SourcePredicate */2 :
                return /* tuple */[
                        functionValue,
                        objectValue,
                        nextValue
                      ];
            
          }
        }));
  return /* tuple */[
          Belt_Option.getWithDefault(match[0], ""),
          Belt_Option.getWithDefault(match[1], ""),
          Belt_Option.getWithDefault(match[2], "")
        ];
}

function predicateToString(predicate) {
  switch (predicate) {
    case /* FunctionPredicate */0 :
        return I18N$BsConsole.get(undefined, "function");
    case /* ObjectPredicate */1 :
        return I18N$BsConsole.get(undefined, "object");
    case /* SourcePredicate */2 :
        return I18N$BsConsole.get(undefined, "source");
    
  }
}

function make(itemKey, handleRuleUpdate, functionInit, objectInit, sourceInit, actionInitOpt, replacementInitOpt, attributeInitOpt, deleteCardTextOpt, deleteAction, dragHandleProps, $staropt$star, $staropt$star$1, $staropt$star$2, _children) {
  var actionInit = actionInitOpt !== undefined ? actionInitOpt : "i";
  var replacementInit = replacementInitOpt !== undefined ? replacementInitOpt : "";
  var attributeInit = attributeInitOpt !== undefined ? attributeInitOpt : "";
  var deleteCardText = deleteCardTextOpt !== undefined ? deleteCardTextOpt : I18N$BsConsole.get(undefined, "Delete");
  if ($staropt$star === undefined) {
    false;
  }
  if ($staropt$star$1 === undefined) {
    false;
  }
  var $$static = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var predicates = match.predicates;
              var replacementMessage = match.replacementMessage;
              var attributeValue = match.attributeValue;
              var replacementValue = match.replacementValue;
              var actionValue = match.actionValue;
              var attribute = match.attribute;
              var replacement = match.replacement;
              var deleteIcon = deleteAction !== undefined ? React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.px(5)),
                            /* [] */0
                          ])
                    }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.dynamic(deleteCardText)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                    className: "smaller-icon " + Css.style(/* :: */[
                                          Css.cursor(/* pointer */-786317123),
                                          /* :: */[
                                            Css.marginLeft(Css.px(2)),
                                            /* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    onClick: (function (param) {
                                        return Curry._1(deleteAction, itemKey);
                                      })
                                  }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))) : null;
              if ($$static) {
                var __x = actionValue.split("");
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.alignItems(/* center */98248149),
                                    /* [] */0
                                  ]
                                ]),
                            onMouseLeave: (function (param) {
                                return Curry._1(send, /* MouseLeave */1);
                              })
                          }, React.createElement("div", {
                                className: cardStyle
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.display(/* flex */-1010954439),
                                          /* :: */[
                                            Css.flexDirection(/* column */-963948842),
                                            /* :: */[
                                              Css.justifyContent(/* spaceBetween */516682146),
                                              /* :: */[
                                                Css.height(Css.pct(100)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]),
                                    onMouseEnter: (function (param) {
                                        return Curry._1(send, /* MouseEnter */0);
                                      })
                                  }, React.createElement("div", {
                                        className: cardMarginStyle
                                      }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                      className: Css.style(/* :: */[
                                                            Css.width(Css.pct(100)),
                                                            /* [] */0
                                                          ])
                                                    }, predicates.length !== 0 ? React.createElement("div", {
                                                            className: ruleSectionStatic
                                                          }, Belt_Array.mapWithIndex(predicates, (function (idx, param) {
                                                                  return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, ruleRowStyleStatic, [
                                                                                  React.createElement("div", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              ruleSpacingStyle,
                                                                                              /* :: */[
                                                                                                blockStyle,
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ])
                                                                                      }, idx === 0 ? I18N$BsConsole.show(undefined, "IF") : I18N$BsConsole.show(undefined, "AND")),
                                                                                  React.createElement("div", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              ruleSpacingStyle,
                                                                                              /* :: */[
                                                                                                boxedStyle,
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ])
                                                                                      }, I18N$BsConsole.showSkip(predicateToString(param[0]))),
                                                                                  React.createElement("div", {
                                                                                        className: ruleSpacingStyle
                                                                                      }, I18N$BsConsole.show(undefined, "matches")),
                                                                                  React.createElement("div", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              inputStyle,
                                                                                              /* :: */[
                                                                                                Css.style(/* :: */[
                                                                                                      Css.flexGrow(2.0),
                                                                                                      /* [] */0
                                                                                                    ]),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ])
                                                                                      }, React.createElement("div", {
                                                                                            className: formStyle
                                                                                          }, I18N$BsConsole.showSkip(param[1])))
                                                                                ]));
                                                                }))) : null, React.createElement("div", {
                                                          className: Css.merge(/* :: */[
                                                                ruleSectionStatic,
                                                                /* [] */0
                                                              ])
                                                        }, Belt_Array.map(__x, (function (a) {
                                                                var match = getValuesFromPredicates(predicates);
                                                                var __x = Belt_List.filter(actionOptions, (function (param) {
                                                                        return a === param[0];
                                                                      }));
                                                                var str = Belt_List.get(Belt_List.map(__x, (function (param) {
                                                                            return param[1];
                                                                          })), 0);
                                                                return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.merge(/* :: */[
                                                                                    ruleRowStyleStatic,
                                                                                    /* [] */0
                                                                                  ]), [
                                                                                match[0].length !== 0 || match[1].length !== 0 ? React.createElement("div", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              ruleSpacingStyle,
                                                                                              /* :: */[
                                                                                                blockStyle,
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ])
                                                                                      }, I18N$BsConsole.show(undefined, "THEN")) : React.createElement("div", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              ruleSpacingStyle,
                                                                                              /* :: */[
                                                                                                blockStyle,
                                                                                                /* :: */[
                                                                                                  Css.style(/* :: */[
                                                                                                        Css.width(Css.px(120)),
                                                                                                        /* [] */0
                                                                                                      ]),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ]
                                                                                            ])
                                                                                      }, I18N$BsConsole.show(undefined, "APPLIES TO *")),
                                                                                React.createElement("div", undefined, str !== undefined ? I18N$BsConsole.dynamic(str) : I18N$BsConsole.show(undefined, "Unknown action"))
                                                                              ]));
                                                              })), replacement ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, ruleRowStyleStatic, [
                                                                    React.createElement("div", {
                                                                          className: Css.merge(/* :: */[
                                                                                ruleSpacingStyle,
                                                                                /* :: */[
                                                                                  blockStyle,
                                                                                  /* :: */[
                                                                                    Css.style(/* :: */[
                                                                                          Css.width(Css.px(125)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ])
                                                                        }, I18N$BsConsole.show(undefined, "REPLACE WITH")),
                                                                    React.createElement("div", {
                                                                          className: Css.merge(/* :: */[
                                                                                inputStyle,
                                                                                /* :: */[
                                                                                  Css.style(/* :: */[
                                                                                        Css.flexGrow(2.0),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, React.createElement("div", {
                                                                              className: formStyle
                                                                            }, I18N$BsConsole.showSkip(replacementValue)))
                                                                  ])) : null, attribute ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.merge(/* :: */[
                                                                        ruleRowStyleStatic,
                                                                        /* :: */[
                                                                          Css.style(/* :: */[
                                                                                Css.marginTop(Css.px(10)),
                                                                                /* [] */0
                                                                              ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]), [
                                                                    React.createElement("div", {
                                                                          className: Css.merge(/* :: */[
                                                                                ruleSpacingStyle,
                                                                                /* :: */[
                                                                                  blockStyle,
                                                                                  /* :: */[
                                                                                    Css.style(/* :: */[
                                                                                          Css.width(Css.px(125)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ])
                                                                        }, I18N$BsConsole.show(undefined, "INCLUDE ATTRIBUTE")),
                                                                    React.createElement("div", {
                                                                          className: Css.merge(/* :: */[
                                                                                inputStyle,
                                                                                /* :: */[
                                                                                  Css.style(/* :: */[
                                                                                        Css.flexGrow(2.0),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, React.createElement("div", {
                                                                              className: formStyle
                                                                            }, I18N$BsConsole.showSkip(attributeValue)))
                                                                  ])) : null))]))))));
              }
              var __x$1 = actionValue.split("");
              return React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.display(/* flex */-1010954439),
                                /* [] */0
                              ]),
                          onMouseLeave: (function (param) {
                              return Curry._1(send, /* MouseLeave */1);
                            })
                        }, React.cloneElement(React.createElement("div", {
                                  className: cardStyle
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.flexDirection(/* column */-963948842),
                                              /* :: */[
                                                Css.justifyContent(/* spaceBetween */516682146),
                                                /* :: */[
                                                  Css.height(Css.pct(100)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]),
                                      onMouseEnter: (function (param) {
                                          return Curry._1(send, /* MouseEnter */0);
                                        })
                                    }, React.createElement("div", {
                                          className: cardMarginStyle
                                        }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                Css.flexGrow(5),
                                                                /* [] */0
                                                              ]), [
                                                            React.createElement("div", {
                                                                  className: ruleSectionStatic
                                                                }, Belt_Array.mapWithIndex(predicates, (function (idx, param) {
                                                                        var value = param[1];
                                                                        var pred = param[0];
                                                                        var tmp;
                                                                        if (idx === 0) {
                                                                          var tmp$1;
                                                                          switch (pred) {
                                                                            case /* FunctionPredicate */0 :
                                                                                tmp$1 = "function";
                                                                                break;
                                                                            case /* ObjectPredicate */1 :
                                                                                tmp$1 = "object";
                                                                                break;
                                                                            case /* SourcePredicate */2 :
                                                                                tmp$1 = "source";
                                                                                break;
                                                                            
                                                                          }
                                                                          tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          ruleSpacingStyle,
                                                                                          /* :: */[
                                                                                            blockStyle,
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.show(undefined, "IF")), React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          ruleSpacingStyle,
                                                                                          /* :: */[
                                                                                            boxedStyle,
                                                                                            /* :: */[
                                                                                              Css.style(/* :: */[
                                                                                                    Css.width(Css.px(110)),
                                                                                                    /* [] */0
                                                                                                  ]),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ]
                                                                                        ])
                                                                                  }, React.createElement("select", {
                                                                                        className: Css.merge(/* :: */[
                                                                                              formStyle,
                                                                                              /* :: */[
                                                                                                Css.style(/* :: */[
                                                                                                      Css.width(Css.px(100)),
                                                                                                      /* :: */[
                                                                                                        Css.borderBottom(Css.px(0), Css.solid, Css.transparent),
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ]),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]),
                                                                                        value: tmp$1,
                                                                                        onChange: (function ($$event) {
                                                                                            var predValue = $$event.target.value;
                                                                                            var nextPredicate;
                                                                                            switch (predValue) {
                                                                                              case "function" :
                                                                                                  nextPredicate = /* FunctionPredicate */0;
                                                                                                  break;
                                                                                              case "object" :
                                                                                                  nextPredicate = /* ObjectPredicate */1;
                                                                                                  break;
                                                                                              case "source" :
                                                                                                  nextPredicate = /* SourcePredicate */2;
                                                                                                  break;
                                                                                              default:
                                                                                                nextPredicate = undefined;
                                                                                            }
                                                                                            if (nextPredicate !== undefined) {
                                                                                              return Curry._1(send, /* UpdatePredicateByIndex */Block.__(5, [
                                                                                                            nextPredicate,
                                                                                                            value,
                                                                                                            idx
                                                                                                          ]));
                                                                                            }
                                                                                            
                                                                                          })
                                                                                      }, React.createElement("option", {
                                                                                            key: "function",
                                                                                            disabled: Belt_Option.isSome(functionInit),
                                                                                            value: "function"
                                                                                          }, I18N$BsConsole.show(undefined, "function")), React.createElement("option", {
                                                                                            key: "object",
                                                                                            disabled: Belt_Option.isSome(objectInit),
                                                                                            value: "object"
                                                                                          }, I18N$BsConsole.show(undefined, "object")), React.createElement("option", {
                                                                                            key: "source",
                                                                                            disabled: Belt_Option.isSome(sourceInit),
                                                                                            value: "source"
                                                                                          }, I18N$BsConsole.show(undefined, "source")))));
                                                                        } else {
                                                                          tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          ruleSpacingStyle,
                                                                                          /* :: */[
                                                                                            blockStyle,
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.show(undefined, "AND")), React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          ruleSpacingStyle,
                                                                                          /* :: */[
                                                                                            boxedStyle,
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ])
                                                                                  }, I18N$BsConsole.showSkip(predicateToString(pred))));
                                                                        }
                                                                        return ReasonReact.element(String(idx) + ("-" + predicateToString(pred)), undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, ruleRowStyleStatic, [
                                                                                        tmp,
                                                                                        React.createElement("div", {
                                                                                              className: ruleSpacingStyle
                                                                                            }, I18N$BsConsole.show(undefined, "matches")),
                                                                                        React.createElement("div", {
                                                                                              className: Css.merge(/* :: */[
                                                                                                    inputStyle,
                                                                                                    /* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.flexGrow(2.0),
                                                                                                            /* [] */0
                                                                                                          ]),
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ])
                                                                                            }, React.createElement("input", {
                                                                                                  className: formStyle,
                                                                                                  label: "add-rule-if",
                                                                                                  placeholder: regexPlaceholder,
                                                                                                  value: value,
                                                                                                  onChange: (function ($$event) {
                                                                                                      var value$prime = $$event.target.value;
                                                                                                      return Curry._1(send, /* UpdatePredicateByIndex */Block.__(5, [
                                                                                                                    pred,
                                                                                                                    value$prime,
                                                                                                                    idx
                                                                                                                  ]));
                                                                                                    })
                                                                                                })),
                                                                                        idx > 0 ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                        className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                      }, I18N$BsConsole.show(undefined, "Remove predicate")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                          className: Css.style(/* :: */[
                                                                                                                Css.marginRight(Css.px(8)),
                                                                                                                /* :: */[
                                                                                                                  Css.marginLeft(Css.px(8)),
                                                                                                                  /* :: */[
                                                                                                                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                                                    /* [] */0
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]) + " smaller-icon",
                                                                                                          onClick: (function (param) {
                                                                                                              return Curry._1(send, /* RemovePredicate */Block.__(7, [pred]));
                                                                                                            })
                                                                                                        }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])) : null
                                                                                      ]));
                                                                      }))),
                                                            React.createElement("div", {
                                                                  className: Css.merge(/* :: */[
                                                                        ruleRowStyle,
                                                                        /* :: */[
                                                                          Css.style(/* :: */[
                                                                                Css.display(/* flex */-1010954439),
                                                                                /* :: */[
                                                                                  Css.flexDirection(/* column */-963948842),
                                                                                  /* :: */[
                                                                                    Css.marginTop(Css.px(10)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]),
                                                                          /* :: */[
                                                                            ruleSectionStatic,
                                                                            /* [] */0
                                                                          ]
                                                                        ]
                                                                      ])
                                                                }, Belt_Array.mapWithIndex(__x$1, (function (index, action) {
                                                                        return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.merge(/* :: */[
                                                                                            ruleRowStyle,
                                                                                            /* :: */[
                                                                                              Css.style(/* :: */[
                                                                                                    Css.padding(Css.px(0)),
                                                                                                    /* [] */0
                                                                                                  ]),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ]), [
                                                                                        React.createElement("div", {
                                                                                              className: Css.merge(/* :: */[
                                                                                                    ruleSpacingStyle,
                                                                                                    /* :: */[
                                                                                                      blockStyle,
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ])
                                                                                            }, I18N$BsConsole.show(undefined, "THEN")),
                                                                                        React.createElement("select", {
                                                                                              className: Css.merge(/* :: */[
                                                                                                    formStyle,
                                                                                                    /* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.marginRight(Css.px(0)),
                                                                                                            /* :: */[
                                                                                                              Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey5)),
                                                                                                              /* :: */[
                                                                                                                Css.height(Css.px(35)),
                                                                                                                /* [] */0
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]),
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ]),
                                                                                              onChange: (function ($$event) {
                                                                                                  var value = $$event.target.value;
                                                                                                  Curry._1(send, /* SetAction */Block.__(0, [
                                                                                                          index,
                                                                                                          value
                                                                                                        ]));
                                                                                                  var newActions = getNewActions(actionValue, index, value);
                                                                                                  var match = getValuesFromPredicates(predicates);
                                                                                                  return Curry._7(handleRuleUpdate, itemKey, match[0], match[1], newActions, replacementValue, attributeValue, match[2]);
                                                                                                })
                                                                                            }, Belt_List.toArray(Belt_List.map(actionOptions, (function (param) {
                                                                                                        var a = param[0];
                                                                                                        return React.createElement("option", {
                                                                                                                    key: a,
                                                                                                                    selected: a === action,
                                                                                                                    value: a
                                                                                                                  }, I18N$BsConsole.dynamic(param[1]));
                                                                                                      })))),
                                                                                        index === 0 ? null : ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                        className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                      }, I18N$BsConsole.show(undefined, "Remove action")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                          className: Css.style(/* :: */[
                                                                                                                Css.marginRight(Css.px(8)),
                                                                                                                /* :: */[
                                                                                                                  Css.marginLeft(Css.px(8)),
                                                                                                                  /* :: */[
                                                                                                                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                                                                                    /* [] */0
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]) + " smaller-icon",
                                                                                                          onClick: (function (param) {
                                                                                                              Curry._1(send, /* RemoveAction */Block.__(3, [index]));
                                                                                                              var newActions = getNewActionsByRemoving(actionValue.split(""), index);
                                                                                                              var match = getValuesFromPredicates(predicates);
                                                                                                              return Curry._7(handleRuleUpdate, itemKey, match[0], match[1], newActions, replacementValue, attributeValue, match[2]);
                                                                                                            })
                                                                                                        }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))
                                                                                      ]));
                                                                      })), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [replacement ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                                                      className: Css.merge(/* :: */[
                                                                                            ruleSpacingStyle,
                                                                                            /* :: */[
                                                                                              blockStyle,
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ])
                                                                                    }, I18N$BsConsole.show(undefined, "REPLACE WITH")), React.createElement("div", {
                                                                                      className: Css.merge(/* :: */[
                                                                                            inputStyle,
                                                                                            /* :: */[
                                                                                              Css.style(/* :: */[
                                                                                                    Css.flexGrow(2.0),
                                                                                                    /* [] */0
                                                                                                  ]),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ])
                                                                                    }, React.createElement("input", {
                                                                                          className: formStyle,
                                                                                          id: "add-rule-replacement",
                                                                                          label: "replacement",
                                                                                          placeholder: regexReplacementPlaceholder,
                                                                                          value: replacementValue,
                                                                                          onChange: (function ($$event) {
                                                                                              var value = $$event.target.value;
                                                                                              Curry._1(send, /* SetReplacementValue */Block.__(1, [value]));
                                                                                              var match = getValuesFromPredicates(predicates);
                                                                                              Curry._7(handleRuleUpdate, itemKey, match[0], match[1], actionValue, value, attributeValue, match[2]);
                                                                                              return Curry._1(send, /* CheckReplacementValue */Block.__(4, [value]));
                                                                                            })
                                                                                        }), replacementMessage !== undefined ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                      className: Css.style(/* :: */[
                                                                                                            Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                                                                            /* :: */[
                                                                                                              Css.fontSize(Css.px(12)),
                                                                                                              /* :: */[
                                                                                                                Css.fontFamily("Roboto"),
                                                                                                                /* [] */0
                                                                                                              ]
                                                                                                            ]
                                                                                                          ])
                                                                                                    }, I18N$BsConsole.showSkip(replacementMessage))])) : null)) : null])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [attribute ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                                                      className: Css.merge(/* :: */[
                                                                                            ruleSpacingStyle,
                                                                                            /* :: */[
                                                                                              blockStyle,
                                                                                              /* :: */[
                                                                                                replacement ? Css.style(/* :: */[
                                                                                                        Css.marginTop(Css.px(8)),
                                                                                                        /* [] */0
                                                                                                      ]) : "",
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ])
                                                                                    }, I18N$BsConsole.show(undefined, "INCLUDE ATTRIBUTE")), React.createElement("div", {
                                                                                      className: Css.merge(/* :: */[
                                                                                            inputStyle,
                                                                                            /* :: */[
                                                                                              Css.style(/* :: */[
                                                                                                    Css.flexGrow(2.0),
                                                                                                    /* [] */0
                                                                                                  ]),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ])
                                                                                    }, React.createElement("input", {
                                                                                          className: formStyle,
                                                                                          id: "add-rule-attribute",
                                                                                          label: "attribute",
                                                                                          placeholder: "",
                                                                                          value: attributeValue,
                                                                                          onChange: (function ($$event) {
                                                                                              var value = $$event.target.value;
                                                                                              Curry._1(send, /* SetAttributeValue */Block.__(2, [value]));
                                                                                              var match = getValuesFromPredicates(predicates);
                                                                                              return Curry._7(handleRuleUpdate, itemKey, match[0], match[1], actionValue, replacementValue, value, match[2]);
                                                                                            })
                                                                                        }))) : null])))
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                            Belt_Array.map(Belt_Array.keep([
                                                                      /* FunctionPredicate */0,
                                                                      /* ObjectPredicate */1,
                                                                      /* SourcePredicate */2
                                                                    ], (function (pred) {
                                                                        return Belt_Option.isNone(Belt_Array.getBy(predicates, (function (param) {
                                                                                          return pred === param[0];
                                                                                        })));
                                                                      })), (function (pred) {
                                                                    var predicateStr = predicateToString(pred);
                                                                    return React.createElement("button", {
                                                                                key: predicateStr,
                                                                                className: addPredicatePlusButton,
                                                                                title: I18N$BsConsole.get(undefined, "Add a second predicate"),
                                                                                onClick: (function (param) {
                                                                                    return Curry._1(send, /* AddPredicate */Block.__(6, [pred]));
                                                                                  })
                                                                              }, React.createElement("div", {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.marginRight(Css.px(3)),
                                                                                          /* :: */[
                                                                                            Css.height(Css.px(10)),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]) + " smaller-icon"
                                                                                  }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), React.createElement("div", undefined, I18N$BsConsole.showSkip(predicateStr)));
                                                                  })),
                                                            React.createElement("button", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.borderRadius(Css.px(5)),
                                                                        /* :: */[
                                                                          Css.width(Css.px(100)),
                                                                          /* :: */[
                                                                            Css.height(Css.px(40)),
                                                                            /* :: */[
                                                                              Css.margin(Css.px(5)),
                                                                              /* :: */[
                                                                                Css.marginLeft(Css.px(20)),
                                                                                /* :: */[
                                                                                  Css.border(Css.px(0), Css.solid, Css.transparent),
                                                                                  /* :: */[
                                                                                    Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                                    /* :: */[
                                                                                      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                                                                                      /* :: */[
                                                                                        Css.cursor(/* pointer */-786317123),
                                                                                        /* :: */[
                                                                                          Css.fontSize(Css.px(14)),
                                                                                          /* :: */[
                                                                                            Css.fontWeight(/* medium */-20425611),
                                                                                            /* :: */[
                                                                                              Css.display(/* flex */-1010954439),
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]),
                                                                  title: I18N$BsConsole.get(undefined, "Add another action"),
                                                                  onClick: (function ($$event) {
                                                                      $$event.preventDefault();
                                                                      Curry._1(send, /* AddAnotherAction */2);
                                                                      var match = getValuesFromPredicates(predicates);
                                                                      return Curry._7(handleRuleUpdate, itemKey, match[0], match[1], actionValue + defaultAction, replacementValue, attributeValue, match[2]);
                                                                    })
                                                                }, React.createElement("div", {
                                                                      className: Css.style(/* :: */[
                                                                            Css.marginRight(Css.px(3)),
                                                                            /* :: */[
                                                                              Css.height(Css.px(10)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]) + " smaller-icon"
                                                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Action")))
                                                          ]))
                                                ]))))), dragHandleProps), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [deleteIcon])));
            }),
          initialState: (function (param) {
              var match = functionInit !== undefined ? (
                  objectInit !== undefined || sourceInit === undefined ? /* tuple */[
                      /* FunctionPredicate */0,
                      /* ObjectPredicate */1,
                      /* SourcePredicate */2
                    ] : /* tuple */[
                      /* FunctionPredicate */0,
                      /* SourcePredicate */2,
                      /* ObjectPredicate */1
                    ]
                ) : (
                  objectInit !== undefined ? (
                      sourceInit !== undefined ? /* tuple */[
                          /* ObjectPredicate */1,
                          /* SourcePredicate */2,
                          /* FunctionPredicate */0
                        ] : /* tuple */[
                          /* ObjectPredicate */1,
                          /* FunctionPredicate */0,
                          /* SourcePredicate */2
                        ]
                    ) : (
                      sourceInit !== undefined ? /* tuple */[
                          /* SourcePredicate */2,
                          /* FunctionPredicate */0,
                          /* ObjectPredicate */1
                        ] : /* tuple */[
                          /* FunctionPredicate */0,
                          /* ObjectPredicate */1,
                          /* SourcePredicate */2
                        ]
                    )
                );
              var __x_001 = /* :: */[
                functionInit,
                /* :: */[
                  sourceInit,
                  /* [] */0
                ]
              ];
              var __x = /* :: */[
                objectInit,
                __x_001
              ];
              var numToShow = Belt_List.reduce(__x, 0, (function (acc, curr) {
                      if (Belt_Option.isSome(curr)) {
                        return acc + 1 | 0;
                      } else {
                        return acc;
                      }
                    }));
              var predicates = Belt_Array.keepMap([
                    /* tuple */[
                      /* FunctionPredicate */0,
                      functionInit
                    ],
                    /* tuple */[
                      /* ObjectPredicate */1,
                      objectInit
                    ],
                    /* tuple */[
                      /* SourcePredicate */2,
                      sourceInit
                    ]
                  ], (function (param) {
                      var value = param[1];
                      if (value !== undefined) {
                        return /* tuple */[
                                param[0],
                                value
                              ];
                      }
                      
                    }));
              return {
                      hovered: false,
                      replacement: actionInit.indexOf("r") > -1,
                      attribute: attributeInit !== "",
                      actionValue: actionInit,
                      replacementValue: replacementInit,
                      attributeValue: attributeInit,
                      firstValueType: match[0],
                      secondValueType: match[1],
                      thirdValueType: match[2],
                      showSecondValue: numToShow > 1,
                      showThirdValue: numToShow > 2,
                      replacementMessage: undefined,
                      predicates: predicates
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* MouseEnter */0 :
                      return /* Update */Block.__(0, [{
                                  hovered: true,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* MouseLeave */1 :
                      return /* Update */Block.__(0, [{
                                  hovered: false,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* AddAnotherAction */2 :
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue + defaultAction,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* SetAction */0 :
                      var newActions = getNewActions(state.actionValue, action[0], action[1]);
                      var hasReplacement = newActions.indexOf("r") > -1;
                      var hasAttribute = newActions.indexOf("a") > -1;
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: hasReplacement,
                                  attribute: hasAttribute,
                                  actionValue: newActions,
                                  replacementValue: hasReplacement ? state.replacementValue : "",
                                  attributeValue: hasAttribute ? state.attributeValue : "",
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* SetReplacementValue */1 :
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: action[0],
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* SetAttributeValue */2 :
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: action[0],
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* RemoveAction */3 :
                      var index = action[0];
                      var actionArr = state.actionValue.split("");
                      var actionToRemove = Belt_Array.get(actionArr, index);
                      var actionToRemove$1 = actionToRemove !== undefined ? actionToRemove : "";
                      var newActions$1 = getNewActionsByRemoving(actionArr, index);
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: actionToRemove$1 === "r" ? false : state.replacement,
                                  attribute: actionToRemove$1 === "a" ? false : state.attribute,
                                  actionValue: newActions$1,
                                  replacementValue: actionToRemove$1 === "r" ? "" : state.replacementValue,
                                  attributeValue: actionToRemove$1 === "a" ? "" : state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* CheckReplacementValue */4 :
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: isRegexFormat(action[0]) ? undefined : badRegexReplacementMessage,
                                  predicates: state.predicates
                                }]);
                  case /* UpdatePredicateByIndex */5 :
                      var idx = action[2];
                      var value = action[1];
                      var pred = action[0];
                      var first = idx === 0 ? /* tuple */[
                          pred,
                          value
                        ] : Belt_Array.get(state.predicates, 0);
                      var second = idx === 1 ? /* tuple */[
                          pred,
                          value
                        ] : Belt_Array.get(state.predicates, 1);
                      var third = idx === 2 ? /* tuple */[
                          pred,
                          value
                        ] : Belt_Array.get(state.predicates, 2);
                      var predicates = Belt_Array.keepMap([
                            first,
                            second,
                            third
                          ], (function (x) {
                              return x;
                            }));
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: predicates
                                },
                                (function (self) {
                                    var match = getValuesFromPredicates(predicates);
                                    return Curry._1(self.send, /* UpdatePredicatesForm */Block.__(8, [
                                                  match[0],
                                                  match[1],
                                                  match[2]
                                                ]));
                                  })
                              ]);
                  case /* AddPredicate */6 :
                      var predicates$1 = Belt_Array.concat(state.predicates, [/* tuple */[
                              action[0],
                              ""
                            ]]);
                      return /* Update */Block.__(0, [{
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: predicates$1
                                }]);
                  case /* RemovePredicate */7 :
                      var predicate = action[0];
                      var predicates$2 = Belt_Array.keep(state.predicates, (function (param) {
                              return param[0] !== predicate;
                            }));
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  hovered: state.hovered,
                                  replacement: state.replacement,
                                  attribute: state.attribute,
                                  actionValue: state.actionValue,
                                  replacementValue: state.replacementValue,
                                  attributeValue: state.attributeValue,
                                  firstValueType: state.firstValueType,
                                  secondValueType: state.secondValueType,
                                  thirdValueType: state.thirdValueType,
                                  showSecondValue: state.showSecondValue,
                                  showThirdValue: state.showThirdValue,
                                  replacementMessage: state.replacementMessage,
                                  predicates: predicates$2
                                },
                                (function (self) {
                                    var match = getValuesFromPredicates(predicates$2);
                                    return Curry._1(self.send, /* UpdatePredicatesForm */Block.__(8, [
                                                  match[0],
                                                  match[1],
                                                  match[2]
                                                ]));
                                  })
                              ]);
                  case /* UpdatePredicatesForm */8 :
                      var sourceValue = action[2];
                      var objectValue = action[1];
                      var functionValue = action[0];
                      return /* SideEffects */Block.__(1, [(function (_self) {
                                    return Curry._7(handleRuleUpdate, itemKey, functionValue, objectValue, state.actionValue, state.replacementValue, state.attributeValue, sourceValue);
                                  })]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var predicateOptions = /* :: */[
  /* FunctionPredicate */0,
  /* :: */[
    /* ObjectPredicate */1,
    /* :: */[
      /* SourcePredicate */2,
      /* [] */0
    ]
  ]
];

exports.defaultAction = defaultAction;
exports.regexReplacementPlaceholder = regexReplacementPlaceholder;
exports.regexPlaceholder = regexPlaceholder;
exports.badRegexReplacementMessage = badRegexReplacementMessage;
exports.isRegexFormat = isRegexFormat;
exports.getNewActions = getNewActions;
exports.getNewActionsByRemoving = getNewActionsByRemoving;
exports.actionOptions = actionOptions;
exports.predicateOptions = predicateOptions;
exports.component = component;
exports.cardStyle = cardStyle;
exports.hoverStyle = hoverStyle;
exports.blockStyle = blockStyle;
exports.boxedStyle = boxedStyle;
exports.ruleSpacingStyle = ruleSpacingStyle;
exports.ruleSectionStatic = ruleSectionStatic;
exports.ruleRowStyleStatic = ruleRowStyleStatic;
exports.ruleRowStyle = ruleRowStyle;
exports.inputStyle = inputStyle;
exports.formStyle = formStyle;
exports.cardMarginStyle = cardMarginStyle;
exports.addPredicatePlusButton = addPredicatePlusButton;
exports.getValuesFromPredicates = getValuesFromPredicates;
exports.predicateToString = predicateToString;
exports.make = make;
/* regexReplacementPlaceholder Not a pure module */
