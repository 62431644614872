// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeTag(json) {
  return {
          tag: Json_decode.field("tag", Json_decode.string, json),
          lastUpdateTime: Json_decode.field("last_updated_time", Json_decode.$$int, json)
        };
}

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("tags", (function (param) {
                              return Json_decode.list(decodeTag, param);
                            }), json);
              }), json);
}

exports.decodeTag = decodeTag;
exports.decode = decode;
/* No side effect */
