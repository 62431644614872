// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function tryWith(fn) {
  var x;
  try {
    x = Curry._1(fn, undefined);
  }
  catch (exn){
    return ;
  }
  return Caml_option.some(x);
}

function compact(_param) {
  while(true) {
    var param = _param;
    if (!param) {
      return /* [] */0;
    }
    var hd = param[0];
    if (hd !== undefined) {
      return /* :: */[
              Caml_option.valFromOption(hd),
              compact(param[1])
            ];
    }
    _param = param[1];
    continue ;
  };
}

function bind(v, f) {
  if (v !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(v)));
  }
  
}

function ofString(s) {
  if (s.length !== 0) {
    return s;
  }
  
}

function wrap(o) {
  return Caml_option.some(o);
}

exports.tryWith = tryWith;
exports.compact = compact;
exports.bind = bind;
exports.ofString = ofString;
exports.wrap = wrap;
/* No side effect */
