// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");

function BugFailure(Props) {
  var children = Props.children;
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.paddingTop(Css.px(80)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: React.createElement(Col2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.fontSize(Css.px(18)),
                          /* [] */0
                        ]),
                    children: children
                  })
            });
}

var make = BugFailure;

exports.make = make;
/* Css Not a pure module */
