// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Button = require("@material-ui/core/Button");

function TeamsCreateOrEditTeam(Props) {
  var _open = Props.open;
  var currentTeam = Props.currentTeam;
  var onCloseOpt = Props.onClose;
  var saveChanges = Props.saveChanges;
  var teamName = Props.teamName;
  var setTeamName = Props.setTeamName;
  var teamDescription = Props.teamDescription;
  var setTeamDescription = Props.setTeamDescription;
  var callToActionString = Props.callToActionString;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var tmp;
  if (currentTeam !== undefined) {
    var s = currentTeam.description;
    tmp = s !== undefined ? s : "";
  } else {
    tmp = "";
  }
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: onClose,
              title: currentTeam !== undefined ? I18N$BsConsole.get(undefined, "Edit team") : I18N$BsConsole.get(undefined, "Create team"),
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* flexEnd */924268066,
                    children: null
                  }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: currentTeam !== undefined ? currentTeam.name : "",
                        value: teamName,
                        label: I18N$BsConsole.show(undefined, "Name"),
                        onChange: (function ($$event) {
                            return Curry._1(setTeamName, BtSettings$BsConsole.getInputValue($$event));
                          })
                      }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                        placeholder: tmp,
                        value: teamDescription,
                        label: I18N$BsConsole.show(undefined, "Description"),
                        onChange: (function ($$event) {
                            return Curry._1(setTeamDescription, BtSettings$BsConsole.getInputValue($$event));
                          })
                      }), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: teamName === "",
                              onClick: saveChanges,
                              children: callToActionString !== undefined ? I18N$BsConsole.showSkip(callToActionString) : (
                                  currentTeam !== undefined ? I18N$BsConsole.show(undefined, "Edit") : I18N$BsConsole.show(undefined, "Create")
                                )
                            })
                      }))
            });
}

var make = TeamsCreateOrEditTeam;

exports.make = make;
/* Css Not a pure module */
