// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var SlateReact = require("slate-react");

function make(style, className, placeholder, readOnly, value, onChange, schema, plugins, children) {
  return ReasonReact.wrapJsForReason(SlateReact.Editor, {
              style: style,
              className: className,
              value: value,
              onChange: onChange,
              placeholder: placeholder,
              readOnly: readOnly,
              schema: schema,
              plugins: plugins
            }, children);
}

var Editor = {
  make: make
};

exports.Editor = Editor;
/* ReasonReact Not a pure module */
