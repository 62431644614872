// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../../I18N.js");

var timestamp = I18N$BsConsole.get(undefined, "Timestamp");

var level = I18N$BsConsole.get(undefined, "Level");

var typeText = I18N$BsConsole.get(undefined, "Type");

var description = I18N$BsConsole.get(undefined, "Description");

var searchPlaceholder = I18N$BsConsole.get(undefined, "Search breadcrumbs...");

var sort = I18N$BsConsole.get(undefined, "Sort");

var noBreadcrumbs = I18N$BsConsole.get(undefined, "No breadcrumbs");

var noResultForSearchParam = I18N$BsConsole.get(undefined, "No breadcrumbs for search parameter");

var filterBy = I18N$BsConsole.get(undefined, "Filter by");

var noTypes = I18N$BsConsole.get(undefined, "No types");

var allTypes = I18N$BsConsole.get(undefined, "All types");

var noLevels = I18N$BsConsole.get(undefined, "No levels");

var allLevels = I18N$BsConsole.get(undefined, "All levels");

var collapseAll = I18N$BsConsole.get(undefined, "Collapse all breadcrumb attributes");

var expandAll = I18N$BsConsole.get(undefined, "Expand all breadcrumb attributes");

var errorFetching = I18N$BsConsole.get(undefined, "Error fetching breadcrumb data.");

var noBreadcrumbsCheckDocs = I18N$BsConsole.get(undefined, "No breadcrumbs found for this error. Check your integration documentation to see if breadcrumbs are supported.");

var noBreadcrumbsFound = I18N$BsConsole.get(undefined, "No valid breadcrumbs found for this error.");

exports.timestamp = timestamp;
exports.level = level;
exports.typeText = typeText;
exports.description = description;
exports.searchPlaceholder = searchPlaceholder;
exports.sort = sort;
exports.noBreadcrumbs = noBreadcrumbs;
exports.noResultForSearchParam = noResultForSearchParam;
exports.filterBy = filterBy;
exports.noTypes = noTypes;
exports.allTypes = allTypes;
exports.noLevels = noLevels;
exports.allLevels = allLevels;
exports.collapseAll = collapseAll;
exports.expandAll = expandAll;
exports.errorFetching = errorFetching;
exports.noBreadcrumbsCheckDocs = noBreadcrumbsCheckDocs;
exports.noBreadcrumbsFound = noBreadcrumbsFound;
/* timestamp Not a pure module */
