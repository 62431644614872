// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function getFriendlyName(attribute) {
  var regex = new RegExp("fingerprint;issues;(.+)");
  var result = regex.exec(attribute);
  if (result === null) {
    return attribute;
  }
  var match_ = Belt_Array.get(result, 1);
  if (match_ !== undefined) {
    return "TRIAGE " + match_;
  } else {
    return attribute;
  }
}

function isNestedIssues(attribute) {
  var regex = new RegExp("fingerprint;issues;(.)");
  return Belt_Option.isSome(Caml_option.null_to_opt(regex.exec(attribute)));
}

function assigneeFromBPGUser(user, watcher) {
  return /* User */Block.__(0, [{
              watcher: watcher,
              username: user.username,
              email: user.email,
              id: user.uid
            }]);
}

function assigneesFromBPGUsers(users) {
  return Belt_List.map(users, (function (u) {
                return (function (param) {
                    return assigneeFromBPGUser(u, param);
                  });
              }));
}

exports.getFriendlyName = getFriendlyName;
exports.isNestedIssues = isNestedIssues;
exports.assigneeFromBPGUser = assigneeFromBPGUser;
exports.assigneesFromBPGUsers = assigneesFromBPGUsers;
/* No side effect */
