// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReForm = require("bs-reform/lib/js/re/ReForm.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Map = require("bs-platform/lib/js/belt_Map.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");

var cmp = Caml_obj.caml_compare;

var C = Belt_Id.MakeComparable({
      cmp: cmp
    });

var empty = Belt_Map.make(C);

function set(k, v, t) {
  return Belt_Map.set(t, k, v);
}

function get(k, t) {
  return Belt_Map.getExn(t, k);
}

var Fields = {
  empty: empty,
  set: set,
  get: get,
  toList: Belt_Map.toList
};

var lens_000 = /* tuple */[
  /* Email */50085628,
  (function (fields) {
      return Belt_Map.getExn(fields, "email");
    }),
  (function (fields, email) {
      return Belt_Map.set(fields, "email", email);
    })
];

var lens = /* :: */[
  lens_000,
  /* [] */0
];

var EditEmailParams = {
  lens: lens
};

var EditEmailForm = ReForm.Create(EditEmailParams);

var lens_000$1 = /* tuple */[
  /* Password */578936635,
  (function (s) {
      return s.password;
    }),
  (function (s, password) {
      return {
              password: password,
              confirmation: s.confirmation
            };
    })
];

var lens_001 = /* :: */[
  /* tuple */[
    /* Confirmation */-138763819,
    (function (s) {
        return s.confirmation;
      }),
    (function (s, confirmation) {
        return {
                password: s.password,
                confirmation: confirmation
              };
      })
  ],
  /* [] */0
];

var lens$1 = /* :: */[
  lens_000$1,
  lens_001
];

var EditPasswordParams = {
  lens: lens$1
};

var EditPasswordForm = ReForm.Create(EditPasswordParams);

var lens_000$2 = /* tuple */[
  /* Method */1039100673,
  (function (s) {
      return s.method_;
    }),
  (function (s, method__) {
      return {
              method_: method__
            };
    })
];

var lens$2 = /* :: */[
  lens_000$2,
  /* [] */0
];

var EditAuthMethodParams = {
  lens: lens$2
};

var EditAuthMethodForm = ReForm.Create(EditAuthMethodParams);

var lens_000$3 = /* tuple */[
  /* Method */1039100673,
  (function (s) {
      return s.method_;
    }),
  (function (s, method__) {
      return {
              email: s.email,
              method_: method__,
              role: s.role,
              superuser: s.superuser
            };
    })
];

var lens_001$1 = /* :: */[
  /* tuple */[
    /* Email */50085628,
    (function (s) {
        return s.email;
      }),
    (function (s, email) {
        return {
                email: email,
                method_: s.method_,
                role: s.role,
                superuser: s.superuser
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Role */914888598,
      (function (s) {
          return s.role;
        }),
      (function (s, role) {
          return {
                  email: s.email,
                  method_: s.method_,
                  role: role,
                  superuser: s.superuser
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* SuperUser */-134776506,
        (function (s) {
            return s.superuser;
          }),
        (function (s, superuser) {
            return {
                    email: s.email,
                    method_: s.method_,
                    role: s.role,
                    superuser: superuser
                  };
          })
      ],
      /* [] */0
    ]
  ]
];

var lens$3 = /* :: */[
  lens_000$3,
  lens_001$1
];

var EditUserParams = {
  lens: lens$3
};

var EditUserForm = ReForm.Create(EditUserParams);

var lens_000$4 = /* tuple */[
  /* Domain */-501930652,
  (function (s) {
      return s.domain;
    }),
  (function (s, domain) {
      return {
              domain: domain,
              role: s.role,
              method_: s.method_
            };
    })
];

var lens_001$2 = /* :: */[
  /* tuple */[
    /* Role */914888598,
    (function (s) {
        return s.role;
      }),
    (function (s, role) {
        return {
                domain: s.domain,
                role: role,
                method_: s.method_
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Method */1039100673,
      (function (s) {
          return s.method_;
        }),
      (function (s, method__) {
          return {
                  domain: s.domain,
                  role: s.role,
                  method_: method__
                };
        })
    ],
    /* [] */0
  ]
];

var lens$4 = /* :: */[
  lens_000$4,
  lens_001$2
];

var EditSingupWhitelistParams = {
  lens: lens$4
};

var EditSingupWhitelistForm = ReForm.Create(EditSingupWhitelistParams);

var lens_000$5 = /* tuple */[
  /* Method */1039100673,
  (function (s) {
      return s.method_;
    }),
  (function (s, method__) {
      return {
              email: s.email,
              username: s.username,
              role: s.role,
              method_: method__
            };
    })
];

var lens_001$3 = /* :: */[
  /* tuple */[
    /* Email */50085628,
    (function (s) {
        return s.email;
      }),
    (function (s, email) {
        return {
                email: email,
                username: s.username,
                role: s.role,
                method_: s.method_
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Username */-90357194,
      (function (s) {
          return s.username;
        }),
      (function (s, username) {
          return {
                  email: s.email,
                  username: username,
                  role: s.role,
                  method_: s.method_
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* Role */914888598,
        (function (s) {
            return s.role;
          }),
        (function (s, role) {
            return {
                    email: s.email,
                    username: s.username,
                    role: role,
                    method_: s.method_
                  };
          })
      ],
      /* [] */0
    ]
  ]
];

var lens$5 = /* :: */[
  lens_000$5,
  lens_001$3
];

var NewInviteFormParams = {
  lens: lens$5
};

var NewInviteForm = ReForm.Create(NewInviteFormParams);

var lens_000$6 = /* tuple */[
  /* Name */869834347,
  (function (s) {
      return s.name;
    }),
  (function (s, name) {
      return {
              name: name
            };
    })
];

var lens$6 = /* :: */[
  lens_000$6,
  /* [] */0
];

var NewProjectParams = {
  lens: lens$6
};

var NewProjectForm = ReForm.Create(NewProjectParams);

var lens_000$7 = /* tuple */[
  /* Owner */-878442477,
  (function (s) {
      return s.owner;
    }),
  (function (s, owner) {
      return {
              owner: owner
            };
    })
];

var lens$7 = /* :: */[
  lens_000$7,
  /* [] */0
];

var EditProjectParams = {
  lens: lens$7
};

var EditProjectForm = ReForm.Create(EditProjectParams);

var lens_000$8 = /* tuple */[
  /* MailFrom */-819352223,
  (function (s) {
      return s.mail_from;
    }),
  (function (s, mail_from) {
      return {
              mail_from: mail_from,
              server: s.server,
              username: s.username,
              password: s.password
            };
    })
];

var lens_001$4 = /* :: */[
  /* tuple */[
    /* Server */583778019,
    (function (s) {
        return s.server;
      }),
    (function (s, server) {
        return {
                mail_from: s.mail_from,
                server: server,
                username: s.username,
                password: s.password
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Username */-90357194,
      (function (s) {
          return s.username;
        }),
      (function (s, username) {
          return {
                  mail_from: s.mail_from,
                  server: s.server,
                  username: username,
                  password: s.password
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* Password */578936635,
        (function (s) {
            return s.password;
          }),
        (function (s, password) {
            return {
                    mail_from: s.mail_from,
                    server: s.server,
                    username: s.username,
                    password: password
                  };
          })
      ],
      /* [] */0
    ]
  ]
];

var lens$8 = /* :: */[
  lens_000$8,
  lens_001$4
];

var NewSmtpParams = {
  lens: lens$8
};

var NewSmtpForm = ReForm.Create(NewSmtpParams);

var lens_000$9 = /* tuple */[
  /* Name */869834347,
  (function (s) {
      return s.name;
    }),
  (function (s, name) {
      return {
              name: name,
              key: s.key,
              cert: s.cert,
              chain: s.chain
            };
    })
];

var lens_001$5 = /* :: */[
  /* tuple */[
    /* Key */3752319,
    (function (s) {
        return s.key;
      }),
    (function (s, key) {
        return {
                name: s.name,
                key: key,
                cert: s.cert,
                chain: s.chain
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Cert */748049156,
      (function (s) {
          return s.cert;
        }),
      (function (s, cert) {
          return {
                  name: s.name,
                  key: s.key,
                  cert: cert,
                  chain: s.chain
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* Chain */-656341791,
        (function (s) {
            return s.chain;
          }),
        (function (s, chain) {
            return {
                    name: s.name,
                    key: s.key,
                    cert: s.cert,
                    chain: chain
                  };
          })
      ],
      /* [] */0
    ]
  ]
];

var lens$9 = /* :: */[
  lens_000$9,
  lens_001$5
];

var SslParams = {
  lens: lens$9
};

var SslForm = ReForm.Create(SslParams);

var lens_000$10 = /* tuple */[
  /* Protocol */-636153224,
  (function (s) {
      return s.protocol;
    }),
  (function (s, protocol) {
      return {
              protocol: protocol,
              type_: s.type_,
              address: s.address,
              dns_name: s.dns_name,
              service: s.service,
              backlog: s.backlog,
              ssl: s.ssl,
              concurrency: s.concurrency
            };
    })
];

var lens_001$6 = /* :: */[
  /* tuple */[
    /* Type */937565914,
    (function (s) {
        return s.type_;
      }),
    (function (s, type_) {
        return {
                protocol: s.protocol,
                type_: type_,
                address: s.address,
                dns_name: s.dns_name,
                service: s.service,
                backlog: s.backlog,
                ssl: s.ssl,
                concurrency: s.concurrency
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Address */-363030124,
      (function (s) {
          return s.address;
        }),
      (function (s, address) {
          return {
                  protocol: s.protocol,
                  type_: s.type_,
                  address: address,
                  dns_name: s.dns_name,
                  service: s.service,
                  backlog: s.backlog,
                  ssl: s.ssl,
                  concurrency: s.concurrency
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* DnsName */-236810124,
        (function (s) {
            return s.dns_name;
          }),
        (function (s, dns_name) {
            return {
                    protocol: s.protocol,
                    type_: s.type_,
                    address: s.address,
                    dns_name: dns_name,
                    service: s.service,
                    backlog: s.backlog,
                    ssl: s.ssl,
                    concurrency: s.concurrency
                  };
          })
      ],
      /* :: */[
        /* tuple */[
          /* Service */-813808619,
          (function (s) {
              return s.service;
            }),
          (function (s, service) {
              return {
                      protocol: s.protocol,
                      type_: s.type_,
                      address: s.address,
                      dns_name: s.dns_name,
                      service: service,
                      backlog: s.backlog,
                      ssl: s.ssl,
                      concurrency: s.concurrency
                    };
            })
        ],
        /* :: */[
          /* tuple */[
            /* Backlog */-924961635,
            (function (s) {
                return s.backlog;
              }),
            (function (s, backlog) {
                return {
                        protocol: s.protocol,
                        type_: s.type_,
                        address: s.address,
                        dns_name: s.dns_name,
                        service: s.service,
                        backlog: backlog,
                        ssl: s.ssl,
                        concurrency: s.concurrency
                      };
              })
          ],
          /* :: */[
            /* tuple */[
              /* Ssl */4153260,
              (function (s) {
                  return s.ssl;
                }),
              (function (s, ssl) {
                  return {
                          protocol: s.protocol,
                          type_: s.type_,
                          address: s.address,
                          dns_name: s.dns_name,
                          service: s.service,
                          backlog: s.backlog,
                          ssl: ssl,
                          concurrency: s.concurrency
                        };
                })
            ],
            /* :: */[
              /* tuple */[
                /* Concurrency */-645910157,
                (function (s) {
                    return s.concurrency;
                  }),
                (function (s, concurrency) {
                    return {
                            protocol: s.protocol,
                            type_: s.type_,
                            address: s.address,
                            dns_name: s.dns_name,
                            service: s.service,
                            backlog: s.backlog,
                            ssl: s.ssl,
                            concurrency: concurrency
                          };
                  })
              ],
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var lens$10 = /* :: */[
  lens_000$10,
  lens_001$6
];

var ListenerParams = {
  lens: lens$10
};

var ListenerForm = ReForm.Create(ListenerParams);

var lens_000$11 = /* tuple */[
  /* Name */869834347,
  (function (s) {
      return s.name;
    }),
  (function (s, name) {
      return {
              name: name,
              format: s.format,
              type_: s.type_,
              description: s.description
            };
    })
];

var lens_001$7 = /* :: */[
  /* tuple */[
    /* Type */937565914,
    (function (s) {
        return s.type_;
      }),
    (function (s, type_) {
        return {
                name: s.name,
                format: s.format,
                type_: type_,
                description: s.description
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Format */841155415,
      (function (s) {
          return s.format;
        }),
      (function (s, format) {
          return {
                  name: s.name,
                  format: format,
                  type_: s.type_,
                  description: s.description
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* Description */-1018191652,
        (function (s) {
            return s.description;
          }),
        (function (s, description) {
            return {
                    name: s.name,
                    format: s.format,
                    type_: s.type_,
                    description: description
                  };
          })
      ],
      /* [] */0
    ]
  ]
];

var lens$11 = /* :: */[
  lens_000$11,
  lens_001$7
];

var AttributeParams = {
  lens: lens$11
};

var AttributeForm = ReForm.Create(AttributeParams);

var lens_000$12 = /* tuple */[
  /* Password */578936635,
  (function (s) {
      return s.password;
    }),
  (function (s, password) {
      return {
              password: password,
              confirmPassword: s.confirmPassword
            };
    })
];

var lens_001$8 = /* :: */[
  /* tuple */[
    /* ConfirmPassword */28083227,
    (function (s) {
        return s.confirmPassword;
      }),
    (function (s, confirmPassword) {
        return {
                password: s.password,
                confirmPassword: confirmPassword
              };
      })
  ],
  /* [] */0
];

var lens$12 = /* :: */[
  lens_000$12,
  lens_001$8
];

var ResetPasswordParams = {
  lens: lens$12
};

var ResetPasswordForm = ReForm.Create(ResetPasswordParams);

var lens_000$13 = /* tuple */[
  /* Password */578936635,
  (function (s) {
      return s.password;
    }),
  (function (s, password) {
      return {
              username: s.username,
              password: password
            };
    })
];

var lens_001$9 = /* :: */[
  /* tuple */[
    /* Username */-90357194,
    (function (s) {
        return s.username;
      }),
    (function (s, username) {
        return {
                username: username,
                password: s.password
              };
      })
  ],
  /* [] */0
];

var lens$13 = /* :: */[
  lens_000$13,
  lens_001$9
];

var LoginParams = {
  lens: lens$13
};

var LoginForm = ReForm.Create(LoginParams);

var lens_000$14 = /* tuple */[
  /* Title */594052472,
  (function (s) {
      return s.title;
    }),
  (function (s, title) {
      return {
              title: title,
              rcpt: s.rcpt,
              day: s.day,
              period: s.period,
              timezone: s.timezone,
              hour: s.hour,
              amOrPm: s.amOrPm
            };
    })
];

var lens_001$10 = /* :: */[
  /* tuple */[
    /* Period */-273689311,
    (function (s) {
        return s.period;
      }),
    (function (s, period) {
        return {
                title: s.title,
                rcpt: s.rcpt,
                day: s.day,
                period: period,
                timezone: s.timezone,
                hour: s.hour,
                amOrPm: s.amOrPm
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Rcpt */914292757,
      (function (s) {
          return s.rcpt;
        }),
      (function (s, rcpt) {
          return {
                  title: s.title,
                  rcpt: rcpt,
                  day: s.day,
                  period: s.period,
                  timezone: s.timezone,
                  hour: s.hour,
                  amOrPm: s.amOrPm
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* Day */3403324,
        (function (s) {
            return s.day;
          }),
        (function (s, day) {
            return {
                    title: s.title,
                    rcpt: s.rcpt,
                    day: day,
                    period: s.period,
                    timezone: s.timezone,
                    hour: s.hour,
                    amOrPm: s.amOrPm
                  };
          })
      ],
      /* :: */[
        /* tuple */[
          /* Timezone */-533547815,
          (function (s) {
              return s.timezone;
            }),
          (function (s, timezone) {
              return {
                      title: s.title,
                      rcpt: s.rcpt,
                      day: s.day,
                      period: s.period,
                      timezone: timezone,
                      hour: s.hour,
                      amOrPm: s.amOrPm
                    };
            })
        ],
        /* :: */[
          /* tuple */[
            /* Hour */803994948,
            (function (s) {
                return s.hour;
              }),
            (function (s, hour) {
                return {
                        title: s.title,
                        rcpt: s.rcpt,
                        day: s.day,
                        period: s.period,
                        timezone: s.timezone,
                        hour: hour,
                        amOrPm: s.amOrPm
                      };
              })
          ],
          /* :: */[
            /* tuple */[
              /* AmOrPm */-194096852,
              (function (s) {
                  return s.amOrPm;
                }),
              (function (s, amOrPm) {
                  return {
                          title: s.title,
                          rcpt: s.rcpt,
                          day: s.day,
                          period: s.period,
                          timezone: s.timezone,
                          hour: s.hour,
                          amOrPm: amOrPm
                        };
                })
            ],
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var lens$14 = /* :: */[
  lens_000$14,
  lens_001$10
];

var ScheduledReport = {
  lens: lens$14
};

var ScheduledReportForm = ReForm.Create(ScheduledReport);

var lens_000$15 = /* tuple */[
  /* Issuer */863942297,
  (function (s) {
      return s.issuer;
    }),
  (function (s, issuer) {
      return {
              issuer: issuer,
              entryPoint: s.entryPoint,
              cert: s.cert,
              decryptionPvk: s.decryptionPvk,
              adminContact: s.adminContact,
              callbackUrl: s.callbackUrl
            };
    })
];

var lens_001$11 = /* :: */[
  /* tuple */[
    /* EntryPoint */572773022,
    (function (s) {
        return s.entryPoint;
      }),
    (function (s, entryPoint) {
        return {
                issuer: s.issuer,
                entryPoint: entryPoint,
                cert: s.cert,
                decryptionPvk: s.decryptionPvk,
                adminContact: s.adminContact,
                callbackUrl: s.callbackUrl
              };
      })
  ],
  /* :: */[
    /* tuple */[
      /* Cert */748049156,
      (function (s) {
          return s.cert;
        }),
      (function (s, cert) {
          return {
                  issuer: s.issuer,
                  entryPoint: s.entryPoint,
                  cert: cert,
                  decryptionPvk: s.decryptionPvk,
                  adminContact: s.adminContact,
                  callbackUrl: s.callbackUrl
                };
        })
    ],
    /* :: */[
      /* tuple */[
        /* DecryptionPvk */782281386,
        (function (s) {
            return s.decryptionPvk;
          }),
        (function (s, decryptionPvk) {
            return {
                    issuer: s.issuer,
                    entryPoint: s.entryPoint,
                    cert: s.cert,
                    decryptionPvk: decryptionPvk,
                    adminContact: s.adminContact,
                    callbackUrl: s.callbackUrl
                  };
          })
      ],
      /* :: */[
        /* tuple */[
          /* AdminContact */-408387087,
          (function (s) {
              return s.adminContact;
            }),
          (function (s, adminContact) {
              return {
                      issuer: s.issuer,
                      entryPoint: s.entryPoint,
                      cert: s.cert,
                      decryptionPvk: s.decryptionPvk,
                      adminContact: adminContact,
                      callbackUrl: s.callbackUrl
                    };
            })
        ],
        /* :: */[
          /* tuple */[
            /* CallbackUrl */822708874,
            (function (s) {
                return s.callbackUrl;
              }),
            (function (s, callbackUrl) {
                return {
                        issuer: s.issuer,
                        entryPoint: s.entryPoint,
                        cert: s.cert,
                        decryptionPvk: s.decryptionPvk,
                        adminContact: s.adminContact,
                        callbackUrl: callbackUrl
                      };
              })
          ],
          /* [] */0
        ]
      ]
    ]
  ]
];

var lens$15 = /* :: */[
  lens_000$15,
  lens_001$11
];

var SSOConfig = {
  lens: lens$15
};

var SSOConfigForm = ReForm.Create(SSOConfig);

exports.Fields = Fields;
exports.EditEmailParams = EditEmailParams;
exports.EditEmailForm = EditEmailForm;
exports.EditPasswordParams = EditPasswordParams;
exports.EditPasswordForm = EditPasswordForm;
exports.EditAuthMethodParams = EditAuthMethodParams;
exports.EditAuthMethodForm = EditAuthMethodForm;
exports.EditUserParams = EditUserParams;
exports.EditUserForm = EditUserForm;
exports.EditSingupWhitelistParams = EditSingupWhitelistParams;
exports.EditSingupWhitelistForm = EditSingupWhitelistForm;
exports.NewInviteFormParams = NewInviteFormParams;
exports.NewInviteForm = NewInviteForm;
exports.NewProjectParams = NewProjectParams;
exports.NewProjectForm = NewProjectForm;
exports.EditProjectParams = EditProjectParams;
exports.EditProjectForm = EditProjectForm;
exports.NewSmtpParams = NewSmtpParams;
exports.NewSmtpForm = NewSmtpForm;
exports.SslParams = SslParams;
exports.SslForm = SslForm;
exports.ListenerParams = ListenerParams;
exports.ListenerForm = ListenerForm;
exports.AttributeParams = AttributeParams;
exports.AttributeForm = AttributeForm;
exports.ResetPasswordParams = ResetPasswordParams;
exports.ResetPasswordForm = ResetPasswordForm;
exports.LoginParams = LoginParams;
exports.LoginForm = LoginForm;
exports.ScheduledReport = ScheduledReport;
exports.ScheduledReportForm = ScheduledReportForm;
exports.SSOConfig = SSOConfig;
exports.SSOConfigForm = SSOConfigForm;
/* C Not a pure module */
