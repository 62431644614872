// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Slate = require("slate");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("../RR.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BugTheme$BsConsole = require("./BugTheme.js");
var ActivityFeed$BsConsole = require("../activity-feed/ActivityFeed.js");
var Avatar = require("@material-ui/core/Avatar");
var Styles = require("@material-ui/core/styles");
var CommentContent$BsConsole = require("../activity-feed/CommentContent.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var Popover = require("@material-ui/core/Popover");
var AccountCircle = require("@material-ui/icons/AccountCircle");

function BugCommentsPopover$CommentCard(Props) {
  var allUsers = Props.allUsers;
  var activeUser = Props.activeUser;
  var value = Props.value;
  var user = Props.user;
  var timestamp = Props.timestamp;
  var match = user !== undefined ? /* tuple */[
      React.createElement(Avatar.default, {
            className: Css.style(/* :: */[
                  Css.width(Css.px(24)),
                  /* :: */[
                    Css.height(Css.px(24)),
                    /* :: */[
                      Css.fontSize(Css.rem(0.875)),
                      /* [] */0
                    ]
                  ]
                ]),
            children: I18N$BsConsole.showSkip(user.username.charAt(0).toUpperCase())
          }),
      user.username
    ] : /* tuple */[
      React.createElement(AccountCircle.default, { }),
      I18N$BsConsole.get(undefined, "[ deleted ]")
    ];
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.padding2(Css.px(8), Css.px(12)),
                    /* [] */0
                  ]),
              children: null
            }, match[0], React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.paddingLeft(Css.px(12)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.height(Css.px(24)),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.fontWeight(Css.medium),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.showSkip(match[1])), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey4)),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, timestamp)))), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.paddingTop(Css.px(6)),
                            /* [] */0
                          ])
                    }, RR$BsConsole.element(undefined, undefined, CommentContent$BsConsole.make(undefined, undefined, false, allUsers, activeUser, value, (function (param) {
                                
                              }), [])))));
}

var CommentCard = {
  make: BugCommentsPopover$CommentCard
};

function BugCommentsPopover(Props) {
  var fingerprint = Props.fingerprint;
  var activeUser = Props.activeUser;
  var projectId = Props.projectId;
  var anchorEl = Props.anchorEl;
  var onClose = Props.onClose;
  var serviceUrl = Props.serviceUrl;
  var token = Props.token;
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var allUsers;
  allUsers = typeof allUsersRemote === "number" ? [] : (
      allUsersRemote.tag ? [] : allUsersRemote[0][0]
    );
  return React.createElement(Styles.MuiThemeProvider, {
              theme: BugTheme$BsConsole.blue,
              children: React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "bottom"
                    },
                    transformOrigin: {
                      horizontal: "right",
                      vertical: "top"
                    },
                    anchorEl: anchorEl,
                    open: Belt_Option.isSome(anchorEl),
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.marginTop(Css.px(4)),
                            /* :: */[
                              Css.marginLeft(Css.px(6)),
                              /* [] */0
                            ]
                          ])
                    },
                    onClose: onClose,
                    children: React.createElement(ActivityFeed$BsConsole.Jsx3.make, {
                          serviceUrl: serviceUrl,
                          fingerprint: fingerprint,
                          projectId: projectId,
                          token: token,
                          user: activeUser,
                          users: allUsers,
                          containerClassName: Css.style(/* :: */[
                                Css.minWidth(Css.px(400)),
                                /* :: */[
                                  Css.selector(".CommentInput_container", /* :: */[
                                        Css.borderRadius(/* zero */-789508312),
                                        /* :: */[
                                          Css.unsafe("border", "unset"),
                                          /* :: */[
                                            Css.marginBottom(/* zero */-789508312),
                                            /* :: */[
                                              Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector(".ActivityFeed_CommentList", /* :: */[
                                          Css.maxHeight(Css.px(500)),
                                          /* :: */[
                                            Css.overflowY(/* auto */-1065951377),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          renderComment: (function (param) {
                              var comment = param.comment;
                              var allUsers = param.allUsers;
                              var user = allUsers.find((function (user) {
                                      return user.uid === comment.userId;
                                    }));
                              return React.createElement(BugCommentsPopover$CommentCard, {
                                          allUsers: allUsers,
                                          activeUser: param.activeUser,
                                          value: Slate.Value.fromJSON(comment.contents),
                                          user: user === undefined ? undefined : Caml_option.some(user),
                                          timestamp: comment.timestamp
                                        });
                            })
                        })
                  })
            });
}

var make = BugCommentsPopover;

exports.CommentCard = CommentCard;
exports.make = make;
/* Css Not a pure module */
