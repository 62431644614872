// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BtCard$BsConsole = require("../utilities/BtCard.js");
var DataCard$BsConsole = require("../utilities/DataCard.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SettingsTheme$BsConsole = require("../project-settings/SettingsTheme.js");
var Styles = require("@material-ui/core/styles");

function PlayCards$DataCardExamples(Props) {
  var cards = [
    React.createElement(DataCard$BsConsole.make, {
          title: I18N$BsConsole.get(undefined, "Disk space consumed"),
          numerator: 6.5,
          denominator: 10.0,
          verb: I18N$BsConsole.get(undefined, "used"),
          formatter: /* Bytes */1,
          failure: undefined,
          extra: undefined
        }),
    React.createElement(DataCard$BsConsole.make, {
          title: I18N$BsConsole.get(undefined, "Total seats filled"),
          numerator: 1.0,
          denominator: 1.0,
          unit: I18N$BsConsole.get(undefined, "seats"),
          formatter: /* NoDecimal */2,
          failure: undefined,
          extra: undefined
        }),
    React.createElement(DataCard$BsConsole.make, {
          title: I18N$BsConsole.get(undefined, "Monthly errors received"),
          numerator: 4000034.34,
          denominator: 3000000.0,
          unit: I18N$BsConsole.get(undefined, "errors"),
          verb: I18N$BsConsole.get(undefined, "received"),
          formatter: /* Millions */0,
          failure: undefined,
          extra: undefined
        })
  ];
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.margin(Css.px(25)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "DataCard")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Component for rendering text with a data visualization")
                }), React.createElement(Styles.MuiThemeProvider, {
                  theme: SettingsTheme$BsConsole.theme,
                  children: React.createElement(Row2$BsConsole.make, {
                        children: cards
                      })
                }));
}

var DataCardExamples = {
  make: PlayCards$DataCardExamples
};

function PlayCards(Props) {
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.margin(Css.px(25)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "BtCard")
                }), React.createElement(Styles.MuiThemeProvider, {
                  theme: SettingsTheme$BsConsole.theme,
                  children: React.createElement(Row2$BsConsole.make, {
                        flexWrap: /* wrap */-822134326,
                        children: React.createElement(BtCard$BsConsole.make, {
                              title: I18N$BsConsole.get(undefined, "Title"),
                              content: Caml_option.some(I18N$BsConsole.show(undefined, "Card content component")),
                              status: Caml_option.some(React.createElement(BtCard$BsConsole.Status.make, { })),
                              more: /* :: */[
                                /* tuple */[
                                  "Edit",
                                  (function (param) {
                                      
                                    })
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "Delete",
                                    (function (param) {
                                        
                                      })
                                  ],
                                  /* [] */0
                                ]
                              ],
                              expand: /* tuple */[
                                I18N$BsConsole.get(undefined, "View expansion"),
                                I18N$BsConsole.get(undefined, "Hide expansion"),
                                I18N$BsConsole.show(undefined, "Expanded content component")
                              ]
                            })
                      })
                }));
}

var make = PlayCards;

exports.DataCardExamples = DataCardExamples;
exports.make = make;
/* Css Not a pure module */
