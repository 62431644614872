// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var Option$BsConsole = require("./option.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");
var BpgSingleton$BsConsole = require("./BpgSingleton.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BpgController$BsConsole = require("./BpgController.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

var SslSingleton = BpgSingleton$BsConsole.Make({
      table: BacktraceParserGenerator$BsConsole.Ssl.table,
      to_json: BacktraceParserGenerator$BsConsole.Ssl.to_json,
      of_json: BacktraceParserGenerator$BsConsole.Ssl.of_json,
      Key: BacktraceParserGenerator$BsConsole.Ssl.Key,
      to_key: BacktraceParserGenerator$BsConsole.Ssl.to_key
    });

var component = ReasonReact.statelessComponent("SettingsNetworkingPage-BsConsole__CreateSslDialog");

function make(onSuccess, token, handleTask, onClose, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Ssl.Create.make, (function (_result, _updated) {
                                            Curry._1(onSuccess, undefined);
                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.SslForm.make, (function (param) {
                                                              var values = param.values;
                                                              return Curry._1(handleSubmit, /* tuple */[
                                                                          {
                                                                            id: 0,
                                                                            name: values.name,
                                                                            key: values.key,
                                                                            cert: Option$BsConsole.ofString(values.cert),
                                                                            chain: Option$BsConsole.ofString(values.chain),
                                                                            password: undefined,
                                                                            method_: 0,
                                                                            __state: undefined,
                                                                            __create: undefined,
                                                                            __modify: undefined
                                                                          },
                                                                          Util$BsConsole.identity,
                                                                          param.setError,
                                                                          param.setSubmitting,
                                                                          param.resetFormState
                                                                        ]);
                                                            }), undefined, undefined, {
                                                            name: "",
                                                            key: "",
                                                            cert: "",
                                                            chain: ""
                                                          }, /* :: */[
                                                            /* tuple */[
                                                              /* Name */869834347,
                                                              /* Required */0
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                /* Key */3752319,
                                                                /* Custom */[(function (param) {
                                                                      
                                                                    })]
                                                              ],
                                                              /* :: */[
                                                                /* tuple */[
                                                                  /* Cert */748049156,
                                                                  /* Custom */[(function (param) {
                                                                        
                                                                      })]
                                                                ],
                                                                /* :: */[
                                                                  /* tuple */[
                                                                    /* Chain */-656341791,
                                                                    /* Custom */[(function (param) {
                                                                          
                                                                        })]
                                                                  ],
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ], undefined, (function (param) {
                                                              var getErrorForField = param.getErrorForField;
                                                              var handleSubmit = param.handleSubmit;
                                                              var handleChange = param.handleChange;
                                                              var form = param.form;
                                                              var msg = state.remote;
                                                              var tmp;
                                                              tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, true, [I18N$BsConsole.dynamic(msg[0])]));
                                                              var partial_arg = Curry._1(handleChange, /* Name */869834347);
                                                              var partial_arg$1 = Curry._1(handleChange, /* Key */3752319);
                                                              var partial_arg$2 = Curry._1(handleChange, /* Cert */748049156);
                                                              var partial_arg$3 = Curry._1(handleChange, /* Chain */-656341791);
                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit STMP configuration")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                        tmp,
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Name */869834347)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Name */869834347), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.name
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("mail_from")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Key */3752319)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Key */3752319), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.key
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("key")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Cert */748049156)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Cert */748049156), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.cert
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("cert")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Chain */-656341791)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Chain */-656341791), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.chain
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("chain")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ]))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      console.log(form.values);
                                                                                                      console.log(form.errors);
                                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  state.remote === /* Loading */1,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Save")]
                                                                                                ]))
                                                                                      ]))
                                                                            ]));
                                                            })));
                                          })))]
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var CreateSslDialog = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("SettingsNetworkingPage-BsConsole__CreateSslDialog");

function make$1(onSuccess, ssl, token, handleTask, onClose, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Ssl.Delete.make, (function (_result, _updated) {
                                Curry._1(onSuccess, undefined);
                                return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var msg = state.remote;
                                var tmp;
                                tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, true, [I18N$BsConsole.dynamic(msg[0])]));
                                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                undefined,
                                                true,
                                                onClose,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [
                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete ssl")])),
                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                            tmp,
                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete ssl")]))
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(handleSubmit, /* tuple */[
                                                                                      ssl,
                                                                                      Util$BsConsole.identity
                                                                                    ]);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      state.remote === /* Loading */1,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Delete")]
                                                                    ]))
                                                          ]))
                                                ]
                                              ]));
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var DeleteSslDialog = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("SettingsNetworkingPage-BsConsole__CreateSmtpDialog");

function make$2(onSuccess, smtp, token, handleTask, onClose, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Smtp.Delete.make, (function (_result, _updated) {
                                Curry._1(onSuccess, undefined);
                                return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var msg = state.remote;
                                var tmp;
                                tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, true, [I18N$BsConsole.dynamic(msg[0])]));
                                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                                undefined,
                                                true,
                                                onClose,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [
                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete ssl")])),
                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                            tmp,
                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete ssl")]))
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(handleSubmit, /* tuple */[
                                                                                      smtp,
                                                                                      Util$BsConsole.identity
                                                                                    ]);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      state.remote === /* Loading */1,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Delete")]
                                                                    ]))
                                                          ]))
                                                ]
                                              ]));
                              })));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var DeleteSmtpDialog = {
  component: component$2,
  make: make$2
};

var component$3 = ReasonReact.statelessComponent("SettingsNetworkingPage-BsConsole__CreateSmtpDialog");

function make$3(onSuccess, smtp, token, handleTask, onClose, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Smtp.Modify.make, (function (_result, _updated) {
                                            Curry._1(onSuccess, undefined);
                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.NewSmtpForm.make, (function (param) {
                                                              var values = param.values;
                                                              return Curry._1(handleSubmit, /* tuple */[
                                                                          smtp,
                                                                          (function (smtp) {
                                                                              return {
                                                                                      id: smtp.id,
                                                                                      mail_from: values.mail_from,
                                                                                      server: values.server,
                                                                                      cainfo: smtp.cainfo,
                                                                                      username: values.username.length !== 0 ? values.username : undefined,
                                                                                      password: values.password.length !== 0 ? values.password : undefined,
                                                                                      __state: smtp.__state,
                                                                                      __create: smtp.__create,
                                                                                      __modify: smtp.__modify
                                                                                    };
                                                                            }),
                                                                          Form$BsConsole.Fields.set("password", values.password, Form$BsConsole.Fields.set("username", values.username, Form$BsConsole.Fields.set("server", values.server, Form$BsConsole.Fields.set("mail_from", values.mail_from, Form$BsConsole.Fields.empty)))),
                                                                          param.setError,
                                                                          param.setSubmitting,
                                                                          param.resetFormState
                                                                        ]);
                                                            }), undefined, undefined, {
                                                            mail_from: smtp.mail_from,
                                                            server: smtp.server,
                                                            username: Belt_Option.getWithDefault(smtp.username, ""),
                                                            password: Belt_Option.getWithDefault(smtp.password, "")
                                                          }, /* :: */[
                                                            /* tuple */[
                                                              /* MailFrom */-819352223,
                                                              /* Required */0
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                /* Server */583778019,
                                                                /* Required */0
                                                              ],
                                                              /* :: */[
                                                                /* tuple */[
                                                                  /* Username */-90357194,
                                                                  /* Custom */[(function (param) {
                                                                        
                                                                      })]
                                                                ],
                                                                /* :: */[
                                                                  /* tuple */[
                                                                    /* Password */578936635,
                                                                    /* Custom */[(function (param) {
                                                                          
                                                                        })]
                                                                  ],
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ], undefined, (function (param) {
                                                              var getErrorForField = param.getErrorForField;
                                                              var handleSubmit = param.handleSubmit;
                                                              var handleChange = param.handleChange;
                                                              var form = param.form;
                                                              var msg = state.remote;
                                                              var tmp;
                                                              tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, true, [I18N$BsConsole.dynamic(msg[0])]));
                                                              var partial_arg = Curry._1(handleChange, /* MailFrom */-819352223);
                                                              var partial_arg$1 = Curry._1(handleChange, /* Server */583778019);
                                                              var partial_arg$2 = Curry._1(handleChange, /* Username */-90357194);
                                                              var partial_arg$3 = Curry._1(handleChange, /* Password */578936635);
                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit SMTP configuration")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                        tmp,
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* MailFrom */-819352223)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* MailFrom */-819352223), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.mail_from
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("mail_from")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Server */583778019)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Server */583778019), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.server
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("server")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Username */-90357194)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Username */-90357194), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.username
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("username")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Password */578936635)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Password */578936635), ""))),
                                                                                                  "password",
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.password
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("password")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  "smtp-password",
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ]))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      console.log(form.values);
                                                                                                      console.log(form.errors);
                                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  state.remote === /* Loading */1,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Save")]
                                                                                                ]))
                                                                                      ]))
                                                                            ]));
                                                            })));
                                          })))]
                            ]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var ModifySmtpDialog = {
  component: component$3,
  make: make$3
};

var component$4 = ReasonReact.statelessComponent("SettingsNetworkingPage-BsConsole__CreateSmtpDialog");

function make$4(onSuccess, token, handleTask, onClose, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Smtp.Create.make, (function (_result, _updated) {
                                            Curry._1(onSuccess, undefined);
                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.NewSmtpForm.make, (function (param) {
                                                              var values = param.values;
                                                              return Curry._1(handleSubmit, /* tuple */[
                                                                          {
                                                                            id: 0,
                                                                            mail_from: values.mail_from,
                                                                            server: values.server,
                                                                            cainfo: undefined,
                                                                            username: values.username.length !== 0 ? values.username : undefined,
                                                                            password: values.password.length !== 0 ? values.password : undefined,
                                                                            __state: undefined,
                                                                            __create: undefined,
                                                                            __modify: undefined
                                                                          },
                                                                          Util$BsConsole.identity,
                                                                          param.setError,
                                                                          param.setSubmitting,
                                                                          param.resetFormState
                                                                        ]);
                                                            }), undefined, undefined, {
                                                            mail_from: "",
                                                            server: "",
                                                            username: "",
                                                            password: ""
                                                          }, /* :: */[
                                                            /* tuple */[
                                                              /* MailFrom */-819352223,
                                                              /* Required */0
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                /* Server */583778019,
                                                                /* Required */0
                                                              ],
                                                              /* :: */[
                                                                /* tuple */[
                                                                  /* Username */-90357194,
                                                                  /* Custom */[(function (param) {
                                                                        
                                                                      })]
                                                                ],
                                                                /* :: */[
                                                                  /* tuple */[
                                                                    /* Password */578936635,
                                                                    /* Custom */[(function (param) {
                                                                          
                                                                        })]
                                                                  ],
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ], undefined, (function (param) {
                                                              var getErrorForField = param.getErrorForField;
                                                              var handleSubmit = param.handleSubmit;
                                                              var handleChange = param.handleChange;
                                                              var form = param.form;
                                                              var msg = state.remote;
                                                              var tmp;
                                                              tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, true, [I18N$BsConsole.dynamic(msg[0])]));
                                                              var partial_arg = Curry._1(handleChange, /* MailFrom */-819352223);
                                                              var partial_arg$1 = Curry._1(handleChange, /* Server */583778019);
                                                              var partial_arg$2 = Curry._1(handleChange, /* Username */-90357194);
                                                              var partial_arg$3 = Curry._1(handleChange, /* Password */578936635);
                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Enable SMTP")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                        tmp,
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* MailFrom */-819352223)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* MailFrom */-819352223), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.mail_from
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("mail_from")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Server */583778019)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Server */583778019), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.server
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("server")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Username */-90357194)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Username */-90357194), ""))),
                                                                                                  undefined,
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.username
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("username")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  (function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Password */578936635)),
                                                                                                  Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Password */578936635), ""))),
                                                                                                  "password",
                                                                                                  /* `String */[
                                                                                                    -976970511,
                                                                                                    form.values.password
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  true,
                                                                                                  Caml_option.some(I18N$BsConsole.showSkip("password")),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ]))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      console.log(form.values);
                                                                                                      console.log(form.errors);
                                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  state.remote === /* Loading */1,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Save")]
                                                                                                ]))
                                                                                      ]))
                                                                            ]));
                                                            })));
                                          })))]
                            ]));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var CreateSmtpDialog = {
  component: component$4,
  make: make$4
};

var component$5 = ReasonReact.statelessComponent("SettingListItem");

function make$5(label, value, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, Css.style(/* :: */[
                                  Css.justifyContent(/* spaceBetween */516682146),
                                  /* [] */0
                                ]), undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(value), Caml_option.some(label), [])),
                              ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                            ]));
            }),
          initialState: component$5.initialState,
          retainedProps: component$5.retainedProps,
          reducer: component$5.reducer,
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var SettingListItem = {
  component: component$5,
  make: make$5
};

var listItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var sectionLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA54)),
      /* [] */0
    ]);

var Styles = {
  listItem: listItem,
  paper: paper,
  sectionLabel: sectionLabel
};

var component$6 = ReasonReact.reducerComponent("SettingsNetworking");

function make$6(handleTask, token, handleChangeUrl, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                if (typeof match === "number") {
                  tmp = match === /* CreateSmtp */0 ? ReasonReact.element(undefined, undefined, make$4((function (_event) {
                                return Curry._1(self.send, /* BustSmtpCache */0);
                              }), token, handleTask, (function (_event) {
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), [])) : ReasonReact.element(undefined, undefined, make((function (_event) {
                                return Curry._1(self.send, /* BustSslCache */1);
                              }), token, handleTask, (function (_event) {
                                return Curry._1(self.send, /* Dialog */[undefined]);
                              }), []));
                } else {
                  switch (match.tag | 0) {
                    case /* ModifySmtp */0 :
                        tmp = ReasonReact.element(undefined, undefined, make$3((function (_event) {
                                    return Curry._1(self.send, /* BustSmtpCache */0);
                                  }), match[0], token, handleTask, (function (_event) {
                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                  }), []));
                        break;
                    case /* DeleteSmtp */1 :
                        tmp = ReasonReact.element(undefined, undefined, make$2((function (_event) {
                                    return Curry._1(self.send, /* BustSmtpCache */0);
                                  }), match[0], token, handleTask, (function (_event) {
                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                  }), []));
                        break;
                    case /* DeleteSsl */2 :
                        tmp = ReasonReact.element(undefined, undefined, make$1((function (_event) {
                                    return Curry._1(self.send, /* BustSmtpCache */0);
                                  }), match[0], token, handleTask, (function (_event) {
                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                  }), []));
                        break;
                    
                  }
                }
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              tmp,
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Basic")])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, paper, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(String(self.state.smtpCacheKey), undefined, Curry._4(BpgController$BsConsole.Smtp.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                              var results = state.remote;
                                                              if (typeof results !== "number" && !results.tag) {
                                                                var smtp = Belt_List.head(results[0]);
                                                                if (smtp !== undefined) {
                                                                  return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, undefined, undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "SMTP Server")), Caml_option.some(React.createElement("div", undefined, React.createElement("p", {
                                                                                                        style: {
                                                                                                          marginBottom: "0px"
                                                                                                        }
                                                                                                      }, I18N$BsConsole.showSkip(smtp.mail_from)), React.createElement("p", undefined, I18N$BsConsole.showSkip(smtp.server)))), [])),
                                                                                  ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                              return [
                                                                                                      ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                  Curry._1(self.send, /* Dialog */[/* ModifySmtp */Block.__(0, [smtp])]);
                                                                                                                  return Curry._1(onClose, undefined);
                                                                                                                }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Edit")])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                  Curry._1(self.send, /* Dialog */[/* DeleteSmtp */Block.__(1, [smtp])]);
                                                                                                                  return Curry._1(onClose, undefined);
                                                                                                                }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]))
                                                                                                    ];
                                                                                            }), undefined, []))
                                                                                ]));
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, (function (_event) {
                                                                                    return Curry._1(self.send, /* Dialog */[/* CreateSmtp */0]);
                                                                                  }), undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "SMTP Server")), Caml_option.some(I18N$BsConsole.show(undefined, "Not enabled")), [])),
                                                                                  ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                                ]));
                                                                }
                                                              }
                                                              return React.createElement("div", undefined, I18N$BsConsole.show(undefined, "loading..."));
                                                            })))]))])),
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Advanced")])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, paper, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [
                                                  ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Ssl.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                              var ssl = Curry._1(SslSingleton.handleRemote, state.remote);
                                                              var tmp;
                                                              if (typeof ssl === "number") {
                                                                switch (ssl) {
                                                                  case /* NotAsked */0 :
                                                                  case /* Loading */1 :
                                                                      tmp = I18N$BsConsole.showSkip("loading...");
                                                                      break;
                                                                  case /* SuccessEmpty */2 :
                                                                      tmp = I18N$BsConsole.show(undefined, "empty");
                                                                      break;
                                                                  
                                                                }
                                                              } else {
                                                                tmp = ssl.tag ? I18N$BsConsole.dynamic(ssl[0]) : I18N$BsConsole.showSkip(ssl[0].name);
                                                              }
                                                              var ssl$1 = Curry._1(SslSingleton.handleRemote, state.remote);
                                                              var tmp$1;
                                                              var exit = 0;
                                                              if (typeof ssl$1 === "number") {
                                                                switch (ssl$1) {
                                                                  case /* NotAsked */0 :
                                                                  case /* Loading */1 :
                                                                      exit = 1;
                                                                      break;
                                                                  case /* SuccessEmpty */2 :
                                                                      tmp$1 = ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                  return [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                      Curry._1(self.send, /* Dialog */[/* CreateSsl */1]);
                                                                                                      return Curry._1(onClose, undefined);
                                                                                                    }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "enable")]))];
                                                                                }), undefined, []));
                                                                      break;
                                                                  
                                                                }
                                                              } else if (ssl$1.tag) {
                                                                var msg = ssl$1[0];
                                                                tmp$1 = ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (_onClose) {
                                                                            return [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, true, undefined, [I18N$BsConsole.dynamic(msg)]))];
                                                                          }), undefined, []));
                                                              } else {
                                                                var ssl$2 = ssl$1[0];
                                                                tmp$1 = ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                            return [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                Curry._1(self.send, /* Dialog */[/* DeleteSsl */Block.__(2, [ssl$2])]);
                                                                                                return Curry._1(onClose, undefined);
                                                                                              }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]))];
                                                                          }), undefined, []));
                                                              }
                                                              if (exit === 1) {
                                                                tmp$1 = ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (_onClose) {
                                                                            return [
                                                                                    ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, true, undefined, [I18N$BsConsole.show(undefined, "Edit")])),
                                                                                    ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, true, undefined, [I18N$BsConsole.show(undefined, "Delete")]))
                                                                                  ];
                                                                          }), undefined, []));
                                                              }
                                                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "SSL certificate")), Caml_option.some(tmp), [])),
                                                                              tmp$1
                                                                            ]));
                                                            }))),
                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                  ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, (function (_event) {
                                                              return Curry._1(handleChangeUrl, /* SettingsNetworkingListeners */16);
                                                            }), undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Custom network listeners")), Caml_option.some(I18N$BsConsole.show(undefined, "Manage")), [])),
                                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                          ]))
                                                ]))]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      smtpCacheKey: 0,
                      sslCacheKey: 0
                    };
            }),
          retainedProps: component$6.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              smtpCacheKey: state.smtpCacheKey,
                              sslCacheKey: state.sslCacheKey + 1 | 0
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              smtpCacheKey: state.smtpCacheKey + 1 | 0,
                              sslCacheKey: state.sslCacheKey
                            }]);
                }
              } else {
                return /* Update */Block.__(0, [{
                            dialog: action[0],
                            smtpCacheKey: state.smtpCacheKey,
                            sslCacheKey: state.sslCacheKey
                          }]);
              }
            }),
          jsElementWrapped: component$6.jsElementWrapped
        };
}

exports.SslSingleton = SslSingleton;
exports.CreateSslDialog = CreateSslDialog;
exports.DeleteSslDialog = DeleteSslDialog;
exports.DeleteSmtpDialog = DeleteSmtpDialog;
exports.ModifySmtpDialog = ModifySmtpDialog;
exports.CreateSmtpDialog = CreateSmtpDialog;
exports.SettingListItem = SettingListItem;
exports.Styles = Styles;
exports.component = component$6;
exports.make = make$6;
/* SslSingleton Not a pure module */
