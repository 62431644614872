// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var UnityErrorPage$BsConsole = require("./UnityErrorPage.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var BacktraceErrorPage$BsConsole = require("./BacktraceErrorPage.js");

function GenericError(Props) {
  var error = Props.error;
  React.useEffect((function () {
          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "GenericError/useEffect0", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x, /* `string */[
                288368849,
                error
              ]);
          
        }), ([]));
  var errorSupportTextCallback = function (primaryMain) {
    var homeLink = Css.style(/* :: */[
          Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* [] */0
          ]
        ]);
    return React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Something has gone wrong! This error has been reported to Backtrace and we are working on it. Take me "), React.createElement("span", {
                    className: homeLink,
                    onClick: (function (param) {
                        return ReasonReactRouter.push("/");
                      })
                  }, I18N$BsConsole.show(undefined, "home")), I18N$BsConsole.show(undefined, "."));
  };
  if (IdentityVariant$BsConsole.current >= 2) {
    return React.createElement(UnityErrorPage$BsConsole.make, {
                errorMessage: I18N$BsConsole.show(undefined, "Unexpected error"),
                errorSupportText: I18N$BsConsole.show(undefined, "Something has gone wrong! This error has been reported and we are working on it.")
              });
  } else {
    return React.createElement(BacktraceErrorPage$BsConsole.make, {
                errorMessage: I18N$BsConsole.show(undefined, "Unexpected Error"),
                errorSupportTextCallback: errorSupportTextCallback
              });
  }
}

var component = ReasonReact.statelessComponent("GenericError");

function make(error, children) {
  return ReasonReactCompat.wrapReactForReasonReact(GenericError, {
              error: error
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var RR;

var make$1 = GenericError;

exports.RR = RR;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
