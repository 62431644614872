// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function display(context, token, string) {
  return string;
}

function wrap(context, token, string) {
  return string;
}

function displayf(context, format, string) {
  return string;
}

function wrapf(context, format, string) {
  return string;
}

var debugEnabled = false;

exports.debugEnabled = debugEnabled;
exports.display = display;
exports.wrap = wrap;
exports.displayf = displayf;
exports.wrapf = wrapf;
/* No side effect */
