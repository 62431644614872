// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugUtils$BsConsole = require("../debugger2/data/BugUtils.js");
var Classifiers$BsConsole = require("../format-renderers/Classifiers.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Tune = require("@material-ui/icons/Tune");
var Button = require("@material-ui/core/Button");
var SimilarityCandidate$BsConsole = require("./SimilarityCandidate.js");

function colorOfDiff(diff) {
  switch (diff) {
    case "Added" :
    case "added" :
        return Css.hex(Colors$BsConsole.yellowGreenA80);
    case "Deleted" :
    case "deleted" :
        return Css.hex(Colors$BsConsole.yellowA50);
    case "Moved" :
    case "moved" :
        return Css.hex(Colors$BsConsole.tealA30);
    case "No change" :
    case "nothing" :
        return Css.hex(Colors$BsConsole.greyA60);
    default:
      return Css.hex(Colors$BsConsole.greyA60);
  }
}

function SimilarityCallstackComparison$LegendChip(Props) {
  var diff = Props.diff;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.backgroundColor(colorOfDiff(diff)),
                    /* :: */[
                      Css.padding(Css.px(3)),
                      /* :: */[
                        Css.fontFamily("Inconsolata"),
                        /* :: */[
                          Css.fontSize(Css.px(13)),
                          /* :: */[
                            Css.marginRight(Css.px(4)),
                            /* :: */[
                              Css.padding2(Css.px(3), Css.px(4)),
                              /* :: */[
                                Css.borderRadius(Css.px(2)),
                                /* :: */[
                                  Css.display(/* inlineBlock */-147785676),
                                  /* :: */[
                                    Css.verticalAlign(/* middle */-866200747),
                                    /* :: */[
                                      Css.maxWidth(Css.px(250)),
                                      /* :: */[
                                        Css.whiteSpace(/* nowrap */867913355),
                                        /* :: */[
                                          Css.overflow(/* hidden */-862584982),
                                          /* :: */[
                                            Css.textOverflow(/* ellipsis */166888785),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, diff));
}

var LegendChip = {
  make: SimilarityCallstackComparison$LegendChip
};

function SimilarityCallstackComparison$DiffFrame(Props) {
  var text = Props.text;
  var diff = Props.diff;
  var index = Props.index;
  return React.createElement("div", {
              key: String(index),
              className: Css.style(/* :: */[
                    Css.backgroundColor(colorOfDiff(diff)),
                    /* :: */[
                      Css.padding(Css.px(3)),
                      /* :: */[
                        Css.fontFamily("Inconsolata"),
                        /* :: */[
                          Css.fontSize(Css.px(13)),
                          /* :: */[
                            Css.marginRight(Css.px(4)),
                            /* :: */[
                              Css.padding2(Css.px(3), Css.px(4)),
                              /* :: */[
                                Css.borderRadius(Css.px(2)),
                                /* :: */[
                                  Css.display(/* inlineBlock */-147785676),
                                  /* :: */[
                                    Css.verticalAlign(/* middle */-866200747),
                                    /* :: */[
                                      Css.maxWidth(Css.px(300)),
                                      /* :: */[
                                        Css.whiteSpace(/* nowrap */867913355),
                                        /* :: */[
                                          Css.overflow(/* hidden */-862584982),
                                          /* :: */[
                                            Css.textOverflow(/* ellipsis */166888785),
                                            /* :: */[
                                              Css.marginBottom(Css.px(5)),
                                              /* :: */[
                                                Css.flexShrink(0),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.showSkip(text));
}

var DiffFrame = {
  make: SimilarityCallstackComparison$DiffFrame
};

function detailUrl(fp, projectName) {
  return "/p/" + (projectName + ("/triage?time=all&fingerprint=" + fp));
}

function SimilarityCallstackComparison(Props) {
  var base = Props.base;
  var candidate = Props.candidate;
  var projectName = Props.projectName;
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.px(650)),
                      /* :: */[
                        Css.maxHeight(Css.px(400)),
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                        alignItems: /* baseline */287825029,
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.padding(Css.px(12)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle2 */7,
                            children: I18N$BsConsole.show(undefined, "Fingerprint comparison")
                          }), React.createElement("a", {
                            href: detailUrl(candidate.fingerprint, projectName),
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                onClick: (function (param) {
                                    
                                  }),
                                children: null
                              }, React.createElement(Tune.default, {
                                    className: Css.style(/* :: */[
                                          Css.marginRight(Css.px(5)),
                                          /* [] */0
                                        ])
                                  }), I18N$BsConsole.show(undefined, "Open candidate")))), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                  /* :: */[
                                    Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                    /* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.borderRight(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                          /* :: */[
                                            Css.height(Css.px(25)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* TableTitle */13,
                                          className: Css.style(/* :: */[
                                                Css.marginTop(Css.px(5)),
                                                /* [] */0
                                              ]),
                                          color: Colors$BsConsole.grey4,
                                          children: I18N$BsConsole.show(undefined, "Base: ")
                                        }), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body2 */9,
                                          className: Css.style(/* :: */[
                                                Css.marginTop(Css.px(5)),
                                                /* :: */[
                                                  Css.display(/* inline */423610969),
                                                  /* [] */0
                                                ]
                                              ]),
                                          color: Colors$BsConsole.grey1,
                                          children: I18N$BsConsole.showSkip(BugUtils$BsConsole.formatFingerprint(base.fingerprint))
                                        }))
                              }), React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.height(Css.px(25)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                children: React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* TableTitle */13,
                                          className: Css.style(/* :: */[
                                                Css.marginTop(Css.px(5)),
                                                /* [] */0
                                              ]),
                                          color: Colors$BsConsole.grey4,
                                          children: I18N$BsConsole.show(undefined, "Candidate: ")
                                        }), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body2 */9,
                                          className: Css.style(/* :: */[
                                                Css.marginTop(Css.px(5)),
                                                /* :: */[
                                                  Css.display(/* inline */423610969),
                                                  /* [] */0
                                                ]
                                              ]),
                                          color: Colors$BsConsole.grey1,
                                          children: I18N$BsConsole.showSkip(BugUtils$BsConsole.formatFingerprint(candidate.fingerprint))
                                        }))
                              })), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.borderRight(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                children: null
                              }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        className: Css.style(/* :: */[
                                              Css.marginTop(Css.px(5)),
                                              /* [] */0
                                            ]),
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Errors: ")
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.marginTop(Css.px(5)),
                                              /* :: */[
                                                Css.display(/* inline */423610969),
                                                /* [] */0
                                              ]
                                            ]),
                                        color: Colors$BsConsole.grey1,
                                        children: I18N$BsConsole.showSkip(String(base.count))
                                      })), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Date range: ")
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.display(/* inline */423610969),
                                              /* [] */0
                                            ]),
                                        color: Colors$BsConsole.grey1,
                                        children: I18N$BsConsole.showSkip(SimilarityCandidate$BsConsole.datesToDateString(base.dates))
                                      })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginBottom(Css.px(5)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Classifiers: ")
                                      }), React.createElement(Classifiers$BsConsole.make, {
                                        classifiers: Belt_List.toArray(Belt_List.sort(base.classifiers, (function (a, b) {
                                                    if (b.length > a.length) {
                                                      return 1;
                                                    } else {
                                                      return -1;
                                                    }
                                                  })))
                                      }))), React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(12)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ]
                                    ]),
                                children: null
                              }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        className: Css.style(/* :: */[
                                              Css.marginTop(Css.px(5)),
                                              /* :: */[
                                                Css.marginLeft(Css.px(12)),
                                                /* [] */0
                                              ]
                                            ]),
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Errors: ")
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.marginTop(Css.px(5)),
                                              /* :: */[
                                                Css.display(/* inline */423610969),
                                                /* [] */0
                                              ]
                                            ]),
                                        color: Colors$BsConsole.grey1,
                                        children: I18N$BsConsole.showSkip(String(candidate.count))
                                      })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(12)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Date range: ")
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.display(/* inline */423610969),
                                              /* [] */0
                                            ]),
                                        color: Colors$BsConsole.grey1,
                                        children: I18N$BsConsole.showSkip(SimilarityCandidate$BsConsole.datesToDateString(candidate.dates))
                                      })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginBottom(Css.px(5)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* TableTitle */13,
                                        className: Css.style(/* :: */[
                                              Css.marginLeft(Css.px(12)),
                                              /* [] */0
                                            ]),
                                        color: Colors$BsConsole.grey4,
                                        children: I18N$BsConsole.show(undefined, "Classifiers: ")
                                      }), React.createElement(Classifiers$BsConsole.make, {
                                        classifiers: Belt_List.toArray(Belt_List.sort(candidate.classifiers, (function (a, b) {
                                                    if (b.length > a.length) {
                                                      return 1;
                                                    } else {
                                                      return -1;
                                                    }
                                                  })))
                                      })))), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.height(Css.px(30)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* :: */[
                                        Css.marginRight(Css.px(5)),
                                        /* [] */0
                                      ]
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableTitle */13,
                                    color: Colors$BsConsole.grey4,
                                    children: I18N$BsConsole.show(undefined, "Legend: ")
                                  })), React.createElement(SimilarityCallstackComparison$LegendChip, {
                                diff: I18N$BsConsole.get(undefined, "No change")
                              }), React.createElement(SimilarityCallstackComparison$LegendChip, {
                                diff: I18N$BsConsole.get(undefined, "Added")
                              }), React.createElement(SimilarityCallstackComparison$LegendChip, {
                                diff: I18N$BsConsole.get(undefined, "Deleted")
                              }), React.createElement(SimilarityCallstackComparison$LegendChip, {
                                diff: I18N$BsConsole.get(undefined, "Moved")
                              })), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.maxHeight(Css.px(200)),
                                      /* :: */[
                                        Css.overflowX(/* hidden */-862584982),
                                        /* :: */[
                                          Css.overflowY(/* scroll */-949692403),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginLeft(Css.px(12)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.minHeight(Css.px(200)),
                                          /* :: */[
                                            Css.borderRight(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: null
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableTitle */13,
                                    className: Css.style(/* :: */[
                                          Css.marginTop(Css.px(5)),
                                          /* :: */[
                                            Css.marginBottom(Css.px(5)),
                                            /* [] */0
                                          ]
                                        ]),
                                    color: Colors$BsConsole.grey4,
                                    children: I18N$BsConsole.show(undefined, "Normalized callstack")
                                  }), Belt_List.toArray(Belt_List.mapWithIndex(candidate.mainCallstackDiff, (function (index, frame) {
                                          if (!frame) {
                                            return null;
                                          }
                                          var match = frame[1];
                                          if (match && !match[1]) {
                                            return React.createElement(SimilarityCallstackComparison$DiffFrame, {
                                                        text: frame[0],
                                                        diff: match[0],
                                                        index: index
                                                      });
                                          } else {
                                            return null;
                                          }
                                        })))), React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(0)),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ]
                                    ]),
                                children: null
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableTitle */13,
                                    className: Css.style(/* :: */[
                                          Css.marginTop(Css.px(5)),
                                          /* :: */[
                                            Css.marginBottom(Css.px(5)),
                                            /* :: */[
                                              Css.marginLeft(Css.px(12)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    color: Colors$BsConsole.grey4,
                                    children: I18N$BsConsole.show(undefined, "Normalized callstack")
                                  }), React.createElement(Col2$BsConsole.make, {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(12)),
                                          /* :: */[
                                            Css.maxHeight(Css.px(200)),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    children: Belt_List.toArray(Belt_List.mapWithIndex(candidate.candidateCallstackDiff, (function (index, frame) {
                                                if (!frame) {
                                                  return null;
                                                }
                                                var match = frame[1];
                                                if (match && !match[1]) {
                                                  return React.createElement(SimilarityCallstackComparison$DiffFrame, {
                                                              text: frame[0],
                                                              diff: match[0],
                                                              index: index
                                                            });
                                                } else {
                                                  return null;
                                                }
                                              })))
                                  })))))
            });
}

var make = SimilarityCallstackComparison;

exports.colorOfDiff = colorOfDiff;
exports.LegendChip = LegendChip;
exports.DiffFrame = DiffFrame;
exports.detailUrl = detailUrl;
exports.make = make;
/* Css Not a pure module */
