// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var component = ReasonReact.statelessComponent("Renderer_Date-BsConsole");

function formatDate(jsDate) {
  return L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate);
}

function showDate(jsDate) {
  return I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate));
}

function showPlainTextDiv(jsDate) {
  return React.createElement("div", {
              className: Styles$BsConsole.$$Text.data
            }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate)));
}

function showTitleSpan(jsDate) {
  return React.createElement("span", {
              className: Styles$BsConsole.$$Text.tooltipTitle
            }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate)));
}

function showButtonWrappedDate(jsDate, onClick) {
  var style = {
    fontWeight: "normal",
    textTransform: "none"
  };
  var __x = I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate));
  return ValueDrilldown$BsConsole.wrapWithButton(onClick, Caml_option.some(style), undefined, undefined, __x);
}

function make(attribute, value, handleAddFilters, handleChangeUrl, classNameOpt, plainTextViewOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var plainTextView = plainTextViewOpt !== undefined ? plainTextViewOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(className, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                                  /* tuple */[
                                                    attribute,
                                                    /* Equal */Block.__(0, [/* `Date */[
                                                          758940238,
                                                          value
                                                        ]])
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      attribute,
                                                      /* NotEqual */Block.__(1, [/* `Date */[
                                                            758940238,
                                                            value
                                                          ]])
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ], undefined, L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, value), undefined, undefined, (function (refHandler, onFocus, onClick) {
                                                    return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, showTitleSpan(value), undefined, undefined, 1000, 100, undefined, [React.createElement("div", {
                                                                          ref: refHandler,
                                                                          onFocus: onFocus,
                                                                          onClick: onClick
                                                                        }, plainTextView ? showPlainTextDiv(value) : showButtonWrappedDate(value, onClick))]));
                                                  }), undefined, undefined, []))]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.formatDate = formatDate;
exports.showDate = showDate;
exports.showPlainTextDiv = showPlainTextDiv;
exports.showTitleSpan = showTitleSpan;
exports.showButtonWrappedDate = showButtonWrappedDate;
exports.make = make;
/* component Not a pure module */
