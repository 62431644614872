// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Select = require("@material-ui/core/Select");
var FormControl = require("@material-ui/core/FormControl");

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* :: */[
        Css.selector(".value", /* :: */[
              Css.marginRight(Css.rem(1)),
              /* [] */0
            ]),
        /* :: */[
          Css.selector(".count", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey4)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var Style = {
  label: label,
  select: select,
  menuItem: menuItem,
  loading: loading
};

function BtOutlineSelect(Props) {
  var value = Props.value;
  var nameOpt = Props.name;
  var renderValue = Props.renderValue;
  var onChange = Props.onChange;
  var label$1 = Props.label;
  var classNameOpt = Props.className;
  var multiple = Props.multiple;
  var _open = Props.open;
  var onClose = Props.onClose;
  var onOpen = Props.onOpen;
  var displayEmpty = Props.displayEmpty;
  var menuPropsOpt = Props.menuProps;
  var children = Props.children;
  var name = nameOpt !== undefined ? nameOpt : "select";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var menuProps = menuPropsOpt !== undefined ? Caml_option.valFromOption(menuPropsOpt) : ({ });
  var tmp = {
    value: value,
    onChange: onChange,
    renderValue: renderValue,
    disableUnderline: true,
    classes: {
      select: Css.style(/* :: */[
            Css.paddingLeft(Css.px(12)),
            /* :: */[
              Css.paddingTop(Css.px(8)),
              /* :: */[
                Css.paddingBottom(Css.px(8)),
                /* [] */0
              ]
            ]
          ]),
      icon: Css.style(/* :: */[
            Css.right(Css.px(5)),
            /* [] */0
          ])
    },
    className: Css.merge(/* :: */[
          select,
          /* :: */[
            className,
            /* [] */0
          ]
        ]),
    variant: "outlined",
    name: name,
    MenuProps: Object.assign({
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          transitionDuration: 0,
          className: Css.style(/* :: */[
                Css.zIndex(1302),
                /* [] */0
              ])
        }, menuProps),
    children: children
  };
  if (_open !== undefined) {
    tmp.open = Caml_option.valFromOption(_open);
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  if (onOpen !== undefined) {
    tmp.onOpen = Caml_option.valFromOption(onOpen);
  }
  if (displayEmpty !== undefined) {
    tmp.displayEmpty = Caml_option.valFromOption(displayEmpty);
  }
  if (multiple !== undefined) {
    tmp.multiple = Caml_option.valFromOption(multiple);
  }
  return React.createElement(FormControl.default, {
              color: "primary",
              children: null
            }, React.createElement("label", {
                  className: label,
                  htmlFor: name
                }, I18N$BsConsole.show(undefined, label$1)), React.createElement(Select.default, tmp));
}

var make = BtOutlineSelect;

exports.Style = Style;
exports.make = make;
/* label Not a pure module */
