// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var ContainerWithDimensions$BsConsole = require("./ContainerWithDimensions.js");
var ResponsiveReactGridLayout = require("react-grid-layout/build/ResponsiveReactGridLayout");

((require('./static/react_grid_layout.css')));

function make(i, w, h, x, y, minW, maxW, minH, maxH, isStatic, isDraggable, isResizable, param) {
  return {
          i: i,
          w: w,
          h: h,
          x: x,
          y: y,
          minW: minW,
          maxW: maxW,
          minH: minH,
          maxH: maxH,
          isStatic: isStatic,
          isDraggable: isDraggable,
          isResizable: isResizable
        };
}

function toJs(item) {
  return {
          i: item.i,
          w: item.w,
          h: item.h,
          x: item.x,
          y: item.y,
          minW: Js_null_undefined.fromOption(item.minW),
          maxW: Js_null_undefined.fromOption(item.maxW),
          minH: Js_null_undefined.fromOption(item.minH),
          maxH: Js_null_undefined.fromOption(item.maxH),
          static: Js_null_undefined.fromOption(item.isStatic),
          isDraggable: Js_null_undefined.fromOption(Caml_obj.caml_equal(item.isStatic, true) ? false : item.isDraggable),
          isResizable: Js_null_undefined.fromOption(Caml_obj.caml_equal(item.isStatic, true) ? false : item.isResizable)
        };
}

function ofJson(json) {
  return {
          i: Json_decode.field("i", Json_decode.string, json),
          w: Json_decode.field("w", Json_decode.$$int, json),
          h: Json_decode.field("h", Json_decode.$$int, json),
          x: Json_decode.field("x", Json_decode.$$int, json),
          y: Json_decode.field("y", Json_decode.$$int, json),
          minW: Json_decode.optional((function (param) {
                  return Json_decode.field("minW", Json_decode.$$int, param);
                }), json),
          maxW: Json_decode.optional((function (param) {
                  return Json_decode.field("maxW", Json_decode.$$int, param);
                }), json),
          minH: Json_decode.optional((function (param) {
                  return Json_decode.field("minH", Json_decode.$$int, param);
                }), json),
          maxH: Json_decode.optional((function (param) {
                  return Json_decode.field("maxH", Json_decode.$$int, param);
                }), json),
          isStatic: Json_decode.optional((function (param) {
                  return Json_decode.field("isStatic", Json_decode.bool, param);
                }), json),
          isDraggable: Json_decode.optional((function (param) {
                  return Json_decode.field("isDraggable", Json_decode.bool, param);
                }), json),
          isResizable: Json_decode.optional((function (param) {
                  return Json_decode.field("isResizable", Json_decode.bool, param);
                }), json)
        };
}

var Item = {
  make: make,
  toJs: toJs,
  ofJson: ofJson
};

var Layout = {
  Item: Item
};

var Responsive = { };

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flex(/* `num */[
              5496390,
              1
            ]),
        /* [] */0
      ]
    ]);

var gridLayout = Css.merge(/* :: */[
      "layout",
      /* :: */[
        Css.style(/* :: */[
              Css.position(/* absolute */-1013592457),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var item = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* [] */0
    ]);

var Style = {
  container: container,
  gridLayout: gridLayout,
  item: item
};

var defaultMargins = Js_dict.fromList(/* :: */[
      /* tuple */[
        "xs",
        /* tuple */[
          16,
          16
        ]
      ],
      /* :: */[
        /* tuple */[
          "sm",
          /* tuple */[
            16,
            16
          ]
        ],
        /* :: */[
          /* tuple */[
            "md",
            /* tuple */[
              16,
              16
            ]
          ],
          /* :: */[
            /* tuple */[
              "lg",
              /* tuple */[
                16,
                16
              ]
            ],
            /* [] */0
          ]
        ]
      ]
    ]);

function ReactGridLayout$ResponsiveWithSizeDetection(Props) {
  var layouts = Props.layouts;
  var cols = Props.cols;
  var rowHeight = Props.rowHeight;
  var breakpoints = Props.breakpoints;
  var onLayoutChangeOpt = Props.onLayoutChange;
  var isDraggableOpt = Props.isDraggable;
  var isResizableOpt = Props.isResizable;
  var marginOpt = Props.margin;
  var children = Props.children;
  var onLayoutChange = onLayoutChangeOpt !== undefined ? onLayoutChangeOpt : (function (param, param$1) {
        
      });
  var isDraggable = isDraggableOpt !== undefined ? isDraggableOpt : false;
  var isResizable = isResizableOpt !== undefined ? isResizableOpt : false;
  var margin = marginOpt !== undefined ? Caml_option.valFromOption(marginOpt) : defaultMargins;
  return React.createElement(ContainerWithDimensions$BsConsole.Jsx3.make, {
              className: container,
              children: (function (width, param) {
                  return React.createElement(ResponsiveReactGridLayout.default, {
                              className: "layout " + Css.style(/* :: */[
                                    Css.position(/* relative */903134412),
                                    /* [] */0
                                  ]),
                              rowHeight: rowHeight,
                              isDraggable: isDraggable,
                              isResizable: isResizable,
                              cols: cols,
                              breakpoints: breakpoints,
                              layouts: layouts,
                              width: width,
                              margin: margin,
                              onLayoutChange: Curry.__2(onLayoutChange),
                              useCSSTransforms: true,
                              children: children
                            });
                })
            });
}

var ResponsiveWithSizeDetection = {
  make: ReactGridLayout$ResponsiveWithSizeDetection
};

exports.Layout = Layout;
exports.Responsive = Responsive;
exports.Style = Style;
exports.defaultMargins = defaultMargins;
exports.ResponsiveWithSizeDetection = ResponsiveWithSizeDetection;
/*  Not a pure module */
