// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function get(uri) {
  fetch(uri).then((function (prim) {
              return prim.json();
            })).then((function (_response) {
            return Promise.resolve(undefined);
          })).catch((function (_error) {
          return Promise.resolve(undefined);
        }));
  
}

exports.get = get;
/* No side effect */
