// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");
var BugTreeValueRow$BsConsole = require("./BugTreeValueRow.js");
var Collapse = require("@material-ui/core/Collapse");

function make(props) {
  var node = props.node;
  var defaultExpanded = props.defaultExpanded;
  var defaultExpanded$1 = defaultExpanded === undefined ? undefined : Caml_option.some(defaultExpanded);
  var closeIndentation = props.closeIndentation;
  var match = React.useState((function () {
          return defaultExpanded$1;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var rootCloseIndentation = Caml_obj.caml_equal(expanded, true) ? 0 : closeIndentation;
  var childCount = node.children.length;
  return React.createElement(React.Fragment, undefined, React.createElement(BugTreeValueRow$BsConsole.make, {
                  node: node,
                  closeIndentation: rootCloseIndentation,
                  onToggleExpanded: (function (param) {
                      return Curry._1(setExpanded, (function (expanded) {
                                    if (expanded !== undefined) {
                                      return !expanded;
                                    } else {
                                      setTimeout((function (param) {
                                              return Curry._1(setExpanded, (function (param) {
                                                            return true;
                                                          }));
                                            }), 0);
                                      return false;
                                    }
                                  }));
                    }),
                  expanded: Caml_obj.caml_equal(expanded, true)
                }), expanded !== undefined ? React.createElement(Collapse.default, {
                    in: expanded,
                    timeout: 250,
                    children: React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ])
                        }, Belt_Array.mapWithIndex(node.children, (function (idx, node) {
                                var tmp = {
                                  node: CamlinternalLazy.force(node),
                                  closeIndentation: idx === (childCount - 1 | 0) ? closeIndentation + 1 | 0 : 0
                                };
                                if (defaultExpanded$1 !== undefined) {
                                  tmp.defaultExpanded = Caml_option.valFromOption(defaultExpanded$1);
                                }
                                return React.createElement(make, tmp);
                              }))),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }) : null);
}

var $$Node = {
  make: make
};

function BugTree(Props) {
  var defaultExpanded = Props.defaultExpanded;
  var node = Props.node;
  var tmp = {
    node: node,
    closeIndentation: 0
  };
  if (defaultExpanded !== undefined) {
    tmp.defaultExpanded = Caml_option.valFromOption(defaultExpanded);
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontFamily("Inconsolata"),
                    /* :: */[
                      Css.fontSize(Css.px(12)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(make, tmp));
}

var make$1 = BugTree;

exports.$$Node = $$Node;
exports.make = make$1;
/* Css Not a pure module */
