// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactBeautifulDnd = require("react-beautiful-dnd");

function make(onDragEnd, children) {
  return ReasonReact.wrapJsForReason(ReactBeautifulDnd.DragDropContext, {
              onDragEnd: onDragEnd
            }, children);
}

var Jsx3 = { };

var DragDropContext = {
  make: make,
  Jsx3: Jsx3
};

function make$1(droppableId, children) {
  return ReasonReact.wrapJsForReason(ReactBeautifulDnd.Droppable, {
              droppableId: droppableId
            }, children);
}

var Jsx3$1 = { };

var Droppable = {
  make: make$1,
  Jsx3: Jsx3$1
};

function make$2(draggableId, index, isDragDisabled, children) {
  return ReasonReact.wrapJsForReason(ReactBeautifulDnd.Draggable, {
              draggableId: draggableId,
              index: index,
              isDragDisabled: isDragDisabled
            }, children);
}

var Jsx3$2 = { };

var Draggable = {
  make: make$2,
  Jsx3: Jsx3$2
};

exports.DragDropContext = DragDropContext;
exports.Droppable = Droppable;
exports.Draggable = Draggable;
/* ReasonReact Not a pure module */
