// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Map = require("bs-platform/lib/js/belt_Map.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

function Make(Form) {
  var cmp = Caml_obj.caml_compare;
  var FieldId = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var getInitialState = function (input) {
    return {
            input: input,
            status: /* Editing */0,
            fields: Belt_List.reduce(Form.validators, Belt_Map.make(FieldId), (function (fields, validator) {
                    return Belt_Map.set(fields, validator.field, /* Pristine */0);
                  })),
            validators: {
              contents: Belt_List.reduce(Form.validators, Belt_Map.make(FieldId), (function (fields, validator) {
                      return Belt_Map.set(fields, validator.field, validator);
                    }))
            },
            submittedOnce: false
          };
  };
  var component = ReasonReact.reducerComponent("Formality.Form");
  var make = function (initialState, onSubmit, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (param) {
                var send = param.send;
                var state = param.state;
                var match = state.status;
                return Curry._1(children, {
                            state: state.input,
                            status: state.status,
                            result: (function (field) {
                                var match = Belt_Map.get(state.fields, field);
                                if (match !== undefined && match && !match[1]) {
                                  return match[0];
                                }
                                
                              }),
                            dirty: (function (param) {
                                return Belt_Map.some(state.fields, (function (param, status) {
                                              if (status) {
                                                return true;
                                              } else {
                                                return false;
                                              }
                                            }));
                              }),
                            valid: (function (param) {
                                return Belt_Map.every(state.fields, (function (field, status) {
                                              if (status) {
                                                if (status[0].tag) {
                                                  return false;
                                                } else {
                                                  return true;
                                                }
                                              } else {
                                                return Belt_Option.getWithDefault(Belt_Option.map(Belt_Map.get(state.validators.contents, field), (function (validator) {
                                                                  var match = Curry._1(validator.validate, state.input);
                                                                  if (match.tag) {
                                                                    return false;
                                                                  } else {
                                                                    return true;
                                                                  }
                                                                })), true);
                                              }
                                            }));
                              }),
                            submitting: typeof match === "number" ? match === 1 : false,
                            change: (function (field, state) {
                                return Curry._1(send, /* Change */Block.__(0, [
                                              field,
                                              state
                                            ]));
                              }),
                            blur: (function (field) {
                                return Curry._1(send, /* Blur */Block.__(1, [field]));
                              }),
                            submit: (function (param) {
                                return Curry._1(send, /* Submit */0);
                              }),
                            dismissSubmissionResult: (function (param) {
                                return Curry._1(send, /* DismissSubmissionResult */1);
                              })
                          });
              }),
            initialState: (function (param) {
                return getInitialState(initialState);
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, state) {
                if (typeof action === "number") {
                  switch (action) {
                    case /* Submit */0 :
                        var match = state.status;
                        if (typeof match === "number" && match === 1) {
                          return /* NoUpdate */0;
                        }
                        var match$1 = Belt_Map.reduce(state.validators.contents, /* tuple */[
                              true,
                              state.fields
                            ], (function (param, field, validator) {
                                var result = Curry._1(validator.validate, state.input);
                                var fields = Belt_Map.set(param[1], field, /* Dirty */[
                                      result,
                                      /* Shown */0
                                    ]);
                                if (param[0] && !result.tag) {
                                  return /* tuple */[
                                          true,
                                          fields
                                        ];
                                } else {
                                  return /* tuple */[
                                          false,
                                          fields
                                        ];
                                }
                              }));
                        var fields = match$1[1];
                        if (match$1[0]) {
                          return /* UpdateWithSideEffects */Block.__(2, [
                                    {
                                      input: state.input,
                                      status: /* Submitting */1,
                                      fields: fields,
                                      validators: state.validators,
                                      submittedOnce: true
                                    },
                                    (function (param) {
                                        var send = param.send;
                                        return Curry._2(onSubmit, param.state.input, {
                                                    notifyOnSuccess: (function (data) {
                                                        return Curry._1(send, /* SetSubmittedStatus */Block.__(2, [data]));
                                                      }),
                                                    notifyOnFailure: (function (fieldLevelErrors, serverMessage) {
                                                        return Curry._1(send, /* SetSubmissionFailedStatus */Block.__(3, [
                                                                      fieldLevelErrors,
                                                                      serverMessage
                                                                    ]));
                                                      }),
                                                    reset: (function (param) {
                                                        return Curry._1(send, /* Reset */2);
                                                      })
                                                  });
                                      })
                                  ]);
                        } else {
                          return /* Update */Block.__(0, [{
                                      input: state.input,
                                      status: /* Editing */0,
                                      fields: fields,
                                      validators: state.validators,
                                      submittedOnce: true
                                    }]);
                        }
                    case /* DismissSubmissionResult */1 :
                        var match$2 = state.status;
                        if (typeof match$2 === "number" && match$2 < 2) {
                          return /* NoUpdate */0;
                        }
                        return /* Update */Block.__(0, [{
                                    input: state.input,
                                    status: /* Editing */0,
                                    fields: state.fields,
                                    validators: state.validators,
                                    submittedOnce: state.submittedOnce
                                  }]);
                    case /* Reset */2 :
                        return /* Update */Block.__(0, [getInitialState(initialState)]);
                    
                  }
                } else {
                  switch (action.tag | 0) {
                    case /* Change */0 :
                        var input = action[1];
                        var field = action[0];
                        var validator = Belt_Map.get(state.validators.contents, field);
                        if (validator === undefined) {
                          return /* Update */Block.__(0, [{
                                      input: input,
                                      status: state.status,
                                      fields: Belt_Map.set(state.fields, field, /* Dirty */[
                                            /* Ok */Block.__(0, [/* Valid */0]),
                                            /* Hidden */1
                                          ]),
                                      validators: state.validators,
                                      submittedOnce: state.submittedOnce
                                    }]);
                        }
                        var status = Belt_Map.get(state.fields, field);
                        var result = Curry._1(validator.validate, input);
                        var dependents = validator.dependents;
                        var fields$1 = dependents !== undefined ? Belt_List.reduce(dependents, state.fields, (function (fields, field) {
                                  var status = Belt_Map.get(fields, field);
                                  if (status === undefined) {
                                    return fields;
                                  }
                                  if (!status) {
                                    return fields;
                                  }
                                  if (status[1]) {
                                    return fields;
                                  }
                                  var validator = Belt_Map.getExn(state.validators.contents, field);
                                  return Belt_Map.set(fields, field, /* Dirty */[
                                              Curry._1(validator.validate, input),
                                              /* Shown */0
                                            ]);
                                })) : state.fields;
                        var match$3 = validator.strategy;
                        var match$4 = state.submittedOnce;
                        var exit = 0;
                        var exit$1 = 0;
                        if (status !== undefined && status && !status[1]) {
                          exit = 3;
                        } else {
                          exit$1 = 4;
                        }
                        if (exit$1 === 4) {
                          if (match$4) {
                            exit = 3;
                          } else {
                            switch (match$3) {
                              case /* OnFirstChange */1 :
                                  exit = 3;
                                  break;
                              case /* OnFirstSuccess */2 :
                              case /* OnFirstSuccessOrFirstBlur */3 :
                                  exit = 1;
                                  break;
                              case /* OnFirstBlur */0 :
                              case /* OnSubmit */4 :
                                  exit = 2;
                                  break;
                              
                            }
                          }
                        }
                        switch (exit) {
                          case 1 :
                              var tmp;
                              tmp = result.tag ? Belt_Map.set(fields$1, field, /* Dirty */[
                                      result,
                                      /* Hidden */1
                                    ]) : Belt_Map.set(fields$1, field, /* Dirty */[
                                      result,
                                      /* Shown */0
                                    ]);
                              return /* Update */Block.__(0, [{
                                          input: input,
                                          status: state.status,
                                          fields: tmp,
                                          validators: state.validators,
                                          submittedOnce: state.submittedOnce
                                        }]);
                          case 2 :
                              return /* Update */Block.__(0, [{
                                          input: input,
                                          status: state.status,
                                          fields: Belt_Map.set(fields$1, field, /* Dirty */[
                                                result,
                                                /* Hidden */1
                                              ]),
                                          validators: state.validators,
                                          submittedOnce: state.submittedOnce
                                        }]);
                          case 3 :
                              return /* Update */Block.__(0, [{
                                          input: input,
                                          status: state.status,
                                          fields: Belt_Map.set(fields$1, field, /* Dirty */[
                                                result,
                                                /* Shown */0
                                              ]),
                                          validators: state.validators,
                                          submittedOnce: state.submittedOnce
                                        }]);
                          
                        }
                        break;
                    case /* Blur */1 :
                        var field$1 = action[0];
                        var status$1 = Belt_Map.get(state.fields, field$1);
                        var validator$1 = Belt_Map.get(state.validators.contents, field$1);
                        var exit$2 = 0;
                        if (status$1 !== undefined && status$1) {
                          if (!status$1[1]) {
                            return /* NoUpdate */0;
                          }
                          if (validator$1 === undefined) {
                            return /* NoUpdate */0;
                          }
                          var result$1 = Curry._1(validator$1.validate, state.input);
                          var match$5 = validator$1.strategy;
                          var exit$3 = 0;
                          if (match$5 !== 3) {
                            if (match$5 !== 0) {
                              return /* Update */Block.__(0, [{
                                          input: state.input,
                                          status: state.status,
                                          fields: Belt_Map.set(state.fields, field$1, /* Dirty */[
                                                result$1,
                                                /* Hidden */1
                                              ]),
                                          validators: state.validators,
                                          submittedOnce: state.submittedOnce
                                        }]);
                            }
                            exit$3 = 2;
                          } else {
                            exit$3 = 2;
                          }
                          if (exit$3 === 2) {
                            return /* Update */Block.__(0, [{
                                        input: state.input,
                                        status: state.status,
                                        fields: Belt_Map.set(state.fields, field$1, /* Dirty */[
                                              result$1,
                                              /* Shown */0
                                            ]),
                                        validators: state.validators,
                                        submittedOnce: state.submittedOnce
                                      }]);
                          }
                          
                        } else {
                          exit$2 = 1;
                        }
                        if (exit$2 === 1) {
                          if (validator$1 === undefined) {
                            return /* Update */Block.__(0, [{
                                        input: state.input,
                                        status: state.status,
                                        fields: Belt_Map.set(state.fields, field$1, /* Dirty */[
                                              /* Ok */Block.__(0, [/* Valid */0]),
                                              /* Hidden */1
                                            ]),
                                        validators: state.validators,
                                        submittedOnce: state.submittedOnce
                                      }]);
                          }
                          var result$2 = Curry._1(validator$1.validate, state.input);
                          var match$6 = validator$1.strategy;
                          var exit$4 = 0;
                          if (match$6 !== 3) {
                            if (match$6 !== 0) {
                              return /* Update */Block.__(0, [{
                                          input: state.input,
                                          status: state.status,
                                          fields: Belt_Map.set(state.fields, field$1, /* Dirty */[
                                                result$2,
                                                /* Hidden */1
                                              ]),
                                          validators: state.validators,
                                          submittedOnce: state.submittedOnce
                                        }]);
                            }
                            exit$4 = 2;
                          } else {
                            exit$4 = 2;
                          }
                          if (exit$4 === 2) {
                            return /* Update */Block.__(0, [{
                                        input: state.input,
                                        status: state.status,
                                        fields: Belt_Map.set(state.fields, field$1, /* Dirty */[
                                              result$2,
                                              /* Shown */0
                                            ]),
                                        validators: state.validators,
                                        submittedOnce: state.submittedOnce
                                      }]);
                          }
                          
                        }
                        break;
                    case /* SetSubmittedStatus */2 :
                        var data = action[0];
                        if (data !== undefined) {
                          return /* Update */Block.__(0, [{
                                      input: Caml_option.valFromOption(data),
                                      status: /* Submitted */2,
                                      fields: state.fields,
                                      validators: state.validators,
                                      submittedOnce: state.submittedOnce
                                    }]);
                        } else {
                          return /* Update */Block.__(0, [{
                                      input: state.input,
                                      status: /* Submitted */2,
                                      fields: state.fields,
                                      validators: state.validators,
                                      submittedOnce: state.submittedOnce
                                    }]);
                        }
                    case /* SetSubmissionFailedStatus */3 :
                        return /* Update */Block.__(0, [{
                                    input: state.input,
                                    status: /* SubmissionFailed */[
                                      action[0],
                                      action[1]
                                    ],
                                    fields: state.fields,
                                    validators: state.validators,
                                    submittedOnce: state.submittedOnce
                                  }]);
                    
                  }
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          FieldId: FieldId,
          getInitialState: getInitialState,
          component: component,
          make: make
        };
}

var React;

var Validation;

var Strategy;

var FormStatus;

exports.React = React;
exports.Validation = Validation;
exports.Strategy = Strategy;
exports.FormStatus = FormStatus;
exports.Make = Make;
/* ReasonReact Not a pure module */
