// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var I18N_report$BsConsole = require("./I18N_report.js");
var I18N_language_code$BsConsole = require("./I18N_language_code.js");

var en = (require('./i18n/console.en.po.json'));

var ja = (require('./i18n/console.ja.po.json'));

var ang = (require('./i18n/console.ang.po.json'));

var ko = (require('./i18n/ko.po.json'));

var pl = (require('./i18n/pl.po.json'));

function fromJson(json) {
  return {
          id: Json_decode.field("msgid", Json_decode.string, json),
          context: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("msgctxt", Json_decode.string, param);
                    }), json), ""),
          str: Json_decode.field("msgstr", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function getString(param) {
  var __x = Belt_Array.get(param.str, 0);
  return Belt_Option.flatMap(__x, (function (s) {
                if (s === "") {
                  return ;
                } else {
                  return s;
                }
              }));
}

var Message = {
  fromJson: fromJson,
  getString: getString
};

function fromJson$1(json) {
  return Json_decode.dict(fromJson, json);
}

var getMessage = Js_dict.get;

var Context = {
  fromJson: fromJson$1,
  getMessage: getMessage
};

function fromJson$2(json) {
  return {
          charset: Json_decode.field("charset", Json_decode.string, json),
          headers: Json_decode.optional((function (param) {
                  return Json_decode.field("headers", (function (param) {
                                return Json_decode.dict(Json_decode.string, param);
                              }), param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.dict(fromJson$1, param);
                }), json)
        };
}

function getContext(param, context) {
  return Js_dict.get(param.translations, context);
}

function getMessage$1(t, context, key) {
  var __x = getContext(t, context);
  return Belt_Option.flatMap(__x, (function (context) {
                return Js_dict.get(context, key);
              }));
}

var cached = {
  contents: undefined
};

function toCache(file) {
  cached.contents = file;
  return cached.contents;
}

function load(t) {
  var json;
  switch (t) {
    case /* English */0 :
        json = en;
        break;
    case /* Japanese */1 :
        json = ja;
        break;
    case /* Korean */2 :
        json = ko;
        break;
    case /* OldEnglish */3 :
        json = ang;
        break;
    case /* Polish */4 :
        json = pl;
        break;
    
  }
  var file;
  try {
    file = fromJson$2(json);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Json_decode.DecodeError) {
      var e$1 = e[1];
      I18N_report$BsConsole.language_load_failure(I18N_language_code$BsConsole.toString(t), e$1);
      console.log("Failed to load translation file:", e$1);
      file = undefined;
    } else {
      throw e;
    }
  }
  return Belt_Option.flatMap(file, toCache);
}

function fromCache(language) {
  var file = cached.contents;
  if (file !== undefined) {
    return file;
  } else {
    return load(language);
  }
}

var LanguageCode;

var Report;

exports.LanguageCode = LanguageCode;
exports.Report = Report;
exports.en = en;
exports.ja = ja;
exports.ang = ang;
exports.ko = ko;
exports.pl = pl;
exports.Message = Message;
exports.Context = Context;
exports.fromJson = fromJson$2;
exports.getContext = getContext;
exports.getMessage = getMessage$1;
exports.cached = cached;
exports.toCache = toCache;
exports.load = load;
exports.fromCache = fromCache;
/* en Not a pure module */
