// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeDefects(json) {
  return {
          fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
          errorMessage: Json_decode.optional((function (param) {
                  return Json_decode.field("errorMessage", Json_decode.string, param);
                }), json),
          functionName: Json_decode.optional((function (param) {
                  return Json_decode.field("functionName", Json_decode.string, param);
                }), json),
          fileName: Json_decode.optional((function (param) {
                  return Json_decode.field("fileName", Json_decode.string, param);
                }), json),
          lineNumber: Json_decode.optional((function (param) {
                  return Json_decode.field("lineNumber", Json_decode.string, param);
                }), json),
          build: Json_decode.optional((function (param) {
                  return Json_decode.field("build", Json_decode.string, param);
                }), json),
          project: Json_decode.field("project", Json_decode.string, json),
          classifiers: Json_decode.optional((function (param) {
                  return Json_decode.field("classifiers", Json_decode.string, param);
                }), json)
        };
}

exports.decodeDefects = decodeDefects;
/* No side effect */
