// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Bpg$BsConsole = require("./bpg.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");

function Make(E) {
  var component = ReasonReact.reducerComponent("BpgView-BsConsole");
  var make = function (token, handleTask, _children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: (function (self) {
                return Curry._1(self.send, /* Request */0);
              }),
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var items = self.state.remote;
                var tmp;
                if (typeof items === "number") {
                  tmp = items === /* NotAsked */0 ? null : React.createElement("div", undefined, I18N$BsConsole.show(undefined, "loading ..."));
                } else if (items.tag) {
                  tmp = React.createElement("div", undefined, I18N$BsConsole.showSkip(items[0]));
                } else {
                  var items$1 = items[0];
                  tmp = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [$$Array.of_list(List.map((function (item) {
                                                  return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [$$Array.append($$Array.map((function (param) {
                                                                              return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(param[0])]));
                                                                            }), $$Array.of_list(List.filter((function (param) {
                                                                                        return param[3] !== /* None */1;
                                                                                      }))(Curry._1(E.toTupleList, item)))), [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))])]));
                                                }), /* :: */[
                                                List.hd(items$1),
                                                /* [] */0
                                              ]))])),
                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [$$Array.sub($$Array.of_list(List.mapi((function (i, item) {
                                                      return ReasonReact.element(String(i), undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [$$Array.append($$Array.map((function (field) {
                                                                                  return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Bpg$BsConsole.formatValueToString(field))]));
                                                                                }), $$Array.of_list(List.filter((function (param) {
                                                                                            return param[3] !== /* None */1;
                                                                                          }))(Curry._1(E.toTupleList, item)))), [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (param) {
                                                                                                  return [
                                                                                                          ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Edit")])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]))
                                                                                                        ];
                                                                                                }), undefined, []))]))])]));
                                                    }), items$1)), Caml_int32.imul(self.state.page, self.state.rowsPerPage) > List.length(items$1) ? List.length(items$1) - self.state.rowsPerPage | 0 : Caml_int32.imul(self.state.page, self.state.rowsPerPage), (self.state.rowsPerPage + Caml_int32.imul(self.state.page, self.state.rowsPerPage) | 0) > List.length(items$1) ? Math.max(0, List.length(items$1) - Caml_int32.imul(self.state.page, self.state.rowsPerPage) | 0) : self.state.rowsPerPage)])),
                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                (function (param, page) {
                                                    return Curry._1(self.send, /* ChangePage */Block.__(0, [page]));
                                                  }),
                                                undefined,
                                                (function ($$event) {
                                                    return Curry._1(self.send, /* ChangeRowsPerPage */Block.__(1, [$$event.target.value]));
                                                  }),
                                                /* `Int */[
                                                  3654863,
                                                  self.state.page
                                                ],
                                                undefined,
                                                /* `Int */[
                                                  3654863,
                                                  List.length(items$1)
                                                ],
                                                /* `Int */[
                                                  3654863,
                                                  self.state.rowsPerPage
                                                ],
                                                /* `Int */[
                                                  3654863,
                                                  6
                                                ],
                                                undefined,
                                                undefined,
                                                []
                                              ]))]))
                          ]));
                }
                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                    Css.marginTop(Css.em(1)),
                                    /* [] */0
                                  ]), undefined, undefined, 0, [tmp]));
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0,
                        page: 0,
                        rowsPerPage: 5
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, state) {
                if (typeof action === "number") {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1,
                              page: state.page,
                              rowsPerPage: state.rowsPerPage
                            },
                            (function (self) {
                                return Curry._2(handleTask, (function (json) {
                                              var match = List.hd(Curry._1(E.decodeBPG, json));
                                              if (match[0] !== 0) {
                                                return Curry._1(self.send, /* Failure */Block.__(3, [match[1]]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(2, [match[2]]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    Curry._1(E.toJSON, Curry._1(E.get, undefined)),
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                }
                switch (action.tag | 0) {
                  case /* ChangePage */0 :
                      return /* Update */Block.__(0, [{
                                  remote: state.remote,
                                  page: action[0],
                                  rowsPerPage: state.rowsPerPage
                                }]);
                  case /* ChangeRowsPerPage */1 :
                      return /* Update */Block.__(0, [{
                                  remote: state.remote,
                                  page: state.page,
                                  rowsPerPage: action[0]
                                }]);
                  case /* Success */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Success */Block.__(0, [action[0]]),
                                  page: state.page,
                                  rowsPerPage: state.rowsPerPage
                                }]);
                  case /* Failure */3 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]]),
                                  page: state.page,
                                  rowsPerPage: state.rowsPerPage
                                }]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          component: component,
          make: make
        };
}

exports.Make = Make;
/* Css Not a pure module */
