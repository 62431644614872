// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");

var ticketButton = Css.style(/* :: */[
      Css.position(/* unset */653193961),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.marginRight(Css.px(5)),
          /* :: */[
            Css.selector(":first-of-type", /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* :: */[
                    Css.whiteSpace(/* nowrap */867913355),
                    /* :: */[
                      Css.maxWidth(Css.pct(100)),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var ticket = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
      /* :: */[
        Css.padding2(Css.px(3), Css.px(0)),
        /* :: */[
          Css.paddingRight(Css.px(4)),
          /* :: */[
            Css.selector(":hover", /* :: */[
                  Css.important(Css.textDecoration(/* underline */131142924)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* [] */0
                  ]
                ]),
            /* :: */[
              Css.display(/* inlineBlock */-147785676),
              /* :: */[
                Css.verticalAlign(/* middle */-866200747),
                /* :: */[
                  Css.unsafe("fontFamily", "inherit"),
                  /* :: */[
                    Css.unsafe("fontSize", "inherit"),
                    /* :: */[
                      Css.selector(":first-of-type", /* :: */[
                            Css.maxWidth(Css.pct(100)),
                            /* :: */[
                              Css.whiteSpace(/* nowrap */867913355),
                              /* :: */[
                                Css.overflow(/* hidden */-862584982),
                                /* :: */[
                                  Css.textOverflow(/* ellipsis */166888785),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  ticketButton: ticketButton,
  ticket: ticket
};

function IssueTickets(Props) {
  var tickets = Props.tickets;
  return Belt_Array.mapWithIndex(tickets, (function (idx, param) {
                var href = param[1];
                var name = param[0];
                return React.createElement("a", {
                            key: name + ("__" + (href + ("-" + String(idx)))),
                            className: ticket,
                            href: href,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, I18N$BsConsole.showSkip(name));
              }));
}

var make = IssueTickets;

exports.Style = Style;
exports.make = make;
/* ticketButton Not a pure module */
