// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Task2$BsConsole = require("./Task2.js");
var WfTasks$BsConsole = require("./workflow/WfTasks.js");
var WfWatcher$BsConsole = require("./workflow/WfWatcher.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

var component = RR$BsConsole.reducerComponent("FetchAvailableWorkflows_FetchIntegrations");

function make(watchers, token, config, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var promises = Belt_Array.map(watchers, (function (watcher) {
                      return new Promise((function (resolve, param) {
                                    var maybeTask = WfTasks$BsConsole.fetchWatchersFullIntegration(watcher, config);
                                    if (maybeTask !== undefined) {
                                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                    if (resp.tag) {
                                                      return resolve(undefined);
                                                    } else {
                                                      return resolve({
                                                                  integration: resp[0],
                                                                  watcher: watcher
                                                                });
                                                    }
                                                  }));
                                    } else {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                    }
                                  }));
                    }));
              var __x = Promise.all(promises);
              var __x$1 = __x.then((function (workflows) {
                      Curry._1(send, /* WorkflowsLoaded */Block.__(0, [Belt_Array.keepMap(workflows, Util$BsConsole.identity)]));
                      return Promise.resolve(undefined);
                    }));
              __x$1.catch((function (exn) {
                      if (exn[0] === Task2$BsConsole.TaskError) {
                        Curry._1(send, /* WorkflowsLoadingFailed */Block.__(1, [exn[1]]));
                      }
                      return Promise.resolve(undefined);
                    }));
              
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return Curry._1(children, param.state.workflows);
            }),
          initialState: (function (param) {
              return {
                      workflows: /* Loading */1
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, param) {
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            workflows: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            workflows: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var FetchWorkflows = {
  component: component,
  make: make
};

function use(config, project, token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setWfRemote = match[1];
  var wfRemote = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setWatchers = match$1[1];
  var watchers = match$1[0];
  var onSuccess = function (ws) {
    return Curry._1(setWatchers, (function (param) {
                  return ws;
                }));
  };
  var onFailure = function (maybeErrorMessage) {
    if (maybeErrorMessage !== undefined) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "Failed to fetch watchers: ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "Failed to fetch watchers: %s"
                        ]), maybeErrorMessage));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to fetch watchers"));
    }
  };
  WfWatcher$BsConsole.useWatchers(project.pid, token, project.name, config, onSuccess, onFailure);
  React.useEffect((function () {
          var promises = Belt_Array.map(watchers, (function (watcher) {
                  return new Promise((function (resolve, param) {
                                var maybeTask = WfTasks$BsConsole.fetchWatchersFullIntegration(watcher, config);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                if (resp.tag) {
                                                  return resolve(undefined);
                                                } else {
                                                  return resolve({
                                                              integration: resp[0],
                                                              watcher: watcher
                                                            });
                                                }
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }));
                }));
          Curry._1(setWfRemote, (function (param) {
                  return /* Loading */1;
                }));
          var __x = Promise.all(promises);
          var __x$1 = __x.then((function (workflows) {
                  Curry._1(setWfRemote, (function (param) {
                          return /* Success */Block.__(0, [Belt_Array.keepMap(workflows, Util$BsConsole.identity)]);
                        }));
                  return Promise.resolve(undefined);
                }));
          __x$1.catch((function (exn) {
                  if (exn[0] === Task2$BsConsole.TaskError) {
                    Curry._1(setWfRemote, (function (param) {
                            return /* Failure */Block.__(1, ["Unable to fetch watchers"]);
                          }));
                  }
                  return Promise.resolve(undefined);
                }));
          
        }), /* tuple */[
        watchers,
        project.name
      ]);
  if (typeof wfRemote === "number") {
    return [];
  } else if (wfRemote.tag) {
    return [];
  } else {
    return wfRemote[0];
  }
}

exports.FetchWorkflows = FetchWorkflows;
exports.use = use;
/* component Not a pure module */
