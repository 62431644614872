// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../I18N.js");

function toString(param) {
  switch (param) {
    case /* OneHour */0 :
        return I18N$BsConsole.get(undefined, "1 hour from now");
    case /* ThreeHours */1 :
        return I18N$BsConsole.get(undefined, "3 hours from now");
    case /* OneDay */2 :
        return I18N$BsConsole.get(undefined, "1 day from now");
    case /* OneWeek */3 :
        return I18N$BsConsole.get(undefined, "1 week from now");
    case /* TwoWeeks */4 :
        return I18N$BsConsole.get(undefined, "2 weeks from now");
    
  }
}

var secondsPerDay = 24 * 60 * 60;

function toSeconds(param) {
  switch (param) {
    case /* OneHour */0 :
        return 60 * 60;
    case /* ThreeHours */1 :
        return 3 * 60 * 60;
    case /* OneDay */2 :
        return 1 * secondsPerDay;
    case /* OneWeek */3 :
        return 7 * secondsPerDay;
    case /* TwoWeeks */4 :
        return 14 * secondsPerDay;
    
  }
}

var options = [
  /* OneHour */0,
  /* ThreeHours */1,
  /* OneDay */2,
  /* OneWeek */3,
  /* TwoWeeks */4
];

exports.toString = toString;
exports.secondsPerDay = secondsPerDay;
exports.toSeconds = toSeconds;
exports.options = options;
/* I18N-BsConsole Not a pure module */
