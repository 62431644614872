// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var triageReadOnly = window._BACKTRACE_READ_ONLY;

var triageReadOnly$1 = (triageReadOnly == null) ? undefined : Caml_option.some(triageReadOnly);

if (Belt_Option.isSome(triageReadOnly$1)) {
  console.log("Backtrace-Read-Only-Mode");
}

var isEnabled = !(triageReadOnly == null) && triageReadOnly ? true : false;

exports.triageReadOnly = triageReadOnly$1;
exports.isEnabled = isEnabled;
/* triageReadOnly Not a pure module */
