// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var IFrame$BsConsole = require("./IFrame.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");

var keyToken = "token";

function toString(t) {
  switch (t) {
    case /* Alpha */0 :
        return "bt-alpha-token";
    case /* Beta */1 :
        return "bt-beta-token";
    case /* I18N */2 :
        return "bt-i18n-token";
    
  }
}

var Token = {
  toString: toString
};

function default_(gateName, isEnabled) {
  console.log("Feature Gate status change:", gateName, isEnabled);
  
}

var Listener = {
  default_: default_
};

function make(listeners) {
  return listeners;
}

function send(t, gateName, isEnabled) {
  return Belt_Array.forEach(t, (function (listener) {
                return Curry._2(listener, gateName, isEnabled);
              }));
}

var Notify = {
  make: make,
  send: send
};

function __getToken(name) {
  return Caml_option.null_to_opt(localStorage.getItem(name));
}

function getToken(param) {
  return Caml_option.null_to_opt(localStorage.getItem(keyToken));
}

function closeNamedToken(name) {
  var _token = localStorage.getItem(keyToken);
  if (_token !== null) {
    localStorage.removeItem(toString(name));
    return ;
  }
  
}

function getNamedToken(name) {
  var name$1 = toString(name);
  return Caml_option.null_to_opt(localStorage.getItem(name$1));
}

function openNamedToken(name) {
  var token = localStorage.getItem(keyToken);
  if (token !== null) {
    localStorage.setItem(toString(name), token === null ? undefined : Caml_option.some(token));
    return ;
  }
  
}

var TokenStorage = {
  __getToken: __getToken,
  getToken: getToken,
  closeNamedToken: closeNamedToken,
  getNamedToken: getNamedToken,
  openNamedToken: openNamedToken
};

var singleton = {
  contents: {
    notify: [default_]
  }
};

var isDefault = {
  contents: true
};

function make$1(listeners) {
  if (isDefault.contents) {
    isDefault.contents = false;
    singleton.contents = {
      notify: listeners
    };
    return /* Ok */Block.__(0, [singleton]);
  } else {
    return /* Error */Block.__(1, ["Attempting to re-initialize the Gate status notification system"]);
  }
}

function metricsDisabled(param) {
  return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_DISABLE_METRICS), false);
}

function isGateOpen(gateName, windowVariable) {
  var match = localStorage.getItem(keyToken);
  var match$1 = getNamedToken(gateName);
  if (windowVariable) {
    return true;
  } else if (match !== null && match$1 !== undefined) {
    return (
            match === null ? undefined : Caml_option.some(match)
          ) === match$1;
  } else {
    return false;
  }
}

function setGateStatus(gateName, isOpen) {
  if (isOpen) {
    openNamedToken(gateName);
  } else {
    closeNamedToken(gateName);
  }
  return send(singleton.contents.notify, gateName, isOpen);
}

function isAlpha(param) {
  return isGateOpen(/* Alpha */0, WindowVariables$BsConsole._BACKTRACE_USE_ALPHA);
}

function isSauceVariant(param) {
  return IdentityVariant$BsConsole.current === 1;
}

function isBeta(param) {
  return isGateOpen(/* Beta */1, WindowVariables$BsConsole._BACKTRACE_USE_BETA || isGateOpen(/* Alpha */0, WindowVariables$BsConsole._BACKTRACE_USE_ALPHA));
}

function isI18N(param) {
  return true;
}

function setAlpha(isOpen) {
  return setGateStatus(/* Alpha */0, isOpen);
}

function setBeta(isOpen) {
  return setGateStatus(/* Beta */1, isOpen);
}

function setI18N(isOpen) {
  return setGateStatus(/* I18N */2, isOpen);
}

function isWfBeta(param) {
  var wfBeta = localStorage.getItem("wf-beta");
  return Pervasives.bool_of_string(Belt_Option.getWithDefault(wfBeta === null ? undefined : Caml_option.some(wfBeta), "false"));
}

function setIsWfBeta(wfBetaVal) {
  localStorage.setItem("wf-beta", Pervasives.string_of_bool(wfBetaVal));
  window.location.reload(true);
  
}

function isBillingFeatureDisabled(feature) {
  isGateOpen(/* Alpha */0, WindowVariables$BsConsole._BACKTRACE_USE_ALPHA);
  return false;
}

function isSuperuser(user) {
  return user.superuser === 1;
}

function isAdmin(user) {
  var match = user.role;
  var adminRole = match === "admin" ? true : false;
  if (adminRole) {
    return true;
  } else {
    return user.superuser === 1;
  }
}

function isNotAdmin(user) {
  return !isAdmin(user);
}

function isMember(user) {
  var match = user.role;
  if (match === "member") {
    return true;
  } else {
    return false;
  }
}

function isNotMember(user) {
  return !isMember(user);
}

function disallowedByRole(feature) {
  if (typeof feature === "number") {
    return false;
  }
  switch (feature.tag | 0) {
    case /* SettingsUsers */0 :
    case /* SettingsAccessControl */1 :
    case /* SettingsAlerts */4 :
    case /* SettingsProjectsNew */6 :
    case /* SettingsUserInvitationManagement */7 :
    case /* SettingsMetricsExporter */13 :
        break;
    case /* RetentionPolicies */3 :
    case /* SettingsSubscriptions */8 :
    case /* SettingsNetworkingListeners */9 :
    case /* SettingsNetworkingSSL */10 :
    case /* SettingsNetworking */11 :
    case /* SettingsSamlConfig */12 :
    case /* SettingsWfConnectionManagement */14 :
        return !isAdmin(feature[0]);
    default:
      return false;
  }
  var user = feature[0];
  if (isAdmin(user)) {
    return false;
  } else {
    return !isMember(user);
  }
}

function disallowedByIframe(feature) {
  if (typeof feature === "number") {
    if (feature === /* VersionsMenuItem */7) {
      return false;
    } else {
      return IFrame$BsConsole.isIFrame;
    }
  }
  switch (feature.tag | 0) {
    case /* SettingsUsers */0 :
    case /* SettingsAccessControl */1 :
    case /* OrganizationSettings */2 :
    case /* ErrorThresholdAlerts */5 :
    case /* SettingsProjectsNew */6 :
    case /* SettingsUserInvitationManagement */7 :
    case /* SettingsSubscriptions */8 :
    case /* SettingsNetworkingListeners */9 :
    case /* SettingsNetworkingSSL */10 :
    case /* SettingsNetworking */11 :
    case /* SettingsSamlConfig */12 :
        return IFrame$BsConsole.isIFrame;
    case /* RetentionPolicies */3 :
    case /* SettingsAlerts */4 :
    case /* SettingsMetricsExporter */13 :
    case /* SettingsWfConnectionManagement */14 :
        return false;
    
  }
}

function disallowedByIdentityVariantAndRole(userEmail, feature) {
  if (typeof feature === "number") {
    if (feature === /* SettingsTeams */1 && IdentityVariant$BsConsole.current === 1) {
      if (userEmail !== undefined) {
        return userEmail !== "security@backtrace.io";
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  switch (feature.tag | 0) {
    case /* SettingsUsers */0 :
    case /* SettingsAccessControl */1 :
    case /* SettingsUserInvitationManagement */7 :
    case /* SettingsSamlConfig */12 :
        break;
    default:
      return false;
  }
  if (IdentityVariant$BsConsole.current !== 1) {
    return false;
  } else {
    return feature[0].email !== "security@backtrace.io";
  }
}

function disallowedWhenNormalWindow(feature) {
  if (typeof feature === "number" && feature === 7) {
    return !IFrame$BsConsole.isIFrame;
  } else {
    return false;
  }
}

function isNotPermitted(userEmail, feature) {
  if (disallowedByRole(feature) || disallowedByIframe(feature) || disallowedWhenNormalWindow(feature)) {
    return true;
  } else {
    return disallowedByIdentityVariantAndRole(userEmail, feature);
  }
}

function isStringCardinalityEnabled(param) {
  return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_ENABLE_LOW_CARDINALITY), false);
}

exports.keyToken = keyToken;
exports.Token = Token;
exports.Listener = Listener;
exports.Notify = Notify;
exports.TokenStorage = TokenStorage;
exports.singleton = singleton;
exports.isDefault = isDefault;
exports.make = make$1;
exports.metricsDisabled = metricsDisabled;
exports.isGateOpen = isGateOpen;
exports.setGateStatus = setGateStatus;
exports.isAlpha = isAlpha;
exports.isSauceVariant = isSauceVariant;
exports.isBeta = isBeta;
exports.isI18N = isI18N;
exports.setAlpha = setAlpha;
exports.setBeta = setBeta;
exports.setI18N = setI18N;
exports.isWfBeta = isWfBeta;
exports.setIsWfBeta = setIsWfBeta;
exports.isBillingFeatureDisabled = isBillingFeatureDisabled;
exports.isSuperuser = isSuperuser;
exports.isAdmin = isAdmin;
exports.isNotAdmin = isNotAdmin;
exports.isMember = isMember;
exports.isNotMember = isNotMember;
exports.disallowedByRole = disallowedByRole;
exports.disallowedByIframe = disallowedByIframe;
exports.disallowedByIdentityVariantAndRole = disallowedByIdentityVariantAndRole;
exports.disallowedWhenNormalWindow = disallowedWhenNormalWindow;
exports.isNotPermitted = isNotPermitted;
exports.isStringCardinalityEnabled = isStringCardinalityEnabled;
/* IFrame-BsConsole Not a pure module */
