// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Browser$BsConsole = require("./Browser.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var SessionStorage$BsConsole = require("./SessionStorage.js");
var UserFrontendSettings$BsConsole = require("./UserFrontendSettings.js");

var projectName = "I18N.Language";

var currentLanguageKey = "current.language.code";

function getFromBrowserLanguage(param) {
  return Belt_Option.flatMap(Browser$BsConsole.$$Navigator.language(undefined), I18N$BsConsole.LanguageCode.fromBCP47);
}

function getFromSessionStorage(param) {
  return Belt_Option.flatMap(SessionStorage$BsConsole.get(projectName, currentLanguageKey), I18N$BsConsole.LanguageCode.fromISO639);
}

function getFromUserFrontendSettings(config) {
  var userSettings = config.userSettings;
  if (userSettings !== undefined) {
    return Belt_Option.flatMap(userSettings.languageCode, I18N$BsConsole.LanguageCode.fromISO639);
  }
  
}

function get(config) {
  var langFromUserFrontendSettings = getFromUserFrontendSettings(config);
  var langFromSessionStorage = getFromSessionStorage(undefined);
  var langFromBrowserLanguage = getFromBrowserLanguage(undefined);
  if (langFromUserFrontendSettings !== undefined) {
    return langFromUserFrontendSettings;
  } else if (langFromSessionStorage !== undefined) {
    return langFromSessionStorage;
  } else if (langFromBrowserLanguage !== undefined) {
    return langFromBrowserLanguage;
  } else {
    return /* English */0;
  }
}

function getNoDefault(config) {
  var maybeLangFromUserFrontendSettings = getFromUserFrontendSettings(config);
  var maybeLangFromSessionStorage = getFromSessionStorage(undefined);
  if (maybeLangFromUserFrontendSettings !== undefined) {
    return maybeLangFromUserFrontendSettings;
  } else if (maybeLangFromSessionStorage !== undefined) {
    return maybeLangFromSessionStorage;
  } else {
    return ;
  }
}

function getString(config) {
  return I18N$BsConsole.LanguageCode.toString(get(config));
}

function getStringNoDefault(config) {
  return Belt_Option.map(getNoDefault(config), I18N$BsConsole.LanguageCode.toISO639);
}

var GetUserLanguage = {
  getFromBrowserLanguage: getFromBrowserLanguage,
  getFromSessionStorage: getFromSessionStorage,
  getFromUserFrontendSettings: getFromUserFrontendSettings,
  get: get,
  getNoDefault: getNoDefault,
  getString: getString,
  getStringNoDefault: getStringNoDefault
};

function setInSessionStorage(maybeLanguageCode) {
  if (maybeLanguageCode !== undefined) {
    return SessionStorage$BsConsole.set(projectName, currentLanguageKey, maybeLanguageCode);
  }
  
}

function setHtmlLangAttribute(maybeLanguageCode) {
  var setAttribute = function (languageCode) {
    var $$document$1 = document.documentElement;
    $$document$1.setAttribute("lang", languageCode);
    
  };
  if (maybeLanguageCode !== undefined) {
    return setAttribute(maybeLanguageCode);
  } else {
    return setAttribute(I18N$BsConsole.LanguageCode.toISO639(Belt_Option.getWithDefault(getFromBrowserLanguage(undefined), /* English */0)));
  }
}

function setInUserFrontendSettings(languageCode, token, config, cb) {
  return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
              /* SetLanguageCode */Block.__(13, [languageCode]),
              /* [] */0
            ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                return Curry._1(cb, undefined);
              }), (function (err) {
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "I18N_config/SetUserLanguage/setInUserFrontendSettings", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "Failed to store language settings: " + err
                    ]);
                return Curry._1(cb, undefined);
              }));
}

function set(languageCodeVariant, token, config, cb) {
  var languageCode = I18N$BsConsole.LanguageCode.toISO639(languageCodeVariant);
  setHtmlLangAttribute(languageCode);
  setInSessionStorage(languageCode);
  setInUserFrontendSettings(languageCode, token, config, cb);
  return languageCodeVariant;
}

var SetUserLanguage = {
  setInSessionStorage: setInSessionStorage,
  setHtmlLangAttribute: setHtmlLangAttribute,
  setInUserFrontendSettings: setInUserFrontendSettings,
  set: set
};

function clearFromSessionStorage(param) {
  return SessionStorage$BsConsole.remove(projectName, currentLanguageKey);
}

function clearFromUserFrontendSettings(token, config, cb) {
  return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
              /* ClearLanguageCode */0,
              /* [] */0
            ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                return Curry._1(cb, undefined);
              }), (function (err) {
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "I18N_config/clearUserLanguage", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "Failed to clear language settings: " + err
                    ]);
                return Curry._1(cb, undefined);
              }));
}

function clear(token, config, cb) {
  SessionStorage$BsConsole.remove(projectName, currentLanguageKey);
  return clearFromUserFrontendSettings(token, config, cb);
}

var ClearUserLanguage = {
  clearFromSessionStorage: clearFromSessionStorage,
  clearFromUserFrontendSettings: clearFromUserFrontendSettings,
  clear: clear
};

function initialize(userSettings) {
  if (userSettings === undefined) {
    return setHtmlLangAttribute(undefined);
  }
  var languageCode = userSettings.languageCode;
  if (languageCode !== undefined) {
    var languageCodeVariant = I18N$BsConsole.LanguageCode.fromISO639(languageCode);
    if (languageCodeVariant !== undefined) {
      I18N$BsConsole.$$File.load(languageCodeVariant);
    }
    
  }
  setInSessionStorage(userSettings.languageCode);
  return setHtmlLangAttribute(userSettings.languageCode);
}

function enable(param) {
  return Gate$BsConsole.setI18N(true);
}

function isEnabled(param) {
  return Gate$BsConsole.isI18N(undefined);
}

var defaultLanguageVariant = /* English */0;

exports.defaultLanguageVariant = defaultLanguageVariant;
exports.projectName = projectName;
exports.currentLanguageKey = currentLanguageKey;
exports.GetUserLanguage = GetUserLanguage;
exports.SetUserLanguage = SetUserLanguage;
exports.ClearUserLanguage = ClearUserLanguage;
exports.initialize = initialize;
exports.enable = enable;
exports.isEnabled = isEnabled;
/* Gate-BsConsole Not a pure module */
