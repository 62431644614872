// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RR$BsConsole = require("../RR.js");

var ctx = React.createContext("");

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = ctx.Provider;

var Jsc = {
  makeProps: makeProps,
  make: make
};

var component = RR$BsConsole.statelessComponent("ProjectNameContext_Re");

function make$1(value, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return React.createElement(make, makeProps(value, children, undefined));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Re = {
  component: component,
  make: make$1
};

var Provider = {
  Jsc: Jsc,
  Re: Re
};

exports.ctx = ctx;
exports.Provider = Provider;
/* ctx Not a pure module */
