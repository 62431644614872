// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Colors$BsConsole = require("../../Colors.js");
var ApiList$BsConsole = require("../../apiList.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var BtThemeContext$BsConsole = require("../../BtThemeContext.js");
var GetApp = require("@material-ui/icons/GetApp");
var BugBreadcrumbsApi$BsConsole = require("./BugBreadcrumbsApi.js");
var BugBreadcrumbsText$BsConsole = require("./BugBreadcrumbsText.js");
var BugBreadcrumbsView$BsConsole = require("./BugBreadcrumbsView.js");
var BugBreadcrumbsUtils$BsConsole = require("./BugBreadcrumbsUtils.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function BugBreadcrumbs$Loader(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.justifyContent(/* center */98248149),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(CircularProgress.default, { }));
}

var Loader = {
  make: BugBreadcrumbs$Loader
};

function getLastId(attributes) {
  try {
    var jsonKey = Js_dict.get(attributes, BugBreadcrumbsUtils$BsConsole.lastBreadcrumbKey);
    if (jsonKey === undefined) {
      return ;
    }
    var match = BugBreadcrumbsUtils$BsConsole.lastIdFromJson(Caml_option.valFromOption(jsonKey));
    var variant = match[0];
    if (variant !== 3654863) {
      if (variant >= 365180284) {
        return Caml_int64.of_float(match[1]);
      } else {
        return Caml_format.caml_int64_of_string(match[1]);
      }
    } else {
      return match[1];
    }
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    exn[0] === Json_decode.DecodeError;
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Error retrieving attribute ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* Char_literal */Block.__(12, [
                              /* "." */46,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "Error retrieving attribute %s."
                ]), BugBreadcrumbsUtils$BsConsole.lastBreadcrumbKey));
    return ;
  }
}

function BugBreadcrumbs(Props) {
  var token = Props.token;
  var showHeaderOpt = Props.showHeader;
  var attachments = Props.attachments;
  var attributes = Props.attributes;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var breadbox = Props.breadbox;
  var setBreadbox = Props.setBreadbox;
  var h = Props.height;
  var w = Props.width;
  var showBacktraceUnitySDKCTA = Props.showBacktraceUnitySDKCTA;
  var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : true;
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setState = match[1];
  var state = match[0];
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  var ctaParent = Css.style(/* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.justifyContent(/* flexStart */662439529),
                /* :: */[
                  Css.paddingTop(Css.pct(10)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var containerStyle = Css.style(/* :: */[
        Css.width(Css.px(w)),
        /* :: */[
          Css.height(Css.px(h)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* [] */0
              ]
            ]
          ]
        ]
      ]);
  var headerText = Css.style(/* :: */[
        Css.fontSize(Css.px(16)),
        /* :: */[
          Css.margin(Css.px(0)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  600
                ]),
            /* [] */0
          ]
        ]
      ]);
  var supportText = Css.style(/* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.margin(Css.px(5)),
              /* :: */[
                Css.fontWeight(/* `num */[
                      5496390,
                      400
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]);
  var downloadIcon = function (primaryMain) {
    return Css.style(/* :: */[
                Css.margin(Css.px(0)),
                /* :: */[
                  Css.fontSize(Css.px(16)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
                    /* [] */0
                  ]
                ]
              ]);
  };
  var link = function (primaryMain) {
    return Css.style(/* :: */[
                Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
                /* :: */[
                  Css.marginLeft(Css.px(4)),
                  /* :: */[
                    Css.visited(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]);
  };
  var getAllBreadcrumbs = function (paths, lastBreadcrumbId) {
    var sortedPaths = Belt_SortArray.stableSortBy(paths, $$String.compare);
    var promises = Belt_Array.map(sortedPaths, (function (path) {
            return BugBreadcrumbsApi$BsConsole.getBreadcrumb(token, path);
          }));
    var __x = Promise.all(promises);
    var __x$1 = __x.then((function (results) {
            var breadcrumbs = Belt_Array.reduce(Belt_Array.keepMap(results, Util$BsConsole.identity), [], Belt_Array.concat);
            var sortedBreadcrumbs = BugBreadcrumbsUtils$BsConsole.sortIdDesc(breadcrumbs);
            if (lastBreadcrumbId !== undefined) {
              var startIndex = Belt_Array.getIndexBy(sortedBreadcrumbs, (function (breadcrumb) {
                      return Caml_int64.le(breadcrumb.id, lastBreadcrumbId);
                    }));
              if (startIndex !== undefined) {
                var processedBreadcrumbs = Belt_Array.sliceToEnd(sortedBreadcrumbs, startIndex);
                Curry._1(setState, (function (param) {
                        return /* Loaded */Block.__(1, [processedBreadcrumbs]);
                      }));
                Curry._1(setBreadbox, (function (param) {
                        return /* Loaded */Block.__(1, [processedBreadcrumbs]);
                      }));
              } else {
                Curry._1(setState, (function (param) {
                        return /* BreadcrumbsAttachmentButEmpty */2;
                      }));
                Curry._1(setBreadbox, (function (param) {
                        return /* BreadcrumbsAttachmentButEmpty */2;
                      }));
              }
            } else {
              Curry._1(setState, (function (param) {
                      return /* Loaded */Block.__(1, [sortedBreadcrumbs]);
                    }));
              Curry._1(setBreadbox, (function (param) {
                      return /* Loaded */Block.__(1, [sortedBreadcrumbs]);
                    }));
            }
            return Promise.resolve(undefined);
          }));
    __x$1.catch((function (_exn) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.errorFetching));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          if (Belt_Option.isSome(breadbox)) {
            Curry._1(setState, (function (param) {
                    return Belt_Option.getWithDefault(breadbox, /* Error */Block.__(0, ["Breadcrumbs: this should never happen"]));
                  }));
          } else {
            var exit = 0;
            if (typeof attachments === "number" || attachments.tag) {
              exit = 1;
            } else {
              var breadcrumbFiles = attachments[0][0].filter((function (file) {
                      var __x = file.name;
                      return __x.startsWith(BugBreadcrumbsUtils$BsConsole.attachmentPrefix);
                    }));
              if (breadcrumbFiles.length === 0) {
                Curry._1(setState, (function (param) {
                        return /* NoBreadcrumbsAttachment */1;
                      }));
                Curry._1(setBreadbox, (function (param) {
                        return /* NoBreadcrumbsAttachment */1;
                      }));
              } else {
                var paths = Belt_Array.map(breadcrumbFiles, (function (file) {
                        return ApiList$BsConsole.getPath(errorId, undefined, projectName, file);
                      }));
                getAllBreadcrumbs(paths, getLastId(attributes));
              }
            }
            if (exit === 1) {
              Curry._1(setState, (function (param) {
                      return /* NoBreadcrumbsAttachment */1;
                    }));
              Curry._1(setBreadbox, (function (param) {
                      return /* NoBreadcrumbsAttachment */1;
                    }));
            }
            
          }
          
        }), [attachments]);
  if (typeof state !== "number") {
    if (state.tag) {
      return React.createElement(BugBreadcrumbsView$BsConsole.make, {
                  breadcrumbs: state[0],
                  height: h,
                  width: w,
                  showHeader: showHeader
                });
    } else {
      return React.createElement("div", {
                  className: containerStyle
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.paddingTop(Css.px(12)),
                            /* :: */[
                              Css.paddingBottom(Css.px(12)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.dynamic(state[0])
                    }));
    }
  }
  switch (state) {
    case /* Loading */0 :
        return React.createElement("div", {
                    className: containerStyle
                  }, React.createElement(BugBreadcrumbs$Loader, { }));
    case /* NoBreadcrumbsAttachment */1 :
        if (showBacktraceUnitySDKCTA) {
          return React.createElement("div", {
                      className: ctaParent
                    }, React.createElement("p", {
                          className: headerText
                        }, I18N$BsConsole.show(undefined, "Want to improve your experience with breadcrumbs?")), React.createElement("p", {
                          className: supportText
                        }, I18N$BsConsole.show(undefined, "Download the"), React.createElement("a", {
                              className: link(palette.primary.main),
                              href: "https://github.com/backtrace-labs/backtrace-unity"
                            }, I18N$BsConsole.show(undefined, "Backtrace-Unity SDK")), React.createElement(GetApp.default, {
                              className: downloadIcon(palette.primary.main)
                            })));
        } else {
          return React.createElement("div", {
                      className: containerStyle
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.paddingTop(Css.px(12)),
                                /* :: */[
                                  Css.paddingBottom(Css.px(12)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.noBreadcrumbsCheckDocs)
                        }));
        }
    case /* BreadcrumbsAttachmentButEmpty */2 :
        return React.createElement("div", {
                    className: containerStyle
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.paddingTop(Css.px(12)),
                              /* :: */[
                                Css.paddingBottom(Css.px(12)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.noBreadcrumbsFound)
                      }));
    
  }
}

var make = BugBreadcrumbs;

exports.Loader = Loader;
exports.getLastId = getLastId;
exports.make = make;
/* Css Not a pure module */
