// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactWindow = require("react-window");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var BugUtils$BsConsole = require("./data/BugUtils.js");
var Switch = require("@material-ui/core/Switch");
var BugDefectUtils$BsConsole = require("./data/BugDefectUtils.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");
var BugMissingSymbols$BsConsole = require("./BugMissingSymbols.js");
var BsInstance_Modules$BsConsole = require("../bs-instance/src/BsInstance_Modules.js");
var BugVariablesToggle$BsConsole = require("./BugVariablesToggle.js");
var Assignment = require("@material-ui/icons/Assignment");
var CallstackCopyButton$BsConsole = require("../CallstackCopyButton.js");
var BugCallstackFrameRow$BsConsole = require("./BugCallstackFrameRow.js");
var BugInteractiveTooltip$BsConsole = require("./BugInteractiveTooltip.js");

function BugGenericCallstack(Props) {
  var config = Props.config;
  var attributes = Props.attributes;
  var callstack = Props.callstack;
  var missingSymbols = Props.missingSymbols;
  var sourceCodeDict = Props.sourceCodeDict;
  var w = Props.width;
  var h = Props.height;
  var selectedFrame = Props.selectedFrame;
  var setSelectedFrame = Props.setSelectedFrame;
  var paneFocused = Props.paneFocused;
  var fingerprintDefects = Props.fingerprintDefects;
  var annotations = Props.annotations;
  var isLocalVarsVisible = Props.isLocalVarsVisible;
  var toggleIsLocalVarsVisbile = Props.toggleIsLocalVarsVisbile;
  var processFrames = Props.processFrames;
  var toggleProcessFrames = Props.toggleProcessFrames;
  var match = React.useState((function () {
          
        }));
  var setShowMissingSymbols = match[1];
  var showMissingSymbols = match[0];
  var applicationName = Js_dict.get(attributes, "application");
  var unameSysname = Js_dict.get(attributes, "uname.sysname");
  var frames = Belt_Array.map(Belt_Array.keep(Belt_Array.map(callstack, (function (frame) {
                  return Caml_option.nullable_to_opt(frame.funcName);
                })), Belt_Option.isSome), Belt_Option.getExn);
  var morgueProcessedFrames = Morgue$BsConsole.Callstack.prep(frames, {
        suffix: 2,
        dynamic: true
      });
  var isWorthProcessingFrames = Caml_obj.caml_notequal(morgueProcessedFrames, frames);
  var callstackFramesHasVariables = Belt_Array.some(callstack, (function (frame) {
          return Belt_Option.isSome(frame.variables);
        }));
  var tmp = {
    config: config,
    open_: Belt_Option.isSome(showMissingSymbols),
    onClose: (function (param) {
        return Curry._1(setShowMissingSymbols, (function (param) {
                      
                    }));
      }),
    missingSymbols: missingSymbols
  };
  if (showMissingSymbols !== undefined) {
    tmp.debugId = Caml_option.valFromOption(showMissingSymbols);
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BugSectionHeader$BsConsole.make, {
                  title: "Callstack",
                  count: callstack.length,
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: null
                      }, callstackFramesHasVariables && !isLocalVarsVisible ? React.createElement(BugVariablesToggle$BsConsole.make, {
                              isLocalVarsVisible: isLocalVarsVisible,
                              toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile
                            }) : null, isWorthProcessingFrames ? React.createElement(Tooltip.default, {
                              children: React.createElement("div", undefined, React.createElement(Switch.default, {
                                        onChange: (function (param, param$1) {
                                            return Curry._1(toggleProcessFrames, undefined);
                                          }),
                                        disableRipple: true,
                                        checked: processFrames
                                      })),
                              title: "Condense function names"
                            }) : null, React.createElement("div", {
                            className: "smaller-icon"
                          }, React.createElement(CallstackCopyButton$BsConsole.Jsx3.make, {
                                callstack: /* Generic */Block.__(2, [callstack]),
                                target: React.createElement("span", undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                                          ariaLabel: "Copy callstack to clipboard",
                                          children: React.createElement(Assignment.default, { })
                                        }))
                              })))
                }), React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.px((h - 32 | 0) - 1 | 0)),
                        /* :: */[
                          Css.overflowY(Css.auto),
                          /* :: */[
                            Css.overflowX(Css.hidden),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.marginTop(Css.px(1)),
                                /* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.fontFamily("Inconsolata"),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: React.createElement(ReactWindow.FixedSizeList, {
                        height: (h - 32 | 0) - 1 | 0,
                        width: w,
                        itemCount: callstack.length,
                        itemSize: 33,
                        children: (function (params) {
                            var frame = Belt_Array.getExn(callstack, params.index);
                            var match = frame.sourceCode;
                            var sourceCodeInfo = match !== undefined && sourceCodeDict !== undefined ? Js_dict.get(Caml_option.valFromOption(sourceCodeDict), match) : undefined;
                            var highlightAppFrames;
                            if (unameSysname !== undefined) {
                              var sysname = Js_json.decodeString(Caml_option.valFromOption(unameSysname));
                              highlightAppFrames = sysname !== undefined ? sysname.toLowerCase() === "ios" : false;
                            } else {
                              highlightAppFrames = false;
                            }
                            var match$1 = frame.library;
                            var isAppFrame;
                            if (match$1 !== undefined && applicationName !== undefined) {
                              var library = Caml_option.valFromOption(match$1);
                              var match$2 = Js_json.decodeString(Caml_option.valFromOption(applicationName));
                              isAppFrame = (library == null) || match$2 === undefined ? false : library === match$2;
                            } else {
                              isAppFrame = false;
                            }
                            var defects;
                            if (fingerprintDefects !== undefined) {
                              var defectsForFrame = BugDefectUtils$BsConsole.getDefectsByFrame(frame, fingerprintDefects, sourceCodeInfo);
                              defects = Belt_List.length(defectsForFrame) > 0 ? defectsForFrame : undefined;
                            } else {
                              defects = undefined;
                            }
                            var frameMissingSymbols = Belt_Option.flatMap(frame.library, (function (lib) {
                                    if (!(lib == null)) {
                                      return Caml_option.undefined_to_opt(missingSymbols.find((function (missingSymbol) {
                                                        return lib.includes(missingSymbol.path);
                                                      })));
                                    }
                                    
                                  }));
                            var match$3 = frame.guessed_frame;
                            var warning = frameMissingSymbols !== undefined ? "Missing symbol" : (
                                match$3 !== undefined && match$3 ? BugUtils$BsConsole.missingCfiText : undefined
                              );
                            var match$4 = frame.guessed_frame;
                            var headerIcon = frameMissingSymbols !== undefined ? /* Warn */969641574 : (
                                match$4 !== undefined && match$4 ? /* Help */803495649 : undefined
                              );
                            var getMissingCfiFrameInfoMaybe = function (frame) {
                              if (!BugUtils$BsConsole.isFrameMissingCfi(frameMissingSymbols, frame.guessed_frame)) {
                                return ;
                              }
                              var library = frame.library;
                              if (library === undefined) {
                                return ;
                              }
                              var library$1 = Caml_option.valFromOption(library);
                              if (library$1 == null) {
                                return ;
                              }
                              var modules = BsInstance_Modules$BsConsole.fromGlobalAnnotations(annotations);
                              return Belt_Array.getBy(modules, (function (m) {
                                            var codeFile = m.code_file;
                                            if (codeFile !== undefined) {
                                              return codeFile === library$1;
                                            } else {
                                              return false;
                                            }
                                          }));
                            };
                            var match$5 = frame.guessed_frame;
                            var funcName = frame.funcName;
                            var address = frame.address;
                            var match$6 = Belt_Option.flatMap(frame.path, (function (prim) {
                                    if (prim == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(prim);
                                    }
                                  }));
                            var match$7 = frame.directory;
                            var match$8 = frame.library;
                            var path;
                            if (match$6 !== undefined) {
                              path = match$7 !== undefined ? match$7 + match$6 : match$6;
                            } else if (match$8 !== undefined) {
                              var library$1 = Caml_option.valFromOption(match$8);
                              path = (library$1 == null) ? undefined : library$1;
                            } else {
                              path = undefined;
                            }
                            var debugId = frame.debug_identifier;
                            var missingFileInfo = getMissingCfiFrameInfoMaybe(frame);
                            var tmp;
                            if (missingFileInfo !== undefined) {
                              var s = missingFileInfo.debug_file;
                              var s$1 = missingFileInfo.debug_identifier;
                              var s$2 = missingFileInfo.code_identifier;
                              tmp = React.createElement(React.Fragment, undefined, BugUtils$BsConsole.hasSomeGuessedInfo(missingFileInfo) ? React.createElement(BugInteractiveTooltip$BsConsole.DisclaimerRow.make, {
                                          text: I18N$BsConsole.get(undefined, "The following comes from a global annotation matching this frame's path:")
                                        }) : null, s !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Debug file"),
                                          content: s
                                        }) : null, s$1 !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Debug ID"),
                                          content: s$1
                                        }) : null, s$2 !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Code ID"),
                                          content: s$2
                                        }) : null);
                            } else {
                              tmp = null;
                            }
                            var match$9 = frame.funcName;
                            var match$10 = frame.address;
                            var path$1 = Belt_Option.flatMap(frame.path, (function (prim) {
                                    if (prim == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(prim);
                                    }
                                  }));
                            var tmp$1;
                            if (path$1 !== undefined) {
                              tmp$1 = path$1;
                            } else {
                              var exit = 0;
                              if (sourceCodeInfo !== undefined && Belt_Option.isSome(sourceCodeInfo.path)) {
                                tmp$1 = sourceCodeInfo.path;
                              } else {
                                exit = 1;
                              }
                              if (exit === 1) {
                                var l = frame.library;
                                tmp$1 = l !== undefined ? Caml_option.nullable_to_opt(Caml_option.valFromOption(l)) : undefined;
                              }
                              
                            }
                            var tmp$2 = {
                              isSelected: Caml_obj.caml_equal(frame, selectedFrame),
                              isFocused: paneFocused,
                              isFaulted: Caml_obj.caml_equal(frame.faulted, true),
                              onClick: (function (param) {
                                  return Curry._1(setSelectedFrame, frame);
                                }),
                              index: params.index,
                              isAppFrame: isAppFrame,
                              highlightAppFrames: highlightAppFrames,
                              functionName: (match$9 == null) ? (
                                  (match$10 == null) ? "" : match$10
                                ) : (
                                  processFrames ? Belt_Array.getExn(Morgue$BsConsole.Callstack.prep([match$9], {
                                              suffix: 2,
                                              dynamic: true
                                            }), 0) : match$9
                                ),
                              missingSymbols: Belt_Option.isSome(frameMissingSymbols),
                              guessedFrame: Caml_obj.caml_equal(frame.guessed_frame, true),
                              inaccurateUnwinding: Belt_Option.isNone(frameMissingSymbols) && Caml_obj.caml_equal(frame.guessed_frame, true),
                              hasDefects: defects !== undefined
                            };
                            if (tmp$1 !== undefined) {
                              tmp$2.path = Caml_option.valFromOption(tmp$1);
                            }
                            var tmp$3 = Belt_Option.map(frame.line, (function (prim) {
                                    return String(prim);
                                  }));
                            if (tmp$3 !== undefined) {
                              tmp$2.line = Caml_option.valFromOption(tmp$3);
                            }
                            var tmp$4 = Belt_Option.map(frame.column, (function (prim) {
                                    return String(prim);
                                  }));
                            if (tmp$4 !== undefined) {
                              tmp$2.column = Caml_option.valFromOption(tmp$4);
                            }
                            var tmp$5 = {
                              contents: React.createElement("div", undefined, (funcName == null) ? null : React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Function name"),
                                          content: funcName
                                        }), (address == null) ? null : React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Address"),
                                          content: address
                                        }), path !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Path"),
                                          content: path
                                        }) : null, debugId !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: I18N$BsConsole.get(undefined, "Debug ID"),
                                          content: debugId
                                        }) : null, tmp, defects !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                          title: "Defect count",
                                          content: String(Belt_List.length(defects))
                                        }) : null),
                              children: React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.padding2(Css.px(4), Css.zero),
                                            /* :: */[
                                              Css.important(Css.height(Css.px(params.style.height + 8 | 0))),
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    style: params.style
                                  }, React.createElement(BugCallstackFrameRow$BsConsole.make, tmp$2))
                            };
                            var tmp$6 = frameMissingSymbols !== undefined ? (function (param) {
                                  return Curry._1(setShowMissingSymbols, (function (param) {
                                                return frameMissingSymbols.debug_identifier;
                                              }));
                                }) : undefined;
                            if (tmp$6 !== undefined) {
                              tmp$5.onHeaderClick = Caml_option.valFromOption(tmp$6);
                            }
                            if (warning !== undefined) {
                              tmp$5.headerText = Caml_option.valFromOption(warning);
                            }
                            var tmp$7 = frameMissingSymbols !== undefined || match$5 !== undefined && match$5 ? Colors$BsConsole.blue : undefined;
                            if (tmp$7 !== undefined) {
                              tmp$5.headerColor = Caml_option.valFromOption(tmp$7);
                            }
                            if (headerIcon !== undefined) {
                              tmp$5.headerIcon = Caml_option.valFromOption(headerIcon);
                            }
                            return React.createElement(BugInteractiveTooltip$BsConsole.make, tmp$5);
                          })
                      })
                }), React.createElement(BugMissingSymbols$BsConsole.make, tmp));
}

var make = React.memo(BugGenericCallstack);

exports.make = make;
/* make Not a pure module */
