// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Button = require("@material-ui/core/Button");
var BtThemeContext$BsConsole = require("../../BtThemeContext.js");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");

function JiraAddNewRowButton(Props) {
  var item = Props.item;
  var handleClick = Props.handleClick;
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Colors$BsConsole.deoctothorpe(palette.primary.main);
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(primaryMain)),
                        /* [] */0
                      ]),
                  onClick: (function (_e) {
                      return Curry._1(handleClick, undefined);
                    }),
                  children: null
                }, React.createElement(AddCircleOutline.default, {
                      fontSize: "inherit"
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(primaryMain)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    500
                                  ]),
                              /* :: */[
                                Css.fontSize(Css.rem(0.875)),
                                /* :: */[
                                  Css.paddingLeft(Css.px(3)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "Add new ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "Add new %s"
                              ]), item)
                    })));
}

var make = JiraAddNewRowButton;

exports.make = make;
/* Css Not a pure module */
