// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var FilterPills$BsConsole = require("./FilterPills.js");
var FormatUtils$BsConsole = require("./FormatUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Edit = require("@material-ui/icons/Edit");
var AutoSizeInput$BsConsole = require("./AutoSizeInput.js");
var InboxDescribe$BsConsole = require("./inbox/InboxDescribe.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

var ellipsis = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.textOverflow(/* ellipsis */166888785),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* [] */0
        ]
      ]
    ]);

var colon = Css.style(/* :: */[
      Css.opacity(0.75),
      /* [] */0
    ]);

var Style = {
  ellipsis: ellipsis,
  colon: colon
};

function getAggregationOperationSets(attribute) {
  return Crdb$BsConsole.HavingOperation.getOptions(attribute);
}

function getAggregationSets(attribute) {
  return Belt_Option.map(Crdb$BsConsole.HavingOperation.getOptions(attribute), (function (sets) {
                return Belt_Array.map(sets, (function (param) {
                              return param[0];
                            }));
              }));
}

function getOperationSets(attribute, aggregation) {
  return Belt_Option.flatMap(Crdb$BsConsole.HavingOperation.getOptions(attribute), (function (sets) {
                return Belt_Option.map(Belt_Array.getBy(sets, (function (param) {
                                  return param[0] === aggregation;
                                })), (function (param) {
                              return param[1];
                            }));
              }));
}

var Pipe = {
  getAggregationOperationSets: getAggregationOperationSets,
  getAggregationSets: getAggregationSets,
  getOperationSets: getOperationSets
};

var descriptionStyle = Css.style(/* :: */[
      Css.important(Css.color(Css.hex("CDCDCD"))),
      /* [] */0
    ]);

var titleContainerStyle = Css.style(/* :: */[
      Css.flexShrink(1),
      /* [] */0
    ]);

var titleStyle = Css.style(/* :: */[
      Css.important(Css.overflow(/* hidden */-862584982)),
      /* :: */[
        Css.important(Css.textOverflow(/* ellipsis */166888785)),
        /* :: */[
          Css.important(Css.whiteSpace(/* nowrap */867913355)),
          /* :: */[
            Css.margin2(Css.px(2), Css.zero),
            /* :: */[
              Css.marginTop(Css.zero),
              /* :: */[
                Css.color(Css.white),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var flex = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var container = Css.style(/* :: */[
      Css.minHeight(Css.px(48)),
      /* :: */[
        Css.justifyContent(/* flexStart */662439529),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* :: */[
              Css.paddingTop(Css.zero),
              /* :: */[
                Css.boxSizing(/* borderBox */9307263),
                /* :: */[
                  Css.unsafe("outline", "none"),
                  /* :: */[
                    Css.padding2(Css.px(4), Css.zero),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.borderBottom(Css.px(1), Css.solid, Css.hex("37474F")),
                        /* :: */[
                          Css.overflow(/* hidden */-862584982),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var col = Css.style(/* :: */[
      Css.flexDirection(/* column */-963948842),
      /* [] */0
    ]);

var row = Css.style(/* :: */[
      Css.flexDirection(/* row */5693978),
      /* [] */0
    ]);

function FilterInput$AttributeItem(Props) {
  var name = Props.name;
  var description = Props.description;
  var custom = Props.custom;
  var triage = Props.triage;
  Props.having;
  var used = Props.used;
  var name$1;
  if (triage) {
    var name$prime = InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(name);
    name$1 = name$prime !== undefined ? name$prime : name;
  } else {
    name$1 = name;
  }
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    container,
                    /* :: */[
                      flex,
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        col,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginLeft(Css.rem(0.875)),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.8125)),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, Css.merge(/* :: */[
                              "add-icon",
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.color(Css.hex("547580")),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]), undefined, undefined, undefined, undefined, []))), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        col,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.width(Css.pct(80)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement("div", {
                      className: titleContainerStyle
                    }, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, titleStyle, undefined, undefined, undefined, [I18N$BsConsole.showSkip(name$1)]))), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, descriptionStyle, undefined, undefined, undefined, [I18N$BsConsole.showSkip(description)]))), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        col,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.paddingRight(Css.rem(0.875)),
                                /* :: */[
                                  Css.width(Css.pct(20)),
                                  /* :: */[
                                    Css.justifyContent(/* flexEnd */924268066),
                                    /* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.flexDirection(/* row */5693978),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.media("(max-width: 1200px)", /* :: */[
                                        Css.display(/* none */-922086728),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ])
                }, used ? null : ReasonReact.element(undefined, undefined, Curry._1(FilterPills$BsConsole.Unused.Jsx2.make, [])), custom ? ReasonReact.element(undefined, undefined, Curry._1(FilterPills$BsConsole.Custom.Jsx2.make, [])) : (
                    triage ? ReasonReact.element(undefined, undefined, Curry._1(FilterPills$BsConsole.Triage.Jsx2.make, [])) : ReasonReact.element(undefined, undefined, Curry._1(FilterPills$BsConsole.Default.Jsx2.make, []))
                  )));
}

var AttributeItem = {
  descriptionStyle: descriptionStyle,
  titleContainerStyle: titleContainerStyle,
  titleStyle: titleStyle,
  flex: flex,
  container: container,
  col: col,
  row: row,
  make: FilterInput$AttributeItem
};

var titleStyle$1 = Css.style(/* :: */[
      Css.important(Css.overflow(/* hidden */-862584982)),
      /* :: */[
        Css.important(Css.textOverflow(/* ellipsis */166888785)),
        /* :: */[
          Css.important(Css.whiteSpace(/* nowrap */867913355)),
          /* :: */[
            Css.margin2(Css.px(2), Css.zero),
            /* :: */[
              Css.marginTop(Css.zero),
              /* :: */[
                Css.color(Css.white),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var flex$1 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var container$1 = Css.style(/* :: */[
      Css.minHeight(Css.px(48)),
      /* :: */[
        Css.justifyContent(/* flexStart */662439529),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* :: */[
              Css.paddingTop(Css.zero),
              /* :: */[
                Css.boxSizing(/* borderBox */9307263),
                /* :: */[
                  Css.unsafe("outline", "none"),
                  /* :: */[
                    Css.padding2(Css.px(4), Css.zero),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.borderBottom(Css.px(1), Css.solid, Css.hex("37474F")),
                        /* :: */[
                          Css.overflow(/* hidden */-862584982),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var col$1 = Css.style(/* :: */[
      Css.flexDirection(/* column */-963948842),
      /* [] */0
    ]);

var row$1 = Css.style(/* :: */[
      Css.flexDirection(/* row */5693978),
      /* [] */0
    ]);

var listClassName = Css.merge(/* :: */[
      "FilterInput-results_list",
      /* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.black)),
              /* :: */[
                Css.selector("> li", /* :: */[
                      Css.maxWidth(/* none */-922086728),
                      /* :: */[
                        Css.padding(/* zero */-789508312),
                        /* :: */[
                          Css.hover(/* :: */[
                                Css.backgroundColor(Css.rgba(0, 0, 0, 0.08)),
                                /* :: */[
                                  Css.selector(".add-icon", /* :: */[
                                        Css.color(Css.hex("FFFFFF")),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]),
                          /* :: */[
                            Css.selector("&[data-focus='true']", /* :: */[
                                  Css.backgroundColor(Css.hex("547581")),
                                  /* :: */[
                                    Css.selector(".add-icon", /* :: */[
                                          Css.color(Css.hex("FFFFFF")),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var rootClassName = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.rem(2.85)),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.backgroundColor(Css.hex("405B65")),
              /* :: */[
                Css.minWidth(Css.px(365)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

function FilterInput$StyledListItem(Props) {
  var el = Props.el;
  var titleStyleExt = Props.titleStyleExt;
  var titleStyle$prime = titleStyleExt !== undefined ? Css.merge(/* :: */[
          titleStyle$1,
          /* :: */[
            titleStyleExt,
            /* [] */0
          ]
        ]) : titleStyle$1;
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    container$1,
                    /* :: */[
                      flex$1,
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        col$1,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginLeft(Css.rem(0.875)),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.8125)),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, Css.merge(/* :: */[
                              "add-icon",
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.color(Css.hex("547580")),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]), undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, titleStyle$prime, undefined, undefined, undefined, [el])));
}

var StyledListItem = {
  titleStyle: titleStyle$1,
  flex: flex$1,
  container: container$1,
  col: col$1,
  row: row$1,
  listClassName: listClassName,
  rootClassName: rootClassName,
  make: FilterInput$StyledListItem
};

function FilterInput$Input(Props) {
  var className = Props.className;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var inputRef = Props.inputRef;
  var autoFocus = Props.autoFocus;
  var ariaLabel = Props.ariaLabel;
  return ReasonReact.element(undefined, undefined, AutoSizeInput$BsConsole.make(placeholder, value, undefined, disabled, onChange, onFocus, onBlur, className, inputRef, autoFocus, ariaLabel, undefined, []));
}

var Input = {
  make: FilterInput$Input
};

function FilterInput$ValueInput(Props) {
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var initialValue = Props.initialValue;
  var onBlur = Props.onBlur;
  var match = React.useState((function () {
          return Belt_Option.getWithDefault(initialValue, "");
        }));
  var setValue = match[1];
  var value = match[0];
  var handleKeyDown = function ($$event) {
    var match = $$event.key;
    switch (match) {
      case "Backspace" :
          if (value === "") {
            return Curry._1(onDelete, undefined);
          } else {
            return ;
          }
      case "Enter" :
          return Curry._1(onChange, value);
      case "Escape" :
          return Curry._1(onDelete, undefined);
      case "Tab" :
          return Curry._1(onBlur, undefined);
      default:
        return ;
    }
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]
                  ]),
              onKeyDown: handleKeyDown
            }, React.createElement(FilterInput$Input, {
                  onBlur: onBlur,
                  placeholder: I18N$BsConsole.get(undefined, "[ value ]"),
                  value: value,
                  onChange: (function (e) {
                      var v = e.target.value;
                      return Curry._1(setValue, (function (param) {
                                    return v;
                                  }));
                    }),
                  autoFocus: true,
                  ariaLabel: "value"
                }));
}

var ValueInput = {
  make: FilterInput$ValueInput
};

function normalizeWithCount(l) {
  return Belt_List.toArray(Belt_List.sort(Belt_List.reduce(Belt_List.fromArray(l), /* [] */0, (function (curr, str) {
                        if (!Belt_List.has(curr, str, (function (a, b) {
                                  return a[0] === b;
                                }))) {
                          return Belt_List.concat(curr, /* :: */[
                                      /* tuple */[
                                        str,
                                        1
                                      ],
                                      /* [] */0
                                    ]);
                        }
                        var match = Belt_Option.getWithDefault(Belt_List.getBy(curr, (function (param) {
                                    return param[0] === str;
                                  })), /* tuple */[
                              str,
                              1
                            ]);
                        return Belt_List.concat(Belt_List.keep(curr, (function (param) {
                                          return param[0] !== str;
                                        })), /* :: */[
                                    /* tuple */[
                                      str,
                                      match[1] + 1 | 0
                                    ],
                                    /* [] */0
                                  ]);
                      })), (function (param, param$1) {
                    return param$1[1] - param[1] | 0;
                  })));
}

function getUserNames(l) {
  return normalizeWithCount(Belt_Array.reduce(Belt_Array.map(Belt_Array.reduce(Belt_Array.map(Belt_Array.keepMap(Belt_Array.map(l, (function (param) {
                                        var format = param[0];
                                        if (format !== undefined && format.tag === /* IssueTickets */13) {
                                          return format[0];
                                        }
                                        
                                      })), Util$BsConsole.identity), (function (ticketList) {
                                return Belt_Array.keepMap(Belt_List.toArray(Belt_List.map(ticketList, (function (param) {
                                                      switch (param.tag | 0) {
                                                        case /* Unlinked */0 :
                                                        case /* Linked */1 :
                                                            return param[0].assignees;
                                                        case /* InteralAssigneeLink */2 :
                                                            return param[0].assignees;
                                                        
                                                      }
                                                    }))), Util$BsConsole.identity);
                              })), [], Belt_Array.concat), (function (assigneeList) {
                        return Belt_Array.keepMap(Belt_List.toArray(Belt_List.map(assigneeList, (function (assignee) {
                                              if (assignee.tag) {
                                                return ;
                                              } else {
                                                return assignee[0].username;
                                              }
                                            }))), Util$BsConsole.identity);
                      })), [], Belt_Array.concat));
}

function getTickets(l) {
  return normalizeWithCount(Belt_Array.reduce(Belt_Array.map(Belt_Array.keepMap(Belt_Array.map(l, (function (param) {
                                var format = param[0];
                                if (format !== undefined && format.tag === /* IssueTickets */13) {
                                  return format[0];
                                }
                                
                              })), Util$BsConsole.identity), (function (ticketList) {
                        return Belt_Array.keepMap(Belt_List.toArray(Belt_List.map(ticketList, (function (param) {
                                              switch (param.tag | 0) {
                                                case /* Unlinked */0 :
                                                case /* Linked */1 :
                                                    return param[0].short;
                                                case /* InteralAssigneeLink */2 :
                                                    return ;
                                                
                                              }
                                            }))), Util$BsConsole.identity);
                      })), [], Belt_Array.concat));
}

function useAttributeValueSuggestions(attribute, aperture, havings, token, projectName) {
  var match = React.useState((function () {
          return [];
        }));
  var setOptions = match[1];
  var match$1 = React.useState((function () {
          return true;
        }));
  var setLoading = match$1[1];
  var attributeName = attribute.name === Issues$BsConsole.Assignees.columnName ? Issues$BsConsole.Tickets.columnName : attribute.name;
  var query_002 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            attributeName,
            /* Distribution */Block.__(1, [100])
          ]), Crdb$BsConsole.Fold.empty);
  var query_005 = Curry._2(Crdb$BsConsole.Havings.eq, havings, Crdb$BsConsole.Havings.empty) ? undefined : Caml_option.some(havings);
  var query = /* Aggregate */Block.__(0, [
      aperture,
      undefined,
      query_002,
      undefined,
      undefined,
      query_005
    ]);
  var match$2 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "filter-value-distribution");
  var paginationRemote = match$2[0];
  React.useEffect((function () {
          var options$prime;
          if (typeof paginationRemote === "number") {
            options$prime = [];
          } else if (paginationRemote.tag) {
            options$prime = [];
          } else {
            var match = paginationRemote[0][0];
            var columns = match[0];
            var group = Belt_List.get(match[1], 0);
            if (group !== undefined) {
              var getter = function (param, param$1) {
                return Group$BsConsole.getAggregation(columns, group, param, param$1);
              };
              var distribution = Group$BsConsole.unwrapDistribution(getter(attributeName, /* Distribution */1));
              if (distribution !== undefined) {
                var colName = attribute.name;
                options$prime = colName === Issues$BsConsole.Assignees.columnName ? getUserNames(Belt_List.toArray(distribution.vals)) : (
                    colName === Issues$BsConsole.Tickets.columnName ? getTickets(Belt_List.toArray(distribution.vals)) : Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
                              return /* tuple */[
                                      FormatUtils$BsConsole.formatToString(param[0]),
                                      param[1]
                                    ];
                            }))
                  );
              } else {
                options$prime = [];
              }
            } else {
              options$prime = [];
            }
          }
          Curry._1(setOptions, (function (param) {
                  return options$prime;
                }));
          var loading$prime;
          loading$prime = typeof paginationRemote === "number" || paginationRemote.tag ? true : false;
          Curry._1(setLoading, (function (param) {
                  return loading$prime;
                }));
          
        }), [paginationRemote]);
  return /* tuple */[
          match[0],
          match$1[0]
        ];
}

function FilterInput$ValueInputAutocomplete(Props) {
  var attribute = Props.attribute;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var projectName = Props.projectName;
  var token = Props.token;
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var initialValue = Props.initialValue;
  var onBlur = Props.onBlur;
  var containerFocused = Props.containerFocused;
  var match = useAttributeValueSuggestions(attribute, aperture, havings, token, projectName);
  return React.createElement(BtAutocomplete$BsConsole.make, {
              toString: (function (param) {
                  return param[0];
                }),
              freeSolo: true,
              considerations: match[0],
              onDelete: onDelete,
              onChange: (function (raw, value$prime) {
                  if (value$prime !== undefined) {
                    return Curry._1(onChange, value$prime[0]);
                  } else {
                    return Curry._1(onChange, raw);
                  }
                }),
              defaultValue: Belt_Option.getWithDefault(initialValue, ""),
              loading: match[1],
              placeholder: I18N$BsConsole.get(undefined, "[ value ]"),
              autoFocus: true,
              inputAriaLabel: "value",
              renderListOption: (function (param, idx) {
                  var value = param[0];
                  return React.createElement(FilterInput$StyledListItem, {
                              el: React.createElement(Col2$BsConsole.make, {
                                    justifyContent: /* spaceAround */-485895757,
                                    className: Css.style(/* :: */[
                                          Css.minWidth(Css.px(100)),
                                          /* [] */0
                                        ]),
                                    children: null,
                                    key: value + ("__" + String(idx))
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.zero),
                                              /* :: */[
                                                Css.unsafe("overflowWrap", "anywhere"),
                                                /* [] */0
                                              ]
                                            ]),
                                        children: I18N$BsConsole.showSkip(value)
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        className: Css.style(/* :: */[
                                              Css.color(Css.hex("eceff1")),
                                              /* :: */[
                                                Css.marginBottom(Css.zero),
                                                /* [] */0
                                              ]
                                            ]),
                                        children: I18N$BsConsole.showSkip(String(param[1]))
                                      })),
                              titleStyleExt: Css.style(/* :: */[
                                    Css.important(Css.whiteSpace(/* unset */653193961)),
                                    /* [] */0
                                  ])
                            });
                }),
              renderRawListOption: (function (label, value) {
                  return React.createElement(FilterInput$StyledListItem, {
                              el: React.createElement(Col2$BsConsole.make, {
                                    justifyContent: /* spaceAround */-485895757,
                                    className: Css.style(/* :: */[
                                          Css.minWidth(Css.px(100)),
                                          /* [] */0
                                        ]),
                                    children: null
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.zero),
                                              /* :: */[
                                                Css.unsafe("overflowWrap", "anywhere"),
                                                /* [] */0
                                              ]
                                            ]),
                                        children: I18N$BsConsole.showSkip(value)
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        className: Css.style(/* :: */[
                                              Css.color(Css.hex("eceff1")),
                                              /* :: */[
                                                Css.marginBottom(Css.zero),
                                                /* [] */0
                                              ]
                                            ]),
                                        children: I18N$BsConsole.showSkip(label)
                                      })),
                              titleStyleExt: Css.style(/* :: */[
                                    Css.important(Css.whiteSpace(/* unset */653193961)),
                                    /* [] */0
                                  ])
                            });
                }),
              renderInput: (function (param) {
                  return React.createElement(FilterInput$Input, {
                              onFocus: param.onFocus,
                              placeholder: param.placeholder,
                              value: param.value,
                              onChange: param.onChange,
                              autoFocus: param.autoFocus,
                              ariaLabel: param.ariaLabel
                            });
                }),
              listClassName: listClassName,
              rootClassName: rootClassName,
              onBlur: onBlur,
              suppressPopper: true,
              containerFocused: containerFocused
            });
}

var ValueInputAutocomplete = {
  normalizeWithCount: normalizeWithCount,
  getUserNames: getUserNames,
  getTickets: getTickets,
  useAttributeValueSuggestions: useAttributeValueSuggestions,
  make: FilterInput$ValueInputAutocomplete
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            attribute: undefined,
            pipe: undefined,
            operator: undefined,
            value: undefined,
            step: /* Attribute */0
          };
  }
  switch (action.tag | 0) {
    case /* SetAttribute */0 :
        var attribute = action[0];
        if (attribute !== undefined) {
          if (Caml_obj.caml_equal(attribute, Attribute_Helpers$BsConsole.rowCount)) {
            return {
                    attribute: attribute,
                    pipe: "count",
                    operator: state.operator,
                    value: state.value,
                    step: /* Operator */2
                  };
          } else {
            return {
                    attribute: attribute,
                    pipe: state.pipe,
                    operator: state.operator,
                    value: state.value,
                    step: /* Operator */2
                  };
          }
        } else {
          return {
                  attribute: attribute,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: state.value,
                  step: state.step
                };
        }
    case /* PromptPipe */1 :
        var attribute$1 = action[0];
        if (attribute$1 === undefined) {
          return {
                  attribute: attribute$1,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: state.value,
                  step: state.step
                };
        }
        var match = getAggregationSets(attribute$1);
        if (match !== undefined) {
          return {
                  attribute: attribute$1,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: state.value,
                  step: /* Pipe */1
                };
        } else {
          return {
                  attribute: attribute$1,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: state.value,
                  step: /* Operator */2
                };
        }
    case /* SetPipe */2 :
        var pipe = action[0];
        if (pipe !== undefined) {
          return {
                  attribute: state.attribute,
                  pipe: pipe,
                  operator: state.operator,
                  value: state.value,
                  step: /* Operator */2
                };
        } else {
          return {
                  attribute: state.attribute,
                  pipe: pipe,
                  operator: state.operator,
                  value: state.value,
                  step: /* Attribute */0
                };
        }
    case /* SetOperator */3 :
        var operator = action[0];
        if (operator !== undefined) {
          return {
                  attribute: state.attribute,
                  pipe: state.pipe,
                  operator: operator,
                  value: state.value,
                  step: /* Value */3
                };
        }
        var p = state.pipe;
        var tmp;
        tmp = p !== undefined && p !== "count" ? p : undefined;
        var match$1 = state.pipe;
        var tmp$1;
        tmp$1 = match$1 !== undefined && match$1 !== "count" ? /* Pipe */1 : /* Attribute */0;
        return {
                attribute: state.attribute,
                pipe: tmp,
                operator: operator,
                value: state.value,
                step: tmp$1
              };
    case /* SetValue */4 :
        var value = action[0];
        if (value !== undefined) {
          return {
                  attribute: state.attribute,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: value,
                  step: /* Attribute */0
                };
        } else {
          return {
                  attribute: state.attribute,
                  pipe: state.pipe,
                  operator: state.operator,
                  value: value,
                  step: /* Operator */2
                };
        }
    case /* SetAttributeAndOperator */5 :
        return {
                attribute: action[0],
                pipe: state.pipe,
                operator: action[1],
                value: state.value,
                step: /* Value */3
              };
    
  }
}

function FilterInput$Default(Props) {
  var route = Props.route;
  var isHavingOpt = Props.isHaving;
  var primaryPlaceholderOpt = Props.primaryPlaceholder;
  var describeRemote = Props.describeRemote;
  var onFilterChange = Props.onFilterChange;
  var onHavingChange = Props.onHavingChange;
  var onDelete = Props.onDelete;
  var onBlur = Props.onBlur;
  var projectName = Props.projectName;
  var token = Props.token;
  var containerFocusedOpt = Props.containerFocused;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var attributeOpt = Props.attribute;
  var pipeOpt = Props.pipe;
  var operatorOpt = Props.operator;
  var valueOpt = Props.value;
  var openQueryBuilder = Props.openQueryBuilder;
  var queryBuilderState = Props.queryBuilderState;
  var queryBuilderOpen = Props.queryBuilderOpen;
  var isHaving = isHavingOpt !== undefined ? isHavingOpt : false;
  var primaryPlaceholder = primaryPlaceholderOpt !== undefined ? primaryPlaceholderOpt : "";
  var containerFocused = containerFocusedOpt !== undefined ? containerFocusedOpt : true;
  var attribute = attributeOpt !== undefined ? Caml_option.valFromOption(attributeOpt) : undefined;
  var pipe = pipeOpt !== undefined ? Caml_option.valFromOption(pipeOpt) : undefined;
  var operator = operatorOpt !== undefined ? Caml_option.valFromOption(operatorOpt) : undefined;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var match = React.useReducer(reducer, {
        attribute: attribute,
        pipe: pipe,
        operator: operator,
        value: value,
        step: attribute !== undefined && operator !== undefined && value !== undefined ? /* Value */3 : /* Attribute */0
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var step = match$1.step;
  var value$1 = match$1.value;
  var operator$1 = match$1.operator;
  var pipe$1 = match$1.pipe;
  var attribute$1 = match$1.attribute;
  var onBlur$1 = function (param) {
    Curry._1(dispatch, /* Reset */0);
    if (onBlur !== undefined) {
      return Curry._1(onBlur, undefined);
    }
    
  };
  React.useEffect((function () {
          if (queryBuilderOpen !== undefined && !queryBuilderOpen) {
            onBlur$1(undefined);
          }
          
        }), [queryBuilderOpen]);
  var match$2 = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.48.39");
  var permitPipe;
  if (match$2 && typeof route !== "number") {
    switch (route.tag | 0) {
      case /* ProjectTriage */11 :
      case /* ProjectQueryBuilder */13 :
          permitPipe = true;
          break;
      default:
        permitPipe = false;
    }
  } else {
    permitPipe = false;
  }
  React.useEffect((function () {
          if (queryBuilderState !== undefined) {
            var qbAttr = queryBuilderState[0];
            if (qbAttr !== undefined) {
              var qbOperator = queryBuilderState[1];
              if (qbOperator !== undefined) {
                Curry._1(dispatch, /* SetAttributeAndOperator */Block.__(5, [
                        qbAttr,
                        qbOperator
                      ]));
              }
              
            }
            
          }
          
        }), [queryBuilderState]);
  var tmp;
  switch (step) {
    case /* Attribute */0 :
        if (pipe$1 !== undefined || operator$1 !== undefined || value$1 !== undefined) {
          tmp = null;
        } else {
          var tmp$1;
          tmp$1 = typeof describeRemote === "number" ? [] : (
              describeRemote.tag ? [] : Belt_Array.concat(Belt_List.toArray(Belt_List.keep(describeRemote[0], Attribute_Helpers$BsConsole.filterFirstSeen)), permitPipe ? [Attribute_Helpers$BsConsole.rowCount] : [])
            );
          var tmp$2 = {
            toString: (function (attr) {
                if (attr.name === Attribute_Helpers$BsConsole.rowCount.name) {
                  return I18N$BsConsole.get(undefined, "error row count");
                } else {
                  return attr.name;
                }
              }),
            considerations: tmp$1,
            onDelete: (function (param) {
                Curry._1(dispatch, /* Reset */0);
                return Curry._1(onDelete, undefined);
              }),
            onChange: (function (_raw, attribute) {
                return Curry._1(dispatch, /* SetAttribute */Block.__(0, [attribute]));
              }),
            keyboardHandlers: /* :: */[
              permitPipe ? /* tuple */[
                  "|",
                  (function (_raw, attribute) {
                      if (attribute !== undefined) {
                        if (Caml_obj.caml_equal(attribute, Attribute_Helpers$BsConsole.rowCount) || Crdb$BsConsole.HavingOperation.getOptions(attribute) === undefined) {
                          return Curry._1(dispatch, /* SetAttribute */Block.__(0, [attribute]));
                        } else {
                          return Curry._1(dispatch, /* PromptPipe */Block.__(1, [attribute]));
                        }
                      }
                      
                    })
                ] : /* tuple */[
                  "|",
                  (function (_raw, _attribute) {
                      
                    })
                ],
              /* [] */0
            ],
            defaultValue: attribute$1 !== undefined ? InboxUtils$BsConsole.getFriendlyName(attribute$1.name) : "",
            loading: describeRemote === /* Loading */1 || describeRemote === /* NotAsked */0,
            placeholder: containerFocused ? I18N$BsConsole.get(undefined, "[ attribute ]") : primaryPlaceholder,
            autoFocus: Belt_Option.isSome(attribute$1),
            inputAriaLabel: "attribute",
            filterOptions: (function (attributes, searchValue) {
                return Fuzz$BsConsole.fuzz(searchValue, attributes, (function (attribute) {
                              return attribute.name + (" " + attribute.description);
                            }));
              }),
            renderListOption: (function (attr, idx) {
                if (attr.name === Attribute_Helpers$BsConsole.rowCount.name) {
                  return React.createElement(FilterInput$AttributeItem, {
                              name: "count",
                              description: attr.description,
                              custom: attr.custom,
                              triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                              having: isHaving,
                              used: attr.statistics.used,
                              key: attr.name + ("__" + String(idx))
                            });
                } else {
                  return React.createElement(FilterInput$AttributeItem, {
                              name: attr.name,
                              description: attr.description,
                              custom: attr.custom,
                              triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                              having: isHaving,
                              used: attr.statistics.used,
                              key: attr.name + ("__" + String(idx))
                            });
                }
              }),
            renderInput: (function (param) {
                return React.createElement(FilterInput$Input, {
                            onFocus: param.onFocus,
                            placeholder: param.placeholder,
                            value: param.value,
                            onChange: param.onChange,
                            autoFocus: param.autoFocus,
                            ariaLabel: param.ariaLabel
                          });
              }),
            listClassName: listClassName,
            rootClassName: rootClassName,
            onBlur: onBlur$1,
            suppressPopper: true,
            containerFocused: containerFocused
          };
          var tmp$3 = openQueryBuilder !== undefined ? /* tuple */[
              React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* row */5693978),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.borderBottom(Css.px(1), Css.solid, Css.hex("37474F")),
                                /* :: */[
                                  Css.minHeight(Css.px(48)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(Edit.default, {
                        className: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.color(Css.hex("FFFFFF")),
                                    /* :: */[
                                      Css.marginLeft(Css.rem(0.875)),
                                      /* :: */[
                                        Css.marginRight(Css.rem(0.8125)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ])
                      }), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.display(/* flex */-1010954439),
                                /* :: */[
                                  Css.flexDirection(/* column */-963948842),
                                  /* :: */[
                                    Css.alignItems(/* flexStart */662439529),
                                    /* :: */[
                                      Css.paddingTop(Css.rem(0.25)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            className: Css.style(/* :: */[
                                  Css.margin(Css.zero),
                                  /* [] */0
                                ]),
                            children: I18N$BsConsole.show(undefined, "Open Query Builder")
                          }), React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.lineHeight(Css.rem(1.25)),
                                  /* :: */[
                                    Css.fontSize(Css.rem(0.8125)),
                                    /* :: */[
                                      Css.color(Css.hex("CDCDCD")),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "Find events quickly by searching across common fields")))),
              (function (param) {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FilterQueryBuilderOpen */126]));
                  return Curry._1(openQueryBuilder, undefined);
                })
            ] : undefined;
          if (tmp$3 !== undefined) {
            tmp$2.staticItem = Caml_option.valFromOption(tmp$3);
          }
          tmp = React.createElement(BtAutocomplete$BsConsole.make, tmp$2);
        }
        break;
    case /* Pipe */1 :
        tmp = attribute$1 !== undefined && !(operator$1 !== undefined || value$1 !== undefined) ? React.createElement(BtAutocomplete$BsConsole.make, {
                toString: (function (op) {
                    return op;
                  }),
                considerations: Belt_Option.getWithDefault(getAggregationSets(attribute$1), []),
                onDelete: (function (param) {
                    return Curry._1(dispatch, /* SetPipe */Block.__(2, [undefined]));
                  }),
                onChange: (function (_raw, pipe) {
                    return Curry._1(dispatch, /* SetPipe */Block.__(2, [pipe]));
                  }),
                defaultValue: Belt_Option.getWithDefault(pipe$1, ""),
                placeholder: I18N$BsConsole.get(undefined, "[ aggregation ]"),
                autoFocus: true,
                inputAriaLabel: "pipe",
                renderListOption: (function (option, _c) {
                    return React.createElement(FilterInput$StyledListItem, {
                                el: I18N$BsConsole.showSkip(option)
                              });
                  }),
                renderInput: (function (param) {
                    return React.createElement(FilterInput$Input, {
                                onFocus: param.onFocus,
                                placeholder: param.placeholder,
                                value: param.value,
                                onChange: param.onChange,
                                autoFocus: param.autoFocus,
                                ariaLabel: param.ariaLabel
                              });
                  }),
                listClassName: listClassName,
                rootClassName: rootClassName,
                onBlur: onBlur$1,
                suppressPopper: true,
                containerFocused: containerFocused,
                key: "pipe__" + attribute$1.name
              }) : null;
        break;
    case /* Operator */2 :
        if (attribute$1 !== undefined && value$1 === undefined) {
          var attributeName = attribute$1 !== undefined ? attribute$1.name : "None";
          var match$3 = InboxUtils$BsConsole.isNestedIssues(attribute$1.name);
          var match$4 = attribute$1.name === "callstack";
          var __x = match$3 ? Belt_List.toArray(InboxDescribe$BsConsole.filter(attribute$1)) : (
              match$4 ? Belt_List.toArray(Belt_List.keep(attribute$1.filter, (function (op) {
                            if (op !== "equal") {
                              return op !== "not-equal";
                            } else {
                              return false;
                            }
                          }))) : (
                  pipe$1 !== undefined ? Belt_Option.getWithDefault(getOperationSets(attribute$1, pipe$1), []) : Belt_List.toArray(attribute$1.filter)
                )
            );
          tmp = React.createElement(BtAutocomplete$BsConsole.make, {
                toString: (function (op) {
                    return op;
                  }),
                considerations: Belt_Array.concat(__x, permitPipe ? (
                        pipe$1 !== undefined ? [] : (
                            Belt_Option.isSome(getAggregationSets(attribute$1)) ? ["|"] : []
                          )
                      ) : []),
                onDelete: (function (param) {
                    return Curry._1(dispatch, /* SetOperator */Block.__(3, [undefined]));
                  }),
                onChange: (function (_raw, operator) {
                    if (operator === "|") {
                      return Curry._1(dispatch, /* PromptPipe */Block.__(1, [attribute$1]));
                    } else {
                      return Curry._1(dispatch, /* SetOperator */Block.__(3, [operator]));
                    }
                  }),
                defaultValue: Belt_Option.getWithDefault(operator$1, ""),
                placeholder: I18N$BsConsole.get(undefined, "[ operator ]"),
                autoFocus: true,
                inputAriaLabel: "operator",
                renderListOption: (function (option, _c) {
                    if (option === "|") {
                      return React.createElement(React.Fragment, undefined, React.createElement(FilterInput$StyledListItem, {
                                      el: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: Css.style(/* :: */[
                                                  Css.margin(Css.zero),
                                                  /* [] */0
                                                ]),
                                            children: I18N$BsConsole.show(undefined, "Filter on an aggregation result")
                                          })
                                    }));
                    } else {
                      return React.createElement(FilterInput$StyledListItem, {
                                  el: React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.zero),
                                              /* [] */0
                                            ]),
                                        children: I18N$BsConsole.showSkip(option)
                                      })
                                });
                    }
                  }),
                renderInput: (function (param) {
                    return React.createElement(React.Fragment, undefined, React.createElement(FilterInput$Input, {
                                    onFocus: param.onFocus,
                                    placeholder: param.placeholder,
                                    value: param.value,
                                    onChange: param.onChange,
                                    autoFocus: param.autoFocus,
                                    ariaLabel: param.ariaLabel
                                  }));
                  }),
                listClassName: listClassName,
                rootClassName: rootClassName,
                onBlur: onBlur$1,
                suppressPopper: true,
                containerFocused: containerFocused,
                key: "attribute__" + attributeName
              });
        } else {
          tmp = null;
        }
        break;
    case /* Value */3 :
        if (attribute$1 !== undefined && operator$1 !== undefined) {
          var onValueSelection = function (value) {
            if (attribute$1.name === Attribute_Helpers$BsConsole.rowCount.name) {
              Curry._1(onHavingChange, Curry._1(Crdb$BsConsole.Having.create, /* tuple */[
                        "|count",
                        Crdb$BsConsole.HavingOperation.fromTuple(/* tuple */[
                              operator$1,
                              value
                            ])
                      ]));
            } else if (pipe$1 !== undefined) {
              Curry._1(onHavingChange, Curry._1(Crdb$BsConsole.Having.create, /* tuple */[
                        attribute$1.name + ("|" + pipe$1),
                        Crdb$BsConsole.HavingOperation.fromTuple(/* tuple */[
                              operator$1,
                              value
                            ])
                      ]));
            } else {
              Curry._1(onFilterChange, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                        attribute$1.name,
                        Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                              operator$1,
                              value
                            ])
                      ]));
            }
            return Curry._1(dispatch, /* Reset */0);
          };
          var exit = 0;
          if (queryBuilderOpen !== undefined && queryBuilderOpen) {
            var value$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(queryBuilderState, (function (param) {
                        return param[2];
                      })), "");
            tmp = React.createElement(FilterInput$Input, {
                  placeholder: I18N$BsConsole.get(undefined, "[ value ]"),
                  disabled: true,
                  value: value$2,
                  onChange: (function (param) {
                      
                    })
                });
          } else {
            exit = 1;
          }
          if (exit === 1) {
            var match$5 = Belt_List.keep(attribute$1.aggregate, (function (agg) {
                    return agg === "bin";
                  }));
            var exit$1 = 0;
            var exit$2 = 0;
            switch (operator$1) {
              case "assigned-to" :
              case "equal" :
              case "linked-to" :
              case "not-assigned-to" :
              case "not-equal" :
              case "not-linked-to" :
                  exit$2 = 3;
                  break;
              default:
                exit$1 = 2;
            }
            if (exit$2 === 3) {
              if (pipe$1 !== undefined || match$5) {
                exit$1 = 2;
              } else {
                tmp = React.createElement(FilterInput$ValueInputAutocomplete, {
                      attribute: attribute$1,
                      aperture: aperture,
                      havings: havings,
                      projectName: projectName,
                      token: token,
                      onChange: onValueSelection,
                      onDelete: (function (param) {
                          return Curry._1(dispatch, /* SetValue */Block.__(4, [undefined]));
                        }),
                      initialValue: value$1,
                      onBlur: onBlur$1,
                      containerFocused: containerFocused
                    });
              }
            }
            if (exit$1 === 2) {
              tmp = React.createElement(FilterInput$ValueInput, {
                    onChange: onValueSelection,
                    onDelete: (function (param) {
                        return Curry._1(dispatch, /* SetValue */Block.__(4, [undefined]));
                      }),
                    initialValue: value$1,
                    onBlur: onBlur$1
                  });
            }
            
          }
          
        } else {
          tmp = null;
        }
        break;
    
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              flexWrap: /* nowrap */867913355,
              className: Css.style(/* :: */[
                    Css.selector("input::placeholder", /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey5)),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.selector("input::-webkit-input-placeholder ", /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey5)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, attribute$1 !== undefined ? (
                step !== 1 ? (
                    step !== 0 ? (
                        Caml_obj.caml_notequal(attribute$1, Attribute_Helpers$BsConsole.rowCount) ? React.createElement("span", {
                                className: ellipsis + (" " + Css.style(/* :: */[
                                        Css.maxWidth(Css.px(100)),
                                        /* :: */[
                                          Css.marginRight(Css.px(4)),
                                          /* [] */0
                                        ]
                                      ]))
                              }, I18N$BsConsole.showSkip(InboxUtils$BsConsole.getFriendlyName(attribute$1.name))) : (
                            Caml_obj.caml_equal(attribute$1, Attribute_Helpers$BsConsole.rowCount) ? React.createElement("span", {
                                    className: ellipsis + (" " + Css.style(/* :: */[
                                            Css.maxWidth(Css.px(100)),
                                            /* :: */[
                                              Css.marginRight(Css.px(4)),
                                              /* [] */0
                                            ]
                                          ]))
                                  }, I18N$BsConsole.show(undefined, "count")) : null
                          )
                      ) : null
                  ) : React.createElement("span", {
                        className: ellipsis + (" " + Css.style(/* :: */[
                                Css.maxWidth(Css.px(100)),
                                /* :: */[
                                  Css.marginRight(Css.px(4)),
                                  /* [] */0
                                ]
                              ]))
                      }, I18N$BsConsole.showSkip(attribute$1.name + " | "))
              ) : null, Caml_obj.caml_equal(attribute$1, Attribute_Helpers$BsConsole.rowCount) || !(pipe$1 !== undefined && step >= 2) ? null : React.createElement("span", {
                    className: ellipsis + (" " + Css.style(/* :: */[
                            Css.maxWidth(Css.px(100)),
                            /* :: */[
                              Css.marginRight(Css.px(4)),
                              /* [] */0
                            ]
                          ]))
                  }, I18N$BsConsole.showSkip("| " + pipe$1)), operator$1 !== undefined && step >= 3 ? React.createElement("span", {
                    className: ellipsis + (" " + Css.style(/* :: */[
                            Css.maxWidth(Css.px(100)),
                            /* :: */[
                              Css.marginRight(Css.px(4)),
                              /* [] */0
                            ]
                          ]))
                  }, I18N$BsConsole.showSkip(InboxUtils$BsConsole.getFriendlyName(operator$1))) : null, tmp);
}

function make(route, isHaving, primaryPlaceholder, describeRemote, onFilterChange, onHavingChange, onDelete, onBlur, projectName, token, containerFocused, aperture, havings, attribute, operator, value, openQueryBuilder, queryBuilderState, children) {
  var tmp = {
    route: route,
    isHaving: isHaving,
    primaryPlaceholder: primaryPlaceholder,
    describeRemote: describeRemote,
    onFilterChange: onFilterChange,
    onHavingChange: onHavingChange,
    onDelete: onDelete,
    projectName: projectName,
    token: token,
    aperture: aperture,
    havings: havings
  };
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (containerFocused !== undefined) {
    tmp.containerFocused = Caml_option.valFromOption(containerFocused);
  }
  if (attribute !== undefined) {
    tmp.attribute = Caml_option.valFromOption(attribute);
  }
  if (operator !== undefined) {
    tmp.operator = Caml_option.valFromOption(operator);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  if (openQueryBuilder !== undefined) {
    tmp.openQueryBuilder = Caml_option.valFromOption(openQueryBuilder);
  }
  if (queryBuilderState !== undefined) {
    tmp.queryBuilderState = Caml_option.valFromOption(queryBuilderState);
  }
  return ReasonReactCompat.wrapReactForReasonReact(FilterInput$Default, tmp, children);
}

var Re = {
  make: make
};

var Default = {
  reducer: reducer,
  make: FilterInput$Default,
  Re: Re
};

var make$1 = FilterInput$Default;

exports.Style = Style;
exports.Pipe = Pipe;
exports.AttributeItem = AttributeItem;
exports.StyledListItem = StyledListItem;
exports.Input = Input;
exports.ValueInput = ValueInput;
exports.ValueInputAutocomplete = ValueInputAutocomplete;
exports.Default = Default;
exports.reducer = reducer;
exports.make = make$1;
exports.Re = Re;
/* ellipsis Not a pure module */
