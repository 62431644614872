// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../../RR.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Regex$BsConsole = require("../../../Regex.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Column$BsConsole = require("../../../utilities/Column.js");
var Styles$BsConsole = require("../../../utilities/Styles.js");
var DropZone$BsConsole = require("../../../DropZone.js");
var MuiIcons$BsConsole = require("../../../MuiIcons.js");
var AccentCard$BsConsole = require("../../../utilities/AccentCard.js");
var SnackPurveyor$BsConsole = require("../../../SnackPurveyor.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_Metrics$BsConsole = require("./SymbolServers_Metrics.js");

var proxyMessage = I18N$BsConsole.get(undefined, "If proxy options are provided, all fields must be provided.");

var serverMessage = I18N$BsConsole.get(undefined, "If server credentials are provided, all fields must be provided.");

var labelStyle = Css.style(/* :: */[
      Css.marginRight(Css.px(15)),
      /* :: */[
        Css.width(Css.px(100)),
        /* :: */[
          Css.height(Css.px(35)),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* [] */0
          ]
        ]
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.important(Css.marginBottom(Css.px(15))),
      /* :: */[
        Css.width(Css.px(400)),
        /* :: */[
          Css.opacity(0.8),
          /* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
            /* :: */[
              Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
              /* :: */[
                Css.height(Css.px(35)),
                /* :: */[
                  Css.padding(Css.px(10)),
                  /* :: */[
                    Css.fontSize(Css.px(14)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var headerStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* bold */-1055161979),
        /* :: */[
          Css.marginTop(Css.px(20)),
          /* :: */[
            Css.marginBottom(Css.px(20)),
            /* [] */0
          ]
        ]
      ]
    ]);

var errorMessageStyle = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* :: */[
        Css.marginRight(Css.px(50)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.errorDark)),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.marginTop(Css.px(-10)),
              /* :: */[
                Css.marginBottom(Css.px(10)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var downloadOptions_000 = {
  label: I18N$BsConsole.get(undefined, "Number of concurrent symbol downloads"),
  default: 5,
  optionId: "numberOfConcurrentDownload"
};

var downloadOptions_001 = /* :: */[
  {
    label: I18N$BsConsole.get(undefined, "Idle timeout (seconds)"),
    default: 30,
    optionId: "timeout"
  },
  /* [] */0
];

var downloadOptions = /* :: */[
  downloadOptions_000,
  downloadOptions_001
];

function authTypeOptions(symboldVersion) {
  var symboldVersion$1 = Semver.valid(symboldVersion);
  if (symboldVersion$1 !== undefined ? Semver.gte(symboldVersion$1, "2.1.11") : false) {
    return /* :: */[
            /* NoAuth */0,
            /* :: */[
              /* Basic */1,
              /* :: */[
                /* AWS */2,
                /* :: */[
                  /* Google */3,
                  /* [] */0
                ]
              ]
            ]
          ];
  } else {
    return /* :: */[
            /* NoAuth */0,
            /* :: */[
              /* Basic */1,
              /* :: */[
                /* AWS */2,
                /* [] */0
              ]
            ]
          ];
  }
}

function authTostring(auth) {
  switch (auth) {
    case /* NoAuth */0 :
        return "noauth";
    case /* Basic */1 :
        return "basic";
    case /* AWS */2 :
        return "aws";
    case /* Google */3 :
        return "google";
    
  }
}

var component = RR$BsConsole.reducerComponent("SymbolServers_FormView-BsConsole");

function make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, id, whitelist, authType, bucketName, lowerFile, lowerId, usePdb, numberOfConcurrentDownload, param, timeout, param$1, url, name, serverUsername, serverPassword, proxyUsername, proxyPassword, proxyHost, proxyPort, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var proxyPortMessage = match.proxyPortMessage;
              var proxyHostMessage = match.proxyHostMessage;
              var proxyPasswordMessage = match.proxyPasswordMessage;
              var proxyUsernameMessage = match.proxyUsernameMessage;
              var serverPasswordMessage = match.serverPasswordMessage;
              var serverUsernameMessage = match.serverUsernameMessage;
              var secretFile = match.secretFile;
              var nameMessage = match.nameMessage;
              var urlMessage = match.urlMessage;
              var proxyPort = match.proxyPort;
              var proxyHost = match.proxyHost;
              var proxyPassword = match.proxyPassword;
              var proxyUsername = match.proxyUsername;
              var serverPassword = match.serverPassword;
              var serverUsername = match.serverUsername;
              var name = match.name;
              var url = match.url;
              var timeout = match.timeout;
              var numberOfConcurrentDownload = match.numberOfConcurrentDownload;
              var usePdb = match.usePdb;
              var lowerId = match.lowerId;
              var lowerFile = match.lowerFile;
              var bucketName = match.bucketName;
              var authType = match.authType;
              var whitelist = match.whitelist;
              var __x = authTypeOptions(symboldVersion);
              var tmp;
              if (authType === 2 || authType === 1) {
                var tmp$1;
                switch (authType) {
                  case /* NoAuth */0 :
                      tmp$1 = null;
                      break;
                  case /* AWS */2 :
                      tmp$1 = I18N$BsConsole.show(undefined, "Key ID");
                      break;
                  case /* Basic */1 :
                  case /* Google */3 :
                      tmp$1 = I18N$BsConsole.show(undefined, "Username");
                      break;
                  
                }
                var tmp$2;
                switch (authType) {
                  case /* NoAuth */0 :
                      tmp$2 = "";
                      break;
                  case /* AWS */2 :
                      tmp$2 = I18N$BsConsole.get(undefined, "Enter key ID");
                      break;
                  case /* Basic */1 :
                  case /* Google */3 :
                      tmp$2 = I18N$BsConsole.get(undefined, "Enter username");
                      break;
                  
                }
                var tmp$3;
                switch (authType) {
                  case /* NoAuth */0 :
                      tmp$3 = null;
                      break;
                  case /* AWS */2 :
                      tmp$3 = I18N$BsConsole.show(undefined, "Access key");
                      break;
                  case /* Basic */1 :
                  case /* Google */3 :
                      tmp$3 = I18N$BsConsole.show(undefined, "Password");
                      break;
                  
                }
                var tmp$4;
                switch (authType) {
                  case /* NoAuth */0 :
                      tmp$4 = "";
                      break;
                  case /* AWS */2 :
                      tmp$4 = I18N$BsConsole.get(undefined, "Enter access key");
                      break;
                  case /* Basic */1 :
                  case /* Google */3 :
                      tmp$4 = I18N$BsConsole.get(undefined, "Enter password");
                      break;
                  
                }
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              React.createElement("div", {
                                    className: inputStyle
                                  }, React.createElement("label", {
                                        className: labelStyle,
                                        htmlFor: "add-server-username"
                                      }, tmp$1), React.createElement("input", {
                                        className: formStyle,
                                        id: "add-server-username",
                                        label: I18N$BsConsole.get(undefined, "Username"),
                                        placeholder: tmp$2,
                                        value: serverUsername !== undefined ? serverUsername : "",
                                        onChange: (function ($$event) {
                                            var value = $$event.target.value;
                                            return Curry._1(send, /* ServerUsernameUpdate */Block.__(7, [value]));
                                          })
                                      })),
                              React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          errorMessageStyle,
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.marginRight(Css.px(0)),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(serverUsernameMessage !== undefined ? serverUsernameMessage : ""))
                            ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              React.createElement("div", {
                                    className: inputStyle
                                  }, React.createElement("label", {
                                        className: labelStyle,
                                        htmlFor: "add-server-password"
                                      }, tmp$3), React.createElement("input", {
                                        className: formStyle,
                                        id: "add-server-password",
                                        label: I18N$BsConsole.get(undefined, "Password"),
                                        placeholder: tmp$4,
                                        value: serverPassword !== undefined ? serverPassword : "",
                                        onChange: (function ($$event) {
                                            var value = $$event.target.value;
                                            return Curry._1(send, /* ServerPasswordUpdate */Block.__(8, [value]));
                                          })
                                      })),
                              React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          errorMessageStyle,
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.marginRight(Css.px(0)),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.show(undefined, serverPasswordMessage !== undefined ? serverPasswordMessage : ""))
                            ])));
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.minHeight(Css.px(350)),
                                    /* :: */[
                                      Css.borderRadius(Css.px(0)),
                                      /* [] */0
                                    ]
                                  ]
                                ]), undefined, undefined, 0, [React.createElement("form", {
                                    className: Css.style(/* :: */[
                                          Css.height(Css.pct(100)),
                                          /* [] */0
                                        ])
                                  }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                Css.height(Css.pct(100)),
                                                /* [] */0
                                              ]), [
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                          Css.paddingLeft(Css.px(40)),
                                                          /* :: */[
                                                            Css.paddingRight(Css.px(80)),
                                                            /* :: */[
                                                              Css.paddingTop(Css.px(18)),
                                                              /* :: */[
                                                                Css.paddingBottom(Css.px(8)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.width(Css.px(200)),
                                                                  /* [] */0
                                                                ])
                                                          }, React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.fontSize(Css.px(13)),
                                                                      /* :: */[
                                                                        Css.fontFamily("Roboto"),
                                                                        /* :: */[
                                                                          Css.fontWeight(/* medium */-20425611),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ])
                                                              }, id !== undefined ? I18N$BsConsole.show(undefined, "Edit symbol server") : I18N$BsConsole.show(undefined, "Add symbol server"))),
                                                      ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                    Css.display(/* flex */-1010954439),
                                                                    /* :: */[
                                                                      Css.justifyContent(/* spaceBetween */516682146),
                                                                      /* :: */[
                                                                        Css.width(Css.px(100)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(115)),
                                                                          /* :: */[
                                                                            Css.height(Css.pct(80)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]), [
                                                                ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                                            }, I18N$BsConsole.show(undefined, "Save changes")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                className: Css.style(/* :: */[
                                                                                      Css.marginRight(Css.px(10)),
                                                                                      /* [] */0
                                                                                    ])
                                                                              }, ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Save"), undefined, (function (param) {
                                                                                          var hasProxyOptions = proxyUsername !== undefined || proxyPassword !== undefined || proxyHost !== undefined ? true : proxyPort !== undefined;
                                                                                          var hasServerCredentials;
                                                                                          switch (authType) {
                                                                                            case /* NoAuth */0 :
                                                                                                hasServerCredentials = false;
                                                                                                break;
                                                                                            case /* Basic */1 :
                                                                                                hasServerCredentials = serverUsername !== undefined ? true : serverPassword !== undefined;
                                                                                                break;
                                                                                            case /* AWS */2 :
                                                                                                hasServerCredentials = serverUsername !== undefined || serverPassword !== undefined || bucketName !== "" ? true : false;
                                                                                                break;
                                                                                            case /* Google */3 :
                                                                                                hasServerCredentials = serverUsername !== undefined || serverPassword !== undefined || bucketName !== "" ? true : false;
                                                                                                break;
                                                                                            
                                                                                          }
                                                                                          var serverCredentialErrorMessage;
                                                                                          switch (authType) {
                                                                                            case /* NoAuth */0 :
                                                                                                serverCredentialErrorMessage = undefined;
                                                                                                break;
                                                                                            case /* Basic */1 :
                                                                                                serverCredentialErrorMessage = serverUsername !== undefined ? (
                                                                                                    serverPassword !== undefined ? undefined : serverMessage
                                                                                                  ) : (
                                                                                                    serverPassword !== undefined ? serverMessage : undefined
                                                                                                  );
                                                                                                break;
                                                                                            case /* AWS */2 :
                                                                                                serverCredentialErrorMessage = serverUsername !== undefined ? (
                                                                                                    serverPassword !== undefined && bucketName !== "" ? undefined : serverMessage
                                                                                                  ) : (
                                                                                                    serverPassword !== undefined || bucketName !== "" ? serverMessage : undefined
                                                                                                  );
                                                                                                break;
                                                                                            case /* Google */3 :
                                                                                                serverCredentialErrorMessage = bucketName === "" || !(id !== undefined || secretFile !== undefined) ? serverMessage : undefined;
                                                                                                break;
                                                                                            
                                                                                          }
                                                                                          var proxyOptionErrorMessage = proxyUsername !== undefined ? (
                                                                                              proxyPassword !== undefined && proxyHost !== undefined && proxyPort !== undefined ? undefined : proxyMessage
                                                                                            ) : (
                                                                                              proxyPassword !== undefined || proxyHost !== undefined || proxyPort !== undefined ? proxyMessage : undefined
                                                                                            );
                                                                                          var bodyType;
                                                                                          switch (authType) {
                                                                                            case /* NoAuth */0 :
                                                                                                bodyType = hasProxyOptions ? /* RequiredPlusProxy */1 : /* RequiredOnly */0;
                                                                                                break;
                                                                                            case /* Basic */1 :
                                                                                                bodyType = hasServerCredentials ? (
                                                                                                    hasProxyOptions ? /* RequiredProxyCredentials */6 : /* RequiredPlusCredentials */2
                                                                                                  ) : (
                                                                                                    hasProxyOptions ? /* RequiredPlusProxy */1 : /* RequiredOnly */0
                                                                                                  );
                                                                                                break;
                                                                                            case /* AWS */2 :
                                                                                                bodyType = hasServerCredentials ? (
                                                                                                    hasProxyOptions ? /* RequiredProxyCredentialsAWS */7 : /* RequiredPlusCredentialsAWS */3
                                                                                                  ) : (
                                                                                                    hasProxyOptions ? /* RequiredPlusProxy */1 : /* RequiredOnly */0
                                                                                                  );
                                                                                                break;
                                                                                            case /* Google */3 :
                                                                                                bodyType = hasServerCredentials ? (
                                                                                                    hasProxyOptions ? /* RequiredProxyCredentialsGoogle */5 : /* RequiredCredentialsGoogle */4
                                                                                                  ) : (
                                                                                                    hasProxyOptions ? /* RequiredPlusProxy */1 : /* RequiredOnly */0
                                                                                                  );
                                                                                                break;
                                                                                            
                                                                                          }
                                                                                          var timeoutMessageTooSmall = I18N$BsConsole.get(undefined, "Download timeout value must be at least 10 seconds.");
                                                                                          var timeoutMessageTooLarge = I18N$BsConsole.get(undefined, "Download timeout value must be less than 180 seconds.");
                                                                                          var timeoutErrorMessage = timeout < 10 ? timeoutMessageTooSmall : (
                                                                                              timeout > 180 ? timeoutMessageTooLarge : undefined
                                                                                            );
                                                                                          var e;
                                                                                          if (serverCredentialErrorMessage !== undefined) {
                                                                                            e = serverCredentialErrorMessage;
                                                                                          } else if (proxyOptionErrorMessage !== undefined) {
                                                                                            e = proxyOptionErrorMessage;
                                                                                          } else if (timeoutErrorMessage !== undefined) {
                                                                                            e = timeoutErrorMessage;
                                                                                          } else {
                                                                                            new Promise((function (resolve, param) {
                                                                                                    var arg = SymbolServer_API$BsConsole.postSymbolServer(endpoint, universe, project, bodyType, id, {
                                                                                                          name: name !== undefined ? name : "",
                                                                                                          url: url !== undefined ? url : "",
                                                                                                          numberOfConcurrentDownload: numberOfConcurrentDownload,
                                                                                                          timeout: Caml_int32.imul(timeout, 1000),
                                                                                                          whiteList: whitelist,
                                                                                                          proxy: {
                                                                                                            host: proxyHost !== undefined ? proxyHost : "",
                                                                                                            port: proxyPort !== undefined ? 0 + Caml_format.caml_int_of_string(proxyPort) | 0 : 0,
                                                                                                            username: proxyUsername !== undefined ? proxyUsername : "",
                                                                                                            password: proxyPassword !== undefined ? proxyPassword : ""
                                                                                                          },
                                                                                                          serverCredentials: {
                                                                                                            userName: serverUsername !== undefined ? serverUsername : "",
                                                                                                            password: serverPassword !== undefined ? serverPassword : "",
                                                                                                            connection: authType !== 2 ? (
                                                                                                                authType >= 3 ? /* Google */Block.__(1, [{
                                                                                                                        lowerFile: lowerFile,
                                                                                                                        lowerId: lowerId,
                                                                                                                        usePdb: usePdb,
                                                                                                                        bucketName: bucketName,
                                                                                                                        secretFile: secretFile
                                                                                                                      }]) : undefined
                                                                                                              ) : /* Aws */Block.__(0, [{
                                                                                                                    lowerFile: lowerFile,
                                                                                                                    lowerId: lowerId,
                                                                                                                    usePdb: usePdb,
                                                                                                                    bucketName: bucketName
                                                                                                                  }])
                                                                                                          }
                                                                                                        }, undefined);
                                                                                                    return Curry._2((function (param) {
                                                                                                                    return (function (param$1, param$2) {
                                                                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                                      });
                                                                                                                  })(token), true, (function (resp) {
                                                                                                                  if (resp.tag) {
                                                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to create server. Please ensure all fields are valid."));
                                                                                                                    if (id !== undefined) {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerUpdateFailed */3);
                                                                                                                    } else {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerCreationFailed */1);
                                                                                                                    }
                                                                                                                    return resolve(Caml_option.some(resp[0]));
                                                                                                                  }
                                                                                                                  var res = resp[0];
                                                                                                                  var _error = res.error;
                                                                                                                  if (_error !== undefined) {
                                                                                                                    if (id !== undefined) {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerUpdateFailed */3);
                                                                                                                    } else {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerCreationFailed */1);
                                                                                                                    }
                                                                                                                    var m = res.message;
                                                                                                                    if (m !== undefined) {
                                                                                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed to create server") + (". " + m)));
                                                                                                                    } else {
                                                                                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to create server"));
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    if (id !== undefined) {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerUpdated */2);
                                                                                                                    } else {
                                                                                                                      SymbolServers_Metrics$BsConsole.send(/* ServerCreated */0);
                                                                                                                    }
                                                                                                                    Curry._1(hideAddView, undefined);
                                                                                                                    Curry._1(hideEditView, undefined);
                                                                                                                    Curry._1(refetch, undefined);
                                                                                                                  }
                                                                                                                  return resolve(undefined);
                                                                                                                }));
                                                                                                  }));
                                                                                            return ;
                                                                                          }
                                                                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Cannot create server.") + (" " + e)));
                                                                                        }), false, 80, [])))])),
                                                                ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                                            }, I18N$BsConsole.show(undefined, "Discard changes")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Cancel"), undefined, (function (param) {
                                                                                          Curry._1(hideAddView, undefined);
                                                                                          return Curry._1(hideEditView, undefined);
                                                                                        }), false, 80, [])))]))
                                                              ]))
                                                    ])),
                                            React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                                                        /* :: */[
                                                          Css.paddingTop(Css.px(1)),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }),
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.padding(Css.px(20)),
                                                          /* :: */[
                                                            Css.paddingLeft(Css.px(40)),
                                                            /* :: */[
                                                              Css.paddingBottom(Css.px(40)),
                                                              /* :: */[
                                                                Css.height(Css.pct(100)),
                                                                /* :: */[
                                                                  Css.overflowY(/* auto */-1065951377),
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                      className: Css.style(/* :: */[
                                                                            Css.padding(Css.px(5)),
                                                                            /* [] */0
                                                                          ])
                                                                    }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-name"
                                                                                      }, I18N$BsConsole.show(undefined, "Name")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-name",
                                                                                        label: I18N$BsConsole.get(undefined, "Name"),
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter name"),
                                                                                        value: name !== undefined ? name : "",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* NameUpdate */Block.__(6, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.showSkip(nameMessage !== undefined ? nameMessage : ""))
                                                                            ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-authtype"
                                                                                      }, I18N$BsConsole.show(undefined, "Credentials")), React.createElement("select", {
                                                                                        className: formStyle,
                                                                                        value: authTostring(authType),
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* SetAuthType */Block.__(2, [value]));
                                                                                          })
                                                                                      }, Belt_List.toArray(Belt_List.map(__x, (function (option) {
                                                                                                  switch (option) {
                                                                                                    case /* NoAuth */0 :
                                                                                                        return React.createElement("option", {
                                                                                                                    key: "noauth",
                                                                                                                    value: "noauth"
                                                                                                                  }, I18N$BsConsole.show(undefined, "None"));
                                                                                                    case /* Basic */1 :
                                                                                                        return React.createElement("option", {
                                                                                                                    key: "basic",
                                                                                                                    value: "basic"
                                                                                                                  }, I18N$BsConsole.show(undefined, "Basic"));
                                                                                                    case /* AWS */2 :
                                                                                                        return React.createElement("option", {
                                                                                                                    key: "aws",
                                                                                                                    value: "aws"
                                                                                                                  }, I18N$BsConsole.show(undefined, "AWS"));
                                                                                                    case /* Google */3 :
                                                                                                        return React.createElement("option", {
                                                                                                                    key: "google",
                                                                                                                    value: "google"
                                                                                                                  }, I18N$BsConsole.show(undefined, "Google Cloud"));
                                                                                                    
                                                                                                  }
                                                                                                }))))),
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-url"
                                                                                      }, I18N$BsConsole.show(undefined, "URL")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-url",
                                                                                        label: I18N$BsConsole.get(undefined, "Enter URL"),
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter URL"),
                                                                                        value: url !== undefined ? url : "",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* UrlUpdate */Block.__(5, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.show(undefined, urlMessage !== undefined ? urlMessage : ""))
                                                                            ])), React.createElement("div", {
                                                                          className: inputStyle
                                                                        }, React.createElement("label", {
                                                                              className: labelStyle,
                                                                              htmlFor: "add-server-whitelist"
                                                                            }, I18N$BsConsole.show(undefined, "Allowlist")), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, undefined, undefined, /* `Bool */[
                                                                                                737456202,
                                                                                                whitelist
                                                                                              ], (function (param, param$1) {
                                                                                                  return Curry._1(send, /* SetWhitelist */Block.__(1, [!whitelist]));
                                                                                                }), undefined, undefined, undefined, []))),
                                                                                  undefined,
                                                                                  Css.style(/* :: */[
                                                                                        Css.important(Css.marginBottom(Css.px(15))),
                                                                                        /* :: */[
                                                                                          Css.height(Css.px(35)),
                                                                                          /* :: */[
                                                                                            Css.padding(Css.px(10)),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]),
                                                                                  "add-server-whitelist",
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))), React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.marginLeft(Css.px(50)),
                                                                                /* :: */[
                                                                                  Css.width(Css.pct(82)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, authType >= 3 ? React.createElement("div", {
                                                                                className: inputStyle
                                                                              }, React.createElement("label", {
                                                                                    className: labelStyle,
                                                                                    htmlFor: "add-service-secret-file"
                                                                                  }, I18N$BsConsole.show(undefined, "Service secret")), React.createElement("div", {
                                                                                    className: Css.merge(/* :: */[
                                                                                          Css.style(/* :: */[
                                                                                                Css.display(/* flex */-1010954439),
                                                                                                /* :: */[
                                                                                                  Css.flexDirection(/* column */-963948842),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ]),
                                                                                          /* [] */0
                                                                                        ])
                                                                                  }, secretFile !== undefined ? React.createElement("div", {
                                                                                          className: Css.merge(/* :: */[
                                                                                                "extra-smaller-icon",
                                                                                                /* :: */[
                                                                                                  Css.style(/* :: */[
                                                                                                        Css.marginBottom(Css.rem(0.5)),
                                                                                                        /* :: */[
                                                                                                          Css.display(/* flex */-1010954439),
                                                                                                          /* :: */[
                                                                                                            Css.flexDirection(/* row */5693978),
                                                                                                            /* :: */[
                                                                                                              Css.justifyContent(/* center */98248149),
                                                                                                              /* :: */[
                                                                                                                Css.alignItems(/* center */98248149),
                                                                                                                /* [] */0
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ])
                                                                                        }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Caml_option.valFromOption(secretFile).name)])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (param) {
                                                                                                      return Curry._1(send, /* SetFile */Block.__(0, [undefined]));
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  "Close",
                                                                                                  [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                ]))) : null, ReasonReact.element(undefined, undefined, DropZone$BsConsole.make((function (files) {
                                                                                              var file = Belt_Array.get(files, 0);
                                                                                              if (file !== undefined) {
                                                                                                return Curry._1(send, /* SetFile */Block.__(0, [Caml_option.some(Caml_option.valFromOption(file))]));
                                                                                              } else {
                                                                                                return Curry._1(send, /* SetFile */Block.__(0, [undefined]));
                                                                                              }
                                                                                            }), ".json", {
                                                                                            border: "1px dashed #" + Colors$BsConsole.grey5,
                                                                                            marginBottom: "1rem",
                                                                                            padding: "2rem 1rem",
                                                                                            width: "400px",
                                                                                            borderRadius: "3px"
                                                                                          }, [React.createElement("div", {
                                                                                                  className: Css.style(/* :: */[
                                                                                                        Css.display(/* flex */-1010954439),
                                                                                                        /* :: */[
                                                                                                          Css.flexDirection(/* column */-963948842),
                                                                                                          /* :: */[
                                                                                                            Css.justifyContent(/* center */98248149),
                                                                                                            /* :: */[
                                                                                                              Css.alignItems(/* center */98248149),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ])
                                                                                                }, React.createElement("div", {
                                                                                                      className: Css.style(/* :: */[
                                                                                                            Css.marginBottom(Css.rem(0.5)),
                                                                                                            /* [] */0
                                                                                                          ])
                                                                                                    }, I18N$BsConsole.show(undefined, "Drag and drop file here to upload")), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          (function (param) {
                                                                                                              
                                                                                                            }),
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          /* Outlined */-28821822,
                                                                                                          /* Primary */-791844958,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          undefined,
                                                                                                          [I18N$BsConsole.show(undefined, "or select file to upload")]
                                                                                                        ])))])))) : null), React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.marginLeft(Css.px(50)),
                                                                                /* :: */[
                                                                                  Css.width(Css.pct(82)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, tmp, authType >= 2 ? React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                              className: inputStyle
                                                                                            }, React.createElement("label", {
                                                                                                  className: labelStyle,
                                                                                                  htmlFor: "add-server-bucketname"
                                                                                                }, I18N$BsConsole.show(undefined, "Bucket name")), React.createElement("input", {
                                                                                                  className: formStyle,
                                                                                                  id: "add-server-bucketname",
                                                                                                  label: I18N$BsConsole.get(undefined, "Bucket name"),
                                                                                                  placeholder: I18N$BsConsole.get(undefined, "Enter bucket name"),
                                                                                                  value: bucketName,
                                                                                                  onChange: (function ($$event) {
                                                                                                      var value = $$event.target.value;
                                                                                                      return Curry._1(send, /* SetAWSBucketName */Block.__(3, [value]));
                                                                                                    })
                                                                                                }))])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                              className: inputStyle
                                                                                            }, React.createElement("label", {
                                                                                                  className: Css.merge(/* :: */[
                                                                                                        labelStyle,
                                                                                                        /* :: */[
                                                                                                          Css.style(/* :: */[
                                                                                                                Css.width(Css.px(150)),
                                                                                                                /* [] */0
                                                                                                              ]),
                                                                                                          /* [] */0
                                                                                                        ]
                                                                                                      ]),
                                                                                                  htmlFor: "add-server-lowerfile"
                                                                                                }, I18N$BsConsole.show(undefined, "Use lower file name")), React.createElement("input", {
                                                                                                  className: formStyle,
                                                                                                  id: "add-server-lowerfile",
                                                                                                  checked: lowerFile,
                                                                                                  type: "checkbox",
                                                                                                  onChange: (function (_event) {
                                                                                                      return Curry._1(send, /* ToggleLowerFile */0);
                                                                                                    })
                                                                                                }))])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                              className: inputStyle
                                                                                            }, React.createElement("label", {
                                                                                                  className: Css.merge(/* :: */[
                                                                                                        labelStyle,
                                                                                                        /* :: */[
                                                                                                          Css.style(/* :: */[
                                                                                                                Css.width(Css.px(150)),
                                                                                                                /* [] */0
                                                                                                              ]),
                                                                                                          /* [] */0
                                                                                                        ]
                                                                                                      ]),
                                                                                                  htmlFor: "add-server-lowerid"
                                                                                                }, I18N$BsConsole.show(undefined, "Use lower debug ID")), React.createElement("input", {
                                                                                                  className: formStyle,
                                                                                                  id: "add-server-lowerid",
                                                                                                  checked: lowerId,
                                                                                                  type: "checkbox",
                                                                                                  onChange: (function (_event) {
                                                                                                      return Curry._1(send, /* ToggleLowerId */1);
                                                                                                    })
                                                                                                }))]))) : null), React.createElement("div", {
                                                                          className: headerStyle
                                                                        }, I18N$BsConsole.show(undefined, "Proxy options")), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-proxy-username"
                                                                                      }, I18N$BsConsole.show(undefined, "Username")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-proxy-username",
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter username"),
                                                                                        value: proxyUsername !== undefined ? proxyUsername : "",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* ProxyUsernameUpdate */Block.__(9, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.showSkip(proxyUsernameMessage !== undefined ? proxyUsernameMessage : ""))
                                                                            ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-proxy-password"
                                                                                      }, I18N$BsConsole.show(undefined, "Password")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-proxy-password",
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter password"),
                                                                                        value: proxyPassword !== undefined ? proxyPassword : "",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* ProxyPasswordUpdate */Block.__(10, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.showSkip(proxyPasswordMessage !== undefined ? proxyPasswordMessage : ""))
                                                                            ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-proxy-host"
                                                                                      }, I18N$BsConsole.show(undefined, "Proxy host")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-proxy-host",
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter host"),
                                                                                        value: proxyHost !== undefined ? proxyHost : "",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* ProxyHostUpdate */Block.__(11, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.showSkip(proxyHostMessage !== undefined ? proxyHostMessage : ""))
                                                                            ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                              React.createElement("div", {
                                                                                    className: inputStyle
                                                                                  }, React.createElement("label", {
                                                                                        className: labelStyle,
                                                                                        htmlFor: "add-server-proxy-port"
                                                                                      }, I18N$BsConsole.show(undefined, "Port")), React.createElement("input", {
                                                                                        className: formStyle,
                                                                                        id: "add-server-proxy-port",
                                                                                        placeholder: I18N$BsConsole.get(undefined, "Enter port"),
                                                                                        onChange: (function ($$event) {
                                                                                            var value = $$event.target.value;
                                                                                            return Curry._1(send, /* ProxyPortUpdate */Block.__(12, [value]));
                                                                                          })
                                                                                      })),
                                                                              React.createElement("div", {
                                                                                    className: errorMessageStyle
                                                                                  }, I18N$BsConsole.showSkip(proxyPortMessage !== undefined ? proxyPortMessage : ""))
                                                                            ])), React.createElement("div", {
                                                                          className: headerStyle
                                                                        }, I18N$BsConsole.show(undefined, "Download options")), React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.map(downloadOptions, (function (opt) {
                                                                                    var match = opt.optionId;
                                                                                    var tmp;
                                                                                    switch (match) {
                                                                                      case "numberOfConcurrentDownload" :
                                                                                          tmp = numberOfConcurrentDownload;
                                                                                          break;
                                                                                      case "timeout" :
                                                                                          tmp = timeout;
                                                                                          break;
                                                                                      default:
                                                                                        tmp = 0;
                                                                                    }
                                                                                    return React.createElement("div", {
                                                                                                key: opt.optionId,
                                                                                                className: inputStyle
                                                                                              }, React.createElement("label", {
                                                                                                    className: Css.merge(/* :: */[
                                                                                                          labelStyle,
                                                                                                          /* :: */[
                                                                                                            Css.style(/* :: */[
                                                                                                                  Css.width(Css.px(400)),
                                                                                                                  /* [] */0
                                                                                                                ]),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]),
                                                                                                    htmlFor: "add-server-download-" + opt.optionId
                                                                                                  }, I18N$BsConsole.showSkip(opt.label)), React.createElement("input", {
                                                                                                    className: Css.merge(/* :: */[
                                                                                                          formStyle,
                                                                                                          /* :: */[
                                                                                                            Css.style(/* :: */[
                                                                                                                  Css.width(Css.px(50)),
                                                                                                                  /* [] */0
                                                                                                                ]),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]),
                                                                                                    id: "add-server-download-" + opt.optionId,
                                                                                                    value: String(tmp),
                                                                                                    onChange: (function ($$event) {
                                                                                                        var value = $$event.target.value;
                                                                                                        var valueWith0 = value === "" ? "0" : value;
                                                                                                        return Curry._1(send, /* SetDownloadValue */Block.__(4, [
                                                                                                                      opt.optionId,
                                                                                                                      Caml_format.caml_int_of_string(valueWith0)
                                                                                                                    ]));
                                                                                                      })
                                                                                                  }));
                                                                                  })))))]))]))
                                          ])))]));
            }),
          initialState: (function (param) {
              return {
                      whitelist: whitelist,
                      authType: authType,
                      bucketName: bucketName,
                      lowerFile: lowerFile,
                      lowerId: lowerId,
                      usePdb: usePdb,
                      numberOfConcurrentDownload: numberOfConcurrentDownload,
                      timeout: timeout,
                      url: url,
                      name: name,
                      serverUsername: serverUsername,
                      serverPassword: serverPassword,
                      proxyUsername: proxyUsername,
                      proxyPassword: proxyPassword,
                      proxyHost: proxyHost,
                      proxyPort: proxyPort,
                      urlMessage: undefined,
                      nameMessage: undefined,
                      secretFile: undefined,
                      serverUsernameMessage: undefined,
                      serverPasswordMessage: undefined,
                      proxyUsernameMessage: undefined,
                      proxyPasswordMessage: undefined,
                      proxyHostMessage: undefined,
                      proxyPortMessage: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* ToggleLowerFile */0) {
                  var newrecord = Caml_obj.caml_obj_dup(state);
                  return /* Update */Block.__(0, [(newrecord.lowerFile = !state.lowerFile, newrecord)]);
                }
                var newrecord$1 = Caml_obj.caml_obj_dup(state);
                return /* Update */Block.__(0, [(newrecord$1.lowerId = !state.lowerId, newrecord$1)]);
              } else {
                switch (action.tag | 0) {
                  case /* SetFile */0 :
                      var newrecord$2 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$2.secretFile = action[0], newrecord$2)]);
                  case /* SetWhitelist */1 :
                      var newrecord$3 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$3.whitelist = action[0], newrecord$3)]);
                  case /* SetAuthType */2 :
                      var value = action[0];
                      if (value === "google") {
                        var newrecord$4 = Caml_obj.caml_obj_dup(state);
                        return /* Update */Block.__(0, [(newrecord$4.url = "https://storage.googleapis.com", newrecord$4.authType = /* Google */3, newrecord$4)]);
                      }
                      var newrecord$5 = Caml_obj.caml_obj_dup(state);
                      var tmp;
                      switch (value) {
                        case "aws" :
                            tmp = /* AWS */2;
                            break;
                        case "basic" :
                            tmp = /* Basic */1;
                            break;
                        case "google" :
                            tmp = /* Google */3;
                            break;
                        case "noauth" :
                            tmp = /* NoAuth */0;
                            break;
                        default:
                          tmp = /* Basic */1;
                      }
                      newrecord$5.authType = tmp;
                      return /* Update */Block.__(0, [newrecord$5]);
                  case /* SetAWSBucketName */3 :
                      var newrecord$6 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$6.bucketName = action[0], newrecord$6)]);
                  case /* SetDownloadValue */4 :
                      var v = action[1];
                      switch (action[0]) {
                        case "numberOfConcurrentDownload" :
                            var newrecord$7 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$7.numberOfConcurrentDownload = v, newrecord$7)]);
                        case "timeout" :
                            var newrecord$8 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$8.timeout = v, newrecord$8)]);
                        default:
                          return /* NoUpdate */0;
                      }
                  case /* UrlUpdate */5 :
                      var str = action[0];
                      var message = str === "" ? I18N$BsConsole.get(undefined, "Required") : (
                          str.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$9 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$9.urlMessage = message, newrecord$9.url = str, newrecord$9)]);
                  case /* NameUpdate */6 :
                      var str$1 = action[0];
                      var message$1 = str$1 === "" ? I18N$BsConsole.get(undefined, "Required") : (
                          str$1.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                              str$1.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                  Regex$BsConsole.isOnlyAscii(str$1) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                )
                            )
                        );
                      var newrecord$10 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$10.nameMessage = message$1, newrecord$10.name = str$1, newrecord$10)]);
                  case /* ServerUsernameUpdate */7 :
                      var str$2 = action[0];
                      var message$2 = str$2 === "" ? undefined : (
                          str$2.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str$2.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str$2.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str$2) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$11 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$11.serverUsernameMessage = message$2, newrecord$11.serverUsername = str$2, newrecord$11)]);
                  case /* ServerPasswordUpdate */8 :
                      var str$3 = action[0];
                      var message$3 = str$3 === "" ? undefined : (
                          str$3.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str$3.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str$3.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str$3) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$12 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$12.serverPasswordMessage = message$3, newrecord$12.serverPassword = str$3, newrecord$12)]);
                  case /* ProxyUsernameUpdate */9 :
                      var str$4 = action[0];
                      var message$4 = str$4 === "" ? undefined : (
                          str$4.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str$4.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str$4.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str$4) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$13 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$13.proxyUsernameMessage = message$4, newrecord$13.proxyUsername = str$4, newrecord$13)]);
                  case /* ProxyPasswordUpdate */10 :
                      var str$5 = action[0];
                      var message$5 = str$5 === "" ? undefined : (
                          str$5.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str$5.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str$5.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str$5) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$14 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$14.proxyPasswordMessage = message$5, newrecord$14.proxyPassword = str$5, newrecord$14)]);
                  case /* ProxyHostUpdate */11 :
                      var str$6 = action[0];
                      var message$6 = str$6 === "" ? undefined : (
                          str$6.split(" ").length > 1 ? I18N$BsConsole.get(undefined, "Spaces are not allowed") : (
                              str$6.split("").length > 64 ? I18N$BsConsole.get(undefined, "Should be less than 64 characters") : (
                                  str$6.split("").length < 3 ? I18N$BsConsole.get(undefined, "Should be at least 3 characters") : (
                                      Regex$BsConsole.isOnlyAscii(str$6) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                                    )
                                )
                            )
                        );
                      var newrecord$15 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$15.proxyHostMessage = message$6, newrecord$15.proxyHost = str$6, newrecord$15)]);
                  case /* ProxyPortUpdate */12 :
                      var str$7 = action[0];
                      var message$7 = str$7 === "" ? undefined : (
                          str$7.split("").length > 6 ? I18N$BsConsole.get(undefined, "Too long") : (
                              Regex$BsConsole.isOnlyAscii(str$7) !== true ? I18N$BsConsole.get(undefined, "Should only contain ASCII characters") : undefined
                            )
                        );
                      var newrecord$16 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$16.proxyPortMessage = message$7, newrecord$16.proxyPort = str$7, newrecord$16)]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var mininumHeight = 350;

exports.mininumHeight = mininumHeight;
exports.proxyMessage = proxyMessage;
exports.serverMessage = serverMessage;
exports.labelStyle = labelStyle;
exports.formStyle = formStyle;
exports.inputStyle = inputStyle;
exports.headerStyle = headerStyle;
exports.errorMessageStyle = errorMessageStyle;
exports.downloadOptions = downloadOptions;
exports.authTypeOptions = authTypeOptions;
exports.authTostring = authTostring;
exports.component = component;
exports.make = make;
/* proxyMessage Not a pure module */
