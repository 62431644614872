// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Crdb$BsConsole = require("./crdb.js");
var Task$BsConsole = require("./task.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");

var component = ReasonReact.reducerComponent("FetchCrdb");

function make(handleTask, token, name, query, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Request */0);
              };
              return Curry._3(children, self.state.remote, query, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              var json = Crdb$BsConsole.QueryManager.toCrdb(query);
                              return Curry._2(handleTask, (function (json) {
                                            return Curry._1(self.send, /* Success */Block.__(0, [TaskCallback$BsConsole.getJson(json)]));
                                          }), Task$BsConsole.makeTask(/* CRDB */Block.__(2, [
                                                token,
                                                name,
                                                json
                                              ])));
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
