// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Gate$BsConsole = require("../../Gate.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("../../Services.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var Tab = require("@material-ui/core/Tab");
var AlertsUtils$BsConsole = require("../../alerts/AlertsUtils.js");
var Tabs = require("@material-ui/core/Tabs");
var AlertsRouter$BsConsole = require("./AlertsRouter.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var TokenContext$BsConsole = require("../../context/TokenContext.js");
var FeatureService$BsConsole = require("../../FeatureService.js");
var Divider = require("@material-ui/core/Divider");
var WorkflowVersion$BsConsole = require("../../workflow/WorkflowVersion.js");
var IssueBasedAlerts$BsConsole = require("./IssueBasedAlerts.js");
var MessageFeatureGate$BsConsole = require("../../MessageFeatureGate.js");
var ProjectNameContext$BsConsole = require("../../context/ProjectNameContext.js");
var ErrorThresholdAlerts$BsConsole = require("./ErrorThresholdAlerts.js");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.height(Css.px(4)),
        /* :: */[
          Css.margin2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription,
  indicator: indicator
};

function PS_Alerts$AlertTabs(Props) {
  var workflowsVersion = Props.workflowsVersion;
  var activeTab = Props.activeTab;
  var handleTabChange = Props.handleTabChange;
  var config = Props.config;
  var tab = typeof workflowsVersion === "number" ? (
      workflowsVersion !== 0 || activeTab !== undefined && !activeTab ? /* ErrorThresholdAlerts */0 : /* IssueBasedAlerts */1
    ) : (
      activeTab !== undefined && !activeTab ? /* ErrorThresholdAlerts */0 : /* IssueBasedAlerts */1
    );
  var tmp;
  var exit = 0;
  if (typeof workflowsVersion === "number" && workflowsVersion !== 0) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Tab.default, {
          value: /* IssueBasedAlerts */1,
          label: I18N$BsConsole.show(undefined, "Issue Based Alerts"),
          disableRipple: true
        });
  }
  return React.createElement(Tabs.default, {
              value: tab,
              classes: {
                indicator: indicator
              },
              onChange: (function (param, tab) {
                  return Curry._1(handleTabChange, tab);
                }),
              children: null
            }, tmp, Gate$BsConsole.isNotPermitted(undefined, /* ErrorThresholdAlerts */Block.__(5, [config.user])) ? null : React.createElement(Tab.default, {
                    value: /* ErrorThresholdAlerts */0,
                    label: I18N$BsConsole.show(undefined, "Error Threshold Alerts"),
                    disableRipple: true
                  }));
}

var AlertTabs = {
  make: PS_Alerts$AlertTabs
};

function PS_Alerts(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var tab = Props.tab;
  var id = Props.id;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var workflowsVersion = WorkflowVersion$BsConsole.current(config);
  var errorThresholdId = tab !== undefined && !(tab || id === undefined) ? id : undefined;
  var issueBasedId = tab !== undefined && tab && id !== undefined ? id : undefined;
  var endpoint = Services$BsConsole.getEndpoint(undefined)(config.services, AlertsUtils$BsConsole.servicename);
  if (endpoint === undefined) {
    return React.createElement(FeatureService$BsConsole.Unavailable.make, {
                serviceName: AlertsUtils$BsConsole.servicename
              });
  }
  var tmp;
  if (project !== undefined) {
    var exit = 0;
    exit = typeof workflowsVersion === "number" ? (
        workflowsVersion !== 0 || tab !== undefined && !tab ? 1 : 2
      ) : (
        tab !== undefined && !tab ? 1 : 2
      );
    switch (exit) {
      case 1 :
          tmp = React.createElement(MessageFeatureGate$BsConsole.make, {
                showMessage: Gate$BsConsole.isNotPermitted(undefined, /* ErrorThresholdAlerts */Block.__(5, [config.user])),
                children: React.createElement(ErrorThresholdAlerts$BsConsole.make, {
                      config: config,
                      token: token,
                      project: project,
                      endpoint: endpoint,
                      handleChangeUrl: handleChangeUrl,
                      id: errorThresholdId
                    })
              });
          break;
      case 2 :
          tmp = React.createElement(IssueBasedAlerts$BsConsole.make, {
                config: config,
                handleChangeUrl: handleChangeUrl,
                projectName: projectName,
                token: token,
                id: issueBasedId
              });
          break;
      
    }
  } else {
    tmp = React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading6 */5,
          className: Css.style(/* :: */[
                pageMarginBottom,
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Project not found")
        });
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: React.createElement(BtSettings$BsConsole.Paper.make, {
                    className: Css.style(/* :: */[
                          Css.padding(Css.rem(1.0)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading5 */4,
                        className: Css.style(/* :: */[
                              pageMarginBottom,
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Alerts and notifications")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        className: featureDescription,
                        children: I18N$BsConsole.show(undefined, "Monitor queries and take action when they reach specified thresholds.")
                      }), React.createElement(PS_Alerts$AlertTabs, {
                        workflowsVersion: workflowsVersion,
                        activeTab: tab,
                        handleTabChange: (function (tab) {
                            if (tab) {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                            projectName,
                                            {
                                              tab: AlertsRouter$BsConsole.typeToString(/* IssueBasedAlerts */1),
                                              id: undefined
                                            }
                                          ]));
                            } else {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                            projectName,
                                            {
                                              tab: AlertsRouter$BsConsole.typeToString(/* ErrorThresholdAlerts */0),
                                              id: undefined
                                            }
                                          ]));
                            }
                          }),
                        config: config
                      }), React.createElement(Divider.default, {
                        className: Css.style(/* :: */[
                              Css.height(Css.px(2)),
                              /* :: */[
                                pageMarginBottom,
                                /* [] */0
                              ]
                            ])
                      }), tmp)
            });
}

function make(config, handleChangeUrl, tab, id, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_Alerts, {
              config: config,
              handleChangeUrl: handleChangeUrl,
              tab: tab,
              id: id
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_Alerts;

exports.Styles = Styles;
exports.AlertTabs = AlertTabs;
exports.make = make$1;
exports.Re = Re;
/* pageMarginBottom Not a pure module */
