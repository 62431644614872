// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ElementRe = require("bs-webapi/lib/js/src/dom/nodes/ElementRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var SubmissionUrls$BsConsole = require("../project-settings/SubmissionUrls.js");
var BacktraceBrowser$BsConsole = require("./BacktraceBrowser.js");

function callToActionContainer(primaryMain) {
  return Css.style(/* :: */[
              Css.padding(Css.em(1)),
              /* :: */[
                Css.paddingRight(Css.px(55)),
                /* :: */[
                  Css.border(Css.px(1), /* solid */12956715, primaryMain),
                  /* :: */[
                    Css.color(primaryMain),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.justifyContent(/* spaceBetween */516682146),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.marginBottom(Css.px(16)),
                            /* :: */[
                              Css.marginTop(Css.px(45)),
                              /* :: */[
                                Css.position(/* relative */903134412),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function leftBlueBar(primaryMain) {
  return Css.style(/* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.left(Css.px(0)),
                /* :: */[
                  Css.height(Css.pct(100)),
                  /* :: */[
                    Css.width(Css.px(7)),
                    /* :: */[
                      Css.backgroundColor(primaryMain),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var bannerTextAndIconContainer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* [] */0
    ]);

var bannerInfoIcon = Css.style(/* :: */[
      Css.marginRight(Css.em(1)),
      /* [] */0
    ]);

var bannerTextContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

function callToActionButtonPrimary(primaryMain) {
  return Css.style(/* :: */[
              Css.height(Css.px(40)),
              /* :: */[
                Css.width(/* auto */-1065951377),
                /* :: */[
                  Css.backgroundColor(primaryMain),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.white)),
                    /* :: */[
                      Css.marginLeft(Css.px(7)),
                      /* :: */[
                        Css.marginRight(Css.px(7)),
                        /* :: */[
                          Css.overflowY(/* hidden */-862584982),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var buttonWithOutIcon = Css.style(/* :: */[
      Css.paddingLeft(Css.px(20)),
      /* :: */[
        Css.paddingRight(Css.px(20)),
        /* [] */0
      ]
    ]);

var bannerButtonsContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.minWidth(Css.px(400)),
          /* [] */0
        ]
      ]
    ]);

var closeBanner = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(5)),
        /* :: */[
          Css.right(Css.px(5)),
          /* :: */[
            Css.width(Css.px(35)),
            /* :: */[
              Css.height(Css.px(35)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bannerTextMain = Css.style(/* :: */[
      Css.fontSize(Css.em(1.1)),
      /* :: */[
        Css.lineHeight(Css.em(1.5)),
        /* :: */[
          Css.color(Css.rgba(0, 0, 0, 0.87)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  500
                ]),
            /* :: */[
              Css.marginBottom(Css.px(5)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bannerTextSecondary = Css.style(/* :: */[
      Css.fontSize(Css.em(0.9)),
      /* :: */[
        Css.lineHeight(Css.em(1.5)),
        /* :: */[
          Css.color(Css.rgba(0, 0, 0, 0.87)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  callToActionContainer: callToActionContainer,
  leftBlueBar: leftBlueBar,
  bannerTextAndIconContainer: bannerTextAndIconContainer,
  bannerInfoIcon: bannerInfoIcon,
  bannerTextContainer: bannerTextContainer,
  callToActionButtonPrimary: callToActionButtonPrimary,
  buttonWithOutIcon: buttonWithOutIcon,
  bannerButtonsContainer: bannerButtonsContainer,
  closeBanner: closeBanner,
  bannerTextMain: bannerTextMain,
  bannerTextSecondary: bannerTextSecondary
};

function SdkExampleBanner(Props) {
  var template = Props.template;
  var config = Props.config;
  React.useEffect((function () {
          window.scrollTo(0, 0);
          
        }), ([]));
  var urlOrigin = window.location.origin;
  var submissionToken = template.submissionToken;
  var projectName = template.projectName;
  var match = React.useState((function () {
          return true;
        }));
  var setShowBanner = match[1];
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Css.hex(Colors$BsConsole.deoctothorpe(palette.primary.main));
  if (submissionToken === "<BACKTRACE_SUBMISSION_TOKEN>" || submissionToken.trim() === "" || projectName.trim() === "") {
    return null;
  }
  var submissionUrl = SubmissionUrls$BsConsole.createSubmissionUrlFromConfig(config, submissionToken, "json")(undefined, undefined, undefined);
  var htmlContent = BacktraceBrowser$BsConsole.generateExampleHTML(urlOrigin, submissionUrl, projectName);
  var dataUrl = "data:text/html;charset=utf-8," + encodeURIComponent(htmlContent);
  var downloadHTMLFile = function (param) {
    var link = document.createElement("a");
    link.setAttribute("href", dataUrl);
    link.setAttribute("download", "example.html");
    var htmlElement = ElementRe.asHtmlElement(link);
    if (htmlElement !== undefined) {
      Caml_option.valFromOption(htmlElement).click();
      return ;
    }
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error downloading example file"));
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SdkExampleBanner/downloadHTMLFile", Backtrace$BsConsole.Attributes.make(undefined));
    return Backtrace$BsConsole.Client.send(__x, /* `string */[
                288368849,
                "\"Error downloading web sdk example file\""
              ]);
  };
  if (match[0]) {
    return React.createElement(Paper.default, {
                className: Css.merge(/* :: */[
                      callToActionContainer(primaryMain),
                      /* [] */0
                    ]),
                children: null
              }, React.createElement(Mui$BsConsole.IconButton.make, {
                    ariaLabel: "close banner",
                    className: closeBanner,
                    onClick: (function (param) {
                        return Curry._1(setShowBanner, (function (param) {
                                      return false;
                                    }));
                      }),
                    children: React.createElement(Close.default, {
                          fontSize: "small"
                        })
                  }), React.createElement("span", {
                    className: leftBlueBar(primaryMain)
                  }), React.createElement(Row2$BsConsole.make, {
                    className: bannerTextAndIconContainer,
                    children: null
                  }, React.createElement("span", {
                        className: bannerInfoIcon
                      }, React.createElement(Info.default, { })), React.createElement("div", {
                        className: bannerTextContainer
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            className: bannerTextMain,
                            children: I18N$BsConsole.show(undefined, "Explore Backtrace with a browser demo project")
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            className: bannerTextSecondary,
                            children: I18N$BsConsole.show(undefined, "Download our example project, view clear code examples, and send test errors directly to your project.")
                          }))), React.createElement("div", {
                    className: bannerButtonsContainer
                  }, React.createElement(Button.default, {
                        variant: "raised",
                        className: Css.merge(/* :: */[
                              callToActionButtonPrimary(primaryMain),
                              /* :: */[
                                buttonWithOutIcon,
                                /* [] */0
                              ]
                            ]),
                        onClick: (function (param) {
                            downloadHTMLFile(undefined);
                            return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* DownloadedWebSdkExample */135]));
                          }),
                        children: I18N$BsConsole.show(undefined, "Download example.html")
                      })));
  } else {
    return null;
  }
}

var component = ReasonReact.statelessComponent("SdkExampleBanner");

function make(template, config) {
  var partial_arg = {
    template: template,
    config: config
  };
  return (function (param) {
      return ReasonReactCompat.wrapReactForReasonReact(SdkExampleBanner, partial_arg, param);
    });
}

var Jsx2 = {
  component: component,
  make: make
};

var GuideTemplate;

var make$1 = SdkExampleBanner;

exports.GuideTemplate = GuideTemplate;
exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* bannerTextAndIconContainer Not a pure module */
