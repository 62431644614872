// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");

var component = RR$BsConsole.statelessComponent("WfIntegerInput-BsConsole");

function make(placeholder, value, onChange, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var tmp;
              tmp = typeof value === "number" || !value.tag ? false : true;
              var tmp$1;
              tmp$1 = typeof value === "number" || !value.tag ? undefined : Caml_option.some(I18N$BsConsole.show(undefined, "Not a valid integer"));
              var tmp$2;
              tmp$2 = typeof value === "number" ? /* `String */[
                  -976970511,
                  ""
                ] : (
                  value.tag ? /* `String */[
                      -976970511,
                      value[0]
                    ] : /* `Int */[
                      3654863,
                      value[0]
                    ]
                );
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                              undefined,
                              undefined,
                              placeholder,
                              undefined,
                              (function (e) {
                                  var str = e.target.value;
                                  var tmp;
                                  if (str === "") {
                                    tmp = /* Empty */0;
                                  } else {
                                    try {
                                      tmp = /* Valid */Block.__(0, [Caml_format.caml_int_of_string(str)]);
                                    }
                                    catch (exn){
                                      tmp = /* Invalid */Block.__(1, [str]);
                                    }
                                  }
                                  return Curry._1(onChange, tmp);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              tmp,
                              tmp$1,
                              undefined,
                              tmp$2,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              []
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
