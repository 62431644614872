// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Fuzz$BsConsole = require("../bindings/Fuzz.js");
var I18N$BsConsole = require("../I18N.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");
var Autosuggest$BsConsole = require("../bindings/Autosuggest.js");
var Chip = require("@material-ui/core/Chip");
var Paper = require("@material-ui/core/Paper");
var Cancel = require("@material-ui/icons/Cancel");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var Typography = require("@material-ui/core/Typography");

function make(props, children) {
  return ReasonReact.wrapJsForReason(Typography.default, props, children);
}

var Typography$1 = {
  make: make
};

function make$1(props, children) {
  return ReasonReact.wrapJsForReason(TextField.default, props, children);
}

var TextField$1 = {
  make: make$1
};

function make$2(props, children) {
  return ReasonReact.wrapJsForReason(Chip.default, props, children);
}

var Chip$1 = {
  make: make$2
};

function make$3(props, children) {
  return ReasonReact.wrapJsForReason(Paper.default, props, children);
}

var Paper$1 = {
  make: make$3
};

function make$4(props, children) {
  return ReasonReact.wrapJsForReason(MenuItem.default, props, children);
}

var MenuItem$1 = {
  make: make$4
};

function make$5(props, children) {
  return ReasonReact.wrapJsForReason(Cancel.default, props, children);
}

var CancelIcon = {
  make: make$5
};

function objAssign(obj1, obj2) {
  return Object.assign(Object.assign({ }, obj1), obj2);
}

function renderInputComponent(inputProps) {
  var props = {
    type: inputProps.type,
    autoComplete: inputProps.autoComplete,
    key: inputProps.key,
    onBlur: inputProps.onBlur,
    onChange: inputProps.onChange,
    onFocus: inputProps.onFocus,
    onKeyDown: inputProps.onKeyDown,
    placeholder: inputProps.placeholder,
    style: inputProps.style,
    value: inputProps.value,
    fullWidth: true,
    InputProps: {
      inputRef: (function (node) {
          inputProps.ref(node);
          var cb = inputProps.inputRef;
          if (!(cb == null)) {
            return Curry._1(cb, node);
          }
          
        }),
      classes: {
        input: inputProps.classes.input
      }
    }
  };
  return RR$BsConsole.element(undefined, undefined, ReasonReact.wrapJsForReason(TextField.default, props, []));
}

function renderSuggestion(getLabel, suggestion, stuff) {
  var label = Curry._1(getLabel, suggestion);
  var query = stuff.query;
  var isHighlighted = stuff.isHighlighted;
  var parts = Fuzz$BsConsole.getMatchParts(label, query);
  var children = [React.createElement("div", undefined, Belt_Array.mapWithIndex(parts, (function (index, part) {
                if (part.highlight) {
                  return React.createElement("span", {
                              key: String(index),
                              style: {
                                fontWeight: "500"
                              }
                            }, I18N$BsConsole.showSkip(part.text));
                } else {
                  return React.createElement("strong", {
                              key: String(index),
                              style: {
                                fontWeight: "300"
                              }
                            }, I18N$BsConsole.showSkip(part.text));
                }
              })))];
  return ReasonReact.element(undefined, undefined, ReasonReact.wrapJsForReason(MenuItem.default, {
                  selected: isHighlighted,
                  component: "div",
                  style: {
                    padding: "6px 8px",
                    fontSize: "14px"
                  }
                }, children));
}

function getSuggestions(considerations, getLabel, value, param) {
  var inputLength = value.length;
  if (inputLength === 0) {
    return [];
  }
  var stuff = FuzzaldrinPlus.filter(Belt_Array.map(considerations, getLabel), value);
  return Belt_Array.map(stuff, (function (label) {
                return Belt_Option.getExn(Caml_option.undefined_to_opt(considerations.find((function (item) {
                                      return Curry._1(getLabel, item) === label;
                                    }))));
              }));
}

var root = Css.style(/* :: */[
      Css.flexGrow(1),
      /* [] */0
    ]);

var container = Css.style(/* :: */[
      Css.position(Css.relative),
      /* [] */0
    ]);

var suggestionsContainerOpen = Css.style(/* :: */[
      Css.position(Css.absolute),
      /* :: */[
        Css.zIndex(1),
        /* :: */[
          Css.marginTop(Css.px(4)),
          /* :: */[
            Css.left(Css.zero),
            /* :: */[
              Css.top(Css.zero),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var suggestion = Css.style(/* :: */[
      Css.display(Css.block),
      /* [] */0
    ]);

var suggestionsList = Css.style(/* :: */[
      Css.margin(Css.zero),
      /* :: */[
        Css.padding(Css.zero),
        /* :: */[
          Css.listStyleType(Css.none),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  root: root,
  container: container,
  suggestionsContainerOpen: suggestionsContainerOpen,
  suggestion: suggestion,
  suggestionsList: suggestionsList
};

function Make(Cfg) {
  var component = RR$BsConsole.reducerComponent("Autocomplete-BsConsole");
  var make = function (placeholder, considerations, value, onChange, onSelect, param) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (param) {
                var send = param.send;
                return React.createElement("div", {
                            className: root
                          }, ReasonReact.element(undefined, undefined, Autosuggest$BsConsole.make(renderInputComponent, param.state.suggestions, (function (e) {
                                      return Curry._1(send, /* FetchSuggestions */[e.value]);
                                    }), (function (param) {
                                      return Curry._1(send, /* ClearSuggestions */0);
                                    }), Cfg.getLabel, (function (param, param$1) {
                                      return renderSuggestion(Cfg.getLabel, param, param$1);
                                    }), {
                                    classes: {
                                      root: root
                                    },
                                    placeholder: placeholder,
                                    value: value,
                                    onChange: (function (e) {
                                        var value = e.target.value;
                                        if (!(value == null)) {
                                          return Curry._1(onChange, value);
                                        }
                                        
                                      })
                                  }, (function (param, info) {
                                      return Curry._1(onSelect, info.suggestion);
                                    }), {
                                    container: container,
                                    suggestionsContainerOpen: suggestionsContainerOpen,
                                    suggestionsList: suggestionsList,
                                    suggestion: suggestion
                                  }, (function (options) {
                                      var open_ = Belt_Option.isSome(Caml_option.nullable_to_opt(options.children));
                                      var children = options.children;
                                      var tmp;
                                      if (children == null) {
                                        tmp = null;
                                      } else {
                                        var children$1 = [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, {
                                                    root: Css.style(/* :: */[
                                                          Css.margin2(Css.px(4), Css.zero),
                                                          /* :: */[
                                                            Css.maxHeight(Css.px(200)),
                                                            /* :: */[
                                                              Css.overflow(Css.auto),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ])
                                                  }, undefined, [children]))];
                                        tmp = ReasonReact.element(undefined, undefined, ReasonReact.wrapJsForReason(Paper.default, {
                                                  square: true,
                                                  style: {
                                                    marginTop: open_ ? "30px" : "0",
                                                    marginLeft: open_ ? "-6px" : "0"
                                                  }
                                                }, children$1));
                                      }
                                      return RR$BsConsole.createDomElement("div", options.containerProps, [tmp]);
                                    }), [])));
              }),
            initialState: (function (param) {
                return {
                        suggestions: []
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, state) {
                if (action) {
                  return /* Update */Block.__(0, [{
                              suggestions: getSuggestions(considerations, Cfg.getLabel, action[0], undefined)
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              suggestions: []
                            }]);
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          component: component,
          make: make
        };
}

exports.Typography = Typography$1;
exports.TextField = TextField$1;
exports.Chip = Chip$1;
exports.Paper = Paper$1;
exports.MenuItem = MenuItem$1;
exports.CancelIcon = CancelIcon;
exports.objAssign = objAssign;
exports.renderInputComponent = renderInputComponent;
exports.renderSuggestion = renderSuggestion;
exports.getSuggestions = getSuggestions;
exports.Styles = Styles;
exports.Make = Make;
/* root Not a pure module */
