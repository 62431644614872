// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function Make(F, S, C) {
  var uniqFold = function (l) {
    if (!l) {
      return /* [] */0;
    }
    var hd = l[0];
    return /* :: */[
            hd,
            uniqFold(Belt_List.keep(l[1], (function (v) {
                        return Caml_obj.caml_notequal(Curry._1(F.getId, hd), Curry._1(F.getId, v));
                      })))
          ];
  };
  var add = function (a$prime, t$prime) {
    return uniqFold(Belt_List.add(t$prime, a$prime));
  };
  var remove = function (a$prime, t$prime) {
    return Belt_List.keep(t$prime, (function (a$prime$prime) {
                  return Caml_obj.caml_notequal(a$prime$prime, a$prime);
                }));
  };
  var keep = function (predicate, t$prime) {
    return Belt_List.keep(t$prime, predicate);
  };
  var getBy = function (predicate, t$prime) {
    return Belt_List.getBy(t$prime, predicate);
  };
  var toCrdb = S.toCrdb;
  var getAttributeAggregateIndex = function (attribute$prime, aggregate$prime, t$prime) {
    var __x = Json_decode.optional((function (param) {
            return Json_decode.field(attribute$prime, (function (param) {
                          return Json_decode.array((function (j) {
                                        return Json_decode.string(Belt_Array.getExn(Json_decode.array((function (e) {
                                                              return e;
                                                            }), j), 0));
                                      }), param);
                        }), param);
          }), Curry._1(toCrdb, t$prime));
    return Belt_Option.map(__x, (function (folds) {
                  return folds.indexOf(aggregate$prime);
                }));
  };
  var eq = function (t$prime, t$prime$prime) {
    return Belt_Set.eq(Belt_Set.fromArray(Belt_List.toArray(t$prime), C), Belt_Set.fromArray(Belt_List.toArray(t$prime$prime), C));
  };
  return {
          size: Belt_List.size,
          empty: /* [] */0,
          add: add,
          getBy: getBy,
          remove: remove,
          toJson: S.toJson,
          fromJson: S.fromJson,
          eq: eq,
          toCrdb: toCrdb,
          toArray: Belt_List.toArray,
          fromArray: Belt_List.fromArray,
          keep: keep,
          getAttributeAggregateIndex: getAttributeAggregateIndex
        };
}

exports.Make = Make;
/* No side effect */
