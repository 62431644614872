// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");

function getEvents(token, path) {
  return new Promise((function (resolve, reject) {
                Task2$BsConsole.raw(token, /* Custom */Block.__(1, [path]), undefined, /* Get */0, undefined, (function (text) {
                        try {
                          return resolve(Json_decode.array((function (v) {
                                            return v;
                                          }), Json.parseOrRaise(text)));
                        }
                        catch (raw_exn){
                          var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                          var __x = Backtrace$BsConsole.Attributes.make(undefined);
                          Backtrace$BsConsole.Client.send(__x, /* `exn */[
                                5049499,
                                Uncaught$BsConsole.castToJsExn(exn)
                              ]);
                          return reject(exn);
                        }
                      }));
                
              }));
}

exports.getEvents = getEvents;
/* Task2-BsConsole Not a pure module */
