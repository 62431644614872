// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Delete = require("@material-ui/icons/Delete");
var TableRow = require("@material-ui/core/TableRow");
var TableCell = require("@material-ui/core/TableCell");

function TeamsProjectMemberUserRow(Props) {
  Props.token;
  var projectMember = Props.projectMember;
  var allUsers = Props.allUsers;
  var project = Props.project;
  var setDeleteDialog = Props.setDeleteDialog;
  var userData = Belt_Array.get(Belt_Array.keep(allUsers, (function (user) {
              return user.uid === projectMember.user;
            })), 0);
  if (userData !== undefined) {
    return React.createElement(TableRow.default, {
                children: null,
                key: String(projectMember.project) + String(projectMember.user)
              }, React.createElement(TableCell.default, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableBody */14,
                          className: Css.style(/* :: */[
                                Css.marginLeft(Css.px(48)),
                                /* [] */0
                              ]),
                          color: Colors$BsConsole.grey0,
                          children: I18N$BsConsole.showSkip(userData.username)
                        })
                  }), React.createElement(TableCell.default, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableBody */14,
                          color: Colors$BsConsole.grey0,
                          children: I18N$BsConsole.show(undefined, "User")
                        })
                  }), React.createElement(TableCell.default, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableBody */14,
                          color: Colors$BsConsole.grey0,
                          children: I18N$BsConsole.show(undefined, "1")
                        })
                  }), React.createElement(TableCell.default, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableBody */14,
                          color: Colors$BsConsole.grey0,
                          children: I18N$BsConsole.dynamic(projectMember.role)
                        })
                  }), React.createElement(TableCell.default, {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.justifyContent(/* flexEnd */924268066),
                            /* :: */[
                              Css.important(Css.marginBottom(Css.px(-1))),
                              /* [] */0
                            ]
                          ]
                        ]),
                    children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                          ariaLabel: "Remove user access to project",
                          onClick: (function (_e) {
                              return Curry._1(setDeleteDialog, (function (param) {
                                            return /* ProjectMemberUser */Block.__(3, [
                                                      projectMember.project,
                                                      projectMember.user,
                                                      userData,
                                                      project
                                                    ]);
                                          }));
                            }),
                          title_tooltip: I18N$BsConsole.get(undefined, "Remove user access to project"),
                          children: React.createElement(Delete.default, { })
                        })
                  }));
  } else {
    return React.createElement(TableRow.default, {
                children: React.createElement(TableCell.default, {
                      colSpan: 5,
                      children: null
                    })
              });
  }
}

var make = TeamsProjectMemberUserRow;

exports.make = make;
/* Css Not a pure module */
