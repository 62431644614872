// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var Format$BsConsole = require("./bs-crdb-response/src/Format.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var Typeahead$BsConsole = require("./typeahead/Typeahead.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

var rootList = Css.style(/* :: */[
      Css.paddingTop(Css.zero),
      /* :: */[
        Css.paddingBottom(Css.zero),
        /* [] */0
      ]
    ]);

var listItem = Css.style(/* :: */[
      Css.unsafe("outline", "none"),
      /* :: */[
        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey625)),
        /* [] */0
      ]
    ]);

var Style = {
  rootList: rootList,
  listItem: listItem
};

var component = ReasonReact.reducerComponent("FilterValue-BsConsole__ValueInput");

function initialState(param) {
  return {
          value: ""
        };
}

function make(onChange, onDelete, initialValueOpt, _children) {
  var initialValue = initialValueOpt !== undefined ? initialValueOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              if (initialValue === "") {
                return ;
              } else {
                return Curry._1(self.send, /* SetInputValue */[initialValue]);
              }
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return React.createElement("div", {
                          onKeyDown: (function ($$event) {
                              var keycode = $$event.keyCode;
                              if (keycode !== 8) {
                                if (keycode !== 13) {
                                  return ;
                                } else {
                                  return Curry._1(onChange, /* tuple */[
                                              self.state.value,
                                              0
                                            ]);
                                }
                              } else if (self.state.value === "") {
                                return Curry._1(onDelete, undefined);
                              } else {
                                return ;
                              }
                            })
                        }, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, rootList, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                      undefined,
                                                      undefined,
                                                      /* `String */[
                                                        -976970511,
                                                        self.state.value
                                                      ],
                                                      (function ($$event) {
                                                          var value = $$event.target.value;
                                                          return Curry._1(self.send, /* SetInputValue */[value]);
                                                        }),
                                                      "[ value ]",
                                                      true,
                                                      true,
                                                      true,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      []
                                                    ]))]))])));
            }),
          initialState: initialState,
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          value: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var ValueInput = {
  Style: Style,
  component: component,
  initialState: initialState,
  make: make
};

function getCount(param) {
  return Caml_option.some(param[1]);
}

function toString(t$prime) {
  return t$prime[0];
}

var fuzz = Util$BsConsole.Fuzz.filterValue;

var ValueTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: "[ value ]",
      fuzz: fuzz
    });

var component$1 = ReasonReact.reducerComponent("FilterValue-BsConsole");

function make$1(attribute, filter, setSelection, name, token, handleTask, aperture, onDelete, initialValueOpt, _children) {
  var initialValue = initialValueOpt !== undefined ? initialValueOpt : "";
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: (function (self) {
              try {
                List.find((function (agg) {
                        return agg === "bin";
                      }), attribute.aggregate);
                return ;
              }
              catch (exn){
                if (exn === Caml_builtin_exceptions.not_found) {
                  switch (filter) {
                    case "assigned-to" :
                    case "equal" :
                    case "linked-to" :
                    case "not-assigned-to" :
                    case "not-equal" :
                    case "not-linked-to" :
                        return Curry._1(self.send, /* Request */0);
                    default:
                      return ;
                  }
                } else {
                  throw exn;
                }
              }
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var exit = 0;
              switch (filter) {
                case "assigned-to" :
                case "equal" :
                case "linked-to" :
                case "not-assigned-to" :
                case "not-equal" :
                case "not-linked-to" :
                    exit = 1;
                    break;
                default:
                  return ReasonReact.element(attribute.name, undefined, make((function (param) {
                                    return Curry._1(setSelection, /* tuple */[
                                                param[0],
                                                param[1]
                                              ]);
                                  }), onDelete, initialValue, []));
              }
              if (exit === 1) {
                var match = self.state.remote;
                var exit$1 = 0;
                if (typeof match === "number") {
                  if (match === /* NotAsked */0) {
                    return ReasonReact.element("not-asked", undefined, make((function (param) {
                                      return Curry._1(setSelection, /* tuple */[
                                                  param[0],
                                                  param[1]
                                                ]);
                                    }), onDelete, initialValue, []));
                  }
                  exit$1 = 2;
                } else if (match.tag) {
                  exit$1 = 2;
                } else {
                  var l = match[0][1];
                  if (attribute.name === Issues$BsConsole.Assignees.columnName) {
                    var uniq = function (l) {
                      if (!l) {
                        return /* [] */0;
                      }
                      var hd = l[0];
                      return /* :: */[
                              hd,
                              uniq(Belt_List.keep(l[1], (function (v) {
                                          return Caml_obj.caml_notequal(v, hd);
                                        })))
                            ];
                    };
                    var __x = List.map((function (param) {
                            var match = Format$BsConsole.parseTicketsFromJson(param[0]);
                            if (match !== undefined && match.tag === /* IssueTickets */13) {
                              return match[0];
                            }
                            
                          }), List.hd(List.hd(l)));
                    var __x$1 = Belt_List.keep(__x, Belt_Option.isSome);
                    var __x$2 = Belt_List.flatten(Belt_List.map(__x$1, Belt_Option.getExn));
                    var __x$3 = Belt_List.map(__x$2, (function (ticket) {
                            switch (ticket.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  return ;
                              case /* InteralAssigneeLink */2 :
                                  return ticket[0].assignees;
                              
                            }
                          }));
                    var __x$4 = Belt_List.keep(__x$3, Belt_Option.isSome);
                    var __x$5 = Belt_List.flatten(Belt_List.map(__x$4, Belt_Option.getExn));
                    var __x$6 = Belt_List.map(__x$5, (function (ticket) {
                            if (ticket.tag) {
                              return ;
                            } else {
                              return ticket[0].username;
                            }
                          }));
                    var __x$7 = Belt_List.keep(__x$6, Belt_Option.isSome);
                    var __x$8 = Belt_List.map(__x$7, Belt_Option.getExn);
                    var usernames = uniq(Belt_List.map(__x$8, (function (u) {
                                return /* tuple */[
                                        u,
                                        0
                                      ];
                              })));
                    return ReasonReact.element(attribute.name + "__success", undefined, Curry.app(ValueTypeahead.make, [
                                    undefined,
                                    undefined,
                                    usernames,
                                    (function (param) {
                                        return Curry._1(setSelection, /* tuple */[
                                                    param[0],
                                                    param[1]
                                                  ]);
                                      }),
                                    (function (name) {
                                        return Curry._1(setSelection, /* tuple */[
                                                    name,
                                                    0
                                                  ]);
                                      }),
                                    onDelete,
                                    initialValue,
                                    undefined,
                                    undefined,
                                    []
                                  ]));
                  }
                  if (attribute.name !== Issues$BsConsole.Tickets.columnName) {
                    return ReasonReact.element(attribute.name + "__success", undefined, Curry.app(ValueTypeahead.make, [
                                    undefined,
                                    undefined,
                                    List.hd(List.hd(l)),
                                    (function (param) {
                                        return Curry._1(setSelection, /* tuple */[
                                                    param[0],
                                                    param[1]
                                                  ]);
                                      }),
                                    (function (name) {
                                        return Curry._1(setSelection, /* tuple */[
                                                    name,
                                                    0
                                                  ]);
                                      }),
                                    onDelete,
                                    initialValue,
                                    undefined,
                                    undefined,
                                    []
                                  ]));
                  }
                  var uniq$1 = function (l) {
                    if (!l) {
                      return /* [] */0;
                    }
                    var hd = l[0];
                    return /* :: */[
                            hd,
                            uniq$1(Belt_List.keep(l[1], (function (v) {
                                        return Caml_obj.caml_notequal(v, hd);
                                      })))
                          ];
                  };
                  var __x$9 = List.map((function (param) {
                          var match = Format$BsConsole.parseTicketsFromJson(param[0]);
                          if (match !== undefined && match.tag === /* IssueTickets */13) {
                            return match[0];
                          }
                          
                        }), List.hd(List.hd(l)));
                  var __x$10 = Belt_List.keep(__x$9, Belt_Option.isSome);
                  var __x$11 = Belt_List.map(__x$10, Belt_Option.getExn);
                  var __x$12 = List.concat(Belt_List.map(__x$11, (function (uns) {
                              return Belt_List.map(uns, (function (u) {
                                            switch (u.tag | 0) {
                                              case /* Unlinked */0 :
                                                  return u[0].short;
                                              case /* Linked */1 :
                                              case /* InteralAssigneeLink */2 :
                                                  return ;
                                              
                                            }
                                          }));
                            })));
                  var __x$13 = Belt_List.keep(__x$12, Belt_Option.isSome);
                  var __x$14 = Belt_List.map(__x$13, Belt_Option.getExn);
                  var tickets = uniq$1(Belt_List.map(__x$14, (function (u) {
                              return /* tuple */[
                                      u,
                                      0
                                    ];
                            })));
                  return ReasonReact.element(attribute.name + "__success", undefined, Curry.app(ValueTypeahead.make, [
                                  undefined,
                                  undefined,
                                  tickets,
                                  (function (param) {
                                      return Curry._1(setSelection, /* tuple */[
                                                  param[0],
                                                  param[1]
                                                ]);
                                    }),
                                  (function (name) {
                                      return Curry._1(setSelection, /* tuple */[
                                                  name,
                                                  0
                                                ]);
                                    }),
                                  onDelete,
                                  initialValue,
                                  undefined,
                                  undefined,
                                  []
                                ]));
                }
                if (exit$1 === 2) {
                  return ReasonReact.element("failure", undefined, Curry.app(ValueTypeahead.make, [
                                  undefined,
                                  undefined,
                                  /* :: */[
                                    /* tuple */[
                                      "Loading...",
                                      0
                                    ],
                                    /* [] */0
                                  ],
                                  (function (param) {
                                      
                                    }),
                                  undefined,
                                  onDelete,
                                  initialValue,
                                  undefined,
                                  undefined,
                                  []
                                ]));
                }
                
              }
              
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              } else {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              var attributeName = attribute.name === Issues$BsConsole.Assignees.columnName ? Issues$BsConsole.Tickets.columnName : attribute.name;
                              var query_002 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                        attributeName,
                                        /* Distribution */Block.__(1, [50])
                                      ]), Crdb$BsConsole.Fold.empty);
                              var query = /* Aggregate */Block.__(0, [
                                  aperture,
                                  undefined,
                                  query_002,
                                  undefined,
                                  undefined,
                                  undefined
                                ]);
                              var json = Crdb$BsConsole.QueryManager.toCrdb(query);
                              return Curry._2(handleTask, (function (json) {
                                            var s = Crdb$BsConsole.smellyParseCRDB(TaskCallback$BsConsole.getJson(json));
                                            if (s !== undefined) {
                                              return Curry._1(self.send, /* Success */[s]);
                                            } else {
                                              return Curry._1(self.send, /* Success */[/* tuple */[
                                                            "",
                                                            /* :: */[
                                                              /* :: */[
                                                                /* [] */0,
                                                                /* [] */0
                                                              ],
                                                              /* [] */0
                                                            ],
                                                            0
                                                          ]]);
                                            }
                                          }), Task$BsConsole.makeTask(/* CRDB */Block.__(2, [
                                                token,
                                                name,
                                                json
                                              ])));
                            })
                        ]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.ValueInput = ValueInput;
exports.ValueTypeahead = ValueTypeahead;
exports.component = component$1;
exports.make = make$1;
/* rootList Not a pure module */
