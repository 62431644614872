// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var component = ReasonReact.statelessComponent("BsVx_Shape__Bar-BsConsole");

function make(className, xOpt, yOpt, width, height, rx, ry, fillOpt, fillOpacity, stroke, strokeWidth, strokeDasharray, onMouseOver, onMouseMove, onMouseEnter, onMouseLeave, children) {
  var x = xOpt !== undefined ? xOpt : 0;
  var y = yOpt !== undefined ? yOpt : 0;
  var fill = fillOpt !== undefined ? fillOpt : Colors$BsConsole.octothorpe(Colors$BsConsole.black);
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var tmp = {
                x: x,
                y: y,
                fill: fill
              };
              if (className !== undefined) {
                tmp.className = Caml_option.valFromOption(className);
              }
              if (width !== undefined) {
                tmp.width = Caml_option.valFromOption(width);
              }
              if (height !== undefined) {
                tmp.height = Caml_option.valFromOption(height);
              }
              if (rx !== undefined) {
                tmp.rx = Caml_option.valFromOption(rx);
              }
              if (ry !== undefined) {
                tmp.ry = Caml_option.valFromOption(ry);
              }
              if (fillOpacity !== undefined) {
                tmp.fillOpacity = Caml_option.valFromOption(fillOpacity);
              }
              if (stroke !== undefined) {
                tmp.stroke = Caml_option.valFromOption(stroke);
              }
              if (strokeWidth !== undefined) {
                tmp.strokeWidth = Caml_option.valFromOption(strokeWidth);
              }
              if (strokeDasharray !== undefined) {
                tmp.strokeDasharray = Caml_option.valFromOption(strokeDasharray);
              }
              if (onMouseOver !== undefined) {
                tmp.onMouseOver = Caml_option.valFromOption(onMouseOver);
              }
              if (onMouseMove !== undefined) {
                tmp.onMouseMove = Caml_option.valFromOption(onMouseMove);
              }
              if (onMouseEnter !== undefined) {
                tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
              }
              if (onMouseLeave !== undefined) {
                tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
              }
              return ReasonReact.createDomElement("rect", tmp, children);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(Caml_option.undefined_to_opt(reactProps.className), Caml_option.undefined_to_opt(reactProps.x), Caml_option.undefined_to_opt(reactProps.y), Caml_option.undefined_to_opt(reactProps.width), Caml_option.undefined_to_opt(reactProps.height), Caml_option.undefined_to_opt(reactProps.rx), Caml_option.undefined_to_opt(reactProps.ry), Caml_option.undefined_to_opt(reactProps.fill), Caml_option.undefined_to_opt(reactProps.fillOpacity), Caml_option.undefined_to_opt(reactProps.stroke), Caml_option.undefined_to_opt(reactProps.strokeWidth), Caml_option.undefined_to_opt(reactProps.strokeDasharray), Caml_option.undefined_to_opt(reactProps.onMouseOver), Caml_option.undefined_to_opt(reactProps.onMouseMove), Caml_option.undefined_to_opt(reactProps.onMouseEnter), Caml_option.undefined_to_opt(reactProps.onMouseLeave), Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(reactProps.children), [], (function (c) {
                          return [c];
                        })));
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
