// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var AuthTasks$BsConsole = require("./auth/AuthTasks.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");

var InviteFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.Invite.validators
    });

var formContainer = Css.style(/* :: */[
      Css.marginTop(Css.em(3)),
      /* [] */0
    ]);

var radioGroup = Css.style(/* :: */[
      Css.marginTop(Css.px(16)),
      /* [] */0
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.flexDirection(/* rowReverse */378951208),
      /* [] */0
    ]);

var Style = {
  formContainer: formContainer,
  radioGroup: radioGroup,
  buttonContainer: buttonContainer
};

var component = ReasonReact.statelessComponent("SettingsUsersNew");

function make(config, token, handleChangeUrl, noMoreUsersPermitted, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Curry._3(InviteFormContainer.make, {
                              email: "",
                              username: "",
                              method_: "password",
                              role: "member"
                            }, (function (state, param) {
                                var notifyOnFailure = param.notifyOnFailure;
                                var arg = AuthTasks$BsConsole.invite(config.universe.id, state.email, state.username, state.method_, state.role, undefined);
                                return Curry._2((function (param) {
                                                return (function (param$1, param$2) {
                                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                  });
                                              })(token), undefined, (function (user) {
                                              if (user.tag) {
                                                var match = user[0];
                                                if (match[0] === Task2$BsConsole.CoronerError) {
                                                  return Curry._2(notifyOnFailure, /* [] */0, match[1].message);
                                                } else if (match[0] === BpgTask$BsConsole.BpgError) {
                                                  return Curry._2(notifyOnFailure, /* [] */0, match[2]);
                                                } else {
                                                  return Curry._2(notifyOnFailure, /* [] */0, I18N$BsConsole.get(undefined, "Unknown error has occured"));
                                                }
                                              }
                                              var arg = AuthTasks$BsConsole.resendInvite(Belt_Option.getExn(user[0].username), undefined);
                                              return Curry._2((function (param) {
                                                              return (function (param$1, param$2) {
                                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                });
                                                            })(token), undefined, (function (param) {
                                                            if (param.tag) {
                                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: Invite Email Not Sent"));
                                                            }
                                                            Curry._1(handleChangeUrl, /* SettingsUsers */6);
                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success: Invite Email Sent"));
                                                          }));
                                            }));
                              }), (function (form) {
                                var partial_arg = form.submit;
                                var match = form.status;
                                var tmp;
                                if (typeof match === "number") {
                                  tmp = null;
                                } else {
                                  var msg = match[1];
                                  tmp = msg !== undefined ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg)]))])) : null;
                                }
                                var match$1 = Curry._1(form.result, /* Email */0);
                                var tmp$1;
                                tmp$1 = match$1 !== undefined && match$1.tag ? true : false;
                                var match$2 = Curry._1(form.result, /* Email */0);
                                var tmp$2;
                                tmp$2 = match$2 !== undefined && match$2.tag ? I18N$BsConsole.dynamic(match$2[0]) : I18N$BsConsole.showEmpty(undefined);
                                var match$3 = Curry._1(form.result, /* Username */1);
                                var tmp$3;
                                tmp$3 = match$3 !== undefined && match$3.tag ? true : false;
                                var match$4 = Curry._1(form.result, /* Username */1);
                                var tmp$4;
                                tmp$4 = match$4 !== undefined && match$4.tag ? I18N$BsConsole.dynamic(match$4[0]) : I18N$BsConsole.showEmpty(undefined);
                                return React.createElement("form", {
                                            onSubmit: (function (param) {
                                                return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                              })
                                          }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, formContainer, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                        tmp,
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  I18N$BsConsole.get(undefined, "Email"),
                                                                                  true,
                                                                                  (function ($$event) {
                                                                                      return Curry._2(form.change, /* Email */0, Curry._2(AuthForms$BsConsole.Invite.Email.update, form.state, $$event.target.value));
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  tmp$1,
                                                                                  Caml_option.some(tmp$2),
                                                                                  "text",
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    form.state.email
                                                                                  ],
                                                                                  undefined,
                                                                                  true,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ])),
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  I18N$BsConsole.get(undefined, "Username"),
                                                                                  true,
                                                                                  (function ($$event) {
                                                                                      return Curry._2(form.change, /* Username */1, Curry._2(AuthForms$BsConsole.Invite.Username.update, form.state, $$event.target.value));
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  tmp$3,
                                                                                  Caml_option.some(tmp$4),
                                                                                  "text",
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    form.state.username
                                                                                  ],
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ])),
                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                            I18N$BsConsole.show(undefined, "Method"),
                                                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.state.method_, (function (_event, value) {
                                                                                                        return Curry._2(form.change, /* Method */2, Curry._2(AuthForms$BsConsole.Invite.Method.update, form.state, value));
                                                                                                      }), undefined, [
                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                "password",
                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "password")),
                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              []
                                                                                                                            ]))),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                []
                                                                                                              ])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                "saml",
                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "saml")),
                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              []
                                                                                                                            ]))),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                []
                                                                                                              ])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                "pam",
                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "pam")),
                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              []
                                                                                                                            ]))),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                []
                                                                                                              ]))
                                                                                                    ]))
                                                                                          ]))])),
                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                            I18N$BsConsole.show(undefined, "Role"),
                                                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.state.role, (function (_event, value) {
                                                                                                        return Curry._2(form.change, /* Role */3, Curry._2(AuthForms$BsConsole.Invite.Role.update, form.state, value));
                                                                                                      }), undefined, [
                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                "member",
                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "member")),
                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              []
                                                                                                                            ]))),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                []
                                                                                                              ])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                "guest",
                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "guest")),
                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              []
                                                                                                                            ]))),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                []
                                                                                                              ]))
                                                                                                    ]))
                                                                                          ]))]))
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, buttonContainer, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, noMoreUsersPermitted ? I18N$BsConsole.show(undefined, "User limit reached.") : I18N$BsConsole.showEmpty(undefined), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                /* Primary */-791844958,
                                                                                                "submit",
                                                                                                undefined,
                                                                                                noMoreUsersPermitted,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                [I18N$BsConsole.show(undefined, "Send invite")]
                                                                                              ])))]))]))
                                                            ]))])));
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.config, reactProps.token, reactProps.handleChangeUrl, reactProps.noMoreUsersPermitted, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.InviteFormContainer = InviteFormContainer;
exports.Style = Style;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* InviteFormContainer Not a pure module */
