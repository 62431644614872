// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeUploadSummary(json) {
  return {
          count: Json_decode.field("count", Json_decode.$$int, json),
          firstUpdatedTime: Json_decode.field("first_updated_time", Json_decode.$$int, json),
          lastUpdatedTime: Json_decode.field("last_updated_time", Json_decode.$$int, json)
        };
}

function decodeMissingSummary(json) {
  return {
          count: Json_decode.field("count", Json_decode.$$int, json),
          firstCrashTime: Json_decode.field("first_crash_time", Json_decode.$$int, json),
          lastCrashTime: Json_decode.field("last_crash_time", Json_decode.$$int, json)
        };
}

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("summary", (function (json) {
                              return {
                                      archives: Json_decode.field("archives", decodeUploadSummary, json),
                                      symbols: Json_decode.field("symbols", decodeUploadSummary, json),
                                      missingSymbols: Json_decode.field("missing_symbols", decodeMissingSummary, json)
                                    };
                            }), json);
              }), json);
}

exports.decodeUploadSummary = decodeUploadSummary;
exports.decodeMissingSummary = decodeMissingSummary;
exports.decode = decode;
/* No side effect */
