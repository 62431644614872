// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(a) {
  switch (a) {
    case /* Unique */0 :
        return "unique";
    case /* Distribution */1 :
        return "distribution";
    case /* Bin */2 :
        return "bin";
    case /* Head */3 :
        return "head";
    case /* Tail */4 :
        return "tail";
    case /* Range */5 :
        return "range";
    case /* Mean */6 :
        return "mean";
    case /* Sum */7 :
        return "sum";
    case /* Min */8 :
        return "min";
    case /* Max */9 :
        return "max";
    
  }
}

function fromString(s) {
  switch (s) {
    case "bin" :
        return /* Bin */2;
    case "distribution" :
        return /* Distribution */1;
    case "head" :
        return /* Head */3;
    case "max" :
        return /* Max */9;
    case "mean" :
        return /* Mean */6;
    case "min" :
        return /* Min */8;
    case "range" :
        return /* Range */5;
    case "sum" :
        return /* Sum */7;
    case "tail" :
        return /* Tail */4;
    case "unique" :
        return /* Unique */0;
    default:
      return ;
  }
}

exports.toString = toString;
exports.fromString = fromString;
/* No side effect */
