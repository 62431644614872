// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var I18N$BsConsole = require("./I18N.js");
var Column$BsConsole = require("./utilities/Column.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

var component = RR$BsConsole.statelessComponent("ViewLoading-BsConsole");

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(LinearProgress.default, { }, children);
}

var LoadingBar = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("ViewLoading-BsConsole");

function make$1(label, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* spaceAround */-485895757, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.minHeight(Css.px(400)),
                                    /* [] */0
                                  ]
                                ]), [React.createElement("div", undefined, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.paddingBottom(Css.px(8)),
                                              /* :: */[
                                                Css.fontSize(Css.px(20)),
                                                /* [] */0
                                              ]
                                            ])
                                      }, I18N$BsConsole.showSkip(label)), ReasonReact.element(undefined, undefined, make([])))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var make$2 = ReasonReactCompat.wrapReasonReactForReact(component$1, (function (reactProps) {
        return make$1(reactProps.label, []);
      }));

var Jsx3 = {
  make: make$2
};

exports.LoadingBar = LoadingBar;
exports.component = component$1;
exports.make = make$1;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
