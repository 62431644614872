// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var SSAUtils$BsConsole = require("../SSAUtils.js");
var SSAAction$BsConsole = require("../SSAAction.js");

var userKey = "Assignee";

var helpText = "Specify the conditions to match on.";

function isUserType(k) {
  return k === userKey;
}

function isComplete(t) {
  var match = t.source;
  var match$1 = t.conditions;
  var match$2 = t.setAttributeKey;
  var match$3 = t.setAttributeValue;
  if (match !== undefined) {
    if (typeof match === "number") {
      if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && SSAAction$BsConsole.isConditionValid(match$1) && SSAUtils$BsConsole.isNotEmptyStr(match$2)) {
        return SSAUtils$BsConsole.isNotEmptyStr(match$3);
      } else {
        return false;
      }
    } else if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && SSAUtils$BsConsole.isNotEmptyStr(match[0]) && SSAAction$BsConsole.isConditionValid(match$1) && SSAUtils$BsConsole.isNotEmptyStr(match$2)) {
      return SSAUtils$BsConsole.isNotEmptyStr(match$3);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function toAction(t) {
  if (!isComplete(t)) {
    return ;
  }
  var match = t.source;
  var match$1 = t.conditions;
  var match$2 = t.setAttributeKey;
  var match$3 = t.setAttributeValue;
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
    if (match$2 === userKey) {
      return /* SetAssignee */Block.__(3, [/* tuple */[
                  undefined,
                  match,
                  match$1,
                  match$3
                ]]);
    } else {
      return /* SetAttribute */Block.__(2, [/* tuple */[
                  undefined,
                  match,
                  match$1,
                  {
                    key: match$2,
                    value: match$3
                  }
                ]]);
    }
  }
  
}

var formItems = [
  /* Source */Block.__(0, [/* tuple */[
        SSAUtils$BsConsole.sourceText,
        helpText
      ]]),
  /* Condition */Block.__(1, [SSAUtils$BsConsole.conditionText]),
  /* SetAttribute */0
];

var Module = {
  formItems: formItems,
  isComplete: isComplete,
  toAction: toAction
};

exports.userKey = userKey;
exports.helpText = helpText;
exports.isUserType = isUserType;
exports.Module = Module;
/* SSAUtils-BsConsole Not a pure module */
