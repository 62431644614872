// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var ParseError = Caml_exceptions.create("Health-BsConsole.ParseError");

var CoronerdError = Caml_exceptions.create("Health-BsConsole.CoronerdError");

function ofJson(json) {
  var version = Json_decode.field("version", Json_decode.string, json);
  return {
          version: version
        };
}

function makeTask(endpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + "/health"]), undefined, /* Get */0, undefined, (function (param) {
                var json = param.json;
                try {
                  return /* Ok */Block.__(0, [ofJson(json)]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Error$BsConsole.HTTPD.decode(json);
                  if (error !== undefined) {
                    return /* Error */Block.__(1, [[
                                CoronerdError,
                                error
                              ]]);
                  }
                  var error$1 = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Health/makeTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error$1
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error$1
                            ]]);
                }
              }), undefined, undefined);
}

exports.ParseError = ParseError;
exports.CoronerdError = CoronerdError;
exports.ofJson = ofJson;
exports.makeTask = makeTask;
/* Task2-BsConsole Not a pure module */
