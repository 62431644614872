// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");

var component = RR$BsConsole.reducerComponent("MentionAutocomplete-BsConsole");

function make(open_, autocompleteUsers, selectedIndex, onMentionSelected, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              var rootElement = self.state.rootRef.contents;
              if (rootElement === undefined) {
                return self.state;
              }
              var element = document.querySelector(".mention-context");
              var bounds = Belt_Option.map((element == null) ? undefined : Caml_option.some(element), (function (__x) {
                      return __x.getBoundingClientRect();
                    }));
              var rootBounds = Caml_option.valFromOption(rootElement).getBoundingClientRect();
              var init = self.state;
              return {
                      leftOffset: Belt_Option.map(bounds, (function (bounds) {
                              return bounds.left - rootBounds.left;
                            })),
                      rootRef: init.rootRef
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state.leftOffset;
              return React.createElement("div", {
                          ref: (function (rootRef) {
                              return Curry._1(send, /* SetRootRef */[rootRef]);
                            }),
                          className: Css.style(/* :: */[
                                Css.position(Css.relative),
                                /* [] */0
                              ])
                        }, open_ && match !== undefined ? React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.position(Css.absolute),
                                      /* :: */[
                                        Css.left(Css.px(match - 24 | 0)),
                                        /* :: */[
                                          Css.top(Css.px(8)),
                                          /* :: */[
                                            Css.zIndex(1),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, 6, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [Belt_Array.mapWithIndex(autocompleteUsers, (function (i, user) {
                                                          return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, true, (function (param) {
                                                                            return Curry._1(onMentionSelected, user);
                                                                          }), i === selectedIndex, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(user.username)]))]));
                                                        }))]))]))) : null);
            }),
          initialState: (function (param) {
              return {
                      leftOffset: undefined,
                      rootRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var rootRef = action[0];
              return /* SideEffects */Block.__(1, [(function (param) {
                            state.rootRef.contents = (rootRef == null) ? undefined : Caml_option.some(rootRef);
                            
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
