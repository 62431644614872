// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Email$BsConsole = require("./Email.js");
var Colors$BsConsole = require("./Colors.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var SubUtil$BsConsole = require("./subscriptions/SubUtil.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var DidMount$BsConsole = require("./DidMount.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var SubTrialUtil$BsConsole = require("./subscriptions/SubTrialUtil.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var SubLimitsUtil$BsConsole = require("./subscriptions/SubLimitsUtil.js");
var BannerPurveyor$BsConsole = require("./utilities/BannerPurveyor.js");
var ApiBillingLimits$BsConsole = require("./ApiBillingLimits.js");

var utilGrid = Css.style(/* :: */[
      Css.unsafe("minWidth", "520px !important"),
      /* [] */0
    ]);

var component = RR$BsConsole.statelessComponent("BillingLimitsCallToAction-BsConsole__LimitsSnack");

function make(limits, scene, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var utilization = limits.submissions.counter;
              var limit = Caml_array.caml_array_get(limits.submissions.limits, limits.submissions.limits.length - 1 | 0);
              var percentage = utilization / limit * 100;
              var percentageStr = percentage.toFixed(2);
              var daysToTrialEnd = DateFns.distanceInWordsToNow(undefined, true, limits.metadata.trialEnd);
              switch (scene) {
                case /* Trial */0 :
                    return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Trial ends in") + (" " + daysToTrialEnd))]))]));
                case /* Utilization */1 :
                    return React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Current usage:"), ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(utilGrid, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(percentageStr + "%")]))])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                                                            365180284,
                                                            percentage
                                                          ], /* Secondary */67972948, /* Determinate */373815968, []))]))
                                      ])), React.createElement("br", undefined));
                case /* TrialUtilization */2 :
                    return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(utilGrid, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Trial ends") + (" " + daysToTrialEnd))])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Current usage:")])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(percentageStr + "%")]))])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                                                            365180284,
                                                            percentage
                                                          ], /* Secondary */67972948, /* Determinate */373815968, []))]))
                                      ])), React.createElement("br", undefined));
                
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var LimitsSnack = {
  utilGrid: utilGrid,
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("BillingLimitsCallToAction-BsConsole__UnityOnTrial");

function make$1(limits, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var daysToTrialEnd = DateFns.distanceInWordsToNow(undefined, true, limits.metadata.expirationTime);
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                              I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Trial ends") + (" " + (daysToTrialEnd + (", " + I18N$BsConsole.get(undefined, "to manage your subscription visit "))))),
                              React.createElement("a", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.em(0.5)),
                                          /* :: */[
                                            Css.fontWeight(/* bolder */-534575822),
                                            /* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                              /* :: */[
                                                Css.hover(/* :: */[
                                                      Css.important(Css.textDecoration(/* underline */131142924)),
                                                      /* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                /* :: */[
                                                  Css.visited(/* :: */[
                                                        Css.important(Css.textDecoration(/* underline */131142924)),
                                                        /* :: */[
                                                          Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]),
                                    href: "https://id.unity.com",
                                    rel: "noopener noreferrer",
                                    target: "_blank"
                                  }, I18N$BsConsole.showSkip("https://id.unity.com"))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var UnityOnTrial = {
  component: component$1,
  make: make$1
};

var component$2 = RR$BsConsole.statelessComponent("BillingLimitsCallToAction-BsConsole__UnityOnPlanExpired");

function make$2(limits, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (param) {
              var email = limits.metadata.sales;
              var salesContact = email !== undefined ? email : Email$BsConsole.sales;
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                              I18N$BsConsole.show(undefined, "Your Backtrace plan has expired, please contact "),
                              React.createElement("a", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.em(0.5)),
                                          /* :: */[
                                            Css.fontWeight(/* bolder */-534575822),
                                            /* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                              /* :: */[
                                                Css.hover(/* :: */[
                                                      Css.important(Css.textDecoration(/* underline */131142924)),
                                                      /* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]),
                                    href: "mailto:" + salesContact
                                  }, I18N$BsConsole.showSkip(salesContact))
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var UnityOnPlanExpired = {
  component: component$2,
  make: make$2
};

function isShutDown(uni) {
  return Belt_List.has(/* :: */[
              "c2xhY2s=",
              /* [] */0
            ], btoa(uni), (function (a, b) {
                return a === b;
              }));
}

function nearLimit(utilization, limit) {
  return utilization / limit >= 0.8;
}

function utilizationLimitBanner(limits, handleChangeUrl) {
  var onTrialPlan = limits.metadata.onTrialPlan;
  var utilization = limits.submissions.counter;
  var limit = Caml_array.caml_array_get(limits.submissions.limits, limits.submissions.limits.length - 1 | 0);
  var showUtilization = nearLimit(utilization, limit);
  var scene = onTrialPlan ? (
      showUtilization ? /* TrialUtilization */2 : /* Trial */0
    ) : (
      showUtilization ? /* Utilization */1 : undefined
    );
  var match = limits.metadata.variant;
  if (scene === undefined) {
    return ;
  }
  if (match) {
    var sales = Belt_Option.getWithDefault(limits.metadata.sales, Email$BsConsole.sales);
    setTimeout((function (param) {
            return SnackPurveyor$BsConsole.enqueue({
                        label: "Contact " + sales,
                        onClick: (function (_event) {
                            
                          })
                      }, undefined, 200000, undefined, ReasonReact.element(undefined, undefined, make(limits, scene, [])));
          }), 5000);
    return ;
  }
  setTimeout((function (param) {
          return SnackPurveyor$BsConsole.enqueue({
                      label: "Upgrade plan",
                      onClick: (function (_event) {
                          var gate = limits.metadata.gate;
                          var tmp;
                          if (gate.tag) {
                            tmp = /* SettingsSubscriptions */20;
                          } else {
                            tmp = gate[0] !== 0 ? /* SettingsBilling */18 : /* SettingsBillingPlans */19;
                          }
                          return Curry._1(handleChangeUrl, tmp);
                        })
                    }, undefined, 200000, undefined, ReasonReact.element(undefined, undefined, make(limits, scene, [])));
        }), 5000);
  
}

var primaryButton = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.blueDark))),
      /* :: */[
        Css.unsafe("color", Colors$BsConsole.octothorpe(Colors$BsConsole.white) + " !important"),
        /* [] */0
      ]
    ]);

var email = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.blueDark))),
      /* [] */0
    ]);

var billingInfoContainer = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey0)),
        /* :: */[
          Css.marginRight(Css.px(12)),
          /* :: */[
            Css.padding(Css.px(12)),
            /* :: */[
              Css.borderRadius(Css.px(4)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var limitsRejected = Css.style(/* :: */[
      Css.unsafe("backgroundColor", Colors$BsConsole.octothorpe(Colors$BsConsole.redA25) + " !important"),
      /* :: */[
        Css.important(Css.color(Css.hex(Colors$BsConsole.errorDark))),
        /* [] */0
      ]
    ]);

var component$3 = RR$BsConsole.statelessComponent("BillingLimitsCallToAction-BsConsole");

function make$3(limits, config, token, route, handleChangeUrl, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: (function (_self) {
              if (limits === undefined) {
                return ;
              }
              var match = limits.metadata.variant;
              if (match) {
                var onTrialPlan = limits.metadata.onTrialPlan;
                var expirationTimeInFuture = limits.metadata.expirationTime.getTime() > Date.now();
                var autoRenew = limits.metadata.external_.autoRenew;
                if (autoRenew) {
                  return utilizationLimitBanner(limits, handleChangeUrl);
                } else if (onTrialPlan) {
                  if (expirationTimeInFuture) {
                    setTimeout((function (param) {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, 200000, undefined, ReasonReact.element(undefined, undefined, make$1(limits, [])));
                          }), 5000);
                    return ;
                  } else {
                    return ;
                  }
                } else if (expirationTimeInFuture) {
                  return ;
                } else {
                  setTimeout((function (param) {
                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, 200000, undefined, ReasonReact.element(undefined, undefined, make$2(limits, [])));
                        }), 5000);
                  return ;
                }
              }
              var _gate = limits.metadata.gate;
              if (!_gate.tag) {
                if (Caml_obj.caml_equal(limits.metadata.gate, /* Legacy */Block.__(0, [/* RequireContact */4])) || !limits.metadata.onTrialPlan || limits.metadata.trialEnd.getTime() > Date.now()) {
                  return utilizationLimitBanner(limits, handleChangeUrl);
                } else {
                  return ;
                }
              }
              var _endTimestamp = _gate[0];
              if (typeof _endTimestamp === "number") {
                return SubLimitsUtil$BsConsole.enqueueUsageLimitsNotifications(config, token, limits, handleChangeUrl);
              }
              if (_endTimestamp.tag) {
                return ;
              }
              if (SubTrialUtil$BsConsole.trialExpiresSoon(_endTimestamp[0], Date.now())) {
                if (SubUtil$BsConsole.canModifyNemesisPlans(config)) {
                  var cta = {
                    title: I18N$BsConsole.get(undefined, "Manage subscription"),
                    action: (function (param) {
                        return Curry._1(handleChangeUrl, /* SettingsSubscriptions */20);
                      })
                  };
                  BannerPurveyor$BsConsole.enqueue({
                        bannerItem: {
                          message: SubTrialUtil$BsConsole.adminTrialExpireMessage,
                          cta: cta
                        },
                        cache: {
                          id: "nemesis_trial_ending_admin",
                          minutes: 1440
                        },
                        el: undefined
                      });
                } else {
                  BannerPurveyor$BsConsole.enqueue({
                        bannerItem: {
                          message: SubTrialUtil$BsConsole.nonadminTrialExpireMessage,
                          cta: undefined
                        },
                        cache: {
                          id: "nemesis_trial_ending_nonadmin",
                          minutes: 1440
                        },
                        el: undefined
                      });
                }
              }
              return SubLimitsUtil$BsConsole.enqueueUsageLimitsNotifications(config, token, limits, handleChangeUrl);
            }),
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              var match = isShutDown(config.universe.name);
              if (match) {
                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                undefined,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                true,
                                [
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Your Backtrace account has been disabled.")])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                      I18N$BsConsole.show(undefined, "Please e-mail "),
                                                      React.createElement("a", {
                                                            className: email,
                                                            href: "mailto:" + Email$BsConsole.sales
                                                          }, I18N$BsConsole.showSkip(Email$BsConsole.sales)),
                                                      I18N$BsConsole.show(undefined, " to re-enable your account.")
                                                    ]))]))
                                ]
                              ]));
              }
              if (limits === undefined) {
                return null;
              }
              var match$1 = limits.metadata.variant;
              var trialExpiration = match$1 ? limits.metadata.expirationTime : limits.metadata.trialEnd;
              var trialOver = trialExpiration.getTime() <= Date.now() && limits.metadata.onTrialPlan;
              var match$2 = limits.metadata.gate;
              var exit = 0;
              if (trialOver && typeof route === "number") {
                if (route === 19 || route === 18) {
                  return null;
                }
                exit = 2;
              } else {
                exit = 2;
              }
              if (exit === 2 && !match$2.tag && match$2[0] >= 4) {
                var expirationDetails = limits.metadata.expirationDetails;
                var match$3 = limits.metadata.variant;
                var tmp;
                if (match$3) {
                  tmp = ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                            I18N$BsConsole.show(undefined, "Please visit "),
                            React.createElement("a", {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.em(0.5)),
                                        /* :: */[
                                          Css.fontWeight(/* bolder */-534575822),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                            /* :: */[
                                              Css.hover(/* :: */[
                                                    Css.important(Css.textDecoration(/* underline */131142924)),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              /* :: */[
                                                Css.visited(/* :: */[
                                                      Css.important(Css.textDecoration(/* underline */131142924)),
                                                      /* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]),
                                  href: "https://id.unity.com",
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }, I18N$BsConsole.showSkip("https://id.unity.com")),
                            I18N$BsConsole.show(undefined, " to re-enable your account.")
                          ]));
                } else {
                  var email$1 = limits.metadata.sales;
                  var email$2 = limits.metadata.sales;
                  tmp = ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                            I18N$BsConsole.show(undefined, "Please e-mail "),
                            React.createElement("a", {
                                  className: email,
                                  href: "mailto:" + (
                                    email$1 !== undefined ? email$1 : Email$BsConsole.sales
                                  )
                                }, I18N$BsConsole.showSkip(email$2 !== undefined ? email$2 : Email$BsConsole.sales)),
                            I18N$BsConsole.show(undefined, " to re-enable your account.")
                          ]));
                }
                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                undefined,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                true,
                                [
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.dynamic(expirationDetails !== undefined ? expirationDetails : I18N$BsConsole.get(undefined, "Your Backtrace account has been disabled."))])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [tmp]))
                                ]
                              ]));
              }
              if (!trialOver) {
                return null;
              }
              var email$3 = limits.metadata.sales;
              var email$4 = limits.metadata.sales;
              var limit = limits.submissions.limit;
              var ratio = limit ? limits.submissions.counter / limit[0] : 0.0;
              var str = (ratio * 100).toFixed(2);
              var limit$1 = limits.submissions.limit;
              var match$4 = limits.metadata.variant;
              var tmp$1;
              if (match$4) {
                var sales = Belt_Option.getWithDefault(limits.metadata.sales, Email$BsConsole.sales);
                tmp$1 = React.createElement("a", {
                      href: "mailto:" + sales
                    }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                              undefined,
                              undefined,
                              (function (param) {
                                  
                                }),
                              undefined,
                              undefined,
                              /* Raised */-387916264,
                              /* Primary */-791844958,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              {
                                raisedPrimary: primaryButton
                              },
                              [I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Contact ") + sales)]
                            ])));
              } else {
                tmp$1 = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                          undefined,
                          undefined,
                          (function (param) {
                              var gate = limits.metadata.gate;
                              var tmp;
                              tmp = gate.tag ? /* SettingsSubscriptions */20 : (
                                  gate[0] !== 0 ? /* SettingsBilling */18 : /* SettingsBillingPlans */19
                                );
                              return Curry._1(handleChangeUrl, tmp);
                            }),
                          undefined,
                          undefined,
                          /* Raised */-387916264,
                          /* Primary */-791844958,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          {
                            raisedPrimary: primaryButton
                          },
                          [I18N$BsConsole.show(undefined, "Upgrade plan")]
                        ]));
              }
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              true,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Your Backtrace trial has ended")])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                    I18N$BsConsole.show(undefined, "Please e-mail "),
                                                    React.createElement("a", {
                                                          className: email,
                                                          href: "mailto:" + (
                                                            email$3 !== undefined ? email$3 : Email$BsConsole.sales
                                                          )
                                                        }, I18N$BsConsole.showSkip(email$4 !== undefined ? email$4 : Email$BsConsole.sales)),
                                                    I18N$BsConsole.show(undefined, " to update your plan selection.")
                                                  ])),
                                          React.createElement("br", undefined),
                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Current usage:")])),
                                          ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, /* V24 */3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(str + "%")])),
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                                                                        365180284,
                                                                        ratio * 100
                                                                      ], undefined, /* Determinate */373815968, []))
                                                            ])),
                                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                              React.createElement("div", {
                                                                    className: billingInfoContainer
                                                                  }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, /* Inherit */-72987685, undefined, undefined, [
                                                                            I18N$BsConsole.show(undefined, "Received "),
                                                                            ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                          className: Styles$BsConsole.$$Text.tooltipTitleSize
                                                                                        }, I18N$BsConsole.show(undefined, "Number of crashed received this month. This includes failed submission attempts such as invalid dumps")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                                                                            className: "smaller-icon"
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Info.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))
                                                                          ])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, /* Right */-57574468, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(String(limits.submissions.counter))]))),
                                                              React.createElement("div", {
                                                                    className: billingInfoContainer
                                                                  }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, /* Inherit */-72987685, undefined, undefined, [
                                                                            I18N$BsConsole.show(undefined, "Limit "),
                                                                            ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                          className: Styles$BsConsole.$$Text.tooltipTitleSize
                                                                                        }, I18N$BsConsole.show(undefined, "The maximum number of crashes your plan allows you to receive this month. After this limit is reached, Backtrace will begin rejecting incoming crashes until the next month.")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                                                                            className: "smaller-icon"
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Info.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))
                                                                          ])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, /* Right */-57574468, /* Inherit */-72987685, undefined, undefined, [limit$1 ? I18N$BsConsole.showSkip(String(limit$1[0])) : I18N$BsConsole.show(undefined, "Unlimited")]))),
                                                              React.createElement("div", {
                                                                    className: billingInfoContainer + (" " + limitsRejected)
                                                                  }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, /* Inherit */-72987685, undefined, undefined, [
                                                                            I18N$BsConsole.show(undefined, "Rejected "),
                                                                            ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                          className: Styles$BsConsole.$$Text.tooltipTitleSize
                                                                                        }, I18N$BsConsole.show(undefined, "Number of crashes rejected this month.")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("span", {
                                                                                            className: "smaller-icon"
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Info.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))
                                                                          ])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, /* Right */-57574468, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(String(limits.submissions.rejected))])))
                                                            ]))
                                                  ])),
                                          React.createElement("br", undefined)
                                        ])),
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [tmp$1]))
                              ]
                            ]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var LimitsCallToAction_ = {
  primaryButton: primaryButton,
  email: email,
  billingInfoContainer: billingInfoContainer,
  limitsRejected: limitsRejected,
  component: component$3,
  make: make$3
};

var component$4 = ReasonReact.reducerComponent("BillingLimitsCallToAction-BsConsole");

function make$4(shell, route, token, handleTask, handleChangeUrl, children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var tmp;
              if (typeof shell === "number" || shell.tag) {
                tmp = Curry._2(children, self.state.remote, undefined);
              } else {
                var config = shell[0];
                var update = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [config]));
                };
                tmp = ReasonReact.element(undefined, undefined, DidMount$BsConsole.make((function (param) {
                            return Curry._1(self.send, /* Request */Block.__(0, [config]));
                          }), [Curry._2(children, self.state.remote, update)]));
              }
              var match = self.state.remote;
              var tmp$1;
              tmp$1 = typeof shell === "number" || shell.tag || typeof match === "number" || match.tag ? null : ReasonReact.element(undefined, undefined, make$3(match[0], shell[0], token, route, handleChangeUrl, []));
              return React.createElement(React.Fragment, undefined, tmp, tmp$1);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var config = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (j) {
                                                try {
                                                  var json = TaskCallback$BsConsole.getJson(j);
                                                  var limits = ApiBillingLimits$BsConsole.ofJson(config.universe.name, json);
                                                  return Curry._1(self.send, /* Success */Block.__(1, [limits]));
                                                }
                                                catch (raw_msg){
                                                  var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
                                                  if (msg[0] === Json_decode.DecodeError) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [msg[1]]));
                                                  } else {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, ["Failed to fetch"]));
                                                  }
                                                }
                                              }), Task$BsConsole.makeTask(/* BillingLimits */Block.__(21, [token])));
                                })
                            ]);
                case /* Success */1 :
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [action[0]])
                              }]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

exports.LimitsSnack = LimitsSnack;
exports.UnityOnTrial = UnityOnTrial;
exports.UnityOnPlanExpired = UnityOnPlanExpired;
exports.isShutDown = isShutDown;
exports.nearLimit = nearLimit;
exports.utilizationLimitBanner = utilizationLimitBanner;
exports.LimitsCallToAction_ = LimitsCallToAction_;
exports.component = component$4;
exports.make = make$4;
/* utilGrid Not a pure module */
