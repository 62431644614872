// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ApiOverview$BsConsole = require("./ApiOverview.js");

var OverviewServiceError = Caml_exceptions.create("FetchProjectOverview-BsConsole.OverviewServiceError");

var ParseError = Caml_exceptions.create("FetchProjectOverview-BsConsole.ParseError");

var UnknownSvcError = Caml_exceptions.create("FetchProjectOverview-BsConsole.UnknownSvcError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError || error[0] === Task2$BsConsole.Httpd) {
    tmp = error[1].message;
  } else if (error[0] === Task2$BsConsole.JsonDecodeError) {
    var message = error[1];
    tmp = message !== undefined ? message : "JsonDecodeError";
  } else if (error[0] === ParseError) {
    var message$1 = error[1].message;
    tmp = message$1 !== undefined ? "ParseError" + message$1 : "ParseError: There was an error parsing data";
  } else {
    console.log(error);
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function makeGetServiceVersionTask(serviceEndpoint, projectName) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/version?project=" + (String(projectName) + "")))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var versionString = Json_decode.field("version", Json_decode.string, param.json);
                  var v = Semver.valid(versionString);
                  if (v !== undefined) {
                    return /* Ok */Block.__(0, [v]);
                  } else {
                    return /* Error */Block.__(1, [UnknownSvcError]);
                  }
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeGetQueryListTask(serviceEndpoint, projectName, universe, dashboard) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/queries?project=" + (String(projectName) + ("&universe=" + (String(universe) + ("&dashboard=" + (String(dashboard) + "")))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var result = ApiOverview$BsConsole.QueryList.decode(param.json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (raw__error){
                  var _error = Caml_js_exceptions.internalToOCamlException(raw__error);
                  console.log(_error);
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makePutLayoutTask(serviceEndpoint, projectName, universe, layout) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/queries/layout?project=" + (String(projectName) + ("&universe=" + (String(universe) + "")))))]), undefined, /* Put */Block.__(1, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "layout",
                        layout
                      ],
                      /* [] */0
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeQueryConfigPostTask(serviceEndpoint, queryId, key, value, projectName, universeName) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/queries/" + (String(queryId) + ("/config?project=" + (String(projectName) + ("&universe=" + (String(universeName) + "")))))))]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "key",
                        key
                      ],
                      /* :: */[
                        /* tuple */[
                          "value",
                          value
                        ],
                        /* [] */0
                      ]
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

function makeQueryPostTask(serviceEndpoint, projectName, universe, queryID, query, versionAttribute, overrides, dashboard) {
  var queryIDString = String(queryID);
  var match;
  match = query.tag ? /* tuple */[
      query[0],
      query[2],
      Crdb$BsConsole.Fold.empty,
      undefined
    ] : /* tuple */[
      query[0],
      query[3],
      query[2],
      query[1]
    ];
  var sort = match[1];
  var postBody = Json_encode.object_(Belt_List.concatMany([
            /* :: */[
              /* tuple */[
                "filter",
                Curry._1(Crdb$BsConsole.Aperture.toCrdbJson, match[0])
              ],
              /* [] */0
            ],
            sort !== undefined ? /* :: */[
                /* tuple */[
                  "order",
                  [Curry._2(Crdb$BsConsole.Sort.toCrdb, sort, match[2])]
                ],
                /* [] */0
              ] : /* [] */0,
            versionAttribute !== undefined ? /* :: */[
                /* tuple */[
                  "versionAttribute",
                  versionAttribute
                ],
                /* [] */0
              ] : /* [] */0,
            overrides
          ]));
  var factor = Curry._1(Crdb$BsConsole.Query.getFactor, query);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(serviceEndpoint) + ("/queries/" + (String(queryIDString) + ("?project=" + (String(projectName) + ("&universe=" + (String(universe) + ("&dashboard=" + (String(dashboard) + "")))))))))]), undefined, /* Post */Block.__(0, [postBody]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return ApiOverview$BsConsole.QueryResponse.decodeResp(factor, param);
              }), undefined, undefined);
}

function useServiceVersion(token, projectName, serviceEndpoint) {
  var task = makeGetServiceVersionTask(serviceEndpoint, projectName);
  return BpgTask$BsConsole.use(token, task);
}

function useQueryList(token, projectName, universe, serviceEndpoint, dashboard) {
  var task = makeGetQueryListTask(serviceEndpoint, projectName, universe, dashboard);
  return BpgTask$BsConsole.use(token, task);
}

function useLayoutList(token, projectName, universe, serviceEndpoint, dashboard) {
  var match = useQueryList(token, projectName, universe, serviceEndpoint, dashboard);
  var queryListRemote = match[0];
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setLayoutList = match$1[1];
  React.useEffect((function () {
          if (typeof queryListRemote === "number") {
            if (queryListRemote === /* NotAsked */0) {
              Curry._1(setLayoutList, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setLayoutList, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (queryListRemote.tag) {
            var msg = queryListRemote[0];
            Curry._1(setLayoutList, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else {
            var queryList = queryListRemote[0];
            Curry._1(setLayoutList, (function (param) {
                    return /* Success */Block.__(0, [queryList]);
                  }));
          }
          
        }), [queryListRemote]);
  return /* tuple */[
          match$1[0],
          match[1]
        ];
}

function useQueryRequest(serviceEndpoint, token, projectName, universe, queryID, query, versionAttribute, overrides, dashboard) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setQueryRequestRemote = match[1];
  var queryRequestRemote = match[0];
  React.useEffect((function () {
          var task = makeQueryPostTask(serviceEndpoint, projectName, universe, queryID, query, versionAttribute, overrides, dashboard);
          var onSuccess = function (result) {
            return Curry._1(setQueryRequestRemote, (function (param) {
                          return /* Success */Block.__(0, [/* tuple */[
                                      result,
                                      /* NotAsked */0
                                    ]]);
                        }));
          };
          var onFailure = function (msg) {
            return Curry._1(setQueryRequestRemote, (function (param) {
                          return /* Failure */Block.__(1, [msg]);
                        }));
          };
          if (typeof queryRequestRemote === "number") {
            if (queryRequestRemote === /* NotAsked */0) {
              Curry._1(setQueryRequestRemote, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!queryRequestRemote.tag) {
            var remote$prime = queryRequestRemote[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [queryRequestRemote]);
  React.useEffect((function () {
          if (typeof queryRequestRemote === "number") {
            
          } else {
            Curry._1(setQueryRequestRemote, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), /* tuple */[
        query,
        versionAttribute,
        overrides
      ]);
  return queryRequestRemote;
}

exports.OverviewServiceError = OverviewServiceError;
exports.ParseError = ParseError;
exports.UnknownSvcError = UnknownSvcError;
exports.responseCb = responseCb;
exports.makeGetServiceVersionTask = makeGetServiceVersionTask;
exports.makeGetQueryListTask = makeGetQueryListTask;
exports.makePutLayoutTask = makePutLayoutTask;
exports.makeQueryConfigPostTask = makeQueryConfigPostTask;
exports.makeQueryPostTask = makeQueryPostTask;
exports.useServiceVersion = useServiceVersion;
exports.useQueryList = useQueryList;
exports.useLayoutList = useLayoutList;
exports.useQueryRequest = useQueryRequest;
/* react Not a pure module */
