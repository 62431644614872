// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("./util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Bpg_response$BsConsole = require("./bpg_response.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function ofInt(i) {
  if (i !== 1) {
    if (i !== 2) {
      return /* Unknown */[i];
    } else {
      return /* Invalid */1;
    }
  } else {
    return /* Failed */0;
  }
}

var $$Error = {
  ofInt: ofInt
};

var UnknownBpgError = Caml_exceptions.create("BpgTask-BsConsole.UnknownBpgError");

var BpgError = Caml_exceptions.create("BpgTask-BsConsole.BpgError");

var BpgResponseWithoutResult = Caml_exceptions.create("BpgTask-BsConsole.BpgResponseWithoutResult");

Task2$BsConsole.registerErrorRenderer((function (error) {
        if (error === UnknownBpgError) {
          return "Unknown BPG error has occurred";
        }
        if (error[0] !== BpgError) {
          if (error[0] === BpgResponseWithoutResult) {
            return "BPG response missing result: (" + (String(error[1]) + ")");
          } else {
            return ;
          }
        }
        var i = error[1];
        if (typeof i === "number") {
          if (i !== 0) {
            return "BPG invalid: " + error[2];
          } else {
            return "BPG failure: " + error[2];
          }
        } else {
          return "BPG invalid " + (String(i[0]) + (" " + error[2]));
        }
      }));

function baseTask(cacheKey, decodeResp, actions, label, param) {
  return Task2$BsConsole.make(label !== undefined ? /* Api */Block.__(0, ["/bpg?label=" + label]) : /* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "actions",
                        actions
                      ],
                      /* [] */0
                    ])]), undefined, decodeResp, cacheKey, undefined);
}

function decodeResult(json) {
  var result_code = Json_decode.field("code", Json_decode.$$int, json);
  var result_text = Json_decode.field("text", Json_decode.string, json);
  var result_result = Json_decode.optional((function (param) {
          return Json_decode.field("result", Util$BsConsole.identity, param);
        }), json);
  var i = result_code;
  if (i !== 0) {
    return /* Error */Block.__(1, [[
                BpgError,
                ofInt(i),
                result_text
              ]]);
  }
  var result = result_result;
  if (result !== undefined) {
    return /* Ok */Block.__(0, [Caml_option.valFromOption(result)]);
  } else {
    return /* Error */Block.__(1, [[
                BpgResponseWithoutResult,
                result_text
              ]]);
  }
}

function decodeHead(json) {
  var json$1 = Bpg_response$BsConsole.head(json);
  var head = json$1 !== undefined ? /* Ok */Block.__(0, [Caml_option.valFromOption(json$1)]) : /* Error */Block.__(1, [UnknownBpgError]);
  return Belt_Result.flatMap(head, decodeResult);
}

function MakeBaseBpgTasks(BpgEntity) {
  var bpgType = "configuration/" + BpgEntity.table;
  var cacheKey = "bpg-" + bpgType;
  var fetchAll = baseTask(cacheKey, (function (param) {
          return Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BpgEntity.of_json, param);
                      }));
        }), [Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                bpgType
              ],
              /* :: */[
                /* tuple */[
                  "action",
                  "get"
                ],
                /* [] */0
              ]
            ])], undefined, undefined);
  var fetchOne = function (k) {
    return baseTask(cacheKey + Json.stringify(Curry._1(BpgEntity.Key.to_json, k)), (function (param) {
                  var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                          return Json_decode.array(BpgEntity.of_json, param);
                        }));
                  if (result.tag) {
                    return /* Error */Block.__(1, [result[0]]);
                  } else {
                    return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                  }
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "get"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, k)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], "fetch-one", undefined);
  };
  var create = function (entity) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), BpgEntity.of_json);
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "create"
                        ],
                        /* :: */[
                          /* tuple */[
                            "object",
                            Curry._1(BpgEntity.to_json, entity)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var updateFieldsByKey = function (key, fields) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "modify"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, key)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fields",
                              Json_encode.object_(Belt_List.fromArray(fields))
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var updateFields = function (entity, fields) {
    return baseTask(undefined, (function (param) {
                  var e = decodeHead(param.json);
                  if (e.tag) {
                    return /* Error */Block.__(1, [e[0]]);
                  }
                  Task2$BsConsole.invalidateCache(cacheKey);
                  var entityObj = Curry._1(BpgEntity.to_json, entity);
                  var fieldsObj = Js_dict.fromArray(fields);
                  return /* Ok */Block.__(0, [Curry._1(BpgEntity.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "modify"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                          ],
                          /* :: */[
                            /* tuple */[
                              "fields",
                              Json_encode.object_(Belt_List.fromArray(fields))
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var $$delete = function (entity) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        "configuration/" + BpgEntity.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var deleteByKey = function (k) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        "configuration/" + BpgEntity.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, k)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  return {
          bpgType: bpgType,
          cacheKey: cacheKey,
          fetchAll: fetchAll,
          fetchOne: fetchOne,
          create: create,
          updateFieldsByKey: updateFieldsByKey,
          updateFields: updateFields,
          $$delete: $$delete,
          deleteByKey: deleteByKey
        };
}

function MakeBpgTasks(BpgEntity) {
  var bpgType = "configuration/" + BpgEntity.table;
  var cacheKey = "bpg-" + bpgType;
  var fetchAll = baseTask(cacheKey, (function (param) {
          return Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BpgEntity.of_json, param);
                      }));
        }), [Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                bpgType
              ],
              /* :: */[
                /* tuple */[
                  "action",
                  "get"
                ],
                /* [] */0
              ]
            ])], undefined, undefined);
  var fetchOne = function (k) {
    return baseTask(cacheKey + Json.stringify(Curry._1(BpgEntity.Key.to_json, k)), (function (param) {
                  var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                          return Json_decode.array(BpgEntity.of_json, param);
                        }));
                  if (result.tag) {
                    return /* Error */Block.__(1, [result[0]]);
                  } else {
                    return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                  }
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "get"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, k)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], "fetch-one", undefined);
  };
  var create = function (entity) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), BpgEntity.of_json);
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "create"
                        ],
                        /* :: */[
                          /* tuple */[
                            "object",
                            Curry._1(BpgEntity.to_json, entity)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var updateFieldsByKey = function (key, fields) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "modify"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, key)
                          ],
                          /* :: */[
                            /* tuple */[
                              "fields",
                              Json_encode.object_(Belt_List.fromArray(fields))
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var updateFields = function (entity, fields) {
    return baseTask(undefined, (function (param) {
                  var e = decodeHead(param.json);
                  if (e.tag) {
                    return /* Error */Block.__(1, [e[0]]);
                  }
                  Task2$BsConsole.invalidateCache(cacheKey);
                  var entityObj = Curry._1(BpgEntity.to_json, entity);
                  var fieldsObj = Js_dict.fromArray(fields);
                  return /* Ok */Block.__(0, [Curry._1(BpgEntity.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        bpgType
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "modify"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                          ],
                          /* :: */[
                            /* tuple */[
                              "fields",
                              Json_encode.object_(Belt_List.fromArray(fields))
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var $$delete = function (entity) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        "configuration/" + BpgEntity.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var deleteByKey = function (k) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        "configuration/" + BpgEntity.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, k)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])], undefined, undefined);
  };
  var cascadeDeleteUnsafe = function (entity) {
    return baseTask(undefined, (function (param) {
                  return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                    Task2$BsConsole.invalidateCache(cacheKey);
                                    return json;
                                  })), (function (param) {
                                return Json_decode.array(BpgEntity.of_json, param);
                              }));
                }), [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        "configuration/" + BpgEntity.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                          ],
                          /* :: */[
                            /* tuple */[
                              "cascade",
                              true
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])], undefined, undefined);
  };
  return {
          bpgType: bpgType,
          cacheKey: cacheKey,
          fetchAll: fetchAll,
          fetchOne: fetchOne,
          create: create,
          updateFieldsByKey: updateFieldsByKey,
          updateFields: updateFields,
          $$delete: $$delete,
          deleteByKey: deleteByKey,
          cascadeDeleteUnsafe: cascadeDeleteUnsafe
        };
}

var InvalidBpgOperation = Caml_exceptions.create("BpgTask-BsConsole.InvalidBpgOperation");

var BulkBpgDecodingError = Caml_exceptions.create("BpgTask-BsConsole.BulkBpgDecodingError");

function decodeAction(json) {
  var bpgType = Json_decode.field("type", Json_decode.string, json);
  var action = Json_decode.field("action", Json_decode.string, json);
  switch (action) {
    case "create" :
        return /* Create */Block.__(1, [bpgType]);
    case "delete" :
        return /* Delete */Block.__(3, [bpgType]);
    case "get" :
        return /* Get */Block.__(0, [bpgType]);
    case "modify" :
        return /* Modify */Block.__(2, [bpgType]);
    default:
      throw InvalidBpgOperation;
  }
}

function decodeBulk(json) {
  var actions = Json_decode.field("actions", (function (param) {
          return Json_decode.array(decodeAction, param);
        }), json);
  var results = Json_decode.field("results", (function (param) {
          return Json_decode.array((function (json$prime) {
                        return json$prime;
                      }), param);
        }), json);
  try {
    return Belt_Array.mapWithIndex(actions, (function (idx, action) {
                  return {
                          action: action,
                          result: Belt_Array.getExn(results, idx)
                        };
                }));
  }
  catch (exn){
    throw BulkBpgDecodingError;
  }
}

function MakeBatchBpgTask1(BpgEntity) {
  var bpgType = "configuration/" + BpgEntity.table;
  var cacheKey = "bpg-" + bpgType;
  var get = Json_encode.object_(/* :: */[
        /* tuple */[
          "type",
          bpgType
        ],
        /* :: */[
          /* tuple */[
            "action",
            "get"
          ],
          /* [] */0
        ]
      ]);
  var create = function (entity) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  bpgType
                ],
                /* :: */[
                  /* tuple */[
                    "action",
                    "create"
                  ],
                  /* :: */[
                    /* tuple */[
                      "object",
                      Curry._1(BpgEntity.to_json, entity)
                    ],
                    /* [] */0
                  ]
                ]
              ]);
  };
  var $$delete = function (entity) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  bpgType
                ],
                /* :: */[
                  /* tuple */[
                    "action",
                    "delete"
                  ],
                  /* :: */[
                    /* tuple */[
                      "key",
                      Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                    ],
                    /* [] */0
                  ]
                ]
              ]);
  };
  var modify = function (entity, fields) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  bpgType
                ],
                /* :: */[
                  /* tuple */[
                    "action",
                    "modify"
                  ],
                  /* :: */[
                    /* tuple */[
                      "key",
                      Curry._1(BpgEntity.Key.to_json, Curry._1(BpgEntity.to_key, entity))
                    ],
                    /* :: */[
                      /* tuple */[
                        "fields",
                        Json_encode.object_(Belt_List.fromArray(fields))
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  };
  var makeTask = function (actions) {
    return baseTask(undefined, (function (param) {
                  try {
                    return /* Ok */Block.__(0, [Belt_Array.map(decodeBulk(param.json), (function (response) {
                                      return decodeResult(response.result);
                                    }))]);
                  }
                  catch (_err){
                    if (_err === InvalidBpgOperation) {
                      return /* Error */Block.__(1, [[
                                  BpgError,
                                  ofInt(1),
                                  "Invalid Bpg Operation"
                                ]]);
                    } else {
                      return /* Error */Block.__(1, [[
                                  BpgError,
                                  ofInt(1),
                                  "BPG decoding error"
                                ]]);
                    }
                  }
                }), actions, undefined, undefined);
  };
  return {
          bpgType: bpgType,
          cacheKey: cacheKey,
          get: get,
          create: create,
          $$delete: $$delete,
          modify: modify,
          makeTask: makeTask
        };
}

var bpgType = "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table;

var cacheKey = "bpg-" + bpgType;

var fetchAll = baseTask(cacheKey, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne(k) {
  return baseTask(cacheKey + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.WatcherNew.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var WatcherNew = {
  bpgType: bpgType,
  cacheKey: cacheKey,
  fetchAll: fetchAll,
  fetchOne: fetchOne,
  create: create,
  updateFieldsByKey: updateFieldsByKey,
  updateFields: updateFields,
  $$delete: $$delete,
  deleteByKey: deleteByKey,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe
};

var bpgType$1 = "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table;

var cacheKey$1 = "bpg-" + bpgType$1;

var fetchAll$1 = baseTask(cacheKey$1, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$1
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$1(k) {
  return baseTask(cacheKey$1 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$1
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$1(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$1);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.SignupPending.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$1
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$1(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$1);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$1
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$1(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$1);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$1
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$1(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$1);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$1(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$1);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$1(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$1);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.SignupPending.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var SignupPending = {
  bpgType: bpgType$1,
  cacheKey: cacheKey$1,
  fetchAll: fetchAll$1,
  fetchOne: fetchOne$1,
  create: create$1,
  updateFieldsByKey: updateFieldsByKey$1,
  updateFields: updateFields$1,
  $$delete: $$delete$1,
  deleteByKey: deleteByKey$1,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$1
};

var bpgType$2 = "configuration/" + BacktraceParserGenerator$BsConsole.Users.table;

var cacheKey$2 = "bpg-" + bpgType$2;

var fetchAll$2 = baseTask(cacheKey$2, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Users.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$2
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$2(k) {
  return baseTask(cacheKey$2 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Users.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$2
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$2(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$2);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Users.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$2
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$2(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$2);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Users.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$2
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$2(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$2);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Users.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Users.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$2
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Users.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$2(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$2);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Users.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Users.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$2(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$2);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Users.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

var Users = {
  bpgType: bpgType$2,
  cacheKey: cacheKey$2,
  fetchAll: fetchAll$2,
  fetchOne: fetchOne$2,
  create: create$2,
  updateFieldsByKey: updateFieldsByKey$2,
  updateFields: updateFields$2,
  $$delete: $$delete$2,
  deleteByKey: deleteByKey$2
};

var bpgType$3 = "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table;

var cacheKey$3 = "bpg-" + bpgType$3;

var fetchAll$3 = baseTask(cacheKey$3, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$3
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$3(k) {
  return baseTask(cacheKey$3 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$3
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$3(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$3);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ResourceOverride.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$3
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$3(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$3);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$3
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$3(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$3);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$3
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$3(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$3);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$3(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$3);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$2(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$3);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ResourceOverride.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ResourceOverride = {
  bpgType: bpgType$3,
  cacheKey: cacheKey$3,
  fetchAll: fetchAll$3,
  fetchOne: fetchOne$3,
  create: create$3,
  updateFieldsByKey: updateFieldsByKey$3,
  updateFields: updateFields$3,
  $$delete: $$delete$3,
  deleteByKey: deleteByKey$3,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$2
};

var bpgType$4 = "configuration/" + BacktraceParserGenerator$BsConsole.Token.table;

var cacheKey$4 = "bpg-" + bpgType$4;

var fetchAll$4 = baseTask(cacheKey$4, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$4
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$4(k) {
  return baseTask(cacheKey$4 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$4
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$4(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$4);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Token.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$4
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$4(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$4);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$4
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$4(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$4);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Token.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Token.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$4
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Token.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$4(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$4);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Token.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$4(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$4);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$3(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$4);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Token.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Token.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Token = {
  bpgType: bpgType$4,
  cacheKey: cacheKey$4,
  fetchAll: fetchAll$4,
  fetchOne: fetchOne$4,
  create: create$4,
  updateFieldsByKey: updateFieldsByKey$4,
  updateFields: updateFields$4,
  $$delete: $$delete$4,
  deleteByKey: deleteByKey$4,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$3
};

var bpgType$5 = "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table;

var cacheKey$5 = "bpg-" + bpgType$5;

var fetchAll$5 = baseTask(cacheKey$5, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$5
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$5(k) {
  return baseTask(cacheKey$5 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$5
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$5(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$5);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ApiToken.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$5
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$5(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$5);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$5
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$5(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$5);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$5
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$5(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$5);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$5(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$5);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$4(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$5);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ApiToken.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ApiToken = {
  bpgType: bpgType$5,
  cacheKey: cacheKey$5,
  fetchAll: fetchAll$5,
  fetchOne: fetchOne$5,
  create: create$5,
  updateFieldsByKey: updateFieldsByKey$5,
  updateFields: updateFields$5,
  $$delete: $$delete$5,
  deleteByKey: deleteByKey$5,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$4
};

var bpgType$6 = "configuration/" + BacktraceParserGenerator$BsConsole.Deduplication.table;

var cacheKey$6 = "bpg-" + bpgType$6;

var fetchAll$6 = baseTask(cacheKey$6, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$6
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$6(k) {
  return baseTask(cacheKey$6 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$6
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$6(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$6);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Deduplication.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$6
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$6(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$6);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$6
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$6(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$6);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$6
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$6(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$6);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Deduplication.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$6(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$6);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Deduplication.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$5(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$6);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Deduplication.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Deduplication.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Deduplication.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Deduplication = {
  bpgType: bpgType$6,
  cacheKey: cacheKey$6,
  fetchAll: fetchAll$6,
  fetchOne: fetchOne$6,
  create: create$6,
  updateFieldsByKey: updateFieldsByKey$6,
  updateFields: updateFields$6,
  $$delete: $$delete$6,
  deleteByKey: deleteByKey$6,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$5
};

var bpgType$7 = "configuration/" + BacktraceParserGenerator$BsConsole.Query.table;

var cacheKey$7 = "bpg-" + bpgType$7;

var fetchAll$7 = baseTask(cacheKey$7, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$7
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$7(k) {
  return baseTask(cacheKey$7 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$7
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$7(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$7);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Query.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$7
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$7(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$7);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$7
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$7(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$7);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Query.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Query.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$7
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Query.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$7(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$7);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Query.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$7(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$7);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$6(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$7);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Query.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Query.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Query = {
  bpgType: bpgType$7,
  cacheKey: cacheKey$7,
  fetchAll: fetchAll$7,
  fetchOne: fetchOne$7,
  create: create$7,
  updateFieldsByKey: updateFieldsByKey$7,
  updateFields: updateFields$7,
  $$delete: $$delete$7,
  deleteByKey: deleteByKey$7,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$6
};

var bpgType$8 = "configuration/" + BacktraceParserGenerator$BsConsole.Project.table;

var cacheKey$8 = "bpg-" + bpgType$8;

var fetchAll$8 = baseTask(cacheKey$8, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$8
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$8(k) {
  return baseTask(cacheKey$8 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$8
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$8(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$8);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Project.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$8
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$8(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$8);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$8
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$8(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$8);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Project.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Project.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$8
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Project.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$8(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$8);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Project.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$8(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$8);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$7(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$8);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Project.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Project.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Project = {
  bpgType: bpgType$8,
  cacheKey: cacheKey$8,
  fetchAll: fetchAll$8,
  fetchOne: fetchOne$8,
  create: create$8,
  updateFieldsByKey: updateFieldsByKey$8,
  updateFields: updateFields$8,
  $$delete: $$delete$8,
  deleteByKey: deleteByKey$8,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$7
};

var bpgType$9 = "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table;

var cacheKey$9 = "bpg-" + bpgType$9;

var fetchAll$9 = baseTask(cacheKey$9, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$9
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$9(k) {
  return baseTask(cacheKey$9 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$9
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$9(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$9);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$9
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$9(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$9);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$9
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$9(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$9);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$9
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$9(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$9);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$9(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$9);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$8(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$9);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var UserFrontendSettings = {
  bpgType: bpgType$9,
  cacheKey: cacheKey$9,
  fetchAll: fetchAll$9,
  fetchOne: fetchOne$9,
  create: create$9,
  updateFieldsByKey: updateFieldsByKey$9,
  updateFields: updateFields$9,
  $$delete: $$delete$9,
  deleteByKey: deleteByKey$9,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$8
};

var bpgType$10 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.table;

var cacheKey$10 = "bpg-" + bpgType$10;

var fetchAll$10 = baseTask(cacheKey$10, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$10
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$10(k) {
  return baseTask(cacheKey$10 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$10
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$10(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$10);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$10
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$10(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$10);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$10
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$10(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$10);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$10
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$10(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$10);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$10(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$10);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$9(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$10);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectFrontendSettings = {
  bpgType: bpgType$10,
  cacheKey: cacheKey$10,
  fetchAll: fetchAll$10,
  fetchOne: fetchOne$10,
  create: create$10,
  updateFieldsByKey: updateFieldsByKey$10,
  updateFields: updateFields$10,
  $$delete: $$delete$10,
  deleteByKey: deleteByKey$10,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$9
};

var bpgType$11 = "configuration/" + BacktraceParserGenerator$BsConsole.Team.table;

var cacheKey$11 = "bpg-" + bpgType$11;

var fetchAll$11 = baseTask(cacheKey$11, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$11
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$11(k) {
  return baseTask(cacheKey$11 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$11
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$11(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$11);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Team.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$11
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$11(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$11);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$11
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$11(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$11);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Team.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Team.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$11
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Team.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$11(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$11);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Team.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Team.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$11(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$11);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Team.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$10(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$11);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Team.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Team.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Team.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Team.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Team = {
  bpgType: bpgType$11,
  cacheKey: cacheKey$11,
  fetchAll: fetchAll$11,
  fetchOne: fetchOne$11,
  create: create$11,
  updateFieldsByKey: updateFieldsByKey$11,
  updateFields: updateFields$11,
  $$delete: $$delete$11,
  deleteByKey: deleteByKey$11,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$10
};

var bpgType$12 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberTeam.table;

var cacheKey$12 = "bpg-" + bpgType$12;

var fetchAll$12 = baseTask(cacheKey$12, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$12
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$12(k) {
  return baseTask(cacheKey$12 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$12
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$12(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$12);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$12
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$12(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$12);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$12
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$12(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$12);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$12
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$12(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$12);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberTeam.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$12(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$12);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberTeam.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$11(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$12);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberTeam.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberTeam.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectMemberTeam = {
  bpgType: bpgType$12,
  cacheKey: cacheKey$12,
  fetchAll: fetchAll$12,
  fetchOne: fetchOne$12,
  create: create$12,
  updateFieldsByKey: updateFieldsByKey$12,
  updateFields: updateFields$12,
  $$delete: $$delete$12,
  deleteByKey: deleteByKey$12,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$11
};

var bpgType$13 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberUser.table;

var cacheKey$13 = "bpg-" + bpgType$13;

var fetchAll$13 = baseTask(cacheKey$13, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$13
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$13(k) {
  return baseTask(cacheKey$13 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$13
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$13(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$13);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$13
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$13(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$13);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$13
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$13(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$13);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$13
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$13(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$13);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberUser.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$13(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$13);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberUser.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$12(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$13);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectMemberUser.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectMemberUser = {
  bpgType: bpgType$13,
  cacheKey: cacheKey$13,
  fetchAll: fetchAll$13,
  fetchOne: fetchOne$13,
  create: create$13,
  updateFieldsByKey: updateFieldsByKey$13,
  updateFields: updateFields$13,
  $$delete: $$delete$13,
  deleteByKey: deleteByKey$13,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$12
};

var bpgType$14 = "configuration/" + BacktraceParserGenerator$BsConsole.TeamMember.table;

var cacheKey$14 = "bpg-" + bpgType$14;

var fetchAll$14 = baseTask(cacheKey$14, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$14
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$14(k) {
  return baseTask(cacheKey$14 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$14
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$14(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$14);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.TeamMember.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$14
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$14(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$14);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$14
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$14(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$14);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$14
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$14(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$14);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.TeamMember.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$14(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$14);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.TeamMember.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$13(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$14);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.TeamMember.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.TeamMember.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var TeamMember = {
  bpgType: bpgType$14,
  cacheKey: cacheKey$14,
  fetchAll: fetchAll$14,
  fetchOne: fetchOne$14,
  create: create$14,
  updateFieldsByKey: updateFieldsByKey$14,
  updateFields: updateFields$14,
  $$delete: $$delete$14,
  deleteByKey: deleteByKey$14,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$13
};

var bpgType$15 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table;

var cacheKey$15 = "bpg-" + bpgType$15;

var fetchAll$15 = baseTask(cacheKey$15, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$15
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$15(k) {
  return baseTask(cacheKey$15 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$15
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$15(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$15);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectRetention.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$15
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$15(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$15);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$15
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$15(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$15);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$15
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$15(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$15);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$15(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$15);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$14(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$15);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectRetention = {
  bpgType: bpgType$15,
  cacheKey: cacheKey$15,
  fetchAll: fetchAll$15,
  fetchOne: fetchOne$15,
  create: create$15,
  updateFieldsByKey: updateFieldsByKey$15,
  updateFields: updateFields$15,
  $$delete: $$delete$15,
  deleteByKey: deleteByKey$15,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$14
};

var bpgType$16 = "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table;

var cacheKey$16 = "bpg-" + bpgType$16;

var fetchAll$16 = baseTask(cacheKey$16, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$16
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$16(k) {
  return baseTask(cacheKey$16 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$16
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$16(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$16);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.InstanceRetention.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$16
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$16(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$16);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$16
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$16(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$16);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$16
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$16(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$16);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$16(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$16);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$15(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$16);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.InstanceRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var InstanceRetention = {
  bpgType: bpgType$16,
  cacheKey: cacheKey$16,
  fetchAll: fetchAll$16,
  fetchOne: fetchOne$16,
  create: create$16,
  updateFieldsByKey: updateFieldsByKey$16,
  updateFields: updateFields$16,
  $$delete: $$delete$16,
  deleteByKey: deleteByKey$16,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$15
};

var bpgType$17 = "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table;

var cacheKey$17 = "bpg-" + bpgType$17;

var fetchAll$17 = baseTask(cacheKey$17, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$17
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$17(k) {
  return baseTask(cacheKey$17 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$17
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$17(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$17);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.UniverseRetention.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$17
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$17(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$17);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$17
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$17(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$17);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$17
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$17(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$17);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$17(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$17);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$16(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$17);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniverseRetention.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var UniverseRetention = {
  bpgType: bpgType$17,
  cacheKey: cacheKey$17,
  fetchAll: fetchAll$17,
  fetchOne: fetchOne$17,
  create: create$17,
  updateFieldsByKey: updateFieldsByKey$17,
  updateFields: updateFields$17,
  $$delete: $$delete$17,
  deleteByKey: deleteByKey$17,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$16
};

var bpgType$18 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectSampling.table;

var cacheKey$18 = "bpg-" + bpgType$18;

var fetchAll$18 = baseTask(cacheKey$18, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$18
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$18(k) {
  return baseTask(cacheKey$18 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$18
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$18(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$18);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectSampling.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$18
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$18(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$18);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$18
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$18(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$18);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$18
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$18(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$18);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectSampling.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$18(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$18);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectSampling.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$17(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$18);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectSampling.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectSampling.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectSampling.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectSampling = {
  bpgType: bpgType$18,
  cacheKey: cacheKey$18,
  fetchAll: fetchAll$18,
  fetchOne: fetchOne$18,
  create: create$18,
  updateFieldsByKey: updateFieldsByKey$18,
  updateFields: updateFields$18,
  $$delete: $$delete$18,
  deleteByKey: deleteByKey$18,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$17
};

var bpgType$19 = "configuration/" + BacktraceParserGenerator$BsConsole.MetricGroup.table;

var cacheKey$19 = "bpg-" + bpgType$19;

var fetchAll$19 = baseTask(cacheKey$19, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$19
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$19(k) {
  return baseTask(cacheKey$19 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$19
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$19(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$19);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.MetricGroup.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$19
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$19(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$19);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$19
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$19(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$19);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$19
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$19(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$19);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricGroup.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$19(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$19);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricGroup.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$18(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$19);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricGroup.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricGroup.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricGroup.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var MetricGroup = {
  bpgType: bpgType$19,
  cacheKey: cacheKey$19,
  fetchAll: fetchAll$19,
  fetchOne: fetchOne$19,
  create: create$19,
  updateFieldsByKey: updateFieldsByKey$19,
  updateFields: updateFields$19,
  $$delete: $$delete$19,
  deleteByKey: deleteByKey$19,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$18
};

var bpgType$20 = "configuration/" + BacktraceParserGenerator$BsConsole.Metric.table;

var cacheKey$20 = "bpg-" + bpgType$20;

var fetchAll$20 = baseTask(cacheKey$20, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$20
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$20(k) {
  return baseTask(cacheKey$20 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$20
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$20(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$20);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Metric.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$20
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$20(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$20);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$20
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$20(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$20);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Metric.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Metric.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$20
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Metric.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$20(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$20);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Metric.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Metric.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$20(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$20);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Metric.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$19(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$20);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Metric.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Metric.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Metric.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Metric.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Metric = {
  bpgType: bpgType$20,
  cacheKey: cacheKey$20,
  fetchAll: fetchAll$20,
  fetchOne: fetchOne$20,
  create: create$20,
  updateFieldsByKey: updateFieldsByKey$20,
  updateFields: updateFields$20,
  $$delete: $$delete$20,
  deleteByKey: deleteByKey$20,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$19
};

var bpgType$21 = "configuration/" + BacktraceParserGenerator$BsConsole.MetricAttribute.table;

var cacheKey$21 = "bpg-" + bpgType$21;

var fetchAll$21 = baseTask(cacheKey$21, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$21
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$21(k) {
  return baseTask(cacheKey$21 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$21
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$21(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$21);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.MetricAttribute.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$21
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$21(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$21);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$21
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$21(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$21);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$21
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$21(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$21);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricAttribute.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$21(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$21);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricAttribute.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$20(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$21);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.MetricAttribute.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.MetricAttribute.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.MetricAttribute.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var MetricAttribute = {
  bpgType: bpgType$21,
  cacheKey: cacheKey$21,
  fetchAll: fetchAll$21,
  fetchOne: fetchOne$21,
  create: create$21,
  updateFieldsByKey: updateFieldsByKey$21,
  updateFields: updateFields$21,
  $$delete: $$delete$21,
  deleteByKey: deleteByKey$21,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$20
};

var bpgType$22 = "configuration/" + BacktraceParserGenerator$BsConsole.Scrubber.table;

var cacheKey$22 = "bpg-" + bpgType$22;

var fetchAll$22 = baseTask(cacheKey$22, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$22
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$22(k) {
  return baseTask(cacheKey$22 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$22
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$22(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$22);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.Scrubber.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$22
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$22(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$22);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$22
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$22(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$22);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$22
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$22(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$22);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Scrubber.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$22(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$22);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Scrubber.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$21(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$22);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.Scrubber.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.Scrubber.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Scrubber.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var Scrubber = {
  bpgType: bpgType$22,
  cacheKey: cacheKey$22,
  fetchAll: fetchAll$22,
  fetchOne: fetchOne$22,
  create: create$22,
  updateFieldsByKey: updateFieldsByKey$22,
  updateFields: updateFields$22,
  $$delete: $$delete$22,
  deleteByKey: deleteByKey$22,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$21
};

var bpgType$23 = "configuration/" + BacktraceParserGenerator$BsConsole.ServerSideActions.table;

var cacheKey$23 = "bpg-" + bpgType$23;

var fetchAll$23 = baseTask(cacheKey$23, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$23
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$23(k) {
  return baseTask(cacheKey$23 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$23
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$23(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$23);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ServerSideActions.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$23
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$23(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$23);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$23
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$23(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$23);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$23
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$23(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$23);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ServerSideActions.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$23(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$23);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ServerSideActions.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$22(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$23);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ServerSideActions.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ServerSideActions.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ServerSideActions.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ServerSideActions = {
  bpgType: bpgType$23,
  cacheKey: cacheKey$23,
  fetchAll: fetchAll$23,
  fetchOne: fetchOne$23,
  create: create$23,
  updateFieldsByKey: updateFieldsByKey$23,
  updateFields: updateFields$23,
  $$delete: $$delete$23,
  deleteByKey: deleteByKey$23,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$22
};

var bpgType$24 = "configuration/" + BacktraceParserGenerator$BsConsole.UniqueAggregations.table;

var cacheKey$24 = "bpg-" + bpgType$24;

var fetchAll$24 = baseTask(cacheKey$24, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$24
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$24(k) {
  return baseTask(cacheKey$24 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$24
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$24(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$24);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$24
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$24(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$24);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$24
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$24(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$24);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$24
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$24(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$24);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniqueAggregations.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$24(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$24);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniqueAggregations.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$23(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$24);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.UniqueAggregations.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.UniqueAggregations.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniqueAggregations.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var UniqueAggregations = {
  bpgType: bpgType$24,
  cacheKey: cacheKey$24,
  fetchAll: fetchAll$24,
  fetchOne: fetchOne$24,
  create: create$24,
  updateFieldsByKey: updateFieldsByKey$24,
  updateFields: updateFields$24,
  $$delete: $$delete$24,
  deleteByKey: deleteByKey$24,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$23
};

var bpgType$25 = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.table;

var cacheKey$25 = "bpg-" + bpgType$25;

var fetchAll$25 = baseTask(cacheKey$25, (function (param) {
        return Belt_Result.map(decodeHead(param.json), (function (param) {
                      return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                    }));
      }), [Json_encode.object_(/* :: */[
            /* tuple */[
              "type",
              bpgType$25
            ],
            /* :: */[
              /* tuple */[
                "action",
                "get"
              ],
              /* [] */0
            ]
          ])], undefined, undefined);

function fetchOne$25(k) {
  return baseTask(cacheKey$25 + Json.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, k)), (function (param) {
                var result = Belt_Result.map(decodeHead(param.json), (function (param) {
                        return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                      }));
                if (result.tag) {
                  return /* Error */Block.__(1, [result[0]]);
                } else {
                  return /* Ok */Block.__(0, [Belt_Array.get(result[0], 0)]);
                }
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$25
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "get"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], "fetch-one", undefined);
}

function create$25(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$25);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$25
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_json, entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFieldsByKey$25(key, fields) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$25);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$25
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, key)
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function updateFields$25(entity, fields) {
  return baseTask(undefined, (function (param) {
                var e = decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey$25);
                var entityObj = Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_json, entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType$25
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

function $$delete$25(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$25);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key, entity))
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function deleteByKey$25(k) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$25);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, k)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], undefined, undefined);
}

function cascadeDeleteUnsafe$24(entity) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey$25);
                                  return json;
                                })), (function (param) {
                              return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.of_json, param);
                            }));
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "configuration/" + BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.table
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "delete"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key, entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "cascade",
                            true
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], undefined, undefined);
}

var ProjectDeduplicationSettings = {
  bpgType: bpgType$25,
  cacheKey: cacheKey$25,
  fetchAll: fetchAll$25,
  fetchOne: fetchOne$25,
  create: create$25,
  updateFieldsByKey: updateFieldsByKey$25,
  updateFields: updateFields$25,
  $$delete: $$delete$25,
  deleteByKey: deleteByKey$25,
  cascadeDeleteUnsafe: cascadeDeleteUnsafe$24
};

var ParseError = Caml_exceptions.create("BpgTask-BsConsole.ParseError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function use(token, task) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp]);
                }));
  };
  var onFailure = function (msg) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [msg]);
                }));
  };
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  var update = function (param) {
    return Curry._1(setRemote, (function (param) {
                  return /* NotAsked */0;
                }));
  };
  return /* tuple */[
          remote,
          update
        ];
}

function encodeStringOrNull(str) {
  var ls = str.trim();
  if (ls === "") {
    return null;
  } else {
    return ls;
  }
}

function getOwnedItemsTask(uid) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "actions",
                        Json_encode.list((function (ele) {
                                return Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "type",
                                              ele
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "action",
                                                "get"
                                              ],
                                              /* [] */0
                                            ]
                                          ]);
                              }), /* :: */[
                              "configuration/project",
                              /* :: */[
                                "configuration/watcher_new",
                                /* :: */[
                                  "configuration/token",
                                  /* :: */[
                                    "configuration/api_token",
                                    /* :: */[
                                      "configuration/deduplication",
                                      /* :: */[
                                        "configuration/report",
                                        /* :: */[
                                          "configuration/resource_override",
                                          /* :: */[
                                            "configuration/symsrv",
                                            /* :: */[
                                              "configuration/project_member_user",
                                              /* :: */[
                                                "configuration/team_member",
                                                /* :: */[
                                                  "configuration/user_frontend_settings",
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      ],
                      /* [] */0
                    ])]), undefined, (function (param) {
                var json = param.json;
                try {
                  var objects = Json_decode.field("actions", (function (param) {
                          return Json_decode.list((function (json) {
                                        return Json_decode.field("type", Json_decode.string, json);
                                      }), param);
                        }), json);
                  var results = Json_decode.field("results", (function (param) {
                          return Json_decode.list((function (json) {
                                        return Json_decode.field("result", (function (param) {
                                                      return Json_decode.list((function (json) {
                                                                    return Json_decode.optional((function (param) {
                                                                                  return Json_decode.field("owner", Json_decode.$$int, param);
                                                                                }), json);
                                                                  }), param);
                                                    }), json);
                                      }), param);
                        }), json);
                  var objectsIds = Belt_List.keep(Belt_List.mapWithIndex(results, (function (idx, results) {
                              return /* tuple */[
                                      Belt_List.getExn(objects, idx),
                                      Belt_List.keep(results, (function (i) {
                                              return Belt_Option.getWithDefault(Belt_Option.map(i, (function (i) {
                                                                return i === uid;
                                                              })), false);
                                            }))
                                    ];
                            })), (function (param) {
                          return Belt_List.length(param[1]) > 0;
                        }));
                  var objs = Belt_List.map(objectsIds, (function (param) {
                          return param[0];
                        }));
                  if (objs) {
                    return /* Ok */Block.__(0, [objs]);
                  } else {
                    return /* Ok */Block.__(0, [undefined]);
                  }
                }
                catch (_exn){
                  return /* Ok */Block.__(0, [undefined]);
                }
              }), undefined, undefined);
}

exports.$$Error = $$Error;
exports.UnknownBpgError = UnknownBpgError;
exports.BpgError = BpgError;
exports.BpgResponseWithoutResult = BpgResponseWithoutResult;
exports.baseTask = baseTask;
exports.decodeResult = decodeResult;
exports.decodeHead = decodeHead;
exports.MakeBaseBpgTasks = MakeBaseBpgTasks;
exports.MakeBpgTasks = MakeBpgTasks;
exports.InvalidBpgOperation = InvalidBpgOperation;
exports.BulkBpgDecodingError = BulkBpgDecodingError;
exports.decodeAction = decodeAction;
exports.decodeBulk = decodeBulk;
exports.MakeBatchBpgTask1 = MakeBatchBpgTask1;
exports.WatcherNew = WatcherNew;
exports.SignupPending = SignupPending;
exports.Users = Users;
exports.ResourceOverride = ResourceOverride;
exports.Token = Token;
exports.ApiToken = ApiToken;
exports.Deduplication = Deduplication;
exports.Query = Query;
exports.Project = Project;
exports.UserFrontendSettings = UserFrontendSettings;
exports.ProjectFrontendSettings = ProjectFrontendSettings;
exports.Team = Team;
exports.ProjectMemberTeam = ProjectMemberTeam;
exports.ProjectMemberUser = ProjectMemberUser;
exports.TeamMember = TeamMember;
exports.ProjectRetention = ProjectRetention;
exports.InstanceRetention = InstanceRetention;
exports.UniverseRetention = UniverseRetention;
exports.ProjectSampling = ProjectSampling;
exports.MetricGroup = MetricGroup;
exports.Metric = Metric;
exports.MetricAttribute = MetricAttribute;
exports.Scrubber = Scrubber;
exports.ServerSideActions = ServerSideActions;
exports.UniqueAggregations = UniqueAggregations;
exports.ProjectDeduplicationSettings = ProjectDeduplicationSettings;
exports.ParseError = ParseError;
exports.responseCb = responseCb;
exports.use = use;
exports.encodeStringOrNull = encodeStringOrNull;
exports.getOwnedItemsTask = getOwnedItemsTask;
/*  Not a pure module */
