// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSearchSelect$BsConsole = require("./BtSearchSelect.js");
var InputLabel = require("@material-ui/core/InputLabel");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");

var label = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.fontSize(Css.px(10)),
        /* :: */[
          Css.left(Css.rem(0.5)),
          /* :: */[
            Css.top(Css.px(4)),
            /* :: */[
              Css.zIndex(3),
              /* :: */[
                Css.color(Css.currentColor),
                /* :: */[
                  Css.opacity(0.54),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.paddingLeft(Css.rem(0.5)),
      /* :: */[
        Css.paddingTop(Css.rem(0.8)),
        /* :: */[
          Css.paddingRight(Css.px(32)),
          /* :: */[
            Css.color(Css.currentColor),
            /* :: */[
              Css.fontSize(Css.px(15)),
              /* :: */[
                Css.width(/* auto */-1065951377),
                /* :: */[
                  Css.height(/* auto */-1065951377),
                  /* :: */[
                    Css.maxWidth(Css.px(150)),
                    /* :: */[
                      Css.minWidth(Css.px(50)),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* :: */[
                          Css.whiteSpace(/* nowrap */867913355),
                          /* :: */[
                            Css.textOverflow(/* ellipsis */166888785),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.color(Css.currentColor),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.unsafe("top", "calc(50% - 12px)"),
          /* :: */[
            Css.right(Css.px(0)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  label: label,
  select: select,
  icon: icon
};

function BtProjectAutocomplete(Props) {
  var projects = Props.projects;
  var handleProjectChange = Props.handleProjectChange;
  var classNameOpt = Props.className;
  Props.rootClassName;
  var label$1 = Props.label;
  var name = Props.name;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return false;
        }));
  var setOpen_ = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    }),
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.position(/* relative */903134412),
                              /* :: */[
                                Css.height(Css.px(40)),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          className,
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(setOpen_, (function (prev) {
                                    return !prev;
                                  }));
                    })
                }, React.createElement(InputLabel.default, {
                      className: label,
                      children: I18N$BsConsole.showSkip(label$1)
                    }), React.createElement("div", {
                      className: select,
                      role: "button"
                    }, I18N$BsConsole.show(undefined, name)), React.createElement(ArrowDropDown.default, {
                      className: icon
                    })), React.createElement(BtSearchSelect$BsConsole.make, {
                  anchorEl: match$1[0],
                  open_: match[0],
                  selectedValue: name,
                  considerations: Belt_List.map(projects, (function (project) {
                          return project.name;
                        })),
                  placeholder: I18N$BsConsole.get(undefined, "Search projects"),
                  onSelectionChange: Curry.__1(handleProjectChange),
                  setMenuOpen: (function (isOpen) {
                      return Curry._1(setOpen_, (function (param) {
                                    return isOpen;
                                  }));
                    })
                }));
}

var component = ReasonReact.statelessComponent("BtProjectAutocomplete");

function make(projects, handleProjectChange, className, label, name, children) {
  return ReasonReactCompat.wrapReactForReasonReact(BtProjectAutocomplete, {
              projects: projects,
              handleProjectChange: handleProjectChange,
              className: className,
              label: label,
              name: name
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = BtProjectAutocomplete;

exports.Style = Style;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* label Not a pure module */
