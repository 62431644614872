// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Services$BsConsole = require("./Services.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var SymbolServers_StatsUsage$BsConsole = require("./project-settings/symbols/SymbolServers/SymbolServers_StatsUsage.js");

var ParseError = Caml_exceptions.create("StorageFetch-BsConsole.ParseError");

function asBytes(t) {
  return t * 1000000.0;
}

function toBytesString(t) {
  return SymbolServers_StatsUsage$BsConsole.bytesFormatted(t * 1000000.0);
}

var Usage = {
  asBytes: asBytes,
  toBytesString: toBytesString
};

function total(t) {
  return Belt_Array.reduce(Belt_Array.map(t, (function (prim) {
                    return prim[1];
                  })), 0, (function (prim, prim$1) {
                return prim + prim$1;
              }));
}

var StorageData = {
  Usage: Usage,
  total: total
};

function fromJson(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          reports: Json_decode.field("reports", Json_decode.$$float, json),
          attachments: Json_decode.field("attachments", Json_decode.$$float, json),
          symbols: Json_decode.field("symbols", Json_decode.$$float, json),
          database: Json_decode.field("database", Json_decode.$$float, json),
          total: Json_decode.field("total", Json_decode.$$float, json)
        };
}

var Project = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return Belt_Array.keep(Json_decode.array(fromJson, json), (function (p) {
                return p.type_ === "project";
              }));
}

var Universe = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          projects: Json_decode.oneOf(/* :: */[
                fromJson$1,
                /* :: */[
                  (function (json) {
                      return [fromJson(json)];
                    }),
                  /* [] */0
                ]
              ], Json_decode.field("data", (function (j) {
                      return j;
                    }), json)),
          timestamp: Json_decode.field("timestamp", Json_decode.$$int, json)
        };
}

function makeTask(endpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint]), undefined, /* Get */0, undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [fromJson$2(param.json)]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "StorageFetch/makeTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  return Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : "Something went wrong.");
}

function use(token, config) {
  var match = React.useState((function () {
          return 0;
        }));
  var setKey = match[1];
  var bumpKey = function (param) {
    return Curry._1(setKey, (function (k) {
                  return k + 1 | 0;
                }));
  };
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match$1[1];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp]);
                }));
  };
  var onFailure = function (errorMessage) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [errorMessage]);
                }));
  };
  React.useEffect((function () {
          var endpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "storage");
          if (endpoint !== undefined) {
            Curry._1(setRemote, (function (param) {
                    return /* Loading */1;
                  }));
            var arg = makeTask(endpoint + "/universe");
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (param) {
                    return responseCb(onSuccess, onFailure, param);
                  }));
          } else {
            console.log("No Storage Utilization Service");
          }
          
        }), /* tuple */[
        match[0],
        config,
        token
      ]);
  return /* tuple */[
          match$1[0],
          bumpKey
        ];
}

exports.ParseError = ParseError;
exports.StorageData = StorageData;
exports.Project = Project;
exports.Universe = Universe;
exports.fromJson = fromJson$2;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.use = use;
/* react Not a pure module */
