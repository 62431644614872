// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var BtTable$BsConsole = require("../BtTable.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var AlertsRouter$BsConsole = require("../project-settings/alerts/AlertsRouter.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Delete = require("@material-ui/icons/Delete");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var OpenInNew = require("@material-ui/icons/OpenInNew");
var ExporterDeleteModal$BsConsole = require("../metrics-exporter/ExporterDeleteModal.js");
var AlertsAlertConfigApi$BsConsole = require("./api/AlertsAlertConfigApi.js");
var AlertsCreateAlertModal$BsConsole = require("./AlertsCreateAlertModal.js");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

function AlertsListView(Props) {
  var config = Props.config;
  var token = Props.token;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var alertConfigs = Props.alertConfigs;
  var setAlertConfigs = Props.setAlertConfigs;
  var fetchAlertConfigs = Props.fetchAlertConfigs;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setCreateModal = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setDeleteModal = match$1[1];
  var deleteModal = match$1[0];
  var handleDelete = function (param) {
    if (deleteModal === undefined) {
      return ;
    }
    var arg = AlertsAlertConfigApi$BsConsole.$$delete(endpoint, universeName, projectName, deleteModal.id, undefined);
    Curry._2((function (param) {
              return (function (param$1, param$2) {
                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                });
            })(token), undefined, (function (resp) {
            if (resp.tag) {
              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
            }
            var updated = Belt_List.keep(alertConfigs, (function (v) {
                    return v.id !== deleteModal.id;
                  }));
            return Curry._1(setAlertConfigs, (function (param) {
                          return updated;
                        }));
          }));
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement(AlertsCreateAlertModal$BsConsole.make, {
                  config: config,
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setCreateModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  init: /* Query */Block.__(1, [
                      /* Identity */0,
                      Crdb$BsConsole.Fold.empty,
                      Crdb$BsConsole.Aperture.$$default,
                      Crdb$BsConsole.Havings.empty,
                      Crdb$BsConsole.Sort.defaultAggregate
                    ]),
                  handleSave: (function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
                      return AlertsAlertConfigApi$BsConsole.saveAlert(/* SettingsListView */1, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7);
                    }),
                  afterSave: fetchAlertConfigs,
                  endpoint: endpoint,
                  universeName: universeName,
                  projectName: projectName,
                  token: token,
                  handleChangeUrl: handleChangeUrl,
                  existingAlerts: /* [] */0
                }), React.createElement(ExporterDeleteModal$BsConsole.make, {
                  open: deleteModal !== undefined,
                  onClose: (function (param) {
                      return Curry._1(setDeleteModal, (function (param) {
                                    
                                  }));
                    }),
                  handleDelete: handleDelete,
                  title: I18N$BsConsole.get(undefined, "Delete alert"),
                  itemName: "alert"
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* :: */[
                            Css.marginRight(Css.px(12)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined));
                    }),
                  children: null
                }, React.createElement(OpenInNew.default, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ])
                    }), I18N$BsConsole.show(undefined, "Create an alert in Explore")), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setCreateModal, (function (param) {
                                    return true;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create an alert here")
                }), Belt_List.length(alertConfigs) === 0 ? null : React.createElement(BtSettings$BsConsole.Paper.make, {
                    className: Css.style(/* :: */[
                          pageMarginBottom,
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ]),
                    children: React.createElement(BtTableContainer$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.borderRadius(Css.px(4)),
                                /* [] */0
                              ]),
                          children: React.createElement(BtTable$BsConsole.make, {
                                children: null
                              }, React.createElement(BtTableHead$BsConsole.make, {
                                    children: React.createElement(BtTableRow$BsConsole.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                              children: I18N$BsConsole.show(undefined, "Name")
                                            }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                              children: I18N$BsConsole.show(undefined, "Status")
                                            }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.px(144)),
                                                    /* [] */0
                                                  ])
                                            }))
                                  }), React.createElement(BtTableBody$BsConsole.make, {
                                    children: Belt_List.toArray(Belt_List.map(alertConfigs, (function (curr) {
                                                return React.createElement(BtTableRow$BsConsole.make, {
                                                            children: null,
                                                            key: curr.name
                                                          }, React.createElement(BtTableCell$BsConsole.make, {
                                                                children: I18N$BsConsole.showSkip(curr.name)
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                children: curr.enabled ? I18N$BsConsole.show(undefined, "Active") : I18N$BsConsole.show(undefined, "Inactive")
                                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                                className: Css.style(/* :: */[
                                                                      Css.important(Css.padding(/* zero */-789508312)),
                                                                      /* [] */0
                                                                    ]),
                                                                children: null
                                                              }, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                                    ariaLabel: "view details",
                                                                    onClick: (function (param) {
                                                                        return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                                                                      projectName,
                                                                                      {
                                                                                        tab: AlertsRouter$BsConsole.typeToString(/* ErrorThresholdAlerts */0),
                                                                                        id: curr.id
                                                                                      }
                                                                                    ]));
                                                                      }),
                                                                    title_tooltip: I18N$BsConsole.get(undefined, "View details"),
                                                                    children: React.createElement(KeyboardArrowRight.default, { })
                                                                  }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                                    ariaLabel: "delete " + curr.name,
                                                                    onClick: (function (param) {
                                                                        return Curry._1(setDeleteModal, (function (param) {
                                                                                      return curr;
                                                                                    }));
                                                                      }),
                                                                    children: React.createElement(Delete.default, { })
                                                                  })));
                                              })))
                                  }))
                        })
                  }));
}

var make = AlertsListView;

exports.pageMarginBottom = pageMarginBottom;
exports.make = make;
/* pageMarginBottom Not a pure module */
