// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Image$BsConsole = require("../components/Image.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BugAttributeRow$BsConsole = require("./BugAttributeRow.js");
var Devices = require("@material-ui/icons/Devices");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");

var iconAttriubteImg = Css.style(/* :: */[
      Css.height(Css.px(16)),
      /* [] */0
    ]);

var Style = {
  iconAttriubteImg: iconAttriubteImg
};

function getAttributeValue(allAttributes, attributeName) {
  var value = Js_dict.get(allAttributes, attributeName);
  if (value !== undefined) {
    return Js_json.decodeString(Caml_option.valFromOption(value));
  }
  
}

function getAttributeRaw(allAttributes, attributeName) {
  var value = Js_dict.get(allAttributes, attributeName);
  if (value !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(value));
  }
  
}

var all_000 = {
  label: "Firefox",
  url: "https://res.cloudinary.com/backtrace/image/upload/v1714426293/firefoxNew.svg",
  regex: new RegExp("firefox", "i"),
  alt: "Firefox Icon"
};

var all_001 = /* :: */[
  {
    label: "Explorer",
    url: "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-iexplore-color.svg",
    regex: new RegExp("explore", "i"),
    alt: "Explorer Icon"
  },
  /* :: */[
    {
      label: "Edge",
      url: "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-edge-color.svg",
      regex: new RegExp("edge", "i"),
      alt: "Edge Icon"
    },
    /* :: */[
      {
        label: "Chrome",
        url: "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-chrome-color.svg",
        regex: new RegExp("chrome", "i"),
        alt: "Chrome Icon"
      },
      /* :: */[
        {
          label: "Safari",
          url: "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-safari-color.svg",
          regex: new RegExp("safari", "i"),
          alt: "Safari Icon"
        },
        /* :: */[
          {
            label: "Opera",
            url: "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-opera-color.svg",
            regex: new RegExp("opera", "i"),
            alt: "Opera Icon"
          },
          /* [] */0
        ]
      ]
    ]
  ]
];

var all = /* :: */[
  all_000,
  all_001
];

var unknownUrl = "https://res.cloudinary.com/backtrace/image/upload/v1692991176/icn-browser-unknown.svg";

var unknownAlt = "Uknown Browser Icon";

var Browser = {
  all: all,
  unknownLabel: "Unknown",
  unknownUrl: unknownUrl,
  unknownAlt: unknownAlt
};

var all_000$1 = {
  label: "Windows",
  url: "https://res.cloudinary.com/backtrace/image/upload/v1692991191/icn-windows8-color.svg",
  regex: new RegExp("windows", "i"),
  alt: "Windows Icon"
};

var all_001$1 = /* :: */[
  {
    label: "Linux",
    url: "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-linux-color.svg",
    regex: new RegExp("linux", "i"),
    alt: "Linux Icon"
  },
  /* :: */[
    {
      label: "macOS",
      url: "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-macos-color.svg",
      regex: new RegExp("mac", "i"),
      alt: "macOS Icon"
    },
    /* :: */[
      {
        label: "iOS",
        url: "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-macos-color.svg",
        regex: new RegExp("ios", "i"),
        alt: "iOS Icon"
      },
      /* :: */[
        {
          label: "ChromeOS",
          url: "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-google-color.svg",
          regex: new RegExp("chrome", "i"),
          alt: "ChromeOS Icon"
        },
        /* :: */[
          {
            label: "Android",
            url: "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-android-color.svg",
            regex: new RegExp("android", "i"),
            alt: "Android Icon"
          },
          /* :: */[
            {
              label: "Chromium",
              url: "https://res.cloudinary.com/backtrace/image/upload/v1714508644/chromium.svg",
              regex: new RegExp("chromium", "i"),
              alt: "Chromium Icon"
            },
            /* :: */[
              {
                label: "PlayStation",
                url: "https://res.cloudinary.com/backtrace/image/upload/v1609955599/playstation_logo.png",
                regex: new RegExp("playstation", "i"),
                alt: "PlayStation Icon"
              },
              /* :: */[
                {
                  label: "Xbox",
                  url: "https://res.cloudinary.com/backtrace/image/upload/v1714509561/xBox.svg",
                  regex: new RegExp("xbox", "i"),
                  alt: "Xbox Icon"
                },
                /* :: */[
                  {
                    label: "Switch",
                    url: "https://res.cloudinary.com/backtrace/image/upload/v1714509878/switch.svg",
                    regex: new RegExp("switch", "i"),
                    alt: "Switch Icon"
                  },
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var all$1 = /* :: */[
  all_000$1,
  all_001$1
];

var unknownUrl$1 = "https://res.cloudinary.com/backtrace/image/upload/v1692991189/icn-os-unknown.svg";

var unknownAlt$1 = "Uknown OS Icon";

var OS = {
  all: all$1,
  unknownLabel: "Unknown",
  unknownUrl: unknownUrl$1,
  unknownAlt: unknownAlt$1
};

function getMatchingIcon(attribute) {
  var attributeName = attribute[0];
  var attributeValue = BugAttributeRow$BsConsole.Model.getAttributeRawValueString(attribute[1]);
  switch (attributeName) {
    case "browser.name" :
        var browserObj = Belt_List.getBy(all, (function (b) {
                return b.regex.test(attributeValue);
              }));
        var match = browserObj !== undefined ? /* tuple */[
            browserObj.url,
            browserObj.alt
          ] : /* tuple */[
            unknownUrl,
            unknownAlt
          ];
        return React.createElement(Image$BsConsole.make, {
                    className: iconAttriubteImg,
                    src: match[0],
                    alt: match[1]
                  });
    case "device.model" :
        return React.createElement(Devices.default, { });
    case "uname.sysname" :
        var str = attributeValue.toLocaleLowerCase().replace(" ", "");
        var mapToOS;
        if (new RegExp("darwin").test(str) || new RegExp("mac").test(str) || new RegExp("macOS").test(str)) {
          mapToOS = "macOS";
        } else if (new RegExp("ios").test(str)) {
          mapToOS = "ios";
        } else if (new RegExp("windows").test(str)) {
          mapToOS = "windows";
        } else if (new RegExp("android").test(str)) {
          mapToOS = "android";
        } else if (new RegExp("linux").test(str) || new RegExp("ubuntu").test(str)) {
          mapToOS = "linux";
        } else if (new RegExp("chrome").test(str)) {
          mapToOS = "chrome";
        } else if (new RegExp("chromium").test(str)) {
          mapToOS = "chromium";
        } else if (new RegExp("ps5").test(str) || new RegExp("ps4").test(str)) {
          mapToOS = "playstation";
        } else if (new RegExp("xbox").test(str)) {
          mapToOS = "xbox";
        } else if (new RegExp("switch").test(str)) {
          mapToOS = "switch";
        } else {
          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugHightlightBarDefaults/getMatchingIcon/mapToOS", Backtrace$BsConsole.Attributes.make(undefined));
          Backtrace$BsConsole.Client.send(__x, /* `string */[
                288368849,
                "\"Highlightbar: uname.sysname " + (String(attributeValue) + " is not mapped to an OS icon.\"")
              ]);
          mapToOS = "unknown";
        }
        var osObj = Belt_List.getBy(all$1, (function (b) {
                return b.regex.test(mapToOS);
              }));
        var match$1 = osObj !== undefined ? /* tuple */[
            osObj.url,
            osObj.alt
          ] : /* tuple */[
            unknownUrl$1,
            unknownAlt$1
          ];
        return React.createElement(Image$BsConsole.make, {
                    className: iconAttriubteImg,
                    src: match$1[0],
                    alt: match$1[1]
                  });
    default:
      var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "BugHightlightBarDefaults/getMatchingIcon", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x$1, /* `string */[
            288368849,
            "\"Highlightbar: attribute " + (String(attributeName) + " does not have an icon.\"")
          ]);
      return React.createElement(Image$BsConsole.make, {
                  className: iconAttriubteImg,
                  src: unknownUrl$1,
                  alt: unknownAlt$1
                });
  }
}

var Icons = {
  Browser: Browser,
  OS: OS,
  getMatchingIcon: getMatchingIcon
};

function getDefaultHighlightBarIcons(attributes) {
  var defaults = [
    "device.model",
    "browser.name",
    "uname.sysname"
  ];
  var defaultAttributes = Belt_Array.keep(attributes, (function (attr) {
          var attributeName = attr[0];
          var attributeValue = BugAttributeRow$BsConsole.Model.getAttributeRawValueString(attr[1]);
          var match = defaults.includes(attributeName);
          if (!match) {
            return false;
          }
          switch (attributeValue) {
            case "" :
            case "null" :
                return false;
            default:
              return true;
          }
        }));
  if (defaultAttributes.length === 0) {
    return [];
  } else {
    return Belt_Array.map(defaultAttributes, (function (attribute) {
                  return {
                          iconElement: getMatchingIcon(attribute),
                          attribute: attribute
                        };
                }));
  }
}

function getDefaultHighlightBarAttributes(attributes, describedAttributes) {
  var defaults = [];
  Belt_Array.forEach([
        [
          "location.href",
          "hostname"
        ],
        [
          "application.version",
          "version"
        ],
        ["error.type"],
        ["mobile"],
        ["scene.name"],
        ["graphic.name"],
        ["graphic.memory"],
        ["process.age"]
      ], (function (attrArr) {
          var oneSelected = {
            contents: false
          };
          return Belt_Array.forEach(attrArr, (function (attr) {
                        var tmp = false;
                        if (!oneSelected.contents) {
                          var tmp$1 = false;
                          if (Object.keys(attributes).includes(attr)) {
                            var maybeAttributeValue = getAttributeRaw(attributes, attr);
                            tmp$1 = maybeAttributeValue !== undefined ? Attribute_Helpers$BsConsole.RelevantAttributes.isRelevantAttribute(describedAttributes, /* tuple */[
                                    attr,
                                    Caml_option.valFromOption(maybeAttributeValue)
                                  ]) : false;
                          }
                          tmp = tmp$1;
                        }
                        if (tmp) {
                          defaults.push(attr);
                          oneSelected.contents = true;
                          return ;
                        }
                        
                      }));
        }));
  return Belt_Array.slice(defaults, 0, 5);
}

var maxHighlightbarAttributes = 5;

exports.maxHighlightbarAttributes = maxHighlightbarAttributes;
exports.Style = Style;
exports.getAttributeValue = getAttributeValue;
exports.getAttributeRaw = getAttributeRaw;
exports.Icons = Icons;
exports.getDefaultHighlightBarIcons = getDefaultHighlightBarIcons;
exports.getDefaultHighlightBarAttributes = getDefaultHighlightBarAttributes;
/* iconAttriubteImg Not a pure module */
