// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../../RR.js");
var I18N$BsConsole = require("../../../I18N.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_Metrics$BsConsole = require("./SymbolServers_Metrics.js");
var SymbolServers_SymbolList$BsConsole = require("./SymbolServers_SymbolList.js");

var component = RR$BsConsole.statelessComponent("SymbolServers_Whitelist-BsConsole");

function make(serverId, token, endpoint, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, SymbolServers_SymbolList$BsConsole.make(SymbolServer_API$BsConsole.getSymbolServerWhitelist, I18N$BsConsole.get(undefined, "allowlist"), SymbolServer_API$BsConsole.postWhitelist, (function (param) {
                                return SymbolServers_Metrics$BsConsole.send(/* WhitelistUpdateSuccess */8);
                              }), (function (param) {
                                return SymbolServers_Metrics$BsConsole.send(/* WhitelistUpdateFailed */9);
                              }), SymbolServer_API$BsConsole.deleteSymbolWhitelist, (function (param) {
                                return SymbolServers_Metrics$BsConsole.send(/* WhitelistDeleteSuccess */10);
                              }), (function (param) {
                                return SymbolServers_Metrics$BsConsole.send(/* WhitelistDeleteFailed */11);
                              }), serverId, token, endpoint, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
