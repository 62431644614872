// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var BugAttributeRow$BsConsole = require("./BugAttributeRow.js");

var errorMessageContainer = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* row */5693978),
            /* :: */[
              Css.justifyContent(/* flexStart */662439529),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.flexGrow(1),
                  /* :: */[
                    Css.maxWidth(Css.pct(100)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey95)),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* :: */[
                          Css.fontWeight(/* `num */[
                                5496390,
                                500
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var errorMessageText = Css.style(/* :: */[
      Css.width(/* auto */-1065951377),
      /* :: */[
        Css.maxWidth(Css.pct(100)),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* :: */[
                Css.fontSize(Css.px(16)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var popoverPaper = Css.style(/* :: */[
      Css.important(Css.maxWidth(Css.px(180))),
      /* [] */0
    ]);

var Styles = {
  errorMessageContainer: errorMessageContainer,
  errorMessageText: errorMessageText,
  popoverPaper: popoverPaper
};

function BugHeaderErrorMessage$BugHeaderMenuList(Props) {
  var model = Props.model;
  var maybeProjectAttribute = Props.maybeProjectAttribute;
  var dispatch = Props.dispatch;
  var match = Js_json.classify(model.attributeValue);
  if (maybeProjectAttribute === undefined) {
    return BugAttributeRow$BsConsole.renderUnknownAttributeMenuList(dispatch, model);
  }
  if (typeof match === "number") {
    if (match === /* JSONNull */2) {
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuListWithoutFilters(dispatch, maybeProjectAttribute, model);
    } else {
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuList(dispatch, maybeProjectAttribute, model);
    }
  }
  switch (match.tag | 0) {
    case /* JSONObject */2 :
    case /* JSONArray */3 :
        return BugAttributeRow$BsConsole.renderProjectAttributeMenuListWithoutFilters(dispatch, maybeProjectAttribute, model);
    default:
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuList(dispatch, maybeProjectAttribute, model);
  }
}

var BugHeaderMenuList = {
  make: BugHeaderErrorMessage$BugHeaderMenuList
};

function BugHeaderErrorMessage(Props) {
  var projectName = Props.projectName;
  var attributeName = Props.attributeName;
  var attributeValue = Props.attributeValue;
  var maybeProjectAttribute = Props.maybeProjectAttribute;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return {
                  attributeName: attributeName,
                  attributeValue: attributeValue,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilter: onAddFilter,
                  isHovered: false,
                  popover: undefined,
                  projectAttribute: maybeProjectAttribute,
                  projectName: projectName,
                  route: /* ProjectSettingsAttributes */Block.__(34, [
                      projectName,
                      {
                        attribute: attributeName
                      }
                    ])
                };
        }));
  var setModel = match[1];
  var model = match[0];
  var dispatch = function (action) {
    return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: errorMessageContainer
                }, React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: React.createElement("span", {
                            className: errorMessageText,
                            onClick: (function ($$event) {
                                var action = BugAttributeRow$BsConsole.Action.popoverOpen($$event);
                                return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
                              }),
                            onMouseEnter: (function (param) {
                                return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseEnter */0));
                              }),
                            onMouseLeave: (function (param) {
                                return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseLeave */1));
                              })
                          }, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Error Message: ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "Error Message: %s"
                                  ]), BugAttributeRow$BsConsole.Model.getAttributeRawValueString(model.attributeValue))),
                      title: BugAttributeRow$BsConsole.Model.isProjectAttribute(model) ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "error.message: ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "error.message: %s"
                                ]), BugAttributeRow$BsConsole.Model.getAttributeRawValueString(model.attributeValue)) : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " is a non-indexed attribute and can not be queried. Click for steps to index.",
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "%s is a non-indexed attribute and can not be queried. Click for steps to index."
                                ]), model.attributeName)
                    })), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: model.popover,
                  open: BugAttributeRow$BsConsole.Model.hasPopover(model),
                  classes: {
                    paper: popoverPaper
                  },
                  onClose: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* PopoverClose */2));
                    }),
                  children: React.createElement(BugHeaderErrorMessage$BugHeaderMenuList, {
                        model: model,
                        maybeProjectAttribute: maybeProjectAttribute,
                        dispatch: dispatch
                      })
                }));
}

var make = BugHeaderErrorMessage;

exports.Styles = Styles;
exports.BugHeaderMenuList = BugHeaderMenuList;
exports.make = make;
/* errorMessageContainer Not a pure module */
