// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var SSAUtils$BsConsole = require("../SSAUtils.js");
var SSAAction$BsConsole = require("../SSAAction.js");

var helpText = "Drop objects and index only when data matches the following conditions.";

function isComplete(t) {
  var match = t.source;
  var match$1 = t.conditions;
  if (match !== undefined ? (
        typeof match === "number" ? (
            match$1 !== undefined ? SSAAction$BsConsole.isConditionValid(match$1) : false
          ) : (
            match$1 !== undefined ? SSAUtils$BsConsole.isNotEmptyStr(match[0]) && SSAAction$BsConsole.isConditionValid(match$1) : false
          )
      ) : false) {
    return Belt_Option.isSome(t.probability.value);
  } else {
    return false;
  }
}

function toAction(t) {
  if (!isComplete(t)) {
    return ;
  }
  var match = t.source;
  var match$1 = t.conditions;
  if (match !== undefined && match$1 !== undefined) {
    return /* IndexOnly */Block.__(1, [/* tuple */[
                undefined,
                match,
                match$1,
                Belt_Option.getWithDefault(t.probability.value, 1)
              ]]);
  }
  
}

var formItems = [
  /* Source */Block.__(0, [/* tuple */[
        SSAUtils$BsConsole.sourceText,
        helpText
      ]]),
  /* Condition */Block.__(1, [SSAUtils$BsConsole.conditionText]),
  /* SetExecutionProbability */2
];

var Module = {
  formItems: formItems,
  isComplete: isComplete,
  toAction: toAction
};

exports.helpText = helpText;
exports.Module = Module;
/* SSAUtils-BsConsole Not a pure module */
