// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");

function ofJson(json) {
  return {
          start: Json_decode.optional((function (param) {
                  return Json_decode.field("start", Json_decode.string, param);
                }), json),
          size: Json_decode.optional((function (param) {
                  return Json_decode.field("size", Json_decode.$$int, param);
                }), json),
          path: Json_decode.optional((function (param) {
                  return Json_decode.field("path", Json_decode.string, param);
                }), json),
          version: Json_decode.optional((function (param) {
                  return Json_decode.field("version", Json_decode.string, param);
                }), json),
          debug_file: Json_decode.optional((function (param) {
                  return Json_decode.field("debug_file", Json_decode.string, param);
                }), json),
          debug_identifier: Json_decode.optional((function (param) {
                  return Json_decode.field("debug_identifier", Json_decode.string, param);
                }), json),
          code_file: Json_decode.optional((function (param) {
                  return Json_decode.field("code_file", Json_decode.string, param);
                }), json),
          code_identifier: Json_decode.optional((function (param) {
                  return Json_decode.field("code_identifier", Json_decode.string, param);
                }), json),
          debug_file_exists: Json_decode.optional((function (param) {
                  return Json_decode.field("debug_file_exists", Json_decode.bool, param);
                }), json)
        };
}

var Module = {
  ofJson: ofJson
};

function partial_arg_000(param) {
  return Json_decode.field("modules", (function (param) {
                return Json_decode.list(ofJson, param);
              }), param);
}

var partial_arg_001 = /* :: */[
  Json_decode.at(/* :: */[
        "annotations",
        /* :: */[
          "Modules",
          /* [] */0
        ]
      ], (function (param) {
          return Json_decode.list(ofJson, param);
        })),
  /* [] */0
];

var partial_arg = /* :: */[
  partial_arg_000,
  partial_arg_001
];

function partial_arg$1(param) {
  return Json_decode.oneOf(partial_arg, param);
}

function partial_arg$2(param) {
  return Json_decode.optional(partial_arg$1, param);
}

function ofJson$1(param) {
  return Json_decode.map((function (modules) {
                if (modules !== undefined && !modules) {
                  return ;
                } else {
                  return modules;
                }
              }), partial_arg$2, param);
}

function fromGlobalAnnotations(globalAnnotations) {
  return Belt_Array.reduce(globalAnnotations, [], (function (arr, annotation) {
                var match = annotation.key;
                if (match[0] < 3752319) {
                  return arr;
                }
                if (match[1] !== "Modules") {
                  return arr;
                }
                var json = CamlinternalLazy.force(annotation.json);
                var parsedModules = Json_decode.array(ofJson, json);
                return Belt_Array.concat(arr, parsedModules);
              }));
}

exports.Module = Module;
exports.ofJson = ofJson$1;
exports.fromGlobalAnnotations = fromGlobalAnnotations;
/* partial_arg Not a pure module */
