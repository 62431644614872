// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RR$BsConsole = require("../RR.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var ctx = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = ctx.Provider;

var Jsc = {
  makeProps: makeProps,
  make: make
};

var component = RR$BsConsole.statelessComponent("RouterContext_Re");

function make$1(handleChangeUrl, handleReplaceUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(make, makeProps({
                  handleChangeUrl: handleChangeUrl,
                  handleReplaceUrl: handleReplaceUrl
                }, children, undefined), children);
}

var Re = {
  component: component,
  make: make$1
};

var Provider = {
  Jsc: Jsc,
  Re: Re
};

exports.ctx = ctx;
exports.Provider = Provider;
/* ctx Not a pure module */
