// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BugLink$BsConsole = require("../debugger2/BugLink.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Close = require("@material-ui/icons/Close");
var CardContent = require("@material-ui/core/CardContent");

function TeamsCreateTeamError(Props) {
  var _open = Props.open;
  var onCloseOpt = Props.onClose;
  var modalWidth = Props.modalWidth;
  Props.refetchTeamMembers;
  var handleChangeUrl = Props.handleChangeUrl;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return React.createElement(Dialog.default, {
              open: _open,
              onClose: onClose,
              children: React.createElement(CardContent.default, {
                    children: null
                  }, React.createElement("div", {
                        className: Css.merge(/* :: */[
                              "smaller-icon",
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.display(/* flex */-1010954439),
                                        /* :: */[
                                          Css.justifyContent(/* spaceBetween */516682146),
                                          /* :: */[
                                            Css.alignItems(/* center */98248149),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle2 */7,
                            children: I18N$BsConsole.show(undefined, "Error")
                          }), React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: "Close",
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              }),
                            children: React.createElement(Close.default, { })
                          })), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: Css.style(/* :: */[
                              Belt_Option.isSome(modalWidth) ? Css.width(Css.px(Belt_Option.getExn(modalWidth))) : Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Overline */12,
                                      children: I18N$BsConsole.show(undefined, "Unable to create team. Please note teams may only be created by admins and are fully configurable in ")
                                    }), React.createElement(BugLink$BsConsole.make, {
                                      route: /* SettingsTeams */7,
                                      handleChangeUrl: handleChangeUrl,
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Overline */12,
                                            color: Colors$BsConsole.blue,
                                            children: I18N$BsConsole.show(undefined, "organization settings")
                                          })
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Overline */12,
                                      children: I18N$BsConsole.show(undefined, ". If you need assistance, please reach out to support.")
                                    })), React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.display(/* flex */-1010954439),
                                          /* :: */[
                                            Css.justifyContent(/* flexEnd */924268066),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, React.createElement(Button.default, {
                                      variant: "contained",
                                      color: "primary",
                                      onClick: (function (param) {
                                          return Curry._1(onClose, undefined);
                                        }),
                                      children: I18N$BsConsole.show(undefined, "Back to select team")
                                    })))
                      }))
            });
}

var make = TeamsCreateTeamError;

exports.make = make;
/* Css Not a pure module */
