// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Task$BsConsole = require("./task.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ApiBillingSubscription$BsConsole = require("./ApiBillingSubscription.js");

var component = ReasonReact.reducerComponent("BillingSubscriptionFetch-BsConsole");

function make(handleTask, token, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return Curry._1(children, self.state.remote);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              remote: /* Failure */Block.__(1, ["Failed to fetch"])
                            }]);
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                return Curry._2(handleTask, (function (j) {
                                              var json = TaskCallback$BsConsole.getJson(j);
                                              var sub = ApiBillingSubscription$BsConsole.ofJson(json);
                                              if (sub !== undefined) {
                                                return Curry._1(self.send, /* Success */[sub]);
                                              } else {
                                                return Curry._1(self.send, /* Failure */1);
                                              }
                                            }), Task$BsConsole.makeTask(/* BillingSubscription */Block.__(22, [token])));
                              })
                          ]);
                }
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
