// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Col2$BsConsole = require("./components/Col2.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Launch = require("@material-ui/icons/Launch");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");
var WorkflowVersion$BsConsole = require("./workflow/WorkflowVersion.js");
var ExperimentalLinks$BsConsole = require("./ExperimentalLinks.js");
var BillingLimitsFetch$BsConsole = require("./BillingLimitsFetch.js");

var container = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
        /* :: */[
          Css.borderRadius(Css.px(4)),
          /* :: */[
            Css.width(Css.px(250)),
            /* :: */[
              Css.padding(Css.rem(1)),
              /* :: */[
                Css.marginRight(Css.rem(1)),
                /* :: */[
                  Css.alignSelf(/* flexStart */662439529),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var section = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* :: */[
        Css.selector("&:last-of-type", /* :: */[
              Css.marginBottom(/* zero */-789508312),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var activeRoute = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.padding2(Css.px(4), /* zero */-789508312),
        /* [] */0
      ]
    ]);

var navItem = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey3)),
            /* [] */0
          ]),
      /* :: */[
        "nav-item",
        /* [] */0
      ]
    ]);

var navItemLink = Css.style(/* :: */[
      Css.padding2(Css.px(4), /* zero */-789508312),
      /* :: */[
        Css.hover(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.accent)),
              /* :: */[
                Css.selector(".nav-item", /* :: */[
                      Css.unsafe("color", "inherit"),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  container: container,
  section: section,
  activeRoute: activeRoute,
  navItem: navItem,
  navItemLink: navItemLink
};

function OrgSettingsNavigation$Container(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: container
            }, children);
}

var Container = {
  make: OrgSettingsNavigation$Container
};

function OrgSettingsNavigation$Section(Props) {
  var children = Props.children;
  return React.createElement(Col2$BsConsole.make, {
              className: section,
              children: children
            });
}

var Section = {
  make: OrgSettingsNavigation$Section
};

function isRouteActive(route, currentRoute) {
  return Route$BsConsole.toString(route) === Route$BsConsole.toString(currentRoute);
}

function OrgSettingsNavigation$NavItem(Props) {
  var route = Props.route;
  var currentRoute = Props.currentRoute;
  var handleChangeUrl = Props.handleChangeUrl;
  var children = Props.children;
  if (isRouteActive(route, currentRoute)) {
    return React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: activeRoute,
                children: children
              });
  } else {
    return React.createElement(Link$BsConsole.Jsx3.make, {
                route: route,
                handleChangeUrl: handleChangeUrl,
                className: navItemLink,
                style: { },
                children: React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: navItem,
                      children: children
                    })
              });
  }
}

var NavItem = {
  make: OrgSettingsNavigation$NavItem
};

function OrgSettingsNavigation$OutsideLink(Props) {
  var href = Props.href;
  var children = Props.children;
  return React.createElement("a", {
              className: navItemLink,
              href: href,
              rel: "noopener noreferrer",
              target: "_blank"
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.merge(/* :: */[
                        navItem,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.display(/* inlineFlex */53323314),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, children, React.createElement(Launch.default, {
                      color: "inherit",
                      fontSize: "inherit"
                    })));
}

var OutsideLink = {
  make: OrgSettingsNavigation$OutsideLink
};

function OrgSettingsNavigation(Props) {
  var route = Props.route;
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var token = Props.token;
  var match = BillingLimitsFetch$BsConsole.use(config.universe.name, token);
  var limitsRemote = match[0];
  var tmp = true;
  if (!Gate$BsConsole.isNotPermitted(undefined, /* SettingsWfConnectionManagement */Block.__(14, [config.user]))) {
    var match$1 = WorkflowVersion$BsConsole.current(config);
    tmp = typeof match$1 === "number" ? true : false;
  }
  var tmp$1;
  if (typeof limitsRemote === "number" || limitsRemote.tag) {
    tmp$1 = null;
  } else {
    var limits = limitsRemote[0];
    tmp$1 = limits !== undefined && !limits.metadata.partnerManaged ? React.createElement(HideFeatureGate$BsConsole.make, {
            shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsSubscriptions */Block.__(8, [config.user])),
            children: React.createElement(OrgSettingsNavigation$NavItem, {
                  route: /* SettingsSubscriptions */20,
                  currentRoute: route,
                  handleChangeUrl: handleChangeUrl,
                  children: I18N$BsConsole.show(undefined, "Subscriptions")
                })
          }) : null;
  }
  var links = ExperimentalLinks$BsConsole.getLinks(config);
  return React.createElement(OrgSettingsNavigation$Container, {
              children: React.createElement(OrgSettingsNavigation$Section, {
                    children: null
                  }, React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsMe */4),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsMe */5,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "My account")
                            })
                      }), React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsUsers */Block.__(0, [config.user])),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsUsers */6,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Users")
                            })
                      }), React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(config.user.email, /* SettingsTeams */1),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsTeams */7,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Teams")
                            })
                      }), React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjects */2),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsProjects */8,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Projects")
                            })
                      }), React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: tmp,
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsWfConnectionManagement */Block.__(6, [{
                                    error: undefined
                                  }]),
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Workflow connections")
                            })
                      }), React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsNetworking */Block.__(11, [config.user])),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsNetworking */13,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Server settings")
                            })
                      }), tmp$1, React.createElement(HideFeatureGate$BsConsole.make, {
                        shouldHide: Gate$BsConsole.isNotPermitted(undefined, /* SettingsSamlConfig */Block.__(12, [config.user])),
                        children: React.createElement(OrgSettingsNavigation$NavItem, {
                              route: /* SettingsSamlConfig */21,
                              currentRoute: route,
                              handleChangeUrl: handleChangeUrl,
                              children: I18N$BsConsole.show(undefined, "Single sign-on")
                            })
                      }), links !== undefined ? Belt_List.toArray(Belt_List.map(links, (function (link) {
                                return React.createElement(OrgSettingsNavigation$OutsideLink, {
                                            href: link.endpoint,
                                            children: I18N$BsConsole.show(undefined, link.title)
                                          });
                              }))) : null)
            });
}

function make(route, config, handleChangeUrl, token, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(OrgSettingsNavigation, {
              route: route,
              config: config,
              handleChangeUrl: handleChangeUrl,
              token: token
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = OrgSettingsNavigation;

exports.Style = Style;
exports.Container = Container;
exports.Section = Section;
exports.isRouteActive = isRouteActive;
exports.NavItem = NavItem;
exports.OutsideLink = OutsideLink;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
