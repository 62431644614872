// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var component = ReasonReact.statelessComponent("Renderer_Number-BsConsole");

function formatInt64($$int) {
  return Numeral$BsConsole.format("0,0", Int64.to_string($$int));
}

function formatInt($$int) {
  return Numeral$BsConsole.format("0,0", String($$int));
}

function formatFloat(f) {
  return Numeral$BsConsole.format("0,0[.][0000]", Pervasives.string_of_float(f));
}

function standardizeFormat(value) {
  var regex = new RegExp("(.+)\\.$");
  var result = regex.exec(value);
  var value$1 = Numeral$BsConsole.format("0,0.[000]", result !== null ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(result, 1), (function (prim) {
                    if (prim == null) {
                      return ;
                    } else {
                      return Caml_option.some(prim);
                    }
                  })), "") : value);
  if (value$1.length > 6) {
    return Numeral$BsConsole.format("0,0.[00]a", value$1);
  } else {
    return value$1;
  }
}

function make(attribute, value, handleAddFilters, handleChangeUrl, $staropt$star, $staropt$star$1, _children) {
  $staropt$star !== undefined;
  if ($staropt$star$1 === undefined) {
    false;
  }
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var formattedNumber = Numeral$BsConsole.format("0,0[.][0000]", Pervasives.string_of_float(value[1]));
              var rawNumber = value[1].toString();
              return ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                              /* tuple */[
                                attribute,
                                /* AtLeast */Block.__(8, [/* `String */[
                                      -976970511,
                                      rawNumber
                                    ]])
                              ],
                              /* :: */[
                                /* tuple */[
                                  attribute,
                                  /* AtMost */Block.__(9, [/* `String */[
                                        -976970511,
                                        rawNumber
                                      ]])
                                ],
                                /* [] */0
                              ]
                            ], undefined, rawNumber, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                return React.createElement("span", {
                                            ref: refHandler,
                                            className: Css.style(/* :: */[
                                                  Css.textAlign(/* right */-379319332),
                                                  /* :: */[
                                                    Css.fontFamily("inconsolata"),
                                                    /* :: */[
                                                      Css.borderRadius(Css.px(2)),
                                                      /* :: */[
                                                        Css.padding(Css.px(3)),
                                                        /* :: */[
                                                          Css.hover(/* :: */[
                                                                Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                                                /* :: */[
                                                                  Css.cursor(/* pointer */-786317123),
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]),
                                            role: "button",
                                            alt: "click to drill down into value",
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, I18N$BsConsole.showSkip(formattedNumber));
                              }), undefined, undefined, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.formatInt64 = formatInt64;
exports.formatInt = formatInt;
exports.formatFloat = formatFloat;
exports.standardizeFormat = standardizeFormat;
exports.make = make;
/* component Not a pure module */
