// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");
var AttributeTypeahead$BsConsole = require("./typeahead/AttributeTypeahead.js");

function setRef(tokenType, theRef, param) {
  if (tokenType) {
    return ;
  } else {
    param.state.attributeInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  }
}

var component = ReasonReact.reducerComponent("AddSelect-BsConsole__Inputs");

function make(attributes, onClose, onChange, attributeOpt, _children) {
  var attribute = attributeOpt !== undefined ? Caml_option.valFromOption(attributeOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, 0, [ReasonReact.element(undefined, Curry._1(self.handle, (function (param, param$1) {
                                          return setRef(/* Attribute */0, param, param$1);
                                        })), Curry.app(AttributeTypeahead$BsConsole.make, [
                                        undefined,
                                        undefined,
                                        Belt_List.keep(attributes, Attribute_Helpers$BsConsole.filterFirstSeen),
                                        (function (att) {
                                            return Curry._1(self.send, /* SetAttribute */[att]);
                                          }),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        attribute !== undefined ? attribute.name : "",
                                        undefined,
                                        undefined,
                                        []
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      attribute: attribute,
                      attributeInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              var attribute = action[0];
              return /* SideEffects */Block.__(1, [(function (_self) {
                            Curry._1(onChange, attribute);
                            return Curry._1(onClose, undefined);
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Inputs = {
  setRef: setRef,
  component: component,
  make: make
};

function setRef$1(theRef, param) {
  param.state.addButton.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component$1 = ReasonReact.reducerComponent("Select");

function make$1(select, onChange, describeRemote, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var focus = function (inputRef) {
                var r = inputRef.contents;
                if (r === undefined) {
                  return ;
                }
                var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                domElement.focus();
                
              };
              var attributes;
              attributes = typeof describeRemote === "number" || describeRemote.tag ? /* [] */0 : describeRemote[0];
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Add attribute column"), undefined, undefined, undefined, undefined, undefined, [ReactDOMRe.createElementVariadic("div", {
                                                      ref: refHandler,
                                                      className: Css.style(/* :: */[
                                                            Css.display(/* inlineBlock */-147785676),
                                                            /* [] */0
                                                          ]),
                                                      onFocus: onFocus,
                                                      onClick: onClick
                                                    }, children)]));
                              }), undefined, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Right */-57574468
                            }, {
                              vertical: /* Top */4202101,
                              horizontal: /* Right */-57574468
                            }, undefined, undefined, undefined, (function (onClose) {
                                return ReasonReact.element(undefined, undefined, make(attributes, (function (param) {
                                                  Curry._1(onClose, undefined);
                                                  setTimeout((function (param) {
                                                          return focus(self.state.addButton);
                                                        }), 300);
                                                  
                                                }), (function (a) {
                                                  Curry._1(onChange, Curry._2(Crdb$BsConsole.Select.add, a.name, select));
                                                  setTimeout((function (param) {
                                                          return focus(self.state.addButton);
                                                        }), 300);
                                                  
                                                }), undefined, []));
                              }), undefined, []));
            }),
          initialState: (function (param) {
              return {
                      addButton: {
                        contents: undefined
                      },
                      isOpen: false
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          addButton: state.addButton,
                          isOpen: !state.isOpen
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var make$2 = ReasonReactCompat.wrapReasonReactForReact(component$1, (function (reactProps) {
        return make$1(reactProps.select, reactProps.onChange, reactProps.describeRemote, Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(reactProps.children), [], (function (c) {
                          return [c];
                        })));
      }));

var Jsx3 = {
  make: make$2
};

exports.Inputs = Inputs;
exports.setRef = setRef$1;
exports.component = component$1;
exports.make = make$1;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
