// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function ofJson(json) {
  return {
          card: Json_decode.optional((function (param) {
                  return Json_decode.field("card", Json_decode.string, param);
                }), json),
          cardStatus: Json_decode.field("cardStatus", Json_decode.string, json)
        };
}

var Customer = {
  ofJson: ofJson
};

function ofJson$1(json) {
  return {
          amount: Json_decode.field("amount", Json_decode.$$int, json),
          description: Json_decode.field("description", Json_decode.string, json)
        };
}

var Item = {
  ofJson: ofJson$1
};

function ofJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          date: Json_decode.map((function (f) {
                  return new Date(f * 1000);
                }), (function (param) {
                  return Json_decode.field("date", Json_decode.$$float, param);
                }), json),
          total: Json_decode.field("total", Json_decode.$$int, json),
          items: Json_decode.field("items", (function (param) {
                  return Json_decode.array(ofJson$1, param);
                }), json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

var Invoice = {
  ofJson: ofJson$2
};

function _ofJson(json) {
  return {
          customer: Json_decode.field("customer", ofJson, json),
          invoices: Json_decode.field("invoices", (function (param) {
                  return Json_decode.array(ofJson$2, param);
                }), json)
        };
}

function ofJson$3(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("data", _ofJson, param);
              }), json);
}

exports.Customer = Customer;
exports.Item = Item;
exports.Invoice = Invoice;
exports._ofJson = _ofJson;
exports.ofJson = ofJson$3;
/* No side effect */
