// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Is_equal = require("date-fns/is_equal");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

function Make(G) {
  var default_001 = G.$$default;
  var $$default = /* Relative */Block.__(0, [
      /* Floating */0,
      default_001
    ]);
  var create = function (t$prime) {
    return t$prime;
  };
  var createCustom = function (range) {
    return /* Custom */Block.__(1, [range]);
  };
  var toRange = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Relative */0 :
      case /* RelativeFirstSeen */2 :
          break;
      case /* Custom */1 :
      case /* CustomFirstSeen */3 :
          return t$prime[0];
      
    }
    var n = t$prime[0];
    if (n) {
      return Curry._2(G.getRange, n[0], t$prime[1]);
    } else {
      return Curry._2(G.getRange, new Date(), t$prime[1]);
    }
  };
  var freeze = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Relative */0 :
          return /* Custom */Block.__(1, [toRange(t$prime)]);
      case /* RelativeFirstSeen */2 :
          return /* CustomFirstSeen */Block.__(3, [toRange(t$prime)]);
      case /* Custom */1 :
      case /* CustomFirstSeen */3 :
          return t$prime;
      
    }
  };
  var start = function (t$prime) {
    return toRange(t$prime)[0];
  };
  var finish = function (t$prime) {
    return toRange(t$prime)[1];
  };
  var toJson = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Relative */0 :
          var now = t$prime[0];
          if (now) {
            return [
                    "relative",
                    [
                      "fixed",
                      now[0].valueOf()
                    ],
                    Curry._1(G.granularityToString, t$prime[1])
                  ];
          } else {
            return [
                    "relative",
                    [
                      "floating",
                      Curry._1(G.granularityToString, t$prime[1])
                    ]
                  ];
          }
      case /* Custom */1 :
          return [
                  "custom",
                  [
                    start(t$prime).valueOf(),
                    finish(t$prime).valueOf()
                  ]
                ];
      case /* RelativeFirstSeen */2 :
          var now$1 = t$prime[0];
          if (now$1) {
            return [
                    "relative-first-seen",
                    [
                      "fixed",
                      now$1[0].valueOf()
                    ],
                    Curry._1(G.granularityToString, t$prime[1])
                  ];
          } else {
            return [
                    "relative-first-seen",
                    [
                      "floating",
                      Curry._1(G.granularityToString, t$prime[1])
                    ]
                  ];
          }
      case /* CustomFirstSeen */3 :
          return [
                  "custom-first-seen",
                  [
                    start(t$prime).valueOf(),
                    finish(t$prime).valueOf()
                  ]
                ];
      
    }
  };
  var decodeRelativeFixedExn = function (json) {
    var match = Json_decode.tuple3(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.string, Json_decode.$$float, param);
          }), Json_decode.string, json);
    var fixed = match[1];
    if (match[0] === "relative") {
      if (fixed[0] === "fixed") {
        return /* Relative */Block.__(0, [
                  /* Fixed */[new Date(fixed[1])],
                  Curry._1(G.stringToGranularity, match[2])
                ]);
      }
      throw [
            Json_decode.DecodeError,
            "Did not match relative fixed date aperture"
          ];
    }
    throw [
          Json_decode.DecodeError,
          "Did not match relative fixed date aperture"
        ];
  };
  var decodeRelativeFloatingExn = function (json) {
    var match = Json_decode.tuple2(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
          }), json);
    var match$1 = match[1];
    if (match[0] === "relative") {
      if (match$1[0] === "floating") {
        return /* Relative */Block.__(0, [
                  /* Floating */0,
                  Curry._1(G.stringToGranularity, match$1[1])
                ]);
      }
      throw [
            Json_decode.DecodeError,
            "Did not match relative fixed date aperture"
          ];
    }
    throw [
          Json_decode.DecodeError,
          "Did not match relative fixed date aperture"
        ];
  };
  var decodeCustomExn = function (json) {
    var match = Json_decode.tuple2(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.$$float, Json_decode.$$float, param);
          }), json);
    var match$1 = match[1];
    if (match[0] === "custom") {
      return /* Custom */Block.__(1, [/* tuple */[
                  new Date(match$1[0]),
                  new Date(match$1[1])
                ]]);
    }
    throw [
          Json_decode.DecodeError,
          "Did not match custom fixed date aperture"
        ];
  };
  var decodeRelativeFirstSeenFixedExn = function (json) {
    var match = Json_decode.tuple3(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.string, Json_decode.$$float, param);
          }), Json_decode.string, json);
    var fixed = match[1];
    if (match[0] === "relative-first-seen") {
      if (fixed[0] === "fixed") {
        return /* RelativeFirstSeen */Block.__(2, [
                  /* Fixed */[new Date(fixed[1])],
                  Curry._1(G.stringToGranularity, match[2])
                ]);
      }
      throw [
            Json_decode.DecodeError,
            "Did not match relative fixed date aperture"
          ];
    }
    throw [
          Json_decode.DecodeError,
          "Did not match relative fixed date aperture"
        ];
  };
  var decodeRelativeFirstSeenFloatingExn = function (json) {
    var match = Json_decode.tuple2(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
          }), json);
    var match$1 = match[1];
    if (match[0] === "relative-first-seen") {
      if (match$1[0] === "floating") {
        return /* RelativeFirstSeen */Block.__(2, [
                  /* Floating */0,
                  Curry._1(G.stringToGranularity, match$1[1])
                ]);
      }
      throw [
            Json_decode.DecodeError,
            "Did not match relative fixed date aperture"
          ];
    }
    throw [
          Json_decode.DecodeError,
          "Did not match relative fixed date aperture"
        ];
  };
  var decodeCustomFirstSeenExn = function (json) {
    var match = Json_decode.tuple2(Json_decode.string, (function (param) {
            return Json_decode.tuple2(Json_decode.$$float, Json_decode.$$float, param);
          }), json);
    var match$1 = match[1];
    if (match[0] === "custom-first-seen") {
      return /* CustomFirstSeen */Block.__(3, [/* tuple */[
                  new Date(match$1[0]),
                  new Date(match$1[1])
                ]]);
    }
    throw [
          Json_decode.DecodeError,
          "Did not match custom fixed date aperture"
        ];
  };
  var fromJson = function (json) {
    try {
      return Json_decode.oneOf(/* :: */[
                  decodeRelativeFixedExn,
                  /* :: */[
                    decodeRelativeFloatingExn,
                    /* :: */[
                      decodeCustomExn,
                      /* :: */[
                        decodeRelativeFirstSeenFixedExn,
                        /* :: */[
                          decodeRelativeFirstSeenFloatingExn,
                          /* :: */[
                            decodeCustomFirstSeenExn,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ], json);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg[0] === Json_decode.DecodeError) {
        throw [
              Json_decode.DecodeError,
              msg[1] + "time aperture"
            ];
      }
      throw msg;
    }
  };
  var getString = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Relative */0 :
          return Curry._1(G.granularityToString, t$prime[1]);
      case /* Custom */1 :
          return "custom";
      case /* RelativeFirstSeen */2 :
          return "first-seen-" + Curry._1(G.granularityToString, t$prime[1]);
      case /* CustomFirstSeen */3 :
          return "custom-first-seen";
      
    }
  };
  var getAttribute = function (t$prime) {
    switch (t$prime.tag | 0) {
      case /* Relative */0 :
      case /* Custom */1 :
          return "timestamp";
      case /* RelativeFirstSeen */2 :
      case /* CustomFirstSeen */3 :
          return "fingerprint;first_seen";
      
    }
  };
  var getStart = function (t$prime) {
    return toRange(t$prime)[0];
  };
  var getFinish = function (t$prime) {
    return toRange(t$prime)[1];
  };
  var eq = function (t$prime, t$prime$prime) {
    switch (t$prime.tag | 0) {
      case /* Custom */1 :
          if (t$prime$prime.tag !== /* Custom */1) {
            return Caml_obj.caml_equal(t$prime, t$prime$prime);
          }
          var match = t$prime$prime[0];
          var match$1 = t$prime[0];
          if (Is_equal(match$1[0], match[0])) {
            return Is_equal(match$1[1], match[1]);
          } else {
            return false;
          }
      case /* Relative */0 :
      case /* RelativeFirstSeen */2 :
          return Caml_obj.caml_equal(t$prime, t$prime$prime);
      case /* CustomFirstSeen */3 :
          if (t$prime$prime.tag !== /* CustomFirstSeen */3) {
            return Caml_obj.caml_equal(t$prime, t$prime$prime);
          }
          var match$2 = t$prime$prime[0];
          var match$3 = t$prime[0];
          if (Is_equal(match$3[0], match$2[0])) {
            return Is_equal(match$3[1], match$2[1]);
          } else {
            return false;
          }
      
    }
  };
  return {
          $$default: $$default,
          create: create,
          createCustom: createCustom,
          toRange: toRange,
          toJson: toJson,
          fromJson: fromJson,
          getString: getString,
          getAttribute: getAttribute,
          getStart: getStart,
          getFinish: getFinish,
          eq: eq,
          freeze: freeze
        };
}

exports.Make = Make;
/* date-fns/is_equal Not a pure module */
