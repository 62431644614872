// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Axis = require("@visx/axis");
var Grid = require("@visx/grid");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Curve = require("@visx/curve");
var Group = require("@visx/group");
var Scale = require("@visx/scale");
var Shape = require("@visx/shape");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Tooltip = require("@visx/tooltip");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("./I18N.js");
var Caml_splice_call = require("bs-platform/lib/js/caml_splice_call.js");
var Colors$BsConsole = require("./Colors.js");
var Interpolate$BsConsole = require("./Interpolate.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

function getXScale(range, xMax) {
  return Scale.scaleLinear().range([
                0,
                xMax
              ]).domain([
              range[0],
              range[1]
            ]);
}

function getYScale(yMax, getBinValue, data) {
  var values = Belt_Array.reduce(data, [], (function (acc, param) {
          return Belt_Array.concat(Belt_Array.map(param[1], getBinValue), acc);
        }));
  return Scale.scaleLinear().range([
                yMax,
                2
              ]).domain([
              Caml_splice_call.spliceApply(Math.min, [values]),
              Caml_splice_call.spliceApply(Math.max, [values])
            ]);
}

function getStrokeColor(colorRange, restColor, index) {
  var color = Belt_Option.getWithDefault(Belt_Array.get(colorRange, index), restColor);
  return "#" + color;
}

var defaultMargins = {
  top: 16,
  right: 16,
  bottom: 50,
  left: 60
};

var defaultTickFormat = Pervasives.string_of_float;

function getGroupClassName(groupedBinData) {
  var match = Belt_Array.get(groupedBinData, 0);
  if (match !== undefined) {
    return Css.style(/* :: */[
                Css.selector(".bin-boundary:nth-of-type(n) + .data-point:nth-of-type(" + (String(match[1].length) + "n)"), /* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                /* [] */0
              ]);
  } else {
    return "";
  }
}

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var boundingRect = Css.style(/* :: */[
      Css.selector("rect:hover + .data-points", /* :: */[
            Css.opacity(1.0),
            /* [] */0
          ]),
      /* [] */0
    ]);

var dataPointsGroup = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.opacity(0),
            /* [] */0
          ]),
      /* :: */[
        "data-points",
        /* [] */0
      ]
    ]);

var dataPoint = Css.style(/* :: */[
      Css.pointerEvents(/* none */-922086728),
      /* [] */0
    ]);

var linePath = Css.style(/* :: */[
      Css.pointerEvents(/* none */-922086728),
      /* [] */0
    ]);

var tooltipRange = Css.style(/* :: */[
      Css.marginBottom(Css.rem(0.5)),
      /* [] */0
    ]);

var tooltipData = Css.style(/* :: */[
      Css.selector(":not(:last-of-type)", /* :: */[
            Css.marginBottom(Css.px(6)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var tooltipDataName = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* :: */[
              Css.textOverflow(/* ellipsis */166888785),
              /* :: */[
                Css.maxWidth(Css.px(250)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var tooltipDataCount = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* [] */0
    ]);

var noDataContainer = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  getGroupClassName: getGroupClassName,
  container: container,
  boundingRect: boundingRect,
  dataPointsGroup: dataPointsGroup,
  dataPoint: dataPoint,
  linePath: linePath,
  tooltipRange: tooltipRange,
  tooltipData: tooltipData,
  tooltipDataName: tooltipDataName,
  tooltipDataCount: tooltipDataCount,
  noDataContainer: noDataContainer
};

var tooltipStyles = Object.assign(Tooltip.defaultStyles, {
      backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.grey35),
      opacity: "0.9",
      color: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
    });

function BinLineChart$DefaultNoDataLabel(Props) {
  return React.createElement("div", {
              className: noDataContainer
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  children: I18N$BsConsole.show(undefined, "no data")
                }));
}

var DefaultNoDataLabel = {
  make: BinLineChart$DefaultNoDataLabel
};

function BinLineChart(Props) {
  var height = Props.height;
  var width = Props.width;
  var data = Props.data;
  var getBinRange = Props.getBinRange;
  var getBinValue = Props.getBinValue;
  var tickFormatOpt = Props.tickFormat;
  var range = Props.range;
  var bins = Props.bins;
  var colorRange = Props.colorRange;
  var showGridRowsOpt = Props.showGridRows;
  var showGridColumnsOpt = Props.showGridColumns;
  var renderTooltipData = Props.renderTooltipData;
  var leftAxisLabel = Props.leftAxisLabel;
  var bottomAxisLabel = Props.bottomAxisLabel;
  var noDataLabelOpt = Props.noDataLabel;
  var tickFormat = tickFormatOpt !== undefined ? tickFormatOpt : defaultTickFormat;
  var showGridRows = showGridRowsOpt !== undefined ? showGridRowsOpt : false;
  var showGridColumns = showGridColumnsOpt !== undefined ? showGridColumnsOpt : false;
  var noDataLabel = noDataLabelOpt !== undefined ? noDataLabelOpt : (function (param) {
        return React.createElement(BinLineChart$DefaultNoDataLabel, { });
      });
  var match = React.useState((function () {
          return Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins, range[0], range[1]), []);
        }));
  var setBackingBins = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setTooltipData = match$1[1];
  var tooltipData = match$1[0];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match$2;
  if (colorRange !== undefined) {
    match$2 = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match$2 = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match$2 = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match$2[1];
  var colors = match$2[0];
  var xMax = React.useMemo((function () {
          return (width - defaultMargins.left | 0) - defaultMargins.right | 0;
        }), [width]);
  var yMax = React.useMemo((function () {
          return (height - defaultMargins.top | 0) - defaultMargins.bottom | 0;
        }), [height]);
  var xScale = React.useMemo((function () {
          return getXScale(range, xMax);
        }), /* tuple */[
        range,
        xMax
      ]);
  var yScale = React.useMemo((function () {
          return getYScale(yMax, getBinValue, data);
        }), /* tuple */[
        yMax,
        data
      ]);
  var getX = function (bin) {
    var match = Curry._1(getBinRange, bin);
    return (match[0] + match[1]) / 2;
  };
  var handleMouseMove = React.useCallback((function ($$event) {
          var el = $$event.currentTarget;
          var boundingRect = el.getBoundingClientRect();
          var left = $$event.clientX - boundingRect.left | 0;
          var top = $$event.clientY - boundingRect.top | 0;
          var x = left - defaultMargins.left | 0;
          if (!(x >= 0 && x <= xMax)) {
            return Curry._1(setTooltipData, (function (param) {
                          
                        }));
          }
          var data$1 = Belt_Array.reduceWithIndex(data, [], (function (acc, param, idx) {
                  var bin = Belt_Array.getBy(param[1], (function (bin) {
                          var match = Curry._1(getBinRange, bin);
                          if (x >= Curry._1(xScale, match[0])) {
                            return x <= Curry._1(xScale, match[1]);
                          } else {
                            return false;
                          }
                        }));
                  if (bin !== undefined) {
                    return Belt_Array.concat(acc, [/* tuple */[
                                  param[0],
                                  Caml_option.valFromOption(bin)
                                ]]);
                  } else {
                    return acc;
                  }
                }));
          if (data$1.length !== 0) {
            return Curry._1(setTooltipData, (function (param) {
                          return /* tuple */[
                                  left,
                                  top,
                                  data$1
                                ];
                        }));
          }
          
        }), /* tuple */[
        data,
        xMax
      ]);
  var handleMouseLeave = function (_event) {
    return Curry._1(setTooltipData, (function (param) {
                  
                }));
  };
  React.useEffect((function () {
          var nextBackingBins = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins, range[0], range[1]), []);
          Curry._1(setBackingBins, (function (param) {
                  return nextBackingBins;
                }));
          
        }), /* tuple */[
        range[0],
        range[1],
        bins
      ]);
  var tmp = {
    className: container
  };
  var tmp$1 = Belt_Option.isSome(renderTooltipData) ? handleMouseLeave : undefined;
  if (tmp$1 !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.isSome(renderTooltipData) ? handleMouseMove : undefined;
  if (tmp$2 !== undefined) {
    tmp.onMouseMove = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = {
    scale: yScale,
    numTicks: 4,
    hideAxisLine: true,
    hideTicks: true
  };
  if (leftAxisLabel !== undefined) {
    tmp$3.label = Caml_option.valFromOption(leftAxisLabel);
  }
  var tmp$4 = {
    scale: xScale,
    numTicks: 4,
    hideAxisLine: true,
    hideTicks: true,
    tickFormat: tickFormat,
    top: yMax
  };
  if (bottomAxisLabel !== undefined) {
    tmp$4.label = Caml_option.valFromOption(bottomAxisLabel);
  }
  return React.createElement("div", tmp, React.createElement("svg", {
                  height: String(height),
                  width: String(width)
                }, React.createElement("rect", {
                      height: String(height),
                      width: String(width),
                      fill: "transparent"
                    }), React.createElement(Group.Group, {
                      top: defaultMargins.top,
                      left: defaultMargins.left,
                      className: getGroupClassName(data),
                      children: null
                    }, showGridRows ? React.createElement(Grid.GridRows, {
                            scale: yScale,
                            numTicks: 4,
                            width: xMax,
                            height: yMax,
                            stroke: "#" + Colors$BsConsole.grey65
                          }) : null, showGridColumns ? React.createElement(Grid.GridColumns, {
                            scale: xScale,
                            numTicks: 4,
                            width: xMax,
                            height: yMax,
                            stroke: "#" + Colors$BsConsole.grey65
                          }) : null, React.createElement(Axis.AxisLeft, tmp$3), React.createElement(Axis.AxisBottom, tmp$4), Belt_Array.mapWithIndex(match[0], (function (i, param) {
                            var end_ = param[1];
                            var start = param[0];
                            return React.createElement("g", {
                                        key: String(i) + ("__" + (Pervasives.string_of_float(start) + ("-" + Pervasives.string_of_float(end_)))),
                                        className: boundingRect
                                      }, React.createElement("rect", {
                                            key: "bin-boundary__" + String(i),
                                            className: "bin-boundary",
                                            height: String(Caml_primitive.caml_int_max(yMax + 40 | 0, 0)),
                                            width: Pervasives.string_of_float(Caml_primitive.caml_float_max(Curry._1(xScale, end_) - Curry._1(xScale, start), 0)),
                                            fill: "transparent",
                                            x: Pervasives.string_of_float(Curry._1(xScale, start)),
                                            y: String(-defaultMargins.top | 0)
                                          }), React.createElement("g", {
                                            className: dataPointsGroup
                                          }, Belt_Array.mapWithIndex(data, (function (j, param) {
                                                  var bin = Belt_Array.get(param[1], i);
                                                  if (bin === undefined) {
                                                    return null;
                                                  }
                                                  var bin$1 = Caml_option.valFromOption(bin);
                                                  return React.createElement("circle", {
                                                              key: String(i) + ("-" + (String(j) + "__point")),
                                                              className: dataPoint,
                                                              title: param[0],
                                                              cx: Pervasives.string_of_float(Curry._1(xScale, getX(bin$1))),
                                                              cy: Pervasives.string_of_float(Curry._1(yScale, Curry._1(getBinValue, bin$1))),
                                                              fill: getStrokeColor(colors, fallbackColor, j),
                                                              r: "4",
                                                              stroke: getStrokeColor(colors, fallbackColor, j)
                                                            });
                                                }))));
                          })), Belt_Array.mapWithIndex(data, (function (idx, param) {
                            return React.createElement(Shape.LinePath, {
                                        data: param[1],
                                        x: (function (bin) {
                                            return Curry._1(xScale, getX(bin));
                                          }),
                                        y: (function (bin) {
                                            return Curry._1(yScale, Curry._1(getBinValue, bin));
                                          }),
                                        className: linePath,
                                        stroke: getStrokeColor(colors, fallbackColor, idx),
                                        strokeWidth: 2,
                                        curve: Curve.curveLinear,
                                        shapeRendering: "geometricPrecision",
                                        key: "line__" + (param[0] + ("-" + String(idx)))
                                      });
                          })))), tooltipData !== undefined && renderTooltipData !== undefined ? React.createElement(Tooltip.TooltipWithBounds, {
                    top: tooltipData[1],
                    left: tooltipData[0],
                    style: tooltipStyles,
                    children: Curry._1(renderTooltipData, tooltipData[2]),
                    key: Pervasives.string_of_float(Math.random())
                  }) : null, data.length !== 0 ? null : Curry._1(noDataLabel, undefined));
}

var make = BinLineChart;

exports.getXScale = getXScale;
exports.getYScale = getYScale;
exports.getStrokeColor = getStrokeColor;
exports.defaultMargins = defaultMargins;
exports.defaultTickFormat = defaultTickFormat;
exports.Style = Style;
exports.tooltipStyles = tooltipStyles;
exports.DefaultNoDataLabel = DefaultNoDataLabel;
exports.make = make;
/* container Not a pure module */
