// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Colors$BsConsole = require("./Colors.js");
var DrilldownMenu$BsConsole = require("./DrilldownMenu.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var ButtonBase = require("@material-ui/core/ButtonBase");

var menuList = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.focus(/* :: */[
              Css.unsafe("outline", "none"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* :: */[
        Css.unsafe("height", "unset"),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.0
                ]),
            /* :: */[
              Css.hover(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.white)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.focus(/* :: */[
                      Css.unsafe("outline", "none"),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.white)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  menuList: menuList,
  menuItem: menuItem
};

function Drilldown$FromContext(Props) {
  var textValue = Props.textValue;
  var filterOptionsOpt = Props.filterOptions;
  var outsideLinkOptionsOpt = Props.outsideLinkOptions;
  var classNameOpt = Props.className;
  var onOpenOpt = Props.onOpen;
  var onCloseOpt = Props.onClose;
  var children = Props.children;
  var filterOptions = filterOptionsOpt !== undefined ? filterOptionsOpt : [];
  var outsideLinkOptions = outsideLinkOptionsOpt !== undefined ? outsideLinkOptionsOpt : [];
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onOpen = onOpenOpt !== undefined ? onOpenOpt : (function (param) {
        
      });
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var drilldownDispatch = DrilldownContext$BsConsole.useDrilldownDispatch(undefined);
  return React.createElement(ButtonBase.default, {
              classes: {
                root: className
              },
              onClick: (function ($$event) {
                  var el = $$event.currentTarget;
                  if (!(el == null)) {
                    if (drilldownDispatch !== undefined) {
                      Curry._1(onOpen, undefined);
                      return Curry._1(drilldownDispatch, /* SetDrilldown */[/* tuple */[
                                    Caml_option.some(el),
                                    textValue,
                                    filterOptions,
                                    outsideLinkOptions,
                                    onClose
                                  ]]);
                    } else {
                      console.log("DrilldownContext's provider is needed as a parent for this component to function properly.");
                      return ;
                    }
                  }
                  
                }),
              disableRipple: true,
              children: children
            });
}

var FromContext = {
  make: Drilldown$FromContext
};

function Drilldown(Props) {
  var textValue = Props.textValue;
  var filterOptionsOpt = Props.filterOptions;
  var classNameOpt = Props.className;
  var onOpenOpt = Props.onOpen;
  Props.onClose;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var children = Props.children;
  var filterOptions = filterOptionsOpt !== undefined ? filterOptionsOpt : [];
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onOpen = onOpenOpt !== undefined ? onOpenOpt : (function (param) {
        
      });
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  if (handleAddFilters !== undefined && handleChangeUrl !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(ButtonBase.default, {
                    classes: {
                      root: className
                    },
                    onClick: (function ($$event) {
                        var el = $$event.currentTarget;
                        if (!(el == null)) {
                          Curry._1(onOpen, undefined);
                          return Curry._1(setAnchorEl, (function (param) {
                                        return Caml_option.some(el);
                                      }));
                        }
                        
                      }),
                    disableRipple: true,
                    children: children
                  }), React.createElement(DrilldownMenu$BsConsole.make, {
                    textValue: textValue,
                    anchorEl: match[0],
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    filterOptions: filterOptions,
                    handleChangeUrl: handleChangeUrl,
                    handleAddFilters: handleAddFilters
                  }));
  } else {
    return children;
  }
}

var make = Drilldown;

exports.Style = Style;
exports.FromContext = FromContext;
exports.make = make;
/* menuList Not a pure module */
