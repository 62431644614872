// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");

var jsMapperConstantArray = [
  /* tuple */[
    -791844958,
    "primary"
  ],
  /* tuple */[
    -384499551,
    "default"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    67972948,
    "secondary"
  ],
  /* tuple */[
    106380200,
    "error"
  ]
];

function colorToJs(param) {
  return Js_mapperRt.binarySearch(5, param, jsMapperConstantArray);
}

function colorFromJs(param) {
  return Js_mapperRt.revSearch(5, jsMapperConstantArray, param);
}

var jsMapperConstantArray$1 = [
  /* tuple */[
    48800667,
    "large"
  ],
  /* tuple */[
    311976103,
    "small"
  ],
  /* tuple */[
    861718677,
    "medium"
  ]
];

function sizeToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$1);
}

function sizeFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$1, param);
}

var jsMapperConstantArray$2 = [
  /* tuple */[
    -925487250,
    "extendedFab"
  ],
  /* tuple */[
    -515484397,
    "contained"
  ],
  /* tuple */[
    -387916264,
    "raised"
  ],
  /* tuple */[
    -28821822,
    "outlined"
  ],
  /* tuple */[
    3502759,
    "fab"
  ],
  /* tuple */[
    781662169,
    "flat"
  ],
  /* tuple */[
    936573133,
    "text"
  ]
];

function buttonVariantToJs(param) {
  return Js_mapperRt.binarySearch(7, param, jsMapperConstantArray$2);
}

function buttonVariantFromJs(param) {
  return Js_mapperRt.revSearch(7, jsMapperConstantArray$2, param);
}

var jsMapperConstantArray$3 = [
  /* tuple */[
    -700823845,
    "indeterminate"
  ],
  /* tuple */[
    -250086680,
    "query"
  ],
  /* tuple */[
    86585632,
    "buffer"
  ],
  /* tuple */[
    373815968,
    "determinate"
  ]
];

function linearProgressVariantToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray$3);
}

function linearProgressVariantFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray$3, param);
}

var jsMapperConstantArray$4 = [
  /* tuple */[
    -1003883683,
    "standard"
  ],
  /* tuple */[
    -28821822,
    "outlined"
  ],
  /* tuple */[
    969114050,
    "filled"
  ]
];

function selectVariantToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$4);
}

function selectVariantFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$4, param);
}

var jsMapperConstantArray$5 = [
  /* tuple */[
    -156865929,
    "persistent"
  ],
  /* tuple */[
    -103274127,
    "temporary"
  ],
  /* tuple */[
    950914574,
    "permanent"
  ]
];

function drawerVariantToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$5);
}

function drawerVariantFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$5, param);
}

var jsMapperConstantArray$6 = [
  /* tuple */[
    -1031209551,
    "top-end"
  ],
  /* tuple */[
    -929427528,
    "top-start"
  ],
  /* tuple */[
    -524285320,
    "right-end"
  ],
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    4202101,
    "top"
  ],
  /* tuple */[
    72185578,
    "left-start"
  ],
  /* tuple */[
    437082891,
    "bottom"
  ],
  /* tuple */[
    529522894,
    "bottom-start"
  ],
  /* tuple */[
    742595647,
    "right-start"
  ],
  /* tuple */[
    801062215,
    "bottom-end"
  ],
  /* tuple */[
    847852583,
    "left"
  ],
  /* tuple */[
    886297699,
    "left-end"
  ]
];

function tooltipPlacementToJs(param) {
  return Js_mapperRt.binarySearch(12, param, jsMapperConstantArray$6);
}

function tooltipPlacementFromJs(param) {
  return Js_mapperRt.revSearch(12, jsMapperConstantArray$6, param);
}

var jsMapperConstantArray$7 = [
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    847852583,
    "left"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function popoverHorizontalOriginToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$7);
}

function popoverHorizontalOriginFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$7, param);
}

var jsMapperConstantArray$8 = [
  /* tuple */[
    -1003883683,
    "standard"
  ],
  /* tuple */[
    -28821822,
    "outlined"
  ],
  /* tuple */[
    969114050,
    "filled"
  ]
];

function textFieldVariantToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$8);
}

function textFieldVariantFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$8, param);
}

var jsMapperConstantArray$9 = [
  /* tuple */[
    4202101,
    "top"
  ],
  /* tuple */[
    437082891,
    "bottom"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function popoverVerticalOriginToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$9);
}

function popoverVerticalOriginFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$9, param);
}

var jsMapperConstantArray$10 = [
  /* tuple */[
    260471020,
    "relative"
  ],
  /* tuple */[
    332360020,
    "fixed"
  ],
  /* tuple */[
    491227799,
    "absolute"
  ],
  /* tuple */[
    982536398,
    "static"
  ],
  /* tuple */[
    1070408009,
    "sticky"
  ]
];

function appBarPositionToJs(param) {
  return Js_mapperRt.binarySearch(5, param, jsMapperConstantArray$10);
}

function appBarPositionFromJs(param) {
  return Js_mapperRt.revSearch(5, jsMapperConstantArray$10, param);
}

var jsMapperConstantArray$11 = [
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    4202101,
    "top"
  ],
  /* tuple */[
    437082891,
    "bottom"
  ],
  /* tuple */[
    847852583,
    "left"
  ]
];

function drawerAnchorToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray$11);
}

function drawerAnchorFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray$11, param);
}

var jsMapperConstantArray$12 = [
  /* tuple */[
    -1012636187,
    "stretch"
  ],
  /* tuple */[
    -403022699,
    "flex-end"
  ],
  /* tuple */[
    199819814,
    "space-around"
  ],
  /* tuple */[
    877391772,
    "flex-start"
  ],
  /* tuple */[
    959915471,
    "space-between"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function alignContentToJs(param) {
  return Js_mapperRt.binarySearch(6, param, jsMapperConstantArray$12);
}

function alignContentFromJs(param) {
  return Js_mapperRt.revSearch(6, jsMapperConstantArray$12, param);
}

var jsMapperConstantArray$13 = [
  /* tuple */[
    -1012636187,
    "stretch"
  ],
  /* tuple */[
    -403022699,
    "flex-end"
  ],
  /* tuple */[
    -354838363,
    "baseline"
  ],
  /* tuple */[
    877391772,
    "flex-start"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function alignItemsToJs(param) {
  return Js_mapperRt.binarySearch(5, param, jsMapperConstantArray$13);
}

function alignItemsFromJs(param) {
  return Js_mapperRt.revSearch(5, jsMapperConstantArray$13, param);
}

var jsMapperConstantArray$14 = [
  /* tuple */[
    -674898307,
    "row-reverse"
  ],
  /* tuple */[
    -105749639,
    "column-reverse"
  ],
  /* tuple */[
    -81804554,
    "column"
  ],
  /* tuple */[
    4102650,
    "row"
  ]
];

function directionToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray$14);
}

function directionFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray$14, param);
}

var jsMapperConstantArray$15 = [
  /* tuple */[
    -403022699,
    "flex-end"
  ],
  /* tuple */[
    -330345984,
    "space-evenly"
  ],
  /* tuple */[
    199819814,
    "space-around"
  ],
  /* tuple */[
    877391772,
    "flex-start"
  ],
  /* tuple */[
    959915471,
    "space-between"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function justifyToJs(param) {
  return Js_mapperRt.binarySearch(6, param, jsMapperConstantArray$15);
}

function justifyFromJs(param) {
  return Js_mapperRt.revSearch(6, jsMapperConstantArray$15, param);
}

var jsMapperConstantArray$16 = [
  /* tuple */[
    -397426005,
    "nowrap"
  ],
  /* tuple */[
    49617517,
    "wrap-reverse"
  ],
  /* tuple */[
    970483178,
    "wrap"
  ]
];

function wrapToJs(param) {
  return Js_mapperRt.binarySearch(3, param, jsMapperConstantArray$16);
}

function wrapFromJs(param) {
  return Js_mapperRt.revSearch(3, jsMapperConstantArray$16, param);
}

function lgToJs(param) {
  return param + 1 | 0;
}

function lgFromJs(param) {
  if (param <= 12 && 1 <= param) {
    return param - 1 | 0;
  }
  
}

function mdToJs(param) {
  return param + 1 | 0;
}

function mdFromJs(param) {
  if (param <= 12 && 1 <= param) {
    return param - 1 | 0;
  }
  
}

function smToJs(param) {
  return param + 1 | 0;
}

function smFromJs(param) {
  if (param <= 12 && 1 <= param) {
    return param - 1 | 0;
  }
  
}

var jsMapperConstantArray$17 = [
  0,
  8,
  16,
  24,
  32,
  40
];

function spacingToJs(param) {
  return Js_mapperRt.toInt(param, jsMapperConstantArray$17);
}

function spacingFromJs(param) {
  return Js_mapperRt.fromInt(6, jsMapperConstantArray$17, param);
}

function xlToJs(param) {
  return param + 1 | 0;
}

function xlFromJs(param) {
  if (param <= 12 && 1 <= param) {
    return param - 1 | 0;
  }
  
}

function xsToJs(param) {
  return param + 1 | 0;
}

function xsFromJs(param) {
  if (param <= 12 && 1 <= param) {
    return param - 1 | 0;
  }
  
}

exports.colorToJs = colorToJs;
exports.colorFromJs = colorFromJs;
exports.sizeToJs = sizeToJs;
exports.sizeFromJs = sizeFromJs;
exports.buttonVariantToJs = buttonVariantToJs;
exports.buttonVariantFromJs = buttonVariantFromJs;
exports.linearProgressVariantToJs = linearProgressVariantToJs;
exports.linearProgressVariantFromJs = linearProgressVariantFromJs;
exports.selectVariantToJs = selectVariantToJs;
exports.selectVariantFromJs = selectVariantFromJs;
exports.drawerVariantToJs = drawerVariantToJs;
exports.drawerVariantFromJs = drawerVariantFromJs;
exports.tooltipPlacementToJs = tooltipPlacementToJs;
exports.tooltipPlacementFromJs = tooltipPlacementFromJs;
exports.popoverHorizontalOriginToJs = popoverHorizontalOriginToJs;
exports.popoverHorizontalOriginFromJs = popoverHorizontalOriginFromJs;
exports.textFieldVariantToJs = textFieldVariantToJs;
exports.textFieldVariantFromJs = textFieldVariantFromJs;
exports.popoverVerticalOriginToJs = popoverVerticalOriginToJs;
exports.popoverVerticalOriginFromJs = popoverVerticalOriginFromJs;
exports.appBarPositionToJs = appBarPositionToJs;
exports.appBarPositionFromJs = appBarPositionFromJs;
exports.drawerAnchorToJs = drawerAnchorToJs;
exports.drawerAnchorFromJs = drawerAnchorFromJs;
exports.alignContentToJs = alignContentToJs;
exports.alignContentFromJs = alignContentFromJs;
exports.alignItemsToJs = alignItemsToJs;
exports.alignItemsFromJs = alignItemsFromJs;
exports.directionToJs = directionToJs;
exports.directionFromJs = directionFromJs;
exports.justifyToJs = justifyToJs;
exports.justifyFromJs = justifyFromJs;
exports.wrapToJs = wrapToJs;
exports.wrapFromJs = wrapFromJs;
exports.lgToJs = lgToJs;
exports.lgFromJs = lgFromJs;
exports.mdToJs = mdToJs;
exports.mdFromJs = mdFromJs;
exports.smToJs = smToJs;
exports.smFromJs = smFromJs;
exports.spacingToJs = spacingToJs;
exports.spacingFromJs = spacingFromJs;
exports.xlToJs = xlToJs;
exports.xlFromJs = xlFromJs;
exports.xsToJs = xsToJs;
exports.xsFromJs = xsFromJs;
/* No side effect */
