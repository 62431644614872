// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var BpgUtils$BsConsole = require("./BpgUtils.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

var prefix = "configuration/";

var projectEntity = prefix + BacktraceParserGenerator$BsConsole.Project.table;

var watcherNewEntity = prefix + BacktraceParserGenerator$BsConsole.WatcherNew.table;

var tokenEntity = prefix + BacktraceParserGenerator$BsConsole.Token.table;

var apiTokenEntity = prefix + BacktraceParserGenerator$BsConsole.ApiToken.table;

var deduplicationEntity = prefix + BacktraceParserGenerator$BsConsole.Deduplication.table;

var reportEntity = prefix + BacktraceParserGenerator$BsConsole.Report.table;

var resourceOverrideEntity = prefix + BacktraceParserGenerator$BsConsole.ResourceOverride.table;

var symsrvEntity = prefix + BacktraceParserGenerator$BsConsole.Symsrv.table;

var projectMemberUserEntity = prefix + BacktraceParserGenerator$BsConsole.ProjectMemberUser.table;

var teamMemberEntity = prefix + BacktraceParserGenerator$BsConsole.TeamMember.table;

var userFrontendSettingsEntity = prefix + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table;

var userEntity = prefix + BacktraceParserGenerator$BsConsole.Users.table;

function getOwnedItemsTask(param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "actions",
                        Json_encode.list((function (ele) {
                                return Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "type",
                                              ele
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "action",
                                                "get"
                                              ],
                                              /* [] */0
                                            ]
                                          ]);
                              }), /* :: */[
                              projectEntity,
                              /* :: */[
                                watcherNewEntity,
                                /* :: */[
                                  tokenEntity,
                                  /* :: */[
                                    apiTokenEntity,
                                    /* :: */[
                                      deduplicationEntity,
                                      /* :: */[
                                        reportEntity,
                                        /* :: */[
                                          resourceOverrideEntity,
                                          /* :: */[
                                            symsrvEntity,
                                            /* :: */[
                                              projectMemberUserEntity,
                                              /* :: */[
                                                teamMemberEntity,
                                                /* :: */[
                                                  userFrontendSettingsEntity,
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      ],
                      /* [] */0
                    ])]), undefined, (function (param) {
                try {
                  var responseDict = BpgUtils$BsConsole.$$Response.toJsDict(param.json);
                  var projects = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, projectEntity, BacktraceParserGenerator$BsConsole.Project.of_json);
                  var watchersNew = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, watcherNewEntity, BacktraceParserGenerator$BsConsole.WatcherNew.of_json);
                  var tokens = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, tokenEntity, BacktraceParserGenerator$BsConsole.Token.of_json);
                  var apiTokens = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, apiTokenEntity, BacktraceParserGenerator$BsConsole.ApiToken.of_json);
                  var deduplicationRules = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, deduplicationEntity, BacktraceParserGenerator$BsConsole.Deduplication.of_json);
                  var reports = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, reportEntity, BacktraceParserGenerator$BsConsole.Report.of_json);
                  var resourceOverrides = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, resourceOverrideEntity, BacktraceParserGenerator$BsConsole.ResourceOverride.of_json);
                  var symbolServers = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, symsrvEntity, BacktraceParserGenerator$BsConsole.Symsrv.of_json);
                  var projectMemberUsers = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, projectMemberUserEntity, BacktraceParserGenerator$BsConsole.ProjectMemberUser.of_json);
                  var teamMembers = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, teamMemberEntity, BacktraceParserGenerator$BsConsole.TeamMember.of_json);
                  var userFrontendSettings = BpgUtils$BsConsole.$$Response.getBPGObjectFromFromDict(responseDict, userFrontendSettingsEntity, BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json);
                  return /* Ok */Block.__(0, [{
                              projects: projects,
                              watchersNew: watchersNew,
                              tokens: tokens,
                              apiTokens: apiTokens,
                              deduplicationRules: deduplicationRules,
                              reports: reports,
                              resourceOverrides: resourceOverrides,
                              symbolServers: symbolServers,
                              projectMemberUsers: projectMemberUsers,
                              teamMembers: teamMembers,
                              userFrontendSettings: userFrontendSettings
                            }]);
                }
                catch (_err){
                  return /* Error */Block.__(1, [[
                              BpgTask$BsConsole.BpgError,
                              BpgTask$BsConsole.$$Error.ofInt(1),
                              "BPG decoding error"
                            ]]);
                }
              }), undefined, undefined);
}

function getUserOwnedProjects(uid, projects) {
  return Belt_Array.keep(projects, (function (project) {
                return project.owner === uid;
              }));
}

function getUserOwnedWatcherNew(uid, watchersNew) {
  return Belt_Array.keep(watchersNew, (function (watcher) {
                return watcher.owner === uid;
              }));
}

function doesUserOwnAToken(uid, tokens) {
  return Belt_Option.isSome(Belt_Array.getBy(tokens, (function (token) {
                    return token.owner === uid;
                  })));
}

function getUserOwnedTokens(uid, tokens) {
  return Belt_Array.keep(tokens, (function (token) {
                return token.owner === uid;
              }));
}

function getUserOwnedApiTokens(uid, apiTokens) {
  return Belt_Array.keep(apiTokens, (function (token) {
                return token.owner === uid;
              }));
}

function getUserOwnedDeduplicationRules(uid, deduplicationRules) {
  return Belt_Array.keep(deduplicationRules, (function (rule) {
                return rule.owner === uid;
              }));
}

function getUserOwnedReports(uid, reports) {
  return Belt_Array.keep(reports, (function (report) {
                return report.owner === uid;
              }));
}

function doesUserOwnASymsrv(uid, symbolServers) {
  return Belt_Option.isSome(Belt_Array.getBy(symbolServers, (function (symsrv) {
                    return symsrv.owner === uid;
                  })));
}

function getUserOwnedSymsrvs(uid, symbolServers) {
  return Belt_Array.keep(symbolServers, (function (symsrv) {
                return symsrv.owner === uid;
              }));
}

function getUserOwnedResourseOverride(uid, resourceOverrides) {
  return Belt_Array.keep(resourceOverrides, (function (resourceOverride) {
                if (resourceOverride.owner === uid) {
                  return true;
                } else {
                  return resourceOverride.uid === uid;
                }
              }));
}

function getUserOwnedProjectMemberUser(uid, projectMemberUsers) {
  return Belt_Array.keep(projectMemberUsers, (function (projectMemberUser) {
                return projectMemberUser.user === uid;
              }));
}

function getUserOwnedTeamMember(uid, teamMembers) {
  return Belt_Array.keep(teamMembers, (function (member) {
                return member.user === uid;
              }));
}

function getUserOwnedFrontendSettings(uid, userFrontendSettings) {
  return Belt_Array.keep(userFrontendSettings, (function (settings) {
                return settings.owner === uid;
              }));
}

function getUserDependencies(uid, userOwnedEntities) {
  var projects = userOwnedEntities.projects;
  var arr = [];
  var arr$1 = Belt_Array.concat(arr, Belt_Array.map(getUserOwnedProjects(uid, projects), (function (project) {
              return BacktraceParserGenerator$BsConsole.Project.table + (" (" + (project.name + ")"));
            })));
  var arr$2 = Belt_Array.concat(arr$1, Belt_Array.map(getUserOwnedWatcherNew(uid, userOwnedEntities.watchersNew), (function (watcher) {
              var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                          return project.pid === watcher.project;
                        })), (function (project) {
                      return project.name;
                    }));
              if (projectName !== undefined) {
                return BacktraceParserGenerator$BsConsole.WatcherNew.table + (" (" + (projectName + (" - " + (watcher.name + ")"))));
              } else {
                return BacktraceParserGenerator$BsConsole.WatcherNew.table + (" (" + (watcher.name + ")"));
              }
            })));
  var arr$3 = Belt_Array.concat(arr$2, Belt_Array.map(getUserOwnedTokens(uid, userOwnedEntities.tokens), (function (token) {
              var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                          return project.pid === token.project;
                        })), (function (project) {
                      return project.name;
                    }));
              if (projectName !== undefined) {
                return BacktraceParserGenerator$BsConsole.Token.table + (" (" + (projectName + ")"));
              } else {
                return BacktraceParserGenerator$BsConsole.Token.table;
              }
            })));
  var arr$4 = Belt_Array.concat(arr$3, Belt_Array.map(getUserOwnedApiTokens(uid, userOwnedEntities.apiTokens), (function (token) {
              var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                          return project.pid === token.project;
                        })), (function (project) {
                      return project.name;
                    }));
              if (projectName !== undefined) {
                return BacktraceParserGenerator$BsConsole.ApiToken.table + (" (" + (projectName + ")"));
              } else {
                return BacktraceParserGenerator$BsConsole.ApiToken.table;
              }
            })));
  var arr$5 = Belt_Array.concat(arr$4, Belt_Array.map(getUserOwnedDeduplicationRules(uid, userOwnedEntities.deduplicationRules), (function (rule) {
              var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                          return project.pid === rule.project;
                        })), (function (project) {
                      return project.name;
                    }));
              if (projectName !== undefined) {
                return BacktraceParserGenerator$BsConsole.Deduplication.table + (" (" + (projectName + ")"));
              } else {
                return BacktraceParserGenerator$BsConsole.Deduplication.table;
              }
            })));
  var arr$6 = Belt_Array.concat(arr$5, Belt_Array.map(getUserOwnedReports(uid, userOwnedEntities.reports), (function (report) {
              var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                          return project.pid === report.project;
                        })), (function (project) {
                      return project.name;
                    }));
              if (projectName !== undefined) {
                return BacktraceParserGenerator$BsConsole.Report.table + (" (" + (projectName + ")"));
              } else {
                return BacktraceParserGenerator$BsConsole.Report.table;
              }
            })));
  return Belt_Array.concat(arr$6, Belt_Array.map(getUserOwnedSymsrvs(uid, userOwnedEntities.symbolServers), (function (server) {
                    var projectName = Belt_Option.map(Belt_Array.getBy(projects, (function (project) {
                                return project.pid === server.project;
                              })), (function (project) {
                            return project.name;
                          }));
                    if (projectName !== undefined) {
                      return BacktraceParserGenerator$BsConsole.Symsrv.table + (" (" + (projectName + ")"));
                    } else {
                      return BacktraceParserGenerator$BsConsole.Symsrv.table;
                    }
                  })));
}

function getDeleteUserDependenciesActions(user, userOwnedEntities) {
  var userFrontendSettingsActions = Belt_Array.map(getUserOwnedFrontendSettings(user.uid, userOwnedEntities.userFrontendSettings), (function (settings) {
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        userFrontendSettingsEntity
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BacktraceParserGenerator$BsConsole.UserFrontendSettings.Key.to_json, BacktraceParserGenerator$BsConsole.UserFrontendSettings.to_key(settings))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
        }));
  var teamMemberActions = Belt_Array.map(getUserOwnedTeamMember(user.uid, userOwnedEntities.teamMembers), (function (teamMember) {
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        teamMemberEntity
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BacktraceParserGenerator$BsConsole.TeamMember.Key.to_json, BacktraceParserGenerator$BsConsole.TeamMember.to_key(teamMember))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
        }));
  var resourceOverrideActions = Belt_Array.map(getUserOwnedResourseOverride(user.uid, userOwnedEntities.resourceOverrides), (function (override) {
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        resourceOverrideEntity
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, BacktraceParserGenerator$BsConsole.ResourceOverride.to_key(override))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
        }));
  var projectMemberUserActions = Belt_Array.map(getUserOwnedProjectMemberUser(user.uid, userOwnedEntities.projectMemberUsers), (function (projectMemberUser) {
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        projectMemberUserEntity
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            Curry._1(BacktraceParserGenerator$BsConsole.ProjectMemberUser.Key.to_json, BacktraceParserGenerator$BsConsole.ProjectMemberUser.to_key(projectMemberUser))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
        }));
  return Belt_Array.concatMany([
              userFrontendSettingsActions,
              teamMemberActions,
              resourceOverrideActions,
              projectMemberUserActions
            ]);
}

function getDeleteUserAction(user) {
  var userEntity = "configuration/" + BacktraceParserGenerator$BsConsole.Users.table;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                userEntity
              ],
              /* :: */[
                /* tuple */[
                  "action",
                  "delete"
                ],
                /* :: */[
                  /* tuple */[
                    "key",
                    Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, BacktraceParserGenerator$BsConsole.Users.to_key(user))
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

function getDeleteUserAndEntitiesAction(user, userOwnedEntities) {
  var dependencyActions = getDeleteUserDependenciesActions(user, userOwnedEntities);
  var deleteUserAction = getDeleteUserAction(user);
  return Belt_Array.concat(dependencyActions, [deleteUserAction]);
}

function getDeleteUserTask(user, userOwnedEntities) {
  var actions = getDeleteUserAndEntitiesAction(user, userOwnedEntities);
  var decodeResp = function (param) {
    var json = param.json;
    try {
      var deleteUserResult = Belt_Option.flatMap(Belt_Array.getIndexBy(Json_decode.field("actions", BpgUtils$BsConsole.$$Response.decodeActions, json), (function (param) {
                  var matchesKey = Belt_Option.getWithDefault(Belt_Option.map(param[2], (function (json) {
                              var key = Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.of_json, json);
                              return key.username === user.username;
                            })), false);
                  if (matchesKey) {
                    return param[1] === userEntity;
                  } else {
                    return false;
                  }
                })), (function (index) {
              return Belt_Option.map(Belt_Array.get(Json_decode.field("results", (function (param) {
                                    return Json_decode.array((function (json$prime) {
                                                  return json$prime;
                                                }), param);
                                  }), json), index), (function (json$prime) {
                            return Json_decode.field("text", Json_decode.string, json$prime);
                          }));
            }));
      if (deleteUserResult !== undefined) {
        if (deleteUserResult === "success") {
          return /* Ok */Block.__(0, ["success"]);
        } else {
          return /* Error */Block.__(1, [[
                      BpgTask$BsConsole.BpgError,
                      /* Failed */0,
                      deleteUserResult
                    ]]);
        }
      } else {
        return /* Error */Block.__(1, [BpgTask$BsConsole.UnknownBpgError]);
      }
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      console.log(err);
      return /* Error */Block.__(1, [BpgTask$BsConsole.UnknownBpgError]);
    }
  };
  return BpgTask$BsConsole.baseTask(undefined, decodeResp, actions, undefined, undefined);
}

function getDeleteUsersAndEntitiesAction(users, userOwnedEntities) {
  var dependencyActions = Belt_Array.concatMany(Belt_Array.map(users, (function (user) {
              return getDeleteUserDependenciesActions(user, userOwnedEntities);
            })));
  var deleteUsersAction = Belt_Array.map(users, getDeleteUserAction);
  return Belt_Array.concat(dependencyActions, deleteUsersAction);
}

function decodeDeleteUsersResults(json) {
  var resultTextArray = Json_decode.field("results", (function (param) {
          return Json_decode.array((function (json$prime) {
                        return Json_decode.field("text", Json_decode.string, json$prime);
                      }), param);
        }), json);
  return Belt_Array.keepMap(Belt_Array.map(Belt_Array.keep(Belt_Array.mapWithIndex(Json_decode.field("actions", BpgUtils$BsConsole.$$Response.decodeActions, json), (function (idx, action) {
                            return /* tuple */[
                                    idx,
                                    action
                                  ];
                          })), (function (param) {
                        return param[1][1] === userEntity;
                      })), (function (param) {
                    var username = Belt_Option.map(param[1][2], (function (json$prime) {
                            return Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.of_json, json$prime).username;
                          }));
                    var resultText = Belt_Array.get(resultTextArray, param[0]);
                    return /* tuple */[
                            username,
                            resultText
                          ];
                  })), (function (param) {
                var resultText = param[1];
                var username = param[0];
                if (username !== undefined && resultText !== undefined) {
                  if (resultText === "success") {
                    return /* Success */Block.__(0, [username]);
                  } else {
                    return /* Failure */Block.__(1, [
                              username,
                              resultText
                            ]);
                  }
                }
                
              }));
}

function getDeleteMultipleUsersTask(users, userOwnedEntities) {
  var actions = getDeleteUsersAndEntitiesAction(users, userOwnedEntities);
  var decodeResp = function (param) {
    try {
      var result = decodeDeleteUsersResults(param.json);
      return /* Ok */Block.__(0, [result]);
    }
    catch (_err){
      return /* Error */Block.__(1, [BpgTask$BsConsole.UnknownBpgError]);
    }
  };
  return BpgTask$BsConsole.baseTask(undefined, decodeResp, actions, undefined, undefined);
}

exports.prefix = prefix;
exports.projectEntity = projectEntity;
exports.watcherNewEntity = watcherNewEntity;
exports.tokenEntity = tokenEntity;
exports.apiTokenEntity = apiTokenEntity;
exports.deduplicationEntity = deduplicationEntity;
exports.reportEntity = reportEntity;
exports.resourceOverrideEntity = resourceOverrideEntity;
exports.symsrvEntity = symsrvEntity;
exports.projectMemberUserEntity = projectMemberUserEntity;
exports.teamMemberEntity = teamMemberEntity;
exports.userFrontendSettingsEntity = userFrontendSettingsEntity;
exports.userEntity = userEntity;
exports.getOwnedItemsTask = getOwnedItemsTask;
exports.getUserOwnedProjects = getUserOwnedProjects;
exports.getUserOwnedWatcherNew = getUserOwnedWatcherNew;
exports.doesUserOwnAToken = doesUserOwnAToken;
exports.getUserOwnedTokens = getUserOwnedTokens;
exports.getUserOwnedApiTokens = getUserOwnedApiTokens;
exports.getUserOwnedDeduplicationRules = getUserOwnedDeduplicationRules;
exports.getUserOwnedReports = getUserOwnedReports;
exports.doesUserOwnASymsrv = doesUserOwnASymsrv;
exports.getUserOwnedSymsrvs = getUserOwnedSymsrvs;
exports.getUserOwnedResourseOverride = getUserOwnedResourseOverride;
exports.getUserOwnedProjectMemberUser = getUserOwnedProjectMemberUser;
exports.getUserOwnedTeamMember = getUserOwnedTeamMember;
exports.getUserOwnedFrontendSettings = getUserOwnedFrontendSettings;
exports.getUserDependencies = getUserDependencies;
exports.getDeleteUserDependenciesActions = getDeleteUserDependenciesActions;
exports.getDeleteUserAction = getDeleteUserAction;
exports.getDeleteUserAndEntitiesAction = getDeleteUserAndEntitiesAction;
exports.getDeleteUserTask = getDeleteUserTask;
exports.getDeleteUsersAndEntitiesAction = getDeleteUsersAndEntitiesAction;
exports.decodeDeleteUsersResults = decodeDeleteUsersResults;
exports.getDeleteMultipleUsersTask = getDeleteMultipleUsersTask;
/* Task2-BsConsole Not a pure module */
