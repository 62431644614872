// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function Make(O) {
  var create = function (param) {
    return {
            attribute: param[0],
            operation: param[1]
          };
  };
  var toJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var toURLEncodedJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toURLEncodedJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var toPrivateJson = function (a) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), O.toPrivateJson, /* tuple */[
                a.attribute,
                a.operation
              ]);
  };
  var fromJson = function (json) {
    return create(Json_decode.tuple2(Json_decode.string, O.fromJson, json));
  };
  var fromURLEncodedJson = function (json) {
    return create(Json_decode.tuple2(Json_decode.string, O.fromURLEncodedJson, json));
  };
  var getAttribute = function (t$prime) {
    return t$prime.attribute;
  };
  var getOperation = function (t$prime) {
    return t$prime.operation;
  };
  var createRange = function (param) {
    var match = param[1];
    var attribute = param[0];
    var match$1 = Curry._2(O.getRange, match[0], match[1]);
    return /* tuple */[
            create(/* tuple */[
                  attribute,
                  match$1[0]
                ]),
            create(/* tuple */[
                  attribute,
                  match$1[1]
                ])
          ];
  };
  return {
          create: create,
          toJson: toJson,
          toURLEncodedJson: toURLEncodedJson,
          toPrivateJson: toPrivateJson,
          fromJson: fromJson,
          fromURLEncodedJson: fromURLEncodedJson,
          getAttribute: getAttribute,
          getOperation: getOperation,
          createRange: createRange
        };
}

exports.Make = Make;
/* No side effect */
