// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");

var crumb = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.marginRight(Css.rem(0.5)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey35)),
              /* [] */0
            ]
          ]),
      /* :: */[
        "crumb",
        /* [] */0
      ]
    ]);

var link = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.color(Css.hex(Colors$BsConsole.accent)),
            /* :: */[
              Css.selector(".crumb", /* :: */[
                    Css.unsafe("color", "inherit"),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]),
      /* [] */0
    ]);

var current = Css.style(/* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            500
          ]),
      /* [] */0
    ]);

var Style = {
  crumb: crumb,
  link: link,
  current: current
};

function NavBreadcrumb(Props) {
  var current$1 = Props.current;
  var trail = Props.trail;
  var handleChangeUrl = Props.handleChangeUrl;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Row2$BsConsole.make, {
              className: className,
              children: null
            }, Belt_Array.mapWithIndex(trail, (function (idx, param) {
                    var routeName = param[0];
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: routeName + ("_" + String(idx))
                              }, React.createElement(Link$BsConsole.Jsx3.make, {
                                    route: param[1],
                                    handleChangeUrl: handleChangeUrl,
                                    className: link,
                                    style: { },
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          className: crumb,
                                          children: I18N$BsConsole.show(undefined, routeName)
                                        }),
                                    key: "link-" + (routeName + ("_" + String(idx)))
                                  }), React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body1 */8,
                                    className: crumb,
                                    children: I18N$BsConsole.showSkip("/"),
                                    key: "BtTypography-" + (routeName + ("_" + String(idx)))
                                  }));
                  })), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: current,
                  children: I18N$BsConsole.show(undefined, current$1)
                }));
}

function make(current, trail, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(NavBreadcrumb, {
              current: current,
              trail: trail,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = NavBreadcrumb;

exports.Style = Style;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* crumb Not a pure module */
