// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CamlinternalLazy = require("bs-platform/lib/js/camlinternalLazy.js");

function getWithDefault(option, otherwise) {
  if (option !== undefined) {
    return Caml_option.valFromOption(option);
  } else {
    return CamlinternalLazy.force(otherwise);
  }
}

var Lazy = {
  getWithDefault: getWithDefault
};

exports.Lazy = Lazy;
/* No side effect */
