// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Numeral = require("numeral");
var D3Scale = require("d3-scale");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var PrModal$BsConsole = require("../progressive-reveal/PrModal.js");
var Tooltip$BsConsole = require("./Tooltip.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var PrModalLayout$BsConsole = require("../progressive-reveal/PrModalLayout.js");
var BsVx_Shape__Bar$BsConsole = require("../bs-vx/src/BsVx_Shape__Bar.js");
var DistributionMenu$BsConsole = require("./DistributionMenu.js");
var ControllableHoverBg$BsConsole = require("../ControllableHoverBg.js");
var SimpleTooltipContent$BsConsole = require("./SimpleTooltipContent.js");
var QbExpandedAggregation$BsConsole = require("../query-builder/QbExpandedAggregation.js");

var component = RR$BsConsole.reducerComponent("DistributionForDetailCard-BsConsole");

function make(attribute, distribution, handleAddFilters, handleChangeUrl, containerWidth, onSomethingHovered, onNothingHovered, factor, aperture, token, name, group, handleTask, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var dialog = state.dialog;
              var hoveredRow = state.hoveredRow;
              var send = param.send;
              var chartData = Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
                      return /* tuple */[
                              FormatUtils$BsConsole.formatToString(param[0]),
                              param[1]
                            ];
                    }));
              var leftSideTextLength = Caml_int32.imul(250, containerWidth) / 450 | 0;
              var maxValue = Belt_Array.reduce(Belt_Array.concat(Belt_Array.map(chartData, (function (prim) {
                              return prim[1];
                            })), [Belt_Option.getWithDefault(distribution.tail, 0)]), 0, (function (prim, prim$1) {
                      if (prim > prim$1) {
                        return prim;
                      } else {
                        return prim$1;
                      }
                    }));
              var xScale = D3Scale.scaleLinear().rangeRound([
                      0,
                      1000
                    ]).domain([
                    0,
                    maxValue
                  ]);
              var valuesTotal = Belt_Array.reduce(Belt_Array.map(chartData, (function (prim) {
                          return prim[1];
                        })), 0, (function (prim, prim$1) {
                      return prim + prim$1 | 0;
                    })) + Belt_Option.getWithDefault(distribution.tail, 0) | 0;
              var toFormattedPct = function (occurrences) {
                return Numeral(Pervasives.string_of_float(occurrences / valuesTotal)).format("0%");
              };
              var tail = distribution.tail;
              var tmp;
              if (tail !== undefined) {
                var tailKeysCount = distribution.keys - chartData.length | 0;
                tmp = ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                              Css.margin(Css.px(3)),
                              /* [] */0
                            ]), [
                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [
                                    React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(leftSideTextLength)),
                                                /* :: */[
                                                  Css.fontFamily("Roboto"),
                                                  /* :: */[
                                                    Css.fontSize(Css.px(12)),
                                                    /* :: */[
                                                      Css.overflow(/* hidden */-862584982),
                                                      /* :: */[
                                                        Css.whiteSpace(/* nowrap */867913355),
                                                        /* :: */[
                                                          Css.textOverflow(/* ellipsis */166888785),
                                                          /* :: */[
                                                            Css.marginBottom(Css.px(5)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String_literal */Block.__(11, [
                                                      "other (",
                                                      /* Int */Block.__(4, [
                                                          /* Int_d */0,
                                                          /* No_padding */0,
                                                          /* No_precision */0,
                                                          /* Char_literal */Block.__(12, [
                                                              /* ")" */41,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ]),
                                                  "other (%d)"
                                                ]), tailKeysCount)),
                                    React.createElement("div", {
                                          style: {
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                            overflow: "hidden",
                                            textAlign: "right",
                                            whiteSpace: "nowrap",
                                            width: "32px",
                                            textOverflow: "ellipsis"
                                          }
                                        }, I18N$BsConsole.showSkip(toFormattedPct(tail)))
                                  ])),
                          React.createElement("svg", {
                                preserveAspectRatio: "none",
                                viewBox: "0 0 " + (String(1000) + (" " + (String(17) + "")))
                              }, ReasonReact.element(undefined, undefined, BsVx_Shape__Bar$BsConsole.make(undefined, undefined, undefined, Curry._1(xScale, tail), 17, undefined, undefined, Colors$BsConsole.octothorpe(Colors$BsConsole.greenLight), undefined, undefined, undefined, undefined, (function (e) {
                                          e.stopPropagation();
                                          
                                        }), undefined, (function (e) {
                                          return Curry._1(send, /* RowHovered */Block.__(0, [/* tuple */[
                                                          Tooltip$BsConsole.fromSynthetic(e),
                                                          "other",
                                                          tail
                                                        ]]));
                                        }), (function (param) {
                                          return Curry._1(send, /* RowUnhovered */0);
                                        }), []))),
                          ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, []))
                        ]));
              } else {
                tmp = null;
              }
              var tmp$1;
              if (dialog !== undefined) {
                var group$1 = dialog[2];
                var attribute$1 = dialog[0];
                var factorName = group$1.factor.desc.name;
                var factorValue = group$1.factor.value;
                tmp$1 = ReasonReact.element(undefined, undefined, PrModalLayout$BsConsole.make(factorName, factorValue, attribute$1, "distribution", (function (_event) {
                            return Curry._1(send, /* Dialog */Block.__(1, [undefined]));
                          }), (function (dialogWidth, dialogMaxHeight) {
                            return ReasonReact.element(undefined, undefined, QbExpandedAggregation$BsConsole.make(undefined, (function (param) {
                                              return Curry._1(send, /* Dialog */Block.__(1, [undefined]));
                                            }), factor, handleChangeUrl, handleAddFilters, aperture, attribute$1, handleTask, token, name, dialogWidth, dialogMaxHeight, []));
                          })));
              } else {
                tmp$1 = React.createElement("div", undefined);
              }
              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* [] */0
                                  ]),
                              onClick: (function (_event) {
                                  return Curry._1(send, /* Dialog */Block.__(1, [/* ExpandedAggregation */[
                                                  attribute,
                                                  undefined,
                                                  group
                                                ]]));
                                })
                            }, Belt_Array.mapWithIndex(chartData, (function (i, param) {
                                    var value = param[1];
                                    var label = param[0];
                                    var tmp;
                                    switch (i) {
                                      case 0 :
                                          tmp = Colors$BsConsole.octothorpe(Colors$BsConsole.greenExtraDark);
                                          break;
                                      case 1 :
                                          tmp = Colors$BsConsole.octothorpe(Colors$BsConsole.greenDark);
                                          break;
                                      case 2 :
                                          tmp = Colors$BsConsole.octothorpe(Colors$BsConsole.green);
                                          break;
                                      default:
                                        tmp = Colors$BsConsole.octothorpe(Colors$BsConsole.greenLight);
                                    }
                                    return ReasonReact.element(label, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                        Css.margin(Css.px(5)),
                                                        /* [] */0
                                                      ]), [
                                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [
                                                              React.createElement("div", {
                                                                    className: Css.style(/* :: */[
                                                                          Css.width(Css.px(leftSideTextLength)),
                                                                          /* [] */0
                                                                        ])
                                                                  }, ReasonReact.element(undefined, undefined, ControllableHoverBg$BsConsole.make({
                                                                            height: "100%"
                                                                          }, (function (param) {
                                                                              return Curry._1(onSomethingHovered, undefined);
                                                                            }), (function (param) {
                                                                              return Curry._1(onNothingHovered, undefined);
                                                                            }), (function (param) {
                                                                              return ReasonReact.element(undefined, undefined, DistributionMenu$BsConsole.make(label, (function (param) {
                                                                                                return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                                                                /* tuple */[
                                                                                                                  attribute,
                                                                                                                  /* Equal */Block.__(0, [/* `String */[
                                                                                                                        -976970511,
                                                                                                                        label
                                                                                                                      ]])
                                                                                                                ],
                                                                                                                /* [] */0
                                                                                                              ]));
                                                                                              }), (function (param) {
                                                                                                return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                                                                /* tuple */[
                                                                                                                  attribute,
                                                                                                                  /* NotEqual */Block.__(1, [/* `String */[
                                                                                                                        -976970511,
                                                                                                                        label
                                                                                                                      ]])
                                                                                                                ],
                                                                                                                /* [] */0
                                                                                                              ]));
                                                                                              }), (function (openMenu) {
                                                                                                return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.ButtonBase.Jsx2.make, Css.style(/* :: */[
                                                                                                                    Css.width(Css.px(leftSideTextLength)),
                                                                                                                    /* :: */[
                                                                                                                      Css.height(/* `percent */[
                                                                                                                            -119887163,
                                                                                                                            100.00
                                                                                                                          ]),
                                                                                                                      /* [] */0
                                                                                                                    ]
                                                                                                                  ]), undefined, (function (e) {
                                                                                                                  return Curry._1(openMenu, e.target);
                                                                                                                }), (function (e) {
                                                                                                                  e.stopPropagation();
                                                                                                                  
                                                                                                                }), (function (e) {
                                                                                                                  e.stopPropagation();
                                                                                                                  
                                                                                                                }), undefined, [React.createElement("div", {
                                                                                                                      className: Css.style(/* :: */[
                                                                                                                            Css.textAlign(/* left */-944764921),
                                                                                                                            /* :: */[
                                                                                                                              Css.width(Css.px(leftSideTextLength)),
                                                                                                                              /* :: */[
                                                                                                                                Css.fontFamily("Roboto"),
                                                                                                                                /* :: */[
                                                                                                                                  Css.fontSize(Css.px(12)),
                                                                                                                                  /* :: */[
                                                                                                                                    Css.overflow(/* hidden */-862584982),
                                                                                                                                    /* :: */[
                                                                                                                                      Css.whiteSpace(/* nowrap */867913355),
                                                                                                                                      /* :: */[
                                                                                                                                        Css.textOverflow(/* ellipsis */166888785),
                                                                                                                                        /* [] */0
                                                                                                                                      ]
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]
                                                                                                                              ]
                                                                                                                            ]
                                                                                                                          ])
                                                                                                                    }, I18N$BsConsole.showSkip(label))]));
                                                                                              })));
                                                                            })))),
                                                              React.createElement("div", {
                                                                    style: {
                                                                      fontFamily: "Roboto",
                                                                      fontSize: "12px",
                                                                      overflow: "hidden",
                                                                      textAlign: "right",
                                                                      whiteSpace: "nowrap",
                                                                      width: "32px",
                                                                      textOverflow: "ellipsis"
                                                                    }
                                                                  }, I18N$BsConsole.showSkip(toFormattedPct(value)))
                                                            ])),
                                                    React.createElement("svg", {
                                                          preserveAspectRatio: "none",
                                                          viewBox: "0 0 " + (String(1000) + (" " + (String(17) + "")))
                                                        }, ReasonReact.element(label, undefined, BsVx_Shape__Bar$BsConsole.make(undefined, undefined, undefined, Curry._1(xScale, value), 17, undefined, undefined, tmp, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                                                    return Curry._1(send, /* RowHovered */Block.__(0, [/* tuple */[
                                                                                    Tooltip$BsConsole.fromSynthetic(e),
                                                                                    label,
                                                                                    value
                                                                                  ]]));
                                                                  }), (function (param) {
                                                                    return Curry._1(send, /* RowUnhovered */0);
                                                                  }), [])), hoveredRow !== undefined ? ReasonReact.element(undefined, undefined, Tooltip$BsConsole.make(hoveredRow[0], [ReasonReact.element(undefined, undefined, SimpleTooltipContent$BsConsole.make(hoveredRow[1], String(hoveredRow[2]), []))])) : null),
                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, []))
                                                  ]));
                                  })), tmp), ReasonReact.element(undefined, undefined, PrModal$BsConsole.make(Belt_Option.isSome(dialog), (function (_event) {
                                    return Curry._1(send, /* Dialog */Block.__(1, [undefined]));
                                  }), [tmp$1])));
            }),
          initialState: (function (param) {
              return {
                      hoveredRow: undefined,
                      dialog: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            hoveredRow: undefined,
                            dialog: state.dialog
                          }]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            hoveredRow: state.hoveredRow,
                            dialog: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            hoveredRow: action[0],
                            dialog: state.dialog
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
