// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugChip$BsConsole = require("./BugChip.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Popover = require("@material-ui/core/Popover");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");

var bugChip = Css.style(/* :: */[
      Css.height(Css.px(20)),
      /* [] */0
    ]);

var Styles = {
  bugChip: bugChip
};

function BugHeaderClassifiers$ClassifierRow(Props) {
  Props.config;
  var onAddFilter = Props.onAddFilter;
  var classifier = Props.classifier;
  var match = React.useState((function () {
          
        }));
  var setClassifierAnchor = match[1];
  var classifierAnchor = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  key: classifier.name,
                  className: Css.style(/* :: */[
                        Css.marginRight(Css.px(8)),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (e) {
                      var target = e.target;
                      return Curry._1(setClassifierAnchor, (function (param) {
                                    return Caml_option.some(target);
                                  }));
                    })
                }, React.createElement(BugChip$BsConsole.make, {
                      backgroundColor: Colors$BsConsole.greenExtraLight,
                      label: classifier.name,
                      className: bugChip
                    })), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: classifierAnchor,
                  open: Belt_Option.isSome(classifierAnchor),
                  onClose: (function (param) {
                      return Curry._1(setClassifierAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Clipboard$BsConsole.copy(classifier.name, undefined, undefined);
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String_literal */Block.__(11, [
                                                  "Classifier copied to clipboard ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "Classifier copied to clipboard %s"
                                            ]), classifier.name));
                                return Curry._1(setClassifierAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Copy")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(onAddFilter, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                          "classifiers",
                                          /* Equal */Block.__(0, [/* `String */[
                                                -976970511,
                                                classifier.name
                                              ]])
                                        ]));
                                return Curry._1(setClassifierAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Filter to")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(onAddFilter, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                          "classifiers",
                                          /* NotEqual */Block.__(1, [/* `String */[
                                                -976970511,
                                                classifier.name
                                              ]])
                                        ]));
                                return Curry._1(setClassifierAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Filter against")
                          }))
                }));
}

var ClassifierRow = {
  make: BugHeaderClassifiers$ClassifierRow
};

function BugHeaderClassifiers(Props) {
  var config = Props.config;
  var classifiers = Props.classifiers;
  var onAddFilter = Props.onAddFilter;
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* [] */0),
              children: Belt_Array.map(classifiers, (function (classifier) {
                      return React.createElement(BugHeaderClassifiers$ClassifierRow, {
                                  config: config,
                                  onAddFilter: onAddFilter,
                                  classifier: classifier,
                                  key: classifier.name
                                });
                    }))
            });
}

var make = BugHeaderClassifiers;

exports.Styles = Styles;
exports.ClassifierRow = ClassifierRow;
exports.make = make;
/* bugChip Not a pure module */
