// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");

function BugRegistersSimple(Props) {
  var w = Props.width;
  var h = Props.height;
  var registers = Props.registers;
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.height(Css.px(h)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(BugSectionHeader$BsConsole.make, {
                  title: "Registers"
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(w)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* :: */[
                            Css.overflowY(Css.auto),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.fontFamily("Inconsolata"),
                            /* :: */[
                              Css.fontSize(Css.px(12)),
                              /* :: */[
                                Css.padding2(Css.px(4), Css.px(8)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: Belt_Array.map(Js_dict.entries(registers), (function (param) {
                              var key = param[0];
                              return React.createElement(Row2$BsConsole.make, {
                                          alignItems: /* center */98248149,
                                          justifyContent: /* spaceBetween */516682146,
                                          className: Css.style(/* :: */[
                                                Css.maxWidth(Css.px(220)),
                                                /* :: */[
                                                  Css.width(Css.pct(100)),
                                                  /* :: */[
                                                    Css.height(Css.px(20)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          children: null,
                                          key: key
                                        }, React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.paddingRight(Css.px(8)),
                                                    /* [] */0
                                                  ])
                                            }, I18N$BsConsole.showSkip(key)), React.createElement("div", undefined, I18N$BsConsole.showSkip(param[1])));
                            }))
                    })));
}

var make = BugRegistersSimple;

exports.make = make;
/* Css Not a pure module */
