// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");

function uniq(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          uniq(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(v, hd);
                    })))
        ];
}

function contains(l, value) {
  return Belt_List.reduce(l, false, (function (acc, elem) {
                if (acc) {
                  return true;
                } else {
                  return Caml_obj.caml_equal(elem, value);
                }
              }));
}

exports.uniq = uniq;
exports.contains = contains;
/* No side effect */
