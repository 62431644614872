// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Step = require("@material-ui/core/Step");
var Button = require("@material-ui/core/Button");
var Stepper = require("@material-ui/core/Stepper");
var StepLabel = require("@material-ui/core/StepLabel");
var StepButton = require("@material-ui/core/StepButton");

function toInt(step) {
  return step;
}

function fromInt(n) {
  switch (n) {
    case 0 :
        return /* SelectIntegration */0;
    case 1 :
        return /* ConfigureConnection */1;
    case 2 :
        return /* CustomizePayload */2;
    case 3 :
        return /* SetupBehavior */3;
    case 4 :
        return /* AdvancedSettings */4;
    default:
      return ;
  }
}

function fromString(str) {
  switch (str) {
    case "advanced-settings" :
        return /* AdvancedSettings */4;
    case "configure-connection" :
        return /* ConfigureConnection */1;
    case "customize-payload" :
        return /* CustomizePayload */2;
    case "select-integration" :
        return /* SelectIntegration */0;
    case "setup-behavior" :
        return /* SetupBehavior */3;
    default:
      return ;
  }
}

function toString(step) {
  switch (step) {
    case /* SelectIntegration */0 :
        return "select-integration";
    case /* ConfigureConnection */1 :
        return "configure-connection";
    case /* CustomizePayload */2 :
        return "customize-payload";
    case /* SetupBehavior */3 :
        return "setup-behavior";
    case /* AdvancedSettings */4 :
        return "advanced-settings";
    
  }
}

function toLocalizedString(step) {
  switch (step) {
    case /* SelectIntegration */0 :
        return I18N$BsConsole.show(undefined, "Select integration");
    case /* ConfigureConnection */1 :
        return I18N$BsConsole.show(undefined, "Configure connection");
    case /* CustomizePayload */2 :
        return I18N$BsConsole.show(undefined, "Customize payload");
    case /* SetupBehavior */3 :
        return I18N$BsConsole.show(undefined, "Setup behavior");
    case /* AdvancedSettings */4 :
        return I18N$BsConsole.show(undefined, "Advanced settings");
    
  }
}

function next(step) {
  return fromInt(step + 1 | 0);
}

function previous(step) {
  return fromInt(step - 1 | 0);
}

function compare(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

var Step$1 = {
  toInt: toInt,
  fromInt: fromInt,
  fromString: fromString,
  toString: toString,
  toLocalizedString: toLocalizedString,
  next: next,
  previous: previous,
  compare: compare
};

function empty(param) {
  return /* [] */0;
}

function make(steps) {
  return steps;
}

function next$1(steps, step) {
  var _steps = steps;
  while(true) {
    var steps$1 = _steps;
    if (!steps$1) {
      return ;
    }
    var tail = steps$1[1];
    if (steps$1[0] === step) {
      if (tail) {
        return tail[0];
      } else {
        return ;
      }
    }
    _steps = tail;
    continue ;
  };
}

function prev(steps, step) {
  if (!steps) {
    return ;
  }
  var head = steps[0];
  if (head === step) {
    return ;
  } else {
    var _steps = steps[1];
    var _prev = head;
    while(true) {
      var prev$1 = _prev;
      var steps$1 = _steps;
      if (!steps$1) {
        return ;
      }
      var head$1 = steps$1[0];
      if (head$1 === step) {
        return prev$1;
      }
      _prev = head$1;
      _steps = steps$1[1];
      continue ;
    };
  }
}

function indexOf(steps, step) {
  var _steps = steps;
  var _i = 0;
  while(true) {
    var i = _i;
    var steps$1 = _steps;
    if (!steps$1) {
      return -1;
    }
    if (steps$1[0] === step) {
      return i;
    }
    _i = i + 1 | 0;
    _steps = steps$1[1];
    continue ;
  };
}

function compare$1(steps, a, b) {
  var a$1 = indexOf(steps, a);
  var b$1 = indexOf(steps, b);
  return a$1 - b$1 | 0;
}

var Steps = {
  empty: empty,
  make: make,
  next: next$1,
  prev: prev,
  indexOf: indexOf,
  compare: compare$1
};

function WfWatcherStepper$UpperNavigation(Props) {
  var steps = Props.steps;
  var step = Props.step;
  var onStep = Props.onStep;
  var maxStep = Props.maxStep;
  var maxStepIndex = Belt_Option.map(maxStep, (function (param) {
          return indexOf(steps, param);
        }));
  var isDisabled = function (step) {
    if (maxStepIndex !== undefined) {
      return indexOf(steps, step) > (maxStepIndex + 1 | 0);
    } else {
      return false;
    }
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.paddingTop(/* `px */[
                          25096,
                          4
                        ]),
                    /* [] */0
                  ])
            }, React.createElement(Stepper.default, {
                  className: Css.style(/* :: */[
                        Css.flex(/* `num */[
                              5496390,
                              1
                            ]),
                        /* [] */0
                      ]),
                  nonLinear: true,
                  activeStep: /* `Int */[
                    3654863,
                    step
                  ],
                  alternativeLabel: true,
                  children: Belt_List.toArray(Belt_List.map(steps, (function (stepToRender) {
                              return React.createElement(Step.default, {
                                          completed: compare(step, stepToRender) > 0,
                                          active: step === stepToRender,
                                          disabled: isDisabled(stepToRender),
                                          children: React.createElement(StepButton.default, {
                                                onClick: (function (ev) {
                                                    return Curry._2(onStep, ev, stepToRender);
                                                  }),
                                                children: React.createElement(StepLabel.default, {
                                                      children: toLocalizedString(stepToRender)
                                                    })
                                              }),
                                          key: String(stepToRender)
                                        });
                            })))
                }));
}

var component = ReasonReact.statelessComponent("WfWatcherStepper-BsConsole");

function make$1(steps, step, onStep, maxStep, children) {
  var tmp = {
    steps: steps,
    step: step,
    onStep: onStep
  };
  if (maxStep !== undefined) {
    tmp.maxStep = Caml_option.valFromOption(maxStep);
  }
  return ReasonReactCompat.wrapReactForReasonReact(WfWatcherStepper$UpperNavigation, tmp, children);
}

var Jsx2 = {
  component: component,
  make: make$1
};

var UpperNavigation = {
  make: WfWatcherStepper$UpperNavigation,
  Jsx2: Jsx2
};

function WfWatcherStepper$BottomNavigation(Props) {
  var steps = Props.steps;
  var step = Props.step;
  var onNext = Props.onNext;
  var onPrev = Props.onPrev;
  var onSubmit = Props.onSubmit;
  var nextStep = next$1(steps, step);
  var prevStep = prev(steps, step);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* flexEnd */924268066),
                      /* :: */[
                        Css.padding3(/* `px */[
                              25096,
                              16
                            ], /* `px */[
                              25096,
                              12
                            ], /* `px */[
                              25096,
                              8
                            ]),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* [] */0
                      ])
                }, prevStep !== undefined ? React.createElement(Button.default, {
                        color: "secondary",
                        onClick: (function (ev) {
                            return Curry._2(onPrev, ev, prevStep);
                          }),
                        children: I18N$BsConsole.show(undefined, "Back")
                      }) : null, nextStep !== undefined ? React.createElement(Button.default, {
                        variant: "raised",
                        color: "secondary",
                        className: Css.style(/* :: */[
                              Css.marginLeft(/* `px */[
                                    25096,
                                    8
                                  ]),
                              /* [] */0
                            ]),
                        onClick: (function (ev) {
                            return Curry._2(onNext, ev, nextStep);
                          }),
                        children: I18N$BsConsole.show(undefined, "Next")
                      }) : React.createElement(Button.default, {
                        variant: "raised",
                        color: "secondary",
                        className: Css.style(/* :: */[
                              Css.marginLeft(/* `px */[
                                    25096,
                                    8
                                  ]),
                              /* [] */0
                            ]),
                        onClick: onSubmit,
                        children: I18N$BsConsole.show(undefined, "Submit")
                      })));
}

var component$1 = ReasonReact.statelessComponent("WfWatcherStepper-BsConsole");

function make$2(steps, step, onNext, onPrev, onSubmit, children) {
  return ReasonReactCompat.wrapReactForReasonReact(WfWatcherStepper$BottomNavigation, {
              steps: steps,
              step: step,
              onNext: onNext,
              onPrev: onPrev,
              onSubmit: onSubmit
            }, children);
}

var Jsx2$1 = {
  component: component$1,
  make: make$2
};

var BottomNavigation = {
  make: WfWatcherStepper$BottomNavigation,
  Jsx2: Jsx2$1
};

exports.Step = Step$1;
exports.Steps = Steps;
exports.UpperNavigation = UpperNavigation;
exports.BottomNavigation = BottomNavigation;
/* component Not a pure module */
