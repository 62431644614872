// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");

function useKeyUp(cb) {
  React.useEffect((function () {
          var $$document$1 = document;
          var keyUp = {
            contents: true
          };
          var keyDownCb = function (e) {
            var match = e.which;
            switch (match) {
              case 33 :
              case 34 :
              case 35 :
              case 36 :
              case 37 :
              case 39 :
                  break;
              case 32 :
              case 38 :
              case 40 :
                  e.preventDefault();
                  break;
              default:
                
            }
            if (keyUp.contents) {
              keyUp.contents = false;
              return Curry._1(cb, e);
            }
            
          };
          var keyUpCb = function (param) {
            keyUp.contents = true;
            
          };
          $$document$1.addEventListener("keydown", keyDownCb);
          $$document$1.addEventListener("keyup", keyUpCb);
          return (function (param) {
                    $$document$1.removeEventListener("keydown", keyDownCb);
                    $$document$1.removeEventListener("keyup", keyUpCb);
                    
                  });
        }), ([]));
  
}

function formatFingerprint(fingerprint) {
  var zeroGroup = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{64}$").exec(fingerprint)));
  var hasZeros = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{56}.+$").exec(fingerprint)));
  var isGroup = hasZeros && !zeroGroup;
  if (isGroup) {
    return fingerprint.slice(57);
  }
  var param = 7;
  return fingerprint.slice(0, param);
}

var missingCfiText = I18N$BsConsole.get(undefined, "Due to missing symbols or stack corruption, Backtrace used various heuristics to scan for this frame. In some cases, a guessed frame will be inaccurate. Uploading the required file can help mitigate this problem in absence of stack corruption.");

function isFrameMissingCfi(frameMissingSymbols, guessedFrame) {
  if (frameMissingSymbols !== undefined || guessedFrame === undefined) {
    return false;
  } else {
    return guessedFrame;
  }
}

function hasSomeGuessedInfo(info) {
  var match = info.debug_file;
  var match$1 = info.debug_identifier;
  var match$2 = info.code_identifier;
  if (match !== undefined || match$1 !== undefined) {
    return true;
  } else {
    return match$2 !== undefined;
  }
}

exports.useKeyUp = useKeyUp;
exports.formatFingerprint = formatFingerprint;
exports.missingCfiText = missingCfiText;
exports.isFrameMissingCfi = isFrameMissingCfi;
exports.hasSomeGuessedInfo = hasSomeGuessedInfo;
/* missingCfiText Not a pure module */
