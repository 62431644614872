// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function getUniqueKey(projectName, key) {
  return projectName + ("__" + key);
}

function remove(projectName, key) {
  window.sessionStorage.removeItem(getUniqueKey(projectName, key));
  
}

function set(projectName, key, value) {
  window.sessionStorage.setItem(getUniqueKey(projectName, key), value);
  
}

function get(projectName, key) {
  return Caml_option.nullable_to_opt(window.sessionStorage.getItem(getUniqueKey(projectName, key)));
}

exports.getUniqueKey = getUniqueKey;
exports.remove = remove;
exports.set = set;
exports.get = get;
/* No side effect */
