// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Colors$BsConsole = require("../Colors.js");

var portalRef = {
  contents: undefined
};

function fromSynthetic(e) {
  return Object.assign({ }, e);
}

var component = RR$BsConsole.reducerComponent("Tooltip-BsConsole__Proto");

function make(x, y, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var ww = window.innerWidth;
              var scrollY = window.scrollY;
              var match;
              if (param.state.elementDimensions !== undefined) {
                var doesOverflowRight = ((x + 12 | 0) + 200 | 0) > ww;
                match = /* tuple */[
                  doesOverflowRight ? (x - 12 | 0) - 200 | 0 : x + 12 | 0,
                  (y + 12 | 0) + (scrollY | 0) | 0
                ];
              } else {
                match = /* tuple */[
                  x + 12 | 0,
                  (y + 12 | 0) + (scrollY | 0) | 0
                ];
              }
              var style = {
                left: String(match[0]) + "px",
                top: String(match[1]) + "px"
              };
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Portal.Jsx2.make, undefined, undefined, /* `Callback */[
                              -659008027,
                              (function (param) {
                                  return portalRef.contents;
                                })
                            ], [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.merge(/* :: */[
                                            Css.style(/* :: */[
                                                  Css.position(/* absolute */-1013592457),
                                                  /* :: */[
                                                    Css.padding2(Css.px(4), Css.px(8)),
                                                    /* :: */[
                                                      Css.zIndex(2147483647),
                                                      /* :: */[
                                                        Css.pointerEvents(/* none */-922086728),
                                                        /* :: */[
                                                          Css.backgroundColor(Css.hex(Colors$BsConsole.grey35)),
                                                          /* :: */[
                                                            Css.opacity(0.9),
                                                            /* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.white)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]),
                                            /* :: */[
                                              "tooltip",
                                              /* [] */0
                                            ]
                                          ]), Caml_option.some(style), undefined, 8, [ReactDOMRe.createElementVariadic("div", {
                                              ref: (function (ref) {
                                                  return Curry._1(send, /* RootElementRef */[ref]);
                                                })
                                            }, children)]))]));
            }),
          initialState: (function (param) {
              return {
                      elementDimensions: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var ref = action[0];
              var match = state.elementDimensions;
              if (match !== undefined) {
                return /* NoUpdate */0;
              }
              if (ref == null) {
                return /* NoUpdate */0;
              }
              var dims = ref.getBoundingClientRect();
              return /* Update */Block.__(0, [{
                          elementDimensions: /* tuple */[
                            dims.width + 12 | 0,
                            dims.height + 12 | 0
                          ]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Proto = {
  portalRef: portalRef,
  fromSynthetic: fromSynthetic,
  component: component,
  make: make
};

var portalRef$1 = {
  contents: undefined
};

function fromSynthetic$1(e) {
  return Object.assign({ }, e);
}

var component$1 = RR$BsConsole.reducerComponent("Tooltip-BsConsole");

function make$1(e, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var listener = function (e) {
                return Curry._1(send, /* UpdateCoords */Block.__(0, [
                              e.pageX,
                              e.pageY
                            ]));
              };
              e.target.addEventListener("mousemove", listener);
              return Curry._1(param.onUnmount, (function (param) {
                            return e.target.removeEventListener("mousemove", listener);
                          }));
            }),
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var elementDimensions = state.elementDimensions;
              var top = state.top;
              var left = state.left;
              var send = param.send;
              var ww = window.innerWidth - 50 | 0;
              var scrollY = window.scrollY;
              var match;
              if (elementDimensions !== undefined) {
                var height = elementDimensions[1];
                var width = elementDimensions[0];
                var doesOverflowRight = ((left + 12 | 0) + width | 0) > ww;
                var doesOverflowTop = ((top - 12 | 0) - height | 0) < (scrollY | 0);
                match = /* tuple */[
                  doesOverflowRight ? (left - 12 | 0) - width | 0 : left + 12 | 0,
                  doesOverflowTop ? top + 12 | 0 : (top - 12 | 0) - height | 0
                ];
              } else {
                match = /* tuple */[
                  left + 12 | 0,
                  top - 12 | 0
                ];
              }
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Portal.Jsx2.make, undefined, undefined, /* `Callback */[
                              -659008027,
                              (function (param) {
                                  return portalRef$1.contents;
                                })
                            ], [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, "tooltip", {
                                        left: String(match[0]) + "px",
                                        padding: "6px",
                                        position: "absolute",
                                        top: String(match[1]) + "px",
                                        zIndex: "2147483647",
                                        pointerEvents: "none"
                                      }, undefined, 8, [ReactDOMRe.createElementVariadic("div", {
                                              ref: (function (ref) {
                                                  return Curry._1(send, /* RootElementRef */Block.__(1, [ref]));
                                                })
                                            }, children)]))]));
            }),
          initialState: (function (param) {
              return {
                      left: e.pageX,
                      top: e.pageY,
                      elementDimensions: undefined
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (!action.tag) {
                return /* Update */Block.__(0, [{
                            left: action[0],
                            top: action[1],
                            elementDimensions: state.elementDimensions
                          }]);
              }
              var ref = action[0];
              var match = state.elementDimensions;
              if (match !== undefined) {
                return /* NoUpdate */0;
              }
              if (ref == null) {
                return /* NoUpdate */0;
              }
              var dims = ref.getBoundingClientRect();
              return /* Update */Block.__(0, [{
                          left: state.left,
                          top: state.top,
                          elementDimensions: /* tuple */[
                            dims.width + 12 | 0,
                            dims.height + 12 | 0
                          ]
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.Proto = Proto;
exports.portalRef = portalRef$1;
exports.fromSynthetic = fromSynthetic$1;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
