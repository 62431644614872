// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("../bs-crdb-response/src/Parse.js");
var Route$BsConsole = require("../route.js");
var Theme$BsConsole = require("../Theme.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var UiStats$BsConsole = require("../UiStats.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var AggregationRender$BsConsole = require("../AggregationRender.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var QbExpandedAggregation$BsConsole = require("../query-builder/QbExpandedAggregation.js");
var ContainerWithDimensions$BsConsole = require("../ContainerWithDimensions.js");

var component = ReasonReact.statelessComponent("AttributeDetailCard-BsConsole");

function make(attribute, config, aperture, stats, projectName, handleChangeUrl, getter, handleAddFilters, _cardWidthOpt, factor, token, group, handleTask, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var maybeUnique = Curry._2(getter, attribute, /* Unique */0);
              var v = Group$BsConsole.unwrapUniqueCount(maybeUnique);
              var maybeMean = Curry._2(getter, attribute, /* Mean */6);
              var v$1 = Group$BsConsole.unwrapMean(maybeMean);
              var maybeSum = Curry._2(getter, attribute, /* Sum */7);
              var v$2 = Group$BsConsole.unwrapSum(maybeSum);
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.minWidth(Css.px(300)),
                                  /* :: */[
                                    Css.width(Css.pct(33.33)),
                                    /* [] */0
                                  ]
                                ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                            Css.padding(Css.px(12)),
                                            /* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                                              /* :: */[
                                                Css.marginRight(Css.rem(1)),
                                                /* :: */[
                                                  Css.marginBottom(Css.rem(1)),
                                                  /* :: */[
                                                    Css.height(Css.px(235)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]), undefined, undefined, 0, [
                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [
                                                  ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(attribute)])),
                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                            Css.style(/* :: */[
                                                                  Css.padding(Css.px(5)),
                                                                  /* [] */0
                                                                ]),
                                                            undefined,
                                                            (function (e) {
                                                                e.stopPropagation();
                                                                var inboxParams = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                                                var attrsToSave = Belt_List.keep(stats, (function (s) {
                                                                        return s !== attribute;
                                                                      }));
                                                                return UserFrontendSettings$BsConsole.getParsedSettingsFromBpg(token, config.user.uid, (function (settings) {
                                                                              if (settings !== undefined) {
                                                                                var configAttrs = Belt_Option.getWithDefault(settings.fingerprintDetailsAttrs, /* [] */0);
                                                                                var newUFSAttrs = Belt_List.keep(configAttrs, (function (s) {
                                                                                        return s !== attribute;
                                                                                      }));
                                                                                return UiStats$BsConsole.updateUserFrontendSettingsFingerprintDetailsAttrs(token, config, newUFSAttrs, (function (param) {
                                                                                              return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                                                            projectName,
                                                                                                            {
                                                                                                              aperture: aperture,
                                                                                                              sort: inboxParams.sort,
                                                                                                              stats: attrsToSave,
                                                                                                              havings: inboxParams.havings,
                                                                                                              fold: inboxParams.fold,
                                                                                                              normBy: inboxParams.normBy,
                                                                                                              similarity: inboxParams.similarity
                                                                                                            },
                                                                                                            undefined
                                                                                                          ]));
                                                                                            }));
                                                                              }
                                                                              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "AttributeDetailCard/UserFrontendSettings.getParsedSettingsFromBpg", Backtrace$BsConsole.Attributes.make(undefined));
                                                                              Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                                    288368849,
                                                                                    "User hit None when trying to get user frontend settings"
                                                                                  ]);
                                                                              return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                                            projectName,
                                                                                            {
                                                                                              aperture: aperture,
                                                                                              sort: inboxParams.sort,
                                                                                              stats: attrsToSave,
                                                                                              havings: inboxParams.havings,
                                                                                              fold: inboxParams.fold,
                                                                                              normBy: inboxParams.normBy,
                                                                                              similarity: inboxParams.similarity
                                                                                            },
                                                                                            undefined
                                                                                          ]));
                                                                            }));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            "Remove attribute from stats",
                                                            [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                            className: Styles$BsConsole.$$Text.tooltipTitle
                                                                          }, I18N$BsConsole.show(undefined, "Remove attribute from stats.")), undefined, undefined, undefined, undefined, Caml_option.some(Theme$BsConsole.main), [React.createElement("span", {
                                                                              className: Css.style(/* :: */[
                                                                                    Css.color(Css.hex(Colors$BsConsole.black)),
                                                                                    /* :: */[
                                                                                      Css.fontSize(Css.px(0)),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]) + " extra-smaller-icon"
                                                                            }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]))]
                                                          ]))
                                                ])),
                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                  v !== undefined ? ReasonReact.element(undefined, undefined, AggregationRender$BsConsole.UniqueForDetail.make(undefined, false, attribute, v | 0, [])) : null,
                                                  v$1 !== undefined ? ReasonReact.element(undefined, undefined, AggregationRender$BsConsole.AttrForDetail.make(I18N$BsConsole.get(undefined, "Mean"), undefined, false, attribute, v$1, handleChangeUrl, handleAddFilters, [])) : null,
                                                  v$2 !== undefined ? ReasonReact.element(undefined, undefined, AggregationRender$BsConsole.AttrForDetail.make(I18N$BsConsole.get(undefined, "Sum"), undefined, false, attribute, v$2, handleChangeUrl, handleAddFilters, [])) : null
                                                ])),
                                        ReasonReact.element(undefined, undefined, ContainerWithDimensions$BsConsole.make(undefined, (function (width, param) {
                                                    var maybeBin = Curry._2(getter, attribute, /* Bin */2);
                                                    var bin = Group$BsConsole.unwrapBin(maybeBin);
                                                    var maybeDistribution = Curry._2(getter, attribute, /* Distribution */1);
                                                    var _distribution = Group$BsConsole.unwrapDistribution(maybeDistribution);
                                                    return React.createElement(React.Fragment, undefined, bin !== undefined ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                              Css.marginTop(Css.px(0)),
                                                                              /* [] */0
                                                                            ]), [ReasonReact.element(undefined, undefined, AggregationRender$BsConsole.BinForDetail.make(bin, width, attribute, handleAddFilters, handleChangeUrl, factor, aperture, token, projectName, group, handleTask, []))])) : null, _distribution !== undefined ? ReasonReact.element(undefined, undefined, QbExpandedAggregation$BsConsole.Fetch.make(handleTask, token, projectName, /* Aggregate */Block.__(0, [
                                                                            aperture,
                                                                            undefined,
                                                                            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                                      attribute,
                                                                                      /* Distribution */Block.__(1, [3])
                                                                                    ]), Crdb$BsConsole.Fold.empty),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined
                                                                          ]), (function (remote2) {
                                                                            if (typeof remote2 === "number") {
                                                                              if (remote2 === /* NotAsked */0) {
                                                                                return null;
                                                                              } else {
                                                                                return React.createElement("div", {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.width(Css.pct(100)),
                                                                                                  /* :: */[
                                                                                                    Css.height(Css.pct(100)),
                                                                                                    /* :: */[
                                                                                                      Css.display(/* flex */-1010954439),
                                                                                                      /* :: */[
                                                                                                        Css.justifyContent(/* center */98248149),
                                                                                                        /* :: */[
                                                                                                          Css.marginTop(Css.px(45)),
                                                                                                          /* [] */0
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ])
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])));
                                                                              }
                                                                            }
                                                                            if (remote2.tag) {
                                                                              return I18N$BsConsole.show(undefined, "Failure fetching data.");
                                                                            }
                                                                            var json = remote2[0];
                                                                            var columns = Parse$BsConsole.parseColumns(json);
                                                                            var maybeGroup = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
                                                                                      Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor),
                                                                                      undefined
                                                                                    ], columns, json));
                                                                            if (maybeGroup === undefined) {
                                                                              return I18N$BsConsole.show(undefined, "No group.");
                                                                            }
                                                                            var getter = function (param, param$1) {
                                                                              return Group$BsConsole.getAggregation(columns, maybeGroup, param, param$1);
                                                                            };
                                                                            var maybeDistribution = Group$BsConsole.unwrapDistribution(getter(attribute, /* Distribution */1));
                                                                            if (maybeDistribution !== undefined) {
                                                                              return React.createElement("div", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.marginTop(Css.px(10)),
                                                                                                /* [] */0
                                                                                              ])
                                                                                        }, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])), ReasonReact.element(undefined, undefined, AggregationRender$BsConsole.DistributionForDetail.make(attribute, maybeDistribution, handleAddFilters, handleChangeUrl, width, factor, aperture, token, projectName, maybeGroup, handleTask, [])));
                                                                            } else {
                                                                              return I18N$BsConsole.show(undefined, "No distribution.");
                                                                            }
                                                                          }))) : null);
                                                  })))
                                      ]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
