// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");

function decodeAttributeExn(json) {
  return {
          custom: Json_decode.field("custom", Json_decode.bool, json),
          aggregate: Json_decode.field("aggregate", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          description: Json_decode.field("description", Json_decode.string, json),
          filter: Json_decode.field("filter", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          format: Json_decode.field("format", Json_decode.string, json),
          group: Json_decode.field("group", Json_decode.bool, json),
          nestedIssues: InboxUtils$BsConsole.isNestedIssues(Json_decode.field("name", Json_decode.string, json)),
          name: Json_decode.field("name", Json_decode.string, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          statistics: Json_decode.withDefault({
                used: false
              }, (function (param) {
                  return Json_decode.field("statistics", (function (json) {
                                return {
                                        used: Json_decode.field("used", Json_decode.bool, json)
                                      };
                              }), param);
                }), json)
        };
}

function to_js(d) {
  return {
          custom: d.custom,
          aggregate: Belt_List.toArray(d.aggregate),
          description: d.description,
          filter: Belt_List.toArray(d.filter),
          format: d.format,
          group: d.group,
          nestedIssues: d.nestedIssues,
          name: d.name,
          type_: d.type_,
          statistics: {
            used: d.statistics.used
          }
        };
}

function of_js(r) {
  return {
          custom: r.custom,
          aggregate: Belt_List.fromArray(r.aggregate),
          description: r.description,
          filter: Belt_List.fromArray(r.filter),
          format: r.format,
          group: r.group,
          nestedIssues: r.nestedIssues,
          name: r.name,
          type_: r.type_,
          statistics: {
            used: r.statistics.used
          }
        };
}

var blackList = /* :: */[
  "fingerprint;issues;fingerprint",
  /* [] */0
];

function decodeExn(json) {
  return Belt_List.keep(Json_decode.field("describe", (function (param) {
                    return Json_decode.list(decodeAttributeExn, param);
                  }), json), (function (param) {
                var name = param.name;
                return Belt_Option.isNone(Belt_List.getBy(blackList, (function (i) {
                                  return i === name;
                                })));
              }));
}

exports.decodeAttributeExn = decodeAttributeExn;
exports.to_js = to_js;
exports.of_js = of_js;
exports.blackList = blackList;
exports.decodeExn = decodeExn;
/* No side effect */
