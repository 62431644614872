// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactCopyToClipboard = require("react-copy-to-clipboard");

function make(text, onCopy, children) {
  return ReasonReact.wrapJsForReason(ReactCopyToClipboard.CopyToClipboard, {
              text: text,
              onCopy: onCopy
            }, children);
}

exports.make = make;
/* ReasonReact Not a pure module */
