// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");

var resource = "alert_evaluations";

function errFromJson(json) {
  return {
          message: Json_decode.field("message", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.$$int, json)
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          uuid: Json_decode.field("uuid", Json_decode.string, json),
          alertId: Json_decode.field("alert_id", Json_decode.string, json),
          evaluatedAt: Json_decode.field("evaluated_at", Json_decode.$$float, json),
          evaluationStart: Json_decode.field("evaluation_start", Json_decode.$$float, json),
          evaluationEnd: Json_decode.field("evaluation_end", Json_decode.$$float, json),
          query: Json_decode.field("query", Json_decode.string, json),
          exampleGroups: Json_decode.field("example_groups", Json_decode.string, json),
          error: Json_decode.field("error", (function (param) {
                  return Json_decode.optional(errFromJson, param);
                }), json),
          warningTriggerCount: Json_decode.field("warning_trigger_count", Json_decode.$$int, json),
          criticalTriggerCount: Json_decode.field("critical_trigger_count", Json_decode.$$int, json),
          warningGroupCount: Json_decode.field("warning_group_count", Json_decode.$$int, json),
          criticalGroupCount: Json_decode.field("critical_group_count", Json_decode.$$int, json)
        };
}

function evalResponseFromJson(json) {
  return {
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.array(fromJson, param);
                }), json),
          nextPageToken: Json_decode.field("next_page_token", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function get(path, universeName, projectName, alertId, limitOpt, evalStartOpt, evalEndOpt, pageToken, param) {
  var limit = limitOpt !== undefined ? limitOpt : 50;
  var evalStart = evalStartOpt !== undefined ? evalStartOpt : 0.0;
  var evalEnd = evalEndOpt !== undefined ? evalEndOpt : 0.0;
  return Task2$BsConsole.make(/* Custom */Block.__(1, [encodeURI("" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + ("&alert_id=" + (String(alertId) + ("&limit=" + (String(limit) + ("&evaluation_start=" + (String(evalStart) + ("&evaluation_end=" + (String(evalEnd) + ""))))))))))))))) + (
                      pageToken !== undefined ? "&page_token=" + (String(pageToken) + "") : ""
                    ))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [evalResponseFromJson(respBag.json)]);
              }), undefined, undefined);
}

function getById(path, universeName, projectName, id, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [encodeURI("" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + ""))))))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [fromJson(respBag.json)]);
              }), undefined, undefined);
}

function getAllPages(token, path, universeName, projectName, alertId, evalStartOpt, evalEndOpt, resultsOpt, error, nextPageToken, firstCallOpt, cb, param) {
  var evalStart = evalStartOpt !== undefined ? evalStartOpt : 0.0;
  var evalEnd = evalEndOpt !== undefined ? evalEndOpt : 0.0;
  var results = resultsOpt !== undefined ? resultsOpt : [];
  var firstCall = firstCallOpt !== undefined ? firstCallOpt : true;
  if (nextPageToken !== undefined) {
    var arg = get(path, universeName, projectName, alertId, 50, evalStart, evalEnd, nextPageToken, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return Curry._2(cb, Caml_option.some(resp[0]), results);
                  }
                  var result = resp[0];
                  return getAllPages(token, path, universeName, projectName, alertId, evalStart, evalEnd, Belt_Array.concat(results, result.values), undefined, result.nextPageToken, false, cb, undefined);
                }));
  }
  if (!firstCall) {
    return Curry._2(cb, error, results);
  }
  var arg$1 = get(path, universeName, projectName, alertId, 50, evalStart, evalEnd, undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return Curry._2(cb, Caml_option.some(resp[0]), results);
                }
                var result = resp[0];
                return getAllPages(token, path, universeName, projectName, alertId, evalStart, evalEnd, Belt_Array.concat(results, result.values), undefined, result.nextPageToken, false, cb, undefined);
              }));
}

exports.resource = resource;
exports.errFromJson = errFromJson;
exports.fromJson = fromJson;
exports.evalResponseFromJson = evalResponseFromJson;
exports.get = get;
exports.getById = getById;
exports.getAllPages = getAllPages;
/* Task2-BsConsole Not a pure module */
