// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var _baseUriRef = {
  contents: "https://metrics.backtrace.io/api/hearse/metrics"
};

function setBaseUri(uri) {
  if (uri !== undefined) {
    _baseUriRef.contents = uri + "/metrics";
    return ;
  }
  
}

function getBaseUri(param) {
  return _baseUriRef.contents;
}

function toUrlEncode(uri) {
  return new Buffer(uri).toString("base64");
}

exports._baseUriRef = _baseUriRef;
exports.setBaseUri = setBaseUri;
exports.getBaseUri = getBaseUri;
exports.toUrlEncode = toUrlEncode;
/* No side effect */
