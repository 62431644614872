// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var ButtonBase = require("@material-ui/core/ButtonBase");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

function toString(param) {
  if (param) {
    return "timestamp-max";
  } else {
    return "timestamp-min";
  }
}

function fromString(param) {
  switch (param) {
    case "timestamp-max" :
        return /* TimestampMax */1;
    case "timestamp-min" :
        return /* TimestampMin */0;
    default:
      return ;
  }
}

function fromSort(sort) {
  if (sort.tag !== /* Range */4) {
    return ;
  }
  if (sort[0] !== "timestamp") {
    return ;
  }
  var ordering = sort[2];
  if (sort[1]) {
    return /* tuple */[
            /* TimestampMax */1,
            ordering
          ];
  } else {
    return /* tuple */[
            /* TimestampMin */0,
            ordering
          ];
  }
}

function toSort(attribute, ordering) {
  if (attribute) {
    return /* Range */Block.__(4, [
              "timestamp",
              /* Finish */1,
              ordering
            ]);
  } else {
    return /* Range */Block.__(4, [
              "timestamp",
              /* Start */0,
              ordering
            ]);
  }
}

var menuList = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.focus(/* :: */[
              Css.unsafe("outline", "none"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* :: */[
        Css.unsafe("height", "unset"),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.0
                ]),
            /* :: */[
              Css.hover(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.white)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.focus(/* :: */[
                      Css.unsafe("outline", "none"),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.white)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var button = Css.style(/* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            500
          ]),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.padding(Css.px(4)),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.borderRadius(Css.px(3)),
              /* :: */[
                Css.textAlign(/* left */-944764921),
                /* :: */[
                  Css.justifyContent(/* flexStart */662439529),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  menuList: menuList,
  menuItem: menuItem,
  button: button
};

function ActivitySortHeader(Props) {
  var handleSetSort = Props.handleSetSort;
  var sort = Props.sort;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = fromSort(sort);
  var tmp;
  if (match$1 !== undefined) {
    var ordering = match$1[1];
    var match$2 = match$1[0] ? /* tuple */[
        I18N$BsConsole.get(undefined, "Most recent activity"),
        ordering
      ] : /* tuple */[
        I18N$BsConsole.get(undefined, "Earliest activity"),
        ordering === /* Ascending */0 ? /* Descending */1 : /* Ascending */0
      ];
    tmp = React.createElement(Row2$BsConsole.make, {
          alignItems: /* center */98248149,
          children: null
        }, React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontSize(Css.px(16)),
                    /* :: */[
                      Css.height(Css.px(16)),
                      /* :: */[
                        Css.width(Css.px(16)),
                        /* :: */[
                          Css.margin2(Css.zero, Css.px(4)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, match$2[1] ? React.createElement(ArrowDownward.default, {
                    fontSize: "inherit"
                  }) : React.createElement(ArrowUpward.default, {
                    fontSize: "inherit"
                  })), React.createElement("div", undefined, I18N$BsConsole.dynamic(match$2[0])));
  } else {
    tmp = I18N$BsConsole.show(undefined, "Activity");
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  children: React.createElement(ButtonBase.default, {
                        classes: {
                          root: Css.merge(/* :: */[
                                button,
                                /* :: */[
                                  className,
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function ($$event) {
                            var el = $$event.currentTarget;
                            if (!(el == null)) {
                              return Curry._1(setAnchorEl, (function (param) {
                                            return Caml_option.some(el);
                                          }));
                            }
                            
                          }),
                        disableRipple: true,
                        children: tmp
                      }),
                  title: I18N$BsConsole.get(undefined, "Sort by activity")
                }), anchorEl !== undefined ? React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    className: Css.style(/* :: */[
                          Css.zIndex(10000),
                          /* [] */0
                        ]),
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    children: React.createElement(MenuList.default, {
                          autoFocus: false,
                          autoFocusItem: false,
                          classes: {
                            root: menuList
                          },
                          children: null
                        }, React.createElement(MenuItem.default, {
                              disableRipple: true,
                              classes: {
                                root: menuItem
                              },
                              onClick: (function (param) {
                                  Curry._1(handleSetSort, /* Range */Block.__(4, [
                                          "timestamp",
                                          /* Finish */1,
                                          /* Descending */1
                                        ]));
                                  return Curry._1(setAnchorEl, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    children: I18N$BsConsole.show(undefined, "Most recent activity")
                                  })
                            }), React.createElement(MenuItem.default, {
                              disableRipple: true,
                              classes: {
                                root: menuItem
                              },
                              onClick: (function (param) {
                                  Curry._1(handleSetSort, /* Range */Block.__(4, [
                                          "timestamp",
                                          /* Start */0,
                                          /* Ascending */0
                                        ]));
                                  return Curry._1(setAnchorEl, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    children: I18N$BsConsole.show(undefined, "Earliest activity")
                                  })
                            }))
                  }) : null);
}

var make = ActivitySortHeader;

exports.toString = toString;
exports.fromString = fromString;
exports.fromSort = fromSort;
exports.toSort = toSort;
exports.Style = Style;
exports.make = make;
/* menuList Not a pure module */
