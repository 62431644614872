// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var BtTable$BsConsole = require("./BtTable.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var PlayCards$BsConsole = require("./playground/PlayCards.js");
var BtTableRow$BsConsole = require("./BtTableRow.js");
var PlayModals$BsConsole = require("./playground/PlayModals.js");
var BtTableBody$BsConsole = require("./BtTableBody.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var BtTableHead$BsConsole = require("./BtTableHead.js");
var PlayBanners$BsConsole = require("./playground/PlayBanners.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var Popper = require("@material-ui/core/Popper");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var BtTableContainer$BsConsole = require("./BtTableContainer.js");
var PlayTypeaheadInput$BsConsole = require("./playground/PlayTypeaheadInput.js");

function UiPlayground$SimpleTable(Props) {
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(50)),
                      /* [] */0
                    ])
              },
              children: React.createElement(BtTableContainer$BsConsole.make, {
                    children: React.createElement(BtTable$BsConsole.make, {
                          children: null
                        }, React.createElement(BtTableHead$BsConsole.make, {
                              children: React.createElement(BtTableRow$BsConsole.make, {
                                    children: null
                                  }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 1")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 2")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 3")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 4")
                                      }))
                            }), React.createElement(BtTableBody$BsConsole.make, {
                              children: null
                            }, React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }))))
                  })
            });
}

var SimpleTable = {
  make: UiPlayground$SimpleTable
};

function UiPlayground$SimpleTableWithGrid(Props) {
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(50)),
                      /* [] */0
                    ])
              },
              children: React.createElement(BtTableContainer$BsConsole.make, {
                    children: React.createElement(BtTable$BsConsole.make, {
                          showGrid: true,
                          children: null
                        }, React.createElement(BtTableHead$BsConsole.make, {
                              children: React.createElement(BtTableRow$BsConsole.make, {
                                    children: null
                                  }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 1")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 2")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 3")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 4")
                                      }))
                            }), React.createElement(BtTableBody$BsConsole.make, {
                              children: null
                            }, React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    })), React.createElement(BtTableRow$BsConsole.make, {
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }))))
                  })
            });
}

var SimpleTableWithGrid = {
  make: UiPlayground$SimpleTableWithGrid
};

function UiPlayground$SimpleTableWithExpandingRow(Props) {
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(50)),
                      /* [] */0
                    ])
              },
              children: React.createElement(BtTableContainer$BsConsole.make, {
                    children: React.createElement(BtTable$BsConsole.make, {
                          children: null
                        }, React.createElement(BtTableHead$BsConsole.make, {
                              children: React.createElement(BtTableRow$BsConsole.make, {
                                    children: null
                                  }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                        className: Css.style(/* :: */[
                                              Css.width(Css.px(48)),
                                              /* [] */0
                                            ])
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 1")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 2")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 3")
                                      }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                        children: I18N$BsConsole.show(undefined, "Header 4")
                                      }))
                            }), React.createElement(BtTableBody$BsConsole.make, {
                              children: null
                            }, React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                  expandedContent: React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.rem(0.5)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "lol whatcha looking at?")),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "lol")
                                    })), React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                  expandedContent: React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.rem(0.5)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "lol whatcha looking at?")),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "wat")
                                    })), React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                  expandedContent: React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.rem(0.5)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "lol whatcha looking at?")),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "alright")
                                    })), React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                  expandedContent: React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.rem(0.5)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "lol whatcha looking at?")),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      children: I18N$BsConsole.show(undefined, "k bye")
                                    })), React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                  expandedContent: React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.margin(Css.rem(0.5)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "lol whatcha looking at?")),
                                  children: null
                                }, React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                      title: I18N$BsConsole.get(undefined, "This is a long sentance to display ellipsis on overflow"),
                                      children: I18N$BsConsole.show(undefined, "This is a long sentance to display ellipsis on overflow")
                                    }))))
                  })
            });
}

var SimpleTableWithExpandingRow = {
  make: UiPlayground$SimpleTableWithExpandingRow
};

var columns = [
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Username"), "username", (function (row) {
          return /* `String */[
                  -976970511,
                  row.username
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Email"), "email", (function (row) {
          return /* `String */[
                  -976970511,
                  row.email
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Auth method"), "method_", (function (row) {
          return /* `String */[
                  -976970511,
                  row.method_
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Role"), "role", (function (row) {
          return /* `String */[
                  -976970511,
                  Belt_Option.getWithDefault(row.role, "")
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Superuser"), "superuser", (function (row) {
          var match = row.superuser;
          if (match !== 0) {
            if (match !== 1) {
              return /* `String */[
                      -976970511,
                      ""
                    ];
            } else {
              return /* `String */[
                      -976970511,
                      "true"
                    ];
            }
          } else {
            return /* `String */[
                    -976970511,
                    "false"
                  ];
          }
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined)
];

function UiPlayground$EnhancedTableBPG(Props) {
  var match = React.useState((function () {
          
        }));
  var setHoveredElement = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setHoveredItem = match$1[1];
  var hoveredItem = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setSortingState = match$2[1];
  var sortingState = match$2[0];
  var match$3 = React.useState((function () {
          return [];
        }));
  var setSelectedUsers = match$3[1];
  var selectedUsers = match$3[0];
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var rows;
  rows = typeof allUsersRemote === "number" ? [] : (
      allUsersRemote.tag ? [] : allUsersRemote[0][0]
    );
  var sortedRows = sortingState !== undefined ? BtEnhancedTable$BsConsole.defaultRowSort(rows, columns, sortingState) : rows;
  var hoverPopover = function ($$event, row) {
    var domElem = $$event.currentTarget;
    console.log(/* tuple */[
          "setting user" + row.username,
          domElem
        ]);
    Curry._1(setHoveredItem, (function (param) {
            return row;
          }));
    return Curry._1(setHoveredElement, (function (param) {
                  if (domElem == null) {
                    return ;
                  } else {
                    return Caml_option.some(domElem);
                  }
                }));
  };
  var clearPopover = function (param, param$1) {
    Curry._1(setHoveredItem, (function (param) {
            
          }));
    return Curry._1(setHoveredElement, (function (param) {
                  
                }));
  };
  var tmp = {
    columns: columns,
    rows: sortedRows,
    getRowId: (function (row) {
        return row.username;
      }),
    onSortChange: (function (sortingState) {
        return Curry._1(setSortingState, (function (param) {
                      return sortingState;
                    }));
      }),
    onSelectedChange: (function (selected) {
        console.log(/* tuple */[
              "selected",
              selected
            ]);
        return Curry._1(setSelectedUsers, (function (param) {
                      return selected;
                    }));
      }),
    selected: selectedUsers,
    onRowEnter: hoverPopover,
    onRowLeave: clearPopover,
    resizeableColumns: true,
    fixedLeftColumns: [
      "username",
      "email"
    ],
    zebraStripe: true
  };
  if (sortingState !== undefined) {
    tmp.sortingState = Caml_option.valFromOption(sortingState);
  }
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(50)),
                      /* :: */[
                        Css.marginBottom(Css.rem(2)),
                        /* [] */0
                      ]
                    ])
              },
              children: null
            }, React.createElement(Popper.default, {
                  anchorEl: match[0],
                  open: Belt_Option.isSome(hoveredItem),
                  placement: "bottom",
                  children: React.createElement(Paper.default, {
                        children: hoveredItem !== undefined ? React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.padding(Css.px(25)),
                                      /* [] */0
                                    ])
                              }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "You're hovering on: ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ]),
                                        "You're hovering on: %s"
                                      ]), hoveredItem.username)) : null
                      })
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.margin(Css.px(10)),
                        /* [] */0
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading6 */5,
                      className: Css.style(/* :: */[
                            Css.margin(Css.rem(1)),
                            /* [] */0
                          ]),
                      children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "Selected usernames (",
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* Char_literal */Block.__(12, [
                                            /* ")" */41,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ]),
                                "Selected usernames (%d)"
                              ]), selectedUsers.length)
                    }), Belt_Array.map(selectedUsers, (function (rowid) {
                        return React.createElement("span", undefined, I18N$BsConsole.showSkip(rowid + " "));
                      }))), React.createElement(BtEnhancedTable$BsConsole.make, tmp));
}

var EnhancedTableBPG = {
  columns: columns,
  make: UiPlayground$EnhancedTableBPG
};

var typographyDemo = React.createElement("div", {
      className: Css.style(/* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
            /* :: */[
              Css.width(Css.vw(50)),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.flexDirection(/* row */5693978),
                  /* :: */[
                    Css.flexWrap(/* wrap */-822134326),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ])
    }, React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading1 */0,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading1")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading2 */1,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading2")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading3 */2,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading3")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading4 */3,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading4")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading5 */4,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading5")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Heading6 */5,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Heading6")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Subtitle1 */6,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Subtitle1")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Subtitle2 */7,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Subtitle2")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Body1 */8,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Body1")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Body2 */9,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Body2")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Button */10,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Button")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Caption */11,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Caption")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Overline */12,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Overline")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* TableTitle */13,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "TableTitle")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* TableBody */14,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "TableBody")
        }), React.createElement(BtTypography$BsConsole.make, {
          variant: /* Chip */15,
          className: Css.style(/* :: */[
                Css.margin(Css.rem(1)),
                /* [] */0
              ]),
          children: I18N$BsConsole.show(undefined, "Chip")
        }));

function UiPlayground(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
                    /* :: */[
                      Css.minHeight(Css.vh(100)),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* column */-963948842),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(PlayBanners$BsConsole.make, { }), React.createElement(PlayCards$BsConsole.DataCardExamples.make, { }), React.createElement(PlayCards$BsConsole.make, { }), React.createElement(PlayTypeaheadInput$BsConsole.make, { }), React.createElement(PlayModals$BsConsole.make, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "BtTypography")
                }), typographyDemo, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Simple table")
                }), React.createElement(UiPlayground$SimpleTable, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Simple table with grid")
                }), React.createElement(UiPlayground$SimpleTableWithGrid, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Simple table with expandable rows")
                }), React.createElement(UiPlayground$SimpleTableWithExpandingRow, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Enhanced BPG table")
                }), React.createElement(UiPlayground$EnhancedTableBPG, { }));
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(UiPlayground, { }, children);
}

var Re = {
  make: make
};

var make$1 = UiPlayground;

exports.SimpleTable = SimpleTable;
exports.SimpleTableWithGrid = SimpleTableWithGrid;
exports.SimpleTableWithExpandingRow = SimpleTableWithExpandingRow;
exports.EnhancedTableBPG = EnhancedTableBPG;
exports.typographyDemo = typographyDemo;
exports.make = make$1;
exports.Re = Re;
/* columns Not a pure module */
