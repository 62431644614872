// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var serverUrl = "https://billing.backtrace.io/api";

var stripePublishable = "pk_live_dq8n5DwG0WK1Trpbr5lBjOLE";

var stripePublishableTest = "pk_test_EglgttWYC7qRooVWAnpURwxk";

var nemesisUrl = "https://subscription-manager.backtrace.io/api/nemesis";

exports.serverUrl = serverUrl;
exports.stripePublishable = stripePublishable;
exports.stripePublishableTest = stripePublishableTest;
exports.nemesisUrl = nemesisUrl;
/* No side effect */
