// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Util$BsConsole = require("../util.js");
var WfWatcherStepper$BsConsole = require("./WfWatcherStepper.js");

function toType(param) {
  if (param === "connection-manager") {
    return /* ConnectionManager */0;
  } else {
    return /* IntegrationTable */1;
  }
}

function toString(param) {
  if (param) {
    return "integration-table";
  } else {
    return "connection-manager";
  }
}

var Referrer = {
  toType: toType,
  toString: toString
};

var FormState = { };

function decodeSearch(search) {
  var json = Util$BsConsole.Qs.parse(search);
  return Json_decode.optional((function (param) {
                return Json_decode.field("error", Json_decode.string, param);
              }), json);
}

var JiraServerError = {
  decodeSearch: decodeSearch
};

function pathToRoute(path, search) {
  if (!path) {
    return /* WatchersList */2;
  }
  switch (path[0]) {
    case "edit" :
        var match = path[1];
        if (!match) {
          return /* WatchersList */2;
        }
        var match$1 = match[1];
        var watcherName = match[0];
        if (match$1) {
          if (match$1[1]) {
            return /* WatchersList */2;
          } else {
            return /* WatcherEdit */Block.__(2, [
                      decodeURI(watcherName),
                      Belt_Option.getWithDefault(WfWatcherStepper$BsConsole.Step.fromString(match$1[0]), /* ConfigureConnection */1)
                    ]);
          }
        } else {
          return /* WatcherEdit */Block.__(2, [
                    decodeURI(watcherName),
                    /* ConfigureConnection */1
                  ]);
        }
    case "jira-cloud" :
        if (path[1]) {
          return /* WatchersList */2;
        } else {
          return /* JiraCloud */0;
        }
    case "jira-server" :
        var match$2 = path[1];
        if (!match$2) {
          return /* JiraServer */Block.__(0, [/* Default */0]);
        }
        var match$3 = match$2[1];
        var route = match$2[0];
        if (!match$3) {
          if (route === "create") {
            return /* JiraServer */Block.__(0, [/* Create */1]);
          } else {
            return /* WatchersList */2;
          }
        }
        if (match$3[1]) {
          return /* WatchersList */2;
        }
        var connectionId = match$3[0];
        if (route === "edit") {
          return /* JiraServer */Block.__(0, [/* Edit */Block.__(0, [
                        Caml_format.caml_int_of_string(connectionId),
                        decodeSearch(search)
                      ])]);
        } else if (route === "authorize") {
          return /* JiraServer */Block.__(0, [/* Authorize */Block.__(1, [Caml_format.caml_int_of_string(connectionId)])]);
        } else {
          return /* WatchersList */2;
        }
    case "new" :
        var match$4 = path[1];
        if (match$4) {
          if (match$4[1]) {
            return /* WatchersList */2;
          } else {
            return /* WatcherCreate */Block.__(1, [Belt_Option.getWithDefault(WfWatcherStepper$BsConsole.Step.fromString(match$4[0]), /* SelectIntegration */0)]);
          }
        } else {
          return /* WatcherCreate */Block.__(1, [/* SelectIntegration */0]);
        }
    case "slack" :
        if (path[1]) {
          return /* WatchersList */2;
        } else {
          return /* Slack */1;
        }
    default:
      return /* WatchersList */2;
  }
}

function routeToPath(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* JiraCloud */0 :
          return /* :: */[
                  "jira-cloud",
                  /* [] */0
                ];
      case /* Slack */1 :
          return /* :: */[
                  "slack",
                  /* [] */0
                ];
      case /* WatchersList */2 :
          return /* [] */0;
      
    }
  } else {
    switch (route.tag | 0) {
      case /* JiraServer */0 :
          var connectionId = route[0];
          if (typeof connectionId === "number") {
            if (connectionId === /* Default */0) {
              return /* :: */[
                      "jira-server",
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      "jira-server",
                      /* :: */[
                        "create",
                        /* [] */0
                      ]
                    ];
            }
          } else if (connectionId.tag) {
            return /* :: */[
                    "jira-server",
                    /* :: */[
                      "authorize",
                      /* :: */[
                        String(connectionId[0]),
                        /* [] */0
                      ]
                    ]
                  ];
          } else {
            return /* :: */[
                    "jira-server",
                    /* :: */[
                      "edit",
                      /* :: */[
                        String(connectionId[0]),
                        /* [] */0
                      ]
                    ]
                  ];
          }
      case /* WatcherCreate */1 :
          return /* :: */[
                  "new",
                  /* :: */[
                    WfWatcherStepper$BsConsole.Step.toString(route[0]),
                    /* [] */0
                  ]
                ];
      case /* WatcherEdit */2 :
          return /* :: */[
                  "edit",
                  /* :: */[
                    route[0],
                    /* :: */[
                      WfWatcherStepper$BsConsole.Step.toString(route[1]),
                      /* [] */0
                    ]
                  ]
                ];
      
    }
  }
}

function routeToAnalyticsString(route) {
  if (typeof route === "number") {
    return "/";
  }
  switch (route.tag | 0) {
    case /* JiraServer */0 :
        var connectionId = route[0];
        if (typeof connectionId === "number") {
          if (connectionId === /* Default */0) {
            return "/";
          } else {
            return "/create";
          }
        } else if (connectionId.tag) {
          return "/authorize/" + String(connectionId[0]);
        } else {
          return "/edit/" + String(connectionId[0]);
        }
    case /* WatcherCreate */1 :
        return "/create/step" + WfWatcherStepper$BsConsole.Step.toString(route[0]);
    case /* WatcherEdit */2 :
        return "/create/step" + WfWatcherStepper$BsConsole.Step.toString(route[1]);
    
  }
}

var namespace = "config_integrations";

var root = /* WatchersList */2;

exports.namespace = namespace;
exports.Referrer = Referrer;
exports.FormState = FormState;
exports.root = root;
exports.JiraServerError = JiraServerError;
exports.pathToRoute = pathToRoute;
exports.routeToPath = routeToPath;
exports.routeToAnalyticsString = routeToAnalyticsString;
/* Util-BsConsole Not a pure module */
