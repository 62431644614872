// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("../../ui.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Form$BsConsole = require("../../Form.js");
var Href$BsConsole = require("../../Href.js");
var I18N$BsConsole = require("../../I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("../../util.js");
var Colors$BsConsole = require("../../Colors.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");
var Typeahead$BsConsole = require("../../typeahead/Typeahead.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var UtilWindow$BsConsole = require("../../UtilWindow.js");
var BpgController$BsConsole = require("../../BpgController.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var SettingsDialog$BsConsole = require("../../SettingsDialog.js");
var MissingSymbolsCallToAction$BsConsole = require("./MissingSymbolsCallToAction.js");

function getCount(param) {
  
}

function toString(t$prime) {
  return t$prime.username;
}

var UserTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: "[ modify owner ]",
      fuzz: undefined
    });

var component = ReasonReact.statelessComponent("PS_SymbolAccessTokens-BsConsole__DeleteTokenDialog");

function make(token_, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ApiToken.Delete.make, (function (param, param$1) {
                                Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                return Curry._1(onSuccess, undefined);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var msg = state.remote;
                                var tmp;
                                tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])]))]));
                                var _msg = state.remote;
                                var tmp$1;
                                tmp$1 = typeof _msg === "number" ? (
                                    _msg === /* NotAsked */0 ? false : true
                                  ) : (
                                    _msg.tag ? true : false
                                  );
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Delete token"), undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                          tmp,
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure you would like to delete this token?")]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    Curry.__1(onClose),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (_event) {
                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                    token_,
                                                                                    Util$BsConsole.identity
                                                                                  ]);
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    tmp$1,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Delete")]
                                                                  ]))
                                                        ]))
                                              ]));
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var DeleteTokenDialog = {
  component: component,
  make: make
};

var smellyWidth = Css.style(/* :: */[
      Css.width(Css.px(500)),
      /* [] */0
    ]);

var errorRoot = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var error = Css.style(/* :: */[
      Css.margin(Css.px(8)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* [] */0
      ]
    ]);

var Styles = {
  smellyWidth: smellyWidth,
  errorRoot: errorRoot,
  error: error
};

var component$1 = ReasonReact.statelessComponent("PS_SymbolAccessTokens-BsConsole__EditTokenDialog");

function make$1(token_, token, users, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ApiToken.Modify.make, (function (param, token) {
                                Curry._1(onClose, undefined);
                                return Curry._1(onSuccess, token);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make((function (param) {
                                                  return Curry._1(onClose, undefined);
                                                }), I18N$BsConsole.get(undefined, "Transfer ownership"), undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(smellyWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditProjectForm.make, (function (param) {
                                                                      var values = param.values;
                                                                      var next = Belt_List.getBy(users, (function (user) {
                                                                              return user.uid === Caml_format.caml_int_of_string(values.owner);
                                                                            }));
                                                                      if (next !== undefined) {
                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                    token_,
                                                                                    (function (token_) {
                                                                                        return {
                                                                                                id: token_.id,
                                                                                                project: token_.project,
                                                                                                capabilities: token_.capabilities,
                                                                                                owner: next.uid,
                                                                                                metadata: token_.metadata,
                                                                                                __state: token_.__state,
                                                                                                __create: token_.__create,
                                                                                                __modify: token_.__modify
                                                                                              };
                                                                                      }),
                                                                                    Form$BsConsole.Fields.set("owner", values.owner, Form$BsConsole.Fields.empty),
                                                                                    param.setError,
                                                                                    param.setSubmitting,
                                                                                    param.resetFormState
                                                                                  ]);
                                                                      } else {
                                                                        console.log("INVARIANT");
                                                                        return ;
                                                                      }
                                                                    }), undefined, undefined, {
                                                                    owner: ""
                                                                  }, /* :: */[
                                                                    /* tuple */[
                                                                      /* Owner */-878442477,
                                                                      /* Required */0
                                                                    ],
                                                                    /* [] */0
                                                                  ], undefined, (function (param) {
                                                                      var getErrorForField = param.getErrorForField;
                                                                      var handleSubmit = param.handleSubmit;
                                                                      var handleChange = param.handleChange;
                                                                      var form = param.form;
                                                                      var userName = Belt_List.getBy(users, (function (user) {
                                                                              return String(user.uid) === form.values.owner;
                                                                            }));
                                                                      var partial_arg = Curry._1(handleChange, /* Owner */-878442477);
                                                                      var _msg = state.remote;
                                                                      var tmp;
                                                                      tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477)) : true;
                                                                      var msg = state.remote;
                                                                      var tmp$1;
                                                                      tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? I18N$BsConsole.showSkip(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Owner */-878442477), "")) : I18N$BsConsole.showSkip(msg[0]);
                                                                      var match = state.remote;
                                                                      var match$1 = Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477));
                                                                      var tmp$2;
                                                                      var exit = 0;
                                                                      if (typeof match === "number" || match.tag !== /* Failure */1) {
                                                                        exit = 1;
                                                                      } else {
                                                                        tmp$2 = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                  root: errorRoot
                                                                                }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                            error: error
                                                                                          }, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match[0])]))]));
                                                                      }
                                                                      if (exit === 1) {
                                                                        tmp$2 = match$1 ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                    root: errorRoot
                                                                                  }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                              error: error
                                                                                            }, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Make sure a user is selected by clicking an option")]))])) : null;
                                                                      }
                                                                      return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                                      return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                    }), /* `String */[
                                                                                                    -976970511,
                                                                                                    userName !== undefined ? userName.username : ""
                                                                                                  ], tmp, tmp$1, I18N$BsConsole.show(undefined, "transfer ownership"), true, false, undefined, (function (_onClose) {
                                                                                                      return ReasonReact.element(undefined, undefined, Curry.app(UserTypeahead.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      users,
                                                                                                                      (function (user) {
                                                                                                                          Curry._2(handleChange, /* Owner */-878442477, String(user.uid));
                                                                                                                          return Curry._1(_onClose, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      []
                                                                                                                    ]));
                                                                                                    }), undefined, []))])), tmp$2, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    (function (_event) {
                                                                                                        return Curry._1(onClose, undefined);
                                                                                                      }),
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                  ])),
                                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    (function (_event) {
                                                                                                        return Curry._1(handleSubmit, undefined);
                                                                                                      }),
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    state.remote === /* Loading */1 || form.values.owner === "",
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    [I18N$BsConsole.show(undefined, "transfer")]
                                                                                                  ]))
                                                                                        ])));
                                                                    })))]))]));
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var ModifyTokenDialog = {
  Styles: Styles,
  component: component$1,
  make: make$1
};

var tokenPaper = Css.style(/* :: */[
      Css.marginTop(Css.em(1)),
      /* [] */0
    ]);

var tableContainer = Css.style(/* :: */[
      Css.marginTop(Css.rem(1)),
      /* :: */[
        Css.wordBreak(/* breakAll */-323760734),
        /* [] */0
      ]
    ]);

var tableHeaderCell = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* :: */[
          Css.minWidth(Css.px(100)),
          /* [] */0
        ]
      ]
    ]);

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.rem(1)),
        /* :: */[
          Css.paddingRight(Css.px(28)),
          /* [] */0
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var addButton = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(-28)),
        /* :: */[
          Css.unsafe("top", "calc(50% - 28px)"),
          /* [] */0
        ]
      ]
    ]);

var Styles$1 = {
  tokenPaper: tokenPaper,
  tableContainer: tableContainer,
  tableHeaderCell: tableHeaderCell,
  titleContainer: titleContainer,
  title: title,
  addButton: addButton
};

var component$2 = ReasonReact.reducerComponent("PS_SymbolAccessTokens-BsConsole");

function make$2(handleTask, token, project, handleChangeUrl, shell, name, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ApiToken.Get.make, handleTask, token, undefined, (function (tokenState, updater) {
                                          var supportLink = Href$BsConsole.Support.make(undefined, /* SettingsSymbolification */22);
                                          return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, MissingSymbolsCallToAction$BsConsole.Banner.make(handleTask, token, handleChangeUrl, name, undefined, [])), ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Symbol access tokens")])),
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Symbol tokens are used for automating the upload of symbols using build scripts or other programs. To learn more about symbol tokens, please refer to the documentation.")])),
                                                                        supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                                        Css.marginTop(Css.rem(0.5)),
                                                                                        /* [] */0
                                                                                      ]), /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                                                          href: supportLink,
                                                                                          target: "_blank"
                                                                                        }, I18N$BsConsole.show(undefined, "Symbolification documentation"))])) : null
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.ApiToken.Create.make, (function (param, param$1) {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created token!"));
                                                                          return Curry._1(updater, undefined);
                                                                        }), handleTask, token, (function (_state, handlseSubmit, _toKey) {
                                                                          if (typeof shell === "number") {
                                                                            return null;
                                                                          }
                                                                          if (shell.tag) {
                                                                            return null;
                                                                          }
                                                                          var configuration = shell[0];
                                                                          return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                          undefined,
                                                                                          addButton,
                                                                                          (function (param) {
                                                                                              return Curry._1(handlseSubmit, /* tuple */[
                                                                                                          {
                                                                                                            id: "",
                                                                                                            project: project.pid,
                                                                                                            capabilities: "symbol:post",
                                                                                                            owner: configuration.user.uid,
                                                                                                            metadata: undefined,
                                                                                                            __state: undefined,
                                                                                                            __create: undefined,
                                                                                                            __modify: undefined
                                                                                                          },
                                                                                                          Util$BsConsole.identity,
                                                                                                          (function (param) {
                                                                                                              
                                                                                                            }),
                                                                                                          (function (param) {
                                                                                                              
                                                                                                            }),
                                                                                                          (function (param) {
                                                                                                              
                                                                                                            })
                                                                                                        ]);
                                                                                            }),
                                                                                          undefined,
                                                                                          undefined,
                                                                                          /* Fab */3502759,
                                                                                          /* Primary */-791844958,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                        ]));
                                                                        })))
                                                            ])), ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (userState, _updater) {
                                                                var match = tokenState.remote;
                                                                var match$1 = userState.remote;
                                                                var tmp;
                                                                var exit = 0;
                                                                var exit$1 = 0;
                                                                var exit$2 = 0;
                                                                if (typeof match === "number") {
                                                                  exit$2 = 3;
                                                                } else if (match.tag) {
                                                                  tmp = I18N$BsConsole.dynamic(match[0]);
                                                                } else if (typeof match$1 === "number") {
                                                                  if (match$1 === /* NotAsked */0) {
                                                                    exit = 1;
                                                                  } else {
                                                                    exit$2 = 3;
                                                                  }
                                                                } else if (match$1.tag) {
                                                                  exit$2 = 3;
                                                                } else {
                                                                  var users = match$1[0];
                                                                  var tokens$prime = Belt_List.keep(match[0], (function (token) {
                                                                          if (token.project === project.pid) {
                                                                            return token.capabilities === "symbol:post";
                                                                          } else {
                                                                            return false;
                                                                          }
                                                                        }));
                                                                  var match$2 = self.state.dialog;
                                                                  var tmp$1;
                                                                  tmp$1 = match$2 !== undefined ? (
                                                                      match$2.tag ? ReasonReact.element(undefined, undefined, make(match$2[0], token, handleTask, (function (param) {
                                                                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                                                                  }), (function (param) {
                                                                                    Curry._1(updater, undefined);
                                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted token!"));
                                                                                  }), [])) : ReasonReact.element(undefined, undefined, make$1(match$2[0], token, users, handleTask, (function (param) {
                                                                                    return Curry._1(self.send, /* Dialog */[undefined]);
                                                                                  }), (function (param) {
                                                                                    Curry._1(updater, undefined);
                                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully edited token!"));
                                                                                  }), []))
                                                                    ) : null;
                                                                  tmp = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Token")])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Owner")])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                              ]))])),
                                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [
                                                                                      Belt_List.toArray(Belt_List.map(tokens$prime, (function (token$prime) {
                                                                                                  var __x = Belt_List.getBy(users, (function (user) {
                                                                                                          return user.uid === token$prime.owner;
                                                                                                        }));
                                                                                                  return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(token$prime.id)])),
                                                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(__x, "-", (function (user) {
                                                                                                                                        return user.username;
                                                                                                                                      })))])),
                                                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, "none", "right", undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                                        return [
                                                                                                                                                ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                            Curry._1(self.send, /* Dialog */[/* EditToken */Block.__(0, [token$prime])]);
                                                                                                                                                            return Curry._1(onClose, undefined);
                                                                                                                                                          }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "transfer ownership")])),
                                                                                                                                                ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                            Curry._1(self.send, /* Dialog */[/* DeleteToken */Block.__(1, [token$prime])]);
                                                                                                                                                            return Curry._1(onClose, undefined);
                                                                                                                                                          }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]))
                                                                                                                                              ];
                                                                                                                                      }), undefined, []))]))
                                                                                                                ]));
                                                                                                }))),
                                                                                      Belt_List.length(tokens$prime) === 0 ? ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "There are no tokens for this project")]))])) : null
                                                                                    ])),
                                                                            tmp$1
                                                                          ]));
                                                                }
                                                                if (exit$2 === 3) {
                                                                  if (typeof match$1 === "number") {
                                                                    if (match$1 === /* NotAsked */0) {
                                                                      exit$1 = 2;
                                                                    } else {
                                                                      exit = 1;
                                                                    }
                                                                  } else if (match$1.tag) {
                                                                    tmp = I18N$BsConsole.dynamic(match$1[0]);
                                                                  } else {
                                                                    exit$1 = 2;
                                                                  }
                                                                }
                                                                if (exit$1 === 2 && typeof match === "number") {
                                                                  exit = 1;
                                                                }
                                                                if (exit === 1) {
                                                                  tmp = React.createElement("div", {
                                                                        style: {
                                                                          display: "flex",
                                                                          margin: "1em 0",
                                                                          alignContent: "center",
                                                                          justifyContent: "center"
                                                                        }
                                                                      }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, [])));
                                                                }
                                                                return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, tableContainer, [tmp]));
                                                              }))));
                                        })))]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.UserTypeahead = UserTypeahead;
exports.DeleteTokenDialog = DeleteTokenDialog;
exports.ModifyTokenDialog = ModifyTokenDialog;
exports.Styles = Styles$1;
exports.component = component$2;
exports.make = make$2;
/* UserTypeahead Not a pure module */
