// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var Column$BsConsole = require("../../utilities/Column.js");
var Styles$BsConsole = require("../../utilities/Styles.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");
var AccentCard$BsConsole = require("../../utilities/AccentCard.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Dedupe_Metrics$BsConsole = require("./Dedupe_Metrics.js");
var Dedupe_EditView$BsConsole = require("./Dedupe_EditView.js");
var Dedupe_FormView$BsConsole = require("./Dedupe_FormView.js");
var Dedupe_RuleTest$BsConsole = require("./Dedupe_RuleTest.js");
var OrderedCardList$BsConsole = require("../../utilities/OrderedCardList.js");
var CancelContinueDialog$BsConsole = require("../../utilities/CancelContinueDialog.js");
var IncludeModulesSwitch$BsConsole = require("./IncludeModulesSwitch.js");
var Dedupe_StaticRuleView$BsConsole = require("./Dedupe_StaticRuleView.js");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

var backtraceRulesetName = "Backtrace ruleset";

var language = I18N$BsConsole.get(undefined, "Language");

var any = I18N$BsConsole.get(undefined, "Any");

var failedToUpdate = I18N$BsConsole.get(undefined, "Failed to update ruleset");

function footerElement(mainText, secondaryText) {
  return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                  React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.px(10)),
                                /* [] */0
                              ]
                            ])
                      }, I18N$BsConsole.showSkip(mainText)),
                  React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey0)),
                              /* :: */[
                                Css.fontWeight(/* normal */812216871),
                                /* [] */0
                              ]
                            ])
                      }, I18N$BsConsole.showSkip(secondaryText))
                ]));
}

var component = RR$BsConsole.reducerComponentWithRetainedProps("Dedupe_InfoView-BsConsole");

function make(itemList, showAddView, hideAddView, showEditView, hideEditView, addView, editView, universe, project, token, uid, refetch, objectId, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              var listsEqual = Belt_List.eq(self.retainedProps, itemList, (function (a, b) {
                      if (a.name === b.name && a.owner === b.owner && a.priority === b.priority && a.rules === b.rules && Caml_obj.caml_equal(a.languages, b.languages) && Caml_obj.caml_equal(a.platforms, b.platforms)) {
                        return a.enabled === b.enabled;
                      } else {
                        return false;
                      }
                    }));
              if (listsEqual) {
                return self.state;
              }
              var init = self.state;
              return {
                      dialog: init.dialog,
                      originalDedupeList: itemList,
                      currentDedupeList: itemList,
                      selectedRuleset: undefined
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var selectedRuleset = match.selectedRuleset;
              var dialog = match.dialog;
              var backtraceRuleset_project = project.pid;
              var backtraceRuleset_languages = "";
              var backtraceRuleset_platforms = "";
              var backtraceRuleset = {
                project: backtraceRuleset_project,
                name: backtraceRulesetName,
                id: 0,
                owner: uid,
                languages: backtraceRuleset_languages,
                platforms: backtraceRuleset_platforms,
                enabled: 1,
                rules: "",
                priority: 0,
                __state: undefined,
                __create: undefined,
                __modify: undefined
              };
              var __x_001 = match.currentDedupeList;
              var __x = /* :: */[
                backtraceRuleset,
                __x_001
              ];
              var __x$1 = Belt_List.sort(__x, (function (a, b) {
                      return a.priority - b.priority | 0;
                    }));
              var dataList = Belt_List.map(__x$1, (function (item) {
                      var tmp;
                      if (item.name === backtraceRulesetName) {
                        tmp = "";
                      } else {
                        var ts = item.__modify;
                        var tmp$1;
                        if (ts !== undefined) {
                          var $$float = ts;
                          tmp$1 = new Date($$float * 1000).toDateString();
                        } else {
                          tmp$1 = "date unknown";
                        }
                        tmp = "Last modified " + tmp$1;
                      }
                      var langs = item.languages;
                      var tmp$2;
                      if (langs !== undefined && langs.length !== 0) {
                        var properLangs = Dedupe_FormView$BsConsole.LanguageOption.apiStrToUiArr(langs);
                        var num = properLangs.length;
                        if (num !== 1) {
                          if (num !== 2) {
                            var a = Belt_Array.get(properLangs, 0);
                            var a$1 = Belt_Array.get(properLangs, 1);
                            var str = (
                              a !== undefined ? a : ""
                            ) + (", " + ((
                                  a$1 !== undefined ? a$1 : ""
                                ) + (" + " + String(num - 2 | 0))));
                            tmp$2 = /* Element */Block.__(1, [footerElement(language, str)]);
                          } else {
                            var a$2 = Belt_Array.get(properLangs, 0);
                            var a$3 = Belt_Array.get(properLangs, 1);
                            var str$1 = (
                              a$2 !== undefined ? a$2 : ""
                            ) + (", " + (
                                a$3 !== undefined ? a$3 : ""
                              ));
                            tmp$2 = /* Element */Block.__(1, [footerElement(language, str$1)]);
                          }
                        } else {
                          tmp$2 = /* Element */Block.__(1, [footerElement(language, langs)]);
                        }
                      } else {
                        tmp$2 = /* Element */Block.__(1, [footerElement(language, any)]);
                      }
                      var plats = item.platforms;
                      var tmp$3;
                      if (plats !== undefined && plats.length !== 0) {
                        var properPlats = Dedupe_FormView$BsConsole.PlatformOption.apiStrToUiArr(plats);
                        var num$1 = properPlats.length;
                        if (num$1 !== 1) {
                          if (num$1 !== 2) {
                            var a$4 = Belt_Array.get(properPlats, 0);
                            var a$5 = Belt_Array.get(properPlats, 1);
                            var str$2 = (
                              a$4 !== undefined ? a$4 : ""
                            ) + (", " + ((
                                  a$5 !== undefined ? a$5 : ""
                                ) + (" + " + String(num$1 - 2 | 0))));
                            tmp$3 = /* Element */Block.__(1, [footerElement("OS", str$2)]);
                          } else {
                            var a$6 = Belt_Array.get(properPlats, 0);
                            var a$7 = Belt_Array.get(properPlats, 1);
                            var str$3 = (
                              a$6 !== undefined ? a$6 : ""
                            ) + (", " + (
                                a$7 !== undefined ? a$7 : ""
                              ));
                            tmp$3 = /* Element */Block.__(1, [footerElement("OS", str$3)]);
                          }
                        } else {
                          tmp$3 = /* Element */Block.__(1, [footerElement("OS", plats)]);
                        }
                      } else {
                        tmp$3 = /* Element */Block.__(1, [footerElement("OS", any)]);
                      }
                      var match = item.name;
                      return {
                              dataKey: String(item.id) + ("-" + item.name),
                              title: item.name,
                              subtitle: tmp,
                              infoLeft: tmp$2,
                              infoRight: tmp$3,
                              handleTitleUpdate: undefined,
                              onEditEnabled: undefined,
                              onEditDisabled: (function (param) {
                                  return Curry._1(hideEditView, undefined);
                                }),
                              editTitleText: undefined,
                              saveTitleText: "Save changes",
                              cancelEditText: "Discard changes",
                              deleteCardText: undefined,
                              deleteAction: undefined,
                              onCardClick: (function (param) {
                                  if (addView || editView !== undefined) {
                                    Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                  } else {
                                    Curry._1(send, /* SelectRuleset */Block.__(3, [item.name]));
                                  }
                                  
                                }),
                              moreActions: item.name === backtraceRulesetName ? /* [] */0 : /* :: */[
                                  {
                                    executable: (function (param) {
                                        if (addView || editView !== undefined) {
                                          return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                        } else {
                                          Curry._1(send, /* SelectRuleset */Block.__(3, [item.name]));
                                          return Curry._1(showEditView, item);
                                        }
                                      }),
                                    label: "Edit"
                                  },
                                  /* :: */[
                                    {
                                      executable: (function (param) {
                                          if (addView) {
                                            return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                          }
                                          if (editView !== undefined) {
                                            return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                          }
                                          var newEnabledValue = item.enabled === 0 ? 1 : 0;
                                          new Promise((function (resolve, param) {
                                                  var arg = BpgTask$BsConsole.Deduplication.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Deduplication.to_key(item), [/* tuple */[
                                                          "enabled",
                                                          newEnabledValue
                                                        ]]);
                                                  return Curry._2((function (param) {
                                                                  return (function (param$1, param$2) {
                                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                    });
                                                                })(token), undefined, (function (resp) {
                                                                if (resp.tag) {
                                                                  var error = resp[0];
                                                                  if (error[0] === Task2$BsConsole.CoronerError) {
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, failedToUpdate + (": " + error[1].message)));
                                                                    Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                    return resolve(Caml_option.some(error));
                                                                  } else if (error[0] === BpgTask$BsConsole.BpgError) {
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, failedToUpdate + (": " + error[2])));
                                                                    Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                    return resolve(Caml_option.some(error));
                                                                  } else {
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, failedToUpdate + (". " + I18N$BsConsole.get(undefined, "Unknown error has occured."))));
                                                                    Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                    return resolve(Caml_option.some(error));
                                                                  }
                                                                }
                                                                Curry._1(refetch, undefined);
                                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Updated ruleset."));
                                                                Dedupe_Metrics$BsConsole.send(/* RulesetUpdated */2);
                                                                return resolve(undefined);
                                                              }));
                                                }));
                                          
                                        }),
                                      label: item.enabled === 1 ? I18N$BsConsole.get(undefined, "Disable") : I18N$BsConsole.get(undefined, "Enable")
                                    },
                                    /* :: */[
                                      {
                                        executable: (function (param) {
                                            if (addView || editView !== undefined) {
                                              return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                            } else {
                                              return Curry._1(send, /* Dialog */Block.__(0, [/* Delete */[item]]));
                                            }
                                          }),
                                        label: I18N$BsConsole.get(undefined, "Delete")
                                      },
                                      /* [] */0
                                    ]
                                  ]
                                ],
                              view: selectedRuleset !== undefined && match === selectedRuleset.name ? /* Selected */0 : /* Standard */1,
                              cardDisabled: item.enabled === 0
                            };
                    }));
              var tmp;
              var exit = 0;
              if (addView) {
                if (editView !== undefined) {
                  exit = 1;
                } else {
                  tmp = ReasonReact.element(undefined, undefined, Dedupe_EditView$BsConsole.make(universe, project, token, uid, refetch, hideAddView, hideEditView, undefined, []));
                }
              } else if (editView !== undefined) {
                exit = 1;
              } else {
                var match$1 = Belt_List.length(itemList);
                if (match$1 !== 0) {
                  if (selectedRuleset !== undefined) {
                    var l = selectedRuleset.platforms;
                    var l$1 = selectedRuleset.languages;
                    tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Dedupe_StaticRuleView$BsConsole.make(selectedRuleset.name, l !== undefined && l !== "" ? l : any, l$1 !== undefined && l$1 !== "" ? l$1 : any, selectedRuleset.rules, selectedRuleset.enabled === 1, [])), ReasonReact.element(undefined, undefined, Dedupe_RuleTest$BsConsole.make(universe, token, project.name, objectId, selectedRuleset.name, handleChangeUrl, [])));
                  } else {
                    tmp = React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(50)),
                                /* [] */0
                              ])
                        }, I18N$BsConsole.show(undefined, "Click on a ruleset to view its details."));
                  }
                } else {
                  tmp = React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.margin(Css.px(50)),
                              /* [] */0
                            ])
                      }, I18N$BsConsole.show(undefined, "You do not have any custom rulesets for this project."));
                }
              }
              if (exit === 1) {
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Dedupe_EditView$BsConsole.make(universe, project, token, uid, refetch, hideAddView, hideEditView, editView, [])));
              }
              var tmp$1;
              if (dialog !== undefined) {
                if (dialog) {
                  var ruleObject = dialog[0];
                  tmp$1 = ReasonReact.element(undefined, undefined, CancelContinueDialog$BsConsole.make((function (_event) {
                              return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                            }), (function (_event) {
                              new Promise((function (resolve, param) {
                                      var arg = BpgTask$BsConsole.Deduplication.$$delete(ruleObject);
                                      return Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), undefined, (function (resp) {
                                                    if (resp.tag) {
                                                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete ruleset."));
                                                      Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                      Dedupe_Metrics$BsConsole.send(/* RulesetDeleteFailed */5);
                                                      return resolve(Caml_option.some(resp[0]));
                                                    }
                                                    Curry._1(refetch, undefined);
                                                    Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                    Dedupe_Metrics$BsConsole.send(/* RulesetDeleted */4);
                                                    return resolve(undefined);
                                                  }));
                                    }));
                              
                            }), I18N$BsConsole.show(undefined, "Are you sure you want to delete this ruleset?"), I18N$BsConsole.show(undefined, "Delete ruleset"), []));
                } else {
                  tmp$1 = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                            undefined,
                            true,
                            (function (_event) {
                                return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                              }),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                            undefined,
                                                            undefined,
                                                            (function (_event) {
                                                                return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            "Close",
                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                          ]))])),
                                        ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.marginBottom(Css.px(10)),
                                                              /* [] */0
                                                            ])
                                                      }, I18N$BsConsole.show(undefined, "Please save or cancel the existing ruleset before continuing."))]))
                                      ]))]
                          ]));
                }
              } else {
                tmp$1 = null;
              }
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.height(Css.pct(100)),
                                        /* [] */0
                                      ]
                                    ]), [
                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                Css.padding(Css.rem(1)),
                                                /* :: */[
                                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                                  /* [] */0
                                                ]
                                              ]), [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* [] */0), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Deduplication settings")]))])),
                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.overflowX(/* auto */-1065951377),
                                                        /* :: */[
                                                          Css.height(/* `vh */[
                                                                26418,
                                                                81.0
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                              Css.minHeight(Css.px(1000)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.px(20)),
                                                                /* [] */0
                                                              ]
                                                            ]), [
                                                          ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                        Css.paddingLeft(Css.px(20)),
                                                                        /* :: */[
                                                                          Css.paddingRight(Css.px(20)),
                                                                          /* :: */[
                                                                            Css.paddingTop(Css.px(20)),
                                                                            /* :: */[
                                                                              Css.paddingBottom(Css.px(20)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]), [
                                                                    CoronerdGate$BsConsole.projectDeduplicationSettings ? ReasonReact.element(undefined, undefined, IncludeModulesSwitch$BsConsole.Jsx2.make(project, token, undefined, [])) : null,
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Deduplication rulesets")])),
                                                                    React.createElement("div", {
                                                                          className: Css.merge(/* :: */[
                                                                                Css.style(/* :: */[
                                                                                      Css.marginBottom(Css.rem(1)),
                                                                                      /* [] */0
                                                                                    ]),
                                                                                /* :: */[
                                                                                  Styles$BsConsole.$$Text.subtitle,
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, React.createElement("i", undefined, I18N$BsConsole.show(undefined, "Rulesets are applied sequentially. Drag to reorder them."))),
                                                                    ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Add new ruleset"), Caml_option.some(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), (function (param) {
                                                                                if (addView || editView !== undefined) {
                                                                                  return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                                                                } else {
                                                                                  return Curry._1(showAddView, undefined);
                                                                                }
                                                                              }), addView, 200, [])),
                                                                    ReasonReact.element(undefined, undefined, OrderedCardList$BsConsole.make(dataList, (function (newList) {
                                                                                var newDedupeList = Belt_List.map(newList, (function (item) {
                                                                                        var match_ = Belt_List.getBy(itemList, (function (dedupeObject) {
                                                                                                return dedupeObject.name === item.title;
                                                                                              }));
                                                                                        if (match_ !== undefined) {
                                                                                          return match_;
                                                                                        } else {
                                                                                          return backtraceRuleset;
                                                                                        }
                                                                                      }));
                                                                                var btIndex = Belt_List.toArray(Belt_List.map(newDedupeList, (function (item) {
                                                                                              return item.name;
                                                                                            }))).indexOf(backtraceRulesetName);
                                                                                var newDedupeListWithNewPriorities = Belt_List.mapWithIndex(newDedupeList, (function (index, item) {
                                                                                        return {
                                                                                                project: item.project,
                                                                                                name: item.name,
                                                                                                id: item.id,
                                                                                                owner: item.owner,
                                                                                                languages: item.languages,
                                                                                                platforms: item.platforms,
                                                                                                enabled: item.enabled,
                                                                                                rules: item.rules,
                                                                                                priority: index - btIndex | 0,
                                                                                                __state: item.__state,
                                                                                                __create: item.__create,
                                                                                                __modify: item.__modify
                                                                                              };
                                                                                      }));
                                                                                var listWithoutBT = Belt_List.keep(newDedupeListWithNewPriorities, (function (item) {
                                                                                        return item.name !== backtraceRulesetName;
                                                                                      }));
                                                                                Curry._1(send, /* UpdateCurrentDedupeList */Block.__(2, [listWithoutBT]));
                                                                                var promises = Belt_List.map(listWithoutBT, (function (item) {
                                                                                        return new Promise((function (resolve, param) {
                                                                                                      var arg = BpgTask$BsConsole.Deduplication.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Deduplication.to_key(item), [/* tuple */[
                                                                                                              "priority",
                                                                                                              item.priority
                                                                                                            ]]);
                                                                                                      return Curry._2((function (param) {
                                                                                                                      return (function (param$1, param$2) {
                                                                                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                                        });
                                                                                                                    })(token), undefined, (function (resp) {
                                                                                                                    if (resp.tag) {
                                                                                                                      return resolve(Caml_option.some(resp[0]));
                                                                                                                    } else {
                                                                                                                      return resolve(undefined);
                                                                                                                    }
                                                                                                                  }));
                                                                                                    }));
                                                                                      }));
                                                                                var __x = Promise.all(Belt_List.toArray(promises));
                                                                                var __x$1 = __x.then((function (results) {
                                                                                        var __x = Belt_List.fromArray(results);
                                                                                        var error = Belt_List.getBy(__x, (function (r) {
                                                                                                return r !== undefined;
                                                                                              }));
                                                                                        if (error !== undefined) {
                                                                                          Curry._1(send, /* SetCurrentToOriginal */0);
                                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update rulesets. Order has been reset."));
                                                                                        } else {
                                                                                          Curry._1(send, /* UpdateOriginalDedupeList */Block.__(1, [newDedupeListWithNewPriorities]));
                                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated ruleset order."));
                                                                                        }
                                                                                        Curry._1(refetch, undefined);
                                                                                        return Promise.resolve(undefined);
                                                                                      }));
                                                                                __x$1.catch((function (exn) {
                                                                                        if (exn[0] === Task2$BsConsole.TaskError) {
                                                                                          Curry._1(send, /* SetCurrentToOriginal */0);
                                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update rulesets. Order has been reset."));
                                                                                        }
                                                                                        Curry._1(refetch, undefined);
                                                                                        return Promise.resolve(undefined);
                                                                                      }));
                                                                                
                                                                              }), true, []))
                                                                  ])),
                                                          React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.borderRight(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                                                      /* :: */[
                                                                        Css.paddingLeft(Css.px(1)),
                                                                        /* [] */0
                                                                      ]
                                                                    ])
                                                              }),
                                                          ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                        Css.width(Css.pct(100)),
                                                                        /* [] */0
                                                                      ]), [tmp]))
                                                        ])))]))
                                ])), tmp$1);
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      originalDedupeList: itemList,
                      currentDedupeList: itemList,
                      selectedRuleset: undefined
                    };
            }),
          retainedProps: itemList,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            dialog: state.dialog,
                            originalDedupeList: state.originalDedupeList,
                            currentDedupeList: state.originalDedupeList,
                            selectedRuleset: state.selectedRuleset
                          }]);
              }
              switch (action.tag | 0) {
                case /* Dialog */0 :
                    return /* Update */Block.__(0, [{
                                dialog: action[0],
                                originalDedupeList: state.originalDedupeList,
                                currentDedupeList: state.currentDedupeList,
                                selectedRuleset: state.selectedRuleset
                              }]);
                case /* UpdateOriginalDedupeList */1 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalDedupeList: action[0],
                                currentDedupeList: state.currentDedupeList,
                                selectedRuleset: state.selectedRuleset
                              }]);
                case /* UpdateCurrentDedupeList */2 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalDedupeList: state.originalDedupeList,
                                currentDedupeList: action[0],
                                selectedRuleset: state.selectedRuleset
                              }]);
                case /* SelectRuleset */3 :
                    var name = action[0];
                    var __x = state.currentDedupeList;
                    var r = Belt_List.filter(__x, (function (o) {
                            return o.name === name;
                          }));
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalDedupeList: state.originalDedupeList,
                                currentDedupeList: state.currentDedupeList,
                                selectedRuleset: Belt_List.get(r, 0)
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var mininumHeight = 1000;

exports.backtraceRulesetName = backtraceRulesetName;
exports.language = language;
exports.any = any;
exports.failedToUpdate = failedToUpdate;
exports.mininumHeight = mininumHeight;
exports.footerElement = footerElement;
exports.component = component;
exports.make = make;
/* language Not a pure module */
