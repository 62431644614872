// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var Row2$BsConsole = require("../components/Row2.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugVerticalDivider$BsConsole = require("./BugVerticalDivider.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var BugHorizontalDivider$BsConsole = require("./BugHorizontalDivider.js");

function balanceGridDimensions(state) {
  var threadSelectWidth = state.threadSelectWidth > 100 ? state.threadSelectWidth : 100;
  var mainContentWidth = state.mainContentWidth > 100 ? state.mainContentWidth : 100;
  var restWidth = (state.containerWidth - state.threadSelectWidth | 0) - state.mainContentWidth | 0;
  if (restWidth < 100) {
    var toShrink = 100 - restWidth | 0;
    if ((mainContentWidth - 100 | 0) > toShrink) {
      mainContentWidth = mainContentWidth - toShrink | 0;
    } else {
      threadSelectWidth = threadSelectWidth - toShrink | 0;
    }
  }
  var threadSelectHeight = state.threadSelectHeight > 60 ? state.threadSelectHeight : 60;
  var mainContentHeight = state.mainContentHeight > 160 ? state.mainContentHeight : 160;
  var callstackHeight = mainContentHeight - threadSelectHeight | 0;
  if (callstackHeight < 100) {
    threadSelectHeight = threadSelectHeight - (100 - callstackHeight | 0) | 0;
  }
  var tabsHeight = state.containerHeight - mainContentHeight | 0;
  if (tabsHeight < 100) {
    mainContentHeight = mainContentHeight - (100 - tabsHeight | 0) | 0;
  }
  return {
          threadSelectWidth: threadSelectWidth,
          threadSelectHeight: threadSelectHeight,
          mainContentWidth: mainContentWidth,
          mainContentHeight: mainContentHeight,
          containerWidth: state.containerWidth,
          containerHeight: state.containerHeight
        };
}

function toJson(state) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "threadSelectWidth",
                state.threadSelectWidth
              ],
              /* :: */[
                /* tuple */[
                  "threadSelectHeight",
                  state.threadSelectHeight
                ],
                /* :: */[
                  /* tuple */[
                    "mainContentWidth",
                    state.mainContentWidth
                  ],
                  /* :: */[
                    /* tuple */[
                      "mainContentHeight",
                      state.mainContentHeight
                    ],
                    /* :: */[
                      /* tuple */[
                        "containerWidth",
                        state.containerWidth
                      ],
                      /* :: */[
                        /* tuple */[
                          "containerHeight",
                          state.containerHeight
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function fromJson(json) {
  return {
          threadSelectWidth: Json_decode.field("threadSelectWidth", Json_decode.$$int, json),
          threadSelectHeight: Json_decode.field("threadSelectHeight", Json_decode.$$int, json),
          mainContentWidth: Json_decode.field("mainContentWidth", Json_decode.$$int, json),
          mainContentHeight: Json_decode.field("mainContentHeight", Json_decode.$$int, json),
          containerWidth: Json_decode.field("containerWidth", Json_decode.$$int, json),
          containerHeight: Json_decode.field("containerHeight", Json_decode.$$int, json)
        };
}

function gridStorageKey(projectName) {
  return "debugger-grid-" + projectName;
}

function saveState(state, projectName) {
  localStorage.setItem("debugger-grid-" + projectName, JSON.stringify(toJson(state)));
  return state;
}

function reducer(projectName, state, action) {
  switch (action.tag | 0) {
    case /* SetThreadsWidth */0 :
        var threadSelectWidth = Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(action[0], 100), (state.containerWidth - 100 | 0) - 100 | 0);
        var mainContentWidth = Caml_primitive.caml_int_max(state.mainContentWidth + (state.threadSelectWidth - threadSelectWidth | 0) | 0, 100);
        return saveState({
                    threadSelectWidth: threadSelectWidth,
                    threadSelectHeight: state.threadSelectHeight,
                    mainContentWidth: mainContentWidth,
                    mainContentHeight: state.mainContentHeight,
                    containerWidth: state.containerWidth,
                    containerHeight: state.containerHeight
                  }, projectName);
    case /* SetThreadsHeight */1 :
        return saveState({
                    threadSelectWidth: state.threadSelectWidth,
                    threadSelectHeight: Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(action[0], 60), state.mainContentHeight - 100 | 0),
                    mainContentWidth: state.mainContentWidth,
                    mainContentHeight: state.mainContentHeight,
                    containerWidth: state.containerWidth,
                    containerHeight: state.containerHeight
                  }, projectName);
    case /* SetMainContentWidth */2 :
        return saveState({
                    threadSelectWidth: state.threadSelectWidth,
                    threadSelectHeight: state.threadSelectHeight,
                    mainContentWidth: Caml_primitive.caml_int_max(action[0], 100),
                    mainContentHeight: state.mainContentHeight,
                    containerWidth: state.containerWidth,
                    containerHeight: state.containerHeight
                  }, projectName);
    case /* SetMainContentHeight */3 :
        return saveState({
                    threadSelectWidth: state.threadSelectWidth,
                    threadSelectHeight: state.threadSelectHeight,
                    mainContentWidth: state.mainContentWidth,
                    mainContentHeight: Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(action[0], 100), state.containerHeight - 100 | 0),
                    containerWidth: state.containerWidth,
                    containerHeight: state.containerHeight
                  }, projectName);
    case /* UpdateContainerDimensions */4 :
        return saveState(balanceGridDimensions({
                        threadSelectWidth: state.threadSelectWidth,
                        threadSelectHeight: state.threadSelectHeight,
                        mainContentWidth: state.mainContentWidth,
                        mainContentHeight: state.mainContentHeight,
                        containerWidth: action[0],
                        containerHeight: action[1]
                      }), projectName);
    
  }
}

function BugGrid(Props) {
  var threadCount = Props.threadCount;
  var w = Props.width;
  var h = Props.height;
  var layout = Props.layout;
  var tabs = Props.tabs;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var reducer$1 = React.useMemo((function () {
          return (function (param, param$1) {
              return reducer(projectName, param, param$1);
            });
        }), [projectName]);
  var initialState = React.useMemo((function () {
          var str = localStorage.getItem("debugger-grid-" + projectName);
          if (str !== null) {
            var state = fromJson(JSON.parse(str));
            return {
                    threadSelectWidth: state.threadSelectWidth,
                    threadSelectHeight: state.threadSelectHeight,
                    mainContentWidth: state.mainContentWidth,
                    mainContentHeight: state.mainContentHeight,
                    containerWidth: w,
                    containerHeight: h
                  };
          }
          var mainContentHeight = h * 0.65 | 0;
          var threadSelectHeight = Caml_primitive.caml_int_min(110, Caml_int32.imul(threadCount, 20) + 42 | 0);
          var threadSelectWidth;
          switch (layout.tag | 0) {
            case /* Generic21 */3 :
            case /* Generic11 */4 :
                threadSelectWidth = 350;
                break;
            default:
              threadSelectWidth = 246;
          }
          var mainContentWidth = ((w - threadSelectWidth | 0) - 4 | 0) * 0.62 | 0;
          return {
                  threadSelectWidth: threadSelectWidth,
                  threadSelectHeight: threadSelectHeight,
                  mainContentWidth: mainContentWidth,
                  mainContentHeight: mainContentHeight,
                  containerWidth: w,
                  containerHeight: h
                };
        }), ([]));
  var match = React.useReducer(Curry.__2(reducer$1), initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var mainContentHeight = match$1.mainContentHeight;
  var mainContentWidth = match$1.mainContentWidth;
  var threadSelectHeight = match$1.threadSelectHeight;
  var threadSelectWidth = match$1.threadSelectWidth;
  var col3Width = ((w - mainContentWidth | 0) - threadSelectWidth | 0) - 4 | 0;
  var bottomTabsHeight = (h - mainContentHeight | 0) - 3 | 0;
  var callstackHeight = (mainContentHeight - threadSelectHeight | 0) - 2 | 0;
  React.useEffect((function () {
          var cb = function (e) {
            localStorage.setItem("backtrace-drag-x", String(e.pageX | 0));
            localStorage.setItem("backtrace-drag-y", String(e.pageY | 0));
            
          };
          window.addEventListener("dragover", cb);
          window.addEventListener("click", cb);
          return (function (param) {
                    window.removeEventListener("dragover", cb);
                    window.removeEventListener("click", cb);
                    
                  });
        }), ([]));
  React.useEffect((function () {
          Curry._1(dispatch, /* UpdateContainerDimensions */Block.__(4, [
                  w,
                  h
                ]));
          
        }), /* tuple */[
        w,
        h
      ]);
  var tmp;
  var exit = 0;
  var row1;
  var row2;
  var exit$1 = 0;
  switch (layout.tag | 0) {
    case /* Btt */0 :
        var match$2 = layout[0];
        row1 = match$2.threadSelect;
        row2 = match$2.callstack;
        exit = 1;
        break;
    case /* Generic211 */2 :
    case /* Generic21 */3 :
        exit$1 = 3;
        break;
    case /* Generic111 */1 :
    case /* Generic11 */4 :
        exit$1 = 2;
        break;
    
  }
  switch (exit$1) {
    case 2 :
        tmp = Curry._2(layout[0].col1, threadSelectWidth, (threadSelectHeight + callstackHeight | 0) + 2 | 0);
        break;
    case 3 :
        var match$3 = layout[0].col1;
        row1 = match$3[0];
        row2 = match$3[1];
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, Curry._2(row1, threadSelectWidth, threadSelectHeight), React.createElement(BugHorizontalDivider$BsConsole.make, {
              height: threadSelectHeight,
              color: BugStyles$BsConsole.Color.dividerTertiary,
              onHeightChange: (function (h) {
                  return Curry._1(dispatch, /* SetThreadsHeight */Block.__(1, [h]));
                })
            }), Curry._2(row2, threadSelectWidth, callstackHeight));
  }
  var tmp$1;
  var exit$2 = 0;
  switch (layout.tag | 0) {
    case /* Btt */0 :
        var match$4 = layout[0];
        var registers = match$4.registers;
        var col2 = match$4.variables;
        tmp$1 = match$4.showVariableDetails ? React.createElement(Col2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.width(Css.px(mainContentWidth + col3Width | 0)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* [] */0
                      ]
                    ]),
                children: null
              }, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.width(Css.px(mainContentWidth + col3Width | 0)),
                          /* :: */[
                            Css.height(Css.pct(100)),
                            /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.flexDirection(/* row */5693978),
                                /* :: */[
                                  Css.overflow(/* auto */-1065951377),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(mainContentWidth)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.flex(/* `num */[
                                        5496390,
                                        1
                                      ]),
                                  /* :: */[
                                    Css.overflow(Css.auto),
                                    /* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.height(Css.pct(100)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, Curry._2(col2, mainContentWidth, mainContentHeight))), React.createElement(BugVerticalDivider$BsConsole.make, {
                        color: BugStyles$BsConsole.Color.dividerTertiary,
                        width: mainContentWidth,
                        onWidthChange: (function (left) {
                            return Curry._1(dispatch, /* SetMainContentWidth */Block.__(2, [left]));
                          })
                      }), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(col3Width)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* [] */0
                              ]
                            ])
                      }, Curry._2(match$4.variableDetails, col3Width, mainContentHeight))), React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.minHeight(Css.px(2)),
                                /* :: */[
                                  Css.background(Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }), Curry._1(registers, (mainContentWidth + col3Width | 0) + 2 | 0))) : React.createElement(Col2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.width(Css.px(mainContentWidth + col3Width | 0)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* [] */0
                      ]
                    ]),
                children: null
              }, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.flex(/* `num */[
                                5496390,
                                1
                              ]),
                          /* :: */[
                            Css.overflow(Css.auto),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ])
                  }, Curry._2(col2, mainContentWidth + col3Width | 0, mainContentHeight)), React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.minHeight(Css.px(2)),
                                /* :: */[
                                  Css.background(Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }), Curry._1(registers, (mainContentWidth + col3Width | 0) + 2 | 0)));
        break;
    case /* Generic111 */1 :
    case /* Generic211 */2 :
        exit$2 = 1;
        break;
    case /* Generic21 */3 :
    case /* Generic11 */4 :
        exit$2 = 2;
        break;
    
  }
  switch (exit$2) {
    case 1 :
        var match$5 = layout[0];
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(mainContentWidth)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.flex(/* `num */[
                                    5496390,
                                    1
                                  ]),
                              /* :: */[
                                Css.overflow(Css.auto),
                                /* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, Curry._2(match$5.col2, mainContentWidth, mainContentHeight))
                }), React.createElement(BugVerticalDivider$BsConsole.make, {
                  color: BugStyles$BsConsole.Color.dividerTertiary,
                  width: mainContentWidth,
                  onWidthChange: (function (left) {
                      return Curry._1(dispatch, /* SetMainContentWidth */Block.__(2, [left]));
                    })
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(col3Width)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* [] */0
                        ]
                      ])
                }, Curry._2(match$5.col3, col3Width, mainContentHeight)));
        break;
    case 2 :
        tmp$1 = Curry._2(layout[0].col2, (mainContentWidth + 2 | 0) + col3Width | 0, mainContentHeight);
        break;
    
  }
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* stretch */-162316795,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.px(mainContentHeight)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.width(Css.px(threadSelectWidth)),
                            /* [] */0
                          ]),
                      children: tmp
                    }), React.createElement(BugVerticalDivider$BsConsole.make, {
                      color: BugStyles$BsConsole.Color.dividerTertiary,
                      width: threadSelectWidth,
                      onWidthChange: (function (w) {
                          return Curry._1(dispatch, /* SetThreadsWidth */Block.__(0, [w]));
                        })
                    }), React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.height(Css.pct(100)),
                            /* [] */0
                          ]),
                      children: React.createElement(Row2$BsConsole.make, {
                            className: Css.style(/* :: */[
                                  Css.height(Css.pct(100)),
                                  /* [] */0
                                ]),
                            children: tmp$1
                          })
                    })), React.createElement(BugHorizontalDivider$BsConsole.make, {
                  height: mainContentHeight,
                  color: BugStyles$BsConsole.Color.dividerSecondary,
                  onHeightChange: (function (h) {
                      return Curry._1(dispatch, /* SetMainContentHeight */Block.__(3, [h]));
                    })
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(w)),
                        /* :: */[
                          Css.height(Css.px(bottomTabsHeight)),
                          /* [] */0
                        ]
                      ])
                }, Curry._2(tabs, w, bottomTabsHeight)));
}

var minTabsHeight = 100;

var minThreadSelectHeight = 60;

var minThreadSelectWidth = 100;

var minMainContentWidth = 100;

var minCallstackHeight = 100;

var minCol3Width = 100;

var make = BugGrid;

exports.minTabsHeight = minTabsHeight;
exports.minThreadSelectHeight = minThreadSelectHeight;
exports.minThreadSelectWidth = minThreadSelectWidth;
exports.minMainContentWidth = minMainContentWidth;
exports.minCallstackHeight = minCallstackHeight;
exports.minCol3Width = minCol3Width;
exports.balanceGridDimensions = balanceGridDimensions;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.gridStorageKey = gridStorageKey;
exports.saveState = saveState;
exports.reducer = reducer;
exports.make = make;
/* Css Not a pure module */
