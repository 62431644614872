// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var namespace = "debugger2.1";

function send(t) {
  return MetricsEvent$BsConsole.send(/* GenericEvent */Block.__(2, [{
                  name: typeof t === "number" ? (
                      t !== 0 ? "debugger2.1/attachment_previewer/download" : "debugger2.1/attachment_previewer/preview"
                    ) : namespace + ("/change_tab/" + t[0]),
                  payload: undefined
                }]));
}

exports.namespace = namespace;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
