// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../../Task2.js");
var Unpack$BsConsole = require("../../bs-crdb-response/src/Unpack.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var ResponseSelect$BsConsole = require("../../bs-crdb-response/src/ResponseSelect.js");

var ParseError = Caml_exceptions.create("BugCrdbAttributes-BsConsole.ParseError");

function makeQuery(_tx) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "limit",
                1
              ],
              /* :: */[
                /* tuple */[
                  "select_wildcard",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "physical",
                          true
                        ],
                        /* [] */0
                      ])
                ],
                /* :: */[
                  /* tuple */[
                    "filter",
                    Json_encode.array(Util$BsConsole.identity, [Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "_tx",
                                  Json_encode.array(Util$BsConsole.identity, [Json_encode.tuple2((function (prim) {
                                                return prim;
                                              }), (function (prim) {
                                                return prim;
                                              }), /* tuple */[
                                              "equal",
                                              _tx
                                            ])])
                                ],
                                /* [] */0
                              ])])
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

function decodeResp(response) {
  try {
    var columns = ResponseSelect$BsConsole.columns(response.json);
    var rows = Belt_Option.getWithDefault(Belt_List.get(Unpack$BsConsole.correlate(response.json, columns), 0), /* [] */0);
    var metadata = ResponseSelect$BsConsole.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugCrdbAttributes/decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTask(projectName, _tx) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + projectName]), undefined, /* Post */Block.__(0, [makeQuery(_tx)]), undefined, decodeResp, undefined, undefined);
}

function use(tx, token, projectName) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          Curry._1(setValue, (function (param) {
                  return /* NotAsked */0;
                }));
          
        }), [tx]);
  React.useEffect((function () {
          if (typeof value === "number") {
            if (value !== 0) {
              var arg = getTask(projectName, tx);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (resp) {
                      if (resp.tag) {
                        var err = resp[0];
                        return Curry._1(setValue, (function (param) {
                                      return /* Failure */Block.__(1, [err]);
                                    }));
                      }
                      var resp$1 = resp[0];
                      return Curry._1(setValue, (function (param) {
                                    return /* Success */Block.__(0, [resp$1]);
                                  }));
                    }));
            } else {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [value]);
  return value;
}

function mergeAttributes(attributes, crdbRow) {
  return Js_dict.fromList(Belt_List.concat(Belt_List.fromArray(Js_dict.entries(attributes)), Belt_List.map(Belt_List.keep(crdbRow, (function (param) {
                            var match = param[0].name;
                            switch (match) {
                              case "callstack" :
                              case "callstack.files" :
                              case "callstack.functions" :
                              case "callstack.modules" :
                                  return false;
                              default:
                                return true;
                            }
                          })), (function (param) {
                        return /* tuple */[
                                param[0].name,
                                param[1]
                              ];
                      }))));
}

function getAttribute(row, name) {
  return Belt_Option.map(Belt_List.getBy(row, (function (param) {
                    return param[0].name === name;
                  })), (function (param) {
                return param[1];
              }));
}

var selectAllAttributesVersion = "1.53.8";

exports.ParseError = ParseError;
exports.makeQuery = makeQuery;
exports.decodeResp = decodeResp;
exports.getTask = getTask;
exports.selectAllAttributesVersion = selectAllAttributesVersion;
exports.use = use;
exports.mergeAttributes = mergeAttributes;
exports.getAttribute = getAttribute;
/* react Not a pure module */
