// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function MessageFeatureGate(Props) {
  var showMessageOpt = Props.showMessage;
  var messageOpt = Props.message;
  var children = Props.children;
  var showMessage = showMessageOpt !== undefined ? showMessageOpt : true;
  var message = messageOpt !== undefined ? Caml_option.valFromOption(messageOpt) : React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Please contact your service administrator for more details."));
  if (showMessage) {
    return message;
  } else {
    return children;
  }
}

function make(showMessage, children) {
  return ReasonReactCompat.wrapReactForReasonReact(MessageFeatureGate, {
              showMessage: showMessage,
              children: children
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = MessageFeatureGate;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* react Not a pure module */
