// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Jss = require("jss");
var ElementRe = require("bs-webapi/lib/js/src/dom/nodes/ElementRe.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Styles = require("@material-ui/core/styles");
var JssProvider = require("react-jss/lib/JssProvider");

var generateClassName = Styles.createGenerateClassName();

var jss = Jss.create(Styles.jssPreset());

var styleEl = document.createElement("style");

styleEl.id = "insertion-point-jss";

ElementRe.insertAdjacentElement(/* AfterBegin */1, styleEl, document.head);

jss.options.insertionPoint = styleEl;

function make(jss, generateClassName, children) {
  return ReasonReact.wrapJsForReason(JssProvider.default, {
              jss: jss,
              generateClassName: generateClassName
            }, children);
}

var Provider = {
  make: make
};

var component = RR$BsConsole.statelessComponent("JssProvider-BsConsole");

function make$1(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, make(jss, generateClassName, children));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.generateClassName = generateClassName;
exports.jss = jss;
exports.styleEl = styleEl;
exports.Provider = Provider;
exports.component = component;
exports.make = make$1;
/* generateClassName Not a pure module */
