// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReForm_Validation = require("./ReForm_Validation.js");

function safeHd(lst) {
  if (List.length(lst) === 0) {
    return ;
  } else {
    return Caml_option.some(List.hd(lst));
  }
}

function $great$great$eq(value, map) {
  if (value !== undefined) {
    return Curry._1(map, Caml_option.valFromOption(value));
  }
  
}

function Create(Config) {
  var getFieldLens = function (field) {
    return List.find((function (param) {
                  return param[0] === field;
                }), Config.lens);
  };
  var validateField = function (field, values, value, schema, i18n) {
    return $great$great$eq(safeHd(List.filter((function (param) {
                            return param[0] === field;
                          }))(schema)), (function (fieldSchema) {
                  return ReForm_Validation.getValidationError(fieldSchema, values, value, i18n);
                }));
  };
  var handleChange = function (param, values) {
    var match = getFieldLens(param[0]);
    return Curry._2(match[2], values, param[1]);
  };
  var Field = {
    getFieldLens: getFieldLens,
    validateField: validateField,
    handleChange: handleChange
  };
  var component = ReasonReact.reducerComponent("ReForm");
  var make = function (onSubmit, onFormStateChangeOpt, validateOpt, initialState, schema, i18nOpt, children) {
    var onFormStateChange = onFormStateChangeOpt !== undefined ? onFormStateChangeOpt : (function (prim) {
          
        });
    var validate = validateOpt !== undefined ? validateOpt : (function (param) {
          
        });
    var i18n = i18nOpt !== undefined ? i18nOpt : ReForm_Validation.I18n.en;
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var handleChange = function (field, value) {
                  return Curry._1(self.send, /* HandleChange */Block.__(4, [/* tuple */[
                                  field,
                                  value
                                ]]));
                };
                var handleGlobalValidation = function (error) {
                  return Curry._1(self.send, /* HandleError */Block.__(3, [error]));
                };
                var handleSubmit = function (param) {
                  var globalValidationError = Curry._1(validate, self.state.values);
                  var fieldsValidationErrors = List.filter((function (param) {
                            return param[1] !== undefined;
                          }))(List.map((function (param) {
                              var fieldName = param[0];
                              var match = getFieldLens(fieldName);
                              return /* tuple */[
                                      fieldName,
                                      validateField(fieldName, self.state.values, Curry._1(match[1], self.state.values), schema, i18n)
                                    ];
                            }), schema));
                  Curry._1(self.send, /* SetFieldsErrors */Block.__(1, [fieldsValidationErrors]));
                  Curry._1(self.send, /* HandleError */Block.__(3, [globalValidationError]));
                  if (globalValidationError === undefined && List.length(fieldsValidationErrors) === 0) {
                    return Curry._1(self.send, /* HandleSubmit */0);
                  }
                  
                };
                var getErrorForField = function (field) {
                  return $great$great$eq(safeHd(List.map((function (param) {
                                        return param[1];
                                      }), List.filter((function (param) {
                                              return param[0] === field;
                                            }))(self.state.errors))), (function (i) {
                                return i;
                              }));
                };
                return Curry._1(children, {
                            form: self.state,
                            handleChange: handleChange,
                            handleGlobalValidation: handleGlobalValidation,
                            handleSubmit: handleSubmit,
                            getErrorForField: getErrorForField
                          });
              }),
            initialState: (function (param) {
                return {
                        values: initialState,
                        isSubmitting: false,
                        errors: /* [] */0,
                        error: undefined
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, state) {
                if (typeof action === "number") {
                  if (action === /* HandleSubmit */0) {
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                values: state.values,
                                isSubmitting: true,
                                errors: state.errors,
                                error: state.error
                              },
                              (function (self) {
                                  Curry._1(onSubmit, {
                                        values: state.values,
                                        setSubmitting: (function (isSubmitting) {
                                            return Curry._1(self.send, /* HandleSubmitting */Block.__(0, [isSubmitting]));
                                          }),
                                        setError: (function (error) {
                                            return Curry._1(self.send, /* HandleError */Block.__(3, [error]));
                                          }),
                                        resetFormState: (function (param) {
                                            return Curry._1(self.send, /* ResetFormState */1);
                                          })
                                      });
                                  return Curry._1(onFormStateChange, self.state);
                                })
                            ]);
                  } else {
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                values: initialState,
                                isSubmitting: false,
                                errors: /* [] */0,
                                error: state.error
                              },
                              (function (self) {
                                  return Curry._1(onFormStateChange, self.state);
                                })
                            ]);
                  }
                }
                switch (action.tag | 0) {
                  case /* HandleSubmitting */0 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  values: state.values,
                                  isSubmitting: action[0],
                                  errors: state.errors,
                                  error: state.error
                                },
                                (function (self) {
                                    return Curry._1(onFormStateChange, self.state);
                                  })
                              ]);
                  case /* SetFieldsErrors */1 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  values: state.values,
                                  isSubmitting: false,
                                  errors: action[0],
                                  error: state.error
                                },
                                (function (self) {
                                    return Curry._1(onFormStateChange, self.state);
                                  })
                              ]);
                  case /* HandleFieldValidation */2 :
                      var match = action[0];
                      var field = match[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  values: state.values,
                                  isSubmitting: state.isSubmitting,
                                  errors: List.append(/* :: */[
                                        /* tuple */[
                                          field,
                                          validateField(field, state.values, match[1], schema, i18n)
                                        ],
                                        /* [] */0
                                      ], List.filter((function (param) {
                                                return param[0] !== field;
                                              }))(state.errors)),
                                  error: state.error
                                },
                                (function (self) {
                                    return Curry._1(onFormStateChange, self.state);
                                  })
                              ]);
                  case /* HandleError */3 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  values: state.values,
                                  isSubmitting: false,
                                  errors: state.errors,
                                  error: action[0]
                                },
                                (function (self) {
                                    return Curry._1(onFormStateChange, self.state);
                                  })
                              ]);
                  case /* HandleChange */4 :
                      var match$1 = action[0];
                      var value = match$1[1];
                      var field$1 = match$1[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  values: handleChange(/* tuple */[
                                        field$1,
                                        value
                                      ], state.values),
                                  isSubmitting: state.isSubmitting,
                                  errors: state.errors,
                                  error: state.error
                                },
                                (function (self) {
                                    return Curry._1(self.send, /* HandleFieldValidation */Block.__(2, [/* tuple */[
                                                    field$1,
                                                    value
                                                  ]]));
                                  })
                              ]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          Field: Field,
          component: component,
          make: make
        };
}

var Helpers;

var Validation;

var Value;

exports.Helpers = Helpers;
exports.Validation = Validation;
exports.Value = Value;
exports.Create = Create;
/* ReasonReact Not a pure module */
