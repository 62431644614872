// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var Issues_assignee$BsConsole = require("./bs-crdb-response/src/Issues_assignee.js");

function revitalizeGhost(username, projectUsers) {
  var user = Belt_Array.getBy(projectUsers, (function (d) {
          return d.username === username;
        }));
  return Belt_Option.map(user, (function (user) {
                return /* User */Block.__(0, [{
                            watcher: "_backtrace",
                            username: user.username,
                            email: user.email,
                            id: user.uid
                          }]);
              }));
}

function getNonIssueAggs(group) {
  return Belt_List.keep(group.aggregations, (function (agg) {
                switch (agg.tag | 0) {
                  case /* Head */3 :
                      switch (agg[0]) {
                        case "callstack" :
                        case "fingerprint;first_seen" :
                        case "fingerprint;issues;assignee" :
                        case "fingerprint;issues;id" :
                        case "fingerprint;issues;invariants" :
                        case "fingerprint;issues;state" :
                        case "fingerprint;issues;tags" :
                        case "fingerprint;issues;ticket" :
                            return false;
                        default:
                          return true;
                      }
                  case /* Bin */2 :
                  case /* Range */8 :
                      return agg[0] !== "timestamp";
                  default:
                    return true;
                }
              }));
}

function getNonIssueColumnsForTable(columns) {
  return Belt_List.toArray(Belt_List.keep(columns, (function (col) {
                    var aggType = col.op;
                    if (aggType === undefined) {
                      return false;
                    }
                    switch (aggType) {
                      case /* Head */3 :
                          var match = col.name;
                          switch (match) {
                            case "callstack" :
                            case "fingerprint;first_seen" :
                            case "fingerprint;issues;assignee" :
                            case "fingerprint;issues;id" :
                            case "fingerprint;issues;invariants" :
                            case "fingerprint;issues;state" :
                            case "fingerprint;issues;tags" :
                            case "fingerprint;issues;ticket" :
                                return false;
                            default:
                              return true;
                          }
                      case /* Bin */2 :
                      case /* Range */5 :
                          return col.name !== "timestamp";
                      case /* Unique */0 :
                      case /* Distribution */1 :
                      case /* Tail */4 :
                      case /* Mean */6 :
                      case /* Sum */7 :
                      case /* Min */8 :
                      case /* Max */9 :
                          return true;
                      
                    }
                  })));
}

function parseColumns(projectUsers, columns, seq, group) {
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var ghostlyPresence = Group$BsConsole.unwrapHeadString(getter(Issues$BsConsole.Assignees.columnName, /* Head */3));
  var zombie = ghostlyPresence !== undefined ? revitalizeGhost(ghostlyPresence, projectUsers) : undefined;
  var properTickets = Group$BsConsole.unwrapTickets(getter(Issues$BsConsole.Tickets.columnName, /* Head */3));
  var tickets = properTickets !== undefined ? (
      zombie !== undefined ? Belt_List.concat(properTickets, /* :: */[
              Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                    zombie,
                    /* [] */0
                  ]),
              /* [] */0
            ]) : properTickets
    ) : (
      zombie !== undefined ? /* :: */[
          Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                zombie,
                /* [] */0
              ]),
          /* [] */0
        ] : properTickets
    );
  var assignees = Belt_Option.map(Belt_Option.map(tickets, (function (tickets) {
              return Belt_List.reduce(tickets, /* [] */0, (function (acc, t) {
                            switch (t.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  break;
                              case /* InteralAssigneeLink */2 :
                                  var assignees = t[0].assignees;
                                  if (assignees !== undefined) {
                                    return Belt_List.concat(acc, assignees);
                                  } else {
                                    return acc;
                                  }
                              
                            }
                            var assignees$1 = t[0].assignees;
                            if (assignees$1 !== undefined) {
                              return Belt_List.concat(acc, assignees$1);
                            } else {
                              return acc;
                            }
                          }));
            })), (function (d) {
          return d;
        }));
  var aggregations = Belt_List.toArray(getNonIssueAggs(group));
  var callstack = Group$BsConsole.unwrapCallstack(getter("callstack", /* Head */3));
  var timestampRange = Group$BsConsole.unwrapDateRange(getter("timestamp", /* Range */5));
  var timestampBin = Group$BsConsole.unwrapBin(getter("timestamp", /* Bin */2));
  var firstSeen = Group$BsConsole.unwrapHeadFirstSeen(getter("fingerprint;first_seen", /* Head */3));
  var issues_state = Group$BsConsole.unwrapIssueState(getter(Issues$BsConsole.State.columnName, /* Head */3));
  var issues_assignees = assignees !== undefined && !assignees ? undefined : assignees;
  var issues_tags = Group$BsConsole.unwrapTags(getter(Issues$BsConsole.Tags.columnName, /* Head */3));
  var issues_tickets = tickets !== undefined && !tickets ? undefined : tickets;
  var issues_invariants = Issues$BsConsole.Invariant.fromJsonStr(Group$BsConsole.unwrapHeadString(getter(Issues$BsConsole.Invariant.columnName, /* Head */3)));
  var issues_id = Group$BsConsole.unwrapIssueId(getter(Issues$BsConsole.Id.columnName, /* Head */3));
  var issues = {
    state: issues_state,
    assignees: issues_assignees,
    tags: issues_tags,
    tickets: issues_tickets,
    seq: seq,
    invariants: issues_invariants,
    id: issues_id
  };
  return /* tuple */[
          issues,
          callstack,
          timestampRange,
          timestampBin,
          firstSeen,
          aggregations
        ];
}

function parseIssueColumns(projectUsers, columns, seq, group) {
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var ghostlyPresence = Group$BsConsole.unwrapHeadString(getter(Issues$BsConsole.Assignees.columnName, /* Head */3));
  var zombie = ghostlyPresence !== undefined ? revitalizeGhost(ghostlyPresence, projectUsers) : undefined;
  var properTickets = Group$BsConsole.unwrapTickets(getter(Issues$BsConsole.Tickets.columnName, /* Head */3));
  var tickets = properTickets !== undefined ? (
      zombie !== undefined ? Belt_List.concat(properTickets, /* :: */[
              Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                    zombie,
                    /* [] */0
                  ]),
              /* [] */0
            ]) : properTickets
    ) : (
      zombie !== undefined ? /* :: */[
          Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                zombie,
                /* [] */0
              ]),
          /* [] */0
        ] : properTickets
    );
  var assignees = Belt_Option.map(Belt_Option.map(tickets, (function (tickets) {
              return Belt_List.reduce(tickets, /* [] */0, (function (acc, t) {
                            switch (t.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  break;
                              case /* InteralAssigneeLink */2 :
                                  var assignees = t[0].assignees;
                                  if (assignees !== undefined) {
                                    return Belt_List.concat(acc, assignees);
                                  } else {
                                    return acc;
                                  }
                              
                            }
                            var assignees$1 = t[0].assignees;
                            if (assignees$1 !== undefined) {
                              return Belt_List.concat(acc, assignees$1);
                            } else {
                              return acc;
                            }
                          }));
            })), (function (d) {
          return d;
        }));
  return {
          state: Group$BsConsole.unwrapIssueState(getter(Issues$BsConsole.State.columnName, /* Head */3)),
          assignees: assignees !== undefined && !assignees ? undefined : assignees,
          tags: Group$BsConsole.unwrapTags(getter(Issues$BsConsole.Tags.columnName, /* Head */3)),
          tickets: tickets !== undefined && !tickets ? undefined : tickets,
          seq: seq,
          invariants: Issues$BsConsole.Invariant.fromJsonStr(Group$BsConsole.unwrapHeadString(getter(Issues$BsConsole.Invariant.columnName, /* Head */3))),
          id: Group$BsConsole.unwrapIssueId(getter(Issues$BsConsole.Id.columnName, /* Head */3))
        };
}

function fromCrdbResponseGroup(projectUsers, columns, seq, groups) {
  var triageGroups = Belt_List.toArray(Belt_List.map(groups, (function (group) {
              var match = parseColumns(projectUsers, columns, seq, group);
              return {
                      count: group.count,
                      fingerprint: group.factor.value,
                      description: undefined,
                      callstack: match[1],
                      timestampRange: match[2],
                      timestampBin: match[3],
                      firstSeen: match[4],
                      aggregations: match[5],
                      issues: match[0]
                    };
            })));
  var aggColumns = getNonIssueColumnsForTable(columns);
  return /* tuple */[
          triageGroups,
          aggColumns
        ];
}

function isGroup(f) {
  var zeroGroup = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{64}$").exec(f)));
  var hasZeros = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{54}.+$").exec(f)));
  if (zeroGroup || !hasZeros) {
    return false;
  } else {
    return true;
  }
}

function formatFingerprint(f) {
  if (isGroup(f)) {
    return f.replace(new RegExp("^(0{57})"), "");
  } else {
    return f;
  }
}

function toIssuesSetJsonTuple(v) {
  switch (v.tag | 0) {
    case /* State */0 :
        return /* tuple */[
                "state",
                Issues$BsConsole.State.toJson(v[0])
              ];
    case /* Invariants */1 :
        return /* tuple */[
                "invariants",
                Issues$BsConsole.Invariant.toJson(v[0])
              ];
    case /* Tickets */2 :
        var v$1 = v[0];
        if (v$1 !== undefined) {
          return /* tuple */[
                  "ticket",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "action",
                          "set"
                        ],
                        /* :: */[
                          /* tuple */[
                            "assignee",
                            "set"
                          ],
                          /* :: */[
                            /* tuple */[
                              "value",
                              Issues$BsConsole.Tickets.toJson(v$1)
                            ],
                            /* [] */0
                          ]
                        ]
                      ])
                ];
        } else {
          return /* tuple */[
                  "ticket",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "action",
                          "reset"
                        ],
                        /* [] */0
                      ])
                ];
        }
    case /* Tags */3 :
        var v$2 = v[0];
        if (v$2 !== undefined) {
          return /* tuple */[
                  "tags",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "action",
                          "set"
                        ],
                        /* :: */[
                          /* tuple */[
                            "value",
                            Issues$BsConsole.Tags.toJson(v$2)
                          ],
                          /* [] */0
                        ]
                      ])
                ];
        } else {
          return /* tuple */[
                  "tags",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "action",
                          "reset"
                        ],
                        /* [] */0
                      ])
                ];
        }
    case /* Assignees */4 :
        return /* tuple */[
                "assignee",
                null
              ];
    
  }
}

function toJsonTuple(v) {
  switch (v.tag | 0) {
    case /* State */0 :
        return /* tuple */[
                "state",
                Issues$BsConsole.State.toJson(v[0])
              ];
    case /* Invariants */1 :
        return /* tuple */[
                "invariants",
                Issues$BsConsole.Invariant.toJson(v[0])
              ];
    case /* Tickets */2 :
        var v$1 = v[0];
        if (v$1 !== undefined) {
          return /* tuple */[
                  "ticket",
                  JSON.stringify(Issues$BsConsole.Tickets.toJson(v$1))
                ];
        } else {
          return /* tuple */[
                  "ticket",
                  null
                ];
        }
    case /* Tags */3 :
        var v$2 = v[0];
        if (v$2 !== undefined) {
          return /* tuple */[
                  "tags",
                  Issues$BsConsole.Tags.toJson(v$2)
                ];
        } else {
          return /* tuple */[
                  "tags",
                  null
                ];
        }
    case /* Assignees */4 :
        return /* tuple */[
                "assignee",
                null
              ];
    
  }
}

function uniq(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          uniq(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(v, hd);
                    })))
        ];
}

function mergeSettingActions(actions) {
  var tickets = Belt_List.keep(actions, (function (action) {
          if (action.tag === /* Tickets */2) {
            return true;
          } else {
            return false;
          }
        }));
  var hasTicketSetting = Belt_List.length(tickets) > 0;
  var notTickets = Belt_List.keep(actions, (function (action) {
          if (action.tag === /* Tickets */2) {
            return false;
          } else {
            return true;
          }
        }));
  var mergedTickets = Belt_List.reduce(tickets, /* [] */0, (function (acc, action) {
          if (action.tag !== /* Tickets */2) {
            return acc;
          }
          var ts = action[0];
          if (ts !== undefined) {
            return Belt_List.concat(ts, acc);
          } else {
            return acc;
          }
        }));
  var ticketsAction = hasTicketSetting ? (
      mergedTickets ? /* :: */[
          /* Tickets */Block.__(2, [mergedTickets]),
          /* [] */0
        ] : /* :: */[
          /* Tickets */Block.__(2, [undefined]),
          /* [] */0
        ]
    ) : /* [] */0;
  var uniq = function (l) {
    if (!l) {
      return /* [] */0;
    }
    var hd = l[0];
    return /* :: */[
            hd,
            uniq(Belt_List.keep(l[1], (function (v) {
                        return Caml_obj.caml_notequal(v, hd);
                      })))
          ];
  };
  return uniq(Belt_List.concat(ticketsAction, notTickets));
}

var Setting = {
  toIssuesSetJsonTuple: toIssuesSetJsonTuple,
  toJsonTuple: toJsonTuple,
  uniq: uniq,
  mergeSettingActions: mergeSettingActions
};

function toSettingActions(actions, group) {
  return Belt_List.flatten(Belt_List.map(Belt_List.flatten(Belt_List.map(actions, (function (inW) {
                            switch (inW.tag | 0) {
                              case /* SetTicketsAssignment */0 :
                                  var user = inW[2];
                                  var ticketsToChange = inW[1];
                                  var backtraceTickets = inW[0] ? (
                                      user !== undefined ? /* :: */[
                                          Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                                                /* User */Block.__(0, [{
                                                      watcher: "_backtrace",
                                                      username: user.username,
                                                      email: user.email,
                                                      id: user.uid
                                                    }]),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ] : /* :: */[
                                          Issues$BsConsole.Ticket.makeBacktraceIssueTicket(undefined),
                                          /* [] */0
                                        ]
                                    ) : Issues$BsConsole.Tickets.getBTs(group.issues.tickets);
                                  var unlinkedTickets = Issues$BsConsole.Tickets.getUnlinked(group.issues.tickets);
                                  var linkedTickets = Issues$BsConsole.Tickets.getLinked(group.issues.tickets);
                                  var updatedLinkedTickets = Belt_Option.map(linkedTickets, (function (tickets) {
                                          return Belt_List.map(tickets, (function (ticket) {
                                                        if (!Belt_Option.isSome(Belt_List.getBy(ticketsToChange, (function (param) {
                                                                      switch (ticket.tag | 0) {
                                                                        case /* Linked */1 :
                                                                            var linkedTicket = ticket[0];
                                                                            if (param[0] === linkedTicket.short) {
                                                                              return param[1] === linkedTicket.watcher;
                                                                            } else {
                                                                              return false;
                                                                            }
                                                                        case /* Unlinked */0 :
                                                                        case /* InteralAssigneeLink */2 :
                                                                            return false;
                                                                        
                                                                      }
                                                                    })))) {
                                                          return ticket;
                                                        }
                                                        switch (ticket.tag | 0) {
                                                          case /* Linked */1 :
                                                              var linkTicket = ticket[0];
                                                              var assignees = Belt_Option.map(user, (function (user) {
                                                                      return /* :: */[
                                                                              Issues_assignee$BsConsole.make(linkTicket.watcher, user.username, user.email, user.uid, undefined),
                                                                              /* [] */0
                                                                            ];
                                                                    }));
                                                              return Issues$BsConsole.Ticket.setAssignees(ticket, assignees);
                                                          case /* Unlinked */0 :
                                                          case /* InteralAssigneeLink */2 :
                                                              return ticket;
                                                          
                                                        }
                                                      }));
                                        }));
                                  return /* :: */[
                                          /* Tickets */Block.__(2, [backtraceTickets]),
                                          /* :: */[
                                            /* Tickets */Block.__(2, [unlinkedTickets]),
                                            /* :: */[
                                              /* Tickets */Block.__(2, [updatedLinkedTickets]),
                                              /* [] */0
                                            ]
                                          ]
                                        ];
                              case /* SetState */1 :
                                  var t = inW[0];
                                  if (CoronerdGate$BsConsole.isStateMachineDisabled) {
                                    return /* :: */[
                                            /* State */Block.__(0, [t]),
                                            /* [] */0
                                          ];
                                  }
                                  var t$1;
                                  if (t !== 0) {
                                    t$1 = t;
                                  } else {
                                    var match = group.issues.tickets;
                                    t$1 = match !== undefined ? /* InProgress */1 : /* Open */0;
                                  }
                                  return /* :: */[
                                          /* State */Block.__(0, [t$1]),
                                          /* [] */0
                                        ];
                              case /* SetInvariant */2 :
                                  return /* :: */[
                                          /* Invariants */Block.__(1, [inW[0]]),
                                          /* [] */0
                                        ];
                              case /* SetBtAssignee */3 :
                                  var u = inW[0];
                                  if (u === undefined) {
                                    return /* :: */[
                                            /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getUnlinked(group.issues.tickets)]),
                                            /* :: */[
                                              /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getLinked(group.issues.tickets)]),
                                              /* [] */0
                                            ]
                                          ];
                                  }
                                  var previousBt = Issues$BsConsole.Tickets.getBTs(group.issues.tickets);
                                  var hopefulBtTicket = Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                                        u,
                                        /* [] */0
                                      ]);
                                  var alreadyHadThatAssignee;
                                  if (previousBt !== undefined) {
                                    var bt = Belt_List.get(previousBt, 0);
                                    if (bt !== undefined) {
                                      switch (bt.tag | 0) {
                                        case /* Unlinked */0 :
                                        case /* Linked */1 :
                                            alreadyHadThatAssignee = true;
                                            break;
                                        case /* InteralAssigneeLink */2 :
                                            alreadyHadThatAssignee = Caml_obj.caml_equal(bt[0].assignees, /* :: */[
                                                  u,
                                                  /* [] */0
                                                ]) ? false : true;
                                            break;
                                        
                                      }
                                    } else {
                                      alreadyHadThatAssignee = true;
                                    }
                                  } else {
                                    alreadyHadThatAssignee = true;
                                  }
                                  if (alreadyHadThatAssignee) {
                                    return /* :: */[
                                            /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getUnlinked(group.issues.tickets)]),
                                            /* :: */[
                                              /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getLinked(group.issues.tickets)]),
                                              /* :: */[
                                                /* Tickets */Block.__(2, [/* :: */[
                                                      hopefulBtTicket,
                                                      /* [] */0
                                                    ]]),
                                                /* [] */0
                                              ]
                                            ]
                                          ];
                                  } else {
                                    return /* [] */0;
                                  }
                              case /* SetUnlinkedTicket */4 :
                                  return /* :: */[
                                          /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getBTs(group.issues.tickets)]),
                                          /* :: */[
                                            /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getLinked(group.issues.tickets)]),
                                            /* :: */[
                                              /* Tickets */Block.__(2, [/* :: */[
                                                    inW[0],
                                                    /* [] */0
                                                  ]]),
                                              /* [] */0
                                            ]
                                          ]
                                        ];
                              case /* AddUnlinkedTicket */5 :
                                  return /* :: */[
                                          /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getUnlinked(group.issues.tickets)]),
                                          /* :: */[
                                            /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getBTs(group.issues.tickets)]),
                                            /* :: */[
                                              /* Tickets */Block.__(2, [Issues$BsConsole.Tickets.getLinked(group.issues.tickets)]),
                                              /* :: */[
                                                /* Tickets */Block.__(2, [/* :: */[
                                                      inW[0],
                                                      /* [] */0
                                                    ]]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ];
                              case /* RemoveTickets */6 :
                                  var ticketsToRemove = inW[0];
                                  var tickets = group.issues.tickets;
                                  if (tickets === undefined) {
                                    return /* :: */[
                                            /* Tickets */Block.__(2, [undefined]),
                                            /* [] */0
                                          ];
                                  }
                                  var tics = Belt_List.keep(tickets, (function (ticket) {
                                          return Belt_Option.isNone(Belt_List.getBy(ticketsToRemove, (function (ticket$prime) {
                                                            return Issues$BsConsole.Ticket.eq(ticket$prime, ticket);
                                                          })));
                                        }));
                                  if (tics) {
                                    return /* :: */[
                                            /* Tickets */Block.__(2, [tics]),
                                            /* [] */0
                                          ];
                                  } else {
                                    return /* :: */[
                                            /* Tickets */Block.__(2, [undefined]),
                                            /* [] */0
                                          ];
                                  }
                              case /* RemoveTags */7 :
                                  var tags = inW[0];
                                  var existingTags = group.issues.tags;
                                  if (existingTags === undefined) {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [undefined]),
                                            /* [] */0
                                          ];
                                  }
                                  var newTags = Belt_List.keep(existingTags, (function (t) {
                                          return Belt_Option.isNone(Belt_List.getBy(tags, (function (t$prime) {
                                                            return t$prime === t;
                                                          })));
                                        }));
                                  if (newTags) {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [newTags]),
                                            /* [] */0
                                          ];
                                  } else {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [undefined]),
                                            /* [] */0
                                          ];
                                  }
                              case /* AddTags */8 :
                                  var tags$1 = inW[0];
                                  var existingTags$1 = group.issues.tags;
                                  if (existingTags$1 !== undefined) {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [Issues$BsConsole.Tags.uniq(Belt_List.concat(existingTags$1, tags$1))]),
                                            /* [] */0
                                          ];
                                  } else {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [tags$1]),
                                            /* [] */0
                                          ];
                                  }
                              case /* AddRemoveTags */9 :
                                  var removedTags = inW[1];
                                  var addedTags = inW[0];
                                  var existingTags$2 = group.issues.tags;
                                  var tagsAfterAdd = existingTags$2 !== undefined ? Issues$BsConsole.Tags.uniq(Belt_List.concat(existingTags$2, addedTags)) : addedTags;
                                  var tagsAfterAddRemove = Belt_List.keep(tagsAfterAdd, (function (t) {
                                          return Belt_Option.isNone(Belt_List.getBy(removedTags, (function (t$prime) {
                                                            return t$prime === t;
                                                          })));
                                        }));
                                  if (tagsAfterAddRemove) {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [tagsAfterAddRemove]),
                                            /* [] */0
                                          ];
                                  } else {
                                    return /* :: */[
                                            /* Tags */Block.__(3, [undefined]),
                                            /* [] */0
                                          ];
                                  }
                              
                            }
                          }))), (function (action) {
                    if (action.tag === /* Tickets */2) {
                      return /* :: */[
                              action,
                              /* :: */[
                                /* Assignees */Block.__(4, [undefined]),
                                /* [] */0
                              ]
                            ];
                    } else {
                      return /* :: */[
                              action,
                              /* [] */0
                            ];
                    }
                  })));
}

var Actions = {
  Setting: Setting,
  toSettingActions: toSettingActions
};

function assignTickets(handleSetIssue, tickets, projectUser, backtraceChecked, param) {
  var ticketAssigneeActions_001 = Belt_List.map(Belt_List.keep(Belt_Option.getWithDefault(tickets, /* [] */0), (function (param) {
              return param[1];
            })), (function (param) {
          return param[0];
        }));
  var ticketAssigneeActions = /* SetTicketsAssignment */Block.__(0, [
      backtraceChecked,
      ticketAssigneeActions_001,
      projectUser
    ]);
  return Curry._2(handleSetIssue, /* :: */[
              ticketAssigneeActions,
              /* [] */0
            ], undefined);
}

var QuickActions = {
  assignTickets: assignTickets
};

exports.revitalizeGhost = revitalizeGhost;
exports.getNonIssueAggs = getNonIssueAggs;
exports.getNonIssueColumnsForTable = getNonIssueColumnsForTable;
exports.parseColumns = parseColumns;
exports.parseIssueColumns = parseIssueColumns;
exports.fromCrdbResponseGroup = fromCrdbResponseGroup;
exports.isGroup = isGroup;
exports.formatFingerprint = formatFingerprint;
exports.Actions = Actions;
exports.QuickActions = QuickActions;
/* Issues-BsConsole Not a pure module */
