// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Belt_MutableQueue = require("bs-platform/lib/js/belt_MutableQueue.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var MetricsNotification$BsConsole = require("../bs-metrics/MetricsNotification.js");

var processBannerEventName = "process-banner";

var localStoragePrefix = "_Backtrace_banner_";

var metricsNamespace = "banner/";

var queue = Belt_MutableQueue.make(undefined);

var displaying = {
  contents: false
};

function noRecentDismissal(notificationItem) {
  var c = notificationItem.cache;
  if (c === undefined) {
    return true;
  }
  var id = c.id;
  var storedBanner = localStorage.getItem("" + (String(localStoragePrefix) + ("" + (String(id) + ""))));
  if (storedBanner === null) {
    return true;
  }
  try {
    return Caml_format.caml_float_of_string(storedBanner) <= Date.now();
  }
  catch (exn){
    return true;
  }
}

function setCacheEntry(notificationItem) {
  var c = notificationItem.cache;
  if (c === undefined) {
    return ;
  }
  var id = c.id;
  var delayMs = c.minutes * 60000;
  var nowMs = Date.now();
  var expireTime = nowMs + delayMs;
  localStorage.setItem("" + (String(localStoragePrefix) + ("" + (String(id) + ""))), Pervasives.string_of_float(expireTime));
  
}

function enqueue(notificationItem) {
  if (noRecentDismissal(notificationItem)) {
    Belt_MutableQueue.add(queue, notificationItem);
    return SnackPurveyor$BsConsole.dispatchEvent(processBannerEventName);
  }
  
}

function BannerPurveyor$Banner(Props) {
  var message = Props.message;
  var cta = Props.cta;
  var close = Props.close;
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.position(/* fixed */10615156),
                        /* :: */[
                          Css.top(/* zero */-789508312),
                          /* :: */[
                            Css.padding(Css.px(8)),
                            /* :: */[
                              Css.zIndex(1101),
                              /* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.blueLighter)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey2)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ])
              },
              square: true,
              elevation: 1,
              children: React.createElement(Col2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.showSkip(message)
                            })
                      }), React.createElement(Row2$BsConsole.make, {
                        children: null
                      }, React.createElement(Button.default, {
                            color: "primary",
                            onClick: (function (param) {
                                MetricsNotification$BsConsole.Send.send(/* DismissClick */Block.__(1, [{
                                          nameSpace: metricsNamespace,
                                          details: message
                                        }]));
                                return Curry._1(close, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Got it")
                          }), cta !== undefined ? React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  MetricsNotification$BsConsole.Send.send(/* CtaClick */Block.__(0, [{
                                            nameSpace: metricsNamespace,
                                            details: message
                                          }]));
                                  Curry._1(cta.action, undefined);
                                  return Curry._1(close, undefined);
                                }),
                              children: I18N$BsConsole.showSkip(cta.title)
                            }) : null))
            });
}

var Banner = {
  make: BannerPurveyor$Banner
};

function BannerPurveyor(Props) {
  var match = React.useState((function () {
          
        }));
  var setNotification = match[1];
  var notification = match[0];
  var close = function (param) {
    if (notification !== undefined) {
      setCacheEntry(notification);
    }
    displaying.contents = false;
    return Curry._1(setNotification, (function (param) {
                  
                }));
  };
  React.useEffect((function () {
          if (notification !== undefined) {
            var match = notification.bannerItem;
            var match$1 = notification.el;
            if (match !== undefined && match$1 === undefined) {
              MetricsNotification$BsConsole.Send.send(/* Impression */Block.__(2, [{
                        nameSpace: metricsNamespace,
                        details: match.message
                      }]));
            }
            
          } else {
            SnackPurveyor$BsConsole.dispatchEvent(processBannerEventName);
          }
          
        }), [notification]);
  React.useEffect((function () {
          var processBanner = function (param) {
            if (displaying.contents) {
              return ;
            }
            var next = Belt_MutableQueue.pop(queue);
            if (next !== undefined) {
              displaying.contents = true;
            } else {
              displaying.contents = false;
            }
            return Curry._1(setNotification, (function (param) {
                          return next;
                        }));
          };
          window.addEventListener(processBannerEventName, processBanner);
          return (function (param) {
                    window.removeEventListener(processBannerEventName, processBanner);
                    
                  });
        }), []);
  if (notification === undefined) {
    return null;
  }
  var match$1 = notification.bannerItem;
  var match$2 = notification.el;
  if (match$1 !== undefined) {
    if (match$2 !== undefined) {
      return null;
    } else {
      return React.createElement(BannerPurveyor$Banner, {
                  message: match$1.message,
                  cta: match$1.cta,
                  close: close
                });
    }
  } else if (match$2 !== undefined) {
    return Curry._1(match$2, close);
  } else {
    return null;
  }
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(BannerPurveyor, { }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = BannerPurveyor;

exports.processBannerEventName = processBannerEventName;
exports.localStoragePrefix = localStoragePrefix;
exports.metricsNamespace = metricsNamespace;
exports.queue = queue;
exports.displaying = displaying;
exports.noRecentDismissal = noRecentDismissal;
exports.setCacheEntry = setCacheEntry;
exports.enqueue = enqueue;
exports.Banner = Banner;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* queue Not a pure module */
