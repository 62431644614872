// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("bs-platform/lib/js/js_exn.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");

function toLabel(param) {
  switch (param) {
    case /* GreaterThan */0 :
        return ">";
    case /* GreaterThanOrEqual */1 :
        return ">=";
    case /* Equal */2 :
        return "==";
    case /* LessThanOrEqual */3 :
        return "<=";
    case /* LessThan */4 :
        return "<";
    case /* NotEqual */5 :
        return "!=";
    
  }
}

function toValue(param) {
  switch (param) {
    case /* GreaterThan */0 :
        return "string_gt";
    case /* GreaterThanOrEqual */1 :
        return "string_ge";
    case /* Equal */2 :
        return "string_eq";
    case /* LessThanOrEqual */3 :
        return "string_le";
    case /* LessThan */4 :
        return "string_lt";
    case /* NotEqual */5 :
        return "string_ne";
    
  }
}

function fromValue(param) {
  switch (param) {
    case "string_eq" :
        return /* Equal */2;
    case "string_ge" :
        return /* GreaterThanOrEqual */1;
    case "string_gt" :
        return /* GreaterThan */0;
    case "string_le" :
        return /* LessThanOrEqual */3;
    case "string_lt" :
        return /* LessThan */4;
    case "string_ne" :
        return /* NotEqual */5;
    default:
      return Js_exn.raiseError(SSAUtils$BsConsole.unsupportedCondition);
  }
}

var options = [
  /* GreaterThan */0,
  /* GreaterThanOrEqual */1,
  /* Equal */2,
  /* LessThanOrEqual */3,
  /* LessThan */4,
  /* NotEqual */5
];

var $$default = /* GreaterThan */0;

exports.toLabel = toLabel;
exports.toValue = toValue;
exports.fromValue = fromValue;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.options = options;
/* SSAUtils-BsConsole Not a pure module */
