// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../I18N.js");

function toDefaultValue(param) {
  switch (param) {
    case /* Threshold */0 :
        return 5;
    case /* Truncate */1 :
        return 50;
    case /* Limit */2 :
        return 1024;
    
  }
}

function toTitleString(param) {
  switch (param) {
    case /* Threshold */0 :
        return I18N$BsConsole.get(undefined, "Threshold");
    case /* Truncate */1 :
        return I18N$BsConsole.get(undefined, "Truncate");
    case /* Limit */2 :
        return I18N$BsConsole.get(undefined, "Limit");
    
  }
}

function toSubtitleString(param) {
  switch (param) {
    case /* Threshold */0 :
        return I18N$BsConsole.get(undefined, "Set a minimum callstack length to be considered a candidate.");
    case /* Truncate */1 :
        return I18N$BsConsole.get(undefined, "Shorten the callstack to this many frames before comparing.");
    case /* Limit */2 :
        return I18N$BsConsole.get(undefined, "Restrict the number of potential candidates.");
    
  }
}

var Hardcoded = {
  toDefaultValue: toDefaultValue,
  toTitleString: toTitleString,
  toSubtitleString: toSubtitleString
};

function toDefaultValue$1(param) {
  if (param) {
    return /* Int */[3];
  } else {
    return /* Int */[5];
  }
}

function toDynamic(param) {
  return /* Dynamic */0;
}

function toTitleString$1(param) {
  if (param) {
    return I18N$BsConsole.get(undefined, "Intersection");
  } else {
    return I18N$BsConsole.get(undefined, "Distance");
  }
}

function toSubtitleString$1(param) {
  if (param) {
    return I18N$BsConsole.get(undefined, "Require candidate callstacks to have at least this many frames in common.");
  } else {
    return I18N$BsConsole.get(undefined, "Set the maximum distance allowed between callstacks to be considered a candidate.");
  }
}

var Dynamic = {
  toDefaultValue: toDefaultValue$1,
  toDynamic: toDynamic,
  toTitleString: toTitleString$1,
  toSubtitleString: toSubtitleString$1
};

exports.Hardcoded = Hardcoded;
exports.Dynamic = Dynamic;
/* I18N-BsConsole Not a pure module */
