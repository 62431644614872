// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Crdb$BsConsole = require("../../crdb.js");
var I18N$BsConsole = require("../../I18N.js");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");
var Tooltip = require("@material-ui/core/Tooltip");

var attachmentPrefix = "bt-breadcrumbs-";

var partial_arg_000 = Crdb$BsConsole.FilterOperation.parseStringExn;

var partial_arg_001 = /* :: */[
  Crdb$BsConsole.FilterOperation.parseIntExn,
  /* :: */[
    Crdb$BsConsole.FilterOperation.parseFloatExn,
    /* [] */0
  ]
];

var partial_arg = /* :: */[
  partial_arg_000,
  partial_arg_001
];

function lastIdFromJson(param) {
  return Json_decode.oneOf(partial_arg, param);
}

function sortIdDesc(breadcrumbs) {
  return Belt_SortArray.stableSortBy(breadcrumbs, (function (a, b) {
                return Caml_int64.to_int32(Caml_int64.sub(b.id, a.id));
              }));
}

function hasBreadcrumbFiles(attachments) {
  if (typeof attachments === "number") {
    return false;
  }
  if (attachments.tag) {
    return false;
  }
  var breadcrumbFiles = attachments[0][0].filter((function (file) {
          return file.name.startsWith(attachmentPrefix);
        }));
  return breadcrumbFiles.length !== 0;
}

function dateTimeMsString(timestamp) {
  var date = new Date(timestamp);
  var valueWithoutMilliseconds = L10N_date_time$BsConsole.Format.numericDayTwentyFourToSecond(undefined, date);
  var msStr = String(date.getMilliseconds() | 0);
  var match = msStr.length;
  var ms;
  switch (match) {
    case 0 :
        ms = "000";
        break;
    case 1 :
        ms = "00" + msStr;
        break;
    case 2 :
        ms = "0" + msStr;
        break;
    case 3 :
        ms = msStr;
        break;
    default:
      var func = function (param, param$1) {
        return param$1.slice(0, param);
      };
      ms = Curry._2(func, 3, msStr);
  }
  return valueWithoutMilliseconds + (":" + ms);
}

function BugBreadcrumbsUtils$Ellipse(Props) {
  var width = Props.width;
  var minWidth = Props.minWidth;
  var maxWidth = Props.maxWidth;
  var text = Props.text;
  var className = Props.className;
  var match = React.useState((function () {
          return false;
        }));
  var setEllipsed = match[1];
  var w = width !== undefined ? (
      width.tag ? Css.pct(width[0]) : Css.px(width[0])
    ) : Css.pct(100);
  var min = minWidth !== undefined ? (
      minWidth.tag ? Css.pct(minWidth[0]) : Css.px(minWidth[0])
    ) : Css.pct(100);
  var max = maxWidth !== undefined ? (
      maxWidth.tag ? Css.pct(maxWidth[0]) : Css.px(maxWidth[0])
    ) : Css.pct(100);
  var baseStyle = Css.style(/* :: */[
        Css.width(w),
        /* :: */[
          Css.minWidth(min),
          /* :: */[
            Css.maxWidth(max),
            /* :: */[
              Css.whiteSpace(/* nowrap */867913355),
              /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.textOverflow(/* ellipsis */166888785),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  return React.createElement(Tooltip.default, {
              children: React.createElement("div", {
                    ref: (function (el) {
                        if (el == null) {
                          return ;
                        }
                        var offsetWidth = el.offsetWidth;
                        var scrollWidth = el.scrollWidth;
                        var isEllipsed = Caml_obj.caml_lessthan(offsetWidth, scrollWidth);
                        return Curry._1(setEllipsed, (function (param) {
                                      return isEllipsed;
                                    }));
                      }),
                    className: Css.merge(/* :: */[
                          baseStyle,
                          /* :: */[
                            Belt_Option.getWithDefault(className, ""),
                            /* [] */0
                          ]
                        ])
                  }, I18N$BsConsole.showSkip(text)),
              title: match[0] ? text : ""
            });
}

var Ellipse = {
  make: BugBreadcrumbsUtils$Ellipse
};

var lastBreadcrumbKey = "breadcrumbs.lastId";

exports.attachmentPrefix = attachmentPrefix;
exports.lastBreadcrumbKey = lastBreadcrumbKey;
exports.lastIdFromJson = lastIdFromJson;
exports.sortIdDesc = sortIdDesc;
exports.hasBreadcrumbFiles = hasBreadcrumbFiles;
exports.dateTimeMsString = dateTimeMsString;
exports.Ellipse = Ellipse;
/* Css Not a pure module */
