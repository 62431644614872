// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("domain", (function (param) {
                              return Json_decode.list(Json_decode.string, param);
                            }), json);
              }), json);
}

exports.decode = decode;
/* No side effect */
