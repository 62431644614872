// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("./Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var BacktraceErrorPage$BsConsole = require("./BacktraceErrorPage.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          state: Json_decode.optional((function (param) {
                  return Json_decode.field("state", Json_decode.string, param);
                }), json),
          endpoint: Json_decode.optional((function (param) {
                  return Json_decode.field("endpoint", Json_decode.string, param);
                }), json)
        };
}

var Service = {
  fromJson: fromJson
};

function errorSupportTextCallback(param) {
  return I18N$BsConsole.show(undefined, "Service is momentarily unavailable please contact support for more information.");
}

var svcLayerTask = Task2$BsConsole.make(/* Api */Block.__(0, ["/svclayer"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
              /* tuple */[
                "action",
                "list"
              ],
              /* [] */0
            ])]), undefined, (function (param) {
        var json = param.json;
        try {
          if (param.status >= 400) {
            var response = ApiWf$BsConsole.$$Error.fromJson(json);
            return /* Error */Block.__(1, [[
                        ApiWf$BsConsole.ServiceError,
                        response.error
                      ]]);
          }
          var response$1 = Json_decode.field("services", (function (param) {
                  return Json_decode.array(fromJson, param);
                }), json);
          return /* Ok */Block.__(0, [response$1]);
        }
        catch (raw_err){
          var err = Caml_js_exceptions.internalToOCamlException(raw_err);
          var error = Uncaught$BsConsole.castToJsExn(err);
          return /* Error */Block.__(1, [[
                      ApiWf$BsConsole.ParseError,
                      error
                    ]]);
        }
      }), undefined, undefined);

function SauceLabsLoginRedirect(Props) {
  var match = React.useState((function () {
          return false;
        }));
  var setDidLoginFail = match[1];
  React.useEffect((function () {
          var onSuccess = function (services) {
            var service = Belt_Array.getBy(services, (function (service) {
                    if (service.name === "auth") {
                      return Belt_Option.getWithDefault(service.state, "") === "active";
                    } else {
                      return false;
                    }
                  }));
            if (service === undefined) {
              return Curry._1(setDidLoginFail, (function (param) {
                            return true;
                          }));
            }
            var endpoint = service.endpoint;
            if (endpoint !== undefined) {
              return Util$BsConsole.openUrlInSameTab(endpoint + ("/saucelabs/oauth2/login?hostname=" + window.location.host));
            } else {
              return Curry._1(setDidLoginFail, (function (param) {
                            return true;
                          }));
            }
          };
          var onFailure = function (param) {
            return Curry._1(setDidLoginFail, (function (param) {
                          return true;
                        }));
          };
          Task2$BsConsole.handle("", svcLayerTask, undefined, (function (param) {
                  return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                }));
          
        }), ([]));
  if (match[0]) {
    return React.createElement(BacktraceErrorPage$BsConsole.make, {
                errorCode: I18N$BsConsole.show(undefined, "503"),
                errorMessage: I18N$BsConsole.show(undefined, "Service Unavailable"),
                errorSupportTextCallback: errorSupportTextCallback
              });
  } else {
    return React.createElement(Row2$BsConsole.make, {
                alignItems: /* center */98248149,
                justifyContent: /* center */98248149,
                className: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.height(Css.vh(100)),
                        /* [] */0
                      ]
                    ]),
                children: React.createElement(CircularProgress.default, {
                      size: 150,
                      color: "primary"
                    })
              });
  }
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(SauceLabsLoginRedirect, { }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = SauceLabsLoginRedirect;

exports.Service = Service;
exports.errorSupportTextCallback = errorSupportTextCallback;
exports.svcLayerTask = svcLayerTask;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* svcLayerTask Not a pure module */
