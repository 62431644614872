// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var ProjectQueryBuilder$BsConsole = require("./query-builder/projectQueryBuilder.js");

function ExploreAggregate$View(Props) {
  var attributes = Props.attributes;
  var metricsAttributes = Props.metricsAttributes;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleTask = Props.handleTask;
  var route = Props.route;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var token = Props.token;
  var fold = Props.fold;
  var havings = Props.havings;
  var config = Props.config;
  var sort = Props.sort;
  var handleAddFilters = Props.handleAddFilters;
  var normBy = Props.normBy;
  var factor = Props.factor;
  var highlightExporter = Props.highlightExporter;
  return React.createElement(ProjectQueryBuilder$BsConsole.make, {
              config: config,
              attributes: attributes,
              normBy: normBy,
              sort: sort,
              fold: fold,
              aperture: aperture,
              havings: havings,
              factor: factor,
              projectName: projectName,
              universe: config.universe.name,
              token: token,
              handleTask: handleTask,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              metricsAttributes: metricsAttributes,
              route: route,
              highlightExporter: highlightExporter
            });
}

var View = {
  make: ExploreAggregate$View
};

function ExploreAggregate(Props) {
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var route = Props.route;
  var config = Props.config;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var fold = Props.fold;
  var sort = Props.sort;
  var havings = Props.havings;
  var normBy = Props.normBy;
  var handleAddFilters = Props.handleAddFilters;
  var handleTask = Props.handleTask;
  var factor = Props.factor;
  var highlightExporter = Props.highlightExporter;
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var match$1 = DescribeTask$BsConsole.use(token, projectName, "metrics", undefined);
  var metricsDescribeRemote = match$1[0];
  var metricsAttributes = React.useMemo((function () {
          if (typeof metricsDescribeRemote === "number" || metricsDescribeRemote.tag) {
            return /* [] */0;
          } else {
            return metricsDescribeRemote[0];
          }
        }), [metricsDescribeRemote]);
  if (typeof describeRemote === "number" || describeRemote.tag) {
    return null;
  } else {
    return React.createElement(ExploreAggregate$View, {
                attributes: describeRemote[0],
                metricsAttributes: metricsAttributes,
                handleChangeUrl: handleChangeUrl,
                handleTask: handleTask,
                route: route,
                aperture: aperture,
                projectName: projectName,
                token: token,
                fold: fold,
                havings: havings,
                config: config,
                sort: sort,
                handleAddFilters: handleAddFilters,
                normBy: normBy,
                factor: factor,
                highlightExporter: highlightExporter
              });
  }
}

function make(token, handleChangeUrl, route, config, projectName, aperture, fold, sort, havings, normBy, handleAddFilters, handleTask, factor, highlightExporter, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ExploreAggregate, {
              token: token,
              handleChangeUrl: handleChangeUrl,
              route: route,
              config: config,
              projectName: projectName,
              aperture: aperture,
              fold: fold,
              sort: sort,
              havings: havings,
              normBy: normBy,
              handleAddFilters: handleAddFilters,
              handleTask: handleTask,
              factor: factor,
              highlightExporter: highlightExporter
            }, children);
}

var Re = {
  make: make
};

var make$1 = ExploreAggregate;

exports.View = View;
exports.make = make$1;
exports.Re = Re;
/* react Not a pure module */
