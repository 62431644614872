// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Popper = require("@material-ui/core/Popper");
var TextField = require("@material-ui/core/TextField");

function BtClickableInput(Props) {
  var suggestions = Props.suggestions;
  var placeholderOpt = Props.placeholder;
  var value = Props.value;
  var handleOnChange = Props.handleOnChange;
  var setValue = Props.setValue;
  var type_Opt = Props.type_;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var type_ = type_Opt !== undefined ? type_Opt : "number";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setOpen = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement(TextField.default, {
                  placeholder: placeholder,
                  value: value !== undefined ? String(value) : "",
                  variant: "standard",
                  className: Css.style(/* :: */[
                        Css.important(Css.marginBottom(/* zero */-789508312)),
                        /* :: */[
                          Css.maxWidth(Css.px(65)),
                          /* :: */[
                            Css.selector("input", /* :: */[
                                  Css.important(Css.padding(Css.px(10))),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  type: type_,
                  onChange: (function ($$event) {
                      var value = $$event.target.value;
                      var floatOption = Belt_Float.fromString(value);
                      if (Belt_Option.isNone(floatOption)) {
                        Curry._1(setOpen, (function (param) {
                                return true;
                              }));
                      }
                      return Curry._1(handleOnChange, $$event);
                    }),
                  onFocus: (function ($$event) {
                      var tar = $$event.currentTarget;
                      Curry._1(setAnchorEl, (function (param) {
                              if (tar == null) {
                                return ;
                              } else {
                                return Caml_option.some(tar);
                              }
                            }));
                      if (value !== undefined) {
                        return ;
                      } else {
                        return Curry._1(setOpen, (function (param) {
                                      return true;
                                    }));
                      }
                    }),
                  onBlur: (function (param) {
                      setTimeout((function (param) {
                              return Curry._1(setOpen, (function (param) {
                                            return false;
                                          }));
                            }), 150);
                      
                    })
                }), React.createElement(Popper.default, {
                  anchorEl: match[0],
                  open: match$1[0] && Belt_Option.isNone(value),
                  placement: "bottom-start",
                  children: React.createElement(Paper.default, {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.px(13)),
                              /* :: */[
                                Css.overflow(/* hidden */-862584982),
                                /* :: */[
                                  Css.margin2(Css.px(2), Css.zero),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        children: React.createElement("ul", {
                              className: Css.style(/* :: */[
                                    Css.unsafe("listStyle", "none"),
                                    /* :: */[
                                      Css.margin(/* zero */-789508312),
                                      /* :: */[
                                        Css.padding(/* zero */-789508312),
                                        /* :: */[
                                          Css.maxHeight(Css.vh(40)),
                                          /* :: */[
                                            Css.overflow(/* auto */-1065951377),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            }, Belt_Array.map(suggestions, (function (suggestion) {
                                    return React.createElement("li", {
                                                className: Css.style(/* :: */[
                                                      Css.hover(/* :: */[
                                                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey6)),
                                                            /* [] */0
                                                          ]),
                                                      /* :: */[
                                                        Css.cursor(/* pointer */-786317123),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                onClick: (function (param) {
                                                    return Curry._1(setValue, suggestion);
                                                  })
                                              }, React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body1 */8,
                                                    className: Css.style(/* :: */[
                                                          Css.padding(Css.px(10)),
                                                          /* [] */0
                                                        ]),
                                                    children: I18N$BsConsole.showSkip(suggestion)
                                                  }));
                                  })))
                      }),
                  key: "key"
                }));
}

function make(suggestions, placeholder, value, handleOnChange, setValue, children) {
  return ReasonReactCompat.wrapReactForReasonReact(BtClickableInput, {
              suggestions: suggestions,
              placeholder: placeholder,
              value: value,
              handleOnChange: handleOnChange,
              setValue: setValue
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = BtClickableInput;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
