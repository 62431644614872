'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom'
};