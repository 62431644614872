// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var white = "FFFFFF";

var dataVizPalette = [
  "2196F3",
  "EE3377",
  "E69F00",
  "009988",
  "1F3142",
  "D55E00",
  "3D7FA4",
  "CC79A7",
  "4D1026",
  "949494"
];

var primaryLight = "64B6F7";

var primaryMain = "2196F3";

var primaryDark = "1883EF";

var primaryContrastText = white;

var successLight = "A5E064";

var successMain = "7ED321";

var successDark = "6BC818";

var successContrastText = white;

var warnLight = "F0C772";

var warnMain = "E9AF36";

var warnDark = "E39F29";

var warnContrastText = white;

var errorLight = "ED685F";

var errorMain = "E5271A";

var errorDark = "DE1D13";

var errorContrastText = white;

var grey50 = "FAFAFA";

var grey100 = "F5F5F5";

var grey200 = "EEEEEE";

var grey300 = "E0E0E0";

var grey400 = "BDBDBD";

var grey500 = "9E9E9E";

var grey600 = "757575";

var grey700 = "616161";

var grey800 = "424242";

var grey900 = "212121";

var dataVizRest = "949494";

var backgroundDefault = "F2F3F4";

exports.white = white;
exports.primaryLight = primaryLight;
exports.primaryMain = primaryMain;
exports.primaryDark = primaryDark;
exports.primaryContrastText = primaryContrastText;
exports.successLight = successLight;
exports.successMain = successMain;
exports.successDark = successDark;
exports.successContrastText = successContrastText;
exports.warnLight = warnLight;
exports.warnMain = warnMain;
exports.warnDark = warnDark;
exports.warnContrastText = warnContrastText;
exports.errorLight = errorLight;
exports.errorMain = errorMain;
exports.errorDark = errorDark;
exports.errorContrastText = errorContrastText;
exports.grey50 = grey50;
exports.grey100 = grey100;
exports.grey200 = grey200;
exports.grey300 = grey300;
exports.grey400 = grey400;
exports.grey500 = grey500;
exports.grey600 = grey600;
exports.grey700 = grey700;
exports.grey800 = grey800;
exports.grey900 = grey900;
exports.dataVizPalette = dataVizPalette;
exports.dataVizRest = dataVizRest;
exports.backgroundDefault = backgroundDefault;
/* No side effect */
