// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var $$window = window;

function getWidth(element) {
  return element.clientWidth;
}

function getHeight(element) {
  return element.getBoundingClientRect().height;
}

function setRef(theRef, param) {
  param.state.container.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component = RR$BsConsole.reducerComponent("ContainerWithDimensions-BsConsole");

function make(classNameOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              var cb = function (param) {
                return Curry._1(self.send, /* Measure */0);
              };
              $$window.addEventListener("resize", cb);
              Curry._1(self.onUnmount, (function (param) {
                      $$window.removeEventListener("resize", cb);
                      
                    }));
              return Curry._1(self.send, /* Measure */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return React.createElement("div", {
                          ref: Curry._1(self.handle, setRef),
                          className: className
                        }, Curry._2(children, self.state.width, self.state.height));
            }),
          initialState: (function (param) {
              return {
                      container: {
                        contents: undefined
                      },
                      height: 0,
                      width: 0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var element = state.container.contents;
              if (element === undefined) {
                return /* NoUpdate */0;
              }
              var element$1 = Caml_option.valFromOption(element);
              var width = element$1.clientWidth;
              var height = element$1.getBoundingClientRect().height;
              return /* Update */Block.__(0, [{
                          container: state.container,
                          height: height,
                          width: width
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(Caml_option.undefined_to_opt(reactProps.className), reactProps.children);
      }));

var Jsx3 = {
  make: make$1
};

exports.$$window = $$window;
exports.getWidth = getWidth;
exports.getHeight = getHeight;
exports.setRef = setRef;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* window Not a pure module */
