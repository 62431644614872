// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Form$BsConsole = require("./Form.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var UsersTable$BsConsole = require("./UsersTable.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BpgController$BsConsole = require("./BpgController.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");
var SubGoToInviteNewUser$BsConsole = require("./subscriptions/SubGoToInviteNewUser.js");
var UserFrontendSettings$BsConsole = require("./UserFrontendSettings.js");

var component = ReasonReact.reducerComponent("SettingsUsersPage-BsConsole__DeleteUserDialog");

function make(user, token, param, onClose, onSuccess, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              Curry._1(self.send, /* SetOwnershipRemote */Block.__(1, [/* Loading */1]));
              var arg = BpgTask$BsConsole.getOwnedItemsTask(user.uid);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (resp) {
                      if (resp.tag) {
                        return ;
                      } else {
                        return Curry._1(self.send, /* SetOwnershipRemote */Block.__(1, [/* Success */Block.__(0, [resp[0]])]));
                      }
                    }));
              
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var ownershipRemote = match.ownershipRemote;
              var remote = match.remote;
              var tmp;
              if (typeof remote === "number" || remote.tag !== /* Failure */1) {
                tmp = I18N$BsConsole.show(undefined, "Are you sure you would like to delete this user?");
              } else {
                var err = remote[0];
                var errorMsg = Task2$BsConsole.renderError(undefined, err);
                var tmp$1;
                if (typeof ownershipRemote === "number" || ownershipRemote.tag) {
                  tmp$1 = null;
                } else {
                  var owned = ownershipRemote[0];
                  tmp$1 = owned !== undefined && Task2$BsConsole.renderError(undefined, err).indexOf("dependencies to the object exist") >= 0 ? React.createElement("div", undefined, React.createElement("ul", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.red)),
                                    /* [] */0
                                  ])
                            }, Belt_Array.map(Belt_List.toArray(owned), (function (obj) {
                                    return React.createElement("li", undefined, I18N$BsConsole.showSkip(obj.replace("configuration/", "").replace("_new", "")));
                                  }))), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.red)),
                                    /* [] */0
                                  ])
                            }, I18N$BsConsole.show(undefined, "Please transfer these dependencies and try again, or reach out to support."))) : null;
                }
                tmp = React.createElement("div", undefined, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.red)),
                                /* [] */0
                              ])
                        }, errorMsg.indexOf("dependencies to the object exist") >= 0 ? I18N$BsConsole.show(undefined, "There are dependencies on this user.  Those dependencies include:") : I18N$BsConsole.showSkip(errorMsg)), tmp$1);
              }
              var tmp$2;
              tmp$2 = typeof remote === "number" ? (
                  remote === /* NotAsked */0 ? false : true
                ) : (
                  remote.tag ? true : false
                );
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                                          undefined,
                                          true,
                                          onClose,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [
                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Delete user")])),
                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [tmp]))])),
                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                undefined,
                                                                undefined,
                                                                Curry.__1(onClose),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                /* Primary */-791844958,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Cancel")]
                                                              ])),
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                undefined,
                                                                undefined,
                                                                (function (evt) {
                                                                    Curry._1(send, /* SetRemote */Block.__(0, [/* Loading */1]));
                                                                    var deleteUser = function (param) {
                                                                      return Task2$BsConsole.handle(token, BpgTask$BsConsole.Users.$$delete(user), undefined, (function (resp) {
                                                                                    if (resp.tag) {
                                                                                      return Curry._1(send, /* SetRemote */Block.__(0, [/* Failure */Block.__(1, [resp[0]])]));
                                                                                    }
                                                                                    Curry._1(send, /* SetRemote */Block.__(0, [/* Success */Block.__(0, [undefined])]));
                                                                                    Curry._1(onClose, evt);
                                                                                    Curry._1(onSuccess, undefined);
                                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success: deleted user"));
                                                                                  }));
                                                                    };
                                                                    return UserFrontendSettings$BsConsole.deleteUserSettingsForUser(token, user.uid, deleteUser, deleteUser);
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                /* Primary */-791844958,
                                                                undefined,
                                                                undefined,
                                                                tmp$2,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Delete")]
                                                              ]))
                                                    ]))
                                          ]
                                        ]))]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0,
                      ownershipRemote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: state.remote,
                            ownershipRemote: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            remote: action[0],
                            ownershipRemote: state.ownershipRemote
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var DeleteUserDialog = {
  component: component,
  make: make
};

var textField = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var radioGroup = Css.style(/* :: */[
      Css.marginTop(Css.px(16)),
      /* [] */0
    ]);

var Styles = {
  textField: textField,
  radioGroup: radioGroup
};

var component$1 = ReasonReact.statelessComponent("SettingsUsersPage-BsConsole__EditUserDialog");

function make$1(onSuccess, currentUser, token, handleTask, user, onClose, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Modify.make, (function (_result, _user) {
                                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success: modified user"));
                                            Curry._1(onSuccess, undefined);
                                            return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditUserForm.make, (function (param) {
                                                              var values = param.values;
                                                              return Curry._1(handleSubmit, /* tuple */[
                                                                          user,
                                                                          Util$BsConsole.identity,
                                                                          Form$BsConsole.Fields.set("superuser", values.superuser, Form$BsConsole.Fields.set("role", values.role, Form$BsConsole.Fields.set("email", values.email, Form$BsConsole.Fields.set("method", values.method_, Form$BsConsole.Fields.empty)))),
                                                                          param.setError,
                                                                          param.setSubmitting,
                                                                          param.resetFormState
                                                                        ]);
                                                            }), undefined, undefined, {
                                                            email: user.email,
                                                            method_: user.method_,
                                                            role: Belt_Option.getWithDefault(user.role, "member"),
                                                            superuser: String(user.superuser)
                                                          }, /* :: */[
                                                            /* tuple */[
                                                              /* Email */50085628,
                                                              /* Email */1
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                /* Method */1039100673,
                                                                /* Required */0
                                                              ],
                                                              /* :: */[
                                                                /* tuple */[
                                                                  /* Role */914888598,
                                                                  /* Required */0
                                                                ],
                                                                /* :: */[
                                                                  /* tuple */[
                                                                    /* SuperUser */-134776506,
                                                                    /* Required */0
                                                                  ],
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ], undefined, (function (param) {
                                                              var getErrorForField = param.getErrorForField;
                                                              var handleSubmit = param.handleSubmit;
                                                              var handleChange = param.handleChange;
                                                              var form = param.form;
                                                              var _msg = state.remote;
                                                              var tmp;
                                                              tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? false : true;
                                                              var partial_arg = Curry._1(handleChange, /* Email */50085628);
                                                              var msg = state.remote;
                                                              var tmp$1;
                                                              tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg[0])]));
                                                              var match = currentUser.superuser;
                                                              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Edit user")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, textField, tmp, undefined, undefined, [
                                                                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            true,
                                                                                                            (function (param) {
                                                                                                                return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                              }),
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            Belt_Option.isSome(Curry._1(getErrorForField, /* Email */50085628)),
                                                                                                            Caml_option.some(I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Email */50085628), ""))),
                                                                                                            undefined,
                                                                                                            /* `String */[
                                                                                                              -976970511,
                                                                                                              form.values.email
                                                                                                            ],
                                                                                                            undefined,
                                                                                                            true,
                                                                                                            Caml_option.some(I18N$BsConsole.show(undefined, "email")),
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            []
                                                                                                          ])),
                                                                                                  tmp$1
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                                            I18N$BsConsole.show(undefined, "Method"),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.method_, (function (_event, value) {
                                                                                                                        return Curry._2(handleChange, /* Method */1039100673, value);
                                                                                                                      }), undefined, [
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "password",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "password")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ])),
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "saml",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "saml")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ])),
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "pam",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "pam")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ]))
                                                                                                                    ]))
                                                                                                          ]))])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                                            I18N$BsConsole.show(undefined, "Role"),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.role, (function (_event, value) {
                                                                                                                        return Curry._2(handleChange, /* Role */914888598, value);
                                                                                                                      }), undefined, [
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "admin",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "admin")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ])),
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "member",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "member")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ])),
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                "guest",
                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, "guest")),
                                                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              /* Primary */-791844958,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              undefined,
                                                                                                                                              []
                                                                                                                                            ]))),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ]))
                                                                                                                    ]))
                                                                                                          ]))])),
                                                                                        match !== 1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, radioGroup, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.FormLabel.Jsx2.make, undefined, [
                                                                                                              I18N$BsConsole.show(undefined, "Access Control"),
                                                                                                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, form.values.superuser, (function (_event, value) {
                                                                                                                          return Curry._2(handleChange, /* SuperUser */-134776506, value);
                                                                                                                        }), undefined, [
                                                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                  "1",
                                                                                                                                  Caml_option.some(I18N$BsConsole.show(undefined, "superuser")),
                                                                                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                []
                                                                                                                                              ]))),
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ])),
                                                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                                  "0",
                                                                                                                                  Caml_option.some(I18N$BsConsole.show(undefined, "normal")),
                                                                                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                []
                                                                                                                                              ]))),
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  undefined,
                                                                                                                                  []
                                                                                                                                ]))
                                                                                                                      ]))
                                                                                                            ]))]))
                                                                                      ])),
                                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  /* Primary */-791844958,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                ])),
                                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  (function (_event) {
                                                                                                      console.log(form.values);
                                                                                                      console.log(form.errors);
                                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  /* Primary */-791844958,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  state.remote === /* Loading */1 || form.values.email === "",
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  [I18N$BsConsole.show(undefined, "Submit")]
                                                                                                ]))
                                                                                      ]))
                                                                            ]));
                                                            })));
                                          })))]
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var EditUserDialog = {
  Styles: Styles,
  component: component$1,
  make: make$1
};

var listItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* [] */0
    ]);

var component$2 = ReasonReact.reducerComponent("SettingsUsersPage-BsConsoleWhitelist");

function make$2(token, config, handleTask, handleChangeUrl, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1)),
                                  /* [] */0
                                ]), [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.SignupWhitelist.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                          var msg = state.remote;
                                          if (typeof msg !== "number") {
                                            if (msg.tag) {
                                              return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, (function (_event) {
                                                                          if (TeamsUtils$BsConsole.isGuest(config)) {
                                                                            return ;
                                                                          } else {
                                                                            return Curry._1(handleChangeUrl, /* SettingsWhitelist */17);
                                                                          }
                                                                        }), undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Allow signups from allowlisted domains")), Caml_option.some(I18N$BsConsole.dynamic(msg[0])), [])),
                                                                        TeamsUtils$BsConsole.isGuest(config) ? null : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                      ]))]));
                                            } else {
                                              return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, (function (_event) {
                                                                          if (TeamsUtils$BsConsole.isGuest(config)) {
                                                                            return ;
                                                                          } else {
                                                                            return Curry._1(handleChangeUrl, /* SettingsWhitelist */17);
                                                                          }
                                                                        }), undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Allow signups from allowlisted domains")), Caml_option.some(I18N$BsConsole.showSkip(List.length(msg[0]) > 0 ? I18N$BsConsole.get(undefined, "enabled") : I18N$BsConsole.get(undefined, "disabled"))), [])),
                                                                        TeamsUtils$BsConsole.isGuest(config) ? null : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                      ]))]));
                                            }
                                          }
                                          msg === /* NotAsked */0;
                                          return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, listItem, undefined, (function (_event) {
                                                                      return Curry._1(handleChangeUrl, /* SettingsWhitelist */17);
                                                                    }), undefined, [
                                                                    ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.showEmpty(undefined)), Caml_option.some(I18N$BsConsole.showEmpty(undefined)), [])),
                                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                  ]))]));
                                        })))]));
            }),
          initialState: (function (param) {
              return {
                      whitelistStatus: /* Enabled */0
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              var match = state.whitelistStatus;
              return /* Update */Block.__(0, [{
                          whitelistStatus: match ? /* Enabled */0 : /* Disabled */1
                        }]);
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var Whitelist = {
  listItem: listItem,
  component: component$2,
  make: make$2
};

var inviteNewUserListItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* [] */0
    ]);

var loadingPaper = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var loadingIndicator = Css.style(/* :: */[
      Css.margin2(Css.em(2), /* zero */-789508312),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.selector(".BtTableContainer", /* :: */[
            Css.maxHeight(Css.px(800)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".BtUsersTable_Header-action", /* :: */[
              Css.width(Css.px(64)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var footerRow = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
          /* :: */[
            Css.padding(Css.px(20)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles$1 = {
  inviteNewUserListItem: inviteNewUserListItem,
  loadingPaper: loadingPaper,
  loadingIndicator: loadingIndicator,
  paper: paper,
  footerRow: footerRow
};

var component$3 = ReasonReact.reducerComponent("UsersTable");

function make$3(token, config, handleTask, handleChangeUrl, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* FetchAllUsers */0);
            }),
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var currentUser = config.user;
              var universe = config.universe.name;
              var msg = self.state.allUsersRemote;
              var tmp;
              var exit = 0;
              if (typeof msg === "number") {
                exit = 1;
              } else if (msg.tag) {
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, paper, undefined, undefined, 0, [ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, msg[0])]))]));
              } else {
                var match = msg[0];
                var loading = match[1] === /* Loading */1;
                tmp = ReasonReact.element(undefined, undefined, UsersTable$BsConsole.Jsx2.make(currentUser, match[0], loading, (function (user) {
                            return Curry._1(self.send, /* Dialog */Block.__(2, [/* EditUser */Block.__(0, [user])]));
                          }), (function (param) {
                            return Curry._1(self.send, /* FetchAllUsers */0);
                          }), token, []));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "primary", undefined, undefined, []))]));
              }
              var match$1 = self.state.dialog;
              var tmp$1;
              tmp$1 = match$1 !== undefined ? (
                  match$1.tag ? ReasonReact.element(undefined, undefined, make(match$1[0], token, handleTask, (function (param) {
                                return Curry._1(self.send, /* Dialog */Block.__(2, [undefined]));
                              }), (function (param) {
                                return Curry._1(self.send, /* FetchAllUsers */0);
                              }), [])) : ReasonReact.element(undefined, undefined, make$1((function (param) {
                                return Curry._1(self.send, /* FetchAllUsers */0);
                              }), currentUser, token, handleTask, match$1[0], (function (param) {
                                return Curry._1(self.send, /* Dialog */Block.__(2, [undefined]));
                              }), []))
                ) : null;
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config.user])), [
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Configure self signup")])),
                                        ReasonReact.element(undefined, undefined, make$2(token, config, handleTask, handleChangeUrl, [])),
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Manage invitations")])),
                                        ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                                      Css.marginBottom(Css.rem(1)),
                                                      /* [] */0
                                                    ]), [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, inviteNewUserListItem, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, SubGoToInviteNewUser$BsConsole.Re.make(handleChangeUrl, TeamsUtils$BsConsole.isGuest(config), []))])),
                                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                            ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, inviteNewUserListItem, undefined, (function (_event) {
                                                                        if (TeamsUtils$BsConsole.isGuest(config)) {
                                                                          return ;
                                                                        } else {
                                                                          return Curry._1(handleChangeUrl, /* SettingsInvites */11);
                                                                        }
                                                                      }), undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.SignupPending.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                                                  var signups = state.remote;
                                                                                  var tmp;
                                                                                  tmp = typeof signups === "number" || signups.tag ? null : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                              /* Int */Block.__(4, [
                                                                                                  /* Int_d */0,
                                                                                                  /* No_padding */0,
                                                                                                  /* No_precision */0,
                                                                                                  /* String_literal */Block.__(11, [
                                                                                                      " pending",
                                                                                                      /* End_of_format */0
                                                                                                    ])
                                                                                                ]),
                                                                                              "%d pending"
                                                                                            ]), Belt_List.length(signups[0]));
                                                                                  return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Pending Invitations")), Caml_option.some(tmp), []));
                                                                                }))),
                                                                      TeamsUtils$BsConsole.isGuest(config) ? null : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))
                                                                    ]))
                                                          ]))]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]), [
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Users")])),
                                        WindowVariables$BsConsole._BACKTRACE_USER_MANAGEMENT !== undefined ? React.createElement("a", {
                                                className: Css.style(/* :: */[
                                                      Css.hover(/* :: */[
                                                            Css.textDecoration(/* none */-922086728),
                                                            /* [] */0
                                                          ]),
                                                      /* [] */0
                                                    ]),
                                                href: WindowVariables$BsConsole._BACKTRACE_USER_MANAGEMENT.replace("{token}", token).replace("{universe}", universe),
                                                target: "_blank"
                                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (_event) {
                                                            
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        /* Raised */-387916264,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [I18N$BsConsole.show(undefined, "View user reports")]
                                                      ]))) : null
                                      ])),
                              tmp,
                              tmp$1
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      allUsersRemote: /* NotAsked */0
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                var match = state.allUsersRemote;
                var allUsersRemote;
                allUsersRemote = typeof match === "number" || match.tag ? /* Loading */1 : /* Success */Block.__(0, [/* tuple */[
                        match[0][0],
                        /* Loading */1
                      ]]);
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            dialog: state.dialog,
                            allUsersRemote: allUsersRemote
                          },
                          (function (self) {
                              var onSuccess = function (users) {
                                var filteredUsers = Util$BsConsole.filterBacktraceInternalUsers(users, config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2);
                                return Curry._1(self.send, /* FetchAllUsersSuccess */Block.__(0, [filteredUsers]));
                              };
                              var onFailure = function (msg) {
                                return Curry._1(self.send, /* FetchAllUsersFailure */Block.__(1, [msg]));
                              };
                              var arg = BpgTask$BsConsole.Users.fetchAll;
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), true, (function (param) {
                                            return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                                          }));
                            })
                        ]);
              }
              switch (action.tag | 0) {
                case /* FetchAllUsersSuccess */0 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                allUsersRemote: /* Success */Block.__(0, [/* tuple */[
                                      action[0],
                                      /* NotAsked */0
                                    ]])
                              }]);
                case /* FetchAllUsersFailure */1 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                allUsersRemote: /* Failure */Block.__(1, [action[0]])
                              }]);
                case /* Dialog */2 :
                    return /* Update */Block.__(0, [{
                                dialog: action[0],
                                allUsersRemote: state.allUsersRemote
                              }]);
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

exports.DeleteUserDialog = DeleteUserDialog;
exports.EditUserDialog = EditUserDialog;
exports.Whitelist = Whitelist;
exports.Styles = Styles$1;
exports.component = component$3;
exports.make = make$3;
/* component Not a pure module */
