// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var D3Array = require("d3-array");
var D3Brush = require("d3-brush");
var D3Scale = require("d3-scale");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var D3Selection = require("d3-selection");
var Mui$BsConsole = require("../bindings/Mui.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Tooltip$BsConsole = require("./Tooltip.js");
var Is_equal = require("date-fns/is_equal");
var Fragment$BsConsole = require("../Fragment.js");
var Selection$BsConsole = require("../bs-vx/src/Selection/Selection.js");
var Interpolate$BsConsole = require("../Interpolate.js");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var BsVx_Axis__Left$BsConsole = require("../bs-vx/src/Axis/BsVx_Axis__Left.js");
var BsVx_Grid__Rows$BsConsole = require("../bs-vx/src/Grid/BsVx_Grid__Rows.js");
var BsVx_Shape__Bar$BsConsole = require("../bs-vx/src/BsVx_Shape__Bar.js");
var Renderer_Number$BsConsole = require("../renders/Renderer_Number.js");
var BsVx_WithTooltip$BsConsole = require("../bs-vx/src/Tooltip/BsVx_WithTooltip.js");
var BsVx_Axis__Bottom$BsConsole = require("../bs-vx/src/Axis/BsVx_Axis__Bottom.js");
var BsVx_Grid__Columns$BsConsole = require("../bs-vx/src/Grid/BsVx_Grid__Columns.js");
var RangeTooltipContent$BsConsole = require("./RangeTooltipContent.js");

var TooltipController = BsVx_WithTooltip$BsConsole.Make({ });

function maxInt(arr) {
  return Belt_Array.reduce(arr, 0, (function (acc, v) {
                if (v > acc) {
                  return v;
                } else {
                  return acc;
                }
              }));
}

function xScaleInt(xMax, param) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        0,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function xScaleFloat(xMin, xMax, param) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        xMin,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function xScaleDate(xMax, param) {
  var __x = D3Scale.scaleTime();
  var __x$1 = __x.rangeRound([
        0,
        xMax
      ]);
  return __x$1.domain([
              param[0],
              param[1]
            ]);
}

function yScale(yMax, data) {
  var __x = D3Scale.scaleLinear();
  var __x$1 = __x.rangeRound([
        yMax,
        0
      ]);
  return __x$1.domain([
              0,
              maxInt(data)
            ]);
}

function getBrushElement(element) {
  return D3Selection.select(element).attr("class", "brush");
}

function getRangeFromBins(bins, range) {
  var rangeEnd = range[1];
  var rangeStart = range[0];
  var startBin = Belt_Array.getBy(bins, (function (param) {
          if (Caml_obj.caml_greaterequal(rangeStart, param[0])) {
            return Caml_obj.caml_lessequal(rangeStart, param[1]);
          } else {
            return false;
          }
        }));
  var endBin = Belt_Array.getBy(bins, (function (param) {
          if (Caml_obj.caml_greaterequal(rangeEnd, param[0])) {
            return Caml_obj.caml_lessequal(rangeEnd, param[1]);
          } else {
            return false;
          }
        }));
  if (startBin !== undefined && endBin !== undefined) {
    return /* tuple */[
            startBin[0],
            endBin[1]
          ];
  } else {
    return range;
  }
}

function createBrush(width, height) {
  return D3Brush.brushX().extent([
              [
                0,
                0
              ],
              [
                width,
                height
              ]
            ]);
}

function addBrushEventListener(brush, eventListener, element, xScale, cb) {
  getBrushElement(element).call(brush.on(eventListener, (function (param) {
              var selection = Selection$BsConsole.$$Event.selection(D3Selection.event);
              if (selection === undefined) {
                return ;
              }
              var selected = Belt_Array.map(selection, (function (param) {
                      return xScale.invert(param);
                    }));
              var mouseEvent = D3Selection.event.sourceEvent;
              if (selected.length !== 2) {
                return ;
              }
              var rangeStart = selected[0];
              var rangeEnd = selected[1];
              return Curry._2(cb, mouseEvent, /* tuple */[
                          rangeStart,
                          rangeEnd
                        ]);
            })));
  
}

function onBrushEnd(brush, element, xScale, handleBrushEnd) {
  return addBrushEventListener(brush, "end", element, xScale, handleBrushEnd);
}

function onBrushMove(brush, element, xScale, handleBrushMove) {
  return addBrushEventListener(brush, "brush", element, xScale, handleBrushMove);
}

var component = ReasonReact.reducerComponent("Charts__Bin-BsConsole__DateChart");

function make(variantOpt, barFill, barHoverFill, numYAxisTicks, hideXAxisOpt, hideYAxisOpt, hideGridColumnsOpt, hideGridRowsOpt, data, param, param$1, yMax, xMax, xScale, yScale, height, width, _children) {
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              return ReasonReact.element(undefined, undefined, Curry._1(BtThemeContext$BsConsole.Consumer.Jsx2.make, (function (themeVariant) {
                                var match;
                                if (barFill !== undefined) {
                                  match = barHoverFill !== undefined ? /* tuple */[
                                      barFill,
                                      barHoverFill
                                    ] : /* tuple */[
                                      barFill,
                                      barFill
                                    ];
                                } else if (themeVariant !== undefined) {
                                  var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
                                  match = /* tuple */[
                                    palette.primary.main,
                                    palette.primary.dark
                                  ];
                                } else {
                                  match = /* tuple */[
                                    Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
                                    Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark)
                                  ];
                                }
                                var barHoverFillColor = match[1];
                                var barFillColor = match[0];
                                var match$1 = state.coords;
                                var match$2 = state.info;
                                var tmp;
                                if (match$1 !== undefined && match$2 !== undefined) {
                                  var y = match$1[1];
                                  var x = match$1[0];
                                  tmp = ReasonReact.element(String(x) + ("__" + String(y)), undefined, Tooltip$BsConsole.Proto.make(x, y, [ReasonReact.element(undefined, undefined, RangeTooltipContent$BsConsole.make(L10N_date_time$BsConsole.Format.textAbbreviatedMonthDay(undefined, match$2[0]), L10N_date_time$BsConsole.Format.textAbbreviatedMonthDay(undefined, match$2[1]), Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* String_literal */Block.__(11, [
                                                                      " errors",
                                                                      /* End_of_format */0
                                                                    ])
                                                                ]),
                                                              "%s errors"
                                                            ]), Renderer_Number$BsConsole.formatInt(match$2[2])), []))]));
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.position(/* relative */903134412),
                                                  /* [] */0
                                                ])
                                          }, React.createElement("svg", {
                                                className: variant ? Css.style(/* :: */[
                                                        Css.cursor(/* crosshair */180897442),
                                                        /* [] */0
                                                      ]) : "",
                                                height: String(height),
                                                width: String(width),
                                                onMouseLeave: (function (_e) {
                                                    return Curry._1(send, /* HideTooltip */0);
                                                  }),
                                                onMouseMove: (function (e) {
                                                    var x = e.clientX;
                                                    var y = e.clientY;
                                                    return Curry._1(send, /* SetCoords */Block.__(0, [/* tuple */[
                                                                    x,
                                                                    y
                                                                  ]]));
                                                  })
                                              }, React.createElement("g", {
                                                    ref: (function (ref) {
                                                        return Curry._1(send, /* SetGroupRef */Block.__(3, [ref]));
                                                      }),
                                                    className: Css.style(/* :: */[
                                                          Css.unsafe("transform", "translate(" + (String(Css.marginLeft) + ("," + (String(Css.marginTop) + ")")))),
                                                          /* [] */0
                                                        ])
                                                  }, React.createElement("rect", {
                                                        height: String(yMax),
                                                        width: String(xMax),
                                                        fill: "transparent"
                                                      }), hideGridRows ? null : ReasonReact.element(undefined, undefined, BsVx_Grid__Rows$BsConsole.make(0, undefined, yScale, xMax, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [])), hideGridColumns ? null : ReasonReact.element(undefined, undefined, BsVx_Grid__Columns$BsConsole.make(undefined, undefined, xScale, yMax, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [])), hideXAxis ? null : ReasonReact.element(undefined, undefined, BsVx_Axis__Bottom$BsConsole.make(undefined, undefined, true, undefined, true, undefined, undefined, undefined, undefined, undefined, 2, undefined, xScale, undefined, undefined, undefined, undefined, undefined, (function (_tickValue, _index) {
                                                                return {
                                                                        dx: "-20px",
                                                                        dy: "0.25em",
                                                                        fontFamily: "inconsolata;monospace",
                                                                        fontSize: 10
                                                                      };
                                                              }), undefined, undefined, undefined, yMax, [])), hideYAxis ? null : ReasonReact.element(undefined, undefined, BsVx_Axis__Left$BsConsole.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numYAxisTicks, undefined, yScale, undefined, undefined, undefined, undefined, undefined, (function (_tickValue, _index) {
                                                                return {
                                                                        dx: "-10px",
                                                                        dy: "2px",
                                                                        fontFamily: "monospace",
                                                                        fontSize: 10
                                                                      };
                                                              }), undefined, undefined, undefined, undefined, [])), Belt_Array.mapWithIndex(data, (function (i, param) {
                                                          var y = param[2];
                                                          var x2 = param[1];
                                                          var x1 = param[0];
                                                          var match = state.info;
                                                          var isHovered = match !== undefined ? Is_equal(x1, match[0]) && Is_equal(x2, match[1]) : false;
                                                          var match$1 = state.info;
                                                          var isHovered$1 = match$1 !== undefined ? Is_equal(x1, match$1[0]) && Is_equal(x2, match$1[1]) : false;
                                                          return ReasonReact.element(String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__fragment")))), undefined, Fragment$BsConsole.make([
                                                                          ReasonReact.element(String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__bar")))), undefined, BsVx_Shape__Bar$BsConsole.make(undefined, Curry._1(xScale, x1), Curry._1(yScale, y) - 1 | 0, Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0), (yMax - Curry._1(yScale, y) | 0) + 1 | 0, undefined, undefined, isHovered ? barHoverFillColor : barFillColor, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [])),
                                                                          ReasonReact.element(String(i) + ("__" + (x1.toString() + ("-" + (x2.toString() + "__background")))), undefined, BsVx_Shape__Bar$BsConsole.make(undefined, Curry._1(xScale, x1), 0, Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0), yMax, undefined, undefined, isHovered$1 ? barFillColor : "transparent", 0.2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                        ]));
                                                        })))), tmp);
                              })));
            }),
          initialState: (function (param) {
              return {
                      tooltip: undefined,
                      info: undefined,
                      coords: undefined,
                      groupRef: {
                        contents: undefined
                      },
                      brushInitialized: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            tooltip: undefined,
                            info: undefined,
                            coords: undefined,
                            groupRef: state.groupRef,
                            brushInitialized: state.brushInitialized
                          }]);
              }
              switch (action.tag | 0) {
                case /* SetCoords */0 :
                    var coords = action[0];
                    var match = state.groupRef.contents;
                    if (match !== undefined && coords !== undefined) {
                      var x = coords[0];
                      var x$prime = x - Caml_option.valFromOption(match).getBoundingClientRect().left | 0;
                      var info = Belt_List.getBy(Belt_List.fromArray(data), (function (param) {
                              if (x$prime >= Curry._1(xScale, param[0])) {
                                return x$prime <= Curry._1(xScale, param[1]);
                              } else {
                                return false;
                              }
                            }));
                      return /* Update */Block.__(0, [{
                                  tooltip: state.tooltip,
                                  info: info,
                                  coords: /* tuple */[
                                    x,
                                    coords[1]
                                  ],
                                  groupRef: state.groupRef,
                                  brushInitialized: state.brushInitialized
                                }]);
                    }
                    return /* Update */Block.__(0, [{
                                tooltip: state.tooltip,
                                info: state.info,
                                coords: coords,
                                groupRef: state.groupRef,
                                brushInitialized: state.brushInitialized
                              }]);
                case /* SetTooltipInfo */1 :
                    return /* Update */Block.__(0, [{
                                tooltip: state.tooltip,
                                info: action[0],
                                coords: state.coords,
                                groupRef: state.groupRef,
                                brushInitialized: state.brushInitialized
                              }]);
                case /* ShowTooltip */2 :
                    return /* Update */Block.__(0, [{
                                tooltip: action[0],
                                info: state.info,
                                coords: state.coords,
                                groupRef: state.groupRef,
                                brushInitialized: state.brushInitialized
                              }]);
                case /* SetGroupRef */3 :
                    var nullableRef = action[0];
                    if (nullableRef == null) {
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    state.groupRef.contents = undefined;
                                    
                                  })]);
                    }
                    if (!variant) {
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    state.groupRef.contents = Caml_option.some(nullableRef);
                                    
                                  })]);
                    }
                    var onChange = variant[0];
                    if (state.brushInitialized) {
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    state.groupRef.contents = Caml_option.some(nullableRef);
                                    
                                  })]);
                    } else {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  tooltip: state.tooltip,
                                  info: state.info,
                                  coords: state.coords,
                                  groupRef: state.groupRef,
                                  brushInitialized: true
                                },
                                (function (self) {
                                    var brush = createBrush(width, height);
                                    onBrushEnd(brush, nullableRef, xScale, (function (_event, range) {
                                            return Curry._1(onChange, getRangeFromBins(data, range));
                                          }));
                                    onBrushMove(brush, nullableRef, xScale, (function ($$event, _range) {
                                            var x = $$event.clientX;
                                            var y = $$event.clientY;
                                            return Curry._1(self.send, /* SetCoords */Block.__(0, [/* tuple */[
                                                            x,
                                                            y
                                                          ]]));
                                          }));
                                    state.groupRef.contents = Caml_option.some(nullableRef);
                                    
                                  })
                              ]);
                    }
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var DateChart = {
  getBrushElement: getBrushElement,
  getRangeFromBins: getRangeFromBins,
  createBrush: createBrush,
  addBrushEventListener: addBrushEventListener,
  onBrushEnd: onBrushEnd,
  onBrushMove: onBrushMove,
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("Charts__Bin-BsConsole__Chart");

function make$1(barFill, barHoverFill, numYAxisTicks, hideXAxisOpt, hideYAxisOpt, hideGridColumnsOpt, hideGridRowsOpt, data, param, param$1, yMax, xMax, height, width, _children) {
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : false;
  var yAxisWidth = hideYAxis ? 0 : 25;
  var xScale = xScaleFloat(yAxisWidth, xMax, Interpolate$BsConsole.Histogram.extent(data));
  var yScale$1 = yScale(yMax, $$Array.map((function (param) {
              return param[2];
            }), data));
  var svgWidth = width - ((
      hideXAxis ? 0 : 10
    ) + yAxisWidth | 0) | 0;
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              return ReasonReact.element(undefined, undefined, Curry._1(BtThemeContext$BsConsole.Consumer.Jsx2.make, (function (themeVariant) {
                                var match;
                                if (barFill !== undefined) {
                                  match = barHoverFill !== undefined ? /* tuple */[
                                      barFill,
                                      barHoverFill
                                    ] : /* tuple */[
                                      barFill,
                                      barFill
                                    ];
                                } else if (themeVariant !== undefined) {
                                  var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
                                  match = /* tuple */[
                                    palette.primary.main,
                                    palette.primary.dark
                                  ];
                                } else {
                                  match = /* tuple */[
                                    Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
                                    Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark)
                                  ];
                                }
                                var barHoverFillColor = match[1];
                                var barFillColor = match[0];
                                var match$1 = state.coords;
                                var match$2 = state.info;
                                var tmp;
                                if (match$1 !== undefined && match$2 !== undefined) {
                                  var y = match$1[1];
                                  var x = match$1[0];
                                  tmp = ReasonReact.element(String(x) + ("__" + String(y)), undefined, Tooltip$BsConsole.Proto.make(x, y, [ReasonReact.element(undefined, undefined, RangeTooltipContent$BsConsole.make(Renderer_Number$BsConsole.formatFloat(match$2[0]), Renderer_Number$BsConsole.formatFloat(match$2[1]), Renderer_Number$BsConsole.formatInt(match$2[2]) + " errors", []))]));
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.position(/* relative */903134412),
                                                  /* :: */[
                                                    Css.width(Css.px(width)),
                                                    /* [] */0
                                                  ]
                                                ])
                                          }, React.createElement("svg", {
                                                height: String(height),
                                                width: String(svgWidth),
                                                onMouseLeave: (function (_e) {
                                                    return Curry._1(send, /* HideTooltip */0);
                                                  }),
                                                onMouseMove: (function (e) {
                                                    var x = e.clientX;
                                                    var y = e.clientY;
                                                    return Curry._1(send, /* SetCoords */Block.__(0, [/* tuple */[
                                                                    x,
                                                                    y
                                                                  ]]));
                                                  })
                                              }, React.createElement("g", {
                                                    ref: (function (ref) {
                                                        return Curry._1(send, /* SetGroupRef */Block.__(3, [ref]));
                                                      }),
                                                    className: Css.style(/* :: */[
                                                          Css.unsafe("transform", "translate(" + (String(Css.marginLeft) + ("," + (String(Css.marginTop) + ")")))),
                                                          /* [] */0
                                                        ])
                                                  }, React.createElement("rect", {
                                                        height: String(yMax),
                                                        width: String(xMax),
                                                        fill: "transparent"
                                                      }), hideGridRows ? null : ReasonReact.element(undefined, undefined, BsVx_Grid__Rows$BsConsole.make(0, undefined, yScale$1, xMax, "#" + Colors$BsConsole.grey5, undefined, undefined, undefined, undefined, undefined, undefined, [])), hideGridColumns ? null : ReasonReact.element(undefined, undefined, BsVx_Grid__Columns$BsConsole.make(undefined, undefined, xScale, yMax, "#" + Colors$BsConsole.grey5, undefined, undefined, undefined, undefined, undefined, undefined, [])), React.createElement("g", undefined, hideXAxis ? null : ReasonReact.element(undefined, undefined, BsVx_Axis__Bottom$BsConsole.make(undefined, undefined, true, undefined, true, undefined, undefined, undefined, undefined, undefined, (width / 100 | 0) + 1 | 0, undefined, xScale, undefined, undefined, undefined, undefined, (function (value, param) {
                                                                    var v = Numeral$BsConsole.format("0.0a", value);
                                                                    if (v.includes(".0")) {
                                                                      return Numeral$BsConsole.format("0a", value);
                                                                    } else {
                                                                      return v;
                                                                    }
                                                                  }), (function (_tickValue, _index) {
                                                                    return {
                                                                            dy: "0",
                                                                            textAnchor: "start",
                                                                            fontFamily: "Inconsolata",
                                                                            fontSize: 10,
                                                                            fill: "#" + Colors$BsConsole.grey4
                                                                          };
                                                                  }), 4, "#" + Colors$BsConsole.grey4, undefined, yMax, []))), React.createElement("g", {
                                                        style: {
                                                          transform: "translate(49px, 0px)"
                                                        }
                                                      }, hideYAxis ? null : ReasonReact.element(undefined, undefined, BsVx_Axis__Left$BsConsole.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numYAxisTicks, undefined, yScale$1, undefined, undefined, undefined, undefined, undefined, (function (_tickValue, _index) {
                                                                    return {
                                                                            dx: "-2px",
                                                                            dy: "0.3em",
                                                                            textAnchor: "end",
                                                                            fontFamily: "Inconsolata;monospace",
                                                                            fontSize: 10
                                                                          };
                                                                  }), undefined, undefined, undefined, undefined, []))), Belt_Array.mapWithIndex(data, (function (i, param) {
                                                          var y = param[2];
                                                          var x2 = param[1];
                                                          var x1 = param[0];
                                                          var match = state.tooltip;
                                                          var isHovered = match !== undefined ? x1 === match[1] && x2 === match[2] : false;
                                                          var match$1 = state.info;
                                                          var isHovered$1 = match$1 !== undefined ? x1 === match$1[0] && x2 === match$1[1] : false;
                                                          return ReasonReact.element(String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__fragment")))), undefined, Fragment$BsConsole.make([
                                                                          ReasonReact.element(String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__bar")))), undefined, BsVx_Shape__Bar$BsConsole.make(undefined, Curry._1(xScale, x1) + yAxisWidth | 0, Curry._1(yScale$1, y) - 1 | 0, Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0), (yMax - Curry._1(yScale$1, y) | 0) + 1 | 0, undefined, undefined, isHovered ? barHoverFillColor : barFillColor, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [])),
                                                                          ReasonReact.element(String(i) + ("__" + (Pervasives.string_of_float(x1) + ("-" + (Pervasives.string_of_float(x2) + "__background")))), undefined, BsVx_Shape__Bar$BsConsole.make(undefined, Curry._1(xScale, x1) + yAxisWidth | 0, 0, Caml_primitive.caml_int_max((Curry._1(xScale, x2) - Curry._1(xScale, x1) | 0) - 1 | 0, 0), yMax, undefined, undefined, isHovered$1 ? barHoverFillColor : "transparent", 0.2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                        ]));
                                                        })))), tmp);
                              })));
            }),
          initialState: (function (param) {
              return {
                      tooltip: undefined,
                      info: undefined,
                      coords: undefined,
                      groupRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            tooltip: undefined,
                            info: undefined,
                            coords: undefined,
                            groupRef: state.groupRef
                          }]);
              }
              switch (action.tag | 0) {
                case /* SetCoords */0 :
                    var coords = action[0];
                    var match = state.groupRef.contents;
                    if (match !== undefined && coords !== undefined) {
                      var x = coords[0];
                      var x$prime = (x - Caml_option.valFromOption(match).getBoundingClientRect().left | 0) - yAxisWidth | 0;
                      var info = Belt_List.getBy(Belt_List.fromArray(data), (function (param) {
                              if (x$prime >= Curry._1(xScale, param[0])) {
                                return x$prime <= Curry._1(xScale, param[1]);
                              } else {
                                return false;
                              }
                            }));
                      return /* Update */Block.__(0, [{
                                  tooltip: state.tooltip,
                                  info: info,
                                  coords: /* tuple */[
                                    x,
                                    coords[1]
                                  ],
                                  groupRef: state.groupRef
                                }]);
                    }
                    return /* Update */Block.__(0, [{
                                tooltip: state.tooltip,
                                info: state.info,
                                coords: coords,
                                groupRef: state.groupRef
                              }]);
                case /* SetTooltipInfo */1 :
                    return /* Update */Block.__(0, [{
                                tooltip: state.tooltip,
                                info: action[0],
                                coords: state.coords,
                                groupRef: state.groupRef
                              }]);
                case /* ShowTooltip */2 :
                    return /* Update */Block.__(0, [{
                                tooltip: action[0],
                                info: state.info,
                                coords: state.coords,
                                groupRef: state.groupRef
                              }]);
                case /* SetGroupRef */3 :
                    var nullableRef = action[0];
                    if (nullableRef == null) {
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    state.groupRef.contents = undefined;
                                    
                                  })]);
                    } else {
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    state.groupRef.contents = Caml_option.some(nullableRef);
                                    
                                  })]);
                    }
                
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Chart = {
  maxLabelLength: 50,
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("Charts__Bin");

function toConsumable(bin) {
  return Belt_List.reduce(bin, undefined, (function (acc, param) {
                var count = param[2];
                var mf$prime$prime = param[1];
                var mf$prime = param[0];
                if (acc !== undefined) {
                  if (typeof acc === "number") {
                    return /* Unsupported */0;
                  }
                  switch (acc.tag | 0) {
                    case /* BinInt */0 :
                        if (mf$prime !== undefined && mf$prime.tag === /* Bytes */5 && mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                          return /* BinInt */Block.__(0, [/* :: */[
                                      /* tuple */[
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime[0])),
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime$prime[0])),
                                        count
                                      ],
                                      acc[0]
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    case /* BinFloat */1 :
                        if (mf$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match = mf$prime[0];
                        if (typeof match === "number") {
                          return /* Unsupported */0;
                        }
                        if (match[0] !== 365180284) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match$1 = mf$prime$prime[0];
                        if (typeof match$1 === "number" || match$1[0] !== 365180284) {
                          return /* Unsupported */0;
                        } else {
                          return /* BinFloat */Block.__(1, [/* :: */[
                                      /* tuple */[
                                        match[1],
                                        match$1[1],
                                        count
                                      ],
                                      acc[0]
                                    ]]);
                        }
                    case /* BinDate */2 :
                        var f$prime;
                        var f$prime$prime;
                        if (mf$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        switch (mf$prime.tag | 0) {
                          case /* Raw */0 :
                              var match$2 = mf$prime[0];
                              if (typeof match$2 === "number") {
                                return /* Unsupported */0;
                              }
                              if (match$2[0] !== 758940238) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime === undefined) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime.tag) {
                                return /* Unsupported */0;
                              }
                              var match$3 = mf$prime$prime[0];
                              if (typeof match$3 === "number") {
                                return /* Unsupported */0;
                              }
                              if (match$3[0] !== 758940238) {
                                return /* Unsupported */0;
                              }
                              f$prime = match$2[1];
                              f$prime$prime = match$3[1];
                              break;
                          case /* UnixTimestamp */2 :
                              if (mf$prime$prime === undefined) {
                                return /* Unsupported */0;
                              }
                              if (mf$prime$prime.tag !== /* UnixTimestamp */2) {
                                return /* Unsupported */0;
                              }
                              f$prime = mf$prime[0];
                              f$prime$prime = mf$prime$prime[0];
                              break;
                          default:
                            return /* Unsupported */0;
                        }
                        return /* BinDate */Block.__(2, [/* :: */[
                                    /* tuple */[
                                      f$prime,
                                      f$prime$prime,
                                      count
                                    ],
                                    acc[0]
                                  ]]);
                    
                  }
                } else {
                  if (mf$prime === undefined) {
                    return /* Unsupported */0;
                  }
                  switch (mf$prime.tag | 0) {
                    case /* Raw */0 :
                        var match$4 = mf$prime[0];
                        if (typeof match$4 === "number") {
                          return /* Unsupported */0;
                        }
                        var variant = match$4[0];
                        if (variant !== 365180284) {
                          if (variant !== 758940238) {
                            return /* Unsupported */0;
                          }
                          if (mf$prime$prime === undefined) {
                            return /* Unsupported */0;
                          }
                          if (mf$prime$prime.tag) {
                            return /* Unsupported */0;
                          }
                          var match$5 = mf$prime$prime[0];
                          if (typeof match$5 === "number" || match$5[0] !== 758940238) {
                            return /* Unsupported */0;
                          } else {
                            return /* BinDate */Block.__(2, [/* :: */[
                                        /* tuple */[
                                          match$4[1],
                                          match$5[1],
                                          count
                                        ],
                                        /* [] */0
                                      ]]);
                          }
                        }
                        if (mf$prime$prime === undefined) {
                          return /* Unsupported */0;
                        }
                        if (mf$prime$prime.tag) {
                          return /* Unsupported */0;
                        }
                        var match$6 = mf$prime$prime[0];
                        if (typeof match$6 === "number" || match$6[0] !== 365180284) {
                          return /* Unsupported */0;
                        } else {
                          return /* BinFloat */Block.__(1, [/* :: */[
                                      /* tuple */[
                                        match$4[1],
                                        match$6[1],
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        }
                    case /* UnixTimestamp */2 :
                        if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* UnixTimestamp */2) {
                          return /* BinDate */Block.__(2, [/* :: */[
                                      /* tuple */[
                                        mf$prime[0],
                                        mf$prime$prime[0],
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    case /* Bytes */5 :
                        if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                          return /* BinInt */Block.__(0, [/* :: */[
                                      /* tuple */[
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime[0])),
                                        Js_math.floor(Caml_format.caml_float_of_string(mf$prime$prime[0])),
                                        count
                                      ],
                                      /* [] */0
                                    ]]);
                        } else {
                          return /* Unsupported */0;
                        }
                    default:
                      return /* Unsupported */0;
                  }
                }
              }));
}

function binToConsumable(bin) {
  return Belt_List.reduce(bin, /* [] */0, (function (acc, param) {
                if (acc === undefined) {
                  return ;
                }
                var count = param[2];
                var mf$prime$prime = param[1];
                var mf$prime = param[0];
                if (mf$prime === undefined) {
                  return ;
                }
                switch (mf$prime.tag | 0) {
                  case /* Raw */0 :
                      var match = mf$prime[0];
                      var variant = match[0];
                      if (variant >= 737456202) {
                        if (variant >= 758940238) {
                          if (mf$prime$prime === undefined) {
                            return ;
                          }
                          if (mf$prime$prime.tag) {
                            return ;
                          }
                          var match$1 = mf$prime$prime[0];
                          if (typeof match$1 === "number" || match$1[0] !== 758940238) {
                            return ;
                          } else {
                            return /* :: */[
                                    /* tuple */[
                                      match[1].valueOf() | 0,
                                      match$1[1].valueOf() | 0,
                                      count
                                    ],
                                    acc
                                  ];
                          }
                        }
                        if (mf$prime$prime === undefined) {
                          return ;
                        }
                        if (mf$prime$prime.tag) {
                          return ;
                        }
                        return ;
                      }
                      if (variant >= 365180284) {
                        if (mf$prime$prime === undefined) {
                          return ;
                        }
                        if (mf$prime$prime.tag) {
                          return ;
                        }
                        var match$2 = mf$prime$prime[0];
                        if (typeof match$2 === "number" || match$2[0] !== 365180284) {
                          return ;
                        } else {
                          return /* :: */[
                                  /* tuple */[
                                    match[1] | 0,
                                    match$2[1] | 0,
                                    count
                                  ],
                                  acc
                                ];
                        }
                      }
                      if (mf$prime$prime === undefined) {
                        return ;
                      }
                      if (mf$prime$prime.tag) {
                        return ;
                      }
                      return ;
                  case /* UnixTimestamp */2 :
                      if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* UnixTimestamp */2) {
                        return /* :: */[
                                /* tuple */[
                                  mf$prime[0].valueOf() | 0,
                                  mf$prime$prime[0].valueOf() | 0,
                                  count
                                ],
                                acc
                              ];
                      } else {
                        return ;
                      }
                  case /* Classifiers */4 :
                      return ;
                  case /* Bytes */5 :
                      if (mf$prime$prime !== undefined && mf$prime$prime.tag === /* Bytes */5) {
                        return /* :: */[
                                /* tuple */[
                                  Caml_format.caml_int_of_string(mf$prime[0]),
                                  Caml_format.caml_int_of_string(mf$prime$prime[0]),
                                  count
                                ],
                                acc
                              ];
                      } else {
                        return ;
                      }
                  default:
                    return ;
                }
              }));
}

function make$2(variantOpt, binsOpt, numYAxisTicksOpt, range, heightOpt, widthOpt, hideXAxisOpt, hideYAxisOpt, hideGridRowsOpt, hideGridColumnsOpt, marginLeftOpt, marginTopOpt, marginRightOpt, marginBottomOpt, barFill, barHoverFill, bin, _children) {
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var bins = binsOpt !== undefined ? binsOpt : 32;
  var numYAxisTicks = numYAxisTicksOpt !== undefined ? numYAxisTicksOpt : 6;
  var height = heightOpt !== undefined ? heightOpt : 100;
  var width = widthOpt !== undefined ? widthOpt : 600;
  var hideXAxis = hideXAxisOpt !== undefined ? hideXAxisOpt : false;
  var hideYAxis = hideYAxisOpt !== undefined ? hideYAxisOpt : false;
  var hideGridRows = hideGridRowsOpt !== undefined ? hideGridRowsOpt : true;
  var hideGridColumns = hideGridColumnsOpt !== undefined ? hideGridColumnsOpt : true;
  var marginLeft = marginLeftOpt !== undefined ? marginLeftOpt : 0;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : 20;
  var marginRight = marginRightOpt !== undefined ? marginRightOpt : 20;
  var marginBottom = marginBottomOpt !== undefined ? marginBottomOpt : 30;
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              var xMax = function (width) {
                return ((width - marginLeft | 0) - marginRight | 0) - (
                        hideXAxis ? 0 : 8
                      ) | 0;
              };
              var yMax = function (height) {
                return (height - marginTop | 0) - marginBottom | 0;
              };
              var data = toConsumable(bin);
              if (data === undefined) {
                return I18N$BsConsole.show(undefined, "No data");
              }
              if (typeof data === "number") {
                return I18N$BsConsole.show(undefined, "Unsupported");
              }
              switch (data.tag | 0) {
                case /* BinInt */0 :
                    var data$prime = Belt_List.map(data[0], (function (param) {
                            return /* tuple */[
                                    param[0],
                                    param[1],
                                    param[2]
                                  ];
                          }));
                    var extent = D3Array.extent(Belt_List.reduce(data$prime, [], (function (acc, param) {
                                return Belt_Array.concat(acc, [
                                            param[0],
                                            param[1]
                                          ]);
                              })));
                    var bins$1 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, extent[0], extent[1]);
                    var arr = Interpolate$BsConsole.Histogram.merge(bins$1, Belt_List.toArray(Belt_List.reverse(data$prime)));
                    var height$1 = (height + marginTop | 0) + marginBottom | 0;
                    var xMax$1 = xMax(width);
                    var yMax$1 = yMax(height$1);
                    return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, make$1(barFill, barHoverFill, numYAxisTicks, hideXAxis, hideYAxis, hideGridColumns, hideGridRows, arr, marginTop, marginLeft, yMax$1, xMax$1, height$1, width, [])));
                case /* BinFloat */1 :
                    var data$prime$1 = data[0];
                    var extent$1 = D3Array.extent(Belt_List.reduce(data$prime$1, [], (function (acc, param) {
                                return Belt_Array.concat(acc, [
                                            param[0],
                                            param[1]
                                          ]);
                              })));
                    var bins$2 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, extent$1[0], extent$1[1]);
                    var arr$1 = Interpolate$BsConsole.Histogram.merge(bins$2, Belt_List.toArray(Belt_List.reverse(data$prime$1)));
                    var height$2 = (height + marginTop | 0) + marginBottom | 0;
                    var xMax$2 = xMax(width);
                    var yMax$2 = yMax(height$2);
                    return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, make$1(barFill, barHoverFill, numYAxisTicks, hideXAxis, hideYAxis, hideGridColumns, hideGridRows, arr$1, marginTop, marginLeft, yMax$2, xMax$2, height$2, width, [])));
                case /* BinDate */2 :
                    var data$prime$2 = data[0];
                    var extent$2 = D3Array.extent(Belt_List.reduce(data$prime$2, [], (function (acc, param) {
                                return Belt_Array.concat(acc, [
                                            param[0],
                                            param[1]
                                          ]);
                              })));
                    var extent$prime = Belt_Option.getWithDefault(range, extent$2);
                    var bins$3 = Interpolate$BsConsole.Histogram.make(/* Float */0, bins, Math.floor(extent$prime[0].valueOf() / 1000), Math.floor(extent$prime[1].valueOf() / 1000));
                    var arr$2;
                    try {
                      arr$2 = Belt_Array.map(Interpolate$BsConsole.Histogram.merge(bins$3, Belt_List.toArray(Belt_List.reverse(Belt_List.map(data$prime$2, (function (param) {
                                              return /* tuple */[
                                                      Math.floor(param[0].valueOf() / 1000),
                                                      Math.floor(param[1].valueOf() / 1000),
                                                      param[2]
                                                    ];
                                            }))))), (function (param) {
                              return /* tuple */[
                                      new Date(param[0] * 1000),
                                      new Date(param[1] * 1000),
                                      param[2]
                                    ];
                            }));
                    }
                    catch (exn){
                      arr$2 = undefined;
                    }
                    if (arr$2 === undefined) {
                      return React.createElement("div", {
                                  style: {
                                    display: "flex",
                                    alignItems: "center",
                                    flex: "1",
                                    justifyContent: "center"
                                  }
                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])));
                    }
                    var height$3 = (height + marginTop | 0) + marginBottom | 0;
                    var xMax$3 = xMax(width);
                    var yMax$3 = yMax(height$3);
                    var sx = xScaleDate(xMax$3, Interpolate$BsConsole.Histogram.extentDate(arr$2));
                    var sy = yScale(yMax$3, $$Array.map((function (param) {
                                return param[2];
                              }), arr$2));
                    return ReasonReact.element(String(width), undefined, make(variant, barFill, barHoverFill, numYAxisTicks, hideXAxis, hideYAxis, hideGridColumns, hideGridRows, arr$2, marginTop, marginLeft, yMax$3, xMax$3, sx, sy, height$3, width, []));
                
              }
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var make$3 = ReasonReactCompat.wrapReasonReactForReact(component$2, (function (reactProps) {
        return make$2(Caml_option.undefined_to_opt(reactProps.variant), Caml_option.undefined_to_opt(reactProps.bins), Caml_option.undefined_to_opt(reactProps.numYAxisTicks), Caml_option.undefined_to_opt(reactProps.range), Caml_option.undefined_to_opt(reactProps.height), Caml_option.undefined_to_opt(reactProps.width), Caml_option.undefined_to_opt(reactProps.hideXAxis), Caml_option.undefined_to_opt(reactProps.hideYAxis), Caml_option.undefined_to_opt(reactProps.hideGridRows), Caml_option.undefined_to_opt(reactProps.hideGridColumns), Caml_option.undefined_to_opt(reactProps.marginLeft), Caml_option.undefined_to_opt(reactProps.marginTop), Caml_option.undefined_to_opt(reactProps.marginRight), Caml_option.undefined_to_opt(reactProps.marginBottom), Caml_option.undefined_to_opt(reactProps.barFill), Caml_option.undefined_to_opt(reactProps.barHoverFill), reactProps.bin, []);
      }));

var Jsx3 = {
  make: make$3
};

exports.TooltipController = TooltipController;
exports.maxInt = maxInt;
exports.xScaleInt = xScaleInt;
exports.xScaleFloat = xScaleFloat;
exports.xScaleDate = xScaleDate;
exports.yScale = yScale;
exports.DateChart = DateChart;
exports.Chart = Chart;
exports.component = component$2;
exports.toConsumable = toConsumable;
exports.binToConsumable = binToConsumable;
exports.make = make$2;
exports.Jsx3 = Jsx3;
/* TooltipController Not a pure module */
