// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

var Entry = { };

function use(elRef) {
  var match = React.useState((function () {
          return new DOMRect(0, 0, 0, 0);
        }));
  var setContentRect = match[1];
  var observer = React.useRef(new ResizeObserver((function (entries) {
              Belt_Option.map(Belt_Array.get(entries, 0), (function (entry) {
                      var nextContentRect = entry.contentRect;
                      return Curry._1(setContentRect, (function (param) {
                                    return nextContentRect;
                                  }));
                    }));
              
            })));
  React.useLayoutEffect((function () {
          var target = elRef.current;
          if (!(target == null)) {
            observer.current.observe(target);
            return (function (param) {
                      observer.current.unobserve(target);
                      
                    });
          }
          
        }), /* tuple */[
        elRef,
        observer
      ]);
  return match[0];
}

exports.Entry = Entry;
exports.use = use;
/* react Not a pure module */
