// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var ReactDropzone = require("react-dropzone");
var Colors$BsConsole = require("../Colors.js");
var Upload$BsConsole = require("../Upload.js");
var Request$BsConsole = require("../Request.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("../configuration.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function BugAttachmentUpload$FileUpload(Props) {
  var config = Props.config;
  var errorId = Props.errorId;
  var file = Props.file;
  var onAttachmentUploaded = Props.onAttachmentUploaded;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var universe = config.universe;
  var port = Belt_Option.getExn(Configuration$BsConsole.getPort(undefined, config));
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setStatus = match[1];
  var status = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setProgress = match$1[1];
  React.useEffect((function () {
          Curry._1(setStatus, (function (param) {
                  return /* Loading */1;
                }));
          var match = Upload$BsConsole.makeRequest(/* FileAttachment */Block.__(0, [{
                    token: token,
                    port: port,
                    project: projectName,
                    universe: universe.name,
                    objectID: errorId,
                    file: file
                  }]), (function (response) {
                  var match = response.status;
                  if (match) {
                    Curry._1(onAttachmentUploaded, undefined);
                    return Curry._1(setStatus, (function (param) {
                                  return /* Success */Block.__(0, [undefined]);
                                }));
                  } else {
                    return Curry._1(setStatus, (function (param) {
                                  return /* Failure */Block.__(1, [response.message]);
                                }));
                  }
                }));
          var request = match[0];
          Request$BsConsole.XML_HttpRequest.addOnProgressListener((function (param) {
                  var total = param[1];
                  var loaded = param[0];
                  return Curry._1(setProgress, (function (param) {
                                return loaded / total * 100;
                              }));
                }), request);
          request.send(file);
          
        }), ([]));
  var tmp;
  tmp = typeof status === "number" ? Colors$BsConsole.blue : (
      status.tag ? Colors$BsConsole.red : Colors$BsConsole.green
    );
  var tmp$1;
  tmp$1 = typeof status === "number" ? I18N$BsConsole.get(undefined, "Uploading") : (
      status.tag ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Error: ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ]),
                  "Error: %s"
                ]), status[0]) : I18N$BsConsole.get(undefined, "Uploaded")
    );
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.padding(Css.px(8)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement("div", undefined, I18N$BsConsole.showSkip(file.name)), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(tmp)),
                            /* [] */0
                          ])
                    }, I18N$BsConsole.showSkip(tmp$1))), React.createElement(LinearProgress.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.height(Css.px(6)),
                            /* [] */0
                          ]
                        ])
                  },
                  variant: "determinate",
                  value: match$1[0]
                }));
}

var FileUpload = {
  make: BugAttachmentUpload$FileUpload
};

function BugAttachmentUpload(Props) {
  var config = Props.config;
  var errorId = Props.errorId;
  var onAttachmentUploaded = Props.onAttachmentUploaded;
  var match = React.useState((function () {
          return [];
        }));
  var setFiles = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  square: true,
                  children: React.createElement(ReactDropzone.default, {
                        className: Css.style(/* :: */[
                              Css.minWidth(Css.px(350)),
                              /* :: */[
                                Css.padding(Css.em(3)),
                                /* [] */0
                              ]
                            ]),
                        onDrop: (function (files) {
                            return Curry._1(setFiles, (function (param) {
                                          return files;
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Drag & drop files here OR click to select files")
                      })
                }), Belt_Array.map(match[0], (function (file) {
                    return React.createElement(BugAttachmentUpload$FileUpload, {
                                config: config,
                                errorId: errorId,
                                file: file,
                                onAttachmentUploaded: onAttachmentUploaded,
                                key: file.name
                              });
                  })));
}

var make = BugAttachmentUpload;

exports.FileUpload = FileUpload;
exports.make = make;
/* Css Not a pure module */
