// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var D3Scale = require("d3-scale");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var VX$BsConsole = require("../VX.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Tooltip = require("@material-ui/core/Tooltip");
var AlertsTimeOptions$BsConsole = require("./AlertsTimeOptions.js");
var CircleJs = require("@vx/marker/lib/markers/Circle.js");

var warningTriggerCountText = I18N$BsConsole.get(undefined, "Warning trigger count");

var criticalTriggerCountText = I18N$BsConsole.get(undefined, "Critical trigger count");

var criticalStroke = "#" + Colors$BsConsole.redDark;

var criticalFill = "#" + Colors$BsConsole.redLight;

var warningStroke = "#" + Colors$BsConsole.orange;

var warningFill = "#" + Colors$BsConsole.orangeExtraLight;

var Circle = { };

function AlertsChart(Props) {
  var data = Props.data;
  var startTimestamp = Props.startTimestamp;
  var endTimestamp = Props.endTimestamp;
  var timeOption = Props.timeOption;
  var yMax = AlertsTimeOptions$BsConsole.toTimelineChartNodeSize(timeOption);
  var maxInt = function (arr) {
    return Belt_Array.reduce(arr, 0, (function (acc, v) {
                  if (v > acc) {
                    return v;
                  } else {
                    return acc;
                  }
                }));
  };
  var yScale = function (yMax, data) {
    return D3Scale.scaleLinear().rangeRound([
                  0,
                  yMax
                ]).domain([
                0,
                maxInt(data)
              ]);
  };
  var xScaleFloat = function (xMin, xMax, param) {
    return D3Scale.scaleLinear().rangeRound([
                  xMin,
                  xMax
                ]).domain([
                param[0],
                param[1]
              ]);
  };
  var firstElement = Belt_Array.get(data, 0);
  var lastElement = Belt_Array.get(data, data.length - 1 | 0);
  var extent = firstElement !== undefined ? (
      lastElement !== undefined ? /* tuple */[
          firstElement[0],
          lastElement[1]
        ] : /* tuple */[
          0.0,
          Pervasives.max_float
        ]
    ) : /* tuple */[
      0.0,
      Pervasives.max_float
    ];
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(VX$BsConsole.Responsive.ParentSizeJsx3.make, {
                      children: (function (parentSize) {
                          var chartWidth = (parentSize.width | 0) - (yMax << 1) | 0;
                          var match = Belt_Option.getWithDefault(lastElement, /* tuple */[
                                0,
                                0,
                                0,
                                /* Warning */0,
                                undefined
                              ]);
                          var xScaleWidth = match[2] > 0 ? chartWidth : parentSize.width | 0;
                          var xScale = xScaleFloat(0, xScaleWidth, extent);
                          var yScaleFunction = yScale(yMax, Belt_Array.map(data, (function (param) {
                                      return param[2];
                                    })));
                          return React.createElement(Col2$BsConsole.make, {
                                      children: null
                                    }, React.createElement("svg", {
                                          height: String(100),
                                          width: Pervasives.string_of_float(parentSize.width)
                                        }, React.createElement("g", undefined, Belt_Array.mapWithIndex(data, (function (_i, param) {
                                                    var $$eval = param[4];
                                                    var triggerType = param[3];
                                                    var y = param[2];
                                                    var x1 = param[0];
                                                    var dotId = "dot" + (Pervasives.string_of_float(x1) + Pervasives.string_of_float(param[1]));
                                                    var nodeSize = Curry._1(yScaleFunction, y);
                                                    var nodeDetails;
                                                    if ($$eval !== undefined) {
                                                      var warningTriggerCount = $$eval.warningTriggerCount;
                                                      var criticalTriggerCount = $$eval.criticalTriggerCount;
                                                      var dateString = new Date($$eval.evaluationStart * 1000).toDateString();
                                                      nodeDetails = "" + (String(dateString) + "    ") + ("- " + (String(warningTriggerCountText) + (": " + (String(warningTriggerCount) + "    "))) + ("- " + (String(criticalTriggerCountText) + (": " + (String(criticalTriggerCount) + "    ")))));
                                                    } else {
                                                      nodeDetails = "";
                                                    }
                                                    return React.createElement(React.Fragment, {
                                                                children: React.createElement("svg", undefined, React.createElement("g", undefined, React.createElement(CircleJs.default, {
                                                                              refX: (yMax / 2 | 0) + 1 | 0,
                                                                              refY: nodeSize,
                                                                              id: dotId,
                                                                              markerHeight: (nodeSize << 1) + 2 | 0,
                                                                              markerUnits: "strokeWidth",
                                                                              markerWidth: (nodeSize << 1) + 2 | 0,
                                                                              size: nodeSize,
                                                                              strokeWidth: 1,
                                                                              fill: triggerType ? criticalFill : warningFill,
                                                                              stroke: triggerType ? criticalStroke : warningStroke
                                                                            }), React.createElement("polyline", {
                                                                              fill: "none",
                                                                              markerStart: y > 0 ? "url(#" + (dotId + ")") : "",
                                                                              points: String(Curry._1(xScale, x1)) + (",60 " + (String(parentSize.width | 0) + ",60")),
                                                                              stroke: "#" + Colors$BsConsole.grey5
                                                                            }), React.createElement(Tooltip.default, {
                                                                              classes: {
                                                                                tooltip: Css.style(/* :: */[
                                                                                      Css.maxWidth(/* none */-922086728),
                                                                                      /* [] */0
                                                                                    ])
                                                                              },
                                                                              enterDelay: 250,
                                                                              children: React.createElement("circle", {
                                                                                    cx: String(Curry._1(xScale, x1) - AlertsTimeOptions$BsConsole.toTimelineChartMouseOffset(timeOption) | 0),
                                                                                    cy: "60",
                                                                                    fill: "transparent",
                                                                                    r: String(nodeSize)
                                                                                  }),
                                                                              leaveDelay: 250,
                                                                              title: nodeDetails
                                                                            }))),
                                                                key: dotId
                                                              });
                                                  })))), React.createElement(Row2$BsConsole.make, {
                                          alignItems: /* center */98248149,
                                          justifyContent: /* spaceBetween */516682146,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: null
                                        }, React.createElement("div", undefined, I18N$BsConsole.showSkip(new Date(startTimestamp * 1000).toLocaleDateString())), React.createElement("div", undefined, I18N$BsConsole.showSkip(new Date(endTimestamp * 1000).toLocaleDateString()))));
                        })
                    })));
}

var make = AlertsChart;

exports.warningTriggerCountText = warningTriggerCountText;
exports.criticalTriggerCountText = criticalTriggerCountText;
exports.criticalStroke = criticalStroke;
exports.criticalFill = criticalFill;
exports.warningStroke = warningStroke;
exports.warningFill = warningFill;
exports.Circle = Circle;
exports.make = make;
/* warningTriggerCountText Not a pure module */
