// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var Health$BsConsole = require("../../Health.js");
var PlainTextView$BsConsole = require("../PlainTextView.js");
var SymbolServer_API$BsConsole = require("./SymbolServers/SymbolServer_API.js");
var SymbolServers_InfoView$BsConsole = require("./SymbolServers/SymbolServers_InfoView.js");

var symbolServersTitle = I18N$BsConsole.get(undefined, "Symbol servers");

var symboldEndpointName = "symbold";

var component = RR$BsConsole.reducerComponent("PS_SymbolServers-BsConsole");

function make(shell, param, token, param$1, name, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var editView = match.editView;
              var addView = match.addView;
              var tmp;
              if (typeof shell === "number") {
                tmp = shell === /* NotAsked */0 ? null : ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Loader */1, []));
              } else if (shell.tag) {
                tmp = ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Error */0, []));
              } else {
                var config = shell[0];
                var isSuperUser = config.user.superuser === 1;
                var universe = config.universe;
                var s = config.services;
                var maybeEndpoints;
                if (s !== undefined) {
                  var __x = Belt_List.filter(s, (function (item) {
                          return item.name === symboldEndpointName;
                        }));
                  maybeEndpoints = Belt_List.map(__x, (function (e) {
                          var e$1 = e.endpoint;
                          if (e$1 !== undefined) {
                            return e$1;
                          } else {
                            return "unknown-api-endpoint";
                          }
                        }));
                } else {
                  maybeEndpoints = /* [] */0;
                }
                var tmp$1;
                var exit = 0;
                if (maybeEndpoints && !maybeEndpoints[1]) {
                  var endpoint = maybeEndpoints[0];
                  tmp$1 = ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Health$BsConsole.makeTask(endpoint), undefined, (function (healthRemote, param) {
                              return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, SymbolServer_API$BsConsole.getSymbolServers(universe.name, name, endpoint, undefined, undefined, undefined), undefined, (function (remote, refetch) {
                                                if (typeof remote === "number") {
                                                  if (remote === /* NotAsked */0) {
                                                    return null;
                                                  } else {
                                                    return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Loader */1, []));
                                                  }
                                                }
                                                if (remote.tag) {
                                                  return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Error */0, []));
                                                }
                                                if (typeof healthRemote === "number") {
                                                  return null;
                                                }
                                                var symboldVersion;
                                                symboldVersion = typeof healthRemote === "number" || healthRemote.tag ? "2.0.0" : healthRemote[0][0].version;
                                                return ReasonReact.element(undefined, undefined, SymbolServers_InfoView$BsConsole.make(symboldVersion, remote[0][0], isSuperUser, (function (param) {
                                                                  return Curry._1(send, /* ShowAddView */0);
                                                                }), (function (param) {
                                                                  return Curry._1(send, /* HideAddView */1);
                                                                }), (function (id) {
                                                                  return Curry._1(send, /* ShowEditView */[id]);
                                                                }), (function (param) {
                                                                  return Curry._1(send, /* HideEditView */2);
                                                                }), addView, editView, universe.name, name, token, endpoint, refetch, []));
                                              })));
                            })));
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp$1 = ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Disabled */2, []));
                }
                tmp = React.createElement(React.Fragment, undefined, tmp$1);
              }
              return React.createElement(React.Fragment, undefined, tmp);
            }),
          initialState: (function (param) {
              return {
                      addView: false,
                      editView: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            addView: state.addView,
                            editView: action[0]
                          }]);
              }
              switch (action) {
                case /* ShowAddView */0 :
                    return /* Update */Block.__(0, [{
                                addView: true,
                                editView: undefined
                              }]);
                case /* HideAddView */1 :
                    return /* Update */Block.__(0, [{
                                addView: false,
                                editView: state.editView
                              }]);
                case /* HideEditView */2 :
                    return /* Update */Block.__(0, [{
                                addView: state.addView,
                                editView: undefined
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.symbolServersTitle = symbolServersTitle;
exports.symboldEndpointName = symboldEndpointName;
exports.component = component;
exports.make = make;
/* symbolServersTitle Not a pure module */
