// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Slate = require("slate");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var CommentContent$BsConsole = require("./CommentContent.js");

var initialValue = Slate.Value.fromJSON({
      document: {
        nodes: [{
            object: "block",
            type: "paragraph",
            nodes: []
          }]
      }
    });

var component = RR$BsConsole.statelessComponent("CommentInput-BsConsole");

function make(value, onChange, allUsers, activeUser, onSendComment, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return React.createElement("div", {
                          className: Css.merge(/* :: */[
                                Css.style(/* :: */[
                                      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                      /* :: */[
                                        Css.borderRadius(Css.px(4)),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.padding(Css.px(12)),
                                              /* :: */[
                                                Css.marginBottom(Css.rem(1)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* :: */[
                                  "CommentInput_container",
                                  /* [] */0
                                ]
                              ])
                        }, React.createElement("div", {
                              style: {
                                minHeight: "80px",
                                flexGrow: "1"
                              }
                            }, ReasonReact.element(undefined, undefined, CommentContent$BsConsole.make(undefined, undefined, true, allUsers, activeUser, value, Curry.__1(onChange), []))), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                  undefined,
                                  Css.style(/* :: */[
                                        Css.alignSelf(Css.flexEnd),
                                        /* :: */[
                                          Css.marginRight(Css.px(-8)),
                                          /* :: */[
                                            Css.marginBottom(Css.px(-6)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  (function (param) {
                                      return Curry._2(onSendComment, value, undefined);
                                    }),
                                  undefined,
                                  undefined,
                                  /* Raised */-387916264,
                                  /* Primary */-791844958,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [I18N$BsConsole.show(undefined, "Post")]
                                ])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.initialValue = initialValue;
exports.component = component;
exports.make = make;
/* initialValue Not a pure module */
