// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Configuration$BsConsole = require("../configuration.js");
var ReprocessQuery$BsConsole = require("./ReprocessQuery.js");
var RetentionPolicy$BsConsole = require("../RetentionPolicy.js");
var Reprocessing_Poll$BsConsole = require("./Reprocessing_Poll.js");
var Reprocessing_Tasks$BsConsole = require("./Reprocessing_Tasks.js");

var hour = 60 * 60;

var day = hour * 24;

var week = day * 7;

var month = day * 30;

var quarter = month * 3;

var year = day * 365.25;

var defaultOptions_000 = {
  ago: hour,
  title: I18N$BsConsole.get(undefined, "last hour"),
  value: "hour",
  withinRange: true
};

var defaultOptions_001 = /* :: */[
  {
    ago: day,
    title: I18N$BsConsole.get(undefined, "last day"),
    value: "day",
    withinRange: true
  },
  /* :: */[
    {
      ago: week,
      title: I18N$BsConsole.get(undefined, "last 7 days"),
      value: "week",
      withinRange: true
    },
    /* :: */[
      {
        ago: month,
        title: I18N$BsConsole.get(undefined, "last 30 days"),
        value: "month",
        withinRange: true
      },
      /* :: */[
        {
          ago: quarter,
          title: I18N$BsConsole.get(undefined, "last 90 days"),
          value: "quarter",
          withinRange: true
        },
        /* :: */[
          {
            ago: year,
            title: I18N$BsConsole.get(undefined, "last year"),
            value: "year",
            withinRange: true
          },
          /* :: */[
            {
              ago: 0,
              title: I18N$BsConsole.get(undefined, "all time"),
              value: "all",
              withinRange: true
            },
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var defaultOptions = /* :: */[
  defaultOptions_000,
  defaultOptions_001
];

function isWithinRange(moment, range) {
  return moment > range;
}

var OptionsList = {
  minute: 60,
  hour: hour,
  day: day,
  week: week,
  month: month,
  quarter: quarter,
  year: year,
  all: 1,
  defaultOptions: defaultOptions,
  isWithinRange: isWithinRange
};

var coronerSupportsReprocessByQuery = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.43.0");

function Reprocessing(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var match = BpgTask$BsConsole.use(token, BpgTask$BsConsole.InstanceRetention.fetchAll);
  var instanceRetentionPolices = match[0];
  var match$1 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.UniverseRetention.fetchAll);
  var universeRetentionPolices = match$1[0];
  var match$2 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.ProjectRetention.fetchAll);
  var projectRetentionPolices = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setCurrentlyReprocessing = match$3[1];
  var currentlyReprocessing = match$3[0];
  var match$4 = React.useState((function () {
          if (coronerSupportsReprocessByQuery) {
            return "hour";
          } else {
            return "all";
          }
        }));
  var setReprocessingTimespanString = match$4[1];
  var reprocessingTimespanString = match$4[0];
  var match$5 = React.useState((function () {
          return defaultOptions;
        }));
  var setOptions = match$5[1];
  var options = match$5[0];
  var universe = config.universe.name;
  React.useEffect((function () {
          var match = Configuration$BsConsole.getProject(projectName, config);
          if (typeof instanceRetentionPolices !== "number" && !instanceRetentionPolices.tag && typeof universeRetentionPolices !== "number" && !universeRetentionPolices.tag && typeof projectRetentionPolices !== "number" && !projectRetentionPolices.tag && match !== undefined) {
            var pid = match.pid;
            var instanceRules = Belt_Option.map(Belt_Array.get(instanceRetentionPolices[0], 0), (function (policy) {
                    return RetentionPolicy$BsConsole.fromString(policy.rules);
                  }));
            var universeRules = Belt_Option.map(Belt_Array.getBy(universeRetentionPolices[0], (function (uni) {
                        return uni.universe === config.universe.id;
                      })), (function (policy) {
                    return RetentionPolicy$BsConsole.fromString(policy.rules);
                  }));
            var projectRules = Belt_Option.map(Belt_Array.getBy(projectRetentionPolices[0], (function (pol) {
                        return pol.project === pid;
                      })), (function (policy) {
                    return RetentionPolicy$BsConsole.fromString(policy.rules);
                  }));
            var rules = Belt_Array.concatMany(Belt_Array.map(Belt_Array.keep([
                          instanceRules,
                          universeRules,
                          projectRules
                        ], Belt_Option.isSome), Belt_Option.getExn));
            var smallestRange = Belt_List.get(Belt_List.sort(Belt_List.map(Belt_List.fromArray(rules), (function (r) {
                            return Caml_format.caml_float_of_string(r.criteria.time);
                          })), (function (a, b) {
                        return a - b | 0;
                      })), 0);
            Belt_Option.map(smallestRange, (function (ruleAgo) {
                    return Curry._1(setOptions, (function (param) {
                                  return Belt_List.map(defaultOptions, (function (opt) {
                                                var match = opt.value;
                                                if (match === "all") {
                                                  return {
                                                          ago: opt.ago,
                                                          title: opt.title,
                                                          value: opt.value,
                                                          withinRange: false
                                                        };
                                                } else {
                                                  return {
                                                          ago: opt.ago,
                                                          title: opt.title,
                                                          value: opt.value,
                                                          withinRange: ruleAgo > opt.ago
                                                        };
                                                }
                                              }));
                                }));
                  }));
          }
          
        }), /* tuple */[
        instanceRetentionPolices,
        universeRetentionPolices,
        projectRetentionPolices
      ]);
  var timespanDropdown = function (param) {
    return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    /* `String */[
                      -976970511,
                      reprocessingTimespanString
                    ],
                    (function ($$event, _element) {
                        return Curry._1(setReprocessingTimespanString, $$event.target.value);
                      }),
                    true,
                    undefined,
                    Css.style(/* :: */[
                          Css.marginLeft(/* `percent */[
                                -119887163,
                                1.0
                              ]),
                          /* [] */0
                        ]),
                    undefined,
                    undefined,
                    currentlyReprocessing,
                    undefined,
                    [Belt_List.map(options, (function (opt) {
                              if (opt.withinRange) {
                                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                -976970511,
                                                opt.value
                                              ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(opt.title)]))]));
                              } else {
                                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                -976970511,
                                                opt.value
                                              ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Includes objects ouside of what your retential policy criteria allows.  These objects would not be reprocessed."), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(opt.title + "*")]))]))]));
                              }
                            }))]
                  ]));
  };
  var reprocessObjectsForm = function (param) {
    var timeAperture = Crdb$BsConsole.granularityStringToTimeAperture(reprocessingTimespanString);
    var aperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default);
    var query_001 = /* :: */[
      "*",
      /* [] */0
    ];
    var query = /* Select */Block.__(1, [
        aperture,
        query_001,
        undefined,
        undefined
      ]);
    return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, ReprocessQuery$BsConsole.Re.make(undefined, undefined, token, projectName, config.universe.name, query, reprocessingTimespanString !== "all", Caml_option.some(coronerSupportsReprocessByQuery ? /* Reprocess_WithQuery_FromSettings */3 : /* Reprocess_WithoutQuery_FromSettings */2), (function (param) {
                                var displayProgress = param[1];
                                var confirmAndReprocess = param[0];
                                return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.justifyContent(/* spaceBetween */516682146),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* :: */[
                                                            Css.alignItems(/* flexEnd */924268066),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ])
                                              }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                            Css.padding4(Css.em(1), Css.px(24), Css.zero, Css.px(24)),
                                                            /* [] */0
                                                          ]), /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Object reprocessing")]))), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                              I18N$BsConsole.showSkip(CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.46.1") ? I18N$BsConsole.get(undefined, "Reprocess previously ingested objects with the latest set of configured attributes. Retention policies may limit which objects are reprocessed.") : I18N$BsConsole.get(undefined, "Reprocess previously ingested objects with the latest set of configured attributes.")),
                                                              React.createElement("div", {
                                                                    className: Css.style(/* :: */[
                                                                          Css.padding2(/* `percent */[
                                                                                -119887163,
                                                                                3
                                                                              ], /* `percent */[
                                                                                -119887163,
                                                                                0
                                                                              ]),
                                                                          /* [] */0
                                                                        ])
                                                                  }, I18N$BsConsole.show(undefined, "Reprocess errors from: "), currentlyReprocessing ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("div", undefined, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "You are already reprocessing a set of errors.")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Try again after this action is completed."))), undefined, /* Right_End */-524285320, undefined, undefined, undefined, [timespanDropdown(undefined)])) : timespanDropdown(undefined)),
                                                              currentlyReprocessing ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.marginBottom(/* `percent */[
                                                                                      -119887163,
                                                                                      1
                                                                                    ]),
                                                                                /* [] */0
                                                                              ])
                                                                        }, I18N$BsConsole.show(undefined, "A reprocessing job is currently in progress:")), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                              undefined,
                                                                              undefined,
                                                                              (function ($$event) {
                                                                                  $$event.stopPropagation();
                                                                                  return Curry._1(displayProgress, undefined);
                                                                                }),
                                                                              undefined,
                                                                              undefined,
                                                                              /* Outlined */-28821822,
                                                                              /* Secondary */67972948,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              [I18N$BsConsole.show(undefined, "View progress")]
                                                                            ]))) : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                          undefined,
                                                                          undefined,
                                                                          (function ($$event) {
                                                                              $$event.stopPropagation();
                                                                              return Curry._1(confirmAndReprocess, undefined);
                                                                            }),
                                                                          undefined,
                                                                          undefined,
                                                                          /* Raised */-387916264,
                                                                          /* Primary */-791844958,
                                                                          undefined,
                                                                          undefined,
                                                                          currentlyReprocessing,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [I18N$BsConsole.show(undefined, "Reprocess objects")]
                                                                        ]))
                                                            ]))])));
                              })))]));
  };
  return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Reprocessing_Tasks$BsConsole.control(token, undefined), undefined, (function (remote, update) {
                              if (typeof remote === "number") {
                                remote === /* NotAsked */0;
                              } else {
                                if (remote.tag) {
                                  return I18N$BsConsole.showSkip(JSON.stringify(remote[0], null, 0));
                                }
                                var _reload = Js_dict.get(remote[0][0].reloads, "/" + (String(universe) + ("/" + (String(projectName) + ""))));
                                return ReasonReact.element(undefined, undefined, Reprocessing_Poll$BsConsole.make(update, 1000, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, {
                                                        backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
                                                      }, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [(_reload !== undefined ? Curry._1(setCurrentlyReprocessing, (function (param) {
                                                                              return true;
                                                                            })) : Curry._1(setCurrentlyReprocessing, (function (param) {
                                                                              return false;
                                                                            })), reprocessObjectsForm(undefined))]))]))));
                              }
                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, "primary", undefined, undefined, []));
                            })))]));
}

function make(token, projectName, config, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(Reprocessing, {
              token: token,
              config: config,
              projectName: projectName
            }, _children);
}

var Re = {
  make: make
};

var make$1 = Reprocessing;

exports.OptionsList = OptionsList;
exports.coronerSupportsReprocessByQuery = coronerSupportsReprocessByQuery;
exports.make = make$1;
exports.Re = Re;
/* defaultOptions Not a pure module */
