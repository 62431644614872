// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var metricsNamespace = "alerts/";

function toString(param) {
  switch (param) {
    case /* ExploreView */0 :
        return "explore-view";
    case /* SettingsListView */1 :
        return "settings-list-view";
    case /* SettingsDetailView */2 :
        return "detail-view";
    
  }
}

var From = {
  toString: toString
};

function toEvent(action) {
  if (typeof action !== "number") {
    return /* GenericEvent */Block.__(2, [{
                name: "alerts/alert_create",
                payload: Caml_option.some(Json_encode.object_(/* :: */[
                          /* tuple */[
                            "from",
                            toString(action[0])
                          ],
                          /* [] */0
                        ]))
              }]);
  }
  switch (action) {
    case /* TargetCreate */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "alerts/target_create",
                    payload: undefined
                  }]);
    case /* TargetUpdate */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "alerts/target_update",
                    payload: undefined
                  }]);
    case /* TargetDelete */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "alerts/target_delete",
                    payload: undefined
                  }]);
    case /* AlertUpdate */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "alerts/alert_update",
                    payload: undefined
                  }]);
    case /* AlertDelete */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: "alerts/alert_delete",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.From = From;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
