// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("FilterOffIcon-BsConsole");

function make(fillOpt, styleOpt, _children) {
  var fill = fillOpt !== undefined ? fillOpt : "currentColor";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({ });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("svg", {
                          style: style,
                          height: "19px",
                          width: "20px",
                          version: "1.1",
                          viewBox: "0 0 20 19"
                        }, React.createElement("g", {
                              id: "Page-1",
                              fill: "none",
                              fillRule: "evenodd",
                              stroke: "none",
                              strokeWidth: "1"
                            }, React.createElement("g", {
                                  id: "Group",
                                  transform: "translate(-1.000000, -2.000000)"
                                }, React.createElement("g", {
                                      id: "baseline-filter_list-24px"
                                    }, React.createElement("polygon", {
                                          id: "Shape",
                                          points: "0 0 24 0 24 24 0 24"
                                        })), React.createElement("polygon", {
                                      id: "Combined-Shape",
                                      fill: fill,
                                      points: "2.77 2.5 1.5 3.77 7.86 10.13 16.73 19 18.73 21 20 19.73"
                                    }), React.createElement("polygon", {
                                      id: "Rectangle-3",
                                      fill: fill,
                                      points: "10 16 13.7284088 16 14 16.2694855 14 18 10 18"
                                    }), React.createElement("polygon", {
                                      id: "Rectangle-3",
                                      fill: fill,
                                      points: "3 6 3.73083496 6 5.73699951 8 3 8"
                                    }), React.createElement("polygon", {
                                      id: "Rectangle-3",
                                      fill: fill,
                                      points: "6 11 8.73075867 11 10.7338715 13 6 13"
                                    }), React.createElement("polygon", {
                                      id: "Rectangle-2",
                                      fill: fill,
                                      points: "13.7717133 11 18 11 18 13 15.7690887 13"
                                    }), React.createElement("polygon", {
                                      id: "Rectangle",
                                      fill: fill,
                                      points: "8.77272444 6 21 6 21 8 10.7722909 8"
                                    }))));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
