// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Column$BsConsole = require("./utilities/Column.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var Renderer$BsConsole = require("./renders/Renderer.js");
var BinForDetailCard$BsConsole = require("./charts/BinForDetailCard.js");
var DistributionForDetailCard$BsConsole = require("./charts/DistributionForDetailCard.js");

var attrStyle = Css.style(/* :: */[
      Css.marginTop(Css.px(10)),
      /* :: */[
        Css.marginRight(Css.px(30)),
        /* :: */[
          Css.marginBottom(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var attrHeaderStyle = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.height(Css.px(12)),
            /* :: */[
              Css.marginBottom(Css.px(5)),
              /* [] */0
            ]
          ]),
      /* :: */[
        Styles$BsConsole.$$Text.bodyWithAccentColor,
        /* [] */0
      ]
    ]);

var attrContentStyle = Styles$BsConsole.$$Text.data;

var pointerCursor = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

var component = ReasonReact.statelessComponent("AggregationRender-BsConsoleAttrForDetail");

function make(label, includeAggTypeLabelOpt, includeAttrLabelOpt, attribute, maybeValue, handleChangeUrl, handleAddFilters, _children) {
  var includeAggTypeLabel = includeAggTypeLabelOpt !== undefined ? includeAggTypeLabelOpt : true;
  var includeAttrLabel = includeAttrLabelOpt !== undefined ? includeAttrLabelOpt : true;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* flexStart */662439529, undefined, undefined, attrStyle, [
                              React.createElement("div", {
                                    className: attrHeaderStyle
                                  }, includeAggTypeLabel ? (
                                      includeAttrLabel ? I18N$BsConsole.showSkip(label + attribute) : I18N$BsConsole.showSkip(label)
                                    ) : (
                                      includeAttrLabel ? I18N$BsConsole.dynamic(attribute) : null
                                    )),
                              React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          attrContentStyle,
                                          /* :: */[
                                            pointerCursor,
                                            /* [] */0
                                          ]
                                        ])
                                  }, ReasonReact.element(undefined, undefined, Renderer$BsConsole.make(maybeValue, attribute, handleAddFilters, handleChangeUrl, undefined, true, undefined, undefined, undefined, undefined, [])))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var AttrForDetail = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("AggregationRender-BsConsole__UniqueForDetail");

function make$1(includeAggTypeLabelOpt, includeAttrLabelOpt, attribute, value, _children) {
  var includeAggTypeLabel = includeAggTypeLabelOpt !== undefined ? includeAggTypeLabelOpt : true;
  var includeAttrLabel = includeAttrLabelOpt !== undefined ? includeAttrLabelOpt : true;
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* flexStart */662439529, undefined, undefined, attrStyle, [
                              React.createElement("div", {
                                    className: attrHeaderStyle
                                  }, includeAggTypeLabel ? (
                                      includeAttrLabel ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String_literal */Block.__(11, [
                                                      "Unique ",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* End_of_format */0
                                                        ])
                                                    ]),
                                                  "Unique %s"
                                                ]), attribute) : I18N$BsConsole.show(undefined, "Unique")
                                    ) : (
                                      includeAttrLabel ? I18N$BsConsole.dynamic(attribute) : null
                                    )),
                              React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          attrContentStyle,
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.cursor(/* default */465819841),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(String(value)))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var UniqueForDetail = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("AggregationRender-BsConsole__BinForDetail");

function make$2(maybeValue, containerWidth, attribute, handleAddFilters, handleChangeUrl, factor, aperture, token, name, group, handleTask, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              if (maybeValue !== undefined) {
                return ReasonReact.element(undefined, undefined, BinForDetailCard$BsConsole.make(attribute, maybeValue, containerWidth - 55 | 0, handleAddFilters, handleChangeUrl, factor, aperture, token, name, group, handleTask, []));
              } else {
                return I18N$BsConsole.show(undefined, "No-Bin");
              }
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var BinForDetail = {
  component: component$2,
  make: make$2
};

var idCount = {
  contents: 0
};

var component$3 = ReasonReact.statelessComponent("AggregationRender-BsConsole__DistributionForDetail");

function make$3(attribute, distribution, handleAddFilters, handleChangeUrl, containerWidth, factor, aperture, token, name, group, handleTask, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, DistributionForDetailCard$BsConsole.make(attribute, distribution, handleAddFilters, handleChangeUrl, containerWidth, (function (param) {
                                
                              }), (function (param) {
                                
                              }), factor, aperture, token, name, group, handleTask, []));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var DistributionForDetail = {
  idCount: idCount,
  component: component$3,
  make: make$3
};

exports.attrStyle = attrStyle;
exports.attrHeaderStyle = attrHeaderStyle;
exports.attrContentStyle = attrContentStyle;
exports.pointerCursor = pointerCursor;
exports.AttrForDetail = AttrForDetail;
exports.UniqueForDetail = UniqueForDetail;
exports.BinForDetail = BinForDetail;
exports.DistributionForDetail = DistributionForDetail;
/* attrStyle Not a pure module */
