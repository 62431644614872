// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function selection(t) {
  return Caml_option.nullable_to_opt(t.selection);
}

var $$Event = {
  selection: selection
};

exports.$$Event = $$Event;
/* No side effect */
