// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugTreeExpandIcon$BsConsole = require("./BugTreeExpandIcon.js");
var BugTreeIndentationGuide$BsConsole = require("./BugTreeIndentationGuide.js");

function BugTreeValueRow(Props) {
  var node = Props.node;
  var closeIndentation = Props.closeIndentation;
  var duration = Props.duration;
  var paneFocused = Props.paneFocused;
  var isSelected = Props.isSelected;
  var onClick = Props.onClick;
  var onToggleExpanded = Props.onToggleExpanded;
  var expanded = Props.expanded;
  var bg = Caml_obj.caml_equal(isSelected, true) ? (
      Caml_obj.caml_equal(paneFocused, true) ? Css.hex(Colors$BsConsole.tealExtraLight) : Css.hex(Colors$BsConsole.grey65)
    ) : Css.hex(Colors$BsConsole.white);
  var tmp;
  if (node.children.length === 0) {
    tmp = React.createElement("div", {
          className: Css.style(/* :: */[
                Css.width(Css.px(18)),
                /* :: */[
                  Css.height(Css.px(20)),
                  /* :: */[
                    Css.flexShrink(0),
                    /* [] */0
                  ]
                ]
              ])
        }, closeIndentation > 0 ? React.createElement("svg", {
                height: "20",
                width: "18",
                shapeRendering: "crispEdges"
              }, React.createElement("path", {
                    d: "M 0 10 L 14 10",
                    fill: "none",
                    stroke: BugTreeIndentationGuide$BsConsole.guideLineColor,
                    strokeWidth: "1"
                  })) : null);
  } else {
    var tmp$1 = {
      onClick: (function (param) {
          
        }),
      expanded: expanded
    };
    if (duration !== undefined) {
      tmp$1.duration = Caml_option.valFromOption(duration);
    }
    tmp = React.createElement(BugTreeExpandIcon$BsConsole.make, tmp$1);
  }
  var value = node.value;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.cursor(/* pointer */-786317123),
                    /* :: */[
                      Css.paddingLeft(Css.px(4)),
                      /* :: */[
                        Css.background(bg),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              onClick: (function (param) {
                  if (onClick !== undefined) {
                    Curry._1(onClick, undefined);
                  }
                  if (node.children.length !== 0) {
                    return Curry._1(onToggleExpanded, undefined);
                  }
                  
                }),
              children: null
            }, React.createElement(BugTreeIndentationGuide$BsConsole.make, {
                  depth: node.depth,
                  closeIndentation: closeIndentation
                }), tmp, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.height(Css.px(20)),
                        /* :: */[
                          Css.color(Css.hex(Belt_Option.getWithDefault(node.color, Colors$BsConsole.grey2))),
                          /* :: */[
                            Css.background(bg),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement("div", undefined, I18N$BsConsole.showSkip(node.key)), value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.padding2(Css.zero, Css.px(8)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.showSkip("=")), React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.whiteSpace(Css.nowrap),
                                  /* [] */0
                                ])
                          }, Caml_option.valFromOption(value))) : null), Belt_Option.getWithDefault(node.suffix, null));
}

var make = BugTreeValueRow;

exports.make = make;
/* Css Not a pure module */
