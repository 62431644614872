// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function actionStatFromJson(json) {
  return {
          matched: Json_decode.field("matched", Json_decode.$$int, json),
          mismatched: Json_decode.field("mismatched", Json_decode.$$int, json),
          conditionEvaluationFailed: Json_decode.field("condition_evaluation_failed", Json_decode.$$int, json)
        };
}

function fromJson(json) {
  return {
          executionFailed: Json_decode.optional(Json_decode.at(/* :: */[
                    "metrics",
                    /* :: */[
                      "execution_failed",
                      /* [] */0
                    ]
                  ], Json_decode.$$int), json),
          actions: Json_decode.optional(Json_decode.at(/* :: */[
                    "metrics",
                    /* :: */[
                      "actions",
                      /* [] */0
                    ]
                  ], (function (param) {
                      return Json_decode.array(actionStatFromJson, param);
                    })), json)
        };
}

function allStatsInProject(res, project) {
  return Belt_Array.reduce(res, [], (function (acc, curr) {
                if (curr.project !== project.pid) {
                  return acc;
                }
                var state = curr.__state;
                if (state !== undefined) {
                  return Belt_Array.concat(acc, [fromJson(Caml_option.valFromOption(state))]);
                } else {
                  return acc;
                }
              }));
}

function overallStats(allStats) {
  var results = Belt_Array.reduce(allStats, {
        executionFailed: undefined
      }, (function (acc, curr) {
          var match = curr.executionFailed;
          var match$1 = acc.executionFailed;
          var updatedExecutionFailed = match !== undefined ? (
              match$1 !== undefined ? match + match$1 | 0 : match
            ) : (
              match$1 !== undefined ? match$1 : undefined
            );
          return {
                  executionFailed: updatedExecutionFailed
                };
        }));
  var match = results.executionFailed;
  if (match !== undefined) {
    return results;
  }
  
}

function actionStats(allStats) {
  return Belt_Array.reduce(allStats, [], (function (acc, curr) {
                var c = curr.actions;
                if (c !== undefined) {
                  return Belt_Array.concat(acc, c);
                } else {
                  return acc;
                }
              }));
}

function getProjectStats(res, project) {
  var allStats = allStatsInProject(res, project);
  var overallStats$1 = overallStats(allStats);
  var actionStats$1 = actionStats(allStats);
  return /* tuple */[
          overallStats$1,
          actionStats$1
        ];
}

exports.actionStatFromJson = actionStatFromJson;
exports.fromJson = fromJson;
exports.allStatsInProject = allStatsInProject;
exports.overallStats = overallStats;
exports.actionStats = actionStats;
exports.getProjectStats = getProjectStats;
/* No side effect */
