// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SauceColors$BsConsole = require("./SauceColors.js");
var Configuration$BsConsole = require("./configuration.js");

var bannerContainer = Css.style(/* :: */[
      Css.unsafe("width", "calc(100% - 4px)"),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  400
                ]),
            /* :: */[
              Css.lineHeight(Css.px(24)),
              /* :: */[
                Css.backgroundColor(Css.hex(SauceColors$BsConsole.green)),
                /* :: */[
                  Css.padding2(Css.px(8), Css.px(16)),
                  /* :: */[
                    Css.marginBottom(Css.px(11)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blueDark)),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                700
              ]),
          /* :: */[
            Css.textDecoration(/* underline */131142924),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  bannerContainer: bannerContainer,
  link: link
};

function CreateFirstProjectBanner(Props) {
  var handleChangeUrl = Props.handleChangeUrl;
  var shell = Props.shell;
  if (typeof shell === "number") {
    return null;
  }
  if (shell.tag) {
    return null;
  }
  var config = shell[0];
  var match = Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjectsNew */Block.__(6, [config.user]));
  var match$1 = Configuration$BsConsole.numberOfNonDemoProjects(config.projects) === 0;
  if (match || !match$1) {
    return null;
  } else {
    return React.createElement("div", {
                className: bannerContainer
              }, I18N$BsConsole.show(undefined, "You are viewing a Demo project on Backtrace. "), React.createElement("a", {
                    className: link,
                    onClick: (function (param) {
                        return Curry._1(handleChangeUrl, /* SettingsProjectsNew */9);
                      })
                  }, I18N$BsConsole.show(undefined, "Create your own project.")));
  }
}

var component = ReasonReact.statelessComponent("CreateFirstProjectBanner");

function make(handleChangeUrl, shell, children) {
  return ReasonReactCompat.wrapReactForReasonReact(CreateFirstProjectBanner, {
              handleChangeUrl: handleChangeUrl,
              shell: shell
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = CreateFirstProjectBanner;

exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* bannerContainer Not a pure module */
