// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");

var label = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.fontSize(Css.px(10)),
              /* :: */[
                Css.left(Css.rem(0.5)),
                /* :: */[
                  Css.top(Css.px(4)),
                  /* :: */[
                    Css.zIndex(3),
                    /* :: */[
                      Css.color(Css.currentColor),
                      /* :: */[
                        Css.opacity(0.54),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "label",
        /* [] */0
      ]
    ]);

var select = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.paddingLeft(Css.rem(0.5)),
            /* :: */[
              Css.paddingTop(Css.rem(0.8)),
              /* :: */[
                Css.color(Css.currentColor),
                /* :: */[
                  Css.fontSize(Css.px(15)),
                  /* :: */[
                    Css.minWidth(Css.px(50)),
                    /* :: */[
                      Css.maxWidth(Css.px(150)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "select",
        /* [] */0
      ]
    ]);

var icon = Css.merge(/* :: */[
      "icon",
      /* :: */[
        Css.style(/* :: */[
              Css.color(Css.currentColor),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function root(variant) {
  if (variant >= 969114050) {
    return Css.style(/* :: */[
                Css.borderRadius(Css.px(3)),
                /* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                  /* :: */[
                    Css.color(Css.currentColor),
                    /* [] */0
                  ]
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.borderRadius(Css.px(3)),
                /* :: */[
                  Css.unsafe("backgroundColor", "inherit"),
                  /* :: */[
                    Css.color(Css.currentColor),
                    /* :: */[
                      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey7)),
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  }
}

var Style = {
  label: label,
  select: select,
  icon: icon,
  root: root
};

var component = RR$BsConsole.statelessComponent("BtSelect-BsConsole");

function make(onChange, value, classNameOpt, label$1, renderValue, open_, onClose, onOpen, rootClassNameOpt, variantOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var rootClassName = rootClassNameOpt !== undefined ? rootClassNameOpt : "";
  var variant = variantOpt !== undefined ? variantOpt : /* Filled */969114050;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", {
                          className: Css.merge(/* :: */[
                                Css.style(/* :: */[
                                      Css.position(/* relative */903134412),
                                      /* :: */[
                                        Css.height(Css.px(40)),
                                        /* [] */0
                                      ]
                                    ]),
                                /* :: */[
                                  className,
                                  /* [] */0
                                ]
                              ])
                        }, ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, label, undefined, [I18N$BsConsole.showSkip(label$1)])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                  open_,
                                  renderValue,
                                  onClose,
                                  onOpen,
                                  true,
                                  {
                                    select: select,
                                    icon: icon,
                                    root: Css.merge(/* :: */[
                                          root(variant),
                                          /* :: */[
                                            rootClassName,
                                            /* :: */[
                                              "root",
                                              /* [] */0
                                            ]
                                          ]
                                        ]),
                                    selectMenu: Css.style(/* :: */[
                                          Css.maxWidth(Css.px(200)),
                                          /* [] */0
                                        ])
                                  },
                                  value,
                                  onChange,
                                  true,
                                  /* Filled */969114050,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [children]
                                ])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Default = {
  component: component,
  make: make
};

exports.Style = Style;
exports.Default = Default;
exports.component = component;
exports.make = make;
/* label Not a pure module */
