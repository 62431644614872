// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../../../Colors.js");
var ColumnsJs = require("@vx/grid/build/grids/Columns.js");

function make(topOpt, leftOpt, scale, height, strokeOpt, strokeWidthOpt, strokeDasharray, className, numTicksOpt, lineStyle, offset, children) {
  var top = topOpt !== undefined ? topOpt : 0;
  var left = leftOpt !== undefined ? leftOpt : 0;
  var stroke = strokeOpt !== undefined ? strokeOpt : Colors$BsConsole.octothorpe(Colors$BsConsole.white);
  var strokeWidth = strokeWidthOpt !== undefined ? strokeWidthOpt : 0.5;
  var numTicks = numTicksOpt !== undefined ? numTicksOpt : 10;
  var tmp = {
    top: top,
    left: left,
    scale: scale,
    height: height,
    stroke: stroke,
    strokeWidth: strokeWidth,
    numTicks: numTicks
  };
  if (strokeDasharray !== undefined) {
    tmp.strokeDasharray = Caml_option.valFromOption(strokeDasharray);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (lineStyle !== undefined) {
    tmp.lineStyle = Caml_option.valFromOption(lineStyle);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  return ReasonReact.wrapJsForReason(ColumnsJs.default, tmp, children);
}

exports.make = make;
/* ReasonReact Not a pure module */
