// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

function parseCoronerdVersionString(versionString) {
  var hyphenIndex = versionString.indexOf("-");
  var versionString$prime = hyphenIndex !== -1 ? versionString.slice(0, hyphenIndex) : versionString;
  var match = versionString$prime.indexOf("release/coronerd/");
  var versionString$prime$prime = match !== 0 ? versionString$prime : versionString.slice(17, versionString$prime.length);
  var match$1 = versionString$prime$prime.split(".");
  var len = match$1.length;
  var tmp;
  if (len >= 6) {
    tmp = versionString$prime$prime;
  } else {
    switch (len) {
      case 0 :
      case 1 :
      case 2 :
          tmp = versionString$prime$prime;
          break;
      case 3 :
          var major = match$1[0];
          var minor = match$1[1];
          var patch = match$1[2];
          tmp = "" + (String(major) + ("." + (String(minor) + ("." + (String(patch) + "")))));
          break;
      case 4 :
          var major$1 = match$1[0];
          var minor$1 = match$1[1];
          var patch$1 = match$1[2];
          tmp = "" + (String(major$1) + ("." + (String(minor$1) + ("." + (String(patch$1) + "")))));
          break;
      case 5 :
          var major$2 = match$1[0];
          var minor$2 = match$1[1];
          var patch$2 = match$1[2];
          tmp = "" + (String(major$2) + ("." + (String(minor$2) + ("." + (String(patch$2) + "")))));
          break;
      
    }
  }
  return Semver.valid(tmp);
}

function isEnoughCoronerdVersion(requiredCoroner) {
  var coronerdVersion = window._BACKTRACE_CORONER_VERSION;
  if (coronerdVersion == null) {
    return false;
  }
  var validVersion = parseCoronerdVersionString(coronerdVersion);
  if (validVersion !== undefined) {
    return Semver.gte(validVersion, requiredCoroner);
  } else {
    return false;
  }
}

function snack(version, textOpt, cb, param) {
  var text = textOpt !== undefined ? textOpt : I18N$BsConsole.get(undefined, "Must upgrade your instance to make use of this feature");
  if (isEnoughCoronerdVersion(version)) {
    return Curry._1(cb, undefined);
  } else {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, text));
  }
}

var stabilityMonitoringMinimumVersion = "1.52.2";

var serverSideActionsMinimumVersion = "1.54.28";

var projectUsersListMinimumVersion = "1.61.20";

var projectDeduplicationSettingsMinimumVersion = "1.59.43";

var projectDeduplicationIncludeSourcesMinimumVersion = "1.61.56";

var projectDeduplicationIncludeMetadataMinimumVersion = "1.61.79";

var mergeUnmergeEndpointMinimumVersion = "1.61.8";

var stateMachineDisabledMinimumVersion = "1.62.26";

var stabilityMonitoring = isEnoughCoronerdVersion(stabilityMonitoringMinimumVersion);

var serverSideActions = isEnoughCoronerdVersion(serverSideActionsMinimumVersion);

var projectUsersList = isEnoughCoronerdVersion(projectUsersListMinimumVersion);

var projectDeduplicationSettings = isEnoughCoronerdVersion(projectDeduplicationSettingsMinimumVersion);

var projectDeduplicationIncludeSources = isEnoughCoronerdVersion(projectDeduplicationIncludeSourcesMinimumVersion);

var projectDeduplicationIncludeMetadata = isEnoughCoronerdVersion(projectDeduplicationIncludeMetadataMinimumVersion);

var mergeUnmergeEndpoint = isEnoughCoronerdVersion(mergeUnmergeEndpointMinimumVersion);

var isStateMachineDisabled = isEnoughCoronerdVersion(stateMachineDisabledMinimumVersion);

function CoronerdGate$FeatureUnavailable(Props) {
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: Css.style(/* :: */[
                    Css.padding(Css.px(25)),
                    /* [] */0
                  ]),
              color: Colors$BsConsole.grey0,
              children: I18N$BsConsole.show(undefined, "You must upgrade your instance to make use of this feature.")
            });
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(CoronerdGate$FeatureUnavailable, { }, children);
}

var Re = {
  make: make
};

var FeatureUnavailable = {
  make: CoronerdGate$FeatureUnavailable,
  Re: Re
};

var guestUserFrontendSettingsMinimumVersion = "1.62.29";

exports.parseCoronerdVersionString = parseCoronerdVersionString;
exports.isEnoughCoronerdVersion = isEnoughCoronerdVersion;
exports.snack = snack;
exports.stabilityMonitoringMinimumVersion = stabilityMonitoringMinimumVersion;
exports.serverSideActionsMinimumVersion = serverSideActionsMinimumVersion;
exports.projectUsersListMinimumVersion = projectUsersListMinimumVersion;
exports.projectDeduplicationSettingsMinimumVersion = projectDeduplicationSettingsMinimumVersion;
exports.projectDeduplicationIncludeSourcesMinimumVersion = projectDeduplicationIncludeSourcesMinimumVersion;
exports.projectDeduplicationIncludeMetadataMinimumVersion = projectDeduplicationIncludeMetadataMinimumVersion;
exports.mergeUnmergeEndpointMinimumVersion = mergeUnmergeEndpointMinimumVersion;
exports.stateMachineDisabledMinimumVersion = stateMachineDisabledMinimumVersion;
exports.guestUserFrontendSettingsMinimumVersion = guestUserFrontendSettingsMinimumVersion;
exports.stabilityMonitoring = stabilityMonitoring;
exports.serverSideActions = serverSideActions;
exports.projectUsersList = projectUsersList;
exports.projectDeduplicationSettings = projectDeduplicationSettings;
exports.projectDeduplicationIncludeSources = projectDeduplicationIncludeSources;
exports.projectDeduplicationIncludeMetadata = projectDeduplicationIncludeMetadata;
exports.mergeUnmergeEndpoint = mergeUnmergeEndpoint;
exports.isStateMachineDisabled = isStateMachineDisabled;
exports.FeatureUnavailable = FeatureUnavailable;
/* stabilityMonitoring Not a pure module */
