// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Colors$BsConsole = require("./Colors.js");
var Styles = require("@material-ui/core/styles");

function makeTheme(primary, secondary) {
  return Styles.createMuiTheme({
              palette: {
                primary: primary,
                secondary: secondary
              },
              overrides: {
                MuiButton: {
                  sizeSmall: {
                    fontSize: "12px"
                  }
                }
              }
            });
}

var main = makeTheme({
      dark: Colors$BsConsole.octothorpe(Colors$BsConsole.blueDark),
      light: Colors$BsConsole.octothorpe(Colors$BsConsole.blueLight),
      main: Colors$BsConsole.octothorpe(Colors$BsConsole.blue),
      contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
    }, {
      light: Colors$BsConsole.octothorpe(Colors$BsConsole.green),
      main: Colors$BsConsole.octothorpe(Colors$BsConsole.greenDark),
      dark: Colors$BsConsole.octothorpe(Colors$BsConsole.greenExtraDark),
      contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
    });

var placeThemeOnWindow = (function(theme) {
  window.__theme = theme;
  });

placeThemeOnWindow(main);

exports.makeTheme = makeTheme;
exports.main = main;
exports.placeThemeOnWindow = placeThemeOnWindow;
/* main Not a pure module */
