// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(title, name, getCellValueOpt, disableSortOpt, disableResizeOpt, disableDraggableOpt, minWidthOpt, maxWidthOpt, initialWidth, headerCellClassNameOpt, dataCellClassNameOpt, alignOpt, param) {
  var getCellValue = getCellValueOpt !== undefined ? getCellValueOpt : (function (_row) {
        return /* None */870530776;
      });
  var disableSort = disableSortOpt !== undefined ? disableSortOpt : false;
  var disableResize = disableResizeOpt !== undefined ? disableResizeOpt : false;
  var disableDraggable = disableDraggableOpt !== undefined ? disableDraggableOpt : false;
  var minWidth = minWidthOpt !== undefined ? minWidthOpt : 64;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : 2000;
  var headerCellClassName = headerCellClassNameOpt !== undefined ? headerCellClassNameOpt : "";
  var dataCellClassName = dataCellClassNameOpt !== undefined ? dataCellClassNameOpt : "";
  var align = alignOpt !== undefined ? alignOpt : /* Left */847852583;
  return {
          title: title,
          name: name,
          getCellValue: getCellValue,
          disableSort: disableSort,
          disableResize: disableResize,
          disableDraggable: disableDraggable,
          minWidth: minWidth,
          maxWidth: maxWidth,
          initialWidth: initialWidth,
          headerCellClassName: headerCellClassName,
          dataCellClassName: dataCellClassName,
          align: align
        };
}

function override(t, titleOpt, nameOpt, getCellValueOpt, disableSortOpt, disableResizeOpt, disableDraggableOpt, minWidthOpt, maxWidthOpt, initialWidthOpt, headerCellClassNameOpt, dataCellClassNameOpt, alignOpt, param) {
  var title = titleOpt !== undefined ? titleOpt : t.title;
  var name = nameOpt !== undefined ? nameOpt : t.name;
  var getCellValue = getCellValueOpt !== undefined ? getCellValueOpt : t.getCellValue;
  var disableSort = disableSortOpt !== undefined ? disableSortOpt : t.disableSort;
  var disableResize = disableResizeOpt !== undefined ? disableResizeOpt : t.disableResize;
  var disableDraggable = disableDraggableOpt !== undefined ? disableDraggableOpt : t.disableDraggable;
  var minWidth = minWidthOpt !== undefined ? minWidthOpt : t.minWidth;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : t.maxWidth;
  var initialWidth = initialWidthOpt !== undefined ? Caml_option.valFromOption(initialWidthOpt) : t.initialWidth;
  var headerCellClassName = headerCellClassNameOpt !== undefined ? headerCellClassNameOpt : t.headerCellClassName;
  var dataCellClassName = dataCellClassNameOpt !== undefined ? dataCellClassNameOpt : t.dataCellClassName;
  var align = alignOpt !== undefined ? alignOpt : t.align;
  return {
          title: title,
          name: name,
          getCellValue: getCellValue,
          disableSort: disableSort,
          disableResize: disableResize,
          disableDraggable: disableDraggable,
          minWidth: minWidth,
          maxWidth: maxWidth,
          initialWidth: initialWidth,
          headerCellClassName: headerCellClassName,
          dataCellClassName: dataCellClassName,
          align: align
        };
}

exports.make = make;
exports.override = override;
/* No side effect */
