// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("./components/Col2.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Image$BsConsole = require("./components/Image.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  logoContainer: logoContainer,
  logo: logo
};

function BacktraceErrorDialog(Props) {
  var title = Props.title;
  var description = Props.description;
  var buttonText = Props.buttonText;
  var onClick = Props.onClick;
  return React.createElement(Dialog.default, {
              open: true,
              children: React.createElement(Col2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.maxWidth(Css.px(500)),
                          /* :: */[
                            Css.padding(Css.px(50)),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* center */98248149,
                        className: logoContainer,
                        children: React.createElement(Image$BsConsole.make, {
                              className: logo,
                              src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg",
                              alt: "Sauce Labs - Backtrace Logo"
                            })
                      }), React.createElement("h1", {
                        className: Css.style(/* :: */[
                              Css.textAlign(/* center */98248149),
                              /* :: */[
                                Css.fontSize(Css.em(1.5)),
                                /* :: */[
                                  Css.fontWeight(/* `num */[
                                        5496390,
                                        500
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, title)), React.createElement("p", {
                        className: Css.style(/* :: */[
                              Css.textAlign(/* center */98248149),
                              /* :: */[
                                Css.paddingBottom(Css.px(8)),
                                /* [] */0
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, description)), React.createElement(Button.default, {
                        variant: "raised",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(onClick, undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, buttonText)
                      }))
            });
}

var make = BacktraceErrorDialog;

exports.Styles = Styles;
exports.make = make;
/* logoContainer Not a pure module */
