// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Column$BsConsole = require("./Column.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");

var component = ReasonReact.statelessComponent("CancelContinueDialog-BsConsole");

function make(onClose, onContinue, text, onContinueText, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                          ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                              undefined,
                                                              undefined,
                                                              Curry.__1(onClose),
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              "Cancel",
                                                              [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                            ]))])),
                                          ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                    React.createElement("div", {
                                                          className: Css.style(/* :: */[
                                                                Css.marginBottom(Css.px(10)),
                                                                /* [] */0
                                                              ])
                                                        }, text),
                                                    React.createElement("div", {
                                                          className: Css.style(/* :: */[
                                                                Css.width(Css.px(400)),
                                                                /* :: */[
                                                                  Css.display(/* flex */-1010954439),
                                                                  /* :: */[
                                                                    Css.justifyContent(/* center */98248149),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  Css.style(/* :: */[
                                                                        Css.marginRight(Css.px(8)),
                                                                        /* [] */0
                                                                      ]),
                                                                  Curry.__1(onClose),
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  /* Primary */-791844958,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                                ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  undefined,
                                                                  Curry.__1(onContinue),
                                                                  undefined,
                                                                  undefined,
                                                                  /* Raised */-387916264,
                                                                  /* Primary */-791844958,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [onContinueText]
                                                                ])))
                                                  ]))
                                        ]))]
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
