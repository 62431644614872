// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeFile(json) {
  return {
          archiveId: Json_decode.field("archive_id", Json_decode.string, json),
          convertTime: Json_decode.field("convert_time", Json_decode.string, json),
          debugFile: Json_decode.field("debug_file", Json_decode.string, json),
          debugId: Json_decode.field("debug_identifier", Json_decode.string, json),
          extractTime: Json_decode.field("extract_time", Json_decode.string, json),
          fileSize: Json_decode.field("file_size", Json_decode.string, json),
          uploadTime: Json_decode.field("upload_time", Json_decode.string, json)
        };
}

function decodeSymbols(json) {
  return {
          tag: Json_decode.field("tag", Json_decode.string, json),
          files: Json_decode.field("files", (function (param) {
                  return Json_decode.list(decodeFile, param);
                }), json)
        };
}

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("symbols", (function (param) {
                              return Json_decode.list(decodeSymbols, param);
                            }), json);
              }), json);
}

exports.decodeFile = decodeFile;
exports.decodeSymbols = decodeSymbols;
exports.decode = decode;
/* No side effect */
