// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var DataCard$BsConsole = require("../utilities/DataCard.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var MessageFeatureGate$BsConsole = require("../MessageFeatureGate.js");
var BillingFeatureFallback$BsConsole = require("../BillingFeatureFallback.js");

function SubUserLimit(Props) {
  Props.config;
  Props.token;
  var limits = Props.limits;
  var handleChangeUrl = Props.handleChangeUrl;
  Props.nemesis;
  var extension = Props.extension;
  var userLimitMaybe = ApiBillingLimits$BsConsole.getUserLimit(limits);
  var currentUserCount = ApiBillingLimits$BsConsole.getCurrentUserCount(undefined);
  var match = React.useState((function () {
          
        }));
  var setData = match[1];
  var data = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setMounted = match$1[1];
  var mounted = match$1[0];
  React.useEffect((function () {
          Curry._1(setMounted, (function (param) {
                  return true;
                }));
          
        }), ([]));
  React.useEffect((function () {
          if (mounted) {
            if (userLimitMaybe !== undefined) {
              Curry._1(setData, (function (param) {
                      return /* tuple */[
                              userLimitMaybe,
                              undefined
                            ];
                    }));
            } else {
              Curry._1(setData, (function (param) {
                      return /* tuple */[
                              undefined,
                              undefined
                            ];
                    }));
            }
          }
          return (function (param) {
                    return Curry._1(setMounted, (function (param) {
                                  return false;
                                }));
                  });
        }), /* tuple */[
        limits,
        mounted
      ]);
  if (data === undefined) {
    return null;
  }
  var userLimit = data[0];
  if (userLimit !== undefined) {
    return React.createElement(DataCard$BsConsole.make, {
                title: I18N$BsConsole.get(undefined, "Total seats filled"),
                numerator: currentUserCount,
                denominator: userLimit,
                unit: I18N$BsConsole.get(undefined, "seats"),
                formatter: /* NoDecimal */2,
                failure: data[1],
                extra: /* Link */Block.__(1, [
                    handleChangeUrl,
                    I18N$BsConsole.get(undefined, "View team details"),
                    /* SettingsUsers */6
                  ]),
                extension: React.createElement(MessageFeatureGate$BsConsole.make, {
                      showMessage: Gate$BsConsole.isBillingFeatureDisabled(/* TotalSeatsFilled */2),
                      message: React.createElement(BillingFeatureFallback$BsConsole.make, { }),
                      children: Belt_Option.getWithDefault(extension, null)
                    })
              });
  } else {
    return React.createElement(DataCard$BsConsole.UnlimitedCard.make, {
                title: I18N$BsConsole.get(undefined, "Total seats filled"),
                numerator: currentUserCount,
                unit: I18N$BsConsole.get(undefined, "seats"),
                formatter: /* NoDecimal */2,
                failure: data[1],
                extra: /* Link */Block.__(1, [
                    handleChangeUrl,
                    I18N$BsConsole.get(undefined, "View team details"),
                    /* SettingsUsers */6
                  ]),
                extension: Belt_Option.getWithDefault(extension, null)
              });
  }
}

var make = SubUserLimit;

exports.make = make;
/* react Not a pure module */
