// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BugDefectView$BsConsole = require("./BugDefectView.js");

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.width(Css.px(180)),
      /* :: */[
        Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
        /* :: */[
          Css.height(Css.px(25)),
          /* :: */[
            Css.fontSize(Css.px(12)),
            /* :: */[
              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey4)),
              /* :: */[
                Css.margin(Css.px(8)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var viewOptions = /* :: */[
  /* Variables */0,
  /* :: */[
    /* Defects */1,
    /* [] */0
  ]
];

function BugDefectVariablesToggle(Props) {
  var config = Props.config;
  var setView = Props.setView;
  var variablesComponent = Props.variablesComponent;
  var defects = Props.defects;
  var selection = Props.selection;
  var height = Props.height;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: inputStyle
                }, React.createElement("select", {
                      className: formStyle,
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          var tmp;
                          switch (value) {
                            case "defects" :
                                tmp = /* Defects */1;
                                break;
                            case "variables" :
                                tmp = /* Variables */0;
                                break;
                            default:
                              tmp = /* Variables */0;
                          }
                          return Curry._1(setView, tmp);
                        })
                    }, Belt_List.toArray(Belt_List.map(viewOptions, (function (option) {
                                if (option) {
                                  return React.createElement("option", {
                                              key: "defects",
                                              selected: selection === /* Defects */1,
                                              value: "defects"
                                            }, I18N$BsConsole.show(undefined, "Correlated defects"));
                                } else {
                                  return React.createElement("option", {
                                              key: "variables",
                                              selected: selection === /* Variables */0,
                                              value: "variables"
                                            }, I18N$BsConsole.show(undefined, "Variables"));
                                }
                              }))))), selection ? React.createElement(BugDefectView$BsConsole.make, {
                    config: config,
                    defects: defects,
                    height: height
                  }) : variablesComponent);
}

var make = BugDefectVariablesToggle;

exports.inputStyle = inputStyle;
exports.formStyle = formStyle;
exports.viewOptions = viewOptions;
exports.make = make;
/* inputStyle Not a pure module */
