// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var Column$BsConsole = require("../../utilities/Column.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Dedupe_API$BsConsole = require("./Dedupe_API.js");

var ParseError = Caml_exceptions.create("Dedupe_RuleTest-BsConsole.ParseError");

var formStyle = Css.style(/* :: */[
      Css.important(Css.marginBottom(Css.px(15))),
      /* :: */[
        Css.width(Css.px(200)),
        /* :: */[
          Css.height(Css.px(40)),
          /* :: */[
            Css.opacity(0.8),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
              /* :: */[
                Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
                /* :: */[
                  Css.height(Css.px(35)),
                  /* :: */[
                    Css.padding(Css.px(10)),
                    /* :: */[
                      Css.fontSize(Css.px(14)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var labelStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.lineHeight(Css.px(16)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.marginBottom(Css.px(8)),
            /* [] */0
          ]
        ]
      ]
    ]);

function getErrorMessage(err) {
  if (err[0] === Task2$BsConsole.UncaughtPromiseError) {
    return Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err[1][0]), (function (prim) {
                      return prim.message;
                    })), "Uncaught promise exception");
  } else if (err[0] === ParseError) {
    return Belt_Option.getWithDefault(err[1].message, "Parse error");
  } else if (err[0] === Task2$BsConsole.JsonDecodeError) {
    return Belt_Option.getWithDefault(err[1], "Json decode error");
  } else if (err[0] === Task2$BsConsole.CoronerError) {
    return err[1].message;
  } else {
    return "Unknown error";
  }
}

var component = ReasonReact.reducerComponent("Dedupe_RuleTest-BsConsole");

function make(universe, token, project, objectId, rulesetName, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var resultRemote = match.resultRemote;
              var objectIdValue = match.objectIdValue;
              var tmp;
              tmp = typeof resultRemote === "number" ? (
                  resultRemote === /* NotAsked */0 ? null : React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.marginLeft(Css.px(40)),
                                /* [] */0
                              ])
                        }, I18N$BsConsole.show(undefined, "Loading..."))
                ) : (
                  resultRemote.tag ? React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.marginLeft(Css.px(40)),
                                /* [] */0
                              ])
                        }, I18N$BsConsole.show(undefined, "Error testing this ruleset."), React.createElement("pre", undefined, I18N$BsConsole.showSkip(resultRemote[0]))) : ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("pre", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(40)),
                                          /* :: */[
                                            Css.width(Css.px(600)),
                                            /* :: */[
                                              Css.overflowX(/* scroll */-949692403),
                                              /* [] */0
                                            ]
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(JSON.stringify(resultRemote[0], null, 2)))]))
                );
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.marginLeft(Css.px(40)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(5)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]), [
                                  React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.marginBottom(Css.px(20)),
                                              /* [] */0
                                            ])
                                      }, I18N$BsConsole.show(undefined, "Ruleset testing")),
                                  React.createElement("label", {
                                        className: labelStyle,
                                        htmlFor: "test-ruleset-id"
                                      }, I18N$BsConsole.show(undefined, "Enter ID of error to test with this ruleset")),
                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* baseline */287825029, undefined, undefined, undefined, [
                                            React.createElement("div", undefined, React.createElement("input", {
                                                      className: Css.merge(/* :: */[
                                                            formStyle,
                                                            /* :: */[
                                                              Css.style(/* :: */[
                                                                    Css.marginRight(Css.px(10)),
                                                                    /* [] */0
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ]),
                                                      id: "test-ruleset-id",
                                                      placeholder: "",
                                                      value: objectIdValue,
                                                      onChange: (function ($$event) {
                                                          var value = $$event.target.value;
                                                          return Curry._1(send, /* UpdateObjectIdValue */Block.__(4, [value]));
                                                        })
                                                    })),
                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                      undefined,
                                                      undefined,
                                                      (function (_event) {
                                                          Curry._1(handleChangeUrl, /* ProjectSettingsDedupeConfig */Block.__(49, [
                                                                  project,
                                                                  objectIdValue
                                                                ]));
                                                          return Curry._1(send, /* FetchObjectData */Block.__(0, [objectIdValue]));
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      /* Primary */-791844958,
                                                      undefined,
                                                      undefined,
                                                      objectIdValue.trim() === "",
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [I18N$BsConsole.show(undefined, "Test")]
                                                    ]))
                                          ]))
                                ])), tmp);
            }),
          initialState: (function (param) {
              return {
                      objectIdValue: objectId !== undefined ? objectId : "",
                      error: undefined,
                      resultRemote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* FetchObjectData */0 :
                    var objectId = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                objectIdValue: state.objectIdValue,
                                error: state.error,
                                resultRemote: /* Loading */1
                              },
                              (function (self) {
                                  var arg = Dedupe_API$BsConsole.getObjectJson(project, token, objectId, undefined);
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (resp) {
                                                if (resp.tag) {
                                                  var msg = getErrorMessage(resp[0]);
                                                  return Curry._1(self.send, /* CallstackTestFailure */Block.__(3, [msg]));
                                                }
                                                var json = resp[0].json;
                                                var language = Json_decode.optional((function (param) {
                                                        return Json_decode.field("lang", Json_decode.string, param);
                                                      }), json);
                                                return Curry._1(self.send, /* FetchCallstackTest */Block.__(1, [
                                                              json,
                                                              language
                                                            ]));
                                              }));
                                })
                            ]);
                case /* FetchCallstackTest */1 :
                    var language = action[1];
                    var objectJson = action[0];
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  var arg = Dedupe_API$BsConsole.testCallstack(universe, project, token, objectJson, rulesetName, language, undefined);
                                  Curry._2((function (param) {
                                            return (function (param$1, param$2) {
                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                              });
                                          })(token), undefined, (function (resp) {
                                          if (!resp.tag) {
                                            return Curry._1(self.send, /* CallstackTestSuccess */Block.__(2, [resp[0]]));
                                          }
                                          var msg = getErrorMessage(resp[0]);
                                          return Curry._1(self.send, /* CallstackTestFailure */Block.__(3, [msg]));
                                        }));
                                  
                                })]);
                case /* CallstackTestSuccess */2 :
                    return /* Update */Block.__(0, [{
                                objectIdValue: state.objectIdValue,
                                error: state.error,
                                resultRemote: /* Success */Block.__(0, [action[0]])
                              }]);
                case /* CallstackTestFailure */3 :
                    return /* Update */Block.__(0, [{
                                objectIdValue: state.objectIdValue,
                                error: state.error,
                                resultRemote: /* Failure */Block.__(1, [action[0]])
                              }]);
                case /* UpdateObjectIdValue */4 :
                    var idVal = Belt_Option.getWithDefault(action[0], "");
                    return /* Update */Block.__(0, [{
                                objectIdValue: idVal,
                                error: state.error,
                                resultRemote: state.resultRemote
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.ParseError = ParseError;
exports.formStyle = formStyle;
exports.labelStyle = labelStyle;
exports.getErrorMessage = getErrorMessage;
exports.component = component;
exports.make = make;
/* formStyle Not a pure module */
