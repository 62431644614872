// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var WorkflowUtil$BsConsole = require("./WorkflowUtil.js");
var WorkflowVersion$BsConsole = require("./workflow/WorkflowVersion.js");
var ApiWfIntegrations$BsConsole = require("./workflow/workflows-api/ApiWfIntegrations.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

var ParseError = Caml_exceptions.create("WatcherNewTask-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("WatcherNewTask-BsConsole.ServiceError");

function fromJson(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var $$Error = {
  fromJson: fromJson
};

function fetchAllWatchers(config, projectName) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* Bpg */0, config);
  var bpgType = "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table;
  var cacheKey = "bpg-" + bpgType;
  var actions = [Json_encode.object_(/* :: */[
          /* tuple */[
            "type",
            bpgType
          ],
          /* :: */[
            /* tuple */[
              "action",
              "get"
            ],
            /* [] */0
          ]
        ])];
  if (maybePath !== undefined) {
    return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                            /* tuple */[
                              "actions",
                              actions
                            ],
                            /* [] */0
                          ])]), [/* tuple */[
                      "X-Coroner-Project",
                      projectName
                    ]], (function (param) {
                      return Belt_Result.map(BpgTask$BsConsole.decodeHead(param.json), (function (param) {
                                    return Json_decode.array(BacktraceParserGenerator$BsConsole.WatcherNew.of_json, param);
                                  }));
                    }), cacheKey, undefined));
  }
  
}

var bpgType = "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table;

var cacheKey = "bpg-" + bpgType;

function baseTask(cacheKey, decodeResp, actions, config, projectName, param) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* Bpg */0, config);
  if (maybePath !== undefined) {
    return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                            /* tuple */[
                              "actions",
                              actions
                            ],
                            /* [] */0
                          ])]), [/* tuple */[
                      "X-Coroner-Project",
                      projectName
                    ]], decodeResp, cacheKey, undefined));
  }
  
}

function create(entity, config, projectName) {
  return baseTask(undefined, (function (param) {
                return Belt_Result.map(Belt_Result.map(BpgTask$BsConsole.decodeHead(param.json), (function (json) {
                                  Task2$BsConsole.invalidateCache(cacheKey);
                                  return json;
                                })), BacktraceParserGenerator$BsConsole.WatcherNew.of_json);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "create"
                      ],
                      /* :: */[
                        /* tuple */[
                          "object",
                          BacktraceParserGenerator$BsConsole.WatcherNew.to_json(entity)
                        ],
                        /* [] */0
                      ]
                    ]
                  ])], config, projectName, undefined);
}

function update(entity, fields, config, projectName) {
  return baseTask(undefined, (function (param) {
                var e = BpgTask$BsConsole.decodeHead(param.json);
                if (e.tag) {
                  return /* Error */Block.__(1, [e[0]]);
                }
                Task2$BsConsole.invalidateCache(cacheKey);
                var entityObj = BacktraceParserGenerator$BsConsole.WatcherNew.to_json(entity);
                var fieldsObj = Js_dict.fromArray(fields);
                return /* Ok */Block.__(0, [BacktraceParserGenerator$BsConsole.WatcherNew.of_json(Object.assign(Object.assign({ }, entityObj), fieldsObj))]);
              }), [Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      bpgType
                    ],
                    /* :: */[
                      /* tuple */[
                        "action",
                        "modify"
                      ],
                      /* :: */[
                        /* tuple */[
                          "key",
                          Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, BacktraceParserGenerator$BsConsole.WatcherNew.to_key(entity))
                        ],
                        /* :: */[
                          /* tuple */[
                            "fields",
                            Json_encode.object_(Belt_List.fromArray(fields))
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ])], config, projectName, undefined);
}

function $$delete(projectName, pluginId, integrationId, config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/projects/" + (projectName + ("/integrations/" + String(integrationId))))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Delete */1, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = ApiWfIntegrations$BsConsole.IntegrationSuccess.fromJson(json);
                      Task2$BsConsole.invalidateCache(cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WatcherNewTask/DeleteIntegration.deleteIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.$$Error = $$Error;
exports.fetchAllWatchers = fetchAllWatchers;
exports.bpgType = bpgType;
exports.cacheKey = cacheKey;
exports.baseTask = baseTask;
exports.create = create;
exports.update = update;
exports.$$delete = $$delete;
/* Task2-BsConsole Not a pure module */
