// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Scanf = require("bs-platform/lib/js/scanf.js");
var Printf = require("bs-platform/lib/js/printf.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$BsConsole = require("./util.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var I18N_debug$BsConsole = require("./I18N_debug.js");
var SessionStorage$BsConsole = require("./SessionStorage.js");
var I18N_language_code$BsConsole = require("./I18N_language_code.js");
var I18N_language_file$BsConsole = require("./I18N_language_file.js");

function getCurrentLanguage(param) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(SessionStorage$BsConsole.get("I18N.Language", "current.language.code"), I18N_language_code$BsConsole.fromISO639), /* English */0);
}

function getCurrentLanguageString(param) {
  return I18N_language_code$BsConsole.toString(getCurrentLanguage(undefined));
}

var store = {
  contents: { }
};

function storeAndForward(key, fn) {
  var reply = Curry._1(fn, undefined);
  store.contents[key] = reply;
  return reply;
}

function memoize(key, fn) {
  var result = Js_dict.get(store.contents, key);
  if (result !== undefined) {
    return result;
  } else {
    return storeAndForward(key, fn);
  }
}

var Cache = {
  store: store,
  storeAndForward: storeAndForward,
  memoize: memoize
};

function __send(kind, context, token) {
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "I18N_real.Report", Backtrace$BsConsole.Attributes.addString("i18n.token", token, Backtrace$BsConsole.Attributes.addString("i18n.context", context, Backtrace$BsConsole.Attributes.addString("i18n.language", I18N_language_code$BsConsole.toString(getCurrentLanguage(undefined)), Backtrace$BsConsole.Attributes.addString("i18n.failure", "I18N_real.get", Backtrace$BsConsole.Attributes.make(undefined))))));
  return Backtrace$BsConsole.Client.send(__x, /* `string */[
              288368849,
              "I18N MISSING " + (String(kind) + ("::" + (String(context) + ("::" + (String(token) + "")))))
            ]);
}

var store$1 = {
  contents: { }
};

function markReported(key) {
  store$1.contents[key] = true;
  return key;
}

function alreadyReported(key) {
  return Belt_Option.isSome(Js_dict.get(store$1.contents, key));
}

function send(isEnabled, context, option) {
  var match = alreadyReported(context);
  if (isEnabled && !(match || option !== undefined)) {
    return __send("CONTEXT", markReported(context), "");
  }
  
}

var Context = {
  store: store$1,
  markReported: markReported,
  alreadyReported: alreadyReported,
  send: send
};

function send$1(isEnabled, context, token, option) {
  var match = alreadyReported(context);
  if (isEnabled && !(match || option !== undefined)) {
    return __send("TOKEN", context, token);
  }
  
}

var Token = {
  send: send$1
};

var Report = {
  __send: __send,
  Context: Context,
  Token: Token
};

function dynamic(string) {
  return string;
}

function dynamicGet(string) {
  return string;
}

function empty(param) {
  return "";
}

function __get(contextOpt, token) {
  var context = contextOpt !== undefined ? contextOpt : "common";
  var key = context + ("::" + token);
  var retrieve = function (param) {
    var __x = I18N_language_file$BsConsole.fromCache(getCurrentLanguage(undefined));
    var __x$1 = Util$BsConsole.tap((function (param) {
            return send(false, context, param);
          }), Belt_Option.flatMap(__x, (function (file) {
                return I18N_language_file$BsConsole.getMessage(file, context, token);
              })));
    return Belt_Option.getWithDefault(Util$BsConsole.tap((function (param) {
                      return send$1(false, context, token, param);
                    }), Belt_Option.flatMap(__x$1, (function (message) {
                          return I18N_language_file$BsConsole.Message.getString(message);
                        }))), token);
  };
  return memoize(key, retrieve);
}

function show(context, token) {
  return I18N_debug$BsConsole.display(context, token, __get(context, token));
}

function showEmpty(param) {
  return "";
}

function showSkip(string) {
  return string;
}

function skip(string) {
  return string;
}

function mapOrEmpty(fn, maybe) {
  if (maybe !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(maybe));
  } else {
    return "";
  }
}

function showOrEmpty(context, maybe) {
  return mapOrEmpty((function (param) {
                return show(context, param);
              }), maybe);
}

function dynamicOrEmpty(maybe) {
  return mapOrEmpty(dynamic, maybe);
}

var $$Option = {
  mapOrEmpty: mapOrEmpty,
  showOrEmpty: showOrEmpty,
  dynamicOrEmpty: dynamicOrEmpty
};

function load(context, token) {
  return Scanf.format_from_string(__get(context, Pervasives.string_of_format(token)), token);
}

function get(contextOpt, token) {
  var context = contextOpt !== undefined ? contextOpt : "common";
  var __x = __get(context, token);
  return I18N_debug$BsConsole.wrap(context, token, __x);
}

function getf(context, format) {
  var printer = function (param) {
    return I18N_debug$BsConsole.wrapf(context, format, param);
  };
  return Printf.ksprintf(printer, load(context, format));
}

function showf(context, format) {
  var printer = function (param) {
    return I18N_debug$BsConsole.displayf(context, format, param);
  };
  return Printf.ksprintf(printer, load(context, format));
}

function showSkipf(format) {
  var printer = function (param) {
    return I18N_debug$BsConsole.displayf(undefined, format, param);
  };
  return Printf.ksprintf(printer, format);
}

var Debug;

var skipf = Printf.sprintf;

exports.Debug = Debug;
exports.getCurrentLanguage = getCurrentLanguage;
exports.getCurrentLanguageString = getCurrentLanguageString;
exports.Cache = Cache;
exports.Report = Report;
exports.dynamic = dynamic;
exports.dynamicGet = dynamicGet;
exports.empty = empty;
exports.__get = __get;
exports.show = show;
exports.showEmpty = showEmpty;
exports.showSkip = showSkip;
exports.skip = skip;
exports.$$Option = $$Option;
exports.load = load;
exports.get = get;
exports.getf = getf;
exports.skipf = skipf;
exports.showf = showf;
exports.showSkipf = showSkipf;
/* Scanf Not a pure module */
