// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Colors$BsConsole = require("./Colors.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtFilterSelect$BsConsole = require("./BtFilterSelect.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

var label = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* :: */[
          Css.marginLeft(Css.rem(0.25)),
          /* [] */0
        ]
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.justifyContent(/* spaceBetween */516682146),
      /* :: */[
        Css.selector(".value", /* :: */[
              Css.marginRight(Css.rem(1)),
              /* [] */0
            ]),
        /* :: */[
          Css.selector(".count", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey4)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

function tag(value) {
  return Css.style(/* :: */[
              Css.fontSize(Css.px(13)),
              /* :: */[
                Css.padding2(/* zero */-789508312, Css.px(4)),
                /* :: */[
                  Css.borderRadius(Css.px(2)),
                  /* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.getTagBackgroundColor(value))),
                    /* :: */[
                      Css.fontFamily("Inconsolata"),
                      /* :: */[
                        Css.lineHeight(Css.px(19)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var Style = {
  label: label,
  select: select,
  iconContainer: iconContainer,
  menuItem: menuItem,
  loading: loading,
  tag: tag
};

function getQueryFold(attribute) {
  var __x = Crdb$BsConsole.Fold.empty;
  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  attribute,
                  /* Distribution */Block.__(1, [10])
                ]), __x);
}

function removeAttributeFilters(aperture, attribute) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== attribute;
        }), __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function addFilter(aperture, filter) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, filter, __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function makeQuery(aperture) {
  var queryAperture = removeAttributeFilters(aperture, "fingerprint;issues;tags");
  var __x = Crdb$BsConsole.Fold.empty;
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "fingerprint",
            /* Unique */4
          ]), __x);
  return /* Aggregate */Block.__(0, [
            queryAperture,
            /* Custom */["fingerprint;issues;tags"],
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function TriageTagSelect$Tag(Props) {
  var value = Props.value;
  return React.createElement("div", {
              className: tag(value)
            }, I18N$BsConsole.show(undefined, value));
}

var Tag = {
  make: TriageTagSelect$Tag
};

function TriageTagSelect(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var handleSetAperture = Props.handleSetAperture;
  var className = Props.className;
  var view = Props.view;
  var query = React.useMemo((function () {
          return makeQuery(aperture);
        }), [aperture]);
  var match = React.useState((function () {
          return [/* tuple */[
                    "All",
                    0
                  ]];
        }));
  var setOptions = match[1];
  var match$1 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "fingerprint;issues;state-type-select");
  var dataRemote = match$1[0];
  React.useEffect((function () {
          if (typeof dataRemote !== "number" && !dataRemote.tag) {
            var match = dataRemote[0][0];
            var columns = match[0];
            var groups = Belt_List.toArray(Belt_List.map(match[1], (function (row) {
                        var tags = row.factor.value;
                        var count = Belt_Option.getWithDefault(Belt_Option.map(Group$BsConsole.unwrapUniqueCount(Group$BsConsole.getAggregation(columns, row, "fingerprint", /* Unique */0)), (function (prim) {
                                    return prim | 0;
                                  })), 0);
                        return /* tuple */[
                                tags,
                                count
                              ];
                      })));
            var tags = Js_dict.entries(Belt_Array.reduce(Belt_Array.keep(groups, (function (param) {
                            var name = param[0];
                            if (name !== "*") {
                              return name.trim() !== "";
                            } else {
                              return false;
                            }
                          })), { }, (function (acc, param) {
                        var count = param[1];
                        var tagsArray = Belt_Array.keep(param[0].split(" "), (function (tag) {
                                return tag !== "";
                              }));
                        Belt_Array.forEach(tagsArray, (function (tag) {
                                var currentCount = Js_dict.get(acc, tag);
                                if (currentCount !== undefined) {
                                  acc[tag] = currentCount + count | 0;
                                  return ;
                                } else {
                                  acc[tag] = count;
                                  return ;
                                }
                              }));
                        return acc;
                      })));
            var totalCount = Belt_Option.getWithDefault(match[2].instances, 0);
            Curry._1(setOptions, (function (param) {
                    return Belt_Array.concat([/* tuple */[
                                  "All",
                                  totalCount
                                ]], tags);
                  }));
          }
          
        }), [dataRemote]);
  var tmp = {
    attribute: "fingerprint;issues;tags",
    label: "Tag",
    name: "fingerprint;issues;tags-select",
    options: match[0],
    renderOption: (function (value) {
        if (value === "All") {
          return I18N$BsConsole.show(undefined, value);
        } else {
          return React.createElement(TriageTagSelect$Tag, {
                      value: value
                    });
        }
      }),
    renderValue: (function (value) {
        if (value === "All") {
          return I18N$BsConsole.show(undefined, value);
        } else {
          return React.createElement(TriageTagSelect$Tag, {
                      value: value
                    });
        }
      }),
    aperture: aperture,
    handleSetAperture: handleSetAperture,
    loading: dataRemote === /* Loading */1,
    createFilter: (function (value) {
        return Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    "fingerprint;issues;tags",
                    /* Contains */Block.__(2, [/* `String */[
                          -976970511,
                          value
                        ]])
                  ]);
      }),
    icon: React.createElement(MuiIcons$BsConsole.Tag.make, {
          color: "inherit",
          fontSize: "inherit"
        }),
    view: view
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(BtFilterSelect$BsConsole.make, tmp);
}

var make = TriageTagSelect;

exports.Style = Style;
exports.getQueryFold = getQueryFold;
exports.removeAttributeFilters = removeAttributeFilters;
exports.addFilter = addFilter;
exports.makeQuery = makeQuery;
exports.Tag = Tag;
exports.make = make;
/* label Not a pure module */
