// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var L10N_date_time_clock$BsConsole = require("../L10N_date_time_clock.js");

function BugHeaderErrorDates(Props) {
  var time = Props.time;
  var recieved = Props.recieved;
  var timeReceived;
  try {
    timeReceived = Belt_Option.map(Belt_Option.flatMap(recieved, (function (timeReceivedJson) {
                return Json_decode.optional(Json_decode.$$float, timeReceivedJson);
              })), (function (timeReceivedString) {
            return new Date(timeReceivedString * 1000);
          }));
  }
  catch (exn){
    timeReceived = undefined;
  }
  var tmp;
  if (timeReceived !== undefined) {
    var timeReceived$1 = Caml_option.valFromOption(timeReceived);
    tmp = React.createElement("div", {
          className: Css.style(/* :: */[
                Css.paddingLeft(Css.px(24)),
                /* :: */[
                  Css.fontSize(Css.px(12)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                    /* [] */0
                  ]
                ]
              ])
        }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Received on ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* Char_literal */Block.__(12, [
                              /* " " */32,
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ]),
                  "Received on %s %s"
                ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, timeReceived$1), L10N_date_time_clock$BsConsole.Format.getGMTOffset(timeReceived$1)));
  } else {
    tmp = null;
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.padding2(Css.px(12), Css.px(0)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.px(12)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey4)),
                          /* [] */0
                        ]
                      ])
                }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "Created on ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* Char_literal */Block.__(12, [
                                      /* " " */32,
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ])
                                ])
                            ]),
                          "Created on %s %s"
                        ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, time), L10N_date_time_clock$BsConsole.Format.getGMTOffset(time))), tmp);
}

var make = BugHeaderErrorDates;

exports.make = make;
/* Css Not a pure module */
