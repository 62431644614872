// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.reducerComponent("Table-BsConsole__Paginataion");

function make(rows, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var aperture = function (arr) {
                return $$Array.sub(arr, Caml_int32.imul(self.state.page, self.state.rowsPerPage) > List.length(rows) ? List.length(rows) - self.state.rowsPerPage | 0 : Caml_int32.imul(self.state.page, self.state.rowsPerPage), (self.state.rowsPerPage + Caml_int32.imul(self.state.page, self.state.rowsPerPage) | 0) > List.length(rows) ? Math.max(0, List.length(rows) - Caml_int32.imul(self.state.page, self.state.rowsPerPage) | 0) : self.state.rowsPerPage);
              };
              var onChangePage = function (param, page) {
                return Curry._1(self.send, /* ChangePage */Block.__(0, [page]));
              };
              var onChangeRowsPerPage = function ($$event) {
                return Curry._1(self.send, /* ChangeRowsPerPage */Block.__(1, [$$event.target.value]));
              };
              var rowsPerPage = self.state.rowsPerPage;
              var page = self.state.page;
              return Curry._5(children, aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage);
            }),
          initialState: (function (param) {
              return {
                      page: 0,
                      rowsPerPage: 25
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            page: state.page,
                            rowsPerPage: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            page: action[0],
                            rowsPerPage: state.rowsPerPage
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Pagination = {
  component: component,
  make: make
};

exports.Pagination = Pagination;
/* component Not a pure module */
