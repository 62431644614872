// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var WfRouter$BsConsole = require("./WfRouter.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

function toEvent(action) {
  switch (action.tag | 0) {
    case /* IntegrationTested */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/integration_tested",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "watcher",
                                  action[1].userFriendlyName
                                ],
                                /* [] */0
                              ]
                            ]))
                  }]);
    case /* ManualActionOpened */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/manual_action_opened",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "watcher",
                                  action[1].userFriendlyName
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "action",
                                    action[2]
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]))
                  }]);
    case /* ManualActionFormSubmitted */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/manual_action_submitted",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "watcher",
                                  action[1].userFriendlyName
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "action",
                                    action[2]
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]))
                  }]);
    case /* ManualActionFormCancelled */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/manual_action_form_cancelled",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "watcher",
                                  action[1].userFriendlyName
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "action",
                                    action[2]
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]))
                  }]);
    case /* ManualActionFormSubmitError */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/manual_action_submit_error",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "watcher",
                                  action[1].userFriendlyName
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "action",
                                    action[2]
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]))
                  }]);
    case /* WorkflowConfigureFormStepInvalid */5 :
        return /* GenericEvent */Block.__(2, [{
                    name: WfRouter$BsConsole.namespace + "/watcher_configure_submit_step_invalid",
                    payload: Caml_option.some(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "integration",
                                action[0].description.title
                              ],
                              /* :: */[
                                /* tuple */[
                                  "step",
                                  action[1]
                                ],
                                /* [] */0
                              ]
                            ]))
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.toEvent = toEvent;
exports.send = send;
/* WfRouter-BsConsole Not a pure module */
