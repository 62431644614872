// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var $$String = require("bs-platform/lib/js/string.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Base$BsConsole = require("./bs-crdb-response/src/Base.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Renderer_Ipv4$BsConsole = require("./renders/Renderer_Ipv4.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var Renderer_MemoryAddress$BsConsole = require("./renders/Renderer_MemoryAddress.js");

function formatDateToAgoString(date) {
  return DateFns.distanceInWordsToNow(undefined, true, date).replace("about", "").replace("less than a minute ago", "seconds ago");
}

function formatToString(maybeFormat) {
  if (maybeFormat === undefined) {
    return "_";
  }
  switch (maybeFormat.tag | 0) {
    case /* Raw */0 :
        var value = maybeFormat[0];
        var variant = value[0];
        if (variant >= 737456202) {
          if (variant >= 758940238) {
            return L10N_date_time$BsConsole.Format.numericDay(undefined, value[1]);
          } else {
            return Pervasives.string_of_bool(value[1]);
          }
        } else if (variant >= 365180284) {
          return Base$BsConsole.floatToString(value[1]);
        } else {
          return value[1];
        }
    case /* None_ */1 :
        return Base$BsConsole.rawToString(maybeFormat[0]);
    case /* UnixTimestamp */2 :
    case /* GpsTimestamp */3 :
        return L10N_date_time$BsConsole.Format.numericDay(undefined, maybeFormat[0]);
    case /* Bytes */5 :
        return maybeFormat[0] + "B";
    case /* KiloBytes */6 :
        return maybeFormat[0] + "KB";
    case /* GigaBytes */7 :
        return maybeFormat[0] + "GB";
    case /* MemoryAddress */8 :
        return Renderer_MemoryAddress$BsConsole.toHex(maybeFormat[0]);
    case /* Callstack */9 :
        return $$String.concat(" ", maybeFormat[0][0]);
    case /* UUID */10 :
        return maybeFormat[0];
    case /* Bitmap */11 :
        return Pervasives.string_of_bool(maybeFormat[0]);
    case /* Ipv4 */12 :
        return Renderer_Ipv4$BsConsole.standardizeFormat(maybeFormat[0]);
    case /* IssueTickets */13 :
        var __x = List.map((function (ticket) {
                switch (ticket.tag | 0) {
                  case /* Unlinked */0 :
                      return ticket[0].short;
                  case /* Linked */1 :
                  case /* InteralAssigneeLink */2 :
                      return ;
                  
                }
              }), maybeFormat[0]);
        return $$String.concat(" ", List.map(Belt_Option.getExn, Belt_List.keep(__x, Belt_Option.isSome)));
    case /* IssueState */14 :
        return Issues$BsConsole.State.toString(maybeFormat[0]);
    case /* Classifiers */4 :
    case /* IssueTags */15 :
        return $$Array.of_list(maybeFormat[0]).join(" ");
    case /* IssueAssignees */16 :
        return Belt_List.toArray(Issues$BsConsole.Assignees.getAssigneeUsernames(maybeFormat[0])).join(", ");
    case /* ObjectID */17 :
        return ObjectID$BsConsole.toHexString(maybeFormat[0]);
    
  }
}

exports.formatDateToAgoString = formatDateToAgoString;
exports.formatToString = formatToString;
/* DateFns Not a pure module */
