// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Task$BsConsole = require("../task.js");
var Task2$BsConsole = require("../Task2.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfForm$BsConsole = require("./WfForm.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var WfTasks$BsConsole = require("./WfTasks.js");
var WfLayout$BsConsole = require("./WfLayout.js");
var WfMetrics$BsConsole = require("./WfMetrics.js");
var WfWatcher$BsConsole = require("./WfWatcher.js");
var WfNameField$BsConsole = require("./WfNameField.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var WatcherNewTask$BsConsole = require("../WatcherNewTask.js");
var WfBehaviorForm$BsConsole = require("./WfBehaviorForm.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");
var WfWatcherStepper$BsConsole = require("./WfWatcherStepper.js");
var WfIntegrationIcon$BsConsole = require("./WfIntegrationIcon.js");
var WfAdvancedSettings$BsConsole = require("./WfAdvancedSettings.js");
var WfCustomizePayload$BsConsole = require("./WfCustomizePayload.js");
var WfIntegrationSelect$BsConsole = require("./WfIntegrationSelect.js");

function makeCreateTask(bpgWatcher, config, projectName) {
  var match = WorkflowVersion$BsConsole.current(config);
  if (typeof match === "number" && match !== 0) {
    return Caml_option.some(BpgTask$BsConsole.WatcherNew.create(bpgWatcher));
  } else {
    return WatcherNewTask$BsConsole.create(bpgWatcher, config, projectName);
  }
}

function isStepSupported(step, version, integration) {
  if (step === 3) {
    if (typeof version === "number") {
      return version !== 0;
    } else {
      return false;
    }
  }
  if (step < 4) {
    return true;
  }
  var match = Belt_Option.flatMap(integration, (function (i) {
          return i.description.advanced_settings;
        }));
  if (typeof version === "number" && version !== 0) {
    return true;
  } else {
    return match !== undefined;
  }
}

function getSupportedSteps(version, integration) {
  var arg = integration;
  return Belt_List.reduce(/* :: */[
              /* SelectIntegration */0,
              /* :: */[
                /* ConfigureConnection */1,
                /* :: */[
                  /* CustomizePayload */2,
                  /* :: */[
                    /* SetupBehavior */3,
                    /* :: */[
                      /* AdvancedSettings */4,
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ], WfWatcherStepper$BsConsole.Steps.empty(undefined), (function (steps, step) {
                if (isStepSupported(step, version, arg)) {
                  return Pervasives.$at(steps, /* :: */[
                              step,
                              /* [] */0
                            ]);
                } else {
                  return steps;
                }
              }));
}

var component = RR$BsConsole.reducerComponent("WfWatcherCreateStepper-BsConsole");

function make(btDefaults, step, onStepChange, onSelectAtypicalRoute, token, allWatchers, availableIntegrations, handleTask, project, onIntegrationCreated, onCancelCreating, user, serviceEndpoint, universeName, config, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var behavior = state.behavior;
              var advancedSettings = state.advancedSettings;
              var payload = state.payload;
              var integration = state.integration;
              var name = state.name;
              var steps = state.steps;
              var send = param.send;
              var tmp;
              if (step !== 0) {
                var tmp$1;
                if (step !== 0) {
                  if (integration !== undefined) {
                    var maybeTask = WfTasks$BsConsole.describeIntegration(integration.description.type_, config);
                    if (maybeTask !== undefined) {
                      tmp$1 = ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Caml_option.valFromOption(maybeTask), undefined, (function (integrationRemote, param) {
                                  if (typeof integrationRemote === "number") {
                                    return null;
                                  }
                                  if (integrationRemote.tag) {
                                    return null;
                                  }
                                  var integration = integrationRemote[0][0];
                                  switch (step) {
                                    case /* SelectIntegration */0 :
                                        return null;
                                    case /* ConfigureConnection */1 :
                                        return ReasonReact.element(undefined, undefined, WfForm$BsConsole.make(btDefaults, token, integration.forms.configureConnection, (function (action) {
                                                          state.highlightConnectionErrors.contents = action;
                                                          
                                                        }), (function (fv) {
                                                          return Curry._1(send, /* UpdateConnection */Block.__(4, [fv]));
                                                        }), undefined, undefined, (function (showAllErrors) {
                                                          return ReasonReact.element(undefined, undefined, WfNameField$BsConsole.make(project.name, allWatchers, undefined, showAllErrors, name, (function (name) {
                                                                            return Curry._1(send, /* UpdateName */Block.__(0, [name]));
                                                                          }), undefined, (function (ref) {
                                                                            return Curry._1(send, /* SetNameRef */Block.__(2, [ref]));
                                                                          }), []));
                                                        }), []));
                                    case /* CustomizePayload */2 :
                                        return ReasonReact.element(undefined, undefined, WfCustomizePayload$BsConsole.make(payload, (function (customizePayload) {
                                                          return Curry._1(send, /* UpdatePayload */Block.__(5, [customizePayload]));
                                                        }), project.name, handleTask, token, []));
                                    case /* SetupBehavior */3 :
                                        var name$1 = state.name;
                                        var tmp;
                                        if (name$1.tag) {
                                          throw [
                                                Caml_builtin_exceptions.assert_failure,
                                                /* tuple */[
                                                  "WfWatcherCreateStepper.re",
                                                  570,
                                                  39
                                                ]
                                              ];
                                        }
                                        tmp = name$1[0];
                                        return ReasonReact.element(undefined, undefined, WfBehaviorForm$BsConsole.make(universeName, project.name + ("-" + tmp), integration, behavior, (function (behavior) {
                                                          return Curry._1(send, /* UpdateBehavior */Block.__(7, [behavior]));
                                                        }), serviceEndpoint, []));
                                    case /* AdvancedSettings */4 :
                                        return ReasonReact.element(undefined, undefined, WfAdvancedSettings$BsConsole.make(advancedSettings, (function (advancedSettings) {
                                                          return Curry._1(send, /* UpdateAdvancedSettings */Block.__(6, [advancedSettings]));
                                                        }), token, handleTask, project.name, integration.description.advanced_settings, []));
                                    
                                  }
                                })));
                    } else {
                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                      tmp$1 = null;
                    }
                  } else {
                    Curry._1(send, /* GoToStep */Block.__(1, [/* SelectIntegration */0]));
                    tmp$1 = null;
                  }
                } else {
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "WfWatcherCreateStepper.re",
                          517,
                          64
                        ]
                      ];
                }
                tmp = React.createElement("div", {
                      style: {
                        padding: "24px"
                      }
                    }, React.createElement("div", {
                          style: {
                            display: "flex",
                            minHeight: "100px",
                            position: "relative",
                            flexDirection: "column"
                          }
                        }, integration !== undefined && step !== /* SelectIntegration */0 ? React.createElement("div", {
                                style: {
                                  position: "absolute",
                                  right: "12px",
                                  top: "8px"
                                }
                              }, ReasonReact.element(undefined, undefined, WfIntegrationIcon$BsConsole.make(integration.description.icon, integration.description.title + " Icon", 56, []))) : null, tmp$1), step !== 0 ? ReasonReact.element(undefined, undefined, Curry._6(WfWatcherStepper$BsConsole.BottomNavigation.Jsx2.make, steps, step, (function (param, step) {
                                  return Curry._1(send, /* GoToStep */Block.__(1, [step]));
                                }), (function (param, step) {
                                  return Curry._1(send, /* GoToStep */Block.__(1, [step]));
                                }), (function (param) {
                                  return Curry._1(send, /* Submit */1);
                                }), [])) : null);
              } else {
                tmp = ReasonReact.element(undefined, undefined, WfIntegrationSelect$BsConsole.make((function (integration) {
                            return Curry._1(send, /* SelectIntegration */Block.__(3, [integration]));
                          }), availableIntegrations, []));
              }
              return ReasonReact.element(undefined, undefined, WfLayout$BsConsole.make(onCancelCreating, "90deg", ReasonReact.element(undefined, undefined, Curry._5(WfWatcherStepper$BsConsole.UpperNavigation.Jsx2.make, steps, step, (function (param, step) {
                                        return Curry._1(send, /* GoToStep */Block.__(1, [step]));
                                      }), state.maxStep, [])), [tmp]));
            }),
          initialState: (function (param) {
              return {
                      maxStep: step,
                      steps: WfWatcherStepper$BsConsole.Steps.make(/* :: */[
                            /* SelectIntegration */0,
                            /* [] */0
                          ]),
                      name: /* Error */Block.__(1, [/* tuple */[
                            "",
                            /* Required */0
                          ]]),
                      nameRef: {
                        contents: undefined
                      },
                      integration: undefined,
                      connection: undefined,
                      payload: undefined,
                      advancedSettings: undefined,
                      highlightConnectionErrors: {
                        contents: undefined
                      },
                      highlightPayloadErrors: {
                        contents: undefined
                      },
                      highlightAdvancedSettingsErrors: {
                        contents: undefined
                      },
                      behavior: {
                        config: undefined,
                        dataSynchronization: true,
                        dataSynchronization2Way: true
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* TestConnection */0) {
                  return /* SideEffects */Block.__(1, [(function (param) {
                                var match = state.connection;
                                if (match === undefined) {
                                  return ;
                                }
                                if (!match.tag) {
                                  return Curry._2(handleTask, (function (_json) {
                                                
                                              }), Task$BsConsole.makeTask(/* TestWipWorkflow */Block.__(28, [
                                                    token,
                                                    project.name,
                                                    match[0]
                                                  ])));
                                }
                                console.log("Errs in connection", match[0]);
                                
                              })]);
                }
                var match = WorkflowVersion$BsConsole.current(config);
                var exit = 0;
                if (typeof match === "number" && match !== 0) {
                  var match$1 = state.name;
                  var match$2 = state.connection;
                  var match$3 = state.payload;
                  var match$4 = state.advancedSettings;
                  var match$5 = state.integration;
                  var exit$1 = 0;
                  if (match$1.tag || match$2 === undefined) {
                    exit$1 = 2;
                  } else {
                    var name = match$1[0];
                    if (match$2.tag || match$3 === undefined) {
                      exit$1 = 2;
                    } else {
                      var f1 = match$2[0];
                      if (match$3.tag) {
                        exit$1 = 2;
                      } else {
                        if (match$4 === undefined) {
                          return /* NoUpdate */0;
                        }
                        var f2 = match$3[0];
                        if (match$4.tag) {
                          exit$1 = 2;
                        } else {
                          if (match$5 === undefined) {
                            return /* NoUpdate */0;
                          }
                          var f3 = match$4[0];
                          return /* SideEffects */Block.__(1, [(function (param) {
                                        var formValue = Object.assign(Object.assign(Object.assign(Object.assign({ }, f1), f2), f3), {
                                              manual_sync: state.behavior.dataSynchronization,
                                              two_way_sync: state.behavior.dataSynchronization2Way
                                            });
                                        var bpgWatcher = WfWatcher$BsConsole.toBpg(WfWatcher$BsConsole.make(undefined, name, project, undefined, WfBehaviorForm$BsConsole.toBehavior(state.behavior.config), formValue, user.uid, match$5.description.path));
                                        var match$6 = match$5.description.type_;
                                        if (match$6 === "sca") {
                                          var scaEndpointMaybe = Json_decode.optional((function (param) {
                                                  return Json_decode.field("sca-service-endpoint", Json_decode.string, param);
                                                }), btDefaults);
                                          if (scaEndpointMaybe !== undefined) {
                                            var arg = WfTasks$BsConsole.ScaBackfill.put(scaEndpointMaybe, universeName, f1.crashProject, f1.crashProjectToken, f1.defectProject, f1.defectProjectToken, f1.queryUrl, f1.ignoreFiles, f1.ignoreFunctions, f1.limitFiles, f1.limitFunctions, f1.classifierMatch, f1.matchColumn);
                                            Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), undefined, (function (resp) {
                                                    if (!resp.tag) {
                                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(resp[0].message));
                                                    }
                                                    var errorMessage = I18N$BsConsole.show(undefined, "Failed to initiate defect correlation backfill.");
                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, errorMessage);
                                                  }));
                                          }
                                          
                                        }
                                        var maybeTask = makeCreateTask(bpgWatcher, config, project.name);
                                        if (maybeTask !== undefined) {
                                          return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                        if (resp.tag) {
                                                          return Task2$BsConsole.shouldntHappen(resp[0]);
                                                        } else {
                                                          return Curry._1(onIntegrationCreated, undefined);
                                                        }
                                                      }));
                                        } else {
                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                        }
                                      })]);
                        }
                      }
                    }
                  }
                  if (exit$1 === 2) {
                    if (match$4 === undefined) {
                      return /* NoUpdate */0;
                    }
                    if (!match$4.tag) {
                      return /* NoUpdate */0;
                    }
                    var path = match$4[0][0];
                    return /* SideEffects */Block.__(1, [(function (param) {
                                  var cb = state.highlightAdvancedSettingsErrors.contents;
                                  if (cb !== undefined) {
                                    return Curry._1(cb, /* ScrollToError */[path]);
                                  }
                                  
                                })]);
                  }
                  
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  var match$6 = state.name;
                  var match$7 = state.connection;
                  var match$8 = state.payload;
                  var match$9 = state.advancedSettings;
                  var match$10 = state.integration;
                  var exit$2 = 0;
                  if (match$9 !== undefined) {
                    if (match$9.tag) {
                      var path$1 = match$9[0][0];
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    var cb = state.highlightAdvancedSettingsErrors.contents;
                                    if (cb !== undefined) {
                                      return Curry._1(cb, /* ScrollToError */[path$1]);
                                    }
                                    
                                  })]);
                    }
                    exit$2 = 2;
                  } else {
                    exit$2 = 2;
                  }
                  if (exit$2 === 2) {
                    if (match$6.tag) {
                      return /* NoUpdate */0;
                    }
                    if (match$7 === undefined) {
                      return /* NoUpdate */0;
                    }
                    var name$1 = match$6[0];
                    if (match$7.tag) {
                      return /* NoUpdate */0;
                    }
                    if (match$8 === undefined) {
                      return /* NoUpdate */0;
                    }
                    var connection = match$7[0];
                    if (match$8.tag) {
                      return /* NoUpdate */0;
                    }
                    if (match$10 === undefined) {
                      return /* NoUpdate */0;
                    }
                    var payload = match$8[0];
                    return /* SideEffects */Block.__(1, [(function (param) {
                                  var partialJsonObj = Object.assign(Object.assign({ }, connection), payload);
                                  var match$11 = state.advancedSettings;
                                  var jsonObj = match$11 !== undefined && !match$11.tag ? Object.assign(partialJsonObj, match$11[0]) : partialJsonObj;
                                  var bpgWatcher = WfWatcher$BsConsole.toBpg(WfWatcher$BsConsole.make(undefined, name$1, project, undefined, undefined, jsonObj, user.uid, match$10.description.path));
                                  var match$12 = match$10.description.type_;
                                  if (match$12 === "sca") {
                                    var scaEndpointMaybe = Json_decode.optional((function (param) {
                                            return Json_decode.field("sca-service-endpoint", Json_decode.string, param);
                                          }), btDefaults);
                                    if (scaEndpointMaybe !== undefined) {
                                      var arg = WfTasks$BsConsole.ScaBackfill.put(scaEndpointMaybe, universeName, connection.crashProject, connection.crashProjectToken, connection.defectProject, connection.defectProjectToken, connection.queryUrl, connection.ignoreFiles, connection.ignoreFunctions, connection.limitFiles, connection.limitFunctions, connection.classifierMatch, connection.matchColumn);
                                      Curry._2((function (param) {
                                                return (function (param$1, param$2) {
                                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                  });
                                              })(token), undefined, (function (resp) {
                                              if (!resp.tag) {
                                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(resp[0].message));
                                              }
                                              var errorMessage = I18N$BsConsole.show(undefined, "Failed to initiate defect correlation backfill.");
                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, errorMessage);
                                            }));
                                    }
                                    
                                  }
                                  var maybeTask = makeCreateTask(bpgWatcher, config, project.name);
                                  if (maybeTask !== undefined) {
                                    return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                  if (resp.tag) {
                                                    return Task2$BsConsole.shouldntHappen(resp[0]);
                                                  } else {
                                                    return Curry._1(onIntegrationCreated, undefined);
                                                  }
                                                }));
                                  } else {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                  }
                                })]);
                  }
                  
                }
                
              } else {
                switch (action.tag | 0) {
                  case /* UpdateName */0 :
                      return /* Update */Block.__(0, [{
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  name: action[0],
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: state.connection,
                                  payload: state.payload,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: state.behavior
                                }]);
                  case /* GoToStep */1 :
                      var nextStep = action[0];
                      var goToStep = function (state) {
                        var nextStepIndex = WfWatcherStepper$BsConsole.Steps.indexOf(state.steps, nextStep);
                        var maxStepIndex = WfWatcherStepper$BsConsole.Steps.indexOf(state.steps, state.maxStep);
                        var newMaxStep = nextStepIndex > maxStepIndex ? nextStep : state.maxStep;
                        if (nextStepIndex <= (maxStepIndex + 1 | 0)) {
                          Curry._1(onStepChange, nextStep);
                          return {
                                  maxStep: newMaxStep,
                                  steps: state.steps,
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: state.connection,
                                  payload: state.payload,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: state.behavior
                                };
                        } else {
                          window.alert("Skipping steps is not allowed");
                          return state;
                        }
                      };
                      var shouldValidate = WfWatcherStepper$BsConsole.Steps.compare(state.steps, nextStep, step) > 0;
                      var match$11 = state.integration;
                      if (match$11 === undefined) {
                        return /* Update */Block.__(0, [goToStep(state)]);
                      }
                      if (!shouldValidate) {
                        return /* Update */Block.__(0, [goToStep(state)]);
                      }
                      switch (step) {
                        case /* ConfigureConnection */1 :
                            var match$12 = state.name;
                            var match$13 = state.connection;
                            if (match$12.tag) {
                              return /* SideEffects */Block.__(1, [(function (param) {
                                            WfMetrics$BsConsole.send(/* WorkflowConfigureFormStepInvalid */Block.__(5, [
                                                    match$11,
                                                    1
                                                  ]));
                                            var ref = state.nameRef.contents;
                                            if (ref !== undefined) {
                                              WfCore$BsConsole.scrollToDomElement(Caml_option.valFromOption(ref));
                                            }
                                            var cb = state.highlightConnectionErrors.contents;
                                            if (cb !== undefined) {
                                              return Curry._1(cb, /* HighlightErrors */0);
                                            }
                                            
                                          })]);
                            }
                            if (match$13 === undefined) {
                              return /* NoUpdate */0;
                            }
                            if (!match$13.tag) {
                              return /* Update */Block.__(0, [goToStep(state)]);
                            }
                            var path$2 = match$13[0][0];
                            return /* SideEffects */Block.__(1, [(function (param) {
                                          var cb = state.highlightConnectionErrors.contents;
                                          if (cb !== undefined) {
                                            return Curry._1(cb, /* ScrollToError */[path$2]);
                                          }
                                          
                                        })]);
                        case /* CustomizePayload */2 :
                            var match$14 = state.payload;
                            if (match$14 === undefined) {
                              return /* Update */Block.__(0, [goToStep(state)]);
                            }
                            if (!match$14.tag) {
                              return /* Update */Block.__(0, [goToStep(state)]);
                            }
                            var path$3 = match$14[0][0];
                            return /* SideEffects */Block.__(1, [(function (param) {
                                          var cb = state.highlightPayloadErrors.contents;
                                          if (cb !== undefined) {
                                            return Curry._1(cb, /* ScrollToError */[path$3]);
                                          }
                                          
                                        })]);
                        case /* SetupBehavior */3 :
                            var match$15 = state.payload;
                            if (match$15 === undefined) {
                              return /* Update */Block.__(0, [goToStep(state)]);
                            }
                            if (!match$15.tag) {
                              return /* Update */Block.__(0, [goToStep(state)]);
                            }
                            var path$4 = match$15[0][0];
                            return /* SideEffects */Block.__(1, [(function (param) {
                                          var cb = state.highlightPayloadErrors.contents;
                                          if (cb !== undefined) {
                                            return Curry._1(cb, /* ScrollToError */[path$4]);
                                          }
                                          
                                        })]);
                        case /* SelectIntegration */0 :
                        case /* AdvancedSettings */4 :
                            return /* Update */Block.__(0, [goToStep(state)]);
                        
                      }
                  case /* SetNameRef */2 :
                      var nullableRef = action[0];
                      return /* SideEffects */Block.__(1, [(function (param) {
                                    param.state.nameRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
                                    
                                  })]);
                  case /* SelectIntegration */3 :
                      var integration = action[0];
                      if (!Caml_obj.caml_notequal(integration, state.integration)) {
                        return /* SideEffects */Block.__(1, [(function (param) {
                                      return Curry._1(param.send, /* GoToStep */Block.__(1, [/* ConfigureConnection */1]));
                                    })]);
                      }
                      var init = state.behavior;
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  maxStep: /* ConfigureConnection */1,
                                  steps: getSupportedSteps(WorkflowVersion$BsConsole.current(config), integration),
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: integration,
                                  connection: undefined,
                                  payload: undefined,
                                  advancedSettings: undefined,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: {
                                    config: WfBehaviorForm$BsConsole.defaultConfig(integration.description.category),
                                    dataSynchronization: init.dataSynchronization,
                                    dataSynchronization2Way: init.dataSynchronization2Way
                                  }
                                },
                                (function (param) {
                                    var send = param.send;
                                    var match = WorkflowVersion$BsConsole.current(config);
                                    if (typeof match === "number" && match !== 0) {
                                      return Curry._1(send, /* GoToStep */Block.__(1, [/* ConfigureConnection */1]));
                                    }
                                    var match$1 = integration.description.type_;
                                    switch (match$1) {
                                      case "jira" :
                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Chosen */Block.__(11, ["jiraCloud"])]));
                                          return Curry._1(onSelectAtypicalRoute, /* JiraCloud */0);
                                      case "jira-server" :
                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Chosen */Block.__(11, ["jiraServer"])]));
                                          return Curry._1(onSelectAtypicalRoute, /* JiraServer */Block.__(0, [/* Default */0]));
                                      case "slack" :
                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Chosen */Block.__(11, ["slack"])]));
                                          return Curry._1(onSelectAtypicalRoute, /* Slack */1);
                                      default:
                                        return Curry._1(send, /* GoToStep */Block.__(1, [/* ConfigureConnection */1]));
                                    }
                                  })
                              ]);
                  case /* UpdateConnection */4 :
                      return /* Update */Block.__(0, [{
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: action[0],
                                  payload: state.payload,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: state.behavior
                                }]);
                  case /* UpdatePayload */5 :
                      return /* Update */Block.__(0, [{
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: state.connection,
                                  payload: action[0],
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: state.behavior
                                }]);
                  case /* UpdateAdvancedSettings */6 :
                      return /* Update */Block.__(0, [{
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: state.connection,
                                  payload: state.payload,
                                  advancedSettings: action[0],
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: state.behavior
                                }]);
                  case /* UpdateBehavior */7 :
                      return /* Update */Block.__(0, [{
                                  maxStep: state.maxStep,
                                  steps: state.steps,
                                  name: state.name,
                                  nameRef: state.nameRef,
                                  integration: state.integration,
                                  connection: state.connection,
                                  payload: state.payload,
                                  advancedSettings: state.advancedSettings,
                                  highlightConnectionErrors: state.highlightConnectionErrors,
                                  highlightPayloadErrors: state.highlightPayloadErrors,
                                  highlightAdvancedSettingsErrors: state.highlightAdvancedSettingsErrors,
                                  behavior: action[0]
                                }]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.makeCreateTask = makeCreateTask;
exports.isStepSupported = isStepSupported;
exports.getSupportedSteps = getSupportedSteps;
exports.component = component;
exports.make = make;
/* component Not a pure module */
