// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var CopyToClipboard = require("copy-to-clipboard");

function copy(text, onCopyOpt, param) {
  var onCopy = onCopyOpt !== undefined ? onCopyOpt : (function (param) {
        
      });
  CopyToClipboard(text);
  return Curry._1(onCopy, text);
}

exports.copy = copy;
/* copy-to-clipboard Not a pure module */
