// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var Gate$BsConsole = require("../../Gate.js");

var jsMapperConstantArray = [
  /* tuple */[
    -792364981,
    "Bytes"
  ],
  /* tuple */[
    -610669292,
    "Networking"
  ],
  /* tuple */[
    -435432635,
    "VersionControl"
  ],
  /* tuple */[
    -92411890,
    "Miscellaneous"
  ],
  /* tuple */[
    870530776,
    "None"
  ],
  /* tuple */[
    936769581,
    "Time"
  ]
];

function formatCategoryToJs(param) {
  return Js_mapperRt.binarySearch(6, param, jsMapperConstantArray);
}

function formatCategoryFromJs(param) {
  return Js_mapperRt.revSearch(6, jsMapperConstantArray, param);
}

var jsMapperConstantArray$1 = [
  /* tuple */[
    -820144251,
    "commit"
  ],
  /* tuple */[
    -812272505,
    "sha256"
  ],
  /* tuple */[
    -792364981,
    "bytes"
  ],
  /* tuple */[
    -379547987,
    "js_timestamp"
  ],
  /* tuple */[
    -349935189,
    "nanoseconds"
  ],
  /* tuple */[
    -324157174,
    "kilobytes"
  ],
  /* tuple */[
    -32400353,
    "labels"
  ],
  /* tuple */[
    220131475,
    "memory_address"
  ],
  /* tuple */[
    256858674,
    "milliseconds"
  ],
  /* tuple */[
    519057866,
    "callstack"
  ],
  /* tuple */[
    528330184,
    "semver"
  ],
  /* tuple */[
    559906156,
    "gps_timestamp"
  ],
  /* tuple */[
    668917343,
    "seconds"
  ],
  /* tuple */[
    746836435,
    "hostname"
  ],
  /* tuple */[
    787392782,
    "unix_timestamp"
  ],
  /* tuple */[
    813535941,
    "ipv4"
  ],
  /* tuple */[
    813535943,
    "ipv6"
  ],
  /* tuple */[
    870530776,
    "none"
  ],
  /* tuple */[
    939376143,
    "gigabytes"
  ],
  /* tuple */[
    946856507,
    "uuid"
  ]
];

function formatToJs(param) {
  return Js_mapperRt.binarySearch(20, param, jsMapperConstantArray$1);
}

function formatFromJs(param) {
  return Js_mapperRt.revSearch(20, jsMapperConstantArray$1, param);
}

var jsMapperConstantArray$2 = [
  /* tuple */[
    -1037417766,
    "Unsigned Integer"
  ],
  /* tuple */[
    -976970511,
    "String"
  ],
  /* tuple */[
    -883944824,
    "Boolean"
  ],
  /* tuple */[
    946856507,
    "UUID"
  ]
];

function typeCategoryToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray$2);
}

function typeCategoryFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray$2, param);
}

var jsMapperConstantArray$3 = [
  /* tuple */[
    -493315045,
    "dictionary16"
  ],
  /* tuple */[
    508176322,
    "dictionary8"
  ],
  /* tuple */[
    530205405,
    "uint128"
  ],
  /* tuple */[
    562781022,
    "uint8"
  ],
  /* tuple */[
    631276559,
    "bitmap"
  ],
  /* tuple */[
    813535941,
    "ipv4"
  ],
  /* tuple */[
    946114815,
    "uint16"
  ],
  /* tuple */[
    946115257,
    "uint32"
  ],
  /* tuple */[
    946115928,
    "uint64"
  ],
  /* tuple */[
    946856507,
    "uuid"
  ],
  /* tuple */[
    1023055798,
    "dictionary"
  ]
];

function type_ToJs(param) {
  return Js_mapperRt.binarySearch(11, param, jsMapperConstantArray$3);
}

function type_FromJs(param) {
  return Js_mapperRt.revSearch(11, jsMapperConstantArray$3, param);
}

function fromJsToString(t) {
  switch (t) {
    case "bitmap" :
        return "Boolean";
    case "dictionary" :
        if (Gate$BsConsole.isStringCardinalityEnabled(undefined)) {
          return "High cardinality string";
        } else {
          return "String";
        }
    case "dictionary16" :
        return "Medium cardinality string";
    case "dictionary8" :
        return "Low cardinality string";
    case "uint128" :
        return "128-bit";
    case "uint16" :
        return "16-bit";
    case "uint32" :
        return "32-bit";
    case "uint64" :
        return "64-bit";
    case "uint8" :
        return "8-bit";
    case "uuid" :
        return "UUID";
    default:
      return t;
  }
}

var Types = {
  fromJsToString: fromJsToString
};

function formatCategoryToString(f) {
  if (f >= -435432635) {
    if (f >= 870530776) {
      if (f >= 936769581) {
        return "Time";
      } else {
        return "None";
      }
    } else if (f >= -92411890) {
      return "Miscellaneous";
    } else {
      return "Version control";
    }
  } else if (f >= -610669292) {
    return "Networking";
  } else {
    return "Bytes";
  }
}

function formatToString(f) {
  if (f >= 528330184) {
    if (f >= 813535941) {
      if (f !== 813535943) {
        if (f >= 939376143) {
          if (f >= 946856507) {
            return "UUID";
          } else {
            return "Gigabytes";
          }
        } else if (f >= 870530776) {
          return "None";
        } else {
          return "IPv4";
        }
      } else {
        return "IPv6";
      }
    } else if (f !== 559906156) {
      if (f >= 746836435) {
        if (f >= 787392782) {
          return "UNIX timestamp";
        } else {
          return "Hostname";
        }
      } else if (f >= 668917343) {
        return "Seconds";
      } else {
        return "Semantic version";
      }
    } else {
      return "GPS Timestamp";
    }
  } else if (f >= -324157174) {
    if (f !== -32400353) {
      if (f >= 256858674) {
        if (f >= 519057866) {
          return "Callstack";
        } else {
          return "Milliseconds";
        }
      } else if (f >= 220131475) {
        return "Memory address";
      } else {
        return "Kilobytes";
      }
    } else {
      return "Labels";
    }
  } else if (f !== -812272505) {
    if (f >= -379547987) {
      if (f >= -349935189) {
        return "Nanoseconds";
      } else {
        return "JavaScript timestamp";
      }
    } else if (f >= -792364981) {
      return "Bytes";
    } else {
      return "Commit hash";
    }
  } else {
    return "SHA-256";
  }
}

function fromJsToString$1(f) {
  switch (f) {
    case "bytes" :
        return "Bytes";
    case "callstack" :
        return "Callstack";
    case "commit" :
        return "Commit hash";
    case "gigabytes" :
        return "Gigabytes";
    case "gps_timestamp" :
        return "GPS timestamp";
    case "hostname" :
        return "Hostname";
    case "ipv4" :
        return "IPv4";
    case "ipv6" :
        return "IPv6";
    case "js_timestamp" :
        return "JavaScript timestamp";
    case "kilobytes" :
        return "Kilobytes";
    case "labels" :
        return "labels";
    case "memory_address" :
        return "Memory address";
    case "milliseconds" :
        return "Milliseconds";
    case "nanoseconds" :
        return "Nanoseconds";
    case "none" :
        return "None";
    case "seconds" :
        return "Seconds";
    case "semver" :
        return "Semantic version";
    case "sha256" :
        return "SHA-256";
    case "unix_timestamp" :
        return "UNIX timestamp";
    case "uuid" :
        return "UUID";
    default:
      return f;
  }
}

function getDerivedType(format) {
  switch (format) {
    case "ipv4" :
        return "uint32";
    case "ipv6" :
        return "uint128";
    case "callstack" :
    case "commit" :
    case "hostname" :
    case "labels" :
    case "none" :
    case "semver" :
    case "sha256" :
        return "dictionary";
    case "bytes" :
    case "gigabytes" :
    case "gps_timestamp" :
    case "js_timestamp" :
    case "kilobytes" :
    case "memory_address" :
    case "milliseconds" :
    case "nanoseconds" :
    case "seconds" :
    case "unix_timestamp" :
        return "uint64";
    case "uuid" :
        return "uuid";
    default:
      return "dictionary";
  }
}

function getPermissableNextFormats(type_) {
  if (type_ >= 946114815) {
    if (type_ !== 946115257) {
      if (type_ >= 946856507) {
        if (type_ >= 1023055798) {
          return /* :: */[
                  /* Callstack */519057866,
                  /* :: */[
                    /* Semver */528330184,
                    /* :: */[
                      /* Labels */-32400353,
                      /* :: */[
                        /* CommitHash */-820144251,
                        /* :: */[
                          /* Hostname */746836435,
                          /* :: */[
                            /* None */870530776,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ];
        } else {
          return /* :: */[
                  /* MemoryAddress */220131475,
                  /* :: */[
                    /* None */870530776,
                    /* :: */[
                      /* UUID */946856507,
                      /* [] */0
                    ]
                  ]
                ];
        }
      } else if (type_ >= 946115928) {
        return /* :: */[
                /* MemoryAddress */220131475,
                /* :: */[
                  /* Bytes */-792364981,
                  /* :: */[
                    /* Kilobytes */-324157174,
                    /* :: */[
                      /* Gigabytes */939376143,
                      /* :: */[
                        /* GPSTimestamp */559906156,
                        /* :: */[
                          /* JsTimestamp */-379547987,
                          /* :: */[
                            /* UnixTimestamp */787392782,
                            /* :: */[
                              /* Milliseconds */256858674,
                              /* :: */[
                                /* Nanoseconds */-349935189,
                                /* :: */[
                                  /* Seconds */668917343,
                                  /* :: */[
                                    /* None */870530776,
                                    /* :: */[
                                      /* IPV4 */813535941,
                                      /* :: */[
                                        /* IPV6 */813535943,
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ];
      } else {
        return /* :: */[
                /* MemoryAddress */220131475,
                /* :: */[
                  /* Bytes */-792364981,
                  /* :: */[
                    /* Kilobytes */-324157174,
                    /* :: */[
                      /* Gigabytes */939376143,
                      /* :: */[
                        /* GPSTimestamp */559906156,
                        /* :: */[
                          /* Milliseconds */256858674,
                          /* :: */[
                            /* Nanoseconds */-349935189,
                            /* :: */[
                              /* Seconds */668917343,
                              /* :: */[
                                /* None */870530776,
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ];
      }
    } else {
      return /* :: */[
              /* MemoryAddress */220131475,
              /* :: */[
                /* Bytes */-792364981,
                /* :: */[
                  /* Kilobytes */-324157174,
                  /* :: */[
                    /* Gigabytes */939376143,
                    /* :: */[
                      /* GPSTimestamp */559906156,
                      /* :: */[
                        /* JsTimestamp */-379547987,
                        /* :: */[
                          /* UnixTimestamp */787392782,
                          /* :: */[
                            /* Milliseconds */256858674,
                            /* :: */[
                              /* Nanoseconds */-349935189,
                              /* :: */[
                                /* Seconds */668917343,
                                /* :: */[
                                  /* None */870530776,
                                  /* :: */[
                                    /* IPV4 */813535941,
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ];
    }
  } else if (type_ >= 562781022) {
    if (type_ >= 631276559) {
      return /* :: */[
              /* None */870530776,
              /* [] */0
            ];
    } else {
      return /* :: */[
              /* MemoryAddress */220131475,
              /* :: */[
                /* Bytes */-792364981,
                /* :: */[
                  /* Kilobytes */-324157174,
                  /* :: */[
                    /* Gigabytes */939376143,
                    /* :: */[
                      /* GPSTimestamp */559906156,
                      /* :: */[
                        /* Milliseconds */256858674,
                        /* :: */[
                          /* Nanoseconds */-349935189,
                          /* :: */[
                            /* Seconds */668917343,
                            /* :: */[
                              /* None */870530776,
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ];
    }
  } else if (type_ >= 530205405) {
    return /* :: */[
            /* MemoryAddress */220131475,
            /* :: */[
              /* Bytes */-792364981,
              /* :: */[
                /* Kilobytes */-324157174,
                /* :: */[
                  /* Gigabytes */939376143,
                  /* :: */[
                    /* GPSTimestamp */559906156,
                    /* :: */[
                      /* JsTimestamp */-379547987,
                      /* :: */[
                        /* UnixTimestamp */787392782,
                        /* :: */[
                          /* Milliseconds */256858674,
                          /* :: */[
                            /* Nanoseconds */-349935189,
                            /* :: */[
                              /* Seconds */668917343,
                              /* :: */[
                                /* None */870530776,
                                /* :: */[
                                  /* IPV4 */813535941,
                                  /* :: */[
                                    /* IPV6 */813535943,
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ];
  } else {
    return /* :: */[
            /* Callstack */519057866,
            /* :: */[
              /* Semver */528330184,
              /* :: */[
                /* Labels */-32400353,
                /* :: */[
                  /* CommitHash */-820144251,
                  /* :: */[
                    /* Hostname */746836435,
                    /* :: */[
                      /* None */870530776,
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ];
  }
}

function getPermissableTypes(format) {
  switch (format) {
    case "ipv4" :
        return /* :: */[
                /* tuple */[
                  /* UnsignedInt */-1037417766,
                  /* :: */[
                    /* UINT32 */946115257,
                    /* [] */0
                  ]
                ],
                /* [] */0
              ];
    case "ipv6" :
        return /* :: */[
                /* tuple */[
                  /* UnsignedInt */-1037417766,
                  /* :: */[
                    /* UINT128 */530205405,
                    /* [] */0
                  ]
                ],
                /* [] */0
              ];
    case "js_timestamp" :
        return /* :: */[
                /* tuple */[
                  /* UnsignedInt */-1037417766,
                  /* :: */[
                    /* UINT64 */946115928,
                    /* [] */0
                  ]
                ],
                /* [] */0
              ];
    case "none" :
        return /* :: */[
                /* tuple */[
                  /* Boolean */-883944824,
                  /* :: */[
                    /* Bitmap */631276559,
                    /* [] */0
                  ]
                ],
                /* :: */[
                  /* tuple */[
                    /* String */-976970511,
                    /* :: */[
                      /* Dictionary8 */508176322,
                      /* :: */[
                        /* Dictionary16 */-493315045,
                        /* :: */[
                          /* Dictionary */1023055798,
                          /* [] */0
                        ]
                      ]
                    ]
                  ],
                  /* :: */[
                    /* tuple */[
                      /* UnsignedInt */-1037417766,
                      /* :: */[
                        /* UINT8 */562781022,
                        /* :: */[
                          /* UINT16 */946114815,
                          /* :: */[
                            /* UINT32 */946115257,
                            /* :: */[
                              /* UINT64 */946115928,
                              /* :: */[
                                /* UINT128 */530205405,
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ],
                    /* :: */[
                      /* tuple */[
                        /* UUID */946856507,
                        /* :: */[
                          /* UUID */946856507,
                          /* [] */0
                        ]
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ];
    case "bytes" :
    case "gigabytes" :
    case "gps_timestamp" :
    case "kilobytes" :
    case "memory_address" :
    case "milliseconds" :
    case "nanoseconds" :
    case "seconds" :
        return /* :: */[
                /* tuple */[
                  /* UnsignedInt */-1037417766,
                  /* :: */[
                    /* UINT8 */562781022,
                    /* :: */[
                      /* UINT16 */946114815,
                      /* :: */[
                        /* UINT32 */946115257,
                        /* :: */[
                          /* UINT64 */946115928,
                          /* :: */[
                            /* UINT128 */530205405,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ],
                /* [] */0
              ];
    case "callstack" :
    case "commit" :
    case "hostname" :
    case "labels" :
    case "semver" :
    case "sha256" :
        return /* :: */[
                /* tuple */[
                  /* String */-976970511,
                  /* :: */[
                    /* Dictionary */1023055798,
                    /* [] */0
                  ]
                ],
                /* [] */0
              ];
    case "unix_timestamp" :
        return /* :: */[
                /* tuple */[
                  /* UnsignedInt */-1037417766,
                  /* :: */[
                    /* UINT32 */946115257,
                    /* :: */[
                      /* UINT64 */946115928,
                      /* [] */0
                    ]
                  ]
                ],
                /* [] */0
              ];
    case "uuid" :
        return /* :: */[
                /* tuple */[
                  /* UUID */946856507,
                  /* :: */[
                    /* UUID */946856507,
                    /* [] */0
                  ]
                ],
                /* [] */0
              ];
    default:
      return ;
  }
}

var Formats_categories = /* :: */[
  /* Bytes */-792364981,
  /* :: */[
    /* Miscellaneous */-92411890,
    /* :: */[
      /* Networking */-610669292,
      /* :: */[
        /* Time */936769581,
        /* :: */[
          /* VersionControl */-435432635,
          /* :: */[
            /* None */870530776,
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var Formats_list = /* :: */[
  {
    name: /* Callstack */519057866,
    category: /* Miscellaneous */-92411890
  },
  /* :: */[
    {
      name: /* Labels */-32400353,
      category: /* Miscellaneous */-92411890
    },
    /* :: */[
      {
        name: /* MemoryAddress */220131475,
        category: /* Miscellaneous */-92411890
      },
      /* :: */[
        {
          name: /* SHA256 */-812272505,
          category: /* Miscellaneous */-92411890
        },
        /* :: */[
          {
            name: /* UUID */946856507,
            category: /* Miscellaneous */-92411890
          },
          /* :: */[
            {
              name: /* Bytes */-792364981,
              category: /* Bytes */-792364981
            },
            /* :: */[
              {
                name: /* Kilobytes */-324157174,
                category: /* Bytes */-792364981
              },
              /* :: */[
                {
                  name: /* Gigabytes */939376143,
                  category: /* Bytes */-792364981
                },
                /* :: */[
                  {
                    name: /* GPSTimestamp */559906156,
                    category: /* Time */936769581
                  },
                  /* :: */[
                    {
                      name: /* JsTimestamp */-379547987,
                      category: /* Time */936769581
                    },
                    /* :: */[
                      {
                        name: /* Milliseconds */256858674,
                        category: /* Time */936769581
                      },
                      /* :: */[
                        {
                          name: /* Nanoseconds */-349935189,
                          category: /* Time */936769581
                        },
                        /* :: */[
                          {
                            name: /* Seconds */668917343,
                            category: /* Time */936769581
                          },
                          /* :: */[
                            {
                              name: /* UnixTimestamp */787392782,
                              category: /* Time */936769581
                            },
                            /* :: */[
                              {
                                name: /* Hostname */746836435,
                                category: /* Networking */-610669292
                              },
                              /* :: */[
                                {
                                  name: /* IPV4 */813535941,
                                  category: /* Networking */-610669292
                                },
                                /* :: */[
                                  {
                                    name: /* IPV6 */813535943,
                                    category: /* Networking */-610669292
                                  },
                                  /* :: */[
                                    {
                                      name: /* CommitHash */-820144251,
                                      category: /* VersionControl */-435432635
                                    },
                                    /* :: */[
                                      {
                                        name: /* Semver */528330184,
                                        category: /* VersionControl */-435432635
                                      },
                                      /* :: */[
                                        {
                                          name: /* None */870530776,
                                          category: /* None */870530776
                                        },
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var Formats = {
  categories: Formats_categories,
  formatCategoryToString: formatCategoryToString,
  formatToString: formatToString,
  fromJsToString: fromJsToString$1,
  list: Formats_list,
  getDerivedType: getDerivedType,
  getPermissableNextFormats: getPermissableNextFormats,
  getPermissableTypes: getPermissableTypes
};

exports.formatCategoryToJs = formatCategoryToJs;
exports.formatCategoryFromJs = formatCategoryFromJs;
exports.formatToJs = formatToJs;
exports.formatFromJs = formatFromJs;
exports.typeCategoryToJs = typeCategoryToJs;
exports.typeCategoryFromJs = typeCategoryFromJs;
exports.type_ToJs = type_ToJs;
exports.type_FromJs = type_FromJs;
exports.Types = Types;
exports.Formats = Formats;
/* Gate-BsConsole Not a pure module */
