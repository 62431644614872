// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var Drilldown$BsConsole = require("../Drilldown.js");

var assignee = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.fontSize(Css.px(13)),
            /* :: */[
              Css.marginRight(Css.px(4)),
              /* :: */[
                Css.padding2(Css.px(3), Css.px(4)),
                /* :: */[
                  Css.borderRadius(Css.px(2)),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Css.display(/* inlineBlock */-147785676),
                        /* :: */[
                          Css.verticalAlign(/* middle */-866200747),
                          /* :: */[
                            Css.selector(":first-of-type", /* :: */[
                                  Css.maxWidth(Css.pct(100)),
                                  /* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.overflow(/* hidden */-862584982),
                                      /* :: */[
                                        Css.textOverflow(/* ellipsis */166888785),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "assignee__item",
        /* [] */0
      ]
    ]);

var assigneeButton = Css.style(/* :: */[
      Css.position(/* unset */653193961),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.selector(":first-of-type", /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.whiteSpace(/* nowrap */867913355),
                  /* :: */[
                    Css.maxWidth(Css.pct(100)),
                    /* [] */0
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  assignee: assignee,
  assigneeButton: assigneeButton
};

function getAssigneeUsernames(assignees) {
  return Belt_List.reduce(Belt_List.map(assignees, (function (assignee) {
                    if (assignee.tag) {
                      return Belt_Option.getWithDefault(assignee[0].username, "");
                    } else {
                      return assignee[0].username;
                    }
                  })), /* [] */0, (function (acc, curr) {
                if (Belt_List.has(acc, curr, (function (a, b) {
                          return a === b;
                        })) || curr === "") {
                  return acc;
                } else {
                  return Belt_List.concat(acc, /* :: */[
                              curr,
                              /* [] */0
                            ]);
                }
              }));
}

function createFilterOptions(user) {
  return [
          /* tuple */[
            "fingerprint;issues;assignee",
            /* AssignedTo */Block.__(10, [/* `String */[
                  -976970511,
                  user
                ]])
          ],
          /* tuple */[
            "fingerprint;issues;assignee",
            /* NotAssignedTo */Block.__(11, [/* `String */[
                  -976970511,
                  user
                ]])
          ]
        ];
}

function IssueAssignees(Props) {
  var assignees = Props.assignees;
  return Belt_List.toArray(Belt_List.mapWithIndex(Issues$BsConsole.Assignees.getAssigneeUsernames(assignees), (function (idx, user) {
                    return React.createElement(Drilldown$BsConsole.FromContext.make, {
                                textValue: user,
                                filterOptions: createFilterOptions(user),
                                className: assigneeButton,
                                children: React.createElement("div", {
                                      key: user + ("__" + String(idx)),
                                      className: assignee
                                    }, I18N$BsConsole.showSkip(user)),
                                key: user + ("__" + String(idx))
                              });
                  })));
}

var make = IssueAssignees;

exports.Styles = Styles;
exports.getAssigneeUsernames = getAssigneeUsernames;
exports.createFilterOptions = createFilterOptions;
exports.make = make;
/* assignee Not a pure module */
