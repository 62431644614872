// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Fuzz$BsConsole = require("../bindings/Fuzz.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var FilterPills$BsConsole = require("../FilterPills.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Input = require("@material-ui/core/Input");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var Search = require("@material-ui/icons/Search");
var BugAttributeRow$BsConsole = require("./BugAttributeRow.js");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var AttributesContext$BsConsole = require("../context/AttributesContext.js");
var ExpandMore = require("@material-ui/icons/ExpandMore");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var BugHighlightBarDefaults$BsConsole = require("./BugHighlightBarDefaults.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

function shouldHideHighlightBar(config) {
  if (TeamsUtils$BsConsole.isGuest(config)) {
    return !CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.62.29");
  } else {
    return false;
  }
}

var errorIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.25)),
      /* :: */[
        Css.marginRight(Css.px(5)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.errorDark)),
          /* [] */0
        ]
      ]
    ]);

var errorText = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.errorDark)),
        /* [] */0
      ]
    ]);

var errorContainer = Css.style(/* :: */[
      Css.height(Css.px(40)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.minHeight(Css.px(100)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var dropDownPaper = Css.style(/* :: */[
      Css.maxHeight(Css.px(500)),
      /* :: */[
        Css.maxWidth(Css.px(400)),
        /* :: */[
          Css.minWidth(Css.px(40)),
          /* :: */[
            Css.overflow(/* auto */-1065951377),
            /* :: */[
              Css.position(/* relative */903134412),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var dropDownRow2 = Css.style(/* :: */[
      Css.position(/* sticky */188263721),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.top(Css.px(0)),
            /* :: */[
              Css.important(Css.backgroundColor(Css.white)),
              /* :: */[
                Css.zIndex(100),
                /* :: */[
                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                  /* :: */[
                    Css.padding4(Css.px(11), Css.px(0), Css.px(11), Css.px(0)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var dropDownSearchContainer = Css.style(/* :: */[
      Css.width(Css.pct(15)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var dropDownSearchIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.searchIcon)),
      /* :: */[
        Css.selector(".root", /* :: */[
              Css.flexGrow(1),
              /* [] */0
            ]),
        /* :: */[
          Css.fontSize(Css.px(24)),
          /* [] */0
        ]
      ]
    ]);

var dropDownSearchInput = Css.style(/* :: */[
      Css.width(Css.pct(70)),
      /* :: */[
        Css.selector("input", /* :: */[
              Css.padding4(Css.px(5), Css.px(8), Css.px(5), Css.px(0)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function dropDownSearchCount(selectedAttributes) {
  return Css.style(/* :: */[
              Css.width(Css.pct(15)),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* :: */[
                    Css.justifyContent(/* center */98248149),
                    /* :: */[
                      selectedAttributes.length === BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes ? Css.color(Css.hex(Colors$BsConsole.red)) : Css.color(Css.hex(Colors$BsConsole.searchIcon)),
                      /* :: */[
                        Css.fontSize(Css.px(14)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var menuListRoot = Css.style(/* :: */[
      Css.maxWidth(Css.pct(100)),
      /* :: */[
        Css.position(/* relative */903134412),
        /* :: */[
          Css.zIndex(0),
          /* [] */0
        ]
      ]
    ]);

var manageAttriubtesContainer = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* :: */[
        Css.display(/* inlineFlex */53323314),
        /* :: */[
          Css.important(Css.paddingRight(Css.px(0))),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.flexShrink(0),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey15)),
                          /* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  500
                                ]),
                            /* [] */0
                          ]
                        ]),
                    /* :: */[
                      Css.textAlign(/* right */-379319332),
                      /* :: */[
                        Css.fontFamily("Roboto"),
                        /* :: */[
                          Css.fontSize(Css.px(14)),
                          /* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  400
                                ]),
                            /* :: */[
                              Css.lineHeight(Css.px(24)),
                              /* :: */[
                                Css.marginLeft(Css.px(4)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var selectAttributesText = Css.style(/* :: */[
      Css.marginRight(Css.px(5)),
      /* [] */0
    ]);

var selectAttributesMenuItem = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.maxWidth(Css.pct(100)),
        /* :: */[
          Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.white))),
          /* :: */[
            Css.important(Css.color(Css.hex(Colors$BsConsole.black))),
            /* :: */[
              Css.padding2(Css.px(10), Css.px(10)),
              /* :: */[
                Css.hover(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.black)),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.Debugger.grey3)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var menuItemAttribute = Css.style(/* :: */[
      Css.flexGrow(1),
      /* [] */0
    ]);

var menuItemChip = Css.style(/* :: */[
      Css.marginLeft(Css.px(15)),
      /* [] */0
    ]);

var attriubtesRowContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(/* auto */-1065951377),
        /* [] */0
      ]
    ]);

var attributeContainer = Css.style(/* :: */[
      Css.$$float(/* right */-379319332),
      /* :: */[
        Css.height(Css.px(24)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* row */5693978),
            /* :: */[
              Css.justifyContent(/* flexStart */662439529),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.flex(/* `num */[
                        5496390,
                        1
                      ]),
                  /* :: */[
                    Css.unsafe("max-width", "calc(50% - 10px)"),
                    /* :: */[
                      Css.overflow(Css.hidden),
                      /* :: */[
                        Css.marginLeft(Css.px(10)),
                        /* :: */[
                          Css.marginTop(Css.px(10)),
                          /* :: */[
                            Css.hover(/* :: */[
                                  Css.selector(".attribute-value", /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey15)),
                                        /* :: */[
                                          Css.fontWeight(/* `num */[
                                                5496390,
                                                500
                                              ]),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var attributeNameAndValue = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* :: */[
        Css.padding2(Css.rem(0.5), Css.rem(0.5)),
        /* :: */[
          Css.justifyContent(/* flexStart */662439529),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.Debugger.grey4)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                /* :: */[
                  Css.textAlign(/* center */98248149),
                  /* :: */[
                    Css.fontFamily("Roboto"),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var noWrapEllipsis = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* [] */0
        ]
      ]
    ]);

var noWrapFrontEllipsis = Css.merge(/* :: */[
      noWrapEllipsis,
      /* :: */[
        Css.style(/* :: */[
              Css.direction(/* rtl */5695082),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var attributeName = Css.merge(/* :: */[
      attributeNameAndValue,
      /* :: */[
        noWrapFrontEllipsis,
        /* :: */[
          Css.style(/* :: */[
                Css.unsafe("width", "max-content"),
                /* :: */[
                  Css.maxWidth(Css.vw(11)),
                  /* :: */[
                    Css.fontSize(Css.px(12)),
                    /* :: */[
                      Css.lineHeight(Css.px(24)),
                      /* :: */[
                        Css.height(Css.px(24)),
                        /* :: */[
                          Css.fontWeight(/* `num */[
                                5496390,
                                500
                              ]),
                          /* :: */[
                            Css.borderTopLeftRadius(Css.rem(0.25)),
                            /* :: */[
                              Css.borderBottomLeftRadius(Css.rem(0.25)),
                              /* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.Debugger.white2)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var attributeValue = Css.merge(/* :: */[
      attributeNameAndValue,
      /* :: */[
        noWrapEllipsis,
        /* :: */[
          "attribute-value",
          /* :: */[
            Css.style(/* :: */[
                  Css.flex(/* `num */[
                        5496390,
                        1
                      ]),
                  /* :: */[
                    Css.height(Css.px(24)),
                    /* :: */[
                      Css.fontSize(Css.px(12)),
                      /* :: */[
                        Css.fontWeight(/* `num */[
                              5496390,
                              400
                            ]),
                        /* :: */[
                          Css.lineHeight(Css.px(24)),
                          /* :: */[
                            Css.borderTopRightRadius(Css.rem(0.25)),
                            /* :: */[
                              Css.borderBottomRightRadius(Css.rem(0.25)),
                              /* :: */[
                                Css.borderTopWidth(Css.px(0)),
                                /* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.Debugger.white1)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var popoverPaper = Css.style(/* :: */[
      Css.important(Css.maxWidth(Css.px(180))),
      /* [] */0
    ]);

var highlightbarContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.minHeight(Css.px(40)),
            /* [] */0
          ]
        ]
      ]
    ]);

var topHighlightsContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* flexEnd */924268066),
            /* :: */[
              Css.paddingBottom(Css.px(0)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var bottomHighlightsContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* flexEnd */924268066),
            /* :: */[
              Css.flexWrap(/* wrap */-822134326),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var iconsContainer = Css.style(/* :: */[
      Css.minWidth(Css.pct(30)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* flexEnd */924268066),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function iconAttriubteContainer(index) {
  return Css.style(/* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* :: */[
                    Css.flexDirection(/* row */5693978),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Css.padding2(Css.px(8), Css.px(8)),
                        /* :: */[
                          index === 0 ? Css.marginLeft(Css.px(0)) : Css.marginLeft(Css.px(10)),
                          /* :: */[
                            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.Debugger.grey4)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                              /* :: */[
                                Css.textAlign(/* center */98248149),
                                /* :: */[
                                  Css.fontFamily("Roboto"),
                                  /* :: */[
                                    Css.fontSize(Css.px(12)),
                                    /* :: */[
                                      Css.lineHeight(Css.px(24)),
                                      /* :: */[
                                        Css.height(Css.px(24)),
                                        /* :: */[
                                          Css.fontWeight(/* `num */[
                                                5496390,
                                                500
                                              ]),
                                          /* :: */[
                                            Css.borderRadius(Css.rem(0.25)),
                                            /* :: */[
                                              Css.hover(/* :: */[
                                                    Css.cursor(/* pointer */-786317123),
                                                    /* :: */[
                                                      Css.selector(".icon-attribute-text", /* :: */[
                                                            Css.color(Css.hex(Colors$BsConsole.grey15)),
                                                            /* :: */[
                                                              Css.fontWeight(/* `num */[
                                                                    5496390,
                                                                    500
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ]),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var iconAttriubteText = Css.merge(/* :: */[
      "icon-attribute-text",
      /* :: */[
        Css.style(/* :: */[
              Css.marginLeft(Css.px(8)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                /* :: */[
                  Css.fontFamily("Roboto"),
                  /* :: */[
                    Css.fontSize(Css.px(12)),
                    /* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            400
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var tooltip = {
  tooltip: Css.style(/* :: */[
        Css.whiteSpace(/* preWrap */660870029),
        /* :: */[
          Css.wordBreak(/* breakAll */-323760734),
          /* [] */0
        ]
      ])
};

var Styles = {
  errorIcon: errorIcon,
  errorText: errorText,
  errorContainer: errorContainer,
  loading: loading,
  dropDownPaper: dropDownPaper,
  dropDownRow2: dropDownRow2,
  dropDownSearchContainer: dropDownSearchContainer,
  dropDownSearchIcon: dropDownSearchIcon,
  dropDownSearchInput: dropDownSearchInput,
  dropDownSearchCount: dropDownSearchCount,
  menuListRoot: menuListRoot,
  manageAttriubtesContainer: manageAttriubtesContainer,
  selectAttributesText: selectAttributesText,
  selectAttributesMenuItem: selectAttributesMenuItem,
  menuItemAttribute: menuItemAttribute,
  menuItemChip: menuItemChip,
  attriubtesRowContainer: attriubtesRowContainer,
  attributeContainer: attributeContainer,
  attributeNameAndValue: attributeNameAndValue,
  noWrapEllipsis: noWrapEllipsis,
  noWrapFrontEllipsis: noWrapFrontEllipsis,
  attributeName: attributeName,
  attributeValue: attributeValue,
  popoverPaper: popoverPaper,
  highlightbarContainer: highlightbarContainer,
  topHighlightsContainer: topHighlightsContainer,
  bottomHighlightsContainer: bottomHighlightsContainer,
  iconsContainer: iconsContainer,
  iconAttriubteContainer: iconAttriubteContainer,
  iconAttriubteText: iconAttriubteText,
  tooltip: tooltip
};

function BugHighlightBar$HighlightBarError(Props) {
  var errorMessage = Props.errorMessage;
  return React.createElement("div", {
              className: errorContainer
            }, React.createElement(Info.default, {
                  className: errorIcon
                }), React.createElement("span", {
                  className: errorText
                }, I18N$BsConsole.show(undefined, "An issue occurred while processing the attributes highlight bar: "), errorMessage));
}

var HighlightBarError = {
  make: BugHighlightBar$HighlightBarError
};

function BugHighlightBar$HighlightbarMenuList(Props) {
  var model = Props.model;
  var maybeProjectAttribute = Props.maybeProjectAttribute;
  var dispatch = Props.dispatch;
  var match = Js_json.classify(model.attributeValue);
  if (maybeProjectAttribute === undefined) {
    return BugAttributeRow$BsConsole.renderUnknownAttributeMenuList(dispatch, model);
  }
  if (typeof match === "number") {
    if (match === /* JSONNull */2) {
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuListWithoutFilters(dispatch, maybeProjectAttribute, model);
    } else {
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuList(dispatch, maybeProjectAttribute, model);
    }
  }
  switch (match.tag | 0) {
    case /* JSONObject */2 :
    case /* JSONArray */3 :
        return BugAttributeRow$BsConsole.renderProjectAttributeMenuListWithoutFilters(dispatch, maybeProjectAttribute, model);
    default:
      return BugAttributeRow$BsConsole.renderProjectAttributeMenuList(dispatch, maybeProjectAttribute, model);
  }
}

var HighlightbarMenuList = {
  make: BugHighlightBar$HighlightbarMenuList
};

function BugHighlightBar$IconAttribute(Props) {
  var projectName = Props.projectName;
  var maybeProjectAttribute = Props.maybeProjectAttribute;
  var attributeName = Props.attributeName;
  var attributeValue = Props.attributeValue;
  var icon = Props.icon;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  var index = Props.index;
  var match = React.useState((function () {
          return {
                  attributeName: attributeName,
                  attributeValue: attributeValue,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilter: onAddFilter,
                  isHovered: false,
                  popover: undefined,
                  projectAttribute: maybeProjectAttribute,
                  projectName: projectName,
                  route: /* ProjectSettingsAttributes */Block.__(34, [
                      projectName,
                      {
                        attribute: attributeName
                      }
                    ])
                };
        }));
  var setModel = match[1];
  var model = match[0];
  var dispatch = function (action) {
    return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: iconAttriubteContainer(index),
                  onClick: (function ($$event) {
                      var action = BugAttributeRow$BsConsole.Action.popoverOpen($$event);
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
                    }),
                  onMouseEnter: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseEnter */0));
                    }),
                  onMouseLeave: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseLeave */1));
                    })
                }, React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: icon,
                      title: BugAttributeRow$BsConsole.Model.isProjectAttribute(model) ? model.attributeName : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " is a non-indexed attribute and can not be queried. Click for steps to index.",
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "%s is a non-indexed attribute and can not be queried. Click for steps to index."
                                ]), model.attributeName)
                    }), React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: React.createElement("span", {
                            className: iconAttriubteText
                          }, I18N$BsConsole.dynamic(BugAttributeRow$BsConsole.Model.getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue))),
                      title: BugAttributeRow$BsConsole.Model.getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue)
                    })), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: model.popover,
                  open: BugAttributeRow$BsConsole.Model.hasPopover(model),
                  classes: {
                    paper: popoverPaper
                  },
                  onClose: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* PopoverClose */2));
                    }),
                  children: React.createElement(BugHighlightBar$HighlightbarMenuList, {
                        model: model,
                        maybeProjectAttribute: maybeProjectAttribute,
                        dispatch: dispatch
                      })
                }));
}

var IconAttribute = {
  make: BugHighlightBar$IconAttribute
};

function BugHighlightBar$Loading(Props) {
  return React.createElement("div", {
              className: loading
            }, React.createElement(CircularProgress.default, {
                  size: 25,
                  color: "secondary"
                }));
}

var Loading = {
  make: BugHighlightBar$Loading
};

function BugHighlightBar$ManageHighlightbarAttributes(Props) {
  var describedAttributes = Props.describedAttributes;
  var attributes = Props.attributes;
  var selectedAttributes = Props.selectedAttributes;
  var handleAddAttribute = Props.handleAddAttribute;
  var handleRemoveAttribute = Props.handleRemoveAttribute;
  var showAttributeSearchLoading = Props.showAttributeSearchLoading;
  var setShowAttributeSearchLoading = Props.setShowAttributeSearchLoading;
  var match = React.useState((function () {
          
        }));
  var setSelectAttributeAnchor = match[1];
  var selectAttributeAnchor = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setAttributeSearchInput = match$1[1];
  var attributeSearchInput = match$1[0];
  var match$2 = React.useState((function () {
          return [];
        }));
  var setFilteredConsiderations = match$2[1];
  var filteredConsiderations = match$2[0];
  React.useEffect((function () {
          var availableAttributes = Belt_SortArray.stableSortBy(Object.keys(attributes), (function (a1, a2) {
                  return $$String.compare(a1.toLowerCase(), a2.toLowerCase());
                }));
          var nextConsiderations = attributeSearchInput.trim() !== "" ? Fuzz$BsConsole.fuzz(attributeSearchInput, availableAttributes, (function (listItem) {
                    return listItem;
                  })) : availableAttributes;
          Curry._1(setFilteredConsiderations, (function (param) {
                  return nextConsiderations;
                }));
          
        }), /* tuple */[
        attributeSearchInput,
        attributes
      ]);
  return React.createElement("div", {
              className: manageAttriubtesContainer
            }, selectedAttributes.length === 0 ? React.createElement("span", {
                    className: selectAttributesText
                  }, I18N$BsConsole.show(undefined, "Select and pin attributes here")) : null, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                  ariaLabel: I18N$BsConsole.get(undefined, "Manage pinned attributes"),
                  onClick: (function (e) {
                      var target = e.target;
                      var target$1 = (target == null) ? undefined : Caml_option.some(target);
                      return Curry._1(setSelectAttributeAnchor, (function (param) {
                                    return Caml_option.some(target$1);
                                  }));
                    }),
                  title_tooltip: I18N$BsConsole.get(undefined, "Manage pinned attributes"),
                  children: React.createElement(ExpandMore.default, { })
                }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "bottom"
                  },
                  anchorEl: selectAttributeAnchor,
                  open: Belt_Option.isSome(selectAttributeAnchor),
                  onClose: (function (param) {
                      return Curry._1(setSelectAttributeAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(Paper.default, {
                        className: dropDownPaper,
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: dropDownRow2,
                            children: null
                          }, React.createElement("div", {
                                className: dropDownSearchContainer
                              }, React.createElement(Search.default, {
                                    className: dropDownSearchIcon
                                  })), React.createElement(Input.default, {
                                className: dropDownSearchInput,
                                value: attributeSearchInput,
                                placeholder: "Search attributes",
                                autoFocus: true,
                                onChange: (function ($$event) {
                                    var value = Belt_Option.getWithDefault(Caml_option.nullable_to_opt($$event.target.value), "");
                                    return Curry._1(setAttributeSearchInput, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                disableUnderline: true
                              }), React.createElement("div", {
                                className: dropDownSearchCount(selectedAttributes)
                              }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* Int */Block.__(4, [
                                            /* Int_d */0,
                                            /* No_padding */0,
                                            /* No_precision */0,
                                            /* Char_literal */Block.__(12, [
                                                /* "/" */47,
                                                /* Int */Block.__(4, [
                                                    /* Int_d */0,
                                                    /* No_padding */0,
                                                    /* No_precision */0,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "%d/%d"
                                      ]), selectedAttributes.length, BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes))), React.createElement(MenuList.default, {
                            classes: {
                              root: menuListRoot
                            },
                            children: showAttributeSearchLoading ? React.createElement(BugHighlightBar$Loading, { }) : (
                                filteredConsiderations.length !== 0 ? Belt_Array.map(Belt_SortArray.stableSortBy(filteredConsiderations, (function (c1, c2) {
                                              if (selectedAttributes.includes(c1)) {
                                                return -1;
                                              } else if (selectedAttributes.includes(c2)) {
                                                return 1;
                                              } else {
                                                return 0;
                                              }
                                            })), (function (attribute) {
                                          var checked = selectedAttributes.includes(attribute);
                                          var isCustom = Attribute_Helpers$BsConsole.RelevantAttributes.isCustomAttribute(describedAttributes, attribute);
                                          return React.createElement(MenuItem.default, {
                                                      disabled: selectedAttributes.length === BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes && !checked,
                                                      onClick: (function (param) {
                                                          if (checked) {
                                                            Curry._1(handleRemoveAttribute, attribute);
                                                          } else if (selectedAttributes.length < BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes) {
                                                            Curry._1(handleAddAttribute, attribute);
                                                          }
                                                          Curry._1(setAttributeSearchInput, (function (param) {
                                                                  return "";
                                                                }));
                                                          return Curry._1(setShowAttributeSearchLoading, (function (param) {
                                                                        return true;
                                                                      }));
                                                        }),
                                                      className: selectAttributesMenuItem,
                                                      children: null,
                                                      key: attribute
                                                    }, React.createElement(Checkbox.default, {
                                                          checked: checked,
                                                          color: "primary"
                                                        }), React.createElement("span", {
                                                          className: menuItemAttribute
                                                        }, I18N$BsConsole.show(undefined, attribute)), React.createElement("span", {
                                                          className: menuItemChip
                                                        }, isCustom ? React.createElement(FilterPills$BsConsole.Custom.make, { }) : null));
                                        })) : React.createElement(MenuItem.default, {
                                        className: selectAttributesMenuItem,
                                        children: I18N$BsConsole.show(undefined, "No attributes found")
                                      })
                              )
                          }))
                }));
}

var ManageHighlightbarAttributes = {
  make: BugHighlightBar$ManageHighlightbarAttributes
};

function BugHighlightBar$HighlightAttr(Props) {
  var projectName = Props.projectName;
  var maybeProjectAttribute = Props.maybeProjectAttribute;
  var attributeName$1 = Props.attributeName;
  var attributeValue$1 = Props.attributeValue;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return {
                  attributeName: attributeName$1,
                  attributeValue: attributeValue$1,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilter: onAddFilter,
                  isHovered: false,
                  popover: undefined,
                  projectAttribute: maybeProjectAttribute,
                  projectName: projectName,
                  route: /* ProjectSettingsAttributes */Block.__(34, [
                      projectName,
                      {
                        attribute: attributeName$1
                      }
                    ])
                };
        }));
  var setModel = match[1];
  var model = match[0];
  var dispatch = function (action) {
    return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: attributeContainer,
                  onClick: (function ($$event) {
                      var action = BugAttributeRow$BsConsole.Action.popoverOpen($$event);
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(action));
                    }),
                  onMouseEnter: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseEnter */0));
                    }),
                  onMouseLeave: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* MouseLeave */1));
                    })
                }, React.createElement(Tooltip.default, {
                      placement: "top-start",
                      enterDelay: 750,
                      children: React.createElement("div", {
                            className: attributeName
                          }, React.createElement("span", {
                                className: noWrapFrontEllipsis
                              }, React.createElement("bdi", undefined, I18N$BsConsole.dynamic(model.attributeName)))),
                      title: BugAttributeRow$BsConsole.Model.isProjectAttribute(model) ? model.attributeName : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " is a non-indexed attribute and can not be queried. Click for steps to index.",
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "%s is a non-indexed attribute and can not be queried. Click for steps to index."
                                ]), model.attributeName)
                    }), React.createElement(Tooltip.default, {
                      classes: tooltip,
                      placement: "top-end",
                      enterDelay: 750,
                      children: React.createElement("div", {
                            className: attributeValue
                          }, React.createElement("span", {
                                className: noWrapEllipsis
                              }, I18N$BsConsole.dynamic(BugAttributeRow$BsConsole.Model.getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue)))),
                      title: BugAttributeRow$BsConsole.Model.getAttributeValueString(model.projectAttribute, model.attributeName, model.attributeValue)
                    })), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: model.popover,
                  open: BugAttributeRow$BsConsole.Model.hasPopover(model),
                  classes: {
                    paper: popoverPaper
                  },
                  onClose: (function (param) {
                      return Curry._1(setModel, BugAttributeRow$BsConsole.Action.update(/* PopoverClose */2));
                    }),
                  children: React.createElement(BugHighlightBar$HighlightbarMenuList, {
                        model: model,
                        maybeProjectAttribute: maybeProjectAttribute,
                        dispatch: dispatch
                      })
                }));
}

var HighlightAttr = {
  make: BugHighlightBar$HighlightAttr
};

function BugHighlightBar$HightlightbarAttributes(Props) {
  var selectedAttributes = Props.selectedAttributes;
  var projectAttributes = Props.projectAttributes;
  var projectName = Props.projectName;
  var attributes = Props.attributes;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement("div", {
              className: attriubtesRowContainer
            }, Belt_Array.map(selectedAttributes, (function (key) {
                    var maybeProjectAttribute = Belt_List.getBy(projectAttributes, (function (pa) {
                            return pa.name === key;
                          }));
                    var v = Js_dict.get(attributes, key);
                    if (v !== undefined) {
                      return React.createElement(BugHighlightBar$HighlightAttr, {
                                  projectName: projectName,
                                  maybeProjectAttribute: maybeProjectAttribute,
                                  attributeName: key,
                                  attributeValue: Caml_option.valFromOption(v),
                                  onAddFilter: onAddFilter,
                                  handleChangeUrl: handleChangeUrl,
                                  key: key
                                });
                    } else {
                      return null;
                    }
                  })));
}

var HightlightbarAttributes = {
  make: BugHighlightBar$HightlightbarAttributes
};

function BugHighlightBar$HighlightbarIcons(Props) {
  var attributes = Props.attributes;
  var projectName = Props.projectName;
  var projectAttributes = Props.projectAttributes;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement("div", {
              className: iconsContainer
            }, Belt_Array.mapWithIndex(BugHighlightBarDefaults$BsConsole.getDefaultHighlightBarIcons(attributes), (function (index, defaultIcon) {
                    var maybeProjectAttribute = Belt_List.getBy(projectAttributes, (function (pa) {
                            return pa.name === defaultIcon.attribute[0];
                          }));
                    var attributeName = defaultIcon.attribute[0];
                    var attributeValue = defaultIcon.attribute[1];
                    return React.createElement(BugHighlightBar$IconAttribute, {
                                projectName: projectName,
                                maybeProjectAttribute: maybeProjectAttribute,
                                attributeName: attributeName,
                                attributeValue: attributeValue,
                                icon: defaultIcon.iconElement,
                                onAddFilter: onAddFilter,
                                handleChangeUrl: handleChangeUrl,
                                index: index,
                                key: attributeName
                              });
                  })));
}

var HighlightbarIcons = {
  make: BugHighlightBar$HighlightbarIcons
};

function BugHighlightBar(Props) {
  var projectName = Props.projectName;
  var config = Props.config;
  var onAddFilter = Props.onAddFilter;
  var attrs = Props.attributes;
  var handleChangeUrl = Props.handleChangeUrl;
  var token = Props.token;
  var errorId = Props.errorId;
  var describedAttributes = React.useContext(AttributesContext$BsConsole.ctx);
  var attributes = Belt_Array.keep(Belt_SortArray.stableSortBy(Js_dict.entries(attrs), (function (param, param$1) {
              return $$String.compare(param[0].toLowerCase(), param$1[0].toLowerCase());
            })), (function (param) {
          return Attribute_Helpers$BsConsole.RelevantAttributes.isRelevantAttribute(describedAttributes, /* tuple */[
                      param[0],
                      param[1]
                    ]);
        }));
  var match = React.useState((function () {
          return [];
        }));
  var setSelectedAttributes = match[1];
  var selectedAttributes = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowAttributeSearchLoading = match$1[1];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setUserSettingsRemote = match$2[1];
  var userSettingsRemote = match$2[0];
  var maybeSdkAttribute = Belt_Array.get(Belt_Array.keep(attributes, (function (attr) {
              return attr[0] === "backtrace.agent";
            })), 0);
  var sdk = maybeSdkAttribute !== undefined ? BugAttributeRow$BsConsole.Model.getAttributeRawValueString(maybeSdkAttribute[1]) : "";
  var handleStoreHighlightbarDefaults = function (attributes) {
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* SetDefaultHighlightBarAttributes */Block.__(7, [
                    projectName,
                    sdk,
                    attributes
                  ]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  return Curry._1(setUserSettingsRemote, (function (param) {
                                return /* Loading */1;
                              }));
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugHighlightbar.re/handleStoreHighlightbarDefaults", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        "Failed to store highlight bar defaults: " + err
                      ]);
                  return Curry._1(setUserSettingsRemote, (function (param) {
                                return /* Failure */Block.__(1, ["failed to fetch default attributes"]);
                              }));
                }));
  };
  var getAttributeValue = function (key, maybeProjectAttribute) {
    var __x = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keep(attributes, (function (k) {
                      return k[0] === key;
                    })), 0), /* tuple */[
            "",
            null
          ])[1];
    return BugAttributeRow$BsConsole.Model.getAttributeValueString(maybeProjectAttribute, key, __x);
  };
  var compareAttributeLength = function (a1_key, a2_key) {
    var maybeProjectAttribute_1 = Belt_List.getBy(describedAttributes.active, (function (pa) {
            return pa.name === a1_key;
          }));
    var maybeProjectAttribute_2 = Belt_List.getBy(describedAttributes.active, (function (pa) {
            return pa.name === a2_key;
          }));
    var a1_value = getAttributeValue(a1_key, maybeProjectAttribute_1);
    var a2_value = getAttributeValue(a2_key, maybeProjectAttribute_2);
    var a1Length = a1_key.length > a1_value.length ? a1_key.length : a1_value.length;
    var a2Length = a2_key.length > a2_value.length ? a2_key.length : a2_value.length;
    if (a1Length > a2Length) {
      return 1;
    } else if (a1Length < a2Length) {
      return -1;
    } else {
      return 0;
    }
  };
  React.useEffect((function () {
          Curry._1(setUserSettingsRemote, (function (param) {
                  return /* Loading */1;
                }));
          
        }), [errorId]);
  React.useEffect((function () {
          if (typeof userSettingsRemote === "number") {
            if (userSettingsRemote !== /* NotAsked */0) {
              var userFrontendSettingsEntity = "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table;
              var decodeActions = function (json) {
                return Json_decode.array((function (json$prime) {
                              var action = Json_decode.field("action", Json_decode.string, json$prime);
                              var type_ = Json_decode.field("type", Json_decode.string, json$prime);
                              return /* tuple */[
                                      action,
                                      type_
                                    ];
                            }), json);
              };
              var fetchUserFrontendSettingsTask = function (uid) {
                var body = Json_encode.object_(/* :: */[
                      /* tuple */[
                        "actions",
                        Json_encode.array((function (entity) {
                                return Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "type",
                                              entity
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "action",
                                                "get"
                                              ],
                                              /* [] */0
                                            ]
                                          ]);
                              }), [userFrontendSettingsEntity])
                      ],
                      /* [] */0
                    ]);
                return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [body]), undefined, (function (param) {
                              var json = param.json;
                              try {
                                var actions = Json_decode.field("actions", decodeActions, json);
                                var func = function (param, param$1) {
                                  var func$1 = function (param, param$1) {
                                    return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getIndexBy(actions, (function (param$2) {
                                                          return Caml_obj.caml_equal(param$2[1], param);
                                                        })), (function (idx) {
                                                      return Belt_Array.get(Json_decode.field("results", (function (param) {
                                                                        return Json_decode.array((function (json$prime) {
                                                                                      return json$prime;
                                                                                    }), param);
                                                                      }), param$1), idx);
                                                    })), (function (json) {
                                                  return Json_decode.field("result", (function (json$prime) {
                                                                return json$prime;
                                                              }), json);
                                                }));
                                  };
                                  return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getBy(Belt_Option.getWithDefault(Belt_Option.map(Curry._2(func$1, userFrontendSettingsEntity, param$1), (function (json$prime) {
                                                                return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, json$prime);
                                                              })), []), (function (projectSettings) {
                                                        return projectSettings.owner === param;
                                                      })), (function (projectSettings) {
                                                    return Json.parse(projectSettings.json);
                                                  })), UserFrontendSettings$BsConsole.ofJson);
                                };
                                var userFrontendSettings = Curry._2(func, uid, json);
                                return /* Ok */Block.__(0, [userFrontendSettings]);
                              }
                              catch (_err){
                                return /* Error */Block.__(1, [[
                                            BpgTask$BsConsole.BpgError,
                                            BpgTask$BsConsole.$$Error.ofInt(1),
                                            "BPG decoding error"
                                          ]]);
                              }
                            }), undefined, undefined);
              };
              var onSuccess = function (userSettings) {
                Curry._1(setShowAttributeSearchLoading, (function (param) {
                        return false;
                      }));
                return Curry._1(setUserSettingsRemote, (function (param) {
                              return /* Success */Block.__(0, [userSettings]);
                            }));
              };
              var onFailure = function (msg) {
                Curry._1(setShowAttributeSearchLoading, (function (param) {
                        return false;
                      }));
                Curry._1(setUserSettingsRemote, (function (param) {
                        return /* Failure */Block.__(1, [msg]);
                      }));
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugHightlightBar/fetchUserFrontendSettingsTask/onFailure", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "\"Highlightbar: failed to fetch userFrontendSettings: " + (String(msg) + "\"")
                    ]);
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Error: failed to fetch user settings: ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "Error: failed to fetch user settings: %s"
                                  ]), msg));
              };
              var arg = fetchUserFrontendSettingsTask(config.user.uid);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          } else if (!userSettingsRemote.tag) {
            var maybeUserSettings = userSettingsRemote[0];
            var useUserFrontendSettingsAttributes = function (storedAttributes) {
              var sortedAttributes = Belt_SortArray.stableSortBy(Util$BsConsole.getUniqueValues(storedAttributes), compareAttributeLength);
              return Curry._1(setSelectedAttributes, (function (param) {
                            return sortedAttributes;
                          }));
            };
            if (maybeUserSettings !== undefined) {
              var highlightbarKey = projectName + sdk;
              var highlightbarAttributes = Js_dict.get(maybeUserSettings.highlightbarAttributes, highlightbarKey);
              if (highlightbarAttributes !== undefined) {
                useUserFrontendSettingsAttributes(highlightbarAttributes);
              } else {
                handleStoreHighlightbarDefaults(BugHighlightBarDefaults$BsConsole.getDefaultHighlightBarAttributes(attrs, describedAttributes));
              }
            } else {
              handleStoreHighlightbarDefaults(BugHighlightBarDefaults$BsConsole.getDefaultHighlightBarAttributes(attrs, describedAttributes));
            }
          }
          
        }), [userSettingsRemote]);
  var handleAddAttribute = function (attribute) {
    var isAttributeAlreadySelected = Belt_Array.keep(selectedAttributes, (function (attr) {
            return attr === attribute;
          })).length !== 0;
    if (!isAttributeAlreadySelected) {
      return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                  /* AddHighlightBarAttribute */Block.__(8, [
                      projectName,
                      sdk,
                      attribute
                    ]),
                  /* [] */0
                ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_HightlightBar_AttributeAdded */112]));
                    return Curry._1(setUserSettingsRemote, (function (param) {
                                  return /* Loading */1;
                                }));
                  }), (function (err) {
                    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugHighlightbar.re/handleAddAttribute", Backtrace$BsConsole.Attributes.make(undefined));
                    Backtrace$BsConsole.Client.send(__x, /* `string */[
                          288368849,
                          "Failed to add highlight bar attribute: " + err
                        ]);
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to add attribute"));
                  }));
    }
    
  };
  var handleRemoveAttribute = function (attribute) {
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* RemoveHighlightBarAttribute */Block.__(9, [
                    projectName,
                    sdk,
                    attribute
                  ]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  return Curry._1(setUserSettingsRemote, (function (param) {
                                return /* Loading */1;
                              }));
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugHighlightbar.re/handleRemoveAttribute", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        "Failed to remove highlight bar attribute: " + err
                      ]);
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to remove attribute"));
                }));
  };
  var tmp;
  tmp = typeof userSettingsRemote === "number" ? (
      userSettingsRemote === /* NotAsked */0 ? React.createElement(BugHighlightBar$Loading, { }) : React.createElement(BugHighlightBar$Loading, { })
    ) : (
      userSettingsRemote.tag ? React.createElement(BugHighlightBar$HighlightBarError, {
              errorMessage: I18N$BsConsole.show(undefined, userSettingsRemote[0])
            }) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: topHighlightsContainer
                }, React.createElement(BugHighlightBar$HighlightbarIcons, {
                      attributes: attributes,
                      projectName: projectName,
                      projectAttributes: describedAttributes.active,
                      onAddFilter: onAddFilter,
                      handleChangeUrl: handleChangeUrl
                    }), React.createElement(BugHighlightBar$ManageHighlightbarAttributes, {
                      describedAttributes: describedAttributes,
                      attributes: attrs,
                      selectedAttributes: selectedAttributes,
                      handleAddAttribute: handleAddAttribute,
                      handleRemoveAttribute: handleRemoveAttribute,
                      showAttributeSearchLoading: match$1[0],
                      setShowAttributeSearchLoading: setShowAttributeSearchLoading
                    })), React.createElement("div", {
                  className: bottomHighlightsContainer
                }, React.createElement(BugHighlightBar$HightlightbarAttributes, {
                      selectedAttributes: selectedAttributes,
                      projectAttributes: describedAttributes.active,
                      projectName: projectName,
                      attributes: attrs,
                      onAddFilter: onAddFilter,
                      handleChangeUrl: handleChangeUrl
                    })))
    );
  return React.createElement("div", {
              className: highlightbarContainer
            }, tmp);
}

var make = BugHighlightBar;

exports.shouldHideHighlightBar = shouldHideHighlightBar;
exports.Styles = Styles;
exports.HighlightBarError = HighlightBarError;
exports.HighlightbarMenuList = HighlightbarMenuList;
exports.IconAttribute = IconAttribute;
exports.Loading = Loading;
exports.ManageHighlightbarAttributes = ManageHighlightbarAttributes;
exports.HighlightAttr = HighlightAttr;
exports.HightlightbarAttributes = HightlightbarAttributes;
exports.HighlightbarIcons = HighlightbarIcons;
exports.make = make;
/* errorIcon Not a pure module */
