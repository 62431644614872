// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Fs = require("fs");

var t = [
  {
    name: "Breakpad",
    qsName: "breakpad",
    fileName: "breakpad.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/breakpad.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/breakpad.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Coresnap",
    qsName: "coresnap",
    fileName: "coresnap.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/coresnap.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/icon.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Crashpad",
    qsName: "crashpad",
    fileName: "crashpad.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/crashpad.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/crashpad.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "CryEngine",
    qsName: "cryengine",
    fileName: "cryengine.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/cryengine.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1620326839/cryengine_eye.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Minidump",
    qsName: "minidump",
    fileName: "minidump.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/minidump.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/minidump.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Unreal",
    qsName: "unreal",
    fileName: "unreal.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/unreal.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/ue4.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "JavaScript",
    qsName: "javascript",
    fileName: "javascript.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/javascript.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/js.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "React",
    qsName: "react",
    fileName: "react.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/react.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/v1694802390/react.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "NodeJS",
    qsName: "nodejs",
    fileName: "nodejs.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/nodejs.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/node.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "NestJS",
    qsName: "nestjs",
    fileName: "nestjs.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/nestjs.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/v1699907794/nestjs.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Electron",
    qsName: "electron",
    fileName: "electron.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/electron.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/electron.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "React Native",
    qsName: "react-native",
    fileName: "react-native.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/react-native.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/v1694802390/react.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "C#",
    qsName: "csharp",
    fileName: "csharp.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/csharp.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/csharp.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "iOS",
    qsName: "ios",
    fileName: "ios.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/ios.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_29/v1557931297/ios.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Android",
    qsName: "android",
    fileName: "android.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/android.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_29/v1557931626/android.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Python",
    qsName: "python",
    fileName: "python.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/python.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/python.png",
    format: /* Language */1,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Unity",
    qsName: "unity",
    fileName: "unity.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/unity.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/unity.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Visual Studio",
    qsName: "visual-studio",
    fileName: "visual-studio.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/visual-studio.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/visual_studio.png",
    format: /* Framework */0,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Address Sanitizer",
    qsName: "address-sanitizer",
    fileName: "asan.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/asan.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1558704991/asan.png",
    format: /* Testing */2,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Dr. Memory",
    qsName: "dr-memory",
    fileName: "drmemory.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/drmemory.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,w_90/v1559061619/doctormemory.png",
    format: /* Testing */2,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Thread Sanitizer",
    qsName: "thread-sanitizer",
    fileName: "tsan.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/tsan.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1559050421/tsan.png",
    format: /* Testing */2,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "JUnit",
    qsName: "junit",
    fileName: "junit.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/junit.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_29/v1558705028/junit-logo.png",
    format: /* Testing */2,
    gated: undefined,
    coronerdVersion: undefined
  },
  {
    name: "Source Maps",
    qsName: "sourcemaps",
    fileName: "sourcemaps.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/sourcemaps.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1548975858/js.png",
    format: /* Symbolication */4,
    gated: undefined,
    coronerdVersion: "1.41.21"
  },
  {
    name: "Proguard",
    qsName: "proguard",
    fileName: "proguard.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/proguard.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_25/v1572030492/progaurd.png",
    format: /* Symbolication */4,
    gated: undefined,
    coronerdVersion: "1.41.21"
  },
  {
    name: "PlayStation 4",
    qsName: "ps4",
    fileName: "ps4.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/ps4.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,w_90/v1584115252/ps4logo.png",
    format: /* DataSources */5,
    gated: "connector",
    coronerdVersion: undefined
  },
  {
    name: "PlayStation 5",
    qsName: "ps5",
    fileName: "ps5.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/ps5.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,w_90/v1584115252/ps5-logo.png",
    format: /* DataSources */5,
    gated: "connector",
    coronerdVersion: undefined
  },
  {
    name: "Nintendo Switch",
    qsName: "switch",
    fileName: "nintendo_switch.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/nintendo_switch.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,h_29/v1584115252/nintendo_switch_logo.png",
    format: /* DataSources */5,
    gated: "connector",
    coronerdVersion: undefined
  },
  {
    name: "Xbox",
    qsName: "xbox_portal",
    fileName: "xbox_portal.md",
    markdown: [/* tuple */[
        "en_US",
        Fs.readFileSync("./src/guides/en_US/xbox_portal.md", "utf8")
      ]],
    iconUrl: "https://res.cloudinary.com/backtrace/image/upload/c_scale,w_90/v1584115252/xbox-logo-green-text.png",
    format: /* DataSources */5,
    gated: "connector",
    coronerdVersion: undefined
  }
];

exports.t = t;
/* t Not a pure module */
