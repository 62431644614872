// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Table$BsConsole = require("./table.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Typeahead$BsConsole = require("./typeahead/Typeahead.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var BpgController$BsConsole = require("./BpgController.js");
var Configuration$BsConsole = require("./configuration.js");
var SettingsDialog$BsConsole = require("./SettingsDialog.js");

function getCount(param) {
  
}

function toString(t$prime) {
  return t$prime.username;
}

var UserTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: undefined,
      placeholder: "[ modify owner ]",
      fuzz: undefined
    });

var smellyWidth = Css.style(/* :: */[
      Css.width(Css.px(500)),
      /* [] */0
    ]);

var errorRoot = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var error = Css.style(/* :: */[
      Css.margin(Css.px(8)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* [] */0
      ]
    ]);

var Styles = {
  smellyWidth: smellyWidth,
  errorRoot: errorRoot,
  error: error
};

var component = ReasonReact.statelessComponent("SettingsProjectsPage-BsConsole__EditProjectDialog");

function make(project, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Project.Modify.make, (function (param, project) {
                                Curry._1(onClose, undefined);
                                return Curry._1(onSuccess, project);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make((function (param) {
                                                  return Curry._1(onClose, undefined);
                                                }), "Transfer ownership", undefined, [ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (state_, _updater) {
                                                            var users = state_.remote;
                                                            if (typeof users === "number") {
                                                              return null;
                                                            }
                                                            if (users.tag) {
                                                              return null;
                                                            }
                                                            var users$1 = users[0];
                                                            return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(smellyWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.EditProjectForm.make, (function (param) {
                                                                                        var values = param.values;
                                                                                        var next = Belt_List.getBy(users$1, (function (user) {
                                                                                                return user.uid === Caml_format.caml_int_of_string(values.owner);
                                                                                              }));
                                                                                        if (next !== undefined) {
                                                                                          return Curry._1(handleSubmit, /* tuple */[
                                                                                                      project,
                                                                                                      (function (project) {
                                                                                                          return {
                                                                                                                  pid: project.pid,
                                                                                                                  universe: project.universe,
                                                                                                                  name: project.name,
                                                                                                                  owner: next.uid,
                                                                                                                  deleted: project.deleted,
                                                                                                                  __state: project.__state,
                                                                                                                  __create: project.__create,
                                                                                                                  __modify: project.__modify
                                                                                                                };
                                                                                                        }),
                                                                                                      Form$BsConsole.Fields.set("owner", values.owner, Form$BsConsole.Fields.empty),
                                                                                                      param.setError,
                                                                                                      param.setSubmitting,
                                                                                                      param.resetFormState
                                                                                                    ]);
                                                                                        } else {
                                                                                          console.log("INVARIANT");
                                                                                          return ;
                                                                                        }
                                                                                      }), undefined, undefined, {
                                                                                      owner: ""
                                                                                    }, /* :: */[
                                                                                      /* tuple */[
                                                                                        /* Owner */-878442477,
                                                                                        /* Required */0
                                                                                      ],
                                                                                      /* [] */0
                                                                                    ], undefined, (function (param) {
                                                                                        var getErrorForField = param.getErrorForField;
                                                                                        var handleSubmit = param.handleSubmit;
                                                                                        var handleChange = param.handleChange;
                                                                                        var form = param.form;
                                                                                        var userName = Belt_List.getBy(users$1, (function (user) {
                                                                                                return String(user.uid) === form.values.owner;
                                                                                              }));
                                                                                        var partial_arg = Curry._1(handleChange, /* Owner */-878442477);
                                                                                        var _msg = state.remote;
                                                                                        var tmp;
                                                                                        tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477)) : true;
                                                                                        var msg = state.remote;
                                                                                        var tmp$1;
                                                                                        tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? I18N$BsConsole.dynamic(Belt_Option.getWithDefault(Curry._1(getErrorForField, /* Owner */-878442477), "")) : I18N$BsConsole.dynamic(msg[0]);
                                                                                        var match = state.remote;
                                                                                        var match$1 = Belt_Option.isSome(Curry._1(getErrorForField, /* Owner */-878442477));
                                                                                        var tmp$2;
                                                                                        var exit = 0;
                                                                                        if (typeof match === "number" || match.tag !== /* Failure */1) {
                                                                                          exit = 1;
                                                                                        } else {
                                                                                          tmp$2 = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                                    root: errorRoot
                                                                                                  }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                                              error: error
                                                                                                            }, undefined, undefined, undefined, [I18N$BsConsole.dynamic(match[0])]))]));
                                                                                        }
                                                                                        if (exit === 1) {
                                                                                          tmp$2 = match$1 ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, {
                                                                                                      root: errorRoot
                                                                                                    }, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, {
                                                                                                                error: error
                                                                                                              }, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Make sure a user is selected by clicking an option")]))])) : null;
                                                                                        }
                                                                                        return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                                                        return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                                      }), /* `String */[
                                                                                                                      -976970511,
                                                                                                                      userName !== undefined ? userName.username : ""
                                                                                                                    ], tmp, tmp$1, I18N$BsConsole.show(undefined, "Transfer ownership"), true, false, undefined, (function (_onClose) {
                                                                                                                        return ReasonReact.element(undefined, undefined, Curry.app(UserTypeahead.make, [
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        users$1,
                                                                                                                                        (function (user) {
                                                                                                                                            return Curry._2(handleChange, /* Owner */-878442477, String(user.uid));
                                                                                                                                          }),
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        undefined,
                                                                                                                                        []
                                                                                                                                      ]));
                                                                                                                      }), undefined, []))])), tmp$2, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (_event) {
                                                                                                                          return Curry._1(onClose, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (_event) {
                                                                                                                          return Curry._1(handleSubmit, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      state.remote === /* Loading */1 || form.values.owner === "",
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      [I18N$BsConsole.show(undefined, "Transfer")]
                                                                                                                    ]))
                                                                                                          ])));
                                                                                      })))]));
                                                          })))]));
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var EditProjectDialog = {
  Styles: Styles,
  component: component,
  make: make
};

function SettingsProjectsPage$EditProjectDialogJsx3(Props) {
  var project = Props.project;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var onClose = Props.onClose;
  var onSuccess = Props.onSuccess;
  return ReasonReact.element(undefined, undefined, make(project, token, handleTask, onClose, onSuccess, []));
}

var EditProjectDialogJsx3 = {
  make: SettingsProjectsPage$EditProjectDialogJsx3
};

var menuCell = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* [] */0
    ]);

var component$1 = ReasonReact.reducerComponent("SettingsProjectsPage-BsConsole");

function make$1(token, config, handleTask, handleChangeUrl, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        undefined,
                                        Css.style(/* :: */[
                                              Css.marginBottom(Css.rem(1)),
                                              /* [] */0
                                            ]),
                                        (function (_event) {
                                            return Curry._1(handleChangeUrl, /* SettingsProjectsNew */9);
                                          }),
                                        undefined,
                                        undefined,
                                        /* Raised */-387916264,
                                        /* Primary */-791844958,
                                        undefined,
                                        undefined,
                                        TeamsUtils$BsConsole.isGuest(config),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [I18N$BsConsole.show(undefined, "Create a new project")]
                                      ])),
                              ReasonReact.element(String(self.state.cacheKey), undefined, Curry._4(BpgController$BsConsole.Project.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                          var projects = state.remote;
                                          if (typeof projects === "number") {
                                            return null;
                                          }
                                          if (projects.tag) {
                                            return null;
                                          }
                                          var projects$1 = projects[0];
                                          if (Configuration$BsConsole.numberOfNonDemoProjects(projects$1) > 0) {
                                            return ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(Configuration$BsConsole.filterOutDemoProjects(projects$1), (function (aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage) {
                                                              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Name")])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Owner")])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                                          ]))])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                                                                                    return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [Curry._1(aperture, $$Array.of_list(List.map((function (project) {
                                                                                                                                    var users = state.remote;
                                                                                                                                    var tmp;
                                                                                                                                    if (typeof users === "number" || users.tag) {
                                                                                                                                      tmp = I18N$BsConsole.showSkip("");
                                                                                                                                    } else {
                                                                                                                                      var __x = Belt_List.getBy(users[0], (function (user) {
                                                                                                                                              return user.uid === project.owner;
                                                                                                                                            }));
                                                                                                                                      tmp = I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(__x, "_", (function (user) {
                                                                                                                                                  return user.username;
                                                                                                                                                })));
                                                                                                                                    }
                                                                                                                                    return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(project.name)])),
                                                                                                                                                    ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [tmp])),
                                                                                                                                                    TeamsUtils$BsConsole.isGuest(config) ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, menuCell, undefined, undefined, undefined, undefined, undefined, [])) : ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, menuCell, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                                                                            return [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                                                                Curry._1(self.send, /* Dialog */[/* EditProject */[project]]);
                                                                                                                                                                                                return Curry._1(onClose, undefined);
                                                                                                                                                                                              }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Transfer ownership")]))];
                                                                                                                                                                          }), undefined, []))]))
                                                                                                                                                  ]));
                                                                                                                                  }), Configuration$BsConsole.filterOutDemoProjects(projects$1))))]));
                                                                                                  }))),
                                                                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            onChangePage,
                                                                                                            undefined,
                                                                                                            onChangeRowsPerPage,
                                                                                                            /* `Int */[
                                                                                                              3654863,
                                                                                                              page
                                                                                                            ],
                                                                                                            [
                                                                                                              25,
                                                                                                              50,
                                                                                                              100
                                                                                                            ],
                                                                                                            /* `Int */[
                                                                                                              3654863,
                                                                                                              List.length(projects$1)
                                                                                                            ],
                                                                                                            /* `Int */[
                                                                                                              3654863,
                                                                                                              rowsPerPage
                                                                                                            ],
                                                                                                            /* `Int */[
                                                                                                              3654863,
                                                                                                              6
                                                                                                            ],
                                                                                                            undefined,
                                                                                                            undefined,
                                                                                                            []
                                                                                                          ]))]))
                                                                                      ]))]));
                                                            })));
                                          } else {
                                            return null;
                                          }
                                        }))),
                              match !== undefined ? ReasonReact.element(undefined, undefined, make(match[0], token, handleTask, (function (param) {
                                            return Curry._1(self.send, /* Dialog */[undefined]);
                                          }), (function (_project) {
                                            return Curry._1(self.send, /* BustCache */0);
                                          }), [])) : null
                            ]));
            }),
          initialState: (function (param) {
              return {
                      cacheKey: 0,
                      dialog: undefined
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey,
                            dialog: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey + 1 | 0,
                            dialog: state.dialog
                          }]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.UserTypeahead = UserTypeahead;
exports.EditProjectDialog = EditProjectDialog;
exports.EditProjectDialogJsx3 = EditProjectDialogJsx3;
exports.menuCell = menuCell;
exports.component = component$1;
exports.make = make$1;
/* UserTypeahead Not a pure module */
