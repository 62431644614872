// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");

var metricsNamespace = "deduplication/";

function toEvent(action) {
  switch (action) {
    case /* RulesetCreated */0 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_created",
                    payload: undefined
                  }]);
    case /* RulesetCreationFailed */1 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_creation_failed",
                    payload: undefined
                  }]);
    case /* RulesetUpdated */2 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_updated",
                    payload: undefined
                  }]);
    case /* RulesetUpdateFailed */3 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_update_failed",
                    payload: undefined
                  }]);
    case /* RulesetDeleted */4 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_deleted",
                    payload: undefined
                  }]);
    case /* RulesetDeleteFailed */5 :
        return /* GenericEvent */Block.__(2, [{
                    name: "deduplication/ruleset_delete_failed",
                    payload: undefined
                  }]);
    
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
