// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");

function renderRoute(limitsRemote, config, whenFrozen, whenNotFrozen) {
  if (typeof limitsRemote === "number") {
    return null;
  }
  if (limitsRemote.tag) {
    return null;
  }
  var limitsMaybe = limitsRemote[0];
  if (limitsMaybe === undefined) {
    return Curry._1(whenNotFrozen, undefined);
  }
  var nemesisTrial = limitsMaybe.metadata.nemesisTrial;
  if (nemesisTrial === undefined) {
    return Curry._1(whenNotFrozen, undefined);
  }
  var uids = nemesisTrial.frozenUids;
  if (uids !== undefined && Belt_List.has(uids, config.user.uid, (function (a, b) {
            return a === b;
          }))) {
    return Curry._1(whenFrozen, undefined);
  } else {
    return Curry._1(whenNotFrozen, undefined);
  }
}

exports.renderRoute = renderRoute;
/* No side effect */
