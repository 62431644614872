// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Regex$BsConsole = require("../Regex.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var ApiWfTickets$BsConsole = require("../workflow/workflows-api/ApiWfTickets.js");
var WorkflowVersion$BsConsole = require("../workflow/WorkflowVersion.js");
var InboxUnlinkedTicket$BsConsole = require("./InboxUnlinkedTicket.js");

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetUrl */0 :
        var url = action[0];
        var urlWithoutQS = url.replace(Regex$BsConsole.matchURLQueryStringParams, "");
        var result = Regex$BsConsole.matchURLLastRoute.exec(urlWithoutQS);
        if (result === null) {
          return {
                  url: url,
                  short: state.short,
                  maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
                };
        }
        var $$short = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(result, 1), (function (prim) {
                    if (prim == null) {
                      return ;
                    } else {
                      return Caml_option.some(prim);
                    }
                  })), "");
        return {
                url: url,
                short: $$short,
                maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
              };
    case /* SetShort */1 :
        return {
                url: state.url,
                short: action[0],
                maybeLinkedIntegrationId: state.maybeLinkedIntegrationId
              };
    case /* SetLinkedIntegrationId */2 :
        return {
                url: state.url,
                short: state.short,
                maybeLinkedIntegrationId: action[0]
              };
    
  }
}

function InboxUnlinkedTicketJsx3(Props) {
  var onSubmit = Props.onSubmit;
  var onClose = Props.onClose;
  var projectName = Props.projectName;
  var workflows = Props.workflows;
  var config = Props.config;
  var token = Props.token;
  var fetchIssues = Props.fetchIssues;
  var selectedFingerprints = Props.selectedFingerprints;
  var match = React.useReducer(reducer, undefined, (function (param) {
          return {
                  url: "",
                  short: "",
                  maybeLinkedIntegrationId: undefined
                };
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var maybeLinkedIntegrationId = match$1.maybeLinkedIntegrationId;
  var $$short = match$1.short;
  var url = match$1.url;
  return React.createElement(InboxUnlinkedTicket$BsConsole.Jsx3Compat.make, {
              url: url,
              short: $$short,
              open_: true,
              projectName: projectName,
              onUrlChange: (function (url$prime) {
                  return Curry._1(dispatch, /* SetUrl */Block.__(0, [url$prime]));
                }),
              onSubmit: (function (param) {
                  var match = WorkflowVersion$BsConsole.current(config);
                  if (typeof match === "number") {
                    return Curry._1(onSubmit, Issues$BsConsole.Ticket.makeUnlinked($$short, url));
                  } else {
                    return ApiWfTickets$BsConsole.setIssueTickets(config, token, fetchIssues, selectedFingerprints, projectName, url, $$short, maybeLinkedIntegrationId, (function (param) {
                                  return Curry._1(onClose, undefined);
                                }));
                  }
                }),
              onShortChange: (function (short$prime) {
                  return Curry._1(dispatch, /* SetShort */Block.__(1, [short$prime]));
                }),
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              workflows: workflows,
              maybeLinkedIntegrationId: maybeLinkedIntegrationId,
              onLinkedIntegrationIdChange: (function (maybeLinkedIntegrationId$prime) {
                  return Curry._1(dispatch, /* SetLinkedIntegrationId */Block.__(2, [maybeLinkedIntegrationId$prime]));
                })
            });
}

var make = InboxUnlinkedTicketJsx3;

exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
