// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");
var Colors$BsConsole = require("../Colors.js");
var InboxUtils$BsConsole = require("../inbox/InboxUtils.js");

function MakeFuzz(F) {
  var fuzz = function (maybeSearch, l) {
    if (maybeSearch === undefined) {
      return l;
    }
    var __x = $$Array.mapi((function (i, e) {
            return {
                    q: InboxUtils$BsConsole.getFriendlyName(Curry._1(F.toString, e)),
                    index: i
                  };
          }), $$Array.of_list(l));
    var results = FuzzaldrinPlus.filter(__x, maybeSearch, {
          key: "q"
        });
    if (results.length !== 0) {
      return $$Array.to_list($$Array.map((function (index) {
                        return List.nth(l, index);
                      }), $$Array.map((function (r) {
                            return r.index;
                          }), results)));
    }
    
  };
  return {
          fuzz: fuzz
        };
}

function Make(F) {
  var fuzz = function (maybeSearch, l) {
    if (maybeSearch === undefined) {
      return l;
    }
    var __x = $$Array.mapi((function (i, e) {
            return {
                    q: InboxUtils$BsConsole.getFriendlyName(Curry._1(F.toString, e)),
                    index: i
                  };
          }), $$Array.of_list(l));
    var results = FuzzaldrinPlus.filter(__x, maybeSearch, {
          key: "q"
        });
    if (results.length !== 0) {
      return $$Array.to_list($$Array.map((function (index) {
                        return List.nth(l, index);
                      }), $$Array.map((function (r) {
                            return r.index;
                          }), results)));
    }
    
  };
  var Fuzz = {
    fuzz: fuzz
  };
  var root = Css.style(/* :: */[
        Css.position(/* relative */903134412),
        /* [] */0
      ]);
  var rootList = Css.style(/* :: */[
        Css.padding(/* zero */-789508312),
        /* [] */0
      ]);
  var attrList = Css.style(/* :: */[
        Css.unsafe("outline", "none"),
        /* [] */0
      ]);
  var input = Css.style(/* :: */[
        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey625)),
        /* [] */0
      ]);
  var subList = Css.style(/* :: */[
        Css.padding(/* zero */-789508312),
        /* [] */0
      ]);
  var Styles = {
    root: root,
    rootList: rootList,
    attrList: attrList,
    input: input,
    subList: subList
  };
  var component = ReasonReact.reducerComponent("TypeAhead");
  var make = function (disabledOpt, onBlur, considerations, onChange, onUnfoundConsiderationChangeOpt, onDeleteOpt, initialValueOpt, attrListClassNameOpt, onEditOpt, _children) {
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var onUnfoundConsiderationChange = onUnfoundConsiderationChangeOpt !== undefined ? onUnfoundConsiderationChangeOpt : (function (param) {
          
        });
    var onDelete = onDeleteOpt !== undefined ? onDeleteOpt : (function (param) {
          
        });
    var initialValue = initialValueOpt !== undefined ? initialValueOpt : "";
    var attrListClassName = attrListClassNameOpt !== undefined ? attrListClassNameOpt : "";
    var onEdit = onEditOpt !== undefined ? onEditOpt : (function (param) {
          
        });
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: (function (self) {
                if (initialValue === "") {
                  return ;
                }
                Curry._1(self.send, /* Search */Block.__(0, [initialValue]));
                var f = F.fuzz;
                var nextConsiderations = Belt_Option.getWithDefault(f !== undefined ? Curry._2(f, initialValue, considerations) : fuzz(initialValue, considerations), /* [] */0);
                Curry._1(self.send, /* SetConsiderations */Block.__(1, [nextConsiderations]));
                return Curry._1(self.send, /* SetConsiderationIdx */Block.__(2, [0]));
              }),
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var handleSelection = function (idx) {
                  var maybeValue = Belt_List.get(self.state.considerations, idx);
                  if (maybeValue !== undefined) {
                    var value = Caml_option.valFromOption(maybeValue);
                    Curry._1(self.send, /* SetInputValue */Block.__(3, [Curry._1(F.toString, value)]));
                    return Curry._1(onChange, value);
                  }
                  var search = self.state.search;
                  if (search !== undefined) {
                    Curry._1(self.send, /* SetInputValue */Block.__(3, [search]));
                    return Curry._1(onUnfoundConsiderationChange, search);
                  }
                  
                };
                return React.createElement("div", {
                            onKeyDown: (function ($$event) {
                                var keycode = $$event.keyCode;
                                if (keycode === 8) {
                                  if (self.state.value === "") {
                                    return Curry._1(onDelete, undefined);
                                  } else {
                                    return ;
                                  }
                                }
                                if (keycode < 38) {
                                  if (keycode !== 13) {
                                    return ;
                                  } else {
                                    return handleSelection(self.state.considerationIdx);
                                  }
                                }
                                if (keycode >= 41) {
                                  return ;
                                }
                                switch (keycode - 38 | 0) {
                                  case 0 :
                                      return Curry._1(self.send, /* KeyUp */0);
                                  case 1 :
                                      return ;
                                  case 2 :
                                      return Curry._1(self.send, /* KeyDown */1);
                                  
                                }
                              })
                          }, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, rootList, [
                                    ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, input, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        /* `String */[
                                                          -976970511,
                                                          self.state.value
                                                        ],
                                                        (function (e) {
                                                            var search = e.target.value;
                                                            Curry._1(self.send, /* Search */Block.__(0, [search]));
                                                            if (search.length > 0) {
                                                              var f = F.fuzz;
                                                              var nextConsiderations = Belt_Option.getWithDefault(f !== undefined ? Curry._2(f, search, considerations) : fuzz(search, considerations), /* [] */0);
                                                              Curry._1(self.send, /* SetConsiderations */Block.__(1, [nextConsiderations]));
                                                              Curry._1(self.send, /* SetConsiderationIdx */Block.__(2, [0]));
                                                            } else {
                                                              Curry._1(self.send, /* SetConsiderations */Block.__(1, [considerations]));
                                                              Curry._1(self.send, /* SetConsiderationIdx */Block.__(2, [0]));
                                                            }
                                                            return Curry._1(onEdit, search);
                                                          }),
                                                        F.placeholder,
                                                        true,
                                                        true,
                                                        true,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        disabled,
                                                        onBlur,
                                                        undefined,
                                                        F.placeholder,
                                                        "off",
                                                        []
                                                      ]))])),
                                    List.length(self.state.considerations) > 0 ? ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, subList, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, {
                                                          root: attrList + (" " + attrListClassName)
                                                        }, undefined, [$$Array.of_list(List.mapi((function (i, consideration) {
                                                                      var renderItem = F.renderItem;
                                                                      var tmp;
                                                                      if (renderItem !== undefined) {
                                                                        tmp = Curry._1(renderItem, consideration);
                                                                      } else {
                                                                        var count = Curry._1(F.getCount, consideration);
                                                                        tmp = React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.showSkip(InboxUtils$BsConsole.getFriendlyName(Curry._1(F.toString, consideration)))), Caml_option.some(count !== undefined ? I18N$BsConsole.showSkip(String(count)) : null), [])));
                                                                      }
                                                                      return React.createElement("div", {
                                                                                  key: Curry._1(F.toString, consideration) + String(i),
                                                                                  ref: (function (el) {
                                                                                      if (!(el == null) && self.state.considerationIdx === i) {
                                                                                        el.scrollIntoView();
                                                                                      }
                                                                                      
                                                                                    }),
                                                                                  onClick: (function (param) {
                                                                                      Curry._1(self.send, /* SetConsiderationIdx */Block.__(2, [i]));
                                                                                      return handleSelection(i);
                                                                                    })
                                                                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                                          -976970511,
                                                                                          Curry._1(F.toString, consideration)
                                                                                        ], undefined, self.state.considerationIdx === i, [tmp])));
                                                                    }), self.state.considerations))]))])) : (
                                        disabled ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "No match")), []))]))
                                      )
                                  ])));
              }),
            initialState: (function (param) {
                return {
                        search: undefined,
                        considerations: considerations,
                        considerationIdx: 0,
                        value: ""
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, state) {
                if (typeof action === "number") {
                  if (action === /* KeyUp */0) {
                    return /* Update */Block.__(0, [{
                                search: state.search,
                                considerations: state.considerations,
                                considerationIdx: state.considerationIdx === 0 ? List.length(considerations) - 1 | 0 : state.considerationIdx - 1 | 0,
                                value: state.value
                              }]);
                  } else {
                    return /* Update */Block.__(0, [{
                                search: state.search,
                                considerations: state.considerations,
                                considerationIdx: state.considerationIdx === (List.length(considerations) - 1 | 0) ? 0 : state.considerationIdx + 1 | 0,
                                value: state.value
                              }]);
                  }
                }
                switch (action.tag | 0) {
                  case /* Search */0 :
                      var value = action[0];
                      return /* Update */Block.__(0, [{
                                  search: value,
                                  considerations: state.considerations,
                                  considerationIdx: state.considerationIdx,
                                  value: value
                                }]);
                  case /* SetConsiderations */1 :
                      return /* Update */Block.__(0, [{
                                  search: state.search,
                                  considerations: action[0],
                                  considerationIdx: state.considerationIdx,
                                  value: state.value
                                }]);
                  case /* SetConsiderationIdx */2 :
                      return /* Update */Block.__(0, [{
                                  search: state.search,
                                  considerations: state.considerations,
                                  considerationIdx: action[0],
                                  value: state.value
                                }]);
                  case /* SetInputValue */3 :
                      return /* Update */Block.__(0, [{
                                  search: state.search,
                                  considerations: state.considerations,
                                  considerationIdx: state.considerationIdx,
                                  value: action[0]
                                }]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          Fuzz: Fuzz,
          Styles: Styles,
          component: component,
          make: make
        };
}

exports.MakeFuzz = MakeFuzz;
exports.Make = Make;
/* Css Not a pure module */
