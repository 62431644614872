// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var NavBreadcrumb$BsConsole = require("./NavBreadcrumb.js");
var Toolbar = require("@material-ui/core/Toolbar");

var toolbar = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
            /* :: */[
              Css.position(/* sticky */188263721),
              /* :: */[
                Css.top(Css.px(64)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* :: */[
                    Css.zIndex(1001),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  toolbar: toolbar
};

function getFriendlyNameForRoute(route) {
  if (typeof route !== "number") {
    if (route.tag === /* SettingsWfConnectionManagement */6) {
      return "Workflow connections";
    } else {
      return ;
    }
  }
  switch (route) {
    case /* SettingsMe */5 :
        return "My account";
    case /* SettingsUsers */6 :
        return "Users";
    case /* SettingsTeams */7 :
        return "Teams";
    case /* SettingsProjects */8 :
        return "Projects";
    case /* SettingsProjectsFork */10 :
        return "Fork";
    case /* SettingsInvites */11 :
        return "Invitations";
    case /* SettingsProjectsNew */9 :
    case /* SettingsInvitesNew */12 :
        return "New";
    case /* SettingsNetworking */13 :
        return "Server settings";
    case /* SettingsRetentionPolicy */14 :
        return "Storage retention";
    case /* SettingsNetworkingSSL */15 :
        return "SSL";
    case /* SettingsNetworkingListeners */16 :
        return "Listeners";
    case /* SettingsWhitelist */17 :
        return "Allowlists";
    case /* SettingsBilling */18 :
        return "Billing";
    case /* SettingsSubscriptions */20 :
        return "Subscriptions";
    case /* SettingsSamlConfig */21 :
        return "Single sign-on";
    default:
      return ;
  }
}

function getProjectRoutingTuple(config) {
  var projectFromSessionStorage = window.sessionStorage.getItem("_Backtrace_last_project");
  var lastProjectEphemeral = Belt_Option.flatMap(config.lastProjectPid, (function (projectPid) {
          return Belt_List.getBy(config.projects, (function (project) {
                        return project.pid === projectPid;
                      }));
        }));
  var firstProjectInProjectsList = Belt_List.get(config.projects, 0);
  if (projectFromSessionStorage == null) {
    if (lastProjectEphemeral !== undefined) {
      return /* tuple */[
              lastProjectEphemeral.name,
              /* Project */Block.__(7, [lastProjectEphemeral.name])
            ];
    } else if (firstProjectInProjectsList !== undefined) {
      return /* tuple */[
              firstProjectInProjectsList.name,
              /* Project */Block.__(7, [firstProjectInProjectsList.name])
            ];
    } else {
      return /* tuple */[
              I18N$BsConsole.get(undefined, "Go back to projects"),
              /* CreateFirstProject */2
            ];
    }
  } else {
    return /* tuple */[
            projectFromSessionStorage,
            /* Project */Block.__(7, [projectFromSessionStorage])
          ];
  }
}

function getTrailForRoute(config, route) {
  var goBackCrumb = getProjectRoutingTuple(config);
  var universeCrumb_000 = config.universe.name;
  var universeCrumb = /* tuple */[
    universeCrumb_000,
    /* SettingsMe */5
  ];
  var defaultCrumbTrail = [
    goBackCrumb,
    universeCrumb
  ];
  if (typeof route !== "number") {
    if (route.tag === /* SettingsWfConnectionManagement */6) {
      return defaultCrumbTrail;
    } else {
      return Js_exn.raiseError("Invalid route");
    }
  }
  switch (route) {
    case /* SettingsProjectsNew */9 :
    case /* SettingsProjectsFork */10 :
        return Belt_Array.concat(defaultCrumbTrail, [/* tuple */[
                      "Projects",
                      /* SettingsProjects */8
                    ]]);
    case /* SettingsNetworkingSSL */15 :
    case /* SettingsNetworkingListeners */16 :
        return Belt_Array.concat(defaultCrumbTrail, [/* tuple */[
                      "Server settings",
                      /* SettingsNetworking */13
                    ]]);
    case /* SettingsInvites */11 :
    case /* SettingsInvitesNew */12 :
    case /* SettingsWhitelist */17 :
        break;
    case /* SettingsSubscriptions */20 :
        return Belt_Array.concat(defaultCrumbTrail, [/* tuple */[
                      "Billing",
                      /* SettingsBilling */18
                    ]]);
    case /* SettingsMe */5 :
    case /* SettingsUsers */6 :
    case /* SettingsTeams */7 :
    case /* SettingsProjects */8 :
    case /* SettingsNetworking */13 :
    case /* SettingsRetentionPolicy */14 :
    case /* SettingsBilling */18 :
    case /* SettingsSamlConfig */21 :
        return defaultCrumbTrail;
    default:
      return Js_exn.raiseError("Invalid route");
  }
  return Belt_Array.concat(defaultCrumbTrail, [/* tuple */[
                "Users",
                /* SettingsUsers */6
              ]]);
}

function OrgSettingsNavBreadcrumbToolbar(Props) {
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  if (IFrame$BsConsole.isIFrame) {
    return null;
  } else {
    return React.createElement(Toolbar.default, {
                className: toolbar,
                children: React.createElement(NavBreadcrumb$BsConsole.make, {
                      current: Belt_Option.getWithDefault(getFriendlyNameForRoute(route), "My account"),
                      trail: getTrailForRoute(config, route),
                      handleChangeUrl: handleChangeUrl
                    })
              });
  }
}

function make(route, handleChangeUrl, config, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(OrgSettingsNavBreadcrumbToolbar, {
              route: route,
              handleChangeUrl: handleChangeUrl,
              config: config
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = OrgSettingsNavBreadcrumbToolbar;

exports.Style = Style;
exports.getFriendlyNameForRoute = getFriendlyNameForRoute;
exports.getProjectRoutingTuple = getProjectRoutingTuple;
exports.getTrailForRoute = getTrailForRoute;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* toolbar Not a pure module */
