// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var OrderedCard$BsConsole = require("./OrderedCard.js");
var BeautifulDnd$BsConsole = require("../bindings/BeautifulDnd.js");

function updateArrayItemPosition(array, from, to_) {
  var otherItems = Belt_Array.concat(Belt_Array.slice(array, 0, from), Belt_Array.slice(array, from + 1 | 0, array.length));
  return Belt_Array.concat(Belt_Array.concat(Belt_Array.slice(otherItems, 0, to_), [Belt_Array.getExn(array, from)]), Belt_Array.slice(otherItems, to_, array.length));
}

var component = RR$BsConsole.reducerComponent("OrderedCardList-BsConsole");

function make(cardListOpt, handleOrderUpdate, showIconsAlwaysOpt, _children) {
  var cardList = cardListOpt !== undefined ? cardListOpt : /* [] */0;
  var showIconsAlways = showIconsAlwaysOpt !== undefined ? showIconsAlwaysOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var hovered = param.state.hovered;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.DragDropContext.make((function (e) {
                                    if (hovered) {
                                      return Curry._1(send, /* MoveAction */[
                                                  e.source.index,
                                                  e.destination.index
                                                ]);
                                    }
                                    
                                  }), [ReasonReact.element(undefined, undefined, BeautifulDnd$BsConsole.Droppable.make("droppable-symbol-servers", (function (provided, param) {
                                              var __x = Belt_List.toArray(cardList);
                                              return React.createElement("div", {
                                                          ref: provided.innerRef
                                                        }, Belt_Array.mapWithIndex(__x, (function (index, card) {
                                                                return React.createElement("span", {
                                                                            key: "orderedcardlist-" + card.dataKey,
                                                                            onMouseEnter: (function (param) {
                                                                                return Curry._1(send, /* MouseEnter */0);
                                                                              }),
                                                                            onMouseLeave: (function (param) {
                                                                                return Curry._1(send, /* MouseLeave */1);
                                                                              })
                                                                          }, ReasonReact.element("draggablelistcard-" + card.dataKey, undefined, BeautifulDnd$BsConsole.Draggable.make(card.dataKey, index, !hovered, (function (provided, snapshot) {
                                                                                      return React.cloneElement(React.createElement("div", {
                                                                                                      ref: provided.innerRef
                                                                                                    }, ReasonReact.element(card.dataKey, undefined, OrderedCard$BsConsole.make(card.title, card.subtitle, card.infoLeft, card.infoRight, card.handleTitleUpdate, card.onEditEnabled, card.onEditDisabled, card.editTitleText, card.saveTitleText, card.cancelEditText, card.deleteCardText, card.deleteAction, card.onCardClick, card.moreActions, provided.dragHandleProps, hovered, snapshot.isDragging, card.view, card.cardDisabled, showIconsAlways, []))), provided.draggableProps);
                                                                                    }))));
                                                              })), provided.placeholder);
                                            })))])));
            }),
          initialState: (function (param) {
              return {
                      hovered: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              hovered: false
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              hovered: true
                            }]);
                }
              }
              var to_ = action[1];
              var from = action[0];
              return /* SideEffects */Block.__(1, [(function (param) {
                            return Curry._1(handleOrderUpdate, Belt_List.fromArray(updateArrayItemPosition(Belt_List.toArray(cardList), from, to_)));
                          })]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.updateArrayItemPosition = updateArrayItemPosition;
exports.component = component;
exports.make = make;
/* component Not a pure module */
