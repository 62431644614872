// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var Fuzz$BsConsole = require("../bindings/Fuzz.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Button = require("@material-ui/core/Button");
var Divider = require("@material-ui/core/Divider");
var Checkbox = require("@material-ui/core/Checkbox");
var InputLabel = require("@material-ui/core/InputLabel");
var AddCircle = require("@material-ui/icons/AddCircle");

function BtTypeaheadInput$CreateNewRow(Props) {
  var handleCreateNewItem = Props.handleCreateNewItem;
  var createNewItemMessage = Props.createNewItemMessage;
  return React.createElement(React.Fragment, undefined, handleCreateNewItem !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Divider.default, { }), React.createElement("div", undefined, React.createElement(Button.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.padding(Css.em(0.1)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            onClick: (function (param) {
                                return Curry._1(handleCreateNewItem, undefined);
                              }),
                            children: React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.display(/* flex */-1010954439),
                                          /* :: */[
                                            Css.height(Css.px(38)),
                                            /* :: */[
                                              Css.cursor(/* pointer */-786317123),
                                              /* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.blue)),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* :: */[
                                                    Css.justifyContent(/* flexStart */662439529),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ])
                                }, React.createElement("div", {
                                      className: "smaller-icon " + Css.style(/* :: */[
                                            Css.height(Css.px(16)),
                                            /* :: */[
                                              Css.marginLeft(Css.px(8)),
                                              /* :: */[
                                                Css.marginRight(Css.px(8)),
                                                /* [] */0
                                              ]
                                            ]
                                          ])
                                    }, React.createElement(AddCircle.default, { })), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.height(Css.px(18)),
                                            /* [] */0
                                          ])
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Subtitle2 */7,
                                          children: createNewItemMessage !== undefined ? I18N$BsConsole.showSkip(createNewItemMessage) : I18N$BsConsole.show(undefined, "Create")
                                        })))
                          }))) : null);
}

var CreateNewRow = {
  make: BtTypeaheadInput$CreateNewRow
};

function BtTypeaheadInput$Single(Props) {
  Props.selectedItem;
  var toString = Props.toString;
  var toId = Props.toId;
  var inputLabel = Props.inputLabel;
  var placeholder = Props.placeholder;
  var noItemsMessage = Props.noItemsMessage;
  var createNewItemMessage = Props.createNewItemMessage;
  var items = Props.items;
  var itemFilterOpt = Props.itemFilter;
  var pixelWidth = Props.pixelWidth;
  var setPositionFixedOpt = Props.setPositionFixed;
  var handleItemSelection = Props.handleItemSelection;
  var handleCreateNewItem = Props.handleCreateNewItem;
  var disabledOpt = Props.disabled;
  var itemFilter = itemFilterOpt !== undefined ? itemFilterOpt : (function (param) {
        return true;
      });
  var setPositionFixed = setPositionFixedOpt !== undefined ? setPositionFixedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState((function () {
          return false;
        }));
  var setTypeahead = match[1];
  var typeahead = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setSearchString = match$1[1];
  var searchString = match$1[0];
  var filteredItems = Belt_Array.keep(items, itemFilter);
  var filteredItems$1 = searchString === "" ? filteredItems : Fuzz$BsConsole.fuzz(searchString, filteredItems, Curry.__1(toString));
  var w = pixelWidth !== undefined ? Css.px(pixelWidth) : Css.pct(100);
  var closeTypeahead = function (item) {
    Curry._1(setTypeahead, (function (param) {
            return false;
          }));
    Curry._1(setSearchString, (function (param) {
            return Curry._1(toString, item);
          }));
    return Curry._1(handleItemSelection, item);
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(Css.relative),
                    /* [] */0
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      if (disabled) {
                        return ;
                      } else {
                        return Curry._1(setTypeahead, (function (param) {
                                      return !typeahead;
                                    }));
                      }
                    })
                }, inputLabel !== undefined ? React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ])
                        },
                        htmlFor: inputLabel,
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.showSkip(inputLabel)
                            })
                      }) : null, React.createElement("div", undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                          placeholder: placeholder,
                          value: searchString,
                          onChange: (function (e) {
                              var value = BtSettings$BsConsole.getInputValue(e);
                              Curry._1(setSearchString, (function (param) {
                                      return value;
                                    }));
                              return Curry._1(setTypeahead, (function (param) {
                                            return true;
                                          }));
                            }),
                          disabled: disabled || items.length === 0,
                          className: Css.style(/* :: */[
                                Css.width(w),
                                /* :: */[
                                  Css.important(Css.marginBottom(Css.px(0))),
                                  /* [] */0
                                ]
                              ])
                        }))), typeahead ? React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.zIndex(10000),
                          /* :: */[
                            Css.position(setPositionFixed ? /* fixed */10615156 : /* absolute */-1013592457),
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(Col2$BsConsole.make, {
                        children: items.length === 0 ? React.createElement(Paper.default, {
                                children: null
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.width(w),
                                          /* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.flexDirection(/* column */-963948842),
                                              /* :: */[
                                                Css.padding(Css.px(10)),
                                                /* :: */[
                                                  Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                                  /* :: */[
                                                    Css.borderRadius(Css.px(6)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Overline */12,
                                        children: I18N$BsConsole.showSkip(noItemsMessage)
                                      })), React.createElement(BtTypeaheadInput$CreateNewRow, {
                                    handleCreateNewItem: handleCreateNewItem,
                                    createNewItemMessage: createNewItemMessage
                                  })) : React.createElement(Paper.default, {
                                children: null
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.maxHeight(Css.px(145)),
                                          /* :: */[
                                            Css.width(w),
                                            /* :: */[
                                              Css.overflowY(/* auto */-1065951377),
                                              /* :: */[
                                                Css.overflowX(/* hidden */-862584982),
                                                /* :: */[
                                                  Css.display(/* flex */-1010954439),
                                                  /* :: */[
                                                    Css.flexDirection(/* column */-963948842),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ])
                                  }, items.length === 0 ? React.createElement("div", undefined, I18N$BsConsole.showSkip(noItemsMessage)) : Belt_Array.map(filteredItems$1, (function (item) {
                                            return React.createElement("div", {
                                                        key: toId !== undefined ? Curry._1(toId, item) : Curry._1(toString, item),
                                                        className: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.paddingLeft(Css.px(12)),
                                                                /* :: */[
                                                                  Css.whiteSpace(/* nowrap */867913355),
                                                                  /* :: */[
                                                                    Css.textOverflow(/* ellipsis */166888785),
                                                                    /* :: */[
                                                                      Css.hover(/* :: */[
                                                                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
                                                                            /* [] */0
                                                                          ]),
                                                                      /* :: */[
                                                                        Css.cursor(/* pointer */-786317123),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]),
                                                        role: "button",
                                                        tabIndex: 0,
                                                        onKeyDown: (function ($$event) {
                                                            var keyCode = $$event.keyCode;
                                                            if (keyCode === 13) {
                                                              return closeTypeahead(item);
                                                            }
                                                            
                                                          }),
                                                        onClick: (function (param) {
                                                            return closeTypeahead(item);
                                                          })
                                                      }, React.createElement(BtTypography$BsConsole.make, {
                                                            variant: /* Caption */11,
                                                            noWrap: true,
                                                            children: I18N$BsConsole.showSkip(Curry._1(toString, item))
                                                          }));
                                          }))), React.createElement(BtTypeaheadInput$CreateNewRow, {
                                    handleCreateNewItem: handleCreateNewItem,
                                    createNewItemMessage: createNewItemMessage
                                  }))
                      })) : null);
}

var Single = {
  make: BtTypeaheadInput$Single
};

function BtTypeaheadInput$Multi(Props) {
  var selectedItems = Props.selectedItems;
  var items = Props.items;
  var toString = Props.toString;
  var toId = Props.toId;
  var actionWordOpt = Props.actionWord;
  var singleItemNameOpt = Props.singleItemName;
  var pluralItemNameOpt = Props.pluralItemName;
  var onActionOpt = Props.onAction;
  var placeholder = Props.placeholder;
  var noItemsMessage = Props.noItemsMessage;
  var inputLabel = Props.inputLabel;
  var itemFilterOpt = Props.itemFilter;
  var pixelWidth = Props.pixelWidth;
  var handleAllItemsAfterChange = Props.handleAllItemsAfterChange;
  var handleItemAfterChange = Props.handleItemAfterChange;
  var cancelButtonTextOpt = Props.cancelButtonText;
  var onCancelOpt = Props.onCancel;
  var hideButtonsOpt = Props.hideButtons;
  var actionWord = actionWordOpt !== undefined ? actionWordOpt : I18N$BsConsole.get(undefined, "Add");
  var singleItemName = singleItemNameOpt !== undefined ? singleItemNameOpt : I18N$BsConsole.get(undefined, "item");
  var pluralItemName = pluralItemNameOpt !== undefined ? pluralItemNameOpt : I18N$BsConsole.get(undefined, "items");
  var onAction = onActionOpt !== undefined ? onActionOpt : (function (param) {
        
      });
  var itemFilter = itemFilterOpt !== undefined ? itemFilterOpt : (function (param) {
        return true;
      });
  var cancelButtonText = cancelButtonTextOpt !== undefined ? cancelButtonTextOpt : I18N$BsConsole.get(undefined, "Cancel");
  var onCancel = onCancelOpt !== undefined ? onCancelOpt : (function (param) {
        
      });
  var hideButtons = hideButtonsOpt !== undefined ? hideButtonsOpt : false;
  var match = React.useState((function () {
          return "";
        }));
  var setSearchString = match[1];
  var searchString = match[0];
  var filteredItems = Belt_Array.keep(items, itemFilter);
  var filteredItems$1 = searchString === "" ? filteredItems : Fuzz$BsConsole.fuzz(searchString, filteredItems, Curry.__1(toString));
  var w = pixelWidth !== undefined ? Css.px(pixelWidth) : Css.pct(100);
  var tmp;
  if (hideButtons) {
    tmp = null;
  } else {
    var match$1 = selectedItems.length;
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* flexEnd */924268066,
          className: Css.style(/* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]),
          children: null
        }, React.createElement(Button.default, {
              variant: "contained",
              color: "primary",
              classes: {
                root: Css.style(/* :: */[
                      Css.marginTop(Css.px(10)),
                      /* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* :: */[
                          Css.marginRight(Css.px(10)),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              onClick: (function (param) {
                  return Curry._1(onCancel, undefined);
                }),
              children: I18N$BsConsole.showSkip(cancelButtonText)
            }), React.createElement(Button.default, {
              variant: "contained",
              color: "primary",
              disabled: selectedItems.length === 0,
              classes: {
                root: Css.style(/* :: */[
                      Css.marginTop(Css.px(10)),
                      /* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* :: */[
                          Css.width(Css.px(110)),
                          /* :: */[
                            Css.marginRight(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              },
              onClick: (function (param) {
                  return Curry._1(onAction, selectedItems);
                }),
              children: match$1 !== 0 ? (
                  match$1 !== 1 ? Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* Char_literal */Block.__(12, [
                                      /* " " */32,
                                      /* Int */Block.__(4, [
                                          /* Int_d */0,
                                          /* No_padding */0,
                                          /* No_precision */0,
                                          /* Char_literal */Block.__(12, [
                                              /* " " */32,
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ])
                                    ])
                                ]),
                              "%s %d %s"
                            ]), actionWord, selectedItems.length, pluralItemName) : I18N$BsConsole.showSkip(actionWord + (" 1 " + singleItemName))
                ) : I18N$BsConsole.showSkip(actionWord + (" " + pluralItemName))
            }));
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(Css.relative),
                    /* :: */[
                      Css.height(Css.px(225)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* [] */0
                        ]
                      ])
                }, inputLabel !== undefined ? React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ])
                        },
                        htmlFor: inputLabel,
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.showSkip(inputLabel)
                            })
                      }) : null, React.createElement("div", undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                          placeholder: placeholder,
                          value: searchString,
                          onChange: (function (e) {
                              var value = BtSettings$BsConsole.getInputValue(e);
                              return Curry._1(setSearchString, (function (param) {
                                            return value;
                                          }));
                            }),
                          className: Css.style(/* :: */[
                                Css.width(w),
                                /* :: */[
                                  Css.important(Css.marginBottom(Css.px(0))),
                                  /* [] */0
                                ]
                              ])
                        }))), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* [] */0
                      ])
                }, React.createElement(Col2$BsConsole.make, {
                      children: null
                    }, items.length === 0 ? React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(w),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.flexDirection(/* column */-963948842),
                                      /* :: */[
                                        Css.padding(Css.px(10)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Overline */12,
                                children: I18N$BsConsole.showSkip(noItemsMessage)
                              })) : React.createElement(Paper.default, {
                            children: React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.height(Css.px(145)),
                                        /* :: */[
                                          Css.width(w),
                                          /* :: */[
                                            Css.overflowY(/* auto */-1065951377),
                                            /* :: */[
                                              Css.overflowX(/* hidden */-862584982),
                                              /* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.flexDirection(/* column */-963948842),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ])
                                }, items.length === 0 ? React.createElement("div", undefined, I18N$BsConsole.showSkip(noItemsMessage)) : Belt_Array.map(filteredItems$1, (function (item) {
                                          return React.createElement(React.Fragment, {
                                                      children: React.createElement(Button.default, {
                                                            classes: {
                                                              root: Css.style(/* :: */[
                                                                    Css.width(Css.pct(100)),
                                                                    /* :: */[
                                                                      Css.padding(Css.em(0.1)),
                                                                      /* [] */0
                                                                    ]
                                                                  ])
                                                            },
                                                            onClick: (function (param) {
                                                                var selectedItem = Belt_Array.getBy(selectedItems, (function (u) {
                                                                        return Curry._1(toString, item) === Curry._1(toString, u);
                                                                      }));
                                                                if (selectedItem !== undefined) {
                                                                  var selectedItem$1 = Caml_option.valFromOption(selectedItem);
                                                                  var updated = Belt_Array.keep(selectedItems, (function (u) {
                                                                          return Curry._1(toString, u) !== Curry._1(toString, selectedItem$1);
                                                                        }));
                                                                  if (handleAllItemsAfterChange !== undefined) {
                                                                    Curry._1(handleAllItemsAfterChange, updated);
                                                                  }
                                                                  if (handleItemAfterChange !== undefined) {
                                                                    return Curry._1(handleItemAfterChange, /* tuple */[
                                                                                selectedItem$1,
                                                                                false
                                                                              ]);
                                                                  } else {
                                                                    return ;
                                                                  }
                                                                }
                                                                var updated$1 = Belt_Array.concat(selectedItems, [item]);
                                                                if (handleAllItemsAfterChange !== undefined) {
                                                                  Curry._1(handleAllItemsAfterChange, updated$1);
                                                                }
                                                                if (handleItemAfterChange !== undefined) {
                                                                  return Curry._1(handleItemAfterChange, /* tuple */[
                                                                              item,
                                                                              true
                                                                            ]);
                                                                }
                                                                
                                                              }),
                                                            children: React.createElement("div", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.whiteSpace(/* nowrap */867913355),
                                                                        /* :: */[
                                                                          Css.textOverflow(/* ellipsis */166888785),
                                                                          /* :: */[
                                                                            Css.display(/* flex */-1010954439),
                                                                            /* :: */[
                                                                              Css.alignItems(/* center */98248149),
                                                                              /* :: */[
                                                                                Css.cursor(/* pointer */-786317123),
                                                                                /* :: */[
                                                                                  Css.height(Css.px(30)),
                                                                                  /* :: */[
                                                                                    Css.fontFamily("Roboto"),
                                                                                    /* :: */[
                                                                                      Css.fontWeight(/* `num */[
                                                                                            5496390,
                                                                                            400
                                                                                          ]),
                                                                                      /* :: */[
                                                                                        Css.fontSize(Css.rem(0.8125)),
                                                                                        /* :: */[
                                                                                          Css.margin(/* zero */-789508312),
                                                                                          /* :: */[
                                                                                            Css.width(w),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ])
                                                                }, React.createElement(Checkbox.default, {
                                                                      checked: Belt_Option.isSome(Belt_Array.getBy(selectedItems, (function (u) {
                                                                                  return Curry._1(toString, u) === Curry._1(toString, item);
                                                                                }))),
                                                                      color: "primary",
                                                                      disableRipple: true,
                                                                      inputProps: {
                                                                        tabIndex: -1
                                                                      }
                                                                    }), I18N$BsConsole.showSkip(Curry._1(toString, item)))
                                                          }),
                                                      key: toId !== undefined ? Curry._1(toId, item) : Curry._1(toString, item)
                                                    });
                                        })))
                          }), tmp)));
}

var Multi = {
  make: BtTypeaheadInput$Multi
};

exports.CreateNewRow = CreateNewRow;
exports.Single = Single;
exports.Multi = Multi;
/* Css Not a pure module */
