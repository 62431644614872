// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var BtSettings$BsConsole = require("../BtSettings.js");

var component = RR$BsConsole.statelessComponent("WfLayout-BsConsole");

function make(onClick, rotation, header, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              React.createElement("div", {
                                    style: {
                                      width: "100%"
                                    }
                                  }, ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [React.createElement("div", {
                                                  style: {
                                                    display: "flex",
                                                    minHeight: "120px",
                                                    paddingRight: "12px",
                                                    position: "relative",
                                                    flexDirection: "column",
                                                    justifyContent: "center"
                                                  }
                                                }, header, React.createElement("div", {
                                                      style: {
                                                        position: "absolute",
                                                        right: "-28px",
                                                        top: "calc(50% - 28px)"
                                                      }
                                                    }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                              undefined,
                                                              undefined,
                                                              onClick,
                                                              undefined,
                                                              undefined,
                                                              /* Fab */3502759,
                                                              /* Primary */-791844958,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [React.createElement("div", {
                                                                      "aria-label": rotation === "45deg" ? "Add a new integration" : "Cancel add integration",
                                                                      style: {
                                                                        display: "flex",
                                                                        transition: "transform 0.1s ease-in-out",
                                                                        transform: "rotate(" + (rotation + ")")
                                                                      }
                                                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]
                                                            ]))))]))),
                              React.createElement("div", {
                                    style: {
                                      padding: "24px 0",
                                      width: "100%"
                                    }
                                  }, ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, children)))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
