// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Util$BsConsole = require("./util.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function toJson(t$prime) {
  if (t$prime.tag) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), (function (prim) {
                  return prim;
                }), /* tuple */[
                "obj",
                t$prime[0]
              ]);
  } else {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), (function (prim) {
                  return prim;
                }), /* tuple */[
                "tx",
                t$prime[0]
              ]);
  }
}

function fromJson(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.map((function (idTuple) {
                              switch (idTuple[0]) {
                                case "obj" :
                                    return /* Obj */Block.__(1, [idTuple[1]]);
                                case "tx" :
                                    return /* Tx */Block.__(0, [idTuple[1]]);
                                default:
                                  throw [
                                        Caml_builtin_exceptions.assert_failure,
                                        /* tuple */[
                                          "DebuggerStateSerializer.re",
                                          24,
                                          22
                                        ]
                                      ];
                              }
                            }), (function (param) {
                              return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
                            }), param);
              }), json);
}

function toString(t$prime) {
  return t$prime[0];
}

var DebuggerObjectID = {
  toJson: toJson,
  fromJson: fromJson,
  toString: toString
};

function selectedObjectsToJson(t) {
  var match = t.selectedThreadIndex;
  var match$1 = t.selectedFrameIndex;
  var match$2 = t.selectedVariableID;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      if (match$2 !== undefined) {
        return Json_encode.array((function (prim) {
                      return prim;
                    }), [
                    match,
                    match$1,
                    match$2
                  ]);
      } else {
        return Json_encode.array((function (prim) {
                      return prim;
                    }), [
                    match,
                    match$1
                  ]);
      }
    }
    
  } else if (match$1 !== undefined) {
    return Json_encode.array((function (prim) {
                  return prim;
                }), [
                -1,
                match$1
              ]);
  }
  return Json_encode.array((function (prim) {
                return prim;
              }), []);
}

function toJson$1(t$prime) {
  return Json_encode.object_(Belt_List.keepMap(/* :: */[
                  /* tuple */[
                    "id",
                    toJson(t$prime.id)
                  ],
                  /* :: */[
                    Belt_Option.flatMap(t$prime.faultedThreadIndex, (function (faultedThreadIndex) {
                            return /* tuple */[
                                    "fti",
                                    faultedThreadIndex
                                  ];
                          })),
                    /* :: */[
                      /* tuple */[
                        "s",
                        selectedObjectsToJson(t$prime)
                      ],
                      /* [] */0
                    ]
                  ]
                ], Util$BsConsole.identity));
}

function fromJson$1(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.map((function (param) {
                              return {
                                      id: param[0],
                                      faultedThreadIndex: undefined,
                                      selectedThreadIndex: param[1],
                                      selectedFrameIndex: param[2],
                                      selectedVariableID: param[3]
                                    };
                            }), (function (json) {
                              var id = Belt_Option.getExn(Json_decode.field("id", fromJson, json));
                              var match = Json_decode.map((function (arr) {
                                      var len = arr.length;
                                      if (len >= 4) {
                                        return /* tuple */[
                                                undefined,
                                                undefined,
                                                undefined
                                              ];
                                      }
                                      switch (len) {
                                        case 0 :
                                            return /* tuple */[
                                                    undefined,
                                                    undefined,
                                                    undefined
                                                  ];
                                        case 1 :
                                            var selectedThreadIndex = arr[0];
                                            return /* tuple */[
                                                    selectedThreadIndex,
                                                    undefined,
                                                    undefined
                                                  ];
                                        case 2 :
                                            var selectedThreadIndex$1 = arr[0];
                                            var selectedFrameIndex = arr[1];
                                            return /* tuple */[
                                                    selectedThreadIndex$1,
                                                    selectedFrameIndex,
                                                    undefined
                                                  ];
                                        case 3 :
                                            var selectedThreadIndex$2 = arr[0];
                                            var selectedFrameIndex$1 = arr[1];
                                            var selectedVariableIndex = arr[2];
                                            return /* tuple */[
                                                    selectedThreadIndex$2,
                                                    selectedFrameIndex$1,
                                                    selectedVariableIndex
                                                  ];
                                        
                                      }
                                    }), (function (param) {
                                      return Json_decode.array(Json_decode.$$int, param);
                                    }), Json_decode.field("s", Util$BsConsole.identity, json));
                              return /* tuple */[
                                      id,
                                      match[0],
                                      match[1],
                                      match[2]
                                    ];
                            }), param);
              }), json);
}

var DebuggerObjectState = {
  selectedObjectsToJson: selectedObjectsToJson,
  toJson: toJson$1,
  fromJson: fromJson$1
};

var empty = {
  currentID: undefined,
  states: undefined
};

function toJson$2(t$prime) {
  return Json_encode.object_(Belt_List.keepMap(/* :: */[
                  Belt_Option.flatMap(t$prime.currentID, (function (id) {
                          return /* tuple */[
                                  "currentID",
                                  toJson(id)
                                ];
                        })),
                  /* :: */[
                    /* tuple */[
                      "s",
                      Json_encode.array(toJson$1, Belt_MapString.valuesToArray(t$prime.states))
                    ],
                    /* [] */0
                  ]
                ], Util$BsConsole.identity));
}

function fromJson$2(json) {
  return Json_decode.optional((function (json) {
                return {
                        currentID: Json_decode.field("currentID", fromJson, json),
                        states: Json_decode.withDefault(undefined, (function (json) {
                                return Json_decode.field("s", (function (param) {
                                              return Json_decode.map((function (l) {
                                                            return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(l, (function (s) {
                                                                                  return /* tuple */[
                                                                                          s.id[0],
                                                                                          s
                                                                                        ];
                                                                                }))));
                                                          }), (function (param) {
                                                            return Json_decode.list((function (json) {
                                                                          return Belt_Option.getExn(fromJson$1(json));
                                                                        }), param);
                                                          }), param);
                                            }), json);
                              }), json)
                      };
              }), json);
}

function setCurrentID(t$prime, id) {
  return {
          currentID: id,
          states: t$prime.states
        };
}

function getCurrentID(t) {
  return t.currentID;
}

function stateForObjectID(t$prime, id) {
  return Belt_MapString.get(t$prime.states, id[0]);
}

function stateOrDefaultForObjectID(t$prime, id) {
  return Belt_Option.getWithDefault(Belt_MapString.get(t$prime.states, id[0]), {
              id: id,
              faultedThreadIndex: undefined,
              selectedThreadIndex: undefined,
              selectedFrameIndex: undefined,
              selectedVariableID: undefined
            });
}

function setObjectState(t$prime, id, updater) {
  return {
          currentID: t$prime.currentID,
          states: Belt_MapString.set(t$prime.states, id[0], Curry._1(updater, Belt_Option.getWithDefault(stateForObjectID(t$prime, id), {
                        id: id,
                        faultedThreadIndex: undefined,
                        selectedThreadIndex: undefined,
                        selectedFrameIndex: undefined,
                        selectedVariableID: undefined
                      })))
        };
}

function getCurrentState(t) {
  return Belt_Option.flatMap(t.currentID, (function (param) {
                return stateForObjectID(t, param);
              }));
}

var DebuggerState = {
  empty: empty,
  toJson: toJson$2,
  fromJson: fromJson$2,
  setCurrentID: setCurrentID,
  getCurrentID: getCurrentID,
  stateForObjectID: stateForObjectID,
  stateOrDefaultForObjectID: stateOrDefaultForObjectID,
  setObjectState: setObjectState,
  getCurrentState: getCurrentState
};

function getFaultState(id, debuggerState, faultedThreadIndex) {
  return setObjectState(setCurrentID(Belt_Option.getWithDefault(debuggerState, empty), /* Obj */Block.__(1, [id])), /* Obj */Block.__(1, [id]), (function (currentState) {
                return {
                        id: currentState.id,
                        faultedThreadIndex: faultedThreadIndex,
                        selectedThreadIndex: currentState.selectedThreadIndex,
                        selectedFrameIndex: currentState.selectedFrameIndex,
                        selectedVariableID: currentState.selectedVariableID
                      };
              }));
}

exports.DebuggerObjectID = DebuggerObjectID;
exports.DebuggerObjectState = DebuggerObjectState;
exports.DebuggerState = DebuggerState;
exports.getFaultState = getFaultState;
/* Util-BsConsole Not a pure module */
