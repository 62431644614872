// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");

var CrdbBucketCountOverflow = Caml_exceptions.create("Interpolate_histogram-BsConsole.CrdbBucketCountOverflow");

var ValueOutOfBounds = Caml_exceptions.create("Interpolate_histogram-BsConsole.ValueOutOfBounds");

function make(histogram, index, count) {
  var start = histogram.low + index * histogram.interval;
  var end_ = start + histogram.interval;
  return {
          start: start,
          end_: end_,
          count: count
        };
}

function toTuple(param) {
  return /* tuple */[
          param.start,
          param.end_,
          param.count
        ];
}

function make$1(numBuckets, low, high) {
  var interval = numBuckets > 0 ? Js_math.ceil((high - low) / numBuckets) : 0;
  return {
          high: low + interval * numBuckets,
          interval: interval,
          low: low,
          numBuckets: numBuckets,
          values: Belt_Array.make(numBuckets, 0)
        };
}

function merge(t, crdbResponse) {
  var crdbCount = crdbResponse.length;
  var valueCount = t.values.length;
  if (crdbCount > valueCount) {
    throw [
          CrdbBucketCountOverflow,
          crdbCount,
          valueCount
        ];
  }
  return Belt_Array.reduce(crdbResponse, t, (function (acc, param) {
                var value = param[0];
                var sum = param[2];
                if (value <= acc.high && value >= acc.low) {
                  var offset = Js_math.floor((value - acc.low) / acc.interval);
                  var offset$1 = offset >= acc.numBuckets ? offset - 1 | 0 : offset;
                  var add = (function rawAdd(a, b) {
        return a + b;
      });
                  var sum$1 = sum === 0 ? 1 : sum;
                  var updated = Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(acc.values, offset$1), (function (current) {
                              return Curry._2(add, current, sum$1);
                            })), sum$1);
                  acc.values[offset$1] = updated;
                  return acc;
                }
                throw [
                      ValueOutOfBounds,
                      value,
                      acc.low,
                      acc.high
                    ];
              }));
}

function serializeWithEmpty(t) {
  return Belt_Array.reduceWithIndex(t.values, [], (function (param, param$1, param$2) {
                param.push(toTuple(make(t, param$2, param$1)));
                return param;
              }));
}

exports.CrdbBucketCountOverflow = CrdbBucketCountOverflow;
exports.ValueOutOfBounds = ValueOutOfBounds;
exports.make = make$1;
exports.merge = merge;
exports.serializeWithEmpty = serializeWithEmpty;
/* No side effect */
