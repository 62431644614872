// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var oneHour = 3600;

var threeHours = Caml_int32.imul(3, oneHour);

var sixHours = Caml_int32.imul(6, oneHour);

var twelveHours = Caml_int32.imul(12, oneHour);

var oneDay = Caml_int32.imul(24, oneHour);

var oneWeek = Caml_int32.imul(7, oneDay);

var oneMonth = Caml_int32.imul(31, oneDay);

var threeMonths = Caml_int32.imul(92, oneDay);

var oneYear = Caml_int32.imul(366, oneDay);

var dataPointGranularities = [
  1800,
  oneHour,
  threeHours,
  sixHours,
  twelveHours,
  oneDay,
  oneWeek,
  oneMonth,
  threeMonths,
  oneYear
];

var storedGranularities = [
  /* tuple */[
    1800,
    oneWeek
  ],
  /* tuple */[
    threeHours,
    oneMonth
  ],
  /* tuple */[
    sixHours,
    threeMonths
  ],
  /* tuple */[
    oneDay,
    oneYear
  ],
  /* tuple */[
    oneWeek,
    Caml_int32.imul(oneYear, 5)
  ]
];

function minStoredGranForWidth(width) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(storedGranularities, (function (param) {
                        return width <= param[1];
                      })), (function (param) {
                    return param[0];
                  })), oneWeek);
}

var DataPointGranularityIndexNotFound = Caml_exceptions.create("UniqueAggregations-BsConsole.DataPointGranularityIndexNotFound");

var StoredGranularityNotFound = Caml_exceptions.create("UniqueAggregations-BsConsole.StoredGranularityNotFound");

var DataPointGranularityNotFound = Caml_exceptions.create("UniqueAggregations-BsConsole.DataPointGranularityNotFound");

function apertureToDataPointRange(aperture) {
  try {
    var match = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture));
    var apertureStart = match[0].valueOf() / 1000;
    var apertureEnd = match[1].valueOf() / 1000;
    var duration = apertureEnd - apertureStart | 0;
    var idx = Belt_Array.getIndexBy(dataPointGranularities, (function (gran) {
            return gran >= duration;
          }));
    var initiatlDurationGranID;
    if (idx !== undefined) {
      initiatlDurationGranID = idx;
    } else {
      throw DataPointGranularityIndexNotFound;
    }
    var durationGranID = initiatlDurationGranID;
    var range;
    var i = 0;
    while(i < storedGranularities.length && Belt_Option.isNone(range)) {
      var match$1 = Belt_Array.get(storedGranularities, i);
      var match$2;
      if (match$1 !== undefined) {
        match$2 = /* tuple */[
          match$1[0],
          match$1[1]
        ];
      } else {
        throw StoredGranularityNotFound;
      }
      if (duration <= match$2[1]) {
        var d = Belt_Array.get(dataPointGranularities, durationGranID);
        var dataPointGran;
        if (d !== undefined) {
          dataPointGran = d;
        } else {
          throw DataPointGranularityNotFound;
        }
        var alignGran = minStoredGranForWidth(dataPointGran);
        var endBucket = Math.ceil(apertureEnd / alignGran);
        var rangeEnd = endBucket * alignGran;
        var rangeStart = rangeEnd - dataPointGran;
        if (duration >= oneDay) {
          rangeStart = rangeStart - match$2[0];
          range = /* tuple */[
            rangeStart,
            rangeEnd
          ];
        } else if (rangeStart > apertureStart) {
          durationGranID = durationGranID + 1 | 0;
        } else {
          range = /* tuple */[
            rangeStart,
            rangeEnd
          ];
        }
      }
      i = i + 1 | 0;
    };
    return range;
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error === DataPointGranularityIndexNotFound) {
      return ;
    }
    var e = Caml_js_exceptions.caml_as_js_exn(error);
    if (e !== undefined) {
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "UniqueAggregation/aperatureToDataPointRange(Some)", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            Caml_option.valFromOption(e)
          ]);
    } else {
      var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "UniqueAggregation/aperatureToDataPointRange(None)", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
            5049499,
            Uncaught$BsConsole.castToJsExn(error)
          ]);
    }
    return ;
  }
}

var thirtyMins = 1800;

exports.thirtyMins = thirtyMins;
exports.oneHour = oneHour;
exports.threeHours = threeHours;
exports.sixHours = sixHours;
exports.twelveHours = twelveHours;
exports.oneDay = oneDay;
exports.oneWeek = oneWeek;
exports.oneMonth = oneMonth;
exports.threeMonths = threeMonths;
exports.oneYear = oneYear;
exports.dataPointGranularities = dataPointGranularities;
exports.storedGranularities = storedGranularities;
exports.minStoredGranForWidth = minStoredGranForWidth;
exports.DataPointGranularityIndexNotFound = DataPointGranularityIndexNotFound;
exports.StoredGranularityNotFound = StoredGranularityNotFound;
exports.DataPointGranularityNotFound = DataPointGranularityNotFound;
exports.apertureToDataPointRange = apertureToDataPointRange;
/* Crdb-BsConsole Not a pure module */
