// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Dedupe_FormView$BsConsole = require("./Dedupe_FormView.js");

var component = RR$BsConsole.statelessComponent("Dedupe_EditView-BsConsole");

function make(universe, project, token, uid, refetch, hideAddView, hideEditView, ruleObject, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              if (ruleObject !== undefined) {
                return ReasonReact.element(undefined, undefined, Dedupe_FormView$BsConsole.make(universe, project, token, uid, refetch, hideAddView, hideEditView, ruleObject, []));
              } else {
                return ReasonReact.element(undefined, undefined, Dedupe_FormView$BsConsole.make(universe, project, token, uid, refetch, hideAddView, hideEditView, undefined, []));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
