// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");

var component = ReasonReact.statelessComponent("WfDropdown-BsConsole");

function make(value, onValueChange, nullLabel, options, required, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return React.createElement("div", {
                          style: {
                            padding: "8px 0"
                          }
                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  Belt_Option.map(value, (function (v) {
                                          return /* `String */[
                                                  -976970511,
                                                  v
                                                ];
                                        })),
                                  (function (param, el) {
                                      return Curry._1(onValueChange, el.props.value);
                                    }),
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  {
                                    minWidth: "84px"
                                  },
                                  [
                                    required ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                -976970511,
                                                undefined
                                              ], undefined, undefined, [Belt_Option.mapWithDefault(nullLabel, null, I18N$BsConsole.showSkip)])),
                                    Belt_Array.map(options, (function (param) {
                                            var v = param[1];
                                            var label = param[0];
                                            return ReasonReact.element(label, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                            -976970511,
                                                            v
                                                          ], undefined, Caml_obj.caml_equal(value, Caml_option.some(v)), [I18N$BsConsole.showSkip(label)]));
                                          }))
                                  ]
                                ])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
