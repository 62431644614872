// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ApiDescribe$BsConsole = require("./apiDescribe.js");

function decodeAttribute(json) {
  try {
    return {
            name: Json_decode.field("name", Json_decode.string, json),
            state: Json_decode.field("state", Json_decode.string, json),
            seen: Json_decode.field("seen", Json_decode.$$int, json),
            value: Json_decode.field("value", Json_decode.string, json),
            custom: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                        return Json_decode.field("custom", Json_decode.bool, param);
                      }), json), true)
          };
  }
  catch (exn){
    return ;
  }
}

function to_js(d) {
  return {
          name: d.name,
          seen: d.seen,
          value: d.value,
          state: d.state,
          custom: d.custom
        };
}

function of_js(r) {
  return {
          name: r.name,
          state: r.state,
          seen: r.seen,
          value: r.value,
          custom: r.custom
        };
}

function decode(json) {
  var __x = Json_decode.field("describe", (function (param) {
          return Json_decode.list(decodeAttribute, param);
        }), json);
  return Belt_List.keepMap(__x, (function (t) {
                return t;
              }));
}

var Disabled = {
  decodeAttribute: decodeAttribute,
  to_js: to_js,
  of_js: of_js,
  decode: decode
};

function decodeAttribute$1(json) {
  try {
    return ApiDescribe$BsConsole.decodeAttributeExn(json);
  }
  catch (exn){
    return ;
  }
}

var excludeList = /* :: */[
  "fingerprint;issues;fingerprint",
  /* [] */0
];

function decode$1(json) {
  var __x = Json_decode.field("describe", (function (param) {
          return Json_decode.list(decodeAttribute$1, param);
        }), json);
  return Belt_List.keepMap(__x, (function (t) {
                if (t !== undefined && !Belt_List.some(excludeList, (function (excludeName) {
                          return t.name === excludeName;
                        }))) {
                  return t;
                }
                
              }));
}

var Active = {
  decodeAttribute: decodeAttribute$1,
  excludeList: excludeList,
  decode: decode$1
};

exports.Disabled = Disabled;
exports.Active = Active;
/* No side effect */
