// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = RR$BsConsole.reducerComponent("WfString-BsConsole");

function make(btDefaults, fieldDoc, initialValue, padding, onValueChange, showAllErrors, schema, remoteAction, param) {
  var setElementRef = function (nullableRef, param) {
    param.state.elementRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var setInputRef = function (nullableRef, param) {
    param.state.inputRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var validate = function (value) {
    var parsedValue = value === "" ? undefined : value;
    var err = WfCore$BsConsole.$$String.validate(schema, parsedValue);
    if (err !== undefined) {
      return /* Error */Block.__(1, [err]);
    } else {
      return /* Ok */Block.__(0, [parsedValue]);
    }
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var state = param.state;
              Curry._1(onValueChange, Belt_Result.map(state.validatedValue, (function (param) {
                          return Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), param);
                        })));
              return Curry._1(remoteAction, (function (param) {
                            if (param.tag) {
                              var element = state.elementRef.contents;
                              if (element !== undefined) {
                                return WfCore$BsConsole.scrollToDomElement(Caml_option.valFromOption(element));
                              } else {
                                return ;
                              }
                            }
                            var element$1 = state.inputRef.contents;
                            if (element$1 !== undefined) {
                              return Caml_option.valFromOption(element$1).focus();
                            }
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var validatedValue = state.validatedValue;
              var send = param.send;
              var handle = param.handle;
              var error;
              error = validatedValue.tag ? validatedValue[0] : undefined;
              var match = schema.uiWidget;
              var match$1 = schema.inputType;
              var tmp;
              var exit = 0;
              if (match !== undefined) {
                if (match !== -578166461) {
                  if (match !== 578936635) {
                    exit = 1;
                  } else {
                    tmp = "password";
                  }
                } else {
                  tmp = "color";
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                if (match$1 !== undefined && match$1 < 3) {
                  switch (match$1) {
                    case /* Password */0 :
                        tmp = undefined;
                        break;
                    case /* Email */1 :
                        tmp = "email";
                        break;
                    case /* Uri */2 :
                        tmp = "url";
                        break;
                    
                  }
                } else {
                  tmp = undefined;
                }
              }
              var match$2 = schema.uiWidget;
              return React.createElement("div", {
                          ref: Curry._1(handle, setElementRef)
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, schema.required, padding, error, undefined, state.dirty, showAllErrors, [React.createElement("div", {
                                        style: {
                                          padding: "8px 0"
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                schema.placeholder,
                                                true,
                                                (function (e) {
                                                    return Curry._1(send, /* UpdateValue */[e.target.value]);
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                tmp,
                                                /* `String */[
                                                  -976970511,
                                                  state.value
                                                ],
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* `Callback */[
                                                  -659008027,
                                                  Curry._1(handle, setInputRef)
                                                ],
                                                undefined,
                                                match$2 !== undefined ? match$2 === 843607770 : false,
                                                undefined,
                                                []
                                              ])))])));
            }),
          initialState: (function (param) {
              var initialValue$1;
              try {
                initialValue$1 = Belt_Option.map(initialValue, Json_decode.string);
              }
              catch (exn){
                initialValue$1 = undefined;
              }
              var match = schema.defaultValue;
              var match$1 = schema.uiPopulatedType;
              var value;
              if (initialValue$1 !== undefined) {
                value = initialValue$1;
              } else if (match$1 !== undefined) {
                switch (match$1) {
                  case /* QueryUrl */0 :
                      var queryEndpointMaybe = Json_decode.optional((function (param) {
                              return Json_decode.field("bt-query-endpoint", Json_decode.string, param);
                            }), btDefaults);
                      value = queryEndpointMaybe !== undefined ? queryEndpointMaybe : "";
                      break;
                  case /* ScaServiceUrl */1 :
                      var scaEndpointMaybe = Json_decode.optional((function (param) {
                              return Json_decode.field("sca-service-endpoint", Json_decode.string, param);
                            }), btDefaults);
                      value = scaEndpointMaybe !== undefined ? scaEndpointMaybe : "";
                      break;
                  case /* CurrentProjectName */2 :
                      value = Json_decode.field("current-project-name", Json_decode.string, btDefaults);
                      break;
                  case /* CurrentProjectToken */3 :
                      value = Json_decode.field("current-project-token", Json_decode.string, btDefaults);
                      break;
                  
                }
              } else {
                value = match !== undefined ? match : "";
              }
              return {
                      value: value,
                      validatedValue: validate(value),
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      inputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var value = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          value: value,
                          validatedValue: validate(value),
                          dirty: true,
                          elementRef: state.elementRef,
                          inputRef: state.inputRef
                        },
                        (function (param) {
                            return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (param) {
                                              return Json_encode.nullable((function (prim) {
                                                            return prim;
                                                          }), param);
                                            })));
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
