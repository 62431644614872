// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var ApiList$BsConsole = require("../../apiList.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var BugReplayApi$BsConsole = require("./BugReplayApi.js");
var BugReplayText$BsConsole = require("./BugReplayText.js");
var BugReplayView$BsConsole = require("./BugReplayView.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var BugReplayUtils$BsConsole = require("./BugReplayUtils.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

((require('rrweb-player/dist/style.css')));

var containerStyle = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.justifyContent(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function BugReplay$Loader(Props) {
  return React.createElement("div", {
              className: containerStyle
            }, React.createElement(CircularProgress.default, { }));
}

var Loader = {
  make: BugReplay$Loader
};

function BugReplay(Props) {
  var showHeaderOpt = Props.showHeader;
  var height = Props.height;
  var width = Props.width;
  var attachments = Props.attachments;
  var eventbox = Props.eventbox;
  var setEventbox = Props.setEventbox;
  var token = Props.token;
  var errorId = Props.errorId;
  var projectName = Props.projectName;
  var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : true;
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setState = match[1];
  var state = match[0];
  var getAllEvents = function (paths) {
    var sortedPaths = Belt_SortArray.stableSortBy(paths, $$String.compare);
    var promises = Belt_Array.map(sortedPaths, (function (path) {
            return BugReplayApi$BsConsole.getEvents(token, path);
          }));
    var __x = Promise.all(promises);
    var __x$1 = __x.then((function (results) {
            var events = Belt_Array.reduce(Belt_Array.keepMap(results, Util$BsConsole.identity), [], Belt_Array.concat);
            Curry._1(setState, (function (param) {
                    return /* Loaded */Block.__(1, [events]);
                  }));
            Curry._1(setEventbox, (function (param) {
                    return /* Loaded */Block.__(1, [events]);
                  }));
            return Promise.resolve(undefined);
          }));
    __x$1.catch((function (_exn) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(BugReplayText$BsConsole.errorFetching));
            Curry._1(setState, (function (param) {
                    return /* Error */Block.__(0, [BugReplayText$BsConsole.errorFetching]);
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          if (Belt_Option.isSome(eventbox)) {
            Curry._1(setState, (function (param) {
                    return Belt_Option.getWithDefault(eventbox, /* Error */Block.__(0, ["Events: this should never happen"]));
                  }));
          } else {
            var exit = 0;
            if (typeof attachments === "number" || attachments.tag) {
              exit = 1;
            } else {
              var replayFiles = attachments[0][0].filter((function (file) {
                      var __x = file.name;
                      return __x.startsWith(BugReplayUtils$BsConsole.attachmentPrefix);
                    }));
              if (replayFiles.length === 0) {
                Curry._1(setState, (function (param) {
                        return /* NoSessionReplayAttachment */1;
                      }));
                Curry._1(setEventbox, (function (param) {
                        return /* NoSessionReplayAttachment */1;
                      }));
              } else {
                getAllEvents(Belt_Array.map(replayFiles, (function (file) {
                            return ApiList$BsConsole.getPath(errorId, undefined, projectName, file);
                          })));
              }
            }
            if (exit === 1) {
              Curry._1(setState, (function (param) {
                      return /* NoSessionReplayAttachment */1;
                    }));
              Curry._1(setEventbox, (function (param) {
                      return /* NoSessionReplayAttachment */1;
                    }));
            }
            
          }
          
        }), [attachments]);
  if (typeof state !== "number") {
    if (state.tag) {
      return React.createElement(BugReplayView$BsConsole.make, {
                  showHeader: showHeader,
                  height: height,
                  width: width,
                  events: state[0]
                });
    } else {
      return React.createElement("div", {
                  className: containerStyle
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.paddingTop(Css.px(12)),
                            /* :: */[
                              Css.paddingBottom(Css.px(12)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.dynamic(state[0])
                    }));
    }
  }
  switch (state) {
    case /* Loading */0 :
        return React.createElement(BugReplay$Loader, { });
    case /* NoSessionReplayAttachment */1 :
        return React.createElement("div", {
                    className: containerStyle
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.paddingTop(Css.px(12)),
                              /* :: */[
                                Css.paddingBottom(Css.px(12)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.showSkip(BugReplayText$BsConsole.noReplayCheckDocs)
                      }));
    case /* SessionReplayAttachmentButEmpty */2 :
        return React.createElement("div", {
                    className: containerStyle
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.paddingTop(Css.px(12)),
                              /* :: */[
                                Css.paddingBottom(Css.px(12)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.showSkip(BugReplayText$BsConsole.noReplayEventsFound)
                      }));
    
  }
}

var make = BugReplay;

exports.containerStyle = containerStyle;
exports.Loader = Loader;
exports.make = make;
/*  Not a pure module */
