// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");

function filter(attribute) {
  if (attribute.name === Issues$BsConsole.Assignees.columnName) {
    return /* :: */[
            "assigned-to",
            /* :: */[
              "not-assigned-to",
              /* [] */0
            ]
          ];
  } else if (attribute.name === Issues$BsConsole.Tickets.columnName) {
    return /* :: */[
            "linked-to",
            /* :: */[
              "not-linked-to",
              /* [] */0
            ]
          ];
  } else {
    return attribute.filter;
  }
}

function aggregate(attribute) {
  if (attribute.name === Issues$BsConsole.Assignees.columnName || attribute.name === Issues$BsConsole.Tickets.columnName) {
    return /* :: */[
            "head",
            /* :: */[
              "tail",
              /* [] */0
            ]
          ];
  } else {
    return attribute.aggregate;
  }
}

function group(attribute) {
  if (attribute.name === Issues$BsConsole.Assignees.columnName || attribute.name === Issues$BsConsole.Tickets.columnName) {
    return false;
  } else {
    return attribute.group;
  }
}

function attributesFold(attributes) {
  return Belt_List.keep(attributes, (function (param) {
                var name = param.name;
                return !(name === Issues$BsConsole.Assignees.columnName || name === Issues$BsConsole.Tickets.columnName);
              }));
}

function attributesSelect(attributes) {
  return Belt_List.keep(attributes, (function (param) {
                var name = param.name;
                return !(name === Issues$BsConsole.Assignees.columnName || name === Issues$BsConsole.Tickets.columnName);
              }));
}

exports.filter = filter;
exports.aggregate = aggregate;
exports.group = group;
exports.attributesFold = attributesFold;
exports.attributesSelect = attributesSelect;
/* Issues-BsConsole Not a pure module */
