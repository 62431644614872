// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Image$BsConsole = require("./components/Image.js");
var Column$BsConsole = require("./utilities/Column.js");
var Bpg_response$BsConsole = require("./bpg_response.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var button = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var gridPadding = Css.style(/* :: */[
      Css.padding(Css.px(12)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* [] */0
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding(Css.px(32))),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var heading = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var statusContainer = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var statusHeader = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var subtitle = Css.style(/* :: */[
      Css.alignSelf(/* center */98248149),
      /* [] */0
    ]);

var inputs = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.selector(".input-item:not(:last-of-type)", /* :: */[
              Css.marginBottom(Css.px(16)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var retryButton = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var Styles = {
  grid: grid,
  button: button,
  gridPadding: gridPadding,
  loadingContainer: loadingContainer,
  paper: paper,
  card: card,
  cardContent: cardContent,
  logoContainer: logoContainer,
  logo: logo,
  heading: heading,
  statusContainer: statusContainer,
  statusHeader: statusHeader,
  subtitle: subtitle,
  inputs: inputs,
  retryButton: retryButton
};

var R = Bpg_response$BsConsole.Make({
      table: BacktraceParserGenerator$BsConsole.Users.table,
      to_json: BacktraceParserGenerator$BsConsole.Users.to_json,
      of_json: BacktraceParserGenerator$BsConsole.Users.of_json,
      Key: BacktraceParserGenerator$BsConsole.Users.Key,
      to_key: BacktraceParserGenerator$BsConsole.Users.to_key
    });

var component = ReasonReact.reducerComponent("ResetPage-BsConsole");

function make(handleTask, handleChangeUrl, token, universe_id, username, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.ResetPasswordForm.make, (function (param) {
                                
                              }), undefined, undefined, {
                              password: "",
                              confirmPassword: ""
                            }, /* :: */[
                              /* tuple */[
                                /* Password */578936635,
                                /* Required */0
                              ],
                              /* :: */[
                                /* tuple */[
                                  /* ConfirmPassword */28083227,
                                  /* Required */0
                                ],
                                /* [] */0
                              ]
                            ], undefined, (function (param) {
                                var handleChange = param.handleChange;
                                var form = param.form;
                                var msg = self.state.remote;
                                var tmp;
                                tmp = typeof msg === "number" ? (
                                    msg === /* NotAsked */0 ? ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, heading, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Reset your password")])),
                                                ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, inputs, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                                    undefined,
                                                                    "input-item",
                                                                    /* `String */[
                                                                      -976970511,
                                                                      form.values.password
                                                                    ],
                                                                    (function ($$event) {
                                                                        var password = $$event.target.value;
                                                                        return Curry._2(handleChange, /* Password */578936635, password);
                                                                      }),
                                                                    I18N$BsConsole.get(undefined, "password"),
                                                                    true,
                                                                    undefined,
                                                                    undefined,
                                                                    "password",
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                                    undefined,
                                                                    "input-item",
                                                                    /* `String */[
                                                                      -976970511,
                                                                      form.values.confirmPassword
                                                                    ],
                                                                    (function ($$event) {
                                                                        var confirmPassword = $$event.target.value;
                                                                        return Curry._2(handleChange, /* ConfirmPassword */28083227, confirmPassword);
                                                                      }),
                                                                    I18N$BsConsole.get(undefined, "confirm password"),
                                                                    true,
                                                                    undefined,
                                                                    undefined,
                                                                    "password",
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    []
                                                                  ]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          /* Medium */861718677,
                                                          button,
                                                          (function (_event) {
                                                              return Curry._1(self.send, /* Request */Block.__(0, [form.values.password]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          /* Raised */-387916264,
                                                          /* Primary */-791844958,
                                                          "submit",
                                                          undefined,
                                                          form.values.password === "" || form.values.password !== form.values.confirmPassword,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Reset password")]
                                                        ]))
                                              ])) : ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "primary", undefined, undefined, []))]))
                                  ) : (
                                    msg.tag ? ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                                          ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Error!")])),
                                                          ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          /* Medium */861718677,
                                                          retryButton,
                                                          (function (_event) {
                                                              return Curry._1(self.send, /* Retry */0);
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          /* Raised */-387916264,
                                                          /* Primary */-791844958,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Try again")]
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          /* Medium */861718677,
                                                          button,
                                                          (function (_event) {
                                                              return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                              referrer: undefined,
                                                                              token: undefined,
                                                                              redirect: undefined,
                                                                              method: undefined
                                                                            }]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          /* Flat */781662169,
                                                          /* Primary */-791844958,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Back to log in")]
                                                        ]))
                                              ])) : ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                                          ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                                          ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Password has been reset.")]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          /* Medium */861718677,
                                                          button,
                                                          (function (_event) {
                                                              return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                              referrer: undefined,
                                                                              token: undefined,
                                                                              redirect: undefined,
                                                                              method: undefined
                                                                            }]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          /* Raised */-387916264,
                                                          /* Primary */-791844958,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Back to log in")]
                                                        ]))
                                              ]))
                                  );
                                return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(Css.merge(/* :: */[
                                                    grid,
                                                    /* :: */[
                                                      "login-container",
                                                      /* [] */0
                                                    ]
                                                  ]), [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                                    ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([]))])),
                                                                    tmp
                                                                  ]))]))]));
                              })));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Retry */0) {
                  return /* Update */Block.__(0, [{
                              remote: /* NotAsked */0
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              remote: /* Success */Block.__(0, [/* [] */0])
                            }]);
                }
              }
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              }
              var password = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          remote: /* Loading */1
                        },
                        (function (self) {
                            var json = Json_encode.object_(/* :: */[
                                  /* tuple */[
                                    "type",
                                    "configuration/users"
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "action",
                                      "modify"
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "key",
                                        Json_encode.object_(/* :: */[
                                              /* tuple */[
                                                "username",
                                                username
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "universe",
                                                  universe_id
                                                ],
                                                /* [] */0
                                              ]
                                            ])
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "fields",
                                          Json_encode.object_(/* :: */[
                                                /* tuple */[
                                                  "password",
                                                  Util$BsConsole.stringToHex(password)
                                                ],
                                                /* [] */0
                                              ])
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]);
                            return Curry._2(handleTask, (function (json) {
                                          var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                          var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                          if (match.tag) {
                                            return Curry._1(self.send, /* Failure */Block.__(1, [match[0]]));
                                          } else {
                                            return Curry._1(self.send, /* Success */1);
                                          }
                                        }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                              token,
                                              /* :: */[
                                                json,
                                                /* [] */0
                                              ]
                                            ])));
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.R = R;
exports.component = component;
exports.make = make;
/* grid Not a pure module */
