// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Text$BsConsole = require("./Text.js");
var Colors$BsConsole = require("./Colors.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var Numeral$BsConsole = require("./Numeral.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");

function secondsToDays(seconds) {
  return Numeral$BsConsole.format("0.0", Pervasives.string_of_float(Caml_format.caml_int_of_string(seconds) / 86400));
}

var jsMapperConstantArray = [/* tuple */[
    328203670,
    "delete-all"
  ]];

function ruleActionToJs(param) {
  return Js_mapperRt.binarySearch(1, param, jsMapperConstantArray);
}

function ruleActionFromJs(param) {
  return Js_mapperRt.revSearch(1, jsMapperConstantArray, param);
}

var tableContainer = Css.style(/* :: */[
      Css.important(Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5))),
      /* :: */[
        Css.background(Css.hex(Colors$BsConsole.grey8)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.selector("tr", /* :: */[
                  Css.borderBottomColor(Css.hex(Colors$BsConsole.grey5)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var table = Css.style(/* [] */0);

var statusContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var status = Css.style(/* :: */[
      Css.background(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        Css.margin(Css.px(6)),
        /* :: */[
          Css.borderRadius(Css.pct(50.0)),
          /* :: */[
            Css.height(Css.px(12)),
            /* :: */[
              Css.width(Css.px(12)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var activeStatus = Css.style(/* :: */[
      Css.background(Css.hex(Colors$BsConsole.greenLight)),
      /* [] */0
    ]);

var idleStatus = Css.style(/* :: */[
      Css.background(Css.hex(Colors$BsConsole.orange)),
      /* [] */0
    ]);

var noRules = Css.style(/* :: */[
      Css.unsafe("color", Colors$BsConsole.octothorpe(Colors$BsConsole.blackA54) + " !important"),
      /* :: */[
        Css.unsafe("lineHeight", "48px !important"),
        /* [] */0
      ]
    ]);

var component = RR$BsConsole.reducerComponent("RetentionPolicyTable-BsConsole__Expansion");

function make(policyTitle, policyOpt, onAddRuleClick, onModifyRuleClick, onDeleteRuleClick, policyStatusRemote, _children) {
  var policy = policyOpt !== undefined ? Caml_option.valFromOption(policyOpt) : undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              if (policy === undefined) {
                return ;
              } else {
                return Curry._1(param.send, /* ToggleExpand */0);
              }
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var tmp;
              var exit = 0;
              if (typeof policyStatusRemote === "number" || policyStatusRemote.tag) {
                exit = 1;
              } else {
                var s = policyStatusRemote[0];
                tmp = ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                              className: Styles$BsConsole.$$Text.tooltipTitle
                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "policy state: ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "policy state: %s"
                                    ]), s.state)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                className: status + (" " + (
                                    s.state === "active" ? activeStatus : ""
                                  ))
                              })]));
              }
              if (exit === 1) {
                tmp = React.createElement("div", {
                      className: status
                    });
              }
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.ExpansionPanel.Jsx2.make, undefined, self.state.expanded, (function (param, param$1) {
                                return Curry._1(self.send, /* ToggleExpand */0);
                              }), [
                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.ExpansionPanelSummary.Jsx2.make, undefined, Caml_option.some(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ExpandMore.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subtitle1 */386038009, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(policyTitle)])),
                                        React.createElement("div", {
                                              className: statusContainer
                                            }, tmp)
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.ExpansionPanelDetails.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, tableContainer, undefined, undefined, 0, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, table, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, noRules, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Rule")]))])),
                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, "right", undefined, [onAddRuleClick !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      onAddRuleClick,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      /* Raised */-387916264,
                                                                                                      /* Primary */-791844958,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      policy !== undefined ? policy.length > 2 : false,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      [I18N$BsConsole.show(undefined, "Add rule")]
                                                                                                    ])) : null]))
                                                                              ]))])),
                                                            policy !== undefined ? Belt_Array.map(policy, (function (rule) {
                                                                      var match = rule.action.type_;
                                                                      var tmp;
                                                                      if (match) {
                                                                        tmp = Text$BsConsole.get(/* RP_Compress_All_Physical */13);
                                                                      } else {
                                                                        var s = rule.action.subsets;
                                                                        tmp = s !== undefined ? Text$BsConsole.get(Belt_Array.getExn(s, 0) === "physical" ? /* RP_Delete_All_Physical */12 : /* RP_Delete_All */11) : Text$BsConsole.get(/* RP_Delete_All */11);
                                                                      }
                                                                      var tmp$1;
                                                                      var exit = 0;
                                                                      if (typeof policyStatusRemote === "number" || policyStatusRemote.tag) {
                                                                        exit = 1;
                                                                      } else {
                                                                        tmp$1 = Belt_Array.map(Belt_Array.keep(policyStatusRemote[0].rules, (function (r) {
                                                                                    var match = r.action.subsets;
                                                                                    var match$1 = rule.action.subsets;
                                                                                    if (match !== undefined) {
                                                                                      return match$1 !== undefined;
                                                                                    } else {
                                                                                      return match$1 === undefined;
                                                                                    }
                                                                                  })), (function (r) {
                                                                                var time = r.criteria.nextObject.expiryTime;
                                                                                if (time === undefined) {
                                                                                  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                      className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                    }, I18N$BsConsole.show(undefined, "rule state: idle - awaiting new objects")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                        className: status + (" " + idleStatus)
                                                                                                      })]));
                                                                                }
                                                                                var tmp;
                                                                                if (time > 0) {
                                                                                  var x = time;
                                                                                  tmp = Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                                                            /* String_literal */Block.__(11, [
                                                                                                "- next object set to delete in ",
                                                                                                /* String */Block.__(2, [
                                                                                                    /* No_padding */0,
                                                                                                    /* End_of_format */0
                                                                                                  ])
                                                                                              ]),
                                                                                            "- next object set to delete in %s"
                                                                                          ]), DateFns.distanceInWordsToNow(undefined, undefined, new Date(x * 1000)));
                                                                                } else {
                                                                                  tmp = "";
                                                                                }
                                                                                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                            /* String_literal */Block.__(11, [
                                                                                                                "rule state: active ",
                                                                                                                /* String */Block.__(2, [
                                                                                                                    /* No_padding */0,
                                                                                                                    /* End_of_format */0
                                                                                                                  ])
                                                                                                              ]),
                                                                                                            "rule state: active %s"
                                                                                                          ]), tmp)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                      className: status + (" " + activeStatus)
                                                                                                    })]));
                                                                              }));
                                                                      }
                                                                      if (exit === 1) {
                                                                        tmp$1 = React.createElement("div", {
                                                                              className: status
                                                                            });
                                                                      }
                                                                      return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                                    tmp,
                                                                                                                    Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                                              /* String_literal */Block.__(11, [
                                                                                                                                  " after ",
                                                                                                                                  /* String */Block.__(2, [
                                                                                                                                      /* No_padding */0,
                                                                                                                                      /* String_literal */Block.__(11, [
                                                                                                                                          " days",
                                                                                                                                          /* End_of_format */0
                                                                                                                                        ])
                                                                                                                                    ])
                                                                                                                                ]),
                                                                                                                              " after %s days"
                                                                                                                            ]), secondsToDays(rule.criteria.time))
                                                                                                                  ])),
                                                                                                          React.createElement("div", {
                                                                                                                className: statusContainer
                                                                                                              }, tmp$1)
                                                                                                        ]))])),
                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, "right", undefined, [onModifyRuleClick !== undefined && onDeleteRuleClick !== undefined ? React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                                  }, I18N$BsConsole.show(undefined, "Delete rule")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          (function (param) {
                                                                                                                              return Curry._1(onDeleteRuleClick, rule);
                                                                                                                            }),
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          "Delete rule",
                                                                                                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                                        ]))]))) : null]))
                                                                                    ]));
                                                                    })) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, noRules, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "No rules exist yet")]))])),
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                      ]))
                                                          ]))]))]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      expanded: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          expanded: !state.expanded
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Expansion = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("RetentionPolicyTable-BsConsole");

function make$1(policyTitle, policyOpt, onAddRuleClick, onModifyRuleClick, onDeleteRuleClick, policyStatusRemote, user, _children) {
  var policy = policyOpt !== undefined ? Caml_option.valFromOption(policyOpt) : undefined;
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              var tmp;
              var exit = 0;
              if (typeof policyStatusRemote === "number" || policyStatusRemote.tag) {
                exit = 1;
              } else {
                var s = policyStatusRemote[0];
                tmp = ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                              className: Styles$BsConsole.$$Text.tooltipTitle
                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "policy state: ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "policy state: %s"
                                    ]), s.state)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                className: status + (" " + (
                                    s.state === "active" ? activeStatus : ""
                                  ))
                              })]));
              }
              if (exit === 1) {
                tmp = React.createElement("div", {
                      className: status
                    });
              }
              return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(policyTitle)])),
                                        React.createElement("div", {
                                              className: statusContainer
                                            }, tmp)
                                      ])),
                              React.createElement("div", {
                                    className: tableContainer,
                                    style: {
                                      marginTop: "1em"
                                    }
                                  }, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, table, undefined, [
                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, noRules, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Rule")]))])),
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, "right", undefined, [onAddRuleClick !== undefined ? ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* RetentionPolicies */Block.__(3, [user])), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                undefined,
                                                                                                undefined,
                                                                                                onAddRuleClick,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                /* Raised */-387916264,
                                                                                                /* Primary */-791844958,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                policy !== undefined ? policy.length > 2 : false,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                [I18N$BsConsole.show(undefined, "Add rule")]
                                                                                              ]))])) : null]))
                                                              ]))])),
                                            policy !== undefined ? Belt_Array.map(policy, (function (rule) {
                                                      var match = rule.action.type_;
                                                      var intro = match ? I18N$BsConsole.get(undefined, "Compress all physical object") : Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(rule.action.subsets, (function (arr) {
                                                                            return Belt_Array.get(arr, 0);
                                                                          })), (function (s) {
                                                                        return s === "physical";
                                                                      })), (function (isPhysical) {
                                                                    if (isPhysical) {
                                                                      return I18N$BsConsole.get(undefined, "Delete all physical objects");
                                                                    }
                                                                    
                                                                  })), I18N$BsConsole.get(undefined, "Delete all objects"));
                                                      var d = secondsToDays(rule.criteria.time);
                                                      var days = d === "1.0" ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                                  /* Int */Block.__(4, [
                                                                      /* Int_d */0,
                                                                      /* No_padding */0,
                                                                      /* No_precision */0,
                                                                      /* String_literal */Block.__(11, [
                                                                          " day",
                                                                          /* End_of_format */0
                                                                        ])
                                                                    ]),
                                                                  "%d day"
                                                                ]), 1) : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* String_literal */Block.__(11, [
                                                                          " days",
                                                                          /* End_of_format */0
                                                                        ])
                                                                    ]),
                                                                  "%s days"
                                                                ]), d);
                                                      var tmp;
                                                      var exit = 0;
                                                      if (typeof policyStatusRemote === "number" || policyStatusRemote.tag) {
                                                        exit = 1;
                                                      } else {
                                                        tmp = Belt_Array.map(Belt_Array.keep(policyStatusRemote[0].rules, (function (r) {
                                                                    var match = r.action.subsets;
                                                                    var match$1 = rule.action.subsets;
                                                                    if (match !== undefined) {
                                                                      return match$1 !== undefined;
                                                                    } else {
                                                                      return match$1 === undefined;
                                                                    }
                                                                  })), (function (r) {
                                                                var time = r.criteria.nextObject.expiryTime;
                                                                if (time === undefined) {
                                                                  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                      className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                    }, I18N$BsConsole.show(undefined, "rule state: idle - awaiting new objects")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                        className: status + (" " + idleStatus)
                                                                                      })]));
                                                                }
                                                                var tmp;
                                                                if (time > 0) {
                                                                  var x = time;
                                                                  tmp = Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                                            /* String_literal */Block.__(11, [
                                                                                " - next object set to delete in ",
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            " - next object set to delete in %s"
                                                                          ]), DateFns.distanceInWordsToNow(undefined, undefined, new Date(x * 1000)));
                                                                } else {
                                                                  tmp = "";
                                                                }
                                                                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                            /* String_literal */Block.__(11, [
                                                                                                "rule state: active",
                                                                                                /* String */Block.__(2, [
                                                                                                    /* No_padding */0,
                                                                                                    /* End_of_format */0
                                                                                                  ])
                                                                                              ]),
                                                                                            "rule state: active%s"
                                                                                          ]), tmp)), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                      className: status + (" " + activeStatus)
                                                                                    })]));
                                                              }));
                                                      }
                                                      if (exit === 1) {
                                                        tmp = React.createElement("div", {
                                                              className: status
                                                            });
                                                      }
                                                      return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                              /* String */Block.__(2, [
                                                                                                                  /* No_padding */0,
                                                                                                                  /* String_literal */Block.__(11, [
                                                                                                                      " after ",
                                                                                                                      /* String */Block.__(2, [
                                                                                                                          /* No_padding */0,
                                                                                                                          /* End_of_format */0
                                                                                                                        ])
                                                                                                                    ])
                                                                                                                ]),
                                                                                                              "%s after %s"
                                                                                                            ]), intro, days)])),
                                                                                          React.createElement("div", {
                                                                                                className: statusContainer
                                                                                              }, tmp)
                                                                                        ]))])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, "right", undefined, [onModifyRuleClick !== undefined && onDeleteRuleClick !== undefined ? ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* RetentionPolicies */Block.__(3, [user])), [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                        }, I18N$BsConsole.show(undefined, "Delete rule")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                (function (param) {
                                                                                                                    return Curry._1(onDeleteRuleClick, rule);
                                                                                                                  }),
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                undefined,
                                                                                                                "Delete rule",
                                                                                                                [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                              ]))]))])) : null]))
                                                                    ]));
                                                    })) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, noRules, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "No rules exist yet")]))])),
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                      ]))
                                          ])))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.secondsToDays = secondsToDays;
exports.ruleActionToJs = ruleActionToJs;
exports.ruleActionFromJs = ruleActionFromJs;
exports.tableContainer = tableContainer;
exports.table = table;
exports.statusContainer = statusContainer;
exports.status = status;
exports.activeStatus = activeStatus;
exports.idleStatus = idleStatus;
exports.noRules = noRules;
exports.Expansion = Expansion;
exports.component = component$1;
exports.make = make$1;
/* tableContainer Not a pure module */
