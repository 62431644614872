// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var SSAUtils$BsConsole = require("../SSAUtils.js");
var SSAAction$BsConsole = require("../SSAAction.js");

var helpText = "Specify the conditions to match on.";

function getNonEmptyValues(values) {
  return Belt_Array.keepMap(values, (function (maybes) {
                if (maybes !== undefined && maybes !== "") {
                  return maybes;
                }
                
              }));
}

function areUnionValuesValid(values) {
  return SSAUtils$BsConsole.isNotEmptyArr(getNonEmptyValues(values));
}

function isComplete(t) {
  var match = t.source;
  var match$1 = t.conditions;
  var match$2 = t.unionKey;
  if (match !== undefined) {
    if (typeof match === "number") {
      if (match$1 !== undefined && match$2 !== undefined && SSAAction$BsConsole.isConditionValid(match$1) && SSAUtils$BsConsole.isNotEmptyStr(match$2)) {
        return SSAUtils$BsConsole.isNotEmptyArr(getNonEmptyValues(t.unionValues));
      } else {
        return false;
      }
    } else if (match$1 !== undefined && match$2 !== undefined && SSAUtils$BsConsole.isNotEmptyStr(match[0]) && SSAAction$BsConsole.isConditionValid(match$1) && SSAUtils$BsConsole.isNotEmptyStr(match$2)) {
      return SSAUtils$BsConsole.isNotEmptyArr(getNonEmptyValues(t.unionValues));
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function toAction(t) {
  if (!isComplete(t)) {
    return ;
  }
  var match = t.source;
  var match$1 = t.conditions;
  var match$2 = t.unionKey;
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
    return /* UnionTags */Block.__(4, [/* tuple */[
                undefined,
                match,
                match$1,
                match$2,
                getNonEmptyValues(t.unionValues)
              ]]);
  }
  
}

var formItems = [
  /* Source */Block.__(0, [/* tuple */[
        SSAUtils$BsConsole.sourceText,
        helpText
      ]]),
  /* Condition */Block.__(1, [SSAUtils$BsConsole.conditionText]),
  /* SetUnionAttribute */1
];

var Module = {
  formItems: formItems,
  isComplete: isComplete,
  toAction: toAction
};

var tagsAttribute = "fingerprint;issues;tags";

exports.tagsAttribute = tagsAttribute;
exports.helpText = helpText;
exports.getNonEmptyValues = getNonEmptyValues;
exports.areUnionValuesValid = areUnionValuesValid;
exports.Module = Module;
/* SSAUtils-BsConsole Not a pure module */
