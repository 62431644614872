// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var SavedQueryDefaults$BsConsole = require("./SavedQueryDefaults.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var ProjectFrontendSettings$BsConsole = require("../ProjectFrontendSettings.js");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

var userFrontendSettingsEntity = "configuration/" + BacktraceParserGenerator$BsConsole.UserFrontendSettings.table;

var projectFrontendSettingsEntity = "configuration/" + BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.table;

function decodeActions(json) {
  return Json_decode.array((function (json$prime) {
                var action = Json_decode.field("action", Json_decode.string, json$prime);
                var type_ = Json_decode.field("type", Json_decode.string, json$prime);
                return /* tuple */[
                        action,
                        type_
                      ];
              }), json);
}

function getEntityResultJson(actions, entity, json) {
  return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getIndexBy(actions, (function (param) {
                        return Caml_obj.caml_equal(param[1], entity);
                      })), (function (idx) {
                    return Belt_Array.get(Json_decode.field("results", (function (param) {
                                      return Json_decode.array((function (json$prime) {
                                                    return json$prime;
                                                  }), param);
                                    }), json), idx);
                  })), (function (json) {
                return Json_decode.field("result", (function (json$prime) {
                              return json$prime;
                            }), json);
              }));
}

function getUserFrontendSettingsFromActions(actions, uid, json) {
  var func = function (param, param$1) {
    return getEntityResultJson(actions, param, param$1);
  };
  return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getBy(Belt_Option.getWithDefault(Belt_Option.map(Curry._2(func, userFrontendSettingsEntity, json), (function (json$prime) {
                                return Json_decode.array(BacktraceParserGenerator$BsConsole.UserFrontendSettings.of_json, json$prime);
                              })), []), (function (projectSettings) {
                        return projectSettings.owner === uid;
                      })), (function (projectSettings) {
                    return Json.parse(projectSettings.json);
                  })), UserFrontendSettings$BsConsole.ofJson);
}

function getProjectFrontendSettingsFromActions(actions, pid, json) {
  var func = function (param, param$1) {
    return getEntityResultJson(actions, param, param$1);
  };
  return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getBy(Belt_Option.getWithDefault(Belt_Option.map(Curry._2(func, projectFrontendSettingsEntity, json), (function (json$prime) {
                                return Json_decode.array(BacktraceParserGenerator$BsConsole.ProjectFrontendSettings.of_json, json$prime);
                              })), []), (function (projectSettings) {
                        return projectSettings.pid === pid;
                      })), (function (projectSettings) {
                    return Json.parse(projectSettings.json);
                  })), ProjectFrontendSettings$BsConsole.ofJson);
}

function makeGetBothSettingsTask(uid, pid) {
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "actions",
          Json_encode.array((function (entity) {
                  return Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                entity
                              ],
                              /* :: */[
                                /* tuple */[
                                  "action",
                                  "get"
                                ],
                                /* [] */0
                              ]
                            ]);
                }), [
                userFrontendSettingsEntity,
                projectFrontendSettingsEntity
              ])
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [body]), undefined, (function (param) {
                var json = param.json;
                try {
                  var actions = Json_decode.field("actions", decodeActions, json);
                  var func = function (param, param$1) {
                    return getUserFrontendSettingsFromActions(actions, param, param$1);
                  };
                  var userFrontendSettings = Curry._2(func, uid, json);
                  var func$1 = function (param, param$1) {
                    return getProjectFrontendSettingsFromActions(actions, param, param$1);
                  };
                  var projectFrontendSettings = Curry._2(func$1, pid, json);
                  return /* Ok */Block.__(0, [/* tuple */[
                              userFrontendSettings,
                              projectFrontendSettings
                            ]]);
                }
                catch (_err){
                  return /* Error */Block.__(1, [[
                              BpgTask$BsConsole.BpgError,
                              BpgTask$BsConsole.$$Error.ofInt(1),
                              "BPG decoding error"
                            ]]);
                }
              }), undefined, undefined);
}

function makeGetUserSettingsTask(uid) {
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "actions",
          Json_encode.array((function (entity) {
                  return Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                entity
                              ],
                              /* :: */[
                                /* tuple */[
                                  "action",
                                  "get"
                                ],
                                /* [] */0
                              ]
                            ]);
                }), [userFrontendSettingsEntity])
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [body]), undefined, (function (param) {
                var json = param.json;
                try {
                  var actions = Json_decode.field("actions", decodeActions, json);
                  var func = function (param, param$1) {
                    return getUserFrontendSettingsFromActions(actions, param, param$1);
                  };
                  var userFrontendSettings = Curry._2(func, uid, json);
                  return /* Ok */Block.__(0, [userFrontendSettings]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              BpgTask$BsConsole.BpgError,
                              BpgTask$BsConsole.$$Error.ofInt(1),
                              "BPG decoding error"
                            ]]);
                }
              }), undefined, undefined);
}

function makeGetProjectSettingsTask(pid) {
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "actions",
          Json_encode.array((function (entity) {
                  return Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                entity
                              ],
                              /* :: */[
                                /* tuple */[
                                  "action",
                                  "get"
                                ],
                                /* [] */0
                              ]
                            ]);
                }), [projectFrontendSettingsEntity])
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/bpg"]), undefined, /* Post */Block.__(0, [body]), undefined, (function (param) {
                var json = param.json;
                try {
                  var actions = Json_decode.field("actions", decodeActions, json);
                  var func = function (param, param$1) {
                    return getProjectFrontendSettingsFromActions(actions, param, param$1);
                  };
                  var projectFrontendSettings = Curry._2(func, pid, json);
                  return /* Ok */Block.__(0, [projectFrontendSettings]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              BpgTask$BsConsole.BpgError,
                              BpgTask$BsConsole.$$Error.ofInt(1),
                              "BPG decoding error"
                            ]]);
                }
              }), undefined, undefined);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* FetchSettings */0 :
          return {
                  settingsRemote: /* Loading */1,
                  userSettingsRemote: /* Loading */1,
                  projectSettingsRemote: /* Loading */1
                };
      case /* SettingsFailure */1 :
          return {
                  settingsRemote: /* Success */Block.__(0, [/* tuple */[
                        undefined,
                        undefined
                      ]]),
                  userSettingsRemote: /* Success */Block.__(0, [undefined]),
                  projectSettingsRemote: /* Success */Block.__(0, [undefined])
                };
      case /* FetchUserSettings */2 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: /* Loading */1,
                  projectSettingsRemote: state.projectSettingsRemote
                };
      case /* UserSettingsFailure */3 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: /* Success */Block.__(0, [undefined]),
                  projectSettingsRemote: state.projectSettingsRemote
                };
      case /* FetchProjectSettings */4 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: state.userSettingsRemote,
                  projectSettingsRemote: /* Loading */1
                };
      case /* ProjectSettingsFailure */5 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: state.userSettingsRemote,
                  projectSettingsRemote: /* Success */Block.__(0, [undefined])
                };
      
    }
  } else {
    switch (action.tag | 0) {
      case /* SettingsSuccess */0 :
          var projectSettings = action[1];
          var userSettings = action[0];
          return {
                  settingsRemote: /* Success */Block.__(0, [/* tuple */[
                        userSettings,
                        projectSettings
                      ]]),
                  userSettingsRemote: /* Success */Block.__(0, [userSettings]),
                  projectSettingsRemote: /* Success */Block.__(0, [projectSettings])
                };
      case /* UserSettingsSuccess */1 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: /* Success */Block.__(0, [action[0]]),
                  projectSettingsRemote: state.projectSettingsRemote
                };
      case /* ProjectSettingsSuccess */2 :
          return {
                  settingsRemote: state.settingsRemote,
                  userSettingsRemote: state.userSettingsRemote,
                  projectSettingsRemote: /* Success */Block.__(0, [action[0]])
                };
      
    }
  }
}

var initialState = {
  settingsRemote: /* NotAsked */0,
  userSettingsRemote: /* NotAsked */0,
  projectSettingsRemote: /* NotAsked */0
};

function useFetchProjectSettings(token, uid, pid) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var projectSettingsRemote = match$1.projectSettingsRemote;
  var userSettingsRemote = match$1.userSettingsRemote;
  var settingsRemote = match$1.settingsRemote;
  React.useEffect((function () {
          if (typeof settingsRemote === "number") {
            if (settingsRemote !== 0) {
              if (pid !== undefined) {
                var onSuccess = function (param) {
                  return Curry._1(dispatch, /* SettingsSuccess */Block.__(0, [
                                param[0],
                                param[1]
                              ]));
                };
                var onFailure = function (msg) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueriesProjectSettings/useFetchProjectSettings.makeGetBothSettingsTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        msg
                      ]);
                  return Curry._1(dispatch, /* SettingsFailure */1);
                };
                var arg = makeGetBothSettingsTask(uid, pid);
                Curry._2((function (param) {
                          return (function (param$1, param$2) {
                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                            });
                        })(token), undefined, (function (param) {
                        return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                      }));
              }
              
            } else if (pid !== undefined) {
              Curry._1(dispatch, /* FetchSettings */0);
            }
            
          }
          
        }), /* tuple */[
        settingsRemote,
        pid
      ]);
  React.useEffect((function () {
          if (typeof userSettingsRemote === "number" && userSettingsRemote !== 0) {
            var onSuccess = function (userSettings) {
              return Curry._1(dispatch, /* UserSettingsSuccess */Block.__(1, [userSettings]));
            };
            var onFailure = function (msg) {
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueriesProjectSettings/useFetchProjectSettings.makeGetUserSettingsTask", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    msg
                  ]);
              return Curry._1(dispatch, /* UserSettingsFailure */3);
            };
            var arg = makeGetUserSettingsTask(uid);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (param) {
                    return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
          }
          
        }), [userSettingsRemote]);
  React.useEffect((function () {
          if (typeof projectSettingsRemote === "number" && projectSettingsRemote !== 0 && pid !== undefined) {
            var onSuccess = function (projectSettings) {
              return Curry._1(dispatch, /* ProjectSettingsSuccess */Block.__(2, [projectSettings]));
            };
            var onFailure = function (msg) {
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueriesProjectSettings/useFetchProjectSettings.makeGetProjectSettingsTask", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    msg
                  ]);
              return Curry._1(dispatch, /* ProjectSettingsFailure */5);
            };
            var arg = makeGetProjectSettingsTask(pid);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (param) {
                    return BpgTask$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
          }
          
        }), /* tuple */[
        projectSettingsRemote,
        pid
      ]);
  var refetchSettings = function (param) {
    return Curry._1(dispatch, /* FetchSettings */0);
  };
  var refetchUserSettings = function (param) {
    return Curry._1(dispatch, /* FetchUserSettings */2);
  };
  var refetchProjectSettings = function (param) {
    return Curry._1(dispatch, /* FetchProjectSettings */4);
  };
  return {
          userSettingsRemote: userSettingsRemote,
          projectSettingsRemote: projectSettingsRemote,
          refetchSettings: refetchSettings,
          refetchUserSettings: refetchUserSettings,
          refetchProjectSettings: refetchProjectSettings
        };
}

function use(token, pid, config, attributes, projectName) {
  var match = React.useState((function () {
          
        }));
  var setProjectSettings = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setUserSettings = match$1[1];
  var match$2 = useFetchProjectSettings(token, config.user.uid, pid);
  var refetchProjectSettings = match$2.refetchProjectSettings;
  var refetchUserSettings = match$2.refetchUserSettings;
  var projectSettingsRemote = match$2.projectSettingsRemote;
  var userSettingsRemote = match$2.userSettingsRemote;
  React.useEffect((function () {
          if (typeof projectSettingsRemote !== "number" && !projectSettingsRemote.tag && pid !== undefined) {
            var projectSettings = projectSettingsRemote[0];
            if (Belt_List.length(attributes) > 0) {
              var match = config.user.role;
              var isAdminOrGreater;
              if (match !== undefined) {
                switch (match) {
                  case "admin" :
                  case "superuser" :
                      isAdminOrGreater = true;
                      break;
                  default:
                    isAdminOrGreater = false;
                }
              } else {
                isAdminOrGreater = false;
              }
              var settings = Belt_Option.getWithDefault(projectSettings, ProjectFrontendSettings$BsConsole.empty);
              var shouldUpdateProjectSettingsBookmarks = settings.firstTimeExperience.bookmarksVersion < SavedQueryDefaults$BsConsole.currentVersion && isAdminOrGreater;
              if (shouldUpdateProjectSettingsBookmarks) {
                var settings$1 = Belt_Option.getWithDefault(projectSettings, ProjectFrontendSettings$BsConsole.empty);
                var bookmarksToAdd = SavedQueryDefaults$BsConsole.getNamesToAddAsBookmarks(settings$1.versionAttribute, settings$1.normBy, attributes, settings$1.firstTimeExperience.bookmarksVersion);
                var arg = Belt_Option.getWithDefault(projectSettings, ProjectFrontendSettings$BsConsole.empty);
                var newSettings = (function (param) {
                      return ProjectFrontendSettings$BsConsole.getNextSettings(param, arg);
                    })(/* :: */[
                      /* AddBookmarksWithDefaults */Block.__(0, [
                          bookmarksToAdd,
                          SavedQueryDefaults$BsConsole.currentVersion
                        ]),
                      /* [] */0
                    ]);
                if (projectSettings !== undefined) {
                  var arg$1 = BpgTask$BsConsole.ProjectFrontendSettings.updateFieldsByKey({
                        pid: pid
                      }, [/* tuple */[
                          "json",
                          ProjectFrontendSettings$BsConsole.toJson(newSettings)
                        ]]);
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                              });
                          })(token), undefined, (function (_res) {
                          return Curry._1(refetchProjectSettings, undefined);
                        }));
                } else {
                  var arg$2 = BpgTask$BsConsole.ProjectFrontendSettings.create(ProjectFrontendSettings$BsConsole.toBPG(newSettings, pid));
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg$2, param$1, param$2);
                              });
                          })(token), true, (function (_res) {
                          return Curry._1(refetchProjectSettings, undefined);
                        }));
                }
              }
              
            }
            
          }
          
        }), /* tuple */[
        projectSettingsRemote,
        attributes,
        pid
      ]);
  React.useEffect((function () {
          if (typeof userSettingsRemote !== "number" && !userSettingsRemote.tag && typeof projectSettingsRemote !== "number" && !projectSettingsRemote.tag) {
            var userSettings = userSettingsRemote[0];
            if (Belt_List.length(attributes) > 0) {
              var arg = Belt_Option.getWithDefault(userSettings, UserFrontendSettings$BsConsole.empty);
              var userFirstTimeExperienceBookmarsVersion = (function (param) {
                    return UserFrontendSettings$BsConsole.getFirstTimeUserExperience(param, arg);
                  })(projectName).bookmarksVersion;
              var shouldUpdateUserSettingsBookmarks = userFirstTimeExperienceBookmarsVersion < SavedQueryDefaults$BsConsole.currentVersion;
              if (shouldUpdateUserSettingsBookmarks) {
                var settings = Belt_Option.getWithDefault(projectSettingsRemote[0], ProjectFrontendSettings$BsConsole.empty);
                var bookmarksToAdd = SavedQueryDefaults$BsConsole.getNamesToAddAsBookmarks(settings.versionAttribute, settings.normBy, attributes, userFirstTimeExperienceBookmarsVersion);
                var arg$1 = Belt_Option.getWithDefault(userSettings, UserFrontendSettings$BsConsole.empty);
                var newSettings = (function (param) {
                      return UserFrontendSettings$BsConsole.getNextSettings(param, arg$1);
                    })(/* :: */[
                      /* AddBookmarksWithDefaults */Block.__(0, [
                          projectName,
                          bookmarksToAdd,
                          SavedQueryDefaults$BsConsole.currentVersion
                        ]),
                      /* [] */0
                    ]);
                if (userSettings !== undefined) {
                  var arg$2 = BpgTask$BsConsole.UserFrontendSettings.updateFieldsByKey({
                        owner: config.user.uid
                      }, [/* tuple */[
                          "json",
                          UserFrontendSettings$BsConsole.toJson(newSettings)
                        ]]);
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg$2, param$1, param$2);
                              });
                          })(token), undefined, (function (_res) {
                          return Curry._1(refetchUserSettings, undefined);
                        }));
                } else {
                  var arg$3 = BpgTask$BsConsole.UserFrontendSettings.create(UserFrontendSettings$BsConsole.toBPG(newSettings, config.user.uid));
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg$3, param$1, param$2);
                              });
                          })(token), true, (function (_res) {
                          return Curry._1(refetchUserSettings, undefined);
                        }));
                }
              }
              
            }
            
          }
          
        }), /* tuple */[
        userSettingsRemote,
        projectSettingsRemote,
        attributes
      ]);
  React.useEffect((function () {
          if (typeof projectSettingsRemote !== "number" && !projectSettingsRemote.tag) {
            var settings$prime = projectSettingsRemote[0];
            Curry._1(setProjectSettings, (function (param) {
                    return settings$prime;
                  }));
          }
          
        }), [projectSettingsRemote]);
  React.useEffect((function () {
          if (typeof userSettingsRemote !== "number" && !userSettingsRemote.tag) {
            var settings$prime = userSettingsRemote[0];
            Curry._1(setUserSettings, (function (param) {
                    return settings$prime;
                  }));
          }
          
        }), [userSettingsRemote]);
  return /* tuple */[
          match[0],
          refetchProjectSettings,
          match$1[0],
          refetchUserSettings
        ];
}

exports.userFrontendSettingsEntity = userFrontendSettingsEntity;
exports.projectFrontendSettingsEntity = projectFrontendSettingsEntity;
exports.decodeActions = decodeActions;
exports.getEntityResultJson = getEntityResultJson;
exports.getUserFrontendSettingsFromActions = getUserFrontendSettingsFromActions;
exports.getProjectFrontendSettingsFromActions = getProjectFrontendSettingsFromActions;
exports.makeGetBothSettingsTask = makeGetBothSettingsTask;
exports.makeGetUserSettingsTask = makeGetUserSettingsTask;
exports.makeGetProjectSettingsTask = makeGetProjectSettingsTask;
exports.reducer = reducer;
exports.initialState = initialState;
exports.useFetchProjectSettings = useFetchProjectSettings;
exports.use = use;
/* react Not a pure module */
