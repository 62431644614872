// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Email$BsConsole = require("../Email.js");
var Column$BsConsole = require("../utilities/Column.js");
var BtSettings$BsConsole = require("../BtSettings.js");

var component = RR$BsConsole.statelessComponent("PlainTextView-BsConsole");

var diabledView = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "This feature is not enabled.")), I18N$BsConsole.show(undefined, "To learn more, please contact us at "), React.createElement("a", {
          href: "mailto:" + Email$BsConsole.support
        }, I18N$BsConsole.showSkip(Email$BsConsole.support)), I18N$BsConsole.show(undefined, " or use the chat box below. "));

var errorView = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Whoops! Something went wrong.")), I18N$BsConsole.show(undefined, "Please contact us at "), React.createElement("a", {
          href: "mailto:" + Email$BsConsole.support
        }, I18N$BsConsole.showSkip(Email$BsConsole.support)), I18N$BsConsole.show(undefined, " or use the chat box below. "));

var loaderView = React.createElement("div", {
      className: Css.style(/* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* :: */[
                  Css.marginTop(Css.px(100)),
                  /* [] */0
                ]
              ]
            ]
          ])
    }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, undefined, undefined, undefined, [])));

function make(title, view, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var tmp;
              if (typeof view === "number") {
                switch (view) {
                  case /* Error */0 :
                      tmp = errorView;
                      break;
                  case /* Loader */1 :
                      tmp = loaderView;
                      break;
                  case /* Disabled */2 :
                      tmp = diabledView;
                      break;
                  
                }
              } else {
                tmp = I18N$BsConsole.showSkip(view[0]);
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* :: */[
                                              Css.minHeight(Css.px(1000)),
                                              /* [] */0
                                            ]
                                          ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.marginLeft(Css.px(50)),
                                                              /* :: */[
                                                                Css.marginTop(Css.px(20)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      }, tmp)]))]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var mininumHeight = 1000;

exports.mininumHeight = mininumHeight;
exports.component = component;
exports.diabledView = diabledView;
exports.errorView = errorView;
exports.loaderView = loaderView;
exports.make = make;
/* component Not a pure module */
