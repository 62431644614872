// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var ApiSignup$BsConsole = require("./apiSignup.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var BpgController$BsConsole = require("./BpgController.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

var containerGrid = Css.style(/* :: */[
      Css.maxWidth(Css.px(900)),
      /* :: */[
        Css.minWidth(Css.px(550)),
        /* :: */[
          Css.marginLeft(/* auto */-1065951377),
          /* :: */[
            Css.marginTop(Css.em(3)),
            /* :: */[
              Css.marginRight(/* auto */-1065951377),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var pendingInvites = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA54)),
      /* :: */[
        Css.marginTop(Css.em(2)),
        /* :: */[
          Css.marginBottom(Css.em(1)),
          /* [] */0
        ]
      ]
    ]);

var tableContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.marginTop(Css.em(3)),
        /* [] */0
      ]
    ]);

var menuCell = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* [] */0
    ]);

var Styles = {
  containerGrid: containerGrid,
  pendingInvites: pendingInvites,
  tableContainer: tableContainer,
  menuCell: menuCell
};

var component = ReasonReact.reducerComponent("SettingsInvitesPage");

function filterSignups(search, considerations) {
  if (search.trim() !== "") {
    return Fuzz$BsConsole.fuzz(search, considerations, (function (listItem) {
                  return listItem.email;
                }));
  } else {
    return considerations;
  }
}

function make(handleChangeUrl, handleTask, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(1)),
                                            /* [] */0
                                          ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  undefined,
                                                  Css.style(/* :: */[
                                                        Css.marginRight(Css.rem(0.5)),
                                                        /* [] */0
                                                      ]),
                                                  (function (_event) {
                                                      return Curry._1(handleChangeUrl, /* SettingsUsers */6);
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Outlined */-28821822,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [
                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardBackspace.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                    I18N$BsConsole.show(undefined, "Back to user settings")
                                                  ]
                                                ]))])),
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Pending invites")])),
                              ReasonReact.element(String(self.state.cacheKey), undefined, Curry._4(BpgController$BsConsole.SignupPending.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                          var signups = state.remote;
                                          if (typeof signups === "number") {
                                            return null;
                                          }
                                          if (signups.tag) {
                                            return null;
                                          }
                                          var signups$1 = signups[0];
                                          var __x = Belt_List.toArray(signups$1);
                                          var __x$1 = filterSignups(self.state.searchString, Belt_List.toArray(signups$1));
                                          return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [
                                                          React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.display(/* flex */-1010954439),
                                                                      /* :: */[
                                                                        Css.alignItems(/* center */98248149),
                                                                        /* :: */[
                                                                          Css.justifyContent(/* spaceBetween */516682146),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ])
                                                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                                                        undefined,
                                                                        Css.style(/* :: */[
                                                                              Css.width(Css.px(300)),
                                                                              /* :: */[
                                                                                Css.marginTop(Css.px(10)),
                                                                                /* :: */[
                                                                                  Css.marginLeft(Css.px(15)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]
                                                                            ]),
                                                                        /* `String */[
                                                                          -976970511,
                                                                          self.state.searchString
                                                                        ],
                                                                        (function ($$event) {
                                                                            var value = Belt_Option.getWithDefault(Caml_option.nullable_to_opt($$event.target.value), "");
                                                                            return Curry._1(self.send, /* UpdateSearchString */[value]);
                                                                          }),
                                                                        I18N$BsConsole.get(undefined, "Search by email"),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        Caml_option.some(ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.InputAdornment.Jsx2.make, undefined, "start", [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Search.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]))),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        []
                                                                      ])), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, Css.style(/* :: */[
                                                                            Css.marginRight(Css.px(15)),
                                                                            /* [] */0
                                                                          ]), undefined, undefined, undefined, [Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                  /* String */Block.__(2, [
                                                                                      /* No_padding */0,
                                                                                      /* String_literal */Block.__(11, [
                                                                                          " of ",
                                                                                          /* String */Block.__(2, [
                                                                                              /* No_padding */0,
                                                                                              /* End_of_format */0
                                                                                            ])
                                                                                        ])
                                                                                    ]),
                                                                                  "%s of %s"
                                                                                ]), String(filterSignups(self.state.searchString, __x).length), String(Belt_List.length(signups$1)))]))),
                                                          ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "email")])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "sent")])),
                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                                      ]))])),
                                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [Belt_Array.map(__x$1, (function (signup) {
                                                                                      var x = Belt_Option.getWithDefault(signup.__create, 0);
                                                                                      return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(signup.email)])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(DateFns.distanceInWordsToNow(undefined, undefined, new Date(x * 1000)))])),
                                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, menuCell, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])), (function (onClose) {
                                                                                                                            return [
                                                                                                                                    ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                var username = signup.username;
                                                                                                                                                if (username !== undefined) {
                                                                                                                                                  Curry._2(handleTask, (function (json) {
                                                                                                                                                          var r = ApiSignup$BsConsole.decode(TaskCallback$BsConsole.getJson(json));
                                                                                                                                                          if (r === undefined) {
                                                                                                                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: Invite Email Not Sent"));
                                                                                                                                                          }
                                                                                                                                                          var match = r.status;
                                                                                                                                                          if (match === "ok") {
                                                                                                                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success: Invite Email Resent"));
                                                                                                                                                          } else {
                                                                                                                                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: Invite Email Not Sent"));
                                                                                                                                                          }
                                                                                                                                                        }), Task$BsConsole.makeTask(/* ResendInvite */Block.__(6, [username])));
                                                                                                                                                } else {
                                                                                                                                                  console.log("INVARIANT");
                                                                                                                                                }
                                                                                                                                                return Curry._1(onClose, undefined);
                                                                                                                                              }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Resend")])),
                                                                                                                                    ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.SignupPending.Delete.make, (function (param, param$1) {
                                                                                                                                                setTimeout((function (param) {
                                                                                                                                                        return Curry._1(self.send, /* BustCache */0);
                                                                                                                                                      }), 0);
                                                                                                                                                
                                                                                                                                              }), handleTask, token, (function (_state, handleSubmit, _toKey) {
                                                                                                                                                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_e) {
                                                                                                                                                                  Curry._1(handleSubmit, /* tuple */[
                                                                                                                                                                        signup,
                                                                                                                                                                        Util$BsConsole.identity
                                                                                                                                                                      ]);
                                                                                                                                                                  setTimeout((function (param) {
                                                                                                                                                                          Curry._1(onClose, undefined);
                                                                                                                                                                          return Curry._1(self.send, /* BustCache */0);
                                                                                                                                                                        }), 0);
                                                                                                                                                                  
                                                                                                                                                                }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]));
                                                                                                                                              })))
                                                                                                                                  ];
                                                                                                                          }), undefined, []))]))
                                                                                                    ]));
                                                                                    }))]))
                                                                  ]))
                                                        ]));
                                        })))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      cacheKey: 0,
                      searchString: ""
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey,
                            searchString: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey + 1 | 0,
                            searchString: state.searchString
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.filterSignups = filterSignups;
exports.make = make;
/* containerGrid Not a pure module */
