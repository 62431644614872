// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Saml$BsConsole = require("./saml.js");
var Task$BsConsole = require("./task.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Image$BsConsole = require("./components/Image.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Footer$BsConsole = require("./Footer.js");
var ApiLogin$BsConsole = require("./apiLogin.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ApiStrategies$BsConsole = require("./ApiStrategies.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");

var LoginFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.Login.validators
    });

var UnknownSvcError = Caml_exceptions.create("LoginPage-BsConsole.UnknownSvcError");

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          state: Json_decode.optional((function (param) {
                  return Json_decode.field("state", Json_decode.string, param);
                }), json),
          endpoint: Json_decode.optional((function (param) {
                  return Json_decode.field("endpoint", Json_decode.string, param);
                }), json)
        };
}

function getEndpoint(name, state, services) {
  return Belt_Option.flatMap(Belt_Array.getBy(services, (function (service) {
                    if (service.name === name) {
                      return Belt_Option.getWithDefault(service.state, "") === state;
                    } else {
                      return false;
                    }
                  })), (function (service) {
                return service.endpoint;
              }));
}

var Service = {
  fromJson: fromJson,
  getEndpoint: getEndpoint
};

var svcLayerTask = Task2$BsConsole.make(/* Api */Block.__(0, ["/svclayer"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
              /* tuple */[
                "action",
                "list"
              ],
              /* [] */0
            ])]), undefined, (function (param) {
        var response = Json_decode.field("services", (function (param) {
                return Json_decode.array(fromJson, param);
              }), param.json);
        return /* Ok */Block.__(0, [response]);
      }), undefined, undefined);

function ssoTestTask(endpoint, host) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint + ("/test?tenant=" + host)]), undefined, /* Get */0, undefined, (function (param) {
                try {
                  var response = Json_decode.field("response", (function (json) {
                          return Json_decode.field("status", Json_decode.string, json);
                        }), param.json);
                  return /* Ok */Block.__(0, [response]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [UnknownSvcError]);
                }
              }), undefined, undefined);
}

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var button = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.textTransform(/* none */-922086728),
        /* [] */0
      ]
    ]);

var signInButton = Css.merge(/* :: */[
      button,
      /* :: */[
        Css.style(/* :: */[
              Css.marginBottom(Css.px(12)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding4(Css.px(32), Css.px(32), Css.px(16), Css.px(32))),
      /* [] */0
    ]);

var input = Css.style(/* :: */[
      Css.marginLeft(Css.px(7)),
      /* [] */0
    ]);

var links = Css.style(/* :: */[
      Css.fontSize(Css.em(0.8)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var errorText = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var loginError = Css.style(/* :: */[
      Css.padding(Css.rem(1)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.errorBackground)),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.errorDark)),
            /* [] */0
          ]
        ]
      ]
    ]);

var row = Css.style(/* :: */[
      Css.marginBottom(Css.px(12)),
      /* [] */0
    ]);

var inputs = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.selector(".input-row:not(:last-of-type)", /* :: */[
              Css.marginBottom(Css.px(16)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var buttons = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  grid: grid,
  button: button,
  signInButton: signInButton,
  card: card,
  cardContent: cardContent,
  input: input,
  links: links,
  loadingContainer: loadingContainer,
  errorText: errorText,
  logoContainer: logoContainer,
  logo: logo,
  loginError: loginError,
  row: row,
  inputs: inputs,
  buttons: buttons
};

function getAuthEndpoint(param) {
  return getEndpoint("auth", "active", param);
}

function loginHref(authEndpoint, handleEndpointResult) {
  var onSuccess = function (strategies) {
    var defaultStrategy = Belt_Array.getBy(strategies, (function (strategy) {
            return strategy.isDefault;
          }));
    if (defaultStrategy === undefined) {
      return Curry._1(handleEndpointResult, undefined);
    }
    var href = authEndpoint + ("/" + defaultStrategy.loginUrl);
    return Curry._1(handleEndpointResult, href);
  };
  var onFailure = function (err) {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "loginPage/GetSSOEndpoint", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          "Error fetching auth strategies: " + err
        ]);
    return Curry._1(handleEndpointResult, undefined);
  };
  var arg = ApiStrategies$BsConsole.QueryLoginStrategies.queryLoginStrategiesTask(authEndpoint, window.location.host);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(""), undefined, (function (param) {
                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
              }));
}

var AuthService = {
  getAuthEndpoint: getAuthEndpoint,
  loginHref: loginHref
};

function getSsoEndpoint(param) {
  return getEndpoint("sso", "active", param);
}

function loginHref$1(services, handleTestEndpoint, handleEndpointResult) {
  var endpoint = getEndpoint("sso", "active", services);
  if (endpoint !== undefined) {
    return Curry._1(handleTestEndpoint, endpoint);
  } else {
    return Curry._1(handleEndpointResult, Belt_Option.map(Saml$BsConsole.provider, (function (provider) {
                      return provider.url;
                    })));
  }
}

var SsoService = {
  getSsoEndpoint: getSsoEndpoint,
  loginHref: loginHref$1
};

var component = ReasonReact.reducerComponent("LoginPage-BsConsole");

function make(handleTask, handleLoginSuccess, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* GetSSOEndpoint */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var msg = self.state.remote;
              var tmp;
              tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, loginError, [I18N$BsConsole.showSkip(msg[0])]));
              return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(Css.merge(/* :: */[
                                  grid,
                                  /* :: */[
                                    "login-container",
                                    /* [] */0
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([]))])),
                                                  tmp,
                                                  ReasonReact.element(undefined, undefined, Curry._3(LoginFormContainer.make, {
                                                            username: "",
                                                            password: ""
                                                          }, (function (state, param) {
                                                              var notifyOnFailure = param.notifyOnFailure;
                                                              return Curry._1(self.send, /* LoginRequest */Block.__(1, [
                                                                            state.username,
                                                                            state.password,
                                                                            (function (param) {
                                                                                return Curry._2(notifyOnFailure, /* [] */0, undefined);
                                                                              })
                                                                          ]));
                                                            }), (function (form) {
                                                              var showError = function (field) {
                                                                var match = Curry._1(form.result, field);
                                                                if (match !== undefined && match.tag) {
                                                                  return true;
                                                                } else {
                                                                  return false;
                                                                }
                                                              };
                                                              var showErrorMsg = function (field) {
                                                                var match = Curry._1(form.result, field);
                                                                if (match !== undefined && match.tag) {
                                                                  return I18N$BsConsole.showSkip(match[0]);
                                                                } else {
                                                                  return I18N$BsConsole.showEmpty(undefined);
                                                                }
                                                              };
                                                              var partial_arg = form.submit;
                                                              var msg = self.state.ssoEndpointRemote;
                                                              var tmp;
                                                              var exit = 0;
                                                              if (typeof msg === "number") {
                                                                exit = 1;
                                                              } else if (msg.tag) {
                                                                tmp = ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.showSkip(msg[0]), undefined, undefined, undefined, undefined, undefined, [React.createElement("span", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                        /* Medium */861718677,
                                                                                        Css.merge(/* :: */[
                                                                                              Css.style(/* :: */[
                                                                                                    Css.display(/* flex */-1010954439),
                                                                                                    /* :: */[
                                                                                                      Css.alignContent(/* center */98248149),
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ]),
                                                                                              /* :: */[
                                                                                                button,
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]),
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        /* Flat */781662169,
                                                                                        /* Primary */-791844958,
                                                                                        "submit",
                                                                                        undefined,
                                                                                        true,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        [React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Sign in with SSO"))]
                                                                                      ])))]));
                                                              } else {
                                                                var href = msg[0];
                                                                tmp = href !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            /* Medium */861718677,
                                                                            button,
                                                                            (function (param) {
                                                                                return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SSOLoginClick */115]));
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            /* Flat */781662169,
                                                                            /* Primary */-791844958,
                                                                            "submit",
                                                                            undefined,
                                                                            undefined,
                                                                            href,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [I18N$BsConsole.show(undefined, "Sign in with SSO")]
                                                                          ])) : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            /* Medium */861718677,
                                                                            Css.merge(/* :: */[
                                                                                  Css.style(/* :: */[
                                                                                        Css.display(/* flex */-1010954439),
                                                                                        /* :: */[
                                                                                          Css.alignContent(/* center */98248149),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]),
                                                                                  /* :: */[
                                                                                    button,
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Flat */781662169,
                                                                            /* Primary */-791844958,
                                                                            "submit",
                                                                            undefined,
                                                                            true,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [React.createElement("span", undefined, I18N$BsConsole.show(undefined, "Sign in with SSO"))]
                                                                          ]));
                                                              }
                                                              if (exit === 1) {
                                                                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                          /* Medium */861718677,
                                                                          Css.merge(/* :: */[
                                                                                Css.style(/* :: */[
                                                                                      Css.display(/* flex */-1010954439),
                                                                                      /* :: */[
                                                                                        Css.alignContent(/* center */98248149),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]),
                                                                                /* :: */[
                                                                                  button,
                                                                                  /* [] */0
                                                                                ]
                                                                              ]),
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          /* Flat */781662169,
                                                                          /* Primary */-791844958,
                                                                          "submit",
                                                                          undefined,
                                                                          true,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [React.createElement("span", {
                                                                                  className: Css.style(/* :: */[
                                                                                        Css.position(/* relative */903134412),
                                                                                        /* [] */0
                                                                                      ])
                                                                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 16, "inherit", undefined, Css.style(/* :: */[
                                                                                              Css.position(/* absolute */-1013592457),
                                                                                              /* :: */[
                                                                                                Css.left(Css.px(-24)),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]), [])), I18N$BsConsole.show(undefined, "Sign in with SSO"))]
                                                                        ]));
                                                              }
                                                              return React.createElement("form", {
                                                                          style: {
                                                                            width: "100%"
                                                                          },
                                                                          onSubmit: (function (param) {
                                                                              return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                                            })
                                                                        }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, inputs, [
                                                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, "input-row", [
                                                                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Person.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                      input,
                                                                                                      undefined,
                                                                                                      I18N$BsConsole.get(undefined, "Username"),
                                                                                                      true,
                                                                                                      (function ($$event) {
                                                                                                          return Curry._2(form.change, /* Username */0, Curry._2(AuthForms$BsConsole.Login.Username.update, form.state, $$event.target.value));
                                                                                                        }),
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      showError(/* Username */0),
                                                                                                      Caml_option.some(showErrorMsg(/* Username */0)),
                                                                                                      "text",
                                                                                                      /* `String */[
                                                                                                        -976970511,
                                                                                                        form.state.username
                                                                                                      ],
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      []
                                                                                                    ]))
                                                                                          ])),
                                                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, "input-row", [
                                                                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Lock.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                      input,
                                                                                                      undefined,
                                                                                                      I18N$BsConsole.get(undefined, "Password"),
                                                                                                      true,
                                                                                                      (function ($$event) {
                                                                                                          return Curry._2(form.change, /* Password */1, Curry._2(AuthForms$BsConsole.Login.Password.update, form.state, $$event.target.value));
                                                                                                        }),
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      showError(/* Password */1),
                                                                                                      Caml_option.some(showErrorMsg(/* Password */1)),
                                                                                                      "password",
                                                                                                      /* `String */[
                                                                                                        -976970511,
                                                                                                        form.state.password
                                                                                                      ],
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      undefined,
                                                                                                      []
                                                                                                    ]))
                                                                                          ]))
                                                                                ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, buttons, [
                                                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                            /* Medium */861718677,
                                                                                            signInButton,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            /* Raised */-387916264,
                                                                                            /* Primary */-791844958,
                                                                                            "submit",
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            [I18N$BsConsole.show(undefined, "Sign in")]
                                                                                          ])),
                                                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, row, [
                                                                                            React.createElement("div", {
                                                                                                  style: {
                                                                                                    borderBottom: "1px solid " + Colors$BsConsole.octothorpe(Colors$BsConsole.grey605),
                                                                                                    marginLeft: "12px",
                                                                                                    flexGrow: "5"
                                                                                                  }
                                                                                                }),
                                                                                            React.createElement("div", {
                                                                                                  style: {
                                                                                                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.grey605),
                                                                                                    textAlign: "center",
                                                                                                    flexGrow: "1"
                                                                                                  }
                                                                                                }, I18N$BsConsole.show(undefined, "OR")),
                                                                                            React.createElement("div", {
                                                                                                  style: {
                                                                                                    borderBottom: "1px solid " + Colors$BsConsole.octothorpe(Colors$BsConsole.grey605),
                                                                                                    marginRight: "12px",
                                                                                                    flexGrow: "5"
                                                                                                  }
                                                                                                })
                                                                                          ])),
                                                                                  tmp
                                                                                ])));
                                                            }))),
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, links, [
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      /* Small */311976103,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(handleChangeUrl, /* Signup */0);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      /* Flat */781662169,
                                                                      /* Primary */-791844958,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      true,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Create account")]
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      /* Small */311976103,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          return Curry._1(handleChangeUrl, /* Recover */1);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      /* Flat */781662169,
                                                                      /* Primary */-791844958,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      true,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Forgot password?")]
                                                                    ]))
                                                          ]))
                                                ]))])),
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                            Css.padding(Css.rem(1)),
                                            /* [] */0
                                          ]), [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.getWithDefault(WindowVariables$BsConsole.loginIndividualizedText, ""))]))])),
                              ReasonReact.element(undefined, undefined, Footer$BsConsole.make([]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0,
                      saml: Saml$BsConsole.provider,
                      ssoEndpoint: undefined,
                      ssoEndpointRemote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: state.remote,
                            saml: state.saml,
                            ssoEndpoint: state.ssoEndpoint,
                            ssoEndpointRemote: /* Loading */1
                          },
                          (function (self) {
                              return Task2$BsConsole.handle("", svcLayerTask, undefined, (function (resp) {
                                            if (resp.tag) {
                                              return Curry._1(self.send, /* SSOEndpointResult */Block.__(5, [undefined]));
                                            }
                                            var services = resp[0];
                                            var maybeAuthEndpoint = getEndpoint("auth", "active", services);
                                            var maybeSsoEndpoint = getEndpoint("sso", "active", services);
                                            var match = Gate$BsConsole.isWfBeta(undefined);
                                            var exit = 0;
                                            var authEndpoint;
                                            if (maybeAuthEndpoint !== undefined && (match || maybeSsoEndpoint === undefined)) {
                                              authEndpoint = maybeAuthEndpoint;
                                              exit = 2;
                                            } else {
                                              exit = 1;
                                            }
                                            switch (exit) {
                                              case 1 :
                                                  return loginHref$1(services, (function (endpoint) {
                                                                return Curry._1(self.send, /* TestSSOEndpoint */Block.__(4, [endpoint]));
                                                              }), (function (result) {
                                                                return Curry._1(self.send, /* SSOEndpointResult */Block.__(5, [result]));
                                                              }));
                                              case 2 :
                                                  return loginHref(authEndpoint, (function (maybeHref) {
                                                                return Curry._1(self.send, /* SSOEndpointResult */Block.__(5, [maybeHref]));
                                                              }));
                                              
                                            }
                                          }));
                            })
                        ]);
              }
              switch (action.tag | 0) {
                case /* SetSSOEndpoint */0 :
                    return /* Update */Block.__(0, [{
                                remote: state.remote,
                                saml: state.saml,
                                ssoEndpoint: action[0],
                                ssoEndpointRemote: state.ssoEndpointRemote
                              }]);
                case /* LoginRequest */1 :
                    var onFailure = action[2];
                    var password = action[1];
                    var username = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1,
                                saml: state.saml,
                                ssoEndpoint: state.ssoEndpoint,
                                ssoEndpointRemote: state.ssoEndpointRemote
                              },
                              (function (self) {
                                  return Curry._2(handleTask, (function (json) {
                                                var login = ApiLogin$BsConsole.decode(TaskCallback$BsConsole.getJson(json));
                                                if (login !== undefined) {
                                                  Curry._1(self.send, /* LoginSuccess */Block.__(2, [login]));
                                                  return Curry._1(handleLoginSuccess, login.token);
                                                }
                                                Curry._1(onFailure, undefined);
                                                var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                                if (error !== undefined) {
                                                  return Curry._1(self.send, /* LoginFailure */Block.__(3, [error.message]));
                                                } else {
                                                  return Curry._1(self.send, /* LoginFailure */Block.__(3, [I18N$BsConsole.get(undefined, "generic error message")]));
                                                }
                                              }), Task$BsConsole.makeTask(/* Login */Block.__(7, [
                                                    username,
                                                    password
                                                  ])));
                                })
                            ]);
                case /* LoginSuccess */2 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [action[0]]),
                                saml: state.saml,
                                ssoEndpoint: state.ssoEndpoint,
                                ssoEndpointRemote: state.ssoEndpointRemote
                              },
                              (function (param) {
                                  return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UserLogin_Success */33]));
                                })
                            ]);
                case /* LoginFailure */3 :
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Failure */Block.__(1, [action[0]]),
                                saml: state.saml,
                                ssoEndpoint: state.ssoEndpoint,
                                ssoEndpointRemote: state.ssoEndpointRemote
                              },
                              (function (param) {
                                  return MetricsEvent$BsConsole.forceSend(/* Action */Block.__(0, [/* UserLogin_Failure */34]));
                                })
                            ]);
                case /* TestSSOEndpoint */4 :
                    var endpoint = action[0];
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  var arg = ssoTestTask(endpoint, window.location.host);
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(""), undefined, (function (resp) {
                                                if (resp.tag) {
                                                  return Curry._1(self.send, /* SSOEndpointResult */Block.__(5, [undefined]));
                                                }
                                                var href = endpoint + ("/saml/login/" + window.location.host);
                                                return Curry._1(self.send, /* SSOEndpointResult */Block.__(5, [href]));
                                              }));
                                })]);
                case /* SSOEndpointResult */5 :
                    return /* Update */Block.__(0, [{
                                remote: state.remote,
                                saml: state.saml,
                                ssoEndpoint: state.ssoEndpoint,
                                ssoEndpointRemote: /* Success */Block.__(0, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.LoginFormContainer = LoginFormContainer;
exports.UnknownSvcError = UnknownSvcError;
exports.Service = Service;
exports.svcLayerTask = svcLayerTask;
exports.ssoTestTask = ssoTestTask;
exports.Styles = Styles;
exports.AuthService = AuthService;
exports.SsoService = SsoService;
exports.component = component;
exports.make = make;
/* LoginFormContainer Not a pure module */
