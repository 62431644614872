// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../Task2.js");
var Nemesis$BsConsole = require("./Nemesis.js");
var BillingConstants$BsConsole = require("../BillingConstants.js");

function ofJson(json) {
  return {
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

function update(flagValue) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "flagValue",
          flagValue
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(BillingConstants$BsConsole.nemesisUrl) + "/invoice-me")]), undefined, /* Post */Block.__(0, [json]), [/* tuple */[
                "x-coroner-location",
                location.origin
              ]], (function (param) {
                try {
                  var r = ofJson(param.json);
                  var match = r.status;
                  if (match === "OK") {
                    return /* Ok */Block.__(0, [r]);
                  } else {
                    return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                  }
                }
                catch (exn){
                  return /* Error */Block.__(1, [Nemesis$BsConsole.UnknownSvcError]);
                }
              }), undefined, undefined);
}

exports.ofJson = ofJson;
exports.update = update;
/* Task2-BsConsole Not a pure module */
