// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ReactGridLayout$BsConsole = require("./ReactGridLayout.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

var QueryParseError = Caml_exceptions.create("ApiOverview-BsConsole.QueryParseError");

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          key: Json_decode.field("key", Json_decode.string, json),
          value: Json_decode.field("val", Json_decode.string, json),
          universeName: Json_decode.field("universe_name", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          projectName: Json_decode.field("project_name", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

var QueryItemConfig = {
  decode: decode
};

function variantFromString(param) {
  switch (param) {
    case "compare_group_table" :
        return /* Compare_Group_Table */9;
    case "distribution" :
        return /* Distribution */5;
    case "distribution_triage" :
        return /* Distribution_Triage */6;
    case "explore" :
        return /* Explore */0;
    case "grouped_bin_chart" :
        return /* GroupedBinChart */2;
    case "grouped_summed_event_bin_chart" :
        return /* Grouped_Summed_Event_Bin_Chart */12;
    case "grouped_unique_event_usage_bin_chart" :
        return /* Grouped_Unique_Event_Usage_Bin_Chart */11;
    case "summary" :
        return /* Summary */3;
    case "summary_v2" :
        return /* Summary_V2 */4;
    case "summed_event_bin_chart" :
        return /* Summed_Event_Bin_Chart */10;
    case "summed_event_point" :
        return /* Summed_Event_Point */7;
    case "triage" :
        return /* Triage */1;
    case "unique_event_point" :
        return /* Unique_Event_Point */8;
    default:
      return /* Explore */0;
  }
}

function toCrdb(json) {
  var aperture = Crdb$BsConsole.Aperture.$$default;
  var group = Json_decode.optional((function (param) {
          return Json_decode.field("group", Crdb$BsConsole.Factor.fromCrdb, param);
        }), json);
  var fold = Json_decode.optional((function (param) {
          return Json_decode.field("fold", Crdb$BsConsole.Fold.fromCrdb, param);
        }), json);
  var select = Json_decode.optional((function (param) {
          return Json_decode.field("select", Crdb$BsConsole.Select.fromCrdb, param);
        }), json);
  var sortOrder = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("order", (function (param) {
                            return Crdb$BsConsole.Sort.fromCrdb(fold, param);
                          }), param);
            }), json), undefined);
  if (fold !== undefined) {
    if (select === undefined) {
      return /* Aggregate */Block.__(0, [
                aperture,
                group,
                Caml_option.valFromOption(fold),
                sortOrder,
                undefined,
                undefined
              ]);
    }
    
  } else if (group === undefined && select !== undefined) {
    return /* Select */Block.__(1, [
              aperture,
              select,
              sortOrder,
              undefined
            ]);
  }
  return /* Aggregate */Block.__(0, [
            aperture,
            undefined,
            Crdb$BsConsole.Fold.empty,
            sortOrder,
            undefined,
            undefined
          ]);
}

function decode$1(json) {
  var __x = Json_decode.field("layouts", (function (param) {
          return Json_decode.dict(ReactGridLayout$BsConsole.Layout.Item.ofJson, param);
        }), json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          query: toCrdb(JSON.parse(Json_decode.field("query", Json_decode.string, json))),
          queryJson: JSON.parse(Json_decode.field("query", Json_decode.string, json)),
          queryItemConfigs: Json_decode.optional((function (param) {
                  return Json_decode.field("query_item_configs", (function (param) {
                                return Json_decode.list(decode, param);
                              }), param);
                }), json),
          variant: variantFromString(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("variant", Json_decode.string, param);
                        }), json), "explore")),
          layoutsJs: Js_dict.map((function (l) {
                  return Curry._1(ReactGridLayout$BsConsole.Layout.Item.toJs, l);
                }), __x)
        };
}

function getGroupFromConfig(t$prime) {
  return Belt_Option.flatMap(t$prime.queryItemConfigs, (function (configs) {
                return Belt_Option.map(Belt_List.head(Belt_List.sort(Belt_List.keep(configs, (function (c) {
                                          return c.key === "group";
                                        })), (function (c, param) {
                                      var match = c.projectName;
                                      var match$1 = c.universeName;
                                      if (match !== undefined && match === "backtrace_default" && match$1 === "backtrace_default") {
                                        return 1;
                                      } else {
                                        return -1;
                                      }
                                    }))), (function (c) {
                              return c.value;
                            }));
              }));
}

function getBacktraceDefaultGroupFromConfig(t$prime) {
  return Belt_Option.flatMap(t$prime.queryItemConfigs, (function (configs) {
                return Belt_Option.map(Belt_List.head(Belt_List.keep(configs, (function (c) {
                                      if (c.key === "group" && Caml_obj.caml_equal(c.projectName, "backtrace_default")) {
                                        return Caml_obj.caml_equal(c.universeName, "backtrace_default");
                                      } else {
                                        return false;
                                      }
                                    }))), (function (c) {
                              return c.value;
                            }));
              }));
}

var QueryItem = {
  variantFromString: variantFromString,
  toCrdb: toCrdb,
  decode: decode$1,
  getGroupFromConfig: getGroupFromConfig,
  getBacktraceDefaultGroupFromConfig: getBacktraceDefaultGroupFromConfig
};

var defaultBreakpoints = Js_dict.fromList(/* :: */[
      /* tuple */[
        "xs",
        1100
      ],
      /* :: */[
        /* tuple */[
          "sm",
          1200
        ],
        /* :: */[
          /* tuple */[
            "md",
            1300
          ],
          /* :: */[
            /* tuple */[
              "lg",
              1800
            ],
            /* [] */0
          ]
        ]
      ]
    ]);

var defaultCols = Js_dict.fromList(/* :: */[
      /* tuple */[
        "xs",
        4
      ],
      /* :: */[
        /* tuple */[
          "sm",
          6
        ],
        /* :: */[
          /* tuple */[
            "md",
            10
          ],
          /* :: */[
            /* tuple */[
              "lg",
              12
            ],
            /* [] */0
          ]
        ]
      ]
    ]);

function decode$2(json) {
  var layouts = Json_decode.field("layouts", (function (param) {
          return Json_decode.dict((function (param) {
                        return Json_decode.array(ReactGridLayout$BsConsole.Layout.Item.ofJson, param);
                      }), param);
        }), json);
  var layoutsJs = Js_dict.map((function (layoutsArr) {
          return Belt_Array.map(layoutsArr, (function (l) {
                        return Curry._1(ReactGridLayout$BsConsole.Layout.Item.toJs, l);
                      }));
        }), layouts);
  return {
          version: Json_decode.field("version", Json_decode.string, json),
          queries: Json_decode.field("queries", (function (param) {
                  return Json_decode.array(decode$1, param);
                }), json),
          layoutsJs: layoutsJs,
          breakpoints: Belt_Option.getWithDefault(Json_decode.optional(Json_decode.at(/* :: */[
                        "gridLayout",
                        /* [] */0
                      ], (function (param) {
                          return Json_decode.field("breakpoints", (function (param) {
                                        return Json_decode.dict(Json_decode.$$int, param);
                                      }), param);
                        })), json), defaultBreakpoints),
          cols: Belt_Option.getWithDefault(Json_decode.optional(Json_decode.at(/* :: */[
                        "gridLayout",
                        /* [] */0
                      ], (function (param) {
                          return Json_decode.field("cols", (function (param) {
                                        return Json_decode.dict(Json_decode.$$int, param);
                                      }), param);
                        })), json), defaultCols),
          rowHeight: Belt_Option.getWithDefault(Json_decode.optional(Json_decode.at(/* :: */[
                        "gridLayout",
                        /* [] */0
                      ], (function (param) {
                          return Json_decode.field("rowHeight", Json_decode.$$int, param);
                        })), json), 150)
        };
}

var QueryList = {
  defaultBreakpoints: defaultBreakpoints,
  defaultCols: defaultCols,
  defaultRowHeight: 150,
  decode: decode$2
};

function decodeAggregate(factor, json) {
  var f = Parse$BsConsole.parseFactorsDesc(json, Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor)).name;
  var factor$1 = f === "identity" ? /* Identity */0 : /* Custom */[f];
  var columns = QueryPaginationTask$BsConsole.AggregateUtils.getColumns(json);
  var func = QueryPaginationTask$BsConsole.AggregateUtils.getRows;
  var func$1 = Curry._1(func, factor$1);
  var rows = Curry._2(func$1, columns, json);
  var metadata = QueryPaginationTask$BsConsole.AggregateUtils.getMetadata(json);
  return /* Aggregate */Block.__(1, [/* tuple */[
              columns,
              rows,
              metadata
            ]]);
}

function decodeSelect(json) {
  var columns = QueryPaginationTask$BsConsole.SelectUtils.getColumns(json);
  var func = QueryPaginationTask$BsConsole.SelectUtils.getRows;
  var rows = Curry._2(func, columns, json);
  var metadata = QueryPaginationTask$BsConsole.SelectUtils.getMetadata(json);
  return /* Select */Block.__(0, [/* tuple */[
              columns,
              rows,
              metadata
            ]]);
}

function decodeSummary(json) {
  return /* Summary */Block.__(2, [Json_decode.list((function (j) {
                    var title = Json_decode.field("title", Json_decode.string, j);
                    var count = Json_decode.field("count", Json_decode.$$int, j);
                    return /* tuple */[
                            title,
                            count
                          ];
                  }), json)]);
}

function decodePointQuery(json) {
  return /* PointQuery */Block.__(3, [{
              title: Json_decode.field("title", Json_decode.string, json),
              denominatorCount: Json_decode.field("denominatorCount", Json_decode.$$int, json),
              numeratorCount: Json_decode.field("numeratorCount", Json_decode.$$int, json),
              denominatorGroupName: Json_decode.field("denominatorGroupName", Json_decode.string, json),
              numeratorGroupName: Json_decode.field("numeratorGroupName", Json_decode.string, json),
              inverse: Json_decode.field("inverse", Json_decode.bool, json),
              error: Json_decode.optional((function (param) {
                      return Json_decode.field("error", Json_decode.string, param);
                    }), json)
            }]);
}

function decodeUniqueEvents(json) {
  return {
          total: Json_decode.field("total", Json_decode.$$int, json),
          events: Json_decode.field("group_events", Json_decode.$$int, json)
        };
}

function decodeSummedEvents(json) {
  return {
          metricGroup: Json_decode.field("metric_group", Json_decode.string, json),
          events: Json_decode.field("events", Json_decode.$$int, json)
        };
}

function decodeCompareGroup(json) {
  return {
          group: Json_decode.field("group", Json_decode.string, json),
          count: Json_decode.field("count", Json_decode.$$int, json),
          uniqueFingerprints: Json_decode.field("unique_fingerprints", Json_decode.$$int, json),
          newFingerprints: Json_decode.field("new_fingerprints", Json_decode.$$int, json),
          triageStatusDist: Json_decode.field("triage_status_distribution", (function (json$prime) {
                  return Parse$BsConsole.distributionDecoder(undefined, undefined, undefined, Caml_option.some(json$prime));
                }), json),
          uniqueEventErrors: Json_decode.optional((function (param) {
                  return Json_decode.field("unique_application_sessions", Json_decode.$$int, param);
                }), json),
          uniqueApplicationSessions: Json_decode.optional((function (param) {
                  return Json_decode.field("unique_application_sessions", Json_decode.$$int, param);
                }), json),
          uniqueEvents: Json_decode.optional((function (param) {
                  return Json_decode.field("unique_events", decodeUniqueEvents, param);
                }), json),
          summedEvents: Json_decode.optional((function (param) {
                  return Json_decode.field("summed_events", decodeSummedEvents, param);
                }), json)
        };
}

function decodeCompareGroups(json) {
  var groups = Json_decode.array(decodeCompareGroup, json);
  return /* CompareGroups */Block.__(4, [groups]);
}

function decodeBin(json) {
  return Json_decode.tuple3(Json_decode.$$float, Json_decode.$$float, Json_decode.$$int, json);
}

function decodeSummedEventsBin(json) {
  return /* SummedEventsBin */Block.__(5, [{
              title: Json_decode.field("title", Json_decode.string, json),
              summedGroup: Json_decode.field("summed_group", Json_decode.string, json),
              errorGroup: Json_decode.field("error_group", Json_decode.string, json),
              summedEvents: Json_decode.field("summed_events", (function (param) {
                      return Json_decode.array(decodeBin, param);
                    }), json),
              errorEvents: Json_decode.field("error_events", (function (param) {
                      return Json_decode.array(decodeBin, param);
                    }), json),
              bins: Json_decode.field("bins", Json_decode.$$int, json),
              range: Json_decode.field("range", (function (param) {
                      return Json_decode.tuple2(Json_decode.$$float, Json_decode.$$float, param);
                    }), json)
            }]);
}

function decodeGroupedUniqueEventsBins(json) {
  return {
          group: Json_decode.field("group", Json_decode.string, json),
          data: Json_decode.at(/* :: */[
                  "results",
                  /* :: */[
                    "data",
                    /* [] */0
                  ]
                ], (function (param) {
                    return Json_decode.array(decodeBin, param);
                  }))(json)
        };
}

function decodeGroupedUniqueEventUsageBins(json) {
  return /* GroupedUniqueEventUsageBins */Block.__(6, [{
              title: Json_decode.field("title", Json_decode.string, json),
              uniqueEvent: Json_decode.field("unique_event", Json_decode.string, json),
              groupedUniqueEvents: Json_decode.field("grouped_unique_events", (function (param) {
                      return Json_decode.array(decodeGroupedUniqueEventsBins, param);
                    }), json),
              totalEventsBins: Json_decode.field("total_events_bins", (function (param) {
                      return Json_decode.array(decodeBin, param);
                    }), json),
              bins: Json_decode.field("bins", Json_decode.$$int, json),
              range: Json_decode.field("range", (function (param) {
                      return Json_decode.tuple2(Json_decode.$$float, Json_decode.$$float, param);
                    }), json)
            }]);
}

function decodeGroupedSummedEventsBins(json) {
  return /* GroupedSummedEventBins */Block.__(7, [{
              title: Json_decode.field("title", Json_decode.string, json),
              bins: Json_decode.field("bins", Json_decode.$$int, json),
              range: Json_decode.field("range", (function (param) {
                      return Json_decode.tuple2(Json_decode.$$float, Json_decode.$$float, param);
                    }), json),
              errorGroup: Json_decode.field("error_group", Json_decode.string, json),
              summedGroup: Json_decode.field("summed_group", Json_decode.string, json),
              groupedEvents: Json_decode.field("grouped_events", (function (param) {
                      return Json_decode.array((function (json) {
                                    return {
                                            group: Json_decode.field("group", Json_decode.string, json),
                                            summedEvents: Json_decode.at(/* :: */[
                                                    "results",
                                                    /* :: */[
                                                      "summed_events",
                                                      /* [] */0
                                                    ]
                                                  ], (function (param) {
                                                      return Json_decode.array(decodeBin, param);
                                                    }))(json),
                                            errorEvents: Json_decode.at(/* :: */[
                                                    "results",
                                                    /* :: */[
                                                      "error_events",
                                                      /* [] */0
                                                    ]
                                                  ], (function (param) {
                                                      return Json_decode.array(decodeBin, param);
                                                    }))(json)
                                          };
                                  }), param);
                    }), json)
            }]);
}

function decodeError(json) {
  return Error$BsConsole.HTTPD.decode(json);
}

function decodeResp(factorOpt, response) {
  var factor = factorOpt !== undefined ? factorOpt : /* Custom */["*"];
  try {
    var __x = response.json;
    var partial_arg_000 = function (param) {
      return decodeAggregate(factor, param);
    };
    var partial_arg_001 = /* :: */[
      decodeSelect,
      /* :: */[
        decodeSummary,
        /* :: */[
          decodePointQuery,
          /* :: */[
            decodeCompareGroups,
            /* :: */[
              decodeSummedEventsBin,
              /* :: */[
                decodeGroupedUniqueEventUsageBins,
                /* :: */[
                  decodeGroupedSummedEventsBins,
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ];
    var partial_arg = /* :: */[
      partial_arg_000,
      partial_arg_001
    ];
    var result = Json_decode.field("response", (function (param) {
            return Json_decode.oneOf(partial_arg, param);
          }), __x);
    return /* Ok */Block.__(0, [result]);
  }
  catch (_error){
    var val;
    var val$1;
    try {
      val = Json_decode.field("response", decodeError, response.json);
      var __x$1 = response.json;
      val$1 = Json_decode.optional(Json_decode.at(/* :: */[
                "response",
                /* :: */[
                  "error",
                  /* [] */0
                ]
              ], Json_decode.string), __x$1);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg[0] === Json_decode.DecodeError) {
        return /* Error */Block.__(1, [[
                    Task2$BsConsole.JsonDecodeError,
                    msg[1]
                  ]]);
      }
      throw msg;
    }
    if (val !== undefined) {
      return /* Error */Block.__(1, [[
                  Task2$BsConsole.Httpd,
                  val
                ]]);
    } else if (val$1 !== undefined) {
      return /* Error */Block.__(1, [[
                  Task2$BsConsole.JsonDecodeError,
                  val$1
                ]]);
    } else {
      return /* Error */Block.__(1, [[
                  Task2$BsConsole.JsonDecodeError,
                  undefined
                ]]);
    }
  }
}

var QueryResponse = {
  decodeAggregate: decodeAggregate,
  decodeSelect: decodeSelect,
  decodeSummary: decodeSummary,
  decodePointQuery: decodePointQuery,
  decodeUniqueEvents: decodeUniqueEvents,
  decodeSummedEvents: decodeSummedEvents,
  decodeCompareGroup: decodeCompareGroup,
  decodeCompareGroups: decodeCompareGroups,
  decodeBin: decodeBin,
  decodeSummedEventsBin: decodeSummedEventsBin,
  decodeGroupedUniqueEventsBins: decodeGroupedUniqueEventsBins,
  decodeGroupedUniqueEventUsageBins: decodeGroupedUniqueEventUsageBins,
  decodeGroupedSummedEventsBins: decodeGroupedSummedEventsBins,
  decodeError: decodeError,
  decodeResp: decodeResp
};

exports.QueryParseError = QueryParseError;
exports.QueryItemConfig = QueryItemConfig;
exports.QueryItem = QueryItem;
exports.QueryList = QueryList;
exports.QueryResponse = QueryResponse;
/* defaultBreakpoints Not a pure module */
