// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = ReasonReact.reducerComponent("WfFloat-BsConsole");

function make(fieldDoc, initialValue, padding, onValueChange, schema, showAllErrors, remoteAction, param) {
  var setElementRef = function (nullableRef, param) {
    param.state.elementRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var setInputRef = function (nullableRef, param) {
    param.state.inputRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var validate = function (value) {
    var tmp;
    if (value === "") {
      tmp = /* Ok */Block.__(0, [undefined]);
    } else {
      try {
        tmp = /* Ok */Block.__(0, [Caml_format.caml_float_of_string(value)]);
      }
      catch (exn){
        tmp = /* Error */Block.__(1, [/* InvalidFloat */4]);
      }
    }
    return Belt_Result.flatMap(tmp, (function ($$int) {
                  var err = WfCore$BsConsole.Float.validate(schema, $$int);
                  if (err !== undefined) {
                    return /* Error */Block.__(1, [err]);
                  } else {
                    return /* Ok */Block.__(0, [$$int]);
                  }
                }));
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var state = param.state;
              Curry._1(onValueChange, Belt_Result.map(state.validatedValue, (function (param) {
                          return Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), param);
                        })));
              return Curry._1(remoteAction, (function (param) {
                            if (param.tag) {
                              var element = state.elementRef.contents;
                              if (element !== undefined) {
                                return WfCore$BsConsole.scrollToDomElement(Caml_option.valFromOption(element));
                              } else {
                                return ;
                              }
                            }
                            var element$1 = state.inputRef.contents;
                            if (element$1 !== undefined) {
                              return Caml_option.valFromOption(element$1).focus();
                            }
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var validatedValue = state.validatedValue;
              var send = param.send;
              var handle = param.handle;
              var error;
              error = validatedValue.tag ? validatedValue[0] : undefined;
              return React.createElement("div", {
                          ref: Curry._1(handle, setElementRef)
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, schema.required, padding, error, undefined, state.dirty, showAllErrors, [React.createElement("div", {
                                        style: {
                                          padding: "8px 0"
                                        }
                                      }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                schema.placeholder,
                                                true,
                                                (function (e) {
                                                    return Curry._1(send, /* UpdateValue */[e.target.value]);
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* `String */[
                                                  -976970511,
                                                  state.value
                                                ],
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* `Callback */[
                                                  -659008027,
                                                  Curry._1(handle, setInputRef)
                                                ],
                                                undefined,
                                                undefined,
                                                undefined,
                                                []
                                              ])))])));
            }),
          initialState: (function (param) {
              var initialValue$1;
              try {
                initialValue$1 = Belt_Option.map(initialValue, Json_decode.$$float);
              }
              catch (exn){
                initialValue$1 = undefined;
              }
              var match = schema.defaultValue;
              var value = initialValue$1 !== undefined ? "" + (String(initialValue$1) + "") : (
                  match !== undefined ? "" + (String(match) + "") : ""
                );
              return {
                      value: value,
                      validatedValue: validate(value),
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      inputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var value = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          value: value,
                          validatedValue: validate(value),
                          dirty: true,
                          elementRef: state.elementRef,
                          inputRef: state.inputRef
                        },
                        (function (param) {
                            return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (param) {
                                              return Json_encode.nullable((function (prim) {
                                                            return prim;
                                                          }), param);
                                            })));
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
