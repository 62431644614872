// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Email$BsConsole = require("./Email.js");
var Image$BsConsole = require("./components/Image.js");
var Regex$BsConsole = require("./Regex.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Services$BsConsole = require("./Services.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BpgController$BsConsole = require("./BpgController.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var RetentionPolicy$BsConsole = require("./RetentionPolicy.js");
var ApiBillingLimits$BsConsole = require("./ApiBillingLimits.js");
var TeamsHasProjects$BsConsole = require("./teams/TeamsHasProjects.js");
var GenerateDemoProject$BsConsole = require("./GenerateDemoProject.js");

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* [] */0
      ]
    ]);

var container = Css.style(/* :: */[
      Css.height(Css.vh(100)),
      /* :: */[
        Css.width(Css.vw(100)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(602)),
      /* :: */[
        Css.padding(Css.px(25)),
        /* :: */[
          Css.fontFamily("Roboto"),
          /* :: */[
            Css.fontStyle(/* normal */812216871),
            /* [] */0
          ]
        ]
      ]
    ]);

var cardContent = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.width(Css.pct(100)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var cardTitle = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.fontSize(Css.px(24)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* :: */[
            Css.lineHeight(Css.px(36)),
            /* :: */[
              Css.marginTop(Css.px(40)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var cardDescription = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                400
              ]),
          /* :: */[
            Css.lineHeight(Css.px(24)),
            /* :: */[
              Css.marginTop(Css.px(10)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var callToActionTitle = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                450
              ]),
          /* :: */[
            Css.lineHeight(Css.px(24)),
            /* [] */0
          ]
        ]
      ]
    ]);

var callToActionInput = Css.style(/* :: */[
      Css.width(Css.px(424)),
      /* :: */[
        Css.marginTop(Css.px(10)),
        /* [] */0
      ]
    ]);

var actions = Css.style(/* :: */[
      Css.justifyContent(/* center */98248149),
      /* [] */0
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var demoContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.marginTop(Css.px(50)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.justifyContent(/* center */98248149),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var demoButtonsContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var demoCallToActionTitle = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                450
              ]),
          /* :: */[
            Css.lineHeight(Css.px(24)),
            /* :: */[
              Css.marginBottom(Css.px(16)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var demoMuiButton = {
  root: Css.style(/* :: */[
        Css.height(Css.px(102)),
        /* :: */[
          Css.width(Css.px(130)),
          /* :: */[
            Css.background(/* none */-922086728),
            /* :: */[
              Css.boxShadow(Css.px(0), Css.px(1), Css.px(4), Css.px(0), undefined, Css.hex(Colors$BsConsole.grey6)),
              /* :: */[
                Css.margin2(Css.px(0), Css.px(10)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]),
  label: Css.style(/* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.justifyContent(/* center */98248149),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.position(/* relative */903134412),
                  /* :: */[
                    Css.transform(Css.translateY(Css.px(-11))),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ])
};

var demoButtonText = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.bottom(Css.px(-1)),
        /* [] */0
      ]
    ]);

var demoIcon = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* :: */[
        Css.marginBottom(Css.px(5)),
        /* [] */0
      ]
    ]);

var lineBreakContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.paddingTop(Css.px(32)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey605)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var hr = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.background(Css.hex(Colors$BsConsole.grey605)),
        /* :: */[
          Css.height(Css.px(2)),
          /* :: */[
            Css.border(Css.px(0), /* none */-922086728, Css.hex(Colors$BsConsole.grey605)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  link: link,
  container: container,
  card: card,
  cardContent: cardContent,
  cardTitle: cardTitle,
  cardDescription: cardDescription,
  callToActionTitle: callToActionTitle,
  callToActionInput: callToActionInput,
  actions: actions,
  logo: logo,
  demoContainer: demoContainer,
  demoButtonsContainer: demoButtonsContainer,
  demoCallToActionTitle: demoCallToActionTitle,
  demoMuiButton: demoMuiButton,
  demoButtonText: demoButtonText,
  demoIcon: demoIcon,
  lineBreakContainer: lineBreakContainer,
  hr: hr
};

var component = ReasonReact.reducerComponent("CreateFirstProject-BsConsole");

function make(config, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var generateDemoProject = function (platform) {
                return GenerateDemoProject$BsConsole.handleGenerateDemoProject(platform, (function (param) {
                              return Curry._1(self.send, /* ToogleLoadingDemoProject */0);
                            }), config, token, /* ProjectCreation */0);
              };
              var isOverviewServiceAvailable = Belt_Option.isSome(Services$BsConsole.getEndpoint(undefined)(config.services, "overview"));
              var match = self.state.loadingDemoProject;
              if (isOverviewServiceAvailable) {
                if (match) {
                  return React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.marginTop(Css.px(32)),
                                    /* [] */0
                                  ])
                            }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])));
                } else {
                  return React.createElement("div", {
                              className: demoContainer
                            }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, demoCallToActionTitle, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Start with a demo project")])), React.createElement("div", {
                                  className: demoButtonsContainer
                                }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Generate a sample React project"), undefined, /* Bottom */437082891, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (_event) {
                                                        return generateDemoProject(/* React */2);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    Caml_option.some(demoMuiButton),
                                                    [
                                                      ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(demoIcon, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1694802390/react.png", "React Logo", undefined)([])),
                                                      React.createElement("label", {
                                                            className: demoButtonText
                                                          }, I18N$BsConsole.show(undefined, "Web"))
                                                    ]
                                                  ]))])), ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Generate a sample Unity project"), undefined, /* Bottom */437082891, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (_event) {
                                                        return generateDemoProject(/* Unity */0);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    Caml_option.some(demoMuiButton),
                                                    [
                                                      ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(Css.style(/* :: */[
                                                                      Css.height(Css.px(63)),
                                                                      /* [] */0
                                                                    ]), undefined, "https://res.cloudinary.com/backtrace/image/upload/v1651233860/U_Logo_Black_RGB-small.png", "Unity Logo", undefined)([])),
                                                      React.createElement("label", {
                                                            className: demoButtonText
                                                          }, I18N$BsConsole.show(undefined, "Gaming"))
                                                    ]
                                                  ]))])), ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Generate a sample React Native project"), undefined, /* Bottom */437082891, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (_event) {
                                                        return generateDemoProject(/* ReactNative */3);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Raised */-387916264,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    Caml_option.some(demoMuiButton),
                                                    [
                                                      ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(demoIcon, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1694802390/react.png", "React Native Logo", undefined)([])),
                                                      React.createElement("label", {
                                                            className: demoButtonText
                                                          }, I18N$BsConsole.show(undefined, "Mobile"))
                                                    ]
                                                  ]))]))), React.createElement("span", {
                                  className: lineBreakContainer
                                }, React.createElement("hr", {
                                      className: hr
                                    }), React.createElement("span", {
                                      className: Css.style(/* :: */[
                                            Css.margin2(Css.px(0), Css.px(8)),
                                            /* :: */[
                                              Css.fontSize(Css.px(16)),
                                              /* :: */[
                                                Css.fontWeight(/* `num */[
                                                      5496390,
                                                      400
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ])
                                    }, I18N$BsConsole.show(undefined, "OR")), React.createElement("hr", {
                                      className: hr
                                    })));
                }
              } else {
                return null;
              }
            }),
          initialState: (function (param) {
              return {
                      loadingDemoProject: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          loadingDemoProject: !state.loadingDemoProject
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var DemoProjectButtons = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("CreateFirstProject-BsConsole");

function make$1(shell, limitsRemote, token, handleTask, handleChangeUrl, refetchShell, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var tmp;
              var exit = 0;
              if (typeof limitsRemote === "number" || limitsRemote.tag) {
                exit = 1;
              } else {
                var limit = limitsRemote[0];
                tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Project.Create.make, (function (result, _updated) {
                            var arg = BpgTask$BsConsole.Token.create({
                                  id: "",
                                  project: result.pid,
                                  owner: result.owner,
                                  expires: 0,
                                  deleted: 0,
                                  __state: undefined,
                                  __create: undefined,
                                  __modify: undefined
                                });
                            return Curry._2((function (param) {
                                            return (function (param$1, param$2) {
                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                              });
                                          })(token), undefined, (function (param) {
                                          if (param.tag) {
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                          }
                                          var defaultRulesJson = RetentionPolicy$BsConsole.toJson(RetentionPolicy$BsConsole.getDefaultRules(undefined));
                                          var rules = Belt_Option.getWithDefault(Belt_Option.flatMap(limit, (function (limit) {
                                                      var rulesArr = RetentionPolicy$BsConsole.assembleRules(ApiBillingLimits$BsConsole.getRetentionCompress(limit), ApiBillingLimits$BsConsole.getRetentionDeleteOnlyPhysical(limit), ApiBillingLimits$BsConsole.getRetentionDeleteAll(limit), undefined);
                                                      if (rulesArr.length !== 0) {
                                                        return Caml_option.some(RetentionPolicy$BsConsole.toJson(rulesArr));
                                                      }
                                                      
                                                    })), defaultRulesJson);
                                          return RetentionPolicy$BsConsole.updateProjectPolicy(undefined, rules, token, (function (param) {
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created project and first submission token"));
                                                        return Curry._1(refetchShell, token);
                                                      }), (function (param) {
                                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                                      }), result.pid, undefined);
                                        }));
                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                            var project = state.remote;
                            if (typeof project === "number") {
                              project === /* NotAsked */0;
                            } else {
                              if (project.tag) {
                                return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([]))])),
                                                          React.createElement("br", undefined),
                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Failed to create project")])),
                                                          React.createElement("br", undefined),
                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.fontFamily("Inconsolata"),
                                                                                /* :: */[
                                                                                  Css.color(Css.hex(Colors$BsConsole.error)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                  /* String_literal */Block.__(11, [
                                                                                      "Error: ",
                                                                                      /* String */Block.__(2, [
                                                                                          /* No_padding */0,
                                                                                          /* End_of_format */0
                                                                                        ])
                                                                                    ]),
                                                                                  "Error: %s"
                                                                                ]), project[0]))])),
                                                          React.createElement("br", undefined),
                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                    I18N$BsConsole.show(undefined, "If problems persists, please contact "),
                                                                    React.createElement("a", {
                                                                          className: link + " link",
                                                                          href: "mailto:" + Email$BsConsole.support
                                                                        }, I18N$BsConsole.show(undefined, " Backtrace support."))
                                                                  ]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, actions, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (param) {
                                                                        window.location.reload();
                                                                        
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    /* Raised */-387916264,
                                                                    /* Primary */-791844958,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Try again")]
                                                                  ]))]))
                                              ]));
                              }
                              var project$1 = project[0];
                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* CreatedFirstProject */Block.__(1, ["initial_project_creation"])]));
                              return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [
                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([]))])),
                                                        React.createElement("br", undefined),
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* String_literal */Block.__(11, [
                                                                                    " has successfully been created. Follow the link below to get started setting your project up.",
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            "%s has successfully been created. Follow the link below to get started setting your project up."
                                                                          ]), project$1.name)]))
                                                      ])),
                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, actions, [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                                    project$1.name,
                                                                    {
                                                                      doc: undefined
                                                                    }
                                                                  ]), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Raised */-387916264,
                                                                            /* Primary */-791844958,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [I18N$BsConsole.show(undefined, "Get started")]
                                                                          ]))]))]))
                                            ]));
                            }
                            if (typeof shell === "number") {
                              return null;
                            }
                            if (shell.tag) {
                              return null;
                            }
                            var config = shell[0];
                            if (TeamsUtils$BsConsole.isGuest(config)) {
                              return ReasonReact.element(undefined, undefined, TeamsHasProjects$BsConsole.Re.make([]));
                            } else {
                              return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.NewProjectForm.make, (function (param) {
                                                var values = param.values;
                                                var __x = new RegExp("\\s");
                                                var match = __x.exec(values.name);
                                                if (match !== null) {
                                                  return Curry._1(self.send, /* SetValidationMessage */[I18N$BsConsole.get(undefined, "Project name must NOT include spaces.")]);
                                                } else {
                                                  Curry._1(self.send, /* SetValidationMessage */[undefined]);
                                                  return Curry._1(handleSubmit, /* tuple */[
                                                              {
                                                                pid: 0,
                                                                universe: config.universe.id,
                                                                name: values.name,
                                                                owner: config.user.uid,
                                                                deleted: 0,
                                                                __state: undefined,
                                                                __create: undefined,
                                                                __modify: undefined
                                                              },
                                                              Util$BsConsole.identity,
                                                              param.setError,
                                                              param.setSubmitting,
                                                              param.resetFormState
                                                            ]);
                                                }
                                              }), undefined, undefined, {
                                              name: ""
                                            }, /* :: */[
                                              /* tuple */[
                                                /* Name */869834347,
                                                /* Custom */[(function (values) {
                                                      if (Regex$BsConsole.isProjectName(values.name)) {
                                                        return ;
                                                      } else {
                                                        return I18N$BsConsole.get(undefined, "illegal name only use a-z, A-Z, 0-9, or \"-\"");
                                                      }
                                                    })]
                                              ],
                                              /* [] */0
                                            ], undefined, (function (param) {
                                                var getErrorForField = param.getErrorForField;
                                                var handleSubmit = param.handleSubmit;
                                                var handleChange = param.handleChange;
                                                var form = param.form;
                                                var match = state.remote;
                                                var match$1 = self.state.validationMessage;
                                                var tmp;
                                                var exit = 0;
                                                var msg;
                                                if (typeof match === "number") {
                                                  if (match$1 !== undefined) {
                                                    msg = match$1;
                                                    exit = 1;
                                                  } else {
                                                    tmp = null;
                                                  }
                                                } else if (match.tag === /* Failure */1) {
                                                  msg = match[0];
                                                  exit = 1;
                                                } else if (match$1 !== undefined) {
                                                  msg = match$1;
                                                  exit = 1;
                                                } else {
                                                  tmp = null;
                                                }
                                                if (exit === 1) {
                                                  tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg)]))]));
                                                }
                                                var msg$1 = Curry._1(getErrorForField, /* Name */869834347);
                                                return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(logo, undefined, "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg", "Sauce Labs - Backtrace Logo", undefined)([]))])),
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, cardTitle, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Find and fix errors before users experience them")])),
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, cardDescription, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Backtrace is the industry-leading error management and monitoring platform designed to help you catch and solve crashes and exceptions before users churn.")])),
                                                                          ReasonReact.element(undefined, undefined, make(config, token, [])),
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.merge(/* :: */[
                                                                                        Css.style(/* :: */[
                                                                                              Css.marginTop(Css.px(32)),
                                                                                              /* [] */0
                                                                                            ]),
                                                                                        /* :: */[
                                                                                          callToActionTitle,
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]), undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Get started by creating a project for your application")])),
                                                                          tmp,
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                    callToActionInput,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    (function (e) {
                                                                                        return ReForm_Helpers.handleDomFormChange(Curry._1(handleChange, /* Name */869834347), e);
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    Belt_Option.isSome(Curry._1(getErrorForField, /* Name */869834347)) || Belt_Option.isSome(form.error),
                                                                                    Caml_option.some(msg$1 !== undefined ? I18N$BsConsole.showSkip(msg$1) : I18N$BsConsole.showEmpty(undefined)),
                                                                                    undefined,
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.values.name
                                                                                    ],
                                                                                    undefined,
                                                                                    true,
                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, "Project name")),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))
                                                                        ])),
                                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, actions, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    (function (_event) {
                                                                                        return Curry._1(handleSubmit, undefined);
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    /* Raised */-387916264,
                                                                                    /* Primary */-791844958,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [I18N$BsConsole.show(undefined, "Create project")]
                                                                                  ]))]))
                                                              ]));
                                              })));
                            }
                          })));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []));
              }
              return React.createElement("div", {
                          className: container
                        }, tmp);
            }),
          initialState: (function (param) {
              return {
                      validationMessage: undefined
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          validationMessage: action[0]
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.Style = Style;
exports.DemoProjectButtons = DemoProjectButtons;
exports.component = component$1;
exports.make = make$1;
/* link Not a pure module */
