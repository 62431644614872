// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var ApiResourceList$BsConsole = require("../ApiResourceList.js");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");

var ParseError = Caml_exceptions.create("BugDownloadError-BsConsole.ParseError");

function createTask(projectName, errorId) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/list?view=resources&object=" + (String(errorId) + ("&project=" + (String(projectName) + "")))]), undefined, /* Get */0, undefined, (function (param) {
                var resourceList = ApiResourceList$BsConsole.fromJson(param.json);
                return /* Ok */Block.__(0, [resourceList]);
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

var defaultResourceList = [
  "json",
  "txt"
];

var menuItemStyle = Css.style(/* :: */[
      Css.textTransform(/* capitalize */-509867604),
      /* [] */0
    ]);

function transformResourceString(other) {
  switch (other) {
    case "json" :
        return "JSON";
    case "txt" :
        return "Text";
    default:
      return other;
  }
}

function BugDownloadError$ResourceMenu(Props) {
  var projectName = Props.projectName;
  var token = Props.token;
  var errorId = Props.errorId;
  var onClose = Props.onClose;
  var match = React.useState((function () {
          return [];
        }));
  var setResourceList = match[1];
  var baseDownloadLink = "/api/get?token=" + (token + ("&project=" + (projectName + ("&object=" + errorId))));
  React.useEffect((function () {
          var onSuccess = function (resourceList) {
            return Curry._1(setResourceList, (function (param) {
                          return resourceList;
                        }));
          };
          var onFailure = function (param) {
            return Curry._1(setResourceList, (function (param) {
                          return defaultResourceList;
                        }));
          };
          var arg = createTask(projectName, errorId);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  return responseCb(onSuccess, onFailure, param);
                }));
          
        }), []);
  return React.createElement(MenuList.default, {
              children: null
            }, React.createElement("a", {
                  download: "",
                  href: baseDownloadLink + "&resource=raw"
                }, React.createElement(MenuItem.default, {
                      onClick: onClose,
                      children: I18N$BsConsole.show(undefined, "Raw")
                    })), Belt_Array.mapWithIndex(match[0], (function (idx, resource) {
                    return React.createElement("a", {
                                key: resource + ("__" + String(idx)),
                                download: "",
                                href: baseDownloadLink + ("&resource=" + (String(resource) + ".gz"))
                              }, React.createElement(MenuItem.default, {
                                    classes: {
                                      root: menuItemStyle
                                    },
                                    onClick: onClose,
                                    children: I18N$BsConsole.showSkip(transformResourceString(resource)),
                                    key: resource + ("__" + (String(idx) + "-MenuItem"))
                                  }));
                  })));
}

var ResourceMenu = {
  make: BugDownloadError$ResourceMenu
};

function BugDownloadError(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var children = Props.children;
  var match = React.useState((function () {
          
        }));
  var setAnchor = match[1];
  var anchor = match[0];
  var handleOnClick = function ($$event) {
    return Curry._1(setAnchor, (function (param) {
                  return Caml_option.some($$event.target);
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  enterDelay: 250,
                  children: Curry._1(children, handleOnClick),
                  title: "Download error"
                }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: anchor,
                  open: Belt_Option.isSome(anchor),
                  onClose: (function (param) {
                      return Curry._1(setAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(BugDownloadError$ResourceMenu, {
                        projectName: projectName,
                        token: token,
                        errorId: errorId,
                        onClose: (function (param) {
                            return Curry._1(setAnchor, (function (param) {
                                          
                                        }));
                          })
                      })
                }));
}

var make = BugDownloadError;

exports.ParseError = ParseError;
exports.createTask = createTask;
exports.responseCb = responseCb;
exports.defaultResourceList = defaultResourceList;
exports.menuItemStyle = menuItemStyle;
exports.transformResourceString = transformResourceString;
exports.ResourceMenu = ResourceMenu;
exports.make = make;
/* menuItemStyle Not a pure module */
