// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var PrModal$BsConsole = require("./progressive-reveal/PrModal.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var FetchCrdb$BsConsole = require("./FetchCrdb.js");
var PrModalLayout$BsConsole = require("./progressive-reveal/PrModalLayout.js");
var PrDistribution$BsConsole = require("./progressive-reveal/PrDistribution.js");

var component = RR$BsConsole.statelessComponent("DistributionModal-BsConsole");

function make(onClose, factorName, factorValue, token, aperture, attribute, handleTask, projectName, handleAddFilters, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, PrModal$BsConsole.make(true, (function (param) {
                                
                              }), [ReasonReact.element(undefined, undefined, PrModalLayout$BsConsole.make(attribute, factorValue, attribute, "distribution", onClose, (function (_dialogWidth, _dialogMaxHeight) {
                                          var aperture$1;
                                          switch (factorName) {
                                            case "None" :
                                            case "none" :
                                                aperture$1 = aperture;
                                                break;
                                            default:
                                              aperture$1 = factorValue === "*" ? aperture : Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                              factorName,
                                                              /* Equal */Block.__(0, [/* `String */[
                                                                    -976970511,
                                                                    factorValue
                                                                  ]])
                                                            ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture);
                                          }
                                          return ReasonReact.element(undefined, undefined, FetchCrdb$BsConsole.make(handleTask, token, projectName, /* Aggregate */Block.__(0, [
                                                            aperture$1,
                                                            undefined,
                                                            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                      attribute,
                                                                      /* Distribution */Block.__(1, [50])
                                                                    ]), Crdb$BsConsole.Fold.empty),
                                                            undefined,
                                                            undefined,
                                                            undefined
                                                          ]), (function (remote, param, param$1) {
                                                            if (typeof remote === "number") {
                                                              remote === /* NotAsked */0;
                                                            } else {
                                                              if (remote.tag) {
                                                                return I18N$BsConsole.dynamic(remote[0]);
                                                              }
                                                              var json = remote[0];
                                                              var columns = Parse$BsConsole.parseColumns(json);
                                                              var maybeGroup = Belt_List.head(Parse$BsConsole.decodeExn(/* tuple */[
                                                                        "fingerprint",
                                                                        undefined
                                                                      ], columns, json));
                                                              if (maybeGroup === undefined) {
                                                                return I18N$BsConsole.show(undefined, "INVARIANT");
                                                              }
                                                              var getter = function (param, param$1) {
                                                                return Group$BsConsole.getAggregation(columns, maybeGroup, param, param$1);
                                                              };
                                                              var maybeDistribution = Group$BsConsole.unwrapDistribution(getter(attribute, /* Distribution */1));
                                                              if (maybeDistribution !== undefined) {
                                                                return ReasonReact.element(undefined, undefined, PrDistribution$BsConsole.make(onClose, attribute, maybeDistribution, handleAddFilters, handleChangeUrl, []));
                                                              } else {
                                                                return I18N$BsConsole.show(undefined, "INVARIANT");
                                                              }
                                                            }
                                                            return ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                                                Css.padding2(Css.rem(2), Css.zero),
                                                                                /* [] */0
                                                                              ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]));
                                                          })));
                                        })))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.onClose, reactProps.factorName, reactProps.factorValue, reactProps.token, reactProps.aperture, reactProps.attribute, reactProps.handleTask, reactProps.projectName, reactProps.handleAddFilters, reactProps.handleChangeUrl, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
