// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Crdb$BsConsole = require("../crdb.js");
var TriageUtils$BsConsole = require("../TriageUtils.js");
var ExploreListData$BsConsole = require("../ExploreListData.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var ExploreAggregateData$BsConsole = require("../ExploreAggregateData.js");

function normalizeQuery(mode, query) {
  var aperture = Curry._1(Crdb$BsConsole.Query.getAperture, query);
  var tmp;
  switch (mode) {
    case /* Inbox */0 :
        var foldFromQuery = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty);
        var fold = TriageUtils$BsConsole.triageFold(aperture, foldFromQuery);
        tmp = query.tag ? undefined : /* Aggregate */Block.__(0, [
              query[0],
              query[1],
              fold,
              query[3],
              query[4],
              query[5]
            ]);
        break;
    case /* Aggregate */1 :
        var foldFromQuery$1 = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty);
        var fold$1 = ExploreAggregateData$BsConsole.createFold(foldFromQuery$1, aperture);
        tmp = query.tag ? undefined : /* Aggregate */Block.__(0, [
              query[0],
              query[1],
              fold$1,
              query[3],
              query[4],
              query[5]
            ]);
        break;
    case /* Instance */2 :
        tmp = query.tag ? /* Select */Block.__(1, [
              query[0],
              ExploreListData$BsConsole.getSelect(query[1]),
              query[2],
              query[3]
            ]) : undefined;
        break;
    
  }
  return Belt_Option.getWithDefault(tmp, query);
}

function isQueryShapeEqual(q1, q2) {
  if (q1.tag) {
    if (!q2.tag) {
      return false;
    }
    var isApertureEqual = Curry._2(Crdb$BsConsole.Aperture.eq, q1[0], q2[0]);
    var isSelectEqual = Curry._2(Crdb$BsConsole.Select.eq, q1[1], q2[1]);
    var isSortEqual = Curry._2(Crdb$BsConsole.Sort.eq, Belt_Option.getWithDefault(q1[2], Crdb$BsConsole.Sort.defaultSelect), Belt_Option.getWithDefault(q2[2], Crdb$BsConsole.Sort.defaultSelect));
    if (isApertureEqual && isSelectEqual) {
      return isSortEqual;
    } else {
      return false;
    }
  }
  var factor = q1[1];
  if (factor === undefined) {
    return false;
  }
  if (q2.tag) {
    return false;
  }
  var factor$prime = q2[1];
  if (factor$prime === undefined) {
    return false;
  }
  var isApertureEqual$1 = Curry._2(Crdb$BsConsole.Aperture.eq, q1[0], q2[0]);
  var isFactorEqual = Curry._2(Crdb$BsConsole.Factor.eq, factor, factor$prime);
  var isFoldEqual = Curry._2(Crdb$BsConsole.Fold.eq, q1[2], q2[2]);
  var isSortEqual$1 = Curry._2(Crdb$BsConsole.Sort.eq, Belt_Option.getWithDefault(q1[3], Crdb$BsConsole.Sort.defaultAggregate), Belt_Option.getWithDefault(q2[3], Crdb$BsConsole.Sort.defaultAggregate));
  var isHavingEqual = Curry._2(Crdb$BsConsole.Havings.eq, Belt_Option.getWithDefault(q1[5], Crdb$BsConsole.Havings.empty), Belt_Option.getWithDefault(q2[5], Crdb$BsConsole.Havings.empty));
  if (isApertureEqual$1 && isFactorEqual && isFoldEqual && isSortEqual$1) {
    return isHavingEqual;
  } else {
    return false;
  }
}

function get(mode, query, savedDashboards) {
  var maybeSaved = Belt_List.getBy(savedDashboards, (function (savedQuery) {
          var mode$prime = SavedQueriesModel$BsConsole.getMode(savedQuery);
          var query$prime = SavedQueriesModel$BsConsole.getQuery(savedQuery);
          var normalized = normalizeQuery(mode, query);
          var normalized$prime = normalizeQuery(mode$prime, query$prime);
          if (mode === mode$prime) {
            return isQueryShapeEqual(normalized, normalized$prime);
          } else {
            return false;
          }
        }));
  if (maybeSaved !== undefined) {
    return /* Saved */[maybeSaved];
  } else {
    return /* Custom */0;
  }
}

function findByName(name, savedDashboards) {
  return Belt_List.getBy(savedDashboards, (function (savedQuery) {
                return name === SavedQueriesModel$BsConsole.getName(savedQuery);
              }));
}

function getValue(t$prime) {
  if (t$prime) {
    return SavedQueriesModel$BsConsole.getName(t$prime[0]);
  } else {
    return "custom";
  }
}

exports.normalizeQuery = normalizeQuery;
exports.isQueryShapeEqual = isQueryShapeEqual;
exports.get = get;
exports.findByName = findByName;
exports.getValue = getValue;
/* Crdb-BsConsole Not a pure module */
