// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function make(watcher, username, email, uid, param) {
  return /* User */Block.__(0, [{
              watcher: watcher,
              username: username,
              email: email,
              id: uid
            }]);
}

function shortenName(str) {
  return str.substr(0, 2);
}

function getDisplayName(user) {
  if (!user.tag) {
    return user[0].username;
  }
  var match = user[0];
  var username = match.username;
  if (username !== undefined) {
    return username;
  }
  var email = match.email;
  if (email !== undefined) {
    return email;
  } else {
    return "?";
  }
}

function toJson(a) {
  if (a.tag) {
    var v = a[0];
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "username",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), v.username)
                ],
                /* :: */[
                  /* tuple */[
                    "email",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), v.email)
                  ],
                  /* [] */0
                ]
              ]);
  }
  var v$1 = a[0];
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "username",
                v$1.username
              ],
              /* :: */[
                /* tuple */[
                  "email",
                  v$1.email
                ],
                /* :: */[
                  /* tuple */[
                    "id",
                    v$1.id
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

function ofJson(json, watcher) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  return /* User */Block.__(0, [{
                              watcher: watcher,
                              username: Json_decode.field("username", Json_decode.string, json),
                              email: Json_decode.field("email", Json_decode.string, json),
                              id: Json_decode.field("id", Json_decode.$$int, json)
                            }]);
                }),
              /* :: */[
                (function (json) {
                    return /* NonUser */Block.__(1, [{
                                watcher: watcher,
                                username: Json_decode.optional((function (param) {
                                        return Json_decode.field("username", Json_decode.string, param);
                                      }), json),
                                email: Json_decode.optional((function (param) {
                                        return Json_decode.field("email", Json_decode.string, param);
                                      }), json)
                              }]);
                  }),
                /* [] */0
              ]
            ], json);
}

exports.make = make;
exports.shortenName = shortenName;
exports.getDisplayName = getDisplayName;
exports.toJson = toJson;
exports.ofJson = ofJson;
/* No side effect */
