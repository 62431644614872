// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

function push(here) {
  return Curry._1((function push(here) {
        window.history.pushState(null, null, here);
      }), here);
}

var isBlocked = {
  contents: false
};

var warningMessage = {
  contents: undefined
};

var component = ReasonReact.statelessComponent("BlockNavigation-BsConsole");

function make(warning, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              isBlocked.contents = true;
              warningMessage.contents = warning;
              var here = window.location.pathname;
              var callBack = function (param) {
                if (!isBlocked.contents) {
                  return ;
                }
                var warningMessage$prime = warningMessage.contents;
                var message = warningMessage$prime !== undefined ? warningMessage$prime : "Navigation is currently blocked.";
                window.alert(message);
                return push(here);
              };
              window.addEventListener("popstate", callBack);
              return Curry._1(self.onUnmount, (function (param) {
                            window.removeEventListener("popstate", callBack);
                            isBlocked.contents = false;
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return Curry._1(children, (function (param) {
                            isBlocked.contents = false;
                            
                          }));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.push = push;
exports.isBlocked = isBlocked;
exports.warningMessage = warningMessage;
exports.component = component;
exports.make = make;
/* component Not a pure module */
