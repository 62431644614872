// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function optionalStringToString(d, o) {
  if (o !== undefined) {
    return Caml_option.valFromOption(o);
  } else {
    return d;
  }
}

function toTupleList(e) {
  return /* :: */[
          /* tuple */[
            "project",
            /* Int */Block.__(1, [e.project]),
            /* Default */0,
            /* None */1
          ],
          /* :: */[
            /* tuple */[
              "name",
              /* String */Block.__(0, [e.name]),
              /* Default */0,
              /* Text */0
            ],
            /* :: */[
              /* tuple */[
                "format",
                /* String */Block.__(0, [optionalStringToString("%undefined", e.format)]),
                /* Default */0,
                /* Text */0
              ],
              /* :: */[
                /* tuple */[
                  "type_",
                  /* String */Block.__(0, [e.type_]),
                  /* Default */0,
                  /* Text */0
                ],
                /* :: */[
                  /* tuple */[
                    "description",
                    /* String */Block.__(0, [e.description]),
                    /* Default */0,
                    /* Text */0
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ];
}

function decode(json) {
  return {
          project: Json_decode.field("project", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          format: Json_decode.field("format", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          type_: Json_decode.field("type", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json)
        };
}

var Attribute_ = {
  table: "attribute",
  toTupleList: toTupleList,
  decode: decode
};

function toTupleList$1(e) {
  return /* :: */[
          /* tuple */[
            "id",
            /* Int */Block.__(1, [e.id]),
            /* Default */0,
            /* None */1
          ],
          /* :: */[
            /* tuple */[
              "email",
              /* String */Block.__(0, [e.email]),
              /* Default */0,
              /* Text */0
            ],
            /* :: */[
              /* tuple */[
                "username",
                /* String */Block.__(0, [e.username]),
                /* PrimaryKey */1,
                /* Text */0
              ],
              /* :: */[
                /* tuple */[
                  "method_",
                  /* String */Block.__(0, [e.method_]),
                  /* Default */0,
                  /* None */1
                ],
                /* :: */[
                  /* tuple */[
                    "password",
                    /* String */Block.__(0, [e.password]),
                    /* Default */0,
                    /* None */1
                  ],
                  /* :: */[
                    /* tuple */[
                      "active",
                      /* Int */Block.__(1, [e.active]),
                      /* Default */0,
                      /* None */1
                    ],
                    /* :: */[
                      /* tuple */[
                        "superuser",
                        /* Int */Block.__(1, [e.superuser]),
                        /* Default */0,
                        /* None */1
                      ],
                      /* :: */[
                        /* tuple */[
                          "universe",
                          /* Int */Block.__(1, [e.universe]),
                          /* PrimaryKey */1,
                          /* None */1
                        ],
                        /* :: */[
                          /* tuple */[
                            "role",
                            /* String */Block.__(0, [e.role]),
                            /* Default */0,
                            /* Text */0
                          ],
                          /* :: */[
                            /* tuple */[
                              "deleted",
                              /* Int */Block.__(1, [e.deleted]),
                              /* Default */0,
                              /* None */1
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ];
}

function decode$1(json) {
  return {
          id: Json_decode.field("uid", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json),
          username: Json_decode.field("username", Json_decode.string, json),
          method_: Json_decode.field("method", Json_decode.string, json),
          password: "",
          active: Json_decode.field("active", Json_decode.$$int, json),
          superuser: Json_decode.field("superuser", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.$$int, json),
          role: Json_decode.withDefault("admin", (function (param) {
                  return Json_decode.field("role", Json_decode.string, param);
                }), json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json)
        };
}

var User_ = {
  table: "users",
  toTupleList: toTupleList$1,
  decode: decode$1
};

function toTupleList$2(e) {
  return /* :: */[
          /* tuple */[
            "id",
            /* Int */Block.__(1, [e.id]),
            /* PrimaryKey */1,
            /* None */1
          ],
          /* :: */[
            /* tuple */[
              "protocol",
              /* Int */Block.__(1, [e.protocol]),
              /* Default */0,
              /* None */1
            ],
            /* :: */[
              /* tuple */[
                "type_",
                /* String */Block.__(0, [e.type_]),
                /* Default */0,
                /* Text */0
              ],
              /* :: */[
                /* tuple */[
                  "address",
                  /* String */Block.__(0, [e.address]),
                  /* Default */0,
                  /* Text */0
                ],
                /* :: */[
                  /* tuple */[
                    "dnsName",
                    /* String */Block.__(0, [e.dnsName]),
                    /* Default */0,
                    /* None */1
                  ],
                  /* :: */[
                    /* tuple */[
                      "service",
                      /* String */Block.__(0, [e.service]),
                      /* Default */0,
                      /* Text */0
                    ],
                    /* :: */[
                      /* tuple */[
                        "backlog",
                        /* Int */Block.__(1, [e.backlog]),
                        /* Default */0,
                        /* Text */0
                      ],
                      /* :: */[
                        /* tuple */[
                          "ssl",
                          /* Int */Block.__(1, [e.ssl]),
                          /* Default */0,
                          /* None */1
                        ],
                        /* :: */[
                          /* tuple */[
                            "concurrency",
                            /* Int */Block.__(1, [e.concurrency]),
                            /* Default */0,
                            /* None */1
                          ],
                          /* :: */[
                            /* tuple */[
                              "undeletable",
                              /* Int */Block.__(1, [e.undeletable]),
                              /* Default */0,
                              /* None */1
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ];
}

function decode$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          protocol: Json_decode.field("protocol", Json_decode.$$int, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          address: Json_decode.field("address", Json_decode.string, json),
          dnsName: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("dns_name", Json_decode.string, param);
                }), json),
          service: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("service", Json_decode.string, param);
                }), json),
          backlog: Json_decode.field("backlog", Json_decode.$$int, json),
          ssl: Json_decode.field("ssl", Json_decode.$$int, json),
          concurrency: Json_decode.field("concurrency", Json_decode.$$int, json),
          undeletable: Json_decode.field("undeletable", Json_decode.$$int, json)
        };
}

var Listener_ = {
  table: "listener",
  toTupleList: toTupleList$2,
  decode: decode$2
};

function toTupleList$3(e) {
  return /* :: */[
          /* tuple */[
            "id",
            /* Int */Block.__(1, [e.id]),
            /* Default */0,
            /* None */1
          ],
          /* :: */[
            /* tuple */[
              "universeID",
              /* Int */Block.__(1, [e.universeID]),
              /* PrimaryKey */1,
              /* None */1
            ],
            /* :: */[
              /* tuple */[
                "name",
                /* String */Block.__(0, [e.name]),
                /* PrimaryKey */1,
                /* Text */0
              ],
              /* :: */[
                /* tuple */[
                  "userID",
                  /* Int */Block.__(1, [e.userID]),
                  /* Default */0,
                  /* None */1
                ],
                /* :: */[
                  /* tuple */[
                    "deleted",
                    /* Int */Block.__(1, [e.deleted]),
                    /* Default */0,
                    /* None */1
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ];
}

function decode$3(json) {
  return {
          id: Json_decode.field("pid", Json_decode.$$int, json),
          universeID: Json_decode.field("universe", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          userID: Json_decode.field("owner", Json_decode.$$int, json),
          deleted: Json_decode.field("deleted", Json_decode.$$int, json)
        };
}

var Project_ = {
  table: "project",
  toTupleList: toTupleList$3,
  decode: decode$3
};

function Make(E) {
  var encode = function (entity) {
    return Json_encode.object_(List.map((function (param) {
                      var value = param[1];
                      var key = param[0];
                      if (value.tag) {
                        return /* tuple */[
                                key,
                                String(value[0])
                              ];
                      } else {
                        return /* tuple */[
                                key,
                                value[0]
                              ];
                      }
                    }), Curry._1(E.toTupleList, entity)));
  };
  var encodePrimaryKey = function (entity) {
    return Json_encode.object_(List.map((function (param) {
                      var value = param[1];
                      var key = param[0];
                      if (value.tag) {
                        return /* tuple */[
                                key,
                                String(value[0])
                              ];
                      } else {
                        return /* tuple */[
                                key,
                                value[0]
                              ];
                      }
                    }), List.filter((function (param) {
                            return param[2] === /* PrimaryKey */1;
                          }))(Curry._1(E.toTupleList, entity))));
  };
  var get = function (param) {
    return /* Get */0;
  };
  var create = function (entity) {
    return /* Create */Block.__(0, [entity]);
  };
  var modify = function (nextEntity) {
    return /* Modify */Block.__(1, [nextEntity]);
  };
  var $$delete = function (entity) {
    return /* Delete */Block.__(2, [entity]);
  };
  var base = "configuration/";
  var toJSON = function (action) {
    if (typeof action === "number") {
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    base + E.table
                  ],
                  /* :: */[
                    /* tuple */[
                      "action",
                      "get"
                    ],
                    /* [] */0
                  ]
                ]);
    }
    switch (action.tag | 0) {
      case /* Create */0 :
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        base + E.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "create"
                        ],
                        /* :: */[
                          /* tuple */[
                            "object",
                            encode(action[0])
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
      case /* Modify */1 :
          var entity = action[0];
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        base + E.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "modify"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            encodePrimaryKey(entity)
                          ],
                          /* :: */[
                            /* tuple */[
                              "object",
                              encode(entity)
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]);
      case /* Delete */2 :
          return Json_encode.object_(/* :: */[
                      /* tuple */[
                        "type",
                        base + E.table
                      ],
                      /* :: */[
                        /* tuple */[
                          "action",
                          "delete"
                        ],
                        /* :: */[
                          /* tuple */[
                            "key",
                            encodePrimaryKey(action[0])
                          ],
                          /* [] */0
                        ]
                      ]
                    ]);
      
    }
  };
  var decode = function (json) {
    return /* tuple */[
            Json_decode.field("code", Json_decode.$$int, json),
            Json_decode.field("text", Json_decode.string, json),
            Json_decode.field("result", (function (param) {
                    return Json_decode.list(E.decode, param);
                  }), json)
          ];
  };
  var decodeBPG = function (param) {
    return Json_decode.field("results", (function (param) {
                  return Json_decode.list(decode, param);
                }), param);
  };
  return {
          toTupleList: E.toTupleList,
          encode: encode,
          encodePrimaryKey: encodePrimaryKey,
          get: get,
          create: create,
          modify: modify,
          $$delete: $$delete,
          base: base,
          toJSON: toJSON,
          decode: decode,
          decodeBPG: decodeBPG
        };
}

function formatValueToString(formatValue) {
  var str = formatValue[1];
  if (str.tag) {
    return String(str[0]);
  } else {
    return str[0];
  }
}

function getMethod(u) {
  var match = u.method_;
  switch (match) {
    case "password" :
        return /* Password */1;
    case "saml" :
        return /* SAML */0;
    default:
      return ;
  }
}

var User = {
  getMethod: getMethod
};

var Helpers = {
  User: User
};

exports.optionalStringToString = optionalStringToString;
exports.Attribute_ = Attribute_;
exports.User_ = User_;
exports.Listener_ = Listener_;
exports.Project_ = Project_;
exports.Make = Make;
exports.formatValueToString = formatValueToString;
exports.Helpers = Helpers;
/* No side effect */
