// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");
var Styles = require("@material-ui/core/styles");

var containedButton = Css.style(/* :: */[
      Css.backgroundColor(/* `hex */[
            5194459,
            Colors$BsConsole.accent
          ]),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.textTransform(/* none */-922086728),
          /* :: */[
            Css.unsafe("boxShadow", "none"),
            /* :: */[
              Css.selector(":hover", /* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accentDark)),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var textButtonStyles_000 = Css.color(/* `hex */[
      5194459,
      Colors$BsConsole.accent
    ]);

var textButtonStyles_001 = /* :: */[
  Css.textTransform(/* none */-922086728),
  /* [] */0
];

var textButtonStyles = /* :: */[
  textButtonStyles_000,
  textButtonStyles_001
];

var textButton = Css.style(textButtonStyles);

var outlinedButton = Css.style(/* :: */[
      Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.blackA23)),
      textButtonStyles
    ]);

var savedViewsTopFold = Css.style(/* :: */[
      Css.padding2(Css.rem(1.5), Css.rem(1)),
      /* [] */0
    ]);

var savedViewsMidSection = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.rem(1)),
      /* [] */0
    ]);

var theme = Styles.createMuiTheme({
      palette: {
        primary: {
          main: Colors$BsConsole.octothorpe(Colors$BsConsole.accent),
          light: Colors$BsConsole.octothorpe(Colors$BsConsole.accentLight),
          dark: Colors$BsConsole.octothorpe(Colors$BsConsole.accentDark),
          contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        },
        secondary: {
          main: Colors$BsConsole.octothorpe(Colors$BsConsole.grey3),
          light: Colors$BsConsole.octothorpe(Colors$BsConsole.grey3),
          dark: Colors$BsConsole.octothorpe(Colors$BsConsole.grey3),
          contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        }
      }
    });

exports.containedButton = containedButton;
exports.textButtonStyles = textButtonStyles;
exports.textButton = textButton;
exports.outlinedButton = outlinedButton;
exports.savedViewsTopFold = savedViewsTopFold;
exports.savedViewsMidSection = savedViewsMidSection;
exports.theme = theme;
/* containedButton Not a pure module */
