// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");
var Lib = require("rrweb-player/lib");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var BugSectionHeader$BsConsole = require("../BugSectionHeader.js");

function BugReplayView(Props) {
  var showHeaderOpt = Props.showHeader;
  var h = Props.height;
  var w = Props.width;
  var events = Props.events;
  var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : true;
  var match = React.useState((function () {
          
        }));
  var setPlayerContainer = match[1];
  var playerContainer = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setPlayer = match$1[1];
  var player = match$1[0];
  var heightOffset = 80 + (
    showHeader ? 20 : 0
  ) | 0;
  var onStart = function (_event) {
    return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SessionReplay_Play */121]));
  };
  React.useEffect((function () {
          var $$event = "start";
          var replayer;
          if (player !== undefined) {
            var replayer$1 = Caml_option.valFromOption(player).getReplayer();
            replayer = Caml_option.some(replayer$1.on($$event, onStart));
          } else {
            replayer = undefined;
          }
          if (replayer === undefined) {
            return ;
          }
          var r = Caml_option.valFromOption(replayer);
          return (function (param) {
                    r.off($$event, onStart);
                    
                  });
        }), [player]);
  React.useEffect((function () {
          if (player !== undefined) {
            var player$prime = Caml_option.valFromOption(player);
            player$prime.$set({
                  width: w,
                  height: h - heightOffset | 0
                });
            player$prime.triggerResize();
          }
          
        }), /* tuple */[
        player,
        w,
        h
      ]);
  React.useEffect((function () {
          if (playerContainer !== undefined) {
            var container = Caml_option.valFromOption(playerContainer);
            Curry._1(setPlayer, (function (param) {
                    return Caml_option.some(new Lib({
                                    target: container,
                                    props: {
                                      events: events,
                                      autoPlay: false
                                    }
                                  }));
                  }));
          }
          
        }), [playerContainer]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.px(w)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* :: */[
                        Css.overflow(/* auto */-1065951377),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* column */-963948842),
                            /* :: */[
                              Css.justifyContent(/* spaceBetween */516682146),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, showHeader ? React.createElement(BugSectionHeader$BsConsole.make, {
                    title: I18N$BsConsole.get(undefined, "Session replay"),
                    className: Css.style(/* :: */[
                          Css.height(Css.px(20)),
                          /* [] */0
                        ])
                  }) : null, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setPlayerContainer, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    })
                }));
}

var make = BugReplayView;

exports.make = make;
/* Css Not a pure module */
