// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");

var guideLineColor = "#" + Colors$BsConsole.grey6;

function BugTreeIndentationGuide(Props) {
  var depth = Props.depth;
  var closeIndentation = Props.closeIndentation;
  var annotationOpt = Props.annotation;
  var annotation = annotationOpt !== undefined ? annotationOpt : false;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: Belt_Array.map(Belt_Array.range(0, depth), (function (idx) {
                      var height = String(20);
                      var width = String(20);
                      return React.createElement("div", {
                                  key: String(idx),
                                  className: Css.style(/* :: */[
                                        Css.width(Css.px(20)),
                                        /* :: */[
                                          Css.height(Css.px(20)),
                                          /* :: */[
                                            Css.flexShrink(0),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, closeIndentation === ((depth - idx | 0) + 1 | 0) ? React.createElement("svg", {
                                        height: height,
                                        width: width,
                                        shapeRendering: "crispEdges"
                                      }, annotation ? null : React.createElement("path", {
                                              d: "M 9 0 L 9 10 L " + (String(width) + " 10"),
                                              fill: "none",
                                              stroke: guideLineColor,
                                              strokeWidth: "1"
                                            })) : (
                                    closeIndentation > (depth - idx | 0) ? React.createElement("svg", {
                                            height: height,
                                            width: width,
                                            shapeRendering: "crispEdges"
                                          }, annotation ? null : React.createElement("path", {
                                                  d: "M 0 10 L " + (String(width) + " 10 M 9 0 L 9 10"),
                                                  fill: "none",
                                                  stroke: guideLineColor,
                                                  strokeWidth: "1"
                                                })) : React.createElement("svg", {
                                            height: height,
                                            width: width,
                                            shapeRendering: "crispEdges"
                                          }, React.createElement("path", {
                                                d: "M 9 0 L 9 " + (String(height) + ""),
                                                fill: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                                                stroke: guideLineColor,
                                                strokeWidth: "1"
                                              }))
                                  ));
                    }))
            });
}

var make = BugTreeIndentationGuide;

exports.guideLineColor = guideLineColor;
exports.make = make;
/* Css Not a pure module */
