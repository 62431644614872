// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfDropdown$BsConsole = require("./WfDropdown.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");

var component = ReasonReact.reducerComponent("WfEnum-BsConsole");

function make(fieldDoc, initialValue, padding, onValueChange, showAllErrors, schema, remoteAction, param) {
  var validate = function (value) {
    var err = WfCore$BsConsole.Enum.validate(schema, value);
    if (err !== undefined) {
      return /* Error */Block.__(1, [err]);
    } else {
      return /* Ok */Block.__(0, [value]);
    }
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (optJson) {
                          if (optJson !== undefined) {
                            return Caml_option.valFromOption(optJson);
                          } else {
                            return null;
                          }
                        })));
              return Curry._1(remoteAction, (function (param) {
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var validatedValue = state.validatedValue;
              var send = param.send;
              var error;
              error = validatedValue.tag ? validatedValue[0] : undefined;
              return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, schema.required, padding, error, undefined, state.dirty, showAllErrors, [ReasonReact.element(undefined, undefined, WfDropdown$BsConsole.make(state.value, (function (v) {
                                          return Curry._1(send, /* Select */[v]);
                                        }), schema.placeholder, Belt_Array.map(schema.items, (function (item) {
                                              return /* tuple */[
                                                      item.label,
                                                      item.value
                                                    ];
                                            })), schema.required, []))]));
            }),
          initialState: (function (param) {
              var match = schema.defaultValue;
              var value = initialValue !== undefined ? Caml_option.some(Caml_option.valFromOption(initialValue)) : (
                  match !== undefined ? Caml_option.some(Caml_option.valFromOption(match)) : undefined
                );
              return {
                      value: value,
                      validatedValue: validate(value),
                      dirty: false,
                      elementRef: {
                        contents: undefined
                      },
                      selectRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              var value = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          value: value,
                          validatedValue: validate(value),
                          dirty: true,
                          elementRef: state.elementRef,
                          selectRef: state.selectRef
                        },
                        (function (param) {
                            return Curry._1(onValueChange, Belt_Result.map(param.state.validatedValue, (function (optJson) {
                                              if (optJson !== undefined) {
                                                return Caml_option.valFromOption(optJson);
                                              } else {
                                                return null;
                                              }
                                            })));
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
