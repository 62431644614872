// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var item = Css.style(/* :: */[
      Css.height(Css.px(20)),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* :: */[
          Css.borderRadius(/* zero */-789508312),
          /* [] */0
        ]
      ]
    ]);

var textLight = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var backgroundDark = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var textDark = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accentDark)),
      /* [] */0
    ]);

var backgroundLight = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
      /* [] */0
    ]);

var tagsClose = Css.style(/* :: */[
      Css.height(Css.pct(100)),
      /* :: */[
        Css.marginRight(/* zero */-789508312),
        /* :: */[
          Css.paddingRight(Css.px(2)),
          /* :: */[
            Css.paddingLeft(Css.px(2)),
            /* [] */0
          ]
        ]
      ]
    ]);

var label = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.padding2(/* zero */-789508312, Css.px(8)),
        /* [] */0
      ]
    ]);

var Styles = {
  item: item,
  textLight: textLight,
  backgroundDark: backgroundDark,
  textDark: textDark,
  backgroundLight: backgroundLight,
  tagsClose: tagsClose,
  label: label
};

var component = ReasonReact.reducerComponent("Renderer_Tags-BsConsole");

function vToAttribute(v) {
  if (v) {
    return "classifiers";
  } else {
    return Issues$BsConsole.Tags.columnName;
  }
}

function make($staropt$star, variant, items, $staropt$star$1, handleAddFilters, handleChangeUrl, onDelete, $staropt$star$2, _children) {
  $staropt$star !== undefined;
  if ($staropt$star$1 !== undefined) {
    Caml_option.valFromOption($staropt$star$1);
  } else {
    ({ });
  }
  $staropt$star$2 !== undefined;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* nowrap */867913355, undefined, [Belt_Array.mapWithIndex(items, (function (i, name) {
                                      if (name.length === 0) {
                                        return null;
                                      } else {
                                        return ReasonReact.element(String(i), undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                                        /* tuple */[
                                                          vToAttribute(variant),
                                                          /* Contains */Block.__(2, [/* `String */[
                                                                -976970511,
                                                                name
                                                              ]])
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            vToAttribute(variant),
                                                            /* NotContains */Block.__(3, [/* `String */[
                                                                  -976970511,
                                                                  name
                                                                ]])
                                                          ],
                                                          /* [] */0
                                                        ]
                                                      ], undefined, name, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                                          var partial_arg = {
                                                            fontWeight: "normal",
                                                            minHeight: "0px",
                                                            minWidth: "unset",
                                                            padding: "3px",
                                                            textTransform: "none"
                                                          };
                                                          var arg = function (param, param$1, param$2) {
                                                            return ValueDrilldown$BsConsole.wrapWithButton(onClick, partial_arg, param, param$1, param$2);
                                                          };
                                                          return React.createElement("span", {
                                                                      ref: refHandler,
                                                                      onFocus: onFocus,
                                                                      onClick: onClick,
                                                                      onMouseEnter: (function (_e) {
                                                                          return Curry._1(send, /* HoverChild */0);
                                                                        }),
                                                                      onMouseLeave: (function (_e) {
                                                                          return Curry._1(send, /* LeaveChild */1);
                                                                        })
                                                                    }, Curry._3(arg, undefined, undefined, onDelete !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                                    item + (" " + (
                                                                                        variant ? backgroundDark + (" " + textLight) : backgroundLight + (" " + textDark)
                                                                                      )),
                                                                                    {
                                                                                      label: label
                                                                                    },
                                                                                    Caml_option.some(I18N$BsConsole.showSkip(name)),
                                                                                    undefined,
                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make("extra-smaller-icon  hover-darken " + tagsClose, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]))),
                                                                                    (function (param) {
                                                                                        return Curry._1(onDelete, name);
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ])) : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                                    item + (" " + (
                                                                                        variant ? backgroundDark + (" " + textLight) : backgroundLight + (" " + textDark)
                                                                                      )),
                                                                                    {
                                                                                      label: label
                                                                                    },
                                                                                    Caml_option.some(I18N$BsConsole.showSkip(name)),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))));
                                                        }), undefined, undefined, []));
                                      }
                                    }))]));
            }),
          initialState: (function (param) {
              return {
                      childHover: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            childHover: false
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            childHover: true
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.vToAttribute = vToAttribute;
exports.make = make;
/* item Not a pure module */
