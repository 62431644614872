// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Validator = require("validator");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfUtils$BsConsole = require("../workflow/WfUtils.js");
var WfFormCell$BsConsole = require("../workflow/WfFormCell.js");
var ApiWfTickets$BsConsole = require("../workflow/workflows-api/ApiWfTickets.js");

var component = RR$BsConsole.reducerComponent("InboxUnlinkedTicket-BsConsole");

function make(showAllErrors, isUrlOpt, value, fieldDoc, onValueChange, disabledOpt, elementRef, param) {
  var isUrl = isUrlOpt !== undefined ? isUrlOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var tmp = value === "" ? /* Required */0 : (
                  isUrl && value.length > 0 && !Validator.isURL(value) ? /* InvalidUrl */5 : undefined
                );
              var tmp$1 = {
                style: {
                  padding: "8px 0"
                }
              };
              if (elementRef !== undefined) {
                tmp$1.ref = Caml_option.valFromOption(elementRef);
              }
              return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, true, undefined, tmp, undefined, param.state.dirty, showAllErrors, [React.createElement("div", tmp$1, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            true,
                                            (function (e) {
                                                Curry._1(send, /* SetDirty */0);
                                                return Curry._1(onValueChange, e.target.value.trim());
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            /* `String */[
                                              -976970511,
                                              value
                                            ],
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            disabled,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            []
                                          ])))]));
            }),
          initialState: (function (param) {
              return {
                      dirty: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          dirty: true
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var StringField = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("InboxUnlinkedTicket-BsConsole");

function make$1(showAllErrors, maybeLinkedIntegrationId, fieldDoc, onLinkedIntegrationIdChange, supportedWorkflows, elementRef, param) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              if (supportedWorkflows.length !== 0) {
                var tmp = {
                  style: {
                    padding: "8px 0"
                  }
                };
                if (elementRef !== undefined) {
                  tmp.ref = Caml_option.valFromOption(elementRef);
                }
                return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, true, undefined, maybeLinkedIntegrationId !== undefined ? undefined : /* Required */0, undefined, false, showAllErrors, [React.createElement("div", tmp, ReasonReact.element(undefined, undefined, Curry._4(ApiWfTickets$BsConsole.SelectLinkedIntegration.Jsx2.make, maybeLinkedIntegrationId, onLinkedIntegrationIdChange, supportedWorkflows, [])))]));
              }
              var tmp$1 = {
                style: {
                  padding: "8px 0"
                }
              };
              if (elementRef !== undefined) {
                tmp$1.ref = Caml_option.valFromOption(elementRef);
              }
              return ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, false, undefined, undefined, undefined, false, showAllErrors, [React.createElement("div", tmp$1, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [
                                            I18N$BsConsole.show(undefined, "No workflow integrations available. "),
                                            React.createElement("a", {
                                                  className: Css.style(/* :: */[
                                                        Css.fontSize(/* inherit_ */-601204732),
                                                        /* :: */[
                                                          Css.cursor(/* pointer */-786317123),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  onClick: (function (param) {
                                                      return Util$BsConsole.openUrl("https://docs.saucelabs.com/error-reporting/workflow-integrations/overview/");
                                                    })
                                                }, I18N$BsConsole.show(undefined, "Click here to learn more."))
                                          ])))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var SelectField = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.reducerComponent("InboxUnlinkedTicket-BsConsole");

function make$2(url, $$short, open_, onUrlChange, projectName, onSubmit, onShortChange, onClose, workflows, maybeLinkedIntegrationId, onLinkedIntegrationIdChange, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var supportedWorkflows = WfUtils$BsConsole.filterBySupportedAction(workflows, "ticket:create");
              var handleOnSumbit = function (v) {
                Curry._1(self.send, /* SetSubmittedTrue */1);
                if ($$short === "" || url === "" || Belt_Option.isNone(maybeLinkedIntegrationId) && supportedWorkflows.length !== 0) {
                  return Curry._1(self.send, /* ShowAllErrors */0);
                } else {
                  return Curry._1(onSubmit, v);
                }
              };
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              open_,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [React.createElement("div", {
                                      style: {
                                        overflowY: "auto",
                                        padding: "24px",
                                        width: "600px"
                                      }
                                    }, React.createElement("div", {
                                          style: {
                                            marginTop: "22px",
                                            padding: "0 17px"
                                          }
                                        }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, {
                                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                                                }, [I18N$BsConsole.show(undefined, "* Required")]))), ReasonReact.element(undefined, undefined, make(self.state.showAllErrors, true, url, {
                                              title: "URL",
                                              description: "e.g. http://hack.jara.com/" + (projectName + "/T1234")
                                            }, onUrlChange, undefined, undefined, [])), ReasonReact.element(undefined, undefined, make(self.state.showAllErrors, undefined, $$short, {
                                              title: "ID",
                                              description: "e.g. T1234"
                                            }, onShortChange, undefined, undefined, [])), ReasonReact.element(undefined, undefined, make$1(self.state.showAllErrors, maybeLinkedIntegrationId, {
                                              title: "Link to Integration",
                                              description: undefined
                                            }, onLinkedIntegrationIdChange, supportedWorkflows, undefined, [])), React.createElement("div", {
                                          style: {
                                            display: "flex",
                                            paddingTop: "16px",
                                            width: "100%",
                                            justifyContent: "flex-end"
                                          }
                                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  undefined,
                                                  Css.style(/* :: */[
                                                        Css.marginRight(Css.px(4)),
                                                        /* [] */0
                                                      ]),
                                                  Curry.__1(onClose),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [I18N$BsConsole.show(undefined, "Cancel")]
                                                ])), self.state.submitted ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Contained */-515484397,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 14, "inherit", undefined, undefined, []))]
                                                  ])) : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    handleOnSumbit,
                                                    undefined,
                                                    undefined,
                                                    /* Contained */-515484397,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Submit")]
                                                  ]))))]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      showAllErrors: false,
                      submitted: false
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            showAllErrors: state.showAllErrors,
                            submitted: true
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            showAllErrors: true,
                            submitted: false
                          }]);
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var make$3 = ReasonReactCompat.wrapReasonReactForReact(component$2, (function (reactProps) {
        return make$2(reactProps.url, reactProps.short, reactProps.open_, reactProps.onUrlChange, reactProps.projectName, reactProps.onSubmit, reactProps.onShortChange, reactProps.onClose, reactProps.workflows, reactProps.maybeLinkedIntegrationId, reactProps.onLinkedIntegrationIdChange, []);
      }));

var Jsx3Compat = {
  make: make$3
};

exports.StringField = StringField;
exports.SelectField = SelectField;
exports.component = component$2;
exports.make = make$2;
exports.Jsx3Compat = Jsx3Compat;
/* component Not a pure module */
