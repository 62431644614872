// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Gate$BsConsole = require("./Gate.js");
var Util$BsConsole = require("./util.js");
var Error$BsConsole = require("./error.js");
var Metrics$BsConsole = require("./bs-metrics/Metrics.js");
var Service$BsConsole = require("./Service.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Services$BsConsole = require("./Services.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var DemoProjectUtil$BsConsole = require("./DemoProjectUtil.js");
var UserFrontendSettings$BsConsole = require("./UserFrontendSettings.js");
var ProjectFrontendSettings$BsConsole = require("./ProjectFrontendSettings.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          tx: Json_decode.optional((function (param) {
                  return Json_decode.field("tx", (function (json) {
                                return Json_decode.string(JSON.stringify(json));
                              }), param);
                }), json)
        };
}

var ProjectMeta = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          role: Json_decode.optional((function (param) {
                  return Json_decode.field("role", Json_decode.string, param);
                }), json)
        };
}

var ProjectRole = {
  fromJson: fromJson$1
};

function getProjectTx(projectName, config) {
  return Belt_Option.flatMap(Belt_List.getBy(config.projects_meta, (function (project_meta) {
                    return project_meta.name === projectName;
                  })), (function (param) {
                return param.tx;
              }));
}

function getProjectRole(projectName, config) {
  return Belt_Option.flatMap(Belt_List.getBy(config.projectRoles, (function (projectRoles) {
                    return projectRoles.name === projectName;
                  })), (function (param) {
                return param.role;
              }));
}

function getProject(projectName, config) {
  return Belt_List.getBy(config.projects, (function (project) {
                return project.name === projectName;
              }));
}

function getWatchers(projectName, config) {
  return Belt_List.getBy(config.watchers, (function (param) {
                return param[0] === projectName;
              }));
}

var decodeProject = BacktraceParserGenerator$BsConsole.Project.of_json;

function filterOutDemoProjects(projects) {
  return Belt_List.filter(projects, (function (project) {
                return !DemoProjectUtil$BsConsole.isDemoProject(project.name);
              }));
}

function numberOfNonDemoProjects(projects) {
  return List.length(Belt_List.filter(projects, (function (project) {
                    return !DemoProjectUtil$BsConsole.isDemoProject(project.name);
                  })));
}

function decodeProjectSettings(json) {
  var projectName = Json_decode.field("name", Json_decode.string, json);
  var settings = Belt_Option.map(Belt_Option.flatMap(Json_decode.optional((function (param) {
                  return Json_decode.field("frontend_settings", Json_decode.string, param);
                }), json), Json.parse), ProjectFrontendSettings$BsConsole.ofJson);
  if (settings !== undefined) {
    return /* tuple */[
            projectName,
            settings
          ];
  }
  
}

function decodeProjectsSettings(json) {
  var __x = Json_decode.list(decodeProjectSettings, json);
  return Belt_List.keepMap(__x, Util$BsConsole.identity);
}

function decodeProjectWatchers(json) {
  var projectName = Json_decode.field("name", Json_decode.string, json);
  var watchers = Json_decode.field("watchers", (function (param) {
          return Json_decode.list((function (json) {
                        return {
                                name: Json_decode.field("name", Json_decode.string, json),
                                integrationType: Json_decode.field("integration_type", Json_decode.string, json)
                              };
                      }), param);
        }), json);
  return /* tuple */[
          projectName,
          watchers
        ];
}

var decodeUser = BacktraceParserGenerator$BsConsole.Users.of_json;

var decodeUserSettings = UserFrontendSettings$BsConsole.ofJson;

function decodeEndpoint(json) {
  var match = Json_decode.field("protocol", Json_decode.string, json);
  var tmp;
  switch (match) {
    case "http" :
        tmp = /* Http */1;
        break;
    case "https" :
        tmp = /* Https */0;
        break;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "configuration.re",
              155,
              15
            ]
          ];
  }
  return {
          protocol: tmp,
          port: Json_decode.field("port", Json_decode.$$int, json)
        };
}

function decodeEndpoints(json) {
  return Json_decode.field("endpoints", (function (param) {
                return Json_decode.dict((function (param) {
                              return Json_decode.list(decodeEndpoint, param);
                            }), param);
              }), json);
}

function decode(handleFailure, json) {
  var error = Error$BsConsole.HTTPD.decode(json);
  var exit = 0;
  if (error !== undefined) {
    if (error.message === "invalid token") {
      Curry._1(handleFailure, undefined);
      return ;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var maybeUniverse;
    try {
      maybeUniverse = Json_decode.at(/* :: */[
              "universe",
              /* :: */[
                "name",
                /* [] */0
              ]
            ], (function (param) {
                return Json_decode.optional(Json_decode.string, param);
              }))(json);
    }
    catch (exn){
      try {
        maybeUniverse = Belt_List.get(Object.keys(json.universes), 0);
      }
      catch (raw_err){
        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
        var __x = Backtrace$BsConsole.Attributes.addString("jsonToDecode", JSON.stringify(json), Backtrace$BsConsole.Attributes.addString("src_module", "Configuration/decode/maybeUniverse", Backtrace$BsConsole.Attributes.make(undefined)));
        Backtrace$BsConsole.Client.send(__x, /* `exn */[
              5049499,
              Uncaught$BsConsole.castToJsExn(err)
            ]);
        maybeUniverse = undefined;
      }
    }
    var maybeUniverses;
    try {
      maybeUniverses = Object.keys(json.universes);
    }
    catch (raw_err$1){
      var err$1 = Caml_js_exceptions.internalToOCamlException(raw_err$1);
      var __x$1 = Backtrace$BsConsole.Attributes.addString("jsonToDecode", JSON.stringify(json), Backtrace$BsConsole.Attributes.addString("src_module", "Configuration/decode/maybeUniverses", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
            5049499,
            Uncaught$BsConsole.castToJsExn(err$1)
          ]);
      maybeUniverses = undefined;
    }
    var maybeUser;
    try {
      maybeUser = Json_decode.field("user", decodeUser, json);
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      var __x$2 = Backtrace$BsConsole.Attributes.addString("jsonToDecode", JSON.stringify(json), Backtrace$BsConsole.Attributes.addString("src_module", "Configuration/decode/maybeUser", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x$2, /* `exn */[
            5049499,
            Uncaught$BsConsole.castToJsExn(e)
          ]);
      maybeUser = undefined;
    }
    if (maybeUniverse !== undefined && maybeUser !== undefined && maybeUniverses !== undefined) {
      var exit$1 = 0;
      var config;
      try {
        var tmp;
        try {
          tmp = Json_decode.optional((function (param) {
                  return Json_decode.field("users", (function (param) {
                                return Json_decode.list(decodeUser, param);
                              }), param);
                }), json);
        }
        catch (exn$1){
          tmp = undefined;
        }
        var match = maybeUser.superuser;
        var match$1 = maybeUniverses.length;
        config = {
          coronerdVersion: Json_decode.at(/* :: */[
                  "config",
                  /* :: */[
                    "version",
                    /* [] */0
                  ]
                ], Json_decode.string)(json),
          universe: Json_decode.field("universe", BacktraceParserGenerator$BsConsole.Universe.of_json, json),
          user: maybeUser,
          users: tmp,
          userSettings: Belt_Option.map(Belt_Option.flatMap(Json_decode.at(/* :: */[
                          "user",
                          /* [] */0
                        ], (function (json) {
                            return Json_decode.optional((function (param) {
                                          return Json_decode.field("frontend_settings", Json_decode.string, param);
                                        }), json);
                          }))(json), Json.parse), UserFrontendSettings$BsConsole.ofJson),
          hasProjects: Json_decode.optional(Json_decode.at(/* :: */[
                    "universes",
                    /* :: */[
                      maybeUniverse,
                      /* :: */[
                        "has_projects",
                        /* [] */0
                      ]
                    ]
                  ], Json_decode.bool), json),
          projects: Json_decode.at(/* :: */[
                  "universes",
                  /* :: */[
                    maybeUniverse,
                    /* :: */[
                      "projects_ext",
                      /* [] */0
                    ]
                  ]
                ], (function (param) {
                    return Json_decode.list(decodeProject, param);
                  }))(json),
          projectsSettings: Json_decode.at(/* :: */[
                  "universes",
                  /* :: */[
                    maybeUniverse,
                    /* :: */[
                      "projects_ext",
                      /* [] */0
                    ]
                  ]
                ], decodeProjectsSettings)(json),
          watchers: Json_decode.at(/* :: */[
                  "universes",
                  /* :: */[
                    maybeUniverse,
                    /* :: */[
                      "projects_ext",
                      /* [] */0
                    ]
                  ]
                ], (function (param) {
                    return Json_decode.list(decodeProjectWatchers, param);
                  }))(json),
          endpoints: decodeEndpoints(json),
          tenancy: match !== 1 || match$1 <= 1 ? /* Single */0 : /* Multi */[maybeUniverses],
          lastProjectPid: Json_decode.optional(Json_decode.at(/* :: */[
                    "ephemeral",
                    /* :: */[
                      "last_project_pid",
                      /* [] */0
                    ]
                  ], Json_decode.$$int), json),
          projects_meta: Json_decode.oneOf(/* :: */[
                (function (json) {
                    return Json_decode.at(/* :: */[
                                  "universes",
                                  /* :: */[
                                    maybeUniverse,
                                    /* :: */[
                                      "projects_ext",
                                      /* [] */0
                                    ]
                                  ]
                                ], (function (param) {
                                    return Json_decode.list(fromJson, param);
                                  }))(json);
                  }),
                /* [] */0
              ], json),
          projectRoles: Json_decode.at(/* :: */[
                  "universes",
                  /* :: */[
                    maybeUniverse,
                    /* :: */[
                      "projects_ext",
                      /* [] */0
                    ]
                  ]
                ], (function (param) {
                    return Json_decode.list(fromJson$1, param);
                  }))(json),
          services: Json_decode.optional((function (param) {
                  return Json_decode.field("services", Services$BsConsole.fromJson, param);
                }), json)
        };
        exit$1 = 2;
      }
      catch (raw_msg){
        var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
        if (msg[0] === Json_decode.DecodeError) {
          var __x$3 = Backtrace$BsConsole.Attributes.addString("jsonToDecode", JSON.stringify(json), Backtrace$BsConsole.Attributes.addString("src_module", "Configuration/decode/config", Backtrace$BsConsole.Attributes.make(undefined)));
          Backtrace$BsConsole.Client.send(__x$3, /* `string */[
                288368849,
                msg[1]
              ]);
          Curry._1(handleFailure, undefined);
          return ;
        }
        throw msg;
      }
      if (exit$1 === 2) {
        Backtrace$BsConsole.Client.memorize("coronerdVersion", config.coronerdVersion);
        Backtrace$BsConsole.Client.memorize("universe.name", config.universe.name);
        Backtrace$BsConsole.Client.memorize("user.username", config.user.username);
        Backtrace$BsConsole.Client.memorize("user.email", config.user.email);
        Belt_Option.map(config.user.role, (function (role) {
                return Backtrace$BsConsole.Client.memorize("user.role", role);
              }));
        Backtrace$BsConsole.Client.memorize("react.version", React.version);
        var metricsServiceFromConfig = Belt_Option.flatMap(config.services, (function (services) {
                return Belt_Option.flatMap(Belt_List.getBy(services, (function (service) {
                                  return service.name === "hearse";
                                })), (function (service) {
                              return service.endpoint;
                            }));
              }));
        Metrics$BsConsole.initialize(/* :: */[
              /* tuple */[
                "coronerdVersion",
                config.coronerdVersion
              ],
              /* :: */[
                /* tuple */[
                  "universe",
                  config.universe.name
                ],
                /* :: */[
                  /* tuple */[
                    "email",
                    config.user.email
                  ],
                  /* :: */[
                    /* tuple */[
                      "username",
                      config.user.username
                    ],
                    /* :: */[
                      /* tuple */[
                        "$distinct_id",
                        config.user.username
                      ],
                      /* :: */[
                        /* tuple */[
                          "alpha_console",
                          Gate$BsConsole.isAlpha(undefined)
                        ],
                        /* :: */[
                          /* tuple */[
                            "beta_console",
                            Gate$BsConsole.isBeta(undefined)
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ], metricsServiceFromConfig);
        return config;
      }
      
    } else {
      Curry._1(handleFailure, undefined);
      return ;
    }
  }
  
}

function updateCurrentUser(t$prime, u$prime) {
  return {
          coronerdVersion: t$prime.coronerdVersion,
          universe: t$prime.universe,
          user: u$prime,
          users: t$prime.users,
          userSettings: t$prime.userSettings,
          hasProjects: t$prime.hasProjects,
          projects: t$prime.projects,
          projectsSettings: t$prime.projectsSettings,
          watchers: t$prime.watchers,
          endpoints: t$prime.endpoints,
          tenancy: t$prime.tenancy,
          lastProjectPid: t$prime.lastProjectPid,
          projects_meta: t$prime.projects_meta,
          projectRoles: t$prime.projectRoles,
          services: t$prime.services
        };
}

function getPort(endpointOpt, config) {
  var endpoint = endpointOpt !== undefined ? endpointOpt : "api/post";
  var endpoints = Js_dict.get(config.endpoints, endpoint);
  if (endpoints === undefined) {
    return ;
  }
  var protocol = location.protocol.replace(":", "");
  var endpoint$1 = Belt_Option.flatMap(Belt_List.getBy(/* :: */[
            Belt_List.getBy(endpoints, (function (endpoint) {
                    if (endpoint.protocol === /* Https */0) {
                      return protocol === "https";
                    } else {
                      return false;
                    }
                  })),
            /* :: */[
              Belt_List.getBy(endpoints, (function (endpoint) {
                      if (endpoint.protocol === /* Http */1) {
                        return protocol === "http";
                      } else {
                        return false;
                      }
                    })),
              /* [] */0
            ]
          ], Belt_Option.isSome), Util$BsConsole.identity);
  if (endpoint$1 !== undefined) {
    return endpoint$1.port;
  }
  
}

function getEndpointWithProjectOverrides(projectName, additionalOverridesOpt, config, name) {
  var additionalOverrides = additionalOverridesOpt !== undefined ? Caml_option.valFromOption(additionalOverridesOpt) : undefined;
  var serviceFromWinvars = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(window._BACKTRACE_SERVICE_OVERRIDES), Json.parse), (function (overrides) {
                  try {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2(Json_decode.string, Service$BsConsole.fromJson, param);
                                }), overrides);
                  }
                  catch (exn){
                    console.log("_BACKTRACE_SERVICE_OVERRIDES Decode Error");
                    return ;
                  }
                })), (function (overrides) {
              return Belt_List.getBy(overrides, (function (param) {
                            if (projectName === "*" || param[0] === projectName) {
                              return param[1].name === name;
                            } else {
                              return false;
                            }
                          }));
            })), (function (param) {
          return param[1].endpoint;
        }));
  var serviceFromPfeSettings = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_List.getBy(config.projectsSettings, (function (param) {
                      return param[0] === projectName;
                    })), (function (param) {
                  return param[1].serviceOverrides;
                })), (function (overrides) {
              return Belt_List.getBy(overrides, (function (service) {
                            return service.name === "sourcecode";
                          }));
            })), (function (s) {
          return s.endpoint;
        }));
  var serviceFromOverrides = Belt_Option.flatMap(Belt_Option.flatMap(additionalOverrides, (function (overrides) {
              return Belt_List.getBy(overrides, (function (param) {
                            if (projectName === "*" || param[0] === projectName) {
                              return param[1].name === name;
                            } else {
                              return false;
                            }
                          }));
            })), (function (param) {
          return param[1].endpoint;
        }));
  var serviceFromConfig = Belt_Option.flatMap(Belt_Option.flatMap(config.services, (function (services) {
              return Belt_List.getBy(services, (function (service) {
                            return service.name === name;
                          }));
            })), (function (s) {
          return s.endpoint;
        }));
  if (serviceFromWinvars !== undefined) {
    return serviceFromWinvars;
  } else if (serviceFromPfeSettings !== undefined) {
    return serviceFromPfeSettings;
  } else if (serviceFromOverrides !== undefined) {
    return serviceFromOverrides;
  } else if (serviceFromConfig !== undefined) {
    return serviceFromConfig;
  } else {
    return ;
  }
}

exports.ProjectMeta = ProjectMeta;
exports.ProjectRole = ProjectRole;
exports.getProjectTx = getProjectTx;
exports.getProjectRole = getProjectRole;
exports.getProject = getProject;
exports.getWatchers = getWatchers;
exports.decodeProject = decodeProject;
exports.filterOutDemoProjects = filterOutDemoProjects;
exports.numberOfNonDemoProjects = numberOfNonDemoProjects;
exports.decodeProjectSettings = decodeProjectSettings;
exports.decodeProjectsSettings = decodeProjectsSettings;
exports.decodeProjectWatchers = decodeProjectWatchers;
exports.decodeUser = decodeUser;
exports.decodeUserSettings = decodeUserSettings;
exports.decodeEndpoint = decodeEndpoint;
exports.decodeEndpoints = decodeEndpoints;
exports.decode = decode;
exports.updateCurrentUser = updateCurrentUser;
exports.getPort = getPort;
exports.getEndpointWithProjectOverrides = getEndpointWithProjectOverrides;
/* react Not a pure module */
