// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Email$BsConsole = require("./Email.js");
var Colors$BsConsole = require("./Colors.js");

function BillingFeatureFallback(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontFamily("Roboto"),
                    /* :: */[
                      Css.fontStyle(/* normal */812216871),
                      /* :: */[
                        Css.fontWeight(/* normal */812216871),
                        /* :: */[
                          Css.fontSize(Css.px(14)),
                          /* :: */[
                            Css.lineHeight(Css.px(16)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.black)),
                              /* :: */[
                                Css.marginTop(Css.px(8)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, "To modify, contact " + Email$BsConsole.support));
}

var make = BillingFeatureFallback;

exports.make = make;
/* Css Not a pure module */
