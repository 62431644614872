// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Core = require("@material-ui/core");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiEnums$BsConsole = require("../MuiEnums.js");
var Tab = require("@material-ui/core/Tab");
var Card = require("@material-ui/core/Card");
var Chip = require("@material-ui/core/Chip");
var Fade = require("@material-ui/core/Fade");
var Grid = require("@material-ui/core/Grid");
var List = require("@material-ui/core/List");
var Menu = require("@material-ui/core/Menu");
var Step = require("@material-ui/core/Step");
var Tabs = require("@material-ui/core/Tabs");
var Badge = require("@material-ui/core/Badge");
var Input = require("@material-ui/core/Input");
var Paper = require("@material-ui/core/Paper");
var Radio = require("@material-ui/core/Radio");
var Table = require("@material-ui/core/Table");
var AppBar = require("@material-ui/core/AppBar");
var Avatar = require("@material-ui/core/Avatar");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Drawer = require("@material-ui/core/Drawer");
var Portal = require("@material-ui/core/Portal");
var Select = require("@material-ui/core/Select");
var Switch = require("@material-ui/core/Switch");
var Styles = require("@material-ui/core/styles");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Stepper = require("@material-ui/core/Stepper");
var Toolbar = require("@material-ui/core/Toolbar");
var Tooltip = require("@material-ui/core/Tooltip");
var Checkbox = require("@material-ui/core/Checkbox");
var Collapse = require("@material-ui/core/Collapse");
var ListItem = require("@material-ui/core/ListItem");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var Snackbar = require("@material-ui/core/Snackbar");
var TableRow = require("@material-ui/core/TableRow");
var FormGroup = require("@material-ui/core/FormGroup");
var FormLabel = require("@material-ui/core/FormLabel");
var StepLabel = require("@material-ui/core/StepLabel");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var TextField = require("@material-ui/core/TextField");
var ButtonBase = require("@material-ui/core/ButtonBase");
var CardHeader = require("@material-ui/core/CardHeader");
var IconButton = require("@material-ui/core/IconButton");
var InputLabel = require("@material-ui/core/InputLabel");
var RadioGroup = require("@material-ui/core/RadioGroup");
var StepButton = require("@material-ui/core/StepButton");
var Typography = require("@material-ui/core/Typography");
var CardActions = require("@material-ui/core/CardActions");
var CardContent = require("@material-ui/core/CardContent");
var DialogTitle = require("@material-ui/core/DialogTitle");
var FormControl = require("@material-ui/core/FormControl");
var TableFooter = require("@material-ui/core/TableFooter");
var ListItemIcon = require("@material-ui/core/ListItemIcon");
var ListItemText = require("@material-ui/core/ListItemText");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var ExpansionPanel = require("@material-ui/core/ExpansionPanel");
var FormHelperText = require("@material-ui/core/FormHelperText");
var InputAdornment = require("@material-ui/core/InputAdornment");
var LinearProgress = require("@material-ui/core/LinearProgress");
var TablePagination = require("@material-ui/core/TablePagination");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");
var ClickAwayListener = require("@material-ui/core/ClickAwayListener");
var DialogContentText = require("@material-ui/core/DialogContentText");
var ExpansionPanelDetails = require("@material-ui/core/ExpansionPanelDetails");
var ExpansionPanelSummary = require("@material-ui/core/ExpansionPanelSummary");

function unwrapJsValue(value) {
  return value[1];
}

var GenericValue = {
  unwrapJsValue: unwrapJsValue
};

function make(theme, children) {
  return ReasonReact.wrapJsForReason(Styles.MuiThemeProvider, {
              theme: theme
            }, children);
}

var MuiThemeProvider = {
  make: make
};

function make$1(children) {
  return ReasonReact.wrapJsForReason(Core.CssBaseline, (function (prim) {
                return { };
              }), children);
}

var CssBaseline = {
  make: make$1
};

function make$2(className, style, container, item, spacing, lg, md, sm, xl, xs, wrap, justify, direction, alignContent, alignItems, children) {
  return ReasonReact.wrapJsForReason(Grid.default, {
              className: Js_null_undefined.fromOption(className),
              style: Js_null_undefined.fromOption(style),
              container: Js_null_undefined.fromOption(container),
              item: Js_null_undefined.fromOption(item),
              spacing: Js_null_undefined.fromOption(Belt_Option.map(spacing, MuiEnums$BsConsole.spacingToJs)),
              lg: Js_null_undefined.fromOption(Belt_Option.map(lg, MuiEnums$BsConsole.lgToJs)),
              md: Js_null_undefined.fromOption(Belt_Option.map(md, MuiEnums$BsConsole.mdToJs)),
              sm: Js_null_undefined.fromOption(Belt_Option.map(sm, MuiEnums$BsConsole.smToJs)),
              xl: Js_null_undefined.fromOption(Belt_Option.map(xl, MuiEnums$BsConsole.xlToJs)),
              xs: Js_null_undefined.fromOption(Belt_Option.map(xs, MuiEnums$BsConsole.xsToJs)),
              wrap: Js_null_undefined.fromOption(Belt_Option.map(wrap, MuiEnums$BsConsole.wrapToJs)),
              justify: Js_null_undefined.fromOption(Belt_Option.map(justify, MuiEnums$BsConsole.justifyToJs)),
              direction: Js_null_undefined.fromOption(Belt_Option.map(direction, MuiEnums$BsConsole.directionToJs)),
              alignContent: Js_null_undefined.fromOption(Belt_Option.map(alignContent, MuiEnums$BsConsole.alignContentToJs)),
              alignItems: Js_null_undefined.fromOption(Belt_Option.map(alignItems, MuiEnums$BsConsole.alignItemsToJs))
            }, children);
}

var Grid$1 = {
  make: make$2
};

function make$3(in_, mountOnEnter, unmountOnExit, style, children) {
  return ReasonReact.wrapJsForReason(Fade.default, {
              in: in_,
              mountOnEnter: mountOnEnter,
              unmountOnExit: unmountOnExit,
              style: style
            }, children);
}

var Fade$1 = {
  make: make$3
};

var ThemeProvider = { };

function make$4(classes, title, interactive, placement, enterDelay, leaveDelay, theme, children) {
  return RR$BsConsole.wrapJsForReason(Tooltip.default, {
              classes: Js_null_undefined.fromOption(classes),
              title: title,
              interactive: Js_null_undefined.fromOption(interactive),
              placement: Js_null_undefined.fromOption(Belt_Option.map(placement, MuiEnums$BsConsole.tooltipPlacementToJs)),
              enterDelay: Js_null_undefined.fromOption(enterDelay),
              leaveDelay: Js_null_undefined.fromOption(leaveDelay),
              theme: Js_null_undefined.fromOption(theme)
            }, children);
}

var Jsx2 = {
  make: make$4
};

var Tooltip$1 = {
  Jsx2: Jsx2
};

var Icon = { };

var BaseIconButton = { };

function Mui$IconButton(Props) {
  var disableRipple = Props.disableRipple;
  var ariaLabel = Props.ariaLabel;
  var disabled = Props.disabled;
  var classes = Props.classes;
  var color = Props.color;
  var size = Props.size;
  var className = Props.className;
  var onClick = Props.onClick;
  var onFocus = Props.onFocus;
  var children = Props.children;
  var tmp = {
    children: children
  };
  if (disableRipple !== undefined) {
    tmp.disableRipple = Caml_option.valFromOption(disableRipple);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  return React.cloneElement(React.createElement(IconButton.default, tmp), {
              "aria-label": I18N$BsConsole.get(undefined, ariaLabel)
            });
}

function make$5(className, style, onClick, onMouseDown, onMouseUp, disableRipple, disabled, type_, classes, color, ariaLabel, children) {
  return ReasonReact.wrapJsForReason(IconButton.default, {
              className: Js_null_undefined.fromOption(className),
              style: Js_null_undefined.fromOption(style),
              onMouseDown: Js_null_undefined.fromOption(onMouseDown),
              onMouseUp: Js_null_undefined.fromOption(onMouseUp),
              onClick: Js_null_undefined.fromOption(onClick),
              type: Js_null_undefined.fromOption(type_),
              classes: Js_null_undefined.fromOption(classes),
              disableRipple: Js_null_undefined.fromOption(disableRipple),
              disabled: Js_null_undefined.fromOption(disabled),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              "aria-label": ariaLabel
            }, children);
}

var Jsx2$1 = {
  make: make$5
};

var IconButton$1 = {
  make: Mui$IconButton,
  Jsx2: Jsx2$1
};

function Mui$IconButtonWithTooltipJsx3(Props) {
  var disableRipple = Props.disableRipple;
  var ariaLabel = Props.ariaLabel;
  var disabled = Props.disabled;
  var classes = Props.classes;
  var color = Props.color;
  var size = Props.size;
  var className = Props.className;
  var onClick = Props.onClick;
  var onFocus = Props.onFocus;
  var classes_tooltip = Props.classes_tooltip;
  var interactive_tooltip = Props.interactive_tooltip;
  var placement_tooltip = Props.placement_tooltip;
  var enterDelay_tooltip = Props.enterDelay_tooltip;
  var leaveDelay_tooltip = Props.leaveDelay_tooltip;
  var onEnter_tooltip = Props.onEnter_tooltip;
  var _open_tooltip = Props._open_tooltip;
  var title_tooltip = Props.title_tooltip;
  var onOpen_tooltip = Props.onOpen_tooltip;
  var children = Props.children;
  var tmp = {
    children: children
  };
  if (disableRipple !== undefined) {
    tmp.disableRipple = Caml_option.valFromOption(disableRipple);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  var tmp$1 = {
    children: React.cloneElement(React.createElement(IconButton.default, tmp), {
          "aria-label": I18N$BsConsole.get(undefined, ariaLabel)
        }),
    title: title_tooltip
  };
  if (classes_tooltip !== undefined) {
    tmp$1.classes = Caml_option.valFromOption(classes_tooltip);
  }
  if (interactive_tooltip !== undefined) {
    tmp$1.interactive = Caml_option.valFromOption(interactive_tooltip);
  }
  if (placement_tooltip !== undefined) {
    tmp$1.placement = Caml_option.valFromOption(placement_tooltip);
  }
  if (enterDelay_tooltip !== undefined) {
    tmp$1.enterDelay = Caml_option.valFromOption(enterDelay_tooltip);
  }
  if (leaveDelay_tooltip !== undefined) {
    tmp$1.leaveDelay = Caml_option.valFromOption(leaveDelay_tooltip);
  }
  if (onEnter_tooltip !== undefined) {
    tmp$1.onEnter = Caml_option.valFromOption(onEnter_tooltip);
  }
  if (_open_tooltip !== undefined) {
    tmp$1.open = Caml_option.valFromOption(_open_tooltip);
  }
  if (onOpen_tooltip !== undefined) {
    tmp$1.onOpen = Caml_option.valFromOption(onOpen_tooltip);
  }
  return React.createElement(Tooltip.default, tmp$1);
}

var IconButtonWithTooltipJsx3 = {
  make: Mui$IconButtonWithTooltipJsx3
};

function make$6(disabled, color, checked, onChange, className, classes, value, children) {
  return RR$BsConsole.wrapJsForReason(Switch.default, {
              disabled: Js_null_undefined.fromOption(disabled),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              checked: Js_null_undefined.fromOption(Belt_Option.map(checked, unwrapJsValue)),
              onChange: Js_null_undefined.fromOption(onChange),
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              value: Js_null_undefined.fromOption(value)
            }, children);
}

var Jsx2$2 = {
  make: make$6
};

var Switch$1 = {
  Jsx2: Jsx2$2
};

function make$7(size, className, onClick, onMouseDown, onMouseUp, variant, color, type_, style, disabled, href, onFocus, fullWidth, disableRipple, title, classes) {
  var partial_arg = {
    classes: Js_null_undefined.fromOption(classes),
    className: Js_null_undefined.fromOption(className),
    size: Js_null_undefined.fromOption(Belt_Option.map(size, MuiEnums$BsConsole.sizeToJs)),
    onClick: Js_null_undefined.fromOption(onClick),
    onMouseDown: Js_null_undefined.fromOption(onMouseDown),
    onMouseUp: Js_null_undefined.fromOption(onMouseUp),
    variant: Js_null_undefined.fromOption(Belt_Option.map(variant, MuiEnums$BsConsole.buttonVariantToJs)),
    color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
    type: Js_null_undefined.fromOption(type_),
    style: Js_null_undefined.fromOption(style),
    disabled: Js_null_undefined.fromOption(disabled),
    href: Js_null_undefined.fromOption(href),
    onFocus: Js_null_undefined.fromOption(onFocus),
    fullWidth: Js_null_undefined.fromOption(fullWidth),
    disableRipple: Js_null_undefined.fromOption(disableRipple),
    title: Js_null_undefined.fromOption(title)
  };
  var partial_arg$1 = Button.default;
  return (function (param) {
      return ReasonReact.wrapJsForReason(partial_arg$1, partial_arg, param);
    });
}

var Jsx2$3 = {
  make: make$7
};

var Button$1 = {
  Jsx2: Jsx2$3
};

function make$8(className, style, onClick, onMouseDown, onMouseUp, type_) {
  var partial_arg = {
    className: Js_null_undefined.fromOption(className),
    style: Js_null_undefined.fromOption(style),
    onMouseDown: Js_null_undefined.fromOption(onMouseDown),
    onMouseUp: Js_null_undefined.fromOption(onMouseUp),
    onClick: Js_null_undefined.fromOption(onClick),
    type: Js_null_undefined.fromOption(type_)
  };
  var partial_arg$1 = ButtonBase.default;
  return (function (param) {
      return ReasonReact.wrapJsForReason(partial_arg$1, partial_arg, param);
    });
}

var Jsx2$4 = {
  make: make$8
};

var ButtonBase$1 = {
  Jsx2: Jsx2$4
};

function make$9(classes, className, style, square, elevation, children) {
  var tmp = {
    children: children
  };
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (square !== undefined) {
    tmp.square = Caml_option.valFromOption(square);
  }
  if (elevation !== undefined) {
    tmp.elevation = Caml_option.valFromOption(elevation);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Paper.default, tmp, children);
}

var Jsx2$5 = {
  make: make$9
};

var Paper$1 = {
  Jsx2: Jsx2$5
};

function make$10(className, classes, raised, elevation, children) {
  return RR$BsConsole.wrapJsForReason(Card.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              raised: Js_null_undefined.fromOption(raised),
              elevation: Js_null_undefined.fromOption(elevation)
            }, children);
}

var Jsx2$6 = {
  make: make$10
};

var Card$1 = {
  Jsx2: Jsx2$6
};

function make$11(className, children) {
  return RR$BsConsole.wrapJsForReason(CardContent.default, {
              className: className
            }, children);
}

var Jsx2$7 = {
  make: make$11
};

var CardContent$1 = {
  Jsx2: Jsx2$7
};

function make$12(className, children) {
  return RR$BsConsole.wrapJsForReason(CardActions.default, {
              className: className
            }, children);
}

var Jsx2$8 = {
  make: make$12
};

var CardActions$1 = {
  Jsx2: Jsx2$8
};

function make$13(className, title, subheader, children) {
  return RR$BsConsole.wrapJsForReason(CardHeader.default, {
              className: className,
              title: Js_null_undefined.fromOption(title),
              subheader: Js_null_undefined.fromOption(subheader)
            }, children);
}

var Jsx2$9 = {
  make: make$13
};

var CardHeader$1 = {
  Jsx2: Jsx2$9
};

function make$14(value, classes, onChange, style, scrollable, fullWidth, centered, children) {
  return RR$BsConsole.wrapJsForReason(Tabs.default, {
              value: Js_null_undefined.fromOption(value),
              classes: Js_null_undefined.fromOption(classes),
              onChange: Js_null_undefined.fromOption(onChange),
              style: Js_null_undefined.fromOption(style),
              scrollable: Js_null_undefined.fromOption(scrollable),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              centered: Js_null_undefined.fromOption(centered)
            }, children);
}

var Jsx2$10 = {
  make: make$14
};

var Tabs$1 = {
  Jsx2: Jsx2$10
};

function make$15(classes, style, className, value, label, disableRipple, children) {
  return RR$BsConsole.wrapJsForReason(Tab.default, {
              classes: Js_null_undefined.fromOption(classes),
              style: Js_null_undefined.fromOption(style),
              className: Js_null_undefined.fromOption(className),
              value: Js_null_undefined.fromOption(value),
              label: Js_null_undefined.fromOption(label),
              disableRipple: Js_null_undefined.fromOption(disableRipple)
            }, children);
}

var Jsx2$11 = {
  make: make$15
};

var Tab$1 = {
  Jsx2: Jsx2$11
};

function make$16(classes, style, className, value, color, variant, children) {
  return RR$BsConsole.wrapJsForReason(LinearProgress.default, {
              classes: Js_null_undefined.fromOption(classes),
              style: Js_null_undefined.fromOption(style),
              className: Js_null_undefined.fromOption(className),
              value: Js_null_undefined.fromOption(value),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              variant: Js_null_undefined.fromOption(Belt_Option.map(variant, MuiEnums$BsConsole.linearProgressVariantToJs))
            }, children);
}

var Jsx2$12 = {
  make: make$16
};

var LinearProgress$1 = {
  Jsx2: Jsx2$12
};

function make$17(disableShrink, size, color, classes, className, children) {
  var tmp = { };
  if (disableShrink !== undefined) {
    tmp.disableShrink = Caml_option.valFromOption(disableShrink);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CircularProgress.default, tmp, children);
}

var Jsx2$13 = {
  make: make$17
};

var CircularProgress$1 = {
  Jsx2: Jsx2$13
};

function make$18(classes, className, in_, timeout, children) {
  return RR$BsConsole.wrapJsForReason(Collapse.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              in: Js_null_undefined.fromOption(in_),
              timeout: Js_null_undefined.fromOption(Belt_Option.map(timeout, unwrapJsValue))
            }, children);
}

var Jsx2$14 = {
  make: make$18
};

var Collapse$1 = {
  Jsx2: Jsx2$14
};

var Fade2 = { };

function unwrapOrigin(origin) {
  var unwrapped = { };
  unwrapped["horizontal"] = MuiEnums$BsConsole.popoverHorizontalOriginToJs(origin.horizontal);
  unwrapped["vertical"] = MuiEnums$BsConsole.popoverVerticalOriginToJs(origin.vertical);
  return unwrapped;
}

function make$19(anchorEl, anchorOrigin, transformOrigin, className, open_, onClose, action, classes, children) {
  return RR$BsConsole.wrapJsForReason(Popover.default, {
              anchorEl: Js_null_undefined.fromOption(Belt_Option.map(anchorEl, unwrapJsValue)),
              className: Js_null_undefined.fromOption(className),
              onClose: Js_null_undefined.fromOption(onClose),
              open: Js_null_undefined.fromOption(open_),
              anchorOrigin: Js_null_undefined.fromOption(Belt_Option.map(anchorOrigin, unwrapOrigin)),
              transformOrigin: Js_null_undefined.fromOption(Belt_Option.map(transformOrigin, unwrapOrigin)),
              action: Js_null_undefined.fromOption(action),
              classes: Js_null_undefined.fromOption(classes)
            }, children);
}

var Jsx2$15 = {
  unwrapOrigin: unwrapOrigin,
  make: make$19
};

var Popover$1 = {
  Jsx2: Jsx2$15
};

var TableSortLabel = { };

var Popper = { };

function make$20(className, open_, onClose, classes, maxWidth, fullWidth, fullScreen, disableBackdropClick, disableEscapeKeyDown, children) {
  return RR$BsConsole.wrapJsForReason(Dialog.default, {
              className: Js_null_undefined.fromOption(className),
              open: Js_null_undefined.fromOption(open_),
              onClose: Js_null_undefined.fromOption(onClose),
              classes: Js_null_undefined.fromOption(classes),
              maxWidth: Js_null_undefined.fromOption(maxWidth),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              fullScreen: Js_null_undefined.fromOption(fullScreen),
              disableBackdropClick: Js_null_undefined.fromOption(disableBackdropClick),
              disableEscapeKeyDown: Js_null_undefined.fromOption(disableEscapeKeyDown)
            }, children);
}

var Jsx2$16 = {
  make: make$20
};

var Dialog$1 = {
  Jsx2: Jsx2$16
};

function make$21(className, classes, container, children) {
  return RR$BsConsole.wrapJsForReason(Portal.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              container: Js_null_undefined.fromOption(Belt_Option.map(container, unwrapJsValue))
            }, children);
}

var Jsx2$17 = {
  make: make$21
};

var Portal$1 = {
  Jsx2: Jsx2$17
};

function make$22(className, children) {
  return RR$BsConsole.wrapJsForReason(DialogTitle.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$18 = {
  make: make$22
};

var DialogTitle$1 = {
  Jsx2: Jsx2$18
};

function make$23(className, position, children) {
  return RR$BsConsole.wrapJsForReason(AppBar.default, {
              className: Js_null_undefined.fromOption(className),
              position: Js_null_undefined.fromOption(Belt_Option.map(position, MuiEnums$BsConsole.appBarPositionToJs))
            }, children);
}

var Jsx2$19 = {
  make: make$23
};

var AppBar$1 = {
  Jsx2: Jsx2$19
};

function make$24(className, children) {
  return RR$BsConsole.wrapJsForReason(DialogActions.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$20 = {
  make: make$24
};

var DialogActions$1 = {
  Jsx2: Jsx2$20
};

function make$25(className, classes, children) {
  return RR$BsConsole.wrapJsForReason(DialogContent.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes)
            }, children);
}

var Jsx2$21 = {
  make: make$25
};

var DialogContent$1 = {
  Jsx2: Jsx2$21
};

function make$26(className, children) {
  return RR$BsConsole.wrapJsForReason(DialogContentText.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$22 = {
  make: make$26
};

var DialogContentText$1 = {
  Jsx2: Jsx2$22
};

function make$27(classes, className, error, fullWidth, component, children) {
  return RR$BsConsole.wrapJsForReason(FormControl.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              error: Js_null_undefined.fromOption(error),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              component: Js_null_undefined.fromOption(Belt_Option.map(component, unwrapJsValue))
            }, children);
}

var Jsx2$23 = {
  make: make$27
};

var FormControl$1 = {
  Jsx2: Jsx2$23
};

function make$28(classes, className, style, error, children) {
  return RR$BsConsole.wrapJsForReason(FormHelperText.default, {
              style: Js_null_undefined.fromOption(style),
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              error: Js_null_undefined.fromOption(error)
            }, children);
}

var Jsx2$24 = {
  make: make$28
};

var FormHelperText$1 = {
  Jsx2: Jsx2$24
};

function make$29(value, label, control, classes, className, name, disabled, style, children) {
  return RR$BsConsole.wrapJsForReason(FormControlLabel.default, {
              name: Js_null_undefined.fromOption(name),
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              value: Js_null_undefined.fromOption(value),
              label: Js_null_undefined.fromOption(label),
              control: Js_null_undefined.fromOption(control),
              disabled: Js_null_undefined.fromOption(disabled),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$25 = {
  make: make$29
};

var FormControlLabel$1 = {
  Jsx2: Jsx2$25
};

function make$30(className, checked, name, color, disabled, value, onChange, style, children) {
  return RR$BsConsole.wrapJsForReason(Radio.default, {
              className: Js_null_undefined.fromOption(className),
              checked: Js_null_undefined.fromOption(Belt_Option.map(checked, unwrapJsValue)),
              name: Js_null_undefined.fromOption(name),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              disabled: Js_null_undefined.fromOption(disabled),
              value: Js_null_undefined.fromOption(value),
              onChange: Js_null_undefined.fromOption(onChange),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$26 = {
  make: make$30
};

var Radio$1 = {
  Jsx2: Jsx2$26
};

function make$31(className, checked, name, color, disabled, value, classes, disableRipple, onChange, indeterminate, checkedIcon, indeterminateIcon, icon, style, children) {
  return RR$BsConsole.wrapJsForReason(Checkbox.default, {
              className: Js_null_undefined.fromOption(className),
              checked: Js_null_undefined.fromOption(Belt_Option.map(checked, unwrapJsValue)),
              name: Js_null_undefined.fromOption(name),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              disabled: Js_null_undefined.fromOption(disabled),
              value: Js_null_undefined.fromOption(value),
              classes: Js_null_undefined.fromOption(classes),
              disableRipple: Js_null_undefined.fromOption(disableRipple),
              onChange: Js_null_undefined.fromOption(onChange),
              indeterminate: Js_null_undefined.fromOption(indeterminate),
              checkedIcon: Js_null_undefined.fromOption(checkedIcon),
              indeterminateIcon: Js_null_undefined.fromOption(indeterminateIcon),
              icon: Js_null_undefined.fromOption(icon),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$27 = {
  make: make$31
};

var Checkbox$1 = {
  Jsx2: Jsx2$27
};

function make$32(className, value, onChange, name, children) {
  return RR$BsConsole.wrapJsForReason(RadioGroup.default, {
              className: Js_null_undefined.fromOption(className),
              value: Js_null_undefined.fromOption(value),
              onChange: Js_null_undefined.fromOption(onChange),
              name: Js_null_undefined.fromOption(name)
            }, children);
}

var Jsx2$28 = {
  make: make$32
};

var RadioGroup$1 = {
  Jsx2: Jsx2$28
};

function make$33(classes, className, htmlFor, children) {
  return RR$BsConsole.wrapJsForReason(InputLabel.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              htmlFor: Js_null_undefined.fromOption(htmlFor)
            }, children);
}

var Jsx2$29 = {
  make: make$33
};

var InputLabel$1 = {
  Jsx2: Jsx2$29
};

function make$34(classes, className, value, onChange, placeholder, fullWidth, disableUnderline, autoFocus, type_, startAdornment, endAdornment, disabled, onBlur, onFocus, name, autoComplete, children) {
  return RR$BsConsole.wrapJsForReason(Input.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              value: Js_null_undefined.fromOption(Belt_Option.map(value, unwrapJsValue)),
              onChange: Js_null_undefined.fromOption(onChange),
              placeholder: Js_null_undefined.fromOption(placeholder),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              disableUnderline: Js_null_undefined.fromOption(disableUnderline),
              autoFocus: Js_null_undefined.fromOption(autoFocus),
              type: Js_null_undefined.fromOption(type_),
              startAdornment: Js_null_undefined.fromOption(startAdornment),
              endAdornment: Js_null_undefined.fromOption(endAdornment),
              disabled: Js_null_undefined.fromOption(disabled),
              onBlur: Js_null_undefined.fromOption(onBlur),
              onFocus: Js_null_undefined.fromOption(onFocus),
              name: Js_null_undefined.fromOption(name),
              autoComplete: Js_null_undefined.fromOption(autoComplete)
            }, children);
}

var Jsx2$30 = {
  make: make$34
};

var Input$1 = {
  Jsx2: Jsx2$30
};

function make$35(className, position, children) {
  return RR$BsConsole.wrapJsForReason(InputAdornment.default, {
              className: Js_null_undefined.fromOption(className),
              position: Js_null_undefined.fromOption(position)
            }, children);
}

var Jsx2$31 = {
  make: make$35
};

var InputAdornment$1 = {
  Jsx2: Jsx2$31
};

var jsMapperConstantArray = [
  /* tuple */[
    -904051921,
    "body1"
  ],
  /* tuple */[
    -904051920,
    "body2"
  ],
  /* tuple */[
    -191243578,
    "caption"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    -11760689,
    "display1"
  ],
  /* tuple */[
    -11760688,
    "display2"
  ],
  /* tuple */[
    -11760687,
    "display3"
  ],
  /* tuple */[
    -11760686,
    "display4"
  ],
  /* tuple */[
    16105,
    "h1"
  ],
  /* tuple */[
    16106,
    "h2"
  ],
  /* tuple */[
    16107,
    "h3"
  ],
  /* tuple */[
    16108,
    "h4"
  ],
  /* tuple */[
    16109,
    "h5"
  ],
  /* tuple */[
    16110,
    "h6"
  ],
  /* tuple */[
    131646923,
    "srOnly"
  ],
  /* tuple */[
    148169314,
    "subheading"
  ],
  /* tuple */[
    242538002,
    "button"
  ],
  /* tuple */[
    386038009,
    "subtitle1"
  ],
  /* tuple */[
    386038010,
    "subtitle2"
  ],
  /* tuple */[
    518168424,
    "overline"
  ],
  /* tuple */[
    579538228,
    "headline"
  ],
  /* tuple */[
    594052472,
    "title"
  ]
];

function variantToJs(param) {
  return Js_mapperRt.binarySearch(22, param, jsMapperConstantArray);
}

function variantFromJs(param) {
  return Js_mapperRt.revSearch(22, jsMapperConstantArray, param);
}

var jsMapperConstantArray$1 = [
  /* tuple */[
    -788068560,
    "justify"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    -57574468,
    "right"
  ],
  /* tuple */[
    847852583,
    "left"
  ],
  /* tuple */[
    980392437,
    "center"
  ]
];

function alignToJs(param) {
  return Js_mapperRt.binarySearch(5, param, jsMapperConstantArray$1);
}

function alignFromJs(param) {
  return Js_mapperRt.revSearch(5, jsMapperConstantArray$1, param);
}

var jsMapperConstantArray$2 = [
  /* tuple */[
    -1020341643,
    "textPrimary"
  ],
  /* tuple */[
    -791844958,
    "primary"
  ],
  /* tuple */[
    -507693849,
    "textSecondary"
  ],
  /* tuple */[
    -384499551,
    "default"
  ],
  /* tuple */[
    -72987685,
    "inherit"
  ],
  /* tuple */[
    67972948,
    "secondary"
  ],
  /* tuple */[
    106380200,
    "error"
  ]
];

function colorToJs(param) {
  return Js_mapperRt.binarySearch(7, param, jsMapperConstantArray$2);
}

function colorFromJs(param) {
  return Js_mapperRt.revSearch(7, jsMapperConstantArray$2, param);
}

function make$36(className, variant, align, color, classes, style, children) {
  return ReasonReact.wrapJsForReason(Typography.default, {
              className: Js_null_undefined.fromOption(className),
              variant: Js_null_undefined.fromOption(Belt_Option.map(variant, (function (v) {
                          return Js_mapperRt.binarySearch(22, v, jsMapperConstantArray);
                        }))),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, (function (v) {
                          return Js_mapperRt.binarySearch(7, v, jsMapperConstantArray$2);
                        }))),
              align: Js_null_undefined.fromOption(Belt_Option.map(align, (function (v) {
                          return Js_mapperRt.binarySearch(5, v, jsMapperConstantArray$1);
                        }))),
              classes: Js_null_undefined.fromOption(classes),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$32 = {
  variantToJs: variantToJs,
  variantFromJs: variantFromJs,
  alignToJs: alignToJs,
  alignFromJs: alignFromJs,
  colorToJs: colorToJs,
  colorFromJs: colorFromJs,
  make: make$36
};

var Typography$1 = {
  Jsx2: Jsx2$32
};

function make$37(open_, renderValue, onClose, onOpen, autoWidth, classes, value, onChange, disableUnderline, variant, className, fullWidth, placeholder, disabled, style, children) {
  return RR$BsConsole.wrapJsForReason(Select.default, {
              open: Js_null_undefined.fromOption(open_),
              renderValue: Js_null_undefined.fromOption(renderValue),
              onClose: Js_null_undefined.fromOption(onClose),
              onOpen: Js_null_undefined.fromOption(onOpen),
              autoWidth: Js_null_undefined.fromOption(autoWidth),
              classes: Js_null_undefined.fromOption(classes),
              value: Js_null_undefined.fromOption(Belt_Option.map(value, unwrapJsValue)),
              onChange: Js_null_undefined.fromOption(onChange),
              disableUnderline: Js_null_undefined.fromOption(disableUnderline),
              variant: Js_null_undefined.fromOption(Belt_Option.map(variant, MuiEnums$BsConsole.selectVariantToJs)),
              className: Js_null_undefined.fromOption(className),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              placeholder: Js_null_undefined.fromOption(placeholder),
              disabled: Js_null_undefined.fromOption(disabled),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$33 = {
  make: make$37
};

var Select$1 = {
  Jsx2: Jsx2$33
};

function unwrapOrigin$1(origin) {
  var unwrapped = { };
  unwrapped["horizontal"] = MuiEnums$BsConsole.popoverHorizontalOriginToJs(origin.horizontal);
  unwrapped["vertical"] = MuiEnums$BsConsole.popoverVerticalOriginToJs(origin.vertical);
  return unwrapped;
}

function make$38(open_, anchorEl, anchorOrigin, transformOrigin, onClose, children) {
  return RR$BsConsole.wrapJsForReason(Menu.default, {
              open: Js_null_undefined.fromOption(open_),
              anchorEl: Js_null_undefined.fromOption(anchorEl),
              onClose: Js_null_undefined.fromOption(onClose),
              anchorOrigin: Js_null_undefined.fromOption(Belt_Option.map(anchorOrigin, unwrapOrigin$1)),
              transformOrigin: Js_null_undefined.fromOption(Belt_Option.map(transformOrigin, unwrapOrigin$1))
            }, children);
}

var Jsx2$34 = {
  unwrapOrigin: unwrapOrigin$1,
  make: make$38
};

var Menu$1 = {
  Jsx2: Jsx2$34
};

var ListSubheader = { };

function make$39(className, classes, placeholder, fullWidth, onChange, onFocus, onBlur, variant, error, helperText, type_, value, color, autoFocus, label, _FormHelperTextProps, _InputProps, disabled, autoComplete, id, name, inputRef, inputProps, multiline, rows, children) {
  return RR$BsConsole.wrapJsForReason(TextField.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              placeholder: Js_null_undefined.fromOption(placeholder),
              fullWidth: Js_null_undefined.fromOption(fullWidth),
              onChange: Js_null_undefined.fromOption(onChange),
              onFocus: Js_null_undefined.fromOption(onFocus),
              onBlur: Js_null_undefined.fromOption(onBlur),
              error: Js_null_undefined.fromOption(error),
              helperText: Js_null_undefined.fromOption(helperText),
              type: Js_null_undefined.fromOption(type_),
              value: Js_null_undefined.fromOption(Belt_Option.map(value, unwrapJsValue)),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs)),
              variant: Js_null_undefined.fromOption(Belt_Option.map(variant, MuiEnums$BsConsole.textFieldVariantToJs)),
              autoFocus: Js_null_undefined.fromOption(autoFocus),
              label: Js_null_undefined.fromOption(label),
              FormHelperTextProps: Js_null_undefined.fromOption(_FormHelperTextProps),
              InputProps: Js_null_undefined.fromOption(_InputProps),
              disabled: Js_null_undefined.fromOption(disabled),
              autoComplete: Js_null_undefined.fromOption(autoComplete),
              id: Js_null_undefined.fromOption(id),
              name: Js_null_undefined.fromOption(name),
              inputRef: Js_null_undefined.fromOption(inputRef),
              inputProps: Js_null_undefined.fromOption(inputProps),
              multiline: Js_null_undefined.fromOption(multiline),
              rows: Js_null_undefined.fromOption(Belt_Option.map(rows, unwrapJsValue))
            }, children);
}

var Jsx2$35 = {
  make: make$39
};

var TextField$1 = {
  Jsx2: Jsx2$35
};

function make$40(className, children) {
  return RR$BsConsole.wrapJsForReason(MenuList.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$36 = {
  make: make$40
};

var MenuList$1 = {
  Jsx2: Jsx2$36
};

function make$41(className, classes, label, icon, deleteIcon, onDelete, onClick, color, children) {
  return RR$BsConsole.wrapJsForReason(Chip.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              label: Js_null_undefined.fromOption(label),
              icon: Js_null_undefined.fromOption(icon),
              deleteIcon: Js_null_undefined.fromOption(deleteIcon),
              onDelete: Js_null_undefined.fromOption(onDelete),
              onClick: Js_null_undefined.fromOption(onClick),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs))
            }, children);
}

var Jsx2$37 = {
  make: make$41
};

var Chip$1 = {
  Jsx2: Jsx2$37
};

function unwrapJsValue$1(value) {
  return value[1];
}

function make$42(className, onClick, value, disabled, selected, children) {
  return RR$BsConsole.wrapJsForReason(MenuItem.default, {
              className: Js_null_undefined.fromOption(className),
              onClick: Js_null_undefined.fromOption(onClick),
              value: Js_null_undefined.fromOption(Belt_Option.map(value, (function (v) {
                          return v[1];
                        }))),
              disabled: Js_null_undefined.fromOption(disabled),
              selected: Js_null_undefined.fromOption(selected)
            }, children);
}

var Jsx2$38 = {
  make: make$42
};

var MenuItem$1 = {
  unwrapJsValue: unwrapJsValue$1,
  Jsx2: Jsx2$38
};

function make$43(className, orientation, children) {
  return RR$BsConsole.wrapJsForReason(Divider.default, {
              className: Js_null_undefined.fromOption(className),
              orientation: Js_null_undefined.fromOption(orientation)
            }, children);
}

var Jsx2$39 = {
  make: make$43
};

var Divider$1 = {
  Jsx2: Jsx2$39
};

var FilledInput = { };

var OutlinedInput = { };

function make$44(classes, className, children) {
  return RR$BsConsole.wrapJsForReason(Avatar.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$40 = {
  make: make$44
};

var Avatar$1 = {
  Jsx2: Jsx2$40
};

function make$45(classes, className, badgeContent, color, children) {
  return RR$BsConsole.wrapJsForReason(Badge.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              badgeContent: Js_null_undefined.fromOption(badgeContent),
              color: Js_null_undefined.fromOption(Belt_Option.map(color, MuiEnums$BsConsole.colorToJs))
            }, children);
}

var Jsx2$41 = {
  make: make$45
};

var Badge$1 = {
  Jsx2: Jsx2$41
};

function make$46(classes, className, variant, open_, anchor, onClose, children) {
  return RR$BsConsole.wrapJsForReason(Drawer.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              variant: Js_null_undefined.fromOption(Belt_Option.map(variant, MuiEnums$BsConsole.drawerVariantToJs)),
              open: Js_null_undefined.fromOption(open_),
              anchor: Js_null_undefined.fromOption(Belt_Option.map(anchor, MuiEnums$BsConsole.drawerAnchorToJs)),
              onClose: Js_null_undefined.fromOption(onClose)
            }, children);
}

var Jsx2$42 = {
  make: make$46
};

var Drawer$1 = {
  Jsx2: Jsx2$42
};

function make$47(classes, className, children) {
  return RR$BsConsole.wrapJsForReason(List.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$43 = {
  make: make$47
};

var List$1 = {
  Jsx2: Jsx2$43
};

function make$48(classes, className, button, onClick, selected, children) {
  return RR$BsConsole.wrapJsForReason(ListItem.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              button: Js_null_undefined.fromOption(button),
              onClick: Js_null_undefined.fromOption(onClick),
              selected: Js_null_undefined.fromOption(selected)
            }, children);
}

var Jsx2$44 = {
  make: make$48
};

var ListItem$1 = {
  Jsx2: Jsx2$44
};

function make$49(classes, className, children) {
  return RR$BsConsole.wrapJsForReason(ListItemIcon.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$45 = {
  make: make$49
};

var ListItemIcon$1 = {
  Jsx2: Jsx2$45
};

function make$50(classes, className, primary, secondary, children) {
  return RR$BsConsole.wrapJsForReason(ListItemText.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              primary: Js_null_undefined.fromOption(primary),
              secondary: Js_null_undefined.fromOption(secondary)
            }, children);
}

var Jsx2$46 = {
  make: make$50
};

var ListItemText$1 = {
  Jsx2: Jsx2$46
};

function make$51(classes, className, disableGutters, children) {
  return RR$BsConsole.wrapJsForReason(Toolbar.default, {
              classes: Js_null_undefined.fromOption(classes),
              className: Js_null_undefined.fromOption(className),
              disableGutters: Js_null_undefined.fromOption(disableGutters)
            }, children);
}

var Jsx2$47 = {
  make: make$51
};

var Toolbar$1 = {
  Jsx2: Jsx2$47
};

function make$52(className, children) {
  return RR$BsConsole.wrapJsForReason(FormLabel.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$48 = {
  make: make$52
};

var FormLabel$1 = {
  Jsx2: Jsx2$48
};

function make$53(className, children) {
  return RR$BsConsole.wrapJsForReason(FormGroup.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$49 = {
  make: make$53
};

var FormGroup$1 = {
  Jsx2: Jsx2$49
};

function make$54(className, expanded, onChange, children) {
  return RR$BsConsole.wrapJsForReason(ExpansionPanel.default, {
              className: Js_null_undefined.fromOption(className),
              expanded: Js_null_undefined.fromOption(expanded),
              onChange: Js_null_undefined.fromOption(onChange)
            }, children);
}

var Jsx2$50 = {
  make: make$54
};

var ExpansionPanel$1 = {
  Jsx2: Jsx2$50
};

function make$55(className, expandIcon, children) {
  return RR$BsConsole.wrapJsForReason(ExpansionPanelSummary.default, {
              className: Js_null_undefined.fromOption(className),
              expandIcon: Js_null_undefined.fromOption(expandIcon)
            }, children);
}

var Jsx2$51 = {
  make: make$55
};

var ExpansionPanelSummary$1 = {
  Jsx2: Jsx2$51
};

function make$56(className, children) {
  return RR$BsConsole.wrapJsForReason(ExpansionPanelDetails.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$52 = {
  make: make$56
};

var ExpansionPanelDetails$1 = {
  Jsx2: Jsx2$52
};

function make$57(className, style, children) {
  return RR$BsConsole.wrapJsForReason(Table.default, {
              className: Js_null_undefined.fromOption(className),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$53 = {
  make: make$57
};

var Table$1 = {
  Jsx2: Jsx2$53
};

function make$58(className, children) {
  return RR$BsConsole.wrapJsForReason(TableHead.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$54 = {
  make: make$58
};

var TableHead$1 = {
  Jsx2: Jsx2$54
};

function make$59(className, children) {
  return RR$BsConsole.wrapJsForReason(TableFooter.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$55 = {
  make: make$59
};

var TableFooter$1 = {
  Jsx2: Jsx2$55
};

function make$60(className, children) {
  return RR$BsConsole.wrapJsForReason(TableRow.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$56 = {
  make: make$60
};

var TableRow$1 = {
  Jsx2: Jsx2$56
};

function make$61(className, children) {
  return RR$BsConsole.wrapJsForReason(TableBody.default, {
              className: Js_null_undefined.fromOption(className)
            }, children);
}

var Jsx2$57 = {
  make: make$61
};

var TableBody$1 = {
  Jsx2: Jsx2$57
};

function make$62(className, classes, colSpan, padding, align, variant, children) {
  return RR$BsConsole.wrapJsForReason(TableCell.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              colSpan: Js_null_undefined.fromOption(colSpan),
              padding: Js_null_undefined.fromOption(padding),
              align: Js_null_undefined.fromOption(align),
              variant: Js_null_undefined.fromOption(variant)
            }, children);
}

var Jsx2$58 = {
  make: make$62
};

var TableCell$1 = {
  Jsx2: Jsx2$58
};

function make$63(className, classes, onChangePage, style, onChangeRowsPerPage, page, rowsPerPageOptions, count, rowsPerPage, colSpan, labelDisplayedRows, labelRowsPerPage, children) {
  return RR$BsConsole.wrapJsForReason(TablePagination.default, {
              onChangePage: onChangePage,
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              style: Js_null_undefined.fromOption(style),
              labelRowsPerPage: Js_null_undefined.fromOption(labelRowsPerPage),
              labelDisplayedRows: Js_null_undefined.fromOption(labelDisplayedRows),
              onChangeRowsPerPage: Js_null_undefined.fromOption(onChangeRowsPerPage),
              page: page[1],
              count: count[1],
              rowsPerPageOptions: Js_null_undefined.fromOption(rowsPerPageOptions),
              colSpan: Js_null_undefined.fromOption(Belt_Option.map(colSpan, unwrapJsValue)),
              rowsPerPage: Js_null_undefined.fromOption(Belt_Option.map(rowsPerPage, unwrapJsValue))
            }, children);
}

var Jsx2$59 = {
  make: make$63
};

var TablePagination$1 = {
  Jsx2: Jsx2$59
};

function make$64(className, classes, completed, active, disabled, children) {
  return RR$BsConsole.wrapJsForReason(Step.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              completed: Js_null_undefined.fromOption(completed),
              active: Js_null_undefined.fromOption(active),
              disabled: Js_null_undefined.fromOption(disabled)
            }, children);
}

var Jsx2$60 = {
  make: make$64
};

var Step$1 = {
  Jsx2: Jsx2$60
};

function make$65(className, classes, connector, activeStep, nonLinear, alternativeLabel, style, children) {
  return RR$BsConsole.wrapJsForReason(Stepper.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              connector: Js_null_undefined.fromOption(connector),
              activeStep: Js_null_undefined.fromOption(Belt_Option.map(activeStep, unwrapJsValue)),
              nonLinear: Js_null_undefined.fromOption(nonLinear),
              alternativeLabel: Js_null_undefined.fromOption(alternativeLabel),
              style: Js_null_undefined.fromOption(style)
            }, children);
}

var Jsx2$61 = {
  make: make$65
};

var Stepper$1 = {
  Jsx2: Jsx2$61
};

function make$66(className, classes, children) {
  return RR$BsConsole.wrapJsForReason(StepLabel.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes)
            }, children);
}

var Jsx2$62 = {
  make: make$66
};

var StepLabel$1 = {
  Jsx2: Jsx2$62
};

function make$67(className, classes, onClick, disabled, children) {
  return RR$BsConsole.wrapJsForReason(StepButton.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              onClick: Js_null_undefined.fromOption(onClick),
              boolean: Js_null_undefined.fromOption(disabled)
            }, children);
}

var Jsx2$63 = {
  make: make$67
};

var StepButton$1 = {
  Jsx2: Jsx2$63
};

function unwrapOrigin$2(origin) {
  var unwrapped = { };
  unwrapped["horizontal"] = MuiEnums$BsConsole.popoverHorizontalOriginToJs(origin.horizontal);
  unwrapped["vertical"] = MuiEnums$BsConsole.popoverVerticalOriginToJs(origin.vertical);
  return unwrapped;
}

function make$68(className, classes, anchorOrigin, open_, onClose, onExited, autoHideDuration, children) {
  return RR$BsConsole.wrapJsForReason(Snackbar.default, {
              className: Js_null_undefined.fromOption(className),
              classes: Js_null_undefined.fromOption(classes),
              anchorOrigin: Js_null_undefined.fromOption(Belt_Option.map(anchorOrigin, unwrapOrigin$2)),
              open: Js_null_undefined.fromOption(open_),
              onClose: Js_null_undefined.fromOption(onClose),
              onExited: Js_null_undefined.fromOption(onExited),
              autoHideDuration: Js_null_undefined.fromOption(Belt_Option.map(autoHideDuration, unwrapJsValue))
            }, children);
}

var Jsx2$64 = {
  make: make$68
};

var Snackbar$1 = {
  unwrapOrigin: unwrapOrigin$2,
  Jsx2: Jsx2$64
};

function make$69(onClickAway, children) {
  return RR$BsConsole.wrapJsForReason(ClickAwayListener.default, {
              onClickAway: onClickAway
            }, children);
}

var Jsx2$65 = {
  make: make$69
};

var ClickAwayListener$1 = {
  Jsx2: Jsx2$65
};

var SvgIcon = { };

exports.GenericValue = GenericValue;
exports.MuiThemeProvider = MuiThemeProvider;
exports.CssBaseline = CssBaseline;
exports.Grid = Grid$1;
exports.Fade = Fade$1;
exports.ThemeProvider = ThemeProvider;
exports.Tooltip = Tooltip$1;
exports.Icon = Icon;
exports.BaseIconButton = BaseIconButton;
exports.IconButton = IconButton$1;
exports.IconButtonWithTooltipJsx3 = IconButtonWithTooltipJsx3;
exports.Switch = Switch$1;
exports.Button = Button$1;
exports.ButtonBase = ButtonBase$1;
exports.Paper = Paper$1;
exports.Card = Card$1;
exports.CardContent = CardContent$1;
exports.CardActions = CardActions$1;
exports.CardHeader = CardHeader$1;
exports.Tabs = Tabs$1;
exports.Tab = Tab$1;
exports.LinearProgress = LinearProgress$1;
exports.CircularProgress = CircularProgress$1;
exports.Collapse = Collapse$1;
exports.Fade2 = Fade2;
exports.Popover = Popover$1;
exports.TableSortLabel = TableSortLabel;
exports.Popper = Popper;
exports.Dialog = Dialog$1;
exports.Portal = Portal$1;
exports.DialogTitle = DialogTitle$1;
exports.AppBar = AppBar$1;
exports.DialogActions = DialogActions$1;
exports.DialogContent = DialogContent$1;
exports.DialogContentText = DialogContentText$1;
exports.FormControl = FormControl$1;
exports.FormHelperText = FormHelperText$1;
exports.FormControlLabel = FormControlLabel$1;
exports.Radio = Radio$1;
exports.Checkbox = Checkbox$1;
exports.RadioGroup = RadioGroup$1;
exports.InputLabel = InputLabel$1;
exports.Input = Input$1;
exports.InputAdornment = InputAdornment$1;
exports.Typography = Typography$1;
exports.Select = Select$1;
exports.Menu = Menu$1;
exports.ListSubheader = ListSubheader;
exports.TextField = TextField$1;
exports.MenuList = MenuList$1;
exports.Chip = Chip$1;
exports.MenuItem = MenuItem$1;
exports.Divider = Divider$1;
exports.FilledInput = FilledInput;
exports.OutlinedInput = OutlinedInput;
exports.Avatar = Avatar$1;
exports.Badge = Badge$1;
exports.Drawer = Drawer$1;
exports.List = List$1;
exports.ListItem = ListItem$1;
exports.ListItemIcon = ListItemIcon$1;
exports.ListItemText = ListItemText$1;
exports.Toolbar = Toolbar$1;
exports.FormLabel = FormLabel$1;
exports.FormGroup = FormGroup$1;
exports.ExpansionPanel = ExpansionPanel$1;
exports.ExpansionPanelSummary = ExpansionPanelSummary$1;
exports.ExpansionPanelDetails = ExpansionPanelDetails$1;
exports.Table = Table$1;
exports.TableHead = TableHead$1;
exports.TableFooter = TableFooter$1;
exports.TableRow = TableRow$1;
exports.TableBody = TableBody$1;
exports.TableCell = TableCell$1;
exports.TablePagination = TablePagination$1;
exports.Step = Step$1;
exports.Stepper = Stepper$1;
exports.StepLabel = StepLabel$1;
exports.StepButton = StepButton$1;
exports.Snackbar = Snackbar$1;
exports.ClickAwayListener = ClickAwayListener$1;
exports.SvgIcon = SvgIcon;
/* react Not a pure module */
