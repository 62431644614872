// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

var featureTitle = Css.style(/* [] */0);

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* [] */0
    ]);

var subFeatureTitle = Css.style(/* [] */0);

var marginBottom = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var Style = {
  merge: Css.merge,
  featureTitle: featureTitle,
  featureDescription: featureDescription,
  subFeatureTitle: subFeatureTitle,
  marginBottom: marginBottom
};

function PS_Playground$SubFeature01$Create(Props) {
  Props.token;
  Props.onSuccess;
  Props.onFailure;
  var disabled = Props.disabled;
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: disabled,
                  classes: {
                    root: Css.merge(/* :: */[
                          subFeatureTitle,
                          /* :: */[
                            marginBottom,
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (_e) {
                      
                    }),
                  children: I18N$BsConsole.show(undefined, "Add a [FEATURE-01]")
                }));
}

var Create = {
  make: PS_Playground$SubFeature01$Create
};

var SubFeature01 = {
  Create: Create
};

function PS_Playground$SubFeature02$Create(Props) {
  Props.token;
  Props.onSuccess;
  Props.onFailure;
  var disabled = Props.disabled;
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: disabled,
                  classes: {
                    root: Css.merge(/* :: */[
                          subFeatureTitle,
                          /* :: */[
                            marginBottom,
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (_e) {
                      
                    }),
                  children: I18N$BsConsole.show(undefined, "Add a [FEATURE-02]")
                }));
}

var Create$1 = {
  make: PS_Playground$SubFeature02$Create
};

var SubFeature02 = {
  Create: Create$1
};

function PS_Playground$Main(Props) {
  Props.config;
  var token = Props.token;
  Props.project;
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[FEATURE-DESCRIPTION].")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.merge(/* :: */[
                        subFeatureTitle,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[SUB-FEATURE-01-NAME]")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[SUB-FEATURE-01-DESCRIPTION]")
                }), React.createElement(PS_Playground$SubFeature01$Create, {
                  token: token,
                  onSuccess: (function (param) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success creating [FEATURE-01]"));
                    }),
                  onFailure: (function (msg) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "Error: ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "Error: %s"
                                        ]), msg));
                    }),
                  disabled: false
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.merge(/* :: */[
                        subFeatureTitle,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[SUB-FEATURE-02-NAME]")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[SUB-FEATURE-02-DESCRIPTION]")
                }), React.createElement(PS_Playground$SubFeature01$Create, {
                  token: token,
                  onSuccess: (function (param) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success creating [FEATURE-02]"));
                    }),
                  onFailure: (function (msg) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "Error: ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "Error: %s"
                                        ]), msg));
                    }),
                  disabled: false
                }));
}

var Main = {
  make: PS_Playground$Main
};

function PS_Playground(Props) {
  var config = Props.config;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.merge(/* :: */[
                        featureTitle,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "[FEATURE-TITLE]")
                }), project !== undefined ? React.createElement(PS_Playground$Main, {
                    config: config,
                    token: token,
                    project: project
                  }) : React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.merge(/* :: */[
                          subFeatureTitle,
                          /* :: */[
                            marginBottom,
                            /* [] */0
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "Project not found")
                  }));
}

function make(config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_Playground, {
              config: config
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_Playground;

exports.Style = Style;
exports.SubFeature01 = SubFeature01;
exports.SubFeature02 = SubFeature02;
exports.Main = Main;
exports.make = make$1;
exports.Re = Re;
/* featureTitle Not a pure module */
