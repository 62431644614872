// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var SymbolTable$BsConsole = require("./SymbolTable.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");
var MissingSymbolsCallToAction$BsConsole = require("./MissingSymbolsCallToAction.js");

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.em(1)),
        /* [] */0
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.padding2(Css.em(3), /* zero */-789508312),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  titleContainer: titleContainer,
  title: title,
  loadingContainer: loadingContainer
};

var component = RR$BsConsole.statelessComponent("PS_SymbolSearch-BsConsole");

function make(handleTask, token, handleChangeUrl, name, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, MissingSymbolsCallToAction$BsConsole.Banner.make(handleTask, token, handleChangeUrl, name, undefined, [])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Search symbols")])),
                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "This page allows you to search symbols in a selected tag. Symbols are organized in tags to allow for better organization and management of large sets of symbols.")]))]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.make(handleTask, token, name, undefined, undefined, (function (remote, _update) {
                                                    return ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Tags.make(handleTask, token, name, (function (remote_, _update) {
                                                                      var exit = 0;
                                                                      if (typeof remote === "number") {
                                                                        if (remote === /* NotAsked */0) {
                                                                          exit = 2;
                                                                        }
                                                                        
                                                                      } else if (remote.tag) {
                                                                        exit = 2;
                                                                      } else {
                                                                        if (typeof remote_ !== "number") {
                                                                          if (remote_.tag) {
                                                                            return null;
                                                                          } else {
                                                                            return ReasonReact.element(undefined, undefined, Curry._3(SymbolTable$BsConsole.Uploaded.Re.make, remote[0], remote_[0], []));
                                                                          }
                                                                        }
                                                                        if (remote_ === /* NotAsked */0) {
                                                                          return null;
                                                                        }
                                                                        exit = 2;
                                                                      }
                                                                      if (exit === 2) {
                                                                        if (typeof remote_ !== "number") {
                                                                          return null;
                                                                        }
                                                                        if (remote_ === 0) {
                                                                          return null;
                                                                        }
                                                                        
                                                                      }
                                                                      return React.createElement("div", {
                                                                                  className: loadingContainer
                                                                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, [])));
                                                                    })));
                                                  })))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* titleContainer Not a pure module */
