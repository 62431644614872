// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Util$BsConsole = require("./util.js");
var Version$BsConsole = require("./Version.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var token = "fee924adf31ed48e7a475c2edea81fc01804ed52823cc482965a5dcf3c22c038";

function sendSession(id) {
  (Curry._3((function post(token, version, id) {
          const xmlHttpRequest = new XMLHttpRequest();
          xmlHttpRequest.open("POST", `https://events-test.backtrace.io/api/summed-events/submit?universe=backtrace&token=${token}`, true);
          xmlHttpRequest.send(
            JSON.stringify({
              application: "bs-frontend-pro",
              appversion: version,
              "summed_events":[
                  {
                    "timestamp":Date.now()/1000,
                    "attributes": {
                      "session_id":id,
                      "version": version
                    },
                    "metric_group":"summed-sessions"
                  }
                ]
            })
          );
        }), token, Version$BsConsole.version, id));
  
}

function sendUnique(guid) {
  (Curry._3((function post(token, version, guid) {
          const xmlHttpRequest = new XMLHttpRequest();
          xmlHttpRequest.open("POST", `https://events-test.backtrace.io/api/unique-events/submit?universe=backtrace&token=${token}`, true);
          xmlHttpRequest.send(
            JSON.stringify({
              application: "bs-frontend-pro",
              appversion: version,
              "unique_events": [
                {
                  "timestamp":Date.now()/1000,
                  "attributes": {
                    "guid": guid,
                    "version": version
                  },
                  "unique": ["guid"]
                }
              ]
            })
          );
        }), token, Version$BsConsole.version, guid));
  
}

function tick(param) {
  var now = Date.now() / 1000;
  var nowStr = Pervasives.string_of_float(now);
  var uniqueStart = localStorage.getItem("Backtrace-Guid-Timestamp");
  var uniqueGuid = localStorage.getItem("Backtrace-Agg-Guid");
  var lastBTNav = localStorage.getItem("Backtrace-Last-Navigation");
  localStorage.setItem("Backtrace-Last-Navigation", nowStr);
  var isNewSession = Belt_Option.getWithDefault(Belt_Option.map(lastBTNav === null ? undefined : Caml_option.some(lastBTNav), (function (lastBTNav) {
              return now - Caml_format.caml_float_of_string(lastBTNav) > 1800;
            })), true);
  if (!isNewSession) {
    if (lastBTNav !== null && uniqueStart !== null && uniqueGuid !== null && Caml_format.caml_float_of_string(lastBTNav) > Caml_format.caml_float_of_string(uniqueStart) + 1800) {
      sendUnique(uniqueGuid);
      localStorage.setItem("Backtrace-Guid-Timestamp", nowStr);
      return ;
    } else {
      return ;
    }
  }
  var uuid = Util$BsConsole.generateUuid(undefined);
  sendSession(uuid);
  sendUnique(uuid);
  Backtrace$BsConsole.Client.memorize("application.session", uuid);
  localStorage.setItem("Backtrace-Agg-Guid", uuid);
  localStorage.setItem("Backtrace-Guid-Timestamp", nowStr);
  
}

var thirtyMinutesInSeconds = 1800;

exports.token = token;
exports.thirtyMinutesInSeconds = thirtyMinutesInSeconds;
exports.sendSession = sendSession;
exports.sendUnique = sendUnique;
exports.tick = tick;
/* Util-BsConsole Not a pure module */
