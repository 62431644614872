// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var SubUserLimit$BsConsole = require("./SubUserLimit.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var SettingsUsersNew$BsConsole = require("../settingsUsersNew.js");
var BillingLimitsFetch$BsConsole = require("../BillingLimitsFetch.js");
var NemesisPlanSelection$BsConsole = require("./NemesisPlanSelection.js");
var NemesisSubscriptions$BsConsole = require("./NemesisSubscriptions.js");
var SubFeatureIncrementer$BsConsole = require("./SubFeatureIncrementer.js");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");

function SubInviteNewUser$InviteUserHeader(Props) {
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement(BtSettings$BsConsole.Container.make, {
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.px(0)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading4 */3,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(3)),
                        /* :: */[
                          Css.fontSize(Css.px(18)),
                          /* [] */0
                        ]
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "Invite a new user")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle1 */6,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(10)),
                        /* :: */[
                          Css.marginBottom(Css.px(10)),
                          /* :: */[
                            Css.fontSize(Css.px(12)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "Send an invitation to a user to create an account in your Backtrace instance.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(Button.default, {
                  variant: "outlined",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginTop(Css.px(10)),
                          /* :: */[
                            Css.marginBottom(Css.px(10)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, /* SettingsUsers */6);
                    }),
                  children: null
                }, React.createElement(KeyboardArrowLeft.default, { }), I18N$BsConsole.show(undefined, "Return to user settings")));
}

var InviteUserHeader = {
  make: SubInviteNewUser$InviteUserHeader
};

function SubInviteNewUser$InviteNewUser(Props) {
  var config = Props.config;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var noMoreUsersPermitted = Props.noMoreUsersPermitted;
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(SubInviteNewUser$InviteUserHeader, {
                  handleChangeUrl: handleChangeUrl
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* baseline */287825029,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* :: */[
                            Css.marginLeft(Css.auto),
                            /* :: */[
                              Css.marginRight(Css.auto),
                              /* :: */[
                                Css.marginBottom(Css.px(80)),
                                /* :: */[
                                  Css.minWidth(Css.px(550)),
                                  /* :: */[
                                    Css.maxWidth(Css.px(1100)),
                                    /* :: */[
                                      Css.width(Css.pct(90)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(580)),
                              /* [] */0
                            ])
                      }, React.createElement(SettingsUsersNew$BsConsole.Jsx3.make, {
                            config: config,
                            token: token,
                            handleChangeUrl: handleChangeUrl,
                            noMoreUsersPermitted: noMoreUsersPermitted
                          }))
                }));
}

var InviteNewUser = {
  make: SubInviteNewUser$InviteNewUser
};

function SubInviteNewUser$InviteNewUserWithNemesis(Props) {
  var config = Props.config;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var nemesis = Props.nemesis;
  var currentUserCount = Props.currentUserCount;
  var sub = nemesis[0];
  var noMoreUsersPermitted = SubUtil$BsConsole.getNoMoreUsersPermitted(limits, currentUserCount);
  var invoiceMe = SubUtil$BsConsole.getInvoiceMe(limits);
  React.useState((function () {
          var trial = limits.metadata.nemesisTrial;
          if (trial !== undefined) {
            return trial.frozenUids;
          }
          
        }));
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(SubInviteNewUser$InviteUserHeader, {
                  handleChangeUrl: handleChangeUrl
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* baseline */287825029,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* :: */[
                            Css.marginLeft(Css.auto),
                            /* :: */[
                              Css.marginRight(Css.auto),
                              /* :: */[
                                Css.marginBottom(Css.px(80)),
                                /* :: */[
                                  Css.minWidth(Css.px(550)),
                                  /* :: */[
                                    Css.maxWidth(Css.px(1100)),
                                    /* :: */[
                                      Css.width(Css.pct(90)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(SettingsUsersNew$BsConsole.Jsx3.make, {
                      config: config,
                      token: token,
                      handleChangeUrl: handleChangeUrl,
                      noMoreUsersPermitted: noMoreUsersPermitted
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.height(Css.px(200)),
                            /* :: */[
                              Css.width(Css.px(400)),
                              /* :: */[
                                Css.marginLeft(Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, React.createElement(SubUserLimit$BsConsole.make, {
                          config: config,
                          token: token,
                          limits: limits,
                          handleChangeUrl: handleChangeUrl,
                          extension: React.createElement(SubFeatureIncrementer$BsConsole.make, {
                                token: token,
                                config: config,
                                limits: limits,
                                refetchLimits: refetchLimits,
                                plan: nemesis[1],
                                plans: nemesis[2],
                                planQuantity: sub.planQuantity,
                                subscription: sub,
                                addons: sub.addons,
                                featureName: "users",
                                updateSubscriptions: nemesis[3],
                                formatter: /* NoDecimal */2,
                                invoiceMe: invoiceMe,
                                subscriptionsPage: false
                              })
                        }))));
}

var InviteNewUserWithNemesis = {
  make: SubInviteNewUser$InviteNewUserWithNemesis
};

function SubInviteNewUser(Props) {
  var config = Props.config;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = BillingLimitsFetch$BsConsole.use(config.universe.name, token);
  var limitsRemote = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setOnNemesis = match$1[1];
  var onNemesis = match$1[0];
  var currentUserCount = ApiBillingLimits$BsConsole.getCurrentUserCount(undefined);
  var match$2 = React.useState((function () {
          
        }));
  var setBasePlans = match$2[1];
  var basePlans = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setSubscription = match$3[1];
  var subscription = match$3[0];
  var match$4 = React.useState((function () {
          return 0;
        }));
  var refetchSubscription = match$4[0];
  var updateSubscriptions = function (planId, planQuantity, addons) {
    var arg = NemesisSubscriptions$BsConsole.update(planId, planQuantity, addons);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Error updating subscription: ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ]),
                                        "Error updating subscription: %s"
                                      ]), Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var subscriptionResult = Belt_Array.get(resp[0], 0);
                  return Curry._1(setSubscription, (function (param) {
                                return subscriptionResult;
                              }));
                }));
  };
  React.useEffect((function () {
          var exit = 0;
          if (typeof limitsRemote === "number" || limitsRemote.tag) {
            exit = 1;
          } else {
            var limitsMaybe = limitsRemote[0];
            if (limitsMaybe !== undefined) {
              var match = limitsMaybe.metadata.gate;
              if (match.tag) {
                Curry._1(setOnNemesis, (function (param) {
                        return true;
                      }));
              } else {
                Curry._1(setOnNemesis, (function (param) {
                        return false;
                      }));
              }
            } else {
              Curry._1(setOnNemesis, (function (param) {
                      return false;
                    }));
            }
          }
          if (exit === 1) {
            Curry._1(setOnNemesis, (function (param) {
                    return false;
                  }));
          }
          
        }), [limitsRemote]);
  React.useEffect((function () {
          if (!(!onNemesis || !SubUtil$BsConsole.canViewNemesisPlans(config))) {
            var arg = NemesisPlanSelection$BsConsole.$$fetch(undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      var err = resp[0];
                      if (err[0] === NemesisPlanSelection$BsConsole.ErrorMessage) {
                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Error retrieving plans: ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "Error retrieving plans: %s"
                                          ]), Task2$BsConsole.renderError(err[1], err)));
                      } else {
                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Error retrieving plans: ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "Error retrieving plans: %s"
                                          ]), Task2$BsConsole.renderError(undefined, err)));
                      }
                    }
                    var planResults = resp[0];
                    return Curry._1(setBasePlans, (function (param) {
                                  return planResults;
                                }));
                  }));
          }
          
        }), /* tuple */[
        config,
        token,
        onNemesis
      ]);
  React.useEffect((function () {
          if (!(!onNemesis || !SubUtil$BsConsole.canViewNemesisPlans(config))) {
            var arg = NemesisSubscriptions$BsConsole.$$fetch(undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "Error retrieving subscriptions: ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "Error retrieving subscriptions: %s"
                                        ]), Task2$BsConsole.renderError(undefined, resp[0])));
                    }
                    var subscriptionResults = resp[0];
                    Curry._1(setSubscription, (function (param) {
                            return Belt_Array.get(subscriptionResults, 0);
                          }));
                    if (refetchSubscription > 0) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Subscription information updated"));
                    }
                    
                  }));
          }
          
        }), /* tuple */[
        config,
        token,
        refetchSubscription,
        onNemesis
      ]);
  if (typeof limitsRemote === "number") {
    return null;
  }
  if (limitsRemote.tag) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, limitsRemote[0]));
    return React.createElement(SubInviteNewUser$InviteNewUser, {
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl,
                noMoreUsersPermitted: true
              });
  }
  var limitsMaybe = limitsRemote[0];
  if (limitsMaybe === undefined) {
    return React.createElement(SubInviteNewUser$InviteNewUser, {
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl,
                noMoreUsersPermitted: false
              });
  }
  var match$5 = limitsMaybe.metadata.manualOverride;
  if (match$5 !== undefined && match$5) {
    var noMoreUsersPermitted = SubUtil$BsConsole.getNoMoreUsersPermitted(limitsMaybe, currentUserCount);
    return React.createElement(SubInviteNewUser$InviteNewUser, {
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl,
                noMoreUsersPermitted: noMoreUsersPermitted
              });
  }
  var match$6 = limitsMaybe.metadata.gate;
  if (!match$6.tag) {
    return React.createElement(SubInviteNewUser$InviteNewUser, {
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl,
                noMoreUsersPermitted: false
              });
  }
  if (subscription === undefined) {
    return null;
  }
  if (basePlans === undefined) {
    return null;
  }
  var planInfo = SubPriceUtil$BsConsole.getPlanInfo(subscription.planId, basePlans);
  if (planInfo !== undefined) {
    return React.createElement(SubInviteNewUser$InviteNewUserWithNemesis, {
                config: config,
                limits: limitsMaybe,
                refetchLimits: match[1],
                token: token,
                handleChangeUrl: handleChangeUrl,
                nemesis: /* tuple */[
                  subscription,
                  planInfo,
                  basePlans,
                  updateSubscriptions
                ],
                currentUserCount: currentUserCount
              });
  }
  var noMoreUsersPermitted$1 = SubUtil$BsConsole.getNoMoreUsersPermitted(limitsMaybe, currentUserCount);
  return React.createElement(SubInviteNewUser$InviteNewUser, {
              config: config,
              token: token,
              handleChangeUrl: handleChangeUrl,
              noMoreUsersPermitted: noMoreUsersPermitted$1
            });
}

function make(config, token, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SubInviteNewUser, {
              config: config,
              token: token,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var make$1 = SubInviteNewUser;

exports.InviteUserHeader = InviteUserHeader;
exports.InviteNewUser = InviteNewUser;
exports.InviteNewUserWithNemesis = InviteNewUserWithNemesis;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
