// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var L10N_date_time_settings$BsConsole = require("./L10N_date_time_settings.js");

var Precision = { };

var Scheme = { };

function numeric(formats, precision) {
  switch (precision) {
    case /* Day */0 :
        return formats.calendar.numeric.day;
    case /* Month */1 :
        return formats.calendar.numeric.month;
    case /* MonthDay */2 :
        return formats.calendar.numeric.monthDay;
    case /* Year */3 :
        return formats.calendar.year;
    
  }
}

function textFormal(formats, precision) {
  switch (precision) {
    case /* Day */0 :
        return formats.calendar.textFormal.day;
    case /* Month */1 :
        return formats.calendar.textFormal.month;
    case /* MonthDay */2 :
        return formats.calendar.textFormal.monthDay;
    case /* Year */3 :
        return formats.calendar.year;
    
  }
}

function textStandard(formats, precision) {
  switch (precision) {
    case /* Day */0 :
        return formats.calendar.textStandard.day;
    case /* Month */1 :
        return formats.calendar.textStandard.month;
    case /* MonthDay */2 :
        return formats.calendar.textStandard.monthDay;
    case /* Year */3 :
        return formats.calendar.year;
    
  }
}

function textAbbreviated(formats, precision) {
  switch (precision) {
    case /* Day */0 :
        return formats.calendar.textAbbreviated.day;
    case /* Month */1 :
        return formats.calendar.textAbbreviated.month;
    case /* MonthDay */2 :
        return formats.calendar.textAbbreviated.monthDay;
    case /* Year */3 :
        return formats.calendar.year;
    
  }
}

var Format = {
  numeric: numeric,
  textFormal: textFormal,
  textStandard: textStandard,
  textAbbreviated: textAbbreviated
};

function pickFormat(param, formats) {
  var precision = param.precision;
  switch (param.scheme) {
    case /* Numeric */0 :
        return numeric(formats, precision);
    case /* TextFormal */1 :
        return textFormal(formats, precision);
    case /* TextStandard */2 :
        return textStandard(formats, precision);
    case /* TextAbbreviated */3 :
        return textAbbreviated(formats, precision);
    
  }
}

var Config = {
  Format: Format,
  pickFormat: pickFormat
};

function fromConfig(formats, config, jsDate) {
  return DateFns.format(pickFormat(config, L10N_date_time_settings$BsConsole.Cache.formats(formats)), jsDate);
}

function numericDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* Day */0
            }, jsDate);
}

function numericMonthDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* MonthDay */2
            }, jsDate);
}

function numericYear(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* Year */3
            }, jsDate);
}

function textAbbreviatedDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextAbbreviated */3,
              precision: /* Day */0
            }, jsDate);
}

function textStandardMonthDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextStandard */2,
              precision: /* MonthDay */2
            }, jsDate);
}

var Format$1 = {
  fromConfig: fromConfig,
  numericDay: numericDay,
  numericMonthDay: numericMonthDay,
  numericYear: numericYear,
  textAbbreviatedDay: textAbbreviatedDay,
  textStandardMonthDay: textStandardMonthDay
};

var Formats;

var Settings;

exports.Formats = Formats;
exports.Settings = Settings;
exports.Precision = Precision;
exports.Scheme = Scheme;
exports.Config = Config;
exports.Format = Format$1;
/* DateFns Not a pure module */
