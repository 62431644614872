// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var InboxMultiAssign$BsConsole = require("../../inbox/InboxMultiAssign.js");

function BugIssueAssignee(Props) {
  var handleSetAssignee = Props.handleSetAssignee;
  var target = Props.target;
  var projectUsers = Props.projectUsers;
  var anchorOrigin = Props.anchorOrigin;
  var tickets = Props.tickets;
  var assignees = Props.assignees;
  var config = Props.config;
  var tmp = {
    handleSetAssignee: handleSetAssignee,
    target: target,
    projectUsers: projectUsers,
    tickets: tickets,
    assignees: assignees,
    config: config
  };
  if (anchorOrigin !== undefined) {
    tmp.anchorOrigin = Caml_option.valFromOption(anchorOrigin);
  }
  return React.createElement(InboxMultiAssign$BsConsole.Jsx3.make, tmp);
}

var make = BugIssueAssignee;

exports.make = make;
/* react Not a pure module */
