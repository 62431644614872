// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Validator = require("validator");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var Crdb$BsConsole = require("../../crdb.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var ApiWf$BsConsole = require("../../workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var InboxUtils$BsConsole = require("../../inbox/InboxUtils.js");
var FilterInput$BsConsole = require("../../FilterInput.js");
var Chip = require("@material-ui/core/Chip");
var BtTypography$BsConsole = require("../../BtTypography.js");
var DescribeTask$BsConsole = require("../../DescribeTask.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var BtAutocomplete$BsConsole = require("../../BtAutocomplete.js");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var Typography = require("@material-ui/core/Typography");
var AttributeTypeahead$BsConsole = require("../../typeahead/AttributeTypeahead.js");
var ApiScheduledReports$BsConsole = require("./ApiScheduledReports.js");
var ScheduledReportsData$BsConsole = require("../ScheduledReportsData.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var FormHelperText = require("@material-ui/core/FormHelperText");

var settingContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var input = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var inputFull = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.width(Css.pct(95)),
        /* :: */[
          Css.boxSizing(/* borderBox */9307263),
          /* [] */0
        ]
      ]
    ]);

var inputHalf = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.width(Css.pct(45)),
        /* :: */[
          Css.boxSizing(/* borderBox */9307263),
          /* [] */0
        ]
      ]
    ]);

var inputSelect = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.minWidth(Css.px(250)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.boxSizing(/* borderBox */9307263),
            /* [] */0
          ]
        ]
      ]
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.flex(/* `num */[
                  5496390,
                  1
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.marginBottom(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var flexColumn = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var sectionRow = Css.merge(/* :: */[
      flexRow,
      /* :: */[
        Css.style(/* :: */[
              Css.flexDirection(/* row */5693978),
              /* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var sectionColumn = Css.merge(/* :: */[
      flexColumn,
      /* :: */[
        Css.style(/* :: */[
              Css.flex(/* `num */[
                    5496390,
                    1
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var loadingContainerStyle = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* :: */[
                            Css.height(Css.vh(75)),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var li = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blackA54)),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.marginBottom(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var parentCreate = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var title = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var titleText = Css.style(/* :: */[
      Css.fontSize(Css.px(24)),
      /* :: */[
        Css.margin(Css.px(0)),
        /* [] */0
      ]
    ]);

var titleLogo = Css.style(/* :: */[
      Css.width(Css.px(24)),
      /* :: */[
        Css.margin4(Css.px(0), Css.px(16), Css.px(0), Css.px(0)),
        /* [] */0
      ]
    ]);

var label = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(5)),
        /* [] */0
      ]
    ]);

var verticalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var horizontalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var flexRow$1 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.flex(/* `num */[
                5496390,
                1
              ]),
          /* :: */[
            Css.marginBottom(Css.px(10)),
            /* [] */0
          ]
        ]
      ]
    ]);

var containerRightMargin = Css.style(/* :: */[
      Css.marginRight(Css.px(40)),
      /* [] */0
    ]);

var flexInput = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.flex(/* `num */[
              5496390,
              1
            ]),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(50)),
          /* :: */[
            Css.marginBottom(Css.px(25)),
            /* [] */0
          ]
        ]
      ]
    ]);

var saveButton = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var formHelperText = Css.style(/* :: */[
      Css.margin4(Css.px(8), Css.px(0), Css.px(0), Css.px(0)),
      /* [] */0
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(40)),
      /* [] */0
    ]);

var advancedRules = Css.style(/* :: */[
      Css.background(/* none */-922086728),
      /* :: */[
        Css.border(Css.px(0), /* solid */12956715, /* transparent */582626130),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* :: */[
            Css.paddingTop(Css.px(5)),
            /* :: */[
              Css.paddingBottom(Css.px(5)),
              /* :: */[
                Css.marginTop(Css.px(13)),
                /* :: */[
                  Css.marginLeft(Css.px(20)),
                  /* :: */[
                    Css.borderRadius(Css.px(4)),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.greyA60)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipIconButton = Css.style(/* :: */[
      Css.important(Css.borderRadius(Css.px(0))),
      /* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* [] */0
      ]
    ]);

var chipRoot = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.white))),
      /* :: */[
        Css.important(Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.blueLight))),
        /* :: */[
          Css.important(Css.borderRadius(Css.px(3))),
          /* :: */[
            Css.important(Css.padding(Css.px(0))),
            /* :: */[
              Css.important(Css.position(/* relative */903134412)),
              /* :: */[
                Css.important(Css.paddingRight(Css.px(36))),
                /* :: */[
                  Css.important(Css.height(Css.px(40))),
                  /* :: */[
                    Css.important(Css.marginRight(Css.px(10))),
                    /* :: */[
                      Css.important(Css.marginBottom(Css.px(10))),
                      /* :: */[
                        Css.hover(/* :: */[
                              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.blueLighter))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipIconRed = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(0)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.redDark)),
          /* :: */[
            Css.hover(/* :: */[
                  Css.important(Css.background(Css.hex(Colors$BsConsole.redBackground))),
                  /* [] */0
                ]),
            /* :: */[
              Css.important(Css.margin(Css.px(0))),
              /* :: */[
                Css.height(Css.pct(100)),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* :: */[
                    Css.important(Css.paddingRight(Css.px(10))),
                    /* :: */[
                      Css.important(Css.paddingLeft(Css.px(10))),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipRootBlue = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.important(Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey6))),
        /* :: */[
          Css.important(Css.borderRadius(Css.px(3))),
          /* :: */[
            Css.important(Css.padding(Css.px(0))),
            /* :: */[
              Css.important(Css.position(/* relative */903134412)),
              /* :: */[
                Css.important(Css.paddingRight(Css.px(5))),
                /* :: */[
                  Css.important(Css.height(Css.px(40))),
                  /* :: */[
                    Css.important(Css.marginRight(Css.px(10))),
                    /* :: */[
                      Css.important(Css.marginBottom(Css.px(10))),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipIconBlue = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* :: */[
        Css.hover(/* :: */[
              Css.important(Css.background(Css.hex(Colors$BsConsole.blueBackground))),
              /* [] */0
            ]),
        /* :: */[
          Css.important(Css.margin(Css.px(0))),
          /* :: */[
            Css.height(Css.pct(100)),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var chipLabel = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.important(Css.paddingTop(Css.px(10))),
          /* :: */[
            Css.important(Css.paddingBottom(Css.px(10))),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  settingContainer: settingContainer,
  paper: paper,
  input: input,
  inputFull: inputFull,
  inputHalf: inputHalf,
  inputSelect: inputSelect,
  inputItem: inputItem,
  flexColumn: flexColumn,
  sectionRow: sectionRow,
  sectionColumn: sectionColumn,
  loadingContainerStyle: loadingContainerStyle,
  li: li,
  parentCreate: parentCreate,
  title: title,
  titleText: titleText,
  titleLogo: titleLogo,
  label: label,
  verticalContainer: verticalContainer,
  horizontalContainer: horizontalContainer,
  flexRow: flexRow$1,
  containerRightMargin: containerRightMargin,
  flexInput: flexInput,
  buttonContainer: buttonContainer,
  saveButton: saveButton,
  formHelperText: formHelperText,
  bottomMargin: bottomMargin,
  advancedRules: advancedRules,
  chipIconButton: chipIconButton,
  chipRoot: chipRoot,
  chipIconRed: chipIconRed,
  chipRootBlue: chipRootBlue,
  chipIconBlue: chipIconBlue,
  chipLabel: chipLabel
};

function ScheduledReportsForm$Required(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: className,
              children: null
            }, children, React.createElement("span", {
                  style: {
                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                  }
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: ScheduledReportsForm$Required
};

function ScheduledReportsForm$FormButtons(Props) {
  var handleSave = Props.handleSave;
  var handleSaveAndPreview = Props.handleSaveAndPreview;
  var handleCancel = Props.handleCancel;
  return React.createElement("div", {
              className: buttonContainer
            }, React.createElement(Button.default, {
                  color: "primary",
                  onClick: handleCancel,
                  children: I18N$BsConsole.show(undefined, "Cancel")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  className: saveButton,
                  onClick: handleSave,
                  children: I18N$BsConsole.show(undefined, "Save report")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  className: saveButton,
                  onClick: handleSaveAndPreview,
                  children: I18N$BsConsole.show(undefined, "Save and preview report")
                }));
}

var FormButtons = {
  make: ScheduledReportsForm$FormButtons
};

function ScheduledReportsForm$SectionRow(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionRow,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionRow = {
  make: ScheduledReportsForm$SectionRow
};

function ScheduledReportsForm$SectionColumn(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionColumn,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionColumn = {
  make: ScheduledReportsForm$SectionColumn
};

var selectedDaysDefault = Belt_Array.map(ScheduledReportsData$BsConsole.days, (function (day) {
        var maybeDay = Belt_Int.fromString(day.value);
        var tmp;
        if (maybeDay !== undefined) {
          tmp = maybeDay;
        } else {
          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReportsForm/autoPopulateState/selectedDays", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
          Backtrace$BsConsole.Client.send(__x, /* `string */[
                288368849,
                "Error converting day of string \"" + (day.value + "\" to int. Defautled to 0.")
              ]);
          tmp = 0;
        }
        return {
                label: day.label,
                value: tmp,
                checked: true
              };
      }));

var selectedMonthsDefault = Belt_Array.map(ScheduledReportsData$BsConsole.months, (function (month) {
        var maybeMonth = Belt_Int.fromString(month.value);
        var tmp;
        if (maybeMonth !== undefined) {
          tmp = maybeMonth;
        } else {
          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReportsForm/autoPopulateState/selectedMonths", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
          Backtrace$BsConsole.Client.send(__x, /* `string */[
                288368849,
                "Error converting month of string \"" + (month.value + "\" to int. Defautled to 0.")
              ]);
          tmp = 0;
        }
        return {
                label: month.label,
                value: tmp,
                checked: true
              };
      }));

var Defaults = {
  selectedDaysDefault: selectedDaysDefault,
  selectedMonthsDefault: selectedMonthsDefault
};

function ScheduledReportsForm$Frequency$Repeat(Props) {
  var frequency = Props.frequency;
  var frequencyTypesAvailable = Props.frequencyTypesAvailable;
  var isFormInvalid = Props.isFormInvalid;
  var handleUpdateFrequency = Props.handleUpdateFrequency;
  var handleResetCustomFrequency = Props.handleResetCustomFrequency;
  var match = isFormInvalid && frequency.type_ === "";
  var match$1 = Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.stringToType, frequency.type_);
  var match$2 = frequency.weekdays;
  var match$3 = frequency.months;
  var tmp;
  if (match) {
    tmp = React.createElement(FormHelperText.default, {
          error: true,
          children: I18N$BsConsole.show(undefined, "Report frequency is required")
        });
  } else {
    var exit = 0;
    if (match$1) {
      if (match$2 !== undefined || match$3 !== undefined) {
        exit = 1;
      } else {
        tmp = React.createElement(FormHelperText.default, {
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.px(10)),
                    /* :: */[
                      Css.marginTop(Css.px(10)),
                      /* [] */0
                    ]
                  ]),
              children: I18N$BsConsole.show(undefined, "Monthly reports will be sent on the first of every month")
            });
      }
    } else if (match$2 !== undefined || match$3 !== undefined) {
      exit = 1;
    } else {
      tmp = React.createElement(FormHelperText.default, {
            className: Css.style(/* :: */[
                  Css.marginBottom(Css.px(10)),
                  /* :: */[
                    Css.marginTop(Css.px(10)),
                    /* [] */0
                  ]
                ]),
            children: I18N$BsConsole.show(undefined, "Daily reports will be sent every day if not customized")
          });
    }
    if (exit === 1) {
      tmp = React.createElement(FormHelperText.default, {
            className: Css.style(/* :: */[
                  Css.marginBottom(Css.px(10)),
                  /* :: */[
                    Css.marginTop(Css.px(10)),
                    /* [] */0
                  ]
                ]),
            children: I18N$BsConsole.show(undefined, "How often reports are to be sent")
          });
    }
    
  }
  return React.createElement(ScheduledReportsForm$SectionColumn, {
              children: null
            }, React.createElement(ScheduledReportsForm$Required, {
                  className: label,
                  children: I18N$BsConsole.show(undefined, "Repeat")
                }), React.createElement(Select.default, {
                  value: frequency.type_,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null) && value !== frequency.type_) {
                        Curry._1(handleResetCustomFrequency, undefined);
                        return Curry._1(handleUpdateFrequency, {
                                    type_: value,
                                    time: frequency.time,
                                    weekdays: frequency.weekdays,
                                    months: frequency.months
                                  });
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: inputFull
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: inputSelect
                  },
                  autoWidth: true,
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    transitionDuration: 0
                  },
                  children: Belt_Array.map(frequencyTypesAvailable, (function (frequencyType) {
                          return React.createElement(MenuItem.default, {
                                      value: Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.typeToString, frequencyType),
                                      children: I18N$BsConsole.showSkip($$String.uppercase(Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.typeToString, frequencyType))),
                                      key: Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.typeToString, frequencyType)
                                    });
                        }))
                }), tmp);
}

var Repeat = {
  make: ScheduledReportsForm$Frequency$Repeat
};

function ScheduledReportsForm$Frequency$Time(Props) {
  var frequency = Props.frequency;
  var handleUpdateFrequency = Props.handleUpdateFrequency;
  var isFormInvalid = Props.isFormInvalid;
  return React.createElement(ScheduledReportsForm$SectionColumn, {
              children: null
            }, React.createElement(ScheduledReportsForm$Required, {
                  className: label,
                  children: I18N$BsConsole.show(undefined, "Time")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  placeholder: "",
                  value: frequency.time,
                  color: "primary",
                  variant: "outlined",
                  type: "time",
                  classes: {
                    root: inputFull
                  },
                  onChange: (function ($$event) {
                      var frequencyTime = $$event.target.value;
                      if (!(frequencyTime == null)) {
                        return Curry._1(handleUpdateFrequency, {
                                    type_: frequency.type_,
                                    time: frequencyTime.trim(),
                                    weekdays: frequency.weekdays,
                                    months: frequency.months
                                  });
                      }
                      
                    })
                }), isFormInvalid && frequency.time === "" ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Report frequency is required")
                  }) : null);
}

var Time = {
  make: ScheduledReportsForm$Frequency$Time
};

function ScheduledReportsForm$Frequency$Timezone(Props) {
  var timezone = Props.timezone;
  var isFormInvalid = Props.isFormInvalid;
  var handleUpdateTimezone = Props.handleUpdateTimezone;
  return React.createElement(ScheduledReportsForm$SectionColumn, {
              children: null
            }, React.createElement(ScheduledReportsForm$Required, {
                  className: label,
                  children: I18N$BsConsole.show(undefined, "Timezone")
                }), React.createElement(Select.default, {
                  value: timezone,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null)) {
                        return Curry._1(handleUpdateTimezone, value);
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: inputFull
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: inputSelect
                  },
                  autoWidth: true,
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    transitionDuration: 0
                  },
                  children: Belt_Array.map(Belt_List.toArray(Belt_List.sort(ScheduledReportsData$BsConsole.timezones, (function (a, b) {
                                  if (a.value.toLowerCase() > b.value.toLowerCase()) {
                                    return 1;
                                  } else {
                                    return 0;
                                  }
                                }))), (function (timeZone) {
                          return React.createElement(MenuItem.default, {
                                      value: timeZone.value,
                                      children: I18N$BsConsole.showSkip(timeZone.value),
                                      key: timeZone.value
                                    });
                        }))
                }), isFormInvalid && timezone === "" ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Report frequency is required")
                  }) : null);
}

var Timezone = {
  make: ScheduledReportsForm$Frequency$Timezone
};

function isDayOfWeekSelected(dayOfWeek, weekdaysSum) {
  var d = Math.pow(2, dayOfWeek) | 0;
  return (weekdaysSum & d) === d;
}

function isMonthSelected(month, monthsSum) {
  var m = Math.pow(2, month) | 0;
  return (monthsSum & m) === m;
}

function ScheduledReportsForm$Frequency(Props) {
  var frequency = Props.frequency;
  var frequencyTypesAvailable = Props.frequencyTypesAvailable;
  var timezone = Props.timezone;
  var handleUpdateFrequency = Props.handleUpdateFrequency;
  var handleUpdateTimezone = Props.handleUpdateTimezone;
  var selectedDays = Props.selectedDays;
  var selectedMonths = Props.selectedMonths;
  var handleUpdateSelectedDays = Props.handleUpdateSelectedDays;
  var handleUpdateSelectedMonths = Props.handleUpdateSelectedMonths;
  var isFormInvalid = Props.isFormInvalid;
  var match = Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.stringToType, frequency.type_);
  return React.createElement("div", {
              className: bottomMargin
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(25)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Frequency")
                }), React.createElement(ScheduledReportsForm$SectionRow, {
                  children: null
                }, React.createElement(ScheduledReportsForm$Frequency$Repeat, {
                      frequency: frequency,
                      frequencyTypesAvailable: frequencyTypesAvailable,
                      isFormInvalid: isFormInvalid,
                      handleUpdateFrequency: handleUpdateFrequency,
                      handleResetCustomFrequency: (function (param) {
                          Curry._1(handleUpdateSelectedDays, selectedDaysDefault);
                          return Curry._1(handleUpdateSelectedMonths, selectedMonthsDefault);
                        })
                    }), React.createElement(ScheduledReportsForm$Frequency$Time, {
                      frequency: frequency,
                      handleUpdateFrequency: handleUpdateFrequency,
                      isFormInvalid: isFormInvalid
                    }), React.createElement(ScheduledReportsForm$Frequency$Timezone, {
                      timezone: timezone,
                      isFormInvalid: isFormInvalid,
                      handleUpdateTimezone: handleUpdateTimezone
                    })), React.createElement(ScheduledReportsForm$SectionRow, {
                  children: match ? React.createElement(ScheduledReportsForm$SectionColumn, {
                          children: null
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: label,
                              children: I18N$BsConsole.show(undefined, "Customize")
                            }), React.createElement(FormHelperText.default, {
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.px(10)),
                                    /* :: */[
                                      Css.marginTop(Css.px(10)),
                                      /* [] */0
                                    ]
                                  ]),
                              children: I18N$BsConsole.show(undefined, "Customize frequency by selecting certain month(s) of the year")
                            }), React.createElement(ScheduledReportsForm$SectionRow, {
                              children: Belt_Array.map(ScheduledReportsData$BsConsole.months, (function (month) {
                                      var isMonthChecked = Belt_Option.getWithDefault(Belt_Array.getBy(selectedMonths, (function (selectedMonth) {
                                                  return selectedMonth.label === month.label;
                                                })), {
                                            label: "",
                                            value: 0,
                                            checked: false
                                          }).checked;
                                      return React.createElement(Chip.default, {
                                                  label: React.createElement(Typography.default, {
                                                        variant: "h5",
                                                        classes: {
                                                          root: chipLabel
                                                        },
                                                        children: I18N$BsConsole.showSkip(month.label)
                                                      }),
                                                  onClick: (function (param) {
                                                      return Curry._1(handleUpdateSelectedMonths, Belt_Array.map(selectedMonths, (function (m) {
                                                                        if (m.label === month.label) {
                                                                          return {
                                                                                  label: m.label,
                                                                                  value: m.value,
                                                                                  checked: !m.checked
                                                                                };
                                                                        } else {
                                                                          return m;
                                                                        }
                                                                      })));
                                                    }),
                                                  icon: React.createElement(Mui$BsConsole.IconButton.make, {
                                                        ariaLabel: (
                                                          isMonthChecked ? "Check " : "Uncheck "
                                                        ) + month.label,
                                                        className: chipIconButton,
                                                        children: React.createElement(Checkbox.default, {
                                                              checked: isMonthChecked,
                                                              color: "primary"
                                                            })
                                                      }),
                                                  classes: {
                                                    icon: chipIconBlue,
                                                    root: chipRoot
                                                  }
                                                });
                                    })),
                              classNames: Css.style(/* :: */[
                                    Css.flexWrap(/* wrap */-822134326),
                                    /* [] */0
                                  ])
                            })) : React.createElement(ScheduledReportsForm$SectionColumn, {
                          children: null
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: label,
                              children: I18N$BsConsole.show(undefined, "Customize")
                            }), React.createElement(FormHelperText.default, {
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.px(10)),
                                    /* :: */[
                                      Css.marginTop(Css.px(10)),
                                      /* [] */0
                                    ]
                                  ]),
                              children: I18N$BsConsole.show(undefined, "Customize frequency by selecting certain day(s) or the week")
                            }), React.createElement(ScheduledReportsForm$SectionRow, {
                              children: Belt_Array.map(ScheduledReportsData$BsConsole.days, (function (day) {
                                      var isDayChecked = Belt_Option.getWithDefault(Belt_Array.getBy(selectedDays, (function (selectedDay) {
                                                  return selectedDay.label === day.label;
                                                })), {
                                            label: "",
                                            value: 0,
                                            checked: false
                                          }).checked;
                                      return React.createElement(Chip.default, {
                                                  label: React.createElement(Typography.default, {
                                                        variant: "h5",
                                                        classes: {
                                                          root: chipLabel
                                                        },
                                                        children: I18N$BsConsole.showSkip(day.label)
                                                      }),
                                                  onClick: (function (param) {
                                                      return Curry._1(handleUpdateSelectedDays, Belt_Array.map(selectedDays, (function (d) {
                                                                        if (d.label === day.label) {
                                                                          return {
                                                                                  label: d.label,
                                                                                  value: d.value,
                                                                                  checked: !d.checked
                                                                                };
                                                                        } else {
                                                                          return d;
                                                                        }
                                                                      })));
                                                    }),
                                                  icon: React.createElement(Mui$BsConsole.IconButton.make, {
                                                        ariaLabel: (
                                                          isDayChecked ? "Check " : "Uncheck "
                                                        ) + day.label,
                                                        className: chipIconButton,
                                                        children: React.createElement(Checkbox.default, {
                                                              checked: isDayChecked,
                                                              color: "primary"
                                                            })
                                                      }),
                                                  classes: {
                                                    icon: chipIconBlue,
                                                    root: chipRoot
                                                  }
                                                });
                                    })),
                              classNames: Css.style(/* :: */[
                                    Css.flexWrap(/* wrap */-822134326),
                                    /* [] */0
                                  ])
                            }))
                }));
}

var Frequency = {
  Defaults: Defaults,
  Repeat: Repeat,
  Time: Time,
  Timezone: Timezone,
  isDayOfWeekSelected: isDayOfWeekSelected,
  isMonthSelected: isMonthSelected,
  make: ScheduledReportsForm$Frequency
};

function typeToInt(param) {
  switch (param) {
    case /* OneDay */0 :
        return 1;
    case /* OneWeek */1 :
        return 7;
    case /* TwoWeeks */2 :
        return 14;
    case /* ThirtyDays */3 :
        return 30;
    
  }
}

function typeToString(param) {
  switch (param) {
    case /* OneDay */0 :
        return "LAST DAY";
    case /* OneWeek */1 :
        return "LAST 7 DAYS";
    case /* TwoWeeks */2 :
        return "LAST 14 DAYS";
    case /* ThirtyDays */3 :
        return "LAST 30 DAYS";
    
  }
}

function stringToType(param) {
  if (param >= 8) {
    if (param !== 14) {
      if (param !== 30) {
        return /* OneWeek */1;
      } else {
        return /* ThirtyDays */3;
      }
    } else {
      return /* TwoWeeks */2;
    }
  } else if (param !== 1) {
    return /* OneWeek */1;
  } else {
    return /* OneDay */0;
  }
}

function intToType(days) {
  if (days >= 8) {
    if (days === 14) {
      return /* TwoWeeks */2;
    }
    if (days === 30) {
      return /* ThirtyDays */3;
    }
    
  } else {
    if (days === 1) {
      return /* OneDay */0;
    }
    if (days >= 7) {
      return /* OneWeek */1;
    }
    
  }
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReportsForm/TimeFrame/intToType", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
  Backtrace$BsConsole.Client.send(__x, /* `string */[
        288368849,
        "TimeFrame of " + (String(days) + "days is not an available option and was defaulted to 7 days")
      ]);
  return /* OneWeek */1;
}

function ScheduledReportsForm$TimeFrame(Props) {
  var timeFrame = Props.timeFrame;
  var timeFramesAvailable = Props.timeFramesAvailable;
  var handleUpdateTimeFrame = Props.handleUpdateTimeFrame;
  return React.createElement(ScheduledReportsForm$SectionColumn, {
              children: null
            }, React.createElement(ScheduledReportsForm$Required, {
                  className: label,
                  children: I18N$BsConsole.show(undefined, "Time frame")
                }), React.createElement(Select.default, {
                  value: timeFrame,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null)) {
                        return Curry._1(handleUpdateTimeFrame, value);
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: inputFull
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: inputSelect
                  },
                  autoWidth: true,
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    transitionDuration: 0
                  },
                  children: Belt_Array.map(timeFramesAvailable, (function (timeFrame) {
                          return React.createElement(MenuItem.default, {
                                      value: timeFrame,
                                      children: I18N$BsConsole.showSkip(typeToString(timeFrame)),
                                      key: typeToString(timeFrame)
                                    });
                        }))
                }));
}

var TimeFrame = {
  typeToInt: typeToInt,
  typeToString: typeToString,
  stringToType: stringToType,
  intToType: intToType,
  make: ScheduledReportsForm$TimeFrame
};

var initialState_frequency = {
  type_: "daily",
  time: "",
  weekdays: undefined,
  months: undefined
};

var initialState_frequencyTypesAvailable = [
  /* Daily */0,
  /* Monthly */1
];

var initialState_timeFramesAvailable = [
  /* OneDay */0,
  /* OneWeek */1,
  /* TwoWeeks */2,
  /* ThirtyDays */3
];

var initialState_recipients = [];

var initialState = {
  title: "",
  frequency: initialState_frequency,
  frequencyTypesAvailable: initialState_frequencyTypesAvailable,
  timezone: "",
  timeFrame: /* OneWeek */1,
  timeFramesAvailable: initialState_timeFramesAvailable,
  recipients: initialState_recipients,
  reportFilters: undefined,
  reportAttributes: undefined,
  isFormInvalid: false,
  errorMessage: "",
  selectedMonths: selectedMonthsDefault,
  selectedDays: selectedDaysDefault,
  awaitEditState: true,
  isFilterInputValid: true,
  isRecipientInputValid: true
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            title: state.title,
            frequency: state.frequency,
            frequencyTypesAvailable: state.frequencyTypesAvailable,
            timezone: state.timezone,
            timeFrame: state.timeFrame,
            timeFramesAvailable: state.timeFramesAvailable,
            recipients: state.recipients,
            reportFilters: state.reportFilters,
            reportAttributes: state.reportAttributes,
            isFormInvalid: state.isFormInvalid,
            errorMessage: state.errorMessage,
            selectedMonths: state.selectedMonths,
            selectedDays: state.selectedDays,
            awaitEditState: false,
            isFilterInputValid: state.isFilterInputValid,
            isRecipientInputValid: state.isRecipientInputValid
          };
  }
  switch (action.tag | 0) {
    case /* SetTitle */0 :
        return {
                title: action[0],
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetTimeFrame */1 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: action[0],
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetFrequency */2 :
        return {
                title: state.title,
                frequency: action[0],
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetTimezone */3 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: action[0],
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetRecipients */4 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: action[0],
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetFilters */5 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: action[0],
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetAttribute */6 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: action[0],
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetIsFormInvalid */7 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: action[0],
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetErrorMessage */8 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: action[0],
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetSelectedMonths */9 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: action[0],
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetSelectedDays */10 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: action[0],
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* AutoPopulateForm */11 :
        var autoPopulateState = action[0];
        return {
                title: autoPopulateState.title,
                frequency: autoPopulateState.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: autoPopulateState.timezone,
                timeFrame: autoPopulateState.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: autoPopulateState.recipients,
                reportFilters: autoPopulateState.reportFilters,
                reportAttributes: autoPopulateState.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: autoPopulateState.selectedMonths,
                selectedDays: autoPopulateState.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetIsFilterInputValid */12 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: action[0],
                isRecipientInputValid: state.isRecipientInputValid
              };
    case /* SetIsRecipientInputValid */13 :
        return {
                title: state.title,
                frequency: state.frequency,
                frequencyTypesAvailable: state.frequencyTypesAvailable,
                timezone: state.timezone,
                timeFrame: state.timeFrame,
                timeFramesAvailable: state.timeFramesAvailable,
                recipients: state.recipients,
                reportFilters: state.reportFilters,
                reportAttributes: state.reportAttributes,
                isFormInvalid: state.isFormInvalid,
                errorMessage: state.errorMessage,
                selectedMonths: state.selectedMonths,
                selectedDays: state.selectedDays,
                awaitEditState: state.awaitEditState,
                isFilterInputValid: state.isFilterInputValid,
                isRecipientInputValid: action[0]
              };
    
  }
}

function ScheduledReportsForm$Title(Props) {
  var title = Props.title;
  var handleAddTitle = Props.handleAddTitle;
  var isFormInvalid = Props.isFormInvalid;
  return React.createElement(ScheduledReportsForm$SectionColumn, {
              children: null
            }, React.createElement(ScheduledReportsForm$Required, {
                  className: label,
                  children: I18N$BsConsole.show(undefined, "Report Title")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  placeholder: "",
                  value: title,
                  color: "primary",
                  variant: "outlined",
                  classes: {
                    root: inputFull
                  },
                  onChange: (function ($$event) {
                      return Curry._1(handleAddTitle, Belt_Option.getWithDefault($$event.target.value, ""));
                    })
                }), isFormInvalid && title.trim() === "" ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Report title is required")
                  }) : null);
}

var Title = {
  make: ScheduledReportsForm$Title
};

function ScheduledReportsForm$Recipients(Props) {
  var recipients = Props.recipients;
  var isFormInvalid = Props.isFormInvalid;
  var handleAddRecipient = Props.handleAddRecipient;
  var handleRemoveRecipient = Props.handleRemoveRecipient;
  var handleRecipientInputValidation = Props.handleRecipientInputValidation;
  var match = React.useState((function () {
          return "";
        }));
  var setRecipient = match[1];
  var recipientInput = match[0];
  React.useEffect((function () {
          if (recipientInput.trim() === "") {
            Curry._1(handleRecipientInputValidation, true);
          } else {
            Curry._1(handleRecipientInputValidation, false);
          }
          
        }), [recipientInput]);
  var match$1 = recipientInput.trim() === "";
  var match$2 = recipients.length === 0;
  return React.createElement("div", {
              className: bottomMargin
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(25)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Recipients")
                }), React.createElement(ScheduledReportsForm$SectionColumn, {
                  children: null
                }, React.createElement(ScheduledReportsForm$Required, {
                      className: label,
                      children: I18N$BsConsole.show(undefined, "Email")
                    }), React.createElement(FormHelperText.default, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(10)),
                            /* :: */[
                              Css.marginTop(Css.px(0)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Add recipient's email")
                    }), React.createElement(ScheduledReportsForm$SectionRow, {
                      children: null
                    }, React.createElement(TextField.default, {
                          autoFocus: false,
                          placeholder: "",
                          value: recipientInput,
                          color: "primary",
                          variant: "outlined",
                          classes: {
                            root: inputHalf
                          },
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._1(setRecipient, (function (param) {
                                              return value.trim();
                                            }));
                              }
                              
                            })
                        }), React.createElement(Tooltip.default, {
                          placement: "bottom",
                          children: React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                className: Css.style(/* :: */[
                                      Css.height(Css.px(25)),
                                      /* :: */[
                                        Css.marginTop(Css.px(7)),
                                        /* :: */[
                                          Css.marginLeft(Css.px(15)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                disabled: !Validator.isEmail(recipientInput),
                                onClick: (function (param) {
                                    if (!Belt_Array.some(recipients, (function (recipient) {
                                              return recipient === recipientInput;
                                            }))) {
                                      Curry._1(handleAddRecipient, recipientInput);
                                    }
                                    return Curry._1(setRecipient, (function (param) {
                                                  return "";
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Add")
                              }),
                          title: "Add recipient"
                        })), React.createElement(ScheduledReportsForm$SectionRow, {
                      children: isFormInvalid ? (
                          match$1 ? (
                              match$2 ? React.createElement(FormHelperText.default, {
                                      error: true,
                                      children: I18N$BsConsole.show(undefined, "At least one recipient is required")
                                    }) : null
                            ) : (
                              match$2 ? React.createElement(FormHelperText.default, {
                                      error: true,
                                      children: I18N$BsConsole.show(undefined, "Click add button to add recipient's email")
                                    }) : React.createElement(FormHelperText.default, {
                                      error: true,
                                      children: I18N$BsConsole.show(undefined, "Add or clear input to continue")
                                    })
                            )
                        ) : null
                    }), React.createElement(ScheduledReportsForm$SectionRow, {
                      children: Belt_Array.mapWithIndex(recipients, (function (index, recipient) {
                              return React.createElement(Chip.default, {
                                          label: React.createElement(Typography.default, {
                                                variant: "h5",
                                                classes: {
                                                  root: chipLabel
                                                },
                                                children: I18N$BsConsole.showSkip(recipient)
                                              }),
                                          icon: React.createElement(Mui$BsConsole.IconButton.make, {
                                                ariaLabel: "delete status",
                                                className: chipIconButton,
                                                onClick: (function (param) {
                                                    return Curry._1(handleRemoveRecipient, index);
                                                  }),
                                                children: React.createElement(Close.default, {
                                                      fontSize: "small"
                                                    })
                                              }),
                                          classes: {
                                            icon: chipIconRed,
                                            root: chipRoot
                                          }
                                        });
                            })),
                      classNames: Css.style(/* :: */[
                            Css.flexWrap(/* wrap */-822134326),
                            /* [] */0
                          ])
                    })));
}

var Recipients = {
  make: ScheduledReportsForm$Recipients
};

function ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInputAutocomplete(Props) {
  var attribute = Props.attribute;
  var token = Props.token;
  var projectName = Props.projectName;
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var initialValue = Props.initialValue;
  var allTimeAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
              /* Floating */0,
              /* All */6
            ])), Crdb$BsConsole.Aperture.$$default);
  var match = FilterInput$BsConsole.ValueInputAutocomplete.useAttributeValueSuggestions(attribute, allTimeAperture, Crdb$BsConsole.Havings.empty, token, projectName);
  return React.createElement("span", {
              className: Css.style(/* :: */[
                    Css.flexGrow(1),
                    /* :: */[
                      Css.marginLeft(Css.px(15)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtAutocomplete$BsConsole.make, {
                  toString: (function (param) {
                      return param[0];
                    }),
                  considerations: match[0],
                  onDelete: onDelete,
                  onChange: (function (raw, value$prime) {
                      if (value$prime !== undefined) {
                        return Curry._1(onChange, value$prime[0]);
                      } else {
                        return Curry._1(onChange, raw);
                      }
                    }),
                  defaultValue: Belt_Option.getWithDefault(initialValue, ""),
                  loading: match[1],
                  placeholder: I18N$BsConsole.get(undefined, "Value"),
                  autoFocus: false,
                  inputAriaLabel: I18N$BsConsole.get(undefined, "Value"),
                  renderListOption: (function (param, idx) {
                      var value = param[0];
                      return React.createElement(Col2$BsConsole.make, {
                                  justifyContent: /* spaceAround */-485895757,
                                  className: Css.style(/* :: */[
                                        Css.minWidth(Css.px(100)),
                                        /* [] */0
                                      ]),
                                  children: null,
                                  key: value + ("__" + String(idx))
                                }, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      className: Css.style(/* :: */[
                                            Css.margin(Css.zero),
                                            /* :: */[
                                              Css.unsafe("overflowWrap", "anywhere"),
                                              /* [] */0
                                            ]
                                          ]),
                                      children: I18N$BsConsole.showSkip(value)
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey4)),
                                            /* :: */[
                                              Css.marginBottom(Css.zero),
                                              /* [] */0
                                            ]
                                          ]),
                                      children: I18N$BsConsole.showSkip(String(param[1]))
                                    }));
                    }),
                  renderInput: (function (param) {
                      var onFocus = param.onFocus;
                      var onChange = param.onChange;
                      var onFocus$prime = Curry.__1(onFocus);
                      var onChange$prime = Curry.__1(onChange);
                      return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                      onFocus: onFocus$prime,
                                      className: Css.style(/* :: */[
                                            Css.important(Css.marginBottom(/* zero */-789508312)),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.selector("input", /* :: */[
                                                      Css.important(Css.padding(Css.px(10))),
                                                      /* [] */0
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      placeholder: param.placeholder,
                                      value: param.value,
                                      onChange: onChange$prime,
                                      autoFocus: true,
                                      variant: "outlined"
                                    }), React.createElement(FormHelperText.default, {
                                      children: I18N$BsConsole.show(undefined, "Filter value")
                                    }));
                    })
                }));
}

var AttributeValueInputAutocomplete = {
  make: ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInputAutocomplete
};

function ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInput(Props) {
  var isDisabled = Props.isDisabled;
  var filterValue = Props.filterValue;
  var onChange = Props.onChange;
  return React.createElement("span", {
              className: Css.style(/* :: */[
                    Css.flexGrow(1),
                    /* :: */[
                      Css.marginLeft(Css.px(15)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: isDisabled,
                  placeholder: "value",
                  value: filterValue,
                  color: "primary",
                  variant: "outlined",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.selector("input", /* :: */[
                                Css.important(Css.padding(Css.px(10))),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ])
                  },
                  onChange: (function ($$event) {
                      return Curry._1(onChange, Belt_Option.getWithDefault($$event.target.value, ""));
                    })
                }), React.createElement(FormHelperText.default, {
                  children: I18N$BsConsole.show(undefined, "Filter value")
                }));
}

var AttributeValueInput = {
  make: ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInput
};

function ScheduledReportsForm$CustomizeReportContent$ReportFilters$AddChipButton(Props) {
  var filterInput = Props.filterInput;
  var onSuccess = Props.onSuccess;
  Props.className;
  return React.createElement(Tooltip.default, {
              placement: "bottom",
              children: React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    className: Css.style(/* :: */[
                          Css.height(Css.px(25)),
                          /* :: */[
                            Css.marginTop(Css.px(7)),
                            /* :: */[
                              Css.marginLeft(Css.px(15)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    disabled: filterInput.attribute === undefined || filterInput.operator === "" || filterInput.value.trim() === "",
                    onClick: (function (param) {
                        return Curry._1(onSuccess, undefined);
                      }),
                    children: I18N$BsConsole.show(undefined, "Add")
                  }),
              title: "Add filter"
            });
}

var AddChipButton = {
  make: ScheduledReportsForm$CustomizeReportContent$ReportFilters$AddChipButton
};

function ScheduledReportsForm$CustomizeReportContent$ReportFilters(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var attributes = Props.attributes;
  var reportFilters = Props.reportFilters;
  var isFilterInputValid = Props.isFilterInputValid;
  var isFormInvalid = Props.isFormInvalid;
  var handleAddFilter = Props.handleAddFilter;
  var handleRemoveFilter = Props.handleRemoveFilter;
  var handleFilterInputValidation = Props.handleFilterInputValidation;
  var match = React.useState((function () {
          return {
                  attribute: undefined,
                  operator: "",
                  value: ""
                };
        }));
  var setFilterInput = match[1];
  var filterInput = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setRowKey = match$1[1];
  React.useEffect((function () {
          Curry._1(setRowKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [reportFilters]);
  React.useEffect((function () {
          var match = filterInput.attribute === undefined;
          var match$1 = filterInput.operator === "";
          var match$2 = filterInput.value === "";
          if (match && match$1 && match$2) {
            Curry._1(handleFilterInputValidation, true);
          } else {
            Curry._1(handleFilterInputValidation, false);
          }
          
        }), /* tuple */[
        filterInput.attribute,
        filterInput.operator,
        filterInput.value
      ]);
  var match$2 = filterInput.attribute;
  var attribute = filterInput.attribute;
  var attr = filterInput.attribute;
  var tmp;
  if (attr !== undefined) {
    var match$3 = filterInput.operator;
    var match$4 = Belt_List.keep(attr.aggregate, (function (agg) {
            return agg === "bin";
          }));
    var exit = 0;
    var exit$1 = 0;
    switch (match$3) {
      case "assigned-to" :
      case "equal" :
      case "linked-to" :
      case "not-assigned-to" :
      case "not-equal" :
      case "not-linked-to" :
          exit$1 = 2;
          break;
      default:
        exit = 1;
    }
    if (exit$1 === 2) {
      if (match$4) {
        exit = 1;
      } else {
        tmp = React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInputAutocomplete, {
              attribute: attr,
              token: token,
              projectName: projectName,
              onChange: (function (value) {
                  return Curry._1(setFilterInput, (function (prevFilter) {
                                return {
                                        attribute: prevFilter.attribute,
                                        operator: prevFilter.operator,
                                        value: value
                                      };
                              }));
                }),
              onDelete: (function (param) {
                  
                }),
              initialValue: filterInput.value
            });
      }
    }
    if (exit === 1) {
      tmp = React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInput, {
            isDisabled: filterInput.operator === "",
            filterValue: filterInput.value,
            onChange: (function (value) {
                return Curry._1(setFilterInput, (function (prevFilter) {
                              return {
                                      attribute: prevFilter.attribute,
                                      operator: prevFilter.operator,
                                      value: value
                                    };
                            }));
              })
          });
    }
    
  } else {
    tmp = React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportFilters$AttributeValueInput, {
          isDisabled: filterInput.operator === "",
          filterValue: filterInput.value,
          onChange: (function (value) {
              return Curry._1(setFilterInput, (function (prevFilter) {
                            return {
                                    attribute: prevFilter.attribute,
                                    operator: prevFilter.operator,
                                    value: value
                                  };
                          }));
            })
        });
  }
  return React.createElement("div", {
              className: bottomMargin
            }, React.createElement(ScheduledReportsForm$SectionColumn, {
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: label,
                      children: I18N$BsConsole.show(undefined, "Filter report content")
                    }), React.createElement("div", {
                      className: flexRow$1
                    }, React.createElement(BtAutocomplete$BsConsole.make, {
                          toString: (function (attribute$prime) {
                              return attribute$prime.name;
                            }),
                          considerations: Belt_List.toArray(attributes),
                          onChange: (function (_raw, attribute$prime) {
                              return Curry._1(setFilterInput, (function (param) {
                                            return {
                                                    attribute: attribute$prime,
                                                    operator: "",
                                                    value: ""
                                                  };
                                          }));
                            }),
                          defaultValue: match$2 !== undefined ? match$2.name : "",
                          placeholder: I18N$BsConsole.get(undefined, "Attribute"),
                          autoFocus: false,
                          inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                          renderListOption: (function (attr, idx) {
                              return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                                          name: attr.name,
                                          description: attr.description,
                                          custom: attr.custom,
                                          triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                                          used: attr.statistics.used,
                                          key: attr.name + ("__" + String(idx))
                                        });
                            }),
                          renderInput: (function (param) {
                              var onFocus = param.onFocus;
                              var onChange = param.onChange;
                              var onFocus$prime = Curry.__1(onFocus);
                              var onChange$prime = function ($$event) {
                                var newValue = $$event.target.value;
                                if (!(newValue == null) && newValue === "") {
                                  Curry._1(setFilterInput, (function (param) {
                                          return {
                                                  attribute: undefined,
                                                  operator: "",
                                                  value: ""
                                                };
                                        }));
                                }
                                return Curry._1(onChange, $$event);
                              };
                              return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                              onFocus: onFocus$prime,
                                              className: Css.style(/* :: */[
                                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                                    /* :: */[
                                                      Css.width(Css.px(300)),
                                                      /* :: */[
                                                        Css.marginRight(Css.px(15)),
                                                        /* :: */[
                                                          Css.selector("input", /* :: */[
                                                                Css.important(Css.padding(Css.px(10))),
                                                                /* [] */0
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]),
                                              placeholder: param.placeholder,
                                              value: param.value,
                                              onChange: onChange$prime,
                                              variant: "outlined"
                                            }), React.createElement(FormHelperText.default, {
                                              children: I18N$BsConsole.show(undefined, "Filter attribute")
                                            }));
                            }),
                          key: "attribute-" + String(match$1[0])
                        }), React.createElement("span", {
                          className: Css.style(/* :: */[
                                Css.flexGrow(1),
                                /* [] */0
                              ])
                        }, React.createElement(Select.default, {
                              value: filterInput.operator,
                              disabled: Belt_Option.isNone(filterInput.attribute),
                              onChange: (function ($$event, param) {
                                  var value = $$event.target.value;
                                  if (!(value == null)) {
                                    return Curry._1(setFilterInput, (function (prevFilter) {
                                                  return {
                                                          attribute: prevFilter.attribute,
                                                          operator: value,
                                                          value: prevFilter.value
                                                        };
                                                }));
                                  }
                                  
                                }),
                              input: React.createElement(OutlinedInput.default, {
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* [] */0
                                          ])
                                    },
                                    labelWidth: 0
                                  }),
                              classes: {
                                select: Css.merge(/* :: */[
                                      inputItem,
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.unsafe("width", "calc(100% - 20px)"),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              autoWidth: true,
                              children: attribute !== undefined ? (
                                  Belt_List.toArray(attribute.filter).length !== 0 ? Belt_Array.map(Belt_List.toArray(attribute.filter), (function (condition) {
                                            return React.createElement(MenuItem.default, {
                                                        value: condition,
                                                        children: I18N$BsConsole.showSkip(condition),
                                                        key: condition
                                                      });
                                          })) : [React.createElement(MenuItem.default, {
                                            children: I18N$BsConsole.show(undefined, "No options available")
                                          })]
                                ) : [React.createElement(MenuItem.default, {
                                        children: I18N$BsConsole.show(undefined, "No options available")
                                      })]
                            }), React.createElement(FormHelperText.default, {
                              children: I18N$BsConsole.show(undefined, "Filter operator")
                            })), tmp, React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportFilters$AddChipButton, {
                          filterInput: filterInput,
                          onSuccess: (function (param) {
                              Curry._3(handleAddFilter, filterInput.attribute, filterInput.operator, filterInput.value);
                              return Curry._1(setFilterInput, (function (param) {
                                            return {
                                                    attribute: undefined,
                                                    operator: "",
                                                    value: ""
                                                  };
                                          }));
                            }),
                          className: Css.style(/* :: */[
                                Css.alignSelf(/* flexStart */662439529),
                                /* [] */0
                              ])
                        }))), React.createElement(ScheduledReportsForm$SectionRow, {
                  children: isFormInvalid && !isFilterInputValid ? React.createElement(FormHelperText.default, {
                          error: true,
                          children: I18N$BsConsole.show(undefined, "Add or clear filters to continue")
                        }) : null
                }), React.createElement(ScheduledReportsForm$SectionRow, {
                  children: reportFilters !== undefined ? Belt_List.toArray(Belt_List.mapWithIndex(reportFilters, (function (index, filter) {
                                var attribute = filter[0];
                                var operator = Util$BsConsole.filterOpMap(Belt_Option.getWithDefault(Belt_Array.get(Belt_Option.getWithDefault(Belt_Array.get(filter[1], 0), []), 0), ""));
                                var value = Belt_Option.getWithDefault(Belt_Array.get(Belt_Option.getWithDefault(Belt_Array.get(filter[1], 0), []), 1), "");
                                return React.createElement(Chip.default, {
                                            label: React.createElement(Typography.default, {
                                                  variant: "h5",
                                                  classes: {
                                                    root: chipLabel
                                                  },
                                                  children: Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* Char_literal */Block.__(12, [
                                                                    /* " " */32,
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* Char_literal */Block.__(12, [
                                                                            /* " " */32,
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ]),
                                                            "%s %s %s"
                                                          ]), attribute, operator, value)
                                                }),
                                            icon: React.createElement(Mui$BsConsole.IconButton.make, {
                                                  ariaLabel: "delete status",
                                                  className: chipIconButton,
                                                  onClick: (function (param) {
                                                      return Curry._1(handleRemoveFilter, index);
                                                    }),
                                                  children: React.createElement(Close.default, {
                                                        fontSize: "small"
                                                      })
                                                }),
                                            classes: {
                                              icon: chipIconRed,
                                              root: chipRoot
                                            }
                                          });
                              }))) : []
                }));
}

var ReportFilters = {
  AttributeValueInputAutocomplete: AttributeValueInputAutocomplete,
  AttributeValueInput: AttributeValueInput,
  AddChipButton: AddChipButton,
  make: ScheduledReportsForm$CustomizeReportContent$ReportFilters
};

function ScheduledReportsForm$CustomizeReportContent$ReportAttributes(Props) {
  var attributes = Props.attributes;
  var reportAttributes = Props.reportAttributes;
  var handleAddAttribute = Props.handleAddAttribute;
  var handleRemoveAttribute = Props.handleRemoveAttribute;
  var match = React.useState((function () {
          return 0;
        }));
  var setRowKey = match[1];
  React.useEffect((function () {
          Curry._1(setRowKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [reportAttributes]);
  return React.createElement("div", {
              className: bottomMargin
            }, React.createElement(ScheduledReportsForm$SectionColumn, {
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: label,
                      children: I18N$BsConsole.show(undefined, "Attribute content (histograms)")
                    }), React.createElement("div", {
                      className: flexRow$1
                    }, React.createElement(BtAutocomplete$BsConsole.make, {
                          toString: (function (attribute$prime) {
                              return attribute$prime.name;
                            }),
                          considerations: Belt_List.toArray(attributes),
                          onChange: (function (_raw, attribute$prime) {
                              return Curry._1(handleAddAttribute, attribute$prime);
                            }),
                          placeholder: I18N$BsConsole.get(undefined, "Attribute"),
                          autoFocus: false,
                          inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                          renderListOption: (function (attr, idx) {
                              return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                                          name: attr.name,
                                          description: attr.description,
                                          custom: attr.custom,
                                          triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                                          used: attr.statistics.used,
                                          key: attr.name + ("__" + String(idx))
                                        });
                            }),
                          renderInput: (function (param) {
                              var onFocus = param.onFocus;
                              var onChange = param.onChange;
                              var onFocus$prime = Curry.__1(onFocus);
                              var onChange$prime = Curry.__1(onChange);
                              return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                              onFocus: onFocus$prime,
                                              className: Css.style(/* :: */[
                                                    Css.important(Css.marginBottom(/* zero */-789508312)),
                                                    /* :: */[
                                                      Css.width(Css.px(300)),
                                                      /* :: */[
                                                        Css.marginRight(Css.px(15)),
                                                        /* :: */[
                                                          Css.selector("input", /* :: */[
                                                                Css.important(Css.padding(Css.px(10))),
                                                                /* [] */0
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]),
                                              placeholder: param.placeholder,
                                              value: param.value,
                                              onChange: onChange$prime,
                                              variant: "outlined"
                                            }), React.createElement(FormHelperText.default, {
                                              children: I18N$BsConsole.show(undefined, "Attribute historgram")
                                            }));
                            }),
                          key: "attribute-" + String(match[0])
                        }))), React.createElement(ScheduledReportsForm$SectionRow, {
                  children: reportAttributes !== undefined ? Belt_List.toArray(Belt_List.mapWithIndex(reportAttributes, (function (index, attribute) {
                                var attribute$1 = attribute[0];
                                return React.createElement(Chip.default, {
                                            label: React.createElement(Typography.default, {
                                                  variant: "h5",
                                                  classes: {
                                                    root: chipLabel
                                                  },
                                                  children: I18N$BsConsole.show(undefined, attribute$1)
                                                }),
                                            icon: React.createElement(Mui$BsConsole.IconButton.make, {
                                                  ariaLabel: "delete status",
                                                  className: chipIconButton,
                                                  onClick: (function (param) {
                                                      return Curry._1(handleRemoveAttribute, index);
                                                    }),
                                                  children: React.createElement(Close.default, {
                                                        fontSize: "small"
                                                      })
                                                }),
                                            classes: {
                                              icon: chipIconRed,
                                              root: chipRoot
                                            }
                                          });
                              }))) : []
                }));
}

var ReportAttributes = {
  make: ScheduledReportsForm$CustomizeReportContent$ReportAttributes
};

function ScheduledReportsForm$CustomizeReportContent(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var reportFilters = Props.reportFilters;
  var handleAddFilter = Props.handleAddFilter;
  var handleRemoveFilter = Props.handleRemoveFilter;
  var reportAttributes = Props.reportAttributes;
  var isFilterInputValid = Props.isFilterInputValid;
  var isFormInvalid = Props.isFormInvalid;
  var handleAddAttribute = Props.handleAddAttribute;
  var handleRemoveAttribute = Props.handleRemoveAttribute;
  var handleFilterInputValidation = Props.handleFilterInputValidation;
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var attributes;
  attributes = typeof describeRemote === "number" || describeRemote.tag ? /* [] */0 : Belt_List.sort(describeRemote[0], (function (attr1, attr2) {
            return $$String.compare(attr1.name.toLowerCase(), attr2.name.toLowerCase());
          }));
  return React.createElement("div", {
              className: bottomMargin
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(25)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Customize report content")
                }), React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportFilters, {
                  token: token,
                  projectName: projectName,
                  attributes: attributes,
                  reportFilters: reportFilters,
                  isFilterInputValid: isFilterInputValid,
                  isFormInvalid: isFormInvalid,
                  handleAddFilter: handleAddFilter,
                  handleRemoveFilter: handleRemoveFilter,
                  handleFilterInputValidation: handleFilterInputValidation
                }), React.createElement(ScheduledReportsForm$CustomizeReportContent$ReportAttributes, {
                  attributes: attributes,
                  reportAttributes: reportAttributes,
                  handleAddAttribute: handleAddAttribute,
                  handleRemoveAttribute: handleRemoveAttribute
                }));
}

var CustomizeReportContent = {
  ReportFilters: ReportFilters,
  ReportAttributes: ReportAttributes,
  make: ScheduledReportsForm$CustomizeReportContent
};

function previewReportTask(recipients, config, token, projectName, goBack, handleError, reportId) {
  var onSuccess = function (param) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Report preview was sent"));
    return Curry._1(goBack, undefined);
  };
  var onFailure = function (err) {
    return Curry._1(handleError, "Error sending report preview: " + err);
  };
  var maybeTask = ApiScheduledReports$BsConsole.PreviewScheduledReport.previewScheduledReportTask(config, projectName, reportId, [config.user.email]);
  if (maybeTask !== undefined) {
    return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                  return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                }));
  } else {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
  }
}

function submitReportTask(title, frequency, timezone, timeFrame, recipients, reportFilters, reportAttributes, selectedDays, selectedMonths, config, token, projectName, goBack, handleError, previewReport, maybeReport) {
  var onSuccess = function (report) {
    if (previewReport) {
      return previewReportTask(recipients, config, token, projectName, goBack, handleError, report.id);
    } else {
      return Curry._1(goBack, undefined);
    }
  };
  var onFailure = function (err) {
    return Curry._1(handleError, "Error sumbitting report setup: " + err);
  };
  var calcFrequencyWeekdays = function (param) {
    return Belt_Array.reduce(selectedDays, 0, (function (acc, day) {
                  if (day.checked) {
                    return acc + (Math.pow(2.0, day.value) | 0) | 0;
                  } else {
                    return acc;
                  }
                }));
  };
  var calcFrequencyMonths = function (param) {
    return Belt_Array.reduce(selectedMonths, 0, (function (acc, month) {
                  if (month.checked) {
                    return acc + (Math.pow(2.0, month.value) | 0) | 0;
                  } else {
                    return acc;
                  }
                }));
  };
  var maybeTask;
  if (maybeReport !== undefined) {
    var match = Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.stringToType, frequency.type_);
    maybeTask = ApiScheduledReports$BsConsole.EditScheduledReport.editScheduledReportTask(title.trim(), true, match ? ({
              type_: frequency.type_,
              time: frequency.time,
              weekdays: frequency.weekdays,
              months: calcFrequencyMonths(undefined)
            }) : ({
              type_: frequency.type_,
              time: frequency.time,
              weekdays: calcFrequencyWeekdays(undefined),
              months: frequency.months
            }), timezone, typeToInt(timeFrame), recipients, reportFilters, reportAttributes, config, projectName, maybeReport.id);
  } else {
    var match$1 = Curry._1(ApiScheduledReports$BsConsole.ScheduledReports.Frequency.stringToType, frequency.type_);
    maybeTask = ApiScheduledReports$BsConsole.CreateScheduledReport.createScheduledReportTask(title, true, match$1 ? ({
              type_: frequency.type_,
              time: frequency.time,
              weekdays: frequency.weekdays,
              months: calcFrequencyMonths(undefined)
            }) : ({
              type_: frequency.type_,
              time: frequency.time,
              weekdays: calcFrequencyWeekdays(undefined),
              months: frequency.months
            }), timezone, typeToInt(timeFrame), recipients, reportFilters, reportAttributes, config, projectName);
  }
  if (maybeTask !== undefined) {
    return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                  return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                }));
  } else {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
  }
}

var SubmitReport = {
  previewReportTask: previewReportTask,
  submitReportTask: submitReportTask
};

function ScheduledReportsForm(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var config = Props.config;
  var goBack = Props.goBack;
  var maybeReport = Props.maybeReport;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var isRecipientInputValid = match$1.isRecipientInputValid;
  var isFilterInputValid = match$1.isFilterInputValid;
  var selectedDays = match$1.selectedDays;
  var selectedMonths = match$1.selectedMonths;
  var errorMessage = match$1.errorMessage;
  var isFormInvalid = match$1.isFormInvalid;
  var reportAttributes = match$1.reportAttributes;
  var reportFilters = match$1.reportFilters;
  var recipients = match$1.recipients;
  var timeFrame = match$1.timeFrame;
  var timezone = match$1.timezone;
  var frequency = match$1.frequency;
  var title$1 = match$1.title;
  var isFormValid = function (param) {
    if (title$1.trim() === "" || frequency.type_ === "" || frequency.time === "" || timezone === "" || recipients.length === 0 || !isFilterInputValid || !isRecipientInputValid) {
      Curry._1(dispatch, /* SetIsFormInvalid */Block.__(7, [true]));
      return false;
    } else {
      Curry._1(dispatch, /* SetIsFormInvalid */Block.__(7, [false]));
      return true;
    }
  };
  React.useEffect((function () {
          window.scrollTo(0, 0);
          
        }), [errorMessage]);
  React.useEffect((function () {
          if (maybeReport !== undefined) {
            var autoPopulateState_title = maybeReport.name;
            var autoPopulateState_timeFrame = intToType(maybeReport.days);
            var autoPopulateState_frequency = {
              type_: maybeReport.frequency.type_,
              time: maybeReport.frequency.time,
              weekdays: maybeReport.frequency.weekdays,
              months: maybeReport.frequency.months
            };
            var autoPopulateState_timezone = maybeReport.timezone;
            var autoPopulateState_recipients = maybeReport.recipients;
            var autoPopulateState_reportFilters = Belt_List.length(maybeReport.filter) > 0 ? maybeReport.filter : undefined;
            var autoPopulateState_reportAttributes = Belt_List.length(maybeReport.attributes) > 0 ? maybeReport.attributes : undefined;
            var autoPopulateState_selectedMonths = Belt_Array.map(ScheduledReportsData$BsConsole.months, (function (month) {
                    var maybeMonth = Belt_Int.fromString(month.value);
                    var tmp;
                    if (maybeMonth !== undefined) {
                      tmp = maybeMonth;
                    } else {
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReportsForm/autoPopulateState/selectedMonths", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
                      Backtrace$BsConsole.Client.send(__x, /* `string */[
                            288368849,
                            "Error converting month of string \"" + (month.value + "\" to int. Defautled to 0.")
                          ]);
                      tmp = 0;
                    }
                    return {
                            label: month.label,
                            value: tmp,
                            checked: isMonthSelected(Caml_format.caml_float_of_string(month.value), Belt_Option.getWithDefault(maybeReport.frequency.months, 0))
                          };
                  }));
            var autoPopulateState_selectedDays = Belt_Array.map(ScheduledReportsData$BsConsole.days, (function (day) {
                    var maybeDay = Belt_Int.fromString(day.value);
                    var tmp;
                    if (maybeDay !== undefined) {
                      tmp = maybeDay;
                    } else {
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ScheduledReportsForm/autoPopulateState/selectedDays", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
                      Backtrace$BsConsole.Client.send(__x, /* `string */[
                            288368849,
                            "Error converting day of string \"" + (day.value + "\" to int. Defautled to 0.")
                          ]);
                      tmp = 0;
                    }
                    return {
                            label: day.label,
                            value: tmp,
                            checked: isDayOfWeekSelected(Caml_format.caml_float_of_string(day.value), Belt_Option.getWithDefault(maybeReport.frequency.weekdays, 0))
                          };
                  }));
            var autoPopulateState = {
              title: autoPopulateState_title,
              timeFrame: autoPopulateState_timeFrame,
              frequency: autoPopulateState_frequency,
              timezone: autoPopulateState_timezone,
              recipients: autoPopulateState_recipients,
              reportFilters: autoPopulateState_reportFilters,
              reportAttributes: autoPopulateState_reportAttributes,
              selectedMonths: autoPopulateState_selectedMonths,
              selectedDays: autoPopulateState_selectedDays
            };
            Curry._1(dispatch, /* AutoPopulateForm */Block.__(11, [autoPopulateState]));
          } else {
            Curry._1(dispatch, /* EndAwaitEditState */0);
          }
          
        }), [maybeReport]);
  var handleSubmit = function (previewReport) {
    if (isFormValid(undefined)) {
      return submitReportTask(title$1, frequency, timezone, timeFrame, recipients, reportFilters, reportAttributes, selectedDays, selectedMonths, config, token, projectName, goBack, (function (err) {
                    return Curry._1(dispatch, /* SetErrorMessage */Block.__(8, [err]));
                  }), previewReport, maybeReport);
    } else {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(8, ["Form is invalid. Please fill out all required fields and resubmit form."]));
    }
  };
  return React.createElement(BtSettings$BsConsole.Container.make, {
              className: settingContainer,
              children: null
            }, errorMessage.length > 0 ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, errorMessage)
                  }) : null, React.createElement(Paper.default, {
                  className: paper,
                  children: null
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            title,
                            /* :: */[
                              bottomMargin,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement("p", {
                          className: titleText
                        }, I18N$BsConsole.show(undefined, "Report Setup"))), React.createElement(ScheduledReportsForm$SectionRow, {
                      children: null,
                      classNames: bottomMargin
                    }, React.createElement(ScheduledReportsForm$Title, {
                          title: title$1,
                          handleAddTitle: (function (updatedTitle) {
                              return Curry._1(dispatch, /* SetTitle */Block.__(0, [updatedTitle]));
                            }),
                          isFormInvalid: isFormInvalid
                        }), React.createElement(ScheduledReportsForm$TimeFrame, {
                          timeFrame: timeFrame,
                          timeFramesAvailable: match$1.timeFramesAvailable,
                          handleUpdateTimeFrame: (function (updatedTimeFrame) {
                              return Curry._1(dispatch, /* SetTimeFrame */Block.__(1, [updatedTimeFrame]));
                            })
                        })), React.createElement(ScheduledReportsForm$Frequency, {
                      frequency: frequency,
                      frequencyTypesAvailable: match$1.frequencyTypesAvailable,
                      timezone: timezone,
                      handleUpdateFrequency: (function (updatedFrequency) {
                          return Curry._1(dispatch, /* SetFrequency */Block.__(2, [updatedFrequency]));
                        }),
                      handleUpdateTimezone: (function (updatedTimezone) {
                          return Curry._1(dispatch, /* SetTimezone */Block.__(3, [updatedTimezone]));
                        }),
                      selectedDays: selectedDays,
                      selectedMonths: selectedMonths,
                      handleUpdateSelectedDays: (function (updatedDays) {
                          return Curry._1(dispatch, /* SetSelectedDays */Block.__(10, [updatedDays]));
                        }),
                      handleUpdateSelectedMonths: (function (updatedMonths) {
                          return Curry._1(dispatch, /* SetSelectedMonths */Block.__(9, [updatedMonths]));
                        }),
                      isFormInvalid: isFormInvalid
                    }), React.createElement(ScheduledReportsForm$Recipients, {
                      recipients: recipients,
                      isFormInvalid: isFormInvalid,
                      handleAddRecipient: (function (recipient) {
                          var updatedRecipients = Belt_Array.concat(recipients, [recipient]);
                          return Curry._1(dispatch, /* SetRecipients */Block.__(4, [updatedRecipients]));
                        }),
                      handleRemoveRecipient: (function (index) {
                          var updatedRecipients = Belt_Array.keepWithIndex(recipients, (function (param, idx) {
                                  return index !== idx;
                                }));
                          return Curry._1(dispatch, /* SetRecipients */Block.__(4, [updatedRecipients]));
                        }),
                      handleRecipientInputValidation: (function (isRecipientInputValid) {
                          return Curry._1(dispatch, /* SetIsRecipientInputValid */Block.__(13, [isRecipientInputValid]));
                        })
                    }), React.createElement(ScheduledReportsForm$CustomizeReportContent, {
                      token: token,
                      projectName: projectName,
                      reportFilters: reportFilters,
                      handleAddFilter: (function (attribute, operator, value) {
                          var attributeName = attribute !== undefined ? attribute.name : "";
                          var updatedFilters = reportFilters !== undefined ? Belt_List.concat(reportFilters, /* :: */[
                                  /* tuple */[
                                    attributeName,
                                    [[
                                        operator,
                                        value
                                      ]]
                                  ],
                                  /* [] */0
                                ]) : /* :: */[
                              /* tuple */[
                                attributeName,
                                [[
                                    operator,
                                    value.trim()
                                  ]]
                              ],
                              /* [] */0
                            ];
                          return Curry._1(dispatch, /* SetFilters */Block.__(5, [updatedFilters]));
                        }),
                      handleRemoveFilter: (function (index) {
                          if (reportFilters === undefined) {
                            return ;
                          }
                          var updatedFilters = Belt_List.keepWithIndex(reportFilters, (function (param, idx) {
                                  return index !== idx;
                                }));
                          if (Belt_List.length(updatedFilters) > 0) {
                            return Curry._1(dispatch, /* SetFilters */Block.__(5, [updatedFilters]));
                          } else {
                            return Curry._1(dispatch, /* SetFilters */Block.__(5, [undefined]));
                          }
                        }),
                      reportAttributes: reportAttributes,
                      isFilterInputValid: isFilterInputValid,
                      isFormInvalid: isFormInvalid,
                      handleAddAttribute: (function (attribute) {
                          var attributeName = attribute !== undefined ? attribute.name : "";
                          var updatedAttributes = reportAttributes !== undefined ? Belt_List.concat(reportAttributes, /* :: */[
                                  /* tuple */[
                                    attributeName,
                                    [["histogram"]]
                                  ],
                                  /* [] */0
                                ]) : /* :: */[
                              /* tuple */[
                                attributeName,
                                [["histogram"]]
                              ],
                              /* [] */0
                            ];
                          return Curry._1(dispatch, /* SetAttribute */Block.__(6, [updatedAttributes]));
                        }),
                      handleRemoveAttribute: (function (index) {
                          if (reportAttributes === undefined) {
                            return ;
                          }
                          var updatedAttributes = Belt_List.keepWithIndex(reportAttributes, (function (param, idx) {
                                  return index !== idx;
                                }));
                          if (Belt_List.length(updatedAttributes) > 0) {
                            return Curry._1(dispatch, /* SetAttribute */Block.__(6, [updatedAttributes]));
                          } else {
                            return Curry._1(dispatch, /* SetAttribute */Block.__(6, [undefined]));
                          }
                        }),
                      handleFilterInputValidation: (function (isFilterInputValid) {
                          return Curry._1(dispatch, /* SetIsFilterInputValid */Block.__(12, [isFilterInputValid]));
                        })
                    }), React.createElement(ScheduledReportsForm$FormButtons, {
                      handleSave: (function (param) {
                          return handleSubmit(false);
                        }),
                      handleSaveAndPreview: (function (param) {
                          return handleSubmit(true);
                        }),
                      handleCancel: (function (param) {
                          return Curry._1(goBack, undefined);
                        })
                    })));
}

var component = ReasonReact.statelessComponent("ScheduledReportsForm");

function make(token, config, projectName, goBack, maybeReport, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ScheduledReportsForm, {
              token: token,
              projectName: projectName,
              config: config,
              goBack: goBack,
              maybeReport: maybeReport
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = ScheduledReportsForm;

exports.Styles = Styles;
exports.Required = Required;
exports.FormButtons = FormButtons;
exports.SectionRow = SectionRow;
exports.SectionColumn = SectionColumn;
exports.Frequency = Frequency;
exports.TimeFrame = TimeFrame;
exports.initialState = initialState;
exports.reducer = reducer;
exports.Title = Title;
exports.Recipients = Recipients;
exports.CustomizeReportContent = CustomizeReportContent;
exports.SubmitReport = SubmitReport;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* settingContainer Not a pure module */
