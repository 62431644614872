// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BugSource$BsConsole = require("./BugSource.js");
var BugDefectView$BsConsole = require("./BugDefectView.js");

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.width(Css.px(180)),
      /* :: */[
        Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
        /* :: */[
          Css.height(Css.px(25)),
          /* :: */[
            Css.fontSize(Css.px(12)),
            /* :: */[
              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey4)),
              /* :: */[
                Css.margin(Css.px(8)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var viewOptions = /* :: */[
  /* SourceCode */0,
  /* :: */[
    /* Defects */1,
    /* [] */0
  ]
];

function BugDefectSourceToggle(Props) {
  var config = Props.config;
  var attachments = Props.attachments;
  var source = Props.source;
  var selectedFrame = Props.selectedFrame;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var defects = Props.defects;
  var height = Props.height;
  var match = React.useState((function () {
          return /* SourceCode */0;
        }));
  var setView = match[1];
  var tmp;
  if (match[0]) {
    tmp = React.createElement(BugDefectView$BsConsole.make, {
          config: config,
          defects: defects,
          height: height
        });
  } else {
    var tmp$1 = {
      attachments: attachments,
      source: source,
      projectName: projectName,
      errorId: errorId
    };
    if (selectedFrame.line !== undefined) {
      tmp$1.faultingLine = Caml_option.valFromOption(selectedFrame.line);
    }
    tmp = React.createElement(BugSource$BsConsole.make, tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: inputStyle
                }, React.createElement("select", {
                      className: formStyle,
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          switch (value) {
                            case "defects" :
                                return Curry._1(setView, (function (param) {
                                              return /* Defects */1;
                                            }));
                            case "sourcecode" :
                                return Curry._1(setView, (function (param) {
                                              return /* SourceCode */0;
                                            }));
                            default:
                              return Curry._1(setView, (function (param) {
                                            return /* SourceCode */0;
                                          }));
                          }
                        })
                    }, Belt_List.toArray(Belt_List.map(viewOptions, (function (option) {
                                if (option) {
                                  return React.createElement("option", {
                                              key: "defects",
                                              value: "defects"
                                            }, I18N$BsConsole.show(undefined, "Correlated defects"));
                                } else {
                                  return React.createElement("option", {
                                              key: "sourcecode",
                                              value: "sourcecode"
                                            }, I18N$BsConsole.show(undefined, "Source code"));
                                }
                              }))))), tmp);
}

var make = BugDefectSourceToggle;

exports.inputStyle = inputStyle;
exports.formStyle = formStyle;
exports.viewOptions = viewOptions;
exports.make = make;
/* inputStyle Not a pure module */
