// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");

function PlayModals(Props) {
  var match = React.useState((function () {
          return false;
        }));
  var setModal = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setSettingsModal = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.margin(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Modals")
                }), React.createElement(Row2$BsConsole.make, {
                  children: null
                }, React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginRight(Css.px(20)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setModal, (function (param) {
                                        return true;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Modal")
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(setSettingsModal, (function (param) {
                                        return true;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Settings-themed modal")
                    })), React.createElement(BtModal$BsConsole.Default.make, {
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  title: I18N$BsConsole.get(undefined, "This is the title"),
                  subtitle: I18N$BsConsole.get(undefined, "This is the subtitle"),
                  children: React.createElement(BtModal$BsConsole.MessageAndButton.make, {
                        message: I18N$BsConsole.get(undefined, "This is a message, and it probably has something to do with that button down there."),
                        buttonTitle: I18N$BsConsole.get(undefined, "Click me"),
                        buttonAction: (function (param) {
                            return Curry._1(setModal, (function (param) {
                                          return false;
                                        }));
                          })
                      })
                }), React.createElement(BtSettings$BsConsole.Container.make, {
                  children: React.createElement(BtModal$BsConsole.Default.make, {
                        open: match$1[0],
                        onClose: (function (param) {
                            return Curry._1(setSettingsModal, (function (param) {
                                          return false;
                                        }));
                          }),
                        title: I18N$BsConsole.get(undefined, "This is the title"),
                        subtitle: I18N$BsConsole.get(undefined, "This is the subtitle"),
                        divider: true,
                        children: React.createElement(BtModal$BsConsole.MessageAndButton.make, {
                              message: I18N$BsConsole.get(undefined, "This is a message, and it probably has something to do with that button down there."),
                              buttonTitle: I18N$BsConsole.get(undefined, "Click me"),
                              buttonAction: (function (param) {
                                  return Curry._1(setSettingsModal, (function (param) {
                                                return false;
                                              }));
                                })
                            })
                      })
                }));
}

var make = PlayModals;

exports.make = make;
/* Css Not a pure module */
