// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Colors$BsConsole = require("../Colors.js");
var Styles = require("@material-ui/core/styles");

var theme = Styles.createMuiTheme({
      palette: {
        primary: {
          main: Colors$BsConsole.octothorpe(Colors$BsConsole.accent),
          light: Colors$BsConsole.octothorpe(Colors$BsConsole.accentLight),
          dark: Colors$BsConsole.octothorpe(Colors$BsConsole.accentDark),
          contrastText: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
        }
      },
      overrides: {
        MuiButton: {
          root: {
            textTransform: "inherit"
          }
        },
        MuiMenuItem: {
          root: {
            "&:hover": {
              backgroundColor: "#" + Colors$BsConsole.blue,
              color: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            },
            "&:focus": {
              backgroundColor: "#" + Colors$BsConsole.blue,
              color: Colors$BsConsole.octothorpe(Colors$BsConsole.white)
            }
          }
        }
      }
    });

exports.theme = theme;
/* theme Not a pure module */
