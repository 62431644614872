// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var SubTextFormatter$BsConsole = require("./SubTextFormatter.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");

var addSeatText = I18N$BsConsole.get(undefined, "Add new seat");

function renderValue(s) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginLeft(Css.px(8)),
                    /* [] */0
                  ])
            }, I18N$BsConsole.showSkip(s));
}

function SubFeatureIncrementerConfirmModal(Props) {
  var modal = Props.modal;
  var setModal = Props.setModal;
  var onConfirm = Props.onConfirm;
  var currentPrice = Props.currentPrice;
  var plan = Props.plan;
  var formatter = Props.formatter;
  var feature = Props.feature;
  var selectedUser = Props.selectedUser;
  var setSelectedUser = Props.setSelectedUser;
  var match = React.useState((function () {
          
        }));
  var setAllUsersMaybe = match[1];
  var allUsersMaybe = match[0];
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  React.useEffect((function () {
          if (typeof allUsersRemote === "number") {
            allUsersRemote === /* NotAsked */0;
          } else if (allUsersRemote.tag) {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ]),
                          "%s"
                        ]), Task2$BsConsole.renderError(undefined, allUsersRemote[0])));
          } else {
            var users = Belt_Array.keep(allUsersRemote[0][0], (function (user) {
                    if (user.deleted === 0) {
                      return !user.email.endsWith("@backtrace.io");
                    } else {
                      return false;
                    }
                  }));
            Curry._1(setAllUsersMaybe, (function (param) {
                    return users;
                  }));
          }
          
        }), [allUsersRemote]);
  var s = feature.label;
  var labelString = s !== undefined ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* End_of_format */0
                ]),
              "%s"
            ]), s) : Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* Char_literal */Block.__(12, [
                      /* " " */32,
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ])
                ]),
              "%s %s"
            ]), SubTextFormatter$BsConsole.toString(feature.incrementAmount, formatter), feature.name);
  var tmp;
  if (modal !== undefined) {
    var exit = 0;
    switch (modal.tag | 0) {
      case /* Add */0 :
      case /* AddUserWithFrozenOptions */1 :
          exit = 1;
          break;
      case /* Remove */2 :
          tmp = Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "Remove ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* String_literal */Block.__(11, [
                                " from your ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        " limit. Your subscription cost will decrease by ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* Char_literal */Block.__(12, [
                                                /* "." */46,
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "Remove %s from your %s limit. Your subscription cost will decrease by %s."
                  ]), labelString, feature.name, SubPriceUtil$BsConsole.priceToString(SubPriceUtil$BsConsole.priceDifferenceOfIncrementingFeature(currentPrice, plan, feature)));
          break;
      
    }
    if (exit === 1) {
      tmp = Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                /* String_literal */Block.__(11, [
                    "Add ",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " to your ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " limit. Your subscription cost will increase by ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* "." */46,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ]),
                "Add %s to your %s limit. Your subscription cost will increase by %s."
              ]), labelString, feature.name, SubPriceUtil$BsConsole.priceToString(SubPriceUtil$BsConsole.priceDifferenceOfIncrementingFeature(currentPrice, plan, feature)));
    }
    
  } else {
    tmp = I18N$BsConsole.showEmpty(undefined);
  }
  var tmp$1;
  if (modal !== undefined) {
    switch (modal.tag | 0) {
      case /* AddUserWithFrozenOptions */1 :
          tmp$1 = React.createElement(Col2$BsConsole.make, {
                children: null
              }, React.createElement("div", {
                    className: Css.merge(/* :: */[
                          SubUtil$BsConsole.Style.bodyText,
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.marginTop(Css.px(12)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ])
                  }, I18N$BsConsole.show(undefined, "You can choose to restore an existing account that was frozen, or add a new seat so you can invite a new user to your Backtrace instance.")), allUsersMaybe !== undefined ? React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.px(12)),
                            /* :: */[
                              Css.marginBottom(Css.px(12)),
                              /* :: */[
                                Css.minWidth(Css.px(250)),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, React.createElement(Select.default, {
                          value: selectedUser,
                          onChange: (function (e, param) {
                              var value = e.target.value;
                              if (value === undefined) {
                                return Curry._1(setSelectedUser, (function (param) {
                                              
                                            }));
                              }
                              var newUser = Belt_Array.get(Belt_Array.keep(allUsersMaybe, (function (user) {
                                          return user.uid === value.uid;
                                        })), 0);
                              return Curry._1(setSelectedUser, (function (param) {
                                            return newUser;
                                          }));
                            }),
                          input: React.createElement(OutlinedInput.default, { }),
                          renderValue: (function (v) {
                              if (v !== undefined) {
                                return renderValue(v.username);
                              } else {
                                return renderValue(addSeatText);
                              }
                            }),
                          displayEmpty: true,
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.backgroundColor(/* transparent */582626130),
                                  /* :: */[
                                    Css.height(Css.rem(2)),
                                    /* :: */[
                                      Css.width(Css.ch(25)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            select: Css.style(/* :: */[
                                  Css.padding(Css.px(5)),
                                  /* :: */[
                                    Css.selector(":focus", /* :: */[
                                          Css.padding(Css.px(5)),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ])
                          },
                          children: null
                        }, React.createElement(MenuItem.default, {
                              value: undefined,
                              children: I18N$BsConsole.show(undefined, addSeatText)
                            }), Belt_List.toArray(Belt_List.keepMap(modal[0][3], (function (uid) {
                                    var user = Belt_Array.get(Belt_Array.keep(allUsersMaybe, (function (user) {
                                                return user.uid === uid;
                                              })), 0);
                                    if (user !== undefined) {
                                      return Caml_option.some(React.createElement(MenuItem.default, {
                                                      value: user,
                                                      children: I18N$BsConsole.showSkip(user.username)
                                                    }));
                                    }
                                    
                                  }))))) : null);
          break;
      case /* Add */0 :
      case /* Remove */2 :
          tmp$1 = null;
          break;
      
    }
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (modal !== undefined) {
    switch (modal.tag | 0) {
      case /* AddUserWithFrozenOptions */1 :
          tmp$2 = selectedUser !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Restore ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ]),
                      "Restore %s"
                    ]), selectedUser.username) : I18N$BsConsole.show(undefined, "Add new seat");
          break;
      case /* Add */0 :
      case /* Remove */2 :
          tmp$2 = I18N$BsConsole.show(undefined, "Confirm");
          break;
      
    }
  } else {
    tmp$2 = I18N$BsConsole.show(undefined, "Confirm");
  }
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: Belt_Option.isSome(modal),
              onClose: (function (param) {
                  return Curry._1(setModal, (function (param) {
                                
                              }));
                }),
              title: modal !== undefined ? I18N$BsConsole.get(undefined, "Update subscription") : I18N$BsConsole.empty(undefined),
              subtitle: I18N$BsConsole.empty(undefined),
              divider: true,
              maxWidth: "sm",
              fullWidth: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: SubUtil$BsConsole.Style.bodyText
                      }, tmp), tmp$1, React.createElement(Row2$BsConsole.make, {
                        alignItems: /* baseline */287825029,
                        justifyContent: /* flexEnd */924268066,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.height(Css.px(50)),
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, React.createElement(Button.default, {
                            disableRipple: true,
                            variant: "text",
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginRight(Css.px(10)),
                                    /* [] */0
                                  ])
                            },
                            onClick: (function (_event) {
                                return Curry._1(setModal, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          }), React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(10)),
                                    /* :: */[
                                      Css.minWidth(Css.px(150)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        
                                      }));
                                return Curry._1(onConfirm, undefined);
                              }),
                            children: tmp$2
                          })))
            });
}

var make = SubFeatureIncrementerConfirmModal;

exports.addSeatText = addSeatText;
exports.renderValue = renderValue;
exports.make = make;
/* addSeatText Not a pure module */
