// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WarningMessage$BsConsole = require("../WarningMessage.js");

function WfError(Props) {
  var defaultMessage = Props.defaultMessage;
  var errorMessage = Props.errorMessage;
  return React.createElement("div", undefined, React.createElement(WarningMessage$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(/* auto */-1065951377),
                        /* [] */0
                      ]),
                  errorMessage: Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ]),
                            "%s %s"
                          ]), defaultMessage, Task2$BsConsole.renderError(undefined, errorMessage))
                }));
}

var component = ReasonReact.statelessComponent("WfError");

function make(defaultMessage, errorMessage) {
  var partial_arg = {
    defaultMessage: defaultMessage,
    errorMessage: errorMessage
  };
  return (function (param) {
      return ReasonReactCompat.wrapReactForReasonReact(WfError, partial_arg, param);
    });
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = WfError;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
