// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var WfCore$BsConsole = require("../WfCore.js");
var WfTasks$BsConsole = require("../WfTasks.js");
var WfMetrics$BsConsole = require("../WfMetrics.js");
var WorkflowUtil$BsConsole = require("../../WorkflowUtil.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var WfIntegrationFull$BsConsole = require("../WfIntegrationFull.js");

var sub = {
  contents: undefined
};

function dispatchAction(action) {
  var cb = sub.contents;
  if (cb !== undefined) {
    return Curry._1(cb, action);
  }
  
}

function createTicket(reporting_user, fingerprint, workflow, projectName, onSuccess, param) {
  var integration = WfIntegrationFull$BsConsole.toSimple(workflow.integration);
  var watcher = workflow.watcher;
  WfMetrics$BsConsole.send(/* ManualActionOpened */Block.__(1, [
          integration,
          watcher,
          "ticket:create"
        ]));
  var integration$1 = workflow.integration;
  var __x = Js_dict.entries(integration$1.actions);
  var match = Belt_Option.getExn(Caml_option.undefined_to_opt(__x.find((function (param) {
                  return param[0] === "ticket:create";
                }))));
  return dispatchAction({
              form: match[1].request,
              initialFormValue: undefined,
              onSuccess: (function (json) {
                  if (json !== undefined) {
                    WfMetrics$BsConsole.send(/* ManualActionFormSubmitted */Block.__(2, [
                            integration,
                            watcher,
                            "ticket:create"
                          ]));
                    var params_watcherName = workflow.watcher.watcherName;
                    var params_payload = Caml_option.valFromOption(json);
                    var params = {
                      fingerprint: fingerprint,
                      projectName: projectName,
                      watcherName: params_watcherName,
                      payload: params_payload,
                      reporting_user: reporting_user
                    };
                    Curry._1(onSuccess, params);
                  } else {
                    WfMetrics$BsConsole.send(/* ManualActionFormCancelled */Block.__(3, [
                            integration,
                            watcher,
                            "ticket:create"
                          ]));
                    Curry._1(onSuccess, undefined);
                  }
                  return Promise.resolve(undefined);
                })
            });
}

function assignTicket(workflow, projectName, initialFormValue, onSuccess, param) {
  var integration = WfIntegrationFull$BsConsole.toSimple(workflow.integration);
  var watcher = workflow.watcher;
  var __x = Js_dict.entries(workflow.integration.actions);
  var match = Belt_Option.getExn(Caml_option.undefined_to_opt(__x.find((function (param) {
                  return param[0] === "ticket:assign";
                }))));
  var form = match[1].request;
  var onSuccess$1 = function (json) {
    if (json !== undefined) {
      WfMetrics$BsConsole.send(/* ManualActionFormSubmitted */Block.__(2, [
              integration,
              watcher,
              "ticket:assign"
            ]));
      var params_watcherName = workflow.watcher.watcherName;
      var params_payload = Caml_option.valFromOption(json);
      var params = {
        projectName: projectName,
        watcherName: params_watcherName,
        payload: params_payload
      };
      Curry._1(onSuccess, params);
    } else {
      WfMetrics$BsConsole.send(/* ManualActionFormCancelled */Block.__(3, [
              integration,
              watcher,
              "ticket:assign"
            ]));
      Curry._1(onSuccess, undefined);
    }
    return Promise.resolve(undefined);
  };
  var errs = WfCore$BsConsole.validateObjectRecursively(form.schema, Json_decode.dict(Util$BsConsole.identity, initialFormValue));
  if (errs.length === 0) {
    onSuccess$1(Caml_option.some(initialFormValue));
    return ;
  } else {
    WfMetrics$BsConsole.send(/* ManualActionOpened */Block.__(1, [
            integration,
            watcher,
            "ticket:assign"
          ]));
    return dispatchAction({
                form: form,
                initialFormValue: Caml_option.some(initialFormValue),
                onSuccess: onSuccess$1
              });
  }
}

function multiTicketAssign(token, projectName, config, assignments, param) {
  var responses = [];
  var allRequestsSentPromise = Belt_Array.reduce(assignments, Promise.resolve(0), (function (prevAssignmentDone, param) {
          var workflow = param.workflow;
          var initialFormValue = param.initialFormValue;
          return prevAssignmentDone.then((function (param) {
                        var integration = workflow.integration;
                        var __x = Js_dict.entries(integration.actions);
                        var match = Belt_Option.getExn(Caml_option.undefined_to_opt(__x.find((function (param) {
                                        return param[0] === "ticket:assign";
                                      }))));
                        var form = match[1].request;
                        var errs = WfCore$BsConsole.validateObjectRecursively(form.schema, Json_decode.dict(Util$BsConsole.identity, initialFormValue));
                        if (errs.length !== 0) {
                          return new Promise((function (goForward, param) {
                                        var taskPromise = new Promise((function (taskCompleted, param) {
                                                return dispatchAction({
                                                            form: form,
                                                            initialFormValue: Caml_option.some(initialFormValue),
                                                            onSuccess: (function (json) {
                                                                if (json !== undefined) {
                                                                  var maybeTask = WfTasks$BsConsole.assignTicket(config, {
                                                                        projectName: projectName,
                                                                        watcherName: workflow.watcher.watcherName,
                                                                        payload: Caml_option.valFromOption(json)
                                                                      });
                                                                  if (maybeTask !== undefined) {
                                                                    Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                                            return taskCompleted(resp);
                                                                          }));
                                                                  } else {
                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                                                  }
                                                                }
                                                                goForward(0);
                                                                return Promise.resolve(undefined);
                                                              })
                                                          });
                                              }));
                                        responses.push(taskPromise);
                                        
                                      }));
                        }
                        var taskPromise = new Promise((function (resolve, param) {
                                var maybeTask = WfTasks$BsConsole.assignTicket(config, {
                                      projectName: projectName,
                                      watcherName: workflow.watcher.watcherName,
                                      payload: initialFormValue
                                    });
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                return resolve(resp);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }));
                        responses.push(taskPromise);
                        return Promise.resolve(0);
                      }));
        }));
  return allRequestsSentPromise.then((function (param) {
                return Promise.all(responses);
              }));
}

function createTicketWithTask(reporting_user, fingerprint, token, workflow, projectName, onSuccess, config, param) {
  var integration = WfIntegrationFull$BsConsole.toSimple(workflow.integration);
  var watcher = workflow.watcher;
  WfMetrics$BsConsole.send(/* ManualActionOpened */Block.__(1, [
          integration,
          watcher,
          "ticket:create"
        ]));
  return dispatchAction({
              form: WorkflowUtil$BsConsole.getTicketCreate(workflow.integration),
              initialFormValue: undefined,
              onSuccess: (function (json) {
                  if (json !== undefined) {
                    WfMetrics$BsConsole.send(/* ManualActionFormSubmitted */Block.__(2, [
                            integration,
                            watcher,
                            "ticket:create"
                          ]));
                    var params_watcherName = workflow.watcher.watcherName;
                    var params_payload = Caml_option.valFromOption(json);
                    var params = {
                      fingerprint: fingerprint,
                      projectName: projectName,
                      watcherName: params_watcherName,
                      payload: params_payload,
                      reporting_user: reporting_user
                    };
                    return new Promise((function (resolve, param) {
                                  var maybeTask = WfTasks$BsConsole.createTicket(config, params);
                                  if (maybeTask !== undefined) {
                                    return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                  if (resp.tag) {
                                                    return resolve(Caml_option.some(resp[0]));
                                                  }
                                                  resolve(undefined);
                                                  return Curry._1(onSuccess, resp[0]);
                                                }));
                                  } else {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                  }
                                }));
                  }
                  Curry._1(onSuccess, undefined);
                  WfMetrics$BsConsole.send(/* ManualActionFormCancelled */Block.__(3, [
                          integration,
                          watcher,
                          "ticket:create"
                        ]));
                  return Promise.resolve(undefined);
                })
            });
}

function syncTicket(workflow, projectName, initialFormValue, sendRequest, param) {
  var integration = WfIntegrationFull$BsConsole.toSimple(workflow.integration);
  var watcher = workflow.watcher;
  var __x = Js_dict.entries(workflow.integration.actions);
  var match = Belt_Option.getExn(Caml_option.undefined_to_opt(__x.find((function (param) {
                  return param[0] === "ticket:sync";
                }))));
  var form = match[1].request;
  var onSuccessOfValidForm = function (json) {
    if (json !== undefined) {
      WfMetrics$BsConsole.send(/* ManualActionFormSubmitted */Block.__(2, [
              integration,
              watcher,
              "ticket:sync"
            ]));
      var params_watcherName = workflow.watcher.watcherName;
      var params_payload = Caml_option.valFromOption(json);
      var params = {
        projectName: projectName,
        watcherName: params_watcherName,
        payload: params_payload
      };
      Curry._1(sendRequest, params);
    } else {
      WfMetrics$BsConsole.send(/* ManualActionFormCancelled */Block.__(3, [
              integration,
              watcher,
              "ticket:sync"
            ]));
      Curry._1(sendRequest, undefined);
    }
    return Promise.resolve(undefined);
  };
  var errs = WfCore$BsConsole.validateObjectRecursively(form.schema, Json_decode.dict(Util$BsConsole.identity, initialFormValue));
  if (errs.length === 0) {
    onSuccessOfValidForm(Caml_option.some(initialFormValue));
    return ;
  } else {
    WfMetrics$BsConsole.send(/* ManualActionOpened */Block.__(1, [
            integration,
            watcher,
            "ticket:sync"
          ]));
    return dispatchAction({
                form: form,
                initialFormValue: Caml_option.some(initialFormValue),
                onSuccess: onSuccessOfValidForm
              });
  }
}

exports.sub = sub;
exports.dispatchAction = dispatchAction;
exports.createTicket = createTicket;
exports.assignTicket = assignTicket;
exports.multiTicketAssign = multiTicketAssign;
exports.createTicketWithTask = createTicketWithTask;
exports.syncTicket = syncTicket;
/* I18N-BsConsole Not a pure module */
