// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Column$BsConsole = require("../utilities/Column.js");

var component = RR$BsConsole.statelessComponent("BtList-BsConsole");

function make(classNameOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.padding2(Css.px(3), Css.px(0)),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    className,
                                    /* [] */0
                                  ]
                                ]), children));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
