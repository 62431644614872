// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var classifier = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.fontFamily("Inconsolata"),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
              /* :: */[
                Css.fontSize(Css.px(13)),
                /* :: */[
                  Css.marginRight(Css.px(4)),
                  /* :: */[
                    Css.padding2(Css.px(3), Css.px(4)),
                    /* :: */[
                      Css.borderRadius(Css.px(2)),
                      /* :: */[
                        Css.hover(/* :: */[
                              Css.filter(/* :: */[
                                    /* `brightness */[
                                      -445542959,
                                      90
                                    ],
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* :: */[
                            Css.display(/* inlineBlock */-147785676),
                            /* :: */[
                              Css.selector(":first-of-type", /* :: */[
                                    Css.maxWidth(Css.pct(100)),
                                    /* :: */[
                                      Css.whiteSpace(/* nowrap */867913355),
                                      /* :: */[
                                        Css.overflow(/* hidden */-862584982),
                                        /* :: */[
                                          Css.textOverflow(/* ellipsis */166888785),
                                          /* :: */[
                                            Css.verticalAlign(/* middle */-866200747),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "classifier__item",
        /* [] */0
      ]
    ]);

var Style = {
  classifier: classifier
};

var component = RR$BsConsole.statelessComponent("Renderer_Classifiers-BsConsole");

function make(classifiers, handleAddFilters, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return Belt_Array.mapWithIndex(classifiers, (function (idx, name) {
                            return ReasonReact.element(name + ("__" + String(idx)), undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                            /* tuple */[
                                              "classifiers",
                                              /* Contains */Block.__(2, [/* `String */[
                                                    -976970511,
                                                    name
                                                  ]])
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "classifiers",
                                                /* NotContains */Block.__(3, [/* `String */[
                                                      -976970511,
                                                      name
                                                    ]])
                                              ],
                                              /* [] */0
                                            ]
                                          ], undefined, name, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                              return React.createElement("div", {
                                                          ref: refHandler,
                                                          className: classifier,
                                                          onFocus: onFocus,
                                                          onClick: onClick
                                                        }, I18N$BsConsole.showSkip(name));
                                            }), Css.style(/* :: */[
                                                Css.zIndex(10000),
                                                /* [] */0
                                              ]), undefined, []));
                          }));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Style = Style;
exports.component = component;
exports.make = make;
/* classifier Not a pure module */
