// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

var root = Css.style(/* :: */[
      Css.fontSize(Css.em(0.75)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.orangeDark)),
        /* [] */0
      ]
    ]);

var Style = {
  root: root,
  merge: Css.merge
};

var component = ReasonReact.statelessComponent("BetaTag-BsConsole");

function make(classNameOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                }, I18N$BsConsole.show(undefined, "We're so excited to get your feedback, that we offer some features as BETA.\n              Please be mindful that there could be bugs or unfinished work,\n              and we appreciate any feedback that you offer!")), undefined, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                    className: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            className,
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip("BETA"))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(Caml_option.undefined_to_opt(reactProps.className), []);
      }));

var Jsx3 = {
  make: make$1
};

exports.Style = Style;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* root Not a pure module */
