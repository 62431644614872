// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactDropzone = require("react-dropzone");

var noStyle = { };

function make(onDrop, accept, styleOpt, children) {
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : noStyle;
  return ReasonReact.wrapJsForReason(ReactDropzone.default, {
              onDrop: onDrop,
              style: style,
              accept: accept
            }, children);
}

var Js = { };

exports.noStyle = noStyle;
exports.make = make;
exports.Js = Js;
/* ReasonReact Not a pure module */
