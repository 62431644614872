// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");

var button = Css.style(/* :: */[
      Css.width(Css.px(40)),
      /* :: */[
        Css.height(Css.px(40)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var buttonSmall = Css.style(/* :: */[
      Css.width(Css.px(36)),
      /* :: */[
        Css.height(Css.px(36)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  button: button,
  buttonSmall: buttonSmall
};

var component = RR$BsConsole.statelessComponent("BtIconButton-BsConsole");

function make(smallOpt, className, disabled, disableRipple, style, color, onClick, ariaLabel, children) {
  var small = smallOpt !== undefined ? smallOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                              Belt_Option.mapWithDefault(className, small ? buttonSmall : button, (function (cn) {
                                      return cn + (" " + (
                                                small ? buttonSmall : button
                                              ));
                                    })),
                              style,
                              onClick,
                              undefined,
                              undefined,
                              disableRipple,
                              disabled,
                              undefined,
                              undefined,
                              color,
                              ariaLabel,
                              children
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Style = Style;
exports.component = component;
exports.make = make;
/* button Not a pure module */
