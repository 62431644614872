// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var ParseError = Caml_exceptions.create("TxTask-BsConsole.ParseError");

function decode(json) {
  return {
          tx: Json_decode.field("tx", Json_decode.$$int, json)
        };
}

var Metadata = {
  decode: decode
};

function decode$1(json) {
  return {
          metadata: Json_decode.field("_", decode, json)
        };
}

var $$Response = {
  Metadata: Metadata,
  decode: decode$1
};

function makeTask(projectName) {
  var emptyBody = Json_encode.object_(/* [] */0);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [emptyBody]), undefined, (function (param) {
                var json = param.json;
                try {
                  if (param.status >= 400) {
                    var response = ApiWf$BsConsole.$$Error.fromJson(json);
                    return /* Error */Block.__(1, [[
                                ApiWf$BsConsole.ServiceError,
                                response.error
                              ]]);
                  }
                  var response$1 = decode$1(json);
                  return /* Ok */Block.__(0, [response$1]);
                }
                catch (raw_obj){
                  var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
                  if (obj[0] === Js_exn.$$Error) {
                    var obj$1 = obj[1];
                    Backtrace$BsConsole.Client.reportWithSourceModule("TxTask/makeTask", obj$1);
                    return /* Error */Block.__(1, [[
                                ParseError,
                                obj$1
                              ]]);
                  }
                  if (obj[0] === Json_decode.DecodeError) {
                    var error = new Error(obj[1]);
                    Backtrace$BsConsole.Client.reportWithSourceModule("TxTask/makeTask", error);
                    return /* Error */Block.__(1, [[
                                ParseError,
                                error
                              ]]);
                  }
                  var error$1 = Uncaught$BsConsole.castToJsExn(obj);
                  Backtrace$BsConsole.Client.reportWithSourceModule("TxTask/makeTask", error$1);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error$1
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  return Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : "Something went wrong.");
}

function use(token, projectName) {
  var match = React.useState((function () {
          return 0;
        }));
  var setKey = match[1];
  var bumpKey = function (param) {
    return Curry._1(setKey, (function (k) {
                  return k + 1 | 0;
                }));
  };
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match$1[1];
  var onSuccess = function (resp) {
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [resp.metadata.tx]);
                }));
  };
  var onFailure = function (errorMessage) {
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [errorMessage]);
                }));
  };
  React.useEffect((function () {
          Curry._1(setRemote, (function (param) {
                  return /* Loading */1;
                }));
          var arg = makeTask(projectName);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  return responseCb(onSuccess, onFailure, param);
                }));
          
        }), /* tuple */[
        match[0],
        projectName
      ]);
  return /* tuple */[
          match$1[0],
          bumpKey
        ];
}

exports.ParseError = ParseError;
exports.$$Response = $$Response;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.use = use;
/* react Not a pure module */
