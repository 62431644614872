// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");

var component = ReasonReact.statelessComponent("RangeTooltipContent-BsConsole");

function make(from, to_, value, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, /* nowrap */867913355, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.white)),
                                                      /* [] */0
                                                    ]), /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(from + (" - " + to_))]))])),
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.white)),
                                                      /* :: */[
                                                        Css.important(Css.fontWeight(/* `num */[
                                                                  5496390,
                                                                  500
                                                                ])),
                                                        /* [] */0
                                                      ]
                                                    ]), /* Body2 */-904051920, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                        className: Css.style(/* :: */[
                                                              Css.fontFamily("inconsolata"),
                                                              /* [] */0
                                                            ])
                                                      }, I18N$BsConsole.showSkip(value))]))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
