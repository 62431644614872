// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Grid = require("@vx/grid");
var Group = require("@vx/group");
var Scale = require("@vx/scale");
var Shape = require("@vx/shape");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Responsive = require("@vx/responsive");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");

function bandwidth(options) {
  var scale = Scale.scaleBand(options);
  return scale.bandwidth();
}

var Band = {
  bandwidth: bandwidth
};

var Linear = { };

var Time = { };

var Scale$1 = {
  Band: Band,
  Linear: Linear,
  Time: Time
};

function make(bsKey, children) {
  return ReasonReact.wrapJsForReason(Group.Group, {
              key: Js_null_undefined.fromOption(bsKey)
            }, children);
}

var Group$1 = {
  make: make
};

function make$1(top, numTicks, scale, width, strokeDasharray, stroke, children) {
  return ReasonReact.wrapJsForReason(Grid.GridRows, {
              lineStyle: {
                pointerEvents: "none"
              },
              top: top,
              numTicks: numTicks,
              scale: scale,
              width: width,
              strokeDasharray: strokeDasharray,
              stroke: stroke
            }, children);
}

var Rows = {
  make: make$1
};

function make$2(scale, numTicks, height, strokeDasharray, stroke, children) {
  return ReasonReact.wrapJsForReason(Grid.GridColumns, {
              lineStyle: {
                pointerEvents: "none"
              },
              numTicks: numTicks,
              scale: scale,
              height: height,
              strokeDasharray: strokeDasharray,
              stroke: stroke
            }, children);
}

var Columns = {
  make: make$2
};

var Grid$1 = {
  Rows: Rows,
  Columns: Columns
};

var Curve = { };

function make$3(data, xScale, yScale, x, y, stroke, fill, curve, children) {
  return ReasonReact.wrapJsForReason(Shape.AreaClosed, {
              data: data,
              xScale: xScale,
              yScale: yScale,
              x: x,
              y: y,
              stroke: stroke,
              fill: fill,
              curve: curve
            }, children);
}

var AreaClosed = {
  make: make$3
};

function make$4(x, y, height, width, fill, bsKey, onMouseEnterOpt, children) {
  var onMouseEnter = onMouseEnterOpt !== undefined ? onMouseEnterOpt : (function (param, param$1, param$2) {
        
      });
  return ReasonReact.wrapJsForReason(Shape.Bar, {
              key: bsKey,
              x: x,
              y: y,
              height: height,
              width: width,
              fill: fill,
              onMouseEnter: onMouseEnter
            }, children);
}

var Bar = {
  make: make$4
};

var Shape$1 = {
  AreaClosed: AreaClosed,
  Bar: Bar
};

function make$5(children) {
  return ReasonReact.wrapJsForReason(Responsive.ParentSize, {
              lol: "weeee\xc3\xaa"
            }, children);
}

var ParentSize = {
  make: make$5
};

function VX$Responsive$ParentSizeJsx3(Props) {
  var children = Props.children;
  return ReasonReact.element(undefined, undefined, make$5([children]));
}

var ParentSizeJsx3 = {
  make: VX$Responsive$ParentSizeJsx3
};

var Responsive$1 = {
  ParentSize: ParentSize,
  ParentSizeJsx3: ParentSizeJsx3
};

var $$Event = { };

exports.Scale = Scale$1;
exports.Group = Group$1;
exports.Grid = Grid$1;
exports.Curve = Curve;
exports.Shape = Shape$1;
exports.Responsive = Responsive$1;
exports.$$Event = $$Event;
/* @vx/grid Not a pure module */
