// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var ExporterMetrics$BsConsole = require("../ExporterMetrics.js");

var resource = "metrics_exports";

function fromJson(json) {
  return {
          storeId: Json_decode.field("metrics_store_id", Json_decode.string, json),
          setId: Json_decode.field("metrics_set_id", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json)
        };
}

function create(path, universeName, projectName, storeId, setId, param) {
  ExporterMetrics$BsConsole.send(/* ExportCreate */6);
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "metrics_store_id",
          storeId
        ],
        /* :: */[
          /* tuple */[
            "metrics_set_id",
            setId
          ],
          /* [] */0
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [fromJson(respBag.json)]);
              }), undefined, undefined);
}

function update(path, universeName, projectName, setId, storeId, id, param) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "metrics_set_id",
          setId
        ],
        /* :: */[
          /* tuple */[
            "metrics_store_id",
            storeId
          ],
          /* [] */0
        ]
      ]);
  ExporterMetrics$BsConsole.send(/* ExportUpdate */7);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Put */Block.__(1, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [respBag.json]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [Json_decode.list(fromJson, respBag.json)]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  ExporterMetrics$BsConsole.send(/* ExportDelete */8);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

exports.resource = resource;
exports.fromJson = fromJson;
exports.create = create;
exports.update = update;
exports.get = get;
exports.$$delete = $$delete;
/* Task2-BsConsole Not a pure module */
