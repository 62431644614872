import React from 'react';
import cx from 'classnames';
import { Line } from '@vx/shape';
import { Group } from '@vx/group';
import { Point } from '@vx/point';

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var objectWithoutProperties = function (obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

function Rows(_ref) {
  var _ref$top = _ref.top,
      top = _ref$top === undefined ? 0 : _ref$top,
      _ref$left = _ref.left,
      left = _ref$left === undefined ? 0 : _ref$left,
      scale = _ref.scale,
      width = _ref.width,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === undefined ? '#eaf0f6' : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === undefined ? 1 : _ref$strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      className = _ref.className,
      _ref$numTicks = _ref.numTicks,
      numTicks = _ref$numTicks === undefined ? 10 : _ref$numTicks,
      lineStyle = _ref.lineStyle,
      offset = _ref.offset,
      restProps = objectWithoutProperties(_ref, ['top', 'left', 'scale', 'width', 'stroke', 'strokeWidth', 'strokeDasharray', 'className', 'numTicks', 'lineStyle', 'offset']);

  var ticks = scale.ticks ? scale.ticks(numTicks) : scale.domain();
  return React.createElement(
    Group,
    { className: cx('vx-rows', className), top: top, left: left },
    ticks.map(function (d, i) {
      var y = offset ? scale(d) + offset : scale(d);
      var fromPoint = new Point({
        x: 0,
        y: y
      });
      var toPoint = new Point({
        x: width,
        y: y
      });
      return React.createElement(Line, _extends({
        key: 'row-line-' + d + '-' + i,
        from: fromPoint,
        to: toPoint,
        stroke: stroke,
        strokeWidth: strokeWidth,
        strokeDasharray: strokeDasharray,
        style: lineStyle
      }, restProps));
    })
  );
}

function Columns(_ref) {
  var _ref$top = _ref.top,
      top = _ref$top === undefined ? 0 : _ref$top,
      _ref$left = _ref.left,
      left = _ref$left === undefined ? 0 : _ref$left,
      scale = _ref.scale,
      height = _ref.height,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === undefined ? '#eaf0f6' : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === undefined ? 1 : _ref$strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      className = _ref.className,
      _ref$numTicks = _ref.numTicks,
      numTicks = _ref$numTicks === undefined ? 10 : _ref$numTicks,
      lineStyle = _ref.lineStyle,
      offset = _ref.offset,
      restProps = objectWithoutProperties(_ref, ['top', 'left', 'scale', 'height', 'stroke', 'strokeWidth', 'strokeDasharray', 'className', 'numTicks', 'lineStyle', 'offset']);

  var ticks = scale.ticks ? scale.ticks(numTicks) : scale.domain();
  return React.createElement(
    Group,
    { className: cx('vx-columns', className), top: top, left: left },
    ticks.map(function (d, i) {
      var x = offset ? scale(d) + offset : scale(d);
      var fromPoint = new Point({
        x: x,
        y: 0
      });
      var toPoint = new Point({
        x: x,
        y: height
      });
      return React.createElement(Line, _extends({
        key: 'column-line-' + d + '-' + i,
        from: fromPoint,
        to: toPoint,
        stroke: stroke,
        strokeWidth: strokeWidth,
        strokeDasharray: strokeDasharray,
        style: lineStyle
      }, restProps));
    })
  );
}

function Grid(_ref) {
  var top = _ref.top,
      left = _ref.left,
      xScale = _ref.xScale,
      yScale = _ref.yScale,
      width = _ref.width,
      height = _ref.height,
      className = _ref.className,
      stroke = _ref.stroke,
      strokeWidth = _ref.strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      numTicksRows = _ref.numTicksRows,
      numTicksColumns = _ref.numTicksColumns,
      rowLineStyle = _ref.rowLineStyle,
      columnLineStyle = _ref.columnLineStyle,
      xOffset = _ref.xOffset,
      yOffset = _ref.yOffset,
      restProps = objectWithoutProperties(_ref, ['top', 'left', 'xScale', 'yScale', 'width', 'height', 'className', 'stroke', 'strokeWidth', 'strokeDasharray', 'numTicksRows', 'numTicksColumns', 'rowLineStyle', 'columnLineStyle', 'xOffset', 'yOffset']);

  return React.createElement(
    Group,
    { className: cx('vx-grid', className), top: top, left: left },
    React.createElement(Rows, _extends({
      className: className,
      scale: yScale,
      width: width,
      stroke: stroke,
      strokeWidth: strokeWidth,
      strokeDasharray: strokeDasharray,
      numTicks: numTicksRows,
      style: rowLineStyle,
      offset: yOffset
    }, restProps)),
    React.createElement(Columns, _extends({
      className: className,
      scale: xScale,
      height: height,
      stroke: stroke,
      strokeWidth: strokeWidth,
      strokeDasharray: strokeDasharray,
      numTicks: numTicksColumns,
      style: columnLineStyle,
      offset: xOffset
    }, restProps))
  );
}

export { Rows as GridRows, Columns as GridColumns, Grid };
