// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");

function ExporterDeleteModal(Props) {
  var _open = Props.open;
  var onClose = Props.onClose;
  var handleDelete = Props.handleDelete;
  var title = Props.title;
  var itemName = Props.itemName;
  var modalWidthOpt = Props.modalWidth;
  var modalWidth = modalWidthOpt !== undefined ? modalWidthOpt : 320;
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: onClose,
              modalWidth: modalWidth,
              title: title,
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(5)),
                              /* :: */[
                                Css.marginBottom(Css.px(15)),
                                /* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: I18N$BsConsole.dynamic("Are you sure you want to delete this " + (itemName + "?"))
                      }), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: false,
                            onClick: (function (param) {
                                Curry._1(handleDelete, undefined);
                                return Curry._1(onClose, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Delete")
                          }), React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var make = ExporterDeleteModal;

exports.make = make;
/* Css Not a pure module */
