// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugTreeNode$BsConsole = require("./BugTreeNode.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BugTreeValueRow$BsConsole = require("./BugTreeValueRow.js");
var Collapse = require("@material-ui/core/Collapse");
var BugTreeExpandIcon$BsConsole = require("./BugTreeExpandIcon.js");
var BugTreeIndentationGuide$BsConsole = require("./BugTreeIndentationGuide.js");

var allocationStatRegexp = /State": "(active|free)/;

function getAllocationState(annotation) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(Caml_option.null_to_opt(allocationStatRegexp.exec(annotation.text)), (function (prim) {
                            return prim;
                          })), (function (captures) {
                        return Belt_Array.get(captures, 1);
                      })), (function (prim) {
                    if (prim == null) {
                      return ;
                    } else {
                      return Caml_option.some(prim);
                    }
                  })), (function (param) {
                switch (param) {
                  case "active" :
                      return /* Active */1;
                  case "free" :
                      return /* Free */0;
                  default:
                    return ;
                }
              }));
}

var AllocationState = {
  allocationStatRegexp: allocationStatRegexp,
  getAllocationState: getAllocationState
};

function makeTree(currDepthOpt, variables) {
  var currDepth = currDepthOpt !== undefined ? currDepthOpt : 0;
  var tree = [];
  var currNode = {
    contents: undefined
  };
  var currChildren = {
    contents: []
  };
  Belt_Array.forEach(variables, (function (variable) {
          if (Caml_format.caml_int_of_string(variable.depth) === currDepth) {
            var node = currNode.contents;
            if (node !== undefined) {
              tree.push({
                    node: node,
                    children: makeTree(currDepth + 1 | 0, currChildren.contents)
                  });
            }
            currNode.contents = variable;
            currChildren.contents = [];
            return ;
          }
          currChildren.contents.push(variable);
          
        }));
  var node = currNode.contents;
  if (node !== undefined) {
    tree.push({
          node: node,
          children: makeTree(currDepth + 1 | 0, currChildren.contents)
        });
  }
  return tree;
}

function BugVariablesTree$VariableRow(Props) {
  var annotationLevel = Props.annotationLevel;
  var allocationState = Props.allocationState;
  var closeIndentation = Props.closeIndentation;
  var duration = Props.duration;
  var variable = Props.variable;
  var paneFocused = Props.paneFocused;
  var selectedVariable = Props.selectedVariable;
  var setSelectedVariable = Props.setSelectedVariable;
  var onToggleExpanded = Props.onToggleExpanded;
  var expanded = Props.expanded;
  var hasChildren = Props.hasChildren;
  var match = variable.value;
  var match$1 = variable.error;
  var match$2 = variable.hints;
  var match$3 = variable.symbol;
  var tmp;
  var exit = 0;
  if (match$2 !== undefined && match$3 !== undefined && match$2.includes("STRING")) {
    tmp = React.createElement("span", {
          className: Css.style(/* :: */[
                Css.lineHeight(Css.px(11)),
                /* :: */[
                  Css.fontSize(Css.px(11)),
                  /* :: */[
                    Css.padding2(Css.px(3), Css.px(6)),
                    /* :: */[
                      Css.marginTop(Css.px(2)),
                      /* :: */[
                        Css.borderRadius(Css.px(4)),
                        /* :: */[
                          Css.background(Css.hex(Colors$BsConsole.orangeExtraLight)),
                          /* :: */[
                            Css.whiteSpace(Css.nowrap),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blueDark)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ])
        }, I18N$BsConsole.showSkip(match$3));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = match !== undefined ? I18N$BsConsole.showSkip(match) : (
        match$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey4)),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.show(undefined, "<optimized out>"))) : null
      );
  }
  var tmp$1;
  if (annotationLevel !== undefined) {
    switch (annotationLevel) {
      case "Critical" :
          tmp$1 = Colors$BsConsole.red;
          break;
      case "Warning" :
          tmp$1 = Colors$BsConsole.orangeDark;
          break;
      default:
        tmp$1 = undefined;
    }
  } else {
    tmp$1 = undefined;
  }
  var node = BugTreeNode$BsConsole.make(Belt_Option.getWithDefault(variable.name, ""), Caml_option.some(tmp), hasChildren ? [undefined] : [], Belt_Option.getExn(Belt_Int.fromString(variable.depth)), allocationState !== undefined ? Caml_option.some(React.createElement(Tooltip.default, {
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.px(4)),
                              /* :: */[
                                Css.height(Css.px(5)),
                                /* :: */[
                                  Css.width(Css.px(5)),
                                  /* :: */[
                                    Css.borderRadius(Css.pct(50)),
                                    /* :: */[
                                      Css.background(Css.hex(allocationState ? Colors$BsConsole.tagPink : Colors$BsConsole.green)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }),
                  title: allocationState ? "Active" : "Free"
                })) : undefined, undefined, tmp$1, undefined);
  var tmp$2 = {
    node: node,
    closeIndentation: closeIndentation,
    paneFocused: paneFocused,
    isSelected: Caml_obj.caml_equal(variable, selectedVariable),
    onClick: (function (param) {
        return Curry._1(setSelectedVariable, variable);
      }),
    onToggleExpanded: onToggleExpanded,
    expanded: expanded
  };
  if (duration !== undefined) {
    tmp$2.duration = Caml_option.valFromOption(duration);
  }
  return React.createElement(BugTreeValueRow$BsConsole.make, tmp$2);
}

var VariableRow = {
  make: BugVariablesTree$VariableRow
};

function BugVariablesTree$InlineAnnotation(Props) {
  var annotation = Props.annotation;
  var depth = Props.depth;
  var closeIndentation = Props.closeIndentation;
  var match = annotation.type;
  switch (match) {
    case "Comment" :
    case "Critical" :
    case "Warning" :
        break;
    default:
      return null;
  }
  var match$1 = annotation.type;
  var tmp;
  switch (match$1) {
    case "Comment" :
        tmp = Colors$BsConsole.blueDark;
        break;
    case "Critical" :
        tmp = Colors$BsConsole.red;
        break;
    case "Warning" :
        tmp = Colors$BsConsole.orangeDark;
        break;
    default:
      tmp = Colors$BsConsole.greenDark;
  }
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.height(Css.px(20)),
                    /* :: */[
                      Css.paddingLeft(Css.px(4)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BugTreeIndentationGuide$BsConsole.make, {
                  depth: depth,
                  closeIndentation: closeIndentation,
                  annotation: true
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginLeft(Css.px(12)),
                        /* :: */[
                          Css.lineHeight(Css.px(11)),
                          /* :: */[
                            Css.fontSize(Css.px(11)),
                            /* :: */[
                              Css.padding2(Css.px(3), Css.px(6)),
                              /* :: */[
                                Css.marginTop(Css.px(2)),
                                /* :: */[
                                  Css.borderRadius(Css.px(4)),
                                  /* :: */[
                                    Css.background(Css.hex(Colors$BsConsole.orangeExtraLight)),
                                    /* :: */[
                                      Css.whiteSpace(Css.nowrap),
                                      /* :: */[
                                        Css.color(Css.hex(tmp)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("b", undefined, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      ": ",
                                      /* End_of_format */0
                                    ])
                                ]),
                              "%s: "
                            ]), annotation.type)), I18N$BsConsole.showSkip(annotation.text)));
}

var InlineAnnotation = {
  make: BugVariablesTree$InlineAnnotation
};

function getDescendantCount(treeNode) {
  var directChildrenCount = treeNode.children.length;
  var otherChildrenCount = Belt_Array.reduce(treeNode.children, 0, (function (acc, treeNode) {
          return acc + getDescendantCount(treeNode) | 0;
        }));
  return directChildrenCount + otherChildrenCount | 0;
}

function make(props) {
  var tree = props.tree;
  var selectedVariable = props.selectedVariable;
  var setSelectedVariable = props.setSelectedVariable;
  var closeIndentation = props.closeIndentation;
  var annotationsDict = props.annotationsDict;
  var onlyChild = props.onlyChild;
  var paneFocused = props.paneFocused;
  var match = React.useState((function () {
          if (onlyChild) {
            return true;
          }
          
        }));
  var setExpanded = match[1];
  var childCount = tree.children.length;
  var descendantCount = getDescendantCount(tree);
  var expanded = childCount !== 0 ? match[0] : undefined;
  var duration = 150 * (1 + 1.5 * descendantCount / (descendantCount + 10)) | 0;
  var annotations = Js_dict.get(annotationsDict, tree.node.id);
  var allocationState = Belt_Option.flatMap(annotations, (function (annotations) {
          return Belt_Array.reduce(annotations, undefined, (function (acc, v) {
                        if (acc !== undefined) {
                          return acc;
                        } else {
                          return getAllocationState(v);
                        }
                      }));
        }));
  var depth = Caml_format.caml_int_of_string(tree.node.depth);
  var rootCloseIndentation = Caml_obj.caml_equal(expanded, true) ? 0 : closeIndentation;
  var annotationLevel = annotations !== undefined ? Belt_Array.reduce(annotations, "JSON", (function (acc, annotation) {
            var match = annotation.type;
            if (acc === "Critical") {
              return "Critical";
            }
            switch (match) {
              case "Critical" :
                  return "Critical";
              case "Warning" :
                  return "Warning";
              default:
                switch (acc) {
                  case "Comment" :
                      return "Comment";
                  case "Warning" :
                      return "Warning";
                  default:
                    if (match === "Comment") {
                      return "Comment";
                    } else {
                      return "JSON";
                    }
                }
            }
          })) : undefined;
  var tmp = {
    allocationState: allocationState,
    closeIndentation: rootCloseIndentation,
    duration: duration,
    variable: tree.node,
    paneFocused: paneFocused,
    selectedVariable: selectedVariable,
    setSelectedVariable: setSelectedVariable,
    onToggleExpanded: (function (param) {
        return Curry._1(setExpanded, (function (expanded) {
                      if (expanded !== undefined) {
                        return !expanded;
                      } else {
                        setTimeout((function (param) {
                                return Curry._1(setExpanded, (function (param) {
                                              return true;
                                            }));
                              }), 0);
                        return false;
                      }
                    }));
      }),
    expanded: Caml_obj.caml_equal(expanded, true),
    hasChildren: tree.children.length !== 0
  };
  if (annotationLevel !== undefined) {
    tmp.annotationLevel = Caml_option.valFromOption(annotationLevel);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BugVariablesTree$VariableRow, tmp), annotations !== undefined ? Belt_Array.mapWithIndex(annotations, (function (idx, annotation) {
                      return React.createElement(BugVariablesTree$InlineAnnotation, {
                                  annotation: annotation,
                                  depth: depth,
                                  closeIndentation: rootCloseIndentation,
                                  key: String(idx)
                                });
                    })) : null, expanded !== undefined ? React.createElement(Collapse.default, {
                    in: expanded,
                    timeout: duration,
                    children: React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ])
                        }, Belt_Array.mapWithIndex(tree.children, (function (idx, tree) {
                                var tmp = {
                                  paneFocused: paneFocused,
                                  annotationsDict: annotationsDict,
                                  tree: tree,
                                  closeIndentation: idx === (childCount - 1 | 0) ? closeIndentation + 1 | 0 : 0,
                                  setSelectedVariable: setSelectedVariable,
                                  onlyChild: childCount === 1,
                                  key: String(idx)
                                };
                                if (selectedVariable !== undefined) {
                                  tmp.selectedVariable = Caml_option.valFromOption(selectedVariable);
                                }
                                return React.createElement(make, tmp);
                              }))),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  }) : null);
}

var $$Node = {
  getDescendantCount: getDescendantCount,
  make: make
};

function BugVariablesTree(Props) {
  var annotationsDict = Props.annotationsDict;
  var title = Props.title;
  var tree = Props.tree;
  var selectedVariable = Props.selectedVariable;
  var setSelectedVariable = Props.setSelectedVariable;
  var initialExpandedOpt = Props.initialExpanded;
  var paneFocused = Props.paneFocused;
  var initialExpanded = initialExpandedOpt !== undefined ? initialExpandedOpt : false;
  var match = React.useState((function () {
          return initialExpanded;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.padding2(Css.px(8), Css.zero),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* :: */[
                            Css.padding2(Css.px(4), Css.zero),
                            /* :: */[
                              Css.paddingLeft(Css.px(4)),
                              /* :: */[
                                Css.fontWeight(Css.medium),
                                /* :: */[
                                  Css.fontFamily("Roboto"),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(setExpanded, (function (prim) {
                                    return !prim;
                                  }));
                    }),
                  children: null
                }, React.createElement(BugTreeExpandIcon$BsConsole.make, {
                      onClick: (function (param) {
                          
                        }),
                      expanded: expanded
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.padding2(Css.px(4), Css.zero),
                            /* :: */[
                              Css.textTransform(Css.uppercase),
                              /* [] */0
                            ]
                          ])
                    }, I18N$BsConsole.showSkip(title))), React.createElement(Collapse.default, {
                  in: expanded,
                  timeout: 200,
                  children: Belt_Array.mapWithIndex(tree, (function (idx, treeNode) {
                          var tmp = {
                            paneFocused: paneFocused,
                            annotationsDict: annotationsDict,
                            tree: treeNode,
                            closeIndentation: idx === (tree.length - 1 | 0) ? 1 : 0,
                            setSelectedVariable: setSelectedVariable,
                            onlyChild: tree.length === 1,
                            key: String(idx)
                          };
                          if (selectedVariable !== undefined) {
                            tmp.selectedVariable = Caml_option.valFromOption(selectedVariable);
                          }
                          return React.createElement(make, tmp);
                        })),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ])
                }));
}

var make$1 = React.memo(BugVariablesTree);

function BugVariablesTree$Empty(Props) {
  var title = Props.title;
  var text = Props.text;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.padding2(Css.px(8), Css.zero),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.padding2(Css.px(4), Css.zero),
                        /* :: */[
                          Css.paddingLeft(Css.px(22)),
                          /* :: */[
                            Css.fontWeight(Css.medium),
                            /* :: */[
                              Css.fontFamily("Roboto"),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.padding2(Css.px(4), Css.zero),
                              /* :: */[
                                Css.textTransform(Css.uppercase),
                                /* [] */0
                              ]
                            ])
                      }, I18N$BsConsole.showSkip(title))
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.paddingLeft(Css.px(22)),
                        /* :: */[
                          Css.paddingTop(Css.px(4)),
                          /* :: */[
                            Css.fontWeight(Css.medium),
                            /* :: */[
                              Css.fontFamily("Roboto"),
                              /* :: */[
                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                /* :: */[
                                  Css.fontStyle(Css.italic),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.showSkip(text)));
}

var Empty = {
  make: BugVariablesTree$Empty
};

exports.AllocationState = AllocationState;
exports.makeTree = makeTree;
exports.VariableRow = VariableRow;
exports.InlineAnnotation = InlineAnnotation;
exports.$$Node = $$Node;
exports.make = make$1;
exports.Empty = Empty;
/* make Not a pure module */
