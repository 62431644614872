// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("./Task2.js");

var $$default = {
  userProvisioning: false
};

function decode(json) {
  return {
          userProvisioning: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("userProvisioning", Json_decode.bool, param);
                    }), json), false)
        };
}

function encode(settings) {
  return JSON.stringify(Json_encode.object_(/* :: */[
                  /* tuple */[
                    "userProvisioning",
                    settings.userProvisioning
                  ],
                  /* [] */0
                ]));
}

var Settings = {
  $$default: $$default,
  decode: decode,
  encode: encode
};

function signatureAlgorithmToString(param) {
  if (param !== 486188135) {
    if (param >= 486336426) {
      return "sha512";
    } else {
      return "sha1";
    }
  } else {
    return "sha256";
  }
}

function signatureAlgorithmFromString(param) {
  switch (param) {
    case "sha1" :
        return /* sha1 */-866989947;
    case "sha256" :
        return /* sha256 */486188135;
    case "sha512" :
        return /* sha512 */486336426;
    default:
      return /* sha1 */-866989947;
  }
}

function decode$1(json) {
  var signature = Json_decode.optional((function (param) {
          return Json_decode.field("signatureAlgorithm", Json_decode.string, param);
        }), json);
  var jsonString = Json_decode.optional((function (param) {
          return Json_decode.field("settings", Json_decode.string, param);
        }), json);
  var tmp;
  if (jsonString !== undefined) {
    try {
      tmp = decode(JSON.parse(jsonString));
    }
    catch (exn){
      tmp = $$default;
    }
  } else {
    tmp = $$default;
  }
  return {
          hostname: Json_decode.field("hostname", Json_decode.string, json),
          issuer: Json_decode.field("issuer", Json_decode.string, json),
          entryPoint: Json_decode.field("entryPoint", Json_decode.string, json),
          cert: Json_decode.optional((function (param) {
                  return Json_decode.field("cert", Json_decode.string, param);
                }), json),
          decryptionPvk: Json_decode.optional((function (param) {
                  return Json_decode.field("decryptionPvk", Json_decode.string, param);
                }), json),
          adminContact: Json_decode.optional((function (param) {
                  return Json_decode.field("adminContact", Json_decode.string, param);
                }), json),
          callbackUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("callbackUrl", Json_decode.string, param);
                }), json),
          privateCert: Json_decode.optional((function (param) {
                  return Json_decode.field("privateCert", Json_decode.string, param);
                }), json),
          signatureAlgorithm: signature !== undefined ? signatureAlgorithmFromString(signature) : undefined,
          settings: tmp
        };
}

function encode$1(tenant) {
  var __x = tenant.cert;
  var __x$1 = tenant.privateCert;
  var algorithm = tenant.signatureAlgorithm;
  var __x$2 = algorithm !== undefined ? signatureAlgorithmToString(algorithm) : undefined;
  var __x$3 = tenant.decryptionPvk;
  var __x$4 = tenant.adminContact;
  return Json_encode.object_(Belt_List.concat(Belt_List.map(Belt_List.keepMap(/* :: */[
                          /* tuple */[
                            "hostname",
                            tenant.hostname
                          ],
                          /* :: */[
                            /* tuple */[
                              "issuer",
                              tenant.issuer
                            ],
                            /* :: */[
                              /* tuple */[
                                "entryPoint",
                                tenant.entryPoint
                              ],
                              /* :: */[
                                /* tuple */[
                                  "callbackUrl",
                                  tenant.callbackUrl
                                ],
                                /* [] */0
                              ]
                            ]
                          ]
                        ], (function (param) {
                            var v = param[1];
                            if (v !== undefined) {
                              return /* tuple */[
                                      param[0],
                                      v
                                    ];
                            }
                            
                          })), (function (param) {
                        return /* tuple */[
                                param[0],
                                param[1]
                              ];
                      })), /* :: */[
                  /* tuple */[
                    "settings",
                    encode(tenant.settings)
                  ],
                  /* :: */[
                    /* tuple */[
                      "cert",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), __x)
                    ],
                    /* :: */[
                      /* tuple */[
                        "privateCert",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), __x$1)
                      ],
                      /* :: */[
                        /* tuple */[
                          "signatureAlgorithm",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), __x$2)
                        ],
                        /* :: */[
                          /* tuple */[
                            "decryptionPvk",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), __x$3)
                          ],
                          /* :: */[
                            /* tuple */[
                              "adminContact",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), __x$4)
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

var Tenant = {
  signatureAlgorithmToString: signatureAlgorithmToString,
  signatureAlgorithmFromString: signatureAlgorithmFromString,
  decode: decode$1,
  encode: encode$1
};

function decode$2(json) {
  return {
          version: Json_decode.field("version", Json_decode.string, json)
        };
}

var Metadata = {
  decode: decode$2
};

function decodeSuccess(json) {
  return {
          status: Json_decode.field("response", (function (json) {
                  return Json_decode.field("status", Json_decode.string, json);
                }), json),
          metadata: Json_decode.field("_", decode$2, json)
        };
}

function decodeError(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json),
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

function decode$3(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  return /* Ok */Block.__(0, [decodeSuccess(json)]);
                }),
              /* :: */[
                (function (json) {
                    var error = decodeError(json);
                    return /* Error */Block.__(1, [[
                                Task2$BsConsole.ErrorMsg,
                                error.message
                              ]]);
                  }),
                /* [] */0
              ]
            ], json);
}

var $$Response = {
  decodeSuccess: decodeSuccess,
  decodeError: decodeError,
  decode: decode$3
};

function decode$4(json) {
  return {
          tenant: Json_decode.field("response", (function (json$prime) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("tenant", decode$1, param);
                              }), json$prime);
                }), json),
          metadata: Json_decode.field("_", decode$2, json)
        };
}

exports.Settings = Settings;
exports.Tenant = Tenant;
exports.Metadata = Metadata;
exports.$$Response = $$Response;
exports.decode = decode$4;
/* Task2-BsConsole Not a pure module */
