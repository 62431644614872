// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");

function filterBySupportedAction(workflows, actionName) {
  return Belt_Array.keep(workflows, (function (param) {
                return Belt_Array.some(Js_dict.entries(param.integration.actions), (function (param) {
                              return param[0] === actionName;
                            }));
              }));
}

exports.filterBySupportedAction = filterBySupportedAction;
/* No side effect */
