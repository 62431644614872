// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");

var component = ReasonReact.statelessComponent("Renderer_String-BsConsole");

var tooltip = Css.style(/* :: */[
      Css.fontSize(Css.em(0.75)),
      /* :: */[
        Css.maxWidth(Css.vw(75)),
        /* [] */0
      ]
    ]);

var string = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.textOverflow(/* ellipsis */166888785),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.display(/* inlineBlock */-147785676),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  tooltip: tooltip,
  string: string
};

function make(attribute, value, handleAddFilters, handleChangeUrl, classNameOpt, plainTextViewOpt, _children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var plainTextView = plainTextViewOpt !== undefined ? plainTextViewOpt : false;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(className, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                        /* tuple */[
                                          attribute,
                                          /* Equal */Block.__(0, [/* `String */[
                                                -976970511,
                                                value
                                              ]])
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            attribute,
                                            /* NotEqual */Block.__(1, [/* `String */[
                                                  -976970511,
                                                  value
                                                ]])
                                          ],
                                          /* [] */0
                                        ]
                                      ], undefined, value, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                          var tmp;
                                          if (plainTextView) {
                                            tmp = React.createElement("div", {
                                                  className: Styles$BsConsole.$$Text.data
                                                }, I18N$BsConsole.showSkip(value));
                                          } else {
                                            var partial_arg = string;
                                            var partial_arg$1 = {
                                              fontWeight: "normal",
                                              minWidth: "unset",
                                              textTransform: "none",
                                              width: "100%"
                                            };
                                            var arg = function (param, param$1) {
                                              return ValueDrilldown$BsConsole.wrapWithButton(onClick, partial_arg$1, partial_arg, param, param$1);
                                            };
                                            tmp = Curry._2(arg, undefined, I18N$BsConsole.showSkip(value));
                                          }
                                          return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, {
                                                          tooltip: tooltip
                                                        }, React.createElement("span", {
                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                            }, I18N$BsConsole.showSkip(value)), undefined, undefined, 1000, 100, undefined, [React.createElement("div", {
                                                                ref: refHandler,
                                                                className: string,
                                                                onFocus: onFocus,
                                                                onClick: onClick
                                                              }, tmp)]));
                                        }), undefined, undefined, []))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var GlobalStyles;

exports.component = component;
exports.GlobalStyles = GlobalStyles;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
