// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../../crdb.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var AlertsRouter$BsConsole = require("../../project-settings/alerts/AlertsRouter.js");
var AlertsMetrics$BsConsole = require("../AlertsMetrics.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");

var resource = "alerts";

function aggregationFromJson(json) {
  return {
          column: Json_decode.field("column", Json_decode.string, json),
          index: Json_decode.field("index", Json_decode.$$int, json)
        };
}

function triggerFromJson(json) {
  return {
          aggregation: Json_decode.field("aggregation", aggregationFromJson, json),
          comparisonOperator: Json_decode.field("comparison_operator", Json_decode.string, json),
          warningThreshold: Json_decode.field("warning_threshold", Json_decode.$$float, json),
          criticalThreshold: Json_decode.field("critical_threshold", Json_decode.$$float, json)
        };
}

function alertFromJson(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json),
          queryPeriod: Json_decode.field("query_period", Json_decode.$$float, json),
          minNotificationInterval: Json_decode.field("min_notification_interval", Json_decode.$$float, json),
          muteUntil: Json_decode.field("mute_until", Json_decode.$$float, json)
        };
}

function fullAlertFromJson(json) {
  return {
          universe: Json_decode.field("universe", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json),
          queryPeriod: Json_decode.field("query_period", Json_decode.$$float, json),
          minNotificationInterval: Json_decode.field("min_notification_interval", Json_decode.$$float, json),
          muteUntil: Json_decode.field("mute_until", Json_decode.$$float, json),
          query: Json_decode.field("query", Json_decode.string, json),
          triggers: Json_decode.field("triggers", (function (param) {
                  return Json_decode.list(triggerFromJson, param);
                }), json),
          targets: Json_decode.field("targets", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json)
        };
}

function aggregationToJson(param) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "column",
                param.column
              ],
              /* :: */[
                /* tuple */[
                  "index",
                  param.index
                ],
                /* [] */0
              ]
            ]);
}

function triggerToJson(param) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "aggregation",
                aggregationToJson(param.aggregation)
              ],
              /* :: */[
                /* tuple */[
                  "comparison_operator",
                  param.comparisonOperator
                ],
                /* :: */[
                  /* tuple */[
                    "warning_threshold",
                    param.warningThreshold
                  ],
                  /* :: */[
                    /* tuple */[
                      "critical_threshold",
                      param.criticalThreshold
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function alertsFromJson(json) {
  return {
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.list(alertFromJson, param);
                }), json)
        };
}

function create(from, path, universeName, projectName, name, triggers, query, queryPeriod, muteUntil, minNotificationInterval, enabled, targets, param) {
  AlertsMetrics$BsConsole.send(/* AlertCreate */[from]);
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "query",
            JSON.stringify(Crdb$BsConsole.QueryManager.toCrdbTimeless(query))
          ],
          /* :: */[
            /* tuple */[
              "query_period",
              queryPeriod
            ],
            /* :: */[
              /* tuple */[
                "mute_until",
                muteUntil
              ],
              /* :: */[
                /* tuple */[
                  "min_notification_interval",
                  minNotificationInterval
                ],
                /* :: */[
                  /* tuple */[
                    "enabled",
                    enabled
                  ],
                  /* :: */[
                    /* tuple */[
                      "targets",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), targets)
                    ],
                    /* :: */[
                      /* tuple */[
                        "triggers",
                        Json_encode.list(triggerToJson, triggers)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [alertFromJson(respBag.json)]);
              }), undefined, undefined);
}

function update(path, universeName, projectName, id, name, triggers, query, queryPeriod, muteUntil, minNotificationInterval, enabled, targets, param) {
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "query",
            query
          ],
          /* :: */[
            /* tuple */[
              "query_period",
              queryPeriod
            ],
            /* :: */[
              /* tuple */[
                "mute_until",
                muteUntil
              ],
              /* :: */[
                /* tuple */[
                  "min_notification_interval",
                  minNotificationInterval
                ],
                /* :: */[
                  /* tuple */[
                    "enabled",
                    enabled
                  ],
                  /* :: */[
                    /* tuple */[
                      "targets",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), targets)
                    ],
                    /* :: */[
                      /* tuple */[
                        "triggers",
                        Json_encode.list(triggerToJson, triggers)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  AlertsMetrics$BsConsole.send(/* AlertUpdate */3);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Put */Block.__(1, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [fullAlertFromJson(respBag.json)]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [alertsFromJson(respBag.json)]);
              }), undefined, undefined);
}

function getById(path, universeName, projectName, id, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [fullAlertFromJson(respBag.json)]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  AlertsMetrics$BsConsole.send(/* AlertDelete */4);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function saveAlert(from, token, path, universeName, projectName, handleChangeUrl, alertSettings, afterSave, param) {
  if (alertSettings.tag) {
    var arg = update(path, universeName, projectName, alertSettings[0], alertSettings[1], alertSettings[2], JSON.stringify(Crdb$BsConsole.QueryManager.toCrdbTimeless(alertSettings[3])), alertSettings[4], alertSettings[5], alertSettings[6], alertSettings[7], alertSettings[8], undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Success editing alert!"));
                  if (afterSave !== undefined) {
                    return Curry._1(afterSave, undefined);
                  }
                  
                }));
  }
  var arg$1 = create(from, path, universeName, projectName, alertSettings[0], alertSettings[1], alertSettings[2], alertSettings[3], alertSettings[4], alertSettings[5], alertSettings[6], alertSettings[7], undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, resp[0])));
                }
                var result = resp[0];
                SnackPurveyor$BsConsole.enqueue({
                      label: "View alert",
                      onClick: (function (param) {
                          SnackPurveyor$BsConsole.close(undefined);
                          return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                        projectName,
                                        {
                                          tab: AlertsRouter$BsConsole.typeToString(/* ErrorThresholdAlerts */0),
                                          id: result.id
                                        }
                                      ]));
                        })
                    }, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Success creating alert!"));
                if (afterSave !== undefined) {
                  return Curry._1(afterSave, undefined);
                }
                
              }));
}

exports.resource = resource;
exports.aggregationFromJson = aggregationFromJson;
exports.triggerFromJson = triggerFromJson;
exports.alertFromJson = alertFromJson;
exports.fullAlertFromJson = fullAlertFromJson;
exports.aggregationToJson = aggregationToJson;
exports.triggerToJson = triggerToJson;
exports.alertsFromJson = alertsFromJson;
exports.create = create;
exports.update = update;
exports.get = get;
exports.getById = getById;
exports.$$delete = $$delete;
exports.saveAlert = saveAlert;
/* Crdb-BsConsole Not a pure module */
