// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var XML_FormData = { };

function withCredentials(t) {
  return ((function(r){
      t.withCredentials=true;
      return t;
    }))(t);
}

function addOnProgressListener(onProgress, request) {
  return ((function(onProgress,request) {
          request.upload.addEventListener("progress", function(e) {
            var loaded = e.loaded;
            var total = e.total;
            var timeStamp = e.timeStamp;
            onProgress([loaded, total, timeStamp]);
          });
        }))(onProgress, request);
}

function sendForm(form, request) {
  request.send(form);
  
}

function getResponse(request) {
  return request.response;
}

function getStatusText(request) {
  return request.statusText;
}

function getReadyState(request) {
  var match = request.readyState;
  if (match > 4 || match < 0) {
    return /* Loading */3;
  } else {
    return match;
  }
}

function setOnReadyStateChange(behavior, request) {
  request.onreadystatechange = behavior;
  
}

function setOnError(behavior, request) {
  request.onreadystatechange = behavior;
  
}

var XML_HttpRequest = {
  withCredentials: withCredentials,
  addOnProgressListener: addOnProgressListener,
  sendForm: sendForm,
  getResponse: getResponse,
  getStatusText: getStatusText,
  getReadyState: getReadyState,
  setOnReadyStateChange: setOnReadyStateChange,
  setOnError: setOnError
};

exports.XML_FormData = XML_FormData;
exports.XML_HttpRequest = XML_HttpRequest;
/* No side effect */
