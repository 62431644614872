// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var BtTypography$BsConsole = require("../BtTypography.js");

var component = ReasonReact.statelessComponent("FixedAttributeCard-BsConsole");

function make($staropt$star, $staropt$star$1, $staropt$star$2, _children) {
  $staropt$star !== undefined;
  var title = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var content = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : I18N$BsConsole.showEmpty(undefined);
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.minWidth(Css.px(300)),
                                  /* :: */[
                                    Css.width(Css.pct(33.33)),
                                    /* [] */0
                                  ]
                                ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                            Css.padding(Css.px(12)),
                                            /* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                                              /* :: */[
                                                Css.marginRight(Css.rem(1)),
                                                /* :: */[
                                                  Css.marginBottom(Css.rem(1)),
                                                  /* :: */[
                                                    Css.minHeight(Css.px(110)),
                                                    /* :: */[
                                                      Css.height(Css.pct(100)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]), undefined, undefined, 0, [
                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(title)]))])),
                                        ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                      Css.paddingTop(Css.px(10)),
                                                      /* [] */0
                                                    ]), [content]))
                                      ]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
