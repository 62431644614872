// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Format$BsConsole = require("./Format.js");
var ObjectID$BsConsole = require("../../ObjectID.js");
var ResponseUtil$BsConsole = require("./ResponseUtil.js");

function unwrapObjects(json) {
  var response$prime = Json_decode.field("response", ResponseUtil$BsConsole.identity, json);
  var objects = Json_decode.field("objects", ResponseUtil$BsConsole.unwrap, response$prime);
  if (objects !== undefined) {
    return ObjectID$BsConsole.decodeDeltaList(Belt_List.getExn(Belt_List.keep(Json_decode.list(ResponseUtil$BsConsole.identity, Caml_option.valFromOption(objects)), (function (v) {
                          return Caml_obj.caml_notequal(v, "*");
                        })), 0));
  } else {
    return /* [] */0;
  }
}

function unwrapCount(json) {
  return Belt_List.length(unwrapObjects(json));
}

function correlate(json, columns) {
  var response$prime = Json_decode.field("response", ResponseUtil$BsConsole.identity, json);
  var values = Json_decode.field("values", (function (param) {
          return Json_decode.list(ResponseUtil$BsConsole.identity, param);
        }), response$prime);
  var parsedValues = Belt_List.mapWithIndex(columns, (function (i, column) {
          var values$prime = Belt_List.get(values, i);
          if (values$prime === undefined) {
            return /* :: */[
                    /* tuple */[
                      column,
                      null
                    ],
                    /* [] */0
                  ];
          }
          var values$prime$prime$prime = Belt_List.keep(Json_decode.list(ResponseUtil$BsConsole.identity, Caml_option.valFromOption(values$prime)), (function (j) {
                  return Caml_obj.caml_notequal(j, "*");
                }));
          return Belt_List.reduce(values$prime$prime$prime, /* [] */0, (function (acc, v) {
                        var match = Json_decode.tuple2(ResponseUtil$BsConsole.identity, Json_decode.$$int, v);
                        return Belt_List.concat(acc, Belt_List.make(match[1], /* tuple */[
                                        column,
                                        match[0]
                                      ]));
                      }));
        }));
  return Belt_List.mapWithIndex(unwrapObjects(json), (function (i, objectId) {
                var row;
                try {
                  row = Belt_List.mapWithIndex(columns, (function (ci, _column) {
                          return Belt_List.getExn(Belt_List.getExn(parsedValues, ci), i);
                        }));
                }
                catch (exn){
                  row = /* [] */0;
                }
                var id = ObjectID$BsConsole.toHexString(objectId);
                return Belt_List.concat(/* :: */[
                            /* tuple */[
                              {
                                name: "id",
                                format: undefined,
                                type_: undefined
                              },
                              id
                            ],
                            /* [] */0
                          ], row);
              }));
}

function response2PointOh(json, columns) {
  return Belt_List.map(correlate(json, columns), (function (r) {
                return Belt_List.map(r, (function (param) {
                              var column = param[0];
                              return /* tuple */[
                                      column.name,
                                      Format$BsConsole.parse2PointOh(column.type_, column.format, column.name, param[1])
                                    ];
                            }));
              }));
}

exports.unwrapObjects = unwrapObjects;
exports.unwrapCount = unwrapCount;
exports.correlate = correlate;
exports.response2PointOh = response2PointOh;
/* Format-BsConsole Not a pure module */
