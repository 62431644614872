// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var WfRouter$BsConsole = require("../workflow/WfRouter.js");
var InboxWorkflow$BsConsole = require("../inbox/InboxWorkflow.js");

function path(r) {
  if (typeof r === "number") {
    switch (r) {
      case /* Signup */0 :
          return "signup";
      case /* Recover */1 :
          return "recover";
      case /* CreateFirstProject */2 :
          return "create-first-project";
      case /* Shell */3 :
          return "shell";
      case /* Settings */4 :
          return "config_";
      case /* SettingsMe */5 :
          return "config_me";
      case /* SettingsUsers */6 :
          return "config_users";
      case /* SettingsTeams */7 :
          return "config_teams";
      case /* SettingsProjects */8 :
          return "config_projects";
      case /* SettingsProjectsNew */9 :
          return "config_projects-new";
      case /* SettingsProjectsFork */10 :
          return "config_projects-fork";
      case /* SettingsInvites */11 :
          return "config_invites";
      case /* SettingsInvitesNew */12 :
          return "config_invites-new";
      case /* SettingsNetworking */13 :
          return "config_networking";
      case /* SettingsRetentionPolicy */14 :
          return "config_retention_policy";
      case /* SettingsNetworkingSSL */15 :
          return "config_networking-ssl";
      case /* SettingsNetworkingListeners */16 :
          return "config_networking-listeners";
      case /* SettingsWhitelist */17 :
          return "config_white-list";
      case /* SettingsBilling */18 :
          return "config_billing";
      case /* SettingsBillingPlans */19 :
          return "config_billing_plans";
      case /* SettingsSubscriptions */20 :
          return "config_subscriptions";
      case /* SettingsSamlConfig */21 :
          return "config_saml";
      case /* WfPlayground */22 :
          return "workflow-playground";
      case /* UiPlayground */23 :
          return "ui-playground";
      
    }
  } else {
    switch (r.tag | 0) {
      case /* Reset */0 :
          return "reset";
      case /* CreateAccount */1 :
          return "create-account";
      case /* UDashEntry */2 :
          return "udash-entry";
      case /* Login */3 :
          return "login";
      case /* Share */4 :
          return "share";
      case /* Activate */5 :
          return "activate";
      case /* SettingsWfConnectionManagement */6 :
          return "config_workflow_connection";
      case /* Project */7 :
          return "project";
      case /* ProjectOverview */8 :
          return "project_overview";
      case /* ProjectCompareReleases */9 :
          return "project_compare_releases";
      case /* ProjectUpload */10 :
          return "upload";
      case /* ProjectTriage */11 :
      case /* ProjectInboxLegacy1 */12 :
          break;
      case /* ProjectQueryBuilder */13 :
      case /* ProjectQueryBuilderLegacy1 */14 :
          return "explore";
      case /* ProjectInstances */15 :
      case /* ProjectInstancesLegacy1 */16 :
          return "list";
      case /* ProjectDebugger */17 :
      case /* ProjectDebuggerLegacy1 */18 :
          return "debugger";
      case /* ProjectDebugger2 */19 :
          return "debugger2";
      case /* ProjectFlamegraph */20 :
          return "flamegraph";
      case /* ProjectSimilarity */21 :
          return "similarity";
      case /* ProjectSettings */22 :
          return "config";
      case /* ProjectSettingsPlayground */23 :
          return "config_playground";
      case /* ProjectSettingsMetricsExporter */24 :
          return "config_metrics-exporter";
      case /* ProjectSettingsScrubbers */25 :
          return "config_scrubbers";
      case /* ProjectSettingsServerSideActions */26 :
          return "config_server-side-actions";
      case /* ProjectSettingsAlerts */27 :
          return "config_alerts";
      case /* ProjectSettingsIntegratedDocs */28 :
          return "config_integrated-docs";
      case /* ProjectSettingsSubmission */29 :
          return "config_submission";
      case /* ProjectSettingsStorage */30 :
          return "config_storage";
      case /* ProjectSettingsSubmissionTokens */31 :
          return "config_submission_tokens";
      case /* ProjectSettingStorageSampling */32 :
          return "config_submission_sampling";
      case /* ProjectSettingsSubmissionDataSources */33 :
          return "config_submission_data_sources";
      case /* ProjectSettingsAttributes */34 :
          return "config_attributes";
      case /* ProjectSettingsIntegrations */35 :
          return WfRouter$BsConsole.namespace + WfRouter$BsConsole.routeToAnalyticsString(r[1]);
      case /* ProjectSettingsSymbolsUploadedArchives */36 :
          return "config_symbols-uploaded-archives";
      case /* ProjectSettingsSymbolsUpload */37 :
          return "config_symbols-upload";
      case /* ProjectSettingsSymbolServers */38 :
          return "config_symbols-servers";
      case /* ProjectSettingsSymbols */39 :
          return "config_symbols";
      case /* ProjectSettingsSymbolAccessTokens */40 :
          return "config_symbol-acces-tokens";
      case /* ProjectSettingsMissingSymbols */41 :
          return "config_missing-symbols";
      case /* ProjectSettingsSymbolSearch */42 :
          return "config_symbol-search";
      case /* ProjectSettingsScheduledReports */43 :
          return "config_scheduled-reports";
      case /* ProjectSettingsAccessControl */44 :
          return "config_access-control";
      case /* ProjectSettingsReprocessing */45 :
          return "config_reprocessing";
      case /* ProjectSettingsRetentionPolicy */46 :
          return "config_retention";
      case /* ProjectSettingsSourceCode */47 :
          return "config_sourcecode";
      case /* ProjectSettingsApiTokens */48 :
          return "config_api-tokens";
      case /* ProjectSettingsDedupeConfig */49 :
          return "dedupe_config";
      case /* ProjectSettingsStabilityMonitoring */50 :
          return "metrics_importer";
      case /* RedirectLegacyDebugger */51 :
          return "redirect_legacy-debugger";
      case /* RedirectLegacyDetailView */52 :
          return "redirect_legacy-detail-view";
      case /* RedirectLegacyQueryBuilder */53 :
          return "redirect_legacy-query-builder";
      case /* Workflow */54 :
          return "workflows";
      case /* SamlError */55 :
          return "saml-error";
      case /* NotFound */56 :
          return "not-found";
      case /* Error */57 :
          return "error-page";
      case /* GenerateDemoProject */58 :
          return "generate_demo_project_" + r[0];
      
    }
  }
  if (InboxWorkflow$BsConsole.Folders.isDetailView(r[1].aperture)) {
    return "group";
  } else {
    return "triage";
  }
}

exports.path = path;
/* WfRouter-BsConsole Not a pure module */
