// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Form$BsConsole = require("../Form.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("../util.js");
var Table$BsConsole = require("../table.js");
var Theme$BsConsole = require("../Theme.js");
var Colors$BsConsole = require("../Colors.js");
var Option$BsConsole = require("../option.js");
var Styles$BsConsole = require("../utilities/Styles.js");
var Fragment$BsConsole = require("../Fragment.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Typeahead$BsConsole = require("../typeahead/Typeahead.js");
var Attributes$BsConsole = require("../bs-crdb-response/src/Attributes.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var FormatType$BsConsole = require("../bs-crdb-response/src/FormatType.js");
var InboxUtils$BsConsole = require("../inbox/InboxUtils.js");
var UtilWindow$BsConsole = require("../UtilWindow.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BpgController$BsConsole = require("../BpgController.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var FetchDescribe$BsConsole = require("../FetchDescribe.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SettingsDialog$BsConsole = require("../SettingsDialog.js");
var EnhancedCustomAttributesTable$BsConsole = require("../EnhancedCustomAttributesTable.js");

function renderHelperText(getErrorForField, field, remoteData) {
  if (typeof remoteData === "number" || remoteData.tag !== /* Failure */1) {
    return I18N$BsConsole.$$Option.dynamicOrEmpty(Curry._1(getErrorForField, field));
  } else {
    return I18N$BsConsole.dynamic(remoteData[0]);
  }
}

function hasError(getErrorForField, field, remoteData) {
  if (typeof remoteData === "number" || remoteData.tag !== /* Failure */1) {
    return Belt_Option.isSome(Curry._1(getErrorForField, field));
  } else {
    return true;
  }
}

var table = Css.style(/* :: */[
      Css.overflow(/* auto */-1065951377),
      /* :: */[
        Css.height(/* auto */-1065951377),
        /* :: */[
          Css.maxHeight(Css.vh(50.0)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  table: table
};

var component = ReasonReact.statelessComponent("PS_Attributes-BsConsole__TableWrapper");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReactDOMRe.createElementVariadic("div", {
                          className: table
                        }, children);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var TableWrapper = {
  Styles: Styles,
  component: component,
  make: make
};

var table$1 = Css.style(/* :: */[
      Css.tableLayout(/* fixed */10615156),
      /* [] */0
    ]);

var tableHeaderCell = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* :: */[
          Css.width(Css.pct(30)),
          /* [] */0
        ]
      ]
    ]);

var maxWidthCell = Css.style(/* :: */[
      Css.width(Css.pct(30)),
      /* :: */[
        Css.unsafe("workBreak", "break-word"),
        /* [] */0
      ]
    ]);

var valueCell = Css.style(/* :: */[
      Css.important(Css.width(Css.pct(50))),
      /* [] */0
    ]);

var addCell = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var addButton = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.unsafe("minWidth", "unset"),
        /* :: */[
          Css.display(/* none */-922086728),
          /* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.right(Css.em(0.5)),
              /* :: */[
                Css.unsafe("top", "calc(50% - 20px)"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var addButtonLabel = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* [] */0
    ]);

var Styles$1 = {
  table: table$1,
  tableHeaderCell: tableHeaderCell,
  maxWidthCell: maxWidthCell,
  valueCell: valueCell,
  addCell: addCell,
  addButton: addButton,
  addButtonLabel: addButtonLabel
};

var component$1 = ReasonReact.statelessComponent("PS_Attributes-BsConsole__SuggestedAttributeTable");

function make$1(attributes, update, onAddAttribute, shouldTableUpdate, onTableUpdate, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              if (shouldTableUpdate) {
                Curry._1(update, undefined);
                Curry._1(onTableUpdate, undefined);
              }
              var attributes$1 = List.sort((function (attributeA, attributeB) {
                      if (attributeA !== undefined && attributeB !== undefined) {
                        return Caml_primitive.caml_string_compare($$String.lowercase(attributeA.name), $$String.lowercase(attributeB.name));
                      } else {
                        return 0;
                      }
                    }), attributes);
              return ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(attributes$1, (function (aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage) {
                                return ReasonReact.element(undefined, undefined, make([ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, table$1, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Name")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell + (" " + valueCell), undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Value")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Seen")])),
                                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, "none", undefined, undefined, []))
                                                                            ]))])),
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [List.length(attributes$1) > 0 ? Curry._1(aperture, $$Array.of_list(List.map((function (attribute) {
                                                                                      if (attribute !== undefined) {
                                                                                        return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, "attributes-table-row", [
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, maxWidthCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(attribute.name)])),
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, maxWidthCell + (" " + valueCell), undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(attribute.value)])),
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, maxWidthCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(FormatUtils$BsConsole.formatDateToAgoString(new Date(attribute.seen * 1000)))])),
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, addCell, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                            undefined,
                                                                                                                            addButton + " action-button",
                                                                                                                            (function (_e) {
                                                                                                                                return Curry._1(onAddAttribute, attribute.name);
                                                                                                                              }),
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            {
                                                                                                                              label: addButtonLabel
                                                                                                                            },
                                                                                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                                          ]))]))
                                                                                                      ]));
                                                                                      } else {
                                                                                        return null;
                                                                                      }
                                                                                    }), attributes$1))) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "There are currently no recommended attributes for this project")]))]))])),
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                                              undefined,
                                                                              undefined,
                                                                              onChangePage,
                                                                              undefined,
                                                                              onChangeRowsPerPage,
                                                                              /* `Int */[
                                                                                3654863,
                                                                                page
                                                                              ],
                                                                              undefined,
                                                                              /* `Int */[
                                                                                3654863,
                                                                                List.length(attributes$1)
                                                                              ],
                                                                              /* `Int */[
                                                                                3654863,
                                                                                rowsPerPage
                                                                              ],
                                                                              /* `Int */[
                                                                                3654863,
                                                                                4
                                                                              ],
                                                                              undefined,
                                                                              undefined,
                                                                              []
                                                                            ]))]))
                                                        ]))]));
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var SuggestedAttributesTable = {
  Styles: Styles$1,
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("PS_Attributes-BsConsole__DeleteAttributeDialog");

function make$2(attribute, project, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Attribute.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                var attributes$prime = state.remote;
                                if (typeof attributes$prime === "number") {
                                  return null;
                                }
                                if (attributes$prime.tag) {
                                  return null;
                                }
                                var deleteAttribute = Belt_List.getBy(attributes$prime[0], (function (attribute$prime) {
                                        if (attribute$prime.project === project.pid) {
                                          return attribute$prime.name === attribute.name;
                                        } else {
                                          return false;
                                        }
                                      }));
                                var dialogText = deleteAttribute !== undefined ? (
                                    attribute.statistics.used ? I18N$BsConsole.get(undefined, "This attribute has data attributed to it in the current project's crash data. Are you sure you would like to delete it?") : I18N$BsConsole.get(undefined, "Are you sure you would like to delete it?")
                                  ) : I18N$BsConsole.get(undefined, "This is not a custom attribute and cannot be deleted");
                                return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Attribute.Delete.make, (function (param) {
                                                  Curry._1(onClose, (new Event('close')));
                                                  return Curry._1(onSuccess, undefined);
                                                }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                                  var msg = state.remote;
                                                  var tmp;
                                                  tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(msg[0])]))]));
                                                  var match = state.remote;
                                                  var tmp$1;
                                                  tmp$1 = typeof match === "number" ? (
                                                      match === /* NotAsked */0 ? deleteAttribute === undefined : true
                                                    ) : (
                                                      match.tag ? true : deleteAttribute === undefined
                                                    );
                                                  return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Delete attribute"), undefined, [
                                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                            tmp,
                                                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.showSkip(dialogText)]))
                                                                          ])),
                                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      Curry.__1(onClose),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                    ])),
                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      (function ($$event) {
                                                                                          if (deleteAttribute !== undefined) {
                                                                                            return Curry._1(handleSubmit, /* tuple */[
                                                                                                        deleteAttribute,
                                                                                                        Util$BsConsole.identity
                                                                                                      ]);
                                                                                          } else {
                                                                                            return Curry._1(onClose, $$event);
                                                                                          }
                                                                                        }),
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      tmp$1,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      [I18N$BsConsole.show(undefined, "Delete")]
                                                                                    ]))
                                                                          ]))
                                                                ]));
                                                })));
                              })));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var DeleteDialog = {
  component: component$2,
  make: make$2
};

function formatCategoryDescription(format) {
  if (Attributes$BsConsole.Formats.formatCategoryToString(format) === "None") {
    return I18N$BsConsole.get(undefined, "no format will be applied for rendering values");
  } else {
    return I18N$BsConsole.empty(undefined);
  }
}

function getCount(param) {
  
}

function toString(t$prime) {
  return Attributes$BsConsole.Formats.formatCategoryToString(t$prime);
}

var placeholder = I18N$BsConsole.get(undefined, "Category");

var renderItem = (function (t$prime) {
    return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.showSkip(Attributes$BsConsole.Formats.formatCategoryToString(t$prime))), Caml_option.some(I18N$BsConsole.showSkip(formatCategoryDescription(t$prime))), []));
  });

var CategoryTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: renderItem,
      placeholder: placeholder,
      fuzz: undefined
    });

function toString$1(t$prime) {
  return Attributes$BsConsole.Formats.formatToString(t$prime.name);
}

function getCount$1(param) {
  
}

var placeholder$1 = I18N$BsConsole.get(undefined, "Format");

var FormatTypeahead = Typeahead$BsConsole.Make({
      toString: toString$1,
      getCount: getCount$1,
      renderItem: undefined,
      placeholder: placeholder$1,
      fuzz: undefined
    });

function setRef(tokenType, theRef, param) {
  var state = param.state;
  if (tokenType) {
    state.categoryInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  } else {
    state.formatInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  }
}

var component$3 = ReasonReact.reducerComponent("Format__Typeahead__PS_Attributes-BsConsole");

var menu = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

function make$3(omit, onChange, onClose, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var goTo = function (inputRef) {
                var r = inputRef.contents;
                if (r === undefined) {
                  return ;
                }
                var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                domElement.querySelector("input").focus();
                domElement.querySelector("input").select();
                
              };
              var category = self.state.category;
              var tmp;
              if (category !== undefined) {
                var formatList = Belt_List.keep(Attributes$BsConsole.Formats.list, (function (format) {
                        return format.category === category;
                      }));
                tmp = ReasonReact.element(Attributes$BsConsole.Formats.formatCategoryToString(category), Curry._1(self.handle, (function (param, param$1) {
                            return setRef(/* Format */0, param, param$1);
                          })), Curry.app(FormatTypeahead.make, [
                          undefined,
                          undefined,
                          Belt_List.keep(formatList, (function (format) {
                                  if (omit !== undefined) {
                                    return !Belt_List.has(omit, format, (function (formatA, formatB) {
                                                  return formatA === formatB.name;
                                                }));
                                  } else {
                                    return true;
                                  }
                                })),
                          (function (format) {
                              Curry._1(self.send, /* SetFormat */Block.__(0, [format.name]));
                              setTimeout((function (param) {
                                      return goTo(self.state.categoryInputRef);
                                    }), 200);
                              
                            }),
                          undefined,
                          (function (param) {
                              Curry._1(self.send, /* SetCategory */Block.__(1, [undefined]));
                              setTimeout((function (param) {
                                      return goTo(self.state.formatInputRef);
                                    }), 200);
                              
                            }),
                          undefined,
                          undefined,
                          undefined,
                          []
                        ]));
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, menu, undefined, undefined, 0, [
                              React.createElement("div", undefined, ReasonReact.element("category__typeahead", Curry._1(self.handle, (function (param, param$1) {
                                              return setRef(/* Category */1, param, param$1);
                                            })), Curry.app(CategoryTypeahead.make, [
                                            undefined,
                                            undefined,
                                            Belt_List.concat(Belt_List.sort(Belt_List.keep(Attributes$BsConsole.Formats.categories, (function (f) {
                                                            return f !== /* None */870530776;
                                                          })), (function (a, b) {
                                                        if (Attributes$BsConsole.Formats.formatCategoryToString(a) > Attributes$BsConsole.Formats.formatCategoryToString(b)) {
                                                          return 1;
                                                        } else {
                                                          return -1;
                                                        }
                                                      })), /* :: */[
                                                  /* None */870530776,
                                                  /* [] */0
                                                ]),
                                            (function (category) {
                                                if (category !== 870530776) {
                                                  Curry._1(self.send, /* SetCategory */Block.__(1, [category]));
                                                  setTimeout((function (param) {
                                                          return goTo(self.state.formatInputRef);
                                                        }), 200);
                                                  return ;
                                                } else {
                                                  return Curry._1(self.send, /* SetFormat */Block.__(0, [/* None */870530776]));
                                                }
                                              }),
                                            undefined,
                                            (function (param) {
                                                return Curry._1(onClose, undefined);
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            []
                                          ]))),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      format: undefined,
                      category: undefined,
                      formatInputRef: {
                        contents: undefined
                      },
                      categoryInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            format: state.format,
                            category: action[0],
                            formatInputRef: state.formatInputRef,
                            categoryInputRef: state.categoryInputRef
                          }]);
              } else {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            format: action[0],
                            category: state.category,
                            formatInputRef: state.formatInputRef,
                            categoryInputRef: state.categoryInputRef
                          },
                          (function (self) {
                              var format = self.state.format;
                              if (format !== undefined) {
                                Curry._1(onChange, Attributes$BsConsole.formatToJs(format));
                              }
                              return Curry._1(onClose, undefined);
                            })
                        ]);
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var Formats = {
  CategoryTypeahead: CategoryTypeahead,
  FormatTypeahead: FormatTypeahead,
  setRef: setRef,
  component: component$3,
  menu: menu,
  make: make$3
};

function formatMenuListItemTextSecondary(type_) {
  if (type_ !== -493315045) {
    if (type_ !== 508176322) {
      if (type_ !== 1023055798) {
        return I18N$BsConsole.empty(undefined);
      } else {
        return I18N$BsConsole.get(undefined, "e.g. User ID, Transaction ID, IP addresses (1B+ unique values)");
      }
    } else {
      return I18N$BsConsole.get(undefined, "e.g. Status, Days of week, User Roles (255 unique values)");
    }
  } else {
    return I18N$BsConsole.get(undefined, "e.g. Postal Codes, SKUs, Product ID (16K+ unique values)");
  }
}

var menu$1 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

function getCount$2(param) {
  
}

var toString$2 = Attributes$BsConsole.typeCategoryToJs;

var placeholder$2 = I18N$BsConsole.get(undefined, "type");

var CategoryTypeahead$1 = Typeahead$BsConsole.Make({
      toString: toString$2,
      getCount: getCount$2,
      renderItem: undefined,
      placeholder: placeholder$2,
      fuzz: undefined
    });

function getCount$3(param) {
  
}

function toString$3(t$prime) {
  return Attributes$BsConsole.Types.fromJsToString(Attributes$BsConsole.type_ToJs(t$prime));
}

var renderItem$1 = (function (t$prime) {
    return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, Caml_option.some(I18N$BsConsole.showSkip(Attributes$BsConsole.Types.fromJsToString(Attributes$BsConsole.type_ToJs(t$prime)))), Caml_option.some(I18N$BsConsole.showSkip(formatMenuListItemTextSecondary(t$prime))), []));
  });

var placeholder$3 = I18N$BsConsole.get(undefined, "type");

var TypeTypeahead = Typeahead$BsConsole.Make({
      toString: toString$3,
      getCount: getCount$3,
      renderItem: renderItem$1,
      placeholder: placeholder$3,
      fuzz: undefined
    });

function setRef$1(tokenType, theRef, param) {
  var state = param.state;
  if (tokenType) {
    state.typeInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  } else {
    state.categoryInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  }
}

var component$4 = ReasonReact.reducerComponent("Type__Typeahead__PS_Attributes-BsConsole");

function make$4(onChange, onClose, permissableTypes, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var match = self.state.category;
              var tmp;
              if (match !== undefined && permissableTypes !== undefined) {
                var tmp$1;
                if (permissableTypes !== undefined) {
                  var exit = 0;
                  var val;
                  try {
                    val = List.find((function (param) {
                            return param[0] === match;
                          }), permissableTypes);
                    exit = 1;
                  }
                  catch (exn){
                    if (exn === Caml_builtin_exceptions.not_found) {
                      tmp$1 = /* [] */0;
                    } else {
                      throw exn;
                    }
                  }
                  if (exit === 1) {
                    tmp$1 = val[1];
                  }
                  
                } else {
                  tmp$1 = /* [] */0;
                }
                tmp = ReasonReact.element(Attributes$BsConsole.typeCategoryToJs(match), Curry._1(self.handle, (function (param, param$1) {
                            return setRef$1(/* Type */1, param, param$1);
                          })), Curry.app(TypeTypeahead.make, [
                          undefined,
                          undefined,
                          tmp$1,
                          (function (type_) {
                              return Curry._1(self.send, /* SetType */Block.__(1, [type_]));
                            }),
                          undefined,
                          (function (param) {
                              Curry._1(self.send, /* SetCategory */Block.__(0, [undefined]));
                              setTimeout((function (param) {
                                      var inputRef = self.state.categoryInputRef;
                                      var r = inputRef.contents;
                                      if (r === undefined) {
                                        return ;
                                      }
                                      var domElement = ReactDom.findDOMNode(Caml_option.valFromOption(r));
                                      domElement.querySelector("input").focus();
                                      domElement.querySelector("input").select();
                                      
                                    }), 200);
                              
                            }),
                          undefined,
                          undefined,
                          undefined,
                          []
                        ]));
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, menu$1, undefined, undefined, 0, [
                              ReasonReact.element("categories__typeahead", Curry._1(self.handle, (function (param, param$1) {
                                          return setRef$1(/* Category */0, param, param$1);
                                        })), Curry.app(CategoryTypeahead$1.make, [
                                        undefined,
                                        undefined,
                                        permissableTypes !== undefined ? List.map((function (param) {
                                                  return param[0];
                                                }), permissableTypes) : /* [] */0,
                                        (function (category) {
                                            return Curry._1(self.send, /* SetCategory */Block.__(0, [category]));
                                          }),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        undefined,
                                        undefined,
                                        undefined,
                                        []
                                      ])),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      category: undefined,
                      type_: undefined,
                      categoryInputRef: {
                        contents: undefined
                      },
                      typeInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, state) {
              if (action.tag) {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            category: state.category,
                            type_: action[0],
                            categoryInputRef: state.categoryInputRef,
                            typeInputRef: state.typeInputRef
                          },
                          (function (self) {
                              var type_ = self.state.type_;
                              if (type_ !== undefined) {
                                Curry._1(onChange, Attributes$BsConsole.type_ToJs(type_));
                              }
                              return Curry._1(onClose, undefined);
                            })
                        ]);
              }
              var category = action[0];
              if (category === undefined) {
                return /* Update */Block.__(0, [{
                            category: category,
                            type_: state.type_,
                            categoryInputRef: state.categoryInputRef,
                            typeInputRef: state.typeInputRef
                          }]);
              }
              if (category !== -976970511) {
                if (category === -883944824) {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                return Curry._1(self.send, /* SetType */Block.__(1, [/* Bitmap */631276559]));
                              })]);
                }
                if (category === 946856507) {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                return Curry._1(self.send, /* SetType */Block.__(1, [/* UUID */946856507]));
                              })]);
                }
                
              } else if (!Gate$BsConsole.isStringCardinalityEnabled(undefined)) {
                return /* SideEffects */Block.__(1, [(function (self) {
                              return Curry._1(self.send, /* SetType */Block.__(1, [/* Dictionary */1023055798]));
                            })]);
              }
              return /* Update */Block.__(0, [{
                          category: category,
                          type_: state.type_,
                          categoryInputRef: state.categoryInputRef,
                          typeInputRef: state.typeInputRef
                        }]);
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var Types_ = {
  menu: menu$1,
  CategoryTypeahead: CategoryTypeahead$1,
  TypeTypeahead: TypeTypeahead,
  setRef: setRef$1,
  component: component$4,
  make: make$4
};

var settingsDialog = Css.style(/* :: */[
      Css.width(Css.pct(550)),
      /* [] */0
    ]);

var component$5 = ReasonReact.statelessComponent("PS_Attributes-BsConsole__CreateDialog");

function make$5(project, nameOpt, onSuccess, token, handleTask, onClose, _children) {
  var name = nameOpt !== undefined ? nameOpt : "";
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Attribute.Create.make, (function (_result, _updatedEntity) {
                                Curry._1(onSuccess, undefined);
                                return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.AttributeForm.make, (function (param) {
                                                  var values = param.values;
                                                  return Curry._1(handleSubmit, /* tuple */[
                                                              {
                                                                project: project.pid,
                                                                name: values.name,
                                                                format: Option$BsConsole.ofString(values.format),
                                                                type_: values.type_,
                                                                description: values.description,
                                                                __state: undefined,
                                                                __create: undefined,
                                                                __modify: undefined
                                                              },
                                                              Util$BsConsole.identity,
                                                              param.setError,
                                                              param.setSubmitting,
                                                              param.resetFormState
                                                            ]);
                                                }), undefined, undefined, {
                                                name: name,
                                                format: "",
                                                type_: "",
                                                description: ""
                                              }, /* :: */[
                                                /* tuple */[
                                                  /* Name */869834347,
                                                  /* Custom */[(function (values) {
                                                        if (/[;|]/.test(values.name)) {
                                                          return "illegal character present";
                                                        }
                                                        
                                                      })]
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    /* Description */-1018191652,
                                                    /* Required */0
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      /* Format */841155415,
                                                      /* Required */0
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        /* Type */937565914,
                                                        /* Required */0
                                                      ],
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ], undefined, (function (param) {
                                                  var getErrorForField = param.getErrorForField;
                                                  var handleSubmit = param.handleSubmit;
                                                  var handleChange = param.handleChange;
                                                  var form = param.form;
                                                  var partial_arg = Curry._1(handleChange, /* Name */869834347);
                                                  var partial_arg$1 = Curry._1(handleChange, /* Description */-1018191652);
                                                  var partial_arg$2 = Curry._1(handleChange, /* Format */841155415);
                                                  var partial_arg$3 = Curry._1(handleChange, /* Type */937565914);
                                                  return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Edit attribute"), settingsDialog, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                          }), /* `String */[
                                                                                          -976970511,
                                                                                          form.values.name
                                                                                        ], hasError(getErrorForField, /* Name */869834347, state.remote), renderHelperText(getErrorForField, /* Name */869834347, state.remote), I18N$BsConsole.show(undefined, "name"), true, true, undefined, undefined, [])),
                                                                                ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                            return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                          }), /* `String */[
                                                                                          -976970511,
                                                                                          form.values.description
                                                                                        ], hasError(getErrorForField, /* Description */-1018191652, state.remote), renderHelperText(getErrorForField, /* Description */-1018191652, state.remote), I18N$BsConsole.show(undefined, "description"), true, undefined, undefined, undefined, [])),
                                                                                React.createElement("div", undefined, ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                                return ReForm_Helpers.handleDomFormChange(partial_arg$2, param);
                                                                                              }), /* `String */[
                                                                                              -976970511,
                                                                                              Attributes$BsConsole.Formats.fromJsToString(form.values.format)
                                                                                            ], hasError(getErrorForField, /* Format */841155415, state.remote), renderHelperText(getErrorForField, /* Format */841155415, state.remote), I18N$BsConsole.show(undefined, "format"), true, false, undefined, (function (onClose) {
                                                                                                return ReasonReact.element(undefined, undefined, make$3(undefined, (function (formatValue) {
                                                                                                                  Curry._2(handleChange, /* Format */841155415, formatValue);
                                                                                                                  return Curry._2(handleChange, /* Type */937565914, Attributes$BsConsole.Formats.getDerivedType(formatValue));
                                                                                                                }), onClose, []));
                                                                                              }), undefined, []))),
                                                                                React.createElement("div", undefined, ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                                return ReForm_Helpers.handleDomFormChange(partial_arg$3, param);
                                                                                              }), /* `String */[
                                                                                              -976970511,
                                                                                              Attributes$BsConsole.Types.fromJsToString(form.values.type_)
                                                                                            ], hasError(getErrorForField, /* Type */937565914, state.remote), renderHelperText(getErrorForField, /* Type */937565914, state.remote), I18N$BsConsole.show(undefined, "type"), true, undefined, "types__input", (function (onClose) {
                                                                                                return ReasonReact.element(undefined, undefined, make$4((function (typeValue) {
                                                                                                                  return Curry._2(handleChange, /* Type */937565914, typeValue);
                                                                                                                }), onClose, Attributes$BsConsole.Formats.getPermissableTypes(form.values.format), []));
                                                                                              }), form.values.format === "", [])))
                                                                              ])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          (function (_event) {
                                                                                              return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                            }),
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                        ])),
                                                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          (function (_event) {
                                                                                              return Curry._1(handleSubmit, undefined);
                                                                                            }),
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          state.remote === /* Loading */1 || form.values.name === "" || form.values.description === "" || form.values.format === "" || form.values.type_ === "",
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          [I18N$BsConsole.show(undefined, "Create")]
                                                                                        ]))
                                                                              ])))]));
                                                })));
                              })));
            }),
          initialState: component$5.initialState,
          retainedProps: component$5.retainedProps,
          reducer: component$5.reducer,
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var CreateDialog = {
  settingsDialog: settingsDialog,
  component: component$5,
  make: make$5
};

var settingsDialog$1 = Css.style(/* :: */[
      Css.width(Css.pct(550)),
      /* [] */0
    ]);

var component$6 = ReasonReact.statelessComponent("PS_Attributes-BsConsole__CreateDialog");

function make$6(project, onSuccess, token, attribute, handleTask, onClose, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Attribute.Get.make, handleTask, token, undefined, (function (state, _updater) {
                                var attributes = state.remote;
                                if (typeof attributes === "number") {
                                  return null;
                                }
                                if (attributes.tag) {
                                  return null;
                                }
                                var attribute$1 = Belt_List.getBy(attributes[0], (function (at) {
                                        if (at.name === attribute.name) {
                                          return at.project === project.pid;
                                        } else {
                                          return false;
                                        }
                                      }));
                                if (attribute$1 !== undefined) {
                                  return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Attribute.Modify.make, (function (_result, _updatedEntity) {
                                                    Curry._1(onSuccess, undefined);
                                                    return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                                    return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.AttributeForm.make, (function (param) {
                                                                      var values = param.values;
                                                                      return Curry._1(handleSubmit, /* tuple */[
                                                                                  attribute$1,
                                                                                  (function (attribute) {
                                                                                      return {
                                                                                              project: attribute.project,
                                                                                              name: attribute.name,
                                                                                              format: attribute.format,
                                                                                              type_: attribute.type_,
                                                                                              description: values.description,
                                                                                              __state: attribute.__state,
                                                                                              __create: attribute.__create,
                                                                                              __modify: attribute.__modify
                                                                                            };
                                                                                    }),
                                                                                  Form$BsConsole.Fields.set("format", values.format, Form$BsConsole.Fields.set("description", values.description, Form$BsConsole.Fields.empty)),
                                                                                  param.setError,
                                                                                  param.setSubmitting,
                                                                                  param.resetFormState
                                                                                ]);
                                                                    }), undefined, undefined, {
                                                                    name: attribute$1.name,
                                                                    format: Belt_Option.getWithDefault(attribute$1.format, ""),
                                                                    type_: attribute$1.type_,
                                                                    description: attribute$1.description
                                                                  }, /* :: */[
                                                                    /* tuple */[
                                                                      /* Description */-1018191652,
                                                                      /* Required */0
                                                                    ],
                                                                    /* :: */[
                                                                      /* tuple */[
                                                                        /* Format */841155415,
                                                                        /* Required */0
                                                                      ],
                                                                      /* :: */[
                                                                        /* tuple */[
                                                                          /* Type */937565914,
                                                                          /* Required */0
                                                                        ],
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ], undefined, (function (param) {
                                                                      var getErrorForField = param.getErrorForField;
                                                                      var handleSubmit = param.handleSubmit;
                                                                      var handleChange = param.handleChange;
                                                                      var form = param.form;
                                                                      var partial_arg = Curry._1(handleChange, /* Description */-1018191652);
                                                                      var match = FormatType$BsConsole.fromString(form.values.format);
                                                                      var tmp;
                                                                      var exit = 0;
                                                                      if (match === 8) {
                                                                        tmp = ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                    
                                                                                  }), /* `String */[
                                                                                  -976970511,
                                                                                  Attributes$BsConsole.Formats.fromJsToString(form.values.format)
                                                                                ], false, null, I18N$BsConsole.show(undefined, "format"), true, undefined, true, undefined, []));
                                                                      } else {
                                                                        exit = 1;
                                                                      }
                                                                      if (exit === 1) {
                                                                        var partial_arg$1 = Curry._1(handleChange, /* Format */841155415);
                                                                        tmp = React.createElement("div", undefined, ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TypeaheadTextField.make((function (param) {
                                                                                        return ReForm_Helpers.handleDomFormChange(partial_arg$1, param);
                                                                                      }), /* `String */[
                                                                                      -976970511,
                                                                                      Attributes$BsConsole.Formats.fromJsToString(form.values.format)
                                                                                    ], hasError(getErrorForField, /* Format */841155415, state.remote), renderHelperText(getErrorForField, /* Format */841155415, state.remote), I18N$BsConsole.show(undefined, "format"), true, false, undefined, (function (onClose) {
                                                                                        var accessibleFormats = Attributes$BsConsole.Formats.getPermissableNextFormats(Belt_Option.getWithDefault(Attributes$BsConsole.type_FromJs(form.values.type_), /* Dictionary */1023055798));
                                                                                        return ReasonReact.element(undefined, undefined, make$3(Belt_List.concat(/* :: */[
                                                                                                            /* SHA256 */-812272505,
                                                                                                            /* [] */0
                                                                                                          ], Belt_List.keep(Belt_List.map(Attributes$BsConsole.Formats.list, (function (format) {
                                                                                                                      return format.name;
                                                                                                                    })), (function (format) {
                                                                                                                  return !Belt_List.has(accessibleFormats, format, (function (formatA, formatB) {
                                                                                                                                return formatA === formatB;
                                                                                                                              }));
                                                                                                                }))), (function (formatValue) {
                                                                                                          Curry._2(handleChange, /* Format */841155415, formatValue);
                                                                                                          return Curry._2(handleChange, /* Type */937565914, Attributes$BsConsole.Formats.getDerivedType(formatValue));
                                                                                                        }), onClose, []));
                                                                                      }), undefined, [])));
                                                                      }
                                                                      return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Edit an attribute"), settingsDialog$1, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                                                                    ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                                                
                                                                                                              }), /* `String */[
                                                                                                              -976970511,
                                                                                                              form.values.name
                                                                                                            ], false, null, I18N$BsConsole.show(undefined, "name"), true, undefined, true, undefined, [])),
                                                                                                    ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                                                return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                              }), /* `String */[
                                                                                                              -976970511,
                                                                                                              form.values.description
                                                                                                            ], hasError(getErrorForField, /* Description */-1018191652, state.remote), renderHelperText(getErrorForField, /* Description */-1018191652, state.remote), I18N$BsConsole.show(undefined, "description"), true, true, undefined, undefined, [])),
                                                                                                    tmp,
                                                                                                    ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.TextField.make((function (param) {
                                                                                                                
                                                                                                              }), /* `String */[
                                                                                                              -976970511,
                                                                                                              form.values.type_
                                                                                                            ], false, null, I18N$BsConsole.show(undefined, "type"), true, undefined, true, undefined, []))
                                                                                                  ])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              (function (_event) {
                                                                                                                  return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                                                                                                }),
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                            ])),
                                                                                                    ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              (function (_event) {
                                                                                                                  return Curry._1(handleSubmit, undefined);
                                                                                                                }),
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              state.remote === /* Loading */1 || form.values.name === "" || form.values.description === "" || form.values.format === "" || form.values.type_ === "",
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              [I18N$BsConsole.show(undefined, "Modify")]
                                                                                                            ]))
                                                                                                  ])))]));
                                                                    })));
                                                  })));
                                } else {
                                  return null;
                                }
                              })));
            }),
          initialState: component$6.initialState,
          retainedProps: component$6.retainedProps,
          reducer: component$6.reducer,
          jsElementWrapped: component$6.jsElementWrapped
        };
}

var ModifyDialog = {
  settingsDialog: settingsDialog$1,
  component: component$6,
  make: make$6
};

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.height(Css.px(4)),
        /* :: */[
          Css.margin2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.em(1)),
        /* :: */[
          Css.paddingRight(Css.px(28)),
          /* [] */0
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var addButton$1 = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(-28)),
        /* :: */[
          Css.unsafe("top", "calc(50% - 28px)"),
          /* :: */[
            Css.color(Css.white),
            /* [] */0
          ]
        ]
      ]
    ]);

var tabsToolbar = Css.style(/* :: */[
      Css.unsafe("minHeight", "unset"),
      /* [] */0
    ]);

var Styles$2 = {
  indicator: indicator,
  titleContainer: titleContainer,
  title: title,
  addButton: addButton$1,
  tabsToolbar: tabsToolbar
};

var component$7 = ReasonReact.reducerComponent("PS_Attributes-BsConsole");

function getDefaultAttributes(attributes) {
  return Belt_List.keep(attributes.active, (function (attribute) {
                var match = attribute.custom;
                var match$1 = InboxUtils$BsConsole.isNestedIssues(attribute.name);
                var match$2 = attribute.name.includes(";first_seen");
                if (match || match$1 || match$2) {
                  return false;
                } else {
                  return true;
                }
              }));
}

function getCustomAttributes(attributes) {
  return Belt_List.keep(attributes.active, (function (attr) {
                return attr.custom;
              }));
}

function getRecommendedAttributes(attributes) {
  return attributes.disabled;
}

function make$7(project, handleTask, handleChangeUrl, token, autoFillAttribute, _children) {
  return {
          debugName: component$7.debugName,
          reactClassInternal: component$7.reactClassInternal,
          handedOffState: component$7.handedOffState,
          willReceiveProps: component$7.willReceiveProps,
          didMount: (function (self) {
              if (autoFillAttribute !== undefined) {
                MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                        /* Debugger_AddCustomAttributeClick */103,
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "custom_attribute",
                                autoFillAttribute
                              ],
                              /* [] */0
                            ])
                      ]));
                return Curry._1(self.send, /* DialogWithTabUpdate */Block.__(2, [
                              /* Create */Block.__(1, [autoFillAttribute]),
                              /* Suggested */2
                            ]));
              }
              
            }),
          didUpdate: component$7.didUpdate,
          willUnmount: component$7.willUnmount,
          willUpdate: component$7.willUpdate,
          shouldUpdate: component$7.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              var tmp;
              if (match !== undefined) {
                switch (match.tag | 0) {
                  case /* Delete */0 :
                      tmp = ReasonReact.element(undefined, undefined, make$2(match[0], project, token, handleTask, (function (_event) {
                                  return Curry._1(self.send, /* Dialog */Block.__(1, [undefined]));
                                }), (function (param, param$1) {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted attribute"));
                                  return Curry._1(self.send, /* UpdateTable */0);
                                }), []));
                      break;
                  case /* Create */1 :
                      tmp = ReasonReact.element(undefined, undefined, make$5(project, match[0], (function (param) {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created attribute"));
                                  return Curry._1(self.send, /* UpdateTable */0);
                                }), token, handleTask, (function (_event) {
                                  return Curry._1(self.send, /* Dialog */Block.__(1, [undefined]));
                                }), []));
                      break;
                  case /* Modify */2 :
                      tmp = ReasonReact.element(undefined, undefined, make$6(project, (function (param) {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully edited attribute"));
                                  return Curry._1(self.send, /* UpdateTable */0);
                                }), token, match[0], handleTask, (function (_event) {
                                  return Curry._1(self.send, /* Dialog */Block.__(1, [undefined]));
                                }), []));
                      break;
                  
                }
              } else {
                tmp = null;
              }
              return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, titleContainer, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Attributes")])),
                                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                                      I18N$BsConsole.show(undefined, "Attributes are custom metadata attached to error reports to aid in debugging and root cause analysis. Clicking the '+' button will index an attribute, allowing for filtering by that attribute's values. To ensure indexing the attribute takes effect in older reports, it will be necessary to "),
                                                                      ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectSettingsReprocessing */Block.__(45, [project.name]), undefined, handleChangeUrl, undefined, {
                                                                                color: Colors$BsConsole.blue,
                                                                                textDecoration: "none"
                                                                              }, undefined, [I18N$BsConsole.show(undefined, "reprocess them")])),
                                                                      I18N$BsConsole.show(undefined, ".")
                                                                    ]))])),
                                                  ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                className: Styles$BsConsole.$$Text.tooltipTitle
                                                              }, I18N$BsConsole.show(undefined, "Create a new attribute")), undefined, undefined, undefined, undefined, Caml_option.some(Theme$BsConsole.main), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      addButton$1,
                                                                      (function (_event) {
                                                                          return Curry._1(self.send, /* Dialog */Block.__(1, [/* Create */Block.__(1, [""])]));
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      /* Fab */3502759,
                                                                      /* Primary */-791844958,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                    ]))]))
                                                ])),
                                        ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.Disabled.make(handleTask, token, project.name, (function (remote, update) {
                                                    if (typeof remote === "number") {
                                                      if (remote === /* NotAsked */0) {
                                                        return null;
                                                      } else {
                                                        return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, self.state.tab, {
                                                                            indicator: indicator
                                                                          }, (function (param, tab) {
                                                                              return Curry._1(self.send, /* Tab */Block.__(0, [tab > 2 || tab < 0 ? /* Custom */0 : tab]));
                                                                            }), undefined, undefined, undefined, undefined, [
                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Custom */0, Caml_option.some(I18N$BsConsole.show(undefined, "Custom")), true, [])),
                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Default */1, Caml_option.some(I18N$BsConsole.show(undefined, "Default")), true, [])),
                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Suggested */2, Caml_option.some(I18N$BsConsole.show(undefined, "Recommended")), true, []))
                                                                          ])), ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [React.createElement("div", {
                                                                                  style: {
                                                                                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.grey2),
                                                                                    display: "flex",
                                                                                    padding: "2em",
                                                                                    width: "100%",
                                                                                    justifyContent: "center"
                                                                                  }
                                                                                }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, [])))])));
                                                      }
                                                    }
                                                    if (remote.tag) {
                                                      return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, self.state.tab, {
                                                                          indicator: indicator
                                                                        }, (function (param, tab) {
                                                                            return Curry._1(self.send, /* Tab */Block.__(0, [tab > 2 || tab < 0 ? /* Custom */0 : tab]));
                                                                          }), undefined, undefined, undefined, undefined, [
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Custom */0, Caml_option.some(I18N$BsConsole.show(undefined, "Custom")), true, [])),
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Default */1, Caml_option.some(I18N$BsConsole.show(undefined, "Default")), true, [])),
                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Suggested */2, Caml_option.some(I18N$BsConsole.show(undefined, "Recommended")), true, []))
                                                                        ])), ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [React.createElement("div", {
                                                                                style: {
                                                                                  color: Colors$BsConsole.octothorpe(Colors$BsConsole.grey2),
                                                                                  display: "flex",
                                                                                  padding: "2em",
                                                                                  width: "100%",
                                                                                  justifyContent: "center"
                                                                                }
                                                                              }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Something went wrong")])))])));
                                                    }
                                                    var attributes = remote[0];
                                                    var match = self.state.tab;
                                                    var tmp;
                                                    switch (match) {
                                                      case /* Custom */0 :
                                                          tmp = ReasonReact.element(undefined, undefined, Curry._7(EnhancedCustomAttributesTable$BsConsole.EnhancedCustomAttributesTable.Jsx2.make, getCustomAttributes(attributes), update, self.state.shouldTableUpdate, (function (param) {
                                                                      return Curry._1(self.send, /* UpdateComplete */1);
                                                                    }), (function (attribute) {
                                                                      return Curry._1(self.send, /* Dialog */Block.__(1, [/* Delete */Block.__(0, [attribute])]));
                                                                    }), (function (attribute) {
                                                                      return Curry._1(self.send, /* Dialog */Block.__(1, [/* Modify */Block.__(2, [attribute])]));
                                                                    }), []));
                                                          break;
                                                      case /* Default */1 :
                                                          tmp = ReasonReact.element(undefined, undefined, Curry._6(EnhancedCustomAttributesTable$BsConsole.ActiveAttributesTable.Jsx2.make, getDefaultAttributes(attributes), update, undefined, self.state.shouldTableUpdate, (function (param) {
                                                                      return Curry._1(self.send, /* UpdateComplete */1);
                                                                    }), []));
                                                          break;
                                                      case /* Suggested */2 :
                                                          tmp = ReasonReact.element(undefined, undefined, Curry._6(EnhancedCustomAttributesTable$BsConsole.RecommendedAttributesTable.Jsx2.make, attributes.disabled, update, (function (attr) {
                                                                      return Curry._1(self.send, /* Dialog */Block.__(1, [/* Create */Block.__(1, [attr])]));
                                                                    }), self.state.shouldTableUpdate, (function (param) {
                                                                      return Curry._1(self.send, /* UpdateComplete */1);
                                                                    }), []));
                                                          break;
                                                      
                                                    }
                                                    return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, self.state.tab, {
                                                                        indicator: indicator
                                                                      }, (function (param, tab) {
                                                                          return Curry._1(self.send, /* Tab */Block.__(0, [tab > 2 || tab < 0 ? /* Default */1 : tab]));
                                                                        }), undefined, undefined, undefined, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Custom */0, Caml_option.some(I18N$BsConsole.show(undefined, "Custom")), true, [])),
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Default */1, Caml_option.some(I18N$BsConsole.show(undefined, "Default")), true, [])),
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, /* Suggested */2, Caml_option.some(List.length(attributes.disabled) > 0 ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                /* String_literal */Block.__(11, [
                                                                                                    "Recommended (",
                                                                                                    /* Int */Block.__(4, [
                                                                                                        /* Int_d */0,
                                                                                                        /* No_padding */0,
                                                                                                        /* No_precision */0,
                                                                                                        /* Char_literal */Block.__(12, [
                                                                                                            /* ")" */41,
                                                                                                            /* End_of_format */0
                                                                                                          ])
                                                                                                      ])
                                                                                                  ]),
                                                                                                "Recommended (%d)"
                                                                                              ]), List.length(attributes.disabled)) : I18N$BsConsole.show(undefined, "Recommended")), true, []))
                                                                      ])), ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [tmp])));
                                                  })))
                                      ])),
                              tmp
                            ]));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined,
                      tab: /* Custom */0,
                      shouldTableUpdate: false
                    };
            }),
          retainedProps: component$7.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* UpdateTable */0) {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              tab: state.tab,
                              shouldTableUpdate: true
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              dialog: state.dialog,
                              tab: state.tab,
                              shouldTableUpdate: false
                            }]);
                }
              }
              switch (action.tag | 0) {
                case /* Tab */0 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                tab: action[0],
                                shouldTableUpdate: state.shouldTableUpdate
                              }]);
                case /* Dialog */1 :
                    return /* Update */Block.__(0, [{
                                dialog: action[0],
                                tab: state.tab,
                                shouldTableUpdate: state.shouldTableUpdate
                              }]);
                case /* DialogWithTabUpdate */2 :
                    return /* Update */Block.__(0, [{
                                dialog: action[0],
                                tab: action[1],
                                shouldTableUpdate: state.shouldTableUpdate
                              }]);
                
              }
            }),
          jsElementWrapped: component$7.jsElementWrapped
        };
}

var GlobalStyles;

exports.renderHelperText = renderHelperText;
exports.hasError = hasError;
exports.TableWrapper = TableWrapper;
exports.SuggestedAttributesTable = SuggestedAttributesTable;
exports.DeleteDialog = DeleteDialog;
exports.formatCategoryDescription = formatCategoryDescription;
exports.Formats = Formats;
exports.formatMenuListItemTextSecondary = formatMenuListItemTextSecondary;
exports.Types_ = Types_;
exports.CreateDialog = CreateDialog;
exports.ModifyDialog = ModifyDialog;
exports.GlobalStyles = GlobalStyles;
exports.Styles = Styles$2;
exports.component = component$7;
exports.getDefaultAttributes = getDefaultAttributes;
exports.getCustomAttributes = getCustomAttributes;
exports.getRecommendedAttributes = getRecommendedAttributes;
exports.make = make$7;
/* table Not a pure module */
