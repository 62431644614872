// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Info = require("@material-ui/icons/Info");
var Tooltip = require("@material-ui/core/Tooltip");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");

function SubUtil$Info(Props) {
  var title = Props.title;
  return React.createElement(Tooltip.default, {
              children: React.createElement(Info.default, {
                    className: Css.style(/* :: */[
                          Css.marginLeft(Css.rem(0.5)),
                          /* [] */0
                        ]),
                    color: "inherit",
                    fontSize: "inherit"
                  }),
              title: title
            });
}

var Info$1 = {
  make: SubUtil$Info
};

var oneDayMs = 86400 * 1000;

function getDaysUntilNextMonth(todayFloat) {
  var today = new Date(todayFloat);
  var monthToday = today.getMonth();
  var yearToday = today.getFullYear();
  var nextMonth = (
    monthToday === 11 ? 0 : monthToday + 1.0
  ) + 1;
  var nextYear = monthToday === 11 ? yearToday + 1.0 : yearToday;
  var beginningOfNextMonth = new Date("" + (String(nextMonth) + ("/1/" + (String(nextYear) + ""))));
  return (beginningOfNextMonth.valueOf() - todayFloat) / oneDayMs;
}

function canModifyNemesisPlans(config) {
  var match = config.user.role;
  if (match === "admin") {
    return true;
  } else {
    return false;
  }
}

function canViewNemesisPlans(config) {
  var match = config.user.role;
  if (match === undefined) {
    return false;
  }
  switch (match) {
    case "guest" :
        return false;
    case "admin" :
    case "member" :
        return true;
    default:
      return false;
  }
}

function hasValidPaymentMethod(subscription, invoiceMe) {
  var match = subscription.cardOnFile;
  if (invoiceMe || !match) {
    return false;
  } else {
    return true;
  }
}

var unknownErrorMessage = I18N$BsConsole.get(undefined, "Unknown error: Please reach out to support for assistance.");

var onlyAdminsText = I18N$BsConsole.get(undefined, "Only admins can modify subscriptions and payment information.");

var paymentMethodSetupText = I18N$BsConsole.get(undefined, "Set up a payment method to modify your subscription.");

var paymentMethodSetupSubPageText = I18N$BsConsole.get(undefined, "Set up a payment method in the subscriptions tab to modify.");

var altPaymentText = I18N$BsConsole.get(undefined, "You've opted into our \"Invoice me\" feature. Our team can assist you with subscription modifications.");

function getPlanModificationTooltip(config, subscription, invoiceMe, subscriptionsPage) {
  var match = canModifyNemesisPlans(config);
  var match$1 = subscription.cardOnFile;
  if (match) {
    if (match$1) {
      if (invoiceMe) {
        return altPaymentText;
      } else {
        return I18N$BsConsole.empty(undefined);
      }
    } else if (invoiceMe) {
      return altPaymentText;
    } else if (subscriptionsPage) {
      return paymentMethodSetupText;
    } else {
      return paymentMethodSetupSubPageText;
    }
  } else {
    return onlyAdminsText;
  }
}

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* [] */0
    ]);

var marginBottom = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var titleText = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* :: */[
            Css.fontSize(Css.px(16)),
            /* :: */[
              Css.lineHeight(Css.px(19)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.black)),
                /* :: */[
                  Css.marginBottom(Css.px(15)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var titleTextNoMarginBottom = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* :: */[
            Css.fontSize(Css.px(16)),
            /* :: */[
              Css.lineHeight(Css.px(19)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.black)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var bodyText = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* normal */812216871),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.lineHeight(Css.px(18)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.black)),
                /* :: */[
                  Css.marginTop(Css.px(5)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  merge: Css.merge,
  featureDescription: featureDescription,
  marginBottom: marginBottom,
  titleText: titleText,
  titleTextNoMarginBottom: titleTextNoMarginBottom,
  bodyText: bodyText
};

function SubUtil$UsageHeader(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: marginBottom,
                  children: I18N$BsConsole.show(undefined, "Usage")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "View your feature limits and current usage.")
                }));
}

var UsageHeader = {
  make: SubUtil$UsageHeader
};

function SubUtil$PaymentsHeader(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: marginBottom,
                  children: I18N$BsConsole.show(undefined, "Payment method")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottom,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "A payment method is required to modify your Backtrace plan and extend your feature limits.")
                }));
}

var PaymentsHeader = {
  make: SubUtil$PaymentsHeader
};

var IncrementerDirection = { };

function getNoMoreUsersPermitted(limits, currentUserCount) {
  var _gate = limits.metadata.gate;
  if (!_gate.tag) {
    return false;
  }
  var userLimitMaybe = ApiBillingLimits$BsConsole.getUserLimit(limits);
  return !ApiBillingLimits$BsConsole.moreUsersPermitted(currentUserCount, userLimitMaybe);
}

function getInvoiceMe(limits) {
  var match = limits.metadata.invoiceMe;
  if (match !== undefined && match) {
    return true;
  } else {
    return false;
  }
}

var oneDaySeconds = 86400;

exports.Info = Info$1;
exports.oneDaySeconds = oneDaySeconds;
exports.oneDayMs = oneDayMs;
exports.getDaysUntilNextMonth = getDaysUntilNextMonth;
exports.canModifyNemesisPlans = canModifyNemesisPlans;
exports.canViewNemesisPlans = canViewNemesisPlans;
exports.hasValidPaymentMethod = hasValidPaymentMethod;
exports.unknownErrorMessage = unknownErrorMessage;
exports.onlyAdminsText = onlyAdminsText;
exports.paymentMethodSetupText = paymentMethodSetupText;
exports.paymentMethodSetupSubPageText = paymentMethodSetupSubPageText;
exports.altPaymentText = altPaymentText;
exports.getPlanModificationTooltip = getPlanModificationTooltip;
exports.Style = Style;
exports.UsageHeader = UsageHeader;
exports.PaymentsHeader = PaymentsHeader;
exports.IncrementerDirection = IncrementerDirection;
exports.getNoMoreUsersPermitted = getNoMoreUsersPermitted;
exports.getInvoiceMe = getInvoiceMe;
/* unknownErrorMessage Not a pure module */
