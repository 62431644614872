// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Chip$BsConsole = require("../Chip.js");
var Crdb$BsConsole = require("../crdb.js");
var Form$BsConsole = require("../Form.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("../util.js");
var Table$BsConsole = require("../table.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var UiSelect$BsConsole = require("../UiSelect.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var UtilWindow$BsConsole = require("../UtilWindow.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var WfComponents$BsConsole = require("../workflow/WfComponents.js");
var BpgController$BsConsole = require("../BpgController.js");
var FetchDescribe$BsConsole = require("../FetchDescribe.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SettingsDialog$BsConsole = require("../SettingsDialog.js");
var HideFeatureGate$BsConsole = require("../HideFeatureGate.js");
var FilterShellLegacy$BsConsole = require("../FilterShellLegacy.js");
var ApiScheduledReports$BsConsole = require("./scheduled-reports/ApiScheduledReports.js");
var ScheduledReportsData$BsConsole = require("./ScheduledReportsData.js");
var ScheduledReportsForm$BsConsole = require("./scheduled-reports/ScheduledReportsForm.js");
var ScheduledReportsTable$BsConsole = require("./scheduled-reports/ScheduledReportsTable.js");

var component = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__LegacyPreviewReport");

function make(token, onSuccess, onFailure, email, children) {
  var previewTask = function (reportId) {
    var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/report"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                  /* tuple */[
                    "action",
                    "send"
                  ],
                  /* :: */[
                    /* tuple */[
                      "form",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "id",
                              reportId
                            ],
                            /* :: */[
                              /* tuple */[
                                "rcpt",
                                email
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ])]), undefined, (function (param) {
            return /* Ok */Block.__(0, [undefined]);
          }), undefined, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  if (param.tag) {
                    return Curry._1(onFailure, undefined);
                  } else {
                    return Curry._1(onSuccess, undefined);
                  }
                }));
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return Curry._1(children, previewTask);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var LegacyPreviewReport = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__ReportHistogram");

function make$1(token, handleTask, name, histograms, onAdd, onDelete, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              var label = I18N$BsConsole.get(undefined, "Add attribute histogram");
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, undefined, true, [ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.make(handleTask, token, name, (function (remote, param) {
                                          if (typeof remote === "number") {
                                            remote === /* NotAsked */0;
                                          } else {
                                            if (remote.tag) {
                                              return null;
                                            }
                                            var filteredAttributes = remote[0][0];
                                            return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                                                  return React.createElement("div", {
                                                                              ref: refHandler,
                                                                              style: {
                                                                                display: "flex",
                                                                                margin: "0 16px",
                                                                                alignItems: "center"
                                                                              },
                                                                              onFocus: onFocus,
                                                                              onClick: onClick
                                                                            }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Add.make(label, [])));
                                                                }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                                                  return ReasonReact.element(undefined, undefined, UiSelect$BsConsole.Inputs.make(filteredAttributes, onClose, (function (a) {
                                                                                    return Curry._1(onAdd, a.name);
                                                                                  }), undefined, []));
                                                                }), undefined, [])), Belt_Array.map(histograms, (function (attribute) {
                                                              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                                                                return React.createElement("div", {
                                                                                            ref: refHandler,
                                                                                            onFocus: onFocus,
                                                                                            onClick: onClick
                                                                                          }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Select.make(attribute, (function (param) {
                                                                                                      return Curry._1(onDelete, attribute);
                                                                                                    }), [])));
                                                                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                                                                return ReasonReact.element(undefined, undefined, UiSelect$BsConsole.Inputs.make(filteredAttributes, onClose, (function (a) {
                                                                                                  if (a.name !== attribute) {
                                                                                                    Curry._1(onDelete, attribute);
                                                                                                    return Curry._1(onAdd, a.name);
                                                                                                  }
                                                                                                  
                                                                                                }), Caml_option.some(Belt_List.getBy(filteredAttributes, (function (attr) {
                                                                                                          return attr.name === attribute;
                                                                                                        }))), []));
                                                                              }), undefined, []));
                                                            })));
                                          }
                                          return React.createElement("div", {
                                                      style: {
                                                        display: "flex",
                                                        margin: "0 16px",
                                                        alignItems: "center"
                                                      }
                                                    }, ReasonReact.element(undefined, undefined, Chip$BsConsole.Add.make(I18N$BsConsole.get(undefined, "Loading..."), [])));
                                        })))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var ReportHistograms = {
  component: component$1,
  make: make$1
};

function Make(F) {
  var select = Css.style(/* :: */[
        Css.marginRight(Css.px(8)),
        /* [] */0
      ]);
  var selectOption = Css.style(/* :: */[
        Css.padding2(Css.zero, Css.px(7)),
        /* [] */0
      ]);
  var component = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");
  var make = function (considerations, onChange, value, _children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (_self) {
                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                undefined,
                                /* `String */[
                                  -976970511,
                                  value
                                ],
                                onChange,
                                undefined,
                                undefined,
                                select + " project-settings-select",
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [Belt_Array.map(considerations, (function (option) {
                                          return ReasonReact.element(Curry._1(F.toString, option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                          -976970511,
                                                          Curry._1(F.toValue, option)
                                                        ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(Curry._1(F.toString, option))]))]));
                                        }))]
                              ]));
              }),
            initialState: component.initialState,
            retainedProps: component.retainedProps,
            reducer: component.reducer,
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          select: select,
          selectOption: selectOption,
          component: component,
          make: make
        };
}

var Select = {
  Make: Make
};

var container = Css.style(/* :: */[
      Css.position(Css.relative),
      /* [] */0
    ]);

var closeButton = Css.style(/* :: */[
      Css.unsafe("position", "absolute !important"),
      /* :: */[
        Css.top(Css.px(12)),
        /* :: */[
          Css.right(Css.px(12)),
          /* [] */0
        ]
      ]
    ]);

var emailRecipient = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.blueDark))),
      /* :: */[
        Css.unsafe("color", Colors$BsConsole.octothorpe(Colors$BsConsole.white) + " !important"),
        /* :: */[
          Css.margin(Css.px(3)),
          /* [] */0
        ]
      ]
    ]);

var emailDeleteIcon = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.blueBackground))),
      /* [] */0
    ]);

var button = Css.style(/* :: */[
      Css.unsafe("marginLeft", "8px !important"),
      /* [] */0
    ]);

function ofJson(json) {
  return {
          attributes: Json_decode.field("attributes", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var Top = {
  ofJson: ofJson
};

function ofJson$1(json) {
  var __x = Js_dict.entries(Belt_List.headExn(Json_decode.field("filter", (function (param) {
                  return Json_decode.list((function (param) {
                                return Json_decode.dict((function (json) {
                                              return Belt_List.keepMap(Json_decode.list((function (json) {
                                                                try {
                                                                  return Crdb$BsConsole.FilterOperation.fromJson(json);
                                                                }
                                                                catch (raw_err){
                                                                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                                                  console.log(err);
                                                                  return ;
                                                                }
                                                              }), json), (function (f) {
                                                            return f;
                                                          }));
                                            }), param);
                              }), param);
                }), json)));
  return {
          top: Json_decode.field("top", (function (param) {
                  return Json_decode.array(ofJson, param);
                }), json),
          filter: Belt_Array.reduce(__x, Crdb$BsConsole.Filters.empty, (function (acc, param) {
                  var attribute = param[0];
                  if (attribute === "timestamp") {
                    return acc;
                  } else {
                    return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                                  return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                  attribute,
                                                  operation
                                                ]), acc);
                                }));
                  }
                }))
        };
}

var Widgets = {
  Top: Top,
  ofJson: ofJson$1
};

function toString(t$prime) {
  return t$prime.label;
}

function toValue(t$prime) {
  return t$prime.value;
}

var select = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var selectOption = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(7)),
      /* [] */0
    ]);

var component$2 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");

function make$2(considerations, onChange, value, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              /* `String */[
                                -976970511,
                                value
                              ],
                              onChange,
                              undefined,
                              undefined,
                              select + " project-settings-select",
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [Belt_Array.map(considerations, (function (option) {
                                        return ReasonReact.element(toString(option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                        -976970511,
                                                        toValue(option)
                                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(toString(option))]))]));
                                      }))]
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var FrequencySelect = {
  select: select,
  selectOption: selectOption,
  component: component$2,
  make: make$2
};

function toString$1(t$prime) {
  return t$prime.label;
}

function toValue$1(t$prime) {
  return t$prime.value;
}

var select$1 = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var selectOption$1 = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(7)),
      /* [] */0
    ]);

var component$3 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");

function make$3(considerations, onChange, value, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              /* `String */[
                                -976970511,
                                value
                              ],
                              onChange,
                              undefined,
                              undefined,
                              select$1 + " project-settings-select",
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [Belt_Array.map(considerations, (function (option) {
                                        return ReasonReact.element(toString$1(option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                        -976970511,
                                                        toValue$1(option)
                                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption$1, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(toString$1(option))]))]));
                                      }))]
                            ]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var DaySelect = {
  select: select$1,
  selectOption: selectOption$1,
  component: component$3,
  make: make$3
};

function toString$2(t$prime) {
  return t$prime.label;
}

function toValue$2(t$prime) {
  return t$prime.value;
}

var select$2 = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var selectOption$2 = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(7)),
      /* [] */0
    ]);

var component$4 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");

function make$4(considerations, onChange, value, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              /* `String */[
                                -976970511,
                                value
                              ],
                              onChange,
                              undefined,
                              undefined,
                              select$2 + " project-settings-select",
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [Belt_Array.map(considerations, (function (option) {
                                        return ReasonReact.element(toString$2(option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                        -976970511,
                                                        toValue$2(option)
                                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption$2, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(toString$2(option))]))]));
                                      }))]
                            ]));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var HourSelect = {
  select: select$2,
  selectOption: selectOption$2,
  component: component$4,
  make: make$4
};

function toString$3(t$prime) {
  return t$prime.label;
}

function toValue$3(t$prime) {
  return t$prime.value;
}

var select$3 = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var selectOption$3 = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(7)),
      /* [] */0
    ]);

var component$5 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");

function make$5(considerations, onChange, value, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              /* `String */[
                                -976970511,
                                value
                              ],
                              onChange,
                              undefined,
                              undefined,
                              select$3 + " project-settings-select",
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [Belt_Array.map(considerations, (function (option) {
                                        return ReasonReact.element(toString$3(option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                        -976970511,
                                                        toValue$3(option)
                                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption$3, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(toString$3(option))]))]));
                                      }))]
                            ]));
            }),
          initialState: component$5.initialState,
          retainedProps: component$5.retainedProps,
          reducer: component$5.reducer,
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var AmOrPmSelect = {
  select: select$3,
  selectOption: selectOption$3,
  component: component$5,
  make: make$5
};

function toString$4(t$prime) {
  return t$prime.label;
}

function toValue$4(t$prime) {
  return t$prime.value;
}

var select$4 = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var selectOption$4 = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(7)),
      /* [] */0
    ]);

var component$6 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Select");

function make$6(considerations, onChange, value, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              /* `String */[
                                -976970511,
                                value
                              ],
                              onChange,
                              undefined,
                              undefined,
                              select$4 + " project-settings-select",
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [Belt_Array.map(considerations, (function (option) {
                                        return ReasonReact.element(toString$4(option), undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                        -976970511,
                                                        toValue$4(option)
                                                      ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, selectOption$4, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(toString$4(option))]))]));
                                      }))]
                            ]));
            }),
          initialState: component$6.initialState,
          retainedProps: component$6.retainedProps,
          reducer: component$6.reducer,
          jsElementWrapped: component$6.jsElementWrapped
        };
}

var TimezoneSelect = {
  select: select$4,
  selectOption: selectOption$4,
  component: component$6,
  make: make$6
};

var timeAperture = Crdb$BsConsole.granularityStringToTimeAperture("month");

function toUtcHour(timezone, hour) {
  return hour - ScheduledReportsData$BsConsole.getTimezoneOffset(timezone) % 24 | 0;
}

var component$7 = RR$BsConsole.reducerComponent("PS_ScheduledReports-BsConsole__LegacyReportsForm");

function make$7(goBack, token, handleTask, name, shell, project, onSuccess, reportOpt, _children) {
  var report = reportOpt !== undefined ? Caml_option.valFromOption(reportOpt) : undefined;
  return {
          debugName: component$7.debugName,
          reactClassInternal: component$7.reactClassInternal,
          handedOffState: component$7.handedOffState,
          willReceiveProps: component$7.willReceiveProps,
          didMount: (function (self) {
              if (report === undefined) {
                return ;
              }
              var include_users = report.include_users === 1;
              var rcpt$prime = report.rcpt;
              var rcpt = rcpt$prime !== undefined ? (
                  rcpt$prime.trim() !== "" ? rcpt$prime.split(" ") : []
                ) : [];
              var widgets;
              try {
                widgets = ofJson$1(JSON.parse(report.widgets));
              }
              catch (raw__msg){
                var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
                widgets = _msg[0] === Json_decode.DecodeError ? ({
                      top: [{
                          attributes: []
                        }],
                      filter: Crdb$BsConsole.Filters.empty
                    }) : ({
                      top: [{
                          attributes: []
                        }],
                      filter: Crdb$BsConsole.Filters.empty
                    });
              }
              var attributes = widgets.top.length !== 0 ? widgets.top[0].attributes : [];
              return Curry._1(self.send, /* SetReport */Block.__(7, [
                            include_users,
                            include_users,
                            rcpt,
                            Curry._2(Crdb$BsConsole.Aperture.setFilters, widgets.filter, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default)),
                            attributes
                          ]));
            }),
          didUpdate: component$7.didUpdate,
          willUnmount: component$7.willUnmount,
          willUpdate: component$7.willUpdate,
          shouldUpdate: component$7.shouldUpdate,
          render: (function (self) {
              var tmp;
              if (typeof shell === "number") {
                tmp = shell === /* NotAsked */0 ? null : ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, []))]));
              } else if (shell.tag) {
                tmp = null;
              } else {
                var config = shell[0];
                tmp = ReasonReact.element(undefined, undefined, make(token, (function (param) {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Preview report sent"));
                          }), (function (param) {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error sending preview"));
                          }), config.user.email, (function (sendReport) {
                            return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Report.Modify.make, (function (_result, updatedEntity) {
                                              Curry._1(onSuccess, undefined);
                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully modified report"));
                                              if (self.state.preview) {
                                                setTimeout((function (param) {
                                                        Curry._1(self.send, /* SetPreview */Block.__(8, [false]));
                                                        return Curry._1(sendReport, updatedEntity.id);
                                                      }), 500);
                                                return ;
                                              }
                                              
                                            }), handleTask, token, (function (_state, handleModify, _toKey) {
                                              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Report.Create.make, (function (result, _updatedEntity) {
                                                                Curry._1(onSuccess, undefined);
                                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created new report"));
                                                                if (self.state.preview) {
                                                                  setTimeout((function (param) {
                                                                          Curry._1(self.send, /* SetPreview */Block.__(8, [false]));
                                                                          return Curry._1(sendReport, result.id);
                                                                        }), 500);
                                                                  return ;
                                                                }
                                                                
                                                              }), handleTask, token, (function (_state, handleSubmit, _toKey) {
                                                                var tmp;
                                                                if (report !== undefined) {
                                                                  var timezoneOffset = ScheduledReportsData$BsConsole.getTimezoneOffset(report.timezone);
                                                                  var rawHour = timezoneOffset + report.hour | 0;
                                                                  var rawDay = rawHour > 24 ? report.day + 1 | 0 : report.day;
                                                                  var hour = rawHour % 24;
                                                                  var day = rawDay % 7;
                                                                  tmp = {
                                                                    title: report.title,
                                                                    rcpt: "",
                                                                    day: String(day),
                                                                    period: report.period,
                                                                    timezone: report.timezone,
                                                                    hour: String(hour > 12 ? hour - 12 | 0 : hour),
                                                                    amOrPm: hour > 12 ? "pm" : "am"
                                                                  };
                                                                } else {
                                                                  tmp = {
                                                                    title: "",
                                                                    rcpt: "",
                                                                    day: "1",
                                                                    period: "day",
                                                                    timezone: "America/New_York",
                                                                    hour: "12",
                                                                    amOrPm: "am"
                                                                  };
                                                                }
                                                                return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.ScheduledReportForm.make, (function (param) {
                                                                                  var resetFormState = param.resetFormState;
                                                                                  var setError = param.setError;
                                                                                  var setSubmitting = param.setSubmitting;
                                                                                  var values = param.values;
                                                                                  var widgets = JSON.stringify(Json_encode.object_(/* :: */[
                                                                                            /* tuple */[
                                                                                              "feed",
                                                                                              Json_encode.object_(/* :: */[
                                                                                                    /* tuple */[
                                                                                                      "limit",
                                                                                                      5
                                                                                                    ],
                                                                                                    /* [] */0
                                                                                                  ])
                                                                                            ],
                                                                                            /* :: */[
                                                                                              /* tuple */[
                                                                                                "top",
                                                                                                [Json_encode.object_(/* :: */[
                                                                                                        /* tuple */[
                                                                                                          "attributes",
                                                                                                          self.state.histograms
                                                                                                        ],
                                                                                                        /* [] */0
                                                                                                      ])]
                                                                                              ],
                                                                                              /* :: */[
                                                                                                /* tuple */[
                                                                                                  "filter",
                                                                                                  [Curry._1(Crdb$BsConsole.Filters.toCrdb, Curry._1(Crdb$BsConsole.Aperture.getFilters, self.state.aperture))]
                                                                                                ],
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ]));
                                                                                  var rawHour = Caml_format.caml_int_of_string(values.hour) + (
                                                                                    values.amOrPm === "pm" ? 12 : 0
                                                                                  ) | 0;
                                                                                  var utcHour = toUtcHour(values.timezone, rawHour);
                                                                                  var day = Caml_format.caml_int_of_string(values.hour) > utcHour ? Caml_format.caml_int_of_string(values.day) + 1 | 0 : Caml_format.caml_int_of_string(values.day);
                                                                                  var include_users = self.state.include_users ? 1 : 0;
                                                                                  var rcpt = self.state.rcpt.length !== 0 ? self.state.rcpt.join(" ") : "";
                                                                                  if (report !== undefined) {
                                                                                    return Curry._1(handleModify, /* tuple */[
                                                                                                report,
                                                                                                Util$BsConsole.identity,
                                                                                                Form$BsConsole.Fields.set("widgets", widgets, Form$BsConsole.Fields.set("hour", String(utcHour), Form$BsConsole.Fields.set("timezone", values.timezone, Form$BsConsole.Fields.set("period", values.period, Form$BsConsole.Fields.set("day", String(day), Form$BsConsole.Fields.set("rcpt", Belt_Option.getWithDefault(rcpt, ""), Form$BsConsole.Fields.set("title", values.title, Form$BsConsole.Fields.set("include_users", String(include_users), Form$BsConsole.Fields.empty)))))))),
                                                                                                setError,
                                                                                                setSubmitting,
                                                                                                resetFormState
                                                                                              ]);
                                                                                  } else {
                                                                                    return Curry._1(handleSubmit, /* tuple */[
                                                                                                {
                                                                                                  id: 0,
                                                                                                  owner: config.user.uid,
                                                                                                  project: project,
                                                                                                  include_users: include_users,
                                                                                                  title: values.title,
                                                                                                  rcpt: rcpt,
                                                                                                  day: day,
                                                                                                  period: values.period,
                                                                                                  timezone: values.timezone,
                                                                                                  hour: utcHour,
                                                                                                  widgets: widgets,
                                                                                                  metadata: undefined,
                                                                                                  __state: undefined,
                                                                                                  __create: undefined,
                                                                                                  __modify: undefined
                                                                                                },
                                                                                                Util$BsConsole.identity,
                                                                                                setError,
                                                                                                setSubmitting,
                                                                                                resetFormState
                                                                                              ]);
                                                                                  }
                                                                                }), undefined, undefined, tmp, /* :: */[
                                                                                /* tuple */[
                                                                                  /* Title */594052472,
                                                                                  /* Required */0
                                                                                ],
                                                                                /* :: */[
                                                                                  /* tuple */[
                                                                                    /* Rcpt */914292757,
                                                                                    /* Custom */[(function (values) {
                                                                                          if (values.rcpt !== "" && !/\S+@\S+\.\S+/.test(values.rcpt)) {
                                                                                            return I18N$BsConsole.get(undefined, "Invalid email");
                                                                                          }
                                                                                          
                                                                                        })]
                                                                                  ],
                                                                                  /* :: */[
                                                                                    /* tuple */[
                                                                                      /* Day */3403324,
                                                                                      /* Required */0
                                                                                    ],
                                                                                    /* :: */[
                                                                                      /* tuple */[
                                                                                        /* Period */-273689311,
                                                                                        /* Required */0
                                                                                      ],
                                                                                      /* :: */[
                                                                                        /* tuple */[
                                                                                          /* Timezone */-533547815,
                                                                                          /* Required */0
                                                                                        ],
                                                                                        /* :: */[
                                                                                          /* tuple */[
                                                                                            /* Hour */803994948,
                                                                                            /* Required */0
                                                                                          ],
                                                                                          /* :: */[
                                                                                            /* tuple */[
                                                                                              /* AmOrPm */-194096852,
                                                                                              /* Required */0
                                                                                            ],
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ], undefined, (function (param) {
                                                                                  var getErrorForField = param.getErrorForField;
                                                                                  var handleSubmit = param.handleSubmit;
                                                                                  var handleChange = param.handleChange;
                                                                                  var form = param.form;
                                                                                  var partial_arg = Curry._1(handleChange, /* Title */594052472);
                                                                                  return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Setup")])),
                                                                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                                      "project-settings-textfield",
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (param) {
                                                                                                                          return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      Belt_Option.isSome(Curry._1(getErrorForField, /* Title */594052472)),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      /* `String */[
                                                                                                                        -976970511,
                                                                                                                        form.values.title
                                                                                                                      ],
                                                                                                                      undefined,
                                                                                                                      true,
                                                                                                                      Caml_option.some(I18N$BsConsole.show(undefined, "Title")),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      []
                                                                                                                    ]))])),
                                                                                                  React.createElement("br", undefined),
                                                                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Frequency ")])),
                                                                                                            ReasonReact.element(undefined, undefined, make$2(ScheduledReportsData$BsConsole.frequency, (function ($$event, param) {
                                                                                                                        return Curry._2(handleChange, /* Period */-273689311, $$event.target.value);
                                                                                                                      }), form.values.period, [])),
                                                                                                            form.values.period === "week" ? React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Day ")])), ReasonReact.element(undefined, undefined, make$3(ScheduledReportsData$BsConsole.days, (function ($$event, param) {
                                                                                                                              return Curry._2(handleChange, /* Day */3403324, $$event.target.value);
                                                                                                                            }), form.values.day, []))) : null
                                                                                                          ])),
                                                                                                  React.createElement("br", undefined),
                                                                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Time ")])),
                                                                                                            ReasonReact.element(undefined, undefined, make$4(ScheduledReportsData$BsConsole.hours, (function ($$event, param) {
                                                                                                                        return Curry._2(handleChange, /* Hour */803994948, $$event.target.value);
                                                                                                                      }), form.values.hour, [])),
                                                                                                            ReasonReact.element(undefined, undefined, make$5(ScheduledReportsData$BsConsole.amOrPm, (function ($$event, param) {
                                                                                                                        return Curry._2(handleChange, /* AmOrPm */-194096852, $$event.target.value);
                                                                                                                      }), form.values.amOrPm, [])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Timezone ")])),
                                                                                                            ReasonReact.element(undefined, undefined, make$6(Belt_List.toArray(Belt_List.sort(ScheduledReportsData$BsConsole.timezones, (function (a, b) {
                                                                                                                                if (a.label > b.label) {
                                                                                                                                  return 1;
                                                                                                                                } else {
                                                                                                                                  return -1;
                                                                                                                                }
                                                                                                                              }))), (function ($$event, param) {
                                                                                                                        return Curry._2(handleChange, /* Timezone */-533547815, $$event.target.value);
                                                                                                                      }), form.values.timezone, []))
                                                                                                          ])),
                                                                                                  React.createElement("br", undefined),
                                                                                                  ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                                                                  React.createElement("br", undefined),
                                                                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Recipients")])),
                                                                                                  ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(!self.state.showMessageCheckbox, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                                                                                      ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Send to all organization members ")])),
                                                                                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                                                                undefined,
                                                                                                                                /* `Bool */[
                                                                                                                                  737456202,
                                                                                                                                  self.state.include_users
                                                                                                                                ],
                                                                                                                                undefined,
                                                                                                                                self.state.include_users ? /* Primary */-791844958 : /* Default */-384499551,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                true,
                                                                                                                                (function (param, checked) {
                                                                                                                                    return Curry._1(self.send, /* SetIncUsers */Block.__(0, [checked]));
                                                                                                                                  }),
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                undefined,
                                                                                                                                []
                                                                                                                              ]))
                                                                                                                    ]))])),
                                                                                                  self.state.rcpt.length !== 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Wrap */970483178, undefined, undefined, undefined, undefined, [Belt_Array.mapWithIndex(self.state.rcpt, (function (idx, email) {
                                                                                                                                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Chip.Jsx2.make, [
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                Caml_option.some(I18N$BsConsole.show(undefined, email)),
                                                                                                                                                undefined,
                                                                                                                                                undefined,
                                                                                                                                                (function (param) {
                                                                                                                                                    return Curry._1(self.send, /* RemoveRcpt */Block.__(3, [/* tuple */[
                                                                                                                                                                    email,
                                                                                                                                                                    idx
                                                                                                                                                                  ]]));
                                                                                                                                                  }),
                                                                                                                                                undefined,
                                                                                                                                                /* Primary */-791844958,
                                                                                                                                                []
                                                                                                                                              ]));
                                                                                                                              }))]))])) : null,
                                                                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                            React.createElement("div", {
                                                                                                                  onKeyDown: (function ($$event) {
                                                                                                                      var keycode = $$event.keyCode;
                                                                                                                      if (keycode !== 13 || form.values.rcpt.trim() === "" || Belt_Option.isSome(Curry._1(getErrorForField, /* Rcpt */914292757))) {
                                                                                                                        return ;
                                                                                                                      } else {
                                                                                                                        Curry._1(self.send, /* AddRcpt */Block.__(2, [form.values.rcpt]));
                                                                                                                        return Curry._2(handleChange, /* Rcpt */914292757, "");
                                                                                                                      }
                                                                                                                    })
                                                                                                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                                                          "project-settings-textfield",
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          (function ($$event) {
                                                                                                                              Curry._2(handleChange, /* Rcpt */914292757, $$event.target.value);
                                                                                                                              console.log(Curry._1(getErrorForField, /* Rcpt */914292757));
                                                                                                                              
                                                                                                                            }),
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          Belt_Option.isSome(Curry._1(getErrorForField, /* Rcpt */914292757)) && form.values.rcpt !== "",
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          /* `String */[
                                                                                                                            -976970511,
                                                                                                                            form.values.rcpt
                                                                                                                          ],
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          Caml_option.some(I18N$BsConsole.show(undefined, "Add recipient email")),
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          []
                                                                                                                        ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          (function (param) {
                                                                                                                              Curry._1(self.send, /* AddRcpt */Block.__(2, [form.values.rcpt]));
                                                                                                                              return Curry._2(handleChange, /* Rcpt */914292757, "");
                                                                                                                            }),
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          form.values.rcpt.trim() === "" || Belt_Option.isSome(Curry._1(getErrorForField, /* Rcpt */914292757)),
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          undefined,
                                                                                                                          "Add recipient",
                                                                                                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                                        ]))),
                                                                                                            React.createElement("br", undefined),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                                                                            React.createElement("br", undefined),
                                                                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Customize report content")])),
                                                                                                            React.createElement("br", undefined),
                                                                                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                                      ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Filter report content ")])),
                                                                                                                      ReasonReact.element(undefined, undefined, FilterShellLegacy$BsConsole.make((function (fm) {
                                                                                                                                  return Curry._1(self.send, /* SetFilters */Block.__(4, [fm]));
                                                                                                                                }), name, handleTask, self.state.aperture, token, []))
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                                                                      ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.InputLabel.Jsx2.make, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Attribute content (histograms)")])),
                                                                                                                      ReasonReact.element(undefined, undefined, make$1(token, handleTask, name, self.state.histograms, (function (attr) {
                                                                                                                                  return Curry._1(self.send, /* AddHistogram */Block.__(5, [attr]));
                                                                                                                                }), (function (attr) {
                                                                                                                                  return Curry._1(self.send, /* RemoveHistogram */Block.__(6, [attr]));
                                                                                                                                }), []))
                                                                                                                    ]))
                                                                                                          ])),
                                                                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (param) {
                                                                                                                          Curry._1(self.send, /* SetShowMessageCheckbox */Block.__(9, [self.state.include_users]));
                                                                                                                          Curry._1(self.send, /* SetPreview */Block.__(8, [true]));
                                                                                                                          return Curry._1(handleSubmit, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      /* Raised */-387916264,
                                                                                                                      /* Secondary */67972948,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      form.values.title === "",
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      {
                                                                                                                        root: button
                                                                                                                      },
                                                                                                                      [I18N$BsConsole.show(undefined, "Save and preview report")]
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (param) {
                                                                                                                          Curry._1(self.send, /* SetShowMessageCheckbox */Block.__(9, [self.state.include_users]));
                                                                                                                          return Curry._1(handleSubmit, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      /* Raised */-387916264,
                                                                                                                      /* Secondary */67972948,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      form.values.title === "",
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      {
                                                                                                                        root: button
                                                                                                                      },
                                                                                                                      [I18N$BsConsole.show(undefined, "Save report")]
                                                                                                                    ])),
                                                                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      (function (param) {
                                                                                                                          return Curry._1(goBack, undefined);
                                                                                                                        }),
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      undefined,
                                                                                                                      {
                                                                                                                        root: button
                                                                                                                      },
                                                                                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                                                                                    ]))
                                                                                                          ]))
                                                                                                ]));
                                                                                })));
                                                              })));
                                            })));
                          })));
              }
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, container, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                  closeButton,
                                                  undefined,
                                                  (function (param) {
                                                      return Curry._1(goBack, undefined);
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  "Close",
                                                  [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                ])),
                                        tmp
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      include_users: false,
                      showMessageCheckbox: false,
                      rcpt: [],
                      aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default),
                      histograms: [],
                      preview: false
                    };
            }),
          retainedProps: component$7.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetIncUsers */0 :
                    return /* Update */Block.__(0, [{
                                include_users: action[0],
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: state.rcpt,
                                aperture: state.aperture,
                                histograms: state.histograms,
                                preview: state.preview
                              }]);
                case /* SetRcpt */1 :
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: action[0],
                                aperture: state.aperture,
                                histograms: state.histograms,
                                preview: state.preview
                              }]);
                case /* AddRcpt */2 :
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: Belt_Array.concat(state.rcpt, [action[0]]),
                                aperture: state.aperture,
                                histograms: state.histograms,
                                preview: state.preview
                              }]);
                case /* RemoveRcpt */3 :
                    var match = action[0];
                    var idx = match[1];
                    var str = match[0];
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: Belt_Array.keepWithIndex(state.rcpt, (function (rcpt, index) {
                                        return !(rcpt === str && idx === index);
                                      })),
                                aperture: state.aperture,
                                histograms: state.histograms,
                                preview: state.preview
                              }]);
                case /* SetFilters */4 :
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: state.rcpt,
                                aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, action[0], state.aperture),
                                histograms: state.histograms,
                                preview: state.preview
                              }]);
                case /* AddHistogram */5 :
                    var histogram = action[0];
                    if (state.histograms.includes(histogram)) {
                      return /* NoUpdate */0;
                    } else {
                      return /* Update */Block.__(0, [{
                                  include_users: state.include_users,
                                  showMessageCheckbox: state.showMessageCheckbox,
                                  rcpt: state.rcpt,
                                  aperture: state.aperture,
                                  histograms: Belt_Array.concat(state.histograms, [histogram]),
                                  preview: state.preview
                                }]);
                    }
                case /* RemoveHistogram */6 :
                    var histogram$1 = action[0];
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: state.rcpt,
                                aperture: state.aperture,
                                histograms: Belt_Array.keep(state.histograms, (function (h) {
                                        return h !== histogram$1;
                                      })),
                                preview: state.preview
                              }]);
                case /* SetReport */7 :
                    return /* Update */Block.__(0, [{
                                include_users: action[0],
                                showMessageCheckbox: action[1],
                                rcpt: action[2],
                                aperture: action[3],
                                histograms: action[4],
                                preview: state.preview
                              }]);
                case /* SetPreview */8 :
                    return /* Update */Block.__(0, [{
                                include_users: state.include_users,
                                showMessageCheckbox: state.showMessageCheckbox,
                                rcpt: state.rcpt,
                                aperture: state.aperture,
                                histograms: state.histograms,
                                preview: action[0]
                              }]);
                case /* SetShowMessageCheckbox */9 :
                    if (action[0]) {
                      return /* NoUpdate */0;
                    } else {
                      return /* Update */Block.__(0, [{
                                  include_users: state.include_users,
                                  showMessageCheckbox: false,
                                  rcpt: state.rcpt,
                                  aperture: state.aperture,
                                  histograms: state.histograms,
                                  preview: state.preview
                                }]);
                    }
                
              }
            }),
          jsElementWrapped: component$7.jsElementWrapped
        };
}

var LegacyReportsForm = {
  container: container,
  closeButton: closeButton,
  emailRecipient: emailRecipient,
  emailDeleteIcon: emailDeleteIcon,
  button: button,
  Widgets: Widgets,
  FrequencySelect: FrequencySelect,
  DaySelect: DaySelect,
  HourSelect: HourSelect,
  AmOrPmSelect: AmOrPmSelect,
  TimezoneSelect: TimezoneSelect,
  timeAperture: timeAperture,
  toUtcHour: toUtcHour,
  component: component$7,
  make: make$7
};

var component$8 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__LegacyDeleteReportDialog");

function make$8(report, token, handleTask, onClose, onSuccess, _children) {
  return {
          debugName: component$8.debugName,
          reactClassInternal: component$8.reactClassInternal,
          handedOffState: component$8.handedOffState,
          willReceiveProps: component$8.willReceiveProps,
          didMount: component$8.didMount,
          didUpdate: component$8.didUpdate,
          willUnmount: component$8.willUnmount,
          willUpdate: component$8.willUpdate,
          shouldUpdate: component$8.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Report.Delete.make, (function (param, param$1) {
                                Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                return Curry._1(onSuccess, undefined);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var msg = state.remote;
                                var tmp;
                                tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])]))]));
                                var _msg = state.remote;
                                var tmp$1;
                                tmp$1 = typeof _msg === "number" ? (
                                    _msg === /* NotAsked */0 ? false : true
                                  ) : (
                                    _msg.tag ? true : false
                                  );
                                return ReasonReact.element(undefined, undefined, SettingsDialog$BsConsole.make(onClose, I18N$BsConsole.get(undefined, "Delete report"), undefined, [
                                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                          tmp,
                                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Are you sure you would like to delete this report?")]))
                                                        ])),
                                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, undefined, [
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    Curry.__1(onClose),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    undefined,
                                                                    undefined,
                                                                    (function (_event) {
                                                                        return Curry._1(handleSubmit, /* tuple */[
                                                                                    report,
                                                                                    Util$BsConsole.identity
                                                                                  ]);
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    tmp$1,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Delete")]
                                                                  ]))
                                                        ]))
                                              ]));
                              })));
            }),
          initialState: component$8.initialState,
          retainedProps: component$8.retainedProps,
          reducer: component$8.reducer,
          jsElementWrapped: component$8.jsElementWrapped
        };
}

var LegacyDeleteReportDialog = {
  component: component$8,
  make: make$8
};

var tableHeaderCell = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* [] */0
      ]
    ]);

var Styles = {
  tableHeaderCell: tableHeaderCell
};

var component$9 = RR$BsConsole.reducerComponent("PS_ScheduledReports-BsConsoleLegacyReportsTable");

function make$9(shell, reports, users, update, handleTask, token, onEditReport, _children) {
  return {
          debugName: component$9.debugName,
          reactClassInternal: component$9.reactClassInternal,
          handedOffState: component$9.handedOffState,
          willReceiveProps: component$9.willReceiveProps,
          didMount: component$9.didMount,
          didUpdate: component$9.didUpdate,
          willUnmount: component$9.willUnmount,
          willUpdate: component$9.willUpdate,
          shouldUpdate: component$9.shouldUpdate,
          render: (function (self) {
              var match = self.state.dialog;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Table$BsConsole.Pagination.make(reports, (function (aperture, page, rowsPerPage, onChangePage, onChangeRowsPerPage) {
                                    return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "title")])),
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, tableHeaderCell, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "owner")])),
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, []))
                                                                      ]))])),
                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [Belt_List.length(reports) > 0 ? Curry._1(aperture, Belt_List.toArray(Belt_List.map(reports, (function (report$prime) {
                                                                                var __x = Belt_List.getBy(users, (function (user) {
                                                                                        return user.uid === report$prime.owner;
                                                                                      }));
                                                                                return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(report$prime.title)])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(__x, "-", (function (user) {
                                                                                                                      return user.username;
                                                                                                                    })))])),
                                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, "none", "right", undefined, [ReasonReact.element(undefined, undefined, Ui$BsConsole.Menu.make(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            "Report settings",
                                                                                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.MoreVert.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                                                                          ])), (function (onClose) {
                                                                                                                      var tmp;
                                                                                                                      var exit = 0;
                                                                                                                      if (typeof shell === "number" || shell.tag) {
                                                                                                                        exit = 1;
                                                                                                                      } else {
                                                                                                                        tmp = ReasonReact.element(undefined, undefined, make(token, (function (param) {
                                                                                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Preview report sent"));
                                                                                                                                  }), (function (param) {
                                                                                                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error sending preview"));
                                                                                                                                  }), shell[0].user.email, (function (sendReport) {
                                                                                                                                    return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (param) {
                                                                                                                                                      return Curry._1(sendReport, report$prime.id);
                                                                                                                                                    }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Preview report")]));
                                                                                                                                  })));
                                                                                                                      }
                                                                                                                      if (exit === 1) {
                                                                                                                        tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "loading...")]));
                                                                                                                      }
                                                                                                                      return [
                                                                                                                              ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (param) {
                                                                                                                                          return Curry._1(onEditReport, report$prime);
                                                                                                                                        }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Edit report")])),
                                                                                                                              tmp,
                                                                                                                              ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Report.Delete.make, (function (param, param$1) {
                                                                                                                                          Curry._1(update, undefined);
                                                                                                                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted report!"));
                                                                                                                                        }), handleTask, token, (function (_state, _handleSubmit, _toKey) {
                                                                                                                                          return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                                                                                            Curry._1(self.send, /* Dialog */[/* DeleteReport */[report$prime]]);
                                                                                                                                                            return Curry._1(onClose, undefined);
                                                                                                                                                          }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete")]));
                                                                                                                                        })))
                                                                                                                            ];
                                                                                                                    }), undefined, []))]))
                                                                                              ]));
                                                                              })))) : ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "There are currently no scheduled reports for this project")]))]))])),
                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableFooter.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TablePagination.Jsx2.make, [
                                                                        undefined,
                                                                        undefined,
                                                                        onChangePage,
                                                                        undefined,
                                                                        onChangeRowsPerPage,
                                                                        /* `Int */[
                                                                          3654863,
                                                                          page
                                                                        ],
                                                                        undefined,
                                                                        /* `Int */[
                                                                          3654863,
                                                                          Belt_List.length(reports)
                                                                        ],
                                                                        /* `Int */[
                                                                          3654863,
                                                                          rowsPerPage
                                                                        ],
                                                                        /* `Int */[
                                                                          3654863,
                                                                          6
                                                                        ],
                                                                        undefined,
                                                                        undefined,
                                                                        []
                                                                      ]))]))
                                                  ]));
                                  }))), match !== undefined ? ReasonReact.element(undefined, undefined, make$8(match[0], token, handleTask, (function (param) {
                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                    }), (function (param) {
                                      Curry._1(update, undefined);
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted report"));
                                    }), [])) : null);
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component$9.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component$9.jsElementWrapped
        };
}

var LegacyReportsTable = {
  Styles: Styles,
  component: component$9,
  make: make$9
};

var tokenPaper = Css.style(/* :: */[
      Css.marginTop(Css.em(1)),
      /* [] */0
    ]);

var tableContainer = Css.style(/* :: */[
      Css.marginTop(Css.em(1)),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
        /* :: */[
          Css.borderRadius(Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.em(1)),
        /* :: */[
          Css.paddingRight(Css.px(28)),
          /* :: */[
            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
            /* :: */[
              Css.borderRadius(Css.px(4)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var addButton = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(-28)),
        /* :: */[
          Css.unsafe("top", "calc(50% - 28px)"),
          /* [] */0
        ]
      ]
    ]);

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var Styles$1 = {
  tokenPaper: tokenPaper,
  tableContainer: tableContainer,
  titleContainer: titleContainer,
  title: title,
  addButton: addButton,
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription
};

var component$10 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__LegacyDefault");

function make$10(handleAddReport, handleTask, token, shell, project, handleEditReport, _children) {
  return {
          debugName: component$10.debugName,
          reactClassInternal: component$10.reactClassInternal,
          handedOffState: component$10.handedOffState,
          willReceiveProps: component$10.willReceiveProps,
          didMount: component$10.didMount,
          didUpdate: component$10.didUpdate,
          willUnmount: component$10.willUnmount,
          willUpdate: component$10.willUpdate,
          shouldUpdate: component$10.shouldUpdate,
          render: (function (param) {
              var supportLink = Href$BsConsole.Support.make(undefined, /* ArticleScheduledReports */4);
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, titleContainer, undefined, undefined, 0, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports")])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, Css.style(/* :: */[
                                                Css.important(Css.padding(Css.px(24))),
                                                /* [] */0
                                              ]), [
                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports are e-mail reports that provide summary data of error activity over a 7 day period and week to week comparison. They can be configured to be generated sent weekly or daily.")])),
                                            React.createElement("br", undefined),
                                            supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                              href: supportLink,
                                                              rel: "noopener noreferrer",
                                                              target: "_blank"
                                                            }, I18N$BsConsole.show(undefined, "If you would like to learn more about scheduled reports, you can do so here."))])) : null
                                          ])),
                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                            undefined,
                                            addButton,
                                            (function (param) {
                                                return Curry._1(handleAddReport, undefined);
                                              }),
                                            undefined,
                                            undefined,
                                            /* Fab */3502759,
                                            /* Primary */-791844958,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Add.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                          ]))
                                ])), ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Report.Get.make, handleTask, token, undefined, (function (state, update) {
                                    return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.User.Get.make, handleTask, token, undefined, (function (state$prime, _updater) {
                                                      var match = state.remote;
                                                      var match$1 = state$prime.remote;
                                                      var tmp;
                                                      var exit = 0;
                                                      var exit$1 = 0;
                                                      if (typeof match === "number") {
                                                        exit$1 = 2;
                                                      } else if (match.tag) {
                                                        tmp = ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match[0])]))]));
                                                      } else if (typeof match$1 === "number" || match$1.tag) {
                                                        exit$1 = 2;
                                                      } else {
                                                        var reports = Belt_List.keep(match[0], (function (report) {
                                                                return report.project === project.pid;
                                                              }));
                                                        tmp = ReasonReact.element(undefined, undefined, make$9(shell, reports, match$1[0], update, handleTask, token, Curry.__1(handleEditReport), []));
                                                      }
                                                      if (exit$1 === 2) {
                                                        if (typeof match$1 === "number" || !match$1.tag) {
                                                          exit = 1;
                                                        } else {
                                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match$1[0])]))]));
                                                        }
                                                      }
                                                      if (exit === 1) {
                                                        tmp = ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "secondary", undefined, undefined, []))]))]));
                                                      }
                                                      return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, tableContainer, undefined, undefined, 0, [tmp]));
                                                    })));
                                  }))));
            }),
          initialState: component$10.initialState,
          retainedProps: component$10.retainedProps,
          reducer: component$10.reducer,
          jsElementWrapped: component$10.jsElementWrapped
        };
}

var LegacyDefault = {
  component: component$10,
  make: make$10
};

var component$11 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__Default");

function make$11(token, config, project, handleAddReport, handleEditReport, _children) {
  return {
          debugName: component$11.debugName,
          reactClassInternal: component$11.reactClassInternal,
          handedOffState: component$11.handedOffState,
          willReceiveProps: component$11.willReceiveProps,
          didMount: component$11.didMount,
          didUpdate: component$11.didUpdate,
          willUnmount: component$11.willUnmount,
          willUpdate: component$11.willUpdate,
          shouldUpdate: component$11.shouldUpdate,
          render: (function (param) {
              var supportLink = Href$BsConsole.Support.make(undefined, /* ArticleScheduledReports */4);
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, titleContainer, undefined, undefined, 0, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports")])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, Css.style(/* :: */[
                                                Css.important(Css.padding(Css.px(24))),
                                                /* [] */0
                                              ]), [
                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports are email-based summaries providing data on error activity over a selected period, along with week-to-week comparisons. These reports can be configured to be generated and sent on a daily, weekly, or monthly basis.")])),
                                            React.createElement("br", undefined),
                                            supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                              href: supportLink,
                                                              rel: "noopener noreferrer",
                                                              target: "_blank"
                                                            }, I18N$BsConsole.show(undefined, "If you would like to learn more about scheduled reports, you can do so here."))])) : null
                                          ])),
                                  ReasonReact.element(undefined, undefined, Curry._4(WfComponents$BsConsole.HeaderActionButton.Jsx2.make, "Create a new report", (function (param) {
                                              return Curry._1(handleAddReport, undefined);
                                            }), Css.style(/* :: */[
                                                Css.marginLeft(Css.px(24)),
                                                /* :: */[
                                                  Css.marginBottom(Css.px(24)),
                                                  /* [] */0
                                                ]
                                              ]), []))
                                ])), ReasonReact.element(undefined, undefined, ScheduledReportsTable$BsConsole.Jsx2.make(token, config, project.name, Curry.__1(handleEditReport), (function (param) {
                                    return Curry._1(handleAddReport, undefined);
                                  }), [])));
            }),
          initialState: component$11.initialState,
          retainedProps: component$11.retainedProps,
          reducer: component$11.reducer,
          jsElementWrapped: component$11.jsElementWrapped
        };
}

var Default = {
  component: component$11,
  make: make$11
};

var component$12 = RR$BsConsole.statelessComponent("PS_ScheduledReports-BsConsole__ServiceUnavailable");

function make$12(_children) {
  return {
          debugName: component$12.debugName,
          reactClassInternal: component$12.reactClassInternal,
          handedOffState: component$12.handedOffState,
          willReceiveProps: component$12.willReceiveProps,
          didMount: component$12.didMount,
          didUpdate: component$12.didUpdate,
          willUnmount: component$12.willUnmount,
          willUpdate: component$12.willUpdate,
          shouldUpdate: component$12.shouldUpdate,
          render: (function (param) {
              var supportLink = Href$BsConsole.Support.make(undefined, /* ArticleScheduledReports */4);
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, titleContainer, undefined, undefined, 0, [
                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports")])),
                                  ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, Css.style(/* :: */[
                                                Css.important(Css.padding(Css.px(24))),
                                                /* [] */0
                                              ]), [
                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Scheduled reports are email-based summaries providing data on error activity over a selected period, along with week-to-week comparisons. These reports can be configured to be generated and sent on a daily, weekly, or monthly basis.")])),
                                            React.createElement("br", undefined),
                                            supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                              href: supportLink,
                                                              rel: "noopener noreferrer",
                                                              target: "_blank"
                                                            }, I18N$BsConsole.show(undefined, "If you would like to learn more about scheduled reports, you can do so here."))])) : null
                                          ]))
                                ])), ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, titleContainer, undefined, undefined, 0, [React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.display(/* flex */-1010954439),
                                              /* :: */[
                                                Css.flexDirection(/* column */-963948842),
                                                /* :: */[
                                                  Css.justifyContent(/* center */98248149),
                                                  /* :: */[
                                                    Css.marginTop(Css.px(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(100)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Notifications.Jsx2.make, Css.style(/* :: */[
                                                    Css.alignSelf(/* center */98248149),
                                                    /* :: */[
                                                      pageMarginBottom,
                                                      /* [] */0
                                                    ]
                                                  ]), undefined, "large", undefined, undefined, [])), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* :: */[
                                                    Css.alignSelf(/* center */98248149),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Service Unavailable")])), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body2 */9, undefined, Css.merge(/* :: */[
                                                    Css.style(/* :: */[
                                                          Css.alignSelf(/* center */98248149),
                                                          /* [] */0
                                                        ]),
                                                    /* :: */[
                                                      featureDescription,
                                                      /* [] */0
                                                    ]
                                                  ]), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "The scheduled reports service is currently unavailable. Please contact support for more information.")])))])));
            }),
          initialState: component$12.initialState,
          retainedProps: component$12.retainedProps,
          reducer: component$12.reducer,
          jsElementWrapped: component$12.jsElementWrapped
        };
}

var ReportsServiceUnavailable = {
  component: component$12,
  make: make$12
};

var component$13 = RR$BsConsole.reducerComponent("PS_ScheduledReports-BsConsole");

function make$13(param, handleTask, token, project, shell, config, name, _children) {
  return {
          debugName: component$13.debugName,
          reactClassInternal: component$13.reactClassInternal,
          handedOffState: component$13.handedOffState,
          willReceiveProps: component$13.willReceiveProps,
          didMount: component$13.didMount,
          didUpdate: component$13.didUpdate,
          willUnmount: component$13.willUnmount,
          willUpdate: component$13.willUpdate,
          shouldUpdate: component$13.shouldUpdate,
          render: (function (self) {
              var maybeReport = self.state.view;
              var tmp;
              if (typeof maybeReport === "number") {
                var match = ApiScheduledReports$BsConsole.getScheduledReportsEndpoint(config);
                tmp = typeof match === "number" ? (
                    match !== 0 ? ReasonReact.element(undefined, undefined, make$10((function (param) {
                                  window.scrollTo(0, 0);
                                  return Curry._1(self.send, /* SetView */[/* LegacyForm */Block.__(0, [undefined])]);
                                }), handleTask, token, shell, project, (function (report) {
                                  window.scrollTo(0, 0);
                                  return Curry._1(self.send, /* SetView */[/* LegacyForm */Block.__(0, [report])]);
                                }), [])) : ReasonReact.element(undefined, undefined, make$12([]))
                  ) : ReasonReact.element(undefined, undefined, make$11(token, config, project, (function (param) {
                              window.scrollTo(0, 0);
                              return Curry._1(self.send, /* SetView */[/* Form */Block.__(1, [undefined])]);
                            }), (function (report) {
                              window.scrollTo(0, 0);
                              return Curry._1(self.send, /* SetView */[/* Form */Block.__(1, [report])]);
                            }), []));
              } else {
                tmp = maybeReport.tag ? ReasonReact.element(undefined, undefined, ScheduledReportsForm$BsConsole.Jsx2.make(token, config, project.name, (function (param) {
                              window.scrollTo(0, 0);
                              return Curry._1(self.send, /* SetView */[/* Default */0]);
                            }), maybeReport[0], [])) : ReasonReact.element(undefined, undefined, make$7((function (param) {
                              window.scrollTo(0, 0);
                              return Curry._1(self.send, /* SetView */[/* Default */0]);
                            }), token, handleTask, name, shell, project.pid, (function (param) {
                              window.scrollTo(0, 0);
                              return Curry._1(self.send, /* SetView */[/* Default */0]);
                            }), Caml_option.some(maybeReport[0]), []));
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [tmp]));
            }),
          initialState: (function (param) {
              return {
                      view: /* Default */0
                    };
            }),
          retainedProps: component$13.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          view: action[0]
                        }]);
            }),
          jsElementWrapped: component$13.jsElementWrapped
        };
}

var ReportFilters;

var Inputs;

exports.ReportFilters = ReportFilters;
exports.Inputs = Inputs;
exports.LegacyPreviewReport = LegacyPreviewReport;
exports.ReportHistograms = ReportHistograms;
exports.Select = Select;
exports.LegacyReportsForm = LegacyReportsForm;
exports.LegacyDeleteReportDialog = LegacyDeleteReportDialog;
exports.LegacyReportsTable = LegacyReportsTable;
exports.Styles = Styles$1;
exports.LegacyDefault = LegacyDefault;
exports.Default = Default;
exports.ReportsServiceUnavailable = ReportsServiceUnavailable;
exports.component = component$13;
exports.make = make$13;
/* component Not a pure module */
