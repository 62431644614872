// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");
var SSAStringComparison$BsConsole = require("./SSAStringComparison.js");

function conditionToLabel(param) {
  if (param.tag) {
    return SSAUtils$BsConsole.stringCompText;
  } else {
    return SSAUtils$BsConsole.regexText;
  }
}

function conditionToId(param) {
  if (param.tag) {
    return SSAUtils$BsConsole.stringCompText + (SSAStringComparison$BsConsole.toValue(param[0]) + param[1]);
  } else {
    return SSAUtils$BsConsole.regexText + (param[0] + Pervasives.string_of_bool(param[1]));
  }
}

function sourceToLabel(param) {
  if (typeof param === "number") {
    if (param !== 0) {
      return SSAUtils$BsConsole.callstackText;
    } else {
      return SSAUtils$BsConsole.moduleText;
    }
  } else {
    return SSAUtils$BsConsole.attributeText;
  }
}

function sourceToId(att) {
  if (typeof att === "number") {
    if (att !== 0) {
      return SSAUtils$BsConsole.callstackText;
    } else {
      return SSAUtils$BsConsole.moduleText;
    }
  } else {
    return SSAUtils$BsConsole.attributeText + att[0];
  }
}

function addStat(stat, param) {
  switch (param.tag | 0) {
    case /* Ignore */0 :
        var match = param[0];
        return /* Ignore */Block.__(0, [/* tuple */[
                    stat,
                    match[1],
                    match[2],
                    match[3]
                  ]]);
    case /* IndexOnly */1 :
        var match$1 = param[0];
        return /* IndexOnly */Block.__(1, [/* tuple */[
                    stat,
                    match$1[1],
                    match$1[2],
                    match$1[3]
                  ]]);
    case /* SetAttribute */2 :
        var match$2 = param[0];
        return /* SetAttribute */Block.__(2, [/* tuple */[
                    stat,
                    match$2[1],
                    match$2[2],
                    match$2[3]
                  ]]);
    case /* SetAssignee */3 :
        var match$3 = param[0];
        return /* SetAssignee */Block.__(3, [/* tuple */[
                    stat,
                    match$3[1],
                    match$3[2],
                    match$3[3]
                  ]]);
    case /* UnionTags */4 :
        var match$4 = param[0];
        return /* UnionTags */Block.__(4, [/* tuple */[
                    stat,
                    match$4[1],
                    match$4[2],
                    match$4[3],
                    match$4[4]
                  ]]);
    
  }
}

function getStat(param) {
  return param[0][0];
}

function getId(param) {
  switch (param.tag | 0) {
    case /* Ignore */0 :
        var match = param[0];
        return "ignore" + (sourceToId(match[1]) + Belt_Array.map(match[2], conditionToId).join());
    case /* IndexOnly */1 :
        var match$1 = param[0];
        return "indexonly" + (sourceToId(match$1[1]) + Belt_Array.map(match$1[2], conditionToId).join());
    case /* SetAttribute */2 :
        var match$2 = param[0];
        var setAttr = match$2[3];
        return "setattribute" + (sourceToId(match$2[1]) + (Belt_Array.map(match$2[2], conditionToId).join() + (setAttr.key + setAttr.value)));
    case /* SetAssignee */3 :
        var match$3 = param[0];
        return "setassignee" + (sourceToId(match$3[1]) + (Belt_Array.map(match$3[2], conditionToId).join() + match$3[3]));
    case /* UnionTags */4 :
        var match$4 = param[0];
        return "uniontags" + (sourceToId(match$4[1]) + (Belt_Array.map(match$4[2], conditionToId).join() + (match$4[3] + match$4[4].join())));
    
  }
}

function isConditionValid(conditionsMaybe) {
  if (conditionsMaybe === undefined) {
    return false;
  }
  var match = Belt_Array.get(conditionsMaybe, 0);
  if (match !== undefined) {
    if (match.tag) {
      return match[1].trim() !== "";
    } else {
      return match[0].trim() !== "";
    }
  } else {
    return false;
  }
}

function sourceConditionTuplesFromJson(json) {
  var sourceType = Json_decode.at(/* :: */[
          "source",
          /* :: */[
            "type",
            /* [] */0
          ]
        ], Json_decode.string)(json);
  var source;
  switch (sourceType) {
    case "attribute" :
        var attr = Json_decode.at(/* :: */[
                "source",
                /* :: */[
                  sourceType,
                  /* :: */[
                    sourceType,
                    /* [] */0
                  ]
                ]
              ], Json_decode.string)(json);
        source = /* Attribute */[attr];
        break;
    case "callstack" :
        source = /* Callstack */1;
        break;
    case "module" :
        source = /* Module */0;
        break;
    default:
      source = Js_exn.raiseError(SSAUtils$BsConsole.unsupportedSource);
  }
  var type_ = Json_decode.field("type", Json_decode.string, json);
  if (type_ === "regular_expression") {
    var pattern = Json_decode.at(/* :: */[
            "regular_expression",
            /* :: */[
              "pattern",
              /* [] */0
            ]
          ], Json_decode.string)(json);
    var inverse = Json_decode.at(/* :: */[
            "regular_expression",
            /* :: */[
              "inverse",
              /* [] */0
            ]
          ], Json_decode.bool)(json);
    return /* tuple */[
            source,
            /* RegEx */Block.__(0, [
                pattern,
                inverse
              ])
          ];
  }
  var cmpType = SSAStringComparison$BsConsole.fromValue(type_);
  var cmpValue = Json_decode.at(/* :: */[
          type_,
          /* :: */[
            "value",
            /* [] */0
          ]
        ], Json_decode.string)(json);
  return /* tuple */[
          source,
          /* StringComparison */Block.__(1, [
              cmpType,
              cmpValue
            ])
        ];
}

function conditionToJson(param) {
  var condition = param[1];
  var source = param[0];
  if (typeof source === "number") {
    if (source !== 0) {
      if (!condition.tag) {
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "regular_expression"
                    ],
                    /* :: */[
                      /* tuple */[
                        "source",
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                "callstack"
                              ],
                              /* [] */0
                            ])
                      ],
                      /* :: */[
                        /* tuple */[
                          "regular_expression",
                          Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "pattern",
                                  condition[0]
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "inverse",
                                    condition[1]
                                  ],
                                  /* [] */0
                                ]
                              ])
                        ],
                        /* [] */0
                      ]
                    ]
                  ]);
      }
      var cmpType = condition[0];
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    SSAStringComparison$BsConsole.toValue(cmpType)
                  ],
                  /* :: */[
                    /* tuple */[
                      "source",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "type",
                              "callstack"
                            ],
                            /* [] */0
                          ])
                    ],
                    /* :: */[
                      /* tuple */[
                        SSAStringComparison$BsConsole.toValue(cmpType),
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "value",
                                condition[1]
                              ],
                              /* [] */0
                            ])
                      ],
                      /* [] */0
                    ]
                  ]
                ]);
    }
    if (!condition.tag) {
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    "regular_expression"
                  ],
                  /* :: */[
                    /* tuple */[
                      "source",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "type",
                              "module"
                            ],
                            /* [] */0
                          ])
                    ],
                    /* :: */[
                      /* tuple */[
                        "regular_expression",
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "pattern",
                                condition[0]
                              ],
                              /* :: */[
                                /* tuple */[
                                  "inverse",
                                  condition[1]
                                ],
                                /* [] */0
                              ]
                            ])
                      ],
                      /* [] */0
                    ]
                  ]
                ]);
    }
    var cmpType$1 = condition[0];
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  SSAStringComparison$BsConsole.toValue(cmpType$1)
                ],
                /* :: */[
                  /* tuple */[
                    "source",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "type",
                            "module"
                          ],
                          /* [] */0
                        ])
                  ],
                  /* :: */[
                    /* tuple */[
                      SSAStringComparison$BsConsole.toValue(cmpType$1),
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "value",
                              condition[1]
                            ],
                            /* [] */0
                          ])
                    ],
                    /* [] */0
                  ]
                ]
              ]);
  }
  var value = source[0];
  if (!condition.tag) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  "regular_expression"
                ],
                /* :: */[
                  /* tuple */[
                    "source",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "type",
                            "attribute"
                          ],
                          /* :: */[
                            /* tuple */[
                              "attribute",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "attribute",
                                      value
                                    ],
                                    /* [] */0
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* :: */[
                    /* tuple */[
                      "regular_expression",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "pattern",
                              condition[0]
                            ],
                            /* :: */[
                              /* tuple */[
                                "inverse",
                                condition[1]
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]
                ]
              ]);
  }
  var cmpType$2 = condition[0];
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                SSAStringComparison$BsConsole.toValue(cmpType$2)
              ],
              /* :: */[
                /* tuple */[
                  "source",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "type",
                          "attribute"
                        ],
                        /* :: */[
                          /* tuple */[
                            "attribute",
                            Json_encode.object_(/* :: */[
                                  /* tuple */[
                                    "attribute",
                                    value
                                  ],
                                  /* [] */0
                                ])
                          ],
                          /* [] */0
                        ]
                      ])
                ],
                /* :: */[
                  /* tuple */[
                    SSAStringComparison$BsConsole.toValue(cmpType$2),
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "value",
                            condition[1]
                          ],
                          /* [] */0
                        ])
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

function fromJson(json) {
  var type_ = Json_decode.field("type", Json_decode.string, json);
  var probability = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("execution_probability", Json_decode.$$float, param);
            }), json), 1);
  var sourceConditionTuples = Json_decode.at(/* :: */[
          "conditions",
          /* [] */0
        ], (function (param) {
            return Json_decode.array(sourceConditionTuplesFromJson, param);
          }))(json);
  var conditions = Belt_Array.map(sourceConditionTuples, (function (param) {
          return param[1];
        }));
  var match = Belt_Array.get(sourceConditionTuples, 0);
  var source = match !== undefined ? match[0] : Js_exn.raiseError(SSAUtils$BsConsole.noSource);
  switch (type_) {
    case "ignore" :
        return /* Ignore */Block.__(0, [/* tuple */[
                    undefined,
                    source,
                    conditions,
                    probability
                  ]]);
    case "index_only" :
        return /* IndexOnly */Block.__(1, [/* tuple */[
                    undefined,
                    source,
                    conditions,
                    probability
                  ]]);
    case "set_assignee" :
        var username = Json_decode.at(/* :: */[
                "set_assignee",
                /* :: */[
                  "assignee",
                  /* [] */0
                ]
              ], Json_decode.string)(json);
        return /* SetAssignee */Block.__(3, [/* tuple */[
                    undefined,
                    source,
                    conditions,
                    username
                  ]]);
    case "set_attribute" :
        var attr = Json_decode.at(/* :: */[
                "set_attribute",
                /* :: */[
                  "attribute",
                  /* [] */0
                ]
              ], Json_decode.string)(json);
        var value = Json_decode.at(/* :: */[
                "set_attribute",
                /* :: */[
                  "value",
                  /* [] */0
                ]
              ], Json_decode.string)(json);
        var set = {
          key: attr,
          value: value
        };
        return /* SetAttribute */Block.__(2, [/* tuple */[
                    undefined,
                    source,
                    conditions,
                    set
                  ]]);
    case "union_tags" :
        var tags = Json_decode.at(/* :: */[
                "union_tags",
                /* :: */[
                  "tags",
                  /* [] */0
                ]
              ], (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }))(json);
        var tagKey = Json_decode.at(/* :: */[
                "union_tags",
                /* :: */[
                  "attribute",
                  /* [] */0
                ]
              ], Json_decode.string)(json);
        return /* UnionTags */Block.__(4, [/* tuple */[
                    undefined,
                    source,
                    conditions,
                    tagKey,
                    tags
                  ]]);
    default:
      return Js_exn.raiseError(SSAUtils$BsConsole.unsupportedType);
  }
}

function toString(action) {
  var tmp;
  switch (action.tag | 0) {
    case /* Ignore */0 :
        var match = action[0];
        var source = match[1];
        var obj_000 = /* tuple */[
          "type",
          "ignore"
        ];
        var obj_001 = /* :: */[
          /* tuple */[
            "conditions",
            Json_encode.array(conditionToJson, Belt_Array.map(match[2], (function (condition) {
                        return /* tuple */[
                                source,
                                condition
                              ];
                      })))
          ],
          /* [] */0
        ];
        var obj = /* :: */[
          obj_000,
          obj_001
        ];
        tmp = Json_encode.object_(CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.57.25") ? /* :: */[
                /* tuple */[
                  "execution_probability",
                  match[3]
                ],
                obj
              ] : obj);
        break;
    case /* IndexOnly */1 :
        var match$1 = action[0];
        var source$1 = match$1[1];
        var obj_000$1 = /* tuple */[
          "type",
          "index_only"
        ];
        var obj_001$1 = /* :: */[
          /* tuple */[
            "conditions",
            Json_encode.array(conditionToJson, Belt_Array.map(match$1[2], (function (condition) {
                        return /* tuple */[
                                source$1,
                                condition
                              ];
                      })))
          ],
          /* [] */0
        ];
        var obj$1 = /* :: */[
          obj_000$1,
          obj_001$1
        ];
        tmp = Json_encode.object_(CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.57.25") ? /* :: */[
                /* tuple */[
                  "execution_probability",
                  match$1[3]
                ],
                obj$1
              ] : obj$1);
        break;
    case /* SetAttribute */2 :
        var match$2 = action[0];
        var match$3 = match$2[3];
        var source$2 = match$2[1];
        tmp = Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                "set_attribute"
              ],
              /* :: */[
                /* tuple */[
                  "conditions",
                  Json_encode.array(conditionToJson, Belt_Array.map(match$2[2], (function (condition) {
                              return /* tuple */[
                                      source$2,
                                      condition
                                    ];
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "set_attribute",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "attribute",
                            match$3.key
                          ],
                          /* :: */[
                            /* tuple */[
                              "value",
                              match$3.value
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]
            ]);
        break;
    case /* SetAssignee */3 :
        var match$4 = action[0];
        var source$3 = match$4[1];
        tmp = Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                "set_assignee"
              ],
              /* :: */[
                /* tuple */[
                  "conditions",
                  Json_encode.array(conditionToJson, Belt_Array.map(match$4[2], (function (condition) {
                              return /* tuple */[
                                      source$3,
                                      condition
                                    ];
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "set_assignee",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "assignee",
                            match$4[3]
                          ],
                          /* [] */0
                        ])
                  ],
                  /* [] */0
                ]
              ]
            ]);
        break;
    case /* UnionTags */4 :
        var match$5 = action[0];
        var source$4 = match$5[1];
        tmp = Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                "union_tags"
              ],
              /* :: */[
                /* tuple */[
                  "conditions",
                  Json_encode.array(conditionToJson, Belt_Array.map(match$5[2], (function (condition) {
                              return /* tuple */[
                                      source$4,
                                      condition
                                    ];
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "union_tags",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "attribute",
                            match$5[3]
                          ],
                          /* :: */[
                            /* tuple */[
                              "tags",
                              Json_encode.array((function (prim) {
                                      return prim;
                                    }), match$5[4])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]
            ]);
        break;
    
  }
  return JSON.stringify(tmp);
}

function toJsonString(actions) {
  var a = Belt_Array.map(actions, toString).join(",");
  return "{\"actions\":[" + (a + "]}");
}

exports.conditionToLabel = conditionToLabel;
exports.conditionToId = conditionToId;
exports.sourceToLabel = sourceToLabel;
exports.sourceToId = sourceToId;
exports.addStat = addStat;
exports.getStat = getStat;
exports.getId = getId;
exports.isConditionValid = isConditionValid;
exports.sourceConditionTuplesFromJson = sourceConditionTuplesFromJson;
exports.conditionToJson = conditionToJson;
exports.fromJson = fromJson;
exports.toString = toString;
exports.toJsonString = toJsonString;
/* SSAUtils-BsConsole Not a pure module */
