// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Scale = require("@visx/scale");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

function getXScale(data, xMax) {
  var total = Belt_Array.reduce(data, 0, (function (acc, param) {
          return acc + param[1] | 0;
        }));
  var xMax$1 = xMax - (data.length << 1) | 0;
  return Scale.scaleLinear().range([
                xMax$1,
                0
              ]).domain([
              total,
              0
            ]);
}

function getFillColor(colorRange, index, colorMap, groupName, opacity, restColor) {
  var mappedColor = Belt_Array.getBy(colorMap, (function (param) {
          return param[0] === groupName;
        }));
  var indexedColor = Belt_Array.get(colorRange, index);
  var color = mappedColor !== undefined ? mappedColor[1] : (
      groupName === "__rest" || indexedColor === undefined ? restColor : indexedColor
    );
  return "#" + Colors$BsConsole.addAlpha(color, opacity);
}

function getGroupName(group) {
  if (group === "__rest") {
    return "Rest of groups";
  } else {
    return group;
  }
}

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  container: container
};

function CondensedDistributionChart(Props) {
  var width = Props.width;
  var data = Props.data;
  var colorRange = Props.colorRange;
  var onDataHoverOpt = Props.onDataHover;
  var emphasizedGroup = Props.emphasizedGroup;
  var colorMapOpt = Props.colorMap;
  var onDataHover = onDataHoverOpt !== undefined ? onDataHoverOpt : (function (param) {
        
      });
  var colorMap = colorMapOpt !== undefined ? colorMapOpt : [];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match;
  if (colorRange !== undefined) {
    match = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match[1];
  var colors = match[0];
  var xScale = getXScale(data, width);
  return React.createElement("div", {
              className: container
            }, React.createElement("svg", {
                  height: "30",
                  width: String(width),
                  onMouseLeave: (function (param) {
                      return Curry._1(onDataHover, undefined);
                    })
                }, React.createElement("rect", {
                      height: "30",
                      width: String(width),
                      fill: "transparent"
                    }), Belt_Array.mapWithIndex(data, (function (idx, param) {
                        var count = param[1];
                        var group = param[0];
                        var xBasis = idx > 0 ? Curry._1(xScale, Belt_Array.reduceWithIndex(data, 0, (function (acc, param, idx$prime) {
                                      if (idx$prime < idx) {
                                        return acc + param[1] | 0;
                                      } else {
                                        return acc;
                                      }
                                    }))) + idx * 2 : 0;
                        var opacity = emphasizedGroup !== undefined ? (
                            group === emphasizedGroup ? 1.0 : 0.5
                          ) : 1.0;
                        return React.createElement("rect", {
                                    key: group + ("__" + String(count)),
                                    className: Css.merge(/* :: */[
                                          "rect_" + group,
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.transition(50, undefined, undefined, "fill"),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ]),
                                    height: "30",
                                    width: Pervasives.string_of_float(Curry._1(xScale, count)),
                                    onMouseEnter: (function (param) {
                                        return Curry._1(onDataHover, /* tuple */[
                                                    group,
                                                    count
                                                  ]);
                                      }),
                                    onMouseLeave: (function (param) {
                                        return Curry._1(onDataHover, undefined);
                                      }),
                                    fill: getFillColor(colors, idx, colorMap, group, opacity, fallbackColor),
                                    x: Pervasives.string_of_float(xBasis),
                                    y: "0"
                                  }, React.createElement("title", undefined, I18N$BsConsole.showSkip(getGroupName(group) + (" - " + String(count)))));
                      }))));
}

var make = CondensedDistributionChart;

exports.getXScale = getXScale;
exports.getFillColor = getFillColor;
exports.getGroupName = getGroupName;
exports.Style = Style;
exports.make = make;
/* container Not a pure module */
