// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function getName(param) {
  return param[0];
}

function getAggString(param) {
  switch (param.tag | 0) {
    case /* Unique */0 :
        return "unique";
    case /* Tail */1 :
        return "tail";
    case /* Bin */2 :
        return "bin";
    case /* Head */3 :
        return "head";
    case /* Sum */4 :
        return "sum";
    case /* Mean */5 :
        return "mean";
    case /* Min */6 :
        return "min";
    case /* Max */7 :
        return "max";
    case /* Range */8 :
        return "range";
    case /* Distribution */9 :
        return "distribution";
    
  }
}

exports.getName = getName;
exports.getAggString = getAggString;
/* No side effect */
