// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var BpgController$BsConsole = require("./BpgController.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

var component = ReasonReact.statelessComponent("Project-BsConsoleFetch");

function make(name, token, handleTask, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Project.Get.make, handleTask, token, undefined, (function (state, updater) {
                                var projects = state.remote;
                                if (typeof projects === "number") {
                                  if (projects === /* NotAsked */0) {
                                    return Curry._2(children, /* NotAsked */0, undefined);
                                  } else {
                                    return Curry._2(children, /* Loading */1, undefined);
                                  }
                                }
                                if (projects.tag) {
                                  return Curry._2(children, /* Failure */Block.__(1, [projects[0]]), undefined);
                                }
                                var projects$1 = projects[0];
                                var maybeProject = Belt_List.getBy(projects$1, (function (p) {
                                        return p.name === name;
                                      }));
                                if (maybeProject === undefined) {
                                  var href = location.href;
                                  var allProjects = JSON.stringify(Json_encode.list(BacktraceParserGenerator$BsConsole.Project.to_json, projects$1));
                                  var __x = Backtrace$BsConsole.Attributes.addString("allProjects", allProjects, Backtrace$BsConsole.Attributes.addString("projectName", name, Backtrace$BsConsole.Attributes.addString("location.href", href, Backtrace$BsConsole.Attributes.addString("src_module", "Project/Fetch.render", Backtrace$BsConsole.Attributes.make(undefined)))));
                                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                                        288368849,
                                        "Unable to find requested project"
                                      ]);
                                }
                                return Curry._2(children, Belt_Option.mapWithDefault(maybeProject, /* Failure */Block.__(1, ["Failed to find project"]), (function (project) {
                                                  return /* Success */Block.__(0, [project]);
                                                })), updater);
                              })));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Fetch = {
  component: component,
  make: make
};

exports.Fetch = Fetch;
/* component Not a pure module */
