// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function addProjectPrefix(id, projectName) {
  var prefix = projectName.slice(0, 2).toUpperCase();
  return prefix + ("-" + id);
}

exports.addProjectPrefix = addProjectPrefix;
/* No side effect */
