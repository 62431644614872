// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var App$BsConsole = require("./app.js");

ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, App$BsConsole.make([])), "content");

/*  Not a pure module */
