// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Services$BsConsole = require("./Services.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

function typeToString(param) {
  switch (param) {
    case /* Unity */0 :
        return "unity";
    case /* NodeJS */1 :
        return "node";
    case /* ReactNative */3 :
        return "react-native";
    case /* React */2 :
    case /* Default */4 :
        return "react";
    
  }
}

function typeToLabel(param) {
  switch (param) {
    case /* Unity */0 :
        return "Unity";
    case /* NodeJS */1 :
        return "Node.js";
    case /* React */2 :
        return "React";
    case /* ReactNative */3 :
        return "React Native";
    case /* Default */4 :
        return "react";
    
  }
}

function stringToType(platform) {
  switch (platform) {
    case "node" :
        return /* NodeJS */1;
    case "react" :
        return /* React */2;
    case "react-native" :
        return /* ReactNative */3;
    case "unity" :
        return /* Unity */0;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "DemoData/Platform/stringToType", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "\"Platform " + (String(platform) + " not accounted for and defaulted to react.\"")
          ]);
      return /* Default */4;
  }
}

function guideNameToType(guideName) {
  switch (guideName) {
    case "NodeJS" :
        return /* NodeJS */1;
    case "React" :
        return /* React */2;
    case "React Native" :
        return /* ReactNative */3;
    case "Unity" :
        return /* Unity */0;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "DemoData/Platform/stringToType", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "\"Platform " + (String(guideName) + " not accounted for and defaulted to react.\"")
          ]);
      return /* Default */4;
  }
}

var Platform = {
  typeToString: typeToString,
  typeToLabel: typeToLabel,
  stringToType: stringToType,
  guideNameToType: guideNameToType
};

function decode(json) {
  return {
          message: Json_decode.field("message", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.$$int, json)
        };
}

var $$Error = {
  decode: decode
};

function decode$1(json) {
  return {
          success: Json_decode.field("success", Json_decode.bool, json),
          rxid: Json_decode.optional((function (param) {
                  return Json_decode.field("rxid", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", Json_decode.$$int, param);
                }), json)
        };
}

var SymbolsResult = {
  decode: decode$1
};

function decode$2(json) {
  return {
          success: Json_decode.field("success", Json_decode.bool, json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", Json_decode.$$int, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", decode, param);
                }), json)
        };
}

var UniqueEventsResults = {
  decode: decode$2
};

function decode$3(json) {
  return {
          success: Json_decode.field("success", Json_decode.bool, json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", Json_decode.$$int, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", decode, param);
                }), json)
        };
}

var SummedEventsResults = {
  decode: decode$3
};

function decode$4(json) {
  return {
          summedEventsResults: Json_decode.field("summedEventsResults", (function (param) {
                  return Json_decode.array(decode$3, param);
                }), json),
          uniqueEventsResults: Json_decode.field("uniqueEventsResults", (function (param) {
                  return Json_decode.array(decode$2, param);
                }), json)
        };
}

var EventsResult = {
  UniqueEventsResults: UniqueEventsResults,
  SummedEventsResults: SummedEventsResults,
  decode: decode$4
};

function decode$5(json) {
  return {
          success: Json_decode.field("success", Json_decode.bool, json),
          response: Json_decode.optional((function (param) {
                  return Json_decode.field("response", Json_decode.string, param);
                }), json),
          rxid: Json_decode.optional((function (param) {
                  return Json_decode.field("_rxid", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", decode, param);
                }), json)
        };
}

var ReportsResult = {
  decode: decode$5
};

function decode$6(json) {
  return {
          eventsResult: Json_decode.field("eventsResult", decode$4, json),
          reportsResult: Json_decode.field("reportsResult", (function (param) {
                  return Json_decode.array(decode$5, param);
                }), json),
          symbolsResult: Json_decode.field("symbolsResult", (function (param) {
                  return Json_decode.array(decode$1, param);
                }), json)
        };
}

var GenerationOutput = {
  decode: decode$6
};

function decode$7(json) {
  return {
          projectName: Json_decode.field("project_name", Json_decode.string, json),
          generation_output: Json_decode.field("generation_output", decode$6, json)
        };
}

function generateDemoProject(config, token, platform, onSuccess, onFailure) {
  var serviceUrl = Services$BsConsole.getEndpoint(undefined)(config.services, "overview");
  if (serviceUrl === undefined) {
    return ;
  }
  var arg = Task2$BsConsole.make(/* Custom */Block.__(1, [serviceUrl + "/demo"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "platform",
                  typeToString(platform)
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          var json = param.json;
          try {
            if (param.status >= 400) {
              var response = ApiWf$BsConsole.$$Error.fromJson(json);
              var __x = Backtrace$BsConsole.Attributes.addString("demo_platform", typeToString(platform), Backtrace$BsConsole.Attributes.addString("src_module", "ApiDemoProject/DemoGenerator/generateDemoProject", Backtrace$BsConsole.Attributes.make(undefined)));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    response.error
                  ]);
              return /* Error */Block.__(1, [[
                          ApiWf$BsConsole.ServiceError,
                          response.error
                        ]]);
            }
            var response$1 = decode$7(json);
            return /* Ok */Block.__(0, [response$1]);
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            var error = Uncaught$BsConsole.castToJsExn(err);
            var __x$1 = Backtrace$BsConsole.Attributes.addString("demo_platform", typeToString(platform), Backtrace$BsConsole.Attributes.addString("src_module", "ApiDemoProject/DemoGenerator/generateDemoProject-exn", Backtrace$BsConsole.Attributes.make(undefined)));
            Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                  5049499,
                  error
                ]);
            return /* Error */Block.__(1, [[
                        ApiWf$BsConsole.ParseError,
                        error
                      ]]);
          }
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (res) {
                if (res.tag) {
                  return Curry._1(onFailure, undefined);
                }
                if (Belt_Array.some(res[0].generation_output.reportsResult, (function (report) {
                          return report.success === true;
                        }))) {
                  return Curry._1(onSuccess, "backtrace-demo-project--" + typeToString(platform));
                }
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiDemoProject/DemoGenerator/generateDemoProject", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "All demo data reports failed to generate for " + typeToString(platform)
                    ]);
                return Curry._1(onFailure, undefined);
              }));
}

var DemoGenerator = {
  $$Error: $$Error,
  SymbolsResult: SymbolsResult,
  EventsResult: EventsResult,
  ReportsResult: ReportsResult,
  GenerationOutput: GenerationOutput,
  decode: decode$7,
  generateDemoProject: generateDemoProject
};

exports.Platform = Platform;
exports.DemoGenerator = DemoGenerator;
/* Task2-BsConsole Not a pure module */
