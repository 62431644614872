// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var BtList$BsConsole = require("./components/BtList.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var BtListItem$BsConsole = require("./components/BtListItem.js");

var mostRecentFingerprintText = "Most recent fingerprints";

var earliestFingerprintText = "Earliest fingerprints";

function fromSort(sort) {
  if (sort.tag === /* Head */2 && sort[0] === "fingerprint;first_seen") {
    return sort[1];
  }
  
}

function firstSeenSort(ordering) {
  return /* Head */Block.__(2, [
            "fingerprint;first_seen",
            ordering
          ]);
}

var component = RR$BsConsole.statelessComponent("TriageFingerprintSorting-BsConsole");

function make(handleSetSort, sort, name, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHander, onFocus, onClick) {
                                var ordering = fromSort(sort);
                                return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Sort by fingerprint initial appearance timestamp"), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                      ref: refHander,
                                                      className: Css.style(/* :: */[
                                                            Css.cursor(/* pointer */-786317123),
                                                            /* :: */[
                                                              Css.display(/* flex */-1010954439),
                                                              /* :: */[
                                                                Css.textAlign(Css.center),
                                                                /* :: */[
                                                                  Css.padding2(Css.px(4), Css.px(3)),
                                                                  /* :: */[
                                                                    Css.hover(/* :: */[
                                                                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                                                                          /* :: */[
                                                                            Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]),
                                                                    /* :: */[
                                                                      Css.marginLeft(Css.px(-3)),
                                                                      /* :: */[
                                                                        Css.borderRadius(Css.px(2)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]),
                                                      role: "button",
                                                      onFocus: onFocus,
                                                      onClick: (function (e) {
                                                          e.stopPropagation();
                                                          return Curry._1(onClick, e);
                                                        })
                                                    }, ordering !== undefined ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                                React.createElement("div", {
                                                                      className: Css.style(/* :: */[
                                                                            Css.selector("svg", /* :: */[
                                                                                  Css.fontSize(Css.px(16)),
                                                                                  /* [] */0
                                                                                ]),
                                                                            /* :: */[
                                                                              Css.height(Css.px(16)),
                                                                              /* :: */[
                                                                                Css.width(Css.px(16)),
                                                                                /* :: */[
                                                                                  Css.margin2(Css.zero, Css.px(4)),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ])
                                                                    }, ordering ? ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ArrowDownward.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])) : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ArrowUpward.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                                                React.createElement("div", undefined, I18N$BsConsole.showSkip(name))
                                                              ])) : I18N$BsConsole.show(undefined, name))]));
                              }), undefined, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Left */847852583
                            }, {
                              vertical: /* Top */4202101,
                              horizontal: /* Left */847852583
                            }, undefined, undefined, undefined, (function (onClose) {
                                return ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [
                                                ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (param) {
                                                            Curry._1(handleSetSort, /* Head */Block.__(2, [
                                                                    "fingerprint;first_seen",
                                                                    /* Descending */1
                                                                  ]));
                                                            return Curry._1(onClose, undefined);
                                                          }), [I18N$BsConsole.show(undefined, mostRecentFingerprintText)])),
                                                ReasonReact.element(undefined, undefined, BtListItem$BsConsole.make(undefined, (function (param) {
                                                            Curry._1(handleSetSort, /* Head */Block.__(2, [
                                                                    "fingerprint;first_seen",
                                                                    /* Ascending */0
                                                                  ]));
                                                            return Curry._1(onClose, undefined);
                                                          }), [I18N$BsConsole.show(undefined, earliestFingerprintText)]))
                                              ]));
                              }), undefined, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.handleSetSort, reactProps.sort, reactProps.name, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.mostRecentFingerprintText = mostRecentFingerprintText;
exports.earliestFingerprintText = earliestFingerprintText;
exports.fromSort = fromSort;
exports.firstSeenSort = firstSeenSort;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
