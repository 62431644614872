// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var Button = require("@material-ui/core/Button");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var SubTextFormatter$BsConsole = require("./SubTextFormatter.js");
var TextField = require("@material-ui/core/TextField");

var title = Css.merge(/* :: */[
      SubUtil$BsConsole.Style.titleText,
      /* :: */[
        Css.style(/* :: */[
              Css.textAlign(/* left */-944764921),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var body = Css.merge(/* :: */[
      SubUtil$BsConsole.Style.bodyText,
      /* :: */[
        Css.style(/* :: */[
              Css.textAlign(/* left */-944764921),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var price = Css.merge(/* :: */[
      SubUtil$BsConsole.Style.bodyText,
      /* :: */[
        Css.style(/* :: */[
              Css.textAlign(/* left */-944764921),
              /* :: */[
                Css.marginTop(Css.px(25)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var plan = SubUtil$BsConsole.Style.titleText;

var planContents = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(Css.px(150)),
        /* :: */[
          Css.paddingTop(Css.px(12)),
          /* :: */[
            Css.paddingBottom(Css.px(12)),
            /* [] */0
          ]
        ]
      ]
    ]);

var selectedPlan = Css.merge(/* :: */[
      plan,
      /* :: */[
        Css.style(/* :: */[
              Css.borderColor(Css.hex(Colors$BsConsole.blue)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  title: title,
  body: body,
  price: price,
  plan: plan,
  planContents: planContents,
  selectedPlan: selectedPlan
};

function SubModifyPlanModal$PlanQuantitySelector(Props) {
  var selectedPlan = Props.selectedPlan;
  var planCount = Props.planCount;
  var setPlanCount = Props.setPlanCount;
  var tmp;
  if (selectedPlan !== undefined && selectedPlan.quantity) {
    var incrementingFeatureMaybe = Belt_Array.getBy(selectedPlan.incrementingFeatures, (function (incrementingFeature) {
            return incrementingFeature.planType === /* Plan */1;
          }));
    if (incrementingFeatureMaybe !== undefined) {
      var s = incrementingFeatureMaybe.label;
      var featureLabel = s !== undefined ? s.toLowerCase() : incrementingFeatureMaybe.name;
      tmp = React.createElement(Col2$BsConsole.make, {
            className: Css.style(/* :: */[
                  Css.width(Css.px(205)),
                  /* [] */0
                ]),
            children: null
          }, React.createElement("div", {
                className: SubUtil$BsConsole.Style.titleText
              }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Select ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " quantity:",
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "Select %s quantity:"
                      ]), featureLabel)), React.createElement(Row2$BsConsole.make, {
                children: null
              }, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.marginRight(Css.px(15)),
                          /* [] */0
                        ])
                  }, React.createElement(TextField.default, {
                        autoFocus: true,
                        placeholder: "",
                        value: String(planCount),
                        color: "primary",
                        type: "number",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.px(45)),
                                /* :: */[
                                  Css.marginRight(Css.px(10)),
                                  /* [] */0
                                ]
                              ])
                        },
                        inputProps: {
                          min: 1
                        },
                        onChange: (function ($$event) {
                            var value;
                            try {
                              value = Caml_format.caml_int_of_string($$event.target.value);
                            }
                            catch (exn){
                              value = 0;
                            }
                            NemesisMetrics$BsConsole.send(/* ChangePlanQuantity */2);
                            return Curry._1(setPlanCount, (function (param) {
                                          return value;
                                        }));
                          })
                      })), React.createElement("div", {
                    className: SubUtil$BsConsole.Style.bodyText
                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* "*" */42,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "%s*"
                          ]), SubPriceUtil$BsConsole.priceToString(SubPriceUtil$BsConsole.getPlanPrice(selectedPlan, planCount))))), React.createElement("div", {
                className: SubUtil$BsConsole.Style.bodyText
              }, React.createElement("span", undefined, I18N$BsConsole.showSkip("*"), I18N$BsConsole.show(undefined, "Total excludes sales tax and any additional addons."))));
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.px(60)),
                      /* :: */[
                        Css.marginBottom(Css.px(25)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: tmp
            });
}

var PlanQuantitySelector = {
  make: SubModifyPlanModal$PlanQuantitySelector
};

function getCurrentlySubscribedPlan(subscription, basePlans) {
  if (subscription !== undefined && basePlans !== undefined) {
    return Belt_Array.getBy(basePlans, (function (plan) {
                  return plan.id === subscription.planId;
                }));
  }
  
}

function SubModifyPlanModal(Props) {
  var openModal = Props.openModal;
  var setOpenModal = Props.setOpenModal;
  var updateSubscriptions = Props.updateSubscriptions;
  var basePlans = Props.basePlans;
  var subscription = Props.subscription;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var loading = Props.loading;
  var setLoading = Props.setLoading;
  var match = React.useState((function () {
          return getCurrentlySubscribedPlan(subscription, basePlans);
        }));
  var setSelectedPlan = match[1];
  var selectedPlan$1 = match[0];
  var match$1 = React.useState((function () {
          if (subscription !== undefined) {
            return subscription.planQuantity;
          } else {
            return 1;
          }
        }));
  var setPlanCount = match$1[1];
  var planCount = match$1[0];
  React.useEffect((function () {
          var currentSubscriptionCount = subscription !== undefined ? subscription.planQuantity : 1;
          Curry._1(setPlanCount, (function (param) {
                  return currentSubscriptionCount;
                }));
          
        }), [subscription]);
  React.useEffect((function () {
          if (subscription !== undefined && selectedPlan$1 !== undefined) {
            if (subscription.planId !== selectedPlan$1.id) {
              var features = limits.metadata.features;
              var nextPlanCount = features !== undefined ? SubPriceUtil$BsConsole.getNextPlanQuantity(selectedPlan$1, features) : 1;
              Curry._1(setPlanCount, (function (param) {
                      return nextPlanCount;
                    }));
            } else {
              Curry._1(setPlanCount, (function (param) {
                      return subscription.planQuantity;
                    }));
            }
          }
          
        }), [selectedPlan$1]);
  React.useEffect((function () {
          if (loading) {
            setTimeout((function (param) {
                    Curry._1(refetchLimits, undefined);
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Curry._1(setOpenModal, (function (param) {
                                  return false;
                                }));
                  }), 1200);
          }
          
        }), [loading]);
  var tmp = true;
  if (!loading) {
    var match$2 = getCurrentlySubscribedPlan(subscription, basePlans);
    var tmp$1;
    if (selectedPlan$1 !== undefined && match$2 !== undefined) {
      var samePlan = selectedPlan$1.id === match$2.id;
      var differentQuantity = subscription !== undefined ? subscription.planQuantity !== planCount : true;
      tmp$1 = samePlan && !differentQuantity;
    } else {
      tmp$1 = true;
    }
    tmp = tmp$1;
  }
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: openModal,
              onClose: (function (param) {
                  Curry._1(setSelectedPlan, (function (param) {
                          return getCurrentlySubscribedPlan(subscription, basePlans);
                        }));
                  return Curry._1(setOpenModal, (function (param) {
                                return false;
                              }));
                }),
              title: I18N$BsConsole.get(undefined, "Modify subscription plan"),
              subtitle: I18N$BsConsole.empty(undefined),
              divider: true,
              maxWidth: "lg",
              fullWidth: true,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* center */98248149,
                  children: basePlans !== undefined ? Belt_Array.mapWithIndex(basePlans, (function (index, plan$1) {
                            var tmp;
                            if (plan$1.quantity) {
                              tmp = Belt_Array.map(Belt_Array.keep(plan$1.incrementingFeatures, (function (incrementingFeature) {
                                          return incrementingFeature.planType === /* Plan */1;
                                        })), (function (incrementingFeature) {
                                      var s = incrementingFeature.label;
                                      var featureLabel = s !== undefined ? s.toLowerCase() : incrementingFeature.name;
                                      return React.createElement("span", {
                                                  key: featureLabel
                                                }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* String_literal */Block.__(11, [
                                                                  " per ",
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* End_of_format */0
                                                                    ])
                                                                ])
                                                            ]),
                                                          "%s per %s"
                                                        ]), SubPriceUtil$BsConsole.priceToString(SubPriceUtil$BsConsole.getPlanPrice(plan$1, 1)), featureLabel));
                                    }));
                            } else {
                              var match = SubPriceUtil$BsConsole.getPlanPrice(plan$1, 1);
                              var price$1 = match[0];
                              tmp = Caml_obj.caml_equal(price$1, 0.0) ? I18N$BsConsole.show(undefined, "Free") : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ]),
                                          "%s"
                                        ]), SubPriceUtil$BsConsole.priceToString(/* tuple */[
                                          price$1,
                                          match[1]
                                        ]));
                            }
                            return React.createElement("div", {
                                        key: plan$1.id,
                                        className: Css.style(/* :: */[
                                              Css.marginRight(Css.px(15)),
                                              /* [] */0
                                            ])
                                      }, React.createElement(Button.default, {
                                            variant: "outlined",
                                            disabled: selectedPlan$1 !== undefined && selectedPlan$1.id === plan$1.id ? false : loading,
                                            classes: {
                                              root: selectedPlan$1 !== undefined && selectedPlan$1.id === plan$1.id ? selectedPlan : plan,
                                              label: planContents
                                            },
                                            onClick: (function (param) {
                                                if (!loading) {
                                                  return Curry._1(setSelectedPlan, (function (param) {
                                                                return plan$1;
                                                              }));
                                                }
                                                
                                              }),
                                            children: React.createElement(Col2$BsConsole.make, {
                                                  justifyContent: /* spaceBetween */516682146,
                                                  className: Css.style(/* :: */[
                                                        Css.height(Css.pct(100)),
                                                        /* [] */0
                                                      ]),
                                                  children: null
                                                }, React.createElement(Col2$BsConsole.make, {
                                                      children: null
                                                    }, React.createElement("div", {
                                                          className: title
                                                        }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* End_of_format */0
                                                                    ]),
                                                                  "%s"
                                                                ]), plan$1.name)), React.createElement(Col2$BsConsole.make, {
                                                          children: Belt_Array.keepMap(plan$1.baseFeatures, (function (baseFeature) {
                                                                  var match = baseFeature.name;
                                                                  switch (match) {
                                                                    case "errors" :
                                                                        return Caml_option.some(React.createElement("div", {
                                                                                        key: "errors",
                                                                                        className: body
                                                                                      }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                /* String */Block.__(2, [
                                                                                                    /* No_padding */0,
                                                                                                    /* Char_literal */Block.__(12, [
                                                                                                        /* " " */32,
                                                                                                        /* String */Block.__(2, [
                                                                                                            /* No_padding */0,
                                                                                                            /* Char_literal */Block.__(12, [
                                                                                                                /* " " */32,
                                                                                                                /* String */Block.__(2, [
                                                                                                                    /* No_padding */0,
                                                                                                                    /* End_of_format */0
                                                                                                                  ])
                                                                                                              ])
                                                                                                          ])
                                                                                                      ])
                                                                                                  ]),
                                                                                                "%s %s %s"
                                                                                              ]), "Receive up to", SubTextFormatter$BsConsole.toString(Caml_format.caml_float_of_string(baseFeature.value), /* Millions */0), "errors")));
                                                                    case "storage" :
                                                                        return Caml_option.some(React.createElement("div", {
                                                                                        key: "storage",
                                                                                        className: body
                                                                                      }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                /* String */Block.__(2, [
                                                                                                    /* No_padding */0,
                                                                                                    /* Char_literal */Block.__(12, [
                                                                                                        /* " " */32,
                                                                                                        /* String */Block.__(2, [
                                                                                                            /* No_padding */0,
                                                                                                            /* Char_literal */Block.__(12, [
                                                                                                                /* " " */32,
                                                                                                                /* String */Block.__(2, [
                                                                                                                    /* No_padding */0,
                                                                                                                    /* End_of_format */0
                                                                                                                  ])
                                                                                                              ])
                                                                                                          ])
                                                                                                      ])
                                                                                                  ]),
                                                                                                "%s %s %s"
                                                                                              ]), "Store up to", SubTextFormatter$BsConsole.toString(Caml_format.caml_float_of_string(baseFeature.value) * ApiBillingLimits$BsConsole.bytesInGB, /* Bytes */1), "on disk")));
                                                                    default:
                                                                      return ;
                                                                  }
                                                                }))
                                                        })), React.createElement("div", {
                                                      className: price
                                                    }, tmp)),
                                            key: plan$1.id
                                          }), index === 0 ? React.createElement(SubModifyPlanModal$PlanQuantitySelector, {
                                              selectedPlan: selectedPlan$1,
                                              planCount: planCount,
                                              setPlanCount: setPlanCount
                                            }) : null);
                          })) : React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No plan options available."))
                }), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        disabled: tmp,
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(10)),
                                /* [] */0
                              ])
                        },
                        onClick: (function (param) {
                            NemesisMetrics$BsConsole.send(/* ChangePlan */1);
                            if (selectedPlan$1 === undefined) {
                              return ;
                            }
                            var features = limits.metadata.features;
                            var addons = features !== undefined ? SubPriceUtil$BsConsole.getNextPlanAddons(selectedPlan$1, planCount, features) : [];
                            return Curry._3(updateSubscriptions, selectedPlan$1.id, planCount, addons);
                          }),
                        children: loading ? I18N$BsConsole.show(undefined, "Loading...") : I18N$BsConsole.show(undefined, "Confirm")
                      })
                }));
}

var make = SubModifyPlanModal;

exports.Style = Style;
exports.PlanQuantitySelector = PlanQuantitySelector;
exports.getCurrentlySubscribedPlan = getCurrentlySubscribedPlan;
exports.make = make;
/* title Not a pure module */
