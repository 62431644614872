// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Link$BsConsole = require("../Link.js");

function BugLink(Props) {
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var className = Props.className;
  var children = Props.children;
  return ReasonReact.element(undefined, undefined, Link$BsConsole.make(route, undefined, handleChangeUrl, undefined, undefined, className, [children]));
}

var make = BugLink;

exports.make = make;
/* ReasonReact Not a pure module */
