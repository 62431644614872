// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var Card = require("@material-ui/core/Card");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var NemesisTrialEnd$BsConsole = require("./NemesisTrialEnd.js");
var ApiBillingLimits$BsConsole = require("../ApiBillingLimits.js");
var CardContent = require("@material-ui/core/CardContent");

var section = Css.style(/* :: */[
      Css.marginTop(Css.px(24)),
      /* [] */0
    ]);

var Style = {
  section: section
};

function SubCustomPlan(Props) {
  var token = Props.token;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var config = Props.config;
  var match = React.useState((function () {
          return false;
        }));
  var setConfirmationModal = match[1];
  var canModifyNemesisPlans = SubUtil$BsConsole.canModifyNemesisPlans(config);
  var _gate = limits.metadata.gate;
  var tmp;
  if (_gate.tag) {
    var endTimestamp = _gate[0];
    tmp = typeof endTimestamp === "number" ? I18N$BsConsole.show(undefined, "You are on a custom plan.") : (
        endTimestamp.tag ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "You are on a custom plan with a free trial that expired at ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* Char_literal */Block.__(12, [
                                /* "." */46,
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "You are on a custom plan with a free trial that expired at %s."
                  ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, new Date(endTimestamp[0]))) : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "You are on a custom plan with a free trial that expires at ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* Char_literal */Block.__(12, [
                                /* "." */46,
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "You are on a custom plan with a free trial that expires at %s."
                  ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, new Date(endTimestamp[0])))
      );
  } else {
    tmp = null;
  }
  var _gate$1 = limits.metadata.gate;
  var tmp$1;
  if (_gate$1.tag) {
    var tmp$2 = _gate$1[0];
    tmp$1 = typeof tmp$2 === "number" ? I18N$BsConsole.show(undefined, "Please reach out to our support team if you need assistance with your subscription.") : (
        tmp$2.tag ? I18N$BsConsole.show(undefined, "Your free trial has ended and your plan will soon be downgraded to the free developer plan.") : (
            canModifyNemesisPlans ? React.createElement(Row2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    children: null
                  }, React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(80)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "If you're loving Backtrace and are ready to subscribe, great! Please click the button to the right. This will remove the trial expiration date from your custom plan so you can continue using Backtrace without interruption. Our team will be notified and get in touch with you soon to discuss payment options and any further plan adjustments.")), React.createElement("div", {
                            className: section
                          }, I18N$BsConsole.show(undefined, "If you decide you do not wish to pay for Backtrace at this time, no worries! Your Backtrace subscription will automatically be downgraded to the Free Developer Plan at the end of your trial.")), ApiBillingLimits$BsConsole.getCurrentUserCount(undefined) > 0 ? React.createElement("div", {
                              className: section
                            }, I18N$BsConsole.show(undefined, "Please note that only 1 seat is included in the Free Developer Plan. Extra user accounts will be blocked from the console. You will be able to restore blocked accounts here in our self-serve portal, as well as make other adjustments to your subscription, after your trial ends.")) : null), React.createElement("div", undefined, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            onClick: (function (_event) {
                                NemesisMetrics$BsConsole.send(/* OpenTrialModal */5);
                                return Curry._1(setConfirmationModal, (function (param) {
                                              return true;
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Subscribe")
                          }))) : null
          )
      );
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Card.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]
                        ])
                  },
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Col2$BsConsole.make, {
                              alignItems: /* flexStart */662439529,
                              justifyContent: /* center */98248149,
                              children: null
                            }, React.createElement("div", {
                                  className: canModifyNemesisPlans ? SubUtil$BsConsole.Style.titleText : SubUtil$BsConsole.Style.titleTextNoMarginBottom
                                }, tmp), React.createElement("div", {
                                  className: SubUtil$BsConsole.Style.bodyText
                                }, tmp$1))
                      })
                }), React.createElement(BtModal$BsConsole.Default.make, {
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setConfirmationModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  title: I18N$BsConsole.get(undefined, "Subscribe to Backtrace"),
                  subtitle: I18N$BsConsole.empty(undefined),
                  divider: true,
                  maxWidth: "sm",
                  fullWidth: true,
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* center */98248149,
                      children: I18N$BsConsole.show(undefined, "By continuing, you are agreeing to purchase a Backtrace subscription. We will be in touch soon to discuss payment options and plan terms.")
                    }), React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* flexEnd */924268066,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Button.default, {
                          variant: "outlined",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginTop(Css.px(10)),
                                  /* :: */[
                                    Css.marginRight(Css.px(15)),
                                    /* [] */0
                                  ]
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(setConfirmationModal, (function (param) {
                                            return false;
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "Cancel")
                        }), React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginTop(Css.px(10)),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              NemesisMetrics$BsConsole.send(/* ConfirmTrialEnd */6);
                              var arg = NemesisTrialEnd$BsConsole.update(undefined);
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), undefined, (function (resp) {
                                            if (resp.tag) {
                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(SubUtil$BsConsole.unknownErrorMessage));
                                            }
                                            Curry._1(refetchLimits, undefined);
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Plan subscription successful!"));
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "Confirm")
                        }))));
}

var make = SubCustomPlan;

exports.Style = Style;
exports.make = make;
/* section Not a pure module */
