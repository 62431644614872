// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var mixpanel = {
  token: "3c5a2c7baa5bb9d275a2e8cf7f811830",
  url: "https://api.mixpanel.com"
};

exports.mixpanel = mixpanel;
/* No side effect */
