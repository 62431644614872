// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var WfArray$BsConsole = require("./WfArray.js");
var WfObject$BsConsole = require("./WfObject.js");
var WfEnumArray$BsConsole = require("./WfEnumArray.js");

var arrayComponent = ReasonReact.statelessComponent("WfMain_Array");

var objectComponent = ReasonReact.statelessComponent("WfMain_Object");

function makeWfArray(btDefaults, token, fieldDoc, initialValue, onValueChange, schema, showAllErrors, remoteAction, param) {
  return {
          debugName: arrayComponent.debugName,
          reactClassInternal: arrayComponent.reactClassInternal,
          handedOffState: arrayComponent.handedOffState,
          willReceiveProps: arrayComponent.willReceiveProps,
          didMount: arrayComponent.didMount,
          didUpdate: arrayComponent.didUpdate,
          willUnmount: arrayComponent.willUnmount,
          willUpdate: arrayComponent.willUpdate,
          shouldUpdate: arrayComponent.shouldUpdate,
          render: (function (param) {
              var enumSchema = schema.childSchema;
              if (enumSchema.tag === /* Enum */3) {
                return ReasonReact.element(undefined, undefined, WfEnumArray$BsConsole.make(fieldDoc, initialValue, onValueChange, showAllErrors, remoteAction, schema, enumSchema[0], []));
              } else {
                return ReasonReact.element(undefined, undefined, WfArray$BsConsole.make(btDefaults, token, fieldDoc, initialValue, onValueChange, schema, showAllErrors, remoteAction, makeWfArray, makeWfObject, []));
              }
            }),
          initialState: arrayComponent.initialState,
          retainedProps: arrayComponent.retainedProps,
          reducer: arrayComponent.reducer,
          jsElementWrapped: arrayComponent.jsElementWrapped
        };
}

function makeWfObject(btDefaults, token, rootObjectOpt, fieldDoc, initialValue, remoteAction, onValueChange, showAllErrors, schema, param) {
  var rootObject = rootObjectOpt !== undefined ? rootObjectOpt : false;
  return {
          debugName: objectComponent.debugName,
          reactClassInternal: objectComponent.reactClassInternal,
          handedOffState: objectComponent.handedOffState,
          willReceiveProps: objectComponent.willReceiveProps,
          didMount: objectComponent.didMount,
          didUpdate: objectComponent.didUpdate,
          willUnmount: objectComponent.willUnmount,
          willUpdate: objectComponent.willUpdate,
          shouldUpdate: objectComponent.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, WfObject$BsConsole.make(btDefaults, token, rootObject, fieldDoc, initialValue, remoteAction, onValueChange, showAllErrors, schema, makeWfArray, makeWfObject, []));
            }),
          initialState: objectComponent.initialState,
          retainedProps: objectComponent.retainedProps,
          reducer: objectComponent.reducer,
          jsElementWrapped: objectComponent.jsElementWrapped
        };
}

var RootObject = {
  make: makeWfObject
};

var RR;

exports.RR = RR;
exports.arrayComponent = arrayComponent;
exports.objectComponent = objectComponent;
exports.makeWfArray = makeWfArray;
exports.makeWfObject = makeWfObject;
exports.RootObject = RootObject;
/* arrayComponent Not a pure module */
