// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");
var BugVariablesTree$BsConsole = require("./BugVariablesTree.js");
var BugVariablesToggle$BsConsole = require("./BugVariablesToggle.js");

function BugVariables(Props) {
  var annotationsDict = Props.annotationsDict;
  var tlsOpt = Props.tls;
  var variables = Props.variables;
  var selectedVariable = Props.selectedVariable;
  var setSelectedVariable = Props.setSelectedVariable;
  var paneFocused = Props.paneFocused;
  var onClick = Props.onClick;
  var isLocalVarsVisible = Props.isLocalVarsVisible;
  var toggleIsLocalVarsVisbile = Props.toggleIsLocalVarsVisbile;
  var tls = tlsOpt !== undefined ? tlsOpt : [];
  var variablesTree = React.useMemo((function () {
          return Belt_Option.map(variables, (function (eta) {
                        return BugVariablesTree$BsConsole.makeTree(undefined, eta);
                      }));
        }), [variables]);
  var tlsTree = React.useMemo((function () {
          if (tls.length === 0) {
            return ;
          } else {
            return BugVariablesTree$BsConsole.makeTree(undefined, tls);
          }
        }), [tls]);
  React.useEffect((function () {
          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_RenderVariables */106]));
          
        }), [variables]);
  var tmp;
  if (tlsTree !== undefined) {
    var tmp$1 = {
      annotationsDict: annotationsDict,
      title: "Thread Local Storage",
      tree: tlsTree,
      setSelectedVariable: setSelectedVariable,
      paneFocused: paneFocused
    };
    if (selectedVariable !== undefined) {
      tmp$1.selectedVariable = Caml_option.valFromOption(selectedVariable);
    }
    tmp = React.createElement(BugVariablesTree$BsConsole.make, tmp$1);
  } else {
    tmp = null;
  }
  var tmp$2;
  if (variablesTree !== undefined) {
    var tmp$3 = {
      annotationsDict: annotationsDict,
      title: tls.length === 0 ? "" : "variables",
      tree: variablesTree,
      setSelectedVariable: setSelectedVariable,
      initialExpanded: true,
      paneFocused: paneFocused
    };
    if (selectedVariable !== undefined) {
      tmp$3.selectedVariable = Caml_option.valFromOption(selectedVariable);
    }
    tmp$2 = React.createElement(BugVariablesTree$BsConsole.make, tmp$3);
  } else {
    tmp$2 = React.createElement(BugVariablesTree$BsConsole.Empty.make, {
          title: "Variables",
          text: "No variables available for this frame"
        });
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.fontSize(Css.px(12)),
                    /* :: */[
                      Css.fontFamily("Inconsolata"),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* [] */0
                      ]
                    ]
                  ]),
              onClick: onClick,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement(BugSectionHeader$BsConsole.make, {
                        title: I18N$BsConsole.get(undefined, "Variables"),
                        children: React.createElement(BugVariablesToggle$BsConsole.make, {
                              isLocalVarsVisible: isLocalVarsVisible,
                              toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile
                            })
                      })
                }), tmp, tmp$2);
}

var make = BugVariables;

exports.make = make;
/* Css Not a pure module */
