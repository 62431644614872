// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Regex$BsConsole = require("../Regex.js");

function usernameValidator(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var str = Curry._1(accessor, state);
              if (str === "") {
                return /* Error */Block.__(1, ["Username is required"]);
              } else if (str.split(" ").length > 1) {
                return /* Error */Block.__(1, ["Spaces are not allowed"]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

function emailValidator(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var email = Curry._1(accessor, state);
              if (email === "") {
                return /* Error */Block.__(1, ["Email is required"]);
              }
              if (!Regex$BsConsole.forValidEmail.test(email)) {
                return /* Error */Block.__(1, ["Invalid email"]);
              }
              if (email.length >= 256) {
                return /* Error */Block.__(1, ["Email too long"]);
              }
              var emailParts = email.split("@");
              var emailStart = Belt_Array.getExn(emailParts, 0);
              var emailEnd = Belt_Array.getExn(emailParts, 1);
              var emailEndParts = emailEnd.split(".");
              if (emailStart.length >= 64 || Belt_Array.some(emailEndParts, (function (part) {
                        return part.length >= 64;
                      }))) {
                return /* Error */Block.__(1, ["Email too long"]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

var asciiOnly = new RegExp("^[\x01-\x7f]+$", "i");

function passwordValidator(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var password = Curry._1(accessor, state);
              if (password === "") {
                return /* Error */Block.__(1, ["Password is required"]);
              } else if (password.length < 8) {
                return /* Error */Block.__(1, ["Password must be at least 8 characters long"]);
              } else if (password.length > 64) {
                return /* Error */Block.__(1, ["Password is too long"]);
              } else if (Regex$BsConsole.containsSymbolsOrNumbers.test(password)) {
                if (asciiOnly.test(password)) {
                  return /* Ok */Block.__(0, [/* Valid */0]);
                } else {
                  return /* Error */Block.__(1, ["Password should only contain ASCII characters"]);
                }
              } else {
                return /* Error */Block.__(1, ["Password must contain at least one number or symbol"]);
              }
            })
        };
}

function passwordForLoginValidator(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var password = Curry._1(accessor, state);
              if (password === "" || password.trim() === "") {
                return /* Error */Block.__(1, ["Password is required"]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

function genericPasswordValidator(password) {
  var password$1 = password.trim();
  if (password$1 === "") {
    return "Password is required";
  } else if (password$1.length < 8) {
    return "Password must be at least 8 characters long";
  } else if (password$1.length > 64) {
    return "Password is too long";
  } else if (Regex$BsConsole.containsSymbolsOrNumbers.test(password$1)) {
    if (!asciiOnly.test(password$1)) {
      return "Password should only contain ASCII characters";
    } else {
      return ;
    }
  } else {
    return "Password must contain at least one number or symbol";
  }
}

function update(state, value) {
  return {
          username: value,
          password: state.password
        };
}

var validator = usernameValidator(/* Username */0, (function (state) {
        return state.username;
      }));

var Username = {
  update: update,
  validator: validator
};

function update$1(state, value) {
  return {
          username: state.username,
          password: value
        };
}

var validator$1 = passwordForLoginValidator(/* Password */1, (function (state) {
        return state.password;
      }));

var Password = {
  update: update$1,
  validator: validator$1
};

var validators_001 = /* :: */[
  validator$1,
  /* [] */0
];

var validators = /* :: */[
  validator,
  validators_001
];

var Login = {
  Username: Username,
  Password: Password,
  validators: validators
};

function update$2(state, value) {
  return {
          emailStart: value,
          emailDomain: state.emailDomain,
          username: state.username
        };
}

function validator_validate(state) {
  var l = state.emailStart.length;
  if (l !== 0) {
    if (l >= 64) {
      return /* Error */Block.__(1, ["Email is too long"]);
    } else {
      return /* Ok */Block.__(0, [/* Valid */0]);
    }
  } else {
    return /* Error */Block.__(1, ["Email is required"]);
  }
}

var validator$2 = {
  field: /* EmailStart */0,
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  dependents: undefined,
  validate: validator_validate
};

var EmailStart = {
  update: update$2,
  validator: validator$2
};

function update$3(state, value) {
  return {
          emailStart: state.emailStart,
          emailDomain: value,
          username: state.username
        };
}

function validator_validate$1(param) {
  return /* Ok */Block.__(0, [/* Valid */0]);
}

var validator$3 = {
  field: /* EmailDomain */1,
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  dependents: undefined,
  validate: validator_validate$1
};

var EmailDomain = {
  update: update$3,
  validator: validator$3
};

function update$4(state, value) {
  return {
          emailStart: state.emailStart,
          emailDomain: state.emailDomain,
          username: value
        };
}

var validator$4 = usernameValidator(/* Username */2, (function (state) {
        return state.username;
      }));

var Username$1 = {
  update: update$4,
  validator: validator$4
};

var validators_001$1 = /* :: */[
  validator$2,
  /* :: */[
    validator$3,
    /* [] */0
  ]
];

var validators$1 = /* :: */[
  validator$4,
  validators_001$1
];

var SignUp = {
  EmailStart: EmailStart,
  EmailDomain: EmailDomain,
  Username: Username$1,
  validators: validators$1
};

function update$5(state, value) {
  return {
          password: value,
          passwordConfirmation: state.passwordConfirmation
        };
}

var init = passwordValidator(/* Password */0, (function (state) {
        return state.password;
      }));

var validator_field = init.field;

var validator_strategy = init.strategy;

var validator_dependents = /* :: */[
  /* PasswordConfirmation */1,
  /* [] */0
];

var validator_validate$2 = init.validate;

var validator$5 = {
  field: validator_field,
  strategy: validator_strategy,
  dependents: validator_dependents,
  validate: validator_validate$2
};

var Password$1 = {
  update: update$5,
  validator: validator$5
};

function update$6(state, value) {
  return {
          password: state.password,
          passwordConfirmation: value
        };
}

function validator_validate$3(state) {
  if (state.passwordConfirmation === "") {
    return /* Error */Block.__(1, ["Password confirmation is required"]);
  } else if (state.password !== state.passwordConfirmation) {
    return /* Error */Block.__(1, ["Passwords do not match"]);
  } else {
    return /* Ok */Block.__(0, [/* Valid */0]);
  }
}

var validator$6 = {
  field: /* PasswordConfirmation */1,
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  dependents: undefined,
  validate: validator_validate$3
};

var PasswordConfirmation = {
  update: update$6,
  validator: validator$6
};

var validators_001$2 = /* :: */[
  validator$6,
  /* [] */0
];

var validators$2 = /* :: */[
  validator$5,
  validators_001$2
];

var Activate = {
  Password: Password$1,
  PasswordConfirmation: PasswordConfirmation,
  validators: validators$2
};

function update$7(state, value) {
  return {
          email: state.email,
          username: value,
          method_: state.method_,
          role: state.role
        };
}

var validator$7 = emailValidator(/* Email */0, (function (state) {
        return state.email;
      }));

var Username$2 = {
  update: update$7,
  validator: validator$7
};

function update$8(state, value) {
  return {
          email: value,
          username: state.username,
          method_: state.method_,
          role: state.role
        };
}

var validator$8 = usernameValidator(/* Username */1, (function (state) {
        return state.username;
      }));

var Email = {
  update: update$8,
  validator: validator$8
};

function update$9(state, value) {
  return {
          email: state.email,
          username: state.username,
          method_: value,
          role: state.role
        };
}

function validator_validate$4(param) {
  return /* Ok */Block.__(0, [/* Valid */0]);
}

var validator$9 = {
  field: /* Method */2,
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  dependents: undefined,
  validate: validator_validate$4
};

var Method = {
  update: update$9,
  validator: validator$9
};

function update$10(state, value) {
  return {
          email: state.email,
          username: state.username,
          method_: state.method_,
          role: value
        };
}

function validator_validate$5(param) {
  return /* Ok */Block.__(0, [/* Valid */0]);
}

var validator$10 = {
  field: /* Role */3,
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  dependents: undefined,
  validate: validator_validate$5
};

var Role = {
  update: update$10,
  validator: validator$10
};

var validators_001$3 = /* :: */[
  validator$8,
  /* :: */[
    validator$9,
    /* :: */[
      validator$10,
      /* [] */0
    ]
  ]
];

var validators$3 = /* :: */[
  validator$7,
  validators_001$3
];

var Invite = {
  Username: Username$2,
  Email: Email,
  Method: Method,
  Role: Role,
  validators: validators$3
};

function update$11(_state, value) {
  return {
          email: value
        };
}

var validator$11 = emailValidator(/* Email */0, (function (state) {
        return state.email;
      }));

var Email$1 = {
  update: update$11,
  validator: validator$11
};

var validators$4 = /* :: */[
  validator$11,
  /* [] */0
];

var ChangeEmail = {
  Email: Email$1,
  validators: validators$4
};

var ChangePassword;

var PasswordReset;

exports.usernameValidator = usernameValidator;
exports.emailValidator = emailValidator;
exports.asciiOnly = asciiOnly;
exports.passwordValidator = passwordValidator;
exports.passwordForLoginValidator = passwordForLoginValidator;
exports.genericPasswordValidator = genericPasswordValidator;
exports.Login = Login;
exports.SignUp = SignUp;
exports.Activate = Activate;
exports.Invite = Invite;
exports.ChangePassword = ChangePassword;
exports.PasswordReset = PasswordReset;
exports.ChangeEmail = ChangeEmail;
/* asciiOnly Not a pure module */
