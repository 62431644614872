// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function ofJson(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          endpoint: Json_decode.field("endpoint", Json_decode.string, json)
        };
}

function injectValues(t, config) {
  return {
          title: t.title,
          endpoint: t.endpoint.replace("${_TOKEN_}", Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("token")), "_TOKEN_")).replace("${_ORIGIN_}", location.origin).replace("${_UNIVERSE_}", config.universe.name)
        };
}

var Link = {
  ofJson: ofJson,
  injectValues: injectValues
};

function getLinks(config) {
  return Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(window._BACKTRACE_CONFIG_LINKS), Json.parse), (function (overrides) {
                    try {
                      return Json_decode.list(ofJson, overrides);
                    }
                    catch (exn){
                      console.log("_BACKTRACE_CONFIG_LINKS Decode Error");
                      return ;
                    }
                  })), (function (ts) {
                return Belt_List.map(ts, (function (t) {
                              return injectValues(t, config);
                            }));
              }));
}

exports.Link = Link;
exports.getLinks = getLinks;
/* No side effect */
