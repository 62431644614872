// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var metricsNamespace = "nemesis/";

function toEvent(action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ViewPlansClick */0 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/view_plans_click",
                      payload: undefined
                    }]);
      case /* ChangePlan */1 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/change_plan",
                      payload: undefined
                    }]);
      case /* ChangePlanQuantity */2 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/change_plan_quantity",
                      payload: undefined
                    }]);
      case /* UpdateCreditCard */3 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/update_credit_card",
                      payload: undefined
                    }]);
      case /* UpdateInvoiceMe */4 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/update_invoice_me",
                      payload: undefined
                    }]);
      case /* OpenTrialModal */5 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/open_trial_modal",
                      payload: undefined
                    }]);
      case /* ConfirmTrialEnd */6 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/confirm_trial_end",
                      payload: undefined
                    }]);
      case /* FrozenPageImpression */7 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/frozen_page_impression",
                      payload: undefined
                    }]);
      case /* FrozenPageLogoutClick */8 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/frozen_page_logout_click",
                      payload: undefined
                    }]);
      
    }
  } else {
    switch (action.tag | 0) {
      case /* AddFeature */0 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/add_feature",
                      payload: Caml_option.some(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "feature",
                                  action[0]
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "view",
                                    action[1]
                                  ],
                                  /* [] */0
                                ]
                              ]))
                    }]);
      case /* RemoveFeature */1 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/remove_feature",
                      payload: Caml_option.some(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "feature",
                                  action[0]
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "view",
                                    action[1]
                                  ],
                                  /* [] */0
                                ]
                              ]))
                    }]);
      case /* TooltipImpression */2 :
          return /* GenericEvent */Block.__(2, [{
                      name: "nemesis/tooltip_impression",
                      payload: Caml_option.some(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "tooltipTitle",
                                  action[0]
                                ],
                                /* [] */0
                              ]))
                    }]);
      
    }
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* MetricsEvent-BsConsole Not a pure module */
