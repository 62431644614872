// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");

function revitalizeGhost(username, users) {
  var user = Belt_List.getBy(users, (function (d) {
          return d.username === username;
        }));
  return Belt_Option.map(user, (function (user) {
                return /* User */Block.__(0, [{
                            watcher: "_backtrace",
                            username: user.username,
                            email: user.email,
                            id: user.uid
                          }]);
              }));
}

function parseIssues(users, columns, seq, group) {
  var columnNames_assignees = Issues$BsConsole.Assignees.columnName;
  var columnNames_tickets = Issues$BsConsole.Tickets.columnName;
  var columnNames_state = Issues$BsConsole.State.columnName;
  var columnNames_tags = Issues$BsConsole.Tags.columnName;
  var columnNames_invariants = Issues$BsConsole.Invariant.columnName;
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var ghostlyPresence = Group$BsConsole.unwrapHeadString(getter(columnNames_assignees, /* Head */3));
  var zombie = users !== undefined && ghostlyPresence !== undefined ? revitalizeGhost(ghostlyPresence, users) : undefined;
  var properTickets = Group$BsConsole.unwrapTickets(getter(columnNames_tickets, /* Head */3));
  var tickets = properTickets !== undefined ? (
      zombie !== undefined ? Belt_List.concat(properTickets, /* :: */[
              Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                    zombie,
                    /* [] */0
                  ]),
              /* [] */0
            ]) : properTickets
    ) : (
      zombie !== undefined ? /* :: */[
          Issues$BsConsole.Ticket.makeBacktraceIssueTicket(/* :: */[
                zombie,
                /* [] */0
              ]),
          /* [] */0
        ] : properTickets
    );
  var assignees = Belt_Option.map(Belt_Option.map(tickets, (function (tickets) {
              return Belt_List.reduce(tickets, /* [] */0, (function (acc, t) {
                            switch (t.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  break;
                              case /* InteralAssigneeLink */2 :
                                  var assignees = t[0].assignees;
                                  if (assignees !== undefined) {
                                    return Belt_List.concat(acc, assignees);
                                  } else {
                                    return acc;
                                  }
                              
                            }
                            var assignees$1 = t[0].assignees;
                            if (assignees$1 !== undefined) {
                              return Belt_List.concat(acc, assignees$1);
                            } else {
                              return acc;
                            }
                          }));
            })), (function (d) {
          return d;
        }));
  return {
          fingerprint: group.factor.value,
          state: Group$BsConsole.unwrapIssueState(getter(columnNames_state, /* Head */3)),
          assignees: assignees !== undefined && !assignees ? undefined : assignees,
          tags: Group$BsConsole.unwrapTags(getter(columnNames_tags, /* Head */3)),
          tickets: tickets !== undefined && !tickets ? undefined : tickets,
          seq: seq,
          invariants: Issues$BsConsole.Invariant.fromJsonStr(Group$BsConsole.unwrapHeadString(getter(columnNames_state, /* Head */3)))
        };
}

function fromBsCrdbResponseGroup(users, columns, seq, group) {
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var guids = Belt_Option.getWithDefault(Group$BsConsole.unwrapUniqueCount(getter("guid", /* Unique */0)), 0);
  return {
          timestampRange: Group$BsConsole.unwrapDateRange(getter("timestamp", /* Range */5)),
          timestampBin: Group$BsConsole.unwrapBin(getter("timestamp", /* Bin */2)),
          firstSeen: Group$BsConsole.unwrapHeadFirstSeen(getter("fingerprint;first_seen", /* Head */3)),
          classifiers: Group$BsConsole.unwrapClassifiers(getter("classifiers", /* Head */3)),
          callstack: Group$BsConsole.unwrapCallstack(getter("callstack", /* Head */3)),
          guids: guids,
          count: group.count,
          fingerprint: group.factor.value,
          issues: parseIssues(users, columns, seq, group)
        };
}

function isGroup(f) {
  var zeroGroup = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{64}$").exec(f)));
  var hasZeros = Belt_Option.isSome(Caml_option.null_to_opt(new RegExp("^0{54}.+$").exec(f)));
  if (zeroGroup || !hasZeros) {
    return false;
  } else {
    return true;
  }
}

function formatFingerprint(f) {
  if (isGroup(f)) {
    return f.replace(new RegExp("^(0{57})"), "");
  } else {
    return f;
  }
}

exports.revitalizeGhost = revitalizeGhost;
exports.parseIssues = parseIssues;
exports.fromBsCrdbResponseGroup = fromBsCrdbResponseGroup;
exports.isGroup = isGroup;
exports.formatFingerprint = formatFingerprint;
/* Issues-BsConsole Not a pure module */
