// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var WfTasks$BsConsole = require("../../workflow/WfTasks.js");
var WfRouter$BsConsole = require("../../workflow/WfRouter.js");
var ApiAlerts$BsConsole = require("./ApiAlerts.js");
var AlertsTable$BsConsole = require("./AlertsTable.js");
var AlertsRouter$BsConsole = require("./AlertsRouter.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var CreateEditAlert$BsConsole = require("./CreateEditAlert.js");
var Notifications = require("@material-ui/icons/Notifications");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(30)),
      /* [] */0
    ]);

var Styles = {
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription,
  bottomMargin: bottomMargin
};

function alertConfigMessage(handleRouteChange) {
  return {
          title: "No issue based alerts configured yet",
          description: "Configure alerts to automatically generate notifications and tickets to your 3rd party integrations.",
          buttonLabel: "Create Alert",
          action: (function (param) {
              return Curry._1(handleRouteChange, /* CreateAlert */1);
            })
        };
}

function integrationConfigMessage(handleChangeUrl, projectName) {
  return {
          title: "No integrations configured yet",
          description: "To configure alerts, you will need to configure 3rd party integrations.",
          buttonLabel: "Take me to Integrations",
          action: (function (param) {
              return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                            projectName,
                            WfRouter$BsConsole.root,
                            undefined
                          ]));
            })
        };
}

function getContent(variant, handleRouteChange, handleChangeUrl, projectName) {
  if (variant) {
    return integrationConfigMessage(handleChangeUrl, projectName);
  } else {
    return alertConfigMessage(handleRouteChange);
  }
}

function IssueBasedAlerts$ConfigurationError(Props) {
  var configuration = Props.configuration;
  var handleRouteChange = Props.handleRouteChange;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var content = getContent(configuration, handleRouteChange, handleChangeUrl, projectName);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.justifyContent(/* center */98248149),
                        /* :: */[
                          Css.marginTop(Css.px(100)),
                          /* :: */[
                            Css.marginBottom(Css.px(100)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(Notifications.default, {
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* center */98248149),
                        /* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ]
                      ]),
                  fontSize: "large"
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* center */98248149),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, content.title)
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.alignSelf(/* center */98248149),
                              /* [] */0
                            ]),
                        /* :: */[
                          featureDescription,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, content.description)
                }), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: React.createElement(Button.default, {
                        variant: "contained",
                        size: "medium",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(content.action, undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, content.buttonLabel)
                      })
                }));
}

var ConfigurationError = {
  alertConfigMessage: alertConfigMessage,
  integrationConfigMessage: integrationConfigMessage,
  getContent: getContent,
  make: IssueBasedAlerts$ConfigurationError
};

var initialState_remote = {
  integrations: /* NotAsked */0,
  availableIntegrations: /* NotAsked */0,
  alerts: /* NotAsked */0
};

var initialState_alerts = [];

var initialState_availableIntegrations = [];

var initialState = {
  remote: initialState_remote,
  route: /* Root */0,
  alerts: initialState_alerts,
  availableIntegrations: initialState_availableIntegrations
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetRemote */0 :
        return {
                remote: action[0],
                route: state.route,
                alerts: state.alerts,
                availableIntegrations: state.availableIntegrations
              };
    case /* SetRoute */1 :
        return {
                remote: state.remote,
                route: action[0],
                alerts: state.alerts,
                availableIntegrations: state.availableIntegrations
              };
    case /* SetRemoteAndRoute */2 :
        return {
                remote: action[0],
                route: action[1],
                alerts: state.alerts,
                availableIntegrations: state.availableIntegrations
              };
    case /* SetAlerts */3 :
        return {
                remote: state.remote,
                route: state.route,
                alerts: action[0],
                availableIntegrations: state.availableIntegrations
              };
    case /* SetIntegrations */4 :
        return {
                remote: state.remote,
                route: state.route,
                alerts: state.alerts,
                availableIntegrations: action[0]
              };
    
  }
}

function IssueBasedAlerts(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var token = Props.token;
  var id = Props.id;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var route = match$1.route;
  var remote = match$1.remote;
  React.useEffect((function () {
          var match = remote.alerts;
          if (typeof match === "number" || match.tag || id === undefined) {
            Curry._1(dispatch, /* SetRoute */Block.__(1, [/* Root */0]));
          } else {
            Belt_Option.map(Belt_Array.getBy(match[0], (function (alert) {
                        return alert.id === Caml_format.caml_int_of_string(id);
                      })), (function (alert) {
                    return Curry._1(dispatch, /* SetRoute */Block.__(1, [/* EditAlert */Block.__(1, [alert])]));
                  }));
          }
          
        }), /* tuple */[
        remote.alerts,
        id
      ]);
  if (typeof route === "number") {
    if (route === /* Root */0) {
      var match$2 = remote.integrations;
      var match$3 = remote.availableIntegrations;
      var match$4 = remote.alerts;
      var exit = 0;
      var exit$1 = 0;
      if (typeof match$2 === "number") {
        var onSuccess = function (results) {
          var updatedRemote_integrations = /* Success */Block.__(0, [results.body]);
          var updatedRemote_availableIntegrations = remote.availableIntegrations;
          var updatedRemote_alerts = remote.alerts;
          var updatedRemote = {
            integrations: updatedRemote_integrations,
            availableIntegrations: updatedRemote_availableIntegrations,
            alerts: updatedRemote_alerts
          };
          return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
        };
        var onFailure = function (err) {
          var updatedRemote_integrations = /* Failure */Block.__(1, [err]);
          var updatedRemote_availableIntegrations = remote.availableIntegrations;
          var updatedRemote_alerts = remote.alerts;
          var updatedRemote = {
            integrations: updatedRemote_integrations,
            availableIntegrations: updatedRemote_availableIntegrations,
            alerts: updatedRemote_alerts
          };
          return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
        };
        var maybeTask = ApiAlerts$BsConsole.QueryIntegrations.queryIntegrationsTask(projectName, config);
        if (maybeTask !== undefined) {
          Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                  return ApiAlerts$BsConsole.responseCb(onSuccess, onFailure, param);
                }));
        } else {
          var updatedRemote_integrations = /* Failure */Block.__(1, ["Workflow service unavailable"]);
          var updatedRemote_availableIntegrations = remote.availableIntegrations;
          var updatedRemote_alerts = remote.alerts;
          var updatedRemote = {
            integrations: updatedRemote_integrations,
            availableIntegrations: updatedRemote_availableIntegrations,
            alerts: updatedRemote_alerts
          };
          Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service Unavailable"));
        }
      } else if (typeof match$3 === "number") {
        var onSuccess$1 = function (availableIntegrations) {
          var updatedRemote_integrations = remote.integrations;
          var updatedRemote_availableIntegrations = /* Success */Block.__(0, [availableIntegrations]);
          var updatedRemote_alerts = remote.alerts;
          var updatedRemote = {
            integrations: updatedRemote_integrations,
            availableIntegrations: updatedRemote_availableIntegrations,
            alerts: updatedRemote_alerts
          };
          return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
        };
        var onFailure$1 = function (err) {
          var updatedRemote_integrations = remote.integrations;
          var updatedRemote_availableIntegrations = /* Failure */Block.__(1, [err]);
          var updatedRemote_alerts = remote.alerts;
          var updatedRemote = {
            integrations: updatedRemote_integrations,
            availableIntegrations: updatedRemote_availableIntegrations,
            alerts: updatedRemote_alerts
          };
          return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
        };
        var maybeTask$1 = WfTasks$BsConsole.QueryWFIntegrations.queryWFIntegrationsTask(config);
        if (maybeTask$1 !== undefined) {
          Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                  return WfTasks$BsConsole.QueryWFIntegrations.responseCb(onSuccess$1, onFailure$1, param);
                }));
        } else {
          var updatedRemote_integrations$1 = remote.integrations;
          var updatedRemote_availableIntegrations$1 = /* Failure */Block.__(1, ["Workflow service unavailable"]);
          var updatedRemote_alerts$1 = remote.alerts;
          var updatedRemote$1 = {
            integrations: updatedRemote_integrations$1,
            availableIntegrations: updatedRemote_availableIntegrations$1,
            alerts: updatedRemote_alerts$1
          };
          Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote$1]));
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
        }
      } else if (typeof match$4 === "number") {
        exit = 2;
      } else if (match$4.tag) {
        exit = 1;
      } else if (typeof match$2 !== "number") {
        if (match$2.tag || !(match$2[0].length === 0 && match$4[0].length === 0)) {
          exit$1 = 3;
        } else {
          Curry._1(dispatch, /* SetRoute */Block.__(1, [/* ConfigurationError */Block.__(3, [/* Integrations */1])]));
        }
      }
      if (exit$1 === 3) {
        if (match$4[0].length === 0) {
          Curry._1(dispatch, /* SetRoute */Block.__(1, [/* ConfigurationError */Block.__(3, [/* Alerts */0])]));
        } else {
          exit = 1;
        }
      }
      switch (exit) {
        case 1 :
            if (typeof match$2 !== "number") {
              if (match$2.tag) {
                Curry._1(dispatch, /* SetRoute */Block.__(1, [/* Error */Block.__(2, [match$2[0]])]));
              } else if (typeof match$3 !== "number") {
                if (match$3.tag) {
                  Curry._1(dispatch, /* SetRoute */Block.__(1, [/* Error */Block.__(2, [match$3[0]])]));
                } else if (typeof match$4 !== "number") {
                  if (match$4.tag) {
                    Curry._1(dispatch, /* SetRoute */Block.__(1, [/* Error */Block.__(2, [match$4[0]])]));
                  } else {
                    Curry._1(dispatch, /* SetRoute */Block.__(1, [/* AlertsTable */Block.__(0, [match$4[0]])]));
                  }
                }
                
              }
              
            }
            break;
        case 2 :
            var onSuccess$2 = function (results) {
              var updatedRemote_integrations = remote.integrations;
              var updatedRemote_availableIntegrations = remote.availableIntegrations;
              var updatedRemote_alerts = /* Success */Block.__(0, [results.body]);
              var updatedRemote = {
                integrations: updatedRemote_integrations,
                availableIntegrations: updatedRemote_availableIntegrations,
                alerts: updatedRemote_alerts
              };
              return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
            };
            var onFailure$2 = function (err) {
              var updatedRemote_integrations = remote.integrations;
              var updatedRemote_availableIntegrations = remote.availableIntegrations;
              var updatedRemote_alerts = /* Failure */Block.__(1, [err]);
              var updatedRemote = {
                integrations: updatedRemote_integrations,
                availableIntegrations: updatedRemote_availableIntegrations,
                alerts: updatedRemote_alerts
              };
              return Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
            };
            var maybeTask$2 = ApiAlerts$BsConsole.QueryAlerts.queryAlertsTask(config, projectName);
            if (maybeTask$2 !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$2), undefined, (function (param) {
                      return ApiAlerts$BsConsole.responseCb(onSuccess$2, onFailure$2, param);
                    }));
            } else {
              var updatedRemote_integrations$2 = remote.integrations;
              var updatedRemote_availableIntegrations$2 = remote.availableIntegrations;
              var updatedRemote_alerts$2 = /* Failure */Block.__(1, ["Workflow service unavailable"]);
              var updatedRemote$2 = {
                integrations: updatedRemote_integrations$2,
                availableIntegrations: updatedRemote_availableIntegrations$2,
                alerts: updatedRemote_alerts$2
              };
              Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote$2]));
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
            break;
        
      }
      return null;
    }
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* IssueBasedAlert_CreateLinkClick */122]));
    var integrations = remote.integrations;
    var tmp;
    tmp = typeof integrations === "number" ? [] : (
        integrations.tag ? [] : integrations[0]
      );
    var availableIntegrations = remote.availableIntegrations;
    var tmp$1;
    tmp$1 = typeof availableIntegrations === "number" ? [] : (
        availableIntegrations.tag ? [] : availableIntegrations[0]
      );
    return React.createElement(CreateEditAlert$BsConsole.make, {
                alertState: /* CreateState */0,
                config: config,
                token: token,
                projectName: projectName,
                integrations: tmp,
                availableIntegrations: tmp$1,
                handleRefresh: (function (param) {
                    var updatedRemote_integrations = remote.integrations;
                    var updatedRemote_availableIntegrations = remote.availableIntegrations;
                    var updatedRemote = {
                      integrations: updatedRemote_integrations,
                      availableIntegrations: updatedRemote_availableIntegrations,
                      alerts: /* Loading */1
                    };
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* IssueBasedAlert_Created */123]));
                    return Curry._1(dispatch, /* SetRemoteAndRoute */Block.__(2, [
                                  updatedRemote,
                                  /* Root */0
                                ]));
                  }),
                handleCancel: (function (param) {
                    return Curry._1(dispatch, /* SetRoute */Block.__(1, [/* Root */0]));
                  })
              });
  } else {
    switch (route.tag | 0) {
      case /* AlertsTable */0 :
          return React.createElement(AlertsTable$BsConsole.make, {
                      alerts: route[0],
                      config: config,
                      projectName: projectName,
                      token: token,
                      handleRefresh: (function (param) {
                          var updatedRemote_integrations = remote.integrations;
                          var updatedRemote_availableIntegrations = remote.availableIntegrations;
                          var updatedRemote = {
                            integrations: updatedRemote_integrations,
                            availableIntegrations: updatedRemote_availableIntegrations,
                            alerts: /* Loading */1
                          };
                          return Curry._1(dispatch, /* SetRemoteAndRoute */Block.__(2, [
                                        updatedRemote,
                                        /* Root */0
                                      ]));
                        }),
                      handleChangeUrl: handleChangeUrl,
                      handleRouteChange: (function (route) {
                          return Curry._1(dispatch, /* SetRoute */Block.__(1, [route]));
                        })
                    });
      case /* EditAlert */1 :
          var integrations$1 = remote.integrations;
          var tmp$2;
          tmp$2 = typeof integrations$1 === "number" ? [] : (
              integrations$1.tag ? [] : integrations$1[0]
            );
          var availableIntegrations$1 = remote.availableIntegrations;
          var tmp$3;
          tmp$3 = typeof availableIntegrations$1 === "number" ? [] : (
              availableIntegrations$1.tag ? [] : availableIntegrations$1[0]
            );
          return React.createElement(CreateEditAlert$BsConsole.make, {
                      alertState: /* EditState */[route[0]],
                      config: config,
                      token: token,
                      projectName: projectName,
                      integrations: tmp$2,
                      availableIntegrations: tmp$3,
                      handleRefresh: (function (param) {
                          var updatedRemote_integrations = remote.integrations;
                          var updatedRemote_availableIntegrations = remote.availableIntegrations;
                          var updatedRemote = {
                            integrations: updatedRemote_integrations,
                            availableIntegrations: updatedRemote_availableIntegrations,
                            alerts: /* Loading */1
                          };
                          Curry._1(dispatch, /* SetRemote */Block.__(0, [updatedRemote]));
                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* IssueBasedAlert_Edited */124]));
                          return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                        projectName,
                                        {
                                          tab: AlertsRouter$BsConsole.typeToString(/* IssueBasedAlerts */1),
                                          id: undefined
                                        }
                                      ]));
                        }),
                      handleCancel: (function (param) {
                          return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                        projectName,
                                        {
                                          tab: AlertsRouter$BsConsole.typeToString(/* IssueBasedAlerts */1),
                                          id: undefined
                                        }
                                      ]));
                        })
                    });
      case /* Error */2 :
          return React.createElement(WarningMessage$BsConsole.make, {
                      errorMessage: I18N$BsConsole.show(undefined, route[0])
                    });
      case /* ConfigurationError */3 :
          return React.createElement(IssueBasedAlerts$ConfigurationError, {
                      configuration: route[0],
                      handleRouteChange: (function (route) {
                          return Curry._1(dispatch, /* SetRoute */Block.__(1, [route]));
                        }),
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName
                    });
      
    }
  }
}

var make = IssueBasedAlerts;

exports.Styles = Styles;
exports.ConfigurationError = ConfigurationError;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* pageMarginBottom Not a pure module */
