// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var WfParser$BsConsole = require("../workflow/WfParser.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var WfFormJsx3$BsConsole = require("../workflow/WfFormJsx3.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");

function adapterToString(t) {
  return t[0];
}

function ExporterCreateOrUpdateStoreModal$ScrollBox(Props) {
  var boxWidth = Props.boxWidth;
  var boxHeight = Props.boxHeight;
  var children = Props.children;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.px(boxWidth)),
                    /* :: */[
                      Css.height(Css.px(boxHeight)),
                      /* :: */[
                        Css.overflowY(/* auto */-1065951377),
                        /* :: */[
                          Css.overflowX(/* auto */-1065951377),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var ScrollBox = {
  make: ExporterCreateOrUpdateStoreModal$ScrollBox
};

function ExporterCreateOrUpdateStoreModal(Props) {
  var token = Props.token;
  var _open = Props.open;
  var onClose = Props.onClose;
  var handleSave = Props.handleSave;
  var adapters = Props.adapters;
  var editStore = Props.editStore;
  React.useState((function () {
          return /* Adapter */0;
        }));
  var match = React.useState((function () {
          
        }));
  var setAdapter = match[1];
  var adapter = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setConfiguration = match$1[1];
  var configuration = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setValidConfiguration = match$2[1];
  var validConfiguration = match$2[0];
  var match$3 = React.useState((function () {
          return 60;
        }));
  var setPeriod = match$3[1];
  var period = match$3[0];
  React.useEffect((function () {
          if (editStore !== undefined) {
            var existingAdapter = editStore[1];
            var existingStore = editStore[0];
            Curry._1(setAdapter, (function (param) {
                    return existingAdapter;
                  }));
            Curry._1(setPeriod, (function (param) {
                    return existingStore.period | 0;
                  }));
          }
          
        }), [editStore]);
  var close = function (param) {
    Curry._1(onClose, undefined);
    Curry._1(setAdapter, (function (param) {
            
          }));
    return Curry._1(setConfiguration, (function (param) {
                  return "";
                }));
  };
  var handleFormValueChange = function (errorOrResult) {
    if (errorOrResult.tag) {
      if (validConfiguration) {
        return Curry._1(setValidConfiguration, (function (param) {
                      return false;
                    }));
      } else {
        return ;
      }
    }
    var form = errorOrResult[0];
    Curry._1(setConfiguration, (function (param) {
            return JSON.stringify(form);
          }));
    if (!validConfiguration) {
      return Curry._1(setValidConfiguration, (function (param) {
                    return true;
                  }));
    }
    
  };
  var tmp;
  if (adapter !== undefined) {
    var adapterConfigurationScheme = adapter[1];
    if (adapterConfigurationScheme !== undefined) {
      if (adapterConfigurationScheme.tag) {
        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, WfParser$BsConsole.getUserFriendlyError(adapterConfigurationScheme[0])));
        tmp = null;
      } else {
        var tmp$1;
        if (editStore !== undefined) {
          try {
            tmp$1 = JSON.parse(editStore[0].configuration);
          }
          catch (_err){
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error parsing store configuration"));
            tmp$1 = Json_encode.object_(/* [] */0);
          }
        } else {
          tmp$1 = Json_encode.object_(/* [] */0);
        }
        tmp = React.createElement(ExporterCreateOrUpdateStoreModal$ScrollBox, {
              boxWidth: 570,
              boxHeight: 250,
              children: React.createElement(WfFormJsx3$BsConsole.make, {
                    btDefaults: Json_encode.object_(/* [] */0),
                    token: token,
                    form: adapterConfigurationScheme[0],
                    initialFormValue: tmp$1,
                    onValueChange: handleFormValueChange
                  })
            });
      }
    } else {
      tmp = React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.marginTop(Css.px(8)),
                  /* :: */[
                    Css.marginLeft(Css.px(12)),
                    /* [] */0
                  ]
                ])
          }, React.createElement(BtTypography$BsConsole.make, {
                variant: /* Caption */11,
                children: I18N$BsConsole.show(undefined, "No configuration required.")
              }));
    }
  } else {
    tmp = React.createElement(ExporterCreateOrUpdateStoreModal$ScrollBox, {
          boxWidth: 570,
          boxHeight: 250,
          children: null
        });
  }
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: close,
              modalWidth: 630,
              title: editStore !== undefined ? I18N$BsConsole.get(undefined, "Edit metrics store configuration") : I18N$BsConsole.get(undefined, "Create a metrics store"),
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.height(Css.px(440)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.height(Css.px(350)),
                              /* [] */0
                            ])
                      }, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                            label: I18N$BsConsole.show(undefined, "Period (in seconds)")
                          }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                            type: "number",
                            placeholder: I18N$BsConsole.get(undefined, "60"),
                            value: String(period),
                            onChange: (function ($$event) {
                                var value = $$event.target.value;
                                try {
                                  var valueInt = Caml_format.caml_int_of_string(value);
                                  return Curry._1(setPeriod, (function (param) {
                                                return valueInt;
                                              }));
                                }
                                catch (_err){
                                  return ;
                                }
                              }),
                            inputProps: {
                              step: 1,
                              min: 0
                            }
                          }), React.createElement(BtTypeaheadInput$BsConsole.Single.make, {
                            selectedItem: adapter,
                            toString: adapterToString,
                            inputLabel: I18N$BsConsole.get(undefined, "Adapter"),
                            placeholder: editStore !== undefined ? editStore[1][0] : I18N$BsConsole.get(undefined, "Select an adapter"),
                            noItemsMessage: I18N$BsConsole.get(undefined, "No known adapters"),
                            items: adapters,
                            itemFilter: (function (param) {
                                return true;
                              }),
                            pixelWidth: 570,
                            handleItemSelection: (function (item) {
                                return Curry._1(setAdapter, (function (param) {
                                              return item;
                                            }));
                              }),
                            disabled: Belt_Option.isSome(editStore)
                          }), tmp), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: adapter !== undefined ? Belt_Option.isSome(adapter[1]) && !validConfiguration : true,
                            onClick: (function (param) {
                                Curry._3(handleSave, adapter, configuration, period);
                                return close(undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Save")
                          }), React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: close,
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var make = ExporterCreateOrUpdateStoreModal;

exports.adapterToString = adapterToString;
exports.ScrollBox = ScrollBox;
exports.make = make;
/* Css Not a pure module */
