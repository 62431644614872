// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Clear = require("@material-ui/icons/Clear");
var Checkbox = require("@material-ui/core/Checkbox");
var TextField = require("@material-ui/core/TextField");
var ApiTokensMetadata$BsConsole = require("./ApiTokensMetadata.js");
var CardContent = require("@material-ui/core/CardContent");
var ApiTokensCapabilities$BsConsole = require("./ApiTokensCapabilities.js");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

function ApiTokensEdit(Props) {
  var apiToken = Props.apiToken;
  var token = Props.token;
  var close = Props.close;
  var match = React.useState((function () {
          return Belt_Option.map(Belt_Option.flatMap(apiToken.metadata, ApiTokensMetadata$BsConsole.fromString), (function (m) {
                        return m.description;
                      }));
        }));
  var setDescription = match[1];
  var description = match[0];
  var match$1 = React.useState((function () {
          return ApiTokensCapabilities$BsConsole.stringToCapabilities(apiToken.capabilities);
        }));
  var setCapabilities = match$1[1];
  var capabilities = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setErrorMsg = match$2[1];
  var errorMsg = match$2[0];
  var capStr = ApiTokensCapabilities$BsConsole.capabilitiesToString(capabilities);
  return React.createElement(CardContent.default, {
              children: null
            }, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: "Close",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading6 */5,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(90)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.show(undefined, "Update API token")
                    }), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.show(undefined, "Token")
                    }), React.createElement(TextField.default, {
                      disabled: true,
                      placeholder: I18N$BsConsole.get(undefined, "Token"),
                      value: apiToken.id,
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.marginRight(Css.rem(1)),
                                /* :: */[
                                  Css.selector("input", /* :: */[
                                        Css.important(Css.paddingTop(Css.px(6))),
                                        /* :: */[
                                          Css.important(Css.paddingBottom(Css.px(7))),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("div[class*='multiline']", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(1)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.show(undefined, "Description")
                    }), React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "Short name / description (optional)"),
                      value: Belt_Option.mapWithDefault(description, "", Util$BsConsole.identity),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1)),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]
                            ])
                      },
                      onChange: (function (e) {
                          var newVal = e.target.value;
                          return Curry._1(setDescription, (function (param) {
                                        return newVal;
                                      }));
                        })
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.show(undefined, "Capabilities")
                    }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ])
                    }), React.createElement(FormControlLabel.default, {
                      label: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip("error:post")
                              }), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.em(1)),
                                      /* :: */[
                                        Css.lineHeight(Css.em(1)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Enable error and dump submission with the specified API token.")
                              })),
                      control: React.createElement(Checkbox.default, {
                            checked: capabilities.errorPost,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                      /* :: */[
                                        Css.paddingRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("svg", /* :: */[
                                                Css.width(Css.rem(0.9)),
                                                /* :: */[
                                                  Css.height(Css.rem(0.9)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var checked = e.target.checked;
                                return Curry._1(setCapabilities, (function (caps) {
                                              return {
                                                      errorPost: checked,
                                                      symbolPost: caps.symbolPost,
                                                      objectGet: caps.objectGet,
                                                      queryPost: caps.queryPost,
                                                      syncPost: caps.syncPost
                                                    };
                                            }));
                              }),
                            disableRipple: true
                          }),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* :: */[
                                Css.marginLeft(/* zero */-789508312),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }
                    }), React.createElement(FormControlLabel.default, {
                      label: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip("symbol:post")
                              }), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.em(1)),
                                      /* :: */[
                                        Css.lineHeight(Css.em(1)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Enable symbol uploads with the specified API token.")
                              })),
                      control: React.createElement(Checkbox.default, {
                            checked: capabilities.symbolPost,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                      /* :: */[
                                        Css.paddingRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("svg", /* :: */[
                                                Css.width(Css.rem(0.9)),
                                                /* :: */[
                                                  Css.height(Css.rem(0.9)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var checked = e.target.checked;
                                return Curry._1(setCapabilities, (function (caps) {
                                              return {
                                                      errorPost: caps.errorPost,
                                                      symbolPost: checked,
                                                      objectGet: caps.objectGet,
                                                      queryPost: caps.queryPost,
                                                      syncPost: caps.syncPost
                                                    };
                                            }));
                              }),
                            disableRipple: true
                          }),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* :: */[
                                Css.marginLeft(/* zero */-789508312),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }
                    }), React.createElement(FormControlLabel.default, {
                      label: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip("object:get")
                              }), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.em(1)),
                                      /* :: */[
                                        Css.lineHeight(Css.em(1)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Allows for fetching objects.")
                              })),
                      control: React.createElement(Checkbox.default, {
                            checked: capabilities.objectGet,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                      /* :: */[
                                        Css.paddingRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("svg", /* :: */[
                                                Css.width(Css.rem(0.9)),
                                                /* :: */[
                                                  Css.height(Css.rem(0.9)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var checked = e.target.checked;
                                return Curry._1(setCapabilities, (function (caps) {
                                              return {
                                                      errorPost: caps.errorPost,
                                                      symbolPost: caps.symbolPost,
                                                      objectGet: checked,
                                                      queryPost: caps.queryPost,
                                                      syncPost: caps.syncPost
                                                    };
                                            }));
                              }),
                            disableRipple: true
                          }),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* :: */[
                                Css.marginLeft(/* zero */-789508312),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }
                    }), React.createElement(FormControlLabel.default, {
                      label: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip("query:post")
                              }), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.em(1)),
                                      /* :: */[
                                        Css.lineHeight(Css.em(1)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Enable queries to be issued using the specified token.")
                              })),
                      control: React.createElement(Checkbox.default, {
                            checked: capabilities.queryPost,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                      /* :: */[
                                        Css.paddingRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("svg", /* :: */[
                                                Css.width(Css.rem(0.9)),
                                                /* :: */[
                                                  Css.height(Css.rem(0.9)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var checked = e.target.checked;
                                return Curry._1(setCapabilities, (function (caps) {
                                              return {
                                                      errorPost: caps.errorPost,
                                                      symbolPost: caps.symbolPost,
                                                      objectGet: caps.objectGet,
                                                      queryPost: checked,
                                                      syncPost: caps.syncPost
                                                    };
                                            }));
                              }),
                            disableRipple: true
                          }),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* :: */[
                                Css.marginLeft(/* zero */-789508312),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }
                    }), React.createElement(FormControlLabel.default, {
                      label: React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip("sync:post")
                              }), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.em(1)),
                                      /* :: */[
                                        Css.lineHeight(Css.em(1)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Enables synchronous post, instance will respond with the results of object processing. There is a performance impact. Required for submission buckets.")
                              })),
                      control: React.createElement(Checkbox.default, {
                            checked: capabilities.syncPost,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                      /* :: */[
                                        Css.paddingRight(Css.rem(0.5)),
                                        /* :: */[
                                          Css.selector("svg", /* :: */[
                                                Css.width(Css.rem(0.9)),
                                                /* :: */[
                                                  Css.height(Css.rem(0.9)),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var checked = e.target.checked;
                                return Curry._1(setCapabilities, (function (caps) {
                                              return {
                                                      errorPost: caps.errorPost,
                                                      symbolPost: caps.symbolPost,
                                                      objectGet: caps.objectGet,
                                                      queryPost: caps.queryPost,
                                                      syncPost: checked
                                                    };
                                            }));
                              }),
                            disableRipple: true
                          }),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* :: */[
                                Css.marginLeft(/* zero */-789508312),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }
                    })), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: capStr.length === 0,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginRight(Css.rem(0.5)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      var metadata;
                      try {
                        metadata = Caml_option.some(ApiTokensMetadata$BsConsole.getMetaJson(description, apiToken.metadata, undefined));
                      }
                      catch (_e){
                        metadata = undefined;
                      }
                      var props = [/* tuple */[
                          "capabilities",
                          capStr
                        ]];
                      var props$1 = metadata !== undefined ? Belt_Array.concat(props, [/* tuple */[
                                "metadata",
                                Caml_option.valFromOption(metadata)
                              ]]) : props;
                      var arg = BpgTask$BsConsole.ApiToken.updateFields(apiToken, props$1);
                      return Curry._2((function (param) {
                                      return (function (param$1, param$2) {
                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                        });
                                    })(token), true, (function (res) {
                                    if (!res.tag) {
                                      return Curry._1(close, undefined);
                                    }
                                    var err = res[0];
                                    if (err[0] !== BpgTask$BsConsole.BpgError) {
                                      return Curry._1(setErrorMsg, (function (param) {
                                                    return I18N$BsConsole.get(undefined, "Unknown error");
                                                  }));
                                    }
                                    var errStr = err[2];
                                    return Curry._1(setErrorMsg, (function (param) {
                                                  return errStr;
                                                }));
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Update")
                }), React.createElement(Button.default, {
                  color: "primary",
                  onClick: (function (param) {
                      return Curry._1(close, undefined);
                    }),
                  children: I18N$BsConsole.show(undefined, "Cancel")
                }), errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.fontSize(Css.px(18)),
                            /* [] */0
                          ]
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.showSkip(errorMsg)
                  }) : null);
}

var make = ApiTokensEdit;

exports.make = make;
/* Css Not a pure module */
