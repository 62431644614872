// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var BtSelect$BsConsole = require("./BtSelect.js");

var component = ReasonReact.statelessComponent("TenantSelect");

function getValue($$event) {
  return $$event.target.value;
}

function getUrl(currentUniverse, nextUniverse, origin) {
  var regex = new RegExp(currentUniverse, "i");
  var match = regex.exec(origin);
  if (match !== null) {
    return origin.replace(regex, nextUniverse);
  }
  
}

function make(currentUniverse, universes, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, BtSelect$BsConsole.make((function (e, param) {
                                var u = e.target.value;
                                if (u === currentUniverse.name) {
                                  return ;
                                }
                                var url = getUrl(currentUniverse.name, u, window.location.origin);
                                if (url !== undefined) {
                                  window.open(url);
                                  return ;
                                } else {
                                  console.log("none");
                                  return ;
                                }
                              }), /* `String */[
                              -976970511,
                              currentUniverse.name
                            ], Css.style(/* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1)),
                                    /* :: */[
                                      Css.selector(".root", /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.white)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        Css.selector(".select", /* :: */[
                                              Css.maxWidth(Css.px(120)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]), I18N$BsConsole.get(undefined, "Universe"), undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey2)),
                                  /* [] */0
                                ]), undefined, [Belt_Array.map(universes, (function (u) {
                                      return ReasonReact.element(u, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                      -976970511,
                                                      u
                                                    ], undefined, undefined, [I18N$BsConsole.showSkip(u)]));
                                    }))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.getValue = getValue;
exports.getUrl = getUrl;
exports.make = make;
/* component Not a pure module */
