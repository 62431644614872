// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Email$BsConsole = require("../Email.js");
var Shell$BsConsole = require("../shell.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Configuration$BsConsole = require("../configuration.js");
var SubmissionUrls$BsConsole = require("./SubmissionUrls.js");

var __DEFAULT_SCA_SERVICE_ENDPOINT = "https://sca.backtrace.io/api/sca";

var __DEFAULT_BACKTRACE_SUBMISSION_TOKEN = "<BACKTRACE_SUBMISSION_TOKEN>";

function guide(t) {
  return t.guide;
}

function guideName(t) {
  return t.guide.name;
}

function format(t) {
  var match = t.guide.name;
  switch (match) {
    case "Source Maps" :
        return "sourcemap";
    case "Android" :
    case "C#" :
    case "Clang-Analyzer" :
    case "Electron" :
    case "JavaScript" :
    case "NestJS" :
    case "NodeJS" :
    case "Python" :
    case "React" :
    case "React Native" :
    case "Unity" :
        return "json";
    case "Breakpad" :
    case "Crashpad" :
    case "Minidump" :
    case "Unreal" :
        return "minidump";
    case "iOS" :
        return "plcrash";
    default:
      return "<format>";
  }
}

function symbolFormat(t) {
  var match = t.guide.name;
  switch (match) {
    case "Proguard" :
        return "proguard";
    case "JavaScript" :
    case "NestJS" :
    case "NodeJS" :
    case "React" :
    case "React Native" :
    case "Source Maps" :
        return "sourcemap";
    default:
      return "<format>";
  }
}

var crashpadSubmitInstruction = I18N$BsConsole.get(undefined, "If your version of Crashpad does not append `/post`, then use the following:");

function crashpadSubmitPathDescriptionWithSubmit(path) {
  return "```\n" + (path + "\n```");
}

function crashpadSubmitPathDescriptionNoSubmit(path) {
  return "```\n      " + (String(path) + ("\n    ```\n    " + (String(crashpadSubmitInstruction) + ("\n    ```\n    " + (String(path) + "/post\n    ```\n    ")))));
}

var $$Text = {
  crashpadSubmitInstruction: crashpadSubmitInstruction,
  crashpadSubmitPathDescriptionWithSubmit: crashpadSubmitPathDescriptionWithSubmit,
  crashpadSubmitPathDescriptionNoSubmit: crashpadSubmitPathDescriptionNoSubmit
};

function make(t) {
  var partial_arg = Caml_option.some(Belt_Option.map(t.port, (function (prim) {
              return String(prim);
            })));
  var partial_arg$1 = Caml_option.some(t.universeName);
  var toUrl = function (param, param$1, param$2, param$3, param$4) {
    return SubmissionUrls$BsConsole.createSubmissionUrl(partial_arg$1, partial_arg, param, param$1, param$2, param$3, param$4);
  };
  var arg = Caml_option.some(SubmissionUrls$BsConsole.getSubmissionUrlOriginTemplate(false, undefined));
  var arg$1 = Caml_option.some(SubmissionUrls$BsConsole.getSubmissionUrlTemplate(false, undefined));
  var match = t.guide.name;
  var toSubmit;
  var exit = 0;
  switch (match) {
    case "Proguard" :
    case "Source Maps" :
        exit = 1;
        break;
    case "Visual Studio" :
        var arg$2 = Caml_option.some(SubmissionUrls$BsConsole.getSubmissionUrlOriginTemplate(undefined, undefined));
        toSubmit = (function (param) {
            return Curry._3(toUrl, Caml_option.some(undefined), param, arg$2);
          });
        break;
    default:
      var arg$3 = Caml_option.some(t.receiveLayer);
      toSubmit = (function (param) {
          return Curry._3(toUrl, Caml_option.some(t.submissionToken), param, arg$3);
        });
  }
  if (exit === 1) {
    var arg$4 = Caml_option.some(t.receiveLayer);
    toSubmit = (function (param) {
        return Curry._3(toUrl, Caml_option.some(t.submissionSymbolToken), param, arg$4);
      });
  }
  var format$1 = format(t);
  var symbolFormat$1 = symbolFormat(t);
  var match$1 = t.receiveLayer;
  if (match$1 !== undefined) {
    return {
            toSubmit: Curry._3(toSubmit, Caml_option.some(format$1), undefined, undefined),
            toNonSubmit: Curry._5(toUrl, undefined, Caml_option.some(format$1), arg, undefined, undefined),
            toSubmitPost: Curry._3(toSubmit, Caml_option.some(format$1), undefined, undefined),
            toSubmitSymbol: Curry._3(toSubmit, Caml_option.some(symbolFormat$1), undefined, undefined),
            toSubmitSymbolPost: Curry._3(toSubmit, Caml_option.some(symbolFormat$1), undefined, undefined),
            descForCrashpad: crashpadSubmitPathDescriptionWithSubmit(Curry._3(toSubmit, Caml_option.some(format$1), undefined, undefined)),
            conditionalSubmitTokenString: undefined
          };
  } else {
    return {
            toSubmit: Curry._5(toUrl, undefined, Caml_option.some(format$1), arg, undefined, undefined),
            toNonSubmit: Curry._5(toUrl, undefined, Caml_option.some(format$1), arg, undefined, undefined),
            toSubmitPost: Curry._5(toUrl, Caml_option.some(t.submissionToken), Caml_option.some(format$1), arg$1, undefined, undefined),
            toSubmitSymbol: Curry._5(toUrl, undefined, Caml_option.some(symbolFormat$1), arg, undefined, undefined),
            toSubmitSymbolPost: Curry._5(toUrl, Caml_option.some(t.submissionSymbolToken), Caml_option.some(symbolFormat$1), arg$1, undefined, undefined),
            descForCrashpad: crashpadSubmitPathDescriptionNoSubmit(Curry._5(toUrl, undefined, Caml_option.some(format$1), arg, undefined, undefined)),
            conditionalSubmitTokenString: "- Token: `REPLACE_TOKEN`"
          };
  }
}

var Path = {
  $$Text: $$Text,
  make: make
};

function fromApiToken(token) {
  return {
          id: token.id,
          project: token.project,
          owner: token.owner
        };
}

function fromToken(token) {
  return {
          id: token.id,
          project: token.project,
          owner: token.owner
        };
}

function findForProjectAndUser(tokenList, defaultToken, projectOwnerID, userUID) {
  var flatMapOptionLeft = function (result, fn) {
    if (!result.tag) {
      return /* Ok */Block.__(0, [result[0]]);
    }
    var list = result[0];
    return Belt_Option.getWithDefault(Belt_Option.map(Curry._1(fn, list), (function (token) {
                      return /* Ok */Block.__(0, [token.id]);
                    })), /* Error */Block.__(1, [list]));
  };
  return Belt_Result.getWithDefault(flatMapOptionLeft(flatMapOptionLeft(flatMapOptionLeft(Belt_List.length(tokenList) === 0 ? /* Ok */Block.__(0, [defaultToken]) : /* Error */Block.__(1, [tokenList]), (function (l) {
                            return Belt_List.getBy(l, (function (token) {
                                          return token.owner === userUID;
                                        }));
                          })), (function (l) {
                        return Belt_List.getBy(l, (function (token) {
                                      return token.owner === projectOwnerID;
                                    }));
                      })), Belt_List.head), defaultToken);
}

var SubmitToken = {
  fromApiToken: fromApiToken,
  fromToken: fromToken,
  findForProjectAndUser: findForProjectAndUser
};

var detector = /%%%([A-Z][A-Z_]+::[A-Z_][A-Z0-9_]+[A-Z0-9])%%%/gm;

function fromTokenString(tokenString) {
  var _otherwise = tokenString.split("::");
  var len = _otherwise.length;
  if (len >= 3) {
    return /* Unknown */Block.__(4, [tokenString]);
  }
  switch (len) {
    case 0 :
        return /* Invalid */Block.__(0, [tokenString]);
    case 1 :
        return /* Unknown */Block.__(4, [tokenString]);
    case 2 :
        var match = _otherwise[0];
        switch (match) {
          case "EMAIL" :
              var email = _otherwise[1];
              return /* Email */Block.__(3, [email]);
          case "ROOT" :
              var root_token = _otherwise[1];
              return /* Root */Block.__(1, [root_token]);
          case "SUPPORT_LINK" :
              var path = _otherwise[1];
              return /* SupportLink */Block.__(2, [path]);
          default:
            return /* Unknown */Block.__(4, [tokenString]);
        }
    
  }
}

function renderRootToken(paths, template, rootToken) {
  switch (rootToken) {
    case "CONDITIONAL_TOKEN" :
        return Belt_Option.getWithDefault(paths.conditionalSubmitTokenString, "");
    case "CRASHPAD_PATH_DESC" :
        return paths.descForCrashpad;
    case "NON_SUBMIT_SUBMISSION_PATH" :
        return paths.toNonSubmit;
    case "ORIGIN" :
        return template.origin;
    case "PROJECT" :
        return template.projectName;
    case "SCA_SERVICE_ENDPOINT" :
        return template.scaServiceEndpoint;
    case "SUBMISSION_PATH" :
        return paths.toSubmit;
    case "SUBMISSION_POST" :
        return paths.toSubmitPost;
    case "SYMBOL_SUBMISSION_PATH" :
        return paths.toSubmitSymbol;
    case "SYMBOL_SUBMISSION_POST" :
        return paths.toSubmitSymbolPost;
    case "SYMBOL_TOKEN" :
        return template.submissionSymbolToken;
    case "TOKEN" :
        return template.submissionToken;
    case "UNIVERSE_NAME" :
        return template.universeName;
    default:
      return rootToken;
  }
}

function renderSupportLink(_paths, _template, pathToken) {
  var maybeDocPath;
  switch (pathToken) {
    case "ARTICLE_PHABRICATOR_INTEGRATION" :
        maybeDocPath = /* ArticlePhabricatorIntegration */2;
        break;
    case "ARTICLE_PRICING_TIER_FEATURES" :
        maybeDocPath = /* ArticlePricingTierFeatures */3;
        break;
    case "ARTICLE_SCHEDULED_REPORTS" :
        maybeDocPath = /* ArticleScheduledReports */4;
        break;
    case "ARTICLE_SUBMISSION_BUCKETS" :
        maybeDocPath = /* ArticleSubmissionBuckets */6;
        break;
    case "CATEGORY_INTEGRATION_GUIDES" :
        maybeDocPath = /* CategoryIntegrationGuides */7;
        break;
    case "GUIDE_ACCOUNT_MANAGEMENT" :
        maybeDocPath = /* GuideAccountManagement */8;
        break;
    case "GUIDE_FLAMEGRAPHS" :
        maybeDocPath = /* GuideFlamegraphs */19;
        break;
    case "GUIDE_INTEGRATION_BREAKPAD" :
        maybeDocPath = /* GuideIntegrationBreakpad */9;
        break;
    case "GUIDE_INTEGRATION_CORESNAP" :
        maybeDocPath = /* GuideIntegrationCoresnap */10;
        break;
    case "GUIDE_INTEGRATION_CRASHPAD" :
        maybeDocPath = /* GuideIntegrationCrashpad */11;
        break;
    case "GUIDE_INTEGRATION_CRYENGINE" :
        maybeDocPath = /* GuideIntegrationCryEngine */12;
        break;
    case "GUIDE_INTEGRATION_ELECTRON" :
        maybeDocPath = /* GuideIntegrationElectron */13;
        break;
    case "GUIDE_INTEGRATION_HTTP_MINIDUMP" :
        maybeDocPath = /* GuideIntegrationHttpMinidump */14;
        break;
    case "GUIDE_INTEGRATION_IOS" :
        maybeDocPath = /* GuideIntegrationIOS */15;
        break;
    case "GUIDE_INTEGRATION_UNITY" :
        maybeDocPath = /* GuideIntegrationUnity */16;
        break;
    case "GUIDE_INTEGRATION_UNREAL" :
        maybeDocPath = /* GuideIntegrationUnreal */17;
        break;
    case "GUIDE_INTEGRATION_VISUAL_STUDIO" :
        maybeDocPath = /* GuideIntegrationVisualStudio */18;
        break;
    case "MANAGE_THE_BACKTRACE_METRIC_OBJECTS" :
        maybeDocPath = /* ManageTheBacktraceMetricObjects */20;
        break;
    case "SETTINGS_ATTRIBUTES" :
        maybeDocPath = /* SettingsAttributes */21;
        break;
    case "SETTINGS_SYMBOLIFICATION" :
        maybeDocPath = /* SettingsSymbolification */22;
        break;
    case "STABILITY_METRIC_WIDGETS" :
        maybeDocPath = /* StabilityMetricWidgets */23;
        break;
    case "WHAT_ARE_SYMBOLS" :
        maybeDocPath = /* WhatAreSymbols */24;
        break;
    case "WHAT_IS_A_SUBMISSION_TOKEN" :
        maybeDocPath = /* WhatIsASubmissionToken */25;
        break;
    case "WHY_ARE_AGGREGATE_FILTERS_REMOVED_IN_CERTAIN_VIEWS" :
        maybeDocPath = /* WhyAreAggregateFiltersRemovedInCertainViews */26;
        break;
    default:
      maybeDocPath = undefined;
  }
  return Belt_Option.getWithDefault(Belt_Option.map(maybeDocPath, (function (path) {
                    var supportLink = Href$BsConsole.Support.make(undefined, path);
                    if (supportLink !== undefined) {
                      return supportLink;
                    } else {
                      return "404";
                    }
                  })), pathToken);
}

function renderSupportEmail(_paths, _template, emailToken) {
  return Email$BsConsole.support;
}

function render(paths, template, tokenString) {
  var token = fromTokenString(tokenString);
  switch (token.tag | 0) {
    case /* Root */1 :
        return renderRootToken(paths, template, token[0]);
    case /* SupportLink */2 :
        return renderSupportLink(paths, template, token[0]);
    case /* Email */3 :
        return renderSupportEmail(paths, template, token[0]);
    case /* Invalid */0 :
    case /* Unknown */4 :
        return tokenString;
    
  }
}

var Token = {
  detector: detector,
  fromTokenString: fromTokenString,
  renderRootToken: renderRootToken,
  renderSupportLink: renderSupportLink,
  renderSupportEmail: renderSupportEmail,
  render: render
};

function renderMarkdown(t, markdown) {
  var paths = make(t);
  var handler = function (_fullMatch, capture, _offset, _wholeString) {
    return render(paths, t, capture);
  };
  return markdown.replace(detector, handler);
}

function make$1(config, origin, project, receiveLayer, tokens, apiTokens, guide) {
  var svcName = guide.gated;
  return {
          backtraceSubmitPath: SubmissionUrls$BsConsole.__BACKTRACE_SUBMIT_PATH,
          guide: guide,
          isEnabled: svcName !== undefined ? Belt_Option.isSome(Belt_Option.flatMap(config.services, (function (l) {
                        return Belt_List.getBy(l, (function (svc) {
                                      return svc.name === svcName;
                                    }));
                      }))) : true,
          isVersionCompatible: Belt_Option.getWithDefault(Belt_Option.map(guide.coronerdVersion, CoronerdGate$BsConsole.isEnoughCoronerdVersion), true),
          origin: origin,
          port: Configuration$BsConsole.getPort(undefined, config),
          projectName: project.name,
          receiveLayer: receiveLayer,
          scaServiceEndpoint: Shell$BsConsole.getServiceEndpoint(config, "sca", __DEFAULT_SCA_SERVICE_ENDPOINT),
          submissionSymbolToken: findForProjectAndUser(Belt_List.map(Belt_List.keep(apiTokens, (function (token) {
                          if (token.project === project.pid) {
                            return token.capabilities === "symbol:post";
                          } else {
                            return false;
                          }
                        })), fromApiToken), __DEFAULT_BACKTRACE_SUBMISSION_TOKEN, project.owner, config.user.uid),
          submissionToken: findForProjectAndUser(Belt_List.map(Belt_List.keep(tokens, (function (token) {
                          return token.project === project.pid;
                        })), fromToken), __DEFAULT_BACKTRACE_SUBMISSION_TOKEN, project.owner, config.user.uid),
          universeName: config.universe.name
        };
}

var BPG;

var __DEFAULT_BACKTRACE_SYMBOL_SUBMISSION_TOKEN = "<BACKTRACE_SYMBOL_SUBMISSION_TOKEN>";

exports.BPG = BPG;
exports.__DEFAULT_SCA_SERVICE_ENDPOINT = __DEFAULT_SCA_SERVICE_ENDPOINT;
exports.__DEFAULT_BACKTRACE_SUBMISSION_TOKEN = __DEFAULT_BACKTRACE_SUBMISSION_TOKEN;
exports.__DEFAULT_BACKTRACE_SYMBOL_SUBMISSION_TOKEN = __DEFAULT_BACKTRACE_SYMBOL_SUBMISSION_TOKEN;
exports.guide = guide;
exports.guideName = guideName;
exports.format = format;
exports.symbolFormat = symbolFormat;
exports.Path = Path;
exports.SubmitToken = SubmitToken;
exports.Token = Token;
exports.renderMarkdown = renderMarkdown;
exports.make = make$1;
/* crashpadSubmitInstruction Not a pure module */
