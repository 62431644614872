// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

function secondsToDays(seconds) {
  return seconds / 60 / 60 / 24 | 0;
}

function daysToSeconds(days) {
  return Caml_int32.imul(Caml_int32.imul(Caml_int32.imul(days, 60), 60), 24);
}

var default_000 = {
  count: 2,
  interval: 0
};

var default_001 = /* :: */[
  {
    count: 4,
    interval: 3600
  },
  /* :: */[
    {
      count: 8,
      interval: 216000
    },
    /* :: */[
      {
        count: 16,
        interval: 5184000
      },
      /* [] */0
    ]
  ]
];

var $$default = /* :: */[
  default_000,
  default_001
];

function fromJson(json) {
  return {
          count: Json_decode.field("count", Json_decode.$$int, json),
          interval: Json_decode.field("interval", Json_decode.$$int, json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "count",
                t.count
              ],
              /* :: */[
                /* tuple */[
                  "interval",
                  t.interval
                ],
                /* [] */0
              ]
            ]);
}

var Backoff = {
  $$default: $$default,
  fromJson: fromJson,
  toJson: toJson
};

var $$default$1 = {
  backoffs: $$default,
  attributes: /* [] */0,
  resetInterval: 30,
  otherFields: /* [] */0
};

function fromJson$1(json) {
  var otherFields = Belt_List.fromArray(Belt_Array.keep(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, json)), (function (param) {
              var n = param[0];
              if (n !== "backoffs" && n !== "object_attributes") {
                return n !== "reset_interval";
              } else {
                return false;
              }
            })));
  return {
          backoffs: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("backoffs", (function (param) {
                                    return Json_decode.list(fromJson, param);
                                  }), param);
                    }), json), /* [] */0),
          attributes: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("object_attributes", (function (param) {
                                    return Json_decode.list(Json_decode.string, param);
                                  }), param);
                    }), json), /* [] */0),
          resetInterval: secondsToDays(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                          return Json_decode.field("reset_interval", Json_decode.$$int, param);
                        }), json), 0)),
          otherFields: otherFields
        };
}

function sortBackoffs(t) {
  return {
          backoffs: Belt_List.sort(t.backoffs, (function (a, b) {
                  return a.count - b.count | 0;
                })),
          attributes: t.attributes,
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function toJson$1(t) {
  var ts = sortBackoffs(t);
  return Json_encode.object_(Belt_List.concatMany([
                  t.otherFields,
                  /* :: */[
                    /* tuple */[
                      "type",
                      "backoff"
                    ],
                    /* :: */[
                      /* tuple */[
                        "backoffs",
                        Json_encode.list(toJson, ts.backoffs)
                      ],
                      /* [] */0
                    ]
                  ],
                  /* :: */[
                    /* tuple */[
                      "object_attributes",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), t.attributes)
                    ],
                    /* [] */0
                  ],
                  /* :: */[
                    /* tuple */[
                      "reset_interval",
                      daysToSeconds(t.resetInterval)
                    ],
                    /* [] */0
                  ]
                ]));
}

function addBackoff(t, backoff) {
  return sortBackoffs({
              backoffs: Belt_List.concat(t.backoffs, /* :: */[
                    backoff,
                    /* [] */0
                  ]),
              attributes: t.attributes,
              resetInterval: t.resetInterval,
              otherFields: t.otherFields
            });
}

function removeBackoff(t, outBackoff) {
  return sortBackoffs({
              backoffs: Belt_List.keep(t.backoffs, (function (inBackoff) {
                      return Caml_obj.caml_notequal(inBackoff, outBackoff);
                    })),
              attributes: t.attributes,
              resetInterval: t.resetInterval,
              otherFields: t.otherFields
            });
}

function removeAttribute(t, outAttribute) {
  return {
          backoffs: t.backoffs,
          attributes: Belt_List.keep(t.attributes, (function (inAttribute) {
                  return inAttribute !== outAttribute;
                })),
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function addAttribute(t, inAttribute) {
  return {
          backoffs: t.backoffs,
          attributes: Belt_List.concat(t.attributes, /* :: */[
                inAttribute,
                /* [] */0
              ]),
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function addRateLimit(t, param) {
  return {
          backoffs: Belt_List.concat(t.backoffs, /* :: */[
                {
                  count: param[0],
                  interval: param[1]
                },
                /* [] */0
              ]),
          attributes: t.attributes,
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function removeRateLimit(t, outRateLimit) {
  return {
          backoffs: Belt_List.keep(t.backoffs, (function (challenger) {
                  if (outRateLimit.count !== challenger.count) {
                    return outRateLimit.interval !== challenger.interval;
                  } else {
                    return false;
                  }
                })),
          attributes: t.attributes,
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function updateRateLimit(t, idxR, rateLimit) {
  return {
          backoffs: Belt_List.mapWithIndex(t.backoffs, (function (idxM, challenger) {
                  if (idxR === idxM) {
                    return rateLimit;
                  } else {
                    return challenger;
                  }
                })),
          attributes: t.attributes,
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

function setResetInterval(t, resetInterval) {
  return {
          backoffs: t.backoffs,
          attributes: t.attributes,
          resetInterval: resetInterval,
          otherFields: t.otherFields
        };
}

function resetRateLimitDefaults(t) {
  return {
          backoffs: $$default,
          attributes: t.attributes,
          resetInterval: t.resetInterval,
          otherFields: t.otherFields
        };
}

var Configuration = {
  $$default: $$default$1,
  fromJson: fromJson$1,
  sortBackoffs: sortBackoffs,
  toJson: toJson$1,
  addBackoff: addBackoff,
  removeBackoff: removeBackoff,
  removeAttribute: removeAttribute,
  addAttribute: addAttribute,
  addRateLimit: addRateLimit,
  removeRateLimit: removeRateLimit,
  updateRateLimit: updateRateLimit,
  setResetInterval: setResetInterval,
  resetRateLimitDefaults: resetRateLimitDefaults
};

function fromBpg(sampling) {
  return {
          key: BacktraceParserGenerator$BsConsole.ProjectSampling.to_key(sampling),
          project: sampling.project,
          configuration: fromJson$1(sampling.configuration),
          disabled: sampling.disabled !== 0
        };
}

function createTask(t) {
  return BpgTask$BsConsole.ProjectSampling.create({
              project: t.project,
              configuration: toJson$1(t.configuration),
              disabled: t.disabled ? 1 : 0,
              __state: undefined,
              __create: undefined,
              __modify: undefined
            });
}

function removeAttribute$1(t, attribute) {
  return {
          key: t.key,
          project: t.project,
          configuration: removeAttribute(t.configuration, attribute),
          disabled: t.disabled
        };
}

function sortBackoffs$1(t) {
  return {
          key: t.key,
          project: t.project,
          configuration: sortBackoffs(t.configuration),
          disabled: t.disabled
        };
}

function addBackoff$1(t, backoff) {
  return {
          key: t.key,
          project: t.project,
          configuration: addBackoff(t.configuration, backoff),
          disabled: t.disabled
        };
}

function removeBackoff$1(t, outBackoff) {
  return {
          key: t.key,
          project: t.project,
          configuration: removeBackoff(t.configuration, outBackoff),
          disabled: t.disabled
        };
}

function addAttribute$1(t, attribute) {
  return {
          key: t.key,
          project: t.project,
          configuration: addAttribute(t.configuration, attribute),
          disabled: t.disabled
        };
}

function addRateLimit$1(t, rateLimit) {
  return {
          key: t.key,
          project: t.project,
          configuration: addRateLimit(t.configuration, rateLimit),
          disabled: t.disabled
        };
}

function removeRateLimit$1(t, rateLimit) {
  return {
          key: t.key,
          project: t.project,
          configuration: removeRateLimit(t.configuration, rateLimit),
          disabled: t.disabled
        };
}

function setResetInterval$1(t, resetInterval) {
  return {
          key: t.key,
          project: t.project,
          configuration: setResetInterval(t.configuration, resetInterval),
          disabled: t.disabled
        };
}

function resetRateLimitDefaults$1(t) {
  return {
          key: t.key,
          project: t.project,
          configuration: resetRateLimitDefaults(t.configuration),
          disabled: t.disabled
        };
}

function updateRateLimit$1(t, idx, rateLimit) {
  return {
          key: t.key,
          project: t.project,
          configuration: updateRateLimit(t.configuration, idx, rateLimit),
          disabled: t.disabled
        };
}

function updateDisabled(t, disabled) {
  return {
          key: t.key,
          project: t.project,
          configuration: t.configuration,
          disabled: disabled
        };
}

function save(token, t, onSuccess, onFailure) {
  var key = t.key;
  if (key !== undefined) {
    var arg = BpgTask$BsConsole.ProjectSampling.updateFieldsByKey(key, [
          /* tuple */[
            "disabled",
            t.disabled ? 1 : 0
          ],
          /* tuple */[
            "configuration",
            toJson$1(t.configuration)
          ]
        ]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return Curry._1(onFailure, resp[0]);
                  } else {
                    return Curry._1(onSuccess, undefined);
                  }
                }));
  }
  var arg$1 = createTask(t);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return Curry._1(onFailure, resp[0]);
                } else {
                  return Curry._1(onSuccess, undefined);
                }
              }));
}

function setDisabled(token, t, onSuccess, onFailure, disabled) {
  var arg = updateDisabled(t, disabled);
  return save(token, arg, onSuccess, onFailure);
}

function use(token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setConfigurations = match[1];
  var configurations = match[0];
  React.useEffect((function () {
          if (typeof configurations === "number" && configurations === 0) {
            Curry._1(setConfigurations, (function (param) {
                    return /* Loading */1;
                  }));
            var arg = BpgTask$BsConsole.ProjectSampling.fetchAll;
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      var msg = resp[0];
                      return Curry._1(setConfigurations, (function (param) {
                                    return /* Failure */Block.__(1, [msg]);
                                  }));
                    }
                    var configurations = resp[0];
                    return Curry._1(setConfigurations, (function (param) {
                                  return /* Success */Block.__(0, [Belt_Array.map(configurations, fromBpg)]);
                                }));
                  }));
          }
          
        }), [configurations]);
  return /* tuple */[
          configurations,
          (function (param) {
              return Curry._1(setConfigurations, (function (param) {
                            return /* NotAsked */0;
                          }));
            })
        ];
}

function isValid(conf) {
  return Belt_Option.isSome(Belt_List.getBy(conf.configuration.backoffs, (function (backoff) {
                    return backoff.interval === 0;
                  })));
}

exports.secondsToDays = secondsToDays;
exports.daysToSeconds = daysToSeconds;
exports.Backoff = Backoff;
exports.Configuration = Configuration;
exports.fromBpg = fromBpg;
exports.createTask = createTask;
exports.removeAttribute = removeAttribute$1;
exports.sortBackoffs = sortBackoffs$1;
exports.addBackoff = addBackoff$1;
exports.removeBackoff = removeBackoff$1;
exports.addAttribute = addAttribute$1;
exports.addRateLimit = addRateLimit$1;
exports.removeRateLimit = removeRateLimit$1;
exports.setResetInterval = setResetInterval$1;
exports.resetRateLimitDefaults = resetRateLimitDefaults$1;
exports.updateRateLimit = updateRateLimit$1;
exports.updateDisabled = updateDisabled;
exports.save = save;
exports.setDisabled = setDisabled;
exports.use = use;
exports.isValid = isValid;
/* react Not a pure module */
