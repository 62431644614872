// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var Lens = require("@material-ui/icons/Lens");

function AlertsGroupInfo(Props) {
  var group = Props.group;
  var groupBy = Props.groupBy;
  var key = group.key;
  return React.createElement("div", undefined, Belt_Array.keepMap(group.triggers, (function (t) {
                    var level = t.level.toLowerCase();
                    var iconColor = level === "warning" ? Colors$BsConsole.orangeExtraLight : Colors$BsConsole.redLight;
                    var columnName = t.trigger.aggregation.column;
                    var str = String(t.value | 0) + (" " + ((
                          columnName === AlertsUtils$BsConsole.errorCountAttribute ? AlertsUtils$BsConsole.userFriendlyErrorCountAlt : columnName
                        ) + " "));
                    if (!(level === "warning" || level === "critical")) {
                      return ;
                    }
                    var match = groupBy.toLowerCase();
                    var tmp = match === "none" ? I18N$BsConsole.showEmpty(undefined) : (
                        key === "*" ? I18N$BsConsole.showSkip(" " + (I18N$BsConsole.get(undefined, "on all") + (" " + Util$BsConsole.pluralize(groupBy, 2, undefined, undefined, undefined)))) : React.createElement(Row2$BsConsole.make, {
                                children: null
                              }, React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(3)),
                                          /* :: */[
                                            Css.marginRight(Css.px(3)),
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "on") + (" " + (groupBy + " ")))), React.createElement(AlertsUtils$BsConsole.FrontEllipsis.make, {
                                    text: key,
                                    widthValue: Css.px(150)
                                  }))
                      );
                    return Caml_option.some(React.createElement(Row2$BsConsole.make, {
                                    alignItems: /* center */98248149,
                                    children: null,
                                    key: str
                                  }, React.createElement(Lens.default, {
                                        className: Css.style(/* :: */[
                                              Css.color(Css.hex(iconColor)),
                                              /* :: */[
                                                Css.important(Css.width(Css.px(10))),
                                                /* :: */[
                                                  Css.marginRight(Css.px(5)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ])
                                      }), I18N$BsConsole.showSkip(str), tmp));
                  })));
}

var make = AlertsGroupInfo;

exports.make = make;
/* Css Not a pure module */
