// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Crdb$BsConsole = require("./crdb.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var FormatUtils$BsConsole = require("./FormatUtils.js");
var NormByDefault$BsConsole = require("./NormByDefault.js");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

function makeMetricsQuery(aperture) {
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture);
  var aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default);
  return /* Aggregate */Block.__(0, [
            aperture$prime,
            /* Custom */["*"],
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "_metric_group",
                      /* Distribution */Block.__(1, [50])
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function selectDefault(metricGroupOptions, emptyMetricGroupOptions) {
  return Belt_Array.get(Belt_Array.concat(metricGroupOptions, emptyMetricGroupOptions), 0);
}

function getOptions(aperture, token, projectName, config) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var project = Belt_List.getBy(config.projects, (function (project) {
          return projectName === project.name;
        }));
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = makeMetricsQuery(apertureForQuery);
  var match$1 = QueryPaginationTask$BsConsole.MetricsAggregate.use(token, projectName, query, "_metric_group-distribution");
  var metricAggRemote = match$1[0];
  var match$2 = Task2$BsConsole.useRefetchable(undefined, BpgTask$BsConsole.MetricGroup.fetchAll, undefined);
  var metricGroupRemote = match$2[0];
  var metricGroupsFromQuery;
  if (typeof metricAggRemote === "number") {
    metricGroupsFromQuery = [];
  } else if (metricAggRemote.tag) {
    metricGroupsFromQuery = [];
  } else {
    var match$3 = metricAggRemote[0][0];
    var columns = match$3[0];
    var group = Belt_List.get(match$3[1], 0);
    if (group !== undefined) {
      var getter = function (param, param$1) {
        return Group$BsConsole.getAggregation(columns, group, param, param$1);
      };
      var distribution = Group$BsConsole.unwrapDistribution(getter("_metric_group", /* Distribution */1));
      metricGroupsFromQuery = distribution !== undefined ? Belt_List.toArray(distribution.vals) : [];
    } else {
      metricGroupsFromQuery = [];
    }
  }
  var metricGroups;
  metricGroups = typeof metricGroupRemote === "number" ? [] : (
      metricGroupRemote.tag ? [] : (
          project !== undefined ? Belt_Array.keep(metricGroupRemote[0][0], (function (metricGroup) {
                    return metricGroup.project === project.pid;
                  })) : []
        )
    );
  var match$4 = Belt_Array.reduce(metricGroups, /* tuple */[
        [],
        []
      ], (function (param, metricGroup) {
          var unused = param[1];
          var used = param[0];
          var match = Belt_Array.getBy(metricGroupsFromQuery, (function (param) {
                  return FormatUtils$BsConsole.formatToString(param[0]) === metricGroup.name;
                }));
          if (match !== undefined) {
            return /* tuple */[
                    Belt_Array.concat(used, [metricGroup.name]),
                    unused
                  ];
          } else {
            return /* tuple */[
                    used,
                    Belt_Array.concat(unused, [metricGroup.name])
                  ];
          }
        }));
  var emptyMetricGroupOptions = match$4[1];
  var metricGroupOptions = match$4[0];
  React.useEffect((function () {
          if (typeof metricGroupRemote === "number") {
            if (metricGroupRemote === /* NotAsked */0) {
              Curry._1(setRemote, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (metricGroupRemote.tag) {
            var str = metricGroupRemote[0];
            Curry._1(setRemote, (function (param) {
                    return /* Failure */Block.__(1, [str]);
                  }));
          } else {
            Curry._1(setRemote, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                metricGroupOptions,
                                emptyMetricGroupOptions
                              ]]);
                  }));
            var shouldBeDefault = selectDefault(metricGroupOptions, emptyMetricGroupOptions);
            var isDefault = NormByDefault$BsConsole.get(projectName, config);
            if (project !== undefined && shouldBeDefault !== undefined && Caml_obj.caml_notequal(shouldBeDefault, isDefault)) {
              NormByDefault$BsConsole.set(token, project, shouldBeDefault);
            }
            
          }
          
        }), [metricGroupRemote]);
  return match[0];
}

exports.makeMetricsQuery = makeMetricsQuery;
exports.selectDefault = selectDefault;
exports.getOptions = getOptions;
/* react Not a pure module */
