// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function preventDefault(submit, $$event) {
  if (!$$event.defaultPrevented) {
    $$event.preventDefault();
  }
  return Curry._1(submit, undefined);
}

function toValueOnChange($$event) {
  return $$event.target.value;
}

function toValueOnBlur($$event) {
  return $$event.target.value;
}

function toCheckedOnChange($$event) {
  return $$event.target.checked;
}

function toCheckedOnBlur($$event) {
  return $$event.target.checked;
}

var Dom = {
  preventDefault: preventDefault,
  toValueOnChange: toValueOnChange,
  toValueOnBlur: toValueOnBlur,
  toCheckedOnChange: toCheckedOnChange,
  toCheckedOnBlur: toCheckedOnBlur
};

exports.Dom = Dom;
/* No side effect */
