// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Configuration$BsConsole = require("./configuration.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");

function formatToString(param) {
  if (param) {
    return "minidump";
  } else {
    return "json";
  }
}

function getSubmissionEndpoint(token, config, format) {
  var origin = location.origin;
  var universe = config.universe;
  var maybePort = Configuration$BsConsole.getPort(undefined, config);
  if (WindowVariables$BsConsole.maybeReceiveLayer !== undefined) {
    return WindowVariables$BsConsole.maybeReceiveLayer.replace("{universe}", universe.name).replace("{token}", token.id).replace("{format}", format ? "minidump" : "json");
  } else if (maybePort !== undefined) {
    return origin + (":" + (String(maybePort) + ("/post?format=" + ((
                    format ? "minidump" : "json"
                  ) + ("&token=" + token.id)))));
  } else {
    return origin + ":{port}?token={token}&format={format}";
  }
}

function getQueryEndpoint(config) {
  var origin = location.origin;
  var maybePort = Configuration$BsConsole.getPort("api/query", config);
  if (maybePort !== undefined) {
    return origin + (":" + (String(maybePort) + "/api/query"));
  } else {
    return origin + "/api/query";
  }
}

function getServiceEndpoint(config, serviceName, defaultEndpoint) {
  var services = config.services;
  if (services === undefined) {
    return defaultEndpoint;
  }
  var serviceConfig = Belt_List.getBy(services, (function (service) {
          return service.name === serviceName;
        }));
  if (serviceConfig === undefined) {
    return defaultEndpoint;
  }
  var endpoint = serviceConfig.endpoint;
  if (endpoint !== undefined) {
    if (endpoint.startsWith("http")) {
      return endpoint;
    } else {
      return location.origin + endpoint;
    }
  } else {
    return defaultEndpoint;
  }
}

exports.formatToString = formatToString;
exports.getSubmissionEndpoint = getSubmissionEndpoint;
exports.getQueryEndpoint = getQueryEndpoint;
exports.getServiceEndpoint = getServiceEndpoint;
/* Configuration-BsConsole Not a pure module */
