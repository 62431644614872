// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function unwrap(t$prime) {
  return /* tuple */[
          t$prime[0],
          t$prime[1]
        ];
}

function getJson(t$prime) {
  return unwrap(t$prime)[0];
}

function getHeaders(t$prime) {
  return unwrap(t$prime)[1];
}

exports.unwrap = unwrap;
exports.getJson = getJson;
exports.getHeaders = getHeaders;
/* No side effect */
