// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Interpolate_histogram$BsConsole = require("./Interpolate_histogram.js");

var MergeFailed = Caml_exceptions.create("Interpolate-BsConsole.Histogram.MergeFailed");

function make(_kind, buckets, low, high) {
  return Interpolate_histogram$BsConsole.make(buckets, low, high);
}

function merge(t, crdb) {
  try {
    return Interpolate_histogram$BsConsole.serializeWithEmpty(Interpolate_histogram$BsConsole.merge(t, crdb));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Interpolate_histogram$BsConsole.ValueOutOfBounds) {
      throw MergeFailed;
    }
    if (exn[0] === Interpolate_histogram$BsConsole.CrdbBucketCountOverflow) {
      throw MergeFailed;
    }
    throw exn;
  }
}

function first(param) {
  return param[0];
}

function second(param) {
  return param[1];
}

function find(arr) {
  return /* tuple */[
          first(Belt_Array.getExn(arr, 0)),
          second(Belt_Array.getExn(arr, arr.length - 1 | 0))
        ];
}

var extent = find;

var extentDate = find;

var Histogram = {
  MergeFailed: MergeFailed,
  make: make,
  merge: merge,
  extent: extent,
  extentDate: extentDate
};

exports.Histogram = Histogram;
/* No side effect */
