// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Notifications = require("@material-ui/icons/Notifications");

function WfComponents$HeaderActionButton(Props) {
  var title = Props.title;
  var onClick = Props.onClick;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Button.default, {
              variant: "contained",
              color: "primary",
              className: className,
              onClick: (function (param) {
                  return Curry._1(onClick, undefined);
                }),
              children: React.createElement("span", {
                    className: Css.style(/* :: */[
                          Css.marginRight(Css.px(5)),
                          /* [] */0
                        ])
                  }, I18N$BsConsole.show(undefined, title))
            });
}

var component = ReasonReact.statelessComponent("WfActionButton");

function make(title, onClick, className, children) {
  var tmp = {
    title: title,
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(WfComponents$HeaderActionButton, tmp, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var HeaderActionButton = {
  make: WfComponents$HeaderActionButton,
  Jsx2: Jsx2
};

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(30)),
      /* [] */0
    ]);

var Styles = {
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription,
  bottomMargin: bottomMargin
};

function WfComponents$EmptyTableMessage(Props) {
  var title = Props.title;
  var description = Props.description;
  var buttonLabel = Props.buttonLabel;
  var onClick = Props.onClick;
  Props.className;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.justifyContent(/* center */98248149),
                        /* :: */[
                          Css.marginTop(Css.px(100)),
                          /* :: */[
                            Css.marginBottom(Css.px(100)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(Notifications.default, {
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* center */98248149),
                        /* :: */[
                          pageMarginBottom,
                          /* [] */0
                        ]
                      ]),
                  fontSize: "large"
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* center */98248149),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, title)
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.alignSelf(/* center */98248149),
                              /* [] */0
                            ]),
                        /* :: */[
                          featureDescription,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, description)
                }), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: React.createElement(Button.default, {
                        variant: "contained",
                        size: "medium",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(onClick, undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, buttonLabel)
                      })
                }));
}

var component$1 = ReasonReact.statelessComponent("WfActionButton");

function make$1(title, description, buttonLabel, onClick, className, children) {
  var tmp = {
    title: title,
    description: description,
    buttonLabel: buttonLabel,
    onClick: onClick
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(WfComponents$EmptyTableMessage, tmp, children);
}

var Jsx2$1 = {
  component: component$1,
  make: make$1
};

var EmptyTableMessage = {
  Styles: Styles,
  make: WfComponents$EmptyTableMessage,
  Jsx2: Jsx2$1
};

exports.HeaderActionButton = HeaderActionButton;
exports.EmptyTableMessage = EmptyTableMessage;
/* component Not a pure module */
