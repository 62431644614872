// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var NavBreadcrumb$BsConsole = require("./NavBreadcrumb.js");
var Toolbar = require("@material-ui/core/Toolbar");

var toolbar = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
            /* :: */[
              Css.position(/* sticky */188263721),
              /* :: */[
                Css.top(Css.px(64)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* :: */[
                    Css.zIndex(1001),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var viewOnlyToolbar = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.npOrangeLightBackground)),
      /* :: */[
        Css.minHeight(Css.px(40)),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.npOrangeBorderColor)),
          /* [] */0
        ]
      ]
    ]);

var p = Css.style(/* :: */[
      Css.margin(Css.px(0)),
      /* :: */[
        Css.paddingLeft(Css.px(16)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  toolbar: toolbar,
  viewOnlyToolbar: viewOnlyToolbar,
  p: p
};

function getFriendlyNameForRoute(route) {
  if (typeof route === "number") {
    return ;
  }
  switch (route.tag | 0) {
    case /* ProjectSettings */22 :
        return "Overview";
    case /* ProjectSettingsPlayground */23 :
        return "Playground";
    case /* ProjectSettingsMetricsExporter */24 :
        return "Metrics exporter";
    case /* ProjectSettingsScrubbers */25 :
        return "Privacy control";
    case /* ProjectSettingsServerSideActions */26 :
        return "Submission actions";
    case /* ProjectSettingsAlerts */27 :
        return "Alerts";
    case /* ProjectSettingsIntegratedDocs */28 :
        return "Integration guides";
    case /* ProjectSettingsSubmission */29 :
        return "Error submission";
    case /* ProjectSettingsStorage */30 :
        return "Storage";
    case /* ProjectSettingsSubmissionTokens */31 :
        return "Submission tokens";
    case /* ProjectSettingStorageSampling */32 :
        return "Storage sampling";
    case /* ProjectSettingsSubmissionDataSources */33 :
        return "Submission data sources";
    case /* ProjectSettingsAttributes */34 :
        return "Attributes";
    case /* ProjectSettingsIntegrations */35 :
        return "Workflow integrations";
    case /* ProjectSettingsSymbolsUploadedArchives */36 :
        return "Uploaded symbol archives";
    case /* ProjectSettingsSymbolsUpload */37 :
        return "Symbols upload";
    case /* ProjectSettingsSymbolServers */38 :
        return "Symbol servers";
    case /* ProjectSettingsSymbols */39 :
        return "Symbols";
    case /* ProjectSettingsSymbolAccessTokens */40 :
        return "Symbol access tokens";
    case /* ProjectSettingsMissingSymbols */41 :
        return "Missing symbols";
    case /* ProjectSettingsSymbolSearch */42 :
        return "Symbol search";
    case /* ProjectSettingsScheduledReports */43 :
        return "Scheduled reports";
    case /* ProjectSettingsAccessControl */44 :
        return "Access control";
    case /* ProjectSettingsReprocessing */45 :
        return "Object reprocessing";
    case /* ProjectSettingsRetentionPolicy */46 :
        return "Storage retention";
    case /* ProjectSettingsSourceCode */47 :
        return "Source code";
    case /* ProjectSettingsApiTokens */48 :
        return "Api tokens";
    case /* ProjectSettingsDedupeConfig */49 :
        return "Deduplication";
    case /* ProjectSettingsStabilityMonitoring */50 :
        return "Stability monitoring";
    default:
      return ;
  }
}

function getOverviewTuple(projectName) {
  return /* tuple */[
          "Project settings",
          /* ProjectSettings */Block.__(22, [projectName])
        ];
}

function ProjectSettingsNavBreadcrumbToolbar(Props) {
  var config = Props.config;
  var route = Props.route;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectSettingsTuple_001 = /* ProjectSettings */Block.__(22, [projectName]);
  var projectSettingsTuple = /* tuple */[
    "Project settings",
    projectSettingsTuple_001
  ];
  if (IFrame$BsConsole.isIFrame) {
    return null;
  } else {
    return React.createElement(React.Fragment, {
                children: null
              }, React.createElement(Toolbar.default, {
                    className: toolbar,
                    children: React.createElement(NavBreadcrumb$BsConsole.make, {
                          current: Belt_Option.getWithDefault(getFriendlyNameForRoute(route), "Overview"),
                          trail: [
                            /* tuple */[
                              projectName,
                              /* Project */Block.__(7, [projectName])
                            ],
                            projectSettingsTuple
                          ],
                          handleChangeUrl: handleChangeUrl
                        })
                  }), TeamsUtils$BsConsole.isGuest(config) ? React.createElement(Toolbar.default, {
                      className: viewOnlyToolbar,
                      children: React.createElement("p", {
                            className: p
                          }, I18N$BsConsole.show(undefined, "Access to settings is restricted. Please contact your administrator to modify any of the settings."))
                    }) : null);
  }
}

function make(config, route, projectName, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ProjectSettingsNavBreadcrumbToolbar, {
              config: config,
              route: route,
              projectName: projectName,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = ProjectSettingsNavBreadcrumbToolbar;

exports.Style = Style;
exports.getFriendlyNameForRoute = getFriendlyNameForRoute;
exports.getOverviewTuple = getOverviewTuple;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* toolbar Not a pure module */
