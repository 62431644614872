// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Flex2$BsConsole = require("./Flex2.js");

var include = Flex2$BsConsole.Make({
      flexDirection: /* column */-963948842
    });

var make = include.make;

exports.make = make;
/* include Not a pure module */
