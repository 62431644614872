// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var Drilldown$BsConsole = require("../Drilldown.js");

var tagButton = Css.style(/* :: */[
      Css.position(/* unset */653193961),
      /* :: */[
        Css.display(/* inlineBlock */-147785676),
        /* :: */[
          Css.marginRight(Css.px(4)),
          /* :: */[
            Css.selector(":first-of-type", /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* :: */[
                    Css.whiteSpace(/* nowrap */867913355),
                    /* :: */[
                      Css.maxWidth(Css.pct(100)),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

function tag(tag$1) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.fontFamily("Inconsolata"),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.getTagBackgroundColor(tag$1), 0.9))),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey1)),
                        /* :: */[
                          Css.fontSize(Css.px(13)),
                          /* :: */[
                            Css.padding2(Css.px(3), Css.px(4)),
                            /* :: */[
                              Css.borderRadius(Css.px(2)),
                              /* :: */[
                                Css.hover(/* :: */[
                                      Css.filter(/* :: */[
                                            /* `brightness */[
                                              -445542959,
                                              90
                                            ],
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* :: */[
                                    Css.display(/* inlineBlock */-147785676),
                                    /* :: */[
                                      Css.verticalAlign(/* middle */-866200747),
                                      /* :: */[
                                        Css.selector(":first-of-type", /* :: */[
                                              Css.maxWidth(Css.pct(100)),
                                              /* :: */[
                                                Css.whiteSpace(/* nowrap */867913355),
                                                /* :: */[
                                                  Css.overflow(/* hidden */-862584982),
                                                  /* :: */[
                                                    Css.textOverflow(/* ellipsis */166888785),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                "tag__item",
                /* [] */0
              ]
            ]);
}

var Styles = {
  tagButton: tagButton,
  tag: tag
};

function createFilterOptions(tag) {
  return [
          /* tuple */[
            Issues$BsConsole.Tags.columnName,
            /* Contains */Block.__(2, [/* `String */[
                  -976970511,
                  tag
                ]])
          ],
          /* tuple */[
            Issues$BsConsole.Tags.columnName,
            /* NotContains */Block.__(3, [/* `String */[
                  -976970511,
                  tag
                ]])
          ]
        ];
}

function IssueTags(Props) {
  var tags = Props.tags;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  return Belt_Array.mapWithIndex(tags, (function (idx, tag$1) {
                var tmp = {
                  textValue: tag$1,
                  filterOptions: createFilterOptions(tag$1),
                  className: tagButton,
                  children: React.createElement("div", {
                        key: tag$1 + ("__" + String(idx)),
                        className: tag(tag$1)
                      }, I18N$BsConsole.showSkip(tag$1)),
                  key: tag$1 + ("__" + String(idx))
                };
                if (handleChangeUrl !== undefined) {
                  tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
                }
                if (handleAddFilters !== undefined) {
                  tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
                }
                return React.createElement(Drilldown$BsConsole.make, tmp);
              }));
}

var make = IssueTags;

exports.Styles = Styles;
exports.createFilterOptions = createFilterOptions;
exports.make = make;
/* tagButton Not a pure module */
