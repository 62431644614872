// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var JiraConnection$BsConsole = require("../JiraConnection.js");
var SlackIntegration$BsConsole = require("./slack/SlackIntegration.js");

function getIntegrationComponent(pluginId, qs, config, token, handleChangeUrl, projectName) {
  switch (pluginId) {
    case "jira" :
    case "jira-server" :
        break;
    case "slack" :
        var match = qs.connectionId;
        var match$1 = qs.integrationId;
        var match$2 = qs.connectionCreated;
        var match$3 = qs.referrer;
        var formState = match !== undefined ? (
            match$2 !== undefined && match$2 === true ? /* Create */Block.__(0, [
                  match,
                  true
                ]) : /* Create */Block.__(0, [
                  match,
                  false
                ])
          ) : (
            match$1 !== undefined ? (
                match$3 !== undefined ? /* Edit */Block.__(1, [
                      match$1,
                      match$3
                    ]) : /* Edit */Block.__(1, [
                      match$1,
                      /* IntegrationTable */1
                    ])
              ) : undefined
          );
        if (formState !== undefined) {
          return ReasonReact.element(undefined, undefined, SlackIntegration$BsConsole.Jsx2.make(formState, token, handleChangeUrl, projectName, config, []));
        }
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "Wf2IntegrationRouter.re",
                87,
                14
              ]
            ];
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "Wf2IntegrationRouter.re",
              89,
              9
            ]
          ];
  }
  var match$4 = qs.error;
  var match$5 = qs.connectionId;
  if (match$4 !== undefined) {
    if (match$5 !== undefined) {
      Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
              projectName,
              /* JiraServer */Block.__(0, [/* Edit */Block.__(0, [
                      match$5,
                      qs.error
                    ])]),
              qs
            ]));
    } else {
      Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                error: qs.error
              }]));
    }
  }
  var match$6 = qs.connectionId;
  var match$7 = qs.integrationId;
  var match$8 = qs.connectionCreated;
  var match$9 = qs.referrer;
  var formState$1 = match$6 !== undefined ? (
      match$8 !== undefined && match$8 === true ? /* Create */Block.__(0, [
            match$6,
            true
          ]) : /* Create */Block.__(0, [
            match$6,
            false
          ])
    ) : (
      match$7 !== undefined ? (
          match$9 !== undefined ? /* Edit */Block.__(1, [
                match$7,
                match$9
              ]) : /* Edit */Block.__(1, [
                match$7,
                /* IntegrationTable */1
              ])
        ) : undefined
    );
  if (formState$1 !== undefined) {
    var tmp;
    switch (pluginId) {
      case "jira" :
          tmp = /* JiraCloud */0;
          break;
      case "jira-server" :
          tmp = /* JiraServer */1;
          break;
      default:
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "Wf2IntegrationRouter.re",
                60,
                17
              ]
            ];
    }
    return ReasonReact.element(undefined, undefined, JiraConnection$BsConsole.Jsx2.make(formState$1, config.universe.name, token, handleChangeUrl, projectName, config, tmp, []));
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "Wf2IntegrationRouter.re",
          64,
          14
        ]
      ];
}

exports.getIntegrationComponent = getIntegrationComponent;
/* ReasonReact Not a pure module */
