// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Colors$BsConsole = require("../../../Colors.js");
var BsVx_Axis$BsConsole = require("./BsVx_Axis.js");

var component = ReasonReact.statelessComponent("Axis__Left");

function make(axisClassName, axisLineClassName, hideAxisLine, hideTicks, hideZero, label, labelClassName, labelOffset, labelProps, left, numTicks, rangePadding, scale, stroke, strokeWidth, strokeDasharray, tickClassName, tickFormat, tickLabelProps, tickLength, tickStroke, tickTransform, top, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, BsVx_Axis$BsConsole.make(Belt_Option.getWithDefault(axisClassName, ""), Belt_Option.getWithDefault(axisLineClassName, ""), Belt_Option.getWithDefault(hideAxisLine, false), Belt_Option.getWithDefault(hideTicks, false), Belt_Option.getWithDefault(hideZero, false), Belt_Option.getWithDefault(label, ""), Belt_Option.getWithDefault(labelClassName, ""), Belt_Option.getWithDefault(labelOffset, 36), Caml_option.some(Belt_Option.getWithDefault(labelProps, { })), Belt_Option.getWithDefault(left, 0), Belt_Option.getWithDefault(numTicks, 10), /* Left */847852583, Belt_Option.getWithDefault(rangePadding, 0), scale, Belt_Option.getWithDefault(stroke, Colors$BsConsole.octothorpe(Colors$BsConsole.black)), Belt_Option.getWithDefault(strokeWidth, 0.5), Belt_Option.getWithDefault(strokeDasharray, ""), Belt_Option.getWithDefault(tickClassName, ""), Belt_Option.getWithDefault(tickFormat, (function (value, param) {
                                    return value;
                                  })), Belt_Option.getWithDefault(tickLabelProps, (function (_tickValue, _index) {
                                    return {
                                            dx: "-0.25em",
                                            dy: "0.25em"
                                          };
                                  })), Belt_Option.getWithDefault(tickLength, 8), Belt_Option.getWithDefault(tickStroke, Colors$BsConsole.octothorpe(Colors$BsConsole.black)), Belt_Option.getWithDefault(tickTransform, ""), undefined, Belt_Option.getWithDefault(top, 0), []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
