// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var MakeText$BsConsole = require("./MakeText.js");

var include = MakeText$BsConsole.Make({
      fontSize: 12,
      fontWeight: /* normal */812216871
    });

var className = include.className;

var component = include.component;

var make = include.make;

var Jsx3 = include.Jsx3;

exports.className = className;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* include Not a pure module */
