// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

var endpointPath = "/project";

function decode(json) {
  return {
          uid: Json_decode.field("uid", Json_decode.$$int, json),
          username: Json_decode.field("username", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

var User = {
  decode: decode
};

function getTaskEndpoint(project) {
  return /* Api */Block.__(0, [endpointPath + ("/list_users?project=" + project)]);
}

function decode$1(json) {
  return Json_decode.field("users", (function (param) {
                return Json_decode.array(decode, param);
              }), json);
}

var ListUsers = {
  getTaskEndpoint: getTaskEndpoint,
  decode: decode$1
};

exports.endpointPath = endpointPath;
exports.User = User;
exports.ListUsers = ListUsers;
/* No side effect */
