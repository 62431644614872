// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

var rowStyle = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* tableRow */-843129172),
            /* :: */[
              Css.verticalAlign(/* middle */-866200747),
              /* :: */[
                Css.unsafe("backgroundColor", "inherit"),
                /* [] */0
              ]
            ]
          ]),
      /* :: */[
        "BtTableRow",
        /* [] */0
      ]
    ]);

function BtTableRow$Expandable(Props) {
  var classNameOpt = Props.className;
  var expandedContent = Props.expandedContent;
  var expandedDisabledOpt = Props.expandedDisabled;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var colSpanOpt = Props.colSpan;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var expandedDisabled = expandedDisabledOpt !== undefined ? expandedDisabledOpt : false;
  var colSpan = colSpanOpt !== undefined ? colSpanOpt : 5;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var tmp = {
    className: Css.merge(/* :: */[
          rowStyle,
          /* :: */[
            Css.style(/* :: */[
                  Css.selector(" > td.BtTableDataCell", /* :: */[
                        Css.unsafe("borderBottom", "unset"),
                        /* [] */0
                      ]),
                  /* [] */0
                ]),
            /* :: */[
              "BtTableExpandableRow",
              /* :: */[
                className,
                /* [] */0
              ]
            ]
          ]
        ])
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("tr", tmp, React.createElement(BtTableCell$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.important(Css.padding(/* zero */-789508312)),
                            /* :: */[
                              Css.width(Css.px(48)),
                              /* [] */0
                            ]
                          ]),
                      children: React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: expanded ? "Collapse" : "Expand",
                            disabled: expandedDisabled,
                            size: "small",
                            onClick: (function (param) {
                                return Curry._1(setExpanded, (function (expandedState) {
                                              return !expandedState;
                                            }));
                              }),
                            children: expanded ? React.createElement(KeyboardArrowUp.default, { }) : React.createElement(KeyboardArrowDown.default, { })
                          })
                    }), children), React.createElement("tr", {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.display(/* tableRow */-843129172),
                              /* :: */[
                                Css.verticalAlign(/* middle */-866200747),
                                /* :: */[
                                  Css.selector(":last-of-type > td.BtTableDataCell", /* :: */[
                                        Css.unsafe("borderBottom", "unset"),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          "BtTableExpandedRow",
                          /* [] */0
                        ]
                      ])
                }, React.createElement(BtTableCell$BsConsole.make, {
                      colSpan: colSpan,
                      className: Css.style(/* :: */[
                            Css.important(Css.paddingTop(/* zero */-789508312)),
                            /* :: */[
                              Css.important(Css.paddingBottom(/* zero */-789508312)),
                              /* :: */[
                                Css.important(Css.height(/* zero */-789508312)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: expanded ? expandedContent : null
                    })));
}

var Expandable = {
  make: BtTableRow$Expandable
};

function BtTableRow$ExpandableControlled(Props) {
  var classNameOpt = Props.className;
  var expandedContent = Props.expandedContent;
  var expandedDisabledOpt = Props.expandedDisabled;
  var hideExpansionOpt = Props.hideExpansion;
  var setExpanded = Props.setExpanded;
  var expandedOpt = Props.expanded;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var colSpan = Props.colSpan;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var expandedDisabled = expandedDisabledOpt !== undefined ? expandedDisabledOpt : false;
  var hideExpansion = hideExpansionOpt !== undefined ? hideExpansionOpt : false;
  var expanded = expandedOpt !== undefined ? expandedOpt : false;
  var tmp = {
    className: Css.merge(/* :: */[
          rowStyle,
          /* :: */[
            Css.style(/* :: */[
                  Css.selector(" > td.BtTableDataCell", /* :: */[
                        Css.unsafe("borderBottom", "unset"),
                        /* [] */0
                      ]),
                  /* [] */0
                ]),
            /* :: */[
              "BtTableExpandableRow",
              /* :: */[
                className,
                /* [] */0
              ]
            ]
          ]
        ])
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("tr", tmp, hideExpansion ? React.createElement(BtTableCell$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.important(Css.padding(/* zero */-789508312)),
                              /* :: */[
                                Css.width(Css.px(48)),
                                /* [] */0
                              ]
                            ])
                      }) : React.createElement(BtTableCell$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.important(Css.padding(/* zero */-789508312)),
                              /* :: */[
                                Css.width(Css.px(48)),
                                /* [] */0
                              ]
                            ]),
                        children: React.createElement(Mui$BsConsole.IconButton.make, {
                              ariaLabel: expanded ? "Collapse" : "Expand",
                              disabled: expandedDisabled,
                              size: "small",
                              onClick: (function (param) {
                                  return Curry._1(setExpanded, expanded);
                                }),
                              children: expanded ? React.createElement(KeyboardArrowUp.default, { }) : React.createElement(KeyboardArrowDown.default, { })
                            })
                      }), children), React.createElement("tr", {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.display(/* tableRow */-843129172),
                              /* :: */[
                                Css.verticalAlign(/* middle */-866200747),
                                /* :: */[
                                  Css.selector(":last-of-type > td.BtTableDataCell", /* :: */[
                                        Css.unsafe("borderBottom", "unset"),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          "BtTableExpandedRow",
                          /* [] */0
                        ]
                      ])
                }, React.createElement(BtTableCell$BsConsole.make, {
                      colSpan: colSpan,
                      className: Css.style(/* :: */[
                            Css.important(Css.paddingTop(/* zero */-789508312)),
                            /* :: */[
                              Css.important(Css.paddingBottom(/* zero */-789508312)),
                              /* :: */[
                                Css.important(Css.height(/* zero */-789508312)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: expanded ? expandedContent : null
                    })));
}

var ExpandableControlled = {
  make: BtTableRow$ExpandableControlled
};

function BtTableRow(Props) {
  var classNameOpt = Props.className;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Css.merge(/* :: */[
          rowStyle,
          /* :: */[
            className,
            /* [] */0
          ]
        ])
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  return React.createElement("tr", tmp, children);
}

var make = BtTableRow;

exports.rowStyle = rowStyle;
exports.Expandable = Expandable;
exports.ExpandableControlled = ExpandableControlled;
exports.make = make;
/* rowStyle Not a pure module */
