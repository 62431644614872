// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var AlertsRouter$BsConsole = require("../project-settings/alerts/AlertsRouter.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var Button = require("@material-ui/core/Button");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");

function AutomatedActionsMovedBanner(Props) {
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Css.hex(Colors$BsConsole.deoctothorpe(palette.primary.main));
  var callToActionContainer = Css.style(/* :: */[
        Css.padding(Css.em(1)),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, primaryMain),
          /* :: */[
            Css.color(primaryMain),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* :: */[
                    Css.marginBottom(Css.px(30)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var callToActionButtonPrimary = Css.style(/* :: */[
        Css.backgroundColor(primaryMain),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.white)),
          /* [] */0
        ]
      ]);
  return React.createElement(Paper.default, {
              className: callToActionContainer,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  children: null
                }, React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.em(1)),
                            /* [] */0
                          ])
                    }, React.createElement(Info.default, { })), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Subtitle1 */6,
                      className: Css.style(/* :: */[
                            Css.lineHeight(Css.em(1.5)),
                            /* :: */[
                              Css.color(Css.rgba(0, 0, 0, 0.87)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Automated Actions have now moved to the Alerts Page.")
                    })), React.createElement("div", undefined, React.createElement(Button.default, {
                      variant: "raised",
                      className: callToActionButtonPrimary,
                      onClick: (function (param) {
                          return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                        projectName,
                                        {
                                          tab: AlertsRouter$BsConsole.typeToString(/* IssueBasedAlerts */1),
                                          id: undefined
                                        }
                                      ]));
                        }),
                      children: I18N$BsConsole.show(undefined, "Review Issue Based Alerts")
                    })));
}

function make(projectName, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(AutomatedActionsMovedBanner, {
              projectName: projectName,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = AutomatedActionsMovedBanner;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
