// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Route$BsConsole = require("../route.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var ExporterUtils$BsConsole = require("./ExporterUtils.js");
var Button = require("@material-ui/core/Button");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");
var InputLabel = require("@material-ui/core/InputLabel");
var OpenInNew = require("@material-ui/icons/OpenInNew");

function ExporterEditSetModal(Props) {
  var _open = Props.open;
  var onClose = Props.onClose;
  var handleSave = Props.handleSave;
  var existingSet = Props.existingSet;
  var existingLinkedExports = Props.existingLinkedExports;
  var stores = Props.stores;
  var $$exports = Props.exports;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          return "";
        }));
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setSelectedStores = match$1[1];
  var selectedStores = match$1[0];
  var match$2 = React.useState((function () {
          return [];
        }));
  var setStoresToAdd = match$2[1];
  var storesToAdd = match$2[0];
  var match$3 = React.useState((function () {
          return [];
        }));
  var setStoresToRemove = match$3[1];
  var storesToRemove = match$3[0];
  var close = function (param) {
    Curry._1(onClose, undefined);
    Curry._1(setName, (function (param) {
            return "";
          }));
    Curry._1(setSelectedStores, (function (param) {
            return [];
          }));
    Curry._1(setStoresToAdd, (function (param) {
            return [];
          }));
    return Curry._1(setStoresToRemove, (function (param) {
                  return [];
                }));
  };
  React.useEffect((function () {
          if (existingSet !== undefined) {
            Curry._1(setName, (function (param) {
                    return existingSet.name;
                  }));
            var __x = Belt_List.keep($$exports, (function ($$export) {
                    return $$export.setId === existingSet.id;
                  }));
            var allLinkedStores = Belt_List.reduce(__x, /* [] */0, (function (acc, $$export) {
                    var linkedStore = Belt_List.getBy(stores, (function (store) {
                            return store.id === $$export.storeId;
                          }));
                    if (linkedStore !== undefined) {
                      return Belt_List.concat(acc, /* :: */[
                                  linkedStore,
                                  /* [] */0
                                ]);
                    } else {
                      return acc;
                    }
                  }));
            Curry._1(setSelectedStores, (function (param) {
                    return Belt_List.toArray(allLinkedStores);
                  }));
          }
          
        }), [existingSet]);
  var match$4 = Belt_List.length(existingLinkedExports);
  var nameUnchanged = existingSet !== undefined ? existingSet.name === name : false;
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: close,
              modalWidth: 300,
              title: I18N$BsConsole.get(undefined, "Edit metrics set"),
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* spaceBetween */516682146,
                    children: null
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(8)),
                                  /* [] */0
                                ])
                          }, React.createElement(Button.default, {
                                variant: "text",
                                size: "small",
                                color: "primary",
                                onClick: (function (param) {
                                    return Curry._1(handleChangeUrl, Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined));
                                  }),
                                children: null
                              }, React.createElement(OpenInNew.default, {
                                    className: Css.style(/* :: */[
                                          Css.marginRight(Css.px(8)),
                                          /* [] */0
                                        ])
                                  }), I18N$BsConsole.show(undefined, "Go to Explore to link metrics to this set"))), React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(0.5)),
                                    /* [] */0
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Name")
                                })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "",
                            value: name,
                            onChange: (function (e) {
                                var value = BtSettings$BsConsole.getInputValue(e);
                                return Curry._1(setName, (function (param) {
                                              return value;
                                            }));
                              }),
                            disabled: false,
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(300)),
                                  /* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginLeft(/* auto */-1065951377),
                                      /* :: */[
                                        Css.marginRight(/* auto */-1065951377),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }), React.createElement(BtTypeaheadInput$BsConsole.Multi.make, {
                            selectedItems: selectedStores,
                            items: Belt_List.toArray(stores),
                            toString: ExporterUtils$BsConsole.getStoreName,
                            toId: (function (store) {
                                return store.id;
                              }),
                            placeholder: I18N$BsConsole.get(undefined, "Find a store"),
                            noItemsMessage: I18N$BsConsole.get(undefined, "No existing stores. Close this modal to create a new store on the settings page."),
                            inputLabel: match$4 !== 0 ? I18N$BsConsole.get(undefined, "Update stores linked to this set") : I18N$BsConsole.get(undefined, "Link this metrics set to a store to start exporting"),
                            itemFilter: (function (param) {
                                return true;
                              }),
                            pixelWidth: 300,
                            handleItemAfterChange: (function (param) {
                                var item = param[0];
                                if (param[1]) {
                                  var toRemoveLessSelectedItem = Belt_Array.keep(storesToRemove, (function (store) {
                                          return store.id !== item.id;
                                        }));
                                  var length = toRemoveLessSelectedItem.length;
                                  var originalLength = storesToRemove.length;
                                  if (length !== originalLength) {
                                    Curry._1(setStoresToRemove, (function (param) {
                                            return toRemoveLessSelectedItem;
                                          }));
                                  } else {
                                    Curry._1(setStoresToAdd, (function (prev) {
                                            return Belt_Array.concat(prev, [item]);
                                          }));
                                  }
                                  var updated = Belt_Array.concat(selectedStores, [item]);
                                  return Curry._1(setSelectedStores, (function (param) {
                                                return updated;
                                              }));
                                }
                                var toAddLessDeselectedItem = Belt_Array.keep(storesToAdd, (function (store) {
                                        return store.id !== item.id;
                                      }));
                                var length$1 = toAddLessDeselectedItem.length;
                                var originalLength$1 = storesToAdd.length;
                                if (length$1 !== originalLength$1) {
                                  Curry._1(setStoresToAdd, (function (param) {
                                          return toAddLessDeselectedItem;
                                        }));
                                } else {
                                  Curry._1(setStoresToRemove, (function (prev) {
                                          return Belt_Array.concat(prev, [item]);
                                        }));
                                }
                                var updated$1 = Belt_Array.keep(selectedStores, (function (u) {
                                        return ExporterUtils$BsConsole.getStoreName(u) !== ExporterUtils$BsConsole.getStoreName(item);
                                      }));
                                return Curry._1(setSelectedStores, (function (param) {
                                              return updated$1;
                                            }));
                              }),
                            hideButtons: true
                          })), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: nameUnchanged && storesToAdd.length === 0 && storesToRemove.length === 0,
                            onClick: (function (param) {
                                Curry._3(handleSave, name, storesToAdd, storesToRemove);
                                return close(undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Save")
                          }), React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: close,
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var make = ExporterEditSetModal;

exports.make = make;
/* Css Not a pure module */
