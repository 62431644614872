// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");

function BugChip(Props) {
  var bgColor = Props.backgroundColor;
  var label = Props.label;
  var className = Props.className;
  var overrideStyle = Belt_Option.getWithDefault(className, "");
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.padding2(Css.px(3), Css.px(6)),
                          /* :: */[
                            Css.backgroundColor(Css.hex(bgColor)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey2)),
                              /* :: */[
                                BugStyles$BsConsole.monospaceFont,
                                /* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.borderRadius(Css.px(4)),
                                    /* :: */[
                                      Css.overflow(Css.hidden),
                                      /* :: */[
                                        Css.textOverflow(Css.ellipsis),
                                        /* :: */[
                                          Css.whiteSpace(Css.nowrap),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      overrideStyle,
                      /* [] */0
                    ]
                  ])
            }, I18N$BsConsole.showSkip(label));
}

var make = BugChip;

exports.make = make;
/* Css Not a pure module */
