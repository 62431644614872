// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Text$BsConsole = require("./Text.js");
var Colors$BsConsole = require("./Colors.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var Current$BsConsole = require("./saved-queries/Current.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ViewNav$BsConsole = require("./ViewNav.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Save = require("@material-ui/icons/Save");
var Button = require("@material-ui/core/Button");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var ExploreListData$BsConsole = require("./ExploreListData.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var ExploreModeSelect$BsConsole = require("./ExploreModeSelect.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("./ErrorsOverTimeBinChart.js");
var DistributionValueSelect$BsConsole = require("./DistributionValueSelect.js");
var SavedQueryBookmarkSelect$BsConsole = require("./saved-queries/SavedQueryBookmarkSelect.js");

function makeQueryForSessionStorage(aperture, select, sort) {
  return /* Select */Block.__(1, [
            aperture,
            select,
            Caml_option.some(sort),
            undefined
          ]);
}

function ProjectInstances(Props) {
  var sort = Props.sort;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var handleTask = Props.handleTask;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var select = Props.select;
  var handleAddFilters = Props.handleAddFilters;
  var config = Props.config;
  var route = Props.route;
  var setSort = function (sort$prime) {
    return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                  projectName,
                  {
                    aperture: aperture,
                    select: select,
                    sort: sort$prime
                  }
                ]));
  };
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = ExploreListData$BsConsole.makeQuery(apertureForQuery, select, sort);
  var match = ExploreListData$BsConsole.useTableData(token, projectName, query, select, sort);
  var tableDataRemote = match[0];
  var match$1 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match$1[0];
  var match$2 = DescribeTask$BsConsole.use(token, projectName, "metrics", undefined);
  var metricsDescribeRemote = match$2[0];
  var metricsAttributes = React.useMemo((function () {
          if (typeof metricsDescribeRemote === "number" || metricsDescribeRemote.tag) {
            return /* [] */0;
          } else {
            return metricsDescribeRemote[0];
          }
        }), [metricsDescribeRemote]);
  var onRefresh = function (_event) {
    if (Curry._2(Crdb$BsConsole.Aperture.eq, aperture, apertureForQuery)) {
      window.location.reload(true);
      return ;
    } else {
      return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                    projectName,
                    {
                      aperture: apertureForQuery,
                      select: select,
                      sort: sort
                    }
                  ]));
    }
  };
  BacktraceHooks$BsConsole.useSortRecover(sort, query, (function (param) {
          return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                        projectName,
                        {
                          aperture: aperture,
                          select: select,
                          sort: Crdb$BsConsole.Sort.defaultSelect
                        }
                      ]));
        }));
  React.useEffect((function () {
          BtTableSessionData$BsConsole.Query.set(projectName, makeQueryForSessionStorage(aperture, select, sort), /* ExploreList */2);
          
        }), /* tuple */[
        query,
        projectName
      ]);
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                  projectName,
                  {
                    aperture: aperture$prime,
                    select: select,
                    sort: sort
                  }
                ]));
  };
  var mode = SavedQueriesModel$BsConsole.modeOfStringExn("instance");
  var queryForSavedQueryContainer = ExploreListData$BsConsole.makeQuery(aperture, select, sort);
  var tmp;
  if (typeof describeRemote === "number" || describeRemote.tag) {
    tmp = null;
  } else {
    var attributes = describeRemote[0];
    tmp = React.createElement(React.Fragment, undefined, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "error.type",
                label: "Error type",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                className: Css.style(/* :: */[
                      Css.marginRight(Css.px(8)),
                      /* [] */0
                    ]),
                icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "uname.sysname",
                label: "Platform",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null);
  }
  var tmp$1;
  if (typeof tableDataRemote === "number") {
    tmp$1 = tableDataRemote === /* NotAsked */0 ? null : React.createElement(ViewLoading$BsConsole.Jsx3.make, {
            label: I18N$BsConsole.get(undefined, "Fetching list data")
          });
  } else if (tableDataRemote.tag) {
    tmp$1 = React.createElement(Col2$BsConsole.make, {
          alignItems: /* center */98248149,
          justifyContent: /* spaceAround */-485895757,
          className: Css.style(/* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.minHeight(Css.px(400)),
                  /* :: */[
                    Css.paddingBottom(Css.px(8)),
                    /* :: */[
                      Css.fontSize(Css.px(20)),
                      /* [] */0
                    ]
                  ]
                ]
              ]),
          children: I18N$BsConsole.dynamic(tableDataRemote[0])
        });
  } else {
    var match$3 = tableDataRemote[0];
    var meta = match$3.meta;
    var rows = match$3.rows;
    var match$4 = rows.length;
    if (match$4 !== 0) {
      var partial_arg = Belt_Option.getWithDefault(config.user.role, "") === "admin";
      var partial_arg$1 = config.universe.name;
      var arg = function (column) {
        var select$1 = Belt_List.keep(select, (function (col) {
                return col !== column;
              }));
        var sort$1 = Belt_List.toArray(select$1).includes(Curry._1(Crdb$BsConsole.Sort.getAttribute, sort)) ? sort : Crdb$BsConsole.Sort.defaultSelect;
        return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                      projectName,
                      {
                        aperture: aperture,
                        select: select$1,
                        sort: sort$1
                      }
                    ]));
      };
      var tmp$2 = {
        columns: match$3.columns,
        rows: rows,
        getRowId: ExploreListData$BsConsole.getRowId,
        renderDataCell: (function (param, param$1, param$2, param$3) {
            return ExploreListData$BsConsole.renderInstanceDataCell(projectName, partial_arg$1, token, (function (param) {
                          var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
                          var __x$1 = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                        "_tx",
                                        /* Equal */Block.__(0, [/* `ObjectID */[
                                              -589436806,
                                              param
                                            ]])
                                      ]), __x), aperture);
                          return /* ProjectDebugger2 */Block.__(19, [
                                    projectName,
                                    {
                                      aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                  /* Floating */0,
                                                  /* All */6
                                                ])), __x$1),
                                      state: undefined
                                    }
                                  ]);
                        }), handleChangeUrl, handleAddFilters, onRefresh, partial_arg, param, param$1, param$2, param$3);
          }),
        renderHeaderCell: (function (param) {
            var func = function (param$1, param$2, param$3, param$4) {
              return ExploreListData$BsConsole.renderHeaderCell(param, param$1, param$2, param$3, param$4);
            };
            return (function (param) {
                var func$1 = Curry._1(func, param);
                return (function (param) {
                    var func$2 = Curry._1(func$1, param);
                    return (function (param) {
                        return Curry._2(func$2, param, arg);
                      });
                  });
              });
          }),
        renderStubHeader: (function (param) {
            return ExploreListData$BsConsole.renderStubHeader((function (select) {
                          var sort$1 = Belt_List.toArray(select).includes(Curry._1(Crdb$BsConsole.Sort.getAttribute, sort)) ? sort : Crdb$BsConsole.Sort.defaultSelect;
                          return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                                        projectName,
                                        {
                                          aperture: aperture,
                                          select: select,
                                          sort: sort$1
                                        }
                                      ]));
                        }), select, describeRemote, param);
          }),
        stubMinWidth: 175,
        onSortChange: (function (param) {
            return ExploreListData$BsConsole.onSortChange(setSort, param);
          }),
        onColumnOrderChange: (function (columnOrder) {
            return BtTableSessionData$BsConsole.ColumnOrder.set(projectName, columnOrder, /* ExploreList */2);
          }),
        resizeableColumns: true,
        draggableColumns: true,
        fixedLeftColumns: ["_tx"],
        page: match$3.page,
        onChangePage: match$3.setPage,
        totalRows: Belt_Option.getWithDefault(meta.instances, 0),
        loading: match$3.loading,
        containerClassName: match$3.containerClassName,
        onColumnWidthChange: (function (columnWidths) {
            return BtTableSessionData$BsConsole.ColumnWidth.set(projectName, columnWidths, /* ExploreList */2);
          })
      };
      var tmp$3 = match[1];
      if (tmp$3 !== undefined) {
        tmp$2.sortingState = Caml_option.valFromOption(tmp$3);
      }
      if (match$3.columnOrder !== undefined) {
        tmp$2.columnOrder = Caml_option.valFromOption(match$3.columnOrder);
      }
      if (match$3.columnWidths !== undefined) {
        tmp$2.columnWidths = Caml_option.valFromOption(match$3.columnWidths);
      }
      tmp$1 = React.createElement(DrilldownContext$BsConsole.Provider.make, {
            handleChangeUrl: handleChangeUrl,
            handleAddFilters: handleAddFilters,
            children: React.createElement(BtEnhancedTable$BsConsole.make, tmp$2)
          });
    } else {
      tmp$1 = React.createElement(Col2$BsConsole.make, {
            alignItems: /* center */98248149,
            justifyContent: /* center */98248149,
            className: Css.style(/* :: */[
                  Css.minHeight(Css.px(175)),
                  /* :: */[
                    Css.padding(Css.rem(2)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* [] */0
                    ]
                  ]
                ]),
            children: Caml_int64.gt(meta.tx, Caml_int64.one) ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading1 */0,
                        children: Text$BsConsole.get(/* Instances_Empty_Title */4)
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        children: Text$BsConsole.get(/* Instances_Empty_Description */5)
                      })) : React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading1 */0,
                        children: Text$BsConsole.get(/* Welcome */8)
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle1 */6,
                        children: Text$BsConsole.get(/* No_Crashes_Uploaded */9)
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (_event) {
                            return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                          projectName,
                                          {
                                            doc: undefined
                                          }
                                        ]));
                          }),
                        children: I18N$BsConsole.show(undefined, "Get started")
                      }))
          });
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(ViewNav$BsConsole.make, {
                      config: config,
                      aperture: aperture,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName,
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                            /* [] */0
                          ])
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                              /* :: */[
                                Css.padding2(Css.px(4), Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                            name: projectName,
                            token: token,
                            handleTask: handleTask,
                            handleChangeUrl: handleChangeUrl,
                            config: config,
                            query: queryForSavedQueryContainer,
                            mode: mode,
                            normBy: undefined,
                            renderTrigger: (function (param) {
                                var queries = param.queries;
                                var handleOpen = param.handleOpen;
                                var disabled = Belt_Option.getWithDefault(Belt_Option.map(queries, (function (savedQueries) {
                                            var mode = SavedQueriesModel$BsConsole.modeOfStringExn("instance");
                                            var query = ExploreListData$BsConsole.makeQuery(aperture, select, sort);
                                            var match = Current$BsConsole.get(mode, query, savedQueries);
                                            if (match) {
                                              return true;
                                            } else {
                                              return false;
                                            }
                                          })), false);
                                var tmp = {
                                  projectBookmarkedViews: param.projectBookmarkedViews,
                                  userBookmarkedViews: param.userBookmarkedViews,
                                  projectDefault: param.projectDefault,
                                  userDefault: param.userDefault,
                                  queries: queries,
                                  handleOpen: (function (param) {
                                      return Curry._2(handleOpen, undefined, undefined);
                                    }),
                                  handleChangeUrl: handleChangeUrl,
                                  currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("instance"),
                                  projectName: projectName
                                };
                                if (queryForSavedQueryContainer !== undefined) {
                                  tmp.query = Caml_option.valFromOption(queryForSavedQueryContainer);
                                }
                                return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, tmp), React.createElement(Button.default, {
                                                color: "primary",
                                                className: Css.style(/* :: */[
                                                      Css.marginRight(Css.px(8)),
                                                      /* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]),
                                                disabled: disabled,
                                                onClick: (function (param) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ViewManagerSaveView */120]));
                                                    return Curry._2(handleOpen, undefined, /* Save */0);
                                                  }),
                                                children: null
                                              }, React.createElement(Save.default, {
                                                    className: Css.style(/* :: */[
                                                          Css.marginRight(Css.px(8)),
                                                          /* [] */0
                                                        ]),
                                                    color: "inherit"
                                                  }), I18N$BsConsole.show(undefined, "Save view")));
                              })
                          })
                    })), React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          "explore-table-paper",
                          /* :: */[
                            "select-container",
                            /* [] */0
                          ]
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.padding4(Css.px(16), Css.px(16), /* zero */-789508312, Css.px(16)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          children: tmp
                        }), React.createElement(ExploreModeSelect$BsConsole.Jsx3.make, {
                          projectName: projectName,
                          aperture: aperture,
                          currentView: /* List */848054398,
                          handleChangeUrl: handleChangeUrl
                        })), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                      token: token,
                      projectName: projectName,
                      aperture: apertureForQuery,
                      onRangeChange: (function (range) {
                          return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                                        projectName,
                                        {
                                          aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture),
                                          select: select,
                                          sort: sort
                                        }
                                      ]));
                        }),
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                            /* [] */0
                          ]),
                      metricsAttributes: metricsAttributes
                    }), tmp$1));
}

function make(sort, projectName, aperture, handleTask, token, handleChangeUrl, select, handleAddFilters, config, route, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ProjectInstances, {
              sort: sort,
              projectName: projectName,
              aperture: aperture,
              handleTask: handleTask,
              token: token,
              handleChangeUrl: handleChangeUrl,
              select: select,
              handleAddFilters: handleAddFilters,
              config: config,
              route: route
            }, _children);
}

var Re = {
  make: make
};

var make$1 = ProjectInstances;

exports.makeQueryForSessionStorage = makeQueryForSessionStorage;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
