'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResponsiveSVG;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ResponsiveSVG(_ref) {
  var children = _ref.children,
      width = _ref.width,
      height = _ref.height,
      _ref$xOrigin = _ref.xOrigin,
      xOrigin = _ref$xOrigin === undefined ? 0 : _ref$xOrigin,
      _ref$yOrigin = _ref.yOrigin,
      yOrigin = _ref$yOrigin === undefined ? 0 : _ref$yOrigin,
      _ref$preserveAspectRa = _ref.preserveAspectRatio,
      preserveAspectRatio = _ref$preserveAspectRa === undefined ? 'xMinYMin meet' : _ref$preserveAspectRa;

  return _react2.default.createElement(
    'div',
    { style: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        verticalAlign: 'top',
        overflow: 'hidden'
      } },
    _react2.default.createElement(
      'svg',
      {
        preserveAspectRatio: preserveAspectRatio,
        viewBox: xOrigin + ' ' + yOrigin + ' ' + width + ' ' + height
      },
      children
    )
  );
}