// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Colors$BsConsole = require("./Colors.js");

var component = RR$BsConsole.statelessComponent("LoadingOverlay-BsConsole");

function make(param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return React.createElement("div", {
                          className: "table-overlay",
                          style: {
                            backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA18),
                            display: "flex",
                            height: "100%",
                            left: "0px",
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            zIndex: "10",
                            alignItems: "center",
                            justifyContent: "center"
                          }
                        }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
