// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Icon = require("@material-ui/core/Icon");

function make(classes, color, fontSize, name, param) {
  return ReasonReact.wrapJsForReason(Icon.default, {
              classes: classes,
              color: Belt_Option.map(color, (function (param) {
                      if (param >= -215364664) {
                        if (param >= 58474434) {
                          if (param >= 373701558) {
                            return "action";
                          } else {
                            return "primary";
                          }
                        } else if (param >= -66829956) {
                          return "disabled";
                        } else {
                          return "error";
                        }
                      } else if (param >= -499495052) {
                        return "secondary";
                      } else {
                        return "inherit";
                      }
                    })),
              fontSize: Belt_Option.map(fontSize, (function (param) {
                      if (param >= -9768761) {
                        if (param >= 465819841) {
                          return "default";
                        } else {
                          return "small";
                        }
                      } else if (param >= -272944197) {
                        return "large";
                      } else {
                        return "inherit";
                      }
                    }))
            }, name);
}

exports.make = make;
/* ReasonReact Not a pure module */
