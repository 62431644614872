// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var AlertsComparisonOperator$BsConsole = require("./AlertsComparisonOperator.js");

function isQuery(param) {
  if (param.tag) {
    return true;
  } else {
    return false;
  }
}

function getFold(param) {
  if (param.tag) {
    return param[1];
  } else {
    return param[1].fold;
  }
}

function getSort(param) {
  if (param.tag) {
    return param[4];
  }
  
}

function getHavings(param) {
  if (param.tag) {
    return param[3];
  } else {
    return AlertsUtils$BsConsole.getHavingsFromHavingsArr(param[1].havingsArr);
  }
}

function getFactor(param) {
  if (param.tag) {
    return param[0];
  } else {
    return param[1].factor;
  }
}

function getAperture(param) {
  if (param.tag) {
    return param[2];
  }
  var __x = Crdb$BsConsole.Aperture.$$default;
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, param[1].filter, __x);
}

function getAggs(param) {
  if (param.tag) {
    return Curry._1(Crdb$BsConsole.Fold.toArray, param[1]);
  } else {
    return Curry._1(Crdb$BsConsole.Fold.toArray, param[1].fold);
  }
}

function getFilters(param) {
  if (param.tag) {
    return Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, param[2]));
  } else {
    return Curry._1(Crdb$BsConsole.Filters.toArray, param[1].filter);
  }
}

function getName(param) {
  if (param.tag) {
    return "";
  } else {
    return param[0].name;
  }
}

function getQueryPeriod(param) {
  if (param.tag) {
    return 60;
  } else {
    return param[0].queryPeriod | 0;
  }
}

function getMinNotificationInterval(param) {
  if (param.tag) {
    return 900;
  } else {
    return param[0].minNotificationInterval | 0;
  }
}

function getInitialActions(param) {
  if (param.tag) {
    return /* :: */[
            undefined,
            /* [] */0
          ];
  } else {
    return /* [] */0;
  }
}

function getUpdatedActions(watchers, actions, param) {
  if (param.tag) {
    return actions;
  } else {
    return Belt_List.fromArray(Belt_Array.map(Belt_Array.keepMap(param[2], (function (t) {
                          return Belt_Array.getBy(watchers, (function (w) {
                                        return w.watcherName === t.name;
                                      }));
                        })), (function (t) {
                      return t;
                    })));
  }
}

function getTitle(param) {
  if (param.tag) {
    return I18N$BsConsole.get(undefined, "Export alert based on query");
  } else {
    return I18N$BsConsole.get(undefined, "Edit alert");
  }
}

function getInitialTriggerConditions(param) {
  if (param.tag) {
    var aggs = Curry._1(Crdb$BsConsole.Fold.toArray, param[1]);
    var aggsForAlert = Belt_Array.concat(Belt_Array.keep(aggs, (function (agg) {
                var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                return match[1] === "unique";
              })), [AlertsUtils$BsConsole.errorCountAggregation]);
    return Belt_List.map(Belt_List.fromArray(Belt_Array.map(aggsForAlert, (function (agg) {
                          var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                          return {
                                  attribute: match[0],
                                  operation: match[1],
                                  comparisonOperator: /* NoThreshold */2,
                                  warningThreshold: 0,
                                  criticalThreshold: 0
                                };
                        }))), (function (t) {
                  return t;
                }));
  }
  var query = param[1];
  return Belt_List.map(Belt_List.keepMap(param[0].triggers, (function (trigger) {
                    var operationMaybe = AlertsUtils$BsConsole.getOperation(query.fold, trigger.aggregation, undefined);
                    if (operationMaybe !== undefined) {
                      return {
                              attribute: trigger.aggregation.column,
                              operation: operationMaybe,
                              comparisonOperator: AlertsComparisonOperator$BsConsole.fromValue(trigger.comparisonOperator),
                              warningThreshold: trigger.warningThreshold | 0,
                              criticalThreshold: trigger.criticalThreshold | 0
                            };
                    }
                    
                  })), (function (t) {
                return t;
              }));
}

exports.isQuery = isQuery;
exports.getFold = getFold;
exports.getSort = getSort;
exports.getHavings = getHavings;
exports.getFactor = getFactor;
exports.getAperture = getAperture;
exports.getAggs = getAggs;
exports.getFilters = getFilters;
exports.getName = getName;
exports.getQueryPeriod = getQueryPeriod;
exports.getMinNotificationInterval = getMinNotificationInterval;
exports.getInitialActions = getInitialActions;
exports.getUpdatedActions = getUpdatedActions;
exports.getTitle = getTitle;
exports.getInitialTriggerConditions = getInitialTriggerConditions;
/* Crdb-BsConsole Not a pure module */
