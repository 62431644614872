// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Colors$BsConsole = require("../../Colors.js");
var BtTable$BsConsole = require("../../BtTable.js");
var BtTableRow$BsConsole = require("../../BtTableRow.js");
var AlertsUtils$BsConsole = require("../../alerts/AlertsUtils.js");
var BtTableBody$BsConsole = require("../../BtTableBody.js");
var BtTableCell$BsConsole = require("../../BtTableCell.js");
var BtTableHead$BsConsole = require("../../BtTableHead.js");
var SymbolTable$BsConsole = require("../../project-settings/symbols/SymbolTable.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Select = require("@material-ui/core/Select");
var Tooltip = require("@material-ui/core/Tooltip");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var BtTableContainer$BsConsole = require("../../BtTableContainer.js");
var BugSectionHeader$BsConsole = require("../BugSectionHeader.js");
var TextField = require("@material-ui/core/TextField");
var BugBreadcrumbsText$BsConsole = require("./BugBreadcrumbsText.js");
var ArrowDropUp = require("@material-ui/icons/ArrowDropUp");
var BugBreadcrumbsFilters$BsConsole = require("./BugBreadcrumbsFilters.js");
var BugBreadcrumbsMetrics$BsConsole = require("./BugBreadcrumbsMetrics.js");
var TableSortLabel = require("@material-ui/core/TableSortLabel");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");
var BugBreadcrumbsTextViews$BsConsole = require("./BugBreadcrumbsTextViews.js");
var BugBreadcrumbsRowDetails$BsConsole = require("./BugBreadcrumbsRowDetails.js");

var allButtonStyle = Css.style(/* :: */[
      Css.paddingTop(Css.px(15)),
      /* :: */[
        Css.paddingBottom(Css.px(15)),
        /* [] */0
      ]
    ]);

var allButtonInnerStyle = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey65)),
        /* [] */0
      ]
    ]);

var initialSortingState = {
  columnName: "timestamp",
  direction: /* Desc */759138929
};

function simpleSort(rows, sortingState) {
  return Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(rows), (function (a, b) {
                    var match = sortingState.columnName;
                    var match$1 = sortingState.direction;
                    if (match === "timestamp") {
                      if (match$1 >= 759138929) {
                        return Caml_int64.to_int32(Caml_int64.sub(b.id, a.id));
                      } else {
                        return Caml_int64.to_int32(Caml_int64.sub(a.id, b.id));
                      }
                    } else {
                      return 1;
                    }
                  })));
}

var Sorting = {
  initialSortingState: initialSortingState,
  simpleSort: simpleSort
};

function isChecked(arr, value) {
  return Belt_Option.isSome(Belt_Array.getBy(arr, (function (x) {
                    return x === value;
                  })));
}

function BugBreadcrumbsView(Props) {
  var breadcrumbs = Props.breadcrumbs;
  var h = Props.height;
  var w = Props.width;
  var showHeaderOpt = Props.showHeader;
  var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : true;
  var match = React.useState((function () {
          return 0;
        }));
  var match$1 = React.useState((function () {
          return initialSortingState;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setSearchValue = match$2[1];
  var searchValue = match$2[0];
  var match$3 = React.useState((function () {
          return BugBreadcrumbsFilters$BsConsole.Type_.$$default;
        }));
  var setSelectedFilterTypes = match$3[1];
  var selectedFilterTypes = match$3[0];
  var match$4 = React.useState((function () {
          return BugBreadcrumbsFilters$BsConsole.Level.$$default;
        }));
  var setSelectedFilterLevels = match$4[1];
  var selectedFilterLevels = match$4[0];
  var match$5 = React.useState((function () {
          return [];
        }));
  var setExpandedAttributes = match$5[1];
  var expandedAttributes = match$5[0];
  var rowsPerPage = breadcrumbs.length;
  var sortedRows = simpleSort(breadcrumbs, sortingState);
  var arg = function (row) {
    var attributes = Util$BsConsole.formattedStringify(row.attributes);
    return row.message + (" " + attributes);
  };
  var messageSearch = function (param) {
    return (function (param$1) {
        return SymbolTable$BsConsole.fuzz(param, param$1, arg);
      });
  };
  var sortedAndSearchedRows = messageSearch(sortedRows)(searchValue);
  var start = Caml_int32.imul(match[0], rowsPerPage);
  var end_ = (start + rowsPerPage | 0) > sortedAndSearchedRows.length ? sortedAndSearchedRows.length : start + rowsPerPage | 0;
  var rows = sortedAndSearchedRows.slice(start, end_).filter((function (breadcrumb) {
          var hasType = Belt_Option.isSome(Belt_Array.getBy(selectedFilterTypes, (function (b) {
                      return BugBreadcrumbsFilters$BsConsole.Type_.toString(b) === breadcrumb.type_;
                    })));
          var hasLevel = Belt_Option.isSome(Belt_Array.getBy(selectedFilterLevels, (function (b) {
                      return BugBreadcrumbsFilters$BsConsole.Level.toString(b) === breadcrumb.level;
                    })));
          var isBreadcrumbType_Unknown = BugBreadcrumbsFilters$BsConsole.Type_.isUnknown(BugBreadcrumbsFilters$BsConsole.Type_.fromString(breadcrumb.type_));
          var isBreadcrumbLevelUnknown = BugBreadcrumbsFilters$BsConsole.Level.isUnknown(BugBreadcrumbsFilters$BsConsole.Level.fromString(breadcrumb.level));
          var isOtherType = isChecked(selectedFilterTypes, BugBreadcrumbsFilters$BsConsole.Type_.other) && isBreadcrumbType_Unknown;
          var isOtherLevel = isChecked(selectedFilterLevels, BugBreadcrumbsFilters$BsConsole.Level.other) && isBreadcrumbLevelUnknown;
          if (hasType || isOtherType) {
            if (hasLevel) {
              return true;
            } else {
              return isOtherLevel;
            }
          } else {
            return false;
          }
        }));
  var search = searchValue.trim();
  var emptyTableText = search !== "" ? BugBreadcrumbsText$BsConsole.noResultForSearchParam : BugBreadcrumbsText$BsConsole.noBreadcrumbs;
  var isExpanded = function (id) {
    return Belt_Option.isSome(Belt_Array.getBy(expandedAttributes, (function (rowId) {
                      return Caml_int64.eq(rowId, id);
                    })));
  };
  var hasNoDetail = function (row) {
    return Caml_obj.caml_equal(row.attributes, { });
  };
  var allCheckedLevels = selectedFilterLevels.length === BugBreadcrumbsFilters$BsConsole.Level.$$default.length;
  var allCheckedTypes = selectedFilterTypes.length === BugBreadcrumbsFilters$BsConsole.Type_.$$default.length;
  var numRowsWithDetails = Belt_Array.keep(rows, (function (r) {
          return !hasNoDetail(r);
        })).length;
  var match$6 = sortingState.direction;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.px(w)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* :: */[
                        Css.overflow(/* auto */-1065951377),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* column */-963948842),
                            /* :: */[
                              Css.justifyContent(/* spaceBetween */516682146),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, showHeader ? React.createElement(BugSectionHeader$BsConsole.make, {
                    title: I18N$BsConsole.get(undefined, "Breadcrumbs")
                  }) : null, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.height(Css.px(50)),
                        /* :: */[
                          Css.position(/* sticky */188263721),
                          /* :: */[
                            Css.top(/* zero */-789508312),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                              /* :: */[
                                Css.zIndex(2),
                                /* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey65)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.px(25)),
                            /* :: */[
                              Css.marginRight(Css.px(10)),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(TextField.default, {
                          placeholder: BugBreadcrumbsText$BsConsole.searchPlaceholder,
                          value: searchValue,
                          onChange: (function (e) {
                              var value = e.target.value;
                              BugBreadcrumbsMetrics$BsConsole.send(/* Search */4);
                              return Curry._1(setSearchValue, (function (param) {
                                            return value;
                                          }));
                            })
                        })), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* [] */0
                          ])
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ]),
                          children: null
                        }, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.marginRight(Css.px(8)),
                                    /* [] */0
                                  ])
                            }, I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.filterBy)), React.createElement(Select.default, {
                              id: "filter-level",
                              value: selectedFilterLevels,
                              disabled: false,
                              onChange: (function ($$event, param) {
                                  BugBreadcrumbsMetrics$BsConsole.send(/* FilterLevel */2);
                                  var value = $$event.target.value;
                                  if (value === "all") {
                                    var updated = allCheckedLevels ? [/* NoneSelected */5] : BugBreadcrumbsFilters$BsConsole.Level.$$default;
                                    return Curry._1(setSelectedFilterLevels, (function (param) {
                                                  return updated;
                                                }));
                                  }
                                  var _alreadyExists = Belt_Array.getBy(selectedFilterLevels, (function (x) {
                                          return Caml_obj.caml_equal(x, value);
                                        }));
                                  if (_alreadyExists !== undefined) {
                                    var updated$1 = Belt_Array.keep(selectedFilterLevels, (function (x) {
                                            return x !== value;
                                          }));
                                    var match = updated$1.length;
                                    if (match !== 0) {
                                      return Curry._1(setSelectedFilterLevels, (function (param) {
                                                    return updated$1;
                                                  }));
                                    } else {
                                      return Curry._1(setSelectedFilterLevels, (function (param) {
                                                    return [/* NoneSelected */5];
                                                  }));
                                    }
                                  }
                                  var updated$2 = Belt_Array.concat(Belt_Array.keep(selectedFilterLevels, (function (x) {
                                              return x !== /* NoneSelected */5;
                                            })), [value]);
                                  return Curry._1(setSelectedFilterLevels, (function (param) {
                                                return updated$2;
                                              }));
                                }),
                              renderValue: (function (value) {
                                  if (value.length === 1) {
                                    var match = value[0];
                                    if (typeof match === "number" && match >= 5) {
                                      return React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.paddingLeft(Css.px(10)),
                                                        /* [] */0
                                                      ])
                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                      variant: /* Caption */11,
                                                      children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.noLevels)
                                                    }));
                                    }
                                    
                                  }
                                  var str = allCheckedLevels ? BugBreadcrumbsText$BsConsole.allLevels : BugBreadcrumbsFilters$BsConsole.Level.arrayToString(selectedFilterLevels);
                                  return React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.paddingLeft(Css.px(10)),
                                                    /* :: */[
                                                      Css.maxWidth(Css.px(160)),
                                                      /* :: */[
                                                        Css.overflow(/* hidden */-862584982),
                                                        /* :: */[
                                                          Css.textOverflow(/* ellipsis */166888785),
                                                          /* :: */[
                                                            Css.whiteSpace(/* nowrap */867913355),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Caption */11,
                                                  children: I18N$BsConsole.showSkip(str)
                                                }));
                                }),
                              disableUnderline: true,
                              classes: {
                                root: AlertsUtils$BsConsole.selectStyle
                              },
                              children: null
                            }, React.createElement(MenuItem.default, {
                                  classes: {
                                    root: allButtonStyle
                                  },
                                  value: "all",
                                  children: React.createElement("div", {
                                        className: allButtonInnerStyle
                                      }, React.createElement(Checkbox.default, {
                                            checked: allCheckedLevels,
                                            color: "primary",
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.allLevels)
                                          })),
                                  key: "all-levels"
                                }), Belt_Array.map(BugBreadcrumbsFilters$BsConsole.Level.$$default, (function (v) {
                                    return React.createElement(MenuItem.default, {
                                                value: v,
                                                children: null,
                                                key: BugBreadcrumbsFilters$BsConsole.Level.toFriendlyString(v)
                                              }, React.createElement(Checkbox.default, {
                                                    checked: isChecked(selectedFilterLevels, v),
                                                    color: "primary",
                                                    onChange: (function (param) {
                                                        
                                                      })
                                                  }), React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Caption */11,
                                                    children: I18N$BsConsole.showSkip(BugBreadcrumbsFilters$BsConsole.Level.toFriendlyString(v))
                                                  }));
                                  }))), React.createElement(Select.default, {
                              id: "filter-type",
                              value: selectedFilterTypes,
                              disabled: false,
                              onChange: (function ($$event, param) {
                                  BugBreadcrumbsMetrics$BsConsole.send(/* FilterType */1);
                                  var value = $$event.target.value;
                                  if (value === "all") {
                                    var updated = allCheckedTypes ? [/* NoneSelected */7] : BugBreadcrumbsFilters$BsConsole.Type_.$$default;
                                    return Curry._1(setSelectedFilterTypes, (function (param) {
                                                  return updated;
                                                }));
                                  }
                                  var _alreadyExists = Belt_Array.getBy(selectedFilterTypes, (function (x) {
                                          return Caml_obj.caml_equal(x, value);
                                        }));
                                  if (_alreadyExists !== undefined) {
                                    var updated$1 = Belt_Array.keep(selectedFilterTypes, (function (x) {
                                            return x !== value;
                                          }));
                                    var match = updated$1.length;
                                    if (match !== 0) {
                                      return Curry._1(setSelectedFilterTypes, (function (param) {
                                                    return updated$1;
                                                  }));
                                    } else {
                                      return Curry._1(setSelectedFilterTypes, (function (param) {
                                                    return [/* NoneSelected */7];
                                                  }));
                                    }
                                  }
                                  var updated$2 = Belt_Array.concat(Belt_Array.keep(selectedFilterTypes, (function (x) {
                                              return x !== /* NoneSelected */7;
                                            })), [value]);
                                  return Curry._1(setSelectedFilterTypes, (function (param) {
                                                return updated$2;
                                              }));
                                }),
                              renderValue: (function (value) {
                                  if (value.length === 1) {
                                    var match = value[0];
                                    if (typeof match === "number" && match >= 7) {
                                      return React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.paddingLeft(Css.px(10)),
                                                        /* [] */0
                                                      ])
                                                }, React.createElement(BtTypography$BsConsole.make, {
                                                      variant: /* Caption */11,
                                                      children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.noTypes)
                                                    }));
                                    }
                                    
                                  }
                                  var str = allCheckedTypes ? BugBreadcrumbsText$BsConsole.allTypes : BugBreadcrumbsFilters$BsConsole.Type_.arrayToString(selectedFilterTypes);
                                  return React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.paddingLeft(Css.px(10)),
                                                    /* :: */[
                                                      Css.maxWidth(Css.px(160)),
                                                      /* :: */[
                                                        Css.overflow(/* hidden */-862584982),
                                                        /* :: */[
                                                          Css.textOverflow(/* ellipsis */166888785),
                                                          /* :: */[
                                                            Css.whiteSpace(/* nowrap */867913355),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Caption */11,
                                                  children: I18N$BsConsole.showSkip(str)
                                                }));
                                }),
                              disableUnderline: true,
                              classes: {
                                root: AlertsUtils$BsConsole.selectStyle
                              },
                              children: null
                            }, React.createElement(MenuItem.default, {
                                  classes: {
                                    root: allButtonStyle
                                  },
                                  value: "all",
                                  children: React.createElement("div", {
                                        className: allButtonInnerStyle
                                      }, React.createElement(Checkbox.default, {
                                            checked: allCheckedTypes,
                                            color: "primary",
                                            onChange: (function (param) {
                                                
                                              })
                                          }), React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.allTypes)
                                          })),
                                  key: "all-types"
                                }), Belt_Array.map(BugBreadcrumbsFilters$BsConsole.Type_.$$default, (function (v) {
                                    return React.createElement(MenuItem.default, {
                                                value: v,
                                                children: null,
                                                key: BugBreadcrumbsFilters$BsConsole.Type_.toFriendlyString(v)
                                              }, React.createElement(Checkbox.default, {
                                                    checked: isChecked(selectedFilterTypes, v),
                                                    color: "primary",
                                                    onChange: (function (param) {
                                                        
                                                      })
                                                  }), React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Caption */11,
                                                    children: I18N$BsConsole.showSkip(BugBreadcrumbsFilters$BsConsole.Type_.toFriendlyString(v))
                                                  }));
                                  }))), numRowsWithDetails > 0 ? (
                            expandedAttributes.length === numRowsWithDetails ? React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                    ariaLabel: BugBreadcrumbsText$BsConsole.collapseAll,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginLeft(Css.px(24)),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (param) {
                                        return Curry._1(setExpandedAttributes, (function (param) {
                                                      return [];
                                                    }));
                                      }),
                                    title_tooltip: BugBreadcrumbsText$BsConsole.collapseAll,
                                    children: React.createElement(ArrowDropUp.default, { })
                                  }) : React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                    ariaLabel: BugBreadcrumbsText$BsConsole.expandAll,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginLeft(Css.px(24)),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (param) {
                                        BugBreadcrumbsMetrics$BsConsole.send(/* ExpandAttributes */3);
                                        var expand = true;
                                        if (!expand) {
                                          return Curry._1(setExpandedAttributes, (function (param) {
                                                        return [];
                                                      }));
                                        }
                                        var updated = Belt_Array.keepMap(rows, (function (r) {
                                                if (hasNoDetail(r)) {
                                                  return ;
                                                } else {
                                                  return r.id;
                                                }
                                              }));
                                        return Curry._1(setExpandedAttributes, (function (param) {
                                                      return updated;
                                                    }));
                                      }),
                                    title_tooltip: BugBreadcrumbsText$BsConsole.expandAll,
                                    children: React.createElement(ArrowDropDown.default, { })
                                  })
                          ) : null))), React.createElement(BtTableContainer$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.important(Css.maxHeight(Css.pct(100))),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(BtTable$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.height(Css.pct(100)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(BtTableHead$BsConsole.make, {
                          children: React.createElement(BtTableRow$BsConsole.make, {
                                children: null
                              }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.px(48)),
                                          /* [] */0
                                        ])
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.px(200)),
                                          /* [] */0
                                        ]),
                                    children: React.createElement(Tooltip.default, {
                                          children: React.createElement(TableSortLabel.default, {
                                                active: true,
                                                onClick: (function (param) {
                                                    var match = sortingState.direction;
                                                    var newDirection = match >= 759138929 ? /* Asc */3258129 : /* Desc */759138929;
                                                    return Curry._1(setSortingState, (function (param) {
                                                                  return {
                                                                          columnName: sortingState.columnName,
                                                                          direction: newDirection
                                                                        };
                                                                }));
                                                  }),
                                                direction: match$6 >= 759138929 ? "desc" : "asc",
                                                children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.timestamp)
                                              }),
                                          title: BugBreadcrumbsText$BsConsole.sort
                                        })
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.px(100)),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.level)
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.px(120)),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.typeText)
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.showSkip(BugBreadcrumbsText$BsConsole.description)
                                  }))
                        }), React.createElement(BtTableBody$BsConsole.make, {
                          children: rows.length === 0 ? React.createElement(BtTableRow$BsConsole.make, {
                                  children: React.createElement(BtTableCell$BsConsole.Data.make, {
                                        colSpan: 5,
                                        className: Css.style(/* :: */[
                                              Css.textAlign(/* center */98248149),
                                              /* [] */0
                                            ]),
                                        children: I18N$BsConsole.showSkip(emptyTableText)
                                      }),
                                  key: "empty-row"
                                }) : Belt_Array.map(rows, (function (row) {
                                    var partial_arg = row.id;
                                    var text = BugBreadcrumbsFilters$BsConsole.Level.toFriendlyString(BugBreadcrumbsFilters$BsConsole.Level.fromString(row.level));
                                    var adornment = BugBreadcrumbsFilters$BsConsole.Level.toAdornment(BugBreadcrumbsFilters$BsConsole.Level.fromString(row.level));
                                    var text$1 = BugBreadcrumbsFilters$BsConsole.Type_.toFriendlyString(BugBreadcrumbsFilters$BsConsole.Type_.fromString(row.type_));
                                    var textColor = BugBreadcrumbsFilters$BsConsole.Type_.toColor(BugBreadcrumbsFilters$BsConsole.Type_.fromString(row.type_));
                                    return React.createElement(BtTableRow$BsConsole.ExpandableControlled.make, {
                                                expandedContent: React.createElement(BugBreadcrumbsRowDetails$BsConsole.make, {
                                                      attributes: row.attributes
                                                    }),
                                                hideExpansion: hasNoDetail(row),
                                                setExpanded: (function (param) {
                                                    var updated = param ? Belt_Array.keep(expandedAttributes, (function (rowId) {
                                                              return Caml_int64.neq(rowId, partial_arg);
                                                            })) : Belt_Array.concat(expandedAttributes, [partial_arg]);
                                                    return Curry._1(setExpandedAttributes, (function (param) {
                                                                  return updated;
                                                                }));
                                                  }),
                                                expanded: isExpanded(row.id),
                                                colSpan: 5,
                                                children: null,
                                                key: Int64.to_string(row.id)
                                              }, React.createElement(BtTableCell$BsConsole.make, {
                                                    children: React.createElement(BugBreadcrumbsTextViews$BsConsole.Timestamp.make, {
                                                          timestamp: row.timestamp,
                                                          isExpanded: isExpanded(row.id)
                                                        })
                                                  }), React.createElement(BtTableCell$BsConsole.make, {
                                                    children: React.createElement(BugBreadcrumbsTextViews$BsConsole.Level.make, {
                                                          text: text,
                                                          adornment: adornment,
                                                          isExpanded: isExpanded(row.id)
                                                        })
                                                  }), React.createElement(BtTableCell$BsConsole.make, {
                                                    children: React.createElement(BugBreadcrumbsTextViews$BsConsole.Type_.make, {
                                                          text: text$1,
                                                          textColor: textColor,
                                                          isExpanded: isExpanded(row.id)
                                                        })
                                                  }), React.createElement(BtTableCell$BsConsole.make, {
                                                    children: React.createElement(BugBreadcrumbsTextViews$BsConsole.Description.make, {
                                                          message: row.message
                                                        })
                                                  }));
                                  }))
                        })), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.position(/* sticky */188263721),
                              /* :: */[
                                Css.bottom(Css.px(0)),
                                /* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                  /* :: */[
                                    Css.minHeight(Css.px(48)),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                        /* :: */[
                                          Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          className: Css.style(/* :: */[
                                Css.whiteSpace(/* nowrap */867913355),
                                /* :: */[
                                  Css.marginLeft(Css.px(12)),
                                  /* [] */0
                                ]
                              ]),
                          children: Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* String_literal */Block.__(11, [
                                            " of ",
                                            /* Int */Block.__(4, [
                                                /* Int_d */0,
                                                /* No_padding */0,
                                                /* No_precision */0,
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "%d of %d"
                                  ]), rows.length, breadcrumbs.length)
                        }))));
}

var Filters;

var BreadcrumbTextView;

var $$Text;

var arrowSize = 48;

var make = BugBreadcrumbsView;

exports.Filters = Filters;
exports.BreadcrumbTextView = BreadcrumbTextView;
exports.$$Text = $$Text;
exports.arrowSize = arrowSize;
exports.allButtonStyle = allButtonStyle;
exports.allButtonInnerStyle = allButtonInnerStyle;
exports.Sorting = Sorting;
exports.isChecked = isChecked;
exports.make = make;
/* allButtonStyle Not a pure module */
