// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var VX$BsConsole = require("./VX.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Colors$BsConsole = require("./Colors.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Charts__Bin$BsConsole = require("./charts/Charts__Bin.js");
var BtSelectJsx3$BsConsole = require("./BtSelectJsx3.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var Paper = require("@material-ui/core/Paper");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var FetchMetricGroup$BsConsole = require("./FetchMetricGroup.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var LinearProgress = require("@material-ui/core/LinearProgress");

function makeQuery(aperture) {
  return Curry._1(Crdb$BsConsole.Query.freeze, /* Aggregate */Block.__(0, [
                aperture,
                /* Custom */["*"],
                Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                          "timestamp",
                          /* Bin */Block.__(0, [
                              64,
                              Crdb$BsConsole.ensureValidTimeRange(Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture)))
                            ])
                        ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                              "timestamp",
                              /* Range */8
                            ]), Crdb$BsConsole.Fold.empty)),
                undefined,
                undefined,
                undefined
              ]));
}

var paper = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
              /* [] */0
            ]
          ]),
      /* :: */[
        BtPaper$BsConsole.mainViewMargins,
        /* [] */0
      ]
    ]);

var Styles = {
  paper: paper
};

function makeMetricsQuery(aperture) {
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture);
  var aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default);
  return /* Aggregate */Block.__(0, [
            aperture$prime,
            /* Custom */["*"],
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "_metric_group",
                      /* Distribution */Block.__(1, [50])
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function getRouteForNormalizeGroup(route, normBy) {
  if (typeof route === "number") {
    return /* NotFound */Block.__(56, ["Something went wrong"]);
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
        if (route[2] !== undefined) {
          return /* NotFound */Block.__(56, ["Something went wrong"]);
        }
        var qs = route[1];
        return /* ProjectTriage */Block.__(11, [
                  route[0],
                  {
                    aperture: qs.aperture,
                    sort: qs.sort,
                    stats: qs.stats,
                    havings: qs.havings,
                    fold: qs.fold,
                    normBy: normBy,
                    similarity: qs.similarity
                  },
                  undefined
                ]);
    case /* ProjectQueryBuilder */13 :
        var qs$1 = route[1];
        return /* ProjectQueryBuilder */Block.__(13, [
                  route[0],
                  {
                    aperture: qs$1.aperture,
                    factor: qs$1.factor,
                    havings: qs$1.havings,
                    fold: qs$1.fold,
                    sort: qs$1.sort,
                    normBy: normBy,
                    highlightExporter: qs$1.highlightExporter
                  }
                ]);
    default:
      return /* NotFound */Block.__(56, ["Something went wrong"]);
  }
}

var allErrors = "All errors";

function getSelectString(normBy) {
  if (normBy !== undefined && normBy !== "_none") {
    return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "Errors normalized by ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "Errors normalized by %s"
                  ]), normBy);
  } else {
    return I18N$BsConsole.get(undefined, allErrors);
  }
}

function WildcardTimestampBin$NormalizeBySelect(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var normBy = Props.normBy;
  Props.currentProject;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var metricsRemote = FetchMetricGroup$BsConsole.getOptions(aperture, token, projectName, config);
  if (typeof metricsRemote === "number") {
    return null;
  }
  if (metricsRemote.tag) {
    return null;
  }
  var match = metricsRemote[0];
  return React.createElement(BtSelectJsx3$BsConsole.make, {
              onChange: (function (_event, param) {
                  
                }),
              value: getSelectString(normBy),
              className: Css.style(/* :: */[
                    Css.selector(".select", /* :: */[
                          Css.minWidth(Css.px(200)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]),
              label: I18N$BsConsole.get(undefined, "Showing"),
              renderValue: I18N$BsConsole.dynamic,
              children: null
            }, React.createElement(Link$BsConsole.Jsx3.make, {
                  route: getRouteForNormalizeGroup(route, "_none"),
                  handleChangeUrl: handleChangeUrl,
                  children: React.createElement(MenuItem.default, {
                        value: /* `String */[
                          -976970511,
                          allErrors
                        ],
                        children: I18N$BsConsole.show(undefined, "All errors")
                      })
                }), Belt_Array.mapWithIndex(match[0], (function (idx, name) {
                    var nextRoute = getRouteForNormalizeGroup(route, name);
                    var optionValue = getSelectString(name);
                    return React.createElement(Link$BsConsole.Jsx3.make, {
                                route: nextRoute,
                                handleChangeUrl: handleChangeUrl,
                                children: React.createElement(MenuItem.default, {
                                      value: /* `String */[
                                        -976970511,
                                        optionValue
                                      ],
                                      children: I18N$BsConsole.show(undefined, optionValue)
                                    }),
                                key: name + ("_" + String(idx))
                              });
                  })), Belt_Array.mapWithIndex(match[1], (function (idx, name) {
                    var nextRoute = getRouteForNormalizeGroup(route, name);
                    var optionValue = getSelectString(name);
                    return React.createElement(Link$BsConsole.Jsx3.make, {
                                route: nextRoute,
                                handleChangeUrl: handleChangeUrl,
                                children: React.createElement(Tooltip.default, {
                                      children: React.createElement(MenuItem.default, {
                                            value: /* `String */[
                                              -976970511,
                                              optionValue
                                            ],
                                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* Char_literal */Block.__(12, [
                                                              /* "*" */42,
                                                              /* End_of_format */0
                                                            ])
                                                        ]),
                                                      "%s*"
                                                    ]), optionValue)
                                          }),
                                      title: I18N$BsConsole.get(undefined, "* - Metric group does not contain any values for the current filters")
                                    }),
                                key: name + ("_" + String(idx))
                              });
                  })));
}

var NormalizeBySelect = {
  makeMetricsQuery: makeMetricsQuery,
  getRouteForNormalizeGroup: getRouteForNormalizeGroup,
  allErrors: allErrors,
  getSelectString: getSelectString,
  make: WildcardTimestampBin$NormalizeBySelect
};

function showNormBySelect(route) {
  if (typeof route === "number") {
    return false;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
    case /* ProjectQueryBuilder */13 :
        return true;
    default:
      return false;
  }
}

function getNormByGroup(route) {
  if (typeof route === "number") {
    return ;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
    case /* ProjectQueryBuilder */13 :
        return route[1].normBy;
    default:
      return ;
  }
}

function WildcardTimestampBin$ErrorBar(Props) {
  var config = Props.config;
  var remote = Props.remote;
  var query = Props.query;
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var normBy = Props.normBy;
  var stabilityScore = Props.stabilityScore;
  var currentProject = Props.currentProject;
  var tmp;
  if (typeof remote === "number" || remote.tag) {
    tmp = null;
  } else {
    var totalCount = Belt_Option.getWithDefault(remote[0][0][2].instances, 0);
    var match = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query)));
    var to_ = match[1];
    var from_ = match[0];
    var tmp$1;
    var exit = 0;
    if (stabilityScore !== undefined && !stabilityScore.tag) {
      tmp$1 = React.createElement(Row2$BsConsole.make, {
            alignItems: /* center */98248149,
            children: null
          }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.style(/* :: */[
                          Css.fontFamily("Inconsolata"),
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(totalCount)))
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body2 */9,
                    className: Css.style(/* [] */0),
                    children: I18N$BsConsole.show(undefined, "errors")
                  })), React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontSize(Css.px(38)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey5)),
                        /* :: */[
                          Css.margin2(Css.zero, Css.px(4)),
                          /* :: */[
                            Css.fontWeight(/* `num */[
                                  5496390,
                                  300
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              }, I18N$BsConsole.showSkip("/")), React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading6 */5,
                    className: Css.style(/* :: */[
                          Css.fontFamily("Inconsolata"),
                          /* [] */0
                        ]),
                    children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Int64.to_string(stabilityScore[0])))
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body2 */9,
                    className: Css.style(/* [] */0),
                    children: I18N$BsConsole.dynamic(Belt_Option.getWithDefault(getNormByGroup(route), "sessions"))
                  })), React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body2 */9,
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* :: */[
                        Css.marginLeft(Css.rem(1)),
                        /* [] */0
                      ]
                    ]),
                children: I18N$BsConsole.dynamic(Util$BsConsole.formatDateRange(undefined, undefined, from_, to_, undefined))
              }));
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = React.createElement(Row2$BsConsole.make, {
            alignItems: /* baseline */287825029,
            children: null
          }, React.createElement(BtTypography$BsConsole.make, {
                variant: /* Heading5 */4,
                className: Css.style(/* :: */[
                      Css.fontFamily("Inconsolata"),
                      /* [] */0
                    ]),
                children: I18N$BsConsole.dynamic(Numeral$BsConsole.format("0,0", String(totalCount)))
              }), React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: Css.style(/* :: */[
                      Css.marginLeft(Css.rem(0.25)),
                      /* [] */0
                    ]),
                children: I18N$BsConsole.show(undefined, "errors")
              }), React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body2 */9,
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* :: */[
                        Css.marginLeft(Css.rem(1)),
                        /* [] */0
                      ]
                    ]),
                children: I18N$BsConsole.dynamic(Util$BsConsole.formatDateRange(undefined, undefined, from_, to_, undefined))
              }));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, showNormBySelect(route) && CoronerdGate$BsConsole.stabilityMonitoring ? React.createElement(Row2$BsConsole.make, {
                alignItems: /* center */98248149,
                children: React.createElement(WildcardTimestampBin$NormalizeBySelect, {
                      aperture: aperture,
                      token: token,
                      projectName: projectName,
                      normBy: normBy,
                      currentProject: currentProject,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      config: config
                    })
              }) : null);
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.position(/* relative */903134412),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.justifyContent(/* spaceBetween */516682146),
                          /* :: */[
                            Css.minHeight(Css.px(48)),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.padding2(Css.zero, Css.rem(0.5)),
                                /* :: */[
                                  Css.borderBottom(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, tmp);
}

function make(config, currentProject, remote, query, aperture, token, projectName, route, handleChangeUrl, normBy, stabilityScore, children) {
  return ReasonReactCompat.wrapReactForReasonReact(WildcardTimestampBin$ErrorBar, {
              config: config,
              remote: remote,
              query: query,
              aperture: aperture,
              token: token,
              projectName: projectName,
              route: route,
              handleChangeUrl: handleChangeUrl,
              normBy: normBy,
              stabilityScore: stabilityScore,
              currentProject: currentProject
            }, children);
}

var Re = {
  make: make
};

var ErrorBar = {
  showNormBySelect: showNormBySelect,
  getNormByGroup: getNormByGroup,
  make: WildcardTimestampBin$ErrorBar,
  Re: Re
};

function WildcardTimestampBin(Props) {
  var config = Props.config;
  var onChange = Props.onChange;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var token = Props.token;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var normByOpt = Props.normBy;
  var stabilityScoreOpt = Props.stabilityScore;
  var normBy = normByOpt !== undefined ? Caml_option.valFromOption(normByOpt) : undefined;
  var stabilityScore = stabilityScoreOpt !== undefined ? Caml_option.valFromOption(stabilityScoreOpt) : undefined;
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = makeQuery(apertureForQuery);
  var currentProject = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var themeVariant = React.useContext(BtThemeContext$BsConsole.context);
  var match = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "wildcard_chart");
  var paginationRemote = match[0];
  var tmp;
  var exit = 0;
  if (typeof paginationRemote === "number") {
    exit = 1;
  } else if (paginationRemote.tag) {
    tmp = null;
  } else {
    var match$1 = paginationRemote[0][0];
    var columns = match$1[0];
    var group = Belt_List.get(match$1[1], 0);
    if (group !== undefined) {
      var getter = function (param, param$1) {
        return Group$BsConsole.getAggregation(columns, group, param, param$1);
      };
      var bin = Group$BsConsole.unwrapTimestampBin2(getter("timestamp", /* Bin */2));
      if (bin !== undefined) {
        var match$2 = themeVariant === 0 ? /* tuple */[
            Colors$BsConsole.octothorpe(Colors$BsConsole.red),
            Colors$BsConsole.octothorpe(Colors$BsConsole.redDark)
          ] : /* tuple */[
            undefined,
            undefined
          ];
        var barHoverFill = match$2[1];
        var barFill = match$2[0];
        tmp = React.createElement(VX$BsConsole.Responsive.ParentSizeJsx3.make, {
              children: (function (parent) {
                  var tmp = {
                    key: JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query)),
                    variant: /* WithBrush */[onChange],
                    bins: 64,
                    range: Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query))),
                    height: parent.height | 0,
                    width: parent.width | 0,
                    hideXAxis: true,
                    hideYAxis: true,
                    marginLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    bin: bin
                  };
                  if (barFill !== undefined) {
                    tmp.barFill = Caml_option.valFromOption(barFill);
                  }
                  if (barHoverFill !== undefined) {
                    tmp.barHoverFill = Caml_option.valFromOption(barHoverFill);
                  }
                  return React.createElement(Charts__Bin$BsConsole.Jsx3.make, tmp);
                })
            });
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
  }
  if (exit === 1) {
    tmp = React.createElement(LinearProgress.default, { });
  }
  return React.createElement(Paper.default, {
              className: paper,
              elevation: BtPaper$BsConsole.elevation,
              children: null
            }, React.createElement(WildcardTimestampBin$ErrorBar, {
                  config: config,
                  remote: paginationRemote,
                  query: query,
                  aperture: aperture,
                  token: token,
                  projectName: projectName,
                  route: route,
                  handleChangeUrl: handleChangeUrl,
                  normBy: normBy,
                  stabilityScore: stabilityScore,
                  currentProject: currentProject
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.padding(Css.px(2)),
                        /* :: */[
                          Css.height(Css.px(82)),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, tmp));
}

function make$1(config, onChange, aperture, projectName, token, route, handleChangeUrl, normByOpt, stabilityScoreOpt, children) {
  var normBy = normByOpt !== undefined ? Caml_option.valFromOption(normByOpt) : undefined;
  var stabilityScore = stabilityScoreOpt !== undefined ? Caml_option.valFromOption(stabilityScoreOpt) : undefined;
  return ReasonReactCompat.wrapReactForReasonReact(WildcardTimestampBin, {
              config: config,
              onChange: onChange,
              aperture: aperture,
              projectName: projectName,
              token: token,
              route: route,
              handleChangeUrl: handleChangeUrl,
              normBy: normBy,
              stabilityScore: stabilityScore
            }, children);
}

var Re$1 = {
  make: make$1
};

var make$2 = WildcardTimestampBin;

exports.makeQuery = makeQuery;
exports.Styles = Styles;
exports.NormalizeBySelect = NormalizeBySelect;
exports.ErrorBar = ErrorBar;
exports.make = make$2;
exports.Re = Re$1;
/* paper Not a pure module */
