// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function Make(F) {
  var toJson = function (f) {
    if (f) {
      return [
              "custom",
              f[0]
            ];
    } else {
      return ["identity"];
    }
  };
  var fromJson = function (json) {
    var match = Json_decode.array(Json_decode.string, json);
    var len = match.length;
    if (len >= 3) {
      throw [
            Json_decode.DecodeError,
            "Failed to decode factor"
          ];
    }
    switch (len) {
      case 0 :
          throw [
                Json_decode.DecodeError,
                "Failed to decode factor"
              ];
      case 1 :
          var match$1 = match[0];
          if (match$1 === "identity") {
            return /* Identity */0;
          }
          throw [
                Json_decode.DecodeError,
                "Failed to decode factor"
              ];
      case 2 :
          var match$2 = match[0];
          if (match$2 === "custom") {
            var str = match[1];
            return /* Custom */[str];
          }
          throw [
                Json_decode.DecodeError,
                "Failed to decode factor"
              ];
      
    }
  };
  var toCrdb = function (f) {
    if (f) {
      return f[0];
    } else {
      return F.identity;
    }
  };
  var toCrdbString = function (f) {
    if (f) {
      return f[0];
    } else {
      return F.identity;
    }
  };
  var eq = Caml_obj.caml_equal;
  return {
          toJson: toJson,
          fromJson: fromJson,
          toCrdb: toCrdb,
          toCrdbString: toCrdbString,
          eq: eq
        };
}

exports.Make = Make;
/* No side effect */
