// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Regex$BsConsole = require("../Regex.js");

var isValidName = Regex$BsConsole.isExporterName;

function getStoreName(store) {
  var match = Belt_List.fromArray(store.id.split("-"));
  if (match) {
    return store.adapter + ("-" + match[0]);
  } else {
    return store.adapter;
  }
}

var servicename = "metrics-exporter";

exports.servicename = servicename;
exports.isValidName = isValidName;
exports.getStoreName = getStoreName;
/* Regex-BsConsole Not a pure module */
