// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BtTable$BsConsole = require("../BtTable.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var AlertsChart$BsConsole = require("./AlertsChart.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Select = require("@material-ui/core/Select");
var AlertsGroupInfo$BsConsole = require("./AlertsGroupInfo.js");
var AlertsTargetApi$BsConsole = require("./api/AlertsTargetApi.js");
var MenuItem = require("@material-ui/core/MenuItem");
var AlertsParseQuery$BsConsole = require("./AlertsParseQuery.js");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var AlertsParseGroups$BsConsole = require("./AlertsParseGroups.js");
var AlertsTimeOptions$BsConsole = require("./AlertsTimeOptions.js");
var AlertsAlertConfigApi$BsConsole = require("./api/AlertsAlertConfigApi.js");
var AlertsEvaluationsApi$BsConsole = require("./api/AlertsEvaluationsApi.js");
var AlertsNotificationApi$BsConsole = require("./api/AlertsNotificationApi.js");
var AlertsDetailViewConfig$BsConsole = require("./AlertsDetailViewConfig.js");
var AlertsMostImportantApi$BsConsole = require("./api/AlertsMostImportantApi.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var AlertsDetailViewErrorLog$BsConsole = require("./AlertsDetailViewErrorLog.js");

var thresholds = /* :: */[
  /* Warning */-685964740,
  /* :: */[
    /* Critical */141150399,
    /* [] */0
  ]
];

function getErrorMessage(err) {
  if (err[0] === Task2$BsConsole.UncaughtPromiseError) {
    return Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err[1][0]), (function (prim) {
                      return prim.message;
                    })), "Uncaught promise exception");
  } else if (err[0] === Task2$BsConsole.JsonDecodeError) {
    return Belt_Option.getWithDefault(err[1], "Json decode error");
  } else if (err[0] === Task2$BsConsole.CoronerError) {
    return err[1].message;
  } else if (err[0] === Task2$BsConsole.ErrorMsg) {
    return err[1];
  } else {
    return "Unknown error";
  }
}

function getMaxEvaluation(evals) {
  var firstEval = Belt_Array.get(evals, 0);
  return Belt_Array.reduce(evals, firstEval, (function (acc, next) {
                if (acc !== undefined && !(acc.criticalTriggerCount > 0 && next.criticalTriggerCount > acc.criticalTriggerCount || acc.criticalTriggerCount === 0 && next.criticalTriggerCount > 0 || acc.criticalTriggerCount === 0 && next.warningTriggerCount > acc.warningTriggerCount)) {
                  return acc;
                } else {
                  return next;
                }
              }));
}

var oneDayMs = 86400 * 1000;

function AlertsDetailView(Props) {
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var alertConfigs = Props.alertConfigs;
  var fetchAlertConfigs = Props.fetchAlertConfigs;
  var alert = Props.alert;
  var token = Props.token;
  var endpoint = Props.endpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var today = new Date(Date.now());
  var endOfToday = today.setHours(23.0, 59.0, 59.0, 999.0);
  var endOfTodayUnixSeconds = endOfToday / 1000;
  var match = React.useState((function () {
          
        }));
  var setAlertFullDetails = match[1];
  var alertFullDetails = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setTargets = match$1[1];
  var match$2 = React.useState((function () {
          return [];
        }));
  var setNotifications = match$2[1];
  var notifications = match$2[0];
  var match$3 = React.useState((function () {
          return [];
        }));
  var setEvalsForNotifications = match$3[1];
  var evalsForNotifications = match$3[0];
  var match$4 = React.useState((function () {
          return /* FourteenDays */1;
        }));
  var setSelectedTimeOption = match$4[1];
  var selectedTimeOption = match$4[0];
  var match$5 = React.useState((function () {
          return 0.0;
        }));
  var setTimelineStartTime = match$5[1];
  var match$6 = React.useState((function () {
          return false;
        }));
  var setTimelineChartError = match$6[1];
  var match$7 = React.useState((function () {
          return true;
        }));
  var setFetchingData = match$7[1];
  var match$8 = React.useState((function () {
          return [];
        }));
  var setDatapoints = match$8[1];
  var fetchAlertConfigFull = function (param) {
    Curry._1(fetchAlertConfigs, undefined);
    var arg = AlertsAlertConfigApi$BsConsole.getById(endpoint, universeName, projectName, alert.id, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setAlertFullDetails, (function (param) {
                                return result;
                              }));
                }));
  };
  var fetchNotifications = function (param) {
    var arg = AlertsNotificationApi$BsConsole.getByAlertId(endpoint, universeName, projectName, alert.id, 50, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setNotifications, (function (param) {
                                return result.values;
                              }));
                }));
  };
  React.useEffect((function () {
          fetchNotifications(undefined);
          fetchAlertConfigFull(undefined);
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        alert.id
      ]);
  React.useEffect((function () {
          if (alertFullDetails !== undefined) {
            var targetPromises = Belt_List.toArray(Belt_List.map(alertFullDetails.targets, (function (targetId) {
                        return new Promise((function (resolve, param) {
                                      var arg = AlertsTargetApi$BsConsole.getById(endpoint, universeName, projectName, targetId, undefined);
                                      return Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), undefined, (function (resp) {
                                                    if (!resp.tag) {
                                                      return resolve(resp[0]);
                                                    }
                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                                                    return resolve(undefined);
                                                  }));
                                    }));
                      })));
            var __x = Promise.all(targetPromises);
            var __x$1 = __x.then((function (results) {
                    var successfulResults = Belt_Array.keepMap(results, (function (result) {
                            return result;
                          }));
                    Curry._1(setTargets, (function (param) {
                            return successfulResults;
                          }));
                    return Promise.resolve(undefined);
                  }));
            __x$1.catch((function (exn) {
                    if (exn[0] === Task2$BsConsole.TaskError) {
                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, exn[1])));
                    }
                    return Promise.resolve(undefined);
                  }));
          }
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        alertFullDetails
      ]);
  React.useEffect((function () {
          var promises = Belt_Array.map(notifications, (function (notification) {
                  return new Promise((function (resolve, param) {
                                var arg = AlertsEvaluationsApi$BsConsole.getById(endpoint, universeName, projectName, notification.alertEvaluationId, undefined);
                                return Curry._2((function (param) {
                                                return (function (param$1, param$2) {
                                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                  });
                                              })(token), undefined, (function (resp) {
                                              return resolve(resp);
                                            }));
                              }));
                }));
          var __x = Promise.all(promises);
          var __x$1 = __x.then((function (results) {
                  Curry._1(setEvalsForNotifications, (function (param) {
                          return results;
                        }));
                  return Promise.resolve(undefined);
                }));
          __x$1.catch((function (exn) {
                  if (exn[0] === Task2$BsConsole.TaskError) {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, exn[1])));
                  }
                  return Promise.resolve(undefined);
                }));
          
        }), [notifications]);
  React.useEffect((function () {
          var numberOfDays = AlertsTimeOptions$BsConsole.toNumber(selectedTimeOption);
          var startTimestamp = endOfTodayUnixSeconds - Caml_int32.imul(numberOfDays, 86400);
          Curry._1(setFetchingData, (function (param) {
                  return true;
                }));
          Curry._1(setTimelineStartTime, (function (param) {
                  return startTimestamp;
                }));
          var arg = AlertsMostImportantApi$BsConsole.get(endpoint, universeName, projectName, alert.id, startTimestamp | 0, endOfTodayUnixSeconds | 0, 86400, undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                    Curry._1(setFetchingData, (function (param) {
                            return false;
                          }));
                    return Curry._1(setTimelineChartError, (function (param) {
                                  return true;
                                }));
                  }
                  var dps = Belt_Array.map(resp[0].buckets, (function (bucket) {
                          var $$eval = bucket.evaluation;
                          if ($$eval !== undefined) {
                            if ($$eval.criticalTriggerCount > 0) {
                              return /* tuple */[
                                      bucket.startTime,
                                      bucket.endTime,
                                      $$eval.criticalTriggerCount,
                                      /* Critical */1,
                                      bucket.evaluation
                                    ];
                            } else {
                              return /* tuple */[
                                      bucket.startTime,
                                      bucket.endTime,
                                      $$eval.warningTriggerCount,
                                      /* Warning */0,
                                      bucket.evaluation
                                    ];
                            }
                          } else {
                            return /* tuple */[
                                    bucket.startTime,
                                    bucket.endTime,
                                    0,
                                    /* Warning */0,
                                    undefined
                                  ];
                          }
                        }));
                  Curry._1(setDatapoints, (function (param) {
                          return dps;
                        }));
                  return Curry._1(setFetchingData, (function (param) {
                                return false;
                              }));
                }));
          
        }), /* tuple */[
        token,
        universeName,
        projectName,
        alert.id,
        selectedTimeOption
      ]);
  var parsedQuery = AlertsParseQuery$BsConsole.parseQuery(alertFullDetails);
  var groupBy;
  if (parsedQuery !== undefined) {
    var factor$prime = parsedQuery.factor;
    groupBy = factor$prime ? factor$prime[0] : "fingerprint";
  } else {
    groupBy = "none";
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(AlertsDetailViewConfig$BsConsole.make, {
                      config: config,
                      handleChangeUrl: handleChangeUrl,
                      alertConfigs: alertConfigs,
                      parsedQuery: parsedQuery,
                      alert: alert,
                      alertFullDetails: alertFullDetails,
                      thresholds: thresholds,
                      targets: match$1[0],
                      endpoint: endpoint,
                      projectName: projectName,
                      universeName: universeName,
                      token: token,
                      refetchAlert: (function (param) {
                          return fetchAlertConfigFull(undefined);
                        })
                    }), React.createElement(Paper.default, {
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.padding(Css.px(24)),
                              /* :: */[
                                Css.marginBottom(Css.px(24)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                  /* :: */[
                                    Css.borderRadius(Css.px(4)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      elevation: 0,
                      children: null
                    }, React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Button */10,
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.px(5)),
                                    /* :: */[
                                      Css.marginRight(Css.px(5)),
                                      /* :: */[
                                        Css.height(Css.px(12)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Timeline")
                            }), React.createElement(Select.default, {
                              id: "select-timeframe",
                              value: selectedTimeOption,
                              disabled: false,
                              onChange: (function ($$event, param) {
                                  var value = $$event.target.value;
                                  return Curry._1(setSelectedTimeOption, (function (param) {
                                                return value;
                                              }));
                                }),
                              renderValue: (function (v) {
                                  return React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.paddingLeft(Css.px(10)),
                                                    /* [] */0
                                                  ])
                                            }, React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Caption */11,
                                                  children: I18N$BsConsole.showSkip(AlertsTimeOptions$BsConsole.toString(v))
                                                }));
                                }),
                              disableUnderline: true,
                              classes: {
                                root: AlertsUtils$BsConsole.selectStyle
                              },
                              children: Belt_Array.map(AlertsTimeOptions$BsConsole.options, (function (v) {
                                      return React.createElement(MenuItem.default, {
                                                  value: v,
                                                  children: React.createElement(BtTypography$BsConsole.make, {
                                                        variant: /* Caption */11,
                                                        children: I18N$BsConsole.showSkip(AlertsTimeOptions$BsConsole.toString(v))
                                                      })
                                                });
                                    }))
                            })), match$7[0] ? React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.flexDirection(/* column */-963948842),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                className: Css.style(/* :: */[
                                      Css.paddingTop(Css.px(12)),
                                      /* :: */[
                                        Css.paddingBottom(Css.px(12)),
                                        /* [] */0
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Fetching timeline. This can take a while.")
                              }), React.createElement(CircularProgress.default, { })) : (
                        alertFullDetails !== undefined ? (
                            match$6[0] ? React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.display(/* flex */-1010954439),
                                          /* :: */[
                                            Css.flexDirection(/* column */-963948842),
                                            /* :: */[
                                              Css.alignItems(/* center */98248149),
                                              /* [] */0
                                            ]
                                          ]
                                        ])
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.paddingTop(Css.px(12)),
                                              /* :: */[
                                                Css.paddingBottom(Css.px(12)),
                                                /* [] */0
                                              ]
                                            ]),
                                        children: I18N$BsConsole.show(undefined, "Error - unable to render timeline")
                                      })) : React.createElement(AlertsChart$BsConsole.make, {
                                    data: match$8[0],
                                    startTimestamp: match$5[0],
                                    endTimestamp: endOfTodayUnixSeconds,
                                    timeOption: selectedTimeOption
                                  })
                          ) : React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.flexDirection(/* column */-963948842),
                                        /* :: */[
                                          Css.alignItems(/* center */98248149),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body1 */8,
                                    className: Css.style(/* :: */[
                                          Css.paddingTop(Css.px(12)),
                                          /* :: */[
                                            Css.paddingBottom(Css.px(12)),
                                            /* [] */0
                                          ]
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "No alert detail data")
                                  }))
                      ))), React.createElement(Paper.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.padding(Css.px(24)),
                            /* :: */[
                              Css.marginBottom(Css.px(24)),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Button */10,
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.px(5)),
                            /* :: */[
                              Css.marginRight(Css.px(5)),
                              /* :: */[
                                Css.height(Css.px(12)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      color: Colors$BsConsole.grey0,
                      children: I18N$BsConsole.show(undefined, "Activity feed")
                    }), React.createElement(BtTableContainer$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]),
                      children: React.createElement(BtTable$BsConsole.make, {
                            children: null
                          }, React.createElement(BtTableHead$BsConsole.make, {
                                children: React.createElement(BtTableRow$BsConsole.make, {
                                      className: Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* [] */0
                                          ]),
                                      children: null
                                    }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(180)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.show(undefined, "Time")
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(120)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.show(undefined, "Type")
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(285)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.show(undefined, "Example groups")
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.show(undefined, "Group details")
                                        }))
                              }), React.createElement(BtTableBody$BsConsole.make, {
                                children: notifications.length === 0 ? React.createElement(BtTableRow$BsConsole.make, {
                                        children: React.createElement(BtTableCell$BsConsole.make, {
                                              children: I18N$BsConsole.show(undefined, "No activity")
                                            })
                                      }) : Belt_Array.mapWithIndex(notifications, (function (index, curr) {
                                          var time = new Date(curr.createdAt * 1000);
                                          var match = Belt_Array.get(evalsForNotifications, index);
                                          var $$eval;
                                          if (match !== undefined) {
                                            if (match.tag) {
                                              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "AlertsDetailView/eval", Backtrace$BsConsole.Attributes.make(undefined));
                                              Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                    288368849,
                                                    getErrorMessage(match[0])
                                                  ]);
                                              $$eval = undefined;
                                            } else {
                                              $$eval = match[0];
                                            }
                                          } else {
                                            $$eval = undefined;
                                          }
                                          var evalStart = $$eval !== undefined ? $$eval.evaluationStart : 0;
                                          var evalEnd = $$eval !== undefined ? $$eval.evaluationEnd : 0;
                                          var groups;
                                          if ($$eval !== undefined) {
                                            var parsedGroups = AlertsParseGroups$BsConsole.parseGroup($$eval.exampleGroups);
                                            groups = parsedGroups !== undefined ? parsedGroups : [];
                                          } else {
                                            groups = [];
                                          }
                                          return React.createElement(BtTableRow$BsConsole.make, {
                                                      children: null,
                                                      key: curr.uuid
                                                    }, React.createElement(BtTableCell$BsConsole.make, {
                                                          children: I18N$BsConsole.showSkip(time.toLocaleDateString() + (" " + time.toLocaleTimeString()))
                                                        }), React.createElement(BtTableCell$BsConsole.make, {
                                                          children: I18N$BsConsole.showSkip(curr.criticalTriggerCount > 0 ? I18N$BsConsole.get(undefined, "Critical") : I18N$BsConsole.get(undefined, "Warning"))
                                                        }), React.createElement(React.Fragment, undefined, React.createElement(BtTableCell$BsConsole.make, {
                                                              children: React.createElement(Row2$BsConsole.make, {
                                                                    children: null
                                                                  }, React.createElement(Col2$BsConsole.make, {
                                                                        children: null
                                                                      }, I18N$BsConsole.dynamic(Util$BsConsole.pluralize("group", groups.length, undefined, true, undefined)), I18N$BsConsole.showSkip(": ")), React.createElement(Col2$BsConsole.make, {
                                                                        className: Css.style(/* :: */[
                                                                              Css.whiteSpace(Css.nowrap),
                                                                              /* :: */[
                                                                                Css.width(Css.pct(75)),
                                                                                /* :: */[
                                                                                  Css.marginLeft(Css.px(10)),
                                                                                  /* :: */[
                                                                                    Css.marginRight(Css.px(10)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]),
                                                                        children: Belt_Array.map(groups, (function (group) {
                                                                                var pathToGroupMaybe;
                                                                                if (parsedQuery !== undefined) {
                                                                                  var havings = AlertsUtils$BsConsole.getHavingsFromHavingsArr(parsedQuery.havingsArr);
                                                                                  var sort = Crdb$BsConsole.Sort.defaultAggregate;
                                                                                  var newFold = AlertsUtils$BsConsole.foldWithoutErrorCountAgg(parsedQuery.fold);
                                                                                  var __x = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                                                              /* Floating */0,
                                                                                              /* All */6
                                                                                            ])), Crdb$BsConsole.Aperture.$$default);
                                                                                  var __x$1 = parsedQuery.filter;
                                                                                  var __x$2 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                                                            "timestamp.received",
                                                                                            /* AtLeast */Block.__(8, [/* `Float */[
                                                                                                  365180284,
                                                                                                  evalStart
                                                                                                ]])
                                                                                          ]), __x$1);
                                                                                  var __x$3 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                                                            "timestamp.received",
                                                                                            /* AtMost */Block.__(9, [/* `Float */[
                                                                                                  365180284,
                                                                                                  evalEnd
                                                                                                ]])
                                                                                          ]), __x$2);
                                                                                  pathToGroupMaybe = Route$BsConsole.pathForRoute(Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                                                                            Curry._1(Crdb$BsConsole.Factor.toCrdbString, parsedQuery.factor),
                                                                                                            Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                                                                                                  "regular-expression",
                                                                                                                  group.key
                                                                                                                ])
                                                                                                          ]), __x$3), __x)), Caml_option.some(havings), parsedQuery.factor, Caml_option.some(newFold), sort, Caml_option.some(undefined), undefined, undefined));
                                                                                } else {
                                                                                  pathToGroupMaybe = undefined;
                                                                                }
                                                                                var any = group.key;
                                                                                var tmp = any === "*" ? "all" : any;
                                                                                return React.createElement(Row2$BsConsole.make, {
                                                                                            children: null
                                                                                          }, React.createElement(AlertsUtils$BsConsole.FrontEllipsis.make, {
                                                                                                text: tmp + " ",
                                                                                                widthValue: Css.px(150)
                                                                                              }), pathToGroupMaybe !== undefined ? React.createElement("div", undefined, React.createElement("a", {
                                                                                                      className: Css.style(/* :: */[
                                                                                                            Css.textDecoration(/* none */-922086728),
                                                                                                            /* [] */0
                                                                                                          ]),
                                                                                                      href: pathToGroupMaybe,
                                                                                                      onClick: (function (e) {
                                                                                                          e.preventDefault();
                                                                                                          return Util$BsConsole.openUrl(pathToGroupMaybe);
                                                                                                        })
                                                                                                    }, I18N$BsConsole.showSkip("(" + (I18N$BsConsole.get(undefined, "view") + ")")))) : null);
                                                                              }))
                                                                      }))
                                                            }), React.createElement(BtTableCell$BsConsole.make, {
                                                              children: Belt_Array.map(groups, (function (group) {
                                                                      return React.createElement(AlertsGroupInfo$BsConsole.make, {
                                                                                  group: group,
                                                                                  groupBy: groupBy
                                                                                });
                                                                    }))
                                                            })));
                                        }))
                              }))
                    })), React.createElement(AlertsDetailViewErrorLog$BsConsole.make, {
                  alert: alert,
                  token: token,
                  endpoint: endpoint,
                  universeName: universeName,
                  projectName: projectName
                }));
}

var oneDaySeconds = 86400;

var make = AlertsDetailView;

exports.thresholds = thresholds;
exports.getErrorMessage = getErrorMessage;
exports.getMaxEvaluation = getMaxEvaluation;
exports.oneDaySeconds = oneDaySeconds;
exports.oneDayMs = oneDayMs;
exports.make = make;
/* Css Not a pure module */
