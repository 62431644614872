// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Util$BsConsole = require("../util.js");
var WfInt$BsConsole = require("./WfInt.js");
var WfBool$BsConsole = require("./WfBool.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfEnum$BsConsole = require("./WfEnum.js");
var WfFloat$BsConsole = require("./WfFloat.js");
var WfString$BsConsole = require("./WfString.js");
var WfFormCell$BsConsole = require("./WfFormCell.js");
var Belt_MutableMapString = require("bs-platform/lib/js/belt_MutableMapString.js");

var component = ReasonReact.reducerComponent("WfObject-BsConsole");

function make(btDefaults, token, rootObjectOpt, fieldDoc, initialValue, remoteAction, onValueChange, showAllErrors, schema, makeWfArray, makeWfObject, param) {
  var rootObject = rootObjectOpt !== undefined ? rootObjectOpt : false;
  var setElementRef = function (nullableRef, param) {
    param.state.elementRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
    
  };
  var setRemoteAction = function (param, param$1) {
    return Belt_MutableMapString.set(param$1.state.remoteActions, param[0], param[1]);
  };
  var sendNewValue = function (param) {
    var state = param.state;
    return Curry._1(onValueChange, Belt_Result.map(Belt_Array.reduce(Belt_Array.keepMap(schema.fields, (function (field) {
                              var value = Belt_MapString.get(state.childValues, field.name);
                              if (value !== undefined) {
                                return /* tuple */[
                                        field.name,
                                        value
                                      ];
                              }
                              
                            })), /* Ok */Block.__(0, [[]]), (function (acc, param) {
                          var v = param[1];
                          if (acc.tag) {
                            return /* Error */Block.__(1, [acc[0]]);
                          }
                          if (!v.tag) {
                            return /* Ok */Block.__(0, [Belt_Array.concat(acc[0], [/* tuple */[
                                              param[0],
                                              v[0]
                                            ]])]);
                          }
                          var match = v[0];
                          return /* Error */Block.__(1, [/* tuple */[
                                      match[0],
                                      match[1]
                                    ]]);
                        })), Js_dict.fromArray));
  };
  var initialValues;
  try {
    initialValues = Belt_Option.map(initialValue, (function (param) {
            return Json_decode.dict(Util$BsConsole.identity, param);
          }));
  }
  catch (exn){
    initialValues = undefined;
  }
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (param) {
              var state = param.state;
              return Curry._1(remoteAction, (function (param) {
                            if (param.tag) {
                              var match = param[0];
                              if (match) {
                                var key = match[0];
                                if (key.tag) {
                                  var action = Belt_MutableMapString.get(state.remoteActions, key[0]);
                                  if (action !== undefined) {
                                    return Curry._1(action, /* ScrollTo */Block.__(1, [match[1]]));
                                  } else {
                                    return ;
                                  }
                                }
                                
                              }
                              var element = state.elementRef.contents;
                              if (element !== undefined) {
                                return WfCore$BsConsole.scrollToDomElement(Caml_option.valFromOption(element));
                              } else {
                                return ;
                              }
                            }
                            var match$1 = param[0];
                            if (!match$1) {
                              return ;
                            }
                            var key$1 = match$1[0];
                            if (!key$1.tag) {
                              return ;
                            }
                            var action$1 = Belt_MutableMapString.get(state.remoteActions, key$1[0]);
                            if (action$1 !== undefined) {
                              return Curry._1(action$1, /* Focus */Block.__(0, [match$1[1]]));
                            }
                            
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var handle = param.handle;
              return React.createElement("div", {
                          ref: Curry._1(handle, setElementRef)
                        }, ReasonReact.element(undefined, undefined, WfFormCell$BsConsole.make(fieldDoc, undefined, rootObject ? "0" : undefined, undefined, undefined, true, showAllErrors, [React.createElement("div", { }, Belt_Array.map(schema.fields, (function (field) {
                                              var onScalarValueChange = function (value) {
                                                var value$1;
                                                value$1 = value.tag ? /* Error */Block.__(1, [/* tuple */[
                                                        /* :: */[
                                                          /* Key */Block.__(1, [field.name]),
                                                          /* [] */0
                                                        ],
                                                        value[0]
                                                      ]]) : /* Ok */Block.__(0, [value[0]]);
                                                return Curry._1(send, /* StoreChildValue */[
                                                            field.name,
                                                            value$1
                                                          ]);
                                              };
                                              var onCompoundValueChange = function (value) {
                                                var value$1;
                                                if (value.tag) {
                                                  var match = value[0];
                                                  value$1 = /* Error */Block.__(1, [/* tuple */[
                                                        /* :: */[
                                                          /* Key */Block.__(1, [field.name]),
                                                          match[0]
                                                        ],
                                                        match[1]
                                                      ]]);
                                                } else {
                                                  value$1 = /* Ok */Block.__(0, [value[0]]);
                                                }
                                                return Curry._1(send, /* StoreChildValue */[
                                                            field.name,
                                                            value$1
                                                          ]);
                                              };
                                              var formField = field.outputType;
                                              var fieldDoc = formField.doc;
                                              var remoteAction = function (remoteAction$1) {
                                                return Curry._2(handle, setRemoteAction, /* tuple */[
                                                            field.name,
                                                            remoteAction$1
                                                          ]);
                                              };
                                              var initialValue = Belt_Option.flatMap(initialValues, (function (__x) {
                                                      return Js_dict.get(__x, field.name);
                                                    }));
                                              var schema = formField.schema;
                                              switch (schema.tag | 0) {
                                                case /* Int */0 :
                                                    return ReasonReact.element(field.name, undefined, WfInt$BsConsole.make(undefined, fieldDoc, initialValue, onScalarValueChange, schema[0], showAllErrors, remoteAction, token, []));
                                                case /* Float */1 :
                                                    return ReasonReact.element(field.name, undefined, WfFloat$BsConsole.make(fieldDoc, initialValue, undefined, onScalarValueChange, schema[0], showAllErrors, remoteAction, []));
                                                case /* Bool */2 :
                                                    return ReasonReact.element(field.name, undefined, WfBool$BsConsole.make(fieldDoc, initialValue, onScalarValueChange, showAllErrors, schema[0], undefined, remoteAction, []));
                                                case /* Enum */3 :
                                                    return ReasonReact.element(field.name, undefined, WfEnum$BsConsole.make(fieldDoc, initialValue, undefined, onScalarValueChange, showAllErrors, schema[0], remoteAction, []));
                                                case /* Array */5 :
                                                    return ReasonReact.element(field.name, undefined, Curry.app(makeWfArray, [
                                                                    btDefaults,
                                                                    token,
                                                                    fieldDoc,
                                                                    initialValue,
                                                                    onCompoundValueChange,
                                                                    schema[0],
                                                                    showAllErrors,
                                                                    remoteAction,
                                                                    []
                                                                  ]));
                                                case /* Object */6 :
                                                    return ReasonReact.element(field.name, undefined, Curry.app(makeWfObject, [
                                                                    btDefaults,
                                                                    token,
                                                                    undefined,
                                                                    fieldDoc,
                                                                    initialValue,
                                                                    remoteAction,
                                                                    onCompoundValueChange,
                                                                    showAllErrors,
                                                                    schema[0],
                                                                    []
                                                                  ]));
                                                case /* String */4 :
                                                case /* CustomField */7 :
                                                    break;
                                                
                                              }
                                              return ReasonReact.element(field.name, undefined, WfString$BsConsole.make(btDefaults, fieldDoc, initialValue, undefined, onScalarValueChange, showAllErrors, schema[0], remoteAction, []));
                                            })))])));
            }),
          initialState: (function (param) {
              return {
                      childValues: undefined,
                      remoteActions: Belt_MutableMapString.make(undefined),
                      elementRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          childValues: Belt_MapString.set(state.childValues, action[0], action[1]),
                          remoteActions: state.remoteActions,
                          elementRef: state.elementRef
                        },
                        sendNewValue
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
