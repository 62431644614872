// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("./I18N.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

var ErrorMsg = Caml_exceptions.create("ApiStrategies-BsConsole.ErrorMsg");

var ParseError = Caml_exceptions.create("ApiStrategies-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("ApiStrategies-BsConsole.ServiceError");

var UnknownSvcError = Caml_exceptions.create("ApiStrategies-BsConsole.UnknownSvcError");

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          loginUrl: Json_decode.field("loginUrl", Json_decode.string, json),
          strategy: Json_decode.field("strategy", Json_decode.string, json),
          isDefault: Json_decode.field("isDefault", Json_decode.bool, json),
          sourceHostname: Json_decode.field("sourceHostname", Json_decode.string, json),
          absoluteLoginUrl: Json_decode.field("absoluteLoginUrl", Json_decode.string, json)
        };
}

var LoginStrategies = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return Json_decode.array(fromJson, json);
}

var Success = {
  fromJson: fromJson$1
};

function queryLoginStrategiesTask(endpoint, host) {
  var url = endpoint + ("/strategies/login?hostname=" + host);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                var json = param.json;
                try {
                  if (param.status >= 400) {
                    var response = ApiWf$BsConsole.$$Error.fromJson(json);
                    return /* Error */Block.__(1, [[
                                ApiWf$BsConsole.ServiceError,
                                response.error
                              ]]);
                  }
                  var response$1 = Json_decode.array(fromJson, json);
                  if (response$1.length !== 0) {
                    return /* Ok */Block.__(0, [response$1]);
                  } else {
                    return /* Error */Block.__(1, [[
                                ErrorMsg,
                                I18N$BsConsole.get(undefined, "No SSOs have been configured")
                              ]]);
                  }
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "apiLogin/QueryLoginStrategies/queryLoginStrategiesTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ApiWf$BsConsole.ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

var QueryLoginStrategies = {
  Success: Success,
  queryLoginStrategiesTask: queryLoginStrategiesTask
};

function typeToString(param) {
  if (param !== 486188135) {
    if (param >= 486336426) {
      return "sha512";
    } else {
      return "sha1";
    }
  } else {
    return "sha256";
  }
}

function stringToType(param) {
  switch (param) {
    case "sha1" :
        return /* sha1 */-866989947;
    case "sha256" :
        return /* sha256 */486188135;
    case "sha512" :
        return /* sha512 */486336426;
    default:
      return /* sha1 */-866989947;
  }
}

var SignatureAlgorithm = {
  typeToString: typeToString,
  stringToType: stringToType
};

function decode(json) {
  var signature = Json_decode.optional((function (param) {
          return Json_decode.field("signatureAlgorithm", Json_decode.string, param);
        }), json);
  return {
          issuer: Json_decode.field("issuer", Json_decode.string, json),
          entryPoint: Json_decode.field("entryPoint", Json_decode.string, json),
          callbackUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("callbackUrl", Json_decode.string, param);
                }), json),
          cert: Json_decode.optional((function (param) {
                  return Json_decode.field("cert", Json_decode.string, param);
                }), json),
          adminContact: Json_decode.optional((function (param) {
                  return Json_decode.field("adminContact", Json_decode.string, param);
                }), json),
          decryptionKey: Json_decode.optional((function (param) {
                  return Json_decode.field("decryptionKey", Json_decode.string, param);
                }), json),
          privateKey: Json_decode.optional((function (param) {
                  return Json_decode.field("privateKey", Json_decode.string, param);
                }), json),
          signatureAlgorithm: signature !== undefined ? stringToType(signature) : undefined
        };
}

function encode(options) {
  var signatureAlgorithm = options.signatureAlgorithm;
  return Json_encode.object_(Belt_List.map(Belt_List.keepMap(/* :: */[
                      /* tuple */[
                        "callbackUrl",
                        options.callbackUrl
                      ],
                      /* :: */[
                        /* tuple */[
                          "issuer",
                          options.issuer
                        ],
                        /* :: */[
                          /* tuple */[
                            "entryPoint",
                            options.entryPoint
                          ],
                          /* :: */[
                            /* tuple */[
                              "cert",
                              options.cert
                            ],
                            /* :: */[
                              /* tuple */[
                                "adminContact",
                                options.adminContact
                              ],
                              /* :: */[
                                /* tuple */[
                                  "decryptionKey",
                                  options.decryptionKey
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "privateKey",
                                    options.privateKey
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "signatureAlgorithm",
                                      signatureAlgorithm !== undefined ? typeToString(signatureAlgorithm) : undefined
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ], (function (param) {
                        var v = param[1];
                        if (v !== undefined) {
                          return /* tuple */[
                                  param[0],
                                  v
                                ];
                        }
                        
                      })), (function (param) {
                    return /* tuple */[
                            param[0],
                            param[1]
                          ];
                  })));
}

var Options = {
  SignatureAlgorithm: SignatureAlgorithm,
  decode: decode,
  encode: encode
};

function decode$1(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.string, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          hostname: Json_decode.field("hostname", Json_decode.string, json),
          strategy: Json_decode.field("strategy", Json_decode.string, json),
          options: Json_decode.field("options", decode, json),
          isDefault: Json_decode.field("isDefault", Json_decode.bool, json),
          userProvisioning: Json_decode.field("userProvisioning", Json_decode.bool, json)
        };
}

function encode$1(strategy) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                strategy.name
              ],
              /* :: */[
                /* tuple */[
                  "hostname",
                  strategy.hostname
                ],
                /* :: */[
                  /* tuple */[
                    "strategy",
                    strategy.strategy
                  ],
                  /* :: */[
                    /* tuple */[
                      "userProvisioning",
                      strategy.userProvisioning
                    ],
                    /* :: */[
                      /* tuple */[
                        "options",
                        encode(strategy.options)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var Strategies = {
  Options: Options,
  decode: decode$1,
  encode: encode$1
};

function decodeSuccess(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          hostname: Json_decode.field("hostname", Json_decode.string, json),
          isDefault: Json_decode.field("isDefault", Json_decode.bool, json),
          name: Json_decode.field("name", Json_decode.string, json),
          strategy: Json_decode.field("strategy", Json_decode.string, json),
          userProvisioning: Json_decode.field("userProvisioning", Json_decode.bool, json),
          options: Json_decode.field("options", decode, json),
          createdAt: Json_decode.field("createdAt", Json_decode.string, json),
          updatedAt: Json_decode.field("updatedAt", Json_decode.string, json),
          isReadonly: Json_decode.field("isReadonly", Json_decode.bool, json)
        };
}

function decodeError(json) {
  return {
          statusCode: Json_decode.field("statusCode", Json_decode.$$int, json),
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

function getDefault(arr) {
  return Belt_Array.getBy(arr, (function (strategy) {
                return strategy.isDefault;
              }));
}

function decode$2(json) {
  var arr = Json_decode.array(decodeSuccess, json);
  return Belt_Array.getBy(arr, (function (strategy) {
                return strategy.isDefault;
              }));
}

function responseToStrategy(response) {
  return {
          id: response.id,
          name: response.name,
          hostname: response.hostname,
          strategy: response.strategy,
          options: response.options,
          isDefault: response.isDefault,
          userProvisioning: response.userProvisioning
        };
}

var $$Response = {
  decodeSuccess: decodeSuccess,
  decodeError: decodeError,
  getDefault: getDefault,
  decode: decode$2,
  responseToStrategy: responseToStrategy
};

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === Task2$BsConsole.ErrorMsg ? "Error: " + error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function queryStrategiesTask(serviceEndpoint, token) {
  var task = Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + "/strategies"]), undefined, /* Get */0, [/* tuple */[
          "X-Coroner-Location",
          location.origin
        ]], (function (param) {
          var json = param.json;
          try {
            if (param.status >= 400) {
              var responseError = decodeError(json);
              return /* Error */Block.__(1, [[
                          ErrorMsg,
                          responseError.message
                        ]]);
            }
            var strategy = decode$2(json);
            return /* Ok */Block.__(0, [strategy]);
          }
          catch (exn){
            return /* Error */Block.__(1, [UnknownSvcError]);
          }
        }), undefined, undefined);
  return BpgTask$BsConsole.use(token, task);
}

var QueryStrategies = {
  responseCb: responseCb,
  queryStrategiesTask: queryStrategiesTask
};

function encodePayload(payload) {
  var signatureAlgorithm = payload.options.signatureAlgorithm;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                payload.name
              ],
              /* :: */[
                /* tuple */[
                  "hostname",
                  payload.hostname
                ],
                /* :: */[
                  /* tuple */[
                    "strategy",
                    payload.strategy
                  ],
                  /* :: */[
                    /* tuple */[
                      "isDefault",
                      payload.isDefault
                    ],
                    /* :: */[
                      /* tuple */[
                        "userProvisioning",
                        payload.userProvisioning
                      ],
                      /* :: */[
                        /* tuple */[
                          "options",
                          Json_encode.object_(Belt_List.map(Belt_List.keepMap(/* :: */[
                                        /* tuple */[
                                          "callbackUrl",
                                          payload.options.callbackUrl
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "issuer",
                                            payload.options.issuer
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "entryPoint",
                                              payload.options.entryPoint
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "cert",
                                                payload.options.cert
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "adminContact",
                                                  payload.options.adminContact
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "decryptionKey",
                                                    payload.options.decryptionKey
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      "privateKey",
                                                      payload.options.privateKey
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        "signatureAlgorithm",
                                                        signatureAlgorithm !== undefined ? typeToString(signatureAlgorithm) : undefined
                                                      ],
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ], (function (param) {
                                          var v = param[1];
                                          if (v !== undefined) {
                                            return /* tuple */[
                                                    param[0],
                                                    v
                                                  ];
                                          }
                                          
                                        })), (function (param) {
                                      return /* tuple */[
                                              param[0],
                                              param[1]
                                            ];
                                    })))
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function createStrategyTask(serviceEndpoint, payload) {
  var url = serviceEndpoint + "/strategies/";
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [encodePayload(payload)]), [/* tuple */[
                    "X-Coroner-Location",
                    location.origin
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = decodeSuccess(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiStrategies/CreateStrategy.createStrategyTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var CreateStrategy = {
  encodePayload: encodePayload,
  createStrategyTask: createStrategyTask
};

function encodePayload$1(payload) {
  var signatureAlgorithm = payload.options.signatureAlgorithm;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                payload.name
              ],
              /* :: */[
                /* tuple */[
                  "hostname",
                  payload.hostname
                ],
                /* :: */[
                  /* tuple */[
                    "isDefault",
                    payload.isDefault
                  ],
                  /* :: */[
                    /* tuple */[
                      "userProvisioning",
                      payload.userProvisioning
                    ],
                    /* :: */[
                      /* tuple */[
                        "options",
                        Json_encode.object_(Belt_List.map(Belt_List.keepMap(/* :: */[
                                      /* tuple */[
                                        "callbackUrl",
                                        payload.options.callbackUrl
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "issuer",
                                          payload.options.issuer
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "entryPoint",
                                            payload.options.entryPoint
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "cert",
                                              payload.options.cert
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "adminContact",
                                                payload.options.adminContact
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "decryptionKey",
                                                  payload.options.decryptionKey
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "privateKey",
                                                    payload.options.privateKey
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      "signatureAlgorithm",
                                                      signatureAlgorithm !== undefined ? typeToString(signatureAlgorithm) : undefined
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ], (function (param) {
                                        var v = param[1];
                                        if (v !== undefined) {
                                          return /* tuple */[
                                                  param[0],
                                                  v
                                                ];
                                        }
                                        
                                      })), (function (param) {
                                    return /* tuple */[
                                            param[0],
                                            param[1]
                                          ];
                                  })))
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function editStrategyTask(serviceEndpoint, payload, id) {
  if (id === undefined) {
    return ;
  }
  var url = serviceEndpoint + ("/strategies/" + id);
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [encodePayload$1(payload)]), [/* tuple */[
                    "X-Coroner-Location",
                    location.origin
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = decodeSuccess(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiStrategies/EditStrategy.editStrategyTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var EditStrategy = {
  encodePayload: encodePayload$1,
  editStrategyTask: editStrategyTask
};

function deleteStrategyTask(serviceEndpoint, id) {
  if (id === undefined) {
    return ;
  }
  var url = serviceEndpoint + ("/strategies/" + id);
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Delete */1, [/* tuple */[
                    "X-Coroner-Location",
                    location.origin
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = decodeSuccess(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiStrategies/DeleteStrategy.deleteStrategyTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var DeleteStrategy = {
  deleteStrategyTask: deleteStrategyTask
};

exports.ErrorMsg = ErrorMsg;
exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.UnknownSvcError = UnknownSvcError;
exports.LoginStrategies = LoginStrategies;
exports.QueryLoginStrategies = QueryLoginStrategies;
exports.Strategies = Strategies;
exports.$$Response = $$Response;
exports.QueryStrategies = QueryStrategies;
exports.CreateStrategy = CreateStrategy;
exports.EditStrategy = EditStrategy;
exports.DeleteStrategy = DeleteStrategy;
/* I18N-BsConsole Not a pure module */
