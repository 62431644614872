// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var QueryDetails$BsConsole = require("./QueryDetails.js");
var BpgController$BsConsole = require("../BpgController.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var BtTableSessionData$BsConsole = require("../BtTableSessionData.js");

var component = ReasonReact.reducerComponent("SavingDialog-BsConsole__Dialog");

function make(currentUser, userIsPrivileged, config, onSuccess, token, handleTask, navToLoad, project, mode, query, refetchProjectDefault, refetchUserDefault, normBy, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var renderSubmitBtn = function (disabled, handleSubmit) {
                return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                undefined,
                                Css.merge(/* :: */[
                                      NewStyles$BsConsole.containedButton,
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.marginLeft(/* zero */-789508312),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]),
                                (function (_event) {
                                    return Curry._1(handleSubmit, /* tuple */[
                                                {
                                                  name: "%dashboard% " + self.state.name,
                                                  project: project.pid,
                                                  owner: currentUser.uid,
                                                  metadata: undefined,
                                                  queries: SavedQueriesModel$BsConsole.fakeQuery,
                                                  payload: JSON.stringify(SavedQueriesModel$BsConsole.toJson(/* Dashboard */Block.__(0, [{
                                                                username: currentUser.username,
                                                                version: /* V1 */1,
                                                                name: self.state.name,
                                                                mode: mode,
                                                                query: query,
                                                                normBy: normBy,
                                                                columnOrder: BtTableSessionData$BsConsole.ColumnOrder.getWithoutView(project.name),
                                                                columnWidth: BtTableSessionData$BsConsole.ColumnWidth.getWithoutView(project.name),
                                                                view: BtTableSessionData$BsConsole.pathNameToView(window.location.pathname)
                                                              }]))),
                                                  __state: undefined,
                                                  __create: undefined,
                                                  __modify: undefined
                                                },
                                                Util$BsConsole.identity,
                                                (function (param) {
                                                    
                                                  }),
                                                (function (param) {
                                                    
                                                  }),
                                                (function (param) {
                                                    
                                                  })
                                              ]);
                                  }),
                                undefined,
                                undefined,
                                /* Raised */-387916264,
                                undefined,
                                undefined,
                                undefined,
                                disabled,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [I18N$BsConsole.show(undefined, "Save")]
                              ]));
              };
              return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Query.Create.make, (function (savedViewBpg, param) {
                                Belt_Option.map(SavedQueriesModel$BsConsole.fromBPG(savedViewBpg), (function (savedView) {
                                        if (self.state.makeUserDefault) {
                                          SavedQueriesModel$BsConsole.makeUserDefault(token, config, project.name, savedView, currentUser.uid, refetchUserDefault);
                                        }
                                        if (self.state.makeProjDefault) {
                                          return SavedQueriesModel$BsConsole.makeProjectDefault(token, savedView, project.pid, project.name, refetchProjectDefault);
                                        }
                                        
                                      }));
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String_literal */Block.__(11, [
                                                  "Successfully saved ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "Successfully saved %s"
                                            ]), self.state.name));
                                return Curry._1(onSuccess, undefined);
                              }), handleTask, token, (function (state, handleSubmit, _toKey) {
                                var _msg = state.remote;
                                var tmp;
                                tmp = typeof _msg === "number" || _msg.tag !== /* Failure */1 ? false : true;
                                var msg = state.remote;
                                var tmp$1;
                                tmp$1 = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : I18N$BsConsole.showSkip(msg[0]);
                                var match = state.remote;
                                var tmp$2;
                                var exit = 0;
                                if (typeof match === "number" || !(match.tag === /* Failure */1 && match[0] === "duplicate object creation attempt")) {
                                  exit = 1;
                                } else {
                                  tmp$2 = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                            I18N$BsConsole.show(undefined, "Name must be unique. Please rename and try again."),
                                            ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, Css.style(/* :: */[
                                                          Css.justifyContent(/* flexStart */662439529),
                                                          /* :: */[
                                                            Css.marginLeft(Css.px(0)),
                                                            /* [] */0
                                                          ]
                                                        ]), [
                                                      renderSubmitBtn(state.remote === /* Loading */1 || self.state.name === "", handleSubmit),
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                undefined,
                                                                NewStyles$BsConsole.textButton,
                                                                (function (_event) {
                                                                    return Curry._1(navToLoad, undefined);
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                /* Flat */781662169,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Cancel")]
                                                              ]))
                                                    ]))
                                          ]));
                                }
                                if (exit === 1) {
                                  tmp$2 = ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogActions.Jsx2.make, Css.style(/* :: */[
                                                Css.justifyContent(/* flexStart */662439529),
                                                /* :: */[
                                                  Css.marginLeft(Css.px(0)),
                                                  /* [] */0
                                                ]
                                              ]), [
                                            renderSubmitBtn(state.remote === /* Loading */1 || self.state.name === "", handleSubmit),
                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                      undefined,
                                                      NewStyles$BsConsole.textButton,
                                                      (function (_event) {
                                                          return Curry._1(navToLoad, undefined);
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      /* Flat */781662169,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [I18N$BsConsole.show(undefined, "Cancel")]
                                                    ]))
                                          ]));
                                }
                                return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                    Css.height(Css.pct(100)),
                                                    /* [] */0
                                                  ]), [
                                                React.createElement("div", {
                                                      className: NewStyles$BsConsole.savedViewsMidSection
                                                    }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                        Css.style(/* :: */[
                                                                              Css.margin4(/* zero */-789508312, /* zero */-789508312, Css.px(8), /* zero */-789508312),
                                                                              /* [] */0
                                                                            ]),
                                                                        undefined,
                                                                        undefined,
                                                                        true,
                                                                        (function ($$event) {
                                                                            var name = $$event.target.value;
                                                                            return Curry._1(self.send, /* ChangeName */Block.__(0, [name]));
                                                                          }),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        tmp,
                                                                        Caml_option.some(tmp$1),
                                                                        undefined,
                                                                        /* `String */[
                                                                          -976970511,
                                                                          self.state.name
                                                                        ],
                                                                        undefined,
                                                                        undefined,
                                                                        Caml_option.some(I18N$BsConsole.show(undefined, "Name your view")),
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        []
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                                            Css.marginTop(Css.rem(1.25)),
                                                                            /* [] */0
                                                                          ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                  undefined,
                                                                                  Caml_option.some(React.createElement("span", {
                                                                                            className: Css.merge(/* :: */[
                                                                                                  BtTypography$BsConsole.body2,
                                                                                                  /* :: */[
                                                                                                    Css.style(/* :: */[
                                                                                                          Css.color(self.state.makeUserDefault ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                                                          /* [] */0
                                                                                                        ]),
                                                                                                    /* [] */0
                                                                                                  ]
                                                                                                ])
                                                                                          }, I18N$BsConsole.show(undefined, "Set as your default view"))),
                                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                                Css.style(/* :: */[
                                                                                                      Css.selector("svg", /* :: */[
                                                                                                            Css.width(Css.rem(0.9)),
                                                                                                            /* :: */[
                                                                                                              Css.height(Css.rem(0.9)),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]),
                                                                                                      /* [] */0
                                                                                                    ]),
                                                                                                /* `Bool */[
                                                                                                  737456202,
                                                                                                  self.state.makeUserDefault
                                                                                                ],
                                                                                                undefined,
                                                                                                /* Primary */-791844958,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                {
                                                                                                  root: Css.style(/* :: */[
                                                                                                        Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                                                        /* :: */[
                                                                                                          Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                                                          /* :: */[
                                                                                                            Css.paddingRight(Css.rem(0.5)),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]
                                                                                                      ])
                                                                                                },
                                                                                                true,
                                                                                                (function (_evt, checked) {
                                                                                                    return Curry._1(self.send, /* ToggleUserDefault */Block.__(1, [checked]));
                                                                                                  }),
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                []
                                                                                              ]))),
                                                                                  undefined,
                                                                                  Css.style(/* :: */[
                                                                                        Css.margin(/* zero */-789508312),
                                                                                        /* :: */[
                                                                                          Css.userSelect(Css.none),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))])),
                                                              userIsPrivileged ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                                              Css.marginBottom(Css.rem(1.5)),
                                                                              /* [] */0
                                                                            ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                    undefined,
                                                                                    Caml_option.some(React.createElement("span", {
                                                                                              className: Css.merge(/* :: */[
                                                                                                    BtTypography$BsConsole.body2,
                                                                                                    /* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.color(self.state.makeProjDefault ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                                                            /* [] */0
                                                                                                          ]),
                                                                                                      /* [] */0
                                                                                                    ]
                                                                                                  ])
                                                                                            }, I18N$BsConsole.show(undefined, "Set as the project view"))),
                                                                                    Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                                  Css.style(/* :: */[
                                                                                                        Css.selector("svg", /* :: */[
                                                                                                              Css.width(Css.rem(0.9)),
                                                                                                              /* :: */[
                                                                                                                Css.height(Css.rem(0.9)),
                                                                                                                /* [] */0
                                                                                                              ]
                                                                                                            ]),
                                                                                                        /* [] */0
                                                                                                      ]),
                                                                                                  /* `Bool */[
                                                                                                    737456202,
                                                                                                    self.state.makeProjDefault
                                                                                                  ],
                                                                                                  undefined,
                                                                                                  /* Primary */-791844958,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  {
                                                                                                    root: Css.style(/* :: */[
                                                                                                          Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                                                          /* :: */[
                                                                                                            Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                                                            /* :: */[
                                                                                                              Css.paddingRight(Css.rem(0.5)),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]
                                                                                                        ])
                                                                                                  },
                                                                                                  true,
                                                                                                  (function (_evt, checked) {
                                                                                                      return Curry._1(self.send, /* ToggleProjDefault */Block.__(2, [checked]));
                                                                                                    }),
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  []
                                                                                                ]))),
                                                                                    undefined,
                                                                                    Css.style(/* :: */[
                                                                                          Css.margin(/* zero */-789508312),
                                                                                          /* :: */[
                                                                                            Css.userSelect(Css.none),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))])) : null,
                                                              tmp$2
                                                            ]))),
                                                ReasonReact.element(undefined, undefined, QueryDetails$BsConsole.Re.make(/* Dashboard */Block.__(0, [{
                                                              username: currentUser.username,
                                                              version: /* V1 */1,
                                                              name: self.state.name,
                                                              mode: mode,
                                                              query: query,
                                                              normBy: normBy,
                                                              columnOrder: undefined,
                                                              columnWidth: undefined,
                                                              view: undefined
                                                            }]), []))
                                              ]));
                              })));
            }),
          initialState: (function (param) {
              return {
                      name: "",
                      makeUserDefault: false,
                      makeProjDefault: false
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* ChangeName */0 :
                    return /* Update */Block.__(0, [{
                                name: action[0],
                                makeUserDefault: state.makeUserDefault,
                                makeProjDefault: state.makeProjDefault
                              }]);
                case /* ToggleUserDefault */1 :
                    return /* Update */Block.__(0, [{
                                name: state.name,
                                makeUserDefault: action[0],
                                makeProjDefault: state.makeProjDefault
                              }]);
                case /* ToggleProjDefault */2 :
                    return /* Update */Block.__(0, [{
                                name: state.name,
                                makeUserDefault: state.makeUserDefault,
                                makeProjDefault: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
