// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var FormatUtils$BsConsole = require("./FormatUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var BtOutlineSelect$BsConsole = require("./BtOutlineSelect.js");
var MenuItem = require("@material-ui/core/MenuItem");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

var select = Css.style(/* :: */[
      Css.minWidth(Css.px(130)),
      /* [] */0
    ]);

var iconContainer = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey2)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.selector(".value", /* :: */[
            Css.marginRight(Css.rem(1)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".caption", /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey4)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemClasses = {
  root: Css.style(/* :: */[
        Css.hover(/* :: */[
              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
              /* [] */0
            ]),
        /* :: */[
          Css.paddingLeft(Css.px(32)),
          /* :: */[
            Css.paddingRight(Css.px(32)),
            /* :: */[
              Css.position(/* relative */903134412),
              /* [] */0
            ]
          ]
        ]
      ]),
  selected: Css.style(/* :: */[
        Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
        /* :: */[
          Css.before(/* :: */[
                Css.fontFamily("'Material Icons'"),
                /* :: */[
                  Css.unsafe("content", "'check'"),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.position(/* absolute */-1013592457),
                      /* :: */[
                        Css.left(Css.px(8)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ])
};

var contentRow = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var loading = Css.style(/* :: */[
      Css.marginRight(Css.rem(0.5)),
      /* [] */0
    ]);

var Style = {
  select: select,
  iconContainer: iconContainer,
  menuItem: menuItem,
  menuItemClasses: menuItemClasses,
  contentRow: contentRow,
  loading: loading
};

function NormalizeBySelect$ValueWithIcon(Props) {
  var icon = Props.icon;
  var children = Props.children;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: iconContainer,
                  children: icon
                }), children);
}

var ValueWithIcon = {
  make: NormalizeBySelect$ValueWithIcon
};

function makeMetricsQuery(aperture, metricsAttributes) {
  var metricsAttributeNames = Belt_List.toArray(Belt_List.map(metricsAttributes, (function (attr) {
              return attr.name;
            })));
  var aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                  return metricsAttributeNames.includes(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter));
                }))), Crdb$BsConsole.Aperture.$$default);
  return /* Aggregate */Block.__(0, [
            aperture$prime,
            /* Custom */["*"],
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "_metric_group",
                      /* Distribution */Block.__(1, [50])
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function NormalizeBySelect(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var view = Props.view;
  var normBy = Props.normBy;
  var handleSetMetricGroup = Props.handleSetMetricGroup;
  var metricsAttributes = Props.metricsAttributes;
  var config = Props.config;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = QueryPaginationTask$BsConsole.MetricsAggregate.use(token, projectName, makeMetricsQuery(aperture, metricsAttributes), view + "_metric_group-distribution");
  var metricAggRemote = match[0];
  var match$1 = Task2$BsConsole.useRefetchable(undefined, BpgTask$BsConsole.MetricGroup.fetchAll, undefined);
  var metricGroupRemote = match$1[0];
  var handleOnChange = function ($$event, _jsEvent) {
    return Curry._1(handleSetMetricGroup, $$event.target.value);
  };
  var metricGroupsFromQuery = React.useMemo((function () {
          if (typeof metricAggRemote === "number") {
            return [];
          }
          if (metricAggRemote.tag) {
            return [];
          }
          var match = metricAggRemote[0][0];
          var columns = match[0];
          var group = Belt_List.get(match[1], 0);
          if (group === undefined) {
            return [];
          }
          var getter = function (param, param$1) {
            return Group$BsConsole.getAggregation(columns, group, param, param$1);
          };
          var distribution = Group$BsConsole.unwrapDistribution(getter("_metric_group", /* Distribution */1));
          if (distribution !== undefined) {
            return Belt_List.toArray(Belt_List.map(distribution.vals, (function (param) {
                              return /* tuple */[
                                      FormatUtils$BsConsole.formatToString(param[0]),
                                      param[1]
                                    ];
                            })));
          } else {
            return [];
          }
        }), [metricAggRemote]);
  var currentProject = Belt_List.getBy(config.projects, (function (project) {
          return projectName === project.name;
        }));
  var availalbleMetricGroupsFromBPG = React.useMemo((function () {
          if (typeof metricGroupRemote === "number") {
            return [];
          } else if (metricGroupRemote.tag) {
            return [];
          } else if (currentProject !== undefined) {
            return Belt_Array.keep(metricGroupRemote[0][0], (function (metricGroup) {
                          return metricGroup.project === currentProject.pid;
                        }));
          } else {
            return [];
          }
        }), /* tuple */[
        metricGroupRemote,
        currentProject
      ]);
  var value = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(normBy, (function (group) {
                            return Belt_Option.map(Belt_Array.getBy(Belt_Array.keep(availalbleMetricGroupsFromBPG, (function (bpgGroup) {
                                                  return bpgGroup.project === Belt_Option.getWithDefault(Belt_Option.map(currentProject, (function (project) {
                                                                    return project.pid;
                                                                  })), 99999);
                                                })), (function (bpgGroup) {
                                              return bpgGroup.name === group;
                                            })), (function (bpgGroup) {
                                          return bpgGroup.name;
                                        }));
                          })), "-");
        }), /* tuple */[
        normBy,
        availalbleMetricGroupsFromBPG
      ]);
  return React.createElement(BtOutlineSelect$BsConsole.make, {
              value: value,
              name: "normalize-by-select",
              renderValue: (function (value) {
                  return React.createElement(NormalizeBySelect$ValueWithIcon, {
                              icon: React.createElement(MuiIcons$BsConsole.OutlinedList.make, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  }),
                              children: I18N$BsConsole.show(undefined, value)
                            });
                }),
              onChange: handleOnChange,
              label: "Errors normalized by",
              className: Css.merge(/* :: */[
                    select,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: null
            }, availalbleMetricGroupsFromBPG.length !== 0 ? Belt_Array.mapWithIndex(availalbleMetricGroupsFromBPG, (function (idx, group) {
                      var foundInSet = Belt_Option.isSome(Belt_Array.getBy(metricGroupsFromQuery, (function (param) {
                                  return group.name === param[0];
                                })));
                      return React.createElement(MenuItem.default, {
                                  disableRipple: true,
                                  disabled: !foundInSet,
                                  classes: menuItemClasses,
                                  value: group.name,
                                  className: menuItem,
                                  children: React.createElement(Row2$BsConsole.make, {
                                        justifyContent: /* spaceBetween */516682146,
                                        className: contentRow,
                                        children: null
                                      }, React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: "value",
                                            children: I18N$BsConsole.show(undefined, group.name)
                                          }), foundInSet ? null : React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Caption */11,
                                              className: "caption",
                                              children: I18N$BsConsole.show(undefined, "(not found in data set)")
                                            })),
                                  key: String(idx) + ("__" + value)
                                });
                    })) : React.createElement(MenuItem.default, {
                    disabled: true,
                    children: React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: I18N$BsConsole.show(undefined, "No metric groups found")
                        })
                  }), typeof metricAggRemote === "number" ? React.createElement(MenuItem.default, {
                    disabled: true,
                    children: React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement("span", {
                              className: loading
                            }, I18N$BsConsole.show(undefined, "Loading")), React.createElement(CircularProgress.default, {
                              size: 16,
                              color: "primary"
                            }))
                  }) : null);
}

var make = NormalizeBySelect;

exports.Style = Style;
exports.ValueWithIcon = ValueWithIcon;
exports.makeMetricsQuery = makeMetricsQuery;
exports.make = make;
/* select Not a pure module */
