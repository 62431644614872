// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/lib/js/src/Fetch.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var BillingConstants$BsConsole = require("./BillingConstants.js");

var api_namespace = "/api";

function pathnameForRequest(request) {
  if (typeof request === "number") {
    switch (request) {
      case /* WhiteList */0 :
          return "/api/signup";
      case /* Session */1 :
      case /* ClearOverride */2 :
          return "/api/session";
      
    }
  } else {
    switch (request.tag | 0) {
      case /* GetObject */0 :
          return api_namespace + ("/get?resource=json.gz&project=" + (request[1] + ("&object=" + (request[2] + ("&token=" + request[0])))));
      case /* Queries */1 :
          return "/api/queries";
      case /* CRDB */2 :
          return api_namespace + ("/query?project=" + request[1]);
      case /* Describe */3 :
          return api_namespace + ("/query?action=describe&project=" + request[1]);
      case /* DescribeWithDisabled */4 :
          return api_namespace + ("/query?action=describe&disabled=true&project=" + request[1]);
      case /* Login */7 :
          return "/api/login";
      case /* Logout */8 :
          return "/api/logout";
      case /* BPG */9 :
          return api_namespace + ("/bpg?token=" + request[0]);
      case /* FileList */10 :
          return api_namespace + ("/list?project=" + (request[2] + ("&object=" + request[1])));
      case /* Recover */5 :
      case /* Reset */12 :
          return api_namespace + ("/reset?username=" + request[0]);
      case /* ResendInvite */6 :
      case /* SignUp */11 :
      case /* Activate */13 :
          return "/api/signup";
      case /* SymbolArchiveTags */14 :
      case /* SymbolArchives */15 :
      case /* MissingSymbols */16 :
      case /* Symbols */17 :
      case /* SymbolsSummary */18 :
          return api_namespace + ("/symfile?project=" + request[1]);
      case /* Configuration */19 :
          return "/api/config";
      case /* SetOverride */20 :
          return "/api/session";
      case /* BillingLimits */21 :
          return "/api/limits";
      case /* BillingSubscription */22 :
          return BillingConstants$BsConsole.serverUrl + ("/subscription?token=" + request[0]);
      case /* BillingPlans */23 :
          return BillingConstants$BsConsole.serverUrl + ("/plans?token=" + request[0]);
      case /* BillingInvoices */24 :
          return BillingConstants$BsConsole.serverUrl + ("/invoices?token=" + request[0]);
      case /* BillingInvoicePDF */25 :
          return BillingConstants$BsConsole.serverUrl + ("/invoices/" + (request[0] + ("?token=" + request[1])));
      case /* GetSamlConfig */26 :
          var tenant = request[2];
          var serviceUrl = request[1];
          var token = request[0];
          if (serviceUrl.includes("/api")) {
            return serviceUrl + ("?host=" + (tenant + ("&token=" + token)));
          } else {
            return serviceUrl + ("/api/saml?token=" + (token + ("&host=" + tenant)));
          }
      case /* WorkflowTask */27 :
          return api_namespace + ("/workflow?project=" + (request[1] + ("&watcher=" + request[2])));
      case /* TestWipWorkflow */28 :
          return api_namespace + ("/workflow?project=" + request[1]);
      
    }
  }
}

function makeInit(headers, method__, body, credentials, mode, param) {
  var func = Fetch.RequestInit.make;
  var arg = credentials;
  var defaultInit = function (param, param$1, param$2) {
    return Curry._7(func, method__, Caml_option.some(headers), param, param$1, param$2, mode, arg);
  };
  return Curry._5(defaultInit(body, undefined, undefined), undefined, undefined, undefined, undefined, undefined);
}

function makeHeaders(token) {
  var content_type = /* tuple */[
    "content-type",
    "application/json"
  ];
  if (token !== undefined) {
    return [
            content_type,
            /* tuple */[
              "X-Coroner-Token",
              token
            ]
          ];
  } else {
    return [content_type];
  }
}

function methodForRequest(request) {
  if (typeof request === "number") {
    if (request === /* Session */1) {
      return /* Get */0;
    } else {
      return /* Post */2;
    }
  }
  switch (request.tag | 0) {
    case /* GetObject */0 :
    case /* FileList */10 :
    case /* Configuration */19 :
    case /* BillingLimits */21 :
    case /* BillingSubscription */22 :
    case /* BillingPlans */23 :
    case /* BillingInvoices */24 :
    case /* BillingInvoicePDF */25 :
    case /* GetSamlConfig */26 :
        return /* Get */0;
    default:
      return /* Post */2;
  }
}

function headersForRequest(request) {
  if (typeof request === "number") {
    switch (request) {
      case /* WhiteList */0 :
      case /* Session */1 :
      case /* ClearOverride */2 :
          return makeHeaders(undefined);
      
    }
  } else {
    switch (request.tag | 0) {
      case /* Recover */5 :
      case /* ResendInvite */6 :
      case /* Login */7 :
      case /* SignUp */11 :
      case /* Reset */12 :
      case /* Activate */13 :
      case /* SetOverride */20 :
      case /* BillingSubscription */22 :
      case /* BillingPlans */23 :
      case /* BillingInvoices */24 :
      case /* BillingInvoicePDF */25 :
      case /* GetSamlConfig */26 :
          return makeHeaders(undefined);
      default:
        return makeHeaders(request[0]);
    }
  }
}

function bodyForRequest(request) {
  if (typeof request === "number") {
    switch (request) {
      case /* WhiteList */0 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "list"
                              ],
                              /* [] */0
                            ])));
      case /* ClearOverride */2 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "unset"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "resources",
                                          Json_encode.list((function (prim) {
                                                  return prim;
                                                }), /* :: */[
                                                "assets_css",
                                                /* :: */[
                                                  "assets_js",
                                                  /* [] */0
                                                ]
                                              ])
                                        ],
                                        /* [] */0
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      default:
        return ;
    }
  } else {
    switch (request.tag | 0) {
      case /* Queries */1 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "list"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "expanded",
                                          true
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "extended",
                                            true
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "project",
                                              request[1]
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* CRDB */2 :
          var json = request[2];
          var tmp;
          try {
            tmp = (function(query) {
        let deleted = /"_deleted":\[\[([^\]]+)\]\],/.exec(query);
        if (deleted[1]) {
          query = query.replace( /"_deleted":\[\[([^\]]+)\]\],/, "");
          var results = /{\"filter":\[{([^}]+)}](.+)/.exec(query);
          query = '{"filter":[{' + results[1] + ',"_deleted":[[' +deleted[1]+']]}]'+ results[2];
        }
        JSON.stringify(JSON.parse(query));
        return query;
      })(JSON.stringify(json));
          }
          catch (exn){
            tmp = Json.stringify(json);
          }
          return Caml_option.some(tmp);
      case /* ResendInvite */6 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "resend"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "username",
                                          request[0]
                                        ],
                                        /* [] */0
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* Login */7 :
          var encodedUsername = encodeURIComponent(request[0]);
          var encodedPassword = encodeURIComponent(request[1]);
          return Caml_option.some("username=" + (encodedUsername + ("&password=" + encodedPassword)));
      case /* BPG */9 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "actions",
                                $$Array.of_list(request[1])
                              ],
                              /* [] */0
                            ])));
      case /* SignUp */11 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "create"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "email",
                                          request[0]
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "username",
                                            request[1]
                                          ],
                                          /* [] */0
                                        ]
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* Activate */13 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "verify"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "token",
                                          request[0]
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "password",
                                            request[1]
                                          ],
                                          /* [] */0
                                        ]
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* SymbolArchiveTags */14 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "tags"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* [] */0)
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* SymbolArchives */15 :
          var archiveIds = request[2];
          if (archiveIds !== undefined) {
            return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "action",
                                  "archives"
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "form",
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "archive_ids",
                                            archiveIds
                                          ],
                                          /* [] */0
                                        ])
                                  ],
                                  /* [] */0
                                ]
                              ])));
          } else {
            return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "action",
                                  "archives"
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "form",
                                    Json_encode.object_(/* [] */0)
                                  ],
                                  /* [] */0
                                ]
                              ])));
          }
      case /* MissingSymbols */16 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "missing_symbols"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* [] */0)
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* Symbols */17 :
          var archiveId = request[2];
          if (archiveId !== undefined) {
            var tag = request[3];
            if (tag !== undefined) {
              return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                  /* tuple */[
                                    "action",
                                    "symbols"
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "form",
                                      Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "archive_ids",
                                              [archiveId]
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "tags",
                                                [tag]
                                              ],
                                              /* [] */0
                                            ]
                                          ])
                                    ],
                                    /* [] */0
                                  ]
                                ])));
            } else {
              return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                  /* tuple */[
                                    "action",
                                    "symbols"
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "form",
                                      Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "archive_id",
                                              [archiveId]
                                            ],
                                            /* [] */0
                                          ])
                                    ],
                                    /* [] */0
                                  ]
                                ])));
            }
          }
          var tag$1 = request[3];
          if (tag$1 !== undefined) {
            return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "action",
                                  "symbols"
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "form",
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "tags",
                                            [tag$1]
                                          ],
                                          /* [] */0
                                        ])
                                  ],
                                  /* [] */0
                                ]
                              ])));
          } else {
            return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "action",
                                  "symbols"
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "form",
                                    Json_encode.object_(/* [] */0)
                                  ],
                                  /* [] */0
                                ]
                              ])));
          }
      case /* SymbolsSummary */18 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "summary"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* [] */0)
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* SetOverride */20 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "action",
                                "set"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "form",
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "resources",
                                          Json_encode.object_(/* :: */[
                                                /* tuple */[
                                                  "assets_js",
                                                  request[0]
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "assets_css",
                                                    request[1]
                                                  ],
                                                  /* [] */0
                                                ]
                                              ])
                                        ],
                                        /* [] */0
                                      ])
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* WorkflowTask */27 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                request[3]
                              ],
                              /* :: */[
                                /* tuple */[
                                  "action",
                                  request[4]
                                ],
                                /* [] */0
                              ]
                            ])));
      case /* TestWipWorkflow */28 :
          return Caml_option.some(JSON.stringify(Json_encode.object_(/* :: */[
                              /* tuple */[
                                "type",
                                "test"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "configuration",
                                  request[2]
                                ],
                                /* [] */0
                              ]
                            ])));
      default:
        return ;
    }
  }
}

function makeTask(req) {
  var url = pathnameForRequest(req);
  var headers = headersForRequest(req);
  var method__ = methodForRequest(req);
  var body = bodyForRequest(req);
  var init;
  var exit = 0;
  if (typeof req === "number") {
    exit = 1;
  } else {
    switch (req.tag | 0) {
      case /* BillingSubscription */22 :
      case /* BillingPlans */23 :
      case /* BillingInvoices */24 :
      case /* BillingInvoicePDF */25 :
      case /* GetSamlConfig */26 :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        init = makeInit(headers, method__, body, /* SameOrigin */1, undefined, undefined);
        break;
    case 2 :
        var headers$1 = [];
        init = makeInit(headers$1, method__, body, /* Omit */0, /* CORS */3, undefined);
        break;
    
  }
  return {
          promise: fetch(url, init),
          request: req
        };
}

var billing_namespace = BillingConstants$BsConsole.serverUrl;

exports.api_namespace = api_namespace;
exports.billing_namespace = billing_namespace;
exports.pathnameForRequest = pathnameForRequest;
exports.makeInit = makeInit;
exports.makeHeaders = makeHeaders;
exports.methodForRequest = methodForRequest;
exports.headersForRequest = headersForRequest;
exports.bodyForRequest = bodyForRequest;
exports.makeTask = makeTask;
/* No side effect */
