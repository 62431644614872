// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Numeral = require("numeral");

function format(form, num) {
  return Numeral(num).format(form);
}

exports.format = format;
/* numeral Not a pure module */
