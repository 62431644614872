// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Unpack$BsConsole = require("./bs-crdb-response/src/Unpack.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var ResponseSelect$BsConsole = require("./bs-crdb-response/src/ResponseSelect.js");

var ParseError = Caml_exceptions.create("CrdbPaginationTask-BsConsole.ParseError");

function addTxFilter(tx, query) {
  if (tx === undefined) {
    return query;
  }
  var ap = Curry._1(Crdb$BsConsole.Query.getAperture, query);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `ObjectID */[
                  -589436806,
                  tx
                ]])
          ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, ap));
  var __x = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, ap);
  return Curry._2(Crdb$BsConsole.Query.setAperture, __x, query);
}

function makeTask(query, projectName, decodeResp, page, tx) {
  var query$prime = Curry._2(Crdb$BsConsole.Query.setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), addTxFilter(tx, query));
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [Crdb$BsConsole.QueryManager.toCrdb(query$prime)]), undefined, decodeResp, undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function Make(PA) {
  var use = function (token, projectName, query, factor, reportName) {
    var match = React.useState((function () {
            return query;
          }));
    var setQuery = match[1];
    var query$prime = match[0];
    var match$1 = React.useState((function () {
            return 0;
          }));
    var setPage = match$1[1];
    var page = match$1[0];
    var match$2 = React.useState((function () {
            return /* NotAsked */0;
          }));
    var setValue = match$2[1];
    var value = match$2[0];
    var match$3 = React.useState((function () {
            
          }));
    var setTx = match$3[1];
    var tx = match$3[0];
    var match$4 = React.useState((function () {
            return projectName;
          }));
    var setRetainedProjectName = match$4[1];
    var retainedProjectName = match$4[0];
    React.useEffect((function () {
            if (!Curry._2(Crdb$BsConsole.Query.eq, query, query$prime)) {
              Curry._1(setQuery, (function (param) {
                      return query;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setValue, (function (remote) {
                      if (typeof remote === "number" || remote.tag) {
                        return /* NotAsked */0;
                      } else {
                        return /* Success */Block.__(0, [/* tuple */[
                                    remote[0][0],
                                    /* Loading */1
                                  ]]);
                      }
                    }));
            }
            
          }), [query]);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
              Curry._1(setRetainedProjectName, (function (param) {
                      return projectName;
                    }));
              Curry._1(setPage, (function (param) {
                      return 0;
                    }));
              Curry._1(setTx, (function (param) {
                      
                    }));
              Curry._1(setValue, (function (param) {
                      return /* NotAsked */0;
                    }));
            }
            
          }), [projectName]);
    React.useEffect((function () {
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
            
          }), [page]);
    React.useEffect((function () {
            var onSuccess = function (resp) {
              MetricsEvent$BsConsole.send(/* Query */Block.__(4, [
                      reportName,
                      query,
                      Curry._1(PA.getTimes, resp)
                    ]));
              Curry._1(setValue, (function (param) {
                      return /* Success */Block.__(0, [/* tuple */[
                                  resp,
                                  /* NotAsked */0
                                ]]);
                    }));
              return Curry._1(setTx, (function (param) {
                            return Curry._1(PA.getTx, resp);
                          }));
            };
            var onFailure = function (errorMessage) {
              return Curry._1(setValue, (function (param) {
                            return /* Failure */Block.__(1, [errorMessage]);
                          }));
            };
            var task = makeTask(query$prime, projectName, Curry._1(PA.decodeResp, factor), page, tx);
            if (typeof value === "number") {
              if (value === /* NotAsked */0) {
                Curry._1(setValue, (function (param) {
                        return /* Loading */1;
                      }));
              } else {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
            } else if (!value.tag) {
              var remote$prime = value[0][1];
              if (typeof remote$prime === "number" && remote$prime !== 0) {
                Task2$BsConsole.handle(token, task, undefined, (function (param) {
                        return responseCb(onSuccess, onFailure, param);
                      }));
              }
              
            }
            
          }), [value]);
    return /* tuple */[
            value,
            page,
            setPage
          ];
  };
  return {
          decodeResp: PA.decodeResp,
          use: use
        };
}

function decodeResp(factor, response) {
  try {
    var columns = Parse$BsConsole.parseColumns(response.json);
    var rows = Parse$BsConsole.decodeExn(/* tuple */[
          Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor),
          undefined
        ], columns, response.json);
    var metadata = Parse$BsConsole.Aggregate.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "CrdbPaginationTask/Aggregate.decodeResponse", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx(param) {
  return param[2].tx;
}

function getTimes(param) {
  return param[2].times;
}

function use(token, projectName, query, factor, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Query.eq, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            MetricsEvent$BsConsole.send(/* Query */Block.__(4, [
                    reportName,
                    query,
                    getTimes(resp)
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask(query$prime, projectName, (function (param) {
                  return decodeResp(factor, param);
                }), page, tx);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var Aggregate = {
  decodeResp: decodeResp,
  use: use
};

function decodeResp$1(_factor, response) {
  try {
    var columns = ResponseSelect$BsConsole.columns(response.json);
    var rows = Unpack$BsConsole.response2PointOh(response.json, columns);
    var metadata = ResponseSelect$BsConsole.metadata(response.json);
    return /* Ok */Block.__(0, [/* tuple */[
                columns,
                rows,
                metadata
              ]]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "CrdbPaginationTask/Select.decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, [[
                ParseError,
                error
              ]]);
  }
}

function getTx$1(param) {
  return param[2].tx;
}

function getTimes$1(param) {
  return param[2].times;
}

function use$1(token, projectName, query, factor, reportName) {
  var match = React.useState((function () {
          return query;
        }));
  var setQuery = match[1];
  var query$prime = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setTx = match$3[1];
  var tx = match$3[0];
  var match$4 = React.useState((function () {
          return projectName;
        }));
  var setRetainedProjectName = match$4[1];
  var retainedProjectName = match$4[0];
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Query.eq, query, query$prime)) {
            Curry._1(setQuery, (function (param) {
                    return query;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setValue, (function (remote) {
                    if (typeof remote === "number" || remote.tag) {
                      return /* NotAsked */0;
                    } else {
                      return /* Success */Block.__(0, [/* tuple */[
                                  remote[0][0],
                                  /* Loading */1
                                ]]);
                    }
                  }));
          }
          
        }), [query]);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(projectName, retainedProjectName)) {
            Curry._1(setRetainedProjectName, (function (param) {
                    return projectName;
                  }));
            Curry._1(setPage, (function (param) {
                    return 0;
                  }));
            Curry._1(setTx, (function (param) {
                    
                  }));
            Curry._1(setValue, (function (param) {
                    return /* NotAsked */0;
                  }));
          }
          
        }), [projectName]);
  React.useEffect((function () {
          Curry._1(setValue, (function (remote) {
                  if (typeof remote === "number" || remote.tag) {
                    return /* NotAsked */0;
                  } else {
                    return /* Success */Block.__(0, [/* tuple */[
                                remote[0][0],
                                /* Loading */1
                              ]]);
                  }
                }));
          
        }), [page]);
  React.useEffect((function () {
          var onSuccess = function (resp) {
            MetricsEvent$BsConsole.send(/* Query */Block.__(4, [
                    reportName,
                    query,
                    getTimes$1(resp)
                  ]));
            Curry._1(setValue, (function (param) {
                    return /* Success */Block.__(0, [/* tuple */[
                                resp,
                                /* NotAsked */0
                              ]]);
                  }));
            return Curry._1(setTx, (function (param) {
                          return getTx$1(resp);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setValue, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          var task = makeTask(query$prime, projectName, (function (param) {
                  return decodeResp$1(factor, param);
                }), page, tx);
          if (typeof value === "number") {
            if (value === /* NotAsked */0) {
              Curry._1(setValue, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
          } else if (!value.tag) {
            var remote$prime = value[0][1];
            if (typeof remote$prime === "number" && remote$prime !== 0) {
              Task2$BsConsole.handle(token, task, undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            }
            
          }
          
        }), [value]);
  return /* tuple */[
          value,
          page,
          setPage
        ];
}

var Select = {
  decodeResp: decodeResp$1,
  use: use$1
};

exports.ParseError = ParseError;
exports.addTxFilter = addTxFilter;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.Make = Make;
exports.Aggregate = Aggregate;
exports.Select = Select;
/* react Not a pure module */
