// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Typeahead$BsConsole = require("./Typeahead.js");
var InboxUtils$BsConsole = require("../inbox/InboxUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var InboxWorkflow$BsConsole = require("../inbox/InboxWorkflow.js");

var descriptionStyle = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.grey4))),
      /* [] */0
    ]);

var titleContainerStyle = Css.style(/* :: */[
      Css.flexShrink(1),
      /* [] */0
    ]);

var titleStyle = Css.style(/* :: */[
      Css.important(Css.overflow(/* hidden */-862584982)),
      /* :: */[
        Css.important(Css.textOverflow(/* ellipsis */166888785)),
        /* :: */[
          Css.important(Css.whiteSpace(/* nowrap */867913355)),
          /* :: */[
            Css.margin2(Css.px(2), Css.zero),
            /* :: */[
              Css.marginTop(Css.zero),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var flex = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* [] */0
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.minHeight(Css.px(48)),
      /* :: */[
        Css.justifyContent(/* flexStart */662439529),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* :: */[
              Css.paddingTop(Css.zero),
              /* :: */[
                Css.boxSizing(/* borderBox */9307263),
                /* :: */[
                  Css.unsafe("outline", "none"),
                  /* :: */[
                    Css.padding2(Css.px(4), Css.px(8)),
                    /* :: */[
                      Css.width(Css.px(350)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var customStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.background(Css.hex(Colors$BsConsole.accent)),
        /* [] */0
      ]
    ]);

var triageStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.background(Css.hex(Colors$BsConsole.greenDark)),
        /* [] */0
      ]
    ]);

var defaultStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.background(Css.hex(Colors$BsConsole.grey6)),
        /* [] */0
      ]
    ]);

var pill = Css.style(/* :: */[
      Css.fontSize(Css.px(10)),
      /* :: */[
        Css.borderRadius(Css.px(2)),
        /* :: */[
          Css.alignSelf(Css.baseline),
          /* :: */[
            Css.padding2(Css.px(2), Css.px(3)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    500
                  ]),
              /* :: */[
                Css.marginLeft(Css.px(4)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

function makeClass(customStyle) {
  return customStyle + (" " + pill);
}

function make(customStyle, badgeText) {
  return React.createElement("span", {
              className: customStyle + (" " + pill)
            }, I18N$BsConsole.showSkip(badgeText));
}

var custom = make(customStyle, I18N$BsConsole.get(undefined, "custom"));

var system = make(defaultStyle, I18N$BsConsole.get(undefined, "default"));

var triage = make(defaultStyle, I18N$BsConsole.get(undefined, "triage"));

var unused = make(defaultStyle, I18N$BsConsole.get(undefined, "unused"));

var Badge = {
  makeClass: makeClass,
  make: make,
  custom: custom,
  system: system,
  triage: triage,
  unused: unused
};

function AttributeTypeahead$AttributeItem(Props) {
  var name = Props.name;
  var description = Props.description;
  var custom$1 = Props.custom;
  var triage$1 = Props.triage;
  var used = Props.used;
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: flex
                }, React.createElement("div", {
                      className: titleContainerStyle
                    }, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, titleStyle, undefined, undefined, undefined, [Belt_Option.getWithDefault(Belt_Option.map(triage$1 ? InboxWorkflow$BsConsole.Folders.getInboxWorkflowAttributeName(name) : undefined, I18N$BsConsole.showSkip), I18N$BsConsole.showSkip(name))]))), React.createElement("div", undefined, used ? null : unused, custom$1 ? custom : (
                        triage$1 ? triage : system
                      ))), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, descriptionStyle, undefined, undefined, undefined, [I18N$BsConsole.dynamic(description)])));
}

function make$1(name, description, custom, triage, used, children) {
  return ReasonReactCompat.wrapReactForReasonReact(AttributeTypeahead$AttributeItem, {
              name: name,
              description: description,
              custom: custom,
              triage: triage,
              used: used
            }, children);
}

var Re = {
  make: make$1
};

var AttributeItem = {
  descriptionStyle: descriptionStyle,
  titleContainerStyle: titleContainerStyle,
  titleStyle: titleStyle,
  flex: flex,
  container: container,
  customStyle: customStyle,
  triageStyle: triageStyle,
  defaultStyle: defaultStyle,
  pill: pill,
  Badge: Badge,
  make: AttributeTypeahead$AttributeItem,
  Re: Re
};

function toString(t$prime) {
  return t$prime.name;
}

function getCount(param) {
  
}

var renderItem = (function (t$prime) {
    return ReasonReact.element(undefined, undefined, make$1(t$prime.name, t$prime.description, t$prime.custom, InboxUtils$BsConsole.isNestedIssues(t$prime.name), t$prime.statistics.used, []));
  });

var placeholder = Curry._1(Printf.sprintf(/* Format */[
          /* String_literal */Block.__(11, [
              "[ ",
              /* String */Block.__(2, [
                  /* No_padding */0,
                  /* String_literal */Block.__(11, [
                      " ]",
                      /* End_of_format */0
                    ])
                ])
            ]),
          "[ %s ]"
        ]), I18N$BsConsole.get(undefined, "attribute"));

var fuzz = Util$BsConsole.Fuzz.filterAttribute;

var AttributeTypeahead = Typeahead$BsConsole.Make({
      toString: toString,
      getCount: getCount,
      renderItem: renderItem,
      placeholder: placeholder,
      fuzz: fuzz
    });

var Fuzz = AttributeTypeahead.Fuzz;

var Styles = AttributeTypeahead.Styles;

var component = AttributeTypeahead.component;

var make$2 = AttributeTypeahead.make;

exports.AttributeItem = AttributeItem;
exports.AttributeTypeahead = AttributeTypeahead;
exports.Fuzz = Fuzz;
exports.Styles = Styles;
exports.component = component;
exports.make = make$2;
/* descriptionStyle Not a pure module */
