// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var BugTasks$BsConsole = require("./BugTasks.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var Belt_MutableMapString = require("bs-platform/lib/js/belt_MutableMapString.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var TokenContext$BsConsole = require("../../context/TokenContext.js");
var ProjectNameContext$BsConsole = require("../../context/ProjectNameContext.js");

var errorCache = Belt_MutableMapString.make(undefined);

function getErrorFromCache(key) {
  return Belt_MutableMapString.get(errorCache, key);
}

function storeErrorInCache(key, error) {
  return Belt_MutableMapString.set(errorCache, key, error);
}

var contentLengthCache = Belt_MutableMapString.make(undefined);

function getContentLengthFromCache(key) {
  return Belt_MutableMapString.get(contentLengthCache, key);
}

function storeContentLengthInCache(key, length) {
  return Belt_MutableMapString.set(contentLengthCache, key, length);
}

function sendApiGetMetricsEvent(responseTime, projectName, objectId, universe) {
  return MetricsEvent$BsConsole.send(/* GenericEvent */Block.__(2, [{
                  name: "query/page_load_error_count",
                  payload: Caml_option.some(Json_encode.object_(/* :: */[
                            /* tuple */[
                              "response-time",
                              responseTime
                            ],
                            /* :: */[
                              /* tuple */[
                                "project",
                                projectName
                              ],
                              /* :: */[
                                /* tuple */[
                                  "object-id",
                                  objectId
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "universe",
                                    universe
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]))
                }]));
}

function fetchHeaders(token, projectName, errorId, setContentLengthRemote, universe, param) {
  var length = Belt_MutableMapString.get(contentLengthCache, projectName + errorId);
  if (length !== undefined) {
    var length$1 = Caml_option.valFromOption(length);
    return Curry._1(setContentLengthRemote, (function (param) {
                  return /* Success */Block.__(0, [length$1]);
                }));
  }
  var headersStart = Date.now();
  var headersTask = BugTasks$BsConsole.getErrorHeaders(projectName, errorId, token);
  new Promise((function (resolve, param) {
          return Task2$BsConsole.handle(token, headersTask, undefined, (function (resp) {
                        var contentLengthOption;
                        if (resp.tag) {
                          contentLengthOption = undefined;
                        } else {
                          var __x = resp[0];
                          contentLengthOption = Caml_option.null_to_opt(__x.get("Content-Length"));
                        }
                        var length = Belt_Option.map(contentLengthOption, (function (opt) {
                                try {
                                  return Caml_format.caml_float_of_string(opt);
                                }
                                catch (exn){
                                  return 1;
                                }
                              }));
                        Belt_MutableMapString.set(contentLengthCache, projectName + errorId, length);
                        Curry._1(setContentLengthRemote, (function (param) {
                                return /* Success */Block.__(0, [length]);
                              }));
                        sendApiGetMetricsEvent(Date.now() - headersStart, projectName, errorId, universe);
                        return resolve(resp);
                      }));
        }));
  
}

function fetchError(token, projectName, errorId, param) {
  var task = BugTasks$BsConsole.getError(projectName, errorId, token);
  return new Promise((function (resolve, param) {
                return Task2$BsConsole.handle(token, task, undefined, (function (resp) {
                              return resolve(resp);
                            }));
              }));
}

function getError(token, projectName, errorId, universe, param) {
  var error = Belt_MutableMapString.get(errorCache, projectName + errorId);
  if (error !== undefined) {
    return Promise.resolve(/* Ok */Block.__(0, [error]));
  }
  var startTime = Date.now();
  var __x = fetchError(token, projectName, errorId, undefined);
  return __x.then((function (errorResult) {
                if (!errorResult.tag) {
                  Belt_MutableMapString.set(errorCache, projectName + errorId, errorResult[0]);
                }
                sendApiGetMetricsEvent(Date.now() - startTime, projectName, errorId, universe);
                return Promise.resolve(errorResult);
              }));
}

function useError(errorId, config) {
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var maybeToken = React.useContext(TokenContext$BsConsole.ctx);
  var universe = config.universe.name;
  var match = React.useState((function () {
          
        }));
  var setError = match[1];
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setContentLengthRemote = match$1[1];
  var contentLengthRemote = match$1[0];
  var isFetchHeadersAvailable = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.61.35");
  React.useEffect((function () {
          if (maybeToken !== undefined) {
            if (isFetchHeadersAvailable) {
              Curry._1(setError, (function (param) {
                      
                    }));
              Curry._1(setContentLengthRemote, (function (param) {
                      return /* Loading */1;
                    }));
              fetchHeaders(maybeToken, projectName, errorId, setContentLengthRemote, universe, undefined);
            } else {
              Curry._1(setError, (function (param) {
                      
                    }));
              Curry._1(setContentLengthRemote, (function (param) {
                      return /* Failure */Block.__(1, ["Fetching headers is not available."]);
                    }));
            }
          } else {
            Curry._1(setError, (function (param) {
                    return /* Error */Block.__(1, [[
                                Task2$BsConsole.ErrorMsg,
                                "Error: missing token."
                              ]]);
                  }));
            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugCache/useError", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x, /* `string */[
                  288368849,
                  "Missing token"
                ]);
          }
          
        }), /* tuple */[
        maybeToken,
        projectName,
        errorId,
        isFetchHeadersAvailable
      ]);
  React.useEffect((function () {
          var cancelled = {
            contents: false
          };
          if (maybeToken !== undefined) {
            if (typeof contentLengthRemote === "number") {
              contentLengthRemote === /* NotAsked */0;
            } else if (contentLengthRemote.tag) {
              if (isFetchHeadersAvailable === false) {
                var __x = getError(maybeToken, projectName, errorId, universe, undefined);
                var __x$1 = __x.then((function (error) {
                        if (!cancelled.contents) {
                          Curry._1(setError, (function (param) {
                                  return error;
                                }));
                        }
                        return Promise.resolve(undefined);
                      }));
                __x$1.catch((function (param) {
                        if (!cancelled.contents) {
                          Curry._1(setError, (function (param) {
                                  return /* Error */Block.__(1, [[
                                              Task2$BsConsole.JsonDecodeError,
                                              undefined
                                            ]]);
                                }));
                        }
                        return Promise.resolve(undefined);
                      }));
              } else {
                Curry._1(setError, (function (param) {
                        return /* Error */Block.__(1, [[
                                    Task2$BsConsole.ErrorMsg,
                                    "Error fetching content length."
                                  ]]);
                      }));
                var __x$2 = Backtrace$BsConsole.Attributes.addString("src_module", "BugCache/useError", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x$2, /* `string */[
                      288368849,
                      "Error fetching content length from header: " + contentLengthRemote[0]
                    ]);
              }
            } else {
              var length = contentLengthRemote[0];
              if (length !== undefined) {
                if (length < 25000000) {
                  var __x$3 = getError(maybeToken, projectName, errorId, universe, undefined);
                  var __x$4 = __x$3.then((function (error) {
                          if (!cancelled.contents) {
                            Curry._1(setError, (function (param) {
                                    return error;
                                  }));
                          }
                          return Promise.resolve(undefined);
                        }));
                  __x$4.catch((function (param) {
                          if (!cancelled.contents) {
                            Curry._1(setError, (function (param) {
                                    return /* Error */Block.__(1, [[
                                                Task2$BsConsole.JsonDecodeError,
                                                undefined
                                              ]]);
                                  }));
                          }
                          return Promise.resolve(undefined);
                        }));
                } else {
                  Curry._1(setError, (function (param) {
                          return /* Error */Block.__(1, [[
                                      Task2$BsConsole.ErrorMsg,
                                      "The error report is too large to render. Please download the report."
                                    ]]);
                        }));
                }
              } else {
                Curry._1(setError, (function (param) {
                        return /* Error */Block.__(1, [[
                                    Task2$BsConsole.ErrorMsg,
                                    "Error fetching content length."
                                  ]]);
                      }));
                var __x$5 = Backtrace$BsConsole.Attributes.addString("src_module", "BugCache/useError", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x$5, /* `string */[
                      288368849,
                      "Fetching header failed to provide content length"
                    ]);
              }
            }
          } else {
            Curry._1(setError, (function (param) {
                    return /* Error */Block.__(1, [[
                                Task2$BsConsole.ErrorMsg,
                                "Error: missing token."
                              ]]);
                  }));
            var __x$6 = Backtrace$BsConsole.Attributes.addString("src_module", "BugCache/useError", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$6, /* `string */[
                  288368849,
                  "Missing token"
                ]);
          }
          return (function (param) {
                    cancelled.contents = true;
                    
                  });
        }), [contentLengthRemote]);
  return match[0];
}

exports.errorCache = errorCache;
exports.getErrorFromCache = getErrorFromCache;
exports.storeErrorInCache = storeErrorInCache;
exports.contentLengthCache = contentLengthCache;
exports.getContentLengthFromCache = getContentLengthFromCache;
exports.storeContentLengthInCache = storeContentLengthInCache;
exports.sendApiGetMetricsEvent = sendApiGetMetricsEvent;
exports.fetchHeaders = fetchHeaders;
exports.fetchError = fetchError;
exports.getError = getError;
exports.useError = useError;
/* errorCache Not a pure module */
