// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var HtmlElementRe = require("bs-webapi/lib/js/src/dom/html/HtmlElementRe.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var InputChip$BsConsole = require("./InputChip.js");
var FilterInput$BsConsole = require("./FilterInput.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var Close = require("@material-ui/icons/Close");
var Delete = require("@material-ui/icons/Delete");
var FilterList = require("@material-ui/icons/FilterList");

var scrollbarStyle = Css.style(/* :: */[
      Css.selector("::-webkit-scrollbar", /* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.grey3)),
            /* :: */[
              Css.height(Css.px(7)),
              /* :: */[
                Css.cursor(/* pointer */-786317123),
                /* [] */0
              ]
            ]
          ]),
      /* :: */[
        Css.selector("::-webkit-scrollbar-thumb", /* :: */[
              Css.important(Css.background(Css.hex(Colors$BsConsole.white))),
              /* :: */[
                Css.borderRadius(Css.px(50)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var filterBar = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.borderRadius(Css.px(3)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.flexWrap(/* nowrap */867913355),
                /* :: */[
                  Css.height(Css.px(40)),
                  /* :: */[
                    Css.minHeight(Css.px(40)),
                    /* :: */[
                      Css.paddingLeft(Css.px(40)),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.position(/* relative */903134412),
                          /* :: */[
                            Css.cursor(/* text */-856044371),
                            /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var filterActionButton = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
      /* :: */[
        Css.height(Css.px(40)),
        /* [] */0
      ]
    ]);

function setContainerFocusStyle(focused) {
  if (focused) {
    return Css.style(/* :: */[
                Css.unsafe("boxShadow", "0px 6px 6px -3px " + (Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20) + (", 0px 10px 14px 1px " + (Colors$BsConsole.octothorpe(Colors$BsConsole.blackA14) + (", 0px 4px 18px 3px " + Colors$BsConsole.octothorpe(Colors$BsConsole.blackA12)))))),
                /* :: */[
                  Css.unsafe("height", "unset"),
                  /* :: */[
                    Css.position(/* absolute */-1013592457),
                    /* :: */[
                      Css.top(Css.px(12)),
                      /* :: */[
                        Css.left(/* zero */-789508312),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]);
  } else {
    return "";
  }
}

function setFilterInputRowStyle(focused) {
  var focusStyle = focused ? Css.style(/* :: */[
          Css.important(Css.flexWrap(/* nowrap */867913355)),
          /* :: */[
            Css.paddingTop(Css.px(5)),
            /* :: */[
              Css.paddingBottom(Css.px(5)),
              /* :: */[
                Css.overflowX(/* scroll */-949692403),
                /* :: */[
                  Css.height(Css.px(47)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]) : "";
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.flexWrap(/* nowrap */867913355),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* :: */[
                focusStyle,
                /* :: */[
                  scrollbarStyle,
                  /* [] */0
                ]
              ]
            ]);
}

function setActionContainer(focused) {
  var focusStyle = focused ? Css.style(/* :: */[
          Css.unsafe("align-self", "flex-start"),
          /* [] */0
        ]) : "";
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* [] */0
                  ]),
              /* :: */[
                focusStyle,
                /* [] */0
              ]
            ]);
}

var Style = {
  scrollbarStyle: scrollbarStyle,
  filterBar: filterBar,
  filterActionButton: filterActionButton,
  setContainerFocusStyle: setContainerFocusStyle,
  setFilterInputRowStyle: setFilterInputRowStyle,
  setActionContainer: setActionContainer
};

function focus(inputRef) {
  if (inputRef === undefined) {
    return ;
  }
  var r = Caml_option.valFromOption(inputRef);
  try {
    r.querySelector("input").focus();
    return r.querySelector("input").select();
  }
  catch (exn){
    return ;
  }
}

function focusChipDelete(param) {
  setTimeout((function (param) {
          var nodeList = document.querySelectorAll(".filter-delete");
          var node = nodeList.item(nodeList.length - 1 | 0);
          if (node == null) {
            return ;
          }
          var element = Curry._1(HtmlElementRe.ofNode, node);
          if (element !== undefined) {
            Caml_option.valFromOption(element).focus();
            return ;
          }
          
        }), 300);
  
}

function getFilterInfo(filter) {
  var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
  var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
  var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
  var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
  return /* tuple */[
          attribute,
          operator,
          value$1
        ];
}

function getHavingInfo(having) {
  var operator = Crdb$BsConsole.HavingOperation.toString(Curry._1(Crdb$BsConsole.Having.getOperation, having));
  var attribute = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Having.getAttribute, having), "");
  var value = Json_decode.string(Crdb$BsConsole.HavingOperation.getValue(Curry._1(Crdb$BsConsole.Having.getOperation, having)));
  var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
  return /* tuple */[
          attribute,
          operator,
          value$1
        ];
}

function FilterInputControl(Props) {
  var route = Props.route;
  var describeRemote = Props.describeRemote;
  var onChange = Props.onChange;
  var projectName = Props.projectName;
  var token = Props.token;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var attribute = Props.attribute;
  var valueOpt = Props.value;
  var filterBarClassNameOpt = Props.filterBarClassName;
  var onContainerFocusedStyleOpt = Props.onContainerFocusedStyle;
  var openQueryBuilder = Props.openQueryBuilder;
  var queryBuilderState = Props.queryBuilderState;
  var queryBuilderOpen = Props.queryBuilderOpen;
  var saveRecentAttribute = Props.saveRecentAttribute;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var filterBarClassName = filterBarClassNameOpt !== undefined ? filterBarClassNameOpt : "";
  var onContainerFocusedStyle = onContainerFocusedStyleOpt !== undefined ? onContainerFocusedStyleOpt : setContainerFocusStyle;
  var match = React.useState((function () {
          return false;
        }));
  var setFocused = match[1];
  var focused = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setIsEditing = match$1[1];
  var filterContainerRef = React.useRef(undefined);
  var filterArray = Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var triageFilters = Belt_Array.keep(filterArray, (function (f) {
          return InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
        }));
  var nonTriageFilters = Belt_Array.keep(filterArray, (function (f) {
          return !InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
        }));
  var filters = Belt_Array.concat(triageFilters, nonTriageFilters);
  React.useEffect((function () {
          if (queryBuilderOpen) {
            
          } else {
            Curry._1(setFocused, (function (param) {
                    return false;
                  }));
          }
          
        }), [queryBuilderOpen]);
  var handleOnBlur = React.useCallback((function (_event) {
          setTimeout((function (param) {
                  var match = filterContainerRef.current;
                  if (queryBuilderOpen) {
                    return Curry._1(setFocused, (function (param) {
                                  return true;
                                }));
                  }
                  if (match === undefined) {
                    return Curry._1(setFocused, (function (param) {
                                  return false;
                                }));
                  }
                  var match$1 = Caml_option.valFromOption(match).querySelector(":focus");
                  if (match$1 == null) {
                    return Curry._1(setFocused, (function (param) {
                                  return false;
                                }));
                  }
                  
                }), 500);
          
        }), /* tuple */[
        filterContainerRef,
        setFocused,
        queryBuilderOpen
      ]);
  var handleOnFocus = React.useCallback((function (_event) {
          return Curry._1(setFocused, (function (param) {
                        return true;
                      }));
        }), [setFocused]);
  var handleOnClick = React.useCallback((function (_event) {
          Curry._1(setFocused, (function (param) {
                  return true;
                }));
          return focus(filterContainerRef.current);
        }), /* tuple */[
        setFocused,
        filterContainerRef
      ]);
  return React.createElement("div", {
              ref: (function (el) {
                  filterContainerRef.current = (el == null) ? undefined : Caml_option.some(el);
                  
                }),
              className: Css.merge(/* :: */[
                    "walkthrough--project_toolbar--filter_typeahead",
                    /* :: */[
                      "filter-input-bar",
                      /* :: */[
                        filterBar,
                        /* :: */[
                          Curry._1(onContainerFocusedStyle, focused),
                          /* :: */[
                            filterBarClassName,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              title: I18N$BsConsole.get(undefined, "Add filter"),
              onFocus: handleOnFocus,
              onBlur: handleOnBlur,
              onClick: handleOnClick
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey5)),
                        /* :: */[
                          Css.height(Css.px(24)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.top(Css.px(8)),
                              /* :: */[
                                Css.left(Css.px(8)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(FilterList.default, { })), React.createElement("div", {
                  className: setFilterInputRowStyle(focused)
                }, Belt_Array.mapWithIndex(Curry._1(Crdb$BsConsole.Havings.toArray, havings), (function (idx, item) {
                        var match = getHavingInfo(item);
                        var isHidden = idx >= 3 && !focused;
                        return React.createElement(InputChip$BsConsole.Filter.make, {
                                    route: route,
                                    isHidden: isHidden,
                                    focused: focused,
                                    describeRemote: describeRemote,
                                    projectName: projectName,
                                    token: token,
                                    aperture: aperture,
                                    havings: havings,
                                    onDelete: focusChipDelete,
                                    isEditable: !queryBuilderOpen,
                                    setIsEditing: (function (v) {
                                        return Curry._1(setIsEditing, (function (param) {
                                                      return v;
                                                    }));
                                      }),
                                    item: /* Having */Block.__(0, [item]),
                                    onChange: onChange,
                                    key: match[0] + ("-" + (match[1] + ("-" + (match[2] + ("__" + String(idx))))))
                                  });
                      })), Belt_Array.mapWithIndex(filters, (function (idx, item) {
                        var match = getFilterInfo(item);
                        var isHidden = idx >= 3 && !focused;
                        return React.createElement(InputChip$BsConsole.Filter.make, {
                                    route: route,
                                    isHidden: isHidden,
                                    focused: focused,
                                    describeRemote: describeRemote,
                                    projectName: projectName,
                                    token: token,
                                    aperture: aperture,
                                    havings: havings,
                                    onDelete: focusChipDelete,
                                    isEditable: !queryBuilderOpen,
                                    setIsEditing: (function (v) {
                                        return Curry._1(setIsEditing, (function (param) {
                                                      return v;
                                                    }));
                                      }),
                                    item: /* Filter */Block.__(1, [item]),
                                    onChange: onChange,
                                    key: match[0] + ("-" + (match[1] + ("-" + (match[2] + ("__" + String(idx))))))
                                  });
                      })), filters.length > 3 && !focused ? React.createElement("span", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* Char_literal */Block.__(12, [
                                    /* "+" */43,
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* String_literal */Block.__(11, [
                                            " more...",
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ]),
                                "+%d more..."
                              ]), filters.length - 3 | 0)) : null, match$1[0] ? null : React.createElement(FilterInput$BsConsole.make, {
                        route: route,
                        primaryPlaceholder: filterArray.length === 0 ? I18N$BsConsole.get(undefined, "Add filters") : I18N$BsConsole.empty(undefined),
                        describeRemote: describeRemote,
                        onFilterChange: (function (newFilter) {
                            Curry._1(saveRecentAttribute, newFilter);
                            var __x = Curry._2(Crdb$BsConsole.Filters.add, newFilter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
                            Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, aperture), havings);
                            setTimeout((function (param) {
                                    return focus(filterContainerRef.current);
                                  }), 500);
                            
                          }),
                        onHavingChange: (function (newHaving) {
                            var nextHavings = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.49.1") ? Curry._2(Crdb$BsConsole.Havings.add, newHaving, havings) : Curry._2(Crdb$BsConsole.Havings.add, newHaving, Crdb$BsConsole.Havings.empty);
                            Curry._2(onChange, aperture, nextHavings);
                            setTimeout((function (param) {
                                    return focus(filterContainerRef.current);
                                  }), 500);
                            
                          }),
                        onDelete: focusChipDelete,
                        projectName: projectName,
                        token: token,
                        containerFocused: focused,
                        aperture: aperture,
                        havings: havings,
                        attribute: attribute,
                        operator: undefined,
                        value: value,
                        openQueryBuilder: openQueryBuilder,
                        queryBuilderState: queryBuilderState,
                        queryBuilderOpen: queryBuilderOpen
                      })), React.createElement("div", {
                  className: setActionContainer(focused) + " smaller-icon"
                }, filterArray.length !== 0 ? React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "Clear all filters",
                        classes: {
                          root: filterActionButton
                        },
                        onClick: (function (e) {
                            e.stopPropagation();
                            return Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, Crdb$BsConsole.Filters.empty, aperture), Crdb$BsConsole.Havings.empty);
                          }),
                        onFocus: (function (e) {
                            return e.stopPropagation();
                          }),
                        title_tooltip: I18N$BsConsole.get(undefined, "Clear all filters"),
                        children: queryBuilderOpen === false ? React.createElement(Close.default, { }) : React.createElement(Delete.default, { })
                      }) : null));
}

var maxChipCount = 3;

var make = FilterInputControl;

exports.maxChipCount = maxChipCount;
exports.Style = Style;
exports.focus = focus;
exports.focusChipDelete = focusChipDelete;
exports.getFilterInfo = getFilterInfo;
exports.getHavingInfo = getHavingInfo;
exports.make = make;
/* scrollbarStyle Not a pure module */
