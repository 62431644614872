// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var attachmentPrefix = "bt-session-replay-";

function hasReplayFiles(attachments) {
  if (typeof attachments === "number") {
    return false;
  }
  if (attachments.tag) {
    return false;
  }
  var sessionEventFiles = attachments[0][0].filter((function (file) {
          return file.name.startsWith(attachmentPrefix);
        }));
  return sessionEventFiles.length !== 0;
}

exports.attachmentPrefix = attachmentPrefix;
exports.hasReplayFiles = hasReplayFiles;
/* No side effect */
