// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var I18N$BsConsole = require("../../I18N.js");

var asciiOnly = new RegExp("^[\x01-\x7f]+$", "i");

var required = I18N$BsConsole.get(undefined, "Required");

var shouldBeLessThan64 = I18N$BsConsole.get(undefined, "Should be less than 64 characters");

var shouldBeAtLeast3 = I18N$BsConsole.get(undefined, "Should be at least 3 characters");

var shouldBeAsciiOnly = I18N$BsConsole.get(undefined, "Should only contain ASCII characters");

function nameValidatorRequired(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var str = Curry._1(accessor, state);
              if (str === "") {
                return /* Error */Block.__(1, [required]);
              } else if (str.split("").length > 64) {
                return /* Error */Block.__(1, [shouldBeLessThan64]);
              } else if (str.split("").length < 3) {
                return /* Error */Block.__(1, [shouldBeAtLeast3]);
              } else if (asciiOnly.test(str) !== true) {
                return /* Error */Block.__(1, [shouldBeAsciiOnly]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

function ruleValidatorRequired(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var str = Curry._1(accessor, state);
              if (str === "") {
                return /* Error */Block.__(1, [required]);
              } else if (str.split("").length > 64) {
                return /* Error */Block.__(1, [shouldBeLessThan64]);
              } else if (asciiOnly.test(str) !== true) {
                return /* Error */Block.__(1, [shouldBeAsciiOnly]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

function ruleValidator(field, accessor) {
  return {
          field: field,
          strategy: /* OnFirstSuccessOrFirstBlur */3,
          dependents: undefined,
          validate: (function (state) {
              var str = Curry._1(accessor, state);
              if (str === "") {
                return /* Ok */Block.__(0, [/* Valid */0]);
              } else if (str.split("").length > 64) {
                return /* Error */Block.__(1, [shouldBeLessThan64]);
              } else if (asciiOnly.test(str) !== true) {
                return /* Error */Block.__(1, [shouldBeAsciiOnly]);
              } else {
                return /* Ok */Block.__(0, [/* Valid */0]);
              }
            })
        };
}

function update(state, value) {
  return {
          name: value
        };
}

var validator = nameValidatorRequired(/* Name */0, (function (state) {
        return state.name;
      }));

var Name = {
  update: update,
  validator: validator
};

var validators = /* :: */[
  validator,
  /* [] */0
];

var AddRuleset = {
  Name: Name,
  validators: validators
};

function update$1(state, value) {
  return {
          function_: value,
          object_: state.object_,
          action: state.action,
          replacement: state.replacement
        };
}

var validator$1 = ruleValidator(/* Function_ */0, (function (state) {
        return state.function_;
      }));

var Function_ = {
  update: update$1,
  validator: validator$1
};

function update$2(state, value) {
  return {
          function_: state.function_,
          object_: value,
          action: state.action,
          replacement: state.replacement
        };
}

var validator$2 = ruleValidator(/* Object_ */1, (function (state) {
        return state.object_;
      }));

var Object_ = {
  update: update$2,
  validator: validator$2
};

function update$3(state, value) {
  return {
          function_: state.function_,
          object_: state.object_,
          action: value,
          replacement: state.replacement
        };
}

var validator$3 = ruleValidatorRequired(/* Action */2, (function (state) {
        return state.action;
      }));

var Action = {
  update: update$3,
  validator: validator$3
};

function update$4(state, value) {
  return {
          function_: state.function_,
          object_: state.object_,
          action: state.action,
          replacement: value
        };
}

var validator$4 = ruleValidator(/* Replacement */3, (function (state) {
        return state.replacement;
      }));

var Replacement = {
  update: update$4,
  validator: validator$4
};

var validators_001 = /* :: */[
  validator$2,
  /* :: */[
    validator$3,
    /* :: */[
      validator$4,
      /* [] */0
    ]
  ]
];

var validators$1 = /* :: */[
  validator$1,
  validators_001
];

var AddRule = {
  Function_: Function_,
  Object_: Object_,
  Action: Action,
  Replacement: Replacement,
  validators: validators$1
};

exports.asciiOnly = asciiOnly;
exports.required = required;
exports.shouldBeLessThan64 = shouldBeLessThan64;
exports.shouldBeAtLeast3 = shouldBeAtLeast3;
exports.shouldBeAsciiOnly = shouldBeAsciiOnly;
exports.nameValidatorRequired = nameValidatorRequired;
exports.ruleValidatorRequired = ruleValidatorRequired;
exports.ruleValidator = ruleValidator;
exports.AddRuleset = AddRuleset;
exports.AddRule = AddRule;
/* asciiOnly Not a pure module */
