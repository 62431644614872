// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function decodeFile(json) {
  return {
          archiveId: Json_decode.field("archive_id", Json_decode.string, json),
          duplicateSymbols: Json_decode.field("duplicate_symbols", Json_decode.$$int, json),
          errors: Json_decode.field("errors", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          fileSize: Json_decode.field("file_size", Json_decode.$$int, json),
          invalidSymbols: Json_decode.field("invalid_symbols", Json_decode.$$int, json),
          newSymbols: Json_decode.field("new_symbols", Json_decode.$$int, json),
          uploadTime: Json_decode.field("upload_time", Json_decode.$$int, json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

function decodeArchive(json) {
  return {
          tag: Json_decode.field("tag", Json_decode.string, json),
          files: Json_decode.field("files", (function (param) {
                  return Json_decode.list(decodeFile, param);
                }), json)
        };
}

function decode(json) {
  return Json_decode.field("response", (function (json) {
                return Json_decode.field("archives", (function (param) {
                              return Json_decode.list(decodeArchive, param);
                            }), json);
              }), json);
}

exports.decodeFile = decodeFile;
exports.decodeArchive = decodeArchive;
exports.decode = decode;
/* No side effect */
