// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Paper = require("@material-ui/core/Paper");
var Help = require("@material-ui/icons/Help");
var Tooltip = require("@material-ui/core/Tooltip");
var Warning = require("@material-ui/icons/Warning");
var ArrowForward = require("@material-ui/icons/ArrowForward");

function BugInteractiveTooltip$Row(Props) {
  var title = Props.title;
  var content = Props.content;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.wordBreak(Css.breakAll),
                    /* :: */[
                      Css.lineHeight(Css.px(13)),
                      /* :: */[
                        Css.paddingTop(Css.px(5)),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.fontWeight(Css.medium),
                        /* [] */0
                      ])
                }, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  ": ",
                                  /* End_of_format */0
                                ])
                            ]),
                          "%s: "
                        ]), title)), I18N$BsConsole.showSkip(content));
}

var Row = {
  make: BugInteractiveTooltip$Row
};

function BugInteractiveTooltip$DisclaimerRow(Props) {
  var text = Props.text;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.wordBreak(Css.breakAll),
                    /* :: */[
                      Css.lineHeight(Css.px(13)),
                      /* :: */[
                        Css.paddingTop(Css.px(5)),
                        /* :: */[
                          Css.fontStyle(/* italic */107228912),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.showSkip(text));
}

var DisclaimerRow = {
  make: BugInteractiveTooltip$DisclaimerRow
};

function BugInteractiveTooltip(Props) {
  var onHeaderClick = Props.onHeaderClick;
  var headerText = Props.headerText;
  var headerColor = Props.headerColor;
  var headerIcon = Props.headerIcon;
  var contents = Props.contents;
  var children = Props.children;
  var tmp;
  if (headerText !== undefined) {
    var tmp$1;
    var exit = 0;
    if (headerIcon !== undefined && headerIcon < 969641574) {
      tmp$1 = React.createElement(Help.default, {
            color: "inherit",
            fontSize: "inherit"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = React.createElement(Warning.default, {
            color: "inherit",
            fontSize: "inherit"
          });
    }
    var tmp$2 = {
      className: Css.style(/* :: */[
            Css.cursor(Belt_Option.isSome(onHeaderClick) ? /* pointer */-786317123 : /* auto */-1065951377),
            /* :: */[
              Css.borderTopRightRadius(Css.px(4)),
              /* :: */[
                Css.borderTopLeftRadius(Css.px(4)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* :: */[
                    Css.background(Css.hex(Belt_Option.getWithDefault(headerColor, Colors$BsConsole.orange))),
                    /* :: */[
                      Css.padding4(Css.px(10), Css.px(12), Css.px(8), Css.px(10)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]),
      children: React.createElement(Row2$BsConsole.make, {
            alignItems: /* center */98248149,
            className: Css.style(/* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]),
            children: null
          }, React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.fontSize(Css.px(14)),
                      /* :: */[
                        Css.paddingRight(Css.px(8)),
                        /* [] */0
                      ]
                    ])
              }, tmp$1), React.createElement(Col2$BsConsole.make, {
                children: React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.fontSize(Css.px(13)),
                            /* [] */0
                          ])
                    }, I18N$BsConsole.showSkip(headerText))
              }), Belt_Option.isSome(onHeaderClick) ? React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginLeft(Css.auto),
                        /* :: */[
                          Css.alignSelf(Css.center),
                          /* :: */[
                            Css.width(Css.px(18)),
                            /* :: */[
                              Css.height(Css.px(18)),
                              /* :: */[
                                Css.fontSize(Css.px(18)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(ArrowForward.default, {
                      fontSize: "inherit"
                    })) : null)
    };
    if (onHeaderClick !== undefined) {
      tmp$2.onClick = Caml_option.valFromOption(onHeaderClick);
    }
    tmp = React.createElement(Col2$BsConsole.make, tmp$2);
  } else {
    tmp = null;
  }
  return React.createElement(Tooltip.default, {
              classes: {
                popper: Css.style(/* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                tooltip: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.padding(Css.zero),
                        /* :: */[
                          Css.background(Css.none),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              interactive: true,
              enterDelay: 750,
              children: children,
              leaveDelay: 300,
              title: React.createElement(Paper.default, {
                    elevation: 8,
                    children: React.createElement(Col2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.minWidth(Css.px(280)),
                                /* :: */[
                                  Css.maxWidth(Css.px(400)),
                                  /* [] */0
                                ]
                              ]),
                          onClick: (function (e) {
                              return e.stopPropagation();
                            }),
                          children: null
                        }, tmp, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.background(Css.hex(Colors$BsConsole.white)),
                                    /* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.padding3(Css.px(5), Css.px(12), Css.px(10)),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.grey2)),
                                          headerText !== undefined ? /* :: */[
                                              Css.borderBottomLeftRadius(Css.px(4)),
                                              /* :: */[
                                                Css.borderBottomRightRadius(Css.px(4)),
                                                /* [] */0
                                              ]
                                            ] : /* :: */[
                                              Css.borderRadius(Css.px(4)),
                                              /* [] */0
                                            ]
                                        ]
                                      ]
                                    ]
                                  ])
                            }, contents))
                  })
            });
}

var make = BugInteractiveTooltip;

exports.Row = Row;
exports.DisclaimerRow = DisclaimerRow;
exports.make = make;
/* Css Not a pure module */
