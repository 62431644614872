// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");

function removeReleaseStringFromVersion(version) {
  var stringToRemove = "release/coronerd/";
  var slashIndex = version.indexOf(stringToRemove);
  if (slashIndex === 0) {
    return /* tuple */[
            true,
            version.slice(stringToRemove.length, version.length)
          ];
  } else {
    return /* tuple */[
            false,
            version
          ];
  }
}

var dedupe_coroner_version = "1.39.0";

var ver = window._BACKTRACE_CORONER_VERSION;

var dedupe_config_flag;

if (ver == null) {
  dedupe_config_flag = false;
} else {
  var validVersion = Semver.valid(ver);
  if (validVersion !== undefined) {
    dedupe_config_flag = Semver.gte(validVersion, dedupe_coroner_version) ? true : false;
  } else {
    var hyphenIndex = ver.indexOf("-");
    var version = hyphenIndex > -1 ? ver.slice(0, hyphenIndex) : ver;
    var validV = Semver.valid(version);
    if (validV !== undefined) {
      dedupe_config_flag = Semver.gte(validV, dedupe_coroner_version) ? true : false;
    } else {
      var match = removeReleaseStringFromVersion(version);
      var match$1 = Semver.valid(match[1]);
      dedupe_config_flag = match$1 !== undefined && Semver.gte(match$1, dedupe_coroner_version) ? true : false;
    }
  }
}

exports.removeReleaseStringFromVersion = removeReleaseStringFromVersion;
exports.dedupe_config_flag = dedupe_config_flag;
/* ver Not a pure module */
