// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Task2$BsConsole = require("../../Task2.js");

var resource = "error_log";

function errFromJson(json) {
  return {
          message: Json_decode.field("message", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.$$int, json)
        };
}

function fromJson(json) {
  return {
          timestamp: Json_decode.field("timestamp", Json_decode.$$float, json),
          message: Json_decode.field("message", Json_decode.string, json),
          transient: Json_decode.field("transient", Json_decode.bool, json)
        };
}

function responseFromJson(json) {
  return {
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.array(fromJson, param);
                }), json),
          nextPageToken: Json_decode.field("next_page_token", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function get(path, universeName, projectName, alertId, includeTransientOpt, startTimeOpt, endTimeOpt, limitOpt, pageToken, param) {
  var includeTransient = includeTransientOpt !== undefined ? includeTransientOpt : true;
  var startTime = startTimeOpt !== undefined ? startTimeOpt : 0.0;
  var endTime = endTimeOpt !== undefined ? endTimeOpt : 0.0;
  var limit = limitOpt !== undefined ? limitOpt : 50;
  return Task2$BsConsole.make(/* Custom */Block.__(1, [encodeURI("" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + ("&alert_id=" + (String(alertId) + ("&limit=" + (String(limit) + ("&start_time=" + (String(startTime) + ("&end_time=" + (String(endTime) + ("&include_transient=" + (String(includeTransient) + ""))))))))))))))))) + (
                      pageToken !== undefined ? "&page_token=" + (String(pageToken) + "") : ""
                    ))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [responseFromJson(respBag.json)]);
              }), undefined, undefined);
}

exports.resource = resource;
exports.errFromJson = errFromJson;
exports.fromJson = fromJson;
exports.responseFromJson = responseFromJson;
exports.get = get;
/* Task2-BsConsole Not a pure module */
