// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function getAggregation(columns, group, searchAttribute, searchAgg) {
  var aggs = List.mapi((function (i, aggregation) {
          return /* tuple */[
                  List.nth(columns, i),
                  aggregation
                ];
        }), group.aggregations);
  var result = Belt_List.getBy(aggs, (function (param) {
          var c = param[0];
          var op = c.op;
          if (op !== undefined) {
            return Caml_obj.caml_equal(/* tuple */[
                        c.name,
                        op
                      ], /* tuple */[
                        searchAttribute,
                        searchAgg
                      ]);
          } else {
            return false;
          }
        }));
  var maybeAggregation = result !== undefined ? result[1] : undefined;
  if (maybeAggregation === undefined) {
    return ;
  }
  switch (maybeAggregation.tag | 0) {
    case /* Tail */1 :
    case /* Min */6 :
    case /* Max */7 :
        return ;
    default:
      return maybeAggregation;
  }
}

function unwrapCallstack(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  switch (maybeShape.tag | 0) {
    case /* Tail */1 :
        var match = maybeShape[1];
        if (match !== undefined && match.tag === /* Callstack */9) {
          return match[0];
        } else {
          return ;
        }
    case /* Head */3 :
        var match$1 = maybeShape[1];
        if (match$1 !== undefined && match$1.tag === /* Callstack */9) {
          return match$1[0];
        } else {
          return ;
        }
    default:
      return ;
  }
}

function unwrapClassifiers(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  switch (maybeShape.tag | 0) {
    case /* Tail */1 :
        var match = maybeShape[1];
        if (match !== undefined && match.tag === /* Classifiers */4) {
          return match[0];
        } else {
          return ;
        }
    case /* Head */3 :
        var match$1 = maybeShape[1];
        if (match$1 !== undefined && match$1.tag === /* Classifiers */4) {
          return match$1[0];
        } else {
          return ;
        }
    default:
      return ;
  }
}

function unwrapTags(maybeShape) {
  var c;
  if (maybeShape === undefined) {
    return ;
  }
  switch (maybeShape.tag | 0) {
    case /* Tail */1 :
        var match = maybeShape[1];
        if (match === undefined) {
          return ;
        }
        if (match.tag !== /* IssueTags */15) {
          return ;
        }
        c = match[0];
        break;
    case /* Head */3 :
        var match$1 = maybeShape[1];
        if (match$1 === undefined) {
          return ;
        }
        if (match$1.tag !== /* IssueTags */15) {
          return ;
        }
        c = match$1[0];
        break;
    default:
      return ;
  }
  if (c && !(c[0] === "" && !c[1])) {
    return c;
  }
  
}

function unwrapHeadFirstSeen(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Head */3) {
    return ;
  }
  var match = maybeShape[1];
  if (match !== undefined && match.tag === /* UnixTimestamp */2) {
    return Caml_option.some(match[0]);
  }
  
}

function unwrapDateRange(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Range */8) {
    return ;
  }
  var match = maybeShape[1];
  if (match === undefined) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 === undefined) {
    return ;
  }
  if (match$1.tag !== /* UnixTimestamp */2) {
    return ;
  }
  var match$2 = match[1];
  if (match$2 !== undefined && match$2.tag === /* UnixTimestamp */2) {
    return /* tuple */[
            match$1[0],
            match$2[0]
          ];
  }
  
}

function unwrapFloatRange(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Range */8) {
    return ;
  }
  var match = maybeShape[1];
  if (match === undefined) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 === undefined) {
    return ;
  }
  if (match$1.tag) {
    return ;
  }
  var match$2 = match$1[0];
  if (typeof match$2 === "number") {
    return ;
  }
  if (match$2[0] !== 365180284) {
    return ;
  }
  var match$3 = match[1];
  if (match$3 === undefined) {
    return ;
  }
  if (match$3.tag) {
    return ;
  }
  var match$4 = match$3[0];
  if (typeof match$4 === "number" || match$4[0] !== 365180284) {
    return ;
  } else {
    return /* tuple */[
            match$2[1],
            match$4[1]
          ];
  }
}

function unwrapHeadArrayStrings(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Head */3) {
    return ;
  }
  var match = maybeShape[1];
  if (match === undefined) {
    return ;
  }
  if (match.tag) {
    return ;
  }
  var match$1 = match[0];
  if (typeof match$1 === "number" || match$1[0] !== -976970511) {
    return ;
  } else {
    return Belt_List.fromArray(match$1[1].split(" "));
  }
}

function unwrapTimestampBinData(bin) {
  return List.map((function (param) {
                var maybeT$prime$prime = param[1];
                var maybeT$prime = param[0];
                if (maybeT$prime !== undefined && maybeT$prime.tag === /* UnixTimestamp */2 && maybeT$prime$prime !== undefined && maybeT$prime$prime.tag === /* UnixTimestamp */2) {
                  return /* tuple */[
                          maybeT$prime[0],
                          maybeT$prime$prime[0],
                          param[2]
                        ];
                }
                
              }), bin);
}

function unwrapTimestampBin(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Bin */2) {
    return ;
  }
  var bin = maybeShape[1];
  if (bin !== undefined) {
    return unwrapTimestampBinData(bin);
  }
  
}

function unwrapTimestampBin2(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Bin */2) {
    return ;
  }
  var bin = maybeShape[1];
  if (bin !== undefined) {
    return bin;
  }
  
}

function unwrapFloatBinData(bin) {
  return List.map((function (param) {
                var maybeT$prime$prime = param[1];
                var maybeT$prime = param[0];
                if (maybeT$prime === undefined) {
                  return ;
                }
                if (maybeT$prime.tag) {
                  return ;
                }
                var match = maybeT$prime[0];
                if (typeof match === "number") {
                  return ;
                }
                if (match[0] !== 365180284) {
                  return ;
                }
                if (maybeT$prime$prime === undefined) {
                  return ;
                }
                if (maybeT$prime$prime.tag) {
                  return ;
                }
                var match$1 = maybeT$prime$prime[0];
                if (typeof match$1 === "number" || match$1[0] !== 365180284) {
                  return ;
                } else {
                  return /* tuple */[
                          match[1],
                          match$1[1],
                          param[2]
                        ];
                }
              }), bin);
}

function unwrapFloatBin(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Bin */2) {
    return ;
  }
  var bin = maybeShape[1];
  if (bin !== undefined) {
    return unwrapFloatBinData(bin);
  }
  
}

function unwrapUniqueCount(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag) {
    return ;
  }
  var v = maybeShape[1];
  if (v !== undefined) {
    return v;
  }
  
}

function unwrapDistribution(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Distribution */9) {
    return ;
  }
  var v = maybeShape[1];
  if (v !== undefined) {
    return v;
  }
  
}

function unwrapBin(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Bin */2) {
    return ;
  }
  var v = maybeShape[1];
  if (v !== undefined) {
    return v;
  }
  
}

function unwrapSum(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Sum */4) {
    return ;
  }
  var v = maybeShape[1];
  if (v !== undefined) {
    return v;
  }
  
}

function unwrapMean(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Mean */5) {
    return ;
  }
  var v = maybeShape[1];
  if (v !== undefined) {
    return v;
  }
  
}

function unwrapHeadString(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Head */3) {
    return ;
  }
  var match = maybeShape[1];
  if (match === undefined) {
    return ;
  }
  if (match.tag) {
    return ;
  }
  var match$1 = match[0];
  if (typeof match$1 === "number" || match$1[0] !== -976970511) {
    return ;
  } else {
    return match$1[1];
  }
}

function unwrapTickets(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  switch (maybeShape.tag | 0) {
    case /* Tail */1 :
        var match = maybeShape[1];
        if (match === undefined) {
          return ;
        }
        if (match.tag !== /* IssueTickets */13) {
          return ;
        }
        var c = match[0];
        if (c) {
          return c;
        } else {
          return ;
        }
    case /* Head */3 :
        var match$1 = maybeShape[1];
        if (match$1 === undefined) {
          return ;
        }
        if (match$1.tag !== /* IssueTickets */13) {
          return ;
        }
        var c$1 = match$1[0];
        if (c$1) {
          return c$1;
        } else {
          return ;
        }
    default:
      return ;
  }
}

function unwrapIssueState(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Head */3) {
    return ;
  }
  var match = maybeShape[1];
  if (match !== undefined && match.tag === /* IssueState */14) {
    return match[0];
  }
  
}

function unwrapIssueId(maybeShape) {
  if (maybeShape === undefined) {
    return ;
  }
  if (maybeShape.tag !== /* Head */3) {
    return ;
  }
  var match = maybeShape[1];
  if (match !== undefined && !match.tag) {
    return match[0];
  }
  
}

exports.getAggregation = getAggregation;
exports.unwrapCallstack = unwrapCallstack;
exports.unwrapClassifiers = unwrapClassifiers;
exports.unwrapTags = unwrapTags;
exports.unwrapHeadFirstSeen = unwrapHeadFirstSeen;
exports.unwrapDateRange = unwrapDateRange;
exports.unwrapFloatRange = unwrapFloatRange;
exports.unwrapHeadArrayStrings = unwrapHeadArrayStrings;
exports.unwrapTimestampBinData = unwrapTimestampBinData;
exports.unwrapTimestampBin = unwrapTimestampBin;
exports.unwrapTimestampBin2 = unwrapTimestampBin2;
exports.unwrapFloatBinData = unwrapFloatBinData;
exports.unwrapFloatBin = unwrapFloatBin;
exports.unwrapUniqueCount = unwrapUniqueCount;
exports.unwrapDistribution = unwrapDistribution;
exports.unwrapBin = unwrapBin;
exports.unwrapSum = unwrapSum;
exports.unwrapMean = unwrapMean;
exports.unwrapHeadString = unwrapHeadString;
exports.unwrapTickets = unwrapTickets;
exports.unwrapIssueState = unwrapIssueState;
exports.unwrapIssueId = unwrapIssueId;
/* No side effect */
