// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../../crdb.js");
var Util$BsConsole = require("../../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Parse$BsConsole = require("../../bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("../../Task2.js");
var Tasks$BsConsole = require("../../Tasks.js");
var Unpack$BsConsole = require("../../bs-crdb-response/src/Unpack.js");
var ApiList$BsConsole = require("../../apiList.js");
var BugError$BsConsole = require("./BugError.js");
var ObjectID$BsConsole = require("../../ObjectID.js");
var BugDefect$BsConsole = require("./BugDefect.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var ResponseSelect$BsConsole = require("../../bs-crdb-response/src/ResponseSelect.js");
var ProjectNameContext$BsConsole = require("../../context/ProjectNameContext.js");

function useErrors(aperture, page) {
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var task = React.useMemo((function () {
          var query_001 = Curry._2(Crdb$BsConsole.Select.add, "fingerprint;issues;invariants", Curry._2(Crdb$BsConsole.Select.add, "fingerprint", Crdb$BsConsole.Select.empty));
          var query_002 = Crdb$BsConsole.Sort.defaultSelect;
          var query_003 = Caml_option.some(Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial));
          var query = /* Select */Block.__(1, [
              aperture,
              query_001,
              query_002,
              query_003
            ]);
          return Tasks$BsConsole.crdb(query, projectName, (function (param) {
                        var json = param.json;
                        try {
                          var columns = ResponseSelect$BsConsole.columns(json);
                          var rows = Belt_Array.map(Belt_List.toArray(Unpack$BsConsole.response2PointOh(json, columns)), Belt_List.toArray);
                          var errorInfoRows = Belt_Array.map(rows, (function (row) {
                                  var nonNullFields = Belt_Array.keepMap(row, (function (param) {
                                          var k = param[0];
                                          return Belt_Option.map(param[1], (function (v) {
                                                        return /* tuple */[
                                                                k,
                                                                v
                                                              ];
                                                      }));
                                        }));
                                  var param = Belt_Option.getExn(Caml_option.undefined_to_opt(nonNullFields.find((function (param) {
                                                  return param[0] === "fingerprint";
                                                }))));
                                  var v = param[1];
                                  var tmp;
                                  var exit = 0;
                                  var exit$1 = 0;
                                  switch (v.tag | 0) {
                                    case /* Raw */0 :
                                    case /* None_ */1 :
                                        exit$1 = 2;
                                        break;
                                    default:
                                      exit = 1;
                                  }
                                  if (exit$1 === 2) {
                                    var match = v[0];
                                    if (typeof match === "number" || match[0] !== -976970511) {
                                      exit = 1;
                                    } else {
                                      tmp = match[1];
                                    }
                                  }
                                  if (exit === 1) {
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "BugTasks.re",
                                            48,
                                            37
                                          ]
                                        ];
                                  }
                                  var param$1 = Belt_Option.getExn(Caml_option.undefined_to_opt(nonNullFields.find((function (param) {
                                                  return param[0] === "id";
                                                }))));
                                  var v$1 = param$1[1];
                                  var tmp$1;
                                  var exit$2 = 0;
                                  switch (v$1.tag | 0) {
                                    case /* Raw */0 :
                                        var match$1 = v$1[0];
                                        if (typeof match$1 === "number") {
                                          exit$2 = 1;
                                        } else {
                                          var variant = match$1[0];
                                          if (variant !== -976970511) {
                                            if (variant !== 365180284) {
                                              exit$2 = 1;
                                            } else {
                                              tmp$1 = match$1[1].toString();
                                            }
                                          } else {
                                            tmp$1 = match$1[1];
                                          }
                                        }
                                        break;
                                    case /* None_ */1 :
                                        var match$2 = v$1[0];
                                        if (typeof match$2 === "number" || match$2[0] !== -976970511) {
                                          exit$2 = 1;
                                        } else {
                                          tmp$1 = match$2[1];
                                        }
                                        break;
                                    case /* ObjectID */17 :
                                        tmp$1 = ObjectID$BsConsole.toDecString(v$1[0]);
                                        break;
                                    default:
                                      exit$2 = 1;
                                  }
                                  if (exit$2 === 1) {
                                    throw [
                                          Caml_builtin_exceptions.assert_failure,
                                          /* tuple */[
                                            "BugTasks.re",
                                            62,
                                            37
                                          ]
                                        ];
                                  }
                                  return {
                                          fingerprint: tmp,
                                          errorId: tmp$1
                                        };
                                }));
                          var meta = Parse$BsConsole.Aggregate.metadata(json);
                          return /* Ok */Block.__(0, [/* tuple */[
                                      errorInfoRows,
                                      meta
                                    ]]);
                        }
                        catch (exn){
                          return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                        }
                      }), undefined);
        }), /* tuple */[
        projectName,
        aperture,
        page
      ]);
  return Task2$BsConsole.use(undefined, task, undefined);
}

function getErrorHeaders(projectName, errorId, token) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/get?resource=json.gz&project=" + (projectName + ("&object=" + (errorId + ("&token=" + token))))]), undefined, /* Head */2, undefined, (function (param) {
                return /* Ok */Block.__(0, [param.headers]);
              }), undefined, undefined);
}

function getError(projectName, errorId, token) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/get?resource=json.gz&project=" + (projectName + ("&object=" + (errorId + ("&token=" + token))))]), undefined, /* Get */0, undefined, (function (param) {
                var headers = param.headers;
                var json = param.json;
                var maybeFingerprint = headers.get("X-Object-Fingerprint");
                if (maybeFingerprint === null) {
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.ErrorMsg,
                              "Failed to fetch fingerprint from header"
                            ]]);
                }
                var bugFormat = Belt_Option.getWithDefault(Caml_option.null_to_opt(headers.get("X-Object-Format")), "unknown");
                var func = Curry._1(BugError$BsConsole.fromJson, maybeFingerprint === null ? undefined : Caml_option.some(maybeFingerprint));
                var func$1 = Curry._1(func, errorId);
                var error = Curry._1((function (param) {
                        return Curry._2(func$1, param, json);
                      }), bugFormat);
                return /* Ok */Block.__(0, [error]);
              }), undefined, undefined);
}

function fetchDefects(endpoint, universe, projectName, build, functionName, fileName, fingerprint) {
  var baseUrl = endpoint + ("/correlate/" + (universe + ("/" + (projectName + ("/" + fingerprint)))));
  var url = build !== undefined ? (
      functionName !== undefined ? (
          fileName !== undefined ? baseUrl + ("?build=" + (build + ("&functionName=" + (functionName + ("&fileName=" + fileName))))) : baseUrl + ("?build=" + (build + ("&functionName=" + functionName)))
        ) : (
          fileName !== undefined ? baseUrl + ("?build=" + (build + ("&fileName=" + fileName))) : baseUrl + ("?build=" + build)
        )
    ) : (
      functionName !== undefined ? (
          fileName !== undefined ? baseUrl + ("?functionName=" + (functionName + ("&fileName=" + fileName))) : baseUrl + ("?functionName=" + functionName)
        ) : (
          fileName !== undefined ? baseUrl + ("?fileName=" + fileName) : baseUrl
        )
    );
  return Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return /* Ok */Block.__(0, [Json_decode.list(BugDefect$BsConsole.decodeDefects, param.json.defects)]);
              }), undefined, undefined);
}

function fetchAttachments(projectName, errorId) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/list?project=" + (projectName + ("&object=" + errorId))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Belt_List.toArray(ApiList$BsConsole.decodeExn(param.json))]);
              }), undefined, undefined);
}

function fromJson(json) {
  return {
          symbolServerCount: Json_decode.field("numberOfSymbolServers", Json_decode.$$int, json),
          skiplist: Json_decode.field("skipList", (function (param) {
                  return Json_decode.array(Util$BsConsole.identity, param);
                }), json),
          whitelist: Json_decode.field("whiteList", (function (param) {
                  return Json_decode.array(Util$BsConsole.identity, param);
                }), json),
          blacklist: Json_decode.field("blackList", (function (param) {
                  return Json_decode.array(Util$BsConsole.identity, param);
                }), json),
          logs: [],
          downloadInProgress: Json_decode.field("downloadInProgress", Json_decode.bool, json),
          symbolsUploadedManually: Json_decode.field("symbolsOnServer", Json_decode.bool, json)
        };
}

var SymbolData = {
  fromJson: fromJson
};

var idx = {
  contents: 0
};

function fromSymbolData(symbolData) {
  if (symbolData.downloadInProgress) {
    return /* DownloadInProgress */0;
  } else if (symbolData.blacklist.length !== 0) {
    return /* SymbolInBlacklist */3;
  } else if (symbolData.skiplist.length !== 0) {
    return /* SymbolInSkiplist */4;
  } else if (symbolData.logs.length !== 0) {
    return /* ErrorsInLogs */2;
  } else if (symbolData.symbolsUploadedManually) {
    return /* NoSymbolsForDebugId */1;
  } else if (symbolData.whitelist.length === 0) {
    return /* AddSymbol */5;
  } else {
    return /* UnknownError */6;
  }
}

var SymbolInformation = {
  test: false,
  idx: idx,
  fromSymbolData: fromSymbolData
};

function getSymbolData(universeName, projectName, debugFile, debugId, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/symbold/symbols/status/" + (String(universeName) + ("/" + (String(projectName) + ("/" + (String(debugFile) + ("/" + (String(debugId) + "")))))))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.protocol + ("//" + location.hostname)
              ]], (function (param) {
                return /* Ok */Block.__(0, [fromJson(param.json)]);
              }), undefined, undefined);
}

function fromJson$1(json) {
  return {
          fileContents: Json_decode.field("fileContents", Json_decode.string, json),
          fileContentsHighlighted: Json_decode.optional((function (param) {
                  return Json_decode.field("fileContentsHighlighted", Json_decode.string, param);
                }), json),
          blame: Json_decode.optional((function (param) {
                  return Json_decode.field("blame", Json_decode.string, param);
                }), json),
          timeStamps: Json_decode.optional((function (param) {
                  return Json_decode.field("timeStamps", Json_decode.string, param);
                }), json),
          commits: Json_decode.optional((function (param) {
                  return Json_decode.field("commits", Json_decode.string, param);
                }), json),
          sourceCommit: Json_decode.field("sourceCommit", Json_decode.string, json),
          sourceCommitDate: Json_decode.field("sourceCommitDate", Json_decode.string, json),
          sourceRepo: Json_decode.field("sourceRepo", Json_decode.string, json),
          sourceFilepath: Json_decode.field("sourceFilepath", Json_decode.string, json),
          startLine: Json_decode.optional((function (param) {
                  return Json_decode.field("startLine", Json_decode.$$int, param);
                }), json),
          totalLines: Json_decode.optional((function (param) {
                  return Json_decode.field("totalLines", Json_decode.$$int, param);
                }), json)
        };
}

var FileNotFound = Caml_exceptions.create("BugTasks-BsConsole.FetchedSourceCode.FileNotFound");

function $$fetch(projectName, universeName, attributes, endpoint, directory, fileName, funcName, objName, faultingLine, surroundingLines, includeBlame, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(endpoint) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/show")))))]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "projectName",
                        projectName
                      ],
                      /* :: */[
                        /* tuple */[
                          "universeName",
                          universeName
                        ],
                        /* :: */[
                          /* tuple */[
                            "objectName",
                            Belt_Option.mapWithDefault(objName, null, (function (prim) {
                                    return prim;
                                  }))
                          ],
                          /* :: */[
                            /* tuple */[
                              "functionName",
                              Belt_Option.mapWithDefault(funcName, null, (function (prim) {
                                      return prim;
                                    }))
                            ],
                            /* :: */[
                              /* tuple */[
                                "faultingLine",
                                Belt_Option.mapWithDefault(faultingLine, null, (function (prim) {
                                        return prim;
                                      }))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "surroundingLines",
                                  surroundingLines
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "directory",
                                    Belt_Option.mapWithDefault(directory, null, (function (prim) {
                                            return prim;
                                          }))
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "fileName",
                                      Belt_Option.mapWithDefault(fileName, null, (function (prim) {
                                              return prim;
                                            }))
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "attributes",
                                        attributes
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "includeBlame",
                                          includeBlame
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.protocol + ("//" + location.hostname)
              ]], (function (param) {
                var json = param.json;
                try {
                  return /* Ok */Block.__(0, [fromJson$1(json)]);
                }
                catch (parseErr){
                  var errno = Json_decode.field("error_number", Json_decode.$$int, json);
                  if (errno === 0) {
                    var errmsg = Json_decode.optional((function (param) {
                            return Json_decode.field("error_message", Json_decode.string, param);
                          }), json);
                    return /* Error */Block.__(1, [[
                                FileNotFound,
                                errmsg
                              ]]);
                  }
                  throw parseErr;
                }
              }), undefined, undefined);
}

var FetchedSourceCode = {
  fromJson: fromJson$1,
  FileNotFound: FileNotFound,
  $$fetch: $$fetch
};

exports.useErrors = useErrors;
exports.getErrorHeaders = getErrorHeaders;
exports.getError = getError;
exports.fetchDefects = fetchDefects;
exports.fetchAttachments = fetchAttachments;
exports.SymbolData = SymbolData;
exports.SymbolInformation = SymbolInformation;
exports.getSymbolData = getSymbolData;
exports.FetchedSourceCode = FetchedSourceCode;
/* react Not a pure module */
