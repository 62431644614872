// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");

function get(format) {
  if (format >= 528330184) {
    if (format >= 813535941) {
      if (format !== 813535943) {
        if (format >= 939376143) {
          if (format >= 946856507) {
            return I18N$BsConsole.get(undefined, "UUID");
          } else {
            return I18N$BsConsole.get(undefined, "Gigabytes");
          }
        } else if (format >= 870530776) {
          return I18N$BsConsole.get(undefined, "None");
        } else {
          return I18N$BsConsole.get(undefined, "IPv4");
        }
      } else {
        return I18N$BsConsole.get(undefined, "IPv6");
      }
    } else if (format !== 559906156) {
      if (format >= 746836435) {
        if (format >= 787392782) {
          return I18N$BsConsole.get(undefined, "UNIX timestamp");
        } else {
          return I18N$BsConsole.get(undefined, "Hostname");
        }
      } else if (format >= 668917343) {
        return I18N$BsConsole.get(undefined, "Seconds");
      } else {
        return I18N$BsConsole.get(undefined, "Semantic version");
      }
    } else {
      return I18N$BsConsole.get(undefined, "GPS Timestamp");
    }
  } else if (format >= -324157174) {
    if (format !== -32400353) {
      if (format >= 256858674) {
        if (format >= 519057866) {
          return I18N$BsConsole.get(undefined, "Callstack");
        } else {
          return I18N$BsConsole.get(undefined, "Milliseconds");
        }
      } else if (format >= 220131475) {
        return I18N$BsConsole.get(undefined, "Memory address");
      } else {
        return I18N$BsConsole.get(undefined, "Kilobytes");
      }
    } else {
      return I18N$BsConsole.get(undefined, "Labels");
    }
  } else if (format !== -812272505) {
    if (format >= -379547987) {
      if (format >= -349935189) {
        return I18N$BsConsole.get(undefined, "Nanoseconds");
      } else {
        return I18N$BsConsole.get(undefined, "JavaScript timestamp");
      }
    } else if (format >= -792364981) {
      return I18N$BsConsole.get(undefined, "Bytes");
    } else {
      return I18N$BsConsole.get(undefined, "Commit hash");
    }
  } else {
    return I18N$BsConsole.get(undefined, "SHA-256");
  }
}

function show(format) {
  return I18N$BsConsole.showSkip(get(format));
}

function getFromJs(s) {
  switch (s) {
    case "bytes" :
        return /* Bytes */-792364981;
    case "callstack" :
        return /* Callstack */519057866;
    case "commit" :
        return /* CommitHash */-820144251;
    case "gigabytes" :
        return /* Gigabytes */939376143;
    case "gps_timestamp" :
        return /* GPSTimestamp */559906156;
    case "hostname" :
        return /* Hostname */746836435;
    case "ipv4" :
        return /* IPV4 */813535941;
    case "ipv6" :
        return /* IPV6 */813535943;
    case "js_timestamp" :
        return /* JsTimestamp */-379547987;
    case "kilobytes" :
        return /* Kilobytes */-324157174;
    case "labels" :
        return /* Labels */-32400353;
    case "memory_address" :
        return /* MemoryAddress */220131475;
    case "milliseconds" :
        return /* Milliseconds */256858674;
    case "nanoseconds" :
        return /* Nanoseconds */-349935189;
    case "none" :
        return /* None */870530776;
    case "seconds" :
        return /* Seconds */668917343;
    case "semver" :
        return /* Semver */528330184;
    case "sha256" :
        return /* SHA256 */-812272505;
    case "unix_timestamp" :
        return /* UnixTimestamp */787392782;
    case "uuid" :
        return /* UUID */946856507;
    default:
      return ;
  }
}

function showFromJs(s) {
  var __x = Belt_Option.map(getFromJs(s), get);
  var __x$1 = Belt_Option.map(__x, I18N$BsConsole.showSkip);
  return Belt_Option.getWithDefault(__x$1, I18N$BsConsole.dynamic(s));
}

var Formats = {
  get: get,
  show: show,
  getFromJs: getFromJs,
  showFromJs: showFromJs
};

function get$1(string) {
  switch (string) {
    case "bitmap" :
        return I18N$BsConsole.get(undefined, "Boolean");
    case "dictionary" :
    case "dictionary16" :
    case "dictionary8" :
        return I18N$BsConsole.get(undefined, "String");
    case "uint128" :
        return I18N$BsConsole.get(undefined, "128-bit");
    case "uint16" :
        return I18N$BsConsole.get(undefined, "16-bit");
    case "uint32" :
        return I18N$BsConsole.get(undefined, "32-bit");
    case "uint64" :
        return I18N$BsConsole.get(undefined, "64-bit");
    case "uint8" :
        return I18N$BsConsole.get(undefined, "8-bit");
    case "uuid" :
        return I18N$BsConsole.get(undefined, "UUID");
    default:
      return I18N$BsConsole.dynamicGet(string);
  }
}

function show$1(string) {
  return I18N$BsConsole.showSkip(get$1(string));
}

var Types = {
  get: get$1,
  show: show$1
};

exports.Formats = Formats;
exports.Types = Types;
/* I18N-BsConsole Not a pure module */
