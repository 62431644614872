// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var CoronerdGate$BsConsole = require("../../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Switch = require("@material-ui/core/Switch");
var FormGroup = require("@material-ui/core/FormGroup");
var FormLabel = require("@material-ui/core/FormLabel");
var DialogTitle = require("@material-ui/core/DialogTitle");
var FormControl = require("@material-ui/core/FormControl");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var FormControlLabel = require("@material-ui/core/FormControlLabel");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

function responseCb(cb, resp) {
  if (resp.tag) {
    var error = resp[0];
    if (error[0] === Task2$BsConsole.CoronerError) {
      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to update settings") + (": " + error[1].message)));
    } else if (error[0] === BpgTask$BsConsole.BpgError) {
      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to update settings") + (": " + error[2])));
    } else {
      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update settings. Unknown error has occured."));
    }
  } else {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Successfully updated deduplication setting")));
  }
  return Curry._1(cb, undefined);
}

function IncludeModulesSwitch(Props) {
  var project = Props.project;
  var token = Props.token;
  Props.className;
  var match = React.useState((function () {
          
        }));
  var setConfirmationDialog = match[1];
  var confirmationDialog = match[0];
  var match$1 = Task2$BsConsole.useRefetchable(undefined, BpgTask$BsConsole.ProjectDeduplicationSettings.fetchAll, undefined);
  var refetchProjectDeduplicationSettings = match$1[1];
  var projectDeduplicationSettingsRemote = match$1[0];
  var includeModuleschecked = React.useMemo((function () {
          if (typeof projectDeduplicationSettingsRemote === "number" || projectDeduplicationSettingsRemote.tag) {
            return true;
          } else {
            return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(projectDeduplicationSettingsRemote[0][0], (function (projectSettings) {
                                  return projectSettings.pid === project.pid;
                                })), (function (projectSettings) {
                              return projectSettings.configuration.includeModules;
                            })), true);
          }
        }), /* tuple */[
        projectDeduplicationSettingsRemote,
        project
      ]);
  var includeSourcesChecked = React.useMemo((function () {
          if (typeof projectDeduplicationSettingsRemote === "number" || projectDeduplicationSettingsRemote.tag) {
            return true;
          } else {
            return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(projectDeduplicationSettingsRemote[0][0], (function (projectSettings) {
                                  return projectSettings.pid === project.pid;
                                })), (function (projectSettings) {
                              return projectSettings.configuration.includeSources;
                            })), true);
          }
        }), /* tuple */[
        projectDeduplicationSettingsRemote,
        project
      ]);
  var includeMetadataChecked = React.useMemo((function () {
          if (typeof projectDeduplicationSettingsRemote === "number" || projectDeduplicationSettingsRemote.tag) {
            return true;
          } else {
            return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(projectDeduplicationSettingsRemote[0][0], (function (projectSettings) {
                                  return projectSettings.pid === project.pid;
                                })), (function (projectSettings) {
                              return projectSettings.configuration.includeMetadata;
                            })), true);
          }
        }), /* tuple */[
        projectDeduplicationSettingsRemote,
        project
      ]);
  var disabled = React.useMemo((function () {
          if (typeof projectDeduplicationSettingsRemote === "number" || projectDeduplicationSettingsRemote.tag) {
            return true;
          } else {
            return false;
          }
        }), [projectDeduplicationSettingsRemote]);
  var handleOnModuleChange = function (_event, value) {
    return Curry._1(setConfirmationDialog, (function (param) {
                  return /* Module */Block.__(0, [value]);
                }));
  };
  var handleOnSourceChange = function (_event, value) {
    return Curry._1(setConfirmationDialog, (function (param) {
                  return /* Source */Block.__(1, [value]);
                }));
  };
  var handleOnMetadataChange = function (_event, value) {
    return Curry._1(setConfirmationDialog, (function (param) {
                  return /* Metadata */Block.__(2, [value]);
                }));
  };
  var handleOnClose = function (_event) {
    return Curry._1(setConfirmationDialog, (function (param) {
                  
                }));
  };
  var tmp;
  if (typeof projectDeduplicationSettingsRemote === "number" || projectDeduplicationSettingsRemote.tag || confirmationDialog === undefined) {
    tmp = null;
  } else {
    var projectsSettings = projectDeduplicationSettingsRemote[0][0];
    switch (confirmationDialog.tag | 0) {
      case /* Module */0 :
          var includeModules = confirmationDialog[0];
          tmp = React.createElement(Dialog.default, {
                open: true,
                onClose: handleOnClose,
                children: null
              }, React.createElement(DialogTitle.default, {
                    children: I18N$BsConsole.show(undefined, "Confirm change")
                  }), React.createElement(DialogContent.default, {
                    children: includeModules ? I18N$BsConsole.show(undefined, "By turning this on, module names such as executables and shared libraries, will be included as part of deduplication. For example, if two errors have identical callstacks but library or executables names are different, they will be assigned different fingerprint values. Would you like to proceed with turning this on?") : I18N$BsConsole.show(undefined, "By turning this off, module names such as executables and libraries, will not be included as part of deduplication. For example, if two errors have identical callstack values but originate from different programs, they will be assigned the same fingerprint. Would you like to proceed with turning this off?")
                  }), React.createElement(DialogContent.default, {
                    children: React.createElement(DialogActions.default, {
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: handleOnClose,
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* [] */0
                                  ]),
                              onClick: (function (_event) {
                                  var currentProjectDeduplicationSettings = Belt_Array.getBy(projectsSettings, (function (projectSettings) {
                                          return projectSettings.pid === project.pid;
                                        }));
                                  if (currentProjectDeduplicationSettings !== undefined) {
                                    var init = currentProjectDeduplicationSettings.configuration;
                                    var newConfiguration_includeSources = init.includeSources;
                                    var newConfiguration_includeMetadata = init.includeMetadata;
                                    var newConfiguration = {
                                      includeModules: includeModules,
                                      includeSources: newConfiguration_includeSources,
                                      includeMetadata: newConfiguration_includeMetadata
                                    };
                                    var arg = BpgTask$BsConsole.ProjectDeduplicationSettings.updateFieldsByKey(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key(currentProjectDeduplicationSettings), [/* tuple */[
                                            "configuration",
                                            JSON.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.toJson, newConfiguration))
                                          ]]);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return responseCb((function (param) {
                                                                Curry._1(setConfirmationDialog, (function (param) {
                                                                        
                                                                      }));
                                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreModulesUpdate */Block.__(16, [includeModules])]));
                                                                return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                              }), param);
                                                }));
                                  }
                                  var init$1 = BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.empty;
                                  var arg$1 = BpgTask$BsConsole.ProjectDeduplicationSettings.create({
                                        pid: project.pid,
                                        configuration: {
                                          includeModules: includeModules,
                                          includeSources: init$1.includeSources,
                                          includeMetadata: init$1.includeMetadata
                                        },
                                        __state: undefined,
                                        __create: undefined,
                                        __modify: undefined
                                      });
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (param) {
                                                return responseCb((function (param) {
                                                              Curry._1(setConfirmationDialog, (function (param) {
                                                                      
                                                                    }));
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreModulesUpdate */Block.__(16, [includeModules])]));
                                                              return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                            }), param);
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Yes")
                            }))
                  }));
          break;
      case /* Source */1 :
          var includeSources = confirmationDialog[0];
          tmp = React.createElement(Dialog.default, {
                open: true,
                onClose: handleOnClose,
                children: null
              }, React.createElement(DialogTitle.default, {
                    children: I18N$BsConsole.show(undefined, "Confirm change")
                  }), React.createElement(DialogContent.default, {
                    children: includeSources ? I18N$BsConsole.show(undefined, "By turning this on, source file names will be included as part of deduplication. For example, if two errors have identical callstacks but source file names are different, they will be assigned different fingerprint values. Would you like to proceed with turning this on?") : I18N$BsConsole.show(undefined, "By turning this off, source file names will not be included as part of deduplication. For example, if two errors have identical callstack values but originate from different source files, they will be assigned the same fingerprint. Would you like to proceed with turning this off?")
                  }), React.createElement(DialogContent.default, {
                    children: React.createElement(DialogActions.default, {
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: handleOnClose,
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* [] */0
                                  ]),
                              onClick: (function (_event) {
                                  var currentProjectDeduplicationSettings = Belt_Array.getBy(projectsSettings, (function (projectSettings) {
                                          return projectSettings.pid === project.pid;
                                        }));
                                  if (currentProjectDeduplicationSettings !== undefined) {
                                    var init = currentProjectDeduplicationSettings.configuration;
                                    var newConfiguration_includeModules = init.includeModules;
                                    var newConfiguration_includeMetadata = init.includeMetadata;
                                    var newConfiguration = {
                                      includeModules: newConfiguration_includeModules,
                                      includeSources: includeSources,
                                      includeMetadata: newConfiguration_includeMetadata
                                    };
                                    var arg = BpgTask$BsConsole.ProjectDeduplicationSettings.updateFieldsByKey(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key(currentProjectDeduplicationSettings), [/* tuple */[
                                            "configuration",
                                            JSON.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.toJson, newConfiguration))
                                          ]]);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return responseCb((function (param) {
                                                                Curry._1(setConfirmationDialog, (function (param) {
                                                                        
                                                                      }));
                                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreSourcesUpdate */Block.__(17, [includeSources])]));
                                                                return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                              }), param);
                                                }));
                                  }
                                  var init$1 = BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.empty;
                                  var arg$1 = BpgTask$BsConsole.ProjectDeduplicationSettings.create({
                                        pid: project.pid,
                                        configuration: {
                                          includeModules: init$1.includeModules,
                                          includeSources: includeSources,
                                          includeMetadata: init$1.includeMetadata
                                        },
                                        __state: undefined,
                                        __create: undefined,
                                        __modify: undefined
                                      });
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (param) {
                                                return responseCb((function (param) {
                                                              Curry._1(setConfirmationDialog, (function (param) {
                                                                      
                                                                    }));
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreSourcesUpdate */Block.__(17, [includeSources])]));
                                                              return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                            }), param);
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Yes")
                            }))
                  }));
          break;
      case /* Metadata */2 :
          var includeMetadata = confirmationDialog[0];
          tmp = React.createElement(Dialog.default, {
                open: true,
                onClose: handleOnClose,
                children: null
              }, React.createElement(DialogTitle.default, {
                    children: I18N$BsConsole.show(undefined, "Confirm change")
                  }), React.createElement(DialogContent.default, {
                    children: includeMetadata ? I18N$BsConsole.show(undefined, "By turning this on, frame associated metadata will be included as part of deduplication. For example, if two errors have identical callstacks but frame associated metadata is different, they will be assigned different fingerprint values. Would you like to proceed with turning this on?") : I18N$BsConsole.show(undefined, "By turning this off, frame associated metadata will not be included as part of deduplication. For example, if two errors have identical callstack values but have different frame associated metadata, they will be assigned the same fingerprint. Would you like to proceed with turning this off?")
                  }), React.createElement(DialogContent.default, {
                    children: React.createElement(DialogActions.default, {
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: handleOnClose,
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* [] */0
                                  ]),
                              onClick: (function (_event) {
                                  var currentProjectDeduplicationSettings = Belt_Array.getBy(projectsSettings, (function (projectSettings) {
                                          return projectSettings.pid === project.pid;
                                        }));
                                  if (currentProjectDeduplicationSettings !== undefined) {
                                    var init = currentProjectDeduplicationSettings.configuration;
                                    var newConfiguration_includeModules = init.includeModules;
                                    var newConfiguration_includeSources = init.includeSources;
                                    var newConfiguration = {
                                      includeModules: newConfiguration_includeModules,
                                      includeSources: newConfiguration_includeSources,
                                      includeMetadata: includeMetadata
                                    };
                                    var arg = BpgTask$BsConsole.ProjectDeduplicationSettings.updateFieldsByKey(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.to_key(currentProjectDeduplicationSettings), [/* tuple */[
                                            "configuration",
                                            JSON.stringify(Curry._1(BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.toJson, newConfiguration))
                                          ]]);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  return responseCb((function (param) {
                                                                Curry._1(setConfirmationDialog, (function (param) {
                                                                        
                                                                      }));
                                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreMetadataUpdate */Block.__(18, [includeMetadata])]));
                                                                return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                              }), param);
                                                }));
                                  }
                                  var init$1 = BacktraceParserGenerator$BsConsole.ProjectDeduplicationSettings.Configuration.empty;
                                  var arg$1 = BpgTask$BsConsole.ProjectDeduplicationSettings.create({
                                        pid: project.pid,
                                        configuration: {
                                          includeModules: init$1.includeModules,
                                          includeSources: init$1.includeSources,
                                          includeMetadata: includeMetadata
                                        },
                                        __state: undefined,
                                        __create: undefined,
                                        __modify: undefined
                                      });
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (param) {
                                                return responseCb((function (param) {
                                                              Curry._1(setConfirmationDialog, (function (param) {
                                                                      
                                                                    }));
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ProjectSettingsDeduplicationIgnoreMetadataUpdate */Block.__(18, [includeMetadata])]));
                                                              return Curry._1(refetchProjectDeduplicationSettings, undefined);
                                                            }), param);
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Yes")
                            }))
                  }));
          break;
      
    }
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.rem(1)),
                    /* :: */[
                      Css.marginBottom(Css.rem(1)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(FormControl.default, {
                  children: null
                }, React.createElement(FormLabel.default, {
                      children: I18N$BsConsole.show(undefined, "Include for grouping")
                    }), React.createElement(FormGroup.default, {
                      children: null
                    }, React.createElement(FormControlLabel.default, {
                          label: I18N$BsConsole.get(undefined, "Executable name"),
                          labelPlacement: "start",
                          control: React.createElement(Switch.default, {
                                color: "primary",
                                disabled: disabled,
                                onChange: handleOnModuleChange,
                                disableRipple: true,
                                checked: includeModuleschecked
                              })
                        }), CoronerdGate$BsConsole.projectDeduplicationIncludeSources ? React.createElement(FormControlLabel.default, {
                            label: I18N$BsConsole.get(undefined, "Source file name"),
                            labelPlacement: "start",
                            control: React.createElement(Switch.default, {
                                  color: "primary",
                                  disabled: disabled,
                                  onChange: handleOnSourceChange,
                                  disableRipple: true,
                                  checked: includeSourcesChecked
                                })
                          }) : null, CoronerdGate$BsConsole.projectDeduplicationIncludeMetadata ? React.createElement(FormControlLabel.default, {
                            label: I18N$BsConsole.get(undefined, "Frame associated metadata"),
                            labelPlacement: "start",
                            control: React.createElement(Switch.default, {
                                  color: "primary",
                                  disabled: disabled,
                                  onChange: handleOnMetadataChange,
                                  disableRipple: true,
                                  checked: includeMetadataChecked
                                })
                          }) : null)), tmp);
}

function make(project, token, className, _children) {
  var tmp = {
    project: project,
    token: token
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(IncludeModulesSwitch, tmp, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = IncludeModulesSwitch;

exports.responseCb = responseCb;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
