// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function fromJson(json) {
  return {
          header: Json_decode.field("header", Json_decode.string, json),
          value: Json_decode.optional((function (param) {
                  return Json_decode.field("value", Json_decode.string, param);
                }), json)
        };
}

function toJson(t) {
  var __x = t.value;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "header",
                t.header
              ],
              /* :: */[
                /* tuple */[
                  "value",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), __x)
                ],
                /* [] */0
              ]
            ]);
}

var HeaderAuth = {
  fromJson: fromJson,
  toJson: toJson
};

function fromJson$1(json) {
  return {
          username: Json_decode.field("username", Json_decode.string, json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json)
        };
}

function toJson$1(t) {
  var __x = t.password;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "username",
                t.username
              ],
              /* :: */[
                /* tuple */[
                  "password",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), __x)
                ],
                /* [] */0
              ]
            ]);
}

var UserPasswordCredentials = {
  fromJson: fromJson$1,
  toJson: toJson$1
};

function fromJson$2(json) {
  return {
          accessKeyId: Json_decode.field("accessKeyId", Json_decode.string, json),
          secretAccessKey: Json_decode.optional((function (param) {
                  return Json_decode.field("secretAccessKey", Json_decode.string, param);
                }), json)
        };
}

function toJson$2(t) {
  var __x = t.secretAccessKey;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "accessKeyId",
                t.accessKeyId
              ],
              /* :: */[
                /* tuple */[
                  "secretAccessKey",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), __x)
                ],
                /* [] */0
              ]
            ]);
}

var AwsCredentials = {
  fromJson: fromJson$2,
  toJson: toJson$2
};

function fromJson$3(json) {
  return {
          region: Json_decode.field("region", Json_decode.string, json),
          clusterId: Json_decode.field("clusterId", Json_decode.string, json),
          dbUser: Json_decode.field("dbUser", Json_decode.string, json)
        };
}

function toJson$3(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "region",
                t.region
              ],
              /* :: */[
                /* tuple */[
                  "clusterId",
                  t.clusterId
                ],
                /* :: */[
                  /* tuple */[
                    "dbUser",
                    t.dbUser
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var RedshiftConfiguration = {
  fromJson: fromJson$3,
  toJson: toJson$3
};

function stringToType(param) {
  switch (param) {
    case "graphite" :
        return /* Graphite */0;
    case "influx" :
        return /* Influx */1;
    case "postgres" :
        return /* Postgres */2;
    case "redshift" :
        return /* Redshift */3;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "ApiStabilityMonitoring.re",
              105,
              11
            ]
          ];
  }
}

function typeToString(param) {
  switch (param) {
    case /* Graphite */0 :
        return "graphite";
    case /* Influx */1 :
        return "influx";
    case /* Postgres */2 :
        return "postgres";
    case /* Redshift */3 :
        return "redshift";
    
  }
}

function typeToDisplayString(param) {
  switch (param) {
    case /* Graphite */0 :
        return "Graphite";
    case /* Influx */1 :
        return "Influx";
    case /* Postgres */2 :
        return "Postgres";
    case /* Redshift */3 :
        return "Redshift";
    
  }
}

function stringToAuthenticationType(param) {
  switch (param) {
    case "aws" :
        return /* Aws */0;
    case "basic_auth" :
        return /* BasicAuth */3;
    case "headers" :
        return /* Headers */2;
    case "none" :
        return /* None */4;
    case "postgres" :
        return /* Postgres */1;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "ApiStabilityMonitoring.re",
              135,
              11
            ]
          ];
  }
}

function authenticationTypeToString(param) {
  switch (param) {
    case /* Aws */0 :
        return "aws";
    case /* Postgres */1 :
        return "postgres";
    case /* Headers */2 :
        return "headers";
    case /* BasicAuth */3 :
        return "basic_auth";
    case /* None */4 :
        return "none";
    
  }
}

function typeToAuthenticationType(type_) {
  switch (type_) {
    case /* Graphite */0 :
        return /* None */4;
    case /* Influx */1 :
        return /* BasicAuth */3;
    case /* Postgres */2 :
        return /* Postgres */1;
    case /* Redshift */3 :
        return /* Aws */0;
    
  }
}

function fromJson$4(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          coronerUrl: Json_decode.field("coronerUrl", Json_decode.string, json),
          submissionToken: Json_decode.field("submissionToken", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          type_: stringToType(Json_decode.field("type", Json_decode.string, json)),
          authenticationType: stringToAuthenticationType(Json_decode.field("authenticationType", Json_decode.string, json)),
          headerAuth: Json_decode.optional((function (param) {
                  return Json_decode.field("headerAuth", fromJson, param);
                }), json),
          userPasswordCredentials: Json_decode.optional((function (param) {
                  return Json_decode.field("userPasswordCredentials", fromJson$1, param);
                }), json),
          awsCredentials: Json_decode.optional((function (param) {
                  return Json_decode.field("awsCredentials", fromJson$2, param);
                }), json),
          redshiftConfiguration: Json_decode.optional((function (param) {
                  return Json_decode.field("redshiftConfiguration", fromJson$3, param);
                }), json)
        };
}

var Source = {
  stringToType: stringToType,
  typeToString: typeToString,
  typeToDisplayString: typeToDisplayString,
  stringToAuthenticationType: stringToAuthenticationType,
  authenticationTypeToString: authenticationTypeToString,
  typeToAuthenticationType: typeToAuthenticationType,
  fromJson: fromJson$4
};

function fromJson$5(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json)
        };
}

var $$Response = {
  fromJson: fromJson$5
};

function fromJson$6(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          query: Json_decode.field("query", Json_decode.string, json),
          delay: Json_decode.field("delay", Json_decode.$$int, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json),
          metricGroup: Json_decode.field("metricGroup", Json_decode.string, json),
          metric: Json_decode.field("metric", Json_decode.string, json),
          earliestScraped: Json_decode.field("earliestScraped", (function (param) {
                  return Json_decode.map((function (seconds) {
                                return new Date(seconds * 1000);
                              }), Json_decode.$$int, param);
                }), json),
          latestScraped: Json_decode.field("latestScraped", (function (param) {
                  return Json_decode.map((function (seconds) {
                                return new Date(seconds * 1000);
                              }), Json_decode.$$int, param);
                }), json),
          lastScrapedTime: Json_decode.field("lastScrapedTime", (function (param) {
                  return Json_decode.map((function (seconds) {
                                return new Date(seconds * 1000);
                              }), Json_decode.$$int, param);
                }), json)
        };
}

var Importer = {
  fromJson: fromJson$6
};

function fromJson$7(json) {
  return {
          importerId: Json_decode.field("importerId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          level: Json_decode.field("level", Json_decode.string, json),
          message: Json_decode.field("message", Json_decode.string, json),
          time: Json_decode.field("time", (function (param) {
                  return Json_decode.map((function (seconds) {
                                return new Date(seconds * 1000);
                              }), Json_decode.$$int, param);
                }), json)
        };
}

var Log = {
  fromJson: fromJson$7
};

exports.HeaderAuth = HeaderAuth;
exports.UserPasswordCredentials = UserPasswordCredentials;
exports.AwsCredentials = AwsCredentials;
exports.RedshiftConfiguration = RedshiftConfiguration;
exports.Source = Source;
exports.$$Response = $$Response;
exports.Importer = Importer;
exports.Log = Log;
/* No side effect */
