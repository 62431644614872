// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var match = window._BACKTRACE_IDENTITY_VARIANT;

var current;

if (match == null) {
  current = /* Backtrace */0;
} else {
  switch (match) {
    case "sauce_labs" :
        current = /* SauceLabs */1;
        break;
    case "unity" :
        current = /* Unity */2;
        break;
    default:
      current = /* Backtrace */0;
  }
}

exports.current = current;
/* match Not a pure module */
