// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18N$BsConsole = require("../I18N.js");

function trialExpiresSoon(endTimestamp, currentTime) {
  var delta = endTimestamp - currentTime;
  return delta < 432000000;
}

var adminTrialExpireMessage = I18N$BsConsole.get(undefined, "Your trial expires soon! Visit the subscriptions page to learn how to keep your current settings or request changes. If you don't subscribe before the end of your trial, you will be downgraded to the Free Developer Plan.");

var nonadminTrialExpireMessage = I18N$BsConsole.get(undefined, "Your trial expires soon! You will lose access to this console when the trial ends unless an admin opts-in to a subscription. Let your admin know you'd like to keep your access!");

var oneDayInMs = 86400000;

var soonDays = 5;

exports.oneDayInMs = oneDayInMs;
exports.soonDays = soonDays;
exports.trialExpiresSoon = trialExpiresSoon;
exports.adminTrialExpireMessage = adminTrialExpireMessage;
exports.nonadminTrialExpireMessage = nonadminTrialExpireMessage;
/* adminTrialExpireMessage Not a pure module */
