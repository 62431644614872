// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");

var parentDiv = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.margin(Css.rem(1)),
          /* [] */0
        ]
      ]
    ]);

function paper(primaryMain) {
  return Css.style(/* :: */[
              Css.borderLeft(Css.px(8), /* solid */12956715, Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* [] */0
            ]);
}

function infoIcon(primaryMain) {
  return Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* :: */[
                Css.marginRight(Css.px(15)),
                /* [] */0
              ]
            ]);
}

var h4 = Css.style(/* :: */[
      Css.margin2(Css.px(3), Css.px(0)),
      /* :: */[
        Css.fontSize(Css.rem(1)),
        /* [] */0
      ]
    ]);

var p = Css.style(/* :: */[
      Css.margin(Css.px(0)),
      /* :: */[
        Css.fontSize(Css.rem(0.875)),
        /* [] */0
      ]
    ]);

var Style = {
  parentDiv: parentDiv,
  paper: paper,
  infoIcon: infoIcon,
  h4: h4,
  p: p
};

function UnitySdkCTA(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  return React.createElement(Paper.default, {
              classes: {
                root: Css.merge(/* :: */[
                      paper(palette.primary.main),
                      /* :: */[
                        className,
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement("div", {
                    className: parentDiv
                  }, React.createElement(Info.default, {
                        className: infoIcon(palette.primary.main)
                      }), React.createElement("div", undefined, React.createElement("h4", {
                            className: h4
                          }, I18N$BsConsole.show(undefined, "Backtrace-Unity SDK configuration is missing")), React.createElement("p", {
                            className: p
                          }, I18N$BsConsole.show(undefined, "You need to configure your SDK to send metric data. Read "), React.createElement("a", {
                                href: "https://github.com/backtrace-labs/backtrace-unity"
                              }, I18N$BsConsole.show(undefined, "SDK documentation")), I18N$BsConsole.show(undefined, " for more details."))))
            });
}

var make = UnitySdkCTA;

exports.Style = Style;
exports.make = make;
/* parentDiv Not a pure module */
