// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var About$BsConsole = require("./About.js");
var Image$BsConsole = require("./components/Image.js");
var Colors$BsConsole = require("./Colors.js");
var Version$BsConsole = require("./Version.js");
var ZenDesk$BsConsole = require("./ZenDesk.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var SauceColors$BsConsole = require("./SauceColors.js");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");
var CreateFirstProjectBanner$BsConsole = require("./CreateFirstProjectBanner.js");

var appBar = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.grey2))),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey0)),
        /* :: */[
          Css.unsafe("position", "sticky !important"),
          /* :: */[
            Css.important(Css.flexDirection(/* row */5693978)),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.important(Css.padding(Css.zero)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var title = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var iframeVersionItem = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.75)),
      /* :: */[
        Css.height(Css.rem(0.70)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  appBar: appBar,
  title: title,
  iframeVersionItem: iframeVersionItem
};

var component = ReasonReact.reducerComponent("SettingsBar-BsConsole");

function make(handleBack, handleChangeUrl, handleLogout, shell, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var tmp;
              var exit = 0;
              if (typeof shell === "number" || shell.tag) {
                exit = 1;
              } else {
                var config = shell[0];
                var ver = window._BACKTRACE_CORONER_VERSION;
                var coronerVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* ZendeskAppBarLauncher */12), [ReasonReact.element(undefined, undefined, Curry._3(ZenDesk$BsConsole.IconButton.Jsx2.make, Css.style(/* :: */[
                                            Css.marginRight(Css.px(5)),
                                            /* [] */0
                                          ]), {
                                        email: config.user.email,
                                        username: config.user.username,
                                        role: config.user.role,
                                        universe: config.universe.name,
                                        coronerdVersion: config.coronerdVersion,
                                        consoleVersion: Version$BsConsole.version
                                      }, []))])), ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Menu"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              "Menu",
                                                              [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Avatar.Jsx2.make, undefined, Css.style(/* :: */[
                                                                              Css.backgroundColor(Css.hex(SauceColors$BsConsole.green)),
                                                                              /* :: */[
                                                                                Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                                /* [] */0
                                                                              ]
                                                                            ]), [I18N$BsConsole.showSkip($$String.uppercase($$String.sub(config.user.username, 0, 1)))]))]
                                                            ]))])));
                              }), undefined, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Center */980392437
                            }, undefined, undefined, undefined, undefined, (function (onClose) {
                                return [
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* About */6), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                              Curry._1(self.send, /* Dialog */[/* About */0]);
                                                              return Curry._1(onClose, undefined);
                                                            }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "About")]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config.user])), [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* SettingsInvitesNew */12, undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                        return Curry._1(onClose, undefined);
                                                                      }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Invite a user")]))]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjectsNew */Block.__(6, [config.user])), [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* SettingsProjectsNew */9, undefined, handleChangeUrl, TeamsUtils$BsConsole.isGuest(config), undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                        return Curry._1(onClose, undefined);
                                                                      }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Create a project")]))]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsLogout */5), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                              Curry._1(handleLogout, undefined);
                                                              return Curry._1(onClose, undefined);
                                                            }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Logout")]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* VersionsMenuItem */7), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, iframeVersionItem, undefined, undefined, true, undefined, [Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                                                      /* String_literal */Block.__(11, [
                                                                          "Console ",
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* String */Block.__(2, [
                                                                                  /* No_padding */0,
                                                                                  /* String_literal */Block.__(11, [
                                                                                      " - coronerd ",
                                                                                      /* String */Block.__(2, [
                                                                                          /* No_padding */0,
                                                                                          /* End_of_format */0
                                                                                        ])
                                                                                    ])
                                                                                ])
                                                                            ])
                                                                        ]),
                                                                      "Console %s%s - coronerd %s"
                                                                    ]), Version$BsConsole.version, Gate$BsConsole.isAlpha(undefined) ? "-alpha" : (
                                                                    Gate$BsConsole.isBeta(undefined) ? "-beta" : ""
                                                                  ), coronerVersion)]))]))
                                      ];
                              }), undefined, [])));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Avatar.Jsx2.make, undefined, undefined, []));
              }
              var _About = self.state.dialog;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.AppBar.Jsx2.make, appBar, /* Sticky */1070408009, [
                                  ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, undefined, true, [
                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.margin2(Css.zero, Css.rem(1)),
                                                          /* :: */[
                                                            Css.display(/* inlineFlex */53323314),
                                                            /* :: */[
                                                              Css.alignItems(/* center */98248149),
                                                              /* :: */[
                                                                Css.padding(Css.px(12)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [ReasonReact.element(undefined, undefined, Image$BsConsole.Jsx2.make(undefined, {
                                                                  cursor: "pointer"
                                                                }, "https://res.cloudinary.com/backtrace/image/upload/v1678128739/saucelabs_backtrace_logo_nav_1.svg", "Sauce Labs Logo", (function (_event) {
                                                                    return Curry._1(handleBack, undefined);
                                                                  }))([]))])),
                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, {
                                                      headline: title
                                                    }, undefined, [I18N$BsConsole.show(undefined, "Organization settings")]))
                                          ])),
                                  React.createElement("div", {
                                        style: {
                                          flex: "1"
                                        }
                                      }),
                                  ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, undefined, undefined, [tmp])),
                                  _About !== undefined ? ReasonReact.element(undefined, undefined, About$BsConsole.make((function (_event) {
                                                return Curry._1(self.send, /* Dialog */[undefined]);
                                              }), [])) : null
                                ])), ReasonReact.element(undefined, undefined, CreateFirstProjectBanner$BsConsole.Jsx2.make(handleChangeUrl, shell, [])));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Style = Style;
exports.component = component;
exports.make = make;
/* appBar Not a pure module */
