// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Fuzz$BsConsole = require("../bindings/Fuzz.js");
var I18N$BsConsole = require("../I18N.js");
var Icon$BsConsole = require("../bindings/Icon.js");
var Util$BsConsole = require("../util.js");
var BtList$BsConsole = require("../components/BtList.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtCheckbox$BsConsole = require("../components/BtCheckbox.js");
var BtListItem$BsConsole = require("../components/BtListItem.js");
var IdentityVariant$BsConsole = require("../IdentityVariant.js");

var component = ReasonReact.reducerComponent("InboxMultiAssign-BsConsole");

function make(handleSetAssignee, target, projectUsers, anchorOrigin, transformOrigin, onCloseOpt, tickets, assignees, config, _children) {
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var selectedUser = state.selectedUser;
              var searchString = state.searchString;
              var send = param.send;
              var ticketsCount = Belt_List.length(tickets);
              var assigneeIds = Belt_List.keepMap(assignees, (function (assignee) {
                      if (assignee.tag) {
                        return ;
                      } else {
                        return assignee[0].id;
                      }
                    }));
              var filteredUsers = Belt_Array.keep(projectUsers, (function (projectUser) {
                      return !Util$BsConsole.shouldFilterBacktraceInternalUser(projectUser.email, config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2);
                    }));
              var filteredUsers$1 = searchString === "" ? filteredUsers : Fuzz$BsConsole.fuzz(searchString, filteredUsers, (function (user) {
                        return user.username;
                      }));
              var filteredSortedUsers = Belt_SortArray.stableSortBy(Belt_SortArray.stableSortBy(filteredUsers$1, (function (u1, u2) {
                          return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
                        })), (function (u1, u2) {
                      var match = Belt_List.has(assigneeIds, u1, (function (uid, user) {
                              return uid === user.uid;
                            }));
                      var match$1 = Belt_List.has(assigneeIds, u2, (function (uid, user) {
                              return user.uid === uid;
                            }));
                      if (match) {
                        if (match$1) {
                          return 0;
                        } else {
                          return -1;
                        }
                      } else if (match$1) {
                        return 1;
                      } else {
                        return 0;
                      }
                    }));
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make(Curry.__3(target), undefined, anchorOrigin, transformOrigin, undefined, (function (param) {
                                Curry._1(send, /* ResetSelection */0);
                                return Curry._1(onClose, undefined);
                              }), undefined, (function (onClose) {
                                return React.createElement("div", {
                                            onClick: (function (e) {
                                                e.stopPropagation();
                                                
                                              })
                                          }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                            Css.padding2(Css.px(0), Css.px(12)),
                                                                            /* [] */0
                                                                          ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                  Css.style(/* :: */[
                                                                                        Css.marginTop(Css.px(8)),
                                                                                        /* :: */[
                                                                                          Css.width(Css.px(190)),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]),
                                                                                  undefined,
                                                                                  "Assign to",
                                                                                  undefined,
                                                                                  (function (e) {
                                                                                      return Curry._1(send, /* SetSearch */Block.__(0, [e.target.value]));
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    searchString
                                                                                  ],
                                                                                  undefined,
                                                                                  true,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  {
                                                                                    className: Css.style(/* :: */[
                                                                                          Css.fontSize(Css.px(12)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    endAdornment: React.createElement("span", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.color(Css.hex(Colors$BsConsole.blackA60)),
                                                                                                /* [] */0
                                                                                              ])
                                                                                        }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* small */-9768761, "search", [])))
                                                                                  },
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))]))])),
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(Css.style(/* :: */[
                                                                  Css.maxHeight(Css.px(168)),
                                                                  /* :: */[
                                                                    Css.overflowY(Css.auto),
                                                                    /* :: */[
                                                                      Css.marginTop(Css.px(8)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]), [Belt_Array.map(filteredSortedUsers, (function (user) {
                                                                      var assignmentsCount = Belt_List.length(Belt_List.keep(assigneeIds, (function (uid) {
                                                                                  return uid === user.uid;
                                                                                })));
                                                                      return ReasonReact.element(user.username, undefined, BtListItem$BsConsole.make(Css.style(/* :: */[
                                                                                          Css.paddingLeft(Css.px(20)),
                                                                                          /* :: */[
                                                                                            Css.flexGrow(1),
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]), undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                undefined,
                                                                                                Caml_option.some(I18N$BsConsole.showSkip(user.username)),
                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, BtCheckbox$BsConsole.make(undefined, /* small */-9768761, (function (param, param$1) {
                                                                                                                var gotUnassigned = selectedUser !== undefined ? (
                                                                                                                    selectedUser ? selectedUser[0].uid === user.uid : false
                                                                                                                  ) : assignmentsCount === ticketsCount;
                                                                                                                if (gotUnassigned) {
                                                                                                                  return Curry._1(send, /* SelectUser */Block.__(1, [/* Deselected */0]));
                                                                                                                } else {
                                                                                                                  return Curry._1(send, /* SelectUser */Block.__(1, [/* Selected */[user]]));
                                                                                                                }
                                                                                                              }), selectedUser !== undefined ? (
                                                                                                                selectedUser ? selectedUser[0].uid === user.uid : false
                                                                                                              ) : assignmentsCount > 0, selectedUser !== undefined ? false : assignmentsCount > 0 && assignmentsCount !== ticketsCount, undefined, []))),
                                                                                                {
                                                                                                  label: Css.style(/* :: */[
                                                                                                        Css.fontSize(Css.px(12)),
                                                                                                        /* :: */[
                                                                                                          Css.paddingLeft(Css.px(2)),
                                                                                                          /* :: */[
                                                                                                            Css.paddingTop(Css.px(2)),
                                                                                                            /* [] */0
                                                                                                          ]
                                                                                                        ]
                                                                                                      ])
                                                                                                },
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                []
                                                                                              ]))]));
                                                                    }))])),
                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                    ReasonReact.element(undefined, undefined, BtList$BsConsole.make(undefined, [ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  /* Small */311976103,
                                                                                  Css.style(/* :: */[
                                                                                        Css.marginRight(Css.px(4)),
                                                                                        /* [] */0
                                                                                      ]),
                                                                                  (function (e) {
                                                                                      e.stopPropagation();
                                                                                      Curry._1(send, /* Apply */1);
                                                                                      return Curry._1(onClose, undefined);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  /* Primary */-791844958,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Apply")]
                                                                                ]))]))]))
                                                  ])));
                              }), undefined, []));
            }),
          initialState: (function (param) {
              return {
                      searchString: "",
                      selectedUser: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* ResetSelection */0) {
                  return /* Update */Block.__(0, [{
                              searchString: state.searchString,
                              selectedUser: undefined
                            }]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (param) {
                                var match = state.selectedUser;
                                if (match !== undefined) {
                                  if (match) {
                                    return Curry._3(handleSetAssignee, match[0], tickets, undefined);
                                  } else {
                                    return Curry._3(handleSetAssignee, undefined, tickets, undefined);
                                  }
                                }
                                
                              })]);
                }
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            searchString: state.searchString,
                            selectedUser: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            searchString: action[0],
                            selectedUser: state.selectedUser
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.handleSetAssignee, reactProps.target, reactProps.projectUsers, Caml_option.undefined_to_opt(reactProps.anchorOrigin), Caml_option.undefined_to_opt(reactProps.transformOrigin), Caml_option.undefined_to_opt(reactProps.onClose), reactProps.tickets, reactProps.assignees, reactProps.config, []);
      }));

var Jsx3 = {
  make: make$1
};

exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
