// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var BtTable$BsConsole = require("../../BtTable.js");
var Numeral$BsConsole = require("../../Numeral.js");
var SSAGroup$BsConsole = require("./SSAGroup.js");
var SSAUtils$BsConsole = require("./SSAUtils.js");
var SSAAction$BsConsole = require("./SSAAction.js");
var BtTableRow$BsConsole = require("../../BtTableRow.js");
var BtTableBody$BsConsole = require("../../BtTableBody.js");
var BtTableCell$BsConsole = require("../../BtTableCell.js");
var BtTableHead$BsConsole = require("../../BtTableHead.js");
var Paper = require("@material-ui/core/Paper");
var SSAStatDisplay$BsConsole = require("./SSAStatDisplay.js");
var Delete = require("@material-ui/icons/Delete");
var BtTableContainer$BsConsole = require("../../BtTableContainer.js");
var SSARenderElements$BsConsole = require("./SSARenderElements.js");
var BugBreadcrumbsUtils$BsConsole = require("../../debugger2/breadcrumbs/BugBreadcrumbsUtils.js");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

function SSATable$Ordered(Props) {
  var actions = Props.actions;
  var handleDelete = Props.handleDelete;
  var handleUpdate = Props.handleUpdate;
  if (actions.length === 0) {
    return null;
  } else {
    return React.createElement(Paper.default, {
                classes: {
                  root: Css.style(/* :: */[
                        pageMarginBottom,
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ])
                },
                children: React.createElement(BtTableContainer$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]),
                      children: React.createElement(BtTable$BsConsole.make, {
                            children: null
                          }, React.createElement(BtTableHead$BsConsole.make, {
                                children: React.createElement(BtTableRow$BsConsole.make, {
                                      children: null
                                    }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(48)),
                                                /* [] */0
                                              ])
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(80)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.orderText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(200)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.sourceText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.conditionText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.attributeText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.valueText)
                                        }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(144)),
                                                /* [] */0
                                              ])
                                        }))
                              }), React.createElement(BtTableBody$BsConsole.make, {
                                children: Belt_Array.mapWithIndex(actions, (function (index, curr) {
                                        var tmp;
                                        var exit = 0;
                                        switch (curr.tag | 0) {
                                          case /* SetAttribute */2 :
                                          case /* SetAssignee */3 :
                                              exit = 1;
                                              break;
                                          default:
                                            tmp = null;
                                        }
                                        if (exit === 1) {
                                          tmp = React.createElement(SSARenderElements$BsConsole.Target.make, {
                                                source: curr[0][1]
                                              });
                                        }
                                        var tmp$1;
                                        var exit$1 = 0;
                                        switch (curr.tag | 0) {
                                          case /* SetAttribute */2 :
                                          case /* SetAssignee */3 :
                                              exit$1 = 1;
                                              break;
                                          default:
                                            tmp$1 = null;
                                        }
                                        if (exit$1 === 1) {
                                          tmp$1 = React.createElement(SSARenderElements$BsConsole.Condition.make, {
                                                conditions: curr[0][2]
                                              });
                                        }
                                        var tmp$2;
                                        switch (curr.tag | 0) {
                                          case /* SetAttribute */2 :
                                              tmp$2 = React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                    text: curr[0][3].key
                                                  });
                                              break;
                                          case /* SetAssignee */3 :
                                              tmp$2 = React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                    text: SSAUtils$BsConsole.assigneeText
                                                  });
                                              break;
                                          default:
                                            tmp$2 = null;
                                        }
                                        var tmp$3;
                                        switch (curr.tag | 0) {
                                          case /* SetAttribute */2 :
                                              tmp$3 = React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                    text: curr[0][3].value
                                                  });
                                              break;
                                          case /* SetAssignee */3 :
                                              tmp$3 = React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                    text: curr[0][3]
                                                  });
                                              break;
                                          default:
                                            tmp$3 = null;
                                        }
                                        return React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                                    expandedContent: React.createElement(SSAStatDisplay$BsConsole.make, {
                                                          action: curr
                                                        }),
                                                    colSpan: 7,
                                                    children: null,
                                                    key: SSAAction$BsConsole.getId(curr)
                                                  }, React.createElement(BtTableCell$BsConsole.make, {
                                                        children: I18N$BsConsole.showSkip(String(index + 1 | 0))
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$1
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$2
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$3
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        className: Css.style(/* :: */[
                                                              Css.important(Css.padding(/* zero */-789508312)),
                                                              /* [] */0
                                                            ]),
                                                        children: null
                                                      }, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                            ariaLabel: SSAUtils$BsConsole.changeOrderText,
                                                            disabled: index === 0,
                                                            onClick: (function (param) {
                                                                return Curry._1(handleUpdate, SSAGroup$BsConsole.increaseActionPriority(actions, index));
                                                              }),
                                                            title_tooltip: SSAUtils$BsConsole.changeOrderText,
                                                            children: React.createElement(ArrowUpward.default, { })
                                                          }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                            ariaLabel: SSAUtils$BsConsole.changeOrderText,
                                                            disabled: index === (actions.length - 1 | 0),
                                                            onClick: (function (param) {
                                                                return Curry._1(handleUpdate, SSAGroup$BsConsole.decreaseActionPriority(actions, index));
                                                              }),
                                                            title_tooltip: SSAUtils$BsConsole.changeOrderText,
                                                            children: React.createElement(ArrowDownward.default, { })
                                                          }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                            ariaLabel: SSAUtils$BsConsole.deleteText,
                                                            onClick: (function (param) {
                                                                return Curry._3(handleDelete, index, curr, undefined);
                                                              }),
                                                            title_tooltip: SSAUtils$BsConsole.deleteText,
                                                            children: React.createElement(Delete.default, { })
                                                          })));
                                      }))
                              }))
                    })
              });
  }
}

var Ordered = {
  make: SSATable$Ordered
};

function SSATable$FourColumn(Props) {
  var actions = Props.actions;
  var handleDelete = Props.handleDelete;
  if (actions.length === 0) {
    return null;
  } else {
    return React.createElement(Paper.default, {
                classes: {
                  root: Css.style(/* :: */[
                        pageMarginBottom,
                        /* :: */[
                          Css.width(Css.pct(85)),
                          /* [] */0
                        ]
                      ])
                },
                children: React.createElement(BtTableContainer$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]),
                      children: React.createElement(BtTable$BsConsole.make, {
                            children: null
                          }, React.createElement(BtTableHead$BsConsole.make, {
                                children: React.createElement(BtTableRow$BsConsole.make, {
                                      children: null
                                    }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(48)),
                                                /* [] */0
                                              ])
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(200)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.sourceText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(300)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.conditionText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.attributeText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.valueText)
                                        }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(48)),
                                                /* [] */0
                                              ])
                                        }))
                              }), React.createElement(BtTableBody$BsConsole.make, {
                                children: Belt_Array.mapWithIndex(actions, (function (index, curr) {
                                        var tmp;
                                        tmp = curr.tag === /* UnionTags */4 ? React.createElement(SSARenderElements$BsConsole.Target.make, {
                                                source: curr[0][1]
                                              }) : null;
                                        var tmp$1;
                                        tmp$1 = curr.tag === /* UnionTags */4 ? React.createElement(SSARenderElements$BsConsole.Condition.make, {
                                                conditions: curr[0][2]
                                              }) : null;
                                        var tmp$2;
                                        tmp$2 = curr.tag === /* UnionTags */4 ? React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                text: curr[0][3]
                                              }) : null;
                                        var tmp$3;
                                        tmp$3 = curr.tag === /* UnionTags */4 ? React.createElement(BugBreadcrumbsUtils$BsConsole.Ellipse.make, {
                                                text: curr[0][4].join(", ")
                                              }) : null;
                                        return React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                                    expandedContent: React.createElement(SSAStatDisplay$BsConsole.make, {
                                                          action: curr
                                                        }),
                                                    colSpan: 6,
                                                    children: null,
                                                    key: SSAAction$BsConsole.getId(curr)
                                                  }, React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$1
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$2
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        children: tmp$3
                                                      }), React.createElement(BtTableCell$BsConsole.make, {
                                                        className: Css.style(/* :: */[
                                                              Css.important(Css.padding(/* zero */-789508312)),
                                                              /* [] */0
                                                            ]),
                                                        children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                              ariaLabel: SSAUtils$BsConsole.deleteText,
                                                              onClick: (function (param) {
                                                                  return Curry._3(handleDelete, index, curr, undefined);
                                                                }),
                                                              title_tooltip: SSAUtils$BsConsole.deleteText,
                                                              children: React.createElement(Delete.default, { })
                                                            })
                                                      }));
                                      }))
                              }))
                    })
              });
  }
}

var FourColumn = {
  make: SSATable$FourColumn
};

function SSATable(Props) {
  var actions = Props.actions;
  var handleDelete = Props.handleDelete;
  if (actions.length === 0) {
    return null;
  } else {
    return React.createElement(Paper.default, {
                classes: {
                  root: Css.style(/* :: */[
                        pageMarginBottom,
                        /* :: */[
                          Css.width(Css.pct(70)),
                          /* [] */0
                        ]
                      ])
                },
                children: React.createElement(BtTableContainer$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]),
                      children: React.createElement(BtTable$BsConsole.make, {
                            children: null
                          }, React.createElement(BtTableHead$BsConsole.make, {
                                children: React.createElement(BtTableRow$BsConsole.make, {
                                      children: null
                                    }, React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(48)),
                                                /* [] */0
                                              ])
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(200)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.sourceText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.conditionText)
                                        }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* [] */0
                                              ]),
                                          children: I18N$BsConsole.showSkip(SSAUtils$BsConsole.probabilityText)
                                        }), React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                          className: Css.style(/* :: */[
                                                Css.width(Css.px(48)),
                                                /* [] */0
                                              ])
                                        }))
                              }), React.createElement(BtTableBody$BsConsole.make, {
                                children: Belt_Array.mapWithIndex(actions, (function (index, curr) {
                                        var probability;
                                        switch (curr.tag | 0) {
                                          case /* Ignore */0 :
                                          case /* IndexOnly */1 :
                                              probability = curr[0][3];
                                              break;
                                          default:
                                            probability = 1;
                                        }
                                        var tmp;
                                        switch (curr.tag | 0) {
                                          case /* SetAttribute */2 :
                                          case /* SetAssignee */3 :
                                              tmp = null;
                                              break;
                                          default:
                                            var match = curr[0];
                                            tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTableCell$BsConsole.make, {
                                                      className: Css.style(/* :: */[
                                                            Css.width(Css.px(200)),
                                                            /* [] */0
                                                          ]),
                                                      children: React.createElement(SSARenderElements$BsConsole.Target.make, {
                                                            source: match[1]
                                                          })
                                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                                      children: React.createElement(SSARenderElements$BsConsole.Condition.make, {
                                                            conditions: match[2]
                                                          })
                                                    }), React.createElement(BtTableCell$BsConsole.make, {
                                                      children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.[000]%", Pervasives.string_of_float(probability)))
                                                    }));
                                        }
                                        return React.createElement(BtTableRow$BsConsole.Expandable.make, {
                                                    expandedContent: React.createElement(SSAStatDisplay$BsConsole.make, {
                                                          action: curr
                                                        }),
                                                    children: null,
                                                    key: SSAAction$BsConsole.getId(curr)
                                                  }, tmp, React.createElement(BtTableCell$BsConsole.make, {
                                                        className: Css.style(/* :: */[
                                                              Css.important(Css.padding(/* zero */-789508312)),
                                                              /* [] */0
                                                            ]),
                                                        children: React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                              ariaLabel: SSAUtils$BsConsole.deleteText,
                                                              onClick: (function (param) {
                                                                  return Curry._3(handleDelete, index, curr, undefined);
                                                                }),
                                                              title_tooltip: SSAUtils$BsConsole.deleteText,
                                                              children: React.createElement(Delete.default, { })
                                                            })
                                                      }));
                                      }))
                              }))
                    })
              });
  }
}

var targetWidth = 200;

var orderWidth = 80;

var tableWidthLarge = 100;

var tableWidthMedium = 85;

var tableWidthSmall = 70;

var make = SSATable;

exports.pageMarginBottom = pageMarginBottom;
exports.targetWidth = targetWidth;
exports.orderWidth = orderWidth;
exports.tableWidthLarge = tableWidthLarge;
exports.tableWidthMedium = tableWidthMedium;
exports.tableWidthSmall = tableWidthSmall;
exports.Ordered = Ordered;
exports.FourColumn = FourColumn;
exports.make = make;
/* pageMarginBottom Not a pure module */
