// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");

function AppBadge(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    className,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.width(Css.px(30)),
                            /* :: */[
                              Css.height(Css.px(15)),
                              /* :: */[
                                Css.borderRadius(Css.px(20)),
                                /* :: */[
                                  Css.paddingBottom(Css.px(2)),
                                  /* :: */[
                                    Css.background(Css.hex(Colors$BsConsole.appBadgeBlue)),
                                    /* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.alignItems(/* center */98248149),
                                        /* :: */[
                                          Css.justifyContent(/* center */98248149),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, "App"));
}

var make = AppBadge;

exports.make = make;
/* Css Not a pure module */
