// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var SubPriceUtil$BsConsole = require("./SubPriceUtil.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SubCustomPlan$BsConsole = require("./SubCustomPlan.js");
var SubUsageDisplay$BsConsole = require("./SubUsageDisplay.js");
var SubPaymentBanner$BsConsole = require("./SubPaymentBanner.js");
var NemesisPlanSelection$BsConsole = require("./NemesisPlanSelection.js");
var NemesisSubscriptions$BsConsole = require("./NemesisSubscriptions.js");
var SubCurrentPlanBanner$BsConsole = require("./SubCurrentPlanBanner.js");
var SubUsageDisplayWithNemesis$BsConsole = require("./SubUsageDisplayWithNemesis.js");

var plan = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontStyle(/* normal */812216871),
        /* :: */[
          Css.fontWeight(/* normal */812216871),
          /* :: */[
            Css.fontSize(Css.px(16)),
            /* :: */[
              Css.lineHeight(Css.px(26)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Style = {
  plan: plan
};

function SubSettingsWithNemesis(Props) {
  var token = Props.token;
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var limits = Props.limits;
  var refetchLimits = Props.refetchLimits;
  var match = React.useState((function () {
          
        }));
  var setBasePlans = match[1];
  var basePlans = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setSubscription = match$1[1];
  var subscription = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setLoadingLimits = match$2[1];
  var match$3 = React.useState((function () {
          
        }));
  var setRefetchSubscription = match$3[1];
  var refetchSubscription = match$3[0];
  var match$4 = React.useState((function () {
          var match = limits.metadata.invoiceMe;
          if (match !== undefined && match) {
            return true;
          } else {
            return false;
          }
        }));
  var invoiceMe = match$4[0];
  var updateSubscriptions = function (planId, planQuantity, addons) {
    Curry._1(setLoadingLimits, (function (param) {
            return true;
          }));
    var arg = NemesisSubscriptions$BsConsole.update(planId, planQuantity, addons);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Error updating subscription: ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ]),
                                        "Error updating subscription: %s"
                                      ]), Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var subscriptionResult = Belt_Array.get(resp[0], 0);
                  return Curry._1(setSubscription, (function (param) {
                                return subscriptionResult;
                              }));
                }));
  };
  React.useEffect((function () {
          if (SubUtil$BsConsole.canViewNemesisPlans(config)) {
            var arg = NemesisPlanSelection$BsConsole.$$fetch(undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      var err = resp[0];
                      if (err[0] === NemesisPlanSelection$BsConsole.ErrorMessage) {
                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Error retrieving plans: ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "Error retrieving plans: %s"
                                          ]), Task2$BsConsole.renderError(err[1], err)));
                      } else {
                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Error retrieving plans: ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "Error retrieving plans: %s"
                                          ]), Task2$BsConsole.renderError(undefined, err)));
                      }
                    }
                    var planResults = resp[0];
                    return Curry._1(setBasePlans, (function (param) {
                                  return planResults;
                                }));
                  }));
          }
          
        }), /* tuple */[
        config,
        token
      ]);
  React.useEffect((function () {
          if (SubUtil$BsConsole.canViewNemesisPlans(config)) {
            var arg = NemesisSubscriptions$BsConsole.$$fetch(undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "Error retrieving subscriptions: ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "Error retrieving subscriptions: %s"
                                        ]), Task2$BsConsole.renderError(undefined, resp[0])));
                    }
                    var subscriptionResults = resp[0];
                    Curry._1(setSubscription, (function (param) {
                            return Belt_Array.get(subscriptionResults, 0);
                          }));
                    if (refetchSubscription !== undefined) {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(refetchSubscription));
                    }
                    
                  }));
          }
          
        }), /* tuple */[
        config,
        token,
        refetchSubscription
      ]);
  var tmp;
  var exit = 0;
  if (subscription !== undefined && basePlans !== undefined) {
    var planInfo = SubPriceUtil$BsConsole.getPlanInfo(subscription.planId, basePlans);
    tmp = planInfo !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(SubCurrentPlanBanner$BsConsole.make, {
                config: config,
                limits: limits,
                refetchLimits: refetchLimits,
                subscription: subscription,
                subscribedPlanInfo: planInfo,
                plans: basePlans,
                loadingLimits: match$2[0],
                setLoadingLimits: setLoadingLimits,
                updateSubscriptions: updateSubscriptions,
                invoiceMe: invoiceMe
              }), React.createElement(SubUtil$BsConsole.UsageHeader.make, { }), React.createElement(SubUsageDisplayWithNemesis$BsConsole.make, {
                limits: limits,
                refetchLimits: refetchLimits,
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl,
                nemesis: /* tuple */[
                  subscription,
                  planInfo,
                  basePlans,
                  updateSubscriptions
                ],
                invoiceMe: invoiceMe
              }), React.createElement(SubUtil$BsConsole.PaymentsHeader.make, { }), React.createElement(SubPaymentBanner$BsConsole.make, {
                config: config,
                subscription: subscription,
                token: token,
                refetchSubscription: (function (toastMaybe) {
                    return Curry._1(setRefetchSubscription, (function (param) {
                                  return toastMaybe;
                                }));
                  }),
                limits: limits,
                refetchLimits: refetchLimits,
                invoiceMe: invoiceMe,
                setInvoiceMe: match$4[1]
              })) : React.createElement(React.Fragment, undefined, React.createElement(SubCustomPlan$BsConsole.make, {
                token: token,
                limits: limits,
                refetchLimits: refetchLimits,
                config: config
              }), React.createElement(SubUtil$BsConsole.UsageHeader.make, { }), React.createElement(SubUsageDisplay$BsConsole.make, {
                limits: limits,
                config: config,
                token: token,
                handleChangeUrl: handleChangeUrl
              }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(SubUtil$BsConsole.UsageHeader.make, { }), React.createElement(SubUsageDisplay$BsConsole.make, {
              limits: limits,
              config: config,
              token: token,
              handleChangeUrl: handleChangeUrl
            }));
  }
  return React.createElement("div", undefined, tmp);
}

var make = SubSettingsWithNemesis;

exports.Style = Style;
exports.make = make;
/* plan Not a pure module */
