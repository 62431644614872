// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var Numeral$BsConsole = require("./Numeral.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function ofJson(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          op: Json_decode.field("op", Json_decode.string, json),
          time: Json_decode.field("time", Json_decode.string, json)
        };
}

function toJson(criteria) {
  return [Json_encode.object_(/* :: */[
                /* tuple */[
                  "type",
                  "object-age"
                ],
                /* :: */[
                  /* tuple */[
                    "op",
                    "at-least"
                  ],
                  /* :: */[
                    /* tuple */[
                      "time",
                      criteria.time
                    ],
                    /* [] */0
                  ]
                ]
              ])];
}

var Criteria = {
  ofJson: ofJson,
  toJson: toJson
};

function toString(param) {
  switch (param) {
    case /* DeleteAll */0 :
        return "delete-all";
    case /* DeleteOnlyPhysical */1 :
        return "delete-only-physical";
    case /* CompressOnlyPhysical */2 :
        return "compress-only-physical";
    
  }
}

function fromString(param) {
  switch (param) {
    case "compress-only-physical" :
        return /* CompressOnlyPhysical */2;
    case "delete-all" :
        return /* DeleteAll */0;
    case "delete-only-physical" :
        return /* DeleteOnlyPhysical */1;
    default:
      return /* DeleteAll */0;
  }
}

function fromString$1(param) {
  if (param === "compress") {
    return /* Compress */1;
  } else {
    return /* Delete */0;
  }
}

function toString$1(param) {
  if (param) {
    return "compress";
  } else {
    return "delete-all";
  }
}

var Type = {
  fromString: fromString$1,
  toString: toString$1
};

function ofJson$1(json) {
  return {
          type_: fromString$1(Json_decode.field("type", Json_decode.string, json)),
          subsets: Json_decode.optional((function (param) {
                  return Json_decode.field("subsets", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

function toJson$1(action) {
  var subsets = action.subsets;
  return [Json_encode.object_(subsets !== undefined ? /* :: */[
                  /* tuple */[
                    "type",
                    action.type_ ? "compress" : "delete-all"
                  ],
                  /* :: */[
                    /* tuple */[
                      "subsets",
                      subsets
                    ],
                    /* [] */0
                  ]
                ] : /* :: */[
                  /* tuple */[
                    "type",
                    action.type_ ? "compress" : "delete-all"
                  ],
                  /* [] */0
                ])];
}

var Action = {
  toString: toString,
  fromString: fromString,
  Type: Type,
  ofJson: ofJson$1,
  toJson: toJson$1
};

function toActionType(r) {
  var match = r.action.type_;
  if (match) {
    return /* CompressOnlyPhysical */2;
  }
  var match$1 = r.action.subsets;
  if (match$1 === undefined) {
    return /* DeleteAll */0;
  }
  if (match$1.length !== 1) {
    return /* DeleteAll */0;
  }
  var match$2 = match$1[0];
  if (match$2 === "physical") {
    return /* DeleteOnlyPhysical */1;
  } else {
    return /* DeleteAll */0;
  }
}

function ofJson$2(json) {
  return {
          criteria: Belt_Array.getExn(Json_decode.field("criteria", (function (param) {
                      return Json_decode.array(ofJson, param);
                    }), json), 0),
          action: Belt_Array.getExn(Json_decode.field("actions", (function (param) {
                      return Json_decode.array(ofJson$1, param);
                    }), json), 0),
          key: JSON.stringify(json)
        };
}

function toJson$2(rule) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "criteria",
                toJson(rule.criteria)
              ],
              /* :: */[
                /* tuple */[
                  "actions",
                  toJson$1(rule.action)
                ],
                /* [] */0
              ]
            ]);
}

var Rule = {
  toActionType: toActionType,
  ofJson: ofJson$2,
  toJson: toJson$2
};

function ofJson$3(json) {
  return Json_decode.array(ofJson$2, json);
}

function fromString$2(policy) {
  try {
    var json = JSON.parse(policy);
    return Json_decode.array(ofJson$2, json);
  }
  catch (raw__msg){
    var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
    if (_msg[0] === Json_decode.DecodeError) {
      return [];
    }
    throw _msg;
  }
}

function toJson$3(policy) {
  return Belt_Array.map(policy, toJson$2);
}

function makeRule(time, actionType) {
  return {
          criteria: {
            type_: "object-age",
            op: "at-least",
            time: time
          },
          action: {
            type_: actionType >= 2 ? /* Compress */1 : /* Delete */0,
            subsets: actionType !== 1 ? undefined : ["physical"]
          },
          key: "new__rule"
        };
}

function getRetentionCompress(t) {
  return Belt_Array.getBy(t, (function (r) {
                return toActionType(r) === /* CompressOnlyPhysical */2;
              }));
}

function getRetentionDeleteOnlyPhysical(t) {
  return Belt_Array.getBy(t, (function (r) {
                return toActionType(r) === /* DeleteOnlyPhysical */1;
              }));
}

function getRetentionDeleteAll(t) {
  return Belt_Array.getBy(t, (function (r) {
                return toActionType(r) === /* DeleteAll */0;
              }));
}

function secondsToDays(seconds) {
  return Numeral$BsConsole.format("0.0", Pervasives.string_of_float(Caml_format.caml_int_of_string(seconds) / 86400));
}

function getCriteriaDays(policy) {
  return /* tuple */[
          Belt_Option.map(Belt_Option.flatMap(policy, (function (policy) {
                      return getRetentionCompress(fromString$2(policy.rules));
                    })), (function (rule) {
                  return Caml_format.caml_float_of_string(secondsToDays(rule.criteria.time));
                })),
          Belt_Option.map(Belt_Option.flatMap(policy, (function (policy) {
                      return getRetentionDeleteOnlyPhysical(fromString$2(policy.rules));
                    })), (function (rule) {
                  return Caml_format.caml_float_of_string(secondsToDays(rule.criteria.time));
                })),
          Belt_Option.map(Belt_Option.flatMap(policy, (function (policy) {
                      return getRetentionDeleteAll(fromString$2(policy.rules));
                    })), (function (rule) {
                  return Caml_format.caml_float_of_string(secondsToDays(rule.criteria.time));
                }))
        ];
}

function assembleRules(compress, physical, all, param) {
  return Belt_Array.keepMap(Belt_Array.map([
                  /* tuple */[
                    /* CompressOnlyPhysical */2,
                    compress
                  ],
                  /* tuple */[
                    /* DeleteOnlyPhysical */1,
                    physical
                  ],
                  /* tuple */[
                    /* DeleteAll */0,
                    all
                  ]
                ], (function (param) {
                    var value = param[1];
                    if (value === undefined) {
                      return ;
                    }
                    var time = String(value * 86400 | 0);
                    return makeRule(time, param[0]);
                  })), (function (v) {
                return v;
              }));
}

function updateProjectPolicy(policy, rules, token, onSuccess, onFailure, pid, param) {
  if (policy !== undefined) {
    var arg = BpgTask$BsConsole.ProjectRetention.updateFieldsByKey(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key(policy), [/* tuple */[
            "rules",
            rules
          ]]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (param) {
                  if (param.tag) {
                    return Curry._1(onFailure, undefined);
                  } else {
                    return Curry._1(onSuccess, undefined);
                  }
                }));
  }
  var arg$1 = BpgTask$BsConsole.ProjectRetention.create({
        project: pid,
        rules: JSON.stringify(rules),
        __state: undefined,
        __create: undefined,
        __modify: undefined
      });
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                    });
                })(token), undefined, (function (msg) {
                if (msg.tag) {
                  return Curry._1(onFailure, undefined);
                } else {
                  return Curry._1(onSuccess, undefined);
                }
              }));
}

function getDefaultRules(param) {
  return assembleRules(30, 90, 365, undefined);
}

exports.Criteria = Criteria;
exports.Action = Action;
exports.Rule = Rule;
exports.ofJson = ofJson$3;
exports.fromString = fromString$2;
exports.toJson = toJson$3;
exports.makeRule = makeRule;
exports.getRetentionCompress = getRetentionCompress;
exports.getRetentionDeleteOnlyPhysical = getRetentionDeleteOnlyPhysical;
exports.getRetentionDeleteAll = getRetentionDeleteAll;
exports.secondsToDays = secondsToDays;
exports.getCriteriaDays = getCriteriaDays;
exports.assembleRules = assembleRules;
exports.updateProjectPolicy = updateProjectPolicy;
exports.getDefaultRules = getDefaultRules;
/* Task2-BsConsole Not a pure module */
