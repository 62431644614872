// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugTabCount$BsConsole = require("./BugTabCount.js");

function BugSectionHeader(Props) {
  var title = Props.title;
  var count = Props.count;
  var classNameOpt = Props.className;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* spaceBetween */516682146,
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.fontFamily("Roboto"),
                          /* :: */[
                            Css.fontWeight(Css.medium),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey0)),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.padding2(Css.zero, Css.px(16)),
                                  /* :: */[
                                    Css.height(Css.px(32)),
                                    /* :: */[
                                      Css.flexShrink(0),
                                      /* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.borderBottom(Css.px(2), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                                          /* :: */[
                                            Css.textTransform(Css.uppercase),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  children: null
                }, React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.lineHeight(Css.px(12)),
                            /* [] */0
                          ])
                    }, I18N$BsConsole.showSkip(title)), React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey2)),
                            /* [] */0
                          ])
                    }, count !== undefined ? React.createElement(BugTabCount$BsConsole.make, {
                            label: String(count)
                          }) : null)), children);
}

var make = BugSectionHeader;

exports.make = make;
/* Css Not a pure module */
