// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function HideFeatureGate(Props) {
  var shouldHideOpt = Props.shouldHide;
  var children = Props.children;
  var shouldHide = shouldHideOpt !== undefined ? shouldHideOpt : true;
  if (shouldHide) {
    return null;
  } else {
    return children;
  }
}

function make(shouldHide, children) {
  return ReasonReactCompat.wrapReactForReasonReact(HideFeatureGate, {
              shouldHide: shouldHide,
              children: children
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = HideFeatureGate;

exports.make = make$1;
exports.Jsx2 = Jsx2;
/* ReasonReactCompat Not a pure module */
