// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var WfCore$BsConsole = require("./WfCore.js");
var WfParser$BsConsole = require("./WfParser.js");
var WfIntegration$BsConsole = require("./WfIntegration.js");

function fromJson(json) {
  var request = Json_decode.field("request", WfParser$BsConsole.parseFormSafe, json);
  if (request.tag) {
    console.log("Parsing action", WfParser$BsConsole.getUserFriendlyError(request[0]), json);
  }
  return {
          request: Json_decode.field("request", WfParser$BsConsole.parseForm, json)
        };
}

var IntegrationAction = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.optional((function (param) {
                  return Json_decode.field("value", Json_decode.string, param);
                }), json)
        };
}

function toObjectField(customField) {
  return WfCore$BsConsole.objectField(customField.id, {
              doc: {
                title: customField.name,
                description: undefined
              },
              schema: /* CustomField */Block.__(7, [{
                    defaultValue: customField.value,
                    required: false,
                    minLength: undefined,
                    maxLength: undefined,
                    regex: undefined,
                    inputType: undefined,
                    uiPopulatedType: undefined,
                    uiWidget: undefined,
                    placeholder: undefined
                  }])
            }, undefined);
}

var CustomField = {
  fromJson: fromJson$1,
  toObjectField: toObjectField
};

function fromJson$2(json) {
  var customFieldsArray = Json_decode.optional(Json_decode.at(/* :: */[
            "description",
            /* :: */[
              "custom_field_definitions",
              /* [] */0
            ]
          ], (function (param) {
              return Json_decode.array(fromJson$1, param);
            })), json);
  var customObjectFields = customFieldsArray !== undefined && customFieldsArray.length !== 0 ? Belt_Option.map(Belt_Option.map(customFieldsArray, (function (customFields) {
                return Belt_Array.map(customFields, toObjectField);
              })), (function (fields) {
            return WfCore$BsConsole.objectField("customFields", {
                        doc: {
                          title: "Custom Fields",
                          description: undefined
                        },
                        schema: /* Object */Block.__(6, [{
                              fields: fields
                            }])
                      }, undefined);
          })) : undefined;
  var forms = Json_decode.at(/* :: */[
          "schemas",
          /* :: */[
            "configuration",
            /* [] */0
          ]
        ], (function (param) {
            return Json_decode.array(WfParser$BsConsole.parseForm, param);
          }))(json);
  var f1 = Belt_Array.getExn(forms, 0);
  var f2 = Belt_Array.get(forms, 1);
  var f3 = Belt_Array.get(forms, 2);
  var forms$1 = {
    configureConnection: f1,
    customizePayload: f2,
    advancedSettings: f3
  };
  var __x = Json_decode.optional(Json_decode.at(/* :: */[
            "schemas",
            /* :: */[
              "actions",
              /* [] */0
            ]
          ], (function (param) {
              return Json_decode.dict(fromJson, param);
            })), json);
  return {
          forms: forms$1,
          description: Json_decode.field("description", WfIntegration$BsConsole.Description.fromJson, json),
          actions: Belt_Option.getWithDefault(__x, { }),
          customFields: customObjectFields
        };
}

function toSimple(fullIntegration) {
  return {
          description: fullIntegration.description,
          supportedActions: Object.keys(fullIntegration.actions)
        };
}

exports.IntegrationAction = IntegrationAction;
exports.CustomField = CustomField;
exports.fromJson = fromJson$2;
exports.toSimple = toSimple;
/* WfCore-BsConsole Not a pure module */
