// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var L10N_date_time_clock$BsConsole = require("./L10N_date_time_clock.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");
var L10N_date_time_settings$BsConsole = require("./L10N_date_time_settings.js");

function fromConfig(formats, calendar, clock, jsDate) {
  var formats$1 = L10N_date_time_settings$BsConsole.Cache.formats(formats);
  var calendarFormat = L10N_date_time_calendar$BsConsole.Config.pickFormat(calendar, formats$1);
  var clockFormat = L10N_date_time_clock$BsConsole.Config.pickFormat(clock, formats$1);
  return DateFns.format("" + (String(calendarFormat) + (" " + (String(clockFormat) + ""))), jsDate);
}

function now(param) {
  return new Date();
}

function numericDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* Day */0
            }, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

function numericDayTwentyFour(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* Day */0
            }, {
              scheme: /* TwentyFour */1,
              precision: /* Minute */0
            }, jsDate);
}

function numericDayTwentyFourToSecond(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* Day */0
            }, {
              scheme: /* TwentyFour */1,
              precision: /* Second */1
            }, jsDate);
}

function numericMonthDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* Numeric */0,
              precision: /* MonthDay */2
            }, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

function textDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextStandard */2,
              precision: /* Day */0
            }, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

function textAbbreviatedDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextAbbreviated */3,
              precision: /* Day */0
            }, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

function textAbbreviatedDayTwentyFour(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextAbbreviated */3,
              precision: /* Day */0
            }, {
              scheme: /* TwentyFour */1,
              precision: /* Minute */0
            }, jsDate);
}

function textAbbreviatedMonthDay(formats, jsDate) {
  return fromConfig(formats, {
              scheme: /* TextAbbreviated */3,
              precision: /* MonthDay */2
            }, {
              scheme: L10N_date_time_settings$BsConsole.clockScheme(Curry._1(L10N_date_time_settings$BsConsole.Cache.getExn, undefined)),
              precision: /* Minute */0
            }, jsDate);
}

var Format = {
  numericDay: numericDay,
  numericDayTwentyFour: numericDayTwentyFour,
  numericDayTwentyFourToSecond: numericDayTwentyFourToSecond,
  numericMonthDay: numericMonthDay,
  textDay: textDay,
  textAbbreviatedDay: textAbbreviatedDay,
  textAbbreviatedDayTwentyFour: textAbbreviatedDayTwentyFour,
  textAbbreviatedMonthDay: textAbbreviatedMonthDay
};

var Formats;

var Cache;

var Clock;

var Calendar;

var Settings;

exports.Formats = Formats;
exports.Cache = Cache;
exports.Clock = Clock;
exports.Calendar = Calendar;
exports.Settings = Settings;
exports.fromConfig = fromConfig;
exports.now = now;
exports.Format = Format;
/* DateFns Not a pure module */
