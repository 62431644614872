// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Styles$BsConsole = require("../../../utilities/Styles.js");
var MuiIcons$BsConsole = require("../../../MuiIcons.js");
var SnackPurveyor$BsConsole = require("../../../SnackPurveyor.js");

var headerStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey2)),
          /* [] */0
        ]
      ]
    ]);

var noSymbolsFound = React.createElement("div", {
      className: headerStyle
    }, I18N$BsConsole.show(undefined, "No symbols are currently listed."));

var paginationPlaceholder = React.createElement("div", {
      className: Css.merge(/* :: */[
            headerStyle,
            /* :: */[
              Css.style(/* :: */[
                    Css.width(Css.px(14)),
                    /* :: */[
                      Css.height(Css.px(14)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ])
    });

var trashPlaceholder = React.createElement("div", {
      className: Css.style(/* :: */[
            Css.width(Css.px(18)),
            /* :: */[
              Css.height(Css.px(18)),
              /* :: */[
                Css.marginLeft(Css.px(7)),
                /* :: */[
                  Css.marginRight(Css.px(7)),
                  /* [] */0
                ]
              ]
            ]
          ])
    });

var component = ReasonReact.reducerComponentWithRetainedProps("SymbolServers_SymbolList-BsConsole");

function showNext(send, page) {
  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                      className: Styles$BsConsole.$$Text.tooltipTitle
                    }, I18N$BsConsole.show(undefined, "View next page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(14)),
                              /* :: */[
                                Css.height(Css.px(14)),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* [] */0
                                ]
                              ]
                            ]) + " smaller-icon",
                        onClick: (function (param) {
                            return Curry._1(send, /* UpdatePage */Block.__(0, [page + 1 | 0]));
                          })
                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronRight.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
}

function showPrevious(send, page) {
  return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                      className: Styles$BsConsole.$$Text.tooltipTitle
                    }, I18N$BsConsole.show(undefined, "View previous page")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.cursor(/* pointer */-786317123),
                              /* [] */0
                            ]) + " smaller-icon",
                        onClick: (function (param) {
                            return Curry._1(send, /* UpdatePage */Block.__(0, [page - 1 | 0]));
                          })
                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]));
}

function make(getListTask, listName, postList, postListSuccess, postListFailure, deleteSymbol, deleteSymbolSuccess, deleteSymbolFailure, serverId, token, endpoint, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              if (serverId === self.retainedProps) {
                return self.state;
              }
              var init = self.state;
              return {
                      page: 0,
                      symbolsToUpload: init.symbolsToUpload,
                      hovered: init.hovered
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var hovered = match.hovered;
              var symbolsToUpload = match.symbolsToUpload;
              var page = match.page;
              return ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, Curry._5(getListTask, serverId, endpoint, page, 25, undefined), undefined, (function (remote, refetch) {
                                if (typeof remote === "number") {
                                  if (remote === /* NotAsked */0) {
                                    return null;
                                  } else {
                                    return React.createElement("div", {
                                                className: headerStyle
                                              }, I18N$BsConsole.show(undefined, "Loading..."));
                                  }
                                }
                                if (remote.tag) {
                                  return React.createElement("div", {
                                              className: headerStyle
                                            }, I18N$BsConsole.show(undefined, "Failed to load symbols."));
                                }
                                var match = remote[0][0];
                                var total = match[1];
                                var result = match[0];
                                var tmp;
                                if (Belt_List.length(result) === 0) {
                                  tmp = noSymbolsFound;
                                } else {
                                  var __x = Belt_List.toArray(result);
                                  tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                React.createElement("div", {
                                                      className: Css.merge(/* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.marginRight(Css.px(166)),
                                                                  /* :: */[
                                                                    Css.marginLeft(Css.px(32)),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            /* :: */[
                                                              headerStyle,
                                                              /* [] */0
                                                            ]
                                                          ])
                                                    }, I18N$BsConsole.show(undefined, "Date")),
                                                React.createElement("div", {
                                                      className: headerStyle
                                                    }, I18N$BsConsole.show(undefined, "Symbol name"))
                                              ])), Belt_Array.map(__x, (function (item) {
                                              return React.createElement("span", {
                                                          key: String(item.id),
                                                          onMouseEnter: (function (param) {
                                                              return Curry._1(send, /* MouseEnter */Block.__(2, [item.id]));
                                                            }),
                                                          onMouseLeave: (function (param) {
                                                              return Curry._1(send, /* MouseLeave */1);
                                                            })
                                                        }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, hovered !== undefined && hovered === item.id ? Css.style(/* :: */[
                                                                        Css.backgroundColor(/* `hex */[
                                                                              5194459,
                                                                              Colors$BsConsole.grey8
                                                                            ]),
                                                                        /* [] */0
                                                                      ]) : "", [
                                                                  hovered !== undefined && hovered === item.id ? ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                  className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                }, I18N$BsConsole.showSkip(listName + " - "), I18N$BsConsole.show(undefined, "Remove symbol from server")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                    onClick: (function (param) {
                                                                                        new Promise((function (resolve, param) {
                                                                                                var arg = Curry._3(deleteSymbol, endpoint, item.id, undefined);
                                                                                                return Curry._2((function (param) {
                                                                                                                return (function (param$1, param$2) {
                                                                                                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                                  });
                                                                                                              })(token), true, (function (resp) {
                                                                                                              if (resp.tag) {
                                                                                                                Curry._1(deleteSymbolFailure, undefined);
                                                                                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete symbol."));
                                                                                                                return resolve(Caml_option.some(resp[0]));
                                                                                                              }
                                                                                                              Curry._1(deleteSymbolSuccess, undefined);
                                                                                                              Curry._1(refetch, undefined);
                                                                                                              return resolve(undefined);
                                                                                                            }));
                                                                                              }));
                                                                                        
                                                                                      })
                                                                                  }, React.createElement("span", {
                                                                                        className: Css.style(/* :: */[
                                                                                              Css.color(Css.hex(Colors$BsConsole.grey5)),
                                                                                              /* :: */[
                                                                                                Css.marginLeft(Css.px(7)),
                                                                                                /* :: */[
                                                                                                  Css.marginRight(Css.px(7)),
                                                                                                  /* :: */[
                                                                                                    Css.cursor(/* pointer */-786317123),
                                                                                                    /* [] */0
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]) + " smaller-icon"
                                                                                      }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))))])) : trashPlaceholder,
                                                                  React.createElement("pre", {
                                                                        className: Css.style(/* :: */[
                                                                              Css.marginRight(Css.px(25)),
                                                                              /* :: */[
                                                                                Css.fontSize(Css.px(14)),
                                                                                /* :: */[
                                                                                  Css.lineHeight(Css.px(14)),
                                                                                  /* :: */[
                                                                                    Css.fontFamily("Inconsolata"),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ])
                                                                      }, I18N$BsConsole.showSkip(item.creationDate)),
                                                                  React.createElement("pre", {
                                                                        className: Css.style(/* :: */[
                                                                              Css.width(Css.px(250)),
                                                                              /* :: */[
                                                                                Css.whiteSpace(/* preWrap */660870029),
                                                                                /* :: */[
                                                                                  Css.wordWrap(/* breakWord */1059921449),
                                                                                  /* :: */[
                                                                                    Css.fontSize(Css.px(14)),
                                                                                    /* :: */[
                                                                                      Css.lineHeight(Css.px(14)),
                                                                                      /* :: */[
                                                                                        Css.fontFamily("Inconsolata"),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ])
                                                                      }, I18N$BsConsole.showSkip(item.symbolName))
                                                                ])));
                                            })), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                    Css.display(/* flex */-1010954439),
                                                    /* :: */[
                                                      Css.justifyContent(/* spaceBetween */516682146),
                                                      /* :: */[
                                                        Css.width(Css.px(130)),
                                                        /* :: */[
                                                          Css.marginTop(Css.px(15)),
                                                          /* :: */[
                                                            Css.marginRight(Css.px(115)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]), [
                                                page !== 0 ? showPrevious(send, page) : paginationPlaceholder,
                                                total === 0 ? null : React.createElement("div", undefined, I18N$BsConsole.showSkip("" + (String(page + 1 | 0) + (" / " + String(total))))),
                                                total === 0 || (page + 1 | 0) === total ? paginationPlaceholder : showNext(send, page)
                                              ])));
                                }
                                return React.createElement("div", undefined, React.createElement("form", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.px(25)),
                                                      /* [] */0
                                                    ])
                                              }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("i", {
                                                              className: Css.merge(/* :: */[
                                                                    Css.style(/* :: */[
                                                                          Css.marginBottom(Css.px(24)),
                                                                          /* [] */0
                                                                        ]),
                                                                    /* :: */[
                                                                      Styles$BsConsole.$$Text.subtitle,
                                                                      /* [] */0
                                                                    ]
                                                                  ])
                                                            }, I18N$BsConsole.showSkip(listName + " - "), I18N$BsConsole.show(undefined, "Add symbols to list. To add multiple symbols at once, separate symbols with new lines."))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                                            Css.width(Css.pct(75)),
                                                            /* [] */0
                                                          ]), [
                                                        React.createElement("label", {
                                                              className: Css.merge(/* :: */[
                                                                    headerStyle,
                                                                    /* :: */[
                                                                      Css.style(/* :: */[
                                                                            Css.display(/* block */888960333),
                                                                            /* :: */[
                                                                              Css.marginBottom(Css.px(10)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              htmlFor: "symbolserver-whitelist-input"
                                                            }, I18N$BsConsole.showSkip(listName + " - "), I18N$BsConsole.show(undefined, "Add symbols")),
                                                        ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                      className: Styles$BsConsole.$$Text.tooltipTitle
                                                                    }, I18N$BsConsole.showSkip(listName + " - "), I18N$BsConsole.show(undefined, "Add symbols")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                        className: Css.merge(/* :: */[
                                                                              headerStyle,
                                                                              /* :: */[
                                                                                Css.style(/* :: */[
                                                                                      Css.color(Css.hex(Colors$BsConsole.accent)),
                                                                                      /* :: */[
                                                                                        Css.cursor(/* pointer */-786317123),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]),
                                                                                /* [] */0
                                                                              ]
                                                                            ]),
                                                                        onClick: (function (param) {
                                                                            var listOfSymbols = Belt_List.fromArray(symbolsToUpload.split("\n"));
                                                                            new Promise((function (resolve, param) {
                                                                                    var arg = Curry._4(postList, endpoint, serverId, listOfSymbols, undefined);
                                                                                    return Curry._2((function (param) {
                                                                                                    return (function (param$1, param$2) {
                                                                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                      });
                                                                                                  })(token), true, (function (resp) {
                                                                                                  if (resp.tag) {
                                                                                                    Curry._1(postListFailure, undefined);
                                                                                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to upload symbols."));
                                                                                                    return resolve(Caml_option.some(resp[0]));
                                                                                                  }
                                                                                                  Curry._1(postListSuccess, undefined);
                                                                                                  Curry._1(refetch, undefined);
                                                                                                  Curry._1(send, /* ClearSymbolsToUpload */0);
                                                                                                  return resolve(undefined);
                                                                                                }));
                                                                                  }));
                                                                            
                                                                          })
                                                                      }, I18N$BsConsole.show(undefined, "Update"))]))
                                                      ])), React.createElement("textarea", {
                                                    className: Css.style(/* :: */[
                                                          Css.width(Css.pct(75)),
                                                          /* :: */[
                                                            Css.height(Css.px(50)),
                                                            /* :: */[
                                                              Css.fontFamily("Inconsolata"),
                                                              /* :: */[
                                                                Css.resize(Css.none),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]),
                                                    id: "symbolserver-input",
                                                    type: "text",
                                                    value: symbolsToUpload,
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        return Curry._1(send, /* UpdateSymbolsToUpload */Block.__(1, [value]));
                                                      })
                                                  })), tmp);
                              })));
            }),
          initialState: (function (param) {
              return {
                      page: 0,
                      symbolsToUpload: "",
                      hovered: undefined
                    };
            }),
          retainedProps: serverId,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* ClearSymbolsToUpload */0) {
                  return /* Update */Block.__(0, [{
                              page: state.page,
                              symbolsToUpload: "",
                              hovered: state.hovered
                            }]);
                } else {
                  return /* Update */Block.__(0, [{
                              page: state.page,
                              symbolsToUpload: state.symbolsToUpload,
                              hovered: undefined
                            }]);
                }
              }
              switch (action.tag | 0) {
                case /* UpdatePage */0 :
                    return /* Update */Block.__(0, [{
                                page: action[0],
                                symbolsToUpload: state.symbolsToUpload,
                                hovered: state.hovered
                              }]);
                case /* UpdateSymbolsToUpload */1 :
                    return /* Update */Block.__(0, [{
                                page: state.page,
                                symbolsToUpload: action[0],
                                hovered: state.hovered
                              }]);
                case /* MouseEnter */2 :
                    return /* Update */Block.__(0, [{
                                page: state.page,
                                symbolsToUpload: state.symbolsToUpload,
                                hovered: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var take = 25;

exports.take = take;
exports.headerStyle = headerStyle;
exports.noSymbolsFound = noSymbolsFound;
exports.paginationPlaceholder = paginationPlaceholder;
exports.trashPlaceholder = trashPlaceholder;
exports.component = component;
exports.showNext = showNext;
exports.showPrevious = showPrevious;
exports.make = make;
/* headerStyle Not a pure module */
