// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SavedQueries$BsConsole = require("./SavedQueries.js");
var SavedQueriesContext$BsConsole = require("./SavedQueriesContext.js");

function SavedQueriesContainer(Props) {
  var name = Props.name;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var query = Props.query;
  var mode = Props.mode;
  var normBy = Props.normBy;
  var renderTrigger = Props.renderTrigger;
  var match = React.useState((function () {
          return /* tuple */[
                  false,
                  undefined
                ];
        }));
  var setOpen = match[1];
  var match$1 = match[0];
  var match$2 = SavedQueriesContext$BsConsole.useData(undefined);
  var refetchQueriesBpg = match$2.refetchQueriesBpg;
  var refetchQueries = match$2.refetchQueries;
  var queries = match$2.queries;
  var userBookmarkedViews = match$2.userBookmarkedViews;
  var projectBookmarkedViews = match$2.projectBookmarkedViews;
  var projectDefault = match$2.projectDefault;
  var userDefault = match$2.userDefault;
  var handleOpen = function (_event, dialog) {
    return Curry._1(setOpen, (function (param) {
                  return /* tuple */[
                          true,
                          dialog
                        ];
                }));
  };
  var tmp = {
    name: name,
    token: token,
    handleTask: handleTask,
    handleChangeUrl: handleChangeUrl,
    config: config,
    query: query,
    mode: mode,
    queriesBpg: match$2.queriesBpg,
    queries: queries,
    refetchQueries: (function (param) {
        Curry._1(refetchQueries, undefined);
        return Curry._1(refetchQueriesBpg, undefined);
      }),
    project: match$2.project,
    projectDefault: projectDefault,
    refetchProjectSettings: match$2.refetchProjectSettings,
    userDefault: userDefault,
    refetchUserSettings: match$2.refetchUserSettings,
    normBy: normBy,
    projectBookmarkedViews: projectBookmarkedViews,
    userBookmarkedViews: userBookmarkedViews,
    open_: match$1[0],
    onClose: (function (param) {
        return Curry._1(setOpen, (function (param) {
                      return /* tuple */[
                              false,
                              undefined
                            ];
                    }));
      })
  };
  var tmp$1 = match$1[1];
  if (tmp$1 !== undefined) {
    tmp.dialogOnOpen = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, Curry._1(renderTrigger, {
                  handleOpen: handleOpen,
                  queries: queries,
                  projectDefault: projectDefault,
                  userDefault: userDefault,
                  projectBookmarkedViews: projectBookmarkedViews,
                  userBookmarkedViews: userBookmarkedViews
                }), React.createElement(SavedQueries$BsConsole.Drawer.Jsx3.make, tmp));
}

function make(name, token, handleTask, handleChangeUrl, config, query, mode, normBy, renderTrigger, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesContainer, {
              name: name,
              token: token,
              handleTask: handleTask,
              handleChangeUrl: handleChangeUrl,
              config: config,
              query: query,
              mode: mode,
              normBy: normBy,
              renderTrigger: renderTrigger
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueriesContainer;

exports.make = make$1;
exports.Re = Re;
/* react Not a pure module */
