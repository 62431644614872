// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("./components/Col2.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Email$BsConsole = require("./Email.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var ApiSaml$BsConsole = require("./ApiSaml.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("./Services.js");
var FetchSaml$BsConsole = require("./FetchSaml.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var ApiStrategies$BsConsole = require("./ApiStrategies.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Select = require("@material-ui/core/Select");
var Divider = require("@material-ui/core/Divider");
var Checkbox = require("@material-ui/core/Checkbox");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var Warning = require("@material-ui/icons/Warning");
var Typography = require("@material-ui/core/Typography");
var Wf2AdvancedOptions$BsConsole = require("./workflow/Wf2AdvancedOptions.js");
var CardContent = require("@material-ui/core/CardContent");
var FormControl = require("@material-ui/core/FormControl");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

function showFeature(minVersion, currentVersion) {
  try {
    return Semver.gte(currentVersion, minVersion);
  }
  catch (exn){
    return false;
  }
}

function hasAccess(currentVersion, config, useNewAuthService, param) {
  var user = config.user;
  if (useNewAuthService !== undefined && useNewAuthService === true) {
    if (Belt_Option.getWithDefault(user.role, "") === "admin") {
      return true;
    } else {
      return user.superuser === 1;
    }
  }
  if (currentVersion === undefined) {
    if (Belt_Option.getWithDefault(user.role, "") === "admin") {
      return true;
    } else {
      return user.superuser === 1;
    }
  }
  try {
    if (Semver.gte(currentVersion, "1.6.0")) {
      return Belt_Option.getWithDefault(user.role, "") === "admin";
    } else if (Belt_Option.getWithDefault(user.role, "") === "admin") {
      return true;
    } else {
      return user.superuser === 1;
    }
  }
  catch (exn){
    if (Belt_Option.getWithDefault(user.role, "") === "admin") {
      return true;
    } else {
      return user.superuser === 1;
    }
  }
}

var configItem = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* :: */[
        Css.marginBottom(Css.px(12)),
        /* :: */[
          Css.marginTop(Css.px(6)),
          /* :: */[
            Css.display(/* inlineFlex */53323314),
            /* :: */[
              Css.background(Css.hex(Colors$BsConsole.grey7)),
              /* :: */[
                Css.fontFamily("Inconsolata"),
                /* :: */[
                  Css.borderRadius(Css.px(2)),
                  /* :: */[
                    Css.whiteSpace(/* preWrap */660870029),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* [] */0
      ]
    ]);

function SettingsSamlConfig$SamlSupport(Props) {
  return React.createElement(CardContent.default, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  children: I18N$BsConsole.show(undefined, "Single sign-on Configuration")
                }), React.createElement("br", undefined), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: null
                }, I18N$BsConsole.show(undefined, "This instance of Backtrace I/O is not configured for single sign-on . If you would like to explore configuring single sign-on for your instance, please contact "), React.createElement("a", {
                      className: link + " link",
                      href: "mailto:" + Email$BsConsole.support
                    }, I18N$BsConsole.show(undefined, "Backtrace support")), I18N$BsConsole.showSkip(".")));
}

var SamlSupport = {
  make: SettingsSamlConfig$SamlSupport
};

function SettingsSamlConfig$ConfigItem(Props) {
  var label = Props.label;
  var value = Props.value;
  if (value !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.display(/* block */888960333),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey3)),
                            /* [] */0
                          ]
                        ]),
                    children: I18N$BsConsole.dynamic(label)
                  }), React.createElement("div", {
                    className: configItem
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        children: I18N$BsConsole.dynamic(value)
                      })));
  } else {
    return null;
  }
}

var ConfigItem = {
  make: SettingsSamlConfig$ConfigItem
};

function getInitialState(tenant, strategy) {
  if (strategy !== undefined) {
    return {
            issuer: strategy.options.issuer,
            entryPoint: strategy.options.entryPoint,
            cert: Belt_Option.getWithDefault(strategy.options.cert, ""),
            decryptionPvk: Belt_Option.getWithDefault(strategy.options.decryptionKey, ""),
            adminContact: Belt_Option.getWithDefault(strategy.options.adminContact, ""),
            privateCert: Belt_Option.getWithDefault(strategy.options.privateKey, ""),
            signatureAlgorithm: Belt_Option.getWithDefault(strategy.options.signatureAlgorithm, /* sha256 */486188135),
            callbackUrl: strategy.options.callbackUrl,
            userProvisioning: strategy.userProvisioning,
            error: undefined,
            advancedOptionsOpen: false,
            id: strategy.id,
            name: strategy.name,
            strategy: strategy.strategy,
            isDefault: strategy.isDefault
          };
  } else if (tenant !== undefined) {
    return {
            issuer: tenant.issuer,
            entryPoint: tenant.entryPoint,
            cert: Belt_Option.getWithDefault(tenant.cert, ""),
            decryptionPvk: Belt_Option.getWithDefault(tenant.decryptionPvk, ""),
            adminContact: Belt_Option.getWithDefault(tenant.adminContact, ""),
            privateCert: Belt_Option.getWithDefault(tenant.privateCert, ""),
            signatureAlgorithm: Belt_Option.getWithDefault(tenant.signatureAlgorithm, /* sha256 */486188135),
            callbackUrl: tenant.callbackUrl,
            userProvisioning: tenant.settings.userProvisioning,
            error: undefined,
            advancedOptionsOpen: false,
            id: undefined,
            name: undefined,
            strategy: undefined,
            isDefault: undefined
          };
  } else {
    return {
            issuer: "https://saml.backtrace.io",
            entryPoint: "",
            cert: "",
            decryptionPvk: "",
            adminContact: "",
            privateCert: "",
            signatureAlgorithm: /* sha256 */486188135,
            callbackUrl: undefined,
            userProvisioning: false,
            error: undefined,
            advancedOptionsOpen: false,
            id: undefined,
            name: undefined,
            strategy: undefined,
            isDefault: undefined
          };
  }
}

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            issuer: state.issuer,
            entryPoint: state.entryPoint,
            cert: state.cert,
            decryptionPvk: state.decryptionPvk,
            adminContact: state.adminContact,
            privateCert: state.privateCert,
            signatureAlgorithm: state.signatureAlgorithm,
            callbackUrl: state.callbackUrl,
            userProvisioning: state.userProvisioning,
            error: state.error,
            advancedOptionsOpen: !state.advancedOptionsOpen,
            id: state.id,
            name: state.name,
            strategy: state.strategy,
            isDefault: state.isDefault
          };
  }
  switch (action.tag | 0) {
    case /* SetIssuer */0 :
        return {
                issuer: action[0],
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetEntryPoint */1 :
        return {
                issuer: state.issuer,
                entryPoint: action[0],
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetCert */2 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: action[0],
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetPrivateCert */3 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: action[0],
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetSignatureAlgorithm */4 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: action[0],
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetDecryptionPvk */5 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: action[0],
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetAdminContact */6 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: action[0],
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetCallbackUrl */7 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: action[0],
                userProvisioning: state.userProvisioning,
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetUserProvisioning */8 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: action[0],
                error: state.error,
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    case /* SetError */9 :
        return {
                issuer: state.issuer,
                entryPoint: state.entryPoint,
                cert: state.cert,
                decryptionPvk: state.decryptionPvk,
                adminContact: state.adminContact,
                privateCert: state.privateCert,
                signatureAlgorithm: state.signatureAlgorithm,
                callbackUrl: state.callbackUrl,
                userProvisioning: state.userProvisioning,
                error: action[0],
                advancedOptionsOpen: state.advancedOptionsOpen,
                id: state.id,
                name: state.name,
                strategy: state.strategy,
                isDefault: state.isDefault
              };
    
  }
}

function SettingsSamlConfig$FormDialog$AuthConfigForm(Props) {
  var token = Props.token;
  var state = Props.state;
  var actionType = Props.actionType;
  var issuer = Props.issuer;
  var entryPoint = Props.entryPoint;
  var cert = Props.cert;
  var onClose = Props.onClose;
  var serviceEndpoint = Props.serviceEndpoint;
  var formToStrategy = Props.formToStrategy;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var error = Props.error;
  var getInputVal = Props.getInputVal;
  var dispatch = Props.dispatch;
  var callbackUrl = Props.callbackUrl;
  var userProvisioning = Props.userProvisioning;
  var adminContact = Props.adminContact;
  var signatureAlgorithm = Props.signatureAlgorithm;
  var privateCert = Props.privateCert;
  var decryptionPvk = Props.decryptionPvk;
  var advancedOptionsOpen = Props.advancedOptionsOpen;
  var isCertRequired = Props.isCertRequired;
  var template = serviceEndpoint.includes("https") ? serviceEndpoint + "/saml/{backtrace hostname}" : "https://{backtrace hostname}/api/sso/saml/{backtrace hostname}";
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: Css.style(/* :: */[
                      Css.maxWidth(Css.px(800)),
                      /* :: */[
                        Css.minWidth(Css.px(350)),
                        /* [] */0
                      ]
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              maxWidth: "lg",
              disableBackdropClick: true,
              children: null
            }, React.createElement(CardContent.default, {
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading6 */5,
                            children: actionType ? I18N$BsConsole.show(undefined, "Modify SSO configuration") : I18N$BsConsole.show(undefined, "Create SSO configuration")
                          }), React.createElement(Row2$BsConsole.make, {
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(onClose, undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                disabled: issuer.trim() === "" || entryPoint.trim() === "" || (
                                  isCertRequired ? cert.trim() === "" : false
                                ),
                                onClick: (function (param) {
                                    var newStrategy = Curry._1(formToStrategy, state);
                                    var maybeTask = actionType ? ApiStrategies$BsConsole.EditStrategy.editStrategyTask(serviceEndpoint, newStrategy, state.id) : ApiStrategies$BsConsole.CreateStrategy.createStrategyTask(serviceEndpoint, newStrategy);
                                    if (maybeTask !== undefined) {
                                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                    return FetchSaml$BsConsole.responseCb(onSuccess, onFailure, param);
                                                  }));
                                    } else {
                                      return Curry._1(onFailure, actionType ? "Error updating configuration" : "Error creating configuration");
                                    }
                                  }),
                                children: I18N$BsConsole.show(undefined, "Save")
                              })))
                }), React.createElement(Divider.default, { }), error !== undefined ? React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.padding(Css.px(16)),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.redDark)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                              /* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redLight)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: I18N$BsConsole.dynamic(error)
                      })) : null, React.createElement(CardContent.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.overflow(/* auto */-1065951377),
                          /* :: */[
                            Css.selector("a:hover, a:visited", /* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                  /* :: */[
                                    Css.important(Css.textDecoration(/* underline */131142924)),
                                    /* [] */0
                                  ]
                                ]),
                            /* :: */[
                              Css.selector("a", /* :: */[
                                    Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  children: React.createElement(FormControl.default, {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "Entity ID"),
                            title: I18N$BsConsole.get(undefined, "Entity ID (issuer)"),
                            value: issuer,
                            label: I18N$BsConsole.show(undefined, "ID for the Service provider. By default, hosted Backtrace tenants will have a value of https://saml.backtrace.io for the Entity ID. This setting needs to be the same in your Identity provider's configuration."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetIssuer */Block.__(0, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "SSO URL"),
                            title: I18N$BsConsole.get(undefined, "SSO URL"),
                            value: entryPoint,
                            label: I18N$BsConsole.show(undefined, "URL pointing to Identity provider"),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetEntryPoint */Block.__(1, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle2 */7,
                            children: I18N$BsConsole.show(undefined, "User provisioning")
                          }), React.createElement(Typography.default, {
                            variant: "caption",
                            children: I18N$BsConsole.show(undefined, "User provisioning, if enabled, allows the SAML SSO service to create a Backtrace user on the return of a successful assertion from the identity provider.")
                          }), React.createElement(FormControlLabel.default, {
                            label: I18N$BsConsole.get(undefined, "Enable user provisioning"),
                            control: React.createElement(Checkbox.default, {
                                  checked: userProvisioning,
                                  color: "primary",
                                  onChange: (function ($$event) {
                                      var maybeValue = $$event.target.checked;
                                      if (!(maybeValue == null)) {
                                        return Curry._1(dispatch, /* SetUserProvisioning */Block.__(8, [maybeValue]));
                                      }
                                      
                                    })
                                })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "admin@my-organization.com"),
                            title: I18N$BsConsole.get(undefined, "Admin contact"),
                            value: adminContact,
                            label: I18N$BsConsole.show(undefined, "(Optional) email contact for handling SSO related topics within the organization."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetAdminContact */Block.__(6, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "-----BEGIN CERTIFICATE-----\n<certificate contents>\n-----END CERTIFICATE-----"),
                            title: I18N$BsConsole.get(undefined, "Public certificate"),
                            value: cert,
                            label: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* Char_literal */Block.__(12, [
                                          /* "(" */40,
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  ") Identity provider's public signing certificate used to validate the signatures of SAML Responses. PEM format.",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "(%s) Identity provider's public signing certificate used to validate the signatures of SAML Responses. PEM format."
                                    ]), isCertRequired ? "Required" : "Optional"),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetCert */Block.__(2, [maybeValue]));
                                }
                                
                              }),
                            rows: 5,
                            rowsMax: 20,
                            multiline: true
                          }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                            title: I18N$BsConsole.get(undefined, "SAML request private key")
                          }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                            label: I18N$BsConsole.show(undefined, "(Optional) Identity provider's private key for signing SAML requests. PEM format.")
                          }), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: null
                          }, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                                label: I18N$BsConsole.show(undefined, "Signature algorithm")
                              }), React.createElement(Select.default, {
                                value: /* `String */[
                                  -976970511,
                                  ApiSaml$BsConsole.Tenant.signatureAlgorithmToString(signatureAlgorithm)
                                ],
                                onChange: (function ($$event, param) {
                                    var value = $$event.target.value;
                                    if (typeof value === "number" || value[0] !== -976970511) {
                                      return ;
                                    } else {
                                      return Curry._1(dispatch, /* SetSignatureAlgorithm */Block.__(4, [ApiSaml$BsConsole.Tenant.signatureAlgorithmFromString(value[1])]));
                                    }
                                  }),
                                renderValue: (function (value) {
                                    return React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Caption */11,
                                                className: Css.style(/* :: */[
                                                      Css.padding2(/* zero */-789508312, Css.rem(1)),
                                                      /* [] */0
                                                    ]),
                                                children: I18N$BsConsole.showSkip(value[1])
                                              });
                                  }),
                                disableUnderline: true,
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA09)),
                                        /* :: */[
                                          Css.marginBottom(Css.rem(0.5)),
                                          /* :: */[
                                            Css.marginLeft(Css.rem(0.5)),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                },
                                children: null
                              }, React.createElement(MenuItem.default, {
                                    value: /* `String */[
                                      -976970511,
                                      "sha1"
                                    ],
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          children: I18N$BsConsole.show(undefined, "sha1")
                                        })
                                  }), React.createElement(MenuItem.default, {
                                    value: /* `String */[
                                      -976970511,
                                      "sha256"
                                    ],
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          children: I18N$BsConsole.show(undefined, "sha256")
                                        })
                                  }), React.createElement(MenuItem.default, {
                                    value: /* `String */[
                                      -976970511,
                                      "sha512"
                                    ],
                                    children: React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Caption */11,
                                          children: I18N$BsConsole.show(undefined, "sha512")
                                        })
                                  }))), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                            multiline: true,
                            rows: 5,
                            rowsMax: 20,
                            placeholder: I18N$BsConsole.get(undefined, "-----BEGIN PRIVATE KEY-----\n<private key contents here delimited at 64 characters per row>\n-----END PRIVATE KEY-----"),
                            value: privateCert,
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetPrivateCert */Block.__(3, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "Decryption private key"),
                            title: I18N$BsConsole.get(undefined, "Decryption private key"),
                            value: decryptionPvk,
                            label: I18N$BsConsole.show(undefined, "(Optional) Private key that will be used to attempt to decrypt any encrypted assertions from identity provider. PEM format."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetDecryptionPvk */Block.__(5, [maybeValue]));
                                }
                                
                              }),
                            rows: 5,
                            rowsMax: 20,
                            multiline: true
                          }), React.createElement(Wf2AdvancedOptions$BsConsole.make, {
                            isOpen: advancedOptionsOpen,
                            handleToggle: (function (param) {
                                return Curry._1(dispatch, /* ToggleAdvancedOptions */0);
                              }),
                            styles: Css.style(/* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.justifyContent(/* flexStart */662439529),
                                    /* :: */[
                                      Css.width(Css.px(200)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            children: React.createElement(BtSettings$BsConsole.FormInput.make, {
                                  placeholder: template,
                                  title: I18N$BsConsole.get(undefined, "Callback URL"),
                                  value: Belt_Option.getWithDefault(callbackUrl, ""),
                                  label: React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "(Optional) URL for Identity provider to post SAML payload to service provider (Backtrace). Format should follow the template of "), React.createElement(BtSettings$BsConsole.InlineHighlight.make, {
                                            children: I18N$BsConsole.dynamic(template)
                                          }), I18N$BsConsole.showSkip(".")),
                                  onChange: (function ($$event) {
                                      var maybeValue = Curry._1(getInputVal, $$event);
                                      if (maybeValue == null) {
                                        return ;
                                      }
                                      var match = maybeValue.trim();
                                      var maybeCallbackUrl = match === "" ? undefined : maybeValue.trim();
                                      return Curry._1(dispatch, /* SetCallbackUrl */Block.__(7, [maybeCallbackUrl]));
                                    })
                                })
                          }))
                }));
}

var AuthConfigForm = {
  make: SettingsSamlConfig$FormDialog$AuthConfigForm
};

function SettingsSamlConfig$FormDialog$SsoConfigForm(Props) {
  var token = Props.token;
  var state = Props.state;
  var actionType = Props.actionType;
  var issuer = Props.issuer;
  var entryPoint = Props.entryPoint;
  var cert = Props.cert;
  var onClose = Props.onClose;
  var serviceEndpoint = Props.serviceEndpoint;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var error = Props.error;
  var getInputVal = Props.getInputVal;
  var dispatch = Props.dispatch;
  var callbackUrl = Props.callbackUrl;
  var userProvisioning = Props.userProvisioning;
  var adminContact = Props.adminContact;
  var signatureAlgorithm = Props.signatureAlgorithm;
  var privateCert = Props.privateCert;
  var decryptionPvk = Props.decryptionPvk;
  var currentVersion = Props.currentVersion;
  var formToTenant = Props.formToTenant;
  var tmp;
  if (showFeature("1.4.6", currentVersion)) {
    var template = serviceEndpoint.includes("https") ? serviceEndpoint + "/saml/{backtrace hostname}" : "https://{backtrace hostname}/api/sso/saml/{backtrace hostname}";
    tmp = React.createElement(BtSettings$BsConsole.FormInput.make, {
          placeholder: template,
          title: I18N$BsConsole.get(undefined, "Callback URL"),
          value: Belt_Option.getWithDefault(callbackUrl, ""),
          label: React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "(Required) URL for Identity provider to post SAML payload to service provider (Backtrace). Format should follow the template of "), React.createElement(BtSettings$BsConsole.InlineHighlight.make, {
                    children: I18N$BsConsole.dynamic(template)
                  }), I18N$BsConsole.showSkip(".")),
          onChange: (function ($$event) {
              var maybeValue = Curry._1(getInputVal, $$event);
              if (maybeValue == null) {
                return ;
              }
              var match = maybeValue.trim();
              var maybeCallbackUrl = match === "" ? undefined : maybeValue.trim();
              return Curry._1(dispatch, /* SetCallbackUrl */Block.__(7, [maybeCallbackUrl]));
            })
        });
  } else {
    tmp = null;
  }
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: Css.style(/* :: */[
                      Css.maxWidth(Css.px(800)),
                      /* :: */[
                        Css.minWidth(Css.px(350)),
                        /* [] */0
                      ]
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              maxWidth: "lg",
              disableBackdropClick: true,
              children: null
            }, React.createElement(CardContent.default, {
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading6 */5,
                            children: actionType ? I18N$BsConsole.show(undefined, "Modify SSO configuration") : I18N$BsConsole.show(undefined, "Create SSO configuration")
                          }), React.createElement(Row2$BsConsole.make, {
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(onClose, undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                disabled: issuer.trim() === "" || entryPoint.trim() === "" || showFeature("1.4.6", currentVersion) && Belt_Option.getWithDefault(callbackUrl, "").trim() === "" || cert.trim() === "",
                                onClick: (function (param) {
                                    var newTenant = Curry._1(formToTenant, state);
                                    var task = actionType ? FetchSaml$BsConsole.modifyTenantTask(serviceEndpoint, window.location.hostname, token, newTenant) : FetchSaml$BsConsole.createTenantTask(serviceEndpoint, window.location.hostname, token, newTenant);
                                    return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                                                  return FetchSaml$BsConsole.responseCb(onSuccess, onFailure, param);
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Save")
                              })))
                }), React.createElement(Divider.default, { }), error !== undefined ? React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.padding(Css.px(16)),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.redDark)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                              /* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redLight)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: I18N$BsConsole.dynamic(error)
                      })) : null, React.createElement(CardContent.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.overflow(/* auto */-1065951377),
                          /* :: */[
                            Css.selector("a:hover, a:visited", /* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                  /* :: */[
                                    Css.important(Css.textDecoration(/* underline */131142924)),
                                    /* [] */0
                                  ]
                                ]),
                            /* :: */[
                              Css.selector("a", /* :: */[
                                    Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  children: React.createElement(FormControl.default, {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "Entity ID",
                            title: "Entity ID (issuer)",
                            value: issuer,
                            label: I18N$BsConsole.show(undefined, "ID for the Service provider. By default, hosted Backtrace tenants will have a value of https://saml.backtrace.io for the Entity ID. This setting needs to be the same in your Identity provider's configuration."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetIssuer */Block.__(0, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "SSO URL"),
                            title: I18N$BsConsole.get(undefined, "SSO URL"),
                            value: entryPoint,
                            label: I18N$BsConsole.show(undefined, "URL pointing to Identity provider"),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetEntryPoint */Block.__(1, [maybeValue]));
                                }
                                
                              })
                          }), tmp, showFeature("1.5.3", currentVersion) ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Subtitle2 */7,
                                  children: I18N$BsConsole.show(undefined, "User provisioning")
                                }), React.createElement(Typography.default, {
                                  variant: "caption",
                                  children: I18N$BsConsole.show(undefined, "User provisioning, if enabled, allows the SAML SSO service to create a Backtrace user on the return of a successful assertion from the identity provider.")
                                }), React.createElement(FormControlLabel.default, {
                                  label: I18N$BsConsole.get(undefined, "Enable user provisioning"),
                                  control: React.createElement(Checkbox.default, {
                                        checked: userProvisioning,
                                        color: "primary",
                                        onChange: (function ($$event) {
                                            var value = $$event.target.checked;
                                            return Curry._1(dispatch, /* SetUserProvisioning */Block.__(8, [value]));
                                          })
                                      })
                                })) : null, React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "admin@my-organization.com",
                            title: I18N$BsConsole.get(undefined, "Admin contact"),
                            value: adminContact,
                            label: I18N$BsConsole.show(undefined, "(Optional) email contact for handling SSO related topics within the organization."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetAdminContact */Block.__(6, [maybeValue]));
                                }
                                
                              })
                          }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "-----BEGIN CERTIFICATE-----\n<certificate contents>\n-----END CERTIFICATE-----"),
                            title: I18N$BsConsole.get(undefined, "Public certificate"),
                            value: cert,
                            label: I18N$BsConsole.show(undefined, "(Required) Identity provider's public signing certificate used to validate the signatures of SAML Responses. PEM format."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetCert */Block.__(2, [maybeValue]));
                                }
                                
                              }),
                            rows: 5,
                            rowsMax: 20,
                            multiline: true
                          }), showFeature("1.7.1", currentVersion) ? React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                                  title: I18N$BsConsole.get(undefined, "SAML request private key")
                                }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                                  label: I18N$BsConsole.show(undefined, "(Optional) Identity provider's private key for signing SAML requests. PEM format.")
                                }), React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  children: null
                                }, React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                                      label: I18N$BsConsole.show(undefined, "Signature algorithm")
                                    }), React.createElement(Select.default, {
                                      value: /* `String */[
                                        -976970511,
                                        ApiSaml$BsConsole.Tenant.signatureAlgorithmToString(signatureAlgorithm)
                                      ],
                                      onChange: (function ($$event, param) {
                                          var value = $$event.target.value;
                                          if (typeof value === "number" || value[0] !== -976970511) {
                                            return ;
                                          } else {
                                            return Curry._1(dispatch, /* SetSignatureAlgorithm */Block.__(4, [ApiSaml$BsConsole.Tenant.signatureAlgorithmFromString(value[1])]));
                                          }
                                        }),
                                      renderValue: (function (value) {
                                          return React.createElement(BtTypography$BsConsole.make, {
                                                      variant: /* Caption */11,
                                                      className: Css.style(/* :: */[
                                                            Css.padding2(/* zero */-789508312, Css.rem(1)),
                                                            /* [] */0
                                                          ]),
                                                      children: I18N$BsConsole.showSkip(value[1])
                                                    });
                                        }),
                                      disableUnderline: true,
                                      classes: {
                                        root: Css.style(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA09)),
                                              /* :: */[
                                                Css.marginBottom(Css.rem(0.5)),
                                                /* :: */[
                                                  Css.marginLeft(Css.rem(0.5)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ])
                                      },
                                      children: null
                                    }, React.createElement(MenuItem.default, {
                                          value: /* `String */[
                                            -976970511,
                                            "sha1"
                                          ],
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Caption */11,
                                                children: I18N$BsConsole.show(undefined, "sha1")
                                              })
                                        }), React.createElement(MenuItem.default, {
                                          value: /* `String */[
                                            -976970511,
                                            "sha256"
                                          ],
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Caption */11,
                                                children: I18N$BsConsole.show(undefined, "sha256")
                                              })
                                        }), React.createElement(MenuItem.default, {
                                          value: /* `String */[
                                            -976970511,
                                            "sha512"
                                          ],
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Caption */11,
                                                children: I18N$BsConsole.show(undefined, "sha512")
                                              })
                                        }))), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                  multiline: true,
                                  rows: 5,
                                  rowsMax: 20,
                                  placeholder: I18N$BsConsole.get(undefined, "-----BEGIN PRIVATE KEY-----\n<private key contents here delimited at 64 characters per row>\n-----END PRIVATE KEY-----"),
                                  value: privateCert,
                                  onChange: (function ($$event) {
                                      var maybeValue = Curry._1(getInputVal, $$event);
                                      if (!(maybeValue == null)) {
                                        return Curry._1(dispatch, /* SetPrivateCert */Block.__(3, [maybeValue]));
                                      }
                                      
                                    })
                                })) : null, React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "Decryption private key"),
                            title: I18N$BsConsole.get(undefined, "Decryption private key"),
                            value: decryptionPvk,
                            label: I18N$BsConsole.show(undefined, "(Optional) Private key that will be used to attempt to decrypt any encrypted assertions from identity provider. PEM format."),
                            onChange: (function ($$event) {
                                var maybeValue = Curry._1(getInputVal, $$event);
                                if (!(maybeValue == null)) {
                                  return Curry._1(dispatch, /* SetDecryptionPvk */Block.__(5, [maybeValue]));
                                }
                                
                              }),
                            rows: 5,
                            rowsMax: 20,
                            multiline: true
                          }))
                }));
}

var SsoConfigForm = {
  make: SettingsSamlConfig$FormDialog$SsoConfigForm
};

function SettingsSamlConfig$FormDialog(Props) {
  var serviceEndpoint = Props.serviceEndpoint;
  var token = Props.token;
  var currentVersion = Props.currentVersion;
  var tenant = Props.tenant;
  var strategy = Props.strategy;
  var onClose = Props.onClose;
  var update = Props.update;
  var actionType = Props.actionType;
  var useNewAuthService = Props.useNewAuthService;
  var match = React.useReducer(reducer, getInitialState(tenant, strategy));
  var state = match[0];
  var error = state.error;
  var userProvisioning = state.userProvisioning;
  var callbackUrl = state.callbackUrl;
  var signatureAlgorithm = state.signatureAlgorithm;
  var privateCert = state.privateCert;
  var adminContact = state.adminContact;
  var decryptionPvk = state.decryptionPvk;
  var cert = state.cert;
  var entryPoint = state.entryPoint;
  var issuer = state.issuer;
  var dispatch = match[1];
  var getInputVal = function (e) {
    return e.target.value;
  };
  var formToStrategy = function (state) {
    var options_issuer = state.issuer;
    var options_entryPoint = state.entryPoint;
    var options_callbackUrl = state.callbackUrl;
    var options_cert = state.cert.trim() === "" ? undefined : state.cert;
    var options_adminContact = state.adminContact.trim() === "" ? undefined : state.adminContact;
    var options_decryptionKey = state.decryptionPvk.trim() === "" ? undefined : state.decryptionPvk;
    var options_privateKey = state.privateCert.trim() === "" ? undefined : state.privateCert;
    var options_signatureAlgorithm = state.privateCert.trim() === "" ? undefined : state.signatureAlgorithm;
    var options = {
      issuer: options_issuer,
      entryPoint: options_entryPoint,
      callbackUrl: options_callbackUrl,
      cert: options_cert,
      adminContact: options_adminContact,
      decryptionKey: options_decryptionKey,
      privateKey: options_privateKey,
      signatureAlgorithm: options_signatureAlgorithm
    };
    return {
            id: state.id,
            name: Belt_Option.getWithDefault(state.name, ""),
            hostname: window.location.hostname,
            strategy: Belt_Option.getWithDefault(state.strategy, "saml"),
            options: options,
            isDefault: Belt_Option.getWithDefault(state.isDefault, true),
            userProvisioning: state.userProvisioning
          };
  };
  var formToTenant = function (state) {
    return {
            hostname: window.location.hostname,
            issuer: state.issuer,
            entryPoint: state.entryPoint,
            cert: state.cert.trim() === "" ? undefined : state.cert,
            decryptionPvk: state.decryptionPvk.trim() === "" ? undefined : state.decryptionPvk,
            adminContact: state.adminContact.trim() === "" ? undefined : state.adminContact,
            callbackUrl: state.callbackUrl,
            privateCert: state.privateCert.trim() === "" ? undefined : state.privateCert,
            signatureAlgorithm: state.privateCert.trim() === "" ? undefined : state.signatureAlgorithm,
            settings: {
              userProvisioning: state.userProvisioning
            }
          };
  };
  var onSuccess = function (param) {
    Curry._1(update, undefined);
    Curry._1(onClose, undefined);
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated configuration"));
  };
  var onFailure = function (msg) {
    Curry._1(dispatch, /* SetError */Block.__(9, [msg]));
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failure to update configuration"));
  };
  if (useNewAuthService) {
    return React.createElement(SettingsSamlConfig$FormDialog$AuthConfigForm, {
                token: token,
                state: state,
                actionType: actionType,
                issuer: issuer,
                entryPoint: entryPoint,
                cert: cert,
                onClose: onClose,
                serviceEndpoint: serviceEndpoint,
                formToStrategy: formToStrategy,
                onSuccess: onSuccess,
                onFailure: onFailure,
                error: error,
                getInputVal: getInputVal,
                dispatch: dispatch,
                callbackUrl: callbackUrl,
                userProvisioning: userProvisioning,
                adminContact: adminContact,
                signatureAlgorithm: signatureAlgorithm,
                privateCert: privateCert,
                decryptionPvk: decryptionPvk,
                advancedOptionsOpen: state.advancedOptionsOpen,
                isCertRequired: actionType ? (
                    strategy !== undefined ? strategy.options.cert !== undefined : false
                  ) : true
              });
  } else {
    return React.createElement(SettingsSamlConfig$FormDialog$SsoConfigForm, {
                token: token,
                state: state,
                actionType: actionType,
                issuer: issuer,
                entryPoint: entryPoint,
                cert: cert,
                onClose: onClose,
                serviceEndpoint: serviceEndpoint,
                onSuccess: onSuccess,
                onFailure: onFailure,
                error: error,
                getInputVal: getInputVal,
                dispatch: dispatch,
                callbackUrl: callbackUrl,
                userProvisioning: userProvisioning,
                adminContact: adminContact,
                signatureAlgorithm: signatureAlgorithm,
                privateCert: privateCert,
                decryptionPvk: decryptionPvk,
                currentVersion: Belt_Option.getWithDefault(currentVersion, "0.0.0"),
                formToTenant: formToTenant
              });
  }
}

var FormDialog = {
  getInitialState: getInitialState,
  reducer: reducer,
  AuthConfigForm: AuthConfigForm,
  SsoConfigForm: SsoConfigForm,
  make: SettingsSamlConfig$FormDialog
};

function SettingsSamlConfig$SamlConfigAuthService$AuthService(Props) {
  var token = Props.token;
  var config = Props.config;
  var dialogState = Props.dialogState;
  var maybeStrategy = Props.maybeStrategy;
  var serviceEndpoint = Props.serviceEndpoint;
  var privateCertExpanded = Props.privateCertExpanded;
  var decryptionPvkExpanded = Props.decryptionPvkExpanded;
  var certExpanded = Props.certExpanded;
  var setDecryptionPvkExpanded = Props.setDecryptionPvkExpanded;
  var setPrivateCertExpanded = Props.setPrivateCertExpanded;
  var setCertExpanded = Props.setCertExpanded;
  var setDialogState = Props.setDialogState;
  var updateStrategyRemote = Props.updateStrategyRemote;
  var tmp;
  if (maybeStrategy !== undefined) {
    var c = maybeStrategy.options.privateKey;
    var c$1 = maybeStrategy.options.cert;
    var c$2 = maybeStrategy.options.decryptionKey;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "SSO success callback URL (ACS URL)"),
              value: maybeStrategy.options.callbackUrl
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Identity provider target URL"),
              value: maybeStrategy.options.entryPoint
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Entity ID (issuer)"),
              value: maybeStrategy.options.issuer
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Admin contact"),
              value: maybeStrategy.options.adminContact
            }), c !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Private key for signing SAML request")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setPrivateCertExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(privateCertExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: privateCertExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c)
                            }))
                  }), privateCertExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, c$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Identity Provider's public signing certificate")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setCertExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(certExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: certExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c$1)
                            }))
                  }), certExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, c$2 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Encrypted assertion key")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setDecryptionPvkExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(decryptionPvkExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: decryptionPvkExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c$2)
                            }))
                  }), decryptionPvkExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, React.createElement(Row2$BsConsole.make, {
              justifyContent: /* flexEnd */924268066,
              children: React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    disabled: !hasAccess(undefined, config, true, undefined),
                    onClick: (function (param) {
                        return Curry._1(setDialogState, (function (param) {
                                      return /* Modify */[/* Strategy */Block.__(1, [ApiStrategies$BsConsole.$$Response.responseToStrategy(maybeStrategy)])];
                                    }));
                      }),
                    children: I18N$BsConsole.show(undefined, "Edit")
                  })
            }));
  } else {
    tmp = React.createElement(Col2$BsConsole.make, {
          alignItems: /* center */98248149,
          className: Css.style(/* :: */[
                Css.margin2(Css.rem(2.0), /* zero */-789508312),
                /* [] */0
              ]),
          children: null
        }, React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1.5)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: hasAccess(undefined, config, true, undefined) ? I18N$BsConsole.show(undefined, "No SSO configuration exists for this universe") : I18N$BsConsole.show(undefined, "No SSO configuration exists for this universe. Please contact your Backtrace administrator to configure SSO.")
                })), hasAccess(undefined, config, true, undefined) ? React.createElement("div", undefined, React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    onClick: (function (param) {
                        return Curry._1(setDialogState, (function (param) {
                                      return /* Create */0;
                                    }));
                      }),
                    children: I18N$BsConsole.show(undefined, "Add configuration")
                  })) : null);
  }
  var tmp$1;
  if (dialogState !== undefined) {
    var serviceType = dialogState ? dialogState[0] : undefined;
    var actionType = dialogState ? /* Modify */1 : /* Create */0;
    var tmp$2;
    tmp$2 = serviceType !== undefined && serviceType.tag ? serviceType[0] : undefined;
    tmp$1 = React.createElement(SettingsSamlConfig$FormDialog, {
          serviceEndpoint: serviceEndpoint,
          token: token,
          currentVersion: undefined,
          tenant: undefined,
          strategy: tmp$2,
          onClose: (function (param) {
              return Curry._1(setDialogState, (function (param) {
                            
                          }));
            }),
          update: updateStrategyRemote,
          actionType: actionType,
          useNewAuthService: true
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, Belt_Option.getWithDefault(Belt_Option.map(maybeStrategy, (function (strategy) {
                        var idpHost = new URL(strategy.options.entryPoint).host;
                        if (Belt_Option.isNone(strategy.options.cert)) {
                          return React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      className: Css.style(/* :: */[
                                            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redDark)),
                                            /* :: */[
                                              Css.borderRadius(Css.px(4)),
                                              /* :: */[
                                                Css.padding2(Css.rem(0.5), Css.rem(1)),
                                                /* :: */[
                                                  Css.marginBottom(Css.rem(1)),
                                                  /* :: */[
                                                    Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]),
                                      children: null
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, React.createElement(Warning.default, {
                                              color: "inherit"
                                            })), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          children: null
                                        }, React.createElement("span", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                    /* :: */[
                                                      Css.fontWeight(/* `num */[
                                                            5496390,
                                                            500
                                                          ]),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.show(undefined, "Warning: ")), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String_literal */Block.__(11, [
                                                      "Your service configuration is missing your identity provider's (",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              ") public signing certificate. Please update your service configuration as soon as possible to avoid any service disruptions in the future.",
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ]),
                                                  "Your service configuration is missing your identity provider's (%s) public signing certificate. Please update your service configuration as soon as possible to avoid any service disruptions in the future."
                                                ]), idpHost)));
                        } else {
                          return null;
                        }
                      })), null), tmp, tmp$1);
}

var AuthService = {
  make: SettingsSamlConfig$SamlConfigAuthService$AuthService
};

function SettingsSamlConfig$SamlConfigAuthService(Props) {
  var serviceEndpoint = Props.serviceEndpoint;
  var config = Props.config;
  var token = Props.token;
  var match = React.useState((function () {
          return false;
        }));
  var match$1 = React.useState((function () {
          return false;
        }));
  var match$2 = React.useState((function () {
          return false;
        }));
  var match$3 = React.useState((function () {
          
        }));
  var match$4 = ApiStrategies$BsConsole.QueryStrategies.queryStrategiesTask(serviceEndpoint, token);
  var strategyRemote = match$4[0];
  if (typeof strategyRemote !== "number") {
    if (strategyRemote.tag) {
      return React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.dynamic(strategyRemote[0])
                });
    } else {
      return React.createElement(SettingsSamlConfig$SamlConfigAuthService$AuthService, {
                  token: token,
                  config: config,
                  dialogState: match$3[0],
                  maybeStrategy: strategyRemote[0],
                  serviceEndpoint: serviceEndpoint,
                  privateCertExpanded: match$1[0],
                  decryptionPvkExpanded: match$2[0],
                  certExpanded: match[0],
                  setDecryptionPvkExpanded: match$2[1],
                  setPrivateCertExpanded: match$1[1],
                  setCertExpanded: match[1],
                  setDialogState: match$3[1],
                  updateStrategyRemote: match$4[1]
                });
    }
  }
  strategyRemote === /* NotAsked */0;
  return React.createElement(Row2$BsConsole.make, {
              justifyContent: /* center */98248149,
              children: React.createElement(CircularProgress.default, {
                    size: 75,
                    color: "primary"
                  })
            });
}

var SamlConfigAuthService = {
  AuthService: AuthService,
  make: SettingsSamlConfig$SamlConfigAuthService
};

function SettingsSamlConfig$SamlConfigSSOservice$SsoService(Props) {
  var token = Props.token;
  var config = Props.config;
  var dialogState = Props.dialogState;
  var samlConfig = Props.samlConfig;
  var certExpanded = Props.certExpanded;
  var serviceEndpoint = Props.serviceEndpoint;
  var privateCertExpanded = Props.privateCertExpanded;
  var updateTenantRemote = Props.updateTenantRemote;
  var decryptionPvkExpanded = Props.decryptionPvkExpanded;
  var setCertExpanded = Props.setCertExpanded;
  var setPrivateCertExpanded = Props.setPrivateCertExpanded;
  var setDecryptionPvkExpanded = Props.setDecryptionPvkExpanded;
  var handleEdit = Props.handleEdit;
  var handleClose = Props.handleClose;
  var handleAddConfiguration = Props.handleAddConfiguration;
  var tenant = samlConfig.tenant;
  var tmp;
  if (tenant !== undefined) {
    var c = tenant.privateCert;
    var c$1 = tenant.cert;
    var c$2 = tenant.decryptionPvk;
    var param = samlConfig.metadata.version;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "SSO success callback URL (ACS URL)"),
              value: tenant.callbackUrl
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Identity provider target URL"),
              value: tenant.entryPoint
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Entity ID (issuer)"),
              value: tenant.issuer
            }), React.createElement(SettingsSamlConfig$ConfigItem, {
              label: I18N$BsConsole.get(undefined, "Admin contact"),
              value: tenant.adminContact
            }), c !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Private key for signing SAML request")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setPrivateCertExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(privateCertExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: privateCertExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c)
                            }))
                  }), privateCertExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, c$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Identity Provider's public signing certificate")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setCertExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(certExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: certExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c$1)
                            }))
                  }), certExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, c$2 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                              /* :: */[
                                Css.marginRight(Css.rem(1.0)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Encrypted assertion key")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        onClick: (function (param) {
                            return Curry._1(setDecryptionPvkExpanded, (function (e) {
                                          return !e;
                                        }));
                          }),
                        children: I18N$BsConsole.showSkip(decryptionPvkExpanded ? I18N$BsConsole.get(undefined, "Collapse") : I18N$BsConsole.get(undefined, "Expand"))
                      })), React.createElement(Collapse.default, {
                    in: decryptionPvkExpanded,
                    children: React.createElement("div", {
                          className: configItem
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.dynamic(c$2)
                            }))
                  }), decryptionPvkExpanded ? null : React.createElement("div", {
                      className: configItem
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "...")
                        }))) : null, React.createElement(Row2$BsConsole.make, {
              justifyContent: /* flexEnd */924268066,
              children: null
            }, showFeature("1.7.1", samlConfig.metadata.version) ? React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginRight(Css.rem(1)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (param) {
                        window.open(serviceEndpoint + ("/saml/login-test/" + window.location.hostname), "_blank", "toolbar=0,location=0,menubar=0");
                        
                      }),
                    children: I18N$BsConsole.show(undefined, "Test configuration")
                  }) : null, React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: !hasAccess(param, config, undefined, undefined),
                  onClick: (function (param) {
                      return Curry._1(handleEdit, tenant);
                    }),
                  children: I18N$BsConsole.show(undefined, "Edit")
                })));
  } else {
    var param$1 = samlConfig.metadata.version;
    var param$2 = samlConfig.metadata.version;
    tmp = React.createElement(Col2$BsConsole.make, {
          alignItems: /* center */98248149,
          className: Css.style(/* :: */[
                Css.margin2(Css.rem(2.0), /* zero */-789508312),
                /* [] */0
              ]),
          children: null
        }, React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1.5)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: hasAccess(param$1, config, undefined, undefined) ? I18N$BsConsole.show(undefined, "No SSO configuration exists for this universe") : I18N$BsConsole.show(undefined, "No SSO configuration exists for this universe. Please contact your Backtrace administrator to configure SSO.")
                })), hasAccess(param$2, config, undefined, undefined) ? React.createElement("div", undefined, React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    onClick: (function (param) {
                        return Curry._1(handleAddConfiguration, undefined);
                      }),
                    children: I18N$BsConsole.show(undefined, "Add configuration")
                  })) : null);
  }
  var tmp$1;
  if (dialogState !== undefined) {
    var serviceType = dialogState ? dialogState[0] : undefined;
    var actionType = dialogState ? /* Modify */1 : /* Create */0;
    var tmp$2;
    tmp$2 = serviceType !== undefined && !serviceType.tag ? serviceType[0] : undefined;
    tmp$1 = React.createElement(SettingsSamlConfig$FormDialog, {
          serviceEndpoint: serviceEndpoint,
          token: token,
          currentVersion: samlConfig.metadata.version,
          tenant: tmp$2,
          strategy: undefined,
          onClose: (function (param) {
              return Curry._1(handleClose, undefined);
            }),
          update: updateTenantRemote,
          actionType: actionType,
          useNewAuthService: false
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, Belt_Option.getWithDefault(Belt_Option.map(samlConfig.tenant, (function (tenant) {
                        var idpHost = new URL(tenant.entryPoint).host;
                        if (Belt_Option.isNone(tenant.cert)) {
                          return React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      className: Css.style(/* :: */[
                                            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.redDark)),
                                            /* :: */[
                                              Css.borderRadius(Css.px(4)),
                                              /* :: */[
                                                Css.padding2(Css.rem(0.5), Css.rem(1)),
                                                /* :: */[
                                                  Css.marginBottom(Css.rem(1)),
                                                  /* :: */[
                                                    Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]),
                                      children: null
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, React.createElement(Warning.default, {
                                              color: "inherit"
                                            })), React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          children: null
                                        }, React.createElement("span", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                    /* :: */[
                                                      Css.fontWeight(/* `num */[
                                                            5496390,
                                                            500
                                                          ]),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.show(undefined, "Warning: ")), Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String_literal */Block.__(11, [
                                                      "Your service configuration is missing your identity provider's (",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              ") public signing certificate. Please update your service configuration as soon as possible to avoid any service disruptions in the future.",
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ]),
                                                  "Your service configuration is missing your identity provider's (%s) public signing certificate. Please update your service configuration as soon as possible to avoid any service disruptions in the future."
                                                ]), idpHost)));
                        } else {
                          return null;
                        }
                      })), null), React.createElement(SettingsSamlConfig$ConfigItem, {
                  label: I18N$BsConsole.get(undefined, "backtrace-saml version"),
                  value: samlConfig.metadata.version
                }), tmp, tmp$1);
}

var SsoService = {
  make: SettingsSamlConfig$SamlConfigSSOservice$SsoService
};

function SettingsSamlConfig$SamlConfigSSOservice(Props) {
  var serviceEndpoint = Props.serviceEndpoint;
  var config = Props.config;
  var token = Props.token;
  var match = React.useState((function () {
          return false;
        }));
  var match$1 = React.useState((function () {
          return false;
        }));
  var match$2 = React.useState((function () {
          return false;
        }));
  var match$3 = React.useState((function () {
          
        }));
  var setDialogState = match$3[1];
  var match$4 = FetchSaml$BsConsole.useConfig(serviceEndpoint, token);
  var configRemote = match$4[0];
  var match$5 = FetchSaml$BsConsole.useGetTenant(serviceEndpoint, token, window.location.hostname);
  var tenantRemote = match$5[0];
  var msg;
  var exit = 0;
  var exit$1 = 0;
  if (typeof tenantRemote === "number") {
    exit$1 = 3;
  } else {
    if (!tenantRemote.tag) {
      return React.createElement(SettingsSamlConfig$SamlConfigSSOservice$SsoService, {
                  token: token,
                  config: config,
                  dialogState: match$3[0],
                  samlConfig: tenantRemote[0],
                  certExpanded: match[0],
                  serviceEndpoint: serviceEndpoint,
                  privateCertExpanded: match$1[0],
                  updateTenantRemote: match$5[1],
                  decryptionPvkExpanded: match$2[0],
                  setCertExpanded: match[1],
                  setPrivateCertExpanded: match$1[1],
                  setDecryptionPvkExpanded: match$2[1],
                  handleEdit: (function (tenant) {
                      return Curry._1(setDialogState, (function (param) {
                                    return /* Modify */[/* Tenant */Block.__(0, [tenant])];
                                  }));
                    }),
                  handleClose: (function (param) {
                      return Curry._1(setDialogState, (function (param) {
                                    
                                  }));
                    }),
                  handleAddConfiguration: (function (param) {
                      return Curry._1(setDialogState, (function (param) {
                                    return /* Create */0;
                                  }));
                    })
                });
    }
    exit$1 = 3;
  }
  if (exit$1 === 3) {
    if (typeof configRemote === "number" || configRemote.tag !== /* Failure */1) {
      exit = 2;
    } else {
      msg = configRemote[0];
    }
  }
  if (exit === 2) {
    if (typeof tenantRemote === "number") {
      return React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* center */98248149,
                  children: React.createElement(CircularProgress.default, {
                        size: 75,
                        color: "primary"
                      })
                });
    }
    msg = tenantRemote[0];
  }
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              children: I18N$BsConsole.dynamic(msg)
            });
}

var SamlConfigSSOservice = {
  SsoService: SsoService,
  make: SettingsSamlConfig$SamlConfigSSOservice
};

function SettingsSamlConfig$SelfServiceView(Props) {
  var limits = Props.limits;
  var email = limits.metadata.sales;
  return React.createElement(CardContent.default, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  children: I18N$BsConsole.show(undefined, "Single sign-on Configuration")
                }), React.createElement("br", undefined), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: null
                }, I18N$BsConsole.show(undefined, "This instance of Backtrace is configured on the Self Service tier. SAML configuration with your Identify Provider is provided on the Enterprise tier. If you would like to configure Backtrace's SAML service to use your Identity Provider, reach out to our "), React.createElement("a", {
                      className: link + " link",
                      href: "mailto:" + (
                        email !== undefined ? email : Email$BsConsole.sales
                      )
                    }, I18N$BsConsole.show(undefined, "account team")), I18N$BsConsole.show(undefined, " about upgrading your instance.")));
}

var SelfServiceView = {
  make: SettingsSamlConfig$SelfServiceView
};

function SettingsSamlConfig(Props) {
  var token = Props.token;
  var config = Props.config;
  var authServiceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "auth");
  var ssoServiceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "sso");
  var match = Gate$BsConsole.isWfBeta(undefined);
  var tmp;
  var exit = 0;
  if (authServiceEndpoint !== undefined && match) {
    tmp = React.createElement(SettingsSamlConfig$SamlConfigAuthService, {
          serviceEndpoint: authServiceEndpoint,
          config: config,
          token: token
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = ssoServiceEndpoint !== undefined ? React.createElement(SettingsSamlConfig$SamlConfigSSOservice, {
            serviceEndpoint: ssoServiceEndpoint,
            config: config,
            token: token
          }) : React.createElement(SettingsSamlConfig$SamlSupport, { });
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: React.createElement(BtSettings$BsConsole.Paper.make, {
                    className: Css.style(/* :: */[
                          Css.padding(Css.rem(1.0)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading5 */4,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1.0)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Single sign-on configuration")
                      }), tmp)
            });
}

function make(token, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SettingsSamlConfig, {
              token: token,
              config: config
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = SettingsSamlConfig;

exports.showFeature = showFeature;
exports.hasAccess = hasAccess;
exports.configItem = configItem;
exports.link = link;
exports.SamlSupport = SamlSupport;
exports.ConfigItem = ConfigItem;
exports.FormDialog = FormDialog;
exports.SamlConfigAuthService = SamlConfigAuthService;
exports.SamlConfigSSOservice = SamlConfigSSOservice;
exports.SelfServiceView = SelfServiceView;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* configItem Not a pure module */
