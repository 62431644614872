// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("./Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");

var component = RR$BsConsole.reducerComponent("AccentCard-BsConsole");

function cardStyle(w) {
  return Css.style(/* :: */[
              Css.borderRadius(Css.px(5)),
              /* :: */[
                Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.accent)),
                /* :: */[
                  Css.width(Css.px(w)),
                  /* :: */[
                    Css.height(Css.px(38)),
                    /* :: */[
                      Css.minHeight(Css.px(38)),
                      /* :: */[
                        Css.marginBottom(Css.px(15)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.accent)),
                          /* :: */[
                            Css.cursor(/* pointer */-786317123),
                            /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function innerStyle(icon) {
  if (icon) {
    return Css.style(/* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.flexDirection(/* column */-963948842),
                  /* :: */[
                    Css.justifyContent(/* center */98248149),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.margin(Css.px(1)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.flexDirection(/* column */-963948842),
                  /* :: */[
                    Css.justifyContent(/* center */98248149),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  }
}

function hoverStyle(hovered) {
  if (hovered) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.accentLight)),
                /* [] */0
              ]);
  } else {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.transparent),
                /* [] */0
              ]);
  }
}

function make(titleOpt, icon, action, isActiveOpt, elemWidthOpt, _children) {
  var title = titleOpt !== undefined ? titleOpt : "";
  var isActive = isActiveOpt !== undefined ? isActiveOpt : false;
  var elemWidth = elemWidthOpt !== undefined ? elemWidthOpt : 200;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, Css.merge(/* :: */[
                                  cardStyle(elemWidth),
                                  /* :: */[
                                    hoverStyle(param.state.hovered || isActive),
                                    /* [] */0
                                  ]
                                ]), undefined, undefined, 0, [React.createElement("div", {
                                    className: icon !== undefined ? innerStyle(true) : innerStyle(false),
                                    onClick: (function (_event) {
                                        if (action !== undefined) {
                                          return Curry._1(action, undefined);
                                        }
                                        
                                      }),
                                    onMouseEnter: (function (param) {
                                        return Curry._1(send, /* MouseEnter */0);
                                      }),
                                    onMouseLeave: (function (param) {
                                        return Curry._1(send, /* MouseLeave */1);
                                      })
                                  }, icon !== undefined ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                  Css.justifyContent(/* center */98248149),
                                                  /* [] */0
                                                ]), [
                                              React.createElement("div", {
                                                    className: "smaller-icon " + Css.style(/* :: */[
                                                          Css.marginRight(Css.px(5)),
                                                          /* [] */0
                                                        ])
                                                  }, Caml_option.valFromOption(icon)),
                                              React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.marginTop(Css.px(1)),
                                                          /* [] */0
                                                        ])
                                                  }, I18N$BsConsole.showSkip(title))
                                            ])) : ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                  Css.justifyContent(/* center */98248149),
                                                  /* [] */0
                                                ]), [React.createElement("div", undefined, I18N$BsConsole.showSkip(title))])))]));
            }),
          initialState: (function (param) {
              return {
                      hovered: false,
                      editEnabled: false,
                      text: title
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            hovered: false,
                            editEnabled: state.editEnabled,
                            text: state.text
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            hovered: true,
                            editEnabled: state.editEnabled,
                            text: state.text
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.cardStyle = cardStyle;
exports.innerStyle = innerStyle;
exports.hoverStyle = hoverStyle;
exports.make = make;
/* component Not a pure module */
