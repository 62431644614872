// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Route$BsConsole = require("./route.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

function fromJson(json) {
  return {
          fullUrl: Json_decode.field("fullUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function getUrl(serviceUrl, hashID) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceUrl + "/hash"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "id",
                        hashID
                      ],
                      /* [] */0
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                return /* Ok */Block.__(0, [fromJson(param.json)]);
              }), undefined, undefined);
}

var component = RR$BsConsole.reducerComponent("ShareRedirect-BsConsole");

function make(serviceUrl, redirect, handleChangeUrl, token, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */1);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                  Css.height(Css.vh(100)),
                                  /* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey75)),
                                    /* [] */0
                                  ]
                                ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, []))]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number" && action !== 0) {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (_self) {
                              var arg = getUrl(serviceUrl, redirect);
                              return Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), undefined, (function (err) {
                                            if (err.tag) {
                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_UseTinyUrlFailure */21]));
                                              var __x = Backtrace$BsConsole.Attributes.addString("hashId", redirect, Backtrace$BsConsole.Attributes.addString("serviceUrl", serviceUrl, Backtrace$BsConsole.Attributes.addString("src_module", "ShareRedirect", Backtrace$BsConsole.Attributes.make(undefined))));
                                              Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                    288368849,
                                                    "ShareRedirect Error: " + (String(err[0]) + "")
                                                  ]);
                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: redirect failed"));
                                              return Curry._1(handleChangeUrl, /* Shell */3);
                                            }
                                            var fullUrl = err[0].fullUrl;
                                            if (fullUrl !== undefined) {
                                              var pathAndSearch = Belt_Array.get(fullUrl.split(window.location.origin + "/"), 1);
                                              if (pathAndSearch !== undefined) {
                                                var path = Belt_List.fromArray(Belt_Option.getWithDefault(Belt_Array.get(pathAndSearch.split("?"), 0), "").split("/"));
                                                var search = Belt_Option.getWithDefault(Belt_Array.get(pathAndSearch.split("?"), 1), "");
                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_UseTinyUrlSuccess */20]));
                                                return Curry._1(handleChangeUrl, Route$BsConsole.routeForPathAndSearch(undefined, path, search));
                                              }
                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_UseTinyUrlFailure */21]));
                                              var __x$1 = Backtrace$BsConsole.Attributes.addString("hashId", redirect, Backtrace$BsConsole.Attributes.addString("serviceUrl", serviceUrl, Backtrace$BsConsole.Attributes.addString("src_module", "ShareRedirect", Backtrace$BsConsole.Attributes.make(undefined))));
                                              Backtrace$BsConsole.Client.send(__x$1, /* `string */[
                                                    288368849,
                                                    "ShareRedirect Error: no pathAndSearch  - " + (String(fullUrl) + "")
                                                  ]);
                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: redirect failed"));
                                              return Curry._1(handleChangeUrl, /* Shell */3);
                                            }
                                            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_UseTinyUrlFailure */21]));
                                            var __x$2 = Backtrace$BsConsole.Attributes.addString("hashId", redirect, Backtrace$BsConsole.Attributes.addString("serviceUrl", serviceUrl, Backtrace$BsConsole.Attributes.addString("src_module", "ShareRedirect", Backtrace$BsConsole.Attributes.make(undefined))));
                                            Backtrace$BsConsole.Client.send(__x$2, /* `string */[
                                                  288368849,
                                                  "ShareRedirect Error: no fullUrl -  " + (String(fullUrl) + "")
                                                ]);
                                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error: redirect failed"));
                                            return Curry._1(handleChangeUrl, /* Shell */3);
                                          }));
                            })
                        ]);
              } else {
                return /* NoUpdate */0;
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.fromJson = fromJson;
exports.getUrl = getUrl;
exports.component = component;
exports.make = make;
/* component Not a pure module */
