// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Card = require("@material-ui/core/Card");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var MoreVert = require("@material-ui/icons/MoreVert");
var CardContent = require("@material-ui/core/CardContent");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");

function BtCard$Status(Props) {
  var errorOpt = Props.error;
  var error = errorOpt !== undefined ? errorOpt : false;
  if (error) {
    return React.createElement(ErrorOutline.default, {
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.redDark)),
                      /* [] */0
                    ])
              });
  } else {
    return React.createElement(CheckCircle.default, {
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.green)),
                      /* [] */0
                    ])
              });
  }
}

var Status = {
  make: BtCard$Status
};

function BtCard(Props) {
  var cardWidthOpt = Props.cardWidth;
  var cardInnerHeightOpt = Props.cardInnerHeight;
  var cardMarginRightOpt = Props.cardMarginRight;
  var cardMarginBottomOpt = Props.cardMarginBottom;
  var title = Props.title;
  var content = Props.content;
  var ctaOpt = Props.cta;
  var status = Props.status;
  var more = Props.more;
  var expand = Props.expand;
  var cardWidth = cardWidthOpt !== undefined ? cardWidthOpt : 325;
  var cardInnerHeight = cardInnerHeightOpt !== undefined ? Caml_option.valFromOption(cardInnerHeightOpt) : 75;
  var cardMarginRight = cardMarginRightOpt !== undefined ? cardMarginRightOpt : 25;
  var cardMarginBottom = cardMarginBottomOpt !== undefined ? cardMarginBottomOpt : 25;
  var cta = ctaOpt !== undefined ? Caml_option.valFromOption(ctaOpt) : undefined;
  var match = React.useState((function () {
          return false;
        }));
  var setMoreMenu = match[1];
  var moreMenu = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setExpanded = match$2[1];
  var expanded = match$2[0];
  var innerHeight = cardInnerHeight !== undefined ? Css.height(Css.px(cardInnerHeight + 24 | 0)) : Css.height(Css.pct(100));
  return React.createElement(Card.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.px(cardWidth)),
                      /* :: */[
                        Css.marginRight(Css.px(cardMarginRight)),
                        /* :: */[
                          Css.marginBottom(Css.px(cardMarginBottom)),
                          /* [] */0
                        ]
                      ]
                    ])
              },
              children: null
            }, React.createElement(CardContent.default, {
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.px(-5)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableTitle */13,
                          children: I18N$BsConsole.showSkip(title)
                        }), React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          justifyContent: /* spaceBetween */516682146,
                          children: null
                        }, status !== undefined ? Caml_option.valFromOption(status) : null, more !== undefined ? React.createElement("div", {
                                ref: (function (el) {
                                    return Curry._1(setAnchorEl, (function (param) {
                                                  if (el == null) {
                                                    return ;
                                                  } else {
                                                    return Caml_option.some(el);
                                                  }
                                                }));
                                  }),
                                className: "smaller-icon"
                              }, React.createElement(Mui$BsConsole.IconButton.make, {
                                    disableRipple: true,
                                    ariaLabel: "Toggle menu",
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.padding(Css.px(5)),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (param) {
                                        return Curry._1(setMoreMenu, (function (param) {
                                                      return !moreMenu;
                                                    }));
                                      }),
                                    children: React.createElement(MoreVert.default, { })
                                  }), React.createElement(Popover.default, {
                                    anchorEl: match$1[0],
                                    open: moreMenu,
                                    onClose: (function (param) {
                                        return Curry._1(setMoreMenu, (function (param) {
                                                      return false;
                                                    }));
                                      }),
                                    children: React.createElement(MenuList.default, {
                                          children: Belt_List.toArray(Belt_List.map(more, (function (param) {
                                                      var actionHandler = param[1];
                                                      return React.createElement(MenuItem.default, {
                                                                  onClick: (function (param) {
                                                                      Curry._1(setMoreMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                                      return Curry._1(actionHandler, undefined);
                                                                    }),
                                                                  children: I18N$BsConsole.showSkip(param[0])
                                                                });
                                                    })))
                                        })
                                  })) : null)), React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.px(8)),
                            /* :: */[
                              Css.marginBottom(Css.px(8)),
                              /* [] */0
                            ]
                          ])
                    }), React.createElement(Col2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            innerHeight,
                            /* [] */0
                          ]),
                      children: null
                    }, content !== undefined ? React.createElement("div", undefined, Caml_option.valFromOption(content)) : React.createElement("div", undefined, null), cta !== undefined ? React.createElement(Button.default, {
                            variant: "text",
                            color: "primary",
                            onClick: cta[1],
                            children: I18N$BsConsole.showSkip(cta[0])
                          }) : React.createElement("div", undefined, null), expand !== undefined ? React.createElement("div", undefined, React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                onClick: (function (param) {
                                    if (expanded) {
                                      return Curry._1(setExpanded, (function (param) {
                                                    return false;
                                                  }));
                                    } else {
                                      return Curry._1(setExpanded, (function (param) {
                                                    return true;
                                                  }));
                                    }
                                  }),
                                children: expanded ? I18N$BsConsole.showSkip(expand[1]) : I18N$BsConsole.showSkip(expand[0])
                              })) : React.createElement("div", undefined, null))), React.createElement(Collapse.default, {
                  in: expanded,
                  children: expand !== undefined ? React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.paddingLeft(Css.px(24)),
                                /* :: */[
                                  Css.paddingRight(Css.px(24)),
                                  /* :: */[
                                    Css.paddingBottom(Css.px(24)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        }, expand[2]) : React.createElement("div", undefined, null),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ])
                }));
}

var make = BtCard;

exports.Status = Status;
exports.make = make;
/* Css Not a pure module */
