// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var I18N$BsConsole = require("../../../I18N.js");
var Column$BsConsole = require("../../../utilities/Column.js");
var SymbolServers_Logs$BsConsole = require("./SymbolServers_Logs.js");
var SymbolServers_Skiplist$BsConsole = require("./SymbolServers_Skiplist.js");
var SymbolServers_Blacklist$BsConsole = require("./SymbolServers_Blacklist.js");
var SymbolServers_Whitelist$BsConsole = require("./SymbolServers_Whitelist.js");
var SymbolServers_StatsUsage$BsConsole = require("./SymbolServers_StatsUsage.js");

var contentSectionStyle = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* :: */[
          Css.overflowY(Css.auto),
          /* [] */0
        ]
      ]
    ]);

var component = ReasonReact.reducerComponent("SymbolServers_Tabs-BsConsole");

function make(tabsScrollOpt, server, token, endpoint, universe, project, _children) {
  var tabsScroll = tabsScrollOpt !== undefined ? tabsScrollOpt : true;
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state.tab;
              var tmp;
              switch (match) {
                case /* StatsAndUsage */0 :
                    tmp = ReasonReact.element(undefined, undefined, SymbolServers_StatsUsage$BsConsole.make(server, token, endpoint, universe, project, []));
                    break;
                case /* WhiteList */1 :
                    tmp = ReasonReact.element(undefined, undefined, SymbolServers_Whitelist$BsConsole.make(server.id, token, endpoint, []));
                    break;
                case /* BlackList */2 :
                    tmp = ReasonReact.element(undefined, undefined, SymbolServers_Blacklist$BsConsole.make(server.id, token, endpoint, []));
                    break;
                case /* SkipList */3 :
                    tmp = ReasonReact.element(undefined, undefined, SymbolServers_Skiplist$BsConsole.make(server.id, token, endpoint, []));
                    break;
                case /* Logs */4 :
                    tmp = ReasonReact.element(undefined, undefined, SymbolServers_Logs$BsConsole.make(server.id, token, endpoint, []));
                    break;
                
              }
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.height(Css.pct(100)),
                                    /* [] */0
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tabs.Jsx2.make, self.state.tab, undefined, (function (param, tab) {
                                          return Curry._1(self.send, /* SetTab */[tab > 4 || tab < 0 ? /* StatsAndUsage */0 : tab]);
                                        }), undefined, tabsScroll, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Statistics and usage")), undefined, [])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Allowlist")), undefined, [])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Blocklist")), undefined, [])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Skip list")), undefined, [])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Tab.Jsx2.make, undefined, undefined, undefined, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Logs")), undefined, []))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                              ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, contentSectionStyle, [tmp]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      tab: /* StatsAndUsage */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          tab: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.contentSectionStyle = contentSectionStyle;
exports.component = component;
exports.make = make;
/* contentSectionStyle Not a pure module */
