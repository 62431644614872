// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");

var labelStyle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.fontStyle(/* normal */812216871),
          /* :: */[
            Css.fontWeight(/* medium */-20425611),
            /* :: */[
              Css.lineHeight(Css.px(15)),
              /* :: */[
                Css.letterSpacing(Css.em(0)),
                /* :: */[
                  Css.textAlign(/* left */-944764921),
                  /* :: */[
                    Css.marginBottom(Css.px(5)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey3)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var helpTextStyle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* :: */[
          Css.fontStyle(/* normal */812216871),
          /* :: */[
            Css.fontWeight(/* normal */812216871),
            /* :: */[
              Css.lineHeight(Css.px(15)),
              /* :: */[
                Css.letterSpacing(Css.em(0)),
                /* :: */[
                  Css.textAlign(/* left */-944764921),
                  /* :: */[
                    Css.marginBottom(Css.px(24)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function SSAFormElements$DropdownSelect(Props) {
  var label = Props.label;
  var options = Props.options;
  var optionToString = Props.optionToString;
  var selectedOption = Props.selectedOption;
  var handleChange = Props.handleChange;
  var classNameOpt = Props.className;
  var formWidthOpt = Props.formWidth;
  var placeholderOpt = Props.placeholder;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var formWidth = formWidthOpt !== undefined ? formWidthOpt : 200;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Select one";
  return React.createElement(Col2$BsConsole.make, {
              className: className,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: labelStyle,
                  children: I18N$BsConsole.showSkip(label)
                }), React.createElement(Select.default, {
                  value: selectedOption,
                  onChange: handleChange,
                  renderValue: (function (selected) {
                      if (selected !== undefined) {
                        return I18N$BsConsole.showSkip(Curry._1(optionToString, Caml_option.valFromOption(selected)));
                      } else {
                        return React.createElement("span", {
                                    className: Css.style(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.grey45)),
                                          /* [] */0
                                        ])
                                  }, I18N$BsConsole.showSkip(placeholder));
                      }
                    }),
                  defaultValue: undefined,
                  displayEmpty: true,
                  disableUnderline: true,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.px(formWidth)),
                          /* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey685)),
                            /* :: */[
                              Css.paddingLeft(Css.px(10)),
                              /* :: */[
                                Css.borderRadius(Css.px(3)),
                                /* :: */[
                                  Css.height(Css.px(39)),
                                  /* :: */[
                                    Css.paddingTop(Css.px(5)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(14)),
                                      /* :: */[
                                        Css.hover(/* :: */[
                                              Css.background(Css.hex(Colors$BsConsole.blackA08)),
                                              /* [] */0
                                            ]),
                                        /* :: */[
                                          Css.focus(/* :: */[
                                                Css.background(Css.hex(Colors$BsConsole.blackA08)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  children: Belt_Array.map(options, (function (a) {
                          return React.createElement(MenuItem.default, {
                                      value: Caml_option.some(a),
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(Curry._1(optionToString, a))
                                          })
                                    });
                        }))
                }));
}

var DropdownSelect = {
  make: SSAFormElements$DropdownSelect
};

function SSAFormElements$TextInput(Props) {
  var label = Props.label;
  var placeholder = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var classNameOpt = Props.className;
  var formSectionTextFieldClass = Props.formSectionTextFieldClass;
  var _type = Props.type;
  var inputProps = Props.inputProps;
  var error = Props.error;
  var className = classNameOpt !== undefined ? classNameOpt : Css.style(/* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]);
  var formSectionTextFieldClass$1 = Belt_Option.getWithDefault(formSectionTextFieldClass, Css.style(/* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]));
  var tmp = {
    className: formSectionTextFieldClass$1,
    placeholder: placeholder,
    value: value,
    onChange: onChange
  };
  if (_type !== undefined) {
    tmp.type = Caml_option.valFromOption(_type);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  if (inputProps !== undefined) {
    tmp.inputProps = Caml_option.valFromOption(inputProps);
  }
  return React.createElement(Col2$BsConsole.make, {
              className: className,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: labelStyle,
                  children: I18N$BsConsole.showSkip(label)
                }), React.createElement(BtSettings$BsConsole.FormSectionTextField.make, tmp));
}

var TextInput = {
  make: SSAFormElements$TextInput
};

function SSAFormElements$RangeSlider(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var min = Props.min;
  var max = Props.max;
  var step = Props.step;
  var isValid = function (value, min, max) {
    try {
      var pctF = Caml_format.caml_float_of_string(value);
      if (pctF >= min) {
        return pctF <= max;
      } else {
        return false;
      }
    }
    catch (_e){
      return false;
    }
  };
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: labelStyle,
                  children: I18N$BsConsole.show(undefined, "Probability of executing this rule")
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.RangeSlider.make, {
                      value: value,
                      onChange: (function (value) {
                          return Curry._1(onChange, /* tuple */[
                                      value,
                                      isValid(value, min, max)
                                    ]);
                        }),
                      min: min,
                      max: max,
                      step: step,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(75)),
                            /* :: */[
                              Css.selector("input", /* :: */[
                                    Css.important(Css.height(Css.em(1.18))),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.marginRight(Css.pct(2)),
                                /* [] */0
                              ]
                            ]
                          ])
                    }), React.createElement(TextField.default, {
                      error: !isValid(value, min, max),
                      placeholder: I18N$BsConsole.get(undefined, "Probability"),
                      value: value,
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.marginBottom(/* zero */-789508312),
                                    /* :: */[
                                      Css.marginRight(Css.rem(0.25)),
                                      /* :: */[
                                        Css.width(Css.pct(19)),
                                        /* :: */[
                                          Css.selector("input", /* :: */[
                                                Css.important(Css.paddingTop(Css.px(6))),
                                                /* :: */[
                                                  Css.important(Css.paddingBottom(Css.px(7))),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ])
                      },
                      inputProps: {
                        type: "number",
                        min: min,
                        max: max,
                        step: step
                      },
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(onChange, /* tuple */[
                                      value,
                                      isValid(value, min, max)
                                    ]);
                        })
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Subtitle1 */6,
                      children: I18N$BsConsole.showSkip("%")
                    })));
}

var RangeSlider = {
  make: SSAFormElements$RangeSlider
};

exports.labelStyle = labelStyle;
exports.helpTextStyle = helpTextStyle;
exports.DropdownSelect = DropdownSelect;
exports.TextInput = TextInput;
exports.RangeSlider = RangeSlider;
/* labelStyle Not a pure module */
