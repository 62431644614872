// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../../RR.js");
var I18N$BsConsole = require("../../../I18N.js");
var Task2$BsConsole = require("../../../Task2.js");
var PlainTextView$BsConsole = require("../../PlainTextView.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_FormView$BsConsole = require("./SymbolServers_FormView.js");

var symbolServersTitle = I18N$BsConsole.get(undefined, "Symbol servers");

var component = RR$BsConsole.statelessComponent("SymbolServers_EditView-BsConsole");

function make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, id, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              if (id !== undefined) {
                return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, SymbolServer_API$BsConsole.getSymbolServer(endpoint, id, undefined), undefined, (function (remote, _refetchServer) {
                                      if (typeof remote === "number") {
                                        if (remote === /* NotAsked */0) {
                                          return null;
                                        } else {
                                          return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Loader */1, []));
                                        }
                                      }
                                      if (remote.tag) {
                                        console.log(JSON.stringify(remote[0], null, 0));
                                        return ReasonReact.element(undefined, undefined, PlainTextView$BsConsole.make(symbolServersTitle, /* Error */0, []));
                                      }
                                      var result = remote[0][0];
                                      var c = result.serverCredentials;
                                      var tmp;
                                      if (c !== undefined) {
                                        var match = c.connection;
                                        tmp = match !== undefined ? (
                                            match.tag ? /* Google */3 : /* AWS */2
                                          ) : /* Basic */1;
                                      } else {
                                        tmp = /* NoAuth */0;
                                      }
                                      var c$1 = result.serverCredentials;
                                      var tmp$1;
                                      if (c$1 !== undefined) {
                                        var match$1 = c$1.connection;
                                        tmp$1 = match$1 !== undefined ? match$1[0].bucketName : "";
                                      } else {
                                        tmp$1 = "";
                                      }
                                      var sc = result.serverCredentials;
                                      var tmp$2;
                                      if (sc !== undefined) {
                                        var match$2 = sc.connection;
                                        tmp$2 = match$2 !== undefined ? match$2[0].lowerFile : false;
                                      } else {
                                        tmp$2 = false;
                                      }
                                      var sc$1 = result.serverCredentials;
                                      var tmp$3;
                                      if (sc$1 !== undefined) {
                                        var match$3 = sc$1.connection;
                                        tmp$3 = match$3 !== undefined ? match$3[0].lowerId : false;
                                      } else {
                                        tmp$3 = false;
                                      }
                                      var sc$2 = result.serverCredentials;
                                      var tmp$4;
                                      if (sc$2 !== undefined) {
                                        var match$4 = sc$2.connection;
                                        tmp$4 = match$4 !== undefined ? match$4[0].usePdb : false;
                                      } else {
                                        tmp$4 = false;
                                      }
                                      var cred = result.serverCredentials;
                                      var cred$1 = result.serverCredentials;
                                      var p = result.proxy;
                                      var p$1 = result.proxy;
                                      var p$2 = result.proxy;
                                      var p$3 = result.proxy;
                                      return ReasonReact.element(undefined, undefined, SymbolServers_FormView$BsConsole.make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, id, result.whiteList, tmp, tmp$1, tmp$2, tmp$3, tmp$4, result.numberOfConcurrentDownload, result.retryLimit, result.timeout / 1000 | 0, result.retryTimeout / 1000 | 0, result.url, result.name, cred !== undefined ? cred.userName : undefined, cred$1 !== undefined ? cred$1.password : undefined, p !== undefined ? p.username : undefined, p$1 !== undefined ? p$1.password : undefined, p$2 !== undefined ? p$2.host : undefined, p$3 !== undefined ? String(p$3.port) : undefined, []));
                                    }))));
              } else {
                return ReasonReact.element(undefined, undefined, SymbolServers_FormView$BsConsole.make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, undefined, false, /* NoAuth */0, "", false, false, false, 5, 2, 30, 60, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, []));
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.symbolServersTitle = symbolServersTitle;
exports.component = component;
exports.make = make;
/* symbolServersTitle Not a pure module */
