// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Fold$BsConsole = require("./bs-crdb-query/src/Fold.js");
var Util$BsConsole = require("./util.js");
var Query$BsConsole = require("./bs-crdb-query/src/Query.js");
var Factor$BsConsole = require("./bs-crdb-query/src/Factor.js");
var Filter$BsConsole = require("./bs-crdb-query/src/Filter.js");
var Having$BsConsole = require("./bs-crdb-query/src/Having.js");
var Select$BsConsole = require("./bs-crdb-query/src/Select.js");
var Utility$BsConsole = require("./bs-crdb-query/src/Utility.js");
var Aperture$BsConsole = require("./bs-crdb-query/src/Aperture.js");
var FoldList$BsConsole = require("./bs-crdb-query/src/FoldList.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Paginate$BsConsole = require("./bs-crdb-query/src/Paginate.js");
var ListUtils$BsConsole = require("./ListUtils.js");
var SortOrder$BsConsole = require("./bs-crdb-query/src/SortOrder.js");
var Attributes$BsConsole = require("./bs-crdb-response/src/Attributes.js");
var FilterList$BsConsole = require("./bs-crdb-query/src/FilterList.js");
var HavingList$BsConsole = require("./bs-crdb-query/src/HavingList.js");
var Aggregation$BsConsole = require("./bs-crdb-query/src/Aggregation.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var TimeAperture$BsConsole = require("./bs-crdb-query/src/TimeAperture.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");

var Defaults = {
  numBins: 32,
  distributionHead: 3
};

function toString(a) {
  if (typeof a !== "number") {
    if (a.tag) {
      return "distribution";
    } else {
      return "bin";
    }
  }
  switch (a) {
    case /* Histogram */0 :
        return "histogram";
    case /* Head */1 :
        return "head";
    case /* Tail */2 :
        return "tail";
    case /* Sum */3 :
        return "sum";
    case /* Unique */4 :
        return "unique";
    case /* Min */5 :
        return "min";
    case /* Max */6 :
        return "max";
    case /* Mean */7 :
        return "mean";
    case /* Range */8 :
        return "range";
    case /* Count */9 :
        return "count";
    
  }
}

function fromString(a) {
  switch (a) {
    case "bin" :
        return /* Bin */Block.__(0, [
                  undefined,
                  undefined
                ]);
    case "count" :
        return /* Count */9;
    case "distribution" :
        return /* Distribution */Block.__(1, [undefined]);
    case "head" :
        return /* Head */1;
    case "histogram" :
        return /* Histogram */0;
    case "max" :
        return /* Max */6;
    case "mean" :
        return /* Mean */7;
    case "min" :
        return /* Min */5;
    case "range" :
        return /* Range */8;
    case "sum" :
        return /* Sum */3;
    case "tail" :
        return /* Tail */2;
    case "unique" :
        return /* Unique */4;
    default:
      return ;
  }
}

function toJson(a) {
  if (typeof a === "number") {
    switch (a) {
      case /* Histogram */0 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "histogram",
                      /* [] */0
                    ]);
      case /* Head */1 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "head",
                      /* [] */0
                    ]);
      case /* Tail */2 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "tail",
                      /* [] */0
                    ]);
      case /* Sum */3 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "sum",
                      /* [] */0
                    ]);
      case /* Unique */4 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "unique",
                      /* [] */0
                    ]);
      case /* Min */5 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "min",
                      /* [] */0
                    ]);
      case /* Max */6 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "max",
                      /* [] */0
                    ]);
      case /* Mean */7 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "mean",
                      /* [] */0
                    ]);
      case /* Range */8 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "range",
                      /* [] */0
                    ]);
      case /* Count */9 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "count",
                      /* [] */0
                    ]);
      
    }
  } else {
    if (a.tag) {
      return ["distribution"];
    }
    var bins = a[0];
    if (bins !== undefined) {
      return [
              "bin",
              bins
            ];
    } else {
      return [
              "bin",
              32
            ];
    }
  }
}

function toCrdb(a) {
  if (typeof a === "number") {
    switch (a) {
      case /* Histogram */0 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "histogram",
                      /* [] */0
                    ]);
      case /* Head */1 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "head",
                      /* [] */0
                    ]);
      case /* Tail */2 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "tail",
                      /* [] */0
                    ]);
      case /* Sum */3 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "sum",
                      /* [] */0
                    ]);
      case /* Unique */4 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "unique",
                      /* [] */0
                    ]);
      case /* Min */5 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "min",
                      /* [] */0
                    ]);
      case /* Max */6 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "max",
                      /* [] */0
                    ]);
      case /* Mean */7 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "mean",
                      /* [] */0
                    ]);
      case /* Range */8 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "range",
                      /* [] */0
                    ]);
      case /* Count */9 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "count",
                      /* [] */0
                    ]);
      
    }
  } else {
    if (a.tag) {
      var bins = a[0];
      if (bins !== undefined) {
        return [
                "distribution",
                bins
              ];
      } else {
        return [
                "distribution",
                3
              ];
      }
    }
    var range = a[1];
    var bins$1 = a[0];
    if (bins$1 !== undefined) {
      if (range !== undefined) {
        return [
                "bin",
                bins$1,
                Caml_int64.to_int32(range[0]),
                Caml_int64.to_int32(range[1])
              ];
      } else {
        return [
                "bin",
                bins$1
              ];
      }
    } else {
      return [
              "bin",
              32
            ];
    }
  }
}

function fromJson(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  var result = Json_decode.tuple2(Json_decode.string, Json_decode.$$int, json);
                  switch (result[0]) {
                    case "bin" :
                        return /* Bin */Block.__(0, [
                                  result[1],
                                  undefined
                                ]);
                    case "distribution" :
                        return /* Distribution */Block.__(1, [3]);
                    default:
                      throw [
                            Json_decode.DecodeError,
                            "Failed to decode operation"
                          ];
                  }
                }),
              /* :: */[
                (function (json) {
                    var result = Json_decode.list(Json_decode.string, json);
                    if (result) {
                      switch (result[0]) {
                        case "count" :
                            if (!result[1]) {
                              return /* Count */9;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "distribution" :
                            if (result[1]) {
                              throw [
                                    Json_decode.DecodeError,
                                    "Failed to decode operation"
                                  ];
                            }
                            return /* Distribution */Block.__(1, [3]);
                        case "head" :
                            if (!result[1]) {
                              return /* Head */1;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "histogram" :
                            if (!result[1]) {
                              return /* Histogram */0;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "max" :
                            if (!result[1]) {
                              return /* Max */6;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "mean" :
                            if (!result[1]) {
                              return /* Mean */7;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "min" :
                            if (!result[1]) {
                              return /* Min */5;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "range" :
                            if (!result[1]) {
                              return /* Range */8;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "sum" :
                            if (!result[1]) {
                              return /* Sum */3;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "tail" :
                            if (!result[1]) {
                              return /* Tail */2;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "unique" :
                            if (!result[1]) {
                              return /* Unique */4;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        default:
                          throw [
                                Json_decode.DecodeError,
                                "Failed to decode operation"
                              ];
                      }
                    } else {
                      throw [
                            Json_decode.DecodeError,
                            "Failed to decode operation"
                          ];
                    }
                  }),
                /* [] */0
              ]
            ], json);
}

var Operation = {
  Defaults: Defaults,
  toString: toString,
  fromString: fromString,
  toJson: toJson,
  toCrdb: toCrdb,
  fromJson: fromJson
};

var Aggregation = Aggregation$BsConsole.Make({
      toString: toString,
      toJson: toJson,
      fromJson: fromJson,
      toCrdb: toCrdb
    });

function cmp(c0, c1) {
  var c = Caml_primitive.caml_string_compare(Curry._1(Aggregation.getAttribute, c0), Curry._1(Aggregation.getAttribute, c1));
  if (c !== 0) {
    return c;
  } else {
    return Caml_primitive.caml_string_compare(toString(Curry._1(Aggregation.getOperation, c0)), toString(Curry._1(Aggregation.getOperation, c1)));
  }
}

var Comparable = Belt_Id.MakeComparable({
      cmp: cmp
    });

function toJson$1(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, Aggregation.toJson));
}

function fromJson$1(json) {
  return Belt_List.fromArray(Json_decode.array(Aggregation.fromJson, json));
}

function toCrdb$1(t$prime) {
  var __x = Belt_MapString.toList(Belt_List.reduce(t$prime, undefined, (function (acc, v) {
              var l = Belt_MapString.get(acc, Curry._1(Aggregation.getAttribute, v));
              if (l !== undefined) {
                return Belt_MapString.set(acc, Curry._1(Aggregation.getAttribute, v), Belt_List.reverse(Belt_List.concat(l, /* :: */[
                                    v,
                                    /* [] */0
                                  ])));
              } else {
                return Belt_MapString.set(acc, Curry._1(Aggregation.getAttribute, v), /* :: */[
                            v,
                            /* [] */0
                          ]);
              }
            })));
  return Json_encode.object_(Belt_List.map(__x, (function (param) {
                    return /* tuple */[
                            param[0],
                            Json_encode.list((function (f) {
                                    return Curry._1(Aggregation.operationToCrdb, Curry._1(Aggregation.getOperation, f));
                                  }), param[1])
                          ];
                  })));
}

var Serialization = {
  toCrdb: toCrdb$1,
  toJson: toJson$1,
  fromJson: fromJson$1
};

var partial_arg = FoldList$BsConsole.Make;

function partial_arg$1(param, param$1) {
  return partial_arg(Aggregation, param, param$1);
}

var include = partial_arg$1(Serialization, Comparable);

var empty = include.empty;

var add = include.add;

var toCrdb$2 = include.toCrdb;

var toArray = include.toArray;

var getAttributeAggregateIndex = include.getAttributeAggregateIndex;

function fromCrdb(json) {
  var aggArray = Belt_Array.map(Belt_Array.reduce(Belt_Array.map(Json_decode.map(Js_dict.entries, (function (param) {
                      return Json_decode.dict(Util$BsConsole.identity, param);
                    }), json), (function (param) {
                  return /* tuple */[
                          param[0],
                          Json_decode.array(fromJson, param[1])
                        ];
                })), [], (function (acc, param) {
              var attribute = param[0];
              var aggTuples = Belt_Array.map(param[1], (function (aggOpp) {
                      return /* tuple */[
                              attribute,
                              aggOpp
                            ];
                    }));
              return Belt_Array.concat(acc, aggTuples);
            })), Aggregation.create);
  return Belt_Array.reduce(aggArray, empty, (function (curr, aggregation) {
                return Curry._2(add, aggregation, curr);
              }));
}

var Fold_size = include.size;

var Fold_getBy = include.getBy;

var Fold_remove = include.remove;

var Fold_toJson = include.toJson;

var Fold_fromJson = include.fromJson;

var Fold_eq = include.eq;

var Fold_fromArray = include.fromArray;

var Fold_keep = include.keep;

var Fold = {
  size: Fold_size,
  empty: empty,
  add: add,
  getBy: Fold_getBy,
  remove: Fold_remove,
  toJson: Fold_toJson,
  fromJson: Fold_fromJson,
  eq: Fold_eq,
  toCrdb: toCrdb$2,
  toArray: toArray,
  fromArray: Fold_fromArray,
  keep: Fold_keep,
  getAttributeAggregateIndex: getAttributeAggregateIndex,
  fromCrdb: fromCrdb
};

function getRange(now, g) {
  switch (g) {
    case /* Hour */0 :
        return /* tuple */[
                DateFns.subMinutes(60.0, now),
                now
              ];
    case /* Day */1 :
        return /* tuple */[
                DateFns.subDays(1.0, now),
                now
              ];
    case /* Week */2 :
        return /* tuple */[
                DateFns.subWeeks(1.0, now),
                now
              ];
    case /* Month */3 :
        return /* tuple */[
                DateFns.subMonths(1.0, now),
                now
              ];
    case /* Quarter */4 :
        return /* tuple */[
                DateFns.subMonths(3.0, now),
                now
              ];
    case /* Year */5 :
        return /* tuple */[
                DateFns.subYears(1.0, now),
                now
              ];
    case /* All */6 :
        return /* tuple */[
                new Date(1000),
                now
              ];
    
  }
}

function granularityToString(g) {
  switch (g) {
    case /* Hour */0 :
        return "hour";
    case /* Day */1 :
        return "day";
    case /* Week */2 :
        return "week";
    case /* Month */3 :
        return "month";
    case /* Quarter */4 :
        return "quarter";
    case /* Year */5 :
        return "year";
    case /* All */6 :
        return "all";
    
  }
}

function stringToGranularity(s) {
  switch (s) {
    case "all" :
        return /* All */6;
    case "day" :
        return /* Day */1;
    case "hour" :
        return /* Hour */0;
    case "month" :
        return /* Month */3;
    case "quarter" :
        return /* Quarter */4;
    case "week" :
        return /* Week */2;
    case "year" :
        return /* Year */5;
    default:
      return /* Week */2;
  }
}

var Granularity = {
  $$default: /* Week */2,
  getRange: getRange,
  granularityToString: granularityToString,
  stringToGranularity: stringToGranularity
};

function toString$1(f) {
  switch (f.tag | 0) {
    case /* Equal */0 :
        return "equal";
    case /* NotEqual */1 :
        return "not-equal";
    case /* Contains */2 :
        return "contains";
    case /* NotContains */3 :
        return "not-contains";
    case /* RegularExpression */4 :
        return "regular-expression";
    case /* InverseRegularExpression */5 :
        return "inverse-regular-expression";
    case /* GreaterThan */6 :
        return "greater-than";
    case /* LessThan */7 :
        return "less-than";
    case /* AtLeast */8 :
        return "at-least";
    case /* AtMost */9 :
        return "at-most";
    case /* AssignedTo */10 :
        return "assigned-to";
    case /* NotAssignedTo */11 :
        return "not-assigned-to";
    case /* LinkedTo */12 :
        return "linked-to";
    case /* NotLinkedTo */13 :
        return "not-linked-to";
    
  }
}

function fromString$1(op, v) {
  switch (op) {
    case "assigned-to" :
        return /* AssignedTo */Block.__(10, [v]);
    case "at-least" :
        return /* AtLeast */Block.__(8, [v]);
    case "at-most" :
        return /* AtMost */Block.__(9, [v]);
    case "contains" :
        return /* Contains */Block.__(2, [v]);
    case "equal" :
        return /* Equal */Block.__(0, [v]);
    case "greater-than" :
        return /* GreaterThan */Block.__(6, [v]);
    case "inverse-regular-expression" :
        return /* InverseRegularExpression */Block.__(5, [v]);
    case "less-than" :
        return /* LessThan */Block.__(7, [v]);
    case "linked-to" :
        return /* LinkedTo */Block.__(12, [v]);
    case "not-assigned-to" :
        return /* NotAssignedTo */Block.__(11, [v]);
    case "not-contains" :
        return /* NotContains */Block.__(3, [v]);
    case "not-equal" :
        return /* NotEqual */Block.__(1, [v]);
    case "not-linked-to" :
        return /* NotLinkedTo */Block.__(13, [v]);
    case "regular-expression" :
        return /* RegularExpression */Block.__(4, [v]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "crdb.re",
              327,
              11
            ]
          ];
  }
}

function getRange$1(r$prime, r$prime$prime) {
  return /* tuple */[
          /* AtLeast */Block.__(8, [/* `Float */[
                365180284,
                r$prime
              ]]),
          /* AtMost */Block.__(9, [/* `Float */[
                365180284,
                r$prime$prime
              ]])
        ];
}

function valueToJson(v) {
  var variant = v[0];
  if (variant >= 3654863) {
    if (variant >= 737456202) {
      if (variant >= 758940238) {
        return v[1].getTime();
      } else {
        return Pervasives.string_of_bool(v[1]);
      }
    } else if (variant >= 365180284) {
      return v[1].toString();
    } else {
      return Int64.to_string(v[1]);
    }
  } else if (variant >= -589436806) {
    return ObjectID$BsConsole.toDecString(v[1]);
  } else {
    return v[1];
  }
}

function valueToTypeStringJson(v) {
  var variant = v[0];
  if (variant >= 3654863) {
    if (variant >= 737456202) {
      if (variant >= 758940238) {
        return "date";
      } else {
        return "bool";
      }
    } else if (variant >= 365180284) {
      return "float";
    } else {
      return "int";
    }
  } else if (variant >= -589436806) {
    return "objectID";
  } else {
    return "string";
  }
}

function valueToCrdb(v) {
  var variant = v[0];
  if (variant >= 3654863) {
    if (variant >= 737456202) {
      if (variant >= 758940238) {
        return v[1].getTime();
      } else {
        return Pervasives.string_of_bool(v[1]);
      }
    } else if (variant >= 365180284) {
      return v[1].toString();
    } else {
      return Int64.to_string(v[1]);
    }
  } else if (variant >= -589436806) {
    return ObjectID$BsConsole.toDecString(v[1]);
  } else {
    return v[1];
  }
}

function getValue(t$prime) {
  return t$prime[0];
}

function encoder(param) {
  return Json_encode.tuple2((function (prim) {
                return prim;
              }), valueToJson, param);
}

function encoderCrdb(param) {
  return Json_encode.tuple2((function (prim) {
                return prim;
              }), valueToCrdb, param);
}

function toJson$2(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "equal",
                    v[0]
                  ]);
    case /* NotEqual */1 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "not-equal",
                    v[0]
                  ]);
    case /* Contains */2 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "contains",
                    v[0]
                  ]);
    case /* NotContains */3 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "not-contains",
                    v[0]
                  ]);
    case /* RegularExpression */4 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "regular-expression",
                    v[0]
                  ]);
    case /* InverseRegularExpression */5 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "inverse-regular-expression",
                    v[0]
                  ]);
    case /* GreaterThan */6 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "greater-than",
                    v[0]
                  ]);
    case /* LessThan */7 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "less-than",
                    v[0]
                  ]);
    case /* AtLeast */8 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "at-least",
                    v[0]
                  ]);
    case /* AtMost */9 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "at-most",
                    v[0]
                  ]);
    case /* AssignedTo */10 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "assigned-to",
                    v[0]
                  ]);
    case /* NotAssignedTo */11 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "not-assigned-to",
                    v[0]
                  ]);
    case /* LinkedTo */12 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "linked-to",
                    v[0]
                  ]);
    case /* NotLinkedTo */13 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToJson, /* tuple */[
                    "not-linked-to",
                    v[0]
                  ]);
    
  }
}

function privateEncoder(param) {
  return Json_encode.tuple2((function (prim) {
                return prim;
              }), valueToTypeStringJson, param);
}

function toPrivateJson(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "equal",
                    v[0]
                  ]);
    case /* NotEqual */1 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "not-equal",
                    v[0]
                  ]);
    case /* Contains */2 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "contains",
                    v[0]
                  ]);
    case /* NotContains */3 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "not-contains",
                    v[0]
                  ]);
    case /* RegularExpression */4 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "regular-expression",
                    v[0]
                  ]);
    case /* InverseRegularExpression */5 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "inverse-regular-expression",
                    v[0]
                  ]);
    case /* GreaterThan */6 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "greater-than",
                    v[0]
                  ]);
    case /* LessThan */7 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "less-than",
                    v[0]
                  ]);
    case /* AtLeast */8 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "at-least",
                    v[0]
                  ]);
    case /* AtMost */9 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "at-most",
                    v[0]
                  ]);
    case /* AssignedTo */10 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "assigned-to",
                    v[0]
                  ]);
    case /* NotAssignedTo */11 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "not-assigned-to",
                    v[0]
                  ]);
    case /* LinkedTo */12 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "linked-to",
                    v[0]
                  ]);
    case /* NotLinkedTo */13 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToTypeStringJson, /* tuple */[
                    "not-linked-to",
                    v[0]
                  ]);
    
  }
}

function encoderCrdbWithInlineRegexFlags(param) {
  var v$prime = param[1];
  var name = param[0];
  if (!CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.49.14")) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), valueToCrdb, /* tuple */[
                name,
                v$prime
              ]);
  }
  var captures = Belt_Option.map(Caml_option.null_to_opt(new RegExp("^\\/(.+)\\/([i])?$").exec(Json_decode.string(valueToJson(v$prime)))), (function (prim) {
          return prim;
        }));
  var inner = Belt_Option.flatMap(Belt_Option.flatMap(captures, (function (captures) {
              return Belt_Array.get(captures, 1);
            })), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  if (inner === undefined) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), valueToCrdb, /* tuple */[
                name,
                v$prime
              ]);
  }
  var match = Belt_Option.flatMap(Belt_Option.flatMap(captures, (function (captures) {
              return Belt_Array.get(captures, 2);
            })), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  if (match !== undefined) {
    if (match === "i") {
      return Json_encode.tuple3((function (prim) {
                    return prim;
                  }), (function (prim) {
                    return prim;
                  }), Json_encode.object_, /* tuple */[
                  name,
                  inner,
                  /* :: */[
                    /* tuple */[
                      "case_insensitive",
                      true
                    ],
                    /* [] */0
                  ]
                ]);
    } else {
      return Json_encode.tuple2((function (prim) {
                    return prim;
                  }), valueToCrdb, /* tuple */[
                  name,
                  /* `String */[
                    -976970511,
                    inner
                  ]
                ]);
    }
  } else {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), valueToCrdb, /* tuple */[
                name,
                /* `String */[
                  -976970511,
                  inner
                ]
              ]);
  }
}

function toCrdb$3(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "equal",
                    v[0]
                  ]);
    case /* NotEqual */1 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "not-equal",
                    v[0]
                  ]);
    case /* Contains */2 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "contains",
                    v[0]
                  ]);
    case /* NotContains */3 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "not-contains",
                    v[0]
                  ]);
    case /* RegularExpression */4 :
        return encoderCrdbWithInlineRegexFlags(/* tuple */[
                    "regular-expression",
                    v[0]
                  ]);
    case /* InverseRegularExpression */5 :
        return encoderCrdbWithInlineRegexFlags(/* tuple */[
                    "inverse-regular-expression",
                    v[0]
                  ]);
    case /* GreaterThan */6 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "greater-than",
                    v[0]
                  ]);
    case /* LessThan */7 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "less-than",
                    v[0]
                  ]);
    case /* AtLeast */8 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "at-least",
                    v[0]
                  ]);
    case /* AtMost */9 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, /* tuple */[
                    "at-most",
                    v[0]
                  ]);
    case /* AssignedTo */10 :
        var v$prime = v[0];
        var param_001 = typeof v$prime === "number" || v$prime[0] !== -976970511 ? v$prime : /* `String */[
            -976970511,
            Utility$BsConsole.toKeyValueRegex("username", v$prime[1])
          ];
        var param = /* tuple */[
          "regular-expression",
          param_001
        ];
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, param);
    case /* NotAssignedTo */11 :
        var v$prime$1 = v[0];
        var param_001$1 = typeof v$prime$1 === "number" || v$prime$1[0] !== -976970511 ? v$prime$1 : /* `String */[
            -976970511,
            Utility$BsConsole.toKeyValueRegex("username", v$prime$1[1])
          ];
        var param$1 = /* tuple */[
          "inverse-regular-expression",
          param_001$1
        ];
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, param$1);
    case /* LinkedTo */12 :
        var v$prime$2 = v[0];
        if (typeof v$prime$2 !== "number" && v$prime$2[0] === -976970511 && v$prime$2[1] === "*") {
          var param_001$2 = /* `String */[
            -976970511,
            Utility$BsConsole.toKeyRegex("short")
          ];
          var param$2 = /* tuple */[
            "inverse-regular-expression",
            param_001$2
          ];
          return Json_encode.tuple2((function (prim) {
                        return prim;
                      }), valueToCrdb, param$2);
        }
        var param_001$3 = typeof v$prime$2 === "number" || v$prime$2[0] !== -976970511 ? v$prime$2 : /* `String */[
            -976970511,
            Utility$BsConsole.toKeyValueRegex("short", v$prime$2[1])
          ];
        var param$3 = /* tuple */[
          "regular-expression",
          param_001$3
        ];
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, param$3);
    case /* NotLinkedTo */13 :
        var v$prime$3 = v[0];
        if (typeof v$prime$3 !== "number" && v$prime$3[0] === -976970511 && v$prime$3[1] === "*") {
          var param_001$4 = /* `String */[
            -976970511,
            Utility$BsConsole.toKeyRegex("short")
          ];
          var param$4 = /* tuple */[
            "regular-expression",
            param_001$4
          ];
          return Json_encode.tuple2((function (prim) {
                        return prim;
                      }), valueToCrdb, param$4);
        }
        var param_001$5 = typeof v$prime$3 === "number" || v$prime$3[0] !== -976970511 ? v$prime$3 : /* `String */[
            -976970511,
            Utility$BsConsole.toKeyValueRegex("short", v$prime$3[1])
          ];
        var param$5 = /* tuple */[
          "inverse-regular-expression",
          param_001$5
        ];
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), valueToCrdb, param$5);
    
  }
}

function parseStringExn(v) {
  return /* `String */[
          -976970511,
          Json_decode.string(v)
        ];
}

function parseFloatExn(v) {
  return /* `Float */[
          365180284,
          Json_decode.$$float(v)
        ];
}

function parseIntExn(v) {
  return /* `Int */[
          3654863,
          Caml_int64.of_int32(Json_decode.$$int(v))
        ];
}

function parseBoolExn(v) {
  return /* `Bool */[
          737456202,
          Json_decode.bool(v)
        ];
}

function fromJson$2(json) {
  var partial_arg_001 = /* :: */[
    parseIntExn,
    /* :: */[
      parseFloatExn,
      /* :: */[
        parseBoolExn,
        /* [] */0
      ]
    ]
  ];
  var partial_arg = /* :: */[
    parseStringExn,
    partial_arg_001
  ];
  var match = Json_decode.tuple2(Json_decode.string, (function (param) {
          return Json_decode.oneOf(partial_arg, param);
        }), json);
  switch (match[0]) {
    case "assigned-to" :
        return /* AssignedTo */Block.__(10, [match[1]]);
    case "at-least" :
        return /* AtLeast */Block.__(8, [match[1]]);
    case "at-most" :
        return /* AtMost */Block.__(9, [match[1]]);
    case "contains" :
        return /* Contains */Block.__(2, [match[1]]);
    case "equal" :
        return /* Equal */Block.__(0, [match[1]]);
    case "greater-than" :
        return /* GreaterThan */Block.__(6, [match[1]]);
    case "inverse-regular-expression" :
        return /* InverseRegularExpression */Block.__(5, [match[1]]);
    case "less-than" :
        return /* LessThan */Block.__(7, [match[1]]);
    case "linked-to" :
        return /* LinkedTo */Block.__(12, [match[1]]);
    case "not-assigned-to" :
        return /* NotAssignedTo */Block.__(11, [match[1]]);
    case "not-contains" :
        return /* NotContains */Block.__(3, [match[1]]);
    case "not-equal" :
        return /* NotEqual */Block.__(1, [match[1]]);
    case "not-linked-to" :
        return /* NotLinkedTo */Block.__(13, [match[1]]);
    case "regular-expression" :
        return /* RegularExpression */Block.__(4, [match[1]]);
    default:
      throw [
            Json_decode.DecodeError,
            "failed to decode filter"
          ];
  }
}

function fromTuple(tuple) {
  return fromJson$2(Json_encode.tuple2((function (prim) {
                    return prim;
                  }), (function (prim) {
                    return prim;
                  }), tuple));
}

function encodeURIComponentOfString(v) {
  if (typeof v === "number" || v[0] !== -976970511) {
    return v;
  } else {
    return /* `String */[
            -976970511,
            encodeURIComponent(v[1])
          ];
  }
}

function decodeURIComponentOfString(v) {
  if (typeof v === "number" || v[0] !== -976970511) {
    return v;
  } else {
    return /* `String */[
            -976970511,
            decodeURIComponent(v[1])
          ];
  }
}

function toURLEncodedJson(v) {
  var tmp;
  switch (v.tag | 0) {
    case /* Equal */0 :
        tmp = /* Equal */Block.__(0, [encodeURIComponentOfString(v[0])]);
        break;
    case /* NotEqual */1 :
        tmp = /* NotEqual */Block.__(1, [encodeURIComponentOfString(v[0])]);
        break;
    case /* Contains */2 :
        tmp = /* Contains */Block.__(2, [encodeURIComponentOfString(v[0])]);
        break;
    case /* NotContains */3 :
        tmp = /* NotContains */Block.__(3, [encodeURIComponentOfString(v[0])]);
        break;
    case /* RegularExpression */4 :
        tmp = /* RegularExpression */Block.__(4, [encodeURIComponentOfString(v[0])]);
        break;
    case /* InverseRegularExpression */5 :
        tmp = /* InverseRegularExpression */Block.__(5, [encodeURIComponentOfString(v[0])]);
        break;
    case /* GreaterThan */6 :
        tmp = /* GreaterThan */Block.__(6, [encodeURIComponentOfString(v[0])]);
        break;
    case /* LessThan */7 :
        tmp = /* LessThan */Block.__(7, [encodeURIComponentOfString(v[0])]);
        break;
    case /* AtLeast */8 :
        tmp = /* AtLeast */Block.__(8, [encodeURIComponentOfString(v[0])]);
        break;
    case /* AtMost */9 :
        tmp = /* AtMost */Block.__(9, [encodeURIComponentOfString(v[0])]);
        break;
    case /* AssignedTo */10 :
        tmp = /* AssignedTo */Block.__(10, [encodeURIComponentOfString(v[0])]);
        break;
    case /* NotAssignedTo */11 :
        tmp = /* NotAssignedTo */Block.__(11, [encodeURIComponentOfString(v[0])]);
        break;
    case /* LinkedTo */12 :
        tmp = /* LinkedTo */Block.__(12, [encodeURIComponentOfString(v[0])]);
        break;
    case /* NotLinkedTo */13 :
        tmp = /* NotLinkedTo */Block.__(13, [encodeURIComponentOfString(v[0])]);
        break;
    
  }
  return toJson$2(tmp);
}

function fromURLEncodedJson(json) {
  var v$prime = fromJson$2(json);
  switch (v$prime.tag | 0) {
    case /* Equal */0 :
        return /* Equal */Block.__(0, [decodeURIComponentOfString(v$prime[0])]);
    case /* NotEqual */1 :
        return /* NotEqual */Block.__(1, [decodeURIComponentOfString(v$prime[0])]);
    case /* Contains */2 :
        return /* Contains */Block.__(2, [decodeURIComponentOfString(v$prime[0])]);
    case /* NotContains */3 :
        return /* NotContains */Block.__(3, [decodeURIComponentOfString(v$prime[0])]);
    case /* RegularExpression */4 :
        return /* RegularExpression */Block.__(4, [decodeURIComponentOfString(v$prime[0])]);
    case /* InverseRegularExpression */5 :
        return /* InverseRegularExpression */Block.__(5, [decodeURIComponentOfString(v$prime[0])]);
    case /* GreaterThan */6 :
        return /* GreaterThan */Block.__(6, [decodeURIComponentOfString(v$prime[0])]);
    case /* LessThan */7 :
        return /* LessThan */Block.__(7, [decodeURIComponentOfString(v$prime[0])]);
    case /* AtLeast */8 :
        return /* AtLeast */Block.__(8, [decodeURIComponentOfString(v$prime[0])]);
    case /* AtMost */9 :
        return /* AtMost */Block.__(9, [decodeURIComponentOfString(v$prime[0])]);
    case /* AssignedTo */10 :
        return /* AssignedTo */Block.__(10, [decodeURIComponentOfString(v$prime[0])]);
    case /* NotAssignedTo */11 :
        return /* NotAssignedTo */Block.__(11, [decodeURIComponentOfString(v$prime[0])]);
    case /* LinkedTo */12 :
        return /* LinkedTo */Block.__(12, [decodeURIComponentOfString(v$prime[0])]);
    case /* NotLinkedTo */13 :
        return /* NotLinkedTo */Block.__(13, [decodeURIComponentOfString(v$prime[0])]);
    
  }
}

var FilterOperation = {
  toString: toString$1,
  fromString: fromString$1,
  getRange: getRange$1,
  valueToJson: valueToJson,
  valueToTypeStringJson: valueToTypeStringJson,
  valueToCrdb: valueToCrdb,
  getValue: getValue,
  encoder: encoder,
  encoderCrdb: encoderCrdb,
  toJson: toJson$2,
  privateEncoder: privateEncoder,
  toPrivateJson: toPrivateJson,
  encoderCrdbWithInlineRegexFlags: encoderCrdbWithInlineRegexFlags,
  toCrdb: toCrdb$3,
  parseStringExn: parseStringExn,
  parseFloatExn: parseFloatExn,
  parseIntExn: parseIntExn,
  parseBoolExn: parseBoolExn,
  fromJson: fromJson$2,
  fromTuple: fromTuple,
  encodeURIComponentOfString: encodeURIComponentOfString,
  decodeURIComponentOfString: decodeURIComponentOfString,
  toURLEncodedJson: toURLEncodedJson,
  fromURLEncodedJson: fromURLEncodedJson
};

function toString$2(f) {
  switch (f.tag | 0) {
    case /* Equal */0 :
        return "equal";
    case /* NotEqual */1 :
        return "not-equal";
    case /* GreaterThan */2 :
        return "greater-than";
    case /* LessThan */3 :
        return "less-than";
    case /* AtLeast */4 :
        return "at-least";
    case /* AtMost */5 :
        return "at-most";
    
  }
}

function getOptions(a) {
  var operations = [
    "equal",
    "not-equal",
    "greater-than",
    "less-than",
    "at-least",
    "at-most"
  ];
  var match = Belt_Option.getWithDefault(Attributes$BsConsole.type_FromJs(a.type_), /* Dictionary */1023055798);
  var exit = 0;
  if (match >= 530205405) {
    if (match >= 946114815) {
      exit = match >= 946856507 ? 1 : 2;
    } else {
      if (match >= 631276559) {
        return ;
      }
      exit = 2;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        var $$default = [
          /* tuple */[
            "head",
            operations
          ],
          /* tuple */[
            "tail",
            operations
          ],
          /* tuple */[
            "max",
            operations
          ],
          /* tuple */[
            "min",
            operations
          ],
          /* tuple */[
            "unique",
            operations
          ]
        ];
        var n = a.name;
        return n === Attribute_Helpers$BsConsole.rowCount.name ? Belt_Array.concat($$default, [/* tuple */[
                        "count",
                        operations
                      ]]) : $$default;
    case 2 :
        return [
                /* tuple */[
                  "min",
                  operations
                ],
                /* tuple */[
                  "max",
                  operations
                ],
                /* tuple */[
                  "sum",
                  operations
                ],
                /* tuple */[
                  "unique",
                  operations
                ]
              ];
    
  }
}

function getOperationCrdbString(f) {
  switch (f.tag | 0) {
    case /* Equal */0 :
        return "==";
    case /* NotEqual */1 :
        return "!=";
    case /* GreaterThan */2 :
        return ">";
    case /* LessThan */3 :
        return "<";
    case /* AtLeast */4 :
        return ">=";
    case /* AtMost */5 :
        return "<=";
    
  }
}

function getValue$1(t$prime) {
  return t$prime[0];
}

function toJsonTuple2(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return /* tuple */[
                "equal",
                v[0]
              ];
    case /* NotEqual */1 :
        return /* tuple */[
                "not-equal",
                v[0]
              ];
    case /* GreaterThan */2 :
        return /* tuple */[
                "greater-than",
                v[0]
              ];
    case /* LessThan */3 :
        return /* tuple */[
                "less-than",
                v[0]
              ];
    case /* AtLeast */4 :
        return /* tuple */[
                "at-least",
                v[0]
              ];
    case /* AtMost */5 :
        return /* tuple */[
                "at-most",
                v[0]
              ];
    
  }
}

function toJson$3(v) {
  return Json_encode.tuple2((function (a) {
                return a;
              }), (function (a) {
                return a;
              }), toJsonTuple2(v));
}

function toCrdbTuple2(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return /* tuple */[
                "equal",
                v[0]
              ];
    case /* NotEqual */1 :
        return /* tuple */[
                "not-equal",
                v[0]
              ];
    case /* GreaterThan */2 :
        return /* tuple */[
                "greater-than",
                v[0]
              ];
    case /* LessThan */3 :
        return /* tuple */[
                "less-than",
                v[0]
              ];
    case /* AtLeast */4 :
        return /* tuple */[
                "at-least",
                v[0]
              ];
    case /* AtMost */5 :
        return /* tuple */[
                "at-most",
                v[0]
              ];
    
  }
}

function privateEncoder$1(param) {
  return Json_encode.tuple2((function (prim) {
                return prim;
              }), (function (prim) {
                return prim;
              }), param);
}

function toPrivateJson$1(v) {
  switch (v.tag | 0) {
    case /* Equal */0 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "equal",
                    v[0]
                  ]);
    case /* NotEqual */1 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "not-equal",
                    v[0]
                  ]);
    case /* GreaterThan */2 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "greater-than",
                    v[0]
                  ]);
    case /* LessThan */3 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "less-than",
                    v[0]
                  ]);
    case /* AtLeast */4 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "at-least",
                    v[0]
                  ]);
    case /* AtMost */5 :
        return Json_encode.tuple2((function (prim) {
                      return prim;
                    }), (function (prim) {
                      return prim;
                    }), /* tuple */[
                    "at-most",
                    v[0]
                  ]);
    
  }
}

function fromJson$3(json) {
  var match = Json_decode.tuple2(Json_decode.string, Json_decode.string, json);
  switch (match[0]) {
    case "at-least" :
        return /* AtLeast */Block.__(4, [match[1]]);
    case "at-most" :
        return /* AtMost */Block.__(5, [match[1]]);
    case "equal" :
        return /* Equal */Block.__(0, [match[1]]);
    case "greater-than" :
        return /* GreaterThan */Block.__(2, [match[1]]);
    case "less-than" :
        return /* LessThan */Block.__(3, [match[1]]);
    case "not-equal" :
        return /* NotEqual */Block.__(1, [match[1]]);
    default:
      throw [
            Json_decode.DecodeError,
            "failed to decode filter"
          ];
  }
}

function fromTuple$1(tuple) {
  return fromJson$3(Json_encode.tuple2((function (prim) {
                    return prim;
                  }), (function (prim) {
                    return prim;
                  }), tuple));
}

function encodeURIComponentOfString$1(v) {
  return encodeURIComponent(v);
}

function decodeURIComponentOfString$1(v) {
  return decodeURIComponent(v);
}

function toURLEncodedJson$1(v) {
  var tmp;
  switch (v.tag | 0) {
    case /* Equal */0 :
        tmp = /* Equal */Block.__(0, [encodeURIComponent(v[0])]);
        break;
    case /* NotEqual */1 :
        tmp = /* NotEqual */Block.__(1, [encodeURIComponent(v[0])]);
        break;
    case /* GreaterThan */2 :
        tmp = /* GreaterThan */Block.__(2, [encodeURIComponent(v[0])]);
        break;
    case /* LessThan */3 :
        tmp = /* LessThan */Block.__(3, [encodeURIComponent(v[0])]);
        break;
    case /* AtLeast */4 :
        tmp = /* AtLeast */Block.__(4, [encodeURIComponent(v[0])]);
        break;
    case /* AtMost */5 :
        tmp = /* AtMost */Block.__(5, [encodeURIComponent(v[0])]);
        break;
    
  }
  return toJson$3(tmp);
}

function fromURLEncodedJson$1(json) {
  var v$prime = fromJson$3(json);
  switch (v$prime.tag | 0) {
    case /* Equal */0 :
        return /* Equal */Block.__(0, [decodeURIComponent(v$prime[0])]);
    case /* NotEqual */1 :
        return /* NotEqual */Block.__(1, [decodeURIComponent(v$prime[0])]);
    case /* GreaterThan */2 :
        return /* GreaterThan */Block.__(2, [decodeURIComponent(v$prime[0])]);
    case /* LessThan */3 :
        return /* LessThan */Block.__(3, [decodeURIComponent(v$prime[0])]);
    case /* AtLeast */4 :
        return /* AtLeast */Block.__(4, [decodeURIComponent(v$prime[0])]);
    case /* AtMost */5 :
        return /* AtMost */Block.__(5, [decodeURIComponent(v$prime[0])]);
    
  }
}

var HavingOperation = {
  toString: toString$2,
  getOptions: getOptions,
  getOperationCrdbString: getOperationCrdbString,
  getValue: getValue$1,
  toJsonTuple2: toJsonTuple2,
  toJson: toJson$3,
  toCrdbTuple2: toCrdbTuple2,
  privateEncoder: privateEncoder$1,
  toPrivateJson: toPrivateJson$1,
  fromJson: fromJson$3,
  fromTuple: fromTuple$1,
  encodeURIComponentOfString: encodeURIComponentOfString$1,
  decodeURIComponentOfString: decodeURIComponentOfString$1,
  toURLEncodedJson: toURLEncodedJson$1,
  fromURLEncodedJson: fromURLEncodedJson$1
};

function getFilterOpereationOptions(attribute, maybeValue) {
  var tmp;
  if (maybeValue !== undefined && !maybeValue.tag) {
    var value = maybeValue[0];
    if (typeof value === "number") {
      tmp = /* [] */0;
    } else {
      var variant = value[0];
      if (variant !== -976970511) {
        if (variant !== 365180284) {
          if (variant !== 737456202) {
            tmp = /* [] */0;
          } else {
            var v = value[1];
            tmp = /* :: */[
              /* Equal */Block.__(0, [/* `Bool */[
                    737456202,
                    v
                  ]]),
              /* :: */[
                /* NotEqual */Block.__(1, [/* `Bool */[
                      737456202,
                      v
                    ]]),
                /* [] */0
              ]
            ];
          }
        } else {
          var v$1 = value[1];
          tmp = /* :: */[
            /* Equal */Block.__(0, [/* `Float */[
                  365180284,
                  v$1
                ]]),
            /* :: */[
              /* NotEqual */Block.__(1, [/* `Float */[
                    365180284,
                    v$1
                  ]]),
              /* :: */[
                /* AtLeast */Block.__(8, [/* `Float */[
                      365180284,
                      v$1
                    ]]),
                /* :: */[
                  /* AtMost */Block.__(9, [/* `Float */[
                        365180284,
                        v$1
                      ]]),
                  /* [] */0
                ]
              ]
            ]
          ];
        }
      } else {
        var v$2 = value[1];
        tmp = /* :: */[
          /* Equal */Block.__(0, [/* `String */[
                -976970511,
                v$2
              ]]),
          /* :: */[
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  v$2
                ]]),
            /* [] */0
          ]
        ];
      }
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.map(tmp, (function (opp) {
                return /* tuple */[
                        attribute,
                        opp
                      ];
              }));
}

var TimeAperture = TimeAperture$BsConsole.Make({
      $$default: /* Week */2,
      granularityToString: granularityToString,
      stringToGranularity: stringToGranularity,
      getRange: getRange
    });

var $$let = Filter$BsConsole.Make({
      toJson: toJson$2,
      toURLEncodedJson: toURLEncodedJson,
      toPrivateJson: toPrivateJson,
      fromJson: fromJson$2,
      fromURLEncodedJson: fromURLEncodedJson,
      getRange: getRange$1
    });

var Filter_create = $$let.create;

var Filter_getAttribute = $$let.getAttribute;

var Filter_getOperation = $$let.getOperation;

var Filter_createRange = $$let.createRange;

var Filter_toJson = $$let.toJson;

var Filter_toURLEncodedJson = $$let.toURLEncodedJson;

var Filter_toPrivateJson = $$let.toPrivateJson;

var Filter_fromJson = $$let.fromJson;

var Filter_fromURLEncodedJson = $$let.fromURLEncodedJson;

var Filter = {
  create: Filter_create,
  getAttribute: Filter_getAttribute,
  getOperation: Filter_getOperation,
  createRange: Filter_createRange,
  toJson: Filter_toJson,
  toURLEncodedJson: Filter_toURLEncodedJson,
  toPrivateJson: Filter_toPrivateJson,
  fromJson: Filter_fromJson,
  fromURLEncodedJson: Filter_fromURLEncodedJson
};

function map(attr) {
  if (attr === "fingerprint;issues;assignee") {
    return "fingerprint;issues;ticket";
  } else {
    return attr;
  }
}

function toJson$4(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let.toJson));
}

function toURLEncodedJson$2(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let.toURLEncodedJson));
}

function toPrivateJson$2(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let.toPrivateJson));
}

function fromJson$4(json) {
  return Belt_List.fromArray(Json_decode.array($$let.fromJson, json));
}

function fromURLEncodedJson$2(json) {
  return Belt_List.fromArray(Json_decode.array($$let.fromURLEncodedJson, json));
}

function toCrdb$4(t$prime) {
  return Json_encode.object_(Belt_List.map(Belt_MapString.toList(Belt_List.reduce(t$prime, undefined, (function (acc, f) {
                            var a = map(Curry._1($$let.getAttribute, f));
                            var o = Curry._1($$let.getOperation, f);
                            var l = Belt_MapString.get(acc, a);
                            if (l !== undefined) {
                              return Belt_MapString.set(acc, a, Belt_List.concat(l, /* :: */[
                                              o,
                                              /* [] */0
                                            ]));
                            } else {
                              return Belt_MapString.set(acc, a, /* :: */[
                                          o,
                                          /* [] */0
                                        ]);
                            }
                          }))), (function (param) {
                    return /* tuple */[
                            param[0],
                            Json_encode.list(toCrdb$3, param[1])
                          ];
                  })));
}

var FilterSerializer = {
  toCrdb: toCrdb$4,
  toJson: toJson$4,
  toURLEncodedJson: toURLEncodedJson$2,
  toPrivateJson: toPrivateJson$2,
  fromJson: fromJson$4,
  fromURLEncodedJson: fromURLEncodedJson$2
};

function cmp$1(c0, c1) {
  var c = Caml_primitive.caml_string_compare(Curry._1($$let.getAttribute, c0), Curry._1($$let.getAttribute, c1));
  if (c !== 0) {
    return c;
  }
  var c$1 = Caml_primitive.caml_string_compare(toString$1(Curry._1($$let.getOperation, c0)), toString$1(Curry._1($$let.getOperation, c1)));
  if (c$1 !== 0) {
    return c$1;
  } else {
    return Caml_obj.caml_compare(Curry._1($$let.getOperation, c0)[0], Curry._1($$let.getOperation, c1)[0]);
  }
}

var FilterComparable = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var partial_arg$2 = FilterList$BsConsole.Make;

function partial_arg$3(param, param$1) {
  return partial_arg$2(Filter, param, param$1);
}

var $$let$1 = partial_arg$3(FilterSerializer, FilterComparable);

var Filters_empty = $$let$1.empty;

var Filters_toJson = $$let$1.toJson;

var Filters_toURLEncodedJson = $$let$1.toURLEncodedJson;

var Filters_toPrivateJson = $$let$1.toPrivateJson;

var Filters_fromJson = $$let$1.fromJson;

var Filters_fromURLEncodedJson = $$let$1.fromURLEncodedJson;

var Filters_eq = $$let$1.eq;

var Filters_addRange = $$let$1.addRange;

var Filters_toCrdb = $$let$1.toCrdb;

var Filters_add = $$let$1.add;

var Filters_remove = $$let$1.remove;

var Filters_toArray = $$let$1.toArray;

var Filters_fromArray = $$let$1.fromArray;

var Filters_keep = $$let$1.keep;

var Filters_size = $$let$1.size;

var Filters_getBy = $$let$1.getBy;

var Filters = {
  empty: Filters_empty,
  toJson: Filters_toJson,
  toURLEncodedJson: Filters_toURLEncodedJson,
  toPrivateJson: Filters_toPrivateJson,
  fromJson: Filters_fromJson,
  fromURLEncodedJson: Filters_fromURLEncodedJson,
  eq: Filters_eq,
  addRange: Filters_addRange,
  toCrdb: Filters_toCrdb,
  add: Filters_add,
  remove: Filters_remove,
  toArray: Filters_toArray,
  fromArray: Filters_fromArray,
  keep: Filters_keep,
  size: Filters_size,
  getBy: Filters_getBy
};

var $$let$2 = Having$BsConsole.Make({
      toJson: toJson$3,
      toURLEncodedJson: toURLEncodedJson$1,
      toPrivateJson: toPrivateJson$1,
      fromJson: fromJson$3,
      fromURLEncodedJson: fromURLEncodedJson$1
    });

var Having_create = $$let$2.create;

var Having_getAttribute = $$let$2.getAttribute;

var Having_getPipe = $$let$2.getPipe;

var Having_getOperation = $$let$2.getOperation;

var Having_toJson = $$let$2.toJson;

var Having_toURLEncodedJson = $$let$2.toURLEncodedJson;

var Having_toPrivateJson = $$let$2.toPrivateJson;

var Having_fromJson = $$let$2.fromJson;

var Having_fromURLEncodedJson = $$let$2.fromURLEncodedJson;

var Having = {
  create: Having_create,
  getAttribute: Having_getAttribute,
  getPipe: Having_getPipe,
  getOperation: Having_getOperation,
  toJson: Having_toJson,
  toURLEncodedJson: Having_toURLEncodedJson,
  toPrivateJson: Having_toPrivateJson,
  fromJson: Having_fromJson,
  fromURLEncodedJson: Having_fromURLEncodedJson
};

function toJson$5(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let$2.toJson));
}

function toURLEncodedJson$3(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let$2.toURLEncodedJson));
}

function toPrivateJson$3(t$prime) {
  return Belt_List.toArray(Belt_List.map(t$prime, $$let$2.toPrivateJson));
}

function fromJson$5(json) {
  return Belt_List.fromArray(Json_decode.array($$let$2.fromJson, json));
}

function fromURLEncodedJson$3(json) {
  return Belt_List.fromArray(Json_decode.array($$let$2.fromURLEncodedJson, json));
}

function toCrdb$5(t$prime, f$prime) {
  if (!t$prime) {
    return [Json_encode.object_(/* [] */0)];
  }
  var __x = Belt_List.map(t$prime, (function (h) {
          var match = Curry._1($$let$2.getAttribute, h);
          var match$1 = Curry._1($$let$2.getPipe, h);
          return /* :: */[
                  /* tuple */[
                    "params",
                    [Curry._1($$let$2.getOperation, h)[0]]
                  ],
                  /* :: */[
                    /* tuple */[
                      "op",
                      getOperationCrdbString(Curry._1($$let$2.getOperation, h))
                    ],
                    /* :: */[
                      /* tuple */[
                        "property",
                        [match !== undefined ? match + (";" + (String(Belt_Option.getWithDefault(Curry._3(getAttributeAggregateIndex, match, match$1, f$prime), 0)) + ";0")) : ";" + match$1]
                      ],
                      /* [] */0
                    ]
                  ]
                ];
        }));
  return Json_encode.array(Json_encode.object_, Belt_List.toArray(Belt_List.reduceWithIndex(__x, /* [] */0, (function (acc, curr, idx) {
                        if (idx !== 0) {
                          return Belt_List.concat(acc, /* :: */[
                                      curr,
                                      /* :: */[
                                        /* :: */[
                                          /* tuple */[
                                            "op",
                                            "boolean"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "params",
                                              ["and"]
                                            ],
                                            /* [] */0
                                          ]
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                        } else {
                          return /* :: */[
                                  curr,
                                  /* [] */0
                                ];
                        }
                      }))));
}

var HavingSerializer = {
  toCrdb: toCrdb$5,
  toJson: toJson$5,
  toURLEncodedJson: toURLEncodedJson$3,
  toPrivateJson: toPrivateJson$3,
  fromJson: fromJson$5,
  fromURLEncodedJson: fromURLEncodedJson$3
};

function cmp$2(c0, c1) {
  var c = Caml_primitive.caml_string_compare(Belt_Option.getWithDefault(Curry._1($$let$2.getAttribute, c0), ""), Belt_Option.getWithDefault(Curry._1($$let$2.getAttribute, c1), ""));
  if (c !== 0) {
    return c;
  }
  var c$1 = Caml_primitive.caml_string_compare(Curry._1($$let$2.getPipe, c0), Curry._1($$let$2.getPipe, c1));
  if (c$1 !== 0) {
    return c$1;
  }
  var c$2 = Caml_primitive.caml_string_compare(toString$2(Curry._1($$let$2.getOperation, c0)), toString$2(Curry._1($$let$2.getOperation, c1)));
  if (c$2 !== 0) {
    return c$2;
  } else {
    return Caml_primitive.caml_string_compare(Curry._1($$let$2.getOperation, c0)[0], Curry._1($$let$2.getOperation, c1)[0]);
  }
}

var HavingComparable = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

var partial_arg$4 = HavingList$BsConsole.Make;

function partial_arg$5(param, param$1) {
  return partial_arg$4(Having, param, param$1);
}

var $$let$3 = partial_arg$5(HavingSerializer, HavingComparable);

var Havings_empty = $$let$3.empty;

var Havings_toJson = $$let$3.toJson;

var Havings_toURLEncodedJson = $$let$3.toURLEncodedJson;

var Havings_toPrivateJson = $$let$3.toPrivateJson;

var Havings_fromJson = $$let$3.fromJson;

var Havings_fromURLEncodedJson = $$let$3.fromURLEncodedJson;

var Havings_eq = $$let$3.eq;

var Havings_eq$prime = $$let$3.eq$prime;

var Havings_toCrdb = $$let$3.toCrdb;

var Havings_add = $$let$3.add;

var Havings_remove = $$let$3.remove;

var Havings_toArray = $$let$3.toArray;

var Havings_fromArray = $$let$3.fromArray;

var Havings_keep = $$let$3.keep;

var Havings = {
  empty: Havings_empty,
  toJson: Havings_toJson,
  toURLEncodedJson: Havings_toURLEncodedJson,
  toPrivateJson: Havings_toPrivateJson,
  fromJson: Havings_fromJson,
  fromURLEncodedJson: Havings_fromURLEncodedJson,
  eq: Havings_eq,
  eq$prime: Havings_eq$prime,
  toCrdb: Havings_toCrdb,
  add: Havings_add,
  remove: Havings_remove,
  toArray: Havings_toArray,
  fromArray: Havings_fromArray,
  keep: Havings_keep
};

var partial_arg$6 = Aperture$BsConsole.Make;

var Aperture = partial_arg$6(Filters, TimeAperture);

function fromCrdb$1(json) {
  return Json_decode.list(Json_decode.string, json);
}

var Select = {
  empty: Select$BsConsole.empty,
  add: Select$BsConsole.add,
  union: Select$BsConsole.union,
  remove: Select$BsConsole.remove,
  get: Select$BsConsole.get,
  size: Select$BsConsole.size,
  toArray: Select$BsConsole.toArray,
  fromArray: Select$BsConsole.fromArray,
  toJson: Select$BsConsole.toJson,
  fromJson: Select$BsConsole.fromJson,
  toCrdb: Select$BsConsole.toCrdb,
  eq: Select$BsConsole.eq,
  fromCrdb: fromCrdb$1
};

var include$1 = Factor$BsConsole.Make({
      identity: "fingerprint"
    });

var toCrdb$6 = include$1.toCrdb;

function fromCrdb$2(json) {
  var param = Json_decode.array(Json_decode.string, json);
  if (param.length !== 1) {
    throw [
          Json_decode.DecodeError,
          "Invalid group value"
        ];
  }
  var attribute = param[0];
  if (attribute === "fingerprint") {
    return /* Identity */0;
  } else {
    return /* Custom */[attribute];
  }
}

var Factor_toJson = include$1.toJson;

var Factor_fromJson = include$1.fromJson;

var Factor_toCrdbString = include$1.toCrdbString;

var Factor_eq = include$1.eq;

var Factor = {
  toJson: Factor_toJson,
  fromJson: Factor_fromJson,
  toCrdb: toCrdb$6,
  toCrdbString: Factor_toCrdbString,
  eq: Factor_eq,
  fromCrdb: fromCrdb$2
};

var cmp$3 = Caml_obj.caml_compare;

var AggregationComparable = Belt_Id.MakeComparable({
      cmp: cmp$3
    });

var Defaults$1 = {
  numBins: 32,
  distributionHead: 3
};

function toString$3(a) {
  if (typeof a !== "number") {
    if (a.tag) {
      return "distribution";
    } else {
      return "bin";
    }
  }
  switch (a) {
    case /* Histogram */0 :
        return "histogram";
    case /* Head */1 :
        return "head";
    case /* Tail */2 :
        return "tail";
    case /* Sum */3 :
        return "sum";
    case /* Unique */4 :
        return "unique";
    case /* Min */5 :
        return "min";
    case /* Max */6 :
        return "max";
    case /* Mean */7 :
        return "mean";
    case /* Range */8 :
        return "range";
    case /* Count */9 :
        return "count";
    
  }
}

function fromString$2(a) {
  switch (a) {
    case "bin" :
        return /* Bin */Block.__(0, [undefined]);
    case "count" :
        return /* Count */9;
    case "distribution" :
        return /* Distribution */Block.__(1, [undefined]);
    case "head" :
        return /* Head */1;
    case "histogram" :
        return /* Histogram */0;
    case "max" :
        return /* Max */6;
    case "mean" :
        return /* Mean */7;
    case "min" :
        return /* Min */5;
    case "range" :
        return /* Range */8;
    case "sum" :
        return /* Sum */3;
    case "tail" :
        return /* Tail */2;
    case "unique" :
        return /* Unique */4;
    default:
      return ;
  }
}

function toJson$6(a) {
  if (typeof a === "number") {
    switch (a) {
      case /* Histogram */0 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "histogram",
                      /* [] */0
                    ]);
      case /* Head */1 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "head",
                      /* [] */0
                    ]);
      case /* Tail */2 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "tail",
                      /* [] */0
                    ]);
      case /* Sum */3 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "sum",
                      /* [] */0
                    ]);
      case /* Unique */4 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "unique",
                      /* [] */0
                    ]);
      case /* Min */5 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "min",
                      /* [] */0
                    ]);
      case /* Max */6 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "max",
                      /* [] */0
                    ]);
      case /* Mean */7 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "mean",
                      /* [] */0
                    ]);
      case /* Range */8 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "range",
                      /* [] */0
                    ]);
      case /* Count */9 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "count",
                      /* [] */0
                    ]);
      
    }
  } else {
    if (a.tag) {
      return ["distribution"];
    }
    var bins = a[0];
    if (bins !== undefined) {
      return [
              "bin",
              bins
            ];
    } else {
      return [
              "bin",
              32
            ];
    }
  }
}

function toCrdb$7(a) {
  if (typeof a === "number") {
    switch (a) {
      case /* Histogram */0 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "histogram",
                      /* [] */0
                    ]);
      case /* Head */1 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "head",
                      /* [] */0
                    ]);
      case /* Tail */2 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "tail",
                      /* [] */0
                    ]);
      case /* Sum */3 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "sum",
                      /* [] */0
                    ]);
      case /* Unique */4 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "unique",
                      /* [] */0
                    ]);
      case /* Min */5 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "min",
                      /* [] */0
                    ]);
      case /* Max */6 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "max",
                      /* [] */0
                    ]);
      case /* Mean */7 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "mean",
                      /* [] */0
                    ]);
      case /* Range */8 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "range",
                      /* [] */0
                    ]);
      case /* Count */9 :
          return Json_encode.list((function (prim) {
                        return prim;
                      }), /* :: */[
                      "count",
                      /* [] */0
                    ]);
      
    }
  } else {
    if (a.tag) {
      var bins = a[0];
      if (bins !== undefined) {
        return [
                "distribution",
                bins
              ];
      } else {
        return [
                "distribution",
                3
              ];
      }
    }
    var bins$1 = a[0];
    if (bins$1 !== undefined) {
      return [
              "bin",
              bins$1
            ];
    } else {
      return [
              "bin",
              32
            ];
    }
  }
}

function fromJson$6(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  var result = Json_decode.tuple2(Json_decode.string, Json_decode.$$int, json);
                  switch (result[0]) {
                    case "bin" :
                        return /* Bin */Block.__(0, [result[1]]);
                    case "distribution" :
                        return /* Distribution */Block.__(1, [3]);
                    default:
                      throw [
                            Json_decode.DecodeError,
                            "Failed to decode operation"
                          ];
                  }
                }),
              /* :: */[
                (function (json) {
                    var result = Json_decode.list(Json_decode.string, json);
                    if (result) {
                      switch (result[0]) {
                        case "count" :
                            if (!result[1]) {
                              return /* Count */9;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "distribution" :
                            if (result[1]) {
                              throw [
                                    Json_decode.DecodeError,
                                    "Failed to decode operation"
                                  ];
                            }
                            return /* Distribution */Block.__(1, [3]);
                        case "head" :
                            if (!result[1]) {
                              return /* Head */1;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "histogram" :
                            if (!result[1]) {
                              return /* Histogram */0;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "max" :
                            if (!result[1]) {
                              return /* Max */6;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "mean" :
                            if (!result[1]) {
                              return /* Mean */7;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "min" :
                            if (!result[1]) {
                              return /* Min */5;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "range" :
                            if (!result[1]) {
                              return /* Range */8;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "sum" :
                            if (!result[1]) {
                              return /* Sum */3;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "tail" :
                            if (!result[1]) {
                              return /* Tail */2;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        case "unique" :
                            if (!result[1]) {
                              return /* Unique */4;
                            }
                            throw [
                                  Json_decode.DecodeError,
                                  "Failed to decode operation"
                                ];
                        default:
                          throw [
                                Json_decode.DecodeError,
                                "Failed to decode operation"
                              ];
                      }
                    } else {
                      throw [
                            Json_decode.DecodeError,
                            "Failed to decode operation"
                          ];
                    }
                  }),
                /* [] */0
              ]
            ], json);
}

var StatsOperation = {
  Defaults: Defaults$1,
  toString: toString$3,
  fromString: fromString$2,
  toJson: toJson$6,
  toCrdb: toCrdb$7,
  fromJson: fromJson$6
};

var StatsAggregation = Aggregation$BsConsole.Make({
      toString: toString$3,
      toJson: toJson$6,
      fromJson: fromJson$6,
      toCrdb: toCrdb$7
    });

var partial_arg$7 = Fold$BsConsole.Make;

var Stats = partial_arg$7(StatsAggregation, AggregationComparable);

var partial_arg_defaultSelect = /* Select */Block.__(5, [
    "timestamp",
    /* Descending */1
  ]);

var partial_arg_defaultAggregate = /* Count */Block.__(1, [/* Descending */1]);

var partial_arg$8 = {
  defaultSelect: partial_arg_defaultSelect,
  defaultAggregate: partial_arg_defaultAggregate
};

var partial_arg$9 = SortOrder$BsConsole.Make;

var Sort$prime = (function (param) {
      return partial_arg$9(partial_arg$8, param);
    })(Fold);

var toCrdb$8 = Sort$prime.toCrdb;

function crdbToSortOrder(fold, json) {
  var name = Json_decode.field("name", Json_decode.string, json);
  var param = Json_decode.field("ordering", Json_decode.string, json);
  var ordering;
  switch (param) {
    case "ascending" :
        ordering = /* Ascending */0;
        break;
    case "descending" :
        ordering = /* Descending */1;
        break;
    default:
      ordering = /* Descending */1;
  }
  var param$1 = name.split(";");
  var len = param$1.length;
  if (len >= 4) {
    return ;
  }
  switch (len) {
    case 0 :
        return ;
    case 1 :
        var attribute = param$1[0];
        if (Belt_Option.isNone(fold)) {
          return /* Select */Block.__(5, [
                    attribute,
                    ordering
                  ]);
        } else {
          return ;
        }
    case 2 :
        var attribute$1 = param$1[0];
        if (attribute$1 === "") {
          var match = param$1[1];
          switch (match) {
            case "count" :
                return /* Count */Block.__(1, [ordering]);
            case "group" :
                return /* Group */Block.__(0, [ordering]);
            default:
              
          }
        }
        var index = param$1[1];
        if (fold !== undefined) {
          var index$1 = Caml_format.caml_int_of_string(index);
          var aggregation = Belt_Array.get(Belt_Array.keep(Curry._1(toArray, Caml_option.valFromOption(fold)), (function (agg) {
                      return Curry._1(Aggregation.getAttribute, agg) === attribute$1;
                    })), index$1);
          if (aggregation !== undefined) {
            var match$1 = Curry._1(Aggregation.getOperation, Caml_option.valFromOption(aggregation));
            if (typeof match$1 !== "number") {
              return ;
            }
            switch (match$1) {
              case /* Head */1 :
                  return /* Head */Block.__(2, [
                            attribute$1,
                            ordering
                          ]);
              case /* Tail */2 :
                  return /* Tail */Block.__(3, [
                            attribute$1,
                            ordering
                          ]);
              case /* Sum */3 :
                  return /* Sum */Block.__(10, [
                            attribute$1,
                            ordering
                          ]);
              case /* Unique */4 :
                  return /* Unique */Block.__(6, [
                            attribute$1,
                            ordering
                          ]);
              case /* Min */5 :
                  return /* Min */Block.__(7, [
                            attribute$1,
                            ordering
                          ]);
              case /* Max */6 :
                  return /* Max */Block.__(8, [
                            attribute$1,
                            ordering
                          ]);
              case /* Mean */7 :
                  return /* Mean */Block.__(9, [
                            attribute$1,
                            ordering
                          ]);
              case /* Histogram */0 :
              case /* Range */8 :
              case /* Count */9 :
                  return ;
              
            }
          } else {
            throw [
                  Json_decode.DecodeError,
                  "Failed to decode sort - index out of bounds"
                ];
          }
        } else {
          throw [
                Json_decode.DecodeError,
                "Failed to decode sort name"
              ];
        }
        break;
    case 3 :
        var attribute$2 = param$1[0];
        var match$2 = param$1[2];
        switch (match$2) {
          case "0" :
              return /* Range */Block.__(4, [
                        attribute$2,
                        /* Start */0,
                        ordering
                      ]);
          case "1" :
              return /* Range */Block.__(4, [
                        attribute$2,
                        /* Finish */1,
                        ordering
                      ]);
          default:
            return ;
        }
    
  }
}

function fromCrdb$3(fold, json) {
  var param = Json_decode.array((function (param) {
          return crdbToSortOrder(fold, param);
        }), json);
  if (param.length !== 1) {
    throw [
          Json_decode.DecodeError,
          "Invalid ordering"
        ];
  }
  return param[0];
}

var Sort_toJson = Sort$prime.toJson;

var Sort_fromJson = Sort$prime.fromJson;

var Sort_eq = Sort$prime.eq;

var Sort_defaultSelect = Sort$prime.defaultSelect;

var Sort_defaultAggregate = Sort$prime.defaultAggregate;

var Sort_getOrdering = Sort$prime.getOrdering;

var Sort_setOrdering = Sort$prime.setOrdering;

var Sort_toggleOrdering = Sort$prime.toggleOrdering;

var Sort_getRangeParam = Sort$prime.getRangeParam;

var Sort_getAttribute = Sort$prime.getAttribute;

var Sort_getAggString = Sort$prime.getAggString;

var Sort = {
  toCrdb: toCrdb$8,
  toJson: Sort_toJson,
  fromJson: Sort_fromJson,
  eq: Sort_eq,
  defaultSelect: Sort_defaultSelect,
  defaultAggregate: Sort_defaultAggregate,
  getOrdering: Sort_getOrdering,
  setOrdering: Sort_setOrdering,
  toggleOrdering: Sort_toggleOrdering,
  getRangeParam: Sort_getRangeParam,
  getAttribute: Sort_getAttribute,
  getAggString: Sort_getAggString,
  crdbToSortOrder: crdbToSortOrder,
  fromCrdb: fromCrdb$3
};

var Paginate = Paginate$BsConsole.Make({
      limit: 20
    });

var partial_arg$10 = {
  empty: Select$BsConsole.empty,
  add: Select$BsConsole.add,
  union: Select$BsConsole.union,
  remove: Select$BsConsole.remove,
  size: Select$BsConsole.size,
  toJson: Select$BsConsole.toJson,
  fromJson: Select$BsConsole.fromJson,
  toCrdb: Select$BsConsole.toCrdb,
  eq: Select$BsConsole.eq
};

var partial_arg$11 = Query$BsConsole.Make;

function partial_arg$12(param, param$1) {
  return partial_arg$11(Aperture, TimeAperture, Fold, Factor, partial_arg$10, Sort, param, param$1);
}

var Query = partial_arg$12(Paginate, Havings);

var partial_arg$13 = {
  empty: Select$BsConsole.empty,
  add: Select$BsConsole.add,
  union: Select$BsConsole.union,
  remove: Select$BsConsole.remove,
  size: Select$BsConsole.size,
  toJson: Select$BsConsole.toJson,
  fromJson: Select$BsConsole.fromJson,
  toCrdb: Select$BsConsole.toCrdb,
  eq: Select$BsConsole.eq
};

var partial_arg$14 = Query$BsConsole.Make;

function partial_arg$15(param, param$1) {
  return partial_arg$14(Aperture, TimeAperture, Fold, Factor, partial_arg$13, Sort, param, param$1);
}

var MetricsQuery$prime = partial_arg$15(Paginate, Havings);

function toCrdb$9(t) {
  if (t.tag) {
    var pagination = t[3];
    var sort = t[2];
    return Json_encode.object_(Belt_List.concatMany([
                    Curry._1(Aperture.toCrdbTuple2, t[0]),
                    /* :: */[
                      /* tuple */[
                        "select",
                        Select$BsConsole.toCrdb(t[1])
                      ],
                      /* [] */0
                    ],
                    sort !== undefined ? /* :: */[
                        /* tuple */[
                          "order",
                          [Curry._2(toCrdb$8, sort, empty)]
                        ],
                        /* [] */0
                      ] : /* [] */0,
                    pagination !== undefined ? /* :: */[
                        /* tuple */[
                          "offset",
                          Curry._1(Paginate.getOffset, Caml_option.valFromOption(pagination))
                        ],
                        /* :: */[
                          /* tuple */[
                            "limit",
                            250
                          ],
                          /* [] */0
                        ]
                      ] : /* [] */0,
                    /* :: */[
                      /* tuple */[
                        "table",
                        "metrics"
                      ],
                      /* [] */0
                    ]
                  ]));
  }
  var pagination$1 = t[4];
  var sort$1 = t[3];
  var fold = t[2];
  var factor = t[1];
  return Json_encode.object_(Belt_List.concatMany([
                  Curry._1(Aperture.toCrdbTuple2, t[0]),
                  factor !== undefined ? /* :: */[
                      /* tuple */[
                        "group",
                        [Curry._1(toCrdb$6, factor)]
                      ],
                      /* [] */0
                    ] : /* [] */0,
                  /* :: */[
                    /* tuple */[
                      "fold",
                      Curry._1(toCrdb$2, fold)
                    ],
                    /* [] */0
                  ],
                  sort$1 !== undefined ? /* :: */[
                      /* tuple */[
                        "order",
                        [Curry._2(toCrdb$8, sort$1, fold)]
                      ],
                      /* [] */0
                    ] : /* [] */0,
                  pagination$1 !== undefined ? /* :: */[
                      /* tuple */[
                        "offset",
                        Curry._1(Paginate.getOffset, Caml_option.valFromOption(pagination$1))
                      ],
                      /* :: */[
                        /* tuple */[
                          "limit",
                          250
                        ],
                        /* [] */0
                      ]
                    ] : /* [] */0,
                  Belt_Option.getWithDefault(Belt_Option.map(t[5], (function (havings) {
                              if (Curry._2($$let$3.eq, $$let$3.empty, havings)) {
                                return /* [] */0;
                              } else {
                                return /* :: */[
                                        /* tuple */[
                                          "having",
                                          Curry._2($$let$3.toCrdb, havings, fold)
                                        ],
                                        /* [] */0
                                      ];
                              }
                            })), /* [] */0),
                  /* :: */[
                    /* tuple */[
                      "table",
                      "metrics"
                    ],
                    /* [] */0
                  ]
                ]));
}

var MetricsQuery_toJson = MetricsQuery$prime.toJson;

var MetricsQuery_toPrivateJson = MetricsQuery$prime.toPrivateJson;

var MetricsQuery_fromJson = MetricsQuery$prime.fromJson;

var MetricsQuery_toCrdb_ = MetricsQuery$prime.toCrdb_;

var MetricsQuery_toCrdbTimeless = MetricsQuery$prime.toCrdbTimeless;

var MetricsQuery_eq = MetricsQuery$prime.eq;

var MetricsQuery_setPagination = MetricsQuery$prime.setPagination;

var MetricsQuery_setAperture = MetricsQuery$prime.setAperture;

var MetricsQuery_setSort = MetricsQuery$prime.setSort;

var MetricsQuery_freeze = MetricsQuery$prime.freeze;

var MetricsQuery_getAperture = MetricsQuery$prime.getAperture;

var MetricsQuery_getFactor = MetricsQuery$prime.getFactor;

var MetricsQuery_getSort = MetricsQuery$prime.getSort;

var MetricsQuery_getFold = MetricsQuery$prime.getFold;

var MetricsQuery_getSelect = MetricsQuery$prime.getSelect;

var MetricsQuery_getHavings = MetricsQuery$prime.getHavings;

var MetricsQuery = {
  toJson: MetricsQuery_toJson,
  toPrivateJson: MetricsQuery_toPrivateJson,
  fromJson: MetricsQuery_fromJson,
  toCrdb_: MetricsQuery_toCrdb_,
  toCrdbTimeless: MetricsQuery_toCrdbTimeless,
  eq: MetricsQuery_eq,
  setPagination: MetricsQuery_setPagination,
  setAperture: MetricsQuery_setAperture,
  setSort: MetricsQuery_setSort,
  freeze: MetricsQuery_freeze,
  getAperture: MetricsQuery_getAperture,
  getFactor: MetricsQuery_getFactor,
  getSort: MetricsQuery_getSort,
  getFold: MetricsQuery_getFold,
  getSelect: MetricsQuery_getSelect,
  getHavings: MetricsQuery_getHavings,
  toCrdb: toCrdb$9
};

var getAperture = Query.getAperture;

function toCrdb$10(t) {
  var durationFilter = Belt_Array.getBy(Curry._1($$let$1.toArray, Curry._1(Aperture.getFilters, Curry._1(getAperture, t))), (function (filter) {
          var match = Curry._1($$let.getAttribute, filter);
          var match$1 = Curry._1($$let.getOperation, filter);
          if (match === "_duration" && !match$1.tag) {
            return true;
          } else {
            return false;
          }
        }));
  var table;
  if (durationFilter !== undefined) {
    var match = Curry._1($$let.getOperation, Caml_option.valFromOption(durationFilter));
    if (match.tag) {
      table = "unique_aggregations_coarse";
    } else {
      var match$1 = match[0];
      table = typeof match$1 === "number" || match$1[0] !== 3654863 || !Caml_int64.le(match$1[1], Caml_int64.mk(3600, 0)) ? "unique_aggregations_coarse" : "unique_aggregations";
    }
  } else {
    table = "unique_aggregations_coarse";
  }
  if (t.tag) {
    var pagination = t[3];
    var sort = t[2];
    return Json_encode.object_(Belt_List.concatMany([
                    Curry._1(Aperture.toCrdbTimelessTuple2, t[0]),
                    /* :: */[
                      /* tuple */[
                        "select",
                        Select$BsConsole.toCrdb(t[1])
                      ],
                      /* [] */0
                    ],
                    sort !== undefined ? /* :: */[
                        /* tuple */[
                          "order",
                          [Curry._2(toCrdb$8, sort, empty)]
                        ],
                        /* [] */0
                      ] : /* [] */0,
                    pagination !== undefined ? /* :: */[
                        /* tuple */[
                          "offset",
                          Curry._1(Paginate.getOffset, Caml_option.valFromOption(pagination))
                        ],
                        /* :: */[
                          /* tuple */[
                            "limit",
                            250
                          ],
                          /* [] */0
                        ]
                      ] : /* [] */0,
                    /* :: */[
                      /* tuple */[
                        "table",
                        table
                      ],
                      /* [] */0
                    ]
                  ]));
  }
  var pagination$1 = t[4];
  var sort$1 = t[3];
  var fold = t[2];
  var factor = t[1];
  return Json_encode.object_(Belt_List.concatMany([
                  Curry._1(Aperture.toCrdbTimelessTuple2, t[0]),
                  factor !== undefined ? /* :: */[
                      /* tuple */[
                        "group",
                        [Curry._1(toCrdb$6, factor)]
                      ],
                      /* [] */0
                    ] : /* [] */0,
                  /* :: */[
                    /* tuple */[
                      "fold",
                      Curry._1(toCrdb$2, fold)
                    ],
                    /* [] */0
                  ],
                  sort$1 !== undefined ? /* :: */[
                      /* tuple */[
                        "order",
                        [Curry._2(toCrdb$8, sort$1, fold)]
                      ],
                      /* [] */0
                    ] : /* [] */0,
                  pagination$1 !== undefined ? /* :: */[
                      /* tuple */[
                        "offset",
                        Curry._1(Paginate.getOffset, Caml_option.valFromOption(pagination$1))
                      ],
                      /* :: */[
                        /* tuple */[
                          "limit",
                          250
                        ],
                        /* [] */0
                      ]
                    ] : /* [] */0,
                  Belt_Option.getWithDefault(Belt_Option.map(t[5], (function (havings) {
                              if (Curry._2($$let$3.eq, $$let$3.empty, havings)) {
                                return /* [] */0;
                              } else {
                                return /* :: */[
                                        /* tuple */[
                                          "having",
                                          Curry._2($$let$3.toCrdb, havings, fold)
                                        ],
                                        /* [] */0
                                      ];
                              }
                            })), /* [] */0),
                  /* :: */[
                    /* tuple */[
                      "table",
                      table
                    ],
                    /* [] */0
                  ]
                ]));
}

var UniqueAggregationsQuery_toJson = Query.toJson;

var UniqueAggregationsQuery_toPrivateJson = Query.toPrivateJson;

var UniqueAggregationsQuery_fromJson = Query.fromJson;

var UniqueAggregationsQuery_toCrdb_ = Query.toCrdb_;

var UniqueAggregationsQuery_toCrdbTimeless = Query.toCrdbTimeless;

var UniqueAggregationsQuery_eq = Query.eq;

var UniqueAggregationsQuery_setPagination = Query.setPagination;

var UniqueAggregationsQuery_setAperture = Query.setAperture;

var UniqueAggregationsQuery_setSort = Query.setSort;

var UniqueAggregationsQuery_freeze = Query.freeze;

var UniqueAggregationsQuery_getFactor = Query.getFactor;

var UniqueAggregationsQuery_getSort = Query.getSort;

var UniqueAggregationsQuery_getFold = Query.getFold;

var UniqueAggregationsQuery_getSelect = Query.getSelect;

var UniqueAggregationsQuery_getHavings = Query.getHavings;

var UniqueAggregationsQuery = {
  toJson: UniqueAggregationsQuery_toJson,
  toPrivateJson: UniqueAggregationsQuery_toPrivateJson,
  fromJson: UniqueAggregationsQuery_fromJson,
  toCrdb_: UniqueAggregationsQuery_toCrdb_,
  toCrdbTimeless: UniqueAggregationsQuery_toCrdbTimeless,
  eq: UniqueAggregationsQuery_eq,
  setPagination: UniqueAggregationsQuery_setPagination,
  setAperture: UniqueAggregationsQuery_setAperture,
  setSort: UniqueAggregationsQuery_setSort,
  freeze: UniqueAggregationsQuery_freeze,
  getAperture: getAperture,
  getFactor: UniqueAggregationsQuery_getFactor,
  getSort: UniqueAggregationsQuery_getSort,
  getFold: UniqueAggregationsQuery_getFold,
  getSelect: UniqueAggregationsQuery_getSelect,
  getHavings: UniqueAggregationsQuery_getHavings,
  toCrdb: toCrdb$10
};

function granularityValueForAperture(a) {
  var hasTimestampFilter = ListUtils$BsConsole.contains(Belt_List.fromArray(Belt_Array.map(Curry._1($$let$1.toArray, Curry._1(Aperture.getFilters, a)), $$let.getAttribute)), "timestamp");
  if (hasTimestampFilter) {
    return "custom";
  } else {
    return Curry._1(TimeAperture.getString, Curry._1(Aperture.getTimeAperture, a));
  }
}

function addRequiredFolds(q) {
  if (q.tag) {
    return q;
  }
  var havings = q[5];
  var hs = Belt_Option.map(havings, (function (havings) {
          return Curry._1($$let$3.toArray, havings);
        }));
  if (hs === undefined) {
    return q;
  }
  if (hs.length === 0) {
    return q;
  }
  var nextFold = Belt_Array.reduce(Belt_Option.getWithDefault(hs, []), q[2], (function (acc, h) {
          var match = Curry._1($$let$2.getAttribute, h);
          var match$1 = Curry._1($$let$2.getPipe, h);
          if (match === undefined) {
            return acc;
          }
          switch (match$1) {
            case "head" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Head */1
                              ]), acc);
            case "max" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Max */6
                              ]), acc);
            case "mean" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Mean */7
                              ]), acc);
            case "min" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Min */5
                              ]), acc);
            case "sum" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Sum */3
                              ]), acc);
            case "tail" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Tail */2
                              ]), acc);
            case "unique" :
                return Curry._2(add, Curry._1(Aggregation.create, /* tuple */[
                                match,
                                /* Unique */4
                              ]), acc);
            default:
              return acc;
          }
        }));
  return /* Aggregate */Block.__(0, [
            q[0],
            q[1],
            nextFold,
            q[3],
            q[4],
            havings
          ]);
}

function toCrdb$11(q) {
  return Curry._1(Query.toCrdb_, addRequiredFolds(q));
}

function toCrdbTimeless(q) {
  return Curry._1(Query.toCrdbTimeless, addRequiredFolds(q));
}

var QueryManager = {
  addRequiredFolds: addRequiredFolds,
  toCrdb: toCrdb$11,
  toCrdbTimeless: toCrdbTimeless
};

function smellyParseCRDB(json) {
  return Belt_List.head(Json_decode.field("response", (function (json) {
                    return Json_decode.field("values", (function (json) {
                                  return Json_decode.list((function (json) {
                                                return Json_decode.tuple3(Json_decode.string, (function (json) {
                                                              return Json_decode.list((function (json) {
                                                                            return Json_decode.list((function (json) {
                                                                                          return Json_decode.field("vals", (function (json) {
                                                                                                        return Json_decode.list((function (json) {
                                                                                                                      return Json_decode.tuple2(Json_decode.string, Json_decode.$$int, json);
                                                                                                                    }), json);
                                                                                                      }), json);
                                                                                        }), json);
                                                                          }), json);
                                                            }), Json_decode.$$int, json);
                                              }), json);
                                }), json);
                  }), json));
}

function ensureValidTimeRange(param) {
  var d$prime = param[1];
  var d = param[0];
  var __x = Caml_int64.of_float(d.valueOf());
  var __x$1 = Caml_int64.of_float(d$prime.valueOf());
  var match = Caml_int64.compare(Caml_int64.div(__x, Caml_int64.mk(1000, 0)), Caml_int64.div(__x$1, Caml_int64.mk(1000, 0)));
  switch (match) {
    case -1 :
        var __x$2 = Caml_int64.of_float(d.valueOf());
        var __x$3 = Caml_int64.of_float(d$prime.valueOf());
        return /* tuple */[
                Caml_int64.div(__x$2, Caml_int64.mk(1000, 0)),
                Caml_int64.div(__x$3, Caml_int64.mk(1000, 0))
              ];
    case 0 :
        var __x$4 = Caml_int64.of_float(d.valueOf());
        var __x$5 = Caml_int64.of_float(d$prime.valueOf());
        var __x$6 = Caml_int64.div(__x$5, Caml_int64.mk(1000, 0));
        return /* tuple */[
                Caml_int64.div(__x$4, Caml_int64.mk(1000, 0)),
                Caml_int64.add(__x$6, Caml_int64.one)
              ];
    case 1 :
        var __x$7 = Caml_int64.of_float(d$prime.valueOf());
        var __x$8 = Caml_int64.of_float(d.valueOf());
        return /* tuple */[
                Caml_int64.div(__x$7, Caml_int64.mk(1000, 0)),
                Caml_int64.div(__x$8, Caml_int64.mk(1000, 0))
              ];
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "crdb.re",
              1602,
              9
            ]
          ];
  }
}

function granularityStringToTimeAperture(s) {
  switch (s) {
    case "all" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* All */6
                    ]));
    case "day" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Day */1
                    ]));
    case "first-seen-all" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* All */6
                    ]));
    case "first-seen-day" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Day */1
                    ]));
    case "first-seen-hour" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Hour */0
                    ]));
    case "first-seen-month" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Month */3
                    ]));
    case "first-seen-quarter" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Quarter */4
                    ]));
    case "first-seen-week" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Week */2
                    ]));
    case "first-seen-year" :
        return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                      /* Floating */0,
                      /* Year */5
                    ]));
    case "hour" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Hour */0
                    ]));
    case "month" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Month */3
                    ]));
    case "quarter" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Quarter */4
                    ]));
    case "week" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Week */2
                    ]));
    case "year" :
        return Curry._1(TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Year */5
                    ]));
    default:
      return Curry._1(TimeAperture.create, /* RelativeFirstSeen */Block.__(2, [
                    /* Floating */0,
                    /* Week */2
                  ]));
  }
}

function addDeletedZeroFilter(filters, apply) {
  if (apply) {
    return Curry._2($$let$1.add, Curry._1($$let.create, /* tuple */[
                    "_deleted",
                    /* Equal */Block.__(0, [/* `Int */[
                          3654863,
                          Int64.zero
                        ]])
                  ]), filters);
  } else {
    return filters;
  }
}

function addFilter(aperture, filters) {
  var filterMap = Belt_List.toArray(filters).reduce((function (filterMap, param) {
          return Curry._2($$let$1.add, Curry._1($$let.create, /* tuple */[
                          param[0],
                          param[1]
                        ]), filterMap);
        }), Curry._1(Aperture.getFilters, aperture));
  return Curry._2(Aperture.setFilters, filterMap, aperture);
}

var setTimeAperture = Aperture.setTimeAperture;

var setFilters = Aperture.setFilters;

exports.Operation = Operation;
exports.Aggregation = Aggregation;
exports.Comparable = Comparable;
exports.Serialization = Serialization;
exports.Fold = Fold;
exports.Granularity = Granularity;
exports.FilterOperation = FilterOperation;
exports.HavingOperation = HavingOperation;
exports.getFilterOpereationOptions = getFilterOpereationOptions;
exports.TimeAperture = TimeAperture;
exports.Filter = Filter;
exports.FilterSerializer = FilterSerializer;
exports.FilterComparable = FilterComparable;
exports.Filters = Filters;
exports.Having = Having;
exports.HavingSerializer = HavingSerializer;
exports.HavingComparable = HavingComparable;
exports.Havings = Havings;
exports.Aperture = Aperture;
exports.Select = Select;
exports.Factor = Factor;
exports.AggregationComparable = AggregationComparable;
exports.StatsOperation = StatsOperation;
exports.StatsAggregation = StatsAggregation;
exports.Stats = Stats;
exports.Sort$prime = Sort$prime;
exports.Sort = Sort;
exports.Paginate = Paginate;
exports.Query = Query;
exports.MetricsQuery$prime = MetricsQuery$prime;
exports.MetricsQuery = MetricsQuery;
exports.UniqueAggregationsQuery = UniqueAggregationsQuery;
exports.granularityValueForAperture = granularityValueForAperture;
exports.QueryManager = QueryManager;
exports.setTimeAperture = setTimeAperture;
exports.setFilters = setFilters;
exports.smellyParseCRDB = smellyParseCRDB;
exports.ensureValidTimeRange = ensureValidTimeRange;
exports.granularityStringToTimeAperture = granularityStringToTimeAperture;
exports.addDeletedZeroFilter = addDeletedZeroFilter;
exports.addFilter = addFilter;
/* Aggregation Not a pure module */
