// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function identity(x) {
  return x;
}

function unwrap(json) {
  return Belt_List.head(Json_decode.list(identity, json));
}

exports.identity = identity;
exports.unwrap = unwrap;
/* No side effect */
