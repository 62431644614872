// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../../util.js");

var empty = {
  description: "",
  buckets: /* [] */0,
  disabled: false
};

function fromJson(json) {
  return {
          description: Belt_Option.mapWithDefault(Json_decode.optional(Json_decode.at(/* :: */[
                        "console",
                        /* :: */[
                          "description",
                          /* [] */0
                        ]
                      ], Json_decode.string), json), "", Util$BsConsole.identity),
          buckets: Belt_List.fromArray(Js_dict.entries(Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("buckets", (function (param) {
                                            return Json_decode.dict((function (json) {
                                                          return {
                                                                  attributes: Json_decode.field("attributes", (function (param) {
                                                                          return Json_decode.list(Json_decode.string, param);
                                                                        }), json),
                                                                  set: Json_decode.optional((function (param) {
                                                                          return Json_decode.field("set", (function (param) {
                                                                                        return Json_decode.dict(Json_decode.string, param);
                                                                                      }), param);
                                                                        }), json),
                                                                  timeout: Json_decode.optional((function (param) {
                                                                          return Json_decode.field("timeout", Json_decode.$$int, param);
                                                                        }), json)
                                                                };
                                                        }), param);
                                          }), param);
                            }), json), { }, Util$BsConsole.identity))),
          disabled: Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("disabled", Json_decode.bool, param);
                    }), json), false, Util$BsConsole.identity)
        };
}

function bucketToJson(bucket) {
  var set = Belt_Option.map(bucket.set, (function (set) {
          return Js_dict.map((function (setStr) {
                        return setStr;
                      }), set);
        }));
  var props_000 = /* tuple */[
    "attributes",
    Json_encode.list((function (prim) {
            return prim;
          }), bucket.attributes)
  ];
  var props = /* :: */[
    props_000,
    /* [] */0
  ];
  var props$1 = set !== undefined ? /* :: */[
      /* tuple */[
        "set",
        Caml_option.valFromOption(set)
      ],
      props
    ] : props;
  var timeout = bucket.timeout;
  return Json_encode.object_(timeout !== undefined ? /* :: */[
                /* tuple */[
                  "timeout",
                  timeout
                ],
                props$1
              ] : props$1);
}

function toJson(metadata) {
  var $$console = Json_encode.object_(/* :: */[
        /* tuple */[
          "description",
          metadata.description
        ],
        /* [] */0
      ]);
  var bucketsDict = Js_dict.fromList(Belt_List.map(metadata.buckets, (function (param) {
              return /* tuple */[
                      param[0],
                      bucketToJson(param[1])
                    ];
            })));
  var props_000 = /* tuple */[
    "buckets",
    bucketsDict
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "console",
      $$console
    ],
    /* [] */0
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "disabled",
                metadata.disabled
              ],
              props
            ]);
}

function fromString(meta) {
  try {
    return fromJson(JSON.parse(meta));
  }
  catch (_e){
    return ;
  }
}

function getMetaJson(descriptionOpt, metadata, param) {
  var description = descriptionOpt !== undefined ? descriptionOpt : "";
  var meta;
  if (metadata !== undefined) {
    try {
      meta = fromJson(JSON.parse(metadata));
    }
    catch (_e){
      meta = empty;
    }
  } else {
    meta = empty;
  }
  return toJson({
              description: description,
              buckets: meta.buckets,
              disabled: meta.disabled
            });
}

exports.empty = empty;
exports.fromJson = fromJson;
exports.bucketToJson = bucketToJson;
exports.toJson = toJson;
exports.fromString = fromString;
exports.getMetaJson = getMetaJson;
/* Util-BsConsole Not a pure module */
