// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Col2$BsConsole = require("./components/Col2.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Input = require("@material-ui/core/Input");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var UserManagement$BsConsole = require("./UserManagement.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Search = require("@material-ui/icons/Search");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var MenuItem = require("@material-ui/core/MenuItem");
var MoreVert = require("@material-ui/icons/MoreVert");
var CardActions = require("@material-ui/core/CardActions");
var CardContent = require("@material-ui/core/CardContent");
var InputAdornment = require("@material-ui/core/InputAdornment");
var CircularProgress = require("@material-ui/core/CircularProgress");

var iconButtonLabel = Css.style(/* :: */[
      Css.unsafe("fontSize", "inherit"),
      /* :: */[
        Css.unsafe("color", "inherit"),
        /* [] */0
      ]
    ]);

function iconButtonRoot(primary) {
  return Css.style(/* :: */[
              Css.fontSize(Css.rem(1)),
              /* :: */[
                Css.important(Css.padding(Css.px(4))),
                /* :: */[
                  Css.color(Css.hex(primary)),
                  /* [] */0
                ]
              ]
            ]);
}

function iconButtonClasses(primary) {
  return {
          root: iconButtonRoot(primary),
          label: iconButtonLabel
        };
}

var paper = Css.style(/* :: */[
      Css.selector(".BtTableContainer", /* :: */[
            Css.maxHeight(Css.px(800)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector(".BtUsersTable_Header-action", /* :: */[
              Css.width(Css.px(64)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var dialog = Css.style(/* :: */[
      Css.maxWidth(Css.px(500)),
      /* :: */[
        Css.minWidth(Css.px(350)),
        /* [] */0
      ]
    ]);

var footerRow = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
          /* :: */[
            Css.padding(Css.px(20)),
            /* [] */0
          ]
        ]
      ]
    ]);

function multiSelectRow(selected) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.blue)),
                      /* :: */[
                        Css.padding2(/* zero */-789508312, Css.px(20)),
                        /* :: */[
                          Css.transition(100, undefined, undefined, "height"),
                          /* :: */[
                            Css.transition(100, undefined, undefined, "display"),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                selected.length !== 0 ? Css.style(/* :: */[
                        Css.height(Css.px(48)),
                        /* [] */0
                      ]) : Css.style(/* :: */[
                        Css.height(/* zero */-789508312),
                        /* :: */[
                          Css.important(Css.display(/* none */-922086728)),
                          /* [] */0
                        ]
                      ]),
                /* [] */0
              ]
            ]);
}

var userDepList = Css.style(/* :: */[
      Css.unsafe("listStyle", "none"),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
        /* :: */[
          Css.borderRadius(Css.px(4)),
          /* :: */[
            Css.height(Css.px(200)),
            /* :: */[
              Css.overflowY(/* auto */-1065951377),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.padding2(Css.px(8), Css.px(16)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var userDepListItem = Css.style(/* :: */[
      Css.selector("details > summary", /* :: */[
            Css.unsafe("listStyleType", "'+ '"),
            /* :: */[
              Css.hover(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.padding2(Css.px(8), /* zero */-789508312),
                /* [] */0
              ]
            ]
          ]),
      /* :: */[
        Css.selector("details[open] > summary", /* :: */[
              Css.unsafe("listStyleType", "'- '"),
              /* [] */0
            ]),
        /* :: */[
          Css.selector("summary::marker", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey4)),
                /* :: */[
                  Css.paddingRight(Css.rem(1)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var failureItem = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* [] */0
    ]);

var Style = {
  iconButtonLabel: iconButtonLabel,
  iconButtonRoot: iconButtonRoot,
  iconButtonClasses: iconButtonClasses,
  paper: paper,
  dialog: dialog,
  footerRow: footerRow,
  multiSelectRow: multiSelectRow,
  userDepList: userDepList,
  userDepListItem: userDepListItem,
  failureItem: failureItem
};

function UsersTable$UsersDependenciesList(Props) {
  var dependentUsers = Props.dependentUsers;
  return React.createElement("ul", {
              className: userDepList
            }, Belt_Array.map(dependentUsers, (function (param) {
                    var username = param[0];
                    return React.createElement("li", {
                                key: username,
                                className: userDepListItem
                              }, React.createElement("details", undefined, React.createElement("summary", undefined, I18N$BsConsole.showSkip(username)), React.createElement("ul", undefined, Belt_Array.map(param[1], (function (dependency) {
                                              return React.createElement("li", {
                                                          key: dependency
                                                        }, React.createElement(BtTypography$BsConsole.make, {
                                                              variant: /* Body1 */8,
                                                              children: I18N$BsConsole.showSkip(dependency),
                                                              key: dependency
                                                            }));
                                            })))));
                  })));
}

var UsersDependenciesList = {
  make: UsersTable$UsersDependenciesList
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* ShowDependencies */0 :
        var userOwnedEntities = action[2];
        var users = action[1];
        var currentUser = action[0];
        var dependentUsers = Belt_Array.keepMap(users, (function (user) {
                var deps = UserManagement$BsConsole.getUserDependencies(user.uid, userOwnedEntities);
                var deps$1 = user.uid === currentUser.uid ? Belt_Array.concat(["Cannot delete yourself"], deps) : deps;
                if (deps$1.length !== 0) {
                  return /* tuple */[
                          user.username,
                          deps$1
                        ];
                }
                
              }));
        var usersWithoutDeps = Belt_Array.keep(users, (function (user) {
                var deps = UserManagement$BsConsole.getUserDependencies(user.uid, userOwnedEntities);
                var deps$1 = user.uid === currentUser.uid ? Belt_Array.concat(["Cannot delete yourself"], deps) : deps;
                return deps$1.length === 0;
              }));
        var view = dependentUsers.length !== 0 ? /* UserDependenciesWarning */Block.__(0, [
              dependentUsers,
              usersWithoutDeps,
              userOwnedEntities
            ]) : /* DeleteUsersWarning */Block.__(1, [
              users,
              userOwnedEntities
            ]);
        return {
                view: view
              };
    case /* ContinueDeletingUsers */1 :
        return {
                view: /* DeleteUsersWarning */Block.__(1, [
                    action[0],
                    action[1]
                  ])
              };
    case /* Failure */2 :
        return {
                view: /* Failure */Block.__(3, [action[0]])
              };
    case /* Success */3 :
        return {
                view: /* Success */Block.__(2, [action[0]])
              };
    
  }
}

var initialState = {
  view: /* InitialLoad */0
};

function UsersTable$MultiDeleteUsersDialog(Props) {
  var currentUser = Props.currentUser;
  var users = Props.users;
  var token = Props.token;
  var onClose = Props.onClose;
  var onDeleteSuccess = Props.onDeleteSuccess;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var view = match[0].view;
  React.useEffect((function () {
          if (typeof view === "number" && view === 0) {
            var arg = UserManagement$BsConsole.getOwnedItemsTask(undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      return Curry._1(dispatch, /* Failure */Block.__(2, ["Something went wrong fetching dependencies"]));
                    } else {
                      return Curry._1(dispatch, /* ShowDependencies */Block.__(0, [
                                    currentUser,
                                    users,
                                    resp[0]
                                  ]));
                    }
                  }));
          }
          
        }), [view]);
  var tmp;
  var exit = 0;
  if (typeof view === "number") {
    exit = 1;
  } else {
    switch (view.tag | 0) {
      case /* UserDependenciesWarning */0 :
          var userOwnedEntities = view[2];
          var usersWithoutDeps = view[1];
          var dependentUsers = view[0];
          tmp = React.createElement(React.Fragment, undefined, React.createElement(CardContent.default, {
                    children: React.createElement(Col2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.maxHeight(Css.px(300)),
                                /* :: */[
                                  Css.overflowY(/* auto */-1065951377),
                                  /* [] */0
                                ]
                              ]),
                          children: dependentUsers.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: dependentUsers.length > 1 ? I18N$BsConsole.show(undefined, "The following users cannot be deleted because they own existing configuration objects. To remove these dependencies from each user you will need to either delete the dependent objects or transfer them to another owner.") : I18N$BsConsole.show(undefined, "The following user cannot be deleted because they own existing configuration objects. To remove these dependencies from each user you will need to either delete the dependent objects or transfer them to another owner.")
                                    }), React.createElement(UsersTable$UsersDependenciesList, {
                                      dependentUsers: dependentUsers
                                    })) : null
                        })
                  }), React.createElement(CardActions.default, {
                    className: Css.style(/* :: */[
                          Css.justifyContent(/* flexEnd */924268066),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Button.default, {
                        color: "primary",
                        onClick: onClose,
                        children: I18N$BsConsole.show(undefined, "Cancel")
                      }), usersWithoutDeps.length !== 0 ? React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          className: Css.style(/* :: */[
                                Css.marginLeft(Css.rem(1)),
                                /* [] */0
                              ]),
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* ContinueDeletingUsers */Block.__(1, [
                                            usersWithoutDeps,
                                            userOwnedEntities
                                          ]));
                            }),
                          children: usersWithoutDeps.length > 1 ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Continue deleting ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " users",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "Continue deleting %s users"
                                    ]), String(usersWithoutDeps.length)) : I18N$BsConsole.show(undefined, "Continue deleting 1 user")
                        }) : null));
          break;
      case /* DeleteUsersWarning */1 :
          var userOwnedEntities$1 = view[1];
          var users$1 = view[0];
          tmp = React.createElement(React.Fragment, undefined, React.createElement(CardContent.default, {
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: users$1.length > 1 ? I18N$BsConsole.show(undefined, "Are you sure you would like to delete the following users?") : I18N$BsConsole.show(undefined, "Are you sure you would like to delete the following user?")
                      }), React.createElement("ul", undefined, Belt_Array.map(users$1, (function (user) {
                              return React.createElement("li", {
                                          key: user.username
                                        }, Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " - ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ]),
                                                  "%s - %s"
                                                ]), user.username, user.email));
                            })))), React.createElement(CardActions.default, {
                    className: Css.style(/* :: */[
                          Css.justifyContent(/* flexEnd */924268066),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Button.default, {
                        color: "primary",
                        onClick: onClose,
                        children: I18N$BsConsole.show(undefined, "Cancel")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.rem(1)),
                              /* [] */0
                            ]),
                        onClick: (function (param) {
                            var arg = UserManagement$BsConsole.getDeleteMultipleUsersTask(users$1, userOwnedEntities$1);
                            return Curry._2((function (param) {
                                            return (function (param$1, param$2) {
                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                              });
                                          })(token), undefined, (function (resp) {
                                          if (resp.tag) {
                                            return Curry._1(dispatch, /* Failure */Block.__(2, ["Something went wrong"]));
                                          } else {
                                            return Curry._1(dispatch, /* Success */Block.__(3, [resp[0]]));
                                          }
                                        }));
                          }),
                        children: users$1.length > 1 ? I18N$BsConsole.show(undefined, "Yes, permanately delete users") : I18N$BsConsole.show(undefined, "Yes, permanately delete user")
                      })));
          break;
      case /* Success */2 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(CardContent.default, {
                    children: React.createElement("ul", undefined, Belt_Array.map(view[0], (function (result) {
                                if (result.tag) {
                                  var errorMessage = result[1];
                                  var username = result[0];
                                  return React.createElement("li", {
                                              key: "failure_" + (username + ("-" + errorMessage)),
                                              className: failureItem
                                            }, Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                      /* String_literal */Block.__(11, [
                                                          "Failed to delete ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* String_literal */Block.__(11, [
                                                                  " (",
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* Char_literal */Block.__(12, [
                                                                          /* ")" */41,
                                                                          /* End_of_format */0
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ]),
                                                      "Failed to delete %s (%s)"
                                                    ]), username, errorMessage));
                                }
                                var username$1 = result[0];
                                return React.createElement("li", {
                                            key: "success_" + username$1
                                          }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "Successfully deleted ",
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "Successfully deleted %s"
                                                  ]), username$1));
                              })))
                  }), React.createElement(CardActions.default, {
                    className: Css.style(/* :: */[
                          Css.justifyContent(/* flexEnd */924268066),
                          /* [] */0
                        ]),
                    children: React.createElement(Button.default, {
                          color: "primary",
                          onClick: (function ($$event) {
                              Curry._1(onClose, $$event);
                              return Curry._1(onDeleteSuccess, undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Close")
                        })
                  }));
          break;
      case /* Failure */3 :
          tmp = null;
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          children: React.createElement(CircularProgress.default, {
                size: 100,
                color: "primary"
              })
        });
  }
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: dialog
              },
              maxWidth: "lg",
              disableBackdropClick: true,
              children: null
            }, React.createElement(CardContent.default, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading6 */5,
                        children: I18N$BsConsole.show(undefined, "Delete users")
                      })
                }), React.createElement(Divider.default, { }), tmp);
}

var MultiDeleteUsersDialog = {
  reducer: reducer,
  initialState: initialState,
  make: UsersTable$MultiDeleteUsersDialog
};

function UsersTable$UserActionMenu(Props) {
  var onEdit = Props.onEdit;
  var onDelete = Props.onDelete;
  var user = Props.user;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var onClose = function (param) {
    return Curry._1(setAnchorEl, (function (param) {
                  
                }));
  };
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  var primary = palette.primary.main;
  return React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                  disableRipple: true,
                  ariaLabel: "User settings",
                  classes: iconButtonClasses(primary),
                  onClick: (function ($$event) {
                      var el = $$event.currentTarget;
                      if (!(el == null)) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      return Caml_option.some(el);
                                    }));
                      }
                      
                    }),
                  children: React.createElement(MoreVert.default, { })
                }), anchorEl !== undefined ? React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    onClose: onClose,
                    children: null
                  }, React.createElement(MenuItem.default, {
                        disableRipple: true,
                        onClick: (function (param) {
                            Curry._1(onEdit, user);
                            return Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Edit user")
                      }), React.createElement(MenuItem.default, {
                        disableRipple: true,
                        onClick: (function (param) {
                            Curry._1(onDelete, user);
                            return Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Delete user")
                      })) : null);
}

var UserActionMenu = {
  make: UsersTable$UserActionMenu
};

function getColumns(currentUser, onEdit, onDelete) {
  var defaultColumns = [
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Username"), "username", (function (row) {
            return /* `String */[
                    -976970511,
                    row.username
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Email"), "email", (function (row) {
            return /* `String */[
                    -976970511,
                    row.email
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Role"), "role", (function (row) {
            return /* `String */[
                    -976970511,
                    Belt_Option.getWithDefault(row.role, "")
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined)
  ];
  var lastLoginColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Last login"), "last login", (function (row) {
          var lastSeenStr = Belt_Option.getWithDefault(Belt_Option.flatMap(row.metadata, (function (meta) {
                      return Belt_Option.map(meta.lastSeen, (function (lastSeen) {
                                    return DateFns.format("YYYY-MM-DD HH:mm", lastSeen);
                                  }));
                    })), "-");
          return /* `String */[
                  -976970511,
                  lastSeenStr
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined);
  var actionsColumn = BtTableColumn$BsConsole.make("", "action", (function (row) {
          return /* `Element */[
                  -744106340,
                  React.createElement(UsersTable$UserActionMenu, {
                        onEdit: onEdit,
                        onDelete: onDelete,
                        user: row
                      })
                ];
        }), true, undefined, undefined, undefined, undefined, undefined, "BtUsersTable_Header-action", undefined, /* Right */-57574468, undefined);
  var match = currentUser.role;
  if (match === undefined) {
    return Belt_Array.concat(defaultColumns, [actionsColumn]);
  }
  switch (match) {
    case "admin" :
        return Belt_Array.concat(defaultColumns, [
                    lastLoginColumn,
                    actionsColumn
                  ]);
    case "guest" :
        return defaultColumns;
    default:
      return Belt_Array.concat(defaultColumns, [actionsColumn]);
  }
}

var initialSortState = {
  columnName: "username",
  direction: /* Desc */759138929
};

function UsersTable(Props) {
  var currentUser = Props.currentUser;
  var users = Props.users;
  var onEdit = Props.onEdit;
  var onDeleteSuccess = Props.onDeleteSuccess;
  var loading = Props.loading;
  var token = Props.token;
  var match = React.useState((function () {
          return "";
        }));
  var setSearchValue = match[1];
  var searchValue = match[0];
  var match$1 = React.useState((function () {
          return initialSortState;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var match$2 = React.useState((function () {
          return [];
        }));
  var setSelected = match$2[1];
  var selected = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setDialog = match$3[1];
  var dialog = match$3[0];
  var rows = React.useMemo((function () {
          if (searchValue.trim() === "") {
            return users;
          } else {
            return Fuzz$BsConsole.fuzz(searchValue.trim(), users, (function (user) {
                          return user.username + (" " + user.email);
                        }));
          }
        }), /* tuple */[
        searchValue,
        users
      ]);
  var onDelete = function (user) {
    return Curry._1(setDialog, (function (param) {
                  return /* MultiDelete */[[user]];
                }));
  };
  var columns = React.useMemo((function () {
          return getColumns(currentUser, onEdit, onDelete);
        }), ([]));
  var sortedRows = React.useMemo((function () {
          if (sortingState !== undefined) {
            return BtEnhancedTable$BsConsole.defaultRowSort(rows, columns, sortingState);
          } else {
            return rows;
          }
        }), /* tuple */[
        rows,
        sortingState
      ]);
  React.useEffect((function () {
          var arg = UserManagement$BsConsole.getOwnedItemsTask(undefined);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  console.log(resp);
                  
                }));
          
        }), ([]));
  React.useEffect((function () {
          if (rows.length !== users.length) {
            Curry._1(setSortingState, (function (param) {
                    
                  }));
          }
          
        }), [rows]);
  React.useEffect((function () {
          Curry._1(setSelected, (function (param) {
                  return [];
                }));
          
        }), [users]);
  var getRowId = React.useCallback((function (row) {
          return row.username;
        }), ([]));
  var handleOnSelectedChange = React.useCallback((function (selected$prime) {
          return Curry._1(setSelected, (function (param) {
                        return selected$prime;
                      }));
        }), [setSelected]);
  var match$4 = currentUser.role;
  var match$5 = currentUser.superuser;
  var isAdmin = match$5 !== 1 && match$4 !== "admin" ? false : true;
  var tmp = {
    columns: columns,
    rows: sortedRows,
    getRowId: getRowId,
    showGrid: false,
    onSortChange: (function (sortingState) {
        return Curry._1(setSortingState, (function (param) {
                      return sortingState;
                    }));
      }),
    resizeableColumns: false,
    fixedLeftColumns: ["username"],
    loading: loading,
    containerClassName: Css.style(/* :: */[
          Css.selector(".BtTableSelectCell", /* :: */[
                Css.width(Css.px(48)),
                /* [] */0
              ]),
          /* [] */0
        ])
  };
  if (sortingState !== undefined) {
    tmp.sortingState = Caml_option.valFromOption(sortingState);
  }
  var tmp$1 = isAdmin ? handleOnSelectedChange : undefined;
  if (tmp$1 !== undefined) {
    tmp.onSelectedChange = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = isAdmin ? selected : undefined;
  if (tmp$2 !== undefined) {
    tmp.selected = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.Paper.make, {
                  className: paper,
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.height(Css.px(48)),
                            /* :: */[
                              Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                              /* :: */[
                                Css.padding2(/* zero */-789508312, Css.px(20)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(Input.default, {
                          className: Css.style(/* :: */[
                                Css.width(Css.px(300)),
                                /* [] */0
                              ]),
                          value: searchValue,
                          placeholder: I18N$BsConsole.get(undefined, "Search by username or email"),
                          startAdornment: React.createElement(InputAdornment.default, {
                                position: "start",
                                children: React.createElement(Search.default, { })
                              }),
                          onChange: (function ($$event) {
                              var value = $$event.currentTarget.value;
                              if (value !== undefined) {
                                return Curry._1(setSearchValue, (function (param) {
                                              return value;
                                            }));
                              }
                              
                            })
                        }), selected.length !== 0 ? React.createElement(Button.default, {
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.red)),
                                  /* :: */[
                                    Css.hover(/* :: */[
                                          Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]),
                            onClick: (function (_event) {
                                var selectedUsers = Belt_Array.keep(users, (function (user) {
                                        return selected.includes(user.username);
                                      }));
                                return Curry._1(setDialog, (function (param) {
                                              return /* MultiDelete */[selectedUsers];
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Delete users")
                          }) : null), React.createElement(BtEnhancedTable$BsConsole.make, tmp), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: selected.length !== 0 ? /* spaceBetween */516682146 : /* flexEnd */924268066,
                      className: footerRow,
                      children: null
                    }, selected.length !== 0 ? React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body2 */9,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* [] */0
                                ]),
                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " users selected",
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "%s users selected"
                                    ]), Numeral$BsConsole.format("0,0", String(selected.length)))
                          }) : React.createElement("div", undefined), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: sortedRows.length < users.length ? Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Showing ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " of ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " total users",
                                                          /* End_of_format */0
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                      "Showing %s of %s total users"
                                    ]), Numeral$BsConsole.format("0,0", String(sortedRows.length)), Numeral$BsConsole.format("0,0", String(users.length))) : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " total users",
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "%s total users"
                                    ]), Numeral$BsConsole.format("0,0", String(users.length)))
                        }))), dialog !== undefined ? React.createElement(UsersTable$MultiDeleteUsersDialog, {
                    currentUser: currentUser,
                    users: dialog[0],
                    token: token,
                    onClose: (function (param) {
                        return Curry._1(setDialog, (function (param) {
                                      
                                    }));
                      }),
                    onDeleteSuccess: (function (param) {
                        Curry._1(setSearchValue, (function (param) {
                                return "";
                              }));
                        return Curry._1(onDeleteSuccess, undefined);
                      })
                  }) : null);
}

var make = React.memo(UsersTable);

function make$1(currentUser, users, loading, onEdit, onDeleteSuccess, token, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(make, {
              currentUser: currentUser,
              users: users,
              onEdit: onEdit,
              onDeleteSuccess: onDeleteSuccess,
              loading: loading,
              token: token
            }, _children);
}

var Jsx2 = {
  make: make$1
};

exports.Style = Style;
exports.UsersDependenciesList = UsersDependenciesList;
exports.MultiDeleteUsersDialog = MultiDeleteUsersDialog;
exports.UserActionMenu = UserActionMenu;
exports.getColumns = getColumns;
exports.initialSortState = initialSortState;
exports.make = make;
exports.Jsx2 = Jsx2;
/* iconButtonLabel Not a pure module */
