// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Colors$BsConsole = require("./Colors.js");
var BtTypography$BsConsole = require("./BtTypography.js");

function cellStyle(align) {
  return Css.style(/* :: */[
              Css.display(/* tableCell */793912528),
              /* :: */[
                Css.textAlign(align),
                /* :: */[
                  Css.verticalAlign(/* inherit_ */-601204732),
                  /* :: */[
                    Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                    /* :: */[
                      Css.padding(Css.px(8)),
                      /* :: */[
                        Css.whiteSpace(/* nowrap */867913355),
                        /* :: */[
                          Css.overflow(/* hidden */-862584982),
                          /* :: */[
                            Css.textOverflow(/* ellipsis */166888785),
                            /* :: */[
                              Css.unsafe("backgroundColor", "inherit"),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var visiuallyHidden = Css.style(/* :: */[
      Css.height(Css.px(1)),
      /* :: */[
        Css.width(Css.px(1)),
        /* :: */[
          Css.margin(Css.px(-1)),
          /* :: */[
            Css.padding(/* zero */-789508312),
            /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.top(Css.px(20)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var make = React.forwardRef((function (Props, theRef) {
        var title = Props.title;
        var colSpan = Props.colSpan;
        var classNameOpt = Props.className;
        var alignOpt = Props.align;
        var onDrag = Props.onDrag;
        var onDragStart = Props.onDragStart;
        var onDragEnd = Props.onDragEnd;
        var onDragEnter = Props.onDragEnter;
        var onDragLeave = Props.onDragLeave;
        var onDragOver = Props.onDragOver;
        var onDrop = Props.onDrop;
        var draggableOpt = Props.draggable;
        var childrenOpt = Props.children;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var align = alignOpt !== undefined ? alignOpt : /* left */-944764921;
        var draggable = draggableOpt !== undefined ? draggableOpt : false;
        var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
        var tmp = {
          ref: theRef,
          className: Css.merge(/* :: */[
                cellStyle(align),
                /* :: */[
                  BtTypography$BsConsole.subtitle2,
                  /* :: */[
                    "BtTableHeaderCell",
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]
                ]
              ]),
          draggable: draggable,
          scope: "col"
        };
        if (title !== undefined) {
          tmp.title = Caml_option.valFromOption(title);
        }
        if (colSpan !== undefined) {
          tmp.colSpan = Caml_option.valFromOption(colSpan);
        }
        if (onDrag !== undefined) {
          tmp.onDrag = Caml_option.valFromOption(onDrag);
        }
        if (onDragEnd !== undefined) {
          tmp.onDragEnd = Caml_option.valFromOption(onDragEnd);
        }
        if (onDragEnter !== undefined) {
          tmp.onDragEnter = Caml_option.valFromOption(onDragEnter);
        }
        if (onDragLeave !== undefined) {
          tmp.onDragLeave = Caml_option.valFromOption(onDragLeave);
        }
        if (onDragOver !== undefined) {
          tmp.onDragOver = Caml_option.valFromOption(onDragOver);
        }
        if (onDragStart !== undefined) {
          tmp.onDragStart = Caml_option.valFromOption(onDragStart);
        }
        if (onDrop !== undefined) {
          tmp.onDrop = Caml_option.valFromOption(onDrop);
        }
        return React.createElement("th", tmp, children);
      }));

var Header = {
  make: make
};

function BtTableCell$HeaderStub(Props) {
  var title = Props.title;
  var colSpan = Props.colSpan;
  var classNameOpt = Props.className;
  Props.align;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    className: Css.merge(/* :: */[
          Css.style(/* :: */[
                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                /* :: */[
                  Css.important(Css.padding(/* zero */-789508312)),
                  /* :: */[
                    Css.unsafe("backgroundColor", "inherit"),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            "BtTableHeaderCell",
            /* :: */[
              "BtTableHeaderStubCell",
              /* :: */[
                className,
                /* [] */0
              ]
            ]
          ]
        ]),
    scope: "col"
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (colSpan !== undefined) {
    tmp.colSpan = Caml_option.valFromOption(colSpan);
  }
  return React.createElement("th", tmp, children);
}

var HeaderStub = {
  make: BtTableCell$HeaderStub
};

function BtTableCell$Data(Props) {
  var title = Props.title;
  var colSpan = Props.colSpan;
  var classNameOpt = Props.className;
  var alignOpt = Props.align;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var align = alignOpt !== undefined ? alignOpt : /* left */-944764921;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    className: Css.merge(/* :: */[
          cellStyle(align),
          /* :: */[
            BtTypography$BsConsole.body2,
            /* :: */[
              "BtTableDataCell",
              /* :: */[
                className,
                /* [] */0
              ]
            ]
          ]
        ])
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (colSpan !== undefined) {
    tmp.colSpan = Caml_option.valFromOption(colSpan);
  }
  return React.createElement("td", tmp, children);
}

var Data = {
  make: BtTableCell$Data
};

function BtTableCell$DataStub(Props) {
  var title = Props.title;
  var colSpan = Props.colSpan;
  var classNameOpt = Props.className;
  Props.align;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    className: Css.merge(/* :: */[
          Css.style(/* :: */[
                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                /* :: */[
                  Css.important(Css.padding(/* zero */-789508312)),
                  /* :: */[
                    Css.unsafe("backgroundColor", "inherit"),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            "BtTableDataCell",
            /* :: */[
              "BtTableDataStubCell",
              /* :: */[
                className,
                /* [] */0
              ]
            ]
          ]
        ])
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (colSpan !== undefined) {
    tmp.colSpan = Caml_option.valFromOption(colSpan);
  }
  return React.createElement("td", tmp, children);
}

var DataStub = {
  make: BtTableCell$DataStub
};

var make$1 = BtTableCell$Data;

exports.cellStyle = cellStyle;
exports.visiuallyHidden = visiuallyHidden;
exports.Header = Header;
exports.HeaderStub = HeaderStub;
exports.Data = Data;
exports.DataStub = DataStub;
exports.make = make$1;
/* visiuallyHidden Not a pure module */
