// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactAutosuggest = require("react-autosuggest");

function make(renderInputComponent, suggestions, onSuggestionsFetchRequested, onSuggestionsClearRequested, getSuggestionValue, renderSuggestion, inputProps, onSuggestionSelected, theme, renderSuggestionsContainer, children) {
  return ReasonReact.wrapJsForReason(ReactAutosuggest, {
              renderInputComponent: renderInputComponent,
              suggestions: suggestions,
              onSuggestionsFetchRequested: onSuggestionsFetchRequested,
              onSuggestionsClearRequested: onSuggestionsClearRequested,
              getSuggestionValue: getSuggestionValue,
              renderSuggestion: renderSuggestion,
              onSuggestionSelected: onSuggestionSelected,
              inputProps: inputProps,
              theme: theme,
              renderSuggestionsContainer: renderSuggestionsContainer
            }, children);
}

exports.make = make;
/* ReasonReact Not a pure module */
