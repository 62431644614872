'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _band = require('./scales/band');

Object.defineProperty(exports, 'scaleBand', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_band).default;
  }
});

var _point = require('./scales/point');

Object.defineProperty(exports, 'scalePoint', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_point).default;
  }
});

var _linear = require('./scales/linear');

Object.defineProperty(exports, 'scaleLinear', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_linear).default;
  }
});

var _time = require('./scales/time');

Object.defineProperty(exports, 'scaleTime', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_time).default;
  }
});

var _utc = require('./scales/utc');

Object.defineProperty(exports, 'scaleUtc', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_utc).default;
  }
});

var _log = require('./scales/log');

Object.defineProperty(exports, 'scaleLog', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_log).default;
  }
});

var _power = require('./scales/power');

Object.defineProperty(exports, 'scalePower', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_power).default;
  }
});

var _ordinal = require('./scales/ordinal');

Object.defineProperty(exports, 'scaleOrdinal', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ordinal).default;
  }
});

var _quantize = require('./scales/quantize');

Object.defineProperty(exports, 'scaleQuantize', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_quantize).default;
  }
});

var _quantile = require('./scales/quantile');

Object.defineProperty(exports, 'scaleQuantile', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_quantile).default;
  }
});

var _threshold = require('./scales/threshold');

Object.defineProperty(exports, 'scaleThreshold', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_threshold).default;
  }
});

var _schemeCategory = require('./scales/color/schemeCategory10');

Object.defineProperty(exports, 'schemeCategory10', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_schemeCategory).default;
  }
});

var _schemeCategory2 = require('./scales/color/schemeCategory20');

Object.defineProperty(exports, 'schemeCategory20', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_schemeCategory2).default;
  }
});

var _schemeCategory20b = require('./scales/color/schemeCategory20b');

Object.defineProperty(exports, 'schemeCategory20b', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_schemeCategory20b).default;
  }
});

var _schemeCategory20c = require('./scales/color/schemeCategory20c');

Object.defineProperty(exports, 'schemeCategory20c', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_schemeCategory20c).default;
  }
});

var _updateScale = require('./util/updateScale');

Object.defineProperty(exports, 'updateScale', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_updateScale).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }