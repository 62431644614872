// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function ofJson(json) {
  return {
          month: Json_decode.optional((function (param) {
                  return Json_decode.field("month", Json_decode.string, param);
                }), json),
          year: Json_decode.optional((function (param) {
                  return Json_decode.field("year", Json_decode.string, param);
                }), json)
        };
}

var Id = {
  ofJson: ofJson
};

function ofJson$1(json) {
  return {
          month: Json_decode.optional((function (param) {
                  return Json_decode.field("month", Json_decode.$$int, param);
                }), json),
          year: Json_decode.optional((function (param) {
                  return Json_decode.field("year", Json_decode.$$int, param);
                }), json)
        };
}

var Price = {
  ofJson: ofJson$1
};

function ofJson$2(json) {
  return {
          id: Json_decode.field("id", ofJson, json),
          isCustom: Json_decode.field("isCustom", Json_decode.bool, json),
          name: Json_decode.field("name", Json_decode.string, json),
          price: Json_decode.field("price", ofJson$1, json)
        };
}

var Plan = {
  Id: Id,
  Price: Price,
  ofJson: ofJson$2
};

function ofJson$3(json) {
  return {
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          text: Json_decode.optional((function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          label: Json_decode.optional((function (param) {
                  return Json_decode.field("label", Json_decode.string, param);
                }), json)
        };
}

var Feature = {
  ofJson: ofJson$3
};

function ofJson$4(json) {
  return {
          features: Json_decode.field("features", (function (param) {
                  return Json_decode.map(Js_dict.values, (function (param) {
                                return Json_decode.dict(ofJson$3, param);
                              }), param);
                }), json),
          period: Json_decode.field("period", Json_decode.string, json),
          plan: Json_decode.field("plan", ofJson$2, json),
          planID: Json_decode.field("plan_id", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          trialEnd: Json_decode.map((function (f) {
                  return new Date(Belt_Option.getWithDefault(f, 0) * 1000);
                }), (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("trialEnd", Json_decode.$$float, param);
                              }), param);
                }), json)
        };
}

var Subscription = {
  ofJson: ofJson$4
};

function ofJson_(json) {
  return {
          current: Json_decode.field("current", ofJson$4, json),
          next: Json_decode.optional((function (param) {
                  return Json_decode.field("next", ofJson$4, param);
                }), json)
        };
}

function ofJson$5(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("data", ofJson_, param);
              }), json);
}

exports.Plan = Plan;
exports.Feature = Feature;
exports.Subscription = Subscription;
exports.ofJson_ = ofJson_;
exports.ofJson = ofJson$5;
/* No side effect */
