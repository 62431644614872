// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Configuration$BsConsole = require("../configuration.js");
var WindowVariables$BsConsole = require("../WindowVariables.js");

var __BACKTRACE_SUBMIT_PATH = "https://submit.backtrace.io";

function getSubmissionUrlOriginTemplate(submitLayerOpt, param) {
  var submitLayer = submitLayerOpt !== undefined ? submitLayerOpt : true;
  var origin = window.location.origin;
  if (submitLayer) {
    if (WindowVariables$BsConsole.maybeReceiveLayer !== undefined) {
      return __BACKTRACE_SUBMIT_PATH;
    } else {
      return origin + ":{port}";
    }
  } else {
    return origin + ":{port}";
  }
}

function getSubmissionUrlTemplate(submitLayerOpt, param) {
  var submitLayer = submitLayerOpt !== undefined ? submitLayerOpt : true;
  var origin = window.location.origin;
  if (submitLayer) {
    if (WindowVariables$BsConsole.maybeReceiveLayer !== undefined) {
      return WindowVariables$BsConsole.maybeReceiveLayer;
    } else {
      return origin + ":{port}/post?format={format}&token={token}";
    }
  } else {
    return origin + ":{port}/post?format={format}&token={token}";
  }
}

function createSubmissionUrl(universeNameOpt, portOpt, tokenOpt, formatOpt, templateOpt, submitLayerOpt, param) {
  var universeName = universeNameOpt !== undefined ? Caml_option.valFromOption(universeNameOpt) : undefined;
  var port = portOpt !== undefined ? Caml_option.valFromOption(portOpt) : undefined;
  var token = tokenOpt !== undefined ? Caml_option.valFromOption(tokenOpt) : undefined;
  var format = formatOpt !== undefined ? Caml_option.valFromOption(formatOpt) : undefined;
  var template = templateOpt !== undefined ? Caml_option.valFromOption(templateOpt) : undefined;
  var submitLayer = submitLayerOpt !== undefined ? submitLayerOpt : true;
  return Belt_Option.getWithDefault(template, getSubmissionUrlTemplate(submitLayer, undefined)).replace("{port}", Belt_Option.getWithDefault(port, "PORT")).replace("{universe}", Belt_Option.getWithDefault(universeName, "UNIVERSE")).replace("{token}", Belt_Option.getWithDefault(token, "TOKEN")).replace("{format}", Belt_Option.getWithDefault(format, "FORMAT"));
}

function createSubmissionUrlFromConfig(config, token, format) {
  var port = Belt_Option.map(Configuration$BsConsole.getPort(undefined, config), (function (prim) {
          return String(prim);
        }));
  var partial_arg = Caml_option.some(format);
  var partial_arg$1 = Caml_option.some(token);
  var partial_arg$2 = Caml_option.some(port);
  var partial_arg$3 = Caml_option.some(config.universe.name);
  return (function (param, param$1, param$2) {
      return createSubmissionUrl(partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2);
    });
}

exports.__BACKTRACE_SUBMIT_PATH = __BACKTRACE_SUBMIT_PATH;
exports.getSubmissionUrlOriginTemplate = getSubmissionUrlOriginTemplate;
exports.getSubmissionUrlTemplate = getSubmissionUrlTemplate;
exports.createSubmissionUrl = createSubmissionUrl;
exports.createSubmissionUrlFromConfig = createSubmissionUrlFromConfig;
/* Configuration-BsConsole Not a pure module */
