// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../RR.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Row$BsConsole = require("../../utilities/Row.js");
var I18N$BsConsole = require("../../I18N.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var Column$BsConsole = require("../../utilities/Column.js");
var Styles$BsConsole = require("../../utilities/Styles.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var AccentCard$BsConsole = require("../../utilities/AccentCard.js");
var Dedupe_Form$BsConsole = require("./Dedupe_Form.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Dedupe_Metrics$BsConsole = require("./Dedupe_Metrics.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");
var Dedupe_RuleList$BsConsole = require("./Dedupe_RuleList.js");
var BacktraceParserGenerator$BsConsole = require("../../BacktraceParserGenerator.js");

var AddRulesetFormContainer = Formality__Form.Make({
      validators: Dedupe_Form$BsConsole.AddRuleset.validators
    });

var labelStyle = Css.style(/* :: */[
      Css.marginRight(Css.px(10)),
      /* :: */[
        Css.width(Css.px(50)),
        /* :: */[
          Css.height(Css.px(35)),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* [] */0
          ]
        ]
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.important(Css.marginBottom(Css.px(15))),
      /* :: */[
        Css.width(Css.px(400)),
        /* :: */[
          Css.opacity(0.8),
          /* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
            /* :: */[
              Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
              /* :: */[
                Css.height(Css.px(35)),
                /* :: */[
                  Css.padding(Css.px(10)),
                  /* :: */[
                    Css.fontSize(Css.px(14)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var optionStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.width(Css.px(115)),
          /* :: */[
            Css.marginRight(Css.px(10)),
            /* :: */[
              Css.marginBottom(Css.px(10)),
              /* :: */[
                Css.justifyContent(/* flexEnd */924268066),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var checkboxLabelStyle = Css.style(/* :: */[
      Css.marginRight(Css.px(10)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* [] */0
          ]
        ]
      ]
    ]);

var headerStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* bold */-1055161979),
        /* :: */[
          Css.marginTop(Css.px(20)),
          /* :: */[
            Css.marginBottom(Css.px(20)),
            /* [] */0
          ]
        ]
      ]
    ]);

var subheaderStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* normal */812216871),
        /* :: */[
          Css.marginTop(Css.px(20)),
          /* :: */[
            Css.marginBottom(Css.px(20)),
            /* [] */0
          ]
        ]
      ]
    ]);

var errorMessageStyle = Css.style(/* :: */[
      Css.textAlign(/* right */-379319332),
      /* :: */[
        Css.marginRight(Css.px(50)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.errorDark)),
          /* :: */[
            Css.fontSize(Css.px(14)),
            /* :: */[
              Css.marginTop(Css.px(-10)),
              /* :: */[
                Css.marginBottom(Css.px(10)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

function decodeRuleObject(json) {
  return {
          actions: Json_decode.field("actions", Json_decode.string, json),
          function_: Json_decode.optional((function (param) {
                  return Json_decode.field("function", Json_decode.string, param);
                }), json),
          source: Json_decode.optional((function (param) {
                  return Json_decode.field("source", Json_decode.string, param);
                }), json),
          object_: Json_decode.optional((function (param) {
                  return Json_decode.field("object", Json_decode.string, param);
                }), json),
          replacement: Json_decode.optional((function (param) {
                  return Json_decode.field("replacement", Json_decode.string, param);
                }), json),
          attribute: Json_decode.optional((function (param) {
                  return Json_decode.field("attribute", Json_decode.string, param);
                }), json)
        };
}

function parseRuleObjectsFromString(rulestring) {
  var parsedRule = Json.parse(rulestring);
  if (parsedRule !== undefined) {
    return Json_decode.list(decodeRuleObject, Caml_option.valFromOption(parsedRule));
  } else {
    return /* [] */0;
  }
}

function transformRulesToCardData(rules) {
  return Belt_List.map(rules, (function (r) {
                return {
                        dataKey: "rulecard-" + Pervasives.string_of_float(Math.random()),
                        functionValue: r.function_,
                        objectValue: r.object_,
                        sourceValue: r.source,
                        replacementValue: r.replacement,
                        attributeValue: r.attribute,
                        actionValue: r.actions
                      };
              }));
}

function platformsToDict(arr) {
  var platforms = { };
  Belt_Array.forEach(arr, (function (a) {
          platforms[a] = a;
          
        }));
  return platforms;
}

function objectRuleToDict(o) {
  var ruleDict = { };
  if (Belt_Option.isSome(o.attribute)) {
    ruleDict["attribute"] = o.attribute;
  }
  if (Belt_Option.isSome(o.replacement)) {
    ruleDict["replacement"] = o.replacement;
  }
  if (Belt_Option.isSome(o.function_)) {
    ruleDict["function"] = o.function_;
  }
  if (Belt_Option.isSome(o.object_)) {
    ruleDict["object"] = o.object_;
  }
  if (Belt_Option.isSome(o.source)) {
    ruleDict["source"] = o.source;
  }
  ruleDict["actions"] = o.actions;
  return ruleDict;
}

function encodeListOfRuleObjectsToListOfDicts(listOfRuleObjects) {
  var __x = Belt_List.toArray(listOfRuleObjects);
  return Belt_Array.map(__x, objectRuleToDict);
}

function convertListToRawRuleString(theList) {
  var ruleObjectsList = Belt_List.map(theList, (function (r) {
          return {
                  actions: r.actionValue,
                  function_: r.functionValue,
                  source: r.sourceValue,
                  object_: r.objectValue,
                  replacement: r.replacementValue,
                  attribute: r.attributeValue
                };
        }));
  var ruleObjectsListEncoded = encodeListOfRuleObjectsToListOfDicts(ruleObjectsList);
  var ruleString = JSON.stringify(ruleObjectsListEncoded);
  if (ruleString !== undefined) {
    return ruleString;
  } else {
    return "[]";
  }
}

function toApiString(t) {
  switch (t) {
    case /* Windows */0 :
        return "windows";
    case /* Macos */1 :
        return "macos";
    case /* Unix */2 :
        return "unix";
    case /* Freebsd */3 :
        return "freebsd";
    case /* Sunos */4 :
        return "sunos";
    case /* Linux */5 :
        return "linux";
    case /* Android */6 :
        return "android";
    case /* Ios */7 :
        return "ios";
    
  }
}

function toUiString(t) {
  switch (t) {
    case /* Windows */0 :
        return "Windows";
    case /* Macos */1 :
        return "MacOS";
    case /* Unix */2 :
        return "Unix";
    case /* Freebsd */3 :
        return "FreeBSD";
    case /* Sunos */4 :
        return "SunOS";
    case /* Linux */5 :
        return "Linux";
    case /* Android */6 :
        return "Android";
    case /* Ios */7 :
        return "iOS";
    
  }
}

function apiStrToUiArr(str) {
  var __x = str.split(" ");
  return Belt_Array.map(__x, (function (item) {
                switch (item) {
                  case "*" :
                      return "*";
                  case "android" :
                      return "Android";
                  case "freebsd" :
                      return "FreeBSD";
                  case "ios" :
                      return "iOS";
                  case "linux" :
                      return "Linux";
                  case "macos" :
                      return "MacOS";
                  case "sunos" :
                      return "SunOS";
                  case "unix" :
                      return "Unix";
                  case "windows" :
                      return "Windows";
                  default:
                    return item;
                }
              }));
}

var PlatformOption = {
  toApiString: toApiString,
  toUiString: toUiString,
  apiStrToUiArr: apiStrToUiArr
};

function toApiString$1(t) {
  switch (t) {
    case /* Csharp */0 :
        return "csharp";
    case /* Python */1 :
        return "python";
    case /* Java */2 :
        return "java";
    case /* Kotlin */3 :
        return "kotlin";
    case /* Swift */4 :
        return "swift";
    case /* Objectivec */5 :
        return "objectivec";
    case /* Nodejs */6 :
        return "nodejs";
    case /* Javascript */7 :
        return "javascript";
    case /* Ruby */8 :
        return "ruby";
    case /* Go */9 :
        return "go";
    case /* C */10 :
        return "c";
    
  }
}

function toUiString$1(t) {
  switch (t) {
    case /* Csharp */0 :
        return "C#";
    case /* Python */1 :
        return "Python";
    case /* Java */2 :
        return "Java";
    case /* Kotlin */3 :
        return "Kotlin";
    case /* Swift */4 :
        return "Swift";
    case /* Objectivec */5 :
        return "Objective-C";
    case /* Nodejs */6 :
        return "NodeJS";
    case /* Javascript */7 :
        return "JavaScript";
    case /* Ruby */8 :
        return "Ruby";
    case /* Go */9 :
        return "Go";
    case /* C */10 :
        return "C/C++";
    
  }
}

function apiStrToUiArr$1(str) {
  var __x = str.split(" ");
  return Belt_Array.map(__x, (function (item) {
                switch (item) {
                  case "*" :
                      return "*";
                  case "c" :
                      return "C/C++";
                  case "csharp" :
                      return "C#";
                  case "go" :
                      return "Go";
                  case "java" :
                      return "Java";
                  case "javascript" :
                      return "JavaScript";
                  case "kotlin" :
                      return "Kotlin";
                  case "nodejs" :
                      return "NodeJS";
                  case "objectivec" :
                      return "Objective-C";
                  case "python" :
                      return "Python";
                  case "ruby" :
                      return "Ruby";
                  case "swift" :
                      return "Swift";
                  default:
                    return item;
                }
              }));
}

var LanguageOption = {
  toApiString: toApiString$1,
  toUiString: toUiString$1,
  apiStrToUiArr: apiStrToUiArr$1
};

var langOptions = /* :: */[
  /* Csharp */0,
  /* :: */[
    /* Python */1,
    /* :: */[
      /* Java */2,
      /* :: */[
        /* Kotlin */3,
        /* :: */[
          /* Swift */4,
          /* :: */[
            /* Objectivec */5,
            /* :: */[
              /* Nodejs */6,
              /* :: */[
                /* Javascript */7,
                /* :: */[
                  /* Ruby */8,
                  /* :: */[
                    /* Go */9,
                    /* :: */[
                      /* C */10,
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var platOptions = /* :: */[
  /* Windows */0,
  /* :: */[
    /* Macos */1,
    /* :: */[
      /* Unix */2,
      /* :: */[
        /* Freebsd */3,
        /* :: */[
          /* Sunos */4,
          /* :: */[
            /* Linux */5,
            /* :: */[
              /* Android */6,
              /* :: */[
                /* Ios */7,
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var component = RR$BsConsole.reducerComponent("Dedupe_FormView-BsConsole");

function make(param, project, token, uid, refetch, hideAddView, hideEditView, ruleObject, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              var generateNewRuleCard = function (ruleObject) {
                var newKey = "rulecard-" + Pervasives.string_of_float(Math.random());
                return {
                        dataKey: newKey,
                        functionValue: ruleObject !== undefined ? ruleObject.function_ : undefined,
                        objectValue: ruleObject !== undefined ? ruleObject.object_ : undefined,
                        sourceValue: ruleObject !== undefined ? ruleObject.source : undefined,
                        replacementValue: ruleObject !== undefined ? ruleObject.replacement : undefined,
                        attributeValue: ruleObject !== undefined ? ruleObject.attribute : undefined,
                        actionValue: ruleObject !== undefined ? ruleObject.actions : "i"
                      };
              };
              var initName = ruleObject !== undefined ? ruleObject.name : "";
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.minHeight(Css.px(350)),
                                        /* :: */[
                                          Css.borderRadius(Css.px(0)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]), undefined, undefined, 0, [ReasonReact.element(undefined, undefined, Curry._3(AddRulesetFormContainer.make, {
                                            name: initName
                                          }, (function (formalityState, param) {
                                              var __x = Belt_List.filter(platOptions, (function (opt) {
                                                      switch (opt) {
                                                        case /* Windows */0 :
                                                            return state.windows;
                                                        case /* Macos */1 :
                                                            return state.macos;
                                                        case /* Unix */2 :
                                                            return state.unix;
                                                        case /* Freebsd */3 :
                                                            return state.freebsd;
                                                        case /* Sunos */4 :
                                                            return state.sunos;
                                                        case /* Linux */5 :
                                                            return state.linux;
                                                        case /* Android */6 :
                                                            return state.android;
                                                        case /* Ios */7 :
                                                            return state.ios;
                                                        
                                                      }
                                                    }));
                                              var selectedPlatforms = Belt_List.map(__x, toApiString);
                                              var numStdSelectedPlat = Belt_List.length(selectedPlatforms);
                                              var selectedPlatforms$1 = Belt_List.reduce(selectedPlatforms, "", (function (accum, curr) {
                                                      if (accum === "") {
                                                        return accum + curr;
                                                      } else {
                                                        return accum + (" " + curr);
                                                      }
                                                    }));
                                              var __x$1 = Belt_List.filter(langOptions, (function (opt) {
                                                      switch (opt) {
                                                        case /* Csharp */0 :
                                                            return state.csharp;
                                                        case /* Python */1 :
                                                            return state.python;
                                                        case /* Java */2 :
                                                            return state.java;
                                                        case /* Kotlin */3 :
                                                            return state.kotlin;
                                                        case /* Swift */4 :
                                                            return state.swift;
                                                        case /* Objectivec */5 :
                                                            return state.objectivec;
                                                        case /* Nodejs */6 :
                                                            return state.nodejs;
                                                        case /* Javascript */7 :
                                                            return state.javascript;
                                                        case /* Ruby */8 :
                                                            return state.ruby;
                                                        case /* Go */9 :
                                                            return state.go;
                                                        case /* C */10 :
                                                            return state.c;
                                                        
                                                      }
                                                    }));
                                              var selectedLanguages = Belt_List.map(__x$1, toApiString$1);
                                              var numStdSelectedLangs = Belt_List.length(selectedLanguages);
                                              var selectedLanguages$1 = Belt_List.reduce(selectedLanguages, "", (function (accum, curr) {
                                                      if (accum === "") {
                                                        return accum + curr;
                                                      } else {
                                                        return accum + (" " + curr);
                                                      }
                                                    }));
                                              var selectedLanguages$2 = numStdSelectedLangs > 0 ? selectedLanguages$1 + (" " + state.languageOther) : state.languageOther;
                                              var selectedPlatforms$2 = numStdSelectedPlat > 0 ? selectedPlatforms$1 + (" " + state.platformOther) : state.platformOther;
                                              var __x$2 = state.rules;
                                              var ruleObjectsList = Belt_List.map(__x$2, (function (r) {
                                                      return {
                                                              actions: r.actionValue,
                                                              function_: r.functionValue,
                                                              source: r.sourceValue,
                                                              object_: r.objectValue,
                                                              replacement: r.replacementValue,
                                                              attribute: r.attributeValue
                                                            };
                                                    }));
                                              var ruleObjectsListEncoded = encodeListOfRuleObjectsToListOfDicts(ruleObjectsList);
                                              var ruleString = JSON.stringify(ruleObjectsListEncoded);
                                              var ruleStringToSend = ruleString !== undefined ? ruleString : "[]";
                                              var endcodeStringOrNull = function (str) {
                                                var ls = str.trim();
                                                if (ls === "") {
                                                  return null;
                                                } else {
                                                  return ls;
                                                }
                                              };
                                              if (ruleObject !== undefined) {
                                                new Promise((function (resolve, param) {
                                                        var arg = BpgTask$BsConsole.Deduplication.updateFieldsByKey(BacktraceParserGenerator$BsConsole.Deduplication.to_key(ruleObject), [
                                                              /* tuple */[
                                                                "languages",
                                                                endcodeStringOrNull(selectedLanguages$2)
                                                              ],
                                                              /* tuple */[
                                                                "platforms",
                                                                endcodeStringOrNull(selectedPlatforms$2)
                                                              ],
                                                              /* tuple */[
                                                                "rules",
                                                                ruleStringToSend
                                                              ],
                                                              /* tuple */[
                                                                "enabled",
                                                                state.rulesetEnabled === true ? 1 : 0
                                                              ]
                                                            ]);
                                                        return Curry._2((function (param) {
                                                                        return (function (param$1, param$2) {
                                                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                          });
                                                                      })(token), undefined, (function (resp) {
                                                                      if (resp.tag) {
                                                                        var error = resp[0];
                                                                        if (error[0] === Task2$BsConsole.CoronerError) {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to update ruleset") + (": " + error[1].message)));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                          return resolve(Caml_option.some(error));
                                                                        } else if (error[0] === BpgTask$BsConsole.BpgError) {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to update ruleset") + (": " + error[2])));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                          return resolve(Caml_option.some(error));
                                                                        } else {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update ruleset. Unknown error has occured."));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetUpdateFailed */3);
                                                                          return resolve(Caml_option.some(error));
                                                                        }
                                                                      }
                                                                      Curry._1(refetch, undefined);
                                                                      Dedupe_Metrics$BsConsole.send(/* RulesetUpdated */2);
                                                                      return resolve(undefined);
                                                                    }));
                                                      }));
                                              } else {
                                                new Promise((function (resolve, param) {
                                                        var arg = BpgTask$BsConsole.Deduplication.create({
                                                              project: project.pid,
                                                              name: formalityState.name,
                                                              id: 0,
                                                              owner: uid,
                                                              languages: selectedLanguages$2,
                                                              platforms: selectedPlatforms$2,
                                                              enabled: state.rulesetEnabled === true ? 1 : 0,
                                                              rules: ruleStringToSend,
                                                              priority: 1,
                                                              __state: undefined,
                                                              __create: undefined,
                                                              __modify: undefined
                                                            });
                                                        return Curry._2((function (param) {
                                                                        return (function (param$1, param$2) {
                                                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                          });
                                                                      })(token), undefined, (function (resp) {
                                                                      if (resp.tag) {
                                                                        var error = resp[0];
                                                                        if (error[0] === Task2$BsConsole.CoronerError) {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to create ruleset") + (": " + error[1].message)));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetCreationFailed */1);
                                                                          return resolve(Caml_option.some(error));
                                                                        } else if (error[0] === BpgTask$BsConsole.BpgError) {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Failed to create ruleset") + (": " + error[2])));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetCreationFailed */1);
                                                                          return resolve(Caml_option.some(error));
                                                                        } else {
                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to create ruleset. Unknown error has occured."));
                                                                          Dedupe_Metrics$BsConsole.send(/* RulesetCreationFailed */1);
                                                                          return resolve(Caml_option.some(error));
                                                                        }
                                                                      }
                                                                      Curry._1(refetch, undefined);
                                                                      Dedupe_Metrics$BsConsole.send(/* RulesetCreated */0);
                                                                      return resolve(undefined);
                                                                    }));
                                                      }));
                                              }
                                              Curry._1(hideAddView, undefined);
                                              Curry._1(hideEditView, undefined);
                                              
                                            }), (function (form) {
                                              var partial_arg = form.submit;
                                              var tmp;
                                              if (ruleObject !== undefined) {
                                                tmp = null;
                                              } else {
                                                var match = Curry._1(form.result, /* Name */0);
                                                var tmp$1;
                                                tmp$1 = match !== undefined && match.tag ? match[0] : "";
                                                tmp = React.createElement("div", {
                                                      className: inputStyle
                                                    }, React.createElement("label", {
                                                          className: labelStyle,
                                                          htmlFor: "add-ruleset-name"
                                                        }, I18N$BsConsole.show(undefined, "Name")), React.createElement("input", {
                                                          className: formStyle,
                                                          id: "add-ruleset-name",
                                                          placeholder: I18N$BsConsole.get(undefined, "Enter name"),
                                                          value: form.state.name,
                                                          onChange: (function ($$event) {
                                                              return Curry._2(form.change, /* Name */0, Curry._2(Dedupe_Form$BsConsole.AddRuleset.Name.update, form.state, $$event.target.value));
                                                            })
                                                        }), React.createElement("div", {
                                                          className: errorMessageStyle
                                                        }, I18N$BsConsole.dynamic(tmp$1)));
                                              }
                                              return React.createElement("form", {
                                                          onSubmit: (function (param) {
                                                              return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                            })
                                                        }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                                                Css.paddingLeft(Css.px(40)),
                                                                                /* :: */[
                                                                                  Css.paddingRight(Css.px(20)),
                                                                                  /* :: */[
                                                                                    Css.paddingTop(Css.px(18)),
                                                                                    /* :: */[
                                                                                      Css.paddingBottom(Css.px(8)),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]), [
                                                                            React.createElement("div", {
                                                                                  className: Css.style(/* :: */[
                                                                                        Css.width(Css.px(200)),
                                                                                        /* [] */0
                                                                                      ])
                                                                                }, React.createElement("div", {
                                                                                      className: Css.style(/* :: */[
                                                                                            Css.fontSize(Css.px(13)),
                                                                                            /* :: */[
                                                                                              Css.fontFamily("Roboto"),
                                                                                              /* :: */[
                                                                                                Css.fontWeight(/* medium */-20425611),
                                                                                                /* [] */0
                                                                                              ]
                                                                                            ]
                                                                                          ])
                                                                                    }, ruleObject !== undefined ? I18N$BsConsole.show(undefined, "Edit ruleset") : I18N$BsConsole.show(undefined, "Create new ruleset"))),
                                                                            ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                                          Css.display(/* flex */-1010954439),
                                                                                          /* :: */[
                                                                                            Css.justifyContent(/* spaceBetween */516682146),
                                                                                            /* :: */[
                                                                                              Css.width(Css.px(100)),
                                                                                              /* :: */[
                                                                                                Css.marginRight(Css.px(115)),
                                                                                                /* :: */[
                                                                                                  Css.height(Css.pct(80)),
                                                                                                  /* [] */0
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]), [
                                                                                      ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                  }, I18N$BsConsole.show(undefined, "Save changes")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", {
                                                                                                      className: Css.style(/* :: */[
                                                                                                            Css.marginRight(Css.px(10)),
                                                                                                            /* [] */0
                                                                                                          ])
                                                                                                    }, ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Save"), undefined, (function (param) {
                                                                                                                return Curry._1(form.submit, undefined);
                                                                                                              }), false, 80, [])))])),
                                                                                      ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                                  }, I18N$BsConsole.show(undefined, "Discard changes")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Cancel"), undefined, (function (param) {
                                                                                                                Curry._1(hideAddView, undefined);
                                                                                                                return Curry._1(hideEditView, undefined);
                                                                                                              }), false, 80, [])))]))
                                                                                    ]))
                                                                          ])),
                                                                  React.createElement("div", {
                                                                        className: Css.style(/* :: */[
                                                                              Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                                                              /* :: */[
                                                                                Css.paddingTop(Css.px(1)),
                                                                                /* [] */0
                                                                              ]
                                                                            ])
                                                                      }),
                                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                                Css.margin(Css.px(20)),
                                                                                /* :: */[
                                                                                  Css.marginLeft(Css.px(40)),
                                                                                  /* :: */[
                                                                                    Css.marginBottom(Css.px(40)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                                            className: Css.style(/* :: */[
                                                                                                  Css.padding(Css.px(5)),
                                                                                                  /* [] */0
                                                                                                ])
                                                                                          }, React.createElement("div", {
                                                                                                className: Css.merge(/* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.marginBottom(Css.px(18)),
                                                                                                            /* :: */[
                                                                                                              Css.width(Css.pct(85)),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]),
                                                                                                      /* :: */[
                                                                                                        Styles$BsConsole.$$Text.subtitle,
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ])
                                                                                              }, React.createElement("i", undefined, I18N$BsConsole.show(undefined, "Please fill out ruleset details and add at least one rule below before saving."))), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [tmp])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                                    React.createElement("div", undefined, React.createElement("div", {
                                                                                                              className: subheaderStyle
                                                                                                            }, I18N$BsConsole.show(undefined, "Operating systems")), React.createElement("div", {
                                                                                                              className: Css.style(/* :: */[
                                                                                                                    Css.display(/* flex */-1010954439),
                                                                                                                    /* :: */[
                                                                                                                      Css.flexWrap(/* wrap */-822134326),
                                                                                                                      /* :: */[
                                                                                                                        Css.width(Css.px(300)),
                                                                                                                        /* [] */0
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ])
                                                                                                            }, React.createElement("div", {
                                                                                                                  key: "ruleset-platform-any",
                                                                                                                  className: optionStyle
                                                                                                                }, React.createElement("label", {
                                                                                                                      className: checkboxLabelStyle,
                                                                                                                      htmlFor: "add-ruleset-platform-any"
                                                                                                                    }, I18N$BsConsole.show(undefined, "Any")), React.createElement("input", {
                                                                                                                      id: "add-ruleset-platform-any",
                                                                                                                      checked: state.platformAny,
                                                                                                                      type: "checkbox",
                                                                                                                      onChange: (function (_event) {
                                                                                                                          return Curry._1(send, /* TogglePlatformAny */1);
                                                                                                                        })
                                                                                                                    })), Belt_List.toArray(Belt_List.map(platOptions, (function (opt) {
                                                                                                                        var str = toApiString(opt);
                                                                                                                        var label = toUiString(opt);
                                                                                                                        var tmp;
                                                                                                                        switch (str) {
                                                                                                                          case "android" :
                                                                                                                              tmp = state.android;
                                                                                                                              break;
                                                                                                                          case "freebsd" :
                                                                                                                              tmp = state.freebsd;
                                                                                                                              break;
                                                                                                                          case "ios" :
                                                                                                                              tmp = state.ios;
                                                                                                                              break;
                                                                                                                          case "linux" :
                                                                                                                              tmp = state.linux;
                                                                                                                              break;
                                                                                                                          case "macos" :
                                                                                                                              tmp = state.macos;
                                                                                                                              break;
                                                                                                                          case "sunos" :
                                                                                                                              tmp = state.sunos;
                                                                                                                              break;
                                                                                                                          case "unix" :
                                                                                                                              tmp = state.unix;
                                                                                                                              break;
                                                                                                                          case "windows" :
                                                                                                                              tmp = state.windows;
                                                                                                                              break;
                                                                                                                          default:
                                                                                                                            tmp = false;
                                                                                                                        }
                                                                                                                        return React.createElement("div", {
                                                                                                                                    key: "ruleset-platform-" + str,
                                                                                                                                    className: optionStyle
                                                                                                                                  }, React.createElement("label", {
                                                                                                                                        className: checkboxLabelStyle,
                                                                                                                                        htmlFor: "add-ruleset-platform-" + str
                                                                                                                                      }, I18N$BsConsole.dynamic(label)), React.createElement("input", {
                                                                                                                                        id: "add-ruleset-platform-" + str,
                                                                                                                                        checked: tmp,
                                                                                                                                        type: "checkbox",
                                                                                                                                        onChange: (function (_event) {
                                                                                                                                            return Curry._1(send, /* SetPlatform */Block.__(1, [str]));
                                                                                                                                          })
                                                                                                                                      }));
                                                                                                                      })))), React.createElement("div", undefined, React.createElement("label", {
                                                                                                                  className: Css.style(/* :: */[
                                                                                                                        Css.marginRight(Css.px(10)),
                                                                                                                        /* :: */[
                                                                                                                          Css.fontSize(Css.px(14)),
                                                                                                                          /* [] */0
                                                                                                                        ]
                                                                                                                      ]),
                                                                                                                  htmlFor: "add-ruleset-platform-other"
                                                                                                                }, I18N$BsConsole.show(undefined, "Other:")), React.createElement("input", {
                                                                                                                  className: Css.merge(/* :: */[
                                                                                                                        formStyle,
                                                                                                                        /* :: */[
                                                                                                                          Css.style(/* :: */[
                                                                                                                                Css.width(Css.px(150)),
                                                                                                                                /* [] */0
                                                                                                                              ]),
                                                                                                                          /* [] */0
                                                                                                                        ]
                                                                                                                      ]),
                                                                                                                  id: "add-ruleset-platform-other",
                                                                                                                  placeholder: "",
                                                                                                                  value: state.platformOther,
                                                                                                                  onChange: (function ($$event) {
                                                                                                                      var value = $$event.target.value;
                                                                                                                      return Curry._1(send, /* SetPlatformOther */Block.__(7, [value]));
                                                                                                                    })
                                                                                                                }))),
                                                                                                    React.createElement("div", undefined, React.createElement("div", {
                                                                                                              className: subheaderStyle
                                                                                                            }, I18N$BsConsole.show(undefined, "Languages")), React.createElement("div", {
                                                                                                              className: Css.style(/* :: */[
                                                                                                                    Css.display(/* flex */-1010954439),
                                                                                                                    /* :: */[
                                                                                                                      Css.flexWrap(/* wrap */-822134326),
                                                                                                                      /* :: */[
                                                                                                                        Css.width(Css.px(300)),
                                                                                                                        /* [] */0
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ])
                                                                                                            }, React.createElement("div", {
                                                                                                                  key: "ruleset-language-any",
                                                                                                                  className: optionStyle
                                                                                                                }, React.createElement("label", {
                                                                                                                      className: checkboxLabelStyle,
                                                                                                                      htmlFor: "add-ruleset-language-any"
                                                                                                                    }, I18N$BsConsole.show(undefined, "Any")), React.createElement("input", {
                                                                                                                      id: "add-ruleset-language-any",
                                                                                                                      checked: state.languageAny,
                                                                                                                      type: "checkbox",
                                                                                                                      onChange: (function (_event) {
                                                                                                                          return Curry._1(send, /* ToggleLanguageAny */2);
                                                                                                                        })
                                                                                                                    })), Belt_List.toArray(Belt_List.map(langOptions, (function (opt) {
                                                                                                                        var str = toApiString$1(opt);
                                                                                                                        var label = toUiString$1(opt);
                                                                                                                        var tmp;
                                                                                                                        switch (str) {
                                                                                                                          case "c" :
                                                                                                                              tmp = state.c;
                                                                                                                              break;
                                                                                                                          case "csharp" :
                                                                                                                              tmp = state.csharp;
                                                                                                                              break;
                                                                                                                          case "go" :
                                                                                                                              tmp = state.go;
                                                                                                                              break;
                                                                                                                          case "java" :
                                                                                                                              tmp = state.java;
                                                                                                                              break;
                                                                                                                          case "javascript" :
                                                                                                                              tmp = state.javascript;
                                                                                                                              break;
                                                                                                                          case "kotlin" :
                                                                                                                              tmp = state.kotlin;
                                                                                                                              break;
                                                                                                                          case "nodejs" :
                                                                                                                              tmp = state.nodejs;
                                                                                                                              break;
                                                                                                                          case "objectivec" :
                                                                                                                              tmp = state.objectivec;
                                                                                                                              break;
                                                                                                                          case "python" :
                                                                                                                              tmp = state.python;
                                                                                                                              break;
                                                                                                                          case "ruby" :
                                                                                                                              tmp = state.ruby;
                                                                                                                              break;
                                                                                                                          case "swift" :
                                                                                                                              tmp = state.swift;
                                                                                                                              break;
                                                                                                                          default:
                                                                                                                            tmp = false;
                                                                                                                        }
                                                                                                                        return React.createElement("div", {
                                                                                                                                    key: "ruleset-language-" + str,
                                                                                                                                    className: optionStyle
                                                                                                                                  }, React.createElement("label", {
                                                                                                                                        className: checkboxLabelStyle,
                                                                                                                                        htmlFor: "add-ruleset-language-" + str
                                                                                                                                      }, I18N$BsConsole.showSkip(label)), React.createElement("input", {
                                                                                                                                        id: "add-ruleset-language-" + str,
                                                                                                                                        checked: tmp,
                                                                                                                                        type: "checkbox",
                                                                                                                                        onChange: (function (_event) {
                                                                                                                                            return Curry._1(send, /* SetLanguage */Block.__(0, [str]));
                                                                                                                                          })
                                                                                                                                      }));
                                                                                                                      })))), React.createElement("div", undefined, React.createElement("label", {
                                                                                                                  className: Css.style(/* :: */[
                                                                                                                        Css.marginRight(Css.px(10)),
                                                                                                                        /* :: */[
                                                                                                                          Css.fontSize(Css.px(14)),
                                                                                                                          /* [] */0
                                                                                                                        ]
                                                                                                                      ]),
                                                                                                                  htmlFor: "add-ruleset-language-other"
                                                                                                                }, I18N$BsConsole.show(undefined, "Other:")), React.createElement("input", {
                                                                                                                  className: Css.merge(/* :: */[
                                                                                                                        formStyle,
                                                                                                                        /* :: */[
                                                                                                                          Css.style(/* :: */[
                                                                                                                                Css.width(Css.px(150)),
                                                                                                                                /* [] */0
                                                                                                                              ]),
                                                                                                                          /* [] */0
                                                                                                                        ]
                                                                                                                      ]),
                                                                                                                  id: "add-ruleset-language-other",
                                                                                                                  placeholder: "",
                                                                                                                  value: state.languageOther,
                                                                                                                  onChange: (function ($$event) {
                                                                                                                      var value = $$event.target.value;
                                                                                                                      return Curry._1(send, /* SetLanguageOther */Block.__(6, [value]));
                                                                                                                    })
                                                                                                                })))
                                                                                                  ])), React.createElement("div", {
                                                                                                className: Css.merge(/* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.marginTop(Css.px(5)),
                                                                                                            /* :: */[
                                                                                                              Css.width(Css.pct(85)),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]),
                                                                                                      /* :: */[
                                                                                                        Styles$BsConsole.$$Text.subtitle,
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ])
                                                                                              }, React.createElement("i", undefined, I18N$BsConsole.show(undefined, "Rules can apply to all platforms and languages, or only specific ones if selected above. Please provide a lowercase, space-separated list if you would like to include other platforms/languages."))), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                                                        Css.marginTop(Css.px(15)),
                                                                                                        /* [] */0
                                                                                                      ]), [
                                                                                                    React.createElement("div", {
                                                                                                          className: Css.style(/* :: */[
                                                                                                                Css.marginTop(Css.px(10)),
                                                                                                                /* [] */0
                                                                                                              ])
                                                                                                        }, React.createElement("label", {
                                                                                                              className: Css.merge(/* :: */[
                                                                                                                    subheaderStyle,
                                                                                                                    /* :: */[
                                                                                                                      Css.style(/* :: */[
                                                                                                                            Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                                                                                            /* :: */[
                                                                                                                              Css.fontSize(Css.px(14)),
                                                                                                                              /* [] */0
                                                                                                                            ]
                                                                                                                          ]),
                                                                                                                      /* [] */0
                                                                                                                    ]
                                                                                                                  ]),
                                                                                                              htmlFor: "ruleset-enabled"
                                                                                                            }, I18N$BsConsole.show(undefined, "Ruleset enabled for all errors"))),
                                                                                                    React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                                  undefined,
                                                                                                                  undefined,
                                                                                                                  Caml_option.some(ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, undefined, undefined, /* `Bool */[
                                                                                                                                737456202,
                                                                                                                                state.rulesetEnabled
                                                                                                                              ], (function (param, param$1) {
                                                                                                                                  return Curry._1(send, /* ToggleRulesetEnabled */0);
                                                                                                                                }), undefined, undefined, undefined, []))),
                                                                                                                  undefined,
                                                                                                                  Css.style(/* :: */[
                                                                                                                        Css.important(Css.marginBottom(Css.px(15))),
                                                                                                                        /* :: */[
                                                                                                                          Css.height(Css.px(35)),
                                                                                                                          /* :: */[
                                                                                                                            Css.padding(Css.px(10)),
                                                                                                                            /* [] */0
                                                                                                                          ]
                                                                                                                        ]
                                                                                                                      ]),
                                                                                                                  "ruleset-enabled",
                                                                                                                  undefined,
                                                                                                                  undefined,
                                                                                                                  []
                                                                                                                ])))
                                                                                                  ])), ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Dedupe_RuleList$BsConsole.make(state.rules, (function (newList) {
                                                                                                                return Curry._1(send, /* UpdateRules */Block.__(3, [newList]));
                                                                                                              }), (function (param) {
                                                                                                                return Curry._1(send, /* AppendNewRule */Block.__(2, [generateNewRuleCard(undefined)]));
                                                                                                              }), (function (k, f, o, a, r, attr, s) {
                                                                                                                var __x = state.rules;
                                                                                                                var newRules = Belt_List.map(__x, (function (rule) {
                                                                                                                        var match = rule.dataKey === k;
                                                                                                                        if (!match) {
                                                                                                                          return rule;
                                                                                                                        }
                                                                                                                        var tmp = f === "" ? undefined : f;
                                                                                                                        var tmp$1 = o === "" ? undefined : o;
                                                                                                                        var tmp$2 = s === "" ? undefined : s;
                                                                                                                        var tmp$3 = r === "" ? undefined : r;
                                                                                                                        var tmp$4 = attr === "" ? undefined : attr;
                                                                                                                        return {
                                                                                                                                dataKey: rule.dataKey,
                                                                                                                                functionValue: tmp,
                                                                                                                                objectValue: tmp$1,
                                                                                                                                sourceValue: tmp$2,
                                                                                                                                replacementValue: tmp$3,
                                                                                                                                attributeValue: tmp$4,
                                                                                                                                actionValue: a
                                                                                                                              };
                                                                                                                      }));
                                                                                                                return Curry._1(send, /* UpdateRules */Block.__(3, [newRules]));
                                                                                                              }), (function (ruleKey) {
                                                                                                                return Curry._1(send, /* DeleteRule */Block.__(5, [ruleKey]));
                                                                                                              }), undefined, []))])), React.createElement("div", {
                                                                                                className: Css.merge(/* :: */[
                                                                                                      Css.style(/* :: */[
                                                                                                            Css.marginBottom(Css.px(18)),
                                                                                                            /* :: */[
                                                                                                              Css.width(Css.pct(85)),
                                                                                                              /* [] */0
                                                                                                            ]
                                                                                                          ]),
                                                                                                      /* :: */[
                                                                                                        Styles$BsConsole.$$Text.subtitle,
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ])
                                                                                              }, React.createElement("i", undefined, I18N$BsConsole.show(undefined, "A rule consists of predicates and an action. If a predicate is omitted, it is considered a wildcard. Drag to reorder rules."))))]))]))
                                                                ])));
                                            })))])));
            }),
          initialState: (function (param) {
              var initRules = ruleObject !== undefined ? transformRulesToCardData(parseRuleObjectsFromString(ruleObject.rules)) : /* [] */0;
              var initEnabled = ruleObject !== undefined ? ruleObject.enabled !== 0 : true;
              var platforms;
              if (ruleObject !== undefined) {
                var plat = ruleObject.platforms;
                platforms = plat !== undefined ? plat : "";
              } else {
                platforms = "";
              }
              var platformsArr = platforms.split(" ");
              var platformDict = platformsToDict(platformsArr);
              var languages;
              if (ruleObject !== undefined) {
                var lang = ruleObject.languages;
                languages = lang !== undefined ? lang : "";
              } else {
                languages = "";
              }
              var languagesArr = languages.split(" ");
              var languageDict = platformsToDict(languagesArr);
              return {
                      platformAny: true,
                      platformOther: "",
                      linux: Js_dict.get(platformDict, "linux") !== undefined,
                      macos: Js_dict.get(platformDict, "macos") !== undefined,
                      windows: Js_dict.get(platformDict, "windows") !== undefined,
                      unix: Js_dict.get(platformDict, "unix") !== undefined,
                      freebsd: Js_dict.get(platformDict, "freebsd") !== undefined,
                      sunos: Js_dict.get(platformDict, "sunos") !== undefined,
                      android: Js_dict.get(platformDict, "android") !== undefined,
                      ios: Js_dict.get(platformDict, "ios") !== undefined,
                      languageAny: true,
                      languageOther: "",
                      csharp: Js_dict.get(languageDict, "csharp") !== undefined,
                      python: Js_dict.get(languageDict, "python") !== undefined,
                      java: Js_dict.get(languageDict, "java") !== undefined,
                      kotlin: Js_dict.get(languageDict, "kotlin") !== undefined,
                      swift: Js_dict.get(languageDict, "swift") !== undefined,
                      objectivec: Js_dict.get(languageDict, "objectivec") !== undefined,
                      nodejs: Js_dict.get(languageDict, "nodejs") !== undefined,
                      javascript: Js_dict.get(languageDict, "javascript") !== undefined,
                      ruby: Js_dict.get(languageDict, "ruby") !== undefined,
                      go: Js_dict.get(languageDict, "go") !== undefined,
                      c: Js_dict.get(languageDict, "c") !== undefined,
                      rules: initRules,
                      rulesetEnabled: initEnabled
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* ToggleRulesetEnabled */0 :
                      var newrecord = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord.rulesetEnabled = !state.rulesetEnabled, newrecord)]);
                  case /* TogglePlatformAny */1 :
                      if (state.platformAny) {
                        var newrecord$1 = Caml_obj.caml_obj_dup(state);
                        return /* Update */Block.__(0, [(newrecord$1.platformAny = false, newrecord$1)]);
                      }
                      var newrecord$2 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$2.ios = false, newrecord$2.android = false, newrecord$2.sunos = false, newrecord$2.freebsd = false, newrecord$2.unix = false, newrecord$2.windows = false, newrecord$2.macos = false, newrecord$2.linux = false, newrecord$2.platformAny = true, newrecord$2)]);
                  case /* ToggleLanguageAny */2 :
                      if (state.languageAny) {
                        var newrecord$3 = Caml_obj.caml_obj_dup(state);
                        return /* Update */Block.__(0, [(newrecord$3.languageAny = false, newrecord$3)]);
                      }
                      var newrecord$4 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$4.c = false, newrecord$4.go = false, newrecord$4.ruby = false, newrecord$4.javascript = false, newrecord$4.nodejs = false, newrecord$4.objectivec = false, newrecord$4.swift = false, newrecord$4.kotlin = false, newrecord$4.java = false, newrecord$4.python = false, newrecord$4.csharp = false, newrecord$4.languageAny = true, newrecord$4)]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* SetLanguage */0 :
                      switch (action[0]) {
                        case "c" :
                            if (state.c) {
                              var newrecord$5 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$5.c = false, newrecord$5)]);
                            }
                            var newrecord$6 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$6.c = true, newrecord$6.languageAny = false, newrecord$6)]);
                        case "csharp" :
                            if (state.csharp) {
                              var newrecord$7 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$7.csharp = false, newrecord$7)]);
                            }
                            var newrecord$8 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$8.csharp = true, newrecord$8.languageAny = false, newrecord$8)]);
                        case "go" :
                            if (state.go) {
                              var newrecord$9 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$9.go = false, newrecord$9)]);
                            }
                            var newrecord$10 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$10.go = true, newrecord$10.languageAny = false, newrecord$10)]);
                        case "java" :
                            if (state.java) {
                              var newrecord$11 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$11.java = false, newrecord$11)]);
                            }
                            var newrecord$12 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$12.java = true, newrecord$12.languageAny = false, newrecord$12)]);
                        case "javascript" :
                            if (state.javascript) {
                              var newrecord$13 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$13.javascript = false, newrecord$13)]);
                            }
                            var newrecord$14 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$14.javascript = true, newrecord$14.languageAny = false, newrecord$14)]);
                        case "kotlin" :
                            if (state.kotlin) {
                              var newrecord$15 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$15.kotlin = false, newrecord$15)]);
                            }
                            var newrecord$16 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$16.kotlin = true, newrecord$16.languageAny = false, newrecord$16)]);
                        case "nodejs" :
                            if (state.nodejs) {
                              var newrecord$17 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$17.nodejs = false, newrecord$17)]);
                            }
                            var newrecord$18 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$18.nodejs = true, newrecord$18.languageAny = false, newrecord$18)]);
                        case "objectivec" :
                            if (state.objectivec) {
                              var newrecord$19 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$19.objectivec = false, newrecord$19)]);
                            }
                            var newrecord$20 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$20.objectivec = true, newrecord$20.languageAny = false, newrecord$20)]);
                        case "python" :
                            if (state.python) {
                              var newrecord$21 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$21.python = false, newrecord$21)]);
                            }
                            var newrecord$22 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$22.python = true, newrecord$22.languageAny = false, newrecord$22)]);
                        case "ruby" :
                            if (state.ruby) {
                              var newrecord$23 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$23.ruby = false, newrecord$23)]);
                            }
                            var newrecord$24 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$24.ruby = true, newrecord$24.languageAny = false, newrecord$24)]);
                        case "swift" :
                            if (state.swift) {
                              var newrecord$25 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$25.swift = false, newrecord$25)]);
                            }
                            var newrecord$26 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$26.swift = true, newrecord$26.languageAny = false, newrecord$26)]);
                        default:
                          return /* NoUpdate */0;
                      }
                  case /* SetPlatform */1 :
                      switch (action[0]) {
                        case "android" :
                            if (state.android) {
                              var newrecord$27 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$27.android = false, newrecord$27)]);
                            }
                            var newrecord$28 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$28.android = true, newrecord$28.platformAny = false, newrecord$28)]);
                        case "freebsd" :
                            if (state.freebsd) {
                              var newrecord$29 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$29.freebsd = false, newrecord$29)]);
                            }
                            var newrecord$30 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$30.freebsd = true, newrecord$30.platformAny = false, newrecord$30)]);
                        case "ios" :
                            if (state.ios) {
                              var newrecord$31 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$31.ios = false, newrecord$31)]);
                            }
                            var newrecord$32 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$32.ios = true, newrecord$32.platformAny = false, newrecord$32)]);
                        case "linux" :
                            if (state.linux) {
                              var newrecord$33 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$33.linux = false, newrecord$33)]);
                            }
                            var newrecord$34 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$34.linux = true, newrecord$34.platformAny = false, newrecord$34)]);
                        case "macos" :
                            if (state.macos) {
                              var newrecord$35 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$35.macos = false, newrecord$35)]);
                            }
                            var newrecord$36 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$36.macos = true, newrecord$36.platformAny = false, newrecord$36)]);
                        case "sunos" :
                            if (state.sunos) {
                              var newrecord$37 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$37.sunos = false, newrecord$37)]);
                            }
                            var newrecord$38 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$38.sunos = true, newrecord$38.platformAny = false, newrecord$38)]);
                        case "unix" :
                            if (state.unix) {
                              var newrecord$39 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$39.unix = false, newrecord$39)]);
                            }
                            var newrecord$40 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$40.unix = true, newrecord$40.platformAny = false, newrecord$40)]);
                        case "windows" :
                            if (state.windows) {
                              var newrecord$41 = Caml_obj.caml_obj_dup(state);
                              return /* Update */Block.__(0, [(newrecord$41.windows = false, newrecord$41)]);
                            }
                            var newrecord$42 = Caml_obj.caml_obj_dup(state);
                            return /* Update */Block.__(0, [(newrecord$42.windows = true, newrecord$42.platformAny = false, newrecord$42)]);
                        default:
                          return /* NoUpdate */0;
                      }
                  case /* AppendNewRule */2 :
                      var newrecord$43 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$43.rules = Belt_List.concat(state.rules, /* :: */[
                                        action[0],
                                        /* [] */0
                                      ]), newrecord$43)]);
                  case /* UpdateRules */3 :
                      var newrecord$44 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$44.rules = action[0], newrecord$44)]);
                  case /* UpdateRuleInRules */4 :
                      var rule = action[0];
                      var __x = state.rules;
                      var newRules = Belt_List.map(__x, (function (r) {
                              if (r.dataKey === rule.dataKey) {
                                return rule;
                              } else {
                                return r;
                              }
                            }));
                      var newrecord$45 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$45.rules = newRules, newrecord$45)]);
                  case /* DeleteRule */5 :
                      var ruleKey = action[0];
                      var __x$1 = state.rules;
                      var newRules$1 = Belt_List.filter(__x$1, (function (r) {
                              return r.dataKey !== ruleKey;
                            }));
                      var newrecord$46 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$46.rules = newRules$1, newrecord$46)]);
                  case /* SetLanguageOther */6 :
                      var newrecord$47 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$47.languageOther = action[0], newrecord$47)]);
                  case /* SetPlatformOther */7 :
                      var newrecord$48 = Caml_obj.caml_obj_dup(state);
                      return /* Update */Block.__(0, [(newrecord$48.platformOther = action[0], newrecord$48)]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var mininumHeight = 350;

exports.AddRulesetFormContainer = AddRulesetFormContainer;
exports.mininumHeight = mininumHeight;
exports.labelStyle = labelStyle;
exports.formStyle = formStyle;
exports.inputStyle = inputStyle;
exports.optionStyle = optionStyle;
exports.checkboxLabelStyle = checkboxLabelStyle;
exports.headerStyle = headerStyle;
exports.subheaderStyle = subheaderStyle;
exports.errorMessageStyle = errorMessageStyle;
exports.decodeRuleObject = decodeRuleObject;
exports.parseRuleObjectsFromString = parseRuleObjectsFromString;
exports.transformRulesToCardData = transformRulesToCardData;
exports.platformsToDict = platformsToDict;
exports.objectRuleToDict = objectRuleToDict;
exports.encodeListOfRuleObjectsToListOfDicts = encodeListOfRuleObjectsToListOfDicts;
exports.convertListToRawRuleString = convertListToRawRuleString;
exports.PlatformOption = PlatformOption;
exports.LanguageOption = LanguageOption;
exports.langOptions = langOptions;
exports.platOptions = platOptions;
exports.component = component;
exports.make = make;
/* AddRulesetFormContainer Not a pure module */
