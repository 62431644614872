// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

function ofJson(json) {
  return {
          savedQueryJsonString: Json_decode.field("savedQueryJsonString", Json_decode.string, json)
        };
}

function toJson(t$prime) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "savedQueryJsonString",
                t$prime.savedQueryJsonString
              ],
              /* [] */0
            ]);
}

exports.ofJson = ofJson;
exports.toJson = toJson;
/* No side effect */
