// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Task2$BsConsole = require("../../Task2.js");
var AlertsMetrics$BsConsole = require("../AlertsMetrics.js");

var resource = "targets";

function workflowFromJson(json) {
  return {
          workflowName: Json_decode.field("workflow_name", Json_decode.string, json)
        };
}

function targetFromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          id: Json_decode.field("id", Json_decode.string, json),
          universe: Json_decode.field("universe", Json_decode.string, json),
          project: Json_decode.field("project", Json_decode.string, json),
          targetType: Json_decode.field("target_type", Json_decode.string, json),
          workflow1: Json_decode.field("workflow1", workflowFromJson, json)
        };
}

function targetsFromJson(json) {
  return {
          values: Json_decode.field("values", (function (param) {
                  return Json_decode.list(targetFromJson, param);
                }), json)
        };
}

function create(path, universeName, projectName, name, workflowName, param) {
  AlertsMetrics$BsConsole.send(/* TargetCreate */0);
  var json = Json_encode.object_(/* :: */[
        /* tuple */[
          "name",
          name
        ],
        /* :: */[
          /* tuple */[
            "target_type",
            "workflow1"
          ],
          /* :: */[
            /* tuple */[
              "workflow1",
              Json_encode.object_(/* :: */[
                    /* tuple */[
                      "workflow_name",
                      workflowName
                    ],
                    /* [] */0
                  ])
            ],
            /* [] */0
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Post */Block.__(0, [json]), undefined, (function (respBag) {
                return /* Ok */Block.__(0, [targetFromJson(respBag.json)]);
              }), undefined, undefined);
}

function get(path, universeName, projectName, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [targetsFromJson(respBag.json)]);
              }), undefined, undefined);
}

function getById(path, universeName, projectName, id, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Get */0, undefined, (function (respBag) {
                return /* Ok */Block.__(0, [targetFromJson(respBag.json)]);
              }), undefined, undefined);
}

function $$delete(path, universeName, projectName, id, param) {
  AlertsMetrics$BsConsole.send(/* TargetDelete */2);
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(resource) + ("/" + (String(id) + ("?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

exports.resource = resource;
exports.workflowFromJson = workflowFromJson;
exports.targetFromJson = targetFromJson;
exports.targetsFromJson = targetsFromJson;
exports.create = create;
exports.get = get;
exports.getById = getById;
exports.$$delete = $$delete;
/* Task2-BsConsole Not a pure module */
